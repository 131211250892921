import { Action } from "src/app/common/enumerations/enumerations";
import { BeamlineRequiringApproval } from "../../models/beamline-requiring-approval";

export class BratoiRefresh {
  static readonly type = '[Bratoi] Refresh';
}

export class BratoiRefreshById {
  static readonly type = '[Bratoi] RefreshById';

  constructor(public id: number) { }
}

export class BratoiCreate {
  static readonly type = '[Bratoi] Create';

  constructor(public item: BeamlineRequiringApproval) { }
}

export class BratoiUpdate {
  static readonly type = '[Bratoi] Update';

  constructor(public id: number, public item: BeamlineRequiringApproval) { }
}

export class BratoiUpdateAction {
  static readonly type = '[Bratoi] UpdateAction';

  constructor(public id: number, public item: BeamlineRequiringApproval, public action: Action) { }
}

export class BratoiDelete {
  static readonly type = '[Bratoi] Delete';

  constructor(public id: number) { }
}
