<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-2">
        <h6>{{ number ?? "#" }}</h6>
      </div>
      <div class="col-9 d-flex pt-2">
        <div class="pr-1">
          <mat-label
            [innerHTML]="textBefore ?? '' | placeholderHighlight"
          ></mat-label>
          <b>&nbsp;<mat-label [innerHTML]="scheduleName"></mat-label>&nbsp;</b>
          <mat-label
            [innerHTML]="textAfter ?? '' | placeholderHighlight"
          ></mat-label>
          <b>&nbsp;<mat-label [innerHTML]="locationName"></mat-label>&nbsp;</b>
          <mat-label [innerHTML]="textAfter2"></mat-label><br />
          <div *ngIf="!moreInfo">
            <mat-label>Last Completed Date:</mat-label>
            <b class="date" [ngClass]="{ 'expired-date': warning }"
              >&nbsp;<mat-label
                [innerHTML]="schedule?.lastCompleted | date : 'M/d/yyyy'"
              ></mat-label
              >&nbsp;</b
            >
            <mat-label>Next Inspection Due Date:</mat-label>
            <b class="date" [ngClass]="{ 'expired-date': warning }"
              >&nbsp;<mat-label
                [innerHTML]="schedule?.nextDue | date : 'M/d/yyyy'"
              ></mat-label
              >&nbsp;</b
            >
            <span *ngIf="dateValue3">
              <mat-label>Extended Date:</mat-label>
              <b class="date" [ngClass]="{ 'expired-date': warning }"
                >&nbsp;<mat-label
                  [innerHTML]="schedule?.extendedDate | date : 'M/d/yyyy'"
                ></mat-label
              ></b>
            </span>
          </div>
          <div *ngIf="moreInfo" class="pr-1 bold color-red">
            <b>{{ moreInfo }}</b>
          </div>
        </div>
      </div>

      <div class="col-2 d-flex flex-row justify-content-end align-items-center">
        <mat-checkbox-with-roles
          labelPosition="before"
          [checked]="checked"
          [disabled]="disabled"
          (change)="checkboxChanged($event)"
          color="primary"
          [roleCodes]="roleCodes"
          [user]="user"
          [warning]="moreInfo ?? ''"
          [date]="date"
        >
        </mat-checkbox-with-roles>
      </div>
    </div>
  </div>
</div>
