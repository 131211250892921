import { KEResource } from '../../ke';

export class KEnableRefresh {
  static readonly type = '[KEnable] Refresh';
}
export class KEnableCreate {
  static readonly type = '[KEnable] Create';
  constructor(public item: KEResource) { }
}
export class KEnableUpdate {
  static readonly type = '[KEnable] Update';
  constructor(public id: number, public item: KEResource) { }
}
export class KEnableDelete {
  static readonly type = '[KEnable] Delete';
  constructor(public id: number) { }
}
export class KEnableRefreshByID {
  static readonly type = '[KEnable] RefreshByID';
  constructor(public id: number) { }
}
