<div class="mt-4">
  <h5>Worker Verification</h5>
  <div class="mat-container">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="user">
        <mat-header-cell *matHeaderCellDef>User</mat-header-cell>
        <mat-cell *matCellDef="let e" class="bold">{{ e.user.name }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
        <mat-cell *matCellDef="let e" class="date">{{
          e.date | date : "medium"
        }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>
          <button
            mat-icon-button
            (click)="addSignature()"
            *ngIf="!disableButtons && !signed"
            matTooltip="Add Worker Verification Signature"
          >
            <mat-icon>add</mat-icon>
          </button></mat-header-cell
        >
        <mat-cell *matCellDef="let e">
          <button
            mat-icon-button
            color="warn"
            (click)="remove()"
            matTooltip="Remove Signature"
            *ngIf="e.userID == getCurrentUser()?.id && !disableButtons"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayed_columns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayed_columns"></mat-row>
    </mat-table>
  </div>
</div>
