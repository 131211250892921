import { TemplateType } from '../../checklists';

export class TemplateTypesRefresh {
  static readonly type = '[TemplateTypes] Refresh';
}
export class TemplateTypesRefreshByDocument {
  static readonly type = '[TemplateTypes] RefreshByDocument';
  constructor(public id: number) { }
}
export class TemplateTypesLoad {
  static readonly type = '[TemplateTypes] Load';
  constructor(public items: TemplateType[]) { }
}
export class TemplateTypesCreate {
  static readonly type = '[TemplateTypes] Create';
  constructor(public item: TemplateType) { }
}
export class TemplateTypesUpdate {
  static readonly type = '[TemplateTypes] Update';
  constructor(public id: number, public item: TemplateType) { }
}
export class TemplateTypesDelete {
  static readonly type = '[TemplateTypes] Delete';
  constructor(public id: number) { }
}
