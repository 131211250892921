import { Component, EventEmitter, Injector, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { utils } from 'src/app/modules/libs/utils';
import { Form } from 'src/app/services/forms/form';
import { WfSignatureResource, WfSignature, WFConfiguration, ExtraAction } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { EbService } from '../../eb.service';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'eb-main-s4',
  templateUrl: './eb-main-s4.component.html',
  styleUrls: ['./eb-main-s4.component.scss']
})
export class EbMainS4Component extends BaseComponent implements OnChanges {

  @Input() form?: Form | null;
  @Input() disabled!: boolean;
  @Input() showAmendment!: boolean;

  @Output() loading = new EventEmitter<boolean>()

  wfSignatures?: WfSignatureResource[];
  signatures!: WfSignature[];
  section4ExtraActions: ExtraAction[] = [];
  section41Signatures: WfSignature[] = [];
  section42Signatures: WfSignature[] = [];

  technicalApprover2Signature: WfSignature | null = null;
  technicalApprover3Signature: WfSignature | null = null;

  oicApproval2Signature: WfSignature | null = null;
  makeBypassPermanentSignature: WfSignature | null = null;

  amendments!: any[];
  configuration?: WFConfiguration[];

  constructor(
    protected override injector: Injector,
    public service: EbService,
    private workflowService: WorkFlowService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  refresh() {
    if (!this.form) return;
    this.workflowService.readConfiguration(9, this.form.wfTableID ?? 0).toPromise().then(data => {
      this.configuration = data;

      const leftPersons = [
        this.configuration?.filter(x => x.identificator == 109) ?? []];

      const rightPersons = [
        this.configuration?.filter(x => x.identificator == 110) ?? []];

      this.wfSignatures = this.form?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 4)?.wfSignatures;
      // const amendmentSignatures = this.form?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 14)?.wfSignatures?.filter(s => s.amendmentNumber) ?? [];

      let technicalApprover2Configuration: WFConfiguration[] = [];
      let oicApproval2Configuration: WFConfiguration[] = [];
      let makeBypassPermanentConfiguration: WFConfiguration[] = [];

      technicalApprover2Configuration = this.configuration?.filter((x) => x.identificator == 207) ?? [];
      oicApproval2Configuration = this.configuration?.filter((x) => x.identificator == 208) ?? [];
      makeBypassPermanentConfiguration = this.configuration?.filter((x) => x.identificator == 209) ?? [];

      this.signatures = [];
      this.section41Signatures = [];
      this.section42Signatures = [];
      this.section4ExtraActions = [];

      if (
        !technicalApprover2Configuration.length || !oicApproval2Configuration.length || !makeBypassPermanentConfiguration.length
      ) {
        this.wfSignatures?.map(signature => {
          this.signatures.push(
            WfSignatureResource.toWFSignatureResourceWithPerson(signature, this.wfSignatures ?? [], leftPersons, rightPersons, this.getCurrentUser()?.id ?? -1));
          if (signature.approveEnabled) {
            this.section4ExtraActions =
              this.section4ExtraActions.concat(
                signature.extraActions
              );
          }
        });
      }
      else {
        this.wfSignatures?.map(signature => {
          if (technicalApprover2Configuration.some((x) => x.wfTaskLocalID == signature.id)) {
            this.technicalApprover2Signature = WfSignatureResource.toWFSignatureResourceWithPerson(signature, this.wfSignatures ?? [], [this.configuration?.filter((x) => x.identificator == 111) ?? []], [this.configuration?.filter((x) => x.identificator == 112) ?? []], this.getCurrentUser()?.id ?? -1);
            this.technicalApprover3Signature = utils.cloneDeep(this.technicalApprover2Signature);
          }

          if (oicApproval2Configuration.some((x) => x.wfTaskLocalID == signature.id)) {
            this.oicApproval2Signature = WfSignatureResource.toWFSignatureResourceWithPerson(signature, this.wfSignatures ?? [], [this.configuration?.filter((x) => x.identificator == 111) ?? []], [this.configuration?.filter((x) => x.identificator == 112) ?? []], this.getCurrentUser()?.id ?? -1);
          }

          if (makeBypassPermanentConfiguration.some((x) => x.wfTaskLocalID == signature.id)) {
            this.makeBypassPermanentSignature = WfSignatureResource.toWFSignatureResourceWithPerson(signature, this.wfSignatures ?? [], [this.configuration?.filter((x) => x.identificator == 111) ?? []], [this.configuration?.filter((x) => x.identificator == 112) ?? []], this.getCurrentUser()?.id ?? -1
            );
          }

          if (signature.approveEnabled)
            this.section4ExtraActions = this.section4ExtraActions.concat(signature.extraActions);
        });

        if (this.technicalApprover2Signature)
          this.technicalApprover2Signature.enabled = this.makeBypassPermanentSignature?.enabled;
        if (this.technicalApprover3Signature)
          this.technicalApprover3Signature.enabled = this.oicApproval2Signature?.enabled;

        if (this.technicalApprover2Signature && !this.technicalApprover2Signature?.enabled) {
          this.technicalApprover2Signature.approved = false;
          this.technicalApprover2Signature.approvedBy = null;
          this.technicalApprover2Signature.approvedOn = null;
          this.technicalApprover2Signature.approve = false;
          this.technicalApprover2Signature.disapprove = false;
          this.technicalApprover2Signature.unapprove = false;
          this.technicalApprover2Signature.amendment = false;
          this.technicalApprover2Signature.cancel = false;
        }

        if (this.technicalApprover3Signature && !this.technicalApprover3Signature.enabled) {
          this.technicalApprover3Signature.approved = false;
          this.technicalApprover3Signature.approvedBy = null;
          this.technicalApprover3Signature.approvedOn = null;
          this.technicalApprover3Signature.approve = false;
          this.technicalApprover3Signature.disapprove = false;
          this.technicalApprover3Signature.unapprove = false;
          this.technicalApprover3Signature.amendment = false;
          this.technicalApprover3Signature.cancel = false;
        }

        if (this.technicalApprover2Signature)
          this.section41Signatures.push(this.technicalApprover2Signature);
        if (this.makeBypassPermanentSignature)
          this.section41Signatures.push(this.makeBypassPermanentSignature);
        if (this.technicalApprover3Signature)
          this.section42Signatures.push(this.technicalApprover3Signature);
        if (this.oicApproval2Signature)
          this.section42Signatures.push(this.oicApproval2Signature);
      }

      this.loading.emit(false);
    });
    this.amendments = [];
    this.signatures?.map(s => {
      if (!this.amendments.includes(s.amendmentNumber)) {
        this.amendments.push(s.amendmentNumber);
      }
    });

  }

  getSignaturesByAmendment(a: any) {
    return this.signatures.filter(s => s.amendmentNumber == a);
  }

  getLastAmendment() {
    const last = this.signatures.filter(x => x.amendmentNumber).map(x => x.amendmentNumber).sort((a, b) => utils.sort(a, b, false));
    return last ? last[0] : 0;
  }

  async approve(s: WfSignature) {
    const signed = await this.service.confirmSign(this.form, s, s.approveId, '', undefined, this.configuration);
    if (!signed) this.refresh();
  }
}
