<div class="card">
  <div class="card-body p-0">
    <form>
      <div class="edit-container relative">
        <div class="row no-gutters">
          <div class="col-12">
            <div class="pb-1">
              <quill-editor
                [modules]="quillConfig"
                *ngIf="!showHTML"
                [(ngModel)]="textValue"
                [formControl]="quillCtrl"
                class="editor"
                (onEditorCreated)="created($event)"
              >
                <div quill-editor-toolbar>
                  <span class="ql-formats">
                    <select class="ql-header">
                      <option value="1">Heading</option>
                      <option value="2">Subheading</option>
                      <option selected>Normal</option>
                    </select>
                    <select class="ql-font">
                      <option selected></option>
                      <option value="serif"></option>
                      <option value="monospace"></option>
                    </select>
                    <select class="ql-size">
                      <option value="small"></option>
                      <option selected></option>
                      <option value="large"></option>
                      <option value="huge"></option>
                    </select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-strike"></button>
                  </span>
                  <span class="ql-formats">
                    <select class="ql-color">
                      <option
                        *ngFor="let color of quillColors"
                        [value]="color"
                      ></option>
                    </select>
                    <select class="ql-background">
                      <option
                        *ngFor="let color of quillColors"
                        [value]="color"
                      ></option>
                    </select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <select class="ql-align">
                      <option selected></option>
                      <option value="center"></option>
                      <option value="right"></option>
                      <option value="justify"></option>
                    </select>
                  </span>
                  <!-- <span class="ql-formats">
                <button class="ql-formula"></button>
                <button class="ql-code-block"></button>
              </span> -->
                  <span class="ql-formats">
                    <!-- <button class="ql-table" (click)="addTable($event)">
                  <mat-icon>grid_on</mat-icon>
                </button> -->
                    <button class="ql-link"></button>
                    <button class="ql-image"></button>
                    <button class="ql-video"></button>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-clean"></button>
                    <button color="primary" (click)="isShowHTML()">
                      <mat-icon>code</mat-icon>
                    </button>
                  </span>
                  <!-- <span class="ql-formats">
                    <div class="d-flex align-items-center">
                      <span class="small mt-1">Default image upload size:</span>
                      <select class="image-sizes">
                        <option value="255">Small</option>
                        <option value="400">Medium</option>
                        <option value="0">Original</option>
                      </select>
                    </div>
                  </span> -->
                  <span class="ql-formats float-right mr-0">
                    <button
                      mat-flat-button
                      class="ok-btn mr-1"
                      (click)="save()"
                      [disabled]="loading"
                      [ngClass]="{ disabled: loading }"
                    >
                      <mat-icon>save</mat-icon>
                    </button>
                    <button
                      mat-stroked-button
                      color="warn"
                      class="cancel-btn"
                      (click)="cancel()"
                      [disabled]="loading"
                    >
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </span>
                </div>
              </quill-editor>
              <div class="ql-toolbar ql-snow ge-toolbar" *ngIf="showHTML">
                <span class="ql-formats">
                  <button color="primary" (click)="isShowHTML()">
                    <mat-icon>code</mat-icon>
                  </button>
                </span>
                <span class="ql-formats float-right mr-0">
                  <button
                    mat-flat-button
                    class="ok-btn mr-1"
                    (click)="save()"
                    [disabled]="loading"
                    [ngClass]="{ disabled: loading }"
                  >
                    <mat-icon>save</mat-icon>
                  </button>
                  <button
                    mat-stroked-button
                    color="warn"
                    class="cancel-btn"
                    (click)="cancel()"
                    [disabled]="loading"
                  >
                    <mat-icon>cancel</mat-icon>
                  </button>
                </span>
              </div>
              <mat-form-field *ngIf="showHTML">
                <textarea
                  aria-label="comment"
                  matInput
                  class="html-textarea"
                  type="text"
                  [(ngModel)]="textValue"
                  [ngModelOptions]="{ standalone: true }"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12">
            <div class="flex-display flex-column">
              <span class="float-right message" *ngIf="loading">
                Uploading images ....
                <span class="spinner-border spinner-border-sm mr-1"></span>
              </span>
              <span class="float-right"> </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
