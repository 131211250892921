import { RadInterlock } from "src/app/components/catalogs/rad-monitor/rad-monitor/rad-interlock";

export class RadInterlockRefresh {
  static readonly type = '[RadInterlock] Refresh';
}

export class RadInterlockCreate {
  static readonly type = '[RadInterlock] Create';
  constructor(public item: RadInterlock) { }
}

export class RadInterlockUpdate {
  static readonly type = '[RadInterlock] Update';
  constructor(public id: number, public item: RadInterlock) { }
}

export class RadInterlockDelete {
  static readonly type = '[RadInterlock] Delete';
  constructor(public id: number) { }
}
