import { ReviewAttendanceCategory } from '../../reviews.models';

export class ReviewAttendanceCategoriesRefresh {
  static readonly type = '[ReviewAttendanceCategories] Refresh';
}

export class ReviewAttendanceCategoriesCreate {
  static readonly type = '[ReviewAttendanceCategories] Create';

  constructor(public item: ReviewAttendanceCategory) { }
}

export class ReviewAttendanceCategoriesUpdate {
  static readonly type = '[ReviewAttendanceCategories] Update';

  constructor(public id: number, public item: ReviewAttendanceCategory) { }
}

export class ReviewAttendanceCategoriesDelete {
  static readonly type = '[ReviewAttendanceCategories] Delete';
  constructor(public id: number) { }
}
