import { AfterViewInit, Component, ElementRef, HostListener, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MenuService } from './menu.service';
import { FilterData } from 'src/app/controls/filter-box/filter-box.component';
import { Observable, Subscription } from 'rxjs';
import { MenuItem } from '../head-page/head-menu/head-menu.models';

@Component({
  selector: 'app-menu-config',
  templateUrl: './menu-config.component.html',
  styleUrls: ['./menu-config.component.scss']
})
export class MenuConfigComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @ViewChild("sidenav") sidenav!: MatSidenav;
  @ViewChild("headerTop") headerTop!: ElementRef;

  disabled: boolean = false;

  loading = false;

  resourceRequired: boolean = false;

  filterCtrl = new FormControl();

  errorMessages: string[] = [];
  headerOnTop: boolean = false;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter: any[] = [];
  currentFilter: any;
  filter: any;

  element!: any;

  loadingMessage!: string;

  showButtons: boolean = false;
  //////

  menuItemsFilterData?: FilterData[];

  menuItems?: MenuItem[];
  menuItemsFiltered?: MenuItem[];
  menuItems$!: Observable<MenuItem[]>;
  menuItemsSubs!: Subscription;


  constructor(
    protected override injector: Injector,
    private service: MenuService
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.setHeights();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.moduleTitle = 'Menu Configuration';
    this.service.currentItem.subscribe(data => {
      this.showButtons = data != undefined;
    });
    this.loadMenuItems();
  }

  loadMenuItems() {
    this.menuItems$ = this.store.select(state => state.MenuItem.data);
    this.menuItemsSubs = this.menuItems$.subscribe(data => {
      if (data?.length) {
        this.menuItemsFilterData = [];
        this.menuItems = data.sort((a, b) => this.utils.sort(a.name, b.name));
        this.menuItems.map(m => {
          this.menuItemsFilterData?.push({ id: m.id as number, text: m.name as string });
        });
      }
    });
  }

  filterResutSelected(e: FilterData) {
    const menuItem = this.menuItems?.find(m => m.id == e.id);
    this.service.setMenuItem(menuItem);
  }

  changeToggle(e: any) {

  }

  clear() {
    this.errorMessages = [];
  }

  @HostListener("window:resize")
  onResize() {
    this.innerWidth = window.innerWidth;
    // if (this.isExpanded != false) {

    // }
    if (this.innerWidth < 768) {
      this.isExpanded = false;
    }
  }

  @HostListener("window:scroll")
  isHeaderOnTop() {
    let elemRec;
    if (this.headerTop?.nativeElement !== undefined) {
      elemRec = this.headerTop.nativeElement.getBoundingClientRect();
      const docViewTop = window.screenTop;
      const elemTop = elemRec.top;
      if (elemTop <= docViewTop) {
        this.headerOnTop = true;
      } else {
        this.headerOnTop = false;
      }
    } else {
      this.headerOnTop = false;
    }
  }

  applyFilter(e: any) {
    if (!e)
      this.valueFilter = '';
    this.filter = e;
  }

  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 2000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Buttons:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Main:
          this.loadingMain = e as boolean;
          break;
      }
      // console.log('m: ' + this.loadingMain + '   b: ' + this.loadingButtons + '   s: ' + this.loadingSide);
      this.loading = this.loadingMain || this.loadingButtons || this.loadingSide;
    }, 100);
  }

  loadingSide = false;
  loadingButtons = false;
  loadingMain = false;
  loadingOrigin = LoadingOrigin;

}

enum LoadingOrigin {
  Side = 1, Buttons = 2, Main = 3
}
