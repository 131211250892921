import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { TransactionLogService } from 'src/app/services/logs/transaction-log.service';
import { TransactionLog } from 'src/app/services/logs/transaction-logs';

@Component({
  selector: 'transaction-log',
  templateUrl: './transaction-log.component.html',
  styleUrls: ['./transaction-log.component.scss']
})
export class TransactionLogComponent extends BaseComponent implements OnChanges {
  @Input() serialNo?: string;
  @Input() formType?: FormType;

  transactionLogs?: TransactionLog[];

  constructor(
    protected override injector: Injector,
    private service: TransactionLogService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getTransactionLogs();
  }

  async getTransactionLogs() {
    if (this.serialNo)
      this.transactionLogs = await this.service.GetBySerialNo(this.serialNo).toPromise();
  }

}
