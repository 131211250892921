import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { ActionItem } from 'src/app/common/models/action-item';
import { ActionItemService } from './action-item/action-item.service';
import { ActionItemEditComponent } from './action-item/action-item-edit/action-item-edit.component';
import { ActionItemsCreate } from './action-item/store/actionItems.action';

@Component({
  selector: 'catl',
  templateUrl: './catl.component.html',
  styleUrls: ['./catl.component.scss']
})
export class CatlComponent implements OnChanges {

  @Input() formType?: string | number;
  @Input() formId?: number;
  @Input() sectionNumber?: number;
  @Input() sectionId?: number;
  @Input() taskId?: number;
  @Input() showLabel?: boolean;
  @Input() serialNo?: string;

  actionItems: ActionItem[] = [];
  actionItems$!: Observable<ActionItem[]>;

  @Input() disabled?: boolean;
  @Input() builder?: boolean;

  @Output() catlChanged: EventEmitter<ActionItem[]> = new EventEmitter();

  subscription!: Subscription;
  isLoading = false;
  isActionLoading = false;

  /** catl ctor */
  constructor(
    private _action: ActionItemService,
    private store: Store,
    private dialog: MatDialog
  ) {

  }

  ngOnChanges() {
    this.getActionItems();
  }

  getActionItems() {
    this.isLoading = true;
    this.actionItems$ = this.store.select(state => state.ActionItems.ActionItems);
    this.subscription = this.actionItems$.subscribe(async data => {
      this.actionItems = [];
      if (this.taskId) {
        if (data.filter(x => x.wfTaskId == this.taskId).length > 0) {
          this.actionItems = data.filter(x => x.wfTaskId == this.taskId).sort((a, b) => (a.id ?? 0) - (b.id ?? 0));
          this.isLoading = false;
        }
        // else {
        //   if (!this.isActionLoading) {
        //     this.isActionLoading = true;
        //     this._action.readByTask(this.taskId).subscribe(res => {
        //       this.actionItems = res.sort((a, b) => (a.id ?? 0) - (b.id ?? 0));
        //       this.actionItems.map(actionItem => {
        //         this.store.dispatch(new ActionItemsCreate(actionItem));
        //       });
        //       this.isLoading = false;
        //     });
        //   }
        // }
      }
      else {
        if (this.sectionId) {
          if (data.filter(a => a.wfSectionId == this.sectionId).length > 0) {
            this.actionItems = data.filter(a => a.wfSectionId == this.sectionId && !a.wfTaskId).sort((a, b) => (a.id ?? 0) - (b.id ?? 0));
            this.isLoading = false;
          }
          // else {
          //   if (!this.isActionLoading) {
          //     this.isActionLoading = true;
          //     this._action.readBySection(this.sectionId).subscribe(res => {
          //       this.actionItems = res.filter(x => !x.wfTaskId).sort((a, b) => (a.id ?? 0) - (b.id ?? 0));
          //       this.actionItems.map(actionItem => {
          //         this.store.dispatch(new ActionItemsCreate(actionItem));
          //       });
          //       this.isLoading = false;
          //     });
          //   }
          // }
        }
      }
    });
  }

  add() {
    const actionItem = { id: 0 };
    this.edit(actionItem);
  }

  edit(actionItem: ActionItem) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      actionItem,
      formType: this.formType,
      formId: this.formId,
      sectionNumber: this.sectionNumber,
      sectionId: this.sectionId,
      taskId: this.taskId,
      serialNo: this.serialNo
    };
    dialogConfig.width = '80vw';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ActionItemEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {

      }
    });
  }
}
