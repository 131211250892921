import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RSSFunctionalTest } from 'src/app/components/rsswa/rss-functional-test';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RSSFunctionalTestService {
  url = environment.urls.baseApiUrl + '/RSSFunctionalTest';

  constructor(
    private http: HttpClient,
  ) { }

  create(rssFunctionalTest: RSSFunctionalTest, amendment: boolean = false): Observable<RSSFunctionalTest> {
    return this.http.post<RSSFunctionalTest>(this.url + '?amendment=' + (amendment ? 'true' : 'false'), rssFunctionalTest);
  }

  readByChecklistID(id: number): Observable<RSSFunctionalTest> {
    return this.http.get<RSSFunctionalTest>(this.url + '/checklist/' + id.toString());
  }

  update(id: number, rssFunctionalTest: RSSFunctionalTest, amendment: boolean = false): Observable<RSSFunctionalTest> {
    return this.http.put<RSSFunctionalTest>(this.url + '/' + id.toString() + '?amendment=' + (amendment ? 'true' : 'false'), rssFunctionalTest);
  }

  delete(id: number, amendment: boolean = false): Observable<any> {
    return this.http.delete<any>(this.url + '/' + id.toString() + '?amendment=' + (amendment ? 'true' : 'false'));
  }

  activate(id: number): Observable<any> {
    return this.http.put<any>(this.url + '/Active/' + id.toString(), null);
  }

  deleteActive(id: number, amendment: boolean = false): Observable<RSSFunctionalTest> {
    return this.http.delete<RSSFunctionalTest>(this.url + '/Active/' + id.toString() + '?amendment=' + (amendment ? 'true' : 'false'));
  }
}
