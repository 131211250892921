<div class="card">
  <div class="card-header">
    <span class="card-title">{{ title }} </span>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label [innerHTML]="label_category"></mat-label>
          <mat-select
            matNativeControl
            name="procedureCategoryID"
            [disabled]="loading"
            [(ngModel)]="procedure.procedureCategoryID"
            (selectionChange)="setProcedureCategory(); setPath()"
          >
            <mat-option
              *ngFor="let procedureCategory of procedureCategories"
              [value]="procedureCategory.id"
              >{{ procedureCategory.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label [innerHTML]="label_procedure_number"></mat-label>
          <input
            matInput
            name="procedureNumber"
            [disabled]="loading"
            [(ngModel)]="procedure.procedureNumber"
            (keyup)="setPath()"
          />
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 col-lg-3" *ngIf="data?.procedureTab !== 1">
        <mat-form-field appearace="outline">
          <mat-label [innerHTML]="label_title"></mat-label>
          <input
            matInput
            name="title"
            [disabled]="loading"
            [(ngModel)]="procedure.title"
          />
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 col-lg-3" *ngIf="data?.procedureTab === 1">
        <mat-form-field appearace="outline">
          <mat-label>Location</mat-label>
          <mat-select
            [(ngModel)]="procedure.locationId"
            [disabled]="loading"
            (selectionChange)="
              setTitleInKeByResource($event.value, shuttersBranchlines)
            "
          >
            <mat-option
              *ngFor="let resource of shuttersBranchlines"
              [value]="resource.id"
            >
              {{ resource.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label [innerHTML]="label_status"></mat-label>
          <mat-select
            matNativeControl
            name="status"
            [disabled]="loading"
            [(ngModel)]="procedure.status"
            (selectionChange)="setPath()"
          >
            <mat-option [value]="1"> {{ label_active }}</mat-option>
            <mat-option [value]="0"> {{ label_inactive }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label [innerHTML]="label_revision"></mat-label>
          <input
            matInput
            name="revision"
            [disabled]="loading"
            [(ngModel)]="procedure.revision"
          />
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label [innerHTML]="label_review_period"></mat-label>
          <mat-select
            matNativeControl
            name="reviewPeriod"
            [disabled]="
              loading ||
              (procedure.daysToExpire != null &&
                procedure.daysToExpire != undefined)
            "
            [(ngModel)]="procedure.reviewPeriod"
          >
            <mat-option>None</mat-option>
            <mat-option [value]="1">{{ label_3_years }}</mat-option>
            <mat-option [value]="2">{{ label_5_years }}</mat-option>
            <mat-option [value]="3">Upon Use</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Last Reviewed</mat-label>
          <input
            matInput
            [matDatepicker]="lastReviewed"
            name="lastReviewed"
            [disabled]="
              loading ||
              (procedure.daysToExpire != null &&
                procedure.daysToExpire != undefined)
            "
            [(ngModel)]="procedure.lastReviewed"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="lastReviewed"
          ></mat-datepicker-toggle>
          <mat-datepicker #lastReviewed></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Days to expire</mat-label>
          <input
            matInput
            type="number"
            min="1"
            [disabled]="
              loading ||
              (procedure.reviewPeriod != null &&
                procedure.reviewPeriod != undefined) ||
              (procedure.lastReviewed != null &&
                procedure.lastReviewed != undefined)
            "
            [(ngModel)]="procedure.daysToExpire"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3" style="text-align: center">
        <mat-slide-toggle
          color="primary"
          [disabled]="
            data?.complete == true || (procedure.previousVersionID ?? 0) > 0
          "
          [(ngModel)]="procedure.newProcedure"
        >
          <span [innerHTML]="label_popup_new_procedure"></span>
        </mat-slide-toggle>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <file-upload-v2
          [(fileID)]="procedure.fileID"
          [(file)]="procedure.file"
          [(processingFile)]="loading"
          [disabled]="disabledFileUpload()"
          [path]="path"
          [deleteFile]="isNew()"
        ></file-upload-v2>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <related-link-document-chips
          [placeholder]="label_appendices"
          [disabled]="false"
          [formId]="procedure.id"
          [relatedLinksDocuments]="procedure.procedureAppendices ?? []"
          [path]="path"
          [disabledFileUpload]="disabledFileUpload()"
          [deleteFile]="isNew()"
        ></related-link-document-chips>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <related-link-chips
          [placeholder]="
            procedure.procedureFiles?.length == 0
              ? label_upload_files
              : label_review_docs
          "
          [disabled]="false"
          [formId]="procedure.id"
          [relatedLinks]="procedure.procedureFiles"
          [documentNameOrHyperlinkPlaceholder]="label_display_text"
          [webAddressPlaceholder]="label_url_link"
        >
        </related-link-chips>
      </div>
    </div>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        color="warn"
        type="button"
        [disabled]="processingFile"
        (click)="cancel()"
        [innerHTML]="label_cancel"
      ></button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="processingFile"
        (click)="submit()"
        [innerHTML]="label_accept"
      ></button>
    </div>
  </div>
</div>
