import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, OnDestroy, ViewChildren, ViewContainerRef, QueryList, Output, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ScheduleSubtype } from 'src/app/components/schedules/models/schedule-subtype';
import { ScheduleTypeNested, ScheduleType } from 'src/app/components/schedules/models/schedule-type';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'schedule-types-list',
  templateUrl: './schedule-types-list.component.html',
  styleUrls: ['./schedule-types-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})
export class ScheduleTypesListComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() filter?: string;
  @Output() loading = new EventEmitter<boolean>();

  public displayedColumns: string[] = ['name', 'document', 'procedure'];
  public innerDisplayedColumns: string[] = ['name'];

  public dataSource!: MatTableDataSource<ScheduleTypeNested>;

  @ViewChildren('matrow', { read: ViewContainerRef })
  rows!: QueryList<ViewContainerRef>;


  @Output() selectedScheduleType = new EventEmitter();
  @Output() selectedScheduleSubtype = new EventEmitter();

  public selectedScheduleTypeIndex!: number | null;
  public selectedScheduleSubtypeIndex!: number | null;
  public valueFilter!: string;
  public showInactive!: boolean;

  scheduleTypes!: ScheduleType[];
  scheduleTypes$!: Observable<ScheduleType[]>;
  scheduleTypesSubs!: Subscription;

  scheduleTypesNested?: ScheduleTypeNested[];

  @ViewChildren('innerTables') innerTables!: QueryList<MatTable<ScheduleSubtype>>;
  @ViewChildren('innerSort') innerSort!: QueryList<MatSort>;
  @ViewChild(MatSort) sort!: MatSort;

  override ngOnDestroy(): void {
    this.scheduleTypesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter']) this.filterDataSource();
  }

  ngOnInit(): void {
    this.loadScheduleTypes();
  }

  loadScheduleTypes() {
    this.loading.emit(true);
    this.scheduleTypes$ = this.store.select(state => state.ScheduleTypes.data);
    this.scheduleTypesSubs = this.scheduleTypes$.subscribe(data => {
      if (data.length) {
        let scheduleTypesTable: ScheduleTypeNested[] = [];
        this.scheduleTypes = data;
        this.scheduleTypes.map(scheduleType => {
          if ((scheduleType.scheduleSubtypes?.length ?? 0) > 0) {
            scheduleTypesTable = [...scheduleTypesTable, { ...scheduleType, scheduleSubtypesTable: new MatTableDataSource(scheduleType.scheduleSubtypes) }];
          }
          else {
            scheduleTypesTable = [...scheduleTypesTable, scheduleType];
          }
        });
        this.scheduleTypesNested = this.showInactive ? scheduleTypesTable : scheduleTypesTable.filter(x => x.isActive);
        this.filterDataSource();
        this.loading.emit(false);
      }
    });
  }

  setSortingAccessor() {
    // Define the sorting accessor
    this.dataSource.sortingDataAccessor = (element, property) => {
      switch (property) {
        case 'documenbt':
          return element.documentType?.name;
        case 'procedure':
          return element.procedureType?.name;
        default:
          return (element as any)[property]; // Fallback to the default property
      }
    };
    this.setSort<ScheduleTypeNested>(this.dataSource, this.sort);
  }

  filterDataSource() {
    const filter = this.filter?.toLowerCase();

    const data = this.scheduleTypesNested?.filter(s => !filter ? true : (
      s.name.toLowerCase().includes(filter) ||
      s.acronym.toLowerCase().includes(filter) ||
      s.description.toLowerCase().includes(filter) ||
      s.documentType?.name?.includes(filter) ||
      s.procedureType?.name.includes(filter) ||
      s.scheduleSubtypes.some(t => t.name?.toLowerCase().includes(filter))
    ));

    this.dataSource = new MatTableDataSource(data);
    this.setSortingAccessor();
  }

  getColor(item: ScheduleType): string {
    if (item.isActive) {
      return '#333';
    }
    else {
      return '#E04E39';
    }
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex(r => r.id == row.id);
    return i;
  }

  showInactiveItems() {
    this.loadScheduleTypes();
  }

  applyFilter(valueFilter: string) {
    const data = this.showInactive ? this.scheduleTypes : this.scheduleTypes.filter(x => x.isActive);
    this.dataSource.data = data.filter(x => x.name.toLowerCase().includes(valueFilter.toLowerCase())) as ScheduleTypeNested[];
  }

  async seletedSubtype(row: ScheduleSubtype) {
    if (await this.canDeactivate()) {
      this.selectedScheduleSubtypeIndex = row.id;
      row.scheduleType = this.scheduleTypes.find(x => x.id == row.scheduleTypeId);
      this.selectedScheduleSubtype.emit(row);
      this.setHeights();
    }
  }

  async create() {
    if (await this.canDeactivate()) {
      this.selectedScheduleType.emit({
        id: 0,
        name: '',
        description: '',
        acronym: '',

      } as ScheduleType);
    }
  }

  async toggleRow(element: ScheduleTypeNested) {
    if (await this.canDeactivate()) {
      if (this.selectedScheduleSubtypeIndex && this.selectedScheduleTypeIndex === element.id) {
        this.selectedScheduleSubtypeIndex = null;
        this.selectedScheduleType.emit(element);
        return;
      }
      this.selectedScheduleTypeIndex = this.selectedScheduleTypeIndex === element.id ? null : element.id;
      this.innerTables.map((table, index) => (table.dataSource as MatTableDataSource<ScheduleSubtype>).sort = this.innerSort.toArray()[index]);
      if (this.selectedScheduleTypeIndex) {
        this.selectedScheduleType.emit(element);
      }
      else {
        this.selectedScheduleType.emit(null);
      }
      this.setHeights();
    }
  }
}
