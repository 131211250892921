import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { APLGroup } from 'src/app/components/apl/services/apl-group.model';
import FileV2 from 'src/app/services/file/file-v2.model';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { APLGroupService } from '../../../apl-group.service';


export class APLGroupPopupParameters {
  public aplCategoryMasterID!: number;
  public aplGroup!: APLGroup;
  public rolesSelected?: Role[];
}

@Component({
  selector: 'app-apl-group-popup',
  templateUrl: './apl-group-popup.component.html',
  styleUrls: ['./apl-group-popup.component.scss']
})
export class APLGroupPopupComponent extends BaseComponent implements OnInit, OnDestroy {
  aplGroup = new APLGroup();
  loading = false;
  title!: string;
  rolesSelected!: Role[];
  aplGroupApprovalPrivilege!: boolean;

  labelCreateGroup!: string;
  labelEditGroup!: string;
  labelName!: string;
  labelDescription!: string;
  labelSelectImage!: string;
  labelManagers!: string;
  labelProcedureTrainingCoordinators!: string;
  labelRoles!: string;
  labelCancel!: string;
  labelAccept!: string;
  labelAreYouSureToDiscardChanges!: string;

  constructor(
    protected override injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    public data: APLGroupPopupParameters,
    private dialogRef: MatDialogRef<APLGroupPopupParameters>,
    private aplGroupService: APLGroupService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.setLabels();
    if (this.data.aplGroup) {
      this.aplGroup = this.utils.cloneDeep(this.data.aplGroup);
      this.aplGroup.previousVersionID = this.aplGroup.id;
      this.aplGroup.aplGroupManagers = this.aplGroup.aplGroupManagers?.filter(m => m.user?.status == 1);
      this.aplGroup.aplProcedureTrainingCoordinators = this.aplGroup.aplProcedureTrainingCoordinators?.filter(m => m.user?.status == 1);
      this.aplGroup.id = 0;
    } else {
      this.aplGroup.aplGroupMaster = {
        aplCategoryMasterID: this.data.aplCategoryMasterID,
      };
    }

    this.aplGroupApprovalPrivilege = this.hasPrivilege(PrivilegeEnum.APLGroupApproval);

    this.rolesSelected = this.data.rolesSelected ?? [];
  }

  setLabels() {
    this.labelCreateGroup = this.getMessage('APL_Group_CreateGroup')?.description;
    this.labelEditGroup = this.getMessage('APL_Group_EditGroup')?.description;
    this.labelName = this.getMessage('APL_Group_Name')?.description;
    this.labelDescription = this.getMessage('APL_Group_Description')?.description;
    this.labelSelectImage = this.getMessage('APL_Group_SelectImage')?.description;
    this.labelManagers = this.getMessage('APL_Group_Managers')?.description;
    this.labelRoles = this.getMessage('APL_Group_Roles')?.description;
    this.labelCancel = this.getMessage('APL_Group_Cancel')?.description;
    this.labelAccept = this.getMessage('APL_Group_Accept')?.description;
    this.labelAreYouSureToDiscardChanges = this.getMessage('APL_AreYouSureToDiscardChanges')?.description;
    this.title = (!this.data?.aplGroup) ? this.labelCreateGroup : this.labelEditGroup;
    this.labelProcedureTrainingCoordinators = this.getMessage('APL_Group_Card_ProcedureTrainingCoordinators')?.description;
  }

  submit() {
    this.loading = true;
    this.aplGroupService.create(this.aplGroup).subscribe(
      () => {
        this.dialogRef.close(true);
      },
      error => {
        this.alert.defaultError();
        this.loading = false;
      },
    );
  }

  cancel() {
    this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.labelAreYouSureToDiscardChanges,
      },
    }).afterClosed().subscribe(data => {
      if (data) {
        this.dialogRef.close(false);
      }
    });
  }

  imageLoaded(file: FileV2) {
    this.aplGroup.description = this.aplGroup.description ?? '';
    this.aplGroup.description += `<img src="${file.url}" alt="${file.name}" />`;
  }

}
