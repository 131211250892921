import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input, Injector } from '@angular/core';
import { ReviewType, ReviewBoardType, ReviewTypesEnum, Review } from '../../reviews.models';
import { Observable, Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { ReviewsService } from '../../reviews.service';
import { ReviewBoardTypesRefresh } from '../../store/review-board-types/review-board-types.action';
import { ReviewsRefresh } from '../../store/reviews/reviews.action';
import { ReviewsSettingsReviewTypesDetailsComponent } from './reviews-settings-review-types-details/reviews-settings-review-types-details.component';
import { USER_OPTIONS } from '@ngxs/logger-plugin/src/logger.module';
import { ReviewTypesRefresh } from '../../store/review-types/review-types.action';

@Component({
  selector: 'reviews-settings-review-types',
  templateUrl: './reviews-settings-review-types.component.html',
  styleUrls: ['./reviews-settings-review-types.component.scss']
})
export class ReviewsSettingsReviewTypesComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  reviewBoardType!: ReviewBoardType;

  reviewTypes!: ReviewType[];
  reviewTypes$!: Observable<ReviewType[]>;
  reviewTypesSubs!: Subscription;

  reviewTypesEnum = ReviewTypesEnum;
  reviewTypeEnum: ReviewTypesEnum = ReviewTypesEnum.FULL;

  reviews?: Review[];
  reviews$!: Observable<Review[]>;
  reviewsSubs!: Subscription;

  options = [{ id: 1, name: 'Full Review Types', checked: true }, { id: 2, name: 'Modification Review Types', checked: false }];
  optionSelectedId = 1;

  showInactive?: boolean;

  constructor(
    protected override injector: Injector,
    private service: ReviewsService,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.reviewTypesSubs?.unsubscribe();
    this.reviewsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadReviews();
  }

  loadReviews() {
    this.reviews$ = this.store.select(state => state.Reviews.data);
    this.reviewsSubs = this.reviews$.subscribe(data => {
      if (data?.length) {
        this.reviews = data;
        this.loadReviewTypes();
      }
    });
  }

  loadReviewTypes() {
    this.reviewTypes$ = this.store.select(state => state.ReviewTypes.data);
    this.reviewTypesSubs = this.reviewTypes$.subscribe(data => {
      if (data.length) {
        this.reviewTypes = data.filter(x => x.type == this.reviewTypeEnum && x.reviewBoardTypeID == this.reviewBoardType.id && (this.showInactive || !this.showInactive && x.status == 1)).sort((a, b) => a.order - b.order);
        this.reviewTypes.map(t => {
          t.reviews = this.reviews?.filter(r => r.reviewTypeID == t.id);
        })
        this.renumberArray();
      }
    });
  }

  changeInactive(e: any) {
    this.loadReviewTypes();
  }

  ngOnInit(): void {
    this.reviewTypeEnum = this.reviewTypesEnum.FULL;
  }

  edit(reviewType: ReviewType) {
    const confirm = this.dialog.open(ReviewsSettingsReviewTypesDetailsComponent, {
      width: '400px',
      data: {
        title: 'Modify Review Type',
        reviewBoardType: this.reviewBoardType,
        reviewType,
        reviewTypeEnum: this.reviewTypeEnum,
        disableClose: true,
        autoFocus: false
      }
    });
    confirm.afterClosed().toPromise().then(data => {
      if (data) {
        this.service.updateReviewType(this.utils.Serialize(data.reviewType)).toPromise().then(() => {
          this.alert.message('ReviewTypeUpdated');
          this.store.dispatch(new ReviewBoardTypesRefresh());
          this.store.dispatch(new ReviewTypesRefresh());
          this.store.dispatch(new ReviewsRefresh());
        });
      }
    });
  }

  delete(reviewType: ReviewType) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('ReviewTypeDeleteConfirmation').description,
        icon: 'warn'
      }
    });
    confirm.afterClosed().toPromise().then(data => {
      if (data && reviewType.id) {
        this.service.deleteReviewType(reviewType.id).toPromise().then(() => {
          this.alert.message('ReviewTypeDeleted');
          this.store.dispatch(new ReviewBoardTypesRefresh());
          this.store.dispatch(new ReviewTypesRefresh());
        });
      }
    });
  }

  add() {
    const confirm = this.dialog.open(ReviewsSettingsReviewTypesDetailsComponent, {
      width: '400px',
      data: {
        title: 'Add Review Type',
        reviewBoardType: this.reviewBoardType,
        reviewTypeEnum: this.reviewTypeEnum,
        disableClose: true,
        autoFocus: false
      }
    });
    confirm.afterClosed().toPromise().then(data => {
      if (data) {
        this.service.createReviewType(data.reviewType).toPromise().then(() => {
          this.alert.message('ReviewTypeCreated');
          this.store.dispatch(new ReviewBoardTypesRefresh());
          this.store.dispatch(new ReviewTypesRefresh());
        });
      }
    });
  }

  drop(event: CdkDragDrop<ReviewType[]>) {
    if (event?.previousIndex !== event?.currentIndex) {
      moveItemInArray(this.reviewTypes, event.previousIndex, event.currentIndex);
      this.renumberArray(true);
    }
  }

  renumberArray(update?: boolean) {
    let order = 1;
    this.reviewTypes?.map(reviewType => {
      if (reviewType.order != order) {
        reviewType.order = order;
        if (update)
          this.service.updateReviewTypeOrder(reviewType).toPromise();
      }
      order++;
    });
    if (update) {
      this.store.dispatch(new ReviewBoardTypesRefresh());
      this.store.dispatch(new ReviewTypesRefresh());
    }
  }

  radioChanged(e: any) {
    this.reviewTypeEnum = e;
    this.loadReviewTypes();
  }
}
