import { CommentResource } from "src/app/controls/comments-and-attachments/services/comment";

export class CommentRefresh {
  static readonly type = '[Comment] Refresh';
}
export class CommentRefreshByID {
  static readonly type = '[Comment] RefreshByID';
  constructor(public id: number) { }
}
export class CommentCreate {
  static readonly type = '[Comment] Create';
  constructor(public item: CommentResource) { }
}
export class CommentUpdate {
  static readonly type = '[Comment] Update';
  constructor(public id: number, public item: CommentResource) { }
}
export class CommentDelete {
  static readonly type = '[Comment] Delete';
  constructor(public id: number) { }
}
