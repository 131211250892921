import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import * as BLSActions from './branchline.actions';
import { utils } from 'src/app/modules/libs/utils';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';

export interface BranchlineStatusStateModel {
  data: Resource[];
}

@State<BranchlineStatusStateModel>({
  name: 'Branchlines',
  defaults: {
    data: []
  }
})

@Injectable()
export class BranchlineStatusState {
  constructor(public resourcesService: ResourcesService) { }

  @Action(BLSActions.Refresh)
  RefreshBranchStatus(ctx: StateContext<BranchlineStatusStateModel>) {
    this.resourcesService.getBranchlines().subscribe(result => {

      result = result.sort((a, b) => utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
      result.map(r => {
        r.moreInfo = r.documents?.join(', ');
      });
      ctx.patchState({ data: result });
    });
  }

  @Action(BLSActions.Update)
  UpdateBranchStatus(ctx: StateContext<BranchlineStatusStateModel>, { payload }: BLSActions.Update) {
    const state = ctx.getState();
    const data = utils.cloneDeep(state.data);
    const index = data.findIndex(val => val.id === payload.id);
    payload.moreInfo = payload.documents?.join(', ');
    data.splice(index, 1, payload);
    ctx.patchState({ data });
  }

  @Action(BLSActions.Add)
  AddBranchStatus(ctx: StateContext<BranchlineStatusStateModel>, { payload }: BLSActions.Add) {
    const state = ctx.getState();
    let data = utils.cloneDeep(state.data);
    payload.moreInfo = payload.documents?.join('');
    data.splice(0, 0, payload);
    data = data.sort((a, b) => utils.SortBeamlines(a.name, b.name));
    ctx.patchState({ data });
  }

  @Action(BLSActions.Delete)
  DeleteBranchStatus(ctx: StateContext<BranchlineStatusStateModel>, { payload }: BLSActions.Delete) {
    const state = ctx.getState();
    const data = utils.cloneDeep(state.data);
    const index = data.findIndex(val => val.id === payload);
    data.splice(index, 1);
    ctx.patchState({ data });
  }

}
