import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";

export class EB2AcceleratorLocation {
  public id?: number;
  public eB2ID?: number;
  public resourceID?: number;
  public resource!: Resource;
}

export class EB2AcceleratorLocationSave {
  public id?: number;
  public eB2ID?: number;
  public resourceID?: number;
}

export class EB2AcceleratorLocationHandler {
  static toSave(eb2AcceleratorLocation: EB2AcceleratorLocation): EB2AcceleratorLocationSave {
    var save = new EB2AcceleratorLocationSave();

    save.id = eb2AcceleratorLocation.id;
    save.eB2ID = eb2AcceleratorLocation.eB2ID;
    save.resourceID = eb2AcceleratorLocation.resourceID;

    return save;
  }
}
