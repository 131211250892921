<div class="side-panel-table" *ngIf="visible">
  <div class="left-table-container table-container mat-container scrollbar">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="serialNo">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="pl-16">
          Serial No.
        </mat-header-cell>
        <mat-cell
          class="font-weight-bold pl-16"
          *matCellDef="let element"
          nowrap
          ><span
            [ngClass]="element.reviewRequestStatus.color"
            [innerHTML]="element.serialNo | highlightSearch : filter"
          ></span>
          <mat-icon class="editing" color="accent" *ngIf="element.editingBy"
            >edit</mat-icon
          >
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="locationNames">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="min-130">
          Location
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          <span
            [ngClass]="element.reviewRequestStatus.color"
            [innerHTML]="element.location | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Date
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          <span
            [ngClass]="element.reviewRequestStatus.color"
            [innerHTML]="
              (utils.isValidDate(element.proposedTimeline)
                ? (element.proposedTimeline | date : 'shortDate')
                : element.proposedTimeline
              ) | highlightSearch : filter
            "
          ></span>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        #matrow
        *matRowDef="let row; columns: displayedColumns"
        (click)="rowClicked(row)"
        class="table-row"
        [matTooltip]="row.description"
        [matTooltipPosition]="'right'"
        matTooltipClass="tooltip-class"
        [ngClass]="{ 'mat-row-highlight': reviewCycle?.id == row.id }"
        [attr.position]="getPosition(row)"
      ></mat-row>
    </mat-table>
  </div>
</div>
