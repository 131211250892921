
import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'src/app/common/base/base.service';
import { ScheduleSubtype } from '../models/schedule-subtype';

@Injectable({
  providedIn: 'root'
})
export class ScheduleSubTypeService extends BaseService<ScheduleSubtype, number> {

  constructor(protected override injector: Injector,) {
    super(injector, '/ScheduleSubtypes');
  }

}
