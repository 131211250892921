import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface Color {
  name: string;
  class?: string;
}

const DATA: Color[] =
  [
    { name: 'Black' },
    { name: 'Green' },
    { name: 'Blue' },
    { name: 'Red' },
    { name: 'Yellow' },
    { name: 'Cian' },
    { name: 'Orange' },
    { name: 'Gray' },
    { name: 'Purple' },
    { name: 'Burgundy' },
    { name: 'Pink' }
  ];

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit, OnChanges {

  @Input() color?: string | null;
  @Output() selected = new EventEmitter<any>();

  colors = DATA;
  colorsFiltered = DATA;
  colorCtrl = new FormControl();
  colorSelected?: Color;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.color) this.color = 'Black';
    if (this.color) {
      this.colorSelected = this.colors.find(x => x.name.toLowerCase() == this.color);
      this.colorCtrl.setValue(this.colorSelected);
    }
  }

  ngOnInit(): void {

  }

  displayObject(e: any) {
    return e ? e.name : null;
  }


  selectedColor(e: any) {
    this.colorSelected = e.option.value;
    this.selected.emit(this.colorSelected?.name.toLowerCase());
  }

  getClass(color?: Color, background: boolean = false) {
    if (background) {
      return color?.name.toLowerCase() + '-background';
    }
    else {
      return color?.name.toLowerCase();
    }
  }

}
