export class RelatedLinkBase {
  public id?: number;
  public formID!: number;
  public text!: string;
  public url?: string;
}

export class RelatedLinkBaseSave {
  public id?: number;
  public formID!: number;
  public text!: string;
  public url?: string;
}

export class RelatedLinkBaseHandler {
  static toSave(relatedLinkBase: RelatedLinkBase): RelatedLinkBaseSave {
    var save = new RelatedLinkBaseSave();

    save.id = relatedLinkBase.id;
    save.formID = relatedLinkBase.formID;
    save.text = relatedLinkBase.text;
    save.url = relatedLinkBase.url;

    return save;
  }
}
