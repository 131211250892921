import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Checklist } from '../../checklists';
import { ChecklistsService } from '../../checklists.service';
import { ChecklistsCreate, ChecklistsDelete, ChecklistsRefresh, ChecklistsUpdate, ChecklistsRefreshByDocument, ChecklistsRefreshByDocumentMin, ChecklistsRefreshById } from './checklists.action';

export class ChecklistsStateModel {
  data: Checklist[] = [];
}

@State<ChecklistsStateModel>({
  name: 'Checklists',
  defaults: {
    data: []
  }
})
@Injectable()
export class ChecklistsState {
  constructor(
    private checklistsService: ChecklistsService
  ) { }

  @Selector()
  static read(state: ChecklistsStateModel) {
    return state.data;
  }

  @Action(ChecklistsRefresh)
  refresh({ patchState }: StateContext<ChecklistsStateModel>) {
    this.checklistsService.getAll().subscribe(
      data => {
        patchState({ data: data });
      },
      error => console.error(error)
    );
  }

  @Action(ChecklistsRefreshByDocument)
  refreshByDocument({ patchState }: StateContext<ChecklistsStateModel>, { id }: ChecklistsRefreshByDocument) {
    this.checklistsService.getAllByDocument(id).toPromise().then(
      data => {
        patchState({ data: data });
      },
      error => console.error(error)
    );
  }

  @Action(ChecklistsRefreshById)
  refreshByID({ patchState, getState }: StateContext<ChecklistsStateModel>, { id }: ChecklistsRefreshByDocument) {
    this.checklistsService.getById(id).toPromise().then(
      data => {
        const state = getState();
        const index = state.data.map(x => x.id).indexOf(id);

        if (index >= 0 && data) {
          state.data.splice(index, 1);
        }
        if (data) {
          patchState({
            data: [...state.data, data]
          });
          this.checklistsService.setExpansions(data);
        }
      },
      error => console.error(error)
    );
  }


  @Action(ChecklistsRefreshByDocumentMin)
  refreshByDocumentMin({ patchState }: StateContext<ChecklistsStateModel>, { id }: ChecklistsRefreshByDocumentMin) {
    this.checklistsService.getAllByDocumentMin(id).toPromise().then(
      data => {
        patchState({ data: data });
      },
      error => console.error(error)
    );
  }

  @Action(ChecklistsCreate)
  create({ getState, patchState }: StateContext<ChecklistsStateModel>, { item }: ChecklistsCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ChecklistsUpdate)
  update({ getState, patchState }: StateContext<ChecklistsStateModel>, { id, item }: ChecklistsUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);
    this.checklistsService.setExpansions(item);
    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ChecklistsDelete)
  delete({ getState, patchState }: StateContext<ChecklistsStateModel>, { id }: ChecklistsDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
