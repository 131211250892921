import { Component, Inject, OnDestroy, OnInit, AfterViewInit, Injector } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Option } from "src/app/components/checklists/checklists";
import { Observable, Subscription } from "rxjs";
import { ChecklistTemplate, ChecklistExecStatus } from "../../../components/checklists/checklists";
import { ComponentType, ResourceType } from "src/app/common/enumerations/enumerations";
import { Schedule } from "src/app/components/schedules/models/schedule";
import { ScheduleResource } from "src/app/components/schedules/models/schedule-resources";
import { ScheduleType } from "src/app/components/schedules/models/schedule-type";
import { WFComponent } from "src/app/components/workflow/workflow";
import { ImageUploadService } from "src/app/services/file/image-upload.service";
import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { Role } from "src/app/components/catalogs/roles/services/role";
import { WfSignature } from "src/app/services/work-flow/work-flow";
import { YesNoDialogComponent } from "../../yes-no-dialog/yes-no-dialog.component";
import { BaseComponent } from "src/app/common/base/base.component";
import { ScheduleObject } from "./cl-editor-schedule/cl-editor-schedule.component";
import { ChecklistBuilderPlaceholdersComponent } from "src/app/components/checklists/checklist-builder/checklist-builder-placeholders/checklist-builder-placeholders.component";
import { ClEditorTagInfoComponent, TagInfoData } from "./cl-editor-tag-info/cl-editor-tag-info.component";
import { BeamlineRequiringApprovalSummary } from "src/app/components/catalogs/bratoi/models/beamline-requiring-approval-summary";

@Component({
  selector: "cl-editor",
  templateUrl: "./cl-editor.component.html",
  styleUrls: ["./cl-editor.component.scss"],
})
export class ClEditorComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  t1?: string | null;
  t2?: string | null;
  t3?: string | null;
  t4?: string;

  disabled: boolean;
  components!: WFComponent[];
  filteredComponents!: WFComponent[];
  control?: WFComponent;

  // typeCtrl = new FormControl();
  // roleCtrl = new FormControl();
  name?: string | null;
  condition?: string;
  code?: string;
  color!: string;
  scheduleResourceId?: number;
  scheduleTypeId?: number;
  locationName?: string | null;
  noOptions: boolean = false;

  type!: number;
  component?: WFComponent;

  error!: boolean;

  tmpOptions!: string;
  multiple!: boolean;
  tmpHeaders!: string;
  spotRows: any[] = [
    { id: 0, posx: 0, posy: 0, spotText: "", percX: 0, percY: 0 },
  ];

  options?: Option[] = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];
  // hasRadioButtons!: boolean;
  // hasMultipleCheckboxes!: boolean;
  // hasMultipleRoles!: boolean;
  // hasMultipleTextboxes!: boolean;
  // textFields!: number;
  // numericHeader!: boolean;
  configuration?: string;
  // colorize!: boolean;

  approve?: boolean;
  unapprove?: boolean;
  disapprove?: boolean;

  allRoles!: Role[];
  filteredRoles!: Role[];

  showHtml!: boolean;
  s: WfSignature;
  formType: string;
  originalRoles: Role[];

  loading!: boolean;
  serialNo: string;

  customButton: any;

  scheduleTypes$!: Observable<ScheduleType[]>;
  scheduleTypes!: ScheduleType[];
  scheduleType?: ScheduleType;
  schedules$!: Observable<Schedule[]>;
  schedules!: Schedule[];
  schedule!: Schedule;
  subscription1!: Subscription;
  subscription2!: Subscription;
  clipboardMessage!: string;

  resources!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  components$!: Observable<WFComponent[]>;
  componentsSubs!: Subscription;

  componentType = ComponentType;

  roles: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  resourceTypes = ResourceType;

  formDirty = false;

  checklistTemplate: ChecklistTemplate;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<ClEditorComponent>,
    private _image: ImageUploadService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(injector);
    this.s = this.utils.cloneDeep(data.s);
    this.formType = data.formType;
    this.roles = this.utils.cloneDeep(data.roles);
    this.serialNo = data.serialNo;
    this.originalRoles = data.roles ? this.utils.cloneDeep(data.roles) : null;
    this.checklistTemplate = data.checklistTemplate;
    this.disabled = data.disabled;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const elem = document.getElementById("mat-dialog-0");
      if (elem) {
        elem.style.overflow = "hidden";
      }
    }, 2000);
  }

  override ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.resourcesSubs?.unsubscribe();
    this.componentsSubs?.unsubscribe();
    this.rolesSubs?.unsubscribe();
  }

  ngOnInit() {
    // this.buildForm();
    this.loadRoles();
    this.loadResources();
    this.getScheduleTypes();

    this.components$ = this.store.select((state) => state.Components.data);
    this.componentsSubs = this.components$.subscribe((data) => {
      this.components = data
        .filter((x) => x.status === 1)
        .sort((a, b) => this.compare(a, b));
      this.filteredComponents = this.components;
      if (this.components.length) {
        this.approve = this.s?.approve;
        this.unapprove = this.s?.unapprove;
        this.disapprove = this.s?.disapprove;
        this.configuration = this.utils.cloneDeep(this.s?.configuration);
        this.options = this.s?.options;
        this.tmpOptions = JSON.stringify(this.s?.options);

        if (this.s?.type) {
          this.t1 = this.s?.question;
          this.t2 = this.s?.question2;
          this.t3 = this.s?.question3;
          this.name = this.s?.name;
          this.condition = this.s?.condition;
          this.code = this.s?.code;

          this.scheduleResourceId = this.s.scheduleResourceId;
          this.locationName = this.s.locationName;
          this.scheduleTypeId = this.s.scheduleTypeId;

          if (this.s.configuration) {
            this.color = JSON.parse(this.s?.configuration).color
              ? JSON.parse(this.s?.configuration).color
              : "primary";
            this.scheduleTypeId =
              JSON.parse(this.s?.configuration).key === "scheduleType"
                ? JSON.parse(this.s?.configuration).val
                : this.scheduleTypeId;
          }
          if (!this.s.component) this.s.component = this.components.find(c => c.id == this.s.type);
          this.component = this.s.component;
          this.type = this.s.type;
          // this.typeCtrl.setValue(this.component?.id);
          this.componentType = ComponentType;
        }

        switch (this.s?.type) {
          case 8:
          case 9:
          case 24:
            this.t1 = this.s?.question2;
            this.t2 = this.s?.question;
            break;
        }
      }
    });
  }

  // buildForm() {
  //   this.formGroup = this.formBuilder.group({
  //     typeCtrl: this.typeCtrl,
  //     roleCtrl: this.roleCtrl,
  //   });
  //   if (this.disabled) {
  //     this.roleCtrl.disable();
  //     this.typeCtrl.disable();
  //   } else {
  //     this.roleCtrl.enable();
  //     this.typeCtrl.enable();
  //   }
  // }

  reset() {
    this.name = '';
    this.t1 = '';
    this.t2 = '';
    this.t3 = '';
    this.t4 = '';
    this.roles = [];
    this.configuration = '';
    this.s.configuration = '';
    this.tmpOptions = '';
    this.options = [];
  }

  loadRoles() {
    this.roles$ = this.store.select((state) => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe((data) => {
      this.allRoles = data;
      this.filterRoles();
    });
  }

  loadResources() {
    this.resources$ = this.store.select((state) => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe((data) => {
      this.resources = data;
    });
  }

  filterRoles() {
    this.filteredRoles = this.allRoles.filter(
      (x) => !this.roles?.map((r) => r.id).includes(x.id)
    );
  }

  selectedRole(role: Role) {
    if (this.roles == null) {
      this.roles = [];
    }
    // const role: Role = event.option.value;
    // this.roles.push({ id: role.id, code: role.code });
    this.s.roleCodes = this.roles.map(r => r.code).join('/');
    this.formDirty = true;
    this.filterRoles();
  }

  removeRole(role: Role) {
    // const index = this.roles.indexOf(role);
    // this.roles.splice(index, 1);
    this.s.roleCodes = this.roles.map(r => r.code).join('/');
    this.formDirty = true;
  }

  compare(a: WFComponent, b: WFComponent) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  cancel() {
    if (this.formDirty) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: "400px",
        data: {
          message: this.getMessage(
            "Rad_Survey_Cancel_Boton_Component_Properties"
          ).description,
          icon: "stop",
        },
      });
      confirm.afterClosed().subscribe((data) => {
        if (data) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  async uploadImages(textValue?: string | null) {
    if (textValue) {
      const text = await this._image.uploadImagesToServer(
        textValue,
        "/" +
        (this.formType ? this.formType.trim() : "Generic") +
        "/" +
        this.serialNo +
        "/"
      );
      this.formDirty = true;
      return text;
    }
    return '';
  }

  async accept() {
    this.s.question = this.clean(await this.uploadImages(this.t1));
    this.s.question2 = this.clean(await this.uploadImages(this.t2));
    this.s.question3 = this.clean(await this.uploadImages(this.t3));
    this.s.options = this.options;
    this.s.name = this.name;
    this.s.condition = this.condition;
    this.s.code = this.code;

    this.s.approve = this.approve;
    this.s.unapprove = this.unapprove;
    this.s.disapprove = this.disapprove;

    this.s.scheduleTypeId = this.scheduleTypeId;
    this.s.scheduleResourceId = this.scheduleResourceId;
    this.s.locationName = this.locationName;

    this.s.configuration = this.configuration;
    this.s.component = this.component;
    this.s.type = this.type;

    switch (this.s.type) {
      case 8:
      case 9:
      case 24:
        this.s.question2 = this.clean(await this.uploadImages(this.t1));
        this.s.question = this.clean(await this.uploadImages(this.t2));
        break;
    }
    this.data.s = this.s;
    this.data.roles = this.roles;
    this.data.originalRoles = this.originalRoles;
    this.dialogRef.close(this.data);
  }

  clean(t?: string | null) {
    if (t) {
      t = t
        ?.replace("<p>", "")
        .replace("</p>", "<br>")
        .replace("<strong>", "<b>")
        .replace("</strong>", "</b>");
      if (t.includes("<br>") && t.lastIndexOf("<br>") == t.length - 4) {
        t = t.slice(0, -4);
      }
    }
    return t;
  }

  html() {
    this.showHtml = !this.showHtml;
  }

  jsonOptions(o: string) {
    try {
      return JSON.parse(o);
    } catch (err) {
      return [];
    }
  }

  optionsChange(e: any) {
    this.tmpOptions = e;
    this.options = this.jsonOptions(this.tmpOptions);
    this.formDirty = true;
  }

  checkboxChanged(e: any) {
    this.configuration = JSON.stringify(e);
    this.s.configuration = this.configuration;
    this.formDirty = true;
  }

  displayType(component: { name: any } | null): string {
    return component ? component.name : '';
  }

  selectedType(e: { option: { value: WFComponent } }) {
    const value = e.option.value as WFComponent;
    this.type = value.id;
    this.component = value;
    this.reset();
    this.code = this.code && !this.code?.includes("RMON") ? this.code : value.defaultTag;

    if (this.type == 7) this.name = "Note:";
    this.formDirty = true;
  }

  scheduleChanged(e: ScheduleObject) {
    this.scheduleTypeId = e?.scheduleType?.id;
    this.scheduleResourceId = e?.schedule?.id;
    this.locationName = e?.schedule?.name;
    this.configuration = JSON.stringify({ scheduleType: e.scheduleType?.id });
    this.formDirty = true;
  }

  locationChanged(location: Resource) {
    this.s.locationName = location.name;
    this.configuration = JSON.stringify({ key: "location", val: location.id });
    this.s.configuration = this.configuration;
    this.formDirty = true;
  }

  shieldingSettingsChanged(e: any) {
    this.configuration = JSON.stringify(e);
    this.s.configuration = this.configuration;
    this.formDirty = true;
  }

  configurationChanged(e: any) {
    this.configuration = e;
    this.s.configuration = this.configuration;
    this.formDirty = true;
  }

  setOptions(options: Option[]) {
    this.tmpOptions = JSON.stringify(options);
    this.options = this.jsonOptions(this.tmpOptions);
    this.formDirty = true;
  }

  setCondition(condition: string) {
    this.condition = condition;
    this.formDirty = true;
  }

  colorChanged(color: string) {
    let configuration = this.utils.JSONparse(this.configuration);
    if (!configuration) {
      configuration = {};
    }
    configuration.color = color;
    this.color = color;
    this.configuration = this.utils.JSONstringify(configuration);
    this.s.configuration = this.configuration;
    this.formDirty = true;
  }


  filterRoles2(e: any) {
    const text = e.target.value;
    this.filterRoles();
    this.filteredRoles = this.filteredRoles.filter(x =>
      x.name?.toLowerCase().includes(text.toLowerCase()) ||
      (x.code?.toLowerCase().includes(text.toLowerCase()) &&
        !this.roles?.map((c) => c.id).includes(x.id))
    );
  }

  filterControls(e: any) {
    const value = e.target.value;
    this.filteredComponents = this.components.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
  }

  async getScheduleTypes() {
    this.scheduleTypes$ = this.store.select(
      (state) => state.ScheduleTypes.data
    );
    this.subscription1 = this.scheduleTypes$.subscribe(async (data) => {
      this.scheduleTypes = data;
      this.scheduleType = data.find((x) => x.id == this.scheduleTypeId);
      this.scheduleTypeId = this.scheduleType?.id;
      await this.getSchedules();
    });
  }

  async getSchedules() {
    this.schedules$ = this.store.select((state) => state.Schedules.data);
    this.subscription2 = this.schedules$.subscribe((data) => {
      const schedules: any[] = [];
      data.map((schedule) => {
        if (
          schedule.statusId != 2 &&
          schedule.typeId == this.scheduleType?.id
        ) {
          schedule.scheduleResources.map((resource) => {
            const scheduleResource: ScheduleResource = {
              id: resource.id,
              name: resource.resourceId
                ? resource.resource?.name
                : resource.resourceString,
              resourceId: resource.resourceId ? resource.resourceId : 0,
              dateValue1: schedule.lastCompleted,
              dateValue2: schedule.nextDue,
              dateValue3: schedule.extendedDate,
            };
            schedules.push(scheduleResource);
          });
          this.schedule = schedules.find(
            (x) => x.id == this.scheduleResourceId
          );
          this.scheduleResourceId = this.schedule.id;
        }
      });
    });
  }

  headersChange(e: string) {
    if (!this.configuration) {
      this.configuration = e;
    }
    const configuration = this.utils.cloneDeep(JSON.parse(this.configuration));
    const newConfig = JSON.parse(e);
    configuration.headers = newConfig.headers;
    configuration.data = newConfig.data;
    configuration.columnsBuilder = newConfig.columnsBuilder;
    configuration.columnsChecklist = newConfig.columnsChecklist;
    configuration.columns = newConfig.columns;
    configuration.widths = newConfig.widths;
    configuration.spots = newConfig.spots;
    configuration.colorize = newConfig.colorize;
    configuration.includeIM = newConfig.includeIM;
    configuration.includeIMPos = newConfig.includeIMPos;
    configuration.images = newConfig.images;
    configuration.channels = newConfig.channels;
    configuration.channelsData = newConfig.channelsData;
    this.configuration = JSON.stringify(configuration);
    this.s.configuration = this.configuration;
    this.formDirty = true;
  }

  change(e: string) {
    let configuration: any;
    if (!this.configuration) {
      configuration = JSON.parse(e);
    } else {
      configuration = JSON.parse(this.configuration);
      const newConfiguration = JSON.parse(e);
      const data = newConfiguration.data;
      const spots = newConfiguration.spots;
      const widths = newConfiguration.widths;
      const images = newConfiguration.images;
      if (data) configuration.data = data;
      if (spots) configuration.spots = spots;
      if (widths) configuration.widths = widths;
      if (images) configuration.images = images;
    }
    this.configuration = this.utils.cloneDeep(JSON.stringify(configuration));
    if (this.s.configuration != this.configuration) {
      this.s.configuration = this.configuration;
      this.formDirty = true;
    }
  }

  statusSelected(configuration: any) {
    if (!configuration) {
      configuration = {};
    }
    this.configuration = this.utils.cloneDeep(this.utils.JSONstringify(configuration));
    this.s.configuration = this.configuration;
    this.formDirty = true;
  }

  actionStatusSelected(status: ChecklistExecStatus, action: number) {
    let configuration = this.utils.JSONparse(this.configuration);
    if (!configuration) {
      configuration = {};
    }
    switch (action) {
      case 1:
        configuration.approveNextStatus = status;
        break;
      case 2:
        configuration.unapproveNextStatus = status;
        break;
      case 3:
        configuration.disapproveNextStatus = status;
        break;
    }

    this.configuration = this.utils.cloneDeep(this.utils.JSONstringify(configuration));
    this.s.configuration = this.configuration;
    this.formDirty = true;
  }

  BRATOIChanged(blRequiring: BeamlineRequiringApprovalSummary) {
    const configuration = blRequiring;
    this.configuration = JSON.stringify(configuration);
    this.s.configuration = this.configuration;
    this.formDirty = true;
  }

  setRoleByCode(roleCode: string) {
    if (this.allRoles?.length) {
      const role = this.allRoles.find((x) => x.code === roleCode);
      if (role?.id && !this.roles.find((x) => x.id === role?.id)) {
        this.roles.push({ id: role?.id, code: role?.code });
        this.filterRoles();
      }
    }
  }

  placeholders() {
    if (this.checklistTemplate && !this.checklistTemplate?.resource) {
      this.checklistTemplate.resource = this.resources.find(
        (x) => x.id == this.checklistTemplate.resourceID
      );
    }
    const settings = this.dialog.open(
      ChecklistBuilderPlaceholdersComponent,
      {
        minHeight: "500px",
        data: { resource: this.checklistTemplate?.resource },
        disableClose: true,
        autoFocus: false,
      }
    );
    settings.afterClosed().subscribe((data) => {
      this.clipboardMessage = "Placeholder <b>" + data + "</b> copied to cliboard";
      setTimeout(() => {
        const placeholderMessage = document.getElementById("placeholderMessage");
        if (placeholderMessage) {
          placeholderMessage.style.opacity = "1";
          const timerId = setInterval(() => {
            // start interval loop
            const opacity = placeholderMessage.style.opacity; // get current opacity
            if (+opacity == 0) {
              // check if its 0 yet
              clearInterval(timerId); // if so, exit from interval loop
            } else {
              placeholderMessage.style.opacity = (+opacity - 0.05).toString(); // else remove 0.05 from opacity
            }
          }, 100); // run every 0.1 second
        }
      }, 5000);
    });
  }

  placeHolderSelected(placeholder: string) {
    let configuration = this.utils.JSONparse(this.configuration);
    if (!configuration) {
      configuration = {};
    }
    configuration.placeholder = placeholder;
    this.configuration = this.utils.JSONstringify(configuration);
    this.s.configuration = this.configuration;
    this.formDirty = true;
  }

  radMonitorConfigChanged(radMonitorConfig: any) {
    this.configuration = JSON.stringify(radMonitorConfig);
    this.s.configuration = this.configuration;
    this.formDirty = true;
  }

  radMeterConfigChanged() {
    this.s.configuration = this.configuration;
    this.formDirty = true;
  }

  textChanged(e: string, number: any) {
    switch (number) {
      case 1:
        this.t1 = e;
        break;
      case 2:
        this.t2 = e;
        break;
      case 3:
        this.t3 = e;
        break;
      case 4:
        this.t4 = e;
        break;
    }
    this.formDirty = true;
  }

  actionChanged() {
    this.formDirty = true;
  }

  showTagInfo() {
    const tagBox = document.getElementById("tagBox")?.getBoundingClientRect();
    if (tagBox) {
      const dialog = this.dialog.open(ClEditorTagInfoComponent, {
        data: {
          component: this.component,
          tag: this.code,
          multi: this.utils.JSONparse(this.configuration)?.multiple,
        } as TagInfoData,
        disableClose: false,
        autoFocus: false,
        width: tagBox.width - 50 + "px",
        hasBackdrop: false,
        position: { left: tagBox.left + 50 + "px", top: tagBox.top + 50 + "px" },
      });
    }
  }

  checkAcceptDisabled() {
    if (this.error) { return true; }
    if (this.component?.needRoles && !this.component.hasMultipleRoles && !this.roles?.length) { return true; }
    return false;
  }
}
