import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import * as ProcedureTrainingActions from './procedure-training.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';
import { MissingTrainingRefresh } from '../procedure-missing-training/procedure-missing-training.action';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { Subscription } from 'rxjs';
import { AppStateService } from 'src/app/store/app-state-service';

@Injectable({
  providedIn: 'root'
})
export class ProcedureTrainingStoreAndListenersService {
  currentUser?: User | null;
  subscription!: Subscription;

  constructor(private store: Store, private signalRService: SignalRService, private ass: AppStateService) { }
  initStore() {
    this.store.dispatch(new ProcedureTrainingActions.ProcedureTrainingRefresh());
    this.subscription = this.ass.AppState.subscribe(appState => {
      this.currentUser = appState.currentUser;
    });
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.PROCEDURETRAINING, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new ProcedureTrainingActions.ProcedureTrainingCreate(data.obj));
          this.store.dispatch(new MissingTrainingRefresh(this.currentUser?.id ?? 0));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new ProcedureTrainingActions.ProcedureTrainingUpdate(data.id, data.obj));
          this.store.dispatch(new MissingTrainingRefresh(this.currentUser?.id ?? 0));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new ProcedureTrainingActions.ProcedureTrainingDelete(data.id));
          this.store.dispatch(new MissingTrainingRefresh(this.currentUser?.id ?? 0));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new ProcedureTrainingActions.ProcedureTrainingRefresh());
          this.store.dispatch(new MissingTrainingRefresh(this.currentUser?.id ?? 0));
      }
    });

  }
}
