import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SiteConfiguration } from './site-configuration';

@Injectable({
  providedIn: 'root'
})
export class SiteConfigurationService {

  constructor(private http: HttpClient) { }

  getById(id: number): Observable<SiteConfiguration> {
    return this.http.get<SiteConfiguration>(environment.urls.baseApiUrl + '/SiteConfiguration/' + id);
  }
  getFirst(): Observable<SiteConfiguration> {
    return this.http.get<SiteConfiguration>(environment.urls.baseApiUrl + '/SiteConfiguration');
  }
  update(value: SiteConfiguration) {
    return this.http.put<SiteConfiguration>(environment.urls.baseApiUrl + '/SiteConfiguration', value);
  }
}
