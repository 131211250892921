<div class="card">
  <div class="card-header">
    <span class="card-title">SCF Settings</span>
    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <div class="card-body min-60">
    <div class="row">
      <div class="col">
        <div class="overlay" *ngIf="isLoading"></div>
        <mat-tab-group animationDuration="0ms" [disabled]="isLoading">
          <mat-tab label="Permissions">
            <scf-v2-settings-permissions
              (isLoading)="setLoading($event)"
            ></scf-v2-settings-permissions
          ></mat-tab>
          <mat-tab label="Signatures">
            <scf-v2-settings-signatures
              (isLoading)="setLoading($event)"
            ></scf-v2-settings-signatures>
          </mat-tab>
          <mat-tab label="Notifications">
            <scf-v2-settings-notifications
              (isLoading)="setLoading($event)"
            ></scf-v2-settings-notifications>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
