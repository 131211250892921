import { PV } from "../pv-info";

export class PVRefresh {
  static readonly type = '[PVResource] Refresh';
}
export class PVCreate {
  static readonly type = '[PVResource] Create';
  constructor(public item: PV) { }
}
export class PVUpdate {
  static readonly type = '[PVResource] Update';
  constructor(public id: number, public item: PV) { }
}
export class PVDelete {
  static readonly type = '[PVResource] Delete';
  constructor(public id: number) { }
}

export class PVRefreshByID {
  static readonly type = '[PVResource] RefreshByID';
  constructor(public id: number) { }
}
