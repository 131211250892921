import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'src/app/common/base/base.service';
import { BeamlineRestriction } from '../models';

@Injectable({
  providedIn: 'root'
})
export class BeamlineRestrictionService extends BaseService<BeamlineRestriction, number> {

  api = '/BeamlineRestrictions';

  constructor(
    protected override injector: Injector,
  ) {
    super(injector, '/BeamlineRestrictions');
  }

  getByBeamlineID(id: number) {
    return this.http.get<BeamlineRestriction[]>(this.BASE_URL + this.api + '/ByBeamlineID/' + id);
  }

  cancel(id: number) {
    return this.http.delete<BeamlineRestriction[]>(this.BASE_URL + this.api + '/Cancel/' + id);
  }

  approve(id: number, approve: boolean, reason?: string) {
    return this.http.put<BeamlineRestriction[]>(this.BASE_URL + this.api + '/Approve/' + id + '/' + approve + '/' + reason, null);
  }
}
