<div class="d-print-flex">
  <mat-form-field>
    <mat-label>{{ placeholder }}</mat-label>
    <mat-chip-grid #rsswaOtherSystemChips [disabled]="disabled">
      <mat-chip-row
        *ngFor="let rsswaOtherSystem of rsswaOtherSystems"
        [selectable]="true"
        [removable]="true"
        (removed)="remove(rsswaOtherSystem)"
      >
        {{ rsswaOtherSystem.text }}
        <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
      </mat-chip-row>
      <input
        #rsswaOtherSystemInput
        [formControl]="control"
        [matChipInputFor]="rsswaOtherSystemChips"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-grid>
  </mat-form-field>
</div>
