import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, ViewChildren, OnChanges, OnDestroy, SimpleChanges, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ComponentType, FormType } from 'src/app/common/enumerations/enumerations';
import { ActionItem } from 'src/app/common/models/action-item';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { WFSectionLocalResource, WfSignatureResource } from 'src/app/services/work-flow/work-flow';
import { AbsiResource } from '../../absi';
import { AbsiSectionXComponent } from './absi-section-x/absi-section-x.component';

@Component({
  selector: 'absi-section-wrapper',
  templateUrl: './absi-section-wrapper.component.html',
  styleUrls: ['./absi-section-wrapper.component.scss']
})
export class AbsiSectionWrapperComponent implements OnInit, OnChanges, OnDestroy {

  @Input() section?: WFSectionLocalResource;
  @Input() absi?: AbsiResource | null;

  @Output() requestRefresh = new EventEmitter<any>();
  @Output() loading = new EventEmitter<boolean>();

  @ViewChild('section') private sectionComponent!: AbsiSectionXComponent;

  sections?: WFSectionLocalResource[];
  sections$!: Observable<WFSectionLocalResource[]>;
  sectionsSubs!: Subscription;

  actionItems$!: Observable<ActionItem[]>;
  actionItemsSubs!: Subscription;
  actionItems!: ActionItem[];

  date?: Date | null;
  user?: User | null = new User();
  catl!: number;

  formType = FormType.ABSI;
  sectionLoading = false;
  isLoading?: boolean;

  @ViewChildren('panel', { read: ElementRef }) panels: any;

  constructor(
    private store: Store
  ) {

  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.actionItemsSubs?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.setLoading(true);;
    this.sectionLoading = false;
    this.getSection();
  }

  panelOpen(val: any) {
    if (this.section)
      this.section.expanded = val;
  }

  getActionItems() {
    this.actionItems$ = this.store.select(state => state.ActionItems.ActionItems);
    this.actionItemsSubs = this.actionItems$.subscribe(async data => {
      this.actionItems = data.filter(x => x.wfSectionId == this.section?.id);
      if (this.section?.wfSignatures) {
        const wfSignature = this.section.wfSignatures?.find(x => x.type == ComponentType.ButtonSignature);
        if (wfSignature) this.showInfo(wfSignature);
      }
    });
  }

  getSection() {
    if (this.section)
      this.sections$ = this.store.select(state => state.AbsiSections.data);
    this.sectionsSubs = this.sections$.subscribe((data: WFSectionLocalResource[]) => {
      const sectionInfo = data.find(x => x.id == this.section?.id);
      if (sectionInfo) {
        sectionInfo.expanded = this.section?.expanded ?? false;
        this.section = sectionInfo;
        this.getActionItems();
      }
    });
  }

  create() {
    this.sectionComponent.create();
  }

  showInfo(wfSignature: WfSignatureResource) {
    this.user = wfSignature.signedBy;
    this.date = wfSignature.signedOn;
    this.catl = this.actionItems?.length;
  }

  setLoading(value: boolean) {
    setTimeout(() => {
      this.isLoading = value;
      this.loading.emit(value);
    }, 100);
  }

}
