<div class="container-fluid mt-2 pb-3">
  <div #table class="mat-container scrollbar">
    <mat-table
      [dataSource]="dataSourceRadLocation"
      matSort
      (matSortChange)="sortData($event)"
    >
      <ng-container matColumnDef="locationName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Location Name
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span [innerHTML]="element.name | highlightSearch : filter"></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="
              element.radLocationType?.name | highlightSearch : filter
            "
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rpixName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          RPIx Name
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="element.rpixName | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Description
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="element.description | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="controllerLocation">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Controller Location
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="element.controllerLocation | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="controllerPower">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Controller Power Switch
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="element.controllerPower | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="resetLocation">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Reset Location
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="element.resetLocation | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="interlockChain">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Interlock Chain
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="element.interlockNames | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="summation">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Summation Chassis Location
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="element.summation | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="tripsetA1">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Tripset A1 mR/h
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <div *ngIf="element.tripsetA1">
            <span
              [innerHTML]="element.tripsetA1 | highlightSearch : filter"
            ></span>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="tripsetI1">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Tripset I1 mR
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <div *ngIf="element.tripsetI1">
            <span
              [innerHTML]="element.tripsetI1 | highlightSearch : filter"
            ></span>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="tripset">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Tripset mR/h
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <div *ngIf="element.tripset">
            <span
              [innerHTML]="element.tripset | highlightSearch : filter"
            ></span>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="notes">
        <mat-header-cell *matHeaderCellDef> Notes </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <notes
            [notes]="element.notes"
            [canDelete]="true"
            (delete)="deleteNote($event)"
          ></notes>
          <notes
            [type]="2"
            [notes]="element.notesf"
            [canDelete]="true"
            (delete)="deleteNote($event)"
          ></notes>
          <!-- <div class="cell-container w-100">
            <div class="cell">
              <button
                mat-button
                *ngIf="element.normalNotes?.length"
                class="normalNote"
                [matMenuTriggerFor]="notes"
                aria-label="Rad Monitor Notes"
              >
                {{ element.normalNotes?.length }}
              </button>
              <mat-menu #notes="matMenu">
                <div class="scrollbar note-menu">
                  <div *ngFor="let radNote of element.normalNotes">
                    <div class="note">
                      <div class="normalNoteHeader">
                        {{ radNote.createdByUser?.name }}
                        <span class="small float-right pb-2">{{
                          radNote.createdOn | date : "shortDate"
                        }}</span>
                      </div>
                      <div class="normalNoteDescription">
                        {{ radNote.description }}
                        <button
                          mat-icon-button
                          (click)="deleteNote(radNote)"
                          *ngIf="hasPrivilege(privilege.DeleteRadLocationNote)"
                        >
                          <mat-icon color="warn">delete_outline</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-menu>
            </div>
          </div> -->
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="faultNotes">
        <mat-header-cell *matHeaderCellDef> Fault Notes </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="cell-container w-100">
            <div class="cell">
              <button
                mat-button
                *ngIf="element.faultNotes?.length"
                class="faultNote"
                [matMenuTriggerFor]="notes"
                aria-label="Rad Monitor Notes"
              >
                {{ element.faultNotes?.length }}
              </button>
              <mat-menu #notes="matMenu">
                <div class="scrollbar note-menu">
                  <div *ngFor="let radNote of element.faultNotes">
                    <div class="note">
                      <div class="faultNoteHeader">
                        {{ radNote.createdByUser?.name }}.&nbsp;{{
                          radNote.monitorName
                        }}&nbsp;{{ radNote.locationName }}
                        <span class="small float-right pb-2">{{
                          radNote.createdOn | date : "shortDate"
                        }}</span>
                      </div>
                      <div class="faultNoteDescription">
                        <div class="row">
                          <div class="col-md-9 pr-0">
                            <div [innerHTML]="radNote.description"></div>
                          </div>
                          <div class="col-md-3">
                            <button
                              mat-icon-button
                              (click)="deleteNote(radNote)"
                              *ngIf="
                                hasPrivilege(
                                  privilege.DeleteRadMonitorFaultNote
                                )
                              "
                            >
                              <mat-icon color="warn">delete_outline</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-menu>
            </div>
          </div>
        </mat-cell>
      </ng-container> -->

      <ng-container matColumnDef="emShop">
        <mat-header-cell *matHeaderCellDef>
          {{ headerEMShop }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <mat-checkbox
            [(ngModel)]="element.isEMShop"
            (change)="setEMShop($event.checked, element)"
          ></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="options">
        <mat-header-cell *matHeaderCellDef> Options </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div *ngIf="validateShowMenu()">
            <div class="cell pt-1 divMenu">
              <button
                mat-icon-button
                color="primary"
                [matMenuTriggerFor]="menu"
                aria-label="Rad Location Menu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  *ngIf="hasPrivilege(privilege.EditRadLocation)"
                  (click)="locationDialog(element)"
                >
                  <mat-icon class="yellow">edit</mat-icon>
                  <span>Edit</span>
                </button>

                <button
                  mat-menu-item
                  *ngIf="hasPrivilege(privilege.CreateRadLocationNote)"
                  (click)="noteDialog(element, radNoteType.Normal)"
                >
                  <mat-icon class="orange">note_add</mat-icon>
                  <span>Create Note</span>
                </button>

                <button
                  mat-menu-item
                  *ngIf="hasPrivilege(privilege.DeleteRadLocation)"
                  (click)="deleteLocation(element)"
                >
                  <mat-icon class="red">delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row
        #header
        *matHeaderRowDef="displayedColumnsRadMonitor; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumnsRadMonitor"
      ></mat-row>
    </mat-table>
  </div>
</div>
