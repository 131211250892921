import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PlaceholdersService } from 'src/app/services/placeholders/placeholders.service';


@Pipe({
  name: 'placeholderHighlight'
})
export class PlaceholderHighlightPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer,
    private placeholdersService: PlaceholdersService) { }

  transform(value: string, editor: boolean = false): SafeHtml {
    if (value) {
      const res = value?.replace(new RegExp('\{([a-z,A-Z,0-9]+)\}', 'ig'), match => {
        value = this.placeholdersService.getValue(match);
        if (value !== undefined && !editor) {
          return '<b style="border-bottom: 1px dotted #ddd; color: #285E87">' + value + '</b>';
        }
        else {
          return '<b style="color: #E04E39">' + match + '</b>';
        }
      });
      return this.sanitizer.bypassSecurityTrustHtml(res);
    }
    else {
      return '';
    }
  }
}
