<div class="popup-body">
  <div class="logo">
    <img src="../../assets/images/berkeley_logo.png" alt="Berkeley Logo" />
  </div>

  <br />
  <h5>ASCC Portal Login</h5>
  <div class="alert">
    <!-- <alert-login></alert-login> -->
    &nbsp;
  </div>
  <div *ngIf="dropdown" class="form-container mb-3">
    <mat-form-field>
      <mat-select
        name="user"
        (selectionChange)="selected($event)"
        ng-disabled="loading"
      >
        <mat-option *ngFor="let e of usersFiltered" [value]="e.id">
          <span class="option">
            {{ e.name }}&nbsp;<span class="small"
              >({{ e.roleCodes }})</span
            ></span
          ></mat-option
        >
      </mat-select>
    </mat-form-field>
    <span
      *ngIf="loading && dropdown"
      style="position: relative; top: -32px; left: 10px"
    >
      <span class="spinner-border spinner-border-sm mr-1"></span> Loading
      Users....
    </span>
  </div>
  <!-- <div *ngIf="!dropdown"  class="form-container" #login>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

        <mat-form-field>
          <mat-label >Username</mat-label>
          <input matInput type="text" [formControl]="usernameCtrl" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
          <div *ngIf="submitted && f.['username'].errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Username is required</div>
          </div>
        </mat-form-field>
        <mat-form-field>
          <mat-label for="password">Password</mat-label>
          <input matInput type="password" [formControl]="passwordCtrl" formControlName="password" [(ngModel)]="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
          </div>
        </mat-form-field>
        <div class="form-group">
          <div class="row">
            <div class="col-8">
              <button mat-flat-button color="warn" type="button" (click)="cancel()" mat-dialog-close>Cancel</button>
            </div>
            <div class="col-4 login-col float-right">
              <button mat-flat-button [disabled]="loading || loginForm.invalid" class="float-right btn-login" color="primary">
                <span *ngIf="loading && !dropdown" class="spinner-border spinner-border-sm mr-1"></span>
                Login
              </button>
            </div>
          </div>
        </div>
      </form>
    </div> -->
  <!-- <div class="bottom" *ngIf="toggleVisible" >
      <a (click)="toggle()" >Toggle mode</a>
    </div> -->
</div>
