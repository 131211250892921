// import { WFSectionLocalResource } from '../../../../Services/WorkFlow/work-flow';
// import { FormType } from "src/app/_interface/Enumerations";
// import { FormStatus } from 'src/app/_interface/Form/FormStatus';

import { FormStatus } from "src/app/services/forms/form-status";

export class FormStatusesRefresh {
  static readonly type = '[FormStatuses] Refresh';
}

export class FormStatusesCreate {
  static readonly type = '[FormStatuses] Create';

  constructor(public item: FormStatus) { }
}

export class FormStatusesUpdate {
  static readonly type = '[FormStatuses] Update';

  constructor(public id: number, public item: FormStatus) { }
}

export class FormStatusesDelete {
  static readonly type = '[FormStatuses] Delete';
  constructor(public id: number) { }
}
