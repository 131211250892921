<div class="pt-2 mt-3 sectionx" id="section4" *ngIf="signatures?.length">
  <div class="row">
    <div class="col-6">
      <h5 id="Section5Title" class="section-title">Operational Approval</h5>
    </div>
    <div
      class="col-6 warning"
      *ngIf="
        scfMaster?.status == formStatusEnum.Amendment ||
        scfMaster?.status == formStatusEnum.RestrictionsAmendment
      "
    >
      {{ message }}
    </div>
  </div>

  <div *ngFor="let s of signatures">
    <div class="row no-gutters">
      <div
        class="col-12 p-1"
        [ngClass]="{
          hilite: scfMaster && this.status?.isViewingAmendment && diffValue(s)
        }"
      >
        <master-control
          [s]="s"
          [type]="s.type"
          [visible]="true"
          [disabled]="s.disabled || closeDisabled(s)"
          [color]="'primary'"
          [header]="s.name"
          [question]="s.question"
          [questionDisabled]="
            !hasPrivilege ||
            scfMaster?.status == formStatusEnum.Closed ||
            scfMaster?.status == formStatusEnum.Canceled ||
            s.approved ||
            scfMaster?.status == formStatusEnum.Amendment ||
            scfMaster?.status == formStatusEnum.RestrictionsAmendment
          "
          [label]="s.name"
          [text]="s.question"
          [radioQuestion]="s.question2"
          [radioOptions]="s.options"
          [radioSelection]="s.value2"
          [checkbox_checked]="s.value"
          [approved]="s.approved"
          [approvedBy]="s.approvedBy"
          [approvedOn]="s.approvedOn"
          [showApprove]="s.approve"
          [showDisapprove]="s.disapprove"
          [showUnapprove]="s.unapprove"
          [roleIds]="s.roles"
          [roleCodes]="s.roleCodes"
          [textBefore]="s.question"
          [textAfter]="s.question2"
          [textAfter2]="s.question3"
          [textValue]="s.textValue"
          [checkboxVisible]="s.visible ?? false"
          [disableEdit]="s.disableEdit"
          [warning]="s.warning ?? false"
          [moreInfo]="s.moreInfo"
          [scheduleResourceId]="s.scheduleResourceId"
          [dateValue1]="s.dateValue1"
          [dateValue2]="s.dateValue2"
          [dateValue3]="s.dateValue3"
          [scheduleName]="s.scheduleName"
          [locationName]="s.locationName"
          [configuration]="s.configuration"
          [userNames]="s.moreInfo"
          [formType]="'SCF'"
          [formId]="scfMaster?.id"
          [status]="s.status"
          [sectionNumber]="4"
          [taskId]="s.taskId"
          [serialNo]="scfMaster?.serialNo"
          [followUpChanged]="s.followUpChanged"
          [privilegeToApproveAnswer]="s.privilegeToApproveAnswer"
          [answerChangedById]="s.answerChangedById"
          [answerChangedBy]="s.answerChangedBy"
          [rolesToApproveChanges]="['RWAPI']"
          (approve)="sign(s.taskId, s.approveId)"
          (unapprove)="sign(s.taskId, s.unapproveId)"
          (disapprove)="sign(s.taskId, s.disapproveId)"
          (checked)="check($event, s.id)"
          (approveAnswer)="approve(s.id, true)"
          (disapproveAnswer)="approve(s.id, false)"
          (close)="close(s.taskId, s.approveId)"
          (changed)="textChanged($event, s.id)"
          (loading)="loading.emit($event)"
        >
        </master-control>
      </div>
    </div>
  </div>
</div>
