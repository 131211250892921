<form [formGroup]="formGroup">
  <div class="container-fluid mt-4" *ngIf="menuItem">
    <div class="row">
      <div class="col d-flex align-items-center">
        <h5 class="mr-3">{{ label }}</h5>
        <span class="d-flex align-items-center">
          <mat-slide-toggle
            (change)="changed($event, 'visibleToggleCtrl')"
            color="primary"
            formControlName="visibleToggleCtrl"
            >Visible
          </mat-slide-toggle>
        </span>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-sm-6 col-md-4">
        <mat-form-field>
          <mat-label>Label</mat-label>
          <input
            matInput
            type="'text'"
            formControlName="labelCtrl"
            (change)="changed($event, 'labelCtrl')"
          />
          <mat-error *ngIf="labelCtrl?.invalid">{{
            getErrorMsg(labelCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <mat-form-field>
          <mat-select
            required
            formControlName="menuItemTypeCtrl"
            (selectionChange)="changed($event, 'menuItemTypeCtrl')"
          >
            <mat-option
              [value]="menuItemType.id"
              *ngFor="let menuItemType of menuItemTypes"
              >{{ menuItemType.name }}</mat-option
            >
          </mat-select>
          <mat-label>Type</mat-label>
          <mat-error *ngIf="menuItemTypeCtrl?.invalid">{{
            getErrorMsg(menuItemTypeCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <role-selection
          [roles]="roles"
          [control]="rolesCtrl"
          (changed)="changed($event, 'rolesCtrl')"
          hint="If Empty, Any role can view this Menu Item"
        ></role-selection>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <mat-form-field>
          <mat-label>Acronym</mat-label>
          <input
            matInput
            type="'text'"
            formControlName="acronymCtrl"
            (change)="changed($event, 'acronymCtrl')"
          />
        </mat-form-field>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <mat-form-field *ngIf="menuItem.type == menuItemType.Route">
          <mat-label>Route</mat-label>

          <input
            #routeInput
            matInput
            type="'text'"
            formControlName="routeCtrl"
            [matAutocomplete]="autoRoute"
            (change)="changed($event, 'routeCtrl'); validateRoute()"
          />

          <mat-autocomplete
            autoActiveFirstOption
            #autoRoute="matAutocomplete"
            [displayWith]="displayRoute"
          >
            <mat-option
              class="route"
              (onSelectionChange)="changed($event, 'routeCtrl')"
              *ngFor="let route of routes"
              [value]="route"
            >
              <div class="d-flex justify-content-between">
                <span>{{ route.path }}</span>
                <span class="small blue">{{
                  "[" + route.component?.name + "]"
                }}</span>
              </div>
            </mat-option>
          </mat-autocomplete>
          <mat-error [innerHTML]="getRouteErrorMsg()"> </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="menuItem.type == menuItemType.Table">
          <mat-select
            required
            formControlName="tableCtrl"
            (selectionChange)="changed($event, 'tableCtrl')"
          >
            <mat-option [value]="table" *ngFor="let table of tables">{{
              table
            }}</mat-option>
          </mat-select>
          <mat-label>Table Name</mat-label>
          <mat-error *ngIf="tableCtrl?.invalid">{{
            getErrorMsg(tableCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-sm-6 col-md-4" [ngClass]="{ 'mt--8': helpFile }">
        <file-upload-v2
          (fileIDChange)="setFileID($event)"
          [path]="routeCtrl?.value?.path"
          [fileHelp]="true"
          [includeFileName]="true"
          [file]="helpFile"
          [fileID]="menuItem.fileV2Id"
          [buttonLabel]="'Upload Help File'"
        ></file-upload-v2>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <mat-form-field class="pt-2 p-0" appearance="outline">
          <mat-label>Parent Menu</mat-label>
          <input
            matInput
            type="text"
            #typeInput
            formControlName="parentCtrl"
            [matAutocomplete]="autoType"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #autoType="matAutocomplete"
            [displayWith]="displayObject"
          >
            <mat-optgroup
              *ngFor="let group of menuItemGroups"
              [label]="group.name ?? ''"
            >
              <mat-option
                (onSelectionChange)="changed($event, 'parentCtrl')"
                *ngFor="let menuItem of getGroupItems(group)"
                [value]="menuItem"
              >
                {{ menuItem?.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
          <mat-hint *ngIf="!parentCtrl?.value"
            >If not Parent Menu selected, this Item will be on the Main
            Menu</mat-hint
          >
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <fieldset class="field-group" *ngIf="menuItem.route?.includes('/')">
          <legend>Valid Routes</legend>

          <div class="row" *ngFor="let item of getRouteTable()?.helpers">
            <div class="col-6 small">
              <a
                (click)="
                  routeCtrl?.setValue({ path: item.route });
                  changed(routeCtrl?.value, 'routeCtrl')
                "
                matTooltip="Select"
                >{{ item.route }}</a
              >
            </div>
            <div class="col-6 small">
              {{ item.description }}
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</form>
