<div [style.max-height]="maxHeight">
  <div class="container-fluid p-0">
    <div class="row no-gutters">
      <div class="col-md-12 col-lg-7"></div>
      <div class="col-md-12 col-lg-5 mb-2">
        <span class="small float-right"
          ><mat-slide-toggle
            color="warn"
            (change)="changeClosed()"
            [(ngModel)]="showClosed"
            >Show Closed/Canceled KE</mat-slide-toggle
          ></span
        >
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="left-table-container mat-container scrollbar">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="serialNo">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            No.
          </mat-header-cell>
          <mat-cell
            class="font-weight-bold"
            [ngClass]="getColor(element)"
            *matCellDef="let element"
            nowrap
            ><span
              [innerHTML]="element.serialNo | highlightSearch : filter"
            ></span
          ></mat-cell>
        </ng-container>

        <ng-container matColumnDef="shutterName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Shutter
          </mat-header-cell>
          <mat-cell
            class="pl-1"
            [ngClass]="getColor(element)"
            *matCellDef="let element"
          >
            <span
              [innerHTML]="element.shutterName | highlightSearch : filter"
            ></span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="typeName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Type
          </mat-header-cell>
          <mat-cell
            class="pl-1"
            [ngClass]="getColor(element)"
            *matCellDef="let element"
          >
            <span
              [innerHTML]="element.typeName | highlightSearch : filter"
            ></span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="statusName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Status
          </mat-header-cell>
          <mat-cell
            class="pl-1"
            [ngClass]="getColor(element)"
            *matCellDef="let element"
          >
            <span
              [innerHTML]="element.statusName | highlightSearch : filter"
            ></span>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row
          #matrow
          *matRowDef="let row; columns: displayedColumns"
          (click)="selectRow(row)"
          class="table-row"
          [ngClass]="{
            'mat-row-highlight': selectedRowIndex == row.id
          }"
          [attr.position]="getPosition(row)"
        ></mat-row>
      </mat-table>
    </div>
  </div>
</div>
