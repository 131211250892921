import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ReviewActionItemsCreate, ReviewActionItemsDelete, ReviewActionItemsRefresh, ReviewActionItemsUpdate } from './review-action-items.action';
import { ReviewsService } from '../../reviews.service';
import { ReviewActionItem } from '../../reviews.models';

export class ReviewActionItemsStateModel {
  data: ReviewActionItem[] = [];
}

@State<ReviewActionItemsStateModel>({
  name: 'ReviewActionItems',
  defaults: {
    data: []
  }
})
@Injectable()
export class ReviewActionItemsState {
  constructor(
    private _service: ReviewsService
  ) { }

  @Selector()
  static read(state: ReviewActionItemsStateModel) {
    return state.data;
  }

  @Action(ReviewActionItemsRefresh)
  refresh({ patchState }: StateContext<ReviewActionItemsStateModel>) {
    this._service.getReviewActionItems().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ReviewActionItemsCreate)
  create({ getState, patchState }: StateContext<ReviewActionItemsStateModel>, { item }: ReviewActionItemsCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ReviewActionItemsUpdate)
  update({ getState, patchState }: StateContext<ReviewActionItemsStateModel>, { id, item }: ReviewActionItemsUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ReviewActionItemsDelete)
  delete({ getState, patchState }: StateContext<ReviewActionItemsStateModel>, { id }: ReviewActionItemsDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
