import { Resource } from "../../../components/catalogs/beamline-catalog/resource/resources";

export class Restriction {
  public id?: number;
  public resourceID?: number;
  public locationID?: number;
  public restrictionLanguage?: string;
  public alternateRestrictionLanguage?: string;
  public scf?: boolean;
  public rsswa?: boolean;
  public ebf?: boolean;
  public ppstb?: boolean;
  public resource?: Resource;
  public location?: Resource;
  public resourceName?: string;
  public locationName?: string;
  public isModified?: boolean;
  public isDeleted?: boolean;
  public isAdded?: boolean;
}

export class SaveRestriction {
  public resourceID?: number;
  public locationID?: number;
  public restrictionLanguage?: string;
  public alternateRestrictionLanguage?: string;
}
