import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, OnDestroy, Injector } from '@angular/core';
import { BratoiHistoryComponent } from './bratoi-history/bratoi-history.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormControl } from '@angular/forms';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { Action, Roles } from 'src/app/common/enumerations/enumerations';
import { PendingChangesDialogComponent } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog.component';
import { Link } from 'src/app/components/catalogs/navigation-links/link/link';
import { TOCA } from 'src/app/components/catalogs/beamline-catalog/resource/toca';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { BratoiAddEditComponent } from './bratoi-add-edit/bratoi-add-edit.component';
import { BratoiService } from './bratoi.service';
import { BeamlineRequiringApproval } from './models/beamline-requiring-approval';
import { BratoiRefresh, BratoiRefreshById } from './store/bratoi/bratoi.action';
import { CanDeactivateResponse } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog-response';

@Component({
  selector: 'bratoi',
  templateUrl: './bratoi.component.html',
  styleUrls: ['./bratoi.component.scss']
})
export class BratoiComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav')
  sidenav!: MatSidenav;
  @ViewChild('headerTop')
  headerTop!: ElementRef;

  loading!: boolean;
  resourceRequired!: boolean;

  filterCtrl = new FormControl();
  tmpFilter!: string;
  public privilege = PrivilegeEnum;

  filter?: string;

  errorMessages!: string[];
  headerOnTop!: boolean;

  disableAction!: boolean;
  public action = Action;

  public bratois!: BeamlineRequiringApproval[];
  public bratois$!: Observable<BeamlineRequiringApproval[]>;
  public bratoisSubs!: Subscription;

  oldBratoi?: BeamlineRequiringApproval | null;

  public displayedColumns: string[] = ['resource', 'documentation', 'criticalApertures', 'approval', 'options'];
  public dataSource: MatTableDataSource<BeamlineRequiringApproval> = new MatTableDataSource();

  @ViewChild('bratoiTable')
  bratoiTable!: ElementRef;

  constructor(
    protected override injector: Injector,

    private route: ActivatedRoute,
    private service: BratoiService
  ) {
    super(injector);

  }

  ngOnInit() {
    this.loading = true;
    this.moduleTitle = 'BL 08-34 Appendix 2: Beamlines Requiring Approval for Top-off';
    // this.store.dispatch(new BratoiRefresh());
    this.getBratoi();
  }

  queryRoute() {
    const id = this.route.snapshot.queryParams['id'];
    if (id) {
    }
  }

  applyFilter(e: any) {
    this.filter = e;
  }

  override ngOnDestroy(): void {
    this.bratoisSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  clear() {
    this.errorMessages = [];
  }

  create() {
    this.loading = true;
  }
  ////////////////////////////////////

  getBratoi() {
    this.loading = true;
    this.bratois$ = this.store.select(state => state.Bratoi.data);
    this.bratoisSubs = this.bratois$.subscribe(data => {
      if (data?.length) {
        this.bratois = data.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.resourceString, b.resourceString)).filter(bl => bl.isActive);
        this.dataSource = new MatTableDataSource(this.bratois);
        this.dataSource.filter = '';
        this.loading = false;
      }
    });
  }

  validateShowMenu(blToUpdateAction: BeamlineRequiringApproval): boolean {
    if (blToUpdateAction?.action >= 0) { return true; }
    if (blToUpdateAction?.action !== 1 && this.hasPrivilege(PrivilegeEnum.BlRequiringApprovalEdit)) { return true; }
    if (blToUpdateAction?.action !== 1 && this.hasPrivilege(PrivilegeEnum.BlRequiringApprovalDelete)) { return true; }
    if (blToUpdateAction?.action === 1 && this.hasPrivilege(PrivilegeEnum.BlRequiringApprovalUnapprove)) { return true; }
    return false;
  }

  AddEditDialog(bratoi?: BeamlineRequiringApproval) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      bratoi: bratoi ? bratoi : new Link(),
      bratois: this.bratois,
      dialogTitle: bratoi ? 'Edit Beamline Requiring Approval' : 'Add Beamline Requiring Approval'
    };
    this.oldBratoi = bratoi;
    dialogConfig.width = '1000px';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(BratoiAddEditComponent, dialogConfig);
    this.dialogDiscardChanges(dialogRef);
    dialogRef.afterClosed().subscribe((bratoi: BeamlineRequiringApproval) => {
      if (bratoi) {
        if (!bratoi.links) { bratoi.links = []; }
        if (!bratoi.tocas) { bratoi.tocas = []; }
        if (bratoi.id) {
          this.updateBratoi(bratoi);
        } else {
          this.createBratoi(bratoi);
        }
      }
    });
  }

  dialogDiscardChanges(dialogRef: MatDialogRef<BratoiAddEditComponent>) {
    dialogRef.backdropClick().subscribe(() => {
      const confirmation = this.dialog.open(PendingChangesDialogComponent, {
        height: 'fit-content',
        width: '40%',
        data: {}
      });
      confirmation.afterClosed().subscribe((response: CanDeactivateResponse) => {
        switch (response) {
          case CanDeactivateResponse.Discard:
            dialogRef.close();
            break;
        }
      });
    });
  }

  createBratoi(bratoiToCreate: BeamlineRequiringApproval) {
    this.loading = true;
    this.service.CreateBlApproval(bratoiToCreate).toPromise().then(() => {
      this.refreshRelated(bratoiToCreate);
      this.loading = false;
    });
  }

  updateBratoi(bratoiToUpdate: BeamlineRequiringApproval) {
    this.loading = true;
    this.service.UpdateBlApproval(bratoiToUpdate).toPromise().then(() => {
      this.refreshRelated(bratoiToUpdate);
      this.loading = false;
    });
  }

  refreshRelated(bratoi: BeamlineRequiringApproval) {
    bratoi.tocas.filter(t => t.beamlineRequiringApprovalRelations?.length).map(t => {
      const rel = t.beamlineRequiringApprovalRelations;
      rel?.map(r => {
        if (r.beamlineRequiringApproval) {
          this.store.dispatch(new BratoiRefreshById(r.beamlineRequiringApprovalId));
        }
      });
    });
    if (this.oldBratoi) {
      const bratoiTmp = this.utils.cloneDeep(this.oldBratoi);
      this.oldBratoi = null;
      this.refreshRelated(bratoiTmp);
    }
  }

  updateAction(blrToUpdateAction: BeamlineRequiringApproval, action: Action) {
    this.loading = true;
    this.service.UpdateActionBlApproval(blrToUpdateAction.id, action, blrToUpdateAction).toPromise().then(bratoi => {
      const messageName = action === Action.Approve ? 'BRATOI_Approved' : 'BRATOI_Unapproved';
      this.alert.message(messageName);
      this.loading = false;
    });
  }

  showHistory(bratoi: BeamlineRequiringApproval) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = bratoi;
    dialogConfig.width = '60vw';
    dialogConfig.maxHeight = '80vh';
    dialogConfig.minHeight = '50vh';
    this.dialog.open(BratoiHistoryComponent, dialogConfig);
  }

  changedFilter(e: any) {
    const text = (e as string).toLowerCase();
    this.filter = text;
    this.dataSource.data = this.bratois.filter(
      x => x.resourceString.toLowerCase().includes(text) ||
        x.links.some(
          l => l.hyperlink?.toLowerCase().includes(text) || l.linkType?.name?.toLowerCase().includes(text)
        ) ||
        x.tocas.some(
          t => t.name.toLowerCase().includes(text) || t.resourceTOCARelations?.some(r => r.resource?.name?.toLowerCase().includes(text)) || t.resourceTOCARelations?.some(r => r.toca?.name.toLowerCase().includes(text))
        )
    );
  }

  getTocaSharedWith(bratoi: BeamlineRequiringApproval, toca: TOCA) {
    const relations = toca.beamlineRequiringApprovalRelations?.filter(r => r.beamlineRequiringApprovalId != bratoi.id).map(r => r.beamlineRequiringApproval?.resource ? r.beamlineRequiringApproval?.resource.name : r.beamlineRequiringApproval?.resourceString).sort((a, b) => this.utils.SortBeamlines(a, b));
    if (relations?.length) {
      return '(Shared with: ' + relations.join(', ') + ')';
    }
    return null;
  }

  confirmDeleteDialog(e: any) {

  }
}
