import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ReviewAttendanceCategory } from 'src/app/components/reviews/reviews.models';
import { ReviewsService } from 'src/app/components/reviews/reviews.service';
import { ReviewAttendanceCategoriesRefresh } from 'src/app/components/reviews/store/review-attendance-category/review-attendance-category.action';

@Component({
  selector: 'app-review-attendance-categories',
  templateUrl: './review-attendance-categories.component.html',
  styleUrls: ['./review-attendance-categories.component.scss']
})
export class ReviewAttendanceCategoriesComponent extends BaseComponent implements OnInit, OnDestroy {

  public reviewCategories$!: Observable<ReviewAttendanceCategory[]>;
  public reviewCategories!: ReviewAttendanceCategory[];
  public reviewCategory!: ReviewAttendanceCategory | null;
  public reviewCategorySubscription!: Subscription;

  reviewCategoryNameCtrl = new FormControl();
  reviewCategoryDescriptionCtrl = new FormControl();
  reviewCategoryCodeCtrl = new FormControl();
  form!: FormGroup;

  public category!: string;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<ReviewAttendanceCategoriesComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: {
      dialogTitle: string,
    },
    private service: ReviewsService
  ) {
    super(injector);

  }

  buildForm() {
    this.form = new FormGroup({
      reviewCategoryNameCtrl: this.reviewCategoryNameCtrl,
      reviewCategoryDescriptionCtrl: this.reviewCategoryDescriptionCtrl,
      reviewCategoryCodeCtrl: this.reviewCategoryCodeCtrl,
    });
    this.reviewCategoryNameCtrl.setValue(null);
    this.reviewCategoryDescriptionCtrl.setValue(null);
    this.reviewCategoryCodeCtrl.setValue(null);
  }

  ngOnInit() {
    this.buildForm();
    this.getReviewCategories();
  }

  close() {
    this.dialogRef.close();
  }

  override ngOnDestroy(): void {
    this.reviewCategorySubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  getReviewCategories() {
    this.reviewCategories$ = this.store.select(state => state.ReviewAttendanceCategories.data);
    this.reviewCategorySubscription = this.reviewCategories$.subscribe(reviewCategories => {
      this.reviewCategories = reviewCategories.sort((a, b) => this.utils.sort(a.order, b.order));
      this.renumberArray();
    });
  }

  drop(event: CdkDragDrop<ReviewAttendanceCategory[]>) {
    if (event?.previousIndex !== event?.currentIndex) {
      moveItemInArray(this.reviewCategories, event.previousIndex, event.currentIndex);
      this.renumberArray();
    }
  }

  renumberArray() {
    let order = 1;
    this.reviewCategories?.map(reviewCategory => {
      if (reviewCategory.id == -1) {
        reviewCategory.order = 999;
      }
      else {
        if (reviewCategory.order != order) {
          reviewCategory.order = order;
          this.service.updateReviewAttendanceCategory(reviewCategory).subscribe(() => {
            this.store.dispatch(new ReviewAttendanceCategoriesRefresh());
          });
        }
        order++;
      }
    });
    this.reviewCategories = this.reviewCategories.sort((a, b) => this.utils.sort(a.order, b.order));
  }

  edit(reviewAttendanceCategory: ReviewAttendanceCategory) {
    this.buildForm();
    this.reviewCategory = reviewAttendanceCategory;
    this.reviewCategoryNameCtrl.setValue(this.reviewCategory.name);
    this.reviewCategoryDescriptionCtrl.setValue(this.reviewCategory.description);
    this.reviewCategoryCodeCtrl.setValue(this.reviewCategory.code);
  }

  add() {
    this.buildForm();
    this.reviewCategory = {
      id: 0,
      order: 999
    } as ReviewAttendanceCategory;
  }

  formValid() {
    return !this.utils.isNullValue(this.reviewCategoryNameCtrl.value) && !this.utils.isNullValue(this.reviewCategoryDescriptionCtrl.value) && !this.utils.isNullValue(this.reviewCategoryCodeCtrl.value);
  }

  cancel() {
    this.reviewCategory = null;
  }

  save() {
    if (this.reviewCategory) {
      this.reviewCategory.name = this.reviewCategoryNameCtrl.value;
      this.reviewCategory.description = this.reviewCategoryDescriptionCtrl.value;
      this.reviewCategory.code = this.reviewCategoryCodeCtrl.value;

      if (this.reviewCategory.id === 0) {
        this.service.createReviewAttendanceCategory(this.reviewCategory).toPromise().then(() => {
          this.reviewCategory = null;
        });
      }
      else {
        this.service.updateReviewAttendanceCategory(this.reviewCategory).toPromise().then(() => {
          this.reviewCategory = null;
        });
      }
    }
  }

  afterSave() {
    this.renumberArray();
    this.reviewCategory = null;
  }

  delete(reviewCategory: ReviewAttendanceCategory) {
    this.service.deleteReviewAttendanceCategory(reviewCategory.id).toPromise().then(() => {
      this.reviewCategory = null;
    });
  }
}
