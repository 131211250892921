<div class="card">
  <div class="card-header">
    <span class="card-title">Documents Category Details</span>
    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="card-body">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input
        matInput
        [(ngModel)]="currentReviewDocumentCategory.name"
        [disabled]="!(adding || modifying) || selected"
        name="name"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Description</mat-label>
      <input
        matInput
        [(ngModel)]="currentReviewDocumentCategory.description"
        [disabled]="!(adding || modifying)"
        name="description"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Status</mat-label>
      <mat-select
        [(ngModel)]="currentReviewDocumentCategory.status"
        [disabled]="!(adding || modifying) || disabledStatus"
        name="status"
      >
        <mat-option *ngFor="let RCstatus of statusList" [value]="RCstatus.id">
          {{ RCstatus.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        *ngIf="showCancelButton"
        mat-flat-button
        color="warn"
        (click)="onNoClick()"
      >
        Cancel
      </button>

      <button
        mat-flat-button
        color="primary"
        [disabled]="disabledTimer"
        (click)="saveReviewDocumentCategory()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
