import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ResourceTypesCreate, ResourceTypesDelete, ResourceTypesRefresh, ResourceTypesUpdate } from './resource-types.action';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';
import { ResourceType } from 'src/app/components/catalogs/beamline-catalog/resource/resources';

export class ResourceTypesStateModel {
  data: ResourceType[] = [];
}

@State<ResourceTypesStateModel>({
  name: 'ResourceTypes',
  defaults: {
    data: []
  }
})
@Injectable()
export class ResourceTypesState {
  constructor(
    private _service: ResourcesService
  ) { }

  @Selector()
  static read(state: ResourceTypesStateModel) {
    return state.data;
  }

  @Action(ResourceTypesRefresh)
  refresh({ patchState }: StateContext<ResourceTypesStateModel>) {
    this._service.getAllResourceTypes().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ResourceTypesCreate)
  create({ getState, patchState }: StateContext<ResourceTypesStateModel>, { item }: ResourceTypesCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ResourceTypesUpdate)
  update({ getState, patchState }: StateContext<ResourceTypesStateModel>, { id, item }: ResourceTypesUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ResourceTypesDelete)
  delete({ getState, patchState }: StateContext<ResourceTypesStateModel>, { id }: ResourceTypesDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
