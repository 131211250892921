import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FormsCreate, FormsDelete, FormsRefresh, FormsRefreshByID, FormsUpdate } from './forms.action';
import { FormService } from '../form.service';
import { Form } from 'src/app/services/forms/form';

export class FormsStateModel {
  data: Form[] = [];
}

@State<FormsStateModel>({
  name: 'Forms',
  defaults: {
    data: []
  }
})
@Injectable()
export class FormsState {
  constructor(
    private _service: FormService
  ) { }

  @Selector()
  static read(state: FormsStateModel) {
    return state.data;
  }

  @Action(FormsRefresh)
  refresh({ patchState }: StateContext<FormsStateModel>, { type }: FormsRefresh) {
    this._service.readByType(type ?? 0).subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(FormsRefreshByID)
  refreshByID({ getState, patchState }: StateContext<FormsStateModel>, { id }: FormsRefreshByID) {
    this._service.getById(id).toPromise().then(data => {
      if (data) {
        const state = getState();
        const index = state.data.map(x => x.id).indexOf(id);

        if (index >= 0)
          state.data.splice(index, 1);

        patchState({
          data: [...state.data, data]
        });
      }
    });
  }

  @Action(FormsCreate)
  create({ getState, patchState }: StateContext<FormsStateModel>, { item }: FormsCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(FormsUpdate)
  update({ getState, patchState }: StateContext<FormsStateModel>, { id, item }: FormsUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(FormsDelete)
  delete({ getState, patchState }: StateContext<FormsStateModel>, { id }: FormsDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
