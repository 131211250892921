<div class="mt-3 sectionx" id="section4">
  <div class="row">
    <div class="col d-flex align-items-end">
      <h5 id="Section4Title">Bypass Removal</h5>
    </div>
    <div class="col d-flex justify-content-end">
      <div
        class="col d-flex align-items-end justify-content-end"
        *ngIf="form?.formVersion?.eB2?.permanentBypassReason"
      >
        <label class="bold">
          {{
            getMessage("EB_Permanent_Bypass_Justification").description
          }}</label
        >&nbsp;<label>{{
          form?.formVersion?.eB2?.permanentBypassReason
        }}</label>
      </div>
      <div class="pb-2 pr-2">
        <extra-action-button
          *ngFor="let action of section4ExtraActions"
          [extraAction]="action"
          [confirm]="true"
          [form]="form"
          [disabled]="disabled"
          (error)="alert.defaultError()"
          (loading)="loading.emit($event)"
        ></extra-action-button>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center">
    <div *ngFor="let s of section41Signatures" class="btn-sign-container mx-1">
      <button-signature
        *ngIf="s.enabled"
        #btnSign
        [roleId]="s.roles"
        [roleCodes]="s.roleCodes"
        [userNames]="s.userNames"
        [user]="s.approvedBy"
        [title]="s.name"
        [disable]="s.disabled"
        [value]="s.approved"
        [showApprove]="s.approve"
        [showUnapprove]="s.unapprove"
        [showDisapprove]="s.disapprove"
        [showCancel]="s.cancel"
        [cancelID]="s.cancelID"
        [date]="s.approvedOn"
        [taskID]="s.taskId"
        [enabled]="s.enabled"
        (approve)="service.confirmSign(form, s, s.approveId)"
        (unapprove)="service.sign(form, s.taskId, s.unapproveId)"
        (disapprove)="service.sign(form, s.taskId, s.disapproveId, $event.text)"
        (onError)="alert.error($event)"
      ></button-signature>
    </div>
  </div>

  <div class="d-flex justify-content-center">
    <div *ngFor="let s of section42Signatures" class="btn-sign-container mx-1">
      <button-signature
        *ngIf="s.enabled"
        #btnSign
        [roleId]="s.roles"
        [roleCodes]="s.roleCodes"
        [userNames]="s.userNames"
        [user]="s.approvedBy"
        [title]="s.name"
        [disable]="s.disabled"
        [value]="s.approved"
        [showApprove]="s.approve"
        [showUnapprove]="s.unapprove"
        [showDisapprove]="s.disapprove"
        [showCancel]="s.cancel"
        [cancelID]="s.cancelID"
        [date]="s.approvedOn"
        [taskID]="s.taskId"
        [enabled]="s.enabled"
        (approve)="service.confirmSign(form, s, s.approveId)"
        (unapprove)="service.sign(form, s.taskId, s.unapproveId)"
        (disapprove)="service.sign(form, s.taskId, s.disapproveId, $event.text)"
        (onError)="alert.error($event)"
      ></button-signature>
    </div>
  </div>
</div>
