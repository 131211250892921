<div class="card">
  <div class="card-header pt-1 pb-0">
    <div
      #header2
      [ngClass]="{ 'container-fluid fixed': true, 'pt-3': headerOnTop }"
      [style.max-width]="tableWidth"
    >
      <div class="row">
        <div class="col-12 col-sm-6 pl-0 d-flex align-items-center">
          <div class="card-title">Branchline Status</div>
        </div>
        <div class="col-12 col-sm-6 pr-0 pb-1">
          <filter-box (changed)="applyFilter($event)"></filter-box>
        </div>
      </div>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="card-body">
    <div class="mat-container">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="branchline">
          <mat-header-cell *matHeaderCellDef> Branchline </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <button
              type="button"
              class="btn btn-link"
              (click)="SendId(element.id)"
            >
              <span
                [innerHTML]="element.name | highlightSearch : filter"
              ></span>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell class="small" *matCellDef="let element">
            <button
              class="operational"
              *ngIf="!element.operationalLockOut"
              [disabled]="!hasPrivilege(privilegeEnum.BranchlineStatusToggle)"
              (click)="doToggle(true, element)"
            >
              Operational
            </button>
            <button
              class="lockout"
              *ngIf="element.operationalLockOut"
              [disabled]="!hasPrivilege(privilegeEnum.BranchlineStatusToggle)"
              (click)="doToggle(false, element)"
            >
              Locked Out
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="keReady">
          <mat-header-cell *matHeaderCellDef> KE Ready? </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div
              class="{{ element.keColor }} badge-pill "
              [matTooltip]="element.moreInfo"
            >
              {{ element.keReady }}
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns"
          [ngClass]="{
            'container-fluid p-0': true,
            'fixed-headers': headerOnTop
          }"
          [style.max-width]="tableWidth"
          [style.top]="headerOnTop ? headerBottom : null"
        >
        </mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="table-row"
          [ngClass]="{ 'mat-row-highlight': selectedRowIndex == row.id }"
        ></mat-row>
      </mat-table>
    </div>
  </div>
</div>
