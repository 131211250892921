import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as ScheduleTypesActions from './schedule-types.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';


@Injectable({
  providedIn: 'root'
})
export class ScheduleTypesStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore() {
    this.store.dispatch(new ScheduleTypesActions.ScheduleTypesRefresh());
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.SCHEDULE_TYPES, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new ScheduleTypesActions.ScheduleTypesCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new ScheduleTypesActions.ScheduleTypesUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new ScheduleTypesActions.ScheduleTypesDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new ScheduleTypesActions.ScheduleTypesRefresh());
          break;
      }
    });
  }
}
