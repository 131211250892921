import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APLCategory } from 'src/app/components/apl/services/apl-category.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class APLCategoryService {
  private url: string = environment.urls.baseApiUrl + '/APLCategory';

  constructor(
    private http: HttpClient,
  ) { }

  public create(aplCategory: APLCategory): Observable<APLCategory> {
    return this.http.post<APLCategory>(this.url, aplCategory);
  }

  public delete(id: number): Observable<any> {
    return this.http.delete<any>(this.url + '/' + id.toString());
  }

  public read(): Observable<APLCategory[]> {
    return this.http.get<APLCategory[]>(this.url);
  }

  public readWithPending(): Observable<APLCategory[]> {
    return this.http.get<APLCategory[]>(this.url + '/Pending');
  }

  public approve(id: number): Observable<APLCategory> {
    return this.http.patch<APLCategory>(this.url + '/Approve/' + id.toString(), null);
  }

  public disapprove(id: number): Observable<APLCategory> {
    return this.http.patch<APLCategory>(this.url + '/Disapprove/' + id.toString(), null);
  }

  public reorder(fromID: number, toID: number): Observable<APLCategory> {
    return this.http.patch<APLCategory>(this.url + '/Reorder/' + fromID.toString() + '/' + toID.toString(), null);
  }
}
