import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { TextareaDialogComponent } from 'src/app/controls/textarea-dialog/textarea-dialog.component';
import { OlogCategoryService } from '../../../services/olog-category.service';
import { OlogCategory } from '../../../services/olog-category';
import { AddEditOlogCategoryComponent } from '../../dialogs/add-edit-olog-category/add-edit-olog-category.component';
import { DocumentType } from 'src/app/services/documents/documents';


@Component({
  selector: 'app-olog-category-tab',
  templateUrl: './olog-category-tab.component.html',
  styleUrls: ['./olog-category-tab.component.scss']
})
export class OlogCategoryTabComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  public ologCategories$!: Observable<OlogCategory[]>;
  public ologCategorySubscription!: Subscription;

  ologCategories?: OlogCategory[];

  public displayedColumnsOlogCategory: string[] = ['name'];
  public dataSource: MatTableDataSource<OlogCategory> = new MatTableDataSource();

  @Input() documentType?: DocumentType;
  @Input() isDisabled = true;

  @Output() changed = new EventEmitter<OlogCategory[]>();

  constructor(
    protected override injector: Injector,
    public ologCategoryService: OlogCategoryService,
  ) {
    super(injector);
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDisabled']) {
      if (this.isDisabled) {
        this.removeAddColumn();
      } else {
        if (this.hasPrivilege(this.privilegeEnum.CreateOlogCategory)) {
          this.displayedColumnsOlogCategory.push('add');
        }
      }
    }
    if (this.documentType)
      this.loadOlogCategorysByDocTypeId(this.documentType.documentTemplateId ?? 0);
  }

  override ngOnDestroy(): void {
    this.ologCategorySubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  loadOlogCategorysByDocTypeId(documentTypeId: number) {
    this.ologCategories$ = this.store.select(x => x.OlogCategory.data);
    this.ologCategorySubscription = this.ologCategories$.subscribe(ologCategories => {
      this.ologCategories = ologCategories.filter(x => x.documentTypeID === documentTypeId);
      this.dataSource = new MatTableDataSource(this.ologCategories);
    });
  }

  ologCategoryDialog(ologCategory?: OlogCategory) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = ologCategory ? this.utils.cloneDeep(ologCategory) : new OlogCategory();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AddEditOlogCategoryComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then((data: OlogCategory) => {
      if (data) {
        this.addUpdate(data);
      }
    });
  }

  addUpdate(ologCategory?: OlogCategory) {
    if (ologCategory?.id) {
      const index = this.ologCategories?.findIndex(c => c.id == ologCategory.id) ?? -1;
      if (index >= 0 && this.ologCategories)
        this.ologCategories[index] = ologCategory;
    }
    else {
      if (ologCategory)
        this.ologCategories?.push(ologCategory);
    }
    this.dataSource = new MatTableDataSource(this.ologCategories);
    this.changed.emit(this.ologCategories);
  }

  delete(ologCategory: OlogCategory) {
    const dialogConfig: MatDialogConfig = {
      data: {
        label: 'Delete Reason',
        placeholder: 'Delete Reason',
        text: ''
      }
    };
    const dialogRefSureToDelete = this.dialog.open(TextareaDialogComponent, dialogConfig);
    dialogRefSureToDelete.afterClosed().toPromise().then(data => {
      if (data?.acceptClicked && data?.text) {
        const index = this.ologCategories?.findIndex(c => c.id == ologCategory.id) ?? -1;
        if (index >= 0)
          this.ologCategories?.splice(index, 1);
        this.dataSource = new MatTableDataSource(this.ologCategories);
        this.changed.emit(this.ologCategories);
      }
    });
  }

  removeAddColumn() {
    if (this.displayedColumnsOlogCategory.includes('add')) {
      const index = this.displayedColumnsOlogCategory.indexOf('add');
      this.displayedColumnsOlogCategory.splice(index, 1);
    }
  }

}
