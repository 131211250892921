import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ChecklistTempComponent } from 'src/app/components/checklists/checklist-temp/checklist-temp.component';
import { Checklist, ChecklistTemplate, ChecklistTemplateStatusEnum } from 'src/app/components/checklists/checklists';
import { ChecklistsService } from 'src/app/components/checklists/checklists.service';
import { ProcedureCategory } from 'src/app/components/procedure/models/procedure-category.model';
import { ProcedureTypeEnum } from 'src/app/components/procedure/enums/procedure-type.enum';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { RelatedLinkDocumentBase } from 'src/app/common/models/related-link-document-base.model';
import { ChecklistTemplatesRefreshByDocument, ChecklistTemplatesRefreshById } from 'src/app/components/checklists/store/checklist-templates/checklist-templates.action';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { Schedule } from 'src/app/components/schedules/models/schedule';

@Component({
  selector: 'procedure-table',
  templateUrl: './procedure-table.component.html',
  styleUrls: ['./procedure-table.component.scss']
})
export class ProcedureTableComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() procedureCategoryID?: number;
  @Input() filter = '';
  @Input() showInactive!: boolean;

  @Output() loading = new EventEmitter<boolean>();

  columns: string[] = ['procedureNumber', 'description', 'revision'];
  title: string[] = ['procedureCategory'];
  sort!: Sort;

  procedureCategorySubscription!: Subscription;
  procedureCategory$!: Observable<ProcedureCategory[]>;
  procedureCategories!: ProcedureCategory[];
  procedureCategory?: ProcedureCategory;

  checklistTemplates?: ChecklistTemplate[];
  checklistTemplatesFiltered?: ChecklistTemplate[];
  checklistTemplates$!: Observable<ChecklistTemplate[]>;
  checklistTemplatesSubs!: Subscription;

  procedureSubscription!: Subscription;
  procedure$!: Observable<Procedure[]>;
  procedures!: Procedure[];
  proceduresFiltered!: Procedure[];
  procedureDS = new MatTableDataSource<Procedure>();

  labelNumber!: string;
  labelTitle!: string;
  labelRevision!: string;
  labelLocation!: string;
  labelBeamline = 'Beamline';
  labelTitleLocationGlobal!: string;

  constructor(
    protected override injector: Injector,
    private checklistsService: ChecklistsService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loading.emit(true);
    this.loadData();
  }

  override ngOnDestroy(): void {
    this.procedureCategorySubscription?.unsubscribe();
    this.procedureSubscription?.unsubscribe();
    this.checklistTemplatesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('filter' in changes) {
      this.proceduresFiltered = this.procedures?.filter(x =>
        x.procedureNumber?.trim()?.toUpperCase()?.includes(this.filter?.trim().toUpperCase()) ||
        x.title?.trim()?.toUpperCase()?.includes(this.filter?.trim().toUpperCase()) ||
        x.procedureAppendices?.filter(appendix => appendix?.text?.trim()?.toUpperCase()?.includes(this.filter?.trim()?.toUpperCase())).length
      ).sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.procedureNumber, b.procedureNumber));
      this.procedureDS.data = this.proceduresFiltered;
    }
    if ('procedureCategoryID' in changes) {
      this.loadData();
    }
  }

  loadData() {
    if (this.procedureCategory?.procedureTypeID == ProcedureTypeEnum.RadSurvey) {
      this.loadChecklistTemplates();
    }
    else this.loadCategories();
    this.setColumnTitles();
  }

  loadChecklistTemplates() {
    this.store.dispatch(new ChecklistTemplatesRefreshByDocument(FormType.RADSURVEYPLAN))
    this.checklistTemplates$ = this.store.select(state => state.ChecklistTemplates.data);
    this.checklistTemplatesSubs = this.checklistTemplates$.pipe(distinctUntilChanged()).subscribe(data => {
      if (data?.length) {
        this.checklistTemplates = data;
        this.loadCategories();
      }
    });
  }

  loadCategories() {
    this.procedureCategory$ = this.store.select(state => state.ProcedureCategory.ProcedureCategories);
    this.procedureCategorySubscription = this.procedureCategory$.pipe(distinctUntilChanged()).subscribe(
      data => {
        this.procedureCategories = data;
        this.procedureCategory = this.procedureCategories.find(x => x.id == this.procedureCategoryID);
        if (this.procedureCategory?.procedureTypeID == ProcedureTypeEnum.RSSTest || this.procedureCategory?.procedureTypeID == ProcedureTypeEnum.EPSTest || this.procedureCategory?.procedureTypeID == ProcedureTypeEnum.KEChecklist) {
          this.sort = {
            active: 'description',
            direction: 'asc',
          };
        }
        this.loadProcedures();
      }
    );
  }

  loadProcedures() {
    this.procedure$ = this.store.select(state => state.Procedures.data);
    this.procedureSubscription = this.procedure$.pipe(distinctUntilChanged()).subscribe((data) => {
      if (data) {
        this.procedures = data.filter(x => x.procedureCategoryID == this.procedureCategoryID && !x.newProcedure && x.active === true);
        this.proceduresFiltered = this.filter ? this.procedures.filter(x => x.procedureNumber?.trim()?.toUpperCase()?.includes(this.filter?.trim()?.toUpperCase()) || x.title?.trim()?.toUpperCase()?.includes(this.filter?.trim()?.toUpperCase())) : this.procedures;
        if (this.procedureCategory?.procedureTypeID != ProcedureTypeEnum.RadSurvey)
          this.procedureDS = new MatTableDataSource(this.proceduresFiltered.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.procedureNumber, b.
            procedureNumber)));
        else this.getChecklistTemplates();
        this.sortData();
        this.loading.emit(false);
      }
    });
  }

  setColumnTitles() {
    this.labelNumber = this.getMessage('ALSPC_All_Number').description;
    this.labelTitle = this.getMessage('ALSPC_All_Title').description;
    this.labelRevision = this.getMessage('ALSPC_All_Revision').description;
    this.labelLocation = this.getMessage('ALSPC_All_Location').description;
    this.labelTitleLocationGlobal = (this.procedureCategory?.procedureTypeID !== ProcedureTypeEnum.ProcedureCenter && this.procedureCategory?.procedureTypeID !== ProcedureTypeEnum.EPSDrawings && this.procedureCategory?.procedureTypeID !== ProcedureTypeEnum.RadSurvey) ? this.labelLocation : this.labelTitle;
    if (this.procedureCategory?.procedureTypeID == ProcedureTypeEnum.RadSurvey) {
      this.labelTitleLocationGlobal = this.labelBeamline;
      this.columns = ['description', 'procedureNumber', 'revision', 'template'];
      this.getChecklistTemplates();
    }
  }

  getChecklistTemplates() {
    this.proceduresFiltered.map(p => p.checklistTemplate = this.checklistTemplates?.find(t => t.procedureMasterID == p.procedureMasterID && t.status == ChecklistTemplateStatusEnum.Active));
    this.procedureDS = new MatTableDataSource(this.proceduresFiltered
      .sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.procedureNumber, b.procedureNumber)));
  }

  sortData(sort: Sort = this.sort) {
    this.sort = sort;
    if (this.proceduresFiltered === undefined || this.proceduresFiltered === null) {
      return;
    }
    const data = this.proceduresFiltered.slice();
    if (!sort?.active || sort?.direction === '') {
      this.procedureDS.data = data;
      return;
    }

    this.procedureDS.data = data.sort((a, b) => {
      const isAsc = sort?.direction === 'asc';
      switch (sort?.active) {
        case 'procedureNumber': return this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.procedureNumber, b.procedureNumber);
        case 'description': return this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.title, b.title);
        default: return 0;
      }
    });
  }

  openViewModeDialog(procedure: Procedure) {
    if (procedure.procedureCategory?.procedureTypeID === ProcedureTypeEnum.RSSTest || procedure.procedureCategory?.procedureTypeID === ProcedureTypeEnum.EPSTest) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        procedureMasterId: procedure.procedureMasterID,
        procedureTypeEnum: procedure.procedureCategory.procedureTypeID
      };
      dialogConfig.minWidth = '60vw';
      dialogConfig.disableClose = true;
      const dialogRef = this.dialog.open(ChecklistTempComponent, dialogConfig);
      dialogRef.afterClosed().toPromise().then((checklist: Checklist) => {
        if (checklist) {
          this.checklistsService.deleteChecklist(checklist.id).toPromise();
        }
      });
    }
  }

  sortedAppendices(procedure: Procedure): RelatedLinkDocumentBase[] {
    return procedure?.procedureAppendices?.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.text, b.text)) ?? [];
  }

  calculateRevision(revision: number) {
    if (this.procedureCategory?.procedureTypeID == ProcedureTypeEnum.RSSTest || this.procedureCategory?.procedureTypeID == ProcedureTypeEnum.EPSTest || this.procedureCategory?.procedureTypeID == ProcedureTypeEnum.RadSurvey) {
      return revision > 0 ? revision - 1 : 0;
    }
    return revision;
  }

}
