<div>
  <form [formGroup]="formNote">
    <div class="row">
      <label class="center" *ngIf="data != null">
        Reject extension to {{ header(data.name) }}
      </label>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Reason</mat-label>
          <textarea matInput formControlName="description"></textarea>
          <!-- <mat-error *ngIf="formNote.controls.description.hasError('required')">Reason is required</mat-error> -->
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="!formNote.valid"
        cdkFocusInitial
      >
        Submit
      </button>
    </div>
  </form>
</div>
