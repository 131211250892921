<div class="container-fluid mt-2 px-4 mx-1">
  <div
    class="row section border mb-2 py-3 print-content"
    id="bl{{ beamline.beamlineID }}"
    *ngFor="let beamline of filteredBeamlines"
  >
    <div class="col-2">
      <h5>
        <span
          [innerHTML]="beamline.beamlineName | highlightSearch : filter"
          [ngClass]="{
            blue: beamline.status == 1,
            yellow: beamline.status == 2,
            black: beamline.status == 3,
            gray: beamline.status == 4
          }"
        ></span>
      </h5>
    </div>
    <div class="col-10">
      <div class="row">
        <div class="col-12 col-sm-4" *ngIf="beamline.supervisors.length > 0">
          <mat-table
            #table
            [dataSource]="beamline.supervisors"
            class="mat-container"
          >
            <ng-container matColumnDef="supervisorName">
              <mat-header-cell *matHeaderCellDef>
                Supervisor(s)
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span
                  [innerHTML]="element.userName | highlightSearch : filter"
                ></span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="endstation">
              <mat-header-cell *matHeaderCellDef> Endstation </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span
                  [innerHTML]="element.endStations | highlightSearch : filter"
                ></span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="branchline">
              <mat-header-cell *matHeaderCellDef> Branchline </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span
                  [innerHTML]="element.branchLines | highlightSearch : filter"
                ></span>
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedColumnsSupervisor"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnsSupervisor"
            ></mat-row>
          </mat-table>
        </div>
        <div class="col-12 col-sm-4" *ngIf="beamline.scientists.length > 0">
          <mat-table
            #table
            [dataSource]="beamline.scientists"
            class="mat-container"
          >
            <ng-container matColumnDef="scientistName">
              <mat-header-cell *matHeaderCellDef>
                BL Scientist(s)
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><span
                  [innerHTML]="element.userName | highlightSearch : filter"
                ></span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="endstation">
              <mat-header-cell *matHeaderCellDef> Endstation </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span
                  [innerHTML]="element.endStations | highlightSearch : filter"
                ></span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="branchline">
              <mat-header-cell *matHeaderCellDef> Branchline </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span
                  [innerHTML]="element.branchLines | highlightSearch : filter"
                ></span>
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedColumnsScientist"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnsScientist"
            ></mat-row>
          </mat-table>
        </div>
        <div
          class="col-12 col-sm-4"
          *ngIf="beamline.alternateScientists.length > 0"
        >
          <mat-table
            #table
            [dataSource]="beamline.alternateScientists"
            class="mat-container"
          >
            <ng-container matColumnDef="alternateScientistName">
              <mat-header-cell *matHeaderCellDef>
                Alternate BL Scientist(s)
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><span
                  [innerHTML]="element.userName | highlightSearch : filter"
                ></span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="endstation">
              <mat-header-cell *matHeaderCellDef> Endstation </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span
                  [innerHTML]="element.endStations | highlightSearch : filter"
                ></span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="branchline">
              <mat-header-cell *matHeaderCellDef> Branchline </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span
                  [innerHTML]="element.branchLines | highlightSearch : filter"
                ></span>
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedColumnsAlternateScientist"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnsAlternateScientist"
            ></mat-row>
          </mat-table>
        </div>
      </div>

      <div
        class="mat-container mt-1"
        style="background-color: rgb(223, 219, 219)"
        *ngIf="
          !(
            beamline.supervisors.length > 0 ||
            beamline.scientists.length > 0 ||
            beamline.alternateScientists.length > 0
          )
        "
      >
        <h6 style="text-align: center">{{ emptyBeamlineStaffMessage }}</h6>
      </div>
    </div>
  </div>
</div>
