import { NotificationTemplate } from "src/app/components/catalogs/notifications/services/notification-template";

export class NotificationTemplateRefresh {
  static readonly type = '[NotificationTemplate] Refresh';
}
export class NotificationTemplateRefreshByID {
  static readonly type = '[NotificationTemplate] RefreshByID';
  constructor(public id: number) { }
}
export class NotificationTemplateCreate {
  static readonly type = '[NotificationTemplate] Create';
  constructor(public item: NotificationTemplate) { }
}
export class NotificationTemplateUpdate {
  static readonly type = '[NotificationTemplate] Update';
  constructor(public id: number, public item: NotificationTemplate) { }
}
export class NotificationTemplateDelete {
  static readonly type = '[NotificationTemplate] Delete';
  constructor(public id: number) { }
}
