import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, Output, EventEmitter, ViewChildren, ViewContainerRef, QueryList, OnDestroy, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BaseComponent } from 'src/app/common/base/base.component';
import { NavigationLink } from 'src/app/services/navigation-links/navigation-link';
import { NavigationLinkService } from 'src/app/services/navigation-links/navigation-link.service';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';

@Component({
  selector: 'navigation-links-list',
  templateUrl: './navigation-links-list.component.html',
  styleUrls: ['./navigation-links-list.component.scss']
})
export class NavigationLinksListComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() filter?: string;
  @Output() loading = new EventEmitter<boolean>();
  @Output() rowSelected = new EventEmitter<NavigationLink>();

  navigationLink?: NavigationLink;
  navigationLinks?: NavigationLink[];
  navigationLinksFiltered?: NavigationLink[];
  navigationLinks$!: Observable<NavigationLink[]>;
  navigationLinksSubs!: Subscription;

  dataSource!: MatTableDataSource<NavigationLink>;
  displayedColumns: string[] = ['hyperlink', 'isActive'];

  selectedRowIndex?: number;


  @ViewChildren('matrow', { read: ViewContainerRef })
  rows!: QueryList<ViewContainerRef>;

  constructor(
    protected override injector: Injector,
    public navigationLinkService: NavigationLinkService,
  ) {
    super(injector);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter']) {
      this.applyFilter();
    }
  }

  ngOnInit(): void {
    this.clear();
    this.loadNavigationLinks();
  }

  override ngOnDestroy(): void {
    this.navigationLinksSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadNavigationLinks() {
    this.loading.emit(true);
    this.navigationLinks$ = this.store.select(state => state.NavigationLinks.data);
    this.navigationLinksSubs = this.navigationLinks$.subscribe(data => {
      if (data?.length) {
        this.navigationLinks = data;
        this.navigationLinksFiltered = data;
        this.dataSource = new MatTableDataSource(data);
        this.loading.emit(false);
      }
    });
  }

  async highlight(row: NavigationLink) {
    if (await this.canDeactivate()) {
      this.selectedRowIndex = row.id;
      this.rowSelected.emit(this.utils.cloneDeep(row));
    }
  }

  async createLink() {
    if (await this.canDeactivate()) {
      this.selectedRowIndex = 0;
      this.rowSelected.emit(new NavigationLink());
    }
  }

  clear() {
    this.dataSource = new MatTableDataSource<NavigationLink>();
    this.navigationLinks = [];
    this.selectedRowIndex = 0;
  }

  sortData(sort: Sort) {
    const data = this.navigationLinks;
    let sortedData: NavigationLink[] = [];
    if (!sort.active || sort.direction === '') {
      sortedData = data ?? [];
      return;
    }
    if (data)
      sortedData = data.sort((a, b) => {
        const isDesc = sort.direction === 'desc';
        switch (sort.active) {
          case 'hyperlink': return this.compare(a.hyperlink, b.hyperlink, isDesc);
          case 'webAddress': return this.compare(a.webAddress, b.webAddress, isDesc);
          default: return 0;
        }
      });
    this.dataSource = new MatTableDataSource(sortedData);
  }

  applyFilter() {
    if (this.filter) {
      this.navigationLinksFiltered = this.navigationLinks?.filter(x => x.hyperlink?.toLowerCase().includes(this.filter?.toLowerCase() ?? '') || x.webAddress?.toLowerCase().includes(this.filter?.toLowerCase() ?? ''));

    }
    else this.navigationLinksFiltered = this.navigationLinks;
    this.dataSource = new MatTableDataSource(this.navigationLinksFiltered);
  }

  showIndex(id: number) {
    this.selectedRowIndex = this.dataSource.data.findIndex(x => x.id === id);
    setTimeout(() => {
      if (this.selectedRowIndex) {
        this.showElement(this.selectedRowIndex, 7);
        this.highlight(this.dataSource.data[this.selectedRowIndex]);
        this.loading.emit(false);
      }
    }, 300);
  }

  showElement(index: number, height: number) {
    const indexStr = index.toString();
    const row = this.rows.find(r => r.element.nativeElement.getAttribute('position' === indexStr));
    if (row != null) {
      const rect = row.element.nativeElement.getBoundingClientRect();
      if ((rect.y <= 0) || ((rect.y + rect.height) > height)) {
        row.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
      }
      return;
    }
    console.log('not found');
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex(r => {
      return r.id === row.id;
    });
    return i;
  }

  refreshLinks(event?: any) {
    this.loadNavigationLinks();
    this.selectedRowIndex = 0;
  }

  compare(a?: number | string, b?: number | string, isAsc?: boolean) {
    if (a && b)
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    return 0;
  }

  reorderTable(event: any, dataSource: MatTableDataSource<NavigationLink>) {
    moveItemInArray(dataSource.data, event.previousIndex, event.currentIndex);
    this.navigationLinkService.updateOrder(this.reorderPositions(dataSource.data)).toPromise();
    dataSource.filter = '';
  }

  reorderPositions(navigationLinks: NavigationLink[]): NavigationLink[] {
    let addPosition = 1;
    navigationLinks.map(navigationLink => {
      navigationLink.order = addPosition++;
    });
    return navigationLinks;
  }

}
