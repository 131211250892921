import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'overlay-loading',
  templateUrl: './overlay-loading.component.html',
  styleUrls: ['./overlay-loading.component.scss']
})
export class OverlayLoadingComponent implements OnInit {

  @Input() message?: string;
  constructor() { }

  ngOnInit() {
  }

}
