import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Store } from '@ngxs/store';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { RolePrivilegeService } from 'src/app/services/role-privilege/role-privilege.service';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { RolePrivilege } from 'src/app/components/catalogs/roles/services/role-privilege';
import { ReviewPermission } from '../../../reviews.models';
import { ReviewPermissionsRefresh } from '../../../store/review-permissions/review-permissions.action';

@Component({
  selector: 'reviews-settings-permissions-roles',
  templateUrl: './reviews-settings-permissions-roles.component.html',
  styleUrls: ['./reviews-settings-permissions-roles.component.scss']
})
export class ReviewsSettingsPermissionsRolesComponent implements OnInit, OnDestroy {

  @Input() reviewPermission!: ReviewPermission;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  roles!: Role[];
  rolesFiltered!: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;
  roleCtrl = new FormControl();

  formGroup!: FormGroup;

  reviewPermissions$!: Observable<ReviewPermission[]>;
  reviewPermissionsSubs!: Subscription;

  rolePermissions!: Role[];
  roleTemp: any;

  constructor(
    private store: Store,
    private service: RolePrivilegeService
  ) { }

  ngOnDestroy(): void {
    this.rolesSubs?.unsubscribe();
    this.reviewPermissionsSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.buildForm();
    this.loadRoles();
    this.loadReviewPermissions();
  }

  buildForm() {
    this.formGroup = new FormGroup({
      roleCtrl: this.roleCtrl
    });
  }

  loadReviewPermissions() {
    this.reviewPermissions$ = this.store.select(state => state.ReviewPermissions.data);
    this.reviewPermissionsSubs = this.reviewPermissions$.subscribe(data => {
      const reviewPermission = data.find(x => x.id == this.reviewPermission.id);
      if (reviewPermission)
        this.reviewPermission = reviewPermission;
      this.reviewPermission.rolePrivileges.map(p => {
        p.role = this.roles.find(r => r.id == p.roleID) as Role;
      })
      this.rolePermissions = this.reviewPermission.rolePrivileges.filter(x => x.type == this.reviewPermission.reviewBoardTypeID).map(x => x.role) ?? [];
      this.filterRoles();
    });
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      this.roles = data;
      this.filterRoles();
    });
  }

  filterRoles() {
    this.rolesFiltered = this.roles?.filter(x => !this.rolePermissions?.map(r => r.id).includes(x.id));
  }

  roleChanged(e: string) {
    this.filterRoles();
    this.rolesFiltered = this.rolesFiltered.filter(x => x.name?.toLowerCase().includes(e.toLowerCase()) || x.code?.toLowerCase().includes(e.toLowerCase()));
  }

  selectedRole(role: Role) {
    if (this.reviewPermission.rolePrivileges == null) { this.reviewPermission.rolePrivileges = []; }
    const rolePrivilege = {
      id: 0,
      roleID: role.id,
      privilege: this.reviewPermission.privilege,
      type: this.reviewPermission.reviewBoardTypeID
    } as RolePrivilege;
    // this.rolePermissions.push(role);
    this.service.createRolePrivilege(rolePrivilege).toPromise().then(data => {
      this.store.dispatch(new ReviewPermissionsRefresh());
    });
  }

  clearInputValue(event: MatChipInputEvent) {
    const input = event.input;
    if (input) {
      input.value = '';
    }
  }

  removeRole(role: Role) {
    // const index = this.rolePermissions.findIndex(x => x.id == role.id);
    // this.rolePermissions.splice(index, 1);
    const rolePrivilege = this.reviewPermission.rolePrivileges.find(x => x.roleID == role.id);
    if (rolePrivilege?.id)
      this.service.deleteRolePrivilege(rolePrivilege.id).toPromise().then(() => {
        this.store.dispatch(new ReviewPermissionsRefresh());
      });
  }

}
