import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { Store } from '@ngxs/store';
import { distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { Configuration } from '../../checklist-builder-sections/checklist-builder-section/checklist-builder-section.component';

@Component({
  selector: 'checklist-roles-chips',
  templateUrl: './checklist-roles-chips.component.html',
  styleUrls: ['./checklist-roles-chips.component.scss']
})
export class ChecklistRolesChipsComponent implements OnInit, OnDestroy {

  @Input() configuration!: Configuration;
  @Input() disabled!: boolean;
  @Output() configurationChanged = new EventEmitter<Configuration>();

  roles!: Role[];
  rolesFiltered!: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;
  rolesTaken: Role[] = [];
  roleCodes!: string;

  tmpRole: any;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private store: Store
  ) { }

  ngOnDestroy(): void {
    this.rolesSubs?.unsubscribe();
  }

  ngOnInit() {
    this.loadRoles();
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.pipe(distinctUntilChanged()).subscribe(data => {
      if (data.length) {
        this.roles = data;
        this.rolesTaken = this.roles.filter(r => this.configuration.roleIDs?.includes(r.id));

        this.roleCodes = this.rolesTaken.map(r => r.code)?.join('/');
        this.rolesFiltered = this.roles.filter(x => !this.rolesTaken.map(r => r.id).includes(x.id));
      }
    });
  }

  selectedRole(e: any) {
    const option = e.option.value;
    this.rolesTaken.push(option);
    this.configuration.roleIDs = this.rolesTaken.map(r => r.id);
    this.configurationChanged.emit(this.configuration);
  }

  change(e: any) {
    const val = e.target.value.toLowerCase();
    this.rolesFiltered = this.roles.filter(x => !this.rolesTaken.map(r => r.id).includes(x.id) && (x.name?.toLowerCase().includes(val) || x.code?.toLowerCase().includes(val)));
  }

  removeRole(role: Role) {
    const index = this.rolesTaken.findIndex(x => x.id == role.id);
    this.rolesTaken.splice(index, 1);
    this.configuration.roleIDs = this.rolesTaken.map(r => r.id);
    this.configurationChanged.emit(this.configuration);
  }

  clearInputValue(event: MatChipInputEvent) {
    const input = event.input;
    if (input) {
      input.value = "";
    }
  }

}
