import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RolePrivilegeService } from '../../role-privilege.service';


import { RolePrivilegeCreate, RolePrivilegeDelete, RolePrivilegeRefresh, RolePrivilegeUpdate } from './role-privilege.action';
import { RolePrivilege } from 'src/app/components/catalogs/roles/services/role-privilege';
import { cloneDeep } from 'lodash';
import { utils } from 'src/app/modules/libs/utils';

export class RolePrivilegeStateModel {
  data: RolePrivilege[] = [];
}

@State<RolePrivilegeStateModel>({
  name: 'RolePrivileges',
  defaults: {
    data: []
  }
})
@Injectable()
export class RolePrivilegeState {
  constructor(
    private rolePrivilegeService: RolePrivilegeService
  ) { }

  @Selector()
  static read(state: RolePrivilegeStateModel) {
    return state.data;
  }

  @Action(RolePrivilegeRefresh)
  refresh({ patchState }: StateContext<RolePrivilegeStateModel>) {
    this.rolePrivilegeService.getAllRolePrivileges().toPromise().then(data => {
      patchState({ data: utils.cloneDeep(data) });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(RolePrivilegeCreate)
  create({ getState, patchState }: StateContext<RolePrivilegeStateModel>, { item }: RolePrivilegeCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(RolePrivilegeUpdate)
  update({ getState, patchState }: StateContext<RolePrivilegeStateModel>, { id, item }: RolePrivilegeUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    const currentState = utils.cloneDeep(state.data);

    if (index >= 0) {
      currentState.splice(index, 1, utils.cloneDeep(item));
      patchState({ data: currentState });
    }
  }

  @Action(RolePrivilegeDelete)
  delete({ getState, patchState }: StateContext<RolePrivilegeStateModel>, { id }: RolePrivilegeDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
