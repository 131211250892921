<div class="" *ngFor="let actionItem of actionItems">
  <action-item
    [actionItem]="actionItem"
    [disabled]="disabled"
    [formType]="formType"
    [formId]="formId"
    [sectionNumber]="sectionNumber"
    [sectionId]="sectionId"
    [serialNo]="serialNo"
    [taskId]="taskId"
  ></action-item>
</div>
