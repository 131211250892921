import { Component, Input, Output, EventEmitter, OnDestroy, OnChanges, OnInit } from '@angular/core';
import { ClBaseComponent } from '../cl-base/cl-base.component';

@Component({
  selector: 'cl-checkbox-multiple-with-textbox',
  templateUrl: './cl-checkbox-multiple-with-textbox.component.html',
  styleUrls: ['./cl-checkbox-multiple-with-textbox.component.scss']
})

export class ClCheckboxMultipleWithTextBoxComponent extends ClBaseComponent implements OnInit, OnDestroy, OnChanges {

  @Output() data = new EventEmitter<any>();

  public role?: string;
  public name?: string;

  public visible = false;

  /*------- Control variables ------*/
  allowMultiple?: boolean;
  allRequired?: boolean;
  /*--------------------------------*/

  ngOnChanges() {
    this.getConfiguration();
    this.setValues();
  }

  setValues() {
    this.valueObjects = this.getValueObjects(this.valueString);
    if (this.valueObjects) {
      this.text = this.valueObjects?.find(x => x.key == 'comments')?.val;
      this.radioOptions = this.valueObjects?.find(x => x.key == 'checked')?.val;
    }
    this.user = this.text || this.radioOptions?.find(o => o.checked) ? this.getCurrentUser() : null;
  }

  getConfiguration() {
    if (this.configuration) {
      const configuration = JSON.parse(this.configuration);
      this.allowMultiple = configuration?.multiple;
      this.allRequired = configuration?.required;
    }
  }

  ngOnInit() {

  }

  checkboxChanged(e: any, val: any) {
    if (!this.allowMultiple) {
      this.radioOptions?.map(x => { x.checked = false; });
    }
    const option = this.radioOptions?.find(x => x.value == val);
    if (option) {
      option.checked = e.checked;
      option.user = e.checked ? this.getCurrentUser(true) : null;
      option.date = e.checked ? new Date() : null;
    }
    this.saveValues(this.getValues());
  }

  getValues() {
    this.valueObjects = [];
    this.valueObjects.push({ key: 'comments', val: this.text });
    this.valueObjects.push({ key: 'checked', val: this.radioOptions });
    return this.valueObjects;
  }

  checkboxEnabled() {
    let enabled = !this.disabled;
    if (enabled) {
      enabled = this.radioOptions?.map(r => r.value).includes(this.radioSelection ?? -1) ?? false;
    }
    return enabled;
  }

  textChanged(text: string) {
    this.text = text;
    this.user = this.getCurrentUser();
    this.saveValues(this.getValues());
  }

  setRadio(value: any) {
    this.radioOptions?.map(r => {
      r.checked = r.value == value;
    });
  }

}
