<div class="card">
  <div class="card-header">
    <span class="card-title"
      >Credited Date for
      <span class="bold">{{ procedure.procedureNumber }}</span></span
    >
  </div>
  <div class="card-body">
    <mat-form-field appearance="outline">
      <mat-label>Please enter credit date</mat-label>
      <input
        matInput
        [matDatepicker]="creditDate"
        name="creditDay"
        [(ngModel)]="procedureTrainingCredited.creditDate"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="creditDate"
      ></mat-datepicker-toggle>
      <mat-datepicker #creditDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div matDialogActions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!procedureTrainingCredited.creditDate"
        (click)="accept()"
      >
        Confirm
      </button>
    </div>
  </div>
</div>
