<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-md-1 pt-2">
        <h6>{{ title }}</h6>
      </div>
      <div class="col-10 col-md-9 pt-2">
        <form [formGroup]="formGroup" (keydown.enter)="$event.preventDefault()">
          <mat-label
            *ngIf="textBefore"
            [innerHTML]="textBefore | placeholderHighlight : editor"
          ></mat-label>
          <mat-form-field appearance="outline" class="no-subscript">
            <mat-label>Locations</mat-label>
            <mat-chip-grid
              #chipListLoc
              aria-label="Location selection"
              [disabled]="disabled"
            >
              <mat-chip-row
                *ngFor="let location of locations"
                [ngClass]="{
                  'manual-entry': location.newLocation,
                  Location: !location.newLocation,
                  AutoEntry: location.autoLocation
                }"
                [selectable]="!disabled"
                [removable]="!disabled"
                (removed)="removeLoc(location)"
              >
                {{ location?.name }}
                <mat-icon matChipRemove *ngIf="!disabled" class="d-print-none"
                  >cancel</mat-icon
                >
              </mat-chip-row>
              <input
                matInput
                aria-label="locations"
                [disabled]="disabled ?? false"
                #locationInput
                formControlName="locationsCtrl"
                [matAutocomplete]="autoLoc"
                [matChipInputFor]="chipListLoc"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="false"
                (matChipInputTokenEnd)="addLoc($event)"
                (keyup)="onLocChange($event.code)"
                #trigger="matAutocompleteTrigger"
              />
            </mat-chip-grid>
            <mat-autocomplete
              #autoLoc="matAutocomplete"
              (optionSelected)="selectedLoc($event)"
              #locationAutocomplete
            >
              <mat-optgroup
                *ngFor="let group of locationGroups"
                [label]="group.name ?? ''"
              >
                <mat-option
                  *ngFor="let location of group.locations"
                  [value]="location"
                >
                  {{ location.name }}
                </mat-option>
              </mat-optgroup>
            </mat-autocomplete>
            <mat-error *ngIf="locationsCtrl?.invalid">{{
              getErrorMsg(locationsCtrl)
            }}</mat-error>
          </mat-form-field>
          <mat-label
            *ngIf="textAfter"
            [innerHTML]="textAfter | placeholderHighlight : editor"
          ></mat-label>
        </form>
      </div>
      <div class="col-2 d-flex flex-row justify-content-end">
        <mat-checkbox-with-roles
          [labelPosition]="'before'"
          [checked]="checked"
          [disabled]="disabled"
          (change)="checkboxChanged($event)"
          color="primary"
          [roleCodes]="roleCodes"
          [user]="user"
          [warning]="moreInfo"
          [date]="date"
        >
        </mat-checkbox-with-roles>
      </div>
    </div>
  </div>
</div>
