<!-- <button mat-button [matMenuTriggerFor]="menu"> </button> -->

<mat-list-item
  class="parent"
  [matMenuTriggerFor]="menu"
  *ngIf="ologMessages?.length"
>
  <span class="full-width" *ngIf="isExpandedOrShowing">{{
    (disabled ? "View " : "Edit ") + "Olog Messages"
  }}</span>
  <mat-icon
    class="material-symbols-outlined blue"
    matListItemIcon
    *ngIf="disabled"
    >contract</mat-icon
  >
  <mat-icon
    class="material-symbols-outlined yellow"
    matListItemIcon
    *ngIf="!disabled"
    >contract_edit</mat-icon
  >
</mat-list-item>

<mat-menu #menu="matMenu" class="olog-menu" xPosition="before">
  <button
    mat-menu-item
    *ngFor="let ologMessage of ologMessages"
    (click)="openOlogMessage(ologMessage)"
  >
    {{ ologMessage.name }}
  </button>
</mat-menu>
