import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RelatedLinkDocumentBase } from 'src/app/common/models/related-link-document-base.model';
import { utils } from 'src/app/modules/libs/utils';
import { FileV2Service } from 'src/app/services/file/file-v2.service';
import { MessageBannerService } from 'src/app/components/messages/services/message-banner.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'add-related-link-document',
  templateUrl: './add-related-link-document.component.html',
  styleUrls: ['./add-related-link-document.component.scss']
})
export class AddRelatedLinkDocumentComponent implements OnInit {

  textCtrl = new FormControl('', [Validators.required, utils.IsWhiteSpaceReactiveForm]);
  // URL = new FormControl('', Validators.pattern(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/));
  URL = new FormControl('', Validators.pattern(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|http:\/\/localhost:[0-9]{4}\/#|http:\/\/localhost:[0-9]{4})?[a-zA-Z0-9]+([\-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?(#\/.*)?$/));

  option: number = 2;
  processingFile = false;

  //messages
  addRelatedDocumentOrLink?: string;
  documentNameOrHyperlink?: string;
  youMustEnterAValidaValue?: string;
  webAddress?: string;
  clipboardText?: string;
  clipboardFromAPL?: boolean;

  constructor(
    public messageService: MessageBannerService,
    public dialogRef: MatDialogRef<AddRelatedLinkDocumentComponent>,
    public fileV2Service: FileV2Service,
    @Inject(MAT_DIALOG_DATA) public data: {
      path: string,
      disabledFileUpload: boolean,
      relatedLinkDocument: RelatedLinkDocumentBase,
      editing: boolean,
    },
  ) { }

  async ngOnInit(): Promise<void> {
    this.addRelatedDocumentOrLink = this.messageService.get("Add Related Document or Link")?.description;
    this.documentNameOrHyperlink = this.messageService.get("Document Name or Hyperlink")?.description;
    this.youMustEnterAValidaValue = this.messageService.get("You must enter a valid value")?.description;
    this.webAddress = this.messageService.get("Web Address")?.description;

    if (this.data.relatedLinkDocument != undefined && this.data.relatedLinkDocument != null) {
      this.option = (this.data?.relatedLinkDocument?.url != null && this.data?.relatedLinkDocument?.url != undefined) ? 1 : 2;
    } else {
      this.data.relatedLinkDocument = this.data?.relatedLinkDocument ?? new RelatedLinkDocumentBase();
    }

    this.clipboardText = await navigator.clipboard.readText();
    this.clipboardFromAPL = this.clipboardText.includes('%%apl%%');
  }

  accept() {
    if (this.option == 1) {
      if (this.data.relatedLinkDocument?.url != null && this.data.relatedLinkDocument.url.substr(0, 4).toUpperCase() !== 'HTTP') {
        this.data.relatedLinkDocument.url = 'HTTPS://' + this.data.relatedLinkDocument.url;
      }
      if (this.data.relatedLinkDocument?.text && !this.URL.invalid) {
        this.dialogRef.close(this.data.relatedLinkDocument);
      }
    } else if (this.option == 2) {
      if (this.data.relatedLinkDocument?.file) {
        this.dialogRef.close(this.data.relatedLinkDocument);
      }
    }
  }

  cancel() {
    if (this.data.relatedLinkDocument?.fileID != null && this.data.relatedLinkDocument.fileID != undefined && !(this.data?.editing === true) && (this.data.relatedLinkDocument.id == null || this.data.relatedLinkDocument.id == undefined)) {
      this.fileV2Service.delete(this.data.relatedLinkDocument.fileID).subscribe(
        () => { },
        error => console.error(error),
      );
      this.dialogRef.close();
    } else {
      this.dialogRef.close();
    }
  }

  optionChange() {
    if (this.data.relatedLinkDocument) {
      this.data.relatedLinkDocument.url = null;
      this.data.relatedLinkDocument.fileID = null;
      this.data.relatedLinkDocument.file = null;
    }
  }

  async pasteFromAPL() {
    const clipboardContent = this.clipboardText?.replace('%%apl%%', '').split('|');
    if (clipboardContent) {
      this.data.relatedLinkDocument.text = clipboardContent[0];
      this.data.relatedLinkDocument.url = clipboardContent[1];
      await navigator.clipboard.writeText('');
      this.clipboardText = await navigator.clipboard.readText();
      this.clipboardFromAPL = false;
    }
  }

}
