import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { RadLocation } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location';
import { ClBaseComponent } from '../../cl-base/cl-base.component';

@Component({
  selector: 'cl-editor-rad-locations',
  templateUrl: './cl-editor-rad-locations.component.html',
  styleUrls: ['./cl-editor-rad-locations.component.scss']
})
export class ClEditorRadLocationsComponent extends ClBaseComponent implements OnChanges {


  radLocations?: RadLocation[];
  radLocationsList?: RadLocation[];
  radLocationsFiltered?: RadLocation[];
  radLocations$!: Observable<RadLocation[]>;
  radLocationsSubs!: Subscription;

  radLocationsCtrl = new FormControl();

  ngOnChanges(changes: SimpleChanges): void {
    this.buildForm();
    this.loadRadLocations();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      radLocationsCtrl: this.radLocationsCtrl
    });
    if (this.disabled)
      this.formGroup.disable();
  }

  loadRadLocations() {
    this.radLocations$ = this.store.select(state => state.RadLocation.data);
    this.radLocationsSubs = this.radLocations$.subscribe(data => {
      if (data?.length) {
        this.radLocations = data;
        this.loadData();
        this.filterRadLocations();
      }
    });
  }

  filterRadLocations() {
    this.radLocationsFiltered = this.radLocations?.filter(r => !this.radLocationsList?.map(x => x.id).includes(r.id)).sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
  }

  loadData() {
    if (this.configuration) {
      this.radLocationsList = [];
      const radLocationsList = this.utils.JSONparse(this.configuration).data as RadLocation[];
      radLocationsList?.map(r => {
        const radLocation = this.radLocations?.find(x => x.id == r.id);
        if (radLocation)
          this.radLocationsList?.push(radLocation);
      });
    }
    else {
      this.radLocationsList = [];
    }
  }

  saveData() {
    const radLocationsList: any[] = [];
    this.radLocationsList?.map(r => {
      radLocationsList.push(this.utils.Serialize(r));
    });
    this.configuration = this.utils.JSONstringify({ data: radLocationsList });
    this.changed.emit(this.configuration);
    this.loadData();
    this.filterRadLocations();
  }

  add() {

  }

  remove(radLocation: RadLocation) {
    const index = this.radLocationsList?.findIndex(x => x.id == radLocation.id) as number;
    if (index >= 0) {
      this.radLocationsList?.splice(index, 1);
      this.saveData();
    }
  }

  onChange(e: any) {
    const value = (e.target.value as string).toLowerCase();
    this.filterRadLocations();
    this.radLocationsFiltered = this.radLocations?.filter(r => r.name?.toLowerCase()?.includes(value));
  }

  selected(e: any) {
    const value = e.option.value;
    this.radLocationsList?.push(value);
    this.saveData();
  }

}
