import { Component, Injector } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class RadMonitorSettingsComponent extends BaseComponent {

  constructor(
    protected override injector: Injector,
    private dialogRef: MatDialogRef<RadMonitorSettingsComponent>
  ) {
    super(injector);
  }

  close() {
    this.dialogRef.close();
  }
}
