import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";


export class ResourcesRefresh {
  static readonly type = '[Resources] Refresh';
}

export class ResourcesRefreshShutters {
  static readonly type = '[Resources] RefreshShutters';
}

export class ResourcesCreate {
  static readonly type = '[Resources] Create';

  constructor(public item: Resource) { }
}

export class ResourcesUpdate {
  static readonly type = '[Resources] Update';

  constructor(public id: number, public item: Resource) { }
}

export class ResourcesDelete {
  static readonly type = '[Resources] Delete';
  constructor(public id: number) { }
}

export class ResourcesRefreshByID {
  static readonly type = '[Resources] RefreshByID';

  constructor(public id: number) { }
}
