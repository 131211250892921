import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, Injector, } from "@angular/core";
import { SCFPrivileges, SCFStatus, ScfV2Service } from "../../scf-v2.service";
import { FormStatusEnum } from "src/app/common/enumerations/enumerations";
import { DataButtonSignature } from "src/app/common/models/data-button-signature";
import { YesNoDialogComponent } from "src/app/controls/yes-no-dialog/yes-no-dialog.component";
import { utils } from "src/app/modules/libs/utils";
import { WfSignature, WfSignatureResource, WFTaskSignatureResource } from "src/app/services/work-flow/work-flow";
import { SCFMaster } from "../../scf";
import { BaseComponent } from "src/app/common/base/base.component";
import { MessagePlaceholder } from "src/app/common/models/placeholder";


@Component({
  selector: "scf-v2-main-s4",
  templateUrl: "./scf-v2-main-s4.component.html",
  styleUrls: ["./scf-v2-main-s4.component.scss"],
})
export class ScfV2MainS4Component extends BaseComponent implements OnInit, OnChanges {
  @Input() scfMaster?: SCFMaster | null;
  @Input() disabled? = true;
  @Input() privileges?: SCFPrivileges;
  @Input() status?: SCFStatus;

  @Output() changed = new EventEmitter<SCFMaster>();
  @Output() loading = new EventEmitter<boolean>();

  disableControls = true;

  signatures?: WfSignature[];
  wfSignatures?: WfSignatureResource[];
  wfSignCount?: number;

  constructor(
    private scfService: ScfV2Service,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  ngOnInit(): void {
    this.initializeForm();
  }


  initializeForm() { }

  refresh() {
    this.setDisabled();
    this.getValues();
  }

  setDisabled() {
    this.disabled =
      (this.scfMaster?.status !== FormStatusEnum.Active &&
        this.scfMaster?.status !== FormStatusEnum.HoldPoint &&
        this.scfMaster?.status !== FormStatusEnum.AwaitingInspection) ||
      !this.getCurrentUser();
  }

  getValues() {
    this.wfSignatures = [];
    this.signatures = [];
    if (this.scfMaster) {
      this.wfSignCount = this.wfSignatures?.filter((x) => x.signed).length;
      this.createSignatures();
    }
  }

  hasHoldPointTasks() {
    return this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find((x) => x.number == 3)?.wfStepLocals?.some((x) => x.code == "HOLDPOINT");
  }

  hasNoWorkTasks() {
    return this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find((x) => x.number == 3)?.wfStepLocals?.some((x) => x.code == "NO_WORK");
  }

  createSignatures() {
    this.wfSignatures = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find((x) => x.number == 3)?.wfSignatures?.filter((x) => x.wfTaskLocal.wfStepLocal?.code !== "HOLDPOINT" && x.wfTaskLocal.wfStepLocal?.code !== "NO_WORK");
    if (this.wfSignatures) {
      for (const t of this.wfSignatures) {
        const signature: WfSignature = {
          id: t.id,
          approveId: t.approveID,
          unapproveId: t.unapproveID,
          disapproveId: t.disapproveID,
          amendmentId: t.amendmentID,
          name: t.titleBefore,
          roles: t.roles.map((r) => r?.id),
          roleCodes: t.roles.sort((a, b) => utils.sort(a?.code, b?.code)).map((r) => r?.code).join("/"),
          disabled: !t.enabled || this.disabled || t.disabled,
          approvedBy: t.signedBy,
          approved: t.signed,
          approvedOn: t.signedOn,
          approve: this.scfMaster?.status != FormStatusEnum.HoldPoint && ((t.approveEnabled && t.code != "SHIELDINGINSPECTED2") ||
            (t.approveEnabled &&
              t.approveID > 0 &&
              t.code == "SHIELDINGINSPECTED2" &&
              this.scfMaster?.status == FormStatusEnum.AwaitingInspection && this.hasRoles(t.roles))),
          unapprove: (t.unapproveEnabled && t.code != 'WORKCOMPLETE') || (t.unapproveEnabled && t.code == 'WORKCOMPLETE' && this.signCount() == 1),
          disapprove:
            t.disapproveEnabled ||
            (t.code == "SHIELDINGINSPECTED2" &&
              t.disapproveID != 0 &&
              !this.disabled &&
              this.scfMaster?.status == FormStatusEnum.AwaitingInspection && this.hasRoles(t.roles)
            ),
          amendment: t.amendmentEnabled,
          required: t.required,
          taskId: t.taskID,
          value: t.value,
          enabled: t.enabled,
          warning: false
        };
        this.wfSignCount = this.signCount();
        this.signatures?.push(signature);
      }
    }
  }

  signCount() {
    let count = 0;
    if (this.wfSignatures) {
      this.wfSignatures.map((t) => {
        if (t?.signed) {
          count++;
        }
      });
    }
    return count;
  }

  approve(s: WfSignature) {
    let status = this.scfMaster?.status;

    const wfTaskSignature: WFTaskSignatureResource = {
      taskId: s.taskId,
      actionId: s.approveId,
    };

    if (this.signCount() + 1 === this.wfSignatures?.length) {
      //  Active => WorkComplete
      status = FormStatusEnum.WorkCompleted;
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: "500px",
        data: {
          message: this.getMessage("toWorkCompleteWarning")
            ?.description,
          icon: "warn",
        },
      });
      confirm
        .afterClosed()
        .toPromise()
        .then((data) => {
          if (data) {
            this.loading.emit(true);
            this.scfService
              .sign(wfTaskSignature)
              .toPromise()
              .then((res) => {
                if (this.scfMaster?.status !== status) {
                  this.alert.message("formStateChanged", [
                    new MessagePlaceholder("{serialNo}", this.scfMaster?.serialNo),
                    new MessagePlaceholder("{status}", "Work Completed"),
                  ]
                  );
                }
                this.loading.emit(false);
              })
              .catch((error) => {
                console.log(error);
                this.alert.defaultError();
                this.loading.emit(false);
              });
          } else {
            this.getValues();
          }
        });
    } else {
      this.loading.emit(true);
      this.scfService
        .sign(wfTaskSignature)
        .toPromise()
        .then((res) => {
          this.loading.emit(false);
        })
        .catch((error) => {
          console.log(error);
          this.alert.defaultError();
          this.loading.emit(false);
        });
    }
  }

  disapprove(s: WfSignature, data: DataButtonSignature) {
    this.loading.emit(true);
    const wfTaskSignature: WFTaskSignatureResource = {
      taskId: s.taskId,
      actionId: s.disapproveId,
      reason: data.text,
    };
    this.scfService
      .sign(wfTaskSignature)
      .toPromise()
      .then(
        (res) => {
          this.loading.emit(false);
        },
        (error) => {
          console.log(error);
          this.alert.defaultError();
          this.loading.emit(false);
        }
      );
  }

  unapprove(s: WfSignature) {
    this.loading.emit(true);
    const wfTaskSignature: WFTaskSignatureResource = {
      taskId: s.taskId,
      actionId: s.unapproveId,
    };
    this.scfService
      .sign(wfTaskSignature)
      .toPromise()
      .then(
        (res) => {
          this.loading.emit(false);
        },
        (error) => {
          console.log(error);
          this.alert.defaultError();
          this.loading.emit(false);
        }
      );
  }

  setLoading(e: any) {
    this.loading.emit(e);
  }
}
