import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-message',
  templateUrl: './confirm-message.component.html',
  styleUrls: ['./confirm-message.component.scss']
})
export class ConfirmMessageComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmMessageComponent>) { }
  result_true = true;
  result_false = false;
  ngOnInit() {
  }

}
