import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, OnDestroy, SimpleChanges, Injector } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { APLCategory } from 'src/app/components/apl/services/apl-category.model';
import { APLGroup } from 'src/app/components/apl/services/apl-group.model';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { APLCategoryService } from '../../apl-category.service';
import { APLCategoryApprovalPopupComponent, APLCategoryApprovalPopupParameters } from '../dialogs/apl-category-approval-popup/apl-category-approval-popup.component';
import { APLCategoryPopupComponent, APLCategoryPopupParameters } from '../dialogs/apl-category-popup/apl-category-popup.component';
import { APLGroupPopupComponent, APLGroupPopupParameters } from '../dialogs/apl-group-popup/apl-group-popup.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedRoute, Route, Router } from '@angular/router';


@Component({
  selector: 'apl-category-v2-categories',
  templateUrl: './apl-category-v2-categories.component.html',
  styleUrls: ['./apl-category-v2-categories.component.scss']
})
export class AplCategoryV2CategoriesComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() enableMove!: boolean;
  @Input() filter!: string;

  @Output() loading = new EventEmitter<boolean>();

  aplCategorySubscription!: Subscription;
  aplCategory$!: Observable<APLCategory[]>;
  aplCategories!: APLCategory[];
  aplCategoriesFiltered!: APLCategory[];
  aplCategoriesPending!: APLCategory[];

  aplGroups!: APLGroup[];
  aplGroups$!: Observable<APLGroup[]>;
  aplGroupsSubscription!: Subscription;

  createCategoryPrivilege!: boolean;

  aplCategoryApproval = false;
  aplCategoryEdit = false;
  aplCategoryDelete = false;

  labelApproveDisapprove!: string;
  labelEdit!: string;
  labelDelete!: string;
  labelAreYouSureYouWantToDelete!: string;
  labelCategoryManagers!: string;

  dragIndex?: number;

  constructor(
    protected override injector: Injector,
    private aplCategoryService: APLCategoryService,
    private router: Router, private route: ActivatedRoute,
    private clipboard: Clipboard) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadGroups();
    this.setPermissions();
  }

  override ngOnDestroy(): void {
    this.aplCategorySubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.loadGroups();
    this.setLabels();
    this.setPermissions();
  }

  loadCategories() {
    this.aplCategory$ = this.store.select(state => state.APLCategory.APLCategories);
    this.aplCategorySubscription = this.aplCategory$.subscribe((data: APLCategory[]) => {
      if (data?.length) {
        this.aplCategories = data;
        if ((this.createCategoryPrivilege || this.aplCategoryEdit || this.aplCategoryApproval) && this.aplCategoriesPending?.length) {
          this.aplCategories = this.aplCategories.filter(c => !this.aplCategoriesPending.map(p => p.aplCategoryMasterID).includes(c.aplCategoryMasterID));
          this.aplCategories = this.aplCategories.concat(this.aplCategoriesPending);
        }
        this.aplCategories.map(x => {
          x.aplGroups = this.aplGroups?.filter(g => g.aplGroupMaster?.aplCategoryMasterID === x?.aplCategoryMasterID);
          // x.newVersion = undefined;
          // x.status = 1;
          const p = this.aplCategoriesPending?.find(y => y.previousVersionID == x.id);
          if (p) {
            x.newVersion = p;
            x.status = p.status;
          }
        });
        this.aplCategories = this.aplCategories?.sort((a, b) => a.aplCategoryMaster.order - b.aplCategoryMaster.order);
        this.aplCategoriesFiltered = this.utils.cloneDeep(this.aplCategories);
        this.applyFilter();
        this.loading.emit(false);
      }
    });
  }

  loadGroups() {
    this.loading.emit(true);
    this.aplGroups$ = this.store.select(state => state.APLGroup.all);
    this.aplGroupsSubscription = this.aplGroups$.subscribe(data => {
      if (data?.length) {
        this.aplGroups = data;
        this.aplGroups.map(group => group.aplGroupRoles?.map(groupRole => {
          groupRole.role?.userRole?.sort((a, b) => this.utils.sort(a.order, b.order));
        }));
        this.loadCategoriesPending();
      }
    });
  }

  applyFilter() {
    if (this.filter) {
      this.filter = this.filter.trim().toLowerCase();
      this.aplCategoriesFiltered.map(c => {
        c.aplGroups?.map(g => {
          g.aplGroupRoles?.map(gr => {
            if (gr.role)
              gr.role.userRole = gr.role.userRole?.filter(r =>
                r.user?.name?.toLowerCase().includes(this.filter) ||
                r.user?.initials?.toLowerCase()?.includes(this.filter)
              );
          });
          g.aplGroupRoles = g.aplGroupRoles?.filter(r =>
            r.role?.userRole?.length ||
            r.role?.name?.toLowerCase()?.includes(this.filter) ||
            r.role?.information?.toLowerCase()?.includes(this.filter)
          );
        });
        c.aplGroups = c.aplGroups?.filter(g =>
          g.aplGroupRoles?.length ||
          g.name?.toLowerCase()?.includes(this.filter) ||
          g.description?.toLowerCase()?.includes(this.filter) ||
          g.aplGroupManagers?.map(gm => gm.user)?.some(u =>
            u?.name?.toLowerCase().includes(this.filter) ||
            u?.initials?.toLowerCase().includes(this.filter)) ||
          g.aplProcedureTrainingCoordinators?.map(c => c.user)?.some(u =>
            u?.name?.toLowerCase().includes(this.filter) ||
            u?.initials?.includes(this.filter))
        );
      });
      this.aplCategoriesFiltered = this.aplCategoriesFiltered.filter(c =>
        c.aplGroups?.length ||
        c.name?.toLowerCase()?.includes(this.filter) ||
        c.code?.toLowerCase()?.includes(this.filter) ||
        c.aplCategoryManagers?.map(cm => cm.user)?.some(u =>
          u?.name?.toLowerCase()?.includes(this.filter) ||
          u?.initials?.toLowerCase()?.includes(this.filter)) ||
        c.aplCategoryManagers?.map(cm => cm.user)?.some(u =>
          u?.initials?.toLowerCase()?.includes(this.filter) ||
          u?.initials?.toLowerCase().includes(this.filter)))
        .sort((a, b) => a.aplCategoryMaster.order - b.aplCategoryMaster.order);
    }
  }

  loadCategoriesPending() {
    this.loading.emit(true);

    this.aplCategory$ = this.store.select(state => state.APLCategory.APLCategoriesWithPending);
    this.aplCategorySubscription = this.aplCategory$.subscribe((data: APLCategory[]) => {
      this.aplCategoriesPending = data.filter(x => x.status < 0);
      this.loadCategories();
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    const from = this.aplCategoriesFiltered[event.previousIndex];
    const to = this.aplCategoriesFiltered[event.currentIndex];
    this.loading.emit(true);
    this.aplCategoryService.reorder(from.aplCategoryMasterID, to.aplCategoryMasterID).subscribe(
      () => { },
      error => this.alert.defaultError(),
    );

    moveItemInArray(this.aplCategoriesFiltered, event.previousIndex, event.currentIndex);
  }

  onDragStarted(i: number) {
    this.dragIndex = i;
  }

  setPermissions() {
    this.aplCategoryApproval = this.hasPrivilege(PrivilegeEnum.APLCategoryApproval);
    this.aplCategoryEdit = this.hasPrivilege(PrivilegeEnum.APLCategoryEdit);
    this.aplCategoryDelete = this.hasPrivilege(PrivilegeEnum.APLCategoryDelete);
    this.createCategoryPrivilege = this.hasPrivilege(PrivilegeEnum.APLCategoryCreate);
  }

  canAddGroup(aplCategory: APLCategory) {
    const createGroupPrivilege = aplCategory?.aplCategoryManagers?.map(x => x.userID).includes(this.currentUser?.id ?? 0) || this.hasPrivilege(PrivilegeEnum.APLGroupCreate);
    return createGroupPrivilege;
  }

  canApproveOrReject(aplCategory: APLCategory): boolean {
    return this.aplCategoryApproval && aplCategory?.status < 0;
  }

  canEdit(aplCategory: APLCategory): boolean {
    return this.aplCategoryEdit && aplCategory?.status === 1;
  }

  canDelete(aplCategory: APLCategory): boolean {
    return this.aplCategoryDelete && aplCategory?.status === 1;
  }

  hasActions(aplCategory: APLCategory): boolean {
    return this.canApproveOrReject(aplCategory) || this.canEdit(aplCategory) || this.canDelete(aplCategory);
  }

  openApprovalPopup(aplCategory: APLCategory) {
    this.dialog.open(APLCategoryApprovalPopupComponent, {
      height: 'auto',
      width: '25vw',
      data: {
        aplCategory: aplCategory.newVersion ?? aplCategory,
        user: this.currentUser
      } as APLCategoryApprovalPopupParameters,
    }).afterClosed().subscribe(data => {
      if (data) this.loading.emit(true);
    });

  }

  openEditCategoryPopup(aplCategory: APLCategory) {
    this.dialog.open(APLCategoryPopupComponent, {
      height: 'auto',
      width: '25vw',
      data: {
        aplCategory,
      } as APLCategoryPopupParameters,
    });
  }

  deleteCategory(aplCategory: APLCategory) {
    this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.labelAreYouSureYouWantToDelete,
      },
    }).afterClosed().subscribe(data => {
      if (data) {
        this.aplCategoryService.delete(aplCategory.id).subscribe(
          () => { },
          error => this.alert.defaultError(),
        );
      }
    });
  }

  getManagers(aplCategory: APLCategory): string {
    return aplCategory?.aplCategoryManagers?.filter(m => m.user?.status == 1)?.map(x => x.user?.name).join(', ');
  }

  categoryOpen(aplCategory: APLCategory, val: boolean) {
    if (aplCategory.open != val) {
      aplCategory.open = val;
    }
  }

  setLabels() {
    this.labelApproveDisapprove = this.getMessage('APL_Category_ApproveDisapprove')?.description;
    this.labelEdit = this.getMessage('APL_Category_Edit')?.description;
    this.labelDelete = this.getMessage('APL_Category_Delete')?.description;
    this.labelAreYouSureYouWantToDelete = this.getMessage('APL_Category_AreYouSureYouWantToDelete')?.description;
    this.labelCategoryManagers = this.getMessage('APL_Category_Card_CategoryManagers')?.description;
    if (!this.labelApproveDisapprove) {
      setTimeout(() => {
        this.setLabels();
      }, 500);
    }
  }

  openCreateGroupPopup(aplCategory: APLCategory) {
    this.dialog.open(APLGroupPopupComponent, {
      height: 'auto',
      width: '40vw',
      disableClose: true,
      data: {
        aplCategoryMasterID: aplCategory.aplCategoryMasterID,
      } as APLGroupPopupParameters,
    });
  }

  copyLink(aplCategory: APLCategory) {
    const link = this.getCurrentURL() + '/' + aplCategory.id;
    this.clipboard.copy(link);
    this.alert.info(link + ' Copied to the Clipboard');
  }

  copyLinkEmbedded(aplCategory: APLCategory) {
    const link = '%%apl%%' + aplCategory.name + '|' + this.getCurrentURL() + '/' + aplCategory.id;
    this.clipboard.copy(link);
    this.alert.info(link.replace('%%apl%%', '') + ' Copied to the Clipboard');
  }

  getCurrentURL() {
    // Get the origin (e.g., http://localhost:4200)
    const origin = window.location.origin;

    // Get the current route
    const fullPath = this.router.url;

    // Split the path by '/'
    const urlSegments = fullPath.split('/');

    // Check if the last segment is a numeric ID
    const lastSegment = urlSegments[urlSegments.length - 1];
    if (!isNaN(Number(lastSegment))) {
      // If it's a number, remove it
      urlSegments.pop();
    }

    // Join the remaining segments
    const pathWithoutLastSegment = urlSegments.join('/');

    // Combine origin with the path
    return origin + '/#' + pathWithoutLastSegment;
  }


}
