<div class="card">
  <div class="card-header">
    <span class="card-title">Select Rad Meter to Add</span>
  </div>

  <div class="card-body">
    <mat-form-field appearance="outline" class="no-subscript">
      <mat-label>RadMeter</mat-label>
      <input
        type="text"
        placeholder="Select RadMeter"
        matInput
        name="radMeterCtrl"
        [formControl]="radMeterCtrl"
        [matAutocomplete]="autoRadMeter"
        (keyup)="changedRadMeter($event)"
      />
      <mat-autocomplete
        #autoRadMeter="matAutocomplete"
        #radMeterAutocomplete
        [displayWith]="displayObject"
        (optionSelected)="selectedRadMeter($event.option.value)"
      >
        <mat-option
          class="option"
          *ngFor="let radMeter of radMeters"
          [value]="radMeter"
          class="mat-option-baseline"
        >
          <div class="row border-bottom">
            <div class="col-3">
              {{ radMeter.serialNumber }}
            </div>
            <div class="col-9 small lh18">
              Model:
              <b>{{
                radMeter.radMeterModel?.name + "/" + radMeter.radMeterType?.name
              }}</b
              ><br />
              Cal Due:
              <b
                class="date"
                [ngClass]="{
                  expired: isDateExpired(radMeter.calibrationDueDate)
                }"
                >{{ radMeter.calibrationDueDate | date }}</b
              >
            </div>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="card-footer row no-gutters full-width">
    <div class="col-6">
      <button mat-stroked-button color="primary" (click)="addNewMeter()">
        Add New RAD Meter
      </button>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <button mat-flat-button color="warn" (click)="close()">Cancel</button>
      <button
        mat-flat-button
        class="ml-2"
        color="primary"
        [disabled]="!radMeterCtrl.value"
        (click)="add()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
