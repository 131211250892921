import { Alert } from './../../services/alert/store/alert.action';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, Subject } from 'rxjs';
import { AcceleratorCatalog } from 'src/app/components/catalogs/accelerator-catalog/services/accelerator-catalog';
import { BeamlineCatalog } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { Move } from 'src/app/components/catalogs/catalog-model';
import { utils } from 'src/app/modules/libs/utils';
import { PendingAccelerator, PendingResource } from 'src/app/services/pending-approvals/pending-approval';

@Component({
  selector: 'catalog-history-navigation',
  templateUrl: './catalog-history-navigation.component.html',
  styleUrls: ['./catalog-history-navigation.component.scss']
})
export class CatalogHistoryNavigationComponent implements OnChanges {

  @Input() catalogs?: AcceleratorCatalog[] | BeamlineCatalog[] = [];
  @Output() selectedAccelerator = new EventEmitter<AcceleratorCatalog>();
  @Output() selectedBeamline = new EventEmitter<BeamlineCatalog>();

  catalog?: AcceleratorCatalog | BeamlineCatalog;
  catalogInfo?: PendingAccelerator | PendingResource | null;

  index: number = 0;

  move = Move;
  sliderCtrl!: FormControl;

  private indexSubject = new Subject<number>();


  ngOnInit() {
    this.indexSubject.pipe(debounceTime(1500)).subscribe((index) => {
      this.index = index;
      this.selectedIndex();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.catalogs?.length) {
      this.index = this.catalogs.length - 1;
      this.sliderCtrl = new FormControl(this.index);
      this.selectedIndex();
    }
  }

  moveOnHistory(move: Move) {
    switch (move) {
      case Move.FIRST:
        this.index = 0;
        break;
      case Move.LAST:
        this.index = (this.catalogs?.length ?? 1) - 1;
        break;
      default:
        this.index += move;
    }

    if (this.index < 0) this.index = 0;
    if (this.index > (this.catalogs?.length ?? 1) - 1) this.index = (this.catalogs?.length ?? 1) - 1;
    this.selectedIndex();
  }

  changed(e: any) {
    const value = e;
    this.indexSubject.next(value);
  }

  selectedIndex() {
    const catalog: any = utils.cloneDeep(this.catalogs?.[this.index]);
    if (catalog.pendingAcceleratorCatalog) {
      this.catalog = catalog as AcceleratorCatalog;
      this.catalogInfo = this.catalog.pendingAcceleratorCatalog;
      this.selectedAccelerator.emit(this.catalog);
    }
    else if (catalog.pendingResource) {
      this.catalog = catalog as BeamlineCatalog;
      this.catalogInfo = this.catalog.pendingResource;
      this.selectedBeamline.emit(this.catalog);
    }


  }
}
