<!-- <div class="row">
  <div class="col-12">
    <div class="justify-content-end">
      <button
        mat-mini-fab
        class="closexat float-right"
        color="warn"
        (click)="close()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div> -->
<!-- <div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <h4 class="w-100" mat-dialog-title>Rad Meters Details</h4>
    </div>
    <div class="col-6 text-right">
      <button
        mat-flat-button
        color="accent"
        class="mr-3"
        (click)="manageMeterTypes()"
      >
        Types
      </button>

      <button
        mat-flat-button
        color="accent"
        class="mr-3"
        (click)="manageMeterUnits()"
      >
        Units
      </button>

      <button
        mat-flat-button
        color="accent"
        class="mr-3"
        (click)="manageMeterModels()"
      >
        Models
      </button>
    </div>
  </div>
  <hr />


</div> -->

<div class="card">
  <div class="card-header">
    <span class="card-title"
      >{{ isModifiying ? "Edit " : "Create " }} Rad Meter</span
    >
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field>
          <mat-label>Serial No.</mat-label>
          <input
            matInput
            [(ngModel)]="radMeter.serialNumber"
            [disabled]="false"
            name="name"
          />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field>
          <mat-label>Model</mat-label>
          <mat-select
            [(value)]="modelSelected"
            name="model"
            placeholder="Select Model"
          >
            <mat-option
              [value]="itemList.id"
              *ngFor="let itemList of listMeterModels"
              >{{ itemList.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field>
          <mat-label>Type</mat-label>
          <mat-select [(value)]="typeSelected" name="modeltype">
            <mat-option
              [value]="itemList.id"
              *ngFor="let itemList of listMeterTypes"
              >{{ itemList.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field>
          <mat-label>Unit</mat-label>
          <mat-select [(value)]="unitSelected" name="modelunit">
            <mat-option
              [value]="itemList.id"
              *ngFor="let itemList of listMeterUnits"
              >{{ itemList.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field>
          <mat-label>Calibration Due Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="radMeter.calibrationDueDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        *ngIf="showCancelButton"
        mat-flat-button
        color="warn"
        (click)="onNoClick()"
      >
        Cancel
      </button>
      <button mat-flat-button color="primary" (click)="saveCatalog()">
        Accept
      </button>
    </div>
  </div>
</div>
