import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, OnInit, OnDestroy, ViewChild, Injector } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { WFStepLocalResource, WfTaskLocalResource, WFStepLocalStatus } from 'src/app/services/work-flow/work-flow';
import { Configuration } from '../../../checklist-builder/checklist-builder-sections/checklist-builder-section/checklist-builder-section.component';
import { Checklist } from '../../../checklists';
import { ChecklistsService } from '../../../checklists.service';
import { ChecklistTasksComponent } from '../../checklist-tasks/checklist-tasks.component';


@Component({
  selector: "checklist-step",
  templateUrl: "./checklist-step.component.html",
  styleUrls: ["./checklist-step.component.scss"],
})
export class ChecklistStepComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentChecklist?: Checklist | null;
  @Input() step!: WFStepLocalResource;
  @Input() precheck!: boolean;

  @Output() loading = new EventEmitter<boolean>();
  @Output() checkShared = new EventEmitter<any>();

  @ViewChild(ChecklistTasksComponent) tasksComponent!: ChecklistTasksComponent;
  hidden!: boolean;

  disabled!: boolean;

  tasks!: WfTaskLocalResource[];
  tasks$!: Observable<WfTaskLocalResource[]>;
  tasksSubs!: Subscription;

  checklists!: Checklist[];
  checklists$!: Observable<Checklist[]>;
  checklistsSubs!: Subscription;

  roles!: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  wfStepLocalStatus = WFStepLocalStatus;

  config!: Configuration;
  canDuplicate!: boolean;
  touched!: boolean;

  tooltip!: string;

  wfTaskLocals?: WfTaskLocalResource[];
  wfTaskLocals$!: Observable<WfTaskLocalResource[]>;
  wfTaskLocalsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private service: ChecklistsService,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.tasksSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.config = this.utils.JSONparse(this.step.configuration);
    if (!this.config) { this.config = new Configuration(); }
    this.loadRoles();
    this.canDuplicate = this.hasRoles(this.config.roleIDs)
    this.getTasks();
  }

  loadWFTaskLocals() {
    this.wfTaskLocals$ = this.store.select(state => state.WFTaskLocals.data);
    this.wfTaskLocalsSubs = this.wfTaskLocals$.subscribe(data => {
      if (data?.length) {
        this.wfTaskLocals = data;
        this.tasks = this.wfTaskLocals.filter(t => t.wfStepLocalID == this.step.id);
      }
    });
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      if (data.length) {
        this.roles = data.filter(r => this.config.roleIDs?.includes(r.id));
        this.tooltip = 'Duplicate Step (Allowed roles: ' + this.roles?.map(r => r.code).join(', ') + ')';
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentChecklist']) {
      this.hidden = this.step.wfStepStatusLocals?.find(x => x.status == this.currentChecklist?.checklistStatusID)?.hidden ?? false;
      this.disabled = !this.step.wfStepStatusLocals.map(x => x.status).includes(this.currentChecklist?.checklistStatusID);
      this.getTasks();
    }
  }

  duplicate(e: any) {
    e.stopPropagation();
    const yesno = this.dialog.open(YesNoDialogComponent, {
      width: "400px",
      data: {
        message: this.getMessage("ChecklistDuplicateStepConfirmation").description,
        icon: "question",
      },
    });
    yesno.afterClosed().subscribe(async (data) => {
      if (data) {
        this.loading.emit(true);
        await this.service.duplicateStep(this.step.id).toPromise();
        this.loading.emit(false);
      }
    });
  }

  delete(e: any) {
    e.stopPropagation();
    const yesno = this.dialog.open(YesNoDialogComponent, {
      width: "400px",
      data: {
        message: this.getMessage("ChecklistDeleteStepConfirmation").description,
        icon: "question",
      },
    });
    yesno.afterClosed().subscribe(async (data) => {
      if (data) {
        this.loading.emit(true);
        await this.service.deleteStep(this.step.id).toPromise();
        this.loading.emit(false);
      }
    });
  }

  setDisabled() {
  }

  getTasks() {
    this.loadWFTaskLocals();
    this.tasks = this.step?.wfTaskLocals;
    this.tasks.map(t => this.service.taskTouched(t));
    this.touched = this.tasks.some(t => t.isTouched);
  }

  isStepExpanded() {
    const expanded = this.step.collapsible ? !this.step.collapsed : true;
    return expanded;
  }

  saveState(collapsed: boolean) {
    if (collapsed != this.step.collapsed && this.step.collapsed != undefined && this.currentChecklist) {
      this.step.collapsed = collapsed;
      this.service.saveStepExpanded(this.currentChecklist.id, this.step.id, collapsed);
    }
  }

}
