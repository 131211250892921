<div class="d-print-flex">
  <mat-form-field>
    <mat-label>{{ placeholder }}</mat-label>
    <mat-chip-grid #userChips [disabled]="disabled">
      <mat-chip-row
        *ngFor="let userRelated of selectedUsers"
        [selectable]="true"
        [removable]="true"
        (removed)="remove(userRelated)"
      >
        {{ userRelated?.user?.name }}
        <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
      </mat-chip-row>
      <input
        #userInput
        [formControl]="control"
        [matAutocomplete]="userAutocomplete"
        [matChipInputFor]="userChips"
        [matChipInputAddOnBlur]="false"
        (keyup)="onUserInputKeyup($event)"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #userAutocomplete="matAutocomplete"
      (optionSelected)="selected($event.option.value)"
    >
      <mat-option *ngFor="let user of filteredUsers" [value]="user">{{
        user?.name
      }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
