<div class="card">
  <div class="card-header">
    <span class="card-title"
      >{{ currentMeterModel.id ? "Edit " : "Add " }}Meter Model</span
    >
  </div>
  <div class="card-body">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input
        matInput
        [(ngModel)]="currentMeterModel.name"
        [disabled]="!(adding || modifying) || selected"
        name="name"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Description</mat-label>
      <input
        matInput
        [(ngModel)]="currentMeterModel.description"
        [disabled]="!(adding || modifying)"
        name="description"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Status</mat-label>
      <mat-select
        [(ngModel)]="currentMeterModel.status"
        [disabled]="!(adding || modifying) || disabledStatus"
        name="status"
      >
        <mat-option *ngFor="let RCstatus of statusList" [value]="RCstatus.id">
          {{ RCstatus.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="disabledTimer"
        (click)="saveMeterModel()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
