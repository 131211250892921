<div class="full-width">
  <div
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    id="{{ 'step-' + wfStepLocalID }}"
    [cdkDropListConnectedTo]="stepDropLists"
    [cdkDropListData]="tasks"
  >
    <div *ngFor="let task of tasks">
      <div
        cdkDrag
        (cdkDragMoved)="service.onDragMoved($event)"
        class="d-flex mb-1 align-items-center"
        cdkDragLockAxis="y"
        [cdkDragDisabled]="tasks.length == 1 || (task.logID ?? 0) > 0"
      >
        <div class="d-flex align-items-center w-48">
          <button
            mat-icon-button
            [class.no-display]="disabled || tasks.length == 1 || task.logID"
            cdkDragHandle
            color="accent"
            style="cursor: move"
          >
            <mat-icon>reorder</mat-icon>
          </button>

          <div
            class="d-flex flex-column"
            *ngIf="showDeleted && task.logID && !isDeletedStep"
          >
            <button
              mat-icon-button
              matTooltip="Restore"
              class="restore-button"
              (click)="restore(task)"
            >
              <mat-icon color="primary">restore_from_trash</mat-icon>
            </button>

            <button
              mat-icon-button
              color="warn"
              [matTooltip]="'Delete forever'"
              (click)="deleteForever(task)"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </div>
        <div
          class="d-flex full-width boxed"
          [ngClass]="{ 'deleted-task': task.logID && !isDeletedStep }"
        >
          <div style="width: 100%" class="pl-3">
            <checklist-builder-task
              [task]="task"
              class="full-width"
              (deletedTask)="deleted($event)"
              (renumber)="refreshTasks()"
              [disabled]="disabled"
              (updated)="updateTask($event)"
              (duplicated)="duplicated($event)"
              (loading)="setLoading($event)"
              [currentVersion]="currentVersion"
              [procedure]="procedure"
              id="{{ 'task-' + task?.id }}"
            ></checklist-builder-task>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button
    mat-stroked-button
    color="accent"
    class="ml-5"
    *ngIf="tasks && !disabled"
    (click)="add()"
    [matTooltip]="'Add New Task'"
  >
    <mat-icon>add_box</mat-icon>Add Task
  </button>
</div>
