export enum SignalRAction {
  Add = 1,
  Update = 2,
  Delete = 3,
  Get = 4,
  PartialUpdate = 5,
  RequestRefresh = 6,

  //
  Olog = 100
}
