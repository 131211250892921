import { TOCA } from "src/app/components/catalogs/beamline-catalog/resource/toca";


export class TOCARefresh {
  static readonly type = '[TOCA] Refresh';
}

export class TOCACreate {
  static readonly type = '[TOCA] Create';

  constructor(public item: TOCA) { }
}

export class TOCAUpdate {
  static readonly type = '[TOCA] Update';

  constructor(public id: number, public item: TOCA) { }
}

export class TOCADelete {
  static readonly type = '[TOCA] Delete';

  constructor(public id: number) { }
}
