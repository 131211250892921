import { ShutterResource } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { User } from "src/app/components/catalogs/user-catalog/services/user";
import { WFTableResource, WFTableConfig } from "../workflow/workflow";
import { WfSignatureResource } from "src/app/services/work-flow/work-flow";
import { ChecklistExecStatus } from "../checklists/checklists";

export class KEResource {
  id!: number;
  name?: string;
  serialNo!: string;
  shutterId?: number;
  shutterName?: string;
  branchlineId?: number;
  branchlineName?: string;
  status!: number;
  type?: number;
  statusName?: string;
  requesterId?: number;
  requesterName?: string;
  createdOn?: Date;
  createdBy?: number;
  createdByName?: string;
  locations?: Location[];
  wfTableID?: number;
  typeName?: string;
  versionName?: string;
  resource?: ShutterResource;
  wfTable?: WFTableResource;
  wfSignatures?: WfSignatureResource[];

  checklistStatusID?: number;
  checklistStatus?: ChecklistExecStatus;
}

export class SaveKEResource {
  id!: number;
  name!: string;
  serialNo!: string;
  type!: number;
  shutterId!: number;
  branchlineId!: number;
  status!: number;
  requesterId?: number;
  wfTableID!: number;
}

export class KEStatusResource {
  status!: number;
  completed!: boolean;
  userID!: number;
  taskCount!: number;
}

export class SaveKEStatusResource {
  id!: number;
  status!: number;
}

export class KEVersionResource {
  id?: number;
  name!: string;
  type!: number;
  status?: number;
  requesterId!: number;
  shutterId!: number;
  branchlineId!: number;
  wfTableID?: number;
  createdOn?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedBy?: number;
  updatedOn?: Date;
  updatedByName?: string;
  resourceID?: number;
  approvedByID?: number;
  approvedBy?: User;
  approvedOn?: Date;
  wfTableConfig?: WFTableConfig;
  procedureMasterID?: number;
}

export class KETStatusResource {
  activeFullVersion!: KEVersionResource;
  activeAbbrVersion!: KEVersionResource;
  newFullVersions!: number;
  newAbbrVersions!: number;
  templateStatus!: number;
}

export interface EpicsValuesResource {
  id: number;
  name: string | null;
  type: number;
  channelName?: string | null;
  vacuumValue: number | null;
  location: string | null;
  keVacuumMinRequirement: number | null;
  keVacuumMaxRequirement: number | null;
  question?: string | null;
  isValid?: boolean;
}
