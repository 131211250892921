import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as AcceleratorStatusActions from './accelerator-status.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';

@Injectable({
  providedIn: 'root',
})
export class AcceleratorStatusListener {
  constructor(
    private store: Store,
    private signalR: SignalRService,
  ) { }

  initStore() {
    this.store.dispatch(new AcceleratorStatusActions.AcceleratorStatusRefresh());
  }

  initListeners() {
    this.signalR.hubConnection?.on(Channels.ACCELERATORSTATUS, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Get:
          this.store.dispatch(new AcceleratorStatusActions.AcceleratorStatusRefresh());
          break;
      }
    });
  }
}
