<mat-form-field appearance="outline" class="no-subscript">
  <mat-label>Color</mat-label>
  <input
    type="text"
    placeholder="Select Color"
    matInput
    name="boardTypeCtrl"
    [formControl]="colorCtrl"
    [matAutocomplete]="autoColor"
    [ngClass]="getClass(colorSelected)"
  />
  <mat-autocomplete
    class="color-picker"
    #autoColor="matAutocomplete"
    #colorAutocomplete
    [displayWith]="displayObject"
    (optionSelected)="selectedColor($event)"
  >
    <mat-option
      *ngFor="let color of colorsFiltered"
      [value]="color"
      class="color mat-option-color"
      [ngClass]="getClass(color, true)"
    >
      <mat-icon *ngIf="color == colorSelected">check</mat-icon>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
