<div class="mat-container">
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> APL Categories </mat-header-cell>
      <mat-cell *matCellDef="let e">
        {{ e.code }}
        <span *ngIf="e.open">
          <mat-icon>arrow_forward_ios</mat-icon>
        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="openCategory(row)"
      [ngClass]="{ pending: row.status != 1, open: row.open }"
    ></mat-row>
  </mat-table>
</div>
