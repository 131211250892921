import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { Form } from 'src/app/services/forms/form';
import { LogToShowResource } from 'src/app/services/logs/log-to-show-resource';
import { SignResource } from 'src/app/services/logs/signs';
import { WfSignatureResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';

@Component({
  selector: 'transaction-log-amendments',
  templateUrl: './transaction-log-amendments.component.html',
  styleUrls: ['./transaction-log-amendments.component.scss']
})
export class TransactionLogAmendmentsComponent extends BaseComponent implements OnChanges {

  @Input() serialNo?: string;
  @Input() formType?: FormType;

  showUpdates = false;
  generalLogs?: LogToShowResource;
  signLogs?: SignResource[];

  show?: boolean;
  showAddedUpdates?: boolean;
  showDeletedUpdates?: boolean;
  showModifiedUpdates?: boolean;

  amendmentList?: any[];

  loading?: boolean;
  hasOriginal?: boolean;

  // form?: Form;

  constructor(
    protected override injector: Injector,
    private wfService: WorkFlowService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  refresh() {
    if (this.serialNo) {
      this.loading = true;
      this.wfService.getLogsBySn(this.serialNo).subscribe(response => {
        this.generalLogs = response;
        if (this.generalLogs) {
          this.show = true;
        }
        this.fillLogs();
        this.generalLogs.signs = this.generalLogs.signs?.sort((a, b) => moment(a.signedOn).isAfter(b.signedOn) ? 1 : moment(a.signedOn).isBefore(b.signedOn) ? -1 : 0);
        this.signLogs = this.generalLogs.signs?.filter(s => s.amendmentId === 0);
        this.signLogs?.map(x => {
          if (x.name?.includes('Disapproved') && !x.reason) {
            x.name = x.name.replace('Disapproved', 'Unsubmitted');
          }
        });
      });
    } else {
      this.show = false;
    }
  }

  fillLogs() {
    if (this.generalLogs?.originalBody) {
      switch (this.formType) {
        case FormType.EBF:
          this.generalLogs.originalBodyEB2 = JSON.parse(this.generalLogs.originalBody) as Form;
          break;
        case FormType.PPSTB:
          this.generalLogs.originalBodyPPSTB = JSON.parse(this.generalLogs.originalBody) as Form;
          break;
      }
      this.hasOriginal = true;
    }
    this.fillUpdates();
  }

  fillUpdates() {

    if (this.generalLogs?.updates?.length) {
      this.generalLogs.updates.map((update, i) => {
        if (update.oldBody && update.currentBody) {
          update.header = '<span class="yellow">Amendment ' + (i + 1) + '</span>';
          update.form = JSON.parse(update.currentBody);
        }
        if (update.added && update.added.length > 0) {
          this.showAddedUpdates = true;
        }
        if (update.deleted && update.deleted.length > 0) {
          this.showDeletedUpdates = true;
        }
        if (update.modified && update.modified.length > 0) {
          this.showModifiedUpdates = true;
        }
        this.generateAmendmentViews();
      });
      this.showUpdates = true;
    } else { this.showUpdates = false; }

  }

  getLogHeadText(amendmentId?: number): string {
    const fakeAmendmentId = this.findIndex(amendmentId);
    const res = (fakeAmendmentId ? ('<span class="yellow">Amendment ' + fakeAmendmentId) : '<span class="red">Amendment Cancelled') + '</span>';
    return res;
  }

  findIndex(amendmentId?: number): number {
    if (amendmentId) {
      let fakeAmendmentId = this.amendmentList?.findIndex(amid => amid === amendmentId);
      if (fakeAmendmentId)
        return fakeAmendmentId + 1;
    }
    return 0;
  }

  getAmendmentList(wfSignatures: WfSignatureResource[]): number[] {
    let olderAmendmentId = -1;
    let res: number[] = [];
    wfSignatures.map(t => {
      if (t.amendmentID !== olderAmendmentId) {
        olderAmendmentId = t.amendmentID;
        res.push(olderAmendmentId);
      }
    });

    res = [...new Set(res)].sort((a, b) => a - b);
    return res;
  }

  generateAmendmentViews() {
    this.generalLogs?.updates?.map((update, i) => {
      if (i == 0)
        update.form!.formVersion.previousVersion = this.generalLogs?.originalBodyEB2?.formVersion;
      else {
        const current = this.utils.JSONparse(this.generalLogs?.updates?.[i - 1].currentBody);
        const previous = this.utils.JSONparse(this.generalLogs?.updates?.[i - 1].oldBody);
        update.form = current;
      }
    });
  }

  getTopPosition(parent: HTMLDivElement) {
    const rect = parent.getBoundingClientRect();
    if (rect.top < 170 && rect.bottom > 250)
      return {
        position: 'fixed', top: '169px', right: '90px', 'padding-bottom': '3px', 'padding-top': '5px',
        'box-shadow': '0 2px 3px #aaa'
      };
    else return { position: 'relative' };
  }

  getUserSign(name?: string) {
    return this.users.find(u => u.name == name);
  }
}
