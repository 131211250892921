import { Component, ElementRef, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { utils } from 'src/app/modules/libs/utils';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ProcedureSignature } from 'src/app/components/procedure/models/procedure-signature.model';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { UserService } from 'src/app/components/catalogs/user-catalog/services/user.service';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { BaseComponent } from 'src/app/common/base/base.component';

export class UserAutocompletePopupParameters {
  public placeholder!: string;
  public selectedUsers!: ProcedureSignature[];
  public privileges?: PrivilegeEnum[];
}

@Component({
  selector: 'user-autocomplete-popup',
  templateUrl: './user-autocomplete-popup.component.html',
  styleUrls: ['./user-autocomplete-popup.component.scss']
})
export class UserAutocompletePopupComponent extends BaseComponent implements OnInit {
  placeholder: string = '';
  disabled: boolean = false;
  selectedUsers: any[] = [];
  user!: User | null;

  allUsers: User[] = [];
  availableUsers: User[] = [];
  filteredUsers: User[] = [];

  @ViewChild('input') input!: ElementRef;

  constructor(
    protected override injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    public data: UserAutocompletePopupParameters,
    public dialogRef: MatDialogRef<UserAutocompletePopupComponent>,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.placeholder = this.data?.placeholder ?? this.placeholder;
    this.selectedUsers = this.data?.selectedUsers ?? this.selectedUsers;
    const users = this.users.filter(u => u.status == 1);
    this.allUsers = this.data.privileges ? users.filter(u => u.userRole?.some(r => this.utils.intersect(r.role?.rolePrivileges?.map(p => p.privilege), this.data.privileges))) : users;
    this.available();
    this.filter();
  }

  available() {
    this.availableUsers = [];
    this.availableUsers = utils.cloneDeep(this.allUsers);
    this.selectedUsers.map(selectedUser => {
      const index = this.availableUsers.map(x => x.id).indexOf(selectedUser.userID);

      if (index >= 0) {
        this.availableUsers.splice(index, 1);
      }
    });
    this.filteredUsers = utils.cloneDeep(this.availableUsers);
  }

  filter(e?: any) {
    const text = e?.target?.value
    const code = e?.code;
    if (text)
      if (!(code?.includes('Arrow') || code?.includes('Enter'))) {
        this.user = null;
        this.filteredUsers = this.availableUsers.filter(x => x.name?.trim().toLowerCase().includes(text?.trim()?.toLowerCase()));
      }
  }

  select(user: User) {
    this.user = user;
    this.input.nativeElement.value = user.name;
  }

  accept() {
    this.dialogRef.close({ user: this.user });
  }

}
