import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ResourcesCreate, ResourcesDelete, ResourcesRefresh, ResourcesRefreshByID, ResourcesRefreshShutters, ResourcesUpdate } from './resources.action';
import { EpicsValuesResource } from 'src/app/components/kenable/ke';
import { Resource, ShutterResource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';

export class ResourcesStateModel {
  data: Resource[] = [];
  shutters: ShutterResource[] = [];
  epics: EpicsValuesResource[] = [];
}

@State<ResourcesStateModel>({
  name: 'Resources',
  defaults: {
    data: [],
    shutters: [],
    epics: []
  }
})
@Injectable()
export class ResourcesState {
  constructor(
    private _service: ResourcesService,
  ) { }

  @Selector()
  static read(state: ResourcesStateModel) {
    return state.data;
  }

  @Action(ResourcesRefresh)
  refresh({ patchState }: StateContext<ResourcesStateModel>) {
    this._service.getAllResources().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ResourcesCreate)
  create({ getState, patchState }: StateContext<ResourcesStateModel>, { item }: ResourcesCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ResourcesUpdate)
  update({ getState, patchState }: StateContext<ResourcesStateModel>, { id, item }: ResourcesUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ResourcesDelete)
  delete({ getState, patchState }: StateContext<ResourcesStateModel>, { id }: ResourcesDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

  @Action(ResourcesRefreshByID)
  refreshByID({ getState, patchState }: StateContext<ResourcesStateModel>, { id }: ResourcesUpdate) {
    this._service.getResourceById(id).toPromise().then(item => {
      const state = getState();
      const index = state.data.map(x => x.id).indexOf(id);
      if (index >= 0) {
        state.data.splice(index, 1);
      }
      if (item)
        patchState({
          data: [...state.data, item]
        });
    });

  }

  // - shutters

  @Action(ResourcesRefreshShutters)
  refreshShutters({ patchState }: StateContext<ResourcesStateModel>) {
    this._service.getShutters().subscribe(
      shutters => patchState({ shutters }),
      error => console.error(error)
    );
  }

  @Action(ResourcesRefresh)
  refreshEpicsValues({ patchState }: StateContext<ResourcesStateModel>) {
    this._service.getEpicsValues().subscribe(
      epics => patchState({ epics }),
      error => console.error(error)
    );
  }


}
