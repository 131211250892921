import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ReviewAttendanceSaveCreate, ReviewAttendanceSaveDelete, ReviewAttendanceSaveRefresh, ReviewAttendanceSaveUpdate } from './review-attendance-save.action';
import { ReviewsService } from '../../reviews.service';
import { ReviewAttendanceSave } from '../../reviews.models';
import { utils } from 'src/app/modules/libs/utils';

export class ReviewAttendanceSaveStateModel {
  data: ReviewAttendanceSave[] = [];
}

@State<ReviewAttendanceSaveStateModel>({
  name: 'ReviewAttendanceSaved',
  defaults: {
    data: []
  }
})
@Injectable()
export class ReviewAttendanceSaveState {
  constructor(
    private reviewsService: ReviewsService
  ) { }

  @Selector()
  static read(state: ReviewAttendanceSaveStateModel) {
    return state.data;
  }

  @Action(ReviewAttendanceSaveRefresh)
  refresh({ patchState }: StateContext<ReviewAttendanceSaveStateModel>) {
    this.reviewsService.getReviewAttendanceSaved().toPromise().then(data => {
      patchState({ data: utils.cloneDeep(data?.sort((a, b) => utils.sort(a.name, b.name))) });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(ReviewAttendanceSaveCreate)
  create({ getState, patchState }: StateContext<ReviewAttendanceSaveStateModel>, { item }: ReviewAttendanceSaveCreate) {
    const state = getState();
    const currentState = utils.cloneDeep(state.data);
    const index = currentState.findIndex(x => x.id === item.id);
    if (index >= 0) {
      currentState.splice(index, 1);
    }
    currentState.push(item);
    currentState.sort((a, b) => utils.sort(a.name, b.name));
    patchState({
      data: currentState
    });
  }

  @Action(ReviewAttendanceSaveUpdate)
  update({ getState, patchState }: StateContext<ReviewAttendanceSaveStateModel>, { id, item }: ReviewAttendanceSaveUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    const currentState = utils.cloneDeep(state.data);

    if (index >= 0) {
      currentState.splice(index, 1, utils.cloneDeep(item));
      patchState({ data: currentState });
    }
  }

  @Action(ReviewAttendanceSaveDelete)
  delete({ getState, patchState }: StateContext<ReviewAttendanceSaveStateModel>, { id }: ReviewAttendanceSaveDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
