import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "cl-editor-textbox-options",
  templateUrl: "./cl-editor-textbox-options.component.html",
  styleUrls: ["./cl-editor-textbox-options.component.scss"],
})
/** cl-editor-textbox-options component*/
export class ClEditorTextboxOptionsComponent {
  @Input() disabled?: boolean;
  @Input() options?: string;
  @Input() configuration?: string;

  tmpOptions?: option[];
  multiple?: boolean;

  @Output() changed = new EventEmitter<string>();
  @Output() textbox = new EventEmitter<boolean>();

  ngOnInit() {
    if (this.options) {
      this.tmpOptions = JSON.parse(this.options) as option[];
    }
    if (this.configuration) {
      var m = JSON.parse(this.configuration).multiple;
      this.multiple = m;
    }
  }

  change() {
    try {
      this.options = JSON.stringify(this.tmpOptions);
      this.changed.emit(this.options);
    } catch (e) { }
  }

  chkChange(e: any) {
    try {
      this.multiple = e.checked;
      this.textbox.emit(this.multiple);
    } catch (e) { }
  }

  add() {
    var opt = new option();
    this.tmpOptions?.push(opt);
    this.change();
  }

  del(e: any) {
    this.tmpOptions?.splice(this.tmpOptions?.indexOf(e) ?? -1, 1);
    this.change();
  }
}

export class option {
  label!: string;
  value!: number;
}
