import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { SCFPrivileges, SCFStatus } from "../../scf-v2.service";
import { AbstractControl } from "@angular/forms";
import { utils } from "src/app/modules/libs/utils";
import { FormRestriction } from "src/app/services/forms/form-restriction";
import { SCFMaster } from "../../scf";
import { BaseComponent } from "src/app/common/base/base.component";
import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { Restriction } from "src/app/controls/restrictions/services/restriction";
@Component({
  selector: "scf-v2-main-s2",
  templateUrl: "./scf-v2-main-s2.component.html",
  styleUrls: ["./scf-v2-main-s2.component.scss"],
})
export class ScfV2MainS2Component extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() scfMaster?: SCFMaster | null;
  @Input() disabled?: boolean | null = true;
  @Input() privileges?: SCFPrivileges | null;
  @Input() status?: SCFStatus | null;

  @Output() changed = new EventEmitter<SCFMaster>();
  @ViewChild("restrictionInput")
  restrictionInput!: ElementRef<HTMLInputElement>;

  disableControls = true;

  // isMobileDevice = utils.isMobileDevice();

  locationIDs?: (number | undefined)[];
  formID?: number;

  public get restrictionsCtrl(): AbstractControl | null {
    return this.formGroup.get("restrictionsCtrl");
  }

  formRestrictions?: FormRestriction[];

  restrictions!: Restriction[];
  restrictions$!: Observable<Restriction[]>;
  restrictionsSubs!: Subscription;

  resources!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  filteredRestrictions!: Restriction[];
  uniqueRestrictions!: Restriction[];

  override ngOnDestroy(): void {
    this.restrictionsSubs?.unsubscribe();
    this.resourcesSubs?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  ngOnInit(): void {
  }

  refresh() {
    this.getValues();
  }

  getValues() {
    this.formRestrictions = [];
    if (this.status?.isViewingAmendment) {
      this.formRestrictions = this.utils.cloneDeep(this.scfMaster?.scfAmendments?.find(x => x.active)?.scfRestrictionsV2);
      const scfAmendment = this.scfMaster?.scfAmendments?.find(x => x.active);
      if (scfAmendment) {
        this.locationIDs = scfAmendment?.locations?.map((l) => l.id);
        this.formID = scfAmendment?.id;
      }
    } else if (this.scfMaster) {
      this.formRestrictions = this.scfMaster?.scfRestrictionsV2;
      this.locationIDs = this.scfMaster?.locations?.map((l) => l.id);
      this.formID = this.scfMaster.id;
    }
    this.formRestrictions?.map((r) => {
      if (r.restriction == null) {
        r.restriction = this.restrictions?.find((x) => x.id == r.restrictionID);
      }
    });
    this.formRestrictions = this.formRestrictions?.sort((a, b) => (a.id ?? 0) - (b.id ?? 0));
  }

  setValues() {
    if (this.status?.isViewingAmendment) {
      const scfAmendment = this.scfMaster?.scfAmendments?.find((x) => x.active);
      if (scfAmendment)
        scfAmendment.scfRestrictionsV2 =
          this.formRestrictions;
    } else {
      if (this.scfMaster)
        this.scfMaster.scfRestrictionsV2 = this.formRestrictions;
    }
    if (this.scfMaster)
      this.changed.emit(this.scfMaster);
  }

  restrictionsChanged(e: FormRestriction[]) {
    this.formRestrictions = e;
    this.setValues();
  }

  diffRestrictions() {
    if (this.scfMaster && this.status?.isViewingAmendment) {
      this.formRestrictions?.map(r => { if (!r.text) r.text = null; });
      this.scfMaster.scfRestrictionsV2?.map(r => { if (!r.text) r.text = null; });
      const current = utils.JSONstringify(
        this.formRestrictions
          ?.sort((a, b) =>
            utils.sort(a.restrictionID, b.restrictionID, true)
          )
          .map((l) => {
            return {
              restrictionID: l.restrictionID,
              text: l.text,
            } as FormRestriction;
          })
      )?.replace(/\s/g, "");
      const original = utils.JSONstringify(
        this.scfMaster?.scfRestrictionsV2
          ?.sort((a, b) =>
            utils.sort(a.restrictionID, b.restrictionID, true)
          )
          .map((l) => {
            return {
              restrictionID: l.restrictionID,
              text: l.text,
            } as FormRestriction;
          })
      )?.replace(/\s/g, "");
      this.formRestrictions?.map(r => {
        r.hilite = this.scfMaster?.scfRestrictionsV2?.find(p => p.restrictionID ? p.restrictionID == r.restrictionID : p.text == r.text) == null && this.status?.isViewingAmendment;
      });
      return current != original;
    }
    else return false;
  }

}
