<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h4 mat-dialog-title>Add User</h4>
      <div mat-dialog-content>
        <div class="col-12">
          <div class="row">
            <mat-form-field>
              <input
                matInput
                type="text"
                placeholder="Select User"
                [matAutocomplete]="auto"
                (keyup)="filter($event)"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                  *ngFor="let user of usersFiltered"
                  [value]="user.name"
                  (onSelectionChange)="
                    getUserSelected($event.source.selected, user)
                  "
                >
                  {{ user.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <div class="col-12">
          <div class="row">
            <div class="col-md-6">
              <button mat-flat-button color="warn" (click)="cancel()">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <button mat-flat-button color="primary" (click)="add()">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
