<div *ngIf="annotations?.length">
  <div class="row">
    <div class="col-11 offset-1">
      <div *ngFor="let annotation of annotationsFiltered">
        <checklist-tasks-annotations-annotation
          [annotation]="annotation"
          [task]="task"
          [currentChecklist]="currentChecklist"
          [s]="s"
          (changed)="changed.emit($event)"
        >
        </checklist-tasks-annotations-annotation>
      </div>
    </div>
  </div>
</div>
