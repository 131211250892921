<div class="container-fluid mt-3">
  <eb-main-s1
    [form]="form"
    [showAmendment]="showAmendment"
    (loading)="loading.emit($event)"
    (formCreated)="formCreated.emit($event)"
  ></eb-main-s1>
  <div
    *ngIf="
      form?.id &&
      (form?.formVersion?.statusID ?? 0) != formStatusEnum.New &&
      form?.formVersion?.statusID != formStatusEnum.Draft
    "
  >
    <eb-main-s2
      [form]="form"
      (loading)="loading.emit($event)"
      *ngIf="form"
    ></eb-main-s2>
    <eb-main-s3
      [form]="form"
      (loading)="loading.emit($event)"
      *ngIf="form"
    ></eb-main-s3>
    <eb-main-s4
      [form]="form"
      (loading)="loading.emit($event)"
      *ngIf="form"
    ></eb-main-s4>
    <eb-main-s5
      [form]="form"
      (loading)="loading.emit($event)"
      *ngIf="form"
    ></eb-main-s5>
    <eb-main-s6
      [form]="form"
      (loading)="loading.emit($event)"
      *ngIf="form"
    ></eb-main-s6>
    <div class="mt-4">
      <comments-and-attachments
        *ngIf="form"
        headerClass="h5"
        id="Section5Title"
        #section5
        [currentDocument]=" {
      status: form.formVersion.statusID,
      serialNo: form.sequenceNumber.number,
      type: formTypeEnum.EBF,
    }"
      >
      </comments-and-attachments>
    </div>
  </div>
</div>
