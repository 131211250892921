<div class="card">
  <div class="card-header">
    <span class="card-title">{{ title }}</span>
  </div>

  <div class="card-body">
    <mat-form-field appearance="outline" class="no-subscript">
      <mat-label>Button Label</mat-label>
      <input
        #nameCtrlElement
        type="text"
        placeholder="Button Label"
        matInput
        name="nameCtrl"
        [formControl]="nameCtrl"
      />
    </mat-form-field>
    <color-picker
      [color]="reviewStatusAction.color"
      (selected)="selectedColor($event)"
    ></color-picker>
    <mat-form-field appearance="outline" class="no-subscript">
      <mat-label>Next Status</mat-label>
      <input
        type="text"
        placeholder="Select Next Status"
        matInput
        name="nextStatusCtrl"
        [formControl]="nextStatusCtrl"
        [matAutocomplete]="autoNextStatus"
        (keyup)="changedNextStatus($event)"
      />
      <mat-autocomplete
        #autoNextStatus="matAutocomplete"
        #nextStatusAutocomplete
        [displayWith]="displayObject"
        (optionSelected)="selectedNextStatus($event)"
      >
        <mat-option
          *ngFor="let status of reviewStatusesFiltered"
          [value]="status"
        >
          <span>{{ status.name }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Internal Action</mat-label>
      <input
        #codeCtrlElement
        type="text"
        placeholder="Button Label"
        matInput
        name="codeCtrl"
        [formControl]="codeCtrl"
      />
      <mat-hint> Internal code execution tag. </mat-hint>
    </mat-form-field>
    <role-selection
      [roles]="getActionRoles(reviewStatusAction.reviewActionRoles)"
      (selected)="selectedRole($event)"
      (deleted)="removeRole($event)"
    ></role-selection>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button mat-flat-button (click)="save()" color="primary">Accept</button>
    </div>
  </div>
</div>
