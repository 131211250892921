import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ScheduleSubtypesCreate, ScheduleSubtypesDelete, ScheduleSubtypesRefresh, ScheduleSubtypesUpdate } from './schedule-subtypes.action';
import { ScheduleSubtype } from '../../models/schedule-subtype';
import { ScheduleSubTypeService } from '../../services/schedule-subtype.service';



export class ScheduleSubtypesStateModel {
  data: ScheduleSubtype[] = [];
}

@State<ScheduleSubtypesStateModel>({
  name: 'ScheduleSubtypes',
  defaults: {
    data: []
  }
})
@Injectable()
export class ScheduleSubtypesState {
  constructor(
    private _service: ScheduleSubTypeService
  ) { }

  @Selector()
  static read(state: ScheduleSubtypesStateModel) {
    return state.data;
  }

  @Action(ScheduleSubtypesRefresh)
  refresh({ patchState }: StateContext<ScheduleSubtypesStateModel>) {
    this._service.getAll().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ScheduleSubtypesCreate)
  create({ getState, patchState }: StateContext<ScheduleSubtypesStateModel>, { item }: ScheduleSubtypesCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ScheduleSubtypesUpdate)
  update({ getState, patchState }: StateContext<ScheduleSubtypesStateModel>, { id, item }: ScheduleSubtypesUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ScheduleSubtypesDelete)
  delete({ getState, patchState }: StateContext<ScheduleSubtypesStateModel>, { id }: ScheduleSubtypesDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
