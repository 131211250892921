import { Review } from "../../reviews.models";

export class ReviewsRefresh {
  static readonly type = '[Reviews] Refresh';
}

export class ReviewsRefreshById {
  static readonly type = '[Reviews] RefreshById';
  constructor(public id: number) { }
}

export class ReviewsCreate {
  static readonly type = '[Reviews] Create';

  constructor(public item: Review) { }
}

export class ReviewsUpdate {
  static readonly type = '[Reviews] Update';

  constructor(public id: number, public item: Review) { }
}

export class ReviewsDelete {
  static readonly type = '[Reviews] Delete';
  constructor(public id: number) { }
}
