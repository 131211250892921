import { RadMeter } from '../../rad-meters';

export class RadMetersRefresh {
  static readonly type = '[RAD Meters] Refresh';
}
export class RadMetersCreate {
  static readonly type = '[RAD Meters] Create';
  constructor(public item: RadMeter) { }
}
export class RadMetersUpdate {
  static readonly type = '[RAD Meters] Update';
  constructor(public id: number, public item: RadMeter) { }
}
export class RadMetersDelete {
  static readonly type = '[RAD Meters] Delete';
  constructor(public id: number) { }
}
