import { ProcedureCategory } from "../../models/procedure-category.model";

export class ProcedureCategoryRefresh {
  static readonly type = "[ProcedureCategory] Refresh";
}

export class ProcedureCategoryCreate {
  static readonly type = "[ProcedureCategory] Create";

  constructor(public item: ProcedureCategory) { }
}

export class ProcedureCategoryUpdate {
  static readonly type = "[ProcedureCategory] Update";

  constructor(public id: number, public item: ProcedureCategory) { }
}

export class ProcedureCategoryDelete {
  static readonly type = "[ProcedureCategory] Delete";

  constructor(public id: number) { }
}
