import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'cl-editor-placeholder-select',
  templateUrl: './cl-editor-placeholder-select.component.html',
  styleUrls: ['./cl-editor-placeholder-select.component.scss']
})
export class ClEditorPlaceholderSelectComponent implements OnInit, OnChanges {

  @Input() configuration?: string;
  @Input() text?: string | null;

  @Output() selected = new EventEmitter<string>();

  constructor() { }

  placeholder!: string;
  options: PlaceholderOption[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.configuration) {
      const configuration = JSON.parse(this.configuration)
      this.placeholder = configuration?.placeholder;
    }
    if (this.text) {
      this.getPlaceholders();
    }
  }

  ngOnInit(): void {
  }

  getPlaceholders() {
    this.options = [];
    if (this.text) {
      const res = this.text.replace(new RegExp('\{([a-z,A-Z,0-9]+)\}', 'ig'), match => {
        const option = { value: match, checked: match == this.placeholder } as PlaceholderOption;
        this.options.push(option);
        return this.text ?? '';
      });
    }
  }

  change(e: any) {
    this.placeholder = e.value;
    this.selected.emit(this.placeholder);
  }

}

export class PlaceholderOption {
  value!: string;
  checked!: boolean;
}
