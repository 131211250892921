<div class="d-print-flex">
  <mat-form-field
    [ngClass]="{
      hilite: hilite
    }"
  >
    <mat-label>{{ placeholder }}</mat-label>
    <mat-chip-grid
      #rsswaShutterChips
      [formControl]="control"
      [disabled]="disabled"
    >
      <mat-chip-row
        *ngFor="let rsswaShutter of selectedRsswaShutters"
        [selectable]="true"
        [removable]="true"
        (removed)="remove(rsswaShutter)"
      >
        {{ rsswaShutter.resource == null ? "" : rsswaShutter.resource.name }}
        <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
      </mat-chip-row>
      <input
        #shutterInput
        [matAutocomplete]="shutterAutocomplete"
        [matChipInputFor]="rsswaShutterChips"
        [matChipInputAddOnBlur]="false"
        [placeholder]="placeholder"
        (keyup)="onShutterInputKeyup($event)"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #shutterAutocomplete="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option *ngFor="let resource of filteredResources" [value]="resource">
        {{ resource == null ? "" : resource.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
