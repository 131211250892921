<div class="mt-2">
  <mat-list-item *ngFor="let signature of signLogs">
    <div class="d-flex align-items-center">
      <span class="date">{{ signature.signedOn | date : "medium" }}</span>
      <mat-icon color="primary" mat-list-icon>arrow_right_alt</mat-icon>

      <span>
        <b class="blue"> {{ signature.name }}</b> by
        <b>{{ signature.signedBy }} </b></span
      >
      <mat-icon
        color="accent"
        class="ml-3"
        *ngIf="signature.reason && signature.reason != ''"
        >comment</mat-icon
      >
      <span class="reason" *ngIf="signature.reason && signature.reason != ''">
        <span class="comment"> {{ signature.reason }}</span></span
      >
    </div>
  </mat-list-item>
</div>
