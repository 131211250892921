import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ProcedureTypeCreate, ProcedureTypeDelete, ProcedureTypeRefresh, ProcedureTypeUpdate } from './procedure-types.action';
import { ProcedureType } from '../../models/procedure-type';
import { ProcedureTypeService } from '../../services/procedure-type.service';

export class ProcedureTypeStateModel {
  data: ProcedureType[] = [];
}

@State<ProcedureTypeStateModel>({
  name: 'ProcedureType',
  defaults: {
    data: []
  }
})

@Injectable()
export class ProcedureTypeState {
  constructor(
    private service: ProcedureTypeService
  ) { }

  @Selector()
  static read(state: ProcedureTypeStateModel) {
    return state.data;
  }

  @Action(ProcedureTypeRefresh)
  refresh({ patchState }: StateContext<ProcedureTypeStateModel>) {
    this.service.read().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ProcedureTypeCreate)
  create({ getState, patchState }: StateContext<ProcedureTypeStateModel>, { item }: ProcedureTypeCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ProcedureTypeUpdate)
  update({ getState, patchState }: StateContext<ProcedureTypeStateModel>, { id, item }: ProcedureTypeUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ProcedureTypeDelete)
  delete({ getState, patchState }: StateContext<ProcedureTypeStateModel>, { id }: ProcedureTypeDelete) {
    patchState({
      data: getState().data.filter(x => x.id != id)
    });
  }

}
