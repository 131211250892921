<div class="card">
  <div class="card-header d-flex no-wrap">
    <span class="card-title mr-3"> {{ inputData.dialogTitle }}</span>
    <span class="blue" *ngIf="bratoi?.id">{{ beamlineName }}</span>
    <mat-form-field
      *ngIf="!bratoi?.id"
      [disabled]="beamline"
      class="no-subscript"
    >
      <mat-label>Beamline/PSS</mat-label>
      <input
        [formControl]="beamlineCtrl"
        matInput
        type="text"
        placeholder="Beamline/PSS"
        [matAutocomplete]="auto"
        (keyup)="filter($event)"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayObject"
        (optionSelected)="selectedBeamline($event)"
      >
        <mat-option
          *ngFor="let beamline of beamlinesFiltered"
          [value]="beamline"
        >
          {{ beamline.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-6">
        <div class="card">
          <div class="card-header p-0">
            <span class="card-title">
              <div class="row">
                <div class="col-10 pt-2 pl-4 d-flex align-items-center">
                  <h6>Documentation</h6>
                </div>
                <div class="col-2">
                  <button
                    class="float-right"
                    mat-icon-button
                    color="accent"
                    [disabled]="disableSubmmit"
                    (click)="linkDialog()"
                  >
                    <mat-icon>add_box</mat-icon>
                  </button>
                </div>
              </div></span
            >
          </div>
          <div class="card-body">
            <div
              class="row border-top list-item"
              *ngFor="let link of links; let elementIndex = index"
            >
              <div class="col-9 margin-auto" *ngIf="link?.webAddress">
                {{ link.linkType?.name }}:
                <a href="{{ link.webAddress }}" target="_blank">{{
                  link.hyperlink
                }}</a>
              </div>
              <div class="col-9 margin-auto" *ngIf="!link?.webAddress">
                {{ link.linkType?.name }}: <a>{{ link.hyperlink }}</a>
              </div>
              <div class="col-3 text-right nowrap">
                <button
                  class="mr-1"
                  mat-icon-button
                  color="accent"
                  [disabled]="disableSubmmit"
                  (click)="linkDialog(link, elementIndex)"
                >
                  <mat-icon>edit</mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="warn"
                  [disabled]="disableSubmmit"
                  (click)="deleteList(links, elementIndex)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-header p-0">
            <span class="card-title">
              <div class="row">
                <div class="col-10 pt-2 pl-4 d-flex align-items-center">
                  <h6>TOCAs</h6>
                </div>
                <div class="col-2">
                  <button
                    class="float-right"
                    mat-icon-button
                    color="accent"
                    [disabled]="disableSubmmit"
                    (click)="tocaDialog()"
                  >
                    <mat-icon>add_box</mat-icon>
                  </button>
                </div>
              </div></span
            >
          </div>
          <div class="card-body">
            <div
              class="row border-top list-item"
              *ngFor="let toca of tocas; let elementIndex = index"
            >
              <div class="col-9 margin-auto">
                {{ toca.name }}
                <span class="shared">{{ getTocaSharedWith(toca) }}</span>
              </div>
              <div class="col-3 text-right nowrap">
                <button
                  class="mr-1"
                  mat-icon-button
                  color="accent"
                  [disabled]="disableSubmmit"
                  (click)="tocaDialog(toca, elementIndex)"
                >
                  <mat-icon>edit</mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="warn"
                  [disabled]="disableSubmmit"
                  (click)="deleteList(tocas, elementIndex)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        class="cancel-btn"
        color="warn"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        class="accept-btn"
        color="primary"
        [disabled]="disableSubmmit"
        (click)="accept()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
