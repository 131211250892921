import { Component, Inject, Injector, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from "@angular/material/dialog";
import * as moment from "moment";
import { Observable, Subscription } from "rxjs";
import { ReviewCycleStatus, ResourceType, StatusType } from "src/app/common/enumerations/enumerations";
import { PendingChangesDialogComponent } from "src/app/controls/pending-changes-dialog/pending-changes-dialog.component";
import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { PrivilegeEnum } from "src/app/services/role-privilege/privilege-enum";
import { ReviewCycle } from "../../reviews.models";
import { ReviewsService } from "../../reviews.service";
import { BaseComponent } from "src/app/common/base/base.component";
import { CanDeactivateResponse } from "src/app/controls/pending-changes-dialog/pending-changes-dialog-response";
import { YesNoDialogComponent } from "src/app/controls/yes-no-dialog/yes-no-dialog.component";

@Component({
  selector: "app-review-request",
  templateUrl: "./review-request.component.html",
  styleUrls: ["./review-request.component.scss"],
})
export class ReviewRequestComponent extends BaseComponent implements OnInit {
  reviewCycle!: ReviewCycle;
  reviewCycleStatus = ReviewCycleStatus;

  static key = "ReviewRequestComponent";

  public hasError!: boolean;

  resource!: Resource | null;
  resources!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;
  resourcesFiltered!: Resource[];
  todaysDate!: Date;
  loading!: boolean;

  reviewRequestForm!: FormGroup;
  customLocationText?: string | null;
  public get resourceCtrl(): AbstractControl | null {
    return this.reviewRequestForm.get("resourceCtrl");
  }
  public get dateCtrl(): AbstractControl | null {
    return this.reviewRequestForm.get("dateCtrl");
  }

  constructor(
    protected override injector: Injector,
    private dialogRef: MatDialogRef<ReviewRequestComponent>,
    @Inject(MAT_DIALOG_DATA)
    public inputData: {
      reviewCycle: ReviewCycle;
      isEvaluate: boolean;
    },
    private reviewsService: ReviewsService,
  ) {
    super(injector);

    this.reviewsService.Init();
  }

  ngOnInit() {
    // if (!this.reviewsService.checkPrivilege(PrivilegeEnum.CreateReviewRequest)) {
    //   this.alert.message("NoEnoughPrivileges");
    //   this.dialogRef.close();
    // }
    this.initializeForm();
    if (!this.inputData) {
      this.inputData = { reviewCycle: {} as ReviewCycle, isEvaluate: false };
    } else {
      this.reviewCycle = this.inputData.reviewCycle;
      this.dateCtrl?.setValue(this.reviewCycle.proposedTimeline);
      this.disableForm();
    }
    this.todaysDate = new Date();
    this.loadResources();
  }

  loadResources() {
    this.resources$ = this.store.select((state) => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe((data) => {
      if (data?.length !== 0) {
        this.resources = data
          .filter(
            (x) =>
              x.type === ResourceType.Accelerator ||
              x.type === ResourceType.Beamline ||
              x.type === ResourceType.ReviewProcessResource ||
              x.type === ResourceType.Branchline
          )
          .sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));

        this.resourcesFiltered = this.resources;

        // Check if the reviewCycle.resourceID is null
        if (!this.reviewCycle?.resourceID) {
          this.customLocationText = this.reviewCycle?.location; // Set the custom location text
          this.resourceCtrl?.setValue(this.customLocationText);
        } else {
          this.resourceCtrl?.setValue(
            this.resources.find((x) => x.id === this.reviewCycle?.resourceID)
          );
        }
      }
    });
  }

  override displayObject(e: any) {
    return e ? e.name ? e.name : e : null;
  }

  changedResource(e: any): void {
    // Find the selected resource in the existing options
    const value = e.target.value;
    this.resourcesFiltered = this.resources.filter(
      (resource) => resource.name?.toLowerCase().includes(value.toLowerCase())
    );

    const selectedResource = this.resourcesFiltered.find(
      (resource) => resource.name?.toLowerCase() === value.toLowerCase()
    );

    if (selectedResource) {
      this.resource = selectedResource;
      this.customLocationText = "";
      this.inputData.reviewCycle.location = this.resource.name;
      this.inputData.reviewCycle.resourceID = this.resource.id;
    } else {
      this.resource = null; // Clear the selected resource
      this.customLocationText = value; // Set the custom location text
      this.inputData.reviewCycle.location = value;
      this.inputData.reviewCycle.resourceID = null;
    }
  }

  selectedResource(e: any) {
    this.resource = e.option.value;
    this.inputData.reviewCycle.location = this.resource?.name;
    this.inputData.reviewCycle.resourceID = this.resource?.id;
  }


  formValid() {
    const estimatedInitOn = moment(this.dateCtrl?.value);
    const dateWithTime = moment(new Date());
    const dateWithoutTime = dateWithTime.startOf("day");
    if (
      !estimatedInitOn.isValid() ||
      estimatedInitOn.isBefore(dateWithoutTime) ||
      this.dateCtrl?.status == "INVALID"
    ) {
      this.dateCtrl?.setErrors({ invalidDate: true });
      this.dateCtrl?.markAsTouched();
    } else {
      this.dateCtrl?.setErrors(null);
    }
    if (
      this.resource == null &&
      (this.customLocationText == null || this.customLocationText.length == 0)
    ) {
      this.resourceCtrl?.setErrors({ invalidResource: true });
    } else {
      this.resourceCtrl?.setErrors(null);
    }
    return this.reviewRequestForm.valid;
  }

  submit() {
    if (
      this.reviewRequestForm.valid &&
      this.inputData.reviewCycle.description
    ) {
      this.disableForm();
      if (this.inputData.reviewCycle.id) {
        this.reviewsService.updateReviewCycle(this.inputData.reviewCycle).toPromise().then((reviewCycleCreated) => {
          if (reviewCycleCreated) {
            this.inputData.reviewCycle = reviewCycleCreated;
            this.reviewsService.sendReviewCycleEmail(reviewCycleCreated).toPromise();
            this.dialogRef.close();
          }
        });
      }
      else {
        this.reviewsService.createReviewCycle(this.inputData.reviewCycle).toPromise().then((reviewCycleCreated) => {
          if (reviewCycleCreated) {
            this.inputData.reviewCycle = reviewCycleCreated;
            this.reviewsService.sendReviewCycleEmail(reviewCycleCreated).toPromise();
            this.dialogRef.close();
          }
        });
      }
    } else {
      this.hasError = true;
    }
  }

  acceptAndReject(isAccepted: boolean) {
    if (this.inputData.reviewCycle.id) {
      this.inputData.reviewCycle.status = isAccepted
        ? ReviewCycleStatus.Approved
        : ReviewCycleStatus.Rejected;
      this.reviewsService
        .updateReviewCycle(this.inputData.reviewCycle)
        .toPromise()
        .then((reviewCycleUpdated) => {
          this.dialogRef.close();
        });
    }
  }

  edit() {
    this.reviewRequestForm.enable();
    this.inputData.isEvaluate = false;
    if (this.reviewCycle.resource) this.resource = this.reviewCycle.resource;
  }

  sendToPending() {
    if (this.inputData.reviewCycle.id) {
      this.inputData.reviewCycle.status = ReviewCycleStatus.Pending;
      this.reviewsService
        .updateReviewCycle(this.inputData.reviewCycle)
        .toPromise()
        .then((reviewCycleUpdated) => {
          this.dialogRef.close();
        });
    }
  }

  cancel() {
    if (this.reviewRequestForm.dirty) {
      const confirmation = this.dialog.open(PendingChangesDialogComponent, {
        width: "40%",
        data: {},
      });
      confirmation.afterClosed().toPromise().then((response: CanDeactivateResponse) => {
        if (response === CanDeactivateResponse.Discard) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  close() {
    this.dialogRef.close();
  }

  descriptionChanged(description: string) {
    this.inputData.reviewCycle.description = description;
  }

  initializeForm() {
    this.reviewRequestForm = this.formBuilder.group({
      resourceCtrl: [
        { value: "" },
        [Validators.required, this.utils.IsWhiteSpaceReactiveForm],
      ],
      dateCtrl: [
        { value: "" },
        [Validators.required, this.utils.IsWhiteSpaceReactiveForm],
      ],
    });
  }

  disableForm() {
    this.reviewRequestForm.disable();
  }

  // getErrorMsg(control: AbstractControl): string {
  //   if (control.hasError("required")) {
  //     return "You must enter a value";
  //   }
  //   return null;
  // }

  archive() {
    if (this.inputData.reviewCycle.reviews.some(r => r.reviewStatus.type != StatusType.Canceled && r.reviewStatus.type != StatusType.Closed)) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message: this.getMessage("ArchiveCycle").description,
          icon: 'warn'
        }
      });
      confirm.afterClosed().subscribe(res => {
        if (res) this.archiveCycle();
      });
    }
    else this.archiveCycle();
  }

  archiveCycle() {
    if (this.inputData.reviewCycle.id) {
      this.loading = true;
      this.inputData.reviewCycle.status = ReviewCycleStatus.Archived;
      this.reviewsService
        .updateReviewCycle(this.inputData.reviewCycle)
        .toPromise()
        .then(() => {
          this.dialogRef.close();
          this.loading = false;
        });
    }
  }

  reActivate() {
    if (this.inputData.reviewCycle.id) {
      this.loading = true;
      this.inputData.reviewCycle.status = ReviewCycleStatus.Approved;
      this.reviewsService
        .updateReviewCycle(this.inputData.reviewCycle)
        .toPromise()
        .then((reviewCycleUpdated) => {
          this.dialogRef.close();
          this.loading = false;
        });
    }
  }

  changedDate(e: any) {
    this.inputData.reviewCycle.proposedTimeline = e.value;
  }

}
