import { Component, ViewChild, Input, Output, EventEmitter, OnDestroy, OnInit, HostListener, ElementRef, Injector, OnChanges, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ShutterResource, Resource, BeamlineShutterRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';
import { ProcedureTypeEnum } from '../../procedure/enums/procedure-type.enum';
import { ProcedureCategory } from '../../procedure/models/procedure-category.model';
import { Procedure } from '../../procedure/models/procedure.model';
import { KEVersionResource } from '../ke';
import { KeChecklistBuilderService } from './ke-checklist-builder.service';
import { KeClBuilderSectionComponent } from './ke-cl-builder-section/ke-cl-builder-section.component';
import { ComponentsStoreAndListenersService } from '../../checklists/store/components/components.store';
import { Roles } from 'src/app/common/enumerations/enumerations';
import * as moment from 'moment';
import { PlaceholdersService } from 'src/app/services/placeholders/placeholders.service';


@Component({
  selector: 'ke-checklist-builder',
  templateUrl: './ke-checklist-builder.component.html',
  styleUrls: ['./ke-checklist-builder.component.scss']
})
export class KeChecklistBuilderComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() beamlineShutter?: BeamlineShutterRelation;
  // @Output() requestRefresh = new EventEmitter<number>();
  @Output() loading = new EventEmitter<boolean>();

  @ViewChild('section1') section1!: KeClBuilderSectionComponent;
  @ViewChild('section2') section2!: KeClBuilderSectionComponent;
  @ViewChild('section3') section3!: KeClBuilderSectionComponent;
  @ViewChild('section4') section4!: KeClBuilderSectionComponent;
  @ViewChild('section5') section5!: KeClBuilderSectionComponent;
  @ViewChild('section6') section6!: KeClBuilderSectionComponent;

  @ViewChildren(KeClBuilderSectionComponent) sections!: QueryList<KeClBuilderSectionComponent>;

  @ViewChild('headerTop')
  headerTop!: ElementRef;

  @ViewChild(MatAutocompleteTrigger)
  brTrigger!: MatAutocompleteTrigger;

  shutter!: ShutterResource | null;
  resource!: Resource;

  selectedTab!: boolean;

  showBranchlines = false;
  createAllowed!: boolean | null;
  // saving!: boolean;
  // duplicating!: boolean;
  keGlobal?: string;
  userName?: string;
  // deleting!: boolean;

  status?: number | null;

  allShutters: ShutterResource[] = [];
  filteredShutters: ShutterResource[] = [];

  version?: KEVersionResource | null;
  activeVersion?: KEVersionResource | null;

  versions!: KEVersionResource[];

  //#region Procedures
  procedureCategories$!: Observable<ProcedureCategory[]>;
  procedures$!: Observable<Procedure[]>;

  procedureCategoriesSubscription!: Subscription;
  procedureSubscription!: Subscription;

  procedureCategories: ProcedureCategory[] = [];
  procedures: Procedure[] = [];
  proceduresFiltered: Procedure[] = [];
  //#endregion

  tmpShutter!: string;
  tmpBranchline?: string | null;
  tmpVersion!: string;

  buttonText!: string;
  radioSelection!: number;

  beamlines!: string;
  branchlines!: Resource[];

  wfTableId?: number | null;
  disableSections = true;

  brOptions: any[] = [{ label: 'Full', value: 1, checked: true }, { label: 'Abbreviated', value: 0 }];

  // #Form Controls
  public form!: FormGroup;
  locationCtrl = new FormControl();
  branchlineCtrl = new FormControl();
  versionCtrl = new FormControl();

  disabled!: boolean;
  approveAllowed!: boolean | null;
  disapprove!: boolean;
  disableControls!: boolean;
  locLoading!: boolean;

  headerOnTop!: boolean;

  keVersion?: KEVersionResource;
  keVersions?: KEVersionResource[];
  keVersions$!: Observable<KEVersionResource[]>;
  keVersionsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private _res: ResourcesService,
    private _keBuilder: KeChecklistBuilderService,
    private _placeholder: PlaceholdersService,
    private componentsStore: ComponentsStoreAndListenersService
  ) {
    super(injector);

    componentsStore.initListeners();
    componentsStore.initStore();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['beamlineShutter']?.currentValue?.beamlineId != changes['beamlineShutter']?.previousValue?.beamlineId)
      this.getDetails(this.beamlineShutter);
  }

  override ngOnDestroy(): void {
    this.procedureCategoriesSubscription?.unsubscribe();
    this.procedureSubscription?.unsubscribe();
    this.keVersionsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit() {
    this.buildForm();
    this.disapprove = false;
    this.setButtons();
    this.loadProcedures();
    this.loadKEVersions();
  }

  loadKEVersions() {
    this.keVersions$ = this.store.select(state => state.KEVersions.data);
    this.keVersionsSubs = this.keVersions$.subscribe(data => {
      if (data?.length) {
        this.keVersions = data;
        if (this.version && this.version.id == data[data.length - 1].id) {
          this.refreshSections();
        }
      }
    });
  }

  buildForm() {
    this.form = new FormGroup({
      locationCtrl: this.locationCtrl,
      versionCtrl: this.versionCtrl
    });
    if (!this.currentUser) {
      this.disabled = true;
      this.form.disable();
      this.versionCtrl.enable();
    } else {
      this.disabled = false;
      this.form.enable();
    }
  }

  //#region Locations component functions:
  loadAllLocations() {
    this.locLoading = true;
    this._res.getShutters().subscribe((data) => {
      this.allShutters = data;
      this.filterShutters();
    });
  }

  loadProcedures() {
    this.procedureCategories$ = this.store.select(state => state.ProcedureCategory.ProcedureCategories);
    this.procedureCategoriesSubscription = this.procedureCategories$.subscribe((categories) => {
      this.procedureCategories = categories.filter(x => x.procedureTypeID === ProcedureTypeEnum.KEChecklist);
      this.procedures$ = this.store.select(state => state.Procedures.data);
      this.procedureSubscription = this.procedures$.subscribe(procedures => {
        if (procedures) {
          this.procedures = procedures.filter(x => this.procedureCategories.map(c => c.id).includes(x.procedureCategoryID) && x.status === 1 && x.active);
          this.procedures.sort((a, b) => this.utils.sort(a.procedureNumber, b.procedureNumber, true));
          this.proceduresFiltered = this.procedures;
        }
      });
    });
  }

  onLocChange(code?: string) {
    if (!code?.includes('Arrow')) {
      this.filterShutters();
      this.filteredShutters = this.filteredShutters.filter(loc => loc.name.toLowerCase().includes(this.locationCtrl.value.trim().toLowerCase()));
    }
  }

  filterShutters() {
    this.filteredShutters = [];
    this.filteredShutters = this.allShutters.filter(b => b.id != (this.shutter ? this.shutter.id : 0));
    this.locLoading = false;
  }

  getDetails(e?: BeamlineShutterRelation, selectedTab?: boolean) {
    this.activeVersion = null;
    this.shutter = null;
    if (e) {
      this.selectedTab = selectedTab ?? false;
      this.loading.emit(true);
      if (e.shutterId)
        this._res.getShutter(e.shutterId).subscribe(data => {
          this.showBranchlines = false;
          this.shutter = data;
          this.tmpShutter = this.shutter.name;
          this.showBeamline(this.shutter.beamline);
          this.resource = this.shutter as Resource;

          this.branchlines = this.shutter.branchlines;
          if (this.branchlines.length) {
            this.branchlines.unshift(this.shutter as Resource);
            this.resource = this.shutter as Resource;
            this.tmpBranchline = this.shutter.name;
            this.branchlineCtrl.setValue(this.resource);
            this.showBranchlines = true;
          }
          this._placeholder.setKeyValuePairs(this.resource, true).then(() => {
            this.wfTableId = this.shutter?.wfTableID;
            this.getVersion();
            this.setButtons();
            this.refresh();
          });
        });
      else {
        this.loading.emit(false);
      }
    }
    else this.loading.emit(false);
  }

  refreshDetails() {
    const branchline = this.branchlineCtrl.value;
    const version = this.versionCtrl.value;
    if (this.shutter?.id)
      this._res.getShutter(this.shutter.id).subscribe(data => {
        this.shutter = data;
        this.tmpShutter = this.shutter.name;
        this.showBeamline(this.shutter.beamline);
        this.resource = this.shutter as Resource;
        this.branchlines = this.shutter.branchlines;
        if (this.branchlines.length != 0) {
          this.branchlines.unshift(this.shutter as Resource);
        }
        if (branchline) {
          this.branchlineCtrl.setValue(this.branchlines.find(x => x.id == branchline.id));
          this.tmpBranchline = this.branchlineCtrl.value.name;
          this.resource = this.branchlineCtrl.value;
        }
        else {
          this.resource = this.shutter as Resource;
        }

        this.getVersion();
        this.versions = this.filterVersions();
        this.versionCtrl.setValue(this.versions.find(v => v.id == version.id));
        this.version = this.versionCtrl.value;
        this.setButtons();
      });
    else this.loading.emit(false);
  }

  getVersion(version?: KEVersionResource) {
    this.versions = this.filterVersions();
    if (this.versions.length == 0 && this.selectedTab) {
      this.alert.warning(this.getMessage('KENoVersionsFound').description);
    }

    if (version) {
      this.version = version;
      this.userName = this.version.updatedByName;
    }
    else {
      this.activeVersion = this.versions.find(v => (v.wfTableID == this.resource.wfTableID && v.branchlineId == this.resource.id && v.type == this.radioSelection && this.radioSelection == 1) || (v.wfTableID == this.resource.wfTableIDAbreviated && v.branchlineId == this.resource.id && v.type == this.radioSelection && this.radioSelection == 0));

      if (this.versions.length) {
        this.version = this.versions[this.versions.length - 1];
        this.keGlobal = this.version.wfTableConfig?.name;
        this.userName = this.version.updatedByName;
        this.radioSelection = this.version.type;
        this.wfTableId = this.version.wfTableID;
        this.resource = this.shutter?.branchlines && this.shutter.branchlines.length ? this.shutter.branchlines.find(b => b.id == this.version?.branchlineId) as Resource : this.shutter as Resource;
      }
      else {
        this.version = null;
        this.keGlobal = '';
        this.userName = '';
        this.wfTableId = 0;
      }
    }
    this.versionCtrl.setValue(this.version);
    this.tmpVersion = this.activeVersion ? this.activeVersion.name : '';
    this.approveAllowed = this.hasRoles([Roles.SA]) && this.shutter && this.activeVersion != this.versionCtrl.value && this.version?.updatedBy != this.currentUser?.id;
  }

  setButtons() {
    if (!this.hasRoles([Roles.KEE])) {
      this.buttonText = '';
    }
    else {
      this.buttonText = 'Create New';
    }
    this.status = this.activeVersion &&
      (this.activeVersion == this.versionCtrl.value ? 1 : moment(this.activeVersion.createdOn).isBefore(this.versionCtrl.value.createdOn) ? 0 : 2);

    this.createAllowed = (this.shutter && this.radioSelection > 0 && this.hasRoles([Roles.KEE])) ?? false;

    this.approveAllowed = this.hasRoles([Roles.SA]) &&
      this.shutter &&
      this.versionCtrl.value &&
      this.activeVersion != this.versionCtrl.value &&
      this.wfTableId != 0 &&
      this.wfTableId &&
      this.currentUser?.id != this.version?.updatedBy;

    this.userName = this.version?.updatedByName;

    this.disableSections = this.status != 0 || !this.hasRoles([Roles.KEE]) ||
      (this.status == 0 && this.currentUser?.id != this.version?.createdBy);
  }

  displayShutter(shutter: Resource) {
    return shutter ? shutter.name : null;
  }

  displayVersion(version: KEVersionResource) {
    return version ? version.name : null;
  }

  //#endregion

  //#region Branchlines
  selectedBranchline(event: MatAutocompleteSelectedEvent) {
    this.resource = event.option.value;
    this.tmpBranchline = this.resource.name;
    this.wfTableId = this.radioSelection == 1 ? this.resource.wfTableID : this.resource.wfTableIDAbreviated;

    this.getVersion();
    this.refresh();
  }

  displayBranchline(branchline: Resource) {
    return branchline ? branchline.name : null;
  }

  isBefore(a?: Date, b?: Date) {
    return moment(a).isBefore(b);
  }

  isAfter(a?: Date, b?: Date) {
    return moment(a).isAfter(b);
  }

  //#endregion

  refresh(loading = true) {
    this.setButtons();
    this.loading.emit(false);
  }

  setLoading() {
    this.loading.emit(this.section1?.loading || this.section2?.loading || this.section3?.loading || this.section4?.loading || this.section5?.loading || this.section6?.loading);
  }

  refreshHead() {
    this.refreshDetails();
  }

  showBeamline(bls: Resource) {

    if (bls != null) {
      this.beamlines = 'Affects ' + bls.name;
      this.disabled = false;
    }
    else {
      this.beamlines = '[No related Beamline found]';
      this.disabled = true;
    }
  }

  create() {
    if (!this.resource || this.resource.id == 0 || !this.shutter) { return; }

    this.loading.emit(true);
    const keVersion: KEVersionResource = {
      name: this.shutter.name,
      requesterId: this.currentUser?.id ?? 0,
      shutterId: this.shutter.id ?? 0,
      branchlineId: this.resource.id,
      status: 0,
      type: this.radioSelection
    };

    this._keBuilder.CreateChecklistTemplate(keVersion).subscribe(data => {
      this.shutter = data;
      this.getVersion();

      const lastVersion = this.versions[this.versions.length - 1];
      this.wfTableId = lastVersion.wfTableID;
      this.version = lastVersion;
      this.versionCtrl.setValue(this.version);
      this.keGlobal = this.version.wfTableConfig?.name;
      this.userName = this.version.updatedByName;
      // if (this.resource?.id == this.shutter.id) {
      //   this.requestRefresh.emit(this.shutter.beamline.id);
      // }
      this.refresh();
    },
      error => {
        console.log(error);
        this.alert.defaultError();
      });
  }

  recreate() {
    if (!this.shutter) return;
    const selectedVersion = this.version;
    this.loading.emit(true);
    const keVersion: KEVersionResource = {
      name: this.shutter.name,
      requesterId: this.currentUser?.id ?? 0,
      shutterId: this.shutter.id ?? 0,
      branchlineId: this.resource.id,
      status: 0,
      type: this.radioSelection,
      wfTableID: this.version?.wfTableID
    };

    this._keBuilder.ReCreateChecklistTemplate(keVersion).subscribe(data => {
      this.shutter = data;
      this.getVersion();

      this.wfTableId = selectedVersion?.wfTableID;
      this.version = this.versions.find(x => x.id == selectedVersion?.id);
      this.versionCtrl.setValue(this.version);
      this.keGlobal = this.version?.wfTableConfig?.name;
      this.userName = this.version?.updatedByName;
      this.loading.emit(false);
      this.refresh();
      this.refreshSections();
    },
      error => {
        console.log(error);
        this.alert.defaultError();
        this.loading.emit(false);
      });
  }

  refreshSections() {
    this.sections.map(s => s.refresh(this.version?.wfTableID));
  }

  duplicate() {
    if (!this.version || this.version.id == 0 || !this.shutter) { return; }

    this.loading.emit(true);

    const keVersion: KEVersionResource = {
      name: this.shutter.name,
      requesterId: this.currentUser?.id ?? 0,
      shutterId: this.shutter.id ?? 0,
      branchlineId: this.resource.id,
      status: 0,
      type: this.radioSelection,
      wfTableID: this.version.wfTableID
    };

    this._keBuilder.CreateChecklistTemplate(keVersion).subscribe(data => {
      this.shutter = data;
      this.getVersion();

      const lastVersion = this.versions[this.versions.length - 1];
      this.wfTableId = lastVersion.wfTableID;
      this.version = lastVersion;
      this.versionCtrl.setValue(lastVersion);
      this.keGlobal = this.version.wfTableConfig?.name;
      this.userName = this.version.updatedByName;
      this.loading.emit(false);
      this.showSelectedVersion();
      // this.store.dispatch(new BeamlineShutterRefresh());
    });
  }

  delete() {
    if (!this.version?.id) { return; }
    this.loading.emit(true);
    this._keBuilder.DeleteVersion(this.version.id).subscribe(data => {
      this.shutter = data;
      this.getVersion();
      this.showSelectedVersion();
      this.loading.emit(false);
    });
  }

  radioChanged(e: any) {
    this.radioSelection = e.value;
    this.wfTableId = e.value == 1 ? this.resource.wfTableID : this.resource.wfTableIDAbreviated;
    if (this.wfTableId == undefined) {
      this.wfTableId = 0;
    }

    this.getVersion();
    this.refresh();
  }

  filterVersions() {
    if (this.radioSelection == undefined) {
      this.radioSelection = 1;
    }

    const filter = this.shutter?.keVersions.filter(v => (this.resource ? (v.branchlineId == this.resource.id) : (v.shutterId == this.shutter?.id)) && v.type == this.radioSelection);
    return filter ?? [];
  }

  versionChange() {
    if (this.versions.map(v => v.name).includes(this.versionCtrl.value)) {
      const selected = this.versions.find(v => v.name == this.versionCtrl.value);
      this.version = selected;

      this.wfTableId = selected?.wfTableID;
    }
    else {
      this.wfTableId = 0;
    }

    this.refresh();
  }

  selectedVersion(event: MatAutocompleteSelectedEvent) {
    this.version = event.option.value;
    this.showSelectedVersion();
  }

  showSelectedVersion() {
    this.keGlobal = this.version?.wfTableConfig?.name;
    this.userName = this.version?.updatedByName;
    this.radioSelection = this.version?.type ?? 0;
    this.wfTableId = this.version?.wfTableID;
    this.refresh();
  }

  reloadShutter() {
    if (this.shutter?.id)
      this._res.getShutter(this.shutter.id).subscribe(data => {
        this.shutter = data;
        const index = this.allShutters.findIndex(s => s.id == this.shutter?.id);
        this.allShutters.splice(index);
        this.allShutters.push(this.shutter);
        this.resource = this.shutter as Resource;
        this.getVersion();
        this.refresh();
      });
  }

  approveVersion() {
    if (this.version)
      this._keBuilder.ApproveVersion(this.version).subscribe(() => {
        this.reloadShutter();
        // this.requestRefresh.emit(this.shutter.beamline.id);
      });
  }

  onProcedureNumberChange(filterValue: string) {
    this.proceduresFiltered = this.proceduresFiltered.filter(x => x.procedureNumber.toLowerCase().includes(filterValue));
  }

  displayProcedure(procedure: Procedure): string | null {
    return procedure.id ? `${procedure.procedureNumber} Rev: ${procedure.revision}` : null;
  }

  @HostListener('window:scroll')
  isHeaderOnTop() {
    let elemRec;
    if (this.headerTop?.nativeElement !== undefined) {
      elemRec = this.headerTop.nativeElement.getBoundingClientRect();
      const docViewTop = window.screenTop;
      const elemTop = elemRec.top;
      // console.log(elemTop,docViewTop);
      if (elemTop <= docViewTop) { this.headerOnTop = true; } else { this.headerOnTop = false; }
      // this.tableWidth = this.scheduleTable.nativeElement.offsetWidth + 'px';
    } else { this.headerOnTop = false; }
  }


}
