<ppstb-main-s1
  [form]="form"
  [showAmendment]="showAmendment"
  (loading)="loading.emit($event)"
  (formCreated)="formCreated.emit($event)"
  (formCancelled)="formCancelled.emit()"
></ppstb-main-s1>
<div
  *ngIf="
    form?.id &&
    (form?.formVersion?.statusID ?? 0) != formStatusEnum.New &&
    form?.formVersion?.statusID != formStatusEnum.Draft
  "
>
  <ppstb-main-s2 [form]="form" (loading)="loading.emit($event)"></ppstb-main-s2>
  <ppstb-main-s3 [form]="form" (loading)="loading.emit($event)"></ppstb-main-s3>
  <div class="container-fluid">
    <worker-verification-table
      [currentDocument]="{
        id: form?.formVersion?.id ?? 0,
        type: formTypeEnum.PPSTB,
        status: form?.formVersion?.statusID ?? 0
      }"
    ></worker-verification-table>
  </div>
  <ppstb-main-s4 [form]="form" (loading)="loading.emit($event)"></ppstb-main-s4>
  <ppstb-main-s5 [form]="form" (loading)="loading.emit($event)"></ppstb-main-s5>
  <ppstb-main-s6 [form]="form" (loading)="loading.emit($event)"></ppstb-main-s6>
  <ppstb-main-s7 [form]="form" (loading)="loading.emit($event)"></ppstb-main-s7>
  <ppstb-main-s8 [form]="form" (loading)="loading.emit($event)"></ppstb-main-s8>

  <div class="container-fluid mt-4">
    <comments-and-attachments
      *ngIf="form"
      headerClass="h5"
      id="Section5Title"
      [currentDocument]=" {
      status: form.formVersion.statusID,
      serialNo: form.sequenceNumber.number,
      type: formTypeEnum.PPSTB,
    }"
    >
    </comments-and-attachments>
  </div>

  <div class="container-fluid mt-2">
    <transaction-log
      [serialNo]="form?.sequenceNumber?.number"
      [formType]="formTypeEnum.PPSTB"
    ></transaction-log>
  </div>
</div>
