import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldDefaultOptions, MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { config } from 'src/assets/config';
import { AppRoutingModule } from '../app-routing.module';
import { AppComponent } from '../app.component';
import { QuillModule } from 'ngx-quill';
import { reducers } from '../store/reducers';
import { HighlightSearchPipe } from './pipes/highlightable-search.pipe';
import { httpInterceptorProviders } from '../interceptors/interceptorProviders';
import { OverlayLoadingComponent } from '../controls/overlay-loading/overlay-loading.component';
import { CommonModule } from '@angular/common';
import { FilterBoxComponent } from '../controls/filter-box/filter-box.component';
import { CommentsBoxModule } from '../controls/comments-box/comments-box.module';
import { PartialOlogComponent } from '../controls/olog/partial-olog/partial-olog.component';
import { NotificationComponent } from '../controls/notifications/notification.component';
import { AddRelatedLinkComponent } from '../controls/add-related-link/add-related-link.component';
import { LocationsService } from '../components/catalogs/beamline-catalog/resource/locations.service';
import { CancelDialogComponent } from '../controls/cancel-dialog/cancel-dialog.component';
import { WorkFlowService } from '../services/work-flow/work-flow.service';
import { TextareaDialogComponent } from '../controls/textarea-dialog/textarea-dialog.component';
import { RoleChipsComponent } from '../controls/role-chips/role-chips.component';
import { MatCheckboxWithRolesComponent } from '../controls/mat-checkbox-with-roles/mat-checkbox-with-roles.component';
import { CommentsAndAttachmentsModule } from '../controls/comments-and-attachments/comments-and-attachments.module';
import { RestrictionAvailableAutocompleteComponent } from '../controls/restrictions/restriction-available-autocomplete/restriction-available-autocomplete.component';
import { FormRestrictionTableComponent } from '../controls/restrictions/form-restriction-table/form-restriction-table.component';
import { ConfirmMessageComponent } from '../controls/confirm-message/confirm-message.component';
import { RelatedDocumentsComponent } from '../controls/related-documents/related-documents.component';
import { AcceleratorLocationChipsComponent } from '../controls/accelerator-location-chips/accelerator-location-chips.component';
import { RelatedLinkChipsComponent } from '../controls/related-link-chips/related-link-chips.component';
import { WorkerVerificationComponent } from '../controls/worker-verification/worker-verification.component';
import { ResourceAutocompleteComponent } from '../controls/resources/resource-autocomplete/resource-autocomplete.component';
import { FormTableComponent } from '../controls/form-table/form-table.component';
import { UserSelectComponent } from '../controls/user/user-select/user-select.component';
import { ResourceChipsComponent } from '../controls/resource-chips/resource-chips.component';
import { OtherSystemChipsComponent } from '../controls/other-system-chips/other-system-chips.component';
import { ExtraActionButtonComponent } from '../controls/checklist-components/extra-action-button/extra-action-button.component';
import { NormalInputDialogComponent } from '../controls/normal-input-dialog/normal-input-dialog.component';
import { DisapprovalReasonComponent } from '../controls/disapproval-reason/disapproval-reason.component';
import { APLCategoryAutocompleteComponent } from '../components/apl/apl-category-v2/controls/apl-category-autocomplete/apl-category-autocomplete.component';
import { APLGroupAutocompleteComponent } from '../components/apl/apl-category-v2/controls/apl-group-autocomplete/apl-group-autocomplete.component';
import { ProcedureAutocompleteComponent } from '../controls/procedure-autocomplete/procedure-autocomplete.component';
import { MatCheckboxWithLabelComponent } from '../controls/mat-checkbox-with-label/mat-checkbox-with-label.component';
import { ActionItemComponent } from '../controls/catl/action-item/action-item.component';
import { ActionItemEditComponent } from '../controls/catl/action-item/action-item-edit/action-item-edit.component';
import { ActionItemService } from '../controls/catl/action-item/action-item.service';
import { CommentsSummaryComponent } from '../controls/comments-summary/comments-summary.component';
import { UserAutocompletePopupComponent } from '../controls/user-autocomplete-popup/user-autocomplete-popup.component';
import { RejectDialogComponent } from '../controls/reject-dialog/reject-dialog.component';
import { ColorPickerComponent } from '../controls/color-picker/color-picker.component';
import { ChooseUsersComponent } from '../components/schedules/dialogs/choose-users/choose-users.component';
import { ChooseRolesComponent } from '../components/schedules/dialogs/choose-roles/choose-roles.component';
import { FileUploadV2Component } from '../controls/file-upload-v2/file-upload-v2.component';
import { RelatedLinkDocumentChipsComponent } from '../controls/related-link-document-chips/related-link-document-chips.component';
import { AddRelatedLinkDocumentComponent } from '../controls/add-related-link-document/add-related-link-document.component';
import { NotesComponent } from '../controls/notes/notes.component';
import { UserChipComponent } from '../components/apl/apl-category-v2/controls/user-chip/user-chip.component';
import { RoleChipComponent } from '../components/apl/apl-category-v2/controls/role-chip/role-chip.component';
import { RssTestShutterChipsComponent } from '../controls/rss-test-shutter-chips/rss-test-shutter-chips.component';
import { DatetimePickerComponent } from '../controls/datetime-picker/datetime-picker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ResizableDirective } from '../common/directives/resizable.directive';
import { OperationalRestrictionsComponent } from '../components/catalogs/operational-restrictions/operational-restrictions.component';
import { WaitingDotsComponent } from '../controls/waiting-dots/waiting-dots.component';
import { WaitingTextComponent } from '../controls/waiting-text/waiting-text.component';
import { PvInfoComponent } from '../controls/pvinfo/pvinfo.component';
import { PvInfoDetailsComponent } from '../controls/pvinfo/pvinfo-details/pvinfo-details.component';
import { CatalogHistoryNavigationComponent } from '../controls/catalog-history-navigation/catalog-history-navigation.component';
import { MatSliderModule } from '@angular/material/slider';
import { StripSpacesDirective } from '../common/directives/strip-spaces.directive';
import { OlogButtonComponent } from '../components/olog/olog-button/olog-button.component';
import { InitialsChipComponent } from '../controls/initials-chip/initials-chip.component';
import { TransactionLogComponent } from '../controls/transaction-log/transaction-log.component';
import { TransactionLogAmendmentsComponent } from '../controls/transaction-log/transaction-log-amendments/transaction-log-amendments.component';
import { TransactionLogApprovalsComponent } from '../controls/transaction-log/transaction-log-approvals/transaction-log-approvals.component';
import { EbModule } from '../components/eb/eb.module';
import { EbMainS1Component } from '../components/eb/eb-main/eb-main-s1/eb-main-s1.component';
import { ChecklistComponentsModule } from '../controls/checklist-components/checklist-components.module';
import { PpstbMainS1Component } from '../components/ppstb/ppstb-main/ppstb-main-s1/ppstb-main-s1.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

@NgModule({
  declarations: [
    RelatedLinkDocumentChipsComponent,
    AddRelatedLinkDocumentComponent,
    AddRelatedLinkComponent,
    UserAutocompletePopupComponent,
    UserChipComponent,
    RoleChipComponent,
    ColorPickerComponent,
    FileUploadV2Component,
    OverlayLoadingComponent,
    PartialOlogComponent,
    WorkerVerificationComponent,
    NotificationComponent,
    HighlightSearchPipe,
    FilterBoxComponent,
    CancelDialogComponent,
    TextareaDialogComponent,
    RoleChipsComponent,
    MatCheckboxWithRolesComponent,
    MatCheckboxWithLabelComponent,
    RestrictionAvailableAutocompleteComponent,
    FormRestrictionTableComponent,
    ConfirmMessageComponent,
    RelatedDocumentsComponent,
    RelatedLinkChipsComponent,
    AcceleratorLocationChipsComponent,
    ResourceAutocompleteComponent,
    FormTableComponent,
    UserSelectComponent,
    ResourceChipsComponent,
    OtherSystemChipsComponent,
    ExtraActionButtonComponent,
    NormalInputDialogComponent,
    DisapprovalReasonComponent,
    ActionItemComponent,
    ActionItemEditComponent,
    RejectDialogComponent,
    APLCategoryAutocompleteComponent,
    APLGroupAutocompleteComponent,
    ProcedureAutocompleteComponent,
    CommentsSummaryComponent,
    ChooseUsersComponent,
    ChooseRolesComponent,
    NotesComponent,
    RssTestShutterChipsComponent,
    DatetimePickerComponent,
    ResizableDirective,
    OperationalRestrictionsComponent,
    WaitingDotsComponent,
    WaitingTextComponent,
    PvInfoComponent,
    PvInfoDetailsComponent,
    OperationalRestrictionsComponent,
    CatalogHistoryNavigationComponent,
    StripSpacesDirective,
    OlogButtonComponent,
    InitialsChipComponent,
    TransactionLogComponent,
    TransactionLogApprovalsComponent,
    TransactionLogAmendmentsComponent,
    EbMainS1Component,
    PpstbMainS1Component
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    HttpClientModule,
    ScrollingModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatRadioModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatGridListModule,
    MatProgressBarModule,
    DragDropModule,
    MatTreeModule,
    MatExpansionModule,
    MatSidenavModule,
    CommentsBoxModule,
    CommentsAndAttachmentsModule,
    ChecklistComponentsModule,
    NgxMaterialTimepickerModule,
    FormsModule,
    MatSliderModule,
    QuillModule.forRoot(),
    NgxsModule.forRoot(reducers, {
      developmentMode: config.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  exports: [
    RouterModule,
    RelatedLinkDocumentChipsComponent,
    AddRelatedLinkDocumentComponent,
    AddRelatedLinkComponent,
    UserChipComponent,
    RoleChipComponent,
    FileUploadV2Component,
    ColorPickerComponent,
    OverlayLoadingComponent,
    PartialOlogComponent,
    NotificationComponent,
    WorkerVerificationComponent,
    HighlightSearchPipe,
    FilterBoxComponent,
    CommentsBoxModule,
    RoleChipsComponent,
    CancelDialogComponent,
    TextareaDialogComponent,
    MatCheckboxWithRolesComponent,
    MatCheckboxWithLabelComponent,
    CommentsAndAttachmentsModule,
    RestrictionAvailableAutocompleteComponent,
    FormRestrictionTableComponent,
    ConfirmMessageComponent,
    RelatedDocumentsComponent,
    AcceleratorLocationChipsComponent,
    RelatedLinkChipsComponent,
    ResourceAutocompleteComponent,
    FormTableComponent,
    UserSelectComponent,
    ResourceChipsComponent,
    OtherSystemChipsComponent,
    ExtraActionButtonComponent,
    NormalInputDialogComponent,
    DisapprovalReasonComponent,
    ActionItemComponent,
    ActionItemEditComponent,
    APLCategoryAutocompleteComponent,
    APLGroupAutocompleteComponent,
    ProcedureAutocompleteComponent,
    NotesComponent,
    RssTestShutterChipsComponent,
    DatetimePickerComponent,
    ResizableDirective,
    OperationalRestrictionsComponent,
    WaitingDotsComponent,
    WaitingTextComponent,
    PvInfoComponent,
    PvInfoDetailsComponent,
    OperationalRestrictionsComponent,
    StripSpacesDirective,
    CatalogHistoryNavigationComponent,
    OlogButtonComponent,
    InitialsChipComponent,
    TransactionLogComponent,
    TransactionLogApprovalsComponent,
    TransactionLogAmendmentsComponent,
    EbMainS1Component,
    PpstbMainS1Component,
    ChecklistComponentsModule
  ],
  providers: [
    LocationsService,
    WorkFlowService,
    ActionItemService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
