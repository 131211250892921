<div class="card">
  <div class="card-header">
    <span class="card-title">{{ title }}</span>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="no-subscript">
          <mat-label>Name</mat-label>
          <input
            #reviewTypeNameCtrlElement
            type="text"
            placeholder="Name"
            matInput
            name="reviewTypeNameCtrl"
            [formControl]="reviewTypeNameCtrl"
          />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="outline" class="no-subscript">
          <mat-label>Description</mat-label>
          <input
            type="text"
            placeholder="Description"
            matInput
            name="reviewTypeDescriptionCtrl"
            [formControl]="reviewTypeDescriptionCtrl"
          />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="outline" class="no-subscript">
          <mat-label>Acronym</mat-label>
          <input
            type="text"
            placeholder="Description"
            matInput
            name="reviewTypeCodeCtrl"
            [formControl]="reviewTypeCodeCtrl"
          />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="outline" class="no-subscript">
          <mat-label>Template Type</mat-label>
          <input
            type="text"
            placeholder="Select Template Type"
            matInput
            name="templateTypeCtrl"
            [formControl]="templateTypeCtrl"
            [matAutocomplete]="autoTemplateType"
            (keyup)="changedTemplateType($event)"
          />
          <mat-autocomplete
            #autoTemplateType="matAutocomplete"
            #templateTypeAutocomplete
            [displayWith]="displayObject"
          >
            <mat-option
              *ngFor="let templateType of templateTypesFiltered"
              [value]="templateType"
            >
              <span>({{ templateType.code }}) {{ templateType.name }}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-12 pb-2">
        <mat-slide-toggle
          color="accent"
          [(ngModel)]="hasNextReview"
          (change)="hasNextReviewChanged($event)"
          >Autocreate Next Review on Complete
        </mat-slide-toggle>
      </div>
      <div class="col-12" *ngIf="hasNextReview">
        <mat-form-field appearance="outline" class="no-subscript">
          <mat-label>Next Review Type</mat-label>
          <input
            type="text"
            placeholder="Select Next Review Type"
            matInput
            name="reviewTypeCtrl"
            [formControl]="reviewTypeCtrl"
            [matAutocomplete]="autoReviewType"
            (keyup)="changedReviewType($event)"
          />
          <mat-autocomplete
            #autoReviewType="matAutocomplete"
            #reviewTypeAutocomplete
            [displayWith]="displayObject"
          >
            <mat-option
              *ngFor="let reviewType of reviewTypesFiltered"
              [value]="reviewType"
            >
              <span>({{ reviewType.name }}) ({{ reviewType.code }})</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Life Span (Weeks)</mat-label>
          <input
            type="text"
            placeholder="Life Span in Weeks"
            matInput
            name="reviewTypeLifeSpanCtrl"
            [formControl]="reviewTypeLifeSpanCtrl"
          />
          <mat-hint>
            Number of Weeks before Review Expiration, leave this field blank for
            NO Expiration date.
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-stroked-button
        color="warn"
        (click)="cancel()"
        [disabled]="disabled"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!(form.dirty && formValid()) || disabled"
        (click)="save()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
