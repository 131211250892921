import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ResourceType } from 'src/app/common/enumerations/enumerations';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { CatalogService } from '../../../catalog-service';


@Component({
  selector: 'app-branchline',
  templateUrl: './branchline.component.html',
  styleUrls: ['./branchline.component.scss']
})
export class BranchlineComponent extends BaseComponent implements OnInit {

  public branchlineName?: string | null;
  public oldBranchline?: string | null;

  public isSaving!: boolean;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<BranchlineComponent>,
    @Inject(MAT_DIALOG_DATA) public resource: Resource,
    private catalogService: CatalogService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.branchlineName = this.resource.name;
    this.oldBranchline = this.resource.name;
  }

  async add() {
    this.isSaving = true;
    if (await this.isValid()) {
      this.resource.name = this.branchlineName;
      this.dialogRef.close(this.resource);
    } else {
      this.isSaving = false;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  async isValid() {
    if (!this.branchlineName) {
      this.alert.message('branchlineDialog_Required');
      return false;
    }
    if (this.oldBranchline !== this.branchlineName) {
      const isRepeated = await this.catalogService.IsFieldRepeatedResource(this.branchlineName, ResourceType.Branchline).toPromise();
      if (isRepeated) {
        this.alert.message('branchlineDialog_Repeated');
        return false;
      }
    }
    return true;
  }

}
