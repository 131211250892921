import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";

export class PPSTBAcceleratorLocation {
  public id?: number;
  public ppstbID?: number;
  public resourceID?: number;
  public resource!: Resource;
}

export class PPSTBAcceleratorLocationSave {
  public id?: number;
  public ppstbID?: number;
  public resourceID?: number;
}

export class PPSTBAcceleratorLocationHandler {
  static toSave(ppstbAcceleratorLocation: PPSTBAcceleratorLocation): PPSTBAcceleratorLocationSave {
    var save = new PPSTBAcceleratorLocationSave();

    save.id = ppstbAcceleratorLocation.id;
    save.ppstbID = ppstbAcceleratorLocation.ppstbID;
    save.resourceID = ppstbAcceleratorLocation.resourceID;

    return save;
  }
}
