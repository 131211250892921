import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Accelerator } from 'src/app/components/catalogs/beamline-catalog/resource/resources';

@Component({
  selector: 'app-accelerator-status',
  templateUrl: './accelerator-status.component.html',
  styleUrls: ['./accelerator-status.component.scss']
})
export class AcceleratorStatusComponent implements OnInit, OnDestroy {

  public acceleratorStatus$: Observable<Accelerator[]>;
  public accStatusSubscription: Subscription;
  public acceleratorStatus!: Accelerator[];
  public loading: boolean;

  constructor(
    private store: Store
  ) {
    this.loading = true;
    this.acceleratorStatus$ = this.store.select(state => state.AcceleratorStatus.acceleratorStatus);
    this.accStatusSubscription = this.acceleratorStatus$.subscribe(acceleratorStatus => {
      this.loading = true;
      if (acceleratorStatus?.length) {
        acceleratorStatus.map(accelerator => {
          accelerator.moreInfo = accelerator.documents.length ? accelerator.documents.join(' ') : '';
        });
        this.acceleratorStatus = acceleratorStatus;
        this.loading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.accStatusSubscription.unsubscribe();
  }

  ngOnInit() {
  }

  getClass(text: string) {
    let style = '';
    if (text) {
      style = text.trim().toLowerCase();
    }
    switch (style) {
      case 'not ready': return 'badge badge-warning';
      case 'ready': return 'badge badge-primary';
      default: return 'badge badge';
    }
  }

}
