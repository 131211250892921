<mat-progress-bar
  class="progress-bar"
  mode="indeterminate"
  *ngIf="loading"
></mat-progress-bar>
<div class="row center">
  <b class="center">
    <label> Edit RSS Functional Test </label>
  </b>
</div>
<div class="row">
  <div class="col-7">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input
        matInput
        type="text"
        [disabled]="loading"
        [(ngModel)]="data.rssFunctionalTest.name"
      />
    </mat-form-field>
  </div>
  <div class="col-2">
    <mat-form-field>
      <mat-label>Resource</mat-label>
      <input matInput type="text" [disabled]="true" [(ngModel)]="resource" />
    </mat-form-field>
  </div>
  <div class="col-2">
    <mat-form-field>
      <mat-label>Template</mat-label>
      <input
        matInput
        type="text"
        [disabled]="true"
        [(ngModel)]="templateType"
      />
    </mat-form-field>
  </div>
  <div class="col-1">
    <button
      mat-flat-button
      color="primary"
      [disabled]="loading"
      (click)="save()"
    >
      Save
    </button>
  </div>
</div>
<div *ngIf="checklistTemplate">
  <div class="row">
    <div class="col">
      <!-- <checklist-builder-sections [sections]="sections" [currentVersion]="checklistTemplate" [wfTableID]="checklistTemplate?.wfTableID" [disabled]="false"></checklist-builder-sections> -->
    </div>
  </div>
</div>
