<div>
  <div class="card p-0">
    <div class="card-header d-flex justify-content-between">
      <h6 class="card-title">Bakeout</h6>
      <div class="header-buttons">
        <button
          mat-icon-button
          color="primary"
          class="focus-outline-none"
          (click)="showArchive()"
        >
          <mat-icon>history</mat-icon>
        </button>
        <button
          mat-icon-button
          color="accent"
          class="focus-outline-none ml-1"
          *ngIf="getCurrentUser()"
          (click)="showAdd()"
        >
          <mat-icon>add_box</mat-icon>
        </button>
      </div>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div class="card-body p-0">
      <div *ngFor="let item of actives" class="small blue">
        <div class="item mb-0 p-1 relative">
          <button
            mat-icon-button
            class="fixed focus-outline-none"
            color="primary"
            [matMenuTriggerFor]="options"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #options="matMenu">
            <button mat-menu-item (click)="showEdit(item)">
              <mat-icon color="accent">edit</mat-icon
              ><span class="Option">Edit</span>
            </button>
            <button mat-menu-item (click)="archive(item.id)">
              <mat-icon class="gray">archive</mat-icon
              ><span class="Option">Archive</span>
            </button>
          </mat-menu>

          <div class="row no-gutters">
            <div class="col">
              User: <b> {{ item.createdByUser }} </b>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col">
              Phone number: <b> {{ item.name }} </b>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col">
              Location: <b>{{ item.description }} </b>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col">
              Estimated Start:
              <b> {{ item.estimatedStart | date : "yyyy-MM-dd" }} </b>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col">
              Estimated End:
              <b [ngClass]="{ red: checkExpired(item.estimatedEnd) }">
                {{ item.estimatedEnd | date : "yyyy-MM-dd" }}
              </b>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col">
              Temperature:
              <b> {{ item.temperature }} &deg;{{ item.temperatureUnit }} </b>
            </div>
          </div>
          <div
            class="row no-gutters"
            *ngIf="
              item.temperatureNotes != null && item.temperatureNotes.length > 0
            "
          >
            <div class="col">
              Control:
              <b> {{ item.temperatureNotes }} </b>
            </div>
          </div>
          <div
            class="row no-gutters"
            *ngIf="
              item.additionalNotes != null && item.additionalNotes.length > 0
            "
          >
            <div class="col">
              Additional Note: <b> {{ item.additionalNotes }} </b>
            </div>
          </div>
          <!-- created and edited -->
          <!-- <div class="row no-gutters">
            <div class="col small text-right">
              Created by {{ item.createdByUser }} on
              {{ item.createdOn | date : "yyyy-MM-dd" }}.
              <span *ngIf="item.updatedByUser != null"
                >Last edited by {{ item.updatedByUser }} on
                {{ item.updatedOn | date : "yyyy-MM-dd" }}.</span
              >
            </div>
          </div> -->
          <div class="row">
            <div class="col small text-right">
              <span [matTooltip]="item.createdByUser ?? ''">
                Created by {{ item.createdByInitials }} on
                {{ item.createdOn | date : "yyyy-MM-dd" }}.
              </span>
            </div>
          </div>
          <div class="row" *ngIf="item.updatedByUser != null">
            <div class="col small text-right">
              <span [matTooltip]="item.updatedByUser">
                Last edited by {{ item.updatedByInitials }} on
                {{ item.updatedOn | date : "yyyy-MM-dd" }}.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="px-2 pb-2 pt-2">
      <div class="row sticky">
        <div class="col">

        </div>

      </div>
      <div
        class="card-body p-0 mt-2 small"
        style="position: relative; overflow: auto"
      >

      </div>
    </div> -->
  </div>
</div>
