BRATOI:
<mat-form-field>
  <mat-select
    [disabled]="disabled ?? false"
    [(ngModel)]="blRequiringApproval"
    (selectionChange)="blRequiringSelected($event.value)"
  >
    <mat-option
      *ngFor="let blRequiring of blRequiringApprovals"
      [value]="blRequiring"
    >
      {{ blRequiring.resource?.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
