<div class="card">
  <div class="card-header">
    <span class="card-title">{{ vacuumId ? "Edit" : "Add" }} Vacuum</span>
  </div>
  <div class="card-body">
    <mat-form-field>
      <mat-label>Vacuum Name</mat-label>
      <input
        [tabIndex]="99"
        matInput
        [(ngModel)]="vacuumName"
        type="text"
        placeholder="Vacuum Name"
        [matAutocomplete]="auto"
        (keyup)="filter($event)"
        (blur)="RemoveInfo($event)"
        [ngModelOptions]="{ standalone: true }"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let vacuum of vacuumsFiltered"
          [value]="vacuum.name"
          (onSelectionChange)="getInfoVacuum(vacuum)"
        >
          {{ vacuum.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Min Pressure</mat-label>
      <input
        matInput
        [(ngModel)]="vacuumMinPressure"
        placeholder="Min Pressure"
        (blur)="changeFormatMin()"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Max Pressure</mat-label>
      <input
        matInput
        [(ngModel)]="vacuumMaxPressure"
        placeholder="Max Pressure"
        (blur)="changeFormatMax()"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Channel Name (PV)</mat-label>
      <input
        matInput
        [(ngModel)]="vacuumChannelName"
        placeholder="Channel Name"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Location</mat-label>
      <input
        matInput
        [(ngModel)]="vacuumLocation"
        placeholder="Location"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()" [tabIndex]="1">
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="isSaving"
        (click)="add()"
      >
        Add
      </button>
    </div>
  </div>
</div>
