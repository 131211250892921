import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Location } from './resources';

@Injectable()
export class LocationsService {

  BASE_URL = environment.urls.baseApiUrl; // Api URL
  Locations = '/Locations';

  constructor(private http: HttpClient) { }

  getLocations(): Observable<Location[]> {
    const httpresult2 = this.http.get<Location[]>(this.BASE_URL + this.Locations);
    return httpresult2;
  }
  GetLocationByShieldingID(id: number): Observable<Location[]> {
    const httpresult2 = this.http.get<Location[]>(this.BASE_URL + this.Locations + '/GetLocationByShieldingID/' + id.toString());
    return httpresult2;
  }
  GetShuttersByShieldingID(id: number): Observable<Location[]> {
    const httpresult2 = this.http.get<Location[]>(this.BASE_URL + this.Locations + '/GetShuttersByShieldingID/' + id.toString());
    return httpresult2;
  }
}
