<div class="pt-2 main-body">
  <form #form="ngForm">
    <div class="row px-3">
      <div class="col-8 col-md-5 col-xl-3">
        <h5>{{ shutter ? shutter.name : null }}</h5>
        <h6>Key Enable Checklist Builder</h6>
      </div>

      <!-- <ng-container *ngIf="showBranchlines || innerWidth > 576">
        <div class="col-8 col-md-4 col-xl-2 pl-0" *ngIf="showBranchlines"> -->
      <div class="col-8 col-md-4 col-xl-2 pl-0">
        <mat-form-field
          appearance="outline"
          class="p-0"
          *ngIf="showBranchlines"
        >
          <mat-label>Shutter or Branchline</mat-label>
          <input
            matInput
            type="text"
            #branchlineInput
            [formControl]="branchlineCtrl"
            [matAutocomplete]="autoBran"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #autoBran="matAutocomplete"
            (optionSelected)="selectedBranchline($event)"
            #branchlineAutocomplete
            [displayWith]="displayObject"
          >
            <mat-option *ngFor="let elem of branchlines" [value]="elem">
              {{ elem.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <!-- </div>
      </ng-container> -->

      <div class="col-4 col-md-3 col-xl-2 p-0 small">
        <mat-radio-group aria-label="Select an option" class="radio-group">
          <mat-radio-button
            *ngFor="let r of brOptions"
            class="radio-button"
            [value]="r.value"
            [checked]="r.checked"
            (change)="radioChanged($event)"
          >
            {{ r.label }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="col-8 col-md-6 col-xl-4">
        <mat-form-field appearance="outline" class="p-0" [disabled]="false">
          <mat-label>Version</mat-label>
          <input
            matInput
            type="text"
            #versionInput
            [formControl]="versionCtrl"
            [matAutocomplete]="autoVersion"
            (change)="versionChange()"
            class="version"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #autoVersion="matAutocomplete"
            (optionSelected)="selectedVersion($event)"
            #versionAutocomplete
            [displayWith]="displayObject"
          >
            <mat-option
              *ngFor="let elem of versions"
              [value]="elem"
              class="small"
              [ngClass]="{ current: elem == activeVersion }"
            >
              <span
                [ngClass]="{
                  'current-version': elem == activeVersion,
                  'old-version':
                    elem &&
                    activeVersion &&
                    isBefore(elem.createdOn, activeVersion.createdOn),
                  'new-version':
                    elem &&
                    activeVersion &&
                    isAfter(elem.createdOn, activeVersion.createdOn)
                }"
              >
                {{ elem.name }}

                <span *ngIf="elem == activeVersion" class="small"
                  >Active Version</span
                >
                <span
                  *ngIf="
                    elem &&
                    activeVersion &&
                    isAfter(elem.createdOn, activeVersion.createdOn)
                  "
                  class="small"
                  >New Version</span
                >
              </span>
            </mat-option>
          </mat-autocomplete>
          <mat-hint>
            <span *ngIf="status == 1" class="current-version">
              Active Version
            </span>
            <span
              *ngIf="
                activeVersion &&
                versionCtrl.value &&
                isBefore(versionCtrl.value.createdOn, activeVersion.createdOn)
              "
              class="old-version"
            >
              Old Version
            </span>
            <span
              *ngIf="
                (activeVersion &&
                  versionCtrl.value &&
                  isAfter(
                    versionCtrl.value.createdOn,
                    activeVersion.createdOn
                  )) ||
                (activeVersion == undefined && versionCtrl.value)
              "
              class="new-version"
            >
              New Version
            </span>
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="col-4 col-md-4 col-xl-1 small button-menu flex-end hidden">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="Action Menu"
          class="float-right"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngIf="createAllowed" (click)="create()">
            <mat-icon color="accent">add</mat-icon>
            <span> {{ buttonText }}</span>
          </button>
          <button mat-menu-item *ngIf="createAllowed" (click)="duplicate()">
            <mat-icon class="orange">content_copy</mat-icon>
            <span> Duplicate</span>
          </button>
          <button mat-menu-item *ngIf="createAllowed" (click)="recreate()">
            <mat-icon color="primary">update</mat-icon>
            <span> Update values</span>
          </button>
          <button
            mat-menu-item
            *ngIf="createAllowed && status != 1"
            (click)="delete()"
          >
            <mat-icon color="warn">delete</mat-icon>
            <span> Delete</span>
          </button>
          <button
            mat-menu-item
            *ngIf="approveAllowed"
            (click)="approveVersion()"
          >
            <mat-icon class="green">done</mat-icon>
            <span>Approve this version</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div #headerTop class="row pt-2 pb-2 px-3 border-bottom">
      <div class="col-3 pr-0">
        <div class="small">
          Global Template: <b>{{ keGlobal }}</b>
        </div>
      </div>

      <div class="col-9 d-flex justify-content-end">
        <div class="small nowrap px-1" *ngIf="userName">
          Last update by: <b>{{ userName }}</b
          >&nbsp;on:
          <span class="date">{{ version?.updatedOn | date }}</span>
        </div>
        <div class="small nowrap px-1" *ngIf="version?.approvedBy">
          Approved by: <b>{{ version?.approvedBy?.name }}</b
          >&nbsp;on:
          <span class="date">{{ version?.approvedOn | date }}</span>
        </div>
      </div>
    </div>

    <div class="scrollbar sidenav-container">
      <ke-cl-builder-section
        id="section1"
        #section1
        [keVersion]="version"
        [section]="1"
        [shutter]="shutter"
        [serialNo]="version?.name"
        [disabled]="disableSections"
        (refreshHead)="refreshHead()"
      ></ke-cl-builder-section>
      <ke-cl-builder-section
        id="section2"
        #section2
        [keVersion]="version"
        [section]="2"
        [shutter]="shutter"
        [serialNo]="version?.name"
        [disabled]="disableSections"
        (refreshHead)="refreshHead()"
      ></ke-cl-builder-section>
      <ke-cl-builder-section
        id="section3"
        #section3
        [keVersion]="version"
        [section]="3"
        [shutter]="shutter"
        [serialNo]="version?.name"
        [disabled]="disableSections"
        (refreshHead)="refreshHead()"
      ></ke-cl-builder-section>
      <ke-cl-builder-section
        id="section4"
        #section4
        [keVersion]="version"
        [section]="4"
        [shutter]="shutter"
        [serialNo]="version?.name"
        [disabled]="disableSections"
        (refreshHead)="refreshHead()"
      ></ke-cl-builder-section>
      <ke-cl-builder-section
        id="section5"
        #section5
        [keVersion]="version"
        [section]="5"
        [shutter]="shutter"
        [serialNo]="version?.name"
        [disabled]="disableSections"
        (refreshHead)="refreshHead()"
      ></ke-cl-builder-section>
      <ke-cl-builder-section
        id="section6"
        #section6
        [keVersion]="version"
        [section]="6"
        [shutter]="shutter"
        [serialNo]="version?.name"
        [disabled]="disableSections"
        (refreshHead)="refreshHead()"
      ></ke-cl-builder-section>
    </div>
  </form>
</div>
