import { WFSectionLocalResource } from "src/app/services/work-flow/work-flow";


export class AbsiSectionRefresh {
  static readonly type = '[AbsiSection] Refresh';
  constructor(public wfTableID: number) { }
}
export class AbsiSectionCreate {
  static readonly type = '[AbsiSection] Create';
  constructor(public item: WFSectionLocalResource) { }
}
export class AbsiSectionUpdate {
  static readonly type = '[AbsiSection] Update';
  constructor(public id: number, public item: WFSectionLocalResource) { }
}
export class AbsiSectionDelete {
  static readonly type = '[AbsiSection] Delete';
  constructor(public id: number) { }
}
