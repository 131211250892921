import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { utils } from 'src/app/modules/libs/utils';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { Privilege, RolePrivilege } from 'src/app/components/catalogs/roles/services/role-privilege';
import { PrivilegeService } from 'src/app/services/role-privilege/privilege.service';
import { RolePrivilegeService } from 'src/app/services/role-privilege/role-privilege.service';

@Component({
  selector: 'scf-v2-settings-permissions',
  templateUrl: './scf-v2-settings-permissions.component.html',
  styleUrls: ['./scf-v2-settings-permissions.component.scss']
})
export class ScfV2SettingsPermissionsComponent implements OnInit, OnDestroy {
  @Output() isloading = new EventEmitter<boolean>();

  privileges?: Privilege[];
  privilegesFiltered?: Privilege[];
  privileges$!: Observable<Privilege[]>;
  privilegesSubs!: Subscription;

  privGroups?: PrivilegeGroup[];

  rolePrivileges!: RolePrivilege[];
  rolePrivileges$!: Observable<RolePrivilege[]>;
  rolePrivilegesSubs!: Subscription;

  roles!: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;
  rolesFiltered!: Role[];

  roleTemp!: string;

  constructor(
    private store: Store,
    private service: RolePrivilegeService
  ) { }

  ngOnDestroy(): void {
    this.rolePrivilegesSubs?.unsubscribe();
    this.rolesSubs?.unsubscribe();

  }

  async ngOnInit(): Promise<void> {
    this.loadRolePrivileges();
    this.loadRoles();
  }

  loadPrivileges() {
    this.isloading.emit(true);
    this.privileges$ = this.store.select(state => state.Privileges.data);
    this.privilegesSubs = this.privileges$.subscribe(data => {
      if (data?.length) {
        this.privileges = data;
        this.privilegesFiltered = this.privileges?.filter(p => p.privilegeCategoryID == FormType.SCF).sort((a, b) => utils.sort(a.code ?? '', b.code ?? '', true));
        this.privilegesFiltered.map(p => p.roles = this.getRolePrivileges(p.privilegeEnum));
        this.privGroups = [];
        this.privilegesFiltered?.map(p => {
          if (p.code && !this.privGroups?.map(p => p.name).includes(p.code))
            this.privGroups?.push({ name: p.code, privileges: this.privilegesFiltered?.filter(f => f.code == p.code) });
        });
        this.isloading.emit(false);
      }
    });
  }

  loadRolePrivileges() {
    this.rolePrivileges$ = this.store.select(state => state.RolePrivileges.data);
    this.rolePrivilegesSubs = this.rolePrivileges$.subscribe(data => {
      this.rolePrivileges = data;
      this.loadPrivileges();
    });
  }

  loadRoles() {
    this.isloading.emit(true);
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      this.roles = data;
      this.isloading.emit(false);
    });
  }

  getRolePrivileges(privilegeEnum: PrivilegeEnum) {
    return this.rolePrivileges.filter(r => r.privilege == privilegeEnum).map(p => p.role) as Role[];
  }

  filterRoles(privilege: Privilege) {
    const rolePrivileges = this.rolePrivileges.filter(x => x.privilege == privilege.privilegeEnum);
    const roles = this.roles?.filter(x => x.id ? !rolePrivileges.map(r => r.roleID).includes(x.id) : false);
    this.rolesFiltered = roles;
  }

  roleChanged(e: any, privilege: Privilege) {
    const value = e.target.value;
    this.filterRoles(privilege);
    this.rolesFiltered = this.rolesFiltered.filter(x => x.name?.toLowerCase().includes(value.toLowerCase()) || x.code?.toLowerCase().includes(value.toLowerCase()));
  }

  selectedRole(role: Role, privilege: Privilege) {
    if (this.rolePrivileges == null) { this.rolePrivileges = []; }
    const rolePrivilege = {
      id: 0,
      roleID: role.id,
      role: this.roles.find(x => x.id == role.id),
      privilege: privilege.privilegeEnum,
    } as RolePrivilege;
    this.service.createRolePrivilege(rolePrivilege).toPromise().then(data => {
    });
  }

  deleteRole(role: Role, privilege: Privilege) {
    const rolePrivilege = this.rolePrivileges.find(p => p.privilege == privilege.privilegeEnum && p.roleID == role.id);
    if (rolePrivilege?.id)
      this.service.deleteRolePrivilege(rolePrivilege.id).toPromise().then(() => {
      });
  }

}


class PrivilegeGroup {
  name?: string;
  privileges?: Privilege[];
}
