import { AbsiResource } from "../../absi";

export class AbsiRefresh {
  static readonly type = '[Absi] Refresh';
  constructor(public id: number) { }
}
export class AbsiRefreshByID {
  static readonly type = '[Absi] RefreshByID';
  constructor(public id: number) { }
}
export class AbsiCreate {
  static readonly type = '[Absi] Create';
  constructor(public item: AbsiResource) { }
}
export class AbsiUpdate {
  static readonly type = '[Absi] Update';
  constructor(public id: number, public item: AbsiResource) { }
}
export class AbsiDelete {
  static readonly type = '[Absi] Delete';
  constructor(public id: number) { }
}
