import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as PrivilegeActions from './privilege.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';

@Injectable({
  providedIn: 'root'
})
export class PrivilegeStoreAndListenersService {

  constructor(
    private store: Store,
    private signalRService: SignalRService
  ) { }

  initStore() {
    this.store.dispatch(new PrivilegeActions.PrivilegeRefresh());
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.PRIVILEGES, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new PrivilegeActions.PrivilegeCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new PrivilegeActions.PrivilegeUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new PrivilegeActions.PrivilegeDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new PrivilegeActions.PrivilegeRefresh());
          break;
      }
    });
  }
}
