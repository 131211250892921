import { BulletinBoardItem } from "../bulletin-board-item";


export class BulletinBoardRefresh {
  static readonly type = '[BulletinBoard] Refresh';
}

export class BulletinBoardCreate {
  static readonly type = '[BulletinBoard] Create';
  constructor(public item: BulletinBoardItem) { }
}

export class BulletinBoardUpdate {
  static readonly type = '[BulletinBoard] Update';
  constructor(public id: number, public item: BulletinBoardItem) { }
}

export class BulletinBoardDelete {
  static readonly type = '[BulletinBoard] Delete';
  constructor(public id: number) { }
}
