import { MenuItem } from "../head-menu/head-menu.models";

export class MenuItemRefresh {
  static readonly type = '[MenuItem] Refresh';
}
export class MenuItemCreate {
  static readonly type = '[MenuItem] Create';
  constructor(public item: MenuItem) { }
}
export class MenuItemUpdate {
  static readonly type = '[MenuItem] Update';
  constructor(public id: number, public item: MenuItem) { }
}
export class MenuItemDelete {
  static readonly type = '[MenuItem] Delete';
  constructor(public id: number) { }
}
