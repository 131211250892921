<fieldset class="field-group">
  <legend>
    Options to compare {{ placeholder ?? "" | placeholderHighlight : true }}
  </legend>

  <div class="row no-gutters pb-1 small">
    <!-- <div class="col-1">Opt</div> -->
    <div class="col-5">Label</div>
    <div class="col-3">Oper</div>
    <div class="col-3">Value</div>
    <div class="col-1 small">
      <div *ngIf="!disabled" class="btnx" (click)="add()">
        <mat-icon color="primary">add</mat-icon>
      </div>
    </div>
  </div>
  <div class="max-200">
    <div class="row no-gutters" *ngFor="let o of tmpOptions">
      <!-- <div class="col-1 pr-1">
        <mat-label>
          {{ o.id }}
        </mat-label>
      </div> -->
      <div class="col-5 pr-1">
        <mat-form-field class="no-subscript">
          <input
            [disabled]="disabled ?? false"
            matInput
            type="text"
            [(ngModel)]="o.label"
            (keyup)="change()"
          />
        </mat-form-field>
      </div>
      <div class="col-3 pr-1">
        <mat-form-field class="no-subscript operator">
          <mat-select
            [disabled]="disabled ?? false"
            [(ngModel)]="o.operator"
            class="select"
            (selectionChange)="change()"
          >
            <mat-option *ngFor="let option of operators" [value]="option.value">
              {{ option.key }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-3 pr-1">
        <mat-form-field class="no-subscript">
          <input
            [disabled]="disabled ?? false"
            matInput
            type="text"
            [(ngModel)]="o.value"
            (keyup)="change()"
          />
        </mat-form-field>
      </div>
      <div class="col-1 small">
        <div *ngIf="!disabled" class="btnx" (click)="del(o)">
          <mat-icon color="warn">delete</mat-icon>
        </div>
      </div>
    </div>
  </div>
</fieldset>
