import { AfterViewInit, Component, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatSidenav } from "@angular/material/sidenav";
import { ActivatedRoute } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import { FormStatusEnum, FormType } from "src/app/common/enumerations/enumerations";
import { CancelDialogComponent } from "src/app/controls/cancel-dialog/cancel-dialog.component";
import { ActionItemsStoreAndListenersService } from "src/app/controls/catl/action-item/store/actionItems.store";
import { Procedure } from "src/app/components/procedure/models/procedure.model";
import { RadLocation } from "src/app/components/catalogs/rad-monitor/rad-monitor/rad-location";
import { RadMonitor } from "src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor";
import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { PrivilegeEnum } from "src/app/services/role-privilege/privilege-enum";
import { RSSFunctionalTestService } from "src/app/services/rss-functional-test/rss-functional-test.service";
import { User } from "src/app/components/catalogs/user-catalog/services/user";
import { WFSectionLocalResource, WFStepLocalResource, WfSignature } from "src/app/services/work-flow/work-flow";
import { FormStatusesStoreAndListenersService } from "src/app/store/form-status/form-status.store";
import { RSSFunctionalTest } from "../../rsswa/rss-functional-test";
import { ScheduleStatusEnum } from "../../schedules/models/enums";
import { Schedule } from "../../schedules/models/schedule";
import { SchedulesStoreAndListenersService } from "../../schedules/store/schedules/schedule.store";
import { ChecklistBuilderService } from "../checklist-builder/checklist-builder.service";
import { Checklist, TemplateType, ChecklistTemplate, TemplateRolePermission, ChecklistPriv, ChecklistTemplateStatusEnum, SaveChecklistStatusResource, Annotation, AnnotationStatus } from "../checklists";
import { ChecklistsService } from "../checklists.service";
import { ChecklistTemplatesRefreshByDocument } from "../store/checklist-templates/checklist-templates.action";
import { ChecklistsUpdate } from "../store/checklists/checklists.action";
import { TemplateTypesRefreshByDocument, TemplateTypesUpdate } from "../store/template-types/template-types.action";
import { ChecklistExecSideComponent } from "./checklist-exec-side/checklist-exec-side.component";
import { BaseComponent } from "src/app/common/base/base.component";
import { DocumentType } from "src/app/services/documents/documents";
import { RadMonitorService } from "../../catalogs/rad-monitor/services/rad-monitor.service";
import { PlaceholdersService } from "src/app/services/placeholders/placeholders.service";
import { Review } from "../../reviews/reviews.models";

@Component({
  selector: "checklist-exec",
  templateUrl: "./checklist-exec.component.html",
  styleUrls: ["./checklist-exec.component.scss"],
})
export class ChecklistExecComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  public radMonitorSubscription!: Subscription;
  public radLocSubscription!: Subscription;

  public radMonitors$!: Observable<RadMonitor[]>;
  public radLocations$!: Observable<RadLocation[]>;

  public radMonitors!: RadMonitor[];
  public radLocations!: RadLocation[];

  @ViewChild("sidenav") sidenav!: MatSidenav;

  @ViewChild(ChecklistExecSideComponent) public side!: ChecklistExecSideComponent;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) typeTrigger!: MatAutocompleteTrigger;

  @Output() toggleSidePanel = new EventEmitter<any>();

  documentTypeId!: number;
  documentType?: DocumentType;
  documentTypes!: DocumentType[];
  documentTypes$!: Observable<DocumentType[]>;
  checklistDocumentType?: DocumentType;

  resourceBased!: boolean;
  participants!: User[];

  currentChecklist?: Checklist | null;

  formStatus = FormStatusEnum;

  tmpType!: string;
  currentTemplateType?: TemplateType | null;
  templateTypes$!: Observable<TemplateType[]>;
  templateTypes!: TemplateType[];
  filteredTemplateTypes!: TemplateType[];
  loadingTypes!: boolean;
  typeCtrl = new FormControl();

  currentResource?: Resource | null;
  resources!: (Resource | undefined)[];

  currentSchedule?: Schedule | null;
  schedules!: Schedule[];
  schedules$!: Observable<Schedule[]>;
  filteredSchedules!: Schedule[];

  currentChecklistTemplate?: ChecklistTemplate | null;
  templateCtrl = new FormControl();

  loading: boolean = true;
  creating!: boolean;
  showLoader = true;

  sections!: WFSectionLocalResource[];
  allSections!: WFSectionLocalResource[];
  templateRolePermissions!: TemplateRolePermission[];
  documentRolePermissions!: TemplateRolePermission[];

  checklistPrivilege = ChecklistPriv;

  precheck!: boolean;

  filter?: string | null;
  subscription!: Subscription;
  templateTypesSubs!: Subscription;
  schedulesSubs!: Subscription;
  sectionsSubs!: Subscription;

  sections$!: Observable<WFSectionLocalResource[]>;

  checklists!: Checklist[];
  checklists$!: Observable<Checklist[]>;
  checklistsSubs!: Subscription;

  checklistTemplates!: ChecklistTemplate[];
  checklistTemplatesFiltered?: ChecklistTemplate[];
  checklistTemplates$!: Observable<ChecklistTemplate[]>;
  checklistTemplatesSubs!: Subscription;

  annotations!: Annotation[];
  annotations$!: Observable<Annotation[]>;
  annotationsSubs!: Subscription;
  totalAnnotations!: number;
  activeAnnotations!: number;

  reviews?: Review[];
  reviewsFiltered?: Review[];
  reviews$!: Observable<Review[]>;
  reviewsSubs!: Subscription;

  scheduleBased!: boolean;

  rssFunctionalTest!: RSSFunctionalTest | null;

  currentProcedure?: Procedure | null;
  procedures!: (Procedure | undefined)[];
  filteredProcedures!: Procedure[];
  procedureCtrl = new FormControl();

  sub!: Subscription;
  procedure!: Procedure;

  expandedAll = false;

  isCollapsible!: boolean;
  isProcedureRelated!: boolean;

  constructor(
    protected override injector: Injector,
    private route: ActivatedRoute,
    private builderService: ChecklistBuilderService,
    private service: ChecklistsService,
    private placeholdersService: PlaceholdersService,
    private rmService: RadMonitorService,
    private rssFunctionalTestService: RSSFunctionalTestService,
    actionItemsStore: ActionItemsStoreAndListenersService,
    scheduleStore: SchedulesStoreAndListenersService,
    formStatusStore: FormStatusesStoreAndListenersService,
  ) {
    super(injector);

    scheduleStore.initStore();
    scheduleStore.initListeners();

    formStatusStore.initListeners();
    formStatusStore.initStore();
    actionItemsStore.initStore(0);
    actionItemsStore.initListeners();
  }

  ngAfterViewInit(): void {
    this.setHeights();
  }


  override ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.templateTypesSubs?.unsubscribe();
    this.schedulesSubs?.unsubscribe();
    this.sectionsSubs?.unsubscribe();
    this.checklistsSubs?.unsubscribe();
    this.radMonitorSubscription?.unsubscribe();
    this.radLocSubscription?.unsubscribe();
    this.checklistTemplatesSubs?.unsubscribe();
    this.reviewsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit() {
    this.loadCurrentUser();
    this.placeholdersService.clearkeyValues();
    this.placeholdersService.getAll().toPromise();
    this.sub = this.route.params.subscribe((params) => {
      if (this.documentTypeId != +params["type"]) {
        this.ngOnDestroy();
        this.loadDocumentTypes();
        this.clear();
        this.clearSelection();
        this.documentTypeId = +params["type"].toString().split('?')[0];
        this.getInfo();

        this.loadTemplateTypes();
        this.loadSchedules(); //loads procedures
        this.loadChecklistTemplates();
        this.loadChecklists();
        this.loadReviews();
      }
      this.store.dispatch(
        new TemplateTypesRefreshByDocument(this.documentTypeId)
      );
      this.store.dispatch(
        new ChecklistTemplatesRefreshByDocument(this.documentTypeId)
      );
    });
  }

  loadChecklists() {
    this.checklists$ = this.store.select((state) => state.Checklists.data);
    this.checklistsSubs = this.checklists$.subscribe(async (data) => {
      if (data.length) {
        this.checklists = data.filter(x => x.checklistTemplate?.documentTypeID == this.documentTypeId);
        const checklistOpen = await this.checkOpenDocument<Checklist>(this.documentTypeId, this.checklists);
        if (checklistOpen) this.currentChecklist = checklistOpen.document;
        if (this.currentChecklist) {
          this.currentChecklist = this.checklists.find(x => x.id == this.currentChecklist?.id);
          if (this.currentChecklist)
            this.currentChecklist.createdByName = this.getUser(this.currentChecklist.createdBy)?.name;
          this.rowSelected(this.currentChecklist);
          this.loadAnnotations();
        }
      }
    });
  }

  loadDocumentTypes() {
    this.documentTypes$ = this.store.select((state) => state.DocumentType.data);
    this.subscription = this.documentTypes$.subscribe(async (data) => {
      if (data.length) {
        this.documentTypes = data;
        this.documentType = this.documentTypes.find(x => x.id == this.documentTypeId);
        this.checklistDocumentType = this.documentTypes.find(x => x.id == this.documentType?.type);
      }
    });
  }

  loadReviews() {
    this.reviews$ = this.store.select(state => state.Reviews.data);
    this.reviewsSubs = this.reviews$.subscribe(data => {
      if (data?.length) {
        this.reviews = data;
      }
    });
  }

  rowSelected(e: Checklist | null = null) {
    this.service.isReviewer = this.hasPrivilege(PrivilegeEnum.RadSurveyReviewer) && this.documentTypeId == 12;
    if (e?.wfTable) {
      this.setLoading({ val: true, l: 'rowSelected' });
      if (e.resourceID) {
        if (this.currentChecklist && e.id != this.currentChecklist?.id) {
          this.placeholdersService.clearkeyValues();
          this.currentChecklist = null;
        }
        if (!this.placeholdersService.keyValuePairs.length)
          this.placeholdersService.setKeyValuePairs(e.resource, false).then(() => {
            if (this.placeholdersService.keyValuePairs.length) {
              this.getChecklist(e);
            }
          });
        else this.getChecklist(e);
      }
      else {
        const values = this.rmService.findRadMonitor(e.wfTable.wfTableLocal?.wfSectionLocals as WFSectionLocalResource[], 'OUT');
        const monitor = values?.find((r: any) => r.key == 'monitor')?.val as RadMonitor;
        const location = values?.find((r: any) => r.key == 'location')?.val as RadMonitor;
        this.placeholdersService.setKeyValuePairs(monitor).then(() => {
          this.getChecklist(e);
        });
      }
    }
  }

  getChecklist(checklist: Checklist) {
    this.currentChecklist = checklist;
    this.currentChecklist = this.checklists?.find(x => x.id == this.currentChecklist?.id);
    if (this.currentChecklist) {
      this.currentChecklist.createdByName = this.getUser(this.currentChecklist.createdBy)?.name;
      this.loadInfo();
    }
    this.setLoading({ val: false, l: 'rowSelected' });
  }

  loadProcedures() {
    const hutchFunctionalProcedures = this.schedules.filter(x => x.type.documentTypeId == this.documentType?.type && x.scheduleSubtypeId == 2 && x.subdetail?.procedure).map((x) => x.subdetail.procedure);

    this.procedures = this.schedules.filter(x => x.type.documentTypeId == this.documentType?.type && x.procedure).map(x => x.procedure);
    this.procedures = this.procedures.concat(hutchFunctionalProcedures);

    this.procedures.map(p => {
      const procedure = this.checklistTemplates?.filter((t) => t.status == 1).find((x) => x.procedureMasterID == p?.procedureMasterID)?.procedure;
      if (procedure) { p = procedure; }
    });
    this.filterProcedures();
    this.isProcedureRelated = this.proceduresVisible();
    this.procedureCtrl.enable();
  }

  filterProcedures() {
    if (this.procedures?.length) {
      const procedures: Procedure[] = [];
      this.procedures?.map(p => {
        const procedure = this.checklistTemplates?.filter((t) => t.status == 1).find((x) => x.procedureMasterID == p?.procedureMasterID)?.procedure;
        if (procedure) procedures.push(procedure);
      });
      procedures.map(p => {
        const index = this.procedures.findIndex(x => x?.procedureMasterID == p.procedureMasterID);
        this.procedures.splice(index, 1);
        this.procedures.push(p);
      });
      this.filteredProcedures = this.procedures.sort((a, b) => this.utils.SortProcedures(a?.procedureNumber, b?.procedureNumber)) as Procedure[];
    }
  }

  loadChecklistTemplates() {
    this.checklistTemplates$ = this.store.select((state) => state.ChecklistTemplates.data);
    this.checklistTemplatesSubs = this.checklistTemplates$.subscribe((data) => {
      if (data.length) {
        this.checklistTemplates = data.filter((t) => t.status == 1);
        this.checklistTemplatesFiltered = this.checklistTemplates.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.resourceString, b.resourceString));
        this.filterProcedures();
      }
    });
  }

  // //#region TemplateTypestemplateCtrl
  loadTemplateTypes() {
    this.setLoading({ val: true, l: 'loadTemplateTypes' });
    this.templateTypes$ = this.store.select((state) => state.TemplateTypes.data);
    this.templateTypesSubs = this.templateTypes$.subscribe(data => {
      if (data.length && this.documentRolePermissions) {
        this.templateTypes = data?.filter(x => x.status == 1 && x.documentTypeID == this.documentType?.id && this.documentRolePermissions?.filter(p => p.checklistPrivilege == this.checklistPrivilege.CreateChecklist &&
          this.hasRoles([p.roleID]))).sort((a, b) => this.utils.sort(a.order, b.order));
        this.filteredTemplateTypes = this.templateTypes;
        if (this.templateTypes?.length) {
          this.checklistTemplates = this.checklistTemplates.filter((x) => x.status == 1);
          this.checklistTemplatesFiltered = this.checklistTemplates.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.resourceString, b.resourceString));
          if (this.currentChecklist) {
            this.currentChecklistTemplate = this.currentChecklist.checklistTemplate;
            this.currentTemplateType = this.currentChecklist.checklistTemplate?.templateType;
            this.filterSchedules();
            this.resourceBased = (this.currentTemplateType?.resourceTypeID ?? 0) > 0;
            this.scheduleBased = (this.currentTemplateType?.scheduleTypeID ?? 0) > 0;
            this.currentSchedule = this.filteredSchedules?.find((x) => x.scheduleResources[0].resourceId ==
              this.currentChecklist?.resourceID);
            if (this.currentChecklistTemplate) {
              this.currentChecklistTemplate.resource = this.currentChecklist.resource;
              if (
                !this.checklistTemplates.map((x) => x.id).includes(this.currentChecklistTemplate.id)) {
                this.checklistTemplates.push(this.currentChecklistTemplate);
              }
              this.templateCtrl.setValue(this.currentChecklistTemplate);
              this.templateCtrl.disable();
              this.currentResource = this.currentChecklist.resource;
            }
          }
          this.setLoading({ val: false, l: 'loadTemplateTypes' });
        } else {
          this.setLoading({ val: data.length == 0, l: 'loadTemplateTypes' });
        }
      }
    });
  }

  getTemplate(procedure: Procedure) {
    const checklistTemplate = this.checklistTemplates.filter(x => x.procedure).find(x => (x.procedureID == procedure.id ||
      x.procedureID == procedure.previousVersionID) && x.status == ChecklistTemplateStatusEnum.Active);
    return checklistTemplate;
  }

  hasTemplate(procedure: Procedure) {
    return this.getTemplate(procedure) != null;
  }

  selectedType(e: any) {
    this.currentTemplateType = e.option.value as TemplateType;
    this.currentProcedure = null;
    this.procedureCtrl.setValue(null);
    this.templateCtrl.setValue(null);
    this.currentSchedule = null;
    this.currentChecklistTemplate = null;
    this.typeCtrl.setValue(this.currentTemplateType);
    this.filterSchedules();
    this.selectedTemplateType();
  }

  selectedTemplateType() {
    if (this.currentTemplateType?.checklistTemplates) {
      this.checklistTemplates = this.currentTemplateType.checklistTemplates.filter(x => x.status == 1);
      this.checklistTemplatesFiltered = this.checklistTemplates.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.resourceString, b.resourceString));
      if (!this.checklistTemplates.length) {
        this.alert.message("GenChklistExec_NoTemplatesAvailable");
        this.clearSelection();
        return;
      }
      this.templateCtrl.enable();
      this.resourceBased = (this.currentTemplateType.resourceTypeID ?? 0) > 0;
      this.scheduleBased = (this.currentTemplateType.scheduleTypeID ?? 0) > 0;
      if (this.scheduleBased) {
        this.filterSchedules();
      } else if (this.resourceBased) {
        this.resources = this.checklistTemplates.map(x => x.resource).sort((a, b) => this.utils.SortBeamlines(a?.name, b?.name));
      } else if (this.currentTemplateType.allowMultipleVersions) {
      } else if (!this.scheduleBased && !this.resourceBased) {
        this.currentChecklistTemplate = this.currentTemplateType.checklistTemplates.find(x => x.status == ChecklistTemplateStatusEnum.Active);
        if (!this.currentChecklistTemplate) {
          this.alert.message("GenChklistExec_NoTemplatesAvailable");
          this.clearSelection();
        }
      }
    } else {
      if (this.currentTemplateType?.id) {
        this.setLoading({ val: true, l: 'getTemplateType' });
        this.builderService
          .getTemplateType(this.currentTemplateType.id)
          .toPromise()
          .then((data) => {
            if (data) {
              this.currentTemplateType = data;
              this.store.dispatch(new TemplateTypesUpdate(data.id, data));
              this.selectedTemplateType();
              this.setLoading({ val: false, l: 'getTemplateType' });
            }
          });
      }
    }
  }

  selectedTemplateName(e: any) {
    this.currentChecklistTemplate = e.option.value;
  }

  selectedProcedure(e: any) {
    this.currentProcedure = e.option.value as Procedure;
    this.checklistTemplates = this.checklistTemplates.filter(x => x.status == 1);
    this.checklistTemplatesFiltered = this.checklistTemplates.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.resourceString, b.resourceString));

    if (!this.hasTemplate(this.currentProcedure)) {
      this.alert.message("GenChklistExec_NoTemplatesAvailable");
      this.clearSelection();
      return;
    }

    this.procedureCtrl.setValue(this.currentProcedure);
    this.currentChecklistTemplate = this.getTemplate(this.currentProcedure);
    if (this.currentChecklistTemplate) {
      this.currentChecklistTemplate.templateType = this.templateTypes.find(x => x.id == this.currentChecklistTemplate?.templateTypeID
      );
      this.currentTemplateType = this.currentChecklistTemplate.templateType;
      this.templateCtrl.setValue(this.currentChecklistTemplate);
      this.typeCtrl.setValue(this.currentTemplateType);
      this.scheduleBased = true;
      this.filterSchedules();
      this.selectedTemplate(this.currentChecklistTemplate);
    }
  }

  changedProcedure() {
    this.filteredProcedures = this.procedures.filter(x => x?.procedureNumber.toLowerCase().includes(this.procedureCtrl.value.toLowerCase())) as Procedure[];
  }

  clearSelection() {
    this.typeCtrl.setValue(null);
    this.currentTemplateType = null;
    this.procedureCtrl.setValue(null);
    this.currentProcedure = null;
    this.templateCtrl.setValue(null);
    this.currentChecklistTemplate = null;
    this.templateCtrl.disable();
    this.loadChecklistTemplates();
    this.loadSchedules();
  }

  checkStatus() {
    const activeStatusOrder = this.currentTemplateType?.documentType?.checklistExecStatuses?.find(x => x.formStatusID == FormStatusEnum.Active)?.order;
    return (this.currentChecklist?.checklistStatus?.order ?? 0) <= (activeStatusOrder ?? 0);
  }

  // //#region Schedules
  loadSchedules() {
    this.setLoading({ val: true, l: 'loadSchedules' });
    this.schedules$ = this.store.select((state) => state.Schedules.data);
    this.schedulesSubs = this.schedules$.subscribe((data) => {
      if (data.length) {
        this.schedules = data.filter(x => x.statusId != ScheduleStatusEnum.remove && x.statusId != ScheduleStatusEnum.completed).sort((a, b) => this.utils.SortBeamlines(a.name, b.name));
        this.loadProcedures();
        this.setLoading({ val: false, l: 'loadSchedules' });
      }
    });
  }

  filterSchedules() {
    if (this.currentTemplateType && this.schedules?.length) {
      this.filteredSchedules = this.schedules.filter(x => x.typeId == this.currentTemplateType?.scheduleTypeID &&
        (this.currentTemplateType?.scheduleSubtype ? x.scheduleSubtypeId == this.currentTemplateType.scheduleSubtypeID : true)).filter(x => this.checklistTemplates?.map((t) => t.resourceID).includes(x.scheduleResources[0].resourceId));

      this.filteredSchedules.map((schedule) => {
        const resource = schedule.scheduleResources[0]?.resource;
        schedule.name = resource?.name;
        schedule.description = resource?.description;
      });
    }
  }

  filterTemplates(e: any) {
    const val = (e.target.value as string).toLowerCase();
    this.checklistTemplatesFiltered = this.checklistTemplates.filter(x => x.resourceString?.toLowerCase().includes(val));
  }

  getSchedule(id: number) {
    if (id) {
      this.currentSchedule = this.schedules?.find((x) => x.id == id);
    } else {
      this.currentSchedule = null;
    }
    return this.currentSchedule;
  }
  // //#endregion

  getInfo() {
    this.clear();
    this.loadDocumentTypes();
    this.builderService.getTemplateRolePermissionsByDocumentTypeID(this.documentTypeId).toPromise().then((data) => {
      if (data)
        this.documentRolePermissions = data.filter(x => x.templateTypeID == 0);
      this.loadTemplateTypes();
    });
  }

  clear() {
    this.currentChecklist = null;
    this.currentSchedule = null;
    this.currentResource = null;
    this.currentTemplateType = null;
    this.procedureCtrl.setValue(null);
    this.currentProcedure = null;
    this.typeCtrl.setValue(this.currentTemplateType);
    this.scheduleBased = false;
    this.resourceBased = false;
    this.checklistTemplates = [];
    this.checklistTemplatesFiltered = this.checklistTemplates;
    this.templateCtrl.setValue(null);
    this.currentChecklistTemplate = null;
    this.sections = [];
    this.creating = false;
    // this.checklistSections?.loadSections();
  }

  displayProcedure(e: any) {
    return e ? e.procedureNumber : null;
  }

  displayTemplate(e: any) {
    return e ? (e.resource ? e.resource.name : e.resourceString) : null;
  }

  selectedTemplate(e: ChecklistTemplate) {
    this.currentChecklistTemplate = e;
    this.currentResource = this.currentChecklistTemplate.resource;
    this.currentSchedule = this.filteredSchedules.find(x => this.currentResource ? x.scheduleResources[0]?.resourceId == this.currentResource.id : x.scheduleResources[0].resourceString == this.currentChecklistTemplate?.resourceString);

    this.currentProcedure = this.procedures.find(x => x?.id == this.currentChecklistTemplate?.procedureID ||
      x?.previousVersionID == this.currentChecklistTemplate?.procedureID);
    this.procedureCtrl.setValue(this.currentProcedure);
  }

  createNew() {
    this.creating = true;
    this.currentChecklist = null;
    if (this.scheduleBased) {
      this.loadSchedules();
    } else {
      this.loadTemplateTypes();
    }
    this.side.unselect();
    this.typeCtrl.enable();
    this.typeCtrl.setValue(null);
    this.procedureCtrl.enable();
    this.procedureCtrl.setValue(null);
    // this.checklistSections.loadSections();
  }

  create() {
    if (!this.checklists.filter(x => x.checklistStatus?.formStatusID != FormStatusEnum.Canceled && x.checklistStatus?.formStatusID != FormStatusEnum.Closed).some(x => (x.scheduleId && x.scheduleId == this.currentSchedule?.id && x.checklistTemplate?.templateTypeID == this.currentTemplateType?.id))) {
      this.setLoading({ val: true, l: 'create' });
      const checklist: Checklist = {
        id: 0,
        resourceID: this.currentResource?.id,
        scheduleId: this.currentSchedule?.id,
        checklistTemplateID: this.currentChecklistTemplate?.id,
        status: FormStatusEnum.Draft,
      };
      this.service
        .post(checklist)
        .toPromise()
        .then((data) => {
          this.currentChecklist = data;
          this.creating = false;
          this.setOpenDocument(this.documentTypeId, this.currentChecklist?.id);
          this.side.selectCreatedRow(data);
          this.setLoading({ val: false, l: 'create' });
        });
    } else {
      this.alert.message("ChecklistAlreadyOpen");
    }
  }

  async loadInfo() {
    this.setLoading({ val: true, l: 'loadInfo' });
    this.loadSchedules();
    this.loadTemplateTypes();
    this.currentProcedure = null;
    if (this.currentChecklist) {
      this.rssFunctionalTestService.readByChecklistID(this.currentChecklist.id).subscribe(
        (data) => (this.rssFunctionalTest = data), () => (this.rssFunctionalTest = null));

      if (!this.currentChecklist?.wfTable) {
        this.currentChecklist = this.checklists.find(c => c.id == this.currentChecklist?.id);
        if (!this.currentChecklist?.wfTable && this.currentChecklist?.id)
          this.currentChecklist = await this.service.getById(this.currentChecklist.id).toPromise();
      }

      if (this.currentChecklist) {
        if (this.currentChecklist.scheduleId)
          this.currentChecklist.schedule = this.getSchedule(this.currentChecklist.scheduleId);
        this.sections = [];
        this.creating = false;
        this.currentProcedure = this.filteredProcedures?.find(x => x.id == this.currentChecklist?.schedule?.procedureID ||
          (x.procedureMasterID == this.currentChecklist?.schedule?.procedureMasterID && x.status == 1));
        this.isProcedureRelated = this.proceduresVisible();
        this.procedureCtrl.setValue(this.currentProcedure);
        this.procedureCtrl.disable();
      }
    }
    else {
      this.currentTemplateType = null;
      this.currentChecklistTemplate = null;

      this.typeCtrl.setValue(null);
      this.typeCtrl.enable();

      this.currentProcedure = null;
      this.procedureCtrl.setValue(null);
      this.currentResource = null;
      this.resourceBased = false;
      this.currentChecklistTemplate = null;
      this.templateCtrl.setValue(null);

      this.sections = [];
    }
    this.service.isPreparer = this.checkPriv(
      this.checklistPrivilege.CreateChecklist
    );
    if (this.currentChecklist?.documentTypeID)
      this.setOpenDocument(this.currentChecklist.documentTypeID, this.currentChecklist?.id)
    this.getSections();
    this.getReview();
    this.setLoading({ val: !this.currentChecklist?.wfTable, l: 'loadInfo' });
  }

  getSections() {
    this.currentChecklistTemplate = this.currentChecklist?.checklistTemplate;

    this.typeCtrl.setValue(this.currentChecklistTemplate?.templateType);
    this.typeCtrl.disable();
    this.procedureCtrl.disable();

    this.resourceBased = (this.currentChecklistTemplate?.templateType?.resourceTypeID ?? 0) > 0;
    this.scheduleBased = (this.currentChecklistTemplate?.templateType?.scheduleTypeID ?? 0) > 0;

    this.currentResource = this.currentChecklist?.resource;
    this.currentSchedule = this.filteredSchedules?.find(x => x.id == this.currentChecklist?.scheduleId);

    if (this.currentSchedule) {
      this.currentResource = this.currentSchedule.scheduleResources.find(x => (x.resourceId ?? 0) > 0)?.resource;
      if (this.currentChecklist)
        this.currentChecklist.schedule = this.currentSchedule;
    }

    this.sections = (this.currentChecklist?.wfTable ? this.currentChecklist.wfTable.wfTableLocal?.wfSectionLocals : []) as WFSectionLocalResource[];
    this.getParticipants(this.sections);
    this.isCollapsible = this.collapsible();
    this.loadAnnotations();
    this.setHeights();
  }

  getReview() {
    const review = this.reviews?.find(r => r.checklistID == this.currentChecklist?.id);
    if (review && this.currentChecklist) this.currentChecklist.review = review;
  }

  checkDisabled(s: WfSignature): boolean {
    const statusId = this.utils.JSONparse(s.configuration)?.status;
    const statuses = this.currentChecklistTemplate?.documentType?.checklistExecStatuses;
    const status = statuses?.find(x => x.id == statusId);

    return this.activeAnnotations > 0 && status == statuses?.find(x => x.formStatusID == FormStatusEnum.PendingReview && this.currentChecklist?.checklistStatus?.formStatusID == FormStatusEnum.Correcting);
  }

  loadAnnotations() {
    this.annotations$ = this.store.select(state => state.Annotations.data);
    this.annotationsSubs = this.annotations$.subscribe(data => {
      if (this.currentChecklist) {
        this.annotations = data.filter(x => x.checklistID == this.currentChecklist?.id);
        this.activeAnnotations = this.annotations?.filter(x => x.status == AnnotationStatus.Active).length;
        this.totalAnnotations = this.annotations?.filter(x => x.checklistID == this.currentChecklist?.id).length;
      }
    });
  }

  getParticipants(sections: WFSectionLocalResource[]) {
    const participantSections = sections?.filter(z => z.wfStepLocals?.some(s => s.wfTaskLocals.some((t) => t.code == "PARTICIPANT")));
    if (participantSections?.length) {
      this.participants = [];
    }
    participantSections?.map(z => {
      z.wfStepLocals?.map(s => {
        s.wfTaskLocals.map(t => {
          const taskSigned = t.wfTasks?.[0].signedBy;
          if (t.code == "PARTICIPANT" && taskSigned) {
            this.participants.push(taskSigned as User);
          }
        });
      });
    });
    this.service.participants = this.participants;
  }

  applyFilter(e: any) {
    this.side.applyFilter(e);
  }

  noCreate() {
    this.creating = false;
    this.scheduleBased = false;
    this.resourceBased = false;
    this.isProcedureRelated = false;
    this.currentProcedure = null;
    this.currentChecklistTemplate = null;
    this.side.unselect();
  }

  checkPriv(privilege: ChecklistPriv) {
    if (privilege == ChecklistPriv.Review) {
      return this.service.isReviewer;
    }
    return this.hasRoles(this.documentRolePermissions?.filter(x => x.checklistPrivilege == privilege).map((x) => x.roleID));
  }

  expandCollapse(x: boolean) {
    if (!x)
      this.alert.warning('Expanding all sections may cause slow performance issues on large checklists.');
    this.sections?.map((section) => {
      section.collapsed = x;
      section.wfStepLocals?.map((step) => { step.collapsed = x });
    });
    this.expandedAll = !this.expandedAll;
    if (this.currentChecklist)
      this.service.saveExpansionStatus(this.currentChecklist, this.expandedAll);
  }

  cancel() {
    const canceledStatus = this.currentChecklist?.checklistTemplate?.documentType?.checklistExecStatuses?.find(
      (x) => x.formStatusID == FormStatusEnum.Canceled
    );
    if (canceledStatus) {
      const cancel = this.dialog.open(CancelDialogComponent, {
        width: "600px",
        data: {},
      });
      cancel.afterClosed().subscribe((data) => {
        if (data.accept) {
          this.setLoading({ val: true, l: 'cancel' });

          const checklistStatusResource: SaveChecklistStatusResource = {
            id: this.currentChecklist?.id ?? 0,
            checklistStatusID: canceledStatus.id,
            status: canceledStatus.id,
            reason: data.text,
          };

          this.service.cancel(checklistStatusResource).toPromise().then((res) => {
            this.alert.message("CancelSuccess", [{ placeholder: "{reason}", value: data.text },]);
            this.creating = false;

            if (res) {
              this.currentChecklist = res;
              this.side.selectCanceledRow(this.currentChecklist);
            } else {
              this.sections = [];
              this.currentChecklist = null;
              this.side.unselect();
              // this.checklistSections.loadSections();
            }
          })
            .catch((error) => {
              this.alert.defaultError();
              console.log(error);
              this.creating = false;
            }).finally(() => this.setLoading({ val: false, l: 'cancel' }));
        }
      });
    }
  }

  openVerificationList() {
    if (this.currentChecklist?.id) {
      this.setLoading({ val: true, l: 996 });
      this.service.getVerifiedById(this.currentChecklist.id).toPromise().then((response) => {
        if (response) {
          this.currentChecklist = response;
          this.store.dispatch(
            new ChecklistsUpdate(this.currentChecklist.id, this.currentChecklist)
          );
          this.setLoading({ val: false, l: 996 });
        }
      });
    }
  }


  precheckChanged(e: any) {
    this.precheck = e;
  }

  proceduresVisible(): boolean {
    const result = this.procedures?.length > 0 && (this.currentSchedule?.type?.procedureTypeID || !this.currentTemplateType) && (this.creating || (this.currentChecklist != null && this.currentProcedure != null));
    return result as boolean;
  }

  collapsible() {
    if (this.currentChecklist) {
      const sections = this.currentChecklist.wfTable?.wfTableLocal?.wfSectionLocals;
      if (sections) {
        const steps = sections.map(s => s.wfStepLocals as WFStepLocalResource[]).reduce((acc, arr) => [...acc, ...arr], []);

        return (
          sections?.filter((x) => x.collapsible)?.length > 0 ||
          steps?.filter((x) => x.collapsible)?.length > 0
        );
      }
    }
    return false;
  }

  loadings: LoadingValues[] = [];

  setLoading(e: any, o: string = 'this') {
    const value = { val: false, l: 0 };
    if (typeof e == "boolean") {
      value.val = e;
    }
    else value.val = e.val;
    const origin = o == 'this' ? e.l : o;
    const i = this.loadings.findIndex(x => x.origin == origin) ?? -1;
    if (i >= 0)
      this.loadings[i].value = value.val;
    else this.loadings.push({ origin, value: value.val });

    setTimeout(() => {
      this.loading = this.loadings.some(x => x.value);
      const currentDate = new Date();

      // Extract individual components
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      const seconds = currentDate.getSeconds();
      const milliseconds = currentDate.getMilliseconds();

      console.log(`${hours}:${minutes}:${seconds}.${milliseconds}` + '    val: ' + value.val + '   orig: ' + o + '   ln: ' + e.l);
    }, e ? 100 : 1500);
  }

  openReview() {
    if (this.currentChecklist?.review?.reviewCycle) {
      const document = this.redirect.findDocument(this.currentChecklist.review.reviewCycleID, FormType.REVIEW);
      localStorage.setItem('redirectID', this.currentChecklist.review.id.toString());
      if (document)
        this.redirect.openDocument(document);
    }
  }
}

interface LoadingValues {
  origin: string;
  value: boolean;
}
