<div class="card">
  <div class="card-header">
    <span class="card-title">Bakeout <span class="small">ARCHIVE</span></span>
  </div>
  <div class="card-body">
    <!-- Serach fields -->
    <div class="row">
      <div class="col col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <mat-form-field appearance="outline">
          <mat-label>Search</mat-label>
          <input (keyup)="filterHistory()" matInput [(ngModel)]="Filter" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="col col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <mat-form-field appearance="outline">
          <mat-label>Month</mat-label>
          <input
            name="Month"
            (keyup)="filterHistory()"
            maxlength="2"
            (keypress)="numberOnly($event)"
            type="text"
            matInput
            [(ngModel)]="Month"
            onDrag="return false"
            onDrop="return false"
            onPaste="return false"
          />
          <mat-hint>MM</mat-hint>
        </mat-form-field>
      </div>
      <div class="col col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <mat-form-field appearance="outline">
          <mat-label>Year</mat-label>
          <input
            name="Year"
            (keyup)="filterHistory()"
            maxlength="4"
            (keypress)="numberOnly($event)"
            type="text"
            matInput
            [(ngModel)]="Year"
            onDrag="return false"
            onDrop="return false"
            onPaste="return false"
          />
          <mat-hint>YYYY</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <!-- Filtered list -->
    <div class="small messages-container scrollbar pr-1">
      <div *ngFor="let item of filtered">
        <div class="alert alert-light border-dark mb-1 p-1">
          <button
            class="close focus-outline-none"
            *ngIf="getCurrentUser() != null"
            (click)="copy(item)"
            title="Copy"
          >
            <i class="material-icons">file_copy</i>
          </button>
          <div class="row no-gutters">
            <div class="col">Username: {{ item.createdByUser }}</div>
            <div class="col">Phone number: {{ item.name }}</div>
            <div class="col">Location: {{ item.description }}</div>
          </div>
          <div class="row no-gutters">
            <div class="col">
              Estimated: {{ item.estimatedStart | date : "yyyy-MM-dd hh:mm" }} -
              {{ item.estimatedEnd | date : "yyyy-MM-dd hh:mm" }}
            </div>
            <div class="col">
              Temperature: {{ item.temperature }} &deg;{{
                item.temperatureUnit
              }}
            </div>
          </div>
          <div class="row no-gutters">
            <div
              class="col"
              *ngIf="
                item.temperatureNotes != null &&
                item.temperatureNotes.length > 0
              "
            >
              How is temperature controlled: {{ item.temperatureNotes }}
            </div>
            <div
              class="col"
              *ngIf="
                item.additionalNotes != null && item.additionalNotes.length > 0
              "
            >
              Additional Note: {{ item.additionalNotes }}
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col small text-right">
              <span
                >Created by {{ item.createdByUser }} on
                {{ item.createdOn | date : "yyyy-MM-dd" }}.</span
              >
              <span *ngIf="item.updatedByUser != null">
                Last edited by {{ item.updatedByUser }} on
                {{ item.updatedOn | date : "yyyy-MM-dd" }}.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
