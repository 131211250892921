import { Component, ViewChild, Input, OnDestroy, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, Injector } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { FormType, FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { Comment, CommentResource } from 'src/app/controls/comments-and-attachments/services/comment';
import { CommentService } from 'src/app/controls/comments-and-attachments/services/comments.service';
import { FileV2Service } from 'src/app/services/file/file-v2.service';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';
import { MatFileUploadService } from 'src/app/modules/file-upload/file-upload-module';
import { MatFileV2UploadComponent } from 'src/app/modules/file-upload/lib/mat-file-v2-upload/mat-file-v2-upload.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { CommentRefreshByID } from './store/comments.action';

@Component({
  selector: 'comments-and-attachments',
  templateUrl: './comments-and-attachments.component.html',
  styleUrls: ['./comments-and-attachments.component.scss']
})
export class CommentsAndAttachmentsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() currentDocument?: any = null;
  @Input() FormNumber?: string;
  @Input() SerialNo?: string;
  @Input() Title = 'Comments and Attachments';
  @Input() ShowFiles = true;
  @Input() disabled?: boolean;
  @Input() headerClass = 'h3';

  @Output() changed = new EventEmitter<any>();

  formTypes = FormType;

  comment?: string | null;

  comments?: CommentResource[];
  commentsFiltered?: CommentResource[];
  comments$!: Observable<CommentResource[]>;
  commentsSubs!: Subscription;

  loading = false;
  loadingFile = false;
  save_files_database = '';
  commentFiles?: any[];
  typeDocument = '';
  formType!: string;

  @ViewChild(MatFileV2UploadComponent) private matFilev2Upload!: MatFileV2UploadComponent;
  serialNo?: string;

  AttachmentsDisabled?: boolean;
  // commentCtrl = new FormControl();
  // uploadCtrl = new FormControl();
  // addBtnCtrl = new FormControl();
  constructor(
    private commentServ: CommentService,
    private file2Service: FileV2Service,
    private uploadServ: MatFileUploadService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    // this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loading = false;

    if (this.serialNo != this.currentDocument?.serialNo) {
      this.serialNo = this.currentDocument?.serialNo;

      this.refresh();
    }
    if (!this.serialNo) this.serialNo = this.SerialNo;
    this.formType = this.currentDocument?.documentType?.code ?? this.serialNo?.split('-')[0];
  }

  loadComments() {
    setTimeout(() => {
      this.loading = true;

      this.comments = [];
      this.comments$ = this.store.select(state => state.Comments.data);
      this.commentsSubs = this.comments$.subscribe(data => {
        if (data?.length) {
          this.comments = data.filter(x => x.serialNo == this.serialNo).sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());
          this.loading = false;
        }
      });
    }, 100);
  }

  override ngOnDestroy(): void {
    this.commentsSubs?.unsubscribe();
  }

  initForm() {
    // this.buildForm();
  }

  refresh() {
    setTimeout(() => {
      this.loading = true;
      this.AttachmentsDisabled = !this.currentDocument || this.currentDocument.status === FormStatusEnum.Closed;
      this.serialNo = this.currentDocument?.serialNo ? this.currentDocument?.serialNo : this.SerialNo;
      if (this.matFilev2Upload) {
        this.matFilev2Upload.uploadId = this.serialNo ?? '-';
      }
      this.loadComments();
    }, 100);
  }

  clear() {
    this.comments = [];
    this.comment = null;
  }

  create() {
    this.currentDocument = null;
    this.clear();
  }

  addComment() {
    this.loading = true;
    const newComment: Comment = {
      serialNo: this.serialNo,
      commentText: this.comment,
    };
    this.comment = '';
    this.commentServ.Create(newComment).toPromise().then(async res => {
      if (this.matFilev2Upload.selectedFiles?.length && res?.id) {
        this.loading = true;
        await this.uploadAttachments(res.id);
      }
      this.alert.success(this.getMessage('CommentAdded').description);
      this.changed.emit(true);
      this.refresh();
      this.loading = false;
    },
      error => {
        this.alert.error(error);
      });
  }

  uploadAttachments(id: number) {
    return new Promise((resolve, reject) => {
      this.matFilev2Upload
        .uploadFiles(id)
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          console.log(error);
          reject(false);
        })
        .finally(() => {
          this.loading = false;
          this.store.dispatch(new CommentRefreshByID(id));
        });
    });
  }

  commentChanged(e: any) {
    this.comment = e;
  }

  DeleteComment(c: CommentResource) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('Delete_Comments_Confirmation').description,
        icon: 'stop'
      }
    });

    confirm.afterClosed().toPromise().then(data => {
      if (data && c.id) {
        this.loading = true;
        this.commentServ.Delete(c.id).toPromise().then((res) => {
          this.alert.success(this.getMessage('CommentDeleted').description);
          this.changed.emit(true);
        },
          (error) => {
            console.error(error);
          });
      }
    },
      (error) => console.error(error));
  }

  deleteCommentFile(idFileDelete: string) {
    this.uploadServ.deleteFile(idFileDelete).subscribe(
      () => console.log(idFileDelete.toString()),
      (error) => console.error(error));
  }

  deleteCommentFileServer(idFileDelete: number) {
    this.file2Service.delete(idFileDelete).subscribe(
      () => console.log(idFileDelete.toString()),
      (error) => console.error(error));
  }

  getFloatLabel(): string {
    return 'auto';
  }

}
