import { Component, EventEmitter, OnInit, Output, OnDestroy, Injector, OnChanges, SimpleChanges, Input } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RadMetersTypesComponent } from 'src/app/components/catalogs/meters-catalog/rad-meters-types/rad-meters-types.component';
import { RadMetersUnitsComponent } from 'src/app/components/catalogs/meters-catalog/rad-meters-units/rad-meters-units.component';
import { RadMeter, RadMeterData } from 'src/app/components/catalogs/meters-catalog/rad-meters';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Observable, Subscription } from 'rxjs';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { RadMeterModelsComponent } from '../rad-meter-models/rad-meter-models.component';
import { RadMetersDetailsComponent } from '../rad-meters-details/rad-meters-details.component';
import { RadMetersService } from '../services/rad-meters.service';

@Component({
  selector: 'rad-meters',
  templateUrl: './rad-meters.component.html',
  styleUrls: ['./rad-meters.component.scss']
})
export class RadMetersComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() filter?: string;
  @Output() loading = new EventEmitter<boolean>();

  @Output() changed = new EventEmitter<string>();
  @Output() save = new EventEmitter<any>();

  public dataSource: MatTableDataSource<RadMeter> = new MatTableDataSource<RadMeter>();

  selectedRowIndex!: number;
  public displayedColumns: string[] = ['serialNumber', 'calibrationDate', 'model', 'type', 'unit'];

  radMeters?: RadMeter[];
  radMetersFiltered?: RadMeter[];
  radMeters$!: Observable<RadMeter[]>;
  radMetersSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private _service: RadMetersService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter'])
      this.applyFilter();
  }

  ngOnInit(): void {
    this.loadRadMeters();
  }

  ngAfterViewInit(): void {

  }

  loadRadMeters() {
    this.loading.emit(true);
    this.radMeters$ = this.store.select(state => state.RadMeters.data);
    this.radMetersSubs = this.radMeters$.subscribe(data => {
      if (data?.length) {
        this.displayedColumns = this.isSA() ? ['serialNumber', 'calibrationDate', 'model', 'type', 'unit', 'options'] : ['serialNumber', 'calibrationDate', 'model', 'type', 'unit'];
        this.loading.emit(false);
        this.radMeters = data;
        this.radMetersFiltered = data.sort((a, b) => this.utils.compare(a.serialNumber, b.serialNumber, true));
        this.dataSource = new MatTableDataSource(this.radMetersFiltered);
      }
    });
  }

  applyFilter() {
    const filterValue = this.filter?.trim().toLowerCase();
    if (filterValue)
      this.radMetersFiltered = this.radMeters?.filter(radMeter =>
        radMeter.serialNumber?.trim()?.toLowerCase()?.includes(filterValue) ||
        radMeter.sourceReading?.trim()?.toLowerCase()?.includes(filterValue) ||
        radMeter.radMeterType?.name?.trim()?.toLowerCase()?.includes(filterValue) ||
        radMeter.radMeterUnit?.name?.trim()?.toLowerCase()?.includes(filterValue) ||
        radMeter.radMeterModel?.name?.trim()?.toLowerCase()?.includes(filterValue) ||
        radMeter.backgroundReading?.trim()?.toLowerCase()?.includes(filterValue) ||
        radMeter.calibrationDueDate?.toLocaleString()?.trim()?.toLowerCase()?.includes(filterValue)
      ).sort((a, b) => this.utils.compare(a.serialNumber, b.serialNumber, true));
    else this.radMetersFiltered = this.radMeters?.sort((a, b) => this.utils.compare(a.serialNumber, b.serialNumber, true));
    this.dataSource = new MatTableDataSource(this.radMetersFiltered);
  }

  addMeterCatalog() {
    const NewRadMeter = {
      serialNumber: '',
      calibrationDueDate: undefined,
      sourceReading: '',
      backgroundReading: '',
      id: 0
    } as RadMeter;

    const dialogConfig = new MatDialogConfig();
    const radMeterData = new RadMeterData();
    radMeterData.radMeter = NewRadMeter;

    dialogConfig.data = radMeterData;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(RadMetersDetailsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(dataReturned => {

    });
  }

  EditRowMeter(meterCatalog: RadMeter) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    const radMeterData = new RadMeterData();
    radMeterData.radMeter = this.utils.cloneDeep(meterCatalog);
    dialogConfig.data = radMeterData;
    const dialogRef = this.dialog.open(RadMetersDetailsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(dataReturned => {

    });
  }

  deleteRowMeter(radMeter: RadMeter) {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '300px',
      data: {
        message: this.getMessage('Confirm_Meter_Delete_Question')?.description,
        icon: 'stop'
      }
    });
    dialogRef.afterClosed().subscribe(dataReturned => {
      if (dataReturned && radMeter.id) {
        this._service.deleteRadMeterCatalog(radMeter.id).toPromise();
      }
    });
  }

  manageMeterTypes() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '35vw';
    dialogConfig.maxHeight = '85%';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(RadMetersTypesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {

    });
  }

  manageMeterUnits() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '35vw';
    dialogConfig.maxHeight = '85%';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(RadMetersUnitsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {

    });
  }

  manageMeterModels() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '35vw';
    dialogConfig.maxHeight = '85%';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(RadMeterModelsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {

    });
  }

  sortData(sort: Sort) {
    const dataToSort = this.utils.cloneDeep(this.dataSource.data);
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = dataToSort;
      return;
    }
    this.dataSource.data = dataToSort.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'serialNumber': return this.utils.sort(a.serialNumber, b.serialNumber, isAsc);
        case 'model': return this.utils.sort(a.radMeterModel?.name, b.radMeterModel?.name, isAsc);
        case 'calibrationDate': return this.utils.sort(a.calibrationDueDate, b.calibrationDueDate, isAsc);
        case 'type': return this.utils.sort(a.radMeterType?.name, b.radMeterType?.name, isAsc);
        case 'unit': return this.utils.sort(a.radMeterUnit?.name, b.radMeterUnit?.name, isAsc);
        default: return 0;
      }
    });
  }

  format(date?: Date) {
    return moment(date).format('MMM DD, YYYY');
  }

  showWarning(e: RadMeter) {
    const mdate = moment(e.calibrationDueDate);
    return mdate.isBefore(new Date());
  }
}
