import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NavigationLinkCreate, NavigationLinkDelete, NavigationLinkRefresh, NavigationLinkUpdate } from './navigation-links.action';
import { NavigationLink } from 'src/app/services/navigation-links/navigation-link';
import { NavigationLinkService } from 'src/app/services/navigation-links/navigation-link.service';

export class NavigationLinkStateModel {
  data: NavigationLink[] = [];
}

@State<NavigationLinkStateModel>({
  name: 'NavigationLinks',
  defaults: {
    data: []
  }
})
@Injectable()
export class NavigationLinkState {
  constructor(
    private service: NavigationLinkService
  ) { }

  @Selector()
  static read(state: NavigationLinkStateModel) {
    return state.data;
  }

  @Action(NavigationLinkRefresh)
  refresh({ patchState }: StateContext<NavigationLinkStateModel>) {
    this.service.readAll().toPromise().then(data => {
      patchState({ data });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(NavigationLinkCreate)
  create({ getState, patchState }: StateContext<NavigationLinkStateModel>, { item }: NavigationLinkCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(NavigationLinkUpdate)
  update({ getState, patchState }: StateContext<NavigationLinkStateModel>, { id, item }: NavigationLinkUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(NavigationLinkDelete)
  delete({ getState, patchState }: StateContext<NavigationLinkStateModel>, { id }: NavigationLinkDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
