import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KEVersionResource } from '../ke';
import { ShutterResource, Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { WFMasterResource } from '../../workflow/workflow';

@Injectable()
export class KeChecklistBuilderService {
  BASE_URL = environment.urls.baseApiUrl; // Api URL
  Api = '/KEBuilder';

  constructor(private http: HttpClient) {

  }

  getAll() {
    return this.http.get<KEVersionResource[]>(this.BASE_URL + this.Api);
  }

  getByID(id: number) {
    return this.http.get<KEVersionResource>(this.BASE_URL + this.Api + '/' + id);
  }

  ReadWFBySection(id: number, sectionNumber: number) {
    return this.http.get<any[]>(this.BASE_URL + this.Api + '/WFBySection/' + id + '?SectionNumber=' + sectionNumber);
  }

  ReadGlobal(id: number): Observable<WFMasterResource> {
    return this.http.get<WFMasterResource>(this.BASE_URL + this.Api + '/Global/' + id);
  }

  CreateChecklistTemplate(keVersion: KEVersionResource): Observable<ShutterResource> {
    return this.http.post<ShutterResource>(this.BASE_URL + this.Api, keVersion);
  }

  ReCreateChecklistTemplate(keVersion: KEVersionResource): Observable<ShutterResource> {
    return this.http.post<ShutterResource>(this.BASE_URL + this.Api + '/ReCreate', keVersion);
  }

  UpdateTemplatesAfterUpdate(shutterId: number, savedId: number): Observable<Resource[]> {
    return this.http.post<Resource[]>(this.BASE_URL + this.Api + '/UpdateAfterApproved/' + shutterId + '/' + savedId, null);
  }

  ApproveVersion(keVersion: KEVersionResource): Observable<KEVersionResource> {
    return this.http.put<KEVersionResource>(this.BASE_URL + this.Api + '/ApproveVersion', keVersion);
  }

  SaveRequired(id: number, required: boolean) {
    return this.http.patch(this.BASE_URL + this.Api + '/SaveRequired/' + id + '/' + required, null);
  }

  SaveOrder(id: number, order: number, saveLastUpdater: boolean) {
    return this.http.patch(this.BASE_URL + this.Api + '/SaveOrder/' + id + '/' + order + '/' + saveLastUpdater, null);
  }

  DeleteVersion(id: number): Observable<ShutterResource> {
    return this.http.delete<ShutterResource>(this.BASE_URL + this.Api + '/DeleteVersion/' + id);
  }

  DeleteWFRoleLocal(id: number) {
    return this.http.delete(this.BASE_URL + this.Api + '/WFRole/' + id);
  }
}
