import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PendingCatalog, PendingUser } from 'src/app/services/pending-approvals/pending-approval';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { UserCatalog } from 'src/app/components/catalogs/user-catalog/services/user';
import { CatalogService } from '../catalog-service';
import { UserCatalogDetailsComponent } from './user-catalog-details/user-catalog-details.component';
import { UserCatalogListComponent } from './user-catalog-list/user-catalog-list.component';


@Component({
  selector: 'user-catalog',
  templateUrl: './user-catalog.component.html',
  styleUrls: ['./user-catalog.component.scss']
})
export class UserCatalogComponent extends BaseComponent implements OnInit, AfterViewInit {

  loading!: boolean;

  userCatalog?: UserCatalog;

  filter!: string;
  valueFilter!: string;

  canCreate!: boolean;
  canApprove!: boolean;

  showModification?: boolean;

  @ViewChild(UserCatalogDetailsComponent)
  userCatalogDetails!: UserCatalogDetailsComponent;
  @ViewChild(UserCatalogListComponent)
  userCatalogList!: UserCatalogListComponent;

  constructor(
    protected override injector: Injector,
    private catalogService: CatalogService,
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.canApprove = this.hasPrivilege(PrivilegeEnum.UserCatalogApprove);
      this.canCreate = this.hasPrivilege(PrivilegeEnum.UserCatalogCreate);
    }, 500);
  }

  ngOnInit(): void {
  }

  rowSelected(data: UserCatalog | null) {
    if (data)
      this.userCatalog = data;
    this.showModification = this.userCatalog?.pendingUser && (this.canApprove || this.canCreate);
  }

  refreshSaved(id: number) {
    if (id)
      this.catalogService.pendingCatalog = { id } as PendingCatalog;
    else {
      this.userCatalog = undefined;
      this.catalogService.pendingCatalog = undefined;
    }
    this.userCatalogList.loadUserCatalogs();
  }

  applyFilter(filter: string) {
    this.filter = filter;
  }

  create() {
    this.userCatalogList?.createUser();
  }

  setLoading(e: boolean) {
    setTimeout(() => {
      this.loading = e;
    }, 100);

  }

}
