import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'src/app/common/base/base.service';
import { RoleScheduleStatus } from '../models/role-schedule-status';

@Injectable({
  providedIn: 'root'
})
export class RoleScheduleStatusService extends BaseService<RoleScheduleStatus, number> {

  constructor(protected override injector: Injector) {
    super(injector, '/role_schedule_status');
  }

  readByStatus(firstId: number) {
    const url = this.BASE_URL + this.endpoint + '/by_status/' + firstId;
    return this.http.get<RoleScheduleStatus[]>(url);
  }
}
