<div class="container-fluid mt-3">
  <div>
    <mat-accordion
      cdkDropList
      (cdkDropListDropped)="drop($event)"
      [cdkDropListData]="aplCategoriesFiltered"
    >
      <mat-expansion-panel
        class="apl-category-expansion-panel"
        [ngClass]="{
          'apl-category-expansion-panel-pending':
            aplCategory?.status !== 1 && hasActions(aplCategory)
        }"
        *ngFor="let aplCategory of aplCategoriesFiltered; let i = index"
        (closed)="categoryOpen(aplCategory, false)"
        (opened)="categoryOpen(aplCategory, true)"
        [expanded]="aplCategory.open"
        id="{{ aplCategory.id }}"
        cdkDrag
        [cdkDragDisabled]="!isSA() || !enableMove"
        cdkDragLockAxis="y"
        (cdkDragStarted)="onDragStarted(i)"
      >
        <mat-expansion-panel-header>
          <div class="row full-width d-flex">
            <div class="col pr-0">
              <div class="pb-0 d-flex justify-content-between">
                <div class="d-flex">
                  <div
                    class="apl-category-card-reorder"
                    *ngIf="
                      !filter?.length &&
                      enableMove &&
                      (aplCategoryApproval ||
                        aplCategoryEdit ||
                        aplCategoryDelete)
                    "
                    (click)="$event.stopPropagation()"
                  >
                    <mat-icon cdkDragHandle>reorder</mat-icon>
                  </div>
                  <h5
                    [innerHTML]="
                      aplCategory?.name + ' [' + aplCategory?.code + ']'
                        | highlightSearch : filter
                    "
                  ></h5>
                </div>
                <div class="d-flex justify-content-end button-container">
                  <div class="groupInfo d-flex justify-content-end">
                    <div class="col pt-2">
                      <mat-icon
                        *ngIf="
                          aplCategory.pendingGroups &&
                          (aplCategoryEdit || aplCategoryApproval)
                        "
                        color="accent"
                        matTooltip="Groups Approval Pending"
                        >warning</mat-icon
                      >
                    </div>
                  </div>
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="linkMenu"
                    (click)="$event.stopPropagation()"
                    matTooltip="Copy Link to This Category"
                  >
                    <mat-icon class="white">link</mat-icon>
                  </button>
                  <mat-menu #linkMenu="matMenu" class="small">
                    <button mat-menu-item (click)="copyLink(aplCategory)">
                      <mat-icon class="gray">link</mat-icon>Link Only
                    </button>
                    <button
                      mat-menu-item
                      (click)="copyLinkEmbedded(aplCategory)"
                    >
                      <mat-icon class="gray">link</mat-icon>Category Title and
                      Link
                    </button>
                  </mat-menu>
                  <div class="apl-category-card-actions">
                    <button
                      mat-icon-button
                      *ngIf="hasActions(aplCategory)"
                      [matMenuTriggerFor]="menu"
                      aria-label="actions"
                      (click)="$event.stopPropagation()"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button
                        mat-menu-item
                        *ngIf="canApproveOrReject(aplCategory)"
                        (click)="openApprovalPopup(aplCategory)"
                      >
                        <span [innerHTML]="labelApproveDisapprove"></span>
                      </button>
                      <button
                        mat-menu-item
                        *ngIf="canEdit(aplCategory)"
                        (click)="openEditCategoryPopup(aplCategory)"
                      >
                        <span [innerHTML]="labelEdit"></span>
                      </button>
                      <button
                        mat-menu-item
                        *ngIf="canDelete(aplCategory)"
                        (click)="deleteCategory(aplCategory)"
                      >
                        <span [innerHTML]="labelDelete"></span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <div class="py-0 d-flex justify-content-between">
                <label
                  ><span
                    class="category-managers-label"
                    [innerHTML]="labelCategoryManagers"
                  ></span
                  >:
                  <span
                    [innerHTML]="
                      getManagers(aplCategory) | highlightSearch : filter
                    "
                  ></span>
                </label>

                <button
                  class="add-button small"
                  mat-stroked-button
                  (click)="
                    openCreateGroupPopup(aplCategory); $event.stopPropagation()
                  "
                  *ngIf="aplCategory.open && canAddGroup(aplCategory)"
                >
                  Add Group <mat-icon class="white">add_box</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-expansion-panel-header>
        <mat-expansion-panel-body class="pl-0">
          <apl-category-v2-groups
            [aplCategory]="aplCategory"
            [enableMove]="enableMove"
            [filter]="filter"
            (loading)="loading.emit($event)"
          >
          </apl-category-v2-groups>
        </mat-expansion-panel-body>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
