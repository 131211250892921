<button mat-mini-fab class="closex" color="warn" (click)="close()">
  <mat-icon>close</mat-icon>
</button>

<mat-dialog-content>
  <div class="d-flex align-items-center">
    <div class="logo mb-5 mt-3">
      <img
        src="../../../../../assets/images/berkeley_logo.png"
        alt="Berkeley Logo"
      />
    </div>
    <h4 class="white mb-4">{{ environment.name }}</h4>
    <h6 class="white">Version: {{ environment.version }}</h6>
  </div>
</mat-dialog-content>
