import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RSSWAResource } from '../../rsswa';
import { Checklist, ChecklistExecStatus } from 'src/app/components/checklists/checklists';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { RSSFunctionalTest } from '../../rss-functional-test';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'rss-functional-test-list',
  templateUrl: './rss-functional-test-list.component.html',
  styleUrls: ['./rss-functional-test-list.component.scss']
})
export class RssFunctionalTestListComponent implements OnChanges {

  @Input() rsswa?: RSSWAResource | null;
  @Input() showAmendment?: boolean;

  hasChecklists?: boolean;
  formStatusEnum = FormStatusEnum;
  rssFunctionalTests?: RSSFunctionalTest[];

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.showAmendment)
      this.rssFunctionalTests = this.rsswa?.rssFunctionalTests;
    else this.rssFunctionalTests = this.rsswa?.rsswaAmendment?.rssFunctionalTests;

    this.rssFunctionalTests?.map(ft => {
      if (ft.checklist) {
        ft.checklist.name = ft.name;
        this.hasChecklists = true;
      }
      else if (ft.checklistTemplate)
        ft.checklist = { name: ft.checklistTemplate.name, checklistTemplate: ft.checklistTemplate, checklistStatus: { cssClass: 'gray' } as ChecklistExecStatus } as Checklist;
    });
  }

  checkDifferences() {
    if (this.showAmendment) {
      return !utils.isEquivalent(this.rsswa?.rssFunctionalTests?.map(t => t.checklistTemplateID) ?? [], this.rsswa?.rsswaAmendment?.rssFunctionalTests?.map(t => t.checklistTemplateID) ?? []);
    }
    return false;
  }

}