<div *ngIf="visible" class="d-flex flex-column px-5">
  <reviews-boards-cycles
    class="full-width"
    [reviewBoardFromIndex]="reviewBoard"
    (selectedReview)="reviewSelected.emit($event)"
  ></reviews-boards-cycles>
</div>
<span *ngIf="visible" class="hidden" id="serialNumber">{{
  reviewCycle?.serialNo
}}</span>
