import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-head-logo-about',
  templateUrl: './head-logo-about.component.html',
  styleUrls: ['./head-logo-about.component.scss']
})
export class HeadLogoAboutComponent {
  environment = environment;

  constructor(
    private dialogRef: MatDialogRef<HeadLogoAboutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  close() {
    this.dialogRef.close();
  }
}
