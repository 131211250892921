import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ProcedureCategoryRefresh, ProcedureCategoryCreate, ProcedureCategoryUpdate, ProcedureCategoryDelete } from './procedure-category.action';
import { ProcedureCategory } from '../../models/procedure-category.model';
import { ProcedureCategoryService } from '../../services/procedure-category.service';

export class ProcedureCategoryStateModel {
  ProcedureCategories: ProcedureCategory[] = [];
}

@State<ProcedureCategoryStateModel>({
  name: 'ProcedureCategory',
  defaults: {
    ProcedureCategories: []
  }
})
@Injectable()
export class ProcedureCategoryState {
  constructor(
    private procedureCategoryService: ProcedureCategoryService
  ) { }

  @Action(ProcedureCategoryRefresh)
  refresh({ patchState }: StateContext<ProcedureCategoryStateModel>) {
    this.procedureCategoryService.read().subscribe(
      data => patchState({ ProcedureCategories: data }),
      error => console.error(error)
    );
  }

  @Selector()
  static read(state: ProcedureCategoryStateModel) {
    return state.ProcedureCategories;
  }

  @Action(ProcedureCategoryCreate)
  create({ getState, patchState }: StateContext<ProcedureCategoryStateModel>, { item }: ProcedureCategoryCreate) {
    const state = getState();

    patchState({
      ProcedureCategories: [...state.ProcedureCategories, item]
    });
  }

  @Action(ProcedureCategoryUpdate)
  update({ getState, patchState }: StateContext<ProcedureCategoryStateModel>, { id, item }: ProcedureCategoryUpdate) {
    const state = getState();
    const index = state.ProcedureCategories.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.ProcedureCategories.splice(index, 1);
      patchState({
        ProcedureCategories: [...state.ProcedureCategories, item]
      });
    }
  }

  @Action(ProcedureCategoryDelete)
  delete({ getState, patchState }: StateContext<ProcedureCategoryStateModel>, { id }: ProcedureCategoryDelete) {
    patchState({
      ProcedureCategories: getState().ProcedureCategories.filter(x => x.id != id)
    });
  }
}
