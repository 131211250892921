<div class="mat-container scrollbar">
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Accelerator</mat-header-cell
      >
      <mat-cell
        class="green bold"
        *matCellDef="let element"
        [ngClass]="{
          red: element?.pendingAcceleratorCatalog?.action == 3,
          yellow:
            element?.pendingAcceleratorCatalog &&
            element?.pendingAcceleratorCatalog?.action != 3 &&
            (hasPrivilege(privilegeEnum.AcceleratorCatalogCreate) ||
              hasPrivilege(privilegeEnum.AcceleratorCatalogApprove)),
          green: element?.details?.accelerator?.status == 1,
          gray: element?.details?.accelerator?.status == 0
        }"
      >
        <span
          [innerHTML]="
            element.details.accelerator
              ? element.details.accelerator.name
              : (element.pendingDetails?.accelerator?.name
                | highlightSearch : filter)
          "
        ></span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Status</mat-header-cell
      >
      <mat-cell
        class="green bold"
        *matCellDef="let element"
        [ngClass]="{
          red: element?.pendingAcceleratorCatalog?.action == 3,
          yellow:
            element?.pendingAcceleratorCatalog &&
            element?.pendingAcceleratorCatalog?.action != 3 &&
            (hasPrivilege(privilegeEnum.AcceleratorCatalogCreate) ||
              hasPrivilege(privilegeEnum.AcceleratorCatalogApprove)),
          green: element?.details?.accelerator?.status == 1,
          gray: element?.details?.accelerator?.status == 0
        }"
      >
        <span *ngIf="element.pendingAcceleratorCatalog" class="pr-1">
          {{
            element.pendingAcceleratorCatalog?.statusName +
              " - " +
              element.pendingAcceleratorCatalog?.actionName
          }}
        </span>
        <span *ngIf="element.details.accelerator?.status == 0">Inactive</span>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="displayedColumnsAccelerator; sticky: true"
    ></mat-header-row>
    <mat-row
      #matrow
      *matRowDef="let row; columns: displayedColumnsAccelerator"
      class="table-row"
      [ngClass]="{
        'mat-row-highlight': selectedRowIndex == row.id
      }"
      [attr.position]="getPosition(row)"
      (click)="selectedRow(row)"
    ></mat-row>
  </mat-table>
</div>
