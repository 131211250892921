import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from '../../app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { QuillModule } from 'ngx-quill';
import { RouterModule, PreloadAllModules, Routes } from '@angular/router';
import { SharedModule } from 'src/app/modules/shared.module';
import { ClBaseComponent } from './cl-base/cl-base.component';
import { ButtonSignatureComponent } from './button-signature/button-signature.component';
import { MasterControlComponent } from './master-control/master-control.component';
import { CheckboxSignatureComponent } from './checkbox-signature/checkbox-signature.component';
import { QuestionSignatureComponent } from './question-signature/question-signature.component';
import { ClCloseActionComponent } from './cl-close-action/cl-close-action.component';
import { FormWorkflowComponent } from 'src/app/common/form-workflow/form-workflow.component';
import { ClCheckboxComponent } from './cl-checkbox/cl-checkbox.component';
import { ClCheckboxAndRadioComponent } from './cl-checkbox-and-radio/cl-checkbox-and-radio.component';
import { ClNoteComponent } from './cl-note/cl-note.component';
import { ClScheduleAndCheckboxComponent } from './cl-schedule-and-checkbox/cl-schedule-and-checkbox.component';
import { ClCheckboxAndTextInputComponent } from './cl-checkbox-and-text-input/cl-checkbox-and-text-input.component';
import { ClCommentsComponent } from './cl-comments/cl-comments.component';
import { ClCheckboxMultipleComponent } from './cl-checkbox-multiple/cl-checkbox-multiple.component';
import { ClEditorComponent } from './cl-editor/cl-editor.component';
import { ClEditorScheduleComponent } from './cl-editor/cl-editor-schedule/cl-editor-schedule.component';
import { ClEditorStatusComponent } from './cl-editor/cl-editor-status/cl-editor-status.component';
import { ClEditorTagInfoComponent } from './cl-editor/cl-editor-tag-info/cl-editor-tag-info.component';
import { ClEditorPlaceholderSelectComponent } from './cl-editor/cl-editor-placeholder-select/cl-editor-placeholder-select.component';
import { ClEditorRadioOptionsComponent } from './cl-editor/cl-editor-radio-options/cl-editor-radio-options.component';
import { ClEditorCheckboxOptionsComponent } from './cl-editor/cl-editor-checkbox-options/cl-editor-checkbox-options.component';
import { ClEditorTextboxOptionsComponent } from './cl-editor/cl-editor-textbox-options/cl-editor-textbox-options.component';
import { ClEditorLocationComponent } from './cl-editor/cl-editor-location/cl-editor-location.component';
import { ClEditorColorsComponent } from './cl-editor/cl-editor-colors/cl-editor-colors.component';
import { ClEditorBRATOIComponent } from './cl-editor/cl-editor-bratoi/cl-editor-bratoi.component';
import { ClEditorSublocationsComponent } from './cl-editor/cl-editor-sublocations/cl-editor-sublocations.component';
import { ClEditorTableHeadersComponent } from './cl-editor/cl-editor-table-headers/cl-editor-table-headers.component';
import { ClEditorImageComponent } from './cl-editor/cl-editor-image/cl-editor-image.component';
import { ClEditorHideDisableToggleComponent } from './cl-editor/cl-editor-hide-disable-toggle/cl-editor-hide-disable-toggle.component';
import { ClEditorPlaceholderOptionsComponent } from './cl-editor/cl-editor-placeholder-options/cl-editor-placeholder-options.component';
import { ClEditorCheckboxRolesSelectionComponent } from './cl-editor/cl-editor-checkbox-roles/cl-editor-checkbox-roles-selection/cl-editor-checkbox-roles-selection.component';
import { ClEditorCheckboxRolesComponent } from './cl-editor/cl-editor-checkbox-roles/cl-editor-checkbox-roles.component';
import { ClCheckboxAndCommentsComponent } from './cl-checkbox-and-comments/cl-checkbox-and-comments.component';
import { ClCatlComponent } from './cl-catl/cl-catl.component';
import { ClScheduleAbsiComponent } from './cl-schedule-absi/cl-schedule-absi.component';
import { ClCheckboxMultipleWithTextBoxComponent } from './cl-checkbox-multiple-with-textbox/cl-checkbox-multiple-with-textbox.component';
import { ClSubtitleComponent } from './cl-subtitle/cl-subtitle.component';
import { ClMultipleTextboxComponent } from './cl-multiple-textbox/cl-multiple-textbox.component';
import { ClShieldingsComponent } from './cl-shieldings/cl-shieldings.component';
import { ClTextComponent } from './cl-text/cl-text.component';
import { ClOlogComponent } from './cl-olog/cl-olog.component';
import { ClStatusChangeComponent } from './cl-status-change/cl-status-change.component';
import { ClRadMetersComponent } from './cl-rad-meters/cl-rad-meters.component';
import { ClRadMetersAddComponent } from './cl-rad-meters/cl-rad-meters-add/cl-rad-meters-add.component';
import { ClImageUploadComponent } from './cl-image-upload/cl-image-upload.component';
import { ClImageUploadTableComponent } from './cl-image-upload/cl-image-upload-table/cl-image-upload-table.component';
import { ClImageUploadTableDataComponent } from './cl-image-upload/cl-image-upload-table/cl-image-upload-table-data/cl-image-upload-table-data.component';
import { ClImageUploadInfoComponent } from './cl-image-upload/cl-image-upload-info/cl-image-upload-info.component';
import { ClEditorTableChannelsAddComponent } from './cl-image-upload/cl-image-upload-table/cl-image-upload-table-data/cl-editor-table-channels-add/cl-editor-table-channels-add.component';
import { ButtonSignatureDuplicateComponent } from './button-signature-duplicate/button-signature-duplicate.component';
import { ClLocationComponent } from './cl-location/cl-location.component';
import { ClBRATOIComponent } from './cl-bratoi/cl-bratoi.component';
import { ClCheckboxMultipleSignComponent } from './cl-checkbox-multiple-sign/cl-checkbox-multiple-sign.component';
import { ClCheckboxRadioLocationComponent } from './cl-checkbox-radio-location/cl-checkbox-radio-location.component';
import { ClRadioPlaceholderCondComponent } from './cl-radio-placeholder-cond/cl-radio-placeholder-cond.component';
import { ClRadMonitorComponent } from './cl-rad-monitor/cl-rad-monitor.component';
import { ClRadLocationComponent } from './cl-rad-location/cl-rad-location.component';
import { ClRestrictionsComponent } from './cl-restrictions/cl-restrictions.component';

import { ButtonSignatureRadioComponent } from './button-signature-radio/button-signature-radio.component';
import { OpApprovalWChecklistsComponent } from './op-approval-w-checklists/op-approval-w-checklists.component';
import { ChecklistStatusComponent } from '../checklist-status/checklist-status.component';
import { DocumentChipsComponent } from '../checklist-chips/document-chips.component';
import { ClRadMonitorDueDateComponent } from './cl-rad-monitor-due-date/cl-rad-monitor-due-date.component';
import { ClEditorRadMonitorsComponent } from './cl-editor/cl-editor-rad-monitors/cl-editor-rad-monitors.component';
import { ClEditorRadLocationsComponent } from './cl-editor/cl-editor-rad-locations/cl-editor-rad-locations.component';
import { ClEditorStatusChangeComponent } from './cl-editor/cl-editor-status-change/cl-editor-status-change.component';

const appRoutes: Routes = [
];

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

@NgModule({
  declarations: [
    // Checklists
    ClScheduleAndCheckboxComponent,
    // ClCheckboxYesNoComponent,
    ClCheckboxAndCommentsComponent,
    ClEditorComponent,
    ClEditorRadioOptionsComponent,
    ClCommentsComponent,
    ClEditorScheduleComponent,
    ClCatlComponent,
    // ActionItemComponent,
    // ActionItemEditComponent,
    // CatlComponent,
    ClSubtitleComponent,
    ClNoteComponent,
    ClCheckboxAndTextInputComponent,
    ClCheckboxMultipleComponent,
    ClCheckboxMultipleWithTextBoxComponent,
    ClMultipleTextboxComponent,
    ClCheckboxComponent,
    ClEditorComponent,
    ClCheckboxAndRadioComponent,
    ClCheckboxRadioLocationComponent,
    ClEditorCheckboxOptionsComponent,
    ClEditorTextboxOptionsComponent,
    ClScheduleAbsiComponent,
    ClTextComponent,
    ClImageUploadComponent,
    ClImageUploadTableComponent,
    ClImageUploadTableDataComponent,
    ClImageUploadInfoComponent,
    ClEditorTableChannelsAddComponent,
    ClEditorLocationComponent,
    ClEditorColorsComponent,
    ClEditorTableHeadersComponent,
    ClEditorImageComponent,
    ClCloseActionComponent,
    ClEditorBRATOIComponent,
    ClEditorHideDisableToggleComponent,
    ClBRATOIComponent,
    ClOlogComponent,
    ClRadMonitorComponent,
    // ClEditorRadMonitorComponent,
    // ClCheckboxAndCommentsEditComponent,
    ClStatusChangeComponent,
    ClEditorStatusComponent,
    ClRadLocationComponent,
    ClRadMetersComponent,
    ClRadMetersAddComponent,
    // ClEditorTableChannelsAddComponent,
    ClRestrictionsComponent,
    ClShieldingsComponent,
    ClLocationComponent,
    ClRadMonitorDueDateComponent,
    ClEditorRadMonitorsComponent,
    ClEditorRadLocationsComponent,

    // Signatures
    ButtonSignatureComponent,
    ButtonSignatureDuplicateComponent,
    CheckboxSignatureComponent,
    QuestionSignatureComponent,

    // General
    // DesignerComponent,
    MasterControlComponent,
    // RoleLabelsComponent,
    // MatCheckboxWithRolesComponent,
    // MatCheckboxWithLabelComponent,
    ClBaseComponent,
    FormWorkflowComponent,

    // Directives
    // TextareaAutoresizeDirective,
    ClEditorTagInfoComponent,
    ClCheckboxMultipleSignComponent,
    ClEditorCheckboxRolesComponent,
    ClEditorCheckboxRolesSelectionComponent,
    ClRadioPlaceholderCondComponent,
    ClEditorPlaceholderSelectComponent,
    ClEditorPlaceholderOptionsComponent,
    ClEditorSublocationsComponent,
    OpApprovalWChecklistsComponent,
    ButtonSignatureRadioComponent,

    ChecklistStatusComponent,
    DocumentChipsComponent,
    ButtonSignatureRadioComponent,
    ClEditorStatusChangeComponent,

  ],
  imports: [
    NgbModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    ScrollingModule,
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatRadioModule,
    // MatFileUploadModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatGridListModule,
    FormsModule,
    MatProgressBarModule,
    DragDropModule,
    MatTreeModule,
    MatExpansionModule,
    MatOptionModule,
    CommonModule,
    MatSidenavModule,
    QuillModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: true,
        useHash: true,
        anchorScrolling: 'enabled',
        preloadingStrategy: PreloadAllModules,
      }
    ),
    SharedModule
  ],
  exports: [
    MasterControlComponent,
    ClEditorComponent,
    ClCloseActionComponent,
    ButtonSignatureComponent,
    CheckboxSignatureComponent,
    QuestionSignatureComponent,
    DocumentChipsComponent,
    FormWorkflowComponent,
    ClOlogComponent
  ],
  providers: [

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ChecklistComponentsModule {
}
