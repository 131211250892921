import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { User } from "src/app/components/catalogs/user-catalog/services/user";

@Component({
  selector: "mat-checkbox-with-label",
  templateUrl: "./mat-checkbox-with-label.component.html",
  styleUrls: ["./mat-checkbox-with-label.component.scss"],
})
export class MatCheckboxWithLabelComponent implements OnInit {
  @Input() checked?: boolean;
  @Input() disabled?: boolean;
  @Input() labelPosition: "before" | "after" = 'before';
  @Input() color: string = "primary";
  @Input() label!: string;
  @Input() user?: User | null;
  @Input() warning?: string | null;
  @Input() editor!: boolean;
  @Input() date?: Date | null;

  @Output() change = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() { }

  getTooltip() {
    if (this.user && this.date)
      return '<b>' + this.user.name + "</b><br><span class='tooltip-date'>" + moment(this.date).format("M/D/YY HH:mm:ss") + "</span>";
    return '';
  }

  changed(e: any) {
    this.change.emit(e);
  }
}
