import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { PVData } from './cl-image-upload-table/cl-image-upload-table-data/cl-image-upload-table-data.component';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';
import { ClImageUploadTableComponent } from './cl-image-upload-table/cl-image-upload-table.component';
import { Checklist, ChecklistTemplate } from 'src/app/components/checklists/checklists';

export class TableColumn {
  name?: string;
  label?: string;
  readOnly?: boolean;
  editor?: any;
  type?: string;
  numericFormat?: any;
  source?: any;
  renderer?: any;
  className?: string;
  width?: number;
  span?: number;
}

export class TableData {
  id!: number;
  values!: string;
  rowId!: string;
  spotid!: number;
  imageid!: number;
  idSpan?: number;
}

@Component({
  selector: 'cl-image-upload',
  templateUrl: './cl-image-upload.component.html',
  styleUrls: ['./cl-image-upload.component.scss']
})
export class ClImageUploadComponent extends ClBaseComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input() enableColumnsBuilder!: boolean;
  @Input() enableColumnsChecklist!: boolean;
  @Input() checklistTemplate?: ChecklistTemplate;
  @Input() saving!: boolean;
  @Input() dataTable!: string;
  @Output() loading = new EventEmitter<boolean>();

  public columnsBuilder!: TableColumn[];
  public columnsChecklist!: TableColumn[];
  public columns!: string[];

  public row: any;
  public colorize!: boolean;

  context!: CanvasRenderingContext2D;

  @ViewChild('mycanvas') mycanvas: any;
  @ViewChild('containerCanvas') containerCanvas: any;
  @ViewChild(ClImageUploadTableComponent)
  uploadImageTable?: ClImageUploadTableComponent;

  imagesList: ListImage[] = [];
  images: string[] = [];
  colWidths!: number[];
  ImageSpotId = 0;
  rowId = 0;
  imageId!: number;
  action!: Action;

  showImageOptions!: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    this.innerWidth = window.innerWidth;
    if ((this.editor || !this.builder) && this.configuration) {
      this.load();
    }
  }

  ngAfterViewInit() {
    if (this.configuration) {
      this.load();
    }
  }

  load() {
    const canvas = this.mycanvas?.nativeElement;
    if (!canvas) {
      setTimeout(() => {
        this.load();
      }, 500);
    }
    else
      this.loadImages();
  }


  loadImages() {
    this.innerWidth = window.innerWidth;
    const configuration = this.utils.JSONparse(this.configuration);
    this.imagesList = configuration.images;
    this.images = configuration.images?.map((x: any) => x.image);
    const spots = configuration.spots as Spot[];
    const canvas = this.mycanvas?.nativeElement;
    if (!canvas) return;
    let context = canvas.getContext('2d');
    if (!this.images?.length && canvas) {
      canvas.height = 0;
      context.clearRect(0, 0, 0, 0);
    }
    if (this.images && canvas) {
      context = canvas.getContext('2d');
      context.globalCompositeOperation = 'destination-over';
      context.clearRect(0, 0, 0, 0);
      let images: any[] = [];
      this.imagesArray().then((data) => {
        images = data as [];
        const width = images.map(x => x.width).reduce((a, b) => a + b, 0);
        canvas.width = width;
        const height = Math.max.apply(null, images.map(x => x.height));
        canvas.height = height;
        for (let x = 0; x < images.length; x++) {
          context.drawImage(images[x], this.calcStart(images, x), 0);
        }
        if (spots) {
          this.loadSpots(spots);
        }
      });
    }
  }


  async imagesArray(): Promise<HTMLImageElement[]> {
    const imagePromises = this.images.map(img => this.imagePush(img));
    return Promise.all(imagePromises);
  }

  async imagePush(i: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = i;

      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }


  calcStart(images: any[], idx: number) {
    let width = 0;
    for (let i = 0; i < idx; i++) {
      width += images[i].width;
    }
    return width;
  }

  accept() {
    this.save.emit(this.configuration);
  }

  imageClicked(event: any) {
    const configuration = this.utils.JSONparse(this.configuration);
    const spots = configuration.spots as Spot[];
    const dataset = configuration.data;
    const canvas = this.mycanvas.nativeElement;
    const rect = canvas.getBoundingClientRect();

    let hcanvas = 0;
    let wcanvas = 0;
    const listImages: ListImage[] = configuration.images;
    listImages.map(item => {
      wcanvas = wcanvas + item.imageWidth;
      if (item.imageHeight > hcanvas) {
        hcanvas = item.imageHeight;
      }
    });
    const wrect: number = rect.width;
    const hrect: number = rect.height;
    const dotX = ((event.clientX) - rect.left);
    const dotY = ((event.clientY) - rect.top);
    const porcentX = (dotX / wrect);
    const porcentY = (dotY / hrect);
    const dotXCalculated = wcanvas * porcentX;
    const dotYCalculated = hcanvas * porcentY;
    const imageId = this.calcImageid(dotXCalculated, listImages);
    const percentages = this.calcPercentages(listImages, dotXCalculated, dotYCalculated, hcanvas);

    if (this.row) {
      spots.push({ id: this.row.id, posx: 0, posy: 0, spotText: this.row.rowId, imageid: imageId, percX: percentages[0].percX, percY: percentages[0].percY, origin: this.editor ? SpotOrigin.BUILDER : SpotOrigin.CHECKLIST });
      const row = dataset.find((x: any) => x.rowId == this.row.rowId);
      row.spotid = this.row.rowId;
      row.imageId = imageId;
      this.saveConfiguration(configuration);
      this.action = Action.None;
      this.row = null;
      this.loadImages();
      const elem = document.getElementById(row.spotid);
      if (elem) {
        this.scrollParentToChild(elem);
      }
    }
    else {
      this.showImageDialog(imageId, spots);
    }
  }

  showImageDialog(imageId?: number, spots?: Spot[]) {
    if (this.checkRoles()) {
      this.showImageOptions = true;
      this.action = Action.ImageOptions;
      if (imageId) {
        const image = this.imagesList.find(i => i.id == imageId);
        if (image) {
          image.canBeDeleted = !spots?.some(s => s.imageid == image.id && s.origin == SpotOrigin.BUILDER);
          this.imageId = image.id;
        }
      }
    }
  }

  scrollParentToChild(child: any) {
    const parent = document.getElementById('scrollableContainer');
    if (parent) {
      // Where is the parent on page
      const parentRect = parent.getBoundingClientRect();
      // What can you see?
      const parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth
      };

      // Where is the child
      const childRect = child.getBoundingClientRect();
      // Is the child viewable?
      const isViewable = (childRect.top >= (parentRect.top ?? 0)) && (childRect.bottom <= (parentRect.top ?? 0) + (parentViewableArea.height ?? 0));

      // if you can't see the child try to scroll parent
      if (!isViewable) {
        // Should we scroll using top or bottom? Find the smaller ABS adjustment
        const scrollTop = childRect.top - (parentRect.top ?? 0);
        parent.scrollTop += childRect.top * 1.2;
      }
    }
  }

  calcImageid(dotX: number, listImages: ListImage[]): number {
    let imgWidth1 = 0;
    let imgWidth2 = 0;
    let imgId = 0;

    listImages.map(item => {
      imgWidth2 = imgWidth2 + item.imageWidth;
      if (dotX > imgWidth1 && dotX <= imgWidth2) {
        imgId = item.id;
      }
      imgWidth1 = imgWidth2;
    });
    return imgId;
  }

  calcImagePercents(listImages: ListImage[], totalImagesW: number, totalImagesH: number): any[] {
    const imagesPercent: any[] = [{ id: 0, percentW: 0, percentH: 0 }];
    listImages.map(img => {
      const pW = img.imageWidth / totalImagesW;
      const pH = img.imageHeight / totalImagesH;
      imagesPercent.push({ id: img.id, percentW: pW, percentH: pH });
    });
    return imagesPercent;
  }

  calcPercentages(listImages: ListImage[], dotX: number, dotY: number, totalImagesH: number): any[] {
    const percentagesSpot = [{ percX: 0, percY: 0 }];
    let imgWidth1 = 0;
    let imgWidth2 = 0;
    let imgHeight = 0;

    listImages.map(item => {
      imgWidth2 = imgWidth2 + item.imageWidth;
      imgHeight = item.imageHeight;
      if (dotX > imgWidth1 && dotX <= imgWidth2) {
        const pointxImageCalculated = (dotX - imgWidth1);
        const pointxImagePercent = (pointxImageCalculated / item.imageWidth);
        const pointyImagePercent = dotY / (totalImagesH * (imgHeight / totalImagesH));
        percentagesSpot[0].percX = pointxImagePercent;
        percentagesSpot[0].percY = pointyImagePercent;
      }
      imgWidth1 = imgWidth2;
    });

    return percentagesSpot;
  }

  calcCoordinates(listImages: ListImage[], imagesPercents: any[], percentX: number, percentY: number, spotImageId: number, totalImagesW: number, totalImagesH: number) {
    const spotCoordinates: any[] = [{ x: 0, y: 0 }];
    let widthX = 0;
    listImages.map(img => {
      const percentImage: any[] = imagesPercents.filter(x => x.id == img.id);
      const widthImage = (totalImagesW * percentImage[0].percentW);
      if (img.id < spotImageId) {
        widthX = widthX + widthImage;
      }
      else if (img.id == spotImageId) {
        const widthCalculated = widthImage * percentX;
        spotCoordinates[0].x = widthX + widthCalculated;
        spotCoordinates[0].y = (totalImagesH * (percentY * percentImage[0].percentH));
      }
    });

    return spotCoordinates;
  }

  addRow(id: number) {
    if (id) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message: this.getMessage('Rad_Survey_Image_Component_Add_Row').description,
          icon: 'stop'
        }
      });
      confirm.afterClosed().subscribe(data => {
        if (data) {
          const configuration = this.utils.JSONparse(this.configuration);
          const dataset = configuration.data;
          const dsRows = dataset.filter((x: any) => x.id == id);
          const firstRow = dsRows[0];
          const channels = this.utils.JSONparse(firstRow.data) as PVData[];
          channels?.map(c => {
            c.date = null;
            c.error = false;
            c.updating = false;
            c.user = null;
            c.value = null;
          });
          const lastRow = dsRows[dsRows.length - 1];
          const lastRowId = lastRow.rowId.toString().split('.');
          const rowId = lastRowId[0] + '.' + (lastRowId[1] ? (+lastRowId[1] + 1).toString() : 1);
          dataset.push({ id, values: '', rowId, spotid: 0, imageid: 0, idSpan: 1, data: this.utils.JSONstringify(channels) });
          configuration.data = dataset;
          this.saveConfiguration(configuration);
        }
      });
    }
  }

  loadSpots(spots: Spot[]) {
    let hcanvas = 0;
    let wcanvas = 0;
    const listImages: ListImage[] = this.imagesList;
    listImages.map(item => {
      wcanvas = wcanvas + item.imageWidth;
      if (item.imageHeight > hcanvas) {
        hcanvas = item.imageHeight;
      }
    });

    const imagePercents = this.calcImagePercents(listImages, wcanvas, hcanvas);

    spots.map(s => {
      if (s.id != 0) {
        const coordinates = this.calcCoordinates(listImages, imagePercents, s.percX, s.percY, s.imageid, wcanvas, hcanvas);
        const x = Math.round(coordinates[0].x);
        const y = Math.round(coordinates[0].y);
        this.drawSpot(+s.spotText, x, y, s.origin ? s.origin : SpotOrigin.BUILDER);
      }
    });
  }

  drawSpot(spotNewId: number, x: number, y: number, origin: SpotOrigin) {
    const ctx = this.mycanvas.nativeElement.getContext('2d');
    const canvasWidth = this.mycanvas.nativeElement.width;
    const ratio = canvasWidth / this.innerWidth;

    ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;
    ctx.shadowBlur = 2;
    ctx.fillStyle = origin == SpotOrigin.BUILDER ? 'rgba(240, 0, 0, 0.7)' : 'rgba(0, 60, 230, 0.7)';
    ctx.strokeStyle = 'rgb(0, 0, 0, .5)';

    const radius = 19 * ratio;
    const font = 17 * ratio;

    ctx.beginPath();
    ctx.arc(x, y, radius, 0, Math.PI * 2, true);
    ctx.fill();
    ctx.stroke();

    ctx.fillStyle = 'rgba(255, 255, 255, 1)';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = font + 'px Verdana';
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowBlur = 0;
    ctx.fillText(spotNewId.toString(), x, y);
  }

  nextId(): number {
    const configuration = this.utils.JSONparse(this.configuration);
    let id = 0;
    const uniqueIds = [...new Set(configuration.data.map((obj: any) => obj.id))] as number[];
    let arrayIds: number[] = [];
    uniqueIds.map(n => {
      arrayIds.push(n);
    });
    arrayIds = arrayIds.sort((n1, n2) => n1 - n2);
    id = (Number(Math.max.apply(null, arrayIds)) + 1);
    return id;
  }

  imageChanged(e: any) {
    const configuration = this.utils.JSONparse(this.configuration);
    const images = e as ListImage[];
    let spots = configuration.spots as Spot[];
    let data = configuration.data;
    const spotsToRemove = spots.filter(s => !images.map(i => i.id).includes(s.imageid));
    spotsToRemove.map(x => {
      data.find((d: any) => d.spotid == x.spotText).spotid = '';
    });

    spots = spots.filter(s => images.map(i => i.id).includes(s.imageid));
    configuration.images = images;
    configuration.spots = spots;
    this.saveConfiguration(configuration);
  }

  tableChange(c: any) {
    const configuration = this.utils.JSONparse(c);
    this.saveConfiguration(configuration);
  }

  deleteRow(element: any) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('Rad_Survey_Image_Component_Delete_Row').description,
        icon: 'stop'
      }
    });
    confirm.afterClosed().subscribe(data => {
      if (data) {
        let configuration = this.utils.JSONparse(this.configuration);
        const dataset = configuration.data as TableData[];
        const index = dataset.findIndex(x => x.rowId == element.rowId);
        dataset.splice(index, 1);
        configuration = this.reorderRows(configuration);
        this.saveConfiguration(configuration);
        this.loadImages();
      }
    });
  }

  duplicateRow(r: any) {
    const configuration = this.utils.JSONparse(this.configuration);
    const dataset = configuration.data as TableData[];
    const rows = dataset.filter(x => x.id == r.id);
    const newId = this.nextId();
    rows.map(row => {
      const newRow = this.utils.cloneDeep(row);
      newRow.id = newId;
      newRow.rowId = newId + '.' + newRow.rowId.split('.')[1];
      newRow.spotid = 0;

      dataset.push(newRow);
    });
    configuration.data = dataset;
    this.saveConfiguration(configuration);
  }

  reorderRows(configuration: any) {
    const rowIds: string[] = [];
    const dataset = configuration.data;
    const spots = configuration.spots;
    const newDataset: any[] = [];
    dataset.map((s: any) => {
      if (!rowIds.includes(s.id)) {
        rowIds.push(s.id);
      }
    });
    rowIds.map(r => {
      const rrows = dataset.filter((s: any) => s.id == r);
      const srows: SpotsOrder[] = [];
      let o = 1;
      rrows.sort((a: any, b: any) => a.rowId.toString().split('.')[1] - b.rowId.toString().split('.')[1]).map((s: any) => {
        srows.push({ id: s.id, rowId: s.rowId, newRowId: s.id + '.' + o.toString() } as SpotsOrder);
        o++;
      });
      srows.map(n => {
        const newRow = this.utils.cloneDeep(dataset.find((d: any) => d.rowId == n.rowId));
        newRow.rowId = n.newRowId;
        newDataset.push(newRow);
        const spot = spots.find((s: any) => s.spotText == n.rowId);
        if (spot) {
          spot.spotText = n.newRowId;
        }
      });
    });
    configuration.data = newDataset;
    return configuration;
  }

  deleteSpot(element: any) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('Rad_Survey_Image_Component_Delete_Spot').description,
        icon: 'stop'
      }
    });

    confirm.afterClosed().subscribe(data => {
      if (data) {
        const configuration = this.utils.JSONparse(this.configuration);
        const spots = configuration.spots;
        const dataset = configuration.data;

        const spotRow = dataset.find((x: any) => x.rowId.toString() === element.rowId);
        spotRow.spotid = 0;
        spotRow.imageid = 0;
        const index = spots.findIndex((x: any) => x.spotText == element.rowId);
        spots.splice(index, 1);
        this.saveConfiguration(configuration);
        if (!this.builder) {
          this.loadImages();
        }
      }
    });
  }

  isBuilderColumn(column: any): boolean {
    let exist = false;
    const configuration = this.utils.JSONparse(this.configuration);
    const colsBuilder: any[] = configuration.columnsBuilder.map((x: any) => x.name);

    exist = colsBuilder.includes(column);

    return exist;
  }

  rowSpan(column: any, item: any, row: any) {
    if (row % 6 == 0 || column.name == 'id' || column.name == 'spotid' || column.name == 'settings' || column.name == 'values') {
      if (column.name == 'settings' || column.name == 'values') {
        return 1;
      }
      else if (column.name == 'id' || this.isBuilderColumn(column.name)) {
        return item.idSpan;
      }
      else if (column.name == 'spotid' || !this.isBuilderColumn(column.name)) {
        return item.spotSpan;
      }
    }
    return 0;
  }

  checkRoles() {
    return this.hasRoles(this.roleIds);
  }

  setRow(e: any) {
    if (this.uploadImageTable) {
      if (e) {
        this.row = e;
        this.action = Action.AddSpot;
        this.uploadImageTable.addingSpot = true;
        this.uploadImageTable.IdSpotAdd = e.rowId;
        this.scrollToTop();
      }
      // this.uploadImageTable.selectedRow = e;
      else {
        this.uploadImageTable.IdSpotAdd = 0;
        this.uploadImageTable.addingSpot = false;
        // this.uploadImageTable.selectedRow = null;
        this.row = null;
        this.action = Action.None;
        this.ImageSpotId = 0;
        this.rowId = 0;
        this.showImageOptions = false;
      }
    }
  }

  async saveConfiguration(configuration: any) {
    if (!this.saving) {
      this.saving = true;
      const c1 = await this.getNewConfiguration(configuration);
      const c2 = this.processData(c1);
      this.configuration = this.utils.JSONstringify(c2);
      this.changed.emit(this.configuration);
      this.saving = false;
    }
  }

  async getNewConfiguration(configuration: any) {
    return new Promise(async (resolve: any) => {
      if (!configuration.images) {
        resolve(configuration);
        return;
      }

      const imageProcessingPromises = configuration.images.map(async (i: any) => {
        const image = i.image as string;
        if (image.includes('data:image')) {
          const imgHTML = '<img src="' + image + '">';
          i.image = await this.uploadImages(imgHTML);
        }
        i.image = i.image.replace('<img src="', '').replace('">', '');
      });

      await Promise.all(imageProcessingPromises);
      resolve(configuration);
    });
  }

  async uploadImages(textValue: string) {
    let documentType: string;
    let serialNo: string;
    if (this.checklist) {
      documentType = (this.checklist as Checklist).documentType?.code?.trim() ?? 'OTHER';
      serialNo = this.checklist.serialNo ?? 'OTHER';
    }
    else if (this.checklistTemplate) {
      documentType = this.checklistTemplate.documentType?.code?.trim() ?? 'OTHER';
      serialNo = this.checklistTemplate.serialNo ?? 'OTHER';
    }
    else {
      documentType = 'OTHER';
      serialNo = 'OTHER';
    }
    const path = '/' + documentType + '/' + serialNo + '/Images/';
    const text = await this.imageUpload.uploadImagesToServer(textValue, path);
    return text;
  }


  processData(configuration: any) {
    configuration.data.map((d: any) => {
      const pvData = this.utils.JSONparse(d.data);
      if (pvData.user) {
        pvData.user = this.getUserInfo(pvData.user.id);
        d.data = this.utils.JSONstringify(pvData);
      }
    });
    return configuration;
  }

  override scrollToTop() {
    $('.card-footer').scrollTop(0);
  }

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
    this.loadImages();
  }

}
export class ListImage {
  id!: number;
  name!: string;
  image!: string;
  imageWidth!: number;
  imageHeight!: number;
  canBeDeleted?: boolean;
}

export enum Action {
  None = 0,
  AddSpot = 1,
  ImageOptions = 2
}

export class SpotsOrder {
  id!: number;
  rowId!: number;
  newRowId?: string;
}

export enum SpotOrigin {
  BUILDER = 0,
  CHECKLIST = 1
}

export class Spot {
  id!: number;
  posx!: number;
  posy!: number;
  spotText!: string;
  imageid!: number;
  percX!: number;
  percY!: number;
  origin!: SpotOrigin;
}
