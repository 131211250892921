import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'src/app/common/base/base.service';
import { ScheduleReminder } from '../models/schedule-reminder';

@Injectable({
  providedIn: 'root'
})
export class ScheduleReminderService extends BaseService<ScheduleReminder, number> {

  constructor(protected override injector: Injector) {
    super(injector, '/schedule_reminder');
  }

  override getAll() {
    return this.http.get<ScheduleReminder[]>(this.URL + '/All');
  }

}
