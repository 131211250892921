import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { SCFPrivileges, SCFStatus, ScfV2Service } from '../../scf-v2.service';
import { FormStatusEnum, Roles, NotificationType, FormType, OlogNotificationType } from 'src/app/common/enumerations/enumerations';
import { Servers } from 'src/app/common/enumerations/servers-enum';
import { NotificationMessage } from 'src/app/components/catalogs/notifications/services/notification.models';
import { SiteConfigurationService } from 'src/app/services/site-configuration/site-configuration-service';
import { WfSignature, WfSignatureResource, WFTaskSignatureResource } from 'src/app/services/work-flow/work-flow';
import { SCFMaster } from '../../scf';
import { NotificationService } from 'src/app/components/catalogs/notifications/services/notification.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { NotificationComponent } from 'src/app/controls/notifications/notification.component';
import { OLogService } from 'src/app/components/olog/olog.service';
import { KEService } from 'src/app/components/kenable/ke.service';
import { Restriction } from '../../../../controls/restrictions/services/restriction';
import { Observable, Subscription } from 'rxjs';
import { FormTableComponent } from 'src/app/controls/form-table/form-table.component';

@Component({
  selector: 'scf-v2-main-s3',
  templateUrl: './scf-v2-main-s3.component.html',
  styleUrls: ['./scf-v2-main-s3.component.scss']
})
export class ScfV2MainS3Component extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() scfMaster?: SCFMaster | null;
  @Input() disabled = false;
  @Input() privileges?: SCFPrivileges | null;
  @Input() status?: SCFStatus | null;

  @Output() changed = new EventEmitter<SCFMaster>();
  @Output() loading = new EventEmitter<boolean>();

  disableControls = true;

  emailsActivated?: boolean;
  amendments!: Amendment[];
  signatures!: WfSignature[];
  wfSignatures?: WfSignatureResource[];

  restrictions!: Restriction[];
  restrictionsFiltered!: Restriction[];
  restrictions$!: Observable<Restriction[]>;
  restrictionsSubs!: Subscription;

  constructor(
    private scfService: ScfV2Service,
    private notiService: NotificationService,
    private siteConfigurationService: SiteConfigurationService,
    private ologService: OLogService,
    protected override injector: Injector,
    private keService: KEService
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.siteConfigurationService.getFirst().toPromise().then((data) => {
      this.emailsActivated = data?.isEmailSCFActive;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadRestrictions();
  }

  override ngOnDestroy(): void {
    this.restrictionsSubs?.unsubscribe();
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
    });
  }

  loadRestrictions() {
    this.restrictions$ = this.store.select((state) => state.Restrictions.data);
    this.restrictionsSubs = this.restrictions$.subscribe((data) => {
      if (data?.length) {
        this.restrictions = data;
      }
    });
  }

  refresh() {
    this.setDisabled();
    this.getValues();
  }

  getValues() {
    this.wfSignatures = [];
    this.signatures = [];
    this.amendments = [];
    this.amendments.push({ ID: 0, number: 0 });
    if (this.scfMaster) {
      this.createSignatures(2);
      if (this.scfMaster.scfAmendments?.length && this.scfMaster.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == 5)?.wfStepLocals?.[0]?.wfTaskLocals?.[0].wfTasks?.[0]?.signedBy) {
        this.createSignatures(6);
      }
    }
  }

  createSignatures(section: number) {
    let index = 0;
    this.wfSignatures = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == section)?.wfSignatures?.filter(x => x.code !== 'SUBMIT').sort((a, b) => ((a.amendmentNumber ?? 0) * 100 + (a.order ?? 0)) - ((b.amendmentNumber ?? 0) * 100 + (b.order ?? 0)));
    if (this.wfSignatures) {
      for (const t of this.wfSignatures) {
        const signature: WfSignature = {
          id: t.id,
          approveId: t.approveID,
          unapproveId: t.unapproveID,
          disapproveId: t.disapproveID,
          amendmentId: t.amendmentID,
          amendmentNumber: t.amendmentNumber ? t.amendmentNumber : 0,
          name: t.titleBefore,
          roles: t.roles.map(r => r?.id),
          roleCodes: t.roles.map(r => r?.code).join('/'),
          userNames: t.users?.map(r => r?.name)[0],
          disabled: (t.disabled || this.disabled),
          approvedBy: t.signedBy,
          approved: t.signed,
          approvedOn: t.signedOn,
          approve: t.approveEnabled && !this.disabled && t.approveID !== 0,
          unapprove: t.unapproveEnabled && !this.disabled && t.unapproveID !== 0,
          disapprove: t.disapproveEnabled && !this.disabled && t.disapproveID !== 0,
          amendment: t.amendmentEnabled,
          required: t.required,
          taskId: t.taskID,
          value: t.value,
          warning: t.warning,
          requesterSignature: t.requesterSignature,
          visible: t.visible,
          enabled: t.enabled,
          sectionNumber: section
        };
        // if (t.code == 'OIC' && this.scfMaster?.status == FormStatusEnum.Pending && this.getCurrentUser()?.userRole?.map(r => r.roleID).includes(Roles.OIC)) {
        //   signature.disabled = false;
        //   const reqSignature = this.wfSignatures.find(x => x.code == 'REQ' && x.enabled);
        //   const afoSignature = this.wfSignatures.find(x => x.code == 'AOFO');
        //   signature.approve = t.approveEnabled && !this.disabled && (reqSignature ? reqSignature.signed : true) && (!afoSignature?.signed || afoSignature.signed && afoSignature?.signedBy?.id != this.getCurrentUser()?.id);
        // }
        if (!signature.enabled) {
          signature.approvedBy = null;
          signature.approved = false;
        }
        index++;
        if (t.amendmentNumber && !this.amendments.map(x => x.number).includes(t.amendmentNumber)) {
          this.amendments.push({ ID: t.amendmentID, number: t.amendmentNumber });
          this.amendments = this.amendments.sort((a, b) => a.number - b.number);
        }
        this.signatures.push(signature);
      }
    }
  }

  signCount() {
    let count = 0;
    if (this.wfSignatures) {
      this.wfSignatures.map(t => {
        if (t?.signed) { count++; }
      });
    }
    return count;
  }

  setValues() {

  }

  setDisabled() {
    this.disabled =
      (this.scfMaster?.status !== FormStatusEnum.WorkApprovals &&
        this.scfMaster?.status !== FormStatusEnum.RestrictionsAmendment &&
        this.scfMaster?.status !== FormStatusEnum.Pending &&
        this.scfMaster?.status !== FormStatusEnum.RestrictionsAmendment) ||
      this.getCurrentUser() === undefined;
  }


  getSignatures(amendment: number) {
    return this.signatures?.filter(x => x.amendmentNumber == amendment);
  }

  getLastAmendment() {
    const last = this.signatures.filter(x => x.amendmentNumber).map(x => x.amendmentNumber).sort((a, b) => (a ?? 0) - (b ?? 0)).reverse();
    return last ? last[0] : 0;
  }

  async approve(s: WfSignature) {
    const wfSignatures = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == s.sectionNumber)?.wfSignatures?.filter(x => x.code !== 'SUBMIT');
    const index = wfSignatures?.findIndex(x => x.id == s.id);
    const signaturesToSign = wfSignatures?.filter(x => x.enabled && (x.amendmentNumber ? x.amendmentNumber : 0) == s.amendmentNumber && x.taskID != s.taskId && !x.signed);
    let ok = true;
    if (signaturesToSign?.length == 0 && index && index > 1) {
      ok = await this.showAproveToActiveMessage();
      if (ok && this.scfMaster?.id) {
        const restrictedResourceIDs = this.restrictions.filter(x => this.scfMaster?.scfRestrictionsV2?.filter(x => x.restrictionID).map(x => x.restrictionID).includes(x.id)).map(y => y.resourceID).filter((resourceID): resourceID is number => resourceID !== undefined);
        const isAmendment = this.scfMaster?.status == FormStatusEnum.RestrictionsAmendment;
        ok = (await this.ologService.postToOlog(FormType.SCF, this.scfMaster.id, isAmendment ? OlogNotificationType.Amended : OlogNotificationType.Activate, s.amendmentNumber))?.ok ?? false;

        //Refresh KEs
        if (restrictedResourceIDs !== undefined && restrictedResourceIDs.length > 0) {
          this.keService.processKES(restrictedResourceIDs);
        }
      }
      else { this.getValues(); }
    }
    if (ok) {
      this.loading.emit(true);
      const wfTaskSignature: WFTaskSignatureResource = {
        taskId: s.taskId,
        actionId: s.approveId
      };
      this.scfService.sign(wfTaskSignature).toPromise().then(() => {
        if (index == 0)
          this.sendNotification();
        this.loading.emit(false);
      },
        (error) => {
          console.log(error);
          this.alert.defaultError();
        }
      );
    }
    else {
      this.getValues();
    }
  }

  async unapprove(s: WfSignature) {
    this.loading.emit(true);

    let verificator = true;
    if (this.scfMaster?.requesterId === this.getCurrentUser()?.id) {
      if (this.signatures.some(s => s.requesterSignature)) {
        try {
          verificator = false;
          let signatures: WfSignatureResource[] | undefined = [];
          if (!this.status?.isViewingAmendment) {
            signatures = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number === 2)?.wfSignatures;
          } else if (this.status.isViewingAmendment) {
            signatures = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number === 6)?.wfSignatures;
            signatures = signatures?.filter(x => x.amendmentID === s.amendmentId);
          }
          const requesterTask = signatures?.find(s => s.requesterSignature);
          const index = signatures?.findIndex(x => x.id === s.id);
          const validate =
            requesterTask &&
            requesterTask.unapproveID &&
            requesterTask.signed &&
            index !== undefined &&
            index !== null &&
            (index === 1 || (this.status?.isViewingAmendment && index === 0));
          if (validate) {
            const wfTaskSignature: WFTaskSignatureResource = {
              taskId: requesterTask.taskID,
              actionId: requesterTask.unapproveID
            };
            await this.scfService.sign(wfTaskSignature).toPromise().then(() => {
              this.loading.emit(false);
            });
          }
          verificator = true;
        } catch (error) {
          this.alert.defaultError();
        }
      }
    }
    if (verificator) {
      const wfTaskSignature: WFTaskSignatureResource = {
        taskId: s.taskId,
        actionId: s.unapproveId
      };
      this.scfService.sign(wfTaskSignature).toPromise().then(() => {
        this.loading.emit(false);
      },
        (error) => {
          console.log(error);
          this.alert.defaultError();
        }
      );
    }
  }

  sendNotification() {
    if (this.scfMaster?.id)
      this.notiService.getNotification(NotificationType.CreateSCF, this.scfMaster.id).toPromise().then(async (notification) => {
        if (notification) {
          const addressesObject = this.utils.JSONparse(notification.toAddresses);
          const emailAddreses = (addressesObject.computed as string[]).concat(addressesObject.added);
          let sendNotification = document.location.hostname == Servers.PRODUCTION;
          if (!sendNotification)
            sendNotification = await this.openNotificationDialog(notification, emailAddreses, true);
          if (!sendNotification)
            this.alert.warning('Email Notification not Sent!');

          else if (sendNotification) {
            this.notiService.sendNotification(notification).toPromise();
            this.alert.success(emailAddreses.join('<br>'), 'Email Notification Sent to:');
          }
        }
      });
  }

  openNotificationDialog(data: NotificationMessage, emailAddresses?: string[], readOnly?: boolean) {
    const dialogRef = this.dialog.open(NotificationComponent, {
      height: "fit-content",
      width: "60vw",
      data: {
        message: data,
        emailAddresses,
        readOnly,
        confirmation: true
      },
    });

    return dialogRef.afterClosed().toPromise()
  }

  disapprove(s: WfSignature, data: any) {
    this.loading.emit(true);
    const wfTaskSignature: WFTaskSignatureResource = {
      taskId: s.taskId,
      actionId: s.disapproveId,
      reason: data.text
    };

    this.scfService.sign(wfTaskSignature).toPromise().then(res => {
      this.loading.emit(false);
    },
      (error) => {
        console.log(error);
        this.alert.defaultError();
      }
    );
  }
}

export class Amendment {
  ID!: number;
  number!: number;
}
