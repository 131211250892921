import { Component, Output, EventEmitter, HostListener, AfterViewInit, OnDestroy, OnInit, OnChanges, Injector } from '@angular/core';
import { DataButtonSignature } from 'src/app/common/models/data-button-signature';
import { RoleToken } from 'src/app/services/authentication/token-structure';
import { MessageTypeEnum } from 'src/app/components/messages/services/message-banner.enums';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { WFTaskSignatureResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { TextareaDialogComponent } from '../../textarea-dialog/textarea-dialog.component';
@Component({
  selector: 'button-signature',
  templateUrl: './button-signature.component.html',
  styleUrls: ['./button-signature.component.scss']
})
export class ButtonSignatureComponent extends ClBaseComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  public wrapperTitle!: string;
  public wrapperWidth!: number;

  @Output() onCancel = new EventEmitter<any>();
  @Output() onError = new EventEmitter<string>();

  public fullRoles!: RoleToken;
  public name?: string | null;
  allRoles: Role[] = [];
  roles = [];
  public showRoles!: Role[];

  public hidden = false;

  wfTaskSignature: WFTaskSignatureResource = new WFTaskSignatureResource();

  fullRoleName!: string;

  constructor(
    public _wf: WorkFlowService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngAfterViewInit() {
    this.wrapperWidth = this.wrapperTitle != undefined ? $('#' + this.wrapperTitle)[0].clientWidth : 0;
    this.hidden = this.title == null || this.title == '';
  }

  ngOnInit() {
    this.hidden = this.title == null || this.title == '';
    this.refresh();
  }

  ngOnChanges() {
    this.hidden = this.title == null || this.title == '';
    this.refresh();
  }

  refresh() {
    this.onResize();

    if (!this.value) {
      this.label = 'Approve';
      this.name = null;
      this.date = null;
      this.color = 'primary';
    }
    else {
      this.label = 'Unapprove';
      this.color = 'accent';
      this.name = this.user?.name ? this.user.name : this.getCurrentUser()?.name;
    }
  }

  click() {
    if (!this.builder) {
      if (!this.value) {
        const confirm = this.dialog.open(YesNoDialogComponent, {
          width: '400px',
          data: {
            message: this.message ? this.message.description : this.getMessage('approve_message')?.description,
            icon: this.message?.type == MessageTypeEnum.Error ? 'stop' : this.message?.type == MessageTypeEnum.Warning ? 'warn' : null
          }
        });
        confirm.afterClosed().subscribe(data => {
          if (data) {
            this.disabled = false;
            this.name = this.getCurrentUser()?.name;
            this.date = new Date();
            this.value = !this.value;
            this.approve.emit({
              value: this.value,
              user: this.value ? this.getCurrentUser()?.id : 0,
              date: this.date
            });
          }
          this.refresh();
        });
      }
      else {
        const confirm = this.dialog.open(YesNoDialogComponent, {
          width: '400px',
          data: {
            message: this.getMessage('unapprove_message')?.description
          }
        });
        confirm.afterClosed().subscribe(data => {
          if (data) {
            this.disabled = false;
            this.name = null;
            this.date = null;
            this.value = !this.value;
            this.unapprove.emit({
              value: this.value,
              user: this.value ? this.getCurrentUser()?.id : 0,
              date: this.date
            });
          }
          this.refresh();
        });
      }
    }
    else {
      if (!this.value) {
        this.disabled = false;
        this.name = this.getCurrentUser()?.name;
        this.date = new Date();
        this.value = !this.value;
      }
      else {
        this.disabled = false;
        this.name = null;
        this.date = null;
        this.value = !this.value;
      }
    }
  }

  disapproveEvent() {
    if (!this.builder) {
      const confirm = this.dialog.open(TextareaDialogComponent, {
        width: '400px',
        data: {
          label: this.getMessage('disapprove_message')?.description,
          placeholder: 'Disapproval Reason',
          text: ''
        }
      });
      confirm.afterClosed().subscribe((data) => {
        if (data?.acceptClicked) {
          this.disabled = false;
          this.name = null;
          this.date = null;
          this.value = !this.value;
          const res: DataButtonSignature = {
            value: this.value,
            user: this.value ? this.getCurrentUser()?.id : 0,
            date: this.date,
            text: data.text
          };
          this.disapprove.emit(res);
        }
      });
    }
  }

  @HostListener('window:resize')
  onResize() {
    const myControl = $('#' + this.wrapperTitle)[0];
    if (myControl != undefined) {
      this.wrapperWidth = myControl.clientWidth;
      // console.log("Panel width: " + panelwith);
      // console.log(" --- Control width: " + this.wrapperWidth);

      const headLeft = $('#chl+' + this.wrapperTitle);
      const headRight = $('#chr+' + this.wrapperTitle);

      if (this.wrapperWidth < 260) {
        headLeft.removeClass('col-6');
        headLeft.addClass('col-12');
        headRight.removeClass('col-6');
        headRight.addClass('col-12');
      }
      else {
        headLeft.removeClass('col-12');
        headLeft.addClass('col-6');
        headRight.removeClass('col-12');
        headRight.addClass('col-6');
      }
    }
  }

  cancel() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: 'Are you sure to cancel the form?',
        icon: 'stop'
      }
    });

    confirm.afterClosed().subscribe(
      async data => {
        // if (data != null) {
        if (data) {
          this.wfTaskSignature.taskId = this.taskID;
          this.wfTaskSignature.actionId = this.cancelID;
          this._wf.sign(this.wfTaskSignature).subscribe(
            () => {
              this.onCancel.emit();
            },
            error => {
              this.onError.emit(error.error);
            }
          );
        }
      }
    );
  }

  getTooltip(action: number): string {
    if (this.builder)
      return this.tooltips?.find(x => x.action == action)?.content ?? '';
    else return '';
  }
}
