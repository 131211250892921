import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
// import { BulletinBoardItem } from 'src/app/_interface/BulletinBoardItem';
// import { BulletinBoardService } from 'src/app/Services/BulletinBoard/bulletin-board-service.service';
import { Store } from '@ngxs/store';
import { BulletinBoardItem } from '../bulletin-board-item';
import { BulletinBoardService } from '../bulletin-board-service.service';
// import { BulletinBoardRefresh } from 'src/app/Store/BulletinBoard/bulletin-board.action';
@Component({
  selector: 'app-modal-add-to-bb',
  templateUrl: './modal-add-to-bb.component.html',
  styleUrls: ['./modal-add-to-bb.component.scss']
})
export class ModalAddToBBComponent implements OnInit {
  Id = 0;
  Tittle = '';
  Description = '';
  ExpirationDate?: Date | null = null;
  EmisionDate: Date = new Date();
  ItemBackup: string | null = null;
  IsPined = false;
  CreatedBy = 0;
  saving = false;
  // USER LOAD
  public innerWidth: any;
  subtitle: string;
  options!: FormGroup;

  constructor(public dialogRef: MatDialogRef<ModalAddToBBComponent>, public BBService: BulletinBoardService, private store: Store) {
    this.subtitle = 'NEW';
    const data: BulletinBoardItem | null = BBService.selectedItem;
    if (data) {
      this.subtitle = 'EDIT';
      this.ItemBackup = JSON.stringify(BBService.selectedItem);
      this.Id = data.id ?? 0;
      this.Tittle = data.name;
      this.Description = data.description;
      this.EmisionDate = data.createdOn;
      this.ExpirationDate = data.expirationDate;
      this.CreatedBy = data.createdBy;
      this.IsPined = data.isPined;
      BBService.selectedItem = null;
    }
  }

  ngOnInit() {
  }

  Validate(): boolean {
    if (!this.Description.length) {
      return false;
    }
    return true;
  }

  Save(): void {
    this.saving = true;
    if (this.Validate()) {
      const item: BulletinBoardItem = {
        id: this.Id,
        name: this.Tittle,
        description: this.Description,
        status: 1,
        createdOn: this.EmisionDate,
        createdBy: this.CreatedBy,
        expirationDate: this.ExpirationDate,
        isPined: this.IsPined,
      };
      const self = this;
      if (item.expirationDate != null && item.expirationDate.toString() == '') {
        item.expirationDate = null;
      }
      if (this.ItemBackup == null || this.ItemBackup === undefined || this.ItemBackup === '') {
        item.createdOn = new Date();
        this.BBService.insertBBI(item).toPromise().then(() => {
          // this.store.dispatch(new BulletinBoardRefresh());
          self.dialogRef.close();
        });
      } else {
        this.BBService.updateBBI(item).toPromise().then(() => {
          // this.store.dispatch(new BulletinBoardRefresh());
          self.dialogRef.close();
        });
      }
    } else {
      this.saving = false;
    }
  }
}
