import { WfTaskLocalResource } from "src/app/services/work-flow/work-flow";


export class TasksRefresh {
  static readonly type = '[Tasks] Refresh';

  constructor(public wfStepLocalID: number) { }
}

export class TasksCreate {
  static readonly type = '[Tasks] Create';

  constructor(public item: WfTaskLocalResource) { }
}

export class TasksUpdate {
  static readonly type = '[Tasks] Update';

  constructor(public id: number, public item: WfTaskLocalResource) { }
}

export class TasksUpdateAll {
  static readonly type = '[Tasks] UpdateAll';

  constructor(public items: WfTaskLocalResource[]) { }
}

export class TasksDelete {
  static readonly type = '[Tasks] Delete';
  constructor(public id: number) { }
}
