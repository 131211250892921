<div class="container-fluid">
  <div *ngFor="let section of sections">
    <absi-builder-section
      #sectionX
      [section]="section"
      [wfTableId]="wfTableId"
      [disabled]="version?.statusName != 'New'"
      [absiTemplateID]="version?.id ?? 0"
      [serialNo]="version?.name ?? ''"
      (loading)="setLoading($event, section.number)"
    ></absi-builder-section>
  </div>
</div>
