import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { TextareaDialogComponent } from 'src/app/controls/textarea-dialog/textarea-dialog.component';
import { DocumentType } from 'src/app/services/documents/documents';
import { OlogEmail } from '../../services/olog-email';
import { OlogEmailService } from '../../services/olog-email.service';
import { AddEditOlogEmailComponent } from '../dialogs/add-edit-olog-email/add-edit-olog-email.component';


@Component({
  selector: 'app-olog-email-tab',
  templateUrl: './olog-email-tab.component.html',
  styleUrls: ['./olog-email-tab.component.scss']
})
export class OlogEmailTabComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() documentType?: DocumentType;
  @Input() isDisabled = true;

  @Output() changed = new EventEmitter<OlogEmail[]>();

  public ologEmails$!: Observable<OlogEmail[]>;
  public ologEmailSubscription!: Subscription;
  public ologEmails?: OlogEmail[];

  public displayedColumnsOlogEmail: string[] = ['user', 'email'];
  public dataSource: MatTableDataSource<OlogEmail> = new MatTableDataSource();

  constructor(
    protected override injector: Injector,
    public ologEmailService: OlogEmailService,
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDisabled']) {
      if (this.isDisabled) {
        this.removeAddColumn();
      } else {
        if (this.hasPrivilege(this.privilegeEnum.CreateOlogEmail)) {
          this.displayedColumnsOlogEmail.push('add');
        }
      }
    }
    if (this.documentType)
      this.loadOlogEmailsByDocTypeId(this.documentType.documentTemplateId ?? 0);
  }

  override ngOnDestroy(): void {
    this.ologEmailSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  loadOlogEmailsByDocTypeId(documentTypeId: number) {
    this.ologEmails$ = this.store.select(x => x.OlogEmail.data);
    this.ologEmailSubscription = this.ologEmails$.subscribe(ologEmails => {
      this.ologEmails = ologEmails.filter(x => x.documentTypeId === documentTypeId) ?? [];
      this.dataSource = new MatTableDataSource(this.ologEmails);
    });
  }

  ologEmailDialog(ologEmail?: OlogEmail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = ologEmail ? this.utils.cloneDeep(ologEmail) : new OlogEmail();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AddEditOlogEmailComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then((ologEmailToSave: OlogEmail) => {
      if (ologEmailToSave) {
        this.addUpdate(ologEmailToSave);
      }
    });
  }

  addUpdate(ologEmail: OlogEmail) {
    if (ologEmail.id && this.ologEmails) {
      const index = this.ologEmails?.findIndex(o => o.id == ologEmail?.id) ?? -1;
      this.ologEmails[index] = ologEmail;
    }
    else {
      this.ologEmails?.push(ologEmail);
    }

    this.dataSource = new MatTableDataSource(this.ologEmails);
    this.changed.emit(this.ologEmails);
    // const index = this.ologEmails?.findIndex(o => o.id == ologEmail?.id) ?? -1;
    // if ((index ?? -1) >= 0) {
    //   if (ologEmail) {
    //     this.dataSource.data[ologEmailIndex as number] = ologEmail;
    //     this.changed.emit(this.ologEmails);
    //   } else {
    //     this.deleteOlogEmailReason(ologEmailIndex as number);
    //   }
    // } else {
    //   if (ologEmail)
    //     this.dataSource.data.push(ologEmail);
    //   this.changed.emit(this.ologEmails);
    // }
    // this.dataSource.filter = '';
  }

  // addUpdateDeleteDb() {
  //   this.dataSource.data.map(ologEmail => {
  //     if (ologEmail.id) {
  //       if (!ologEmail.deleteReason) {
  //         if (ologEmail.isModified) {
  //           this.updateOlogEmail(ologEmail);
  //         }
  //       } else {
  //         this.deleteOlogEmail(ologEmail);
  //       }
  //     } else {
  //       this.createOlogEmail(ologEmail);
  //     }
  //   });
  // }

  // createOlogEmail(ologEmail: OlogEmail) {
  //   ologEmail.documentTypeId = this.documentType?.documentTemplateId;
  //   this.ologEmailService.CreateOlogEmail(ologEmail).toPromise().catch(() => {
  //     this.alert.defaultError();
  //   });
  // }

  // updateOlogEmail(ologEmail: OlogEmail) {
  //   this.ologEmailService.UpdateOlogEmail(ologEmail.id as number, ologEmail).toPromise().catch(() => {
  //     this.alert.defaultError();
  //   });
  // }

  // deleteOlogEmail(ologEmail: OlogEmail) {
  //   this.ologEmailService.DeleteOlogEmail(ologEmail.id as number, ologEmail.deleteReason ?? '').toPromise().catch(() => {
  //     this.alert.defaultError();
  //   });
  // }

  deleteOlogEmailReason(ologEmail: OlogEmail) {
    const dialogConfig: MatDialogConfig = {
      data: {
        label: 'Delete Reason',
        placeholder: 'Delete Reason',
        text: ''
      }
    };
    const dialogRefSureToDelete = this.dialog.open(TextareaDialogComponent, dialogConfig);
    dialogRefSureToDelete.afterClosed().toPromise().then(data => {
      if (data?.acceptClicked && data?.text) {
        const index = this.ologEmails?.findIndex(o => o.id == ologEmail?.id) ?? -1;
        this.ologEmails?.splice(index, 1);
        this.dataSource = new MatTableDataSource(this.ologEmails);
        this.changed.emit(this.ologEmails);
      }
    });
  }

  removeAddColumn() {
    if (this.displayedColumnsOlogEmail.includes('add')) {
      const index = this.displayedColumnsOlogEmail.indexOf('add');
      this.displayedColumnsOlogEmail.splice(index, 1);
    }
  }

}
