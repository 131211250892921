import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserRoleComponent } from './user-role/user-role.component';
import { MatTableDataSource } from '@angular/material/table';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { UserCatalog } from 'src/app/components/catalogs/user-catalog/services/user';

@Component({
  selector: 'user-catalog-roles',
  templateUrl: './user-catalog-roles.component.html',
  styleUrls: ['./user-catalog-roles.component.scss']
})
export class UserCatalogRolesComponent implements OnChanges {

  @Input() userCatalog?: UserCatalog;

  displayedColumns: string[] = ['name', 'code'];
  dataSource!: MatTableDataSource<Role>;

  roles?: Role[];

  constructor(
    private dialog: MatDialog
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    this.roles = this.userCatalog?.pendingUser ? this.userCatalog?.pendingDetails?.roles : this.userCatalog?.details.roles
    this.dataSource = new MatTableDataSource(this.roles);
  }

  openAddUserRole() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = new Role();
    dialogConfig.width = '460px';
    const dialogRef = this.dialog.open(UserRoleComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.roles?.push(data);
      }
    });
  }

  deletUserRole(role: Role) {
    const index = this.dataSource.data.indexOf(role);
    this.dataSource.data.splice(index, 1);
    this.dataSource.filter = '';
  }

}
