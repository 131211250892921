import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { LinkType, Link } from 'src/app/components/catalogs/navigation-links/link/link';


@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent extends BaseComponent implements OnInit {

  public urlRegex = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/);

  public hyperlink?: string;
  public webAddress?: string;

  public isSaving!: boolean;

  public linkSelected?: LinkType;

  linkTypes: LinkType[] = [];
  linkTypes$!: Observable<LinkType[]>;
  linkTypesSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<LinkComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: {
      link: Link,
      dialogTitle: string,
      typeAvailable: boolean,
      linkType: number
    },
  ) {
    super(injector);
  }

  async ngOnInit() {
    if (this.inputData.typeAvailable) {
      this.getLinkTypes();
      if (this.inputData.linkType) {
        this.linkSelected = this.linkTypes.find(x => x.id === this.inputData.linkType);
      }
    } else {
      this.inputData.link.status = 1;
    }
    this.hyperlink = this.inputData.link.hyperlink;
    this.webAddress = this.inputData.link.webAddress;
  }

  getLinkTypes() {
    this.linkTypes$ = this.store.select(state => state.LinkTypes.data);
    this.linkTypes$.subscribe(data => {
      this.linkTypes = data.filter(x => x.status === 2);
    });
    // return (await this.linkTypeService.getLinkTypes().toPromise()).filter(link => link.status === 2);
  }

  add() {
    this.isSaving = true;
    if (this.isValid()) {
      if (this.webAddress?.substr(0, 4).toLowerCase() !== 'http' && this.webAddress) {
        this.inputData.link.webAddress = 'https://' + this.webAddress;
      } else {
        this.inputData.link.webAddress = this.webAddress;
      }
      this.inputData.link.hyperlink = this.hyperlink;
      if (this.inputData.linkType !== 1) {
        this.inputData.link.typeId = this.linkSelected?.id;
        this.inputData.link.typeName = this.linkSelected?.name;
        this.inputData.link.linkType = this.linkSelected;
      } else {
        this.inputData.link.typeId = this.inputData.linkType;
      }
      this.dialogRef.close(this.inputData.link);
    } else {
      this.isSaving = false;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  isValid() {
    if (!this.hyperlink) {
      this.alert.message('linkDialog_Hyperlink_Required');
      return false;
    }
    if (!this.webAddress && !this.inputData.typeAvailable) {
      this.alert.message('linkDialog_WebAddress_Required');
      return false;
    }
    if (this.webAddress) {
      if (!this.urlRegex.test(this.webAddress)) {
        this.alert.message('linkDialog_WebAddress_NotValid');
        return false;
      }
    }
    if (this.inputData.typeAvailable) {
      if (!this.linkSelected) {
        this.alert.message('linkDialog_BRATOI_TypeRequired');
        return false;
      }
    }
    return true;
  }

}
