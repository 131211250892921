<div class="full-width">
	<mat-accordion multi>
		<ng-container *ngFor="let step of steps">
			<div class="row no-gutters">
				<div class="d-flex full-width">
					<checklist-step
						class="full-width"
						[currentChecklist]="currentChecklist"
						[step]="step"
						[precheck]="precheck??false"
						(loading)="loading.emit($event)"
						(checkShared)="checkShared.emit($event)"
					></checklist-step>
				</div>
			</div>
		</ng-container>
	</mat-accordion>
</div>
