import { Component, Inject, OnInit, OnDestroy, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Review, ReviewBoard, ReviewType, ReviewTypesEnum, ReviewCycle, ReviewBoardType } from '../../reviews.models';
import { FormControl } from '@angular/forms';
import { ChecklistTemplatesStoreAndListenersService } from 'src/app/components/checklists/store/checklist-templates/checklist-templates.store';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Observable, Subscription } from 'rxjs';
import { FormType, ReviewCycleStatus } from 'src/app/common/enumerations/enumerations';
import { ChecklistTemplate, TemplateType } from 'src/app/components/checklists/checklists';
import { ReviewsService } from '../../reviews.service';
import * as moment from 'moment';
@Component({
  selector: 'reviews-boards-review-create',
  templateUrl: './reviews-boards-review-create.component.html',
  styleUrls: ['./reviews-boards-review-create.component.scss']
})
export class ReviewsBoardsReviewCreateComponent extends BaseComponent implements OnInit, OnDestroy {

  review: Review;
  reviewBoard: ReviewBoard;

  reviewTypeCtrl = new FormControl();
  reviewType!: ReviewType;
  reviewTypes!: ReviewType[];
  reviewTypesFiltered!: ReviewType[];
  reviewTypes$!: Observable<ReviewType[]>;
  reviewTypesSubs!: Subscription;

  reviewBoardTypes!: ReviewBoardType[];
  reviewBoardTypesFiltered!: ReviewBoardType[];
  reviewBoardTypes$!: Observable<ReviewBoardType[]>;
  reviewBoardTypesSubs!: Subscription;

  checklistTemplateCtrl = new FormControl();
  checklistTemplates$!: Observable<ChecklistTemplate[]>;
  checklistTemplates!: ChecklistTemplate[];
  checklistTemplatesFiltered!: ChecklistTemplate[];
  checklistTemplatesSubs!: Subscription;

  templateTypes!: TemplateType[];
  templateTypes$!: Observable<TemplateType[]>;
  templateTypesSubs!: Subscription;

  reviewRequestCtrl = new FormControl();
  reviewRequests$!: Observable<ReviewCycle[]>;
  reviewRequests!: ReviewCycle[];
  reviewRequestsFiltered!: ReviewCycle[];
  reviewRequest!: ReviewCycle;
  reviewRequestsSubs!: Subscription;

  reviewDateTimeCtrl = new FormControl();
  isFullReview!: boolean;

  defaultTime!: any[];
  options = [{ id: 1, name: 'Full Review', checked: true }, { id: 2, name: 'Modification Review', checked: false }];
  reviewTypeEnum?: ReviewTypesEnum | null = ReviewTypesEnum.FULL;

  title!: string;
  disabled!: boolean;
  constructor(
    protected override injector: Injector,
    private dialogRef: MatDialogRef<ReviewsBoardsReviewCreateComponent>,
    public service: ReviewsService,
    private checklistTemplatesStore: ChecklistTemplatesStoreAndListenersService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(injector);
    this.reviewBoard = data.reviewBoard;
    this.review = data.review;
    this.checklistTemplatesStore.initStore(FormType.RCP);
  }

  override ngOnDestroy(): void {
    this.templateTypesSubs?.unsubscribe();
    this.checklistTemplatesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    const now = moment();
    this.defaultTime = [now.add(1, 'hour').format('HH'), '00', '00'];
    this.title = this.review ? `Edit Review ${this.reviewBoard.name}` : `Create Review ${this.reviewBoard.name}`;
    this.loadReviewBoardTypes();
    this.loadReviewTypes();
    this.loadReviewRequests();
    this.loadChecklistTemplates();
  }

  loadReviewBoardTypes() {
    this.reviewBoardTypes$ = this.store.select(state => state.ReviewBoardTypes.data);
    this.reviewBoardTypesSubs = this.reviewBoardTypes$.subscribe(data => {
      if (data?.length) {
        this.reviewBoardTypes = data;
        if (this.reviewBoard) {
          this.reviewBoard.reviewBoardType = this.reviewBoardTypes.find(t => t.id == this.reviewBoard.reviewBoardTypeID);
        }
      }
    });
  }

  loadReviewTypes() {
    this.reviewTypes$ = this.store.select(state => state.ReviewTypes.data);
    this.reviewTypesSubs = this.reviewTypes$.subscribe(data => {
      if (data.length) {
        this.reviewTypes = data;
        this.reviewTypesFiltered = this.reviewTypes.filter(t => t.reviewBoardTypeID == this.reviewBoard.reviewBoardTypeID && t.status == 1);
      }
    });
  }

  filterReviewTypes() {
    return this.reviewTypesFiltered.filter(x => x.type === this.reviewTypeEnum);
  }

  loadChecklistTemplates() {
    this.checklistTemplates$ = this.store.select(state => state.ChecklistTemplates.data);
    this.checklistTemplatesSubs = this.checklistTemplates$.subscribe(data => {
      this.checklistTemplates = data;
    });
  }

  loadReviewRequests() {
    this.reviewRequests$ = this.store.select(state => state.ReviewCycles.data);
    this.reviewRequestsSubs = this.reviewRequests$.subscribe(data => {
      this.reviewRequests = data.filter(x => (!x.resourceID || x.resourceID === this.reviewBoard.resourceID) && x.status !== ReviewCycleStatus.Rejected && x.status !== ReviewCycleStatus.Pending && x.status != ReviewCycleStatus.Archived);
      // this.reviewRequests = data.filter(x => !x.resourceID && x.status !== ReviewCycleStatus.Rejected && x.status !== ReviewCycleStatus.Pending && x.status != ReviewCycleStatus.Archived);
      this.reviewRequestsFiltered = this.reviewRequests.sort((a, b) => a.id - b.id);
    });
  }

  filterChecklistTemplates() {
    this.templateTypes$ = this.store.select(state => state.TemplateTypes.data);
    this.checklistTemplatesFiltered = [];
    this.templateTypesSubs = this.templateTypes$.subscribe(data => {
      const templateType = data.find(x => x.id === this.reviewType.templateTypeID);
      this.checklistTemplatesFiltered = this.checklistTemplates.filter(x => x.templateTypeID == templateType?.id && x.status == 1);
      if (this.checklistTemplatesFiltered?.length === 1) {
        this.checklistTemplateCtrl.setValue(this.checklistTemplatesFiltered[0]);
      }
      else {
        this.checklistTemplateCtrl.setValue(null);
      }
    });
  }

  displaySerialNo(template: any): string {
    return template && template.serialNo ? template.serialNo : '';
  }


  close() {
    this.dialogRef.close(this.data);
    this.service.loading = false;
  }

  selectedReviewRequest(e: any) {
    this.reviewRequest = e.option.value;
    if (this.reviewRequest.reviews && this.reviewRequest.reviews.length > 0) {
      this.reviewTypeEnum = this.reviewRequest.reviews[0].type;
    }
    else {
      this.reviewTypeEnum = ReviewTypesEnum.FULL;
    }
    this.isFullReview = this.reviewTypeEnum === ReviewTypesEnum.FULL;
  }

  optionDisabled() {
    return this.reviewRequest?.reviews?.length > 0;
  }

  changedReviewRequest(e: any) {
    this.reviewRequestsFiltered = this.reviewRequests.filter(x => x.name?.toLowerCase().includes(e.currentTarget.value.toLowerCase()));
  }

  radioChanged(optionSelected: number) {
    this.isFullReview = optionSelected === ReviewTypesEnum.FULL;
  }

  selectedReviewType(e: any) {
    this.reviewType = e.option.value;
    this.filterChecklistTemplates();
  }

  dateChanged(e: any) {
    this.reviewDateTimeCtrl.setValue(e);
  }

  cancel() {
    this.close();
  }

  accept() {
    this.service.loading = true;
    const reviewRequest = this.utils.Serialize(this.reviewRequest);
    reviewRequest.reviewBoardID = this.reviewBoard.id;
    reviewRequest.resourceID = this.reviewBoard.resourceID;
    reviewRequest.type = this.isFullReview ? ReviewTypesEnum.FULL : ReviewTypesEnum.MODF;
    reviewRequest.code = (ReviewTypesEnum[this.isFullReview ? ReviewTypesEnum.FULL : ReviewTypesEnum.MODF]) + ' ' + this.reviewType.code + ' ' + this.reviewBoard.resource?.name;
    reviewRequest.reviews = [];
    const status = this.reviewBoard.reviewBoardType?.reviewStatuses?.find(x => x.code?.toUpperCase() == 'DRAFT')?.id;
    if (status) {
      this.service.updateReviewCycle(reviewRequest).toPromise().then(reviewRequestUpdated => {
        if (reviewRequestUpdated) {
          this.reviewRequest = reviewRequestUpdated;
          const review = {
            id: 0,
            name: this.reviewType.name + ' for ' + this.reviewBoard.resource?.name,
            description: (this.isFullReview ? 'Full' : 'Modification') + ' ' + this.reviewType.name + ' for ' + this.reviewBoard.resource?.name,
            reviewTypeID: this.reviewType.id,
            reviewCycleID: this.reviewRequest.id,
            checklistTemplateID: this.checklistTemplateCtrl.value?.id,
            reviewDate: this.reviewDateTimeCtrl.value,
            status,
            reviewStatusID: status,
            type: this.isFullReview ? ReviewTypesEnum.FULL : ReviewTypesEnum.MODF,
            code: ReviewTypesEnum[this.isFullReview ? ReviewTypesEnum.FULL : ReviewTypesEnum.MODF]
          } as Review;
          this.service.createReview(review).toPromise().then(reviewCreated => {
            if (reviewCreated)
              this.review = reviewCreated;
            this.review.reviewActionItems = [];
            this.alert.message('ReviewCreated', [{ placeholder: '{name}', value: reviewCreated?.name }]);
            this.close();
          });
        }
      });
    }
    else {
      this.alert.error('There is no Status defined as DRAFT for this Review Type. Please check Status settings.');
      this.service.loading = false;
    }
  }

  formValid() {
    return this.reviewRequest != null && this.reviewType != null && this.reviewDateTimeCtrl.value != null;
  }
}
