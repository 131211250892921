import { Component, OnChanges } from '@angular/core';
import { ClBaseComponent } from '../cl-base/cl-base.component';

@Component({
  selector: 'cl-comments',
  templateUrl: './cl-comments.component.html',
  styleUrls: ['./cl-comments.component.scss']
})
export class ClCommentsComponent extends ClBaseComponent implements OnChanges {

  ngOnChanges() {
    this.initials = this.user ? this.user.initials : '';
    if (this.formType)
      this.formType = this.formType.toString().trim();
    this.setValues();
  }

  setValues() {
    this.valueObjects = this.getValueObjects(this.valueString);
    if (this.valueObjects) {
      const text = this.valueObjects?.find(x => x.key == 'comments')?.val;
      this.textValue = text ? text : '';
    }
  }

  textChanged(e: any) {
    this.textValue = e;
    this.valueObjects = [];
    this.valueObjects.push({ key: 'comments', val: this.textValue });
    this.saveValues(this.valueObjects);
  }

}
