import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { FormControl, FormGroupDirective, NgForm, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { ErrorStateMatcher, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldDefaultOptions, MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { ChecklistComponentsModule } from 'src/app/controls/checklist-components/checklist-components.module';
import { httpInterceptorProviders } from 'src/app/interceptors/interceptorProviders';
import { SharedModule } from 'src/app/modules/shared.module';
import { ChecklistsModule } from '../checklists/checklists.module';
import { APLCategoryV2Component } from './apl-category-v2/apl-category-v2.component';
import { AplCategoryV2ListComponent } from './apl-category-v2/apl-category-v2-list/apl-category-v2-list.component';
import { AplCategoryV2CategoriesComponent } from './apl-category-v2/apl-category-v2-categories/apl-category-v2-categories.component';
import { AplCategoryV2GroupsComponent } from './apl-category-v2/apl-category-v2-categories/apl-category-v2-groups/apl-category-v2-groups.component';
import { AplCategoryV2GroupCardComponent } from './apl-category-v2/apl-category-v2-categories/apl-category-v2-groups/apl-category-v2-group-card/apl-category-v2-group-card.component';
import { APLGroupApprovalPopupComponent } from './apl-category-v2/dialogs/apl-group-approval-popup/apl-group-approval-popup.component';
import { APLGroupPopupComponent } from './apl-category-v2/dialogs/apl-group-popup/apl-group-popup.component';
import { APLCategoryApprovalPopupComponent } from './apl-category-v2/dialogs/apl-category-approval-popup/apl-category-approval-popup.component';
import { APLCategoryPopupComponent } from './apl-category-v2/dialogs/apl-category-popup/apl-category-popup.component';
import { RedirectGuard } from 'src/app/common/guards/redirect.guard';


const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

class TouchedErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

const appRoutes: Routes = [
  { path: 'apl-categories', component: APLCategoryV2Component, canDeactivate: [RedirectGuard] },
  { path: 'apl-categories/:id', component: APLCategoryV2Component, canDeactivate: [RedirectGuard] },
];

@NgModule({
  declarations: [
    APLCategoryV2Component,
    AplCategoryV2CategoriesComponent,
    APLCategoryPopupComponent,
    APLCategoryApprovalPopupComponent,
    AplCategoryV2GroupsComponent,
    AplCategoryV2GroupCardComponent,
    APLGroupPopupComponent,
    APLGroupApprovalPopupComponent,
    AplCategoryV2ListComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    HttpClientModule,
    ScrollingModule,
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatRadioModule,
    // MatFileUploadModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    DragDropModule,
    MatTreeModule,
    MatExpansionModule,
    MatOptionModule,
    MatSidenavModule,
    MatTooltipModule,
    // SavingModule,
    QuillModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: true,
        useHash: true,
        anchorScrolling: 'enabled',
        preloadingStrategy: PreloadAllModules,
      }
    ),
    SharedModule,
    ChecklistComponentsModule,
    ChecklistsModule
    // ClCommentsPopupModule,
    // CommentsAndAttachmentsModule
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    httpInterceptorProviders,
    { provide: ErrorStateMatcher, useClass: TouchedErrorStateMatcher }
  ],
  bootstrap: [AppComponent, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AplModule { }
