import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MenuItem } from '../head-menu/head-menu.models';
import { MenuItemCreate, MenuItemDelete, MenuItemRefresh, MenuItemUpdate } from './menu-item.action';
import { HeadMenuService } from '../head-menu/head-menu.service';

export class MenuItemStateModel {
  data: MenuItem[] = [];
}

@State<MenuItemStateModel>({
  name: 'MenuItem',
  defaults: {
    data: [],
  }
})
@Injectable()
export class MenuItemState {
  constructor(
    private headMenuService: HeadMenuService
  ) { }

  @Selector()
  static read(state: MenuItemStateModel) {
    return state.data;
  }

  @Action(MenuItemRefresh)
  refresh({ patchState }: StateContext<MenuItemStateModel>) {
    this.headMenuService.getAll().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(MenuItemCreate)
  create({ getState, patchState }: StateContext<MenuItemStateModel>, { item }: MenuItemCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(MenuItemUpdate)
  update({ getState, patchState }: StateContext<MenuItemStateModel>, { id, item }: MenuItemUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(MenuItemDelete)
  delete({ getState, patchState }: StateContext<MenuItemStateModel>, { id }: MenuItemDelete) {
    patchState({
      data: getState().data.filter(x => x.id != id)
    });
  }


}
