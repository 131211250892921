import { RadLocationType } from "src/app/components/catalogs/rad-monitor/rad-monitor/rad-location-type";

export class RadLocationTypeRefresh {
  static readonly type = '[RadLocationType] Refresh';
}

export class RadLocationTypeCreate {
  static readonly type = '[RadLocationType] Create';
  constructor(public item: RadLocationType) { }
}

export class RadLocationTypeUpdate {
  static readonly type = '[RadLocationType] Update';
  constructor(public id: number, public item: RadLocationType) { }
}

export class RadLocationTypeDelete {
  static readonly type = '[RadLocationType] Delete';
  constructor(public id: number) { }
}
