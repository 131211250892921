<div class="card">
  <div class="card-header">
    <span class="card-title"
      >{{ resource.id ? "Edit " : "Add " }} Location</span
    >
  </div>
  <div class="card-body">
    <mat-form-field>
      <mat-label>Location Name</mat-label>
      <input
        matInput
        [(ngModel)]="locationName"
        placeholder="Location Name"
        name="locationName"
      />
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button mat-flat-button color="primary" (click)="add()">Accept</button>
    </div>
  </div>
</div>
