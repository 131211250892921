import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Role } from '../services/role';
import { RoleService } from '../services/role.service';
import { RoleCreate, RoleDelete, RoleRefresh, RoleUpdate } from './role.action';

export class RoleStateModel {
  data: Role[] = [];
}

@State<RoleStateModel>({
  name: 'Roles',
  defaults: {
    data: []
  }
})
@Injectable()
export class RoleState {
  constructor(
    private roleService: RoleService
  ) { }

  @Selector()
  static read(state: RoleStateModel) {
    return state.data;
  }

  @Action(RoleRefresh)
  refresh({ patchState }: StateContext<RoleStateModel>) {
    this.roleService.Read().toPromise().then(data => {
      patchState({ data });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(RoleCreate)
  create({ getState, patchState }: StateContext<RoleStateModel>, { item }: RoleCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(RoleUpdate)
  update({ getState, patchState }: StateContext<RoleStateModel>, { id, item }: RoleUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    const currentState = state.data;

    if (index >= 0) {
      currentState.splice(index, 1, item);
      patchState({ data: currentState });
    }
  }

  @Action(RoleDelete)
  delete({ getState, patchState }: StateContext<RoleStateModel>, { id }: RoleDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
