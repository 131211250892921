import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import * as AbsiTemplateTypeActions from './absi-template-type.action';
import { GeneralSignalRResponse } from "src/app/services/signal-r/general-signal-r-response";
import { SignalRService } from "src/app/services/signal-r/signal-r.service";
import { SignalRAction } from "src/app/services/signal-r/signalr-enums";
import { Channels } from "src/app/store/channels";

@Injectable({
  providedIn: 'root'
})
export class AbsiTemplateTypeStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore() {
    this.store.dispatch(new AbsiTemplateTypeActions.AbsiTemplateTypeRefresh());
  }

  initListeners() {
    // AbsiTemplateType
    this.signalRService.hubConnection?.on(Channels.ABSITEMPLATETYPES, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new AbsiTemplateTypeActions.AbsiTemplateTypeCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new AbsiTemplateTypeActions.AbsiTemplateTypeUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new AbsiTemplateTypeActions.AbsiTemplateTypeDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new AbsiTemplateTypeActions.AbsiTemplateTypeRefresh());
          break;

      }
    });

  }
}
