import { Component, Input, OnInit, Injector, } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Review, ReviewDocumentCategory, ReviewDocuments, } from "../../reviews.models";
import { ReviewsService } from "../../reviews.service";
import { ReviewAttachmentTypesComponent } from "../review-attachment-types/review-attachment-types.component";
import { ReviewAttachmentComponent } from "../review-attachment/review-attachment.component";
import { ReviewLinksComponent } from "../review-links/review-links.component";
import { BaseComponent } from "src/app/common/base/base.component";
import { YesNoDialogComponent } from "src/app/controls/yes-no-dialog/yes-no-dialog.component";
import FileV2 from "src/app/services/file/file-v2.model";
import { FileV2Service } from "src/app/services/file/file-v2.service";
import { Roles } from "src/app/common/enumerations/enumerations";

@Component({
  selector: "review-attachment-documents",
  templateUrl: "./review-attachment-documents.component.html",
  styleUrls: ["./review-attachment-documents.component.scss"],
})
export class ReviewAttachmentDocumentsComponent extends BaseComponent implements OnInit {
  @Input() review?: Review;
  @Input() canDelete!: boolean;

  public displayedColumns!: string[];
  public showTable = false;
  public enableCategoriesButton = false;
  public dataSources: any[] = [];
  path!: string;
  fileToUpload!: File;
  fileIdServer!: number;
  uploadingFiles = false;
  loading = false;
  reviewDocuments!: ReviewDocuments[];
  reviewDocumentsFiltered!: ReviewDocuments[];
  selectedRowIndex!: number;
  reviewattachmentCategories: ReviewDocumentCategory[] = [];
  reviewattachmentCategoriesFiltered: ReviewDocumentCategory[] = [];

  constructor(
    protected override injector: Injector,
    private _service: ReviewsService,
    private file2Service: FileV2Service
  ) {
    super(injector);
    this.loading = true;
  }


  ngOnInit(): void {
    this.displayedColumns = this.canDelete
      ? ["name", "attachmentUser", "attachmentDate", "type", "actions"]
      : ["name", "attachmentUser", "attachmentDate", "type"];
    this.enableCategoriesButton = this.hasRoles([Roles.BRC]);
    this.loadReviewDocuments();
    this.loading = false;
  }

  async loadReviewDocuments() {
    await this.ObtainAttachmentCategories();
    if (this.review)
      await this._service.getReviewDocumentsByReview(this.review.id).toPromise().then((result) => {
        if (!(result?.length)) {
          result = [];
          this.showTable = false;
        } else {
          this.showTable = true;
        }

        this.reviewattachmentCategoriesFiltered = [];
        this.dataSources = [];
        this.reviewDocuments = result;
        if (this.reviewDocuments.length > 0) {
          const uniqueAttachmentCategories = [
            ...new Set(this.reviewDocuments.map((doc) => doc.reviewCategoryID)),
          ];
          uniqueAttachmentCategories.sort((a, b) => this.utils.sort(a, b));

          uniqueAttachmentCategories.map((idCategory) => {
            const searchReviewDocumentCategory =
              this.reviewattachmentCategories.filter(
                (x) => x.id == idCategory
              )[0];
            this.reviewattachmentCategoriesFiltered.push(
              searchReviewDocumentCategory
            );
          });
        }
        if (this.reviewattachmentCategoriesFiltered.length) {
          this.reviewattachmentCategoriesFiltered?.map((attachmentCategoryType) => {
            const dataSourceCategory = new MatTableDataSource<ReviewDocuments>();
            dataSourceCategory.data = this.reviewDocuments.filter((x) => x.reviewCategoryID == attachmentCategoryType.id).sort((a, b) => this.utils.sort(a.name, b.name, true));
            if (dataSourceCategory)
              this.dataSources.push(dataSourceCategory);
          }
          );
        }
      });
  }

  async ObtainAttachmentCategories() {
    await this._service.getReviewDocumentCategories().toPromise().then((result) => {
      if (!(result?.length)) {
        result = [];
      }
      this.reviewattachmentCategories = result;
    });
  }

  addReviewDocument() {
    const dataDialog = this.review;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = dataDialog;
    dialogConfig.width = "420px";
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ReviewAttachmentComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then((data) => {
      if (data && this.review) {
        this.loading = true;
        this.uploadDocuments(data, this.review);
      }
    });
  }

  addLinkDocument() {
    const dataDialog = this.review;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = dataDialog;
    dialogConfig.minWidth = "35vw";
    dialogConfig.width = "400px";
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ReviewLinksComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then((data: ReviewDocuments) => {
      if (data && this.review) {
        this.loading = true;
        this.uploadsLinks(data, this.review);
      }
    });
  }

  public async SearchFile(rowFile: ReviewDocuments) {
    if (rowFile.type == null || rowFile.type == 1) {
      if (rowFile.fileID?.toString() != "") {
        if (rowFile) {
          const link = document.createElement("a");
          link.setAttribute("target", "_blank");
          link.setAttribute("href", rowFile.url ?? '');
          link.setAttribute("download", rowFile.name ?? '');
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          console.log(
            "Error: Could not get file information (SearchFile function in review-attachment-documents component)"
          );
        }
      }
    } else {
      if (rowFile != null) {
        const link = document.createElement("a");
        link.setAttribute("target", "_blank");
        link.setAttribute("href", rowFile.url ?? '');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.log(
          "Error: Could not get link information (SearchFile function in review-attachment-documents component)"
        );
      }
    }
  }

  manageCategories() {
    const dataDialog = this.review;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = dataDialog;
    dialogConfig.minWidth = "35vw";
    dialogConfig.maxHeight = "85%";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(
      ReviewAttachmentTypesComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((data) => {
      if (data != undefined) {
        this.loading = true;
      }
    });
  }

  deleteReviewDocument(element: any) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "400px",
      data: {
        message: this.getMessage("BRC_Question_Delete_File").description,
        icon: "stop",
      },
    });
    confirm
      .afterClosed()
      .toPromise()
      .then(
        async (data) => {
          if (data != null) {
            this.loading = true;
            if (element.type == null || element.type == 1) {
              await this.deleteCommentFileServer(element.fileID, element.id);
            } else {
              await this.deleteLinkFile(element.id);
            }
          }
        },
        (error) => { }
      )
      .finally(() => {
        this.loadReviewDocuments();
        this.loading = false;
      });
  }

  async deleteCommentFileServer(fileId: number, documentId: number) {
    await this.file2Service.delete(fileId).toPromise().then((async) => {
      console.log(fileId.toString());
      this.deleteCommentFile(documentId);
      this.alert.success(this.getMessage("BRC_Confirmation_Deleted_File").description);
    },
      (error) => {
        this.alert.error("Error !! The attached file could not be removed!!");
        console.error(error);
      }
    );
  }

  async deleteLinkFile(documentId: number) {
    const promise = new Promise(async (resolve, reject) => {
      this.deleteCommentFile(documentId);
      this.alert.success(this.getMessage("BRC_Confirmation_Deleted_File").description);
      resolve(true);
    })
      .catch(() => {
        this.alert.error("Error !! The attached link could not be removed!!");
        console.error("Error !! The attached link could not be removed!!");
      })
      .finally(() => { });
    return promise;
  }

  async deleteCommentFile(fileId: number) {
    await this._service.deleteReviewDocument(fileId).toPromise().then(() => {
      console.log(fileId.toString());
    },
      (error) => {
        console.error(error);
      }
    );
  }

  obtainIcon(fileExtension: string): string {
    const pathIcon: string =
      "../../assets/icons/files/" +
      fileExtension.replace(".", "").toLocaleLowerCase() +
      ".png";
    return pathIcon;
  }

  uploadsLinks(attachedLink: ReviewDocuments, reviewActual: Review) {
    const promise = new Promise(async (resolve, reject) => {
      const attachedReviewDocuments: ReviewDocuments[] = [];
      attachedReviewDocuments.push(attachedLink);
      await this._service.createReviewDocuments(attachedReviewDocuments).toPromise().then((result) => {
        if (result) {
          const filesAdded: number = result;
          this.alert.success(this.getMessage("BRC_Confirmation_Attached_File").description);
          this.loadReviewDocuments();
        }
      });
      resolve(attachedReviewDocuments);
    }).finally(() => {
      this.loading = false;
    });
    return promise;
  }

  uploadDocuments(files: any[], reviewActual: Review) {
    const promise = new Promise(async (resolve, reject) => {
      const attachedReviewDocuments: ReviewDocuments[] = [];
      let fileAdded: FileV2 = new FileV2();
      const size = 0;
      this.setPath("Review", reviewActual.name ?? '');

      for (let i = 0; i < files.length; i++) {
        this.fileToUpload = files[i];
        const categoryId: number = files[i].CategoryId;

        // Save the file in the file server and obtain the id of the record of FileV2 table
        await this.getIdFileServer(this.fileToUpload).then((res) => {
          this.fileIdServer = res;
        });

        await this.ObtainFile(this.fileIdServer).then((res) => {
          fileAdded = res;
        });

        const attachedDocument: ReviewDocuments = {
          name: this.fileToUpload.name,
          fileID: this.fileIdServer,
          reviewID: this.review?.id,
          reviewCategoryID: categoryId,
          code: fileAdded.extension,
          url: fileAdded.url,
          id: 0,
          description: "Document of  " + reviewActual.name,
          type: 1,
          status: 1,
          size:
            this.utils.numberWithCommas(Math.round(this.fileToUpload.size / 1024)) +
            " Kb",
        } as ReviewDocuments;

        attachedReviewDocuments.push(attachedDocument);
      }

      await this._service
        .createReviewDocuments(attachedReviewDocuments)
        .toPromise()
        .then((result) => {
          if (result) {
            const filesAdded: number = result;
            this.alert.success(this.getMessage("BRC_Confirmation_Attached_File").description);
            this.loadReviewDocuments();
          }
        });
      resolve(attachedReviewDocuments);
    }).finally(() => {
      this.loading = false;
    });
    return promise;
  }

  setPath(folderName: string, holderName: string) {
    this.path = "/" + folderName + "/" + holderName + "/Files/";
  }

  async getIdFileServer(file: File) {
    const promise = new Promise<number>(async (resolve) => {
      const form = new FormData();
      let idFileServer: number = 0;

      form.append("file", file);
      form.append("path", this.path + file.name);

      await this.file2Service.createFiles(form).toPromise().then((file: FileV2) => {
        idFileServer = file.id;
        this.fileIdServer = file.id;
      },
        (error) => {
          idFileServer = 0;
          this.fileIdServer = 0;
          console.error(error);
        }
      );
      resolve(idFileServer);
    });
    return promise;
  }

  async ObtainFile(fileId: number) {
    const promise = new Promise<FileV2>(async (resolve) => {
      let fileSearched: FileV2 | undefined = undefined;

      await this.file2Service.obtainFile(fileId).toPromise().then((file: FileV2) => {
        fileSearched = file;
      },
        (error) => {
          fileSearched = undefined;
          console.error(error);
        }
      );
      if (fileSearched)
        resolve(fileSearched);
    });
    return promise;
  }
}
