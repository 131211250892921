<div class="card">
  <div class="card-header">
    <span class="card-title"
      >{{ resource.id ? "Edit" : "Add" }} Endstation</span
    >
  </div>
  <div class="card-body">
    <mat-form-field>
      <mat-label>Endstation Name</mat-label>
      <input
        matInput
        [(ngModel)]="endstationName"
        placeholder="Endstation Name"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>ABSI Type</mat-label>
      <mat-select
        [(value)]="absiType"
        (selectionChange)="absiTypeChange($event.value)"
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-option *ngFor="let absiType of absiTypes" [value]="absiType">
          {{ absiType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="isSaving"
        (click)="add()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
