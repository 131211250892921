import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormVersionSave, FormVersion } from './form-version';

@Injectable({
  providedIn: 'root'
})
export class FormVersionService {
  url = environment.urls.baseApiUrl;
  page = '/FormVersion';

  constructor(
    public http: HttpClient,
  ) { }

  update(formVersion: FormVersionSave): Observable<FormVersion> {
    return this.http.put<FormVersion>(this.url + this.page, formVersion);
  }

  read(id: number): Observable<FormVersion> {
    return this.http.get<FormVersion>(this.url + this.page + '/' + id.toString());
  }

  readActiveByForm(formID: number): Observable<FormVersion> {
    return this.http.get<FormVersion>(this.url + this.page + '/Active/Form/' + formID.toString());
  }

  newVersion(id: number): Observable<FormVersion> {
    return this.http.put<FormVersion>(this.url + this.page + '/NewVersion/' + id.toString(), null);
  }
}
