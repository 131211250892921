import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProcedureReport } from '../models/procedure-report.model';

@Injectable({
  providedIn: 'root'
})
export class ProcedureReportService {
  private url: string = environment.urls.baseApiUrl + '/ProcedureCredited';

  constructor(
    private http: HttpClient) { }

  public read(): Observable<ProcedureReport[]> {
    return this.http.get<ProcedureReport[]>(this.url + '/ProcedureReport');
  }

  public readByUserID(userID: number): Observable<ProcedureReport[]> {
    return this.http.get<ProcedureReport[]>(this.url + '/ProcedureReport/' + userID);
  }

}
