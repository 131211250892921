import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserRole } from './user-role';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  constructor(private http: HttpClient, public authentication: AuthenticationService) { }

  Read(userID?: number | null, roleID?: number): Observable<UserRole[]> {
    let url: string;
    let first = false;

    url = environment.urls.baseApiUrl + '/UserRoles';

    if (userID != null || roleID != null) {
      url += '?';
    }

    if (userID != null) {
      if (!first) {
        first = true;
      } else {
        url += '&';
      }

      url += 'userID=' + userID.toString();
    }

    if (roleID != null) {
      if (!first) {
        first = true;
      } else {
        url += '&';
      }

      url += 'roleID=' + roleID.toString();
    }

    return this.http.get<UserRole[]>(url);
  }

  Create(userRole: UserRole): Observable<UserRole> {
    return this.http.post<UserRole>(environment.urls.baseApiUrl + '/UserRoles', userRole);
  }

  Delete(id: number): Observable<any> {
    return this.http.delete<any>(environment.urls.baseApiUrl + '/UserRoles/' + id.toString());
  }
}
