<div class="card">
  <div class="card-header">
    <span class="card-title">{{ label }}</span>
  </div>
  <div class="card-body">
    <mat-form-field>
      <textarea
        aria-label="textarea"
        #textarea
        matInput
        [(ngModel)]="text"
        [ngStyle]="{ 'min-height': '300px' }"
      ></textarea>
    </mat-form-field>
    <!-- <form>
      <mat-form-field>
        <mat-label>{{ label }}</mat-label>
        <input
          matInput
          type="{{ inputType }}"
          name="reason"
          [(ngModel)]="text"
        />
      </mat-form-field>
    </form> -->
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="text && text.trim() === ''"
        (click)="accept()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
