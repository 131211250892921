import { ReviewCycle } from '../../reviews.models';

export class ReviewCyclesRefresh {
  static readonly type = '[ReviewCycles] Refresh';
}

export class ReviewsCyclesRefreshById {
  static readonly type = '[ReviewCycles] RefreshById';
  constructor(public id: number) { }
}
export class ReviewCyclesCreate {
  static readonly type = '[ReviewCycles] Create';

  constructor(public item: ReviewCycle) { }
}

export class ReviewCyclesUpdate {
  static readonly type = '[ReviewCycles] Update';

  constructor(public id: number, public item: ReviewCycle) { }
}

export class ReviewCyclesDelete {
  static readonly type = '[ReviewCycles] Delete';
  constructor(public id: number) { }
}
