import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'reject-dialog',
  templateUrl: './reject-dialog.component.html',
  styleUrls: ['./reject-dialog.component.scss']
})
export class RejectDialogComponent implements OnInit {
  title: string = '';
  warning: string = '';
  placeholder: string = '';
  icon!: boolean;

  constructor(
    public dialog: MatDialogRef<RejectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.data.accept = false;
    this.data.cancel = false;
    this.title = this.data.title;
    this.warning = this.data.waring;
    this.icon = this.data.icon;
    this.placeholder = this.data.placeholder;
  }

  cancel() {
    this.data.accept = false;
    this.data.cancel = true;
    this.dialog.close(this.data);
  }

  accept() {
    this.data.accept = true;
    this.data.cancel = false;
    this.dialog.close(this.data);
  }

}
