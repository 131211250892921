<div class="card">
  <div class="card-header">
    <span class="card-title">Complete Action Item</span>
    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="card-body">
    <comments-box
      [noSubscript]="true"
      [title]="'Complete Action Item Comment'"
      [textValue]="completeComment"
      [disabled]="false"
      [disabled]="isLoading"
      [formType]="'RCP'"
      [serialNo]="reviewActionItem.review.reviewCycle.serialNo"
      [hasError]="hasError"
      (textChanged)="commentChanged($event)"
      [minHeight]="'150px'"
    ></comments-box>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-button
        color="warn"
        class="mr-2"
        [disabled]="isLoading"
        (click)="close()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="hasError || isLoading"
        (click)="complete()"
      >
        Complete
      </button>
    </div>
  </div>
</div>
