import { Component, Inject, OnInit, OnDestroy, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReviewActionItemType, ReviewActionItem } from '../../reviews.models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ReviewsService } from '../../reviews.service';
import { utils } from 'src/app/modules/libs/utils';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';

@Component({
  selector: 'reviews-action-item-types',
  templateUrl: './reviews-action-item-types.component.html',
  styleUrls: ['./reviews-action-item-types.component.scss']
})
export class ReviewsActionItemTypesComponent extends BaseComponent implements OnInit, OnDestroy {

  reviewActionItemType!: ReviewActionItemType | null;
  reviewActionItemTypes!: ReviewActionItemType[];
  reviewActionItemTypes$!: Observable<ReviewActionItemType[]>;
  reviewActionItemTypesSubs!: Subscription;

  reviewActionItems!: ReviewActionItem[];
  reviewActionItems$!: Observable<ReviewActionItem[]>;
  reviewActionItemsSubs!: Subscription;

  reviewActionItemTypeNameCtrl = new FormControl();
  reviewActionItemTypeDescriptionCtrl = new FormControl();
  reviewActionItemTypeCodeCtrl = new FormControl();

  isStopper!: boolean;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<ReviewsActionItemTypesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: ReviewsService
  ) {
    super(injector);
    this.buildForm();
  }

  override ngOnDestroy(): void {
    this.reviewActionItemsSubs?.unsubscribe();
    this.reviewActionItemTypesSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.reviewActionItems$ = this.store.select(state => state.ReviewActionItems.data);
    this.reviewActionItemsSubs = this.reviewActionItems$.subscribe(data => {
      this.reviewActionItems = data;
      this.filterReviewActionItems();
    });

    this.reviewActionItemTypes$ = this.store.select(state => state.ReviewActionItemTypes.data);
    this.reviewActionItemTypesSubs = this.reviewActionItemTypes$.subscribe(data => {
      this.reviewActionItemTypes = data.sort((a, b) => a.order - b.order);
      this.filterReviewActionItems();
    });
  }

  filterReviewActionItems() {
    if (this.reviewActionItems && this.reviewActionItemTypes) {
      this.reviewActionItemTypes.map(r => {
        r.reviewActionItems = this.reviewActionItems.filter(x => x.reviewActionItemTypeID == r.id);
      });
    }
  }

  buildForm() {
    this.formGroup = new FormGroup({
      reviewActionItemTypeNameCtrl: this.reviewActionItemTypeNameCtrl,
      reviewActionItemTypeDescriptionCtrl: this.reviewActionItemTypeDescriptionCtrl,
      reviewActionItemTypeCodeCtrl: this.reviewActionItemTypeCodeCtrl,
    });
    this.reviewActionItemTypeNameCtrl.setValue(null);
    this.reviewActionItemTypeDescriptionCtrl.setValue(null);
    this.reviewActionItemTypeCodeCtrl.setValue(null);
  }

  drop(event: CdkDragDrop<ReviewActionItemType[]>) {
    if (event?.previousIndex !== event?.currentIndex) {
      moveItemInArray(this.reviewActionItemTypes, event.previousIndex, event.currentIndex);
      this.renumberArray();
    }
  }

  renumberArray() {
    let order = 1;
    this.reviewActionItemTypes?.map(reviewActionItemType => {
      if (reviewActionItemType.order != order) {
        reviewActionItemType.order = order;
        this.service.updateReviewActionItemType(reviewActionItemType).subscribe(data => {
        });
      }
      order++;
    });
  }

  close() {
    this.dialogRef.close();
  }

  add() {
    this.buildForm();
    this.reviewActionItemType = {
      id: 0,
      order: 999
    } as ReviewActionItemType;
  }

  edit(reviewActionItemType: ReviewActionItemType) {
    this.reviewActionItemType = reviewActionItemType;
    this.reviewActionItemTypeNameCtrl.setValue(this.reviewActionItemType.name);
    this.reviewActionItemTypeDescriptionCtrl.setValue(this.reviewActionItemType.description);
    this.reviewActionItemTypeCodeCtrl.setValue(this.reviewActionItemType.code);
    this.isStopper = this.reviewActionItemType.isStopper;
  }

  save() {
    if (this.reviewActionItemType) {
      this.reviewActionItemType.name = this.reviewActionItemTypeNameCtrl.value;
      this.reviewActionItemType.description = this.reviewActionItemTypeDescriptionCtrl.value;
      this.reviewActionItemType.code = this.reviewActionItemTypeCodeCtrl.value;
      this.reviewActionItemType.isStopper = this.isStopper;

      if (this.reviewActionItemType.id === 0) {
        this.service.createReviewActionItemType(this.reviewActionItemType).toPromise().then(data => {
          this.afterSave();
        });
      }
      else {
        this.service.updateReviewActionItemType(this.reviewActionItemType).toPromise().then(data => {
          this.afterSave();
        });
      }
    }
  }

  afterSave() {
    this.renumberArray();
    this.reviewActionItemType = null;
    this.alert.message('update', [new MessagePlaceholder('{what}', 'Action Item Categories')]);
    this.dialogRef.close();
  }

  delete(reviewActionItemType: ReviewActionItemType) {
    this.service.deleteReviewActionItemType(reviewActionItemType.id).toPromise().then(() => {
      this.renumberArray();
      this.reviewActionItemType = null;
    });
  }

  cancel() {
    this.reviewActionItemType = null;
  }

  formValid() {
    return !utils.isNullValue(this.reviewActionItemTypeNameCtrl.value) && !utils.isNullValue(this.reviewActionItemTypeDescriptionCtrl.value) && !utils.isNullValue(this.reviewActionItemTypeCodeCtrl.value);
  }

  isStopperChanged(e: any) {
    this.isStopper = e.checked;
    this.formGroup.markAsDirty();
  }

}
