// decorator.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ComponentRegistry {
  components?: { [name: string]: any };

  registerComponent(name: string, component: any) {
    if (!this.components) this.components = {};
    this.components[name] = component;
    // console.log(this.components);
  }
}

export function RegisterComponent(name: string) {
  return function (target: any) {
    ComponentRegistry.prototype.registerComponent(name, target);
  }
}
