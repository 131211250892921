import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Form } from 'src/app/services/forms/form';

@Component({
  selector: 'ppstb-main-s2',
  templateUrl: './ppstb-main-s2.component.html',
  styleUrls: ['./ppstb-main-s2.component.scss']
})
export class PpstbMainS2Component {

  @Input() form?: Form | null;
  @Input() disabled!: boolean;
  @Input() showAmendment!: boolean;

  @Output() loading = new EventEmitter<boolean>();

}
