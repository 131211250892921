import { APLCategoryManager } from "./apl-category-manager.model";
import { APLCategoryMaster } from "./apl-category-master.model";
import { APLGroup } from "./apl-group.model";

export class APLCategory {
  public id!: number;
  public name!: string;
  public code!: string;
  public status!: number;
  public aplCategoryMasterID!: number;
  public previousVersionID!: number;
  public version!: number;
  public createdBy!: number;
  public aplCategoryMaster!: APLCategoryMaster;
  public aplCategoryManagers: APLCategoryManager[];
  public previousVersion!: APLCategory;
  public newVersion?: APLCategory;
  public aplGroups?: APLGroup[];

  public open?: boolean;
  public pendingGroups?: boolean;

  constructor() {
    this.aplCategoryManagers = [];
    this.aplGroups = [];
  }
}
