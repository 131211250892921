<fieldset class="field-group">
  <legend>Options</legend>
  <div class="row no-gutters pb-1">
    <div class="col-11">Label</div>
    <div class="col-1 small">
      <div *ngIf="!disabled" class="btnx" (click)="add()">
        <mat-icon color="primary">add</mat-icon>
      </div>
    </div>
  </div>

  <div class="row no-gutters" *ngFor="let o of tmpOptions">
    <div class="col-11 pr-1">
      <mat-form-field class="no-subscript">
        <input
          [disabled]="disabled ?? false"
          matInput
          type="text"
          [(ngModel)]="o.label"
          (keyup)="change()"
        />
      </mat-form-field>
    </div>
    <div class="col-1 small">
      <div *ngIf="!disabled" class="btnx" (click)="del(o)">
        <mat-icon color="warn">delete</mat-icon>
      </div>
    </div>
  </div>
</fieldset>
