<form [formGroup]="formGroup">
  <mat-form-field
    appearance="outline"
    class="no-subscript"
    [ngClass]="{
      hilite: hilite
    }"
  >
    <mat-label>{{ label ? label : "ASCC Links" }}</mat-label>
    <mat-chip-grid
      #chipListDoc
      aria-label="Document selection"
      class="matchiplistLinks"
      formControlName="documentCtrl"
    >
      <mat-chip-row
        *ngFor="let document of relatedDocuments"
        [selectable]="!disabled"
        [removable]="!disabled && !document.disabled"
        (removed)="removeDoc(document)"
        [ngClass]="document.cssClass"
      >
        <span
          class="btn-link"
          (click)="openDocument(document)"
          [ngClass]="{
            canceledRD:
              document.relatedDocumentStatus == formStatusEnum.Canceled,
            closedRD: document.relatedDocumentStatus == formStatusEnum.Closed
          }"
          >{{ document.relatedDocumentSerialNo }}</span
        >
        <mat-icon
          matChipRemove
          *ngIf="!disabled && !document.disabled"
          class="d-print-none"
          >cancel</mat-icon
        >
      </mat-chip-row>

      <input
        matInput
        aria-label="relatedforms"
        #documentInput
        [matAutocomplete]="autoDoc"
        [matChipInputFor]="chipListDoc"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addDoc($event)"
        (keyup)="onDocChange($event)"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #autoDoc="matAutocomplete"
      (optionSelected)="selectedDoc($event)"
    >
      <mat-optgroup
        *ngFor="let type of documentTypes"
        [label]="type.description ?? type.name ?? ''"
      >
        <mat-option
          *ngFor="let document of filterTypeDocuments(type.documents)"
          [value]="document"
          class="option"
        >
          <span
            [ngClass]="document.cssClass"
            class="bold"
            [innerHTML]="document.serialNo ?? '' | highlightSearch : filter"
          ></span>
          <span
            class="small description ml-2"
            *ngIf="!disabled && document?.description"
            [matTooltip]="document?.description ?? ''"
            matTooltipPosition="right"
            matTooltipClass="tooltip-white tooltip-wide"
            [matTooltipDisabled]="disableTooltips"
            [innerHTML]="document?.description ?? '' | highlightSearch : filter"
          >
          </span>
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
    <!-- <mat-icon
            matSuffix
            class="search-icon"
            (click)="openSearchRel($event); $event.stopPropagation()"
            *ngIf="!disabled"
            >search</mat-icon
          > -->
  </mat-form-field>
</form>
