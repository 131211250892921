import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { RoleCheck } from 'src/app/common/models/role-check';
import { Role } from 'src/app/components/catalogs/roles/services/role';

@Component({
  selector: 'app-choose-roles',
  templateUrl: './choose-roles.component.html',
  styleUrls: ['./choose-roles.component.scss']
})
export class ChooseRolesComponent implements OnInit, OnDestroy {
  roles: RoleCheck[] = [];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  usersTable = new MatTableDataSource(this.roles);
  loading = false;
  displayedColumns: string[] = ['choose', 'name'];
  result: RoleCheck[] = [];

  constructor(
    public dialogRef: MatDialogRef<ChooseRolesComponent>,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: number[]) { }

  ngOnDestroy(): void {
    this.rolesSubs?.unsubscribe();
  }

  ngOnInit() {
    this.loadRoles();
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      this.roles = data.map(val => {
        const valR = (val as any as RoleCheck);
        valR.isCheck = false;
        if (this.data) {
          if (this.data.includes(valR.id)) {
            valR.isCheck = true;
            this.result.push(valR);
          }
        }
        return valR;
      });
      this.usersTable.data = this.roles;
      this.loading = false;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.result);
  }

  checking(value: boolean, element: RoleCheck) {
    if (value) {
      this.result.push(element);
    } else {
      this.removeItem(this.result, element);
    }
  }

  removeItem(array: any, value: any): any {
    const index: number = array.indexOf(value);
    array.splice(index, 1);
    return index;
  }

  getRecord(row: any) {
    console.log(row);
  }
}
