<div class="container-fluid p-0">
  <div class="d-flex flex-wrap justify-content-between">
    <!-- <h6 class="card-title">Shielding Control Forms</h6> -->
    <span class="small ml-auto mb-2"
      ><mat-slide-toggle
        color="warn"
        (change)="changeClosed()"
        [(ngModel)]="showClosed"
        >Show Closed/Canceled</mat-slide-toggle
      ></span
    >
  </div>
</div>
<div class="side-panel-table">
  <div class="left-table-container table-container mat-container scrollbar">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="serialNo">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="pl-16">
          Serial No.
        </mat-header-cell>
        <mat-cell
          class="font-weight-bold pl-16"
          [ngClass]="element.formStatus.color"
          *matCellDef="let element"
          nowrap
          ><span
            [innerHTML]="element.serialNo | highlightSearch : filter"
          ></span>
          <mat-icon class="editing" color="accent" *ngIf="element.editingBy"
            >edit</mat-icon
          >
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="locationNames">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="min-130">
          Affected Systems
        </mat-header-cell>
        <mat-cell
          class="pl-1"
          [ngClass]="element.formStatus.color"
          *matCellDef="let element"
        >
          <span
            [innerHTML]="
              formatLocationNames(element.locationNames)
                | highlightSearch : filter
            "
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="statusName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </mat-header-cell>
        <mat-cell
          class="pl-1"
          [ngClass]="element.formStatus.color"
          *matCellDef="let element"
        >
          <span
            [innerHTML]="element.formStatus.name | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        #matrow
        *matRowDef="let row; columns: displayedColumns"
        (click)="rowClicked(row)"
        class="table-row"
        [matTooltip]="row.description"
        [matTooltipPosition]="'right'"
        matTooltipClass="tooltip-white tooltip-wide"
        [ngClass]="{ 'mat-row-highlight': selectedRowIndex == row.id }"
        [attr.position]="getPosition(row)"
      ></mat-row>
    </mat-table>
  </div>
</div>
