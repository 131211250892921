
export const urls = {
  production: true,
  ologUrl: location.origin === 'https://ascc.als.lbl.gov' ? 'https://controls.als.lbl.gov/olog/rpc.php' : 'https://controls.als.lbl.gov/olog_test/rpc.php',
  baseApiUrl:
    location.origin === 'https://ascc.als.lbl.gov' ? 'https://ascc.als.lbl.gov:8443/api' : // PROD
      location.origin === 'https://ascc-stg.als.lbl.gov' ? 'https://ascc-stg.als.lbl.gov:8443/api' : // stg
        location.origin === 'https://ascc-dev.als.lbl.gov' ? 'https://ascc-dev.als.lbl.gov:8443/api' : // dev
          location.origin === 'http://localhost:4200' ? 'http://localhost:58894/api' : // Local
            location.origin === 'http://localhost:58773' ? 'http://localhost:58895/api' : // Local
              '',
  pvwsURL: ''

};
