<div class="card">
  <div class="card-header">
    <span class="card-title">Component Help</span>
    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="card-body px-3 pb-1 pt-2" *ngIf="component">
    <comments-box
      [textValue]="component.helpText"
      formType="editor"
      serialNo="component"
      [noBorder]="true"
      (textChanged)="changed($event)"
    ></comments-box>
  </div>
</div>
