<div class="card section p-0">
	<div class="card-header">
		<div class="row">
			<div class="col-6 d-flex">
				<h6 [innerHTML]="title ?? '' | placeholderHighlight : editor"></h6>
				<role-chips [roleCodes]="roleCodes" class="ml-3"></role-chips>
			</div>
			<div class="col-6">
				<div *ngIf="!disabled" class="btn mr-1 float-right" (click)="add()">
					<mat-icon>add</mat-icon>
				</div>
			</div>
		</div>
	</div>
	<div class="card-body">
		<catl
			[disabled]="disabled"
			[builder]="builder"
			[formType]="formType"
			[formId]="formId"
			[sectionNumber]="sectionNumber"
			[sectionId]="sectionId"
			[taskId]="taskID"
			[showLabel]="false"
			[serialNo]="serialNo"
			(catlChanged)="catlChanged($event)"
		>
		</catl>
	</div>
</div>
