import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import * as ProcedureCategoryActions from './procedure-category.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';

@Injectable({
  providedIn: 'root'
})
export class ProcedureCategoryStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore() {
    this.store.dispatch(new ProcedureCategoryActions.ProcedureCategoryRefresh());
  }

  initListeners() {
    // ABSI
    this.signalRService.hubConnection?.on(Channels.PROCEDURECATEGORY, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new ProcedureCategoryActions.ProcedureCategoryCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new ProcedureCategoryActions.ProcedureCategoryUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new ProcedureCategoryActions.ProcedureCategoryDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new ProcedureCategoryActions.ProcedureCategoryRefresh());
      }
    });

  }
}
