import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuItem, MenuItemOrderList, MenuItemType } from 'src/app/components/head-page/head-menu/head-menu.models';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class MenuService {

  url = environment.urls.baseApiUrl;
  page = '/Menu';
  role = '/Roles';
  type = '/Types';
  ItemRoles = '/MenuItemsRoles';
  menuItem?: MenuItem;

  private menuItemActual = new BehaviorSubject(this.menuItem);
  currentItem = this.menuItemActual.asObservable();
  constructor(public http: HttpClient) { }

  setMenuItem(item?: MenuItem) {
    this.menuItemActual.next(item);
  }

  getAll(): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(this.url + this.page);
  }

  // getAllRoles(): Observable<Role[]> {
  //   return this.http.get<Role[]>(this.url + this.role);
  // }

  getAllTypes(): Observable<MenuItemType[]> {
    return this.http.get<MenuItemType[]>(this.url + this.page + this.type);
  }

  createNewMenuOption(optionMenu: MenuItem): Observable<MenuItem> {
    return this.http.post<MenuItem>(this.url + this.page + '/New', optionMenu);
  }

  // AddNewMenuOption(optionMenu: MenuItem): Observable<MenuItem> {
  //   return this.http.post<MenuItem>(this.url + this.page + '/Add', optionMenu);
  // }

  deleteMenuOption(id: number): Observable<any> {
    return this.http.delete<any>(this.url + this.page + '/' + id);
  }

  updateMenuOption(optionMenu: MenuItem): Observable<any> {
    return this.http.put<any>(this.url + this.page + '/Update', optionMenu);
  }

  updateMenuOptions(optionMenus: MenuItem[]): Observable<any> {
    return this.http.put<any>(this.url + this.page + '/UpdateArray', optionMenus);
  }

  // updateMenuStatus(optionMenu: MenuItem): Observable<any> {
  //   return this.http.put<any>(this.url + this.page + '/UpdateMenuStatus', optionMenu);
  // }

  // UpdateMenuOrder(orderList: MenuItemOrderList[]): Observable<any> {
  //   return this.http.put<any>(this.url + this.page + '/UpdateMenuOrder', orderList);
  // }

  getMenuItemRolesById(id: number): Observable<any> {
    return this.http.get<any>(this.url + this.ItemRoles + '/Menu/' + id);
  }
}
