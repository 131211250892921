import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter, Injector } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
// import { Privilege, RolePrivilege, RolePrivilegePending } from 'src/app/_interface/RolePrivilege';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { RolePrivilegeService } from 'src/app/services/role-privilege/role-privilege.service';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { RolePrivilegePending, RolePrivilege, Privilege } from 'src/app/components/catalogs/roles/services/role-privilege';
import { RolePrivilegeAddComponent } from './role-privilege-add/role-privilege-add.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { utils } from 'src/app/modules/libs/utils';
import { PrivilegeCategoryRefresh } from 'src/app/services/role-privilege/store/privilege-categories/privilege-categories.action';

@Component({
  selector: 'role-privilege',
  templateUrl: './role-privilege.component.html',
  styleUrls: ['./role-privilege.component.scss']
})
export class RolePrivilegeComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() role?: Role;
  @Input() modifying!: boolean;
  @Input() showChanges!: boolean;

  @Output() rolePrivilegesPending = new EventEmitter<RolePrivilegePending[]>()

  rolePrivilege!: RolePrivilege;
  rolePrivileges!: RolePrivilege[];
  rolePrivilegesOriginal!: RolePrivilege[];
  rolePrivileges$!: Observable<RolePrivilege[]>;
  rolePrivilegesSubs!: Subscription;

  privilege!: Privilege;
  privileges!: Privilege[];
  privileges$!: Observable<Privilege[]>;
  privilegesSubs!: Subscription;

  documentTypes!: DocumentType[];
  documentTypes$!: Observable<DocumentType[]>;
  documentTypesSubs!: Subscription;

  showChangesToggle!: boolean;

  public displayedColumns: string[] = ['type', 'name', 'action'];

  public dataSource: MatTableDataSource<RolePrivilege> = new MatTableDataSource();

  constructor(
    protected override injector: Injector,
    private service: RolePrivilegeService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.showChanges) {
      this.showChangesPending();
    }
    else {
      this.loadRolePrivileges();
    }
  }

  override ngOnDestroy(): void {
    this.rolePrivilegesSubs?.unsubscribe();
    this.privilegesSubs?.unsubscribe();
    this.documentTypesSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.loadPrivileges();
    this.loadDocumentTypes();
    this.store.dispatch(new PrivilegeCategoryRefresh());
  }

  loadPrivileges() {
    this.privileges$ = this.store.select(state => state.Privileges.data);
    this.privilegesSubs = this.privileges$.subscribe(data => {
      this.privileges = data;
    });
  }

  loadRolePrivileges() {
    this.rolePrivileges$ = this.store.select(state => state.RolePrivileges.data);
    this.rolePrivilegesSubs = this.rolePrivileges$.subscribe(data => {
      this.rolePrivileges = data.filter(x => x.roleID == this.role?.id);
      this.rolePrivileges.map(r => {
        const privilegeObj = this.privileges.find(p => p.id == r.privilege);
        if (privilegeObj) {
          r.privilegeObj = privilegeObj;
        }
      });
      this.rolePrivileges = this.rolePrivileges.filter(x => x.privilegeObj).sort(utils.sortByMultipleFields<RolePrivilege>(['privilegeObj.privilegeCategory.name', 'privilegeObj.name']));
      this.rolePrivilegesOriginal = this.rolePrivileges;
      this.dataSource = new MatTableDataSource(this.rolePrivileges);
    });
  }

  loadDocumentTypes() {
    this.documentTypes$ = this.store.select(state => state.DocumentType.data);
    this.documentTypesSubs = this.documentTypes$.subscribe(data => {
      this.documentTypes = data;
    });
  }

  getRowSpan(e: RolePrivilege) {
    return this.rolePrivileges.filter(x => x.privilegeObj?.privilegeCategoryID == e.privilegeObj?.privilegeCategoryID).length;
  }

  isFirstElem(e: RolePrivilege) {
    const group = this.rolePrivileges.filter(x => x.privilegeObj?.privilegeCategoryID == e.privilegeObj?.privilegeCategoryID);
    return group.findIndex(x => x.privilege == e.privilege) == 0;
  }

  delete(e: any) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure to delete this Privilege from the Role ?',
        icon: 'warn'
      }
    });
    confirm.afterClosed().subscribe(res => {
      if (res && this.role) {
        const index = this.rolePrivileges.findIndex(x => x.id == e.id);
        this.rolePrivileges.splice(index, 1);
        const rolePrivilegesPending = {
          roleID: this.role?.id,
          privilegesJson: utils.JSONstringify(this.rolePrivileges),
          status: 1
        } as RolePrivilegePending;
        this.role.rolePrivilegesPending = [];
        this.role.rolePrivilegesPending.push(rolePrivilegesPending);
        this.rolePrivilegesPending.emit(this.role.rolePrivilegesPending);
        this.showChanges = true;
        this.showChangesPending();
      }
    });
  }

  openAddRolePrivilege() {
    const dialogData = {
      rolePrivileges: this.rolePrivileges
    };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.data = dialogData;
    const dialogRef = this.dialog.open(RolePrivilegeAddComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data: Privilege) => {
      if (data && this.role) {
        const rolePrivilege = {
          roleID: this.role.id,
          privilege: data.id,
          status: 1,
          privilegeObj: this.privileges.find(x => x.id == data.id),
        } as RolePrivilege;
        this.rolePrivileges.push(rolePrivilege);
        const rolePrivilegesPending = {
          roleID: this.role.id,
          privilegesJson: utils.JSONstringify(this.rolePrivileges),
          status: 1
        } as RolePrivilegePending;
        this.role.rolePrivilegesPending = [];
        this.role.rolePrivilegesPending.push(rolePrivilegesPending);
        this.rolePrivilegesPending.emit(this.role.rolePrivilegesPending);
        this.showChanges = true;
        this.showChangesPending();
      }
    });
  }

  showChangesPending() {
    if (this.role?.rolePrivilegesPending?.length) {
      this.rolePrivileges = utils.JSONparse(this.role.rolePrivilegesPending[0].privilegesJson);
      this.rolePrivileges.map(r => {
        const privilegeObj = this.privileges.find(p => p.id == r.privilege);
        if (privilegeObj) {
          r.privilegeObj = privilegeObj;
        }
        r.pending = !this.rolePrivilegesOriginal.find(x => x.id == r.id);
      });
      this.rolePrivileges = this.rolePrivileges.filter(x => x.privilegeObj).sort(utils.sortByMultipleFields<RolePrivilege>(['privilegeObj.privilegeCategory.name', 'privilegeObj.name']));
      this.dataSource = new MatTableDataSource(this.rolePrivileges);
    }
  }
}
