<div class="card">
  <div class="card-header d-flex justify-content-between">
    <h5>Placeholders</h5>
    <span class="float-right small pr-4">
      Click on a row to copy the Placeholder to the Clipboard
    </span>
    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="card-body">
    <div class="pb-3 mat-table-container">
      <div class="mat-container scrollbar">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="Name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Name
            </mat-header-cell>
            <mat-cell class="break-spaces" *matCellDef="let element">
              {{ element.name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Placeholder">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Placeholder
            </mat-header-cell>
            <mat-cell class="pl-1 placeholder" *matCellDef="let element">
              <span
                matTooltip="data from: {{ element.object }}"
                matTooltipClass="tooltip-placeholder"
                >{{ element.placeholderString }}</span
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Data">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Data
            </mat-header-cell>
            <mat-cell class="pl-1" *matCellDef="let element">
              {{ element.data }}
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            #matrow
            *matRowDef="let row; columns: displayedColumns"
            (click)="rowClicked(row)"
            style="white-space: nowrap"
            [ngClass]="{ 'mat-row-highlight': selectedRowIndex == row.id }"
            [attr.position]="getPosition(row)"
          ></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
