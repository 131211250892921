import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProcedureCredited } from '../models/procedure-credited.model';
import { ProcedureTrainingCredited } from '../models/procedure-training-credited.model';

@Injectable({
  providedIn: 'root'
})
export class ProcedureCreditedService {
  private url: string = environment.urls.baseApiUrl + '/ProcedureCredited';

  constructor(
    private http: HttpClient,
  ) { }

  read(): Observable<ProcedureCredited[]> {
    return this.http.get<ProcedureCredited[]>(this.url);
  }

  complete(procedureID: number, userID: number = 0): Observable<ProcedureCredited> {
    return this.http.post<ProcedureCredited>(this.url + '/Complete/' + procedureID.toString() + '/' + userID.toString(), null);
  }

  approve(procedureCreditedID: number, procedureTrainingCredited: ProcedureTrainingCredited): Observable<ProcedureCredited> {
    return this.http.put<ProcedureCredited>(this.url + '/Approve/' + procedureCreditedID.toString(), procedureTrainingCredited);
  }

  // Undo completion || removes training credit letting the complete option available
  removeProcedureCredited(procedureCreditedID: number): Observable<ProcedureCredited> {
    return this.http.delete<ProcedureCredited>(this.url + '/RemoveProcedureCredited/' + procedureCreditedID.toString());
  }
}
