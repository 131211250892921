import { AfterViewInit, Component, ElementRef, HostListener, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PendingCatalogColor } from 'src/app/common/enumerations/enumerations';
import { NotificationTemplate } from 'src/app/components/catalogs/notifications/services/notification-template';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @ViewChild("sidenav") sidenav!: MatSidenav;
  @ViewChild("headerTop") headerTop!: ElementRef;

  notificationTemplate?: NotificationTemplate | null;
  notificationTemplates?: NotificationTemplate[];
  notificationTemplatesFiltered?: NotificationTemplate[];
  notificationTemplates$!: Observable<NotificationTemplate[]>;
  notificationTemplatesSubs!: Subscription;

  disabled: boolean = false;

  loading = false;

  resourceRequired: boolean = false;

  filterCtrl = new FormControl();

  errorMessages: string[] = [];
  headerOnTop: boolean = false;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter: any[] = [];
  currentFilter: any;
  filter: any;

  showChanges!: boolean;

  pendingCatalog = PendingCatalogColor;

  loadingMessage!: string;
  //////

  constructor(
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.setHeights();
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit() {
    this.moduleTitle = 'Email Notification Templates';
    this.checkPrivileges();
    this.loadNotificationTemplates();
  }

  loadNotificationTemplates() {
    this.notificationTemplates$ = this.store.select(state => state.NotificationTemplates.data);
    this.notificationTemplatesSubs = this.notificationTemplates$.subscribe(data => {
      if (data?.length) {
        this.notificationTemplates = data;
        if (this.notificationTemplate) {
          this.notificationTemplate = this.notificationTemplates.find(t => t.id == this.notificationTemplate?.id);
        }
      }
    });
  }

  override ngOnDestroy(): void {
    this.notificationTemplatesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  clear() {
    this.errorMessages = [];
  }

  create() {

  }

  release() {
  }

  async setEditing(value: boolean) {
  }

  @HostListener("window:resize")
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.isExpanded = false;
    }
  }

  @HostListener("window:scroll")
  isHeaderOnTop() {
    let elemRec;
    if (this.headerTop?.nativeElement !== undefined) {
      elemRec = this.headerTop.nativeElement.getBoundingClientRect();
      const docViewTop = window.screenTop;
      const elemTop = elemRec.top;
      if (elemTop <= docViewTop) {
        this.headerOnTop = true;
      } else {
        this.headerOnTop = false;
      }
    } else {
      this.headerOnTop = false;
    }
  }

  //////
  changedFilter() { }
  clearFilter() { }
  selectedFilter() { }
  createDisabled() { }
  editDisabled() { }
  action() { }

  applyFilter(e: any) {
    if (!e)
      this.valueFilter = '';
    this.filter = e;
  }

  rowSelected(e: NotificationTemplate) {
    this.notificationTemplate = e;
    this.showChanges = false;
  }

  buttonsVisible: boolean | null = false;



  checkPrivileges() {

  }

  duplicate() {

  }



  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 2000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Buttons:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Main:
          this.loadingMain = e as boolean;
          break;
      }
      // console.log('m: ' + this.loadingMain + '   b: ' + this.loadingButtons + '   s: ' + this.loadingSide);
      this.loading = this.loadingMain || this.loadingButtons || this.loadingSide;
    }, 100);
  }

  loadingSide = false;
  loadingButtons = false;
  loadingMain = false;
  loadingOrigin = LoadingOrigin;


}

enum LoadingOrigin {
  Side = 1, Buttons = 2, Main = 3
}
