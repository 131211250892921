import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormType, ResourceType } from 'src/app/common/enumerations/enumerations';
import { FormRestriction } from 'src/app/services/forms/form-restriction';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { Restriction } from 'src/app/controls/restrictions/services/restriction';
import { FormControl } from '@angular/forms';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'restriction-available-autocomplete',
  templateUrl: './restriction-available-autocomplete.component.html',
  styleUrls: ['./restriction-available-autocomplete.component.scss']
})
export class RestrictionAvailableAutocompleteComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() formType!: FormType;
  @Input() placeholder?: string;
  @Input() disabled?: boolean | null;
  @Input() formRestrictions?: FormRestriction[];
  @Input() locationIDs?: (number | undefined)[];
  @Input() control!: FormControl;

  @Output() onError = new EventEmitter<string>();
  @Output() onRestrictionSelected = new EventEmitter<Restriction>();
  @Output() onFreeTextRestrictionWritten = new EventEmitter<string>();

  @ViewChild('restrictionInput') restrictionInput?: ElementRef<HTMLInputElement>;

  allRestrictions?: Restriction[];
  availableRestrictions?: Restriction[];
  filteredRestrictions?: Restriction[];
  uniqueRestrictions?: Restriction[];

  resources!: Resource[];
  resourcesFiltered?: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  restrictions!: Restriction[];
  restrictionsFiltered?: Restriction[];
  restrictions$!: Observable<Restriction[]>;
  restrictionsSubs!: Subscription;

  constructor(
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.clear();
    this.loadResources();
    const valid = this.control?.valid;
    if (this.disabled)
      this.control?.disable();
    else this.control?.enable();
    if (!valid)
      this.control?.setErrors({ required: true });
  }

  ngOnInit(): void {

  }

  loadResources() {
    this.resources$ = this.store.select((state) => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe((data) => {
      if (data.length) {
        this.resources = data;
        this.loadRestrictions();
      }
    });
  }

  loadRestrictions() {
    this.restrictions$ = this.store.select((state) => state.Restrictions.data);
    this.restrictionsSubs = this.restrictions$.subscribe((data) => {
      this.restrictions = data.filter((x) => x.scf && this.formType == FormType.SCF || x.rsswa && this.formType == FormType.RSSWA || x.ebf && this.formType == FormType.EBF || x.ppstb && this.formType == FormType.PPSTB);
      let availablerestrictions: Restriction[] = [];
      let resources = this.resources.filter((x) => this.locationIDs?.includes(x.id));
      resources?.map((r) => {
        switch (r.type) {
          case ResourceType.AcceleratorLocation:
            availablerestrictions = availablerestrictions.concat(
              ...this.restrictions.filter(x => r.parentResources?.map(p => p.parentResourceID).includes(x.resourceID)));
            break;
          case ResourceType.Accelerator:
            availablerestrictions = availablerestrictions.concat(
              ...this.restrictions.filter(
                (x) => x.resourceID == r.id || x.locationID == r.id
              )
            );
            r.parentResources?.map(c => {
              if (
                c.parentResourceID &&
                c.parentResourceType == ResourceType.Accelerator
              ) {
                availablerestrictions = availablerestrictions.concat(
                  ...this.restrictions.filter(
                    (x) =>
                      x.resourceID == c.parentResourceID ||
                      x.locationID == c.parentResourceID
                  )
                );
              }
            });
            break;

          case ResourceType.Beamline:
            r.parentResources?.map((c) => {
              if (c.parentResourceID) {
                availablerestrictions = availablerestrictions.concat(
                  ...this.restrictions.filter(
                    (x) =>
                      x.resourceID == c.parentResourceID ||
                      x.locationID == c.parentResourceID
                  )
                );
              }
            });

            break;

          case ResourceType.Shutter:
            availablerestrictions = availablerestrictions.concat(
              ...this.restrictions.filter(
                (x) =>
                  x.resourceID == r.id ||
                  x.locationID == r.id
              )
            );

            break;

          case ResourceType.Branchline:
            availablerestrictions = availablerestrictions.concat(
              ...this.restrictions.filter(
                (x) => x.resourceID == r.id || x.locationID == r.id
              )
            );
            r.parentResources?.map((x) => {
              if (x.parentResourceID) {
                const parentResource = this.resources.find(
                  (rr) => rr.id == x.parentResourceID
                );
                parentResource?.parentResources?.map((c) => {
                  if (c.parentResourceID) {
                    availablerestrictions = availablerestrictions.concat(
                      ...this.restrictions.filter(
                        (x) =>
                          x.resourceID == c.parentResourceID ||
                          x.locationID == c.parentResourceID
                      )
                    );
                  }
                });
              }
            });
            break;
        }
      });

      this.filteredRestrictions = availablerestrictions.filter((x) => !this.formRestrictions?.map((r) => r.restrictionID).includes(x.id));
      this.uniqueRestrictions = [...new Set(this.filteredRestrictions)];
    });
  }

  refresh(resourceIds: number[], selectedRestrictions?: (Restriction | undefined)[]) {
    this.locationIDs = resourceIds;
    this.loadResources();
  }

  onRestrictionInputKeyup(event: KeyboardEvent) {
    if (!event.code?.includes('Arrow')) {
      if (event.keyCode == 13 && this.restrictionInput?.nativeElement.value.trim() != "") {
        this.onFreeTextRestrictionWritten.emit(this.restrictionInput?.nativeElement.value.trim());
        this.clear();
      } else {
        if (this.restrictionInput != null) {
          this.filteredRestrictions = this.availableRestrictions?.filter(x => x?.restrictionLanguage?.toLocaleLowerCase()?.includes(this.restrictionInput?.nativeElement?.value?.toLocaleLowerCase() ?? ''));
        }
      }
    }
  }

  select(restriction: Restriction) {
    this.clear();
    this.onRestrictionSelected.emit(restriction);
  }

  clear() {
    if (this.restrictionInput)
      this.restrictionInput.nativeElement.value = '';
    this.filteredRestrictions = [];
    this.availableRestrictions?.map(x => {
      this.filteredRestrictions?.push(x);
    });
  }

  reset() {
    this.allRestrictions = [];
    this.clear();
  }

  // setAvailableRestrictions(restrictions: (Restriction | undefined)[]) {
  //   this.availableRestrictions = [];
  //   this.allRestrictions?.map(x => this.availableRestrictions?.push(x));
  //   restrictions.map(x => {
  //     const index = this.availableRestrictions?.map(x => x?.id).indexOf(x?.id) ?? -1;

  //     if (index >= 0) {
  //       this.availableRestrictions?.splice(index, 1);
  //     }
  //   });
  //   this.filteredRestrictions = [];
  //   this.availableRestrictions.map(x => this.filteredRestrictions?.push(x));
  // }

  blur(text: string) {
    if (text.trim() != "") {
      this.onFreeTextRestrictionWritten.emit(text);
      this.clear();
    }
  }

}
