<div class="card">
  <div class="card-header">
    <span class="card-title">Toxic Gas <span class="small">ARCHIVE</span></span>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <mat-form-field appearance="outline">
          <mat-label>Search</mat-label>
          <input (keyup)="filterHistory()" matInput [(ngModel)]="Filter" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="col col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <mat-form-field appearance="outline">
          <mat-label>Month</mat-label>
          <input
            name="Month"
            (keyup)="filterHistory()"
            maxlength="2"
            (keypress)="numberOnly($event)"
            type="text"
            matInput
            [(ngModel)]="Month"
            onDrag="return false"
            onDrop="return false"
            onPaste="return false"
          />
          <mat-hint>MM</mat-hint>
        </mat-form-field>
      </div>
      <div class="col col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <mat-form-field appearance="outline">
          <mat-label>Year</mat-label>
          <input
            name="Year"
            (keyup)="filterHistory()"
            maxlength="4"
            (keypress)="numberOnly($event)"
            type="text"
            matInput
            [(ngModel)]="Year"
            onDrag="return false"
            onDrop="return false"
            onPaste="return false"
          />
          <mat-hint>YYYY</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <!-- Filtered list -->
    <div class="small messages-container scrollbar">
      <div *ngFor="let item of filtered">
        <div
          class="alert p-1 mb-1"
          [ngClass]="{
            'alert-danger': !item.roofAccess,
            'alert-success': item.roofAccess
          }"
        >
          <button
            class="close focus-outline-none"
            *ngIf="hasPrivilege(privilegeEnum.ToxicGasEditor)"
            (click)="copy(item)"
            title="Copy"
          >
            <i class="material-icons">file_copy</i>
          </button>
          <div class="row no-gutters">
            <div class="col-4">Location: {{ item.description }}</div>
            <div class="col-4">Gas: {{ item.name }}</div>
            <div class="col-4">
              <div
                class="badge"
                [ngClass]="{
                  'badge-danger': !item.roofAccess,
                  'badge-success': item.roofAccess
                }"
              >
                Roof Access : {{ item.roofAccess ? "OK" : "Not OK" }}
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col small text-right">
              <span
                >Creates by {{ item.createdByUser }} on
                {{ item.createdOn | date : "yyyy-MM-dd" }}.</span
              >
              <span *ngIf="item.updatedByUser != null">
                Last edited by {{ item.updatedByUser }} on
                {{ item.updatedOn | date : "yyyy-MM-dd" }}.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Serach fields -->
</div>
