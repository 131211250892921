import { Injectable } from '@angular/core';
import { MessageBanner, SystemCategory } from './message-banner';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class MessageBannerService {
  BASE_URL = environment.urls.baseApiUrl;
  Api = '/MessageBanner';

  messageBanners?: MessageBanner[];
  messageBanners$!: Observable<MessageBanner[]>;
  messageBannersSubs!: Subscription;

  categories: SystemCategory[] = [];

  constructor(
    private store: Store,
    private http: HttpClient
  ) {
    this.loadMessageBanners();
  }

  loadMessageBanners() {
    this.messageBanners$ = this.store.select(state => state.MessageBanners.data);
    this.messageBannersSubs = this.messageBanners$.subscribe(data => {
      if (data?.length) {
        this.messageBanners = data;
      }
    });
  }

  readAll(): Observable<MessageBanner[]> {
    return this.http.get<MessageBanner[]>(this.BASE_URL + this.Api);
  }

  get(name: string): MessageBanner {
    return this.messageBanners?.find(val => val.name == name) ?? (new MessageBanner());
  }

  // getById(id: number) {
  //   let messageBanner = new MessageBanner();
  //   if (this.messageBanners?.length) {
  //     messageBanner = this.messageBanners.find(val => val.id === id) ?? (new MessageBanner());
  //     return messageBanner;
  //   }
  //   setTimeout(() => {
  //     this.getById(id);
  //   }, 500);
  //   return null;
  // }

  update(data: MessageBanner): Observable<MessageBanner> {
    return this.http.put<MessageBanner>(this.BASE_URL + this.Api, data);
  }
}
