import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommentResource } from 'src/app/controls/comments-and-attachments/services/comment';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';

@Component({
  selector: 'comments-summary',
  templateUrl: './comments-summary.component.html',
  styleUrls: ['./comments-summary.component.scss']
})
export class CommentsSummaryComponent {

  comments?: CommentResource[];
  loading: boolean = true;
  sections: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<CommentsSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public currentDocument: any,
    private _wf: WorkFlowService
  ) {

  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this._wf.comments(this.currentDocument.wfTableID).subscribe(data => {
      this.comments = data;
      var section: string | undefined = "";
      this.comments.map(c => {
        if (c.section != section && c.section) {
          section = c.section;
          this.sections.push(c.section);
        }
      });
      this.loading = false;
    })
  }

  commentsBySection(section: string) {
    return this.comments?.filter(x => x.section == section);
  }

  close() {
    this.dialogRef.close();
  }
}
