import { Bakeout } from "../../bakeouts";


export class BakeoutsRefresh {
  static readonly type = '[Bakeouts] Refresh';
}

export class BakeoutsCreate {
  static readonly type = '[Bakeouts] Create';

  constructor(public item: Bakeout) { }
}

export class BakeoutsUpdate {
  static readonly type = '[Bakeouts] Update';

  constructor(public id: number, public item: Bakeout) { }
}

export class BakeoutsDelete {
  static readonly type = '[Bakeouts] Delete';
  constructor(public id: number) { }
}
