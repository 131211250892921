<h6>{{ header }}</h6>
<div class="mat-container">
  <mat-table mat-table [dataSource]="dataSource" matSort #sort="matSort">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header="name">
        Name
      </mat-header-cell>
      <mat-cell class="font-weight-bold" *matCellDef="let element" nowrap>
        {{ element.name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="statusName">
      <mat-header-cell *matHeaderCellDef mat-sort-header="statusName">
        Status
      </mat-header-cell>
      <mat-cell class="pl-1" *matCellDef="let element">
        <span
          [innerHTML]="
            element.statusName +
            (element.statusByUser
              ? ' by
          <b>' +
                element.statusByUser?.name +
                '</b>'
              : '')
          "
        ></span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actionName">
      <mat-header-cell *matHeaderCellDef mat-sort-header="actionName">
        Action
      </mat-header-cell>
      <mat-cell class="pl-1" *matCellDef="let element">
        {{ element.actionName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="createdOn">
      <mat-header-cell *matHeaderCellDef mat-sort-header="createdOn">
        Date
      </mat-header-cell>
      <mat-cell class="pl-1" *matCellDef="let element">
        {{ element.createdOn | date : "longDate" }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="disapprovalReason">
      <mat-header-cell *matHeaderCellDef mat-sort-header="disapprovalReason">
        Disapproval Reason
      </mat-header-cell>
      <mat-cell class="pl-1" *matCellDef="let element">
        {{ element.disapprovalReason }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="remove">
      <mat-header-cell *matHeaderCellDef>
        <button
          class="float-right"
          mat-icon-button
          *ngIf="showRemoveAll"
          class="delete"
          matTooltip="Acknowledge All"
          (click)="removeAll()"
        >
          <mat-icon class="yellow">visibility_off</mat-icon>
        </button></mat-header-cell
      >
      <mat-cell class="pl-1" *matCellDef="let element">
        <button
          class="float-right"
          mat-icon-button
          *ngIf="
            element.status == 3 && element.pendingID && element.catalogType < 10
          "
          class="delete"
          matTooltip="Acknowledge"
          (click)="removeRow($event, element)"
        >
          <mat-icon class="blue">visibility_off</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="setItem(row)"
      class="table-row"
    ></mat-row>
  </mat-table>
</div>
