import { Component, Injector, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { RadLocationType } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location-type';
import { RadLocationTypeService } from '../../services/rad-location-type.service';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { RadLocation } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location';


@Component({
  selector: 'rad-monitor-add-edit-rad-location-type',
  templateUrl: './add-edit-rad-location-type.component.html',
  styleUrls: ['./add-edit-rad-location-type.component.scss']
})
export class RadMonitorAddEditRadLocationTypeComponent extends BaseComponent implements OnInit {

  radLocationType?: RadLocationType;
  radLocationTypes?: RadLocationType[];
  radLocationTypesFiltered?: RadLocationType[];
  radLocationTypes$!: Observable<RadLocationType[]>;
  radLocationTypesSubs!: Subscription;

  radLocations?: RadLocation[];
  radLocationsFiltered?: RadLocation[];
  radLocations$!: Observable<RadLocation[]>;
  radLocationsSubs!: Subscription;

  public displayedColumnsRadManagement: string[] = ['name', 'options'];
  public dataSourceRadManagement: MatTableDataSource<RadManagement> = new MatTableDataSource();

  public name!: string;

  constructor(
    protected override injector: Injector,
    private service: RadLocationTypeService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loadRadLocations();
  }

  loadRadLocationTypes() {
    this.radLocationTypes$ = this.store.select(state => state.RadLocationType.data);
    this.radLocationTypesSubs = this.radLocationTypes$.subscribe(data => {
      if (data?.length) {
        this.radLocationTypes = data;
        this.radLocationTypes?.map(t => {
          t.deleteDisabled = this.radLocations?.some(l => l.typeId == t.id);
          t.preName = t.name;
        });
        this.dataSourceRadManagement = new MatTableDataSource(this.radLocationTypes);
      }
    });
  }

  loadRadLocations() {
    this.radLocations$ = this.store.select(state => state.RadLocation.data);
    this.radLocationsSubs = this.radLocations$.subscribe(data => {
      if (data?.length) {
        this.radLocations = data;
        this.loadRadLocationTypes();
      }
    });
  }

  create() {
    if (this.name) {
      const radManagement: RadManagement = {
        name: this.name,
        preName: this.name,
        editing: false
      };
      this.service.CreateRadLocationType(radManagement).toPromise();
    } else {
      this.alert.message('RadManagement_NotBlank', [new MessagePlaceholder('{what}', 'Type')]);
    }
  }

  saveChanges(radManagement: RadManagement) {
    if (radManagement.name && radManagement.id) {
      radManagement.name = radManagement.preName;
      this.service.UpdateRadLocationType(radManagement.id, radManagement).toPromise();
      radManagement.editing = false;
    } else {
      this.alert.message('RadManagement_NotBlank', [new MessagePlaceholder('{what}', 'Type')]);
    }
  }

  delete(radManagement: RadManagement) {
    const dialog = this.dialog.open(YesNoDialogComponent, {
      width: "500px", data: {
        message: 'Are you sure you want to Delete <b>' + radManagement.name + '</b> ?'
        , icon: "question",
      },
    });
    dialog.afterClosed().subscribe(async (data) => {
      if (data && radManagement.id) {
        this.service.DeleteRadLocationType(radManagement.id).toPromise();
      }
    });
  }

  setEditing(radManagement: RadManagement) {
    this.dataSourceRadManagement.data.map(x => {
      x.editing = false;
    });
    radManagement.editing = true;
  }

  discardChanges(radManagement: RadManagement) {
    radManagement.preName = radManagement.name;
    radManagement.preDefaultValue = radManagement.defaultValue;
    radManagement.editing = false;
  }

}

class RadManagement {
  id?: number;
  name?: string;
  preName?: string;
  status?: number;
  editing?: boolean;
  defaultValue?: string;
  preDefaultValue?: string;
}
