import { KEVersionResource } from '../../ke';

export class KEVersionRefresh {
  static readonly type = '[KEVersion] Refresh';
}
export class KEVersionCreate {
  static readonly type = '[KEVersion] Create';
  constructor(public item: KEVersionResource) { }
}
export class KEVersionUpdate {
  static readonly type = '[KEVersion] Update';
  constructor(public id: number, public item: KEVersionResource) { }
}
export class KEVersionDelete {
  static readonly type = '[KEVersion] Delete';
  constructor(public id: number) { }
}
export class KEVersionRefreshByID {
  static readonly type = '[KEVersion] RefreshByID';
  constructor(public id: number) { }
}
