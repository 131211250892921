<div class="row pt-2 full-width">
  <div class="col d-flex justify-content-end">
    <div
      [ngClass]="{ yellow: showPrevResource }"
      class="small"
      *ngIf="detailResourceUsers.updateUserName"
    >
      Last update by: <b>{{ detailResourceUsers.updateUserName }}</b
      >&nbsp;on:&nbsp;
      <span class="date">{{
        detailResourceUsers.updatedOn | date : "short"
      }}</span
      >&nbsp;
    </div>
    <div class="small" *ngIf="detailResourceUsers.approverUserName">
      Approved by: <b>{{ detailResourceUsers.approverUserName }}</b
      >&nbsp;on:&nbsp;
      <span class="date">{{
        detailResourceUsers.approvedOn | date : "short"
      }}</span>
    </div>
  </div>
</div>
<div class="p-1">
  <form #form>
    <div class="main-panel scrollbar pl-3 pr-3 pt-1">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-sm-6">
              <mat-form-field appearance="outline">
                <mat-label>Shutter Name</mat-label>
                <input
                  matInput
                  [(ngModel)]="shutterName"
                  [disabled]="true"
                  name="ShutterName"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6">
              <mat-form-field appearance="outline">
                <mat-label>Beamline Name</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamlineName"
                  [disabled]="true"
                  name="BeamlineName"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <mat-form-field appearance="outline" *ngIf="shutter">
                <mat-label>RSS Rack Location</mat-label>
                <input
                  matInput
                  [(ngModel)]="shutter.rssRackLocation"
                  [disabled]="true"
                  name="RSSRackLocation"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('shutter', 'epsRackLocation')
                }"
                *ngIf="shutter"
              >
                <mat-label>EPS Location</mat-label>
                <input
                  matInput
                  [(ngModel)]="shutter.epsRackLocation"
                  [disabled]="true"
                  name="EPSLocation"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('beamline', 'RSSTestType')
                }"
                *ngIf="beamline"
              >
                <mat-label>RSS Test Type</mat-label>
                <mat-select
                  matNativeControl
                  [(ngModel)]="beamline.RSSTestType"
                  [disabled]="!isModifying"
                  name="RSSTestType"
                >
                  <mat-option [value]="1">Hutchless</mat-option>
                  <mat-option [value]="2">Hutch / Hutch Functional</mat-option>
                  <mat-option [value]="3">Mini Hutch</mat-option>
                  <mat-option [value]="4">Vacuum Switch</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('beamline', 'RSSTestProcedureNumber')
                }"
                *ngIf="beamline"
              >
                <mat-label>Procedure Number</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamline.RSSTestProcedureNumber"
                  [disabled]="!isModifying"
                  name="RSSTestProcedureNumber"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="row align-items-center">
                <div
                  class="col-auto"
                  *ngIf="
                    beamlineShutter?.shutterName != null &&
                    beamlineShutter?.shutterName != undefined
                  "
                >
                  <label class="mb-0">{{
                    beamlineShutter?.shutterName + " Key Code"
                  }}</label>
                </div>
                <div class="col">
                  <mat-form-field
                    class="no-subscript"
                    [ngClass]="{
                      hilite: isModified('beamline', 'RSSTestShutterKeyCode')
                    }"
                    *ngIf="beamline"
                  >
                    <mat-label>Shutter Key Code</mat-label>
                    <input
                      matInput
                      [(ngModel)]="beamline.RSSTestShutterKeyCode"
                      [disabled]="!isModifying"
                      name="RSSTestShutterKeyCode"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('beamline', 'RSSTestWiringDiagramPrint')
                }"
                *ngIf="beamline"
              >
                <mat-label>Wiring Diagram Print</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamline.RSSTestWiringDiagramPrint"
                  [disabled]="!isModifying"
                  name="RSSTestWiringDiagramPrint"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified(
                    'beamline',
                    'RSSTestSafetyInterlockControlsChassisPrint'
                  )
                }"
                *ngIf="beamline"
              >
                <mat-label>Safety Interlock Controls Chassis Print</mat-label>
                <input
                  matInput
                  [(ngModel)]="
                    beamline.RSSTestSafetyInterlockControlsChassisPrint
                  "
                  [disabled]="!isModifying"
                  name="RSSTestSafetyInterlockControlsChassisPrint"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified(
                    'beamline',
                    'RSSTestBeamlineHutchInterlockControlsPrint'
                  )
                }"
                *ngIf="beamline"
              >
                <mat-label>Beamline Hutch Interlock Controls Print</mat-label>
                <input
                  matInput
                  [(ngModel)]="
                    beamline.RSSTestBeamlineHutchInterlockControlsPrint
                  "
                  [disabled]="!isModifying"
                  name="RSSTestBeamlineHutchInterlockControlsPrint"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="row align-items-center">
                <div
                  class="col-auto"
                  *ngIf="
                    shutter?.rssRackLocation != null &&
                    shutter?.rssRackLocation != undefined
                  "
                >
                  <label class="mb-0">{{ shutter?.rssRackLocation }}</label>
                </div>
                <div class="col">
                  <mat-form-field
                    class="no-subscript"
                    [ngClass]="{
                      hilite: isModified('beamline', 'RSSTestRackLocation')
                    }"
                    *ngIf="beamline"
                  >
                    <mat-label>Rack Location (Specific)</mat-label>
                    <input
                      matInput
                      [(ngModel)]="beamline.RSSTestRackLocation"
                      [disabled]="!isModifying"
                      name="RSSTestRackLocation"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('beamline', 'RSSTestSectorNumber')
                }"
                *ngIf="beamline"
              >
                <mat-label>Sector Number</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamline.RSSTestSectorNumber"
                  [disabled]="!isModifying"
                  name="RSSTestSectorNumber"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('beamline', 'RSSTestEPSPLCLocation')
                }"
                *ngIf="beamline"
              >
                <mat-label>EPS PLC Location</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamline.RSSTestEPSPLCLocation"
                  [disabled]="!isModifying"
                  name="RSSTestEPSPLCLocation"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified(
                    'beamline',
                    'RSSTestHutchInterlockControlsChassisLocation'
                  )
                }"
                *ngIf="beamline"
              >
                <mat-label>Hutch Interlock Controls Chassis Location</mat-label>
                <input
                  matInput
                  [(ngModel)]="
                    beamline.RSSTestHutchInterlockControlsChassisLocation
                  "
                  [disabled]="!isModifying"
                  name="RSSTestHutchInterlockControlsChassisLocation"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified(
                    'beamline',
                    'RSSTestInternalCrashOffSearchBoxLocations'
                  )
                }"
                *ngIf="beamline"
              >
                <mat-label>Internal Crash Off Search Box Locations</mat-label>
                <input
                  matInput
                  [(ngModel)]="
                    beamline.RSSTestInternalCrashOffSearchBoxLocations
                  "
                  [disabled]="!isModifying"
                  name="RSSTestInternalCrashOffSearchBoxLocations"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified(
                    'beamline',
                    'RSSTestExternalCrashOffSearchBoxLocations'
                  )
                }"
                *ngIf="beamline"
              >
                <mat-label>External Crash Off Search Box Locations</mat-label>
                <input
                  matInput
                  [(ngModel)]="
                    beamline.RSSTestExternalCrashOffSearchBoxLocations
                  "
                  [disabled]="!isModifying"
                  name="RSSTestExternalCrashOffSearchBoxLocations"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified(
                    'beamline',
                    'RSSTestSRRFINTLKSOKLampsLocation'
                  )
                }"
                *ngIf="beamline"
              >
                <mat-label>SR Interlock Summing Chassis Location</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamline.RSSTestSRRFINTLKSOKLampsLocation"
                  [disabled]="!isModifying"
                  name="RSSTestSRRFINTLKSOKLampsLocation"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('beamline', 'RSSTestTimeDelay')
                }"
                *ngIf="beamline"
              >
                <mat-label>Time Delay</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamline.RSSTestTimeDelay"
                  [disabled]="!isModifying"
                  name="RSSTestTimeDelay"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified(
                    'beamline',
                    'RSSTestArcSectorInterlockSummingChassisLocation'
                  )
                }"
                *ngIf="beamline"
              >
                <mat-label
                  >Arc Sector Interlock Summing Chassis Location</mat-label
                >
                <input
                  matInput
                  [(ngModel)]="
                    beamline.RSSTestArcSectorInterlockSummingChassisLocation
                  "
                  [disabled]="!isModifying"
                  name="RSSTestArcSectorInterlockSummingChassisLocation"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified(
                    'beamline',
                    'RSSTestESVacuumSwitchInterfaceBox'
                  )
                }"
                *ngIf="beamline"
              >
                <mat-label>ES Vacuum Switch Interface Box</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamline.RSSTestESVacuumSwitchInterfaceBox"
                  [disabled]="!isModifying"
                  name="RSSTestESVacuumSwitchInterfaceBox"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('beamline', 'RSSTestMicroswitchClamps')
                }"
                *ngIf="beamline"
              >
                <mat-label>Microswitch Clamps</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamline.RSSTestMicroswitchClamps"
                  [disabled]="!isModifying"
                  name="RSSTestMicroswitchClamps"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-3" *ngIf="beamline">
              <mat-slide-toggle
                [(ngModel)]="beamline.RSSTestShortingPlugs"
                [disabled]="!isModifying"
                name="RSSTestShortingPlugs"
                [ngClass]="{
                  hilite: isModified('beamline', 'RSSTestShortingPlugs')
                }"
                >Shorting Plugs</mat-slide-toggle
              >
            </div>
            <div class="col-12 col-sm-3" *ngIf="beamline">
              <mat-slide-toggle
                [(ngModel)]="beamline.RSSTestMagnetPlate"
                [disabled]="!isModifying"
                name="RSSTestMagnetPlate"
                [ngClass]="{
                  hilite: isModified('beamline', 'RSSTestMagnetPlate')
                }"
                >Magnet Plate</mat-slide-toggle
              >
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <rss-test-shutter-chips
                [placeholder]="'Shutters Required to be Closed for Test'"
                [disabled]="!isModifying"
                [resourceId]="beamline?.id"
                [selectedRssTestShutters]="
                  beamline?.RSSTestShuttersRequiredToBeClosedForTest
                "
                [isModified]="
                  isModified(
                    'beamline',
                    'RSSTestShuttersRequiredToBeClosedForTest'
                  )
                "
              >
              </rss-test-shutter-chips>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="buttons border-top">
  <div class="row">
    <div class="col-6">
      <button
        mat-flat-button
        color="warn"
        (click)="cancel()"
        *ngIf="showCancelButton"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="warn"
        (click)="reset()"
        *ngIf="isModifying"
      >
        Cancel
      </button>
    </div>
    <div class="col-6">
      <button
        mat-flat-button
        color="accent"
        class="right-btn"
        *ngIf="showModifyButton"
        [disabled]="!modifyAvailable || isModifying"
        (click)="modifyResource()"
      >
        Modify
      </button>
      <button
        mat-flat-button
        color="primary"
        class="right-btn"
        *ngIf="isModifying"
        (click)="saveResource()"
      >
        Save
      </button>
      <button
        mat-flat-button
        color="primary"
        class="right-btn"
        *ngIf="showApproveDisapprove"
        (click)="approve()"
      >
        Approve
      </button>
      <button
        mat-flat-button
        color="warn"
        class="right-btn"
        *ngIf="showApproveDisapprove"
        (click)="openAddDisapprovalDialog()"
      >
        Disapprove
      </button>
    </div>
  </div>
</div>
