// Production Normal
//export const config = {
//  toggle: false,
//  production: true
//}

// Production with Toggle Users
/* export const config = {
  toggle: true,
  production: true
} */

//// Development 
export const config = {
  toggle: true,
  production: false
}
