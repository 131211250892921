import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { YesNoDialogComponent } from "src/app/controls/yes-no-dialog/yes-no-dialog.component";
import { utils } from "src/app/modules/libs/utils";
import { AlertService } from "src/app/services/alert/alert.service";
import { MessageBannerService } from "src/app/components/messages/services/message-banner.service";

@Component({
  selector: "cl-editor-table-headers",
  templateUrl: "./cl-editor-table-headers.component.html",
  styleUrls: ["./cl-editor-table-headers.component.scss"],
})
export class ClEditorTableHeadersComponent implements OnInit, OnChanges {
  @Input() disabled?: boolean;
  @Input() configuration?: string;
  @Output() changed = new EventEmitter<string>();

  columnsToggle: boolean = false;
  colorToggle: boolean = false;
  channelsToggle: boolean = false;
  rowsToggle: boolean = false;
  incIMToggle: boolean = true;
  incIMPosition: number = 1;
  images: any[] = [];

  defaultDataChannel = [
    { name: "cmm:beam_current", label: "Beam Current (mA)", pos: 1 },
  ];

  columnsBuilder: TableColumn[] = [
    { name: "beam_current", label: "Beam<br>Current" },
  ];

  columnsChecklist: TableColumn[] = [
    { name: "placeholder", label: "Placeholder", editor: false },
    {
      name: "dose",
      label: "Max. Reading<br>(mR/h)",
      editor: "numeric",
      type: "numeric",
      numericFormat: { pattern: "0.000" },
    },
    { name: "location", label: "Location" },
    { name: "values", label: "Values", editor: false },
    { name: "notes", label: "Notes" },
  ];

  columnsIntegratedMeasurements: TableColumn[] = [
    { name: "reading", label: "Int. Reading<br>(mR)", type: "group" },
    { name: "time", label: "Int. Time<br>(m)", type: "group" },
    {
      name: "calcdose",
      label: "Calculated Dose Rate<br>(mR/h)",
      type: "group",
    },
  ];

  public columnData: TableColumn[] = [{ name: "data", label: "Data" }];

  columns: TableColumn[] = [];

  spotRows: any[] = [
    { id: 0, posx: 0, posy: 0, spotText: "", imageid: 0, percX: 0, percY: 0 },
  ];

  dataset: rowData[] = [
    {
      id: 1,
      values: "",
      rowId: "1.1",
      spotid: 0,
      imageid: 0,
      data: JSON.stringify(this.defaultDataChannel),
    },
  ];

  showHtml = false;
  rows: any[] = [];

  constructor(
    public dialog: MatDialog,
    public _dMessage: MessageBannerService,
    private alert: AlertService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.placeholderCheck();
    if (this.configuration) {
      const configuration = JSON.parse(this.configuration);
      this.columnsBuilder = configuration.columnsBuilder;
      this.columnsChecklist = configuration.columnsChecklist;
      this.colorToggle = configuration.colorize;
      this.incIMToggle = configuration.includeIM;
      this.incIMPosition = configuration.includeIMPos;
      this.images = configuration.images;
      this.channelsToggle = configuration.channels;
      if (this.incIMPosition == undefined) {
        this.incIMPosition = this.columnsChecklist.length;
      }
      this.getColumns();
      if (configuration.data) {
        this.dataset = configuration.data;
        this.getRows();
      }
      if (configuration.spots) {
        this.spotRows = configuration.spots;
      }
    } else {
      this.change();
    }
  }

  ngOnInit() { }

  placeholderCheck() {
    if (this.columnsChecklist && !this.columnsChecklist.some(c => c.name == "placeholder")) {
      this.columnsChecklist.splice(0, 0, { name: "placeholder", label: "Placeholder", editor: false });
      this.change();
    }
  }

  getRows() {
    this.rows = [];
    let calculatedRows = [];
    calculatedRows = [...new Set(this.dataset.map((x) => x.id))];
    calculatedRows = calculatedRows.sort((a, b) => (a ?? 0) - (b ?? 0));

    if (calculatedRows.length) {
      for (let i = 0; i < calculatedRows.length; i++) {
        const labelRow: string = String(calculatedRows[i]);
        this.rows.push({ rowId: i, rowLabel: labelRow });
      }
    }
    this.rows = this.rows.sort((a, b) => +a.rowLabel - +b.rowLabel);
  }

  change() {
    try {
      this.configuration = JSON.stringify({
        columnsBuilder: this.columnsBuilder,
        columnsChecklist: this.columnsChecklist,
        data: this.dataset,
        spots: this.spotRows,
        colorize: this.colorToggle,
        includeIM: this.incIMToggle,
        includeIMPos: this.incIMPosition,
        images: this.images,
        channels: this.channelsToggle,
      });
      this.changed.emit(this.configuration);
    } catch (e) { }
  }

  displayDeleteButtonRow(): boolean {
    let displayButton = true;
    const newConfig = utils.JSONparse(this.configuration);
    const uniqueIds = [...new Set(newConfig.data.map((obj: any) => obj.id))];
    if (uniqueIds.length <= 1) {
      displayButton = false;
    }
    return displayButton;
  }

  displayDeleteButtonHeader(): boolean {
    let displayButton = true;
    const cantColums = this.columns.filter((x) => x.name != "values").length;
    if (cantColums <= 1) {
      displayButton = false;
    }
    return displayButton;
  }

  headerChange(column: TableColumn) {
    let columns: TableColumn[] = [];
    if (this.columnsToggle) {
      if (this.columnsChecklist.find((x) => x.name == column.name)) {
        columns = this.columnsChecklist;
      }
    } else {
      if (this.columnsBuilder.find((x) => x.name == column.name)) {
        columns = this.columnsBuilder;
      }
    }
    const columnToUpdate = columns.find((x) => x.name == column.name);
    if (columnToUpdate) {
      columnToUpdate.label = utils.replaceAll(column?.label ?? '', "<p>", "");
      columnToUpdate.label = utils.replaceAll(
        columnToUpdate.label,
        "</p>",
        "<br>"
      );
      columnToUpdate.label = utils.replaceAll(
        columnToUpdate.label,
        "<div>",
        ""
      );
      columnToUpdate.label = utils.replaceAll(
        columnToUpdate.label,
        "</div>",
        "<br>"
      );
      columnToUpdate.label = columnToUpdate.label.replace(
        '<span style="color: rgb(187, 187, 187);">',
        ""
      );
      columnToUpdate.label = columnToUpdate.label.replace("</span>", "");

      if (columnToUpdate.name == "new" || columnToUpdate.name?.includes("no_label")) {
        if (column.label == "") {
          columnToUpdate.label = '<span style="color: rgb(187, 187, 187);">No label</span>';
          columnToUpdate.name = "no_label";
        } else {
          let newName = utils.replaceAll(
            utils.replaceAll(columnToUpdate.label, " ", "_").toLocaleLowerCase(), "<br>", "_");
          if (this.columnsChecklist.find((x) => x.name == newName) || this.columnsBuilder.find((x) => x.name == newName)) {
            newName = newName + "_";
          }
          columnToUpdate.name = newName;
        }
      }
      this.change();
    }
  }

  showSave(column: TableColumn) {
    if (this.columnsToggle) {
      return !this.columnsChecklist.some(c => c.label == column.label);
    }
    else {
      return !this.columnsBuilder.some(c => c.label == column.label);
    }
  }

  changeToggle() {
    this.getColumns();
  }

  getColumns() {
    if (this.columnsToggle) {
      if (this.incIMToggle) {
        this.columns = utils.insertArrayAt(
          utils.cloneDeep(this.columnsChecklist),
          this.incIMPosition,
          this.columnsIntegratedMeasurements
        );
      } else {
        this.columns = utils.cloneDeep(this.columnsChecklist);
      }
      if (this.channelsToggle) {
        const pos = this.columns.find((x) => x.name == "notes")
          ? this.columns.length - 1
          : this.columns.length;
        this.columns = utils.insertArrayAt(
          utils.cloneDeep(this.columns),
          pos,
          this.columnData
        );
      }
    } else {
      this.columns = utils.cloneDeep(this.columnsBuilder);
    }
    return this.columns;
  }

  add() {
    if (
      !this.columnsChecklist.find((c) => c.name == "new") &&
      !this.columnsBuilder.find((c) => c.name == "new")
    ) {
      const column = new TableColumn();
      column.label = "";
      column.name = "new";

      let index;

      if (this.columnsToggle) {
        // Default to inserting at the last position
        index = this.columnsChecklist.length;

        // Check if any element has the name notes or data
        let hasNotes = false;
        let hasData = false;
        let hasValues = false;

        for (let i = 0; i < this.columnsChecklist.length; i++) {
          if (this.columnsChecklist[i].name === "notes") {
            hasNotes = true;
          }
          if (this.columnsChecklist[i].name === "data") {
            hasData = true;
          }
          if (this.columnsChecklist[i].name === "values") {
            hasData = true;
          }
        }

        if (hasNotes) {
          index--;
        }
        if (hasData) {
          index--;
        }
        if (hasValues) {
          index--;
        }

        this.columnsChecklist.splice(index, 0, { name: "new", label: "" });
      } else {
        index = this.columnsBuilder.length;
        this.columnsBuilder.splice(index, 0, { name: "new", label: "" });
      }
      this.change();
    } else {
      this.alert.error(
        "Please save your column changes before adding another."
      );
    }
  }

  delete(column: TableColumn) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      minWidth: "400px",
      data: {
        message: this._dMessage.get("Rad_Survey_Image_Component_Delete_Column")
          .description,
        icon: "stop",
      },
    });
    confirm.afterClosed().subscribe((data) => {
      if (data) {
        if (this.columnsToggle) {
          const index = this.columnsChecklist.findIndex(
            (x) => x.name == column.name
          );
          if (index >= 0) {
            this.columnsChecklist.splice(index, 1);
          }
        } else {
          const index = this.columnsBuilder.findIndex(
            (x) => x.name == column.name
          );
          if (index >= 0) {
            this.columnsBuilder.splice(index, 1);
          }
        }
        this.change();
      }
    });
  }

  addTableRow() {
    const id = this.nextId();
    let pvData = null;
    if (this.incIMToggle) {
      pvData = JSON.stringify(this.defaultDataChannel);
    }
    this.dataset.push({
      id,
      values: "",
      rowId: (id + 0.1).toString(),
      spotid: 0,
      imageid: 0,
      data: pvData,
    });
    this.getRows();
    this.change();
  }

  duplicateRow(r: any) {
    const rows = this.dataset.filter((x) => x.id == +r.rowLabel);
    const newId = this.nextId();
    rows.map((row) => {
      const newRow = utils.cloneDeep(row);
      newRow.id = newId;
      newRow.rowId = newId + "." + newRow.rowId?.split(".")[1];
      newRow.spotid = 0;

      this.dataset.push(newRow);
    });
    this.getRows();
    this.change();
  }

  deleteRow(row: any) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "400px",
      data: {
        message: this._dMessage.get("Rad_Survey_Image_Component_Delete_Row")
          .description,
        icon: "stop",
      },
    });

    confirm.afterClosed().subscribe((data) => {
      if (data != null) {
        this.dataset = this.dataset.filter(x => x.id?.toString() != row.rowLabel);
        this.deleteSpot(row);
        this.reorderRows();
        this.getRows();
        this.change();
      }
    });
  }

  reorderRows() {
    let id = 0;
    let lastId = 0;
    const newDataset: rowData[] = [];
    this.dataset.sort((a, b) => ((a.id ?? 0) * 10000 + +(a.rowId ?? 0)) - ((b.id ?? 0) * 10000 + +(b.rowId ?? 0))).map(d => {
      if (d.id != lastId) {
        lastId = d.id ?? 0;
        id++
      }
      const rowId = id.toString() + '.' + d.rowId?.split('.')[1];
      const data: rowData = utils.cloneDeep(d);
      data.id = id;
      data.rowId = rowId;
      newDataset.push(data);
    });
    this.dataset = newDataset;
  }

  deleteSpot(row: any) {
    const filtered = this.spotRows.filter((x) => String(x.id) != row.rowLabel);
    this.spotRows = filtered;
  }

  nextId(): number {
    const newConfig = utils.JSONparse(this.configuration);
    let _id = 0;
    const uniqueIds: any[] = [...new Set(newConfig.data.map((obj: any) => obj.id))];
    let arrayIds: number[] = [];
    uniqueIds.map((n) => {
      arrayIds.push(n);
    });
    arrayIds = arrayIds.sort((n1, n2) => n1 - n2);
    _id = Number(Math.max.apply(null, arrayIds)) + 1;
    return _id;
  }

  move(move: number) {
    let newPosition = (this.incIMPosition += move);
    if (newPosition < 0) {
      newPosition = 0;
    }
    if (newPosition > this.columnsChecklist.length - 1) {
      newPosition = this.columnsChecklist.length - 1;
    }
    this.incIMPosition = newPosition;
    this.getColumns();
    this.change();
  }

  // checkMove(column: TableColumn, move: number) {
  //   var lastIndex = this.columns.length - 1;
  //   var index = this.columns.findIndex(c => c.name == column.name);
  //   //If Notes or Data column
  //   if (column.name == "notes" || column.name == "data") {
  //     return false;
  //   }
  //   //If index is adjacent to data or notes column
  //   else if (move === 1 && ((this.columns[index + 1]?.name ?? "") == "notes" || (this.columns[index + 1]?.name ?? "") == "data" || (this.columns[index + 1]?.name ?? "") == "values")) {
  //     return false;
  //   }
  //   //If index is adjacent to placeholder
  //   else if (move === -1 && (this.columns[index - 1]?.name ?? "") == "placeholder") {
  //     return false;
  //   }
  //   //Left arrow check on columnsChecklist side (account for placeholder)
  //   else if (move === -1 && this.columnsToggle)
  //     return index > 1;
  //   //Left arrow check on columnsBuilder side
  //   else if (move === -1)
  //     return index > 0;
  //   //Right arrow check
  //   else
  //     return index < lastIndex;
  // }

  checkMove(column: TableColumn, move: number) {
    const { columns, columnsToggle } = this;
    const columnNames = columns.map(c => c.name);
    const index = columnNames.indexOf(column.name);
    const lastIndex = columns.length - 1;

    if (column.name === "notes" || column.name === "data") {
      return false;
    }
    //Check move to right
    if (move === 1) {
      const nextColumnName = columnNames[index + 1] ?? "";
      if (["notes", "data", "values"].includes(nextColumnName)) {
        return false;
      }
      //Check move to left
    } else if (move === -1) {
      const prevColumnName = columnNames[index - 1] ?? "";
      if (prevColumnName === "placeholder") {
        return false;
      }
      if (columnsToggle) {
        return index > 1;
      } else {
        return index > 0;
      }
    }

    return index < lastIndex;
  }


  moveColumn(column: TableColumn, move: number) {
    //Handle Integrated Toggle On moves on columnsChecklist side
    if (this.columnsToggle && this.incIMToggle) {
      const indexColumns = this.columns.findIndex(c => c.name == column.name)
      const indexChecklist = this.columnsChecklist.findIndex(c => c.name == column.name)
      // const index = this.columns.findIndex(c => c.name == column.name)
      if (indexColumns === -1) {
        throw new Error('Item not found in the array');
      }
      //If move is to left, check if index -1 is the calc dose column and call move in reverse
      if (move === -1 && (this.columns[indexColumns - 1].name ?? "") == "calcdose") {
        this.move(1);
      }
      //If move is to right, check if index +1 is the int reading column and call move in reverse
      else if (move === 1 && (this.columns[indexColumns + 1].name ?? "") == "reading") {
        this.move(-1);
      }
      //Move column left
      else if (move === -1 && indexChecklist > 0) {
        [this.columnsChecklist[indexChecklist], this.columnsChecklist[indexChecklist - 1]] = [this.columnsChecklist[indexChecklist - 1], this.columnsChecklist[indexChecklist]]
      }
      //Move column right
      else if (move === 1 && indexChecklist < this.columnsChecklist.length - 1) {
        [this.columnsChecklist[indexChecklist], this.columnsChecklist[indexChecklist + 1]] = [this.columnsChecklist[indexChecklist + 1], this.columnsChecklist[indexChecklist]]
      }
    }
    //Handle Integrated Toggle Off moves on columnsChecklis side
    else if (this.columnsToggle && !this.incIMToggle) {
      const index = this.columnsChecklist.findIndex(c => c.name == column.name);
      if (index === -1) {
        throw new Error('Item not found in the array');
      }
      //Move column left
      if (move === -1 && index > 0) {
        [this.columnsChecklist[index], this.columnsChecklist[index - 1]] = [this.columnsChecklist[index - 1], this.columnsChecklist[index]]
      }
      //Move column right
      else if (move === 1 && index < this.columnsChecklist.length - 1) {
        [this.columnsChecklist[index], this.columnsChecklist[index + 1]] = [this.columnsChecklist[index + 1], this.columnsChecklist[index]]
      }
      // this.getColumns();
    }
    //Handle moves on columnsBuilder side
    else {
      const index = this.columnsBuilder.findIndex(c => c.name == column.name);
      if (index === -1) {
        throw new Error('Item not found in the array');
      }
      //Move column left
      if (move === -1 && index > 0) {
        [this.columnsBuilder[index], this.columnsBuilder[index - 1]] = [this.columnsBuilder[index - 1], this.columnsBuilder[index]]
      }
      //Move column right
      else if (move === 1 && index < this.columnsBuilder.length - 1) {
        [this.columnsBuilder[index], this.columnsBuilder[index + 1]] = [this.columnsBuilder[index + 1], this.columnsBuilder[index]]
      }
    }
    this.change();
  }

  imageChanged(e: any) {
    const configuration = utils.JSONparse(this.configuration);
    const images: any[] = [];

    e.map((img: any) => {
      images.push(img);
    });

    let spots = configuration.spots;
    let dataSet = configuration.data;

    let imageIdArray = images.map((x) => x.id);
    let spotsIdArray = spots.map((x: { imageid: any }) => x.imageid);
    spotsIdArray = [...new Set(spotsIdArray)];
    imageIdArray = [...new Set(imageIdArray)];

    imageIdArray.map((item) => {
      const index = spotsIdArray.indexOf(item);
      if (index != -1) {
        spotsIdArray.splice(index, 1);
      }
    });

    spotsIdArray.map((item: number) => {
      if (item != 0) {
        spots = spots.filter((x: { imageid: any }) => x.imageid != item);
        const rowsImageId = dataSet.filter(
          (x: { imageid: any }) => x.imageid == item
        );
        let rowsIds = rowsImageId.map((x: { id: any }) => x.id);

        rowsIds = [...new Set(rowsIds)];
        rowsIds.map((element: any) => {
          const spotsRowIdTotal = [
            ...new Set(
              dataSet
                .filter((x: { id: any }) => x.id == element)
                .map((x: { spotid: any }) => x.spotid)
            ),
          ].length;
          const spotsRowIdImageSpots = [
            ...new Set(
              dataSet
                .filter(
                  (x: { imageid: any; id: any }) =>
                    x.imageid == item && x.id == element
                )
                .map((x: { spotid: any }) => x.spotid)
            ),
          ].length;
          if (spotsRowIdTotal > spotsRowIdImageSpots) {
            dataSet = dataSet.filter(
              (x: { id: any; imageid: any }) =>
                !(x.id == element && x.imageid == item)
            );
          } else {
            const imageSpotsId = [
              ...new Set(
                dataSet
                  .filter(
                    (x: { imageid: any; id: any }) =>
                      x.imageid == item && x.id == element
                  )
                  .map((x: { spotid: any }) => x.spotid)
              ),
            ];
            const firstImageSpotId = imageSpotsId[0];

            const imageSpotsCount = dataSet.filter(
              (x: { imageid: any; id: any; spotid: unknown }) =>
                x.imageid == item &&
                x.id == element &&
                x.spotid == firstImageSpotId
            ).length;

            for (let _i = 0; _i < imageSpotsCount; _i++) {
              dataSet.find(
                (x: { imageid: any; id: any; spotid: unknown }) =>
                  x.imageid == item &&
                  x.id == element &&
                  x.spotid == firstImageSpotId
              ).spotid = 0;
            }
            for (let _i = 0; _i < imageSpotsCount; _i++) {
              dataSet.find(
                (x: { id: any; spotid: number; imageid: any }) =>
                  x.id == element && x.spotid == 0 && x.imageid == item
              ).imageid = 0;
            }
            dataSet = dataSet.filter(
              (x: { id: any; imageid: any; spotid: number }) =>
                !(x.id == element && x.imageid == item && x.spotid != 0)
            );
          }
        });
      }
    });

    this.images = images;
    this.change();
  }
}

class rowData {
  id?: number;
  values?: string;
  rowId?: string;
  spotid?: number;
  imageid?: number;
  data?: string | null;
}

export class TableColumn {
  name?: string;
  label?: string;
  readOnly?: boolean;
  editor?: any;
  type?: string;
  numericFormat?: any;
  source?: any;
  renderer?: any;
  className?: string;
  width?: number;
  span?: number;
  include?: boolean;
}
