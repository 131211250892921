import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Bakeout } from './bakeouts';

@Injectable({ providedIn: 'root' })
export class BakeoutService {
  constructor(private http: HttpClient) {

  }


  private OrderBy(a: Bakeout, b: Bakeout): number {
    return a.createdOn > b.createdOn ? -1 : a.createdOn < b.createdOn ? 1 : 0;
  }

  loadActives(list: Bakeout[]): Bakeout[] {
    list.map(item => {
      if (item.estimatedStart != null) {
        item.estimatedStart = new Date(item.estimatedStart);
      }
      if (item.estimatedEnd != null) {
        item.estimatedEnd = new Date(item.estimatedEnd);
      }
    });

    return list.sort(this.OrderBy);
  }

  loadArchives(list: Bakeout[]): Bakeout[] {
    list.map(item => {
      if (item.estimatedStart != null) {
        item.estimatedStart = new Date(item.estimatedStart);
      }
      if (item.estimatedEnd != null) {
        item.estimatedEnd = new Date(item.estimatedEnd);
      }
    });

    return list.sort(this.OrderBy);
  }

  getAll(): Observable<Bakeout[]> {
    const result = this.http.get<Bakeout[]>(
      environment.urls.baseApiUrl + '/Bakeout'
    );
    return result;
  }

  getActive(): Observable<Bakeout[]> {
    const result = this.http.get<Bakeout[]>(
      environment.urls.baseApiUrl + '/Bakeout/GetActives'
    );
    return result;
  }

  getArchive(): Observable<Bakeout[]> {
    const result = this.http.get<Bakeout[]>(
      environment.urls.baseApiUrl + '/Bakeout/GetInactives'
    );
    return result;
  }
  save(item: Bakeout): Observable<Bakeout> {
    return !item.id ? this.insert(item) : this.update(item);
  }
  private insert(item: Bakeout): Observable<Bakeout> {
    const result = this.http.post<Bakeout>(
      environment.urls.baseApiUrl + '/Bakeout/',
      item
    );
    return result;
  }
  private update(item: Bakeout): Observable<Bakeout> {
    const result = this.http.put<Bakeout>(
      environment.urls.baseApiUrl + '/Bakeout/' + item.id.toString(),
      item
    );

    return result;
  }

  archive(id: number): Observable<Bakeout> {
    const result = this.http.delete<Bakeout>(
      environment.urls.baseApiUrl + '/Bakeout/' + id.toString()
    );

    return result;
  }

}
