import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'

import { MatFileUploadComponent } from './mat-file-upload.component'
import { MatFileComponent } from './mat-file/mat-file.component'
import { MatFileListComponent } from './mat-file-list/mat-file-list.component'
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFileV2UploadComponent } from './mat-file-v2-upload/mat-file-v2-upload.component';

@NgModule({
  declarations: [
    MatFileUploadComponent,
    MatFileComponent,
    MatFileListComponent,
    MatFileV2UploadComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    FormsModule
  ],
  exports: [MatFileUploadComponent,
    MatFileComponent,
    MatFileListComponent,
    MatFileV2UploadComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MatFileUploadModule { }
