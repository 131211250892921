import { RadLocation } from "src/app/components/catalogs/rad-monitor/rad-monitor/rad-location";

export class RadLocationRefresh {
  static readonly type = '[RadLocation] Refresh';
}

export class RadLocationCreate {
  static readonly type = '[RadLocation] Create';
  constructor(public item: RadLocation) { }
}

export class RadLocationUpdate {
  static readonly type = '[RadLocation] Update';
  constructor(public id: number, public item: RadLocation) { }
}

export class RadLocationDelete {
  static readonly type = '[RadLocation] Delete';
  constructor(public id: number) { }
}
