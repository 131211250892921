import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { Restriction } from 'src/app/controls/restrictions/services/restriction';


@Component({
  selector: 'app-restriction',
  templateUrl: './restriction.component.html',
  styleUrls: ['./restriction.component.scss']
})
export class RestrictionComponent extends BaseComponent implements OnInit {

  public appliesToList!: Resource[];
  public branchline!: Resource[];
  public acceleratorList!: Resource[];

  public appliesToSend?: Resource;

  public appliesToSelected!: number;
  public restrictionLanguage?: string;

  public isSaving!: boolean;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<RestrictionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      restriction: Restriction,
      branchlines: Resource[],
      shutter: Resource,
      accelerators: Resource[]
    },

  ) {
    super(injector);

  }

  ngOnInit(): void {
    this.clear();
    let idAux = -1;
    if (this.data.shutter && this.data.shutter.name) {
      if (this.data.shutter.id) {
        this.appliesToList.push(this.data.shutter);
      } else {
        this.data.shutter.id = idAux--;
        this.appliesToList.push(this.data.shutter);
      }
    }
    if (this.data.branchlines) {
      this.branchline = this.data.branchlines;
      this.branchline.map(data => {
        if (data.id) {
          this.appliesToList.push(data);
        } else {
          data.id = idAux--;
          this.appliesToList.push(data);
        }
      });
    }
    if (this.data.accelerators) {
      this.acceleratorList = this.data.accelerators;
      this.acceleratorList.map(data => {
        this.appliesToList.push(data);
      });
    }
    if (this.data.restriction?.location) {
      this.appliesToSelected = this.data.restriction?.location.id;
      this.appliesToSend = this.data.restriction.location;
    }
    this.restrictionLanguage = this.data.restriction?.restrictionLanguage;
  }

  add() {
    this.isSaving = true;
    if (this.isValid()) {
      this.data.restriction.restrictionLanguage = this.restrictionLanguage;
      this.appliesToList.map(x => {
        if (x.id < 0) {
          x.id = 0;
        }
      });
      this.data.restriction.location = this.appliesToSend;
      this.data.restriction.locationID = 0;
      this.data.restriction.resourceID = 0;
      this.dialogRef.close(this.data.restriction);
    } else {
      this.isSaving = false;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  isValid() {
    if (!this.restrictionLanguage) {
      this.alert.message('restrictionDialog_Language_Required');
      return false;
    }
    if (!this.appliesToSelected) {
      this.alert.message('restrictionDialog_AppliesTo_Required');
      return false;
    }
    return true;
  }

  getInfoAppliesTo(appliesToSelectedId: number) {
    this.appliesToSend = this.appliesToList.find(x => x.id === appliesToSelectedId);
  }

  clear() {
    this.appliesToList = [];

    this.appliesToSend = new Resource();

    this.appliesToSelected = 0;
    this.restrictionLanguage = '';

    this.appliesToList.map(x => {
      if (x.id < 0) { x.id = 0; }
    });
  }

}
