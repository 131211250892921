import { ProcedureConfiguration } from "../../models/procedure-configuration.model";

export class ProcedureConfigurationRefresh {
  static readonly type = '[ProcedureConfiguration] Refresh';
}

export class ProcedureConfigurationUpdate {
  static readonly type = '[ProcedureConfiguration] Update';

  constructor(public item: ProcedureConfiguration) { }
}
