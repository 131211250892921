<nav
  class="navbar navbar-expand-lg navbar-dark fixed-top"
  [ngClass]="{
    'bg-prep': environmentLabel == 'prep',
    'bg-dark': environmentLabel == 'prod',
    'bg-test': environmentLabel == 'test',
    'bg-dev': environmentLabel == 'dev',
    'bg-local': environmentLabel == 'local',
    'bg-new-auth': environmentLabel == 'new-auth',
    'h-90': innerWidth > 990
  }"
>
  <head-logo></head-logo>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    class="collapse navbar-collapse full-width flex-column align-items-center"
    #navbarSupportedContent
    id="navbarSupportedContent"
    [ngClass]="{ show: showMenu }"
  >
    <div
      class="row d-flex align-items-start justify-content-end full-width h-45"
    >
      <div
        class="col-1 offset-7 d-flex justify-content-center h-45"
        *ngIf="innerWidth < 1200"
      >
        <head-action-buttons></head-action-buttons>
      </div>
      <div class="col pr-0">
        <head-login></head-login>
      </div>
    </div>
    <div
      class="row full-width d-flex justfy-content-between align-items-center"
      [ngClass]="{ 'top-10': innerWidth >= 1200 }"
    >
      <div class="col d-flex align-items-center pt-0" id="navbar">
        <head-menu class="full-width"></head-menu>
      </div>
      <head-action-buttons
        class="max-width ml-3"
        *ngIf="innerWidth >= 1200"
        #actions
      ></head-action-buttons>
    </div>
  </div>

  <div *ngIf="environmentLabel != 'prod'" class="banner">
    {{ environmentLabel }}
  </div>
</nav>
