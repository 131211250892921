import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { Role } from './role'
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  url = environment.urls.baseApiUrl + "/roles";
  constructor(private http: HttpClient) { }

  Read(): Observable<Role[]> {
    return this.http.get<Role[]>(this.url).pipe(retry(1));
  }

  ReadOne(id: number): Observable<Role> {
    return this.http.get<Role>(this.url + '/' + id).pipe(retry(1));
  }

  Post(role: Role): Observable<Role> {
    return this.http.post<Role>(this.url, role).pipe(retry(1));
  }

  Put(id: number, role: Role): Observable<Role> {
    return this.http.put<Role>(this.url + "/" + id, role).pipe(retry(1));
  }
}
