import { Privilege } from "src/app/components/catalogs/roles/services/role-privilege";

export class PrivilegeRefresh {
  static readonly type = '[Privilege] Refresh';
}

export class PrivilegeCreate {
  static readonly type = '[Privilege] Create';
  constructor(public item: Privilege) { }
}

export class PrivilegeUpdate {
  static readonly type = '[Privilege] Update';
  constructor(public id: number, public item: Privilege) { }
}

export class PrivilegeDelete {
  static readonly type = '[Privilege] Delete';
  constructor(public id: number) { }
}
