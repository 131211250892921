import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RadLocationCreate, RadLocationDelete, RadLocationRefresh, RadLocationUpdate } from './rad-location.action';
import { RadLocation } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location';
import { RadLocationService } from '../../services/rad-location.service';

export class RadLocationStateModel {
  data: RadLocation[] = [];
}

@State<RadLocationStateModel>({
  name: 'RadLocation',
  defaults: {
    data: []
  }
})
@Injectable()
export class RadLocationState {
  constructor(
    private radLocationService: RadLocationService
  ) { }

  @Selector()
  static read(state: RadLocationStateModel) {
    return state.data;
  }

  @Action(RadLocationRefresh)
  refresh({ patchState }: StateContext<RadLocationStateModel>) {
    this.radLocationService.GetRadLocations().toPromise().then(data => {
      patchState({ data });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(RadLocationCreate)
  create({ getState, patchState }: StateContext<RadLocationStateModel>, { item }: RadLocationCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(RadLocationUpdate)
  update({ getState, patchState }: StateContext<RadLocationStateModel>, { id, item }: RadLocationUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(RadLocationDelete)
  delete({ getState, patchState }: StateContext<RadLocationStateModel>, { id }: RadLocationDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
