import { Component, Input, OnChanges, ViewChildren, QueryList, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ChecklistTaskComponent } from './checklist-task/checklist-task.component';
import { ChecklistsService } from '../../checklists.service';
import { Checklist } from '../../checklists';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'checklist-tasks',
  templateUrl: './checklist-tasks.component.html',
  styleUrls: ['./checklist-tasks.component.scss']
})
export class ChecklistTasksComponent implements OnChanges {

  @Input() tasks!: WfTaskLocalResource[];
  @Input() currentChecklist?: Checklist | null;
  @Input() disabled!: boolean;
  @Input() precheck?: boolean;

  @Output() loading = new EventEmitter<boolean>();
  @Output() checkShared = new EventEmitter<any>();

  @ViewChildren(ChecklistTaskComponent) taskComponents!: QueryList<ChecklistTaskComponent>;

  formStatus = FormStatusEnum;
  updating!: boolean;
  renumbering!: boolean;
  update!: boolean;

  constructor(
    private checklistService: ChecklistsService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (!this.updating && !this.renumbering && this.checklistService.allTasks?.length) {
    if (!this.updating && !this.renumbering) {
      this.renumber();
      // this.refreshTasks();
    }
  }

  renumber() {
    this.tasks = this.tasks.sort((a, b) => utils.sort(a.order, b.order));
    let number = 1;
    this.tasks.map(t => {
      if (t.required && t.component?.numericHeader) {
        if (t.name != number.toString()) {
          t.name = number.toString();
        }
        number++;
      }
    });
  }

  updateTask(task: WfTaskLocalResource) {
    // this.checklistService.updateTask(task);
    this.renumber();
    // this.refreshTasks();
  }

  // refreshTasks() {
  //   this.taskComponents?.map(t => {
  //     // const task = this.checklistService.allTasks.find(x => x.id == t.task?.id);
  //     t.refreshSignature();
  //   });
  // }
}
