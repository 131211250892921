import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ChecklistBuilderAnnotationsService } from 'src/app/components/checklists/checklist-builder/checklist-builder-annotations/checklist-builder-annotations.service';
import { ChecklistBuilderService } from 'src/app/components/checklists/checklist-builder/checklist-builder.service';
import { Annotation, Checklist } from 'src/app/components/checklists/checklists';
import { ImageUploadService } from 'src/app/services/file/image-upload.service';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { WfSignature, WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { AppStateService } from 'src/app/store/app-state-service';

@Component({
  selector: 'app-checklist-tasks-annotations-edit',
  templateUrl: './checklist-tasks-annotations-edit.component.html',
  styleUrls: ['./checklist-tasks-annotations-edit.component.scss']
})
export class ChecklistTasksAnnotationsEditComponent extends BaseComponent implements OnInit {

  s: WfSignature;
  task: WfTaskLocalResource;
  annotation: Annotation;
  annotationText?: string | null;
  isResponse: boolean;
  isComment: boolean;
  currentChecklist: Checklist;

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
        [{ align: [] }],
        ['link', 'image'],
        ['clean']

      ]
    },
    // autoLink: true,
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: () => {
            return true;
          }
        }
      }
    },
    imageDrop: true,
    imageResize: true
  };

  wfTaskLocal!: WfTaskLocalResource;

  constructor(
    protected override injector: Injector,
    private dialogRef: MatDialogRef<ChecklistTasksAnnotationsEditComponent>,
    private annotationsService: ChecklistBuilderAnnotationsService,
    private _image: ImageUploadService,
    private builderService: ChecklistBuilderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(injector);
    this.s = data.s;
    this.isResponse = data.isResponse;
    this.isComment = data.isComment;
    this.annotation = data.annotation;
    this.annotationText = this.isResponse ? this.annotation?.responseText : this.annotation?.annotationText;
    this.task = data.task;
    this.currentChecklist = data.currentChecklist;
  }

  ngOnInit() {
    if (this.s.id)
      this.builderService.getWFTaskLocal(this.s.id).toPromise().then(data => {
        if (data)
          this.wfTaskLocal = data;
      });
  }

  async accept() {
    if (!this.isResponse) {
      if (this.annotation == null) {
        this.annotation = {
          id: 0,
          annotationText: this.annotationText,
          wfTaskLocalID: this.task.id,
          checklistID: this.currentChecklist.id,
          status: 0
        };

        this.annotationsService.create(this.annotation).toPromise().then(data => {
          this.dialogRef.close(data);
        });
      }
      else {
        if (this.annotationText && this.currentChecklist.documentType?.code && this.wfTaskLocal.wfStepLocal)
          this.annotation.annotationText = await this.uploadImages(this.annotationText, this.currentChecklist.documentType.code.trim() + this.currentChecklist.serialNo + '/WFSectionLocal-' + this.wfTaskLocal.wfStepLocal.wfSectionLocalID + '/WFTaskLocal-' + this.s.id + '/Suggestion-' + this.annotation.id + '/');
        this.annotationsService.update(this.annotation).toPromise().then(data => {
          this.dialogRef.close(data);
        });
      }
    }
    else {
      if (this.annotationText && this.currentChecklist.documentType?.code && this.wfTaskLocal.wfStepLocal)
        this.annotation.responseText = await this.uploadImages(this.annotationText, this.currentChecklist.documentType.code.trim() + '/' + this.currentChecklist.serialNo + '/WFSectionLocal-' + this.wfTaskLocal.wfStepLocal.wfSectionLocalID + '/WFTaskLocal-' + this.s.id + '/Response-' + this.annotation.id + '/');
      this.annotation.responseBy = this.getCurrentUser()?.id;
      this.annotation.responseByName = this.getCurrentUser()?.name;
      this.annotation.responseDate = new Date();
      this.annotationsService.update(this.annotation).toPromise().then(data => {
        this.dialogRef.close(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  async uploadImages(textValue: string, path: string) {
    const text = await this._image.uploadImagesToServer(textValue, path);
    return text;
  }

}
