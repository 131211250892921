import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";

export class PPSTBShutter {
  public id?: number;
  public ppstbID?: number;
  public resourceID?: number;
  public resource!: Resource;
}

export class PPSTBShutterSave {
  public id?: number;
  public ppstbID?: number;
  public resourceID?: number;
}

export class PPSTBShutterHandler {
  static toSave(ppstbShutter: PPSTBShutter): PPSTBShutterSave {
    const save = new PPSTBShutterSave();

    save.id = ppstbShutter.id;
    save.ppstbID = ppstbShutter.ppstbID;
    save.resourceID = ppstbShutter.resourceID;

    return save;
  }
}
