import { PPSTBShutter, PPSTBShutterSave, PPSTBShutterHandler } from './ppstb-shutter';
import { PPSTBAcceleratorLocation, PPSTBAcceleratorLocationSave, PPSTBAcceleratorLocationHandler } from './ppstb-accelerator-location';
import { PPSTBOtherSystem, PPSTBOtherSystemSave, PPSTBOtherSystemHandler } from './ppstb-other-system';
import { PPSTBOtherFollowUp, PPSTBOtherFollowUpSave, PPSTBOtherFollowUpHandler } from './ppstb-other-follow-up';
import { RelatedDocument } from 'src/app/services/documents/documents';
import { FormRestriction, FormRestrictionSave, FormRestrictionHandler } from 'src/app/services/forms/form-restriction';
import { RelatedLinkBase, RelatedLinkBaseSave, RelatedLinkBaseHandler } from 'src/app/components/catalogs/navigation-links/link/related-link-base';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';

export class PPSTB {
  public id!: number;
  public formVersionID!: number;
  public justificationForBypass: string;
  public systemAndPrintNumbers: string;
  public bypassDetails: string;
  public permittedStartDate: Date;
  public estimatedCompletion: Date;
  public mitigationNotRequired!: boolean;
  public bypassNotRequired!: boolean;
  public conditionsAndLimitations: string;
  public topOff!: boolean;
  public radiationMonitoringSystem!: boolean;
  public beamline!: boolean;
  public accelerator!: boolean;
  public repairReplaceComponent!: boolean;
  public modifyInterlock!: boolean;
  public other!: boolean;
  public createdBy?: number;
  public createdOn?: Date;
  public updatedBy?: number;
  public updatedOn?: Date;
  public testingRequiredBeforeOperation?: string;
  public ppstbShutters: PPSTBShutter[];
  public ppstbAcceleratorLocations: PPSTBAcceleratorLocation[];
  public ppstbOtherSystems: PPSTBOtherSystem[];
  public ppstbRestrictions: FormRestriction[];
  public ppstbRelatedLinks: RelatedLinkBase[];
  public relatedDocuments: RelatedDocument[];
  public ppstbOtherFollowUps: PPSTBOtherFollowUp[];
  public createdByUser!: User;
  public updatedByUser!: User;

  constructor() {
    this.justificationForBypass = '';
    this.systemAndPrintNumbers = '';
    this.bypassDetails = '';
    this.conditionsAndLimitations = '';
    this.testingRequiredBeforeOperation = '';
    this.permittedStartDate = new Date();
    this.estimatedCompletion = new Date();
    this.ppstbShutters = [];
    this.ppstbAcceleratorLocations = [];
    this.ppstbOtherSystems = [];
    this.ppstbRestrictions = [];
    this.ppstbRelatedLinks = [];
    this.relatedDocuments = [];
    this.ppstbOtherFollowUps = [];
  }
}

export class PPSTBSave {
  public id!: number;
  public formVersionID!: number;
  public justificationForBypass!: string;
  public systemAndPrintNumbers!: string;
  public bypassDetails!: string;
  public permittedStartDate!: Date;
  public estimatedCompletion!: Date;
  public mitigationNotRequired!: boolean;
  public bypassNotRequired!: boolean;
  public conditionsAndLimitations!: string;
  public topOff!: boolean;
  public radiationMonitoringSystem!: boolean;
  public beamline!: boolean;
  public accelerator!: boolean;
  public repairReplaceComponent!: boolean;
  public modifyInterlock!: boolean;
  public other!: boolean;
  public testingRequiredBeforeOperation?: string;
  public ppstbShutters: PPSTBShutterSave[];
  public ppstbAcceleratorLocations: PPSTBAcceleratorLocationSave[];
  public ppstbOtherSystems: PPSTBOtherSystemSave[];
  public ppstbRestrictions: FormRestrictionSave[];
  public ppstbRelatedLinks: RelatedLinkBaseSave[];
  public relatedDocuments: RelatedDocument[];
  public ppstbOtherFollowUps: PPSTBOtherFollowUpSave[];

  constructor() {
    this.ppstbShutters = [];
    this.ppstbAcceleratorLocations = [];
    this.ppstbOtherSystems = [];
    this.ppstbRestrictions = [];
    this.ppstbRelatedLinks = [];
    this.relatedDocuments = [];
    this.ppstbOtherFollowUps = [];
  }
}

export class PPSTBHandler {
  static toSave(ppstb: PPSTB): PPSTBSave {
    const save = new PPSTBSave();

    save.id = ppstb.id;
    save.formVersionID = ppstb.formVersionID;
    save.justificationForBypass = ppstb.justificationForBypass;
    save.systemAndPrintNumbers = ppstb.systemAndPrintNumbers;
    save.bypassDetails = ppstb.bypassDetails;
    save.permittedStartDate = ppstb.permittedStartDate;
    save.estimatedCompletion = ppstb.estimatedCompletion;
    save.mitigationNotRequired = ppstb.mitigationNotRequired;
    save.bypassNotRequired = ppstb.bypassNotRequired;
    save.conditionsAndLimitations = ppstb.conditionsAndLimitations;
    save.testingRequiredBeforeOperation = ppstb.testingRequiredBeforeOperation;
    save.topOff = ppstb.topOff;
    save.radiationMonitoringSystem = ppstb.radiationMonitoringSystem;
    save.beamline = ppstb.beamline;
    save.accelerator = ppstb.accelerator;
    save.repairReplaceComponent = ppstb.repairReplaceComponent;
    save.modifyInterlock = ppstb.modifyInterlock;
    save.other = ppstb.other;
    save.ppstbShutters = [];
    ppstb.ppstbShutters.map(x => save.ppstbShutters.push(PPSTBShutterHandler.toSave(x)));
    save.ppstbAcceleratorLocations = [];
    ppstb.ppstbAcceleratorLocations.map(x => save.ppstbAcceleratorLocations.push(PPSTBAcceleratorLocationHandler.toSave(x)));
    save.ppstbOtherSystems = [];
    ppstb.ppstbOtherSystems.map(x => save.ppstbOtherSystems.push(PPSTBOtherSystemHandler.toSave(x)));
    save.ppstbRestrictions = [];
    ppstb.ppstbRestrictions.map(x => save.ppstbRestrictions.push(FormRestrictionHandler.toSave(x)));
    save.ppstbRelatedLinks = [];
    ppstb.ppstbRelatedLinks.map(x => save.ppstbRelatedLinks.push(RelatedLinkBaseHandler.toSave(x)));
    save.relatedDocuments = [];
    ppstb.relatedDocuments.map(x => save.relatedDocuments.push(x));
    save.ppstbOtherFollowUps = [];
    ppstb.ppstbOtherFollowUps.map(x => save.ppstbOtherFollowUps.push(PPSTBOtherFollowUpHandler.toSave(x)));

    return save;
  }
}
