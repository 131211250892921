import { Component, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { RestrictionService } from 'src/app/controls/restrictions/services/restriction.service';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { Document } from 'src/app/services/documents/documents';

@Component({
  selector: 'cl-restrictions',
  templateUrl: './cl-restrictions.component.html',
  styleUrls: ['./cl-restrictions.component.scss']
})
export class ClRestrictionsComponent extends ClBaseComponent implements OnInit, OnChanges, OnDestroy {

  documents!: string;

  resource?: Resource;
  resources!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  task?: WfTaskLocalResource;
  manual!: boolean;

  constructor(
    protected override injector: Injector,
    private service: RestrictionService,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.resourcesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['valueString'] || changes['configuration'] || changes['disabled']) {
      this.loadResources();
      this.loadData();
    }
  }

  ngOnInit() {

  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data.length) {
        this.resources = data;
      }
    });
  }

  loadData() {
    const { val: locationID } = this.utils.JSONparse(this.configuration);
    this.resource = this.resources?.find(x => x.id == locationID);
    const wfSectionLocal = this.checklist?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.wfStepLocals?.some(t => t.wfTaskLocals.some(x => x.id === this.taskID)));
    const wfStepLocal = wfSectionLocal?.wfStepLocals?.find(s => s.wfTaskLocals.filter(x => x.id == this.taskID).length > 0);
    this.task = wfStepLocal?.wfTaskLocals?.find(x => x.id == this.taskID);
    const val = this.utils.JSONparse(this.valueString);
    this.checked = val?.user != null;
    this.manual = val?.manualCheck;
    this.documents = val?.documents;
    if (val?.user) {
      this.user = val.user === -1
        ? { id: val.user, name: 'ASCC', initials: 'ASCC' }
        : this.getUsers()?.find(u => u.id === val.user);
      if (!val.manualCheck)
        this.disabled = val.user === -1;
    }
    if (this.checked && !wfStepLocal?.wfStepStatusLocals?.filter(x => x.enabled || !x.hidden).map(x => x.status).includes(this.checklist?.status)) {
      this.displayInfo();
    }
    else {
      if (locationID && !val?.manualCheck && !this.disabled) {
        this.getRestrictions();
      }
    }
  }

  getRestrictions() {
    const { val: locationID } = this.utils.JSONparse(this.configuration);
    this.service.CheckByLocationID(locationID).toPromise().then(data => {
      if (data) {
        this.displayInfo(data);
        if (!this.editor && !this.builder) {
          this.checked = !data.length;
          this.disabled = !data.length;
          if (this.checked) {
            this.setValues(!data.length ? -1 : null);
          }
        }
      }
    });
  }

  reset() {
    this.manual = false;
    this.checkboxChanged({ checked: false });
  }

  displayInfo(documents: Document[] | null | undefined = null) {
    if (documents) {
      this.documents = documents.map(x => x.serialNo).join(', ');
      this.warning = documents.length > 0;
    }
    this.moreInfo = this.documents;
  }

  checkboxChanged(e: any) {
    this.checked = e.checked;
    if (this.checked && this.warning) {
      this.dialog.open(YesNoDialogComponent, {
        width: '500px',
        data: {
          message: this.getMessage('RestrictionsDocuments_Warning').description.replace('{location}', this.resource?.name ?? '').replace('{documents}', this.moreInfo ?? ''),
          icon: 'stop',
          labelButtonCancel: 'Cancel',
          labelButtonAccept: 'Continue',
          changeButtonLabels: true
        },
      }).afterClosed().subscribe(data => {
        if (data) {
          this.setValues(this.getCurrentUser()?.id);
        }
        else {
          this.checked = false;
          this.loadData();
        }
      });
    }
    else if (this.checked) {
      this.setValues(this.getCurrentUser()?.id);
    }
    else {
      this.setValues();
    }
  }

  setValues(userID?: number | null) {
    const valueString = this.utils.JSONstringify({ documents: this.documents, user: userID, manualCheck: (userID && userID != -1) });
    if (this.valueString != valueString) {
      this.save.emit({ text: valueString, sign: { checked: this.checked, isASCC: userID == -1 } });
    }
  }

}
