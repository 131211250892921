<div
  class="row mx-0 relative"
  *ngFor="let a of amendments"
  [ngClass]="{
    'border-amd': a > 0 && a == getLastAmendment(),
    'border-amd-old': a > 0 && a != getLastAmendment()
  }"
>
  <span
    [ngClass]="{
      'amendment-title': a == getLastAmendment(),
      'amendment-title-old': a != getLastAmendment()
    }"
    *ngIf="a > 0"
    >Amendment {{ a }}</span
  >
  <div class="col-12">
    <div class="row">
      <div *ngFor="let s of getSignaturesByAmendment(a)" class="col px-1">
        <master-control
          [type]="s.type"
          [visible]="s.visible"
          [roleIds]="s.roles"
          [roleCodes]="s.roleCodes"
          [userNames]="s.userNames"
          [approvedBy]="s.approvedBy"
          [checkbox_checked]="s.approved"
          [header]="s.name"
          [disabled]="s.disabled"
          [radioOptions]="s.options"
          [approved]="s.approved"
          [showApprove]="s.approve"
          [showUnapprove]="s.unapprove"
          [showDisapprove]="s.disapprove"
          [showCancel]="s.cancel"
          [cancelID]="s.cancelID"
          [approvedOn]="s.approvedOn"
          [taskId]="s.taskId"
          [enabled]="s.enabled"
          (approve)="approve(s, true, $event)"
          (checked)="check($event, s)"
          (unapprove)="unnaprove(s.taskId, s.unapproveId, $event)"
          (disapprove)="
            service.sign(form, s.taskId, s.disapproveId, $event.text)
          "
        >
        </master-control>
      </div>
    </div>
  </div>
</div>
