import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { Form } from 'src/app/services/forms/form';

@Component({
  selector: 'eb-main-s6',
  templateUrl: './eb-main-s6.component.html',
  styleUrls: ['./eb-main-s6.component.scss']
})
export class EbMainS6Component implements OnChanges {

  @Input() form?: Form | null;
  @Input() disabled!: boolean;
  @Input() showAmendment!: boolean;

  @Output() loading = new EventEmitter<boolean>()

  closeDisabled?: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    this.closeDisabled = this.form?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 5)?.wfSignatures?.some((x) => x.enabled && !x.signed);
  }

}
