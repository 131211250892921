import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ReviewCycle, ReviewRequestStatus } from '../../reviews.models';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { ReviewRequestComponent } from '../review-request/review-request.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ReviewCyclesRefresh } from '../../store/review-cycles/review-cycles.action';

@Component({
  selector: 'reviews-requests-table',
  templateUrl: './reviews-requests-table.component.html',
  styleUrls: ['./reviews-requests-table.component.scss']
})
export class ReviewsRequestsTableComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() statuses!: ReviewRequestStatus[];
  @Output() loading = new EventEmitter<boolean>();

  public reviewCycles$!: Observable<ReviewCycle[]>;
  public reviewCycleSubscription!: Subscription;
  public reviewCycles!: ReviewCycle[];

  public displayedColumns: string[] = ['serialNo', 'location', 'proposedTimeline', 'requestBy', 'requestOn', 'reviews'];
  public dataSource: MatTableDataSource<ReviewCycle> = new MatTableDataSource();

  constructor(protected override injector: Injector,
  ) {
    super(injector);
    this.store.dispatch(new ReviewCyclesRefresh());
  }


  override ngOnDestroy(): void {
    this.reviewCycleSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadReviewCycles();
  }

  ngOnInit(): void {
  }

  loadReviewCycles(): void {
    this.loading.emit(true);
    let isFirstTime = false;
    this.reviewCycles$ = this.store.select(state => state.ReviewCycles.data);
    this.reviewCycleSubscription = this.reviewCycles$.subscribe(reviewCycles => {
      this.reviewCycles = reviewCycles.sort((a, b) => this.utils.sort(a.serialNo, b.serialNo, false));
      this.reviewCycles.map(x => x.reviewRequestStatus = this.statuses.find(s => s.status == x.status));
      this.filterReviewCycles();
      if (isFirstTime || reviewCycles?.length) { this.loading.emit(false); }
      isFirstTime = true;
    });
  }

  filterReviewCycles() {
    if (this.reviewCycles?.length) {
      this.dataSource = new MatTableDataSource(this.reviewCycles.filter(x => this.statuses.filter(s => s.selected).map(s => s.status).includes(x.status ?? 0)));
    }
  }

  sortData(sort: Sort) {
    const dataToSort = this.utils.cloneDeep(this.dataSource.data);
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = dataToSort;
      return;
    }
    this.dataSource.data = dataToSort.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'serialNo': return this.utils.sort(a.serialNo, b.serialNo, isAsc);
        case 'description': return this.utils.sort(a.description, b.description, isAsc);
        case 'location': return this.utils.sort(a.location, b.location, isAsc);
        case 'proposedTimeline': return this.utils.sort(a.proposedTimeline, b.proposedTimeline, isAsc);
        default: return 0;
      }
    });
  }

  reviewRequestDialog(reviewRequest: ReviewCycle) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      reviewCycle: reviewRequest,
      isEvaluate: true
    };
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ReviewRequestComponent, dialogConfig);
    dialogRef.afterClosed().toPromise();
  }

}
