import { Component, EventEmitter, Input, Output, Injector, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormTableComponent } from 'src/app/controls/form-table/form-table.component';
import { FormService } from 'src/app/controls/form-table/form.service';
import { FormsUpdate } from 'src/app/controls/form-table/store/forms.action';
import { Form } from 'src/app/services/forms/form';
import { FormType } from 'src/app/common/enumerations/enumerations';

@Component({
  selector: 'eb-side',
  templateUrl: './eb-side.component.html',
  styleUrls: ['./eb-side.component.scss']
})
export class EbSideComponent extends BaseComponent {
  @Input() showAllForms?: boolean;
  @Input() formsFilter?: string;

  @Output() rowSelected = new EventEmitter<Form | null>();
  @Output() loading = new EventEmitter<boolean>();

  @ViewChild(FormTableComponent) formTable!: FormTableComponent;

  form?: Form | null;

  constructor(
    protected override injector: Injector,
    private formService: FormService,
  ) {
    super(injector);
  }

  async rowSelectedEvent(form?: Form | null) {
    if (form?.id != this.form?.id || form?.editingBy != this.form?.editingBy || form?.retrievedBy?.id != this.getCurrentUser()?.id) {
      if (form) {
        this.loading.emit(true);
        form = await this.formService.getById(form.id).toPromise();
        if (form?.id) {
          this.setOpenDocument(FormType.EBF, form.id);
          form.retrievedBy = this.getCurrentUser();
          this.store.dispatch(new FormsUpdate(form?.id, form));
          this.loading.emit(false);
          this.form = form;
          this.rowSelected.emit(form);
        }
      } else {
        this.formTable.form = form;
        this.rowSelected.emit(form);
        this.loading.emit(false);
      }
    }
  }

  unselect() {
    this.formTable.form = null;
    this.setOpenDocument(FormType.EBF);
  }

  formCreated(form: Form) {
    this.formTable.loadForms();
  }

}
