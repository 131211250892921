import { Component, Output, EventEmitter, ViewChild, ElementRef, Input, OnDestroy, OnInit, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { KEResource, SaveKEResource, SaveKEStatusResource } from '../../ke';
import { MatAutocomplete, MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { KEService } from '../../ke.service';
import { Observable, Subscription } from 'rxjs';
import { Roles, FormStatusEnum, ResourceType } from 'src/app/common/enumerations/enumerations';
import { CancelDialogComponent } from 'src/app/controls/cancel-dialog/cancel-dialog.component';
import { ShutterResource, Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { WFTaskSignatureResource, WfSignatureResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { utils } from 'src/app/modules/libs/utils';
@Component({
  selector: 'ke-section0',
  templateUrl: './ke-section0.component.html',
  styleUrls: ['./ke-section0.component.scss']
})
/** ke-section0 component*/
export class KESection0Component extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Output() requestRefresh = new EventEmitter();
  @Output() unselectTable = new EventEmitter();
  @Output() displayDocument = new EventEmitter<string>();
  @Output() loading = new EventEmitter<boolean>();

  @Input() ke?: KEResource | null;
  @Output() keChange = new EventEmitter<KEResource>();

  kes!: KEResource[];
  kes$!: Observable<KEResource[]>;
  kesSubs!: Subscription;

  shutter?: ShutterResource | null;
  resource?: Resource;
  resources!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  allShutters: ShutterResource[] = [];
  filteredShutters: ShutterResource[] = [];

  radioSelection = 1;
  wfTableID?: number | null;
  templateVersion?: string | null;

  serialNo!: string;
  saving = false;

  locLoading!: boolean;
  reqLoading = false;

  validRoles = [Roles.FO];
  public saveButtonText!: string;
  errorMsg: string[] = [];

  dateNow!: Date;
  disabled = true;
  disableControls!: boolean;
  showbranch!: boolean;

  brOptions: any[] = [{ label: 'Full', value: 1, checked: true }, { label: 'Abbreviated', value: 0 }];

  wfTaskSignature: WFTaskSignatureResource = new WFTaskSignatureResource();

  //#region Fields
  tmpDate?: Date = new Date();
  tmpOperator?: string | null = null;
  tmpSerialNo = '';
  tmpRequester = null;
  tmpShutter?: string | null;
  tmpRequesterName = null;
  tmpBranchline?: string | null = null;

  openKEs!: KEResource[];

  beamlines!: string | null;
  branchlines?: Resource[] | null;

  approveId!: number;
  disapproveId?: number;
  disapproveReason!: string;
  disapproved = false;

  taskId!: number;

  //#region booleans
  public showCreate = false;
  public disapprove = false;
  public unapprove = false;

  // #Form Controls
  public get shutterCtrl(): AbstractControl | null {
    return this.formGroup.get("shutterCtrl");
  }
  public get branCtrl(): AbstractControl | null {
    return this.formGroup.get("branCtrl");
  }

  @ViewChild('autoLoc') matAutocompleteLoc!: MatAutocomplete;
  @ViewChild('locationInput') locationInput!: ElementRef<HTMLInputElement>;
  @ViewChild('locationInput', { read: MatAutocompleteTrigger }) locationTrigger!: MatAutocompleteTrigger;

  //#region WF
  wfSignatures?: WfSignatureResource[];

  constructor(
    protected override injector: Injector,
    private _ke: KEService,
    private _wf: WorkFlowService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ke?.id) {
      this.refresh();
    }
    else if (this.ke?.id == 0 && this.ke.status == FormStatusEnum.New) {
      this.create();
    }
    else {
      this.clear();
    }
  }

  override ngOnDestroy(): void {
    this.kesSubs?.unsubscribe();
    this.resourcesSubs?.unsubscribe();
  }

  ngOnInit() {
    this.loadOpenKEs();
    this.loadAllShutters();
    if (!this.getCurrentUser()) {
      this.alert.warning(this.getMessage('user_not_logged')?.description);
    }
    this.buildForm();
  }

  clear() {
    this.tmpShutter = null;
    this.tmpOperator = this.getCurrentUser() ? this.getCurrentUser()?.name : null;
    this.tmpDate = new Date();
    this.tmpRequester = null;
    this.tmpRequesterName = null;
    this.saving = false;
    this.beamlines = null;
    this.shutter = null;
    this.showCreate = false;
    this.disapprove = false;
    this.approveId = 0;
    this.disapproveId = 0;
    this.templateVersion = null;
    this.disabled = true;
    this.disableControls = true;

    this.buildForm();
    this.formGroup.reset();
    this.shutterCtrl?.disable();
    this.branCtrl?.disable();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      shutterCtrl: [null],
      branCtrl: [null]
    });
    if (this.ke?.id && this.ke?.status != FormStatusEnum.Draft || !this.getCurrentUser()) {
      this.disabled = true;
      this.disableControls = true;
      this.shutterCtrl?.disable();
      this.branCtrl?.disable();
      this.formGroup.disable();
    }
  }

  create() {
    this.clear();
    this.setRadio(1);
    this.showCreate = true;
    this.disabled = false;
    this.loadOpenKEs();
    this.shutterCtrl?.enable();
    this.branCtrl?.enable();
    this.formGroup.enable();
    this.loading.emit(false);
    this.locLoading = false;
    this.tmpOperator = this.getCurrentUser()?.name;
  }

  refresh() {
    this.buildForm();
    this.disabled = true;
    this.showCreate = false;

    if (this.ke) {
      this.tmpShutter = this.ke.shutterName;
      this.tmpOperator = this.ke.createdByName;
      this.tmpDate = this.ke.createdOn;
      this.shutter = this.allShutters.find(x => x.id == this.ke?.resource?.id);
      this.setRadio(this.ke.type);
      this.shutterCtrl?.setValue(this.shutter);
      this.showbranch = this.ke.shutterId != this.ke.branchlineId;
      this.branchlines = this.shutter?.branchlines ? this.shutter?.branchlines : null;
      this.branCtrl?.setValue(this.branchlines?.find(x => x.id == this.ke?.branchlineId));
      this.showBeamline(this.shutter?.beamline, this.ke.branchlineName != this.ke.shutterName ? this.ke.branchlineName : null);
      this.tmpBranchline = this.ke.branchlineName;
      this.resource = this.shutter?.branchlines?.find(b => b.id == this.ke?.branchlineId);
      this.templateVersion = this.shutter?.keVersions?.find(v => v.branchlineId == this.ke?.branchlineId && v.shutterId == this.ke.shutterId && v.type == this.ke?.type && v.wfTableID == (this.ke.type == 1 ? this.shutter?.wfTableID : this.shutter?.wfTableIDAbreviated))?.name;

      this.shutterCtrl?.disable();
      this.branCtrl?.disable();

      this.wfSignatures = this.ke.wfSignatures?.filter(x => x.sectionNumber == 0);
      if (this.wfSignatures?.length) {
        this.approveId = this.wfSignatures[0]?.approveID;
        this.disapprove = !this.showCreate && this.checkRoles() && this.ke.status != FormStatusEnum.KeyEnable && this.ke.status != FormStatusEnum.Handshake && this.ke.status != FormStatusEnum.Closed && this.ke.status != FormStatusEnum.Canceled;
        this.disapproveId = this.wfSignatures[0]?.disapproveID;
        this.taskId = this.wfSignatures[0]?.taskID;
        this.disapproveReason = this.wfSignatures[0]?.disapproveReason;
        this.disapproved = this.disapproveReason != null && this.disapproveReason != '';
      }
    }
  }

  loadOpenKEs() {
    this.kes$ = this.store.select(state => state.KEnables.data);
    this.kesSubs = this.kes$.subscribe(data => {
      if (data.length > 0) {
        this.kes = data;
        this.openKEs = this.kes.filter(x => x.status != FormStatusEnum.Canceled && x.status != FormStatusEnum.Closed);
        if (this.ke && this.ke.id) {
          this.ke = this.kes.find(x => x.id == this.ke?.id);
          this.keChange.emit(this.ke);
        }
      }
    });
  }

  //#region Locations component functions:
  loadAllShutters() {
    this.resources$ = this.store.select(state => state.Resources.shutters);
    this.resourcesSubs = this.resources$.subscribe(data => {
      this.resources = data;
      this.allShutters = this.resources.filter(x => x.type == ResourceType.Shutter) as ShutterResource[];
      this.filteredShutters = this.allShutters.sort((a, b) => utils.SortBeamlines(a.name, b.name));
    });
  }



  isValid(ke: SaveKEResource) {
    this.errorMsg = [];
    const found = this.openKEs.find(x => x.branchlineId == ke.branchlineId && x.shutterId == ke.shutterId);
    if (found) {
      this.errorMsg.push(this.getMessage('KEChecklistAlreadyOpen').description + ' [' + found.serialNo + ']');
      return false;
    }
    return true;
  }



  setRadio(value?: number) {
    this.brOptions.map(r => {
      r.checked = r.value == value;
    });
  }

  showBeamline(bl?: Resource, branchline?: string | null) {
    if (bl != null) {
      this.beamlines = 'Affects ' + (branchline ? branchline : bl.name);
    }
  }

  formDisabled() {
    return (!this.formGroup.dirty || !this.formGroup.valid) && this.radioSelection != null;
  }

  checkRoles() {
    let ok = false;
    if (this.getCurrentUser()) {
      this.getCurrentUser()?.userRole?.map(r => {
        if (r.roleID && Roles[r.roleID] && this.validRoles.includes(r.roleID)) { ok = true; }
      });
    }
    return ok;
  }

  onLocChange(e: any) {
    if (!e.code?.includes('Arrow')) {
      this.filteredShutters = this.allShutters.filter(loc => loc.name.toLowerCase().includes(e.target.value.trim().toLowerCase()));
    }
  }

  displayShutter(shutter: Resource) {
    return shutter ? shutter.name : null;
  }

  selectedLoc(event: MatAutocompleteSelectedEvent): void {
    if (this.ke) {
      this.shutter = event.option.value;
      this.tmpShutter = this.shutter?.name;
      this.showBeamline(this.shutter?.beamline, null);
      this.branchlines = utils.cloneDeep(this.shutter?.branchlines);
      this.resource = this.shutter as Resource;

      this.wfTableID = this.shutter?.wfTableID;
      this.validateWFTableID();
      this.showbranch = (this.branchlines?.length ?? 0) > 0;
      if (this.showbranch) {
        const shutterBranchine = utils.cloneDeep(this.shutter) as Resource;
        if (shutterBranchine) {
          shutterBranchine.name = 'None';
          this.branchlines?.unshift(shutterBranchine);
          this.branCtrl?.setValue(this.shutter);
        }
      }

      this.ke.shutterId = this.shutter?.id;
      this.ke.shutterName = this.shutter?.name;
      this.ke.branchlineId = this.shutter?.id;
      this.keChange.emit(this.ke);
    }
  }

  selectedBran(e: any) {
    if (this.ke) {
      this.resource = e.option.value;
      this.showBeamline(this.shutter?.beamline, this.resource?.type == ResourceType.Branchline ? this.resource.name : null);
      this.wfTableID = this.radioSelection == 1 ? this.resource?.wfTableID : this.resource?.wfTableIDAbreviated;
      this.validateWFTableID();
      this.ke.branchlineId = this.resource?.id;
      this.keChange.emit(this.ke);
    }
  }

  //#endregion

  displayBranchline(branchline: Resource) {
    if (branchline?.type == ResourceType.Shutter) {
      return '';
    }
    return branchline ? branchline.name : null;
  }

  validateWFTableID() {
    if (this.ke) {
      this.wfTableID = this.radioSelection == 1 ? this.resource?.wfTableID : this.resource?.wfTableIDAbreviated;
      if (this.wfTableID == undefined || this.wfTableID == null || this.wfTableID == 0) {
        this.alert.error(this.getMessage('no_ketemplate').description.replace('{0}', this.radioSelection == 0 ? 'Abbreviated' : 'Full'));
        this.templateVersion = null;
      }
      else {
        this.templateVersion = this.shutter?.keVersions.find(v => v.status == 1 && v.resourceID == this.resource?.id && v.type == this.radioSelection)?.name;
        if (!this.templateVersion) {
          this.alert.error(this.getMessage('no_ketemplate').description.replace('{0}', this.radioSelection == 0 ? 'Abbreviated' : 'Full'));
        }
      }
      this.ke.versionName = this.templateVersion ?? undefined;
    }
  }

  reset() {
    this.clear();
  }

  cancel() {
    const confirm = this.dialog.open(CancelDialogComponent, {
      width: '600px',
      data: {}
    });
    confirm.afterClosed().toPromise().then(async (data) => {
      if (data?.accept) {
        this.loading.emit(true);
        const serialNo = this.ke?.serialNo;
        if (this.ke?.status == FormStatusEnum.Draft) {
          await this._ke.delete(this.ke.id).toPromise().catch(() => { this.loading.emit(false); });
          this.alert.info('Key-Enable <b>' + serialNo + ' has been Deleted!!');
          this.requestRefresh.emit(null);
          this.unselectTable.emit();
          this.loading.emit(false);
        }
        else {
          if (this.ke) {
            const status: SaveKEStatusResource = {
              id: this.ke.id,
              status: FormStatusEnum.Canceled
            };
            this.ke.status = FormStatusEnum.Canceled;
            this._ke.putStatus(status).subscribe(ke => {
              this.ke = ke;
              const disapproveSignature = this.ke.wfSignatures?.find(x => x.sectionNumber == 0);
              this.wfTaskSignature.taskId = this.taskId;
              this.wfTaskSignature.actionId = disapproveSignature?.disapproveID;
              this.wfTaskSignature.reason = data.text;

              this._wf.sign(this.wfTaskSignature).subscribe(res => {
                this.alert.info('Key-Enable <b>' + serialNo + ' has been Canceled!!');
                this.requestRefresh.emit(null);
                this.unselectTable.emit();
                this.loading.emit(false);
              }, error => {
                console.log(error);
                this.alert.error(error.error);
                this.loading.emit(false);
              });
              this.unselectTable.emit();
            });
          }
        }
      }
    });
  }

  sign(taskId: number, actionId: number) {
    if (this.ke) {
      const id = this.ke.id;
      this.wfTaskSignature.taskId = taskId;
      this.wfTaskSignature.actionId = actionId;
      this._wf.sign(this.wfTaskSignature).subscribe(() => {
      }, error => {
        console.log(error);
        this.alert.error(error.error);
      });
    }
  }

  radioChanged(e: any) {
    this.radioSelection = e.value;
    this.wfTableID = this.radioSelection == 1 ? this.resource?.wfTableID : this.resource?.wfTableIDAbreviated;
    this.validateWFTableID();
  }
}
