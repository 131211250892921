import { Component, OnInit, Inject, OnDestroy, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BaseComponent } from 'src/app/common/base/base.component';
import { UserCheck } from 'src/app/common/models/user-check';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';

@Component({
  selector: 'app-choos-users',
  templateUrl: './choose-users.component.html',
  styleUrls: ['./choose-users.component.scss']
})
export class ChooseUsersComponent extends BaseComponent implements OnInit, OnDestroy {

  usersFiltered!: UserCheck[];
  usersTable = new MatTableDataSource(this.users);
  loading = false;
  displayedColumns: string[] = ['choose', 'name', 'username', 'emailAddress'];
  selectedUsers: UserCheck[] = [];

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<ChooseUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number[]) {
    super(injector);

  }


  ngOnInit() {
    this.getUsers_();
  }

  getUsers_() {
    this.usersFiltered = this.getUsers().map(val => {
      const userCheck = (val as UserCheck);
      userCheck.isCheck = false;
      if (userCheck.id && this.data.includes(userCheck.id)) {
        userCheck.isCheck = true;
        this.selectedUsers.push(userCheck);
      }
      return userCheck;
    });
    this.usersTable.data = this.users;
    this.loading = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.selectedUsers);
  }

  checking(value: boolean, element: UserCheck) {
    if (value) {
      this.selectedUsers.push(element);
    } else {
      this.removeUser(element);
    }
  }


  getRecord(row: UserCheck) {
    row.isCheck = !row.isCheck;
    if (row.isCheck) {
      this.selectedUsers.push(row);
    } else {
      this.removeUser(row);
    }
  }

  removeUser(user: UserCheck) {
    const index: number = this.selectedUsers.indexOf(user);
    if (index >= 0)
      this.selectedUsers.splice(index, 1);
  }
}
