<div class="card">
  <div class="card-header">
    <span class="card-title">Attendance Categories</span>
    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="card-body">
    <div *ngIf="!reviewCategory">
      <div
        class="row scrollbar px-2 mb-3"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="col-12 review-box mb-2"
          *ngFor="let reviewCategory of reviewCategories"
        >
          <div cdkDrag class="row" [cdkDragDisabled]="reviewCategory.id < 0">
            <div class="col-1 pt-2">
              <mat-icon cdkDragHandle color="accent" class="drag"
                >drag_handle</mat-icon
              >
            </div>
            <div class="col-7 pt-2">
              {{ reviewCategory.order }}. {{ reviewCategory.name }}
            </div>
            <div class="col-4 d-flex justify-content-end">
              <button
                mat-icon-button
                (click)="edit(reviewCategory)"
                *ngIf="reviewCategory.id >= 0"
              >
                <mat-icon color="accent">edit</mat-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="
                  reviewCategory.reviewAttendanceRosters?.length == 0 &&
                  reviewCategory.id >= 0
                "
                (click)="delete(reviewCategory)"
              >
                <mat-icon color="warn">delete_outline</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="reviewCategory" class="flex-column">
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input
              #reviewCategoryCtrlElement
              type="text"
              placeholder="Name"
              matInput
              name="reviewCategoryNameCtrl"
              [formControl]="reviewCategoryNameCtrl"
            />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input
              type="text"
              placeholder="Description"
              matInput
              name="reviewCategoryDescriptionCtrl"
              [formControl]="reviewCategoryDescriptionCtrl"
            />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Acronym</mat-label>
            <input
              type="text"
              placeholder="Description"
              matInput
              name="reviewCategoryCodeCtrl"
              [formControl]="reviewCategoryCodeCtrl"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        color="accent"
        (click)="add()"
        *ngIf="!reviewCategory"
      >
        Add Category
        <mat-icon>add</mat-icon>
      </button>
      <div>
        <button
          mat-button
          color="warn"
          class="mr-2"
          (click)="cancel()"
          *ngIf="reviewCategory"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="!(form.dirty && formValid())"
          (click)="save()"
          *ngIf="reviewCategory"
        >
          <mat-icon>save</mat-icon>&nbsp;Save
        </button>
      </div>
    </div>
  </div>
</div>
