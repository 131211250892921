<div class="d-flex justify-content-center align-items-center">
  <div class="px-3" *ngIf="errorMessages.length">
    <div class="row">
      <div class="col-12 py-2 d-flex justify-content-center flex-wrap">
        <div class="red-bg pill" *ngFor="let e of displayedErrors">
          <span (click)="scrollTo(e)">{{ e.text }}</span>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="notDisplayedErrors?.length"
    class="red"
    [matTooltip]="getMoreErrors() ?? ''"
    matTooltipClass="warn-tooltip tooltip-wide tooltip-scroll"
  >
    and {{ notDisplayedErrors?.length }} more
    {{ notDisplayedErrors?.length == 1 ? "issue" : "issues" }} found!
  </div>
</div>
