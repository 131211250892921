import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'src/app/common/base/base.service';
import { ScheduleStatus } from '../models/schedule-status';

@Injectable({
  providedIn: 'root'
})
export class ScheduleStatusService extends BaseService<ScheduleStatus, number> {

  constructor(
    protected override injector: Injector,
  ) {
    super(injector, '/schedule_status');
  }

  UpdateMessage(id: number, message: string) {
    return this.http.put<ScheduleStatus>(this.URL + '/status_message', { id, message });
  }
}
