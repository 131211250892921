<div class="pt-2">
  <span [innerHTML]="getMenuMessage()"></span>
  <button mat-flat-button color="primary" *ngIf="!showButton()" (click)="add()">
    <mat-icon>add</mat-icon> Create
  </button>
  <button
    mat-flat-button
    class="ml-2"
    color="warn"
    *ngIf="showButton()"
    (click)="del()"
  >
    <mat-icon>delete_outline</mat-icon> Remove
  </button>
</div>
