import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Comment, CommentResource } from './comment';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  url = environment.urls.baseApiUrl + '/Comments';
  constructor(private http: HttpClient) { }

  Read(): Observable<CommentResource[]> {
    return this.http.get<CommentResource[]>(this.url);
  }

  ReadBySerialNo(serialno: string): Observable<CommentResource[]> {
    return this.http.get<CommentResource[]>(this.url + '/SerialNo/' + serialno);
  }

  ReadOne(id: number): Observable<CommentResource> {
    if (!id) { id = 0; }
    return this.http.get<CommentResource>(this.url + '/' + id.toString());
  }

  Create(comment: Comment): Observable<Comment> {
    return this.http.post<Comment>(this.url, comment);
  }

  Update(id: number, comment: Comment): Observable<Comment> {
    return this.http.put<Comment>(this.url + '/' + id.toString(), comment);
  }

  Delete(id: number) {
    return this.http.delete(this.url + '/' + id.toString());
  }
}
