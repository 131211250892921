<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col">
        <h5 class="card-title pb-3">Create RSS Functional Test Template</h5>
        <button
          mat-mini-fab
          class="closex"
          color="warn"
          (click)="dialogRef.close()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <mat-form-field class="no-subscript">
          <mat-label>Name</mat-label>
          <input
            aria-label="name"
            matInput
            type="text"
            [disabled]="loading || !!checklistTemplate"
            [(ngModel)]="name"
            css
          />
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field class="no-subscript">
          <mat-label>Type</mat-label>
          <mat-select
            [disabled]="loading || !!checklistTemplate"
            [(ngModel)]="templateType"
            (ngModelChange)="templateType = $event"
          >
            <mat-option
              *ngFor="let templateType of templateTypes"
              [value]="templateType"
            >
              {{ templateType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-3">
        <resource-autocomplete
          *ngIf="templateType?.resourceTypeID"
          [placeholder]="'Shutter'"
          [disabled]="loading || !!checklistTemplate"
          [customResources]="resources"
          [(resource)]="resource"
        ></resource-autocomplete>
      </div>

      <div class="col-1">
        <button
          *ngIf="!checklistTemplate"
          mat-flat-button
          color="primary"
          [disabled]="loading"
          (click)="createTemplate()"
        >
          Create
        </button>
      </div>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="card-body p-0">
    <div *ngIf="!checklistTemplate">
      <div class="row">
        <div class="col">
          <mat-tab-group #tabs mat-align-tabs="center" animationDuration="0">
            <mat-tab label="RSS Tests">
              <div class="mat-container mt-1 mb-3">
                <table mat-table [dataSource]="rssTestTemplatesDS">
                  <ng-container matColumnDef="version">
                    <th mat-header-cell *matHeaderCellDef>
                      <span>Version</span>
                    </th>
                    <td mat-cell *matCellDef="let e" class="pr-2">
                      <span>{{ e.serialNo }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="resource">
                    <th mat-header-cell *matHeaderCellDef>
                      <span>Resource</span>
                    </th>
                    <td mat-cell *matCellDef="let e" class="pr-2">
                      <span>{{ e.resource?.name }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>
                      <span>Type</span>
                    </th>
                    <td mat-cell *matCellDef="let e" class="pr-2">
                      <span>{{ e.templateType?.name }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="duplicate">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let e" class="pr-2">
                      <button
                        mat-icon-button
                        color="primary"
                        [matTooltip]="'Duplicate'"
                        (click)="duplicate(e)"
                      >
                        <mat-icon>content_copy</mat-icon>
                      </button>
                      <button
                        mat-icon-button
                        color="accent"
                        [matTooltip]="'View'"
                        (click)="openViewMode(e)"
                      >
                        <mat-icon>visibility</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="checklistTemplateColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: checklistTemplateColumns"
                  ></tr>
                </table>
              </div>
            </mat-tab>
            <mat-tab label="RSS Functional Tests">
              <div class="mat-container mt-1 mb-3">
                <table mat-table [dataSource]="rssFunctionalTestTemplatesDS">
                  <ng-container matColumnDef="version">
                    <th mat-header-cell *matHeaderCellDef>
                      <span>Version</span>
                    </th>
                    <td mat-cell *matCellDef="let e" class="pr-2">
                      <span>{{ e.serialNo }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="resource">
                    <th mat-header-cell *matHeaderCellDef>
                      <span>Resource</span>
                    </th>
                    <td mat-cell *matCellDef="let e" class="pr-2">
                      <span>{{ e.resource?.name }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>
                      <span>Type</span>
                    </th>
                    <td mat-cell *matCellDef="let e" class="pr-2">
                      <span>{{ e.templateType?.name }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="duplicate">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let e" class="pr-2">
                      <button
                        mat-icon-button
                        color="primary"
                        [matTooltip]="'Duplicate'"
                        (click)="duplicate(e)"
                      >
                        <mat-icon>content_copy</mat-icon>
                      </button>
                      <button
                        mat-icon-button
                        color="accent"
                        [matTooltip]="'View'"
                        (click)="openViewMode(e)"
                      >
                        <mat-icon>visibility</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="checklistTemplateColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: checklistTemplateColumns"
                  ></tr>
                </table>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
    <div *ngIf="checklistTemplate">
      <checklist-builder-reject
        [errorMessages]="errorMessages ?? []"
        *ngIf="errorMessages?.length"
      >
      </checklist-builder-reject>
      <div
        id="scrollableContainer"
        class="sections-container p-2"
        #scrollableContainer
        scrollDetector
      >
        <checklist-builder-sections
          [sections]="sections ?? []"
          [currentVersion]="checklistTemplate"
          [wfTableID]="checklistTemplate.wfTableID"
          [disabled]="false"
        ></checklist-builder-sections>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions *ngIf="checklistTemplate" align="center">
      <button
        mat-flat-button
        color="warn"
        [disabled]="loading"
        (click)="dialogRef.close()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        class="ml-2"
        [disabled]="loading || (errorMessages?.length ?? 0) > 0"
        (click)="createRSSFunctionalTest()"
      >
        Save
      </button>
    </div>
  </div>
</div>
