<a
  mat-menu-item
  *ngIf="menuItem.visible || editing"
  class="dropdown-item"
  [ngClass]="{
    'line-through': !menuItem.visible,
    'dropdown-edit': editing
  }"
  #menu
  [matMenuTriggerFor]="submenu"
  (mouseleave)="closeMenu()"
  (mouseover)="openMenu()"
  >{{ menuItem.name }}</a
>

<mat-menu class="menu-panel" #submenu="matMenu">
  <span
    (mouseover)="menuOver()"
    (mouseout)="menuOut()"
    *ngFor="let menuItem of menuItems; let i = index"
    class="d-flex align-items-center overflowX-hidden"
  >
    <a
      *ngIf="menuItem.type != menuItemTypes.PopUp"
      class="dropdown-item"
      [ngClass]="{
        'line-through': (!menuItem.visible || menuItem.status == 0) && editing,
        'display-none': (!menuItem.visible || menuItem.status == 0) && !editing
      }"
      [routerLink]="!editing ? menuItem.routerLink : null"
      (click)="actions?.edit(menuItem)"
      >{{ menuItem.name }}</a
    >
    <a
      *ngIf="menuItem.type == menuItemTypes.PopUp"
      class="dropdown-item"
      [ngClass]="{
        'line-through': (!menuItem.visible || menuItem.status == 0) && editing,
        'display-none': (!menuItem.visible || menuItem.status == 0) && !editing
      }"
      (click)="
        !editing ? openComponent(menuItem.route ?? '') : actions?.edit(menuItem)
      "
      >{{ menuItem.name }}</a
    >
    <menu-config-actions
      *ngIf="editing && !menuItem.disableEdit"
      [SA]="SA"
      [menuItem]="menuItem"
      [menuItems]="menuItems"
      [index]="i"
      [orientation]="orientationEnum.Vertical"
    ></menu-config-actions>
  </span>
  <!-- </span> -->
</mat-menu>
