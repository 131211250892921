import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MenuItem, MenuItemTypes, MenuValidation } from './head-menu.models';
import { utils } from 'src/app/modules/libs/utils';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Roles } from 'src/app/common/enumerations/enumerations';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { AppStateService } from 'src/app/store/app-state-service';
import { ComponentRegistry } from 'src/app/common/extensions/decorator';

@Injectable({
  providedIn: 'root'
})
export class HeadMenuService {
  BASE_URL = environment.urls.baseApiUrl; // Api URL
  Api = '/Menu';

  currentUser!: User | null;
  // signatures: ProcedureSignature[];
  unsubs!: Subscription;
  components!: any[];

  constructor(
    private http: HttpClient,
    private ass: AppStateService,
    private componentRegistry: ComponentRegistry,
    private dialog: MatDialog
  ) {

  }

  getAll(): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(this.BASE_URL + this.Api);
  }

  createMenu(menuItem: MenuItem): Observable<MenuItem> {
    return this.http.post<MenuItem>(this.BASE_URL + this.Api, menuItem);
  }

  deleteMenu(menuItemId: number) {
    const httpresult = this.http.delete(this.BASE_URL + this.Api + '/' + menuItemId);
    return httpresult;
  }

  calculateRouterLinks(menuItems: MenuItem[]): MenuItem[] {
    this.unsubs = this.ass.AppState.subscribe(appState => {
      this.currentUser = appState?.currentUser;
    });
    menuItems.map(m => {
      if (m.route) {
        if (m.tab) {
          m.routerLink = [m.route, m.tab];
        }
        else {
          m.routerLink = [m.route];
        }
      }
      if (m.menuValidation == MenuValidation.ByRole) {
        m.visible = this.calculateRoles(m) && m.status != 0;
      }
      else {
        m.visible = m.status != 0;
      }
    });
    return menuItems;
  }

  calculateRoles(menuItem: MenuItem) {
    if (menuItem.childMenuItems?.length) {
      this.menuValidation(menuItem);
      menuItem.visible = menuItem.childMenuItems.some(m => m.visible);
      return menuItem.visible;
    }
    else {
      if (menuItem.type == MenuItemTypes.Separator) {
        return false;
      }
      if (menuItem.menuItemRoles?.length == 0 || !menuItem.menuItemRoles) {
        return true;
      }
      else {
        const result = utils.intersect(menuItem.menuItemRoles.map(x => x.roleID), this.currentUser?.userRole?.map(x => x.roleID));
        return result;
      }
    }
  }

  menuValidation(menuItem: MenuItem) {
    menuItem.childMenuItems?.map(async m => {
      if (m.menuValidation == MenuValidation.ByRole) {
        m.visible = this.calculateRoles(m);
      }
      else {
        m.visible = true;
      }
    });
    return menuItem;
  }

  isSA() {
    return this.currentUser?.userRole?.map(r => r.roleID).includes(Roles.SA);
  }

  openComponent(name: string) {
    const componentType = this.componentRegistry.components?.[name];

    if (!componentType) {
      console.error(`Component with name '${name}' not found in the registry.`);
      return;
    }
    console.log(this.componentRegistry.components);
    console.log(componentType);
    const dialogRef = this.dialog.open(componentType);
    dialogRef.afterClosed().subscribe((data) => {
      // Handle dialog close event
    });
  }

}
