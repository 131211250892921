<mat-progress-bar
	class="progress-bar"
	mode="indeterminate"
	*ngIf="loading"
></mat-progress-bar>
<div
	class="row"
	*ngFor="
    let reviewattachmentCategories of reviewattachmentCategoriesFiltered;
    let i = index
  "
>
	<div class="col-12">
		<h6>{{ reviewattachmentCategories.name }}</h6>
	</div>
	<div class="col-12">
		<div class="pb-3 mat-table-container">
			<div class="mat-container scrollbar">
				<mat-table [dataSource]="dataSources[i]" matSort>
					<ng-container matColumnDef="name">
						<mat-header-cell *matHeaderCellDef mat-sort-header
							>Doc / Link</mat-header-cell
						>
						<mat-cell class="font-weight-bold" *matCellDef="let element" nowrap>
							<a
								*ngIf="element.url != null"
								href="{{ element.url }}"
								target="_blank"
								>{{ element.name }}</a
							>
							<a (click)="SearchFile(element)" *ngIf="element.url == null">
								{{ element.name }}
							</a>
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="attachmentUser">
						<mat-header-cell *matHeaderCellDef mat-sort-header>
							Uploaded By
						</mat-header-cell>
						<mat-cell class="pl-1" *matCellDef="let element">
							{{ element.createdByName }}
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="attachmentDate">
						<mat-header-cell *matHeaderCellDef mat-sort-header>
							Attachment Date
						</mat-header-cell>
						<mat-cell class="pl-1" *matCellDef="let element">
							{{ element.createdOn | date }}
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="type">
						<mat-header-cell *matHeaderCellDef mat-sort-header>
							Type
						</mat-header-cell>
						<mat-cell class="pl-1" *matCellDef="let element">
							{{ element.type == 2 ? "Link" : "File" }}
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="actions" *ngIf="canDelete">
						<mat-header-cell *matHeaderCellDef class="w-80">
							Actions
						</mat-header-cell>
						<mat-cell *matCellDef="let element" class="w-80">
							<button
								mat-icon-button
								color="warn"
								[matTooltip]="'Delete File'"
								(click)="deleteReviewDocument(element)"
								*ngIf="canDelete"
							>
								<mat-icon class="icon">delete</mat-icon>
							</button>
						</mat-cell>
					</ng-container>

					<mat-header-row
						*matHeaderRowDef="displayedColumns; sticky: true"
					></mat-header-row>
					<mat-row
						#matrow
						*matRowDef="let row; columns: displayedColumns"
						class="table-row ws-space"
						[ngClass]="{
              'mat-row-highlight': selectedRowIndex == row.id
            }"
					></mat-row>
				</mat-table>
			</div>
		</div>
	</div>
</div>
