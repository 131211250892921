import { Component, ElementRef, HostListener, Injector, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Document } from '../../../services/documents/documents';
import { RadMonitorSettingsComponent } from './settings/settings.component';
import { RadMonitorLocationTabComponent } from './location-tab/location-tab.component';
import { RadMonitorTabComponent } from './monitor-tab/monitor-tab.component';

@Component({
  selector: 'app-rad-monitor',
  templateUrl: './rad-monitor.component.html',
  styleUrls: ['./rad-monitor.component.scss']
})
export class RadMonitorComponent extends BaseComponent {

  @ViewChild(RadMonitorTabComponent) monitors?: RadMonitorTabComponent;
  @ViewChild(RadMonitorLocationTabComponent) locations?: RadMonitorLocationTabComponent;

  links = [{ id: 0, name: 'Rad Monitor Schedule & Inventory' }, { id: 1, name: 'Locations' }];
  selectedTab: number = 0;

  disabled: boolean = false;

  loading = false;

  resourceRequired: boolean = false;

  filterCtrl = new FormControl();

  errorMessages: string[] = [];
  headerOnTop: boolean = false;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter: any[] = [];
  currentFilter: any;
  filter: any;

  loadingMessage!: string;


  ngAfterViewInit(): void {
    this.setHeights();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.moduleTitle = 'Rad Monitor Catalog';
    this.checkPrivileges();
  }

  onTabChanged(link: any) {
    this.selectedTab = link.id;
  }

  clear() {
    this.errorMessages = [];
  }

  create() {
    this.monitors?.monitorDialog();
  }

  createLocation() {
    this.locations?.locationDialog();
  }

  //////
  changedFilter() { }
  clearFilter() { }
  selectedFilter() { }
  createDisabled() { }
  editDisabled() { }
  action() { }

  settings() {
    const settings = this.dialog.open(RadMonitorSettingsComponent, {
      width: "50vw", height: '65vh', disableClose: true,
    });
    settings.afterClosed().subscribe(() => { });
  }

  applyFilter(e: any) {
    if (!e)
      this.valueFilter = '';
    this.filter = e;
  }


  buttonsVisible: boolean | null = false;

  checkPrivileges() {

  }

  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 1000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Buttons:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Main:
          this.loadingMain = e as boolean;
          break;
      }
      // console.log('m: ' + this.loadingMain + '   b: ' + this.loadingButtons + '   s: ' + this.loadingSide);
      this.loading = this.loadingMain || this.loadingButtons || this.loadingSide;
    }, 100);
  }

  // mainValid(): boolean {
  //   // return this.main.formValid();
  // }

  loadingSide = false;
  loadingButtons = false;
  loadingMain = false;
  loadingOrigin = LoadingOrigin;


}

enum LoadingOrigin {
  Side = 1, Buttons = 2, Main = 3
}
