<div #filterBox>
  <mat-form-field class="filter-box no-subscript pt-1">
    <mat-label>Filter</mat-label>
    <input
      matInput
      [(ngModel)]="valueFilter"
      (keyup)="applyFilter($event)"
      placeholder="Text to filter"
    />
    <mat-icon
      *ngIf="!valueFilter"
      class="filter-icon"
      color="primary"
      (click)="applyFilter($event)"
      >search</mat-icon
    >
    <mat-icon
      *ngIf="valueFilter"
      class="filter-icon pointer"
      color="warn"
      (click)="applyFilter(null)"
      >close</mat-icon
    >
  </mat-form-field>
</div>
<div
  class="results border py-2 white-background"
  [ngClass]="{ invisible: !filteredValues?.length }"
  #results
>
  <div
    class="item row px-2 py-1"
    *ngFor="let item of filteredValues"
    (click)="itemSelected(item)"
  >
    <div class="col">
      <span
        class="item-content"
        [innerHTML]="item.text | highlightSearch : valueFilter"
      ></span>
    </div>
  </div>
</div>
