import * as QuillNamespace from 'quill';
import { ImageActionsIds, QuillCustomClasses } from './quill-custom-names';
import { CustomImageSizeOptions } from 'src/app/common/enumerations/enumerations';
import { utils } from '../../utils';

export default class CustomImageResize {
  Quill: any = QuillNamespace;
  container: any;
  resizeOptionSelected?: CustomImageSizeOptions;
  smallId: string = ImageActionsIds.smallId;
  fitId: string = ImageActionsIds.fitId;
  largeId: string = ImageActionsIds.largeId;
  imageSizesClass: string = QuillCustomClasses.custom_image_sizes_class;
  imageClicked: boolean = false;
  constructor(
    quill: {
      root: {
        addEventListener: (arg0: string, arg1: (ev: any) => void) => void;
      };
      getSelection: (arg0: boolean) => any;
      getBounds: (arg0: any, arg1: any) => any;
      container: { firstChild: { childNodes: any }; scrollTop: any };
    },
    options: any
  ) {
    quill.root.addEventListener('click', (ev: { target: unknown }) => {
      const ImageBlot = this.Quill.import('formats/image');
      const Parchment = this.Quill.import('parchment');
      const image = Parchment.find(ev.target);

      const Embed = Parchment.Embed; // base "Embed" blot without any content
      class CustomImageResizeBlot extends Embed {
        static create(data: { value: any }) {
          const node = super.create(data.value);

          node.innerHTML += data.value;
          return node;
        }

        static value(domNode: { getAttribute: (arg0: string) => any }) {
          return domNode.getAttribute('data-value');
        }
      }
      CustomImageResizeBlot['blotName'] = 'imageResize';
      CustomImageResizeBlot['tagName'] = 'span';
      CustomImageResizeBlot['className'] = this.imageSizesClass;
      this.Quill.register(CustomImageResizeBlot);

      if (image instanceof ImageBlot) {
        this.imageClicked = true;
        const range = quill.getSelection(true);

        const bounds = quill.getBounds(range?.index ?? 0, range?.length ?? 0);
        // utils.removeExistingCustomContainers();
        this.Quill.debug('error'); // Change of Quill debug setting to supress warning after inserting the custom blot
        let i = -1;

        for (const childNode of quill.container.firstChild.childNodes) {
          i = Array.from(childNode.childNodes).indexOf(ev.target);
          if (i != -1) {
            const nextSibling = childNode.childNodes[i + 1];
            this.insertCustomSpan(quill);
            const elements = document.getElementsByClassName(
              this.imageSizesClass
            );

            if (nextSibling) {
              childNode.insertBefore(elements[0], nextSibling);
            } else {
              childNode.childNodes[i].parentNode.appendChild(elements[0]);
            }
            const customElement = document.querySelector(
              '.' + this.imageSizesClass
            ) as HTMLElement;
            customElement.style.position = 'relative';
            const offsetLeft =
              childNode.childNodes[i].nodeName === 'IMG'
                ? childNode.childNodes[i].offsetLeft
                : childNode.childNodes[i + 1].offsetLeft;

            if (offsetLeft > 0) {
              customElement.style.left = (offsetLeft - 15).toString() + 'px';
            }

            const previousSiblingBeforeDotContainer =
              document.getElementsByClassName('ql-tooltip');

            // Hidding the resize dotted rectangule in mobiles
            if (
              screen.width < 1024 &&
              previousSiblingBeforeDotContainer.length > 0
            ) {
              const next = previousSiblingBeforeDotContainer[0]
                .nextSibling as HTMLElement;
              next.style.display = 'none';
            }

            break;
          }
        }

        quill.container.scrollTop = bounds.top;
        const smallBtn = document.getElementById(this.smallId);
        if (smallBtn)
          smallBtn.onclick = function () {
            utils.resizeImage(CustomImageSizeOptions.Small, ev);
          };
        const fitBtn = document.getElementById(this.fitId);
        if (fitBtn)
          fitBtn.onclick = function () {
            utils.resizeImage(CustomImageSizeOptions.Fit, ev);
          };
        const largeBtn = document.getElementById(this.largeId);
        if (largeBtn)
          largeBtn.onclick = function () {
            utils.resizeImage(CustomImageSizeOptions.Original, ev);
          };
      } else {
        if (this.imageClicked) {
          utils.removeExistingCustomContainers();
          this.imageClicked = false;
        }
      }
    });
  }

  insertCustomSpan(quill: any) {
    const content = `<br><a id=${this.smallId} style="cursor: pointer">Small</a> | <a id=${this.fitId} style="cursor: pointer">Fit</a> | <a id=${this.largeId} style="cursor: pointer">Original</a><br>`;
    const range = quill.getSelection(true);

    quill.insertEmbed(
      range.index,
      'imageResize',
      {
        index: 0,
        id: 'id',
        value: content,
      },
      'api'
    );
  }
}
