import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'src/app/common/base/base.service';
import { RoleScheduleReminder } from '../models/role-schedule-reminder';

@Injectable({
  providedIn: 'root'
})
export class RoleReminderService extends BaseService<RoleScheduleReminder, number> {

  constructor(protected override injector: Injector) {
    super(injector, '/role_reminder');
  }

  readByReminder(firstId: number) {
    const url = this.endpoint + '/by_reminder/' + firstId;
    return this.http.get<RoleScheduleReminder[]>(url);
  }
}
