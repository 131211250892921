import { ENTER, TAB } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { Placeholder } from 'src/app/components/checklists/checklists';
import { RolePrivilegeService } from 'src/app/services/role-privilege/role-privilege.service';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { RolePrivilege } from 'src/app/components/catalogs/roles/services/role-privilege';

@Component({
  selector: 'rad-monitor-add-edit-privilege',
  templateUrl: './add-edit-privilege.component.html',
  styleUrls: ['./add-edit-privilege.component.scss']
})
export class RadMonitorAddEditPrivilegeComponent extends BaseComponent implements OnInit, OnDestroy {

  rolePrivileges?: RolePrivilege[];
  rolePrivilegesFiltered?: RolePrivilege[];
  rolePrivileges$!: Observable<RolePrivilege[]>;
  rolePrivilegesSubs!: Subscription;

  roles?: Role[];
  rolesFiltered?: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  public radMonitorPrivileges: PrivilegeManagement[] = [];

  public loading = false;
  public saving = false;

  public selectable = true;
  public removable = true;
  public addOnBlur = true;

  constructor(
    protected override injector: Injector,
    private rolePrivilegeService: RolePrivilegeService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loadRoles();
    this.loadRolePrivileges();
  }

  override ngOnDestroy(): void {
    this.rolesSubs?.unsubscribe()
    this.rolePrivilegesSubs?.unsubscribe();
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      if (data?.length) {
        this.roles = data;
        this.rolesFiltered = data;
      }
    });
  }

  loadRolePrivileges() {
    this.rolePrivileges$ = this.store.select(state => state.RolePrivileges.data);
    this.rolePrivilegesSubs = this.rolePrivileges$.subscribe(data => {
      if (data?.length) {
        this.rolePrivileges = data;
        this.getRadMonitorPrivileges();
      }
    });
  }

  getRadMonitorPrivileges() {
    this.radMonitorPrivileges.push({ name: 'Create Rad Monitor', privilegeId: 79, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 79) });
    this.radMonitorPrivileges.push({ name: 'Edit Rad Monitor', privilegeId: 115, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 115).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Delete Rad Monitor', privilegeId: 117, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 117).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Create And Edit Rad Monitor Type', privilegeId: 80, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 80).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Create And Edit Rad Monitor Description', privilegeId: 81, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 81).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Create Rad Location', privilegeId: 82, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 82).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Edit Rad Location', privilegeId: 116, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 116).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Delete Rad Location', privilegeId: 118, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 118).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Create And Edit Rad Location Type', privilegeId: 83, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 83).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Create And Edit Rad Location Interlock', privilegeId: 84, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 84).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Create Rad Monitor Note', privilegeId: 85, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 85).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Create Rad Location Note', privilegeId: 86, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 86).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Create Rad Monitor Fault Note', privilegeId: 112, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 112).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Delete Rad Monitor Note', privilegeId: 113, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 113).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Delete Rad Location Note', privilegeId: 114, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 114).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Delete Rad Monitor FaultNote', privilegeId: 152, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 152).filter(x => x.roleID) });
    this.radMonitorPrivileges.push({ name: 'Add RadLocation EMShop', privilegeId: 153, rolePrivileges: this.rolePrivileges?.filter(x => x.privilege === 153).filter(x => x.roleID) });
    this.loading = false;
  }


  selected(role: Role, privilegeId: number): void {
    this.saving = true;
    const rolePrivilege: RolePrivilege = {
      roleID: role.id,
      privilege: privilegeId,
      role: new Role()
    };
    this.rolePrivilegeService.createRolePrivilege(rolePrivilege).toPromise()
      .then(rolePrivilegeCreated => {
        if (rolePrivilegeCreated)
          this.radMonitorPrivileges.find(x => x.privilegeId === privilegeId)?.rolePrivileges?.push(rolePrivilegeCreated);
        this.saving = false;
      })
      .catch((error: HttpErrorResponse) => {
        if (error.status === 409) {
          this.alert.message('RadPrivilege_AlreadyAdded', [new MessagePlaceholder('{what}', error.error)]);
        } else {
          this.alert.defaultError();
        }
        this.saving = false;
      });
  }

  remove(rolePrivilege: RolePrivilege, privilegeId: number): void {
    this.saving = true;
    const radMonitorPrivilege = this.radMonitorPrivileges.find(x => x.privilegeId === privilegeId);
    const index = radMonitorPrivilege?.rolePrivileges?.findIndex(x => x.id === rolePrivilege.id) ?? -1;
    if (index >= 0 && rolePrivilege.id) {
      this.rolePrivilegeService.deleteRolePrivilege(rolePrivilege.id).toPromise()
        .then(() => {
          radMonitorPrivilege?.rolePrivileges?.splice(index, 1);
          this.saving = false;
        })
        .catch(() => {
          this.alert.defaultError();
          this.saving = false;
        });
    }
  }

  applyFilter(e: any) {
    const filterValue = e.target.value;
    this.rolesFiltered = this.roles?.filter(x => x.name?.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) || x.code?.toLowerCase().includes(filterValue.toLowerCase()));
  }

}

class PrivilegeManagement {
  name!: string;
  privilegeId!: number;
  rolePrivileges?: RolePrivilege[];
}
