<div class="row">
  <div class="col-12 col-sm-12">
    <mat-label>{{ componentTitle }}</mat-label>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12 col-sm-12">
    <div class="mat-container">
      <mat-table #table [dataSource]="dataSourceLink">
        <ng-container matColumnDef="hyperlink">
          <mat-header-cell *matHeaderCellDef> Hyperlinks </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a
              *ngIf="element?.webAddress"
              href="{{ element.webAddress }}"
              target="_blank"
              >{{ element.hyperlink }}</a
            >
            <a *ngIf="!element?.webAddress">{{ element.hyperlink }}</a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="webAddress">
          <mat-header-cell *matHeaderCellDef> Web Address </mat-header-cell>
          <mat-cell *matCellDef="let element"
            >{{ element.webAddress }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="add">
          <mat-header-cell *matHeaderCellDef>
            <mat-icon mat-list-icon class="add" (click)="openAddLinkDialog()"
              >add</mat-icon
            >
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon
              mat-list-icon
              color="accent"
              (click)="openEditLinkDialog(element)"
              >edit</mat-icon
            >
            <mat-icon mat-list-icon color="warn" (click)="deleteLink(element)"
              >delete</mat-icon
            >
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="showChanges">
          <mat-header-cell *matHeaderCellDef>
            <mat-slide-toggle
              *ngIf="!loading"
              [(ngModel)]="showChanges"
              [ngModelOptions]="{ standalone: true }"
              (change)="changesLinks()"
            >
              Show Changes
            </mat-slide-toggle>
          </mat-header-cell>
          <mat-cell *matCellDef="let element"></mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="displayedColumnsLink"
        ></mat-header-row>
        <mat-row
          [ngClass]="{ prevResource: !row.status }"
          *matRowDef="let row; columns: displayedColumnsLink"
        ></mat-row>
      </mat-table>
    </div>
  </div>
</div>
