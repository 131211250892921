<div class="row mt-3">
  <div class="col">
    <h5>Approvals</h5>
  </div>
</div>
<form-workflow
  [form]="form"
  [sectionNumber]="2"
  [amendmentSectionNumbers]="[12]"
  [leftConfigs]="[101, 103, 105, 113, 115, 117]"
  [rightConfigs]="[102, 104, 105, 114, 116, 118]"
  [disabled]="disabled"
  (loading)="loading.emit($event)"
>
</form-workflow>
