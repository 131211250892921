<a
  class="nav-link dropdown-toggle p-0"
  href="#"
  data-toggle="dropdown"
  role="button"
  aria-haspopup="true"
  aria-expanded="false"
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="menu"
  >{{ menuItem.name }}</a
>
<mat-menu class="menu-panel" #menu="matMenu">
  <span
    *ngFor="let menuItem of menuItems; let i = index"
    class="d-flex align-items-center"
  >
    <a
      *ngIf="
        menuItem.routerLink &&
        (menuItem.visible || editing) &&
        menuItem.type == menuItemTypes.Normal
      "
      class="dropdown-item"
      [ngClass]="{
        strikeout: !menuItem.visible && editing,
        'dropdown-edit': editing
      }"
      [routerLink]="!editing ? menuItem.routerLink : null"
      (click)="actions?.edit(menuItem)"
      >{{ menuItem.name }}
    </a>
    <a
      *ngIf="
        !menuItem.isSeparator &&
        menuItem.routerLink &&
        (menuItem.visible || editing) &&
        menuItem.type == menuItemTypes.Link
      "
      class="dropdown-item"
      [ngClass]="{
        strikeout: !menuItem.visible && editing,
        'dropdown-edit': editing
      }"
      target="_blank"
      href="{{ !editing ? menuItem.route : null }}"
      (click)="actions?.edit(menuItem)"
    >
      {{ menuItem.name }}
    </a>
    <a
      *ngIf="menuItem.visible && menuItem.type == menuItemTypes.PopUp"
      class="dropdown-item"
      [ngClass]="{
        strikeout: !menuItem.visible && editing,
        'dropdown-edit': editing
      }"
      (click)="
        !editing ? openComponent(menuItem.route ?? '') : actions?.edit(menuItem)
      "
      >{{ menuItem.name }}</a
    >
    <head-menu-submenu
      *ngIf="
        (menuItem.status || menuItem.visible || editing) &&
        menuItem.childMenuItems &&
        menuItem.type == menuItemTypes.Submenu
      "
      [menuItem]="menuItem"
      [editing]="editing"
      class="full-width"
      [ngClass]="{
        strikeout: !menuItem.visible && editing,
        'dropdown-edit': editing
      }"
    ></head-menu-submenu>
    <hr *ngIf="menuItem.type == menuItemTypes.Separator" />
    <menu-config-actions
      *ngIf="editing && !menuItem.disableEdit"
      [menuItem]="menuItem"
      [menuItems]="menuItems"
      [index]="i"
      [orientation]="orientationEnum.Vertical"
    ></menu-config-actions>
  </span>
</mat-menu>
