<div
  class="card p-0"
  [ngClass]="{ 'fixed-panel': routerPath == '/beamline-overview' }"
>
  <div class="card-header pt-1 pb-1">
    <div
      #header1
      [ngClass]="{
        fixed: !(routerPath == '/beamline-overview'),
        'pt-3': headerOnTop
      }"
      [style.max-width]="routerPath == '/beamline-overview' ? null : tableWidth"
    >
      <div class="row align-items-center">
        <div class="col-12 col-sm-6">
          <div class="card-title">Beamline Status</div>
        </div>
        <div class="col-12 col-sm-6">
          <filter-box (changed)="applyFilter($event)"></filter-box>
        </div>
      </div>

      <div
        class="d-flex flex-wrap mb-0 mt-0 justify-content-between align-items-end"
      >
        <div class="mr-2">
          <p class="small">Beamline Color Key:</p>
        </div>
        <div class="">
          <p class="blue">
            <mat-icon class="dot">fiber_manual_record</mat-icon>Active
          </p>
        </div>
        <div class="">
          <p class="yellow">
            <mat-icon class="dot">fiber_manual_record</mat-icon>Commissioning
          </p>
        </div>
        <div class="">
          <p class="text-decommissioned">
            <mat-icon class="dot">fiber_manual_record</mat-icon>Decommissioned
          </p>
        </div>
        <div class="">
          <p class="gray">
            <mat-icon class="dot">fiber_manual_record</mat-icon>Inactive
          </p>
        </div>
      </div>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div #beamStatus class="card-body p-0">
    <div
      class="mat-container-overview"
      [ngClass]="{ scrollbar: routerPath == '/beamline-overview' }"
      [style.max-height]="routerPath == '/beamline-overview' ? maxHeight : null"
    >
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="beamlineName">
          <mat-header-cell *matHeaderCellDef> Beamline </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <button
              type="button"
              class="btn btn-link"
              [ngClass]="{
                blue: element.status == 1,
                yellow: element.status == 2,
                black: element.status == 3,
                gray: element.status == 4
              }"
            >
              <span
                [innerHTML]="element.beamlineName | highlightSearch : filter"
              ></span>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="shutterName">
          <mat-header-cell *matHeaderCellDef> Shutter </mat-header-cell>
          <mat-cell class="small" *matCellDef="let element">
            <span
              [innerHTML]="element.shutterName | highlightSearch : filter"
            ></span
          ></mat-cell>
        </ng-container>
        <ng-container matColumnDef="shutterStatus">
          <mat-header-cell *matHeaderCellDef> PSS </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div
              [ngClass]="getClass(element.pssChannelStatus)"
              *ngIf="element.status != 3"
            >
              {{ element.pssChannelStatus }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="rssStatus">
          <mat-header-cell *matHeaderCellDef> RSS </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div
              [ngClass]="getClass(element.rssChannelStatus)"
              *ngIf="element.status != 3"
            >
              {{ element.rssChannelStatus }}
            </div>
            <span
              *ngIf="element.status == 3"
              [ngClass]="{ decomSize: element.status == 3 }"
              style="font-weight: bolder"
              >Decommissioned</span
            >
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="keReady">
          <mat-header-cell *matHeaderCellDef> KE Ready? </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div
              class="{{ element.keReady.cssClass }} badge-pill"
              [matTooltip]="element.moreInfo"
            >
              {{ element.keReady.ready ? "Ready" : "Not Ready" }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="restrictions">
          <mat-header-cell *matHeaderCellDef> Op Restrictions </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span
              class="restriction"
              *ngIf="
                element.restrictions?.length || element.hasPendingRestrictions
              "
              [ngClass]="{
                pending:
                  element.restrictions?.length &&
                  element.hasPendingRestrictions,
                'only-pending':
                  !element.restrictions?.length &&
                  element.hasPendingRestrictions
              }"
              [matTooltip]="element.restrictionsTooltip"
              matTooltipPosition="right"
              matTooltipClass="tooltip-white tooltip-wide"
            >
              {{ element.restrictions.length }}
            </span>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns"
          [ngClass]="{
            'container-fluid p-0': true,
            'fixed-headers':
              routerPath == '/beamline-overview' ? null : headerOnTop,
            'sticky-headers': routerPath == '/beamline-overview'
          }"
          [style.max-width]="
            routerPath == '/beamline-overview' ? null : tableWidth
          "
          [style.top]="routerPath == '/beamline-overview' ? null : headerBottom"
        >
        </mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="table-row"
          [ngClass]="{
            'mat-row-highlight': sendIdService.id == row.beamlineId
          }"
          (click)="rowClick(row.beamlineId)"
        ></mat-row>
      </mat-table>
    </div>
  </div>
</div>
