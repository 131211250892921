import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, Injector, } from "@angular/core";
import { SCFPrivileges, SCFStatus, ScfV2Service } from "../../../scf-v2.service";
import { FormStatusEnum, SignatureAction } from "src/app/common/enumerations/enumerations";
import { CancelDialogComponent } from "src/app/controls/cancel-dialog/cancel-dialog.component";
import { WfSignature, WfSignatureResource, WFTaskSignatureResource } from "src/app/services/work-flow/work-flow";
import { SCFHoldPointResource, SCFMaster, SaveSCFStatusResource } from "../../../scf";
import { BaseComponent } from "src/app/common/base/base.component";
import { FormStatus } from "src/app/services/forms/form-status";

@Component({
  selector: "scf-v2-main-s4-nowork",
  templateUrl: "./scf-v2-main-s4-nowork.component.html",
  styleUrls: ["./scf-v2-main-s4-nowork.component.scss"],
})
export class ScfV2MainS4NoworkComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() scfMaster?: SCFMaster | null;
  @Input() disabled? = true;
  @Input() privileges?: SCFPrivileges;
  @Input() status?: SCFStatus;

  @Output() changed = new EventEmitter<SCFMaster>();
  @Output() loading = new EventEmitter<boolean>();

  disableControls = true;

  signatures?: WfSignature[];
  wfSignatures?: WfSignatureResource[];

  noWorkPerformedTitle?: string;
  noWorkPerformedLegend?: string;

  holdPoints?: SCFHoldPointResource[];

  requesterAmended?: boolean;

  constructor(
    private scfService: ScfV2Service,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setDisabled();
    this.getValues();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.setMessages();
  }

  initializeForm() { }

  setMessages() {
    this.noWorkPerformedTitle = this.getMessage(
      "noWorkPerformedTitle"
    ).description;
    this.noWorkPerformedLegend = this.getMessage(
      "noWorkPerformedLegend"
    ).description;
  }

  setDisabled() {
    this.disabled =
      (this.scfMaster?.status !== FormStatusEnum.Active &&
        this.scfMaster?.status !== FormStatusEnum.HoldPoint &&
        this.scfMaster?.status !== FormStatusEnum.PendingCancelation) ||
      !this.getCurrentUser();
  }

  getValues() {
    this.wfSignatures = [];
    this.signatures = [];
    if (this.scfMaster) {
      this.createSignatures();
    }
  }

  createSignatures() {
    let index = 0;
    this.wfSignatures = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals
      ?.find((x) => x.number == 3)
      ?.wfSignatures?.filter((x) => x.wfTaskLocal.wfStepLocal?.code == "NO_WORK")
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    const requesterSignature = this.wfSignatures?.find(x => x.requesterSignature);
    if (this.status?.isViewingAmendment) {
      const amendment = this.scfMaster?.scfAmendments?.find(x => x.active);
      this.requesterAmended = this.scfMaster?.requesterId != amendment?.requesterId;
      if (this.requesterAmended && requesterSignature && amendment) {
        requesterSignature.users = [];
        const user = this.getUser(amendment.requesterId);
        if (user)
          requesterSignature.users.push(user);
      }
    }
    else {
      if (requesterSignature && this.scfMaster?.requesterId) {
        requesterSignature.users = [];
        const user = this.getUser(this.scfMaster?.requesterId);
        if (user)
          requesterSignature.users.push(user);
      }
    }
    if (this.wfSignatures) {
      for (const t of this.wfSignatures) {
        const signature: WfSignature = {
          id: t.id,
          type: t.type,
          approveId: t.approveID,
          unapproveId: t.unapproveID,
          disapproveId: t.disapproveID,
          amendmentId: t.amendmentID,
          amendmentNumber: t.amendmentNumber ? t.amendmentNumber : 0,
          name: t.titleBefore,
          roles: t.roles.map((r) => r?.id),
          roleCodes: t.roles.length
            ? t.roles.map((r) => r?.code).join("/")
            : undefined,
          userNames: t.users?.map((r) => r?.name)[0],
          disabled:
            this.disabled ||
            (t.requesterSignature && !t.users.map(u => u.id).includes(this.currentUser?.id)),
          approvedBy: t.signedBy,
          approved: t.signed,
          approvedOn: t.signedOn,
          approve:
            this.taskEnabled(t, SignatureAction.Approve) && !this.disabled,
          unapprove:
            this.taskEnabled(t, SignatureAction.Unnaprove) && !this.disabled,
          disapprove:
            this.taskEnabled(t, SignatureAction.Disapprove) &&
            !this.disabled &&
            !t.signed,
          amendment: t.amendmentEnabled,
          required: t.required,
          taskId: t.taskID,
          value: t.type == 2 ? t.signed : t.value,
          requesterSignature: t.requesterSignature,
          visible: t.visible,
          enabled: t.enabled,
          warning: false,
          sectionNumber: 3,
        };

        if (!signature.enabled) {
          signature.approvedBy = null;
          signature.approved = false;
        }
        index++;

        this.signatures?.push(signature);
      }
    }
  }

  taskEnabled(t: WfSignatureResource, a: SignatureAction) {
    let result: boolean | undefined = false;
    if (t.requesterSignature) {
      switch (a) {
        case SignatureAction.Approve:
          result =
            t.wfTaskLocal.wfTasks?.[0]?.signedBy == null &&
            this.scfMaster?.requesterId == this.getCurrentUser()?.id;
          break;

        case SignatureAction.Unnaprove:
          result =
            t.wfTaskLocal.wfTasks?.[0]?.signedBy != null &&
            this.scfMaster?.requesterId == this.getCurrentUser()?.id;
          break;
      }
    } else {
      if (this.getCurrentUser()?.id != this.scfMaster?.requesterId) {
        const reqSignature = this.wfSignatures?.find(
          (x) => x.code == "NO_WORK_CHECK"
        );
        t.enabled = reqSignature?.signed;
        result =
          this.hasRoles(t.roles) &&
          this.scfMaster?.status == FormStatusEnum.PendingCancelation &&
          t.enabled;
      }
    }
    return result;
  }

  approve(s: WfSignature, text: string | null = null) {
    return new Promise((resolve, reject) => {
      const wfTaskSignature: WFTaskSignatureResource = {
        taskId: s.taskId,
        actionId: s.approveId,
        reason: text,
      };
      this.loading.emit(true);
      this.scfService
        .sign(wfTaskSignature)
        .toPromise()
        .then((res) => {
          this.scfMaster = res;
          this.checkStatus(s);
          this.loading.emit(false);
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          this.alert.defaultError();
          reject();
        });
    });
  }

  checkStatus(s: WfSignature) {
    this.getValues();
    if (!this.wfSignatures?.find((x) => x.signed)) {
      const hp = this.holdPoints = this.scfMaster?.scfHoldPoints?.filter(x => x.status == 1).sort((a, b) => a.number - b.number);
      const hpStatus = hp?.some(x => x.wfTaskLocals?.filter(x => x.wfTasks?.some(t => t.signedBy)).length == 0);
      const scfStatusResource = {
        id: this.scfMaster?.id,
        status: hpStatus ? FormStatusEnum.HoldPoint : FormStatusEnum.Active,
      } as SaveSCFStatusResource;
      this.scfService
        .putStatus(scfStatusResource)
        .toPromise()
        .then(() => {
          this.loading.emit(false);
        });
    } else {
      if (!s.requesterSignature) {
        const signatures = this.wfSignatures.filter(
          (x) => x.code != "NO_WORK_CHECK"
        );
        const allSigned = signatures.filter((x) => !x.signed).length == 0;
        if (allSigned) {
          const scfStatusResource = {
            id: this.scfMaster?.id,
            status: FormStatusEnum.Closed,
          } as SaveSCFStatusResource;
          this.scfService
            .putStatus(scfStatusResource)
            .toPromise()
            .then(() => {
              // this.loading.emit(false);
            });
        }
      } else {
        const signature = this.wfSignatures.find(
          (x) => x.code == "NO_WORK_CHECK"
        );
        const scfStatusResource = {
          id: this.scfMaster?.id,
          status: signature?.signed
            ? FormStatusEnum.PendingCancelation
            : FormStatusEnum.Active,
        } as SaveSCFStatusResource;
        this.scfService
          .putStatus(scfStatusResource)
          .toPromise()
          .then(() => {
            // this.loading.emit(false);
          });
      }
    }
  }

  disapprove(s: WfSignature, data: any) {
    this.loading.emit(true);
    const wfTaskSignature: WFTaskSignatureResource = {
      taskId: s.taskId,
      actionId: s.disapproveId,
      reason: data.text,
    };
    this.scfService
      .sign(wfTaskSignature)
      .toPromise()
      .then(
        (res) => {
          this.scfMaster = res;
          this.loading.emit(false);
          this.checkStatus(s);
          this.loading.emit(false);
        },
        (error) => {
          console.log(error);
          this.alert.defaultError();
          this.loading.emit(false);
        }
      );
  }

  unapprove(s: WfSignature) {
    return new Promise((resolve, reject) => {
      this.loading.emit(true);
      const wfTaskSignature: WFTaskSignatureResource = {
        taskId: s.taskId,
        actionId: s.unapproveId,
      };
      this.scfService
        .sign(wfTaskSignature)
        .toPromise()
        .then(
          (res: any) => {
            this.scfMaster = res;
            this.loading.emit(false);
            this.checkStatus(s);
            this.loading.emit(false);
            resolve(res);
          },
          (error: any) => {
            console.log(error);
            this.alert.defaultError();
            reject();
          }
        );
    });
  }

  async checked(s: WfSignature, e: any) {
    if (e) {
      if (s.requesterSignature) {
        const confirm = this.dialog.open(CancelDialogComponent, {
          width: "600px",
          data: {
            label: this.getMessage("EnterAReasonToCloseSCF").description,
            text: "",
            inputType: "text",
          },
        });
        confirm.afterClosed().subscribe(async (data) => {
          if (data.text) {
            this.loading.emit(true);
            this.approve(s, data.text);
          } else {
            this.getValues();
          }
        });
      } else {
        await this.approve(s);
      }
    } else {
      this.loading.emit(true);
      this.unapprove(s);
    }
  }
}
