<div class="card">
  <div class="card-header">
    <div class="d-flex justify-content-between relative">
      <div class="card-title">Bulletin Board</div>
      <div class="d-flex justify-content-end header-buttons">
        <button
          mat-icon-button
          color="primary"
          class="MarginR10"
          (click)="BBHistory()"
        >
          <mat-icon>history</mat-icon>
        </button>
        <button
          mat-icon-button
          class="ml-1"
          color="accent"
          *ngIf="this.hasPrivilege(privilegeEnum.BulletinBoardEditor)"
          (click)="AddToBB()"
        >
          <mat-icon>add_box</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body px-4 pb-1 pt-2">
    <div
      *ngFor="let item of activesHistory; let i = index"
      class="row mb-2 pb-2 bb-item"
      [ngClass]="{
        pinned: item.isPined
      }"
    >
      <div class="col-11 py-3 d-flex align-items-center">
        <mat-icon color="primary" class="mr-2" *ngIf="item.isPined"
          >push_pin</mat-icon
        >
        <span class="h6 my-auto" (click)="seeMore($event, item)">
          {{ item.description.substring(0, 250) }}
          <a *ngIf="item.description.length > 250" href="#">... See more</a>
        </span>
      </div>
      <div
        class="col-1 text-right pr-0"
        *ngIf="this.hasPrivilege(privilegeEnum.BulletinBoardEditor)"
      >
        <button
          mat-icon-button
          class="focus-outline-none"
          color="primary"
          [matMenuTriggerFor]="options"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu
          #options="matMenu"
          id="{{ 'contentOptions-' + i }}"
          class="d-flex flex-column align-items-start"
        >
          <button mat-menu-item *ngIf="!item.isPined" (click)="PinBB(item)">
            <mat-icon color="primary">push_pin</mat-icon><span>Pin</span>
          </button>
          <button mat-menu-item *ngIf="item.isPined" (click)="UnPinBB(item)">
            <mat-icon class="r45" color="primary">push_pin</mat-icon
            ><span>Unpin</span>
          </button>
          <hr class="my-0" />
          <button mat-menu-item (click)="EditBB(item)">
            <mat-icon color="accent">edit</mat-icon
            ><span class="Option">Edit</span>
          </button>
          <button mat-menu-item (click)="ArchiveBB(item)">
            <mat-icon class="gray">archive</mat-icon
            ><span class="Option">Archive</span>
          </button>
        </mat-menu>
      </div>

      <div
        class="col-12 small d-flex justify-content-end"
        [ngClass]="{ blue: item.isPined, gray: !item.isPined }"
      >
        <span>
          Created by {{ item.createdByUser }} on
          {{ item.createdOn | date : "yyyy-MM-dd" }}.
        </span>
        <span *ngIf="item.updatedBy" class="ml-1">
          Last edited by {{ item.updatedByUser }} on
          {{ item.updatedOn | date : "yyyy-MM-dd" }}.
        </span>
        <span
          *ngIf="
            item.expirationDate != null && item.expirationDate.toString() != ''
          "
          [ngClass]="{ Expired: item.expirationDate < nowdate }"
          class="ml-1"
        >
          Estimated expiration:
          {{ item.expirationDate | date : "yyyy-MM-dd" }}.
        </span>
      </div>
    </div>
  </div>
</div>
