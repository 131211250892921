import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScheduleReminder } from '../../models/schedule-reminder';
import { ReminderPeriodEnum } from '../../models/enums';

@Component({
  selector: 'app-add-reminder',
  templateUrl: './add-reminder.component.html',
  styleUrls: ['./add-reminder.component.scss']
})
export class AddReminderComponent implements OnInit {

  reminderForm!: FormGroup;
  ReminderPeriodEnum = ReminderPeriodEnum;
  Period = ['Before Inspected Due Date', 'After Inspected Due Date'];

  constructor(public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddReminderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ScheduleReminder) { }

  ngOnInit(): void {
    this.reminderForm = this.formBuilder.group({
      name: ['', Validators.required],
      timeInDays: [0, [Validators.required, Validators.min(0)]],
      period: ['', Validators.required],
      message: ['', Validators.required],
      beamlineStaffNotification: [''],
      typeId: []
    });
    if (this.data) {
      this.reminderForm.controls['name'].setValue(this.data.name ?? null);
      this.reminderForm.controls['typeId'].setValue(this.data.typeId ?? null);
      this.reminderForm.controls['timeInDays'].setValue(this.data.timeInDays ?? null);
      this.reminderForm.controls['period'].setValue(this.data.period ?? null);
      this.reminderForm.controls['message'].setValue(this.data.message ?? null);
      this.reminderForm.controls['beamlineStaffNotification'].setValue(this.data.beamlineStaffNotification ?? null);
    }
    else {
      this.data = { id: 0, isActive: true };
    }
  }

  messageChanged(e: string) {
    this.data.message = e;
    this.reminderForm.controls['message'].setValue(this.data.message ?? null);
  }

  onSubmit() {
    console.log(this.reminderForm.value);
    this.reminderForm.controls['message'].setValue(this.data.message ?? null);
    this.dialogRef.close(this.reminderForm.value);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
