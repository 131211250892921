<div
  class="annotations-wrapper"
  [ngClass]="{
    'annotations-wrapper-disabled': annotation.status == annotationStatus.Done
  }"
>
  <div class="row pr-2">
    <div class="col-9">
      <span class="annotation" [innerHTML]="annotationTextSanitized"></span>
    </div>
    <div class="col-1 pr-0 d-flex">
      <div *ngIf="annotation.status != annotationStatus.Done">
        <button
          mat-icon-button
          class="yellow"
          (click)="edit()"
          [matTooltip]="'Edit'"
          *ngIf="
            (currentVersion.status == checklistTemplateStatusEnum.InReview &&
              isReviewer &&
              !procedureSignature?.signedOn &&
              isCreator) ||
            (currentVersion.status == checklistTemplateStatusEnum.Suggesting &&
              isApprover &&
              !procedureSignature?.signedOn &&
              isCreator) ||
            (isRCP && isCreator)
          "
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          class="red"
          (click)="delete()"
          [matTooltip]="'Delete'"
          *ngIf="
            (currentVersion.status == checklistTemplateStatusEnum.InReview &&
              isReviewer &&
              !procedureSignature?.signedOn &&
              isCreator) ||
            (currentVersion.status == checklistTemplateStatusEnum.Suggesting &&
              isApprover &&
              !procedureSignature?.signedOn &&
              isCreator) ||
            (isRCP && isCreator)
          "
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <button
        mat-icon-button
        class="blue"
        (click)="respond()"
        [matTooltip]="'Write a response'"
        *ngIf="
          (currentVersion.status ==
            checklistTemplateStatusEnum.CorrectionsInProgress ||
            currentVersion.status == checklistTemplateStatusEnum.Draft ||
            (isRCP && !isCreator)) &&
          annotation.status == annotationStatus.Active &&
          (isPreparer || (isRCP && !isCreator)) &&
          !annotation.responseText
        "
      >
        <mat-icon>question_answer</mat-icon>
      </button>
      <button
        mat-icon-button
        class="green"
        (click)="done()"
        [matTooltip]="'Mark as Done'"
        *ngIf="
          (currentVersion.status ==
            checklistTemplateStatusEnum.CorrectionsInProgress ||
            currentVersion.status == checklistTemplateStatusEnum.Draft ||
            (isRCP && isCreator)) &&
          annotation.status == annotationStatus.Active &&
          (isPreparer || (isRCP && isCreator))
        "
      >
        <mat-icon>mark_chat_read</mat-icon>
      </button>
      <button
        mat-icon-button
        class="orange"
        (click)="undone()"
        [matTooltip]="'Mark as Undone'"
        *ngIf="
          (currentVersion.status ==
            checklistTemplateStatusEnum.CorrectionsInProgress ||
            currentVersion.status == checklistTemplateStatusEnum.Draft ||
            (isRCP && isCreator)) &&
          annotation.status == annotationStatus.Done &&
          (isPreparer || (isRCP && isCreator))
        "
      >
        <mat-icon>mark_chat_unread</mat-icon>
      </button>
    </div>
    <div class="col-2 info">
      <span class="person align-end">
        <mat-icon class="gray">person</mat-icon> {{ annotation.createdByName
        }}<br /><span class="date">{{
          annotation.createdOn | date : "medium"
        }}</span>
      </span>
    </div>
  </div>
</div>
<div class="response-wrapper" *ngIf="annotation.responseText">
  <div class="row">
    <div class="col-9">
      <span class="annotation" [innerHTML]="responseTextSanitized"></span>
    </div>
    <div class="col-1 pr-0">
      <span class="buttons"
        ><button
          mat-icon-button
          class="yellow"
          (click)="respond()"
          [matTooltip]="'Edit'"
          *ngIf="
            (currentVersion.status ==
              checklistTemplateStatusEnum.CorrectionsInProgress ||
              currentVersion.status == checklistTemplateStatusEnum.Draft) &&
            isPreparer &&
            !procedureSignature?.signedOn
          "
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          class="red"
          (click)="deleteResponse()"
          [matTooltip]="'Delete'"
          *ngIf="
            (currentVersion.status ==
              checklistTemplateStatusEnum.CorrectionsInProgress ||
              currentVersion.status == checklistTemplateStatusEnum.Draft) &&
            isPreparer &&
            !procedureSignature?.signedOn
          "
        >
          <mat-icon>delete</mat-icon>
        </button>
      </span>
    </div>
    <div class="col-2 info">
      <span class="person align-end">
        <mat-icon class="gray">person</mat-icon> {{ annotation.responseByName
        }}<br /><span class="date">{{
          annotation.responseDate | date : "medium"
        }}</span>
      </span>
    </div>
  </div>
</div>
