import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { WFMasterResource, WFTaskConfig, WFStepConfig, WFActionConfig, WFRoleConfig, WFSectionConfig } from '../../../workflow/workflow';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { WfSignature } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { Tooltip } from 'src/app/common/tooltip';
import { ClEditorComponent } from 'src/app/controls/checklist-components/cl-editor/cl-editor.component';

@Component({
  selector: 'scf-v2-settings-signatures',
  templateUrl: './scf-v2-settings-signatures.component.html',
  styleUrls: ['./scf-v2-settings-signatures.component.scss']
})
export class ScfV2SettingsSignaturesComponent implements OnInit {

  @Output() isLoading = new EventEmitter<boolean>();

  sections: any[] = [
    { id: 2, name: 'Work Authorizations', orientation: 'horizontal' },
    { id: 3, name: 'Work Complete', orientation: 'horizontal' },
    { id: 4, name: 'Operational Approval', orientation: 'vertical' },
    { id: 6, name: 'Amendments', orientation: 'horizontal' }
  ];

  workflow?: WFMasterResource[];

  constructor(
    private _workflow: WorkFlowService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loadWFConfiguration();
  }

  loadWFConfiguration() {
    this.isLoading.emit(true);
    this._workflow.getWFSectionsByForm(FormType.SCF).toPromise().then(data => {
      this.workflow = data;
      this.isLoading.emit(false);
    });
  }

  getSteps(id: number) {
    const section = this.workflow?.find(x => x.identificator == id);
    return section?.steps;
  }

  getTasks(step: WFStepConfig) {
    return step.taskConfigs.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
  }

  getRoleIds(task: WFTaskConfig) {
    return task.wfRoleConfigs?.map(r => r.roleID);
  }

  getRoleCodes(task: WFTaskConfig) {
    return task.wfRoleConfigs?.map(r => r.roleCode).join('/');
  }

  getAction(action: number, task: WFTaskConfig) {
    return task.wfActionConfigs?.some(x => x.action == action);
  }

  edit(task: WFTaskConfig) {
    const s = new WfSignature();
    s.id = task.id;
    s.name = task.name;
    s.question = task.name2;
    s.question2 = task.name3;
    s.question3 = task.name4;
    const options: any[] = task.options ? JSON.parse(task.options) : [];
    s.options = options;
    s.condition = task.condition;
    s.type = task.type;
    s.visible = true;
    s.required = true;
    s.code = task.code;
    s.enabled = true;
    s.configuration = task.configuration;
    s.approve = task.wfActionConfigs?.find(x => x.action == 1) ? true : false;
    s.unapprove = task.wfActionConfigs?.find(x => x.action == 3) ? true : false;
    s.disapprove = task.wfActionConfigs?.find(x => x.action == 2) ? true : false;
    s.approveId = task.wfActionConfigs?.find(x => x.action == 1)?.id;
    s.unapproveId = task.wfActionConfigs?.find(x => x.action == 3)?.id;
    s.disapproveId = task.wfActionConfigs?.find(x => x.action == 2)?.id;
    s.roles = task.wfRoleConfigs?.map(x => x.roleID);
    s.roleCodes = task.wfRoleConfigs?.map(x => x.roleCode).join('/');
    const editor = this.dialog.open(ClEditorComponent, {
      width: '60vw',
      disableClose: true,
      data: {
        s,
        roles: task.wfRoleConfigs?.map(x => x.role),
        formType: 'WF',
        serialNo: 'NoSerial'
      },
      autoFocus: false
    });
    editor.afterClosed().subscribe((data) => {
      if (data) {
        this.save(task, data.s).then(() => {
          this.saveRoles(task, data.roles, data.originalRoles);
          this.loadWFConfiguration();
        });
      }
    });
  }

  async save(task: WFTaskConfig, s: WfSignature) {
    return new Promise((resolve, reject) => {
      const wfTask: WFTaskConfig = {
        id: s.id,
        name: s.name,
        name2: s.question,
        name3: s.question2,
        name4: s.question3,
        enabled: s.enabled,
        required: s.required,
        condition: s.condition,
        options: JSON.stringify(s.options),
        type: s.type,
        code: s.code,
        configuration: s.configuration,
        wfStepConfigID: task.wfStepConfigID,
      }

      if (s.id != 0) {
        this._workflow.putWFTask(wfTask).toPromise().then(async data => {
          if (data) {
            const section = this.workflow?.find(x => x.steps?.some(s => s.taskConfigs?.some(t => t.id == data.id)));
            const step = section?.steps.find((s => s.taskConfigs.some(t => t.id == data.id)));
            const taskIdx = step?.taskConfigs.findIndex(t => t.id == data.id) ?? -1;
            step?.taskConfigs.splice(taskIdx, 1);
            step?.taskConfigs.push(data);
            this.saveActions(task, s);
            resolve(data);
          }
        });
      }
      // else {
      //   wfTask.id = 0;
      //   this._workflow.postWFTask(wfTask).subscribe(async data => {
      //     wfTask = data;
      //     s.taskId = wfTask.id;
      //     this.saveActions(s);
      //     this.requestRefresh.emit(this.node);
      //     this.displayInfo(this.node, data);
      //     this.loading = false;
      //     this.editing = false;
      //     resolve(data);
      //   });
      // }
    });
  }

  saveActions(task: WFTaskConfig, s: WfSignature) {
    if (s.approve && !s.approveId) {
      const action: WFActionConfig = {
        id: 0,
        name: 'Approve',
        label: 'Approve',
        action: 1,
        wfTaskConfigID: task.id
      };
      this.saveAction(s.approve, action);
    }
    if (!s.approve && s.approveId) {
      this.saveAction(s.approve ?? false, task.wfActionConfigs?.find(x => x.id == s.approveId));
    }

    if (s.unapprove && !s.unapproveId) {
      const action: WFActionConfig = {
        id: 0,
        name: 'Unapprove',
        label: 'Unapprove',
        action: 3,
        wfTaskConfigID: task.id
      };
      this.saveAction(s.approve ?? false, action);
    }
    if (!s.unapprove && s.unapproveId) {
      this.saveAction(s.unapprove ?? false, task.wfActionConfigs?.find(x => x.id == s.unapproveId));
    }

    if (s.disapprove && !s.disapproveId) {
      const action: WFActionConfig = {
        id: 0,
        name: 'Dispprove',
        label: 'Dispprove',
        action: 2,
        wfTaskConfigID: task.id
      };
      this.saveAction(s.disapprove, action);
    }
    if (!s.disapprove && s.disapproveId) {
      this.saveAction(s.disapprove ?? false, task.wfActionConfigs?.find(x => x.id == s.disapproveId));
    }
  }

  saveAction(checked: boolean, action: any) {

    const id = action.id ? action.id : 0;
    let wfAction: WFActionConfig;

    wfAction = {
      id,
      name: action.name ? action.name : action.key,
      label: action.label ? action.label : action.key,
      action: action.action,
      wfTaskConfigID: action.wfTaskConfigID,
      wfNextStepConfigID: action.next
    };

    if (checked) {
      if (id == 0) {
        // Post
        this._workflow.postWFAction(wfAction).subscribe(data => {
        });
      }
      else {
        // Put
        this._workflow.putWFAction(wfAction).subscribe(data => {
        });
      }
    }
    else {
      // Delete
      this._workflow.delWFAction(wfAction).subscribe(() => {
      });
    }
  }

  saveRoles(task: WFTaskConfig, roles: Role[], roleConfigs: Role[]) {
    return new Promise((resolve, reject) => {
      roles.map(r => {
        if (!roleConfigs.map(x => x.id).includes(r.id)) {
          const wfRoleConfig: WFRoleConfig = {
            id: 0,
            roleID: r.id ?? 0,
            wfTaskConfigID: task.id ?? 0
          };
          this._workflow.postWFRole(wfRoleConfig).subscribe(data => { this.loadWFConfiguration(); });
        }
      });

      roleConfigs.map(r => {
        if (!roles.map(x => x.id).includes(r.id)) {
          const wfRoleConfig = task.wfRoleConfigs?.find(x => x.roleID == r.id);
          if (wfRoleConfig)
            this._workflow.delWFRole(wfRoleConfig).subscribe(data => { this.loadWFConfiguration(); });
        }
      });
    });
  }

  drop(event: CdkDragDrop<WFTaskConfig[]>, section: WFSectionConfig, step: WFStepConfig) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      event.container.data.map((x, i) => {
        x.order = i;
      });
      this.saveTasks(event.container.data);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      event.item.data.wfStepConfigID = event.container.id.replace('draglist-', '');
      event.container.data.map((x, i) => {
        x.order = i;
      });
      this.saveTasks(event.previousContainer.data.concat(event.container.data));
    }
  }

  getConnectedTo(section: WFSectionConfig, step: WFStepConfig) {
    return this.workflow?.find(x => x.id == section.id)?.steps.filter(s => s.id != step.id).map(s => 'draglist-' + s.id) ?? '';
  }

  saveTasks(wfTaskConfigs: WFTaskConfig[]) {
    this._workflow.putWFTasks(wfTaskConfigs).toPromise().then(() => { this.loadWFConfiguration(); });
  }

  getTooltips(task: WFTaskConfig) {
    const tooltips = [] as Tooltip[];
    task.wfActionConfigs?.map(x => {
      const section = this.workflow?.find(w => w.steps?.some(s => s.id == x.wfNextStepConfigID));
      const step = section?.steps.find(s => s.id == x.wfNextStepConfigID);
      if (step) {
        const tooltip: Tooltip = { action: x.action, content: 'GOTO: <b class="blue">' + step?.name + '</b>' };
        tooltips.push(tooltip);
      }
    });
    return tooltips;
  }

  calcWidth(step: WFStepConfig) {
    const tasks = this.getTasks(step);
    if (tasks.length > 3) {
      return (80 / tasks.length) + '%';
    }
    else {
      return '30%';
    }
  }
}
