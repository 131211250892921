

export class PendingApprovalsRefresh {
  static readonly type = '[PendingApprovals] Refresh';
}
// export class PendingApprovalsCreate {
//   static readonly type = '[PendingApprovals] Create';
//   constructor(public item: PendingApprovals) { }
// }
// export class PendingApprovalsUpdate {
//   static readonly type = '[PendingApprovals] Update';
//   constructor(public id: number, public item: PendingApprovals) { }
// }
// export class PendingApprovalsDelete {
//   static readonly type = '[PendingApprovals] Delete';
//   constructor(public id: number) { }
// }
