import { OlogCategory } from "../../services/olog-category";

export class OlogCategoryRefresh {
  static readonly type = '[OlogCategory] Refresh';
}

export class OlogCategoryCreate {
  static readonly type = '[OlogCategory] Create';
  constructor(public item: OlogCategory) { }
}

export class OlogCategoryUpdate {
  static readonly type = '[OlogCategory] Update';
  constructor(public id: number, public item: OlogCategory) { }
}

export class OlogCategoryDelete {
  static readonly type = '[OlogCategory] Delete';
  constructor(public id: number) { }
}
