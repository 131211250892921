import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { APLCategory } from 'src/app/components/apl/services/apl-category.model';

@Component({
  selector: 'apl-category-v2-list',
  templateUrl: './apl-category-v2-list.component.html',
  styleUrls: ['./apl-category-v2-list.component.scss']
})
export class AplCategoryV2ListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() filter?: string;
  @Input() aplCategories?: APLCategory[];

  id: string | null = null;

  displayedColumns: string[] = ['name'];
  dataSource!: MatTableDataSource<APLCategory>;

  constructor(private route: ActivatedRoute, private router: Router) { }
  ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = new MatTableDataSource(this.aplCategories);
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        const aplCategory = this.aplCategories?.find(c => c.id == +id);
        if (aplCategory) {
          setTimeout(() => {
            this.openCategory(aplCategory);
          }, 500);
        }
      }
    });
  }

  ngOnInit(): void {

  }

  openCategory(aplCategory: APLCategory) {
    aplCategory.open = true;
    const divElement = document.getElementById(aplCategory.id.toString());
    if (divElement)
      divElement.scrollIntoView({ behavior: 'smooth' });

  }


}
