<div class="full-width">
  <ng-container *ngFor="let task of tasks">
    <div class="row no-gutters mb-1">
      <div class="col-12">
        <div class="full-width display-flex">
          <checklist-task
            class="full-width"
            [currentChecklist]="currentChecklist"
            [task]="task"
            [disabled]="disabled"
            [precheck]="precheck"
            (updateTask)="updateTask($event)"
            (loading)="loading.emit($event)"
            (checkShared)="checkShared.emit($event)"
          >
          </checklist-task>
        </div>
      </div>
    </div>
  </ng-container>
</div>
