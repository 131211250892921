import { Component, EventEmitter, Injector, Input, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PendingCatalogColor } from 'src/app/common/enumerations/enumerations';
import { NotificationTemplate } from 'src/app/components/catalogs/notifications/services/notification-template';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'notifications-side',
  templateUrl: './notifications-side.component.html',
  styleUrls: ['./notifications-side.component.scss']
})
export class NotificationsSideComponent extends BaseComponent {

  @Input() filter?: string;
  @Output() loading = new EventEmitter<boolean>();
  @Output() selectedRow = new EventEmitter<NotificationTemplate>();

  notificationTemplates?: NotificationTemplate[];
  notificationTemplatesFiltered?: NotificationTemplate[];
  notificationTemplates$!: Observable<NotificationTemplate[]>;
  notificationTemplatesSubs!: Subscription;

  public displayedColumns: string[] = ['name'];
  public dataSource!: MatTableDataSource<NotificationTemplate>;

  showInactive!: boolean;
  selectedRowIndex?: number;
  pendingCatalog = PendingCatalogColor;

  constructor(
    protected override injector: Injector,

  ) {
    super(injector);
    this.loadNotificationTemplates();
  }

  override ngOnDestroy(): void {
    this.notificationTemplatesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter'])
      this.filterNotifications();

  }

  loadNotificationTemplates() {
    this.loading.emit(true);
    this.notificationTemplates$ = this.store.select(state => state.NotificationTemplates.data);
    this.notificationTemplatesSubs = this.notificationTemplates$.subscribe(data => {
      if (data?.length) {
        this.notificationTemplates = data.sort((a, b) => this.utils.sort(a.name, b.name));
        this.filterNotifications();
        this.loading.emit(false);
      }
    });
  }

  filterNotifications() {
    this.notificationTemplatesFiltered = this.notificationTemplates?.filter(t => t.name.toLowerCase().includes(this.filter?.toLowerCase() ?? ''))
    if (this.showInactive == true) {
      this.dataSource = new MatTableDataSource(this.notificationTemplatesFiltered);
    } else {
      this.dataSource = new MatTableDataSource(this.notificationTemplatesFiltered?.filter(x => x.status == 1));
    }
  }

  showInactiveNotificationTemplates(e: any) {
    this.showInactive = e.checked;
    this.filterNotifications();
  }

  async highlight(row: NotificationTemplate) {
    if (await this.canDeactivate()) {
      this.selectedRowIndex = row.id;
      this.selectedRow.emit(row);
    }
  }


}
