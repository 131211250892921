<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-2">
        <h6>{{ number ?? "#" }}</h6>
      </div>
      <div class="col-9 pr-1 pt-2">
        <span
          id="display-text"
          [innerHTML]="textBefore ?? '' | placeholderHighlight : editor"
        ></span>
      </div>
      <div class="col-2 d-flex flex-column align-items-end">
        <div class="d-flex flex-row justify-content-end align-items-center">
          <mat-label class="pt-1" [ngClass]="{ disabled: disabled }"
            >Applies</mat-label
          >
          <mat-checkbox-with-roles
            [labelPosition]="'before'"
            [checked]="applies"
            [disabled]="disabled"
            (change)="appliesChecked($event)"
            [color]="'primary'"
            [roleCodes]="roleCodes"
            [warning]="moreInfo ?? ''"
            [user]="user"
            [date]="date"
          >
          </mat-checkbox-with-roles>
        </div>
        <div class="pt-1 text-right">
          <mat-radio-group
            class="radio-group d-flex flex-column"
            aria-label="Select an option"
            [(ngModel)]="radioSelection"
            [disabled]="disabled ?? false"
            *ngIf="applies || builder"
          >
            <mat-radio-button
              [labelPosition]="'before'"
              class="radio-button"
              *ngFor="let r of radioOptions"
              [checked]="r.checked"
              [value]="r.value"
              (change)="radioChanged($event)"
            >
              {{ r.label }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <div class="row pt-2" *ngIf="applies || builder">
      <div class="col-11 offset-1">
        <div class="row no-gutters">
          <div class="col-12 pr-xl-1">
            <comments-box
              [noSubscript]="true"
              [serialNo]="serialNo"
              [formType]="formType"
              [textValue]="textValue"
              [disabled]="disabled || loading"
              title="Comments"
              placeholder="Write a Comment"
              (textChanged)="textChanged($event)"
            ></comments-box>
          </div>
          <div class="col-12 mt-2 pt-3 px-3 back-gray">
            <div class="row pb-2">
              <div class="col-6">
                <mat-label class="h6">Action Items for CATL</mat-label>
              </div>
              <div class="col-6">
                <div
                  *ngIf="!disabled"
                  class="btn blue mr-1 float-right"
                  (click)="addActionItem()"
                >
                  <mat-icon>add</mat-icon>
                </div>
              </div>
            </div>
            <div class="row pl-1 pr-1">
              <catl
                [disabled]="disabled"
                [builder]="builder"
                [formId]="formId"
                [sectionNumber]="sectionNumber"
                [sectionId]="sectionId"
                [taskId]="taskID"
                [formType]="formType"
                [serialNo]="serialNo"
                [showLabel]="true"
                (catlChanged)="catlChanged($event)"
                class="full-width"
              >
              </catl>
              <!--<span class="ai-label" *ngIf="actionItems.lenght == 0">CATL</span>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
