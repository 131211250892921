<div
  class="counter"
  *ngIf="totalTasks"
  [ngClass]="{ green: completedTasks == totalTasks }"
>
  <mat-icon class="green mr-1" *ngIf="completedTasks == totalTasks"
    >check_circle</mat-icon
  >
  <span class="d-flex">
    Completed&nbsp;<b>{{ completedTasks }}</b
    >&nbsp;</span
  ><span *ngIf="completedTasks != totalTasks">of&nbsp;</span>
  <b *ngIf="completedTasks != totalTasks">{{ totalTasks }}&nbsp;</b>
  Tasks
</div>
