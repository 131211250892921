import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ReviewBoardType, ReviewStatus, ReviewStatusAction } from '../../../reviews.models';
import { ReviewsSettingsComponent } from '../../reviews-settings.component';
import { utils } from 'src/app/modules/libs/utils';
import { StatusType } from 'src/app/common/enumerations/enumerations';

@Component({
  selector: 'reviews-settings-statuses-details',
  templateUrl: './reviews-settings-statuses-details.component.html',
  styleUrls: ['./reviews-settings-statuses-details.component.scss']
})
export class ReviewsSettingsStatusesDetailsComponent implements OnInit, OnDestroy {


  reviewBoardType: ReviewBoardType;
  reviewStatus?: ReviewStatus;
  reviewStatuses$!: Observable<ReviewStatus[]>;
  reviewStatuses: ReviewStatus[];
  reviewStatusesSubs!: Subscription;

  reviewStatusNameCtrl = new FormControl();
  reviewStatusDescriptionCtrl = new FormControl();
  reviewStatusCodeCtrl = new FormControl();
  reviewStatusTypeCtrl = new FormControl();

  isActive!: boolean;
  isCompleted!: boolean;

  form!: FormGroup;
  title: string;
  disabled!: boolean;

  statusTypes = utils.getENUM(StatusType);

  constructor(
    private store: Store,
    public dialog: MatDialogRef<ReviewsSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reviewBoardType = data.reviewBoardType;
    this.title = data.title;
    this.reviewStatus = data.reviewStatus;
    this.reviewStatuses = data.reviewStatuses;
  }

  ngOnDestroy(): void {
    this.reviewStatusesSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.buildForm();
    this.reviewStatusNameCtrl.setValue(this.reviewStatus?.name);
    this.reviewStatusDescriptionCtrl.setValue(this.reviewStatus?.description);
    this.reviewStatusCodeCtrl.setValue(this.reviewStatus?.code);
    this.reviewStatusTypeCtrl.setValue(this.statusTypes.find(x => x.value == this.reviewStatus?.type));
    this.reviewStatuses$ = this.store.select(state => state.ReviewStatus.data);
    this.reviewStatusesSubs = this.reviewStatuses$.subscribe(data => {
      this.reviewStatuses = data.filter(x => x.reviewBoardTypeID == this.reviewBoardType.id);
      this.reviewStatus = this.reviewStatuses.find(x => x.id == this.reviewStatus?.id);
      if (!this.reviewStatus) {
        this.reviewStatus = { id: 0, status: 1, type: 1, reviewBoardTypeID: this.reviewBoardType.id } as ReviewStatus;
      }
    });
  }

  buildForm() {
    this.form = new FormGroup({
      reviewStatusNameCtrl: this.reviewStatusNameCtrl,
      reviewStatusDescriptionCtrl: this.reviewStatusDescriptionCtrl,
      reviewStatusCodeCtrl: this.reviewStatusCodeCtrl,
      reviewStatusTypeCtrl: this.reviewStatusTypeCtrl
    });
  }

  displayObject(e: any) {
    return e ? e.name : null;
  }

  save() {
    if (this.reviewStatus) {
      this.reviewStatus.name = this.reviewStatusNameCtrl.value;
      this.reviewStatus.description = this.reviewStatusDescriptionCtrl.value;
      this.reviewStatus.code = this.reviewStatusCodeCtrl.value;
      this.reviewStatus.code = this.reviewStatus.code?.toUpperCase();
      this.reviewStatus.reviewBoardTypeID = this.reviewBoardType.id ?? 0;
      this.reviewStatus.type = this.reviewStatusTypeCtrl.value?.value;
      this.data.reviewStatus = this.reviewStatus;
      this.dialog.close(this.data);
    }
  }

  cancel() {
    this.form.reset();
    this.dialog.close();
  }

  formValid() {
    return !utils.isNullValue(this.reviewStatusNameCtrl.value) && !utils.isNullValue(this.reviewStatusDescriptionCtrl.value) && !utils.isNullValue(this.reviewStatusCodeCtrl.value) && !utils.isNullValue(this.reviewStatus?.color);
  }

  selectedColor(e: any) {
    if (this.reviewStatus)
      this.reviewStatus.color = e;
    this.form.markAsDirty();
  }

  actionsChanged(reviewStatusActions: ReviewStatusAction[]) {
    if (this.reviewStatus) {
      this.form.markAsDirty();
      this.reviewStatus.reviewStatusActions = reviewStatusActions;
    }
  }

}
