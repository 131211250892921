import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ComponentRegistry, RegisterComponent } from 'src/app/common/extensions/decorator';
import { SiteConfigurationComponent } from '../site-configuration/site-configuration.component';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { BaseComponent } from 'src/app/common/base/base.component';

@RegisterComponent('HomeComponent')
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {

  constructor(
    protected override injector: Injector,
    private registry: ComponentRegistry) {
    super(injector);
  }

  ngOnInit() {
    this.registry.registerComponent('SiteConfigurationComponent', SiteConfigurationComponent);
  }

  refresh() {

  }
}
