import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BeamlineShutterCreate, BeamlineShutterDelete, BeamlineShutterRefresh, BeamlineShutterRefreshById, BeamlineShutterUpdate } from './beamline-shutter.action';
import { BeamlineShutterRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { CatalogService } from '../../catalog-service';
import { Catalog } from 'src/app/common/enumerations/enumerations';
import { A } from '@angular/cdk/keycodes';


export class BeamlineShutterStateModel {
  data: BeamlineShutterRelation[] = [];
}

@State<BeamlineShutterStateModel>({
  name: 'BeamlineShutter',
  defaults: {
    data: []
  }
})
@Injectable()
export class BeamlineShutterState {
  constructor(
    private _catalog: CatalogService
  ) { }

  @Selector()
  static read(state: BeamlineShutterStateModel) {
    return state.data;
  }

  @Action(BeamlineShutterRefresh)
  refresh({ patchState }: StateContext<BeamlineShutterStateModel>) {
    this._catalog.GetJoinCreated(Catalog.BeamlineCatalog, true).subscribe(
      data => {
        const beamlineShutters = data.beamlineShutterRelations;
        patchState({ data: beamlineShutters });
      },
      error => console.error(error)
    );
  }

  @Action(BeamlineShutterRefreshById)
  refreshById({ getState, patchState }: StateContext<BeamlineShutterStateModel>, { id }: BeamlineShutterRefreshById) {
    this._catalog.GetBeamlineShutter(id).subscribe(item => {
      const state = getState();
      const index = state.data.map(x => x.beamlineId).indexOf(id);
      if (index >= 0)
        state.data.splice(index, 1);

      patchState({
        data: [...state.data, item]
      });
    })

  }

  @Action(BeamlineShutterCreate)
  create({ getState, patchState }: StateContext<BeamlineShutterStateModel>, { item }: BeamlineShutterCreate) {
    const state = getState();
    const index = state.data.map(x => x.beamlineId).indexOf(item.beamlineId);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(BeamlineShutterUpdate)
  update({ getState, patchState }: StateContext<BeamlineShutterStateModel>, { id, item }: BeamlineShutterUpdate) {
    const state = getState();
    const index = state.data.map(x => x.beamlineId).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(BeamlineShutterDelete)
  delete({ getState, patchState }: StateContext<BeamlineShutterStateModel>, { id }: BeamlineShutterDelete) {
    patchState({
      data: getState().data.filter(x => x.beamlineId !== id)
    });
  }

}
