import { APLGroupManager } from "./apl-group-manager.model";
import { APLGroupMasterRoleCatalog } from "./apl-group-master-role-catalog.model";
import { APLGroupCard_PTCs } from "./apl-group-ptc";

export class APLGroupRoleCatalog {
  public id!: number;
  public name!: string;
  public status!: number;
  public aplGroupMaster?: APLGroupMasterRoleCatalog;
  public aplGroupManagers: APLGroupManager[];
  public aplProcedureTrainingCoordinators?: APLGroupCard_PTCs[];

  constructor() {
    this.aplGroupMaster = this.aplGroupMaster ?? new APLGroupMasterRoleCatalog();
    this.aplGroupManagers = [];
    this.aplProcedureTrainingCoordinators = []
  }
}
