import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { RadMeter, RadMeterData } from 'src/app/components/catalogs/meters-catalog/rad-meters';
import { RadMetersDetailsComponent } from 'src/app/components/catalogs/meters-catalog/rad-meters-details/rad-meters-details.component';

@Component({
  selector: 'app-cl-rad-meters-add',
  templateUrl: './cl-rad-meters-add.component.html',
  styleUrls: ['./cl-rad-meters-add.component.scss']
})
export class ClRadMetersAddComponent implements OnInit {

  radMeterCtrl = new FormControl();

  radMetersList: RadMeter[];
  radMeters!: RadMeter[];
  radMeters$!: Observable<RadMeter[]>;
  radMetersSubs!: Subscription;

  selected!: string;

  constructor(
    private dialogRef: MatDialogRef<ClRadMetersAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RadMeter[],
    private store: Store,
    private dialog: MatDialog
  ) {
    this.radMetersList = data;
  }

  ngOnInit(): void {
    this.loadRadMeters();
  }

  loadRadMeters() {
    this.radMeters$ = this.store.select(state => state.RadMeters.data);
    this.radMetersSubs = this.radMeters$.subscribe(data => {
      this.radMeters = data;
    });
  }

  add() {
    this.dialogRef.close(this.radMeterCtrl.value);
  }

  close() {
    this.dialogRef.close();
  }

  changedRadMeter(e: any) {
    const value = e.target.value;
  }

  selectedRadMeter(value: RadMeter) {
    this.selected = 'Selected: ' + value.serialNumber;
  }

  displayObject(e: any) {
    return e ? e.serialNumber : null;
  }

  addNewMeter() {
    const NewRadMeter = {
      id: 0,
      serialNumber: '',
      calibrationDueDate: undefined,
      sourceReading: '',
      backgroundReading: '',
    } as RadMeter;
    const data = {
      radMeter: NewRadMeter,
      radMetersSerialNumbers: []
    } as RadMeterData
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(RadMetersDetailsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(dataReturned => {
      if (dataReturned != undefined) {
        this.dialogRef.close(dataReturned);
      }
    });
  }

  isDateExpired(d?: Date | null) {
    if (d) {
      const mdate = moment(d);
      return mdate.isBefore(new Date());
    }
    return false;
  }
}
