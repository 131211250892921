<div class="row">
  <div class="col-12">
    <mat-slide-toggle
      class="float-right"
      color="warn"
      [(ngModel)]="showInactive"
      (change)="showInactiveItems()"
      >Show Inactive</mat-slide-toggle
    >
  </div>
</div>
<div class="mat-container left-table-container">
  <mat-table
    [dataSource]="dataSource"
    matSort
    multiTemplateDataRows
    matSortActive="created"
    matSortDisableClear
  >
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Schedule Type
      </mat-header-cell>
      <mat-cell
        class="bold"
        *matCellDef="let element"
        [ngClass]="{ gray: !element.isActive }"
      >
        <span [innerHTML]="element.name | highlightSearch : filter"></span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="document">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Document Type
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ gray: !element.isActive }"
      >
        <span
          [innerHTML]="element.documentType?.name | highlightSearch : filter"
        ></span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="procedure">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Procedure Type
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ gray: !element.isActive }"
      >
        <span
          [innerHTML]="element.procedureType?.name | highlightSearch : filter"
        ></span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <mat-cell
        class="inner-cell"
        [ngClass]="{
          hidden: !element.scheduleSubtypesTable,
          gray: !element.isActive
        }"
        *matCellDef="let element"
        [attr.colspan]="displayedColumns.length"
      >
        <div
          class="element-detail"
          *ngIf="element.scheduleSubtypesTable"
          [@detailExpand]="
            element.id == selectedScheduleTypeIndex ? 'expanded' : 'collapsed'
          "
        >
          <mat-table
            #innerTables
            class="inner-table"
            #innerSort="matSort"
            [dataSource]="element.scheduleSubtypesTable"
            matSort
          >
            <ng-container
              matColumnDef="{{ innerColumn }}"
              *ngFor="let innerColumn of innerDisplayedColumns"
            >
              <mat-cell *matCellDef="let element">
                <span
                  [innerHTML]="element[innerColumn] | highlightSearch : filter"
                ></span>
              </mat-cell>
            </ng-container>
            <mat-row
              class="inner-detail-row blue"
              *matRowDef="let row; columns: innerDisplayedColumns"
              [ngClass]="{
                'mat-row-highlight-subtype':
                  selectedScheduleSubtypeIndex == row.id
              }"
              (click)="seletedSubtype(row)"
            ></mat-row>
          </mat-table>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      #matrow
      *matRowDef="let element; columns: displayedColumns"
      class="blue element-row"
      [class.example-expanded-row]="selectedScheduleTypeIndex === element.id"
      [ngClass]="{
        'mat-row-highlight': selectedScheduleTypeIndex == element.id
      }"
      [attr.position]="getPosition(element)"
      (click)="toggleRow(element)"
    >
    </mat-row>
    <mat-row
      *matRowDef="let element; columns: ['expandedDetail']"
      class="blue detail-row"
      [ngClass]="{
        'detail-row-hidden': !element.scheduleSubtypesTable,
        'mat-row-highlight': selectedScheduleTypeIndex == element.id
      }"
    ></mat-row>
  </mat-table>
</div>

<!-- <button
  mat-flat-button
  color="primary"
  class="start-new-btn"
  [disabled]="!createAvailable"
  (click)="create()"
>
  Create Schedule Type
</button> -->
