<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-md-12">
      <h4 mat-dialog-title class="text-center w-100">{{ inputData.title }}</h4>
      <div mat-dialog-content class="content">
        <div class="row">
          <div class="col-md-12">
            <mat-slide-toggle
              color="accent"
              class="float-right"
              (change)="toggleChanged($event)"
              *ngIf="resourcesFiltered?.length && title != 'Edit Location'"
              >Add from existing Locations</mat-slide-toggle
            >

            <mat-form-field *ngIf="!addFromExisting">
              <mat-label>Location Name</mat-label>
              <input
                aria-label="resourceName"
                matInput
                [(ngModel)]="resourceName"
                formControlName="resourceNameCtrl"
                (keyup)="isRepeated(resourceNameCtrl)"
              />
              <mat-error *ngIf="resourceNameCtrl?.invalid">{{
                getErrorMsg(resourceNameCtrl)
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="addFromExisting">
              <mat-label>{{
                resource ? "Location" : "Select Location"
              }}</mat-label>
              <input
                type="text"
                matInput
                name="resourceCtrl"
                formControlName="resourceCtrl"
                [matAutocomplete]="autoResource"
              />
              <mat-autocomplete
                #autoResource="matAutocomplete"
                #resourceAutocomplete
                [displayWith]="displayObject"
                (optionSelected)="selectedResource($event)"
              >
                <mat-option
                  *ngFor="let resource of resourcesFiltered"
                  [value]="resource"
                >
                  {{ resource.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Description</mat-label>
              <textarea
                aria-label="resourceDesc"
                matInput
                [(ngModel)]="resourceDesc"
                formControlName="resourceDescCtrl"
              ></textarea>
              <mat-error *ngIf="resourceNameCtrl?.invalid">{{
                getErrorMsg(resourceNameCtrl)
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div mat-dialog-actions align="center">
            <button
              type="button"
              mat-flat-button
              color="warn"
              (click)="cancel()"
              [disabled]="loading"
            >
              Cancel
            </button>
            <button
              type="submit"
              mat-flat-button
              color="primary"
              (click)="submit()"
              [disabled]="!formValid()"
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
