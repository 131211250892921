<div class="review-wrapper small" *ngIf="currentVersion.procedureID">
  <div
    class="d-flex flex-row justify-content-between align-items-center mb-1"
    *ngIf="!procedure"
  >
    Loading procedure
  </div>
  <div
    class="d-flex flex-row justify-content-between align-items-center mb-1"
    *ngIf="procedure"
  >
    <div class="d-flex">
      <div class="pt-2 ml-2">
        <mat-icon color="primary">description</mat-icon>
      </div>
      <div class="text-center ml-4">
        Procedure No. <br /><b>{{ procedure.procedureNumber }}</b>
      </div>
    </div>
    <div class="text-center">
      Title <br /><b>{{ procedure.title }}</b>
    </div>
    <div class="text-center">
      Rev <br /><b>{{ procedure.revision }}</b>
    </div>
    <div class="d-flex justify-content-evenly">
      <div class="text-center">
        <checklist-builder-review-signatures
          [title]="'Preparers'"
          [signatures]="preparers"
          [procedure]="procedure"
          [templateType]="templateType"
          [type]="1"
          class="relative"
          [currentTemplate]="currentVersion"
          (requestRefresh)="requestRefresh.emit()"
        ></checklist-builder-review-signatures>
      </div>
      <div class="text-center">
        <checklist-builder-review-signatures
          [title]="'Reviewers'"
          [signatures]="reviewers"
          [procedure]="procedure"
          [templateType]="templateType"
          [type]="2"
          class="relative"
          [currentTemplate]="currentVersion"
          (requestRefresh)="requestRefresh.emit()"
        ></checklist-builder-review-signatures>
      </div>
      <div class="text-center">
        <checklist-builder-review-signatures
          [title]="'Approvers'"
          [signatures]="approvers"
          [procedure]="procedure"
          [templateType]="templateType"
          [type]="3"
          class="relative"
          [currentTemplate]="currentVersion"
          (requestRefresh)="requestRefresh.emit()"
        ></checklist-builder-review-signatures>
      </div>
    </div>

    <div
      class="suggestions p-1"
      *ngIf="
        currentVersion?.status == checklistTemplateStatusEnum.Draft ||
          currentVersion?.status == checklistTemplateStatusEnum.InReview ||
          currentVersion?.status ==
            checklistTemplateStatusEnum.CorrectionsPending ||
          currentVersion?.status ==
            checklistTemplateStatusEnum.CorrectionsInProgress ||
          currentVersion?.status == checklistTemplateStatusEnum.PendingApr ||
          currentVersion?.status == checklistTemplateStatusEnum.Suggesting ||
          currentVersion?.status == checklistTemplateStatusEnum.Approving ||
          currentVersion?.status == checklistTemplateStatusEnum.PendingRev;
        else filler
      "
    >
      <div class="d-flex flex-row">
        <div class="pt-2 pl-2">
          <mat-icon class="pink">reviews</mat-icon>
        </div>
        <div class="pl-2">
          <span class="float-right text-right pr-2">
            Total Suggestions: <b>{{ totalAnnotations }}</b>
            <br />
            Active Suggestions: <b>{{ activeAnnotations }}</b>
          </span>
        </div>
      </div>
    </div>
    <ng-template #filler><span class="filler"></span></ng-template>
  </div>
</div>
