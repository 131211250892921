import { Component, Input, OnInit, EventEmitter, Output, OnDestroy, AfterViewInit } from '@angular/core';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { ChecklistTemplate, ChecklistExecStatus } from 'src/app/components/checklists/checklists';
import { WFStepLocalResource, WFStepStatusLocal } from 'src/app/services/work-flow/work-flow';
import { utils } from 'src/app/modules/libs/utils';
@Component({
  selector: 'checklist-builder-step-config',
  templateUrl: './checklist-builder-step-config.component.html',
  styleUrls: ['./checklist-builder-step-config.component.scss']
})
export class ChecklistBuilderStepConfigComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() step!: WFStepLocalResource;
  @Input() currentVersion!: ChecklistTemplate;
  @Input() disabled!: boolean;
  @Output() statusChanged = new EventEmitter<any>();

  allStatuses!: ChecklistExecStatus[];
  statuses!: (number | undefined)[];
  wfStatuses!: WFStepStatusLocal[];
  formStatus = FormStatusEnum;

  ngAfterViewInit(): void {
    this.checkVisible('#stepConfig', 'visible');
  }

  ngOnInit(): void {
    this.loadStatuses();
  }

  ngOnDestroy() {
  }

  loadStatuses() {
    this.wfStatuses = utils.cloneDeep(this.step.wfStepStatusLocals);
    this.allStatuses = utils.cloneDeep(this.currentVersion.documentType?.checklistExecStatuses?.filter(x => x.id != 0).sort((a, b) => utils.sort(a.order, b.order, true))) ?? [];
    this.statuses = this.step.wfStepStatusLocals.map(x => x.checklistExecStatus?.id);
    this.allStatuses.map(s => {
      const wfStatus = this.wfStatuses.find(x => x.status == s.id);
      s.enabled = this.statuses.filter(x => x == s.id && !wfStatus?.hidden).length == 1;
      s.hidden = this.statuses.filter(x => x == s.id && wfStatus?.hidden).length == 1;
    });
  }

  accept() {
    this.statusChanged.emit(this.wfStatuses);
  }

  cancel() {
    this.statusChanged.emit(null);
  }

  getClass(color: string) {
    return color + '-background';
  }

  hiddenChange(e: any, status: ChecklistExecStatus) {
    let wfStatus = this.wfStatuses.find(x => x.status == status.id);
    const s = this.allStatuses.find(x => x.id == status.id);
    if (s) {
      s.hidden = e.checked;
      s.enabled = !e.checked;
    }
    if (wfStatus) {
      wfStatus.hidden = e.checked;
      status.enabled = e.checked;
      status.hidden = !e.checked;
    }
    else {
      wfStatus = {
        id: 0,
        wfStepLocalID: this.step.id,
        checklistExecStatus: status,
        hidden: e.checked,
        status: status.id,
        cssClass: status.cssClass,
        enabled: false
      };
      this.wfStatuses.push(wfStatus);
    }
  }

  enabledChange(e: any, status: ChecklistExecStatus) {
    let wfStatus = this.wfStatuses.find(x => x.status == status.id);
    const s = this.allStatuses.find(x => x.id == status.id);
    if (s) {
      s.enabled = e.checked;
      s.hidden = !e.checked;
    }
    if (e.checked) {
      if (!wfStatus) {
        wfStatus = {
          id: 0,
          wfStepLocalID: this.step.id,
          checklistExecStatus: status,
          hidden: false,
          status: status.id,
          cssClass: status.cssClass,
          enabled: e.checked
        };
        this.wfStatuses.push(wfStatus);
      }
      else {
        wfStatus.hidden = false;
      }
    }
    else {
      if (wfStatus) {
        this.wfStatuses.splice(this.wfStatuses.findIndex(x => x.status == status.id), 1);
        const s = this.allStatuses.find(x => x.id == status.id);
        if (s) s.hidden = false;
      }
      // else {
      //   wfStatus.hidden = true;
      // }
    }
  }

  checkVisible(elm: any, evalType: any) {
    evalType = evalType || 'visible';

    const vpH = $(window).height() ?? 0; // Viewport Height
    const st = $(window).scrollTop() ?? 0; // Scroll Top
    const y = $(elm).offset()?.top ?? 0;
    const elementHeight = $(elm).height() ?? 0;

    const visible = ((y + elementHeight) < (vpH + st)) && (y > (st - elementHeight));
    if (!visible) {
      $(elm).offset({ top: vpH - elementHeight - 50 });
    }
  }

}
