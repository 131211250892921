<div class="container-fluid">
  <form [formGroup]="formGroup">
    <div class="row mt-3">
      <div class="col">
        <mat-slide-toggle
          formControlName="notificationActiveCtrl"
          [disabled]="!hasRoles([roleEnum.SA])"
          (change)="activeToggleChanged($event)"
          >SCF Notifications Active
        </mat-slide-toggle>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <mat-form-field appearance="outline" class="filter">
          <mat-label>Email List for HP Notifications</mat-label>
          <input
            aria-label="emails"
            matInput
            (change)="emailsChanged(1, $event)"
            formControlName="notificationHPEmailsCtrl"
            type="text"
          />
          <mat-hint><span [innerHTML]="getHint(1)"></span></mat-hint>
          <mat-error *ngIf="notificationHPEmailsCtrl?.invalid">{{
            getErrorMsg(notificationHPEmailsCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <mat-form-field appearance="outline" class="filter">
          <mat-label>Email List for RP Notifications</mat-label>
          <input
            aria-label="emails"
            matInput
            (change)="emailsChanged(2, $event)"
            formControlName="notificationRPEmailsCtrl"
            type="text"
          />
          <mat-hint><span [innerHTML]="getHint(2)"></span></mat-hint>
          <mat-error *ngIf="notificationRPEmailsCtrl?.invalid">{{
            getErrorMsg(notificationRPEmailsCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <mat-form-field appearance="outline" class="filter">
          <mat-label>Email List for All Notifications</mat-label>
          <input
            aria-label="emails"
            matInput
            (change)="emailsChanged(0, $event)"
            formControlName="notificationEmailsCtrl"
            type="text"
          />
          <mat-hint><span [innerHTML]="getHint(0)"></span></mat-hint>
          <mat-error *ngIf="notificationEmailsCtrl?.invalid">{{
            getErrorMsg(notificationEmailsCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
