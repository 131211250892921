import { Component, Inject, OnInit, OnDestroy, Injector } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { RadMeter, RadMeterType, RadMeterUnit, RadMeterModel, RadMeterData } from '../rad-meters';
import { RadMetersService } from '../services/rad-meters.service';
import { RadMetersRefresh } from '../store/rad-meters/rad-meters.action';
import { RadMeterModelsComponent } from '../rad-meter-models/rad-meter-models.component';
import { RadMetersTypesComponent } from '../rad-meters-types/rad-meters-types.component';
import { RadMetersUnitsComponent } from '../rad-meters-units/rad-meters-units.component';

@Component({
  selector: 'rad-meters-details',
  templateUrl: './rad-meters-details.component.html',
  styleUrls: ['./rad-meters-details.component.scss']
})
export class RadMetersDetailsComponent extends BaseComponent implements OnInit, OnDestroy {

  radMeter!: RadMeter;
  radMeters!: RadMeter[];
  radMeters$!: Observable<RadMeter[]>;
  radMetersSubs!: Subscription;

  showCancelButton = true;
  isModifiying = false;

  public typeSelected!: number;
  public unitSelected!: number;
  public modelSelected!: number;
  listMeterTypes!: RadMeterType[];
  listMeterUnits!: RadMeterUnit[];
  listMeterModels!: RadMeterModel[];

  constructor(
    protected override injector: Injector,
    private _service: RadMetersService,
    private dialogRef: MatDialogRef<RadMetersDetailsComponent>,
    private radMeterService: RadMetersService,
    @Inject(MAT_DIALOG_DATA) public data: RadMeterData) {
    super(injector);
    this.store.dispatch(new RadMetersRefresh());
  }

  override ngOnDestroy(): void {
    this.radMetersSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.obtainMeterUnitsList();
    this.obtainMeterTypesList();
    this.getMeterModelsList();

    this.loadRadMeters();

    this.radMeter = this.data.radMeter;
    if (this.radMeter.radMeterUnitID) {
      this.unitSelected = this.radMeter.radMeterUnitID;
    }
    if (this.radMeter.radMeterTypeID) {
      this.typeSelected = this.radMeter.radMeterTypeID;
    }
    if (this.radMeter.radMeterModelID) {
      this.modelSelected = this.radMeter.radMeterModelID;
    }

    this.isModifiying = this.radMeter.id == 0 ? false : true;
  }

  loadRadMeters() {
    this.radMeters$ = this.store.select(state => state.RadMeters.data);
    this.radMetersSubs = this.radMeters$.subscribe(data => {
      this.radMeters = data;
    });
  }


  close() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveCatalog() {
    let dataValid = true;
    if (this.radMeter.serialNumber == '' || this.radMeter.serialNumber == undefined) {
      dataValid = false;
      this.alert.error('Please insert the serial number');
    }
    if (!this.radMeter.calibrationDueDate) {
      dataValid = false;
      this.alert.error('Please select a valid value for calibration date field');
    }
    if (!this.unitSelected) {
      dataValid = false;
      this.alert.error('Please select a valid value for unit field');
    }
    if (!this.typeSelected) {
      dataValid = false;
      this.alert.error('Please select a valid value for type field');
    }
    if (this.radMeters.some(x => x.serialNumber === this.radMeter.serialNumber && !this.isModifiying)) {
      dataValid = false;
      this.alert.error('The serial number already exists');
    }
    if (!this.modelSelected) {
      dataValid = false;
      this.alert.error('Please select a valid value for Model field');
    }

    if (dataValid) {
      this.radMeter.radMeterTypeID = this.typeSelected;
      this.radMeter.radMeterUnitID = this.unitSelected;
      this.radMeter.radMeterModelID = this.modelSelected;
      this.isModifiying ? this.updateRadMeterCatalog() : this.createRadMeterCatalog();
    }
  }

  updateRadMeterCatalog() {
    this.radMeterService.updateRadMeterCatalog(this.radMeter).toPromise().then(result => {
      if (result) {
        this.radMeter = result;
        this.alert.info('Rad Meter ' + this.radMeter.serialNumber + ' Updated!');
        this.dialogRef.close(this.radMeter);
      }
    });
  }

  createRadMeterCatalog() {
    this.radMeterService.createRadMeterCatalog(this.radMeter).toPromise().then(result => {
      if (result) {
        this.radMeter = result;
        this.alert.success('Rad Meter ' + this.radMeter.serialNumber + ' Created!');
        this.dialogRef.close(this.radMeter);
      }
    });
  }

  async obtainMeterTypesList() {
    await this._service.getRadMeterTypes().toPromise().then(result => {
      if (!(result?.length)) {
        result = [];
      }
      this.listMeterTypes = result.filter(type => type.status === 1);
    });
  }

  async obtainMeterUnitsList() {
    await this._service.getRadMeterUnits().toPromise().then(result => {
      if (!(result?.length)) {
        result = [];
      }
      this.listMeterUnits = result.filter(unit => unit.status === 1);
    });
  }

  async getMeterModelsList() {
    await this._service.getRadMeterModels().toPromise().then(result => {
      this.listMeterModels = result?.filter(model => model.status === 1) ?? [];
    });
  }

  manageMeterTypes() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '35vw';
    dialogConfig.maxHeight = '85%';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(RadMetersTypesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.obtainMeterTypesList();
      }
    });
  }

  manageMeterUnits() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '35vw';
    dialogConfig.maxHeight = '85%';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(RadMetersUnitsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.obtainMeterUnitsList();
      }
    });
  }

  manageMeterModels() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '35vw';
    dialogConfig.maxHeight = '85%';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(RadMeterModelsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.getMeterModelsList();
      }
    });
  }

}
