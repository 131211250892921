<div class="card">
  <div class="card-header">
    <span class="card-title">Add Privilege</span>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col">
        <mat-form-field class="p-0" appearance="outline">
          <mat-label>Category</mat-label>
          <input
            aria-label="category"
            matInput
            type="text"
            #typeInput
            (keyup)="changedCategory($event)"
            [matAutocomplete]="autoType"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #autoType="matAutocomplete"
            (optionSelected)="selectedType($event)"
            #typeAutocomplete
            [displayWith]="displayObject"
          >
            <mat-option
              *ngFor="let elem of privilegeCategoriesFiltered"
              [value]="elem"
            >
              {{ elem.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="p-0" appearance="outline">
          <mat-label>Privilege</mat-label>
          <input
            aria-label="privilege"
            matInput
            type="text"
            #privilegeInput
            [matAutocomplete]="autoPriv"
            (keyup)="changedPrivilege($event)"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #autoPriv="matAutocomplete"
            (optionSelected)="selectedPriv($event)"
            #privAutocomplete
            [displayWith]="displayObject"
          >
            <mat-option *ngFor="let elem of privilegesFiltered" [value]="elem">
              {{ elem.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="card-footer" matDialogActions align="center">
    <button mat-flat-button (click)="cancel()" color="warn">Cancel</button>
    <button
      mat-flat-button
      (click)="accept()"
      color="primary"
      [disabled]="!privilege"
    >
      Accept
    </button>
  </div>
</div>
