import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { RadMeter, RadMeterModel, RadMeterType, RadMeterUnit } from 'src/app/components/catalogs/meters-catalog/rad-meters';

@Injectable({
  providedIn: 'root'
})
export class RadMetersService {

  BASE_URL = environment.urls.baseApiUrl; // Api URL
  ApiMetersType = '/RadMeterTypes';
  ApiMetersUnit = '/RadMeterUnits';
  ApiMeterModels = '/RadMeterModel';
  ApiRadMeterCatalog = '/RadMeters';

  constructor(private http: HttpClient) { }
  getAll(): Observable<RadMeter[]> {
    return this.http.get<RadMeter[]>(this.BASE_URL + this.ApiRadMeterCatalog);
  }

  getRadMeterCatalogById(radMeterCatalogId: number): Observable<RadMeter> {
    return this.http.get<RadMeter>(this.BASE_URL + this.ApiRadMeterCatalog + '/' + radMeterCatalogId);
  }

  createRadMeterCatalog(radMeterCatalog: RadMeter): Observable<RadMeter> {
    return this.http.post<RadMeter>(this.BASE_URL + this.ApiRadMeterCatalog, radMeterCatalog);
  }

  updateRadMeterCatalog(radMeterCatalog: RadMeter) {
    return this.http.put<RadMeter>(this.BASE_URL + this.ApiRadMeterCatalog + '/' + radMeterCatalog.id, radMeterCatalog);
  }

  deleteRadMeterCatalog(radMeterCatalogId: number) {
    return this.http.delete(this.BASE_URL + this.ApiRadMeterCatalog + '/' + radMeterCatalogId);
  }

  //#region.RadMeterType
  getRadMeterTypes(): Observable<RadMeterType[]> {
    return this.http.get<RadMeterType[]>(this.BASE_URL + this.ApiMetersType);
  }

  getRadMeterTypeById(radMeterTypeId: number): Observable<RadMeterType> {
    return this.http.get<RadMeterType>(this.BASE_URL + this.ApiMetersType + '/' + radMeterTypeId);
  }

  createRadMeterType(radMeterType: RadMeterType): Observable<RadMeterType> {
    return this.http.post<RadMeterType>(this.BASE_URL + this.ApiMetersType, radMeterType);
  }

  updateRadMeterType(radMeterType: RadMeterType) {
    return this.http.put<RadMeterType>(this.BASE_URL + this.ApiMetersType + '/' + radMeterType.id, radMeterType);
  }

  deleteRadMeterType(radMeterTypeId: number) {
    return this.http.delete(this.BASE_URL + this.ApiMetersType + '/' + radMeterTypeId);
  }
  //#endregion

  //#region.RadMeterUnit
  getRadMeterUnits(): Observable<RadMeterUnit[]> {
    return this.http.get<RadMeterUnit[]>(this.BASE_URL + this.ApiMetersUnit);
  }

  getRadMeterUnitById(radMeterUnitId: number): Observable<RadMeterUnit> {
    return this.http.get<RadMeterUnit>(this.BASE_URL + this.ApiMetersUnit + '/' + radMeterUnitId);
  }

  createRadMeterUnit(radMeterUnit: RadMeterUnit): Observable<RadMeterUnit> {
    return this.http.post<RadMeterUnit>(this.BASE_URL + this.ApiMetersUnit, radMeterUnit);
  }

  updateRadMeterUnit(radMeterUnit: RadMeterUnit) {
    return this.http.put<RadMeterUnit>(this.BASE_URL + this.ApiMetersUnit + '/' + radMeterUnit.id, radMeterUnit);
  }

  deleteRadMeterUnit(radMeterUnitId: number) {
    return this.http.delete(this.BASE_URL + this.ApiMetersUnit + '/' + radMeterUnitId);
  }
  //#endregion

  //#region.RadMeterModels
  getRadMeterModels(): Observable<RadMeterModel[]> {
    return this.http.get<RadMeterModel[]>(this.BASE_URL + this.ApiMeterModels);
  }

  getRadMeterModelById(radMeterModelId: number): Observable<RadMeterModel> {
    return this.http.get<RadMeterModel>(this.BASE_URL + this.ApiMeterModels + '/' + radMeterModelId);
  }

  createRadMeterModel(radMeterModel: RadMeterModel): Observable<RadMeterModel> {
    return this.http.post<RadMeterModel>(this.BASE_URL + this.ApiMeterModels, radMeterModel);
  }

  updateRadMeterModel(radMeterModel: RadMeterModel) {
    return this.http.put<RadMeterModel>(this.BASE_URL + this.ApiMeterModels + '/' + radMeterModel.id, radMeterModel);
  }

  deleteRadMeterModel(radMeterModelId: number) {
    return this.http.delete(this.BASE_URL + this.ApiMeterModels + '/' + radMeterModelId);
  }
  // //#endregion



}
