import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlightSearch'
})
export class HighlightSearchPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value?: string, search?: string | null): SafeHtml {
    const nullValue = this.sanitizer.bypassSecurityTrustHtml('');
    if (search) {
      const valueStr = ((value ?? '') + ''); // Ensure numeric values are converted to strings
      return valueStr ? this.sanitizer.bypassSecurityTrustHtml(this.replace(valueStr, search)) : nullValue;
    }
    else {
      return value ? this.sanitizer.bypassSecurityTrustHtml(value) : nullValue;
    }
  }

  private replace(value: string, search: string): string {
    var pos = value.toLowerCase().indexOf(search.toLowerCase());
    var returnVal = value;
    if (pos >= 0) {
      var rby = '<span class="hlt">' + value.substring(pos, pos + search.length) + '</span>';
      returnVal = value.substring(0, pos) + rby + this.replace(value.substring(pos + search.length), search);
    }
    return returnVal;
  }

}
