import { Component, OnInit, Input, OnDestroy, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { Schedule } from 'src/app/components/schedules/models/schedule';
import { ScheduleType } from 'src/app/components/schedules/models/schedule-type';
import * as moment from 'moment';


@Component({
  selector: 'cl-schedule-and-checkbox',
  templateUrl: './cl-schedule-and-checkbox.component.html',
  styleUrls: ['./cl-schedule-and-checkbox.component.scss']
})
export class ClScheduleAndCheckboxComponent extends ClBaseComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() scheduleTypeId?: number;
  @Input() locationName?: string | null;

  editing!: boolean;

  scheduleName?: string;

  scheduleType?: ScheduleType;
  scheduleTypes?: ScheduleType[];
  scheduleTypesFiltered?: ScheduleType[];
  scheduleTypes$!: Observable<ScheduleType[]>;
  scheduleTypesSubs!: Subscription;

  schedule?: Schedule;
  schedules?: Schedule[];
  schedulesFiltered?: Schedule[];
  schedules$!: Observable<Schedule[]>;
  schedulesSubs!: Subscription;

  subscription1!: Subscription;
  subscription2!: Subscription;

  checkboxChanged(e: any) {
    this.checked = e.checked;
    this.answered.emit(this.checked);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngAfterViewInit();
  }

  ngAfterViewInit() {
    this.scheduleTypes$ = this.store.select(state => state.ScheduleTypes.data);
    this.subscription1 = this.scheduleTypes$.subscribe(data => {
      const scheduleType = data.find(x => x.id == this.scheduleTypeId);
      this.scheduleName = scheduleType?.acronym;
    });

    this.schedules$ = this.store.select(state => state.Schedules.data);
    this.subscription2 = this.schedules$.subscribe(data => {
      this.schedules = data.filter(x => x.statusId != 2 && x.typeId == this.scheduleTypeId);
      this.schedule = this.schedules.find(x => x.scheduleResources.map(r => r.id).includes(this.scheduleResourceId));
      this.locationName = this.locationName ? this.locationName : this.schedule?.scheduleResources.find(x => x.id == this.scheduleResourceId)?.resource?.name;
      this.warning = moment(this.schedule?.expireDate).isBefore(new Date);
    });
  }

  ngOnInit() {

  }

  textChanged() {
    this.editing = false;
    this.changed.emit(this.textValue);
  }

  loadSchedules() {
    this.schedules$ = this.store.select(state => state.Schedules.data);
    this.schedulesSubs = this.schedules$.subscribe(data => {
      if (data?.length) {
        this.schedules = data;
      }
    });
  }

  loadScheduleTypes() {
    this.scheduleTypes$ = this.store.select(state => state.ScheduleTypes.data);
    this.scheduleTypesSubs = this.scheduleTypes$.subscribe(data => {
      if (data?.length) {
        this.scheduleTypes = data;
      }
    });
  }
}
