<button
  mat-stroked-button
  class="ml-1 file-input-button maxWidth d-print-none"
  (click)="fileInput.click()"
  [attr.aria-label]="selectButtonText"
  [disabled]="disabled || uploading"
>
  <span *ngIf="uploading" class="spinner-border spinner-border-sm mr-1"></span>
  <span>{{ uploading ? "Uploading" : selectButtonText }}</span>
  <mat-icon color="accent" *ngIf="!uploading">add_box</mat-icon>
</button>
<input
  aria-label="input"
  #fileInput
  type="file"
  class="hidden"
  [accept]="acceptedTypes"
  [multiple]="allowMultipleFiles"
  (change)="filesChanged($event)"
/>
<div class="row nowrap small">
  <div class="col-12">
    {{ selectedFileText }}
    <button
      *ngIf="selectedFileText != ''"
      mat-icon-button
      (click)="filesChanged(null)"
      aria-label="Remove Selected File(s)"
    >
      <mat-icon color="warn">delete</mat-icon>
    </button>
  </div>
</div>
<p *ngIf="showProgress">
  progress <strong>{{ percentage }}%</strong>
</p>
<div
  *ngIf="showFiles"
  class="border-top p-2"
  style="display: flex; flex-direction: row"
>
  <mat-file
    *ngFor="let attachment of attachments"
    [fileIcon]="attachment.fileIcon"
    [fileName]="attachment.fileName"
    [fileSize]="attachment.SizeKb ?? ''"
    [id]="attachment.id"
    (deleted)="refresh()"
  ></mat-file>
</div>
