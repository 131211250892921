<div class="card">
  <div class="card-header">
    <span class="card-title">Extra Procedure Training</span>
  </div>
  <div class="card-body">
    <mat-form-field>
      <mat-label>Procedure trainings</mat-label>
      <mat-chip-grid #chipList aria-label="Procedure training selection">
        <mat-chip-row
          *ngFor="let procedure of proceduresToCreate"
          (removed)="remove(procedure)"
        >
          {{ procedure }}
          <mat-icon matChipRemove class="d-print-none">cancel</mat-icon>
        </mat-chip-row>
        <input
          tabindex="-1"
          *ngIf="!proceduresToCreate.length"
          placeholder="Select an extra procedure training"
          #procedureInput
          [formControl]="procedureCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"
          (keyup)="applyFilter($event)"
          (open)="$event.preventDefault()"
        />
      </mat-chip-grid>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selected($event)"
      >
        <mat-option
          *ngFor="let procedure of procedureFiltered"
          [value]="procedure"
        >
          {{ procedure?.procedureNumber }} - {{ procedure?.title }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="closeThisModal()">
        Cancel
      </button>
      <span style="margin-right: 10px"></span>
      <button mat-flat-button color="primary" (click)="createExtraTraining()">
        Add
      </button>
    </div>
  </div>
</div>
