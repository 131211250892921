import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplateType } from '../../checklists';
import { ChecklistBuilderSettingsGeneralComponent } from './checklist-builder-settings-general/checklist-builder-settings-general.component';
import { Store } from '@ngxs/store';
import { TemplateTypesRefreshByDocument } from '../../store/template-types/template-types.action';
import { DocumentType } from 'src/app/services/documents/documents';

@Component({
  selector: 'checklist-builder-settings',
  templateUrl: './checklist-builder-settings.component.html',
  styleUrls: ['./checklist-builder-settings.component.scss']
})
export class ChecklistBuilderSettingsComponent {

  templateType?: TemplateType | null;
  documentType: DocumentType;
  loading?: boolean;
  disabled!: boolean;

  loads?: number[] = [];

  @ViewChild(ChecklistBuilderSettingsGeneralComponent) public checklistSettingsGeneral!: ChecklistBuilderSettingsGeneralComponent;

  constructor(
    public dialogRef: MatDialogRef<ChecklistBuilderSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store
  ) {
    // this.templateType = data.templateType;
    this.documentType = data.documentType;
  }

  refresh() {
    if (this.documentType.id)
      this.store.dispatch(new TemplateTypesRefreshByDocument(this.documentType.id));
  }

  close() {
    this.dialogRef.close(this.data);
  }

  disableClose(e: boolean) {
    this.disabled = e;
  }

  selectedTemplateType(e: TemplateType) {
    this.templateType = e;
  }

  setLoading(val: boolean, origin: number) {
    if (val) {
      if (!this.loads?.includes(origin))
        this.loads?.push(origin);
    }
    else {
      const i = this.loads?.findIndex(x => x == origin) as number;
      if (i >= 0)
        this.loads?.splice(i, 1);
    }
    setTimeout(() => {
      this.loading = (this.loads?.length ?? 0) > 0;
    }, 100);

  }
  // back() {
  //   this.templateType = null;
  // }
}
