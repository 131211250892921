<div *ngIf="annotations?.length">
  <div class="row">
    <div class="col-11 offset-1">
      <div *ngFor="let annotation of annotations">
        <checklist-builder-annotation
          [annotation]="annotation"
          [task]="task"
          [currentVersion]="currentVersion"
          [s]="s"
          (changed)="changed.emit($event)"
        >
        </checklist-builder-annotation>
      </div>
    </div>
  </div>
</div>
