<div class="mt-3">
  <!-- <button mat-stroked-button (click)="sendNotification()">
    Notification Test
  </button> -->

  <div
    class="row px-2 justify-content-center relative pb-2"
    *ngFor="let amendment of amendments"
    [ngClass]="{
      'border-amd':
        amendment.number > 0 && amendment.number == getLastAmendment(),
      'border-amd-old':
        amendment.number > 0 && amendment.number != getLastAmendment()
    }"
  >
    <span
      [ngClass]="{
        'amendment-title': amendment.number == getLastAmendment(),
        'amendment-title-old': amendment.number != getLastAmendment()
      }"
      lass="amendment-title"
      *ngIf="amendment.number"
      >Amendment {{ amendment.number }}</span
    >
    <div
      class="col px-1 pb-2"
      *ngFor="let s of getSignatures(amendment.number)"
      [ngClass]="{ 'd-none': !s.visible }"
    >
      <button-signature
        #btnSign
        [roleId]="s.roles"
        [enabled]="s.enabled"
        [roleCodes]="s.roleCodes"
        [userNames]="s.userNames"
        [user]="s.approvedBy"
        [title]="s.name"
        [disabled]="s.disabled"
        [value]="s.approved"
        [showApprove]="s.approve"
        [showDisapprove]="s.disapprove"
        [showUnapprove]="s.unapprove"
        [date]="s.approvedOn"
        (approve)="approve(s)"
        (unapprove)="unapprove(s)"
        (disapprove)="disapprove(s, $event)"
      >
      </button-signature>
    </div>
  </div>
</div>
