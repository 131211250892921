<h6 mat-dialog-title>Request date extension for</h6>
<form #form>
  <mat-dialog-content>
    <!-- <mat-form-field>
			<input
				matInput
				[ngxMatDatetimePicker]="picker"
				placeholder="Choose a Date and Time"
				[formControl]="dateTimeCtrl"
				[min]="minDate"
			/>
			<mat-datepicker-toggle
				matSuffix
				[for]="$any(picker)"
			></mat-datepicker-toggle>
			<ngx-mat-datetime-picker
				#picker
				[showSpinners]="true"
				[showSeconds]="false"
				[stepHour]="1"
				[stepMinute]="15"
				[stepSecond]="1"
				[touchUi]="false"
				[defaultTime]="defaultTime"
				[enableMeridian]="true"
				[disableMinute]="false"
				[hideTime]="false"
			>
				<ng-template>
					<span color="primary">Accept</span>
				</ng-template>
			</ngx-mat-datetime-picker>
		</mat-form-field> -->
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close color="warn">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="check_disabled()"
      (click)="request()"
    >
      Request Extension
    </button>
  </mat-dialog-actions>
</form>
