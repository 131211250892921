<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingAccelerator.data.length }"
>
  <pending-approvals-catalogs-table
    id="accCatalog"
    [dataSource]="dataSourcePendingAccelerator"
  ></pending-approvals-catalogs-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingResource.data.length }"
>
  <pending-approvals-catalogs-table
    id="beamCatalog"
    [dataSource]="dataSourcePendingResource"
  ></pending-approvals-catalogs-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingUser.data.length }"
>
  <pending-approvals-catalogs-table
    id="users"
    [dataSource]="dataSourcePendingUser"
  ></pending-approvals-catalogs-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingRole.data.length }"
>
  <pending-approvals-catalogs-table
    id="roles"
    [dataSource]="dataSourcePendingRole"
  ></pending-approvals-catalogs-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{
    invisible: !dataSourcePendingNotificationTemplate.data.length
  }"
>
  <pending-approvals-catalogs-table
    id="notificationTemplates"
    [dataSource]="dataSourcePendingNotificationTemplate"
  ></pending-approvals-catalogs-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingRSSDatabase.data.length }"
>
  <pending-approvals-catalogs-table
    id="rssdb"
    [dataSource]="dataSourcePendingRSSDatabase"
  ></pending-approvals-catalogs-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingTemplates.data.length }"
>
  <pending-approvals-catalogs-table
    id="checklistTemplates"
    [dataSource]="dataSourcePendingTemplates"
  ></pending-approvals-catalogs-table>
</div>
