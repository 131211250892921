<div class="card">
  <div class="card-header">
    <span class="card-title">Action Item Categories</span>
    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="card-body">
    <div *ngIf="!reviewActionItemType">
      <div class="row" cdkDropList (cdkDropListDropped)="drop($event)">
        <div
          class="col-12 review-box mb-2"
          *ngFor="let reviewActionItemType of reviewActionItemTypes"
        >
          <div cdkDrag class="row">
            <div class="col-1 pt-2">
              <mat-icon cdkDragHandle color="accent" class="drag"
                >drag_handle</mat-icon
              >
            </div>
            <div
              class="col-8 pt-2"
              [ngClass]="{ red: reviewActionItemType.isStopper }"
            >
              {{ reviewActionItemType.order }}. {{ reviewActionItemType.name }}
            </div>
            <div class="col-3 text-right">
              <button mat-icon-button (click)="edit(reviewActionItemType)">
                <mat-icon color="accent">edit</mat-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="reviewActionItemType.reviewActionItems.length == 0"
                (click)="delete(reviewActionItemType)"
              >
                <mat-icon color="warn">delete_outline</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="reviewActionItemType" class="flex-column">
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input
              #reviewActionItemTypeNameCtrlElement
              type="text"
              placeholder="Name"
              matInput
              name="reviewActionItemTypeNameCtrl"
              [formControl]="reviewActionItemTypeNameCtrl"
            />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input
              type="text"
              placeholder="Description"
              matInput
              name="reviewActionItemTypeDescriptionCtrl"
              [formControl]="reviewActionItemTypeDescriptionCtrl"
            />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Acronym</mat-label>
            <input
              type="text"
              placeholder="Description"
              matInput
              name="reviewActionItemTypeCodeCtrl"
              [formControl]="reviewActionItemTypeCodeCtrl"
            />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-slide-toggle
            color="warn"
            [(ngModel)]="isStopper"
            (change)="isStopperChanged($event)"
            >Reviews should be stopped if active actions for this category.
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center" *ngIf="!reviewActionItemType">
      <button mat-flat-button color="primary" (click)="add()">
        <mat-icon>add</mat-icon>Add
      </button>
    </div>
    <div mat-dialog-actions align="center" *ngIf="reviewActionItemType">
      <button mat-stroked-button color="warn" class="mr-2" (click)="cancel()">
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!(formGroup.dirty && formValid()) || !formGroup.dirty"
        (click)="save()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
