<mat-expansion-panel [hideToggle]="hideToggle" class="checklist-expansion">
  <mat-expansion-panel-header class="card-header pb-3">
    <div class="row full-width">
      <div class="col-6">
        <span
          *ngIf="currentChecklist?.serialNo != currentChecklist?.name"
          [ngClass]="currentChecklist?.checklistStatus?.cssClass"
          class="h5"
        >
          {{ currentChecklist?.name }}
          <span class="small">{{
            " (" + currentChecklist?.serialNo + ")"
          }}</span>
        </span>
        <span
          *ngIf="currentChecklist?.serialNo == currentChecklist?.name"
          [ngClass]="currentChecklist?.checklistStatus?.cssClass"
          class="h5"
        >
          {{ currentChecklist?.serialNo }}
        </span>
      </div>
      <div class="col-4">
        <span
          [ngClass]="currentChecklist?.checklistStatus?.cssClass"
          class="h6"
        >
          {{ currentChecklist?.checklistTemplate?.templateType?.name }}
        </span>
      </div>
    </div>
    <div class="col-2">
      <span
        class="h6"
        [ngClass]="currentChecklist?.checklistStatus?.cssClass"
        >{{ currentChecklist?.checklistStatus?.name }}</span
      >
    </div>
  </mat-expansion-panel-header>
  <checklist-sections
    [currentChecklist]="currentChecklist"
    [fullHeight]="true"
  ></checklist-sections>
</mat-expansion-panel>
