import { Component, ViewChild, Injector, OnInit } from '@angular/core';
import { RsswaSidePanelComponent } from './rsswa-side-panel/rsswa-side-panel.component';
import { RSSWAResource } from './rsswa';
import { BaseComponent } from 'src/app/common/base/base.component';
import { RsswaService } from './rsswa.service';
import { FormStatusEnum, FormType, LoadingOrigin, Roles } from 'src/app/common/enumerations/enumerations';
import { Observable, Subscription } from 'rxjs';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { WfSignatureResource } from 'src/app/services/work-flow/work-flow';
import { CanComponentDeactivate } from 'src/app/common/guards/unsaved-changes.guard';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';

@Component({
  selector: 'rsswa',
  templateUrl: './rsswa.component.html',
  styleUrls: ['./rsswa.component.scss']
})

export class RsswaComponent extends BaseComponent implements OnInit, CanComponentDeactivate {

  loading?: boolean;

  rsswa?: RSSWAResource | null;
  rsswas?: RSSWAResource[];
  rsswasFiltered?: RSSWAResource[];
  rsswas$!: Observable<RSSWAResource[]>;
  rsswasSubs!: Subscription;

  canCreateAmendment!: boolean | null;
  wfAmendmentSignature?: WfSignatureResource;
  showAmendmentToggle = false;
  testingCompleteSigned? = false;

  @ViewChild(RsswaSidePanelComponent) public sidePanel!: RsswaSidePanelComponent;

  constructor(
    protected override injector: Injector,
    public service: RsswaService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loadRSSWAs();
  }

  ngAfterViewInit(): void {
    this.setHeights();
  }

  loadRSSWAs() {
    this.rsswas$ = this.store.select(state => state.RSSWA.data);
    this.rsswasSubs = this.rsswas$.subscribe(data => {
      if (data?.length) {
        this.rsswas = data;
        if (this.rsswa?.serialNo) {
          const status = this.rsswa?.status;
          const rsswa = this.rsswas.find(r => r.serialNo == this.rsswa?.serialNo);
          if (rsswa) {
            this.rsswa = rsswa;
            this.sidePanel.selectedRowIndex = this.rsswa.id;
            this.checkPrivileges();
            if (this.rsswa.status != status) this.alert.message('formStateChanged', [new MessagePlaceholder('{serialNo}', this.rsswa?.serialNo), new MessagePlaceholder('{status}', this.rsswa.statusName)], undefined, 'Form Status Changed');
          } else {
            this.rsswa = null;
            this.sidePanel.selectedRowIndex = -1;
          };
        }
      }
    });
  }

  checkPrivileges() {
    const signatures = this.rsswa?.wfTable?.wfTableLocal?.wfSectionLocals.map(s => s.wfSignatures)?.flatMap(a => a || []);
    this.wfAmendmentSignature = this.rsswa?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 9)?.wfSignatures?.[0];
    this.canCreateAmendment = this.rsswa?.status == FormStatusEnum.Active && this.hasPrivilege(this.privilegeEnum.RequestRSSWAAmendment) && this.wfAmendmentSignature != null;
    this.showAmendmentToggle = this.rsswa?.status === FormStatusEnum.Amendment || this.rsswa?.status === FormStatusEnum.AmendmentSign;
    const cancelSignature = signatures?.find(s => s.cancelID);
    this.testingCompleteSigned = signatures?.find(s => s.code == 'TESTING_COMPLETE')?.signed;
    this.setHeights();

  }

  async create() {
    if (await this.canDeactivate())
      if (this.sidePanel) {
        this.setLoading(true, this.loadingOrigin.Main);
        const serialNo = await this.service.getSerialNo().toPromise()
        this.sidePanel.selectedRowIndex = -1;
        this.rsswa = {
          id: 0,
          type: FormType.RSSWA,
          serialNo: serialNo?.serialNo,
          status: FormStatusEnum.New,
          statusName: 'New',
          createdBy: this.getCurrentUser()?.id,
          createdByName: this.getCurrentUser()?.name,
          createdOn: new Date(),
          formStatus: { name: 'New', color: 'yellow' },
          editingBy: this.getCurrentUser(),
          editingById: this.getCurrentUser()?.id,
          startOn: new Date(),
          completeOn: new Date()
        } as RSSWAResource;
        this.rsswa = (await this.service.create(this.rsswa).toPromise()) as RSSWAResource;
        // this.service.currentDocument = this.rsswa;
        this.sidePanel.selectedRowIndex = this.rsswa.id;
        this.showAmendmentToggle = false;
        this.service.showAmendment = false;
        this.setLoading(false, this.loadingOrigin.Main);
        this.setFormDirty();
      }
  }

  async edit(value: boolean) {
    if (value) {
      this.changeEditing(value);
    }
    else if (!value && await this.canDeactivate()) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: "500px", data: {
          message: this.getMessage("SCF_Release_confirmation")
            ?.description, icon: "stop",
        },
      });
      confirm.afterClosed().subscribe(async data => {
        if (data) {
          this.changeEditing(value);
        }
      });
    }
  }

  changeEditing(value: boolean) {
    if (this.rsswa?.id) {
      this.setLoading(true, this.loadingOrigin.Main);
      this.service.setEditing(this.rsswa?.id, value).toPromise();
      this.setLoading(false, this.loadingOrigin.Main);
      if (this.rsswa?.status == this.formStatusEnum.Amendment)
        this.service.showAmendment = value;
    }
  }

  requestAmendment() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.getMessage('request_amendment')?.description,
        icon: 'warn'
      }
    });

    confirm.afterClosed().subscribe(async data => {
      if (data && this.rsswa) {
        this.setLoading(true, 3)
        this.service.requestAmendment(this.rsswa.id).toPromise();
        this.service.showAmendment = true;
      }
    });
  }

  showAmendmentChange(e: any) {
    this.service.showAmendment = e.checked;
  }

  changedEvent(e: RSSWAResource | null) {
    this.rsswa = e;
  }

  rowSelected(e: any) {
    if (e.id != this.rsswa?.id) this.scrollToTop('sidenav-content');
    this.rsswa = e;
    this.service.showAmendment = (this.rsswa?.status == FormStatusEnum.Amendment || this.rsswa?.status == FormStatusEnum.AmendmentSign) && this.rsswa?.editingById == this.getCurrentUser()?.id;
    this.loadRSSWAs();
  }

  duplicate() {
    const removeRef = this.dialog.open(YesNoDialogComponent, {
      width: '20em',
      data: {
        message: 'Are you sure you want to copy this form?',
        icon: 'warn',
        val: false
      }
    });
    removeRef.afterClosed().subscribe(result => {
      if (result && this.rsswa) {
        this.setLoading(true, this.loadingOrigin.Self);
        this.service.copyRSSWA(this.rsswa.id, false).subscribe(data => {
          this.rsswa = data;
          // this.service.currentDocument = this.rsswa;
          this.sidePanel.loadRSSWAs();
          this.sidePanel.selectedRowIndex = this.rsswa.id;
          this.showAmendmentToggle = false;
          this.service.showAmendment = false;
          this.setLoading(false, this.loadingOrigin.Self);
        });
      }
    });
  }

  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 1000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Self:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Main:
          this.loadingMain = e as boolean;
          break;
      }
      console.log('m: ' + this.loadingMain + '   b: ' + this.loadingButtons + '   s: ' + this.loadingSide);
      this.loading = this.loadingMain || this.loadingButtons || this.loadingSide;
    }, 1);
  }

  // hasAdmin() {
  //   const user = this.getCurrentUser();
  //   const roles = user?.userRole?.map((x) => x.roleID) ?? [];
  //   return roles.includes(Roles.SA) || roles.includes(Roles.ADM);
  // }

  loadingSide!: boolean;
  loadingButtons!: boolean;
  loadingMain!: boolean;
  loadingOrigin = LoadingOrigin;
}
