<button-signature
	[title]="title"
	[roleIds]="roleIds"
	[roleCodes]="roleCodes"
	[disabled]="disabled"
	[value]="value"
	[user]="user"
	[date]="date"
	[enabled]="enabled"
	[userNames]="userNames"
	[showApprove]="showApprove"
	[showUnapprove]="showUnapprove"
	[showDisapprove]="showDisapprove"
	(approve)="approveEvent($event)"
	(unapprove)="unapproveEvent($event)"
	(disapprove)="disapproveEvent($event)"
>
</button-signature>
<button
	mat-icon-button
	class="remove"
	aria-label="Duplicate Signature Box"
	*ngIf="!disabled && !user && code == 'DUP'"
	[disabled]="disabled"
	color="warn"
	(click)="removeDuplicateEvent()"
>
	<mat-icon>delete_outline</mat-icon>
</button>
<button
	mat-icon-button
	class="copy"
	aria-label="Duplicate Signature Box"
	*ngIf="!disabled"
	[disabled]="disabled"
	color="primary"
	(click)="duplicateEvent()"
>
	<mat-icon>content_copy</mat-icon>
</button>
