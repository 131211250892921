import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';


@Component({
  selector: 'app-fast-valve-sensor',
  templateUrl: './fast-valve-sensor.component.html',
  styleUrls: ['./fast-valve-sensor.component.scss']
})
export class FastValveSensorComponent extends BaseComponent implements OnInit {

  public fvsName?: string | null;
  public fvsKeText?: string | null;

  public isSaving!: boolean;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<FastValveSensorComponent>,
    @Inject(MAT_DIALOG_DATA) public resource: Resource,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.fvsName = this.resource.name;
    this.fvsKeText = this.resource.keText;
  }

  async add() {
    this.isSaving = true;
    if (await this.isValid()) {
      this.resource.name = this.fvsName;
      this.resource.keText = this.fvsKeText;
      this.dialogRef.close(this.resource);
    } else {
      this.isSaving = false;
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  async isValid() {
    if (!this.fvsName) {
      this.alert.message('fastValveDialog_Name_Required');
      return false;
    }
    if (!this.fvsKeText) {
      this.alert.message('fastValveDialog_KEText_Required');
      return false;
    }
    return true;
  }

}
