import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import * as DocumentTypeActions from './document-type.action';
import { GeneralSignalRResponse } from "src/app/services/signal-r/general-signal-r-response";
import { SignalRService } from "src/app/services/signal-r/signal-r.service";
import { SignalRAction } from "src/app/services/signal-r/signalr-enums";
import { Channels } from "src/app/store/channels";


@Injectable({
  providedIn: 'root'
})
export class DocumentTypeStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore() {
    this.store.dispatch(new DocumentTypeActions.DocumentTypeRefresh());
  }

  initListeners() {
    // DocumentType
    this.signalRService.hubConnection?.on(Channels.DOCUMENTTYPES, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new DocumentTypeActions.DocumentTypeCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new DocumentTypeActions.DocumentTypeUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new DocumentTypeActions.DocumentTypeDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new DocumentTypeActions.DocumentTypeRefresh());
      }
    });
  }
}
