import { Component, Output, EventEmitter } from '@angular/core';
import { ClBaseComponent } from '../cl-base/cl-base.component';

@Component({
  selector: 'cl-multiple-textbox',
  templateUrl: './cl-multiple-textbox.component.html',
  styleUrls: ['./cl-multiple-textbox.component.scss']
})
export class ClMultipleTextboxComponent extends ClBaseComponent {

  @Output() data = new EventEmitter<any>();

  public visible: boolean = false;

  allowMultiple?: boolean;

  ngOnChanges() {
    this.initials = this.user ? this.user.initials : "";
    this.setValues();
    this.getConfiguration();
  }

  setValues() {
    this.valueObjects = this.getValueObjects(this.valueString);
    if (this.valueObjects) {
      this.options = this.valueObjects?.find(x => x.key == "values")?.val;
    }
  }

  getConfiguration() {
    if (this.configuration) {
      this.allowMultiple = JSON.parse(this.configuration).multiple;
    }
  }

  ngOnInit() {
  }

  getValues() {
    this.valueObjects = [];
    this.valueObjects.push({ key: "values", val: this.options });
    return this.valueObjects;
  }

  textChanged(o: any) {
    if (o.text)
      o.user = this.getCurrentUser(true);
    else o.user = null;
    this.saveValues(this.getValues());
  }


}
