import { State, Selector, Action, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { WorkerVerificationCreate, WorkerVerificationDelete, WorkerVerificationRefresh, WorkerVerificationUpdate } from './worker-verification.action';
import { WorkerVerification } from '../../../../services/worker-verification/worker-verification';
import { WorkerVerificationService } from 'src/app/services/worker-verification/worker-verification.service';

export class WorkerVerificationStateModel {
  data: WorkerVerification[] = [];
}

@State<WorkerVerificationStateModel>({
  name: 'WorkerVerification',
  defaults: {
    data: []
  }
})
@Injectable()
export class WorkerVerificationState {
  constructor(
    private workerverificationService: WorkerVerificationService,
    private store: Store
  ) { }

  @Selector()
  static read(state: WorkerVerificationStateModel) {
    return state.data;
  }

  @Action(WorkerVerificationRefresh)
  refresh({ patchState }: StateContext<WorkerVerificationStateModel>) {
    this.workerverificationService.getAll().toPromise().then(data => {
      patchState({ data });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(WorkerVerificationCreate)
  create({ getState, patchState }: StateContext<WorkerVerificationStateModel>, { item }: WorkerVerificationCreate) {
    const state = getState();
    patchState({
      data: [...state.data, item]
    });
  }

  @Action(WorkerVerificationUpdate)
  update({ getState, patchState }: StateContext<WorkerVerificationStateModel>, { id, item }: WorkerVerificationUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(WorkerVerificationDelete)
  delete({ getState, patchState }: StateContext<WorkerVerificationStateModel>, { id }: WorkerVerificationDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
