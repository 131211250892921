import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RadInterlock } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-interlock';
import { RadLocationInterlockRelation } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location-interlock-relation';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RadInterlockService {

  public api: string = environment.urls.baseApiUrl + '/RadInterlock';

  constructor(
    private http: HttpClient
  ) { }

  GetRadInterlocks(): Observable<RadInterlock[]> {
    return this.http.get<RadInterlock[]>(this.api);
  }

  GetRadInterlockRelations(): Observable<RadLocationInterlockRelation[]> {
    return this.http.get<RadLocationInterlockRelation[]>(this.api + '/Relations');
  }

  GetRadInterlockById(id: number): Observable<RadInterlock> {
    return this.http.get<RadInterlock>(this.api + '/' + id);
  }

  CreateRadInterlock(radInterlock: RadInterlock): Observable<RadInterlock> {
    return this.http.post<RadInterlock>(this.api, radInterlock);
  }

  UpdateRadInterlock(id: number, radInterlock: RadInterlock): Observable<RadInterlock> {
    return this.http.put<RadInterlock>(this.api + '/' + id, radInterlock);
  }

  UpdateRadInterlocks(radInterlocks: RadInterlock[]): Observable<RadInterlock[]> {
    return this.http.patch<RadInterlock[]>(this.api, radInterlocks);
  }

  DeleteRadInterlock(id: number) {
    return this.http.delete(this.api + '/' + id);
  }
}
