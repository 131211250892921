import { ReviewActionItemType } from '../../reviews.models';

export class ReviewActionItemTypesRefresh {
  static readonly type = '[ReviewActionItemTypes] Refresh';
}

export class ReviewActionItemTypesCreate {
  static readonly type = '[ReviewActionItemTypes] Create';

  constructor(public item: ReviewActionItemType) { }
}

export class ReviewActionItemTypesUpdate {
  static readonly type = '[ReviewActionItemTypes] Update';

  constructor(public id: number, public item: ReviewActionItemType) { }
}

export class ReviewActionItemTypesDelete {
  static readonly type = '[ReviewActionItemTypes] Delete';
  constructor(public id: number) { }
}
