<div class="card-body p-0">
  <mat-tab-group #tabs animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <div
          class="d-flex align-items-center justify-content-center"
          [ngClass]="{
            hilite: modifyingCatalogs?.includes(catalog.Roles) && roleModified
          }"
        >
          <span
            [ngClass]="{
              'hilite-label':
                modifyingCatalogs?.includes(catalog.Roles) && !roleModified
            }"
          >
            Role Details
          </span>
        </div>
      </ng-template>
      <div
        class="p-2 roleModified"
        [ngClass]="{
          showRoleModified:
            roleModified && modifyingCatalogs?.includes(catalog.Roles)
        }"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-4">
              <mat-form-field
                [ngClass]="{
                  hilite:
                    role && roleModified && tmpRoleCode != originalRole?.code
                }"
              >
                <mat-label>Role</mat-label>
                <input
                  aria-label="code"
                  matInput
                  #roleCode
                  id="roleCode"
                  [(ngModel)]="tmpRoleCode"
                  (ngModelChange)="roleChanged($event)"
                  [disabled]="!(adding || modifying) || (isOnlyPTC ?? true)"
                  name="roleCode"
                />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field
                [ngClass]="{
                  hilite:
                    role && roleModified && tmpStatus != originalRole?.status
                }"
              >
                <mat-label>Status</mat-label>
                <mat-select
                  [(ngModel)]="tmpStatus"
                  *ngIf="!deleted"
                  (selectionChange)="changeStatusRole(role?.status)"
                  [disabled]="
                    !(adding || modifying) ||
                    disabledStatus ||
                    (isOnlyPTC ?? true)
                  "
                  name="status"
                  (ngModelChange)="role ? (role.status = $event) : null"
                >
                  <mat-option
                    *ngFor="let status of statusList"
                    [value]="status.id"
                  >
                    {{ status.name }}
                  </mat-option>
                </mat-select>
                <mat-select
                  [(ngModel)]="tmpStatus"
                  *ngIf="deleted"
                  disabled
                  name="status"
                >
                  <mat-option [value]="0"> Deleted </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-4 offset-1 toggles">
              <div class="slide-center">
                <mat-slide-toggle
                  [(ngModel)]="tmpPerformingWork"
                  name="performingWork"
                  [disabled]="!(adding || modifying) || (isOnlyPTC ?? true)"
                  (change)="setFormDirty()"
                  (ngModelChange)="role ? (role.performingWork = $event) : null"
                  [ngClass]="{
                    hilite:
                      role &&
                      roleModified &&
                      tmpPerformingWork != originalRole?.performingWork
                  }"
                  >Performs RSSWA Work</mat-slide-toggle
                >
              </div>
              <div class="slide-center">
                <mat-slide-toggle
                  [(ngModel)]="tmpTrainingRole"
                  name="trainingRole"
                  (change)="setFormDirty()"
                  [disabled]="!(adding || modifying) || (isOnlyPTC ?? true)"
                  (ngModelChange)="role ? (role.trainingRole = $event) : null"
                  [ngClass]="{
                    hilite:
                      role &&
                      roleModified &&
                      tmpTrainingRole != originalRole?.trainingRole
                  }"
                  >Training</mat-slide-toggle
                >
              </div>
            </div>

            <div class="col-12 col-sm-12">
              <mat-form-field
                [ngClass]="{
                  hilite: role && roleModified && tmpName != originalRole?.name
                }"
              >
                <mat-label>Description</mat-label>
                <input
                  aria-label="name"
                  matInput
                  [(ngModel)]="tmpName"
                  [disabled]="!(adding || modifying) || (isOnlyPTC ?? true)"
                  name="roleName"
                  (change)="setFormDirty()"
                  (ngModelChange)="role ? (role.name = $event) : null"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-12">
              <div class="textarea-with-counter">
                <mat-form-field
                  [ngClass]="{
                    hilite:
                      role &&
                      roleModified &&
                      tmpInformation != originalRole?.information
                  }"
                >
                  <mat-label>Info</mat-label>
                  <textarea
                    aria-label="information"
                    matInput
                    style="overflow: hidden"
                    name="information"
                    [disabled]="!(adding || modifying) || (isOnlyPTC ?? true)"
                    [(ngModel)]="tmpInformation"
                    (keydown)="keydown($event)"
                    (change)="setFormDirty()"
                    (ngModelChange)="role ? (role.information = $event) : null"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                  >
                  </textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <apl-category-autocomplete
                [placeholder]="'Category'"
                [disabled]="true"
                [aplCategoryID]="
                  role?.aplGroupRole?.aplGroup?.aplGroupMaster
                    ?.aplCategoryMaster?.aplCategory?.id
                "
                (aplCategoryIDChange)="aplCategoryIDChanged($event)"
                [hilite]="
                  role &&
                  roleModified &&
                  role.aplGroupRole?.aplGroup?.aplGroupMaster?.aplCategoryMaster
                    ?.aplCategory?.id !=
                    originalRole?.aplGroupRole?.aplGroup?.aplGroupMaster
                      ?.aplCategoryMaster?.aplCategory?.id
                "
              >
              </apl-category-autocomplete>
            </div>
            <div class="col-md-6 col-sm-12">
              <mat-form-field>
                <mat-label>Category Manager</mat-label>
                <input
                  aria-label="group"
                  matInput
                  type="text"
                  [value]="
                    getCategoryManagers(
                      role?.aplGroupRole?.aplGroup?.aplGroupMaster
                        ?.aplCategoryMaster?.aplCategory
                    )
                  "
                  [disabled]="true"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6 col-sm-12">
              <apl-group-autocomplete
                [placeholder]="'Group'"
                [disabled]="true"
                [aplGroupID]="role?.aplGroupRole?.aplGroup?.id"
                (aplGroupIDChange)="aplGroupIDChanged($event)"
                [hilite]="
                  role &&
                  roleModified &&
                  role.aplGroupRole?.aplGroup?.id !=
                    originalRole?.aplGroupRole?.aplGroup?.id
                "
              >
              </apl-group-autocomplete>
            </div>
            <div class="col-md-6 col-sm-12">
              <mat-form-field>
                <mat-label>Group Manager</mat-label>
                <input
                  aria-label="role"
                  matInput
                  type="text"
                  [value]="getGroupManagers(role?.aplGroupRole?.aplGroup)"
                  [disabled]="true"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6 col-sm-12">
              <mat-form-field>
                <mat-label>Procedure Training Coordinator</mat-label>
                <input
                  aria-label="x"
                  matInput
                  type="text"
                  [value]="
                    getProcedureCenterCoordinators(role?.aplGroupRole?.aplGroup)
                  "
                  [disabled]="true"
                />
              </mat-form-field>
            </div>
            <div class="col-12">
              <button
                mat-flat-button
                color="warn"
                *ngIf="role && modifying"
                [disabled]="
                  dataSourceRoleUsers.data.length || role.status !== 0
                "
                (click)="deleteRole()"
              >
                <mat-icon>delete</mat-icon> Delete Role
              </button>
              <p
                *ngIf="
                  role &&
                  modifying &&
                  (dataSourceRoleUsers?.data?.length !== 0 ||
                    role?.status !== 0) &&
                  !isOnlyPTC
                "
              >
                You must set the role to inactive and delete all the role users
                to delete the role.
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div
          class="d-flex align-items-center justify-content-center"
          [ngClass]="{
            hilite: modifyingCatalogs?.includes(catalog.Users) && roleModified
          }"
        >
          <span
            [ngClass]="{
              'hilite-label':
                modifyingCatalogs?.includes(catalog.Users) && !roleModified
            }"
            >Users</span
          >
        </div>
      </ng-template>
      <div
        class="container-fluid mt-2 roleModified"
        [ngClass]="{
          showRoleModified:
            roleModified && modifyingCatalogs?.includes(catalog.Users)
        }"
      >
        <div class="mat-container scrollbar users">
          <mat-table
            #table
            class="mat-elevation-z8"
            [dataSource]="dataSourceRoleUsers"
            cdkDropList
            #usersListTable
            #usersList="cdkDropList"
            [cdkDropListData]="dataSourceRoleUsers.data"
            (cdkDropListDropped)="drop($event)"
            id="usersListTable"
          >
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><mat-icon *ngIf="dragEnabled && !isOnlyPTC">reorder</mat-icon>
                {{ element.user?.name }}</mat-cell
              >
            </ng-container>
            <ng-container matColumnDef="notes">
              <mat-header-cell *matHeaderCellDef> Notes </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <label *ngFor="let note of element?.notes">
                  {{ note?.description }}
                </label>
              </mat-cell>
            </ng-container>
            <ng-container
              *ngIf="modifying && !isOnlyPTC"
              matColumnDef="noteActions"
            >
              <mat-header-cell *matHeaderCellDef>
                Note actions
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-list>
                  <mat-list-item
                    style="font-size: 12px"
                    *ngFor="let note of element?.notes"
                  >
                    <mat-icon
                      *ngIf="modifying"
                      color="primary"
                      (click)="openNoteEditor(element, note)"
                      >edit</mat-icon
                    >
                    <mat-icon
                      *ngIf="modifying"
                      color="warn"
                      (click)="deleteNote(element, note)"
                      >delete</mat-icon
                    >
                    <mat-divider
                      *ngIf="element?.notes?.length > 1"
                    ></mat-divider>
                  </mat-list-item>
                </mat-list>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="add">
              <mat-header-cell class="mat-column-opt" *matHeaderCellDef>
                <mat-icon
                  mat-list-icon
                  *ngIf="role?.status"
                  class="add fakeA"
                  color="accent"
                  (click)="openAddRoleUser()"
                  >add</mat-icon
                >
                <mat-icon
                  mat-list-icon
                  *ngIf="!role?.status"
                  class="add fakeA colorDisabled"
                  matTooltip="You can not add users to an inactive role"
                  >add</mat-icon
                >
              </mat-header-cell>
              <mat-cell class="mat-column-opt" *matCellDef="let element">
                <div class="cell-container w-100">
                  <button
                    mat-icon-button
                    *ngIf="modifying"
                    [matMenuTriggerFor]="menu"
                    aria-label="Schedules menu"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      *ngIf="element?.notes?.length == 0"
                      (click)="openNoteEditor(element)"
                    >
                      <mat-icon>edit</mat-icon>
                      <span>Add note</span>
                    </button>
                    <button
                      mat-menu-item
                      (click)="deleteRoleUser(element)"
                      color="warn"
                    >
                      <mat-icon>delete</mat-icon>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </div>
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedColumnsRoleUsers; sticky: true"
            ></mat-header-row>
            <mat-row
              [cdkDragDisabled]="!dragEnabled"
              *matRowDef="let row; columns: displayedColumnsRoleUsers"
              cdkDrag
            ></mat-row>
          </mat-table>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div
          class="d-flex align-items-center justify-content-center"
          [ngClass]="{
            hilite:
              modifyingCatalogs?.includes(catalog.RolePrivileges) &&
              roleModified
          }"
        >
          <span
            [ngClass]="{
              'hilite-label':
                modifyingCatalogs?.includes(catalog.RolePrivileges) &&
                !roleModified
            }"
            >Privileges</span
          >
        </div>
      </ng-template>
      <div
        class="roleModified p-1"
        [ngClass]="{
          showRoleModified:
            roleModified && modifyingCatalogs?.includes(catalog.RolePrivileges)
        }"
      >
        <div class="container-fluid">
          <role-privilege
            [role]="role"
            [showChanges]="roleModified"
            [modifying]="modifying"
            (rolePrivilegesPending)="setRolePrivilegesPending($event)"
          ></role-privilege>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="role?.trainingRole">
      <ng-template mat-tab-label>
        <div
          class="d-flex align-items-center justify-content-center"
          [ngClass]="{
            hilite:
              modifyingCatalogs?.includes(catalog.Trainings) && roleModified
          }"
        >
          <span
            [ngClass]="{
              'hilite-label':
                modifyingCatalogs?.includes(catalog.Trainings) && !roleModified
            }"
            >{{ getMessage("ALSPC_RolesCatalog_Training").description }}</span
          >
        </div>
      </ng-template>
      <div
        class="container-fluid mt-2 roleModified"
        [ngClass]="{
          showRoleModified:
            roleModified && modifyingCatalogs?.includes(catalog.Trainings)
        }"
      >
        <div
          class="mat-container scrollbar"
          [ngClass]="{
            'mat-container-training': modifying
          }"
        >
          <mat-table [dataSource]="procedureTrainingsDS" class="mb-2">
            <ng-container matColumnDef="procedure">
              <mat-header-cell *matHeaderCellDef>
                <span>{{
                  getMessage("ALSPC_RolesCatalog_Procedure").description
                }}</span>
              </mat-header-cell>
              <mat-cell *matCellDef="let e" class="px-2">
                <span
                  [ngClass]="{
                    inactive: e?.procedureMaster?.procedure?.status == 0
                  }"
                  >{{
                    e?.procedureMaster?.procedure?.procedureNumber +
                      " - " +
                      e?.procedureMaster?.procedure?.title
                  }}</span
                >
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="obligation">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{
                    getMessage("ALSPC_RolesCatalog_Obligation").description
                  }}</span
                >
              </mat-header-cell>
              <mat-cell *matCellDef="let e" class="px-2">
                <mat-select
                  matNativeControl
                  name="obligationType"
                  (selectionChange)="updateTraining()"
                  [(ngModel)]="e.obligationType"
                  [disabled]="!(adding || modifying)"
                >
                  <mat-option [value]="1">
                    <span>Recommended</span>
                  </mat-option>
                  <mat-option [value]="2">
                    <span>Required - Read Only</span>
                  </mat-option>
                  <mat-option [value]="3">
                    <span>Required - Follow Up</span>
                  </mat-option>
                </mat-select>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="delete">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let e" class="px-2">
                <button
                  mat-icon-button
                  color="warn"
                  (click)="deleteTraining(e)"
                  [disabled]="!(adding || modifying)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="trainingColumns; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: trainingColumns"
              class="p-0"
            ></mat-row>
          </mat-table>
        </div>
        <procedure-autocomplete
          *ngIf="tabs.selectedIndex == 3 && (adding || modifying)"
          #procedureAutocomplete
          [placeholder]="
            getMessage('ALSPC_RolesCatalog_AddProcedure').description
          "
          [disabled]="false"
          [selectedProcedures]="selectedProcedures"
          (select)="createTraining($event)"
        >
        </procedure-autocomplete>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="sidenav-content-buttons">
  <div class="row">
    <div class="col-6" *ngIf="showCancelButton">
      <button
        *ngIf="!showRoleModified && !showCancelPending && (modifying || adding)"
        mat-flat-button
        color="warn"
        (click)="discardChanges()"
        style="width: 100px"
      >
        Cancel
      </button>
      <button
        *ngIf="showCancelPending"
        mat-flat-button
        color="warn"
        (click)="cancelPendingChanges()"
        style="width: 100px"
      >
        Cancel
      </button>
    </div>
    <div class="col-6">
      <button
        mat-flat-button
        color="accent"
        class="right-btn"
        [disabled]="
          !modifyAvailable ||
          isModifiying ||
          (tabs.selectedIndex == 2 && !isSA())
        "
        *ngIf="role?.id && !modifying && this.getCurrentUser()"
        (click)="modifyRole()"
        style="width: 100px"
      >
        Modify
      </button>
      <button
        mat-flat-button
        color="primary"
        class="right-btn"
        *ngIf="modifying || adding"
        [disabled]="disabledTimer"
        (click)="saveRole()"
      >
        Save
      </button>
      <button
        mat-flat-button
        color="primary"
        class="right-btn styleWidth"
        *ngIf="showApproveUnapprove && !isOnlyPTC"
        [disabled]="disabledTimer"
        (click)="approve()"
      >
        Approve
      </button>
      <button
        mat-flat-button
        color="warn"
        class="right-btn styleWidth"
        *ngIf="showApproveUnapprove && !isOnlyPTC"
        (click)="openAddDisapprovalDialog()"
      >
        Disapprove
      </button>
    </div>
  </div>
</div>
