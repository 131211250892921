import { Component, OnInit, Inject, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { RegisterComponent } from 'src/app/common/extensions/decorator';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { SiteConfiguration } from 'src/app/services/site-configuration/site-configuration';
import { SiteConfigurationService } from 'src/app/services/site-configuration/site-configuration-service';

@RegisterComponent('SiteConfigurationComponent')
@Component({
  selector: 'app-site-configuration',
  templateUrl: './site-configuration.component.html',
  styleUrls: ['./site-configuration.component.scss']
})
export class SiteConfigurationComponent extends BaseComponent implements OnInit {

  static key = 'SiteConfigurationComponent';
  loading = false;
  siteConf!: SiteConfiguration;
  index = 0;

  isEmailSCFActive = new FormControl();
  isSchedulesReminderActive = new FormControl();

  constructor(
    protected override injector: Injector,
    public siteConfService: SiteConfigurationService,
    public dialogRef: MatDialogRef<SiteConfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(injector);
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      secondsUntilLiberateSCF: [null, Validators.required],
      secondsUntilMessageSCF: [null, Validators.required],
      secondsUntilLiberateRSSWA: [null, Validators.required],
      secondsUntilMessageRSSWA: [null, Validators.required],
      secondsUntilLiberatePPSTB: [null, Validators.required],
      secondsUntilMessagePPSTB: [null, Validators.required],
      secondsUntilLiberateEB: [null, Validators.required],
      secondsUntilMessageEB: [null, Validators.required],
      isEmailSCFActive: [null, Validators.required],
      defaultEmails: [null, Validators.pattern(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4}(,\s)?)+$/)],
      ologUser: [null, Validators.required],
      ologPassword: [null],
      isSchedulesReminderActive: [null, Validators.required]
    });

    this.loading = true;
    this.siteConfService.getFirst().toPromise().then(response => {
      if (response) {
        this.loading = false;
        const scfResult = response.secondsUntilLiberateSCF * (1 / 60);
        const scfMResult = response.secondsUntilMessageSCF * (1 / 60);
        const rsswaResult = response.secondsUntilLiberateRSSWA * (1 / 60);
        const rsswaMResult = response.secondsUntilMessageRSSWA * (1 / 60);
        const ppstbResult = response.secondsUntilLiberatePPSTB * (1 / 60);
        const ppstbMResult = response.secondsUntilMessagePPSTB * (1 / 1000);
        const ebResult = response.secondsUntilLiberateEB * (1 / 60);
        const ebMResult = response.secondsUntilMessageEB * (1 / 1000);
        response.defaultEmails = response.defaultEmails.split(',').join(', ');
        this.formGroup = this.formBuilder.group({
          secondsUntilLiberateSCF: [scfResult, Validators.required],
          secondsUntilMessageSCF: [scfMResult, Validators.required],
          secondsUntilLiberateRSSWA: [rsswaResult, Validators.required],
          secondsUntilMessageRSSWA: [rsswaMResult, Validators.required],
          secondsUntilLiberatePPSTB: [ppstbResult, Validators.required],
          secondsUntilMessagePPSTB: [ppstbMResult, Validators.required],
          secondsUntilLiberateEB: [ebResult, Validators.required],
          secondsUntilMessageEB: [ebMResult, Validators.required],
          isEmailSCFActive: [response.isEmailSCFActive, Validators.required],
          defaultEmails: [response.defaultEmails, Validators.pattern(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4}(,\s)?)+$/)],
          ologUser: [response.ologUser, Validators.required],
          ologPassword: [''],
          isSchedulesReminderActive: [response.isSchedulesReminderActive, Validators.required]
        });
        this.siteConf = response;
        if (this.isSA()) {
          this.formGroup.enable();
        }
        else {
          this.formGroup.disable();
        }
      }
    }).catch(() => {
      this.alert.defaultError();
    });
  }

  onNoClick(submit?: boolean): void {
    this.dialogRef.close(submit);
  }

  onSubmit() {
    const siteconf = this.formGroup.value as SiteConfiguration;
    siteconf.secondsUntilLiberateRSSWA *= 60;
    siteconf.secondsUntilLiberateSCF *= 60;
    siteconf.secondsUntilLiberatePPSTB *= 60;
    siteconf.secondsUntilMessageRSSWA *= 60;
    siteconf.secondsUntilMessageSCF *= 60;
    siteconf.secondsUntilMessagePPSTB *= 1000;
    siteconf.secondsUntilMessageEB *= 1000;
    siteconf.secondsUntilLiberateEB *= 60;
    siteconf.defaultEmails = siteconf.defaultEmails.replace(' ', '');
    this.loading = true;
    this.siteConfService.update(siteconf).toPromise().then(() => {
      this.loading = false;
      this.alert.message('updates', [new MessagePlaceholder('{what}', 'Site Configuration')]);
      this.dialogRef.close();
    }).catch(() => {
      this.loading = false;
      this.alert.defaultError();
    });
  }
}
