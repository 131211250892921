<app-document-type-tab
  [documentType]="documentType"
  [isDisabled]="isDisabled"
  (loading)="loading.emit($event)"
  (sendDocumentTemplateId)="setDocumentTemplateId($event)"
  (changed)="documentTypeChanged($event)"
>
</app-document-type-tab>

<app-template-type-tab
  [documentType]="documentType"
  [isDisabled]="isDisabled || !documentType?.id"
  (changed)="templateTypesChanged($event)"
>
</app-template-type-tab>
<app-olog-category-tab
  [documentType]="documentType"
  [isDisabled]="isDisabled || !documentType?.id"
  (changed)="ologCategoriesChanged($event)"
>
</app-olog-category-tab>
<app-olog-email-tab
  [documentType]="documentType"
  [isDisabled]="isDisabled || !documentType?.id"
  (changed)="ologEmailsChanged($event)"
>
</app-olog-email-tab>

<div class="sidenav-content-buttons" *ngIf="documentType">
  <div class="row">
    <div class="col-6">
      <button
        mat-stroked-button
        color="warn"
        *ngIf="
          !isModifyAvailable && hasPrivilege(privilegeEnum.ModifyDocumentType)
        "
        (click)="cancel()"
      >
        Cancel
      </button>
      <button
        mat-stroked-button
        color="warn"
        *ngIf="
          isModifyAvailable && hasPrivilege(privilegeEnum.ModifyDocumentType)
        "
        [disabled]="templateTypeTab?.hasChecklistTemplates"
        (click)="delete()"
      >
        Delete
      </button>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <button
        mat-flat-button
        color="accent"
        *ngIf="
          isModifyAvailable && hasPrivilege(privilegeEnum.ModifyDocumentType)
        "
        (click)="modify()"
      >
        Modify
      </button>
      <button
        mat-flat-button
        color="primary"
        *ngIf="
          documentType &&
          hasPrivilege(privilegeEnum.ModifyDocumentType) &&
          !isModifyAvailable
        "
        (click)="save()"
        [disabled]="!isSaveAvailable && !isformDirty()"
      >
        Save
      </button>
    </div>
  </div>
</div>
