<div>
  <div class="white-layer"></div>
  <div class="row no-gutters mt-2">
    <div class="col-12 col-lg-4 p-1">
      <app-beam-status
        (sendId)="beamlineOverviewDetails?.loadBeamlineOverview($event)"
      ></app-beam-status>
    </div>
    <div class="col-12 col-lg-8 p-1">
      <app-beamline-overview-details></app-beamline-overview-details>
    </div>
  </div>
</div>
