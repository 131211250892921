<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-2">
        <h6>{{ label }}</h6>
      </div>
      <div class="col-11 pt-2">
        <mat-label
          [innerHTML]="text ?? '' | placeholderHighlight : editor"
        ></mat-label>
      </div>
    </div>
  </div>
</div>
