import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Placeholder } from '../../checklists';
import { Clipboard } from '@angular/cdk/clipboard';
import { CatalogService } from 'src/app/components/catalogs/catalog-service';
import { BeamlineShutterRelation, ResourceSummary, Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PlaceholdersService } from 'src/app/services/placeholders/placeholders.service';

@Component({
  selector: 'app-checklist-builder-placeholders',
  templateUrl: './checklist-builder-placeholders.component.html',
  styleUrls: ['./checklist-builder-placeholders.component.scss']
})
export class ChecklistBuilderPlaceholdersComponent extends BaseComponent implements OnInit {

  placeholders?: Placeholder[];
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  public displayedColumns: string[] = ['Name', 'Placeholder', 'Data'];
  public dataSource = new MatTableDataSource<Placeholder>();
  selectedRowIndex!: number;

  beamlineShutter!: BeamlineShutterRelation;
  resourceSummary!: ResourceSummary;
  beamline!: Resource;
  shutter!: Resource;

  resource: Resource;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<ChecklistBuilderPlaceholdersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: PlaceholdersService,
    private clipboard: Clipboard,
    public catalogService: CatalogService,
  ) {
    super(injector);
    this.resource = data.resource;
  }

  async ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.service.setKeyValuePairs(this.resource, true).then(data => {
      this.load();
    });
  }

  load() {
    this.service.getAll().toPromise().then(data => {
      this.placeholders = data;
      this.placeholders?.map(pl => {
        pl.data = this.service.getValue(pl.placeholderString);
      });
      this.dataSource = new MatTableDataSource(this.placeholders);
    });
  }

  close() {
    this.dialogRef.close(this.data);
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex(r => {
      return r.id == row.id;
    });
    return i;
  }

  rowClicked(data: any) {
    this.clipboard.copy(data.placeholderString);
    this.dialogRef.close(data.placeholderString);
  }
}
