<mat-accordion class="px-2" [multi]="true">
  <!--Original body-->
  <mat-expansion-panel *ngIf="generalLogs" class="mx-2 mb-1 mt-0">
    <mat-expansion-panel-header>
      <mat-panel-title> Original Form </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="original-wrapper" #original>
      <eb-main-s1
        *ngIf="formType == formTypeEnum.EBF"
        [form]="generalLogs.originalBodyEB2!"
        [showAmendment]="false"
        [disabled]="true"
      ></eb-main-s1>
      <ppstb-main-s1
        *ngIf="formType == formTypeEnum.PPSTB"
        [form]="generalLogs.originalBodyPPSTB!"
        [showAmendment]="false"
        [disabled]="true"
      ></ppstb-main-s1>
    </div>
  </mat-expansion-panel>

  <!-- Updates -->
  <div *ngIf="showUpdates">
    <mat-expansion-panel
      #panel
      *ngFor="let update of generalLogs?.updates"
      class="card mx-2"
    >
      <mat-expansion-panel-header class="card-header">
        <mat-panel-title>
          <h6 class="ml-2 mb-0">
            <span [innerHTML]="update.header"></span>

            <span class="date small ml-3">{{
              update?.amendmentDate | date : "medium"
            }}</span>
          </h6>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="card-body relative" #parent>
        <!-- <div class="amendment-slide" [ngStyle]="getTopPosition(parent)">
          <mat-slide-toggle
            *ngIf="update.status"
            [(ngModel)]="update.status.isViewingAmendment"
            class="float-right d-print-none mr-3 pt-1"
            (ngModelChange)="s1.refresh()"
            name="amendment"
            color="accent"
            >Show Amendment</mat-slide-toggle
          >
        </div> -->

        <div
          class="original-wrapper"
          [ngClass]="{
            'amendment-wrapper': update.status?.isViewingAmendment
          }"
        >
          <eb-main-s1
            #s1
            *ngIf="formType == formTypeEnum.EBF"
            [form]="update.form!"
            [showAmendment]="true"
          ></eb-main-s1>

          <ppstb-main-s1
            *ngIf="formType == formTypeEnum.PPSTB"
            [form]="update.form!"
            [showAmendment]="true"
          >
          </ppstb-main-s1>

          <div class="card mt-3">
            <div class="card-header">
              <h6>Signatures</h6>
            </div>
            <div class="card-body px-2">
              <div class="d-flex justify-centent-between">
                <div
                  class="log-signature full-height full-width mx-1"
                  *ngFor="let s of update?.signs"
                >
                  <master-control
                    [type]="1"
                    [visible]="true"
                    [roleCodes]="s.roleName"
                    [approvedBy]="getUserSign(s.signedBy)"
                    [checkbox_checked]="true"
                    [header]="s.name"
                    [disabled]="true"
                    [enabled]="true"
                    [approved]="true"
                    [showApprove]="false"
                    [showUnapprove]="false"
                    [showDisapprove]="false"
                    [approvedOn]="s.signedOn"
                  >
                  </master-control>
                </div>
                <div *ngIf="update?.signs?.length == 0">
                  <h6>There are not signatures</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</mat-accordion>
