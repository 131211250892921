<div class="card">
  <div class="card-header">
    <span class="card-title">Complete Schedule</span>
  </div>
  <div class="card-body">
    <form [formGroup]="formComplete">
      <div class="row">
        <label class="center" *ngIf="data != null"> {{ data.name }} </label>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Complete Date</mat-label>
            <input
              matInput
              [matDatepicker]="startDate"
              placeholder="Complete Date"
              name="Completed Date"
              formControlName="date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="startDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
            <!-- <mat-error *ngIf = "this.formComplete.controls.date.hasError('required')" >it must be a valid date</mat-error> -->
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="!formComplete.valid"
      >
        Accept
      </button>
    </div>
  </div>
</div>
