import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
	selector: '[stripSpaces]'
})
export class StripSpacesDirective {
	constructor(private el: ElementRef) { }

	@HostListener('input', ['$event.target.value'])
	onInput(value: string): void {
		const strippedValue = value.replace(/\s+/g, '');
		this.el.nativeElement.value = strippedValue;
	}
}
