import { Injectable, Injector } from '@angular/core';
import { UserScheduleReminder } from '../models/user-schedule-reminder';
import { BaseService } from 'src/app/common/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class UserReminderService extends BaseService<UserScheduleReminder, number> {

  constructor(
    protected override injector: Injector,
  ) {
    super(injector, '/user_reminder');
  }

  readByReminder(firstId: number) {
    const url = this.endpoint + '/by_reminder/' + firstId;
    return this.http.get<UserScheduleReminder[]>(url);
  }
}
