import { ChecklistExecStatus, TemplateRolePermission, TemplateType } from "src/app/components/checklists/checklists";
import { Location } from "../../components/catalogs/beamline-catalog/resource/resources";
import { OlogCategory } from "src/app/components/catalogs/document-type/services/olog-category";
import { OlogEmail } from "src/app/components/catalogs/document-type/services/olog-email";
import { FormStatus } from "../forms/form-status";
import { StatusType } from "src/app/common/enumerations/enumerations";

// export interface IDocument {
//   id: number;
//   name: string;
//   description: string;
//   status: number;
//   statusName?: string;
//   type: number;
//   code: string;
//   createdOn: Date;
//   createdBy: number;
//   locations: docLocations[];
// }

export interface Document {
  id: number;
  formID?: number;
  serialNo?: string;
  description?: string;
  type?: number | null;
  status?: number;
  statusName?: string;
  locationIDs?: string;
  locationNames?: string;
  code?: string;
  createdOn?: Date;
  createdBy?: number;
  createdByName?: string;
  requesterID?: number;
  requesterName?: string;
  updatedOn?: Date | null;
  updatedBy?: number | null;
  gen1?: string;
  documentType?: DocumentType;
  locations?: Location[];
  cssClass?: string;
  statusType?: StatusType;

  category?: DocumentCategory;
  formStatus?: FormStatus;
  disableLink?: boolean;
}

export interface RelatedDocument {
  id?: number;
  originalDocumentId?: number;
  originalDocumentTypeId?: number;
  relatedDocumentId?: number;
  relatedDocumentStatus?: number;
  relatedDocumentTypeId?: number;
  relatedDocumentSerialNo?: string;

  cssClass?: string;
  disabled?: boolean; // used in SCF
}

export class docLocations {
  id!: number;
  name!: string;
  type!: number;
}

export interface DocumentType {
  id: number;
  name?: string;
  description?: string;
  status?: number;
  type?: number;
  code?: string;
  createdOn?: Date;
  createdBy?: number;
  updatedOn?: Date;
  updatedBy?: number;
  documentTemplateId?: number;
  showInCatalog?: boolean;
  checklistExecStatuses?: ChecklistExecStatus[];
  templateRolePermissions?: TemplateRolePermission[];

  documents?: Document[];
  ologCategories?: OlogCategory[];
  ologEmails?: OlogEmail[];
  templateTypes?: TemplateType[];
  order?: number;
  visible?: boolean;
}


export enum DocumentCategory {
  Form = 1,
  Checklist = 2,
  Review = 3,
  ChecklistTemplate = 4,
  ABSITemplate = 5,
  KETemplate = 6
}
