import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges, OnDestroy, Injector } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { environment } from 'src/environments/environment';
import { TextareaDialogComponent } from '../../textarea-dialog/textarea-dialog.component';
import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'question-signature',
  templateUrl: './question-signature.component.html',
  styleUrls: ['./question-signature.component.scss']
})
export class QuestionSignatureComponent extends ClBaseComponent implements OnDestroy, OnInit, OnChanges {
  @Input() privilegeToApproveAnswer?: PrivilegeEnum | null = null;
  @Input() answerChangedById?: number | null = null;
  @Input() answerChangedBy?: User | null = null;
  @Input() approved?: boolean;
  @Input() answer?: boolean;

  @Input() RolesToApproveChanges?: string[];
  @Input() questionDisabled?: boolean;
  @Input() followUpChanged?: boolean;

  @Output() approveAnswer = new EventEmitter<any>();
  @Output() disapproveAnswer = new EventEmitter<any>();

  public role?: string;
  public name?: string | null;

  public visible?: boolean | null = false;

  public loading = false;
  public questionSanitized!: SafeHtml;
  CanApproveAnswer?: boolean | 0 | null = false;
  constructor(
    private sanitizer: DomSanitizer,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.role = this.roleCodes;
    this.setCanAnswer();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refresh();
  }

  refresh() {
    this.checked = this.answer;
    this.loading = false;
    this.questionSanitized = this.sanitizer.bypassSecurityTrustHtml(this.question ?? '');
    if (!this.approved) {
      this.label = 'Approve';
      this.name = null;
      this.date = null;
      this.color = 'primary';
      this.visible = this.showApprove != undefined ? this.showApprove : true;
    } else {
      this.label = 'Unapprove';
      this.color = 'accent';
      this.visible = this.showUnapprove;
      this.name = this.getCurrentUser()?.name;
    }
    this.setCanAnswer();
  }

  setCanAnswer() {
    if (this.answerChangedById) {
      this.questionDisabled = true;
    }
    if (this.privilegeToApproveAnswer) {
      this.CanApproveAnswer = this.answerChangedById && this.answerChangedById !== this.getCurrentUser()?.id && this.hasPrivilege(this.privilegeToApproveAnswer);
    } else {
      this.CanApproveAnswer = false;
    }
  }

  checkboxChanged() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: environment.messages.answer_message
      }
    });
    confirm.afterClosed().subscribe(data => {
      if (data) {
        this.loading = true;
        this.answered.emit(this.checked);
      } else {
        this.checked = !this.checked;
      }
    });
  }

  click() {
    if (!this.approved) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message: this.getMessage('approve_message')?.description
        }
      });

      confirm.afterClosed().subscribe(data => {
        if (data) {
          this.name = this.getCurrentUser()?.name;
          this.date = new Date();

          this.approve.emit({
            value: !this.approved,
            user: !this.approved ? this.getCurrentUser()?.id : 0,
            date: this.date,
          });
        }
      });
    } else {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message: this.getMessage('unapprove_message')?.description
        }
      });
      confirm.afterClosed().subscribe(data => {
        if (data) {
          this.name = null;
          this.date = null;
          this.unapprove.emit({
            value: !this.approved,
            user: !this.approved ? this.getCurrentUser()?.id : 0,
            date: this.date
          });
        }
      });
    }
  }

  apprAns() {
    this.loading = true;
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: environment.messages.approve_message
      }
    });
    confirm.afterClosed().subscribe(data => {
      if (data) {
        this.approveAnswer.emit(!this.checked);
      }
    });
  }

  disapAns() {
    this.loading = true;
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: environment.messages.disapprove_message_no_reason
      }
    });
    confirm.afterClosed().subscribe(data => {
      if (data) {
        this.disapproveAnswer.emit();
      }
    });
  }

  disap() {
    const confirm = this.dialog.open(TextareaDialogComponent, {
      width: '400px',
      data: {
        label: 'Dissaprove reason',
        text: ''
      }
    });
    confirm.afterClosed().subscribe(data => {
      if (data.acceptClicked) {
        this.name = null;
        this.date = null;

        this.disapprove.emit({
          value: !this.approved,
          user: !this.approved ? this.getCurrentUser()?.id : 0,
          date: this.date,
          text: data.text
        });
      }
    });
  }

  undo() {
    this.disapproveAnswer.emit({
      value: !this.approved,
      user: 0,
      date: this.date,
      text: ''
    });
  }
}
