import { Component, ViewChildren, QueryList, OnDestroy, Input, Injector, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { RSSWAResource, SaveRSSWASection5Resource } from '../../rsswa';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { DataButtonSignature } from 'src/app/common/models/data-button-signature';
import { ButtonSignatureComponent } from 'src/app/controls/checklist-components/button-signature/button-signature.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { WfTaskLocalResource, WfTaskResource, WfSignature, WfSignatureResource, WFTaskSignatureResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';

@Component({
  selector: 'rsswa-section5',
  templateUrl: './rsswa-section5.component.html',
  styleUrls: ['./rsswa-section5.component.scss']
})
export class RsswaSection5Component extends BaseComponent implements OnDestroy, OnChanges {

  @Input() rsswa?: RSSWAResource | null;
  @Output() loading = new EventEmitter<boolean>();

  rsswaS5!: SaveRSSWASection5Resource;

  serialNo!: string;
  status!: string;

  // Workflow
  @ViewChildren('btnSign')
  signatureComponents!: QueryList<ButtonSignatureComponent>;
  workFlow!: WfTaskLocalResource[];
  workFlowPending!: WfTaskResource[];
  signatures!: WfSignature[];
  wfSignatures!: WfSignatureResource[];
  wfTaskSignature: WFTaskSignatureResource = new WFTaskSignatureResource();
  wfSignCount!: number;
  approvals!: number;
  disabled!: boolean;

  // Form
  public form!: FormGroup;

  constructor(
    private _wf: WorkFlowService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  buildForm() {
    this.form = new FormGroup({});
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  clear() {
    this.wfSignatures = [];
    this.signatures = [];
    this.buildForm();
    this.form.reset();
    this.form.disable();
  }

  refresh() {
    this.disabled = this.rsswa?.status !== FormStatusEnum.Active;

    if (this.rsswa) {
      this.wfSignCount = 0;
      this.wfSignatures = this.rsswa.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 5)?.wfSignatures ?? [];

      this.approvals = this.wfSignatures.length;
      this.signatures = [];
      this.wfSignatures.map(t => {

        const taskEnabled = (t.approveEnabled || t.unapproveEnabled) && this.rssFunctionalTestsFinished() && t.status == this.rsswa?.status;

        const signature: WfSignature = {
          id: t.id,
          approveId: t.approveID,
          unapproveId: t.unapproveID,
          disapproveId: t.disapproveID,
          amendmentId: t.amendmentID,
          name: t.titleBefore,
          roles: t.roles.map(r => r.id),
          roleCodes: t.roles.map(r => r.code).join('/'),
          disabled: this.disabled || !taskEnabled,
          approvedBy: t.signedBy,
          approved: t.signed,
          approvedOn: t.signedOn,
          approve: t.approveEnabled,
          unapprove: t.unapproveEnabled,
          disapprove: t.disapproveEnabled,
          amendment: t.amendmentEnabled,
          required: t.required,
          taskId: t.taskID,
          value: t.value,
          cancelID: t.cancelID,
          cancel: t.cancelEnabled,
          enabled: t.enabled
        };
        this.wfSignCount = this.signCount();
        if (this.signatures.filter(x => x.id === signature.id).length <= 0) {
          this.signatures.push(signature);
        }
      });
    }
  }

  async approve(taskId?: number, actionId?: number) {
    let status = this.rsswa?.status;
    if (this.signCount() + 1 === this.approvals) {   //  Active => WorkComplete
      status = FormStatusEnum.WorkCompleted;
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: '500px',
        data: {
          message: this.getMessage('toWorkCompleteWarning')?.description,
          icon: 'warn'
        }
      });

      confirm.afterClosed().toPromise().then(async data => {
        if (data) {
          this.wfTaskSignature.taskId = taskId;
          this.wfTaskSignature.actionId = actionId;
          this.loading.emit(true);
          await this._wf.sign(this.wfTaskSignature).toPromise();
        }
        else {
          this.refresh();
        }
      });
    }
    else {
      this.wfTaskSignature.taskId = taskId;
      this.wfTaskSignature.actionId = actionId;
      this.loading.emit(true);
      await this._wf.sign(this.wfTaskSignature).toPromise();
    }
  }

  async unapprove(taskId?: number, actionId?: number) {
    this.wfTaskSignature.taskId = taskId;
    this.wfTaskSignature.actionId = actionId;
    this.loading.emit(true);
    await this._wf.sign(this.wfTaskSignature).toPromise();
  }

  disapprove(taskID?: number, actionID?: number, data?: DataButtonSignature) {
    this.wfTaskSignature.taskId = taskID;
    this.wfTaskSignature.actionId = actionID;
    this.wfTaskSignature.reason = data?.text;
    this.loading.emit(true);
    this._wf.sign(this.wfTaskSignature).toPromise();
  }

  signCount() {
    let count = 0;
    if (this.wfSignatures) {
      this.wfSignatures.map(t => {
        if (t.signedBy != null) { count++; }
      });
    }
    return count;
  }

  rssFunctionalTestsFinished(): boolean {
    return !this.rsswa?.rssFunctionalTests?.filter(x => x.checklist?.status != 5 && x.checklist?.status != -1)?.length;
  }
}
