import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { Placeholder } from 'src/app/components/checklists/checklists';
import { Resource, ResourceUserRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { UserRole } from 'src/app/services/user-roles/user-role';
import { UserRoleService } from 'src/app/services/user-roles/user-role.service';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';

@Component({
  selector: 'app-resource-user-relation',
  templateUrl: './resource-user-relation.component.html',
  styleUrls: ['./resource-user-relation.component.scss']
})
export class ResourceUserRelationComponent extends BaseComponent implements OnInit {

  public endstations: Resource[] = [];
  public branchlines: Resource[] = [];
  public supervisorsRole: UserRole[] = [];
  public user!: User;
  public resourceUserRelationToSend: ResourceUserRelation[] = [];

  public userSelected?: string;
  public endstationSelected?: string | null;
  public branchlineSelected?: string | null;
  public endstationListSelected: string[] = [];
  public branchlineListSelected: string[] = [];

  public isSaving!: boolean;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<ResourceUserRelationComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: {
      resourceUserRelation: ResourceUserRelation,
      branchlines: Resource[],
      endstations: Resource[],
      dataJoin: {
        resourceUserRlations: ResourceUserRelation[],
        user: User,
        endstationsList: string[],
        branchlinesList: string[]
      },
      dialogTitle: string,
      roleTitle: string,
      roleSelected: number
    },
    private userRoleService: UserRoleService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.endstations = this.inputData.endstations;
    this.branchlines = this.inputData.branchlines;

    this.userRoleService.Read(null, this.inputData.roleSelected).subscribe(data => {
      this.supervisorsRole = data as UserRole[];
    });
    if (this.inputData.resourceUserRelation?.user) {
      this.userSelected = this.inputData.resourceUserRelation.user.name;
    }
    if (this.inputData.resourceUserRelation?.endstation) {
      this.endstationSelected = this.inputData.resourceUserRelation.endstation.name;
    }
    if (this.inputData.resourceUserRelation?.branchline) {
      this.branchlineSelected = this.inputData.resourceUserRelation.branchline.name;
    }
    if (this.inputData?.dataJoin?.endstationsList) {
      this.endstationListSelected = this.inputData.dataJoin.endstationsList;
    }
    if (this.inputData?.dataJoin?.branchlinesList) {
      this.branchlineListSelected = this.inputData.dataJoin.branchlinesList;
    }
  }

  add() {
    this.isSaving = true;
    let dataToSend: {
      resourceUserRlations: ResourceUserRelation[],
      user?: User,
      endstationsList: string[],
      branchlinesList: string[]
    };
    dataToSend = {
      resourceUserRlations: [],
      user: new User(),
      endstationsList: [],
      branchlinesList: []
    };
    if (this.isValid()) {
      const userSelected = this.users.find(x => x.name === this.userSelected);
      if (this.endstationListSelected.length >= 1 || this.branchlineListSelected.length >= 1) {
        this.endstationListSelected.map(endSelected => {
          const resourceUserRelation: ResourceUserRelation = {
            user: userSelected,
            endstation: this.endstations.find(x => x.name === endSelected),
            branchline: new Resource()
          };
          this.resourceUserRelationToSend.push(resourceUserRelation);
        });
        this.branchlineListSelected.map(brSelected => {
          const resourceUserRelation: ResourceUserRelation = {
            user: userSelected,
            endstation: new Resource(),
            branchline: this.branchlines.find(x => x.name === brSelected)
          };
          this.resourceUserRelationToSend.push(resourceUserRelation);
        });
      } else {
        const resourceUserRelation: ResourceUserRelation = {
          user: userSelected,
          endstation: new Resource(),
          branchline: new Resource()
        };
        this.resourceUserRelationToSend.push(resourceUserRelation);
      }
      dataToSend.resourceUserRlations = this.resourceUserRelationToSend;
      dataToSend.user = userSelected;
      dataToSend.endstationsList = this.endstationListSelected;
      dataToSend.branchlinesList = this.branchlineListSelected;
      this.dialogRef.close(dataToSend);
    } else {
      this.isSaving = false;
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  getInfoEndstations(endstations: string[]) {
    this.endstationListSelected = endstations;
  }

  getInfoBranchlines(branchines: string[]) {
    this.branchlineListSelected = branchines;
  }

  isValid() {
    if (!this.userSelected) {
      this.alert.message('resourceUserRelationDialog_Required', [new MessagePlaceholder('{what}', this.inputData.roleTitle)]);
      return false;
    }
    return true;
  }
}
