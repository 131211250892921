import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { utils } from 'src/app/modules/libs/utils';
import { TokenInfoService } from 'src/app/services/authentication/token-info.service';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { BulletinBoardItem } from '../bulletin-board-item';
import { BulletinBoardService } from '../bulletin-board-service.service';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'app-modal-history-bb',
  templateUrl: './modal-history-bb.component.html',
  styleUrls: ['./modal-history-bb.component.scss']
})
export class ModalHistoryBBComponent extends BaseComponent implements OnInit, OnDestroy {

  public bulletinBoardItems$!: Observable<BulletinBoardItem[]>;

  public bulletinBoardItemSubscription!: Subscription;

  inactivesHistory: BulletinBoardItem[] = [];
  FilteredInactivesHistory: BulletinBoardItem[] = [];

  Month!: number;
  Year!: number;
  Filter = '';

  constructor(
    protected override injector: Injector,
    @Inject(DOCUMENT) private document: Document,
    public bulletinBoardService: BulletinBoardService,
  ) {
    super(injector);
    this.InitialLoadBB();
  }

  ngOnInit(): void {
    this.getBulletinBoardItems();
  }

  override ngOnDestroy(): void {
    this.bulletinBoardItemSubscription?.unsubscribe();
  }

  getBulletinBoardItems() {
    this.bulletinBoardItems$ = this.store.select(state => state.BulletinBoard.bulletinBoardItems);
    this.bulletinBoardItemSubscription = this.bulletinBoardItems$.subscribe(bulletinBoardItems => {
      this.inactivesHistory = bulletinBoardItems.filter(bb => bb.status === 0);
      this.filterHistory();
    });
  }

  SeeMore(event: any, item: BulletinBoardItem) {
    if (item.description.length > 250) {
      if (event.target.nodeName === 'A') {
        event = event.target.parentNode;
      } else if (event.target.nodeName === 'SPAN') {
        event = event.target;
      } else if (event.target.nodeName === 'P') {
        event = event.target.firstElementChild;
      }
      if (event.lastChild.nodeName === 'A') {
        event.innerHTML = item.description.split('\n').join('<br>');
        event.className += 'OverflowVisible';
      } else {
        event.innerHTML = item.description.substring(0, 250) + '<a href="#">... See more</a>';
        event.classList.remove('OverflowVisible');
      }
    }
  }

  InitialLoadBB() {

    this.Filter = '';
    this.Month = 0;
    this.Year = 0;
  }

  DisarchiveBB(item: BulletinBoardItem) {
    item.status = 1;
    const self = this;
    this.bulletinBoardService.updateBBI(item).toPromise().then(() => {
      // this.store.dispatch(new BulletinBoardRefresh());
    });
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (event.target.name === 'Month') {
      const currentMonth = this.Month ? this.Month.toString() : '0';
      const testMonth = Number(currentMonth + event.key);
      if (testMonth > 12) {
        return false;
      }
    } else if (event.target.name === 'Year') {
      const currentYear = this.Year ? this.Year.toString() : '0';
      const testYear = Number(currentYear + event.key);
      if (testYear > (new Date()).getFullYear()) {
        return false;
      }
    }
    return true;
  }

  filterHistory() {
    let query: BulletinBoardItem[] = this.inactivesHistory;
    if (!isNaN(this.Month) && this.Month) {
      const searchMonth = this.Month - 1;
      query = query.filter(item => 
        (new Date(item.createdOn).getMonth() == searchMonth) ||
        (new Date(item.updatedOn).getMonth() == searchMonth) ||
        (new Date(item.expirationDate).getMonth() == searchMonth));
    }

    if (!isNaN(this.Year) && this.Year) {
      query = query.filter(item => 
        (new Date(item.createdOn).getFullYear() == this.Year) ||
        (new Date(item.updatedOn).getFullYear() == this.Year) ||
        (new Date(item.expirationDate).getFullYear() == this.Year));
    }

    if (this.Filter && this.Filter != "") {
      const searchTerm = this.Filter.toLowerCase();
      query = query.filter(item => 
        item.name.toLowerCase().includes(searchTerm) ||
        item.description.toLowerCase().includes(searchTerm) ||
        item.createdByUser?.name?.toLowerCase().includes(searchTerm) ||
        item.updatedByUser?.toLowerCase().includes(searchTerm));
    }

    this.FilteredInactivesHistory = query;
  }
}
