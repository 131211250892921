import { Component, ElementRef, Injector, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PendingResourcesType, Roles } from 'src/app/common/enumerations/enumerations';
import { BeamlineShutterRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourcesListNewComponent } from './resources-list-new/resources-list-new.component';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { detailsUsers } from './resources-new/resources-new.component';
import { CatalogService } from '../catalog-service';
import { PendingResource } from 'src/app/services/pending-approvals/pending-approval';

@Component({
  selector: 'app-beamline-catalog',
  templateUrl: './beamline-catalog.component.html',
  styleUrls: ['./beamline-catalog.component.scss']
})
export class BeamlineCatalogComponent extends BaseComponent {

  @ViewChild("sidenav") sidenav!: MatSidenav;
  @ViewChild("headerTop") headerTop!: ElementRef;
  @ViewChild(ResourcesListNewComponent) side!: ResourcesListNewComponent;

  beamlineShutter?: BeamlineShutterRelation;

  disabled: boolean = false;
  showHistory?: boolean;

  loading = false;

  resourceRequired: boolean = false;

  filterCtrl = new FormControl();

  errorMessages: string[] = [];
  headerOnTop: boolean = false;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter: any[] = [];
  currentFilter: any;
  filter: any;

  beamlineShutterRelation?: BeamlineShutterRelation;
  beamlineShutterRelations?: BeamlineShutterRelation[];
  beamlineShutterRelationsFiltered?: BeamlineShutterRelation[];
  beamlineShutterRelations$!: Observable<BeamlineShutterRelation[]>;
  beamlineShutterRelationsSubs!: Subscription;

  originalBeamlineShutterRelation?: BeamlineShutterRelation;
  public detailResourceUsers: detailsUsers = { approverUserName: '', updateUserName: '', approvedOn: null, updatedOn: null };

  loadingMessage!: string;

  privileges = { canCreate: false };
  showChanges: boolean = false;

  pendingResource?: PendingResource;
  //////

  links = [{ id: 0, name: 'Details' }, { id: 1, name: 'KE Builder' }, { id: 2, name: 'RSS Database' }];
  selectedTab: number = 0;

  constructor(
    protected override injector: Injector,
    private _route: ActivatedRoute,
    private catalogService: CatalogService,
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.checkPrivileges();
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit() {
    this.moduleTitle = 'Beamline Catalog';
    this.checkPrivileges();
    this.loadBeamlineShutterRelations();
    let tab = this._route.snapshot.queryParams['tab'];
    if (!tab) tab = this._route.snapshot.params['tab'];
    if (tab)
      this.selectedTab = +tab;
  }

  selectedRow(row: BeamlineShutterRelation) {
    this.beamlineShutter = row;
    this.setShowChanges();
    this.setUpdaterAndApprovalNames(row.beamlineId!);
  }

  async onTabChanged(link: any) {
    if (await this.canDeactivate())
      this.showChanges = false;
    this.selectedTab = link.id;
  }

  loadBeamlineShutterRelations() {
    this.beamlineShutterRelations$ = this.store.select(state => state.BeamlineShutter.data);
    this.beamlineShutterRelationsSubs = this.beamlineShutterRelations$.subscribe(data => {
      if (data?.length) {
        this.beamlineShutterRelations = data;
        if (this.beamlineShutter) {
          this.beamlineShutter = this.beamlineShutterRelations.find(r => r.beamlineId == this.beamlineShutter?.beamlineId);
          if (this.beamlineShutter)
            this.beamlineShutter.statusClass = this.side.getColor(this.beamlineShutter);
        }
      }
      this.setShowChanges();
    });
  }

  async changeShow(e: any) {
    if (await this.canDeactivate()) {
      this.showChanges = e.checked;
    }

  }

  setShowChanges() {
    switch (this.selectedTab) {
      case 0:
        this.pendingResource = this.beamlineShutter?.pendingResources?.find(p => p.type == PendingResourcesType.Details);
        if (this.pendingResource) {
          this.pendingResource.userCreatedBy = this.getUser(this.pendingResource?.createdBy);
          this.showChanges = (this.isSA() || this.hasPrivilege(PrivilegeEnum.BeamlineCatalog)) && this.currentUser?.id == this.pendingResource?.createdBy;
        }
        break;
      case 1:
        this.showChanges = false;
        break;
      case 2:
        this.showChanges = false;
        break;
    }
  }

  override ngOnDestroy(): void {
    this.beamlineShutterRelationsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  clear() {
    this.errorMessages = [];
  }

  async create() {
    if (await this.canDeactivate()) {
      this.beamlineShutter = new BeamlineShutterRelation();
      this.beamlineShutter.beamlineName = 'New Beamline';
      this.beamlineShutter.beamlineStatus = 'Draft';
      this.beamlineShutter.statusClass = 'yellow';
    }
  }

  applyFilter(e: any) {
    if (!e)
      this.valueFilter = '';
    this.filter = e;
  }

  buttonsVisible: boolean | null = false;

  checkPrivileges() {
    this.privileges.canCreate = this.hasRoles([Roles.ADM]);
    this.setHeights(30);
  }

  toggleVisible() {
    switch (this.selectedTab) {
      case 0:
        return this.beamlineShutter?.pendingResources?.some(p => p.type == PendingResourcesType.Details || p.type == PendingResourcesType.Both);
      case 1:
        return false;
      case 2:
        return this.beamlineShutter?.pendingResources?.some(p => p.type == PendingResourcesType.Rss_Database || p.type == PendingResourcesType.Both);
    }
    return false;
  }

  history(val: boolean) {
    this.showHistory = val;
    if (val) {
      this.originalBeamlineShutterRelation = this.utils.cloneDeep(this.beamlineShutter);
      this.showChanges = false;
    }
    else {
      this.beamlineShutter = this.utils.cloneDeep(this.originalBeamlineShutterRelation);
    }
  }

  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 2000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Buttons:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Details:
        case LoadingOrigin.KEBuilder:
        case LoadingOrigin.RSSDb:
          this.loadingMain = e as boolean;
          break;
      }
      // console.log('m: ' + this.loadingMain + '   b: ' + this.loadingButtons + '   s: ' + this.loadingSide);
      this.loading = this.loadingMain || this.loadingButtons || this.loadingSide;
    }, 100);
  }

  loadingSide = false;
  loadingButtons = false;
  loadingMain = false;
  loadingOrigin = LoadingOrigin;

  setUpdaterAndApprovalNames(beamlineId: number) {
    this.catalogService.GetPendingResourcesUsersByResourceId(beamlineId, PendingResourcesType.Details).toPromise().then(PendingResourceUsers => {
      this.detailResourceUsers.approverUserName = this.getUser(PendingResourceUsers?.approverId)?.name;
      this.detailResourceUsers.approvedOn = PendingResourceUsers?.approvedOn;
      this.detailResourceUsers.updateUserName = this.getUser(PendingResourceUsers?.updateById)?.name;
      this.detailResourceUsers.updatedOn = PendingResourceUsers?.updatedOn;
    });
    const pendingResource = this.beamlineShutter?.pendingResources[this.beamlineShutter.pendingResources.length - 1];
    // if (this.showPrevResource && pendingResource) {
    //   this.pendingResourceUsers.updateUserName = this.getUser(pendingResource?.createdBy)?.name;
    //   this.pendingResourceUsers.updatedOn = pendingResource?.createdOn;
    // }
  }

}

enum LoadingOrigin {
  Side = 1, Buttons = 2, Details = 3, KEBuilder = 4, RSSDb = 5
}
