import { Role } from '../services/role';

export class RoleRefresh {
  static readonly type = '[Role] Refresh';
}

export class RoleCreate {
  static readonly type = '[Role] Create';
  constructor(public item: Role) { }
}

export class RoleUpdate {
  static readonly type = '[Role] Update';
  constructor(public id: number, public item: Role) { }
}

export class RoleDelete {
  static readonly type = '[Role] Delete';
  constructor(public id: number) { }
}
