import { MatDialogConfig } from '@angular/material/dialog';
import { ReviewRequestComponent } from './review-request/review-request.component';
import { ReviewsRequestsTableComponent } from './reviews-requests-table/reviews-requests-table.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ReviewCycleStatus } from 'src/app/common/enumerations/enumerations';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ReviewPermissionsRefresh } from '../store/review-permissions/review-permissions.action';

@Component({
  selector: 'reviews-requests',
  templateUrl: './reviews-requests.component.html',
  styleUrls: ['./reviews-requests.component.scss']
})
export class ReviewsRequestsComponent extends BaseComponent implements OnInit {


  createRequestPrivilege!: boolean;
  loading!: boolean;

  statuses: ReviewRequestStatus[] = [
    { status: ReviewCycleStatus.Pending, name: 'Pending', icon: 'new_releases', color: 'yellow', selected: true },
    { status: ReviewCycleStatus.Approved, name: 'Approved', icon: 'check_circle', color: 'green', selected: true },
    { status: ReviewCycleStatus.Rejected, name: 'Rejected', icon: 'report', color: 'red' },
    { status: ReviewCycleStatus.Archived, name: 'Archived', icon: 'archive', color: 'gray' },
  ];

  @ViewChild(ReviewsRequestsTableComponent)
  table!: ReviewsRequestsTableComponent;

  ngOnInit(): void {
    this.store.dispatch(new ReviewPermissionsRefresh());
    this.createRequestPrivilege = this.hasPrivilege(PrivilegeEnum.CreateReviewRequest);
  }

  filterChanged(status: ReviewRequestStatus) {
    const s = this.statuses.find(x => x.status == status.status);
    if (s)
      s.selected = !s.selected;
    this.table.loadReviewCycles();
  }

  reviewRequestDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ReviewRequestComponent, dialogConfig);
    dialogRef.afterClosed().toPromise();
  }

}

export class ReviewRequestStatus {
  status!: ReviewCycleStatus;
  name!: string;
  icon!: string;
  color!: string;
  selected?: boolean;
}
