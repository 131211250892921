import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { OlogEmail } from '../../../services/olog-email';


@Component({
  selector: 'app-add-edit-olog-email',
  templateUrl: './add-edit-olog-email.component.html',
  styleUrls: ['./add-edit-olog-email.component.scss']
})
export class AddEditOlogEmailComponent extends BaseComponent implements OnInit {

  public usersFiltered?: User[];

  public get userNameCtrl(): AbstractControl | null { return this.formGroup.get('userNameCtrl'); }
  public get userEmailCtrl(): AbstractControl | null { return this.formGroup.get('userEmailCtrl'); }

  constructor(
    protected override injector: Injector,

    public dialogRef: MatDialogRef<AddEditOlogEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public ologEmail: OlogEmail,
  ) {
    super(injector);

  }

  ngOnInit() {
    if (!this.ologEmail.user?.id) { this.ologEmail.user = new User(); }
    this.usersFiltered = this.getUsers();
    this.initializeForm();
  }

  add() {
    if (this.ologEmail.user?.id) {
      this.ologEmail.userId = this.ologEmail.user.id;
      this.dialogRef.close(this.ologEmail);
    } else {
      this.userNameCtrl?.setErrors({ required: true });
      this.formGroup.markAllAsTouched();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  displayUserName(user: User) {
    return user && user.name ? user.name : '';
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      userNameCtrl: [{ value: '' }],
      userEmailCtrl: [{ value: '', disabled: true }]
    });
  }

  filter(e: any) {
    const text = e.target.value;
    const code = e.code;
    if (!code?.includes('Arrow')) {
      this.usersFiltered = this.users.filter(x => x.name?.toLowerCase().includes(text.trim().toLowerCase()));
    }
  }

}
