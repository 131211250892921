import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ProcedureConfigurationRefresh, ProcedureConfigurationUpdate } from './procedure-configuration.action';
import { ProcedureConfiguration } from '../../models/procedure-configuration.model';
import { ProcedureConfigurationService } from '../../services/procedure-configuration.service';

export class ProcedureConfigurationStateModel {
  ProcedureConfiguration!: ProcedureConfiguration | null;
}

@State<ProcedureConfigurationStateModel>({
  name: 'ProcedureConfiguration',
  defaults: {
    ProcedureConfiguration: null
  }
})
@Injectable()
export class ProcedureConfigurationState {
  constructor(
    private procedureConfigurationService: ProcedureConfigurationService
  ) { }

  @Selector()
  static read(state: ProcedureConfigurationStateModel) {
    return state.ProcedureConfiguration;
  }

  @Action(ProcedureConfigurationRefresh)
  refresh({ patchState }: StateContext<ProcedureConfigurationStateModel>) {
    this.procedureConfigurationService.read().subscribe(
      data => patchState({ ProcedureConfiguration: data }),
      error => console.error(error)
    );
  }

  @Action(ProcedureConfigurationUpdate)
  update({ patchState }: StateContext<ProcedureConfigurationStateModel>, { item }: ProcedureConfigurationUpdate) {
    patchState({
      ProcedureConfiguration: item
    });
  }
}
