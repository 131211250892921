<div class="card">
  <div class="card-body">
    <div class="d-print-flex">
      <mat-form-field>
        <mat-label>{{ placeholder }}</mat-label>
        <input
          matInput
          type="text"
          #input
          [matAutocomplete]="userAutocomplete"
          (keyup)="filter($event)"
        />
        <mat-autocomplete
          #userAutocomplete="matAutocomplete"
          (optionSelected)="select($event.option.value)"
        >
          <mat-option *ngFor="let user of filteredUsers" [value]="user">
            {{ user.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions class="card-footer" align="center">
    <button mat-flat-button color="warn" (click)="dialogRef.close()">
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="user == null || user == undefined"
      (click)="accept()"
    >
      Accept
    </button>
  </div>
</div>
