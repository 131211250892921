import { Component, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { Role } from 'src/app/components/catalogs/roles/services/role';

@Component({
  selector: 'role-chips',
  templateUrl: './role-chips.component.html',
  styleUrls: ['./role-chips.component.scss']
})
export class RoleChipsComponent implements OnChanges, OnDestroy {

  @Input() roleCodes?: string;
  @Input() userNames?: string;
  @Input() alignLeft!: boolean;

  roles!: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;
  filteredRoles!: Role[];

  constructor(private store: Store) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadRoles();
  }

  ngOnDestroy(): void {
    this.rolesSubs?.unsubscribe();
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      this.roles = data;
      if (this.roleCodes) {
        const rolesArray = this.roleCodes.split('/');
        this.filteredRoles = this.roles.filter(val => val.code && rolesArray.includes(val.code));
      }
    });
  }

  getTooltip(role?: Role) {
    if (role && this.userNames) {
      const users = this.userNames?.split(',');
      return role.name + '<br><b>' + users?.find(u => u.includes(role.code ?? '')) + '</b>';
    }
    else
      return role?.name ?? '';
  }


}
