import { Component, OnInit, Input, Output, EventEmitter, Injector } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { OtherSystem } from './other-system';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'other-system-chips',
  templateUrl: './other-system-chips.component.html',
  styleUrls: ['./other-system-chips.component.scss']
})
export class OtherSystemChipsComponent extends BaseComponent implements OnInit {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() control!: FormControl;
  @Input() formId?: number;
  @Input() formType?: FormType;
  @Input() otherSystems?: OtherSystem[];
  @Input() hilite?: boolean | null;

  @Output() onEdit = new EventEmitter<OtherSystem[]>();

  constructor(
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  add(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if (input) {
      input.value = '';
    }

    if (value.trim() != '') {
      if (!this.otherSystems) this.otherSystems = [];
      this.otherSystems?.push({
        text: value
      });
      this.onEdit.emit(this.otherSystems);
    }
  }

  remove(otherSystem: OtherSystem) {
    const index = this.otherSystems?.indexOf(otherSystem) ?? -1;

    if (index >= 0) {
      this.otherSystems?.splice(index, 1);
      this.onEdit.emit(this.otherSystems);
    }
  }

}
