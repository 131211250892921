import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, OnChanges, SimpleChanges, Injector, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ResourceType } from 'src/app/common/enumerations/enumerations';
import { utils } from 'src/app/modules/libs/utils';
import { Resource, ResourceGroup } from 'src/app/components/catalogs/beamline-catalog/resource/resources';

@Component({
  selector: 'resource-chips',
  templateUrl: './resource-chips.component.html',
  styleUrls: ['./resource-chips.component.scss']
})
export class ResourceChipsComponent extends BaseComponent implements OnChanges, OnInit {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() control!: FormControl;
  @Input() selectedResources?: Resource[];
  @Input() hilite?: boolean | null;
  @Input() resourceTypes!: ResourceType[];

  @Output() onError = new EventEmitter<string>();
  @Output() onEdit = new EventEmitter<Resource[]>();

  @ViewChild('resourceAutocomplete') resourceAutocomplete?: MatAutocomplete;
  @ViewChild('resourceInput') resourceInput?: ElementRef<HTMLInputElement>;

  resources?: Resource[];
  resourceGroups!: ResourceGroup[];
  availableResources?: Resource[];
  filteredResources?: Resource[];

  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  filter?: string | null;
  constructor(
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loadResources();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterResourcces();
    if (this.disabled) this.control.disable();
    else this.control.enable();
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data?.length) {
        this.resources = data.filter(r => this.resourceTypes?.includes(r.type ?? 0)).sort((a, b) => utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
      }
    });
  }

  filterResourcces() {
    this.availableResources = this.resources?.filter(x => !this.selectedResources?.map(r => r?.id).includes(x.id));
    this.filteredResources = this.availableResources?.filter(x => x.name?.toLowerCase().includes(this.filter?.toLowerCase() ?? ''));
  }

  createResourceGroups() {
    this.resourceGroups = [];
    this.filteredResources?.map(l => {
      if (l.type == 1) {
        if (!this.resourceGroups.map(g => g.id).includes(l.id)) {
          const resources = l.childResources?.filter(x => x.childResourceType == ResourceType.AcceleratorLocation && !this.selectedResources?.map(r => r.id).includes(x.childResourceID ?? 0)).map(x => x.childResource) as Resource[];
          if (resources?.length)
            this.resourceGroups.push({ name: l.name, type: l.type, id: l.id, resources });
        }
      }
      else {
        if (!this.resourceGroups.map(g => g.type).includes(l.type) && l.type != ResourceType.AcceleratorLocation) {
          const resources = this.filteredResources?.filter(x => x.type == l.type && (x.name?.toLowerCase().includes(this.filter?.toLowerCase() ?? '') || !this.filter));
          if (resources?.length)
            this.resourceGroups.push({ name: l.resourceType?.name, type: l.type, resources });
        }
      }
    });
  }

  selected(event: MatAutocompleteSelectedEvent) {
    const resource: Resource = event.option.value;
    this.selectedResources?.push(resource);
    this.clear();
    this.loadResources();
    this.createResourceGroups();
    this.onEdit.emit(this.selectedResources ?? []);
  }

  remove(resource: Resource) {
    const index = this.selectedResources?.indexOf(resource) ?? -1;
    if (index >= 0) {
      this.selectedResources?.splice(index, 1);
      this.clear();
      this.loadResources();
      this.createResourceGroups();
      this.onEdit.emit(this.selectedResources);
    }
  }

  clear() {
    if (this.resourceInput) this.resourceInput.nativeElement.value = '';
    this.filter = null;
  }

  onKeyUp(e: any) {
    const code = e.code;
    const text = e.target.value;
    if (!code?.includes("Arrow")) {
      this.filter = text;
      this.createResourceGroups();
    }
  }

}
