import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ChecklistTemplateStatusesCreate, ChecklistTemplateStatusesDelete, ChecklistTemplateStatusesRefresh, ChecklistTemplateStatusesUpdate } from './checklist-status.action';

import { ChecklistBuilderService } from '../../checklist-builder/checklist-builder.service';
import { ChecklistTemplateStatus } from '../../checklists';


export class ChecklistTemplateStatusesStateModel {
  data: ChecklistTemplateStatus[] = [];
}

@State<ChecklistTemplateStatusesStateModel>({
  name: 'ChecklistTemplateStatuses',
  defaults: {
    data: []
  }
})
@Injectable()
export class ChecklistTemplateStatusesState {
  constructor(
    private _service: ChecklistBuilderService
  ) { }

  @Selector()
  static read(state: ChecklistTemplateStatusesStateModel) {
    return state.data;
  }

  @Action(ChecklistTemplateStatusesRefresh)
  refresh({ patchState }: StateContext<ChecklistTemplateStatusesStateModel>) {
    this._service.getStatuses().toPromise().then(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ChecklistTemplateStatusesCreate)
  create({ getState, patchState }: StateContext<ChecklistTemplateStatusesStateModel>, { item }: ChecklistTemplateStatusesCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ChecklistTemplateStatusesUpdate)
  update({ getState, patchState }: StateContext<ChecklistTemplateStatusesStateModel>, { id, item }: ChecklistTemplateStatusesUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ChecklistTemplateStatusesDelete)
  delete({ getState, patchState }: StateContext<ChecklistTemplateStatusesStateModel>, { id }: ChecklistTemplateStatusesDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
