<div class="row p-1">
  <div class="col-6">
    <mat-form-field appearance="outline">
      <mat-label>Document Name</mat-label>
      <input matInput [(ngModel)]="documentType.name" disabled name="Name" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Document Description</mat-label>
      <input
        matInput
        [(ngModel)]="documentType.description"
        disabled
        name="Description"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Document Code</mat-label>
      <input matInput [(ngModel)]="documentType.code" disabled name="Code" />
    </mat-form-field>
  </div>
  <div class="col-6">
    <h6>Template Types</h6>
    <div class="item-container" cdkDropList (cdkDropListDropped)="drop($event)">
      <mat-accordion
        cdkDrag
        *ngFor="let e of templateTypesExpansions; index as i"
        [hideToggle]="true"
      >
        <mat-expansion-panel
          class="expansion-panel mb-1"
          [expanded]="e.expanded"
          [disabled]="true"
        >
          <mat-expansion-panel-header class="pl-2">
            <mat-panel-title class="mr-2">
              <mat-icon cdkDragHandle color="accent" style="cursor: move"
                >reorder</mat-icon
              >
            </mat-panel-title>
            <mat-panel-description>
              <mat-checkbox
                [(ngModel)]="e.templateType.status"
                labelPosition="after"
                color="primary"
                (change)="changeRequired($event.checked, e)"
              >
                {{ e.templateType.name }}</mat-checkbox
              >
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <div class="row border mt-1 p-1">
              <div class="col-6">
                <mat-slide-toggle
                  color="warn"
                  [(ngModel)]="e.rtToggle"
                  (change)="rtToggleChanged(e, $event)"
                  >Link to Resource
                </mat-slide-toggle>
              </div>
              <div class="col-6">
                <mat-form-field
                  appearance="outline"
                  *ngIf="e.rtToggle"
                  class="no-subscript"
                >
                  <mat-label>Resource Type</mat-label>
                  <input
                    type="text"
                    placeholder="Resource Type"
                    matInput
                    [matAutocomplete]="autoResource"
                    [ngModel]="e.templateType.resourceType"
                  />
                  <mat-autocomplete
                    #autoResource="matAutocomplete"
                    #resourceAutocomplete
                    [displayWith]="displayObject"
                    (optionSelected)="selectedResourceType(e, $event)"
                  >
                    <mat-option
                      *ngFor="let resourceType of resourceTypes"
                      [value]="resourceType"
                    >
                      {{ resourceType.name }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-hint *ngIf="!rtError"> Linked Resource Type </mat-hint>
                  <mat-hint class="error" *ngIf="rtError">
                    Select Resource Type
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
            <!-- <div class="row card mt-1">
                <div class="col-12">
                  <mat-slide-toggle
                    color="warn"
                    [ngModel]="t.scheduleType != null"
                    (change)="scToggleChanged(t, $event)"
                    >Create from Schedule
                  </mat-slide-toggle>
                  <mat-form-field appearance="outline" *ngIf="scToggle">
                    <mat-label>Schedule Type</mat-label>
                    <input
                      type="text"
                      placeholder="Schedule Type"
                      matInput
                      name="scheduleTypeCtrl"
                      [formControl]="scheduleTypeCtrl"
                      [matAutocomplete]="autoSchedule"
                    />
                    <mat-autocomplete
                      #autoSchedule="matAutocomplete"
                      #scheduleAutocomplete
                      [displayWith]="displayObject"
                      (optionSelected)="selectedScheduleType(t, $event)"
                    >
                      <mat-option
                        *ngFor="let scheduleType of filteredScheduleTypes"
                        [value]="scheduleType"
                      >
                        {{ scheduleType.name }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-hint *ngIf="!scError">
                      Schedule Type to create Checklist from
                    </mat-hint>
                    <mat-hint class="error" *ngIf="scError">
                      Select Schedule Type
                    </mat-hint>
                  </mat-form-field>
                  <mat-slide-toggle
                    *ngIf="scToggle"
                    color="accent"
                    [(ngModel)]="!scToggleHide"
                    (change)="scToggleHideChanged($event)"
                    >Show Custom Schedules in Resource List
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="row card mt-1">
                <div class="col-12">
                  <mat-slide-toggle
                    color="warn"
                    [(ngModel)]="rxToggle"
                    (change)="rxToggleChanged($event)"
                    >Checklist Execution has Review Process
                  </mat-slide-toggle>
                  <mat-form-field appearance="outline" *ngIf="rxToggle">
                    <mat-label>Roles to Review the Checklist</mat-label>
                    <mat-chip-grid
                      #chipListRole
                      aria-label="Role selection"
                      [disabled]="false"
                      style="height: 4em"
                    >
                      <mat-chip-row
                        *ngFor="let role of roles"
                        [selectable]="false"
                        [removable]="true"
                        (removed)="removeRole(role)"
                      >
                        {{ role.role.code }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip-row>
                      <input
                        #roleInput
                        [(ngModel)]="tmpRole"
                        [formControl]="roleCtrl"
                        [matAutocomplete]="autoRole"
                        [matChipInputFor]="chipListRole"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true"
                        (keyup)="onRoleChange($event)"
                      />
                    </mat-chip-grid>
                    <mat-autocomplete
                      #autoRole="matAutocomplete"
                      (optionSelected)="selectedRole($event)"
                      #roleAutocomplete
                    >
                      <mat-option
                        *ngFor="let role of rolesFiltered"
                        [value]="role"
                      >
                        {{ role.name }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>You must choose at least one Role</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row card mt-1">
                <div class="col-12">
                  <mat-slide-toggle
                    color="warn"
                    [(ngModel)]="aiToggle"
                    (change)="aiToggleChanged($event)"
                    >Allow enter Action Items in Checklist
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="row card mt-1">
                <div class="col-12">
                  <mat-slide-toggle
                    [disabled]="scToggle || (rtToggle ?? false)"
                    color="warn"
                    [(ngModel)]="mvToggle"
                    (change)="mvToggleChanged($event)"
                    >Allow Multiple Active Versions
                  </mat-slide-toggle>
                </div>
              </div> -->
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <span class="small red"
      >Use Document Catalog to modify Template Types Details</span
    >
    <!-- <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div
          cdkDrag
          class="row cardx mb-1"
          *ngFor="let t of templateTypes; index as i"
        >
          <div class="col-1 d-flex px-2 align-items-center">
            <mat-icon cdkDragHandle color="accent" style="cursor: move"
              >reorder</mat-icon
            >
          </div>
          <div class="col-9 d-flex px-2 align-items-center">
            <mat-checkbox
              [(ngModel)]="t.status"
              labelPosition="after"
              color="primary"
              (change)="changeRequired($event.checked, t)"
            >
              {{ t.name }}</mat-checkbox
            >
          </div>
          <div class="col-2 d-flex pr-1 align-items-center justify-content-end">
            <button mat-icon-button color="primary" (click)="settings(t)">
              <mat-icon color="accent">settings</mat-icon>
            </button>
          </div>
        </div>
      </div> -->
  </div>
</div>
<!-- <div class="row" *ngIf="templateType && templateType.id >= 0">
    <div class="col-6">
      <mat-form-field appearance="outline" class="mb-3">
        <mat-label>Template Name</mat-label>
        <input
          matInput
          [(ngModel)]="templateType.name"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="true"
        />
        <mat-hint>How is displayed on Dropdowns</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mb-3">
        <mat-label>Template Description</mat-label>
        <input
          matInput
          [(ngModel)]="templateType.description"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="true"
        />
        <mat-hint>Full description</mat-hint>
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline" class="mb-3">
        <mat-label>Template Code</mat-label>
        <input
          matInput
          [(ngModel)]="templateType.code"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="true"
        />
        <mat-hint>To be used on Serial Numbers</mat-hint>
      </mat-form-field>
    </div>
    <div class="col-6" *ngIf="templateType?.id"></div>
  </div> -->

<div class="saving" *ngIf="saving">
  <saving-circle></saving-circle>
</div>
