<h5>History Log</h5>
<mat-tab-group animationDuration="0ms">
  <mat-tab class="log-tab" label="Approvals"
    ><transaction-log-approvals
      [transactionLogs]="transactionLogs"
    ></transaction-log-approvals
  ></mat-tab>
  <mat-tab
    class="log-tab"
    label="Amendments"
    [ngClass]="{ invisible: !amendments.generalLogs?.updates?.length }"
  >
    <transaction-log-amendments
      #amendments
      [serialNo]="serialNo"
      [formType]="formType"
    ></transaction-log-amendments>
  </mat-tab>
</mat-tab-group>
