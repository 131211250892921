import { Component, EventEmitter, Input, Output, ViewChild, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { ChecklistTemplate, ChecklistExecStatus } from '../../../checklists';
import { ChecklistBuilderService } from '../../checklist-builder.service';
import { ChecklistBuilderTasksComponent } from '../../checklist-builder-tasks/checklist-builder-tasks.component';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { WFStepLocalResource, WFStepStatusLocal, WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { utils } from 'src/app/modules/libs/utils';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { Configuration } from '../../checklist-builder-sections/checklist-builder-section/checklist-builder-section.component';
import { distinctUntilChanged, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'checklist-builder-step',
  templateUrl: './checklist-builder-step.component.html',
  styleUrls: ['./checklist-builder-step.component.scss'],
})
export class ChecklistBuilderStepComponent
  extends BaseComponent
  implements OnChanges {
  @Input() step!: WFStepLocalResource;
  @Input() currentVersion!: ChecklistTemplate;
  @Input() disabled!: boolean;
  @Input() procedure?: Procedure | null;
  @Input() stepDropLists!: string[];
  @Input() task!: WfTaskLocalResource;
  @Input() showDeleted?: boolean;
  @Input() isDeletedSection?: boolean;

  @Output() loading = new EventEmitter();
  @Output() deleted = new EventEmitter();
  @Output() refreshPreviousStep = new EventEmitter<number>();

  @ViewChild(ChecklistBuilderTasksComponent) tasksComponent!: ChecklistBuilderTasksComponent;

  editing!: boolean;
  configuration: Configuration = new Configuration();

  enabledOn?: (ChecklistExecStatus | undefined)[];
  hiddenOn!: (ChecklistExecStatus | undefined)[];
  visibleOn!: (ChecklistExecStatus | undefined)[];
  public statusError!: boolean;

  allStatuses!: ChecklistExecStatus[];
  displaySettings!: boolean;
  showStatus!: boolean;
  deletedTasks: WfTaskLocalResource[] = [];
  showDeletedTasks?: boolean;

  wfTaskLocals?: WfTaskLocalResource[];
  wfTaskLocals$!: Observable<WfTaskLocalResource[]>;
  wfTaskLocalsSubs!: Subscription;

  deletedTaskLocals?: WfTaskLocalResource[];
  deletedTaskLocals$!: Observable<WfTaskLocalResource[]>;
  deletedTaskLocalsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private _builder: ChecklistBuilderService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  refresh() {
    this.loadStatuses();
    if (this.step.logID) {
      this.wfTaskLocals = this.step.wfTaskLocals;
    }
    else {
      this.wfTaskLocals$ = this.store.select((state) => state.WFTaskLocals.data);
      this.wfTaskLocalsSubs = this.wfTaskLocals$.pipe(distinctUntilChanged()).subscribe((data) => {
        if (data?.length) {
          this.wfTaskLocals = data.filter((t) => t.wfStepLocalID == this.step.id);
        }
        this.loadDeletedTasks();
      });
    }
    this.disabled = this.disabled || (this.step.logID ?? 0) > 0;
    this.loading.emit(false);
  }

  getInfo() {
    this.enabledOn = this.step.wfStepStatusLocals.filter((x) => !x.hidden).map((x) => x.checklistExecStatus);
    this.hiddenOn = this.step.wfStepStatusLocals.filter((x) => x.hidden).map((x) => x.checklistExecStatus);
    this.visibleOn = this.step.wfStepStatusLocals.filter((x) => !x.hidden).map((x) => x.checklistExecStatus);
    this.configuration = utils.JSONparse(this.step.configuration);
    if (!this.configuration) {
      this.configuration = new Configuration();
    }
  }

  loadStatuses() {
    this.statusError = false;
    this.allStatuses =
      this.currentVersion?.documentType?.checklistExecStatuses ?? [];
    this.step.wfStepStatusLocals?.map(s => {
      const checklistStatus = this.allStatuses?.find((x) => x.id == s.status);
      if (checklistStatus) s.checklistExecStatus = checklistStatus;
      s.enabled = !s.hidden;
      if (!s.checklistExecStatus) {
        this.statusError = true;
      }
    });
    this.getInfo();
  }

  loadDeletedTasks() {
    this.deletedTaskLocals$ = this.store.select((state) => state.DeletedWFTaskLocals.data);
    this.deletedTaskLocalsSubs = this.deletedTaskLocals$.pipe(distinctUntilChanged()).subscribe((data) => {
      if (this.showDeleted && !this.step.logID) {
        this.deletedTasks = data.filter((t) => t.wfStepLocalID == this.step.id);
        // this.deletedTasks.map((t) => (t.order = (t.order ?? 0) - 1));
        if (this.showDeleted)
          this.wfTaskLocals = this.wfTaskLocals?.filter(t => !t.logID).concat(this.deletedTasks).sort((a, b) => this.utils.sort(a.order, b.order));
      }
    });
  }

  edit() {
    if (!this.disabled) {
      this.editing = true;
    }
  }

  save() {
    this.editing = false;
    this._builder
      .updateStep(this.step)
      .toPromise()
      .then(
        (data) => {
          if (data) this.step = data;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  delete() {
    if (this.step.wfTaskLocals.length != 0) {
      const message =
        (this.deletedTasks.length
          ? this.getMessage('CB_Delete_Step_DeletedTasks').description.replace(
            '{deletedTasksCount}',
            this.deletedTasks.length.toString()
          )
          : '') + this.getMessage('CB_Delete_Step_Confirmation').description;
      const yesno = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message,
          icon: 'stop',
        },
      });
      yesno.afterClosed().subscribe((data) => {
        if (data) {
          this.deleteStep();
        }
      });
    } else {
      this.deleteStep();
    }
  }

  deleteStep() {
    this._builder
      .deleteStep(this.step)
      .toPromise()
      .then((data) => {
        this.deleted.emit(this.step);
        this.alert.info(
          'Step: <b>' +
          this.step.name +
          '</b> has been Deleted!. You may Undo this in Deleted Steps panel.'
        );
      });
  }

  cancel() {
    this.editing = false;
  }

  settings(show: boolean) {
    this.displaySettings = show;
  }

  saveStatus(statuses: WFStepStatusLocal[]) {
    this.settings(false);
    const wfStepStatuses: WFStepStatusLocal[] = [];
    if (statuses) {
      statuses.map((s) => {
        const checklistExecStatus = this.allStatuses.find(
          (x) => x.id === s.status
        );
        if (checklistExecStatus && (s.hidden || s.enabled)) {
          s.checklistExecStatus = undefined;
          wfStepStatuses.push(s);
        }
      });
      this.step.wfStepStatusLocals = wfStepStatuses;
      this._builder
        .updateStep(this.step)
        .toPromise()
        .then((data) => {
          if (data) this.step = data;
          this.loadStatuses();
        });
    }
  }

  getClass(e?: ChecklistExecStatus) {
    return e?.cssClass + '-bg';
  }

  showStatusList() {
    this.showStatus = !this.showStatus;
  }
  setLoading(e: boolean) {
    this.loading.emit(e);
  }

  configurationChanged() {
    this.step.configuration = utils.JSONstringify(this.configuration);
    this.save();
  }

  refreshRestored(wfTaskLocal: WfTaskLocalResource) {
    this.step.wfTaskLocals.push(wfTaskLocal);
    this.refresh();
  }

  scrollDeletedTasks() {
    setTimeout(() => {
      const element = document.getElementById('deleted-tasks-' + this.step.id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }, 500);
  }

  duplicate() {
    const message =
      this.getMessage('ChecklistDuplicateStepConfirmation').description;
    const yesno = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message,
        icon: 'question',
      },
    });
    yesno.afterClosed().subscribe(async (data) => {
      this.loading.emit(true);
      if (data) {
        await this._builder.duplicateStep(this.step.id).toPromise().then(data => {
          this.alert.info(
            'Step: <b>' +
            this.step.name +
            '</b> has been Duplicated!.'
          );
          this.loading.emit(false);
        });
      }
    });
  }
}
