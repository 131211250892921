<div class="container-fluid mt-2">
  <scf-v2-main-s1
    #s1
    [scfMaster]="scfMaster"
    [disabled]="disabled"
    [privileges]="privileges"
    [status]="status"
    (changed)="changed($event)"
    (loading)="setLoading($event, 1)"
    (workRequiredChanged)="setChecksFromWorkRequired($event)"
  >
  </scf-v2-main-s1>
  <scf-v2-main-s2
    [scfMaster]="scfMaster"
    [disabled]="disabled"
    [privileges]="privileges"
    [status]="status"
    (changed)="changed($event)"
  >
  </scf-v2-main-s2>

  <scf-v2-main-s3
    [scfMaster]="scfMaster"
    [disabled]="scfMaster?.status != formStatusEnum.WorkApprovals"
    [privileges]="privileges"
    [status]="status"
    (changed)="changed($event)"
    (loading)="setLoading($event, 3)"
  >
  </scf-v2-main-s3>
  <worker-verification-table
    [currentDocument]="scfMaster"
    [disabled]="disabled"
    [privileges]="privileges"
    [status]="status"
    *ngIf="scfMaster && scfMaster?.status !== formStatusEnum.New"
  ></worker-verification-table>
  <!-- <section-verification
  *ngIf="scfMaster && scfMaster?.status !== formStatusEnum.New"
  id="section-verification"
  [currentDocument]="scfMaster"
></section-verification> -->

  <scf-v2-main-s4
    [scfMaster]="scfMaster"
    [disabled]="disabled"
    [privileges]="privileges"
    [status]="status"
    (changed)="changed($event)"
    (loading)="setLoading($event, 4)"
  >
  </scf-v2-main-s4>

  <scf-v2-main-s5
    #s5
    [scfMaster]="scfMaster"
    [disabled]="disabled"
    [privileges]="privileges"
    [status]="status"
    (changed)="changed($event)"
    (loading)="setLoading($event, 5)"
    (saved)="savedEvent($event)"
  >
  </scf-v2-main-s5>

  <div *ngIf="scfMaster?.status != formStatusEnum.New">
    <div class="mt-3">
      <comments-and-attachments
        id="section5"
        #section5
        FormNumber=""
        [currentDocument]="scfMaster"
        headerClass="h5"
      >
      </comments-and-attachments>
    </div>

    <div class="mt-3">
      <scf-log
        id="scfLog"
        *ngIf="scfMaster"
        class="afterCut"
        #scfLog
        [scfMaster]="scfMaster"
      >
      </scf-log>
    </div>
  </div>
</div>
