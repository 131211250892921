<div
	class="annotations-wrapper p-2"
	*ngIf="
    totalAnnotations &&
    (currentChecklist?.checklistStatus?.formStatusID == formStatus.Correcting ||
      currentChecklist?.checklistStatus?.formStatusID == formStatus.Reviewing ||
      currentChecklist?.checklistStatus?.formStatusID ==
        formStatus.PendingReview)
  "
>
	<div class="d-flex flex-row">
		<div class="pt-2 pl-2">
			<mat-icon class="pink">reviews</mat-icon>
		</div>
		<div class="pl-2">
			<span class="float-right text-right pr-2">
				Total Suggestions: <b>{{ totalAnnotations }}</b>
				<br />
				Active Suggestions: <b>{{ activeAnnotations }}</b>
			</span>
		</div>
	</div>
</div>
