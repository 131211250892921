<a routerLink="home">
  <img
    alt="Berkeley Logo"
    src="../assets/images/berkeley_alt4_logo.png"
    id="logo"
    class="img-responsive log-img"
  />
</a>
<div class="brand">
  <a class="white" href="/" [matTooltip]="environment.version">
    ASCC<sup class="ml-1">4</sup></a
  >
  <mat-icon
    *ngIf="signalR.connection"
    class="white material-symbols-outlined ml-2"
    matTooltip="Connected to Server"
  >
    bigtop_updates
  </mat-icon>
  <mat-icon
    *ngIf="!signalR.connection"
    class="red material-symbols-outlined ml-2"
    matTooltip="Disconnected from Server"
  >
    signal_disconnected
  </mat-icon>
</div>
