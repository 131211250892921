<div class="container-fluid">
  <div class="row mt-3">
    <div class="col">
      <h5>Bypass Removal and Verification of Operation</h5>
    </div>
  </div>
  <form-workflow
    [form]="form"
    [sectionNumber]="6"
    [leftConfigs]="[54, 70]"
    [rightConfigs]="[55, 71]"
    [disabled]="disabled"
    (loading)="loading.emit($event)"
  >
  </form-workflow>
</div>
