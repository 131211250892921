import { SCFPrivileges, SCFStatus, ScfV2Service } from "./scf-v2.service";
import { Component, ElementRef, HostListener, OnInit, ViewChild, OnDestroy, OnChanges, SimpleChanges, AfterViewInit, Injector, } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSidenav } from "@angular/material/sidenav";
import { Observable, Subscription } from "rxjs";
import { ScfV2SideComponent } from "./scf-v2-side/scf-v2-side.component";
import * as moment from "moment";
import { ScfV2MainComponent } from "./scf-v2-main/scf-v2-main.component";
import { ScfV2SettingsComponent } from "./scf-v2-settings/scf-v2-settings.component";
import { BaseComponent } from "src/app/common/base/base.component";
import { Roles, FormStatusEnum, LoadingOrigin, FormType } from "src/app/common/enumerations/enumerations";
import { YesNoDialogComponent } from "src/app/controls/yes-no-dialog/yes-no-dialog.component";
import { utils } from "src/app/modules/libs/utils";
import { FormStatus } from "src/app/services/forms/form-status";
import { Procedure } from "src/app/components/procedure/models/procedure.model";
import { PrivilegeEnum } from "src/app/services/role-privilege/privilege-enum";
import { SCFMaster } from "./scf";
import { MessagePlaceholder } from "src/app/common/models/placeholder";
import { BratoiStoreAndListenersService } from "../catalogs/bratoi/store/bratoi/bratoi.store";
import { RelatedLinkDocumentBase } from "src/app/common/models/related-link-document-base.model";

@Component({
  selector: "scf-v2",
  templateUrl: "./scf-v2.component.html",
  styleUrls: ["./scf-v2.component.scss"],
})
export class ScfV2Component extends BaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  validRoles = [Roles.FO, Roles.AO, Roles.OT];

  @ViewChild("sidenav") sidenav!: MatSidenav;
  @ViewChild("headerTop") headerTop!: ElementRef;
  @ViewChild(ScfV2SideComponent) side!: ScfV2SideComponent;
  @ViewChild(ScfV2MainComponent) main!: ScfV2MainComponent;

  disabled: boolean = false;
  loading = true;
  resourceRequired: boolean = false;

  filterCtrl = new FormControl();

  errorMessages: string[] = [];

  headerOnTop: boolean = false;

  procedure?: Procedure;
  procedures: Procedure[] = [];
  procedures$!: Observable<Procedure[]>;
  proceduresSubs: Subscription = new Subscription;
  procedureAppendix?: RelatedLinkDocumentBase;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter: any[] = [];
  currentFilter: any;
  filter: any;

  scfMasterUnchanged?: SCFMaster | null;
  scfMaster?: SCFMaster | null;
  scfMasters: SCFMaster[] = [];
  scfMasters$!: Observable<SCFMaster[]>;
  scfMastersSubs: Subscription = new Subscription;

  loadingMessage!: string;

  privileges: SCFPrivileges = new SCFPrivileges();
  public status: SCFStatus = new SCFStatus();

  showAmendmentToggle: boolean = false;
  //////

  constructor(
    public scfService: ScfV2Service,
    private bratoiStore: BratoiStoreAndListenersService,
    protected override injector: Injector
  ) {
    super(injector);
    this.bratoiStore.initListeners();
    this.bratoiStore.initStore();
  }

  ngAfterViewInit(): void {
    this.setHeights();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadSCFs();
  }

  ngOnInit() {
    this.loadSCFs();
    this.checkPrivileges();
    this.loadProcedures();
  }

  override currentUserUpdated(): void {
    this.checkPrivileges();
  };

  async loadSCFs() {
    this.scfMasters$ = this.store.select((state) => state.SCFMasters.data);
    this.scfMastersSubs = this.scfMasters$.subscribe((data) => {
      if (data && data.length > 0) {
        this.scfMasters = data.filter((x) => x.serialNo !== "");
        if (this.scfMaster) {
          this.scfMaster = this.scfMasters.find(
            (x) => x.id == this.scfMaster?.id
          );
          this.checkPrivileges();
        }
      }
    });
  }

  showStatusChangeMessage(scfMaster: SCFMaster) {
    this.alert.message("formStateChanged", [
      new MessagePlaceholder("{serialNo}", scfMaster.serialNo), new MessagePlaceholder("{status}", scfMaster.statusName ?? 'New'),]);
  }

  override ngOnDestroy(): void {
    this.scfMastersSubs?.unsubscribe();
    this.proceduresSubs?.unsubscribe();
  }

  clear() {
    this.errorMessages = [];
  }

  loadProcedures() {
    this.procedures$ = this.store.select((state) => state.Procedures.data);
    this.proceduresSubs = this.procedures$.subscribe((data) => {
      if (data) {
        this.procedures = data;
        this.procedure = this.procedures?.find(x => x.procedureMasterID == 41);
        this.procedureAppendix = this.procedure?.procedureAppendices?.find(x => x.text?.includes("Appendix 1a")
        );
      }
    });
  }

  async create() {
    if (await this.canDeactivate()) {
      this.loading = true;
      this.scfService
        .getSerialNo()
        .toPromise()
        .then((data) => {
          if (data) {
            this.status.isCreating = true;
            this.scfMaster = new SCFMaster();
            this.scfMaster.name = data.serialNo;
            this.scfMaster.serialNo = data.serialNo;
            this.scfMaster.status = FormStatusEnum.New;
            this.scfMaster.formStatus = new FormStatus();
            this.scfMaster.createdBy = this.getCurrentUser()?.id;
            this.scfMaster.createdByName = this.getCurrentUser()?.name;
            this.scfMaster.relatedDocuments = [];
            this.scfMaster.createdOn = new Date();
            this.scfMaster.estimatedStartOn = new Date();
            this.scfMaster.estimatedEndOn = new Date();
            this.createdOn = moment(this.scfMaster?.createdOn).format(
              "MMM-DD-YYYY"
            );
            this.scfMaster.editingBy = this.getCurrentUser();
            this.side.scfMaster = this.scfMaster;
            this.side.selectedRowIndex = -1;

            this.loading = true;
            this.scfService
              .create(this.scfMaster)
              .toPromise()
              .then(scfMaster => {
                if (scfMaster) {
                  this.scfMaster = scfMaster;
                  this.loading = true;
                  this.disabled = false;
                  this.checkPrivileges();
                  this.status.isCreating = true;
                  this.status.isEditing = true;
                  if (this.scfMaster) {
                    this.scfMaster.retrievedBy = this.getCurrentUser()?.id;
                    this.side.selectedRowIndex = this.scfMaster?.id;
                  }
                  this.checkButtonsVisible();
                  this.setOpenDocument(FormType.SCF, this.scfMaster.id);
                  this.side.getValues(this.scfMaster);
                }
              });
          }
        });
    }
  }

  async edit() {
    this.loading = true;
    await this.setEditing(true);
    this.status.isEditing = true;
    if (this.scfMaster)
      this.scfMasterUnchanged = utils.cloneDeep(this.scfMaster);
  }

  async editAmendment() {
    this.loading = true;
    await this.setEditing(true);
    this.status.isEditingAmendment = true;
    this.status.isViewingAmendment = true;
    if (this.scfMaster)
      this.scfMasterUnchanged = utils.cloneDeep(this.scfMaster);
  }

  async release() {
    if (await this.canDeactivate() && this.scfMaster && this.scfMasterUnchanged) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: "500px", data: {
          message: this.getMessage("SCF_Release_confirmation")
            ?.description, icon: "stop",
        },
      });
      confirm.afterClosed().subscribe(async (data) => {
        if (data) {
          if (this.scfMaster?.status == FormStatusEnum.New) {
            this.loading = true;
            this.scfService
              .cancel(this.scfMaster, "New Form Canceled")
              .toPromise()
              .then(() => {
                this.loading = false;
                this.scfMaster = null;
              });
          } else {
            this.releaseForm();
          }
        }
      })
    };
  }

  cleanSCFMaster(scfMaster: SCFMaster) {
    let clonedSCFMaster: SCFMaster = new SCFMaster();
    if (scfMaster) {
      clonedSCFMaster = utils.cloneDeep(scfMaster);
      clonedSCFMaster.wfTable = null;
      clonedSCFMaster.editingBy = null;
      clonedSCFMaster.editingById = null;
      clonedSCFMaster.editingUntil = null;
      clonedSCFMaster.updatedOn = null;
      clonedSCFMaster.updatedBy = null;
    }
    return clonedSCFMaster;
  }

  async releaseForm() {
    this.loading = true;
    this.status.isEditing = false;
    this.status.isEditingAmendment = false;
    this.scfMaster = this.scfMasterUnchanged;
    await this.setEditing(false);
  }

  async setEditing(value: boolean) {
    await this.scfService
      .setEditing(this.scfMaster?.id ?? 0, value)
      .toPromise()
      .then(async () => {
        await this.loadSCFs();
      });
    this.disabled = !value;
  }

  @HostListener("window:resize")
  onResize() {
    this.innerWidth = window.innerWidth;
    // if (this.isExpanded != false) {

    // }
    if (this.innerWidth < 768) {
      this.isExpanded = false;
    }
  }

  @HostListener("window:scroll")
  isHeaderOnTop() {
    let elemRec;
    if (this.headerTop?.nativeElement !== undefined) {
      elemRec = this.headerTop.nativeElement.getBoundingClientRect();
      const docViewTop = window.screenTop;
      const elemTop = elemRec.top;
      if (elemTop <= docViewTop) {
        this.headerOnTop = true;
      } else {
        this.headerOnTop = false;
      }
      // this.tableWidth = this.scheduleTable.nativeElement.offsetWidth + 'px';
    } else {
      this.headerOnTop = false;
    }
  }


  //////
  changedFilter() { }
  clearFilter() { }
  selectedFilter() { }
  createDisabled() { }
  editDisabled() { }
  action() { }

  settings() {
    const settings = this.dialog.open(ScfV2SettingsComponent, {
      width: "70vw", minWidth: "70vw", minHeight: "60vh", disableClose: true,
    });
    settings.afterClosed().subscribe(() => {
      this.checkPrivileges();
    });
  }

  applyFilter(e: any) {
    if (!e)
      this.valueFilter = '';
    this.filter = e;
  }

  rowSelected(e: SCFMaster | any) {
    if (e.id != this.scfMaster?.id) this.scrollToTop('sidenav-content');
    this.scfMaster = e as SCFMaster;
    if (
      this.scfMasterUnchanged?.status != this.scfMaster?.status &&
      this.scfMasterUnchanged?.id == this.scfMaster?.id
    ) {
      this.showStatusChangeMessage(this.scfMaster);
    }
    this.scfMasterUnchanged = e ? utils.cloneDeep(e) : null;
    this.checkPrivileges();
    this.createdOn = moment(this.scfMaster?.createdOn).format("MMM-DD-YYYY");
    this.status = new SCFStatus();
    if (this.scfMaster?.status == FormStatusEnum.Amendment) {
      this.status.isEditingAmendment =
        this.scfMaster?.editingBy?.id == this.getCurrentUser()?.id;
      this.status.isViewingAmendment = this.privileges.canViewAmendment;
    } else if (this.scfMaster?.editingBy?.id == this.getCurrentUser()?.id) {
      this.status.isEditing = true;
      this.status.isViewingAmendment = false;
      this.status.isCreating = this.scfMaster.status == FormStatusEnum.New;
    } else {
      this.status.isViewingAmendment = false;
      this.status.isEditing = false;
      this.status.isEditingAmendment = false;
    }
    this.checkDisabled();
    this.showAmendmentToggle = (this.scfMaster?.status == FormStatusEnum.Amendment ||
      this.scfMaster?.status == FormStatusEnum.AmendmentSign ||
      this.scfMaster?.status == FormStatusEnum.RestrictionsAmendment) &&
      this.canSeeAmendment();
    this.checkButtonsVisible();
    this.setHeights();

  }

  buttonsVisible: boolean | null = false;

  checkButtonsVisible() {
    this.buttonsVisible =
      this.status.isCreating ||
      this.status.isEditing ||
      this.status.isEditingAmendment ||
      this.status.isCreatingAmendment ||
      this.status.isViewingAmendment ||
      (this.scfMaster?.status == FormStatusEnum.WorkApprovals &&
        (this.privileges.canUnsubmit || this.privileges.canCancel)) ||
      (this.scfMaster?.status == FormStatusEnum.Pending &&
        this.privileges.canCancel) ||
      (this.scfMaster?.status == FormStatusEnum.Draft &&
        this.privileges.canUnsubmit) ||
      (this.scfMaster?.status == FormStatusEnum.RestrictionsAmendment &&
        this.privileges.canUnsubmitAmendment)
  }

  async changeAmendmentToggle(e: any) {
    if (await this.canDeactivate()) {
      this.status.isViewingAmendment = e.checked;
      this.checkPrivileges();
    }
  }

  canSeeAmendment(): boolean {
    let res = this.hasPrivilege(PrivilegeEnum.SCFSubmit);
    if (res) {
      return true;
    }
    res = this.hasPrivilege(PrivilegeEnum.SCFSubmitAmendment);
    if (res) {
      return true;
    }
    res = this.hasPrivilege(PrivilegeEnum.SCFNewForm);
    if (res) {
      return true;
    }
    res = this.hasPrivilege(PrivilegeEnum.EditSCFAmendment);
    if (res) {
      return true;
    }
    res = this.hasPrivilege(PrivilegeEnum.ViewSCFAmendment);
    if (res) {
      return true;
    }
    res = this.scfMaster?.requesterId == this.getCurrentUser()?.id || this.scfMaster?.scfAmendments?.find(a => a.active)?.requesterId == this.getCurrentUser()?.id;
    if (res) {
      return true;
    }
    return false;
  }

  isRequester() {
    return true;
  }

  checkDisabled() {
    this.disabled = true;
    if (!this.getCurrentUser()) {
      return;
    } else {
      this.disabled = !(
        this.scfMaster?.status === FormStatusEnum.New ||
        this.scfMaster?.status === FormStatusEnum.Draft ||
        (this.scfMaster?.status === FormStatusEnum.Amendment &&
          this.status.isViewingAmendment)
      );
    }
  }

  checkPrivileges() {
    this.checkDisabled();
    const user = this.getCurrentUser();
    const roles = user?.userRole?.map((x) => x.roleID) ?? [];
    const hasAdmin = roles.includes(Roles.SA) || roles.includes(Roles.ADM);
    this.privileges = new SCFPrivileges();
    this.privileges = {
      canCreate: this.hasPrivilege(PrivilegeEnum.SCFNewForm),
      canEdit:
        (this.scfMaster?.status == FormStatusEnum.Draft ||
          this.scfMaster?.status == FormStatusEnum.New) &&
        this.hasPrivilege(PrivilegeEnum.SCFNewForm) &&
        (!this.scfMaster?.editingBy ||
          this.scfMaster?.editingBy?.id == user?.id),
      canSubmit:
        (this.scfMaster?.status == FormStatusEnum.Draft ||
          this.scfMaster?.status == FormStatusEnum.New) &&
        this.hasPrivilege(PrivilegeEnum.SCFSubmit),
      canCancel:
        (this.scfMaster?.status == this.formStatusEnum.Draft ||
          this.scfMaster?.status == FormStatusEnum.New ||
          this.scfMaster?.status == FormStatusEnum.WorkApprovals ||
          this.scfMaster?.status == FormStatusEnum.Pending) &&
        this.hasPrivilege(PrivilegeEnum.SCFSubmit),
      canUnsubmit:
        this.scfMaster?.status == this.formStatusEnum.WorkApprovals &&
        this.hasPrivilege(PrivilegeEnum.SCFSubmit) &&
        this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find((x) => x.number == 2)?.wfSignatures?.filter((x) => x.code != "SUBMIT" && x.signed).length ==
        0,
      canCreateAmendment:
        (this.scfMaster?.status == FormStatusEnum.Active ||
          this.scfMaster?.status == FormStatusEnum.HoldPoint) &&
        this.hasPrivilege(PrivilegeEnum.RequestAmendment),
      canEditAmendment:
        this.scfMaster?.status == FormStatusEnum.Amendment &&
        this.hasPrivilege(PrivilegeEnum.EditSCFAmendment),
      canSubmitAmendment:
        this.scfMaster?.status == FormStatusEnum.Amendment &&
        this.hasPrivilege(PrivilegeEnum.SCFSubmitAmendment),
      canUnsubmitAmendment:
        this.scfMaster?.status == FormStatusEnum.RestrictionsAmendment &&
        this.hasPrivilege(PrivilegeEnum.SCFSubmitAmendment) &&
        !this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == 6)?.wfSignatures?.filter(x =>
          x.amendmentID == this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == 6)?.wfSignatures?.find(x => x.amendmentNumber == this.scfMaster?.scfAmendments?.length
          )?.amendmentID
        )
          .some((x) => x.signed),
      canCancelAmendment:
        (this.scfMaster?.status == FormStatusEnum.Amendment ||
          this.scfMaster?.status == FormStatusEnum.AmendmentSign ||
          this.scfMaster?.status == FormStatusEnum.RestrictionsAmendment) &&
        this.hasPrivilege(PrivilegeEnum.SCFSubmitAmendment),
      canViewAmendment: (this.scfMaster && this.canSeeAmendment()) ?? false,
      canAddHoldPoints:
        (this.scfMaster &&
          this.hasPrivilege(PrivilegeEnum.CreateHoldPoints) &&
          (this.scfMaster.status == FormStatusEnum.Active ||
            this.scfMaster.status == FormStatusEnum.HoldPoint)) ?? false,
      canAddHoldPointsDraft:
        (this.scfMaster &&
          this.hasPrivilege(
            PrivilegeEnum.CreateHoldPointsDraft
          ) &&
          this.scfMaster.status == FormStatusEnum.Draft) ?? false,
      canDuplicate:
        (this.scfMaster &&
          this.hasPrivilege(PrivilegeEnum.CopySCF)) ?? false,
      canRelease:
        ((this.scfMaster &&
          this.scfMaster?.editingBy?.id == user?.id) ||
          hasAdmin) ?? false,
    };
  }

  async duplicate() {
    if (await this.canDeactivate()) {
      const removeRef = this.dialog.open(YesNoDialogComponent, {
        width: "20em", data: {
          message: this.getMessage(
            "Are you sure you want to copy this form"
          ).description, icon: "warn", val: false,
        },
      });
      removeRef
        .afterClosed()
        .toPromise()
        .then((result) => {
          if (result != null && result) {
            const id = this.scfMaster?.id ?? 0;
            this.side.rowUnselected();
            // this.cdRef.detectChanges();
            this.loading = true;
            this.scfService
              .copySCF(id, false)
              .toPromise()
              .then(async (scfMaster) => {
                this.scfMaster = scfMaster;
                // if (this.scfMaster && this.scfMaster?.id) {
                // const notification: NotificationMessage = {
                //   notificationId: NotificationType.CreateSCF,
                //   documentId: this.scfMaster.id,
                //   toAddresses: this.utils.JSONstringify({ computed: (await this.scfService.getEmails(this.scfMaster)).join(', ') }),
                //   locations: this.scfMaster?.locations,
                //   type: 5,
                //   serialNo: this.scfMaster.serialNo,
                //   fromAddress: 'noreply@lbl.gov',
                //   fromName: 'ASCC Notifications',
                //   messageSubject:
                // }
                // this.notificationService.createNotification(notification).toPromise();
                // };
                if (this.scfMaster)
                  await this.side.getValues(this.scfMaster);
                this.loading = false;
              })
              .catch((error) => {
                console.log(error);
                this.alert.message("defaultErrorMessage");
              });
          }
        });
    }
  }

  async requestAmendment() {
    if (await this.canDeactivate()) {
      const pendingChanges = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == 4)?.wfSignatures?.filter(x => !x.wfTaskLocal.amendmentID)?.filter(x => x.answerChangedBy);
      if (!pendingChanges?.length) {
        const confirm = this.dialog.open(YesNoDialogComponent, {
          width: "500px", data: {
            message: this.getMessage("request_amendment")?.description, icon: "warn",
          },
        });
        confirm.afterClosed().subscribe(async (data) => {
          if (data) {
            this.loading = true;
            if (this.scfMaster?.id)
              this.scfService.requestAmendment(this.scfMaster.id).toPromise().then(() => {
                this.status.isCreatingAmendment = true;
                this.status.isViewingAmendment = true;
                this.loading = false;
              }, (error) => {
                console.log(error);
                this.alert.message("defaultErrorMessage");
              }
              );
          }
        });
      } else {
        this.alert.message("OperationApprovalPendingChanges");
      }
    }
  }

  setStatus(status: SCFStatus | any) {
    this.status = status;
  }

  savedEvent(e: SCFMaster | null | undefined | any) {
    this.scfMaster = e;
    this.scfMasterUnchanged = utils.cloneDeep(this.scfMaster);
    // this.scfService.currentDoc = this.scfMaster;
    this.rowSelected(e);
  }

  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 2000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Buttons:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Main:
          this.loadingMain = e as boolean;
          break;
      }
      console.log('m: ' + this.loadingMain + '   b: ' + this.loadingButtons + '   s: ' + this.loadingSide);
      this.loading = this.loadingMain || this.loadingButtons || this.loadingSide;
    }, 100);
  }

  mainValid(): boolean {
    return this.main?.formValid();
  }

  loadingSide = false;
  loadingButtons = false;
  loadingMain = false;
  loadingOrigin = LoadingOrigin;

}
