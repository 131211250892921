import { Component, Input } from '@angular/core';
import { SignResource } from 'src/app/services/logs/signs';

@Component({
  selector: 'scf-log-signature',
  templateUrl: './scf-log-signature.component.html',
  styleUrls: ['./scf-log-signature.component.scss']
})
export class ScfLogSignatureComponent {

  @Input() signature!: SignResource;


  formatText(t?: string) {
    let res: string = '';
    if (t?.includes('Submitted')) {
      res = '<span class="btn ' + (t.includes('Approved') ? 'blue-background' : 'red-background') + '" >' + (t.includes('Approved') ? 'Submit' : 'Unsubmit') + '</span>';
    }
    else {
      if (t?.includes('Work Approvals/') || t?.includes('RWAPI Confirm')) res = this.format('Work Approval');
      else if (t?.includes('Requester')) res = this.format('Requester Concurrence');
      else if (t?.includes('Cancel')) res = this.format('Cancelled');
      else if (t?.includes('Op Restrictions')) res = this.format('Op Restrictions in place');
      else if (t?.includes('Amendment Created')) res = '<span class="btn blue-background">Amendment Request</span>';
      else if (t?.includes('Work Complete')) res = this.format('Work Complete');
      else if (t?.includes('Shielding verified')) res = this.format('Shielding Verified');
      else if (t?.includes('/Shielding inspected')) res = this.format('Shielding Inspected');
      else if (t?.includes('SCF Complete')) res = this.format('SCF Complete');
      else if (t?.includes('Operational Approval/')) res = this.format(t.replace('Operational Approval/', ''));
      else if (t?.includes('Operational Approval/')) res = this.format(t.replace('Operational Approval/', ''));
      else if (t?.includes('No work performed/')) res = this.format(t?.replace('No work performed/', ''));
      else res = this.format(t);
    }
    res = res.replaceAll(' approved', '').replaceAll(' - Approved', '');
    return res;
  }

  format(t?: string): string {
    const type = t?.includes('Unapproved') ? Type.Unapprove : t?.includes('Disaproved') ? Type.Disapprove : Type.Normal;
    switch (type) {
      case Type.Unapprove:
        return '<b class="yellow">' + t + ' <span class="btn small-btn white yellow-background">Unnaproved</span></b>'
      case Type.Disapprove:
        return '<b>' + t + ' <span class="btn small-btn white yellow-background">Disapproved</span></b>';
      default:
    }
    return '<b>' + t + '</b>';
  }

  cancelled(signature: SignResource) {
    return signature.reason &&
      !signature.name?.includes('Unsubmit') &&
      !signature.name?.includes('Disapproved')
  }
}

enum Type {
  Normal = 0,
  Cancel = 1,
  Unapprove = 2,
  Disapprove = 3
}
