<div class="mat-container left-table-container">
  <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Name
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="text-break" [ngClass]="service.getCategoryClass(element)">
        {{ element.name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="category">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Category
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="text-break" [ngClass]="service.getCategoryClass(element)">
        {{ service.getCategoryName(element) }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Description
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="text-break" [ngClass]="service.getCategoryClass(element)">
        {{ element.description }}
      </mat-cell>
    </ng-container>

    <mat-header-row #header class="p-0" *matHeaderRowDef="displayedColumnsDocumentType">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumnsDocumentType" [ngClass]="{
        'mat-row-highlight': documentTypeSelectedIndex == row.id
      }" (click)="getDocumentType(row)">
    </mat-row>
  </mat-table>
</div>