<div class="container-fluid pt-3">
  Template Types

  <div class="mat-container">
    <mat-table #table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Template Name </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef>
          Template Description
        </mat-header-cell>
        <mat-cell *matCellDef="let element"
          >{{ element.description }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="code">
        <mat-header-cell *matHeaderCellDef> Template Code </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.code }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="add" *ngIf="canEdit">
        <mat-header-cell *matHeaderCellDef>
          <button
            mat-icon-button
            color="accent"
            (click)="templateTypeDialog()"
            *ngIf="hasPrivilege(privilegeEnum.CreateTemplateType)"
          >
            <mat-icon>add</mat-icon>
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let elementIndex = index">
          <button
            mat-icon-button
            color="accent"
            (click)="templateTypeDialog(element)"
            *ngIf="hasPrivilege(privilegeEnum.UpdateTemplateType)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            (click)="delete(element)"
            [disabled]="element.checklistTemplates?.length > 0"
            *ngIf="hasPrivilege(privilegeEnum.DeleteTemplateType)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumnsTemplateType"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumnsTemplateType"
        [hidden]="row.deleteReason"
      ></mat-row>
    </mat-table>
  </div>
</div>
