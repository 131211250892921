import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Note } from 'src/app/services/notes/note.model';


@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent extends BaseComponent implements OnInit {

  public noteName?: string;

  public isSaving!: boolean;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<NoteComponent>,
    @Inject(MAT_DIALOG_DATA) public note: Note,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.noteName = this.note.noteName;
  }

  add() {
    this.isSaving = true;
    if (this.isValid()) {
      this.note.noteName = this.noteName;
      this.dialogRef.close(this.note);
    } else {
      this.isSaving = false;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  isValid() {
    if (!this.noteName) {
      this.alert.message('noteDialog_Required');
      return false;
    }
    return true;
  }

}
