import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProcedureType } from '../models/procedure-type';

@Injectable({
  providedIn: 'root'
})
export class ProcedureTypeService {
  private url: string = environment.urls.baseApiUrl + '/ProcedureTypes';

  constructor(
    private http: HttpClient,
  ) { }

  public create(obj: ProcedureType): Observable<ProcedureType> {
    return this.http.post<ProcedureType>(this.url, obj);
  }

  public read(): Observable<ProcedureType[]> {
    return this.http.get<ProcedureType[]>(this.url);
  }

  public update(id: number, obj: ProcedureType): Observable<ProcedureType> {
    return this.http.put<ProcedureType>(this.url + '/' + id.toString(), obj);
  }

  public delete(id: number): Observable<any> {
    return this.http.delete<any>(this.url + '/' + id.toString());
  }
}
