<div
  class="table-container mat-container scrollbar"
  [ngClass]="{ hilite: hilite }"
>
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="text">
      <mat-header-cell *matHeaderCellDef class="pl-16 w-100">
        {{ customTitle }}
      </mat-header-cell>
      <mat-cell
        class="font-weight-bold pl-16 w-100"
        *matCellDef="let element"
        nowrap
        >{{
          element.restriction
            ? element.restriction.restrictionLanguage
            : element.text
        }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="max-50 text-right">
      </mat-header-cell>
      <mat-cell
        class="pl-1 max-50 d-print-none"
        *matCellDef="let element; let i = index"
      >
        <button
          type="button"
          mat-icon-button
          (click)="delete(element)"
          *ngIf="!disabled"
        >
          <mat-icon color="warn">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      #matrow
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{
        disabled: disabled,
        background: row.restriction,
        hilite: row.hilite
      }"
    ></mat-row>
  </mat-table>
</div>
<restriction-available-autocomplete
  *ngIf="!disabled"
  [formType]="formType"
  [disabled]="disabled"
  [formRestrictions]="restrictions"
  [placeholder]="placeholder"
  [control]="control"
  [locationIDs]="locationIDs"
  (onRestrictionSelected)="selected($event)"
  (onFreeTextRestrictionWritten)="added($event)"
></restriction-available-autocomplete>
