import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from '../../app-routing.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppComponent } from 'src/app/app.component';
import { ChecklistComponentsModule } from 'src/app/controls/checklist-components/checklist-components.module';
import { SharedModule } from 'src/app/modules/shared.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTreeModule } from '@angular/material/tree';
import { ResourcesNewComponent } from './beamline-catalog/resources-new/resources-new.component';
import { RssTestDatabaseComponent } from './beamline-catalog/rss-test-database/rss-test-database.component';
import { ResourceDetailsNewComponent } from './beamline-catalog/resource-details-new/resource-details-new.component';
import { RestrictionTableComponent } from './beamline-catalog/controls/restriction-table/restriction-table.component';
import { ResourcesListNewComponent } from './beamline-catalog/resources-list-new/resources-list-new.component';
import { KenableModule } from '../kenable/kenable.module';
import { VacuumComponent } from './beamline-catalog/dialogs/vacuum/vacuum.component';
import { ShieldingComponent } from './beamline-catalog/dialogs/shielding/shielding.component';
import { RestrictionComponent } from './beamline-catalog/dialogs/restriction/restriction.component';
import { OrganizationComponent } from './beamline-catalog/dialogs/organization/organization.component';
import { NoteComponent } from './beamline-catalog/dialogs/note/note.component';
import { FastValveSensorComponent } from './beamline-catalog/dialogs/fast-valve-sensor/fast-valve-sensor.component';
import { EndstationComponent } from './beamline-catalog/dialogs/endstation/endstation.component';
import { BranchlineComponent } from './beamline-catalog/dialogs/branchline/branchline.component';
import { UnsavedChangesGuard } from 'src/app/common/guards/unsaved-changes.guard';
import { BeamlineCatalogComponent } from './beamline-catalog/beamline-catalog.component';
import { ResourceUserRelationComponent } from './beamline-catalog/dialogs/resource-user-relation/resource-user-relation.component';
import { LinkComponent } from './beamline-catalog/dialogs/link/link.component';
import { RadMonitorModule } from './rad-monitor/rad-monitor.module';
import { RadMonitorComponent } from './rad-monitor/rad-monitor.component';
import { AcceleratorCatalogComponent } from './accelerator-catalog/accelerator-catalog.component';
import { AcceleratorCatalogDetailsComponent } from './accelerator-catalog/accelerator-catalog-details/accelerator-catalog-details.component';
import { AcceleratorShieldingComponent } from './accelerator-catalog/accelerator-catalog-details/accelerator-shielding/accelerator-shielding.component';
import { AcceleratorCatalogListComponent } from './accelerator-catalog/accelerator-catalog-list/accelerator-catalog-list.component';
import { AcceleratorShieldingDialogComponent } from './accelerator-catalog/accelerator-catalog-details/accelerator-shielding/accelerator-shielding-dialog/accelerator-shielding-dialog.component';
import { UserCatalogComponent } from './user-catalog/user-catalog.component';
import { UserCatalogListComponent } from './user-catalog/user-catalog-list/user-catalog-list.component';
import { UserRoleComponent } from './user-catalog/user-catalog-details/user-catalog-roles/user-role/user-role.component';
import { UserCatalogRolesComponent } from './user-catalog/user-catalog-details/user-catalog-roles/user-catalog-roles.component';
import { UserCatalogDetailsComponent } from './user-catalog/user-catalog-details/user-catalog-details.component';
import { DocumentTypeComponent } from './document-type/document-type.component';
import { DocumentTypeListComponent } from './document-type/document-type-list/document-type-list.component';
import { DocumentTypeDetailsComponent } from './document-type/document-type-details/document-type-details.component';
import { DocumentTypeTabComponent } from './document-type/document-type-details/document-type-tab/document-type-tab.component';
import { TemplateTypeTabComponent } from './document-type/document-type-details/template-type-tab/template-type-tab.component';
import { AddEditTemplateTypeComponent } from './document-type/document-type-details/dialogs/add-edit-template-type/add-edit-template-type.component';
import { OlogCategoryTabComponent } from './document-type/document-type-details/olog-category-tab/olog-category-tab.component';
import { AddEditOlogCategoryComponent } from './document-type/document-type-details/dialogs/add-edit-olog-category/add-edit-olog-category.component';
import { OlogEmailTabComponent } from './document-type/document-type-details/olog-email-tab/olog-email-tab.component';
import { AddEditOlogEmailComponent } from './document-type/document-type-details/dialogs/add-edit-olog-email/add-edit-olog-email.component';
import { NavigationLinksComponent } from './navigation-links/navigation-links.component';
import { NavigationLinksModule } from './navigation-links/navigation-links.module';
import { MetersCatalogComponent } from './meters-catalog/meters-catalog.component';
import { MetersCatalogModule } from './meters-catalog/meters-catalog.module';
import { ScheduleTypesComponent } from './schedule-types/schedule-types.component';
import { ScheduleTypesModule } from './schedule-types/schedule-types.module';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsSideComponent } from './notifications/notifications-side/notifications-side.component';
import { NotificationsDetailsComponent } from './notifications/notifications-details/notifications-details.component';
import { BeamlineStaffComponent } from './beamline-staff/beamline-staff.component';
import { BeamlineStaffSidePanelComponent } from './beamline-staff/beamline-staff-side-panel/beamline-staff-side-panel.component';
import { BeamlineStaffMainPanelComponent } from './beamline-staff/beamline-staff-main-panel/beamline-staff-main-panel.component';
import { ResourceUserRelationTableComponent } from './beamline-catalog/controls/resource-user-relation-table/resource-user-relation-table.component';
import { ResourceLinkRelationComponent } from './beamline-catalog/controls/resource-link-relation/resource-link-relation.component';
import { AcceleratorLocationComponent } from './accelerator-catalog/dialogs/location/location.component';
import { RssdListComponent } from './rssd-list/rssd-list.component';
import { RssdListSideComponent } from './rssd-list/rssd-list-side/rssd-list-side.component';
import { RssdListMainComponent } from './rssd-list/rssd-list-main/rssd-list-main.component';
import { RolesComponent } from './roles/roles.component';
import { RedirectGuard } from 'src/app/common/guards/redirect.guard';


const appRoutes: Routes = [
  { path: 'resources-new', component: BeamlineCatalogComponent, canDeactivate: [UnsavedChangesGuard, RedirectGuard] },
  { path: 'resources-new/:tab', component: BeamlineCatalogComponent, canDeactivate: [UnsavedChangesGuard, RedirectGuard] },
  { path: 'meters-catalog', component: MetersCatalogComponent, canDeactivate: [UnsavedChangesGuard, RedirectGuard] },
  { path: 'rad-monitor', component: RadMonitorComponent, canDeactivate: [UnsavedChangesGuard, RedirectGuard] },
  { path: 'accelerator-catalog', component: AcceleratorCatalogComponent, canDeactivate: [UnsavedChangesGuard, RedirectGuard] },
  { path: 'users', component: UserCatalogComponent, canDeactivate: [UnsavedChangesGuard, RedirectGuard] },
  { path: 'document-type-catalog', component: DocumentTypeComponent, canDeactivate: [UnsavedChangesGuard, RedirectGuard] },
  { path: 'link', component: NavigationLinksComponent, canDeactivate: [UnsavedChangesGuard, RedirectGuard] },
  { path: 'schedule-types', component: ScheduleTypesComponent, canDeactivate: [UnsavedChangesGuard, RedirectGuard] },
  { path: 'email-notification-catalog', component: NotificationsComponent, canDeactivate: [UnsavedChangesGuard, RedirectGuard] },
  { path: 'beam-staff', component: BeamlineStaffComponent, canDeactivate: [UnsavedChangesGuard, RedirectGuard] },
  { path: 'notification-templates', component: NotificationsComponent, canDeactivate: [UnsavedChangesGuard, RedirectGuard] },
  { path: 'rssd-list', component: RssdListComponent },
  { path: 'roles/:roleID', component: RolesComponent, canDeactivate: [UnsavedChangesGuard, RedirectGuard] }
];

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

@NgModule({
  declarations: [
    BeamlineCatalogComponent,
    ResourcesNewComponent,
    ResourcesListNewComponent,
    ResourceDetailsNewComponent,
    RssTestDatabaseComponent,

    RestrictionTableComponent,

    VacuumComponent,
    ShieldingComponent,
    RestrictionComponent,
    OrganizationComponent,
    NoteComponent,
    FastValveSensorComponent,
    EndstationComponent,
    BranchlineComponent,

    ResourceUserRelationTableComponent,
    ResourceUserRelationComponent,
    ResourceLinkRelationComponent,
    LinkComponent,

    AcceleratorCatalogComponent,
    AcceleratorCatalogListComponent,
    AcceleratorCatalogDetailsComponent,
    AcceleratorShieldingComponent,
    AcceleratorLocationComponent,
    AcceleratorShieldingDialogComponent,

    UserCatalogComponent,
    UserCatalogListComponent,
    UserCatalogDetailsComponent,
    UserCatalogRolesComponent,
    UserRoleComponent,

    DocumentTypeComponent,
    DocumentTypeListComponent,
    DocumentTypeDetailsComponent,
    DocumentTypeTabComponent,
    TemplateTypeTabComponent,
    AddEditTemplateTypeComponent,
    OlogCategoryTabComponent,
    AddEditOlogCategoryComponent,
    OlogEmailTabComponent,
    AddEditOlogEmailComponent,


    NotificationsComponent,
    NotificationsSideComponent,
    NotificationsDetailsComponent,


    BeamlineStaffComponent,
    BeamlineStaffSidePanelComponent,
    BeamlineStaffMainPanelComponent,
    RssdListComponent,
    RssdListSideComponent,
    RssdListMainComponent,

  ],

  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    HttpClientModule,
    ScrollingModule,
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatRadioModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    DragDropModule,
    MatTreeModule,
    MatExpansionModule,
    MatOptionModule,
    MatSidenavModule,
    DatePipe,
    MatToolbarModule,
    // SavingModule,
    QuillModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: true,
        useHash: true,
        anchorScrolling: 'enabled',
        preloadingStrategy: PreloadAllModules,
      }
    ),

    SharedModule,
    ChecklistComponentsModule,
    KenableModule,
    RadMonitorModule,
    NavigationLinksModule,
    MetersCatalogModule,
    ScheduleTypesModule
  ],
  exports: [
    CommonModule,
    MatButtonModule,
    MatNativeDateModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    RestrictionTableComponent
  ],
  providers: [

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CatalogsModule {
}
