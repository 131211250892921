<div class="card-header">
  <mat-progress-bar mode="indeterminate" *ngIf="saving"></mat-progress-bar>
  <h5>
    Bulletin Board <span class="small">{{ subtitle }}</span>
  </h5>
</div>
<div class="card-body">
  <mat-form-field hintLabel="Max 1000 characters" appearance="outline">
    <mat-label>Description</mat-label>
    <textarea
      [disabled]="saving"
      required
      rows="3"
      maxlength="1000"
      [(ngModel)]="Description"
      matInput
    ></textarea>
    <mat-hint align="end">{{ Description.length || 0 }}/1000</mat-hint>
    <mat-error *ngIf="Description?.length == 0 || !Description"
      >You must enter a value</mat-error
    >
  </mat-form-field>
  <mat-form-field>
    <mat-label>Expiration date</mat-label>
    <input
      [disabled]="saving"
      matInput
      [(ngModel)]="ExpirationDate"
      [matDatepicker]="picker"
    />
    <button
      [disabled]="saving"
      mat-button
      *ngIf="ExpirationDate"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="ExpirationDate = null"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-datepicker-toggle
      [disabled]="saving"
      matSuffix
      [for]="picker"
    ></mat-datepicker-toggle>
    <mat-datepicker [disabled]="saving" #picker></mat-datepicker>
  </mat-form-field>
</div>
<div class="card-footer">
  <div align="right">
    <button
      mat-flat-button
      [disabled]="saving"
      color="primary"
      class="col-3"
      (click)="Save()"
    >
      Save
    </button>
  </div>
</div>
