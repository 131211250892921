import { PPSTBFollowUpType } from "../schedules/models/enums";

export class PPSTBOtherFollowUp {
  public id?: number;
  public ppstbID?: number;
  public text?: string;
  public signedBy?: number;
  public signedOn?: Date;
  public type?: PPSTBFollowUpType;
}

export class PPSTBOtherFollowUpSave {
  public id?: number;
  public ppstbID?: number;
  public text?: string;
  public signedBy?: number;
  public signedOn?: Date;
  public type?: PPSTBFollowUpType;
}

export class PPSTBOtherFollowUpHandler {
  static toSave(ppstbOtherFollowUp: PPSTBOtherFollowUp): PPSTBOtherFollowUpSave {
    const save = new PPSTBOtherFollowUp();

    save.id = ppstbOtherFollowUp.id;
    save.ppstbID = ppstbOtherFollowUp.ppstbID;
    save.text = ppstbOtherFollowUp.text;
    save.type = ppstbOtherFollowUp.type;

    return save;
  }
}
