<div class="mat-container">
  <mat-table #table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Role Name </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.name }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
      <mat-cell *matCellDef="let element" class="pl-2 pb-2">
        <app-role-labels [roleCode]="element.code"></app-role-labels>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="add">
      <mat-header-cell *matHeaderCellDef>
        <mat-icon mat-list-icon class="add" (click)="openAddUserRole()">
          add
        </mat-icon>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button
          mat-mini-fab
          color="warn"
          aria-label="Example icon button with a delete icon"
        >
          <mat-icon (click)="deletUserRole(element)">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
