<table
  mat-table
  [dataSource]="procedureDS"
  matSort
  (matSortChange)="sortData($event)"
>
  <ng-container matColumnDef="procedureNumber" sticky>
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span [innerHTML]="labelNumber"></span>
    </th>
    <td
      mat-cell
      *matCellDef="let e"
      class="pr-2"
      (click)="openViewModeDialog(e)"
    >
      <span *ngIf="!e.fileID" [ngClass]="{ inactive: e.status == 0 }"
        ><span
          class="bold"
          [innerHTML]="e.procedureNumber | highlightSearch : filter"
        ></span
      ></span>
      <a
        *ngIf="e.fileID"
        [ngClass]="{ inactive: e.status == 0 }"
        href="{{ e.file.url }}"
        target="_blank"
        ><span
          class="bold"
          [innerHTML]="e.procedureNumber | highlightSearch : filter"
        ></span
      ></a>
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span [innerHTML]="labelTitleLocationGlobal"></span>
    </th>
    <td mat-cell *matCellDef="let e" class="pr-2">
      <span [ngClass]="{ inactive: e.status == 0 }"
        ><span
          class="bold"
          [innerHTML]="e.title | highlightSearch : filter"
        ></span></span
      ><br />
      <ng-container *ngFor="let appendix of sortedAppendices(e)">
        <span
          *ngIf="!appendix.file && !appendix.url"
          [ngClass]="{ inactive: e.status == 0 }"
          ><span [innerHTML]="appendix.text | highlightSearch : filter"></span
        ></span>
        <a
          *ngIf="
            (appendix.file == null || appendix.file == undefined) &&
            appendix.url != null &&
            (appendix.url.toLowerCase().includes('ascc.als.lbl.gov') ||
              appendix.url.toLowerCase().includes('ascc-stg.als.lbl.gov'))
          "
          [ngClass]="{ inactive: e.status == 0 }"
          href="{{ appendix.url }}"
          ><span [innerHTML]="appendix.text | highlightSearch : filter"></span
        ></a>
        <a
          *ngIf="
            (appendix.file == null || appendix.file == undefined) &&
            appendix.url != null &&
            !(
              appendix.url.toLowerCase().includes('ascc.als.lbl.gov') ||
              appendix.url.toLowerCase().includes('ascc-stg.als.lbl.gov')
            )
          "
          [ngClass]="{ inactive: e.status == 0 }"
          href="{{ appendix.url }}"
          target="_blank"
          ><span [innerHTML]="appendix.text | highlightSearch : filter"></span
        ></a>
        <a
          *ngIf="appendix.file"
          [ngClass]="{ inactive: e.status == 0 }"
          href="{{ appendix.file.url }}"
          target="_blank"
          ><span [innerHTML]="appendix.text | highlightSearch : filter"></span
        ></a>
        <br />
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="location">
    <th mat-header-cell *matHeaderCellDef>Location</th>
    <td mat-cell *matCellDef="let e" class="pr-2">
      <span [ngClass]="{ inactive: e.status == 0 }"
        ><b>{{ e.locationFreeText }}</b></span
      ><br />
      <ng-container *ngFor="let appendix of e.procedureAppendices">
        <span
          *ngIf="
            (appendix.file == null || appendix.file == undefined) &&
            appendix.url == null
          "
          [ngClass]="{ inactive: e.status == 0 }"
          ><span [innerHTML]="appendix.text | highlightSearch : filter"></span
        ></span>
        <a
          *ngIf="
            (appendix.file == null || appendix.file == undefined) &&
            appendix.url != null
          "
          [ngClass]="{ inactive: e.status == 0 }"
          href="{{ appendix.url }}"
          target="_blank"
          ><span [innerHTML]="appendix.text | highlightSearch : filter"></span
        ></a>
        <a
          *ngIf="appendix.file != null && appendix.file != undefined"
          [ngClass]="{ inactive: e.status == 0 }"
          href="{{ appendix.file?.url }}"
          target="_blank"
          ><span [innerHTML]="appendix.text | highlightSearch : filter"></span
        ></a>
        <br />
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="revision">
    <th mat-header-cell *matHeaderCellDef>
      <span [innerHTML]="labelRevision"></span>
    </th>
    <td mat-cell *matCellDef="let e">
      <span [ngClass]="{ inactive: e.status == 0 }">{{
        calculateRevision(e.revision)
      }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="template">
    <th mat-header-cell *matHeaderCellDef>
      <span>Checklist Template</span>
    </th>
    <td mat-cell *matCellDef="let e">
      <waiting-text *ngIf="!checklistTemplates?.length"></waiting-text>
      <span [ngClass]="{ inactive: e.status == 0 }">
        <a href="#/redirect/{{ e.checklistTemplate?.serialNo }}">
          {{ e.checklistTemplate?.serialNo }}</a
        >
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="procedureCategory">
    <th class="header-title" mat-header-cell *matHeaderCellDef colspan="20">
      <span [innerHTML]="procedureCategory?.name"></span>
    </th>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="title; sticky: true"></tr>
  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: columns"
    [ngClass]="{ hidden: !showInactive && row.status == 0 }"
  ></tr>
</table>
