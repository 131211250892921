import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RadLocationInterlockRelationRefresh, RadLocationInterlockRelationCreate, RadLocationInterlockRelationUpdate, RadLocationInterlockRelationDelete } from './rad-interlock-relation.action';
import { RadInterlockService } from '../../services/rad-interlock.service';
import { RadLocationInterlockRelation } from "src/app/components/catalogs/rad-monitor/rad-monitor/rad-location-interlock-relation";

export class RadLocationInterlockRelationStateModel {
  data: RadLocationInterlockRelation[] = [];
}

@State<RadLocationInterlockRelationStateModel>({
  name: 'RadLocationInterlockRelation',
  defaults: {
    data: []
  }
})
@Injectable()
export class RadLocationInterlockRelationState {
  constructor(
    private radLocationInterlockRelationService: RadInterlockService
  ) { }

  @Selector()
  static read(state: RadLocationInterlockRelationStateModel) {
    return state.data;
  }

  @Action(RadLocationInterlockRelationRefresh)
  refresh({ patchState }: StateContext<RadLocationInterlockRelationStateModel>) {
    this.radLocationInterlockRelationService.GetRadInterlockRelations().toPromise().then(data => {
      patchState({ data });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(RadLocationInterlockRelationCreate)
  create({ getState, patchState }: StateContext<RadLocationInterlockRelationStateModel>, { item }: RadLocationInterlockRelationCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(RadLocationInterlockRelationUpdate)
  update({ getState, patchState }: StateContext<RadLocationInterlockRelationStateModel>, { id, item }: RadLocationInterlockRelationUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    const currentState = state.data;

    if (index >= 0) {
      currentState.splice(index, 1, item);
      patchState({ data: currentState });
    }
  }

  @Action(RadLocationInterlockRelationDelete)
  delete({ getState, patchState }: StateContext<RadLocationInterlockRelationStateModel>, { id }: RadLocationInterlockRelationDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
