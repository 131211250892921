import { ENTER, TAB } from '@angular/cdk/keycodes';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Injector, OnChanges, SimpleChanges, Input, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { TemplateType } from 'src/app/components/checklists/checklists';
import { ProcedureType } from 'src/app/components/procedure/models/procedure-type';
import { Schedule } from 'src/app/components/schedules/models/schedule';
import { ScheduleSubtype } from 'src/app/components/schedules/models/schedule-subtype';
import { ScheduleType } from 'src/app/components/schedules/models/schedule-type';
import { ScheduleTypeService } from 'src/app/components/schedules/services/schedule-type.service';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { DocumentType } from 'src/app/services/documents/documents';
import { ResourceType } from 'src/app/components/catalogs/beamline-catalog/resource/resources';

@Component({
  selector: 'schedule-types-details',
  templateUrl: './schedule-types-details.component.html',
  styleUrls: ['./schedule-types-details.component.scss']
})
export class ScheduleTypesDetailsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() scheduleType?: ScheduleType | null;

  @Output() selectedScheduleSubtype = new EventEmitter();

  scheduleTypeTemp?: ScheduleType | null;

  adding!: boolean;
  modifying!: boolean;
  disabled!: boolean;

  today: number = Date.now();
  dateFormat?: string;

  procedureTypes!: ProcedureType[];
  procedureTypes$!: Observable<ProcedureType[]>;
  procedureTypesSubs!: Subscription;

  documentTypes!: DocumentType[];
  documentTypes$!: Observable<DocumentType[]>;
  documentTypesSubs!: Subscription;

  scheduleResourceTypes!: ResourceType[];

  resourceTypes!: ResourceType[];
  resourceTypesFiltered!: ResourceType[];
  resourceTypes$!: Observable<ResourceType[]>;
  resourceTypesSubs!: Subscription;

  templateTypes!: TemplateType[];
  templateTypes$!: Observable<TemplateType[]>;
  templateTypesSubs!: Subscription;

  schedules!: Schedule[];
  schedules$!: Observable<Schedule[]>;
  schedulesSubs!: Subscription;

  disableDelete?: boolean;

  public get nameCtrl(): AbstractControl | null { return this.formGroup.get('nameCtrl'); }
  public get descriptionCtrl(): AbstractControl | null { return this.formGroup.get('descriptionCtrl'); }
  public get acronymCtrl(): AbstractControl | null { return this.formGroup.get('acronymCtrl'); }
  public get activeToggleCtrl(): AbstractControl | null { return this.formGroup.get('activeToggleCtrl'); }
  public get tabsToggleCtrl(): AbstractControl | null { return this.formGroup.get('tabsToggleCtrl'); }
  public get graceTimeCtrl(): AbstractControl | null { return this.formGroup.get('graceTimeCtrl'); }
  public get dueDateCtrl(): AbstractControl | null { return this.formGroup.get('dueDateCtrl'); }
  public get documentTypeCtrl(): AbstractControl | null { return this.formGroup.get('documentTypeCtrl'); }
  public get procedureTypeCtrl(): AbstractControl | null { return this.formGroup.get('procedureTypeCtrl'); }
  public get resourceTypeCtrl(): AbstractControl | null { return this.formGroup.get('resourceTypeCtrl'); }
  public get templateTypeCtrl(): AbstractControl | null { return this.formGroup.get('templateTypeCtrl'); }
  public get dateFormatCtrl(): AbstractControl | null { return this.formGroup.get('dateFormatCtrl'); }
  public get customToggleCtrl(): AbstractControl | null { return this.formGroup.get('customToggleCtrl'); }

  @ViewChild('autoInput') autoInput!: ElementRef<HTMLInputElement>;

  constructor(
    protected override injector: Injector,
    private service: ScheduleTypeService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initializeForm();
    this.loadProcedureTypes();
    this.loadDocumentTypes();
    this.loadResourceTypes();
    this.loadTemplateTypes();
    this.loadSchedules();
    this.getDetails();
  }

  override ngOnDestroy(): void {
    this.procedureTypesSubs?.unsubscribe();
    this.documentTypesSubs?.unsubscribe();
    this.resourceTypesSubs?.unsubscribe();
    this.templateTypesSubs?.unsubscribe();
    this.schedulesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadProcedureTypes();
    this.loadDocumentTypes();
    this.loadResourceTypes();
    this.loadTemplateTypes();
    this.loadSchedules();
    this.getDetails();
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      nameCtrl: [{ value: null }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      descriptionCtrl: [{ value: null }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      acronymCtrl: [{ value: null }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      activeToggleCtrl: [{ value: false }],
      graceTimeCtrl: [{ value: 30 }, [Validators.pattern('^[0-9]*$'), Validators.required]],
      dueDateCtrl: [{ value: 12 }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm, Validators.pattern('^[0-9]*$')]],
      documentTypeCtrl: [{ value: false }],
      procedureTypeCtrl: [{ value: null }],
      tabsToggleCtrl: [{ value: false }],
      resourceTypeCtrl: [{ value: null }],
      templateTypeCtrl: [{ value: null }],
      dateFormatCtrl: [{ value: 'shortDate' }],
      customToggleCtrl: [{ value: false }]
    });
    this.nameCtrl?.setValue(null);
    this.descriptionCtrl?.setValue(null);
    this.acronymCtrl?.setValue(null);
    this.graceTimeCtrl?.setValue(null);
    this.dueDateCtrl?.setValue(null);
    this.validateForm();
  }

  override getErrorMsg(control: AbstractControl | null): string | null {
    if (control?.hasError('required')) { return 'You must enter a value'; }
    if (control?.hasError('hasWhiteSpace')) { return 'Enter a valid value'; }
    if (control?.hasError('resourceType')) { return 'You must select a Resource Type'; }
    if (control?.hasError('scheduleType')) { return 'You must select a Schedule Type'; }
    if (control?.hasError('scheduleSubtype')) { return 'You must select a Schedule Subtype'; }
    if (control?.hasError('numeric')) { return 'This value shuld be numeric'; }
    return null;
  }

  getDetails() {
    this.modifying = false;
    if (this.scheduleType) {
      this.adding = this.scheduleType?.id == 0;
      this.scheduleTypeTemp = this.utils.cloneDeep(this.scheduleType);
      this.nameCtrl?.setValue(this.scheduleType?.name);
      this.descriptionCtrl?.setValue(this.scheduleType?.description);
      this.acronymCtrl?.setValue(this.scheduleType?.acronym);
      this.activeToggleCtrl?.setValue(this.scheduleType?.isActive);
      this.graceTimeCtrl?.setValue(this.scheduleType?.graceTime);
      this.dueDateCtrl?.setValue(this.scheduleType?.nextDueDateTime);
      this.documentTypeCtrl?.setValue(this.scheduleType?.documentTypeId);
      this.procedureTypeCtrl?.setValue(this.scheduleType?.procedureTypeID);
      this.tabsToggleCtrl?.setValue(this.scheduleType?.tabColumns);
      this.dateFormatCtrl?.setValue(this.scheduleType?.dateFormat);
      this.customToggleCtrl?.setValue(this.scheduleType?.allowCustomSchedules);
      this.dateFormat = this.scheduleType?.dateFormat;
      this.getRelatedInfo();
      this.filterResourceTypes();
      this.validateForm();
    }
    else {
      this.formGroup.reset();
    }
  }

  getRelatedInfo() {
    this.getTemplateType();
    this.getSchedules();
  }

  getTemplateType() {
    if (!this.scheduleType?.id) {
      this.templateTypeCtrl?.setValue(null);
    }
    else if (this.scheduleType.id == 1) {
      this.templateTypeCtrl?.setValue('ABSI - Universal');
    }
    else {
      this.templateTypeCtrl?.setValue(this.templateTypes?.find(x => x.scheduleTypeID == this.scheduleType?.id && x.scheduleSubtypeID == null)?.name);
    }
  }

  getSchedules() {
    if (this.scheduleType) {
      this.scheduleType.schedules = this.schedules?.filter(x => x.typeId == this.scheduleType?.id);
    }
  }

  validateForm() {
    if (!(this.adding || this.modifying)) {
      this.formGroup.markAsPristine();
      this.formGroup.disable();
      this.resourceTypeCtrl?.disable();
      this.disabled = true;
    }
    else {
      this.formGroup.enable();
      this.resourceTypeCtrl?.enable();
      this.disabled = false;
      this.setFormDirty(false);
      this.formGroup.valueChanges.subscribe(() => {
        this.setFormDirty(this.formGroup.dirty);
      })
    }
    this.disableDelete =
      !(!this.scheduleType?.scheduleSubtypes?.length && !this.scheduleType?.schedules?.length);
    this.templateTypeCtrl?.disable();
  }

  loadSchedules() {
    this.schedules$ = this.store.select(state => state.Schedules.data);
    this.schedulesSubs = this.schedules$.subscribe(data => {
      this.schedules = data;
    });
  }

  loadTemplateTypes() {
    this.templateTypes$ = this.store.select(state => state.TemplateTypes.data);
    this.templateTypesSubs = this.templateTypes$.subscribe(data => {
      this.templateTypes = data;
    });
  }

  loadDocumentTypes() {
    this.documentTypes$ = this.store.select(state => state.DocumentType.data);
    this.documentTypesSubs = this.documentTypes$.subscribe(data => {
      this.documentTypes = data.filter(x => x.description && !x.type);
      if (!this.documentTypes.map(x => x.id).includes(0)) {
        this.documentTypes.unshift({ id: 0, description: '[None]' } as DocumentType);
      }
    });
  }

  loadProcedureTypes() {
    this.procedureTypes$ = this.store.select(state => state.ProcedureType.data);
    this.procedureTypesSubs = this.procedureTypes$.subscribe(data => {
      this.procedureTypes = data;
      if (!this.procedureTypes.map(x => x.id).includes(0)) {
        this.procedureTypes.unshift({ id: 0, name: '[None]' } as ProcedureType);
      }
    });
  }

  loadResourceTypes() {
    this.resourceTypes$ = this.store.select(state => state.ResourceTypes.data);
    this.resourceTypesSubs = this.resourceTypes$.subscribe(data => {
      this.resourceTypes = data;
    });
  }

  changedResourceType(e: any) {
    const text = e.target.value;
    this.filterResourceTypes();
    this.resourceTypesFiltered = this.resourceTypesFiltered.filter(x => x.name.toLowerCase().includes(text.toLowerCase()));
  }

  filterResourceTypes() {
    let resourceTypeArray: number[] = [];
    resourceTypeArray = this.scheduleType?.resourceTypeIds ? JSON.parse(this.scheduleType?.resourceTypeIds).map(Number) : new Array();
    this.scheduleResourceTypes = [];
    resourceTypeArray.map(id => {
      const resourceType = this.resourceTypes.find(x => x.id == id);
      if (resourceType)
        this.scheduleResourceTypes.push(resourceType);
    });
    this.resourceTypesFiltered = this.resourceTypes.filter(x => !this.scheduleResourceTypes.map(t => t.id).includes(x.id));
  }

  removeResourceType(resourceType: ResourceType) {
    this.scheduleResourceTypes.splice(this.scheduleResourceTypes.findIndex(x => x.id == resourceType.id), 1);
    this.formGroup.markAsDirty();
  }

  modify() {
    this.modifying = true;
    this.validateForm();
  }

  reset() {
    this.adding = false;
    this.modifying = false;
    this.scheduleType = null;
    this.validateForm();
    if (this.scheduleTypeTemp?.id) this.scheduleType = this.scheduleTypeTemp;
    this.getDetails();
    this.setFormDirty(false);
    this.formGroup.reset();

  }

  selectedScheduleResource(e: any) {
    if (!this.scheduleResourceTypes) {
      this.scheduleResourceTypes = [];
    }
    this.scheduleResourceTypes.push(e.option.value);
    this.autoInput.nativeElement.value = '';
    this.setFormDirty();
  }

  save() {
    if (this.scheduleType) {
      this.scheduleType.name = this.nameCtrl?.value;
      this.scheduleType.description = this.descriptionCtrl?.value;
      this.scheduleType.acronym = this.acronymCtrl?.value;
      this.scheduleType.isActive = this.activeToggleCtrl?.value;
      this.scheduleType.tabColumns = this.tabsToggleCtrl?.value && this.scheduleType.scheduleSubtypes?.length > 0;
      this.scheduleType.graceTime = this.graceTimeCtrl?.value;
      this.scheduleType.nextDueDateTime = this.dueDateCtrl?.value;
      this.scheduleType.documentTypeId = this.documentTypeCtrl?.value != 0 ? this.documentTypeCtrl?.value : null;
      this.scheduleType.procedureTypeID = this.procedureTypeCtrl?.value != 0 ? this.procedureTypeCtrl?.value : null;
      this.scheduleType.dateFormat = this.dateFormatCtrl?.value;
      this.scheduleType.allowCustomSchedules = this.customToggleCtrl?.value;
      this.scheduleType.scheduleSubtypes = [];
      this.scheduleType.resourceTypeIds = this.utils.JSONstringify(this.scheduleResourceTypes.map(x => x.id));
      if (this.scheduleType.id) {
        this.service.update(this.utils.Serialize(this.scheduleType)).toPromise().then(data => {
          this.alert.success("Schedule Type Updated!!");
          if (data) this.scheduleType = data;
          this.getDetails();
          this.setFormDirty(false);
        });
      }
      else {
        this.service.create(this.scheduleType).toPromise().then(data => {
          this.alert.success("Schedule Type Created!!");
          if (data) this.scheduleType = data;
          this.getDetails();
          this.setFormDirty(false);
        });
      }
    }
  }

  delete() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure to Delete this Schedule Type?',
        icon: 'stop'
      }
    });
    confirm.afterClosed().subscribe(data => {
      if (data && this.scheduleType) {
        this.service.delete(this.scheduleType.id).toPromise().then(() => {
          this.getDetails();
        });
      }
    });
  }

  createSubtype() {
    const scheduleSubtype = {
      id: 0,
      scheduleTypeId: this.scheduleType?.id,
      scheduleType: this.scheduleType
    } as ScheduleSubtype
    this.selectedScheduleSubtype.emit(scheduleSubtype);
  }

}
