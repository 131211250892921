import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { DocumentType } from 'src/app/services/documents/documents';
import { DocumentTypeTabComponent } from './document-type-tab/document-type-tab.component';
import { OlogCategory } from '../services/olog-category';
import { DocumentsService } from 'src/app/services/documents/documents.service';
import { TemplateType } from 'src/app/components/checklists/checklists';
import { OlogEmail } from '../services/olog-email';
import { TemplateTypeTabComponent } from './template-type-tab/template-type-tab.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-document-type-details',
  templateUrl: './document-type-details.component.html',
  styleUrls: ['./document-type-details.component.scss']
})
export class DocumentTypeDetailsComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() documentType?: DocumentType;
  @Output() loading = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<DocumentType | undefined>();

  @ViewChild(DocumentTypeTabComponent) documentTypeTab?: DocumentTypeTabComponent;
  @ViewChild(TemplateTypeTabComponent) public templateTypeTab?: TemplateTypeTabComponent;

  public isModifyAvailable?: boolean;
  public isDeleteAvailable?: boolean;
  public isSaveAvailable?: boolean;
  public isDisabled = true;

  originalDocumentType?: DocumentType;

  constructor(
    protected override injector: Injector,
    private documentTypeService: DocumentsService,
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.documentType)
      this.getDocumentType(this.documentType);
  }

  getDocumentType(documentTypeSelected?: DocumentType) {
    this.clear();
    this.documentType = this.utils.cloneDeep(documentTypeSelected);

    if (this.documentType?.id) {
      this.documentType.code = this.documentType.code?.trim();
      if (this.documentType.documentTemplateId === 10) { // ABSI Template Type
        this.templateTypeTab?.loadAbsiTemplateTypes();
      } else {
        if (this.documentType.documentTemplateId)
          this.templateTypeTab?.loadTemplateTypesByDocTypeId(this.documentType.documentTemplateId);
      }
      // this.ologCategoryTab.loadOlogCategorysByDocTypeId(this.documentType.documentTemplateId);
      // this.ologEmailTab.loadOlogEmailsByDocTypeId(this.documentType.documentTemplateId);
      this.isModifyAvailable = true;

    } else {
      this.isSaveAvailable = this.isformDirty();
      this.isDisabled = this.documentType == null;
    }
    this.loading.emit(false);
  }

  modify() {
    this.isModifyAvailable = false;
    this.isSaveAvailable = false;
    this.isDisabled = false;
    this.originalDocumentType = this.utils.cloneDeep(this.documentType) as DocumentType;
  }

  async save() {
    this.loading.emit(true);
    await this.saveDocumentType();
    this.isModifyAvailable = true;
    this.setFormDirty(false);
    this.isDisabled = true;
  }

  async saveDocumentType() {
    if (this.documentTypeTab?.isDocumentTypeValid()) {
      if (this.documentType?.id) {
        await this.updateDocumentType(this.documentType);
      } else {
        await this.createDocumentType(this.documentType as DocumentType);
      }
    }
  }

  createDocumentType(documentType: DocumentType) {
    this.loading.emit(true);
    documentType.showInCatalog = true;
    this.documentTypeService.createDocumentType(documentType).toPromise().then(documentTypeCreated => {
      if (documentTypeCreated) {
        this.loading.emit(false);
        this.changed.emit(documentTypeCreated);
        this.alert.message('documentTypeTab_Created');
      }
    }).catch(() => {
      this.alert.defaultError();
    });
  }

  delete() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "500px", data: {
        message: "Are you sure you want to Delete Document Type <b>" + this.documentType?.name + "</b> ?",
        icon: "stop",
      },
    });
    confirm.afterClosed().subscribe(async data => {
      if (data && this.documentType?.id) {
        this.loading.emit(true);
        await this.documentTypeService.delete(this.documentType?.id).toPromise();
        this.loading.emit(false);
        this.alert.warning('Document Type Deleted!!');
        this.clear();
        this.changed.emit(undefined);
      }
    });
  }

  async updateDocumentType(documentType: DocumentType) {
    this.loading.emit(true);
    await this.documentTypeService.updateDocumentType(documentType.id, documentType).toPromise().then(documentTypeUpdated => {
      if (documentTypeUpdated) {
        this.loading.emit(false);
        this.alert.message('documentTypeTab_Updated');
      }
    }).catch(() => {
      this.alert.defaultError();
    });
  }

  setDocumentTemplateId(id: number) {
    if (this.documentType) {
      this.documentType.documentTemplateId = id;
    }
  }

  disableButtons() {
    this.isSaveAvailable = false;
    this.isDisabled = true;
    this.isModifyAvailable = true;
  }

  cancel() {
    this.isModifyAvailable = true;
    this.setFormDirty(false);
    this.isDisabled = true;
    this.documentType = this.originalDocumentType;
  }

  clear() {
    this.documentType = { id: 0 };
    this.disableButtons();
    this.isModifyAvailable = false;
  }

  documentTypeChanged(documentType: DocumentType) {
    this.documentType = documentType;
    this.setFormDirty();
  }

  ologCategoriesChanged(ologCategories?: OlogCategory[]) {
    if (this.documentType)
      this.documentType.ologCategories = ologCategories;
    this.setFormDirty();
  }

  templateTypesChanged(templateTypes: TemplateType[]) {
    if (this.documentType)
      this.documentType.templateTypes = templateTypes;
    this.setFormDirty();
  }

  ologEmailsChanged(ologEmails: OlogEmail[]) {
    if (this.documentType)
      this.documentType.ologEmails = ologEmails;
    this.setFormDirty();
  }

}
