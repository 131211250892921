import { RolePrivilege } from "src/app/components/catalogs/roles/services/role-privilege";

export class RolePrivilegeRefresh {
  static readonly type = '[RolePrivilege] Refresh';
}

export class RolePrivilegeCreate {
  static readonly type = '[RolePrivilege] Create';
  constructor(public item: RolePrivilege) { }
}

export class RolePrivilegeUpdate {
  static readonly type = '[RolePrivilege] Update';
  constructor(public id: number, public item: RolePrivilege) { }
}

export class RolePrivilegeDelete {
  static readonly type = '[RolePrivilege] Delete';
  constructor(public id: number) { }
}
