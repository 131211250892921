import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'scf-v2-settings',
  templateUrl: './scf-v2-settings.component.html',
  styleUrls: ['./scf-v2-settings.component.scss']
})
export class ScfV2SettingsComponent implements OnInit, AfterViewInit {

  isLoading?: boolean;

  constructor(
    public dialog: MatDialogRef<ScfV2SettingsComponent>,
  ) { }

  ngAfterViewInit(): void {

  }

  async ngOnInit(): Promise<void> {

  }

  close() {
    this.dialog.close();
  }

  setLoading(e: any) {
    this.isLoading = e;
  }
}
