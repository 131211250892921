import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RadMeterType } from 'src/app/components/catalogs/meters-catalog/rad-meters';
import { AlertService } from 'src/app/services/alert/alert.service';
import { RadMetersService } from '../../services/rad-meters.service';


@Component({
  selector: 'rad-meters-types-details',
  templateUrl: './rad-meters-types-details.component.html',
  styleUrls: ['./rad-meters-types-details.component.scss']
})
export class RadMetersTypesDetailsComponent implements OnInit {
  @Input()
  currentMeterType!: RadMeterType;

  public modifying!: boolean;
  public selected!: boolean;
  public adding = false;
  public disabledStatus!: boolean;
  public statusList: any = [{ name: 'Active', id: 1 }, { name: 'Inactive', id: 0 }];
  public showCancelButton!: boolean;
  public isModifiying!: boolean;
  public modifyAvailable = false;
  public disabledTimer!: boolean;
  listMeterTypes: RadMeterType[] = [];
  private valid!: boolean;
  oldName?: string = '';
  oldDescription?: string = '';
  oldStaus?: number;
  private errorMsg: string[] = [];

  constructor(
    private alert: AlertService,
    private _service: RadMetersService,
    private dialogRef: MatDialogRef<RadMetersTypesDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RadMeterType) { }

  ngOnInit(): void {
    if (this.currentMeterType == undefined) {
      this.currentMeterType = { name: '', description: '', status: 0 } as RadMeterType;
    }
    this.obtainMeterTypes();
    if (this.data) {
      this.getDetails(this.data);
    }
  }

  async obtainMeterTypes() {
    await this._service.getRadMeterTypes().toPromise().then(result => {
      if (!result) {
        result = [];
      }
      this.listMeterTypes = result;
    });
  }

  async saveMeterType() {
    const meterType = this.createMeterType();
    if (await this.isValid()) {
      if (!this.currentMeterType.id) {
        const meterTypeSearch = this.listMeterTypes.filter(cat => cat.name?.trim() == this.currentMeterType.name?.trim()).length;
        if (meterTypeSearch == 0) {
          await this._service.createRadMeterType(meterType).toPromise().then(result => {
            this.currentMeterType.id = result?.id;
          }).finally(() => {
            this.dialogRef.close('Create');
          });
        }
        else {
          this.alert.warning('Warning !! There is already a category with the name entered!!');
        }
      } else {
        meterType.id = this.currentMeterType.id;
        this._service.updateRadMeterType(meterType).toPromise().then(result => {
        }).finally(() => {
          this.dialogRef.close('Update');
        });
      }
    }
  }

  async isValid() {
    this.valid = true;
    if (this.currentMeterType.name == undefined || isNullOrWhiteSpace(this.currentMeterType.name) == true) {
      this.alert.error('Field <b>Name</b> should not be blank!');
      this.valid = false;
    }
    if (this.currentMeterType.description == undefined || isNullOrWhiteSpace(this.currentMeterType.description) == true) {
      this.alert.error('Field <b>Description</b> should not be blank!');
      this.valid = false;
    }
    return this.valid;
  }

  createMeterType(): RadMeterType {
    const radMeterType: RadMeterType = {
      name: this.currentMeterType.name,
      description: this.currentMeterType.description,
      status: this.currentMeterType.status,
      id: undefined,
      type: undefined,
      code: ''
    };
    return radMeterType;
  }

  getDetails(data: RadMeterType) {
    this.clear();
    if (!data.id) {
      this.currentMeterType.description = data.description;
      this.currentMeterType.name = data.name;
      this.currentMeterType.status = data.status;
      this.showCancelButton = true;
      this.adding = true;
      this.modifying = false;
      this.disabledStatus = false;
      this.selected = false;
    }
    else {
      this.selected = true;
      this.modifying = true;
      this.adding = false;
      this.disabledStatus = false;
      this.oldName = data.name;
      this.oldDescription = data.description;
      this.oldStaus = data.status;
      this.currentMeterType.id = data.id;
      this.currentMeterType.description = data.description;
      this.currentMeterType.name = data.name;
      this.currentMeterType.status = data.status;
      this.showCancelButton = true;
    }
  }

  clear() {
    this.currentMeterType = { name: '', description: '', status: 0 } as RadMeterType;
    this.adding = false;
    this.selected = false;
    this.modifying = false;
    this.isModifiying = false;
    this.disabledStatus = false;
    this.showCancelButton = false;
    this.disabledTimer = false;
  }

  close() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

function isNullOrWhiteSpace(input: any) {
  return !input || !input.trim();
}
