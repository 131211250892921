<div class="action-items-container" *ngIf="reviewActionItems?.length">
	<div class="row action-item" *ngFor="let actionItem of reviewActionItems">
		<div class="col-3">Topic: <b>{{ actionItem.name }}</b></div>
		<div class="col-3">
			Responsible: <b>{{ actionItem.responsible?.name }}</b>
		</div>
		<div class="col-3">
			Start: <b>{{ actionItem.startDate | date }}</b>
			<span *ngIf="hasEndDate(actionItem)">
				End: <b>{{ actionItem.endDate | date }}</b></span
			>
		</div>
		<div class="col-3">
			<span
				class="float-right"
				[ngClass]="{'red': actionItem.reviewActionItemType?.isStopper }"
				><b>{{ actionItem.reviewActionItemType?.name }}</b></span
			>
		</div>
	</div>
</div>
