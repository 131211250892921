import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ResourceType } from 'src/app/common/enumerations/enumerations';
import { PendingChangesDialogComponent } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog.component';
import { Link } from 'src/app/components/catalogs/navigation-links/link/link';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { TOCA } from 'src/app/components/catalogs/beamline-catalog/resource/toca';
import { LinkComponent } from '../../beamline-catalog/dialogs/link/link.component';
import { BeamlineRequiringApproval } from '../models/beamline-requiring-approval';
import { TocaComponent } from '../toca/toca.component';
import { CanDeactivateResponse } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog-response';

@Component({
  selector: 'app-bratoi-add-edit',
  templateUrl: './bratoi-add-edit.component.html',
  styleUrls: ['./bratoi-add-edit.component.scss']
})
export class BratoiAddEditComponent extends BaseComponent implements OnInit {

  public beamline!: Resource;
  public beamlineName?: string | null;
  public beamlines!: Resource[];
  public beamlines$!: Observable<Resource[]>;
  public beamlinesSubs!: Subscription;
  public beamlineCtrl = new FormControl();
  public beamlinesFiltered!: Resource[];

  public links: Link[] = [];
  public tocas: TOCA[] = [];

  public bratoi!: BeamlineRequiringApproval;

  public disableSubmmit!: boolean;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<BratoiAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: {
      bratoi: BeamlineRequiringApproval,
      bratois: BeamlineRequiringApproval[],
      dialogTitle: string
    },
  ) {
    super(injector);
  }

  ngOnInit() {
    this.bratoi = this.utils.cloneDeep(this.inputData.bratoi);
    this.beamlineName = this.bratoi?.resource ? this.bratoi.resource.name : this.bratoi.resourceString;
    this.beamlineCtrl.setValue(this.beamlineName);
    this.links = this.bratoi?.links ? this.bratoi.links : [];
    this.tocas = this.bratoi?.tocas ? this.bratoi.tocas : [];
    this.getResources();
  }

  getResources() {
    this.beamlines$ = this.store.select(state => state.Resources.data);
    this.beamlinesSubs = this.beamlines$.subscribe(data => {
      if (data?.length > 0) {
        this.beamlines = data.filter(r => r.type == ResourceType.Beamline).sort((a, b) => this.utils.SortBeamlines(a.name, b.name));
        this.beamlinesFiltered = this.beamlines.filter(x => !this.inputData.bratois.map(b => b.resourceId).includes(x.id));
      }
    });
  }

  selectedBeamline(event: MatAutocompleteSelectedEvent) {
    this.beamline = event.option.value;
  }

  accept() {
    if (this.validate()) {
      this.bratoi.links = this.links;
      this.bratoi.tocas = this.tocas;
      if (typeof (this.beamline) === 'object') {
        this.bratoi.resourceId = this.beamline.id;
      }
      if (typeof (this.beamline) === 'string') {
        this.bratoi.resource.name = this.beamline;
      }
      this.dialogRef.close(this.bratoi);
    } else {
      this.alert.message('BRATOI__NotBlank');
    }
  }

  cancel() {
    const confirmation = this.dialog.open(PendingChangesDialogComponent, {
      height: 'fit-content',
      width: '40%',
      data: {}
    });
    confirmation.afterClosed().toPromise().then((response: CanDeactivateResponse) => {
      switch (response) {
        case CanDeactivateResponse.Discard:
          this.dialogRef.close(null);
          break;
      }
    });
  }

  displayBeamlineName(beamline: Resource) {
    return beamline && beamline.name ? beamline.name : beamline;
  }

  linkDialog(linkToUpdate?: Link, index?: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      link: linkToUpdate ? linkToUpdate : new Link(),
      dialogTitle: linkToUpdate ? 'Edit Link' : 'Add Link',
      typeAvailable: true,
      linkType: linkToUpdate?.typeId
    };
    dialogConfig.width = '600px';
    const dialogRef = this.dialog.open(LinkComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then(async (link: Link) => {
      if (link) {
        this.updateLink(link, index ?? -1);
      }
    });
  }

  tocaDialog(tocaToUpdate?: TOCA, index?: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      toca: tocaToUpdate ? tocaToUpdate : new TOCA(),
      dialogTitle: tocaToUpdate ? 'Edit TOCA' : 'Add TOCA',
      inputTitle: 'TOCA',
      tocas: this.tocas
    };
    dialogConfig.minWidth = '600px';
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(TocaComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then(async (toca: TOCA) => {
      if (toca) {
        this.updateTOCA(toca, index ?? -1);
      }
    });
  }

  deleteList(listToDelete: any, index: number) {
    listToDelete.splice(index, 1);
  }

  updateLink(link: Link, index: number) {
    if (index >= 0) {
      this.links[index] = link;
    } else {
      this.links.push(link);
    }
  }

  updateTOCA(toca: TOCA, index: number) {
    if (index >= 0) {
      this.tocas[index] = toca;
    } else {
      this.tocas.push(toca);
    }
  }

  validate(): boolean {
    if (!this.bratoi?.id) {
      if (!this.beamlineCtrl.value) { return false; }
      if (typeof (this.beamlineCtrl.value) === 'string') {
        if (!this.beamline) { return false; }
      }
    }
    return true;
  }

  filter(e: any) {
    const textToFilter = e.target.value;
    this.beamlinesFiltered = this.beamlines.filter(x => x.name?.toLowerCase().includes(textToFilter.toLowerCase()));
  }

  getTocaSharedWith(toca: TOCA) {
    const relations = toca.beamlineRequiringApprovalRelations?.filter(r => r.beamlineRequiringApprovalId != this.bratoi.id).map(r => r.beamlineRequiringApproval?.resource ? r.beamlineRequiringApproval?.resource.name : r.beamlineRequiringApproval?.resourceString).sort((a, b) => this.utils.SortBeamlines(a, b));
    if (relations?.length) {
      return '(Shared with: ' + relations.join(', ') + ')';
    }
    return null;
  }

}
