interface String {
  // Define your method
  hasValue(): void;
}

// Implement the method
String.prototype.hasValue = function () {
  return this.trim().length > 0;
};

// Usage
const myString: string = "Hello";
myString.hasValue(); // This will print "My custom method for string: Hello"
