import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AbsiTemplateTypeResource } from '../../absi';
import { AbsiService } from '../../absi.service';
import { AbsiTemplateTypeRefresh, AbsiTemplateTypeCreate, AbsiTemplateTypeUpdate, AbsiTemplateTypeDelete } from './absi-template-type.action';

export class AbsiTemplateTypeStateModel {
  data: AbsiTemplateTypeResource[] = [];
}

@State<AbsiTemplateTypeStateModel>({
  name: 'AbsiTemplateType',
  defaults: {
    data: []
  }
})

@Injectable()
export class AbsiTemplateTypeState {
  constructor(
    private absiService: AbsiService
  ) { }

  @Selector()
  static read(state: AbsiTemplateTypeStateModel) {
    return state.data;
  }

  @Action(AbsiTemplateTypeRefresh)
  refresh({ patchState }: StateContext<AbsiTemplateTypeStateModel>) {
    this.absiService.getAllAbsiTemplateTypes().toPromise().then(data => {
      patchState({ data });
    });
  }

  @Action(AbsiTemplateTypeCreate)
  create({ getState, patchState }: StateContext<AbsiTemplateTypeStateModel>, { item }: AbsiTemplateTypeCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(AbsiTemplateTypeUpdate)
  update({ getState, patchState }: StateContext<AbsiTemplateTypeStateModel>, { id, item }: AbsiTemplateTypeUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    const currentState = state.data;

    if (index >= 0) {
      currentState.splice(index, 1, item);
      patchState({ data: currentState });
    }
  }

  @Action(AbsiTemplateTypeDelete)
  delete({ getState, patchState }: StateContext<AbsiTemplateTypeStateModel>, { id }: AbsiTemplateTypeDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
