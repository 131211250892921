<mat-form-field class="d-print-inline">
    <mat-label>{{placeholder}}</mat-label>
    <mat-select
        matNativeControl
        name="user-select"
        [disabled]="disabled"
        [formControl]="control"
        [(ngModel)]="userID"
        >
        <mat-option
            *ngFor="let user of users"
            [value]="user.id"
            >
            {{user.name}}
        </mat-option>
    </mat-select>
</mat-form-field>