<div class="card">
  <div class="card-header">
    <span class="card-title">{{ title }}</span>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="no-subscript">
              <mat-label>Name</mat-label>
              <input
                #reviewStatusNameCtrlElement
                type="text"
                placeholder="Name"
                matInput
                name="reviewStatusNameCtrl"
                [formControl]="reviewStatusNameCtrl"
              />
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="outline" class="no-subscript">
              <mat-label>Description</mat-label>
              <input
                type="text"
                placeholder="Description"
                matInput
                name="reviewStatusDescriptionCtrl"
                [formControl]="reviewStatusDescriptionCtrl"
              />
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="outline" class="no-subscript">
              <mat-label>Acronym</mat-label>
              <input
                type="text"
                placeholder="Description"
                matInput
                name="reviewStatusCodeCtrl"
                #code
                [formControl]="reviewStatusCodeCtrl"
                class="uppercase"
              />
            </mat-form-field>
          </div>
          <div class="col-12">
            <color-picker
              [color]="reviewStatus?.color"
              (selected)="selectedColor($event)"
            ></color-picker>
          </div>
          <div class="col-12">
            <mat-form-field appearance="outline" class="no-subscript">
              <mat-label>Status Type</mat-label>
              <mat-select [formControl]="reviewStatusTypeCtrl">
                <mat-option
                  *ngFor="let statusType of statusTypes"
                  [value]="statusType"
                >
                  {{ statusType.key }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-6 pr-4">
        <div class="row border-box">
          <div class="col-12 py-2">Action Buttons</div>
          <div class="col-12">
            <reviews-settings-statuses-actions
              [reviewStatus]="reviewStatus"
              [reviewStatuses]="reviewStatuses"
              (changed)="actionsChanged($event)"
            ></reviews-settings-statuses-actions>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-stroked-button
        color="warn"
        (click)="cancel()"
        [disabled]="disabled"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!(form.dirty && formValid()) || disabled"
        (click)="save()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
