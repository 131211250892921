<div
  *ngIf="
    (!formType || serialNo == null || serialNo == undefined) &&
    !disabled &&
    environment != servers.PRODUCTION
  "
>
  <fieldset class="field-group">
    <legend class="field-group-label">{{ label ?? title }}</legend>
    <div>
      This comment box is disabled because the developer forgot to add a
      required parameter to the component:
    </div>
    <div *ngIf="!formType">
      Required Input Parameter: <span class="red">formType</span>
    </div>
    <div *ngIf="serialNo == null || serialNo == undefined">
      Required Input Parameter: <span class="red">serialNo</span>
    </div>
  </fieldset>
</div>
<div
  *ngIf="
    (formType && serialNo != null && serialNo != undefined) ||
    disabled ||
    environment == servers.PRODUCTION
  "
  class="comments-container scrollbar pt-1"
  [ngClass]="{ disabled: disabled }"
  [ngStyle]="{ 'min-height': minHeight }"
>
  <mat-progress-bar
    *ngIf="loading"
    class="progress-bar"
    mode="indeterminate"
  ></mat-progress-bar>

  <div #parent [ngClass]="{ commentsHasError: hasError }">
    <mat-form-field
      class="text-container {{ cssClass }}"
      [ngClass]="{
        hilite: hilite,
        'no-subscript': noSubscript || !hasError,
        'no-border': noBorder
      }"
      [ngStyle]="{ 'min-height': minHeight }"
      [floatLabel]="textValueSanitized ? 'always' : 'auto'"
      (click)="editText($event, parent)"
    >
      <mat-label>{{
        textValue ? label ?? title : placeholder ?? label ?? title
      }}</mat-label>
      <div
        [innerHTML]="textValueSanitized"
        class="comment-box"
        [ngStyle]="{
          'max-height': maxHeight,
          'overflow-y': maxHeight ? 'auto' : 'unset'
        }"
      ></div>
      <div class="resize-icon">
        <textarea
          [placeholder]="placeholder ?? ''"
          *ngIf="control"
          [formControl]="control"
          aria-label="textarea"
          #textarea
          matInput
          readonly
          class="h1em"
          [ngStyle]="{ 'min-height': minHeight }"
        ></textarea>
        <textarea
          *ngIf="!control"
          [placeholder]="placeholder ?? ''"
          aria-label="textarea"
          #textarea
          matInput
          readonly
          class="h1em"
          [disabled]="disabled ?? false"
          [ngStyle]="{ 'min-height': minHeight }"
        ></textarea>
      </div>
      <mat-error *ngIf="hasError">You must enter a value</mat-error>
      <mat-hint><span [innerHTML]="hint"></span></mat-hint>
    </mat-form-field>
  </div>

  <button
    mat-icon-button
    class="edit"
    (click)="editText($event, parent)"
    *ngIf="!disabled && !loading"
  >
    <mat-icon color="accent">edit</mat-icon>
  </button>
  <span class="message small" *ngIf="loading"> Uploading Images ... </span>
</div>
