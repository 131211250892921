import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { WfTaskLocalResource, WfSignature } from 'src/app/services/work-flow/work-flow';
import { Checklist, Annotation, AnnotationStatus } from '../../../checklists';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'checklist-tasks-annotations',
  templateUrl: './checklist-tasks-annotations.component.html',
  styleUrls: ['./checklist-tasks-annotations.component.scss']
})
export class ChecklistTasksAnnotationsComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() task?: WfTaskLocalResource;
  @Input() s!: WfSignature;
  @Input() currentChecklist?: Checklist | null;

  @Output() changed = new EventEmitter<WfTaskLocalResource>();

  annotations?: Annotation[];
  annotationsFiltered?: Annotation[];
  annotations$!: Observable<Annotation[]>;
  annotationsSubs!: Subscription;

  hasAnnotations!: boolean;

  ngOnInit(): void {
  }

  loadAnnotations() {
    this.annotations$ = this.store.select(state => state.Annotations.data);
    this.annotationsSubs = this.annotations$.subscribe(data => {
      if (data?.length) {
        this.annotations = data;
        this.annotationsFiltered = this.annotations.filter(a => a.wfTaskLocalID == this.task?.id).sort((a, b) => a.id - b.id) ?? [];
        this.hasAnnotations = this.annotationsFiltered?.some(x => x.status == AnnotationStatus.Active);
      }
    });
  }

  ngOnChanges() {
    this.loadAnnotations();
  }
}
