import { Role } from "../../catalogs/roles/services/role";
import { ProcedureMaster } from "./procedure-master.model";
import { ProcedureObligationType } from "../enums/procedure-obligation-type.enum";

export class ProcedureTraining {
  public id?: number;
  public procedureMasterID?: number;
  public roleID?: number;
  public obligationType!: ProcedureObligationType;
  public procedureMaster?: ProcedureMaster;
  public role?: Role;
  public userID?: number | null = null;
}
