import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ChecklistsService } from '../../checklists.service';
import { Observable, Subscription } from 'rxjs';
import { WFStepLocalResource, WFSectionLocalResource, WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { Store } from '@ngxs/store';


@Component({
  selector: 'checklist-task-counter',
  templateUrl: './checklist-task-counter.component.html',
  styleUrls: ['./checklist-task-counter.component.scss']
})
export class ChecklistTaskCounterComponent implements OnInit, OnChanges, OnDestroy {

  @Input() step?: WFStepLocalResource;
  @Input() section?: WFSectionLocalResource;

  tasks?: WfTaskLocalResource[];
  tasksSubs!: Subscription;
  totalTasks!: number;
  completedTasks!: number;
  componentsToCheck = [0, 1];

  wfTaskLocals?: WfTaskLocalResource[];
  wfTaskLocals$!: Observable<WfTaskLocalResource[]>;
  wfTaskLocalsSubs!: Subscription;

  constructor(private store: Store) { }

  ngOnDestroy(): void {
    this.wfTaskLocalsSubs?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.loadWFTaskLocals();
    // this.tasksSubs = this.service.allTasksObservable.subscribe(data => {
    //   this.tasks = data;
    //   this.getCompletedTasks();
    // });
  }

  loadWFTaskLocals() {
    this.wfTaskLocals$ = this.store.select(state => state.WFTaskLocals.data);
    this.wfTaskLocalsSubs = this.wfTaskLocals$.subscribe(data => {
      if (data?.length) {
        this.wfTaskLocals = data;
        this.tasks = data;
        this.getCompletedTasks();
      }
    });
  }

  getCompletedTasks() {
    const tasks = this.tasks?.filter(t => t.required && t.component && this.componentsToCheck.includes(t.component.type) && (this.section ? (t.wfStepLocal?.wfSectionLocalID == this.section.id) : (this.step ? (t.wfStepLocalID == this.step.id) : 0)));
    setTimeout(() => {
      this.totalTasks = tasks?.length ?? 0;
      this.completedTasks = tasks?.filter(t => t.isCompleted).length ?? 0;
    }, 10);
  }



}
