import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as AlertActions from './alert.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';

@Injectable({
  providedIn: 'root'
})
export class AlertStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.ALERT, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new AlertActions.Alert(data.obj));
          break;
        case SignalRAction.Olog:
          this.store.dispatch(new AlertActions.OlogAlert(data.obj));
      }
    });
  }
}
