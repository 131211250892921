import { Component, ElementRef, inject, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { OlogMessage } from '../../../../services/olog-message';
import { FormControl } from '@angular/forms';
import { FormStatus } from 'src/app/services/forms/form-status';
import { Observable, Subscription } from 'rxjs';
import { OlogNotificationType } from 'src/app/common/enumerations/enumerations';
import { DocumentCategory, DocumentType } from 'src/app/services/documents/documents';
import { OlogCategoriesService } from '../../../../services/olog-categories.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { EmailAddress } from 'src/app/components/olog/olog';
import { OlogMessageService } from '../../../../services/olog-message.service';
import { ChecklistExecStatus } from 'src/app/components/checklists/checklists';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-olog-message-dialog',
  templateUrl: './olog-message-dialog.component.html',
  styleUrls: ['./olog-message-dialog.component.scss']
})
export class OlogMessageDialogComponent extends BaseComponent implements OnInit {

  nameCtrl = new FormControl();
  activeCtrl = new FormControl();
  fromCtrl = new FormControl();
  toCtrl = new FormControl();
  descriptionCtrl = new FormControl();
  subjectCtrl = new FormControl();
  messageCtrl = new FormControl();
  notificationTypeCtrl = new FormControl();
  emailsCtrl = new FormControl();
  categoriesCtrl = new FormControl();

  notifications?: any[];


  fromStatusesFiltered?: Status[];
  toStatusesFiltered?: Status[];

  formStatuses?: FormStatus[];
  formStatuses$!: Observable<FormStatus[]>;
  formStatusesSubs!: Subscription;

  checklistExecStatuses?: ChecklistExecStatus[];
  checklistExecStatuses$!: Observable<ChecklistExecStatus[]>;
  checklistExecStatusesSubs!: Subscription;

  serialNo = '0';

  ologMessage!: OlogMessage;
  documentType!: DocumentType;
  ologCategories: string[] = [];

  ologEmails: EmailAddress[] = [];
  filteredEmails: EmailAddress[] = [];

  announcer = inject(LiveAnnouncer);

  @ViewChild('categoryInput') categoryInput!: ElementRef<HTMLInputElement>;
  @ViewChild('emailInput') emailInput!: ElementRef<HTMLInputElement>;

  categories: string[] = [
    'Accelerator Controls',
    'Accelerator Physics',
    'APEX',
    'Beamline Activities',
    'Beamline Controls',
    'Computer Security',
    'Electronics Maintenance',
    'EPS',
    'Facilities',
    'Injection & Tuning',
    'Insertion Device',
    'Instrumentation',
    'LFB / TFB / BC',
    'LOTO',
    'Network Support',
    'Operations',
    'PC Support',
    'Power Supplies',
    'Radiation',
    'RF',
    'RF Maintenance',
    'Safety',
    'Safety Interlocks',
    'Superbends',
    'Test',
    'Timing',
    'Top Off RSS',
    'Unix Support',
    'URGENT Off - Hours Unix Support',
    'User Feedback',
    'User Services',
    'Vacuum',
    'Water and Air',
    'Web Support'
  ];
  filteredCategories?: string[];

  constructor(
    protected override injector: Injector,
    private service: OlogMessageService,
    private ologCategoriesService: OlogCategoriesService,
    public dialogRef: MatDialogRef<OlogMessageDialogComponent>,
    private clipboard: Clipboard,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(injector);

  }

  ngOnInit() {
    this.ologMessage = this.data.ologMessage;
    this.documentType = this.data.documentType;
    this.initializeCategories();
    this.initializeEmails();
    this.notifications = this.utils.enumToIdNameArray(OlogNotificationType);
    this.loadFormStatuses();
    this.loadChecklistExecStatuses();
    this.initializeForm();
    if (this.ologMessage?.id) this.serialNo = this.ologMessage.id.toString();
  }

  async initializeCategories() {
    if (!this.ologMessage.categories)
      this.ologCategories = (await this.ologCategoriesService.GetOlogCategoryByDocumentTypeID(this.documentType.documentTemplateId ?? 0).toPromise())?.categoryNames?.split(',') ?? [];
    else this.ologCategories = this.ologMessage.categories.split(',');
    this.filteredCategories = this.categories.filter(c => !this.ologCategories.includes(c));
  }

  initializeEmails() {
    this.filterEmails();
    if (this.ologMessage.emailAddresses) {
      const ologEmails = this.extractEmailAddresses(this.ologMessage?.emailAddresses);
      this.ologEmails = this.users.filter(u => ologEmails?.includes(u.emailAddress ?? '')).map(u => {
        return { name: u.name, address: u.emailAddress, isCustom: false } as EmailAddress
      });
      const customEmails = ologEmails.filter(e => !this.users.map(u => u.emailAddress).includes(e));
      this.ologEmails = this.ologEmails.concat(customEmails.map(c => {
        return { address: c, isCustom: true } as EmailAddress;
      }));
    }
  }

  accept() {
    const ologMessage = {
      id: this.ologMessage.id ?? 0,
      documentTypeID: this.documentType.id,
      name: this.nameCtrl.value,
      notificationType: this.notificationTypeCtrl.value.id,
      subject: this.subjectCtrl.value,
      fromStatusID: this.fromCtrl.value.id,
      toStatusID: this.toCtrl.value.id,
      description: this.descriptionCtrl.value,
      status: this.activeCtrl.value ? 1 : 0,
      message: this.messageCtrl.value,
      categories: this.ologCategories.join(','),
      emailAddresses: this.ologEmails.map(e => `${e.name ? e.name : ''} <${e.address}>`).join(', '),
    } as OlogMessage;

    if (ologMessage.id) {
      // Update
      this.service.update(ologMessage).toPromise().then(data => {
        this.dialogRef.close(data);
        this.setFormDirty(false);
      }).catch(error => this.alert.defaultError());
    }
    else {
      // Create
      this.service.create(ologMessage).toPromise().then(data => {
        this.dialogRef.close(data);
        this.setFormDirty(false);
      }).catch(error => this.alert.defaultError());
    }
  }

  loadFormStatuses() {
    this.formStatuses$ = this.store.select(state => state.FormStatuses.data);
    this.formStatusesSubs = this.formStatuses$.subscribe(data => {
      if (data?.length) {
        this.formStatuses = data.sort((a, b) => this.utils.sort(a.name, b.name));
        this.filterFromStatuses();
        this.filterToStatuses();
      }
    });
  }

  loadChecklistExecStatuses() {
    this.checklistExecStatuses$ = this.store.select(state => state.ChecklistExecStatuses.data);
    this.checklistExecStatusesSubs = this.checklistExecStatuses$.subscribe(data => {
      if (data?.length) {
        this.checklistExecStatuses = data;
        this.filterFromStatuses();
        this.filterToStatuses();
      }
    });
  }

  filterFromStatuses(e?: any) {
    this.fromStatusesFiltered = this.documentType.category == DocumentCategory.Form ? this.formStatuses?.map(s => {
      const status = { id: s.id, name: s.name, class: s.color } as Status;
      return status;
    }) :
      this.checklistExecStatuses?.map(s => {
        const status = { id: s.id, name: s.name, class: s.cssClass } as Status;
        return status;
      });
    if (e) {
      const value = e.target.value?.toLowerCase();
      this.fromStatusesFiltered = this.fromStatusesFiltered?.filter(s => s.name.toLowerCase().includes(value));
    }
    if (!this.ologMessage.fromStatus) this.ologMessage.fromStatus = this.fromStatusesFiltered?.find(s => s.id == this.ologMessage.fromStatusID);
  }

  filterToStatuses(e?: any) {
    this.toStatusesFiltered = this.documentType.category == DocumentCategory.Form ? this.formStatuses?.map(s => {
      const status = { id: s.id, name: s.name, class: s.color } as Status;
      return status;
    }) :
      this.checklistExecStatuses?.map(s => {
        const status = { id: s.id, name: s.name, class: s.cssClass } as Status;
        return status;
      });
    if (e) {
      const value = e.target.value?.toLowerCase();
      this.toStatusesFiltered = this.toStatusesFiltered?.filter(s => s.name.toLowerCase().includes(value));
    }
    if (!this.ologMessage.toStatus) this.ologMessage.toStatus = this.toStatusesFiltered?.find(s => s.id == this.ologMessage.toStatusID);
  }

  cancel() {
    this.dialogRef.close();
  }

  displayUserName(user: User) {
    return user && user.name ? user.name : '';
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      nameCtrl: this.nameCtrl,
      activeCtrl: this.activeCtrl,
      descriptionCtrl: this.descriptionCtrl,
      fromCtrl: this.fromCtrl,
      toCtrl: this.toCtrl,
      emailsCtrl: this.emailsCtrl,
      subjectCtrl: this.subjectCtrl,
      messageCtrl: this.messageCtrl,
      notificationTypeCtrl: this.notificationTypeCtrl,
      categoriesCtrl: this.categoriesCtrl
    });

    this.formGroup.valueChanges.subscribe(form => {
      this.setFormDirty(true);
      this.validateForm();
    });

    this.formGroup.markAsPristine();
    this.setValues();
    this.setFormDirty(false);
  }

  setValues() {
    this.nameCtrl.setValue(this.ologMessage.name);
    this.descriptionCtrl.setValue(this.ologMessage.description);
    this.notificationTypeCtrl.setValue(this.notifications?.find(n => n.id == this.ologMessage.notificationType));
    this.fromCtrl.setValue(this.ologMessage.fromStatus);
    this.toCtrl.setValue(this.ologMessage.toStatus);
    this.activeCtrl.setValue(this.ologMessage.status);
    this.messageCtrl.setValue(this.ologMessage.message);
    this.subjectCtrl.setValue(this.ologMessage.subject);
  }

  extractEmailAddresses(input: string): string[] {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
    return input.match(emailRegex) || [];
  }

  validateForm() {
    if (!this.nameCtrl.value) this.nameCtrl.setErrors({ 'required': true });
    if (!this.fromCtrl.value) this.fromCtrl.setErrors({ 'required': true });
    if (!this.toCtrl.value) this.toCtrl.setErrors({ 'required': true });
    if (!this.subjectCtrl.value) this.subjectCtrl.setErrors({ 'required': true });
    if (!this.notificationTypeCtrl.value) this.notificationTypeCtrl.setErrors({ 'required': true });
    // Email addresses and Message can be empty
  }

  //Category

  addCategory(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.ologCategories.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.filterCategories();
  }

  removeCategory(category: string): void {
    const index = this.ologCategories.indexOf(category);

    if (index >= 0) {
      this.ologCategories.splice(index, 1);
      this.announcer.announce(`Removed ${category}`);
      this.filterCategories();
    }
  }

  selectedCategory(event: MatAutocompleteSelectedEvent): void {
    this.ologCategories.push(event.option.viewValue);
    this.categoryInput.nativeElement.value = '';
    this.filterCategories();
  }

  filterCategories(e?: any) {
    const filterValue = e?.target?.value?.toLowerCase() ?? '';
    this.filteredCategories = this.categories.filter(c => !this.ologCategories.includes(c));
    if (e)
      this.filteredCategories = this.filteredCategories.filter(category => category.toLowerCase().includes(filterValue)) ?? [];
  }

  // Emails

  removeEmail(email: EmailAddress): void {
    const index = this.ologEmails.indexOf(email);
    if (index >= 0) {
      this.ologEmails.splice(index, 1);
    }
  }

  selectedEmail(event: MatAutocompleteSelectedEvent): void {
    this.ologEmails.push(event.option.value);
    this.emailInput.nativeElement.value = '';
    this.filterEmails();
  }

  filterEmails(e?: any) {
    const text = e?.target?.value;
    this.filteredEmails = this.users.filter(u => !this.ologEmails.map(x => x.address)?.includes(u.emailAddress ?? '')).map(u => {
      return { name: u.name, address: u.emailAddress, isCustom: false } as EmailAddress
    });

    if (text)
      this.filteredEmails = this.filteredEmails.filter(x => x.name.toLowerCase().includes(text.toLowerCase()) || x.address.toLowerCase().includes(text.toLowerCase()));
  }

  addEmail(event: MatChipInputEvent): void {
    if (event.value) {
      const emailAddress = { address: event.value, isCustom: true } as EmailAddress;
      if (!this.utils.isValidEmail(emailAddress.address)) {
        this.emailsCtrl.setErrors({ invalid: true });
      }
      else {
        this.ologEmails.push(emailAddress);
        this.emailInput.nativeElement.value = '';
        this.filterEmails();
      }
    }
  }

  copyToClipboard(value: string) {
    this.clipboard.copy(value);
    this.alert.info(`<b>${value}</b> has been copied to the <b>Clipboard</b>`);
  }

}

export interface Status {
  id: number;
  name: string;
  class: string;
}