<div class="card">
  <div class="card-header">
    <span class="card-title">History for {{ bratoi.resourceString }}</span>
    <button
      mat-mini-fab
      class="closex"
      color="warn"
      (click)="dialogRef.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="card-body">
    <div class="mat-container">
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="resource">
          <mat-header-cell *matHeaderCellDef> Beamline/PSS </mat-header-cell>
          <mat-cell
            [ngClass]="{ blInactive: !element.IsActive }"
            *matCellDef="let element"
          >
            {{ element.ResourceString }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="documentation">
          <mat-header-cell *matHeaderCellDef> Documentation </mat-header-cell>
          <mat-cell
            [ngClass]="{ blInactive: !element.IsActive }"
            *matCellDef="let element"
          >
            <div *ngFor="let link of element.Links">
              <div class="mb-2">
                {{ link.LinkType.Name }}:
                <a href="{{ link.WebAddress }}" target="_blank">{{
                  link.Hyperlink
                }}</a>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="criticalApertures">
          <mat-header-cell *matHeaderCellDef>
            List Of Critical Apertures
          </mat-header-cell>
          <mat-cell
            [ngClass]="{ blInactive: !element.IsActive }"
            *matCellDef="let element"
          >
            <div *ngFor="let toca of element.Tocas">
              <div class="mb-1">
                {{ toca.Name }}
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
          <mat-cell
            [ngClass]="{ blInactive: !element.IsActive }"
            *matCellDef="let element"
          >
            <div>
              <b>{{ element.Action }} </b><br />
              {{ element.ActionBy.Name }} <br />
              <span class="date">
                {{ element.ActionOn | date : "longDate" }}</span
              >
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row
          #header
          class="p-0"
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
