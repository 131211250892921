import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { TextareaDialogComponent } from 'src/app/controls/textarea-dialog/textarea-dialog.component';
import { DocumentCategory, DocumentType } from 'src/app/services/documents/documents';
import { OlogMessage } from '../../../services/olog-message';
import { OlogMessageService } from '../../../services/olog-message.service';
import { OlogMessageDialogComponent, Status } from './olog-message-dialog/olog-message-dialog.component';
import { ChecklistExecStatus } from 'src/app/components/checklists/checklists';
import { FormStatus } from 'src/app/services/forms/form-status';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'olog-messages-tab',
  templateUrl: './olog-messages-tab.component.html',
  styleUrls: ['./olog-messages-tab.component.scss']
})
export class OlogMessagesTabComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() documentType?: DocumentType;
  @Input() disabled = true;

  @Output() changed = new EventEmitter<OlogMessage[]>();

  public ologMessages$!: Observable<OlogMessage[]>;
  public ologMessageSubscription!: Subscription;

  ologMessages?: OlogMessage[];

  public displayedColumns: string[] = ['name', 'status', 'from', 'to'];
  public dataSource: MatTableDataSource<OlogMessage> = new MatTableDataSource();

  formStatuses?: FormStatus[];
  formStatuses$!: Observable<FormStatus[]>;
  formStatusesSubs!: Subscription;

  checklistExecStatuses?: ChecklistExecStatus[];
  checklistExecStatuses$!: Observable<ChecklistExecStatus[]>;
  checklistExecStatusesSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    public service: OlogMessageService,
  ) {
    super(injector);
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      if (this.disabled) {
        this.removeAddColumn();
      } else {
        if (this.hasPrivilege(this.privilegeEnum.CreateOlogMessage)) {
          this.displayedColumns.push('add');
        }
      }
    }
    if (this.documentType)
      this.loadOlogMessages();
  }

  override ngOnDestroy(): void {
    this.ologMessageSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  loadOlogMessages() {
    this.ologMessages$ = this.store.select(x => x.OlogMessage.data);
    this.ologMessageSubscription = this.ologMessages$.subscribe(ologMessages => {
      this.ologMessages = ologMessages.filter(x => x.documentTypeID === this.documentType?.id);
      this.dataSource = new MatTableDataSource(this.ologMessages);
      if (this.documentType?.category == DocumentCategory.Form)
        this.loadFormStatuses();
      else this.loadChecklistExecStatuses();
    });
  }

  loadFormStatuses() {
    this.formStatuses$ = this.store.select(state => state.FormStatuses.data);
    this.formStatusesSubs = this.formStatuses$.subscribe(data => {
      if (data?.length) {
        this.formStatuses = data.sort((a, b) => this.utils.sort(a.name, b.name));
        this.setStatuses();
      }
    });
  }

  loadChecklistExecStatuses() {
    this.checklistExecStatuses$ = this.store.select(state => state.ChecklistExecStatuses.data);
    this.checklistExecStatusesSubs = this.checklistExecStatuses$.subscribe(data => {
      if (data?.length) {
        this.checklistExecStatuses = data;
        this.setStatuses();
      }
    });
  }

  setStatuses() {
    const statuses = this.documentType?.category == DocumentCategory.Form ? this.formStatuses?.map(s => {
      const status = { id: s.id, name: s.name, class: s.color } as Status;
      return status;
    }) :
      this.checklistExecStatuses?.map(s => {
        const status = { id: s.id, name: s.name, class: s.cssClass } as Status;
        return status;
      });
    this.ologMessages?.map(m => {
      m.fromStatus = statuses?.find(s => s.id == m.fromStatusID);
      m.toStatus = statuses?.find(s => s.id == m.toStatusID);
    });
  }

  ologMessageDialog(ologMessage?: OlogMessage) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      ologMessage: ologMessage ? this.utils.cloneDeep(ologMessage) : {},
      documentType: this.documentType
    };
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '60vw';
    dialogConfig.maxHeight = '90vh';
    const dialogRef = this.dialog.open(OlogMessageDialogComponent, dialogConfig);
    dialogRef.afterClosed().toPromise();
  }

  delete(ologMessage: OlogMessage) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "500px", data: {
        message: this.getMessage("GenericActionConfirmation").description?.replace('{action}', 'Delete').replace('{item}', `this <b>Olog Message -> ${ologMessage.name}</b> `)
        ,
        icon: "stop",
      },
    });
    confirm.afterClosed().subscribe((data) => {
      if (data) {
        this.deleteOlogMessage(ologMessage);
      }
    });
  }

  deleteOlogMessage(ologMessage: OlogMessage) {
    const dialogConfig: MatDialogConfig = {
      data: {
        label: 'Delete Reason',
        placeholder: 'Delete Reason',
        text: ''
      }
    };
    const dialogRefSureToDelete = this.dialog.open(TextareaDialogComponent, dialogConfig);
    dialogRefSureToDelete.afterClosed().toPromise().then(data => {
      if (data?.acceptClicked && data?.text) {
        const index = this.ologMessages?.findIndex(c => c.id == ologMessage.id) ?? -1;
        if (index >= 0)
          this.ologMessages?.splice(index, 1);
        this.dataSource = new MatTableDataSource(this.ologMessages);
        this.service.delete(ologMessage.id).toPromise();
      }
    });
  }

  removeAddColumn() {
    if (this.displayedColumns.includes('add')) {
      const index = this.displayedColumns.indexOf('add');
      this.displayedColumns.splice(index, 1);
    }
  }

}
