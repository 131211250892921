export enum ReminderPeriodEnum {
  before = 1,
  after = 2
}
export enum ScheduleStatusEnum {
  new = 1,
  completed = 2,
  requestExtension = 3,
  extensionGranted = 4,
  refuseExtension = 5,
  remove = 6,
  completedSubdetail = 7
}
export enum ScheduleStatusTimeEnum {
  Good = 1,
  Grace = 2,
  Expired = 3,
  ExtensionGranted = 4,
  Completed = 5,
  Archived = 7
}

export enum PPSTBFollowUpType {
  RepairReplaceComponent = 1,
  ModifyInterlock = 2,
  Other = 3
}
