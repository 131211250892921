// import { OverlayLoadingComponent } from './overlay-loading/overlay-loading.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldDefaultOptions, MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
// import { TextMaskModule } from 'angular2-text-mask';
// import { NgxSpinnerModule } from 'ngx-spinner';
import { config } from 'src/assets/config';
import { AppRoutingModule } from '../app-routing.module';
import { AppComponent } from '../app.component';
// import { httpInterceptorProviders } from '../interceptors';
// import { MatFileUploadModule } from '../modules/file-upload/file-upload-module';
// import { reducers } from '../Store/reducers';
// import { RelatedLinkChipsComponent } from './related-links/related-link-chips/related-link-chips.component';
// import { RelatedLinkDocumentChipsComponent } from './related-links-documents/related-link-document-chips/related-link-document-chips.component';
// import { UserAutocompletePopupComponent } from './user/user-autocomplete-popup/user-autocomplete-popup.component';
// import { AddRelatedLinkDocumentComponent } from './related-links-documents/add-related-link-document/add-related-link-document.component';
// import { FileUploadV2Component } from './file/file-upload-v2/file-upload-v2.component';
// import { ProcedureAutocompleteComponent } from './procedure/procedure-autocomplete/procedure-autocomplete.component';
// import { ProcedureTemplateAutocompleteComponent } from './procedure/procedure-template-autocomplete/procedure-template-autocomplete.component';
// import { QuillEditorDialogComponent } from './quill-editor-dialog/quill-editor-dialog.component';
import { QuillModule } from 'ngx-quill';
import { reducers } from '../store/reducers';
import { HighlightSearchPipe } from './pipes/highlightable-search.pipe';
import { httpInterceptorProviders } from '../interceptors/interceptorProviders';
import { OverlayLoadingComponent } from '../controls/overlay-loading/overlay-loading.component';
// import { UserAutocompleteComponent } from './user/user-autocomplete/user-autocomplete.component';
// import { APLCategoryCardComponent } from '../components/apl/apl-category/apl-category-card/apl-category-card.component';
// import { UserChipComponent } from './user/user-chip/user-chip.component';
// import { ResourceAutocompleteComponent } from './resources/resource-autocomplete/resource-autocomplete.component';
// import { RssFunctionalTestChipsComponent } from './rsswa/rss-functional-test-chips/rss-functional-test-chips.component';
// import { ColorPickerComponent } from './color-picker/color-picker.component';
// import { RoleChipComponent } from './role/role-chip/role-chip.component';
// import { APLGroupCardComponent } from '../components/apl/apl-group/apl-group-card/apl-group-card.component';
// import { FileUploadComponent } from './file/file-upload/file-upload.component';
// import { APLCategoryAutocompleteComponent } from '../components/apl/apl-category/apl-category-autocomplete/apl-category-autocomplete.component';
// import { APLGroupAutocompleteComponent } from '../components/apl/apl-group/apl-group-autocomplete/apl-group-autocomplete.component';
// import { ClCommentsPopupModule } from './checklist/cl-comments-popup/cl-comments-popup.module';
// import { SCFLogComponent } from '../components/scf-v2/scf-v2-main/scf-log/scf-log.component';
// import { SectionVerificationComponent } from './section-verification/section-verification.component';
// import { ScfWorkerVerificationTableComponent } from './scf-worker-verification/scf-worker-verification-table/scf-worker-verification-table.component';
// import { WorkerVerificationSignComponent } from './worker-verification-sign/worker-verification-sign.component';
// import { HighlightSearchPipe } from '../components/head-page/search-dialog/highlightable-search.pipe';
// import { AcceleratorLocationChipsComponent } from './accelerator-location-chips/accelerator-location-chips.component';
// import { FormatLayoutComponent } from './format-layout/format-layout.component';
// import { MatIconWithTootipComponent } from './common/mat-icon-with-tootip/mat-icon-with-tootip.component';
// import { BaseComponent } from './common/base/base.component';
import { CommonModule } from '@angular/common';
import { FilterBoxComponent } from '../controls/filter-box/filter-box.component';
import { CommentsBoxModule } from '../controls/comments-box/comments-box.module';
import { PartialOlogComponent } from '../controls/olog/partial-olog/partial-olog.component';
import { NotificationComponent } from '../controls/notifications/notification.component';
import { AddRelatedLinkComponent } from '../controls/add-related-link/add-related-link.component';
import { LocationsService } from '../components/catalogs/beamline-catalog/resource/locations.service';
import { CancelDialogComponent } from '../controls/cancel-dialog/cancel-dialog.component';
import { WorkFlowService } from '../services/work-flow/work-flow.service';
import { TextareaDialogComponent } from '../controls/textarea-dialog/textarea-dialog.component';
import { RoleChipsComponent } from '../controls/role-chips/role-chips.component';
import { MatCheckboxWithRolesComponent } from '../controls/mat-checkbox-with-roles/mat-checkbox-with-roles.component';
import { CommentsAndAttachmentsModule } from '../controls/comments-and-attachments/comments-and-attachments.module';
import { RestrictionAvailableAutocompleteComponent } from '../controls/restrictions/restriction-available-autocomplete/restriction-available-autocomplete.component';
import { FormRestrictionTableComponent } from '../controls/restrictions/form-restriction-table/form-restriction-table.component';
import { ConfirmMessageComponent } from '../controls/confirm-message/confirm-message.component';
import { RelatedDocumentsComponent } from '../controls/related-documents/related-documents.component';
import { AcceleratorLocationChipsComponent } from '../controls/accelerator-location-chips/accelerator-location-chips.component';
import { RelatedLinkChipsComponent } from '../controls/related-link-chips/related-link-chips.component';
import { WorkerVerificationComponent } from '../controls/worker-verification/worker-verification.component';
import { ResourceAutocompleteComponent } from '../controls/resources/resource-autocomplete/resource-autocomplete.component';
import { FormTableComponent } from '../controls/form-table/form-table.component';
import { UserSelectComponent } from '../controls/user/user-select/user-select.component';
import { ResourceChipsComponent } from '../controls/resource-chips/resource-chips.component';
import { OtherSystemChipsComponent } from '../controls/other-system-chips/other-system-chips.component';
import { ExtraActionButtonComponent } from '../controls/checklist-components/extra-action-button/extra-action-button.component';
import { NormalInputDialogComponent } from '../controls/normal-input-dialog/normal-input-dialog.component';
import { DisapprovalReasonComponent } from '../controls/disapproval-reason/disapproval-reason.component';
import { APLCategoryAutocompleteComponent } from '../components/apl/apl-category-v2/controls/apl-category-autocomplete/apl-category-autocomplete.component';
import { APLGroupAutocompleteComponent } from '../components/apl/apl-category-v2/controls/apl-group-autocomplete/apl-group-autocomplete.component';
import { ProcedureAutocompleteComponent } from '../controls/procedure-autocomplete/procedure-autocomplete.component';
import { MatCheckboxWithLabelComponent } from '../controls/mat-checkbox-with-label/mat-checkbox-with-label.component';
import { PlaceholderHighlightPipe } from './pipes/placeholder-highlight-pipe.pipe';
import { CatlComponent } from '../controls/catl/catl.component';
import { ActionItemComponent } from '../controls/catl/action-item/action-item.component';
import { ActionItemEditComponent } from '../controls/catl/action-item/action-item-edit/action-item-edit.component';
import { ActionItemService } from '../controls/catl/action-item/action-item.service';
import { CommentsSummaryComponent } from '../controls/comments-summary/comments-summary.component';
import { UserAutocompletePopupComponent } from '../controls/user-autocomplete-popup/user-autocomplete-popup.component';
import { RejectDialogComponent } from '../controls/reject-dialog/reject-dialog.component';
import { ColorPickerComponent } from '../controls/color-picker/color-picker.component';
import { ChooseUsersComponent } from '../components/schedules/dialogs/choose-users/choose-users.component';
import { ChooseRolesComponent } from '../components/schedules/dialogs/choose-roles/choose-roles.component';
import { FileUploadV2Component } from '../controls/file-upload-v2/file-upload-v2.component';
import { RelatedLinkDocumentChipsComponent } from '../controls/related-link-document-chips/related-link-document-chips.component';
import { AddRelatedLinkDocumentComponent } from '../controls/add-related-link-document/add-related-link-document.component';
import { NotesComponent } from '../controls/notes/notes.component';
import { UserChipComponent } from '../components/apl/apl-category-v2/controls/user-chip/user-chip.component';
import { RoleChipComponent } from '../components/apl/apl-category-v2/controls/role-chip/role-chip.component';
import { RssTestShutterChipsComponent } from '../controls/rss-test-shutter-chips/rss-test-shutter-chips.component';
import { RoleSelectionComponent } from '../controls/role-selection/role-selection.component';
import { DatetimePickerComponent } from '../controls/datetime-picker/datetime-picker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ResizableDirective } from '../common/directives/resizable.directive';
import { OperationalRestrictionsComponent } from '../components/catalogs/operational-restrictions/operational-restrictions.component';
import { WaitingDotsComponent } from '../controls/waiting-dots/waiting-dots.component';
import { WaitingTextComponent } from '../controls/waiting-text/waiting-text.component';
import { PvInfoComponent } from '../controls/pvinfo/pvinfo.component';
import { PvInfoDetailsComponent } from '../controls/pvinfo/pvinfo-details/pvinfo-details.component';
import { CatalogHistoryNavigationComponent } from '../controls/catalog-history-navigation/catalog-history-navigation.component';
import { MatSliderModule } from '@angular/material/slider';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

@NgModule({
  declarations: [
    // RelatedLinkChipsComponent,
    RelatedLinkDocumentChipsComponent,
    AddRelatedLinkDocumentComponent,
    AddRelatedLinkComponent,
    UserAutocompletePopupComponent,
    // UserAutocompleteComponent,
    UserChipComponent,
    RoleChipComponent,
    // AddRelatedLinkDocumentComponent,
    // FileUploadV2Component,
    // ProcedureAutocompleteComponent,
    // ProcedureTemplateAutocompleteComponent,
    // APLCategoryCardComponent,
    // ResourceAutocompleteComponent,
    // RssFunctionalTestChipsComponent,
    // ColorPickerComponent,
    // QuillEditorDialogComponent,
    ColorPickerComponent,
    // RoleChipComponent,
    // APLGroupCardComponent,
    // FileUploadComponent,
    FileUploadV2Component,
    // APLCategoryAutocompleteComponent,
    // APLGroupAutocompleteComponent,
    OverlayLoadingComponent,
    PartialOlogComponent,
    // SCFLogComponent,
    WorkerVerificationComponent,
    NotificationComponent,
    HighlightSearchPipe,
    PlaceholderHighlightPipe,
    // AcceleratorLocationChipsComponent,
    // FormatLayoutComponent,
    // MatIconWithTootipComponent,
    // BaseComponent
    FilterBoxComponent,
    CancelDialogComponent,
    TextareaDialogComponent,
    RoleChipsComponent,
    MatCheckboxWithRolesComponent,
    MatCheckboxWithLabelComponent,
    RestrictionAvailableAutocompleteComponent,
    FormRestrictionTableComponent,
    ConfirmMessageComponent,
    RelatedDocumentsComponent,
    RelatedLinkChipsComponent,
    AcceleratorLocationChipsComponent,
    ResourceAutocompleteComponent,
    FormTableComponent,
    UserSelectComponent,
    ResourceChipsComponent,
    OtherSystemChipsComponent,
    ExtraActionButtonComponent,
    NormalInputDialogComponent,
    DisapprovalReasonComponent,
    CatlComponent,
    ActionItemComponent,
    ActionItemEditComponent,
    RejectDialogComponent,
    APLCategoryAutocompleteComponent,
    APLGroupAutocompleteComponent,
    ProcedureAutocompleteComponent,
    CommentsSummaryComponent,
    ChooseUsersComponent,
    ChooseRolesComponent,
    NotesComponent,
    RssTestShutterChipsComponent,
    RoleSelectionComponent,
    DatetimePickerComponent,
    ResizableDirective,
    OperationalRestrictionsComponent,
    WaitingDotsComponent,
    WaitingTextComponent,
    PvInfoComponent,
    PvInfoDetailsComponent,
    OperationalRestrictionsComponent,
    CatalogHistoryNavigationComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    HttpClientModule,
    ScrollingModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatRadioModule,
    // MatFileUploadModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    DragDropModule,
    MatTreeModule,
    MatExpansionModule,
    // TextMaskModule,
    MatSidenavModule,
    CommentsBoxModule,
    CommentsAndAttachmentsModule,
    NgxMaterialTimepickerModule,
    FormsModule,
    MatSliderModule,
    QuillModule.forRoot(),
    NgxsModule.forRoot(reducers, {
      developmentMode: config.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  exports: [
    RouterModule,
    // RelatedLinkChipsComponent,
    RelatedLinkDocumentChipsComponent,
    AddRelatedLinkDocumentComponent,
    AddRelatedLinkComponent,
    // UserAutocompletePopupComponent,
    // UserAutocompleteComponent,
    UserChipComponent,
    RoleChipComponent,
    FileUploadV2Component,
    // ProcedureAutocompleteComponent,
    // ProcedureTemplateAutocompleteComponent,
    // APLCategoryCardComponent,
    // ResourceAutocompleteComponent,
    // RssFunctionalTestChipsComponent,
    // ColorPickerComponent,
    // QuillEditorDialogComponent,
    ColorPickerComponent,
    // RoleChipComponent,
    // APLGroupCardComponent,
    // FileUploadComponent,
    // APLCategoryAutocompleteComponent,
    // APLGroupAutocompleteComponent,
    OverlayLoadingComponent,
    PartialOlogComponent,
    NotificationComponent,
    // SCFLogComponent,
    WorkerVerificationComponent,
    HighlightSearchPipe,
    PlaceholderHighlightPipe,
    // AcceleratorLocationChipsComponent,
    // FormatLayoutComponent,
    // MatIconWithTootipComponent,
    // BaseComponent
    FilterBoxComponent,
    CommentsBoxModule,
    RoleChipsComponent,
    CancelDialogComponent,
    TextareaDialogComponent,
    MatCheckboxWithRolesComponent,
    MatCheckboxWithLabelComponent,
    CommentsAndAttachmentsModule,
    RestrictionAvailableAutocompleteComponent,
    FormRestrictionTableComponent,
    ConfirmMessageComponent,
    RelatedDocumentsComponent,
    AcceleratorLocationChipsComponent,
    RelatedLinkChipsComponent,
    ResourceAutocompleteComponent,
    FormTableComponent,
    UserSelectComponent,
    ResourceChipsComponent,
    OtherSystemChipsComponent,
    ExtraActionButtonComponent,
    NormalInputDialogComponent,
    DisapprovalReasonComponent,
    CatlComponent,
    ActionItemComponent,
    ActionItemEditComponent,
    APLCategoryAutocompleteComponent,
    APLGroupAutocompleteComponent,
    ProcedureAutocompleteComponent,
    NotesComponent,
    RssTestShutterChipsComponent,
    RoleSelectionComponent,
    DatetimePickerComponent,
    ResizableDirective,
    OperationalRestrictionsComponent,
    WaitingDotsComponent,
    WaitingTextComponent,
    PvInfoComponent,
    PvInfoDetailsComponent,
    OperationalRestrictionsComponent,
    CatalogHistoryNavigationComponent
  ],
  providers: [
    LocationsService,
    WorkFlowService,
    ActionItemService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
