import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PendingResourcesType, Catalog, ResourceType } from 'src/app/common/enumerations/enumerations';
import { KeChecklistBuilderService } from 'src/app/components/kenable/ke-checklist-builder/ke-checklist-builder.service';
import { DisapprovalReasonComponent } from 'src/app/controls/disapproval-reason/disapproval-reason.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { Link } from 'src/app/components/catalogs/navigation-links/link/link';
import { Note } from 'src/app/services/notes/note.model';
import { Organization } from 'src/app/services/organization/organization';
import { BeamlineShutterRelation, ResourceSummary, Resource, ResourceUserRelation, ResourceOrganization, BeamlineCatalog } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';
import { TOCA } from 'src/app/components/catalogs/beamline-catalog/resource/toca';
import { Restriction } from 'src/app/controls/restrictions/services/restriction';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { CatalogModel } from '../../catalog-model';
import { CatalogService } from '../../catalog-service';
import { BranchlineComponent } from '../dialogs/branchline/branchline.component';
import { EndstationComponent } from '../dialogs/endstation/endstation.component';
import { FastValveSensorComponent } from '../dialogs/fast-valve-sensor/fast-valve-sensor.component';
import { NoteComponent } from '../dialogs/note/note.component';
import { OrganizationComponent } from '../dialogs/organization/organization.component';
import { ShieldingComponent } from '../dialogs/shielding/shielding.component';
import { VacuumComponent } from '../dialogs/vacuum/vacuum.component';
import { detailsUsers } from '../resources-new/resources-new.component';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { Observable, Subscription } from 'rxjs';
import { MessageTypeEnum } from 'src/app/components/messages/services/message-banner.enums';
import { PVInfoService } from 'src/app/services/pv-info/pv-info.service';
import { FormControl } from '@angular/forms';
import { PV } from 'src/app/services/pv-info/pv-info';


@Component({
  selector: 'app-resource-details-new',
  templateUrl: './resource-details-new.component.html',
  styleUrls: ['./resource-details-new.component.scss']
})

export class ResourceDetailsNewComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() showPrevResource!: boolean;
  @Input() beamlineShutter?: BeamlineShutterRelation;
  @Input() showHistory?: boolean;

  @Output() updateKE = new EventEmitter<any>();
  @Output() loading = new EventEmitter<boolean>();
  @Output() cancelAdd = new EventEmitter();

  // public detailResourceUsers: detailsUsers = { approverUserName: '', updateUserName: '', approvedOn: null, updatedOn: null };
  // public pendingResourceUsers: detailsUsers = { approvedOn: null, updatedOn: null };
  public resourceSummary?: ResourceSummary;
  public shutter?: Resource = new Resource();
  public beamline?: Resource = new Resource();
  public catalogModel!: CatalogModel;
  public RURAux!: ResourceUserRelation;

  public supervisors!: ResourceUserRelation[];

  public oldShutterName?: string;
  public oldBeamlineName?: string;

  public branchlineParent: any;
  public restrictionsParent: any;

  public modifying!: boolean;
  public deleting!: boolean;
  public adding!: boolean;

  public showApproveDisapprove!: boolean;
  public showModifyButton!: boolean;
  public hasShutter!: boolean;
  public fieldRepeatedShutter!: boolean;
  public fieldRepeatedBeamline!: boolean;
  public showCancelButton!: boolean;
  public showChangesTOCA!: boolean;

  currentResourceSummary?: ResourceSummary | null;

  public displayedColumnsShielding: string[] = ['position', 'shieldingName', 'keText', 'visible', 'alsoRelated', 'isRSSD'];
  public displayedColumnsVacuum: string[] = ['position', 'vacuumName', 'minPressure', 'maxPressure', 'channelName', 'location', 'alsoRelated'];
  public displayedColumnsOrganization: string[] = ['organizationName', 'endstation', 'branchline'];
  public displayedColumnsNote: string[] = ['note'];
  public displayedColumnsEndStation: string[] = ['endstation', 'absiTemplateType'];
  public displayedColumnsBranchline: string[] = ['branchline'];
  public displayedColumnsFastValveSensor: string[] = ['position', 'fvsName', 'keText'];
  public displayedColumnsTOCA: string[] = ['name'];

  public dataSourceRestriction: MatTableDataSource<Restriction> = new MatTableDataSource();
  public dataSourceShielding: MatTableDataSource<Resource> = new MatTableDataSource();
  public dataSourceVacuum: MatTableDataSource<Resource> = new MatTableDataSource();
  public dataSourceOrganization: MatTableDataSource<ResourceOrganization> = new MatTableDataSource();
  public dataSourceSupervisor: MatTableDataSource<ResourceUserRelation> = new MatTableDataSource();
  public dataSourceScientist: MatTableDataSource<ResourceUserRelation> = new MatTableDataSource();
  public dataSourceAlternateScientist: MatTableDataSource<ResourceUserRelation> = new MatTableDataSource();
  public dataSourceNote: MatTableDataSource<Note> = new MatTableDataSource();
  public dataSourceEndStation: MatTableDataSource<Resource> = new MatTableDataSource();
  public dataSourceBranchline: MatTableDataSource<Resource> = new MatTableDataSource();
  public dataSourceOtherLinks: MatTableDataSource<Link> = new MatTableDataSource();
  public dataSourceMEDrawing: MatTableDataSource<Link> = new MatTableDataSource();
  public dataSourceAPPhaseSpace: MatTableDataSource<Link> = new MatTableDataSource();
  public dataSourceSASurvey: MatTableDataSource<Link> = new MatTableDataSource();
  public dataSourceFastValveSensor: MatTableDataSource<Resource> = new MatTableDataSource();
  public dataSourceTOCA: MatTableDataSource<TOCA> = new MatTableDataSource();

  beamlineShutterRelations?: BeamlineShutterRelation[];
  beamlineShutterRelationsFiltered?: BeamlineShutterRelation[];
  beamlineShutterRelations$!: Observable<BeamlineShutterRelation[]>;
  beamlineShutterRelationsSubs!: Subscription;

  resourceType = ResourceType;

  resourceStatuses = [{ id: 1, name: 'Active' }, { id: 2, name: 'Commisioning' }, { id: 3, name: 'Decommisioned' }, { id: 4, name: 'Inactive' }];

  beamlineHistory?: BeamlineCatalog[];

  constructor(
    protected override injector: Injector,
    private resourceService: ResourcesService,
    private catalogService: CatalogService,
    private keBuilder: KeChecklistBuilderService,
    private pvService: PVInfoService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reset();
    this.loadBeamlineShutterRelations();
    if (this.showHistory)
      this.getHistory();
  }

  ngOnInit() {
    this.loadPVs();
  }

  override ngOnDestroy(): void {
    this.beamlineShutterRelationsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadBeamlineShutterRelations() {
    this.beamlineShutterRelations$ = this.store.select(state => state.BeamlineShutter.data);
    this.beamlineShutterRelationsSubs = this.beamlineShutterRelations$.subscribe(async data => {
      if (data?.length) {
        this.beamlineShutterRelations = data;
        if (this.beamlineShutter) {
          const beamlineShutter = this.beamlineShutterRelations.find(r => r.beamlineId == this.beamlineShutter?.beamlineId);
          if (beamlineShutter) this.beamlineShutter = beamlineShutter;
          await this.getDetails();
        }
        else this.reset();
      }
    });
  }

  async getDetails() {
    // Get Regular Data
    if (this.beamlineShutter?.beamlineId) {
      this.oldShutterName = this.beamlineShutter.shutterName;
      this.oldBeamlineName = this.beamlineShutter.beamlineName;
      this.loading.emit(true);
      // this.setUpdaterAndApprovalNames(this.beamlineShutter.beamlineId);

      if (this.beamlineShutter.beamlineId > 0) {
        await this.getResourceSummary();
      } else if (this.beamlineShutter.beamlineId < 0) {
        this.x(this.beamlineShutter.beamlineId);
      }
    }
    else if (this.beamlineShutter && !this.beamlineShutter?.beamlineId) {
      this.clear();
      this.adding = true;
      this.showModifyButton = false;
      this.restrictionsParent = { list: this.dataSourceRestriction.data };
      this.branchlineParent = { list: this.dataSourceBranchline.data };
      this.loading.emit(false);
    }
    else this.reset();
  }

  gettingRS = false;

  async getResourceSummary() {
    if (!this.gettingRS) {
      if (this.showPrevResource) {
        if (this.beamlineShutter?.beamlineId)
          this.currentResourceSummary = await this.resourceService.getResourceSummaryByBeamline(this.beamlineShutter.beamlineId).toPromise();
        if (this.beamlineShutter?.beamlineId && this.beamlineShutter.pendingResources.length) {
          this.gettingRS = true;

          const catalogModel = await this.catalogService.GetPendingCatalogById(this.beamlineShutter.beamlineId, Catalog.BeamlineCatalog).toPromise();
          this.resourceSummary = catalogModel?.resourceSummary;
          if (this.resourceSummary) {
            await this.addResourceSummaryInfo(this.resourceSummary);
            this.fillUserInfo(this.resourceSummary.alternateScientists);
            this.fillUserInfo(this.resourceSummary.scientists);
            this.fillUserInfo(this.resourceSummary.supervisors);
          }
          this.gettingRS = false;
        }
        else {
          this.showPrevResource = false;
          await this.getResourceSummary();
        }
      } else {
        if (this.beamlineShutter?.beamlineId) {
          this.gettingRS = true;
          const data = await this.resourceService.getResourceSummaryByBeamline(this.beamlineShutter.beamlineId).toPromise();
          if (data) {
            this.currentResourceSummary = null;
            await this.addResourceSummaryInfo(data);
          }
          // this.loading.emit(false);
          this.gettingRS = false;
        }
      }
      this.loading.emit(false);
    }
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      rssCtrl: this.rssCtrl,
      pssCtrl: this.pssCtrl
    });
    this.formGroup.disable();
  }

  async addResourceSummaryInfo(data: ResourceSummary, isShowModification?: boolean) {
    this.resourceSummary = data as ResourceSummary;
    this.hasShutter = false;
    if (this.resourceSummary) {
      if (this.resourceSummary?.shutter) {
        this.shutter = this.resourceSummary.shutter as Resource;
        if (this.shutter?.name != null) {
          this.hasShutter = true;
        }
        if (!this.shutter) { this.shutter = new Resource(); }
      }
      else this.shutter = new Resource();
      this.buildForm();
      this.rssValue = undefined;
      this.pssValue = undefined;
      this.getPVValues();

      this.beamline = this.resourceSummary.beamline as Resource;
      if (!this.beamline) this.beamline = new Resource();
      this.dataSourceEndStation = new MatTableDataSource(this.resourceSummary.endstations);
      this.dataSourceBranchline = new MatTableDataSource(this.resourceSummary.branchlines);
      this.dataSourceRestriction = new MatTableDataSource(this.resourceSummary.restrictions);
      this.dataSourceShielding = new MatTableDataSource(this.resourceSummary.shieldings?.sort((a, b) => this.utils.sort(a.position, b.position)));
      this.dataSourceVacuum = new MatTableDataSource(this.convertSciNotationToString(this.resourceSummary.vacuums as Resource[]));
      this.dataSourceOrganization = new MatTableDataSource(this.resourceSummary.organizations);
      this.dataSourceSupervisor = new MatTableDataSource(this.joinRURs(this.resourceSummary.supervisors));
      this.dataSourceScientist = new MatTableDataSource(this.joinRURs(this.resourceSummary.scientists));
      this.dataSourceAlternateScientist = new MatTableDataSource(this.joinRURs(this.resourceSummary.alternateScientists));
      this.dataSourceNote.data = this.resourceSummary.notes as Note[];
      this.dataSourceOtherLinks.data = this.resourceSummary.otherLinks ?? [];

      if (!isShowModification) { this.dataSourceMEDrawing.data = this.resourceSummary.meDrawing ?? []; }
      if (!isShowModification) { this.dataSourceAPPhaseSpace.data = this.resourceSummary.apPhaseSpace ?? []; }
      if (!isShowModification) { this.dataSourceSASurvey.data = this.resourceSummary.saSurvey ?? []; }
      this.dataSourceFastValveSensor.data = this.resourceSummary.fastValveSensors as Resource[];
      this.dataSourceTOCA = new MatTableDataSource(this.resourceSummary.toca);

      // if (!isShowModification) { this.dataSourceTOCA.data = this.resourceSummary.toca ?? []; }
      // if (this.resourceSummary.toca && !isShowModification) {
      //   // this.removeColumnOfDisplayColumns(this.displayedColumnsTOCA, 'showChanges');
      //   if (this.beamlineShutter?.beamlineId) {
      //     const tocaChanges = await this.resourceService.getTOCAChangesByResourceId(this.beamlineShutter.beamlineId, true).toPromise();
      //     if (this.resourceSummary.toca.some(x => x.status === 5)) {
      //       if (tocaChanges?.some(x => x.status === 0) || tocaChanges?.length !== this.resourceSummary.toca.length) {
      //         // this.displayedColumnsTOCA.push('showChanges');
      //       }
      //     } else if (tocaChanges?.some(x => x.status === 0) && tocaChanges?.filter(x => x.status === 0).length !== this.resourceSummary.toca.length) {
      //       // this.displayedColumnsTOCA.push('showChanges');
      //     }
      //   }
      // }

      if (this.resourceSummary.branchlines || this.resourceSummary.branchlines != null) {
        this.branchlineParent = { list: this.resourceSummary.branchlines };
      }
      this.restrictionsParent = { list: this.resourceSummary.restrictions };
    }
    this.showModifyButton = (this.isSA() || this.hasPrivilege(PrivilegeEnum.BeamlineCatalog));
    this.showCancelButton = false;

    const pendingResource = this.beamlineShutter?.pendingResources[this.beamlineShutter?.pendingResources.length - 1];

    if (pendingResource) {
      this.showApproveDisapprove = this.isSA() && this.currentUser?.id != pendingResource.createdBy;
      this.showModifyButton = (this.isSA() || this.hasPrivilege(PrivilegeEnum.BeamlineCatalog)) && this.currentUser?.id == pendingResource.createdBy && this.showPrevResource;
      this.showCancelButton = (this.isSA() || this.hasPrivilege(PrivilegeEnum.BeamlineCatalog)) && this.currentUser?.id == pendingResource.createdBy && this.showPrevResource;
    }
    this.adding = false;
  }

  x(id: number) {
    this.catalogService.GetPendingCatalogById(id, Catalog.BeamlineCatalog).toPromise().then(data => {
      if (data?.resourceSummary)
        this.addResourceSummaryInfo(data.resourceSummary);
      const pendingResource = this.beamlineShutter?.pendingResources?.find(x => (x.type == PendingResourcesType.Details || x.type == PendingResourcesType.Default) && (this.hasPrivilege(PrivilegeEnum.BeamlineCatalog) || this.isSA()));
      this.showApproveDisapprove = this.currentUser?.id != pendingResource?.createdBy;
      this.loading.emit(false);
    }).catch(() => {
      this.alert.defaultError();
      this.loading.emit(false);
    });
  }

  // setUpdaterAndApprovalNames(beamlineId: number) {
  //   this.catalogService.GetPendingResourcesUsersByResourceId(beamlineId, PendingResourcesType.Details).toPromise().then(PendingResourceUsers => {
  //     this.detailResourceUsers.approverUserName = this.getUser(PendingResourceUsers?.approverId)?.name;
  //     this.detailResourceUsers.approvedOn = PendingResourceUsers?.approvedOn;
  //     this.detailResourceUsers.updateUserName = this.getUser(PendingResourceUsers?.updateById)?.name;
  //     this.detailResourceUsers.updatedOn = PendingResourceUsers?.updatedOn;
  //   });
  //   const pendingResource = this.beamlineShutter?.pendingResources[this.beamlineShutter.pendingResources.length - 1];
  //   if (this.showPrevResource && pendingResource) {
  //     this.pendingResourceUsers.updateUserName = this.getUser(pendingResource?.createdBy)?.name;
  //     this.pendingResourceUsers.updatedOn = pendingResource?.createdOn;
  //   }
  // }

  async saveResource() {
    this.loading.emit(true);
    if (await this.isValid()) {
      const catalogModel = new CatalogModel();
      catalogModel.resourceSummary = this.addInputDataToResourceSummary();
      if (!this.modifying) {
        this.catalogService.SaveAsDraft(catalogModel, Catalog.BeamlineCatalog).toPromise().then(() => {
          this.alert.message('beamline_Create_SentToApprove');
          this.reset();
          this.showPrevResource = true;
        }).catch(() => {
          this.alert.defaultError();
        });
      } else {
        this.catalogService.SaveAsDraftType(catalogModel, Catalog.BeamlineCatalog, PendingResourcesType.Details).toPromise().then(() => {
          this.reset();
          this.showPrevResource = true;
          this.alert.message('beamline_Update_SentToApprove');
        }).catch(() => {
          this.alert.defaultError();
        });
      }
    } else {
      this.loading.emit(false);
    }
  }

  getDataFromRestriction(restrictions: Restriction[]) {
    this.dataSourceRestriction.data = restrictions;
  }

  openAddEndstationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = new Resource();
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(EndstationComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then(data => {
      if (data) {
        if (this.isResourceRepeated(this.dataSourceEndStation.data, data, 'Endstation')) {
          this.addEndstation(data);
          this.setFormDirty();
        }
      }
    });
  }

  openEditEndstationDialog(endstation: Resource, index: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.utils.cloneDeep(endstation);
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(EndstationComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then((endstationUpdated: Resource) => {
      if (endstationUpdated) {
        this.dataSourceEndStation.data[index] = endstationUpdated;
        this.dataSourceEndStation.filter = '';
        this.updateResourceNames(endstationUpdated, endstation.name ?? '');
        this.setFormDirty();
      }
    });
  }

  openAddBranchlineDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = new Resource();
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(BranchlineComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (this.isResourceRepeated(this.dataSourceBranchline.data, data, 'Branchline')) {
          this.addBranchline(data);
          this.setFormDirty();
          this.branchlineParent = { list: this.dataSourceBranchline.data };
        }
      }
    });
  }

  openEditBranchlineDialog(branchline: Resource, index: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.utils.cloneDeep(branchline);
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(BranchlineComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then((branchlineUpdated: Resource) => {
      if (branchlineUpdated) {
        this.dataSourceBranchline.data[index] = branchlineUpdated;
        this.dataSourceBranchline.filter = '';
        this.updateResourceNames(branchlineUpdated, branchline.name ?? '');
        this.setFormDirty();
      }
    });
  }

  openAddShieldingDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      resource: new Resource(),
      shutter: this.shutter
    };
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ShieldingComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (this.isResourceRepeated(this.dataSourceShielding.data, data, 'Shielding')) {
          this.addShielding(data);
          this.setFormDirty();
        }
      }
    });
  }

  openEditShieldingDialog(shielding: Resource) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      resource: shielding,
      shutter: this.shutter
    };
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ShieldingComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data)
        this.setFormDirty();
    });
  }

  openAddVacuumDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      resource: new Resource(),
      shutter: this.shutter
    };
    dialogConfig.width = '500px';
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(VacuumComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (this.isResourceRepeated(this.dataSourceVacuum.data, data, 'Vacuum')) {
          this.addVacuum(data);
          this.setFormDirty();
        }
      }
    });
  }

  openEditVacuumDialog(vacuum: Resource) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      resource: vacuum,
      shutter: this.shutter
    };
    dialogConfig.width = '500px';
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(VacuumComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      const index = this.dataSourceVacuum.data.findIndex(x => x.id == data.id);
      this.dataSourceVacuum.data[index] = data;
      this.setFormDirty();
    });
  }

  openAddOrganizationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { resourceOrganization: new ResourceOrganization(), branchlines: this.dataSourceBranchline.data, endstations: this.dataSourceEndStation.data };
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(OrganizationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data: ResourceOrganization) => {
      if (data && data.organization && data.branchline && data.endstation) {
        if (this.isOrganizationRepeted(this.dataSourceOrganization.data, data.organization, data.branchline, data.endstation)) {
          this.addOrganization(data);
          this.setFormDirty();
        }
      }
    });
  }

  openEditOrganizationDialog(resourceOrganization: ResourceOrganization, index: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = { resourceOrganization: this.utils.cloneDeep(resourceOrganization), branchlines: this.dataSourceBranchline.data, endstations: this.dataSourceEndStation.data };
    const dialogRef = this.dialog.open(OrganizationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (this.isOrganizationRepeted(this.dataSourceOrganization.data, data.organization, data.branchline, data.endstation, resourceOrganization)) {
          this.dataSourceOrganization.data[index] = data;
          this.dataSourceOrganization.filter = '';
          this.setFormDirty();
        }
      }
    });
  }

  openAddNoteDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = new Note();
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(NoteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.addNote(data);
        this.setFormDirty();
      }
    });
  }

  openEditNoteDialog(note: Note) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = note;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(NoteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.setFormDirty();
    });
  }

  openAddFastValveSensorDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = new Resource();
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(FastValveSensorComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data != null) {
        this.addFastValveSensor(data);
        this.setFormDirty();
      }
    });
  }

  openEditFastValveSensorDialog(fastValveSensor: Resource) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = fastValveSensor;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(FastValveSensorComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.setFormDirty();
    });
  }

  openAddDisapprovalDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = '';
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DisapprovalReasonComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.disapprove(data);
      }
    });
  }

  addEndstation(data: Resource) {
    const endstation = this.createNewEndstation(this.dataSourceEndStation.data.length + 1, data);
    this.dataSourceEndStation.data.push(endstation);
    this.dataSourceEndStation.filter = '';
  }

  addBranchline(data: Resource) {
    const branchline = this.createNewBranchline(this.dataSourceBranchline.data.length + 1, data);
    this.dataSourceBranchline.data.push(branchline);
    this.dataSourceBranchline.filter = '';
  }

  addShielding(data: Resource) {
    const shielding = this.createNewShielding(this.dataSourceShielding.data.length + 1, data);
    this.dataSourceShielding.data.push(shielding);
    this.dataSourceShielding.filter = '';
  }

  addVacuum(data: Resource) {
    const vacuum = this.createNewVacuum(this.dataSourceVacuum.data.length + 1, data);
    this.dataSourceVacuum.data.push(vacuum);
    this.dataSourceVacuum.filter = '';
  }

  addOrganization(data: ResourceOrganization) {
    this.dataSourceOrganization.data.push(data);
    this.dataSourceOrganization.filter = '';
  }

  addNote(data: Note) {
    this.dataSourceNote.data.push(data);
    this.dataSourceNote.filter = '';
  }

  addFastValveSensor(data: Resource) {
    const fastValveSensor = this.createNewFastValveSensor(this.dataSourceFastValveSensor.data.length + 1, data);
    this.dataSourceFastValveSensor.data.push(fastValveSensor);
    this.dataSourceFastValveSensor.filter = '';
  }

  approve() {
    this.loading.emit(true);
    if (this.beamlineShutter?.beamlineId)
      this.catalogService.Approve(this.beamlineShutter.beamlineId, Catalog.BeamlineCatalog, PendingResourcesType.Details).toPromise().then(savedId => {
        this.alert.message('beamline_Approved');
        if (this.shutter?.id && savedId) {
          this.keBuilder.UpdateTemplatesAfterUpdate(this.shutter.id, savedId).toPromise().then(data => {
            const resources = data as Resource[];
            this.alert.message('beamline_KETemplates_Updated', [new MessagePlaceholder('{resources}', resources.map(r => r.name).join(', '))], MessageTypeEnum.Info);
            this.reset();
          }).catch(() => {
            this.alert.defaultError();
          });
        } else {
          this.reset();
        }
      }).catch(() => {
        this.alert.defaultError();
      });
  }

  disapprove(disapprovalReason: string) {
    if (this.beamlineShutter?.beamlineId)
      this.catalogService.Unapprove(this.beamlineShutter.beamlineId, Catalog.BeamlineCatalog, disapprovalReason, PendingResourcesType.Details).toPromise().then(() => {
        this.alert.message('beamline_Disapproved');
        this.reset();
      }).catch(() => {
        this.alert.defaultError();
      });
  }

  modifyResource() {
    this.showModifyButton = false;
    this.adding = false;
    this.deleting = false;
    this.modifying = true;
    this.formGroup.enable();
    this.setFormDirty(false);
  }

  deleteEndstation(data: Resource) {
    this.dialogService.ConfirmationDialog('Remove', 'this <b>Endstation</b>').subscribe((result) => {
      if (result) {
        const index = this.dataSourceEndStation.data.indexOf(data);
        this.dataSourceEndStation.data.splice(index, 1);
        this.deleteResourceNames(data);
        this.deleteResourceNamesNtype(data);
        if (this.dataSourceEndStation.data.length > 0) {
          this.reorderPositions(this.dataSourceEndStation.data);
        }
        this.dataSourceEndStation.filter = '';
        this.setFormDirty();
      }
    });
  }

  deleteBranchline(data: Resource) {
    this.dialogService.ConfirmationDialog('Remove', 'this <b>Branchline</b>').subscribe((result) => {
      if (result) {
        const index = this.dataSourceBranchline.data.indexOf(data);
        this.dataSourceBranchline.data.splice(index, 1);
        this.deleteResourceNames(data);
        this.deleteResourceNamesNtype(data);
        if (this.dataSourceBranchline.data.length > 0) {
          this.reorderPositions(this.dataSourceBranchline.data);
        }
        this.dataSourceBranchline.filter = '';
        this.branchlineParent = { list: this.dataSourceBranchline.data };
        this.setFormDirty();
      }
    });
  }

  deleteShielding(data: Resource) {
    this.dialogService.ConfirmationDialog('Remove', 'this <b>Shielding</b>').subscribe((result) => {
      if (result) {
        const index = this.dataSourceShielding.data.indexOf(data);
        this.dataSourceShielding.data.splice(index, 1);
        if (this.dataSourceShielding.data.length > 0) {
          this.reorderPositions(this.dataSourceShielding.data);
        }
        this.dataSourceShielding.filter = '';
        this.setFormDirty();
      }
    });
  }

  deleteVacuum(data: Resource) {
    this.dialogService.ConfirmationDialog('Remove', 'this <b>Vacuum</b>').subscribe((result) => {
      if (result) {
        const index = this.dataSourceVacuum.data.indexOf(data);
        this.dataSourceVacuum.data.splice(index, 1);
        if (this.dataSourceVacuum.data.length > 0) {
          this.reorderPositions(this.dataSourceVacuum.data);
        }
        this.dataSourceVacuum.filter = '';
        this.setFormDirty();
      }
    });
  }

  deleteOrganization(data: ResourceOrganization) {
    this.dialogService.ConfirmationDialog('Remove', 'this <b>Organization</b>').subscribe((result) => {
      if (result) {
        const index = this.dataSourceOrganization.data.indexOf(data);
        this.dataSourceOrganization.data.splice(index, 1);
        this.dataSourceOrganization.filter = '';
        this.setFormDirty();
      }
    });
  }

  deleteNote(data: Note) {
    this.dialogService.ConfirmationDialog('Remove', 'this <b>Note</b>').subscribe((result) => {
      if (result) {
        const index = this.dataSourceNote.data.indexOf(data);
        this.dataSourceNote.data.splice(index, 1);
        this.dataSourceNote.filter = '';
        this.setFormDirty();
      }
    });
  }

  deleteFastValveSensor(data: Resource) {
    this.dialogService.ConfirmationDialog('Remove', 'this <b>Valve Sensor</b>').subscribe((result) => {
      if (result) {
        const index = this.dataSourceFastValveSensor.data.indexOf(data);
        this.dataSourceFastValveSensor.data.splice(index, 1);
        if (this.dataSourceFastValveSensor.data.length > 0) {
          this.reorderPositions(this.dataSourceFastValveSensor.data);
        }
        this.dataSourceFastValveSensor.filter = '';
        this.setFormDirty();
      }
    });
  }

  createNewEndstation(position: number, resource?: Resource): Resource {
    const endstationCreated: Resource = {
      id: 0,
      name: resource?.name,
      type: ResourceType.Endstation,
      position,
      status: 1,
      absiTemplateTypeID: resource?.absiTemplateTypeID,
      absiTemplateName: resource?.absiTemplateName
    };
    return endstationCreated;
  }

  createNewBranchline(position: number, resource?: Resource): Resource {
    const branchlineCreated: Resource = {
      id: 0,
      name: resource?.name,
      type: ResourceType.Branchline,
      position,
      status: 1,
    };
    return branchlineCreated;
  }

  createNewShielding(position: number, resource?: Resource): Resource {
    const shieldingCreated: Resource = {
      id: resource?.id ?? 0,
      name: resource?.name,
      type: ResourceType.Shielding,
      position,
      status: 1,
      keText: resource?.keText,
      checkboxVisible: true,
      sharedWith: resource?.sharedWith,
    };
    return shieldingCreated;
  }

  createNewVacuum(position: number, resource?: Resource): Resource {
    const vacuumCreated: Resource = {
      id: resource?.id ?? 0,
      name: resource?.name,
      type: ResourceType.Vacuum,
      position,
      status: 1,
      keRequirement: resource?.keRequirement,
      channelName: resource?.channelName,
      location: resource?.location,
      keVacuumMaxRequirement: resource?.keVacuumMaxRequirement,
      keVacuumMinRequirement: resource?.keVacuumMinRequirement,
      MinPressureString: resource?.MinPressureString,
      MaxPressureString: resource?.MaxPressureString,
      sharedWith: resource?.sharedWith
    };
    return vacuumCreated;
  }

  createNewShutter(resource: Resource): Resource {
    const shutterCreated: Resource = {
      id: resource.id,
      name: resource.name,
      type: ResourceType.Shutter,
      status: 1,
      rssChannelName: resource.rssChannelName,
      pssChannelName: resource.pssChannelName,
      drawingPostLocation: resource.drawingPostLocation,
      rssRackLocation: resource.rssRackLocation,
      epsRackLocation: resource.epsRackLocation,
      fvsemLocation: resource.fvsemLocation
    };
    return shutterCreated;
  }

  createNewBeamline(resource: Resource): Resource {
    const beamlineCreated: Resource = {
      id: resource.id,
      name: resource.name,
      type: ResourceType.Beamline,
      status: 1,
      rssChannelName: resource.rssChannelName,
      pssChannelName: resource.pssChannelName,
      description: resource.description,
      beamLineExtension: resource.beamLineExtension,
      statusResourceId: resource.statusResourceId
    };
    return beamlineCreated;
  }

  createNewFastValveSensor(position: number, resource?: Resource): Resource {
    const fastValveSensorCreated: Resource = {
      id: 0,
      name: resource?.name,
      type: ResourceType.FastValveSensor,
      position,
      status: 1,
      keText: resource?.keText,
    };
    return fastValveSensorCreated;
  }

  reorderPositions(resources: Resource[]) {
    let addPosition = 1;
    resources.map(element => {
      element.position = addPosition++;
    });
  }

  setShutterSelected() {
    if (this.shutter) {
      this.shutter = this.createNewShutter(this.shutter);
      this.buildForm();
      this.formGroup.enable();
      this.updateResourceNames(this.shutter);
      this.hasShutter = true;
    } else {
      this.hasShutter = false;
      this.formGroup.disable();
    }
  }

  cancelChanges() {
    if (this.beamlineShutter?.pendingResources.length && this.beamlineShutter.pendingResources[0].createdBy == this.currentUser?.id) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        message: 'Are you sure you want to Cancel you changes?',
        icon: 'question'
      };
      const dialogRef = this.dialog.open(YesNoDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        if (data && this.beamlineShutter?.beamlineId) {
          this.catalogService.CancelPending(this.beamlineShutter.beamlineId, Catalog.BeamlineCatalog, PendingResourcesType.Details).subscribe(() => {
            this.reset();
            this.alert.message('beamline_Disapproved');
          });
        }
      });
    }
    else {
      setTimeout(() => {
        this.reset();
      }, 300);
    }
  }

  cancel() {
    this.loadBeamlineShutterRelations();
    if (this.adding)
      this.cancelAdd.emit();
    this.modifying = false;
    this.adding = false;
    this.deleting = false;
    this.showCancelButton = false;
    this.setFormDirty(false);
  }

  reset() {
    this.scrollToTop();
    this.adding = false;
    this.modifying = false;
    this.deleting = false;

    this.showApproveDisapprove = false;
    this.showModifyButton = false;
    this.showCancelButton = false;

    this.hasShutter = false;
    this.fieldRepeatedShutter = false;
    this.fieldRepeatedBeamline = false;
    this.showChangesTOCA = false;

    this.setFormDirty(false);

    this.loading.emit(false);

  }

  clear() {
    this.resetRURAuxData();
    this.shutter = new Resource();
    this.beamline = new Resource();
    this.catalogModel = new CatalogModel();
    this.supervisors = [];

    this.dataSourceRestriction = new MatTableDataSource();
    this.dataSourceShielding = new MatTableDataSource();
    this.dataSourceVacuum = new MatTableDataSource();
    this.dataSourceOrganization = new MatTableDataSource();
    this.dataSourceSupervisor = new MatTableDataSource();
    this.dataSourceScientist = new MatTableDataSource();
    this.dataSourceAlternateScientist = new MatTableDataSource();
    this.dataSourceNote = new MatTableDataSource();
    this.dataSourceEndStation = new MatTableDataSource();
    this.dataSourceBranchline = new MatTableDataSource();
    this.dataSourceOtherLinks = new MatTableDataSource();
    this.dataSourceMEDrawing = new MatTableDataSource();
    this.dataSourceAPPhaseSpace = new MatTableDataSource();
    this.dataSourceSASurvey = new MatTableDataSource();
    this.dataSourceFastValveSensor = new MatTableDataSource();
    this.dataSourceTOCA = new MatTableDataSource();
    // this.detailResourceUsers = new detailsUsers();
  }

  reorderTable(event: any, dataSource: MatTableDataSource<any>) {
    if (this.adding || this.modifying) {
      moveItemInArray(dataSource.data, event.previousIndex, event.currentIndex);
      this.reorderPositions(dataSource.data);
      this.setFormDirty();
      dataSource.filter = '';
    }
  }

  getColumns(type: ResourceType) {
    switch (type) {
      case ResourceType.Shielding:
        return (!this.adding && !this.modifying) ? this.displayedColumnsShielding : this.displayedColumnsShielding.concat('add');
      case ResourceType.Vacuum:
        return (!this.adding && !this.modifying) ? this.displayedColumnsVacuum : this.displayedColumnsVacuum.concat('add');
      case ResourceType.Endstation:
        return (!this.adding && !this.modifying) ? this.displayedColumnsEndStation : this.displayedColumnsEndStation.concat('add');
      case ResourceType.Branchline:
        return (!this.adding && !this.modifying) ? this.displayedColumnsBranchline : this.displayedColumnsBranchline.concat('add');
      case ResourceType.FastValveSensor:
        return (!this.adding && !this.modifying) ? this.displayedColumnsFastValveSensor : this.displayedColumnsFastValveSensor.concat('add');
      case ResourceType.Note:
        return (!this.adding && !this.modifying) ? this.displayedColumnsNote : this.displayedColumnsNote.concat('add');
      case ResourceType.Organization:
        return (!this.adding && !this.modifying) ? this.displayedColumnsOrganization : this.displayedColumnsOrganization.concat('add');
      case ResourceType.TOCA:
        return (!this.adding && !this.modifying) ? this.displayedColumnsTOCA : this.displayedColumnsTOCA.concat('showChanges');
    }
    return [];
  }

  async isValid() {
    const resourceSummary: ResourceSummary = {
      shutter: this.shutter,
      beamline: this.beamline
    };
    const catalogModel: CatalogModel = {
      resourceSummary
    };
    if (!this.shutter?.name && !this.beamline?.name) {
      if (!this.shutter?.name) {
        this.alert.message('shutter_Required');
        return false;
      }
      if (!this.beamline?.name) {
        this.alert.message('beamline_Name_Required');
        return false;
      } else {
        if (this.oldBeamlineName !== this.beamline.name) {
          this.fieldRepeatedBeamline = true;
        }
      }
      if (!this.beamline.statusResourceId) {
        this.alert.message('beamline_Status_Required');
        return false;
      }
    }
    if (this.beamline?.name || this.shutter?.name) {
      if (!this.beamline?.name) {
        this.alert.message('beamline_Name_Required');
        return false;
      } else {
        if (this.oldBeamlineName !== this.beamline.name) {
          this.fieldRepeatedBeamline = true;
        }
      }
      if (!this.beamline.statusResourceId) {
        this.alert.message('beamline_Status_Required');
        return false;
      }
      if (this.shutter?.name) {
        if (this.oldShutterName !== this.shutter?.name) {
          this.fieldRepeatedShutter = true;
        }
      }
    }

    return true;
  }

  isResourceRepeated(resources: Resource[], resource: Resource, typeName: string) {
    if (resources.some(x => x.name?.toLowerCase() === resource.name?.toLowerCase())) {
      this.alert.message('resource_Added', [new MessagePlaceholder('{resourceTypeName}', typeName), new MessagePlaceholder('{resourceName}', resource.name?.toUpperCase())]);
      return false;
    }
    return true;
  }

  isOrganizationRepeted(resourceOrganizations: ResourceOrganization[], organization: Organization, branchline: Resource, endstation: Resource, oldResourceOrganization?: ResourceOrganization): boolean {
    let isOrganizationNameEqual: boolean = false;
    if (resourceOrganizations.some(resourceOrg => resourceOrg.organization?.name === organization.name && (resourceOrg.branchline?.name === branchline?.name || resourceOrg.endstation?.name === endstation?.name))) {
      if (oldResourceOrganization) { isOrganizationNameEqual = oldResourceOrganization.organization?.name === organization.name; }
      if (!isOrganizationNameEqual) {
        this.alert.message('organization_Added', [new MessagePlaceholder('{organizationName}', organization.name?.toUpperCase())]);
        return false;
      }
    }
    return true;
  }

  addInputDataToResourceSummary(): ResourceSummary | undefined {
    const resourceSummary = new ResourceSummary();
    if (resourceSummary) {
      if (this.dataSourceEndStation.data.length) {
        resourceSummary.endstations = this.dataSourceEndStation.data;
      } else {
        resourceSummary.endstations = [];
      }
      if (this.dataSourceBranchline.data.length) {
        resourceSummary.branchlines = this.dataSourceBranchline.data;
      } else {
        resourceSummary.branchlines = [];
      }
      if (this.dataSourceRestriction.data.length) {
        resourceSummary.restrictions = this.dataSourceRestriction.data;
      } else {
        resourceSummary.restrictions = [];
      }
      if (this.dataSourceShielding.data.length) {
        this.reorderPositions(this.dataSourceShielding.data);
        resourceSummary.shieldings = this.dataSourceShielding.data;
      } else {
        resourceSummary.shieldings = [];
      }
      if (this.dataSourceVacuum.data.length) {
        this.reorderPositions(this.dataSourceVacuum.data);
        resourceSummary.vacuums = this.convertSciNotationToNumber(this.dataSourceVacuum.data);
      } else {
        resourceSummary.vacuums = [];
      }
      if (this.dataSourceOrganization.data.length) {
        resourceSummary.organizations = this.dataSourceOrganization.data;
      } else {
        resourceSummary.organizations = [];
      }
      if (this.dataSourceSupervisor.data.length) {
        resourceSummary.supervisors = this.addRURs(this.dataSourceSupervisor.data);
      } else {
        resourceSummary.supervisors = [];
      }
      if (this.dataSourceScientist.data.length) {
        resourceSummary.scientists = this.addRURs(this.dataSourceScientist.data);
      } else {
        resourceSummary.scientists = [];
      }
      if (this.dataSourceAlternateScientist.data.length) {
        resourceSummary.alternateScientists = this.addRURs(this.dataSourceAlternateScientist.data);
      } else {
        resourceSummary.alternateScientists = [];
      }
      if (this.dataSourceNote.data.length) {
        resourceSummary.notes = this.dataSourceNote.data;
      } else {
        resourceSummary.notes = [];
      }
      if (this.dataSourceFastValveSensor.data.length) {
        resourceSummary.fastValveSensors = this.dataSourceFastValveSensor.data;
      } else {
        resourceSummary.fastValveSensors = [];
      }
      if (this.dataSourceOtherLinks.data.length) {
        resourceSummary.otherLinks = this.dataSourceOtherLinks.data;
      } else {
        resourceSummary.otherLinks = [];
      }
      if (this.shutter) {
        resourceSummary.shutter = this.createNewShutter(this.shutter);
      }
      if (this.beamline)
        resourceSummary.beamline = this.createNewBeamline(this.beamline);
    }
    return resourceSummary;
  }

  updateResourceNames(resource: Resource, oldResourceName?: string) {
    if (this.dataSourceOrganization.data.length) {
      this.dataSourceOrganization.data.map(resourceOrg => {
        if (resource.type === ResourceType.Endstation && resourceOrg.endstation) {
          if (resourceOrg.endstation.id === resource.id) { resourceOrg.endstation = resource; }
          else if (resourceOrg.endstation.name === oldResourceName) { resourceOrg.endstation = resource; }
        }
        if (resource.type === ResourceType.Branchline && resourceOrg.branchline) {
          if (resourceOrg.branchline.id === resource.id) { resourceOrg.branchline = resource; }
          else if (resourceOrg.branchline.name === oldResourceName) { resourceOrg.branchline = resource; }
        }
      });
      this.dataSourceOrganization.filter = '';
    }
    if (oldResourceName) {
      this.dataSourceSupervisor.data = this.updateResourceNamesOfRURs(this.dataSourceSupervisor.data, resource, oldResourceName);
      this.dataSourceScientist.data = this.updateResourceNamesOfRURs(this.dataSourceScientist.data, resource, oldResourceName);
      this.dataSourceAlternateScientist.data = this.updateResourceNamesOfRURs(this.dataSourceAlternateScientist.data, resource, oldResourceName);
    }
    if (this.dataSourceRestriction.data.length) {
      this.dataSourceRestriction.data.map(x => {
        if (x.locationID) {
          if (x.locationID === resource.id) { x.location = resource; }
        } else if (x.location?.name === oldResourceName) { x.location = resource; }
      });
      this.dataSourceRestriction.filter = '';
    }
  }

  deleteResourceNames(resourceToDelete: Resource) {
    let index = -1;
    if (this.dataSourceOrganization.data.length) {
      this.dataSourceOrganization.data.map(resourceOrg => {
        if (resourceToDelete.type === ResourceType.Endstation && resourceOrg.endstation) {
          if (resourceOrg.endstation.id === resourceToDelete.id) { resourceOrg.endstation = new Resource(); }
          else if (resourceOrg.endstation.name === resourceToDelete.name) { resourceOrg.endstation = new Resource(); }
        }
        if (resourceToDelete.type === ResourceType.Branchline && resourceOrg.branchline) {
          if (resourceOrg.branchline.id === resourceToDelete.id) { resourceOrg.branchline = new Resource(); }
          else if (resourceOrg.branchline.name === resourceToDelete.name) { resourceOrg.branchline = new Resource(); }
        }
      });
      this.dataSourceOrganization.filter = '';
    }
    this.dataSourceSupervisor.data = this.joinRURs(this.addRURs(this.deleteResourceNamesOfRURs(this.dataSourceSupervisor.data, resourceToDelete)));
    this.dataSourceScientist.data = this.joinRURs(this.addRURs(this.deleteResourceNamesOfRURs(this.dataSourceScientist.data, resourceToDelete)));
    this.dataSourceAlternateScientist.data = this.joinRURs(this.addRURs(this.deleteResourceNamesOfRURs(this.dataSourceAlternateScientist.data, resourceToDelete)));

    if (this.dataSourceRestriction.data.length) {
      index = 0;
      this.dataSourceRestriction.data.map(x => {
        index++;
        if (x.locationID) {
          if (x.locationID === resourceToDelete.id) {
            this.dataSourceRestriction.data.splice(index - 1, 1);
          }
        }
      });
      this.dataSourceAlternateScientist.filter = '';
    }
  }

  deleteResourceNamesNtype(resource: Resource) {
    let index = 0;
    if (this.dataSourceOrganization.data.length) {
      index = 0;
      this.dataSourceOrganization.data.map(x => {
        index++;
        if (!x.endstationId) {
          if (x.endstation?.name === resource.name && x.endstation?.type === resource.type) {
            this.dataSourceOrganization.data.splice(index - 1, 1);
          }
        }
        if (!x.branchlineId) {
          if (x.branchline?.name === resource.name && x.branchline?.type === resource.type) {
            this.dataSourceOrganization.data.splice(index - 1, 1);
          }
        }
      });
      this.dataSourceOrganization.filter = '';
    }
    if (this.dataSourceSupervisor.data.length) {
      index = 0;
      this.dataSourceSupervisor.data.map(x => {
        index++;
        if (!x.endstationId) {
          if (x.endstation?.name === resource.name && x.endstation?.type === resource.type) {
            this.dataSourceSupervisor.data.splice(index - 1, 1);
          }
        }
        if (!x.branchlineId) {
          if (x.branchline?.name === resource.name && x.branchline?.type === resource.type) {
            this.dataSourceSupervisor.data.splice(index - 1, 1);
          }
        }
      });
      this.dataSourceSupervisor.filter = '';
    }
    if (this.dataSourceScientist.data.length) {
      index = 0;
      this.dataSourceScientist.data.map(x => {
        index++;
        if (x.endstationId) {
          if (x.endstation?.name === resource.name && x.endstation?.type === resource.type) {
            this.dataSourceScientist.data.splice(index - 1, 1);
          }
        }
        if (x.branchlineId) {
          if (x.branchline?.name === resource.name && x.branchline?.type === resource.type) {
            this.dataSourceScientist.data.splice(index - 1, 1);
          }
        }
      });
      this.dataSourceScientist.filter = '';
    }
    if (this.dataSourceAlternateScientist.data.length) {
      index = 0;
      this.dataSourceAlternateScientist.data.map(x => {
        index++;
        if (x.endstationId) {
          if (x.endstation?.name === resource.name && x.endstation?.type === resource.type) {
            this.dataSourceAlternateScientist.data.splice(index - 1, 1);
          }
        }
        if (x.branchlineId) {
          if (x.branchline?.name === resource.name && x.branchline?.type === resource.type) {
            this.dataSourceAlternateScientist.data.splice(index - 1, 1);
          }
        }
      });
      this.dataSourceAlternateScientist.filter = '';
    }
    if (this.dataSourceRestriction.data.length) {
      index = 0;
      this.dataSourceRestriction.data.map(x => {
        index++;
        if (!x.locationID) {
          if (x.location?.name === resource.name && x.location?.type === resource.type) {
            this.dataSourceRestriction.data.splice(index - 1, 1);
          }
        }
      });
      this.dataSourceAlternateScientist.filter = '';
    }
  }

  addRURs(RURs: ResourceUserRelation[]): ResourceUserRelation[] {
    const RURsToSend: ResourceUserRelation[] = [];
    RURs.map(RURTable => {
      RURTable.resourceUserRelations?.map(RUR => {
        RURsToSend.push(RUR);
      });
    });
    return RURsToSend;
  }

  updateResourceUserRelations(dataSourceRUR: MatTableDataSource<ResourceUserRelation>, data: { resourceUserRlations: ResourceUserRelation[], user: User, endstationsList: string[], branchlinesList: string[] }, index: number) {
    const RURToUpdate: ResourceUserRelation = {
      user: data.user,
      joinEndstations: data.endstationsList,
      joinBranchlines: data.branchlinesList,
      resourceUserRelations: data.resourceUserRlations
    };
    dataSourceRUR.data[index] = RURToUpdate;
    dataSourceRUR.filter = '';
  }

  joinRURs(resourceUserRelations?: ResourceUserRelation[]): ResourceUserRelation[] {
    let RURs = this.utils.cloneDeep(resourceUserRelations);
    if (RURs) {
      this.resetRURAuxData();
      let exit = false;
      const RURsJoined: ResourceUserRelation[] = [];
      if (RURs.length > 1) {
        do {
          for (let i = 0; i < RURs.length; i++) {
            if (RURs[0].user?.id === RURs[i].user?.id) {
              this.addInfoRUR(RURs, i);
            }
          }
          RURsJoined.push(this.RURAux);
          if (this.RURAux.user?.id)
            RURs = this.deleteRURsByUserId(this.RURAux.user.id, RURs);
          this.resetRURAuxData();
          if (RURs.length <= 1) {
            exit = true;
          }
        } while (!exit);
      }
      if (RURs.length === 1) {
        this.addInfoRUR(RURs, 0);
        RURsJoined.push(this.RURAux);
      }
      RURs = RURsJoined;
      return RURsJoined;
    }
    return [];
  }

  resetRURAuxData() {
    this.RURAux = new ResourceUserRelation();
    this.RURAux.resourceUserRelations = [];
    this.RURAux.joinEndstations = [];
    this.RURAux.joinBranchlines = [];
  }

  addInfoRUR(RURs: ResourceUserRelation[], position: number) {
    const RUR: ResourceUserRelation = {
      user: RURs[position].user,
      endstation: RURs[position].endstation,
      branchline: RURs[position].branchline,
      isAdded: RURs[position].isAdded,
      isDeleted: RURs[position].isDeleted,
      isModified: RURs[position].isModified,
    };
    this.RURAux.resourceUserRelations?.push(RUR);
    this.RURAux.user = RURs[position].user;
    this.RURAux.isAdded = RURs[position].isAdded;
    this.RURAux.isDeleted = RURs[position].isDeleted;
    const e = RURs[position].endstation?.name;
    if (e) {
      this.RURAux.joinEndstations?.push(e);
    }
    const b = RURs[position].branchline?.name;
    if (b) {
      this.RURAux.joinBranchlines?.push(b);
    }
  }

  deleteRURsByUserId(userId: number, RURs: ResourceUserRelation[]): ResourceUserRelation[] {
    for (let i = RURs.length - 1; i >= 0; i--) {
      if (RURs[i].user?.id === userId) {
        RURs.splice(i, 1);
      }
    }
    return RURs;
  }





  fillUserInfo(ur?: ResourceUserRelation[]) {
    ur?.map(u => {
      u.branchlineId = u.branchline?.id;
      u.endstationId = u.endstation?.id;
      u.resourceId = u.resource?.id;
      u.roleId = u.role?.id;
      u.userId = u.user?.id;
    });
  }

  convertSciNotationToString(vaccums: Resource[]): Resource[] {
    vaccums.map(data => {
      if (data.keVacuumMinRequirement === 0.0) {
        data.MinPressureString = '';
      } else {
        data.MinPressureString = data.keVacuumMinRequirement?.toPrecision(3).toString().replace('e', ' x 10').replace('-', '^-');
      }
      if (data.keVacuumMaxRequirement === 0.0) {
        data.MaxPressureString = '';
      } else {
        data.MaxPressureString = data.keVacuumMaxRequirement?.toPrecision(3).toString().replace('e', ' x 10').replace('-', '^-');
      }
    });
    return vaccums;
  }

  convertSciNotationToNumber(vaccums: Resource[]): Resource[] {
    vaccums.map(data => {
      if (data.MinPressureString) {
        data.keVacuumMinRequirement = +data.MinPressureString.replace(/\s/g, '').replace('x10', 'e').replace('^-', '-');
      } else {
        data.keVacuumMinRequirement = 0;
      }
      if (data.MaxPressureString) {
        data.keVacuumMaxRequirement = +data.MaxPressureString.replace(/\s/g, '').replace('x10', 'e').replace('^-', '-');
      } else {
        data.keVacuumMaxRequirement = 0;
      }
    });
    return vaccums;
  }

  onShowChangesTOCA() {
    if (this.beamlineShutter?.beamlineId)
      this.resourceService.getTOCAChangesByResourceId(this.beamlineShutter.beamlineId, this.showChangesTOCA).toPromise().then(tocas => {
        this.dataSourceTOCA.data = tocas ?? [];
      });
  }

  updateResourceNamesOfRURs(resourceUserRelations: ResourceUserRelation[], resourceUpdated: Resource, oldResourceName: string) {
    resourceUserRelations.map(x => {
      if (x.resourceUserRelations?.length) {
        x.resourceUserRelations.map(rur => {
          if (resourceUpdated.type === ResourceType.Endstation && rur.endstation?.name) {
            if (rur.endstation.id && rur.endstation.id === resourceUpdated.id) { rur.endstation = resourceUpdated; }
            else if (rur.endstation.name === oldResourceName) { rur.endstation = resourceUpdated; }
          }
          if (resourceUpdated.type === ResourceType.Branchline && rur.branchline?.name) {
            if (rur.branchline.id && rur.branchline.id === resourceUpdated.id) { rur.branchline = resourceUpdated; }
            else if (rur.branchline.name === oldResourceName) { rur.branchline = resourceUpdated; }
          }
        });
      }
    });
    resourceUserRelations = this.joinRURs(this.addRURs(resourceUserRelations));
    return resourceUserRelations;
  }

  deleteResourceNamesOfRURs(resourceUserRelations: ResourceUserRelation[], resourceToDelete: Resource) {
    resourceUserRelations.map(x => {
      if (x.resourceUserRelations?.length) {
        x.resourceUserRelations.map(rur => {
          if (resourceToDelete.type === ResourceType.Endstation && rur.endstation?.name) {
            if (rur.endstation.id && rur.endstation.id === resourceToDelete.id) { rur.endstation = new Resource(); }
            else if (rur.endstation.name === resourceToDelete.name) { rur.endstation = new Resource(); }
          }
          if (resourceToDelete.type === ResourceType.Branchline && rur.branchline?.name) {
            if (rur.branchline.id && rur.branchline.id === resourceToDelete.id) { rur.branchline = new Resource(); }
            else if (rur.branchline.name === resourceToDelete.name) { rur.branchline = new Resource(); }
          }
        });
      }
    });
    return resourceUserRelations;
  }

  isModified(resource: string, prop: string) {
    if (this.resourceSummary && this.currentResourceSummary && this.showPrevResource) {
      switch (resource) {
        case 'shutter':
          return this.resourceSummary.shutter && this.currentResourceSummary.shutter ? ((this.resourceSummary.shutter as any)[prop] != (this.currentResourceSummary.shutter as any)[prop]) : false;
          break;
        case 'beamline':
          if (typeof (this.resourceSummary.beamline as any)[prop] != 'object') {
            return (this.resourceSummary.beamline as any)[prop] != (this.currentResourceSummary.beamline as any)[prop];
          }
          else {
            const obj1 = (this.resourceSummary.beamline as any)[prop];
            const obj2 = (this.currentResourceSummary.beamline as any)[prop];
            return !this.utils.isEquivalent(obj1, obj2);
          }
          break;
        case 'summary':
          const sum1 = !this.resourceSummary ? [] : ((this.resourceSummary as any)?.[prop] as any[])?.sort((a, b) => this.utils.sort(a.position ?? 0, b.position ?? 0));
          const sum2 = ((this.currentResourceSummary as any)?.[prop] as any[])?.sort((a, b) => this.utils.sort(a?.position, b?.position));
          switch (prop) {
            case 'restrictions':
              sum1?.map((p: Restriction, i) => {
                const q = sum2?.[i] as Restriction;
                if (!q || p.restrictionLanguage != q.restrictionLanguage || p.ebf != q.ebf || p.scf != q.scf || p.rsswa != q.rsswa || p.ppstb != q.ppstb || p.locationID != q.locationID)
                  p.isModified = true;
              });
              return sum1?.some(x => x.isModified);
              break;
            case 'endstations':
              sum1?.map((p: Resource, i) => {
                const q = sum2?.[i] as Resource;
                if (!q || p.name != q.name || p.absiTemplateTypeID != q.absiTemplateTypeID)
                  p.isModified = true;
              });
              return sum1?.some(x => x.isModified);
              break;
            case 'branchlines':
              sum1?.map((p: Resource, i) => {
                const q = sum2?.[i] as Resource;
                if (!q || p.name != q.name)
                  p.isModified = true;
              });
              return sum1?.some(x => x.isModified);
              break;
            case 'toca':
              sum1?.map((p: Resource, i) => {
                const q = sum2?.[i] as Resource;
                if (!q || p.name != q.name)
                  p.isModified = true;
              });
              return sum1?.some(x => x.isModified);
              break;
            case 'shieldings':
              sum1?.map((p: Resource, i) => {
                const q = sum2?.[i] as Resource;
                if (!q || p.name != q.name || p.keText != q.keText || p.position != q.position || p.checkboxVisible != q.checkboxVisible || p.isRSSD != q.isRSSD)
                  p.isModified = true;
              });
              return sum1?.some(x => x.isModified);
              break;
            case 'vacuums':
              sum1?.map((p: Resource, i) => {
                const q = sum2?.[i] as Resource;
                if (!q || p.name != q.name || p.keVacuumMinRequirement != q.keVacuumMinRequirement || p.keVacuumMaxRequirement != q.keVacuumMaxRequirement || p.channelName != q.channelName || p.location != q.location || p.position != q.position)
                  p.isModified = true;
              });
              return sum1?.some(x => x.isModified);
              break;
            case 'organizations':
              sum1?.map((p: ResourceOrganization, i) => {
                const q = sum2?.[i] as ResourceOrganization;
                if (!q || p.organization?.id != q.organization?.id || p.endstation?.id != q.endstation?.id || p.branchline?.id != q.branchline?.id)
                  p.isModified = true;
              });
              return sum1?.some(x => x.isModified);
              break;
            case 'notes':
              sum1?.map((p: Note, i) => {
                const q = sum2?.[i] as Note;
                if (!q || p.id != q.id || p.noteName != q.noteName)
                  p.isModified = true;
              });
              return sum1?.some(x => x.isModified);
              break;
            case 'meDrawings':
              sum1?.map((p: Link, i) => {
                const q = sum2?.[i] as Link;
                if (!q || p.webAddress != q.webAddress || p.hyperlink != q.hyperlink)
                  p.isModified = true;
              });
              return sum1?.some(x => x.isModified);
              break;
            case 'fastValveSensors':
              sum1?.map((p: Resource, i) => {
                const q = sum2?.[i] as Resource;
                if (!q || p.name != q.name || p.keText != q.keText || p.position != q.position)
                  p.isModified = true;
              });
              return sum1?.some(x => x.isModified);
              break;
            case 'supervisors':
            case 'scientists':
            case 'alternateScientists':
              sum1?.filter(u => u.user)?.sort((a, b) => a.userId - b.userId).map((p: ResourceUserRelation, i) => {
                const q = sum2.find(u => u.userId == p.userId);
                p.isModified = !q || (p.endstationId ?? 0) != (q.endstationId ?? 0) || (p.branchlineId ?? 0) != (q.branchlineId ?? 0);
              });
              return sum1?.some(x => x.isModified);
              break;
          }
          return false;
      }
    }

    return false;
  }

  override scrollToTop() {
    $('.main-panel').scrollTop(0);
  }

  pssValue?: string;
  rssValue?: string;

  rssCtrl = new FormControl();
  pssCtrl = new FormControl();

  pVs?: PV[];
  pVsFiltered?: PV[];
  pVs$!: Observable<PV[]>;
  pVsSubs!: Subscription;

  loadPVs() {
    this.pVs$ = this.store.select(state => state.PV.data);
    this.pVsSubs = this.pVs$.subscribe(data => {
      if (data?.length) {
        this.pVs = data;
      }
    });
  }

  validateChannel(channel?: any, control?: FormControl, type?: string) {
    if (channel) {
      this.pvService.subscribe(channel).subscribe(data => {
        if (data)
          this.setValues(data.value, control, type, true);
      }, error => {
        control?.setErrors({ invalid: true });
        console.log(error);
      }
      );
    }
  }

  getPVValues() {
    this.setValues(this.pVs?.find(p => p.pvName == this.shutter?.rssChannelName)?.value, this.rssCtrl, 'rss');
    this.setValues(this.pVs?.find(p => p.pvName == this.shutter?.pssChannelName)?.value, this.pssCtrl, 'pss');
  }

  setValues(value?: string, control?: FormControl, type?: string, validate?: boolean) {
    if (!value?.includes('ERROR')) {
      control?.setErrors(null);
      if (validate) this.setFormDirty();
      if (type == 'rss') value ? this.rssValue = 'Online' : 'Offline';
      if (type == 'pss') value ? this.pssValue = 'Open' : 'Close';
    }
    else {
      type == 'rss' ? this.rssValue = 'ERROR!' : this.pssValue = 'ERROR!';
      control?.setErrors({ invalid: true, message: 'Invalid Channel Name!' });
    }
  }

  getHistory() {
    this.loading.emit(true);
    this.beamlineHistory = [];
    this.catalogService.GetHistory(Catalog.BeamlineCatalog, this.beamlineShutter!.beamlineId!).toPromise().then(data => {
      if (data) {
        this.beamlineHistory = data.beamlineCatalogs;
        if (this.beamlineHistory) {
          this.beamlineHistory.map(h => {
            if (h.pendingResource) {
              h.pendingResource.userCreatedBy = this.users.find(u => u.id == h.pendingResource?.createdBy);
              h.pendingResource.statusByUser = this.users.find(u => u.id == h.pendingResource?.statusBy);
            }
          });
        }
        this.loading.emit(false);
      }
    });
  }

  beamlineCatalog?: BeamlineCatalog;

  selectedHistory(beamlineCatalog: BeamlineCatalog) {
    this.loading.emit(true);
    this.shutter = beamlineCatalog.resourceSummary.shutter;
    this.beamline = beamlineCatalog.resourceSummary.beamline;
    this.resourceSummary = this.utils.cloneDeep(beamlineCatalog.resourceSummary);

    // in history, currentResourceSumary is actually the previous one

    this.currentResourceSummary = null;
    const index = this.beamlineHistory?.findIndex(x => x.pendingResource.id == beamlineCatalog.pendingResource.id) ?? -1;
    if (index > 0) {
      this.currentResourceSummary = this.utils.cloneDeep(this.beamlineHistory?.[index - 1])?.resourceSummary;
    }
    this.showPrevResource = true;
    this.checkAddedAndDeletedRows();

    this.addResourceSummaryInfo(this.resourceSummary, true);

    this.loading.emit(false);
  }

  checkAddedAndDeletedRows() {
    if (this.showHistory || this.showPrevResource) {
      // Restrictions
      const currentRestrictions = this.resourceSummary?.restrictions ?? [];
      const prevRestrictions = this.currentResourceSummary?.restrictions ?? [];

      if (currentRestrictions && prevRestrictions) {
        prevRestrictions.map(p => {
          if (p.id && !currentRestrictions.find(c => c.id == p.id)) {
            p.isDeleted = true;
            currentRestrictions.push(p);
          }
        });
      }
      currentRestrictions?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevRestrictions?.find(p => p.id == c.id);
      });

      //Endstations
      const currentEndstations = this.resourceSummary?.endstations ?? [];
      const prevEndstations = this.currentResourceSummary?.endstations ?? [];

      if (currentEndstations && prevEndstations) {
        prevEndstations.map(p => {
          if (p.id && !currentEndstations.find(c => c.id == p.id)) {
            p.isDeleted = true;
            currentEndstations.push(p);
          }
        });
      }
      currentEndstations?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevEndstations?.find(p => p.id == c.id);
      });

      // Branchlines
      const currentBranchlines = this.resourceSummary?.branchlines ?? [];
      const prevBranchlines = this.currentResourceSummary?.branchlines ?? [];

      if (currentBranchlines && prevBranchlines) {
        prevBranchlines.map(p => {
          if (p.id && !currentBranchlines.find(c => c.id == p.id)) {
            p.isDeleted = true;
            currentBranchlines.push(p);
          }
        });
      }
      currentBranchlines?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevBranchlines?.find(p => p.id == c.id);
      });

      //Shieldings
      const currentShieldings = this.resourceSummary?.shieldings ?? [];
      const prevShieldings = this.currentResourceSummary?.shieldings ?? [];

      if (currentShieldings && prevShieldings) {
        prevShieldings.map(p => {
          if (p.id >= 0 && !currentShieldings.find(c => c.id == p.id)) {
            p.isDeleted = true;
            currentShieldings.push(p);
          }
        });
      }
      currentShieldings?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevShieldings?.find(p => p.id == c.id);
      });

      // Vacuum
      const currentVacuums = this.resourceSummary?.vacuums ?? [];
      const prevVacuums = this.currentResourceSummary?.vacuums ?? [];

      if (currentVacuums && prevVacuums) {
        prevVacuums.map(p => {
          if (p.id && !currentVacuums.find(c => c.id == p.id)) {
            p.isDeleted = true;
            currentVacuums.push(p);
          }
        });
      }
      currentVacuums?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevVacuums?.find(p => p.id == c.id);
      });

      //Organizations
      const currentOrganizations = this.resourceSummary?.organizations ?? [];
      const prevOrganizations = this.currentResourceSummary?.organizations ?? [];

      if (currentOrganizations && prevOrganizations) {
        prevOrganizations.map(p => {
          if (p.id && !currentOrganizations.find(c => c.id == p.id)) {
            p.isDeleted = true;
            currentOrganizations.push(p);
          }
        });
      }
      currentOrganizations?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevOrganizations?.find(p => p.id == c.id);
      });

      //Supervisors
      const currentSupervisors = this.resourceSummary?.supervisors ?? [];
      const prevSupervisors = this.currentResourceSummary?.supervisors ?? [];

      if (currentSupervisors && prevSupervisors) {
        prevSupervisors.map(p => {
          p.userId = p.user?.id;
          if (p.user?.id && !currentSupervisors.find(c => c.user?.id == p.user?.id)) {
            p.isDeleted = true;
            currentSupervisors.push(p);
          }
        });
      }
      currentSupervisors?.filter(c => !c.isDeleted).map(c => {
        c.userId = c.user?.id;
        c.isAdded = c.user?.id == 0 || !prevSupervisors?.find(p => p.user?.id == c.user?.id);
      });

      //BL Scientists
      const currentScientists = this.resourceSummary?.scientists ?? [];
      const prevScientists = this.currentResourceSummary?.scientists ?? [];

      if (currentScientists && prevScientists) {
        prevScientists.map(p => {
          p.userId = p.user?.id;
          if (p.user?.id && !currentScientists.find(c => c.user?.id == p.user?.id)) {
            p.isDeleted = true;
            currentScientists.push(p);
          }
        });
      }
      currentScientists?.filter(c => !c.isDeleted).map(c => {
        c.userId = c.user?.id;
        c.isAdded = c.user?.id == 0 || !prevScientists?.find(p => p.user?.id == c.user?.id);
      });

      //Alternate BL Scientists
      const currentAlternateScientists = this.resourceSummary?.alternateScientists ?? [];
      const prevAlternateScientists = this.currentResourceSummary?.alternateScientists ?? [];

      if (currentAlternateScientists && prevAlternateScientists) {
        prevAlternateScientists.map(p => {
          p.userId = p.user?.id;
          if (p.user?.id && !currentAlternateScientists.find(c => c.user?.id == p.user?.id)) {
            p.isDeleted = true;
            currentAlternateScientists.push(p);
          }
        });
      }
      currentAlternateScientists?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.user?.id == 0 || !prevAlternateScientists?.find(p => p.user?.id == c.user?.id);
      });

      //Notes
      const currentNotes = this.resourceSummary?.notes ?? [];
      const prevNotes = this.currentResourceSummary?.notes ?? [];

      if (currentNotes && prevNotes) {
        prevNotes.map(p => {
          if (p.id && !currentNotes.find(c => c.id == p.id)) {
            p.isDeleted = true;
            currentNotes.push(p);
          }
        });
      }
      currentNotes?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevNotes?.find(p => p.id == c.id);
      });

      //Other Links
      const currentOtherLinks = this.resourceSummary?.otherLinks ?? [];
      const prevOtherLinks = this.currentResourceSummary?.otherLinks ?? [];

      if (currentOtherLinks && prevOtherLinks) {
        prevOtherLinks.map(p => {
          if (p.id && !currentOtherLinks.find(c => c.id == p.id)) {
            p.isDeleted = true;
            currentOtherLinks.push(p);
          }
        });
      }
      currentOtherLinks?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevOtherLinks?.find(p => p.id == c.id);
      });

      // ME Drawing Links
      const currentMeDrawings = this.resourceSummary?.meDrawing ?? [];
      const prevMeDrawings = this.currentResourceSummary?.meDrawing ?? [];

      if (currentMeDrawings && prevMeDrawings) {
        prevMeDrawings.map(p => {
          if (p.id && !currentMeDrawings.find(c => c.id == p.id)) {
            p.isDeleted = true;
            currentMeDrawings.push(p);
          }
        });
      }
      currentMeDrawings?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevMeDrawings?.find(p => p.id == c.id);
      });

      // AP PhaseSpace Link
      const currentAPPhaseSpace = this.resourceSummary?.apPhaseSpace ?? [];
      const prevAPPhaseSpace = this.currentResourceSummary?.apPhaseSpace ?? [];

      if (currentAPPhaseSpace && prevAPPhaseSpace) {
        prevAPPhaseSpace.map(p => {
          if (p.id && !currentAPPhaseSpace.find(c => c.id == p.id)) {
            p.isDeleted = true;
            currentAPPhaseSpace.push(p);
          }
        });
      }
      currentAPPhaseSpace?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevAPPhaseSpace?.find(p => p.id == c.id);
      });

      //SA Survey Links
      const currentSASurveys = this.resourceSummary?.saSurvey ?? [];
      const prevSASurveys = this.currentResourceSummary?.saSurvey ?? [];

      if (currentSASurveys && prevSASurveys) {
        prevSASurveys.map(p => {
          if (p.id && !currentSASurveys.find(c => c.id == p.id)) {
            p.isDeleted = true;
            currentSASurveys.push(p);
          }
        });
      }
      currentSASurveys?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevSASurveys?.find(p => p.id == c.id);
      });


      //Fast Valve Sensors
      const currentFastValveSensors = this.resourceSummary?.fastValveSensors ?? [];
      const prevFastValveSensors = this.currentResourceSummary?.fastValveSensors ?? [];

      if (currentFastValveSensors && prevFastValveSensors) {
        prevFastValveSensors.map(p => {
          if (p.id && !currentFastValveSensors.find(c => c.id == p.id)) {
            p.isDeleted = true;
            currentFastValveSensors.push(p);
          }
        });
      }
      currentFastValveSensors?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevFastValveSensors?.find(p => p.id == c.id);
      });

      // // Locations
      // const currentLocations = this.acceleratorCatalog?.pendingDetails?.locations;
      // const prevLocations = this.acceleratorCatalog?.details?.locations;

      // if (currentLocations && prevLocations) {
      //   prevLocations.map(p => {
      //     if (p.id && !currentLocations.find(c => c.id == p.id)) {
      //       p.isDeleted = true;
      //       currentLocations.push(p);
      //     }
      //   });
      // }
      // currentLocations?.filter(c => !c.isDeleted).map(c => {
      //   c.isAdded = c.id == 0 || !prevLocations?.find(p => p.id == c.id);
      // });

      // Shieldings
      // const currentShieldings = this.acceleratorCatalog?.pendingDetails?.shieldings;
      // const prevShieldings = this.acceleratorCatalog?.details?.shieldings;
      // if (prevShieldings && currentShieldings) {
      //   prevShieldings.map(p => {
      //     const parentResource = p.parentResources?.find(
      //       r => r.parentResourceID == this.accelerator.id
      //     );
      //     if (p.id && !currentShieldings.find(c => c.id == p.id)) {
      //       p.isDeleted = true;
      //       p.position = parentResource ? (parentResource.position ?? 0) : (p.position ?? 0);
      //       currentShieldings.push(p);
      //     }
      //   });
      // }
      // currentShieldings?.filter(c => !c.isDeleted).map(c => {
      //   c.isAdded = c.id == 0 || !prevShieldings?.find(p => p.name == c.name);
      // });


    }
  }

}
