import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { utils } from '../../../modules/libs/utils';

@Component({
  selector: 'app-redirect-dialog',
  templateUrl: './redirect-dialog.component.html',
  styleUrls: ['./redirect-dialog.component.scss']
})
export class RedirectDialogComponent implements OnInit {

  private closeTimeout: any;            // To store the timeout reference
  private mouseHasEntered: boolean = false;  // Track if mouse has entered at least once
  private mouseInDialog: boolean = false;    // Track if mouse is currently inside the dialog

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<RedirectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private elementRef: ElementRef
  ) { }

  ngOnDestroy() {
    // Clean up the event listeners to avoid memory leaks
    this.elementRef.nativeElement.removeEventListener('mouseleave', this.onMouseLeave.bind(this));
    this.elementRef.nativeElement.removeEventListener('mouseenter', this.onMouseEnter.bind(this));
  }

  ngOnInit(): void {
    // Add event listeners for 'mouseleave' and 'mouseenter' events
    this.elementRef.nativeElement.addEventListener('mouseleave', this.onMouseLeave.bind(this));
    this.elementRef.nativeElement.addEventListener('mouseenter', this.onMouseEnter.bind(this));
  }

  // Triggered when the mouse enters the dialog
  onMouseEnter() {
    this.mouseInDialog = true;  // Mouse is inside the dialog
    this.mouseHasEntered = true;  // Mark that the mouse has entered at least once
    clearTimeout(this.closeTimeout);  // Cancel any closing action if mouse re-enters
  }

  // Triggered when the mouse leaves the dialog
  onMouseLeave() {
    if (!this.mouseHasEntered) {
      return;  // Exit if the mouse hasn't been over the dialog yet
    }

    this.mouseInDialog = false; // Set the flag to false

    // Add a delay before closing the dialog (e.g., 1 second)
    this.closeTimeout = setTimeout(() => {
      if (!this.mouseInDialog) { // Only close if the mouse hasn't re-entered
        this.dialogRef.close(); // Close the dialog
      }
    }, 1000); // Delay in milliseconds (1 second)
  }

  openSameTab() {
    // Logic for opening the same tab (e.g., using Router)
    this.router.navigate([utils.replaceAll(this.data.targetUrl, '%20', ' ')]);
    this.dialogRef.close(true);
  }

  openNewTab() {
    // Logic for opening in a new tab
    window.open('#/' + this.data.targetUrl, '_blank');
    setTimeout(() => {
      this.dialogRef.close(false);
    }, 1000);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
