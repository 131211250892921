<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h4 mat-dialog-title>{{ inputData.dialogTitle }}</h4>
      <div mat-dialog-content>
        <div class="col-12" *ngIf="inputData.typeAvailable">
          <div class="row">
            <mat-form-field>
              <mat-label>Select Type</mat-label>
              <mat-select
                [(ngModel)]="linkSelected"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option *ngFor="let link of linkTypes" [value]="link">
                  {{ link.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <mat-form-field>
              <mat-label>Hyperlink Text</mat-label>
              <textarea
                matInput
                [(ngModel)]="hyperlink"
                rows="5"
                placeholder="Hyperlink Text"
                [ngModelOptions]="{ standalone: true }"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div mat-dialog-content>
        <div class="col-12">
          <div class="row">
            <mat-form-field>
              <mat-label>URL</mat-label>
              <textarea
                matInput
                [(ngModel)]="webAddress"
                rows="5"
                placeholder="URL"
                [ngModelOptions]="{ standalone: true }"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <div class="col-12">
          <div class="row">
            <div class="col-md-6">
              <button mat-flat-button color="warn" (click)="cancel()">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <button
                mat-flat-button
                color="primary"
                [disabled]="isSaving"
                (click)="add()"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
