import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as RolePrivilegeActions from './role-privilege.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';

@Injectable({
  providedIn: 'root'
})
export class RolePrivilegeStoreAndListenersService {

  constructor(
    private store: Store,
    private signalRService: SignalRService
  ) { }

  initStore() {
    this.store.dispatch(new RolePrivilegeActions.RolePrivilegeRefresh());
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.ROLE_PRIVILEGES, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new RolePrivilegeActions.RolePrivilegeCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new RolePrivilegeActions.RolePrivilegeUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new RolePrivilegeActions.RolePrivilegeDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new RolePrivilegeActions.RolePrivilegeRefresh());
          break;
      }
    });
  }
}
