import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AbsiTemplateVersionCreate, AbsiTemplateVersionDelete, AbsiTemplateVersionRefresh, AbsiTemplateVersionRefreshByID, AbsiTemplateVersionUpdate } from './absi-template.action';
import { AbsiBuilderService } from '../../absi-builder/absi-builder.service';
import { AbsiTemplateVersionResource } from '../../absi';

export class AbsiTemplateVersionStateModel {
  data: AbsiTemplateVersionResource[] = [];
}

@State<AbsiTemplateVersionStateModel>({
  name: 'AbsiTemplateVersions',
  defaults: {
    data: []
  }
})

@Injectable()
export class AbsiTemplateVersionState {
  constructor(
    private service: AbsiBuilderService
  ) { }

  @Selector()
  static read(state: AbsiTemplateVersionStateModel) {
    return state.data;
  }

  @Action(AbsiTemplateVersionRefresh)
  refresh({ patchState }: StateContext<AbsiTemplateVersionStateModel>, { id }: AbsiTemplateVersionRefresh) {
    this.service.GetTemplateVersionsByType(id).subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(AbsiTemplateVersionRefreshByID)
  refreshByID({ getState, patchState }: StateContext<AbsiTemplateVersionStateModel>, { id }: AbsiTemplateVersionRefreshByID) {
    this.service.GetTemplateVersionsByID(id).subscribe(data => {
      const state = getState();
      const index = state.data.map(x => x.id).indexOf(id);

      if (index >= 0) {
        state.data.splice(index, 1);
      }
      patchState({
        data: [...state.data, data]
      });
    },
      error => console.error(error)
    );
  }

  @Action(AbsiTemplateVersionCreate)
  create({ getState, patchState }: StateContext<AbsiTemplateVersionStateModel>, { item }: AbsiTemplateVersionCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(AbsiTemplateVersionUpdate)
  update({ getState, patchState }: StateContext<AbsiTemplateVersionStateModel>, { id, item }: AbsiTemplateVersionUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(AbsiTemplateVersionDelete)
  delete({ getState, patchState }: StateContext<AbsiTemplateVersionStateModel>, { id }: AbsiTemplateVersionDelete) {
    patchState({
      data: getState().data.filter(x => x.id != id)
    });
  }

}
