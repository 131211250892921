import { Component, OnInit, AfterViewInit, HostListener, ViewChild, Output, EventEmitter, ElementRef, OnDestroy, Injector } from '@angular/core';
import { BranchlineStatusState, BranchlineStatusStateModel, } from "src/app/components/home/branchline-status/store/branchline-status.state";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { Select } from "@ngxs/store";
import { MatDialog } from "@angular/material/dialog";
import { IResource } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { SendIdService } from "../beamline-status/send-id.service";
import { ResourcesService } from "src/app/components/catalogs/beamline-catalog/resource/resources.service";
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: "app-branchline-status",
  templateUrl: "./branchline-status.component.html",
  styleUrls: ["./branchline-status.component.scss"],
})
export class BranchlineStatusComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("header2")
  header2!: ElementRef;
  @ViewChild("branchBody")
  branchBody!: ElementRef;
  headerOnTop = false;
  tableWidth = "";
  headerBottom = "";
  branchlines: IResource[] = [];
  branchlines$!: Observable<IResource[]>;
  branchlinesSubs!: Subscription;

  displayedColumns: string[] = ["branchline", "status", "keReady"];
  dataSource = new MatTableDataSource<IResource>();
  selectedRowIndex = -1;

  loading!: boolean;
  filter?: string;

  spanningColumns = ["branchline"];
  spans = [];

  maxHeight: string | null = null;

  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @Output() rowSelected = new EventEmitter<any>();
  suscriptionBSS!: Subscription;
  @Select(BranchlineStatusState)
  branchlineStatusState!: Observable<BranchlineStatusStateModel>;

  constructor(
    private resourcesService: ResourcesService,
    private router: Router,
    private sendIdService: SendIdService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.suscriptionBSS?.unsubscribe();
  }

  ngOnInit() {
    this.loading = true;
    this.responsiveFunction();
    this.loadBranchlines();
  }

  loadBranchlines() {
    this.loading = true;
    this.branchlines$ = this.store.select(state => state.Branchlines.data);
    this.branchlinesSubs = this.branchlines$.subscribe(data => {
      if (data?.length) {
        this.branchlines = data;
        this.dataSource = new MatTableDataSource(this.branchlines.filter(b => this.filter ? b.name.toLowerCase().includes(this.filter) :
          b));
        this.loading = false;
      }
    });
  }

  ngAfterViewInit() {
    this.responsiveFunction();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: { target: { innerWidth: any; innerHeight: any } }) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
    this.maxHeight = parseInt((this.innerHeight * 0.45).toFixed(0)) + "px";
    this.isHeaderOnTop();
  }

  responsiveFunction() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.maxHeight = parseInt((this.innerHeight * 0.45).toFixed(0)) + "px";
  }

  @HostListener("window:scroll")
  isHeaderOnTop() {
    let elemRec;
    if (this.branchBody?.nativeElement != undefined) {
      elemRec = this.branchBody.nativeElement.getBoundingClientRect();
      // const docViewTop = window.screenTop;
      const elemTop = Number(elemRec.top.toFixed(0));
      const elemBottom = Number(elemRec.bottom.toFixed(0));
      // const headerTop = this.header1.nativeElement.getBoundingClientRect().top;
      const headerBottom = Number(
        this.header2.nativeElement.getBoundingClientRect().bottom.toFixed(0)
      );

      if (elemTop < headerBottom) {
        this.headerOnTop = true;
      } else {
        this.headerOnTop = false;
      }
      this.tableWidth = this.branchBody.nativeElement.offsetWidth + "px";
      this.headerBottom = headerBottom + "px";
    } else {
      this.headerOnTop = false;
    }
  }

  applyFilter(e: any) {
    const filterValue: string = e;
    this.filter = filterValue.toLowerCase();
    this.loadBranchlines();
  }

  SendId(id: number) {
    console.log(id);
    this.sendIdService.SendId(id);
    this.router.navigate(["beamline-overview"]);
  }

  doToggle(value: any, element: IResource) {
    const resource = element as IResource;
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "400px",
      data: {
        message: this.getMessage("ChangeBranchlineStatus")
          .description,
        icon: "warn",
      },
    });
    confirm.afterClosed().subscribe((data) => {
      if (data) {
        this.resourcesService
          .updateOperationalLockOut(resource.id, value)
          .subscribe((response) => {
            element.operationalLockOut = value;
          });
      }
    });
  }
}
