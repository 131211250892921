<div
  class="d-flex align-items-center"
  [ngClass]="{ 'pb-3': !checklistsIDs.length && !disabled }"
>
  <mat-form-field appearance="outline" class="no-subscript">
    <mat-label>Checklists</mat-label>
    <mat-chip-grid
      #chipList
      aria-label="Document selection"
      class="matchiplistLinks"
      [disabled]="disabled"
    >
      <mat-chip-row
        *ngFor="let checklist of checklistsSaved"
        [selectable]="!disabled"
        [removable]="!disabled"
        (removed)="remove(checklist)"
        [ngClass]="checklist.checklistStatus?.cssClass + '-background'"
        [matTooltip]="checklist.resourceName ?? ''"
        [matTooltipClass]="checklist.checklistStatus?.cssClass + '-background'"
        (click)="openDocument(checklist)"
      >
        <span class="btn-link">{{ checklist.serialNo }}</span>
        <mat-icon matChipRemove *ngIf="!disabled" class="d-print-none"
          >cancel</mat-icon
        >
      </mat-chip-row>
      <input
        aria-label="checklists"
        #checklistsInput
        [matAutocomplete]="autoChk"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (keyup)="onChange($event)"
        [formControl]="checklistsCtrl"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #autoChk="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-optgroup
        *ngFor="let document of documentTypes"
        [label]="document?.description ?? ''"
      >
        <mat-option
          *ngFor="let checklist of getFilteredChecklists(document)"
          [value]="checklist"
          class="option"
        >
          <div
            class="d-flex justify-content-between full-width {{
              checklist.checklistStatus?.cssClass
            }}"
          >
            <div class="d-flex flex-column">
              <span class="bold"> {{ checklist.serialNo }}</span>
              <span class="small no-wrap" *ngIf="checklist.resourceName"
                >({{ checklist.resourceName }})</span
              >
            </div>
            <div class="small bold">
              {{ checklist.checklistStatus?.name }}
            </div>
          </div>
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
    <mat-hint *ngIf="changes" class="red">
      Changes have not been saved
    </mat-hint>
  </mat-form-field>
  <div
    class="d-flex flex-column pl-1"
    *ngIf="(changes || checked) && !disabled"
  >
    <button
      mat-flat-button
      color="primary"
      class="accept-buttonmb-1"
      (click)="save()"
      matTooltip="Save Checklists changes"
    >
      <mat-icon>check_circle_outline</mat-icon>
    </button>
    <button
      mat-flat-button
      color="accent"
      class="undo-button"
      matTooltip="Undo Checklists changes"
      (click)="undo()"
      *ngIf="changes"
    >
      <mat-icon>undo</mat-icon>
    </button>
  </div>
</div>
