import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Checklist } from '../../checklists';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Document } from 'src/app/services/documents/documents';
import { Observable, Subscription } from 'rxjs';
import { DocumentChip } from 'src/app/controls/checklist-chips/document-chips.component';

@Component({
  selector: 'checklist-exec-related',
  templateUrl: './checklist-exec-related.component.html',
  styleUrls: ['./checklist-exec-related.component.scss']
})
export class ChecklistExecRelatedComponent extends BaseComponent implements OnChanges {

  documents?: Document[];
  documents$!: Observable<Document[]>;
  documentsSubs!: Subscription;

  @Input() checklist?: Checklist | null;

  documentChips?: DocumentChip[];

  ngOnChanges(changes: SimpleChanges): void {
    this.loadDocuments();
  }

  loadDocuments() {
    this.documents$ = this.store.select(state => state.Documents.data);
    this.documentsSubs = this.documents$.subscribe(data => {
      if (data?.length) {
        this.documentChips = [];
        this.documents = data;
        this.checklist?.relatedDocuments?.map(r => {
          const document = this.documents?.find(d => d.formID == r.originalDocumentId && d.type == r.originalDocumentTypeId);
          if (document) {
            const documentChip = { id: document?.formID, serialNo: document?.serialNo, cssClass: document?.cssClass, status: document?.statusName } as DocumentChip;
            this.documentChips?.push(documentChip);
          }
        });
      }
    });
  }

}
