import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Review, ReviewDocumentCategory } from '../../reviews.models';
import { ReviewsService } from '../../reviews.service';


@Component({
  selector: 'app-review-attachment',
  templateUrl: './review-attachment.component.html',
  styleUrls: ['./review-attachment.component.scss']
})

export class ReviewAttachmentComponent implements OnInit {
  @Output() barProgressStatus = new EventEmitter<Boolean>();

  @ViewChild("fileDropRef", { static: false }) fileDropEl!: ElementRef;

  files: any[] = [];
  public typeSelected!: number;
  public menuListCategories!: ReviewDocumentCategory[];
  public reviewParameter!: Review;
  path!: string;
  fileToUpload!: File;
  selectedFiles!: FileList;
  attachedFiles!: File[];


  constructor(private dialogRef: MatDialogRef<ReviewAttachmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Review, private _service: ReviewsService) { }

  ngOnInit(): void {
    this.loadReviewCategories();
    if (this.data) {
      this.reviewParameter = this.data;
    }
  }

  loadReviewCategories() {
    this._service.getReviewDocumentCategories().toPromise().then(result => {
      if (!(result?.length)) {
        result = [];
      }
      this.menuListCategories = result.filter(x => x.status == 1);
      if (this.menuListCategories.length > 0) { this.typeSelected = this.menuListCategories[0].id }
    });
  }

  /**
 * on file drop handler
 */
  onFileDropped(event: any) {
    this.prepareFilesList(event);
  }

  /**
 * handle file from browsing
 */
  fileBrowseHandler(e: any) {
    const files = e.target.files;
    if (this.typeSelected && files)
      this.prepareFilesList2(files);
  }

  /**
 * Delete file from files list
 * @param index (File index)
 */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      console.log("Upload in progress.");
      return;
    }
    this.files.splice(index, 1);
    this.attachedFiles = this.files;
  }

  /**
 * Convert Files list to normal array list
 * @param files (Files List)
 */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);
  }

  prepareFilesList2(files: Array<any>) {
    for (const item of files) {
      let existNameFile = this.files.filter(fileAdded => fileAdded.name.toUpperCase().trim() == item.name.toUpperCase().trim()).length;
      if (existNameFile == 0) {
        item.progress = 0;
        item.CategoryId = this.typeSelected;
        this.files.push(item);
      }
    }
    this.attachedFiles = this.files;
    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);
  }

  getFileExtension(fileName: string): string | undefined {
    const parts = fileName.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1];
    }
    return undefined;
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  /**
  * Simulate the upload process
  */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 500);
  }

  SaveFiles() {
    this.dialogRef.close(this.files);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setPath(folderName: string, holderName: string) {
    this.path = "/" + folderName + "/" + holderName + "/";
  }

  close() {
    this.dialogRef.close();
  }

}
