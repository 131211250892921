<div class="container-fluid mt-3">
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-12 col-sm-6 col-lg-4">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input aria-label="name" matInput formControlName="nameCtrl" />
          <mat-error *ngIf="nameCtrl?.invalid">{{
            getErrorMsg(nameCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-6 col-lg-4">
        <mat-form-field>
          <mat-label>Description</mat-label>
          <input
            aria-label="description"
            matInput
            formControlName="descriptionCtrl"
          />
          <mat-error *ngIf="descriptionCtrl?.invalid">{{
            getErrorMsg(descriptionCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-6 col-lg-2">
        <mat-form-field>
          <mat-label>Acronym</mat-label>
          <input aria-label="acronym" matInput formControlName="acronymCtrl" />
          <mat-error *ngIf="acronymCtrl?.invalid">{{
            getErrorMsg(acronymCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-6 col-lg-2">
        <div class="slide-center">
          <mat-label class="label-slide-center">Is Active</mat-label>
          <mat-slide-toggle
            formControlName="activeToggleCtrl"
          ></mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-lg-2">
        <mat-form-field>
          <mat-label>Grace Period</mat-label>
          <input
            aria-label="graceTime"
            type="number"
            matInput
            formControlName="graceTimeCtrl"
          />
          <mat-error *ngIf="graceTimeCtrl?.invalid">{{
            getErrorMsg(graceTimeCtrl)
          }}</mat-error>
          <mat-hint>Days</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-6 col-lg-2">
        <mat-form-field>
          <mat-label>Due Period</mat-label>
          <input
            aria-label="dueDate"
            type="number"
            matInput
            formControlName="dueDateCtrl"
          />
          <mat-error *ngIf="dueDateCtrl?.invalid">{{
            getErrorMsg(dueDateCtrl)
          }}</mat-error>
          <mat-hint>Months</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-6 col-lg-2">
        <mat-form-field>
          <mat-label>Date Format</mat-label>
          <input
            aria-label="dateFormat"
            matInput
            formControlName="dateFormatCtrl"
            [(ngModel)]="dateFormat"
          />
          <mat-error *ngIf="dateFormatCtrl?.invalid">{{
            getErrorMsg(dateFormatCtrl)
          }}</mat-error>
          <mat-hint>
            {{ today | date : dateFormat }}
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-6 col-lg-4">
        <div class="slide-center">
          <mat-label class="label-slide-center">Custom Schedules</mat-label>
          <mat-slide-toggle
            formControlName="customToggleCtrl"
          ></mat-slide-toggle>
        </div>
      </div>
    </div>

    <div class="row pt-3">
      <div class="col-4">
        <mat-form-field>
          <mat-label>Document Type</mat-label>
          <mat-select formControlName="documentTypeCtrl">
            <mat-option
              *ngFor="let documentType of documentTypes"
              [value]="documentType.id"
            >
              {{ documentType.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field>
          <mat-label>Procedure Type</mat-label>
          <mat-select
            (selectionChange)="selectedProcedureType($event)"
            formControlName="procedureTypeCtrl"
          >
            <mat-option
              *ngFor="let procedureType of procedureTypes"
              [value]="procedureType.id"
            >
              {{ procedureType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field appearance="outline">
          <mat-label>Schedule Resource Type List</mat-label>
          <mat-chip-grid
            [disabled]="disabled"
            #chipListResourceType
            aria-label="Resource selection"
          >
            <mat-chip-row
              *ngFor="let item of scheduleResourceTypes"
              [removable]="!disabled"
              (removed)="removeResourceType(item)"
            >
              {{ item.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-row>
            <input
              #autoInput
              aria-label="resourceType"
              formControlName="resourceTypeCtrl"
              [matAutocomplete]="autoResourceType"
              [matChipInputFor]="chipListResourceType"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (keyup)="changedResourceType($event)"
            />
          </mat-chip-grid>
          <mat-autocomplete
            #autoResourceType="matAutocomplete"
            (optionSelected)="selectedScheduleResource($event)"
            #scheduleResourceAutocomplete
          >
            <mat-option
              *ngFor="let resourceType of resourceTypesFiltered"
              [value]="resourceType"
            >
              {{ resourceType.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-error>You must choose at least one Resource Type</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row border-top pt-3">
      <div class="col-6">
        <h6>Related Data</h6>
      </div>
      <div class="col-6">
        <mat-form-field>
          <mat-label>Checklist Template Type</mat-label>
          <input
            aria-label="templateType"
            matInput
            formControlName="templateTypeCtrl"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="scheduleSubtype?.schedules">
      <div class="col-12 scrollbar">
        <h6>Schedules</h6>
        <!-- <app-schedule-tab
            class="schedules"
            [scheduleType]="scheduleType"
            [scheduleSubtype]="scheduleType.tabColumns ? scheduleSubtype : null"
            [showFilter]="false"
            [showHeader]="false"
            [readOnly]="true"
          ></app-schedule-tab> -->
      </div>
    </div>
  </form>
</div>

<div class="buttons">
  <div class="row">
    <div class="col-6">
      <button
        *ngIf="modifying || adding"
        mat-flat-button
        color="warn"
        (click)="reset()"
        style="width: 100px"
      >
        Cancel
      </button>
      <button
        *ngIf="!modifying && !adding && scheduleType"
        mat-stroked-button
        color="warn"
        (click)="delete()"
        style="width: 100px"
      >
        Delete
      </button>
    </div>
    <div class="col-6 text-right">
      <button
        mat-flat-button
        color="accent"
        class="right-btn"
        *ngIf="!modifying && scheduleType && !adding"
        (click)="modify()"
        style="width: 100px"
      >
        Modify
      </button>
      <button
        mat-flat-button
        color="primary"
        class="right-btn"
        *ngIf="modifying || adding"
        [disabled]="!(formGroup.dirty && formGroup.valid) || formGroup.disabled"
        (click)="save()"
      >
        Save
      </button>
    </div>
  </div>
</div>
