import { Injectable } from '@angular/core';
import { MatTooltipDefaultOptions } from '@angular/material/tooltip';

@Injectable({
  providedIn: 'root',
})
export class TooltipConfigService implements MatTooltipDefaultOptions {
  // Define your default tooltip class here
  tooltipClass = 'ascc-tooltip';
  showDelay = 0;
  hideDelay = 100;
  touchendHideDelay = 3000;
}
