<form-table
  [formType]="formTypeEnum.EBF"
  [all]="showAllForms"
  [displayedColumns]="['sequenceNumber', 'systemAffected', 'status']"
  [filter]="formsFilter"
  (rowClick)="rowSelectedEvent($event)"
  (error)="alert.error($event)"
  (loading)="loading.emit($event)"
>
</form-table>
