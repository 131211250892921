// import APLMessages from "src/app/messages/apl.messages";
// import EB2Messages from "src/app/messages/eb2.messages";
// import ALSPCMessages from "../app/messages/als-pc.messages";

export const messages = {
  approve_message: `Are you sure you want to approve?`,
  request_amendment: `<strong>Are you sure you want to Request an Amendment for this form?</strong>`,
  unapprove_message: `Are you sure you want to unapprove?`,
  disapprove_message: `Enter a detailed reason for the disapprove:`,
  disapprove_message_no_reason: `Are you sure you want to disapprove?`,
  answer_message: "Are you sure you want to change this required status?",
  finish_message: `<strong>Are you sure you want to Close this form?</strong><br><br>If you click Yes, you won't be able to revert this action!`,
  toActiveWarning: `<strong>Are you sure you want to Activate this form?</strong><br><br>If you click Yes, you won't be able to revert this action!`,
  toWorkCompleteWarning: `<strong>Are you sure you want to Complete the Work for this form?</strong><br><br>If you click Yes, you won't be able to revert this action!`,
  toHandshakeWarning: `<b>Are you sure you want to finish Key Enabling?</b><br><br>If you click Yes, you won't be able to revert this action and status will set to <b>Handshake!</b>`,
  formStateChanged: `<b>{serialNo}</b> is now <b>{status}</b> !`,
  user_not_logged: `You have to <strong>Login</strong> in order to perform any task in this screen.`,
  LocationAdded: `Location: {location} was added to Locations list.`,
  NewChecklistCreated: `New form {serialNo} has been created!`,
  KEResetVacuumValues: `This action will reset all vacuum values and clear all the checkboxes. Are you sure to continue?`,
  KENoVersionsFound: `No Active or New Versions Found for this Resource`,
  DraftSaved: `{serialNo} Form Saved as Draft!`,
  DraftUpdated: `{serialNo} Form Draft Updated!`,
  FormUpdated: "{serialNo} Form Updated!",
  Submitted: `{serialNo} has been Submmited!`,
  amendment_saved: "{serialNo} amendment saved!",
  amendment_Submitted: "{serialNo} amendment has been Submitted!",
  Section1Updated: `{serialNo} Section 1 Updated!`,
  Section2Updated: `{serialNo} Section 2 Updated!`,
  Section3Updated: `{serialNo} Section 3 Updated!`,
  Section4Updated: `{serialNo} Section 4 Updated!`,
  Section5Updated: `{serialNo} Section 5 Updated!`,
  Section6Updated: `{serialNo} Section 6 Updated!`,
  FormClosed: `{serialNo} is Closed!`,
  OLogEntryCreated: `OLOG Entry has been saved for {serialNo}.`,
  CommentAdded: `New comment Added successfully!`,
  CommentDeleted: `Comment Deleted successfully!`,
  invalidLocation: `You should add at least one <strong>Location</ strong >`,
  invalidStartDate: `Please enter a valid <strong>Estimated Start Date</strong>`,
  invalidEndDate: `Please enter a valid <strong>Estimated End Date</strong>`,
  invalidRequester: `Please select a <strong>Requester</strong>`,
  // invalidRelatedLinks: `Please enter a <strong>Related Links</strong>`,
  invalidDescription: `Please enter a <strong>Description</strong> of work being performed.`,
  upload_invalid_exe: `Executable files are not allowed to be Uploaded`,
  no_ketemplate: `No {0} KE Checklist Template found for this Shutter and/or Branchline.`,
  no_absiTemplate: `No Active ABSI Checklist Template found for this ABSI Type.`,
  cancelForm: `Enter a reason to cancel the form`,
  cancelAmendment: `Enter a reason to cancel the amendment`,
  deleteFormConfirmation: `Are you sure to Delete this form?`,
  finishTime: `Time is up.`,
  oneMinuteUntilTime: `You have one minute left, reset the timer or save the form.`,
  editingByTimer: `This form is being edited by {username}.`,
  OlogEntrySent: "OLOG Entry has been sent for {serialNo}.",
  OlogEntryError: "System error (OLOG). Please contact Control Room",
  OlogPasswordWrong: "Your OLOG password is wrong!",
  OlogDevEnv: `Olog don't works on Development Environment`,
  KEChecklistAlreadyOpen:
    "There is already an Open Key Enable Checklist for this Resource",
  AbsiChecklistAlreadyOpen:
    "There is already an Open ABSI Checklist for this Resource",
  defaultErrorMessage: "System error. Please contact Control Room.",
  HassDisapprovalReason:
    "This form has disapproval reason: {DisapprovalReason}.",
  removeRelatedDoc: `Are you sure you want to remove this related document?`,
  KEVacuumEditTextWarning:
    "Are you sure you want to edit this comment? This will remove the current initials.",
  KERadioChangedWarning:
    "Are you sure you want to change this value? This will remove the current initials.",
  KEResetToActive:
    "This will remove all initials from Section 4 to allow editing of Sections 1-3.<br>Continue?",
  ppstbRequesterValidation: "no requester was specified",
  ppstbJustificationValidation: "no justifications were specified",
  ppstbBypassDetailsValidation: "no bypass details were specified",
  ppstbPermittedStartDateValidation: "no permitted start date was specified",
  ppstbEstimatedCompletionValidation:
    "no estimated completion date was specified",
  ppstbEstimatedCompletionLessThanPermittedStartDateValidation:
    "the estimated completion date is less than the permitted start date",
  ppstbPermittedStartDateGreaterThanEstimatedCompletion:
    "the permitted start date is greater than estimated completion",
  ppstbConditionsAndLimitationsValidations:
    "no conditions or limitations were specified",
  ppstbSystemsValidation: "no systems were specified",
  noStandarRestrictionsWereAdded: "no standard restrictions werer added",
  KEResetShieldings:
    "This will remove all initials from Section 4 to allow to start clean checklist.<br>Continue?",
  KEResetedToShieldings:
    "All Initials were removed from Section 4. You can start checking it now.",
  pleaseCheckOnAtLeastOneSystemAffected:
    "please check on at least one system affected",
  CopyForm: "The form was copied successfully with Serial No. ",
  AccAdded:
    "Congratulations !! The New Accelerator Has Been Sent To Approve Successfully!!",
  AccApr: "Congratulations !! Accelerator Approved Successfully!!",
  AccDisapp: "Congratulations !! Accelerator Disapproved Successfully!!",
  AccMod:
    "Congratulations !! The Accelerator Modification Has Been Sent To Approve Successfully!!",
  inactiveUsers: "Please contact Control Room to re-activate your User",
  sessionFinished: "Your previous session has ended, please login again!!",
  logoutShib:
    "You <b>MUST</b> close your browser to complete the logout process.",
  verifyPassword: "Please verify User/Password, or contact Control Room",
  KEBlSciErrorSignature:
    "Initials for FO in 6.1 are missing. A Floor Opeartor must check step 6.1 first.",
  ChangeBranchlineStatus:
    "Are you sure you want to change this Branchline Status?",
  NoRestrictionsWereAdded: "No restrictions were added",
  NoMitigationsWereAdded: "no mitigations were added",
  AreYouSureYouWantToCopyThisForm: "are you sure you want to copy this form?",
  Enter_A_Reason_To_Disable: "Enter a reason to disable",
  Enter_your_password_to_submit_the_entry_to_olog:
    "Enter your password to submit the entry to olog",
  DocumentNameOrHyperlink: "Document or Link Name",
  webAddress: "URL",
  AddRelatedDocumentOrLink: "Add Related Document or Link",
  YouMustEnterAValidaValue: "You must enter a valid value",
  PPSTB_Filter: "Filter",
  PPSTB_PPSTB_Forms: "PPSTB Forms",
  PPSTB_Show_Closed_Canceled_PPSTB: "Show Closed/Canceled PPSTB",
  PPSTB_Loading_Data: "Loading data...",
  PPSTB_Start_New: "Start new",
  PPSTB_Edit_Form: "Edit Form",
  PPSTB_Need_More_Time: "Need more time?",
  PPSTB_Release_Form: "Release form",
  PPSTB_Force_Release_Form: "Force release form",
  PPSTB_Create_An_Amendment: "Create an amendment",
  PPSTB_Bypass_Initiation: "Bypass<br>Initiation",
  PPSTB_Controls_And_Approvals: "Controls and<br>Approvals",
  PPSTB_Mitigations_Installation: "Mitigations<br>Installation",
  PPSTB_Bypass_Installation: "Bypass<br>Installation",
  PPSTB_Operation_Verification: "Operation<br>Verification",
  PPSTB_Bypass_Removal: "Bypass<br>Removal",
  PPSTB_Mitigations_And_Conditions: "Mitigations &<br>Conditions",
  PPSTB_Close_Out: "Close<br>Out",
  PPSTB_Comments_And_Attachments: "Comments and<br>Attachments",
  PPSTB_1_Bypass_Initiation: "1. Bypass Initiation",
  PPSTB_Creation_Date: "Creation Date",
  PPSTB_User_Initiating_Form: "User Initiating Form",
  PPSTB_Requester: "Requester",
  PPSTB_Related_Forms: "Related Forms",
  PPSTB_Related_Documents_And_Links: "Related Documents and Links",
  PPSTB_Justification_For_Bypass: "Justification for Bypass",
  PPSTB_Systems_Affected: "Systems Affected:",
  PPSTB_Top_Off: "Top-off",
  PPSTB_Radiation_Monitoring_System: "Radiation Monitoring System",
  PPSTB_Beamline: "Beamline",
  PPSTB_Accelerator: "Accelerator",
  PPSTB_Shutters: "Shutters",
  PPSTB_Other_Systems: "Other systems",
  PPSTB_2_Controls_And_Approvals: "2. Controls and Approvals",
  PPSTB_Edit_Olog: "Edit Olog",
  PPSTB_Mitigations: "Mitigations",
  PPSTB_Add_Mitigation: "Add mitigation",
  PPSTB_Conditions_And_Limitations: "Conditions and limitations",
  PPSTB_Systems_And_Print_Number: "Systems & Print Number",
  PPSTB_Bypass_Details: "Bypass Details",
  PPSTB_Permitted_Start_Date: "Permitted Start Date",
  PPSTB_Estimated_Completion: "Estimated Completion",
  PPSTB_Cancel: "Cancel",
  PPSTB_Submit: "Submit",
  PPSTB_Unsubmit: "Unsubmit",
  PPSTB_Save_As_Draft: "Save as Draft",
  PPSTB_Update_Draft: "Update Draft",
  PPSTB_3_Mitigation_Installation_And_Conditions_Limitations_In_Place:
    "3. Mitigation Installation & Conditions / Limitations in Place",
  PPSTB_Save_And_Continue: "Save and Continue",
  PPSTB_Undo: "Undo",
  PPSTB_Mitigation_Not_Required: "Mitigation Not Required",
  PPSTB_4_Bypass_Installation: "4. Bypass Installation",
  PPSTB_Bypass_Not_Required: "Bypass Not Required",
  PPSTB_5_Operation_Verification: "5. Operation Verification",
  PPSTB_6_Bypass_Removal_And_Verification_Of_Operation:
    "6. Bypass Removal and Verification of Operation",
  PPSTB_7_Mitigation_And_Conditions_Limitations_Removal:
    "7. Mitigation and Conditions / Limitations Removal",
  PPSTB_Follow_Up: "Follow Up",
  PPSTB_Repair_Replace_Component: "Repair / replace component",
  PPSTB_Modify_Interlock: "Modify interlock",
  PPSTB_Re_Design_Interlock: "Re-design interlock",
  PPSTB_Other: "Other",
  PPSTB_Save: "Save",
  PPSTB_8_Close_Out: "8. Close Out",
  PPSTB_Close_PPSTB: "Close PPSTB",
  PPSTB_History_Log: "History Log",
  PPSTB_Initial_Values: "Initial Values",
  PPSTB_Empty: "Empty",
  PPSTB_Operational_Restrictions: "Operational Restrictions",
  PPSTB_Approvals: "Approvals",
  PPSTB_Reason: "Reason",
  PPSTB_Amendments: "Amendments",
  PPSTB_Amendment: "Amendment",
  PPSTB_Created_On: "Created on",
  PPSTB_By: "by",
  PPSTB_On: "on",
  PPSTB_Added: "Added",
  PPSTB_Deleted: "Deleted",
  PPSTB_Modified: "Modified",
  PPSTB_Signatures: "Signatures",
  PPSTB_There_Are_No_Signatures: "There are not signatures",
  PPSTB_Date: "Date",
  Rad_Survey_Image_Component_Delete_Row:
    "Are you sure to delete the row and its reference points",
  Rad_Survey_Image_Component_Delete_Image:
    "Are you sure to delete the image and its reference points",
  Rad_Survey_Image_Component_Delete_Column:
    "Are you sure to delete the column of the survey",
  Rad_Survey_Image_Component_Delete_Spot:
    "Are you sure to delete the reference point",
  Rad_Survey_Cancel_Boton_Component_Properties:
    "Are you sure to cancel the editing of the component? (changes will be discarded)",
  PPSTB_label_testing_required:
    "Testing Required Before Operation (Full Test, Functional Test, etc.)?",
  // ...EB2Messages,
  // ...ALSPCMessages,
  // ...APLMessages,
  label_training_complete_option: `I have completed training for this procedure per my supervisor's requirements and I am confident in my ability to fulfill my responsibilities specific to this procedure.`,
  label_training_remove_option: `Are you sure you want to remove this training credit?`,
  genericError: "System error. Please contact Control Room.",
};

export const genericMessage = {
  create: (what: any) => `${what} has been <strong>created</strong> successfully.`,
  update: (what: any) => `${what} has been <strong>updated</strong> successfully.`,
  add: (what: any) => `${what} has been <strong>added</strong> successfully.`,
  remove: (what: any) => `${what} has been <strong> removed</strong> successfully.`,
  read: (what: any) => `${what} has been <strong>*</strong> successfully`,
  creates: (what: any) => `${what} have been <strong>created</strong> successfully.`,
  updates: (what: any) => `${what} have been <strong>updated</strong> successfully.`,
  adds: (what: any) => `${what} have been <strong>added</strong> successfully.`,
  removes: (what: any) =>
    `${what} have been <strong> removed</strong> successfully.`,
  reads: (what: any) => `${what} have been <strong>*</strong> successfully`,
  genericError: "System error. Please contact Control Room",
};

export const shceduleMessage = {
  requestExtension: () => `<strong>Extension</strong> has been requested.`,
};
