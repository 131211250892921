import { Component, Output, EventEmitter, OnChanges } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ClBaseComponent } from '../cl-base/cl-base.component';

@Component({
  selector: 'cl-checkbox-multiple',
  templateUrl: './cl-checkbox-multiple.component.html',
  styleUrls: ['./cl-checkbox-multiple.component.scss']
})
export class ClCheckboxMultipleComponent extends ClBaseComponent implements OnChanges {

  questionSanitized!: SafeHtml;

  @Output() data = new EventEmitter<any>();

  public role!: string;
  public name!: string;

  public visible = false;

  /*------- Control variables ------*/
  allowMultiple!: boolean;
  allRequired!: boolean;

  /*--------------------------------*/

  ngOnChanges() {
    this.getConfiguration();
    this.setValues();
  }

  getConfiguration() {
    if (this.configuration) {
      const configuration = this.utils.JSONparse(this.configuration);
      this.allowMultiple = configuration.multiple;
      this.allRequired = configuration.required;
    }
  }

  setValues() {
    this.valueObjects = this.getValueObjects(this.valueString);
    if (this.valueObjects) {
      this.radioOptions = this.valueObjects?.find(x => x.key == 'checked')?.val;
    }
  }

  checkboxChanged(e: any, val: any) {
    if (!this.allowMultiple) {
      this.radioOptions?.map(x => { x.checked = false; });
    }
    const option = this.radioOptions?.find(x => x.value == val);
    if (option) {
      option.checked = e.checked;
      option.user = e.checked ? this.getCurrentUser() : null;
      option.date = e.checked ? new Date() : null;
      this.valueObjects = [];
      this.valueObjects.push({ key: 'checked', val: this.radioOptions });
      this.saveValues(this.valueObjects);
    }
  }

  checkboxEnabled() {
    let enabled = !this.disabled;
    if (enabled)
      enabled = this.radioOptions?.map(r => r.value).includes(this.radioSelection ?? 0) ?? false;
    return enabled;
  }

  setRadio(value: any) {
    this.radioOptions?.map(r => {
      r.checked = r.value == value;
    });
  }
}
