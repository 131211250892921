<div class="d-flex">
  <mat-icon
    *ngIf="warning"
    color="warn"
    class="warn"
    [matTooltip]="warning"
    matTooltipClass="warn-tooltip"
    matTooltipPosition="left"
    >warning</mat-icon
  >
  <div
    class="checkbox-container ml-3"
    [matTooltip]="getTooltip()"
    matTooltipClass="user-tooltip"
    matTooltipPosition="left"
    [matTooltipDisabled]="false"
  >
    <mat-checkbox
      [labelPosition]="labelPosition"
      [(ngModel)]="checked"
      [disabled]="disabled ?? false"
      (change)="change.emit($event)"
      [color]="color"
      [date]="date"
      [attr.data-initials]="user?.initials"
      class="m-0 checkbox"
    >
      <role-chips
        [roleCodes]="roleCodes"
        class="pt-1 mr-2 role-label"
      ></role-chips>
    </mat-checkbox>
    <div class="label-container" *ngIf="checked">
      <mat-label *ngIf="user?.id != -1; else checkIcon">{{
        checked ? user?.initials : null
      }}</mat-label>
      <ng-template #checkIcon>
        <mat-icon class="white">check</mat-icon>
      </ng-template>
    </div>
    <div class="label-container-blue" *ngIf="!checked">
      <mat-label *ngIf="user?.id != -1">{{ user?.initials }}</mat-label>
    </div>
  </div>
</div>
