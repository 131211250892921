<div class="card">
  <div class="card-header">
    <span class="card-title">
      {{ isAdding ? "Add New Shielding" : "Edit Shielding" }}</span
    >
  </div>
  <div class="card-body">
    <form [formGroup]="formGroup">
      <mat-form-field>
        <mat-label>Shielding Name</mat-label>
        <input
          matInput
          #shieldingInput
          id="shieldingInput"
          type="text"
          placeholder="Shielding Name"
          formControlName="shieldingCtrl"
          [matAutocomplete]="auto"
          (keyup)="filter($event)"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayObject"
          (optionSelected)="selected($event)"
        >
          <mat-option
            *ngFor="let shielding of shieldingsFiltered"
            [value]="shielding"
          >
            {{ shielding.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="shieldingCtrl?.invalid">{{
          getErrorMsg(shieldingCtrl)
        }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>KE Text</mat-label>
        <textarea
          matInput
          [(ngModel)]="shieldingKeText"
          rows="5"
          placeholder="KE Text"
          [disabled]="loading"
          formControlName="ketextCtrl"
        ></textarea>
        <mat-error *ngIf="ketextCtrl?.invalid">{{
          getErrorMsg(ketextCtrl)
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Sub Locations</mat-label>
        <mat-chip-grid #chipListLoc aria-label="Location selection">
          <mat-chip-row
            *ngFor="let location of locations"
            [selectable]="true"
            [removable]="true"
            (removed)="removeLoc(location)"
          >
            {{ location?.childResourceName }}
            <mat-icon matChipRemove class="d-print-none">cancel</mat-icon>
          </mat-chip-row>
          <input
            aria-label="locations"
            #locationInput
            [matAutocomplete]="autoLoc"
            [matChipInputFor]="chipListLoc"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (keyup)="onLocChange($event)"
            #trigger="matAutocompleteTrigger"
            formControlName="locationCtrl"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #autoLoc="matAutocomplete"
          (optionSelected)="selectedLoc($event)"
          #locationAutocomplete
        >
          <mat-option
            *ngFor="let location of subLocationsFiltered"
            [value]="location"
          >
            {{ location.childResourceName }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="locationCtrl?.invalid">{{
          getErrorMsg(locationCtrl)
        }}</mat-error>
      </mat-form-field>
      <color-picker
        class="full-width"
        [color]="shieldingColor"
        (selected)="selectedColor($event)"
      >
      </color-picker>
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        class="float-right"
        mat-flat-button
        color="primary"
        [disabled]="!isValid() || loading"
        (click)="save()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
