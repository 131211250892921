<div class="row" *ngIf="s?.required">
  <div class="col-12">
    <div class="row task" id="{{ s.id }}">
      <div
        class="col-12 pb-1 pt-1"
        [ngClass]="{
          completed:
            task.isCompleted &&
            (task.component?.type == 0 || task.component?.type == 1),
          'not-completed':
            !task.isCompleted &&
            (task.component?.type == 0 || task.component?.type == 1)
        }"
      >
        <master-control
          class="full-width"
          [id]="s.id"
          [code]="s.code"
          [formType]="currentChecklist?.documentType?.code"
          [serialNo]="currentChecklist?.serialNo"
          [type]="s.type"
          [builder]="false"
          [enabled]="s.enabled"
          [serialNo]="currentChecklist?.serialNo"
          [formType]="currentChecklist?.documentType?.code"
          [visible]="s.visible && s.required"
          [disabled]="s.disabled || disabledClose(s) || saving"
          [disableEdit]="s.disableEdit"
          [saving]="saving"
          [currentChecklist]="currentChecklist"
          [color]="color"
          [number]="s.number"
          [header]="s.name"
          [label]="s.name"
          [text]="s.question"
          [question]="s.question"
          [radioQuestion]="s.question2"
          [radioSelection]="s.value2"
          [radioOptions]="s.options"
          [options]="s.options"
          [checkbox_checked]="s.approved"
          [showApprove]="s.approve"
          [approved]="s.approved"
          [approvedBy]="s.approvedBy"
          [approvedOn]="s.approvedOn"
          [showUnapprove]="s.unapprove"
          [showDisapprove]="s.disapprove && !s.approved"
          [roleIds]="s.roles"
          [roleCodes]="s.roleCodes"
          [scheduleResourceId]="s.scheduleResourceId"
          [textBefore]="
            s.type == 6 || s.type == 8 || s.type == 9 ? s.question2 : s.question
          "
          [textAfter]="
            s.type == 6 || s.type == 8 || s.type == 9 ? s.question : s.question2
          "
          [textAfter2]="s.question3"
          [textValue]="s.textValue"
          [checkboxVisible]="s.visible != undefined ? s.visible : true"
          [dateValue1]="s.dateValue1"
          [dateValue2]="s.dateValue2"
          [dateValue3]="s.dateValue3"
          [scheduleTypeId]="s.scheduleTypeId"
          [scheduleResourceId]="s.scheduleResourceId"
          [scheduleName]="s.scheduleName"
          [locationName]="s.locationName"
          [configuration]="s.configuration"
          [sectionNumber]="section?.number"
          [sectionId]="section?.id"
          [editMode]="s.editMode"
          [taskId]="s.id"
          [booleanValue]="s.booleanValue"
          (changed)="change($event, s.id)"
          (save)="save($event, s.id, s.taskId, s.approveId, s.unapproveId)"
          (checked)="check($event, s.taskId, s.approveId, s.unapproveId)"
          (approve)="sign($event, s.taskId, s.approveId)"
          (unapprove)="sign($event, s.taskId, s.unapproveId)"
          (disapprove)="sign($event, s.taskId, s.disapproveId)"
          (duplicate)="duplicate($event)"
          (loading)="loading.emit($event)"
          (checkShared)="checkShared.emit($event)"
          (close)="close()"
        >
        </master-control>
      </div>
      <div
        class="rcp-actions"
        *ngIf="
          task?.component?.type == 1 &&
          currentChecklist?.checklistTemplate?.templateType?.allowActionItems
        "
      >
        <checklist-task-rcpactions
          [task]="task"
          [s]="s"
          [currentChecklist]="currentChecklist"
        ></checklist-task-rcpactions>
      </div>

      <checklist-task-review
        class="review"
        *ngIf="
          currentChecklist?.checklistStatus?.formStatusID ==
            formStatus.Reviewing && isReviewer()
        "
        [s]="s"
        [currentChecklist]="currentChecklist"
        [task]="task"
        (changed)="annotationsChanged($event)"
      ></checklist-task-review>
      <checklist-task-actionitems
        class="action-items"
        *ngIf="
          task?.component?.type == 1 &&
          currentChecklist?.checklistTemplate?.templateType?.allowActionItems
        "
        [task]="task"
        [currentChecklist]="currentChecklist"
      ></checklist-task-actionitems>
      <checklist-tasks-annotations
        class="annotations"
        [task]="task"
        [currentChecklist]="currentChecklist"
        [s]="s"
        (changed)="annotationsChanged($event)"
        *ngIf="
          currentChecklist?.checklistStatus?.formStatusID != formStatus.Draft ||
          currentChecklist?.checklistTemplate?.documentTypeID == 200
        "
      ></checklist-tasks-annotations>
    </div>
  </div>
  <!-- <div
    class="col-2 pl-0"
    *ngIf="currentChecklist?.checklistTemplate?.templateType?.code == 'BAR'"
  >
    <checklist-task-review-satisfaction-check
      [task]="task"
      [checklist]="currentChecklist"
      [precheck]="precheck"
    ></checklist-task-review-satisfaction-check>
  </div> -->
</div>
