<div class="mat-container scrollbar">
  <mat-progress-bar
    class="progress-bar"
    mode="indeterminate"
    *ngIf="loading"
  ></mat-progress-bar>
  <mat-table
    [dataSource]="dataSource"
    matSortActive="created"
    matSortDisableClear
    matSort
    (matSortChange)="sortData($event)"
    matSortDirection="desc"
  >
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Unit
      </mat-header-cell>
      <mat-cell
        class="font-weight-bold"
        *matCellDef="let element"
        [ngStyle]="{ color: getColor(element) }"
      >
        {{ element.name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Description
      </mat-header-cell>
      <mat-cell
        align="center"
        *matCellDef="let element"
        [ngStyle]="{ color: getColor(element) }"
      >
        {{ element.description }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngStyle]="{ color: getColor(element) }"
      >
        {{ element.status == 1 ? "Active" : "Inactive" }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef [ngClass]="'w-120'">
        <button
          mat-icon-button
          color="accent"
          [disabled]="pending"
          (click)="createMeterUnit()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="'w-120'">
        <button
          mat-icon-button
          color="accent"
          [matTooltip]="'Edit File'"
          (click)="EditMeterUnit(element)"
        >
          <mat-icon class="icon">edit</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          [matTooltip]="'Delete File'"
          *ngIf="element.radMeters?.length == 0"
          (click)="deleteMeterUnit(element)"
        >
          <mat-icon class="icon">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      #matrow
      *matRowDef="let row; columns: displayedColumns"
      class="table-row"
      [ngClass]="{ 'mat-row-highlight': selectedRowIndex == row.id }"
      [attr.position]="getPosition(row)"
      (click)="highlight(row)"
    >
    </mat-row>
  </mat-table>
</div>
