<mat-form-field appearance="outline" class="no-subscript">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    #resourceInput
    matInput
    type="text"
    [disabled]="disabled"
    [attr.disabled]="disabled"
    [matAutocomplete]="resourceAutocomplete"
    (keyup)="onResourceInputKeyup($event)"
  />

  <mat-icon
    matSuffix
    color="warn"
    *ngIf="resourceInput.value.length > 0 && !disabled"
    (click)="clear()"
    >close</mat-icon
  >
  <mat-autocomplete
    #resourceAutocomplete="matAutocomplete"
    (optionSelected)="select($event)"
  >
    <mat-option *ngFor="let resource of customResources" [value]="resource">{{
      resource.name
    }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
