<div class="card">
  <div
    class="card-header"
    [ngClass]="{
      'red-background': cancelled(signature)
    }"
  >
    <div class="d-flex align-items-center">
      <mat-icon *ngIf="cancelled(signature)">cancel</mat-icon>
      <span [innerHTML]="formatText(signature.name)"></span>
    </div>
    <span *ngIf="cancelled(signature)" class="d-flex align-items-center"
      ><mat-icon>comment</mat-icon>{{ signature.reason }}</span
    >
  </div>
  <div class="card-body">
    By: <b>{{ signature.signedBy }}</b
    ><br />
    On:
    <span class="date small"> {{ signature.signedOn | date : "medium" }}</span>
  </div>
</div>
<!-- {{ signature.name }} -->
