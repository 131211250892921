import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Store } from '@ngxs/store';
import { Subscription, Observable } from 'rxjs';
import { utils } from 'src/app/modules/libs/utils';
import { APLGroup } from 'src/app/components/apl/services/apl-group.model';

@Component({
  selector: 'apl-group-autocomplete',
  templateUrl: './apl-group-autocomplete.component.html',
  styleUrls: ['./apl-group-autocomplete.component.scss']
})
export class APLGroupAutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() aplGroupID?: number;

  @Output() aplGroupIDChange = new EventEmitter<number>();

  aplGroupIDValue!: number;
  aplGroupSubscription!: Subscription;
  aplGroup$!: Observable<APLGroup[]>;
  allAplGroups!: APLGroup[];
  filteredAplGroups!: APLGroup[];
  text!: string;

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.aplGroup$ = this.store.select(state => state.APLGroup.all);
    this.aplGroupSubscription = this.aplGroup$.subscribe(aplGroups => {
      this.allAplGroups = aplGroups;
      this.filteredAplGroups = utils.cloneDeep(this.allAplGroups);
      this.set();
      this.filter();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('aplGroupID' in changes) {
      this.set();
      this.filter();
    }
  }

  ngOnDestroy(): void {
    this.aplGroupSubscription?.unsubscribe();
  }

  set() {
    if (this.allAplGroups?.filter(x => x.id === this.aplGroupID)?.length > 0) {
      this.text = this.allAplGroups?.filter(x => x.id === this.aplGroupID)[0]?.name ?? '';
    } else {
      this.text = '';
    }
  }

  filter(code?: string) {
    if (!code?.includes('Arrow')) {
      if (this.text != undefined) {
        this.filteredAplGroups = this.allAplGroups?.filter(x => x?.name?.toLowerCase()?.includes(this.text.toLowerCase()));
      } else {
        this.filteredAplGroups = utils.cloneDeep(this.allAplGroups);
      }
    }
  }

  select(event: MatAutocompleteSelectedEvent) {
    const aplGroup: APLGroup = event.option.value;
    this.aplGroupID = aplGroup.id;
    this.set();
    this.filter();
  }

}
