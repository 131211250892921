import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'src/app/common/base/base.service';
import { ScheduleNote } from '../models/schedule-note';

@Injectable({
  providedIn: 'root'
})
export class ScheduleSubdetailNoteService extends BaseService<ScheduleNote, number> {

  constructor(protected override injector: Injector,) {
    super(injector, '/schedule_subdetail_note');
  }

}
