import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { RelatedLink } from 'src/app/common/models/related-link';
import { RelatedLinkBase } from 'src/app/components/catalogs/navigation-links/link/related-link-base';
import { AddRelatedLinkComponent } from '../add-related-link/add-related-link.component';
import { AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'related-link-chips',
  templateUrl: './related-link-chips.component.html',
  styleUrls: ['./related-link-chips.component.scss']
})
export class RelatedLinkChipsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() formId?: number;
  @Input() relatedLinks?: RelatedLinkBase[];
  @Input() documentNameOrHyperlinkPlaceholder?: string;
  @Input() webAddressPlaceholder?: string;
  @Input() hilite?: boolean | null;

  @Output() onEdited = new EventEmitter<RelatedLinkBase[]>();

  public get chipInputCtrl(): AbstractControl | null { return this.formGroup?.get("chipInputCtrl"); }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      chipInputCtrl: [{ value: null }],
    });
    if (this.disabled) this.formGroup.disable(); else this.formGroup.enable();
  }

  open() {
    if (!this.disabled) {
      const dialog = this.dialog.open(AddRelatedLinkComponent, {
        maxWidth: '800px',
        data: {
          obj: {
            Text: null,
            URL: null,
          } as RelatedLink,
          DocumentNameOrHyperlinkPlaceholder: this.documentNameOrHyperlinkPlaceholder,
          WebAddressPlaceholder: this.webAddressPlaceholder,
        }
      });
      dialog.afterClosed().subscribe((result: RelatedLink) => {
        if (result) {
          if (!this.relatedLinks) this.relatedLinks = [];
          this.relatedLinks?.push({
            formID: this.formId ?? 0,
            text: result.Text ?? '',
            url: result.URL ?? ''
          });
          this.onEdited.emit(this.relatedLinks);
        }
      });
    }
  }

  remove(relatedLink: RelatedLinkBase) {
    const index = this.relatedLinks?.indexOf(relatedLink) ?? -1;

    if (index >= 0) {
      this.relatedLinks?.splice(index, 1);
      this.onEdited.emit(this.relatedLinks);
    }
  }

}
