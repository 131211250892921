import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnInit, ViewChildren, QueryList, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';
import { Checklist } from '../../checklists';
import { ChecklistsService } from '../../checklists.service';
import { ChecklistSectionComponent } from './checklist-section/checklist-section.component';
import { utils } from 'src/app/modules/libs/utils';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';
import { ComponentType } from 'src/app/common/enumerations/enumerations';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: "checklist-sections",
  templateUrl: "./checklist-sections.component.html",
  styleUrls: ["./checklist-sections.component.scss"],
})
export class ChecklistSectionsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() currentChecklist?: Checklist | null;
  @Input() precheck?: boolean;
  @Input() fullHeight?: boolean;

  @Output() loading = new EventEmitter<any>();
  @Output() checkShared = new EventEmitter<any>();

  sections!: WFSectionLocalResource[];
  scrolled?: boolean;
  updating: boolean = false;

  checklists?: Checklist[];
  checklistsFiltered?: Checklist[];
  checklists$!: Observable<Checklist[]>;
  checklistsSubs!: Subscription;

  @ViewChildren(ChecklistSectionComponent) public sectionComponents!: QueryList<ChecklistSectionComponent>;


  constructor(
    protected override injector: Injector,
    private route: ActivatedRoute,
    private service: ChecklistsService,
  ) { super(injector); }

  ngOnInit(): void {
    this.loadChecklists();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentChecklist']) {
      if (changes['currentChecklist'].currentValue?.id != changes['currentChecklist'].previousValue?.id) {
        this.scrolled = false;
        this.scrollToTask();
      }
      if (this.currentChecklist?.wfTable?.wfTableLocal?.wfSectionLocals?.length != this.sections?.length) {
        if (this.currentChecklist?.documentTypeID)
          this.setOpenDocument(this.currentChecklist.documentTypeID, this.currentChecklist.id);
        this.loadSections();
      }
    }
  }

  loadChecklists() {
    this.checklists$ = this.store.select(state => state.Checklists.data);
    this.checklistsSubs = this.checklists$.subscribe(data => {
      if (data?.length) {
        this.checklists = data;
        if (this.currentChecklist) {
          const checklist = this.checklists.find(c => c.id == this.currentChecklist?.id);
          if (checklist) {
            this.currentChecklist = checklist;
            this.loadSections();
          }
        }
      }
    });
  }

  async loadSections() {
    let checklist: Checklist | undefined;
    if (this.currentChecklist?.documentTypeID && this.checklists) {
      checklist = (await this.checkOpenDocument<Checklist>(this.currentChecklist?.documentTypeID, this.checklists))?.document;
    }

    if (checklist)
      this.currentChecklist = checklist as Checklist;

    this.sections = this.currentChecklist?.wfTable?.wfTableLocal?.wfSectionLocals?.sort((a, b) => utils.sort(a.order, b.order)) ?? [];
    const sectionTypes = Array.from(new Set(this.sections?.filter(s => s.type).map(s => s.type)));
    sectionTypes.map(t => {
      const sections = this.sections.filter(s => t && s.type == t);
      if (sections.length > 1) {
        let index = 1;
        sections.filter((s, i) => i != 0).map(s => { s.code = String.fromCharCode(96 + (index++)) });
      }
    });
    if (!this.sections) { this.sections = []; }
    this.service.setAllTasks(this.sections);

  }

  scrollToTask() {
    if (!this.scrolled) {
      const id = this.route.snapshot.queryParams['task'];
      if (id) {
        const element = document.getElementById(id);
        if (element) {
          setTimeout(() => {
            element?.scrollIntoView({ behavior: "smooth" });
          }, 1000);
          this.scrolled = true;
        }
      } else {
        const element = document.getElementById("sections");
        const container = document.getElementById('scrollableContainer');

        if (element && container) {
          // Calculate the distance of the element from the top of the container
          const elementOffsetTop = element.offsetTop - 10;

          // Scroll the container to the element's position
          container.scrollTo({
            top: elementOffsetTop,
            behavior: 'smooth', // Optional for smooth scrolling
          });
          this.scrolled = true;
        }
      }
    }
  }

  checkSharedComponents(e: any) {
    // const shieldings = e.shieldings as any[];
    // const taskID = e.task;
    // this.sectionComponents.map(sectionComponent => {
    //   if (!sectionComponent.stepsComponent) {
    //     // debugger;
    //   }
    //   sectionComponent.stepsComponent.stepComponents.map(stepComponent => {
    //     stepComponent.tasksComponent.taskComponents.filter(taskComponent => taskComponent.task.component?.id == ComponentType.ShieldingsComponent && taskComponent.task.id != taskID).map(taskComponent => {
    //       const sc = taskComponent.masterControl.shieldingsComponent;
    //       if (sc?.shieldings?.some(s => shieldings.map(s => s.resourceID).includes(s.resourceID))) {
    //         setTimeout(() => {
    //           // sc.changeShared(shieldings);
    //         }, 10);
    //       }
    //     });
    //   })
    // });
  }

}
