<div class="card p-0">
  <div
    class="d-flex align-items-center justify-content-between px-3 py-2"
    *ngIf="schedule"
  >
    <div>
      <mat-slide-toggle
        [(ngModel)]="isChecked"
        [disabled]="disabled ?? false"
        color="primary"
        labelPosition="before"
        (ngModelChange)="toggleChanged()"
      >
        <span [innerHTML]="toggleLabel" class="mr-2"></span
        ><span [ngClass]="{ 'bold red': !isChecked, gray: isChecked }"
          >NO</span
        ></mat-slide-toggle
      ><span
        class="ml-1"
        [ngClass]="{ 'bold blue': isChecked, gray: !isChecked }"
        >YES</span
      >
    </div>
    <div class="small">
      <span *ngIf="schedule.statusId != 1">({{ schedule.status.name }}) </span>

      {{ schedule.type.acronym }} Schedule - Due Date:
      <span class="date">{{ schedule.nextDue | date }}</span>
      <span *ngIf="schedule.scheduleFor && !schedule.completedDate">
        - Scheduled For:
        <span class="date">{{ schedule.scheduleFor | date }}</span></span
      >
      <span *ngIf="schedule.completedDate">
        - Completed Date:
        <span class="date">{{ schedule.completedDate | date }}</span></span
      >
    </div>
  </div>
  <div class="card-header p-3">
    <div class="row pb-0 flex-row">
      <div class="col">
        <div class="row">
          <div class="col-12 text-wrap d-flex flex-row">
            <h6 [innerHTML]="title ?? '' | placeholderHighlight : editor">
              <!-- {{ title }} -->
            </h6>
            <role-chips [roleCodes]="roleCodes" class="ml-3"></role-chips>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div
          class="row-buttons row flex-column d-flex d-print-none align-items-end pr-2"
          style="height: 100%; justify-content: flex-start"
        >
          <div class="col-buttons col-12 p-0">
            <button
              mat-flat-button
              class="float-right"
              *ngIf="!value"
              [disabled]="buttonDisabled()"
              color="warn"
              (click)="click()"
            >
              {{ label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body p-3" *ngIf="value">
    <div class="row no-gutters">
      <div class="col-12 col-xl-3 fit-content pb-0">Closed By:</div>
      <div class="col-12 col-xl-9 fit-content pb-0">
        <span class="name">{{ name }}</span>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-xl-3 pt-0">
        <small>Date:</small>
      </div>
      <div class="col-12 col-xl-9 fit-content pt-0">
        <span class="date small">{{ date | date : "medium" }}</span>
      </div>
    </div>
  </div>
</div>
