import { Component, OnInit, Output, EventEmitter, ViewChildren, ViewContainerRef, QueryList, ViewChild, OnDestroy, AfterViewInit, Input, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatRow } from '@angular/material/table';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Catalog, StatusPendingCatalog, ActionPendingCatalog, PendingCatalogColor } from 'src/app/common/enumerations/enumerations';
import { AcceleratorCatalog } from 'src/app/components/catalogs/accelerator-catalog/services/accelerator-catalog';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { CatalogService } from '../../catalog-service';
import { PendingCatalogType } from 'src/app/services/pending-approvals/pending-approval';


@Component({
  selector: 'app-accelerator-catalog-list',
  templateUrl: './accelerator-catalog-list.component.html',
  styleUrls: ['./accelerator-catalog-list.component.scss']
})
export class AcceleratorCatalogListComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() filter!: string;

  @Output() loading = new EventEmitter<boolean>();
  @Output() rowSelected = new EventEmitter<any>();

  canCreate!: boolean;
  canApprove!: boolean;

  selectedRowIndex!: number;
  selectedIndex!: number;

  displayedColumnsAccelerator: string[] = ['name', 'status'];
  dataSource!: MatTableDataSource<AcceleratorCatalog>;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChildren('matrow', { read: ViewContainerRef })
  rows!: QueryList<ViewContainerRef>;
  @ViewChildren('matrow')
  datarows!: QueryList<MatRow>;

  constructor(
    protected override injector: Injector,
    private catalogService: CatalogService,) {
    super(injector);
  }

  async ngOnInit() {
    this.loadAccelerators();
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  loadAccelerators() {
    this.loading.emit(true);
    const accelerator = this.catalogService.pendingCatalog?.catalogType == PendingCatalogType.Accelerator ? this.catalogService.pendingCatalog : null;
    this.canApprove = this.hasPrivilege(PrivilegeEnum.AcceleratorCatalogApprove);
    this.canCreate = this.hasPrivilege(PrivilegeEnum.AcceleratorCatalogCreate);
    this.catalogService.GetJoinCreated(Catalog.AcceleratorCatalog, this.canCreate || this.canApprove).toPromise().then(data => {
      data?.acceleratorCatalogs?.map(x => {
        if (x.pendingAcceleratorCatalog) {
          x.pendingAcceleratorCatalog.statusName = StatusPendingCatalog[x.pendingAcceleratorCatalog.status];
          x.pendingAcceleratorCatalog.actionName = ActionPendingCatalog[x.pendingAcceleratorCatalog.action];
        }
        if (x.lastPendingAccelerator)
          x.lastPendingAccelerator.statusByUser = this.users.find(u => u.id == x.lastPendingAccelerator?.statusBy);
      });
      this.dataSource = new MatTableDataSource(data?.acceleratorCatalogs);
      this.setSortingAccessor();
      this.dataSource.filter = this.filter;

      if (accelerator) {
        if (accelerator.id) {
          this.showIndex(accelerator.id);
        }
        else if (!accelerator.id) {
          this.showIndex(accelerator.pendingID);
        }
      } else {
        this.loading.emit(false);
      }
      this.loading.emit(false);
    }, error => this.loading.emit(false));
  }

  setSortingAccessor() {
    // Define the sorting accessor
    this.dataSource.sortingDataAccessor = (element, property) => {
      switch (property) {
        case 'name':
          return element.details.accelerator
            ? element.details.accelerator.name
            : element.pendingDetails?.accelerator?.name;
        case 'status':
          return element.pendingAcceleratorCatalog?.statusName +
            " - " +
            element.pendingAcceleratorCatalog?.actionName;
        default:
          return (element as any)[property]; // Fallback to the default property
      }
    };
    this.setSort<AcceleratorCatalog>(this.dataSource, this.sort);
  }

  async selectedRow(row: AcceleratorCatalog) {
    if (await this.canDeactivate())
      return this.selectRow(this.utils.cloneDeep(row));
  }

  selectRow(row: AcceleratorCatalog) {
    if (row && this.selectedRowIndex != row.id) {
      this.selectedRowIndex = row.id;
      this.rowSelected.emit(row);
    } else {
      this.selectedRowIndex = -1;
      this.rowSelected.emit(null);
    }
  }

  createResource() {
    this.selectedRowIndex = -1;
    this.rowSelected.emit(new AcceleratorCatalog());
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getColor(resource: Resource): string {
    if (resource.color === PendingCatalogColor.Create || resource.color === PendingCatalogColor.Modify) {
      return '#EAAA00';
    }
    return '';
  }

  showIndex(id: number) {
    this.selectedIndex = this.dataSource.data.findIndex(x => x.id === id);
    this.selectedRowIndex = -1;
    this.showElement(this.selectedIndex, 7);
    this.selectedRow(this.dataSource.data[this.selectedIndex]);
    this.loading.emit(false);
  }

  showElement(index: number, height: number) {
    const indexstr = index.toString();
    const row = this.rows.find(x => x.element.nativeElement.getAttribute('position') === indexstr);
    if (row) {
      const rect = row.element.nativeElement.getBoundingClientRect();
      if ((rect.y <= 0) || ((rect.y + rect.height) > height)) {
        row.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
      }
      return;
    }
    console.log('not found');
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex(r => {
      return r.id === row.id;
    });
    return i;
  }

}
