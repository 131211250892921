import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EpicsValuesResource } from 'src/app/components/kenable/ke';
import { environment } from 'src/environments/environment';
import { Link } from '../../navigation-links/link/link';
import { Resource, IResource, ResourceType, ResourceRelation, StatusResource, ResourceUserRelation, ShutterResource, SaveResource, ResourceSummary, BeamlineShutterRelation, BeamlineResource } from './resources';
import { TOCA } from './toca';

@Injectable()
export class ResourcesService {
  api = '/Resources';
  constructor(private http: HttpClient) { }

  getAllResources(): Observable<Resource[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/Resources')
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getResourcesByType(type: number): Observable<Resource[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/Resources/Type/' + type.toString())
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getIResourcesByType(type: number): Observable<IResource[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/Resources/Type/' + type.toString())
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getBranchlines(): Observable<IResource[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/Resources/Branchlines/')
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  updateOperationalLockOut(resourceId: number, value: any): Observable<any> {
    const data = { resource: resourceId, value };
    const result = this.http.put(environment.urls.baseApiUrl + '/Resources/update/branchline/OperationalLockOut', data)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getAllResourceTypes(): Observable<ResourceType[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/ResourceTypes')
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getRelatedResources(id: number): Observable<ResourceRelation[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/Resources/Related/' + id)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getResourcesRelationsByChildId(id: number): Observable<ResourceRelation[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/ResourceRelations/ChildId/' + id)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getResourceById(id: number): Observable<Resource> {
    const result = this.http.get(environment.urls.baseApiUrl + '/Resources/' + id.toString())
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getResourcesRelatedTreeByChildId(id: number): Observable<Resource[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/ResourceRelations/RelatedTree/' + id.toString())
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getResourcesRelatedTreeByParent(id: number): Observable<Resource[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/ResourceRelations/RelatedTreeParent/' + id.toString())
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getRelationResources(): Observable<ResourceRelation[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/ResourceRelations')
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getStatusResources(): Observable<StatusResource[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/StatusResource')
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  // to be continued
  getResourceUserRelationsById(resIds: number[]): Observable<ResourceUserRelation[]> {
    let resources = '';
    resIds.map(element => {
      resources += 'resIds=' + element + '&';
    });
    resources = resources.slice(0, -1);
    const result = this.http.get(environment.urls.baseApiUrl + '/ResourceUserRelation/byIds?' + resources)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getShutters(): Observable<ShutterResource[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/Resources/Shutters')
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getBeamlines(): Observable<BeamlineResource[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/Resources/Beamlines')
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getShutter(id: number): Observable<ShutterResource> {
    const result = this.http.get(environment.urls.baseApiUrl + '/Resources/Shutters/' + id)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }
  getLocations(): Observable<Resource[]> {
    return this.http.get<Resource[]>(environment.urls.baseApiUrl + '/Resources/Locations');
  }

  getEpicsValues(): Observable<EpicsValuesResource[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/Resources/EpicsValues')
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  saveResource(resource: Resource): Observable<Resource> {
    if (!resource.id) {
      const saveResource: SaveResource = {
        name: resource.name,
        type: resource.type,
        status: 1,
        order: resource.order,
        description: resource.description ?? '',
        resourceTypeID: resource.type,
        beamLineExtension: resource.beamLineExtension ?? '',
        beamLineSupervisorID: resource.beamLineSupervisorID,
        beamLineScientistID: resource.beamLineScientistID,
        beamLineAlternateScientistID: resource.beamLineAlternateScientistID,
        beamLineNotes: resource.beamLineNotes,
        keRestricted: resource.keRestricted,
        reviewRestrictedResourceID: resource.reviewRestrictedResourceID,
        rssChannelName: resource.rssChannelName,
        pssChannelName: resource.pssChannelName
      };
      return this.http.post<Resource>(environment.urls.baseApiUrl + '/Resources', saveResource);
    } else {
      return this.http.put<Resource>(environment.urls.baseApiUrl + '/Resources/' + resource.id, resource);
    }
  }

  getResourceSummaryByBeamline(id: number): Observable<ResourceSummary> {
    const result = this.http.get(environment.urls.baseApiUrl + '/ResourcesSummary/' + id.toString())
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getResourceSummaryByShutter(id: number): Observable<ResourceSummary> {
    const result = this.http.get(environment.urls.baseApiUrl + '/ResourcesSummary/GetResourceSummaryByShutter/' + id.toString())
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }
  getAllResourceSummaries(): Observable<BeamlineShutterRelation[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/ResourcesSummary')
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getAllResourceStaff(): Observable<any[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/ResourcesSummary/GetAllResourceStaff')
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getResourceSummaryPendingById(id: number): Observable<ResourceSummary> {
    const result = this.http.get(environment.urls.baseApiUrl + '/ResourcesSummary/PendingResource/' + id.toString())
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getResourceSummaryPendingByResourceId(id: number): Observable<ResourceSummary> {
    const result = this.http.get(environment.urls.baseApiUrl + '/ResourcesSummary/PendingResourceByResource/' + id.toString())
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getResourcesJoinCreatedResources(): Observable<Resource[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/ResourcesSummary/JoinCreatedResources')
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getResourceIsModifiyingById(id: number): Observable<boolean> {
    const result = this.http.get(environment.urls.baseApiUrl + '/ResourcesSummary/IsModifiying/' + id.toString())
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getTOCAChangesByResourceId(resourceId: number, showChanges: boolean): Observable<TOCA[]> {
    const result = this.http.get<TOCA[]>(environment.urls.baseApiUrl + '/ResourcesSummary/TOCAChanges/' + resourceId + '/' + showChanges)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getLinkChangesByResourceId(resourceId: number, linkType: number, showChanges: boolean): Observable<Link[]> {
    const result = this.http.get<Link[]>(environment.urls.baseApiUrl + '/ResourcesSummary/LinkChanges/' + resourceId + '/' + linkType + '/' + showChanges)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  approveCreateResourceSummaryById(id: number): Observable<ResourceSummary> {
    const result = this.http.put(environment.urls.baseApiUrl + '/ResourcesSummary/ApproveToCreate/' + id.toString(), null)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  approveUpdateResourceSummaryById(id: number): Observable<ResourceSummary> {
    const result = this.http.put(environment.urls.baseApiUrl + '/ResourcesSummary/ApproveToUpdate/' + id.toString(), null)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  unapproveResourceSummaryById(id: number, unapprovalReason: string): Observable<null> {
    const result = this.http.put(environment.urls.baseApiUrl + '/ResourcesSummary/Unapprove/' + id, { reason: unapprovalReason })
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  postResourceSummary(resourceSummary: ResourceSummary) {
    if (resourceSummary) {
      if (resourceSummary.shutter) {
        resourceSummary.shutter.type = 7;
        resourceSummary.shutter.status = 1;
      }
      if (resourceSummary.beamline) {
        resourceSummary.beamline.type = 2;
        resourceSummary.beamline.status = 1;
      }
    }
    const result = this.http.post(environment.urls.baseApiUrl + '/ResourcesSummary', resourceSummary)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  putResourceSummary(id: number, resourceSummary: ResourceSummary) {
    const result = this.http.put(environment.urls.baseApiUrl + '/ResourcesSummary/' + id.toString(), resourceSummary)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  deleteResource(resource: Resource) {
    const result = this.http.delete(environment.urls.baseApiUrl + '/Resources/' + resource.id)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  saveResourceRelations(id: number, resourceRelations: ResourceRelation[], oldResourceRelations: ResourceRelation[]): Observable<ResourceRelation[]> {
    let result: any;
    resourceRelations.map(rel => {
      // tslint:disable-next-line: no-debugger
      debugger;
      if (!rel.id) {
        result = this.http.post(environment.urls.baseApiUrl + '/ResourceRelations', rel)
          .pipe(
            catchError(this.handleError)
          );
        console.log('Added - ' + (result as ResourceRelation).childResourceName);
        return result;
      } else {
        result = this.http.put(environment.urls.baseApiUrl + '/ResourceRelations/' + rel.id, rel)
          .pipe(
            catchError(this.handleError)
          );
        console.log('Updated - ' + (result as ResourceRelation).childResourceName);
        return result;
      }
    });
    oldResourceRelations.map(rel => {
      if (!resourceRelations.find(elem => elem.id === rel.id)) {
        result = this.http.delete(environment.urls.baseApiUrl + '/ResourceRelations/' + rel.id)
          .pipe(
            catchError(this.handleError)
          );
        console.log('Deleted - ' + rel.childResourceName);
        return result;
      }
    });
    return result;
  }
  postResourceRelation(rel: ResourceRelation) {
    const result = this.http.post(environment.urls.baseApiUrl + '/ResourceRelations', rel)
      .pipe(
        catchError(this.handleError)
      );
    console.log('Added - ' + rel.childResourceName);
    return result;
  }
  putResourceRelation(rel: ResourceRelation) {
    const result = this.http.put(environment.urls.baseApiUrl + '/ResourceRelations/' + rel.id, rel)
      .pipe(
        catchError(this.handleError)
      );
    console.log('Updated - ' + rel.childResourceName);
    return result;
  }
  deleteResourceRelation(rel: ResourceRelation) {
    const result = this.http.delete(environment.urls.baseApiUrl + '/ResourceRelations/' + rel.id)
      .pipe(
        catchError(this.handleError)
      );
    console.log('Deleted - ' + rel.childResourceName);
    return result;
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
  getAllShutters(): Observable<Resource[]> {
    return this.http.get<Resource[]>(environment.urls.baseApiUrl + this.api + '/Shieldings');
  }
  getShieldingByBeamlineId(id: number): Observable<Resource> {
    return this.http.get<Resource>(environment.urls.baseApiUrl + this.api + 'Shieldings/BeamlineId/' + id.toString());
  }

  getByCodes(codes: string[]): Observable<Resource[]> {
    let url = environment.urls.baseApiUrl + this.api + '/Codes';
    let query = '?';

    for (let i = 0; i < codes.length; i++) {
      if (query != '?') {
        query += '&';
      }
      query += 'c=' + codes[i];
    }
    url += query;
    return this.http.get<Resource[]>(url);
  }

  getByTypes(types: number[]): Observable<Resource[]> {
    let values = '';
    types.map(x => {
      if (values !== '') {
        values += '&';
      }
      values += 't=' + x.toString();
    });
    return this.http.get<Resource[]>(environment.urls.baseApiUrl + this.api + '/Types?' + values);
  }

  getBeamlinesByShutterRestrictions(ids: number[]): Observable<Location[]> {
    let url = environment.urls.baseApiUrl + this.api + '/BeamlineByShutterRestrictions';
    let query = '?';

    for (let i = 0; i < ids.length; i++) {
      if (query != '?') {
        query += '&';
      }
      query += 'i=' + ids[i];
    }
    url += query;

    return this.http.get<Location[]>(url);
  }

  getAllBranchlines(): Observable<Resource[]> {
    return this.http.get<Resource[]>(environment.urls.baseApiUrl + this.api + '/AllBranchlines');
  }

  getFromChecklistTemplateByDocumentType(documentTypeID: number): Observable<Resource[]> {
    return this.http.get<Resource[]>(environment.urls.baseApiUrl + this.api + '/FromChecklistTemplateByDocumentType/' + documentTypeID.toString());
  }

  getByTemplateType(templateType: number): Observable<Resource[]> {
    return this.http.get<Resource[]>(environment.urls.baseApiUrl + this.api + '/ByTemplateType/' + templateType.toString());
  }
}
