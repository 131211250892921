import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToxicGas } from './toxic-gas';

@Injectable({ providedIn: 'root' })
export class ToxicGasService {
  constructor(
    private http: HttpClient
  ) {
  }

  private OrderBy(a: ToxicGas, b: ToxicGas): number {
    const roofAccess = a.roofAccess === b.roofAccess ? 0 : a.roofAccess ? 1 : -1;
    return roofAccess === 0 ? a.date > b.date ? -1 : a.date < b.date ? 1 : 0 : roofAccess;
  }

  OrderByDate(a: ToxicGas, b: ToxicGas): number {
    return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
  }

  loadActives(list: ToxicGas[]): ToxicGas[] {
    list.map(item => {
      if (item.date != null) {
        item.date = new Date(item.date);
      }
    });

    return list.sort(this.OrderBy);
  }

  loadArchives(list: ToxicGas[]): ToxicGas[] {
    list.map(item => {
      if (item.date != null) {
        item.date = new Date(item.date);
      }
    });

    return list.sort(this.OrderByDate);
  }

  getActive(): Observable<ToxicGas[]> {
    const result = this.http.get<ToxicGas[]>(
      environment.urls.baseApiUrl + '/ToxicGas/GetActives'
    );

    return result;
  }

  getArchive(): Observable<ToxicGas[]> {
    const result = this.http.get<ToxicGas[]>(
      environment.urls.baseApiUrl + '/ToxicGas/GetInactives'
    );

    return result;
  }

  getAll(): Observable<ToxicGas[]> {
    const result = this.http.get<ToxicGas[]>(
      environment.urls.baseApiUrl + '/ToxicGas'
    );

    return result;
  }

  save(item: ToxicGas): Observable<ToxicGas> {
    return !item.id ? this.insert(item) : this.update(item);
  }

  private insert(item: ToxicGas): Observable<ToxicGas> {
    const result = this.http.post<ToxicGas>(
      environment.urls.baseApiUrl + '/ToxicGas/',
      item
    );

    return result;
  }

  private update(item: ToxicGas): Observable<ToxicGas> {
    const result = this.http.put<ToxicGas>(
      environment.urls.baseApiUrl + '/ToxicGas/' + item.id.toString(),
      item
    );

    return result;
  }

  archive(id: number): Observable<ToxicGas> {
    const result = this.http.delete<ToxicGas>(
      environment.urls.baseApiUrl + '/ToxicGas/' + id.toString()
    );

    return result;
  }

}
