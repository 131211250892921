<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-2">
        <h5>CATL Report</h5>
      </div>
      <div class="col-3">
        <h5>
          <span class="small">SerialNo: </span>{{ currentDocument.serialNo }}
        </h5>
      </div>
      <div class="col-3">
        <h5 class="float-right">
          <span class="small">Endstation(s): </span
          >{{ currentDocument.resourceName }}
        </h5>
      </div>
      <div class="col-3 nowrap">
        <h5 class="float-right">
          <span class="small pr-2">Action Items Count: </span
          >{{ actionItems?.length }}
        </h5>
      </div>
      <div class="col-1">
        <button
          mat-mini-fab
          class="closex float-right"
          color="warn"
          (click)="close()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <mat-progress-bar
      class="progress-bar"
      mode="indeterminate"
      *ngIf="loading"
    ></mat-progress-bar>
  </div>
  <div class="card-body card-body-scroll">
    <div class="card" *ngFor="let action of actionItems">
      <div class="card-header">
        <div class="row">
          <div class="col-2">
            Date: <b>{{ action.createdOn | date }}</b>
          </div>
          <div class="col-3">
            Owner: <b>{{ action.ownerName }}</b>
          </div>
          <div class="col-2">
            Location: <b>{{ action.location }}</b>
          </div>
          <div class="col-2">
            Due Period: <b>{{ action.duePeriod }} days</b>
          </div>
          <div class="col-3">
            Created By: <b>{{ action.createdByName }}</b>
          </div>
        </div>
      </div>
      <div class="card-body">
        <span [innerHTML]="action.html"></span>
      </div>
    </div>
  </div>
</div>
