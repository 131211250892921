
<div class="container-fluid">
  <div class="row mt-3">
  <div class="col">
    <h5>Mitigation and Conditions / Limitations Removal</h5>
  </div>
</div>
<form-workflow
  [form]="form"
  [sectionNumber]="7"
  [leftConfigs]="[56]"
  [rightConfigs]="[57]"
  [disabled]="disabled"
  (loading)="loading.emit($event)"
>
</form-workflow>
</div>