<div class="card p-0">
  <form [formGroup]="formOlog" *ngIf="formOlog">
    <div class="card-header">
      <h5>Olog Notification ({{ serialNo }})</h5>
      <button
        mat-mini-fab
        class="closex"
        color="warn"
        (click)="cancel()"
        *ngIf="disabled"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="card-body">
      <div class="pt-2">
        <mat-form-field appearance="outline" class="no-subscript">
          <mat-label>Emails</mat-label>
          <mat-chip-grid #chipList>
            <mat-chip-row
              *ngFor="let mail of emailAddresses"
              [removable]="mail.isCustom"
              (removed)="remove(mail)"
            >
              {{
                mail.name ? mail.name + " <" + mail.address + ">" : mail.address
              }}
              <mat-icon matChipRemove *ngIf="mail.isCustom">cancel</mat-icon>
            </mat-chip-row>
            <input
              aria-label="email"
              (keyup)="keyUpCc()"
              [matChipInputFor]="chipList"
              [disabled]="disabled"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event)"
              formControlName="cc"
            />
          </mat-chip-grid>
          <mat-hint class="error" *ngIf="formOlog?.controls?.['cc']?.errors"
            >Enter a valid email address</mat-hint
          >
        </mat-form-field>
      </div>
      <div class="pt-2">
        <mat-form-field appearance="outline" class="no-subscript">
          <mat-label>Subject</mat-label>
          <mat-chip-grid #chipListSubject aria-label="Subject selection">
            <mat-chip-row
              [selectable]="false"
              *ngIf="
                formOlog.controls['subject']?.value &&
                formOlog.controls['subject']?.value !== ''
              "
            >
              {{ formOlog.controls["subject"].value }}
            </mat-chip-row>
            <input
              aria-label="subject"
              [matChipInputFor]="chipListSubject"
              formControlName="extraSubject"
              [disabled]="disabled"
            />
          </mat-chip-grid>
          <mat-error *ngIf="formOlog?.get('extraSubject')?.errors"
            >You must enter a valid value</mat-error
          >
        </mat-form-field>
      </div>
      <fieldset class="field-group pt-2 mb-2 relative">
        <legend class="field-group-label">Details</legend>
        <div
          class="pl-2"
          *ngIf="tmpDescription"
          [innerHTML]="tmpDescription"
        ></div>
        <comments-box
          *ngIf="tmpDetails"
          [textValue]="tmpDetails"
          [formType]="formType?.code?.trim()"
          [serialNo]="serialNo"
          (textChanged)="tmpDetails = $event"
          [maxHeight]="'300px'"
          [noSubscript]="true"
          [noBorder]="true"
          [disabled]="disabled"
          [minHeight]="'60px'"
        ></comments-box>
        <span class="pl-2 mt-2">
          Please see ASCC for details - <a>{{ serialNo }}</a></span
        >
      </fieldset>
      <div class="pt-2">
        <div class="row">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label> Categories</mat-label>
            <input
              aria-label="categories"
              matInput
              value="{{ categories }}"
              disabled
            />
          </mat-form-field>
          <mat-form-field class="col-4" appearance="outline">
            <mat-label>Highlights</mat-label>
            <input
              aria-label="highlight"
              matInput
              value="{{ highlight }}"
              disabled
            />
          </mat-form-field>
          <mat-form-field class="col-4" appearance="outline">
            <mat-label>Level</mat-label>
            <input aria-label="level" matInput value="{{ level }}" disabled />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="card-footer" *ngIf="!disabled">
      <button
        mat-flat-button
        color="warn"
        class="float-left"
        type="button"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        class="float-right"
        [disabled]="!formOlog.valid"
        (click)="processData()"
      >
        Save Olog
      </button>
    </div>
  </form>
</div>
