import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Alert, OlogAlert } from './alert.action';
import { AlertService } from '../alert.service';
import { XMLService } from '../../xml/xml-service.service';

export class AlertStateModel {
}

@State<AlertStateModel>({
  name: 'Alert'
})
@Injectable()
export class AlertState {
  constructor(
    private service: AlertService,
    private xmlService: XMLService,
  ) { }

  @Selector()
  static read() {
  }

  @Action(Alert)
  alert({ }: StateContext<AlertStateModel>, { item }: Alert) {
    this.service.alert(item);
  }

  @Action(OlogAlert)
  ologAlert({ }: StateContext<AlertStateModel>, { response }: OlogAlert) {
    const message = this.xmlService.parseXml(response.description).root;

    const fields = [
      { label: 'Email to', value: message.entry.cc },
      { label: 'Subject', value: message.entry.subject },
      { label: 'Details', value: message.entry.details },
      { label: 'Categories', value: message.entry.categories.length ? message.entry.categories.join(', ') : message.entry.categories },
      { label: 'Highlight', value: message.entry.highlight },
      { label: 'Level', value: message.entry.level }
    ];

    const htmlMessage = fields
      .map(field => `<p>${field.label}: <b>${field.value}</b></p>`)
      .join('');

    this.service.banner(htmlMessage, 'Test Olog Post:', 15000);
  }

}
