<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-2">
        <h6>{{ number ?? "#" }}</h6>
      </div>
      <div class="col-9 pt-2">
        <div class="d-flex flex-row">
          <div class="pr-1">
            <mat-label
              [innerHTML]="textBefore ?? '' | placeholderHighlight : editor"
            ></mat-label>
            <b
              >&nbsp;<mat-label
                [innerHTML]="blRequiringApproval?.resource?.name"
              ></mat-label
              >&nbsp;</b
            >
            <mat-label
              [innerHTML]="textAfter ?? '' | placeholderHighlight : editor"
            ></mat-label>
          </div>
        </div>
      </div>
      <div class="col-2 d-flex flex-row justify-content-end align-items-center">
        <mat-checkbox-with-roles
          [labelPosition]="'before'"
          [checked]="checked"
          [disabled]="disabled"
          (change)="checkboxChanged($event)"
          color="warn"
          [roleCodes]="roleCodes"
          [warning]="moreInfo"
          [user]="user"
          [date]="date"
        >
        </mat-checkbox-with-roles>
      </div>
    </div>
  </div>
</div>
