import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { CanDeactivateResponse } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog-response';
import { PendingChangesDialogComponent } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog.component';
import { RadInterlock } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-interlock';
import { RadLocation } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location';
import { RadLocationInterlockRelation } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location-interlock-relation';
import { RadLocationType } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location-type';

@Component({
  selector: 'rad-monitor-add-edit-location',
  templateUrl: './add-edit-location.component.html',
  styleUrls: ['./add-edit-location.component.scss']
})
export class RadMonitorAddEditLocationComponent extends BaseComponent implements OnInit {

  public radInterlockIds: number[] = [];

  public get name(): AbstractControl | null { return this.formGroup.get('name'); }
  public get type(): AbstractControl | null { return this.formGroup.get('type'); }
  public get description(): AbstractControl | null { return this.formGroup.get('description'); }
  public get interlocks(): AbstractControl | null { return this.formGroup.get('interlocks'); }

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<RadMonitorAddEditLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: {
      radLocation: RadLocation,
      radLocationTypes: RadLocationType[],
      radInterlocks: RadInterlock[],
      dialogTitle: string
    },
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initializeForm();
    this.setInterlockIds();
  }

  setInterlockIds() {
    this.radInterlockIds = [];
    this.inputData.radLocation?.radLocationInterlockRelations?.map(x => {
      if (x.radInterlockId)
        this.radInterlockIds.push(x.radInterlockId);
    });
  }

  add() {
    if (this.validate()) {
      this.inputData.radLocation.radLocationInterlockRelations = [];
      this.radInterlockIds.map(interlockId => {
        const radLocInterlockRelation: RadLocationInterlockRelation = {
          radLocationId: this.inputData.radLocation?.id ? this.inputData.radLocation.id : 0,
          radInterlockId: interlockId
        };
        this.inputData.radLocation.radLocationInterlockRelations?.push(radLocInterlockRelation);
      });
      this.dialogRef.close(this.inputData.radLocation);
    }
  }

  cancel() {
    if (this.formGroup.dirty) {
      const confirmation = this.dialog.open(PendingChangesDialogComponent, {
        height: 'fit-content',
        width: '40%',
        data: {}
      });
      confirmation.afterClosed().toPromise().then((response: CanDeactivateResponse) => {
        if (response === CanDeactivateResponse.Discard) {
          this.dialogRef.close(null);
        }
      });
    } else {
      this.dialogRef.close(null);
    }
  }

  validate(): boolean {
    let result = true;
    if (!this.inputData.radLocation.name) {
      this.alert.error('<b> Name </b> is required!');
      this.name?.setErrors({ required: true });
      result = false;
    }
    if (!this.inputData.radLocation.typeId) {
      this.alert.error('<b> Type </b> is required!');
      this.type?.setErrors({ required: true });
      result = false;
    }
    if (!this.radInterlockIds?.length) {
      this.alert.error('<b> Interlock Chain Area </b> is required!');
      this.interlocks?.setErrors({ required: true });
      result = false;
    }
    this.formGroup.markAllAsTouched();
    return result;
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      name: [{ value: '' }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      type: [{ value: '' }, Validators.required],
      interlocks: [{ value: '' }, Validators.required],
      description: [{ value: '' }],
      rpixName: [{ value: '' }],
      summation: [{ value: '' }],
      controllerLocation: [{ value: '' }],
      controllerPower: [{ value: '' }],
      resetLocation: [{ value: '' }],
      tripsetA1: [{ value: '' }],
      tripsetI1: [{ value: '' }],
      tripset: [{ value: '' }]
    });
  }

}
