import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ToxicGasCreate, ToxicGasDelete, ToxicGasRefresh, ToxicGasUpdate } from './toxic-gas.action';
import { ToxicGas } from '../../toxic-gas';
import { ToxicGasService } from '../../toxic-gas.service';

export class ToxicGasStateModel {
  data: ToxicGas[] = [];
}

@State<ToxicGasStateModel>({
  name: 'ToxicGas',
  defaults: {
    data: []
  }
})
@Injectable()
export class ToxicGasState {
  constructor(
    private _service: ToxicGasService
  ) { }

  @Selector()
  static read(state: ToxicGasStateModel) {
    return state.data;
  }

  @Action(ToxicGasRefresh)
  refresh({ patchState }: StateContext<ToxicGasStateModel>) {
    this._service.getAll().toPromise().then(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ToxicGasCreate)
  create({ getState, patchState }: StateContext<ToxicGasStateModel>, { item }: ToxicGasCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ToxicGasUpdate)
  update({ getState, patchState }: StateContext<ToxicGasStateModel>, { id, item }: ToxicGasUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ToxicGasDelete)
  delete({ getState, patchState }: StateContext<ToxicGasStateModel>, { id }: ToxicGasDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
