<div [ngSwitch]="type" *ngIf="visible" class="full-width full-height">
  <button
    mat-flat-button
    *ngSwitchCase="componentType.Button"
    [disabled]="disabled && !builder && !editor"
    color="{{ color }}"
    (click)="click_event()"
  >
    {{ header }}
  </button>

  <button-signature
    *ngSwitchCase="componentType.ButtonSignature"
    [title]="approved ? (headerAfter ? headerAfter : header) : header"
    [number]="number"
    [roleIds]="roleIds"
    [tooltips]="tooltips"
    [editor]="editor"
    [builder]="builder"
    [roleCodes]="roleCodes"
    [enabled]="enabled"
    [disabled]="disabled"
    [value]="approved"
    [user]="approvedBy"
    [date]="approvedOn"
    [userNames]="userNames"
    [showApprove]="showApprove"
    [showUnapprove]="showUnapprove"
    [showDisapprove]="showDisapprove"
    (approve)="approve_event($event)"
    (unapprove)="unapprove_event($event)"
    (disapprove)="disapprove_event($event)"
    [message]="message"
    [date]="approvedOn"
  ></button-signature>

  <button-signature-radio
    *ngSwitchCase="componentType.ButtonSignatureRadio"
    [title]="approved ? (headerAfter ? headerAfter : header) : header"
    [number]="number"
    [roleIds]="roleIds"
    [tooltips]="tooltips"
    [editor]="editor"
    [builder]="builder"
    [roleCodes]="roleCodes"
    [enabled]="enabled"
    [radioOptions]="radioOptions"
    [disabled]="disabled"
    [value]="approved"
    [user]="approvedBy"
    [date]="approvedOn"
    [userNames]="userNames"
    [showApprove]="showApprove"
    [showUnapprove]="showUnapprove"
    [showDisapprove]="showDisapprove"
    (approve)="approve_event($event)"
    (unapprove)="unapprove_event($event)"
    (disapprove)="disapprove_event($event)"
    [message]="message"
    [date]="approvedOn"
  ></button-signature-radio>

  <checkbox-signature
    *ngSwitchCase="componentType.CheckboxSignature"
    [title]="approved ? (headerAfter ? headerAfter : header) : header"
    [number]="number"
    [builder]="builder"
    [roleIds]="roleIds"
    [editor]="editor"
    [roleCodes]="roleCodes"
    [disabled]="disabled"
    [checked]="approved"
    [value]="approved"
    [confirm]="confirm"
    [user]="approvedBy"
    [date]="approvedOn"
    (check)="check_event($event)"
    [moreInfo]="moreInfo"
    [userNames]="userNames"
  ></checkbox-signature>

  <question-signature
    *ngSwitchCase="componentType.QuestionSignature"
    [disabled]="disabled"
    [enabled]="enabled"
    [title]="approved ? (headerAfter ? headerAfter : header) : header"
    [number]="number"
    [signature]="s"
    [question]="question"
    [editor]="editor"
    [builder]="builder"
    [questionDisabled]="questionDisabled"
    [answer]="checkbox_checked"
    [user]="approvedBy"
    [date]="approvedOn"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [approved]="approved"
    [showApprove]="showApprove"
    [showUnapprove]="showUnapprove"
    (approve)="approve_event($event)"
    (unapprove)="unapprove_event($event)"
    (answered)="check_event($event)"
    [followUpChanged]="followUpChanged"
    [RolesToApproveChanges]="rolesToApproveChanges"
    [privilegeToApproveAnswer]="privilegeToApproveAnswer"
    [answerChangedById]="answerChangedById"
    [answerChangedBy]="answerChangedBy"
    (approveAnswer)="approveAnswer_event($event)"
    (disapproveAnswer)="disapproveAnswer_event($event)"
    [date]="approvedOn"
  >
  </question-signature>

  <cl-checkbox
    *ngSwitchCase="componentType.Checkbox"
    [editor]="editor"
    [number]="number"
    [disabled]="disabled"
    [enabled]="enabled"
    [color]="color"
    [title]="header"
    [question]="question"
    [checked]="checkbox_checked"
    (answered)="check_event($event)"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [date]="approvedOn"
  >
  </cl-checkbox>

  <cl-checkbox-and-radio
    *ngSwitchCase="componentType.CheckboxAndRadio"
    [editor]="editor"
    [number]="number"
    [disabled]="disabled"
    [color]="color"
    [title]="header"
    [question]="question"
    [checked]="checkbox_checked"
    [radioQuestion]="radioQuestion"
    [radioOptions]="radioOptions"
    [radioSelection]="radioSelection"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [date]="approvedOn"
    (answered)="check_event($event)"
    (radioSelected)="radio_event($event)"
  >
  </cl-checkbox-and-radio>

  <cl-note
    *ngSwitchCase="componentType.Note"
    [label]="label"
    [number]="number"
    [text]="text"
    [editor]="editor"
    [date]="approvedOn"
  ></cl-note>

  <cl-checkbox-and-text-input
    *ngSwitchCase="componentType.CheckboxAndTextInput"
    [disabled]="disabled"
    [color]="color"
    [number]="number"
    [disableEdit]="disableEdit"
    [editor]="editor"
    [title]="header"
    [textBefore]="textBefore"
    [textAfter]="textAfter"
    [textValue]="textValue"
    [checked]="checkbox_checked"
    (answered)="check_event($event)"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    (changed)="text_changed($event)"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [date]="approvedOn"
    [tooltips]="tooltips"
  ></cl-checkbox-and-text-input>

  <cl-schedule-and-checkbox
    *ngSwitchCase="componentType.ScheduleAndCheckbox"
    [disabled]="disabled"
    [title]="header"
    [number]="number"
    [editor]="editor"
    [textBefore]="textBefore"
    [textAfter]="textAfter"
    [textAfter2]="textAfter2"
    [textValue]="textValue"
    [checked]="checkbox_checked"
    (answered)="check_event($event)"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    (changed)="text_changed($event)"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [scheduleResourceId]="scheduleResourceId"
    [scheduleTypeId]="scheduleTypeId"
    [locationName]="locationName"
    [dateValue1]="dateValue1"
    [dateValue2]="dateValue2"
    [dateValue3]="dateValue3"
    [date]="approvedOn"
  >
  </cl-schedule-and-checkbox>

  <cl-checkbox-yes-no
    *ngSwitchCase="componentType.CheckboxYesNo"
    [editor]="editor"
    [disabled]="disabled"
    [number]="number"
    [color]="color"
    [number]="header"
    [title]="header"
    [question]="question"
    [checked]="checkbox_checked"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [valueString]="textValue"
    (save)="save_event($event)"
    [date]="approvedOn"
  >
  </cl-checkbox-yes-no>

  <cl-checkbox-and-comments
    *ngSwitchCase="componentType.CheckboxAndComments"
    [builder]="builder"
    [editor]="editor"
    [number]="number"
    [disabled]="disabled"
    [disableEdit]="disableEdit"
    [radioOptions]="radioOptions"
    [options]="options"
    [title]="header"
    [textBefore]="textBefore"
    [textAfter]="textAfter"
    [valueString]="textValue"
    [checked]="checkbox_checked"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [formType]="formType"
    [formId]="formId"
    [sectionNumber]="sectionNumber"
    [sectionId]="sectionId"
    [taskID]="taskId"
    [serialNo]="serialNo"
    [formType]="formType"
    (approve)="approve_event($event)"
    (unapprove)="unapprove_event($event)"
    (save)="save_event($event)"
    [date]="approvedOn"
  >
  </cl-checkbox-and-comments>

  <cl-comments
    *ngSwitchCase="componentType.Comments"
    [disabled]="disabled"
    [editor]="editor"
    [number]="number"
    [disableEdit]="disableEdit"
    [title]="header"
    [valueString]="textValue"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [formType]="formType"
    [serialNo]="serialNo"
    (save)="save_event($event)"
    [date]="approvedOn"
  >
  </cl-comments>

  <cl-subtitle
    *ngSwitchCase="componentType.Subtitle"
    [label]="label"
    [number]="number"
    [editor]="editor"
    [text]="text"
    [date]="approvedOn"
  ></cl-subtitle>

  <cl-catl
    *ngSwitchCase="componentType.Catl"
    [builder]="builder"
    [editor]="editor"
    [number]="number"
    [disabled]="disabled"
    [disableEdit]="disableEdit"
    [title]="header"
    [textBefore]="textBefore"
    [textAfter]="textAfter"
    [valueString]="textValue"
    [checked]="checkbox_checked"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [formType]="formType"
    [formId]="formId"
    [serialNo]="serialNo"
    [sectionNumber]="sectionNumber"
    [sectionId]="sectionId"
    (approve)="approve_event($event)"
    (unapprove)="unapprove_event($event)"
    (save)="save_event($event)"
    [date]="approvedOn"
  >
  </cl-catl>

  <cl-schedule-absi
    *ngSwitchCase="componentType.ScheduleAbsi"
    [disabled]="disabled"
    [editor]="editor"
    [number]="number"
    [disableEdit]="disableEdit"
    [title]="header"
    [textBefore]="textBefore"
    [textAfter]="textAfter"
    [textAfter2]="textAfter2"
    [textValue]="textValue"
    [checked]="checkbox_checked"
    (answered)="check_event($event)"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    (changed)="text_changed($event)"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [scheduleResourceId]="scheduleResourceId"
    [dateValue1]="dateValue1"
    [dateValue2]="dateValue2"
    [dateValue3]="dateValue3"
    [configuration]="configuration"
    (save)="save_event($event)"
    [date]="approvedOn"
    [checklist]="currentChecklist"
  >
  </cl-schedule-absi>

  <cl-checkbox-multiple
    *ngSwitchCase="componentType.CheckboxMultiple"
    [editor]="editor"
    [disabled]="disabled"
    [number]="number"
    [color]="color"
    [title]="header"
    [question]="question"
    [checked]="checkbox_checked"
    [user]="approvedBy"
    [roleId]="roleIds"
    [roleCodes]="roleCodes"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [valueString]="textValue"
    (save)="save_event($event)"
    [configuration]="configuration"
    [radioOptions]="radioOptions"
    [radioSelection]="radioSelection"
    (radioSelected)="radio_event($event)"
    [date]="approvedOn"
  >
  </cl-checkbox-multiple>

  <cl-checkbox-multiple-sign
    *ngSwitchCase="componentType.CheckboxMultipleSign"
    [editor]="editor"
    [disabled]="disabled"
    [number]="number"
    [color]="color"
    [title]="header"
    [question]="question"
    [checked]="checkbox_checked"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [valueString]="textValue"
    (save)="save_event($event)"
    [configuration]="configuration"
    [roleOptions]="options"
    [date]="approvedOn"
  >
  </cl-checkbox-multiple-sign>

  <cl-checkbox-multiple-with-textbox
    *ngSwitchCase="componentType.CheckboxMultipleWithTextbox"
    [editor]="editor"
    [serialNo]="serialNo"
    [formType]="formType"
    [formId]="formId"
    [disabled]="disabled"
    [number]="number"
    [color]="color"
    [title]="header"
    [question]="question"
    [checked]="checkbox_checked"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [valueString]="textValue"
    (save)="save_event($event)"
    [radioOptions]="radioOptions"
    [radioSelection]="radioSelection"
    [configuration]="configuration"
    (radioSelected)="radio_event($event)"
    [date]="approvedOn"
  >
  </cl-checkbox-multiple-with-textbox>

  <cl-multiple-textbox
    *ngSwitchCase="componentType.MultipleTextbox"
    [editor]="editor"
    [disabled]="disabled"
    [number]="number"
    [color]="color"
    [title]="header"
    [question]="question"
    [checked]="checkbox_checked"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [valueString]="textValue"
    (save)="save_event($event)"
    [options]="options"
    [radioSelection]="radioSelection"
    [configuration]="configuration"
    (radioSelected)="radio_event($event)"
    [date]="approvedOn"
  >
  </cl-multiple-textbox>

  <cl-text
    *ngSwitchCase="componentType.TextNumericHead"
    [label]="header"
    [number]="number"
    [text]="text"
    [date]="approvedOn"
  ></cl-text>

  <cl-checkbox-radio-location
    *ngSwitchCase="componentType.CheckboxRadioLocation"
    [disabled]="disabled"
    [number]="number"
    [editor]="editor"
    [color]="color"
    [title]="header"
    [question]="question"
    [checked]="checkbox_checked"
    [radioQuestion]="radioQuestion"
    [radioOptions]="options"
    [radioSelection]="radioSelection"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [configuration]="configuration"
    (answered)="check_event($event)"
    (radioSelected)="radio_event($event)"
    (sendOptions)="sendOptions_event($event)"
    (sendCondition)="sendCondition_event($event)"
    [date]="approvedOn"
  >
  </cl-checkbox-radio-location>

  <button-signature-duplicate
    *ngSwitchCase="componentType.ButtonSignatureDuplicate"
    [title]="
      approved
        ? headerAfter != '' && headerAfter != null
          ? headerAfter
          : header
        : header
    "
    [code]="code"
    [number]="number"
    [roleId]="roleIds"
    [editor]="editor"
    [roleCodes]="roleCodes"
    [disabled]="disabled"
    [enabled]="enabled"
    [value]="approved"
    [user]="approvedBy"
    [date]="approvedOn"
    [userNames]="userNames"
    [showApprove]="showApprove"
    [showUnapprove]="showUnapprove"
    [showDisapprove]="showDisapprove"
    [taskID]="taskId"
    (approve)="approve_event($event)"
    (unapprove)="unapprove_event($event)"
    (disapprove)="disapprove_event($event)"
    (duplicate)="duplicate_event($event)"
    (loading)="setLoading($event)"
  >
  </button-signature-duplicate>

  <cl-image-upload
    *ngSwitchCase="componentType.RADSurveyImageTable"
    [id]="id"
    [builder]="builder"
    [number]="number"
    [editor]="editor"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [enableColumnsBuilder]="enableColumns ?? false"
    [disabled]="disabled"
    [configuration]="configuration"
    (changed)="text_changed($event)"
    (save)="save_event($event)"
    (setLoading)="setLoading($event)"
    [date]="approvedOn"
    [saving]="saving ?? false"
    [checklistTemplate]="currentChecklistTemplate"
  >
  </cl-image-upload>

  <cl-close-action
    *ngSwitchCase="componentType.CloseButton"
    [currentChecklist]="currentChecklist"
    [valueString]="textValue"
    [editor]="editor"
    [number]="number"
    [builder]="builder"
    [disabled]="disabled"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [title]="header"
    [question]="question"
    [value]="approved"
    [user]="approvedBy"
    [date]="approvedOn"
    [taskID]="taskId"
    (loading)="setLoading($event)"
    (closed)="close_event($event)"
    (changed)="text_changed($event)"
    [date]="approvedOn"
  >
  </cl-close-action>

  <cl-bratoi
    *ngSwitchCase="componentType.BRATOI"
    [editor]="editor"
    [builder]="builder"
    [number]="number"
    [checkboxVisible]="checkboxVisible"
    [checked]="checkbox_checked"
    [configuration]="configuration"
    [disabled]="disabled"
    [roleCodes]="roleCodes"
    [moreInfo]="moreInfo"
    [textAfter]="textAfter"
    [textBefore]="textBefore"
    [title]="header"
    [user]="approvedBy"
    [warning]="warning"
    (answered)="check_event($event)"
    [date]="approvedOn"
  >
  </cl-bratoi>

  <app-cl-olog
    *ngSwitchCase="componentType.Olog"
    [disabled]="disabled"
    [valueString]="textValue"
    [number]="number"
    [btnColor]="color"
    [roleCodes]="roleCodes"
    [btnName]="header"
    [checked]="checkbox_checked"
    [checklist]="currentChecklist"
    (loading)="setLoading($event)"
    (changed)="text_changed($event)"
    [date]="approvedOn"
  >
  </app-cl-olog>

  <app-cl-rad-monitor
    *ngSwitchCase="componentType.RadMonitorIncoming"
    [editor]="editor"
    [number]="number"
    [configuration]="configuration"
    [textAfter]="textAfter"
    [title]="header"
    [textBefore]="textBefore"
    [checkboxVisible]="checkboxVisible"
    [user]="approvedBy"
    [disabled]="disabled"
    [checked]="checkbox_checked"
    [isBuilder]="builder"
    [roleCodes]="roleCodes"
    [valueString]="textValue"
    [isApproved]="booleanValue"
    (save)="save_event($event)"
    (answered)="check_event($event)"
    [date]="approvedOn"
  >
  </app-cl-rad-monitor>

  <app-cl-rad-location
    *ngSwitchCase="componentType.RadMonitorOutgoing"
    [editor]="editor"
    [configuration]="configuration"
    [number]="number"
    [currentChecklist]="currentChecklist"
    [textAfter]="textAfter"
    [title]="header"
    [textBefore]="textBefore"
    [checkboxVisible]="checkboxVisible"
    [user]="approvedBy"
    [roleCodes]="roleCodes"
    [disabled]="disabled"
    [checked]="checkbox_checked"
    [valueString]="textValue"
    [isApproved]="booleanValue"
    (save)="save_event($event)"
    (answered)="check_event($event)"
    [date]="approvedOn"
    [checklist]="currentChecklist"
  >
  </app-cl-rad-location>

  <cl-status-change
    *ngSwitchCase="componentType.StatusChange"
    [editor]="editor"
    [number]="number"
    [builder]="builder"
    [disabled]="disabled"
    [color]="color"
    [title]="header"
    [roleCodes]="roleCodes"
    [question]="question"
    [checklist]="currentChecklist"
    [currentChecklistTemplate]="currentChecklistTemplate"
    [disabled]="disabled && !builder && !editor"
    [configuration]="configuration"
    (loading)="setLoading($event)"
    [date]="approvedOn"
  ></cl-status-change>

  <cl-rad-meters
    *ngSwitchCase="componentType.RadMeters"
    [id]="id"
    [number]="number"
    [checklist]="currentChecklist"
    [builder]="builder"
    [editor]="editor"
    [disabled]="disabled"
    [color]="color"
    [textAfter]="textAfter"
    [textBefore]="textBefore"
    [title]="header"
    [question]="question"
    [roleIds]="roleIds"
    [configuration]="configuration"
    [valueString]="textValue"
    (changed)="text_changed($event)"
    (save)="save_event($event)"
    [date]="approvedOn"
  >
  </cl-rad-meters>

  <cl-radio-placeholder-cond
    *ngSwitchCase="componentType.PlaceholderCondition"
    [number]="number"
    [editor]="editor"
    [disabled]="disabled"
    [color]="color"
    [title]="header"
    [question]="question"
    [checked]="checkbox_checked"
    (answered)="check_event($event)"
    [radioQuestion]="radioQuestion"
    [radioOptions]="options"
    [radioSelection]="radioSelection"
    (radioSelected)="radio_event($event)"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [configuration]="configuration"
    [checklist]="currentChecklist"
    [date]="approvedOn"
  >
  </cl-radio-placeholder-cond>

  <cl-shieldings
    *ngSwitchCase="componentType.ShieldingsComponent"
    [editor]="editor"
    [signature]="s"
    [builder]="builder"
    [disabled]="disabled"
    [number]="number"
    [color]="color"
    [title]="header"
    [question]="question"
    [radioQuestion]="radioQuestion"
    [radioOptions]="options"
    [radioSelection]="radioSelection"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [taskID]="taskId"
    [roleCodes]="roleCodes"
    [checkboxVisible]="checkboxVisible"
    [valueString]="textValue"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [configuration]="configuration"
    [checklist]="currentChecklist"
    [checklistTemplate]="currentChecklistTemplate"
    [options]="options"
    (checkShared)="check_shared_event($event)"
    (save)="save_event($event)"
    [date]="approvedOn"
  >
  </cl-shieldings>

  <cl-restrictions
    *ngSwitchCase="componentType.Restrictions"
    [builder]="builder"
    [editor]="editor"
    [configuration]="configuration"
    [user]="approvedBy"
    [checklist]="currentChecklist"
    [disabled]="disabled"
    [disableEdit]="disableEdit"
    [radioOptions]="options"
    [title]="header"
    [number]="number"
    [textBefore]="textBefore"
    [textAfter]="textAfter"
    [valueString]="textValue"
    [checked]="checkbox_checked"
    [user]="approvedBy"
    [roleId]="roleIds"
    [roleCodes]="roleCodes"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [formType]="formType"
    [formId]="formId"
    [sectionNumber]="sectionNumber"
    [sectionId]="sectionId"
    [taskID]="taskId"
    [serialNo]="serialNo"
    [formType]="formType"
    (save)="save_event($event)"
    [date]="approvedOn"
  ></cl-restrictions>

  <cl-location
    *ngSwitchCase="componentType.Locations"
    [builder]="builder"
    [editor]="editor"
    [number]="number"
    [configuration]="configuration"
    [user]="approvedBy"
    [checklist]="currentChecklist"
    [disabled]="disabled"
    [disableEdit]="disableEdit"
    [radioOptions]="options"
    [title]="header"
    [textBefore]="textBefore"
    [textAfter]="textAfter"
    [valueString]="textValue"
    [checked]="checkbox_checked"
    [user]="approvedBy"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [checkboxVisible]="checkboxVisible"
    [warning]="warning"
    [moreInfo]="moreInfo"
    [formType]="formType"
    [formId]="formId"
    [sectionNumber]="sectionNumber"
    [sectionId]="sectionId"
    [serialNo]="serialNo"
    [formType]="formType"
    (answered)="check_event($event)"
    (save)="save_event($event)"
    [date]="approvedOn"
    [taskID]="taskId"
  ></cl-location>

  <op-approval-w-checklists
    *ngSwitchCase="componentType.OperationalApproval"
    [disabled]="disabled"
    [enabled]="enabled"
    [title]="
      approved
        ? headerAfter != '' && headerAfter != null
          ? headerAfter
          : header
        : header
    "
    [signature]="s"
    [formId]="formId"
    [formType]="formType"
    [status]="status ?? 0"
    [question]="question"
    [editor]="editor"
    [builder]="builder"
    [questionDisable]="questionDisabled"
    [answer]="checkbox_checked"
    [user]="approvedBy"
    [date]="approvedOn"
    [roleIds]="roleIds"
    [roleCodes]="roleCodes"
    [approved]="approved"
    [showApprove]="showApprove"
    [showUnapprove]="showUnapprove"
    (approve)="approve_event($event)"
    (unapprove)="unapprove_event($event)"
    (answered)="check_event($event)"
    [followUpChanged]="followUpChanged"
    [rolesToApproveChanges]="rolesToApproveChanges"
    [privilegeToApproveAnswer]="privilegeToApproveAnswer"
    [answerChangedById]="answerChangedById"
    [answerChangedBy]="answerChangedBy"
    (approveAnswer)="approveAnswer_event($event)"
    (disapproveAnswer)="disapproveAnswer_event($event)"
    [date]="approvedOn"
    [configuration]="configuration"
    [valueString]="textValue"
    (changed)="text_changed($event)"
    [taskID]="taskId"
    (loading)="setLoading($event)"
  ></op-approval-w-checklists>

  <cl-rad-monitor-due-date
    *ngSwitchCase="componentType.RadMonitorDueDate"
    [id]="id"
    [number]="number"
    [checklist]="currentChecklist"
    [builder]="builder"
    [editor]="editor"
    [disabled]="disabled"
    [color]="color"
    [textAfter]="textAfter"
    [textBefore]="textBefore"
    [title]="header"
    [question]="question"
    [roleIds]="roleIds"
    [configuration]="configuration"
    [valueString]="textValue"
    (changed)="text_changed($event)"
    (save)="save_event($event)"
    [date]="approvedOn"
  >
  </cl-rad-monitor-due-date>

  <div *ngSwitchDefault class="no-component">
    <div>{{ header }}</div>
    <div class="small">No Component type defined!</div>
  </div>
</div>
