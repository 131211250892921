import { ActionItem } from "src/app/common/models/action-item";
import { FormType } from '../../../../common/enumerations/enumerations';

export class ActionItemsRefresh {
  static readonly type = '[ActionItems] Refresh';
  constructor(public AbsiId: number) { }
}

export class ActionItemsCreate {
  static readonly type = '[ActionItems] Create';

  constructor(public item: ActionItem) { }
}

export class ActionItemsUpdate {
  static readonly type = '[ActionItems] Update';

  constructor(public id: number, public item: ActionItem) { }
}

export class ActionItemsUpdateBySection {
  static readonly type = '[ActionItems] UpdateBySection';

  constructor(public sectionID: number, public items: ActionItem[]) { }
}

export class ActionItemsUpdateByForm {
  static readonly type = '[ActionItems] UpdateByForm';

  constructor(public type: FormType, public formID: number, public items: ActionItem[]) { }
}

export class ActionItemsDelete {
  static readonly type = '[ActionItems] Delete';
  constructor(public id: number) { }
}
