export enum NotificationType {
  CreateSCF = 1,
  CreateRSSWA = 101,
  Procedure = 200,
  ChecklistStatusChange = 300
}

export enum Roles {
  SA = 1,
  ADM = 2,
  HP = 4,
  ATS = 5,
  VAC = 7,
  BLSCI = 8,
  EMT = 11,
  FO = 12,
  MT = 13,
  OIC = 14,
  SnA = 21,
  AO = 24,
  OT = 25,
  ALTBLSCI = 47,
  KEE = 1054,
  WFE = 57,
  BRC = 1047,
  RFEng = 1048,
  RP = 1051,
  PC = 1052,
  ARC = 1062,
  RWAPI = 20,
  EMSup = 1066,
  PPSTBTA = 1068,
  RWPADes = 1110
}

export enum FormType {
  SCF = 1,
  KE = 2,
  RSSWA = 3,
  PPSTB = 4,
  KET = 8,
  EBF = 9,
  ABSIT = 10,
  ABSI = 11,
  RADSURVEYPLAN = 12,
  RCP = 200,
  REVIEW = 201,
  REVIEW_REQUEST = 999
}

export enum FormStatusEnum {
  Temporal = -2,
  Canceled = -1,
  New = 0,
  Draft = 1,
  WorkApprovals = 2,
  Active = 3,
  WorkCompleted = 4,
  Closed = 5,
  Amendment = 6,
  RestrictionsAmendment = 7,
  Pending = 8,
  AmendmentSign = 9,
  Initiated = 10,
  Vacuum = 11,
  Shielding = 12,
  KeyEnable = 13,
  Handshake = 14,
  ActiveBypassInstalled = 15,
  ActiveOperationsVerified = 16,
  ActiveBypassRemoved = 17,
  ActiveMitigationsInPlace = 18,
  AwaitingInspection = 19,
  PendingReview = 30,
  Reviewing = 31,
  Correcting = 32,
  ApprovalsPending = 33,
  Approvals = 34,
  Preparation = 39,
  ReviewAndConcurrence = 41,
  HoldPoint = 50,
  PendingCancelation = 51
}

export enum ResourceType {
  All = 0,
  Accelerator = 1,
  Beamline = 2,
  Branchline = 3,
  Shielding = 4,
  Vacuum = 6,
  Shutter = 7,
  RSSSystems = 10,
  TOMBISSystems = 11,
  Endstation = 13,
  FastValveSensor = 14,
  AcceleratorLocation = 15,
  ReviewProcessResource = 20,
  RadMonitor = 90,

  // To use only on Beamline Catalog
  Note = 1001,
  Organization = 1002,
  TOCA = 1003
}

export enum AffectedSystemType {
  BLSystem = 1,
  AccSystem = 2,
  TOMBISSystem = 3
}

export enum Action {
  Approve = 1,
  Disapprove = 2,
  Unapprove = 3,
  Amendment = 4,
  Cancel = 5
}

export enum ActionPendingCatalog {
  None = 0,
  Create = 1,
  Update = 2,
  Delete = 3,
  Review = 4,
  Correction = 5,
  Approve = 6
}

export enum StatusPendingCatalog {
  Pending = 1,
  Approved = 2,
  Disapproved = 3
}

export enum Catalog {
  None = 0,
  BeamlineCatalog = 1,
  Users = 2,
  Roles = 3,
  AcceleratorCatalog = 4,
  EmailNotificationCatalog = 5,
  RolePrivileges = 6,
  Trainings = 7,
  KET = 10,
  ABSI = 11,
  ChecklistTemplate = 12
}

export enum WFSaveOptions {
  AUTO = 1,
  MANUAL = 2
}

export enum PendingCatalogColor {
  Modify = 1,
  Create = 2,
  Inactive = 3,
  ModifyRSS = 4
}

export enum RSSTestType {
  Hutchless = 1,
  HutchHutchFunctional = 2,
  MiniHutch = 3,
  VacuumSwitch = 4
}

export enum RadNoteType {
  Normal = 1,
  Fault = 2
}

export enum PendingResourcesType {
  Default = 0,
  Details = 1,
  Rss_Database = 2,
  Both = 3
}

export enum CustomImageSizeOptions {
  Fit = 1,
  Small = 2,
  Original = 3
}

export enum ReviewCycleStatus {
  Rejected = -1,
  Pending = 0,
  Approved = 1,
  Archived = 9
}

export enum StatusType {
  New = 0,
  Active = 1,
  Closed = 2,
  Expired = 3,
  Canceled = 4,
  Pending = 5,
}

export enum ComponentType {
  Button = -1,
  ButtonSignature = 1,
  CheckboxSignature = 2,
  QuestionSignature = 3,
  Checkbox = 4,
  CheckboxAndRadio = 6,
  Note = 7,
  CheckboxAndTextInput = 8,
  ScheduleAndCheckbox = 9,
  CheckboxYesNo = 10,
  CheckboxAndComments = 11,
  Comments = 12,
  Subtitle = 13,
  Catl = 14,
  ScheduleAbsi = 15,
  CheckboxMultiple = 16,
  CheckboxMultipleWithTextbox = 17,
  MultipleTextbox = 18,
  TextNumericHead = 19,
  CheckboxRadioLocation = 20,
  ButtonSignatureDuplicate = 21,
  RADSurveyImageTable = 22,
  CloseButton = 23,
  BRATOI = 24,
  Olog = 25,
  RadMonitorIncoming = 26,
  RadMonitorOutgoing = 27,
  StatusChange = 28,
  RadMeters = 29,
  CheckboxMultipleSign = 30,
  PlaceholderCondition = 31,
  ShieldingsComponent = 32,
  Restrictions = 33,
  Locations = 34,
  ButtonSignatureRadio = 35,
  OperationalApproval = 36,
  RadMonitorDueDate = 37
}

export enum WorkRequired {
  None = 0,
  AccRFShielding = 1,
  BlShielding = 2,
  PermanentAccRemoval = 3,
  PermanentBLRemoval = 4,
  TocaMovement = 5
}

export enum OperationalApprovalCode {
  BLREVREQ = 'BLREVREQ',
  ACREVREQ = 'ACREVREQ',
  RADSVREQ = 'RADSVREQ',
  DOCUPREQ = 'DOCUPREQ'
}

export enum SignatureAction {
  Approve = 1,
  Disapprove = 2,
  Unnaprove = 3
}

export enum ReturnType {
  None = 0,
  Numeric = 1,
  Boolean = 2,
  Text = 3,
  Sign = 4,
}

export enum CompareOperator {
  '=' = 0,
  '>' = 1,
  '>=' = 2,
  '<' = 3,
  '<=' = 4,
  '<>' = 5
}

export enum GeneralCondition {
  AllTasksCompleted = 'ALL-TASKS-COMPLETE',
  ChecklistComplete = 'CHECKLIST-COMPLETE'

}

export enum LoadingOrigin {
  Side = 1, Buttons = 2, Main = 3, Self = 4
}


export enum MenuItemType {
  Divider = -1,
  Route = 1,
  Submenu = 2,
  Table = 3
}

export enum OlogNotificationType {
  Activate = 1,
  Amended = 2,
  Closed = 3,
  Handshake = 4
}


export enum Fields {
  TEXT = 'text',
  BOOLEAN = "booleanValue",
  DOUBLE = "doubleValue",
  NUMERIC = "numericValue",
  VALUE = "value",
}

export enum icons {
  warning = '⚠️',
  info = 'ℹ️',
  success = '✔️',
  pendingActions = '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#006ba6"><path d="M687.69-100q-74.92 0-127.46-52.54-52.54-52.54-52.54-127.46 0-74.92 52.54-127.46Q612.77-460 687.69-460q74.93 0 127.46 52.54 52.54 52.54 52.54 127.46 0 74.92-52.54 127.46Q762.62-100 687.69-100Zm66.62-88.46 24.92-24.92-73.84-73.85v-110.46H670v124.92l84.31 84.31Zm-542 48.46q-29.92 0-51.12-21.19Q140-182.39 140-212.31v-535.38q0-29.92 21.19-51.12Q182.39-820 212.31-820h176.23q8.31-29.23 33.96-48.46t57.5-19.23q33.08 0 58.42 19.23 25.35 19.23 33.66 48.46h175.61q29.92 0 51.12 21.19Q820-777.61 820-747.69v245.38q-14.16-9.15-28.77-15.65-14.62-6.5-31.23-11.58v-218.15q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H660v104.61H300V-760h-87.69q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v535.38q0 5.39 3.46 8.85t8.85 3.46h228.54q5.08 16.61 11.77 31.42 6.69 14.81 16.61 28.58H212.31ZM480-755.38q15.46 0 25.81-10.35 10.34-10.35 10.34-25.81 0-15.46-10.34-25.81-10.35-10.34-25.81-10.34-15.46 0-25.81 10.34-10.34 10.35-10.34 25.81 0 15.46 10.34 25.81 10.35 10.35 25.81 10.35Z"/></svg>',
  autoDelete = '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e04e39"><path d="M292.31-140q-29.83 0-51.07-21.24Q220-182.48 220-212.31V-720h-11.54q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37 8.62-8.62 21.37-8.62H360q0-14.69 10.35-25.04 10.34-10.34 25.03-10.34h169.24q14.69 0 25.03 10.34Q600-794.69 600-780h151.54q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37-8.62 8.62-21.37 8.62H740v131.77q0 14.69-11.58 24.54-11.58 9.84-26.88 8-4.93-.62-10.46-.93-5.54-.3-11.08-.3-23.46 0-46.92 3.84-23.47 3.85-46.16 12.54V-610q0-12.75-8.63-21.38-8.63-8.62-21.38-8.62-12.76 0-21.37 8.62-8.62 8.63-8.62 21.38v99.23q-30.54 20.46-54.5 48.12-23.96 27.65-39.34 60.96V-610q0-12.75-8.63-21.38-8.63-8.62-21.39-8.62-12.75 0-21.37 8.62-8.61 8.63-8.61 21.38v300q0 12.75 8.62 21.38 8.63 8.62 21.38 8.62 0 24.98 4.96 49.53T421.69-183q6.46 15.46-2.46 29.23T394.85-140H292.31Zm387.6 56.92q-81.76 0-139.29-57.62-57.54-57.63-57.54-139.39 0-81.76 57.62-139.29 57.63-57.54 139.39-57.54 81.76 0 139.29 57.62 57.54 57.63 57.54 139.39 0 81.76-57.62 139.29-57.63 57.54-139.39 57.54Zm17.78-203.99V-380q0-7.08-5.31-12.38-5.3-5.31-12.38-5.31-7.08 0-12.38 5.31-5.31 5.3-5.31 12.38v92.15q0 7.07 2.61 13.69 2.62 6.62 8.23 12.24l60.39 60.38q5.23 5.23 12.27 5.42 7.04.2 12.65-5.42 5.62-5.61 5.62-12.46 0-6.85-5.62-12.46l-60.77-60.61Z"/></svg>'
};
