<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-2">
        <h6>{{ number ?? "#" }}</h6>
      </div>
      <div class="col-9 pt-2">
        <mat-label
          [innerHTML]="question ?? '' | placeholderHighlight : editor"
        ></mat-label>
      </div>
      <div class="col-2 d-flex flex-row justify-content-end align-items-center">
        <mat-checkbox-with-roles
          *ngIf="checkboxVisible"
          [labelPosition]="'before'"
          [checked]="checked"
          [disabled]="disabled ?? true"
          (change)="checkboxChanged($event)"
          [color]="color"
          [roleCodes]="roleCodes"
          [user]="user"
          [warning]="moreInfo ?? ''"
          [date]="date"
        >
        </mat-checkbox-with-roles>
      </div>
    </div>
  </div>
</div>
