import { Component, EventEmitter, Injector, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { utils } from 'src/app/modules/libs/utils';
import { Form } from 'src/app/services/forms/form';
import { WfSignatureResource, WfSignature, WFConfiguration } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { EbService } from '../../eb.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ComponentType } from 'src/app/common/enumerations/enumerations';

@Component({
  selector: 'eb-main-s5',
  templateUrl: './eb-main-s5.component.html',
  styleUrls: ['./eb-main-s5.component.scss']
})
export class EbMainS5Component extends BaseComponent implements OnChanges {

  @Input() form?: Form | null;
  @Input() disabled!: boolean;
  @Input() showAmendment!: boolean;

  @Output() loading = new EventEmitter<boolean>()

  wfSignatures?: WfSignatureResource[];
  signatures!: WfSignature[];

  configuration?: WFConfiguration[];

  visible?: boolean;

  constructor(
    protected override injector: Injector,
    public service: EbService,
    private workflowService: WorkFlowService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  async refresh() {
    if (this.form) {
      this.configuration = await this.workflowService.readConfiguration(9, this.form.wfTableID ?? 0).toPromise();

      const leftPersons = [
        this.configuration?.filter(x => x.identificator == 111) ?? []];

      const rightPersons = [
        this.configuration?.filter(x => x.identificator == 112) ?? []];

      this.wfSignatures = this.form?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 5)?.wfSignatures;

      this.signatures = [];
      this.wfSignatures?.map(signature => {
        this.signatures.push(
          WfSignatureResource.toWFSignatureResourceWithPerson(signature, this.wfSignatures ?? [], leftPersons, rightPersons, this.getCurrentUser()?.id ?? -1));
      });
      this.visible = this.signatures.some(s => s.enabled);
    }
  }

  async approve(s: WfSignature) {
    const signed = await this.service.confirmSign(this.form, s, s.approveId, '', undefined, this.configuration);
    if (!signed) this.refresh();
  }

}
