import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResourceType } from 'src/app/common/enumerations/enumerations';
import { RadMonitor } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor';
import { RadMonitorService } from 'src/app/components/catalogs/rad-monitor/services/rad-monitor.service';
import { Resource, ResourceSummary } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';
import { RadLocation } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location';
import { Placeholder } from 'src/app/components/checklists/checklists';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class PlaceholdersService {
  BASE_URL = environment.urls.baseApiUrl; // Api URL
  Api = '/Placeholders';

  public isBuilder?: boolean;

  keyValuePairs: any[] = [];
  keyValuePairsObservable = new BehaviorSubject<any[]>(this.keyValuePairs);

  request?: boolean;

  placeholders?: Placeholder[];
  placeholdersFiltered?: Placeholder[];
  placeholders$!: Observable<Placeholder[]>;
  placeholdersSubs!: Subscription;

  rmco!: RadMonitor;
  rmci!: RadMonitor;

  constructor(
    private http: HttpClient,
    public resourceService: ResourcesService,
    private store: Store
  ) {
    this.loadPlaceholders();
  }

  getAll(): Observable<Placeholder[]> {
    const httpresult = this.http.get<Placeholder[]>(this.BASE_URL + this.Api);
    httpresult.toPromise().then(data => this.placeholders = data);
    return httpresult;
  }

  get(id: number): Observable<Placeholder> {
    const httpresult = this.http.get<Placeholder>(this.BASE_URL + this.Api + '/' + id);
    return httpresult;
  }

  create(placeholder: Placeholder): Observable<Placeholder> {
    return this.http.put<Placeholder>(this.BASE_URL + this.Api, placeholder);
  }

  update(placeholder: Placeholder): Observable<Placeholder> {
    return this.http.put<Placeholder>(this.BASE_URL + this.Api, placeholder);
  }

  delete(placeholder: Placeholder, text: string): Observable<Placeholder> {
    return this.http.delete<any>(this.BASE_URL + this.Api + '/' + placeholder.id + '/' + text);
  }

  getValue(placeholder: string) {
    if (this.keyValuePairs) {
      const object = this.placeholders?.find(x => x.placeholderString.toLowerCase() == placeholder?.toLowerCase())?.object;
      if (object) {
        const obj = object?.split('|');
        let value = this.keyValuePairs.find(x => x.key.toLowerCase() == obj[0]?.toLowerCase())?.value;
        value = (obj && obj[1]) ? (value ? value[(obj[1])] : 'No value Set!') : value;
        if (typeof value === 'object') {
          if (Array.isArray(value)) {
            value = value.map(x => x.Shutter.name).join(', ');
          }
        }
        if (!this.isBuilder && !value) {
          return `Value for ${placeholder} not found!`;
        } else {
          return value;
        }
      }
      else {
        return 'No Value Set!';
      }
    }
    else {
      return 'Wrong Placeholder Found: <b class="red">' + placeholder + '</b>';
    }
  }

  loadPlaceholders() {
    this.placeholders$ = this.store.select(state => state.Placeholders.data);
    this.placeholdersSubs = this.placeholders$.subscribe(data => {
      if (data?.length) {
        this.placeholders = data;
      }
    });
  }

  async setKeyValuePairs(resource?: Resource | RadMonitor | null, isBuilder?: boolean) {
    return new Promise(async (resolve, reject) => {
      if (!this.placeholders?.length)
        this.loadPlaceholders();

      this.isBuilder = isBuilder;
      if (!this.request) {
        this.request = true;
        if (resource?.id && resource?.type == ResourceType.Shutter) {
          const resourceSummary = await this.resourceService.getResourceSummaryByShutter(resource.id).toPromise();
          if (resourceSummary)
            this.getKeyValues(resourceSummary);
          this.request = false;
          resolve(true);
        }
        else if (resource?.id && resource?.type == ResourceType.Beamline) {
          const resourceSummary = await this.resourceService.getResourceSummaryByBeamline(resource.id).toPromise();
          if (resourceSummary)
            this.getKeyValues(resourceSummary);
          this.request = false;
          resolve(true);
        }
        else {
          this.request = false;
          const radMonitor = resource as RadMonitor;
          if (radMonitor)
            this.getKeyValuesRadMonitor(radMonitor, 'rmco');
          if (radMonitor?.radLocation)
            this.getKeyValuesRadMonitor(radMonitor.radLocation as RadLocation, 'radLocation');
          resolve(true);
        }
      }
      else resolve(true);
    });
  }

  getKeyValues(rs: ResourceSummary) {
    const keyValues: any[] = [];
    if (rs.shutter != undefined)
      keyValues.push.apply(keyValues, Object.entries(rs.shutter).map(([key, value]) => ({ key: 'shutter.' + key, value })));
    if (rs.beamline)
      keyValues.push.apply(keyValues, Object.entries(rs.beamline).map(([key, value]) => ({ key: 'beamline.' + key, value })));
    this.keyValuePairs = keyValues;
    this.keyValuePairsObservable.next(this.keyValuePairs);
  }

  getKeyValuesRadMonitor(radItem: RadMonitor | RadLocation, type: string) {
    const keyValues: any[] = [];
    if (radItem) {
      keyValues.push.apply(keyValues, Object.entries(radItem).map(([key, value]) => ({ key: type + '.' + key, value })));
    }
    this.keyValuePairs = keyValues;
    this.keyValuePairsObservable.next(this.keyValuePairs);
  }

  clearkeyValues() {
    this.keyValuePairs = [];
    this.keyValuePairsObservable.next(this.keyValuePairs);
  }
}
