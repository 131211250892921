import { AfterViewInit, Component, ElementRef, HostListener, Injector, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { BaseComponent } from 'src/app/common/base/base.component';
import { AbsiTemplateResource, AbsiTemplateTypeResource, AbsiTemplateVersionResource } from '../absi';
import { AbsiBuilderService } from './absi-builder.service';
import { AbsiBuilderSideComponent } from './absi-builder-side/absi-builder-side.component';
import { Observable, Subscription } from 'rxjs';
import { AbsiBuilderMainComponent } from './absi-builder-main/absi-builder-main.component';
import { StepError } from '../../checklists/checklist-builder/checklist-builder.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-absi-builder',
  templateUrl: './absi-builder.component.html',
  styleUrls: ['./absi-builder.component.scss']
})
export class AbsiBuilderComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild("sidenav") sidenav!: MatSidenav;
  @ViewChild("headerTop") headerTop!: ElementRef;
  @ViewChild(AbsiBuilderSideComponent) side!: AbsiBuilderSideComponent;
  @ViewChild(AbsiBuilderMainComponent) main!: AbsiBuilderMainComponent;


  disabled: boolean = false;

  loading = false;

  resourceRequired: boolean = false;

  filterCtrl = new FormControl();

  headerOnTop: boolean = false;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter: any[] = [];
  currentFilter: any;
  filter: any;

  loadingMessage!: string;

  version?: AbsiTemplateVersionResource | null;
  versions?: AbsiTemplateVersionResource[];
  versions$!: Observable<AbsiTemplateVersionResource[]>;
  versionsSubs!: Subscription;

  globalTemplate?: string;

  type?: AbsiTemplateTypeResource;

  //////

  errorMessages?: StepError[];

  constructor(
    protected override injector: Injector,
    private _builder: AbsiBuilderService
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.setHeights();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.moduleTitle = 'ABSI Template Builder';
  }

  loadVersions() {
    this.versions$ = this.store.select(state => state.AbsiTemplateVersions.data);
    this.versionsSubs = this.versions$.subscribe(data => {
      if (data?.length) {
        this.versions = data;
        if (this.version)
          this.version = this.versions.find(x => x.id == this.version?.id);
      }
    });
  }

  selectedRow(e: AbsiTemplateVersionResource) {
    this.setLoading(true, this.loadingOrigin.Self);
    this.version = e;
    this.globalTemplate = this.version?.wfTableConfig?.name;
    this.setHeights();
    this.setLoading(false, this.loadingOrigin.Self);
  }

  selectedType(type: AbsiTemplateTypeResource) {
    this.type = type;
    this.version = null;
    this.loadVersions();
    this.setHeights()
  }

  clear() {
  }

  create() {
    if (this.type) {
      this.version = null;
      let absiTemplate: AbsiTemplateResource = {
        name: 'ABSI',
        requesterId: this.getCurrentUser()?.id ?? -1,
        status: 0,
        type: this.type.id ?? 0
      };
      this.setLoading(true, this.loadingOrigin.Self);
      this._builder.CreateChecklistTemplate(absiTemplate).subscribe(data => {
        this.alert.success(data.name + ' was Created!');
        this.side.setStatus(data);
        this.version = data;
        this.side.version = data;
        this.setLoading(false, this.loadingOrigin.Self);

      },
        error => {
          console.log(error);
          this.alert.error(error.error);
        });
    }
  }

  duplicate() {
    let absiTemplate: AbsiTemplateResource = {
      name: 'ABSI - Copy',
      requesterId: this.getCurrentUser()?.id ?? -1,
      status: 0,
      type: this.type?.id ?? 0,
      wfTableID: this.version?.wfTableID
    };

    this._builder.CreateChecklistTemplate(absiTemplate).subscribe(data => {
      this.alert.success(this.version?.name + ' was Duplicated to ' + data.name + '!');
      this.version = data;
    });
  }

  delete() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "500px", data: {
        message: 'Are you sure you want to Delete this ABSI Template?',
        icon: "stop",
      },
    });
    confirm.afterClosed().subscribe(async (data) => {
      if (data) {
        if (this.version?.id) {
          this.setLoading(true, this.loadingOrigin.Self);
          const name = this.version.name;
          this._builder.DeleteVersion(this.version.id).subscribe(data => {
            this.alert.warning(name + ' was Deleted!');
            this.version = null;
            this.setLoading(false, this.loadingOrigin.Self);

          });
        }
      }
    });
  }

  approve() {
    let version = this.version;
    if (version) {
      this.setLoading(true, this.loadingOrigin.Self)
      this.loading = true;
      this._builder.ApproveVersion(version).subscribe(async data => {
        this.alert.success(version?.name + ' was Approved!!');
        this.version = data;
        this.setLoading(false, this.loadingOrigin.Self);
      });
    }
  }


  @HostListener("window:resize")
  onResize() {
    this.innerWidth = window.innerWidth;
    // if (this.isExpanded != false) {

    // }
    if (this.innerWidth < 768) {
      this.isExpanded = false;
    }
  }

  @HostListener("window:scroll")
  isHeaderOnTop() {
    let elemRec;
    if (this.headerTop?.nativeElement !== undefined) {
      elemRec = this.headerTop.nativeElement.getBoundingClientRect();
      const docViewTop = window.screenTop;
      const elemTop = elemRec.top;
      if (elemTop <= docViewTop) {
        this.headerOnTop = true;
      } else {
        this.headerOnTop = false;
      }
      // this.tableWidth = this.scheduleTable.nativeElement.offsetWidth + 'px';
    } else {
      this.headerOnTop = false;
    }
  }

  //////
  changedFilter() { }
  clearFilter() { }
  selectedFilter() { }
  createDisabled() { }
  editDisabled() { }
  action() { }

  hasErrors() {
    this.errorMessages = [];
    this.main?.sectionComponents.map((s) => {
      const errors = s.signatures?.map(t => {
        if (t.invalid) {
          const errorMessage =
            t.errors +
            " in Section: " +
            s.section.number +
            " " +
            s.section.name +
            ", Task: " +
            t.name;
          this.errorMessages?.push({ stepId: t.sectionNumber ?? 0, taskId: t.id, text: errorMessage });
        }
      });
    });

    return this.errorMessages.length > 0;
  }

  applyFilter(e: any) {
    if (!e)
      this.valueFilter = '';
    this.filter = e;
  }

  buttonsVisible: boolean | null = false;


  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 2000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Buttons:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Main:
          this.loadingMain = e as boolean;
          break;
      }
      console.log('self: ' + this.loadingSelf + '   main: ' + this.loadingMain + '   buttons: ' + this.loadingButtons + '   side: ' + this.loadingSide);
      this.loading = this.loadingMain || this.loadingButtons || this.loadingSide || this.loadingSelf;
    }, 100);
  }

  loadingSide = false;
  loadingButtons = false;
  loadingMain = false;
  loadingSelf = false;
  loadingOrigin = LoadingOrigin;
}

enum LoadingOrigin {
  Self = 0, Side = 1, Buttons = 2, Main = 3
}
