import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Store } from "@ngxs/store";
import { Observable, Subscription } from "rxjs";
import { ResourceType } from "src/app/common/enumerations/enumerations";
import { utils } from "src/app/modules/libs/utils";
import { Resource, ResourceRelation } from "src/app/components/catalogs/beamline-catalog/resource/resources";

@Component({
  selector: "cl-editor-location",
  templateUrl: "./cl-editor-location.component.html",
  styleUrls: ["./cl-editor-location.component.scss"],
})
export class ClEditorLocationComponent implements OnInit, OnDestroy {

  @Output() changed = new EventEmitter();
  @Input() disabled?: boolean;
  @Input() configuration?: string;
  @Input() resourceTypes?: ResourceType[];

  resource?: Resource;
  resources!: Resource[];
  resourcesFiltered!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;
  resourceGroups!: ResourceGroup[];

  resourceRelations!: ResourceRelation[];
  resourceRelations$!: Observable<ResourceRelation[]>;
  resourceRelationsSubs!: Subscription;

  locationCtrl = new FormControl();


  constructor(private store: Store) {
  }

  ngOnDestroy(): void {
    this.resourcesSubs?.unsubscribe();
    this.resourceRelationsSubs?.unsubscribe();
  }

  ngOnInit() {
    this.loadResources();
    this.loadResourceRelations();
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data.length) {
        this.resources = data;
        this.resourcesFiltered = this.resources.filter(x => this.resourceTypes?.includes(x.type ?? 0) || this.resourceTypes?.[0] == ResourceType.All);
        this.createLocationGroups();
        if (this.configuration) {
          this.resource = this.resources.find(x => x.id === utils.JSONparse(this.configuration).val);
          this.locationCtrl.setValue(this.resource);
        }
      }
    });
  }

  loadResourceRelations() {
    this.resourceRelations$ = this.store.select(state => state.ResourceRelations.data);
    this.resourceRelationsSubs = this.resourceRelations$.subscribe(data => {
      if (data.length) {
        this.resourceRelations = data;
        this.createLocationGroups();
      }
    });
  }

  createLocationGroups() {
    this.resourceGroups = [];
    this.resourcesFiltered.map(r => {
      if (!this.resourceGroups.map(g => g.type).includes(r.type ?? 0) && r.type != ResourceType.AcceleratorLocation) {
        this.resourceGroups.push({ name: r.resourceType?.name, type: r.type, locations: this.resourcesFiltered.filter(x => x.type == r.type).sort((a, b) => utils.SortBeamlines(a.name, b.name)) });
      }
      // }
    });
    this.resourceGroups = this.resourceGroups.sort((a, b) => (a.type ?? 0) - (b.type ?? 0));
  }

  displayObject(e: any) {
    return e ? e.name : null;
  }

  change(e: any) {
    const value = (e.target.value as string).toLowerCase();
    this.resourcesFiltered = this.resources.filter(x => this.resourceTypes?.includes(x.type ?? 0) || this.resourceTypes?.[0] == ResourceType.All);
    this.resourcesFiltered = this.resourcesFiltered.filter(x => x.name?.toLowerCase().includes(value));
    this.createLocationGroups();
  }

  selected(e: any) {
    const value = e.option.value as Resource;
    this.changed.emit(value);
  }
}


export class ResourceGroup {
  name?: string | null;
  type?: number | null;
  id?: number;
  locations?: Resource[];
}
