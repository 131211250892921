import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReviewBoardType } from '../reviews.models';
import { Store } from '@ngxs/store';
import { ReviewTypesRefresh } from '../store/review-types/review-types.action';

@Component({
  selector: 'reviews-settings',
  templateUrl: './reviews-settings.component.html',
  styleUrls: ['./reviews-settings.component.scss']
})
export class ReviewsSettingsComponent implements OnInit {

  reviewBoardType: ReviewBoardType | null;
  disabled?: boolean;

  constructor(
    private store: Store,
    public dialog: MatDialogRef<ReviewsSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reviewBoardType = data.reviewBoardType;
  }

  ngOnInit(): void {
    this.store.dispatch(new ReviewTypesRefresh());
  }

  close() {
    this.dialog.close(this.data);
  }

  reviewBoardTypeSelected(reviewBoardType: ReviewBoardType | null) {
    this.reviewBoardType = reviewBoardType;
  }

}
