<div class="d-print-flex">
  <mat-form-field
    [ngClass]="{
      hilite: hilite
    }"
  >
    <mat-label>{{ placeholder }}</mat-label>
    <mat-chip-grid #resourceChips [formControl]="control">
      <mat-chip-row
        *ngFor="let resource of selectedResources"
        [selectable]="true"
        [removable]="true"
        (removed)="remove(resource)"
      >
        {{ resource == null ? "" : resource?.name }}
        <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
      </mat-chip-row>
      <input
        #resourceInput
        [matAutocomplete]="resourceAutocomplete"
        [matChipInputFor]="resourceChips"
        [matChipInputAddOnBlur]="false"
        [placeholder]="placeholder"
        (keyup)="onKeyUp($event)"
        (mousedown)="createResourceGroups()"
        [formControl]="control"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #resourceAutocomplete="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-optgroup
        *ngFor="let group of resourceGroups"
        [label]="group.name ?? ''"
      >
        <mat-option *ngFor="let resource of group.resources" [value]="resource">
          {{ resource == null ? "" : resource.name }}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
    <mat-error *ngIf="control?.invalid">{{ getErrorMsg(control) }}</mat-error>
  </mat-form-field>
</div>
