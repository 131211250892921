import { Component, ViewChild, ViewChildren, ViewContainerRef, QueryList, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatRow } from '@angular/material/table';
import { KEService } from '../ke.service';
import { KEResource } from '../ke';
import { Subscription, Observable } from 'rxjs';
import { FormStatusEnum, FormType } from 'src/app/common/enumerations/enumerations';
import { FormStatus } from 'src/app/services/forms/form-status';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { BaseComponent } from 'src/app/common/base/base.component';
import { KEnableUpdate } from '../store/ke/kenable.action';

@Component({
  selector: 'ke-side-panel',
  templateUrl: './ke-side-panel.component.html',
  styleUrls: ['./ke-side-panel.component.scss']
})
export class KESidePanelComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() filter!: string;
  @Output() loading = new EventEmitter<boolean>();

  public displayedColumns: string[] = ['serialNo', 'shutterName', 'typeName', 'statusName'];
  public dataSource = new MatTableDataSource<KEResource>();

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren('matrow', { read: ViewContainerRef }) rows!: QueryList<ViewContainerRef>;

  @ViewChildren('matrow')
  datarows!: QueryList<MatRow>;

  public serialNo!: string;
  public selectedRowIndex = -1;
  public selectedIndex = -1;
  public showClosed = false;

  public allowNew?: boolean;
  public disabled = false;
  maxHeight!: string;

  @Output() rowSelected = new EventEmitter<KEResource>();
  @Output() newDocument = new EventEmitter<KEResource>();
  @Output() toggleSidePanel = new EventEmitter<any>();
  @Output() discard = new EventEmitter<any>();

  ke?: KEResource | null;
  kes!: KEResource[];
  kes$!: Observable<KEResource[]>;
  kesSubs!: Subscription;

  formStatuses!: FormStatus[];
  formStatuses$!: Observable<FormStatus[]>;
  formStatusesSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private _ke: KEService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.applyFilter(this.filter);
  }

  override ngOnDestroy(): void {
    this.kesSubs?.unsubscribe();
    this.formStatusesSubs?.unsubscribe();
  }

  ngOnInit() {
    this.loadFormStatuses();
    this.allowNew = (this.getCurrentUser() && this.hasPrivilege(PrivilegeEnum.CreateKEChecklist)) ?? false;
    this.loadKEs();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  loadFormStatuses() {
    this.formStatuses$ = this.store.select(state => state.FormStatuses.data);
    this.formStatusesSubs = this.formStatuses$.subscribe(data => {
      if (data) {
        this.formStatuses = data;
      }
    });
  }

  //#region Load KE

  loadKEs() {
    this.loading.emit(true);
    this.kes$ = this.store.select(state => state.KEnables.data);
    this.kesSubs = this.kes$.subscribe(async data => {
      if (data.length) {
        this.kes = data.sort((a, b) => this.utils.sort(a.serialNo, b.serialNo, false));
        this.dataSource = new MatTableDataSource(this.kes.filter(x => this.showClosed ? true : (x.status != FormStatusEnum.Closed && x.status != FormStatusEnum.Canceled)));

        const openDocument = await this.checkOpenDocument<KEResource>(FormType.KE, data);
        if (openDocument) {
          this.loading.emit(true);
          this.showClosed = openDocument.closed;
          this.getValues(openDocument.document);
        }
        else this.loading.emit(false);
      }
      this.loading.emit(data.length == 0);
    });
  }

  async selectRow(row?: KEResource) {
    if (await this.canDeactivate()) {
      if (row) row.wfTable = undefined;
      this.getValues(row);
    }
  }

  getValues(row?: KEResource) {
    this.ke = row;
    this.rowSelected.emit(row);
    this.selectedRowIndex = row?.id ?? -1;
    this.setOpenDocument(FormType.KE, row?.id);
    if (row && !row?.wfTable) {
      this.loading.emit(true);
      this._ke.getById(row.id).subscribe(ke => {
        if (ke && JSON.stringify(ke) !== JSON.stringify(row)) {
          row = ke;
          this.ke = ke;
          this.store.dispatch(new KEnableUpdate(ke.id, ke));
        }
        this.rowSelected.emit(row);
        this.loading.emit(false);
      });
    }
    else {
      this.rowSelected.emit(row);
      this.loading.emit(false);
    }
  }

  override async canDeactivate(): Promise<boolean> {
    if (this.isFormDirty()) {
      const value = await super.canDeactivate();
      if (value)
        this.discard.emit(true);
      return value;
    }
    else return true;
  }

  create() {
    this.selectedRowIndex = -1;
    this.serialNo = '{Serial No}';
    const newDoc: KEResource =
    {
      id: 0,
      type: FormType.KE,
      serialNo: this.serialNo,
      status: 0,
      statusName: 'New'
    };
    this.newDocument.emit(newDoc);
  }

  unselect() {
    this.ke = null;
    this.selectedIndex = -1;
  }

  //#region FILTERS

  applyFilter(filterValue: string) {
    const val = filterValue?.toLowerCase();
    this.dataSource = new MatTableDataSource(this.kes?.filter(x => this.showClosed ? true : (x.status != FormStatusEnum.Closed && x.status != FormStatusEnum.Canceled))?.filter(k => k.serialNo.toLowerCase().includes(val) || k.shutterName?.toLowerCase().includes(val) || k.typeName?.toLowerCase().includes(val) || k.statusName?.toLowerCase().includes(val)));
  }

  changeClosed() {
    this.loadKEs();
  }

  //#region TableScroll
  showElement(index: number, height: number) {
    const indexstr = index.toString();
    const row = this.rows.find(row => row.element.nativeElement.getAttribute('position') === indexstr);
    if (row != null) {
      const rect = row.element.nativeElement.getBoundingClientRect();
      if ((rect.y <= 0) || ((rect.y + rect.height) > height)) {
        row.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
      }
      return;
    }
    console.log('not found');
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex(r => {
      return r.id == row.id;
    });
    return i;
  }

  getColor(row: KEResource) {
    return this.formStatuses?.find(x => x.id == row.status)?.color;
  }
}
