import { Component } from '@angular/core';

@Component({
  selector: 'waiting-text',
  templateUrl: './waiting-text.component.html',
  styleUrls: ['./waiting-text.component.scss']
})
export class WaitingTextComponent {

}
