import { Component, ElementRef, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ChecklistsService } from 'src/app/components/checklists/checklists.service';
import { Location, LocationGroup, Resource, ResourceRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { AbstractControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { SaveChecklistLocationsResource } from 'src/app/components/checklists/checklists';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { ResourceType } from 'src/app/common/enumerations/enumerations';

@Component({
  selector: 'cl-location',
  templateUrl: './cl-location.component.html',
  styleUrls: ['./cl-location.component.scss']
})
export class ClLocationComponent extends ClBaseComponent implements OnInit, OnChanges, OnDestroy {

  locations!: Location[];
  filteredLocations!: Location[];
  allLocations!: Location[];
  locationGroups!: LocationGroup[];

  @ViewChild("locationInput") locationInput!: ElementRef<HTMLInputElement>;
  @ViewChild("autoLoc") matAutocompleteLoc!: MatAutocomplete;

  resources!: Resource[];
  resourcesFiltered!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  resourceRelations!: ResourceRelation[];
  resourceRelationsFiltered!: ResourceRelation[];
  resourceRelations$!: Observable<ResourceRelation[]>;
  resourceRelationsSubs!: Subscription;

  public get locationsCtrl(): AbstractControl | null { return this.formGroup?.get("locationsCtrl"); }

  constructor(
    protected override injector: Injector,
    private _checklist: ChecklistsService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadResources();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['valueString']) {
      const locations = this.checklist?.locations as string;
      if (locations) {
        this.locations = this.utils.JSONparse(locations);
        this.loadLocations();
      }
    }
  }

  override ngOnDestroy(): void {
    this.resourcesSubs?.unsubscribe();
    this.resourceRelationsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      locationsCtrl: [
        { value: "" },
        [Validators.required, this.utils.IsWhiteSpaceReactiveForm],
      ],
    });
    this.formGroup.disable();
    this.formGroup.valueChanges.subscribe(() => {
      this.formValid();
    });
  }

  formValid() {
    if (this.locations?.length == 0) {
      this.locationsCtrl?.setErrors({ required: true });
    } else {
      this.locationsCtrl?.setErrors(null);
    }
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data.length) {
        this.resources = data;
        this.loadResourceRelations();
      }
    });
  }

  loadResourceRelations() {
    this.resourceRelations$ = this.store.select(state => state.ResourceRelations.data);
    this.resourceRelationsSubs = this.resourceRelations$.subscribe(data => {
      if (data.length) {
        this.resourceRelations = data;
        this.loadLocations();
      }
    });
  }

  loadLocations() {
    this.resources$ = this.store.select((state) => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe((data) => {
      if (data.length) {
        this.resources = data;
        this.allLocations = this.resources
          .filter((x) => x.status !== 0 && x.resourceType?.location)
          ?.map((x) => {
            const location = {
              id: x.id,
              name: x.name,
              type: x.type,
              resourceType: x.resourceType,
            };
            return location;
          })
          .sort((a, b) => this.utils.SortBeamlines(a.name, b.name));
        this.filteredLocations = this.allLocations.filter(
          (x) => !this.locations?.map((l) => l.id).includes(x.id)
        );
        this.createLocationGroups();
      }
    });
  }

  createLocationGroups() {
    this.locationGroups = [];
    this.filteredLocations.map((l) => {
      if (l.type == ResourceType.Accelerator) {
        if (!this.locationGroups.map(g => g.id).includes(l.id)) {
          const relations = this.resourceRelations?.filter(rr => rr.parentResourceID == l.id && !this.locations?.map(x => x.id).includes(rr.childResourceID));
          const locs = relations?.filter(l => l.childResourceType == ResourceType.AcceleratorLocation).map(rr => {
            return { id: rr.childResourceID, name: rr.childResourceName, type: rr.childResourceType } as Location;
          });
          this.locationGroups.push({
            name: l.name,
            type: l.type,
            id: l.id,
            locations: locs
          });
        }
      } else {
        if (
          !this.locationGroups.map((g) => g.type).includes(l.type) &&
          l.type != ResourceType.AcceleratorLocation
        ) {
          this.locationGroups.push({
            name: l.resourceType?.name,
            type: l.type,
            locations: this.filteredLocations.filter((x) => x.type == l.type),
          });
        }
      }
    });
    this.locationGroups = this.locationGroups.sort((a, b) => this.utils.sort(a.type, b.type));
  }

  selectedLoc(event: MatAutocompleteSelectedEvent) {
    if (!this.locations) {
      this.locations = [];
    }
    if (
      !this.locations?.some((l) => l.id === event.option.value?.id) &&
      event.option.value !== null &&
      event.option.value !== undefined
    ) {
      this.locations.push(event.option.value);
      this.locationInput.nativeElement.value = "";
      this.locationsCtrl?.setValue(this.locations);
      this.saveLocations();
    }
  }

  async addLoc(event: MatChipInputEvent) {
    if (!this.matAutocompleteLoc.isOpen) {
      const input = event.input;
      const value = event.value;
      if (input) {
        input.value = "";
      }
      if (value !== "") {
        const newLocation: Location = {
          id: 0,
          name: value,
          type: -1,
          newLocation: true,
        };
        if (!this.locations) this.locations = [];
        this.locations.push(newLocation);
      }
      this.locationsCtrl?.setValue(this.locations);
      this.saveLocations();
    }
  }

  removeLoc(location: Location): void {
    const index = this.locations.indexOf(location);
    if (index >= 0) {
      this.locations.splice(index, 1);
      this.saveLocations();
      if (!this.locations.length)
        this.checkboxChanged({ checked: false });
    }
  }

  onLocChange(code?: string) {
    if (!code?.includes("Arrow")) {
      this.loadLocations();
      this.filteredLocations = this.filteredLocations.filter((loc) =>
        loc.name
          ?.toLowerCase()
          .includes(this.locationsCtrl?.value?.trim().toLowerCase())
      );
      this.createLocationGroups();
    }
  }

  checkboxChanged(e: any) {
    this.checked = e.checked;
    this.user = this.checked ? this.getCurrentUser() : null;
    this.answered.emit(this.checked);
  }

  async saveLocations() {
    const saveChecklist: SaveChecklistLocationsResource = {
      id: this.checklist?.id ?? 0,
      locations: this.utils.JSONstringify(this.locations)
    }
    await this._checklist.putLocations(saveChecklist).toPromise();
    this.saveValues(this.locations);
  }

}
