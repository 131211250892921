<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-2 justify-content-center">
        <mat-icon color="accent">warning</mat-icon>
      </div>
      <div class="col d-flex flex-column">
        <h6>{{ title }}</h6>
        <label>{{ warning }}</label>
        <mat-form-field>
          <mat-label><span innerHTML="{{ placeholder }}"></span></mat-label>
          <textarea
            aria-label="placeholder"
            matInput
            id="cancelReason"
            rows="5"
            [(ngModel)]="data.text"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <mat-dialog-actions class="d-flex justify-content-center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        class="ml-2"
        mat-flat-button
        color="primary"
        [disabled]="!data.text"
        (click)="accept()"
      >
        Accept
      </button>
    </mat-dialog-actions>
  </div>
</div>
