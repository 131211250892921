import { Component, OnInit, Inject, Injector, OnChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { UserService } from 'src/app/components/catalogs/user-catalog/services/user.service';
// import { User } from '../../../../Services/Users/user';
// import { UserService } from '../../../../Services/Users/user.service';

@Component({
  selector: 'app-role-user',
  templateUrl: './role-user.component.html',
  styleUrls: ['./role-user.component.scss']
})
export class RoleUserComponent extends BaseComponent implements OnChanges {

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<RoleUserComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User,
  ) {
    super(injector);
  }

  public usersFiltered?: User[];
  public userSelected?: User;

  ngOnChanges(): void {
    this.usersFiltered = this.getUsers();
  }

  getUserSelected(isSelected: boolean, user: User) {
    if (isSelected) {
      this.userSelected = user;
    }
  }

  add() {
    if (this.userSelected?.id != undefined) {
      this.dialogRef.close(this.userSelected);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  filter(e: any) {
    const code = e.target.code;
    const value = e.target.value;
    if (!code?.includes('Arrow')) {
      this.usersFiltered = this.getUsers()?.filter(x => x.name?.toLowerCase().includes(value.trim().toLowerCase()));
    }
  }

}
