<div class="pt-2 mt-3 sectionx" id="section">
  <div class="row no-gutters" *ngIf="sectionName">
    <div
      class="col-2 border-bottom d-flex flex-column justify-content-end pt-2"
    >
      <div
        *ngIf="!disabled"
        class="small d-flex flex-row justify-content-between"
      >
        <div><p>Reorder</p></div>
        <div><p>Include</p></div>
      </div>
    </div>
    <div class="col-9 pl-4 pb-2">
      <h5 id="Section1Title">{{ section.number }}. {{ sectionName }}</h5>
    </div>
    <div
      class="col-1 border-bottom d-flex flex-column justify-content-end pt-2"
    >
      <div
        *ngIf="!disabled"
        class="small pr-2 d-flex flex-row justify-content-between"
      >
        <div><p>Edit</p></div>
        <div><p>Delete</p></div>
      </div>
    </div>
  </div>

  <div (cdkDropListDropped)="reorderTable($event)" cdkDropList>
    <div *ngFor="let s of signatures" id="{{ 'task-' + s.id }}">
      <div cdkDrag class="row no-gutters relative">
        <div
          class="col-2 p-1 border-bottom d-flex flex-column justify-content-center"
        >
          <div
            *ngIf="s.id != -1"
            class="w-100 d-flex justify-content-between flex-row"
          >
            <div>
              <mat-icon
                [class.no-display]="disabled"
                cdkDragHandle
                color="accent"
                style="cursor: move"
                >reorder</mat-icon
              >
            </div>
            <div class="pt-1 text-center">
              <mat-checkbox
                [(ngModel)]="s.required"
                [labelPosition]="'before'"
                color="primary"
                (change)="changeRequired($event, s.taskId ?? 0)"
                [disabled]="disabled"
              ></mat-checkbox>
            </div>
          </div>
        </div>
        <div class="col p-1 pl-3" *ngIf="!editing || s.id != editingId">
          <master-control
            [type]="s.type"
            [builder]="true"
            [visible]="true"
            [disabled]="true"
            [color]="'primary'"
            [number]="s.number"
            [header]="
              s.required ? (s.number ? s.number.toString() : s.name) : null
            "
            [label]="s.name"
            [text]="s.question"
            [question]="s.question"
            [radioQuestion]="s.question2"
            [radioOptions]="s.options"
            [controlID]="s.value2"
            [checkbox_checked]="s.approved"
            [approved]="s.approved"
            [approvedBy]="s.approvedBy"
            [approvedOn]="s.approvedOn"
            [roleIds]=""
            [roleCodes]="s.roleCodes"
            [scheduleResourceId]="s.scheduleResourceId"
            [textBefore]="s.question"
            [textAfter]="s.question2"
            [textAfter2]="s.question3"
            [textValue]="s.textValue"
            [checkboxVisible]="s.visible"
            [disableEdit]="true"
            [dateValue1]="s.dateValue1"
            [dateValue2]="s.dateValue2"
            [dateValue3]="s.dateValue3"
            [scheduleName]="s.scheduleName"
            [locationName]="s.locationName"
            [configuration]="s.configuration"
            [editMode]="s.editMode"
            [serialNo]="serialNo"
            [formType]="formType"
            style="width: 100%"
          >
          </master-control>
        </div>

        <div
          class="warning"
          *ngIf="s.invalid"
          [matTooltip]="s.errors ?? ''"
          matTooltipClass="warn-tooltip"
          matTooltipPosition="right"
        >
          <mat-icon color="warn">warning</mat-icon>
        </div>
        <div
          class="col-1 p-1 border-bottom d-flex flex-column justify-content-center"
        >
          <!--  -->
          <div
            *ngIf="!s.editMode && s.id != -1 && !disabled"
            class="w-100 d-flex justify-content-between flex-row"
          >
            <div *ngIf="!s.iterable">
              <mat-icon color="accent" class="edit" (click)="edit(s.id)"
                >edit</mat-icon
              >
            </div>
            <div class="pt-1 text-center" *ngIf="!s.iterable">
              <mat-icon color="warn" class="delete" (click)="delete(s)"
                >delete</mat-icon
              >
            </div>
          </div>
          <div
            *ngIf="!s.editMode && s.id != -1 && disabled"
            class="w-100 d-flex justify-content-between flex-row"
          >
            <div *ngIf="!s.iterable">
              <mat-icon color="primary" class="edit" (click)="edit(s.id)"
                >visibility</mat-icon
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row no-gutters" *ngIf="sectionName && !disabled">
    <div class="col-2"></div>
    <div class="col-10 p-2 pl-3">
      <button matButton class="btn" *ngIf="!adding" (click)="edit()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
