<div class="container-fluid mt-3">
  <div class="row">
    <div class="col">
      <mat-form-field
        [ngClass]="{
          hilite:
            showChanges &&
            notificationTemplate?.name != tmpNotificationTemplate.name
        }"
      >
        <mat-label>Name</mat-label>
        <input
          aria-label="name"
          matInput
          [ngModel]="notificationTemplate?.name"
          [disabled]="!(adding || modifying)"
          name="notificationTemplateName"
          (ngModelChange)="changed(item.Name, $event)"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field
        [ngClass]="{
          hilite:
            showChanges &&
            notificationTemplate?.messageSubject !=
              tmpNotificationTemplate.messageSubject
        }"
      >
        <mat-label>Subject</mat-label>
        <input
          aria-label="message"
          matInput
          [ngModel]="notificationTemplate?.messageSubject"
          [disabled]="!(adding || modifying)"
          name="notificationTemplateMessageSubject"
          (ngModelChange)="changed(item.Subject, $event)"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div
      class="col px-4 mb-3"
      [innerHTML]="notificationTemplate?.description"
    ></div>
  </div>

  <div class="row pt-2">
    <div class="col">
      <div class="pb-1">
        <!-- <mat-label>Body</mat-label> -->
        <comments-box
          [editor]="true"
          [textValue]="notificationTemplate?.messageBody"
          [disabled]="!(adding || modifying)"
          [serialNo]="'Notification=' + notificationTemplate?.id"
          [formType]="'NotificationTemplates'"
          [title]="'Notification Body'"
          (textChanged)="changed(item.Body, $event)"
          maxHeight="70vh"
          [hilite]="
            showChanges &&
            notificationTemplate?.messageBody !=
              tmpNotificationTemplate.messageBody
          "
        >
        </comments-box>
        <!-- <quill-editor
                [(ngModel)]="notificationTemplate.messageBody"
                [disabled]="!(adding || modifying)"
              ></quill-editor> -->
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col">
      <div class="pb-1">
        <comments-box
          [editor]="true"
          [textValue]="notificationTemplate?.signature"
          [disabled]="!(adding || modifying)"
          [serialNo]="'Notification=' + notificationTemplate?.id"
          [formType]="'NotificationTemplates'"
          [title]="'Notification Signature'"
          (textChanged)="changed(item.Signature, $event)"
          maxHeight="70vh"
          [hilite]="
            showChanges &&
            notificationTemplate?.signature != tmpNotificationTemplate.signature
          "
        >
        </comments-box>
        <!-- <mat-label>Signature</mat-label>
              <quill-editor
                [(ngModel)]="notificationTemplate.signature"
                [disabled]="!(adding || modifying)"
                name="notificationTemplateSignature"
              ></quill-editor> -->
      </div>
    </div>
  </div>
</div>
<div class="buttons">
  <div class="row">
    <div class="col-6">
      <button
        mat-stroked-button
        color="warn"
        (click)="reset()"
        *ngIf="adding || modifying"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="warn"
        (click)="cancel()"
        *ngIf="isModifiying && isModifier"
      >
        Cancel
      </button>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <button
        mat-flat-button
        color="accent"
        [disabled]="!hasRoles([roleEnum.ADM]) || isModifiying"
        *ngIf="notificationTemplate && !modifying && !isModifiying"
        (click)="modify()"
      >
        Modify
      </button>
      <button
        mat-flat-button
        color="primary"
        *ngIf="modifying || adding"
        [disabled]="!isformDirty()"
        (click)="saveNotificationTemplate()"
      >
        Save
      </button>
      <button
        mat-flat-button
        color="primary"
        class="mr-2"
        *ngIf="showApproveUnapprove"
        (click)="approve()"
      >
        Approve
      </button>
      <button
        mat-flat-button
        color="warn"
        *ngIf="showApproveUnapprove"
        (click)="openAddDisapprovalDialog()"
      >
        Disapprove
      </button>
    </div>
  </div>
</div>
