import { Injectable } from '@angular/core';
import { PrivilegeEnum } from '../role-privilege/privilege-enum';
import { RoleToken, Token } from './token-structure';
import { utils } from 'src/app/modules/libs/utils';
// import { Token, RoleToken } from '../_interface/token-structure';
// import { PrivilegeEnum } from './RolePrivilege/privilege-enum';

@Injectable({
  providedIn: 'root'
})
export class TokenInfoService {

  constructor() { }

  getFullInfo(): any {
    const token = localStorage.getItem('Token');
    if (!token) {
      return null;
    }
    const data = atob(token.split('.')[1]);
    return data;
  }

  getRoles(): RoleToken[] | null {
    const token = localStorage.getItem('Token');
    if (!token) {
      return null;
    }
    const data: Token = JSON.parse(atob(token.split('.')[1]));
    try {
      return data.role as RoleToken[];
    } catch (e) {
      return null;
    }
  }

  getPrivileges(): PrivilegeEnum[] | null {
    const token = localStorage.getItem('Token');
    if (!token) {
      return null;
    }
    const data: Token = JSON.parse(atob(token.split('.')[1]));
    return data.privilege?.map(val => {
      const result: number = +val;
      return result;
    }) as PrivilegeEnum[];
  }

  hasRole(roleCode: string): boolean | null {
    const token = localStorage.getItem('Token');
    if (!token) {
      return null;
    }
    const data: Token = JSON.parse(atob(token.split('.')[1]));
    const roles = data.role.map(r => utils.JSONparse(r.toString())) as RoleToken[];
    if (roles?.find(val => val.code == roleCode)) {
      return true;
    } else {
      return false;
    }

  }

  getRole(roleCode: string): RoleToken | null {
    const token = localStorage.getItem('Token');
    if (!token) {
      return null;
    }
    const data: Token = JSON.parse(atob(token.split('.')[1]));
    const roles = data.role.map(r => utils.JSONparse(r.toString())) as RoleToken[];
    return roles.find(val => val.code === roleCode) ?? null;
  }

  hasPrivilege(privilege: PrivilegeEnum): boolean | null {
    const token = localStorage.getItem('Token');
    if (!token) {
      return null;
    }
    const data: Token = JSON.parse(atob(token.split('.')[1]));
    if (typeof (data.privilege) === 'string') {
      const y: number = +data.privilege;
      if (y === privilege) {
        return true;
      }
    } else {

      const allPrivilage = data.privilege?.map(val => {
        const result: number = +val;
        return result;
      }) as PrivilegeEnum[];
      if (allPrivilage) {
        return allPrivilage.includes(privilege);
      }
    }
    return false;
  }
}
