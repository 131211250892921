import { Component, Inject, Injector, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, Validators } from "@angular/forms";
import { BaseComponent } from "src/app/common/base/base.component";
import { utils } from "src/app/modules/libs/utils";

@Component({
  selector: "app-add-related-link",
  templateUrl: "./add-related-link.component.html",
  styleUrls: ["./add-related-link.component.scss"],
})
export class AddRelatedLinkComponent extends BaseComponent implements OnInit {
  Text = new FormControl("", [
    Validators.required,
    utils.IsWhiteSpaceReactiveForm,
  ]);
  URL = new FormControl(
    "",
    Validators.pattern(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    )
  );

  //messages
  addRelatedDocumentOrLink?: string;
  documentNameOrHyperlink?: string;
  youMustEnterAValidaValue?: string;
  webAddress?: string;

  constructor(
    public dialogRef: MatDialogRef<AddRelatedLinkComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.addRelatedDocumentOrLink = this.getMessage("Add Related Document or Link").description;
    this.documentNameOrHyperlink =
      this.data?.DocumentNameOrHyperlinkPlaceholder ?? this.getMessage("Document or Link Name").description;
    this.youMustEnterAValidaValue = this.getMessage("You must enter a valid value").description;
    this.webAddress = this.data?.WebAddressPlaceholder ?? this.getMessage("URL").description;
  }

  Add() {
    if (this.data.obj.URL === "" || !this.data.obj.URL) {
      this.data.obj = {
        Text: this.data.obj.Text,
      };
    } else {
      if (this.URL.invalid) {
        return;
      }
    }
    if (
      this.data.obj.URL != null &&
      this.data.obj.URL.substr(0, 4).toUpperCase() !== "HTTP"
    ) {
      this.data.obj.URL = "HTTPS://" + this.data.obj.URL;
    }
    if (this.data.obj.Text) {
      this.dialogRef.close(this.data.obj);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
