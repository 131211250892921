<div class="card">
  <div class="card-header">
    <span class="card-title">
      This component returns <b>{{ returnType }}</b> value.</span
    >

    <p>You can use <b>Tag</b> as follows in the <b>Conditions</b> field:</p>

    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="card-body">
    <table>
      <tr>
        <th>Syntax</th>
        <th>Operator</th>
        <th>Explanation</th>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Boolean">
        <td class="no-wrap"><span class="tag" [innerHTML]="tag"></span></td>
        <td>(None)</td>
        <td>Check if component value is Yes or True</td>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Boolean">
        <td class="no-wrap">
          <b>!</b><span class="tag" [innerHTML]="tag"></span>
        </td>
        <td class="no-wrap">! (Inverse)</td>
        <td>Check if component component value is No or False</td>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Sign">
        <td class="no-wrap"><span class="tag" [innerHTML]="tag"></span></td>
        <td class="no-wrap">(None)</td>
        <td>Check if component has been Signed or Checked</td>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Sign">
        <td class="no-wrap">
          <b>!</b><span class="tag" [innerHTML]="tag"></span>
        </td>
        <td class="no-wrap"><b>!</b> (Inverse)</td>
        <td>Check if component has not been Signed or Checked</td>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Sign">
        <td class="no-wrap">
          <span class="tag" [innerHTML]="tag"></span> <b>&&</b> [Other Tag]
        </td>
        <td class="no-wrap"><b>&&</b> (And)</td>
        <td>Check if two components have been Signed or Checked</td>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Sign">
        <td class="no-wrap">
          <span class="tag" [innerHTML]="tag"></span> <b>||</b> [Other Tag]
        </td>
        <td class="no-wrap"><b>||</b> (Or)</td>
        <td>Check if any of two components have been Signed or Checked</td>
      </tr>
      <tr
        *ngIf="data.component.returnType == returnTypes.Numeric && data.multi"
      >
        <td class="no-wrap">
          <span class="tag" [innerHTML]="tag"></span> <b>==</b> [Number]
        </td>
        <td class="no-wrap"><b>==</b> (Equal)</td>
        <td>Check if selected values includes a specific number</td>
      </tr>
      <tr
        *ngIf="data.component.returnType == returnTypes.Numeric && data.multi"
      >
        <td class="no-wrap">
          <span class="tag" [innerHTML]="tag"></span> <b>!=</b> [Number]
        </td>
        <td class="no-wrap"><b>!=</b> (Not Equal)</td>
        <td>Check if selected values not include a specfic number</td>
      </tr>
      <tr
        *ngIf="data.component.returnType == returnTypes.Numeric && !data.multi"
      >
        <td class="no-wrap">
          <span class="tag" [innerHTML]="tag"></span> <b>==</b> [Number]
        </td>
        <td class="no-wrap"><b>==</b> (Equal)</td>
        <td>Check if selected value is a specific number</td>
      </tr>
      <tr
        *ngIf="data.component.returnType == returnTypes.Numeric && !data.multi"
      >
        <td class="no-wrap">
          <span class="tag" [innerHTML]="tag"></span> <b>!=</b> [Number]
        </td>
        <td class="no-wrap"><b>!=</b> (Not Equal)</td>
        <td>Check if selected value is not a specfic number</td>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Text">
        <td class="no-wrap">
          <span class="tag" [innerHTML]="tag"></span> <b>==</b> '[String]'
        </td>
        <td class="no-wrap"><b>==</b> (Equal)</td>
        <td>Check if any of entered text values matches a String</td>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Text">
        <td class="no-wrap">
          <span class="tag" [innerHTML]="tag"></span> <b>!=</b> '[String]'
        </td>
        <td class="no-wrap"><b>!=</b> (Not Equal)</td>
        <td>Check if none of entered text values matches a String</td>
      </tr>
    </table>
  </div>
</div>
