import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from "@angular/core";
import { Store } from "@ngxs/store";
import { Observable, Subscription } from "rxjs";
import { BeamlineRequiringApprovalSummary } from "src/app/components/catalogs/bratoi/models/beamline-requiring-approval-summary";
import { utils } from "src/app/modules/libs/utils";

@Component({
  selector: "cl-editor-bratoi",
  templateUrl: "./cl-editor-bratoi.component.html",
  styleUrls: ["./cl-editor-bratoi.component.scss"],
})
export class ClEditorBRATOIComponent implements OnInit, OnDestroy {
  public blRequiringApprovals$: Observable<BeamlineRequiringApprovalSummary[]>;
  public blRequiringApprovalSubscription: Subscription;
  public blRequiringApprovals: BeamlineRequiringApprovalSummary[] = [];
  public blRequiringApproval?: BeamlineRequiringApprovalSummary;

  @Input() disabled?: boolean;
  @Input() configuration?: string;
  @Output() changed = new EventEmitter<BeamlineRequiringApprovalSummary>();
  @Output() sendBRCRole = new EventEmitter<string>();

  constructor(private store: Store) {
    this.blRequiringApprovals$ = this.store.select(
      (state) => state.Bratoi.data
    );
    this.blRequiringApprovalSubscription = this.blRequiringApprovals$.subscribe(
      (blRequiringApprovals) => {
        this.blRequiringApprovals =
          utils.cloneDeep(blRequiringApprovals);
      }
    );
  }

  ngOnInit() {
    if (this.configuration) {
      this.getBlRequiring();
    }
    this.sendBRCRole.emit("BRC");
  }

  ngOnDestroy() {
    this.blRequiringApprovalSubscription?.unsubscribe();
  }

  getBlRequiring() {
    this.blRequiringApproval = this.blRequiringApprovals.find(
      (x) =>
        x.idBRATOIRelated === utils.JSONparse(this.configuration).idBRATOIRelated
    );
  }

  blRequiringSelected(blRequiring: BeamlineRequiringApprovalSummary) {
    this.changed.emit(blRequiring);
  }
}
