import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { SignResource } from 'src/app/services/logs/signs';
import { TransactionLog } from 'src/app/services/logs/transaction-logs';

@Component({
  selector: 'transaction-log-approvals',
  templateUrl: './transaction-log-approvals.component.html',
  styleUrls: ['./transaction-log-approvals.component.scss']
})
export class TransactionLogApprovalsComponent extends BaseComponent implements OnChanges {

  @Input() transactionLogs?: TransactionLog[];

  signLogs?: SignResource[] = [];
  showBoxes?: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    this.createSignLogs();
  }

  createSignLogs() {
    this.transactionLogs?.filter(t => t.entityName == 'WFTask')?.sort((a, b) => this.utils.sort(a.id, b.id))?.map(t => {
      const wfTask = this.utils.JSONparse(t.objectJson);
      const signLog = {
        name: wfTask.Name,
        signedBy: wfTask.SignedBy,
        signedOn: wfTask.SignedOn,
        amendmentId: wfTask.AmendmentId,
        reason: wfTask.Reason,
        roleName: wfTask.RoleName
      } as SignResource;
      this.signLogs?.push(signLog);
    });
  }
}
