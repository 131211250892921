import { ResourceType } from "src/app/components/catalogs/beamline-catalog/resource/resources";


export class ResourceTypesRefresh {
  static readonly type = '[ResourceTypes] Refresh';
}

export class ResourceTypesCreate {
  static readonly type = '[ResourceTypes] Create';

  constructor(public item: ResourceType) { }
}

export class ResourceTypesUpdate {
  static readonly type = '[ResourceTypes] Update';

  constructor(public id: number, public item: ResourceType) { }
}

export class ResourceTypesDelete {
  static readonly type = '[ResourceTypes] Delete';
  constructor(public id: number) { }
}
