import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ReviewsService } from '../../reviews.service';
import { ReviewStatus } from '../../reviews.models';
import { ReviewStatusRefresh, ReviewStatusCreate, ReviewStatusUpdate, ReviewStatusDelete } from './review-status.action';

export class ReviewStatusStateModel {
  data: ReviewStatus[] = [];
}

@State<ReviewStatusStateModel>({
  name: 'ReviewStatus',
  defaults: {
    data: []
  }
})
@Injectable()
export class ReviewStatusState {
  constructor(
    private _service: ReviewsService
  ) { }

  @Selector()
  static read(state: ReviewStatusStateModel) {
    return state.data;
  }

  @Action(ReviewStatusRefresh)
  refresh({ patchState }: StateContext<ReviewStatusStateModel>) {
    this._service.getReviewStatuses().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ReviewStatusCreate)
  create({ getState, patchState }: StateContext<ReviewStatusStateModel>, { item }: ReviewStatusCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ReviewStatusUpdate)
  update({ getState, patchState }: StateContext<ReviewStatusStateModel>, { id, item }: ReviewStatusUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ReviewStatusDelete)
  delete({ getState, patchState }: StateContext<ReviewStatusStateModel>, { id }: ReviewStatusDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
