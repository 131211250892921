import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
// import { RSSWAAcceleratorLocation } from 'src/app/_interface/RSSWA/RSSWAAcceleratorLocation';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
// import { Resource } from 'src/app/_interface/Resources.models';
// import { ResourcesService } from 'src/app/Services/resources.service';
import { Store } from '@ngxs/store';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';
import { RSSWAAcceleratorLocation } from '../../../rsswa-accelerator-location';

@Component({
  selector: 'rsswa-accelerator-location-chips',
  templateUrl: './rsswa-accelerator-location-chips.component.html',
  styleUrls: ['./rsswa-accelerator-location-chips.component.scss']
})
export class RsswaAcceleratorLocationChipsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() control!: FormControl;
  @Input() rsswaId?: number;
  @Input() locations?: RSSWAAcceleratorLocation[];
  @Input() exceptions!: Resource[][];
  @Input() hilite!: boolean | null;

  @Output() onError = new EventEmitter<string>();
  @Output() onEdit = new EventEmitter<RSSWAAcceleratorLocation[]>();

  @ViewChild('locationAutocomplete') locationAutocomplete?: MatAutocomplete;
  @ViewChild('locationInput') locationInput?: ElementRef<HTMLInputElement>;


  constructor(
    public resourceService: ResourcesService,
  ) { }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {

  }

  select(event: MatAutocompleteSelectedEvent) {
    var location: Resource = event.option.value;
    if (!this.locations) this.locations = [];
    this.locations?.push({
      rsswaID: this.rsswaId,
      resourceID: location.id,
      resource: location
    });
    this.clear();
    this.onEdit.emit(this.locations);
  }

  remove(rsswaAcceleratorLocation: RSSWAAcceleratorLocation) {
    const index = this.locations?.indexOf(rsswaAcceleratorLocation) ?? -1;

    if (index >= 0) {
      this.locations?.splice(index, 1);
      this.clear();
      this.onEdit.emit(this.locations);
    }
  }

  clear() {
    if (this.locationInput != null) this.locationInput.nativeElement.value = '';
    if (this.control != null) this.control.setValue(null);
  }

}
