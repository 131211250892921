<div class="d-flex flex-column full-width">
  <cl-editor-status
    [disabled]="disabled"
    [configuration]="configuration"
    [checklistTemplate]="checklistTemplate"
    (selected)="statusSelected($event)"
  ></cl-editor-status>
  <div class="d-flex flex-row full-width align-items-center mt-1">
    <role-selection
      class="full-width"
      [noSubscript]="(rolesFiltered?.length ?? 0) > 0"
      [roles]="rolesFiltered"
      [disabled]="disabled"
      hint="Notification won't be sent without first selecting a Role"
      label="Roles to Notify on Status Change"
      (changed)="rolesSelected($event)"
    ></role-selection>
  </div>
</div>
