import { Component, Input, OnInit, OnDestroy, Injector } from '@angular/core';
import { Review, ReviewActionItem, ReviewStatusEnum } from 'src/app/components/reviews/reviews.models';
import { ChecklistTasksAnnotationsEditComponent } from '../checklist-tasks-annotations/checklist-tasks-annotations-annotation/checklist-tasks-annotations-edit/checklist-tasks-annotations-edit.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Observable, Subscription } from 'rxjs';
import { WfTaskLocalResource, WfSignature } from 'src/app/services/work-flow/work-flow';
import { Checklist } from '../../../checklists';
import { ReviewsActionItemsDetailsComponent } from 'src/app/components/reviews/reviews-action-items/reviews-action-items-details/reviews-action-items-details.component';
import { ReviewsService } from 'src/app/components/reviews/reviews.service';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';

@Component({
  selector: 'checklist-task-rcpactions',
  templateUrl: './checklist-task-rcpactions.component.html',
  styleUrls: ['./checklist-task-rcpactions.component.scss']
})
export class ChecklistTaskRcpactionsComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() task!: WfTaskLocalResource;
  @Input() currentChecklist?: Checklist | null;
  @Input() s!: WfSignature;

  review?: Review;
  reviews!: Review[];
  reviews$!: Observable<Review[]>;
  reviewsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private service: ReviewsService,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.reviewsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.loadReviews();
  }

  loadReviews() {
    this.reviews$ = this.store.select(state => state.Reviews.data);
    this.reviewsSubs = this.reviews$.subscribe(data => {
      this.reviews = data;
      this.review = this.reviews.find(x => x.checklistID == this.currentChecklist?.id);
    });
  }

  addActionItem() {
    const reviewActionItem = {
      id: 0,
      review: this.review,
      reviewID: this.review?.id,
    } as ReviewActionItem;
    this.openActionItemDetail(reviewActionItem);
  }

  checkActionItemsEnabled() {
    if (this.review)
      return this.service.checkPrivilege(PrivilegeEnum.AddRemoveActionItems, this.review?.reviewCycle.reviewBoard.reviewBoardType, this.review?.reviewStatus) && this.review?.reviewStatus.id != ReviewStatusEnum.Completed;
    else return false;
  }

  openActionItemDetail(reviewActionItem: ReviewActionItem) {
    const dialog = this.dialog.open(ReviewsActionItemsDetailsComponent, {
      width: '80%',
      minWidth: '500px',
      data: {
        reviewActionItem,
        wfTaskLocal: this.task,
        checklist: this.currentChecklist
      },
      disableClose: true,
      autoFocus: false
    });
    dialog.afterClosed().subscribe(data => {

    });
  }

  addComment() {
    const editor = this.dialog.open(ChecklistTasksAnnotationsEditComponent, {
      width: '80vw',
      maxHeight: '90vh',
      data: {
        s: this.s,
        isComment: true,
        task: this.task,
        annotation: null,
        currentChecklist: this.currentChecklist
      },
      autoFocus: false,
      disableClose: true,
      hasBackdrop: true
    });
    editor.afterClosed().subscribe((data) => {
      if (data) {

      }
    });
  }

}
