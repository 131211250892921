<div class="d-flex flex-column">
  <button
    *ngIf="checkActionItemsEnabled()"
    mat-icon-button
    color="accent"
    class="action-item-button"
    matTooltip="Add Action Item"
    [matTooltipPosition]="'above'"
    (click)="addActionItem()"
  >
    <mat-icon>feedback</mat-icon>
  </button>

  <button
    mat-icon-button
    class="blue"
    matTooltip="Add Comments"
    [matTooltipPosition]="'above'"
    (click)="addComment()"
  >
    <mat-icon>message</mat-icon>
  </button>
</div>
