import { ReviewActionItem } from '../../reviews.models';

export class ReviewActionItemsRefresh {
  static readonly type = '[ReviewActionItems] Refresh';
}

export class ReviewActionItemsCreate {
  static readonly type = '[ReviewActionItems] Create';

  constructor(public item: ReviewActionItem) { }
}

export class ReviewActionItemsUpdate {
  static readonly type = '[ReviewActionItems] Update';

  constructor(public id: number, public item: ReviewActionItem) { }
}

export class ReviewActionItemsDelete {
  static readonly type = '[ReviewActionItems] Delete';
  constructor(public id: number) { }
}
