import { WorkerVerification } from '../../../../services/worker-verification/worker-verification';

export class WorkerVerificationRefresh {
  static readonly type = '[WorkerVerification] Refresh';
}

export class WorkerVerificationCreate {
  static readonly type = '[WorkerVerification] Create';

  constructor(public item: WorkerVerification) { }
}

export class WorkerVerificationUpdate {
  static readonly type = '[WorkerVerification] Update';

  constructor(public id: number, public item: WorkerVerification) { }
}

export class WorkerVerificationDelete {
  static readonly type = '[WorkerVerification] Delete';

  constructor(public id: number) { }
}
