import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ResourceType } from 'src/app/common/enumerations/enumerations';
import { utils } from 'src/app/modules/libs/utils';
import { RSSTestShutterRequiredToBeClosedForTest, Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';

@Component({
  selector: 'rss-test-shutter-chips',
  templateUrl: './rss-test-shutter-chips.component.html',
  styleUrls: ['./rss-test-shutter-chips.component.scss']
})
export class RssTestShutterChipsComponent implements OnInit, OnChanges {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() control!: FormControl;
  @Input() resourceId?: number;
  @Input() selectedRssTestShutters?: RSSTestShutterRequiredToBeClosedForTest[] | null;
  @Input() isModified!: boolean;

  @Output() error = new EventEmitter<string>();
  @Output() edit = new EventEmitter();

  @ViewChild('shutterAutocomplete')
  shutterAutocomplete!: MatAutocomplete;
  @ViewChild('shutterInput')
  shutterInput!: ElementRef<HTMLInputElement>;

  allResources!: Resource[];
  availableResources!: Resource[];
  filteredResources!: Resource[];

  loading = false;

  constructor(
    public resourceService: ResourcesService
  ) { }

  ngOnInit(): void {
    if (this.control == null) {
      this.control = new FormControl();
    }
    this.allResources = [];
    this.availableResources = [];
    this.filteredResources = [];
    this.loading = true;
    this.resourceService.getResourcesByType(ResourceType.Shutter).subscribe(
      (data: Resource[]) => {
        data.map(x => {
          this.allResources.push(x);
          this.availableResources.push(x);
          this.filteredResources.push(x);
        });
      },
      error => {
        this.error.emit(error.error);
      },
      () => this.loading = false
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedRssTestShutters' in changes) {
      this.formatProps()
      this.setAvailableResources();
      this.clear();
    }
  }

  formatProps() {
    this.selectedRssTestShutters?.map(s => {
      s.Shutter = utils.convertToResource(s.Shutter);
    });
  }

  selected(event: MatAutocompleteSelectedEvent) {
    const shutter: Resource = event.option.value;
    this.selectedRssTestShutters?.push({
      BeamlineID: this.resourceId,
      ShutterID: shutter.id,
      Shutter: shutter
    });
    this.setAvailableResources();
    this.clear();
    this.edit.emit();
  }

  remove(rssTestShutter: RSSTestShutterRequiredToBeClosedForTest) {
    const index = this.selectedRssTestShutters?.indexOf(rssTestShutter) ?? -1;

    if (index >= 0) {
      this.selectedRssTestShutters?.splice(index, 1);
      this.setAvailableResources();
      this.clear();
      this.edit.emit();
    }
  }

  clear() {
    if (this.shutterInput != null) {
      this.shutterInput.nativeElement.value = '';
    }
    if (this.control != null) {
      this.control.setValue(null);
    }
  }

  setAvailableResources() {
    this.availableResources = [];
    if (this.allResources == null) {
      this.allResources = [];
    }
    if (this.selectedRssTestShutters == null) {
      this.selectedRssTestShutters = [];
    }
    this.allResources.map(x => this.availableResources.push(x));
    this.selectedRssTestShutters.map(x => {
      if (x.Shutter?.id) {
        const index = this.availableResources.map(y => y.id).indexOf(x.Shutter.id);

        if (index >= 0) {
          this.availableResources.splice(index, 1);
        }
      }
    });
    this.filteredResources = [];
    this.availableResources.map(x => this.filteredResources.push(x));
  }

  onShutterInputKeyup(e: any) {
    const text = e.target.value;
    this.filteredResources = this.availableResources.filter(x => x.name?.trim().toLowerCase().includes(text.trim().toLowerCase()));
  }

}
