import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { RolePrivilege, RolePrivilegePending } from '../../components/catalogs/roles/services/role-privilege';
import { PrivilegeEnum } from './privilege-enum';

@Injectable({
  providedIn: 'root'
})
export class RolePrivilegeService {

  public url: string = environment.urls.baseApiUrl;
  public api: string = this.url + '/RolePrivilege';
  public apiPending: string = this.url + '/RolePrivilegePending';

  constructor(
    public http: HttpClient,
    public auth: AuthenticationService
  ) { }

  getAllRolePrivileges(): Observable<RolePrivilege[]> {
    return this.http.get<RolePrivilege[]>(this.api);
  }

  ByPrivilege(privilege: PrivilegeEnum): Observable<RolePrivilege[]> {
    return this.http.get<RolePrivilege[]>(this.url + '/RolePrivilege/ByPrivilege/' + privilege.toString());

  }

  HasAccess(privilege: PrivilegeEnum): Observable<boolean> {
    return this.http.get<boolean>(this.url + '/RolePrivilege/HasAccess/' + privilege.toString());
  }

  createRolePrivilege(rolePrivilege: RolePrivilege): Observable<RolePrivilege> {
    return this.http.post<RolePrivilege>(this.api, rolePrivilege);
  }

  createRolePrivilegePending(rolePrivilegePending: RolePrivilegePending): Observable<RolePrivilegePending> {
    return this.http.post<RolePrivilegePending>(this.apiPending, rolePrivilegePending);
  }

  deleteRolePrivilege(id: number) {
    return this.http.delete(this.api + '/' + id);
  }

}
