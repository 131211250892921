<div class="container-fluid px-0">
  <div class="row no-gutters">
    <div class="col-sm-12 col-lg-4">
      <div class="row no-gutters">
        <div class="col height p-1">
          <app-accelerator-status></app-accelerator-status>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-sm-12 d-inline-block p-1">
          <app-beam-status></app-beam-status>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-8">
      <div class="row no-gutters">
        <div class="col-sm p-1">
          <app-current-activity></app-current-activity>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-sm p-1">
          <app-bulletin-board></app-bulletin-board>
        </div>
      </div>
      <div
        class="row no-gutters"
        *ngIf="hasPrivilege(privilegeEnum.RadAreaView)"
      >
        <div class="col-sm p-1">
          <app-radiation-area></app-radiation-area>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-12 col-lg-4 p-1">
          <app-branchline-status></app-branchline-status>
        </div>
        <div class="col-sm-4 p-1">
          <app-toxic-gas-panel></app-toxic-gas-panel>
        </div>
        <div class="col-sm-4 p-1">
          <app-bakeouts-panel></app-bakeouts-panel>
        </div>
      </div>
    </div>
  </div>
</div>
