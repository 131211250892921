import { Component, Inject, Injector, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ReviewsService } from "../../reviews.service";
import { BaseComponent } from "src/app/common/base/base.component";
import { Review, ReviewDocumentCategory, ReviewDocuments } from "../../reviews.models";

@Component({
  selector: "review-links",
  templateUrl: "./review-links.component.html",
  styleUrls: ["./review-links.component.scss"],
})
export class ReviewLinksComponent extends BaseComponent implements OnInit {

  constructor(
    protected override injector: Injector,
    private dialogRef: MatDialogRef<ReviewLinksComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Review,
    private _service: ReviewsService,
  ) {
    super(injector);
  }

  public hyperlink!: string;
  public webAddress!: string;
  public typeSelected!: number;
  public menuListCategories!: ReviewDocumentCategory[];
  public reviewParameter!: Review;
  public urlRegex = new RegExp(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
  );
  files: any[] = [];
  reviewLink!: ReviewDocuments;

  ngOnInit(): void {
    this.loadReviewCategories();
    if (this.data) {
      this.reviewParameter = this.data;
    }
  }

  async loadReviewCategories() {
    await this._service
      .getReviewDocumentCategories()
      .toPromise()
      .then((result) => {
        if (!(result?.length)) {
          result = [];
        }
        this.menuListCategories = result.filter((x) => x.status == 1);
        if (this.menuListCategories.length > 0) {
          this.typeSelected = this.menuListCategories[0].id;
        }
      });
  }

  close() {
    this.dialogRef.close();
  }

  SaveFiles() {
    this.reviewLink = {} as ReviewDocuments;

    if (this.isValid()) {
      if (
        this.webAddress != null &&
        this.webAddress.substr(0, 4).toUpperCase() !== "HTTP"
      ) {
        this.webAddress = "HTTPS://" + this.webAddress;
      }
      let attachedLink: ReviewDocuments = {
        name: this.hyperlink.trim(),
        fileID: undefined,
        reviewID: this.reviewParameter.id,
        reviewCategoryID: this.typeSelected,
        code: "url",
        url: this.webAddress.trim(),
        id: 0,
        description: "Document of  " + this.reviewParameter.name,
        type: 2,
        status: 1,
        size: "0 Kb",
      } as ReviewDocuments;

      this.reviewLink = attachedLink;
      this.dialogRef.close(this.reviewLink);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isValid() {
    if (this.utils.IsNullOrWhiteSpace(this.hyperlink)) {
      this.alert.message("linkDialog_Hyperlink_Required");
      return false;
    }
    if (this.utils.IsNullOrWhiteSpace(this.webAddress)) {
      this.alert.message("linkDialog_WebAddress_Required");
      return false;
    }
    if (this.webAddress) {
      if (!this.urlRegex.test(this.webAddress)) {
        this.alert.message("linkDialog_WebAddress_NotValid");
        return false;
      }
    }
    return true;
  }
}
