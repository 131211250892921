<div class="row">
  <div class="col-1 pt-1 pr-2 d-flex justify-content-between">
    <mat-icon color="accent">note</mat-icon>
    <h6>
      {{ label }}
    </h6>
  </div>
  <div class="col-10 pt-1">
    <mat-label
      [innerHTML]="text ?? '' | placeholderHighlight : editor"
    ></mat-label>
  </div>

  <div class="col-1"></div>
</div>
