import { Injectable, Injector } from '@angular/core';
import { FormType, OlogNotificationType } from 'src/app/common/enumerations/enumerations';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { FormService } from 'src/app/controls/form-table/form.service';
import { PartialOlogComponent } from 'src/app/controls/olog/partial-olog/partial-olog.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { OLogService } from 'src/app/components/olog/olog.service';
import { WfSignature, WFConfiguration, WFTaskSignatureResource } from 'src/app/services/work-flow/work-flow';
import { Form } from 'src/app/services/forms/form';
import { NormalInputDialogComponent } from 'src/app/controls/normal-input-dialog/normal-input-dialog.component';
import { Restriction } from 'src/app/controls/restrictions/services/restriction';
import { Observable, Subscription } from 'rxjs';
import { KEService } from '../kenable/ke.service';
import { BaseService } from 'src/app/common/base/base.service';
import { PPSTB } from './ppstb';

@Injectable({
  providedIn: 'root'
})
export class PpstbService extends BaseService<PPSTB, number> {
  public currentDocument?: Form;
  public form!: Form;
  loading!: boolean;

  restrictions!: Restriction[];
  restrictionsFiltered!: Restriction[];
  restrictions$!: Observable<Restriction[]>;
  restrictionsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private formService: FormService,
    private ologService: OLogService,
    private keService: KEService
  ) {
    super(injector, '/PPSTB');
  }

  loadRestrictions() {
    this.restrictions$ = this.store.select((state) => state.Restrictions.data);
    this.restrictionsSubs = this.restrictions$.subscribe((data) => {
      if (data?.length) {
        this.restrictions = data;
      }
    });
  }

  async confirmSign(form: Form | null | undefined, task: WfSignature, action?: number, reason?: string, options?: string, configuration?: WFConfiguration[]) {
    this.loadRestrictions();
    return new Promise(async (resolve) => {
      if (form && task && action) {
        this.form = form;
        const restrictedResourceIDs = this.restrictions.filter(x => this.form?.formVersion?.ppstb?.ppstbRestrictions?.filter(x => x.restrictionID).map(r => r.restrictionID).includes(x.id)).map(y => y.resourceID).filter((resourceID): resourceID is number => resourceID !== undefined);

        // Section 3 Mitigations

        if (configuration?.some(x => x.wfTaskLocalID == task.id && x.identificator == 102)) {
          const confirm = this.dialog.open(YesNoDialogComponent, {
            width: "500px",
            data: {
              message: this.getMessage('toActiveWarning').description,
              icon: "warn",
            },
          });
          confirm.afterClosed().subscribe(async (data) => {
            if (data && form?.id && task?.taskId) {
              const response = await this.ologService.postToOlog(FormType.PPSTB, form.id, OlogNotificationType.Activate);
              if (response?.ok) {
                await this.sign(form, task.taskId, action, reason, options);
                //Refresh KEs
                if (restrictedResourceIDs !== undefined && restrictedResourceIDs.length > 0) {
                  this.keService.processKES(restrictedResourceIDs);
                }
              }
              resolve(true);
            }
            else resolve(false);
          });
        }

        // Section 2 OIC Approval

        else if (configuration?.some(x => x.wfTaskLocalID == task.id && x.identificator == 103)) {
          const confirm = this.dialog.open(YesNoDialogComponent, {
            width: "500px",
            data: {
              message: this.getMessage('finish_message').description,
              icon: "warn",
            },
          });
          confirm.afterClosed().subscribe(async (data) => {
            if (data && form.id && task.taskId) {
              const response = await this.ologService.postToOlog(FormType.PPSTB, form.id, OlogNotificationType.Closed);
              if (response?.ok) {
                await this.sign(form, task.taskId, action, reason, options);
                //Refresh KEs
                if (restrictedResourceIDs !== undefined && restrictedResourceIDs.length > 0) {
                  this.keService.processKES(restrictedResourceIDs);
                }
              }
              resolve(true);
            }
            else resolve(false);
          });
        }

        else if (configuration?.some(x => x.wfTaskLocalID === task.id && x.identificator === 108)) {
          const confirm = this.dialog.open(NormalInputDialogComponent, {
            width: '400px',
            data: {
              label: this.getMessage('Enter_A_Reason_To_Disable').description,
              text: '',
              inputType: 'text'
            }
          });
          confirm.afterClosed().subscribe(
            async (data: string) => {
              if (data && task.taskId) {
                await this.sign(form, task.taskId, action, data, options);
              }
            }
          );
        } else if (configuration?.some(x => x.wfTaskLocalID === task.id && x.identificator === 107)) {
          const confirm = this.dialog.open(YesNoDialogComponent, {
            width: '500px',
            data: {
              message: this.getMessage('toActiveWarning').description,
              icon: 'warn'
            }
          });
          confirm.afterClosed().subscribe(data => {
            if (data != null) {
              this.dialog.open(NormalInputDialogComponent, {
                width: '400px',
                data: {
                  label: this.getMessage('Enter_A_Reason_To_Disable').description,
                  text: '',
                  inputType: 'text'
                }
              }).afterClosed().subscribe(
                async (data: string) => {
                  if (data) {
                    const response = await this.ologService.postToOlog(FormType.PPSTB, form.id, OlogNotificationType.Activate);
                    if (response?.ok) {
                      await this.sign(form, task.taskId, action, data);
                      //Refresh KEs
                      if (restrictedResourceIDs !== undefined && restrictedResourceIDs.length > 0) {
                        this.keService.processKES(restrictedResourceIDs);
                      }
                    }
                  }
                });
            }
          });
        }
        else {
          if (form.id && task.taskId) {
            await this.sign(form, task.taskId, action, reason, options);
            resolve(true);
          }
        }
      }
    });
  }

  async sign(form?: Form | null, taskId?: number, actionId?: number, reason?: string, options?: string, amendment?: boolean) {
    this.loading = true;
    if (form?.id && taskId && actionId) {
      this.form = form;
      const formStatus = this.form.formVersion.status;
      const formID = form?.id
      const wfTaskSignature: WFTaskSignatureResource = { taskId, actionId, reason, formID, amendment, options };
      const data = await this.formService.sign(wfTaskSignature).toPromise().catch(error => {
        console.error(error);
        this.alert.defaultError();
      });
      if (data && data?.formVersion.statusID != formStatus.id) {
        this.alert.message('formStateChanged', [new MessagePlaceholder("{serialNo}", this.form?.sequenceNumber.number), new MessagePlaceholder('{status}', data.formVersion.status.name)]);
      }
      return data;
    }
    else return undefined;
  }

  async editOlog(form?: Form | null) {
    if (form) {
      try {
        const data = await this.ologService.getOlog(FormType.PPSTB, form.id, OlogNotificationType.Activate).toPromise();
        if (data) {
          const dialogRef = this.dialog.open(PartialOlogComponent, {
            height: "fit-content",
            width: "80%",
            data,
          });
          dialogRef.afterClosed().subscribe((isSaved) => {
            console.log("The dialog was closed");
            if (isSaved) {
              console.log(isSaved);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  // async callOLogService(): Promise<boolean> {
  //   try {
  //     const olog: OlogDataBaseObject | undefined = await this.ologService.getOlogBySN(this.form.sequenceNumber.number).toPromise();
  //     if (olog) {

  //       let cc: string[] = [];
  //       // Begin special email charge
  //       const tmpRsswaShutters = this.form.formVersion.eB2.eB2Shutters;
  //       const shuttersIds: number[] = tmpRsswaShutters.map(x => x.resourceID);
  //       const emailsBL = await this.resourceSummaryService.GetEmailsFromShuttersArray(shuttersIds);
  //       Array.prototype.push.apply(cc, emailsBL);
  //       const roleCodes: any = [];

  //       if (roleCodes.length) {
  //         const emailsRoles = await this.userService.getEmailsByRoles(roleCodes).toPromise();
  //         Array.prototype.push.apply(cc, emailsRoles ?? []);
  //       }
  //       // End special email charge
  //       const body: OLogFormData = JSON.parse(olog.xmlBody.replace("\n", ""));
  //       const siteConfig = await this.siteConfigurationService.getById(1).toPromise();
  //       const siteConfigEmails = siteConfig?.defaultEmails.split(",") ?? [];
  //       Array.prototype.push.apply(cc, siteConfigEmails);

  //       if (this.form.formVersion.status.amendment) {
  //         body.subject = olog.serialNo + " has been Amended";
  //       }
  //       const OlogObject: OLogEntry = {
  //         root: {
  //           author: siteConfig?.ologUser,
  //           password: siteConfig?.ologPassword,
  //           entry: body,
  //         },
  //       };
  //       Array.prototype.push.apply(cc, OlogObject.root.entry.cc ?? []); // join all the emails
  //       cc = cc.map((item) => item.toLowerCase()); // change if it is necesary to lowercase (Specialy because Notifications don't make this)
  //       cc = [...new Set(cc)]; // Remove duplicates
  //       cc = cc.filter((mail) => mail !== "");
  //       OlogObject.root.entry.cc = cc.map(e => { return { address: e } as EmailAddress }); // returns the uniques emails
  //       const xmlBody = this.ologService.createXMLBody(OlogObject, this.form.sequenceNumber.number); // create the XML String from the body
  //       const result = await this.ologService.SendOLogEntry(xmlBody, this.form.sequenceNumber.number, olog
  //       );
  //       return result;
  //     }
  //     return false;
  //   } catch (e) {
  //     'OlogEntryError'
  //       ;
  //     return false;
  //   }


  // }

  // async callCloseOLogService(): Promise<boolean> {
  //   try {
  //     const olog: OlogDataBaseObject | undefined = await this.ologService.getOlogBySN(this.form.sequenceNumber.number).toPromise();
  //     if (olog) {
  //       let cc: string[] = [];
  //       // Begin special email charge
  //       const tmpRsswaShutters = this.form.formVersion.eB2.eB2Shutters;
  //       const shuttersIds: number[] = tmpRsswaShutters.map(x => x.resourceID);
  //       const emailsBL =
  //         await this.resourceSummaryService.GetEmailsFromShuttersArray(
  //           shuttersIds
  //         );
  //       Array.prototype.push.apply(cc, emailsBL);
  //       const roleCodes: number[] = [];
  //       // if (this.showAmendment ? formVersion.eB2.topOff : formVersion.previousVersion.eB2.topOff) {
  //       //   roleCodes.push(23);
  //       // }
  //       // if (this.showAmendment ? formVersion.eB2.radiationMonitoringSystem : formVersion.previousVersion.eB2.radiationMonitoringSystem) {
  //       //   roleCodes.push(16);
  //       // }
  //       if (roleCodes.length > 0) {
  //         const emailsRoles = await this.userService.getEmailsByRoles(roleCodes).toPromise();
  //         Array.prototype.push.apply(cc, emailsRoles ?? []);
  //       }
  //       // End special email charge
  //       const body: OLogFormData = JSON.parse(olog.xmlBody.replace("\n", ""));
  //       body.subject = this.form.sequenceNumber.number + " is Closed";
  //       body.details = null;
  //       body.extraSubject = null;
  //       const siteConfig = await this.siteConfigurationService
  //         .getById(1)
  //         .toPromise();
  //       const siteConfigEmails = siteConfig?.defaultEmails.split(",") ?? [];
  //       Array.prototype.push.apply(cc, siteConfigEmails);
  //       const OlogObject: OLogEntry = {
  //         root: {
  //           author: siteConfig?.ologUser,
  //           password: siteConfig?.ologPassword,
  //           entry: body,
  //         },
  //       };
  //       Array.prototype.push.apply(cc, OlogObject.root.entry.cc ?? []); // join all the emails
  //       cc = cc.map((item) => item.toLowerCase()); // change if it is necesary to lowercase (Specialy because Notifications don't make this)
  //       cc = [...new Set(cc)]; // Remove duplicates
  //       cc = cc.filter((mail) => mail !== "");
  //       OlogObject.root.entry.cc = cc.map(e => { return { address: e } as EmailAddress }); // returns the uniques emails
  //       const xmlBody = this.ologService.createXMLBody(OlogObject, this.form.sequenceNumber.number); // create the XML String from the body
  //       const result = await this.ologService.SendOLogEntry(xmlBody, this.form.sequenceNumber.number, olog);
  //       return result;
  //     }
  //     return false;
  //   } catch (e) {
  //     'OlogEntryError'
  //       ;
  //     return false;
  //   }
  // }
}
