import { WFComponent } from 'src/app/components/workflow/workflow';

export class ComponentsRefresh {
  static readonly type = '[Components] Refresh';
}

export class ComponentsCreate {
  static readonly type = '[Components] Create';

  constructor(public item: WFComponent) { }
}

export class ComponentsUpdate {
  static readonly type = '[Components] Update';

  constructor(public id: number, public item: WFComponent) { }
}

export class ComponentsDelete {
  static readonly type = '[Components] Delete';
  constructor(public id: number) { }
}
