import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ProcedureCreditedCreate, ProcedureCreditedDelete, ProcedureCreditedRefresh, ProcedureCreditedUpdate } from './procedure-credited.action';
import { ProcedureCreditedService } from '../../services/procedure-credited.service';
import { ProcedureCredited } from '../../models/procedure-credited.model';

export class ProcedureCreditedStateModel {
  ProceduresCredited: ProcedureCredited[] = [];
}

@State<ProcedureCreditedStateModel>({
  name: 'ProcedureCredited',
  defaults: {
    ProceduresCredited: [],
  },
})
@Injectable()
export class ProcedureCreditedState {
  constructor(
    private procedureCreditedService: ProcedureCreditedService,
  ) { }

  @Selector()
  static read(state: ProcedureCreditedStateModel) {
    return state.ProceduresCredited;
  }

  @Action(ProcedureCreditedRefresh)
  refresh({ patchState }: StateContext<ProcedureCreditedStateModel>) {
    this.procedureCreditedService.read().subscribe(
      data => patchState({ ProceduresCredited: data }),
      error => console.error(error),
    );
  }

  @Action(ProcedureCreditedCreate)
  create({ getState, patchState }: StateContext<ProcedureCreditedStateModel>, { item }: ProcedureCreditedCreate) {
    patchState({
      ProceduresCredited: [...getState().ProceduresCredited, item],
    });
  }

  @Action(ProcedureCreditedUpdate)
  update({ getState, patchState }: StateContext<ProcedureCreditedStateModel>, { id, item }: ProcedureCreditedUpdate) {
    const index = getState().ProceduresCredited.map(x => x.id).indexOf(id);

    if (index >= 0) {
      getState().ProceduresCredited.splice(index, 1);
      patchState({
        ProceduresCredited: [...getState().ProceduresCredited, item],
      });
    }
  }

  @Action(ProcedureCreditedDelete)
  delete({ getState, patchState }: StateContext<ProcedureCreditedStateModel>, { id }: ProcedureCreditedDelete) {
    patchState({
      ProceduresCredited: getState().ProceduresCredited.filter(x => x.id != id),
    });
  }
}
