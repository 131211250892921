<div
  class="section p-0 pl-2"
  [ngClass]="{ amendment: signature?.pendingChange }"
>
  <div class="row pl-3 pr-3">
    <div class="col-12 col-lg-4 pt-3 pb-2">
      <div class="row no-gutters">
        <div class="col-10 p-0">
          <h6 [innerHTML]="questionSanitized"></h6>
        </div>

        <div class="col-2">
          <mat-checkbox
            class="float-right"
            [(ngModel)]="checked"
            [disabled]="
              questionDisable || signature?.pendingChange || (approved ?? false)
            "
            (change)="checkboxChanged()"
          >
          </mat-checkbox>
        </div>
        <div class="col-12" *ngIf="checked && checklistTypeID">
          <checklist-status
            [formStatus]="status"
            [checked]="checked"
            [checklistsIDs]="checklistsIDs"
            [checklistTypeID]="checklistTypeID"
            [disabled]="questionDisable || signature?.pendingChange || approved"
            (changed)="textChanged($event)"
            (changing)="isChanging = $event"
            [isChanging]="isChanging"
          >
          </checklist-status>
        </div>
        <div
          class="col-12 small d-flex justify-content-between flex-row"
          [ngClass]="{ 'pending-changes-box': signature?.pendingChange }"
        >
          <div
            class="d-flex flex-column align-items-start"
            [ngClass]="{ changes: signature?.pendingChange }"
          >
            <div
              class="white mb-1 align-self-start"
              *ngIf="signature?.pendingChange"
            >
              <h6>Pending Changes:</h6>
            </div>

            <div
              *ngIf="lastChange"
              [ngClass]="{
                inline: checklistChips?.length,
                'd-flex': !checklistChips?.length
              }"
            >
              <span id="lastChange" [innerHTML]="lastChange"></span>
              <document-chips
                [documentChips]="checklistChips"
                [shadow]="true"
              ></document-chips>
              <span id="lastChangeBy" [innerHTML]="lastChangeBy"></span>&nbsp;
              <span id="lastChangeApproval" [innerHTML]="lastApproval"></span>
            </div>
          </div>
          <span
            class="d-flex c-buttons flex-column justify-content-center align-items-end"
            *ngIf="signature?.pendingChange"
          >
            <button
              mat-icon-button
              class="undo-button"
              matTooltip="Undo change"
              color="primary"
              *ngIf="answerChangedById && answerChangedById == currentUser?.id"
              (click)="undo()"
            >
              <mat-icon>undo</mat-icon>
            </button>
            <button
              *ngIf="canApproveAnswer"
              mat-flat-button
              class="approval-button mb-1"
              color="primary"
              (click)="apprAns()"
            >
              Approve
            </button>
            <button
              *ngIf="canApproveAnswer"
              mat-flat-button
              class="approval-button"
              color="warn"
              (click)="disapAns()"
            >
              Disapprove
            </button>
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-8 background">
      <div class="row">
        <div class="p-58per col-12 col-md-7">
          <div class="row">
            <div class="col-12 justify-content-center fit-content pb-0 d-flex">
              <h6>
                {{ title }}
              </h6>
              <role-chips [roleCodes]="roleCodes" class="ml-3"></role-chips>
            </div>
            <div
              class="col-12 text-center fit-content pt-0 d-flex justify-content-center"
            >
              <button
                mat-flat-button
                class="margin-auto d-print-none"
                *ngIf="visible"
                [disabled]="
                  disabled || signature?.pendingChange || checklistNotClosed
                "
                [color]="color"
                (click)="click()"
              >
                {{ label }}
              </button>
            </div>
            <div
              class="col-12 justify-content-center pt-0"
              *ngIf="showDisapprove"
            >
              <button
                mat-flat-button
                class="margin-auto d-print-none"
                *ngIf="visible && showDisapprove && !disabled"
                [disabled]="disabled"
                color="warn"
                (click)="disap()"
              >
                Disapprove
              </button>
            </div>
          </div>
        </div>
        <div class="p-41per col-12 col-md-5">
          <div class="row no-gutters">
            <div class="p-25per col-12 col-xl-3 fit-content pb-0">By:</div>
            <div class="p-75per col-12 col-xl-9 fit-content pb-0">
              <span class="name" *ngIf="approved">{{ name }}</span>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="p-25per col-12 col-xl-3 fit-content pt-0">
              <span class="small">Date:</span>
            </div>
            <div class="p-75per col-12 col-xl-9 fit-content pt-0">
              <span class="small date" *ngIf="approved">{{
                date | date : "medium"
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
