import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, OnDestroy, Input, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PendingResourcesType, Catalog } from 'src/app/common/enumerations/enumerations';
import { DisapprovalReasonComponent } from 'src/app/controls/disapproval-reason/disapproval-reason.component';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { CatalogModel } from '../../catalog-model';
import { CatalogService } from '../../catalog-service';
import { detailsUsers } from '../resources-new/resources-new.component';
import { Resource, ResourceSummary, BeamlineShutterRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rss-test-database',
  templateUrl: './rss-test-database.component.html',
  styleUrls: ['./rss-test-database.component.scss']
})
export class RssTestDatabaseComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() beamlineShutter?: BeamlineShutterRelation;
  @Input() showPrevResource!: boolean;

  @Output() loading = new EventEmitter<boolean>();
  @Output() refreshResources = new EventEmitter<any>();
  @Output() pending = new EventEmitter<boolean>();
  @ViewChild('bodyHeader') bodyHeader!: ElementRef;

  valid = false;
  // modifying = false;
  // deleted = false;
  // adding = false;
  isModifying = false;

  prevResource = false;

  showApproveDisapprove = false;
  showModifyButton = false;
  showCancelButton = false;

  modifyAvailable = false;

  editPrivilege = false;
  authPrivilege = false;

  shutter: Resource | null = new Resource();
  beamline: Resource | null = new Resource();
  resourceSummary: ResourceSummary = new ResourceSummary();

  currentBeamline!: Resource | null;
  currentShutter!: Resource | null;

  shutterName!: string;
  beamlineName!: string;

  beamlineShutterRelations?: BeamlineShutterRelation[];
  beamlineShutterRelationsFiltered?: BeamlineShutterRelation[];
  beamlineShutterRelations$!: Observable<BeamlineShutterRelation[]>;
  beamlineShutterRelationsSubs!: Subscription;

  public detailResourceUsers: detailsUsers = { approverUserName: '', updateUserName: '', approvedOn: null, updatedOn: null };

  constructor(
    protected override injector: Injector,
    public resourceService: ResourcesService,
    public catalogService: CatalogService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadBeamlineShutterRelations();
  }

  ngOnInit(): void {
    this.checkPrivileges();
    this.clear();
  }

  override ngOnDestroy(): void {
    this.beamlineShutterRelationsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  checkPrivileges() {
    this.editPrivilege = this.hasPrivilege(PrivilegeEnum.RSSTestDBEdit);
    this.authPrivilege = this.hasPrivilege(PrivilegeEnum.RSSTestDBAuth);
  }

  loadBeamlineShutterRelations() {
    this.beamlineShutterRelations$ = this.store.select(state => state.BeamlineShutter.data);
    this.beamlineShutterRelationsSubs = this.beamlineShutterRelations$.subscribe(data => {
      if (data?.length) {
        this.beamlineShutterRelations = data;
        if (this.beamlineShutter) {
          this.beamlineShutter = this.beamlineShutterRelations.find(r => r.beamlineId == this.beamlineShutter?.beamlineId);
          this.set();
        }
      }
    });
  }

  set() {
    this.loading.emit(true);
    if (!this.beamlineShutter) this.clear();

    if (this.beamlineShutter?.beamlineId) {
      this.catalogService.GetPendingResourcesUsersByResourceId(this.beamlineShutter.beamlineId, PendingResourcesType.Rss_Database).toPromise().then(PendingResourceUsers => {
        const idApproverUser = PendingResourceUsers?.approverId;
        let idLastUpdateUser = PendingResourceUsers?.updateById;

        if (!idLastUpdateUser && this.beamlineShutter?.pendingResources.length && this.showPrevResource) {
          this.detailResourceUsers.updateUserName = this.getUser(this.beamlineShutter.pendingResources[0].createdBy)?.name;
          this.detailResourceUsers.updatedOn = this.beamlineShutter.pendingResources[0].createdOn;
        }
        else if (idLastUpdateUser) {
          this.detailResourceUsers.approverUserName = this.getUser(idApproverUser)?.name;
          this.detailResourceUsers.updateUserName = this.getUser(idLastUpdateUser)?.name;
          this.detailResourceUsers.approvedOn = PendingResourceUsers?.approvedOn;
          this.detailResourceUsers.updatedOn = PendingResourceUsers?.updatedOn;
        }
        else {
          this.detailResourceUsers.approverUserName = '';
          this.detailResourceUsers.approvedOn = null;
          this.detailResourceUsers.updateUserName = '';
          this.detailResourceUsers.updatedOn = null;
        }
      });
      this.resourceService.getResourceSummaryByBeamline(this.beamlineShutter.beamlineId).subscribe(
        data => {
          this.addResourceSummaryInfo(data);
          this.showModifyButton = true;
          const pendingResource = this.beamlineShutter?.pendingResources?.find(x => x.type == PendingResourcesType.Rss_Database && (this.editPrivilege || this.authPrivilege));
          if (pendingResource && this.authPrivilege && this.currentUser?.id != pendingResource.createdBy) {
            this.prevResource = true;
            this.showApproveDisapprove = true;
            this.showModifyButton = false;
          }
          else {
            this.showCancelButton = this.currentUser?.id == pendingResource?.createdBy;
            this.prevResource = this.currentUser?.id == pendingResource?.createdBy;
            this.isModifying = false;
            this.showModifyButton = this.editPrivilege;
            this.modifyAvailable = !pendingResource;
          }
          this.onShowPrevResource();
          this.loading.emit(false);
        }
      );
    } else if (this.beamlineShutter?.beamlineId && this.beamlineShutter.beamlineId < 0) {
      this.catalogService.GetPendingCatalogById(this.beamlineShutter.beamlineId, Catalog.BeamlineCatalog).subscribe(
        data => {
          if (data.resourceSummary)
            this.addResourceSummaryInfo(data.resourceSummary);
          this.showApproveDisapprove = this.authPrivilege;
          this.loading.emit(false);
        }
      );
    }

    this.scrollToTop();
  }

  reset() {
    setTimeout(() => {
      this.clear();
      this.set();
    }, 300);
  }

  clear() {
    this.currentBeamline = null;
    this.currentShutter = null;
    this.resourceSummary = new ResourceSummary();
    this.shutter = new Resource();
    this.beamline = new Resource();
    this.valid = false;
    this.isModifying = false;
    this.showApproveDisapprove = false;
    this.showModifyButton = false;
    this.prevResource = false;
    this.showCancelButton = false;
    this.modifyAvailable = false;

    this.loading.emit(false);
  }

  addResourceSummaryInfo(data: ResourceSummary) {
    this.resourceSummary = data;
    if (data.shutter) {
      this.shutter = data.shutter as Resource;
      this.shutterName = this.shutter.name ?? '';
    }
    this.beamline = data.beamline as Resource;
    this.beamlineName = this.beamline.name ?? '';
  }

  modifyResource() {
    this.showModifyButton = false;
    this.isModifying = true;
  }

  async saveResource() {
    if (await this.isValid()) {
      const catalogModel = new CatalogModel();
      catalogModel.resourceSummary = this.resourceSummary;

      this.catalogService.SaveAsDraftType(catalogModel, Catalog.BeamlineCatalog, PendingResourcesType.Rss_Database).subscribe(x => {
        this.alert.warning('The Resource Modification Has Been Sent To Approve Successfully!!');
        this.reset();
      });
    }
  }

  async isValid() {
    this.valid = true;

    return this.valid;
  }

  approve() {
    if (this.beamlineShutter?.beamlineId)
      this.catalogService.Approve(this.beamlineShutter.beamlineId, Catalog.BeamlineCatalog, PendingResourcesType.Rss_Database).subscribe(
        () => {
          this.alert.success('Resource Approved Successfully!!');
          this.reset();
        },
        error => console.error(error)
      );
  }

  openAddDisapprovalDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = '';
    dialogConfig.width = '400px';
    const dialogRef = this.dialog.open(DisapprovalReasonComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data != undefined) {
        this.disapprove(data);
      }
    });
  }

  disapprove(disapprovalReason: string) {
    if (this.beamlineShutter?.beamlineId)
      this.catalogService.Unapprove(this.beamlineShutter.beamlineId, Catalog.BeamlineCatalog, disapprovalReason, PendingResourcesType.Rss_Database).subscribe(data => {
        this.alert.success('Resource Disapproved Successfully!!');
        this.reset();
      });
  }

  onShowPrevResource() {
    if (this.showPrevResource) {
      this.currentBeamline = this.beamline;
      this.currentShutter = this.shutter;
      const pendingResource = this.beamlineShutter?.pendingResources.find(x => x.type == PendingResourcesType.Rss_Database);
      const resourceData = this.utils.JSONparse(pendingResource?.resourceData);
      this.beamline = this.utils.convertToResource(resourceData.Beamline);
      this.shutter = this.utils.convertToResource(resourceData.Shutter);
    } else {
      this.beamline = this.currentBeamline ?? this.beamline;
      this.shutter = this.currentShutter ?? this.shutter;
      this.currentBeamline = null;
      this.currentShutter = null;
    }
  }

  isModified(resource: string, prop: string) {
    if (this.currentBeamline && this.currentShutter) {
      switch (resource) {
        case 'shutter':
          return (this.shutter as any)[prop] !== (this.currentShutter as any)[prop];
        case 'beamline':
          if (typeof (this.beamline as any)[prop] !== 'object') {
            return (this.beamline as any)[prop] !== (this.currentBeamline as any)[prop];
          } else {
            const obj1 = (this.beamline as any)[prop];
            const obj2 = (this.currentBeamline as any)[prop];
            return !this.utils.isEquivalent(obj1, obj2);
          }
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  cancel() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure you want to Cancel your changes ?',
        icon: 'warn'
      }
    });
    confirm.afterClosed().subscribe(res => {
      if (res) {
        if (this.beamlineShutter?.beamlineId)
          this.catalogService.Unapprove(this.beamlineShutter.beamlineId, Catalog.BeamlineCatalog, 'User Cancelled Changes', PendingResourcesType.Rss_Database).subscribe(data => {
            this.alert.warning('Changes has been cancelled!');
          });
      }
    });
  }

}
