import { Component, OnInit, Input, Output, EventEmitter, Injector } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { FormGroup, Validators, AbstractControl } from '@angular/forms';
import { CanDeactivateResponse } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog-response';
import { PendingChangesDialogComponent } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog.component';
import { utils } from 'src/app/modules/libs/utils';
import { BakeoutService } from '../bakeout.service';
import { Bakeout } from '../bakeouts';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'app-backout-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})

export class BakeoutEditorComponent extends BaseComponent implements OnInit {
  @Input()
  dataItem!: Bakeout;
  @Output() returnedItemEvent = new EventEmitter<Bakeout>();

  Id: number = 0;
  Username?: string;
  Phonenumber!: string;
  Location!: string;
  EstimatedStart!: Date;
  EstimatedEnd!: Date;
  Temperature!: string;
  TemperatureUnit!: string;
  TemperatureNotes!: string | null;
  AdditionalNotes!: string | null;
  EstimatedStartOutput!: Date | null | string;
  EstimatedEndOutput!: Date | null | string;
  DateNow!: Date;

  //flow
  subtitle!: string;
  saving!: boolean;

  //formGroup
  bakeoutForm!: FormGroup;
  get location(): AbstractControl | null { return this.bakeoutForm.get("location"); }
  get phone(): AbstractControl | null { return this.bakeoutForm.get("phone"); }

  constructor(
    public modal: MatDialogRef<BakeoutEditorComponent>,
    public service: BakeoutService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  validate(): boolean {
    if (utils.IsNullOrWhiteSpace(this.Phonenumber)) { return false; }

    if (utils.IsNullOrWhiteSpace(this.Location)) { return false; }

    if (!this.EstimatedStartOutput) { return false; }

    if (!this.EstimatedEndOutput) { return false; }

    if (new Date(this.EstimatedStartOutput) > new Date(this.EstimatedEndOutput)) { return false; }

    if (!this.Temperature || !Number.isInteger(+this.Temperature)) { return false; }

    return true;
  }

  save() {
    if (this.validate()) {
      const self = this;
      this.saving = true;
      this.service.save({
        id: this.Id,
        name: this.Phonenumber,
        description: this.Location,
        estimatedEnd: this.EstimatedEndOutput ?? new Date(),
        estimatedStart: this.EstimatedStartOutput ?? new Date(),
        temperature: this.Temperature,
        createdBy: this.getCurrentUser()?.id ?? -1,
        createdOn: new Date(),
        temperatureUnit: this.TemperatureUnit,
        temperatureNotes: this.TemperatureNotes,
        additionalNotes: this.AdditionalNotes,
      }).subscribe({
        next(returnedItem: Bakeout) {
          self.returnedItemEvent.emit(returnedItem);
        },
        error(msg) {
          console.log(msg);
        },
        complete() {
          self.modal.close();
          self.saving = false;
        }
      });
    }
  }

  ngOnInit() {
    this.initializeForm();
    if (this.dataItem) {
      this.subtitle = this.dataItem.id == 0 ? this.dataItem.name == "" ? "NEW" : "COPY" : "EDIT";
      this.Id = this.dataItem.id;
      // this.Username = this.dataItem.createdByUser;
      this.Username = this.subtitle == "COPY" ? this.getCurrentUser()?.name : this.dataItem.createdByUser;
      this.Phonenumber = this.dataItem.name;
      this.Location = this.dataItem.description;
      this.EstimatedStartOutput = moment(this.dataItem.estimatedStart).format("YYYY-MM-DD");
      this.EstimatedEndOutput = this.dataItem.estimatedEnd ? moment(this.dataItem.estimatedEnd).format("YYYY-MM-DD") : null;
      this.Temperature = this.dataItem.temperature;
      this.TemperatureUnit = this.dataItem.temperatureUnit;
      this.TemperatureNotes = this.dataItem.temperatureNotes;
      this.AdditionalNotes = this.dataItem.additionalNotes;
      // this.DateNow = moment(new Date).format("YYYY-MM-DDTkk:mm");
      this.DateNow = new Date();
    }
  }
  initializeForm() {
    this.bakeoutForm = this.formBuilder.group({
      phone: [{ value: '', disabled: this.saving }, [Validators.required, utils.IsWhiteSpaceReactiveForm]],
      location: [{ value: '', disabled: this.saving }, [Validators.required, utils.IsWhiteSpaceReactiveForm]],
      temperature: [{ value: '', disabled: this.saving }, Validators.required]
    });
  }

  changeDate(blur?: boolean) {
    this.DateNow = new Date();
    if (this.EstimatedStartOutput) {
      // if (new Date(this.EstimatedStartOutput) < new Date(this.DateNow) && blur)
      if (this.EstimatedStartOutput < this.DateNow && blur)
        this.EstimatedStartOutput = this.DateNow;
      if (this.EstimatedEndOutput) {
        // if (new Date(this.EstimatedEndOutput) < new Date(this.EstimatedStartOutput))
        if (this.EstimatedEndOutput < this.EstimatedStartOutput)
          this.EstimatedEndOutput = this.EstimatedStartOutput;
      }
    }
    if (!this.EstimatedStartOutput && this.EstimatedEndOutput) {
      // if (new Date(this.EstimatedEndOutput) < new Date(this.DateNow))
      if (this.EstimatedEndOutput < this.DateNow)
        this.EstimatedEndOutput = this.DateNow;
    }
  }

  numericOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 46) {
      return false;
    }
    return true;
  }

  override getErrorMsg(control: AbstractControl | null): string | null {
    if (control) {
      if (control.hasError("required")) return "You must enter a value";
      if (control.hasError("hasWhiteSpace")) return "Enter a valid value";
    }
    return null;
  }

  cancel() {
    const confirmation = this.dialog.open(PendingChangesDialogComponent, {
      data: {}
    });
    confirmation.afterClosed().subscribe((response: CanDeactivateResponse) => {
      switch (response) {
        case CanDeactivateResponse.Discard:
          this.modal.close();
          break;
      }
    });
  }

}
