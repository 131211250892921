import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { Observable, Subscription } from 'rxjs';
import { CalDueDateStatus, RadMonitor } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor';
import { MatTableDataSource } from '@angular/material/table';
import { FormStatusEnum, WFSaveOptions } from 'src/app/common/enumerations/enumerations';
import * as moment from 'moment';
import { css } from 'jquery';
import { cloneDeep } from 'lodash';
import { RadLocation } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'cl-rad-monitor-due-date',
  templateUrl: './cl-rad-monitor-due-date.component.html',
  styleUrls: ['./cl-rad-monitor-due-date.component.scss']
})
export class ClRadMonitorDueDateComponent extends ClBaseComponent implements OnInit, OnDestroy, OnChanges {

  radMonitors?: RadMonitor[];
  radMonitorsList?: RadMonitor[];
  radMonitors$!: Observable<RadMonitor[]>;
  radMonitorsSubs!: Subscription;

  radLocations?: RadLocation[];
  radLocationsList?: RadLocation[];
  radLocationsFiltered?: RadLocation[];
  radLocations$!: Observable<RadLocation[]>;
  radLocationsSubs!: Subscription;

  public dataSource: MatTableDataSource<RadMonitor> = new MatTableDataSource<RadMonitor>();
  public displayedColumns = ['ehs', 'locationType', 'location', 'description', 'dueDate'];

  globalStatus?: CalDueDateStatus;
  showWarning?: boolean;
  mode?: WFSaveOptions;
  questionSafe?: SafeHtml;

  saveEnabled?: boolean;
  valueObject?: any;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.valueObject = this.utils.JSONparse(this.valueString);
    this.checked = this.valueObject?.checked;
    this.mode = this.valueObject?.mode;
    this.user = this.valueObject?.user;
    this.questionSafe = this.sanitize(this.question);
    this.loadRadLocations();
  }

  override ngOnDestroy(): void {
    this.radMonitorsSubs?.unsubscribe();
    this.radLocationsSubs?.unsubscribe();
  }

  loadRadLocations() {
    this.radLocations$ = this.store.select(state => state.RadLocation.data);
    this.radLocationsSubs = this.radLocations$.subscribe(data => {
      if (data?.length) {
        this.radLocations = data;
        this.loadRadMonitors();
      }
    });
  }

  loadRadMonitors() {
    this.radMonitors$ = this.store.select(state => state.RadMonitor.data);
    this.radMonitorsSubs = this.radMonitors$.subscribe(data => {
      if (data?.length) {
        this.radMonitors = data;
        this.loadData();
      }
    });
  }

  canModify() {
    return this.editor;
  }

  checkboxChanged(e: any) {
    this.checked = e.checked;
    this.mode = this.checked ? WFSaveOptions.MANUAL : WFSaveOptions.AUTO;
    this.user = this.checked ? this.getCurrentUser() : null;
    this.saveData();
    if (!this.checked) {
      this.valueString = null;
      this.loadData();
    }
  }

  loadData() {
    if (this.configuration) {
      this.radMonitorsList = [];
      const radLocationsList = this.utils.JSONparse(this.configuration).data as RadLocation[];
      this.getRadMonitors(radLocationsList);
      // this.radMonitorsList?.sort((a, b) => this.utils.sort(a.ehs, b.ehs));
      this.dataSource = new MatTableDataSource(this.radMonitorsList);
      if (!this.disabled) this.checkStatus();
    }
    else {
      this.radMonitorsList = [];
    }
  }

  getRadMonitors(radLocationsList: RadLocation[]) {
    radLocationsList?.map(l => {
      var radMonitors: RadMonitor[] | undefined = [];
      if (this.editor || this.builder || (!this.disabled && (!this.valueString || this.valueObject.mode == WFSaveOptions.AUTO || (this.valueObject.mode == WFSaveOptions.MANUAL && this.checked)))) {

        radMonitors = this.radMonitors?.filter(x => x.radLocationID == l.id);
        this.saveEnabled = true;
        radMonitors?.map(r => {
          r.status = this.getStatus(r.calDueDate, r.status);
          r.cssClass = this.getCssClass(r.status);
          this.radMonitorsList?.push(r);
        });
      }
      else if (this.valueObject) {
        this.checked = this.valueObject.checked;
        this.radMonitorsList = this.valueObject.data;
        this.mode = this.valueObject.mode;
        this.radMonitorsList?.map(r => {
          r.radLocation = this.radLocations?.find(l => l.id == r.radLocationID);
        });
        this.user = this.valueObject.user;
      }
    });
  }

  getStatus(date?: Date | null, currentStatus?: CalDueDateStatus) {
    if (this.builder || this.editor || (!this.builder && !this.disabled)) {
      let status = CalDueDateStatus.Good;
      if (moment(date).isBefore(moment(new Date)))
        status = CalDueDateStatus.Expired;
      else if (moment(date).isBefore(moment(new Date).add(14, 'days')))
        status = CalDueDateStatus.CloseToExpire;
      return status;
    }
    else return currentStatus;
  }

  getCssClass(status?: CalDueDateStatus) {
    switch (status) {
      case CalDueDateStatus.Expired:
        return 'red';
      case CalDueDateStatus.CloseToExpire:
        return 'yellow';
      default:
        return 'green';
    }
  }

  checkStatus() {
    const checked = this.checked;
    if (this.radMonitorsList?.some(r => r.status == CalDueDateStatus.Expired)) {
      this.checked = false;
      this.user = null;
      this.showWarning = true;
      this.globalStatus = CalDueDateStatus.Expired;
    }
    else if (this.radMonitorsList?.some(r => r.status == CalDueDateStatus.CloseToExpire)) {
      if (!this.checked || this.user?.id == 1) {
        this.checked = false;
        this.user = null;
      }
      this.showWarning = true;
      this.globalStatus = CalDueDateStatus.CloseToExpire;
    }
    else {
      if (!this.checked) {
        this.checked = true;
        this.user = { id: -1, name: 'ASCC' };
      }
      this.showWarning = false;
      this.globalStatus = CalDueDateStatus.Good;
    }
    if (this.checked != checked && !this.builder && !this.editor && this.saveEnabled) {
      this.mode = WFSaveOptions.AUTO;
      this.saveData();
      this.saveEnabled = false;
    }
  }

  saveData() {
    const radMonitorsList: any[] = [];
    this.radMonitorsList?.map(r => {
      radMonitorsList.push(this.utils.Serialize(r));
    });
    const values = this.utils.JSONstringify({ data: radMonitorsList, checked: this.checked, user: this.user, isASCC: this.user?.id == -1, mode: this.mode });
    this.changed.emit(values);
  }
}
