<div class="pt-2 mt-3 pt-print-2">
  <div class="d-flex justify-content-between">
    <h5>RSS Functional Tests</h5>
    <span *ngIf="!hasChecklists" class="small yellow"
      >Tests will be available on form Activation</span
    >
  </div>
  <div
    class="test-list"
    [ngClass]="{ hilite: showAmendment && checkDifferences() }"
  >
    <mat-accordion class="headers-align" multi>
      <checklist-exec-standalone
        *ngFor="let rssFunctionalTest of rssFunctionalTests"
        [currentChecklist]="rssFunctionalTest?.checklist"
        [disabled]="rsswa?.status != formStatusEnum.Active"
      ></checklist-exec-standalone>
    </mat-accordion>
  </div>
</div>
