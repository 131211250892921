<div class="row pb-2 px-1">
  <div
    class="col-12 review-box"
    *ngFor="let reviewStatusAction of reviewStatusActions"
  >
    <div class="row">
      <div class="col-8 d-flex align-items-center">
        <button
          mat-flat-button
          class="ml-1"
          [ngClass]="getClass(reviewStatusAction.color)"
        >
          {{ reviewStatusAction.name }}
        </button>
      </div>
      <div class="col-4 pr-0 d-flex align-items-center justify-content-end">
        <button mat-icon-button (click)="edit(reviewStatusAction)">
          <mat-icon color="accent">edit</mat-icon>
        </button>
        <button mat-icon-button (click)="delete(reviewStatusAction)">
          <mat-icon color="warn">delete_outline</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 text-right">
    <button
      mat-flat-button
      color="primary"
      class="small-button"
      (click)="add()"
      *ngIf="reviewStatus?.id"
    >
      <mat-icon>add</mat-icon>Add
    </button>
  </div>
</div>
