<fieldset class="field-group">
  <legend class="field-group-label">Options</legend>
  <div class="row no-gutters pb-1">
    <div class="col-6">Label</div>
    <div class="col-5">Value</div>
    <div class="col-1 small">
      <div *ngIf="!disabled" class="btnx" (click)="add()">
        <mat-icon color="primary">add</mat-icon>
      </div>
    </div>
  </div>
  <div class="scrollbar max-200">
    <div class="row no-gutters pb-1" *ngFor="let o of tmpOptions">
      <div class="col-6 pr-1">
        <mat-form-field class="no-subscript">
          <input
            [disabled]="disabled ?? false"
            matInput
            type="text"
            [(ngModel)]="o.label"
            (keyup)="change()"
          />
        </mat-form-field>
      </div>
      <div class="col-5 pr-1">
        <mat-form-field class="no-subscript">
          <input
            [disabled]="disabled ?? false"
            matInput
            type="text"
            [(ngModel)]="o.value"
            (keyup)="change()"
          />
        </mat-form-field>
      </div>
      <div class="col-1 small">
        <div *ngIf="!disabled" class="btnx" (click)="del(o)">
          <mat-icon color="warn">delete</mat-icon>
        </div>
      </div>
    </div>
  </div>
</fieldset>
