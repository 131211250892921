import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges, ViewChild, Output, EventEmitter, Injector } from '@angular/core';
import { ReviewBoardType, ReviewBoard, Review, ReviewType, ReviewCycle } from '../reviews.models';
import { MatPaginator } from '@angular/material/paginator';
import { ReviewsBoardsReviewCreateComponent } from './reviews-boards-review-create/reviews-boards-review-create.component';
import { ReviewsBoardsAddResourceComponent } from './reviews-boards-reviews/reviews-boards-add-resource/reviews-boards-add-resource.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Observable, Subscription } from 'rxjs';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { Resource, ResourceRelation, ShutterResource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { ReviewsService } from '../reviews.service';
import { ResourceType, ReviewCycleStatus } from 'src/app/common/enumerations/enumerations';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ResourcesService } from '../../catalogs/beamline-catalog/resource/resources.service';
import { KEService } from '../../kenable/ke.service';

const ELEMENT_DATA: any[] = [];

@Component({
  selector: 'reviews-boards',
  templateUrl: './reviews-boards.component.html',
  styleUrls: ['./reviews-boards.component.scss']
})
export class ReviewsBoardsComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() reviewBoardType?: ReviewBoardType;
  @Input() resourcesFiltered!: Resource[];
  @Input() showOnlyActive!: boolean;

  @Output() selectedReview = new EventEmitter<Review | null>();
  @Output() setLoading = new EventEmitter<boolean>();

  @Input() review?: Review | null;
  reviewBoards?: ReviewBoard[];
  reviewBoards$!: Observable<ReviewBoard[]>;
  reviewBoardsSubs!: Subscription;

  reviews!: Review[];
  reviews$!: Observable<Review[]>;
  reviewsSubs!: Subscription;

  resourceRelation?: ResourceRelation;
  resourceRelations?: ResourceRelation[];
  resourceRelationsFiltered?: ResourceRelation[];
  resourceRelations$!: Observable<ResourceRelation[]>;
  resourceRelationsSubs!: Subscription;

  reviewCycles!: ReviewCycle[];
  reviewCyclesFiltered!: ReviewCycle[];
  reviewCycles$!: Observable<ReviewCycle[]>;
  reviewCyclesSubs!: Subscription;

  resourceCtrl = new FormControl();
  branchCtrl = new FormControl();
  resource!: Resource | null;
  resources!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  shutter?: ShutterResource | null;
  branchline?: Resource | null;
  allShutters: ShutterResource[] = [];
  allBranchlines: Resource[] = [];
  filteredShutters: ShutterResource[] = [];
  filteredBranchlines: Resource[] = [];
  branchlines?: Resource[] | null;
  showbranch!: boolean;

  reviewRequests$!: Observable<ReviewCycle[]>;
  reviewRequests!: ReviewCycle[];
  draftRequestsCount: number = 0;
  draftRequests!: ReviewCycle[];
  reviewRequestsFiltered!: ReviewCycle[];
  reviewRequest!: ReviewCycle;
  reviewRequestsSubs!: Subscription;
  moreInfo!: string;

  reviewTypes?: ReviewType[];
  createReady!: boolean;

  privilege = PrivilegeEnum;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  displayedColumns: string[] = ['Location'];
  columnDefs: any[] = [];
  data: any[] = ELEMENT_DATA;

  selectedRowIndex!: number;
  checked!: boolean;

  constructor(
    protected override injector: Injector,
    public service: ReviewsService,
    private resourcesService: ResourcesService,
    private keService: KEService
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.reviewBoardsSubs?.unsubscribe();
    this.reviewsSubs?.unsubscribe();
    this.reviewCyclesSubs?.unsubscribe();
    this.resourcesSubs?.unsubscribe();
    this.resourceRelationsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit() {
    this.service.Init();
    this.loadResourceRelations();
  }

  ngAfterViewInit(): void {
    if (this.redirect.document?.category == 3 && this.redirect.document.serialNo) {
      const element = document.getElementById(this.redirect.document.serialNo);
      this.redirect.document = null;
      const container = document.querySelector('.sidenav-content');
      if (element && container) {
        // Get the height of the container
        const containerHeight = container.clientHeight;

        // Get the position of the element relative to the container
        const elementRect = element.getBoundingClientRect();
        const elementTopRelativeToContainer = elementRect.top - container.getBoundingClientRect().top;

        // Calculate the scroll amount to center the element in the container
        const scrollAmount = elementTopRelativeToContainer - (containerHeight / 2) + (elementRect.height / 2);

        // Scroll the container by the calculated scroll amount
        container.scrollTop += scrollAmount;
        // element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.reviewTypes = this.reviewBoardType?.reviewTypes?.sort((a, b) => a.order - b.order);
    // this.reviewBoards = this.reviewBoardType?.reviewBoards?.filter(x => x.reviewBoardTypeID == this.reviewBoardType?.id && this.resourcesFiltered?.map(r => r.id).includes(x.resourceID)).sort((a, b) => this.utils.sort(a.resource?.name?.toLowerCase(), b.resource?.name?.toLowerCase(), true));
    this.loadReviewBoards();
    this.setLoadingBar(true);
    this.loadReviewCycles();
    this.loadReviewRequests();
  }

  loadReviewRequests() {
    this.reviewRequests$ = this.store.select(state => state.ReviewCycles.data);
    this.reviewRequestsSubs = this.reviewRequests$.subscribe(data => {
      this.reviewRequests = data.filter(x => !x.resourceID && x.status !== ReviewCycleStatus.Rejected && x.status !== ReviewCycleStatus.Pending && x.status != ReviewCycleStatus.Archived);
      this.draftRequests = data.filter(x => x.status == ReviewCycleStatus.Pending);
      this.draftRequestsCount = this.draftRequests.length;
      this.moreInfo = this.addMoreInfo();
    });
  }

  addMoreInfo(): string {
    let moreInfo = this.draftRequests.map(x => x.name).join(", ");
    return moreInfo;
  }

  loadReviewCycles() {
    this.reviewCycles$ = this.store.select(state => state.ReviewCycles.data);
    this.reviewCyclesSubs = this.reviewCycles$.subscribe(data => {
      if (data.length) {
        this.reviewCycles = data.filter(c => c.reviewBoard?.reviewBoardTypeID == this.reviewBoardType?.id || !c.reviewBoard);
      }
      this.loadReviewBoards();
    });
  }

  loadReviewBoards() {
    this.reviewBoards$ = this.store.select(state => state.ReviewBoards.data);
    this.reviewBoardsSubs = this.reviewBoards$.subscribe(data => {
      if (data?.length && this.resourcesFiltered) {
        this.reviewBoards = data.filter(x => x.reviewBoardTypeID == this.reviewBoardType?.id && this.resourcesFiltered?.map(r => r.id).includes(x.resourceID)).sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.resource?.name, b.resource?.name, 'asc', true));
        if (this.showOnlyActive && this.resourcesFiltered.length != 1) {
          this.reviewBoards = this.reviewBoards.filter(reviewBoard => reviewBoard.reviewCycles?.some(reviewCycle => (reviewCycle.status != ReviewCycleStatus.Archived)));
        }
        this.reviewBoards.map(b => b.reviewCycles = this.reviewCycles?.filter(c => c.resourceID == b.resourceID));
        this.loadReviews();
        this.loadResources();
      }
    });
  }

  loadReviews() {
    this.reviews$ = this.store.select(state => state.Reviews.data);
    this.reviewsSubs = this.reviews$.subscribe(data => {
      this.reviews = data;
      this.reviewBoards?.map(b => {
        b.reviewCycles?.map(c => {
          c.reviews = this.reviews.filter(r => r.reviewCycleID == c.id);
        });
      });
      this.setLoadingBar(false);
    });
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data?.length != 0) {
        //If BRC board, show branchlines in addition to beamlines and custom locations
        if (this.reviewBoardType?.id == 1)
          this.resources = data.filter((x) => x.type == this.resourceTypeEnum.Beamline || x.type == this.resourceTypeEnum.Branchline || x.type == ResourceType.ReviewProcessResource)
            .sort((a, b) => this.utils.SortBeamlines(a.name, b.name));
        else {
          this.resources = data.filter((x) => x.type == this.reviewBoardType?.resourceTypeID || x.type == ResourceType.ReviewProcessResource)
            .sort((a, b) => this.utils.SortBeamlines(a.name, b.name));
        }
        // this.resourcesFiltered = this.resources;
        this.reviewBoards?.filter(x => x.resource?.reviewRestrictedResourceID).map(x => {
          if (x.resource)
            x.resource.reviewRestrictedResource = data.find(y => y.id == x.resource?.reviewRestrictedResourceID);
        });
        this.setLoadingBar(false);
      }
    });
  }

  loadResourceRelations() {
    this.resourceRelations$ = this.store.select(state => state.ResourceRelations.data);
    this.resourceRelationsSubs = this.resourceRelations$.subscribe(data => {
      if (data?.length) {
        this.resourceRelations = data;
      }
    });
  }

  create(reviewBoard: ReviewBoard) {
    const dialog = this.dialog.open(ReviewsBoardsReviewCreateComponent, {
      width: '30%',
      minWidth: '400px',
      data: {
        reviewBoard
      },
      disableClose: true,
      autoFocus: false
    });
    dialog.afterClosed().toPromise();
  }

  hasReviews(reviews: Review[], reviewType: ReviewType) {
    return reviews.filter(x => x.reviewTypeID == reviewType.id).length != 0;
  }

  reviewSelected(review: Review) {
    this.review = review;
    this.selectedReview.emit(review);
  }

  reviewUpdated(review: Review | null) {
    this.review = review;
    this.selectedReview.emit(review);
  }

  setLoadingBar(val: boolean) {
    this.setLoading.emit(val);
  }

  checkPrivilege(privilege: PrivilegeEnum) {
    return this.service.checkPrivilege(privilege, this.reviewBoardType);
  }

  edit(resource?: Resource) {
    const dialog = this.dialog.open(ReviewsBoardsAddResourceComponent, {
      width: '30%',
      minWidth: '400px',
      disableClose: true,
      autoFocus: false,
      data: {
        reviewBoardType: this.reviewBoardType,
        resource,
        title: 'Edit Location'
      }
    });
    dialog.afterClosed().toPromise().then((data: Resource) => {
      const index = this.resourcesFiltered.findIndex(r => r.id == data.id);
      if (index >= 0)
        this.resourcesFiltered[index] = data;
      const reviewBoard = this.reviewBoards?.find(b => b.resourceID == data.id);
      if (reviewBoard) reviewBoard.resource = data;
    });
  }

  delete(resource?: Resource) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure you want to Delete this Location?',
        icon: 'stop'
      }
    });

    confirm.afterClosed().subscribe(data => {
      if (data) {
        const reviewBoardID = this.reviewBoards?.find(x => x.resourceID == resource?.id)?.id;
        if (reviewBoardID)
          this.service.deleteReviewBoard(reviewBoardID).toPromise();
      }
    });
  }

  pendingRequests(reviewBoard: ReviewBoard, created: boolean) {
    let result = 0;
    if (reviewBoard.reviewCycles) {
      if (created) {
        result = reviewBoard.reviewCycles.filter(x => x.reviews.length > 0 && x.status !== ReviewCycleStatus.Rejected && x.status !== ReviewCycleStatus.Pending && x.status != ReviewCycleStatus.Archived).length;
      }
      else {
        result = reviewBoard.reviewCycles.filter(x => x.reviews.length == 0 && x.status !== ReviewCycleStatus.Rejected && x.status !== ReviewCycleStatus.Pending && x.status != ReviewCycleStatus.Archived).length;
      }
    }
    return result;
  }

  pendingCustom() {
    let result = 0;
    result = this.reviewRequests.length;
    return result;
  }

  showLabel() {
    //get width of locDiv
    const locDiv = $("#locDiv").width();
    return locDiv ? +locDiv > 190 : false;
  }

  shutterExists(reviewBoard: ReviewBoard) {
    if (this.resourceRelations?.find(x => x.childResourceID == reviewBoard.resourceID && (x.parentResourceType == this.resourceTypeEnum.Shutter))?.parentResourceID) {
      return true;
    }
    else if (this.resources?.find(x => x.id == reviewBoard.resourceID)?.type == this.resourceTypeEnum.Branchline) {
      return true;
    }
    else {
      return false;
    }
  }

  sliderChanged(e: any, reviewBoard: ReviewBoard) {
    if (reviewBoard.resource) {
      reviewBoard.resource.keRestricted = e.checked;
      if (reviewBoard.resource.keRestricted) {
        const confirm = this.dialog.open(YesNoDialogComponent, {
          width: '400px',
          data: {
            message: this.getMessage('BRC_KE_Restrict')?.description.replace('{location}', reviewBoard?.resource?.name ?? '')
          }
        });
        confirm.afterClosed().subscribe(async data => {
          if (data) {
            if (reviewBoard.resource) {
              this.setLoadingBar(true);
              if (reviewBoard.resource.type == this.resourceTypeEnum.Beamline) {
                reviewBoard.resource.reviewRestrictedResourceID = this.resourceRelations?.find(x => x.childResourceID == reviewBoard.resourceID && x.parentResourceType == this.resourceTypeEnum.Shutter)?.parentResourceID;
                reviewBoard.resource.reviewRestrictedResource = this.resources.find(x => x.id == reviewBoard.resource?.reviewRestrictedResourceID);
              }
              else if (reviewBoard.resource.type == this.resourceTypeEnum.Branchline) {
                reviewBoard.resource.reviewRestrictedResourceID = reviewBoard.resourceID;
                reviewBoard.resource.reviewRestrictedResource = this.resources.find(x => x.id == reviewBoard.resource?.reviewRestrictedResourceID);
              }
              await this.resourcesService.saveResource(reviewBoard.resource).toPromise();
              if (reviewBoard.resource.reviewRestrictedResourceID)
                await this.keService.processKES([reviewBoard.resource.reviewRestrictedResourceID]);
              this.setLoadingBar(false);
            }
          }
          else {
            if (reviewBoard.resource) {
              reviewBoard.resource.keRestricted = !reviewBoard.resource.keRestricted;
            }
          }
        });
      }
      else {
        const confirm = this.dialog.open(YesNoDialogComponent, {
          width: '400px',
          data: {
            message: this.getMessage('BRC_KE_Unrestrict')?.description.replace('{location}', reviewBoard?.resource?.name ?? '')
          }
        });

        confirm.afterClosed().subscribe(async data => {
          if (data) {
            if (reviewBoard.resource) {
              this.setLoadingBar(true);
              if (reviewBoard.resource.reviewRestrictedResourceID)
                await this.keService.processKES([reviewBoard.resource.reviewRestrictedResourceID]);
              reviewBoard.resource.reviewRestrictedResourceID = 0;
              await this.resourcesService.saveResource(reviewBoard.resource).toPromise();
              this.setLoadingBar(false);
            }
          }
          else {
            if (reviewBoard.resource) reviewBoard.resource.keRestricted = !reviewBoard.resource.keRestricted;
          }
        });
      }
    }
  }

  changedResource(e: any) {
    const val = e.target.value as string;
    this.resourcesFiltered = this.resources.filter((x) => x.name?.toLowerCase().includes(val.toLowerCase()));
    this.resource = null;
  }

  selectedResource(e: any) {
    this.resource = e.option.value;
    if (this.resource)
      this.resourcesFiltered = [this.resource];
  }

  clearFilter() {
    this.resource = null;
    this.resourceCtrl.setValue(this.resource);
    this.resourcesFiltered = this.resources;
  }

  clearShutter() {
    this.shutter = null;
    this.filteredShutters = this.allShutters;
    this.showbranch = false;
    this.clearBranch();
  }

  clearBranch() {
    this.branchline = null;
    this.branchCtrl.setValue(this.branchline);
    this.filteredBranchlines = this.allBranchlines;
  }

  onLocChange(e: any) {
    if (!e.code?.includes('Arrow')) {
      this.filteredShutters = this.allShutters.filter(loc => loc.name.toLowerCase().includes(e.target.value.trim().toLowerCase()));
    }
  }

  selectedLoc(event: MatAutocompleteSelectedEvent, reviewBoard: ReviewBoard) {
    this.shutter = event.option.value;
    if (this.shutter) {
      this.filteredShutters = [this.shutter];
      this.showbranch = true;
    }
  }

  selectedBranch(e: any) {
    this.branchline = e.option.value;
    if (this.branchline)
      this.filteredBranchlines = [this.branchline];
  }

}
