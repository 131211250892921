import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { APLCategory } from 'src/app/components/apl/services/apl-category.model';
import { CatalogService } from 'src/app/components/catalogs/catalog-service';
import { PendingCatalogType } from 'src/app/services/pending-approvals/pending-approval';

@Component({
  selector: 'apl-category-v2-list',
  templateUrl: './apl-category-v2-list.component.html',
  styleUrls: ['./apl-category-v2-list.component.scss']
})
export class AplCategoryV2ListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() filter?: string;
  @Input() aplCategories?: APLCategory[];

  id: string | null = null;

  displayedColumns: string[] = ['name'];
  dataSource!: MatTableDataSource<APLCategory>;

  constructor(private route: ActivatedRoute, private catalog: CatalogService) { }
  ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = new MatTableDataSource(this.aplCategories);
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id)
        this.openCategory(+id);
      else if (this.catalog.pendingCatalog?.approvalType == PendingCatalogType.APLCategories) {
        this.openCategory(this.catalog.pendingCatalog.id);
      }
      else if (this.catalog.pendingCatalog?.approvalType == PendingCatalogType.APLGroups) {
        const aplCategory = this.aplCategories?.find(c => c.aplGroups?.find(g => g.id == this.catalog.pendingCatalog?.pendingID));
        if (aplCategory)
          this.openCategory(aplCategory.id);
      }
    });
  }

  ngOnInit(): void {

  }

  openCategory(aplCategoryID: number) {
    setTimeout(() => {
      const aplCategory = this.aplCategories?.find(c => c.id == aplCategoryID);
      if (aplCategory) {
        aplCategory.open = true;
        const divElement = document.getElementById(aplCategory.id.toString());
        if (divElement)
          divElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  }
}
