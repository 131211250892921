import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Input, Output, EventEmitter, OnDestroy, OnInit, OnChanges, AfterViewInit, HostListener, Injector, SimpleChanges, } from "@angular/core";
import { FormGroup, AbstractControl } from "@angular/forms";
import { MatDialogConfig } from "@angular/material/dialog";
import { Observable, Subscription } from "rxjs";
import { BaseComponent } from "src/app/common/base/base.component";
import { FormType } from "src/app/common/enumerations/enumerations";
import { ChecklistBuilderService } from "src/app/components/checklists/checklist-builder/checklist-builder.service";
import { ScheduleResource } from "src/app/components/schedules/models/schedule-resources";
import { ScheduleType } from "src/app/components/schedules/models/schedule-type";
import { ScheduleTypeService } from "src/app/components/schedules/services/schedule-type.service";
import { ScheduleService } from "src/app/components/schedules/services/schedule.service";
import { WFComponent } from "src/app/components/workflow/workflow";
import { ClEditorComponent } from "src/app/controls/checklist-components/cl-editor/cl-editor.component";
import { ShutterResource } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { WfSignatureResource, WfSignature, WFTaskSignatureResource, WfTaskLocalResource } from "src/app/services/work-flow/work-flow";
import { KEVersionResource } from "../../ke";
import { KeChecklistBuilderService } from "../ke-checklist-builder.service";


@Component({
  selector: "ke-cl-builder-section",
  templateUrl: "./ke-cl-builder-section.component.html",
  styleUrls: ["./ke-cl-builder-section.component.scss"],
})
export class KeClBuilderSectionComponent extends BaseComponent implements OnDestroy, OnInit, OnChanges, AfterViewInit {
  public chbTypes: Array<number> = [4, 5];
  public activeVersion!: boolean;

  controls!: WFComponent[];

  @Input() section!: number;
  @Input() shutter!: ShutterResource | null;
  @Input() serialNo?: string | null;
  @Input() keVersion?: KEVersionResource | null;
  @Input() disabled?: boolean;
  @Output() refreshHead = new EventEmitter();

  currentDocument: any;
  sectionName!: string | null;

  tmpText!: string;
  tmpText2!: string;
  tmpText3!: string;
  tmpText4!: string;

  loading!: boolean;
  adding!: boolean;
  editing!: boolean;
  editingId!: number;

  wfTableId!: number;
  wfStepLocalId!: number;

  wfSignatures!: WfSignatureResource[];
  Signatures!: WfSignature[];
  wfTaskSignature: WFTaskSignatureResource = new WFTaskSignatureResource();

  approvals!: number;

  scheduleTypes: ScheduleType[] = [];
  scheduleType!: number;
  scheduleTypeEdit!: number;

  components!: WFComponent[];
  components$!: Observable<WFComponent[]>;
  componentsSubs!: Subscription;

  tmpSchedules: ScheduleResource[] = [];
  tmpSchedulesFiltered: ScheduleResource[] = [];
  scheduleResource: ScheduleResource = new ScheduleResource();
  scheduleResourceEdit: ScheduleResource = new ScheduleResource();
  tmpSchedId!: number;

  controlOptions: any[] = [
    { label: "Checkbox", value: 4 },
    { label: "Note", value: 7 },
    { label: "Checkbox & Text", value: 8 },
    { label: "Schedule & Checkbox", value: 9 },
  ];

  radioSelection!: number;

  keForm!: FormGroup;
  keFormEdit!: FormGroup;

  get scheduleTypeForm(): AbstractControl | null {
    return this.keForm.get("scheduleTypeForm");
  }
  get locationsForm(): AbstractControl | null {
    return this.keForm.get("locationsForm");
  }
  get scheduleTypeFormEdit(): AbstractControl | null {
    return this.keFormEdit.get("scheduleTypeFormEdit");
  }
  get locationsFormEdit(): AbstractControl | null {
    return this.keFormEdit.get("locationsFormEdit");
  }

  constructor(
    protected override injector: Injector,
    private _keBuilder: KeChecklistBuilderService,
    private _gBuilder: ChecklistBuilderService,
    private _scheduleTypeService: ScheduleTypeService,
    private _scheduleService: ScheduleService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.wfTableId != this.keVersion?.wfTableID)
      this.refresh(this.keVersion?.wfTableID);
  }

  ngOnInit() {
    this.getScheduleTypes();
    this.getControls();
  }

  override ngOnDestroy(): void {
    this.componentsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  getControls() {
    this.components$ = this.store.select((state) => state.Components.data);
    this.componentsSubs = this.components$.subscribe((data) => {
      this.controls = data;
    });
  }

  ngAfterViewInit() { }

  create() {
    this.wfSignatures = [];
    this.Signatures = [];
    this.sectionName = null;
  }

  refresh(wfTableId: number | undefined | null) {
    if (wfTableId) {
      this.wfTableId = wfTableId;

      if (this.wfTableId === undefined || this.wfTableId === 0) {
        this.create();
        return;
      }
      this.loading = true;

      this._keBuilder
        .ReadWFBySection(this.wfTableId, this.section)
        .subscribe((data) => {
          this.wfSignatures = data;

          this.wfStepLocalId =
            data[0] && data[0].wfTaskLocal && data[0].wfTaskLocal.wfTasks[0]
              ? data[0].wfTaskLocal.wfTasks[0].wfStep.wfStepLocalID
              : 0;

          this.sectionName = this.wfSignatures[0]
            ? this.wfSignatures[0].sectionName
            : null;

          let index = 1;
          const isOrdered = this.checkOrders();

          this.Signatures = [];
          this.approvals = this.wfSignatures.length;
          this.wfSignatures.map((t) => {
            const options: any[] = t.options ? JSON.parse(t.options) : [];
            const sectionNumber = this.section + "." + index;
            const signature: WfSignature = {
              id: t.id,
              number: t.required ? sectionNumber : null,
              approveId: t.approveID,
              unapproveId: t.unapproveID,
              disapproveId: t.disapproveID,
              amendmentId: t.amendmentID,
              name: t.titleBefore,
              question: t.question,
              question2: t.titleAfter,
              question3: t.titleAfter2,
              roles: t.roles.map((r) => r.id),
              roleCodes: t.roles.map((r) => r.code).join("/"),
              disabled: true,
              approvedBy: t.signedBy,
              approved: t.signed,
              approvedOn: t.signedOn,
              approve: t.approveEnabled,
              unapprove: t.unapproveEnabled,
              disapprove: t.disapproveEnabled,
              amendment: t.amendmentEnabled,
              required: t.required,
              taskId: t.taskID,
              value: t.value,
              value2: t.numericValue,
              type: t.type,
              options,
              sectionName: t.sectionName,
              iterable:
                t.iterable &&
                (t.wfTaskLocal.name3?.includes("{KEStep}") ||
                  t.wfTaskLocal.name2?.includes("{vacuum}")),
              order: t.order,
              textValue: t.text,
              visible: true,
              resourceId: t.resourceId,
              scheduleResourceId: t.scheduleResourceId,
              scheduleTypeId: t.scheduleResourceId
                ? this.scheduleTypes.find((x) => x.acronym === t.scheduleName)?.id
                : 0,
              dateValue1: t.dateValue1,
              dateValue2: t.dateValue2,
              dateValue3:
                t.dateValue3 &&
                  t.dateValue3.toString() !== "0001-01-01T00:00:00"
                  ? t.dateValue3
                  : null,
              scheduleName: t.scheduleName,
              locationName: t.locationName,
              moreInfo: t.moreInfo ? JSON.parse(t.moreInfo).join("\n") : null,
              configuration: t.configuration,
              condition: t.condition,
              code: t.code,
            };
            index++;
            this.Signatures.push(signature);
          });

          this.renumberArray(false);
          this.loading = false;
        });
    }
    else this.Signatures = [];
  }

  checkOrders() {
    const order = this.wfSignatures.map((x) => x.order);

    let i = 0;
    let result = true;

    order.map((o) => {
      if (o !== i + 1) {
        result = false;
      }
      i++;
    });

    return result;
  }

  edit(id?: number) {
    let signature: WfSignature = {
      name: "[New Name]",
      options: [],
      roles: [],
      required: true,
      approve: true,
      unapprove: true,
      order: (this.Signatures?.[this.Signatures.length - 1]?.order ?? 0) + 1,
    };

    if (id) {
      const s = this.Signatures.find((x) => x.id === id);
      if (s) signature = s;
      const wfSignature = this.wfSignatures.find((x) => x.id == id);
      signature.name = wfSignature?.wfTaskLocal.name;
      signature.question = wfSignature?.wfTaskLocal.name3;
      signature.question2 = wfSignature?.wfTaskLocal.name2;
      signature.question3 = wfSignature?.wfTaskLocal.name4;
    }
    signature.stepId = this.wfStepLocalId;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      s: signature,
      roles: this.wfSignatures.find((x) => x.id === signature.id)?.roles,
      formType: FormType[FormType.KE],
      serialNo: this.serialNo,
      checklistTemplate: this.keVersion,
      disabled: this.disabled,
    };
    this.innerWidth > 768
      ? (dialogConfig.width = "60vw")
      : (dialogConfig.width = "80vw");
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ClEditorComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data) {
          if (id) {
            this._gBuilder.updateWFTaskLocal(data).subscribe(
              (data: WfTaskLocalResource) => {
                this.refresh(this.wfTableId);
                // this.refreshHead.emit();
              },
              (error) => {
                console.log(error);
                this.alert.defaultError();
              }
            );
          } else {
            this._gBuilder.createWFTaskLocal(data).subscribe(
              (data: WfTaskLocalResource) => {
                this.refresh(this.wfTableId);
                this.refreshHead.emit();
              },
              (error) => {
                console.log(error);
                this.alert.defaultError();
              }
            );
          }
        }
      });
  }

  delete(id?: number) {
    const taskLocal = this.wfSignatures.find((x) => x.id == id)?.wfTaskLocal;
    if (taskLocal)
      this._gBuilder.deleteTask(taskLocal).subscribe(() => {
        this.refresh(this.wfTableId);
        this.refreshHead.emit();
      });
  }

  changeRequired(e: any, taskId?: number) {
    const required = e.checked;
    this.renumberArray(true);
    if (taskId)
      this._keBuilder.SaveRequired(taskId, required).subscribe(() => {
        this.refreshHead.emit();
      });
  }

  changeOrder(save: boolean) {
    this.Signatures.map((s) => {
      if (s.taskId && s.order)
        this._keBuilder.SaveOrder(s.taskId, s.order, save).subscribe(() => { });
    });
  }

  renumberArray(save_last_updater: boolean) {
    let index = 1;
    let order = 1;
    this.Signatures.map((s) => {
      const control = this.controls.find((c) => c.id === s.type);
      s.order = order;
      order++;

      if (s.required && control?.numericHeader) {
        s.number = this.section + "." + index;
        index++;
      } else {
        s.number = null;
      }
    });
    this.changeOrder(save_last_updater);
  }

  reorderTable(event: CdkDragDrop<WfSignature[]>) {
    if (event?.previousIndex !== event?.currentIndex) {
      moveItemInArray(this.Signatures, event.previousIndex, event.currentIndex);
      this.renumberArray(true);
    }
  }

  click() { }

  getScheduleTypes() {
    this._scheduleTypeService.getAll().subscribe((data) => {
      this.scheduleTypes = data as ScheduleType[];
    });
  }

  async getSchedules(edit: boolean, scheduleType: number) {
    this.tmpSchedules = [];
    this.scheduleResource = new ScheduleResource();
    this.loading = true;
    if (scheduleType) {
      if (!edit) {
        this.locationsForm?.enable();
      } else {
        this.locationsFormEdit?.enable();
      }
      await this._scheduleService
        .readByType(scheduleType)
        .toPromise()
        .then((data) => {
          data?.map((schedule) => {
            if (schedule.statusId !== 2) {
              schedule.scheduleResources.map((resource) => {
                const scheduleResource: ScheduleResource = {
                  id: resource.id,
                  name: resource.resourceId
                    ? resource.resource?.name
                    : resource.resourceString,
                  resourceId: resource.resourceId ? resource.resourceId : 0,
                };
                this.tmpSchedules.push(scheduleResource);
              });
            }
          });
          const sched1 = this.tmpSchedules
            .filter((x) => x.resourceId !== 0)
            .sort((a, b) => this.utils.SortBeamlines(a.name, b.name));
          const sched2 = this.tmpSchedules.filter((x) => x.resourceId === 0);
          this.tmpSchedules = sched1.concat(sched2);
          this.tmpSchedulesFiltered = this.utils.cloneDeep(this.tmpSchedules);
          this.loading = false;
        });
    }
  }

  @HostListener("window:resize")
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
