import { EventEmitter, Injectable, Injector } from "@angular/core";

import { Observable, Subscription } from "rxjs";
import { FormType, NotificationType, Roles, WorkRequired } from "src/app/common/enumerations/enumerations";
import { WFTaskSignatureResource, SaveTaskResource } from "src/app/services/work-flow/work-flow";
import { SCFMaster, SaveSCFStatusResource, SCFHoldPointResource } from "./scf";
import { SiteConfigurationService } from "src/app/services/site-configuration/site-configuration-service";
import { OLogEntry, OLogFormData, OlogDataBaseObject } from "src/app/components/olog/olog";
import { OLogService } from "src/app/components/olog/olog.service";
import { NotificationService } from "src/app/components/catalogs/notifications/services/notification.service";
import { NotificationsToAdress } from "src/app/components/catalogs/notifications/services/notification.models";
import { utils } from "src/app/modules/libs/utils";
import { UserService } from "../catalogs/user-catalog/services/user.service";
import { BaseService } from "src/app/common/base/base.service";
import { ResourcesService } from "../catalogs/beamline-catalog/resource/resources.service";

import { User } from "../catalogs/user-catalog/services/user";

@Injectable({
  providedIn: "root",
})
export class ScfV2Service extends BaseService<SCFMaster, number> {

  CheckSelectedSection1: EventEmitter<any> = new EventEmitter<any>();
  SCFediting?: number | null = null;
  AmendmentSwitch = false;
  amendmentsList: number[] = [];
  copyDoc: SCFMaster | null = null;
  SerialNumber = "/SerialNumbers/" + FormType.SCF;
  Locations = "/Locations";
  Api = "/SCFMasterV2";
  ApiRestrictions = "/SCFRestriction";
  user?: User;
  users: User[] = [];
  users$!: Observable<User[]>;
  usersSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private siteConfiguration: SiteConfigurationService,
    private resourcesService: ResourcesService,
    private ologService: OLogService,
    private userService: UserService,
    private notificationService: NotificationService,
    private siteConfigurationService: SiteConfigurationService,
  ) {
    super(injector, '/SCFMasterV2');
  }

  // Gets the Serial Number of the Form
  getSerialNo(): Observable<SerialNumber> {
    const httpresult1 = this.http.get<SerialNumber>(
      this.BASE_URL + this.SerialNumber
    );
    return httpresult1;
  }

  post(scfMaster: SCFMaster) {
    return this.http.post<SCFMaster>(this.BASE_URL + this.Api, scfMaster);
  }

  put(scfMaster: SCFMaster) {
    return this.http.put<SCFMaster>(
      this.BASE_URL + this.Api + "/" + scfMaster.id,
      scfMaster
    );
  }

  cancel(scfMaster: SCFMaster, reason: string) {
    return this.http.patch(this.BASE_URL + this.Api + "/cancel", {
      id: scfMaster.id,
      reason,
    });
  }

  cancelAmendment(scfMaster: SCFMaster, reason: string) {
    return this.http.patch(this.BASE_URL + this.Api + "/cancelAmendment", {
      id: scfMaster.id,
      reason,
    });
  }

  sign(taskSignature: WFTaskSignatureResource): Observable<SCFMaster> {
    const url: string = this.BASE_URL + this.Api + "/Sign";
    const httpresult = this.http.put<any>(url, taskSignature);
    return httpresult;
  }

  save(s: SaveTaskResource) {
    const httpresult = this.http.put<any>(
      this.BASE_URL + this.Api + "/Save/" + s.id,
      s
    );
    return httpresult;
  }

  saveTasks(saveTasks: SaveTaskResource[]) {
    const httpresult = this.http.put<any>(
      this.BASE_URL + this.Api + "/SaveTasks",
      saveTasks
    );
    return httpresult;
  }

  copySCF(id: number, isAmendment: boolean): Observable<SCFMaster> {
    return this.http.post<SCFMaster>(
      this.BASE_URL + this.Api + "/copy/" + id + "/" + isAmendment,
      null
    );
  }

  putStatus(saveScfStatus: SaveSCFStatusResource) {
    return this.http.put<SCFMaster>(
      this.BASE_URL + this.Api + "/SetStatus/" + saveScfStatus.id,
      saveScfStatus
    );
  }

  setEditing(id: number, value?: boolean): Observable<number> {
    const URL = this.BASE_URL + this.Api + "/SetEditing/" + id;
    const httpresult2 = this.http.put<number>(URL, { Value: value });
    return httpresult2;
  }

  // Amendments
  requestAmendment(id: number): Observable<any> {
    return this.http.put<any>(
      this.BASE_URL + this.Api + "/RequestAmendment/" + id.toString(),
      null
    );
  }

  finishAmendment(id: number): Observable<any> {
    return this.http.put<any>(
      this.BASE_URL + this.Api + "/FinishAmendment/" + id.toString(),
      null
    );
  }

  async getEmails(scf: SCFMaster) {
    const notifiedRoleIDs: number[] = [];
    const emails: string[] = [];
    const siteConfig = await this.siteConfiguration.getFirst().toPromise();
    // Default
    if (
      siteConfig?.defaultEmails &&
      siteConfig?.defaultEmails.trim().length > 0
    ) {
      const defaultEmails = siteConfig.defaultEmails?.split(",")
        .map((x) => x.trim());
      defaultEmails?.map((e) => {
        utils.pushNoRepeat(emails, e.trim());
      });
    }
    // HP
    if (scf.notifyHP) {
      const emailsHP = siteConfig?.scfhpNotificationEmails?.split(",");
      emailsHP?.map((e) => {
        utils.pushNoRepeat(emails, e.trim());
      });
    }
    // BLSCI
    if (scf.notifyBLSci) {
      const notifiedUsers: number[] = [];
      scf.locations?.map((loc) => notifiedUsers.push(loc.id ?? 0));
      const relUsers = await this.resourcesService
        .getResourceUserRelationsById(notifiedUsers)
        .toPromise();
      relUsers?.map((related) => {
        if (
          related.roleId == Roles.BLSCI ||
          related.roleId === Roles.ALTBLSCI
        ) {
          if (
            related?.user?.emailAddress &&
            related?.user.emailAddress.trim() !== ""
          ) {
            utils.pushNoRepeat(emails, related.user.emailAddress);
          }
        }
      });
    }
    if (scf.notifyBRC) {
      notifiedRoleIDs.push(Roles.BRC);
    }
    if (scf.notifyRF) {
      notifiedRoleIDs.push(Roles.RFEng);
    }
    if (scf.notifySnA) {
      notifiedRoleIDs.push(Roles.SnA);
    }
    if (scf.notifyARC) {
      notifiedRoleIDs.push(Roles.ARC);
    }
    // RP
    if (scf.notifyRP) {
      const emailsRP = siteConfig?.scfrpNotificationEmails?.split(",");
      emailsRP?.map((e) => {
        utils.pushNoRepeat(emails, e.trim());
      });
    }

    // By Role
    const users = await this.userService
      .ReadByRole(notifiedRoleIDs)
      .toPromise();
    users?.map((e) => {
      if (e.emailAddress && e.emailAddress.trim() !== "") {
        utils.pushNoRepeat(emails, e.emailAddress.trim());
      }
    });

    // SCF All
    if (siteConfig?.scfNotificationEmails?.length) {
      const emailsALL = siteConfig.scfNotificationEmails?.split(",");
      emailsALL?.map((e) => {
        utils.pushNoRepeat(emails, e.trim());
      });
    }

    // Requester
    this.users$ = this.store.select((state) => state.Users.data);
    this.usersSubs = this.users$.subscribe((data) => {
      this.users = data;
      this.user = this.users.find((x) => x.id == scf.requesterId);
      utils.pushNoRepeat(emails, this.user?.emailAddress?.trim());
    });

    return emails;
  }

  // Hold Points
  createHoldPoint(
    holdPoint: SCFHoldPointResource
  ): Observable<SCFHoldPointResource> {
    return this.http.post<SCFHoldPointResource>(
      this.BASE_URL + this.Api + "/CreateHoldPoint",
      holdPoint
    );
  }

  updateHoldPoint(
    holdPoint: SCFHoldPointResource
  ): Observable<SCFHoldPointResource> {
    return this.http.put<SCFHoldPointResource>(
      this.BASE_URL + this.Api + "/UpdateHoldPoint",
      holdPoint
    );
  }

  updateHoldPointNumbers(holdPoints: SCFHoldPointResource[]): Observable<SCFHoldPointResource> {
    return this.http.put<SCFHoldPointResource>(this.BASE_URL + this.Api + "/UpdateHoldPointNumbers", holdPoints);
  }

  deleteHoldPoint(holdPoint: SCFHoldPointResource) {
    return this.http.delete(
      this.BASE_URL + this.Api + "/DeleteHoldPoint/" + holdPoint.id
    );
  }

  // async postOlog(scfMaster: SCFMaster, message: string): Promise<boolean | null> {
  //   try {
  //     if (scfMaster?.id) {
  //       const olog: OlogDataBaseObject | undefined = await this.ologService.getOlogBySN(scfMaster.serialNo).toPromise();
  //       if (olog) {
  //         const noti = await this.notificationService.getNotification(NotificationType.CreateSCF, scfMaster.id).toPromise();
  //         let cc: any[] = [];
  //         if (noti) {
  //           const notificationsMails: NotificationsToAdress = JSON.parse(noti.toAddresses);
  //           Array.prototype.push.apply(cc, notificationsMails.computed);
  //           Array.prototype.push.apply(cc, notificationsMails.added);
  //         }
  //         const body: OLogFormData = JSON.parse(olog.xmlBody.replace('\n', ''));
  //         body.subject = olog.serialNo + message;
  //         body.details = location.origin + '/redirect/' + olog.serialNo;;
  //         body.extraSubject = null;
  //         const siteConfig = await this.siteConfigurationService.getById(1).toPromise();
  //         const siteConfigEmails = siteConfig?.defaultEmails.split(',');
  //         if (siteConfig && siteConfigEmails) {
  //           Array.prototype.push.apply(cc, siteConfigEmails);
  //           const OlogObject: OLogEntry = {
  //             root: {
  //               author: siteConfig.ologUser,
  //               password: siteConfig.ologPassword,
  //               entry: body
  //             }
  //           };
  //           if (OlogObject.root.entry.cc)
  //             Array.prototype.push.apply(cc, OlogObject.root.entry.cc); // join all the emails
  //           cc = cc.map(item => item.toLowerCase()); // change if it is necesary to lowercase (Specialy because Notifications don't make this)
  //           cc = [...new Set(cc)]; // Remove duplicates
  //           cc = cc.filter(mail => mail !== '');
  //           OlogObject.root.entry.cc = cc; // returns the uniques emails
  //           const xmlBody = await this.ologService.createXMLBody(OlogObject, scfMaster.serialNo); // create the XML String from the body
  //           const result = await this.ologService.SendOLogEntry(xmlBody, scfMaster.serialNo, olog);
  //           return result;
  //         }
  //       }
  //     }
  //     return null;
  //   } catch (e) {
  //     this.alert.message('OlogEntryError');
  //     return false;
  //   }
  // }
}

class SerialNumber {
  serialNo!: string;
}

export class SCFPrivileges {
  canCreate!: boolean | null;
  canEdit!: boolean | null
  canSubmit!: boolean | null
  canUnsubmit!: boolean | null
  canCancel!: boolean | null
  canViewAmendment!: boolean | null
  canCreateAmendment!: boolean | null
  canEditAmendment!: boolean | null
  canSubmitAmendment!: boolean | null
  canUnsubmitAmendment!: boolean | null
  canCancelAmendment!: boolean | null
  canDuplicate!: boolean | null
  canRelease!: boolean | null
  canAddHoldPoints!: boolean | null
  canAddHoldPointsDraft!: boolean | null
}

export class SCFStatus {
  isCreating!: boolean | null
  isEditing!: boolean | null
  isViewingAmendment!: boolean | null
  isCreatingAmendment!: boolean | null
  isEditingAmendment!: boolean | null
}

export class SCFWorkRequired {
  workRequired?: WorkRequired;
  checked!: boolean | null
}
