import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TagInfoData } from '../cl-editor-tag-info/cl-editor-tag-info.component';

@Component({
  selector: 'app-cl-editor-conditions-help',
  templateUrl: './cl-editor-conditions-help.component.html',
  styleUrls: ['./cl-editor-conditions-help.component.scss']
})
export class ClEditorConditionsHelpComponent {

  constructor(
    public dialog: MatDialogRef<ClEditorConditionsHelpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TagInfoData) {
    // this.returnType = ReturnType[data.component.returnType ?? -1];
    // this.tag = data.tag ? data.tag : '<b class="red">{Tag}</b>';
  }

  close() {
    this.dialog.close();
  }

}
