import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ReviewCyclesCreate, ReviewCyclesDelete, ReviewCyclesRefresh, ReviewCyclesUpdate, ReviewsCyclesRefreshById } from './review-cycles.action';
import { ReviewsService } from '../../reviews.service';
import { ReviewCycle } from '../../reviews.models';

export class ReviewCyclesStateModel {
  data: ReviewCycle[] = [];
}

@State<ReviewCyclesStateModel>({
  name: 'ReviewCycles',
  defaults: {
    data: []
  }
})
@Injectable()
export class ReviewCyclesState {
  constructor(
    private _service: ReviewsService
  ) { }

  @Selector()
  static read(state: ReviewCyclesStateModel) {
    return state.data;
  }

  @Action(ReviewCyclesRefresh)
  refresh({ patchState }: StateContext<ReviewCyclesStateModel>) {
    this._service.getReviewCycles().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ReviewsCyclesRefreshById)
  refreshById({ getState, patchState }: StateContext<ReviewCyclesStateModel>, { id }: ReviewsCyclesRefreshById) {
    this._service.getReviewCycleById(id).subscribe(item => {
      const state = getState();
      const index = state.data.map(x => x.id).indexOf(id);
      if (index >= 0) {
        state.data.splice(index, 1);
      }
      patchState({
        data: [...state.data, item]
      });
    },
      error => console.error(error)
    );
  }

  @Action(ReviewCyclesCreate)
  create({ getState, patchState }: StateContext<ReviewCyclesStateModel>, { item }: ReviewCyclesCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ReviewCyclesUpdate)
  update({ getState, patchState }: StateContext<ReviewCyclesStateModel>, { id, item }: ReviewCyclesUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ReviewCyclesDelete)
  delete({ getState, patchState }: StateContext<ReviewCyclesStateModel>, { id }: ReviewCyclesDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
