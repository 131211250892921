<div
  class="table-container mat-container scrollbar"
  [ngClass]="{ hilite: hasPending }"
>
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="text">
      <mat-header-cell *matHeaderCellDef class="pl-16 w-100">
        {{ customTitle }}
      </mat-header-cell>
      <mat-cell
        class="font-weight-bold pl-16 w-100"
        *matCellDef="let element"
        nowrap
        ><span
          [innerHTML]="element.description"
          [ngClass]="{ 'red strikeout': element.code }"
        ></span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell *matCellDef="let element" nowrap class="justify-content-start">
        <div class="row">
          <div
            class="col-12 d-flex align-items-center pending"
            *ngIf="element.status == 0 && !element.code"
          >
            <mat-icon color="primary">pending_actions</mat-icon
            ><span class="blue bold">Pending Approval</span>
          </div>
          <div
            class="col-12 d-flex align-items-center small"
            *ngIf="
              element.createdBy && (element.status == 0 || element.status == 1)
            "
          >
            <span
              [innerHTML]="
                'Created by <b>' + element.createdBy.name + '</b> on '
              "
            ></span>
            <span class="date ml-1">{{
              element.createdOn | date : "short"
            }}</span>
          </div>

          <div
            class="col-12 d-flex align-items-center small"
            *ngIf="element.updatedBy && element.status == 1"
          >
            <span
              [innerHTML]="
                'Approved by <b>' + element.updatedBy.name + '</b> on '
              "
            ></span>
            <span class="date ml-1">{{
              element.updatedOn | date : "short"
            }}</span>
          </div>

          <div
            class="col-12 d-flex align-items-center pending"
            *ngIf="
              element.status == -1 &&
              (hasPrivilege(privilegeEnum.BeamlineRestrictions) ||
                hasPrivilege(privilegeEnum.BeamlineRestrictionsApprove))
            "
          >
            <mat-icon color="warn">auto_delete</mat-icon>
            <span class="red bold">Pending Deletion</span>
          </div>
          <div
            class="col-12 d-flex align-items-center small"
            *ngIf="element.status == -1"
          >
            <span
              [innerHTML]="
                'Deleted by <b>' + element.updatedBy.name + '</b> on '
              "
            ></span>
            <span class="date ml-1">{{
              element.updatedOn | date : "short"
            }}</span>
          </div>
          <div
            class="col-12 d-flex align-items-center small"
            *ngIf="element.status == 0 && element.code"
          >
            <span
              [innerHTML]="
                'Disapproved by <b>' + element.updatedBy.name + '</b> on '
              "
            ></span>
            <span class="date ml-1">{{
              element.updatedOn | date : "short"
            }}</span>
          </div>
          <div
            class="col-12 d-flex align-items-center"
            *ngIf="element.status == 0 && element.code"
          >
            <span class="red bold">{{ element.code }}</span>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="pt-2">
        <button
          type="button"
          mat-icon-button
          (click)="edit()"
          matTooltip="Add"
          *ngIf="
            !disabled &&
            hasPrivilege(privilegeEnum.BeamlineRestrictions) &&
            !adding &&
            beamline?.id
          "
        >
          <mat-icon color="accent">add</mat-icon>
        </button>
      </mat-header-cell>
      <mat-cell
        class="pl-1 max-50 d-print-none"
        *matCellDef="let element; let i = index"
      >
        <!-- Delete After Approval -->
        <button
          type="button"
          class="background-white"
          mat-icon-button
          matTooltip="Delete"
          (click)="delete(element)"
          *ngIf="
            !disabled &&
            hasPrivilege(privilegeEnum.BeamlineRestrictions) &&
            element.status == 1
          "
        >
          <mat-icon color="warn">delete</mat-icon>
        </button>
        <!-- Edit Before Approval -->
        <button
          type="button"
          class="background-white"
          mat-icon-button
          matTooltip="Edit"
          (click)="edit(element)"
          *ngIf="
            !disabled &&
            element.createdByID == currentUser?.id &&
            element.status == 0
          "
        >
          <mat-icon class="yellow">edit</mat-icon>
        </button>
        <!-- Cancel Submit -->
        <button
          type="button"
          class="background-white"
          mat-icon-button
          matTooltip="Cancel"
          (click)="cancel(element)"
          *ngIf="
            !disabled &&
            element.createdByID == currentUser?.id &&
            element.status == 0 &&
            !element.code
          "
        >
          <mat-icon color="warn">cancel</mat-icon>
        </button>
        <!-- Cancel Delete -->
        <button
          type="button"
          class="background-white"
          mat-icon-button
          matTooltip="Cancel Deletion"
          (click)="cancelDeletion(element)"
          *ngIf="
            !disabled &&
            element.updatedByID == currentUser?.id &&
            element.status == -1
          "
        >
          <mat-icon color="warn">cancel</mat-icon>
        </button>
        <!-- Approve -->
        <button
          type="button"
          class="background-white"
          mat-icon-button
          matTooltip="Approve"
          (click)="approve(element, true)"
          *ngIf="
            !disabled &&
            !element.code &&
            ((element.status == 0 && element.createdByID != currentUser?.id) ||
              (element.status == -1 &&
                element.updatedByID != currentUser?.id)) &&
            hasPrivilege(privilegeEnum.BeamlineRestrictions)
          "
        >
          <mat-icon class="green">check_circle</mat-icon>
        </button>
        <!-- Clear if Disapproved -->
        <button
          type="button"
          class="background-white"
          mat-icon-button
          matTooltip="Clear"
          (click)="cancel(element)"
          *ngIf="
            !disabled &&
            element.status == 0 &&
            element.code &&
            (hasPrivilege(privilegeEnum.BeamlineRestrictionsApprove) ||
              hasPrivilege(privilegeEnum.BeamlineRestrictionsApprove))
          "
        >
          <mat-icon color="accent">delete</mat-icon>
        </button>
        <!-- Disapprove -->
        <button
          type="button"
          class="background-white"
          mat-icon-button
          matTooltip="Disapprove"
          (click)="approve(element, false)"
          *ngIf="
            !disabled &&
            !element.code &&
            ((element.status == 0 && element.createdByID != currentUser?.id) ||
              (element.status == -1 &&
                element.updatedByID != currentUser?.id)) &&
            hasPrivilege(privilegeEnum.BeamlineRestrictionsApprove)
          "
        >
          <mat-icon class="red">unpublished</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      #matrow
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{
        disabled: disabled,
        background: row.restriction,
        hilite: row.isPending
      }"
    ></mat-row>
  </mat-table>
</div>

<comments-box
  class="invisible"
  [noSubscript]="true"
  [formType]="'BLRestrictions'"
  [serialNo]="beamline?.name ?? ''"
  [label]="'Add Operational Restriction'"
  (textChanged)="save($event)"
  (cancelled)="adding = false; editing = null"
></comments-box>
