import { APLGroup } from "src/app/components/apl/services/apl-group.model";

export class APLGroupAllPendingRefresh {
  static readonly type = '[APL Group] All Pending Refresh';
}
export class APLGroupRefresh {
  static readonly type = '[APL Group] Refresh';

  constructor() { }
}

export class APLGroupRefreshByAPLCategoryMasterID {
  static readonly type = '[APL Group] Refresh by APL Category Master ID';

  constructor(public id: number) { }
}

export class APLGroupCreate {
  static readonly type = '[APL Group] Create';

  constructor(public item: APLGroup) { }
}

export class APLGroupUpdate {
  static readonly type = '[APL Group] Update';

  constructor(public id: number, public item: APLGroup) { }
}

export class APLGroupDelete {
  static readonly type = '[APL Group] Delete';

  constructor(public id: number) { }
}
