import { Component, Input, OnChanges, Output, EventEmitter, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import { WFStepLocalResource } from 'src/app/services/work-flow/work-flow';
import { Checklist } from '../../checklists';
import { ChecklistStepComponent } from './checklist-step/checklist-step.component';

@Component({
  selector: "checklist-steps",
  templateUrl: "./checklist-steps.component.html",
  styleUrls: ["./checklist-steps.component.scss"],
})
export class ChecklistStepsComponent implements OnChanges {
  @Input() currentChecklist?: Checklist | null;
  @Input() steps?: WFStepLocalResource[];
  @Input() precheck?: boolean;
  @Input() disabled?: boolean;

  @Output() loading = new EventEmitter<boolean>();
  @Output() checkShared = new EventEmitter<any>();

  @ViewChildren(ChecklistStepComponent) public stepComponents!: QueryList<ChecklistStepComponent>;

  ngOnChanges(changes: SimpleChanges) {
    this.steps = this.steps?.sort((a, b) => a.order - b.order);
  }

}
