import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ProcedureCreate, ProcedureDelete, ProcedureRefresh, ProcedureUpdate, ProcedureSet, ProcedureRefreshByType } from "./procedure.action";
import { Procedure } from "src/app/components/procedure/models/procedure.model";
import { ProcedureService } from "src/app/components/procedure/services/procedure.service";

export class ProcedureStateModel {
  data: Procedure[] = [];
}

@State<ProcedureStateModel>({
  name: 'Procedures',
  defaults: {
    data: [],
  }
})
@Injectable()
export class ProcedureState {
  constructor(
    private procedureService: ProcedureService
  ) { }

  @Selector()
  static read(state: ProcedureStateModel) {
    return state.data;
  }

  @Action(ProcedureRefresh)
  refresh({ patchState }: StateContext<ProcedureStateModel>) {
    this.procedureService.read().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ProcedureRefreshByType)
  refreshByType({ patchState }: StateContext<ProcedureStateModel>, { type }: ProcedureRefreshByType) {
    this.procedureService.readByType(type).subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ProcedureCreate)
  create({ getState, patchState }: StateContext<ProcedureStateModel>, { item }: ProcedureCreate) {
    if (getState().data != undefined && getState().data != null) {
      patchState({
        data: [...getState().data, item]
      });
    }
  }

  @Action(ProcedureUpdate)
  update({ getState, patchState }: StateContext<ProcedureStateModel>, { id, item }: ProcedureUpdate) {
    if (getState().data != undefined && getState().data != null) {
      const index = getState().data.map(x => x.id).indexOf(id);

      if (index >= 0) {
        getState().data.splice(index, 1);
        patchState({
          data: [...getState().data, item]
        });
      }
    }
  }

  @Action(ProcedureDelete)
  delete({ getState, patchState }: StateContext<ProcedureStateModel>, { id }: ProcedureDelete) {
    if (getState().data != undefined && getState().data != null) {
      patchState({
        data: getState().data.filter(x => x.id != id)
      });
    }
  }

  @Action(ProcedureSet)
  set({ patchState }: StateContext<ProcedureStateModel>, { items }: ProcedureSet) {
    patchState({
      data: items,
    });
  }
}
