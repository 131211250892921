import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Document, DocumentCategory, DocumentType } from 'src/app/services/documents/documents';
import { Observable, Subscription } from 'rxjs';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { ReviewCycle } from '../reviews/reviews.models';
import { DocumentsRefresh } from 'src/app/services/documents/store/documents.action';
import { MatDialog } from '@angular/material/dialog';
import { RedirectDialogComponent } from './redirect-dialog/redirect-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  document?: Document | null;
  documents?: Document[];
  documentsFiltered?: Document[];
  documents$!: Observable<Document[]>;
  documentsSubs!: Subscription;

  documentTypes?: DocumentType[];
  documentTypesFiltered?: DocumentType[];
  documentTypes$!: Observable<DocumentType[]>;
  documentTypesSubs!: Subscription;

  reviewCycles?: ReviewCycle[];
  reviewCycles$!: Observable<ReviewCycle[]>;
  reviewCyclesSubs!: Subscription;
  openDocuments: OpenDocument[] = [];

  previousUrl: string | null = null;
  currentUrl: string | null = null;

  mouseX?: string;
  mouseY?: string;

  constructor(
    private store: Store,
    private router: Router,
    private dialog: MatDialog
  ) {

  }

  openDialog() {
    const dialogRef = this.dialog.open(RedirectDialogComponent, { width: '300px', height: '300px' });
    dialogRef.afterClosed();
  }

  openDocument(document: Document) {
    if (document) {
      this.document = document;
      let route = '';
      this.loadDocumentTypes();

      switch (document.category) {
        case DocumentCategory.Form:
          document.documentType = this.documentTypes?.find(t => t.id == document.type);
          route = document.documentType?.code?.toLowerCase().trim() ?? '';
          break;

        case DocumentCategory.Checklist:
          const documentType = this.documentTypes?.find(t => t.type == document.type);
          if (documentType) {
            route = 'checklist-exec/' + documentType?.id
          }
          break;

        case DocumentCategory.Review:
          this.reviewCycles$ = this.store.select(state => state.ReviewCycles.data);
          this.reviewCyclesSubs = this.reviewCycles$.subscribe(data => {
            if (data?.length) {
              this.reviewCycles = data;
              const reviewCycle = this.reviewCycles.find(c => c.serialNo == document.serialNo);
              if (reviewCycle?.reviewBoard)
                route = `reviews/${reviewCycle.reviewBoard.type}`;
              else {
                const review = reviewCycle?.reviews?.[0];
                const boardType = review?.reviewCycle?.reviewBoard?.type;
                if (boardType)
                  route = `reviews/${boardType}`;
              }
            }
          });
          break;

        case DocumentCategory.ChecklistTemplate:
          localStorage.setItem('checklistTemplateID', document.formID?.toString() ?? '0');
          route = 'checklist-builder/' + document.documentType?.id;
          break;

        case DocumentCategory.ABSITemplate:
          route = 'absi-builder';
          break;

        case DocumentCategory.KETemplate:
          route = 'resources-new/1';
          break;

      }
      this.router.navigate([route]);
    }
  }

  loadDocuments() {
    this.documents$ = this.store.select(state => state.Documents.data);
    this.documentsSubs = this.documents$.subscribe(data => {
      if (data?.length) {
        this.documents = data;
      }
    });
  }

  loadDocumentTypes() {
    this.documentTypes$ = this.store.select(state => state.DocumentType.data);
    this.documentTypesSubs = this.documentTypes$.subscribe(data => {
      if (data?.length) {
        this.documentTypes = data;
      }
    });
  }

  async getDocument(type: FormType): Promise<Document | undefined | null> {
    if (this.document?.type == type || this.document?.category == DocumentCategory.Checklist && this.document.type == this.documentTypes?.find(t => t.id == type)?.type)
      return this.document;
    else {
      const openDocument = this.openDocuments.find(o => o.type == type);
      if (openDocument?.id) {
        this.loadDocuments();
        this.loadDocumentTypes();
        const document = this.findDocument(openDocument.id, openDocument.type);
        if (document) return document;
        else {
          this.store.dispatch(new DocumentsRefresh());
          setTimeout(() => {
            return this.getDocument(type);
          }, 1000);
        }
      }
    }
    return null;
  }

  findDocument(id: number, type: number) {
    const document = this.documents?.find(d => d.formID == id &&
      (d.type == type ||
        d.category == DocumentCategory.Checklist && d.type == this.documentTypes?.find(t => t.id == type)?.type)
    );
    return document;
  }
}

interface OpenDocument {
  id?: number;
  type: FormType;
}
