import { BeamlineShutterRelation } from "src/app/components/catalogs/beamline-catalog/resource/resources";

export class BeamlineShutterRefresh {
  static readonly type = '[BeamlineShutter] Refresh';
}

export class BeamlineShutterCreate {
  static readonly type = '[BeamlineShutter] Create';

  constructor(public item: BeamlineShutterRelation) { }
}

export class BeamlineShutterUpdate {
  static readonly type = '[BeamlineShutter] Update';

  constructor(public id: number, public item: BeamlineShutterRelation) { }
}

export class BeamlineShutterRefreshById {
  static readonly type = '[BeamlineShutter] RefreshById';

  constructor(public id: number) { }
}

export class BeamlineShutterDelete {
  static readonly type = '[BeamlineShutter] Delete';
  constructor(public id: number) { }
}
