<mat-form-field class="no-subscript">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-chip-grid #rssFunctionalTestChips>
    <mat-chip-row
      class="yellow-background"
      *ngFor="let rssFunctionalTest of rssFunctionalTests"
      [selectable]="true"
      [removable]="true"
      (removed)="removeRSSFunctionalTest(rssFunctionalTest)"
    >
      <span
        class="rss-functional-test-name white"
        [matTooltip]="
          (rssFunctionalTest.checklistTemplate?.serialNo ?? '') +
          '<br><span class=\'date small\'>' +
          (rssFunctionalTest.createdOn | date : 'medium')
        "
        matTooltipClass="tooltip-yellow tooltip-wide"
        (click)="openViewMode(rssFunctionalTest)"
        >{{ rssFunctionalTest.name }}</span
      >
      <mat-icon
        *ngIf="!disabled && editPrivilege"
        class="pl-1 edit-icon"
        (click)="editRSSFunctionalTest(rssFunctionalTest)"
        >create</mat-icon
      >
      <mat-icon class="red" matChipRemove *ngIf="!disabled && deletePrivilege"
        >cancel</mat-icon
      >
    </mat-chip-row>
    <input
      [placeholder]="placeholder"
      [matChipInputFor]="rssFunctionalTestChips"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [formControl]="control"
    />
  </mat-chip-grid>
  <mat-error *ngIf="control.invalid">{{ getErrorMsg(control) }} </mat-error>
</mat-form-field>
