import { OlogEmail } from "../../services/olog-email";

export class OlogEmailRefresh {
  static readonly type = '[OlogEmail] Refresh';
}

export class OlogEmailCreate {
  static readonly type = '[OlogEmail] Create';
  constructor(public item: OlogEmail) { }
}

export class OlogEmailUpdate {
  static readonly type = '[OlogEmail] Update';
  constructor(public id: number, public item: OlogEmail) { }
}

export class OlogEmailDelete {
  static readonly type = '[OlogEmail] Delete';
  constructor(public id: number) { }
}
