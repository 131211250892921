import { State, Selector, Action, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { TOCACreate, TOCADelete, TOCARefresh, TOCAUpdate } from './toca.action';
import { utils } from 'src/app/modules/libs/utils';
import { TOCA } from 'src/app/components/catalogs/beamline-catalog/resource/toca';
import { BratoiService } from '../../bratoi.service';

export class TOCAStateModel {
  data: TOCA[] = [];
}

@State<TOCAStateModel>({
  name: 'TOCA',
  defaults: {
    data: []
  }
})
@Injectable()
export class TOCAState {
  constructor(
    private store: Store,
    private bratoiService: BratoiService,
  ) { }

  @Selector()
  static read(state: TOCAStateModel) {
    return state.data;
  }

  @Action(TOCARefresh)
  refresh({ patchState }: StateContext<TOCAStateModel>) {
    this.bratoiService.GetActiveTOCAs().toPromise().then(data => {
      patchState({ data });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(TOCACreate)
  create({ getState, patchState }: StateContext<TOCAStateModel>, { item }: TOCACreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(TOCAUpdate)
  update({ getState, patchState }: StateContext<TOCAStateModel>, { id, item }: TOCAUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    const currentState = utils.cloneDeep(state.data);

    if (index >= 0) {
      currentState.splice(index, 1, utils.cloneDeep(item));
      patchState({ data: currentState });
    }
  }

  @Action(TOCADelete)
  delete({ getState, patchState }: StateContext<TOCAStateModel>, { id }: TOCADelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
