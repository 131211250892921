import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AcceleratorCatalogListComponent } from './accelerator-catalog-list/accelerator-catalog-list.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { AcceleratorCatalog } from 'src/app/components/catalogs/accelerator-catalog/services/accelerator-catalog';
import { PendingCatalog } from 'src/app/services/pending-approvals/pending-approval';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { CatalogService } from '../catalog-service';


@Component({
  selector: 'accelerator-catalog',
  templateUrl: './accelerator-catalog.component.html',
  styleUrls: ['./accelerator-catalog.component.scss']
})
export class AcceleratorCatalogComponent extends BaseComponent implements OnInit, OnDestroy {

  loading!: boolean;

  accelerator?: Resource;
  acceleratorCatalog?: AcceleratorCatalog;

  originalAcceleratorCatalog?: AcceleratorCatalog;

  filter!: string;
  valueFilter!: string;

  showModification!: boolean;
  showHistory = false;

  @ViewChild(AcceleratorCatalogListComponent) acceleratorCatalogList?: AcceleratorCatalogListComponent;

  constructor(
    protected override injector: Injector,
    private catalogService: CatalogService,
  ) {
    super(injector);

  }

  override ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.moduleTitle = 'Accelerator Catalog';
  }

  ngAfterViewInit(): void {
  }

  rowSelected(data: AcceleratorCatalog) {
    this.acceleratorCatalog = data;
    if (this.acceleratorCatalog?.lastPendingAccelerator) {
      this.acceleratorCatalog.lastPendingAccelerator.userCreatedBy = this.users.find(u => u.id == this.acceleratorCatalog?.lastPendingAccelerator?.createdBy);
    }
    if (this.acceleratorCatalog?.pendingAcceleratorCatalog) {
      this.acceleratorCatalog.pendingAcceleratorCatalog.userCreatedBy = this.users.find(u => u.id == this.acceleratorCatalog?.pendingAcceleratorCatalog?.createdBy);
    }
    this.accelerator = data?.details?.accelerator ?? data?.pendingDetails?.accelerator;
    this.showModification = false;
    this.showHistory = false;
  }

  refreshSaved(id: number) {
    this.catalogService.pendingCatalog = { id } as PendingCatalog;
    this.acceleratorCatalogList?.loadAccelerators();
  }

  applyFilter(filter: string) {
    this.filter = filter;
  }

  create() {
    this.acceleratorCatalogList?.createResource();
  }

  history(val: boolean) {
    this.showHistory = val;
    if (val) {
      this.originalAcceleratorCatalog = this.utils.cloneDeep(this.acceleratorCatalog);
      this.showModification = false;
    }
    else {
      this.acceleratorCatalog = this.utils.cloneDeep(this.originalAcceleratorCatalog);
    }
  }

  setLoading(e: any) {
    setTimeout(() => {
      this.loading = e;
    }, 100);

  }

}
