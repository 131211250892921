import { Component, Injector, OnInit } from '@angular/core';
import { Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/common/base/base.component';
import { SiteConfiguration } from 'src/app/services/site-configuration/site-configuration';
import { SiteConfigurationService } from 'src/app/services/site-configuration/site-configuration-service';

@Component({
  selector: 'scf-v2-settings-notifications',
  templateUrl: './scf-v2-settings-notifications.component.html',
  styleUrls: ['./scf-v2-settings-notifications.component.scss']
})
export class ScfV2SettingsNotificationsComponent extends BaseComponent implements OnInit {

  loading = false;
  siteConfiguration!: SiteConfiguration;

  public get notificationActiveCtrl(): AbstractControl | null { return this.formGroup.get('notificationActiveCtrl'); }
  public get notificationEmailsCtrl(): AbstractControl | null { return this.formGroup.get('notificationEmailsCtrl'); }
  public get notificationHPEmailsCtrl(): AbstractControl | null { return this.formGroup.get('notificationHPEmailsCtrl'); }
  public get notificationRPEmailsCtrl(): AbstractControl | null { return this.formGroup.get('notificationRPEmailsCtrl'); }

  constructor(
    public siteConfService: SiteConfigurationService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.buildform();
    this.loadSiteConfiguration();
  }

  buildform() {
    this.formGroup = this.formBuilder.group({
      notificationActiveCtrl: [null, Validators.required],
      notificationEmailsCtrl: [null, Validators.pattern(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4}(,\s)?)+$/)],
      notificationHPEmailsCtrl: [null, Validators.pattern(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4}(,\s)?)+$/)],
      notificationRPEmailsCtrl: [null, Validators.pattern(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4}(,\s)?)+$/)],
    });
  }

  loadSiteConfiguration() {
    this.loading = true;
    this.siteConfService.getFirst().toPromise().then(data => {
      if (data) {
        this.siteConfiguration = data;
        this.loading = false;
        this.notificationActiveCtrl?.setValue(this.siteConfiguration.isEmailSCFActive);
        this.notificationEmailsCtrl?.setValue(this.siteConfiguration.scfNotificationEmails);
        this.notificationHPEmailsCtrl?.setValue(this.siteConfiguration.scfhpNotificationEmails);
        this.notificationRPEmailsCtrl?.setValue(this.siteConfiguration.scfrpNotificationEmails);
        this.checkDisabled();
      }
    });
  }

  checkDisabled() {
    if (this.siteConfiguration.isEmailSCFActive) {
      this.notificationEmailsCtrl?.enable();
    }
    else {
      this.notificationEmailsCtrl?.disable();
    }
  }

  override getErrorMsg(control: AbstractControl | null): string | null {
    if (control)
      if (control.hasError('pattern')) { return 'Invalid email Addresses'; }
    return null;
  }

  activeToggleChanged(e: any) {
    this.siteConfiguration.isEmailSCFActive = e.checked;
    this.checkDisabled();
    this.save();
  }

  emailsChanged(type: number, e: any) {
    switch (type) {
      case 0:
        this.siteConfiguration.scfNotificationEmails = e.target.value;
        break;
      case 1:
        this.siteConfiguration.scfhpNotificationEmails = e.target.value;
        break;
      case 2:
        this.siteConfiguration.scfrpNotificationEmails = e.target.value;
        break;
    }

    if (this.formGroup.valid) {
      this.save();
    }
  }

  getHint(type: number) {
    switch (type) {
      case 0:
        return this.getMessage('SCFEmailNotificationslAll').description;
        break;
      case 1:
        return this.getMessage('SCFEmailNotificationslHP').description;
        break;
      case 2:
        return this.getMessage('SCFEmailNotificationslRP').description;
        break;
    }
    return '';
  }

  save() {
    this.siteConfService.update(this.siteConfiguration).toPromise().then(() => {
      this.alert.success('Configuration Saved!');
    });
  }
}
