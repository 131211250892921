<div id="divContainer">
  <div class="card">
    <div class="card-header sticky-header">
      <span class="card-title">Beamline Overview</span>
      <div class="mat-container maxHeight">
        <table mat-table #table [dataSource]="dataSourceBlStatus">
          <ng-container matColumnDef="beamline">
            <th mat-header-cell *matHeaderCellDef>Beamline</th>
            <td mat-cell *matCellDef="let element">
              <h6 class="p-0">{{ element.beamlineName }}</h6>
            </td>
          </ng-container>
          <ng-container matColumnDef="shutter">
            <th mat-header-cell *matHeaderCellDef>Shutter</th>
            <td mat-cell *matCellDef="let element">
              <h6 class="p-0">{{ element.shutterName }}</h6>
            </td>
          </ng-container>
          <ng-container matColumnDef="pss">
            <th mat-header-cell *matHeaderCellDef>PSS</th>
            <td
              mat-cell
              *matCellDef="let element"
              [attr.colspan]="element.status == 3 ? 2 : 1"
            >
              <div
                [ngClass]="getClass(element.pssChannelStatus)"
                *ngIf="element.status != 3"
              >
                {{ element.pssChannelStatus }}
              </div>
              <div *ngIf="element.status == 3" class="decommissioned">
                Decommissioned
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="rss">
            <th mat-header-cell *matHeaderCellDef>RSS</th>
            <td
              mat-cell
              *matCellDef="let element"
              [attr.hidden]="element.status == 3 ? '' : null"
            >
              <div [ngClass]="getClass(element.rssChannelStatus)">
                {{ element.rssChannelStatus }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="keReady">
            <th mat-header-cell *matHeaderCellDef>KE Status</th>
            <td mat-cell *matCellDef="let element">
              <div
                class="{{ element.keReady.cssClass }} badge-pill"
                [matTooltip]="element.moreInfo"
              >
                {{ element.keReady.ready ? "Ready" : "Not Ready" }}
              </div>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsBlStatus; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsBlStatus"
          ></tr>
        </table>
      </div>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div class="card-body">
      <div class="row" *ngIf="!loading">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-sm-4">
              <mat-form-field appearance="outline" class="no-subscript">
                <mat-label>Beamline Status</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamlineStatus"
                  [disabled]="true"
                  name="beamlineDescription"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-4">
              <mat-form-field appearance="outline" class="no-subscript">
                <mat-label>Beamline Name</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamline.description"
                  [disabled]="true"
                  name="beamlineDescription"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-4">
              <mat-form-field appearance="outline" class="no-subscript">
                <mat-label>Extension</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamline.beamLineExtension"
                  [disabled]="true"
                  name="beamlineExtension"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>Comment</mat-label>
            </div>
          </div>
          <div class="row">
            <div class="col-11">
              <div class="w-100 pb-2">
                <comments-box
                  [control]="overviewCtrl"
                  [textValue]="overviewCtrlText"
                  [disabled]="!isAbleToEditComment()"
                  formType="BLOverview"
                  [serialNo]="beamline.name ?? undefined"
                  title="Comment"
                  (textChanged)="commentChanged($event)"
                  [hint]="getHint()"
                  [noSubscript]="!getHint()"
                ></comments-box>
              </div>
            </div>
            <div class="d-flex align-items-center col-1">
              <button
                mat-icon-button
                class="noOutline"
                (click)="deleteBlComment()"
                *ngIf="isAbleToDeleteComment()"
              >
                <mat-icon color="warn">delete</mat-icon>
              </button>
            </div>
          </div>

          <div class="mb-3">
            <div class="pb-1">Operational Restrictions</div>
            <operational-restrictions
              [beamline]="beamline"
            ></operational-restrictions>
          </div>

          <div *ngIf="documents.length > 0" class="mb-3">
            <div>List Of Forms Affecting KE Ready Status</div>
            <div class="row">
              <div class="col-12">
                <div class="mat-container maxHeight scrollbar">
                  <div class="d-flex flex-wrap">
                    <div
                      class="m-2 p-2 listItem d-inline-flex"
                      *ngFor="let document of scfDocuments"
                    >
                      <mat-icon>arrow_right</mat-icon>
                      <p class="itemText">
                        <button
                          type="button"
                          class="btn btn-link"
                          (click)="navigateToForm(document)"
                        >
                          {{ document.serialNo }}
                        </button>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex flex-wrap">
                    <div
                      class="m-2 p-2 listItem d-inline-flex"
                      *ngFor="let document of rsswaDocuments"
                    >
                      <mat-icon>arrow_right</mat-icon>
                      <p class="itemText">
                        <button
                          type="button"
                          class="btn btn-link"
                          (click)="navigateToForm(document)"
                        >
                          {{ document.serialNo }}
                        </button>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex flex-wrap">
                    <div
                      class="m-2 p-2 listItem d-inline-flex"
                      *ngFor="let document of ebDocuments"
                    >
                      <mat-icon>arrow_right</mat-icon>
                      <p class="itemText">
                        <button
                          type="button"
                          class="btn btn-link"
                          (click)="navigateToForm(document)"
                        >
                          {{ document.serialNo }}
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>Endstations</mat-label>
            </div>
          </div>
          <div class="row mt-1 mb-4">
            <div class="col-12 col-sm-12">
              <div class="mat-container maxHeight scrollbar">
                <mat-table #table [dataSource]="dataSourceEndStation">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                      Endstation
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{
                      element.name
                    }}</mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumnsResource; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsResource"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>Branchlines</mat-label>
            </div>
          </div>
          <div class="row mt-1 mb-4">
            <div class="col-12 col-sm-12">
              <div class="mat-container maxHeight scrollbar">
                <mat-table #table [dataSource]="dataSourceBranchline">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                      Branchline
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{
                      element.name
                    }}</mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumnsResource; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsResource"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>Organizations</mat-label>
            </div>
          </div>
          <div class="row mt-1 mb-4">
            <div class="col-12 col-sm-12">
              <div class="mat-container maxHeight scrollbar">
                <mat-table #table [dataSource]="dataSourceOrganization">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                      Organizations
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.organization?.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="endstation">
                    <mat-header-cell *matHeaderCellDef>
                      Endstation
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.endstation?.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="branchline">
                    <mat-header-cell *matHeaderCellDef>
                      Branchline
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.branchline?.name }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="
                      displayedColumnsOrgUserRelation;
                      sticky: true
                    "
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="
                      let row;
                      columns: displayedColumnsOrgUserRelation
                    "
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>Supervisors</mat-label>
            </div>
          </div>
          <div class="row mt-1 mb-4">
            <div class="col-12 col-sm-12">
              <div class="mat-container maxHeight scrollbar">
                <mat-table #table [dataSource]="dataSourceSupervisor">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                      Supervisors
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.user?.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="endstation">
                    <mat-header-cell *matHeaderCellDef>
                      Endstation
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.endstation?.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="branchline">
                    <mat-header-cell *matHeaderCellDef>
                      Branchline
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.branchline?.name }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="
                      displayedColumnsOrgUserRelation;
                      sticky: true
                    "
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="
                      let row;
                      columns: displayedColumnsOrgUserRelation
                    "
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>BL Scientists</mat-label>
            </div>
          </div>
          <div class="row mt-1 mb-4">
            <div class="col-12 col-sm-12">
              <div class="mat-container maxHeight scrollbar">
                <mat-table #table [dataSource]="dataSourceScientist">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                      BL Scientists
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"
                      >{{ element.user?.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="endstation">
                    <mat-header-cell *matHeaderCellDef>
                      Endstation
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.endstation?.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="branchline">
                    <mat-header-cell *matHeaderCellDef>
                      Branchline
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.branchline?.name }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="
                      displayedColumnsOrgUserRelation;
                      sticky: true
                    "
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="
                      let row;
                      columns: displayedColumnsOrgUserRelation
                    "
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>Alternate BL Scientists</mat-label>
            </div>
          </div>
          <div class="row mt-1 mb-4">
            <div class="col-12 col-sm-12">
              <div class="mat-container maxHeight scrollbar">
                <mat-table #table [dataSource]="dataSourceAlternateScientist">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                      Alternate BL Scientists
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"
                      >{{ element.user?.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="endstation">
                    <mat-header-cell *matHeaderCellDef>
                      Endstation
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.endstation?.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="branchline">
                    <mat-header-cell *matHeaderCellDef>
                      Branchline
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.branchline?.name }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="
                      displayedColumnsOrgUserRelation;
                      sticky: true
                    "
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="
                      let row;
                      columns: displayedColumnsOrgUserRelation
                    "
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>Links</mat-label>
            </div>
          </div>
          <div class="row mt-1 mb-4">
            <div class="col-12 col-sm-12">
              <div class="mat-container scrollbar">
                <mat-table #table [dataSource]="dataSourceLink">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                      Hyperlinks
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <a href="{{ element.webAddress }}" target="_blank">{{
                        element.hyperlink
                      }}</a>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumnsResource; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsResource"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>Schedules</mat-label>
            </div>
          </div>
          <div class="row mt-1 mb-4">
            <div class="col-12 col-sm-12">
              <div class="mat-container maxHeight scrollbar">
                <mat-table #table [dataSource]="dataSourceSchedule">
                  <ng-container matColumnDef="schedule">
                    <mat-header-cell *matHeaderCellDef>
                      Schedule
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <button
                        type="button"
                        class="btn btn-link"
                        (click)="navigateToSchedule(element)"
                      >
                        {{ element.scheduleType }}
                      </button>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="location">
                    <mat-header-cell *matHeaderCellDef>
                      Location
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.location }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="dueDate">
                    <mat-header-cell *matHeaderCellDef>
                      Due Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.nextDue | date : "longDate" }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Status
                    </mat-header-cell>
                    <mat-cell
                      [ngClass]="element.cssColor"
                      *matCellDef="let element"
                    >
                      {{ element.statusTimeName }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumnsSchedule; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsSchedule"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-12 pb-1">
              <mat-label>Forms & Checklists</mat-label>
            </div>
            <div class="col-6 pr-1">
              <div class="mat-container maxHeight scrollbar mb-3">
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="loading2 && beamline.id"
                ></mat-progress-bar>
                <mat-table #table [dataSource]="scfs">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> SCF's </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <button
                        type="button"
                        class="btn btn-link"
                        (click)="navigateToForm(element)"
                      >
                        {{ element.serialNo }}
                      </button>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Status
                    </mat-header-cell>
                    <mat-cell
                      [ngClass]="element.cssClass"
                      *matCellDef="let element"
                      >{{ element.statusName }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumnsForms; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsForms"
                  ></mat-row>
                </mat-table>
              </div>
              <div class="mat-container maxHeight scrollbar mb-3">
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="loading2 && beamline.id"
                ></mat-progress-bar>
                <mat-table #table [dataSource]="ppstbs">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                      PPSTB's
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <button
                        type="button"
                        class="btn btn-link"
                        (click)="navigateToForm(element)"
                      >
                        {{ element.serialNo }}
                      </button>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Status
                    </mat-header-cell>
                    <mat-cell
                      [ngClass]="element.cssClass"
                      *matCellDef="let element"
                      >{{ element.statusName }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumnsForms; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsForms"
                  ></mat-row>
                </mat-table>
              </div>
              <div class="mat-container maxHeight scrollbar mb-3">
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="loading2 && beamline.id"
                ></mat-progress-bar>
                <mat-table #table [dataSource]="kes">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> KEs </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <button
                        type="button"
                        class="btn btn-link"
                        (click)="navigateToForm(element)"
                      >
                        {{ element.serialNo }}
                      </button>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Status
                    </mat-header-cell>
                    <mat-cell
                      [ngClass]="element.cssClass"
                      *matCellDef="let element"
                      >{{ element.statusName }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumnsForms; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsForms"
                  ></mat-row>
                </mat-table>
              </div>
              <div class="mat-container maxHeight scrollbar mb-3">
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="loading2 && beamline.id"
                ></mat-progress-bar>
                <mat-table #table [dataSource]="rads">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> RADs </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <button
                        type="button"
                        class="btn btn-link"
                        (click)="navigateToForm(element)"
                      >
                        {{ element.serialNo }}
                      </button>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Status
                    </mat-header-cell>
                    <mat-cell
                      [ngClass]="element.cssClass"
                      *matCellDef="let element"
                      >{{ element.statusName }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumnsForms; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsForms"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
            <div class="col-6">
              <div class="mat-container maxHeight scrollbar mb-3">
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="loading2 && beamline.id"
                ></mat-progress-bar>
                <mat-table #table [dataSource]="rsswas">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                      RSSWA's
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <button
                        type="button"
                        class="btn btn-link"
                        (click)="navigateToForm(element)"
                      >
                        {{ element.serialNo }}
                      </button>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Status
                    </mat-header-cell>
                    <mat-cell
                      [ngClass]="element.cssClass"
                      *matCellDef="let element"
                      >{{ element.statusName }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumnsForms; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsForms"
                  ></mat-row>
                </mat-table>
              </div>
              <div class="mat-container maxHeight scrollbar mb-3">
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="loading2 && beamline.id"
                ></mat-progress-bar>
                <mat-table #table [dataSource]="ebs">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> EB's </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <button
                        type="button"
                        class="btn btn-link"
                        (click)="navigateToForm(element)"
                      >
                        {{ element.serialNo }}
                      </button>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Status
                    </mat-header-cell>
                    <mat-cell
                      [ngClass]="element.cssClass"
                      *matCellDef="let element"
                      >{{ element.statusName }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumnsForms; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsForms"
                  ></mat-row>
                </mat-table>
              </div>
              <div class="mat-container maxHeight scrollbar mb-3">
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="loading2 && beamline.id"
                ></mat-progress-bar>
                <mat-table #table [dataSource]="absis">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> ABSIs </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <button
                        type="button"
                        class="btn btn-link"
                        (click)="navigateToForm(element)"
                      >
                        {{ element.serialNo }}
                      </button>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Status
                    </mat-header-cell>
                    <mat-cell
                      [ngClass]="element.cssClass"
                      *matCellDef="let element"
                      >{{ element.statusName }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumnsForms; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsForms"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
