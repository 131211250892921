<div class="card">
  <div class="card-header">
    <span class="card-title"
      >Bulletin Board <span class="small">ARCHIVE</span></span
    >
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <mat-form-field appearance="outline">
          <mat-label>Search</mat-label>
          <input (keyup)="filterHistory()" matInput [(ngModel)]="Filter" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="col col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <mat-form-field appearance="outline">
          <mat-label>Month</mat-label>
          <input
            name="Month"
            (keyup)="filterHistory()"
            maxlength="2"
            (keypress)="numberOnly($event)"
            type="text"
            matInput
            [(ngModel)]="Month"
            onDrag="return false"
            onDrop="return false"
            onPaste="return false"
          />
          <mat-hint>MM</mat-hint>
        </mat-form-field>
      </div>
      <div class="col col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <mat-form-field appearance="outline">
          <mat-label>Year</mat-label>
          <input
            name="Year"
            (keyup)="filterHistory()"
            maxlength="4"
            (keypress)="numberOnly($event)"
            type="text"
            matInput
            [(ngModel)]="Year"
            onDrag="return false"
            onDrop="return false"
            onPaste="return false"
          />
          <mat-hint>YYYY</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="containerCards scrollbar">
      <div *ngFor="let item of FilteredInactivesHistory; let i = index">
        <div
          class="Bulletin col PaddingB5"
          [ngClass]="{
            MarginB5: FilteredInactivesHistory.length !== i + 1,
            pinned: item.isPined
          }"
        >
          <div class="row PR">
            <P
              class="col my-auto"
              align="Justify"
              (click)="SeeMore($event, item)"
            >
              <span align="Justify" class="MarginB0 small BulletinContent">
                {{ item.description.substring(0, 250) }}
                <a *ngIf="item.description.length > 250" href="#"
                  >... See more</a
                >
              </span>
            </P>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon class="small-dots">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                *ngIf="hasPrivilege(privilegeEnum.BulletinBoardEditor)"
                (click)="DisarchiveBB(item)"
              >
                <span>Re-activate</span>
              </button>
            </mat-menu>
          </div>
          <p class="MarginB0 SsmallRight" align="Justify">
            <span>
              Created by {{ item.createdByUser }} on
              {{ item.createdOn | date : "yyyy-MM-dd" }}.
            </span>
            <span *ngIf="(item.updates?.length ?? 0) > 0">
              Last edited by
              {{ item.updates?.[item.updates?.length??0 - 1]?.updatedBy }} on
              {{
              item.updates?.[item.updates?.length??0 - 1]?.updatedOn
                | date : "yyyy-MM-dd"





              }}.
            </span>
            <span
              *ngIf="
                item.expirationDate != null &&
                item.expirationDate.toString() != ''
              "
            >
              Estimated expiration:
              {{ item.expirationDate | date : "yyyy-MM-dd" }}.
            </span>
          </p>
        </div>
        <hr *ngIf="FilteredInactivesHistory.length !== i + 1" />
      </div>
    </div>
  </div>
</div>
