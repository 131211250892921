
import { Component, Injector, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators, FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ScheduleStatusService } from '../../services/schedule-status.service';

@Component({
  selector: 'app-edit-request-message',
  templateUrl: './edit-request-message.component.html',
  styleUrls: ['./edit-request-message.component.scss']
})
export class EditRequestMessageComponent extends BaseComponent implements OnInit {

  statusForm!: FormGroup;
  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<EditRequestMessageComponent>,
    public service: ScheduleStatusService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.statusForm = this.formBuilder.group({
      notificationMessage: [null, Validators.required]
    });

    this.service.getById(3).subscribe(response => { // status 3 is ExtensionRequest
      // console.log(response);
      // console.log(response);
      this.statusForm.controls['notificationMessage'].setValue(response.notificationMessage);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.service.UpdateMessage(3, this.statusForm.controls['notificationMessage'].value).subscribe(response => {
      this.dialogRef.close();
    });

  }

}
