import { FormType } from "src/app/common/enumerations/enumerations";
import { User } from "../../components/catalogs/user-catalog/services/user";
import { FormVersion, FormVersionSave, FormVersionHandler } from "./form-version";
import { SequenceNumber } from "./sequence-number";
import { WFTableResource } from "src/app/components/workflow/workflow";

export class Form {
  id!: number;
  type!: FormType;
  sequenceNumberID?: number;
  date?: Date;
  wfTableID?: number;
  wfTable?: WFTableResource;
  createdBy?: number;
  createdOn?: Date;
  updatedBy?: number;
  updatedOn?: Date;
  sequenceNumber: SequenceNumber = new SequenceNumber;
  formVersion: FormVersion;
  editingUntil?: Date;
  editingById?: number | null;
  editingBy?: User;
  createdByUser?: User;
  updatedByUser?: User;
  description?: string;
  retrievedBy?: User | null;

  constructor(type: FormType) {
    this.type = type;
    this.formVersion = new FormVersion(type);
  }
}

export class FormSave {
  id?: number;
  type: FormType;
  date?: Date;
  formVersion: FormVersionSave;
  sequenceNumber!: SequenceNumber;

  constructor(type: FormType) {
    this.type = type;
    this.formVersion = new FormVersionSave(type);
  }
}

export class FormHandler {
  static toSave(form?: Form | null): FormSave | null {
    if (form) {
      const save = new FormSave(form.type);

      save.id = form.id <= 0 ? 0 : form.id;
      save.type = form.type;
      save.date = form.date;
      save.formVersion = FormVersionHandler.toSave(form.formVersion);
      save.sequenceNumber = form.sequenceNumber;
      return save;
    }
    return null;
  }
}
