<div
	class="annotations-wrapper"
	[ngClass]="{
    'annotations-wrapper-disabled': annotation?.status == annotationStatus.Done
  }"
>
	<div class="row">
		<div class="col-8">
			<span class="annotation" [innerHTML]="annotationTextSanitized"></span>
		</div>
		<div class="col-1 pr-0">
			<span class="buttons">
				<button
					mat-icon-button
					class="yellow"
					(click)="edit()"
					[matTooltip]="'Edit'"
					*ngIf="
            (currentChecklist?.checklistStatus?.formStatusID ==
              formStatus.Reviewing ||
              isRCP) &&
            (isReviewer || isRCP) &&
            isCreator &&
            annotation?.status == annotationStatus.Active &&
            !annotation?.responseText
          "
				>
					<mat-icon>edit</mat-icon>
				</button>
				<button
					mat-icon-button
					class="red"
					(click)="delete()"
					[matTooltip]="'Delete'"
					*ngIf="
            (currentChecklist?.checklistStatus?.formStatusID ==
              formStatus.Reviewing ||
              isRCP) &&
            (isReviewer || isRCP) &&
            isCreator &&
            annotation?.status == annotationStatus.Active &&
            !annotation?.responseText
          "
				>
					<mat-icon>delete</mat-icon>
				</button>

				<button
					mat-icon-button
					class="blue"
					(click)="respond()"
					[matTooltip]="'Write a response'"
					*ngIf="
            (currentChecklist?.checklistStatus?.formStatusID ==
              formStatus.Correcting ||
              isRCP) &&
            annotation?.status == annotationStatus.Active &&
            !annotation?.responseText &&
            !isCreator
          "
				>
					<mat-icon>question_answer</mat-icon>
				</button>
				<button
					mat-icon-button
					class="green"
					(click)="done()"
					[matTooltip]="'Mark as Done'"
					*ngIf="
            (currentChecklist?.checklistStatus?.formStatusID ==
              formStatus.Correcting ||
              isRCP) &&
            annotation?.status == annotationStatus.Active
          "
				>
					<mat-icon>mark_chat_read</mat-icon>
				</button>
				<button
					mat-icon-button
					class="orange"
					(click)="undone()"
					[matTooltip]="'Mark as Undone'"
					*ngIf="
            (currentChecklist?.checklistStatus?.formStatusID ==
              formStatus.Correcting ||
              isRCP) &&
            annotation?.status == annotationStatus.Done &&
            isPreparer
          "
				>
					<mat-icon>mark_chat_unread</mat-icon>
				</button>
			</span>
		</div>
		<div class="col-3 info">
			<span class="person align-end">
				<mat-icon class="gray">person</mat-icon>
				{{ annotation?.createdByName }} <br /><span class="date"
					>{{ annotation?.createdOn | date : "medium" }}
				</span>
			</span>
		</div>
	</div>
</div>
<div class="response-wrapper" *ngIf="annotation?.responseText">
	<div class="arrow">
		<mat-icon>subdirectory_arrow_right</mat-icon>
	</div>
	<div class="row">
		<div class="col-8">
			<span class="annotation" [innerHTML]="responseTextSanitized"></span>
		</div>
		<div class="col-1 pr-0">
			<span class="buttons"
				><button
					mat-icon-button
					class="yellow"
					(click)="respond()"
					[matTooltip]="'Edit'"
					*ngIf="
            (currentChecklist?.checklistStatus?.formStatusID ==
              formStatus.Correcting &&
              isResponder) ||
            (isRCP && isResponder)
          "
				>
					<mat-icon>edit</mat-icon>
				</button>
				<button
					mat-icon-button
					class="red"
					(click)="deleteResponse()"
					[matTooltip]="'Delete'"
					*ngIf="
            (currentChecklist?.checklistStatus?.formStatusID ==
              formStatus.Correcting &&
              isResponder) ||
            (isRCP && isResponder)
          "
				>
					<mat-icon>delete</mat-icon>
				</button>
			</span>
		</div>
		<div class="col-3 info">
			<span class="person align-end">
				<mat-icon class="gray">person</mat-icon> {{ annotation?.responseByName
				}}<br /><span class="date"
					>{{ annotation?.responseDate | date : "medium" }}</span
				>
			</span>
		</div>
	</div>
</div>
