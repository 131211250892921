<div class="main-panel p-2">
  <mat-accordion>
    <div class="pt-2" *ngFor="let section of sections">
      <absi-section-wrapper
        id="{{ section.name }}"
        #sectionX
        [section]="section"
        [absi]="absi"
        (loading)="setLoading($event, section.number ?? 0)"
      ></absi-section-wrapper>
    </div>
  </mat-accordion>

  <absi-review-and-concurrence
    *ngIf="sectionInfo?.id"
    id="ReviewAndConcurrence"
    #section100
    [sectionInfo]="sectionInfo"
    [absi]="absi"
    (loading)="setLoading($event, 100)"
  ></absi-review-and-concurrence>

  <div class="mt-3 pl-3">
    <comments-and-attachments
      *ngIf="absi?.wfTable"
      [currentDocument]="absi"
      [headerClass]="'h5'"
    ></comments-and-attachments>
  </div>

  <div class="dummySpace"></div>
  <!--This is so that the main section does not show a blank space when the side panel is open-->
</div>
