export class Link {
  public id?: number;
  public hyperlink?: string;
  public webAddress?: string;
  public typeId?: number;
  public typeName?: string;
  public linkType?: LinkType;
  public status?: number;
  isModified?: boolean;
  isDeleted?: boolean;
  isAdded?: boolean;
}

export class LinkType {
  public id?: number;
  public name?: string;
  public status?: number;
}
