<div *ngIf="show">
  <h5 class="section-title no-break-inside">History Log</h5>
  <mat-progress-bar
    class="progress-bar"
    mode="indeterminate"
    *ngIf="loading"
  ></mat-progress-bar>
</div>
<div class="section p-2 mt-3" *ngIf="show">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Approvals">
      <div class="tab-container">
        <mat-slide-toggle [(ngModel)]="showBoxes"
          >Show Signature Boxes</mat-slide-toggle
        >
        <div *ngIf="showBoxes" class="d-flex flex-wrap">
          <div
            class="log-signature full-height"
            *ngFor="let signature of signLogs"
          >
            <scf-log-signature
              class="d-block full-height"
              [signature]="signature"
            ></scf-log-signature>
          </div>
        </div>

        <mat-list *ngIf="!showBoxes">
          <mat-list-item *ngFor="let signature of signLogs">
            <div class="d-flex align-items-center">
              <span class="date">{{
                signature.signedOn | date : "medium"
              }}</span>
              <mat-icon color="primary" mat-list-icon>arrow_right_alt</mat-icon>

              <span>
                <b class="blue"> {{ signature.name }}</b> by
                <b>{{ signature.signedBy }} </b></span
              >
              <mat-icon
                color="accent"
                class="ml-3"
                *ngIf="signature.reason && signature.reason != ''"
                >comment</mat-icon
              >
              <span
                class="reason"
                *ngIf="signature.reason && signature.reason != ''"
              >
                <span class="comment"> {{ signature.reason }}</span></span
              >
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-tab>
    <mat-tab label="Amendments" *ngIf="showUpdates">
      <mat-accordion class="px-2" [multi]="true">
        <!--Original body-->
        <mat-expansion-panel *ngIf="this.generalLogs" class="mx-2 mb-1 mt-0">
          <mat-expansion-panel-header>
            <mat-panel-title> Original Form </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="original-wrapper" #original>
            <scf-v2-main-s1
              #s0
              [scfMaster]="originalBody"
              [disabled]="true"
              [status]="status"
            >
            </scf-v2-main-s1>
            <scf-v2-main-s2
              #s2
              [scfMaster]="originalBody"
              [disabled]="true"
              [status]="status"
            ></scf-v2-main-s2>
          </div>
        </mat-expansion-panel>

        <!-- Updates -->
        <div *ngIf="showUpdates">
          <mat-expansion-panel
            #panel
            *ngFor="let update of generalLogs?.updates"
            class="card mx-2"
            (afterExpand)="fillUpdates()"
          >
            <mat-expansion-panel-header class="card-header">
              <mat-panel-title>
                <h6 class="ml-2 mb-0">
                  <span [innerHTML]="update.header"></span>

                  <span class="date small ml-3">{{
                    update?.amendmentDate | date : "medium"
                  }}</span>
                </h6>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="card-body relative" #parent>
              <div class="amendment-slide" [ngStyle]="getTopPosition(parent)">
                <mat-slide-toggle
                  *ngIf="update.status"
                  [(ngModel)]="update.status.isViewingAmendment"
                  class="float-right d-print-none mr-3 pt-1"
                  (ngModelChange)="s1.getValues(); s2.getValues()"
                  name="amendment"
                  color="accent"
                  >Show Amendment</mat-slide-toggle
                >
              </div>

              <div
                class="original-wrapper"
                [ngClass]="{
                  'amendment-wrapper': update.status?.isViewingAmendment
                }"
              >
                <scf-v2-main-s1
                  #s1
                  [scfMaster]="update.scfMaster"
                  [disabled]="true"
                  [status]="update.status"
                >
                </scf-v2-main-s1>
                <scf-v2-main-s2
                  #s2
                  [scfMaster]="update.scfMaster"
                  [disabled]="true"
                  [status]="update.status"
                ></scf-v2-main-s2>
                <div class="card mt-3">
                  <div class="card-header">
                    <h6>Signatures</h6>
                  </div>
                  <div class="card-body">
                    <div class="d-flex flex-wrap">
                      <div
                        class="log-signature full-height"
                        *ngFor="let signature of update?.signs"
                      >
                        <scf-log-signature
                          [signature]="signature"
                          class="full-height d-block"
                        >
                        </scf-log-signature>
                      </div>
                      <div *ngIf="update?.signs?.length == 0">
                        <h6>There are not signatures</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
    </mat-tab>
  </mat-tab-group>
</div>
<div style="height: 1em; width: 100%"></div>
