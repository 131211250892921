import { Component, EventEmitter, Injector, Input, Output, QueryList, SimpleChanges, ViewChildren, ViewContainerRef } from '@angular/core';
import { MatTableDataSource, MatRow } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { FormStatusEnum, FormType } from 'src/app/common/enumerations/enumerations';
import { utils } from 'src/app/modules/libs/utils';
import { SCFMaster } from '../../scf-v2/scf';
import { ScfV2Service } from '../../scf-v2/scf-v2.service';
import { SCFMastersUpdate } from '../../scf-v2/store/scf-master/scf-masters.action';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'template-side',
  templateUrl: './template-side.component.html',
  styleUrls: ['./template-side.component.scss']
})
export class TemplateSideComponent extends BaseComponent {

  @Input() filter?: string | null;
  @Output() loading = new EventEmitter<boolean>();

  displayedColumns: string[] = [
    'serialNo',
    'locationNames',
    'statusName',
  ];
  dataSource = new MatTableDataSource<SCFMaster>();

  @Output() rowSelected = new EventEmitter<any>();

  @ViewChildren('matrow', { read: ViewContainerRef }) rows?: QueryList<ViewContainerRef>;

  scfMasters: SCFMaster[] = [];
  scfMasters$!: Observable<SCFMaster[]>;
  scfMastersSubs: Subscription = new Subscription;
  scfMaster?: SCFMaster | null;

  @ViewChildren('matrow') datarows?: QueryList<MatRow>;

  serialNo!: string;
  selectedRowIndex? = -1;
  selectedIndex = -1;
  showClosed = false;

  allowNew = false;
  disabled = false;
  maxHeight!: string;

  constructor(
    private service: ScfV2Service,
    protected override injector: Injector
  ) {
    super(injector);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter']) {
      this.applyFilter();
    }
  }

  override ngOnDestroy(): void {
    this.scfMastersSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.loadSCFs();
  }

  loadSCFs() {
    // this.loading.emit(true);
    // this.scfMasters$ = this.store.select(state => state.SCFMasters.data);
    // this.scfMastersSubs = this.scfMasters$.subscribe(async data => {
    //   if (data?.length > 0) {
    //     this.scfMasters = data.filter(x => x.serialNo !== '');
    //     this.createDataSource();
    //     if (this.scfMaster) {
    //       let scfMaster = this.scfMasters.find(x => x.id == this.scfMaster?.id);
    //       if (scfMaster) {
    //         if (this.scfMaster.retrievedBy != this.getCurrentUser()?.id) {
    //           await this.getValues(scfMaster);
    //         }
    //         this.scfMaster = scfMaster;
    //         this.showClosed = this.scfMaster.status == FormStatusEnum.Closed || this.scfMaster.status == FormStatusEnum.Canceled;
    //         this.createDataSource();
    //         this.scfMaster.retrievedBy = this.getCurrentUser()?.id;
    //       }
    //       this.rowSelected.emit(this.scfMaster);
    //     }
    //     else if (this.service?.currentDoc?.id) {
    //       this.showClosed = this.service.currentDoc.status == FormStatusEnum.Canceled as number || this.service.currentDoc?.status == FormStatusEnum.Closed;
    //       this.createDataSource();
    //       await this.getValues(this.service.currentDoc);
    //     }
    //     else {
    //       this.loading.emit(false);
    //     }
    //   }
    // });
  }

  createDataSource() {
    this.dataSource = new MatTableDataSource(this.scfMasters?.filter(x => ((x.status !== FormStatusEnum.Canceled && x.status !== FormStatusEnum.Closed) && !this.showClosed) || this.showClosed).sort((a, b) => utils.sort(a.serialNo, b.serialNo, false)));
  }

  rowUnselected() {
    this.scfMaster = null;
    this.selectedRowIndex = -1;
    // this.service.currentDoc = null;
    this.rowSelected.emit(null);
  }

  rowClicked(row: SCFMaster) {
    if (this.scfMaster?.id == row.id) {
      this.rowUnselected();
      this.loading.emit(false);
    }
    else {
      this.getValues(row);
    }
  }

  async getValues(row: SCFMaster) {
    this.loading.emit(true);
    this.selectedIndex = this.dataSource.data.findIndex(x => x.id === row.id);
    if (row?.id) {
      this.scfMaster = await this.getSCFMasterByID(row.id);
      if (this.scfMaster && this.scfMaster?.id) {
        this.selectedRowIndex = this.scfMaster?.id;
        // this.service.currentDoc = this.scfMaster;
        this.store.dispatch(new SCFMastersUpdate(this.scfMaster?.id, this.scfMaster));
        this.rowSelected.emit(this.scfMaster);
        this.loading.emit(false);
        this.showElement(this.selectedIndex, 7);
      }
    }
  }

  getSCFMasterByID(id: number): Promise<SCFMaster> {
    return new Promise((resolve, reject) => {
      this.service.getById(id).toPromise().then(scfMaster => {
        if (scfMaster) {
          scfMaster.retrievedBy = this.getCurrentUser()?.id;
          resolve(scfMaster);
        }
      });
    });
  }

  showDocument(e: SCFMaster) {
    this.getValues(e);
  }

  //#endregion LOAD

  //#region ADD

  createSCF() {
    this.selectedRowIndex = -1;
    this.serialNo = '{Serial No}';
    // this.service.currentDoc = {
    //   id: 0,
    //   serialNo: this.serialNo,
    //   type: FormType.SCF,
    //   status: 1,
    //   locations: [],
    //   statusName: 'New',
    //   scfRestrictionsV2: [],
    //   wasRouted: false,
    // };
  }

  unselect() {
    this.createSCF();
  }

  //#endregion

  //#region FILTERS

  applyFilter() {
    this.createDataSource();
    this.dataSource.filter = this.filter?.trim().toLowerCase() ?? '';
  }

  changeClosed() {
    this.applyFilter();
  }

  async refresh() {

  }

  //#endregion

  // #region TableScroll
  showElement(index: number, height: number) {
    const indexstr = index.toString();
    const row = this.rows?.find(
      (r) => r.element.nativeElement.getAttribute('position') === indexstr
    );
    if (row) {
      const rect = row.element.nativeElement.getBoundingClientRect();
      if (rect.y <= 0 || rect.y + rect.height > height) {
        row.element.nativeElement.scrollIntoView(false, {
          behavior: 'instant',
        });
      }
      return;
    }
    console.log('not found');
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex((r) => {
      return r.id === row.id;
    });
    return i;
  }

  formatLocationNames(locationNames: string) {
    if (locationNames) {
      return utils.replaceAll(locationNames, ',', ', ');
    }
    return '';
  }

}
