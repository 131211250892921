import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Store } from '@ngxs/store';
import { SetCurrentUser } from 'src/app/store/actions';
import { User } from '../../components/catalogs/user-catalog/services/user';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationResponse } from 'src/app/common/models/authentication';
import { Observable, Subject } from 'rxjs';
import { AlertService } from '../alert/alert.service';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { MessageTypeEnum } from 'src/app/components/messages/services/message-banner.enums';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private cookieSubject = new Subject<string>();
  private cookieName = '_shibobj';
  private cookieValue?: string;
  private intervalId?: any;
  constructor(
    private http: HttpClient,
    private store: Store,
    private alert: AlertService,
    private cookieService: CookieService,
  ) {
    if (document.location.hostname === environment.productionEnvironment) {
      // Initialize the cookie value
      this.cookieValue = this.cookieService.get(this.cookieName);
      this.cookieSubject.next(this.cookieValue);

      // Poll for changes in the cookie value every 5 seconds (adjust as needed)
      this.intervalId = setInterval(() => {
        const newValue = this.cookieService.get(this.cookieName);
        const token = localStorage.getItem('Token');

        if (token && !environment.token)
          environment.token = token;
        if (environment.token && !token)
          this.logout();

        if (newValue !== this.cookieValue) {
          // If the value has changed, emit the new value to subscribers
          this.cookieValue = newValue;
          this.cookieSubject.next(this.cookieValue);
          if (!this.cookieValue)
            clearInterval(this.intervalId);
        }
      }, 5000); // 5000 milliseconds = 5 seconds
    }
  }

  getMe() {
    return this.http.get<any>(environment.urls.baseApiUrl + '/Authentication');
  }

  loginMFA(email: string) {
    const obj = {
      emailAddress: email
    };
    return this.http.patch<AuthenticationResponse>(environment.urls.baseApiUrl + '/Authentication', obj)
      .pipe(map(response => {
        // login successful if there's a jwt token in the response
        if (response.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.store.dispatch(new SetCurrentUser(response.token));
          this.showMessage(response);
        }
        return response;
      }));
  }

  authenticate(user: User): Observable<AuthenticationResponse> {
    return this.http.post<AuthenticationResponse>(environment.urls.baseApiUrl + '/Authentication', user)
      .pipe(map(response => {
        if (response.code == 202 && response.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.store.dispatch(new SetCurrentUser(response.token));
        }
        return response;
      }),
        catchError(this.handleError)
      );
  }

  showMessage(response: AuthenticationResponse) {
    this.alert.message('Hello <b>{name}</b><br>Welcome to ASCC!', [new MessagePlaceholder('{name}', response.user.name)], MessageTypeEnum.Success, 'Successful Login!');
  }

  logout() {
    environment.token = '';
    this.cookieService.delete('_shibobj', '/');
    localStorage.clear();
    this.store.dispatch(new SetCurrentUser(null));
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error.message); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  getCookieValue() {
    return this.cookieSubject.asObservable();
  }

}
