<div class="card">
  <div class="card-header">
    <span class="card-title"
      >Request Extension for {{ header(data.name) }}</span
    >
  </div>
  <div class="card-body">
    <form [formGroup]="formNote">
      <div class="row">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Extension Date</mat-label>
            <input
              matInput
              [matDatepicker]="startDate"
              placeholder="Due Date"
              name="Extension Date"
              [min]="data.expireDate"
              formControlName="extendedDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="startDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
            <mat-error>Date must be greater than grace period</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Reason</mat-label>
            <textarea matInput formControlName="description"></textarea>
            <mat-error *ngIf="formNote">Reason is required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="!formNote.valid"
        cdkFocusInitial
      >
        Submit
      </button>
    </div>
  </div>
</div>
