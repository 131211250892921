import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransactionLog } from 'src/app/services/logs/transaction-logs';
import { TOCA } from 'src/app/components/catalogs/beamline-catalog/resource/toca';
import { environment } from 'src/environments/environment';
import { BeamlineRequiringApproval } from './models/beamline-requiring-approval';
import { Action } from 'src/app/common/enumerations/enumerations';


@Injectable({
  providedIn: 'root'
})
export class BratoiService {

  public api: string = environment.urls.baseApiUrl + '/BeamlineRequiringApproval';

  constructor(
    private http: HttpClient
  ) { }

  GetBeamlinelApprovals(): Observable<BeamlineRequiringApproval[]> {
    return this.http.get<BeamlineRequiringApproval[]>(this.api);
  }

  GetBeamlinelApproval(id: number): Observable<BeamlineRequiringApproval> {
    return this.http.get<BeamlineRequiringApproval>(this.api + '/' + id);
  }

  GetHistoryByBlRequiring(id: number): Observable<TransactionLog[]> {
    return this.http.get<TransactionLog[]>(this.api + '/History/' + id);
  }

  CreateBlApproval(blRequiringApproval: BeamlineRequiringApproval): Observable<BeamlineRequiringApproval> {
    return this.http.post<BeamlineRequiringApproval>(this.api, blRequiringApproval);
  }

  UpdateBlApproval(blRequiringApproval: BeamlineRequiringApproval): Observable<BeamlineRequiringApproval> {
    return this.http.put<BeamlineRequiringApproval>(this.api + '/' + blRequiringApproval.id, blRequiringApproval);
  }

  DeleteBlApproval(id: number) {
    return this.http.delete(this.api + '/' + id);
  }

  UpdateActionBlApproval(id: number, action: Action, blRequiringApproval: BeamlineRequiringApproval): Observable<any> {
    return this.http.put<BeamlineRequiringApproval>(this.api + '/' + id + '/' + action, blRequiringApproval);
  }

  //TOCA
  GetActiveTOCAs(): Observable<TOCA[]> {
    return this.http.get<TOCA[]>(this.api + '/TOCA');
  }
}
