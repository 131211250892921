<div class="comments-container">
  <comments-and-attachments
    [currentDocument]="review"
    [SerialNo]="serialNo"
    [ShowFiles]="false"
    Title=""
    [disabled]="disabled"
    (changed)="loadComments()"
  ></comments-and-attachments>
</div>
