import { Injectable } from '@angular/core';
import { utils } from 'src/app/modules/libs/utils';

@Injectable({
  providedIn: 'root',
})
export class XMLService {
  parseXml(xmlString: string): any {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
    // Handle CDATA in the 'details' field
    const detailsElement = xmlDoc.getElementsByTagName('details')[0];
    const cdataContent = detailsElement?.textContent?.trim() ?? '';

    return {
      root: {
        author: xmlDoc.getElementsByTagName('author')[0]?.textContent,
        password: xmlDoc.getElementsByTagName('password')[0]?.textContent,
        entry: {
          cc: xmlDoc.getElementsByTagName('cc')[0]?.textContent,
          level: xmlDoc.getElementsByTagName('level')[0]?.textContent,
          subject: xmlDoc.getElementsByTagName('subject')[0]?.textContent,
          details: cdataContent,
          categories: Array.from(xmlDoc.getElementsByTagName('category')).map(
            (cat) => cat.textContent
          ),
          highlight: xmlDoc.getElementsByTagName('highlight')[0]?.textContent,
        },
      },
    };
    // const jsonObj = this.xmlToJson(xmlDoc);

    // const jsonText = utils.JSONstringify(jsonObj).replaceAll('#text', 'value');
    // return utils.JSONparse(jsonText);
  }

  private xmlToJson(xml: any): any {
    // Create the return object
    let obj: any = {};

    // If the node is an element
    if (xml.nodeType === 1) {
      // Do attributes
      if (xml.attributes.length > 0) {
        obj['@attributes'] = {};
        for (let j = 0; j < xml.attributes.length; j++) {
          const attribute = xml.attributes.item(j);
          obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType === 3) {
      // text node
      obj = xml.nodeValue.trim();
    }

    // Do children nodes
    if (xml.hasChildNodes()) {
      for (let i = 0; i < xml.childNodes.length; i++) {
        const item = xml.childNodes.item(i);
        const nodeName = item.nodeName;
        if (typeof obj[nodeName] === 'undefined') {
          const nodeValue = this.xmlToJson(item);
          if (nodeValue !== '') {
            obj[nodeName] = nodeValue;
          }
        } else {
          if (typeof obj[nodeName].push === 'undefined') {
            const old = obj[nodeName];
            obj[nodeName] = [old];
          }
          const nodeValue = this.xmlToJson(item);
          if (nodeValue !== '') {
            obj[nodeName].push(nodeValue);
          }
        }
      }
    }
    return obj;
  }
}
