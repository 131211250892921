import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ResourceGroup } from '../cl-editor-location/cl-editor-location.component';
import { FormControl } from '@angular/forms';
import { ResourceType } from 'src/app/common/enumerations/enumerations';
import { Resource, ResourceRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'cl-editor-sublocations',
  templateUrl: './cl-editor-sublocations.component.html',
  styleUrls: ['./cl-editor-sublocations.component.scss']
})
export class ClEditorSublocationsComponent implements OnInit, OnDestroy, OnChanges {

  @Output() configurationChanged = new EventEmitter<string>();
  @Input() configuration?: string;

  resources!: Resource[];
  resourcesFiltered!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  resourceRelations!: ResourceRelation[];
  resourceRelations$!: Observable<ResourceRelation[]>;
  resourceRelationsSubs!: Subscription;

  shieldingConfiguration!: ShieldingConfiguration;

  // public locations: Resource[] = [];
  public location?: Resource = new Resource();

  longShutdown!: boolean;
  inTunnel!: boolean;
  locationCtrl = new FormControl();

  resourceGroups!: ResourceGroup[];

  constructor(
    private store: Store
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadResources();
    this.loadResourceRelations();
    this.shieldingConfiguration = utils.JSONparse(this.configuration);
    this.location = this.resources?.find(x => x.id == this.shieldingConfiguration?.location);
    this.locationCtrl.setValue(this.location);
    this.longShutdown = this.shieldingConfiguration.longShutdown;
    this.inTunnel = this.shieldingConfiguration.inTunnel;
  }

  ngOnDestroy(): void {
    this.resourcesSubs?.unsubscribe();
    this.resourceRelationsSubs?.unsubscribe();
  }

  ngOnInit() {
    this.loadResources();
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data.length) {
        this.resources = data;
        this.resourcesFiltered = this.resources.filter(x => x.type == ResourceType.Accelerator);
      }
    });
  }

  loadResourceRelations() {
    this.resourceRelations$ = this.store.select(state => state.ResourceRelations.data);
    this.resourceRelationsSubs = this.resourceRelations$.subscribe(data => {
      if (data.length) {
        this.resourceRelations = data;
        this.createLocationGroups();
      }
    });
  }

  displayLocation(e: any) {
    return e ? e.name : null;
  }

  changed() {
    this.shieldingConfiguration = new ShieldingConfiguration();
    this.shieldingConfiguration.location = this.locationCtrl.value?.id;
    this.shieldingConfiguration.longShutdown = this.longShutdown;
    this.shieldingConfiguration.inTunnel = this.inTunnel;
    this.configurationChanged.emit(utils.JSONstringify(this.shieldingConfiguration));
  }

  createLocationGroups() {
    this.resourceGroups = [];
    this.resourcesFiltered.map(r => {
      if (r.type == ResourceType.Accelerator) {
        if (!this.resourceGroups.map(g => g.id).includes(r.id)) {
          const relations = this.resourceRelations?.filter(rr => rr.parentResourceID == r.id && rr.childResource?.status == 1);
          const locs = relations?.filter(l => l.childResourceType == ResourceType.AcceleratorLocation).map(rr => {
            return { id: rr.childResourceID, name: rr.childResourceName, type: rr.childResourceType } as Resource;
          });
          if (locs?.length) {
            this.resourceGroups.push({ name: r.name, type: r.type, id: r.id, locations: locs.sort((a, b) => utils.SortBeamlines(a.name, b.name)) });
          }
        }
      }
      else {
        if (!this.resourceGroups.map(g => g.type).includes(r.type) && r.type != ResourceType.AcceleratorLocation) {
          this.resourceGroups.push({ name: r.resourceType?.name, type: r.type, locations: this.resourcesFiltered.filter(x => x.type == r.type).sort((a, b) => utils.SortBeamlines(a.name, b.name)) });
        }
      }
    });
    this.resourceGroups = this.resourceGroups.sort((a, b) => (a.type ?? 0) - (b.type ?? 0));
  }

  displayObject(e: any) {
    return e ? e.name : null;
  }

  change(e: any) {
    const value = (e.target.value as string).toLowerCase();
    this.resourcesFiltered = this.resources.filter(x => x.type == ResourceType.Accelerator);
    this.resourcesFiltered = this.resourcesFiltered.filter(x => x.name?.toLowerCase().includes(value));
    this.createLocationGroups();
  }


}

export class ShieldingConfiguration {
  location!: number;
  longShutdown!: boolean;
  inTunnel!: boolean;
}
