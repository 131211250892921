import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { DocumentType } from 'src/app/services/documents/documents';


@Component({
  selector: 'app-document-type-list',
  templateUrl: './document-type-list.component.html',
  styleUrls: ['./document-type-list.component.scss']
})
export class DocumentTypeListComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() filter?: string;
  @Output() documentTypeSelected = new EventEmitter<DocumentType>();
  @Output() loading = new EventEmitter<boolean>();

  public documentTypes$!: Observable<DocumentType[]>;
  public documentTypes!: DocumentType[];
  public documentTypeSubscription!: Subscription;

  public documentTypeSelectedIndex = -1;

  public displayedColumnsDocumentType: string[] = ['name', 'description'];
  public dataSourceDocumentType: MatTableDataSource<DocumentType> = new MatTableDataSource<DocumentType>();

  constructor(
    protected override injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loadDocumentTypes();
  }

  override ngOnDestroy(): void {
    this.documentTypeSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  loadDocumentTypes() {
    this.loading.emit(true);
    this.documentTypes$ = this.store.select(state => state.DocumentType.data);
    this.documentTypeSubscription = this.documentTypes$.subscribe(documentTypes => {
      this.documentTypes = documentTypes;
      this.dataSourceDocumentType = new MatTableDataSource(this.utils.cloneDeep(documentTypes.filter(x => x.showInCatalog)));
      this.loading.emit(false);
    });
  }

  async getDocumentType(documentTypeSelected: DocumentType) {
    if (await this.canDeactivate()) {
      if (documentTypeSelected.id === 11) { // ABSI
        documentTypeSelected.documentTemplateId = 10; // ABSI Template
      } else {
        documentTypeSelected.documentTemplateId = this.documentTypes.find(x => x.type === documentTypeSelected.id)?.id;
      }
      this.documentTypeSelectedIndex = documentTypeSelected.id;
      this.documentTypeSelected.emit(documentTypeSelected);
    }
  }

  async createDocumentType() {
    if (await this.canDeactivate()) {
      this.documentTypeSelectedIndex = 0;
      this.documentTypeSelected.emit({ id: 0 });
    }
  }

  sortData(sort: Sort) {
    const dataToSort = this.utils.cloneDeep(this.dataSourceDocumentType.data);
    if (!sort.active || sort.direction === '') {
      this.dataSourceDocumentType.data = dataToSort;
      return;
    }
    this.dataSourceDocumentType.data = dataToSort.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.utils.sort(a.name, b.name, isAsc);
        case 'description': return this.utils.sort(a.description, b.description, isAsc);
        default: return 0;
      }
    });
  }

  // pendingChanges(index: number, documentType: DocumentType) {
  //   const dialogConfig: MatDialogConfig = {
  //     height: 'fit-content',
  //     width: '40%'
  //   };
  //   const dialogRef = this.dialog.open(PendingChangesDialogComponent, dialogConfig);
  //   dialogRef.afterClosed().toPromise().then((pendingChangesResponse: PendingChangesDialogResponse) => {
  //     switch (pendingChangesResponse) {
  //       case PendingChangesDialogResponse.Discard:
  //         this.documentTypeSelectedIndex = index;
  //         this.documentTypeSelected.emit(documentType);
  //         break;
  //     }
  //   });
  // }

  applyFilter(filterValue: string) {
    this.dataSourceDocumentType.filter = filterValue.trim().toLowerCase();
  }

}
