import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as SchedulesActions from 'src/app/components/schedules/store/schedules/schedule.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';

@Injectable({
  providedIn: 'root'
})
export class SchedulesStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore() {
    this.store.dispatch(new SchedulesActions.SchedulesRefresh());
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.SCHEDULES, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new SchedulesActions.SchedulesCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new SchedulesActions.SchedulesUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new SchedulesActions.SchedulesDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new SchedulesActions.SchedulesRefresh());
      }
    });
  }
}
