<div class="card p-1 file">
  <div (click)="obtainFile(rootDirectory)">
    <div class="row no-gutters">
      <div class="col-4 col-sm-2">
        <img
          alt="image"
          style="margin: auto"
          class="icon"
          src="{{ fileIcon }}"
        />
      </div>
      <div class="col-8 col-sm-10 small">
        <span>{{ fileName }}</span
        ><br />
        <span class="dim">{{ fileSize }}</span>
      </div>
    </div>
  </div>
  <mat-icon
    *ngIf="!disabled"
    class="delete"
    color="warn"
    (click)="deleteAttachment(id, rootDirectory)"
    >delete</mat-icon
  >
</div>
