<div class="row">
  <div class="col-9 pr-0">
    <fieldset class="field-group">
      <legend>Headers</legend>
      <div class="headers p-1">
        <div class="headers-row small">
          <div
            *ngFor="let column of columns"
            class="header-container pt-3 pb-2"
          >
            <div
              class="border d-flex align-items-center relative"
              *ngIf="
                !showHtml &&
                column.name != 'values' &&
                column.name != 'actions' &&
                column.name != 'placeholder'
              "
              [ngClass]="{
                fixed:
                  column.type == 'group' ||
                  column.name == 'data' ||
                  column.name == 'notes',
                notsaved:
                  showSave(column) &&
                  column.type != 'group' &&
                  column.name != 'data'
              }"
            >
              <quill-editor
                [(ngModel)]="column.label"
                theme="bubble"
                placeholder=""
                class="small"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="
                  column.type == 'group' ||
                  column.name == 'data' ||
                  column.name == 'notes' ||
                  (disabled ?? false)
                "
              >
              </quill-editor>
              <div
                class="small btnx pointer bottom-box"
                [ngClass]="{ btny: showSave(column) }"
                *ngIf="
                  column.type != 'group' &&
                  column.name != 'data' &&
                  column.name != 'notes' &&
                  !disabled
                "
              >
                <mat-icon (click)="delete(column)" color="warn"
                  >delete_outline</mat-icon
                >
                <mat-icon
                  *ngIf="showSave(column)"
                  (click)="headerChange(column)"
                  color="primary"
                  >save</mat-icon
                >
              </div>
              <div
                class="d-flex justify-content-between small btnx pointer top-box"
                *ngIf="
                  column.type != 'group' &&
                  column.name != 'data' &&
                  column.name != 'notes' &&
                  !showSave(column) &&
                  !disabled
                "
              >
                <mat-icon
                  *ngIf="checkMove(column, -1)"
                  (click)="moveColumn(column, -1)"
                  color="accent"
                  >arrow_back</mat-icon
                >
                <mat-icon *ngIf="!checkMove(column, -1)" color="warn"
                  >arrow_back</mat-icon
                >
                <mat-icon
                  *ngIf="checkMove(column, 1)"
                  (click)="moveColumn(column, 1)"
                  color="accent"
                  >arrow_forward</mat-icon
                >
                <mat-icon *ngIf="!checkMove(column, 1)" color="warn"
                  >arrow_forward</mat-icon
                >
              </div>
              <div
                class="small btnx pointer top-box"
                *ngIf="column.name == 'reading' && !disabled"
                matTooltip="Move left"
              >
                <mat-icon
                  (click)="move(-1)"
                  *ngIf="checkMove(column, -1)"
                  color="accent"
                  >arrow_back</mat-icon
                >
                <mat-icon *ngIf="!checkMove(column, -1)" color="warn"
                  >arrow_back</mat-icon
                >
              </div>
              <div
                class="small btnx pointer float-right top-box"
                *ngIf="column.name == 'calcdose' && !disabled"
                matTooltip="Move right"
              >
                <mat-icon
                  (click)="move(1)"
                  *ngIf="checkMove(column, 1)"
                  color="accent"
                  >arrow_forward</mat-icon
                >
                <mat-icon *ngIf="!checkMove(column, 1)" color="warn"
                  >arrow_forward</mat-icon
                >
              </div>
            </div>
            <div *ngIf="showHtml" class="input-sizer stacked">
              <textarea
                [disabled]="disabled ?? false"
                aria-label="html"
                matInput
                onmouseover="this.parentNode.dataset.value = this.value"
                onfocus="this.parentNode.dataset.value = this.value"
                oninput="this.parentNode.dataset.value = this.value"
                (change)="headerChange(column)"
                rows="1"
                [(ngModel)]="column.label"
                appTextareaAutoresize
              ></textarea>
            </div>
          </div>
        </div>
        <div class="small d-flex flex-column justify-content-between pr-1">
          <div class="row">
            <div class="col-6">
              <div
                *ngIf="!disabled"
                class="btn pointer mt-1"
                (click)="add()"
                matTooltip="Add Header"
              >
                <mat-icon>add</mat-icon>
              </div>
            </div>
            <div class="col-6">
              <div
                class="btn pointer mt-1 float-right"
                (click)="showHtml = !showHtml"
                matTooltip="Show HTML"
              >
                <mat-icon>code</mat-icon>
              </div>
            </div>
          </div>
          <div>
            <mat-slide-toggle
              color="accent"
              (change)="changeToggle()"
              [(ngModel)]="columnsToggle"
              >Left/Right Columns
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="d-flex flex-row w-100 justify-content-between pt-2">
      <mat-slide-toggle
        [disabled]="disabled ?? false"
        color="primary"
        (change)="change()"
        [(ngModel)]="colorToggle"
        >Colorize Columns
      </mat-slide-toggle>
      <mat-slide-toggle
        [disabled]="disabled ?? false"
        color="primary"
        (change)="change()"
        [(ngModel)]="incIMToggle"
        >Include Integrated Measurements
      </mat-slide-toggle>
      <mat-slide-toggle
        [disabled]="disabled ?? false"
        color="primary"
        (change)="change()"
        [(ngModel)]="channelsToggle"
        >Include PV Data
      </mat-slide-toggle>
    </div>
    <!-- <div class="mt-2" *ngIf="channelsToggle">
      <cl-editor-table-channels
        (changed)="channelsChanged($event)"
        [channels]="channels"
      ></cl-editor-table-channels>
    </div> -->
  </div>

  <div class="col-3 pl-3">
    <div class="row">
      <div class="col-12 pt-0 pb-2">
        <cl-editor-image
          [disabled]="disabled"
          [configuration]="configuration"
          (changed)="imageChanged($event)"
        >
        </cl-editor-image>
      </div>
    </div>

    <fieldset class="field-group" *ngIf="!disabled">
      <legend>Table Rows</legend>
      <div class="row">
        <div class="col-6">
          <div class="d-flex flex-column justify-content-between h-100">
            <button mat-stroked-button color="primary" (click)="addTableRow()">
              <mat-icon>playlist_add</mat-icon> Add Row
            </button>

            <mat-slide-toggle color="primary" [(ngModel)]="rowsToggle"
              >Show Rows
            </mat-slide-toggle>
          </div>
        </div>
        <div class="col-6" *ngIf="rowsToggle">
          <div class="rows">
            <div class="hot-row" *ngFor="let r of rows">
              <div class="row no-gutters">
                <div class="col-2 table-gray text-red">
                  {{ r.rowLabel }}
                </div>
                <div
                  class="col-3 border-bottom border-top border-right"
                  [ngClass]="{ 'table-green': colorToggle }"
                ></div>
                <div
                  class="col-3 border-bottom border-top border-right"
                  [ngClass]="{
                    'table-yellow': colorToggle,
                    'border-left': !colorToggle
                  }"
                ></div>
                <div
                  *ngIf="!disabled"
                  class="col-2 px-1 small"
                  (click)="duplicateRow(r)"
                  [matTooltip]="'Duplicate entire row ' + r.rowLabel"
                >
                  <mat-icon color="primary" class="small-icon"
                    >content_copy</mat-icon
                  >
                </div>
                <div
                  class="col-2 pl-1 small"
                  (click)="deleteRow(r)"
                  *ngIf="displayDeleteButtonRow() && !disabled"
                  [matTooltip]="'Delete row ' + r.rowLabel"
                >
                  <mat-icon color="warn" class="small-icon"
                    >delete_outline</mat-icon
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</div>
