<div class="row no-gutters">
  <div class="col-12">
    <span class="small float-right">
      <mat-slide-toggle
        color="warn"
        (change)="changeClosed()"
        [(ngModel)]="showClosed"
        >Show Closed/Canceled Checklists
      </mat-slide-toggle>
    </span>
  </div>
</div>

<div class="side-panel-table">
  <div class="left-table-container table-container mat-container scrollbar">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="serialNo">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          No.
        </mat-header-cell>
        <mat-cell
          class="font-weight-bold"
          [ngClass]="element.checklistStatus?.cssClass"
          *matCellDef="let element"
          nowrap
        >
          <span
            [innerHTML]="element.serialNo | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="resourceName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Resource
        </mat-header-cell>
        <mat-cell
          class="pl-1"
          [ngClass]="element.checklistStatus?.cssClass"
          *matCellDef="let element"
        >
          <span
            [innerHTML]="element.resourceName | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="typeName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Type
        </mat-header-cell>
        <mat-cell
          class="pl-1"
          [ngClass]="element.checklistStatus?.cssClass"
          *matCellDef="let element"
        >
          <span
            [innerHTML]="
              element.checklistTemplate?.templateType.name
                | highlightSearch : filter
            "
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="procedureNo">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Procedure
        </mat-header-cell>
        <mat-cell
          class="pl-1"
          [ngClass]="element.checklistStatus?.cssClass"
          *matCellDef="let element"
        >
          <span
            [innerHTML]="
              element.checklistTemplate?.procedure?.procedureNumber
                | highlightSearch : filter
            "
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="review">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Review
        </mat-header-cell>
        <mat-cell
          class="pl-1"
          [ngClass]="element.checklistStatus?.cssClass"
          *matCellDef="let element"
        >
          <span
            [innerHTML]="
              (element.review?.reviewType?.code ?? '') +
                ' ' +
                (element.review?.reviewCycle?.location ?? '') +
                '<br>' +
                (element.review?.reviewStatus?.name
                  ? '(' + element.review?.reviewStatus?.name + ')'
                  : '') | highlightSearch : filter
            "
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="statusName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </mat-header-cell>
        <mat-cell
          class="pl-1"
          [ngClass]="element.checklistStatus?.cssClass"
          *matCellDef="let element"
        >
          <span
            [innerHTML]="
              (element.checklistStatus?.description
                ? element.checklistStatus?.description.replace('Checklist', '')
                : element.statusName
              ) | highlightSearch : filter
            "
          ></span>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        #matrow
        *matRowDef="let row; columns: displayedColumns"
        (click)="getValues(row)"
        class="table-row"
        style="white-space: nowrap"
        [ngClass]="{
          'mat-row-highlight': selectedRowIndex == row.id
        }"
        [attr.position]="getPosition(row)"
      ></mat-row>
    </mat-table>
  </div>
</div>
