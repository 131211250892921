<mat-expansion-panel
  class="card mb-2 section-expansion"
  [hideToggle]="!section?.collapsible"
  [expanded]="isSectionExpanded()"
  (opened)="saveState(false)"
  (closed)="saveState(true)"
  [disabled]="!section?.collapsible"
>
  <mat-expansion-panel-header
    class="card-header"
    [ngClass]="{ 'no-pointer': !section?.collapsible }"
  >
    <div class="row full-width d-flex justify-content-between">
      <div class="col flex-grow-2">
        <div class="d-flex align-items-center">
          <h5 class="border0">{{ section?.number }}{{ section?.code }}.</h5>
          <h5 class="section-name pl-1">{{ section?.name }}</h5>
          <button
            class="ml-2"
            mat-icon-button
            (click)="duplicate($event)"
            color="primary"
            *ngIf="
              section?.status == wfSectionLocalStatus.NONE &&
              config.duplicate &&
              !checklistClosed
            "
            [disabled]="!userCanDuplicate"
          >
            <mat-icon [matTooltip]="tooltip">content_copy</mat-icon>
          </button>
          <div
            class="small ml-2"
            *ngIf="section?.status == wfSectionLocalStatus.DUPLICATED"
          >
            <span class="pl-2">Duplicated by</span>
            <span class="pl-1 bold">{{ section?.createdBy?.name }}</span>
            <span class="pl-1">on</span>
            <span class="date pl-1">{{
              section?.createdOn | date : "medium"
            }}</span>
          </div>
          <button
            mat-icon-button
            *ngIf="
              section?.status == wfSectionLocalStatus.DUPLICATED &&
              !checklistClosed &&
              !hasCompletedTasks
            "
            color="warn"
            (click)="delete($event)"
            [disabled]="!userCanDuplicate"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <checklist-task-counter [section]="section"></checklist-task-counter>
      </div>
    </div>
  </mat-expansion-panel-header>
  <div class="card-body p-1">
    <checklist-steps
      *ngIf="isSectionExpanded()"
      [currentChecklist]="currentChecklist"
      [steps]="section?.wfStepLocals"
      [disabled]="disabled"
      [precheck]="precheck"
      (loading)="loading.emit($event)"
      (checkShared)="checkShared.emit($event)"
    ></checklist-steps>
  </div>
</mat-expansion-panel>
