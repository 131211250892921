
import { Placeholder } from "src/app/components/checklists/checklists";

export class PlaceholdersRefresh {
  static readonly type = '[Placeholders] Refresh';
}

export class PlaceholdersCreate {
  static readonly type = '[Placeholders] Create';
  constructor(public item: Placeholder) { }
}

export class PlaceholdersUpdate {
  static readonly type = '[Placeholders] Update';
  constructor(public id: number, public item: Placeholder) { }
}

export class PlaceholdersDelete {
  static readonly type = '[Placeholders] Delete';
  constructor(public id: number) { }
}
