import { ENTER, TAB } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Subscription, Observable } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { AlertService } from 'src/app/services/alert/alert.service';
import { TokenInfoService } from 'src/app/services/authentication/token-info.service';
import { ProcedureCategory } from 'src/app/components/procedure/models/procedure-category.model';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { ScheduleStatusEnum, ScheduleStatusTimeEnum } from '../../models/enums';
import { Schedule, ScheduleSubdetail } from '../../models/schedule';
import { ScheduleResources } from '../../models/schedule-resources';
import { ScheduleSubtype } from '../../models/schedule-subtype';
import { ScheduleType } from '../../models/schedule-type';
import { ScheduleSubTypeService } from '../../services/schedule-subtype.service';

@Component({
  selector: 'app-add-schedule',
  templateUrl: './add-schedule.component.html',
  styleUrls: ['./add-schedule.component.scss']
})
export class AddScheduleComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  schedule: Schedule;
  scheduleType: ScheduleType;
  scheduleSubtype: ScheduleSubtype;
  numberOfCycles = 0;
  isLastCompleted = false;
  calculate!: Date;
  minDate: Date;
  isMultilocation = false;
  isInpuntEnable = true;
  isAllowStrings = false;
  resourceTypes = [];
  showRemoveRequired!: boolean;
  showHutch!: boolean;
  showLastC2!: boolean;
  showPeriodTime!: boolean;
  subtypeSelected!: ScheduleSubtype;
  nextDueDatePeriodSelected?: number;
  absiType?: number | null;
  // scheduleTab: ScheduleCustomTab;

  periodsTime: any[] = [
    { label: '6', value: 6 },
    { label: '12', value: 12 }
  ];

  /*************************************************************************************** **/
  // Add Locations
  @ViewChild('autoLoc') matAutocompleteLoc!: MatAutocomplete;
  @ViewChild('locationInput') locationInput!: ElementRef<HTMLInputElement>;
  @ViewChild('locationInput', { read: MatAutocompleteTrigger }) locationTrigger!: MatAutocompleteTrigger;
  @ViewChild('chipListLoc') chipList: any;

  locations: ScheduleResources[];
  filteredLocations: ScheduleResources[] = [];
  allLocations: ScheduleResources[] = [];
  allSubtypes: ScheduleSubtype[] = [];
  locationsIdUsed: number[] = [];
  /*************************************************************************************** **/
  /*************************************************************************************** **/

  //#region Procedures
  // proceduresNumbers: string[] = [];
  // proceduresNumbersFiltered: string[] = [];
  categoriesSubscription!: Subscription;
  procedureSubscription!: Subscription;
  categories$!: Observable<ProcedureCategory[]>;
  procedures$!: Observable<Procedure[]>;
  categories: ProcedureCategory[] = [];
  procedures: Procedure[] = [];
  proceduresFiltered: Procedure[] = [];
  showProcedures!: boolean;
  isRemoveRequired!: boolean;
  //#endregion

  //#region ScheduleForm
  public scheduleForm!: FormGroup;
  public get lastCompleted(): AbstractControl | null { return this.scheduleForm.get('lastCompleted'); }
  public get nextDue(): AbstractControl | null { return this.scheduleForm.get('nextDue'); }
  //#endregion

  constructor(
    protected override injector: Injector,
    public subtypeService: ScheduleSubTypeService,
    public resourcesService: ResourcesService,
    public dialogRef: MatDialogRef<AddScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(injector);

    if (!data.schedule) {
      this.showRemoveRequired = true;
    }
    this.schedule = data.schedule;
    this.locations = this.schedule?.scheduleResources ?? [];
    this.scheduleType = data.scheduleType;
    this.scheduleSubtype = data.scheduleSubtype;
    this.allLocations = this.utils.cloneDeep(data.allLocations);
    this.minDate = new Date();
    this.locationsIdUsed = this.utils.cloneDeep(data.scheduleLocationsId);
    this.absiType = this.utils.cloneDeep(data.absiType);
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  override ngOnDestroy(): void {
    this.categoriesSubscription?.unsubscribe();
    this.procedureSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  ngAfterViewInit() {
    if (!this.isMultilocation && this.locations?.length >= 1) {
      this.scheduleForm.controls['locationCtrl'].disable();
    }
  }

  ngOnInit(): void {
    this.loadProcedures();

    this.isMultilocation = true;
    this.isAllowStrings = this.scheduleType.allowCustomSchedules;
    this.showHutch = false;
    this.resourceTypes = this.utils.JSONparse(this.scheduleType?.resourceTypeIds ? this.scheduleType.resourceTypeIds : null)?.map(Number);
    if (!this.resourceTypes?.length) {
      this.alert.warning('No Resource Type List defined in Schedule Catalog');
    }
    switch (this.scheduleType.id) {
      case 1:
        this.absiType = this.schedule?.scheduleResources[0]?.resource?.absiTemplateTypeID;
        break;
      case 2:
      case 3:
        break;
      case 4:
        break;
      case 5: // RSS
        this.showHutch = true;
        this.getSubtypes();
        break;
    }

    this.scheduleForm = this.formBuilder.group({
      resource: [],
      locationCtrl: [{ value: null, disabled: true }],
      subtypeCtrl: [''],
      resourceId: [''],
      nextDue: ['', Validators.required],
      scheduleFor: [],
      lastCompleted: ['', Validators.required],
      lastCompleted2: [],
      expireDate: [],
      statusId: [],
      statusTime: [],
      typeId: [],
      nextDueDatePeriodSelected: [],
      procedureNumber: [''],
      procedureNumber2: ['']
    });

    if (this.schedule != null) {
      this.scheduleForm.controls['typeId'].setValue(this.schedule.id);
      this.scheduleForm.controls['statusId'].setValue(this.schedule.statusId);
      this.scheduleForm.controls['statusTime'].setValue(this.schedule.statusTime);
      this.scheduleForm.controls['lastCompleted'].setValue(this.schedule.lastCompleted);
      this.scheduleForm.controls['scheduleFor'].setValue(this.schedule.scheduleFor);
      this.scheduleForm.controls['nextDue'].setValue(this.schedule.nextDue);
      if (this.schedule.lastCompleted && this.schedule.nextDue) {
        this.scheduleForm.controls['nextDue'].disable();
      } else {
        this.scheduleForm.controls['lastCompleted'].disable();
      }
      this.scheduleForm.controls['resourceId'].setValue('');
      if (this.schedule.subtype) {
        this.scheduleForm.controls['subtypeCtrl'].setValue(this.schedule.subtype);
        this.subtypeSelected = this.schedule.subtype;
        if (this.schedule.scheduleSubtypeId === 1) {
          this.scheduleForm.controls['nextDueDatePeriodSelected'].setValue(this.schedule.nextDueDatePeriod);
          this.scheduleForm.controls['nextDueDatePeriodSelected'].setValidators(Validators.required);
        }
      }
      this.nextDueDatePeriodSelected = this.schedule.nextDueDatePeriod;
      this.showLastC2 = this.schedule.subtype?.id === 2;
      this.showPeriodTime = this.schedule.subtype?.id === 1;
      this.scheduleForm.controls['lastCompleted2'].setValue(this.schedule.subdetail?.lastCompleted);
      if (!this.hasPrivilege(PrivilegeEnum.EditScheduleDates)) {
        this.scheduleForm.controls['lastCompleted'].disable();
        this.scheduleForm.controls['nextDue'].disable();
        this.scheduleForm.controls['scheduleFor'].disable();
      }
      this.scheduleForm.controls['resourceId'].disable();
      this.scheduleForm.controls['procedureNumber'].setValue(this.schedule.procedure);
      this.scheduleForm.controls['procedureNumber2'].setValue(this.schedule.subdetail?.procedure);
    } else {
      this.scheduleForm.controls['typeId'].setValue(this.scheduleType.id);
      this.scheduleForm.controls['statusId'].setValue(ScheduleStatusEnum.new);
      this.scheduleForm.controls['statusTime'].setValue(ScheduleStatusTimeEnum.Good);
    }
    this.filterLocations();
  }

  initialize() {

  }

  displayProcedure(e: Procedure) {
    return (e && e.id) ? e.procedureNumber + '    Rev:' + e.revision : '';
  }

  loadProcedures() {
    if ((this.scheduleSubtype && this.scheduleSubtype.procedureTypeID) || (!this.scheduleSubtype && this.scheduleType.procedureTypeID)) {
      this.procedures$ = this.store.select(state => state.Procedures.data);
      this.procedureSubscription = this.procedures$.subscribe(procedures => {
        if (procedures) {
          this.showProcedures = true;
          const typeID = this.scheduleSubtype?.procedureType ? this.scheduleSubtype.procedureTypeID : this.scheduleType?.procedureType ? this.scheduleType.procedureTypeID : null;
          const categoryID = this.scheduleSubtype?.procedureCategory ? this.scheduleSubtype.procedureCategoryID : this.scheduleType?.procedureCategory ? this.scheduleType.procedureCategoryID : null;
          this.procedures = procedures.filter(x => (typeID ? x.procedureCategory?.procedureTypeID == typeID : !typeID) && (categoryID ? x.procedureCategoryID == categoryID : !categoryID) && x.status === 1 && x.active);
          this.procedures.sort((a, b) => this.utils.sort(a.procedureNumber, b.procedureNumber, true));
          if (this.schedule?.procedureMasterID) {
            this.procedures.unshift({ id: 0, procedureNumber: '[None]' });
          }
          this.proceduresFiltered = this.procedures;
        }
      });
    }
  }

  _filter(value: any): ScheduleResources[] {
    console.log('_filtter');
    console.log(value);
    const filterValue = value.toLowerCase();
    const result = this.locations.filter(option => option.resource?.name?.toLowerCase().includes(filterValue));
    if (result.length === 1) {
      if (result[0]?.resource?.name?.trim() === (this.scheduleForm.controls['resource'].value as string).trim()) {
        this.scheduleForm.controls['resourceId'].setValue(result[0]?.resource?.id);
      } else {
        this.scheduleForm.controls['resource'].setErrors({ incorrect: true });
      }
    } else {
      this.scheduleForm.controls['resource'].setErrors({ incorrect: true });
    }
    return result;
  }

  _filterProcedures(procedureNumber: string): Procedure[] {
    const filterValue = procedureNumber.toLowerCase();
    return this.procedures.filter(pn => pn.procedureNumber.toLowerCase().includes(filterValue));
  }

  async getSubtypes() {
    this.allSubtypes = (await this.subtypeService.getAll().toPromise()) ?? [];
  }

  onNoClick(submit?: boolean): void {
    this.dialogRef.close(submit);
  }

  onSubmit() {
    if (this.locations?.length < 1) {
      this.chipList.errorState = true;
      return;
    }
    if (this.schedule != null) {
      this.schedule.nextDue = this.scheduleForm.controls['nextDue'].value;
      this.schedule.scheduleFor = this.scheduleForm.controls['scheduleFor'].value;
      this.schedule.lastCompleted = this.scheduleForm.controls['lastCompleted'].value;
      this.schedule.scheduleSubtypeId = this.scheduleForm.controls['subtypeCtrl'].value !== '' ? this.scheduleForm.controls['subtypeCtrl'].value?.id : this.scheduleSubtype ? this.scheduleSubtype.id : null;
      if (!this.schedule.subdetail) {
        this.schedule.subdetail = {} as ScheduleSubdetail;
      }
      this.schedule.subdetail.lastCompleted = this.scheduleForm.controls['lastCompleted2'].value;
      this.schedule.subdetail.procedureMasterID = this.scheduleForm.controls['procedureNumber2']?.value?.procedureMasterID;
      this.schedule.scheduleResources = this.locations.map(val => {
        const sr = {} as ScheduleResources;
        sr.scheduleId = this.schedule.id;
        sr.resourceId = val.resource?.id;
        sr.resourceString = val.resourceString;
        return sr;
      });
      this.schedule.nextDueDatePeriod = this.nextDueDatePeriodSelected;
      this.schedule.procedureID = null;
      this.schedule.procedureMasterID = this.scheduleForm.controls['procedureNumber']?.value?.procedureMasterID;
      this.dialogRef.close(this.schedule);
    } else {
      if (this.scheduleForm.controls['nextDue'].value) {
        const expireDate = new Date((this.scheduleForm.controls['nextDue'].value as Date).getTime());
        expireDate.setDate(expireDate.getDate() + this.scheduleType.graceTime);
        this.scheduleForm.controls['expireDate'].setValue(expireDate);
      }
      const schedule = this.scheduleForm.value as Schedule;
      schedule.nextDue = this.scheduleForm.controls['nextDue'].value;
      schedule.scheduleFor = this.scheduleForm.controls['scheduleFor'].value;
      schedule.lastCompleted = this.scheduleForm.controls['lastCompleted'].value;
      schedule.scheduleSubtypeId = this.scheduleForm.controls['subtypeCtrl'].value !== '' ? this.scheduleForm.controls['subtypeCtrl'].value?.id : this.scheduleSubtype ? this.scheduleSubtype.id : null;
      schedule.scheduleResources = this.locations.map(val => {
        const sr = {} as ScheduleResources;
        sr.scheduleId = schedule.id;
        sr.resourceId = val.resource?.id;
        sr.resourceString = val.resourceString;
        return sr;
      });
      if (this.scheduleForm.controls['lastCompleted2']?.value) {
        const sd = {} as ScheduleSubdetail;
        sd.lastCompleted = this.scheduleForm.controls['lastCompleted2']?.value;
        schedule.subdetail = sd;
      }
      if (this.scheduleForm.controls['subtypeCtrl']?.value?.id === 1) {
        schedule.nextDueDatePeriod = this.nextDueDatePeriodSelected;
      }
      schedule.procedureID = null;
      schedule.procedureMasterID = this.scheduleForm.controls['procedureNumber']?.value?.procedureMasterID;
      this.dialogRef.close(schedule);
    }
  }

  get scheduleFor() { return this.scheduleForm.get('scheduleFor'); }
  /*************************************************************************************** **/
  // Add Locations
  /*************************************************************************************** **/
  removeLoc(location: ScheduleResources): void {
    const index = this.locations.indexOf(location);
    if (index >= 0) {
      this.locations.splice(index, 1);
      if (location.resourceId) {
        this.locationsIdUsed.splice(this.locationsIdUsed.indexOf(location.resourceId), 1);
      }
      if (this.locations.length === 0) {
        this.absiType = null;
      }
    }
    if (!this.isMultilocation) {
      if (this.locations.length <= 0) {
        this.scheduleForm?.controls['locationCtrl'].enable();
      }
    }
    this.filterLocations();
    this.validateEmptyLocations();
  }

  onLocChange(code?: string) {
    if (!code?.includes('Arrow')) {
      this.filterLocations();
      this.filteredLocations = this.filteredLocations.filter(loc => loc.resource?.name?.toLowerCase().includes(this.scheduleForm?.controls?.['locationCtrl']?.value?.trim().toLowerCase()));
    }
  }

  onProcedureNumberChange() {
    if (this.scheduleForm.controls['procedureNumber'].value) {
      this.proceduresFiltered = this._filterProcedures(this.scheduleForm.controls['procedureNumber'].value);
    } else {
      this.proceduresFiltered = this.procedures;
    }
  }

  selectedLoc(event: MatAutocompleteSelectedEvent) {
    if (this.locations == null) { this.locations = []; }
    if (!this.locations.some(l => l.resource?.id === event.option.value?.id) && event.option.value != null && event.option.value !== undefined) {
      const scheduleResource = event.option.value as ScheduleResources;
      scheduleResource.resourceId = event.option.value.resource?.id;
      scheduleResource.scheduleId = this.schedule?.id ?? 0;
      this.absiType = scheduleResource.resource?.absiTemplateTypeID;
      if (scheduleResource.resourceId) {
        this.locationsIdUsed.push(scheduleResource.resourceId);
      }
      this.locations.push(scheduleResource);
      this.locationInput.nativeElement.value = '';
      if (!this.isMultilocation) {
        if (this.locations.length >= 1) {
          this.scheduleForm?.controls['locationCtrl'].disable();
        }
      }
      this.filterLocations();
    }
    this.validateEmptyLocations();
  }

  filterLocations() {
    this.filteredLocations = [];
    for (const location of this.allLocations) {
      let absiCheck = true;
      if (this.scheduleType.id === 1) {
        absiCheck = false;
        if (this.absiType) {
          absiCheck = location.resource?.absiTemplateTypeID !== 0 && location.resource?.absiTemplateTypeID === this.absiType;
        } else {
          absiCheck = location.resource?.absiTemplateTypeID !== 0;
        }
      }
      const found = this.isLocationUsed(location);
      if (!found) {
        if (absiCheck) {
          this.filteredLocations.push(location);
        }
      }
    }
    this.filteredLocations.sort((a, b) => this.utils.SortBeamlines(a.resource?.name, b.resource?.name));
  }


  async addLoc(event: MatChipInputEvent) {
    if (!this.isAllowStrings) {

      return;
    }
    if (!this.matAutocompleteLoc?.isOpen) {
      const input = event.input;
      const value = event.value;
      // Reset the input value
      if (input) {
        input.value = '';
      }
      if (value !== '') {
        const locationFree = {
          scheduleId: this.schedule?.id ?? 0,
          resourceId: null,
          resourceString: value
        } as ScheduleResources;
        this.locations.push(locationFree);
      }
      this.scheduleForm?.controls['locationCtrl']?.setValue(null);
      if (!this.isMultilocation) {
        if (this.locations.length >= 1) {
          this.scheduleForm?.controls['locationCtrl'].disable();
        }
      } else {
        this.filterLocations();
      }
    }
    this.validateEmptyLocations();
  }
  /*************************************************************************************** **/
  /*************************************************************************************** **/
  validateEmptyLocations() {
    if (this.locations?.length < 1) {
      this.chipList.errorState = true;
    } else {
      this.chipList.errorState = false;
    }
  }

  changedHutch(e: any) {
    this.showLastC2 = e.option.value.id === 2;
    this.showPeriodTime = e.option.value.id === 1;
    this.scheduleForm.controls['lastCompleted2'].clearValidators();
    this.scheduleForm.controls['lastCompleted2'].updateValueAndValidity();
    if (this.scheduleForm.controls['subtypeCtrl'].value) {
      this.scheduleForm.controls['subtypeCtrl'].setValidators(Validators.required);
    }
    if (this.scheduleForm.controls['lastCompleted'].value) {
      this.setNextDueDateTime(this.scheduleForm.controls['nextDue'], this.scheduleForm.controls['lastCompleted'].value);
    }
    if (e.option.value.id === 2) {
      this.scheduleForm.controls['lastCompleted2'].setValidators(Validators.required);
    }
    if (e.option.value.id === 1) {
      this.scheduleForm.controls['nextDueDatePeriodSelected'].setValidators(Validators.required);
    }
  }

  changeNextDueDatePeriod() {
    if (this.scheduleForm.controls['lastCompleted'].value) {
      this.setNextDueDateTime(this.scheduleForm.controls['nextDue'], this.scheduleForm.controls['lastCompleted'].value);
    }
  }

  setNextDueDateTime(control: AbstractControl, value: any) {
    const date = new Date(value) as Date;
    const currentDate = new Date(date.getTime());
    let result = this.scheduleType.nextDueDateTime;
    if (this.scheduleForm.controls['subtypeCtrl'].value) {
      if (this.scheduleForm.controls['subtypeCtrl'].value.id === 1 && this.nextDueDatePeriodSelected) {
        result = this.nextDueDatePeriodSelected;
      } else {
        result = this.subtypeSelected.nextDueDateTime ?? 0;
      }
    }
    currentDate.setMonth(date.getMonth() + result);
    control.setValue(currentDate);
  }

  isLocationUsed(location: ScheduleResources): boolean {
    return this.locationsIdUsed.some(l => l == location.resource?.id);
  }

  changeDate(type: string) {
    if (type === 'lastCompleted') {
      if (this.scheduleForm.controls['lastCompleted'].value) {
        this.setNextDueDateTime(this.scheduleForm.controls['nextDue'], this.scheduleForm.controls['lastCompleted'].value);
        this.scheduleForm.controls['nextDue'].disable();
      } else {
        this.scheduleForm.controls['nextDue'].enable();
      }
    }
    if (type === 'nextDue') {
      if (this.scheduleForm.controls['nextDue'].value) {
        this.scheduleForm.controls['lastCompleted'].disable();
      } else {
        this.scheduleForm.controls['lastCompleted'].enable();
      }
    }
  }

  removeLastAndNextDateRequired(isRemoveRequired: boolean) {
    this.isRemoveRequired = isRemoveRequired;
    if (this.isRemoveRequired) {
      this.lastCompleted?.clearValidators();
      // this.nextDue.clearValidators();
    } else {
      this.lastCompleted?.setValidators(Validators.required);
      this.nextDue?.setValidators(Validators.required);
    }
    this.lastCompleted?.updateValueAndValidity();
    this.nextDue?.updateValueAndValidity();
  }
}
