import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ResourceSummary, ResourceUserRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourceBeamlineStaffCatalog } from '../beamline-staff';

@Component({
  selector: 'beamline-staff-main-panel',
  templateUrl: './beamline-staff-main-panel.component.html',
  styleUrls: ['./beamline-staff-main-panel.component.scss']
})
export class BeamlineStaffMainPanelComponent extends BaseComponent implements OnInit {

  @Input() filter?: string;
  @Input() beamlines?: ResourceBeamlineStaffCatalog[] = [];

  public resourceSummary?: ResourceSummary;
  public RURAux?: ResourceUserRelation;

  public loading = false;

  public displayedColumnsSupervisor: string[] = ['supervisorName', 'endstation', 'branchline'];
  public displayedColumnsScientist: string[] = ['scientistName', 'endstation', 'branchline'];
  public displayedColumnsAlternateScientist: string[] = ['alternateScientistName', 'endstation', 'branchline'];

  public valueFilter = '';
  public emptyBeamlineStaffMessage = '';

  id = '';
  public filteredBeamlines?: any[];

  ngOnInit() {
    this.emptyBeamlineStaffMessage = this.getMessage('beamline_Staff_Catalog').description ?? 'Without assigning supervisors or scientists to the resource';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const filter = this.filter ? this.filter?.toLowerCase() : null;
    this.filteredBeamlines = filter ?
      this.beamlines?.filter(b =>
        b.beamlineName.toLowerCase().includes(filter) ||
        b.supervisors.some(u => u.userName.toLowerCase().includes(filter)) ||
        b.scientists.some(u => u.userName.toLowerCase().includes(filter)) ||
        b.alternateScientists.some(u => u.userName.toLowerCase().includes(filter)) ||
        b.supervisors.some(u => u.endStations.includes(filter)) ||
        b.supervisors.some(u => u.branchLines.includes(filter)) ||
        b.scientists.some(u => u.endStations.includes(filter)) ||
        b.scientists.some(u => u.branchLines.includes(filter)) ||
        b.alternateScientists.some(u => u.endStations.includes(filter)) ||
        b.alternateScientists.some(u => u.branchLines.includes(filter))
      )
      : this.beamlines;
  }

  clearRURAuxData() {
    this.RURAux = new ResourceUserRelation();
    this.RURAux.resourceUserRelations = [];
    this.RURAux.joinEndstations = [];
    this.RURAux.joinBranchlines = [];
  }

  clearFilter() {
    this.valueFilter = '';
    this.filteredBeamlines = this.beamlines;
  }

  scrollToBeamline(b: ResourceBeamlineStaffCatalog) {
    const elem = 'bl' + b.beamlineID;
    const divElement = document.getElementById(elem);
    if (divElement)
      divElement.scrollIntoView({ behavior: 'smooth' });
  }
}
