import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as ActionItemsActions from './actionItems.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';

@Injectable({
  providedIn: 'root'
})
export class ActionItemsStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore(AbisId: number) {
    this.store.dispatch(new ActionItemsActions.ActionItemsRefresh(AbisId));
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.CATLACTIONITEMS, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new ActionItemsActions.ActionItemsCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new ActionItemsActions.ActionItemsUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new ActionItemsActions.ActionItemsDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new ActionItemsActions.ActionItemsRefresh(data.id));
      }
    });
  }
}
