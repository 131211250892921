import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { RSSWAPerformingWorkUser } from '../../../rsswa-performing-work-user';
import { BaseComponent } from 'src/app/common/base/base.component';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'rsswa-performing-work-user-chips',
  templateUrl: './rsswa-performing-work-user-chips.component.html',
  styleUrls: ['./rsswa-performing-work-user-chips.component.scss']
})
export class RsswaPerformingWorkUserChipsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() rsswaId!: number;
  @Input() selectedRsswaPerformingWorkUsers?: RSSWAPerformingWorkUser[];
  @Input() errorMessage!: string | null;
  @Input() hilite!: boolean | null;

  @Output() error = new EventEmitter<string>();
  @Output() edit = new EventEmitter();

  @ViewChild("userAutocomplete") userAutocomplete?: MatAutocomplete;
  @ViewChild("userInput") userInput?: ElementRef<HTMLInputElement>;

  allUsers?: User[];
  availableUsers?: User[];
  filteredUsers?: User[];

  public get chipInputCtrl(): AbstractControl | null { return this.formGroup?.get("chipInputCtrl"); }

  ngOnInit(): void {
    this.allUsers = [];
    this.availableUsers = [];
    this.filteredUsers = [];
    this.allUsers = this.getUsers();
    this.availableUsers = utils.cloneDeep(this.allUsers);
    this.filteredUsers = utils.cloneDeep(this.allUsers);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ("selectedRsswaPerformingWorkUsers" in changes) {
      this.setAvailableUsers();
      this.clear();
    }
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      chipInputCtrl: [{ value: null }],
    });
    if (this.disabled) this.formGroup.disable(); else this.formGroup.enable();
  }

  select(event: MatAutocompleteSelectedEvent) {
    var user: User = event.option.value;
    if (user?.id)
      this.selectedRsswaPerformingWorkUsers?.push({
        rsswaID: this.rsswaId,
        userID: user.id,
        user: user
      });
    this.setAvailableUsers();
    this.clear();
    this.edit.emit();
  }

  remove(rsswaPerformingWorkUser: RSSWAPerformingWorkUser) {
    const index = this.selectedRsswaPerformingWorkUsers?.indexOf(rsswaPerformingWorkUser) ?? -1;

    if (index >= 0) {
      this.selectedRsswaPerformingWorkUsers?.splice(index, 1);
      this.setAvailableUsers();
      this.clear();
      this.edit.emit();
    }
  }

  clear() {
    if (this.userInput != null) this.userInput.nativeElement.value = "";
    if (this.chipInputCtrl) this.chipInputCtrl.setValue(null);
  }

  setAvailableUsers() {
    this.availableUsers = [];
    this.allUsers = this.getUsers();
    if (this.selectedRsswaPerformingWorkUsers == null) this.selectedRsswaPerformingWorkUsers = [];
    this.availableUsers = utils.cloneDeep(this.allUsers);
    this.selectedRsswaPerformingWorkUsers.map(x => {
      const index = this.availableUsers?.map(y => y.id).indexOf(x.userID) ?? -1;

      if (index >= 0) {
        this.availableUsers?.splice(index, 1);
      }
    });
    this.filteredUsers = utils.cloneDeep(this.availableUsers);
  }

  onUserInputKeyup(e: any) {
    const text = e.target.value;
    const code = e.code;
    if (!code?.includes("Arrow")) {
      this.filteredUsers = this.availableUsers?.filter(x => x.name?.trim().toLowerCase().includes(text.trim().toLowerCase()));
    }
  }

}
