import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { Document, DocumentType } from 'src/app/services/documents/documents';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Observable, Subscription } from 'rxjs';
import { FormStatus } from 'src/app/services/forms/form-status';
import { Checklist } from 'src/app/components/checklists/checklists';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent extends BaseComponent implements OnInit, OnDestroy {

  loading?: boolean;

  displayedColumns: string[] = ['name', 'description', 'locationNames', 'userInfo', 'workRelated'];

  forms = [this.formTypeEnum.SCF, this.formTypeEnum.KE, this.formTypeEnum.RSSWA, this.formTypeEnum.EBF, this.formTypeEnum.PPSTB, this.formTypeEnum.ABSI];

  documents?: Document[];
  documentsFiltered?: Document[];
  documents$!: Observable<Document[]>;
  documentsSubs!: Subscription;

  formStatuses?: FormStatus[];
  formStatusesFiltered?: FormStatus[];
  formStatuses$!: Observable<FormStatus[]>;
  formStatusesSubs!: Subscription;

  checklists?: Checklist[];
  checklistsFiltered?: Checklist[];
  checklists$!: Observable<Checklist[]>;
  checklistsSubs!: Subscription;

  documentTypes?: DocumentType[];
  documentTypes$!: Observable<DocumentType[]>;
  documentTypesSubs!: Subscription;

  dataSource!: MatTableDataSource<Document>;
  years: (number | undefined)[] = [];
  year: number = 0;

  type: number = 0;

  // Filters values
  serialNo = '';
  location = '';
  workRel = '';
  condRel = '';
  initiator = '';
  requester = '';
  status = '';
  description = '';

  filterValue: string = '';

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<SearchDialogComponent>,
    private router: Router,
  ) {
    super(injector);
  }
  ngOnInit(): void {
    this.loading = true;
    this.loadFormStatuses();
  }

  override ngOnDestroy(): void {
    this.documentsSubs?.unsubscribe();
    this.formStatusesSubs?.unsubscribe();
    this.checklistsSubs?.unsubscribe();
    this.documentTypesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadDocumentTypes() {
    this.documentTypes$ = this.store.select(state => state.DocumentType.data);
    this.documentTypesSubs = this.documentTypes$.subscribe(data => {
      if (data?.length) {
        this.documentTypes = data.filter(d => this.documentsFiltered?.map(x => x.type).includes(d.id)).sort((a, b) => this.utils.sort(a.description, b.description));
      }
    });
  }

  loadDocuments() {
    this.documents$ = this.store.select(state => state.Documents.data);
    this.documentsSubs = this.documents$.subscribe(data => {
      if (data?.length) {
        this.documents = this.utils.cloneDeep(data.filter(x => x.serialNo));
        this.documents.map(d => {
          d.formStatus = this.formStatuses?.find(s => s.id == d.status) ?? new FormStatus();
          if (d.locationNames) {
            const locationNames = d.locationNames.split(',');
            d.locationNames = '<div class="d-flex flex-wrap">' + locationNames?.map(l => {
              return '<span class="chip">' + l.trim() + '</span>';
            }).join('') + '</div>';
          }
          d.gen1 = this.formatGen(d.gen1);
        });

        this.filterData();
        this.loading = false;
        this.loadChecklists();
        this.loadDocumentTypes();
        this.years = Array.from(new Set(this.documentsFiltered?.map(d => d.createdOn ? new Date(d.createdOn).getFullYear() : 0)));
      }
    });
  }

  loadChecklists() {
    this.checklists$ = this.store.select(state => state.Checklists.data);
    this.checklistsSubs = this.checklists$.subscribe(data => {
      if (data?.length) {
        this.checklists = data;
        this.documents?.filter(d => !this.forms.includes(d.type ?? 0))?.map(d => {
          const checklist = this.checklists?.find(c => c.serialNo == d.serialNo);
          if (checklist && d.formStatus) {
            d.statusName = checklist?.checklistStatus?.name;
            const formStatus = new FormStatus();
            formStatus.name = checklist?.checklistStatus?.name ?? '';
            formStatus.color = checklist?.checklistStatus?.cssClass ?? '';
            d.formStatus = formStatus;
          }
        });
      }
    });
  }

  loadFormStatuses() {
    this.formStatuses$ = this.store.select(state => state.FormStatuses.data);
    this.formStatusesSubs = this.formStatuses$.subscribe(data => {
      if (data?.length) {
        this.formStatuses = this.utils.cloneDeep(data);
        this.loadDocuments();
      }
    });
  }

  applyFilter(e?: string) {
    this.filterValue = e?.toLowerCase() ?? '';
    this.filterData();
  }

  filterData() {
    this.documentsFiltered = this.documents?.filter(d => d.serialNo?.toLowerCase().includes(this.filterValue) || d.description?.toLowerCase().includes(this.filterValue) || d.locationNames?.toLowerCase()?.includes(this.filterValue) || d.createdByName?.toLowerCase().includes(this.filterValue) || d.requesterName?.toLowerCase().includes(this.filterValue) || d.gen1?.toLowerCase().includes(this.filterValue) || d.statusName?.toLowerCase().includes(this.filterValue));
    this.documentsFiltered = this.documentsFiltered?.filter(d => !this.year || (d.createdOn ? new Date(d.createdOn).getFullYear() == this.year : false));
    this.documentsFiltered = this.documentsFiltered?.filter(d => !this.type || d.type == this.type);

    this.sortData();
    this.dataSource = new MatTableDataSource(this.documentsFiltered);
  }

  filterType(e: any) {
    this.type = e.value;
    this.filterData();
  }

  filterYear(e: any) {
    this.year = e.value;
    this.filterData();
  }

  formatGen(data?: string) {
    let result = '';
    if (data) {
      const obj = this.utils.JSONparse(data);
      if (obj) {
        result += this.generateHTML(obj.TypeOfWork, 'Type Of Work');
        result += this.generateHTML(obj.Shieldings, 'Shielding Affected');
        result += this.generateHTML(obj.Restrictions, 'Restrictions');
      }
    }
    return result;
  }

  sortData(sort: Sort | null = null) {
    if (!sort || sort?.direction === '')
      this.documentsFiltered?.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers((b.createdOn?.toString()), (a.createdOn?.toString())));
    else
      this.documentsFiltered?.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name': return this.utils.compare(a.serialNo, b.serialNo, isAsc);
          case 'description': return this.utils.compare(a.description, b.description, isAsc);
          case 'locationNames': return this.utils.compare(a.locationNames, b.locationNames, isAsc);
          case 'workRelated': return this.utils.compare(a.gen1, b.gen1, isAsc);
          case 'userInfo': return this.utils.compare(a.requesterName, b.createdByName, isAsc);
          // case 'requester': return compare(a.requester, b.requester, isDesc);
          default: return 0;
        }
      });
    this.dataSource = new MatTableDataSource(this.documentsFiltered);
  }

  generateHTML(items: any[], title: string) {
    let html = '';
    if (items && items.length > 0) {
      html += `<div ><b>${title}:</b><br><ul class="mb-0">`;
      items.map(item => {
        if (item?.name)
          html += `<li>${item.name}</li>`;
      });
      html += '</ul></div>';
    }
    return html;
  }

  openDocument(e: Document) {
    let route = 'redirect/' + e.serialNo;
    this.router.navigate([route]);
    this.dialogRef.close();
  }

}
