<div class="row">
  <div class="col-12">
    <mat-form-field appearance="outline" class="no-subscript">
      <mat-label>Board Type</mat-label>
      <input
        type="text"
        placeholder="Select Board Type"
        matInput
        name="boardTypeCtrl"
        [formControl]="boardTypeCtrl"
        [matAutocomplete]="autoBoardType"
        (keyup)="changedBoardType($event)"
      />
      <mat-autocomplete
        #autoBoardType="matAutocomplete"
        #boardTypeAutocomplete
        [displayWith]="displayObject"
        (optionSelected)="selectedBoardType($event)"
      >
        <mat-option
          *ngFor="let boardType of reviewBoardTypesFiltered"
          [value]="boardType"
        >
          <span [ngClass]="{ 'add-new': boardType.id == 0 }">{{
            boardType.name
          }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="col-12">
    <mat-form-field appearance="outline" class="no-subscript">
      <mat-label>Name</mat-label>
      <input
        #boardTypeNameCtrlElement
        type="text"
        placeholder="Name"
        matInput
        name="boardTypeNameCtrl"
        [formControl]="boardTypeNameCtrl"
      />
    </mat-form-field>
  </div>
  <div class="col-12">
    <mat-form-field appearance="outline" class="no-subscript">
      <mat-label>Description</mat-label>
      <input
        type="text"
        placeholder="Description"
        matInput
        name="boardTypeDescriptionCtrl"
        [formControl]="boardTypeDescriptionCtrl"
      />
    </mat-form-field>
  </div>
  <div class="col-12">
    <mat-form-field appearance="outline" class="no-subscript">
      <mat-label>Acronym</mat-label>
      <input
        type="text"
        placeholder="Description"
        matInput
        name="boardTypeCodeCtrl"
        [formControl]="boardTypeCodeCtrl"
      />
    </mat-form-field>
  </div>

  <div class="col-12" *ngIf="reviewBoardType?.id == 1">
    <mat-form-field appearance="outline" class="no-subscript">
      <mat-label>Resource Type</mat-label>
      <input
        type="text"
        placeholder="Resource Type"
        matInput
        name="resourceTypeCtrl"
        [disabled]="true"
        [value]="getFormattedResourceTypes()"
      />
    </mat-form-field>
  </div>

  <div class="col-12" *ngIf="reviewBoardType?.id != 1">
    <mat-form-field appearance="outline" class="no-subscript">
      <mat-label>Resource Type</mat-label>
      <input
        type="text"
        placeholder="Resource Type"
        matInput
        name="resourceTypeCtrl"
        [formControl]="resourceTypeCtrl"
        [matAutocomplete]="autoResourceType"
        (keyup)="changedResourceType($event)"
      />
      <mat-autocomplete
        #autoResourceType="matAutocomplete"
        #resourceTypeAutocomplete
        [displayWith]="displayObject"
      >
        <mat-option
          *ngFor="let resourceType of resourceTypes"
          [value]="resourceType"
        >
          {{ resourceType.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="col-12">
    <mat-slide-toggle
      color="primary"
      [(ngModel)]="crToggle"
      [disabled]="!isModifying"
      (change)="crToggleChanged($event)"
      >Allow Create New Locations</mat-slide-toggle
    >
  </div>
  <div class="col-12 text-right">
    <button
      mat-stroked-button
      color="warn"
      class="float-left"
      *ngIf="
        !isModifying &&
        reviewBoardType &&
        reviewBoardType.id != 0 &&
        reviewBoardType.reviewTypes?.length == 0
      "
      (click)="delete()"
    >
      Delete
    </button>
    <button
      mat-button
      color="warn"
      class="mr-2"
      *ngIf="reviewBoardType?.id == 0 || isModifying"
      (click)="cancel()"
      [disabled]="disabled"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      class="btn-green"
      *ngIf="reviewBoardType?.id == 0 || isModifying"
      [disabled]="!(formGroup.dirty && formValid()) || disabled"
      (click)="save()"
    >
      <mat-icon>save</mat-icon>&nbsp;Save
    </button>
    <button
      mat-flat-button
      color="accent"
      *ngIf="!isModifying && reviewBoardType && reviewBoardType.id != 0"
      (click)="modify()"
    >
      <mat-icon>edit</mat-icon>&nbsp;Modify
    </button>
  </div>
</div>
