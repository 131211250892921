import { Component, OnInit, OnDestroy, Injector, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { TemplateType } from 'src/app/components/checklists/checklists';
import { ProcedureType } from 'src/app/components/procedure/models/procedure-type';
import { Schedule } from 'src/app/components/schedules/models/schedule';
import { ScheduleSubtype } from 'src/app/components/schedules/models/schedule-subtype';
import { ScheduleType } from 'src/app/components/schedules/models/schedule-type';
import { ScheduleSubTypeService } from 'src/app/components/schedules/services/schedule-subtype.service';
import { ScheduleSubtypesRefresh } from 'src/app/components/schedules/store/schedule-subtypes/schedule-subtypes.action';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { DocumentType } from 'src/app/services/documents/documents';
import { ResourceType } from 'src/app/components/catalogs/beamline-catalog/resource/resources';

@Component({
  selector: 'schedule-subtypes-details',
  templateUrl: './schedule-subtypes-details.component.html',
  styleUrls: ['./schedule-subtypes-details.component.scss']
})
export class ScheduleSubtypesDetailsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() scheduleSubtype?: ScheduleSubtype | null;

  scheduleType?: ScheduleType;
  scheduleSubtypeTemp?: ScheduleSubtype | null;

  adding!: boolean;
  modifying!: boolean;
  disabled!: boolean;

  today: number = Date.now();
  dateFormat?: string;

  scheduleTypes!: ScheduleType[];
  scheduleTypes$!: Observable<ScheduleType[]>;
  scheduleTypesSubs!: Subscription;

  procedureTypes!: ProcedureType[];
  procedureTypes$!: Observable<ProcedureType[]>;
  procedureTypesSubs!: Subscription;

  documentTypes!: DocumentType[];
  documentTypes$!: Observable<DocumentType[]>;
  documentTypesSubs!: Subscription;

  scheduleResourceTypes!: ResourceType[];

  resourceTypes!: ResourceType[];
  resourceTypesFiltered!: ResourceType[];
  resourceTypes$!: Observable<ResourceType[]>;
  resourceTypesSubs!: Subscription;

  templateTypes!: TemplateType[];
  templateTypes$!: Observable<TemplateType[]>;
  templateTypesSubs!: Subscription;

  schedules!: Schedule[];
  schedules$!: Observable<Schedule[]>;
  schedulesSubs!: Subscription;

  public get nameCtrl(): AbstractControl | null { return this.formGroup.get('nameCtrl'); }
  public get descriptionCtrl(): AbstractControl | null { return this.formGroup.get('descriptionCtrl'); }
  public get acronymCtrl(): AbstractControl | null { return this.formGroup.get('acronymCtrl'); }
  public get activeToggleCtrl(): AbstractControl | null { return this.formGroup.get('activeToggleCtrl'); }
  public get tabsToggleCtrl(): AbstractControl | null { return this.formGroup.get('tabsToggleCtrl'); }
  public get graceTimeCtrl(): AbstractControl | null { return this.formGroup.get('graceTimeCtrl'); }
  public get dueDateCtrl(): AbstractControl | null { return this.formGroup.get('dueDateCtrl'); }
  public get documentTypeCtrl(): AbstractControl | null { return this.formGroup.get('documentTypeCtrl'); }
  public get procedureTypeCtrl(): AbstractControl | null { return this.formGroup.get('procedureTypeCtrl'); }
  public get resourceTypeCtrl(): AbstractControl | null { return this.formGroup.get('resourceTypeCtrl'); }
  public get templateTypeCtrl(): AbstractControl | null { return this.formGroup.get('templateTypeCtrl'); }
  public get dateFormatCtrl(): AbstractControl | null { return this.formGroup.get('dateFormatCtrl'); }
  public get customToggleCtrl(): AbstractControl | null { return this.formGroup.get('customToggleCtrl'); }

  @ViewChild('autoInput') autoInput!: ElementRef<HTMLInputElement>;

  constructor(
    protected override injector: Injector,
    private service: ScheduleSubTypeService,
  ) {
    super(injector);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.initializeForm();
    this.loadProcedureTypes();
    this.loadDocumentTypes();
    this.loadResourceTypes();
    this.loadScheduleTypes();
    this.loadTemplateTypes();
    this.loadSchedules();
    this.getDetails();
  }

  override ngOnDestroy(): void {
    this.procedureTypesSubs?.unsubscribe();
    this.documentTypesSubs?.unsubscribe();
    this.resourceTypesSubs?.unsubscribe();
    this.templateTypesSubs?.unsubscribe();
    this.scheduleTypesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadProcedureTypes();
    this.loadDocumentTypes();
    this.loadResourceTypes();
    this.loadScheduleTypes();
    this.loadTemplateTypes();
    this.loadSchedules();
    this.getDetails();
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      nameCtrl: [{ value: null }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      descriptionCtrl: [{ value: null }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      acronymCtrl: [{ value: null }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      activeToggleCtrl: [{ value: false }],
      graceTimeCtrl: [{ value: 30 }],
      dueDateCtrl: [{ value: 12 }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      documentTypeCtrl: [{ value: false }],
      procedureTypeCtrl: [{ value: null }],
      tabsToggleCtrl: [{ value: false }],
      resourceTypeCtrl: [{ value: null }],
      templateTypeCtrl: [{ value: null }],
      dateFormatCtrl: [{ value: 'shortDate' }],
      customToggleCtrl: [{ value: false }]
    });
    this.nameCtrl?.setValue(null);
    this.descriptionCtrl?.setValue(null);
    this.acronymCtrl?.setValue(null);
    this.graceTimeCtrl?.setValue(null);
    this.dueDateCtrl?.setValue(null);
    this.validateForm();
  }

  override getErrorMsg(control: AbstractControl | null): string | null {
    if (control?.hasError('required')) { return 'You must enter a value'; }
    if (control?.hasError('hasWhiteSpace')) { return 'Enter a valid value'; }
    if (control?.hasError('resourceType')) { return 'You must select a Resource Type'; }
    if (control?.hasError('scheduleType')) { return 'You must select a Schedule Type'; }
    if (control?.hasError('scheduleSubtype')) { return 'You must select a Schedule Subtype'; }
    if (control?.hasError('numeric')) { return 'This value shuld be numeric'; }
    return null;
  }

  getDetails() {
    this.adding = this.scheduleSubtype?.id == 0;
    this.modifying = false;
    this.scheduleSubtypeTemp = this.utils.cloneDeep(this.scheduleSubtype);
    this.scheduleType = this.scheduleTypes.find(x => x.id == this.scheduleSubtype?.scheduleTypeId);
    this.nameCtrl?.setValue(this.scheduleSubtype?.name);
    this.descriptionCtrl?.setValue(this.scheduleSubtype?.description);
    this.acronymCtrl?.setValue(this.scheduleSubtype?.acronym);
    this.activeToggleCtrl?.setValue(this.scheduleSubtype?.isActive);
    this.graceTimeCtrl?.setValue(this.scheduleSubtype?.graceTime);
    this.dueDateCtrl?.setValue(this.scheduleSubtype?.nextDueDateTime);
    this.documentTypeCtrl?.setValue(this.scheduleType?.documentTypeId);
    this.procedureTypeCtrl?.setValue(this.scheduleSubtype?.procedureTypeID);
    this.dateFormatCtrl?.setValue(this.scheduleSubtype?.dateFormat);
    this.dateFormat = this.scheduleSubtype?.dateFormat;
    this.customToggleCtrl?.setValue(this.scheduleType?.allowCustomSchedules);
    this.getRelatedInfo();
    this.filterResourceTypes();
    this.validateForm();
  }

  getRelatedInfo() {
    this.getTemplateType();
    this.getSchedules();
  }

  getTemplateType() {
    if (!this.scheduleType?.id) {
      this.templateTypeCtrl?.setValue(null);
    }
    else {
      this.templateTypeCtrl?.setValue(this.templateTypes?.find(x => x.scheduleSubtypeID == this.scheduleSubtype?.id)?.name);
    }
  }

  getSchedules() {
    if (this.scheduleType && this.scheduleSubtype) {
      this.scheduleSubtype.schedules = this.schedules?.filter(x => x.typeId == this.scheduleSubtype?.id);
    }
  }

  validateForm() {
    if (!(this.adding || this.modifying)) {
      this.formGroup.markAsPristine();
      this.formGroup.disable();
      this.resourceTypeCtrl?.disable();
      this.disabled = true;
    }
    else {
      this.formGroup.enable();
      this.resourceTypeCtrl?.enable();
      this.disabled = false;
      this.formGroup.valueChanges.subscribe(() => {
        this.setFormDirty(this.formGroup.dirty);
      });
    }
    this.templateTypeCtrl?.disable();
    this.documentTypeCtrl?.disable();
    this.customToggleCtrl?.disable();
  }

  loadTemplateTypes() {
    this.templateTypes$ = this.store.select(state => state.TemplateTypes.data);
    this.templateTypesSubs = this.templateTypes$.subscribe(data => {
      this.templateTypes = data;
    });
  }

  loadSchedules() {
    this.schedules$ = this.store.select(state => state.Schedules.data);
    this.schedulesSubs = this.schedules$.subscribe(data => {
      this.schedules = data;
    });
  }

  loadScheduleTypes() {
    this.scheduleTypes$ = this.store.select(state => state.ScheduleTypes.data);
    this.scheduleTypesSubs = this.scheduleTypes$.subscribe(data => {
      this.scheduleTypes = data;
    });
  }

  loadDocumentTypes() {
    this.documentTypes$ = this.store.select(state => state.DocumentType.data);
    this.documentTypesSubs = this.documentTypes$.subscribe(data => {
      this.documentTypes = data.filter(x => x.description && !x.type);
      if (!this.documentTypes.map(x => x.id).includes(0)) {
        this.documentTypes.unshift({ id: 0, description: '[None]' } as DocumentType);
      }
    });
  }

  loadProcedureTypes() {
    this.procedureTypes$ = this.store.select(state => state.ProcedureType.data);
    this.procedureTypesSubs = this.procedureTypes$.subscribe(data => {
      this.procedureTypes = data;
      if (!this.procedureTypes.map(x => x.id).includes(0)) {
        this.procedureTypes.unshift({ id: 0, name: '[None]' } as ProcedureType);
      }
    });
  }

  loadResourceTypes() {
    this.resourceTypes$ = this.store.select(state => state.ResourceTypes.data);
    this.resourceTypesSubs = this.resourceTypes$.subscribe(data => {
      this.resourceTypes = data;
    });
  }

  changedResourceType(e: any) {
    const text = e.target.value;
    this.filterResourceTypes();
    this.resourceTypesFiltered = this.resourceTypesFiltered.filter(x => x.name.toLowerCase().includes(text.toLowerCase()));
  }

  filterResourceTypes() {
    let resourceTypeArray: number[] = [];
    resourceTypeArray = this.scheduleSubtype?.resourceTypeIds ? JSON.parse(this.scheduleSubtype?.resourceTypeIds).map(Number) : (this.scheduleType?.resourceTypeIds ? JSON.parse(this.scheduleType?.resourceTypeIds) : new Array());
    this.scheduleResourceTypes = [];
    resourceTypeArray.map(id => {
      const resourceType = this.resourceTypes.find(x => x.id == id);
      if (resourceType)
        this.scheduleResourceTypes.push(resourceType);
    });
    this.resourceTypesFiltered = this.resourceTypes.filter(x => !this.scheduleResourceTypes.map(t => t.id).includes(x.id));
  }

  removeResourceType(resourceType: ResourceType) {
    this.scheduleResourceTypes.splice(this.scheduleResourceTypes.findIndex(x => x.id == resourceType.id), 1);
    this.formGroup.markAsDirty();
  }

  modify() {
    this.modifying = true;
    this.validateForm();
  }

  reset() {
    this.adding = false;
    this.modifying = false;
    this.validateForm();
    if (this.scheduleSubtypeTemp) this.scheduleSubtype = this.scheduleSubtypeTemp;
    this.getDetails();
    this.formGroup.reset();
    this.setFormDirty(false);
  }

  selectedScheduleResource(e: any) {
    if (!this.scheduleResourceTypes) {
      this.scheduleResourceTypes = [];
    }
    this.scheduleResourceTypes.push(e.option.value);
    this.autoInput.nativeElement.value = '';
  }

  selectedProcedureType(e: any) {
    this.formGroup.markAsDirty();
  }

  save() {
    if (this.scheduleSubtype) {
      this.scheduleSubtype.name = this.nameCtrl?.value;
      this.scheduleSubtype.description = this.descriptionCtrl?.value;
      this.scheduleSubtype.acronym = this.acronymCtrl?.value;
      this.scheduleSubtype.isActive = this.activeToggleCtrl?.value;
      this.scheduleSubtype.graceTime = this.graceTimeCtrl?.value;
      this.scheduleSubtype.nextDueDateTime = this.dueDateCtrl?.value;
      this.scheduleSubtype.procedureTypeID = this.procedureTypeCtrl?.value != 0 ? this.procedureTypeCtrl?.value : null;
      this.scheduleSubtype.dateFormat = this.dateFormatCtrl?.value;
      this.scheduleSubtype.resourceTypeIds = this.utils.JSONstringify(this.scheduleResourceTypes.map(x => x.id));
      this.scheduleSubtype.scheduleType = undefined;
      if (this.scheduleSubtype.id) {
        this.service.update(this.utils.Serialize(this.scheduleSubtype)).toPromise().then(data => {
          this.alert.success('Schedule Subtype Updated!!');
          this.getDetails();
          this.setFormDirty(false);
          // this.store.dispatch(new ScheduleSubtypesRefresh());
          // this.store.dispatch(new TemplateTypesRefreshByDocument(this.scheduleType?.documentTypeId));
        }).catch(() => this.alert.defaultError());
      }
      else {
        this.service.create(this.scheduleSubtype).toPromise().then(data => {
          this.alert.success('Schedule Subtype Created!!');
          this.getDetails();
          this.setFormDirty(false);
          // this.store.dispatch(new ScheduleSubtypesRefresh());
          // this.store.dispatch(new TemplateTypesRefreshByDocument(this.scheduleType?.documentTypeId));
        }).catch(() => this.alert.defaultError());
      }
    }
  }

  delete() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure to Delete this Schedule Subtype?',
        icon: 'stop'
      }
    });
    confirm.afterClosed().subscribe(data => {
      if (data && this.scheduleSubtype) {
        this.service.delete(this.scheduleSubtype.id).toPromise().then(() => {
          this.getDetails();
          this.store.dispatch(new ScheduleSubtypesRefresh());
        });
      }
    });
  }

}
