import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as KEVersionActions from 'src/app/components/kenable/store/ke-version/ke-version.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';

@Injectable({
  providedIn: 'root'
})
export class KEVersionStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore() {
    this.store.dispatch(new KEVersionActions.KEVersionRefresh());
  }

  initListeners() {
    // KEVersion
    this.signalRService.hubConnection?.on(Channels.KEVERSION, (data: GeneralSignalRResponse) => {
      switch (data.action) {

        case SignalRAction.Get:
          this.store.dispatch(new KEVersionActions.KEVersionRefresh());
          break;

        case SignalRAction.Add:
          this.store.dispatch(new KEVersionActions.KEVersionCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new KEVersionActions.KEVersionUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new KEVersionActions.KEVersionDelete(data.id));
          break;

        case SignalRAction.RequestRefresh:
          this.store.dispatch(new KEVersionActions.KEVersionRefreshByID(data.id));
      }
    });
  }
}
