import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { ChecklistTemplateStatus, Annotation } from '../../checklists';

@Injectable()
export class ChecklistBuilderAnnotationsService {
  BASE_URL = environment.urls.baseApiUrl; // Api URL
  Api = '/ChecklistBuilderAnnotations';

  public Statuses: ChecklistTemplateStatus[] = [];

  constructor(private http: HttpClient) {
  }

  create(annotation: Annotation): Observable<WfTaskLocalResource> {
    return this.http.post<WfTaskLocalResource>(this.BASE_URL + this.Api, annotation);
  }

  update(annotation: Annotation): Observable<WfTaskLocalResource> {
    return this.http.put<WfTaskLocalResource>(this.BASE_URL + this.Api, annotation);
  }

  delete(annotation: Annotation): Observable<WfTaskLocalResource> {
    return this.http.delete<WfTaskLocalResource>(this.BASE_URL + this.Api + '/' + annotation.id);
  }

  getAll(): Observable<Annotation[]> {
    const httpresult = this.http.get<Annotation[]>(this.BASE_URL + this.Api);
    return httpresult;
  }

  getByTemplateId(id: number): Observable<Annotation[]> {
    const httpresult = this.http.get<Annotation[]>(this.BASE_URL + this.Api + '/ByTemplateID/' + id);
    return httpresult;
  }

  getByChecklistId(id: number): Observable<Annotation[]> {
    const httpresult = this.http.get<Annotation[]>(this.BASE_URL + this.Api + '/ByChecklistID/' + id);
    return httpresult;
  }
}
