<mat-expansion-panel
  class="card mb-2 step-expansion"
  [hideToggle]="!step.collapsible"
  [expanded]="isStepExpanded()"
  (opened)="saveState(false)"
  (closed)="saveState(true)"
  [disabled]="!step.collapsible"
  *ngIf="!hidden"
>
  <mat-expansion-panel-header
    class="card-header"
    *ngIf="step?.name"
    [ngClass]="{ 'no-pointer': !step.collapsible }"
  >
    <div class="row full-width">
      <div class="col-10 d-flex align-items-center">
        <h5 class="step-name">{{ step.name }}</h5>
        <button
          class="duplicate-button ml-2"
          mat-icon-button
          color="primary"
          (click)="duplicate($event)"
          *ngIf="
            step.status == wfStepLocalStatus.NONE &&
            config.duplicate &&
            !disableTasks
          "
          [disabled]="!canDuplicate"
        >
          <mat-icon [matTooltip]="tooltip">content_copy</mat-icon>
        </button>
        <div
          class="small ml-2"
          *ngIf="step.status == wfStepLocalStatus.DUPLICATED"
        >
          <span class="pl-2">Duplicated by</span>
          <span class="pl-1 bold">{{ step.createdBy.name }}</span>
          <span class="pl-1">on</span>
          <span class="date pl-1">{{ step.createdOn | date : "medium" }}</span>
        </div>
        <button
          mat-icon-button
          *ngIf="
            step.status == wfStepLocalStatus.DUPLICATED &&
            !touched &&
            canDuplicate &&
            !disableTasks
          "
          color="warn"
          (click)="delete($event)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div class="col-2 pr-0">
        <div class="d-flex pr-0 justify-content-end">
          <checklist-task-counter
            class="counter"
            [step]="step"
          ></checklist-task-counter>
        </div>
      </div>
    </div>
  </mat-expansion-panel-header>
  <div class="card-body">
    <checklist-tasks
      [currentChecklist]="currentChecklist"
      [tasks]="tasks"
      [disabled]="disableTasks ?? false"
      [precheck]="precheck"
      (loading)="loading.emit($event)"
      (checkShared)="checkShared.emit($event)"
    ></checklist-tasks>
  </div>
</mat-expansion-panel>
