import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReviewDocumentCategory } from '../../../reviews.models';
import { ReviewsService } from '../../../reviews.service';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-review-attachment-type-details',
  templateUrl: './review-attachment-type-details.component.html',
  styleUrls: ['./review-attachment-type-details.component.scss']
})
export class ReviewAttachmentTypeDetailsComponent implements OnInit {
  @Input() currentReviewDocumentCategory!: ReviewDocumentCategory;

  public modifying!: boolean;
  public selected!: boolean;
  public adding: boolean = false;
  public disabledStatus!: boolean;
  public statusList: any = [{ name: "Active", id: 1 }, { name: "Inactive", id: 0 }];
  public showCancelButton!: boolean;
  public isModifiying!: boolean;
  public modifyAvailable: boolean = false;
  public disabledTimer!: boolean;
  listReviewCategories: ReviewDocumentCategory[] = [];
  private valid!: boolean;
  oldName: string = "";
  oldDescription: string = "";
  oldStaus?: number = undefined;
  private errorMsg: string[] = [];

  constructor(
    private _alert: AlertService,
    private _service: ReviewsService,
    private dialogRef: MatDialogRef<ReviewAttachmentTypeDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReviewDocumentCategory) { }

  ngOnInit(): void {
    if (this.currentReviewDocumentCategory == undefined) {
      this.currentReviewDocumentCategory = { name: "", description: "", status: 0 } as ReviewDocumentCategory;
    }
    this.obtainReviewCategories();
    if (this.data) {
      this.getDetails(this.data);
    }
  }

  async obtainReviewCategories() {
    await this._service.getReviewDocumentCategories().toPromise().then(result => {
      if (!(result?.length)) {
        result = [];
      }
      this.listReviewCategories = result;
    });
  }

  async saveReviewDocumentCategory() {
    var reviewDocCategory = this.createReviewDocumentCategory();
    if (await this.isValid()) {
      if (!this.currentReviewDocumentCategory?.id) {
        var reviewDocumentCategorySearch = this.listReviewCategories.filter(cat => cat.name?.trim() == this.currentReviewDocumentCategory?.name?.trim()).length;
        if (reviewDocumentCategorySearch == 0) {
          await this._service.createReviewDocumentCategory(reviewDocCategory).toPromise().then(result => {
            if (this.currentReviewDocumentCategory && result)
              this.currentReviewDocumentCategory.id = result.id;
          }).finally(() => {
            this.dialogRef.close("Create");
          });
        }
        else {
          this._alert.warning("Warning !! There is already a category with the name entered!!");
        }
      } else {
        reviewDocCategory.id = this.currentReviewDocumentCategory.id;
        this._service.updateReviewDocumentCategory(reviewDocCategory).toPromise().then(result => {
        }).finally(() => {
          this.dialogRef.close("Update");
        });
      }
    }
  }

  async isValid() {
    this.valid = true;
    if (!this.currentReviewDocumentCategory?.name) {
      this.errorMsg.push("Field <b>Name</b> should not be blank!");
      this.valid = false;
    }
    if (!this.currentReviewDocumentCategory?.description) {
      this.errorMsg.push("Field <b>Description</b> should not be blank!");
      this.valid = false;
    }
    return this.valid;
  }

  createReviewDocumentCategory(): ReviewDocumentCategory {
    var reviewDocumentCategory: ReviewDocumentCategory = {
      name: this.currentReviewDocumentCategory?.name,
      description: this.currentReviewDocumentCategory?.description,
      status: this.currentReviewDocumentCategory?.status,
      id: 0,
      type: undefined,
      code: ""
    }
    return reviewDocumentCategory;
  }

  getDetails(data: ReviewDocumentCategory) {
    this.clear();
    if (this.currentReviewDocumentCategory)
      if (!data.id) {
        this.currentReviewDocumentCategory.description = data.description;
        this.currentReviewDocumentCategory.name = data.name;
        this.currentReviewDocumentCategory.status = data.status;
        this.showCancelButton = true;
        this.adding = true;
        this.modifying = false;
        this.disabledStatus = false;
        this.selected = false;
      }
      else {
        this.selected = true;
        this.modifying = true;
        this.adding = false
        this.disabledStatus = false;
        this.oldName = data.name ?? '';
        this.oldDescription = data.description ?? '';
        this.oldStaus = data.status;
        this.currentReviewDocumentCategory.id = data.id;
        this.currentReviewDocumentCategory.description = data.description;
        this.currentReviewDocumentCategory.name = data.name;
        this.currentReviewDocumentCategory.status = data.status;
        this.showCancelButton = true;
      }
  }

  clear() {
    this.currentReviewDocumentCategory = { name: "", description: "", status: 0 } as ReviewDocumentCategory;
    this.adding = false;
    this.selected = false;
    this.modifying = false;
    this.isModifiying = false;
    this.disabledStatus = false;
    this.showCancelButton = false;
    this.disabledTimer = false;
  }

  close() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
