<div class="card">
  <div class="card-header">
    <span class="card-title">Disapproval Reason</span>
  </div>
  <div class="card-body">
    <mat-form-field>
      <mat-label>Disapproval Reason</mat-label>
      <textarea
        matInput
        [(ngModel)]="disapprovalReason"
        rows="5"
        placeholder="Enter a Disapproval Reason"
        name="disapprovalReason"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        (click)="add()"
        [disabled]="!disapprovalReason"
      >
        Accept
      </button>
    </div>
  </div>
</div>
