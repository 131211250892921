<div class="row">
  <div class="col-12 col-sm-12">
    <div class="mat-container">
      <mat-table
        cdkDropList
        #table
        [dataSource]="dataSource"
        [cdkDropListData]="dataSource"
        (cdkDropListDropped)="reorderTable($event, dataSource)"
        [cdkDropListDisabled]="!(isAdding || isEditing)"
        [cdkDropListOrientation]="'vertical'"
      >
        <ng-container matColumnDef="position">
          <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon *ngIf="enableMove" cdkDragHandle color="accent"
              >reorder</mat-icon
            >
            {{ element.position }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="shieldingName">
          <mat-header-cell *matHeaderCellDef> Shielding Name </mat-header-cell>
          <mat-cell
            [ngClass]="element.resource.cssClass"
            *matCellDef="let element"
          >
            {{ element.name }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="keText">
          <mat-header-cell *matHeaderCellDef> KE Text </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.resource.keText }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="shutdown">
          <mat-header-cell *matHeaderCellDef>
            Long Shutdown Check Only?
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-slide-toggle
              [(ngModel)]="element.longShutdown"
              (change)="longChanged(element)"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!(isAdding || isEditing)"
            >
            </mat-slide-toggle>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="checked">
          <mat-header-cell *matHeaderCellDef>
            Checked on startups?
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-slide-toggle
              [(ngModel)]="element.checkboxVisible"
              (change)="checkedChange(element)"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!(isAdding || isEditing)"
            >
            </mat-slide-toggle>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tunnel">
          <mat-header-cell *matHeaderCellDef> In Tunnel? </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-slide-toggle
              [(ngModel)]="element.inTunnel"
              (change)="someChange(element)"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!(isAdding || isEditing)"
            >
            </mat-slide-toggle>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="subLocation">
          <mat-header-cell *matHeaderCellDef> Sub Location </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.subLocationNames }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sharedWith">
          <mat-header-cell *matHeaderCellDef> Shared With </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.sharedWithNames }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="isRSSD">
          <mat-header-cell *matHeaderCellDef> Is RSSD? </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-slide-toggle
              [(ngModel)]="element.isRSSD"
              (change)="someChange(element)"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!(isAdding || isEditing)"
            >
            </mat-slide-toggle>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="add">
          <mat-header-cell *matHeaderCellDef>
            <mat-icon
              mat-list-icon
              class="add-icon"
              (click)="openAddShieldingDialog()"
              >add</mat-icon
            >
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon
              mat-list-icon
              class="edit"
              color="accent"
              (click)="openEditShieldingDialog(element)"
              >edit</mat-icon
            >
            <mat-icon
              mat-list-icon
              class="delete"
              color="warn"
              (click)="deleteShielding(element)"
              >delete</mat-icon
            >
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          cdkDrag
          [cdkDragData]="row"
          [cdkDragDisabled]="!enableMove"
          cdkDragLockAxis="y"
          [ngClass]="{
            'added-row': row.isAdded,
            'deleted-row': row.isDeleted,
            'modified-row': row.isModified && !row.isAdded && !row.isDeleted
          }"
        >
        </mat-row>
      </mat-table>
    </div>
  </div>
</div>
