import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Checklist, ChecklistTemplate } from 'src/app/components/checklists/checklists';
import { environment } from 'src/environments/environment';
import { PrivilegeEnum } from '../../../services/role-privilege/privilege-enum';
import { ProcedureNote } from '../models/procedure-note.model';
import { ProcedureSignatureType } from '../enums/procedure-signature-type.enum';
import { ProcedureSignature } from '../models/procedure-signature.model';
import { ProcedureTypeEnum } from '../enums/procedure-type.enum';
import { Procedure } from '../models/procedure.model';

@Injectable({
  providedIn: 'root'
})
export class ProcedureService {
  private url: string = environment.urls.baseApiUrl + '/Procedure';

  constructor(
    private http: HttpClient,
  ) { }

  public read(): Observable<Procedure[]> {
    return this.http.get<Procedure[]>(this.url);
  }

  public readByType(type: number): Observable<Procedure[]> {
    return this.http.get<Procedure[]>(this.url + '/ByProcedureType/' + type);
  }

  public create(procedure: Procedure): Observable<Procedure> {
    return this.http.post<Procedure>(this.url, procedure);
  }

  public update(id: number, procedure: Procedure): Observable<Procedure> {
    return this.http.put<Procedure>(this.url + '/' + id.toString(), procedure);
  }

  public delete(id: number): Observable<any> {
    return this.http.delete<any>(this.url + '/' + id.toString());
  }

  public createSignature(procedureSignature: ProcedureSignature): Observable<ProcedureSignature> {
    return this.http.post<ProcedureSignature>(this.url + '/Signature', procedureSignature);
  }

  public deleteSignature(id: number): Observable<any> {
    return this.http.delete<any>(this.url + '/Signature/' + id.toString());
  }

  public approveSignature(id: number): Observable<any> {
    return this.http.put<any>(this.url + '/Signature/Approve/' + id.toString(), null);
  }

  public unapproveSignature(id: number): Observable<any> {
    return this.http.put<any>(this.url + '/Signature/Unapprove/' + id.toString(), null);
  }

  public createNote(note: ProcedureNote): Observable<ProcedureNote> {
    return this.http.post<ProcedureNote>(this.url + '/Note', note);
  }

  public deleteNote(id: number): Observable<any> {
    return this.http.delete<any>(this.url + '/Note/' + id.toString());
  }

  public complete(id: number, procedure: Procedure): Observable<Procedure> {
    return this.http.post<Procedure>(this.url + '/Complete/' + id.toString(), procedure);
  }

  // public signatures(userId: number): Observable<ProcedureSignature[]> {
  //   return this.http.get<ProcedureSignature[]>(this.url + '/signatures/' + userId);
  // }

  public getChecklist(procedureMasterID: number): Observable<Checklist> {
    return this.http.get<Checklist>(this.url + '/checklist/' + procedureMasterID.toString());
  }

  public getChecklistTemplate(procedureMasterID: number, status?: number): Observable<ChecklistTemplate> {
    return this.http.get<ChecklistTemplate>(this.url + '/checklistTemplate/' + procedureMasterID.toString() + (status !== undefined && status !== null ? '?status=' + status?.toString() : ''));
  }

  public getPrivilege(procedureType?: ProcedureTypeEnum, procedureSignatureType?: ProcedureSignatureType): PrivilegeEnum | null {
    switch (procedureType) {
      case ProcedureTypeEnum.EPSTest:
        switch (procedureSignatureType) {
          case ProcedureSignatureType.Preparer: return PrivilegeEnum.PCEPSPreparer;
          case ProcedureSignatureType.Reviewer: return PrivilegeEnum.PCEPSReviewer;
          case ProcedureSignatureType.Approver: return PrivilegeEnum.PCEPSApprover;
          default: return null;
        }

      case ProcedureTypeEnum.RSSTest:
        switch (procedureSignatureType) {
          case ProcedureSignatureType.Preparer: return PrivilegeEnum.PCRSSPreparer;
          case ProcedureSignatureType.Reviewer: return PrivilegeEnum.PCRSSReviewer;
          case ProcedureSignatureType.Approver: return PrivilegeEnum.PCRSSApprover;
          default: return null;
        }

      case ProcedureTypeEnum.RadSurvey:
        switch (procedureSignatureType) {
          case ProcedureSignatureType.Preparer: return PrivilegeEnum.PCRADPreparer;
          case ProcedureSignatureType.Reviewer: return PrivilegeEnum.PCRADReviewer;
          case ProcedureSignatureType.Approver: return PrivilegeEnum.PCRADApprover;
          default: return null;
        }
    }
    return null;
  }
}
