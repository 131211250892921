<div
  class="card border-0 full-width full-height pt-2 d-flex flex-column justify-content-between"
>
  <div class="card-body p-0">
    <div class="row">
      <div class="col">
        <div class="mat-container scrollbar">
          <mat-table [dataSource]="dataSourceRadManagement">
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef>Interlock</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div *ngIf="!element.editing">{{ element.name }}</div>
                <mat-form-field class="no-subscript" *ngIf="element.editing">
                  <input
                    matInput
                    class="inputEdit text-break"
                    [(ngModel)]="element.preName"
                    [ngModelOptions]="{ standalone: true }"
                /></mat-form-field>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="defaultValue">
              <mat-header-cell *matHeaderCellDef
                >Model Default Value</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                <div *ngIf="!element.editing">{{ element.defaultValue }}</div>
                <input
                  matInput
                  class="inputEdit text-break"
                  *ngIf="element.editing"
                  [(ngModel)]="element.preDefaultValue"
                  [ngModelOptions]="{ standalone: true }"
                />
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="options">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div class="d-flex justify-content-center">
                  <button
                    mat-icon-button
                    color="warn"
                    *ngIf="element.editing"
                    (click)="discardChanges(element)"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="accent"
                    *ngIf="!element.editing"
                    (click)="setEditing(element)"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="primary"
                    *ngIf="element.editing"
                    (click)="saveChanges(element)"
                  >
                    <mat-icon>save</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="delete(element)"
                    [disabled]="element.deleteDisabled"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsRadManagement"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnsRadManagement"
            ></mat-row>
          </mat-table>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer p-0 pt-2">
    <div class="row">
      <div class="col-10">
        <mat-form-field class="no-subscript">
          <mat-label>Interlock</mat-label>
          <input
            matInput
            [(ngModel)]="name"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <button mat-flat-button color="primary" (click)="create()">Add</button>
      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-12">
      <mat-dialog-actions align="center">
        <button
          mat-flat-button
          class="cancel-btn"
          color="warn"
          (click)="cancel()"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          class="accept-btn"
          color="primary"
          (click)="accept()"
        >
          Accept
        </button>
      </mat-dialog-actions>
    </div>
  </div> -->
</div>
