import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, ElementRef, Injector } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BeamlineStatusRefresh } from './store/bl-status.action';
import { utils } from 'src/app/modules/libs/utils';
import { BeamlineStatus } from './beamline-status';
import { SendIdService } from './send-id.service';
import { PV } from 'src/app/services/pv-info/pv-info';
import { BeamlineRestriction } from '../../catalogs/operational-restrictions/models';
import { BaseComponent } from 'src/app/common/base/base.component';
import * as moment from 'moment';
import { icons } from 'src/app/common/enumerations/enumerations';

@Component({
  selector: 'app-beam-status',
  templateUrl: './beamline-status.component.html',
  styleUrls: ['./beamline-status.component.scss']
})
export class BeamStatusComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild('beamStatus')
  beamStatus!: ElementRef;
  @ViewChild('header1')
  header1!: ElementRef;
  headerOnTop = false;
  tableWidth!: string;
  headerBottom = '';

  beamlineStatuses!: BeamlineStatus[];
  beamlineStatusesFiltered!: BeamlineStatus[];
  beamlineStatuses$!: Observable<BeamlineStatus[]>;
  beamlineStatusesSubs!: Subscription;

  beamlineRestrictions?: BeamlineRestriction[];
  beamlineRestrictionsFiltered?: BeamlineRestriction[];
  beamlineRestrictions$!: Observable<BeamlineRestriction[]>;
  beamlineRestrictionsSubs!: Subscription;

  pvs?: PV[];
  pvs$!: Observable<PV[]>;
  pvsSubs!: Subscription;

  public displayedColumns: string[] = ['beamlineName', 'shutterName', 'shutterStatus', 'rssStatus', 'keReady', 'restrictions'];
  public dataSource = new MatTableDataSource<BeamlineStatus>();
  public selectedRowID = -1;

  spanningColumns = ['beamlineName'];
  spans = [];

  maxHeight: string | null = null;
  loading!: boolean;

  filter?: string;

  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @Output() rowSelected = new EventEmitter<any>();
  @Output() sendId = new EventEmitter<any>();

  routerPath: string;

  constructor(
    protected override injector: Injector,
    private router: Router,
    public sendIdService: SendIdService
  ) {
    super(injector);
    this.routerPath = router.url;
  }

  ngOnInit() {
    this.loadPVs();
    this.loadBeamlineStatuses();
    this.loadBeamlineRestrictions();
  }

  override currentUserUpdated(): void {
    this.ngOnInit();
  };

  loadBeamlineStatuses() {
    this.loading = true;
    this.beamlineStatuses$ = this.store.select(state => state.BeamlineStatus.data);
    this.beamlineStatusesSubs = this.beamlineStatuses$.subscribe(data => {
      if (data.length) {
        this.beamlineStatuses = data.sort((a, b) => utils.sortArrayAlphabeticallyWithComplexNumbers(a.beamlineName ?? '', b.beamlineName ?? '')).filter(d => this.filter ? (d.beamlineName?.toLowerCase().includes(this.filter) || d.shutterName?.toLowerCase().includes(this.filter)) : d);
        this.getAdditionalInfo();
        this.loading = false;
      }
    });
  }

  loadBeamlineRestrictions() {
    this.beamlineRestrictions$ = this.store.select(state => state.BeamlineRestrictions.data);
    this.beamlineRestrictionsSubs = this.beamlineRestrictions$.subscribe(data => {
      if (data?.length) {
        this.beamlineRestrictions = data;
        this.beamlineRestrictionsFiltered = data.filter(x => Math.abs(x.status) == 1);
        this.getAdditionalInfo();
      }
    });
  }

  loadPVs() {
    this.pvs$ = this.store.select(state => state.PV.data);
    this.pvsSubs = this.pvs$.subscribe(data => {
      if (data?.length) {
        this.pvs = data;
        this.getAdditionalInfo();
      }
    });
  }

  ngAfterViewInit() {
    this.store.dispatch(new BeamlineStatusRefresh());
  }

  applyFilter(e: any) {
    this.filter = e;
    this.loadBeamlineStatuses();
  }

  rowClick(id: number) {
    this.sendIdService.id = id;
    if (this.router.url === '/beamline-overview') {
      this.sendId.emit(id);
    } else {
      this.sendIdService.SendId(id);
      setTimeout(() => {
        this.router.navigate(['beamline-overview']);
      }, 300);
    }
  }

  tablePercentage(percent: number) {
    if (this.beamStatus) {
      return (this.beamStatus.nativeElement.offsetWidth * percent).toFixed(0);
    } else { return 50; }
  }

  statusClass(status: number) {
    switch (status) {
      case 1: return 'blue';
      case 2: return 'yellow';
      case 3: return 'res-status-color-decommissioned';
      case 4: return 'res-status-color-inactive';
      default: return '';
    }
  }

  getClass(text: string) {
    let style = '';
    if (text) {
      style = text.trim().toLowerCase();
    }
    switch (style) {
      case 'offline': return 'badge badge-offline badge-pill';
      case 'online': return 'badge badge-online badge-pill';
      case 'open': return 'badge badge-open badge-pill';
      case 'closed': return 'badge badge-closed badge-pill';
      case 'error': return 'badge badge-error badge-pill';
      default: return 'badge badge-unknown badge-pill';
    }
  }

  getAdditionalInfo() {
    this.beamlineStatuses?.map(s => {
      s.moreInfo = s.documents?.length ? s.documents.map(doc => doc.serialNo).join(', ') : null;
      s.keReady = this.getKeReadyStatus(s);
      this.getRestrictions(s);
    });
    this.dataSource = new MatTableDataSource(this.beamlineStatuses);
  }

  getKeReadyStatus(s: BeamlineStatus) {
    const pssPV = this.pvs?.find(p => p.pvName == s.pssChannelName)
    if (pssPV)
      s.pssChannelStatus = pssPV.value == '1' ? 'Open' : 'Closed';

    const rssPV = this.pvs?.find(p => p.pvName == s.rssChannelName);
    if (rssPV) {

      switch (rssPV.value) {
        case "1":
          s.rssChannelStatus = 'Online';
          if (s.keReady)
            s.keReady.cssClass = s.keReady.ready ? "hidden" : "badge badge-danger blinking small";
          break;

        case "0":
          s.rssChannelStatus = 'Offline';
          if (s.keReady)
            s.keReady.cssClass = s.keReady.ready ? "badge badge-primary" : "badge badge-warning small";
          break;

        default:
          if (s.keReady)
            s.keReady.cssClass = "hidden";
          break;
      }
    }
    else {
      if (s.keReady)
        s.keReady.cssClass = "hidden";
    }
    return s.keReady;
  }

  getRestrictions(s: BeamlineStatus) {
    const restrictions = this.beamlineRestrictionsFiltered?.filter(r => r.beamlineID == s.beamlineId);
    s.restrictions = restrictions;
    const priv = this.hasPrivilege(this.privilegeEnum.BeamlineRestrictions) || this.hasPrivilege(this.privilegeEnum.BeamlineRestrictionsApprove);
    const allRestrictions = this.beamlineRestrictions?.filter(r => r.beamlineID == s.beamlineId && priv && !r.code);

    s.hasPendingRestrictions = (restrictions?.length != allRestrictions?.length || restrictions?.some(r => r.status == -1)) && priv;

    let restrictionsTooltip = restrictions;

    if (priv) restrictionsTooltip = allRestrictions;

    const tooltipText = restrictionsTooltip?.map(r => {
      let pendingMessage = '';
      if (priv)
        if (r.status == 0) pendingMessage = `<span class="blue bold mr-2">${icons.pendingActions}Pending Approval</span>`;
        else if (r.status == -1) pendingMessage = `<span class="red bold mr-2">${icons.autoDelete}Pending Deletion</span>`;
      return `<div class="restriction-tooltip-row my-1 d-flex align-items-center">${pendingMessage}<span>${r.description.replaceAll('div', 'span')} on <span class="ml-1 date">${moment(r.createdOn).format('M/D/YYYY')}</span></span></div>`;
    }).join('');
    s.restrictionsTooltip = tooltipText;

  }

}
