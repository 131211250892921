import { Role } from 'src/app/components/catalogs/roles/services/role';

export interface MenuItem {
  id?: number;
  name?: string;
  description?: string;
  status: number;
  type: number | null;
  code?: string;
  parentMenuItemID?: number | null;
  route?: string;
  order: number;
  table?: string;
  tab?: number;
  isSeparator: boolean | null;
  menuValidation?: MenuValidation;
  menuItemType?: MenuItemType;
  childMenuItems?: MenuItem[];
  menuItemRoles?: MenuItemRole[];
  createdOn?: string;
  createdBy?: number;
  updatedOn?: string | null;
  updatedBy?: number | null;
  fileV2Id?: number | null;

  routerLink?: any;
  visible?: boolean;
  disableEdit?: boolean;
}

export interface MenuItemRole {
  id?: number;
  menuItemID?: number;
  roleID?: number;
  role?: Role;
}

export interface MenuItemType {
  id?: number;
  name?: string;
}

export enum MenuItemTypes {
  Separator = -1,
  Normal = 1,
  Submenu = 2,
  Table = 3,
  PopUp = 4,
  Link = 5,
  Ignore = 9
}

export enum MenuValidation {
  ByRole = 0,
  RSSUser = 1,
  EPSUser = 2
}

export type MenuItemOrderList = {
  id: number;
  order: number;
  parentid: number;
}
