<div class="card">
  <div class="card-header">
    <span class="card-title">{{
      inputData.radLocation.id ? "Edit location" : "Add Location"
    }}</span>
  </div>
  <div class="card-body">
    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Location Name</mat-label>
            <input
              matInput
              [(ngModel)]="inputData.radLocation.name"
              formControlName="name"
            />
            <mat-error *ngIf="name?.invalid">{{ getErrorMsg(name) }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select
              [(ngModel)]="inputData.radLocation.typeId"
              formControlName="type"
            >
              <mat-option
                *ngFor="let radLocationType of inputData.radLocationTypes"
                [value]="radLocationType.id"
              >
                {{ radLocationType.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="type?.invalid">{{ getErrorMsg(type) }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>RPIx Name</mat-label>
            <input
              matInput
              [(ngModel)]="inputData.radLocation.rpixName"
              formControlName="rpixName"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Location Description</mat-label>
            <input
              matInput
              [(ngModel)]="inputData.radLocation.description"
              formControlName="description"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Summation Chassis Location</mat-label>
            <input
              matInput
              [(ngModel)]="inputData.radLocation.summation"
              formControlName="summation"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Controller Location</mat-label>
            <input
              matInput
              [(ngModel)]="inputData.radLocation.controllerLocation"
              formControlName="controllerLocation"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Controller Power</mat-label>
            <input
              matInput
              [(ngModel)]="inputData.radLocation.controllerPower"
              formControlName="controllerPower"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Reset Location</mat-label>
            <input
              matInput
              [(ngModel)]="inputData.radLocation.resetLocation"
              formControlName="resetLocation"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Interlock Chain Area</mat-label>
            <mat-select
              [(ngModel)]="radInterlockIds"
              multiple
              formControlName="interlocks"
            >
              <mat-option
                *ngFor="let radInterlock of inputData.radInterlocks"
                [value]="radInterlock.id"
              >
                {{ radInterlock.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="interlocks?.invalid">{{
              getErrorMsg(interlocks)
            }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Tripset A1 mR/h</mat-label>
            <input
              matInput
              [(ngModel)]="inputData.radLocation.tripsetA1"
              formControlName="tripsetA1"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Tripset I1 mR</mat-label>
            <input
              matInput
              [(ngModel)]="inputData.radLocation.tripsetI1"
              formControlName="tripsetI1"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Tripset mR/h</mat-label>
            <input
              matInput
              [(ngModel)]="inputData.radLocation.tripset"
              formControlName="tripset"
            />
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="card-footer">
    <mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        [disabled]="formGroup.pristine"
        mat-flat-button
        color="primary"
        (click)="add()"
      >
        Accept
      </button>
    </mat-dialog-actions>
  </div>
</div>

<div class="container-fluid"></div>
