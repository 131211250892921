import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AnnotationsCreate, AnnotationsDelete, AnnotationsRefresh, AnnotationsUpdate } from './annotations.action';
import { Annotation } from '../../checklists';
import { ChecklistBuilderAnnotationsService } from '../../checklist-builder/checklist-builder-annotations/checklist-builder-annotations.service';


export class AnnotationsStateModel {
  data: Annotation[] = [];
}

@State<AnnotationsStateModel>({
  name: 'Annotations',
  defaults: {
    data: []
  }
})
@Injectable()
export class AnnotationsState {
  constructor(
    private _service: ChecklistBuilderAnnotationsService
  ) { }

  @Selector()
  static read(state: AnnotationsStateModel) {
    return state.data;
  }

  @Action(AnnotationsRefresh)
  refresh({ patchState }: StateContext<AnnotationsStateModel>) {
    this._service.getAll().toPromise().then(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(AnnotationsCreate)
  create({ getState, patchState }: StateContext<AnnotationsStateModel>, { item }: AnnotationsCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(AnnotationsUpdate)
  update({ getState, patchState }: StateContext<AnnotationsStateModel>, { id, item }: AnnotationsUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(AnnotationsDelete)
  delete({ getState, patchState }: StateContext<AnnotationsStateModel>, { id }: AnnotationsDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
