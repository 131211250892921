import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as ChecklistTemplatesActions from './checklist-templates.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';

@Injectable({
  providedIn: 'root'
})
export class ChecklistTemplatesStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore(documentType: number) {
    this.store.dispatch(new ChecklistTemplatesActions.ChecklistTemplatesRefreshPending());
    this.store.dispatch(new ChecklistTemplatesActions.ChecklistTemplatesRefreshByDocument(documentType));
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.CHECKLIST_TEMPLATES, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new ChecklistTemplatesActions.ChecklistTemplatesCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new ChecklistTemplatesActions.ChecklistTemplatesUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new ChecklistTemplatesActions.ChecklistTemplatesDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new ChecklistTemplatesActions.ChecklistTemplatesRefreshPending());
          this.store.dispatch(new ChecklistTemplatesActions.ChecklistTemplatesRefreshByDocument(data.id));
          break;

        case SignalRAction.RequestRefresh:
          this.store.dispatch(new ChecklistTemplatesActions.ChecklistTemplatesRefreshById(data.id));
          break;
      }
    });
  }
}
