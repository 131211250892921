import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { utils } from "src/app/modules/libs/utils";
import { User } from "src/app/components/catalogs/user-catalog/services/user";

@Component({
  selector: "cl-editor-checkbox-roles",
  templateUrl: "./cl-editor-checkbox-roles.component.html",
  styleUrls: ["./cl-editor-checkbox-roles.component.scss"],
})
export class ClEditorCheckboxRolesComponent implements OnInit {
  @Input() disabled!: boolean;
  @Input() options?: string;
  @Input() configuration?: string;

  roleOptions?: RoleOption[];

  @Output() changed = new EventEmitter<string>();
  @Output() checkbox = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
    if (this.options) {
      this.roleOptions = utils.JSONparse(this.options) as RoleOption[];
    } else {
      this.roleOptions = [];
    }
  }

  add() {
    var opt = new RoleOption();
    opt.id = this.roleOptions?.length
      ? (this.roleOptions.map((x) => x.id).sort((a, b) => (b ?? 0) - (a ?? 0))[0] ?? 0) + 1
      : 1;
    this.roleOptions?.push(opt);
    this.roleOptionsChanged();
  }

  del(e: any) {
    this.roleOptions?.splice(this.roleOptions?.indexOf(e) ?? -1, 1);
    this.roleOptionsChanged();
  }

  roleOptionsChanged(e: RoleOption | null = null) {
    if (e && this.roleOptions) {
      const i = this.roleOptions?.findIndex((x) => x.id == e.id) ?? -1;
      this.roleOptions[i] = e;
    }
    this.options = utils.JSONstringify(this.roleOptions);
    this.changed.emit(this.options);
  }
}

export class RoleOption {
  id?: number;
  roleIDs?: number[];
  roleCodes?: string;
  userID?: number | null;
  checked?: boolean;
  user?: User | null;
  disabled?: boolean;
  date?: Date | null;
}
