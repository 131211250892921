import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input, Injector } from '@angular/core';
import { ReviewType, ReviewBoardType, ReviewTypesEnum } from '../../reviews.models';
import { Observable, Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { ReviewsService } from '../../reviews.service';
import { ReviewBoardTypesRefresh } from '../../store/review-board-types/review-board-types.action';
import { ReviewsRefresh } from '../../store/reviews/reviews.action';
import { ReviewsSettingsReviewTypesDetailsComponent } from './reviews-settings-review-types-details/reviews-settings-review-types-details.component';

@Component({
  selector: 'reviews-settings-review-types',
  templateUrl: './reviews-settings-review-types.component.html',
  styleUrls: ['./reviews-settings-review-types.component.scss']
})
export class ReviewsSettingsReviewTypesComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  reviewBoardType!: ReviewBoardType;

  reviewTypes!: ReviewType[];
  reviewTypes$!: Observable<ReviewType[]>;
  reviewTypesSubs!: Subscription;

  reviewTypesEnum = ReviewTypesEnum;
  reviewTypeEnum: ReviewTypesEnum = ReviewTypesEnum.FULL;

  options = [{ id: 1, name: 'Full Review Types', checked: true }, { id: 2, name: 'Modification Review Types', checked: false }];
  optionSelectedId = 1;

  constructor(
    protected override injector: Injector,
    private service: ReviewsService,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.reviewTypesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadReviewTypes();
  }

  loadReviewTypes() {
    this.reviewTypes$ = this.store.select(state => state.ReviewTypes.data);
    this.reviewTypesSubs = this.reviewTypes$.subscribe(data => {
      if (data.length) {
        this.reviewTypes = data.filter(x => x.type == this.reviewTypeEnum).sort((a, b) => a.order - b.order);
        this.renumberArray();
      }
    });
  }

  ngOnInit(): void {
    this.reviewTypeEnum = this.reviewTypesEnum.FULL;
  }

  edit(reviewType: ReviewType) {
    const confirm = this.dialog.open(ReviewsSettingsReviewTypesDetailsComponent, {
      width: '400px',
      data: {
        title: 'Modify Review Type',
        reviewBoardType: this.reviewBoardType,
        reviewType,
        reviewTypeEnum: this.reviewTypeEnum,
        disableClose: true,
        autoFocus: false
      }
    });
    confirm.afterClosed().toPromise().then(data => {
      if (data) {
        this.service.updateReviewType(this.utils.Serialize(data.reviewType)).toPromise().then(() => {
          this.alert.message('ReviewTypeUpdated');
          this.store.dispatch(new ReviewBoardTypesRefresh());
          this.store.dispatch(new ReviewsRefresh());
        });
      }
    });
  }

  delete(reviewType: ReviewType) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('ReviewTypeDeleteConfirmation').description,
        icon: 'warn'
      }
    });
    confirm.afterClosed().toPromise().then(data => {
      if (data && reviewType.id) {
        this.service.deleteReviewType(reviewType.id).toPromise().then(() => {
          this.alert.message('ReviewTypeDeleted');
          this.store.dispatch(new ReviewBoardTypesRefresh());
        });
      }
    });
  }

  add() {
    const confirm = this.dialog.open(ReviewsSettingsReviewTypesDetailsComponent, {
      width: '400px',
      data: {
        title: 'Add Review Type',
        reviewBoardType: this.reviewBoardType,
        reviewTypeEnum: this.reviewTypeEnum,
        disableClose: true,
        autoFocus: false
      }
    });
    confirm.afterClosed().toPromise().then(data => {
      if (data) {
        this.service.createReviewType(data.reviewType).toPromise().then(() => {
          this.alert.message('ReviewTypeCreated');
          this.store.dispatch(new ReviewBoardTypesRefresh());
        });
      }
    });
  }

  drop(event: CdkDragDrop<ReviewType[]>) {
    if (event?.previousIndex !== event?.currentIndex) {
      moveItemInArray(this.reviewTypes, event.previousIndex, event.currentIndex);
      this.renumberArray();
    }
  }

  renumberArray() {
    let order = 1;
    this.reviewTypes?.map(reviewType => {
      if (reviewType.order != order) {
        reviewType.order = order;
        this.service.updateReviewTypeOrder(reviewType).subscribe(() => {
          this.store.dispatch(new ReviewBoardTypesRefresh());
        });
      }
      order++;
    });
  }

  radioChanged(e: any) {
    this.reviewTypeEnum = e;
    this.loadReviewTypes();
  }
}
