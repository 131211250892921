<div class="d-flex flex-column justify-content-between pt-2 settings-container">
  <div class="card mb-2" *ngFor="let section of sections">
    <div class="card-header">
      {{ section.name }}
    </div>
    <div class="card-body" cdkDropListGroup>
      <fieldset class="field-group" *ngFor="let step of getSteps(section.id)">
        <legend>{{ step.name }}</legend>
        <div
          class="d-flex flex-row justify-content-around flex-wrap drop-list"
          cdkDropList
          [cdkDropListOrientation]="section.orientation"
          [cdkDropListData]="getTasks(step)"
          id="{{ 'draglist-' + step.id }}"
          [cdkDropListConnectedTo]="getConnectedTo(section, step)"
          (cdkDropListDropped)="drop($event, section, step)"
        >
          <div
            *ngFor="let task of getTasks(step)"
            class="my-1 mx-2"
            [ngStyle]="{
              width: section.id == 4 ? '100%' : calcWidth(step)
            }"
            cdkDrag
            [cdkDragData]="task"
          >
            <span class="relative cursor-move">
              <master-control
                [builder]="true"
                [type]="task.type"
                [visible]="true"
                [disabled]="false"
                [color]="'primary'"
                number=""
                [header]="task.name"
                [question]="task.type == 3 ? task.name2 : task.name"
                [questionDisabled]="true"
                [label]="task.name"
                [text]="task.name2"
                [radioQuestion]="task.name3"
                [checkbox_checked]="false"
                [showApprove]="getAction(1, task)"
                [showDisapprove]="getAction(2, task)"
                [showUnapprove]="getAction(3, task)"
                [roleIds]="getRoleIds(task)"
                [roleCodes]="getRoleCodes(task)"
                [textBefore]="task.name"
                [textAfter]="task.name2"
                [textAfter2]="task.name3"
                [checkboxVisible]="true"
                [disableEdit]="true"
                [moreInfo]=""
                [configuration]="task.configuration"
                [formType]="1"
                [formId]="0"
                [taskId]="0"
                style="width: 100%"
                [RolesToApproveChanges]="['RWAPI']"
                [tooltips]="getTooltips(task)"
              >
              </master-control>

              <button mat-icon-button (click)="edit(task)" class="edit-button">
                <mat-icon color="accent">edit</mat-icon>
              </button>
            </span>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</div>
