import { Directive, ElementRef, Renderer2, HostListener, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[resizableDialog]'
})
export class ResizableDirective implements OnInit {
  @Input() resizableGrabWidth = 10;
  @Input() resizableMinWidth = 200;
  @Input() resizableMinHeight = 200;

  private draggingCorner = false;
  private startWidth!: number;
  private startHeight!: number;
  private startX!: number;
  private startY!: number;
  private originalWidth!: number;
  private originalHeight!: number;
  private fullscreen = false;
  private fullscreenIcon?: HTMLElement;
  private resizer?: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.setStyle(this.el.nativeElement, 'max-height', window.innerHeight + 'px');
    const card = this.el.nativeElement.querySelector('.card');
    this.renderer.setStyle(card, 'max-height', window.innerHeight + 'px');
    // Create resizer handle
    this.resizer = this.renderer.createElement('mat-icon');
    this.renderer.setStyle(this.resizer, 'position', 'absolute');
    this.renderer.setStyle(this.resizer, 'right', '0');
    this.renderer.setStyle(this.resizer, 'bottom', '0');
    this.renderer.setStyle(this.resizer, 'cursor', 'se-resize');
    this.renderer.setStyle(this.resizer, 'color', '#888');
    this.renderer.setStyle(this.resizer, 'font-size', 'large');
    this.renderer.addClass(this.resizer, 'material-icons');
    this.renderer.addClass(this.resizer, 'diagonal-hidden');
    this.renderer.setProperty(this.resizer, 'innerHTML', 'texture');
    this.renderer.appendChild(this.el.nativeElement, this.resizer);

    // Create full screen icon
    this.fullscreenIcon = this.renderer.createElement('mat-icon');
    this.renderer.setAttribute(this.fullscreenIcon, 'aria-label', 'Toggle Full Screen');
    this.renderer.setStyle(this.fullscreenIcon, 'position', 'absolute');
    this.renderer.setStyle(this.fullscreenIcon, 'top', '0');
    this.renderer.setStyle(this.fullscreenIcon, 'right', '0');
    this.renderer.setStyle(this.fullscreenIcon, 'cursor', 'pointer');
    this.renderer.setStyle(this.fullscreenIcon, 'color', '#888'); // Gray color
    this.renderer.setStyle(this.fullscreenIcon, 'font-size', 'large');
    this.renderer.addClass(this.fullscreenIcon, 'material-icons');
    this.renderer.setProperty(this.fullscreenIcon, 'innerHTML', 'zoom_out_map'); // Initial icon
    this.renderer.listen(this.fullscreenIcon, 'click', () => this.toggleFullScreen());
    this.renderer.appendChild(this.el.nativeElement, this.fullscreenIcon);

    // Make sure the inner content is flexible
    this.renderer.setStyle(this.el.nativeElement, 'display', 'flex');
    this.renderer.setStyle(this.el.nativeElement, 'flexDirection', 'column');
    this.renderer.setStyle(this.el.nativeElement, 'height', '100%');

    // Store original dimensions
    this.originalWidth = this.el.nativeElement.clientWidth;
    this.originalHeight = this.el.nativeElement.clientHeight;
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (event.target === this.resizer) {
      this.initDrag(event);
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.draggingCorner) {
      const width = this.startWidth + (event.clientX - this.startX);
      const height = this.startHeight + (event.clientY - this.startY);
      if (this.fullscreen) this.toggleFullScreen();
      this.resizeTo(width, height);
    }
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    if (this.draggingCorner) {
      this.draggingCorner = false;
    }
  }

  private initDrag(event: MouseEvent) {
    this.draggingCorner = true;
    this.startWidth = this.el.nativeElement.clientWidth;
    this.startHeight = this.el.nativeElement.clientHeight;
    this.startX = event.clientX;
    this.startY = event.clientY;
    event.preventDefault();
  }

  private resizeTo(width: number, height: number) {

    if (width > this.resizableMinWidth) {
      this.renderer.setStyle(this.el.nativeElement, 'width', `${width}px`);
    }
    if (height > this.resizableMinHeight) {
      this.renderer.setStyle(this.el.nativeElement, 'height', `${height}px`);
    }
  }

  private toggleFullScreen() {
    const elem = this.el.nativeElement as HTMLElement;

    if (!this.fullscreen) {
      this.originalWidth = elem.clientWidth; // Store current width before full screen
      this.originalHeight = elem.clientHeight; // Store current height before full screen

      const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

      // Enter full screen
      this.renderer.setStyle(elem, 'position', 'fixed');
      this.renderer.setStyle(elem, 'top', '0');
      this.renderer.setStyle(elem, 'left', '0');
      this.renderer.setStyle(elem, 'width', `${viewportWidth}px`);
      this.renderer.setStyle(elem, 'height', `${viewportHeight}px`);

      this.renderer.setStyle(this.fullscreenIcon, 'color', '#888'); // Gray color
      this.renderer.setProperty(this.fullscreenIcon, 'innerHTML', 'zoom_in_map'); // Change to 'close_fullscreen' icon
    } else {
      // Exit full screen
      // Restore original dimensions
      this.renderer.setStyle(elem, 'position', '');
      this.renderer.setStyle(elem, 'top', '');
      this.renderer.setStyle(elem, 'left', '');
      this.renderer.setStyle(elem, 'width', `${this.originalWidth}px`);
      this.renderer.setStyle(elem, 'height', `${this.originalHeight}px`);

      this.renderer.setStyle(this.fullscreenIcon, 'color', '#888'); // Gray color
      this.renderer.setProperty(this.fullscreenIcon, 'innerHTML', 'zoom_out_map'); // Change back to 'open_in_full' icon
    }

    this.fullscreen = !this.fullscreen;
  }
}
