import { ActionPendingCatalog, Catalog } from "src/app/common/enumerations/enumerations";
import { AcceleratorCatalog } from "../accelerator-catalog/services/accelerator-catalog";
import { Role } from "../roles/services/role";
import { UserRoleNote } from "../../../services/user-roles/user-role-note";
import { UserCatalog } from "../user-catalog/services/user";
import { NotificationTemplate } from "../notifications/services/notification-template";
import { BeamlineShutterRelation, ResourceSummary } from "../beamline-catalog/resource/resources";

export class CatalogModel {
  public userCatalog?: UserCatalog;
  public role?: Role;
  public acceleratorCatalog?: AcceleratorCatalog;
  public resourceSummary?: ResourceSummary;
  public notificationTemplate?: NotificationTemplate;
  public action?: ActionPendingCatalog;
  public userRoleNote?: UserRoleNote;
}

export class CatalogModelList {
  public userCatalogs?: UserCatalog[];
  public roles?: Role[];
  public acceleratorCatalogs?: AcceleratorCatalog[];
  public beamlineShutterRelations?: BeamlineShutterRelation[];
  public notificationTemplates?: NotificationTemplate[];
}

export interface ModifyingResult {
  isModifying: boolean;
  userID: number | null;
  catalogs: Catalog[] | null;
}

export interface Tuple {
  item1: any;
  item2: any;
}
