import { UserRole } from "../../../../services/user-roles/user-role";
import { APLGroupRoleRoleCatalog } from "../../../apl/services/apl-group-role-role-catalog.model";
import { ProcedureTraining } from "../../../procedure/models/procedure-training.model";
import { User } from "../../user-catalog/services/user";
import RoleALSPCDesignee from "./role-alspc-designee.model";
import RoleALSPCSupervisor from "./role-alspc-supervisor.model";
import { RolePrivilege, RolePrivilegePending } from "./role-privilege";
import { ModelBase } from "src/app/common/models/model-base";
import { RoleScheduleReminder } from "src/app/components/schedules/models/role-schedule-reminder";
import { RoleScheduleStatus } from "src/app/components/schedules/models/role-schedule-status";

export class Role {
  public id!: number;
  public name?: string;
  public code?: string;
  public status?: number;
  public color?: number;
  public userRole?: UserRole[];
  public performingWork?: boolean;
  public information?: string;
  public trainingRole?: boolean;
  public roleALSPCSupervisors?: RoleALSPCSupervisor[];
  public roleALSPCDesignees?: RoleALSPCDesignee[];
  public aplGroupRole?: APLGroupRoleRoleCatalog;
  public procedureTrainings?: ProcedureTraining[];
  public rolePrivileges?: RolePrivilege[];
  public rolePrivilegesPending?: RolePrivilegePending[];
  public createdBy?: number;
  public createdOn?: Date;
  public createdByUser?: User;
  public approvedByID?: number;
  public approvedBy?: User;
  public approvedDate?: Date;
  public updatedBy?: number;
  public updatedByUser?: User;
  public updatedOn?: Date;
  public relatedLinks?: string;

  constructor() {
    this.roleALSPCSupervisors = [];
    this.roleALSPCDesignees = [];
    this.aplGroupRole = this.aplGroupRole ?? new APLGroupRoleRoleCatalog();
  }
}

export interface IRole extends ModelBase {
  userRole: UserRole[];
  rolePrivileges: RolePrivilege[];
  roleReminders: RoleScheduleReminder[];
  roleScheduleStatus: RoleScheduleStatus[];
}
