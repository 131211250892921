import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AbsiResource } from './absi';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { LoadingOrigin } from 'src/app/common/enumerations/enumerations';
import { CatlReportComponent } from 'src/app/controls/catl/catl-report/catl-report.component';
import { CommentsSummaryComponent } from 'src/app/controls/comments-summary/comments-summary.component';
import { Observable, Subscription } from 'rxjs';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';

@Component({
  selector: 'absi',
  templateUrl: './absi.component.html',
  styleUrls: ['./absi.component.scss', '../../../quill-helper.scss']
})
export class AbsiComponent extends BaseComponent implements OnInit, AfterViewInit {

  absi?: AbsiResource;

  @ViewChild("sidenav") sidenav!: MatSidenav;
  @ViewChild("headerTop") headerTop!: ElementRef;

  tmpEndstations!: string;

  disabled: boolean = false;

  loading = false;

  resourceRequired: boolean = false;

  filterCtrl = new FormControl();

  errorMessages: string[] = [];
  headerOnTop: boolean = false;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter: any[] = [];
  currentFilter: any;
  filter: any;

  // absiUnchanged?: Absi | null;
  absis: AbsiResource[] = [];
  absis$!: Observable<AbsiResource[]>;
  absisSubs: Subscription = new Subscription;

  loadingMessage!: string;

  showAmendmentToggle: boolean = false;
  //////

  ngAfterViewInit(): void {
    this.setHeights();
  }

  ngOnInit() {
    this.moduleTitle = 'ABSI Checklists';
    this.loadAbsis();
  }

  loadAbsis() {
    this.absis$ = this.store.select(state => state.Absi.data);
    this.absisSubs = this.absis$.subscribe(data => {
      if (data?.length) {
        this.absis = data;
        if (this.absi) {
          const absi = this.absis.find(a => a.id == this.absi?.id);
          if (absi?.status != this.absi.status)
            this.alert.message('formStateChanged', [new MessagePlaceholder('{serialNo}', this.absi?.serialNo), new MessagePlaceholder('{status}', absi?.formStatus?.name)]);
          this.absi = absi;
        }
      }
    });
  }

  override ngOnDestroy(): void {
    this.absisSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  clear() {
    this.errorMessages = [];
  }

  @HostListener("window:resize")
  onResize() {
    this.innerWidth = window.innerWidth;
    // if (this.isExpanded != false) {

    // }
    if (this.innerWidth < 768) {
      this.isExpanded = false;
    }
  }

  @HostListener("window:scroll")
  isHeaderOnTop() {
    let elemRec;
    if (this.headerTop?.nativeElement !== undefined) {
      elemRec = this.headerTop.nativeElement.getBoundingClientRect();
      const docViewTop = window.screenTop;
      const elemTop = elemRec.top;
      if (elemTop <= docViewTop) {
        this.headerOnTop = true;
      } else {
        this.headerOnTop = false;
      }
    } else {
      this.headerOnTop = false;
    }
  }


  applyFilter(e: any) {
    if (!e)
      this.valueFilter = '';
    this.filter = e;
  }

  rowSelected(e: AbsiResource) {
    this.loading = true;
    setTimeout(() => {
      if (this.absi?.id != e.id) {
        this.setHeights();
        this.scrollToTop('sidenav-content');

      }
      this.absi = e;
    }, 100);
  }

  buttonsVisible: boolean | null = false;

  catlReport() {
    const report = this.dialog.open(CatlReportComponent, {
      width: '80vw',
      data: this.absi
    });
    report.afterClosed().subscribe((data) => {
      if (data) {
      }
    });
  }

  commentsSummary() {
    const report = this.dialog.open(CommentsSummaryComponent, {
      height: '85vh',
      width: '80vw',
      minHeight: '680px',
      data: this.absi
    });
    report.afterClosed().subscribe((data) => {
    });
  }

  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 2000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Buttons:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Main:
          this.loadingMain = e as boolean;
          break;
        case LoadingOrigin.Self:
          this.loadingMain = e as boolean;
          break;

      }
      console.log('main: ' + this.loadingMain + '   buttons: ' + this.loadingButtons + '   side: ' + this.loadingSide + '   self: ' + this.loadingSelf);
      this.loading = this.loadingMain || this.loadingButtons || this.loadingSide || this.loadingSelf;
    }, 100);
  }

  loadingSide = false;
  loadingButtons = false;
  loadingMain = false;
  loadingSelf = false;
  loadingOrigin = LoadingOrigin;

}
