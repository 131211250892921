<form [formGroup]="formGroup">
  <mat-form-field appearance="outline" class="no-subscript">
    <mat-label>Rad Monitors</mat-label>
    <mat-chip-grid #chipList aria-label="Rad monitors">
      <mat-chip-row
        *ngFor="let radMonitor of radMonitorsList"
        [selectable]="!disabled"
        [removable]="!disabled"
        (removed)="remove(radMonitor)"
      >
        <span>{{ radMonitor.ehs }}</span>
        <mat-icon matChipRemove *ngIf="!disabled" class="d-print-none"
          >cancel</mat-icon
        >
      </mat-chip-row>

      <input
        matInput
        aria-label="relatedforms"
        #radMonitorInput
        [matAutocomplete]="autoRadm"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event)"
        formControlName="radMonitorsCtrl"
        (keyup)="onChange($event)"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #autoRadm="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option
        *ngFor="let radMonitor of radMonitorsFiltered"
        [value]="radMonitor"
        class="option"
      >
        <span class="blue" [innerHTML]="radMonitor.ehs"></span>
        <span
          class="small description ml-1"
          matTooltipPosition="right"
          matTooltipClass="tooltip-white tooltip-wide"
          [innerHTML]="
            radMonitor?.radLocation?.name +
            '/' +
            radMonitor.radLocation?.radLocationType?.name
          "
        >
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
