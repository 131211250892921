import { Component, HostListener, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Servers } from 'src/app/common/enumerations/servers-enum';

@Component({
  selector: 'head-page',
  templateUrl: './head-page.component.html',
  styleUrls: ['./head-page.component.scss']
})
export class HeadPageComponent extends BaseComponent {
  environmentLabel = 'local';
  showMenu = false;
  showBerkeley = true;

  constructor(
    protected override injector: Injector
  ) {
    super(injector);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; }; }) {
    this.innerWidth = event.target.innerWidth;
    this.updatePixelRatio();
  }

  updatePixelRatio() {
    const pr = window.devicePixelRatio;
    if (pr > 1.25) {
      this.showBerkeley = false;
    } else {
      if (this.innerWidth < 1480) {
        this.showBerkeley = false;
      } else {
        this.showBerkeley = true;
      }
    }
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;

    if (document.location.hostname === Servers.PRODUCTION) {
      this.environmentLabel = 'prod';
    } else {
      if (document.location.host === Servers.DEVELOPMENT) {
        this.environmentLabel = 'dev';
      } else if (document.location.host === Servers.STAGING) {
        this.environmentLabel = 'test';
      } else if (document.location.host === Servers.PREPROD) {
        this.environmentLabel = 'prep';
      }
    }
  }

}
