import { Component, OnInit, ViewChild } from '@angular/core';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { RoleDetailsComponent } from './role-details/role-details.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { CanComponentDeactivate } from 'src/app/common/guards/unsaved-changes.guard';


@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent extends BaseComponent implements OnInit, CanComponentDeactivate {

  loading!: boolean;
  role!: Role;

  @ViewChild(RoleDetailsComponent) roleDetails!: RoleDetailsComponent;

  ngOnInit() {
    this.moduleTitle = 'Roles Catalog';
  }

  clear() {
    this.roleDetails.clear();
  }

  applyFilter(e: any) { }

}
