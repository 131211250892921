<div class="card">
	<div class="card-header">
		<span class="card-title">{{ addRelatedDocumentOrLink }}</span>
	</div>
	<div class="card-body">
		<mat-form-field>
			<mat-label>Document name or Hyperlink</mat-label>
			<textarea
				required
				matInput
				[(ngModel)]="data.obj.Text"
				[formControl]="Text"
				rows="5"
				name="hyperlink"
			></textarea>
			<mat-error>{{ youMustEnterAValidaValue }}</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>URL or Web Address</mat-label>
			<textarea
				matInput
				[(ngModel)]="data.obj.URL"
				[formControl]="URL"
				rows="5"
				name="webAddress"
			></textarea>
			<mat-error>{{ youMustEnterAValidaValue }}</mat-error>
		</mat-form-field>
	</div>
	<div class="card-footer">
		<div mat-dialog-actions align="center">
			<button mat-flat-button color="warn" (click)="onNoClick()">
				Cancel
			</button>
			<button
				mat-flat-button
				color="primary"
				[disabled]="Text.invalid || URL.invalid"
				(click)="Add()"
			>
				Accept
			</button>
		</div>
	</div>
</div>
