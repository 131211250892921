import { PendingCatalogs } from "src/app/services/pending-approvals/pending-approval";


export class PendingCatalogsRefresh {
  static readonly type = '[PendingCatalogs] Refresh';
}
// export class PendingCatalogsCreate {
//   static readonly type = '[PendingCatalogs] Create';
//   constructor(public item: PendingCatalogs) { }
// }
// export class PendingCatalogsUpdate {
//   static readonly type = '[PendingCatalogs] Update';
//   constructor(public id: number, public item: PendingCatalogs) { }
// }
// export class PendingCatalogsDelete {
//   static readonly type = '[PendingCatalogs] Delete';
//   constructor(public id: number) { }
// }
