<div id="section1">
  <form [formGroup]="formGroup" (keydown.enter)="$event.preventDefault()">
    <div class="row">
      <div class="col-8">
        <h5 id="section1Title">Initiation</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <mat-form-field
          [ngClass]="{
            hilite:
              formVersion &&
              showAmendment &&
              formVersion.requesterID !=
                formVersion.previousVersion?.requesterID
          }"
        >
          <mat-label>Requester</mat-label>
          <input
            matInput
            placeholder="Requester"
            aria-label="Requester"
            [matAutocomplete]="reqAuto"
            formControlName="requesterCtrl"
            name="Requester"
            (keyup)="onReqChange($event)"
            #trigger="matAutocompleteTrigger"
          />
          <mat-autocomplete
            #reqAuto="matAutocomplete"
            [displayWith]="displayObject"
            (optionSelected)="selectedReq($event)"
          >
            <mat-option [value]="user" *ngFor="let user of usersFiltered">{{
              user.name
            }}</mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="requesterCtrl?.invalid">{{
            getErrorMsg(requesterCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-4">
        <related-documents
          [relatedDocuments]="formVersion?.eB2?.relatedDocuments"
          [disabled]="disabled"
          [formID]="formVersion?.eB2?.id"
          [formType]="formTypeEnum.EBF"
          [disabled]="disabled"
          (changed)="relatedDocumentsChanged($event)"
          [hilite]="diffRelatedDocuments()"
        ></related-documents>
      </div>
      <div class="col-12 col-md-4">
        <related-link-chips
          placeholder="Related Links"
          [disabled]="disabled"
          [formId]="formVersion?.eB2?.id"
          (onEdited)="setFormDirty()"
          [relatedLinks]="formVersion?.eB2?.eB2RelatedLinks"
          [hilite]="diffRelatedLinks()"
        >
        </related-link-chips>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <comments-box
          [disabled]="disabled"
          formType="EB"
          [serialNo]="form?.sequenceNumber?.number"
          title="Equipment or Function for Bypass and Reason"
          [textValue]="formVersion?.eB2?.equipmentOrFunctionForBypassAndReason"
          (textChanged)="descriptionChanged($event)"
          [control]="descriptionCtrl"
          [hilite]="
            formVersion &&
            showAmendment &&
            formVersion.eB2.equipmentOrFunctionForBypassAndReason !=
              formVersion.previousVersion?.eB2
                ?.equipmentOrFunctionForBypassAndReason
          "
        ></comments-box>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <mat-checkbox
          class="d-block"
          name="beamlineCheckbox"
          formControlName="chkBeamlineCtrl"
          (change)="blCheckboxChanged($event)"
          [ngClass]="{
            hilite:
              formVersion &&
              showAmendment &&
              formVersion.eB2.beamline !=
                formVersion.previousVersion?.eB2?.beamline
          }"
        >
          Beamline
        </mat-checkbox>

        <resource-chips
          [selectedResources]="getResources(formVersion?.eB2?.eB2Shutters)"
          [resourceTypes]="[
            resourceTypeEnum.Shutter,
            resourceTypeEnum.Branchline
          ]"
          [placeholder]="'Beamline Locations'"
          [control]="shuttersCtrl"
          [disabled]="!(!disabled && chkBeamlineCtrl.value)"
          (onEdit)="onShutterEdited($event)"
          (onError)="alert.error($event)"
          [hilite]="diffShutters()"
        >
        </resource-chips>
      </div>
      <div class="col-4">
        <mat-checkbox
          class="d-block"
          name="acceleratorCheckbox"
          formControlName="chkAcceleratorCtrl"
          (change)="accCheckboxChanged($event)"
          [ngClass]="{
            hilite:
              formVersion &&
              showAmendment &&
              formVersion.eB2.accelerator !=
                formVersion.previousVersion?.eB2?.accelerator
          }"
        >
          Accelerator
        </mat-checkbox>
        <resource-chips
          [selectedResources]="
            getResources(formVersion?.eB2?.eB2AcceleratorLocations)
          "
          [resourceTypes]="[
            resourceTypeEnum.Accelerator,
            resourceTypeEnum.AcceleratorLocation
          ]"
          [placeholder]="'Accelerator Locations'"
          [control]="acceleratorCtrl"
          [disabled]="!(!disabled && chkAcceleratorCtrl.value)"
          (onEdit)="onAcceleratorEdited($event)"
          (onError)="alert.error($event)"
          [hilite]="diffAccelerators()"
        >
        </resource-chips>
      </div>
      <div class="col-4">
        <mat-checkbox class="d-block invisible" [disabled]="disabled">
          Dummy
        </mat-checkbox>
        <other-system-chips
          [placeholder]="'Other systems'"
          [disabled]="disabled"
          [control]="otherSystemCtrl"
          [formId]="formVersion?.eB2?.id"
          [formType]="formTypeEnum.EBF"
          [otherSystems]="tmpOtherSystems"
          (onEdit)="onOtherSystemsEdited($event)"
          [hilite]="diffOtherSystems()"
        >
        </other-system-chips>
      </div>
    </div>
    <div class="row" [ngClass]="{ 'mb-4': !form?.editingBy }">
      <div class="col">
        <form-restriction-table
          [formRestrictions]="formVersion?.eB2?.eB2Restrictions"
          [formID]="formVersion?.eB2?.id"
          [formType]="formTypeEnum.EBF"
          [disabled]="disabled"
          customTitle="Restrictions"
          [control]="restrictionsCtrl"
          [locationIDs]="locationIDs"
          [hilite]="diffRestrictions()"
          (changed)="restrictionsChanged($event)"
        ></form-restriction-table>
      </div>
    </div>

    <div id="section2">
      <div class="row">
        <div class="col">
          <comments-box
            [title]="getMessage('EB_Bypass_Location_And_Details').description"
            [disabled]="disabled"
            formType="EB"
            [serialNo]="form?.sequenceNumber?.number"
            [textValue]="formVersion?.eB2?.bypassDetails"
            [control]="bypassCtrl"
            (textChanged)="bypassDetailsChanged($event)"
            [hilite]="
              formVersion &&
              showAmendment &&
              formVersion.eB2.bypassDetails !=
                formVersion.previousVersion?.eB2?.bypassDetails
            "
          ></comments-box>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <comments-box
            [title]="getMessage('EB_Conditions_And_Limitations').description"
            [disabled]="disabled"
            formType="EB"
            [serialNo]="form?.sequenceNumber?.number"
            [textValue]="formVersion?.eB2?.conditionsAndLimitations"
            [control]="conditionsCtrl"
            (textChanged)="conditionsAndlimitationsChanged($event)"
            [hilite]="
              formVersion &&
              showAmendment &&
              formVersion.eB2.conditionsAndLimitations !=
                formVersion.previousVersion?.eB2?.conditionsAndLimitations
            "
          ></comments-box>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <comments-box
            [title]="getMessage('EB_System_And_Print_Numbers').description"
            [disabled]="disabled"
            formType="EB"
            [serialNo]="form?.sequenceNumber?.number"
            [textValue]="formVersion?.eB2?.systemAndPrintNumbers"
            [control]="systemCtrl"
            (textChanged)="systemAndPrintChanged($event)"
            [hilite]="
              formVersion &&
              showAmendment &&
              formVersion.eB2.systemAndPrintNumbers !=
                formVersion.previousVersion?.eB2?.systemAndPrintNumbers
            "
          ></comments-box>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">
          <mat-form-field
            [ngClass]="{
              hilite:
                formVersion &&
                showAmendment &&
                formVersion.eB2.permittedStartDate !=
                  formVersion.previousVersion?.eB2?.permittedStartDate
            }"
          >
            <mat-label
              >{{ getMessage("EB_Permitted_Start_Date").description }}
            </mat-label>
            <input
              matInput
              [matDatepicker]="permittedStartDatePicker"
              formControlName="startDateCtrl"
              (dateChange)="startDateChanged($event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="permittedStartDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #permittedStartDatePicker></mat-datepicker>
            <mat-error *ngIf="startDateCtrl?.invalid">{{
              getErrorMsg(startDateCtrl)
            }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-4">
          <mat-form-field
            [ngClass]="{
              hilite:
                formVersion &&
                showAmendment &&
                formVersion.eB2.estimatedCompletion !=
                  formVersion.previousVersion?.eB2?.estimatedCompletion
            }"
          >
            <mat-label
              >{{ getMessage("EB_Date_Of_Estimated_Completion").description }}
            </mat-label>
            <input
              matInput
              [matDatepicker]="estimatedCompletionPicker"
              name="completionDate"
              (dateChange)="completionDateChanged($event)"
              formControlName="completionDateCtrl"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="estimatedCompletionPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #estimatedCompletionPicker></mat-datepicker>
            <mat-error *ngIf="completionDateCtrl?.invalid">{{
              getErrorMsg(completionDateCtrl)
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="buttons d-print-none d-flex justify-content-between">
      <div class="d-flex justify-content-start">
        <button
          mat-flat-button
          *ngIf="cancelAvailable"
          color="warn"
          class="m-2 d-print-none"
          (click)="cancel()"
        >
          Cancel
        </button>

        <button
          mat-flat-button
          *ngIf="
            form &&
            showAmendment &&
            form?.formVersion?.statusID == formStatusEnum.Amendment &&
            hasPrivilege(privilegeEnum.EBCancel)
          "
          color="warn"
          class="m-2 d-print-none"
          (click)="cancel()"
        >
          Cancel Amendment
        </button>

        <button
          mat-flat-button
          *ngIf="
            form &&
            form?.editingById == currentUser?.id &&
            ((form?.formVersion?.statusID ?? 0) == formStatusEnum.New ||
              form?.formVersion?.statusID == formStatusEnum.Draft)
          "
          color="warn"
          class="m-2 d-print-none"
          (click)="cancelDraft()"
        >
          Cancel
        </button>
      </div>

      <div class="d-flex justify-content-end">
        <button
          mat-stroked-button
          color="primary"
          class="m-2 d-print-none"
          *ngIf="form?.formVersion?.statusID == formStatusEnum.Draft"
          (click)="service.editOlog(form)"
        >
          Edit Olog
        </button>
        <button
          [disabled]="formGroup.invalid"
          mat-flat-button
          class="m-2 d-print-none"
          color="primary"
          [disabled]="!form"
          *ngIf="
            form?.formVersion?.statusID == formStatusEnum.Draft &&
            hasRoles([roleEnum.OIC])
          "
          (click)="submit()"
        >
          Submit
        </button>
        <button
          mat-flat-button
          *ngIf="
            showAmendment &&
            hasPrivilege(privilegeEnum.EBEditAmendment) &&
            form?.formVersion?.statusID == formStatusEnum.Amendment
          "
          color="primary"
          type="submit"
          (click)="submitAmendment()"
          class="m-2 d-print-none"
        >
          Submit
        </button>
        <button
          mat-flat-button
          [disabled]="formGroup.disabled"
          *ngIf="
            (form &&
              (form?.formVersion?.statusID ?? 0) == formStatusEnum.New) ||
            form?.formVersion?.statusID == formStatusEnum.Draft ||
            (form?.formVersion?.statusID == formStatusEnum.Amendment &&
              showAmendment)
          "
          color="accent"
          (click)="save()"
          class="m-2 d-print-none"
        >
          {{
            form?.formVersion?.statusID == formStatusEnum.Amendment
              ? "Update Amendment"
              : (form?.id ?? -1) <= 0
              ? "Save Draft"
              : "Update Draft"
          }}
        </button>

        <button
          *ngIf="
            canUnsubmit &&
            (form?.formVersion?.createdBy == currentUser?.id ||
              signature?.signedBy?.id == this.currentUser?.id ||
              isSA()) &&
            form?.formVersion?.statusID == formStatusEnum.WorkApprovals
          "
          class="m-2 d-print-none"
          mat-flat-button
          color="warn"
          (click)="unsubmit()"
        >
          Unsubmit
        </button>

        <button
          *ngIf="
            formVersion?.createdBy == currentUser?.id &&
            form?.formVersion?.statusID ==
              formStatusEnum.RestrictionsAmendment &&
            showAmendment &&
            hasPrivilege(privilegeEnum.EBRequestAmendment)
          "
          class="m-2 d-print-none"
          mat-flat-button
          color="warn"
          (click)="unsubmitAmendment()"
        >
          Unsubmit Amendment
        </button>
      </div>
    </div>
  </form>
</div>
