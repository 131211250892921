import { ProcedureTemplateMaster } from "../../models/procedure-template-master.model";

export class ProcedureTemplateRefresh {
  static readonly type = '[ProcedureTemplate] Refresh';
}

export class ProcedureTemplateCreate {
  static readonly type = '[ProcedureTemplate] Create';

  constructor(public item: ProcedureTemplateMaster) { }
}

export class ProcedureTemplateUpdate {
  static readonly type = '[ProcedureTemplate] Update';

  constructor(public id: number, public item: ProcedureTemplateMaster) { }
}

export class ProcedureTemplateDelete {
  static readonly type = '[ProcedureTemplate] Delete';

  constructor(public id: number) { }
}
