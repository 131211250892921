import { NavigationLink } from "src/app/services/navigation-links/navigation-link";

export class NavigationLinkRefresh {
  static readonly type = '[NavigationLink] Refresh';
}

export class NavigationLinkCreate {
  static readonly type = '[NavigationLink] Create';
  constructor(public item: NavigationLink) { }
}

export class NavigationLinkUpdate {
  static readonly type = '[NavigationLink] Update';
  constructor(public id: number, public item: NavigationLink) { }
}

export class NavigationLinkDelete {
  static readonly type = '[NavigationLink] Delete';
  constructor(public id: number) { }
}
