import { ScheduleSubtype } from "../../models/schedule-subtype";


export class ScheduleSubtypesRefresh {
  static readonly type = '[ScheduleSubtypes] Refresh';
}

export class ScheduleSubtypesCreate {
  static readonly type = '[ScheduleSubtypes] Create';

  constructor(public item: ScheduleSubtype) { }
}

export class ScheduleSubtypesUpdate {
  static readonly type = '[ScheduleSubtypes] Update';

  constructor(public id: number, public item: ScheduleSubtype) { }
}

export class ScheduleSubtypesDelete {
  static readonly type = '[ScheduleSubtypes] Delete';
  constructor(public id: number) { }
}
