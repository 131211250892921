import { Component, Injector, InjectorType, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { OlogMessage } from '../../catalogs/document-type/services/olog-message';
import { BaseComponent } from 'src/app/common/base/base.component';
import { OLogService } from '../olog.service';

@Component({
  selector: 'olog-button',
  templateUrl: './olog-button.component.html',
  styleUrls: ['./olog-button.component.scss'],

})
export class OlogButtonComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() disabled?: boolean;
  @Input() isExpandedOrShowing?: boolean;
  @Input() documentTypeID!: number;
  @Input() documentID!: number;

  ologMessages?: OlogMessage[];
  ologMessagesFiltered?: OlogMessage[];
  ologMessages$!: Observable<OlogMessage[]>;
  ologMessagesSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    public service: OLogService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loadOlogMessages();
  }

  loadOlogMessages() {
    this.ologMessages$ = this.store.select(state => state.OlogMessage.data);
    this.ologMessagesSubs = this.ologMessages$.subscribe(data => {
      if (data?.length) {
        this.ologMessages = data.filter(m => m.documentTypeID == this.documentTypeID);
      }
    });
  }

  override ngOnDestroy(): void {
    this.ologMessagesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  openOlogMessage(ologMessage: OlogMessage) {
    this.service.editOlog(this.documentTypeID, this.documentID, ologMessage.notificationType, this.disabled);
  }

}
