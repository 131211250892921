<div class="side-panel-table">
  <div class="left-table-container table-container mat-container scrollbar">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="pl-16">
          Name
        </mat-header-cell>
        <mat-cell
          class="font-weight-bold pl-16"
          *matCellDef="let element"
          [ngClass]="element.type == 1 ? 'blue' : 'green'"
          nowrap
          ><span
            [innerHTML]="
              element.displayName +
                (element.displayName != element.name
                  ? ' (' + element.name + ')'
                  : '') | highlightSearch : filter
            "
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="min-130">
          Type
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          <span
            [innerHTML]="element.resourceType.name | highlightSearch : filter"
            [ngClass]="element.type == 1 ? 'blue' : 'green'"
          ></span>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        #matrow
        *matRowDef="let row; columns: displayedColumns"
        (click)="rowClicked(row)"
        class="table-row"
        [matTooltip]="row.description"
        [matTooltipPosition]="'right'"
        matTooltipClass="tooltip-white tooltip-wide"
        [attr.position]="getPosition(row)"
      ></mat-row>
    </mat-table>
  </div>
</div>
