import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { BeamlineOverview } from './BeamlineOverview';
// import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BeamlineOverview } from './beamline-overview';

@Injectable({
  providedIn: 'root'
})
export class BeamlineOverviewService {

  constructor(private http: HttpClient) { }


  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  GetBeamlineOverviewById(id: number): Observable<BeamlineOverview> {
    const result = this.http.get(environment.urls.baseApiUrl + '/BeamLineOverview/' + id)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

}
