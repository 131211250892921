import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { OlogMessageCreate, OlogMessageDelete, OlogMessageRefresh, OlogMessageUpdate } from './olog-message.action';
import { utils } from 'src/app/modules/libs/utils';
import { OlogMessage } from '../../services/olog-message';
import { OlogMessageService } from '../../services/olog-message.service';

export class OlogMessageStateModel {
  data: OlogMessage[] = [];
}

@State<OlogMessageStateModel>({
  name: 'OlogMessage',
  defaults: {
    data: []
  }
})
@Injectable()
export class OlogMessageState {
  constructor(
    private service: OlogMessageService
  ) { }

  @Selector()
  static read(state: OlogMessageStateModel) {
    return state.data;
  }

  @Action(OlogMessageRefresh)
  refresh({ patchState }: StateContext<OlogMessageStateModel>) {
    this.service.getAll().toPromise().then(data => {
      patchState({ data: utils.cloneDeep(data) });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(OlogMessageCreate)
  create({ getState, patchState }: StateContext<OlogMessageStateModel>, { item }: OlogMessageCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(OlogMessageUpdate)
  update({ getState, patchState }: StateContext<OlogMessageStateModel>, { id, item }: OlogMessageUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    const currentState = utils.cloneDeep(state.data);

    if (index >= 0) {
      currentState.splice(index, 1, utils.cloneDeep(item));
      patchState({ data: currentState });
    }
  }

  @Action(OlogMessageDelete)
  delete({ getState, patchState }: StateContext<OlogMessageStateModel>, { id }: OlogMessageDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
