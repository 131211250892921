import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Note } from 'src/app/services/notes/note.model';

@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent extends BaseComponent {

  @Input() notes?: Note[];
  @Input() canDelete = false;
  @Input() type?: number;
  @Output() delete = new EventEmitter<Note>();

  noteTypeText = {
    1: 'Note',
    2: 'Extension Request',
    3: 'Extension Rejected'
  };

}
