<div class="card">
	<div class="card-header">
		<span class="card-title">Manage Categories</span>
	</div>
	<div class="card-body">
		<div class="row" style="margin-bottom: 10px;">
			<div class="col">
				<div style="max-height: 50vh;" class="mat-container scrollbar style-15">
					<mat-table [dataSource]="dataSource">
						<ng-container matColumnDef="name">
							<mat-header-cell *matHeaderCellDef>
								<span>Category Name</span>
							</mat-header-cell>
							<mat-cell *matCellDef="let e">
								<span *ngIf="!e.editing">{{e.name}}</span>
								<mat-form-field *ngIf="e.editing">
									<mat-label>Category Name</mat-label>
									<input
										matInput
										style="border-bottom: 1px solid #b0b0b0; width: 90%;"
										[(ngModel)]="e.preName"
									/>
								</mat-form-field>
							</mat-cell>
						</ng-container>

						<ng-container matColumnDef="type">
							<mat-header-cell *matHeaderCellDef>
								<span>Type</span>
							</mat-header-cell>
							<mat-cell *matCellDef="let e">
								<span *ngIf="!e.editing">{{getProcedureTypeName(e)}}</span>
								<mat-form-field appearance="outline" *ngIf="e.editing">
									<mat-label>Type</mat-label>
									<mat-select
										matNativeControl
										name="procedureType"
										[(ngModel)]="e.procedureType"
									>
										<mat-option [value]="1">Procedure</mat-option>
										<mat-option [value]="4">KE Checklist</mat-option>
										<mat-option [value]="2">RSS Test</mat-option>
										<mat-option [value]="3">EPS Test</mat-option>
										<mat-option [value]="5">EPS Drawings</mat-option>
										<mat-option [value]="6">Rad Survey</mat-option>
									</mat-select>
								</mat-form-field>
							</mat-cell>
						</ng-container>

						<ng-container matColumnDef="clear">
							<mat-header-cell *matHeaderCellDef></mat-header-cell>
							<mat-cell *matCellDef="let e">
								<button
									mat-icon-button
									color="warn"
									*ngIf="e.editing"
									(click)="discard(e)"
								>
									<mat-icon>clear</mat-icon>
								</button>
							</mat-cell>
						</ng-container>

						<ng-container matColumnDef="edit_save">
							<mat-header-cell *matHeaderCellDef></mat-header-cell>
							<mat-cell *matCellDef="let e">
								<button
									mat-icon-button
									color="primary"
									*ngIf="!e.editing"
									(click)="startEditing(e)"
								>
									<mat-icon>edit</mat-icon>
								</button>
								<button
									mat-icon-button
									class="green"
									*ngIf="e.editing"
									(click)="update(e)"
								>
									<mat-icon>save</mat-icon>
								</button>
							</mat-cell>
						</ng-container>

						<ng-container matColumnDef="delete">
							<mat-header-cell *matHeaderCellDef></mat-header-cell>
							<mat-cell *matCellDef="let e">
								<button mat-icon-button color="warn" (click)="delete(e.id)">
									<mat-icon>delete</mat-icon>
								</button>
							</mat-cell>
						</ng-container>

						<mat-header-row
							*matHeaderRowDef="columns; sticky: true;"
						></mat-header-row>
						<mat-row
							*matRowDef="let row; columns: columns;"
							class="py-0"
						></mat-row>
					</mat-table>
				</div>
			</div>
		</div>
	</div>

	<div class="card-footer">
		<div class="row" style="margin-bottom: 0px;">
			<div class="col">
				<mat-form-field>
					<mat-label>Category Name</mat-label>
					<input matInput #NameInput />
				</mat-form-field>
			</div>
			<div class="col">
				<mat-form-field appearance="outline">
					<mat-label>Type</mat-label>
					<mat-select
						#procedureTypeSelect
						matNativeControl
						name="procedureTypeSelect"
					>
						<mat-option [value]="1">Procedure</mat-option>
						<mat-option [value]="4">KE Checklist</mat-option>
						<mat-option [value]="2">RSS Test</mat-option>
						<mat-option [value]="3">EPS Test</mat-option>
						<mat-option [value]="5">EPS Drawings</mat-option>
						<mat-option [value]="6">Rad Survey</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-auto" style="margin-top: 5px; padding-left: 0px;">
				<button
					mat-mini-fab
					color="primary"
					(click)="create(NameInput.value, procedureTypeSelect.value)"
				>
					<mat-icon>add</mat-icon>
				</button>
			</div>
		</div>
	</div>
</div>
