import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Roles } from 'src/app/common/enumerations/enumerations';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { Placeholder } from 'src/app/components/checklists/checklists';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { NavigationLink } from 'src/app/services/navigation-links/navigation-link';
import { NavigationLinkService } from 'src/app/services/navigation-links/navigation-link.service';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';


@Component({
  selector: 'navigation-links-details',
  templateUrl: './navigation-links-details.component.html',
  styleUrls: ['./navigation-links-details.component.scss']
})
export class NavigationLinksDetailsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() navigationLink?: NavigationLink;


  navigationLinks?: NavigationLink[];
  navigationLinksFiltered?: NavigationLink[];
  navigationLinks$!: Observable<NavigationLink[]>;
  navigationLinksSubs!: Subscription;
  modifyAvailable?: boolean;
  originalNavigationLink?: NavigationLink;

  modifying!: boolean;
  adding = false;
  disabledFields!: boolean;
  selected!: boolean;
  valid!: boolean;
  deleted = false;

  showCancelButton!: boolean;

  urlRegex = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/);

  constructor(
    protected override injector: Injector,
    public navigationLinkService: NavigationLinkService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getDetails();
  }

  ngOnInit(): void {
    this.loadNavigationLinks();
  }

  override ngOnDestroy(): void {
    this.navigationLinksSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadNavigationLinks() {
    this.navigationLinks$ = this.store.select(state => state.NavigationLinks.data);
    this.navigationLinksSubs = this.navigationLinks$.subscribe(data => {
      if (data?.length) {
        this.navigationLinks = data;
        if (this.navigationLink) {
          const navigationLink = this.navigationLinks.find(x => x.id == this.navigationLink?.id);
          this.navigationLink = navigationLink;
        }
      }
    });
  }

  getDetails() {
    this.modifyAvailable = this.currentUser?.userRole?.some(r => r.roleID === Roles.ADM);
    if (this.navigationLink && !this.navigationLink?.id) {
      this.adding = true;
      this.modifying = false;
      this.disabledFields = false;
      this.showCancelButton = true;
    } else if (this.navigationLink && this.navigationLink.id) {
      this.disabledFields = true;
      this.modifying = false;
      this.adding = false;
      this.selected = true;
      this.showCancelButton = true;
    }
    else if (!this.navigationLink) {
      this.clear();
    }
  }

  clear() {
    this.disabledFields = true;
    this.adding = false;
    this.modifying = false;
    this.selected = false;
    this.showCancelButton = false;
    this.navigationLink = this.originalNavigationLink;
    this.setFormDirty(false);
    this.getDetails();
  }

  hyperLinkChanged(e: any) {
    if (this.navigationLink)
      this.navigationLink.hyperlink = e.target.value;
    this.setFormDirty();
  }

  webAddressChanged(e: any) {
    if (this.navigationLink)
      this.navigationLink.webAddress = e.target.value;
    this.setFormDirty();
  }

  isActiveChanged(e: any) {
    if (this.navigationLink)
      this.navigationLink.isActive = e.checked;
    this.setFormDirty();
  }

  async save() {
    if (this.validate()) {
      if (this.navigationLink && !this.navigationLink?.id) {
        this.navigationLink.order = await this.getPositionToCreate();
        await this.navigationLinkService.create(this.navigationLink).toPromise();
        this.setFormDirty(false);
        this.alert.message('create', [new MessagePlaceholder('{what}', 'Link')]);
      } else if (this.navigationLink?.id) {
        await this.navigationLinkService.update(this.navigationLink.id, this.navigationLink).toPromise();
        this.setFormDirty(false);
        this.alert.message('update', [new MessagePlaceholder('{what}', 'Link')]);
      }
    }
  }

  modify() {
    this.disabledFields = false;
    this.modifying = true;
    this.adding = false;
    this.selected = true;
    this.showCancelButton = true;
    this.originalNavigationLink = this.utils.cloneDeep(this.navigationLink);
  }

  async delete() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "500px", data: {
        message: 'Are you sure you want to Delete link to <b>' + this.navigationLink?.hyperlink + '</b>?', icon: "stop",
      },
    });
    confirm.afterClosed().subscribe(async (data) => {
      if (data) {
        if (this.navigationLink?.id)
          await this.navigationLinkService.delete(this.navigationLink.id).toPromise().then(async () => {
            this.alert.message('remove', [new MessagePlaceholder('{what}', 'Link')]);
            await this.reorderPositions();
            this.navigationLink = undefined;
            this.getDetails();
          });
      }
    });
  }

  validate(): boolean {
    if (!this.navigationLink?.hyperlink) {
      this.alert.error('Please enter an hyperlink name');
      return false;
    }
    if (this.navigationLink.hyperlink.trim() === '') {
      this.alert.error('Please enter an hyperlink name');
      return false;
    }
    if (!this.navigationLink.webAddress) {
      this.alert.error('Please enter a url');
      return false;
    }
    if (this.navigationLink.webAddress.trim() === '') {
      this.alert.error('Please enter a url');
      return false;
    }
    if (!this.urlRegex.test(this.navigationLink.webAddress)) {
      this.alert.error('Please enter a valid url');
      return false;
    }
    return true;
  }

  async getPositionToCreate() {
    return ((this.navigationLinks?.sort((a, b) => this.utils.sort(b.order, a.order)))?.[0].order ?? 0) + 1;
  }

  async reorderPositions() {
    let addPosition = 1;
    this.navigationLinks?.map(navigationLink => {
      navigationLink.order = addPosition++;
    });
    if (this.navigationLinks)
      await this.navigationLinkService.updateOrder(this.navigationLinks).toPromise();
  }

}
