import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  TasksCreate,
  TasksDelete,
  TasksRefresh,
  TasksUpdate,
  TasksUpdateAll,
} from './task.action';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { ChecklistsService } from '../../checklists.service';
import { utils } from 'src/app/modules/libs/utils';

export class TasksStateModel {
  data: WfTaskLocalResource[] = [];
}

@State<TasksStateModel>({
  name: 'WFTaskLocals',
  defaults: {
    data: [],
  },
})
@Injectable()
export class TasksState {
  utils = utils;
  constructor(private service: ChecklistsService) { }

  @Selector()
  static read(state: TasksStateModel) {
    return state.data;
  }

  @Action(TasksRefresh)
  refresh(
    { getState, patchState }: StateContext<TasksStateModel>,
    { wfStepLocalID }: TasksRefresh
  ) {
    this.service
      .getTasksByStepID(wfStepLocalID)
      .toPromise()
      .then(
        (items) => {
          if (items) {
            patchState({
              data: getState().data.filter(
                (x) => !items?.map((i) => i.id).includes(x.id)
              ),
            });
            const state = getState();
            const data = state.data.concat(items);
            patchState({
              data,
            });
          }
        },
        (error) => console.error(error)
      );
  }

  @Action(TasksCreate)
  create(
    { getState, patchState }: StateContext<TasksStateModel>,
    { item }: TasksCreate
  ) {
    const state = getState();
    const index = state.data.map((x) => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item],
    });
  }

  @Action(TasksUpdate)
  update(
    { getState, patchState }: StateContext<TasksStateModel>,
    { id, item }: TasksUpdate
  ) {
    const state = getState();
    let index = state.data.map((x) => x.id).indexOf(id);
    item.isCompleted = utils.taskComplete(item);
    let newData = [...state.data];
    if (index >= 0) {
      newData.splice(index, 1);
    }
    newData.push(item);
    const parsedTasks = utils.parseConditions(newData);
    patchState({ data: parsedTasks });
  }

  @Action(TasksUpdateAll)
  updateAll({ getState, patchState }: StateContext<TasksStateModel>, { items }: TasksUpdateAll) {

    const state = getState().data;
    const updatedTasks: WfTaskLocalResource[] = [];
    items.map(task => {
      const currentTask = state.find(t => t.id == task.id);
      if (currentTask?.required != undefined)
        task.required = currentTask?.required;
      task.wfTasks?.map(t => {
        t.order = currentTask?.wfTasks?.find(x => x.id == t.id)?.order;
      })
      if (currentTask && !utils.isEquivalent(currentTask, task) || !currentTask) {
        updatedTasks.push(task);
      }
    });
    const IDs = [...new Set(updatedTasks.map(t => t.id))];
    let data = getState().data.filter(t => !IDs.includes(t.id));
    data = this.utils.cloneDeep(data.concat(updatedTasks));
    patchState({ data });
  }

  @Action(TasksDelete)
  delete(
    { getState, patchState }: StateContext<TasksStateModel>,
    { id }: TasksDelete
  ) {
    patchState({
      data: getState().data.filter((x) => x.id !== id),
    });
  }
}
