import { CheckboxSignatureComponent } from '../../checkbox-signature/checkbox-signature.component';
import {
  Component,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";

@Component({
  selector: "cl-editor-colors",
  templateUrl: "./cl-editor-colors.component.html",
  styleUrls: ["./cl-editor-colors.component.scss"],
})
export class ClEditorColorsComponent implements AfterViewInit {
  @Output() colorChanged = new EventEmitter();
  @Input() disabled?: boolean;
  @Input() configuration?: string;

  color: any = "primary";

  colorOptions: option[] = [
    { label: "Primary", value: "primary", checked: true },
    { label: "Accent", value: "accent", checked: false },
    { label: "Warn", value: "warn", checked: false },
  ];

  constructor() { }

  ngAfterViewInit() {
    if (this.configuration) {
      this.color = JSON.parse(this.configuration).color;
    }
    this.setColor(this.color);
  }

  colorChange(e: any) {
    this.color = e.value;
    this.colorChanged.emit(this.color);
  }

  setColor(value: any) {
    this.colorOptions.map((r) => {
      r.checked = r.value == value;
    });
  }
}

class option {
  label!: string;
  value!: any;
  checked!: boolean;
}
