import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RadMonitorCreate, RadMonitorDelete, RadMonitorRefresh, RadMonitorUpdate } from './rad-monitor.action';
import { RadMonitor } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor';
import { RadMonitorService } from 'src/app/components/catalogs/rad-monitor/services/rad-monitor.service';

export class RadMonitorStateModel {
  data: RadMonitor[] = [];
}

@State<RadMonitorStateModel>({
  name: 'RadMonitor',
  defaults: {
    data: []
  }
})
@Injectable()
export class RadMonitorState {
  constructor(
    private radMonitorService: RadMonitorService
  ) { }

  @Selector()
  static read(state: RadMonitorStateModel) {
    return state.data;
  }

  @Action(RadMonitorRefresh)
  refresh({ patchState }: StateContext<RadMonitorStateModel>) {
    this.radMonitorService.GetRadMonitors().toPromise().then(data => {
      patchState({ data });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(RadMonitorCreate)
  create({ getState, patchState }: StateContext<RadMonitorStateModel>, { item }: RadMonitorCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(RadMonitorUpdate)
  update({ getState, patchState }: StateContext<RadMonitorStateModel>, { id, item }: RadMonitorUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({ data: state.data });
    }
  }

  @Action(RadMonitorDelete)
  delete({ getState, patchState }: StateContext<RadMonitorStateModel>, { id }: RadMonitorDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
