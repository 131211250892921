import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CanDeactivateResponse } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog-response';
import { PendingChangesDialogComponent } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog.component';
import { utils } from 'src/app/modules/libs/utils';


@Component({
  selector: 'app-add-rad-note',
  templateUrl: './add-rad-note.component.html',
  styleUrls: ['./add-rad-note.component.scss']
})
export class RadNoteComponent implements OnInit {

  public noteForm!: FormGroup;
  public get note(): AbstractControl | null { return this.noteForm.get('note'); }

  constructor(
    public dialogRef: MatDialogRef<RadNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: {
      noteDescription: string,
      inputLabel: string,
      dialogTitle: string
    },
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  add() {
    this.dialogRef.close(this.inputData.noteDescription);
  }

  cancel() {
    if (this.noteForm.dirty) {
      const confirmation = this.dialog.open(PendingChangesDialogComponent, {
        height: 'fit-content',
        width: '40%',
        data: {}
      });
      confirmation.afterClosed().toPromise().then((response: CanDeactivateResponse) => {
        if (response === CanDeactivateResponse.Discard) {
          this.dialogRef.close(null);
        }
      });
    } else {
      this.dialogRef.close(null);
    }
  }

  initializeForm() {
    this.noteForm = this.formBuilder.group({
      note: [{ value: '' }, [Validators.required, utils.IsWhiteSpaceReactiveForm]]
    });
  }

  getErrorMsg(control: AbstractControl | null): string {
    if (control) {
      if (control.hasError('required')) { return 'You must enter a value'; }
      if (control.hasError('hasWhiteSpace')) { return 'Enter a valid value'; }
    }
    return '';
  }

}
