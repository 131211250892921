import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BakeoutsCreate, BakeoutsDelete, BakeoutsRefresh, BakeoutsUpdate } from './bakeouts.action';
import { BakeoutService } from '../../bakeout.service';
import { Bakeout } from '../../bakeouts';


export class BakeoutsStateModel {
  data: Bakeout[] = [];
}

@State<BakeoutsStateModel>({
  name: 'Bakeouts',
  defaults: {
    data: []
  }
})
@Injectable()
export class BakeoutsState {
  constructor(
    private _service: BakeoutService
  ) { }

  @Selector()
  static read(state: BakeoutsStateModel) {
    return state.data;
  }

  @Action(BakeoutsRefresh)
  refresh({ patchState }: StateContext<BakeoutsStateModel>) {
    this._service.getAll().toPromise().then(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(BakeoutsCreate)
  create({ getState, patchState }: StateContext<BakeoutsStateModel>, { item }: BakeoutsCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(BakeoutsUpdate)
  update({ getState, patchState }: StateContext<BakeoutsStateModel>, { id, item }: BakeoutsUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(BakeoutsDelete)
  delete({ getState, patchState }: StateContext<BakeoutsStateModel>, { id }: BakeoutsDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
