<div #sections class="main-panel scrollbar">
  <ke-sectionX
    id="section1"
    #section1
    [section]="1"
    *ngIf="ke?.id"
    [ke]="ke"
    (requestRefresh)="request_refresh($event)"
    (loadingChanged)="setLoading($event)"
  ></ke-sectionX>
  <ke-sectionX
    id="section2"
    #section2
    [section]="2"
    *ngIf="ke?.id"
    [ke]="ke"
    (requestRefresh)="request_refresh($event)"
    (loadingChanged)="setLoading($event)"
  ></ke-sectionX>
  <ke-sectionX
    id="section3"
    #section3
    [section]="3"
    *ngIf="ke?.id"
    [ke]="ke"
    (requestRefresh)="request_refresh($event)"
    (loadingChanged)="setLoading($event)"
  ></ke-sectionX>
  <ke-sectionX
    id="section4"
    #section4
    [section]="4"
    *ngIf="ke?.id"
    [ke]="ke"
    (requestRefresh)="request_refresh($event)"
    (loadingChanged)="setLoading($event)"
  ></ke-sectionX>
  <ke-sectionX
    id="section5"
    #section5
    [section]="5"
    *ngIf="ke?.id"
    [ke]="ke"
    (requestRefresh)="request_refresh($event)"
    (loadingChanged)="setLoading($event)"
  ></ke-sectionX>
  <ke-sectionX
    id="section6"
    #section6
    [section]="6"
    *ngIf="ke?.id"
    [ke]="ke"
    (requestRefresh)="request_refresh($event)"
    (loadingChanged)="setLoading($event)"
  ></ke-sectionX>
  <div class="container-fluid">
    <comments-and-attachments
      [FormNumber]="'7. '"
      [headerClass]="'h5'"
      *ngIf="showComments"
      [currentDocument]="ke"
    ></comments-and-attachments>
  </div>
</div>
