import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HeadLogoAboutComponent } from '../head-logo-about/head-logo-about.component';

@Component({
  selector: 'head-logo-tooltip',
  templateUrl: './head-logo-tooltip.component.html',
  styleUrls: ['./head-logo-tooltip.component.scss']
})
export class HeadLogoTooltipComponent {
  @Input() tooltipText = 'Click me!';

  constructor(private dialog: MatDialog) { }

  openDialog(event: MouseEvent) {
    event.preventDefault();
    // event.stopPropagation(); // Prevent unwanted clicks on the background
    this.dialog.open(HeadLogoAboutComponent, {
      width: '500px',
      data: { message: 'Dialog opened from tooltip!' }
    });
  }

}
