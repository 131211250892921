<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-2">
        <h6>{{ title }}</h6>
      </div>
      <div class="col-9 pt-2">
        <div class="row">
          <div class="col-4">
            <h6
              [innerHTML]="textBefore ?? '' | placeholderHighlight : editor"
            ></h6>
          </div>
          <div class="col-5">
            <mat-form-field class="no-subscript">
              <mat-label>Location</mat-label>
              <input
                matInput
                placeholder="Location"
                [matAutocomplete]="autoLoc"
                (keyup)="filterRadLocation($event)"
                [formControl]="radLocationCtrl"
              />
              <mat-autocomplete
                #autoLoc="matAutocomplete"
                [displayWith]="displayObject"
                (optionSelected)="radLocationSelected($event.option.value)"
              >
                <mat-option
                  *ngFor="let radLocation of radLocationsFiltered"
                  [value]="radLocation"
                >
                  {{ radLocation.name }}
                  <span class="small">
                    {{ radLocation.radLocationType?.name }}</span
                  >
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="no-subscript">
              <mat-label>Outgoing Monitor</mat-label>
              <input
                matInput
                placeholder="Outgoing Monitor"
                [matAutocomplete]="autoMon"
                (keyup)="filterRadMonitor($event)"
                [formControl]="radMonitorCtrl"
              />
              <mat-autocomplete
                #autoMon="matAutocomplete"
                [displayWith]="displayObjectMon"
                (optionSelected)="radMonitorSelected($event.option.value)"
              >
                <mat-option
                  *ngFor="let radMonitor of radMonitorsFiltered"
                  [value]="radMonitor"
                >
                  {{ radMonitor.ehs }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-2" *ngIf="radMonitor">
          <div class="col-4">
            <!-- <h6 class="float-right">Changeout Reason:</h6> -->
          </div>
          <div class="col-8">
            <fieldset class="field-group">
              <legend>Changeout Reason</legend>
              <mat-radio-group
                class="d-flex flex-row"
                [formControl]="reasonCtrl"
              >
                <mat-radio-button
                  *ngFor="let changeoutReason of changeoutReasons"
                  [value]="changeoutReason.value"
                  (change)="changeoutReasonChange($event.value)"
                >
                  {{ changeoutReason.label }}
                </mat-radio-button>
              </mat-radio-group>
              <span class="small" *ngIf="changeoutReason == 'failure'">
                {{ failureMessage }}</span
              >
              <comments-box
                *ngIf="changeoutReason == 'failure'"
                class="rad-note"
                [textValue]="faultNote?.description"
                [disabled]="disabled || checked"
                [title]="'Describe Monitor Failure'"
                (textChanged)="saveFaultNote($event)"
                [serialNo]="checklist?.serialNo"
                [formType]="currentChecklist?.documentType?.code"
                [noSubscript]="true"
              ></comments-box>

              <div class="row" *ngIf="faultNote?.updatedBy">
                <div class="col-12 small">
                  Last updated by
                  <span
                    class="font-weight-bold"
                    matTooltip="{{ faultNote?.updatedBy?.name }}"
                    >{{ faultNote?.updatedBy?.initials }}</span
                  >
                  on
                  <span class="date">{{
                    faultNote?.updatedOn | date : "short"
                  }}</span>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <!-- <div class="row" *ngIf="changeoutReason == 'failure'">
          <div class="col-8 offset-4 pb-3">

          </div>
          <div class="col">
            <h6 class="float-right">Describe Monitor Failure:</h6>
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-12">
                <div class="w-100">

                </div>
              </div>
            </div>

          </div>
        </div> -->
      </div>
      <div class="col-2 d-flex flex-row justify-content-end">
        <div class="d-flex flex-column align-items-center">
          <mat-checkbox-with-roles
            [disabled]="checkboxDisabled()"
            (change)="checkboxChanged($event)"
            *ngIf="checkboxVisible"
            color="primary"
            [date]="date"
            [checked]="checked"
            [roleCodes]="roleCodes"
            [user]="user"
            [date]="date"
          >
          </mat-checkbox-with-roles>
        </div>
      </div>
    </div>
  </div>
</div>
