import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExtraAction, WFTaskSignatureResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { NormalInputDialogComponent } from '../../normal-input-dialog/normal-input-dialog.component';
import { FormService } from '../../form-table/form.service';
import { Form } from 'src/app/services/forms/form';
// import { NormalInputDialogComponent } from '../../common/normal-input-dialog/normal-input-dialog.component';

@Component({
  selector: 'extra-action-button',
  templateUrl: './extra-action-button.component.html',
  styleUrls: ['./extra-action-button.component.scss']
})
export class ExtraActionButtonComponent implements OnInit {
  @Input() extraAction!: ExtraAction;
  @Input() confirm!: boolean;
  @Input() disabled!: boolean;
  @Input() form?: Form | null;

  @Output() error = new EventEmitter<string>();
  @Output() signed = new EventEmitter();
  @Output() loading = new EventEmitter<boolean>();

  wfTaskSignature: WFTaskSignatureResource = new WFTaskSignatureResource();

  constructor(
    public service: FormService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  click() {
    if (this.confirm) {
      const confirm = this.dialog.open(NormalInputDialogComponent, {
        width: "600px",
        data: {
          label: "Enter a reason to Make Bypass Permanent",
          text: ""
        }
      });

      confirm.afterClosed().subscribe(
        data => {
          if (data) {
            this.loading.emit(true);
            this.wfTaskSignature.taskId = this.extraAction.taskID;
            this.wfTaskSignature.actionId = this.extraAction.id;
            this.wfTaskSignature.formID = this.form?.id;
            this.wfTaskSignature.reason = data;
            this.service.sign(this.wfTaskSignature).toPromise().then(() => this.signed.emit()).catch(error => this.error.emit(error));
          }
        }
      );
    } else {
      this.loading.emit(true);
      this.wfTaskSignature.taskId = this.extraAction.taskID;
      this.wfTaskSignature.actionId = this.extraAction.id;
      this.wfTaskSignature.formID = this.form?.id;
      this.service.sign(this.wfTaskSignature).toPromise().then(() => this.signed.emit()).catch(error => this.error.emit(error));
    }
  }

}
