<div class="row">
  <div class="col-12 col-sm-12">
    <div
      class="mat-container"
      [ngClass]="{
                  hilite
                }"
    >
      <mat-table cdkDropList #table [dataSource]="dataSourceRestriction">
        <ng-container matColumnDef="restriction">
          <mat-header-cell *matHeaderCellDef>
            Restriction Language
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.restrictionLanguage }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="scf">
          <mat-header-cell *matHeaderCellDef> SCF </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox
              [(ngModel)]="element.scf"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!adding"
              (change)="emitData.emit(dataSourceRestriction.data)"
            ></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="rsswa">
          <mat-header-cell *matHeaderCellDef> RSSWA </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox
              [(ngModel)]="element.rsswa"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!adding"
              (change)="emitData.emit(dataSourceRestriction.data)"
            ></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ebf">
          <mat-header-cell *matHeaderCellDef> EBF </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox
              [(ngModel)]="element.ebf"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!adding"
              (change)="emitData.emit(dataSourceRestriction.data)"
            ></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ppstb">
          <mat-header-cell *matHeaderCellDef> PPSTB </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox
              [(ngModel)]="element.ppstb"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!adding"
              (change)="emitData.emit(dataSourceRestriction.data)"
            ></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="appliesTo">
          <mat-header-cell *matHeaderCellDef> Applies To </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.location?.name }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="add">
          <mat-header-cell *matHeaderCellDef>
            <mat-icon
              class="pointer"
              mat-list-icon
              color="accent"
              (click)="openAddRestrictionDialog()"
              >add</mat-icon
            >
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon
              class="pointer"
              mat-list-icon
              color="accent"
              (click)="openEditRestrictionDialog(element)"
              >edit</mat-icon
            >
            <mat-icon
              class="pointer"
              mat-list-icon
              color="warn"
              (click)="deleteRestriction(element)"
              >delete</mat-icon
            >
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="displayedColumnsRestrictions"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsRestrictions"
          [ngClass]="{
            'added-row': row.isAdded,
            'deleted-row': row.isDeleted,
            'modified-row': row.isModified && !row.isAdded && !row.isDeleted
          }"
        ></mat-row>
      </mat-table>
    </div>
  </div>
</div>
