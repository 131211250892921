import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { FormType } from "../common/enumerations/enumerations";
import { GeneralSignalRResponse } from "../services/signal-r/general-signal-r-response";
import { SignalRService } from "../services/signal-r/signal-r.service";
import { SignalRAction } from "../services/signal-r/signalr-enums";
import { Channels } from "./channels";

export class SetCurrentUser {
  constructor(public token: string | null) { }
  static readonly type = '[Users] Set current user';
}

export class RefreshCurrentUser {
  static readonly type = '[Users] Refresh current user';
}

export class RefreshFormsByType {
  static readonly type = '[Form] Refresh forms by type';
  constructor(public ftype: FormType) { }
}

@Injectable({
  providedIn: 'root'
})

export class AuthenticationStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.AUTHENTICATION, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Get:
          this.store.dispatch(new RefreshCurrentUser());
          break;
      }
    });
  }
}
