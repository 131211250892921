<mat-form-field appearance="outline" class="no-subscript">
  <mat-label>{{ statusLabel ? statusLabel : "Status to Change to" }}</mat-label>
  <input
    [disabled]="disabled"
    type="text"
    placeholder="Select Type"
    matInput
    [ngClass]="status?.cssClass"
    name="statusNameCtrl"
    [formControl]="statusCtrl"
    [matAutocomplete]="autoStatus"
    (keyup)="changedStatus($event)"
    #triggerType="matAutocompleteTrigger"
  />
  <mat-autocomplete
    #autoStatus="matAutocomplete"
    #typeAutocomplete
    [displayWith]="displayObject"
    (optionSelected)="selectedStatus($event)"
  >
    <mat-option
      *ngFor="let s of statusesFiltered"
      [value]="s"
      [ngClass]="s.cssClass"
    >
      <span *ngIf="s.order">{{ s.order }}.</span> {{ s.description }}
    </mat-option>
  </mat-autocomplete>
  <mat-error>You must choose a Status</mat-error>
</mat-form-field>
