<fieldset class="field-group white-background" *ngIf="file">
  <legend>PDF File</legend>
  <div class="row no-gutters">
    <div class="col-1">
      <img
        style="margin: auto"
        class="icon"
        src="../../../../assets/icons/files/pdf.png"
      />
    </div>
    <div class="col-9 small d-flex flex-wrap align-items-center">
      <a href="{{ file.url }}" target="_blank" class="mr-2"
        ><span>{{ getFileName() }}</span></a
      >
      <span class="small">{{
        (file.size / 1024 / 1024 | number : "1.2-2") + " mb"
      }}</span>
    </div>
    <div class="col-2 d-flex justify-content-end">
      <button
        class="p-0 btnx"
        mat-icon-button
        color="warn"
        *ngIf="!processingFile"
        (click)="delete()"
        matTooltip="Delete File"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <span
        class="spinner-border spinner-border-sm mr-1"
        *ngIf="processingFile"
      ></span>
    </div>
  </div>
</fieldset>
<button
  mat-stroked-button
  class="full-width"
  color="primary"
  *ngIf="!file"
  [disabled]="processingFile || disabled"
  (click)="fileInput.click()"
>
  <mat-icon class="yellow">add_box</mat-icon>
  {{ buttonLabel ? buttonLabel : label_select_pdf }}
  <span
    class="spinner-border spinner-border-sm mr-1"
    *ngIf="processingFile"
  ></span>
  <input
    #fileInput
    type="file"
    style="display: none"
    accept="application/pdf"
    [multiple]="false"
    (change)="upload($event)"
  />
</button>
