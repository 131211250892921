<div class="row mt-3">
  <div class="col">
    <h5>Bypass Installation</h5>
  </div>
</div>
<form-workflow
  [form]="form"
  [sectionNumber]="3"
  [amendmentSectionNumbers]="[13]"
  [leftConfigs]="[107, 119]"
  [rightConfigs]="[108, 120]"
  [disabled]="disabled"
  (loading)="loading.emit($event)"
>
</form-workflow>
