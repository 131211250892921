<div class="card">
  <div class="card-header">
    <span class="card-title"
      >{{ data.restriction.id ? "Edit" : "Add" }} Restriction</span
    >
  </div>
  <div class="card-body">
    <mat-form-field>
      <mat-label>Restriction Language</mat-label>
      <textarea
        matInput
        [(ngModel)]="restrictionLanguage"
        rows="5"
        placeholder="Restriction Language"
        [ngModelOptions]="{ standalone: true }"
      ></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Applies To</mat-label>
      <mat-select
        [(ngModel)]="appliesToSelected"
        (selectionChange)="getInfoAppliesTo($event.value)"
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-option
          *ngFor="let appliesTo of appliesToList"
          [value]="appliesTo.id"
        >
          {{ appliesTo.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="isSaving"
        (click)="add()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
