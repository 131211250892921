import { PendingResource } from "src/app/services/pending-approvals/pending-approval";

export class PendingResourcesRefresh {
  static readonly type = '[PendingResources] Refresh';
}

export class PendingResourcesCreate {
  static readonly type = '[PendingResources] Create';

  constructor(public item: PendingResource) { }
}

export class PendingResourcesUpdate {
  static readonly type = '[PendingResources] Update';

  constructor(public id: number, public item: PendingResource) { }
}

export class PendingResourcesDelete {
  static readonly type = '[PendingResources] Delete';
  constructor(public id: number) { }
}
