import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { ReviewStatusAction, ReviewStatus, ReviewActionRole } from 'src/app/components/reviews/reviews.models';
import { Role } from 'src/app/components/catalogs/roles/services/role';

@Component({
  selector: 'app-reviews-settings-statuses-actions-details',
  templateUrl: './statuses-actions-details.component.html',
  styleUrls: ['./statuses-actions-details.component.scss']
})
export class ReviewsSettingsStatusesActionsDetailsComponent implements OnInit {

  reviewStatusAction: ReviewStatusAction;
  reviewStatuses: ReviewStatus[];
  reviewStatusesFiltered: ReviewStatus[];

  title: string;

  roles!: Role[];
  rolesFiltered!: Role[];
  roleCtrl = new FormControl();
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  codeCtrl = new FormControl();
  nameCtrl = new FormControl();
  nextStatusCtrl = new FormControl();
  form!: FormGroup;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  externalActions = [{ name: 'Request Extension', code: '@request' }, { name: 'Grant Extension', code: '@grant' }, { name: 'Deny Extension', code: '@reject' }]

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<ReviewsSettingsStatusesActionsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reviewStatusAction = data.reviewStatusAction;
    this.reviewStatuses = data.reviewStatuses;
    this.reviewStatusesFiltered = this.reviewStatuses;
    this.title = data.title;
  }

  ngOnInit(): void {
    this.buildForm();
    this.loadRoles();
    this.nameCtrl.setValue(this.reviewStatusAction.name);
    this.codeCtrl.setValue(this.reviewStatusAction.code);
    this.nextStatusCtrl.setValue(this.reviewStatusesFiltered.find(x => x.id == this.reviewStatusAction.nextReviewStatusID));
  }

  buildForm() {
    this.form = new FormGroup({
      nameCtrl: this.nameCtrl,
      nextStatusCtrl: this.nextStatusCtrl,
      codeCtrl: this.codeCtrl,
      roleCtrl: this.roleCtrl
    });
  }

  save() {
    this.reviewStatusAction.name = this.nameCtrl.value;
    this.reviewStatusAction.code = this.codeCtrl.value;
    this.reviewStatusAction.nextReviewStatusID = this.nextStatusCtrl.value?.id;
    this.dialogRef.close(this.reviewStatusAction);
  }

  displayObject(e: any) {
    return e ? e.name : null;
  }

  changedNextStatus(e: any) {
    const val = e.target.value as string;
    this.reviewStatusesFiltered = this.reviewStatuses.filter(x => x.name?.toLowerCase().includes(val.toLowerCase()));
  }

  selectedNextStatus(e: any) {
    this.reviewStatusAction.nextReviewStatusID = e.option.value.id;
  }

  selectedColor(e: any) {
    this.reviewStatusAction.color = e;
    this.form.markAsDirty();
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      if (data.length) {
        this.roles = data;
        this.rolesFiltered = data.filter(x => !this.reviewStatusAction?.reviewActionRoles?.map(r => r.roleID).includes(x.id));
      }
    });
  }

  getActionRoles(reviewActionRoles: ReviewActionRole[]) {
    this.loadRoles();
    const roles: Role[] = [];
    reviewActionRoles?.map(r => {
      const role = this.roles.find(x => x.id == r.roleID);
      if (role)
        roles.push(role);
    });
    return roles;
  }

  removeRole(role: any) {
    const index = this.reviewStatusAction.reviewActionRoles.indexOf(role);
    this.reviewStatusAction.reviewActionRoles.splice(index, 1);
  }

  rolesChanged(e: any) {
    const val = e.target.value;
    this.rolesFiltered = this.roles?.filter(x => x.name?.toLowerCase().includes(val.toLowerCase()) || x.code?.toLowerCase().includes(val.toLowerCase()));
  }

  clearInputValue(e: any) {
    const input = e.input;
    if (input) {
      input.value = '';
    }
  }

  selectedRole(role: Role) {
    if (!this.reviewStatusAction.reviewActionRoles) { this.reviewStatusAction.reviewActionRoles = []; }
    const reviewActionRole = {
      id: 0,
      role,
      roleID: role.id,
      reviewStatusActionID: this.reviewStatusAction.id
    } as ReviewActionRole;
    this.reviewStatusAction.reviewActionRoles.push(reviewActionRole);
    this.rolesFiltered = this.roles.filter(x => !this.reviewStatusAction?.reviewActionRoles?.map(r => r.roleID).includes(x.id));
  }

  cancel() {
    this.dialogRef.close();
  }
}
