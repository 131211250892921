import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { ProcedureTrainingService } from "src/app/components/procedure/services/procedure-training.service";
import { ProcedureTrainingCreate, ProcedureTrainingDelete, ProcedureTrainingRefresh, ProcedureTrainingUpdate } from "./procedure-training.action";
import { ProcedureTraining } from "../../models/procedure-training.model";

export class ProcedureTrainingStateModel {
  ProcedureTrainings: ProcedureTraining[] = [];
}

@State<ProcedureTrainingStateModel>({
  name: "ProcedureTraining",
  defaults: {
    ProcedureTrainings: []
  }
})
@Injectable()
export class ProcedureTrainingState {
  constructor(
    private procedureTrainingService: ProcedureTrainingService
  ) { }

  @Action(ProcedureTrainingRefresh)
  refresh({ patchState }: StateContext<ProcedureTrainingStateModel>) {
    this.procedureTrainingService.read().subscribe(
      data => patchState({ ProcedureTrainings: data }),
      error => console.error(error)
    );
  }

  @Action(ProcedureTrainingCreate)
  create({ getState, patchState }: StateContext<ProcedureTrainingStateModel>, { item }: ProcedureTrainingCreate) {
    patchState({ ProcedureTrainings: [...getState().ProcedureTrainings, item] });
  }

  @Action(ProcedureTrainingUpdate)
  update({ getState, patchState }: StateContext<ProcedureTrainingStateModel>, { id, item }: ProcedureTrainingUpdate) {
    const index = getState().ProcedureTrainings.map(x => x.id).indexOf(id);

    if (index >= 0) {
      getState().ProcedureTrainings.splice(index, 1);
      patchState({
        ProcedureTrainings: [...getState().ProcedureTrainings, item]
      });
    }
  }

  @Action(ProcedureTrainingDelete)
  delete({ getState, patchState }: StateContext<ProcedureTrainingStateModel>, { id }: ProcedureTrainingDelete) {
    patchState({
      ProcedureTrainings: getState().ProcedureTrainings.filter(x => x.id != id)
    })
  }
}
