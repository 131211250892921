import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Review, ReviewBoard, ReviewCycle } from '../reviews.models';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'reviews-index',
  templateUrl: './reviews-index.component.html',
  styleUrls: ['./reviews-index.component.scss']
})
export class ReviewsIndexComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() reviewBoardTypeID!: number;
  @Input() visible!: boolean;
  @Input() reviewCycle?: ReviewCycle;
  @Output() reviewSelected = new EventEmitter<Review>();

  reviewCycles!: ReviewCycle[];
  reviewCyclesFiltered!: ReviewCycle[];
  reviewCycles$!: Observable<ReviewCycle[]>;
  reviewCyclesSubs!: Subscription;

  reviewBoard?: ReviewBoard;
  reviewBoards!: ReviewBoard[];
  reviewBoardsFiltered!: ReviewBoard[];
  reviewBoards$!: Observable<ReviewBoard[]>;
  reviewBoardsSubs!: Subscription;

  override ngOnDestroy(): void {
    this.reviewBoardsSubs?.unsubscribe();
    this.reviewCyclesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes?: SimpleChanges): void {
    this.loadReviewCycles();
    this.loadReviewBoards();
  }

  ngOnInit() {
    this.ngOnChanges();
  }

  loadReviewBoards() {
    this.reviewBoards$ = this.store.select(state => state.ReviewBoards.data);
    this.reviewBoardsSubs = this.reviewBoards$.subscribe(data => {
      if (data.length) {
        this.reviewBoards = data.filter(b => b.reviewBoardTypeID == this.reviewBoardTypeID);
        this.reviewBoard = this.reviewBoards.find(b => b.id == this.reviewCycle?.reviewBoardID);
        if (this.reviewBoard)
          this.reviewBoard.reviewCycles = this.reviewCycles?.filter(c => c.id == this.reviewCycle?.id);
      }
    });
  }

  loadReviewCycles() {
    this.reviewCycles$ = this.store.select(state => state.ReviewCycles.data);
    this.reviewCyclesSubs = this.reviewCycles$.subscribe(data => {
      if (data.length)
        this.reviewCycles = data.filter(c => c.id == this.reviewCycle?.id);
    });
  }

}
