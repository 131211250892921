<div class="card">
  <div class="card-header">
    <span class="card-title"
      >{{ ologCategory.id ? "Edit" : "Add" }} Olog Category</span
    >
  </div>
  <div class="card-body">
    <form [formGroup]="formGroup">
      <mat-form-field>
        <mat-label>Category Name</mat-label>
        <input
          matInput
          [(ngModel)]="ologCategory.name"
          formControlName="ologCategoryNameCtrl"
        />
        <mat-error *ngIf="ologCategoryNameCtrl?.invalid">{{
          getErrorMsg(ologCategoryNameCtrl)
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        class="cancel-btn"
        color="warn"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        class="accept-btn"
        color="primary"
        (click)="add()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
