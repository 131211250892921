import { Injectable, Injector } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../services/alert/alert.service';
import { OLogEntry, OlogDataBaseObject, PostOlogResponse } from './olog';
import { TransactionLog } from '../../services/logs/transaction-logs';
import { TransactionLogService } from '../../services/logs/transaction-log.service';
import { FormType, OlogNotificationType } from '../../common/enumerations/enumerations';
import { XMLService } from 'src/app/services/xml/xml-service.service';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { BaseService } from 'src/app/common/base/base.service';
import { Form } from 'src/app/services/forms/form';
import { PartialOlogComponent } from 'src/app/controls/olog/partial-olog/partial-olog.component';


@Injectable({
  providedIn: 'root'
})
export class OLogService extends BaseService<OlogDataBaseObject, number> {
  url: string = environment.urls.baseApiUrl + '/Olog';

  ologIDPosting?: number = 0; // OlogID being Posted

  constructor(
    protected override injector: Injector,
    private alertService: AlertService,
    private log: TransactionLogService,
    private xmlService: XMLService,
  ) {
    super(injector, '/Olog')
  }

  updateOlogNotification(ologDB: OlogDataBaseObject, finishForm: boolean, tlog: TransactionLog) {
    ologDB.sent = true;
    ologDB.closed = finishForm;
    try {
      if (ologDB.id)
        this.Put(ologDB, ologDB.id).toPromise(); // Is not necesary await for the answer
    } catch (error) {
      tlog.description = 'DB Error';
      tlog.objectJson = JSON.stringify(error);
      tlog.status = 8;
      this.log.Post(tlog).subscribe((error: any) => { console.log(error); });
    }
  }

  Put(Olog: OlogDataBaseObject, id: number) {
    return this.http.put<OlogDataBaseObject>(this.url + '/' + id, Olog);
  }

  Post(Olog: OlogDataBaseObject) {
    return this.http.post<OlogDataBaseObject>(this.url, Olog);
  }

  Delete(id: number) {
    return this.http.delete<any>(this.url + '/' + id);
  }

  DeleteOlogBySN(SN: string) {
    return this.http.delete<any>(this.url + '/BySerialNo/' + SN);
  }

  createXMLBody(ologResData: OLogEntry, serialNo: string) {
    let entry: string =
      '<root>' +
      '<author>' + ologResData.root.author + '</author>' +
      '<password>' + ologResData.root.password + '</password>' +
      '<entry>' +
      '<cc>' + ologResData.root.entry.cc.map(c => c.address)?.join(', ') + '</cc>' +
      '<level>' + ologResData.root.entry.level + '</level>';
    let extraSub = '';
    if (document.location.host !== 'ascc.als.lbl.gov') {
      extraSub = 'TEST //';
    }
    if (ologResData.root.entry.extraSubject && ologResData.root.entry.extraSubject !== '') {
      entry += '<subject>' + extraSub + ologResData.root.entry.subject + ' ' + ologResData.root.entry.extraSubject + '</subject>';
    } else {
      entry += '<subject>' + extraSub + ologResData.root.entry.subject + '</subject>';
    }
    entry += '<details>'
      + (ologResData.root.entry.details ? ologResData.root.entry.details + '<![CDATA[<html><br><br>' : '<![CDATA[<html>')
      + 'Please see ASCC for details - <a href="'
      + document.location.origin
      + '/#/redirect/'
      + serialNo
      + '" />'
      + document.location.origin
      + '/#/redirect/'
      + serialNo
      + '</a>]]>'
      + '</details>';
    entry += '<categories>';
    ologResData.root.entry.categories?.map(cat => {
      entry += '<category>' + cat + '</category>';
    });
    entry += '</categories>';
    entry += '<highlight>' + ologResData.root.entry.highlight + '</highlight>' +
      '</entry>' +
      '</root>';
    return (entry);
  }

  /**
   * Post an Olog Message to Olog Service (Olog URL is set on appsettings.json file)
   * @param formType Number: Document Type
   * @param formID Number: ID of the Document
   * @param type OlogNotificationType Enum: Notification Type: Activate = 1, Amended = 2, Closed = 3, Handshake = 4
   * @param amendmentNo Number: Amendment Number if any
   * @param showDialogOnErrors Boolean: If True when Olog server is Down a Dialog is displayed to ask to continue otherwise a Warning Message is going to be displayed. Default = True
   * @param overridePost Boolean: If True then a Post to Olog will be sent ignoring the already Sent Flag, Default = false
   *
   * @returns Boolean value indicating if the post to Olog went OK or not or if was allowed by the User response on the popup Dialog
*/
  async postToOlog(formType: number, formID: number, type: OlogNotificationType, amendmentNo: number = 0, showDialogOnErrors = true, overridePost: boolean = false) {
    const response = await this.http.post<PostOlogResponse>(this.url + '/PostToOlog/' + formType + '/' + formID + '/' + type + '/' + amendmentNo + '/' + overridePost, null).toPromise();
    if (response) response.ok = true;

    if (response && response?.code != 200 && response?.code != 201 && response?.code != 208 && response.code != 500) {
      this.alertService.message('olog_error');
      console.log(response);
    }
    else if (response && response.code == 201) {
      const message = this.xmlService.parseXml(response.message).root;
      const htmlAddresses = '<br><p>Email to: <b>' + message.entry.cc + '</b></p>';
      const htmlSubject = '<p>Subject: <b>' + message.entry.subject + '</b></p>';
      const htmlDetails = '<p>Details: <b>' + message.entry.details + '</b></p>';
      const htmlCategories = '<p>Categories: <b>' + (message.entry.categories.length ? message.entry.categories.map((x: any) => x).join(', ') : message.entry.categories) + '</b></p>';
      const htmlHighlights = '<p>Highlight: <b>' + message.entry.highlight + '</b></p>';
      const htmlLevel = '<p>Level: <b>' + message.entry.level + '</b></p>';
      const htmlMessage = htmlAddresses + htmlSubject + htmlDetails + htmlCategories + htmlHighlights + htmlLevel;
      this.alertService.banner(htmlMessage, 'Test Olog Post:', 15000);
    }
    else if (response?.code == 208) {
      // Olog already sent previously!
    }
    else if (response?.code == 500) {
      if (showDialogOnErrors) {
        // YesNo Dialog
        const res = await this.displayDialog();
        response.ok = res == true;
      }
      else {
        this.alertService.message('olog_server_down');
      }
    }
    return response;
  }

  getOlog(formType: number, formID: number, type: OlogNotificationType) {
    return this.http.get<OlogDataBaseObject>(this.url + '/GetOlog/' + formType + '/' + formID + '/' + type);
  }

  postToOlogFE(xmlBody: string) {
    const testOlog = 'https://controls.als.lbl.gov/olog_test/rpc.php';
    return this.http.post(/* environment.urls.ologUrl */ testOlog, xmlBody, {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
      }),
      responseType: 'text',
      withCredentials: true
    });
  }

  displayDialog() {
    return new Promise((resolve, reject) => {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: "500px", data: {
          message: this.getMessage('olog_post_failed_question').description,
          icon: "question",
        },
      });
      confirm.afterClosed().subscribe(async (data) => {
        resolve(data);
      });
    });
  }

  async editOlog(formType: FormType, formID: number, type: OlogNotificationType, disabled = false) {
    try {
      const data = await this.getOlog(formType, formID, type).toPromise();
      if (data) {
        data.disabled = disabled;
        const dialogRef = this.dialog.open(PartialOlogComponent, {
          height: "fit-content",
          width: "80%",
          data,
        });
        dialogRef.afterClosed().subscribe((isSaved) => {
          if (isSaved) {
            console.log(isSaved);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

}
