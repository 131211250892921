import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ResourceRelationsCreate, ResourceRelationsDelete, ResourceRelationsRefresh, ResourceRelationsUpdate } from './resource-relations.action';
import { ResourceRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';


export class ResourceRelationsStateModel {
  data: ResourceRelation[] = [];
}

@State<ResourceRelationsStateModel>({
  name: 'ResourceRelations',
  defaults: {
    data: []
  }
})
@Injectable()
export class ResourceRelationsState {
  constructor(
    private _service: ResourcesService,
  ) { }

  @Selector()
  static read(state: ResourceRelationsStateModel) {
    return state.data;
  }

  @Action(ResourceRelationsRefresh)
  refresh({ patchState }: StateContext<ResourceRelationsStateModel>) {
    this._service.getRelationResources().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ResourceRelationsCreate)
  create({ getState, patchState }: StateContext<ResourceRelationsStateModel>, { item }: ResourceRelationsCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ResourceRelationsUpdate)
  update({ getState, patchState }: StateContext<ResourceRelationsStateModel>, { id, item }: ResourceRelationsUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ResourceRelationsDelete)
  delete({ getState, patchState }: StateContext<ResourceRelationsStateModel>, { id }: ResourceRelationsDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
