import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ProcedureNote } from '../../models/procedure-note.model';
import { Procedure } from '../../models/procedure.model';

@Component({
  selector: 'procedure-note-popup',
  templateUrl: './procedure-note-popup.component.html',
  styleUrls: ['./procedure-note-popup.component.scss']
})
export class ProcedureNotePopupComponent extends BaseComponent implements OnInit {
  note = new ProcedureNote();

  label_note!: string;
  label_description_is_required!: string;
  label_cancel!: string;
  label_accept!: string;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<ProcedureNotePopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Procedure
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.setLabels();
  }

  setLabels() {
    this.label_note = this.getMessage('ALSPC_Note_Note').description;
    this.label_description_is_required = this.getMessage('ALSPC_Note_DescriptionIsRequired').description;
    this.label_cancel = this.getMessage('ALSPC_Note_Cancel').description;
    this.label_accept = this.getMessage('ALSPC_Note_Accept').description;
    if (!this.label_note) {
      setTimeout(() => {
        this.setLabels();
      }, 1000);
    }
  }

  accept() {
    this.note.procedureID = this.data.id;
    this.dialogRef.close(this.note);
  }

  validateDescription() {
    return this.note?.description?.trim()?.length;
  }

}
