import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Resource } from '../../beamline-catalog/resource/resources';

@Component({
  selector: 'rssd-list-main',
  templateUrl: './rssd-list-main.component.html',
  styleUrls: ['./rssd-list-main.component.scss']
})
export class RssdListMainComponent extends BaseComponent {

  @Input() resources?: Resource[] | null;
  @Input() resource?: Resource;


  getRSSDs(r: Resource) {
    return r.childResources?.filter(c => c.childResource?.isRSSD).map(c => c.childResource);
  }

  scrollTo(r: Resource) {
    const elem = r.id.toString();
    const divElement = document.getElementById(elem);
    if (divElement)
      divElement.scrollIntoView({ behavior: 'smooth' });
  }
}
