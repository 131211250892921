import { ReviewAttendanceSave } from '../../reviews.models';

export class ReviewAttendanceSaveRefresh {
  static readonly type = '[ReviewAttendanceSave] Refresh';
}

export class ReviewAttendanceSaveCreate {
  static readonly type = '[ReviewAttendanceSave] Create';

  constructor(public item: ReviewAttendanceSave) { }
}

export class ReviewAttendanceSaveUpdate {
  static readonly type = '[ReviewAttendanceSave] Update';

  constructor(public id: number, public item: ReviewAttendanceSave) { }
}

export class ReviewAttendanceSaveDelete {
  static readonly type = '[ReviewAttendanceSave] Delete';
  constructor(public id: number) { }
}
