import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BaseService } from 'src/app/common/base/base.service';
import { ActionPendingCatalog, Roles, StatusPendingCatalog } from 'src/app/common/enumerations/enumerations';
import { PendingCatalogs, PendingCatalog, PendingCatalogType } from 'src/app/services/pending-approvals/pending-approval';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { ChecklistPriv, ChecklistTemplate, ChecklistTemplateStatusEnum } from '../checklists/checklists';
import { ProcedureSignatureType } from '../procedure/enums/procedure-signature-type.enum';
import { utils } from 'src/app/modules/libs/utils';

@Injectable({
  providedIn: 'root'
})
export class PendingApprovalsV2Service extends BaseService<PendingCatalogs, number> {

  public pendingCatalogsSubject!: BehaviorSubject<PendingCatalogs | null>;

  pendingApprovals!: PendingCatalogs;
  myPendingChanges!: PendingCatalogs;

  pendingCatalogs$!: Observable<PendingCatalogs>;
  pendingCatalogsSubs!: Subscription;

  catalogNames: string[] = [
    'None',
    'Beamline',
    'Users',
    'Role',
    'Accelerator',
    'Notifications',
    'Privileges',
    'Trainings'
  ];

  checklistTemplates?: ChecklistTemplate[];
  checklistTemplates$!: Observable<ChecklistTemplate[]>;
  checklistTemplatesSubs!: Subscription;

  constructor(
    protected override injector: Injector,
  ) {
    super(injector, 'PendingApprovals');
    this.pendingCatalogsSubject = new BehaviorSubject<PendingCatalogs | null>(null);
    this.loadChecklistTemplates();
  }

  override currentUserUpdated(): void {
    this.loadChecklistTemplates();
  }

  loadChecklistTemplates() {
    this.checklistTemplates$ = this.store.select(state => state.ChecklistTemplates.pending);
    this.checklistTemplatesSubs = this.checklistTemplates$.subscribe(data => {
      this.checklistTemplates = data;
      this.loadPendingApprovals();
    });
  }

  loadPendingApprovals() {
    this.pendingCatalogs$ = this.store.select(state => state.PendingCatalogs.data);
    this.pendingCatalogsSubs = this.pendingCatalogs$.subscribe(data => {
      if (data && this.currentUser) {
        const p: PendingCatalogs = {
          pendingAccelerators: this.getMyApprovals(data.pendingAccelerators ?? []),
          pendingUsers: this.getMyApprovals(data.pendingUsers),
          pendingRoles: this.getMyApprovals(data.pendingRoles),
          pendingNotificationTemplates: this.getMyApprovals(data.pendingNotificationTemplates),
          pendingResources: this.getMyApprovals(data.pendingResources),
          pendingRSSDb: this.getMyApprovals(data.pendingRSSDb),
          pendingChecklistTemplates: this.getMyApprovals(data.pendingChecklistTemplates)
        };
        p.total =
          (p.pendingAccelerators?.length ?? 0) +
          (p.pendingUsers?.length ?? 0) +
          (p.pendingRoles?.length ?? 0) +
          (p.pendingResources?.length ?? 0) +
          (p.pendingRSSDb?.length ?? 0) + (p.pendingChecklistTemplates?.length ?? 0);
        this.pendingApprovals = p;

        const q: PendingCatalogs = {
          pendingAccelerators: this.getMyChanges(data.pendingAccelerators),
          pendingUsers: this.getMyChanges(data.pendingUsers),
          pendingRoles: this.getMyChanges(data.pendingRoles),
          pendingNotificationTemplates: this.getMyChanges(data.pendingNotificationTemplates),
          pendingResources: this.getMyChanges(data.pendingResources),
          pendingRSSDb: this.getMyChanges(data.pendingRSSDb),
          pendingChecklistTemplates: this.getMyChanges(data.pendingChecklistTemplates)
        };
        q.total =
          (q.pendingAccelerators?.length ?? 0) +
          (q.pendingUsers?.length ?? 0) +
          (q.pendingRoles?.length ?? 0) +
          (q.pendingResources?.length ?? 0) +
          (q.pendingRSSDb?.length ?? 0) + (q.pendingChecklistTemplates?.length ?? 0);
        this.myPendingChanges = q;
        this.pendingCatalogsSubject.next(data);
      }
    });
  }

  private setNames(data: PendingCatalog[]) {
    data?.map(x => {
      x.actionName = ActionPendingCatalog[x.action];
      if (x.catalogType != PendingCatalogType.ChecklistTemplate)
        x.statusName = StatusPendingCatalog[x.status];
    });
    return data;
  }

  private getMyChanges(data: PendingCatalog[] = []) {
    return this.setNames(data?.filter(x => x.createdBy == this.currentUser?.id && x.show && (x.catalogType != PendingCatalogType.ChecklistTemplate && x.statusBy != this.currentUser.id || x.catalogType == PendingCatalogType.ChecklistTemplate)));
  }

  private getMyApprovals(data: PendingCatalog[] = []) {
    return this.setNames(data?.filter(x => {
      if (x.createdBy == this.currentUser?.id) return false;

      switch (x.catalogType) {
        case PendingCatalogType.Accelerator:
          return x.status == StatusPendingCatalog.Pending && (this.isSA() || this.hasPrivilege(PrivilegeEnum.AcceleratorCatalogApprove));

        case PendingCatalogType.Beamline:
        case PendingCatalogType.Role:
        case PendingCatalogType.User:
          return x.status == StatusPendingCatalog.Pending && (this.isSA() && x.pendingID || !x.pendingID && this.hasPrivilege(PrivilegeEnum.BeamlineRestrictionsApprove));

        case PendingCatalogType.RSSDb:
          return x.status == StatusPendingCatalog.Pending && (this.isSA() || this.hasPrivilege(PrivilegeEnum.RSSTestDBAuth));

        case PendingCatalogType.KET:
          return this.hasRoles([Roles.SA]);

        case PendingCatalogType.ABSI:
          return this.hasPrivilege(PrivilegeEnum.ApproveABSITemplate);

        case PendingCatalogType.ChecklistTemplate:
          return this.canApproveTemplate(x);

        default:
          return false;
      }
    })
    );
  }

  canApproveTemplate(templateApproval: PendingCatalog) {
    const checklistTemplate = this.checklistTemplates?.find(t => t.id == templateApproval.pendingID);

    switch (templateApproval?.status) {

      case ChecklistTemplateStatusEnum.PendingRev:
        if (checklistTemplate?.procedure) {
          return checklistTemplate.procedure.procedureSignatures?.filter(s => s.signatureType == ProcedureSignatureType.Reviewer).map(s => s.userID).includes(this.currentUser?.id ?? 0);
        }
        return false;

      case ChecklistTemplateStatusEnum.CorrectionsPending:
        if (checklistTemplate?.procedure) {
          return checklistTemplate.procedure.procedureSignatures?.filter(s => s.signatureType == ProcedureSignatureType.Preparer).map(s => s.userID).includes(this.currentUser?.id ?? 0);
        }
        return false;

      case ChecklistTemplateStatusEnum.PendingApr:
        if (checklistTemplate?.procedure) {
          return checklistTemplate.procedure.procedureSignatures?.filter(s => s.signatureType == ProcedureSignatureType.Approver).map(s => s.userID).includes(this.currentUser?.id ?? 0);
        }
        else {
          return utils.intersect(checklistTemplate?.templateType?.documentType?.templateRolePermissions?.filter(p => p.checklistPrivilege == ChecklistPriv.Approve)?.map(p => p.roleID), this.currentUser?.userRole?.map(r => r.roleID));
        }

      default:
        return false;

    }
  }
}
