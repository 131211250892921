import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { RoleService } from 'src/app/components/catalogs/roles/services/role.service';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.scss']
})
export class UserRoleComponent extends BaseComponent implements OnInit {

  roleSelected?: Role;
  roles?: Role[];
  rolesFiltered?: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<UserRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public role: Role,
    private _roleService: RoleService) {
    super(injector);
  }

  ngOnInit(): void {
    this.loadRoles();
  }

  add() {
    if (this.roleSelected?.id) {
      this.dialogRef.close(this.roleSelected);
    }
  }

  getRoleSelected(role: Role) {
    this.roleSelected = role;
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      if (data?.length) {
        this.roles = data;
        this.rolesFiltered = data;
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }


  filter(e: any) {
    const text = e.target.value;
    const code = e.code;
    if (!code?.includes("Arrow")) {
      this.rolesFiltered = this.roles?.filter(x => x.name?.toLocaleLowerCase().includes(text.trim().toLocaleLowerCase()) || x.code?.toLowerCase().includes(text.trim().toLowerCase()));
    }
  }

}
