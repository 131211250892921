import { PrivilegeCategory } from "src/app/components/catalogs/roles/services/role-privilege";


export class PrivilegeCategoryRefresh {
  static readonly type = '[PrivilegeCategory] Refresh';
}

export class PrivilegeCategoryCreate {
  static readonly type = '[PrivilegeCategory] Create';
  constructor(public item: PrivilegeCategory) { }
}

export class PrivilegeCategoryUpdate {
  static readonly type = '[PrivilegeCategory] Update';
  constructor(public id: number, public item: PrivilegeCategory) { }
}

export class PrivilegeCategoryDelete {
  static readonly type = '[PrivilegeCategory] Delete';
  constructor(public id: number) { }
}
