import { Component, Input, OnInit, SimpleChanges, OnChanges, OnDestroy, Output, EventEmitter, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { ChecklistBuilderAnnotationsService } from 'src/app/components/checklists/checklist-builder/checklist-builder-annotations/checklist-builder-annotations.service';
import { Annotation, AnnotationStatus, Checklist } from 'src/app/components/checklists/checklists';
import { ChecklistsService } from 'src/app/components/checklists/checklists.service';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { WfSignature, WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { AppStateService } from 'src/app/store/app-state-service';
import { ChecklistTasksAnnotationsEditComponent } from './checklist-tasks-annotations-edit/checklist-tasks-annotations-edit.component';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'checklist-tasks-annotations-annotation',
  templateUrl: './checklist-tasks-annotations-annotation.component.html',
  styleUrls: ['./checklist-tasks-annotations-annotation.component.scss']
})
export class ChecklistTasksAnnotationsAnnotationComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() annotation?: Annotation;
  @Input() s!: WfSignature;
  @Input() task?: WfTaskLocalResource;
  @Input() currentChecklist?: Checklist | null;

  @Output() changed = new EventEmitter<WfTaskLocalResource>();

  annotationTextSanitized!: SafeHtml;
  responseTextSanitized!: SafeHtml;
  isRCP!: boolean;

  formStatus = FormStatusEnum;
  annotationStatus = AnnotationStatus;

  hasRresponse!: boolean;
  isPreparer!: boolean;
  isReviewer!: boolean;
  isApprover!: boolean;
  isCreator!: boolean;
  isResponder!: boolean;

  annotations!: Annotation[];
  annotations$!: Observable<Annotation[]>;
  annotationsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private annotationService: ChecklistBuilderAnnotationsService,
    private checklistsService: ChecklistsService,
  ) {
    super(injector);
  }

  override  ngOnDestroy(): void {
    this.annotationsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadUsers();
    this.isRCP = this.currentChecklist?.checklistTemplate?.documentTypeID == 200;
  }

  ngOnInit() {
    this.loadAnnotations();
    this.isPreparer = this.checklistsService.isPreparer;
    this.isReviewer = this.checklistsService.isReviewer;
  }

  loadAnnotations() {
    this.annotations$ = this.store.select(state => state.Annotations.data);
    this.annotationsSubs = this.annotations$.subscribe(data => {
      this.annotations = data;
      if (this.annotation) {
        this.annotation = this.annotations.find(x => x.id == this.annotation?.id);
        if (this.annotation) {
          this.annotation.createdByName = this.users.find(x => x.id == this.annotation?.createdBy)?.name;
          this.annotation.responseByName = this.users.find(x => x.id == this.annotation?.responseBy)?.name;
        }
        this.annotationTextSanitized = this.sanitize(this.annotation?.annotationText);
        this.responseTextSanitized = this.sanitize(this.annotation?.responseText);
        this.isCreator = this.annotation?.createdBy == this.currentUser?.id;
        this.isResponder = this.annotation?.responseBy == this.currentUser?.id;
      }
    });
  }

  edit() {
    const editor = this.dialog.open(ChecklistTasksAnnotationsEditComponent, {
      width: '80vw',
      maxHeight: '90vh',
      data: {
        s: this.s,
        task: this.task,
        annotation: this.annotation,
        currentChecklist: this.currentChecklist,
        isResponse: false
      },
      autoFocus: false,
      disableClose: true,
      hasBackdrop: true
    });
    editor.afterClosed().subscribe(async (data) => {
      if (data) {
        this.changed.emit(data);
      }
    });
  }

  delete() {
    const yesno = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('GenChklistBldr_DeleteAnnotationConfimation').description,
        icon: 'stop'
      }
    });
    yesno.afterClosed().subscribe(data => {
      if (data && this.annotation) {
        this.annotationService.delete(this.annotation).toPromise().then((data) => {
          this.changed.emit(data);
        });
      }
    });
  }

  done() {
    if (this.annotation) {
      this.annotation.status = this.annotationStatus.Done;
      this.annotationService.update(this.annotation).toPromise().then();
    }
  }

  undone() {
    if (this.annotation) {
      this.annotation.status = this.annotationStatus.Active;
      this.annotationService.update(this.annotation).toPromise().then();
    }
  }

  respond() {
    const editor = this.dialog.open(ChecklistTasksAnnotationsEditComponent, {
      width: '80vw',
      maxHeight: '90vh',
      data: {
        s: this.s,
        task: this.task,
        annotation: this.annotation,
        currentChecklist: this.currentChecklist,
        isResponse: true
      },
      autoFocus: false,
      disableClose: true,
      hasBackdrop: true
    });
    editor.afterClosed().toPromise().then();
  }


  deleteResponse() {
    if (this.annotation) {
      this.annotation.responseBy = null;
      this.annotation.responseByName = null;
      this.annotation.responseDate = null;
      this.annotation.responseText = null;
      this.annotationService.update(this.annotation).toPromise().then();
    }
  }

}
