<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <input
    matInput
    type="text"
    [matAutocomplete]="aplGroupAutocomplete"
    [disabled]="disabled"
    (keyup)="filter($event.code)"
    [(ngModel)]="text"
  />
  <mat-autocomplete
    #aplGroupAutocomplete="matAutocomplete"
    (optionSelected)="select($event)"
  >
    <mat-option *ngFor="let aplGroup of filteredAplGroups" [value]="aplGroup">
      {{ aplGroup?.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
