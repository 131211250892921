import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ReviewPermission, ReviewStatusPermission, ReviewStatus, ReviewBoardType } from '../../../reviews.models';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ReviewsService } from '../../../reviews.service';
import { ReviewPermissionsRefresh } from '../../../store/review-permissions/review-permissions.action';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'reviews-settings-permissions-status',
  templateUrl: './reviews-settings-permissions-status.component.html',
  styleUrls: ['./reviews-settings-permissions-status.component.scss']
})
export class ReviewsSettingsPermissionsStatusComponent implements OnInit, OnDestroy {

  @Input() reviewPermission?: ReviewPermission;
  @Input()
  reviewBoardType!: ReviewBoardType;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  formGroup!: FormGroup;
  statusCtrl = new FormControl();

  statusPermissions?: ReviewStatusPermission[];

  reviewPermissions$!: Observable<ReviewPermission[]>;
  reviewPermissionsSubs!: Subscription;

  reviewStatuses!: ReviewStatus[];
  reviewStatusesFiltered!: ReviewStatus[];
  reviewStatuses$!: Observable<ReviewStatus[]>;
  reviewStatusesSubs!: Subscription;

  statusTemp: any;

  constructor(
    private store: Store,
    private service: ReviewsService
  ) { }

  ngOnDestroy(): void {
    this.reviewStatusesSubs?.unsubscribe();
    this.reviewPermissionsSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.buildForm();
    this.loadReviewPermissions();
    this.loadStatuses();
  }

  buildForm() {
    this.formGroup = new FormGroup({
      statusCtrl: this.statusCtrl
    });
  }

  loadReviewPermissions() {
    this.reviewPermissions$ = this.store.select(state => state.ReviewPermissions.data);
    this.reviewPermissionsSubs = this.reviewPermissions$.subscribe(data => {
      this.reviewPermission = data.find(x => x.id == this.reviewPermission?.id);
      this.statusPermissions = this.reviewPermission?.reviewStatusPermissions;
      this.filterStatuses();
    });
  }

  loadStatuses() {
    this.reviewStatuses$ = this.store.select(state => state.ReviewStatus.data);
    this.reviewStatusesSubs = this.reviewStatuses$.subscribe(data => {
      this.reviewStatuses = data.filter(x => x.reviewBoardTypeID == this.reviewBoardType.id);
      this.filterStatuses();
    });
  }

  filterStatuses() {
    this.reviewStatusesFiltered = this.reviewStatuses?.filter(x => !this.statusPermissions?.map(s => s.reviewStatusID).includes(x.id));
  }

  statusChanged(e: any) {
    const text = e.target.value;
    this.filterStatuses();
    this.reviewStatusesFiltered = this.reviewStatusesFiltered.filter(x => x.name?.toLowerCase().includes(text.toLowerCase()) || x.code?.toLowerCase().includes(text.toLowerCase()));
  }

  selectedStatus(event: MatAutocompleteSelectedEvent) {
    if (this.reviewPermission && !this.reviewPermission?.reviewStatusPermissions) { this.reviewPermission.reviewStatusPermissions = []; }
    const status: ReviewStatus = event.option.value;
    const statusPermission = {
      id: 0,
      name: status.name,
      reviewStatus: status,
      privilege: this.reviewPermission?.privilege,
      reviewStatusID: status.id,
      reviewPermissionID: this.reviewPermission?.id
    } as ReviewStatusPermission;
    this.statusPermissions?.push(statusPermission);
    this.service.createReviewStatusPermission(utils.Serialize(statusPermission)).toPromise().then(() => {
      this.store.dispatch(new ReviewPermissionsRefresh());
    });
  }

  clearInputValue(event: MatChipInputEvent) {
    const input = event.input;
    if (input) {
      input.value = '';
    }
  }

  getClass(statusPermission: ReviewStatusPermission) {
    return statusPermission.reviewStatus?.color + '-background';
  }

  removeStatus(statusPermission: ReviewStatusPermission) {
    const index = this.statusPermissions?.findIndex(x => x.id == statusPermission.id) ?? -1;
    if (index >= 0)
      this.statusPermissions?.splice(index, 1);
    this.service.deleteReviewStatusPermission(statusPermission.id).toPromise().then(() => {
      this.store.dispatch(new ReviewPermissionsRefresh());
    });
  }

}
