import { CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, Input, OnChanges, SimpleChanges, ElementRef, Injector } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatTabGroup } from '@angular/material/tabs';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Catalog, Roles, ActionPendingCatalog } from 'src/app/common/enumerations/enumerations';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { utils } from 'src/app/modules/libs/utils';
import { APLCategoryRoleCatalog } from 'src/app/components/apl/services/apl-category-role-catalog.model';
import { APLGroupRoleCatalog } from 'src/app/components/apl/services/apl-group-role-catalog.model';
import { APLGroupRoleRoleCatalog } from 'src/app/components/apl/services/apl-group-role-role-catalog.model';
import { TokenInfoService } from 'src/app/services/authentication/token-info.service';
import { CatalogModel } from 'src/app/components/catalogs/services/catalog-model';
import { ProcedureObligationType } from 'src/app/components/procedure/enums/procedure-obligation-type.enum';
import { ProcedureTraining } from 'src/app/components/procedure/models/procedure-training.model';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { RolePrivilegePending } from 'src/app/components/catalogs/roles/services/role-privilege';
import { RoleService } from 'src/app/components/catalogs/roles/services/role.service';
import { UserRole } from 'src/app/services/user-roles/user-role';
import { UserRoleNote } from 'src/app/services/user-roles/user-role-note';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { CatalogService } from '../../catalog-service';
import { ProcedureTrainingService } from 'src/app/components/procedure/services/procedure-training.service';
import { ProcedureTrainingRefresh } from 'src/app/components/procedure/store/procedure-training/procedure-training.action';
import { ProcedureTemplateMaster } from 'src/app/components/procedure/models/procedure-template-master.model';
import { DisapprovalReasonComponent } from 'src/app/controls/disapproval-reason/disapproval-reason.component';
import { RoleUserComponent } from '../role-user/role-user.component';
import { AddUserRoleNoteComponent } from '../add-user-role-note-component/add-user-role-note.component';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { ProcedureMaster } from 'src/app/components/procedure/models/procedure-master.model';
import { PendingApprovalsRefresh } from 'src/app/components/pending-approvals-v2/store/pending-approvals/pending-approvals.action';
import { RelatedLinkBase } from '../../navigation-links/link/related-link-base';

@Component({
  selector: 'app-role-details',
  templateUrl: './role-details.component.html',
  styleUrls: ['./role-details.component.scss']
})
export class RoleDetailsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() role?: Role;
  @Output() refreshRoles = new EventEmitter<any>();
  @Output() pending = new EventEmitter<boolean>();
  @Output() restoreRole = new EventEmitter<number>();
  @Output() roleCreated = new EventEmitter<string>();

  @ViewChild('usersListTable') usersListTable!: MatTable<any>;
  @ViewChild('usersList') usersList!: CdkDropList;
  @ViewChild('tabs') tabGroup!: MatTabGroup;
  @ViewChild('roleCode') roleCode!: ElementRef;

  modifyingRolePrivileges!: boolean;

  public userLogged!: User;
  catalog = Catalog;
  public catalogModel!: CatalogModel;

  public errorMsg: string[] = [];
  public oldRoleCode?: string;
  public oldRoleName?: string;

  public modifying = false;
  public modifyingAsPTC = false;
  public modifyingCatalogs: Catalog[] | null = [];
  public adding = false;
  public disabledFields!: boolean;
  public showTabRoles = false;
  public selectingUserRole = false;
  public valid!: boolean;
  public deleted = false;
  public isPC!: boolean;
  public modifyAvailable?: boolean;
  public isOnlyPTC? = false;
  public isModifiying!: boolean;
  public showCancelPending!: boolean;
  public showApproveUnapprove!: boolean;
  public showRoleModified!: boolean;
  public roleModified!: boolean;
  public fieldRepeatedRoleName!: boolean;
  public fieldRepeatedRoleCode!: boolean;
  public disabledStatus!: boolean;
  public showCancelButton!: boolean;
  public disabledTimer!: boolean;
  public statusInactive = false;
  public statusList: any = [{ name: 'Active', id: 1 }, { name: 'Inactive', id: 0 }];
  public displayedColumnsRoleUsers: string[] = ['name', 'notes'];
  public dataSourceRoleUsers: MatTableDataSource<UserRole> = new MatTableDataSource();
  public trainingColumns: string[] = ['procedure', 'obligation', 'delete'];
  procedureTraining$!: Observable<ProcedureTraining[]>;
  procedureTrainings!: ProcedureTraining[];
  procedureTrainingsRole?: ProcedureTraining[];
  pendingTrainings?: ProcedureTraining[] | null;
  procedureTrainingsDS!: MatTableDataSource<ProcedureTraining>;
  selectedProcedures?: (Procedure | undefined)[] = [];
  procedureTrainingAdmin?: boolean | null = false;
  dragEnabled = false;

  roles!: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  procedures!: Procedure[];
  procedures$!: Observable<Procedure[]>;
  proceduresSubs!: Subscription;

  rolePrivilegesPending!: RolePrivilegePending[];

  notesList: UserRoleNote[] = [];

  tmpRoleCode?: string | null;
  tmpPerformingWork?: boolean;
  tmpTrainingRole?: boolean;
  tmpStatus?: number;
  tmpName?: string;
  tmpInformation?: string;

  originalRole?: Role;

  relatedLinks: RelatedLinkBase[] = [];

  constructor(
    protected override injector: Injector,
    private _roleService: RoleService,
    private _catalogService: CatalogService,
    private procedureTrainingService: ProcedureTrainingService,
    private tokenService: TokenInfoService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.role) {
      this.role = new Role();
      this.clear();
    }
    else {
      this.getDetails();
    }
    this.setAvailableOptions();
  }

  ngOnInit() {
    this.role = new Role();
    this.store.dispatch(new ProcedureTrainingRefresh());
    this.clear();
    this.loadRoles();
    this.loadTrainings();
    this.loadProcedures();
  }

  loadTrainings() {
    this.procedureTraining$ = this.store.select(state => state.ProcedureTraining.data);
    this.procedureTraining$.subscribe((data: ProcedureTraining[]) => {
      if (data) {
        this.procedureTrainings = data.sort((a, b) => utils.sort(a.procedureMaster?.procedure?.procedureNumber, b.procedureMaster?.procedure?.procedureNumber, true));
        this.setTrainingDS();
      }
    });
    this.procedureTrainingAdmin = this.tokenService.hasPrivilege(PrivilegeEnum.ALSProcedureTrainingAdmin);
  }

  loadProcedures() {
    this.procedures$ = this.store.select(state => state.Procedures.data);
    this.proceduresSubs = this.procedures$.subscribe(data => {
      if (data?.length) {
        this.procedures = data;
      }
    });
  }

  override ngOnDestroy(): void {
    this.rolesSubs?.unsubscribe();
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      this.roles = data;
      if (this.role?.id) {
        this.role = this.roles.find(x => x.id == this.role?.id);
        this.getDetails();
      }
    });
  }

  getDetails() {
    this.modifyingCatalogs = [];
    this.roleModified = false;
    this.tabGroup.selectedIndex = 0;
    if (!this.role) { this.role = new Role(); }
    this.role.aplGroupRole = this.role?.aplGroupRole ?? new APLGroupRoleRoleCatalog();
    this.clear();

    this.tmpRoleCode = this.role.code;
    this.tmpPerformingWork = this.role.performingWork;
    this.tmpTrainingRole = this.role.trainingRole;
    this.tmpStatus = this.role.status;
    this.tmpName = this.role.name;
    this.tmpInformation = this.role.information;
    this.relatedLinks = this.utils.JSONparse(this.role.relatedLinks);

    if ((this.role?.id ?? 0) < 0) {
      this._catalogService.GetPendingCatalogById(this.role.id ?? 0, Catalog.Roles).toPromise().then(result => {
        if (result?.role) {
          result.role.aplGroupRole = result.role.aplGroupRole ?? new APLGroupRoleRoleCatalog();
          this.role = result.role as Role;
          this.dataSourceRoleUsers.data = result.role.userRole as UserRole[];
          this.isModifiying = true;
          this.showApproveUnapprove = this.getCurrentUser()?.id != this.role.createdBy;
          this.showCancelButton = true;
          this.role.createdByUser = this.getUsers().find(x => x.id == this.role?.createdBy);
          this.pendingTrainings = this.role.procedureTrainings;
          this.relatedLinks = this.utils.JSONparse(this.role.relatedLinks);
          this.setTrainingDS();
        }
      });
    }
    else if (this.role.id == 0) {
      this.adding = true;
      this.modifying = false;
      this.dragEnabled = false;
      this.pending.emit(this.modifying || this.adding);
      this.disabledFields = false;
      this.displayedColumnsRoleUsers.push('add');
      this.role.status = 1;
      this.disabledStatus = true;
      this.showCancelButton = true;
      this.dataSourceRoleUsers.data = [];
      this.procedureTrainingsDS.data = [];
      this.relatedLinks = [];
      this.setTrainingDS();
    }
    else {
      this.role.createdByUser = this.getUser(this.role?.createdBy);
      this.role.updatedByUser = this.getUser(this.role?.updatedBy);
      this._catalogService.IsModifiyingById(this.role.id ?? 0, Catalog.Roles).toPromise().then(result => {
        if (result && this.role) {
          const rolePrivilegesPending = this.role?.rolePrivilegesPending?.find(x => x.status == 1);
          this.isModifiying = result.isModifying || rolePrivilegesPending != null;
          this.modifyingCatalogs = result.catalogs;
          this.showCancelPending = result.userID == this.getCurrentUser()?.id && this.isModifiying;
          this.showRoleModified = this.getCurrentUser()?.id == result.userID;
          if ((this.isSA() || this.isPC) && this.getCurrentUser()?.id != result.userID) {
            this.showApproveUnapprove = this.isSA() ? result.isModifying : (!this.isSA() && result.catalogs?.includes(Catalog.RolePrivileges) ? false : result.isModifying);
            this.showRoleModified = result.isModifying as boolean;
          }
          if (this.getCurrentUser()?.id != result.userID && !this.showApproveUnapprove) {
            if (!result.catalogs?.includes(Catalog.RolePrivileges)) {
              this.ValidateCategoryManagerCanApprove(this.role, result.isModifying);
              this.ValidateGroupManagerCanApprove(this.role, result.isModifying);
              this.ValidateProcedureTrainingCoordinatorCanApprove(this.role, result.isModifying);
            }
          }
          if (this.isSA() && this.isModifiying) {
            this.roleModified = true;
            this.onShowRoleModified();
          }
          this.showTabRoles = true;
          this.disabledFields = false;
          this.modifying = false;
          this.dragEnabled = false;
          this.adding = false;
          this.pending.emit(this.modifying || this.adding);
          this.showCancelButton = true;
          this.setActiveTab();
        }

      });
      this.dataSourceRoleUsers.data = this.getUsersOrderedWithOrderField(this.role.userRole as UserRole[]);
      this.oldRoleCode = this.role.code;
      this.oldRoleName = this.role.name;
      this.showCancelButton = true;
      this.setTrainingDS();
    }
    this.setAvailableOptions();
  }

  drop(event: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    // updates moved data and table
    this.reorderRoleUserData();
    this.setFormDirty();
  }

  sortDataSourceOrderByUserRoleId() {
    if (this.dataSourceRoleUsers.data) {
      this.dataSourceRoleUsers.data.sort((a, b) => utils.sort(a.id, b.id));
    }
  }

  setUserRolesOrder() {
    const users = this.dataSourceRoleUsers.data as UserRole[];
    users.map(user => user.order = 0);
    users.map(user => {
      const maximumOrder = Math.max.apply(Math, users?.map(o => o.order ?? 0));
      user.order = maximumOrder + 1;
      return user;
    });
  }

  ValidateCategoryManagerCanApprove(data: Role, enable: boolean) {
    if (data?.aplGroupRole?.aplGroup?.aplGroupMaster?.aplCategoryMaster?.aplCategory?.aplCategoryManagers?.map(categoryManager => categoryManager?.user?.id).some(val => val === this.getCurrentUser()?.id)) {
      this.setApproveUnnapprove(enable);
    }
  }

  ValidateGroupManagerCanApprove(data: Role, enable: boolean) {
    if (data.aplGroupRole?.aplGroup?.aplGroupManagers?.map(groupManager => groupManager?.user?.id).some(userId => userId === this.getCurrentUser()?.id)) {
      this.setApproveUnnapprove(enable);
    }
  }

  ValidateProcedureTrainingCoordinatorCanApprove(data: Role, enable: boolean) {
    if (data.aplGroupRole?.aplGroup?.aplProcedureTrainingCoordinators?.map(ptc => ptc?.user?.id).some(userId => userId === this.getCurrentUser()?.id)) {
      this.setApproveUnnapprove(enable);
    }
  }

  setApproveUnnapprove(enable: boolean) {
    this.showApproveUnapprove = enable;
    this.showRoleModified = enable;
  }

  createTraining(procedure: Procedure) {
    const newProcedureTraining = new ProcedureTraining();
    newProcedureTraining.procedureMasterID = procedure.procedureMasterID;
    newProcedureTraining.roleID = this.role?.id;
    newProcedureTraining.obligationType = ProcedureObligationType.RequiredFollowUp;
    newProcedureTraining.procedureMaster = new ProcedureMaster();
    if (newProcedureTraining.procedureMaster) {
      newProcedureTraining.procedureMaster.id = procedure.procedureMasterID ?? 0;
      newProcedureTraining.procedureMaster.procedure = procedure;
    }
    this.procedureTrainingsRole?.push(newProcedureTraining);
    this.procedureTrainingsRole?.sort((a, b) => utils.sort(a.procedureMaster?.procedure?.procedureNumber, b.procedureMaster?.procedure?.procedureNumber, true));
    this.procedureTrainingsDS = new MatTableDataSource(this.procedureTrainingsRole);
    this.selectedProcedures = this.procedureTrainingsRole?.map(x => x.procedureMaster?.procedure);
  }

  updateTraining() {
    // this.procedureTrainingService.update(procedureTraining.id, procedureTraining).subscribe(
    //   () => { },
    //   error => {
    //     if (error.status === 404 || error.status == 409) {
    //       this._alert.error(error.error);
    //     } else {
    //       this._alert.general(this.message_general_error);
    //     }
    //   },
    // );
    this.setFormDirty();
  }

  deleteTraining(procedureTraining: ProcedureTraining) {
    this.dialog.open(YesNoDialogComponent, {
      minWidth: '400px',
      data: {
        message: this.getMessage('ALSPC_RolesCatalog_AreYouSureYouWantToDeleteTheProcedure').description,
      }
    }).afterClosed().subscribe(
      data => {
        if (data) {
          this.setFormDirty();
          const index: number = this.procedureTrainingsRole?.indexOf(procedureTraining) ?? -1;
          if (index >= 0) {
            this.procedureTrainingsRole?.splice(index, 1);
            this.procedureTrainingsDS = new MatTableDataSource(this.procedureTrainingsRole);
          }
        }
      }
    );
  }

  setTemplate(procedureTemplateMaster: ProcedureTemplateMaster) {
    this.dialog.open(YesNoDialogComponent, {
      minWidth: '400px',
      data: {
        message: this.getMessage('ALSPC_Template_AreYouSureYouWantToApplyTheTemplateInTheRole').description.replace('{template}', procedureTemplateMaster?.name ?? '').replace('{role}', this.role?.name ?? ''),
      },
    }).afterClosed().subscribe(
      data => {
        if (data && this.role?.id && procedureTemplateMaster.id) {
          this.procedureTrainingService.createFromTemplate(this.role?.id, procedureTemplateMaster.id).subscribe(
            () => { },
            error => {
              if (error?.status === 404 || error?.status === 409) {
                this.alert.error(error.error);
              } else {
                this.alert.defaultError();
              }
            },
          );
        }
      }
    );
  }

  setTrainingDS() {
    const role: Role | undefined = utils.cloneDeep(this.role);
    if (role?.id) {
      this.procedureTrainingAdmin = this.tokenService.hasPrivilege(PrivilegeEnum.ALSProcedureTrainingAdmin) ||
        role?.roleALSPCSupervisors?.some(x => x.userID == this.getCurrentUser()?.id) ||
        role?.roleALSPCDesignees?.some(x => x.userID == this.getCurrentUser()?.id) ||
        role?.aplGroupRole?.aplGroup?.aplGroupManagers?.some(x => x?.userID === this.getCurrentUser()?.id) ||
        role?.aplGroupRole?.aplGroup?.aplGroupMaster?.aplCategoryMaster?.aplCategory?.aplCategoryManagers?.some(x => x?.userID === this.getCurrentUser()?.id);

      if (this.pendingTrainings) {
        this.pendingTrainings.map(training => {
          training.procedureMaster = this.procedureTrainings.find(trainingRole => trainingRole.procedureMasterID == training.procedureMasterID)?.procedureMaster;
        });
        this.procedureTrainingsRole = utils.cloneDeep(this.pendingTrainings.filter(x => x.roleID == role?.id));
        if (role.id < 0) {
          this.procedureTrainingsRole = this.pendingTrainings;
        }
      }
      else {
        this.procedureTrainingsRole = this.role?.procedureTrainings;
      }
      this.procedureTrainingsRole?.filter(x => !x.procedureMaster).map(x => {
        const procedures = this.procedures.filter(p => p.procedureMasterID == x.procedureMasterID);
        const procedureMaster = procedures[0].procedureMaster;
        if (procedureMaster)
          procedureMaster.procedure = procedures[0];
        x.procedureMaster = procedureMaster;
      });
      this.selectedProcedures = this.procedureTrainingsRole?.map(x => x.procedureMaster?.procedure);
      this.procedureTrainingsDS = new MatTableDataSource(this.procedureTrainingsRole);
    } else {
      this.procedureTrainingAdmin = this.tokenService.hasPrivilege(PrivilegeEnum.ALSProcedureTrainingAdmin);
      this.procedureTrainingsRole = [];
    }
  }

  async saveRole() {
    this.disabledTimer = true;
    this.catalogModel.role = this.createRole();
    if (await this.isValid()) {
      if (!this.role?.id) {
        this._catalogService.SaveAsDraft(this.catalogModel, Catalog.Roles).subscribe(() => {
          this.alert.success('Congratulations !! The New Role Has Been Sent To Approve Successfully!!');
          this.modifyAvailable = false;
          this.roleCreated.emit(this.catalogModel.role?.code);
        });
      } else {
        this._catalogService.SaveAsDraft(this.catalogModel, Catalog.Roles).subscribe(() => {
          this.alert.success('Congratulations !! The Role Modification Has Been Sent To Approve Successfully!!');
          this.modifyAvailable = false;
          this.roleModified = true;
          this.roleCreated.emit(this.catalogModel.role?.code);
        });
      }
      this.setFormDirty(false);
    } else {
      this.alertError();
      this.disabledTimer = false;
    }
    this.errorMsg = [];
  }

  restoreInfo() {
    if (this.role) {
      this.getDetails();
      this.restoreRole.emit(this.role.id);
    }
  }

  approve() {
    this.disabledTimer = true;
    if (this.role?.id)
      this._catalogService.Approve(this.role.id, Catalog.Roles).subscribe(() => {
        this.alert.success('Congratulations !! Role Approved Successfully!!');
        this.reset();
        this.roleCreated.emit(this.role?.code);
        this.modifyAvailable = false;
      });
  }

  disapprove(disapprovalReason: string) {
    if (this.role?.id)
      this._catalogService.Unapprove(this.role.id, Catalog.Roles, disapprovalReason).subscribe(() => {
        this.alert.success('Congratulations !! Role Disapproved Successfully!!');
        this.reset();
      });
  }

  async deleteRole() {
    if (await this.canDeactivate())
      this.dialog.open(YesNoDialogComponent, {
        minWidth: '400px',
        data: {
          message: this.getMessage('Delete_Role_Question').description.replace('{role}', this.role?.name ?? ''),
          icon: 'stop'
        }
      }).afterClosed().toPromise().then(res => {
        if (res) {
          this.disabledTimer = true;
          this.catalogModel.role = this.createRole();
          this.catalogModel.action = ActionPendingCatalog.Delete;
          this._catalogService.SaveAsDraft(this.catalogModel, Catalog.Roles).toPromise().then(() => {
            'Delete_Role_Success'
              ;
            this.reset();
          });
        }
      });
  }

  deleteRoleUser(userRole: UserRole) {
    const index = this.dataSourceRoleUsers.data.indexOf(userRole);
    this.dataSourceRoleUsers.data.splice(index, 1);
    this.dataSourceRoleUsers.filter = '';
    if (this.dataSourceRoleUsers.data.length === 0) {
      this.statusInactive = false;
    }
    this.setFormDirty();
  }

  openAddDisapprovalDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = '';
    const dialogRef = this.dialog.open(DisapprovalReasonComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data != undefined) {
        this.disapprove(data);
      }
    });
  }

  cancelPendingChanges() {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      minWidth: '400px',
      data: {
        message: 'Are you sure to cancel your pending changes?',
        icon: 'stop'
      }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (this.role?.id)
          this._catalogService.Unapprove(this.role.id, Catalog.Roles, 'Canceled by Requester').subscribe(() => {
            this.alert.success('Congratulations !! Role Changes Cancelled Successfully!!');
            this.roleCreated.emit(this.role?.code);
          });
      }
    });
  }

  modifyRole() {
    this.disabledFields = false;
    this.modifying = true;
    this.pending.emit(this.modifying || this.adding);
    if (!this.isOnlyPTC) {
      this.dragEnabled = true;
      this.displayedColumnsRoleUsers.push('noteActions');
      this.displayedColumnsRoleUsers.push('add');
    }
  }

  modifyRolePrivileges() {
    this.modifyingRolePrivileges = true;
  }

  endModifyRolePrivileges() {
    this.modifyingRolePrivileges = false;
  }

  aplCategoryIDChanged(e: any) {
    if (this.role?.aplGroupRole?.aplGroup?.aplGroupMaster?.aplCategoryMaster?.aplCategory?.id) {
      this.role.aplGroupRole.aplGroup.aplGroupMaster.aplCategoryMaster.aplCategory.id = e;
      this.setFormDirty();
    }
  }

  aplGroupIDChanged(e: any) {
    if (this.role?.aplGroupRole)
      this.role.aplGroupRole.aplGroup.id = e;
    this.setFormDirty();
  }

  createRole(): Role | undefined {
    this.sortDataSourceOrderByUserRoleId();
    const trainingsToSend = this.getTrainingsModified();
    if (this.role) {
      const role: Role = {
        id: this.role.id,
        code: this.role.code,
        name: this.role.name,
        status: this.role.status,
        information: this.role.information,
        userRole: this.dataSourceRoleUsers.data,
        performingWork: this.role.performingWork,
        trainingRole: this.role.trainingRole,
        roleALSPCSupervisors: this.role.roleALSPCSupervisors,
        roleALSPCDesignees: this.role.roleALSPCDesignees,
        procedureTrainings: trainingsToSend,
        rolePrivilegesPending: this.rolePrivilegesPending,
        relatedLinks: this.utils.JSONstringify(this.relatedLinks)
      };
      return role
    }
    else return undefined;
  }

  getTrainingsModified(): ProcedureTraining[] | undefined {
    const trainings = utils.cloneDeep(this.procedureTrainingsRole)
    if (trainings)
      trainings.map(training => {
        if (training.procedureMaster)
          training.procedureMaster.procedure = undefined;
        training.role = undefined;
      });
    return trainings;
  }

  async isValid() {
    this.valid = true;
    const catalogModel: CatalogModel = {
      role: this.role
    };
    if (!this.role?.code) {
      this.errorMsg.push('Field <b>Role</b> should not be blank!');
      this.valid = false;
    } else {
      if (this.oldRoleCode != this.role.code) {
        this.fieldRepeatedRoleCode = true;
      }
    }
    if (!this.role?.name) {
      this.errorMsg.push('Field <b>Description</b> should not be blank!');
      this.valid = false;
    } else {
      if (this.oldRoleName != this.role.name) {
        this.fieldRepeatedRoleName = true;
      }
    }
    if (this.fieldRepeatedRoleCode || this.fieldRepeatedRoleName) {
      await this._catalogService.IsFieldRepeated(catalogModel, Catalog.Roles).toPromise().then(data => {
        if (data) {
          if (data.item1 == true && this.fieldRepeatedRoleCode == true) {
            this.errorMsg.push('Field <b>Role Name</b> already exists');
            this.valid = false;
          }
          if (data.item2 == true && this.fieldRepeatedRoleName == true) {
            this.errorMsg.push('Field <b>Role Description</b> already exists');
            this.valid = false;
          }
        }
      });
    }
    return this.valid;
  }

  addUserRole(user: User) {
    const userRole: UserRole = {
      roleID: this.role?.id,
      userID: user.id,
      user
    };
    this.dataSourceRoleUsers.data.push(userRole);
    this.dataSourceRoleUsers.filter = '';
    this.reorderRoleUserData();
    this.setFormDirty();
  }

  reorderRoleUserData() {
    // this.dataSourceRoleUsers.data = clonedeep(this.dataSourceRoleUsers.data);
    this.setUserRolesOrder();
  }

  openAddRoleUser() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = new User();
    const dialogRef = this.dialog.open(RoleUserComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (this.isUserRoleRepeated(this.dataSourceRoleUsers.data, data)) {
          this.addUserRole(data);
        } else {
          this.alertError();
        }
        this.errorMsg = [];
      }
    });
  }

  isUserRoleRepeated(roleUsers: UserRole[], user: User): boolean {
    this.valid = true;
    if (roleUsers.filter(x => x.userID == user.id).length != 0) {
      this.errorMsg.push('User <b>' + user.name + '</b> is already added');
      this.valid = false;
    }
    return this.valid;
  }

  clear() {
    this.setFormDirty(false);
    // this.role = new Role();
    this.catalogModel = new CatalogModel();
    // this.dataSourceRoleUsers = new MatTableDataSource();
    this.disabledFields = true;
    this.adding = false;
    this.modifying = false;
    this.dragEnabled = false;
    this.pending.emit(this.modifying || this.adding);
    this.showTabRoles = false;
    this.selectingUserRole = false;
    this.showRoleModified = false;
    this.showApproveUnapprove = false;
    this.roleModified = false;
    this.isModifiying = false;
    this.fieldRepeatedRoleCode = false;
    this.fieldRepeatedRoleName = false;
    this.disabledStatus = false;
    this.showCancelButton = true;
    this.disabledTimer = false;
    this.deleted = false;
    this.pendingTrainings = null;
    this.relatedLinks = [];
    $('#roleModified').removeClass('showRoleModified');
    $('#roleModified').removeClass('showRoleDeleted');
    if (this.displayedColumnsRoleUsers.includes('add')) {
      const index = this.displayedColumnsRoleUsers.indexOf('add');
      this.displayedColumnsRoleUsers.splice(index, 1);
    }
    if (this.displayedColumnsRoleUsers.includes('noteActions')) {
      const index = this.displayedColumnsRoleUsers.indexOf('noteActions');
      this.displayedColumnsRoleUsers.splice(index, 1);
    }
  }

  reset() {
    const that = this;
    this.dragEnabled = false;
    setTimeout(function () {
      that.refreshRoles.emit('reset');
      that.clear();
    }, 300);
    this.restoreInfo();
    this.store.dispatch(new PendingApprovalsRefresh());
  }

  async discardChanges() {
    if (await this.canDeactivate()) {
      this.clear();
      this.restoreInfo();
    }
  }

  alertError() {
    this.alert.error(this.errorMsg.join(', '));
  }

  onShowRoleModified() {
    if (this.roleModified) {
      if (this.isModifiying && this.role?.id) {
        this._catalogService.GetPendingCatalogById(this.role.id, Catalog.Roles).toPromise().then(data => {
          if (data?.role) {
            data.role.aplGroupRole = data.role.aplGroupRole ?? new APLGroupRoleRoleCatalog();
            this.originalRole = utils.cloneDeep(this.role);
            this.role = data.role as Role;

            this.role.aplGroupRole = this.originalRole?.aplGroupRole;
            this.role.updatedBy = this.role.createdBy;
            this.role.updatedOn = this.role.createdOn;
            this.role.updatedByUser = this.getUser(this.role?.updatedBy);
            this.role.createdBy = this.originalRole?.createdBy;
            this.role.createdByUser = this.originalRole?.createdByUser;
            this.role.approvedBy = this.originalRole?.approvedBy;
            this.role.approvedByID = this.originalRole?.approvedByID;
            this.role.approvedDate = this.originalRole?.approvedDate;
            this.pendingTrainings = data.role.procedureTrainings;
            this.relatedLinks = this.utils.JSONparse(this.role.relatedLinks);
            this.tmpRoleCode = this.role.code;
            this.tmpPerformingWork = this.role.performingWork;
            this.tmpTrainingRole = this.role.trainingRole;
            this.tmpStatus = this.role.status;
            this.tmpName = this.role.name;
            this.tmpInformation = this.role.information;

            this.setTrainingDS();

            this.dataSourceRoleUsers.data = this.getUsersOrderedWithOrderField(data.role.userRole as UserRole[]);
            if (this.tabGroup.selectedIndex == 0) {
              this.setActiveTab();
            }
          }
        });
      }
    } else {
      this.pendingTrainings = undefined;
      if (this.role?.id)
        this._roleService.ReadOne(this.role.id).toPromise().then(data => {
          if (data) {
            this.deleted = false;
            data.aplGroupRole = data.aplGroupRole ?? new APLGroupRoleRoleCatalog();
            this.role = data as Role;
            this.role.createdByUser = this.getUsers().find(x => x.id == this.role?.createdBy);
            this.role.updatedByUser = this.getUsers().find(x => x.id == this.role?.updatedBy);

            this.tmpRoleCode = this.role.code;
            this.tmpPerformingWork = this.role.performingWork;
            this.tmpTrainingRole = this.role.trainingRole;
            this.tmpStatus = this.role.status;
            this.tmpName = this.role.name;
            this.tmpInformation = this.role.information;
            this.originalRole = undefined;
            this.relatedLinks = this.utils.JSONparse(this.role.relatedLinks);
            this.dataSourceRoleUsers.data = this.getUsersOrderedWithOrderField(this.role.userRole as UserRole[]);
            this.setTrainingDS();
          }
        });

    }
  }

  setActiveTab() {
    if (this.modifyingCatalogs?.length) {
      switch (this.modifyingCatalogs[0]) {
        case Catalog.Roles:
          this.tabGroup.selectedIndex = 0;
          break;
        case Catalog.Users:
          this.tabGroup.selectedIndex = 1;
          break;
        case Catalog.RolePrivileges:
          this.tabGroup.selectedIndex = 2;
          break;
        case Catalog.Trainings:
          this.tabGroup.selectedIndex = 3;
          break;
      }
    }
  }

  getUsersOrderedWithOrderField(users: UserRole[]): UserRole[] {
    if (users.some(user => user.order)) {
      users = users.sort((a, b) => utils.sort(a.order, b.order));
    }
    return users;
  }

  changeStatusRole(data: any) {
    if (data == 0) {
      if (this.dataSourceRoleUsers.data.length > 0) {
        'Error_Inactive_Role'
          ;
        this.statusInactive = true;
      }
    } else {
      this.statusInactive = false;
    }
    this.setFormDirty()
  }

  keydown(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (this.role?.information) {
        this.role.information += '\n';
      } else {
        if (this.role) {
          this.role.information = '';
          this.role.information += '\n';
        }
      }
    }
  }

  // blur() {
  //     this.role.information = this.role.information.substr(0, this.maxlength);
  // }

  getGroupManagers(group?: APLGroupRoleCatalog): string | undefined {
    return group?.aplGroupManagers?.map(groupManager => groupManager?.user?.name)?.join(', ');
  }

  getCategoryManagers(category?: APLCategoryRoleCatalog): string | undefined {
    return category?.aplCategoryManagers?.map(categoryManager => categoryManager?.user?.name)?.join(', ');
  }

  getProcedureCenterCoordinators(group?: APLGroupRoleCatalog): string | undefined {
    return group?.aplProcedureTrainingCoordinators?.map(ptc => ptc?.user?.name)?.join(', ');
  }

  setAvailableOptions() {
    this.modifyAvailable =
      this.getCurrentUser()?.userRole?.some(role => role.roleID == Roles.ADM || role.role?.code === 'PC') ||
      this.role?.aplGroupRole?.aplGroup?.aplGroupManagers?.some(groupManager => groupManager?.userID == this.getCurrentUser()?.id) ||
      this.role?.aplGroupRole?.aplGroup?.aplGroupMaster?.aplCategoryMaster?.aplCategory?.aplCategoryManagers?.some(categoryManager => categoryManager?.userID == this.getCurrentUser()?.id);
    if (!this.modifyAvailable) {
      this.isOnlyPTC = this.role?.aplGroupRole?.aplGroup?.aplProcedureTrainingCoordinators?.some(ptc => ptc?.userID == this.getCurrentUser()?.id);
      this.modifyAvailable = this.isOnlyPTC ? true : false;
    }
  }

  openNoteEditor(userRole: UserRole, note: UserRoleNote | undefined = undefined) {
    let index = -1;
    if (note) {
      const notes = this.dataSourceRoleUsers.data.find(user => user.userID === userRole.userID)?.notes;
      index = notes?.indexOf(note, 0) ?? -1;
    }
    const dialogRef = this.dialog.open(AddUserRoleNoteComponent, {
      width: '20em',
      data: note
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.setFormDirty();
        if (note && index >= 0) {
          this.updateNote(result, userRole, index);
        }
        else {
          this.createNoteInCatalog(result, userRole);
        }
      }
    });
  }

  createNoteInCatalog(note: UserRoleNote, userRole: UserRole) {
    note.userRoleID = userRole.roleID;
    const users = this.dataSourceRoleUsers.data as UserRole[];
    let notes = users.find(user => user.userID == userRole.userID)?.notes ?? [];
    notes = notes == undefined ? [] : notes;
    notes.push(note);
    const user = users.find(user => user.userID == userRole.userID);
    if (user)
      user.notes = notes;
    this.alert.message('add', [new MessagePlaceholder('{what}', 'Note')]);
  }

  updateNote(note: UserRoleNote, userRole: UserRole, index: number) {
    note.userRoleID = userRole.roleID;
    const noteData = this.dataSourceRoleUsers.data.find(user => user.userID === userRole.userID)?.notes?.[index];
    if (noteData)
      noteData.description = note.description;
    this.alert.message('add', [new MessagePlaceholder('{what}', 'Note')]);
  }

  deleteNote(userRole: UserRole, note: UserRoleNote) {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      minWidth: '400px',
      data: {
        message: this.getMessage('Confirm_Note_Delete_Question')?.description,
        icon: 'stop'
      }
    });
    dialogRef.afterClosed().subscribe(dataReturned => {
      if (dataReturned) {
        const users = this.dataSourceRoleUsers.data as UserRole[];
        const notes = users.find(user => user.userID == userRole.userID)?.notes;
        const index = notes?.indexOf(note, 0) ?? -1;
        if (index >= 0) {
          notes?.splice(index, 1);
        }
      }
    });
  }

  setRolePrivilegesPending(e: RolePrivilegePending[]) {
    this.rolePrivilegesPending = e;
    this.setFormDirty();
  }

  roleChanged(e: any) {
    if (this.role) {
      this.role.code = e;
      this.setFormDirty();
    }
  }

  relatedLinksChanged(relatedLinks: RelatedLinkBase[]) {
    this.relatedLinks = relatedLinks;
    this.setFormDirty();
  }



}
