import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { DocumentType } from 'src/app/services/documents/documents';
import { DocumentsService } from 'src/app/services/documents/documents.service';


@Component({
  selector: 'app-document-type-list',
  templateUrl: './document-type-list.component.html',
  styleUrls: ['./document-type-list.component.scss']
})
export class DocumentTypeListComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() filter?: string;
  @Output() documentTypeSelected = new EventEmitter<DocumentType>();
  @Output() loading = new EventEmitter<boolean>();

  public documentTypes$!: Observable<DocumentType[]>;
  public documentTypes!: DocumentType[];
  public documentTypeSubscription!: Subscription;

  public documentTypeSelectedIndex = -1;

  public displayedColumnsDocumentType: string[] = ['name', 'category', 'description'];
  public dataSource: MatTableDataSource<DocumentType> = new MatTableDataSource<DocumentType>();

  constructor(
    protected override injector: Injector,
    public service: DocumentsService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loadDocumentTypes();
  }

  override ngOnDestroy(): void {
    this.documentTypeSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  loadDocumentTypes() {
    this.loading.emit(true);
    this.documentTypes$ = this.store.select(state => state.DocumentType.data);
    this.documentTypeSubscription = this.documentTypes$.subscribe(documentTypes => {
      this.documentTypes = documentTypes;
      this.dataSource = new MatTableDataSource(this.utils.cloneDeep(documentTypes.filter(x => x.showInCatalog)));
      this.loading.emit(false);
    });
  }

  async getDocumentType(documentTypeSelected: DocumentType) {
    if (await this.canDeactivate()) {
      if (documentTypeSelected.id === 11) { // ABSI
        documentTypeSelected.documentTemplateId = 10; // ABSI Template
      } else {
        documentTypeSelected.documentTemplateId = this.documentTypes.find(x => x.type === documentTypeSelected.id)?.id;
      }
      this.documentTypeSelectedIndex = documentTypeSelected.id;
      this.documentTypeSelected.emit(documentTypeSelected);
    }
  }

  async createDocumentType() {
    if (await this.canDeactivate()) {
      this.documentTypeSelectedIndex = 0;
      this.documentTypeSelected.emit({ id: 0 });
    }
  }

  sortData(sort: Sort) {
    const dataToSort = this.utils.cloneDeep(this.dataSource.data);
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = dataToSort;
      return;
    }
    this.dataSource.data = dataToSort.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.utils.sort(a.name, b.name, isAsc);
        case 'description': return this.utils.sort(a.description, b.description, isAsc);
        default: return 0;
      }
    });
  }



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
