import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ResourceType } from 'src/app/common/enumerations/enumerations';
import { AbsiTemplateTypeResource } from 'src/app/components/absi/absi';
import { AbsiBuilderService } from 'src/app/components/absi/absi-builder/absi-builder.service';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { CatalogService } from '../../../catalog-service';


@Component({
  selector: 'app-endstation',
  templateUrl: './endstation.component.html',
  styleUrls: ['./endstation.component.scss']
})
export class EndstationComponent extends BaseComponent implements OnInit {

  public endstationName?: string | null;
  public oldEndstation?: string | null;

  public isSaving!: boolean;

  public absiTypes!: AbsiTemplateTypeResource[];
  public absiType?: AbsiTemplateTypeResource;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<EndstationComponent>,
    @Inject(MAT_DIALOG_DATA) public resource: Resource,
    private catalogService: CatalogService,
    private absi: AbsiBuilderService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.endstationName = this.resource.name;
    this.oldEndstation = this.resource.name;
    this.absi.GetTemplateTypes().toPromise().then(absiTypes => {
      this.absiTypes = absiTypes ?? [];
      this.absiType = absiTypes?.find(x => x.id === this.resource.absiTemplateTypeID);
    });
  }

  async add() {
    this.isSaving = true;
    if (await this.isValid()) {
      this.resource.name = this.endstationName;
      this.dialogRef.close(this.resource);
    } else {
      this.isSaving = false;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  async isValid() {
    if (!this.endstationName) {
      this.alert.message('endstationDialog_Required');
      return false;
    }
    if (!this.absiType) {
      this.alert.message('endstationDialog_ABSI_TypeRequired');
      return false;
    }
    if (this.oldEndstation !== this.endstationName) {
      const isRepeated = await this.catalogService.IsFieldRepeatedResource(this.endstationName, ResourceType.Endstation).toPromise();
      if (isRepeated) {
        this.alert.message('endstationDialog_Repeated');
        return false;
      }
    }
    return true;
  }

  absiTypeChange(absiTypeSelected: AbsiTemplateTypeResource) {
    this.resource.absiTemplateTypeID = absiTypeSelected.id;
    this.resource.absiTemplateName = absiTypeSelected.name;
  }

}
