import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ResourceType } from 'src/app/common/enumerations/enumerations';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';
import { ReviewBoard, ReviewBoardType } from '../../../reviews.models';

@Component({
  selector: 'app-reviews-boards-add-resource',
  templateUrl: './reviews-boards-add-resource.component.html',
  styleUrls: ['./reviews-boards-add-resource.component.scss']
})
export class ReviewsBoardsAddResourceComponent extends BaseComponent implements OnInit, OnDestroy {

  resourceName?: string | null;
  resourceDesc?: string | null;
  resources!: Resource[];
  resourcesFiltered!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;
  resource!: Resource;

  reviewBoards!: ReviewBoard[];
  reviewBoards$!: Observable<ReviewBoard[]>;
  reviewBoardsSubs!: Subscription;

  title!: string;
  loading!: boolean;

  addFromExisting!: boolean;

  public get resourceNameCtrl(): AbstractControl | null { return this.formGroup.get('resourceNameCtrl'); }
  public get resourceDescCtrl(): AbstractControl | null { return this.formGroup.get('resourceDescCtrl'); }
  public get resourceCtrl(): AbstractControl | null { return this.formGroup.get('resourceCtrl'); }

  constructor(
    protected override injector: Injector,
    private dialogRef: MatDialogRef<ReviewsBoardsAddResourceComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: {
      reviewBoardType: ReviewBoardType,
      resource: Resource,
      title: string
    },
    private _resources: ResourcesService,
  ) {
    super(injector);

  }

  override ngOnDestroy(): void {
    this.reviewBoardsSubs?.unsubscribe();
    this.resourcesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadReviewBoards();
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      resourceNameCtrl: [{ value: '' }, [Validators.required]],
      resourceDescCtrl: [{ value: '' }, [Validators.required]],
      resourceCtrl: [{ value: '' }, [Validators.required]],
    });
    if (this.inputData.resource) {
      this.resourceName = this.inputData.resource.name;
      this.resourceDesc = this.inputData.resource.description;
      this.resourceNameCtrl?.setValue(this.resourceName);
      this.resourceDescCtrl?.setValue(this.resourceDesc);
      this.resourceCtrl?.setValue(this.inputData.resource);
    }
  }

  toggleChanged(e: any) {
    this.addFromExisting = e.checked;
  }

  loadReviewBoards() {
    this.reviewBoards$ = this.store.select(state => state.ReviewBoards.data);
    this.reviewBoardsSubs = this.reviewBoards$.subscribe(data => {
      if (data.length) {
        this.reviewBoards = data.filter(b => b.reviewBoardTypeID == this.inputData.reviewBoardType.id);
        this.loadResources();
      }
    });
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {

      if (this.inputData.reviewBoardType?.id == 1)
        this.resources = data.filter(x => x.type == this.resourceTypeEnum.Beamline || x.type == this.resourceTypeEnum.Branchline || x.type == ResourceType.ReviewProcessResource);
      else {
        this.resources = data.filter(x => x.type == this.inputData.reviewBoardType.resourceTypeID || x.type == ResourceType.ReviewProcessResource);
      }

      // this.resources = data.filter(x => x.type == this.inputData.reviewBoardType.resourceTypeID || x.type == ResourceType.ReviewProcessResource);
      this.resourcesFiltered = this.resources.filter(x => !this.reviewBoards.map(b => b.resource?.id).includes(x.id));
    });
  }

  isRepeated(control: AbstractControl | null) {
    const repeated = this.resources.find(x => x.name?.toLowerCase() == control?.value.toLowerCase());
    if (repeated) {
      this.resourceNameCtrl?.setErrors({ repeated: true });
      return { repeated: true };
    }
    return null;
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    if (!this.resource || this.inputData.resource) {
      const resource = {
        id: this.inputData.resource?.id,
        name: this.resourceName,
        description: this.resourceDesc,
        status: 1,
        type: ResourceType.ReviewProcessResource,
        code: 'RCP'
      } as Resource;
      this._resources.saveResource(resource).toPromise().then(res => {
        this.dialogRef.close(res);
      });
    }
    else {
      this.dialogRef.close(this.resource);
    }
  }

  selectedResource(e: any) {
    this.resource = e.option.value;
    this.resourceDesc = this.resource.description;
  }

  formValid() {
    if (this.addFromExisting) {
      return this.resource && this.resourceDesc;
    }
    else {
      return this.resourceName && this.resourceDesc;
    }
  }
}
