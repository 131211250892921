import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { APLGroupService } from 'src/app/components/apl/apl-group.service';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { APLCategory } from 'src/app/components/apl/services/apl-category.model';
import { APLGroup } from 'src/app/components/apl/services/apl-group.model';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { APLGroupApprovalPopupComponent, APLGroupApprovalPopupParameters } from '../../../dialogs/apl-group-approval-popup/apl-group-approval-popup.component';
import { APLGroupPopupComponent, APLGroupPopupParameters } from '../../../dialogs/apl-group-popup/apl-group-popup.component';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';

@Component({
  selector: 'apl-category-v2-group-card',
  templateUrl: './apl-category-v2-group-card.component.html',
  styleUrls: ['./apl-category-v2-group-card.component.scss']
})
export class AplCategoryV2GroupCardComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() aplGroup!: APLGroupCard;
  @Input() reorderable = false;
  @Input() rolesSelected!: Role[];
  @Input() aplGroupCategoryManager = false;
  @Input() isSuperAdmin: boolean = false;
  @Input() filter!: string;

  @Output() loading = new EventEmitter<boolean>();

  aplCategoriesSubscription!: Subscription;
  aplCategories$!: Observable<APLCategory[]>;
  aplCategory!: APLCategory;

  labelAreYouSureYouWantToDelete!: string;
  labelGroupManagers!: string;
  labelProcedureTrainingCoordinators!: string;
  labelApproveDisapprove!: string;
  labelEdit!: string;
  labelDelete!: string;
  labelInfo!: string;
  labelMembers!: string;
  labelDescription!: string;

  aplGroupApproval = false;
  aplGroupEdit = false;
  aplGroupDelete = false;

  isGroupManager = false;
  override users: User[] = [];

  constructor(
    protected override injector: Injector,
    private aplGroupService: APLGroupService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    this.setPermissions();
    this.users = super.getUsers().filter(u => u.status == 1);

    this.aplCategories$ = this.store.select(state => state.APLCategory.APLCategories);
    this.aplCategoriesSubscription = this.aplCategories$.subscribe(aplCategories => {
      this.aplCategory = aplCategories.filter(x => x.aplCategoryMasterID === this.aplGroup?.aplGroupMaster?.aplCategoryMasterID)[0];
    });

    this.setLabels();
  }

  override ngOnDestroy(): void {
    this.aplCategoriesSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  setPermissions() {
    this.aplGroupApproval = this.hasPrivilege(PrivilegeEnum.APLGroupApproval);
    this.aplGroupEdit = this.hasPrivilege(PrivilegeEnum.APLGroupEdit);
    this.aplGroupDelete = this.hasPrivilege(PrivilegeEnum.APLGroupDelete);
    this.isGroupManager = this.aplGroup?.aplGroupManagers?.some(x => x?.userID === this.currentUser?.id) ?? false;
  }

  canApproveOrReject(): boolean {
    const groupStatus = this.aplGroup?.status ?? 0;
    const categoryStatus = this.aplCategory?.status ?? 0;

    // Check if the current user is a manager of the current APL group
    const isGroupManager = this.aplGroup?.aplGroupManagers?.some(x => x?.userID === this.currentUser?.id);

    // Check if the current user is a manager of the previous version of the APL group
    const isPreviousGroupManager = this.aplGroup?.previousVersion?.aplGroupManagers?.some(x => x?.userID === this.currentUser?.id);

    // Check if the current user is a manager of the current APL category
    const isCategoryManager = this.aplCategory?.aplCategoryManagers?.some(x => x?.userID === this.currentUser?.id);

    // Check if the current user is a manager of the previous version of the APL category
    const isPreviousCategoryManager = this.aplCategory?.previousVersion?.aplCategoryManagers?.some(x => x?.userID === this.currentUser?.id);

    // Check conditions for approval or rejection
    if (this.aplGroupApproval && groupStatus < 0) {
      return true;
    }

    if (groupStatus === 1) {
      if (groupStatus < 0 && isGroupManager) {
        return true;
      }
    } else {
      if (groupStatus < 0 && isPreviousGroupManager) {
        return true;
      }
    }

    if (categoryStatus === 1) {
      if (groupStatus < 0 && isCategoryManager) {
        return true;
      }
    } else {
      if (groupStatus < 0 && isPreviousCategoryManager) {
        return true;
      }
    }

    return false;
  }

  canEdit(): boolean {
    return this.aplGroup.status === 1 && (this.aplGroupEdit || this.aplGroup?.aplGroupManagers?.some(x => x?.userID === this.currentUser?.id) || this.aplCategory?.aplCategoryManagers?.some(x => x?.userID === this.currentUser?.id));
  }

  canDelete(): boolean {
    return this.aplGroup.status === 1 && (this.aplGroupDelete || this.aplGroup?.aplGroupManagers?.some(x => x?.userID === this.currentUser?.id) || this.aplCategory?.aplCategoryManagers?.some(x => x?.userID === this.currentUser?.id));
  }

  hasActions(): boolean {
    return this.canApproveOrReject() || this.canEdit() || this.canDelete();
  }

  openApprovalPopup() {
    this.dialog.open(APLGroupApprovalPopupComponent, {
      height: 'auto',
      width: '40vw',
      maxHeight: '90vh',
      data: {
        aplGroup: this.aplGroup.newVersion ? this.aplGroup.newVersion : this.aplGroup,
        user: this.currentUser
      } as APLGroupApprovalPopupParameters,
    }).afterClosed().toPromise().then(data => {
      if (data) {
        this.loading.emit(true);
      }
    });
  }

  openEditPopup() {
    this.dialog.open(APLGroupPopupComponent, {
      height: 'auto',
      width: '40vw',
      maxHeight: '90vh',
      disableClose: true,
      data: {
        aplGroup: this.aplGroup,
        rolesSelected: this.rolesSelected,
      } as APLGroupPopupParameters,
    }).afterClosed().toPromise().then(data => {
      if (data) {
        this.loading.emit(true);
      }
    });
  }

  deleteGroup() {
    this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.labelAreYouSureYouWantToDelete,
      },
    }).afterClosed().subscribe(data => {
      if (data) {
        this.aplGroupService.delete(this.aplGroup?.id).subscribe(
          () => { this.loading.emit(true); },
          error => this.alert.error(error.error),
        );
      }
    });
  }

  getManagers(): string {
    return this.aplGroup?.aplGroupManagers?.filter(m => m.user?.status == 1)?.map(x => x.user?.name).join(', ') ?? '';
  }

  getPTCs(): string {
    return this.aplGroup?.aplProcedureTrainingCoordinators?.filter(m => m.user?.status == 1)?.map(x => x.user?.name).join(', ') ?? '';
  }

  dropRole(event: CdkDragDrop<string[]>) {
    if (this.aplGroup.aplGroupRoles) {
      const from = this.aplGroup.aplGroupRoles[event.previousIndex];
      const to = this.aplGroup.aplGroupRoles[event.currentIndex];
      if (from?.id && to?.id)
        this.aplGroupService.reorderRole(from.id, to.id).subscribe(
          () => { },
          error => this.alert.error(error.error),
        );
      moveItemInArray(this.aplGroup.aplGroupRoles, event.previousIndex, event.currentIndex);
    }
  }

  setLabels() {
    this.labelAreYouSureYouWantToDelete = this.getMessage('APL_Group_Card_AreYouSureYouWantToDelete')?.description;
    this.labelGroupManagers = this.getMessage('APL_Group_Card_GroupManagers')?.description;
    this.labelApproveDisapprove = this.getMessage('APL_Group_Card_ApproveDisapprove')?.description;
    this.labelEdit = this.getMessage('APL_Group_Card_Edit')?.description;
    this.labelDelete = this.getMessage('APL_Group_Card_Delete')?.description;
    this.labelInfo = this.getMessage('APL_Group_Card_Info')?.description;
    this.labelMembers = this.getMessage('APL_Group_Card_Members')?.description;
    this.labelDescription = this.getMessage('APL_Group_Card_Description')?.description;
    this.labelProcedureTrainingCoordinators = this.getMessage('APL_Group_Card_ProcedureTrainingCoordinators')?.description;
  }
}

export class APLGroupCard extends APLGroup {
  public open?: boolean;
}
