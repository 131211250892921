import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { KEResource, KEVersionResource } from '../../ke';
import { KEVersionCreate, KEVersionDelete, KEVersionRefresh, KEVersionRefreshByID, KEVersionUpdate } from './ke-version.action';
import { KEService } from '../../ke.service';
import { KeChecklistBuilderService } from '../../ke-checklist-builder/ke-checklist-builder.service';

export class KEVersionStateModel {
  data: KEVersionResource[] = [];
}

@State<KEVersionStateModel>({
  name: 'KEVersions',
  defaults: {
    data: []
  }
})

@Injectable()
export class KEVersionState {
  constructor(
    private service: KeChecklistBuilderService
  ) { }

  @Selector()
  static read(state: KEVersionStateModel) {
    return state.data;
  }

  @Action(KEVersionRefresh)
  refresh({ patchState }: StateContext<KEVersionStateModel>) {
    this.service.getAll().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(KEVersionRefreshByID)
  refreshByID({ getState, patchState }: StateContext<KEVersionStateModel>, { id }: KEVersionRefreshByID) {
    this.service.getByID(id).subscribe(data => {
      const state = getState();
      const index = state.data.map(x => x.id).indexOf(id);

      if (index >= 0) {
        state.data.splice(index, 1);
      }
      patchState({
        data: [...state.data, data]
      });
    },
      error => console.error(error)
    );
  }

  // @Action(KEVersionCreate)
  // create({ getState, patchState }: StateContext<KEVersionStateModel>, { item }: KEVersionCreate) {
  //   patchState({
  //     data: [...getState().data, item]
  //   });
  // }

  // @Action(KEVersionUpdate)
  // update({ getState, patchState }: StateContext<KEVersionStateModel>, { id, item }: KEVersionUpdate) {
  //   const state = getState();
  //   const index = state.data.map(x => x.id).indexOf(id);

  //   if (index >= 0) {
  //     state.data.splice(index, 1);
  //     patchState({
  //       data: [...state.data, item]
  //     });
  //   }
  // }

  // @Action(KEVersionDelete)
  // delete({ getState, patchState }: StateContext<KEVersionStateModel>, { id }: KEVersionDelete) {
  //   patchState({
  //     data: getState().data.filter(x => x.id != id)
  //   });
  // }
}
