import { Component, ViewChild, ViewChildren, ViewContainerRef, QueryList, Output, EventEmitter, HostListener, OnInit, AfterViewInit, OnDestroy, Injector, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RSSWAResource } from '../rsswa';
import { RsswaService } from '../rsswa.service';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormType, FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { RSSWAUpdate } from '../store/rsswa.action';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'rsswa-side-panel',
  templateUrl: './rsswa-side-panel.component.html',
  styleUrls: ['./rsswa-side-panel.component.scss']
})
export class RsswaSidePanelComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  public displayedColumns: string[] = ['serialNo', 'affectedSystemsNames', 'statusName'];
  public dataSource = new MatTableDataSource<RSSWAResource>();

  filter?: string | null;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChildren('matrow', { read: ViewContainerRef }) rows!: QueryList<ViewContainerRef>;

  public serialNo!: string;
  public selectedRowIndex = -1;
  public showClosed = false;

  public allowNew!: boolean | null;
  public disabled = false;

  maxHeight!: string;

  @Output() rowSelected = new EventEmitter<any>();
  @Output() newDocument = new EventEmitter<any>();
  @Output() requestClear = new EventEmitter<any>();
  @Output() loading = new EventEmitter<any>();

  rsswa?: RSSWAResource;
  rsswas?: RSSWAResource[];
  rsswasFiltered?: RSSWAResource[];
  rsswas$!: Observable<RSSWAResource[]>;
  rsswasSubs!: Subscription;

  constructor(
    private service: RsswaService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.loadRSSWAs();
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngAfterViewInit() {
  }

  loadRSSWAs() {
    this.loading.emit(true);
    this.rsswas$ = this.store.select(state => state.RSSWA.data);
    this.rsswasSubs = this.rsswas$.subscribe(async data => {
      if (data?.length) {
        this.rsswas = data;
        const openDocument = await this.checkOpenDocument<RSSWAResource>(FormType.RSSWA, data);
        if (openDocument) {
          if (openDocument.closed)
            this.showClosed = openDocument.closed;
          if (this.rsswa?.id == openDocument.document.id)
            openDocument.document = this.rsswa;
          await this.getValues(openDocument.document, this.rsswa?.retrievedBy != this.currentUser?.id);
        }
        else this.loading.emit(false);
        this.rsswasFiltered = data.filter(r => !this.showClosed ? (r?.status != FormStatusEnum.Closed && r?.status != FormStatusEnum.Canceled) : true).sort((a, b) => utils.sort(a.serialNo, b.serialNo, false));
        this.dataSource = new MatTableDataSource(this.rsswasFiltered);
        this.setSort<RSSWAResource>(this.dataSource, this.sort);
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
    if (this.innerHeight > 500) {
      this.maxHeight = (this.innerHeight - 130) + 'px';
    } else { this.maxHeight = (this.innerHeight * 2) + 'px'; }
  }

  async getValues(row?: RSSWAResource, refresh: boolean = true) {
    const user = this.getCurrentUser();
    if (await this.canDeactivate())
      if (!this.disabled && row) {
        this.disabled = true;
        this.loading.emit(true);
        this.selectedRowIndex = row?.id;
        this.setOpenDocument(FormType.RSSWA, row.id);
        if (!row?.wfTable || row.retrievedBy != user?.id)
          row = (await this.service.getById(row.id).toPromise()) as RSSWAResource;
        if (row) {
          if (refresh) {
            row.retrievedBy = user?.id;
            this.rsswa = row;
            this.store.dispatch(new RSSWAUpdate(row.id, row));
          }
          this.rowSelected.emit(row);
          this.showElement(row);
          setTimeout(() => {
            this.disabled = false;
            this.loading.emit(false);
          }, 100);
        }
      }
  }

  //#region FILTERS

  applyFilter(e: any) {
    const filterValue = e;
    this.filter = filterValue;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  //#region TableScroll
  showElement(rsswa?: RSSWAResource) {
    this.setHeights();
    const indexstr = this.dataSource.data?.findIndex(r => r.id == rsswa?.id).toString();
    const row = this.rows.find(row => row.element.nativeElement.getAttribute('position') === indexstr);
    if (row) {
      const rect = row.element.nativeElement.getBoundingClientRect();
      if ((rect.y <= 0) || ((rect.y + rect.height) > 7)) {
        row.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
      }
      return;
    }
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex(r => {
      return r.id == row.id;
    });
    return i;
  }
  //#endregion

}
