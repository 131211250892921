import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, Injector } from '@angular/core';
import { ReviewsActionItemTypesComponent } from './reviews-action-item-types/reviews-action-item-types.component';
import { ReviewsActionItemsDetailsComponent } from './reviews-action-items-details/reviews-action-items-details.component';
import { ReviewActionItem, Review, ReviewActionItemType } from '../reviews.models';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Observable, Subscription } from 'rxjs';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { Checklist } from '../../checklists/checklists';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { ReviewActionItemsRefresh } from '../store/review-action-items/review-action-items.action';
import { ReviewsRefreshById } from '../store/reviews/reviews.action';
import { ReviewsService } from '../reviews.service';

@Component({
  selector: 'reviews-action-items',
  templateUrl: './reviews-action-items.component.html',
  styleUrls: ['./reviews-action-items.component.scss']
})
export class ReviewsActionItemsComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() review?: Review;
  @Input() canDelete?: boolean;

  actionItemTypes!: ReviewActionItemType[];
  actionItemTypesFiltered!: ReviewActionItemType[];
  actionItemTypes$!: Observable<ReviewActionItemType[]>;
  actionItemTypesSubs!: Subscription;

  checklists!: Checklist[];
  checklists$!: Observable<Checklist[]>;
  checklistsSubs!: Subscription;

  actionItems!: ReviewActionItem[];
  actionItems$!: Observable<ReviewActionItem[]>;
  actionItemsSubs!: Subscription;

  public displayedColumns!: string[];
  public dataSources: any[] = [];

  selectedRowIndex!: number;

  constructor(
    protected override injector: Injector,
    private router: Router,
    private reviewsService: ReviewsService
  ) {
    super(injector);

  }

  override ngOnDestroy(): void {
    this.actionItemTypesSubs?.unsubscribe();
    this.checklistsSubs?.unsubscribe();
    this.actionItemsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.displayedColumns = this.canDelete ? ['status', 'topic', 'responsible', 'startDate', 'endDate', 'taskNo', 'action'] : ['status', 'topic', 'responsible', 'startDate', 'endDate', 'taskNo'];
    this.loadActionItems();
    this.loadActionItemTypes();
    this.loadChecklists();
  }

  loadChecklists() {
    this.checklists$ = this.store.select(state => state.Checklists.data);
    this.checklistsSubs = this.checklists$.subscribe(data => {
      this.checklists = data;
    });
  }

  loadActionItemTypes() {
    this.actionItemTypes$ = this.store.select(state => state.ReviewActionItemTypes.data);
    this.actionItemTypesSubs = this.actionItemTypes$.subscribe(data => {
      this.actionItemTypes = data?.sort((a, b) => a.order - b.order);
      this.filterActionItemTypes();
    });
  }

  loadActionItems() {
    // this.actionItems = this.review.reviewActionItems;
    this.actionItems$ = this.store.select(state => state.ReviewActionItems.data);
    this.actionItemsSubs = this.actionItems$.subscribe(data => {
      this.actionItems = data?.filter(x => x.reviewID == this.review?.id);
      this.filterActionItemTypes();
    });
  }

  filterActionItemTypes() {
    this.actionItemTypesFiltered = this.actionItemTypes?.filter(x => this.actionItems?.map(a => a.reviewActionItemTypeID).includes(x.id));
    this.dataSources = [];
    this.actionItemTypesFiltered?.map(actionItemType => {
      const dataSource = new MatTableDataSource<ReviewActionItem>(this.actionItems.filter(x => x.reviewActionItemTypeID == actionItemType.id).sort((a, b) => this.utils.sort(a.startDate, b.startDate, true)));
      this.dataSources.push(dataSource);
    });
  }

  manageCategories() {
    const dialog = this.dialog.open(ReviewsActionItemTypesComponent, {
      width: '40%',
      minWidth: '400px',
      data: {

      },
      disableClose: true,
      autoFocus: false
    });
    dialog.afterClosed().subscribe(data => {

    });
  }

  addActionItem() {
    const reviewActionItem = {
      id: 0,
      review: this.review,
      reviewID: this.review?.id,
      description: null,
      name: null,
      status: 1
    } as ReviewActionItem;
    this.openActionItemDetail(reviewActionItem);
  }

  getClass(e: ReviewActionItem) {
    if (e.status == 0)
      return 'gray';
    if (e.reviewActionItemType?.isStopper)
      return 'red';
    return '';
  }

  rowClicked(row: ReviewActionItem) {
    this.openActionItemDetail(row);
  }

  openActionItemDetail(reviewActionItem: ReviewActionItem) {
    const dialog = this.dialog.open(ReviewsActionItemsDetailsComponent, {
      width: '80%',
      minWidth: '500px',
      maxHeight: '80%',
      data: {
        reviewActionItem,
        wfTaskLocal: reviewActionItem.wfTaskLocal
      },
      disableClose: true,
      autoFocus: false
    });
    dialog.afterClosed().subscribe(data => {
    });
  }

  getEndDate(endDate: Date) {
    return endDate != null && endDate.toString() != '0001-01-01T00:00:00' ? endDate : null;
  }

  getTaskName(task: WfTaskLocalResource) {
    if (task) {
      return task.wfStepLocal?.wfSectionLocal.name + ' > ' + task.wfStepLocal?.name + ' > ' + task.name + (task.name3 ? ' ' + task.name3 : '');
    }
    return '';
  }

  linkToTask(e: any, task: WfTaskLocalResource) {
    e.stopPropagation();
    const checklist = this.checklists.find(x => x.id == this.review?.checklistID);
    if (checklist)
      this.openChecklist(checklist, task);
  }

  openChecklist(checklist: Checklist, task: WfTaskLocalResource) {
    const newRelativeUrl = this.router.createUrlTree(['/checklist-exec/200'], { queryParams: { id: checklist.id, task: task.id } });
    const baseUrl = window.location.href.replace(this.router.url, '');
    window.open(baseUrl + newRelativeUrl, '_blank');
  }

  deleteActionItem(reviewActionItem: ReviewActionItem) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage("ReviewDeleteActionItem").description,
        icon: 'warn'
      }
    });
    confirm.afterClosed().subscribe(res => {
      if (res) {
        this.reviewsService.deleteReviewActionItem(reviewActionItem.id).toPromise().then(() => {
          this.alert.message('ReviewActionItem_Deleted');
          if (this.review)
            this.store.dispatch(new ReviewsRefreshById(this.review.id));
          this.store.dispatch(new ReviewActionItemsRefresh());
          this.loadActionItems();
        }).catch(() => {
          this.alert.defaultError();
        });
      }
    });
  }

}
