<div class="card">
  <div class="card-header">
    <span class="card-title">{{ inputData.dialogTitle }}</span>
  </div>
  <div class="card-body">
    <form [formGroup]="noteForm">
      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label> {{ inputData.inputLabel }} </mat-label>
            <textarea
              matInput
              [(ngModel)]="inputData.noteDescription"
              rows="5"
              placeholder="{{ inputData.inputLabel }}"
              formControlName="note"
            ></textarea>
            <mat-error *ngIf="note?.invalid">{{ getErrorMsg(note) }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button mat-flat-button color="primary" (click)="add()">Add</button>
    </div>
  </div>
</div>
