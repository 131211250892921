import { Review, ReviewTypesEnum, SaveReview, ReviewStatusAction, ReviewActionItem, ReviewType, ReviewStatus } from 'src/app/components/reviews/reviews.models';
import { ReviewStatusEnum } from './../../../reviews.models';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy, AfterViewInit, Injector } from '@angular/core';
import { ReviewsBoardsReviewsDetailsTemplateComponent } from './reviews-details-template/reviews-details-template.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormControl } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { StatusType, FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { ChecklistBuilderService } from 'src/app/components/checklists/checklist-builder/checklist-builder.service';
import { Checklist, ChecklistTemplate } from 'src/app/components/checklists/checklists';
import { CancelDialogComponent } from 'src/app/controls/cancel-dialog/cancel-dialog.component';
import { CommentsAndAttachmentsComponent } from 'src/app/controls/comments-and-attachments/comments-and-attachments.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { CommentResource } from 'src/app/controls/comments-and-attachments/services/comment';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { ReviewsService } from '../../../reviews.service';
import { ReviewCyclesRefresh } from '../../../store/review-cycles/review-cycles.action';
import { ReviewsBoardsReviewsMembersComponent } from './reviews-boards-reviews-members/reviews-members.component';
import { ReviewsActionItemsComponent } from '../../../reviews-action-items/reviews-action-items.component';
import { ReviewAttachmentDocumentsComponent } from '../../../reviews-attachments-documents/review-attachment-documents/review-attachment-documents.component';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
@Component({
  selector: 'reviews-boards-reviews-details',
  templateUrl: './reviews-boards-reviews-details.component.html',
  styleUrls: ['./reviews-boards-reviews-details.component.scss']
})
export class ReviewsBoardsReviewsDetailsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() review?: Review;
  @Output() reviewUpdated = new EventEmitter<Review | null>();

  @ViewChild(CommentsAndAttachmentsComponent) commentsAndAttachments!: CommentsAndAttachmentsComponent;
  @ViewChild(ReviewsBoardsReviewsDetailsTemplateComponent) reviewDetailsTemplate!: ReviewsBoardsReviewsDetailsTemplateComponent;
  @ViewChild(ReviewsActionItemsComponent) reviewActionItems!: ReviewsActionItemsComponent;
  @ViewChild(ReviewsBoardsReviewsMembersComponent) reviewMembers?: ReviewsBoardsReviewsMembersComponent;
  @ViewChild(ReviewAttachmentDocumentsComponent) reviewAttachments!: ReviewAttachmentDocumentsComponent;

  disabled = false;
  step = 0;

  reviewTypes!: ReviewType[];
  reviewTypes$!: Observable<ReviewType[]>;
  reviewTypesSubs!: Subscription;

  comments!: CommentResource[];
  commentsTextSanitized!: SafeHtml;
  serialNo!: string;

  isTemplateEditing!: boolean;

  privilege = PrivilegeEnum;
  dateEditing!: boolean;

  reviews$!: Observable<Review[]>;
  reviews!: Review[];
  reviewsSubs!: Subscription;

  actionItems!: ReviewActionItem[];
  actionItems$!: Observable<ReviewActionItem[]>;
  actionItemsSubs!: Subscription;

  reviewTypesEnum = ReviewTypesEnum;
  reviewDateTimeCtrl = new FormControl();
  reviewStatusEnum = ReviewStatusEnum;
  statusType = StatusType;

  reviewStatuses!: ReviewStatus[];
  reviewStatusesFiltered!: ReviewStatus[];
  reviewStatuses$!: Observable<ReviewStatus[]>;
  reviewStatusesSubs!: Subscription;

  hasStopper!: boolean;

  title?: string;

  checklists?: Checklist[];
  checklistsFiltered?: Checklist[];
  checklists$!: Observable<Checklist[]>;
  checklistsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    public service: ReviewsService,
    private checklistBuilderService: ChecklistBuilderService,
    private router: Router,
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.loadComments();
  }

  override ngOnDestroy(): void {
    this.reviewsSubs?.unsubscribe();
    this.actionItemsSubs?.unsubscribe();
    this.reviewTypesSubs?.unsubscribe();
    this.reviewStatusesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.title = this.review?.name ?? '';
    this.loadActionItems();
    this.loadReviews();
    this.loadReviewTypes();
    this.loadReviewStatuses();
    this.loadChecklists();
  }

  loadChecklists() {
    this.checklists$ = this.store.select(state => state.Checklists.data);
    this.checklistsSubs = this.checklists$.subscribe(data => {
      if (data?.length) {
        this.checklists = data;
        if (this.review?.checklistID) {
          const checklist = data.find(c => c.id == this.review?.checklistID);
          if (checklist) {
            if (this.review.checklist.checklistStatusID != checklist.checklistStatusID) {
              this.alert.message('formStateChanged', [new MessagePlaceholder('{serialNo}', checklist.serialNo), new MessagePlaceholder('{status}', checklist.checklistStatus?.name)]);
            }
            this.review.checklist = checklist;
          }
        }
      }
    });
  }

  checkPrivilege(privilege: PrivilegeEnum) {
    return this.service.checkPrivilege(privilege, this.review?.reviewType?.reviewBoardType, this.review?.reviewStatus) && (this.review?.reviewStatusID != ReviewStatusEnum.Completed && this.review?.reviewStatusID != ReviewStatusEnum.Canceled);
  }

  checkActionsRoles() {
    const actionRoles: number[] = [];
    this.review?.reviewStatus?.reviewStatusActions?.map(a => {
      a.reviewActionRoles.map(r => {
        actionRoles.push(r.roleID);
      });
    });
    const userRoles = this.currentUser?.userRole?.map(x => x.roleID);
    return this.utils.intersect(actionRoles, userRoles);
  }

  loadActionItems() {
    this.actionItems$ = this.store.select(state => state.ReviewActionItems.data);
    this.actionItemsSubs = this.actionItems$.subscribe(data => {
      this.actionItems = data;
      if (this.review) {
        this.review.reviewActionItems = this.actionItems.filter(x => x.reviewID == this.review?.id && x.status == 1);
      }
    });
  }

  loadReviews() {
    this.reviews$ = this.store.select(state => state.Reviews.data);
    this.reviewsSubs = this.reviews$.subscribe(data => {
      this.reviews = data;
      if (this.review) {
        this.review.reviewCycle.createdByUser = this.users.find(u => u.id == this.review?.reviewCycle.createdBy);
        this.review = this.reviews.find(x => x.id == this.review?.id);
        this.loadActionItems();
        this.loadComments();
        this.loadReviewStatuses();
        this.hasStopper = this.review?.reviewActionItems.some(x => x.reviewActionItemType?.isStopper) ?? false;
      }
    });
  }

  loadReviewTypes() {
    this.reviewTypes$ = this.store.select(state => state.ReviewTypes.data);
    this.reviewTypesSubs = this.reviewTypes$.subscribe(data => {
      this.reviewTypes = data;
    });
  }

  loadReviewStatuses() {
    this.reviewStatuses$ = this.store.select(state => state.ReviewStatus.data);
    this.reviewStatusesSubs = this.reviewStatuses$.subscribe(data => {
      if (data?.length) {
        this.reviewStatuses = data;
        if (this.review) this.getActions();
      }
    });
  }

  getActions() {
    this.review?.reviewStatus.reviewStatusActions.map(a => {
      a.reviewStatus = this.reviewStatuses.find(s => s.id == a.reviewStatusID);
      a.nextReviewStatus = this.reviewStatuses.find(s => s.id == a.nextReviewStatusID);
    });
  }

  loadComments() {
    this.serialNo = this.review?.reviewCycle.serialNo + '-' + this.review?.code + '-' + this.review?.reviewType?.code + '-' + this.review?.id;
    this.commentsAndAttachments?.refresh();
  }

  label() {
    return this.review?.reviewCycle.type == ReviewTypesEnum.MODF ? this.review.reviewCycle.reviewBoard.resource?.name?.substr(0, 1) + 'MR' : null;
  }

  openChecklistTemplate(e: any, checklistTemplate: ChecklistTemplate) {
    e.stopPropagation();
    this.checklistBuilderService.currentTemplateType = checklistTemplate.templateType;
    this.checklistBuilderService.setChecklistTemplate(checklistTemplate);
    const newRelativeUrl = this.router.createUrlTree(['/checklist-builder/200'], { queryParams: { id: checklistTemplate.id } });
    const baseUrl = window.location.href.replace(this.router.url, '');
    window.open(baseUrl + newRelativeUrl, '_blank');
    return false;
  }

  executeAction(actionId: number, review: Review | undefined, status: ReviewStatusEnum) {
    this.service.loading = true;
    const reviewStatusAction = this.review?.reviewStatus.reviewStatusActions.find(x => x.id == actionId);
    if (reviewStatusAction?.code && review) {
      switch (reviewStatusAction.code) {
        case '@request':
          // this.requestExtension(review, status);
          break;
        case '@grant':
          // this.grantRejectExtension(review, status, true);
          break;
        case '@reject':
          // this.grantRejectExtension(review, status, false);
          break;
      }
    }
    else {
      if (review)
        if (status != ReviewStatusEnum.Canceled) {
          this.setStatus(review, status);
        }
        else {
          this.cancelReview(review);
        }
    }
  }

  setStatus(review: Review, status: ReviewStatusEnum) {
    this.service.loading = true;
    const statusToChange = this.reviewStatuses.find(x => x.id == status);
    if (statusToChange) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message: this.getMessage('ReviewStatusChangeConfirmation').description.replace('{status}', statusToChange.name ?? ''),
          icon: 'stop'
        }
      });
      confirm.afterClosed().subscribe(data => {
        if (data) {
          this.updateReview(review, status);
        }
        else {
          this.service.loading = false;
        }
      });
    }
  }

  async updateReview(review: Review, status: ReviewStatusEnum) {
    const saveReview: SaveReview = this.utils.Serialize(review);
    saveReview.reviewStatusID = status;
    await this.service.updateReview(saveReview).toPromise().then(data => {
      this.review = data;
      this.service.loading = false;
      if (status != this.review?.reviewStatusID)
        this.alert.message('ReviewStatusChanged', [{ placeholder: '{status}', value: this.review?.reviewStatus.name }]);
      this.reviewUpdated.emit(this.review);
      if (this.review?.reviewStatus.type == StatusType.Closed && this.review.reviewType.nextReviewTypeID) {
        this.alert.message('ReviewCompleted', [{ placeholder: '{name}', value: this.review.name }]);
        this.createNextReview();
      }
    });
  }

  cancelReview(review: Review) {
    const confirm = this.dialog.open(CancelDialogComponent, {
      width: '600px',
      data: {
      }
    });
    confirm.afterClosed().subscribe(async data => {
      if (data?.accept) {
        this.service.loading = true;
        if (review.reviewStatusID == ReviewStatusEnum.Draft) {
          await this.service.deleteReview(review.id).toPromise().then(() => {
            this.alert.message('ReviewCanceled');
            this.reviewUpdated.emit(null);
            this.service.loading = false;
          });
        }
        else {
          review.comments = data.text;
          await this.updateReview(review, ReviewStatusEnum.Canceled).then(() => {
            this.service.loading = false;
          });
        }
      }
    });
  }

  createNextReview() {
    const reviewType = this.reviewTypes.find(x => x.id == this.review?.reviewType.nextReviewTypeID);
    const reviewBoard = this.review?.reviewCycle.reviewBoard;
    const review = {
      id: 0,
      name: reviewType?.name + ' for ' + reviewBoard?.resource?.name,
      description: (this.review?.code == ReviewTypesEnum[ReviewTypesEnum.FULL] ? 'Full' : 'Modification') + ' ' + reviewType?.name + ' for ' + reviewBoard?.resource?.name,
      reviewTypeID: reviewType?.id,
      reviewCycleID: this.review?.reviewCycleID,
      checklistTemplateID: null,
      reviewDate: new Date(),
      status: FormStatusEnum.Draft,
      type: this.review?.code == ReviewTypesEnum[ReviewTypesEnum.FULL] ? ReviewTypesEnum.FULL : ReviewTypesEnum.MODF,
      code: ReviewTypesEnum[this.review?.code == ReviewTypesEnum[ReviewTypesEnum.FULL] ? ReviewTypesEnum.FULL : ReviewTypesEnum.MODF],
      previousReviewID: this.review?.id
    };
    this.service.createReview(review).toPromise().then(reviewCreated => {
      this.alert.message('ReviewCreatedAutomatically', [{ placeholder: '{name}', value: reviewCreated?.name }]);
    });
  }

  checkButtonsPriv(action: ReviewStatusAction) {
    return this.hasRoles(action?.reviewActionRoles?.map(x => x.roleID));
  }

  createChecklist() {
    this.reviewDetailsTemplate.isChecklistEditing = false;
    this.reviewDetailsTemplate.createChecklist();
  }

  selectChecklist() {
    this.reviewDetailsTemplate.isChecklistEditing = true;
  }

  changeDate(review?: Review) {
    this.dateEditing = true;
    this.reviewDateTimeCtrl.setValue(review?.reviewDate);
  }

  dateChanged() {
    return this.review?.reviewDate == this.reviewDateTimeCtrl.value;
  }

  saveDate() {
    this.service.loading = true;
    if (this.review)
      this.review.reviewDate = this.reviewDateTimeCtrl.value;
    this.dateEditing = false;
    const review = this.utils.Serialize(this.review);
    if (review)
      this.service.updateReview(review).toPromise().then(data => {
        this.review = data;
        this.store.dispatch(new ReviewCyclesRefresh());
        this.alert.message('ReviewDateUpdated');
        this.service.loading = false;
      });
    this.service.loading = false;
  }

  getActionButtonColor(action: any, disabled: boolean) {
    if (!disabled) {
      return action.color + '-background';
    }
    return '';
  }

  copyEmails(review?: Review) {
    //filter out guests
    const emails = review?.reviewAttendanceRosters.filter(r => r.roleID != -1).map(r => r.user?.emailAddress).join(", ");
    navigator.clipboard.writeText(emails ?? "").then(() => {
      alert("Emails copied to clipboard!");
    }).catch(err => {
      console.error("Could not copy text: ", err);
    });
  }

  rosterCheck(review?: Review) {
    return review?.reviewAttendanceRosters?.some(r => r.roleID != -1);
  }

}
