import { Component, Input, ViewChild, ElementRef, OnChanges, Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialogConfig } from '@angular/material/dialog';
import { ActionItemEditComponent } from './action-item-edit/action-item-edit.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ActionItem } from 'src/app/common/models/action-item';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';
import { ActionItemService } from './action-item.service';
import { ActionItemsDelete } from './store/actionItems.action';

@Component({
  selector: 'action-item',
  templateUrl: './action-item.component.html',
  styleUrls: ['./action-item.component.scss']
})
export class ActionItemComponent extends BaseComponent implements OnChanges {

  @Input() disabled?: boolean;
  @Input() formType?: number | string;
  @Input() formId?: number;
  @Input() sectionNumber?: number;
  @Input() sectionId?: number;
  @Input() taskId?: number;
  @Input() serialNo?: string;

  /*--- Values response ------------*/
  @Input()
  actionItem!: ActionItem;

  /*------- Control variables ------*/
  label = 'Action Item Description';
  filteredUsers!: User[];

  textValue?: string | null;
  textValueSanitized!: SafeHtml;
  ownerValue!: Owner;
  locationValue?: string;
  radioValue?: number;

  tmpText?: string | null;
  loading!: boolean;

  /*--------------------------------*/

  radioOptions: any[] = [{ value: 30, label: '30 days' }, { value: 60, label: '60 days' }, { value: 90, label: '90 days' }];
  /** Form Controls **/
  public form!: FormGroup;
  ownerCtrl = new FormControl();
  locationCtrl = new FormControl();
  radioCtrl = new FormControl();
  quillCtrl = new FormControl();

  @ViewChild('autoOwner')
  matAutocompleteLoc!: MatAutocomplete;
  @ViewChild('ownerInput')
  ownerInput!: ElementRef<HTMLInputElement>;
  @ViewChild('ownerInput', { read: MatAutocompleteTrigger })
  ownerTrigger!: MatAutocompleteTrigger;

  delayTimer: any;


  /** action-item ctor */
  constructor(
    protected override injector: Injector,
    private _action: ActionItemService,
    private sanitizer: DomSanitizer,
  ) {
    super(injector);
  }

  ngOnChanges() {
    this.buildForm();
    this.setValues();
  }

  buildForm() {
    this.form = new FormGroup({
      ownerCtrl: this.ownerCtrl,
      locationCtrl: this.locationCtrl,
      radioControl: this.radioCtrl,
      quillCrtl: this.quillCtrl
    });
  }

  setValues() {
    if (this.actionItem) {
      this.textValue = this.actionItem.description;
      this.tmpText = this.textValue;
      this.textValueSanitized = this.sanitizer.bypassSecurityTrustHtml(this.actionItem.description ? this.actionItem.description : '');
      if (!this.textValue && this.disabled) {
        this.textValue = 'Action Item Description';
      }
      this.ownerValue = { id: this.actionItem.ownerUserId, name: this.actionItem.ownerName };
      this.locationValue = this.actionItem.location;
      this.radioValue = this.actionItem.duePeriod;

      this.ownerCtrl.setValue(this.ownerValue?.name);
      this.locationCtrl.setValue(this.locationValue);
      this.radioCtrl.setValue(this.radioValue);
    }
    this.form.disable();
  }

  displayOwner(e: any) {
    return e ? e.name : null;
  }

  edit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      actionItem: this.actionItem,
      formType: this.formType,
      formId: this.formId,
      serialNo: this.serialNo,
      sectionNumber: this.sectionNumber,
      sectionId: this.sectionId,
      taskId: this.taskId
    };
    dialogConfig.width = '80vw';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ActionItemEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {

      }
    });
  }

  del() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('ActionItemDeleteConfirmation').description,
        icon: 'warn'
      }
    });
    confirm.afterClosed().toPromise().then(async data => {
      if (this.actionItem.id) {
        await this._action.deleteActionItem(this.actionItem.id).toPromise();
        this.store.dispatch(new ActionItemsDelete(this.actionItem.id));
        this.alert.info(this.getMessage('ActionItemDeleted').description);
      }
    }
    );
  }
}

class Owner {
  id?: number;
  name?: string;
}
