import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { APLCategory } from 'src/app/components/apl/services/apl-category.model';
import { MessageBanner } from 'src/app/components/messages/services/message-banner';
import { messages } from 'src/environments/messages';
import { APLCategoryService } from '../../../apl-category.service';

export class APLCategoryPopupParameters {
  public aplCategory!: APLCategory;
}

@Component({
  selector: 'apl-category-popup',
  templateUrl: './apl-category-popup.component.html',
  styleUrls: ['./apl-category-popup.component.scss']
})
export class APLCategoryPopupComponent extends BaseComponent implements OnInit, OnDestroy {
  aplCategory: APLCategory = new APLCategory();
  loading: boolean = false;
  title!: string;

  labelCreateCategory!: string;
  labelEditCategory!: string;
  labelName!: string;
  labelInitials!: string;
  labelManager!: string;
  labelCancel!: string;
  labelAccept!: string;

  constructor(
    protected override injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    public data: APLCategoryPopupParameters,
    private dialogRef: MatDialogRef<APLCategoryPopupComponent>,
    private aplCategoryService: APLCategoryService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.setLabels();
    if (this.data.aplCategory) {
      this.aplCategory = this.utils.cloneDeep(this.data.aplCategory);
      this.aplCategory.previousVersionID = this.aplCategory.id;
      this.aplCategory.aplCategoryManagers = this.aplCategory.aplCategoryManagers.filter(m => m.user?.status == 1);
      this.aplCategory.id = 0;
    }
  }

  setLabels() {
    this.labelCreateCategory = this.getMessage('APL_Category_CreateCategory')?.description;
    this.labelEditCategory = this.getMessage('APL_Category_EditCategory')?.description;
    this.labelName = this.getMessage('APL_Category_Name')?.description;
    this.labelInitials = this.getMessage('APL_Category_Initials')?.description;
    this.labelManager = this.getMessage('APL_Category_Manager')?.description;
    this.labelCancel = this.getMessage('APL_Category_Cancel')?.description;
    this.labelAccept = this.getMessage('APL_Category_Accept')?.description;
    this.title = (!this.data?.aplCategory) ? this.labelCreateCategory : this.labelEditCategory;

  }

  submit() {
    this.loading = true;
    this.aplCategoryService.create(this.aplCategory).subscribe(
      () => this.dialogRef.close(),
      error => {
        this.alert.defaultError();
        this.loading = false;
      },
    );
  }

  cancel() {
    this.dialogRef.close();
  }

}
