import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CommentCreate, CommentDelete, CommentRefresh, CommentRefreshByID, CommentUpdate } from './comments.action';
import { CommentResource } from 'src/app/controls/comments-and-attachments/services/comment';
import { CommentService } from 'src/app/controls/comments-and-attachments/services/comments.service';


export class CommentStateModel {
  data: CommentResource[] = [];
}

@State<CommentStateModel>({
  name: 'Comments',
  defaults: {
    data: []
  }
})

@Injectable()
export class CommentState {
  constructor(
    private service: CommentService
  ) { }

  @Selector()
  static read(state: CommentStateModel) {
    return state.data;
  }

  @Action(CommentRefresh)
  refresh({ patchState }: StateContext<CommentStateModel>) {
    this.service.Read().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(CommentRefreshByID)
  refreshByID({ getState, patchState }: StateContext<CommentStateModel>, { id }: CommentRefreshByID) {
    this.service.ReadOne(id).subscribe(
      item => {
        const state = getState();
        const index = state.data.map(x => x.id).indexOf(id);
        if (index >= 0) {
          state.data.splice(index, 1);
        }
        patchState({
          data: [...state.data, item]
        });
      },
      error => console.error(error)
    );
  }

  @Action(CommentCreate)
  create({ getState, patchState }: StateContext<CommentStateModel>, { item }: CommentCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(CommentUpdate)
  update({ getState, patchState }: StateContext<CommentStateModel>, { id, item }: CommentUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(CommentDelete)
  delete({ getState, patchState }: StateContext<CommentStateModel>, { id }: CommentDelete) {
    patchState({
      data: getState().data.filter(x => x.id != id)
    });
  }

}
