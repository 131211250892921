<div class="card">
  <div class="card-header">
    <span class="card-title">{{ shieldingId ? "Edit" : "Add" }} Shielding</span>
  </div>
  <div class="card-body">
    <mat-form-field>
      <mat-label>Shielding Name</mat-label>
      <input
        matInput
        [(ngModel)]="shieldingName"
        type="text"
        placeholder="Shielding Name"
        [disabled]="loading"
        [matAutocomplete]="auto"
        (keyup)="filter($event)"
        [ngModelOptions]="{ standalone: true }"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let shielding of shieldingsFiltered"
          [value]="shielding.name"
          (onSelectionChange)="getInfoShielding(shielding)"
        >
          {{ shielding.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="no-subscript">
      <mat-label>KE Text</mat-label>
      <textarea
        matInput
        [(ngModel)]="shieldingKeText"
        rows="5"
        placeholder="KE Text"
        [disabled]="loading"
        [ngModelOptions]="{ standalone: true }"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="isSaving"
        (click)="add()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
