import { MessageBanner } from "../services/message-banner";


export class GetAll {
  static readonly type = '[Message Banner] - Refresh';
}

export class Update {
  static readonly type = '[Message Banner] - Update';
  constructor(public payload: MessageBanner) { }
}

export class Add {
  static readonly type = '[Message Banner] - Add';
  constructor(public payload: MessageBanner) { }
}

export class Delete {
  static readonly type = '[Message Banner] - Delete';
  constructor(public payload: number) { }
}
