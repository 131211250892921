<div class="card">
  <div class="card-header">
    <span class="card-title"
      >{{ dataItem.id ? "Edit " : "Add " }}Toxic Gas</span
    >
  </div>
  <div class="card-body">
    <form [formGroup]="toxicGasForm">
      <mat-form-field>
        <mat-label>Username</mat-label>
        <input
          disabled
          [(ngModel)]="Username"
          [ngModelOptions]="{ standalone: true }"
          matInput
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Date</mat-label>
        <input
          [disabled]="saving"
          required
          matInput
          [(ngModel)]="Date"
          [ngModelOptions]="{ standalone: true }"
          [matDatepicker]="picker"
        />
        <button
          [disabled]="saving"
          mat-button
          *ngIf="Date"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="Date = null"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle
          [disabled]="saving"
          matSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker [disabled]="saving" #picker></mat-datepicker>
        <mat-error *ngIf="Date == null || Date == undefined || !Date"
          >You must enter a date</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label>Location</mat-label>
        <input
          required
          [(ngModel)]="Location"
          formControlName="location"
          matInput
        />
        <mat-error *ngIf="location?.invalid">{{
          getErrorMsg(location)
        }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Gas</mat-label>
        <input required [(ngModel)]="Gas" formControlName="gas" matInput />
        <mat-error *ngIf="gas?.invalid">{{ getErrorMsg(gas) }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Responsible Party</mat-label>
        <input
          [(ngModel)]="ResponsibleParty"
          formControlName="responsibleParty"
          matInput
        />
      </mat-form-field>

      <fieldset class="field-group">
        <legend>Roof Access</legend>
        <mat-radio-group
          required
          class="d-flex pb-2 justify-content-around"
          [(ngModel)]="RoofAccess"
          [ngModelOptions]="{ standalone: true }"
          aria-labelledby="roof-access-label"
          aria-label="Roof Access"
          [value]="RoofAccess"
        >
          <mat-radio-button value="true">OK</mat-radio-button>
          <mat-radio-button value="false">Not OK</mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="RoofAccess?.length == 0 || !RoofAccess"
          >You must select an option</mat-error
        >
      </fieldset>
    </form>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        color="warn"
        type="button"
        (click)="dialogRef.close()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        [disabled]="saving"
        color="primary"
        (click)="save()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
