<div
  [ngClass]="{ 'container-fluid h-200 mt-2': !fullHeight }"
  class="full-width"
  id="scrollableContainer"
  #scrollableContainer
  cdkScrollable
>
  <mat-accordion multi *ngIf="sections?.length" id="sections">
    <ng-container *ngFor="let section of sections">
      <div class="row no-gutters">
        <div class="full-width d-flex">
          <checklist-section
            class="full-width"
            [currentChecklist]="currentChecklist"
            [disabled]="disabled"
            [section]="section"
            [precheck]="precheck ?? false"
            (loading)="loading.emit({ val: $event, l: -1 })"
            (checkShared)="checkSharedComponents($event)"
          ></checklist-section>
        </div>
      </div>
    </ng-container>
    <div class="row mt-3 pb-3">
      <div class="col mx-3">
        <comments-and-attachments
          [currentDocument]="currentChecklist"
          [FormNumber]="currentChecklist?.serialNo"
          [disabled]="disabled"
          headerClass="h5"
        >
        </comments-and-attachments>
      </div>
    </div>
  </mat-accordion>
</div>
