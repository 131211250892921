<div class="card">
  <div class="card-header">
    <span class="card-title"
      >{{ resource.id ? "Edit" : "Add" }} Fast Valve Sensor</span
    >
  </div>
  <div class="card-body">
    <mat-form-field>
      <mat-label>FVS Name</mat-label>
      <input
        matInput
        [(ngModel)]="fvsName"
        placeholder="FVS Name"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>KE Text</mat-label>
      <textarea
        matInput
        [(ngModel)]="fvsKeText"
        rows="5"
        placeholder="KE Text"
        [ngModelOptions]="{ standalone: true }"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="isSaving"
        (click)="add()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
