<div class="d-print-flex">
  <mat-form-field>
    <mat-label>{{ placeholder }}</mat-label>
    <mat-chip-grid #roleChips [disabled]="disabled">
      <mat-chip-row
        *ngFor="let roleRelated of roles"
        [selectable]="true"
        [removable]="true"
        (removed)="remove(roleRelated)"
      >
        {{ roleRelated?.role?.name }}
        <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
      </mat-chip-row>
      <input
        #roleInput
        [formControl]="control"
        [matAutocomplete]="roleAutocomplete"
        [matChipInputFor]="roleChips"
        [matChipInputAddOnBlur]="false"
        (keyup)="onRoleInputKeyup($event)"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #roleAutocomplete="matAutocomplete"
      (optionSelected)="selected($event.option.value)"
    >
      <mat-option *ngFor="let role of filteredRoles" [value]="role">{{
        role?.name
      }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
