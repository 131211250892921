import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'cl-editor-hide-disable-toggle',
  templateUrl: './cl-editor-hide-disable-toggle.component.html',
  styleUrls: ['./cl-editor-hide-disable-toggle.component.scss']
})
export class ClEditorHideDisableToggleComponent implements OnInit, OnChanges {

  @Input() configuration?: string = '';
  @Input() disabled?: boolean;

  @Output() changed = new EventEmitter<string>();

  value?: boolean;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    const configuration = utils.JSONparse(this.configuration);
    this.value = configuration?.hideDisable;
  }

  ngOnInit() {
  }

  change() {
    let configuration = utils.JSONparse(this.configuration);
    if (!configuration) { configuration = {}; }
    configuration.hideDisable = this.value;
    this.changed.emit(utils.JSONstringify(configuration));
  }

}
