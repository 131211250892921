<div
  class="card w-100 p-0 d-print-inline-block"
  *ngIf="!hidden"
  [class.card-disabled]="!enabled"
>
  <div class="card-header p-0 pl-3 pr-2">
    <div class="row pb-0 flex-row flex-nowrap justify-content-between">
      <div class="p-3 d-flex align-items-center">
        <h6>
          <mat-radio-group
            [disabled]="disabled || (showUnapprove && !showApprove)"
            [ngClass]="{ 'd-flex flex-nowrap': !radioQuestion }"
          >
            <mat-radio-button
              class="radio-btn mx-2"
              *ngFor="let r of radioOptions"
              [checked]="r.checked"
              [value]="r.value"
              (change)="radioChange($event)"
            >
              <span
                [innerHTML]="r.label | placeholderHighlight : editor"
              ></span>
            </mat-radio-button>
          </mat-radio-group>
        </h6>
        <role-chips
          class="ml-2"
          *ngIf="roleCodes"
          [roleCodes]="roleCodes"
          [userNames]="userNames"
        ></role-chips>

        <mat-chip-row
          class="small user-name ml-2 mt-1"
          *ngIf="userNames && !value && !roleCodes"
          >{{ userNames }}</mat-chip-row
        >
      </div>
      <div class="d-flex p-1 flex-column d-print-none justify-content-center">
        <button
          mat-flat-button
          class="approval-button"
          *ngIf="
            (showApprove && !checked && buttonEnabled()) ||
            (showUnapprove && checked)
          "
          [disabled]="disabled"
          [color]="color"
          (click)="click()"
          [matTooltip]="getTooltip(value ? 3 : 1)"
          [matTooltipPosition]="'right'"
          matTooltipClass="tooltip-class"
        >
          {{ label }}
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-3 nowrap">By:</div>
      <div class="col-9">
        <span class="name" *ngIf="value">{{ name }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <small class="nowrap">Date:</small>
      </div>
      <div class="col-9">
        <span class="date small" *ngIf="value">{{
          date | date : "medium"
        }}</span>
      </div>
    </div>
  </div>
</div>
