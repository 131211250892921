import { OlogMessage } from "../../services/olog-message";

export class OlogMessageRefresh {
  static readonly type = '[OlogMessage] Refresh';
}

export class OlogMessageCreate {
  static readonly type = '[OlogMessage] Create';
  constructor(public item: OlogMessage) { }
}

export class OlogMessageUpdate {
  static readonly type = '[OlogMessage] Update';
  constructor(public id: number, public item: OlogMessage) { }
}

export class OlogMessageDelete {
  static readonly type = '[OlogMessage] Delete';
  constructor(public id: number) { }
}
