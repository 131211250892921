<div class="row">
  <div class="col-8">
    <mat-radio-group
      aria-label="Select an option"
      [(ngModel)]="optionSelectedId"
      name="opList"
      color="primary"
      class="d-flex"
    >
      <mat-radio-button
        *ngFor="let option of options"
        [value]="option.id"
        (change)="radioChanged($event.value)"
      >
        {{ option.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="col-4">
    <mat-slide-toggle
      color="accent"
      [(ngModel)]="showInactive"
      (change)="changeInactive($event)"
      >Show Inactive Types
    </mat-slide-toggle>
  </div>
</div>

<div class="row" cdkDropList (cdkDropListDropped)="drop($event)">
  <div class="col-12 review-box mb-2" *ngFor="let reviewType of reviewTypes">
    <div cdkDrag class="row">
      <div class="col-1 d-flex align-items-center">
        <mat-icon cdkDragHandle color="accent" class="drag"
          >drag_handle</mat-icon
        >
      </div>
      <div
        class="col-8 d-flex align-items-center"
        [ngClass]="{ red: reviewType.status == 0 }"
      >
        {{ reviewType.order }}. {{ reviewType.name }} ({{ reviewType.code }})
      </div>
      <div class="col-3 justify-content-end d-flex align-items-center">
        <button mat-icon-button (click)="edit(reviewType)">
          <mat-icon color="accent">edit</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="delete(reviewType)"
          [disabled]="(reviewType.reviews?.length ?? 0) > 0"
          color="warn"
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<button mat-flat-button color="primary" (click)="add()">
  <mat-icon>add</mat-icon>Add
</button>
