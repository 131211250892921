import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ReviewAttendanceRosterCreate, ReviewAttendanceRosterDelete, ReviewAttendanceRosterRefresh, ReviewAttendanceRosterUpdate } from './review-attendance-roster.action';
import { ReviewsService } from '../../reviews.service';
import { ReviewAttendanceRoster } from '../../reviews.models';
import { utils } from 'src/app/modules/libs/utils';

export class ReviewAttendanceRosterStateModel {
  reviewAttendanceRosters: ReviewAttendanceRoster[] = [];
}

@State<ReviewAttendanceRosterStateModel>({
  name: 'ReviewAttendanceRosters',
  defaults: {
    reviewAttendanceRosters: []
  }
})
@Injectable()
export class ReviewAttendanceRosterState {
  constructor(
    private reviewsService: ReviewsService
  ) { }

  @Selector()
  static read(state: ReviewAttendanceRosterStateModel) {
    return state.reviewAttendanceRosters;
  }

  @Action(ReviewAttendanceRosterRefresh)
  refresh({ patchState }: StateContext<ReviewAttendanceRosterStateModel>) {
    this.reviewsService.getReviewAttendanceRosters().toPromise().then(data => {
      patchState({ reviewAttendanceRosters: utils.cloneDeep(data) });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(ReviewAttendanceRosterCreate)
  create({ getState, patchState }: StateContext<ReviewAttendanceRosterStateModel>, { item }: ReviewAttendanceRosterCreate) {
    patchState({
      reviewAttendanceRosters: [...getState().reviewAttendanceRosters, item]
    });
  }

  @Action(ReviewAttendanceRosterUpdate)
  update({ getState, patchState }: StateContext<ReviewAttendanceRosterStateModel>, { id, item }: ReviewAttendanceRosterUpdate) {
    const state = getState();
    const index = state.reviewAttendanceRosters.findIndex(x => x.id === id);
    const currentState = utils.cloneDeep(state.reviewAttendanceRosters);

    if (index >= 0) {
      currentState.splice(index, 1, utils.cloneDeep(item));
      patchState({ reviewAttendanceRosters: currentState });
    }
  }

  @Action(ReviewAttendanceRosterDelete)
  delete({ getState, patchState }: StateContext<ReviewAttendanceRosterStateModel>, { id }: ReviewAttendanceRosterDelete) {
    patchState({
      reviewAttendanceRosters: getState().reviewAttendanceRosters.filter(x => x.id !== id)
    });
  }

}
