import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbsiTemplateVersionResource } from '../../absi';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'absi-builder-buttons',
  templateUrl: './absi-builder-buttons.component.html',
  styleUrls: ['./absi-builder-buttons.component.scss']
})
export class AbsiBuilderButtonsComponent extends BaseComponent {
  @Input() version?: AbsiTemplateVersionResource | null;
  @Input() disabled?: boolean;

  @Output() canceled = new EventEmitter<boolean>();
  @Output() approved = new EventEmitter<boolean>();


}
