<div class="container-fluid">
  <form-workflow
    [form]="form"
    sectionTitle="Review & Authorization"
    [sectionNumber]="2"
    [amendmentSectionNumbers]="[12, 22]"
    [leftConfigs]="[58, 60, 62, 64, 66, 68, 74, 76, 78]"
    [rightConfigs]="[59, 61, 63, 65, 67, 69, 75, 77, 79]"
    [disabled]="disabled"
    [showAmendment]="showAmendment"
    (loading)="loading.emit($event)"
  >
  </form-workflow>
</div>
