<div class="card">
  <button
    mat-mini-fab
    class="closex"
    color="warn"
    *ngIf="!loading"
    (click)="close()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div class="card-header">
    <span class="card-title"> {{ title }} </span>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="card-body max-60vh">
    <div
      class="row"
      *ngIf="data?.aplGroup?.previousVersion && data?.aplGroup?.status == -1"
    >
      <div class="col"></div>
      <div class="col-auto">
        <mat-slide-toggle color="accent" [(ngModel)]="showPrevious">
          <span [innerHTML]="labelShowPrevious"></span>
        </mat-slide-toggle>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
        <small
          >Submitted by: <b>{{ submitted?.name }}</b></small
        >
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>
            <span [innerHTML]="labelName"></span>
          </mat-label>
          <input
            aria-label="name"
            matInput
            name="name"
            [disabled]="true"
            [ngModel]="
              showPrevious
                ? data.aplGroup.previousVersion?.name
                : data.aplGroup.name
            "
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div
          class="scrollbar descriptionContainer"
          [innerHTML]="
            showPrevious
              ? data.aplGroup.previousVersion?.description
              : data.aplGroup.description
          "
        ></div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <user-chip
          [placeholder]="labelManagers"
          [disabled]="true"
          [relatedId]="
            (showPrevious
              ? data.aplGroup.previousVersion?.id
              : data.aplGroup.id) ?? 0
          "
          [relatedTag]="'aplGroupID'"
          [selectedUsers]="
            (showPrevious
              ? data.aplGroup.previousVersion?.aplGroupManagers
              : data.aplGroup.aplGroupManagers) ?? []
          "
          (error)="alert.error($event)"
        >
        </user-chip>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <role-chip
          [placeholder]="labelRoles"
          [disabled]="true"
          [relatedId]="
            (showPrevious
              ? data.aplGroup.previousVersion?.id
              : data.aplGroup.id) ?? 0
          "
          [relatedTag]="'aplGroupID'"
          [roles]="
            (showPrevious
              ? data.aplGroup.previousVersion?.aplGroupRoles
              : data.aplGroup.aplGroupRoles) ?? []
          "
          (error)="alert.error($event)"
        >
        </role-chip>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <user-chip
          [placeholder]="labelProcedureTrainingCoordinators"
          [disabled]="true"
          [relatedId]="
            (showPrevious
              ? data.aplGroup.previousVersion?.id
              : data.aplGroup.id) ?? 0
          "
          [relatedTag]="'aplGroupID'"
          [selectedUsers]="
            (showPrevious
              ? data.aplGroup.previousVersion?.aplProcedureTrainingCoordinators
              : data.aplGroup.aplProcedureTrainingCoordinators) ?? []
          "
          (error)="alert.error($event)"
        >
        </user-chip>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        color="warn"
        [disabled]="loading || disableApproveDisapprove"
        (click)="disapprove()"
      >
        <span [innerHTML]="labelDisapprove"></span>
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="loading || disableApproveDisapprove"
        (click)="approve()"
      >
        <span [innerHTML]="labelApprove"></span>
      </button>
      <button
        *ngIf="submitted?.id == currentUser?.id"
        mat-flat-button
        color="accent"
        [disabled]="loading"
        (click)="unSubmit()"
      >
        <span>Unsubmit</span>
      </button>
    </div>
  </div>
</div>
