<div class="card">
  <div class="card-header">
    <span class="card-title">Select Saved Roster</span>
  </div>

  <div class="card-body card-body-scroll">
    <div class="row pb-2">
      <div class="col-12 center">
        <!-- toggle to combine or replace here -->
        <span class="blue pt-1 pr-1" [ngClass]="{ bold: !replace }"
          >Combine Rosters &nbsp;</span
        >
        <!-- TODO need to send replace to reviews-members somehow -->
        <mat-slide-toggle color="warn" [(ngModel)]="replace"
          ><span class="red pl-1" [ngClass]="{ bold: replace }"
            >Replace Roster</span
          ></mat-slide-toggle
        >
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-8">
            <mat-form-field class="no-subscript" appearance="outline">
              <mat-label>Saved Roster</mat-label>
              <input
                matInput
                type="text"
                #listInput
                [formControl]="listCtrl"
                [matAutocomplete]="autoList"
                (keyup)="changedList($event)"
                (blur)="selectList()"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #autoList="matAutocomplete"
                (optionSelected)="selectedList($event)"
                #versionAutocomplete
                [displayWith]="displayObject"
              >
                <mat-option
                  *ngFor="let elem of reviewAttendanceSavedFiltered"
                  [value]="elem"
                >
                  {{ elem.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-4 text-right d-flex align-items-center">
            <button
              mat-icon-button
              color="accent"
              (click)="edit()"
              *ngIf="reviewAttendanceSavedSelected"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              color="warn"
              (click)="delete()"
              *ngIf="reviewAttendanceSavedSelected"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-12">Current Roster</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <load-saved-roster-left
          [reviewAttendanceSave]="reviewAttendanceSavedSelected"
        ></load-saved-roster-left>
      </div>
      <div class="col-6">
        <load-saved-roster-right
          [review]="review"
          [reviewAttendanceSave]="reviewAttendanceSavedSelected"
          [replace]="replace"
          (membersToAddChanged)="membersToAdd($event)"
        ></load-saved-roster-right>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-stroked-button color="warn" (click)="cancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        (click)="load()"
        [disabled]="disabled"
      >
        Load Roster Members into Review
      </button>
    </div>
  </div>
</div>
