import { Restriction } from "src/app/controls/restrictions/services/restriction";

export class RestrictionsRefresh {
  static readonly type = '[Restrictions] Refresh';
}

export class RestrictionsCreate {
  static readonly type = '[Restrictions] Create';

  constructor(public item: Restriction) { }
}

export class RestrictionsUpdate {
  static readonly type = '[Restrictions] Update';

  constructor(public id: number, public item: Restriction) { }
}

export class RestrictionsDelete {
  static readonly type = '[Restrictions] Delete';
  constructor(public id: number) { }
}
