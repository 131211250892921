import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ProcedureTemplateCreate, ProcedureTemplateDelete, ProcedureTemplateRefresh, ProcedureTemplateUpdate } from "./procedure-template.action";
import { ProcedureTemplate } from "../../models/procedure-template.model";
import { ProcedureTemplateService } from "../../services/procedure-template.service";

export class ProcedureTemplateStateModel {
  ProcedureTemplates: ProcedureTemplate[] = [];
}

@State<ProcedureTemplateStateModel>({
  name: 'ProcedureTemplate',
  defaults: {
    ProcedureTemplates: [],
  }
})
@Injectable()
export class ProcedureTemplateState {
  constructor(
    private procedureTemplateService: ProcedureTemplateService,
  ) { }

  @Action(ProcedureTemplateRefresh)
  refresh({ patchState }: StateContext<ProcedureTemplateStateModel>) {
    this.procedureTemplateService.readProcedureTemplateMaster().subscribe(
      data => patchState({ ProcedureTemplates: data }),
      error => console.error(error),
    );
  }

  @Selector()
  static read(state: ProcedureTemplateStateModel) {
    return state.ProcedureTemplates;
  }

  @Action(ProcedureTemplateCreate)
  create({ getState, patchState }: StateContext<ProcedureTemplateStateModel>, { item }: ProcedureTemplateCreate) {
    patchState({
      ProcedureTemplates: [...getState().ProcedureTemplates, item],
    });
  }

  @Action(ProcedureTemplateUpdate)
  update({ getState, patchState }: StateContext<ProcedureTemplateStateModel>, { id, item }: ProcedureTemplateUpdate) {
    const index = getState().ProcedureTemplates.map(x => x.id).indexOf(id);

    if (index >= 0) {
      getState().ProcedureTemplates.splice(index, 1);
      patchState({
        ProcedureTemplates: [...getState().ProcedureTemplates, item],
      });
    }
  }

  @Action(ProcedureTemplateDelete)
  delete({ getState, patchState }: StateContext<ProcedureTemplateStateModel>, { id }: ProcedureTemplateDelete) {
    patchState({
      ProcedureTemplates: getState().ProcedureTemplates.filter(x => x.id != id),
    });
  }
}
