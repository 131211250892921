<div class="card">
  <div class="card-header">
    <span class="card-title">{{ label }}</span>
  </div>

  <div class="card-body">
    <mat-form-field class="no-subscript">
      <textarea
        matInput
        id="reason"
        name="reason"
        rows="5"
        placeholder="{{ placeholder }}"
        [(ngModel)]="data.text"
        (ngModelChange)="textChange($event)"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        class="ml-2"
        mat-flat-button
        color="primary"
        [disabled]="acceptDisabled"
        (click)="accept()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
