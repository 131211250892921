import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { BeamlineShutterRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { AppStateService } from 'src/app/store/app-state-service';
import { MenuItem, MenuItemTypes } from '../../head-page/head-menu/head-menu.models';
import { HeadMenuService } from '../../head-page/head-menu/head-menu.service';
import { PendingApprovalsRefresh } from '../../pending-approvals-v2/store/pending-approvals/pending-approvals.action';
import { Servers } from 'src/app/common/enumerations/servers-enum';
import { MenuConfigActionsComponent, Orientation } from '../menu-config-actions/menu-config-actions.component';

@Component({
  selector: 'menu-config-head',
  templateUrl: './menu-config-head.component.html',
  styleUrls: ['./menu-config-head.component.scss']
})
export class MenuConfigHeadComponent {

  @Input() user?: User;
  @Input() SA!: boolean;

  environment = 'local';

  currentUser!: User | null;

  menuItems: MenuItem[] = [];
  menuItems$!: Observable<MenuItem[]>;
  subscription: Subscription = new Subscription;
  showingMenu: MenuItem[] = [];

  resources: BeamlineShutterRelation[] = [];
  resources$!: Observable<BeamlineShutterRelation[]>;
  resourcesSubs: Subscription = new Subscription;

  isLoading = true;
  menuItemTypes = MenuItemTypes;
  unsubs: Subscription;
  showCarrousel = false;
  innerWidth = 0;

  orientation = Orientation;

  @ViewChild(MenuConfigActionsComponent) actions?: MenuConfigActionsComponent;

  constructor(
    private _service: HeadMenuService,
    private store: Store,
    private ass: AppStateService,
  ) {
    this.unsubs = this.ass.AppState.subscribe(appState => {
      this.currentUser = appState.currentUser;
      this.store.dispatch(new PendingApprovalsRefresh());
      this.loadMenuItems();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    this.unsubs.unsubscribe();
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (document.location.hostname === Servers.PRODUCTION) {
      this.environment = 'prod';
    } else {
      if (document.location.host === Servers.DEVELOPMENT) {
        this.environment = 'dev';
      } else if (document.location.host === Servers.STAGING) {
        this.environment = 'test';
      } else if (document.location.host === Servers.PREPROD) {
        this.environment = 'prep';
      }
    }
    this.loadMenuItems();
  }

  loadMenuItems() {
    this.isLoading = true;
    this.menuItems$ = this.store.select(state => state.MenuItem.data);
    this.subscription = this.menuItems$.subscribe(async data => {
      if (data.length) {
        this.menuItems = data;
        const menuItems = this._service.calculateRouterLinks(this.menuItems.filter(x => !x.parentMenuItemID));
        this.menuItems = menuItems.sort((a, b) => a.order - b.order);
        if (this.menuItems.length > 9) {
          this.showCarrousel = true;
          const pageAmount = 9;
          if (this.menuItems.length > pageAmount) {
            this.showingMenu = this.menuItems.slice(0, pageAmount);
          } else {
            this.showingMenu = this.menuItems;
          }
        } else {
          this.showCarrousel = false;
          this.showingMenu = this.menuItems;
        }
        this.isLoading = false;
      }
    });
  }

  next(goNext: boolean) {
    const pageAmount = 9;
    if (goNext) {
      const lastItem = this.menuItems.indexOf(this.showingMenu[this.showingMenu.length - 1]);
      if (lastItem + pageAmount >= this.menuItems.length) {
        if (!this.showingMenu.includes(this.menuItems[this.menuItems.length - 1])) {
          this.showingMenu = this.menuItems.slice(lastItem, this.menuItems.indexOf(this.menuItems[this.menuItems.length - 1]) + 1);
        }
      } else {
        this.showingMenu = this.menuItems.slice(lastItem, lastItem + pageAmount);
      }
    } else {
      const firstItem = this.menuItems.indexOf(this.showingMenu[0]);
      if (firstItem - pageAmount <= 0) {
        this.showingMenu = this.menuItems.slice(0, pageAmount);
      } else {
        this.showingMenu = this.menuItems.slice(firstItem - pageAmount, firstItem);
      }
    }
  }

  openComponent(name: string) {
    this._service.openComponent(name);
  }

}
