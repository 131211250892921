import { Component, Input, OnInit } from '@angular/core';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';

@Component({
  selector: 'checklist-builder-task-more',
  templateUrl: './checklist-builder-task-more.component.html',
  styleUrls: ['./checklist-builder-task-more.component.scss']
})
export class ChecklistBuilderTaskMoreComponent {

  @Input() task!: WfTaskLocalResource;

}
