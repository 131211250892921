<mat-accordion>
  <mat-expansion-panel
    class="apl-group-card"
    [ngClass]="{
      'apl-group-card-pending': aplGroup.status !== 1 && canApproveOrReject()
    }"
    [expanded]="aplGroup.open === true"
    (opened)="aplGroup.open = true"
    (closed)="aplGroup.open = false"
  >
    <mat-expansion-panel-header>
      <div class="apl-group-card-header mt-2">
        <div class="row">
          <div class="col-11">
            <mat-icon
              *ngIf="
                reorderable &&
                (aplGroupApproval ||
                  aplGroupEdit ||
                  aplGroupDelete ||
                  aplGroupCategoryManager ||
                  isSuperAdmin)
              "
              (click)="$event.stopPropagation()"
              cdkDragHandle
              >reorder</mat-icon
            >
            <h6 [innerHTML]="aplGroup.name | highlightSearch : filter"></h6>
          </div>
          <div class="col-1 apl-group-card-header-actions">
            <button
              mat-icon-button
              *ngIf="hasActions()"
              [matMenuTriggerFor]="menu"
              aria-label="actions"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                *ngIf="canApproveOrReject()"
                (click)="openApprovalPopup()"
              >
                <span [innerHTML]="labelApproveDisapprove"></span>
              </button>
              <button mat-menu-item *ngIf="canEdit()" (click)="openEditPopup()">
                <span [innerHTML]="labelEdit"></span>
              </button>
              <button mat-menu-item *ngIf="canDelete()" (click)="deleteGroup()">
                <span [innerHTML]="labelDelete"></span>
              </button>
            </mat-menu>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-5">
            <label
              ><b>{{ labelGroupManagers }}</b
              ><br /><span
                [innerHTML]="getManagers() | highlightSearch : filter"
              ></span>
            </label>
          </div>
          <div class="col-12 col-md-7">
            <label
              ><b>{{ labelProcedureTrainingCoordinators }}</b
              ><br /><span
                [innerHTML]="getPTCs() | highlightSearch : filter"
              ></span
            ></label>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div>
      <label
        ><b class="mr-2">{{ labelDescription }}:</b
        ><span
          [innerHTML]="aplGroup.description | highlightSearch : filter"
        ></span
      ></label>
    </div>
    <div cdkDropList class="role-list" (cdkDropListDropped)="dropRole($event)">
      <div
        class="row mt-3 mb-0 roles-box"
        *ngFor="let groupRole of aplGroup?.aplGroupRoles; index as i"
        cdkDrag
        [cdkDragDisabled]="!(isGroupManager || isSuperAdmin) || !reorderable"
      >
        <div
          class="col-auto role-left"
          *ngIf="
            reorderable &&
            aplGroup.status === 1 &&
            (isGroupManager || isSuperAdmin)
          "
          (click)="$event.stopPropagation()"
        >
          <i class="material-icons" cdkDragHandle>reorder</i>
        </div>
        <div class="col role-right">
          <div class="row mb-2">
            <div class="col-12 d-flex justify-content-start align-items-center">
              <h6
                class="mr-2"
                [innerHTML]="groupRole?.role?.name | highlightSearch : filter"
              ></h6>
              <a href="{{ '/#/roles/' + groupRole?.role?.id }}"
                ><mat-icon color="primary">link</mat-icon></a
              >
              <label
                class="ml-4"
                [innerHTML]="
                  groupRole?.role?.information | highlightSearch : filter
                "
              ></label>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label
                ><b>{{ labelMembers }}</b></label
              ><br />
            </div>
            <div class="col-8">
              <label><b>Notes</b></label
              ><br />
            </div>
          </div>

          <div
            class="row border-top pt-2"
            *ngFor="let userRole of groupRole.role?.userRole"
          >
            <div class="col-4">
              <label
                [innerHTML]="userRole?.user?.name | highlightSearch : filter"
              ></label>
            </div>
            <div class="col-8">
              <label *ngFor="let note of userRole?.notes">
                {{ note?.description }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
