import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ReviewAttendanceSave } from 'src/app/components/reviews/reviews.models';

@Component({
  selector: 'app-review-attendance-save',
  templateUrl: './review-attendance-save.component.html',
  styleUrls: ['./review-attendance-save.component.scss']
})
export class ReviewAttendanceSaveComponent implements OnInit, OnDestroy {

  reviewAttendanceSave!: ReviewAttendanceSave;
  reviewAttendanceSaved!: ReviewAttendanceSave[];
  reviewAttendanceSavedFiltered!: ReviewAttendanceSave[];
  reviewAttendanceSaved$!: Observable<ReviewAttendanceSave[]>;
  reviewAttendanceSavedSubs!: Subscription;

  rosterCtrl = new FormControl();

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<ReviewAttendanceSaveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      message: string,
    },
  ) { }

  ngOnDestroy(): void {
    this.reviewAttendanceSavedSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.loadReviewAttendanceSaved();
  }

  displayObject(e: any) {
    return e ? e.name : null;
  }

  changedRoster(e: any) {
    const value: string = e.target.value;
    this.reviewAttendanceSavedFiltered = this.reviewAttendanceSaved.filter(x => x.name?.toLowerCase().includes(value.toLowerCase()));
  }

  loadReviewAttendanceSaved() {
    this.reviewAttendanceSaved$ = this.store.select(state => state.ReviewAttendanceSaved.data);
    this.reviewAttendanceSavedSubs = this.reviewAttendanceSaved$.subscribe(data => {
      if (data.length) {
        this.reviewAttendanceSaved = data;
        this.reviewAttendanceSavedFiltered = data;
      }
    });
  }

  selectedReviewAttendanceSave(e: any) {
    this.reviewAttendanceSave = e.option.value;
  }

  save() {
    this.dialogRef.close(this.rosterCtrl.value);
  }

  cancel() {
    this.dialogRef.close();
  }

  disabledSave() {
    return !this.rosterCtrl.value;
  }
}
