import { Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MenuItem, MenuItemTypes } from '../head-menu.models';
import { HeadMenuService } from '../head-menu.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { MenuConfigActionsComponent, Orientation } from 'src/app/components/menu-config/menu-config-actions/menu-config-actions.component';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'head-menu-submenu',
  templateUrl: './head-menu-submenu.component.html',
  styleUrls: ['./head-menu-submenu.component.scss']
})
export class HeadMenuSubmenuComponent implements OnInit, OnChanges {

  @Input() menuItem!: MenuItem;
  @Input() editing?: boolean;

  @ViewChild(MenuConfigActionsComponent) actions?: MenuConfigActionsComponent;

  orientationEnum = Orientation;

  menuItems!: MenuItem[];
  menuItems$!: Observable<MenuItem[]>;
  subscription: Subscription = new Subscription;

  @ViewChild('menu', { read: MatMenuTrigger })
  protected trigger!: MatMenuTrigger;
  menuHovered!: boolean;
  menuItemTypes = MenuItemTypes;

  constructor(
    private _service: HeadMenuService,
    public dialog: MatDialog,
    private store: Store
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadMenuItems();
  }

  async ngOnInit() {

  }

  loadMenuItems() {
    this.menuItems$ = this.store.select(state => state.MenuItem.data);
    this.subscription = this.menuItems$.subscribe(data => {
      if (data.length) {
        if (this.menuItem) {
          const menuItem = data.find(x => x.id == this.menuItem.id);
          if (menuItem) {
            menuItem.childMenuItems = data.filter(x => x.parentMenuItemID == menuItem?.id && (x.status || this.editing)).sort((a, b) => utils.sort(a.order, b.order));
            this.menuItem = menuItem;
            this.createMenu();
          }
        }
      }
    });
  }

  createMenu() {
    if (this.menuItem.childMenuItems)
      this.menuItems = this._service.calculateRouterLinks(this.menuItem.childMenuItems).sort((a, b) => a.order - b.order);
  }

  openMenu() {
    if (!this.trigger.menuOpen) {
      this.trigger.openMenu();
    }
  }

  closeMenu() {
    setTimeout(() => {
      if (!this.menuHovered) {
        this.trigger.closeMenu();
      }
    }, 100);
  }

  menuOver() {
    this.menuHovered = true;
  }

  menuOut() {
    if (!this.editing) {
      this.menuHovered = false;
      this.closeMenu();
    }
  }

  openComponent(name: string) {
    this._service.openComponent(name);
  }

}
