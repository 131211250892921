import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Role } from 'src/app/components/catalogs/roles/services/role';

@Component({
  selector: 'role-selection',
  templateUrl: './role-selection.component.html',
  styleUrls: ['./role-selection.component.scss']
})
export class RoleSelectionComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() roles?: Role[] = [];
  @Input() label?: string = 'Roles';
  @Input() noSubscript?: boolean;
  @Input() control?: AbstractControl<any, any> | FormControl | null;
  @Input() hint?: string;
  @Input() disabled?: boolean;
  @Input() showNames?: boolean;

  @Output() changed = new EventEmitter<Role[]>();
  @Output() deleted = new EventEmitter<Role>();
  @Output() selected = new EventEmitter<Role>();

  @Output() mousedown = new EventEmitter();

  allRoles?: Role[];
  rolesFiltered?: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  ngOnInit(): void {
    this.loadRoles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.roles?.sort((a, b) => this.utils.sort(a.code, b.code));
    this.filterRoles();
    if (this.disabled && this.control)
      this.control.disable();
    else if (this.control)
      this.control.enable();
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      if (data?.length) {
        this.allRoles = data;
        this.filterRoles();
      }
    });
  }

  filterRoles() {
    this.rolesFiltered = this.allRoles?.filter(r => !this.roles?.map(c => c.id).includes(r.id));
  }

  remove(role: Role) {
    const index = this.roles?.findIndex(r => r.id == role.id);
    if (index != undefined) this.roles?.splice(index, 1);
    this.deleted.emit(role);
    this.changed.emit(this.roles);
    this.filterRoles();
  }

  change(e: any) {
    const text = e.target.value;
    this.filterRoles();
    this.rolesFiltered = this.rolesFiltered?.filter(r => r.code?.toLowerCase().includes(text.toLowerCase()) || r.name?.toLowerCase().includes(text.toLowerCase()));
  }

  select(e: MatAutocompleteSelectedEvent) {
    const role = e.option.value;
    if (!this.roles) this.roles = [];
    this.roles?.push(role)
    this.roles?.sort((a, b) => this.utils.sort(a.code, b.code));
    this.selected.emit(role);
    this.changed.emit(this.roles);
    this.filterRoles();
  }
}
