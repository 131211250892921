<div class="card">
  <div class="card-header d-flex justify-content-between relative">
    <h6 class="card-title">Toxic Gas</h6>
    <div class="header-buttons">
      <button
        mat-icon-button
        color="primary"
        class="focus-outline-none"
        (click)="showArchive()"
      >
        <mat-icon>history</mat-icon>
      </button>
      <button
        mat-icon-button
        color="accent"
        class="focus-outline-none ml-1"
        *ngIf="hasPrivilege(privilegeEnum.ToxicGasEditor)"
        (click)="showAdd()"
      >
        <mat-icon>add_box</mat-icon>
      </button>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="card-body p-0 small">
    <div *ngFor="let item of actives">
      <div
        class="alert p-2 mb-0"
        [ngClass]="{
          'alert-danger': !item.roofAccess,
          'alert-success': item.roofAccess
        }"
      >
        <div class="row mb-2">
          <div class="col-10">
            <div
              class="badge"
              [ngClass]="{
                'pill-danger': !item.roofAccess,
                'pill-success': item.roofAccess
              }"
            >
              Roof Access : {{ item.roofAccess ? "OK" : "Not OK" }}
            </div>
          </div>

          <div class="col-2">
            <button
              class="menu"
              mat-icon-button
              [matMenuTriggerFor]="menu"
              *ngIf="hasPrivilege(privilegeEnum.ToxicGasEditor)"
            >
              <mat-icon aria-hidden="false" aria-label="options" class="green"
                >more_vert</mat-icon
              >
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="showEdit(item)">
                <mat-icon class="yellow">edit</mat-icon>Edit
              </button>
              <button mat-menu-item (click)="archive(item.id)">
                <mat-icon class="gray">archive</mat-icon>Archive
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="row">
          <div class="col">
            Location: <b> {{ item.description }} </b>
          </div>
        </div>
        <div class="row">
          <div class="col">
            Gas: <b> {{ item.name }} </b>
          </div>
          <!-- <div class="col text-right">
            <div
              class="badge"
              [ngClass]="{
                'pill-danger': !item.roofAccess,
                'pill-success': item.roofAccess
              }"
            >
              Roof Access : {{ item.roofAccess ? "OK" : "Not OK" }}
            </div>
          </div> -->
        </div>
        <div class="row">
          <div class="col">
            Responsible: <b> {{ item.responsibleParty }} </b>
          </div>
        </div>
        <div class="row">
          <div class="col small text-right">
            <span [matTooltip]="item.createdByUser ?? ''">
              Created by {{ item.createdByInitials }} on
              {{ item.createdOn | date : "yyyy-MM-dd" }}.
            </span>
          </div>
        </div>
        <div class="row" *ngIf="item.updatedByUser != null">
          <div class="col small text-right">
            <span [matTooltip]="item.updatedByUser">
              Last edited by {{ item.updatedByInitials }} on
              {{ item.updatedOn | date : "yyyy-MM-dd" }}.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="px-2">
      <div class="sticky row pb-2 pt-3">
        <div class="col">

        </div>

      </div>
      <div #bodyElem class="card-body mt-2 small toxic-content">

      </div>
    </div>
  </div>
 -->
</div>
