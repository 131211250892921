import { Component, ViewChildren, QueryList, OnDestroy, Injector, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { RSSWAResource } from '../../rsswa';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormStatusEnum, OlogNotificationType } from 'src/app/common/enumerations/enumerations';
import { DataButtonSignature } from 'src/app/common/models/data-button-signature';
import { ButtonSignatureComponent } from 'src/app/controls/checklist-components/button-signature/button-signature.component';
import { OLogService } from 'src/app/components/olog/olog.service';
import { RSSFunctionalTestService } from 'src/app/services/rss-functional-test/rss-functional-test.service';
import { WFConfiguration, WfSignature, WfSignatureResource, WFTaskSignatureResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { KEService } from 'src/app/components/kenable/ke.service';
import { Restriction } from 'src/app/controls/restrictions/services/restriction';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rsswa-section4',
  templateUrl: './rsswa-section4.component.html',
  styleUrls: ['./rsswa-section4.component.scss']
})
export class RsswaSection4Component extends BaseComponent implements OnDestroy, OnChanges {

  @Input() rsswa?: RSSWAResource | null;
  @Output() loading = new EventEmitter<boolean>();

  // Workflow
  @ViewChildren('btnSign')
  signatureComponents!: QueryList<ButtonSignatureComponent>;
  wfConfiguration!: WFConfiguration;
  Signatures!: WfSignature[];
  wfSignatures?: WfSignatureResource[];
  wfTaskSignature: WFTaskSignatureResource = new WFTaskSignatureResource();
  disabled!: boolean;

  // section!: number;
  amendments: any[] = [];
  previousSigned = false;

  restrictions!: Restriction[];
  restrictionsFiltered!: Restriction[];
  restrictions$!: Observable<Restriction[]>;
  restrictionsSubs!: Subscription;
  //


  // Form
  public form!: FormGroup;

  constructor(
    private _wf: WorkFlowService,
    private ologService: OLogService,
    private rssFunctionalTestService: RSSFunctionalTestService,
    protected override injector: Injector,
    private keService: KEService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
    // this.canApprove = !this.Signatures.some(s => s.approvedBy?.id == this.currentUser?.id);
  }

  ngOnInit(): void {
    this.loadRestrictions();
  }

  override ngOnDestroy(): void {
    this.restrictionsSubs?.unsubscribe();
  }

  loadRestrictions() {
    this.restrictions$ = this.store.select((state) => state.Restrictions.data);
    this.restrictionsSubs = this.restrictions$.subscribe((data) => {
      if (data?.length) {
        this.restrictions = data;
      }
    });
  }

  async refresh() {
    let configuration: WFConfiguration[] | undefined;
    let data11: WfSignatureResource[];

    if (this.rsswa?.id && this.rsswa.wfTable) {
      this.loading.emit(true);

      this.disabled = this.rsswa?.status != FormStatusEnum.Active && this.rsswa?.status != FormStatusEnum.Pending && this.rsswa?.status != FormStatusEnum.WorkCompleted && this.rsswa?.status != FormStatusEnum.AmendmentSign;

      if (this.rsswa?.wfTable)
        configuration = await this._wf.readConfiguration(3, this.rsswa.wfTable.id).toPromise();
      data11 = this.rsswa.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 11)?.wfSignatures?.filter(s => s.amendmentID) ?? [];
      this.wfSignatures = this.rsswa.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 4)?.wfSignatures;
      this.wfSignatures = this.wfSignatures?.concat(data11);

      this.Signatures = [];
      let index = 0;

      this.wfSignatures = this.wfSignatures?.sort((o1, o2) => {
        if ((o1.amendmentID ?? 0) > (o2.amendmentID ?? 0)) { return 1; }
        if ((o1.amendmentID ?? 0) < (o2.amendmentID ?? 0)) { return -1; }
        return 0;
      });

      let amendmentNumber = 0;
      let lastAmendmentID = 0;
      this.wfSignatures?.map(signature => {
        if ((signature.amendmentID ?? 0) > 0 && signature.amendmentID != lastAmendmentID) {
          amendmentNumber++;
          lastAmendmentID = signature.amendmentID;
        }
        signature.amendmentNumber = amendmentNumber;

        this.previousSigned = this.wfSignatures?.some(s => s.amendmentID == signature.amendmentID && s.signedBy?.id == this.currentUser?.id && signature.id != s.id) ?? false;

        const taskEnabled = (signature.approveEnabled || signature.unapproveEnabled) && signature.status == this.rsswa?.status;

        const s: WfSignature = {
          id: signature.id,
          approveId: signature.approveID,
          unapproveId: signature.unapproveID,
          disapproveId: signature.disapproveID,
          amendmentId: signature.amendmentID,
          name: signature.titleBefore,
          roles: signature.roles.map(r => r.id),
          roleCodes: signature.roles.map(r => r.code).join('/'),
          disabled: this.disabled || !taskEnabled,
          approvedBy: signature.signedBy,
          approved: signature.signed,
          approvedOn: signature.signedOn,
          approve: signature.approveEnabled && !this.previousSigned,
          unapprove: signature.unapproveEnabled,
          disapprove: signature.disapproveEnabled && !this.previousSigned,
          amendment: signature.amendmentEnabled,
          required: signature.required,
          taskId: signature.taskID,
          value: signature.value,
          cancelID: signature.cancelID,
          cancel: signature.cancelEnabled,
          enabled: signature.enabled,
          amendmentNumber: signature.amendmentNumber
        };

        if (index < 2) {
          if (this.rsswa?.status != FormStatusEnum.Pending && this.rsswa?.status != FormStatusEnum.AmendmentSign) {
            signature.disabled = true;
          }
        }

        index++;
        if (!this.Signatures.some(x => x.taskId == s.taskId)) {
          this.Signatures.push(s);
        }
        this.signatureComponents.map(s => { s.refresh(); });
      });
      this.amendments = [];
      this.Signatures.map(s => {
        if (!this.amendments.includes(s.amendmentNumber ?? 0)) {
          this.amendments.push(s.amendmentNumber ?? 0);
        }
      });

      this.loading.emit(false);
    }
  }

  getSignaturesByAmendment(a: any) {
    return this.Signatures.filter(s => (s.amendmentNumber ?? 0) == (a ?? 0));
  }

  getLastAmendment() {
    const last = this.Signatures.filter(x => x.amendmentNumber).map(x => x.amendmentNumber).sort((a, b) => (a ?? 0) - (b ?? 0)).reverse();
    return last ? last[0] : 0;
  }

  async approve(taskId?: number, actionId?: number) {
    this.loading.emit(true);
    let ok = true;
    let signatures = this.wfSignatures?.filter(s => s.taskID != taskId);
    if (!signatures?.filter(x => !x.signed).length && this.rsswa?.id) {
      ok = await this.showAproveToActiveMessage();

      if (ok && this.rsswa?.id) {
        const response = await this.ologService.postToOlog(this.formTypeEnum.RSSWA, this.rsswa?.id, this.rsswa?.status === FormStatusEnum.Pending ? OlogNotificationType.Activate : OlogNotificationType.Amended, this.amendments[this.amendments.length - 1]);
        ok = response?.ok ?? false;
      }
      if (this.rsswa?.id)
        await this.rssFunctionalTestService.activate(this.rsswa?.id).toPromise();
    }
    if (ok) {
      this.wfTaskSignature.taskId = taskId;
      this.wfTaskSignature.actionId = actionId;
      await this._wf.sign(this.wfTaskSignature).toPromise();


      //Refresh KEs
      const restrictedResourceIDs = this.restrictions.filter(x => this.rsswa?.rsswaRestrictions?.filter(x => x.restrictionID).map(r => r.restrictionID).includes(x.id)).map(y => y.resourceID).filter((resourceID): resourceID is number => resourceID !== undefined);
      if (restrictedResourceIDs !== undefined && restrictedResourceIDs.length > 0) {
        this.keService.processKES(restrictedResourceIDs);
      }
    }
    else { this.refresh(); }
    this.loading.emit(false);
  }

  async unapprove(taskId?: number, actionId?: number) {
    this.loading.emit(true);
    this.wfTaskSignature.taskId = taskId;
    this.wfTaskSignature.actionId = actionId;
    await this._wf.sign(this.wfTaskSignature).toPromise();
  }

  async disapprove(taskID?: number, actionID?: number, data?: DataButtonSignature) {
    this.loading.emit(true);
    this.wfTaskSignature.taskId = taskID;
    this.wfTaskSignature.actionId = actionID;
    this.wfTaskSignature.reason = data?.text;
    await this._wf.sign(this.wfTaskSignature).toPromise();
  }

}
