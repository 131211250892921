import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TokenInfoService } from 'src/app/services/authentication/token-info.service';
import { MessageBanner } from '../../services/message-banner';
import { MessageTypeEnum } from '../../services/message-banner.enums';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'app-edit-display-message',
  templateUrl: './edit-display-message.component.html',
  styleUrls: ['./edit-display-message.component.scss']
})
export class EditDisplayMessageComponent implements OnInit {

  editMessageForm!: FormGroup;
  message: MessageBanner;
  MessageType = MessageTypeEnum;

  constructor(public infoToken: TokenInfoService, public formBuilder: FormBuilder, public dialogRef: MatDialogRef<EditDisplayMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data;
  }

  ngOnInit(): void {
    if (this.message != null) {
      this.editMessageForm = this.formBuilder.group({
        name: [this.message.name],
        description: [this.message.description, [Validators.required, utils.IsWhiteSpaceReactiveForm]],
        type: [this.message.type, Validators.required],
        timeout: [this.message.timeout],
        keepAfterRouteChange: [this.message.keepAfterRouteChange, Validators.required],
        notes: [this.message.notes]
      });
    }
  }


  onNoClick(submit?: boolean): void {
    this.dialogRef.close(submit);
  }

  onSubmit() {
    Object.assign(this.message, this.editMessageForm.value);
    this.dialogRef.close(this.message);
  }

}
