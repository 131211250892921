import { ActionPendingCatalog, Catalog } from "src/app/common/enumerations/enumerations";
import { AcceleratorCatalog } from "src/app/components/catalogs/accelerator-catalog/services/accelerator-catalog";
import { NotificationTemplate } from "src/app/components/catalogs/notifications/services/notification-template";
import { ResourceSummary, BeamlineShutterRelation } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { Role } from "src/app/components/catalogs/roles/services/role";
import { UserRoleNote } from "src/app/services/user-roles/user-role-note";
import { UserCatalog } from "src/app/components/catalogs/user-catalog/services/user";

export class CatalogModel {
  public userCatalog?: UserCatalog;
  public role?: Role;
  public acceleratorCatalog?: AcceleratorCatalog;
  public resourceSummary?: ResourceSummary;
  public notificationTemplate?: NotificationTemplate;
  public action?: ActionPendingCatalog;
  public userRoleNote?: UserRoleNote;
}

export class CatalogModelList {
  public userCatalogs?: UserCatalog[];
  public roles?: Role[];
  public acceleratorCatalogs?: AcceleratorCatalog[];
  public beamlineShutterRelations?: BeamlineShutterRelation[];
  public notificationTemplates?: NotificationTemplate[];
}

export interface ModifyingResult {
  isModifying: boolean;
  userID: number | null;
  catalogs: Catalog[] | null;
}

export interface Tuple {
  item1: any;
  item2: any;
}

export enum Catalogs {
  Accelerator = 'Accelerator',
  Roles = 'Roles'
}

export enum Move {
  NEXT = 1,
  PREV = -1,
  FIRST = 0,
  LAST = 999
}
