import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ImageUploadService } from 'src/app/services/file/image-upload.service';
import { WfSignature, WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { Annotation, ChecklistTemplate } from '../../../checklists';
import { ChecklistBuilderService } from '../../checklist-builder.service';
import { ChecklistBuilderAnnotationsService } from '../checklist-builder-annotations.service';

@Component({
  selector: 'app-checklist-builder-annotation-edit',
  templateUrl: './checklist-builder-annotation-edit.component.html',
  styleUrls: ['./checklist-builder-annotation-edit.component.scss']
})
export class ChecklistBuilderAnnotationEditComponent extends BaseComponent implements OnInit {

  s: WfSignature;
  task: WfTaskLocalResource;
  templateId: number;
  annotation: Annotation;
  annotationText?: string | null;
  isResponse: boolean;
  isComment?: boolean;
  currentVersion: ChecklistTemplate;

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
        [{ align: [] }],
        ['link', 'image'],
        ['clean']

      ]
    },
    // autoLink: true,
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: () => {
            return true;
          }
        }
      }
    },
    imageDrop: true,
    imageResize: true
  };

  wfTaskLocal!: WfTaskLocalResource;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<ChecklistBuilderAnnotationEditComponent>,
    private _serv: ChecklistBuilderAnnotationsService,
    private _image: ImageUploadService,
    private _builder: ChecklistBuilderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(injector);

    this.s = data.s;
    this.isResponse = data.isResponse;
    this.annotation = data.annotation;
    this.annotationText = this.isResponse ? this.annotation?.responseText ?? '' : this.annotation?.annotationText;
    this.task = data.task;
    this.templateId = data.templateId;
    this.currentVersion = data.currentVersion;
  }

  ngOnInit() {
    if (this.s.id)
      this._builder.getWFTaskLocal(this.s.id).toPromise().then(data => {
        if (data)
          this.wfTaskLocal = data;
      });
    this.isComment = this.currentVersion.documentTypeID == 200;
  }

  async accept() {
    if (!this.isResponse) {
      if (this.annotation == null) {
        this.annotation = {
          id: 0,
          annotationText: this.annotationText ?? '',
          wfTaskLocalID: this.task.id,
          checklistTemplateID: this.templateId,
          status: 0
        };

        this._serv.create(this.annotation).toPromise().then(data => {
          this.dialogRef.close(data);
        });
      }
      else {
        if (this.annotationText)
          this.annotation.annotationText = await this.uploadImages(this.annotationText, this.currentVersion.documentType?.code?.trim() ?? '' + this.currentVersion.serialNo + '/WFSectionLocal-' + this.wfTaskLocal.wfStepLocal?.wfSectionLocalID + '/WFTaskLocal-' + this.s.id + '/Suggestion-' + this.annotation.id + '/') ?? '';
        this._serv.update(this.annotation).toPromise().then(data => {
          this.dialogRef.close(data);
        });
      }
    }
    else {
      if (this.annotationText)
        this.annotation.responseText = await this.uploadImages(this.annotationText, this.currentVersion.documentType?.code?.trim() + '/' + this.currentVersion.serialNo + '/WFSectionLocal-' + this.wfTaskLocal.wfStepLocal?.wfSectionLocalID + '/WFTaskLocal-' + this.s.id + '/Response-' + this.annotation.id + '/');
      this.annotation.responseBy = this.getCurrentUser()?.id;
      this.annotation.responseByName = this.getCurrentUser()?.name;
      this.annotation.responseDate = new Date();
      this._serv.update(this.annotation).toPromise().then(data => {
        this.dialogRef.close(data);
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  async uploadImages(textValue: string, path: string) {
    const text = await this._image.uploadImagesToServer(textValue, path);
    return text;
  }

}
