import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RadNote } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-note';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RadNoteService {

  public api: string = environment.urls.baseApiUrl + '/RadNote';

  constructor(
    private http: HttpClient
  ) { }

  GetRadNotes(): Observable<RadNote[]> {
    return this.http.get<RadNote[]>(this.api);
  }

  GetRadNoteById(id: number): Observable<RadNote> {
    return this.http.get<RadNote>(this.api + '/' + id);
  }

  CreateRadNote(radNote: RadNote): Observable<RadNote> {
    return this.http.post<RadNote>(this.api, radNote);
  }

  UpdateRadNote(id: number, radNote: RadNote): Observable<RadNote> {
    return this.http.put<RadNote>(this.api + '/' + id, radNote);
  }

  DeleteRadNote(id: number) {
    return this.http.delete(this.api + '/' + id);
  }
}
