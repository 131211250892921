import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/common/base/base.service';
import { OlogMessage } from './olog-message';

@Injectable({
  providedIn: 'root'
})
export class OlogMessageService extends BaseService<OlogMessage, number> {

  public api: string = environment.urls.baseApiUrl + '/OlogMessages';

  constructor(
    protected override injector: Injector
  ) {
    super(injector, '/OlogMessages');
  }

}
