<fieldset class="field-group">
  <legend>Color</legend>
  <mat-radio-group
    class="d-flex justify-content-between"
    [disabled]="disabled ?? false"
    aria-label="Select an option"
    [(ngModel)]="color"
  >
    <mat-radio-button
      class="small"
      *ngFor="let r of colorOptions"
      [color]="r.value"
      [checked]="r.checked"
      [value]="r.value"
      (change)="colorChange($event)"
    >
      {{ r.label }}
    </mat-radio-button>
  </mat-radio-group>
</fieldset>
