import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { ProcedureCategoryPopupComponent } from '../dialogs/procedure-category-popup/procedure-category-popup.component';
import { ProcedurePopupComponent } from '../dialogs/procedure-popup/procedure-popup.component';
import { ProcedureTypeEnum } from '../enums/procedure-type.enum';
import { ProcedureCategory } from '../models/procedure-category.model';
import { ProcedureConfiguration } from '../models/procedure-configuration.model';
import { Procedure } from '../models/procedure.model';
import { ProcedureCategoryRefresh } from '../store/procedure-category/procedure-category.action';
import { ProcedureConfigurationRefresh } from '../store/procedure-configuration/procedure-configuration.action';
import { ProcedureCreditedRefresh } from '../store/procedure-credited/procedure-credited.action';
import { ProcedureTemplateRefresh } from '../store/procedure-template/procedure-template.action';
import { ProcedureTrainingRefresh } from '../store/procedure-training/procedure-training.action';

@Component({
  selector: 'app-procedure-update-v2',
  templateUrl: './procedure-update-v2.component.html',
  styleUrls: ['./procedure-update-v2.component.scss']
})
export class ProcedureUpdateV2Component extends BaseComponent implements OnInit {

  disabled!: boolean;
  loading = false;
  loadingMessage!: string;

  filterCtrl = new FormControl();

  errorMessages!: string[];
  headerOnTop!: boolean;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter!: any[];
  currentFilter: any;
  filter: any;

  ///////////////////////

  categoriesSubscription!: Subscription;
  categories$!: Observable<ProcedureCategory[]>;
  categories: ProcedureCategory[] = [];
  categoriesDS = new MatTableDataSource<ProcedureCategory>();
  categoriesColumns: string[] = ['category'];

  procedureConfigurationSubscription!: Subscription;
  procedureConfiguration$!: Observable<ProcedureConfiguration>;
  procedureConfiguration!: ProcedureConfiguration;

  showInactive = false;
  showArchived = false;
  showMyProcedures = false;
  sidenavOpened = false;
  today = new Date();
  categoryPermission = false;
  addNewPermission = false;
  procedureCategorySort = '';
  tabIndex = 0;


  prevScrollpos = window.pageYOffset;
  headerWidth = '';
  links = this.hasPrivilege(PrivilegeEnum.PCViewRadTab) ? ['Procedure', 'RSS Test', 'EPS Test', 'EPS Drawings', 'Rad Survey'] : ['Procedure', 'RSS Test', 'EPS Test', 'EPS Drawings'];
  activeLink = this.links[0];

  @ViewChild(MatSidenavContainer)
  sideNav!: MatSidenavContainer;
  @ViewChild('content')
  content!: ElementRef;

  tabLabel!: string;

  label_categories!: string;
  label_procedure_management!: string;
  label_filter!: string;
  label_manage_categories!: string;
  label_show_archived!: string;
  label_show_inactive!: string;
  label_show_myprocedures!: string;

  constructor(
    protected override injector: Injector,
    private activatedRoute: ActivatedRoute,
  ) {
    super(injector);

  }

  ngOnInit(): void {
    this.setLoading(true, LoadingOrigin.Self);
    // this.initStore();
    this.setLabels();
    this.categoryPermission = this.hasPrivilege(PrivilegeEnum.ALSProcedureCategory);
    this.addNewPermission = this.hasPrivilege(PrivilegeEnum.ALSProcedureAddNew);

    this.categories$ = this.store.select(state => state.ProcedureCategory.ProcedureCategories);
    this.categoriesSubscription = this.categories$.subscribe((data) => {
      this.categories = data.sort((a, b) => this.utils.compare(a.name, b.name, true));
      this.onTabChanged(null);
    });

    this.procedureConfiguration$ = this.store.select(state => state.ProcedureConfiguration.ProcedureConfiguration);
    this.procedureConfigurationSubscription = this.procedureConfiguration$.subscribe(data => {
      this.procedureConfiguration = data;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.setTab(params?.['tab']);
    });
    this.setLoading(false, LoadingOrigin.Self);
  }

  applyFilter(e: any) {
    this.filter = e;
  }

  setLabels() {
    this.label_categories = this.getMessage('ALSPC_Update_Categories')?.description;
    this.label_procedure_management = this.getMessage('ALSPC_Update_ProcedureManagement')?.description;
    this.label_filter = this.getMessage('ALSPC_Update_Filter')?.description;
    this.label_manage_categories = this.getMessage('ALSPC_Update_ManageCategories')?.description;
    this.label_show_archived = this.getMessage('ALSPC_Update_ShowArchived')?.description;
    this.label_show_inactive = this.getMessage('ALSPC_Update_ShowInactive')?.description;
    this.label_show_myprocedures = 'My Procedures';
    if (!this.label_categories) {
      setTimeout(() => {
        this.setLabels();
      }, 1000);
    }
  }

  onTabChanged(e: any) {
    this.tabIndex = e ? this.links.indexOf(e) : 0;
    switch (this.tabIndex) {
      case 0:
        this.categoriesDS.data = this.categories.filter(x => x.procedureTypeID === ProcedureTypeEnum.ProcedureCenter);
        break;

      // case 1:
      //   this.categoriesDS.data = this.categories.filter(x => x.procedureTypeID === ProcedureTypeEnum.KEChecklist);
      //   break;

      case 1:
        this.categoriesDS.data = this.categories.filter(x => x.procedureTypeID === ProcedureTypeEnum.RSSTest);
        break;

      case 2:
        this.categoriesDS.data = this.categories.filter(x => x.procedureTypeID === ProcedureTypeEnum.EPSTest);
        break;

      case 3:
        this.categoriesDS.data = this.categories.filter(x => x.procedureTypeID === ProcedureTypeEnum.EPSDrawings);
        break;

      case 4:
        this.categoriesDS.data = this.categories.filter(x => x.procedureTypeID === ProcedureTypeEnum.RadSurvey);
        break;
    }
  }

  setTab(tabParam?: string) {
    this.tabIndex = tabParam ? Number(tabParam) : 0;
    this.setActiveLink();
  }

  setActiveLink() {
    this.activeLink = this.links[this.tabIndex];
    this.tabLabel = this.activeLink;
  }

  settings() {

  }


  navigate(id: number) {
    document.getElementById('_' + id.toString())?.scrollIntoView({ behavior: 'smooth' });
  }

  openCategoriesPopup() {
    this.dialog.open(ProcedureCategoryPopupComponent, {
      height: 'auto',
      width: '75vw',
      data: {},
    });
  }

  openProcedurePopup(procedure: Procedure | null) {
    let obj: Procedure | null = null;

    if (this.procedureConfiguration?.currentPath?.trim()?.length <= 0 || this.procedureConfiguration?.inactivePath?.trim()?.length <= 0 || this.procedureConfiguration?.archivedPath?.trim()?.length <= 0) {
      this.alert.error('You need to configure tha paths to save the files');
      return;
    }

    if (procedure) {
      obj = this.utils.cloneDeep(procedure);
    }

    this.dialog.open(ProcedurePopupComponent, {
      height: 'auto',
      width: '75vw',
      maxHeight: '85vh',
      disableClose: true,
      data: {
        procedure: obj,
        procedureTab: this.tabIndex,
      }
    });

  }

  initStore() {
    this.store.dispatch(
      new ProcedureCategoryRefresh()
    );
    this.store.dispatch(
      new ProcedureTrainingRefresh()
    );
    this.store.dispatch(
      new ProcedureCreditedRefresh()
    );
    this.store.dispatch(
      new ProcedureTemplateRefresh()
    );
    this.store.dispatch(
      new ProcedureConfigurationRefresh()
    );
  }

  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 2000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Self:
          this.loadingSelf = e as boolean;
          break;
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Buttons:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Main:
          this.loadingMain = e as boolean;
          break;
      }
      // console.log('m: ' + this.loadingMain + '   b: ' + this.loadingButtons + '   s: ' + this.loadingSide);
      this.loading = this.loadingSelf || this.loadingMain || this.loadingButtons || this.loadingSide;
    }, 100);
  }

  loadingSide = false;
  loadingButtons = false;
  loadingMain = false;
  loadingSelf = false;
  loadingOrigin = LoadingOrigin;
}

enum LoadingOrigin {
  Self = 0, Side = 1, Buttons = 2, Main = 3
}
