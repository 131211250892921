<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-5 col-md-3">
        <mat-icon color="warn" *ngIf="icon == 'stop'">report</mat-icon>
        <mat-icon color="accent" *ngIf="icon == 'warn' || icon == 'warning'"
          >warning</mat-icon
        >
        <mat-icon color="primary" *ngIf="icon == 'question'"
          >help_outline</mat-icon
        >
        <mat-icon color="primary" *ngIf="icon == 'info'">info</mat-icon>
      </div>
      <div class="col-7 col-md-9">
        <div class="h-100 d-flex align-items-center" mat-dialog-content>
          <span [innerHTML]="message"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <mat-dialog-actions align="center">
      <button mat-flat-button color="warn" [mat-dialog-close]="false">
        {{ buttonCancel }}
      </button>
      <button mat-flat-button color="primary" [mat-dialog-close]="data.value">
        {{ buttonAccept }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
