import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RadMeterModel } from '../../rad-meters';
import { AlertService } from 'src/app/services/alert/alert.service';
import { RadMetersService } from '../../services/rad-meters.service';

@Component({
  selector: 'app-rad-meter-model-details',
  templateUrl: './rad-meter-model-details.component.html',
  styleUrls: ['./rad-meter-model-details.component.scss']
})
export class RadMeterModelDetailsComponent implements OnInit {

  @Input()
  currentMeterModel!: RadMeterModel;
  public modifying!: boolean;
  public selected!: boolean;
  public adding = false;
  public disabledStatus!: boolean;
  public statusList: any = [{ name: 'Active', id: 1 }, { name: 'Inactive', id: 0 }];
  public showCancelButton!: boolean;
  public isModifiying!: boolean;
  public modifyAvailable = false;
  public disabledTimer!: boolean;
  meterModelsList: RadMeterModel[] = [];
  private valid!: boolean;
  oldName?: string = '';
  oldDescription?: string = '';
  oldStaus?: number;
  private errorMsg: string[] = [];

  constructor(
    private alert: AlertService,
    private _service: RadMetersService,
    private dialogRef: MatDialogRef<RadMeterModelDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RadMeterModel) { }

  ngOnInit(): void {
    if (this.currentMeterModel == undefined) {
      this.currentMeterModel = { name: '', description: '', status: 0 } as RadMeterModel;
    }
    this.obtainMeterModels();
    if (this.data) {
      this.getDetails(this.data);
    }
  }

  async obtainMeterModels() {
    await this._service.getRadMeterModels().toPromise().then(result => {
      if (!(result?.length)) {
        result = [];
      }
      this.meterModelsList = result;
    });
  }

  async saveMeterModel() {
    const meterModel = this.createMeterModel();
    if (await this.isValid()) {
      if (!this.currentMeterModel.id) {
        const meterModelSearch = this.meterModelsList.filter(cat => cat.name?.trim() == this.currentMeterModel.name?.trim()).length;
        if (meterModelSearch == 0) {
          await this._service.createRadMeterModel(meterModel).toPromise().then(result => {
            this.currentMeterModel.id = result?.id;
          }).finally(() => {
            this.dialogRef.close('Create');
          });
        }
        else {
          this.alert.warning('Warning !! There is already a category with the name entered!!');
        }
      } else {
        meterModel.id = this.currentMeterModel.id;
        this._service.updateRadMeterModel(meterModel).toPromise().then(result => {
        }).finally(() => {
          this.dialogRef.close('Update');
        });
      }
    }
  }

  async isValid() {
    this.valid = true;
    if (!this.currentMeterModel.name) {
      this.alert.error('Field <b>Name</b> should not be blank!');
      this.valid = false;
    }
    if (!this.currentMeterModel.description) {
      this.alert.error('Field <b>Description</b> should not be blank!');
      this.valid = false;
    }
    return this.valid;
  }

  createMeterModel(): RadMeterModel {
    const radMeterModel: RadMeterModel = {
      name: this.currentMeterModel.name,
      description: this.currentMeterModel.description,
      status: this.currentMeterModel.status,
      id: undefined,
      type: undefined,
      code: ''
    };
    return radMeterModel;
  }

  getDetails(data: RadMeterModel) {
    this.clear();
    if (!data.id) {
      this.currentMeterModel.description = data.description;
      this.currentMeterModel.name = data.name;
      this.currentMeterModel.status = data.status;
      this.showCancelButton = true;
      this.adding = true;
      this.modifying = false;
      this.disabledStatus = false;
      this.selected = false;
    }
    else {
      this.selected = true;
      this.modifying = true;
      this.adding = false;
      this.disabledStatus = false;
      this.oldName = data.name;
      this.oldDescription = data.description;
      this.oldStaus = data.status;
      this.currentMeterModel.id = data.id;
      this.currentMeterModel.description = data.description;
      this.currentMeterModel.name = data.name;
      this.currentMeterModel.status = data.status;
      this.showCancelButton = true;
    }
  }

  clear() {
    this.currentMeterModel = { name: '', description: '', status: 0 } as RadMeterModel;
    this.adding = false;
    this.selected = false;
    this.modifying = false;
    this.isModifiying = false;
    this.disabledStatus = false;
    this.showCancelButton = false;
    this.disabledTimer = false;
  }

  close() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
