<div [style.max-height]="maxHeight">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col">
        <filter-box (changed)="applyFilter($event)"></filter-box>
      </div>
    </div>
    <div class="d-flex flex-wrap justify-content-end">
      <span class="small ml-auto mb-2"
        ><mat-slide-toggle
          color="warn"
          (change)="loadRSSWAs()"
          [(ngModel)]="showClosed"
          >Show Closed/Canceled RSSWA</mat-slide-toggle
        ></span
      >
    </div>
  </div>
  <div class="left-table-container table-container mat-container scrollbar">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="serialNo">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          No.
        </mat-header-cell>
        <mat-cell
          class="font-weight-bold"
          [ngClass]="element.formStatus.color"
          *matCellDef="let element"
          nowrap
          ><span
            class="nowrap"
            [innerHTML]="element.serialNo | highlightSearch : filter"
          ></span>
          <mat-icon color="accent" *ngIf="element.editingById" class="editing"
            >edit</mat-icon
          >
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="affectedSystemsNames">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Affected System(s)
        </mat-header-cell>
        <mat-cell
          class="pl-1"
          [ngClass]="element.formStatus.color"
          *matCellDef="let element"
        >
          <span
            [innerHTML]="
              element.affectedSystemsNames | highlightSearch : filter
            "
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="statusName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </mat-header-cell>
        <mat-cell
          class="pl-1"
          [ngClass]="element.formStatus.color"
          *matCellDef="let element"
        >
          <span
            [innerHTML]="element.statusName | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        #matrow
        *matRowDef="let row; columns: displayedColumns"
        (click)="getValues(row)"
        class="table-row"
        [matTooltip]="row.description"
        [matTooltipPosition]="'right'"
        matTooltipClass="tooltip-white tooltip-wide"
        [ngClass]="{ 'mat-row-highlight': selectedRowIndex == row.id }"
        [attr.position]="getPosition(row)"
      ></mat-row>
    </mat-table>
  </div>
</div>
