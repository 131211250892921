import { MessageBanner } from "src/app/components/messages/services/message-banner";

export class Alert {
  static readonly type = '[Alert] Alert';
  constructor(public item: MessageBanner) { }
}

export class OlogAlert {
  static readonly type = '[Alert] OlogAlert';
  constructor(public response: MessageBanner) { }
}
