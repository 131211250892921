import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { CommentsAndAttachmentsComponent } from 'src/app/controls/comments-and-attachments/comments-and-attachments.component';
import { Form } from 'src/app/services/forms/form';
import { PpstbMainS1Component } from './ppstb-main-s1/ppstb-main-s1.component';

@Component({
  selector: 'ppstb-main',
  templateUrl: './ppstb-main.component.html',
  styleUrls: ['./ppstb-main.component.scss']
})
export class PpstbMainComponent extends BaseComponent implements OnChanges {
  @Input() form?: Form | null;
  @Input() showAmendment?: boolean;
  @Output() loading = new EventEmitter<boolean>();
  @Output() formCreated = new EventEmitter<Form>();
  @Output() formCancelled = new EventEmitter();

  @ViewChild(PpstbMainS1Component) section1!: PpstbMainS1Component;
  @ViewChild(CommentsAndAttachmentsComponent) comments!: CommentsAndAttachmentsComponent;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.comments && this.form) {
      this.comments.currentDocument = {
        status: this.form.formVersion?.statusID,
        serialNo: this.form.sequenceNumber?.number,
        type: FormType.PPSTB,
      };
      this.comments?.refresh();
    }
    this.setHeights();
  }

}
