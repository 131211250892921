<div class="card">
  <div class="card-header">
    <span class="card-title">Email Notification ({{ serialNo }})</span>
  </div>
  <div class="card-body px-5">
    <div class="row">
      <div class="col-12 col-lg-2 float-lg-right">To:</div>
      <div class="col-12 col-lg-10 border">
        <mat-label>
          {{ toAddresses }}
          <span *ngFor="let e of newEmails"
            >, {{ e }}
            <a *ngIf="!readOnly" class="icon" (click)="removeEmail(e)"
              ><mat-icon>remove_circle</mat-icon></a
            ></span
          >
        </mat-label>
        <a
          *ngIf="!readOnly"
          (click)="showAddEmailField()"
          class="pointer pl-2 float-right"
          ><i class="material-icons">add_circle</i></a
        >
        <mat-form-field *ngIf="AddEmailRecipients">
          <input
            matInput
            placeholder="Add Email Address"
            [(ngModel)]="emails"
            (keyup)="validateEmailAddresses()"
            (blur)="checkIfEmpty()"
            #emailsInput
            id="emailsInput"
            autofocus
          />
          <mat-hint *ngIf="!emailError">Comma separated email list!</mat-hint>
          <mat-hint class="error" *ngIf="emailError"
            >Enter a valid email address</mat-hint
          >
        </mat-form-field>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-2">Subject:</div>
      <div class="col-10 border">
        <mat-label [innerHTML]="Subject"></mat-label>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-2">Message:</div>
      <div class="col-10 border">
        <mat-label [innerHTML]="Message"></mat-label>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-2">Custom text:</div>
      <div class="col-10">
        <mat-form-field>
          <textarea
            matInput
            [disabled]="readOnly"
            placeholder="Write custom message for the notification"
            Rows="3"
            [(ngModel)]="AdditionalText"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Signature:</div>
      <div class="col-10">
        <mat-label [innerHTML]="Signature"></mat-label>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <button
      mat-flat-button
      color="warn"
      class="float-left"
      (click)="cancel()"
      *ngIf="!confirmation || data.send"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="accent"
      class="float-left"
      (click)="cancel()"
      *ngIf="confirmation"
    >
      Continue without Sending
    </button>

    <button
      [matTooltip]="disabledTooltipMessage"
      matTooltipClass="warn-tooltip"
      mat-flat-button
      color="primary"
      class="float-right"
      (click)="disableSend ? continue() : sendNotification()"
      *ngIf="confirmation || data.send"
      [disabled]="disableSend"
      [matTooltipDisabled]="!disableSend"
    >
      Send Email Notification
    </button>
    <button
      mat-flat-button
      color="primary"
      class="float-right"
      style="width: 7em"
      [disabled]="readOnly || emailError"
      (click)="saveNotification()"
      *ngIf="!confirmation && !data.send"
    >
      Save
    </button>
  </div>
</div>
