import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ReviewSatisfactionChecksCreate, ReviewSatisfactionChecksDelete, ReviewSatisfactionChecksRefresh, ReviewSatisfactionChecksUpdate } from './review-satisfaction-checks.action';
import { ReviewsService } from '../../reviews.service';
import { ReviewSatisfactionCheck } from '../../reviews.models';

export class ReviewSatisfactionChecksStateModel {
  data!: ReviewSatisfactionCheck[];
}

@State<ReviewSatisfactionChecksStateModel>({
  name: 'ReviewSatisfactionChecks',
  defaults: {
    data: []
  }
})
@Injectable()
export class ReviewSatisfactionChecksState {
  constructor(
    private _service: ReviewsService
  ) { }

  @Selector()
  static read(state: ReviewSatisfactionChecksStateModel) {
    return state.data;
  }

  @Action(ReviewSatisfactionChecksRefresh)
  refresh({ patchState }: StateContext<ReviewSatisfactionChecksStateModel>) {
    this._service.getReviewSatisfactionChecks().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ReviewSatisfactionChecksCreate)
  create({ getState, patchState }: StateContext<ReviewSatisfactionChecksStateModel>, { item }: ReviewSatisfactionChecksCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ReviewSatisfactionChecksUpdate)
  update({ getState, patchState }: StateContext<ReviewSatisfactionChecksStateModel>, { id, item }: ReviewSatisfactionChecksUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ReviewSatisfactionChecksDelete)
  delete({ getState, patchState }: StateContext<ReviewSatisfactionChecksStateModel>, { id }: ReviewSatisfactionChecksDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
