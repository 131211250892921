<div class="card">
  <div class="card-header">
    <span class="card-title">Attach Review Link</span>
  </div>

  <div class="card-body">
    <mat-form-field class="no-subscript">
      <mat-label>Select a category</mat-label>
      <mat-select [(value)]="typeSelected">
        <mat-option
          [value]="itemList.id"
          *ngFor="let itemList of menuListCategories"
          >{{ itemList.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="no-subscript">
      <mat-label>Hyperlink Text</mat-label>
      <textarea
        matInput
        [(ngModel)]="hyperlink"
        rows="5"
        placeholder="Hyperlink Text"
        [ngModelOptions]="{ standalone: true }"
      ></textarea>
    </mat-form-field>
    <mat-form-field class="no-subscript">
      <mat-label>URL</mat-label>
      <textarea
        matInput
        [(ngModel)]="webAddress"
        rows="5"
        placeholder="URL"
        [ngModelOptions]="{ standalone: true }"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
      <button mat-flat-button color="primary" (click)="SaveFiles()">
        Accept
      </button>
    </div>
  </div>
</div>
