import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RSSWAResource, SaveRSSWAResource } from '../rsswa';
import { RSSWARefresh, RSSWACreate, RSSWAUpdate, RSSWADelete, RSSWARefreshByID } from './rsswa.action';
import { RsswaService } from '../rsswa.service';

export class RSSWAStateModel {
  data: RSSWAResource[] = [];
}

@State<RSSWAStateModel>({
  name: 'RSSWA',
  defaults: {
    data: [],
  }
})
@Injectable()
export class RSSWAState {
  constructor(
    private service: RsswaService
  ) { }

  @Selector()
  static read(state: RSSWAStateModel) {
    return state.data;
  }

  @Action(RSSWARefresh)
  refresh({ patchState }: StateContext<RSSWAStateModel>) {
    this.service.getAll().subscribe(data => {
      const rsswas = data as RSSWAResource[];
      patchState({ data: rsswas });
    },
      error => console.error(error)
    );
  }

  @Action(RSSWARefreshByID)
  refreshByID({ getState, patchState }: StateContext<RSSWAStateModel>, { id }: RSSWARefreshByID) {
    this.service.getById(id).subscribe(
      data => {
        const rsswa = data as RSSWAResource;
        const state = getState();
        const index = state.data.map(x => x.id).indexOf(id);

        if (index >= 0) {
          state.data.splice(index, 1);
        }
        patchState({
          data: [...state.data, rsswa]
        });
      }
    );
  }

  @Action(RSSWACreate)
  create({ getState, patchState }: StateContext<RSSWAStateModel>, { item }: RSSWACreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(RSSWAUpdate)
  update({ getState, patchState }: StateContext<RSSWAStateModel>, { id, item }: RSSWAUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...state.data, item]
    });
  }

  @Action(RSSWADelete)
  delete({ getState, patchState }: StateContext<RSSWAStateModel>, { id }: RSSWADelete) {
    patchState({
      data: getState().data.filter(x => x.id != id)
    });
  }


}
