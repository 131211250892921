import { ReviewSatisfactionCheck } from '../../reviews.models';

export class ReviewSatisfactionChecksRefresh {
  static readonly type = '[ReviewSatisfactionChecks] Refresh';
}

export class ReviewSatisfactionChecksCreate {
  static readonly type = '[ReviewSatisfactionChecks] Create';

  constructor(public item: ReviewSatisfactionCheck) { }
}

export class ReviewSatisfactionChecksUpdate {
  static readonly type = '[ReviewSatisfactionChecks] Update';

  constructor(public id: number, public item: ReviewSatisfactionCheck) { }
}

export class ReviewSatisfactionChecksDelete {
  static readonly type = '[ReviewSatisfactionChecks] Delete';
  constructor(public id: number) { }
}
