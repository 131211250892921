import { map } from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RSSWAResource } from '../../rsswa';

@Component({
  selector: 'rss-functional-test-list',
  templateUrl: './rss-functional-test-list.component.html',
  styleUrls: ['./rss-functional-test-list.component.scss']
})
export class RssFunctionalTestListComponent implements OnChanges {

  @Input() rsswa?: RSSWAResource | null;

  ngOnChanges(changes: SimpleChanges): void {
    this.rsswa?.rssFunctionalTests?.map(ft => {
      if (ft.checklist)
        ft.checklist.name = ft.name;
    });
  }

}