<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-2">
        <h6>{{ number ?? "#" }}</h6>
      </div>
      <div class="col-9 pt-2 d-flex">
        <mat-label
          [innerHTML]="textBefore ?? '' | placeholderHighlight : editor"
        ></mat-label>
        <mat-label class="px-1"
          ><b>{{ resource?.name?.trim() }} </b></mat-label
        >
        <mat-label
          [innerHTML]="textAfter ?? '' | placeholderHighlight : editor"
        ></mat-label>
      </div>
      <div class="col-2 d-flex flex-row justify-content-end align-items-center">
        <mat-icon-with-tootip
          *ngIf="manual"
          (click)="reset()"
          class="warning-icon"
          color="accent"
          icon="warning"
          [toolTipText]="
            documents +
            '<br><span class=\'small\'>This checkbox was manually ' +
            (this.checked ? 'Checked' : 'Unchecked') +
            '<br>[Click to Reset!]</span>'
          "
          toolTipClass="accent-tooltip"
          toolTipPosition="left"
        ></mat-icon-with-tootip>

        <mat-checkbox-with-roles
          [checked]="checked"
          [disabled]="disabled"
          [labelPosition]="'before'"
          [roleCodes]="roleCodes"
          [user]="user"
          [warning]="moreInfo"
          (change)="checkboxChanged($event)"
          color="primary"
          *ngIf="checkboxVisible"
          [date]="date"
        >
        </mat-checkbox-with-roles>
      </div>
    </div>
  </div>
</div>
