<div class="body">
  <mat-toolbar class="toolbar">
    <div class="menu d-print-none">
      <button mat-icon-button class="noOutline" (click)="toggleSidenav()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div class="full-width d-flex">
      <div class="row full-width">
        <div class="col-sm-12 col-md-2 d-print-none d-flex">
          <div
            class="toolbar-title d-flex flex-column justify-content-center serial-title"
          >
            <div
              class="no-wrap"
              [matTooltip]="checklistDocumentType?.description ?? ''"
            >
              {{ checklistDocumentType?.description }}
            </div>

            <div
              [ngClass]="currentChecklist.checklistStatus?.cssClass"
              *ngIf="currentChecklist"
              id="serialNumber"
            >
              {{ currentChecklist.serialNo }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-10 pr-0">
          <div
            class="row no-gutters d-flex justify-content-between flex-nowrap"
          >
            <div class="col p-1" *ngIf="isProcedureRelated">
              <div class="type-header">
                <mat-form-field appearance="outline">
                  <mat-label>Procedure</mat-label>
                  <input
                    class="field"
                    type="text"
                    placeholder="Select Procedure"
                    matInput
                    name="procedureCtrl"
                    [formControl]="procedureCtrl"
                    [matAutocomplete]="autoProcedure"
                    #typeTrigger="matAutocompleteTrigger"
                    (keyup)="changedProcedure()"
                  />

                  <mat-icon
                    class="close-icon"
                    *ngIf="currentProcedure && creating"
                    color="warn"
                    (click)="clearSelection()"
                    >close</mat-icon
                  >
                  <mat-autocomplete
                    #autoProcedure="matAutocomplete"
                    #procedureAutocomplete
                    [displayWith]="displayProcedure"
                    (optionSelected)="selectedProcedure($event)"
                  >
                    <mat-option
                      *ngFor="let procedure of filteredProcedures"
                      [value]="procedure"
                      [ngClass]="{ red: !hasTemplate(procedure) }"
                    >
                      {{ procedure.procedureNumber }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-hint class="error" *ngIf="!currentProcedure"
                    >Optional</mat-hint
                  >
                  <mat-hint *ngIf="currentProcedure">
                    Rev. {{ currentProcedure.revision }}
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div
              class="col p-1"
              *ngIf="(documentType && creating) || currentChecklist"
            >
              <div class="type-header">
                <mat-form-field appearance="outline">
                  <mat-label>Type</mat-label>
                  <input
                    class="field"
                    type="text"
                    placeholder="Select Type"
                    matInput
                    name="typeCtrl"
                    [formControl]="typeCtrl"
                    [matAutocomplete]="autoType"
                    #typeTrigger="matAutocompleteTrigger"
                  />

                  <mat-icon
                    class="close-icon"
                    *ngIf="currentTemplateType && creating"
                    color="warn"
                    (click)="clearSelection()"
                    >close</mat-icon
                  >
                  <mat-autocomplete
                    #autoType="matAutocomplete"
                    #typeAutocomplete
                    [displayWith]="displayObject"
                    (optionSelected)="selectedType($event)"
                  >
                    <mat-option
                      *ngFor="let type of filteredTemplateTypes"
                      [value]="type"
                    >
                      {{ type.name }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-hint class="error" *ngIf="!currentTemplateType"
                    >You must choose a Type</mat-hint
                  >
                  <mat-hint *ngIf="currentTemplateType">
                    {{ currentTemplateType.description }}
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="col p-1" *ngIf="scheduleBased || resourceBased">
              <div class="type-header">
                <mat-form-field
                  appearance="outline"
                  class="mat-form-field-header"
                  *ngIf="
                    (documentType && creating) ||
                    (currentChecklist && (resourceBased || scheduleBased))
                  "
                >
                  <mat-label>Resource</mat-label>
                  <input
                    class="field"
                    type="text"
                    placeholder="Select Resource"
                    matInput
                    name="templateCtrl"
                    [formControl]="templateCtrl"
                    [matAutocomplete]="autoTemplate"
                    (keyup)="filterTemplates($event)"
                  />

                  <mat-icon
                    class="close-icon"
                    *ngIf="currentChecklistTemplate && creating"
                    color="warn"
                    (click)="clearSelection()"
                    >close</mat-icon
                  >
                  <mat-autocomplete
                    #autoTemplate="matAutocomplete"
                    #templateAutocomplete
                    [displayWith]="displayTemplate"
                    (optionSelected)="selectedTemplate($event.option.value)"
                  >
                    <mat-option
                      *ngFor="let template of checklistTemplatesFiltered"
                      [value]="template"
                    >
                      {{
                        template.resource
                          ? template.resource.name
                          : template.resourceString
                      }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-hint class="error" *ngIf="!currentChecklistTemplate"
                    >You must select a Resource</mat-hint
                  >
                  <mat-hint *ngIf="currentChecklistTemplate">
                    {{ currentChecklistTemplate.resource?.description }}
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>

            <div
              class="col p-1"
              *ngIf="currentTemplateType?.allowMultipleVersions"
            >
              <div class="type-header">
                <mat-form-field
                  appearance="outline"
                  class="mat-form-field-header"
                  *ngIf="(documentType && creating) || currentChecklist"
                >
                  <mat-label>Name</mat-label>
                  <input
                    class="field"
                    type="text"
                    placeholder="Select Template Name"
                    matInput
                    name="templateCtrl"
                    [formControl]="templateCtrl"
                    [matAutocomplete]="autoTemplate"
                  />

                  <mat-icon
                    class="close-icon"
                    *ngIf="currentChecklistTemplate && creating"
                    color="warn"
                    (click)="clearSelection()"
                    >close</mat-icon
                  >
                  <mat-autocomplete
                    #autoTemplate="matAutocomplete"
                    #templateAutocomplete
                    [displayWith]="displayObject"
                    (optionSelected)="selectedTemplateName($event)"
                  >
                    <mat-option
                      *ngFor="let template of checklistTemplates"
                      [value]="template"
                    >
                      {{ template.name }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-hint class="error" *ngIf="!currentChecklistTemplate"
                    >You must select a Template</mat-hint
                  >
                </mat-form-field>
              </div>
            </div>

            <div
              class="col p-1"
              *ngIf="currentChecklistTemplate && currentChecklist"
            >
              <div class="type-header">
                <mat-form-field>
                  <mat-label>Template Version</mat-label>
                  <input
                    [ngClass]="{
                      gray: !currentChecklist,
                      yellow:
                        currentChecklist && !currentChecklist.verificationList,
                      green:
                        currentChecklist.verificationList &&
                        currentChecklist.verifiedOK,
                      red:
                        currentChecklist.verificationList &&
                        !currentChecklist.verifiedOK
                    }"
                    class="version"
                    type="text"
                    matInput
                    [ngModel]="currentChecklistTemplate.serialNo"
                    [disabled]="true"
                  />
                  <mat-icon
                    *ngIf="
                      currentChecklist && !currentChecklist.verificationList
                    "
                    class="verified-icon yellow"
                    >gpp_maybe</mat-icon
                  >
                  <mat-icon
                    *ngIf="
                      currentChecklist &&
                      currentChecklist?.verificationList &&
                      currentChecklist?.verifiedOK
                    "
                    class="verified-icon green"
                    >verified_user</mat-icon
                  >
                  <mat-icon
                    *ngIf="
                      currentChecklist &&
                      currentChecklist?.verificationList &&
                      !currentChecklist?.verifiedOK
                    "
                    class="verified-icon red"
                    >gpp_bad</mat-icon
                  >
                  <mat-hint>
                    <button
                      *ngIf="
                        currentChecklist &&
                          !currentChecklist?.verificationList!;
                        loading
                      "
                      mat-button
                      class="hint-button"
                      (click)="openVerificationList()"
                      color="accent"
                    >
                      Unverified! (Click here to Verify)
                    </button>
                    <button
                      mat-stroked-button
                      class="hint-button green"
                      *ngIf="
                        currentChecklist &&
                        currentChecklist?.verificationList &&
                        currentChecklist?.verifiedOK &&
                        !loading
                      "
                      (click)="openVerificationList()"
                    >
                      <span class="green">Verified!</span>
                    </button>
                    <button
                      mat-button
                      class="hint-button"
                      color="warn"
                      *ngIf="
                        currentChecklist &&
                        currentChecklist?.verificationList &&
                        !currentChecklist?.verifiedOK &&
                        !loading
                      "
                      (click)="openVerificationList()"
                    >
                      Verification Error!!
                    </button>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>

            <div class="col p-1" *ngIf="currentChecklist">
              <div class="type-header">
                <mat-form-field>
                  <mat-label>Operator Initiating Checklist</mat-label>
                  <input
                    class="field"
                    type="text"
                    matInput
                    [ngModel]="currentChecklist.createdByName"
                    [disabled]="true"
                  />
                  <mat-hint
                    >On:
                    <span class="date">{{
                      currentChecklist.createdOn | date : "short"
                    }}</span></mat-hint
                  >
                </mat-form-field>
              </div>
            </div>
            <div
              class="col d-flex pt-2"
              *ngIf="currentChecklist?.relatedDocuments?.length"
            >
              <checklist-exec-related
                class="full-width"
                [checklist]="currentChecklist"
              ></checklist-exec-related>
            </div>
            <div
              class="col reason full-width d-flex flex-column justify-content-center"
              *ngIf="
                currentChecklist &&
                currentChecklist.checklistStatus?.formStatusID ==
                  formStatus.Canceled
              "
            >
              <div class="small">Reason of Cancellation:</div>
              <div>
                <b class="red small">
                  {{ currentChecklist.canceledComments }}
                </b>
              </div>
            </div>

            <div class="col p-1 align-self-center d-print-none">
              <div class="d-flex justify-content-end">
                <span class="pt-1 mr-1">
                  <button
                    mat-flat-button
                    color="accent"
                    (click)="create()"
                    *ngIf="currentChecklistTemplate && creating"
                  >
                    Create
                  </button>
                </span>
                <span class="pt-1">
                  <button
                    mat-flat-button
                    color="warn"
                    (click)="noCreate()"
                    *ngIf="creating"
                  >
                    Cancel
                  </button>
                </span>
                <span class="pt-1">
                  <button
                    mat-flat-button
                    color="warn"
                    (click)="cancel()"
                    *ngIf="
                      currentChecklist &&
                      (((rssFunctionalTest == null ||
                        rssFunctionalTest === undefined) &&
                        (currentChecklist?.checklistStatus?.formStatusID ==
                          formStatus.Draft ||
                          checkStatus())) ||
                        (rssFunctionalTest != null &&
                          rssFunctionalTest !== undefined &&
                          currentChecklist?.checklistStatus?.formStatusID ==
                            formStatus.Active)) &&
                      checkPriv(checklistPrivilege.CreateChecklist)
                    "
                  >
                    Cancel Checklist
                  </button></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="expand-column-header" *ngIf="isCollapsible">
        <button
          mat-icon-button
          color="primary"
          (click)="expandCollapse(expandedAll)"
          *ngIf="currentChecklist"
          [matTooltip]="!expandedAll ? 'Expand All' : 'Collapse All'"
        >
          <mat-icon *ngIf="!expandedAll">unfold_more</mat-icon>
          <mat-icon *ngIf="expandedAll">unfold_less</mat-icon>
        </button>
      </div>
      <checklist-exec-review-summary
        [currentChecklist]="currentChecklist"
        *ngIf="currentChecklist"
      ></checklist-exec-review-summary>
    </div>
  </mat-toolbar>
  <mat-sidenav-container
    class="sidenav-container mat-sidenav-collapse-transition"
    autosize
  >
    <mat-sidenav
      [ngClass]="{ 'mat-sidenav-collapsed': !isExpanded && !isShowing }"
      #sidenav
      opened="true"
      class="d-print-none"
      mode="side"
      (mouseenter)="mouseenter()"
      (mouseleave)="mouseleave()"
    >
      <mat-nav-list>
        <mat-list-item>
          <mat-icon
            matListItemIcon
            *ngIf="!isExpanded && !isShowing"
            class="blue"
            >search</mat-icon
          >
          <filter-box
            (changed)="applyFilter($event)"
            *ngIf="isExpanded || isShowing"
          ></filter-box>
        </mat-list-item>
        <mat-list-item style="height: unset">
          <div [ngClass]="{ hidden: !isExpanded && !isShowing }">
            <checklist-exec-side
              [documentTypeId]="documentTypeId"
              (rowSelected)="rowSelected($event)"
              (loading)="setLoading($event, 't')"
              [filter]="filter"
            >
            </checklist-exec-side>
          </div>
        </mat-list-item>
      </mat-nav-list>
      <mat-nav-list id="left-nav-buttons">
        <mat-list-item
          (click)="createNew()"
          class="parent"
          *ngIf="!creating && checkPriv(checklistPrivilege.CreateChecklist)"
        >
          <span class="full-width" *ngIf="isExpanded || isShowing"
            >Create New</span
          >
          <mat-icon matListItemIcon class="yellow">add_box</mat-icon>
        </mat-list-item>
        <mat-list-item
          (click)="openReview()"
          class="parent"
          *ngIf="currentChecklist?.review"
        >
          <span class="full-width" *ngIf="isExpanded || isShowing"
            >Open Linked {{ currentChecklist?.review?.name }}</span
          >
          <mat-icon matListItemIcon class="orange">reviews</mat-icon>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content px-0">
      <checklist-exec-precheck-toggle
        *ngIf="currentChecklist?.checklistTemplate?.templateType?.code == 'BAR'"
        (prechecChanged)="precheckChanged($event)"
      ></checklist-exec-precheck-toggle>

      <!---  Sections  --->
      <checklist-sections
        [currentChecklist]="currentChecklist"
        [precheck]="precheck"
        (loading)="setLoading($event, 's')"
      ></checklist-sections>
      <!------  ------------->
    </mat-sidenav-content>
  </mat-sidenav-container>
  <overlay-loading *ngIf="loading"></overlay-loading>
</div>
