<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <input
    matInput
    type="text"
    [matAutocomplete]="aplCategoryAutocomplete"
    [disabled]="disabled"
    (keyup)="filter($event.code)"
    [(ngModel)]="text"
  />
  <mat-autocomplete
    #aplCategoryAutocomplete="matAutocomplete"
    (optionSelected)="select($event)"
  >
    <mat-option
      *ngFor="let aplCategory of filteredAplCategories"
      [value]="aplCategory"
    >
      {{ aplCategory?.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
