import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PPSTBFollowUpType } from 'src/app/components/schedules/models/enums';
import { Form } from 'src/app/services/forms/form';
import { PPSTBOtherFollowUp } from '../../ppstb-other-follow-up';
import { FormVersionHandler, FormVersion } from 'src/app/services/forms/form-version';
import { FormVersionService } from 'src/app/services/forms/form-version.service';

@Component({
  selector: 'ppstb-main-s8',
  templateUrl: './ppstb-main-s8.component.html',
  styleUrls: ['./ppstb-main-s8.component.scss']
})
export class PpstbMainS8Component extends BaseComponent {
  @Input() form?: Form | null;
  @Input() disabled!: boolean;

  @Output() loading = new EventEmitter<boolean>();

  ppstbFollowUpType = PPSTBFollowUpType;

  constructor(
    protected override injector: Injector,
    private service: FormVersionService
  ) {
    super(injector);
  }

  async approveFollowUp(info: any, type: number) {
    const ppstbOtherFollowUp = info.ppstbOtherFollowUp as PPSTBOtherFollowUp;
    if (this.form && this.form.formVersion.ppstb) {
      if (!ppstbOtherFollowUp?.id) {
        const ppstbOtherFollowUpToSave: PPSTBOtherFollowUp = {
          ppstbID: this.form.formVersion.ppstb.id,
          type,
          text: info.details
        };

        this.form.formVersion.ppstb.ppstbOtherFollowUps.push(ppstbOtherFollowUpToSave);

      } else {
        const followUp = this.form?.formVersion.ppstb.ppstbOtherFollowUps.find(x => x.id === ppstbOtherFollowUp.id);
        if (followUp)
          followUp.text = info.details;
      }

      switch (type) {
        case PPSTBFollowUpType.RepairReplaceComponent:
          this.form.formVersion.ppstb.repairReplaceComponent = info.value;
          break;
        case PPSTBFollowUpType.ModifyInterlock:
          this.form.formVersion.ppstb.modifyInterlock = info.value;
          break;
        case PPSTBFollowUpType.Other:
          this.form.formVersion.ppstb.other = info.value;
          break;
      }
      this.save(true);
    }
  }

  save(init: boolean = false) {
    if (this.form) {
      this.loading.emit(true);
      this.service.update(FormVersionHandler.toSave(this.form.formVersion)).subscribe((data: FormVersion) => {
        this.loading.emit(false);
      },
        error => {
          console.error(error);
          this.alert.defaultError();
          this.loading.emit(false);
        }

      );
    }

  }
}
