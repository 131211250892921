<h5 mat-dialog-title>{{ inputData.dialogTitle }}</h5>
<div mat-dialog-content>
  <mat-form-field>
    <input
      matInput
      name="tocasCtrl"
      [formControl]="tocasCtrl"
      type="text"
      placeholder="TOCA"
      [matAutocomplete]="auto"
      (keyup)="filter($event)"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayObject"
      (optionSelected)="selectedToca($event)"
    >
      <mat-option *ngFor="let toca of tocasFiltered" [value]="toca">
        {{ toca.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
<div mat-dialog-actions align="center">
  <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="add()">Accept</button>
</div>
