<div class="card full-height">
  <div class="card-header">
    <span class="card-title">Settings</span>
    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="card-body pt-0 rad-mon-card">
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Types"><rad-meters-types></rad-meters-types></mat-tab>
      <mat-tab label="Models"><rad-meter-models></rad-meter-models></mat-tab>
      <mat-tab label="Units"><rad-meters-units></rad-meters-units></mat-tab>
    </mat-tab-group>
  </div>
</div>
