<div class="row h-full w-100" *ngIf="notFoundText">
  <div
    class="col d-flex w-100 h-100 justify-content-center align-items-center align-content-center"
  >
    <span class="not-found text-center"
      ><mat-icon class="large">search_off</mat-icon><br />{{
        notFoundText
      }}</span
    >
  </div>
</div>
<overlay-loading *ngIf="loading" [message]="message"></overlay-loading>
