import { Component, EventEmitter, Injector, Input, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { NotificationTemplate } from 'src/app/components/catalogs/notifications/services/notification-template';
import { CatalogService } from '../../catalog-service';
import { Catalog } from 'src/app/common/enumerations/enumerations';
import { MatDialogConfig } from '@angular/material/dialog';
import { DisapprovalReasonComponent } from 'src/app/controls/disapproval-reason/disapproval-reason.component';
import { CatalogModel } from '../../catalog-model';
import { add } from 'lodash';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { NotificationTemplateRefresh } from '../store/notification-template.action';

@Component({
  selector: 'notifications-details',
  templateUrl: './notifications-details.component.html',
  styleUrls: ['./notifications-details.component.scss']
})
export class NotificationsDetailsComponent extends BaseComponent {

  @Input() notificationTemplate?: NotificationTemplate | null;
  @Input() showChanges?: boolean;
  @Output() loading = new EventEmitter<boolean>();

  tmpNotificationTemplate!: NotificationTemplate;

  adding?: boolean;
  modifying?: boolean;
  item = Item;

  showApproveUnapprove?: boolean;
  isModifiying?: boolean;
  isModifier?: boolean;

  constructor(
    protected override injector: Injector,
    private catalogService: CatalogService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.notificationTemplate)
      this.getDetails(this.notificationTemplate);
  }

  getDetails(data: NotificationTemplate) {
    this.clearForm();
    if (data.id < 0) {
      this.catalogService.GetPendingCatalogById(data.id, Catalog.EmailNotificationCatalog).subscribe(
        data => {
          this.notificationTemplate = data.notificationTemplate as NotificationTemplate;
          this.isModifiying = true;
          this.showApproveUnapprove = true;
        }
      );
    } else {
      this.notificationTemplate = data;
    }
    if (!data.id) {
      this.adding = true;
      this.modifying = false;
    } else {
      if (data.id > 0) {
        this.catalogService.IsModifiyingById(data.id, Catalog.EmailNotificationCatalog).subscribe(
          data => {
            this.isModifiying = data.isModifying as boolean;
            this.isModifier = data.userID == this.currentUser?.id;
            if (this.isSA() && this.currentUser?.id != data.userID) {
              this.showApproveUnapprove = data.isModifying as boolean;
            }
            if (this.isModifiying && this.showChanges && this.notificationTemplate) {
              this.tmpNotificationTemplate = this.utils.cloneDeep(this.notificationTemplate);
              this.catalogService.GetPendingCatalogById(this.notificationTemplate.id, Catalog.EmailNotificationCatalog).subscribe(
                data => {
                  this.notificationTemplate = data.notificationTemplate as NotificationTemplate;
                }
              );
            }
            else if (!this.showChanges && this.tmpNotificationTemplate && this.tmpNotificationTemplate.id == this.notificationTemplate?.id) {
              this.notificationTemplate = this.tmpNotificationTemplate;
            }
          }
        );

      }
      this.modifying = false;
      this.adding = false;
    }
  }

  modify() {
    this.tmpNotificationTemplate = this.utils.cloneDeep(this.notificationTemplate) as NotificationTemplate;
    this.modifying = true;
  }

  clearForm() {
    this.setFormDirty(false);
    this.notificationTemplate = null;
    this.adding = false;
    this.modifying = false;
    this.showApproveUnapprove = false;
    this.isModifiying = false;
  }

  changed(item: Item, value: string) {
    if (this.notificationTemplate) {
      switch (item) {
        case Item.Name:
          this.notificationTemplate.name = value;
          break;
        case Item.Body:
          this.notificationTemplate.messageBody = value;
          break;
        case Item.Signature:
          this.notificationTemplate.signature = value;
          break;
        case Item.Subject:
          this.notificationTemplate.messageSubject = value;
          break;
      }
      this.setFormDirty();
    }
  }

  reset() {
    if (this.adding)
      this.clearForm();
    else {
      this.modifying = false;
      this.notificationTemplate = this.tmpNotificationTemplate;
      this.setFormDirty(false);
      if (this.notificationTemplate)
        this.getDetails(this.notificationTemplate);
    }
  }

  approve() {
    if (this.notificationTemplate)
      this.catalogService.Approve(this.notificationTemplate.id, Catalog.EmailNotificationCatalog).subscribe(
        data => {
          this.alert.success('Congratulations !! Notification Template Approved Successfully!!');
          this.reset();
        }
      );
  }

  disapprove(disapproveReason: string) {
    if (this.notificationTemplate)
      this.catalogService.Unapprove(this.notificationTemplate.id, Catalog.EmailNotificationCatalog, disapproveReason).subscribe(
        data => {
          this.alert.warning('Congratulations !! Notification Template Disapproved Successfully!!');
          this.reset();
        }
      );
  }

  cancel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: "Are you sure you want to Cancel your changes?",
      icon: "question",
    };
    const dialogRef = this.dialog.open(YesNoDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (this.notificationTemplate)
          this.catalogService.Unapprove(this.notificationTemplate.id, Catalog.EmailNotificationCatalog, 'User Cancel').subscribe(
            data => {
              this.alert.warning('Changes Cancelled!');
              this.reset();
            }
          );
      }
    }
    );
  }

  openAddDisapprovalDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = '';
    const dialogRef = this.dialog.open(DisapprovalReasonComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.disapprove(data);
      }
    });
  }

  saveNotificationTemplate() {
    if (this.notificationTemplate) {
      const catalogModel = new CatalogModel();
      catalogModel.notificationTemplate = this.notificationTemplate;
      if (this.isValid()) {
        if (this.notificationTemplate.id <= 0 || this.notificationTemplate.id == undefined) {
          this.catalogService.SaveAsDraft(catalogModel, Catalog.EmailNotificationCatalog).subscribe(
            data => {
              this.alert.success('Congratulations !! The New Notification Template Has Been Sent To Approve Successfully!!');
              this.reset();
            }
          );
        } else {
          this.catalogService.SaveAsDraft(catalogModel, Catalog.EmailNotificationCatalog).subscribe(
            data => {
              this.alert.success('Congratulations !! The Notification Template Modification Has Been Sent To Approve Successfully!!');
              this.reset();
            }
          );
        }
      }
    }
  }

  isValid(): boolean {
    // TODO validations
    return true;
  }

}

enum Item {
  Name = 1,
  Subject = 2,
  Body = 3,
  Signature = 4
}
