import { Component, OnInit, OnDestroy, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BeamlineRequiringApprovalSummary } from 'src/app/components/catalogs/bratoi/models/beamline-requiring-approval-summary';
import { ClBaseComponent } from '../cl-base/cl-base.component';

@Component({
  selector: 'cl-bratoi',
  templateUrl: './cl-bratoi.component.html',
  styleUrls: ['./cl-bratoi.component.scss']
})
export class ClBRATOIComponent extends ClBaseComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  public blRequiringApprovals$!: Observable<BeamlineRequiringApprovalSummary[]>;
  public blRequiringApprovalSubscription!: Subscription;
  public blRequiringApprovals: BeamlineRequiringApprovalSummary[] = [];
  public blRequiringApproval?: BeamlineRequiringApprovalSummary;

  ngOnInit() {
    this.blRequiringApprovals$ = this.store.select(state => state.Bratoi.data);
    this.blRequiringApprovalSubscription = this.blRequiringApprovals$.subscribe(blRequiringApprovals => {
      this.blRequiringApprovals = this.utils.cloneDeep(blRequiringApprovals);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ngAfterViewInit();
  }

  override ngOnDestroy(): void {
    this.blRequiringApprovalSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  ngAfterViewInit() {
    if (this.configuration) { this.getBlRequiring(); }
    this.initials = this.user ? this.user.initials : '';
  }

  getBlRequiring() {
    if (this.configuration) {
      const id = JSON.parse(this.configuration).idBRATOIRelated;
      setTimeout(() => {
        this.blRequiringApproval = this.blRequiringApprovals.find(x => x.idBRATOIRelated == id);
      }, 100);

    }
  }

  checkboxChanged(e: any) {
    this.checked = e.checked;
    this.answered.emit(this.checked);
  }

}
