import { DocumentType } from "src/app/services/documents/documents";

export class DocumentTypeRefresh {
  static readonly type = '[DocumentType] Refresh';
}
export class DocumentTypeCreate {
  static readonly type = '[DocumentType] Create';
  constructor(public item: DocumentType) { }
}
export class DocumentTypeUpdate {
  static readonly type = '[DocumentType] Update';
  constructor(public id: number, public item: DocumentType) { }
}
export class DocumentTypeDelete {
  static readonly type = '[DocumentType] Delete';
  constructor(public id: number) { }
}
