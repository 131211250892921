import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener, Injector, } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription, Observable, distinctUntilChanged } from 'rxjs';
import { CancelDialogComponent } from 'src/app/controls/cancel-dialog/cancel-dialog.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { CommentResource } from 'src/app/controls/comments-and-attachments/services/comment';
import { ProcedureSignatureType } from 'src/app/components/procedure/enums/procedure-signature-type.enum';
import { ProcedureSignature } from 'src/app/components/procedure/models/procedure-signature.model';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { ProcedureService } from 'src/app/components/procedure/services/procedure.service';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { WFSectionLocalResource, } from 'src/app/services/work-flow/work-flow';
import { ProcedureUpdate } from '../../procedure/store/procedure/procedure.action';
import { Schedule } from '../../schedules/models/schedule';
import { ScheduleSubtype } from '../../schedules/models/schedule-subtype';
import { ScheduleType } from '../../schedules/models/schedule-type';
import { TemplateRolePermission, TemplateType, ChecklistTemplate, ChecklistTemplateStatus, ChecklistTemplateStatusEnum, ChecklistPriv, } from '../checklists';
import { TemplateTypesRefreshByDocument, TemplateTypesUpdate, } from '../store/template-types/template-types.action';
import { ChecklistBuilderDuplicateComponent } from './checklist-builder-duplicate/checklist-builder-duplicate.component';
import { ChecklistBuilderPlaceholdersComponent } from './checklist-builder-placeholders/checklist-builder-placeholders.component';
import { ChecklistBuilderService } from './checklist-builder.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { DocumentType } from 'src/app/services/documents/documents';
import * as moment from 'moment';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { ChecklistBuilderListComponent } from './checklist-builder-list/checklist-builder-list.component';
import { ChecklistBuilderReviewSummaryComponent } from './checklist-builder-review-summary/checklist-builder-review-summary.component';
import { ChecklistBuilderSectionsComponent } from './checklist-builder-sections/checklist-builder-sections.component';
import { RejectDialogComponent } from 'src/app/controls/reject-dialog/reject-dialog.component';
import { ChecklistTemplatesUpdate } from '../store/checklist-templates/checklist-templates.action';
import { ChecklistBuilderSettingsComponent } from './checklist-builder-settings/checklist-builder-settings.component';
import { ChecklistTemplatesStoreAndListenersService } from '../store/checklist-templates/checklist-templates.store';
import { PlaceholdersService } from 'src/app/services/placeholders/placeholders.service';
import { MessageTypeEnum } from '../../messages/services/message-banner.enums';
import { ChecklistBuilderMapComponent, ChecklistElement, SelectedElement } from './checklist-builder-map/checklist-builder-map.component';
import { TasksUpdateAll } from '../store/tasks/task.action';
import { DeletedTasksRefresh } from '../store/deleted-tasks/deleted-task.action';
import { DeletedStepsRefresh } from '../store/deleted-steps/deleted-step.action';
import { DeletedSectionsRefresh } from '../store/deleted-sections/deleted-section.action';

@Component({
  selector: 'checklist-builder',
  templateUrl: './checklist-builder.component.html',
  styleUrls: ['./checklist-builder.component.scss'],
})
export class ChecklistBuilderComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChild('headerTop') headerTop!: ElementRef;

  loading: boolean = false;

  documentTypeId!: number;
  documentType?: DocumentType;
  documentTypes!: DocumentType[];
  documentTypes$!: Observable<DocumentType[]>;

  currentSchedule?: Schedule | null;
  schedules!: Schedule[];
  schedules$!: Observable<Schedule[]>;
  filteredSchedules!: Schedule[];
  scheduleCtrl = new FormControl();

  documentRolePermissions!: TemplateRolePermission[];

  currentTemplateType?: TemplateType | null;
  templateTypes$!: Observable<TemplateType[]>;
  templateTypes!: TemplateType[];
  filteredTemplateTypes!: TemplateType[];
  typeCtrl = new FormControl();

  currentResource?: Resource | null;
  resources!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  filteredResources!: Resource[];
  resourceCtrl = new FormControl();

  currentVersion?: ChecklistTemplate | null;
  activeVersion?: ChecklistTemplate | null;
  versions?: ChecklistTemplate[];
  filteredVersions?: ChecklistTemplate[];
  versionCtrl = new FormControl();

  sections?: WFSectionLocalResource[];
  deletedSections?: WFSectionLocalResource[];
  showDeleted?: boolean;

  checklistTemplateStatuses!: ChecklistTemplateStatus[];
  filteredChecklistTemplateStatuses!: ChecklistTemplateStatus[];
  checklistTemplateStatusEnum = ChecklistTemplateStatusEnum;
  checklistTemplateStatuses$!: Observable<ChecklistTemplateStatus[]>;
  checklistTemplateStatusesSubs!: Subscription;

  documentTypesSubs!: Subscription;
  templateTypesSubs!: Subscription;
  schedulesSubs!: Subscription;

  procedure?: Procedure | null;
  procedurePreparerSignature?: ProcedureSignature;
  procedureReviewerSignature?: ProcedureSignature;
  procedureApproverSignature?: ProcedureSignature;

  isPreparer!: boolean;
  isReviewer!: boolean;
  isApprover!: boolean;

  scheduleTypes!: ScheduleType[];
  scheduleTypes$!: Observable<ScheduleType[]>;
  scheduleTypesSubs!: Subscription;

  scheduleSubtypes!: ScheduleSubtype[];
  scheduleSubtypes$!: Observable<ScheduleSubtype[]>;
  scheduleSubtypeSubs!: Subscription;

  checklistTemplates?: ChecklistTemplate[];
  filteredChecklistTemplates?: ChecklistTemplate[];
  checklistTemplates$!: Observable<ChecklistTemplate[]>;
  checklistTemplatesSubs!: Subscription;

  deletedSectionLocals?: WFSectionLocalResource[];
  deletedSectionLocals$!: Observable<WFSectionLocalResource[]>;
  deletedSectionLocalsSubs!: Subscription;

  nameCtrl = new FormControl();
  filteredOther?: ChecklistTemplate[];

  errorMessages?: StepError[];
  private sub: any;
  messageType = MessageTypeEnum;

  createEnabled = false;
  @ViewChild(ChecklistBuilderReviewSummaryComponent) reviewSummary!: ChecklistBuilderReviewSummaryComponent;
  @ViewChild(ChecklistBuilderSectionsComponent) sectionsComponent!: ChecklistBuilderSectionsComponent;
  @ViewChild(ChecklistBuilderListComponent) checklistBuilderList!: ChecklistBuilderListComponent;
  @ViewChild(ChecklistBuilderMapComponent) templateMap?: ChecklistBuilderMapComponent;
  @ViewChild(ChecklistBuilderMapComponent) checklistBuilderMap?: ChecklistBuilderMapComponent;

  headerOnTop!: boolean;
  message = { type: 0, text: '', cssClass: '' };

  constructor(
    protected override injector: Injector,
    private route: ActivatedRoute,
    public service: ChecklistBuilderService,
    private _procedure: ProcedureService,
    private placeholdersService: PlaceholdersService,
    private checklisttemplatesStore: ChecklistTemplatesStoreAndListenersService
  ) {
    super(injector);
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.service.pointerY = event.clientY;
  }

  ngOnInit() {
    this.setLoading({ val: true, origin: '149' });
    this.loadStatuses(true);
    this.service.loadProcedures();
    this.service.loadScheduleTypes();
    this.service.loadScheduleSubtypes();
    this.placeholdersService.loadPlaceholders();
    this.placeholdersService.clearkeyValues();
    this.loadUsers();
    this.sub = this.route.params.subscribe((params) => {
      const docType = +params['type'];
      if (this.documentTypeId != docType) {
        if (this.documentTypeId != null) {
          this.clear();
        }
        this.setLoading({ val: true, origin: '162' });
        this.service.setProcedure(null);
        this.setPrivs();
        this.documentTypeId = docType;
        this.getPermissions();
        this.documentTypes$ = this.store.select(
          (state) => state.DocumentType.data
        );
        this.documentTypesSubs = this.documentTypes$.pipe(distinctUntilChanged()).subscribe(async (data) => {
          if (data.length) {
            this.documentTypes = data;
            this.documentType = this.documentTypes.find(
              (x) => x.id == this.documentTypeId
            );

            this.store.dispatch(
              new TemplateTypesRefreshByDocument(this.documentTypeId)
            );
            this.checklisttemplatesStore.initStore(this.documentTypeId);

            await this.loadTemplateTypes();
            this.loadSchedules();
            this.loadScheduleTypes();
            this.loadScheduleSubtypes();
            this.loadChecklistTemplates();
            this.loadProcedure();

            if (this.currentTemplateType?.resourceTypeID) {
              this.loadResources().then(() => {
                this.loadVersions();
              });
            } else {
              this.loadVersions();
            }
            this.versionCtrl.disable();
            this.setLoading({ val: false, origin: '197' });
          }
        });
      }
    });
  }

  loadProcedure() {
    this.service.getProcedure().subscribe((procedure) => {
      this.procedure = procedure;
      this.procedurePreparerSignature =
        this.procedure?.procedureSignatures?.find(
          (x) =>
            x.userID == this.getCurrentUser()?.id &&
            x.signatureType == ProcedureSignatureType.Preparer
        );
      this.procedureReviewerSignature =
        this.procedure?.procedureSignatures?.find(
          (x) =>
            x.userID == this.getCurrentUser()?.id &&
            x.signatureType == ProcedureSignatureType.Reviewer
        );
      this.procedureApproverSignature =
        this.procedure?.procedureSignatures?.find(
          (x) =>
            x.userID == this.getCurrentUser()?.id &&
            x.signatureType == ProcedureSignatureType.Approver
        );
    });
  }

  loadChecklistTemplates() {
    this.checklistTemplates$ = this.store.select((state) => state.ChecklistTemplates.data);
    this.checklistTemplatesSubs = this.checklistTemplates$.pipe(distinctUntilChanged()).subscribe((data) => {
      this.checklistTemplates = data;
      this.checklistTemplates = this.service.setResources(this.checklistTemplates, this.templateTypes, this.schedules);
      this.service.checklistTemplates = this.checklistTemplates;
      if (this.currentVersion && !this.isAnyDialogOpen()) {
        const currentVersion = this.checklistTemplates?.find((x) => x.id == this.currentVersion?.id);
        this.service.setChecklistTemplate(currentVersion);

        if (currentVersion && !this.isEquivalent(currentVersion, this.currentVersion)) {
          this.currentVersion = currentVersion;
          this.rowSelected(this.currentVersion);
        }
      } else {
        this.filteredChecklistTemplates = data;
        this.setLoading({ val: false, origin: '240' });
      }
    });
  }

  isEquivalent(version1: ChecklistTemplate, version2: ChecklistTemplate) {
    const obj1 = this.utils.cloneDeep(version1) as ChecklistTemplate;
    const obj2 = this.utils.cloneDeep(version2) as ChecklistTemplate;
    obj1.wfTable = undefined;
    if (obj1.templateType)
      obj1.templateType.checklistTemplates = [];
    obj2.wfTable = undefined;
    if (obj2.templateType)
      obj2.templateType.checklistTemplates = [];
    obj1.statusName = undefined;
    obj1.statusOrder = undefined;
    obj2.statusName = undefined;
    obj2.statusOrder = undefined;
    return this.utils.isEquivalent(obj1, obj2);
  }

  async loadTemplateFromRoute() {
    const id = this.route.snapshot.queryParams['id'];
    if (id) {
      const checklistTemplate = this.checklistTemplates?.find(
        (x) => x.id == +id
      );
      if (checklistTemplate) {
        this.rowSelected(checklistTemplate);
      }
    }
  }

  rowSelected(row?: ChecklistTemplate | null) {
    if (row) {
      this.setLoading({ val: true, origin: '956' });
      this.currentTemplateType = this.templateTypes.find(
        (x) => x.id == row.templateTypeID
      );
      this.currentVersion = row;
      this.service.currentTemplateType = this.currentTemplateType;
      this.loadSchedules();
      this.getChecklistTemplate();
      this.service.setProcedure(row.procedure ?? null);
      this.currentResource = row.resource;

      if (this.currentResource) {
        this.loadResources().then(async () => {
          this.resourceCtrl.setValue(this.currentResource);
          await this.placeholdersService.setKeyValuePairs(this.currentResource, true);
        });
      }
      // Refresh deleted Items
      if (this.currentVersion) {
        this.store.dispatch(new DeletedTasksRefresh(this.currentVersion.id));
        this.store.dispatch(new DeletedStepsRefresh(this.currentVersion.id));
        this.store.dispatch(new DeletedSectionsRefresh(this.currentVersion.id));
      }
    }
  }


  getChecklistTemplate() {
    this.service.getchecklistTemplateById(this.currentVersion?.id ?? 0).toPromise().then((data) => {
      if (data && this.currentVersion) {
        this.currentVersion = data;

        this.currentVersion.updatedByName = this.getUsers().find((x) => x.id == this.currentVersion?.updatedBy)?.name;
        this.currentVersion.createdByName = this.getUsers().find((x) => x.id == this.currentVersion?.createdBy)?.name;
        if (this.currentTemplateType && !this.currentTemplateType?.checklistTemplates?.length) {
          this.currentTemplateType.checklistTemplates = [];
        }
        const index =
          this.currentTemplateType?.checklistTemplates?.findIndex((x) => x.id == this.currentVersion?.id);
        if ((index ?? -1) >= 0) {
          this.currentTemplateType?.checklistTemplates?.splice(index ?? -1, 1);
        }
        this.currentTemplateType?.checklistTemplates?.push(this.currentVersion);
        if (this.currentVersion.templateType?.checklistTemplates)
          this.currentVersion.templateType.checklistTemplates = [];
        this.store.dispatch(
          new ChecklistTemplatesUpdate(
            this.currentVersion.id,
            this.currentVersion
          )
        );
        this.getMoreInfo();
      }
    });
  }

  getMoreInfo() {
    this.currentSchedule = this.schedules?.find(s => s.id == this.currentVersion?.schedule?.id);
    this.service.currentSchedule = this.currentSchedule;
    this.service.setChecklistTemplate(this.currentVersion);
    this.service.setProcedure(this.currentVersion?.procedure ?? null);
    this.setPrivs();
    this.getSections();
    this.scheduleCtrl.setValue(this.currentSchedule);
    this.resourceCtrl.setValue(this.currentResource);
    this.filterCustomNameTemplates();
    // setTimeout(() => {
    this.nameCtrl.setValue(this.currentVersion);
    this.typeCtrl.setValue(this.currentTemplateType);
    this.loadVersions(this.currentVersion);
    this.setLoadingFalse();
  }

  override ngOnDestroy(): void {
    this.documentTypesSubs?.unsubscribe();
    this.templateTypesSubs?.unsubscribe();
    this.schedulesSubs?.unsubscribe();
    this.resourcesSubs?.unsubscribe();
    this.scheduleTypesSubs?.unsubscribe();
    this.scheduleSubtypeSubs?.unsubscribe();
    this.checklistTemplateStatusesSubs?.unsubscribe();
    this.checklistTemplatesSubs?.unsubscribe();
    this.service.setProcedure(null);
    super.ngOnDestroy();
  }

  async clear() {
    this.errorMessages = [];
    this.currentSchedule = null;
    this.scheduleCtrl.setValue(null);
    this.nameCtrl.setValue(null);
    this.currentTemplateType = null;
    this.typeCtrl.setValue(null);
    this.currentResource = null;
    this.resourceCtrl.setValue(null);
    await this.placeholdersService.setKeyValuePairs(null, true);
    this.currentVersion = null;
    this.versionCtrl.setValue(null);
    this.checklistTemplates = [];
    this.filteredChecklistTemplates = [];
    this.service.currentSchedule = this.currentSchedule;
    this.service.currentTemplateType = null;
    this.service.documentRolePermissions = [];
    this.service.setProcedure(null);
    this.versions = [];
    this.filteredVersions = [];
    this.procedure = null;
    this.filterChecklistTemplates();
  }

  loadStatuses(renew: boolean = false) {
    this.checklistTemplateStatuses$ = this.store.select(
      (state) => state.ChecklistTemplateStatuses.data
    );
    this.checklistTemplateStatusesSubs =
      this.checklistTemplateStatuses$.pipe(distinctUntilChanged()).subscribe((data) => {
        if (data?.length) {
          this.checklistTemplateStatuses = data;
          this.checklistTemplateStatuses = data.sort(
            (a, b) => a.order - b.order
          );
          this.checklistTemplateStatuses.map(
            (s) =>
            (s.selected =
              s.statusEnum != this.checklistTemplateStatusEnum.Archived)
          );
          this.service.checklistTemplateStatuses =
            this.checklistTemplateStatuses;
        }
      });
  }

  loadScheduleTypes() {
    this.scheduleTypes$ = this.store.select(
      (state) => state.ScheduleTypes.data
    );
    this.scheduleTypesSubs = this.scheduleTypes$.pipe(distinctUntilChanged()).subscribe((data) => {
      this.scheduleTypes = data;
    });
  }

  loadScheduleSubtypes() {
    this.scheduleSubtypes$ = this.store.select(
      (state) => state.ScheduleSubtypes.data
    );
    this.scheduleSubtypeSubs = this.scheduleSubtypes$.pipe(distinctUntilChanged()).subscribe((data) => {
      this.scheduleSubtypes = data;
    });
  }

  create() {
    if (!this.createEnable()) return;
    this.setLoading({ val: true, origin: '332' });
    const name = typeof this.nameCtrl?.value == 'string' ? this.nameCtrl.value : this.nameCtrl?.value?.name;
    const checklistTemplate = this.service.getNewChecklistTemplate(
      this.documentType,
      this.currentTemplateType,
      this.currentSchedule,
      this.currentResource,
      this.procedure,
      name
    );
    this.service.createChecklistTemplate(checklistTemplate).subscribe(
      async (data) => {
        this.checklistTemplates = await this.service.setResources(this.checklistTemplates, this.templateTypes, this.schedules);
        this.currentVersion = data;
        this.procedure = this.currentVersion?.procedure;
        this.service.setProcedure(this.currentVersion?.procedure ?? null);
        this.loadVersions();
        this.versionCtrl.setValue(data);
        this.rowSelected(data);
        this.scheduleCtrl.setValue(data.schedule);
        this.setLoading({ val: false, origin: '351' });
      },
      (error) => {
        console.log(error);
        this.setLoading({ val: false, origin: '464' });
      }
    );
  }

  updateTemplateTypes() {
    this.filterTemplateTypes();
    this.checklistTemplates = this.currentTemplateType?.checklistTemplates;
    if (this.checklistTemplates && this.currentVersion) {
      const index =
        this.checklistTemplates?.findIndex(
          (x) => x.id == this.currentVersion?.id
        ) ?? -1;
      if (index >= 0) {
        this.checklistTemplates[index] = this.currentVersion;
      } else {
        this.checklistTemplates.push(this.currentVersion);
      }
      this.checklistBuilderList?.init();
      this.currentTemplateType = this.currentVersion.templateType;
      if (this.currentTemplateType) {
        this.currentTemplateType.checklistTemplates = this.checklistTemplates;
        this.service.currentTemplateType = this.currentTemplateType;
        this.store.dispatch(
          new TemplateTypesUpdate(
            this.currentTemplateType.id ?? 0,
            this.currentTemplateType
          )
        );
        this.typeCtrl.setValue(this.currentTemplateType);
        this.loadVersions(this.currentVersion);
      }
    }
  }

  delete() {
    const cancel = this.dialog.open(CancelDialogComponent, {
      width: '600px',
      data: {},
    });
    cancel.afterClosed().subscribe((data) => {
      if (data.accept && this.currentVersion) {
        this.setLoading({ val: true, origin: '388' });
        this.service
          .deleteChecklistTemplate(this.currentVersion, data.text)
          .subscribe(() => {
            this.clear();
            this.filterChecklistTemplates();
            this.setLoading({ val: false, origin: '392' });
          });
      }
    });
  }

  filterChecklistTemplates() {
    this.filteredChecklistTemplates = [];
    this.loadChecklistTemplates();
    let resourceID = 0;
    if (this.currentTemplateType) {
      if (this.currentSchedule) {
        resourceID =
          this.currentSchedule.scheduleResources.find((x) => x.resourceId)
            ?.resourceId ?? 0;
      }
      if (this.currentResource) {
        resourceID = this.currentResource.id;
      }
      this.filteredChecklistTemplates = this.checklistTemplates?.filter(
        (x) =>
          x.templateTypeID == this.currentTemplateType?.id &&
          ((resourceID != 0 &&
            x.resourceString ==
            this.currentSchedule?.scheduleResources[0]?.resourceString) ||
            x.resourceID == resourceID ||
            resourceID == 0)
      );
    } else {
      this.filteredChecklistTemplates = this.checklistTemplates;
    }
  }

  // TemplateTypes

  async loadTemplateTypes() {
    this.setLoading({ val: true, origin: '417' });

    this.templateTypes$ = this.store.select((x) => x.TemplateTypes.data);
    this.templateTypesSubs = this.templateTypes$.pipe(distinctUntilChanged()).subscribe((data) => {
      if (data.length) {
        this.templateTypes = data
          .filter(
            (x) => x.documentTypeID === this.documentTypeId && x.status === 1
          )
          .sort((a, b) => this.utils.sort(a.order, b.order, true));
        this.filterTemplateTypes();
        this.templateTypes.map(t => t.checklistTemplates = []);
        if (this.currentTemplateType) {
          this.currentTemplateType = this.filteredTemplateTypes.find(
            (x) => x.id == this.currentTemplateType?.id
          );
          this.service.currentTemplateType = this.currentTemplateType;
        }
        this.filterChecklistTemplates();
        if (this.currentTemplateType?.scheduleTypeID) this.loadSchedules();
        if (this.currentVersion) {
          const version = this.checklistTemplates?.find(
            (x) => x.id == this.currentVersion?.id
          );
          this.currentVersion = version;
        } else {
          this.loadTemplateFromRoute();
        }
        this.setLoadingFalse();
      }
    });
  }

  changedType() {
    this.filterTemplateTypes();
    this.filteredTemplateTypes = this.filteredTemplateTypes.filter((x) =>
      x.name?.toLowerCase().includes(this.typeCtrl.value.toLowerCase())
    );
  }

  selectedType(e: MatAutocompleteSelectedEvent) {
    this.clear();
    const value = e.option.value;
    this.currentTemplateType = value;
    this.typeCtrl.setValue(this.currentTemplateType);
    this.service.currentTemplateType = this.currentTemplateType;
    this.filterChecklistTemplates();

    this.setPrivs();
    if (
      this.currentTemplateType?.resourceTypeID &&
      !this.currentTemplateType.scheduleTypeID
    ) {
      this.loadResources().then(() => {
        this.loadVersions();
      });
    } else if (this.currentTemplateType?.scheduleTypeID) {
      this.loadSchedules();
      this.loadVersions();
    } else {
      this.loadVersions();
    }
    this.filterCustomNameTemplates();
  }

  filterTemplateTypes() {
    this.filteredTemplateTypes = this.templateTypes.sort((a, b) =>
      this.utils.sort(a.order, b.order, true)
    );
    this.filterChecklistTemplates();
  }

  filterCustomNameTemplates() {
    this.filteredOther = this.checklistTemplates
      ?.filter(c => c.templateTypeID == this.currentTemplateType?.id)  // Step 1: Filter based on allowMultipleVersions
      ?.reduce((acc: ChecklistTemplate[], current) => {
        if (!acc.some((item: ChecklistTemplate) => item.name === current.name)) {
          acc.push(current);  // Step 2: Only push if name is not already in the accumulator
        }
        return acc;
      }, []).sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
  }

  customNameSelected(other: ChecklistTemplate) {
    this.filteredChecklistTemplates = this.checklistTemplates?.filter(c => c.name == other.name);
  }

  clearCustomFilter() {
    this.nameCtrl.setValue(null);
    this.currentVersion = null;
    this.filteredChecklistTemplates = this.checklistTemplates?.filter(c => c.templateTypeID == this.currentTemplateType?.id);
  }


  clearTemplateTypes() {
    this.errorMessages = [];
    this.currentResource = null;
    this.resourceCtrl.setValue(null);
    this.currentSchedule = null;
    this.scheduleCtrl.setValue(null);
    this.currentTemplateType = null;
    this.typeCtrl.setValue(null);
    this.currentVersion = null;
    this.versionCtrl.setValue(null);
    this.clearResources();
    this.clearSchedules();
    this.filterTemplateTypes();
    this.versionCtrl.setValue(this.currentVersion);
    this.service.currentTemplateType = this.currentTemplateType;
    this.service.setProcedure(null);
    this.typeCtrl.setValue(this.currentTemplateType);
    this.filterChecklistTemplates();
    this.loadVersions();
    this.setLoading({ val: false, origin: '506' });
    this.checkCreateEnabled();
  }

  // Resources

  loadResources() {
    return new Promise((resolve) => {
      const resourceType = this.currentResource
        ? this.currentResource.type
        : this.currentTemplateType?.resourceTypeID
          ? this.currentTemplateType?.resourceTypeID
          : this.currentSchedule
            ? this.currentSchedule.scheduleResources.find((r) => r.resourceId)
              ?.resource?.resourceTypeID
            : null;
      if (resourceType) {
        this.resources$ = this.store.select((state) => state.Resources.data);
        this.resourcesSubs = this.resources$.pipe(distinctUntilChanged()).subscribe((data) => {
          if (data.length > 0) {
            this.resources = data.filter((x) => x.type == resourceType).sort((a, b) => this.utils.SortBeamlines(a.name, b.name));
            this.filteredResources = this.resources;
            resolve(this.resources);
          }
        });
      }
    });
  }

  changedResource() {
    this.filteredResources = this.resources.filter((x) =>
      x.name?.toLowerCase().includes(this.resourceCtrl.value.toLowerCase())
    );
  }

  async selectedResource(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;
    this.currentResource = value;
    await this.placeholdersService.setKeyValuePairs(this.currentResource, true);
    this.filteredChecklistTemplates = this.checklistTemplates?.filter(
      (x) => x.resourceID == this.currentResource?.id
    );
    this.loadVersions();
  }

  async clearResources() {
    this.errorMessages = [];
    this.currentResource = null;
    this.currentVersion = null;
    this.versions = [];
    this.filteredVersions = [];
    this.resourceCtrl.setValue(this.currentResource);
    await this.placeholdersService.setKeyValuePairs(this.currentResource, true);
    this.versionCtrl.setValue(this.currentVersion);
    this.filterChecklistTemplates();
    this.loadVersions();
    this.setLoading({ val: false, origin: '560' });
    this.checkCreateEnabled();
  }

  // Versions

  async loadVersions(currentVersion: ChecklistTemplate | null = null) {
    // this.setLoading({ val: true, origin: '566' });
    this.versions = this.checklistTemplates?.filter(x => x.templateTypeID == this.currentTemplateType?.id);
    this.filteredVersions = this.versions;
    if (currentVersion) {
      //   this.currentVersion = currentVersion;
      this.versionCtrl.setValue(this.currentVersion);
      //   this.nameCtrl.setValue(this.currentVersion?.name);
      //   this.activeVersion = this.filteredVersions?.find((x) => x.status == 1);
      //   await this.placeholdersService.setKeyValuePairs(this.currentVersion?.resource, true);
      //   this.setLoadingFalse();
      //   if (this.filteredVersions?.length ?? 0 > 1) {
      //     this.versionCtrl.enable();
      //   } else {
      //     this.versionCtrl.disable();
      //   }
      //   this.setLoading({ val: false, origin: '586' });
      // } else if (this.currentSchedule && this.currentTemplateType) {
      //   this.filteredVersions = this.versions?.filter(
      //     (x) =>
      //       x.resourceID ==
      //       this.currentSchedule?.scheduleResources[0].resourceId &&
      //       x.templateTypeID == this.currentTemplateType?.id
      //   );
      //   this.setLoading({ val: false, origin: '589' });
      // } else if (this.currentResource && this.currentTemplateType) {
      //   this.filteredVersions = this.versions?.filter(
      //     (x) =>
      //       x.resourceID == this.currentResource?.id &&
      //       x.templateTypeID == this.currentTemplateType?.id
      //   );
      //   this.setLoading({ val: false, origin: '592' });
      // } else {
      //   this.service.currentTemplate = null;
      //   this.currentVersion = null;
      //   this.currentResource = null;
      //   this.activeVersion = null;
      //   this.sections = [];
      //   this.setLoading({ val: false, origin: '599' });
    }
  }

  filteredStatuses() {
    this.filteredChecklistTemplateStatuses =
      this.checklistTemplateStatuses?.filter((x) =>
        this.checklistTemplates?.map((t) => t.code).includes(x.code)
      );
    return this.filteredChecklistTemplateStatuses;
  }

  setLoadingFalse() {
    if (this.currentTemplateType?.scheduleTypeID && this.schedules && this.templateTypes) {
      this.setLoading({ val: false, origin: '617' });
    }
    if (this.currentTemplateType?.resourceTypeID && this.resources && this.templateTypes) {
      this.setLoading({ val: false, origin: '624' });
    }
    if (!this.currentTemplateType?.scheduleTypeID && !this.currentTemplateType?.resourceTypeID && this.templateTypes
    ) {
      this.setLoading({ val: false, origin: '631' });
    }
  }

  changedVersion() {
    this.filteredVersions = [];
    this.filteredVersions = this.versions?.filter((x) =>
      x.name?.toLowerCase().includes(this.versionCtrl.value.toLowerCase())
    );
  }

  selectedVersion(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;
    this.currentVersion = value;
    this.nameCtrl.setValue(this.currentVersion?.name);


    // Refresh deleted Items
    if (this.currentVersion) {
      this.store.dispatch(new DeletedTasksRefresh(this.currentVersion.id));
      this.store.dispatch(new DeletedStepsRefresh(this.currentVersion.id));
      this.store.dispatch(new DeletedSectionsRefresh(this.currentVersion.id));
    }

    this.getSections();
  }
  // Workflow

  getSections() {
    this.sections =
      this.currentVersion?.wfTable?.wfTableLocal?.wfSectionLocals?.sort(
        (a, b) => this.utils.sort(a.order, b.order)
      );
    if (this.sections?.[0] && (!this.service.selectedNode || !this.sections.map(s => s.id).includes(this.service.selectedNode.sectionNode?.id ?? 0))) {
      this.service.selectedNode = { sectionNode: { id: this.sections[0].id, type: ChecklistElement.SECTION } } as SelectedElement;
      this.sections[0].expanded = true;
    }
    this.storeTasks();
    this.getErrors();
    this.loadDeletedSections();
    this.setLoading({ val: false, origin: '650' });
  }

  // Schedules

  loadSchedules() {
    this.schedules$ = this.store.select((state) => state.Schedules.data);
    this.schedulesSubs = this.schedules$.pipe(distinctUntilChanged()).subscribe((data) => {
      if (data.length) {
        this.schedules = data;
        this.filterSchedules();
      }
    });
  }

  changedSchedule() {
    this.filteredSchedules = [];
    this.filterSchedules();
    if (this.scheduleCtrl.value) {
      this.filteredSchedules = this.filteredSchedules.filter((x) =>
        x.name?.toLowerCase().includes(this.scheduleCtrl.value?.toLowerCase())
      );
    }
  }

  selectedSchedule(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;
    this.currentSchedule = value;
    this.service.currentSchedule = this.currentSchedule;
    const procedure =
      this.currentTemplateType?.scheduleSubtypeID == -1
        ? this.currentSchedule?.subdetail.procedure
        : this.currentSchedule?.procedure;
    this.service.setProcedure(
      this.service.procedures.find((p) => p.id == procedure?.id) ?? null
    );
    this.setPrivs();
    this.filterChecklistTemplates();
    this.loadVersions();
  }

  filterSchedules() {
    if (this.schedules && this.templateTypes?.length) {
      this.service.checklistTemplates = this.checklistTemplates;
      this.schedules = this.service.filterSchedulesByTemplateType(this.schedules, this.currentTemplateType);
      this.schedules.map((s) => (s.objectInfo = this.hasTemplatesInProgress(s)));
      this.filteredSchedules = this.schedules.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
    }
  }

  hasTemplatesInProgress(s: Schedule) {
    return (
      this.checklistTemplates?.find(
        (t) =>
          t.status &&
          t.schedule?.id == s.id &&
          ![
            ChecklistTemplateStatusEnum.Active,
            ChecklistTemplateStatusEnum.Archived,
          ].includes(t.status)
      ) != null
    );
  }

  clearSchedules() {
    this.errorMessages = [];
    this.currentSchedule = null;
    this.currentResource = null;
    this.currentVersion = null;
    this.service.setProcedure(null);
    this.service.setChecklistTemplate(null);
    this.versionCtrl.setValue(null);
    this.versions = [];
    this.filteredVersions = [];
    this.service.currentSchedule = this.currentSchedule;
    this.scheduleCtrl.setValue(this.currentSchedule);
    this.filterTemplateTypes();
    this.scheduleCtrl.setValue(null);
    this.changedSchedule();
    this.clearResources();
    this.setLoading({ val: false, origin: '717' });
    this.checkCreateEnabled();
  }

  /////

  displayVersion(e: any) {
    return e ? e.serialNo : null;
  }

  checkCreateEnabled() {
    this.createEnabled = this.createEnable();
  }

  createEnable() {
    // Early exit if there's no current template type
    if (!this.currentTemplateType) return false;

    let result = true;
    this.message = { type: 0, text: '', cssClass: '' };
    let message = '';

    if (this.currentTemplateType.scheduleTypeID) {
      if (this.currentSchedule) {
        const exists = this.checklistTemplates
          ?.filter(
            (x) =>
              x.templateTypeID == this.currentTemplateType?.id &&
              x.status != ChecklistTemplateStatusEnum.Active &&
              x.status != ChecklistTemplateStatusEnum.Archived
          )
          ?.some(
            (t) =>
              t.procedureID == this.currentSchedule?.procedure?.id ||
              t.scheduleID == this.currentSchedule?.id
          );
        const scheduleType = this.scheduleTypes.find(
          (x) => x.id == this.currentTemplateType?.scheduleTypeID
        );

        if (
          result &&
          this.currentSchedule &&
          this.checklistTemplates?.some(
            (t) => t.procedureID == this.currentSchedule?.procedure?.id
          )
        ) {
          const existing = this.checklistTemplates.find(
            (t) => t.procedureID == this.currentSchedule?.procedure?.id
          );
          message = (
            this.getMessage('AlreadyTemplateWithSameProcedure')
              .description as string
          )
            .replace(
              '{procedure}',
              '<b>' + existing?.procedure?.procedureNumber + '</b>'
            )
            .replace('{rev}', '<b>' + existing?.procedure?.revision + '</b>');
          this.alertAndDisplayMessage(MessageTypeEnum.Error, message);
          result = false;
        }
        // Check if the schedule type has a procedure type ID and if a checklist template already exists
        if (result && scheduleType?.procedureTypeID && exists) {
          message = this.getMessage('ExistingTemplate').description as string;
          this.alertAndDisplayMessage(MessageTypeEnum.Error, message);
          result = false;
        }
        // Further checks based on schedule type
        if (result && scheduleType?.procedureTypeID) {
          this.service.currentSchedule = this.currentSchedule;
          this.service.currentTemplateType = this.currentTemplateType;
          const privilege = this._procedure.getPrivilege(
            this.procedure?.procedureCategory?.procedureTypeID ?? 0,
            ProcedureSignatureType.Preparer
          );

          if (privilege) {
            const hasPrivilege = this.hasPrivilege(privilege);
            if (hasPrivilege) {
              const isPreparer = this.is(ProcedureSignatureType.Preparer);
              if (!isPreparer) {
                message = this.getMessage(
                  'ShouldSubscribeAsPreparer'
                ).description?.replace(
                  '{procedure}',
                  this.currentSchedule?.procedure?.procedureNumber +
                  ' - Rev: ' +
                  this.currentSchedule?.procedure?.revision
                ) as string;
                this.alertAndDisplayMessage(MessageTypeEnum.Info, message);
              }
            } else {
              message = this.getMessage(
                'DuplicateNoPreparer'
              ).description?.replace(
                '{procedure}',
                this.currentSchedule?.procedure?.procedureNumber +
                ' - Rev: ' +
                this.currentSchedule?.procedure?.revision
              ) as string;
              this.alertAndDisplayMessage(MessageTypeEnum.Warning, message);
            }
          } else {
            if (this.currentSchedule && this.procedure) {
              message = this.getMessage('NotAllowedToCreateTemplates')
                .description as string;
              this.alertAndDisplayMessage(MessageTypeEnum.Warning, message);
              result = false;
            }
          }
        }
      } else result = false;
    } else if (this.currentTemplateType.resourceTypeID) {
    } else if (this.currentTemplateType.allowMultipleVersions) {
      if (this.nameCtrl.value) {
        result = true;
        // const existing = this.checklistTemplates?.find(
        // );
        // if (existing) {
        //   message = this.getMessage('TemplateSameName').description as string;
        //   this.alertAndDisplayMessage(MessageTypeEnum.Warning, message);
        //   result = false;
        // }
      } else result = false;
    }

    // Display success message if the template creation conditions are met
    if (result && !message) {
      const name = this.nameCtrl.value
        ? ', Named: <b>' + this.nameCtrl.value + '</b>'
        : '';
      const schedule = this.currentSchedule
        ? ', Resource: <b>' +
        this.currentSchedule.scheduleResources?.[0]?.resource?.name +
        '</b>'
        : '';
      const resource = this.currentResource
        ? ', Resource: <b>' + this.currentResource.name + '</b>'
        : '';
      const procedure = this.currentSchedule
        ? ', Procedure: <b>' +
        this.currentSchedule.procedure?.procedureNumber +
        '</b>' +
        ', Rev: <b>' +
        this.currentSchedule.procedure?.revision +
        '</b>'
        : '';
      message =
        'A new template can be created for Type: <b>' +
        this.currentTemplateType.name +
        '</b>' +
        name +
        schedule +
        resource +
        procedure;
      this.alertAndDisplayMessage(MessageTypeEnum.Success, message);
    }

    return result;
  }

  alertAndDisplayMessage(type: MessageTypeEnum, text: string) {
    // this.alert.message(text, [], type);
    this.message = {
      type,
      text,
      cssClass:
        type == MessageTypeEnum.Success
          ? 'primary-tooltip'
          : type == MessageTypeEnum.Error
            ? 'warn-tooltip'
            : 'accent-tooltip',
    };
  }

  createDisabled() {
    if (this.loading) {
      return true;
    }
    if (!this.currentTemplateType?.id) {
      return true;
    }

    if (
      this.currentTemplateType.allowMultipleVersions &&
      this.nameCtrl.value == null
    ) {
      return true;
    }
    if (
      !this.currentTemplateType.resourceTypeID &&
      !this.currentTemplateType.scheduleTypeID
    ) {
      return false;
    }

    const enableR =
      this.currentTemplateType &&
      this.currentTemplateType.resourceTypeID &&
      this.currentResource;
    const enableS = this.currentTemplateType && this.currentSchedule;
    let enableSchedProc = false;
    if (
      this.currentTemplateType.scheduleSubtype &&
      this.currentSchedule?.scheduleSubtypeId ==
      this.currentTemplateType.scheduleSubtypeID
    ) {
      enableSchedProc = this.currentSchedule?.procedure != null;
    } else if (
      this.currentTemplateType.scheduleTypeID &&
      this.currentSchedule?.typeId == this.currentTemplateType.scheduleTypeID
    ) {
      enableSchedProc = this.currentSchedule?.procedure != null;
    }
    const exists = this.checklistTemplates?.some(
      (x) =>
        x.templateTypeID == this.currentTemplateType?.id &&
        x.status != ChecklistTemplateStatusEnum.Active &&
        x.status != ChecklistTemplateStatusEnum.Archived &&
        ((this.currentSchedule &&
          this.currentSchedule?.scheduleResources
            .map((r) => r.resourceId)
            .includes(x.resourceID)) ||
          (this.currentResource && this.currentResource.id == x.resourceID))
    );
    const enabled = enableR || enableS;
    return !enabled || exists;
  }

  isNewVersion() {
    return (
      (this.activeVersion &&
        this.versionCtrl.value &&
        moment(this.versionCtrl.value.createdOn).isAfter(
          this.activeVersion?.createdOn
        )) ||
      (this.activeVersion == undefined && this.versionCtrl.value)
    );
  }

  settings() {
    const settings = this.dialog.open(ChecklistBuilderSettingsComponent, {
      width: '60vw',
      minWidth: '850px',
      height: '700px',
      data: {
        templateType: this.currentTemplateType,
        documentType: this.documentType,
      },
      disableClose: true,
      autoFocus: false,
    });
    settings.afterClosed().subscribe(() => {
      this.filterTemplateTypes();
      this.loadVersions();
      this.getPermissions();
    });
  }

  placeholders() {
    const settings = this.dialog.open(ChecklistBuilderPlaceholdersComponent, {
      minWidth: '850px',
      minHeight: '500px',
      data: { resource: this.currentVersion?.resource },
      disableClose: true,
      autoFocus: false,
    });
    settings.afterClosed().subscribe(() => {
      this.filterTemplateTypes();
      this.loadVersions();
    });
  }

  duplicate() {
    const settings = this.dialog.open(ChecklistBuilderDuplicateComponent, {
      width: '60vw',
      height: '60vh',
      minHeight: '33em',
      data: this.utils.cloneDeep({
        templateType: this.currentTemplateType,
        documentType: this.documentType,
        templateVersion: this.currentVersion,
        resource: this.currentResource,
        resources: this.filteredResources,
        schedule: this.currentSchedule,
        schedules: this.filteredSchedules,
        documentRolePermissions: this.documentRolePermissions,
      }),
      disableClose: true,
      autoFocus: false,
    });
    settings.afterClosed().subscribe((data) => {
      if (data.duplicatedVersion) {
        this.currentVersion = data.duplicatedVersion;
        this.currentTemplateType = data.duplicatedVersion.templateType;
        // this.checklistTemplates = this.service.setResources(this.checklistTemplates, this.templateTypes, this.schedules);
        // this.currentVersion = this.checklistTemplates?.find((t) => t.id == data.duplicatedVersion.id);
        this.procedure = this.currentVersion?.procedure;
        this.service.setProcedure(this.currentVersion?.procedure ?? null);
        this.loadVersions(this.currentVersion);
        this.rowSelected(this.currentVersion);
        this.setLoading({ val: false, origin: '952' });
      }
    });
  }

  getSchedule() {
    return this.filteredSchedules?.find(
      (x) =>
        (x.scheduleResources
          .map((s) => s.resourceId)
          .includes(this.currentVersion?.resourceID) &&
          this.currentVersion?.resourceID) ||
        (this.currentVersion?.resourceString &&
          x.scheduleResources
            .map((s) => s.resourceString)
            .includes(this.currentVersion?.resourceString))
    );
  }

  isCreator() {
    return this.getCurrentUser()?.id == this.currentVersion?.createdBy;
  }

  setArchived() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('GenChklistBldr_SetArchivedConfirmation')
          .description,
        icon: 'stop',
      },
    });
    confirm.afterClosed().subscribe((data) => {
      if (data) {
        this.setStatus(ChecklistTemplateStatusEnum.Archived);
      }
    });
  }

  setStatus(
    status: ChecklistTemplateStatusEnum,
    comments: string | null = null
  ) {
    if (this.currentVersion) {
      this.setLoading({ val: true, origin: '881' });
      this.currentVersion.status = status;
      if (comments) {
        this.currentVersion.comments = comments;
      }
      this.service
        .updateChecklistTemplate(this.utils.Serialize(this.currentVersion))
        .toPromise()
        .then((data) => {
          if (this.currentVersion?.status != data?.status) {
            this.alert.message('formStateChanged', [
              new MessagePlaceholder(
                '{serialNo}',
                this.currentVersion?.serialNo
              ),
              new MessagePlaceholder(
                '{status}',
                this.getStatus(data?.code)?.name
              ),
            ]);
          }

          this.currentVersion = data;
          this.service.setProcedure(data?.procedure ?? null);
          this.setLoading({ val: false, origin: '896' });
        });
    }
  }

  updateRejectionComment(comment: string) {
    this.setStatus(ChecklistTemplateStatusEnum.Rejected, comment);
  }

  setPrivs() {
    this.checkCreateEnabled();
    this.isPreparer = this.is(ProcedureSignatureType.Preparer);
    this.isReviewer = this.is(ProcedureSignatureType.Reviewer);
    this.isApprover = this.is(ProcedureSignatureType.Approver);
  }

  hasPreparerPriv() {
    if (this.procedure) {
      const privilege = this._procedure.getPrivilege(
        this.procedure.procedureCategory?.procedureTypeID ?? 0,
        ProcedureSignatureType.Preparer
      );
      if (privilege) return this.hasPrivilege(privilege);
      else return false;
    } else {
      const roles =
        this.currentTemplateType?.documentType?.templateRolePermissions
          ?.filter((x) => x.checklistPrivilege == 1)
          .map((x) => x.roleID);
      return this.hasRoles(roles);
    }
  }

  canBePreparer() {
    if (!this.procedure) return false;
    return this.service.userCanBeAdded(
      this.procedure,
      ProcedureSignatureType.Preparer
    );
  }

  is(signatureType: ProcedureSignatureType) {
    const checklistPriv =
      signatureType == ProcedureSignatureType.Preparer
        ? ChecklistPriv.Prepare
        : signatureType == ProcedureSignatureType.Reviewer
          ? ChecklistPriv.Review
          : signatureType == ProcedureSignatureType.Approver
            ? ChecklistPriv.Approve
            : null;
    if (this.procedure) {
      const signatures = this.procedure?.procedureSignatures?.filter(
        (x) => x.signatureType == signatureType
      );
      return (
        signatures
          ?.map((x) => x.userID)
          .includes(this.getCurrentUser()?.id ?? 0) ?? false
      );
    } else {
      const canApprove =
        ((this.currentTemplateType?.samePersonCanApprove ||
          (!this.currentTemplateType?.samePersonCanApprove &&
            this.getCurrentUser()?.id != this.currentVersion?.updatedBy)) &&
          signatureType == ProcedureSignatureType.Approver) ||
        signatureType != ProcedureSignatureType.Approver;
      return (
        this.hasRoles(
          this.documentRolePermissions
            ?.filter((x) => x.checklistPrivilege == checklistPriv)
            .map((x) => x.roleID)
        ) && canApprove
      );
    }
  }

  storeTasks() {
    const wfTaskLocals =
      this.currentVersion?.wfTable?.wfTableLocal?.wfSectionLocals?.flatMap(
        (s) => s.wfStepLocals?.flatMap((z) => z.wfTaskLocals ?? []) ?? []
      ) ?? [];

    this.store.dispatch(new TasksUpdateAll(wfTaskLocals));
  }

  loadDeletedSections() {
    this.deletedSectionLocals$ = this.store.select((state) => state.DeletedWFSectionLocals.data);
    this.deletedSectionLocalsSubs = this.deletedSectionLocals$.pipe(distinctUntilChanged()).subscribe((data) => {
      this.deletedSectionLocals = data.filter(t => t.checklistTemplateID == this.currentVersion?.id);
      if (this.showDeleted) {
        const sections = this.sections?.filter(s => !s.logID)?.concat(this.deletedSectionLocals).sort((a, b) => this.utils.sort(a.order, b.order));
        this.sections = sections;
      }
    });
  }

  async submitToReview() {
    if (this.reviewDisabled()) return;
    if (this.procedurePreparerSignature) {
      this._procedure
        .approveSignature(this.procedurePreparerSignature.id)
        .toPromise()
        .then(() => {
          if (this.procedure?.id) {
            const preparerSignature = this.procedure?.procedureSignatures?.find(
              (x) => x.id == this.procedurePreparerSignature?.id
            );
            if (preparerSignature) preparerSignature.signedOn = new Date();
            this.store.dispatch(
              new ProcedureUpdate(this.procedure.id, this.procedure)
            );
            const finished = !this.procedure.procedureSignatures
              ?.filter(
                (x) => x.signatureType == ProcedureSignatureType.Preparer
              )
              .find((x) => !x.signedOn);
            if (finished)
              this.setStatus(ChecklistTemplateStatusEnum.PendingRev);
          }
        });
    }
  }

  submitToApprove() {
    if (this.errorMessages?.length) return;
    this.setStatus(ChecklistTemplateStatusEnum.PendingApr);
  }

  unsubmit() {
    this.setStatus(ChecklistTemplateStatusEnum.Draft);
  }

  approve() {
    if (this.procedureApproverSignature) {
      this._procedure
        .approveSignature(this.procedureApproverSignature.id)
        .toPromise()
        .then(() => {
          if (this.procedure?.id) {
            const approverSignature = this.procedure.procedureSignatures?.find(
              (x) => x.id == this.procedureApproverSignature?.id
            );
            if (approverSignature) approverSignature.signedOn = new Date();
            this.store.dispatch(
              new ProcedureUpdate(this.procedure.id, this.procedure)
            );
            const finished = !this.procedure.procedureSignatures
              ?.filter(
                (x) => x.signatureType == ProcedureSignatureType.Approver
              )
              .find((x) => !x.signedOn);
            if (finished) {
              if (this.reviewSummary.activeAnnotations == 0) {
                this.publishProcedure();
                this.setStatus(ChecklistTemplateStatusEnum.Active);
              } else {
                this.correctionsStatusWarning();
              }
            } else {
              this.setStatus(ChecklistTemplateStatusEnum.Approving);
            }
          }
        });
    } else {
      this.setStatus(ChecklistTemplateStatusEnum.Active);
    }
  }

  correctionsStatusWarning() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage(
          'GenChklistBldr_CorrectionsConfirmation'
        ).description.replace(
          '{count}',
          '<b>' + this.reviewSummary.activeAnnotations?.toString() + '</b>'
        ),
        icon: 'stop',
      },
    });
    confirm.afterClosed().subscribe((data) => {
      if (data) {
        this.setStatus(ChecklistTemplateStatusEnum.CorrectionsPending);
      }
      this.procedure?.procedureSignatures
        ?.filter(
          (x) =>
            x.signatureType == ProcedureSignatureType.Approver ||
            (x.signatureType == ProcedureSignatureType.Reviewer &&
              !this.isApprover)
        )
        .map((s) => {
          this._procedure.unapproveSignature(s.id).toPromise();
          s.signedOn = null;
        });
      if (this.procedure?.id)
        this.store.dispatch(
          new ProcedureUpdate(this.procedure.id, this.procedure)
        );
    });
  }

  unapprove() {
    if (this.procedureApproverSignature) {
      this._procedure
        .unapproveSignature(this.procedureApproverSignature.id)
        .toPromise()
        .then(() => {
          if (this.procedure?.id) {
            const approverSignature = this.procedure.procedureSignatures?.find(
              (x) => x.id == this.procedureApproverSignature?.id
            );
            if (approverSignature) approverSignature.signedOn = null;
            this.store.dispatch(
              new ProcedureUpdate(this.procedure.id, this.procedure)
            );
            const empty = !this.procedure.procedureSignatures
              ?.filter(
                (x) => x.signatureType == ProcedureSignatureType.Approver
              )
              .find((x) => x.signedOn);
            if (empty) this.setStatus(ChecklistTemplateStatusEnum.PendingApr);
          }
        });
    }
  }

  reject() {
    const cancel = this.dialog.open(RejectDialogComponent, {
      width: '600px',
      data: {
        warning: this.getMessage('RejectDialogWarning').description,
        title: this.currentVersion?.templateType?.scheduleType?.procedureTypeID
          ? this.getMessage('RejectChecklistDialogTitle').description
          : 'Rejection Comment',
        placeholder: this.getMessage('RejectChecklistPlaceholder').description,
        icon: true,
      },
    });
    cancel.afterClosed().subscribe((data) => {
      if (data.accept && this.currentVersion) {
        const comment = {
          commentText: data.text,
          createdBy: this.getCurrentUser()?.id,
          createdOn: new Date(),
          createdByName: this.getCurrentUser()?.name,
        } as CommentResource;
        this.currentVersion.comments = JSON.stringify(comment);
        if (this.procedure?.id) {
          this.procedure.procedureSignatures?.map((s) => {
            if (s.signatureType != ProcedureSignatureType.Preparer) {
              s.signedOn = null;
              this._procedure.unapproveSignature(s.id).toPromise();
            }
          });
          this.store.dispatch(
            new ProcedureUpdate(this.procedure.id, this.procedure)
          );
        }
        this.setStatus(
          ChecklistTemplateStatusEnum.Rejected,
          this.currentVersion.comments
        );
      }
    });
  }

  rework() {
    if (this.procedure?.procedureSignatures?.length) {
      if (
        this.procedure.procedureSignatures.filter((x) => x.signedOn).length > 1
      ) {
        const confirm = this.dialog.open(YesNoDialogComponent, {
          width: '400px',
          data: {
            message: this.getMessage(
              'GenChklistBldr_RemoveSignaturesConfirmation'
            ).description,
            icon: 'stop',
          },
        });
        confirm.afterClosed().subscribe((data) => {
          if (data) {
            this.removeSignatures();
          }
        });
      } else {
        this.removeSignatures();
      }
    } else {
      this.setStatus(ChecklistTemplateStatusEnum.Draft);
    }
  }

  removeSignatures() {
    this.procedure?.procedureSignatures?.map((s) => {
      s.signedOn = null;
      this._procedure.unapproveSignature(s.id).toPromise();
    });
    if (this.procedure?.id)
      this.store.dispatch(
        new ProcedureUpdate(this.procedure.id, this.procedure)
      );
    if (
      this.currentVersion &&
      (this.currentVersion.status ==
        ChecklistTemplateStatusEnum.CorrectionsPending ||
        this.currentVersion.status == ChecklistTemplateStatusEnum.Rejected ||
        this.currentVersion.status == ChecklistTemplateStatusEnum.Approving ||
        this.currentVersion.status == ChecklistTemplateStatusEnum.PendingApr ||
        this.currentVersion.status == ChecklistTemplateStatusEnum.InReview)
    ) {
      this.setStatus(ChecklistTemplateStatusEnum.CorrectionsInProgress);
    } else if (
      this.currentVersion?.status == ChecklistTemplateStatusEnum.PendingRev
    ) {
      this.setStatus(ChecklistTemplateStatusEnum.Draft);
    } else {
      this.alert.info('Unassigned action!');
    }
  }

  reactivate() {
    this.setStatus(ChecklistTemplateStatusEnum.Active);
  }

  async review() {
    if (this.procedureReviewerSignature?.signedOn) {
      await this._procedure
        .unapproveSignature(this.procedureReviewerSignature.id)
        .toPromise();
      const reviewerSignature = this.procedure?.procedureSignatures?.find(
        (x) => x.id == this.procedureReviewerSignature?.id
      );
      if (reviewerSignature) reviewerSignature.signedOn = null;
      if (this.procedure?.id)
        this.store.dispatch(
          new ProcedureUpdate(this.procedure.id, this.procedure)
        );
    }
    this.setStatus(ChecklistTemplateStatusEnum.InReview);
  }

  reviewed() {
    if (this.procedureReviewerSignature)
      this._procedure
        .approveSignature(this.procedureReviewerSignature.id)
        .toPromise()
        .then(() => {
          const reviewersignature = this.procedure?.procedureSignatures?.find(
            (x) => x.id == this.procedureReviewerSignature?.id
          );
          if (reviewersignature) reviewersignature.signedOn = new Date();
          this.checkReviewers();
        });
  }

  async suggest() {
    if (this.procedureApproverSignature?.signedOn && this.procedure?.id) {
      await this._procedure
        .unapproveSignature(this.procedureApproverSignature.id)
        .toPromise();
      const approverSignature = this.procedure?.procedureSignatures?.find(
        (x) => x.id == this.procedureApproverSignature?.id
      );
      if (approverSignature) approverSignature.signedOn = null;
      this.store.dispatch(
        new ProcedureUpdate(this.procedure.id, this.procedure)
      );
    }
    this.setStatus(ChecklistTemplateStatusEnum.Suggesting);
  }

  filterChanged(e: ChecklistTemplateStatus) {
    const status = this.checklistTemplateStatuses.find((x) => x.id == e.id);
    if (status)
      status.selected = !this.checklistTemplateStatuses?.find(
        (x) => x.id == e.id
      )?.selected;
    this.service.checklistTemplateStatuses = this.checklistTemplateStatuses;
    this.checklistBuilderList.init();
  }

  getStatus(code?: string) {
    return this.checklistTemplateStatuses?.find((x) => x.code == code);
  }

  disableSections() {
    if (
      this.currentVersion?.status == ChecklistTemplateStatusEnum.Active ||
      this.currentVersion?.status == ChecklistTemplateStatusEnum.Archived
    ) {
      return true;
    }
    if (this.currentVersion?.procedure) {
      return !this.isPreparer ||
        this.procedurePreparerSignature?.signedOn ||
        this.procedureReviewerSignature?.signedOn ||
        this.procedureApproverSignature?.signedOn
        ? true
        : false;
    } else {
      return (
        this.currentVersion?.status != ChecklistTemplateStatusEnum.Draft ||
        !this.hasRoles(
          this.documentRolePermissions
            ?.filter((x) => x.checklistPrivilege == ChecklistPriv.Prepare)
            .map((x) => x.roleID)
        )
      );
    }
  }

  getErrors() {
    setTimeout(() => {
      this.errorMessages = [];
      this.sectionsComponent?.sectionComponents.filter(s => !s.section.logID).map((s) => {
        s.stepsComponent?.stepComponents.filter(z => !z.step.logID).map((x) => {
          if (x.statusError) {
            const statusErrorMessage =
              'Wrong status Settings on Step: ' +
              s.section.number +
              ' ' +
              s.section.name +
              ', Step: ' +
              (x.step.name ? x.step.name : 'Unnamed Step');
            this.errorMessages?.push({
              stepId: x.step.id,
              text: statusErrorMessage,
            });
          }
          if (!x.enabledOn || x.enabledOn.length == 0) {
            this.errorMessages?.push({
              stepId: x.step.id,
              text:
                'No Status Set on Step: ' +
                s.section.number +
                ' ' +
                s.section.name +
                ', Step: ' +
                (x.step.name ? x.step.name : 'Unnamed Step'),
            });
          }
          x.tasksComponent?.taskComponents.filter(t => !t.task.logID).map((t) => {
            if (t.hasErrors()) {
              const errorMessage =
                t.errorMessage +
                ' in Section: ' +
                s.section.number +
                ' ' +
                s.section.name +
                ', Step: ' +
                x.step.name +
                ', Task: ' +
                t.task.name;
              this.errorMessages?.push({
                stepId: x.step.id,
                taskId: t.task.id,
                text: errorMessage,
              });
            }
          });
        });
      });
    }, 500);
  }

  resolveRejection() {
    if (this.currentVersion) {
      this.setLoading({ val: true, origin: '1309' });
      this.currentVersion.comments = null;
      this.service
        .updateChecklistTemplate(this.currentVersion)
        .subscribe((data) => {
          this.currentVersion = data;
          this.updateTemplateTypes();
          this.setLoading({ val: false, origin: '1316' });
        });
    }
  }

  publishProcedure() {
    if (this.procedure?.id) {
      const prevRevision = this.procedure.revision ?? 0;
      const revision = +prevRevision + 1;
      const newVersion: Procedure = this.utils.cloneDeep(this.procedure);
      newVersion.fileID = null;
      newVersion.file = null;
      newVersion.revision = revision.toString();
      newVersion.newProcedure = false;
      newVersion.procedureAppendices = [];
      this.procedure.procedureAppendices?.map((appendix) => {
        newVersion.procedureAppendices?.push({
          id: 0,
          formID: 0,
          fileID: 0,
          text: appendix.text,
          url: appendix.url,
        });
      });
      newVersion.checklistTemplate = null;
      this._procedure.complete(this.procedure.id, newVersion).subscribe(
        () => {
          this.setStatus(ChecklistTemplateStatusEnum.Active);
        },
        (error) => {
          if (error.status === 409 || error.status == 404) {
            this.alert.error(error.error);
          } else {
            this.alert.defaultError();
            console.error(error);
          }
          this.setLoading({ val: false, origin: '1352' });
        }
      );
    }
  }

  setProcedure() {
    this.setPrivs();
    if (this.procedure?.procedureSignatures?.length) {
      switch (this.currentVersion?.status) {
        case ChecklistTemplateStatusEnum.Draft:
          this.checkPreparers();
          break;
        case ChecklistTemplateStatusEnum.InReview:
          this.checkReviewers();
          break;
        case ChecklistTemplateStatusEnum.Approving:
          this.checkApprovers();
          break;
      }
    }
  }

  checkPreparers() {
    const preparers = this.procedure?.procedureSignatures?.filter(
      (x) => x.signatureType == ProcedureSignatureType.Preparer
    );
    if (preparers?.length) {
      const finished = !preparers.find((x) => !x.signedOn);
      if (finished) {
        this.setStatus(ChecklistTemplateStatusEnum.PendingRev);
      }
    }
  }

  checkReviewers() {
    if (this.procedure?.id) {
      const reviewers = this.procedure?.procedureSignatures?.filter(
        (x) => x.signatureType == ProcedureSignatureType.Reviewer
      );
      if (reviewers?.length) {
        const finished = !reviewers.find((x) => !x.signedOn);
        if (finished) {
          if (this.reviewSummary.activeAnnotations) {
            this.procedure?.procedureSignatures
              ?.filter(
                (x) => x.signatureType == ProcedureSignatureType.Reviewer
              )
              .map((s) => {
                this._procedure.unapproveSignature(s.id).toPromise();
                s.signedOn = null;
              });
            this.setStatus(ChecklistTemplateStatusEnum.CorrectionsPending);
            this.store.dispatch(
              new ProcedureUpdate(this.procedure.id, this.procedure)
            );
          } else this.setStatus(ChecklistTemplateStatusEnum.PendingApr);
        }
      }
    }
  }

  checkApprovers() {
    if (this.procedure?.id) {
      const approvers = this.procedure?.procedureSignatures?.filter(
        (x) => x.signatureType == ProcedureSignatureType.Approver
      );
      if (approvers?.length) {
        const finished = !approvers.find((x) => !x.signedOn);
        if (finished) {
          if (this.reviewSummary.activeAnnotations) {
            this.procedure?.procedureSignatures
              ?.filter(
                (x) => x.signatureType == ProcedureSignatureType.Reviewer
              )
              .map((s) => {
                this._procedure.unapproveSignature(s.id).toPromise();
                s.signedOn = null;
              });
            this.setStatus(ChecklistTemplateStatusEnum.CorrectionsPending);
            this.store.dispatch(
              new ProcedureUpdate(this.procedure.id, this.procedure)
            );
          } else this.setStatus(ChecklistTemplateStatusEnum.Active);
        }
      }
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
    // if (this.isExpanded != false) {

    // }
    if (this.innerWidth < 768) {
      this.isExpanded = false;
    }
  }

  @HostListener('window:scroll')
  isHeaderOnTop() {
    let elemRec;
    if (this.headerTop?.nativeElement !== undefined) {
      elemRec = this.headerTop.nativeElement.getBoundingClientRect();
      const docViewTop = window.screenTop;
      const elemTop = elemRec.top;
      if (elemTop <= docViewTop) {
        this.headerOnTop = true;
      } else {
        this.headerOnTop = false;
      }
      // this.tableWidth = this.scheduleTable.nativeElement.offsetWidth + 'px';
    } else {
      this.headerOnTop = false;
    }
  }

  // scrollToTop(elem) {
  //   (function smoothschroll() {
  //     const yOffset = -230;
  //     // const element = $(elem)[0];
  //     const y = elem.clientTop + window.pageYOffset + yOffset;
  //     window.scrollTo({ top: y, behavior: "smooth" });
  //   })();
  // }

  getPermissions() {
    this.service
      .getTemplateRolePermissionsByDocumentTypeID(this.documentTypeId)
      .subscribe((data) => {
        this.documentRolePermissions = data.filter(
          (x) => x.templateTypeID === 0
        );
        this.service.documentRolePermissions = this.documentRolePermissions;
        this.setPrivs();
      });
  }

  showRework() {
    if (this.currentVersion) {
      if (this.procedure) {
        return (
          this.currentVersion?.status !=
          this.checklistTemplateStatusEnum.Active &&
          this.currentVersion?.status !=
          this.checklistTemplateStatusEnum.Archived &&
          this.currentVersion?.status !=
          this.checklistTemplateStatusEnum.Temp &&
          this.currentVersion?.status !=
          this.checklistTemplateStatusEnum.Draft &&
          this.currentVersion?.status !=
          this.checklistTemplateStatusEnum.CorrectionsInProgress &&
          this.currentVersion?.status !=
          this.checklistTemplateStatusEnum.Suggesting
        );
      } else {
        return (
          this.currentVersion?.status ==
          this.checklistTemplateStatusEnum.Rejected ||
          this.currentVersion?.status ==
          this.checklistTemplateStatusEnum.PendingApr
        );
      }
    }
    return false;
  }

  showSubmitToReview() {
    return (
      (this.currentVersion?.status == ChecklistTemplateStatusEnum.Draft ||
        this.currentVersion?.status ==
        ChecklistTemplateStatusEnum.CorrectionsInProgress) &&
      !this.procedurePreparerSignature?.signedOn &&
      this.procedure
    );
  }

  reviewDisabled() {
    if (
      this.currentVersion?.comments ||
      this.reviewSummary?.activeAnnotations ||
      this.errorMessages?.length
    )
      return true;
    return false;
  }

  setShowDeleted(e: boolean) {
    this.showDeleted = e;
    this.getSections();
  }

  scrollDeletedSections() {
    setTimeout(() => {
      const element = document.getElementById('deleted-sections');
      element?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }

  setLoading(e: any) {
    let loadingList = false;
    let loadingSections = false;
    let loadingSelf = false;
    console.warn('loading:: val: ' + e.val + '   from:' + e.origin);

    if ((e.origin = 'list')) loadingList = e.val;
    else if ((e.origin = 'sections')) loadingSections = e.val;
    else loadingSelf = e.val;

    setTimeout(() => {
      this.loading = loadingList || loadingSections || loadingSelf;
    }, (loadingList || loadingSections || loadingSelf) ? 100 : 1000);
  }


}

export interface StepError {
  stepId: number;
  taskId?: number;
  text: string;
}
