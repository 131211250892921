import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ActionPendingCatalog } from 'src/app/common/enumerations/enumerations';
import { AbsiTemplateTypeResource } from 'src/app/components/absi/absi';
import { AbsiService } from 'src/app/components/absi/absi.service';
import { ChecklistBuilderService } from 'src/app/components/checklists/checklist-builder/checklist-builder.service';
import { ChecklistTemplate, TemplateType, TemplateTypeDialogData } from 'src/app/components/checklists/checklists';
import { TemplateTypesRefreshByDocument, TemplateTypesRefresh, TemplateTypesDelete } from 'src/app/components/checklists/store/template-types/template-types.action';
import { ScheduleType } from 'src/app/components/schedules/models/schedule-type';
import { ScheduleTypesRefresh } from 'src/app/components/schedules/store/schedule-types/schedule-types.action';
import { TextareaDialogComponent } from 'src/app/controls/textarea-dialog/textarea-dialog.component';
import { DocumentTypeRefresh } from '../../store/document-type/document-type.action';
import { DocumentType } from 'src/app/services/documents/documents';
import { AddEditTemplateTypeComponent } from '../dialogs/add-edit-template-type/add-edit-template-type.component';
import { ChecklistTemplatesRefreshByDocument } from 'src/app/components/checklists/store/checklist-templates/checklist-templates.action';

@Component({
  selector: 'app-template-type-tab',
  templateUrl: './template-type-tab.component.html',
  styleUrls: ['./template-type-tab.component.scss']
})
export class TemplateTypeTabComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() documentType?: DocumentType;
  @Input() isDisabled = true;

  @Output() changed = new EventEmitter<TemplateType[]>();

  public templateTypes!: TemplateType[];
  public templateTypes$!: Observable<TemplateType[]>;
  public absiTemplateTypes$!: Observable<AbsiTemplateTypeResource[]>;

  hasChecklistTemplates?: boolean;
  checklistTemplates?: ChecklistTemplate[];
  checklistTemplatesFiltered?: ChecklistTemplate[];
  checklistTemplates$!: Observable<ChecklistTemplate[]>;
  checklistTemplatesSubs!: Subscription;

  public templateTypeSubscription!: Subscription;
  public absiTemplateTypeSubscription!: Subscription;

  public displayedColumnsTemplateType: string[] = ['name', 'description', 'code', 'add'];
  public dataSource: MatTableDataSource<TemplateType> = new MatTableDataSource();

  public isAbsiTemplateType!: boolean;

  scheduleType!: ScheduleType;
  canEdit!: boolean;

  constructor(
    protected override injector: Injector,
    private absiService: AbsiService,
    private checklistBuilderService: ChecklistBuilderService,
  ) {
    super(injector);
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.canEdit = this.hasPrivilege(this.privilegeEnum.CreateTemplateType) && !this.isDisabled;
    this.displayedColumnsTemplateType = this.canEdit ? ['name', 'description', 'code', 'add'] : ['name', 'description', 'code'];
    if (this.documentType?.documentTemplateId)
      this.loadTemplateTypesByDocTypeId(this.documentType?.documentTemplateId);
    else {
      this.templateTypes = [];
      this.dataSource = new MatTableDataSource(this.templateTypes);
    }
    this.hasChecklistTemplates = false;
    this.loadChecklistTemplates();
  }

  override ngOnDestroy(): void {
    this.templateTypeSubscription?.unsubscribe();
    this.absiTemplateTypeSubscription?.unsubscribe();
    this.checklistTemplatesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadChecklistTemplates() {
    if (this.documentType?.documentTemplateId)
      this.store.dispatch(new ChecklistTemplatesRefreshByDocument(this.documentType?.documentTemplateId));
    this.checklistTemplates$ = this.store.select(state => state.ChecklistTemplates.data);
    this.checklistTemplatesSubs = this.checklistTemplates$.subscribe(data => {
      if (data?.length) {
        this.checklistTemplates = data;
        this.checkChecklistTemplates();
      }
    });
  }

  loadTemplateTypesByDocTypeId(documentTypeId: number) {
    if (documentTypeId != 10) {
      this.store.dispatch(new TemplateTypesRefreshByDocument(documentTypeId));
      this.isAbsiTemplateType = false;
      this.templateTypes$ = this.store.select(x => x.TemplateTypes.data);
      this.templateTypeSubscription = this.templateTypes$.subscribe(templateTypes => {
        this.templateTypes = templateTypes.filter(x => x.documentTypeID == documentTypeId).sort((a, b) => this.utils.sort(a.order, b.order, true));
        this.checkChecklistTemplates();
        this.dataSource = new MatTableDataSource(this.templateTypes);
      });
    }
  }

  checkChecklistTemplates(isAbsi?: boolean) {
    this.templateTypes?.map(t => {
      if (!isAbsi)
        t.checklistTemplates = this.checklistTemplates?.filter(c => c.templateTypeID == t.id);
      if (t.checklistTemplates?.length)
        this.hasChecklistTemplates = true;
    });
  }

  loadAbsiTemplateTypes() {
    this.isAbsiTemplateType = true;
    this.absiTemplateTypes$ = this.store.select(x => x.AbsiTemplateType.data);
    this.absiTemplateTypeSubscription = this.absiTemplateTypes$.subscribe(absiTemplateTypes => {
      const templateTypesFormatted: TemplateType[] = [];
      absiTemplateTypes?.map(absiTemplateType => {
        const templateTypeFormatted: TemplateType = {
          id: absiTemplateType.id ?? 0,
          name: absiTemplateType.name,
          scheduleTypeID: this.scheduleType?.id,
          description: absiTemplateType.description,
          code: absiTemplateType.code,
          checklistTemplates: absiTemplateType.absiTemplates as ChecklistTemplate[]
        };
        templateTypesFormatted.push(templateTypeFormatted);
      });
      this.templateTypes = templateTypesFormatted;
      this.checkChecklistTemplates(true);
      this.dataSource = new MatTableDataSource(this.templateTypes);
    });
  }

  templateTypeDialog(templateType?: TemplateType) {
    this.openTemplateTypeDialog(templateType);
  }

  openTemplateTypeDialog(templateType?: TemplateType) {
    const dialogConfig = new MatDialogConfig();
    const data: TemplateTypeDialogData = {
      templateType: templateType ? this.utils.cloneDeep(templateType) : { id: 0 },
      documentType: this.documentType,
      isAbsiTemplateType: this.isAbsiTemplateType
    }
    dialogConfig.data = data;
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '60%';
    dialogConfig.minHeight = '450px';
    const dialogRef = this.dialog.open(AddEditTemplateTypeComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then((templateTypeToSave: TemplateType) => {
      if (templateTypeToSave) {
        this.addUpdateTable(templateTypeToSave);
        this.changed.emit(this.templateTypes);
      }
    });
  }

  delete(templateType: TemplateType) {
    const index = this.templateTypes.findIndex(x => x.id == templateType.id) ?? -1;
    if (index >= 0)
      this.templateTypes.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.templateTypes);
    this.changed.emit(this.templateTypes);
  }

  addUpdateTable(templateType: TemplateType) {
    if (templateType.id) {
      const index = this.templateTypes.findIndex(x => x.id == templateType.id) ?? -1;
      if (index >= 0) this.templateTypes[index] = templateType;
    }
    else {
      templateType.documentTypeID = this.documentType?.id;
      templateType.order = Math.max(...this.templateTypes.map(t => t.order ?? 0)) + 1;
      this.templateTypes.push(templateType);
    }
    this.dataSource = new MatTableDataSource(this.templateTypes);
    this.changed.emit(this.templateTypes);
  }

  addUpdateDeleteDb() {
    this.addUpdateDeleteTemplates();
  }

  addUpdateDeleteTemplates() {
    this.dataSource.data.map(templateType => {
      if (templateType.actionPending) {
        if (this.isAbsiTemplateType) {
          switch (templateType.actionPending) {
            case ActionPendingCatalog.Update:
              this.updateAbsiTemplateType(templateType);
              break;
            case ActionPendingCatalog.Delete:
              this.deleteAbsiTemplateType(templateType);
              break;
            case ActionPendingCatalog.Create:
              this.createAbsiTemplateType(templateType);
              break;
          }
        }
        else {
          switch (templateType.actionPending) {
            case ActionPendingCatalog.Update:
              this.updateTemplateType(templateType);
              break;
            case ActionPendingCatalog.Delete:
              this.deleteTemplateType(templateType);
              break;
            case ActionPendingCatalog.Create:
              this.createTemplateType(templateType);
              break;
          }
        }
        templateType.actionPending = ActionPendingCatalog.None;
      }
    });
  }

  //#region ABSI
  createAbsiTemplateType(templateType: TemplateType) {
    const absiTemplateType: AbsiTemplateTypeResource = {
      name: templateType.name,
      description: templateType.description,
      code: templateType.code
    };
    this.absiService.createAbsiTemplateType(absiTemplateType).toPromise();
  }

  updateAbsiTemplateType(templateType: TemplateType) {
    const absiTemplateType: AbsiTemplateTypeResource = {
      id: templateType.id,
      name: templateType.name,
      description: templateType.description,
      code: templateType.code
    };
    if (absiTemplateType.id)
      this.absiService.updateAbsiTemplateType(absiTemplateType.id, absiTemplateType).toPromise();
  }

  deleteAbsiTemplateType(templateTypeToDelete: TemplateType) {
    this.absiService.deleteAbsiTemplateType(templateTypeToDelete.id, templateTypeToDelete.deleteReason ?? '').toPromise();
  }

  //#endregion ABSI

  refreshStore() {
    this.store.dispatch(new TemplateTypesRefresh());
    this.store.dispatch(new ScheduleTypesRefresh());
    this.store.dispatch(new DocumentTypeRefresh());
  }

  createTemplateType(templateType: TemplateType) {
    templateType.documentTypeID = this.documentType?.documentTemplateId;
    this.checklistBuilderService.createTemplateType(this.utils.Serialize(templateType)).toPromise().then(() => {
      this.refreshStore();
    }).catch(() => {
      this.alert.defaultError();
    });
  }

  updateTemplateType(templateType: TemplateType) {
    this.checklistBuilderService.updateTemplateType(templateType).toPromise().then(() => {
      this.refreshStore();
    }).catch(() => {
      this.alert.defaultError();
    });
  }

  deleteTemplateType(templateTypeToDelete: TemplateType) {
    this.deleteTemplate(templateTypeToDelete);
    this.refreshStore();
  }

  deleteTemplate(templateType: TemplateType) {
    this.checklistBuilderService.deleteTemplateType(templateType, templateType.deleteReason ?? '').toPromise().then(() => {
      this.store.dispatch(new TemplateTypesDelete(templateType.id));
      this.refreshStore();
    }).catch(() => {
      this.alert.defaultError();
    });
  }

  deleteTemplateTypeReason(templateTypeIndex: number) {
    this.openDeleteTemplateTypeReasonModal(templateTypeIndex);
  }

  openDeleteTemplateTypeReasonModal(templateTypeIndex: number) {
    const dialogConfig: MatDialogConfig = {
      data: {
        label: 'Delete Reason',
        placeholder: 'Delete Reason',
        text: ''
      }
    };
    const dialogRefSureToDelete = this.dialog.open(TextareaDialogComponent, dialogConfig);
    dialogRefSureToDelete.afterClosed().toPromise().then(data => {
      if (data?.acceptClicked && data?.text) {
        this.dataSource.data[templateTypeIndex].deleteReason = data.text;
        this.alert.message('templateTypeTab_Deleted');
        this.changed.emit(this.templateTypes);
      }
    });
  }

}
