import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ResourceOrganization, Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { Organization } from './organization';
import { OrganizationService } from './organization.service';


@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent extends BaseComponent implements OnInit {

  public endstations!: Resource[];
  public branchlines!: Resource[];
  public organizations!: Organization[];
  public organizationsFiltered!: Organization[];
  public resourceOrganizationToSend!: ResourceOrganization;

  public isSaving!: boolean;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<OrganizationComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: {
      resourceOrganization: ResourceOrganization,
      branchlines: Resource[],
      endstations: Resource[]
    },
    private organizationService: OrganizationService,
  ) {
    super(injector);
  }

  async ngOnInit() {
    await this.loadOrganizations();
  }

  add() {
    this.isSaving = true;
    if (this.isValid()) {
      this.inputData.resourceOrganization.organization = typeof (this.inputData.resourceOrganization.organization) === 'object' ? this.inputData.resourceOrganization.organization : { name: this.inputData.resourceOrganization.organization } as Organization;
      this.dialogRef.close(this.inputData.resourceOrganization);
    } else {
      this.isSaving = false;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  async loadOrganizations() {
    await this.organizationService.Read().toPromise().then(organizations => {
      this.organizations = organizations ?? [];
      this.organizationsFiltered = organizations ?? [];
    });
  }

  displayOrganizationName(organization: Organization) {
    return organization && organization.name ? organization.name : organization;
  }

  compareResource(resource1: Resource, resource2: Resource) {
    return resource1 && resource2 && resource1.name === resource2.name;
  }

  isValid() {
    const organizationSelected = typeof (this.inputData.resourceOrganization.organization) === 'object' ? this.inputData.resourceOrganization.organization : { name: this.inputData.resourceOrganization.organization } as Organization;
    if (!organizationSelected.name) {
      this.alert.message('organizationDialog_Required');
      return false;
    }
    return true;
  }

  filter(e: any) {
    const text = e.target.value;
    const code = e.code;
    if (!code?.includes('Arrow')) {
      this.organizationsFiltered = this.organizations.filter(x => x.name?.toLocaleLowerCase().includes(text.trim().toLocaleLowerCase()));
    }
  }
}
