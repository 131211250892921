import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { SCFMaster, SCFHoldPointResource } from 'src/app/components/scf-v2/scf';
import { utils } from 'src/app/modules/libs/utils';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';

@Component({
  selector: 'scf-v2-main-s4-holdpoints-add',
  templateUrl: './scf-v2-main-s4-holdpoints-add.component.html',
  styleUrls: ['./scf-v2-main-s4-holdpoints-add.component.scss']
})
export class ScfV2MainS4HoldpointsAddComponent implements OnInit, OnDestroy {

  wfTaskLocals?: WfTaskLocalResource[];
  scfMaster: SCFMaster;
  scfHoldPoint: SCFHoldPointResource;

  roles?: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  constructor(
    private matDialogRef: MatDialogRef<ScfV2MainS4HoldpointsAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      scfMaster: SCFMaster;
      scfHoldPoint: SCFHoldPointResource
    },
    private store: Store
  ) {
    this.scfMaster = data.scfMaster;
    this.scfHoldPoint = data.scfHoldPoint;
  }

  ngOnDestroy(): void {
    this.rolesSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.loadRoles();
    if (this.scfHoldPoint.wfTaskLocals) {
      this.wfTaskLocals = this.scfHoldPoint.wfTaskLocals;
      this.wfTaskLocals.map(t => {
        t.required = t.wfTasks?.length ? t.wfTasks[0].required : false;
        this.getRoles(t);
      });
    }
    else {
      this.wfTaskLocals = utils.cloneDeep(this.scfMaster.wfTable?.wfTableLocal?.wfSectionLocals.find(x => x.number == 3)?.wfStepLocals?.find(x => x.code == 'HOLDPOINT')?.wfTaskLocals.filter(x => !x.holdPointID).sort((a, b) => (a.order ?? 0) - (b.order ?? 0)));
      this.wfTaskLocals?.map(t => { this.getRoles(t); });
    }
  }

  getRoles(wfTaskLocal: WfTaskLocalResource) {
    if (wfTaskLocal.requesterSignature) {
      return 'Requester: ' + this.scfMaster.requesterName;
    }
    const rolesArray: Role[] = [];
    wfTaskLocal.wfRoleLocals?.map(r => {
      const role = this.roles?.find(x => x.id == r.roleID);
      if (role)
        rolesArray.push(role);
    });
    const roles = rolesArray.map(x => x.code).join('/');
    return roles;
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      this.roles = data;
    });
  }

  textChanged(e: any) {
    this.scfHoldPoint.description = e;
  }

  cancel() {
    this.matDialogRef.close();
  }

  save() {
    if (this.scfMaster.id) {
      if (!this.scfHoldPoint.id) {
        this.wfTaskLocals?.map(t => {
          t.id = 0;
          t.wfActionLocals?.map(a => a.id = 0);
          t.wfRoleLocals?.map(r => r.id = 0);
        });
      }
      this.scfHoldPoint.wfTaskLocals = this.wfTaskLocals;
      this.scfHoldPoint.scfMasterID = this.scfMaster.id;
      this.matDialogRef.close(this.scfHoldPoint);
    }
  }

  isValid() {
    return this.scfHoldPoint.description?.trim() != '' && this.wfTaskLocals?.filter(x => x.required).length;
  }

}
