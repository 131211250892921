import { AbstractControl } from "@angular/forms";
import { CompareOperator, ComponentType, CustomImageSizeOptions, GeneralCondition, ReturnType } from "src/app/common/enumerations/enumerations";
import { QuillCustomClasses } from "./quill/quill-custom-image-resize/quill-custom-names";
import { WFSectionLocalResource, WfTaskLocalResource } from "src/app/services/work-flow/work-flow";
import { Option, Shielding } from "src/app/components/checklists/checklists";
import { RadMeter } from "src/app/components/catalogs/meters-catalog/rad-meters";
import * as _ from 'lodash';
import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import * as moment from "moment";
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff';
import { User } from "src/app/components/catalogs/user-catalog/services/user";
import { WFTableResource } from "src/app/components/workflow/workflow";
import { DatePipe } from "@angular/common";
import { EmailAddress } from "src/app/components/olog/olog";

export class utils {

  static isMobileDevice() {
    const isTouchDevice = window.ontouchstart || navigator.maxTouchPoints > 0;
    return isTouchDevice;
  }

  static SortProcedures(a?: string, b?: string): number {
    const aSeparated = a?.match(/[a-zA-Z]+|[0-9]+/g);
    const bSeparated = b?.match(/[a-zA-Z]+|[0-9]+/g);

    for (let i = 0; i < Math.max(aSeparated?.length ?? 0, bSeparated?.length ?? 0); i++) {
      const aElement = aSeparated?.[i];
      const bElement = bSeparated?.[i];

      if (!aElement) {
        return -1;
      }
      if (!bElement) {
        return 1;
      }

      const aNum = Number(aElement);
      const bNum = Number(bElement);

      if (isNaN(aNum) || isNaN(bNum)) {
        // Compare as strings
        if (aElement < bElement) {
          return -1;
        } else if (aElement > bElement) {
          return 1;
        }
      } else {
        // Compare as numbers
        if (aNum < bNum) {
          return -1;
        } else if (aNum > bNum) {
          return 1;
        }
      }
    }
    // All elements are equal up to this point
    return 0;
  }

  static SortBeamlines(a?: string | null, b?: string | null) {
    // Validate emptys
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    a = a.toUpperCase();
    b = b.toUpperCase();
    // Validate and sort the just-lyrics elements
    const aisblbr =
      (a.substring(0, 3) == "BL " || a.substring(0, 3) == "BR ") &&
      a.match(/\d+/g) != null;
    const bisblbr =
      (b.substring(0, 3) == "BL " || b.substring(0, 3) == "BR ") &&
      b.match(/\d+/g) != null;
    if (!aisblbr && !bisblbr) {
      if (a > b) {
        return 1;
      }
      if (b > a) {
        return -1;
      }
      return 0;
    }
    if (aisblbr && !bisblbr) {
      return 1;
    }
    if (!aisblbr && bisblbr) {
      return -1;
    }
    // Separate BL and BR
    const aisbl = a.substring(0, 3) == "BL ";
    const bisbl = b.substring(0, 3) == "BL ";
    if (!aisbl && bisbl) {
      return 1;
    }
    if (aisbl && !bisbl) {
      return -1;
    }
    a = a + ".";
    b = b + ".";
    // Sort the BR or BR
    const aLength = a.match(/\d+[.]/g)?.length ?? 0;
    const bLength = b.match(/\d+[.]/g)?.length ?? 0;
    if (aLength > bLength) {
      for (let i = 0; i < aLength; i++) {
        if (i > bLength - 1) {
          return 1;
        }
        let aMatchResult = a.match(/\d+[.]/g);
        let asub = '';
        if (aMatchResult) {
          asub = aMatchResult[i];
        }
        asub = asub.substring(0, asub.length - 1);
        let bMatchResult = a.match(/\d+[.]/g);
        let bsub = '';
        if (bMatchResult) {
          bsub = bMatchResult[i];
        }
        bsub = bsub.substring(0, bsub.length - 1);
        if (Number.parseInt(asub) > Number.parseInt(bsub)) {
          return 1;
        }
        if (Number.parseInt(asub) < Number.parseInt(bsub)) {
          return -1;
        }
      }
    } else {
      for (let i = 0; i < bLength; i++) {
        if (i > aLength - 1) {
          return -1;
        }
        let aMatchResult = a.match(/\d+[.]/g);
        let asub = '';
        if (aMatchResult) {
          asub = aMatchResult[i];
        }
        asub = asub.substring(0, asub.length - 1);
        let bMatchResult = a.match(/\d+[.]/g);
        let bsub = '';
        if (bMatchResult) {
          bsub = bMatchResult[i];
        }
        bsub = bsub.substring(0, bsub.length - 1);
        if (Number.parseInt(asub) > Number.parseInt(bsub)) {
          return 1;
        }
        if (Number.parseInt(asub) < Number.parseInt(bsub)) {
          return -1;
        }
      }
    }
    return 1;
  }

  static sortArrayAlphabeticallyWithComplexNumbers(a?: string | null, b?: string | null, dir: string | null = 'asc', ignoreCase: boolean = false) {
    const compareString = (strA?: string | null, strB?: string | null) => {
      if (ignoreCase) {
        strA = strA?.toLowerCase();
        strB = strB?.toLowerCase();
      }

      // Define regular expressions to match alphanumeric and numeric parts
      const regex = /(\D+)|(\d+(\.\d+)?)/g;

      // Extract all alphanumeric and numeric parts from the strings
      const aParts = strA?.match(regex) || [];
      const bParts = strB?.match(regex) || [];

      // Iterate through the parts to compare them
      for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
        const partA = aParts[i];
        const partB = bParts[i];

        if (!isNaN(Number(partA)) && !isNaN(Number(partB))) {
          // If both parts are numeric, compare them as numbers
          const numA = parseFloat(partA);
          const numB = parseFloat(partB);

          if (numA < numB) return -1;
          if (numA > numB) return 1;
        } else {
          // Otherwise, compare the parts as strings
          const stringComparison = partA.localeCompare(partB, undefined, { numeric: true });
          if (stringComparison !== 0) {
            return stringComparison;
          }
        }
      }

      // Handle the case where one string is a prefix of the other
      return aParts.length - bParts.length;
    };

    return compareString(dir == 'asc' ? a : b, dir == 'asc' ? b : a);
  };


  static getNestedValue<T>(obj: { [key: string]: any }, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  static sortByMultipleFields<T extends { [key: string]: any }>(
    fields: string[]
  ): (a: T, b: T) => number {
    return (a: T, b: T) => {
      for (let field of fields) {
        const aValue = this.getNestedValue(a, field);
        const bValue = this.getNestedValue(b, field);

        if (aValue < bValue) {
          return -1;
        }
        if (aValue > bValue) {
          return 1;
        }
      }
      return 0;
    };
  }

  static DatePlusseconds(seconds: number) {
    const d1 = new Date();
    const d2 = new Date(d1);
    d2.setSeconds(d1.getSeconds() + seconds);
    return d2;
  }

  static cloneDeep<T>(data: T): T {
    const copy = _.cloneDeep(data);
    return copy as T;
  }

  static Serialize<T>(data: T): T {
    const result = { ...data };
    for (const key in result) {
      if (Object.prototype.toString.call(data[key]) == "[object Object]") {
        result[key] = null as any;
      }
    }
    return result;
  }

  static IsNullOrWhiteSpace(input: string) {
    return !input || !input?.trim();
  }

  static IsWhiteSpaceReactiveForm(control: AbstractControl) {
    if (typeof control.value === "string") {
      if (!control.value.trim()) {
        return { hasWhiteSpace: true };
      }
    }
    return null;
  }

  static OrderByDate(a: string | number | Date, b: string | number | Date) {
    a = new Date(a);
    b = new Date(b);
    return a > b ? -1 : a < b ? 1 : 0;
  }

  static JSONparse(val?: string | null) {
    if (val)
      try {
        return JSON.parse(val);
      } catch (e) {
        // console.log(e);
        return null;
      }
  }

  // static JSONParseClass(val: string, classType: new () => any): any {
  //   const obj = this.JSONparse(val);
  //   if (typeof obj !== 'object' || obj === null) {
  //     return obj;
  //   }

  //   if (Array.isArray(obj)) {
  //     return obj.map((item) => this.JSONParseClass(item, classType));
  //   }

  //   const result: any = {};
  //   const myClass = new classType();
  //   const propertyNames = Object.keys(myClass);

  //   for (const key in obj) {
  //     if (obj.hasOwnProperty(key)) {
  //       const newKey = propertyNames.find((propertyName) => propertyName.toLowerCase() === key.toLowerCase());

  //       if (newKey) {
  //         result[newKey] = this.JSONParseClass(obj[key], classType);
  //       } else {
  //         result[key] = this.JSONParseClass(obj[key], classType);
  //       }
  //     }
  //   }

  //   return result;
  // }

  static JSONstringify(val: any) {
    const cache = new Set();
    return JSON.stringify(
      val,
      (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (cache.has(value)) {
            // Circular reference found, discard key
            return;
          }
          // Store value in our collection
          cache.add(value);
        }
        return value;
      },
      4
    );
  }

  private static operatorsArray() {
    return Object.values(Operators);
  }

  private static getValues(
    tag: string,
    tasks: WfTaskLocalResource[],
    operator: Operators = Operators.NONE
  ) {
    let values: any[] = [];
    tasks
      .filter((t) => t.code?.trim() == tag.trim())
      .map((t) => {
        if (t.component?.returnType) {
          switch (t.component.returnType) {
            case ReturnType.Boolean:
              if (t.component.hasMultipleRoles) {
                values = this.getRolesChecked(t, operator);
              } else {
                values = this.getBoolean(t, operator);
              }
              break;
            case ReturnType.Numeric:
              values = this.getChecked(t, operator);
              break;
            case ReturnType.Sign:
              if (operator == Operators.NONE) {
                values.push(t.wfTasks?.[0].signedBy != null);
              } else {
                values.push(t.wfTasks?.[0].signedBy == null);
              }
              break;
            case ReturnType.Text:
              values = this.getText(t);
              break;
            default:
              values.push(t.value);
          }
        }
      });
    return values;
  }

  private static getText(task: WfTaskLocalResource): any[] {
    let values: any[] = [];
    const storedText = this.JSONparse(task.text);
    if (storedText?.length) {
      if (storedText.some((x: { key: any; }) => x.key)) {
        const storedValues = storedText.find((x: { key: string; }) => x.key == "values").val;
        values = storedValues.filter((x: { text: any; }) => x.text).map((x: { text: any; }) => x.text);
      } else {
        const storedString = this.JSONparse(storedText[0].stringValue);
        const storedValues = storedString.find((x: { key: string; }) => x.key == "values").val;
        values = storedValues.filter((x: { text: any; }) => x.text).map((x: { text: any; }) => x.text);
      }
    }
    return values;
  }



  private static getRolesChecked(
    task: WfTaskLocalResource,
    operator: Operators
  ): any[] {
    let values: any[] = [];
    let roleOptions = [];
    const storedText = this.JSONparse(task.text);
    if (task.text?.includes("stringValue")) {
      roleOptions = this.JSONparse(storedText[0].stringValue);
    } else {
      roleOptions = storedText;
    }
    if (operator == Operators.NONE) {
      values.push(
        roleOptions &&
        roleOptions?.filter((o: { checked: any; }) => o.checked).length == roleOptions?.length
      );
    } else {
      values.push(
        !roleOptions ||
        roleOptions?.filter((o: { checked: any; }) => o.checked).length != roleOptions?.length
      );
    }
    return values;
  }

  private static getBoolean(
    task: WfTaskLocalResource,
    operator: Operators
  ): any[] {
    const values: any[] = [];
    const storedText = this.JSONparse(task.text);
    if (storedText?.length) {
      if (storedText.some((x: { key: any; }) => x.key)) {
        const storedValues = storedText.find(
          (x: { key: string; }) =>
            x.key == (operator == Operators.NONE ? "checkedYes" : "checkedNo")
        ).val;
        values.push(storedValues);
      } else {
        const storedString = this.JSONparse(storedText[0].stringValue);
        const storedValues = storedString.find(
          (x: { key: string; }) =>
            x.key == (operator == Operators.NONE ? "checkedYes" : "checkedNo")
        ).val;
        values.push(storedValues);
      }
    }
    return values;
  }

  private static getChecked(task: WfTaskLocalResource, operator: Operators): any[] {
    const component = task.component;
    let values = [];
    if (component?.hasMultipleCheckboxes) {
      const storedText = this.JSONparse(task.text);
      if (storedText?.length) {
        if (storedText.some((x: { key: any; }) => x.key)) {
          const storedValues = storedText.find((x: { key: string; }) => x.key == "checked").val;
          values = storedValues.filter((x: { checked: any; }) => x.checked).map((x: { value: any; }) => x.value);
        } else {
          const storedString = this.JSONparse(storedText[0].stringValue);
          const storedValues = storedString.find((x: { key: string; }) => x.key == "checked").val;
          values = storedValues.filter((x: { checked: any; }) => x.checked).map((x: { value: any; }) => x.value);
        }
      }
    } else if (component?.hasRadioButtons) {
      if (operator != Operators.NONE) {
        values.push(task.numericValue?.toString());
      }
      else {
        values.push(task.numericValue != 0 && task.wfTasks?.[0]?.signedBy != null);
      }
    } else {
      if (task.booleanValue != undefined) {
        values.push(task.booleanValue);
      }
      if (task.isCompleted != undefined) {
        values.push(task.isCompleted);
      }
    }
    return values;
  }

  static conditionParse(
    condition: string,
    tasks: WfTaskLocalResource[]
  ): boolean {
    if (!condition) {
      return true;
    }
    let result = false;
    let operator = this.operatorsArray().find((x) =>
      condition.includes(x.toString())
    );
    let operands: any[] = [];
    let var1 = null;
    let var2 = null;
    let value = null;

    if (!operator) {
      operator = condition?.includes(Operators.NOT)
        ? Operators.NOT
        : Operators.NONE;
    }
    switch (operator) {
      case Operators.EQUAL:
      case Operators.EQUAL2:
        operands = condition.split(operator);
        if (operands.length == 2) {
          var1 = operands[0].trim();
          value = operands[1].trim().replace(/'/g, "");
          result = this.getValues(var1, tasks, operator).includes(value);
        }
        break;
      case Operators.NOTEQUAL:
      case Operators.NOTEQUAL2:
        operands = condition.split(operator);
        if (operands.length == 2) {
          var1 = operands[0].trim();
          value = operands[1].trim();
          result = !this.getValues(var1, tasks, operator).includes(value);
        }
        break;
      case Operators.AND:
        operands = condition.split(operator);
        if (operands.length == 2) {
          var1 = operands[0].trim();
          var2 = operands[1].trim();
          result =
            this.getValues(var1, tasks).includes(true) &&
            this.getValues(var2, tasks).includes(true);
        }
        break;
      case Operators.OR:
        operands = condition.split(operator);
        if (operands.length == 2) {
          var1 = operands[0].trim();
          var2 = operands[1].trim();
          result =
            this.getValues(var1, tasks).includes(true) ||
            this.getValues(var2, tasks).includes(true);
        }
        break;
      case Operators.NOT:
      case Operators.NONE:
        condition = condition.replace("!", "");
        const generalConditions = this.getENUMString(GeneralCondition);
        if (generalConditions.map((x) => x.value).includes(condition)) {
          result = this.evalGeneralConditions(condition, tasks);
        } else {
          result = this.getValues(condition, tasks, operator).includes(true);
        }

        break;
    }
    return result;
  }

  static evalGeneralConditions(
    condition: string,
    tasks: WfTaskLocalResource[]
  ) {
    let result = true;
    switch (condition) {
      case GeneralCondition.AllTasksCompleted:
        tasks
          .filter((t) => t.condition != condition)
          .map((t) => {
            const req = this.conditionParse(t.condition ?? '', tasks);
            if (req) {
              const res = this.taskComplete(t);
              if (!res) {
                result = res ?? false;
              }
            }
          });

        break;
      default:
        return true;
    }
    return result;
  }

  static parseConditions(tasks: WfTaskLocalResource[]) {
    tasks.map(t => {
      let value = true;
      if (t.condition) {
        value = this.conditionParse(t.condition, tasks);
      }
      let hideDisable = false;
      if (t.configuration) {
        const configuration = this.JSONparse(t.configuration);
        hideDisable = configuration.hideDisable;
      }
      t.required = !hideDisable ? value : true;
      t.disabled = hideDisable ? !value : false;
      if (t.wfTasks?.[0]) {
        t.wfTasks[0].required = t.required;
        t.wfTasks[0].enabled = !t.disabled;
      }
      t.isCompleted = t.required ? this.taskComplete(t) : true;
    });
    return tasks;
  }

  static isEquivalent(obj1: any, obj2: any) {
    const dif = diff(obj1, obj2) as Record<string, any>;
    return Object.keys(dif).length === 0;
  }


  static isNull(x: any, res: any) {
    if (x == undefined || x == null) {
      return res;
    } else {
      return x;
    }
  }

  /* finds the intersection of
   * two arrays in a simple fashion.
   *
   * PARAMS
   *  a - first array, must already be sorted
   *  b - second array, must already be sorted
   *
   * NOTES
   *
   *  Should have O(n) operations, where n is
   *    n = MIN(a.length(), b.length())
   */
  static intersect(a: Iterable<unknown> | null | undefined, b: Iterable<unknown> | null | undefined) {
    const setB = new Set(b);
    const intersect = [...new Set(a)].filter((x) => setB.has(x));
    return intersect.length > 0;
  }

  static compare(
    a?: number | string | Date | null,
    b?: number | string | Date | null,
    isAsc?: boolean
  ) {
    if (a && b)
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    return 0;
  }

  // static sort(
  //   a?: number | string | boolean | Date | null | undefined,
  //   b?: number | string | boolean | Date | null | undefined,
  //   isAsc: boolean = true
  // ) {
  //   return (!a ? 1 : !b ? -1 : a > b ? 1 : -1) * (isAsc ? 1 : -1);
  // }

  // static sort(a?: number | null, b?: number | null, isAsc: boolean = true) {
  //   return ((a ?? 0) - (b ?? 0)) * (isAsc ? 1 : -1);
  // }

  static sort(
    a: number | string | Date | boolean | null | undefined,
    b: number | string | Date | boolean | null | undefined,
    isAsc: boolean = true
  ) {
    if (a === undefined || a === null) return isAsc ? 1 : -1;
    if (b === undefined || b === null) return isAsc ? -1 : 1;

    if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b) * (isAsc ? 1 : -1);
    }

    if (moment.isDate(a) && moment.isDate(b)) {
      const momentA = moment(a);
      const momentB = moment(b);
      return momentA.diff(momentB) * (isAsc ? 1 : -1);
    }

    // For numbers, booleans, and other types
    return (((a as number) ?? 0) - ((b as number) ?? 0)) * (isAsc ? 1 : -1);
  }

  static isNullValue(value?: string | null) {
    return value == null || value == "" || value == undefined;
  }

  static replaceAll(str: string, find: string | RegExp, replace: any) {
    return str?.replace(new RegExp(find, "g"), replace);
  }

  static getENUM(ENUM: any): any[] {
    const myEnum: { key: string; value: any; }[] = [];
    const objectEnum = Object.entries(ENUM);
    objectEnum.map((e) => {
      if (typeof e[1] === "number") {
        myEnum.push({ key: e[0], value: e[1] });
      }
    });
    return myEnum.filter(x => !isNaN(x.value));
  }

  static getENUMString(ENUM: any): any[] {
    const myEnum: any[] = [];
    const objectEnum = Object.entries(ENUM);
    objectEnum.map((e) => {
      myEnum.push({ key: e[0], value: e[1] });
    });
    return myEnum;
  }

  static enumToIdNameArray(enumObj: any): { id: number | string; name: string }[] {
    return Object.keys(enumObj)
      .filter(key => isNaN(Number(key))) // Exclude numeric keys
      .map(key => ({
        id: enumObj[key], // Enum value
        name: key // Enum key
      }));
  }

  // Resize images inside quill editor
  static resizeImage(customSize: any, event: any) {
    let naturalWidth;
    let normalWidth = 504;
    let smallDivider = 2;
    let fitDivider = 1.5;

    if (screen.width < 1000) {
      normalWidth = screen.width;
      smallDivider = 4;
      fitDivider = 2.5;
    }

    switch (customSize) {
      case CustomImageSizeOptions.Small:
        naturalWidth = normalWidth / smallDivider;
        break;
      case CustomImageSizeOptions.Fit:
        naturalWidth =
          event.target.naturalWidth > normalWidth
            ? normalWidth / fitDivider
            : event.target.naturalWidth / fitDivider;
        break;
      case CustomImageSizeOptions.Original:
        naturalWidth = event.target.naturalWidth;
        break;
    }
    event.target.width = naturalWidth;
  }

  // Removes containers for the custom image resize inside quill editor
  static removeExistingCustomContainers() {
    const html = '<p>Small | Fit | Original</p>';
    const elements = document.getElementsByClassName(
      QuillCustomClasses.custom_image_sizes_class
    );
    while (elements.length > 0) {
      const parent = elements[0].parentNode?.parentElement;
      elements[0].parentNode?.removeChild(elements[0]);
      if (parent)
        this.removeHtmlInstancesFromElement(parent, html);
    }

    this.removeHtmlInstances(html);
  }

  static removeHtmlInstancesFromElement(element: HTMLElement | null, html: string) {
    const regex = new RegExp(html.replace(/\|/g, '\\|'), 'g'); // Escape the pipe character in the regex
    if (element)
      element.innerHTML = element.innerHTML.replace(regex, '');
  }

  static removeHtmlInstances(html: string) {
    const quillEditors = document.getElementsByClassName('ql-editor');

    Array.from(quillEditors).map((editor) => {
      const regex = new RegExp(html.replace(/\|/g, '\\|'), 'g'); // Escape the pipe character in the regex
      editor.innerHTML = editor.innerHTML.replace(regex, '');
    });
  }

  static convertToResource(object: any): Resource {
    const resource = new Resource();
    const objectProps = Object.entries(object);
    const resourceProps = Object.entries(resource);

    objectProps.map((p) => {
      const propName = p[0];
      const propValue = p[1];
      const resourceProp = resourceProps.find((x) => x[0].toLowerCase() === propName.toLowerCase());

      if (resourceProp) {
        // Use type assertion to access properties dynamically
        (resource as any)[resourceProp[0]] = propValue;
      }
    });

    return resource;
  }


  static convertObjectKeysToCamelCase<T>(obj: any | any[]): T | T[] {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(item => this.convertObjectKeysToCamelCase(item)) as T[];
    }

    const instance = {} as { [key: string]: any };

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
        instance[camelCaseKey] = this.convertObjectKeysToCamelCase(obj[key]);
      }
    }

    return instance as T;
  }

  // static convertToResourceSummary(object: any): ResourceSummary {
  //   const resource = new ResourceSummary();
  //   const objectProps = Object.entries(object);
  //   const resourceProps = Object.entries(resource);
  //   objectProps.map((p) => {
  //     const prop = resourceProps.find(
  //       (x) => x[0].toLowerCase() == p[0].toLowerCase()
  //     );
  //     if (prop) {
  //       const type = typeof prop[1];
  //       switch (type) {
  //         case "object":
  //           switch (prop.constructor.name) {
  //             case "Resource":
  //               resource[prop[0]] = this.convertToResource(p[1]);
  //           }
  //           break;
  //         case "string":
  //           resource[prop[0]] = p[1];
  //           break;
  //       }
  //     }
  //   });
  //   return resource;
  // }

  // static isDate(ExpiryDate: string): boolean {
  //   let objDate; // date object initialized from the ExpiryDate string
  //   let mSeconds; // ExpiryDate in milliseconds
  //   let day; // day
  //   let month; // month
  //   let year; // year
  //   // date length should be 10 characters (no more no less)
  //   if (ExpiryDate.length !== 10) {
  //     return false;
  //   }
  //   // third and sixth character should be '/'
  //   if (
  //     ExpiryDate.substring(2, 3) !== "/" ||
  //     ExpiryDate.substring(5, 6) !== "/"
  //   ) {
  //     return false;
  //   }
  //   // extract month, day and year from the ExpiryDate (expected format is mm/dd/yyyy)
  //   // subtraction will cast variables to integer implicitly (needed
  //   // for !== comparing)
  //   month = ExpiryDate.substring(0, 2) - 1; // because months in JS start from 0
  //   day = ExpiryDate.substring(3, 5) - 0;
  //   year = ExpiryDate.substring(6, 10) - 0;
  //   // test year range
  //   if (year < 1000 || year > 3000) {
  //     return false;
  //   }
  //   // convert ExpiryDate to milliseconds
  //   mSeconds = new Date(year, month, day).getTime();
  //   // initialize Date() object from calculated milliseconds
  //   objDate = new Date();
  //   objDate.setTime(mSeconds);
  //   // compare input date and parts from Date() object
  //   // if difference exists then date isn't valid
  //   if (
  //     objDate.getFullYear() !== year ||
  //     objDate.getMonth() !== month ||
  //     objDate.getDate() !== day
  //   ) {
  //     return false;
  //   }
  //   // otherwise return true
  //   return true;
  // }

  static isValidDate(value: any): boolean {
    if (value instanceof Date) {
      // If the value is already a Date object, it's considered a valid date.
      return !isNaN(value.getTime());
    } else if (typeof value === 'string') {
      // If the value is a string, attempt to parse it as a date.
      const date = new Date(value);
      return !isNaN(date.getTime());
    } else {
      // If the value is neither a Date object nor a string, it's not a valid date.
      return false;
    }
  }


  static cleanHTML(text: string): string {
    return text ? text.replace(/(<([^>]+)>)/gi, "") : "";
  }

  // Clean Quill editor HTML formating
  // static cleanHTML(t) {
  //   this.removeExistingCustomContainers();
  //   if (t) {
  //     t = t?.replaceAll('<p>', '').replaceAll('</p>', '<br>').replaceAll('<strong>', '<b>').replaceAll('</strong>', '</b>');
  //     if (t.includes('<br>') && t.lastIndexOf('<br>') == t.length - 4) {
  //       t = t.slice(0, -4);
  //     }
  //   }
  //   return t;
  // }

  // static roundNumber(num: string | number, scale: string | number) {
  //   if (!("" + num).includes("e")) {
  //     return +(Math.round(+(num + "e+" + scale)) + "e-" + scale);
  //   } else {
  //     const arr = ("" + num).split("e");
  //     let sig = "";
  //     if (+arr[1] + +scale > 0) {
  //       sig = "+";
  //     }
  //     return +(
  //       Math.round(+(+arr[0] + "e" + sig + (+arr[1] + +scale))) +
  //       "e-" +
  //       scale
  //     );
  //   }
  // }

  static roundNumber(num: string | number, scale: number): number {
    const numStr = "" + num; // Convert the input to a string

    if (!numStr.includes("e")) {
      // Handle numbers without exponential notation
      return +(Math.round(+(num + "e+" + scale)) + "e-" + scale);
    } else {
      // Handle numbers in exponential notation
      const [base, exponent] = numStr.split("e").map(str => +str); // Split the base and exponent
      const newExponent = +exponent + scale; // Adjust the exponent

      return +(Math.round(+(base + "e" + newExponent)) + "e-" + scale); // Round and adjust the exponent back
    }
  }

  static insertArrayAt(array: any, index: any, arrayToInsert: any) {
    array.splice(index, 0, ...arrayToInsert);
    return array;
  }

  static numberWithCommas(x: { toString: () => string; }) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static pushNoRepeat(array: any[], item: any) {
    if (!array.includes(item)) {
      array.push(item);
    }
    return array;
  }

  static compareNumbers(n1: number, n2: number, op: CompareOperator) {
    switch (op) {
      case CompareOperator["="]:
        return n1 == n2;
      case CompareOperator["<"]:
        return n1 < n2;
      case CompareOperator["<="]:
        return n1 <= n2;
      case CompareOperator[">"]:
        return n1 > n2;
      case CompareOperator[">="]:
        return n1 >= n2;
      case CompareOperator["<>"]:
        return n1 != n2;
      default:
        return false;
    }
  }

  static taskComplete(task: WfTaskLocalResource) {
    switch (task.component?.type) {
      case 0: // Signature
        if (task.componentID != ComponentType.CloseButton) {
          task.isCompleted = task.wfTasks?.[0]?.signedBy != null;
        } else {
          task.isCompleted = true;
        }
        break;

      case 1: // Normal
        task.isCompleted = this.checkCompletedByComponent(task);
        break;

      default:
        task.isCompleted = true;
    }
    return task.isCompleted;
  }

  static checkCompletedByComponent(task: WfTaskLocalResource) {
    let valueObjects = null;
    switch (task.componentID) {
      case ComponentType.CheckboxMultiple:
      case ComponentType.CheckboxMultipleWithTextbox:
        valueObjects = this.JSONparse(this.getTextValue(task.text));
        const allRequired = this.JSONparse(task.configuration)?.required;
        if (valueObjects) {
          const options = valueObjects?.find((x: { key: string; }) => x.key == "checked")
            ?.val as Option[];
          if (allRequired) {
            return options.find(x => !x.checked) == null;
          }
          else {
            return options.find((x) => x.checked) != null;
          }
        }
        return false;
      case ComponentType.MultipleTextbox:
        valueObjects = this.JSONparse(this.getTextValue(task.text));
        if (valueObjects) {
          const options = valueObjects?.find((x: { key: string; }) => x.key == "values")
            ?.val as Option[];
          return options.find((x) => !x.text) == null;
        }
        break;
      case ComponentType.CheckboxMultipleSign:
        const options = this.JSONparse(this.getTextValue(task.text));
        return options ? options.find((x: { userID: null; }) => x.userID == null) == null : false;
      case ComponentType.RadMeters:
        valueObjects = this.JSONparse(this.getTextValue(task.text));
        if (valueObjects && valueObjects.data?.length) {
          const radMeters = valueObjects.data as RadMeter[];
          return !radMeters.some(r => !r.backgroundReadingChangedByID && !r.sourceReadingChangedByID);
        }
        break;
      case ComponentType.ShieldingsComponent:
        const shieldings = this.JSONparse(this.getTextValue(task.text)) as Shielding[];
        const unchecked = shieldings?.filter(s => s.resourceID).map(s => s.roleOptions).filter(o => o?.some((r) => !r.checked));
        return unchecked?.length == 0;
      case ComponentType.Catl:
      case ComponentType.Olog:
      case ComponentType.StatusChange:
        return true;
      default:
        return task.wfTasks?.[0]?.signedBy != null;
    }
    return false;
  }

  static getTextValue(text: any) {
    const storedValues = this.JSONparse(text);
    if (
      storedValues &&
      storedValues[0]?.Name == null &&
      storedValues[0]?.stringValue
    ) {
      return storedValues[0].stringValue;
    }
    return text;
  }

  static nameToCamelCase(input: string): string {
    const words = input.split(' ');
    const camelCaseWords = words.map(word => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      }
      return '';
    });
    return camelCaseWords.join(' ');
  }

  static compareArrays(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) {
      return false; // If the arrays have different lengths, they can't be equal.
    }

    for (let i = 0; i < arr1.length; i++) {
      // Use a deep equality comparison (e.g., using JSON.stringify) to compare objects.
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        return false; // If any elements differ, the arrays are not equal.
      }
    }

    return true; // If no differences were found, the arrays are equal.
  }

  static stripHTML(html: any) {
    // Replace &nbsp; with a space, then strip HTML tags
    return html.replace(/&nbsp;/g, ' ').replace(/<\/?[^>]+(>|$)/g, "");
  }


  static getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static stripSpaces(text?: string | null) {
    if (text)
      return text.replaceAll(' ', '');
    return null;
  }

  static isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  static getAllRequesters(wfTable?: WFTableResource): User[] {
    return wfTable?.wfTableLocal?.wfSectionLocals.flatMap((section: WFSectionLocalResource) => section.wfSignatures?.filter(s => s.requesterSignature)?.flatMap((signature) => signature.users.flatMap(user => user) || []) || []) || [];
  }

  static formatDate(date: Date | undefined | string | null): string | null {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'MM-dd-yyyy');
  }

  static formatDatePipe(date: Date | string | null | undefined, format: string): string {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, format) || '';
  }

  static parseEmailAddresses(emailStrings?: string[]) {
    const result = emailStrings?.map((emailString) => {
      // Match strings in the format: "Name <email@domain.com>"
      const emailRegex = /^(?:\(([^)]+)\)\s*|([^<>\s]+)\s*<|)([\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,})>?$/;
      const match = emailString?.match(emailRegex);

      if (match) {
        const name = match[1]?.trim();
        const address = match[3]?.trim();
        return {
          name: name || '', // Use empty string if no name is found
          address,
          isCustom: false,  // Default value, adjust as needed
        } as EmailAddress;
      }
      return null;
    }).filter((email) => email !== null);
    return result as EmailAddress[];
  }
}

export enum Operators {
  EQUAL = "==",
  EQUAL2 = "===",
  NOTEQUAL = "!=",
  NOTEQUAL2 = "!==",
  AND = "&&",
  OR = "||",
  NOT = "!",
  NONE = 0,
}
