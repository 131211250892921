<div class="mat-container">
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="beamlineName">
      <mat-header-cell *matHeaderCellDef>Beamline Name</mat-header-cell>
      <mat-cell class="font-weight-bold" *matCellDef="let element" nowrap>
        <span
          [ngClass]="{
            blue: element.status == 1,
            yellow: element.status == 2,
            black: element.status == 3,
            gray: element.status == 4
          }"
          [innerHTML]="element.beamlineName | highlightSearch : filter"
        ></span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="shutterName">
      <mat-header-cell *matHeaderCellDef>Shutter Name</mat-header-cell>
      <mat-cell *matCellDef="let element" nowrap
        ><span
          [ngClass]="{
            blue: element.status == 1,
            yellow: element.status == 2,
            black: element.status == 3,
            gray: element.status == 4
          }"
          [innerHTML]="element.shutterName | highlightSearch : filter"
        ></span
      ></mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="beamLineListColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      #matrow
      *matRowDef="let row; columns: beamLineListColumns"
      (click)="selectBeamline(row)"
      class="table-row"
    ></mat-row>
  </mat-table>
</div>
