import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BulletinBoardItem } from './bulletin-board-item';

@Injectable({
  providedIn: 'root'
})
export class BulletinBoardService {

  public apiBulletinBoard: string = environment.urls.baseApiUrl + '/BulletinBoardItem/';

  selectedItem: BulletinBoardItem | null = null;

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<BulletinBoardItem[]> {
    return this.http.get<BulletinBoardItem[]>(this.apiBulletinBoard + 'GetAll');
  }
  getAllActives(): Observable<BulletinBoardItem[]> {
    return this.http.get<BulletinBoardItem[]>(this.apiBulletinBoard + 'GetActives');
  }
  getAllInactives(): Observable<BulletinBoardItem[]> {
    return this.http.get<BulletinBoardItem[]>(this.apiBulletinBoard + 'GetInactives');
  }
  insertBBI(item: BulletinBoardItem): Observable<BulletinBoardItem> {
    return this.http.post<BulletinBoardItem>(this.apiBulletinBoard, item);
  }
  updateBBI(item: BulletinBoardItem) {
    return this.http.put<BulletinBoardItem>(this.apiBulletinBoard + item.id, item);
  }
  archiveBBI(id: number) {
    return this.http.delete<BulletinBoardItem>(this.apiBulletinBoard + id.toString());
  }
}
