import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { SignalRService } from "./signal-r.service";
import { RSSWAStoreAndListenersService } from 'src/app/components/rsswa/store/rsswa.store';
import { UsersStoreAndListenersService } from "../../components/catalogs/user-catalog/store/users.store";
import { AuthenticationStoreAndListenersService, SetCurrentUser } from "src/app/store/actions";
import { AcceleratorStatusListener } from "src/app/components/home/accelerator-status/store/accelerator-status.listener";
import { BeamlineStatusStoreAndListenersService } from "src/app/components/home/beamline-status/store/bl-status.store";
import { BulletinBoardStoreAndListenersService } from "src/app/components/home/bulletin-board/store/bulletin-board.store";
import { BranchlineStatusStoreAndListenersService } from "src/app/components/home/branchline-status/store/branchline-status.store";
import { SCFMastersStoreAndListenersService } from "src/app/components/scf-v2/store/scf-master/scf-masters.store";
import { ResourceRelationsStoreAndListenersService } from "src/app/components/catalogs/store/resources-relations/resource-relations.store";
import { ResourcesStoreAndListenersService } from "src/app/components/catalogs/store/resources/resources.store";
import { DocumentsStoreAndListenersService } from "../documents/store/documents.store";
import { RestrictionsStoreAndListenersService } from "src/app/components/scf-v2/store/restrictions/restrictions.store";
import { RoleStoreAndListenersService } from "../../components/catalogs/roles/store/role.store";
import { CommentStoreAndListenersService } from "src/app/controls/comments-and-attachments/store/comments.store";
import { SchedulesStoreAndListenersService } from "src/app/components/schedules/store/schedules/schedule.store";
import { ScheduleTypesStoreAndListenersService } from "src/app/components/schedules/store/schedule-types/schedule-types.store";
import { MenuItemStoreAndListenersService } from "src/app/components/head-page/store/menu-item.store";
import { WorkerVerificationStoreAndListenersService } from "src/app/controls/worker-verification/store/worker-verification/worker-verification.store";
import { TemplateTypesStoreAndListenersService } from "src/app/components/checklists/store/template-types/template-types.store";
import { FormsStoreAndListenersService } from "src/app/controls/form-table/store/forms.store";
import { RolePrivilegeStoreAndListenersService } from "../role-privilege/store/role-privilege/role-privilege.store";
import { PrivilegeCategoryStoreAndListenersService } from "../role-privilege/store/privilege-categories/privilege-categories.store";
import { PrivilegeStoreAndListenersService } from "../role-privilege/store/privilege/privilege.store";
import { APLCategoryStoreAndListenersService } from "src/app/components/apl/store/apl-category/apl-category.store";
import { AplGroupStoreAndListenersService } from "src/app/components/apl/store/apl-group/apl-group.store";
import { ProcedureStoreAndListenersService } from "src/app/components/procedure/store/procedure/procedure.store";
import { KEnableStoreAndListenersService } from "src/app/components/kenable/store/ke/kenable.store";
import { FormStatusesStoreAndListenersService } from "src/app/store/form-status/form-status.store";
import { ComponentsStoreAndListenersService } from "src/app/components/checklists/store/components/components.store";
import { AbsiTemplateVersionStoreAndListenersService } from "src/app/components/absi/store/absi-template/absi-template.store";
import { ActionItemsStoreAndListenersService } from "src/app/controls/catl/action-item/store/actionItems.store";
import { AbsiStoreAndListenersService } from "src/app/components/absi/store/absi/absi.store";
import { DocumentTypeStoreAndListenersService } from "src/app/components/catalogs/document-type/store/document-type/document-type.store";
import { ChecklistsStoreAndListenersService } from "src/app/components/checklists/store/checklists/checklists.store";
import { ResourceTypesStoreAndListenersService } from "src/app/components/catalogs/store/resource-types/resource-types.store";
import { BeamlineShutterStoreAndListenersService } from "src/app/components/catalogs/store/beamline-shutter/beamline-shutter.store";
import { ChecklistTemplatesStoreAndListenersService } from "src/app/components/checklists/store/checklist-templates/checklist-templates.store";
import { ChecklistTemplateStatusesStoreAndListenersService } from "src/app/components/checklists/store/checklist-status/checklist-status.store";
import { RadMetersStoreAndListenersService } from "src/app/components/catalogs/meters-catalog/store/rad-meters/rad-meters.store";
import { AnnotationsStoreAndListenersService } from "src/app/components/checklists/store/annotations/annotations.store";
import { TasksStoreAndListenersService } from "src/app/components/checklists/store/tasks/task.store";
import { ReviewCyclesStoreAndListenersService } from "src/app/components/reviews/store/review-cycles/review-cycles.store";
import { ReviewPermissionsStoreAndListenersService } from "src/app/components/reviews/store/review-permissions/review-permissions.store";
import { ScheduleRemindersStoreAndListenersService } from "src/app/components/schedules/store/schedule-reminder/schedule-reminder.store";
import { ProcedureTemplateStoreAndListenersService } from "src/app/components/procedure/store/procedure-template/procedure-template.store";
import { ProcedureCreditedStoreAndListenersService } from "src/app/components/procedure/store/procedure-credited/procedure-credited.store";
import { ProcedureCategoryStoreAndListenersService } from "src/app/components/procedure/store/procedure-category/procedure-category.store";
import { ProcedureTrainingStoreAndListenersService } from "src/app/components/procedure/store/procedure-training/procedure-training.store";
import { RadLocationTypetoreAndListenersService } from "src/app/components/catalogs/rad-monitor/store/rad-location-type/rad-location-type.store";
import { RadLocationStoreAndListenersService } from "src/app/components/catalogs/rad-monitor/store/rad-location/rad-location.store";
import { RadInterlockStoreAndListenersService } from "src/app/components/catalogs/rad-monitor/store/rad-interlock/rad-interlock.store";
import { RadLocationInterlockRelationStoreAndListenersService } from "src/app/components/catalogs/rad-monitor/store/rad-interlock-relations/rad-interlock-relation.store";
import { RadMonitorStoreAndListenersService } from "src/app/components/catalogs/rad-monitor/store/rad-monitor/rad-monitor.store";
import { PendingResourcesStoreAndListenersService } from "src/app/components/catalogs/store/pending-resources/pending-resources.store";
import { OlogCategoryStoreAndListenersService } from "src/app/components/catalogs/document-type/store/olog-category/olog-category.store";
import { OlogEmailStoreAndListenersService } from "src/app/components/catalogs/document-type/store/olog-email/olog-email.store";
import { AbsiTemplateTypeStoreAndListenersService } from "src/app/components/absi/store/absi-template-type/absi-template-type.store";
import { NavigationLinkStoreAndListenersService } from "src/app/components/catalogs/navigation-links/store/navigation-links.store";
// import { MyPendingCatalogsStoreAndListenersService } from "src/app/components/pending-approvals-v2/store/my-pending-catalogs/my-pending-catalogs.store";
import { PendingCatalogsStoreAndListenersService } from "src/app/components/pending-approvals-v2/store/pending-catalogs/pending-catalogs.store";
import { BratoiStoreAndListenersService } from "src/app/components/catalogs/bratoi/store/bratoi/bratoi.store";
import { ScheduleSubtypesStoreAndListenersService } from "src/app/components/schedules/store/schedule-subtypes/schedule-subtypes.store";
import { ProcedureTypeStoreAndListenersService } from "src/app/components/procedure/store/procedure-types/procedure-types.store";
import { NotificationTemplateStoreAndListenersService } from "src/app/components/catalogs/notifications/store/notification-template.store";
import { AbsiSectionStoreAndListenersService } from "src/app/components/absi/store/absi-sections/absi-section.store";
import { ReviewBoardTypesStoreAndListenersService } from "src/app/components/reviews/store/review-board-types/review-board-types.store";
import { ToxicGasStoreAndListenersService } from "src/app/components/home/toxic-gas-panel/store/toxic-gas/toxic-gas.store";
import { BakeoutsStoreAndListenersService } from "src/app/components/home/bakeouts-panel/store/bakeouts/bakeouts.store";
import { ReviewAttendanceSaveStoreAndListenersService } from "src/app/components/reviews/store/review-attendance-save/review-attendance-save.store";
import { TocaStoreAndListenersService } from "src/app/components/catalogs/bratoi/store/toca/toca.store";
import { ReviewActionItemsStoreAndListenersService } from "src/app/components/reviews/store/review-action-items/review-action-items.store";
import { ReviewActionItemTypesStoreAndListenersService } from "src/app/components/reviews/store/review-action-item-types/review-action-item-types.store";
import { ReviewsStoreAndListenersService } from "src/app/components/reviews/store/reviews/reviews.store";
import { ReviewSatisfactionChecksStoreAndListenersService } from "src/app/components/reviews/store/review-satisfaction-checks/review-satisfaction-checks.store";
import { PVStoreAndListenersService } from "../pv-info/store/pv-info.store";
import { MessageBannerStoreAndListenersService } from "src/app/components/messages/store/message-banner.store";
import { KEVersionStoreAndListenersService } from "src/app/components/kenable/store/ke-version/ke-version.store";
import { AlertStoreAndListenersService } from "../alert/store/alert.store";
import { RadiationAreaStoreAndListenersService } from "src/app/components/home/radiation-area/store/radiation-area.store";
import { DeletedTasksStoreAndListenersService } from "src/app/components/checklists/store/deleted-tasks/deleted-task.store";
import { DeletedStepsStoreAndListenersService } from "src/app/components/checklists/store/deleted-steps/deleted-step.store";
import { DeletedSectionsStoreAndListenersService } from "src/app/components/checklists/store/deleted-sections/deleted-section.store";
import { BeamlineRestrictionStoreAndListenersService } from "src/app/components/catalogs/operational-restrictions/store/beamline-restrictions/beamline-restrictions.store";
import { PlaceholdersStoreAndListenersService } from "../placeholders/store/placeholder.store";
@Injectable({
  providedIn: 'root'
})
export class InitStoreAndListenersService {
  constructor(
    private store: Store,
    private absiSectionStore: AbsiSectionStoreAndListenersService,
    private absiStore: AbsiStoreAndListenersService,
    private absiTemplateTypeStore: AbsiTemplateTypeStoreAndListenersService,
    private absiTemplateVersionStore: AbsiTemplateVersionStoreAndListenersService,
    private acceleratorStatusListener: AcceleratorStatusListener,
    private actionItemStore: ActionItemsStoreAndListenersService,
    private annotationsStore: AnnotationsStoreAndListenersService,
    private aplCategoryStore: APLCategoryStoreAndListenersService,
    private aplGroupStore: AplGroupStoreAndListenersService,
    private bakeoutStore: BakeoutsStoreAndListenersService,
    private beamlineShutters: BeamlineShutterStoreAndListenersService,
    private beamlineStatusStore: BeamlineStatusStoreAndListenersService,
    private branchlineStatusStore: BranchlineStatusStoreAndListenersService,
    private bratoiStore: BratoiStoreAndListenersService,
    private bulletinBoardStore: BulletinBoardStoreAndListenersService,
    private radiationAreaStore: RadiationAreaStoreAndListenersService,
    private checklistTemplateStatusStore: ChecklistTemplateStatusesStoreAndListenersService,
    private checklistsStore: ChecklistsStoreAndListenersService,
    private checklistTemplatesStore: ChecklistTemplatesStoreAndListenersService,
    private commentsStore: CommentStoreAndListenersService,
    private componentsStore: ComponentsStoreAndListenersService,
    private documentTypeStore: DocumentTypeStoreAndListenersService,
    private documentsStore: DocumentsStoreAndListenersService,
    private formStatusStore: FormStatusesStoreAndListenersService,
    private formsStore: FormsStoreAndListenersService,
    private kEnableStore: KEnableStoreAndListenersService,
    private menuItemStore: MenuItemStoreAndListenersService,
    private messageBannerStore: MessageBannerStoreAndListenersService,
    // private myPendingCatalogsStore: MyPendingCatalogsStoreAndListenersService,
    private navigationLinkStore: NavigationLinkStoreAndListenersService,
    private notificationTemplateStore: NotificationTemplateStoreAndListenersService,
    private ologCategoryStore: OlogCategoryStoreAndListenersService,
    private ologEmailStore: OlogEmailStoreAndListenersService,
    private pendingCatalogsStore: PendingCatalogsStoreAndListenersService,
    private pendingResourcesStore: PendingResourcesStoreAndListenersService,
    private privilegeCategoryStore: PrivilegeCategoryStoreAndListenersService,
    private privilegeStore: PrivilegeStoreAndListenersService,
    private procedureCategoryStore: ProcedureCategoryStoreAndListenersService,
    private procedureCreditedStore: ProcedureCreditedStoreAndListenersService,
    private procedureStore: ProcedureStoreAndListenersService,
    private procedureTemplateStore: ProcedureTemplateStoreAndListenersService,
    private procedureTrainingStore: ProcedureTrainingStoreAndListenersService,
    private procedureTypeStore: ProcedureTypeStoreAndListenersService,
    private radInterlockRelationsStore: RadLocationInterlockRelationStoreAndListenersService,
    private radInterlockStore: RadInterlockStoreAndListenersService,
    private radLocationStore: RadLocationStoreAndListenersService,
    private radLocationTypeStore: RadLocationTypetoreAndListenersService,
    private radMetersStore: RadMetersStoreAndListenersService,
    private radMonitorStore: RadMonitorStoreAndListenersService,
    private resourceRelationsStore: ResourceRelationsStoreAndListenersService,
    private resourceTypesStore: ResourceTypesStoreAndListenersService,
    private resourcesStore: ResourcesStoreAndListenersService,
    private restrictionsStore: RestrictionsStoreAndListenersService,
    private reviewActionItemsStore: ReviewActionItemsStoreAndListenersService,
    private reviewActionItemTypesStore: ReviewActionItemTypesStoreAndListenersService,
    private reviewAttendanceSaveStore: ReviewAttendanceSaveStoreAndListenersService,
    private pvStore: PVStoreAndListenersService,
    private reviewBoardTypesStore: ReviewBoardTypesStoreAndListenersService,
    private reviewCyclesStore: ReviewCyclesStoreAndListenersService,
    private reviewPermissionsStore: ReviewPermissionsStoreAndListenersService,
    private reviewSatisfactionCheckStore: ReviewSatisfactionChecksStoreAndListenersService,
    private reviewsStore: ReviewsStoreAndListenersService,
    private rolePrivilegeStore: RolePrivilegeStoreAndListenersService,
    private roleStore: RoleStoreAndListenersService,
    private rsswaStore: RSSWAStoreAndListenersService,
    private scheduleReminderStore: ScheduleRemindersStoreAndListenersService,
    private scheduleStore: SchedulesStoreAndListenersService,
    private scheduleSubtypesStore: ScheduleSubtypesStoreAndListenersService,
    private scheduleTypesStore: ScheduleTypesStoreAndListenersService,
    private scfMastersStore: SCFMastersStoreAndListenersService,
    private signalRService: SignalRService,
    private templateTypesStore: TemplateTypesStoreAndListenersService,
    private tasksStore: TasksStoreAndListenersService,
    private tocaStore: TocaStoreAndListenersService,
    private toxicGasStore: ToxicGasStoreAndListenersService,
    private usersStore: UsersStoreAndListenersService,
    private workerVerificationStore: WorkerVerificationStoreAndListenersService,
    private keVersionStore: KEVersionStoreAndListenersService,
    private alertStore: AlertStoreAndListenersService,
    private deletedTasksStore: DeletedTasksStoreAndListenersService,
    private deletedStepsStore: DeletedStepsStoreAndListenersService,
    private deletedSectionsStore: DeletedSectionsStoreAndListenersService,
    private beamlineRestrictionStore: BeamlineRestrictionStoreAndListenersService,
    private placeholderStore: PlaceholdersStoreAndListenersService
  ) {
    this.checkReconnection();
  }

  checkReconnection() {
    setTimeout(() => {
      if (this.signalRService.reconnected) {
        this.initListeners();
        this.signalRService.reconnected = false;
      }
      this.checkReconnection();
    }, 5000);
  }

  initStore() {
    this.store.dispatch(new SetCurrentUser(localStorage.getItem('Token')));
    this.documentsStore.initStore();
    this.checklistTemplatesStore.initStore(0);

    // Init
    this.menuItemStore.initStore();
    this.usersStore.initStore();
    this.privilegeStore.initStore();
    this.privilegeCategoryStore.initStore();
    this.pendingCatalogsStore.initStore();

    // Home
    this.bulletinBoardStore.initStore();
    this.radiationAreaStore.initStore();
    this.messageBannerStore.initStore();
    this.beamlineStatusStore.initStore();
    this.branchlineStatusStore.initStore();
    this.acceleratorStatusListener.initStore();
    this.toxicGasStore.initStore();
    this.bakeoutStore.initStore();
    this.commentsStore.initStore();
    this.beamlineRestrictionStore.initStore();

    // Catalogs
    this.roleStore.initStore();
    this.rolePrivilegeStore.initStore();
    this.documentTypeStore.initStore();
    this.resourceRelationsStore.initStore();
    this.resourcesStore.initStore();
    this.resourceTypesStore.initStore();
    this.beamlineShutters.initStore();
    this.radLocationStore.initStore();
    this.radLocationTypeStore.initStore();
    this.radInterlockStore.initStore();
    this.radInterlockRelationsStore.initStore();
    this.radMonitorStore.initStore();
    this.radMetersStore.initStore();
    this.navigationLinkStore.initStore();
    this.bratoiStore.initStore();
    this.tocaStore.initStore();
    this.notificationTemplateStore.initStore();
    this.pendingResourcesStore.initStore();
    this.ologCategoryStore.initStore();
    this.ologEmailStore.initStore();

    // Forms
    this.formStatusStore.initStore();
    this.restrictionsStore.initStore();
    this.scfMastersStore.initStore();
    this.rsswaStore.initStore();
    this.workerVerificationStore.initStore();

    // Checklists
    this.componentsStore.initStore();
    this.checklistsStore.initStore();

    this.checklistTemplateStatusStore.initStore();
    this.templateTypesStore.initStore();
    this.kEnableStore.initStore();
    this.keVersionStore.initStore();
    this.absiStore.initStore();
    this.absiSectionStore.initStore();

    this.absiTemplateVersionStore.initStore();
    this.absiTemplateTypeStore.initStore();
    this.annotationsStore.initStore();
    this.actionItemStore.initStore(0);

    this.placeholderStore.initStore();

    // Schedules
    this.scheduleStore.initStore();
    this.scheduleTypesStore.initStore();
    this.scheduleSubtypesStore.initStore();
    this.scheduleReminderStore.initStore();

    // Procedure
    this.procedureStore.initStore();
    this.procedureTemplateStore.initStore();
    this.procedureCreditedStore.initStore();
    this.procedureCategoryStore.initStore()
    this.procedureTrainingStore.initStore();
    this.procedureTypeStore.initStore();

    // Reviews
    this.reviewsStore.initStore();
    this.reviewPermissionsStore.initStore();
    this.reviewCyclesStore.initStore();
    this.reviewBoardTypesStore.initStore();
    this.reviewAttendanceSaveStore.initStore();
    this.reviewActionItemsStore.initStore();
    this.reviewActionItemTypesStore.initStore();
    this.reviewSatisfactionCheckStore.initStore();

    // APL
    this.aplCategoryStore.initStore();
    this.aplGroupStore.initStore();

    // PV
    this.pvStore.initStore();

  }

  initListeners() {
    // Init
    this.documentsStore.initListeners();
    this.menuItemStore.initListeners();
    this.usersStore.initListeners();
    this.privilegeStore.initListeners();
    this.privilegeCategoryStore.initListeners();
    // this.myPendingCatalogsStore.initListeners();
    this.pendingCatalogsStore.initListeners();
    this.alertStore.initListeners();

    // Home
    this.bulletinBoardStore.initListeners();
    this.radiationAreaStore.initListeners();
    this.messageBannerStore.initListeners();
    this.beamlineStatusStore.initListeners();
    this.branchlineStatusStore.initListeners();
    this.messageBannerStore.initListeners();
    this.acceleratorStatusListener.initListeners();
    this.toxicGasStore.initListeners();
    this.bakeoutStore.initListeners();
    this.commentsStore.initListeners();
    this.beamlineRestrictionStore.initListeners();

    // Catalogs
    this.roleStore.initListeners();
    this.rolePrivilegeStore.initListeners();
    this.documentTypeStore.initListeners();
    this.resourceRelationsStore.initListeners();
    this.resourcesStore.initListeners();
    this.resourceTypesStore.initListeners();
    this.beamlineShutters.initListeners();
    this.radLocationStore.initListeners();
    this.radLocationTypeStore.initListeners();
    this.radInterlockStore.initListeners();
    this.radInterlockRelationsStore.initListeners();
    this.radMonitorStore.initListeners();
    this.radMetersStore.initListeners();
    this.navigationLinkStore.initListeners();
    this.bratoiStore.initListeners();
    this.tocaStore.initListeners();
    this.notificationTemplateStore.initListeners();
    this.pendingResourcesStore.initListeners();
    this.ologCategoryStore.initListeners();
    this.ologEmailStore.initListeners();

    // Forms
    this.formsStore.initListeners();
    this.formStatusStore.initListeners();
    this.restrictionsStore.initListeners();
    this.scfMastersStore.initListeners();
    this.rsswaStore.initListeners();
    this.workerVerificationStore.initListeners();

    // Checklists
    this.componentsStore.initListeners();
    this.checklistsStore.initListeners();
    this.kEnableStore.initListeners();
    this.keVersionStore.initListeners();
    this.absiStore.initListeners();
    this.absiSectionStore.initListeners();
    this.annotationsStore.initListeners();
    this.actionItemStore.initListeners();
    this.tasksStore.initListeners();

    this.placeholderStore.initListeners();

    // Checklist Builder
    this.absiTemplateVersionStore.initListeners();
    this.absiTemplateTypeStore.initListeners();
    this.checklistTemplatesStore.initListeners();
    this.checklistTemplateStatusStore.initListeners();
    this.templateTypesStore.initListeners();
    this.deletedTasksStore.initListeners();
    this.deletedStepsStore.initListeners();
    this.deletedSectionsStore.initListeners();

    // Schedules
    this.scheduleStore.initListeners();
    this.scheduleTypesStore.initListeners();
    this.scheduleSubtypesStore.initListeners();
    this.scheduleReminderStore.initListeners();

    // Procedure
    this.procedureStore.initListeners();
    this.procedureTemplateStore.initListeners();
    this.procedureCreditedStore.initListeners();
    this.procedureCategoryStore.initListeners();
    this.procedureTrainingStore.initListeners();
    this.procedureTypeStore.initListeners();

    // Reviews
    this.reviewsStore.initListeners();
    this.reviewPermissionsStore.initListeners();
    this.reviewCyclesStore.initListeners();
    this.reviewBoardTypesStore.initListeners();
    this.reviewAttendanceSaveStore.initListeners();
    this.reviewActionItemsStore.initListeners();
    this.reviewActionItemTypesStore.initListeners();
    this.reviewSatisfactionCheckStore.initListeners();

    // APL
    this.aplCategoryStore.initListeners();
    this.aplGroupStore.initListeners();

    // PV
    this.pvStore.initListeners();

  }

  closeListener(channel: string) {
    this.signalRService.hubConnection?.off(channel);
  }
}
