import { ReviewBoard } from "../../reviews.models";

export class ReviewBoardsRefresh {
  static readonly type = '[ReviewBoards] Refresh';
}

export class ReviewBoardsCreate {
  static readonly type = '[ReviewBoards] Create';

  constructor(public item: ReviewBoard) { }
}

export class ReviewBoardsUpdate {
  static readonly type = '[ReviewBoards] Update';

  constructor(public id: number, public item: ReviewBoard) { }
}

export class ReviewBoardsDelete {
  static readonly type = '[ReviewBoards] Delete';
  constructor(public id: number) { }
}

export class ReviewBoardsRefreshByID {
  static readonly type = '[ReviewBoards] RefreshByID';
  constructor(public id: number) { }
}
