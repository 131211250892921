<div class="card">
  <button
    mat-mini-fab
    class="closex"
    color="warn"
    *ngIf="!loading"
    (click)="close()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div class="card-header">
    <span class="card-title">{{ title }}</span>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="card-body">
    <div
      class="row"
      *ngIf="
        data?.aplCategory?.previousVersion && data?.aplCategory?.status == -1
      "
    >
      <div class="col"></div>
      <div class="col-auto">
        <mat-slide-toggle color="accent" [(ngModel)]="showPrevious">{{
          labelShowPrevious
        }}</mat-slide-toggle>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
        <small
          >Submitted by: <b>{{ submitted?.name }}</b></small
        >
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label [innerHTML]="labelName"></mat-label>
          <input
            matInput
            name="name"
            [disabled]="true"
            [ngModel]="
              showPrevious
                ? data.aplCategory.previousVersion.name
                : data.aplCategory.name
            "
            (ngModelChange)="
              showPrevious
                ? (data.aplCategory.previousVersion.name = $event)
                : (data.aplCategory.name = $event)
            "
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label [innerHTML]="labelInitials"></mat-label>
          <input
            matInput
            name="initials"
            [disabled]="true"
            [ngModel]="
              showPrevious
                ? data.aplCategory.previousVersion.code
                : data.aplCategory.code
            "
            (ngModelChange)="
              showPrevious
                ? (data.aplCategory.previousVersion.code = $event)
                : (data.aplCategory.code = $event)
            "
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <user-chip
          [placeholder]="labelManagers"
          [disabled]="true"
          [relatedID]="
            showPrevious
              ? data.aplCategory.previousVersion.id
              : data.aplCategory.id
          "
          [relatedTag]="'aplCategoryID'"
          [selectedUsers]="
            showPrevious
              ? data.aplCategory.previousVersion.aplCategoryManagers
              : data.aplCategory.aplCategoryManagers
          "
          (error)="alert.error($event)"
        ></user-chip>
      </div>
    </div>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        color="warn"
        type="button"
        [disabled]="loading || disableApproveDisapprove"
        (click)="disapprove()"
        [innerHTML]="labelDisapprove"
      ></button>
      <button
        mat-flat-button
        color="primary"
        type="button"
        [disabled]="loading || disableApproveDisapprove"
        (click)="approve()"
        [innerHTML]="labelApprove"
      ></button>
      <button
        mat-flat-button
        color="accent"
        type="button"
        [disabled]="loading"
        (click)="unSubmit()"
        *ngIf="submitted?.id == currentUser?.id"
      >
        Unsubmit
      </button>
    </div>
  </div>
</div>
