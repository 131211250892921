<div class="mat-container left-table-container scrollbar mt-2">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="beamlineName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Beamline</th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.statusClass">
        <span
          class="bold"
          [innerHTML]="element.beamlineName | highlightSearch : filter"
        ></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="shutterName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Shutter</th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.statusClass">
        <span
          [innerHTML]="element.shutterName | highlightSearch : filter"
        ></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="beamlineStatus">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.statusClass">
        <span
          [innerHTML]="element.beamlineStatus | highlightSearch : filter"
        ></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="ketStatus">
      <th mat-header-cell *matHeaderCellDef>
        <div style="width: 100%; text-align: center">KET Version</div>

        <div
          style="display: flex; justify-content: space-between"
          class="small"
        >
          <div style="width: 30%">Full</div>
          <div style="width: 30%; text-align: center">Abbr</div>
          <div style="width: 40%; text-align: right">Global</div>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.statusClass">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <div [ngClass]="getClass(element, 1)" style="width: 20px"></div>
          <div [ngClass]="getClass(element, 0)" style="width: 20px"></div>
          <div style="width: 30px; text-align: right">
            <mat-icon
              color="accent"
              *ngIf="element.ketStatus?.templateStatus == 2"
              >history</mat-icon
            >
            <mat-icon
              style="color: #74aa50"
              *ngIf="element.ketStatus?.templateStatus == 1"
              >check_circle</mat-icon
            >
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      #matrow
      *matRowDef="let row; columns: displayedColumns"
      class="table-row"
      [ngClass]="{
        'mat-row-highlight': selectedRowIndex == row.beamlineId
      }"
      [attr.position]="getPosition(row)"
      (click)="selectRow(row)"
    ></tr>
  </table>
</div>
