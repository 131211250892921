import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Location } from '../../beamline-catalog/resource/resources';
import { NotificationMessage } from './notification.models';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private http: HttpClient) { }

  getNotification(type: number, docId: number, locations?: Location[], code?: string): Observable<NotificationMessage | undefined> {
    const values = { type: parseInt(type.toString(), 10), documentId: parseInt(docId.toString(), 10), locations, code };
    const result = this.http.patch<NotificationMessage>(environment.urls.baseApiUrl + '/NotificationMessages', values);
    return result;
  }

  createNotification(notification: NotificationMessage): Observable<NotificationMessage> {
    const result = this.http.post<NotificationMessage>(environment.urls.baseApiUrl + '/NotificationMessages', notification);
    return result;
  }

  saveNotification(notification: NotificationMessage): Observable<NotificationMessage> {
    const id = notification.id;
    const result = this.http.put<NotificationMessage>(environment.urls.baseApiUrl + '/NotificationMessages/' + id, notification);
    return result;
  }

  sendNotification(notificationMessage: NotificationMessage): Observable<NotificationMessage> {
    const result = this.http.post<NotificationMessage>(environment.urls.baseApiUrl + '/NotificationMessages/' + notificationMessage.id, notificationMessage);
    return result;
  }

}
