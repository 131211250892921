<div class="row" [formGroup]="formGroup">
  <div class="col-12">
    <role-selection
      [roles]="rolePermissions"
      [noSubscript]="true"
      (selected)="selectedRole($event)"
      (deleted)="removeRole($event)"
    ></role-selection>
  </div>
</div>
