import { Component, OnInit, Output, EventEmitter, OnDestroy, Input, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { SCFMaster } from '../../components/scf-v2/scf';
import { WorkerVerification } from 'src/app/services/worker-verification/worker-verification';
import { WorkerVerificationService } from 'src/app/services/worker-verification/worker-verification.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Document } from 'src/app/services/documents/documents';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { RSSWAResource } from 'src/app/components/rsswa/rsswa';
import { WorkerVerificationRefresh } from './store/worker-verification/worker-verification.action';

@Component({
  selector: 'worker-verification-table',
  templateUrl: './worker-verification.component.html',
  styleUrls: ['./worker-verification.component.scss']
})

export class WorkerVerificationComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() currentDocument?: SCFMaster | RSSWAResource | Document | null;
  @Input() disabled?: boolean;
  @Output() on_refresh_error = new EventEmitter<any>(false);

  signed: boolean = false;
  disableButtons?: boolean = true;

  dataSource = new MatTableDataSource<WorkerVerification>();
  displayed_columns = ['user', 'date', 'action'];
  workerVerifications?: WorkerVerification[];
  workerVerifications$!: Observable<WorkerVerification[]>;
  workerVerificationsSubs!: Subscription;

  constructor(
    public service: WorkerVerificationService,
    protected override injector: Injector
  ) {
    super(injector);
    this.store.dispatch(new WorkerVerificationRefresh());
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadWorkerVerifications();
    this.checkDisabled();
  }

  override ngOnDestroy(): void {
    this.workerVerificationsSubs?.unsubscribe();
  }

  ngOnInit() {
    this.loadWorkerVerifications();
  }

  checkDisabled() {
    if (this.currentDocument)
      this.disableButtons = !(
        (this.currentDocument?.status === FormStatusEnum.Active ||
          this.currentDocument?.status === FormStatusEnum.HoldPoint ||
          this.currentDocument?.status === FormStatusEnum.ActiveBypassInstalled ||
          this.currentDocument?.status === FormStatusEnum.ActiveOperationsVerified ||
          this.currentDocument?.status === FormStatusEnum.ActiveBypassInstalled ||
          this.currentDocument?.status === FormStatusEnum.ActiveMitigationsInPlace ||
          this.currentDocument?.status === FormStatusEnum.Amendment ||
          this.currentDocument?.status === FormStatusEnum.RestrictionsAmendment ||
          this.currentDocument?.status === FormStatusEnum.AmendmentSign)
      );
  }

  loadWorkerVerifications() {
    this.workerVerifications$ = this.store.select(state => state.WorkerVerification.data);
    this.workerVerificationsSubs = this.workerVerifications$.subscribe(data => {
      this.workerVerifications = data.filter(x => x.form == this.currentDocument?.type && x.formID == this.currentDocument?.id);
      this.signed = this.workerVerifications.some(w => w.userID == this.getCurrentUser()?.id);
      this.dataSource = new MatTableDataSource(this.workerVerifications);
    });
  }

  refresh(form: number, formID: number) {
    this.dataSource = new MatTableDataSource(this.workerVerifications?.filter(x => x.form == form && x.formID == formID));
  }

  clean() {
    this.dataSource.data = [];
  }

  addSignature() {
    let worker_verification: WorkerVerification = {
      form: this.currentDocument?.type,
      formID: this.currentDocument?.id,
      userID: this.getCurrentUser()?.id,
      date: new Date(),
    };

    const message = worker_verification.form == 1 ? "WorkerVerification_SignatureApprovement" : "WorkerVerification_SignatureApprovement_NonSCF";

    const confirmApprovement = this.dialog.open(YesNoDialogComponent, {
      width: "500px",
      data: {
        message: this.getMessage(message).description,
        icon: "warn",
      },
    });

    confirmApprovement
      .afterClosed()
      .toPromise()
      .then(async (data) => {
        if (data) {
          this.service
            .create(worker_verification)
            .toPromise()
            .then(() => {
              this.store.dispatch(new WorkerVerificationRefresh());
            }
              // () => {
              //   this.signed = null;
              //   this.on_sign.emit();
              // },
              // (error) => this.on_error.emit(error.error)
            );
        } else {
        }
      });
  }

  remove() {
    if (this.currentDocument?.id && this.currentDocument.type)
      this.service
        .deleteByFormUser(this.currentDocument.type, this.currentDocument.id, this.getCurrentUser()?.id ?? 0)
        .toPromise()
        .then(() => {
          this.store.dispatch(new WorkerVerificationRefresh());
        }
          // () => {
          //   this.signed = null;
          //   this.on_sign.emit();
          // },
          // (error) => this.on_error.emit(error.error)
        );
  }
}
