<mat-progress-bar
  class="progress-bar"
  mode="indeterminate"
  *ngIf="loading || saving"
></mat-progress-bar>
<div class="privileges scrollbar p-1 pt-2">
  <div class="row" *ngFor="let radMonitorPrivilege of radMonitorPrivileges">
    <div class="col-5">{{ radMonitorPrivilege.name }}</div>
    <div class="col-7">
      <mat-form-field>
        <mat-label>Roles</mat-label>
        <mat-chip-grid #chipList>
          <mat-chip-row
            *ngFor="let rolePrivilege of radMonitorPrivilege.rolePrivileges"
            [removable]="removable"
            (removed)="remove(rolePrivilege, radMonitorPrivilege.privilegeId)"
          >
            {{ rolePrivilege?.role?.code }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip-row>
          <input
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="clearInputValue($event)"
            (keyup)="applyFilter($event)"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="
            selected($event.option.value, radMonitorPrivilege.privilegeId)
          "
        >
          <mat-option *ngFor="let role of rolesFiltered" [value]="role">
            {{ role.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</div>
