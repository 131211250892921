import { Role } from 'src/app/components/catalogs/roles/services/role';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { TemplateType, ChecklistTemplate, Checklist } from '../checklists/checklists';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { ReviewCycleStatus } from 'src/app/common/enumerations/enumerations';
import { RolePrivilege } from 'src/app/components/catalogs/roles/services/role-privilege';
import { Resource, ResourceType } from 'src/app/components/catalogs/beamline-catalog/resource/resources';

interface ReviewsModelBase {
  id: number;
  name?: string | null;
  description?: string | null;
  status?: number;
  type?: number | null;
  code?: string | null;
  createdOn?: Date;
  createdBy?: number;
  updatedOn?: Date | null;
  updatedBy?: number | null;
}

export interface ReviewDocumentCategory extends ReviewsModelBase {
}

export interface ReviewDocuments extends ReviewsModelBase {
  reviewID?: number;
  reviewCategoryID?: number;
  fileID?: number;
  url?: string;
  fileIcon?: string;
  createdByName?: string;
}

export interface ReviewBoardType extends ReviewsModelBase {
  resourceTypeID: number;
  resourceType: ResourceType;
  reviewBoards: ReviewBoard[];
  reviewTypes: ReviewType[];
  reviewStatuses: ReviewStatus[];
  allowCustomResources: boolean;
}

export interface ReviewType extends ReviewsModelBase {
  order: number;
  reviewBoardTypeID: number;
  reviewBoardType: ReviewBoardType;
  templateTypeID?: number;
  templateType: TemplateType;
  nextReviewTypeID?: number;
  nextReviewType?: ReviewType;
  lifeSpan?: number;
}

export interface ReviewStatus extends ReviewsModelBase {
  order: number;
  color: string;
  reviews: Review[];
  reviewBoardTypeID: number;
  reviewBoardType: ReviewBoardType;
  reviewStatusActions: ReviewStatusAction[];
}

export interface ReviewStatusAction extends ReviewsModelBase {
  reviewStatusID: number;
  reviewStatus?: ReviewStatus;
  color: string | null;
  nextReviewStatusID: number;
  nextReviewStatus?: ReviewStatus;
  reviewActionRoles: ReviewActionRole[];
}

export interface ReviewActionRole extends ReviewsModelBase {
  reviewStatusActionID: number;
  reviewStatusAction: ReviewStatusAction;
  roleID: number;
  role: Role;
}

export interface ReviewBoard extends ReviewsModelBase {
  resourceID: number;
  resource?: Resource;
  reviewBoardTypeID: number;
  reviewBoardType?: ReviewBoardType;
  reviewCycles?: ReviewCycle[];
}

export interface ReviewCycle extends ReviewsModelBase {
  location?: string | null;
  proposedTimeline: string;
  reviewBoardID: number;
  reviewBoard: ReviewBoard;
  resourceID?: number | null;
  resource?: Resource;
  reviews: Review[];
  serialNo: string;
  createdByUser?: User;
  reviewRequestStatus?: ReviewRequestStatus;
}

export interface Review extends ReviewsModelBase {
  reviewCycleID: number;
  reviewCycle: ReviewCycle;
  reviewTypeID: number;
  reviewType: ReviewType;
  reviewDate: Date;
  reviewStatusID: number;
  reviewStatus: ReviewStatus;
  checklistTemplateID?: number;
  checklistTemplate: ChecklistTemplate;
  checklistID?: number;
  checklist: Checklist;
  reviewActionItems: ReviewActionItem[];
  previousReviewID?: number;
  previousReview?: Review;
  reviewAttendanceRosters: ReviewAttendanceRoster[];
  reviewExtensions?: ReviewExtension[];
  comments?: string;
}

export interface SaveReview extends ReviewsModelBase {
  reviewCycleID?: number;
  reviewTypeID?: number;
  reviewDate?: Date;
  reviewStatusID?: number;
  checklistTemplateID?: number | null;
  checklistID?: number | null;
  comments?: string;
}

export interface ReviewActionItem extends ReviewsModelBase {
  reviewID: number;
  review: Review;
  responsibleID?: number;
  responsible?: User;
  reviewActionItemTypeID?: number;
  reviewActionItemType?: ReviewActionItemType;
  startDate?: Date;
  endDate?: Date;
  wfTaskLocalID?: number | null;
  wfTaskLocal?: WfTaskLocalResource;
  closingComments?: string;
  closedByID?: number | null;
  closedOn?: Date | null;
  closedBy?: User;
  isCATL?: boolean;
  emailSent?: boolean;
}

export interface ReviewActionItemType extends ReviewsModelBase {
  order: number;
  isStopper: boolean;
  reviewActionItems: ReviewActionItem[];
}

export interface ReviewAttendanceRoster extends ReviewsModelBase {
  reviewID: number;
  userID: number;
  roleID: number;
  categoryID: number;
  attended?: boolean;
  accepted?: boolean;
  acceptedBy?: number;
  acceptedOn?: Date;
  review?: Review;
  user?: User;
  acceptedByUser?: User;
  role?: Role;
  category?: ReviewAttendanceCategory;
}

export interface ReviewAttendanceCategory extends ReviewsModelBase {
  reviewAttendanceRosters?: ReviewAttendanceRoster[];
  preName: string;
  editing: boolean;
  order?: number;
}

export interface ReviewSatisfactionCheck extends ReviewsModelBase {
  checklistID: number;
  checklist: Checklist;
  wfTaskLocalID: number;
  wfTaskLocal: WfTaskLocalResource;
  preCheck?: Satisfaction | null;
  preCheckByID?: number | null;
  preCheckBy?: User;
  preCheckOn?: Date | null;
  satisfaction?: Satisfaction | null;
  satisfactionCheckByID?: number | null;
  satisfactionCheckBy?: User | null;
  satisfactionCheckOn?: Date | null;
  comments?: string;
}

export interface ReviewPermission extends ReviewsModelBase {
  privilege: PrivilegeEnum;
  reviewBoardTypeID: number;
  reviewBoardType: ReviewBoardType;
  hasStatusPermissions: boolean;
  rolePrivileges: RolePrivilege[];
  reviewStatusPermissions: ReviewStatusPermission[];
}

export interface ReviewStatusPermission extends ReviewsModelBase {
  privilege: PrivilegeEnum;
  reviewStatusID: number;
  reviewPermissionID: number;
  reviewStatus?: ReviewStatus;
  reviewPermission?: ReviewPermission;
}

export interface ReviewAttendanceSave extends ReviewsModelBase {
  reviewAttendanceSavedList: ReviewAttendanceSaveList[];
  replace: boolean;
}

export interface ReviewAttendanceSaveSave extends ReviewsModelBase {
  reviewAttendanceSavedList: ReviewAttendanceSaveListSave[];
}

export interface ReviewAttendanceSaveListSave extends ReviewsModelBase {
  reviewAttendanceSaveID: number;
  userID: number;
  roleID: number;
  categoryID: number;
}

export interface ReviewAttendanceSaveList extends ReviewsModelBase {
  reviewAttendanceSaveID: number;
  userID: number;
  roleID: number;
  categoryID: number;
  user?: User | null;
  role?: Role | null;
  category?: ReviewAttendanceCategory;
  reviewAttendanceSave: ReviewAttendanceSave;
}

export interface ReviewExtension extends ReviewsModelBase {
  reviewID: number;
  review: Review;
  extensionRequested: Date;
  extensionRequestedByID: number;
  extensionRequestedBy: User;
  extensionGranted?: Date;
  extensionGrantedByID?: number;
  extensionGrantedBy?: User;
  extensionRejected?: Date;
  extensionRejectedByID?: number;
  extensionRejectedBy?: User;
  comments?: string;
}

export class ReviewRequestStatus {
  status!: ReviewCycleStatus;
  name!: string;
  icon!: string;
  color!: string;
  selected?: boolean;
}

export enum ReviewTypesEnum {
  FULL = 1,
  MODF = 2
}

export enum Satisfaction {
  Unsatisfied = -1,
  Indeterminate = 0,
  Satisfied = 1
}

export enum ReviewStatusEnum {
  Draft = 1,
  InProgress = 2,
  Completed = 3,
  Canceled = 4,
  Expired = 5,
  Pending = 6
}
