import { Injectable } from "@angular/core";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { APLCategoryCreate, APLCategoryRefresh, APLCategoryRefreshByID, APLCategoryUpdate } from "./apl-category.action";
import { APLCategory } from "src/app/components/apl/services/apl-category.model";
import { APLCategoryService } from "../../apl-category.service";

export class APLCategoryStateModel {
  APLCategories: APLCategory[] = [];
  APLCategoriesWithPending: APLCategory[] = [];
}

@State<APLCategoryStateModel>({
  name: 'APLCategory',
  defaults: {
    APLCategories: [],
    APLCategoriesWithPending: [],
  },
})
@Injectable()
export class APLCategoryState {
  constructor(
    private store: Store,
    private aplCategoryService: APLCategoryService,
  ) { }

  @Action(APLCategoryRefresh)
  refresh({ patchState }: StateContext<APLCategoryStateModel>) {
    this.aplCategoryService.read().subscribe(
      data => patchState({ APLCategories: data }),
    );
    this.aplCategoryService.readWithPending().subscribe(
      data => patchState({ APLCategoriesWithPending: data }),
    );
  }

  @Action(APLCategoryCreate)
  create({ getState }: StateContext<APLCategoryStateModel>, { item }: APLCategoryCreate) {
    if (getState().APLCategories && getState().APLCategoriesWithPending) {
      this.store.dispatch(APLCategoryRefresh);
    }
  }

  @Action(APLCategoryUpdate)
  update({ getState, patchState }: StateContext<APLCategoryStateModel>, { id, item }: APLCategoryUpdate) {
    const state = getState();
    const index = state.APLCategories.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.APLCategories.splice(index, 1);
      patchState({
        APLCategories: [...state.APLCategories, item]
      });
    }
  }

}
