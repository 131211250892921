import { User } from "../services/user";

export class UsersRefresh {
  static readonly type = '[Users] Refresh';
}

export class UsersCreate {
  static readonly type = '[Users] Create';

  constructor(public item: User) { }
}

export class UsersUpdate {
  static readonly type = '[Users] Update';

  constructor(public id: number, public item: User) { }
}

export class UsersDelete {
  static readonly type = '[Users] Delete';
  constructor(public id: number) { }
}
