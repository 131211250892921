<fieldset class="field-group">
  <legend>{{ placeholder }}</legend>
  <span class="placeholder" *ngIf="!relatedLinksDocuments.length">{{
    placeholder
  }}</span>
  <mat-chip-set #relatedLinkDocumentChips>
    <mat-chip
      *ngFor="let r of relatedLinksDocuments"
      [selectable]="true"
      [removable]="true"
      (removed)="remove(r)"
      [matTooltip]="r.text ?? ''"
    >
      <div class="overflow-ellipsis" *ngIf="!r.file && !r.url">
        {{ r.text }}
      </div>
      <div class="overflow-ellipsis" *ngIf="!r.file && r.url">
        <a href="{{ r.url }}" target="_blank">{{ r.text }}</a>
      </div>
      <div class="overflow-ellipsis" *ngIf="r.file">
        <a href="{{ r.file.url }}" target="_blank">{{ r.text }}</a>
      </div>
      <mat-icon *ngIf="!disabled" class="pl-1 edit-icon" (click)="edit(r)"
        >create</mat-icon
      >
      <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
    </mat-chip>
  </mat-chip-set>
  <button
    class="small-icon-button"
    mat-icon-button
    (click)="open()"
    color="accent"
    matTooltip="Add {{ placeholder }}"
  >
    <mat-icon>add_box</mat-icon>
  </button>
</fieldset>
