<mat-form-field appearance="outline" class="role-list no-subscript">
  <mat-label>Roles</mat-label>
  <mat-chip-grid #chipListRole aria-label="Role selection">
    <mat-chip-row
      class="role-chip"
      *ngFor="let role of roles"
      [selectable]="!disabled"
      [removable]="!disabled"
      (removed)="removeRole(role)"
    >
      {{ role.code }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>
    <input
      [disabled]="disabled"
      aria-label="roles"
      #roleInput
      class="roles-input"
      [matAutocomplete]="autoRole"
      [matChipInputFor]="chipListRole"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [(ngModel)]="role"
      [matChipInputAddOnBlur]="true"
      (keyup)="onKeyUp($event)"
      (matChipInputTokenEnd)="clearInputValue($event)"
      #trigger="matAutocompleteTrigger"
    />
  </mat-chip-grid>
  <mat-autocomplete
    #autoRole="matAutocomplete"
    (optionSelected)="selectedRole($event)"
  >
    <mat-option
      class="role-option"
      *ngFor="let role of rolesFiltered"
      [value]="role"
      matTooltip="{{ role.name }}"
    >
      <div class="row no-gutters full-width">
        <div class="col-3 d-flex justify-content-end">
          <mat-chip class="role-chip">{{ role.code }} </mat-chip>
        </div>
        <div class="col-9 small nowrap overflow-ellipsis pl-1">
          {{ role.name }}
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
  <mat-error>You must choose at least one Role</mat-error>
</mat-form-field>
