<div class="row">
  <div class="col">
    <mat-slide-toggle
      class="float-right"
      color="warn"
      [(ngModel)]="showInactive"
      (change)="showInactiveNotificationTemplates($event)"
      >Show Inactive</mat-slide-toggle
    >
  </div>
</div>
<div class="mat-container">
  <mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="created"
    matSortDisableClear
    matSortDirection="desc"
  >
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{
          yellow: element.color == 1,
          blue: element.color == 0
        }"
        ><span [innerHTML]="element.name | highlightSearch : filter"></span>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      #matrow
      *matRowDef="let row; columns: displayedColumns"
      class="table-row"
      [ngClass]="{ 'mat-row-highlight': selectedRowIndex == row.id }"
      (click)="highlight(row)"
    ></mat-row>
  </mat-table>
</div>
