import { AbsiTemplateVersionResource } from "../../absi";

export class AbsiTemplateVersionRefresh {
  static readonly type = '[AbsiTemplateVersion] Refresh';
  constructor(public id: number) { }
}
export class AbsiTemplateVersionRefreshByID {
  static readonly type = '[AbsiTemplateVersion] RefreshByID';
  constructor(public id: number) { }
}
export class AbsiTemplateVersionCreate {
  static readonly type = '[AbsiTemplateVersion] Create';
  constructor(public item: AbsiTemplateVersionResource) { }
}
export class AbsiTemplateVersionUpdate {
  static readonly type = '[AbsiTemplateVersion] Update';
  constructor(public id: number, public item: AbsiTemplateVersionResource) { }
}
export class AbsiTemplateVersionDelete {
  static readonly type = '[AbsiTemplateVersion] Delete';
  constructor(public id: number) { }
}
