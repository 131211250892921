<div class="container-fluid">
  <div class="card-body">
    <form [formGroup]="formGroup">
      <div class="row mt-3">
        <div class="col-12 col-sm-8">
          <div class="row">
            <div class="col">
              <mat-form-field
                [ngClass]="{
                  hilite: isModified('name')
                }"
              >
                <mat-label>Name</mat-label>
                <input
                  matInput
                  formControlName="nameCtrl"
                  name="fullName"
                  (keyup)="compareName($event, 1)"
                />
                <mat-error *ngIf="nameCtrl?.invalid">{{
                  getErrorMsg(nameCtrl)
                }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field
                [ngClass]="{
                  hilite: isModified('username')
                }"
              >
                <mat-label>Username</mat-label>
                <input
                  matInput
                  formControlName="userCtrl"
                  name="username"
                  (keyup)="compareName($event, 2)"
                />
                <mat-error *ngIf="userCtrl?.invalid">{{
                  getErrorMsg(userCtrl)
                }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field
                [ngClass]="{
                  hilite: isModified('email')
                }"
              >
                <mat-label>Email Address</mat-label>
                <input
                  matInput
                  formControlName="emailCtrl"
                  name="emailAddress"
                  (keyup)="compareName($event, 3)"
                />
                <mat-error *ngIf="emailCtrl?.invalid">{{
                  getErrorMsg(emailCtrl)
                }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <mat-form-field
                [ngClass]="{
                  hilite: isModified('phone')
                }"
              >
                <mat-label>Phone Ext.</mat-label>
                <input
                  matInput
                  (keypress)="numericOnly($event)"
                  formControlName="phoneCtrl"
                  name="phoneExt"
                />
              </mat-form-field>
            </div>
            <div class="col-8">
              <mat-form-field
                [ngClass]="{
                  hilite: isModified('mobile')
                }"
              >
                <mat-label>Mobile</mat-label>
                <input
                  matInput
                  (keypress)="numericOnly($event)"
                  formControlName="mobileCtrl"
                  name="mobile"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-form-field
                [ngClass]="{
                  hilite: isModified('initials')
                }"
              >
                <mat-label>Initials</mat-label>
                <input
                  matInput
                  formControlName="initialsCtrl"
                  name="initials"
                />
                <mat-error *ngIf="initialsCtrl?.invalid">{{
                  getErrorMsg(initialsCtrl)
                }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field
                [ngClass]="{
                  hilite: isModified('status')
                }"
              >
                <mat-label>Status</mat-label>
                <mat-select formControlName="statusCtrl" name="status">
                  <mat-option
                    *ngFor="let status of statusList"
                    [value]="status.id"
                  >
                    {{ status.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field
                [ngClass]="{
                  hilite: isModified('organization')
                }"
              >
                <mat-label>Organization</mat-label>
                <mat-select
                  formControlName="organizationCtrl"
                  name="organization"
                >
                  <mat-option
                    *ngFor="let organization of organizations"
                    [value]="organization.id"
                  >
                    {{ organization.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field
                [ngClass]="{
                  hilite: isModified('supervisor')
                }"
              >
                <mat-label>Supervisor</mat-label>
                <mat-select formControlName="supervisorCtrl" name="supervisor">
                  <mat-option
                    *ngFor="let supervisor of supervisors"
                    [value]="supervisor.id"
                  >
                    {{ supervisor.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field
                [ngClass]="{
                  hilite: isModified('roles'),
                  'mat-form-field-invalid': !roles.length && rolesCtrl?.touched
                }"
              >
                <mat-label>Roles</mat-label>
                <mat-chip-grid
                  #chipListRole
                  aria-label="Role selection"
                  [disabled]="disabled"
                >
                  <mat-chip-row
                    class="role-chip"
                    *ngFor="let role of roles"
                    [selectable]="!disabled"
                    [removable]="!disabled"
                    (removed)="removeRole(role)"
                    [matTooltip]="role.name ?? ''"
                  >
                    {{ !showRoleNames ? role.code : role.name }}
                    <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
                  </mat-chip-row>
                  <input
                    #roleInput
                    [matAutocomplete]="autoRole"
                    [matChipInputFor]="chipListRole"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="true"
                    (keyup)="changeRole($event)"
                    (matChipInputTokenEnd)="clearInputValue($event)"
                    #trigger="matAutocompleteTrigger"
                    formControlName="rolesCtrl"
                  />
                </mat-chip-grid>
                <mat-autocomplete
                  #autoRole="matAutocomplete"
                  (optionSelected)="selectedRole($event)"
                >
                  <mat-option
                    class="role-option"
                    *ngFor="let role of rolesFiltered"
                    [value]="role"
                    matTooltip="{{ role.name }}"
                  >
                    <div class="row no-gutters full-width">
                      <div class="col-3 d-flex justify-content-end">
                        <mat-chip class="role-chip">{{ role.code }} </mat-chip>
                      </div>
                      <div class="col-9 small nowrap overflow-ellipsis pl-1">
                        {{ role.name }}
                      </div>
                    </div>
                  </mat-option>
                </mat-autocomplete>
                <mat-hint *ngIf="!roles.length && !disabled">{{
                  getErrorMsg(rolesCtrl, "rolesCtrl")
                }}</mat-hint>
                <mat-error *ngIf="rolesCtrl?.invalid">{{
                  getErrorMsg(rolesCtrl, "rolesCtrl")
                }}</mat-error>
              </mat-form-field>

              <mat-slide-toggle
                class="mt-2"
                name="showRoleNames"
                (change)="showRoleNames = !showRoleNames"
                color="primary"
                [disabled]="!userCatalog?.id"
                >Show role names</mat-slide-toggle
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="row">
            <div class="col">
              <mat-label class="label">Is Supervisor?</mat-label>
              <mat-slide-toggle
                [ngClass]="{
                  hilite: isModified('sup')
                }"
                name="isManager"
                formControlName="issupCtrl"
              ></mat-slide-toggle>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="mat-container"
                *ngIf="userCatalog?.details?.user?.isManager"
              >
                <mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="supervisor">
                    <mat-header-cell *matHeaderCellDef>
                      Supervisees
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.name }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="sidenav-content-buttons">
  <div class="row no-gutters">
    <div class="col-6">
      <button
        mat-flat-button
        color="warn"
        *ngIf="canCancel || formGroup.dirty || isEditing || isCreating"
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
    <div class="col-6">
      <button
        mat-flat-button
        color="accent"
        class="right-btn"
        *ngIf="canModify && !isCreating && !isEditing"
        [disabled]="!canModify || isEditing"
        (click)="modifyUser()"
      >
        Modify
      </button>
      <button
        mat-flat-button
        color="primary"
        class="right-btn"
        *ngIf="isEditing || isCreating"
        [disabled]="!isValid()"
        (click)="save()"
      >
        Save
      </button>
      <button
        mat-flat-button
        color="primary"
        class="right-btn"
        *ngIf="canApprove && !isCreating && !isEditing"
        (click)="approve()"
      >
        Approve
      </button>
      <button
        mat-flat-button
        color="warn"
        class="right-btn"
        *ngIf="canApprove && !isCreating && !isEditing"
        (click)="openAddDisapprovalDialog()"
      >
        Disapprove
      </button>
    </div>
  </div>
</div>
