import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TemplateType } from '../../checklists';
import { TemplateTypesCreate, TemplateTypesDelete, TemplateTypesRefresh, TemplateTypesUpdate, TemplateTypesLoad, TemplateTypesRefreshByDocument } from './template-types.action';
import { ChecklistBuilderService } from '../../checklist-builder/checklist-builder.service';

export class TemplateTypesStateModel {
  data: TemplateType[] = [];
}

@State<TemplateTypesStateModel>({
  name: 'TemplateTypes',
  defaults: {
    data: [],
  }
})

@Injectable()
export class TemplateTypesState {
  constructor(
    private checklistBuilderService: ChecklistBuilderService,
  ) { }

  @Selector()
  static read(state: TemplateTypesStateModel) {
    return state.data;
  }

  @Action(TemplateTypesLoad)
  load({ patchState, getState }: StateContext<TemplateTypesStateModel>, { items }: TemplateTypesLoad) {
    let state = getState();
    items.map(i => {
      const index = state.data.map(x => x.id).indexOf(i.id);
      state = getState();
      if (index >= 0) {
        state.data.splice(index, 1);
        patchState({
          data: [...state.data, i]
        });
      }
      else {
        patchState({ data: [...getState().data, i] });
      }
    });
  }

  @Action(TemplateTypesRefresh)
  refresh({ patchState }: StateContext<TemplateTypesStateModel>) {
    this.checklistBuilderService.getAllTemplateTypes().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(TemplateTypesRefreshByDocument)
  refreshByDocument({ patchState }: StateContext<TemplateTypesStateModel>, { id }: TemplateTypesRefreshByDocument) {
    this.checklistBuilderService.getTemplateTypesByDocumentId(id).subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(TemplateTypesCreate)
  create({ getState, patchState }: StateContext<TemplateTypesStateModel>, { item }: TemplateTypesCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(TemplateTypesUpdate)
  update({ getState, patchState }: StateContext<TemplateTypesStateModel>, { id, item }: TemplateTypesUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(TemplateTypesDelete)
  delete({ getState, patchState }: StateContext<TemplateTypesStateModel>, { id }: TemplateTypesDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
