import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { OlogEmailCreate, OlogEmailDelete, OlogEmailRefresh, OlogEmailUpdate } from './olog-email.action';
import { OlogEmail } from '../../services/olog-email';
import { OlogEmailService } from '../../services/olog-email.service';

export class OlogEmailStateModel {
  data: OlogEmail[] = [];
}

@State<OlogEmailStateModel>({
  name: 'OlogEmail',
  defaults: {
    data: []
  }
})
@Injectable()
export class OlogEmailState {
  constructor(
    private ologEmailService: OlogEmailService
  ) { }

  @Selector()
  static read(state: OlogEmailStateModel) {
    return state.data;
  }

  @Action(OlogEmailRefresh)
  refresh({ patchState }: StateContext<OlogEmailStateModel>) {
    this.ologEmailService.GetOlogEmails().toPromise().then(data => {
      patchState({ data });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(OlogEmailCreate)
  create({ getState, patchState }: StateContext<OlogEmailStateModel>, { item }: OlogEmailCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(OlogEmailUpdate)
  update({ getState, patchState }: StateContext<OlogEmailStateModel>, { id, item }: OlogEmailUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    const currentState = state.data;

    if (index >= 0) {
      currentState.splice(index, 1, item);
      patchState({ data: currentState });
    }
  }

  @Action(OlogEmailDelete)
  delete({ getState, patchState }: StateContext<OlogEmailStateModel>, { id }: OlogEmailDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
