import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as NavigationLinkActions from './navigation-links.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';


@Injectable({
  providedIn: 'root'
})
export class NavigationLinkStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore() {
    this.store.dispatch(new NavigationLinkActions.NavigationLinkRefresh());
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.NAVIGATIONLINKS, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new NavigationLinkActions.NavigationLinkCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new NavigationLinkActions.NavigationLinkUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new NavigationLinkActions.NavigationLinkDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new NavigationLinkActions.NavigationLinkRefresh());
      }
    });

  }
}
