<div class="mat-container scrollbar">
  <mat-table
    cdkDropList
    [cdkDropListData]="dataSource"
    [dataSource]="dataSource"
    matSort
    matSortActive="created"
    matSortDisableClear
    (matSortChange)="sortData($event)"
    matSortDirection="desc"
    (cdkDropListDropped)="reorderTable($event, dataSource)"
  >
    <ng-container matColumnDef="hyperlink">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Hyper Link
      </mat-header-cell>
      <mat-cell *matCellDef="let element"
        ><a
          href="{{ element.webAddress }}"
          target="_blank"
          [innerHTML]="element.hyperlink | highlightSearch : filter"
        ></a>
      </mat-cell>
    </ng-container>
    <!-- <ng-container matColumnDef="webAddress">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Web Address
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.webAddress }}</mat-cell>
    </ng-container> -->
    <ng-container matColumnDef="isActive">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Is Active
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-slide-toggle [disabled]="true" [checked]="element.isActive">
        </mat-slide-toggle>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      #matrow
      *matRowDef="let row; columns: displayedColumns; when"
      class="table-row"
      [ngClass]="{ 'mat-row-highlight': selectedRowIndex == row.id }"
      [attr.position]="getPosition(row)"
      (click)="highlight(row)"
      [matTooltip]="row.webAddress"
      [matTooltipPosition]="'right'"
      matTooltipClass="tooltip-white tooltip-wide"
      cdkDrag
      [cdkDragData]="row"
    ></mat-row>
  </mat-table>
</div>
