import { AfterViewInit, Component, ElementRef, HostListener, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { BaseComponent } from 'src/app/common/base/base.component';
import { OLogService } from './olog.service';
import { ActivatedRoute, Params } from '@angular/router';
import { OlogNotificationType } from '../../common/enumerations/enumerations';

@Component({
  selector: 'olog',
  templateUrl: './olog.component.html',
  styleUrls: ['./olog.component.scss']
})
export class OlogComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @ViewChild("sidenav") sidenav!: MatSidenav;
  @ViewChild("headerTop") headerTop!: ElementRef;

  disabled: boolean = false;

  loading = false;

  errorMessages: string[] = [];
  headerOnTop: boolean = false;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter: any[] = [];
  currentFilter: any;
  filter: any;

  element!: any;
  loadingMessage!: string;

  constructor(
    public service: OLogService,
    protected override injector: Injector,
    private route: ActivatedRoute
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.setHeights();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.moduleTitle = 'Olog Operations';
    this.route.queryParams.subscribe(async params => {
      const post = params['post'];
      const formType = params['form'];
      const formID = params['id'];
      const type = params['type'];

      if (post && formType && formID && type) {
        return await this.service.postToOlog(formType, formID, type);
      }
      else return null;
    });
  }

  changeToggle(e: any) {

  }


  clear() {
    this.errorMessages = [];
  }

  create() {

  }


  @HostListener("window:resize")
  onResize() {
    this.innerWidth = window.innerWidth;
    // if (this.isExpanded != false) {

    // }
    if (this.innerWidth < 768) {
      this.isExpanded = false;
    }
  }

  @HostListener("window:scroll")
  isHeaderOnTop() {
    let elemRec;
    if (this.headerTop?.nativeElement !== undefined) {
      elemRec = this.headerTop.nativeElement.getBoundingClientRect();
      const docViewTop = window.screenTop;
      const elemTop = elemRec.top;
      if (elemTop <= docViewTop) {
        this.headerOnTop = true;
      } else {
        this.headerOnTop = false;
      }
      // this.tableWidth = this.scheduleTable.nativeElement.offsetWidth + 'px';
    } else {
      this.headerOnTop = false;
    }
  }

  //////
  changedFilter() { }
  clearFilter() { }
  selectedFilter() { }
  createDisabled() { }
  editDisabled() { }
  action() { }



  applyFilter(e: any) {
    if (!e)
      this.valueFilter = '';
    this.filter = e;
  }

  buttonsVisible: boolean | null = false;


  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 2000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Buttons:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Main:
          this.loadingMain = e as boolean;
          break;
      }
      // console.log('m: ' + this.loadingMain + '   b: ' + this.loadingButtons + '   s: ' + this.loadingSide);
      this.loading = this.loadingMain || this.loadingButtons || this.loadingSide;
    }, 100);
  }


  loadingSide = false;
  loadingButtons = false;
  loadingMain = false;
  loadingOrigin = LoadingOrigin;


}

enum LoadingOrigin {
  Side = 1, Buttons = 2, Main = 3
}
