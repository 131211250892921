import { Injectable } from '@angular/core';
import { FileV2Service } from './file-v2.service';
import FileV2 from './file-v2.model';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

  constructor(
    private file2Service: FileV2Service
  ) { }

  async uploadImagesToServer(text: string, path: string): Promise<string | null> {
    if (text === undefined) {
      return null;
    }

    const html = new DOMParser().parseFromString(text, 'text/html');
    const images = Array.from(html.images)
      .map(i => i.src)
      .filter(i => i.includes('data:image'));

    if (images.length > 0) {
      const result = await this.processImages(images, path, text);
      console.log(result);
      return result as string;
    }

    return text;
  }


  async processImages(images: any[], path: string, text: any) {
    return new Promise(async (resolve) => {
      if (images.length === 0) {
        resolve(text);
        return;
      }

      const imageProcessingPromises = images.map(async (image: any) => {
        const base64 = image.split(',')[1];
        const imageType = image.split(',')[0].replace('data:', '').replace(';base64', '');
        const imageBlob = this.dataURItoBlob(base64, imageType);
        const imageExt = 'img.' + imageType.split('/')[1];
        const imageFile = new File([imageBlob], imageExt, { type: imageType });

        const file: FileV2 = await this.upload(imageFile, path);
        text = text.replace(image, file.url);
      });

      await Promise.all(imageProcessingPromises);
      resolve(text);
    });
  }

  upload(file: File, path: string): any {
    return new Promise<FileV2>((resolve) => {
      const form = new FormData();
      form.append('file', file);
      form.append('path', path + file.name);
      this.file2Service.createFiles(form).toPromise().then(
        (file: FileV2) => {
          resolve(file);
        },
        error => {
          console.error(error);
          resolve(error);
        }
      );
    });
  }

  dataURItoBlob(data: string, type: any) {
    const byteString = window.atob(data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type });
    return blob;
  }
}
