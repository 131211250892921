import { ProcedureCredited } from "../../models/procedure-credited.model";

export class ProcedureCreditedRefresh {
  static readonly type = "[ProcedureCredited] Refresh";
}

export class ProcedureCreditedCreate {
  static readonly type = "[ProcedureCredited] Create";

  constructor(public item: ProcedureCredited) { }
}

export class ProcedureCreditedUpdate {
  static readonly type = "[ProcedureCredited] Update";

  constructor(public id: number, public item: ProcedureCredited) { }
}

export class ProcedureCreditedDelete {
  static readonly type = "[ProcedureCredited] Delete";

  constructor(public id: number) { }
}
