import { Component, EventEmitter, Injector, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatRow } from '@angular/material/table';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Catalog, StatusPendingCatalog, ActionPendingCatalog, PendingCatalogColor } from 'src/app/common/enumerations/enumerations';
import { PendingUser } from 'src/app/services/pending-approvals/pending-approval';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { UserCatalog, User } from 'src/app/components/catalogs/user-catalog/services/user';
import { CatalogService } from '../../catalog-service';


@Component({
  selector: 'app-user-catalog-list',
  templateUrl: './user-catalog-list.component.html',
  styleUrls: ['./user-catalog-list.component.scss']
})
export class UserCatalogListComponent extends BaseComponent implements OnInit {

  @Input()
  filter!: string;

  @Output() loading = new EventEmitter<boolean>();
  @Output() rowSelected = new EventEmitter<UserCatalog | null>();

  canCreate!: boolean;
  canApprove!: boolean;

  selectedRowIndex!: number;
  selectedIndex!: number;

  valueFilter = '';
  showInactive!: boolean;

  userCatalogs?: UserCatalog[];

  public displayedColumns: string[] = ['name', 'roles', 'status'];
  dataSource!: MatTableDataSource<UserCatalog>;
  pending = false;

  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChildren('matrow', { read: ViewContainerRef })
  rows!: QueryList<ViewContainerRef>;
  @ViewChildren('matrow')
  datarows!: QueryList<MatRow>;

  constructor(
    protected override injector: Injector,
    private catalogService: CatalogService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loadUserCatalogs();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.canCreate = this.hasPrivilege(PrivilegeEnum.UserCatalogCreate);
    this.canApprove = this.hasPrivilege(PrivilegeEnum.UserCatalogApprove);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('filter' in changes) {
      this.filterUsers();
    }
  }

  loadUserCatalogs() {
    this.loading.emit(true);
    this.valueFilter = '';
    this.canCreate = this.hasPrivilege(PrivilegeEnum.UserCatalogCreate);
    this.canApprove = this.hasPrivilege(PrivilegeEnum.UserCatalogApprove);
    const user = this.catalogService.pendingCatalog;
    this.catalogService.GetJoinCreated(Catalog.Users, (this.canApprove || this.canCreate) ?? false).toPromise().then(data => {
      this.catalogService.userCatalogs = data?.userCatalogs?.filter(x => x.id != -1);
      data?.userCatalogs?.map(x => {
        if (x.pendingUser) {
          x.pendingUser.statusName = StatusPendingCatalog[x.pendingUser.status];
          x.pendingUser.actionName = ActionPendingCatalog[x.pendingUser.action];
        }
      });

      this.userCatalogs = data?.userCatalogs;
      this.filterUsers();
      if (user) {
        if (user.id > 0) {
          this.showIndex(user.id);
        }
        else {
          user.id = this.userCatalogs?.find(u => u.id < -1)?.id ?? 0;
          this.showIndex(user.id);
        }
      }
      this.loading.emit(false);
    },
      error => {
        this.loading.emit(false);
        this.alert.defaultError();
      }
    );
  }

  filterUsers() {
    this.dataSource = new MatTableDataSource(this.userCatalogs?.filter(x =>
      (this.showInactive || !this.showInactive && x.details.user.status == 1)
      && (this.filter ? (x.details.user.name?.toLowerCase().includes(this.filter.toLowerCase()) || x.details.user.initials?.toLowerCase().includes(this.filter.toLowerCase())) : true)));
    this.setSortingAccessor();
  }

  setSortingAccessor() {
    // Define the sorting accessor
    this.dataSource.sortingDataAccessor = (element, property) => {
      switch (property) {
        case 'name':
          return element.details.user.name;
        case 'roles':
          return this.getRoleCodes(element);
        case 'status':
          return element.details.user.status == 0 ? 'Inactive' : element.pendingUser ? element.pendingUser?.statusName +
            " - " +
            element.pendingUser.actionName : '';
        default:
          return (element as any)[property]; // Fallback to the default property
      }
    };
    this.setSort<UserCatalog>(this.dataSource, this.sort);
  }

  getRoleCodes(userCatalog: UserCatalog) {
    return userCatalog.details.roles.sort((a, b) => this.utils.sort(a.code, b.code, true)).map(x => x.code).join('/');
  }

  showActiveInactive() {
    this.filterUsers();
  }

  async selectedRow(row: UserCatalog) {
    if (await this.canDeactivate())
      return this.selectRow(row);
  }

  selectRow(row: UserCatalog) {
    if (this.selectedRowIndex != row.id) {
      this.selectedRowIndex = row.id;
      this.rowSelected.emit(row);
    } else {
      this.selectedRowIndex = -1;
      this.rowSelected.emit(null);
    }
  }

  createUser() {
    this.selectedRowIndex = -1;
    this.rowSelected.emit(new UserCatalog());
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getColor(resource: User): string {
    if (resource.color === PendingCatalogColor.Create || resource.color === PendingCatalogColor.Modify) {
      return '#EAAA00';
    }
    return '';
  }

  showIndex(id: number) {
    this.selectedIndex = this.dataSource.data.findIndex(x => x.id === id);
    this.selectedRowIndex = -1;
    this.showElement(this.selectedIndex, 7);
    this.selectedRow(this.dataSource.data[this.selectedIndex]);
    this.loading.emit(false);
  }

  showElement(index: number, height: number) {
    const indexstr = index.toString();
    const row = this.rows.find(x => x.element.nativeElement.getAttribute('position') === indexstr);
    if (row != null) {
      const rect = row.element.nativeElement.getBoundingClientRect();
      if ((rect.y <= 0) || ((rect.y + rect.height) > height)) {
        row.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
      }
      return;
    }
    console.log('not found');
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex(r => {
      return r.id === row.id;
    });
    return i;
  }

}
