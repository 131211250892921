import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProcedureCategory } from '../models/procedure-category.model';

@Injectable({
  providedIn: 'root'
})
export class ProcedureCategoryService {
  private url: string = environment.urls.baseApiUrl + "/ProcedureCategory";

  constructor(
    private http: HttpClient,
  ) { }

  public create(obj: ProcedureCategory): Observable<ProcedureCategory> {
    return this.http.post<ProcedureCategory>(this.url, obj);
  }

  public read(): Observable<ProcedureCategory[]> {
    return this.http.get<ProcedureCategory[]>(this.url);
  }

  public update(id: number, obj: ProcedureCategory): Observable<ProcedureCategory> {
    return this.http.put<ProcedureCategory>(this.url + "/" + id.toString(), obj);
  }

  public delete(id: number): Observable<any> {
    return this.http.delete<any>(this.url + "/" + id.toString());
  }
}
