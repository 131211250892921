import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { PrivilegeEnum } from '../../../../services/role-privilege/privilege-enum';
import { User } from './user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  url = environment.urls.baseApiUrl + '/Users';
  constructor(private http: HttpClient, private authService: AuthenticationService) { }
  Read(): Observable<User[]> {
    return this.http.get<User[]>(this.url);
  }
  ReadByRole(roles: number[]): Observable<User[]> {
    let urlRoles = '';
    roles.map((element) => {
      urlRoles = urlRoles + 'roles=' + element + '&';
    });
    urlRoles = urlRoles.slice(0, -1);
    const result = this.http.get<User[]>(this.url + '/byRoles?' + urlRoles);
    return result;
  }
  getEmailsByRoles(roles: number[]): Observable<string[]> {
    let urlRoles = '';
    roles.map((element) => {
      urlRoles = urlRoles + 'roles=' + element + '&';
    });
    urlRoles = urlRoles.slice(0, -1);
    const result = this.http.get<string[]>(this.url + '/GetEmailbyRole?' + urlRoles);
    return result;
  }
  ReadActive(): Observable<User[]> {
    return this.http.get<User[]>(this.url + '/Active');
  }

  ReadOne(id: number): Observable<User> {
    if (id == null) { id = 0; }
    return this.http.get<User>(this.url + '/' + id.toString());
  }
  Create(user: User): Observable<User> {
    return this.http.post<User>(this.url, user);
  }
  Update(id: number, user: User): Observable<User> {
    return this.http.put<User>(this.url + '/' + id.toString(), user);
  }
  Delete(id: number) {
    this.http.delete(this.url + '/' + id.toString());
  }

  readSupervisedForTraining(): Observable<User[]> {
    return this.http.get<User[]>(this.url + '/SupervisedForTraining');
  }

  readByPrivilege(privileges: PrivilegeEnum[]): Observable<User[]> {
    let url = this.url + '/Privilege';
    let first = true;

    for (const privilege of privileges) {
      url = url + (first ? '?' : '&') + 'privileges=' + privilege.toString();
      first = false;
    }
    return this.http.get<User[]>(url);
  }

}
