import { Component, OnInit, Input, Injector, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { RsswaService } from '../../rsswa.service';
import { RSSWAResource, SaveRSSWAStatusResource } from '../../rsswa';
import { FormGroup } from '@angular/forms';

import { BaseComponent } from 'src/app/common/base/base.component';
import { FormStatusEnum, FormType, OlogNotificationType } from 'src/app/common/enumerations/enumerations';
import { OLogService } from 'src/app/components/olog/olog.service';
import { WfTaskLocalResource, WfTaskResource, WfSignature, WfSignatureResource, WFTaskSignatureResource, SaveTaskResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';

@Component({
  selector: 'rsswa-section6',
  templateUrl: './rsswa-section6.component.html',
  styleUrls: ['./rsswa-section6.component.scss']
})
export class RsswaSection6Component extends BaseComponent implements OnInit, OnChanges {

  @Input() rsswa?: RSSWAResource | null;
  @Output() loading = new EventEmitter<boolean>();

  public disabled!: boolean;
  public questionDisabled!: boolean;

  // Workflow
  workFlow!: WfTaskLocalResource[];
  workFlowPending!: WfTaskResource[];
  signatures!: WfSignature[];
  wfSignatures!: WfSignatureResource[];
  wfTaskSignature: WFTaskSignatureResource = new WFTaskSignatureResource();
  wfSignCount!: number;

  wfFinish = new WfSignature;
  approvals?: number;
  reqApprovals!: boolean;

  // Form
  public form!: FormGroup;

  constructor(
    private _rsswa: RsswaService,
    public _wf: WorkFlowService,
    private ologService: OLogService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  public refresh() {
    this.wfSignCount = 0;
    if (this.rsswa?.wfTable) {
      this.wfSignatures = this.rsswa.wfTable.wfTableLocal?.wfSectionLocals.find(s => s.number == 6)?.wfSignatures ?? [];
      this.signatures = [];
      this.approvals = this.wfSignatures.length;
      this.reqApprovals = this.wfSignatures.some(t => t.value && t.approveEnabled && !t.wfTaskLocal.wfActionLocals?.some(x => x.name == 'Close'));
      this.setDisabled();
      this.wfSignatures.map(t => {
        const taskEnabled = (t.approveEnabled || t.unapproveEnabled) && this.rsswa?.status != FormStatusEnum.Canceled && this.rsswa?.status != FormStatusEnum.Closed;
        if (!t.wfTaskLocal.wfActionLocals?.some(x => x.name == 'Close')) {
          let signature: WfSignature = {
            id: t.id,
            approveId: t.approveID,
            unapproveId: t.unapproveID,
            disapproveId: t.disapproveID,
            amendmentId: t.amendmentID,
            name: t.titleBefore,
            question: t.question,
            roles: t.roles.map(r => r.id),
            roleCodes: t.roles.map(r => r.code).join('/'),
            disabled: this.disabled || !taskEnabled,
            approvedBy: t.signedBy,
            approved: t.signed,
            approvedOn: t.signedOn,
            approve: t.approveEnabled && t.value,
            unapprove: t.unapproveEnabled && t.value,
            disapprove: t.disapproveEnabled,
            amendment: t.amendmentEnabled,
            required: t.required,
            taskId: t.taskID,
            value: t.value
          };
          this.wfSignCount = this.signCount();
          this.signatures.push(signature);
        }
        else {
          this.wfFinish = {
            id: t.id,
            approveId: t.approveID,
            name: t.titleBefore,
            roles: t.roles.map(r => r.id),
            roleCodes: t.roles.map(r => r.code).join('/'),
            disabled: this.disabled || this.reqApprovals,
            approvedBy: t.signedBy,
            approved: t.signed,
            approvedOn: t.signedOn,
            required: t.required,
            question: t.question,
            value: t.value,
            taskId: t.taskID,
            approve: true,
            unapprove: false,
            amendment: false
          };
        }
      });
    }
  }

  setDisabled() {
    this.disabled = this.rsswa?.status != FormStatusEnum.WorkCompleted;
    this.questionDisabled = this.rsswa?.status == FormStatusEnum.Closed || this.rsswa?.status == FormStatusEnum.Canceled || !this.getCurrentUser();
  }


  answered(taskId: any, e: any) {
    let saveTask: SaveTaskResource = {
      id: taskId,
      value: e
    };

    this.saveTask(saveTask);
  }

  saveTask(saveTask: SaveTaskResource) {
    this.loading.emit(true);
    this._wf.save(saveTask).subscribe(() => {
    }, error => {
      console.log(error);
      this.alert.defaultError();
    });
  }

  approve(taskId?: number, actionId?: number) {
    this.wfTaskSignature.taskId = taskId;
    this.wfTaskSignature.actionId = actionId;
    this.loading.emit(true);
    this._wf.sign(this.wfTaskSignature).subscribe(() => {
    }, error => {
      console.log(error);
      this.alert.defaultError();
    });
  }

  async close(taskId?: number, actionId?: number) {
    if (!this.rsswa?.id) return;
    const response = await this.ologService.postToOlog(FormType.RSSWA, this.rsswa?.id, OlogNotificationType.Closed);
    if (response?.ok) {
      this.wfTaskSignature.taskId = taskId;
      this.wfTaskSignature.actionId = actionId;
      this.loading.emit(true);
      this._wf.sign(this.wfTaskSignature).subscribe(() => {
        if (this.rsswa) {
          const status: SaveRSSWAStatusResource = {
            id: this.rsswa.id,
            status: FormStatusEnum.Closed
          };
          this._rsswa.putStatus(status).subscribe(
            () => {
              this.alert.message('formStateChanged', [new MessagePlaceholder('{serialNo}', this.rsswa?.serialNo), new MessagePlaceholder('{status}', 'Closed')]);
            },
            error => {
              console.error(error);
              this.alert.defaultError();
            }
          );
        }
      }, error => {
        console.log(error);
        this.alert.defaultError();
      });
    }
  }

  unapprove(taskId?: number, actionId?: number) {
    this.wfTaskSignature.taskId = taskId;
    this.wfTaskSignature.actionId = actionId;
    this.loading.emit(true);
    this._wf.sign(this.wfTaskSignature).subscribe(() => {
      this.loading.emit(false);
    }, error => {
      console.log(error);
      this.alert.defaultError();
    });
  }

  saveStatus(status: SaveRSSWAStatusResource) {
    this._rsswa.putStatus(status).toPromise();
  }

  signCount() {
    return this.wfSignatures.filter(t => !t.signed).length - 1;
  }
}
