import { RadLocationInterlockRelation } from "src/app/components/catalogs/rad-monitor/rad-monitor/rad-location-interlock-relation";

export class RadLocationInterlockRelationRefresh {
  static readonly type = '[RadLocationInterlockRelation] Refresh';
}

export class RadLocationInterlockRelationCreate {
  static readonly type = '[RadLocationInterlockRelation] Create';
  constructor(public item: RadLocationInterlockRelation) { }
}

export class RadLocationInterlockRelationUpdate {
  static readonly type = '[RadLocationInterlockRelation] Update';
  constructor(public id: number, public item: RadLocationInterlockRelation) { }
}

export class RadLocationInterlockRelationDelete {
  static readonly type = '[RadLocationInterlockRelation] Delete';
  constructor(public id: number) { }
}
