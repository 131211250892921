import { SCFMaster } from "../../scf";



export class SCFMastersRefresh {
  static readonly type = '[SCFMasters] Refresh';
}

export class SCFMastersRefreshById {
  static readonly type = '[SCFMasters] RefreshById';
  constructor(public id: number) { }
}

export class SCFMastersCreate {
  static readonly type = '[SCFMasters] Create';

  constructor(public item: SCFMaster) { }
}

export class SCFMastersUpdate {
  static readonly type = '[SCFMasters] Update';

  constructor(public id: number, public item: SCFMaster) { }
}

export class SCFMastersDelete {
  static readonly type = '[SCFMasters] Delete';
  constructor(public id: number) { }
}
