import { Component, Inject, Injector, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable, Subscription } from "rxjs";
import { BaseComponent } from "src/app/common/base/base.component";
import { ResourceType } from "src/app/common/enumerations/enumerations";
import { FileV2Service } from "src/app/services/file/file-v2.service";
import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { ProcedureTypeEnum } from "../../enums/procedure-type.enum";
import { ProcedureCategory } from "../../models/procedure-category.model";
import { ProcedureConfiguration } from "../../models/procedure-configuration.model";
import { ProcedureService } from "../../services/procedure.service";
import { Procedure } from "../../models/procedure.model";

@Component({
  selector: "procedure-popup",
  templateUrl: "./procedure-popup.component.html",
  styleUrls: ["./procedure-popup.component.scss"],
})
export class ProcedurePopupComponent extends BaseComponent implements OnInit {
  procedureType!: ProcedureTypeEnum;
  procedureCategories$!: Observable<ProcedureCategory[]>;
  resources$!: Observable<Resource[]>;

  resourcesSubscription!: Subscription;

  shuttersBranchlines!: Resource[];
  procedureCategories!: ProcedureCategory[];
  procedureCategory?: ProcedureCategory;
  procedure!: Procedure;
  loading: boolean = false;
  processingFile = false;
  title!: string;
  procedureConfiguration$!: Observable<ProcedureConfiguration>;
  procedureConfiguration!: ProcedureConfiguration;
  path!: string;
  editing: boolean = false;
  initialFileID!: number;

  //messages

  label_complete_procedure!: string;
  label_edit_procedure!: string;
  label_new_procedure!: string;
  label_category!: string;
  label_procedure_number!: string;
  label_title!: string;
  label_revision!: string;
  label_review_period!: string;
  label_3_years!: string;
  label_5_years!: string;
  label_status!: string;
  label_active!: string;
  label_inactive!: string;
  label_select_pdf!: string;
  label_cancel!: string;
  label_accept!: string;
  label_upload_files!: string;
  label_appendices!: string;
  label_review_docs!: string;
  label_display_text!: string;
  label_url_link!: string;
  label_popup_new_procedure!: string;
  //messages

  constructor(
    protected override injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<ProcedurePopupComponent>,
    private procedureService: ProcedureService,
    private fileV2Service: FileV2Service
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loadResources();
    this.setLabels();
    if (this.data?.procedure) {
      this.procedure = this.utils.cloneDeep(this.data?.procedure);
      this.title = this.data.complete ? this.label_complete_procedure : this.label_edit_procedure;
      if (this.procedure && this.data.complete) {
        this.procedure.lastReviewed = null;
      }
    } else {
      this.procedure = { id: 0, procedureNumber: '', procedureFiles: [], procedureAppendices: [] };
      this.title = this.label_new_procedure;
    }

    this.procedureCategories$ = this.store.select((state) => state.ProcedureCategory.ProcedureCategories);
    this.procedureCategories$.subscribe((data) => {
      this.procedureCategories = data.sort((x1, x2) => this.utils.sort(x1.name, x2.name));
    });

    this.procedureConfiguration$ = this.store.select((state) => state.ProcedureConfiguration.ProcedureConfiguration);
    this.procedureConfiguration$.subscribe((data) => {
      this.procedureConfiguration = data;
      this.setPath();
    });

    this.editing = (this.procedure?.id ?? 0) > 0 && !this.data?.complete;
    this.initialFileID = this.data?.procedure?.fileID;
    this.setProcedureCategory();

  }

  setLabels() {
    this.label_complete_procedure = this.getMessage("ALSPC_Porcedure_CompleteProcedure").description;
    this.label_edit_procedure = this.getMessage("ALSPC_Procedure_EditProcedure").description;
    this.label_new_procedure = this.getMessage("ALSPC_Procedure_NewProcedure").description;
    this.label_category = this.getMessage("ALSPC_Procedure_Category").description;
    this.label_procedure_number = this.getMessage("ALSPC_Procedure_ProcedureNumber").description;
    this.label_title = this.getMessage("ALSPC_Procedure_Title").description;
    this.label_revision = this.getMessage("ALSPC_Procedure_Revision").description;
    this.label_review_period = this.getMessage("ALSPC_Procedure_ReviewPeriod").description;
    this.label_3_years = this.getMessage("ALSPC_Procedure_3_Years").description;
    this.label_5_years = this.getMessage("ALSPC_Procedure_5_Years").description;
    this.label_status = this.getMessage("ALSPC_Procedure_Status").description;
    this.label_active = this.getMessage("ALSPC_Procedure_Active").description;
    this.label_inactive = this.getMessage("ALSPC_Procedure_Inactive").description;
    this.label_select_pdf = this.getMessage("ALSPC_Procedure_SelectPDF").description;
    this.label_cancel = this.getMessage("ALSPC_Procedure_Cancel").description;
    this.label_accept = this.getMessage("ALSPC_Procedure_Accept").description;
    this.label_upload_files = this.getMessage("ALSPC_Procedure_UploadFiles").description;
    this.label_appendices = this.getMessage("ALSPC_Procedure_Appendices").description;
    this.label_review_docs = this.getMessage("ALSPC_Procedure_ReviewDocs").description;
    this.label_display_text = this.getMessage("ALSPC_Procedure_DisplayText").description;
    this.label_url_link = this.getMessage("ALSPC_Procedure_URLLink").description;
    this.label_popup_new_procedure = this.getMessage("ALSPC_Update_NewProcedure").description;

  }

  submit() {
    this.loading = true;
    this.procedure.checklistTemplate = null;
    if (this.data.complete && this.procedure) {
      this.procedureService.complete(this.procedure.id, this.procedure).subscribe(() => this.dialogRef.close(),
        (error) => {
          if (error.status === 409 || error.status == 404) {
            this.alert.error(error.error);
          } else {
            this.alert.defaultError();
            console.error(error);
          }
          this.loading = false;
        }
      );
    } else {
      if (this.procedure?.id) {
        this.procedureService
          .update(this.procedure.id, this.procedure).subscribe(() => this.dialogRef.close(),
            (error) => {
              if (error.status === 409 || error.status == 404) {
                this.alert.error(error.error);
              } else {
                this.alert.defaultError();
                console.error(error);
              }
              this.loading = false;
            }
          );
      } else {
        if (this.procedure)
          this.procedureService.create(this.procedure).subscribe(() => this.dialogRef.close(),
            (error) => {
              if (error.status === 409 || error.status == 404) {
                this.alert.error(error.error);
              } else {
                this.alert.defaultError();
                console.error(error);
              }
              this.loading = false;
            }
          );
      }
    }
  }

  cancel() {
    var files: number[] = [];
    if (!this.isNew() && this.procedure) {
      if (this.procedure.fileID != null && this.initialFileID != this.procedure.fileID) {
        files.push(this.procedure.fileID);
      }
      const procedureFiles = this.procedure.procedureAppendices?.filter(x => !x.id && x.fileID).map(x => x.fileID);
      if (procedureFiles)
        files = files.concat(procedureFiles as number[]);
    } else {
      if (this.procedure && this.procedure.fileID) {
        files.push(this.procedure.fileID);
      }
      const procedureApendicesFiles = this.procedure?.procedureAppendices?.filter(x => x.fileID).map((x) => x.fileID);
      if (procedureApendicesFiles)
        files = files.concat(procedureApendicesFiles as number[]);
    }
    files.map((file) => {
      this.fileV2Service.delete(file).subscribe(
        () => { },
        (error) => console.error(error)
      );
    });
    this.dialogRef.close();
  }

  setPath() {
    if (this.procedure?.status === 1) {
      this.path = this.procedureConfiguration?.currentPath.replace("[ALSPCCategory]", this.procedureCategories.find((x) => x.id == this.procedure?.procedureCategoryID)?.name ?? '').replace("[ALSPCNumber]", this.procedure?.procedureNumber);
    } else {
      this.path = this.procedureConfiguration?.inactivePath.replace("[ALSPCCategory]", this.procedureCategories.find((x) => x.id == this.procedure?.procedureCategoryID)?.name ?? '').replace("[ALSPCNumber]", this.procedure?.procedureNumber ?? '');
    }
  }

  isThereAppendixWithFile(): boolean {
    return this.procedure?.procedureAppendices?.some((x) => x.fileID) ?? false;
  }

  disabledFileUpload(): boolean {
    return (
      !this.procedure?.procedureCategoryID ||
      !this.procedure.procedureNumber ||
      (this.procedure.status !== 0 && this.procedure.status !== 1)
    );
  }

  isNew(): boolean {
    return (!this.data.complete && !this.procedure?.id);
  }

  setProcedureCategory() {
    this.procedureCategory = this.procedureCategories.find(
      (x) => x.id == this.procedure?.procedureCategoryID
    );
  }

  loadResources() {
    this.resources$ = this.store.select((state) => state.Resources.data);
    this.resourcesSubscription = this.resources$.subscribe((data) => {
      if (data?.length) {
        this.shuttersBranchlines = data
          .filter(
            (x) =>
              x.type === ResourceType.Shutter ||
              x.type === ResourceType.Branchline
          )
          .sort((a, b) => this.utils.SortBeamlines(a.name, b.name));
      }
    });
  }

  setTitleInKeByResource(resourceId: number, resources: Resource[]) {
    if (this.procedure)
      this.procedure.title = resources.find((x) => x.id === resourceId)?.name;
  }

  setProcedureTitle(e: any) {
    if (this.procedure)
      this.procedure.title = e;
  }
}
