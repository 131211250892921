import { Component, Output, EventEmitter, Input, OnDestroy, AfterViewInit, ChangeDetectorRef, SimpleChanges, OnChanges, } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Store } from "@ngxs/store";
import { Schedule } from "src/app/components/schedules/models/schedule";
import { ScheduleResource } from "src/app/components/schedules/models/schedule-resources";
import { ScheduleType } from "src/app/components/schedules/models/schedule-type";
import { utils } from "src/app/modules/libs/utils";

@Component({
  selector: "cl-editor-schedule",
  templateUrl: "./cl-editor-schedule.component.html",
  styleUrls: ["./cl-editor-schedule.component.scss"],
})
/** cl-editor-schedule component*/
export class ClEditorScheduleComponent implements OnDestroy, AfterViewInit, OnChanges {

  @Input() disabled!: boolean;
  @Input() configuration?: string;
  @Input() scheduleTypeId?: number;
  @Input() scheduleResourceId?: number;
  @Input() showLocation: boolean = true;
  @Output() scheduleChanged = new EventEmitter();

  scheduleType?: ScheduleType;
  tmpSchedules: ScheduleResource[] = [];

  tmpSchedulesFiltered: ScheduleResource[] = [];
  scheduleResource: ScheduleResource = new ScheduleResource();
  scheduleResourceEdit: ScheduleResource = new ScheduleResource();
  tmpSchedId!: number;

  scheduleTypes$!: Observable<ScheduleType[]>;
  scheduleTypes!: ScheduleType[];

  schedules$!: Observable<Schedule[]>;
  schedules!: Schedule[];

  subscription1!: Subscription;
  subscription2!: Subscription;

  schedule?: ScheduleResource;

  constructor(private store: Store) { }

  ngOnDestroy(): void {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.configuration) {
      this.scheduleTypeId = JSON.parse(this.configuration).scheduleType;
    }
    this.ngAfterViewInit();
  }

  ngAfterViewInit() {
    this.scheduleTypes$ = this.store.select(
      (state) => state.ScheduleTypes.data
    );
    this.subscription1 = this.scheduleTypes$.subscribe((data) => {
      this.scheduleTypes = data;
      this.scheduleType = data.find((x) => x.id == this.scheduleTypeId);
      this.getSchedules();
    });
  }

  getSchedules() {
    this.schedules$ = this.store.select((state) => state.Schedules.data);
    this.subscription2 = this.schedules$.subscribe((data) => {
      this.tmpSchedules = [];
      data
        .filter((x) => x.statusId != 2 && x.typeId == this.scheduleType?.id)
        .map((schedule) => {
          schedule.scheduleResources.map((sr) => {
            const scheduleResource: ScheduleResource = {
              id: sr.id,
              name: sr.resourceId ? sr.resource?.name : sr.resourceString,
              resourceId: sr.resourceId ? sr.resourceId : 0,
              dateValue1: schedule.lastCompleted,
              dateValue2: schedule.nextDue,
              dateValue3: schedule.extendedDate,
            };
            this.tmpSchedules.push(scheduleResource);
          });
        });
      const sched1 = this.tmpSchedules
        .filter((x) => x.resourceId != 0)
        .sort((a, b) => utils.SortBeamlines(a.name, b.name));
      const sched2 = this.tmpSchedules.filter((x) => x.resourceId == 0);
      this.tmpSchedules = [...new Set(sched1.concat(sched2))];
      this.schedule = this.tmpSchedules.find((x) => x.id == this.scheduleResourceId);

      this.tmpSchedulesFiltered = utils.cloneDeep(this.tmpSchedules);
    });
  }

  displayType(e: any) {
    return e ? e.acronym : null;
  }

  displayLocation(e: any) {
    return e ? e.name : null;
  }

  async scheduleSelected() {
    this.tmpSchedules = [];
    this.scheduleResource = new ScheduleResource();
    this.getSchedules();
    this.emit();
  }

  locationSelected() {
    this.emit();
  }

  emit() {
    const scheduleObject = new ScheduleObject();
    scheduleObject.scheduleType = this.scheduleType;
    scheduleObject.schedule = this.schedule;
    this.scheduleChanged.emit(scheduleObject);
  }
}
export class ScheduleObject {
  scheduleType?: ScheduleType;
  schedule?: ScheduleResource;
}
