import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Document, RelatedDocument } from 'src/app/services/documents/documents';
import { OLogService } from '../olog.service';
import { OlogNotificationType } from 'src/app/common/enumerations/enumerations';
import { OLogEntry, OLogFormData, PostOlogResponse } from '../olog';
import { MatDialogConfig } from '@angular/material/dialog';
import { PartialOlogComponent } from 'src/app/controls/olog/partial-olog/partial-olog.component';
import { SiteConfigurationService } from 'src/app/services/site-configuration/site-configuration-service';
import { SiteConfiguration } from 'src/app/services/site-configuration/site-configuration';

@Component({
  selector: 'olog-main',
  templateUrl: './olog-main.component.html',
  styleUrls: ['./olog-main.component.scss']
})
export class OlogMainComponent extends BaseComponent implements OnInit, OnDestroy {

  category?: number;
  documents?: Document[];
  notification?: OlogNotificationType;
  results?: PostOlogResponse[];

  types = [{ id: 1, name: 'Activate' }, { id: 2, name: 'Amendment' }, { id: 3, name: 'Close' }];
  type?: any;

  siteConfiguration?: SiteConfiguration;

  constructor(
    protected override injector: Injector,
    private service: OLogService,
    private site: SiteConfigurationService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    this.siteConfiguration = await this.site.getById(1).toPromise();
  }

  changed(e: RelatedDocument[]) {
    this.documents = [];
    e.map(r => {
      const document = { formID: r.relatedDocumentId, type: r.relatedDocumentTypeId } as Document;
      this.documents?.push(document);
    });
  }

  post() {
    this.results = [];
    this.documents?.map(async document => {
      if (document.type && document.formID) {
        const res = await this.service.postToOlog(document.type, document.formID, this.type.id, 0, true, true);
        if (res)
          this.results?.push(res);
      }
    });
  }

  postFE() {
    this.results = [];
    const document = this.documents?.[0];
    if (document?.type && document.formID)
      this.service.getOlog(document.type, document.formID, this.type.id).toPromise().then(async data => {
        if (data) {
          const body: OLogFormData = JSON.parse(data.xmlBody.replace('\n', ''));
          const ologEntry = {
            root: {
              author: this.siteConfiguration?.ologUser,
              password: this.siteConfiguration?.ologPassword,
              entry: body
            }
          } as OLogEntry;
          const xmlBody = this.service.createXMLBody(ologEntry, data.serialNo);
          const res = await this.service.postToOlogFE(xmlBody).toPromise();
          this.results?.push({ code: 0, message: xmlBody, ok: true });
        }
      });
  }

  formatMessage(message: string) {
    return message.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replace('<![CDATA[', '').replace(']]>', '');
  }

  openOlog() {
    const document = this.documents?.[0];
    if (document?.type && document.formID)
      this.service.getOlog(document.type, document.formID, this.type.id).toPromise().then(data => {
        if (data) {
          data.cc = data.cc?.filter(email => email.address !== '');
          const dialogConfig: MatDialogConfig = {
            data,
            width: '80vw'
          };
          dialogConfig.data = data;
          const dialogRef = this.dialog.open(PartialOlogComponent, dialogConfig);
          dialogRef.afterClosed().toPromise();
        }
      });
  }



}
