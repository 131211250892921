import { Injectable } from '@angular/core';
import * as OlogCategoryActions from './olog-category.action';
import { Store } from '@ngxs/store';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';


@Injectable({
  providedIn: 'root'
})
export class OlogCategoryStoreAndListenersService {

  constructor(
    private store: Store,
    private signalRService: SignalRService
  ) { }

  initStore() {
    this.store.dispatch(new OlogCategoryActions.OlogCategoryRefresh());
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.OLOGCATEGORY, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new OlogCategoryActions.OlogCategoryCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new OlogCategoryActions.OlogCategoryUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new OlogCategoryActions.OlogCategoryDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new OlogCategoryActions.OlogCategoryRefresh());
      }
    });
  }
}
