import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { ChecklistExecStatus } from 'src/app/components/checklists/checklists';
import { Subscription } from 'rxjs/internal/Subscription';
import { DocumentType } from 'src/app/services/documents/documents';
import { distinctUntilChanged, Observable } from 'rxjs';
import { FormStatus } from 'src/app/services/forms/form-status';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'app-checklist-builder-settings-status',
  templateUrl: './checklist-builder-settings-status.component.html',
  styleUrls: ['./checklist-builder-settings-status.component.scss']
})
export class ChecklistBuilderSettingsStatusComponent implements OnInit, OnDestroy {


  documentType: DocumentType;
  status: ChecklistExecStatus;
  formStatus: FormStatus | null;

  statusNameCtrl = new FormControl();
  statusDescriptionCtrl = new FormControl();
  statusCodeCtrl = new FormControl();

  statuses!: FormStatus[];
  statusesFiltered!: FormStatus[];
  statuses$!: Observable<FormStatus[]>;
  statusesSubs!: Subscription;

  form!: FormGroup;
  title: string;
  disabled!: boolean;

  constructor(
    private store: Store,
    public dialog: MatDialogRef<ChecklistBuilderSettingsStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.documentType = data.documentType;
    this.title = data.title;
    this.status = data.status;
    this.formStatus = this.status?.formStatus;
  }

  ngOnDestroy(): void {
    this.statusesSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.loadStatuses();
    this.buildForm();
    if (!this.status) {
      this.status = { id: 0, documentTypeID: this.documentType.id, status: 1, type: 1 } as ChecklistExecStatus;
    }

    this.statusNameCtrl.setValue(this.formStatus);
    this.statusDescriptionCtrl.setValue(this.status?.description);
    this.statusCodeCtrl.setValue(this.status?.code);
  }

  loadStatuses() {
    this.statuses$ = this.store.select(state => state.FormStatuses.data);
    this.statusesSubs = this.statuses$.pipe(distinctUntilChanged()).subscribe(data => {
      this.statuses = data.sort((a, b) => utils.sort(a.name, b.name));
      this.statusesFiltered = this.statuses;
    });
  }

  changedStatus(e: any) {
    const status = e.target.value;
    this.formStatus = null;
    this.statusesFiltered = this.statuses.filter(x => x.name.toLowerCase().includes(status.toLowerCase()));
  }

  selectedStatus(e: any) {
    this.formStatus = e.option.value;
    this.statusDescriptionCtrl.setValue(this.formStatus?.description);
    this.statusCodeCtrl.setValue(this.formStatus?.code);
    this.statusesFiltered = this.statuses.filter(x => x.id != this.formStatus?.id);
  }

  buildForm() {
    this.form = new FormGroup({
      statusNameCtrl: this.statusNameCtrl,
      statusDescriptionCtrl: this.statusDescriptionCtrl,
      statusCodeCtrl: this.statusCodeCtrl,
    });
  }

  displayObject(e: any) {
    return e ? e.name : null;
  }

  save() {
    if (this.formStatus?.id && this.formStatus.name) {
      this.status.formStatusID = this.formStatus.id;
      this.status.name = this.formStatus.name;
      this.status.description = this.statusDescriptionCtrl.value;
      this.status.code = this.statusCodeCtrl.value;
      this.data.status = this.status;
      this.dialog.close(this.data);
    }
  }

  cancel() {
    this.form.reset();
    this.dialog.close();
  }

  formValid() {
    if (this.statusesFiltered.length == 0) {
      return false;
    }
    return this.formStatus && !utils.isNullValue(this.statusNameCtrl.value) && !utils.isNullValue(this.statusDescriptionCtrl.value) && !utils.isNullValue(this.statusCodeCtrl.value) && !utils.isNullValue(this.status.cssClass);
  }

  selectedColor(e: string) {
    this.status.cssClass = e;
    this.form.markAsDirty();
  }

}
