import { User } from "../../components/catalogs/user-catalog/services/user";

export class Note {
  id!: number;
  noteName?: string;
  isModified?: boolean;
  description?: string;

  date?: Date;
  userID?: number;
  user?: User;
  type?: NoteType;

  // id: number;
  // isActive: boolean;
  createdOn?: Date;
  // createdBy?: number;
  // updatedOn?: Date;
  // updatedBy?: number;
  // deletedOn?: Date;
  // deletedBy?: number;
  // description: string;
  // scheduleId?: number;
  // schedule: Schedule;
  // userId: number;

}

export enum NoteType {
  Normal = 1,
  Extension = 2,
  Rejection = 3
}
