import { ReviewStatus } from "../../reviews.models";

export class ReviewStatusRefresh {
  static readonly type = '[ReviewStatus] Refresh';
}

export class ReviewStatusCreate {
  static readonly type = '[ReviewStatus] Create';

  constructor(public item: ReviewStatus) { }
}

export class ReviewStatusUpdate {
  static readonly type = '[ReviewStatus] Update';

  constructor(public id: number, public item: ReviewStatus) { }
}

export class ReviewStatusDelete {
  static readonly type = '[ReviewStatus] Delete';
  constructor(public id: number) { }
}
