<div class="card">
  <div class="card-header">
    <div class="d-flex justify-content-between">
      <div class="col p-0">
        <div class="card-title">Current Activity</div>
      </div>
      <div class="d-flex flex-wrap justify-content-end">
        <div *ngFor="let item of documentTypesFiltered">
          <span
            *ngIf="item.visible"
            [matTooltip]="addMoreInfo(item.documents)"
            matTooltipClass="tooltip-blue"
            (click)="navigateToForm(item)"
            class="item"
            [ngClass]="{
              'item-dimmed': !item.documents?.length,
              'green-background':
                item.documents?.length &&
                (item.order ?? 0) > 4 &&
                (item.order ?? 0) < 999,
              'orange-background': item.order == 999
            }"
          >
            {{ item.code }}
            <span class="number"> {{ item.documents?.length }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
</div>
