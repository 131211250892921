<div class="card">
  <div class="card-header">
    <span class="card-title">{{ inputData.dialogTitle }}</span>
  </div>
  <div class="card-body">
    <mat-form-field>
      <mat-label>{{ inputData.roleTitle }}</mat-label>
      <mat-select
        [(ngModel)]="userSelected"
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-option
          *ngFor="let supervisor of supervisorsRole"
          [value]="supervisor.user?.name"
        >
          {{ supervisor.user?.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Endstation</mat-label>
      <mat-select
        [(ngModel)]="endstationListSelected"
        (selectionChange)="getInfoEndstations($event.value)"
        multiple
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-option
          *ngFor="let endstation of endstations"
          [value]="endstation.name"
        >
          {{ endstation.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Branchline</mat-label>
      <mat-select
        [(value)]="branchlineListSelected"
        (selectionChange)="getInfoBranchlines($event.value)"
        multiple
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-option
          *ngFor="let branchline of branchlines"
          [value]="branchline.name"
        >
          {{ branchline.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="isSaving"
        (click)="add()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
