import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DocumentTypeCreate, DocumentTypeDelete, DocumentTypeRefresh, DocumentTypeUpdate } from './document-type.action';
import { DocumentType } from 'src/app/services/documents/documents';
import { DocumentsService } from 'src/app/services/documents/documents.service';
import { utils } from 'src/app/modules/libs/utils';

export class DocumentTypeStateModel {
  data: DocumentType[] = [];
}

@State<DocumentTypeStateModel>({
  name: 'DocumentType',
  defaults: {
    data: []
  }
})

@Injectable()
export class DocumentTypeState {
  constructor(
    private service: DocumentsService
  ) { }

  @Selector()
  static read(state: DocumentTypeStateModel) {
    return state.data;
  }

  @Action(DocumentTypeRefresh)
  refresh({ patchState }: StateContext<DocumentTypeStateModel>) {
    this.service.getDocumentTypes().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(DocumentTypeCreate)
  create({ getState, patchState }: StateContext<DocumentTypeStateModel>, { item }: DocumentTypeCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(DocumentTypeUpdate)
  update({ getState, patchState }: StateContext<DocumentTypeStateModel>, { id, item }: DocumentTypeUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    const currentState = utils.cloneDeep(state.data);

    if (index >= 0) {
      currentState.splice(index, 1, utils.cloneDeep(item));
      patchState({ data: currentState });
    }
  }

  @Action(DocumentTypeDelete)
  delete({ getState, patchState }: StateContext<DocumentTypeStateModel>, { id }: DocumentTypeDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
