import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import * as AbsiActions from './absi.action';
import { GeneralSignalRResponse } from "src/app/services/signal-r/general-signal-r-response";
import { SignalRService } from "src/app/services/signal-r/signal-r.service";
import { SignalRAction } from "src/app/services/signal-r/signalr-enums";
import { Channels } from "src/app/store/channels";

@Injectable({
  providedIn: 'root'
})
export class AbsiStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore() {
    this.store.dispatch(new AbsiActions.AbsiRefresh(0));
  }

  initListeners() {
    // Absi
    this.signalRService.hubConnection?.on(Channels.ALLABSIS, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new AbsiActions.AbsiCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new AbsiActions.AbsiUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new AbsiActions.AbsiDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new AbsiActions.AbsiRefresh(data.id));
          break;

        case SignalRAction.RequestRefresh:
          this.store.dispatch(new AbsiActions.AbsiRefreshByID(data.id));

      }
    });

  }
}
