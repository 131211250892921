<div class="d-flex">
  <button
    mat-icon-button
    class="pink"
    (click)="annotate()"
    matTooltip="Add a Suggestion"
  >
    <mat-icon>rate_review</mat-icon>
  </button>
</div>
