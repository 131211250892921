import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChecklistTemplate } from 'src/app/components/checklists/checklists';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { utils } from 'src/app/modules/libs/utils';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';

@Component({
  selector: 'cl-editor-status-change',
  templateUrl: './cl-editor-status-change.component.html',
  styleUrls: ['./cl-editor-status-change.component.scss']
})
export class ClEditorStatusChangeComponent implements OnInit, OnDestroy {

  @Input() disabled!: boolean;
  @Input() configuration?: string;
  @Input() checklistTemplate!: ChecklistTemplate;
  @Input() statusLabel!: string;
  @Input() action!: number;

  @Output() changed = new EventEmitter<any>();

  statusID?: number | null;
  roles?: Role[];
  rolesFiltered?: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.loadRoles();
  }

  ngOnDestroy(): void {
    this.rolesSubs?.unsubscribe();
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      if (data?.length) {
        this.roles = data;
        this.getConfiguration();
      }
    });
  }

  getConfiguration() {
    const configuration = utils.JSONparse(this.configuration);
    this.statusID = configuration.status;
    this.rolesFiltered = this.roles?.filter(r => (configuration.roles as number[])?.includes(r.id)) ?? [];
  }

  statusSelected(statusID: number | null) {
    this.statusID = statusID;
    this.setConfiguration();
  }

  rolesSelected(roles: Role[]) {
    this.rolesFiltered = roles;
    this.setConfiguration();
  }

  setConfiguration() {
    const configuration = { status: this.statusID, roles: this.rolesFiltered?.map(r => r.id) };
    this.changed.emit(configuration);
  }
}
