import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { User } from '../services/user';
import { UsersCreate, UsersDelete, UsersRefresh, UsersUpdate } from './users.action';
import { utils } from 'src/app/modules/libs/utils';
import { UserService } from '../services/user.service';

export class UsersStateModel {
  data: User[] = [];
  minData: User[] = [];
}

@State<UsersStateModel>({
  name: 'Users',
  defaults: {
    data: [],
    minData: []
  }
})

@Injectable()
export class UsersState {
  constructor(
    private _service: UserService
  ) { }

  @Selector()
  static read(state: UsersStateModel) {
    return state.data;
  }

  @Action(UsersRefresh)
  refresh({ patchState }: StateContext<UsersStateModel>) {
    this._service.Read().toPromise().then(
      data => {
        patchState({ data });
        const minData = utils.cloneDeep(data);
        minData?.map(u => u.userRole = []);
        patchState({ minData })
      },
      error => console.error(error)
    );
  }

  @Action(UsersCreate)
  create({ getState, patchState }: StateContext<UsersStateModel>, { item }: UsersCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(UsersUpdate)
  update({ getState, patchState }: StateContext<UsersStateModel>, { id, item }: UsersUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(UsersDelete)
  delete({ getState, patchState }: StateContext<UsersStateModel>, { id }: UsersDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
