export class EB2OtherSystem {
  public id?: number;
  public eB2ID?: number;
  public text?: string;
}

export class EB2OtherSystemSave {
  public id?: number;
  public eB2ID?: number;
  public text?: string;
}

export class EB2OtherSystemHandler {
  static toSave(eb2OtherSystem: EB2OtherSystem): EB2OtherSystemSave {
    var save = new EB2OtherSystemSave();

    save.id = eb2OtherSystem.id;
    save.eB2ID = eb2OtherSystem.eB2ID;
    save.text = eb2OtherSystem.text;

    return save;
  }
}
