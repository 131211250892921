import { ReviewAttendanceRoster } from '../../reviews.models';

export class ReviewAttendanceRosterRefresh {
  static readonly type = '[ReviewAttendanceRoster] Refresh';
}

export class ReviewAttendanceRosterCreate {
  static readonly type = '[ReviewAttendanceRoster] Create';

  constructor(public item: ReviewAttendanceRoster) { }
}

export class ReviewAttendanceRosterUpdate {
  static readonly type = '[ReviewAttendanceRoster] Update';

  constructor(public id: number, public item: ReviewAttendanceRoster) { }
}

export class ReviewAttendanceRosterDelete {
  static readonly type = '[ReviewAttendanceRoster] Delete';
  constructor(public id: number) { }
}
