<button
  *ngIf="SA"
  mat-icon-button
  [matMenuTriggerFor]="menu"
  aria-label="actions"
  (click)="$event.stopPropagation()"
>
  <mat-icon [ngClass]="buttonClass">more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    *ngIf="menuItem.status == 1"
    (click)="changeVisible(menuItem, false); $event.stopPropagation()"
  >
    <mat-icon matListItemIcon class="red">visibility_off</mat-icon>Hide
  </button>
  <button
    mat-menu-item
    *ngIf="menuItem.status == 0"
    (click)="changeVisible(menuItem, true); $event.stopPropagation()"
  >
    <mat-icon matListItemIcon class="blue">visibility</mat-icon>Show
  </button>

  <mat-divider></mat-divider>

  <button
    mat-menu-item
    *ngIf="menuItem.type != menuItemType.Divider"
    (click)="edit(menuItem)"
  >
    <mat-icon matListItemIcon class="yellow">edit</mat-icon>Modify
  </button>
  <button mat-menu-item (click)="create(menuItem)">
    <mat-icon matListItemIcon class="green">add_box</mat-icon>Add Menu Item
  </button>
  <mat-divider></mat-divider>

  <button
    mat-menu-item
    *ngIf="
      index < (menuItems?.length ?? 0) - 1 &&
      orientation == orientationEnum.Horizontal
    "
    (click)="move(menuItem, 1); $event.stopPropagation()"
  >
    <mat-icon matListItemIcon class="orange">east</mat-icon>Move Right
  </button>
  <button
    mat-menu-item
    (click)="move(menuItem, -1); $event.stopPropagation()"
    *ngIf="index > 0 && orientation == orientationEnum.Horizontal"
  >
    <mat-icon matListItemIcon class="orange">west</mat-icon>Move Left
  </button>

  <button
    mat-menu-item
    *ngIf="index > 0 && orientation == orientationEnum.Vertical"
    (click)="move(menuItem, -1); $event.stopPropagation()"
  >
    <mat-icon matListItemIcon class="orange">north</mat-icon>Move Up
  </button>
  <button
    mat-menu-item
    *ngIf="
      index < (menuItems?.length ?? 0) - 1 &&
      orientation == orientationEnum.Vertical
    "
    (click)="move(menuItem, 1); $event.stopPropagation()"
  >
    <mat-icon matListItemIcon class="orange">south</mat-icon>Move Down
  </button>

  <mat-divider></mat-divider>

  <button mat-menu-item (click)="delete(menuItem)">
    <mat-icon matListItemIcon class="red">delete</mat-icon>Remove
  </button>
  <mat-divider *ngIf="orientation == orientationEnum.Vertical"></mat-divider>
  <button
    mat-menu-item
    *ngIf="orientation == orientationEnum.Vertical"
    (click)="addDivider(menuItem); $event.stopPropagation()"
  >
    <mat-icon matListItemIcon class="gray">horizontal_rule</mat-icon>Add Divider
  </button>
</mat-menu>
