import { APLGroupRoleCatalog } from "./apl-group-role-catalog.model";

export class APLGroupRoleRoleCatalog {
  public id!: number;
  public aplGroup!: APLGroupRoleCatalog;

  constructor() {
    this.aplGroup = this.aplGroup ?? new APLGroupRoleCatalog();
  }
}
