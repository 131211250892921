import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener, ElementRef, OnDestroy, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResourceDetailsNewComponent } from '../resource-details-new/resource-details-new.component';
import { KeChecklistBuilderComponent } from '../../../kenable/ke-checklist-builder/ke-checklist-builder.component';
import { ResourcesListNewComponent } from '../resources-list-new/resources-list-new.component';
import { MatTabGroup } from '@angular/material/tabs';
import { PendingResourcesType } from 'src/app/common/enumerations/enumerations';
import { BeamlineShutterRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { BaseComponent } from 'src/app/common/base/base.component';
import { RssTestDatabaseComponent } from '../rss-test-database/rss-test-database.component';

@Component({
  selector: 'app-resources-new',
  templateUrl: './resources-new.component.html',
  styleUrls: ['./resources-new.component.scss']
})
export class ResourcesNewComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild(ResourceDetailsNewComponent) details!: ResourceDetailsNewComponent;
  @ViewChild(KeChecklistBuilderComponent) keBuilder!: KeChecklistBuilderComponent;
  @ViewChild(ResourcesListNewComponent) list!: ResourcesListNewComponent;
  @ViewChild(RssTestDatabaseComponent) rssTestDatabase!: RssTestDatabaseComponent;
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  @ViewChild('divHeader') divHeader!: ElementRef;

  constructor(
    protected override injector: Injector,
    private _route: ActivatedRoute) {
    super(injector);
  }

  public valid = false;
  public isShutter = true;

  public selectedTab = 0;
  beamlineShutter!: BeamlineShutterRelation;
  showPrevResource!: boolean;
  showChangesToggle = false;

  getDetails(e: BeamlineShutterRelation) {
    this.showPrevResource = false;
    this.beamlineShutter = e;
    this.showChangesToggle = this.showChanges();
    // this.details.getDetails(e);
    // this.rssTestDatabase.set(e);
    if (e.shutterId && this.keBuilder)
      this.keBuilder.getDetails(e, this.tabGroup.selectedIndex == 1);
  }

  showChanges() {
    const editPrivilege = this.hasPrivilege(PrivilegeEnum.RSSTestDBEdit);
    const authPrivilege = this.hasPrivilege(PrivilegeEnum.RSSTestDBAuth);
    const blPrivilege = this.hasPrivilege(PrivilegeEnum.BeamlineCatalog);
    if (isNaN(this.selectedTab)) this.selectedTab = 0;
    switch (this.selectedTab) {
      case 0:
        return this.beamlineShutter?.pendingResources?.some(x => (x.type == PendingResourcesType.Details || x.type == PendingResourcesType.Both) && (blPrivilege));
      case 1:
        return false;
      case 2:
        return this.beamlineShutter?.pendingResources?.some(x => (x.type == PendingResourcesType.Rss_Database || x.type == PendingResourcesType.Both) && (editPrivilege || authPrivilege));
    }
    return false;
  }


  ngOnInit() {
    this._route.params.subscribe(params => {
      this.selectedTab = +params['tab'];
    });
  }

  tabClicked(e: any) {
    this.selectedTab = e;
  }
}

export class detailsUsers {
  approverUserName?: string;
  updateUserName?: string;
  approvedOn?: Date | null;
  updatedOn?: Date | null;
}

export class PendingResourceUsers {
  approverId?: number;
  updateById?: number;
  approvedOn?: Date;
  updatedOn?: Date;
}
