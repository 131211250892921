<div class="card">
  <div class="card-header">
    <button
      mat-mini-fab
      class="closex float-right"
      color="warn"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>

    <h5>Review Request</h5>
  </div>
  <div class="card-body">
    <form [formGroup]="reviewRequestForm">
      <div class="row">
        <div class="col-md-12">
          <div class="content d-flex flex-column">
            <div>
              <comments-box
                [textValue]="inputData.reviewCycle.description"
                [disabled]="inputData.isEvaluate"
                title="Description"
                [hasError]="hasError"
                (textChanged)="descriptionChanged($event)"
                formType="reviewRequest"
                serialNo="reviewRequestImages"
              >
              </comments-box>
            </div>
            <div>
              <mat-form-field appearance="outline" class="resource-type">
                <mat-label> Location </mat-label>
                <input
                  type="text"
                  placeholder="Select or enter custom location"
                  matInput
                  name="resourceCtrl"
                  formControlName="resourceCtrl"
                  [matAutocomplete]="autoResource"
                  (keyup)="changedResource($event)"
                  #locationInput
                />
                <mat-autocomplete
                  #autoResource="matAutocomplete"
                  #resourceAutocomplete
                  [displayWith]="displayObject"
                  (optionSelected)="selectedResource($event)"
                >
                  <mat-option
                    *ngFor="let resource of resourcesFiltered"
                    [value]="resource"
                  >
                    {{ resource.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="resourceCtrl?.invalid">
                  You must enter a value
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <mat-label>Proposed Review Date</mat-label>
                <input
                  matInput
                  formControlName="dateCtrl"
                  [matDatepicker]="startDate"
                  placeholder="Proposed Date for Review"
                  name="startDate"
                  (dateChange)="changedDate($event)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="startDate"
                  [min]="todaysDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
                <mat-error *ngIf="dateCtrl?.invalid">
                  You must enter a valid date
                </mat-error>
                <!-- <input
                matInput
                [(ngModel)]="inputData.reviewCycle.proposedTimeline"
                formControlName="proposedTimeline"
              />
              <mat-error *ngIf="proposedTimeline.invalid">{{
                getErrorMsg(proposedTimeline)
              }}</mat-error> -->
              </mat-form-field>
            </div>
            <div>
              <mat-form-field *ngIf="inputData.reviewCycle.serialNo">
                <mat-label>Serial Number</mat-label>
                <input
                  matInput
                  [(ngModel)]="inputData.reviewCycle.serialNo"
                  [disabled]="true"
                  [ngModelOptions]="{ standalone: true }"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions *ngIf="!inputData.isEvaluate" align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!formValid()"
        (click)="submit()"
      >
        Submit
      </button>
    </div>
    <div
      mat-dialog-actions
      *ngIf="
        inputData.isEvaluate &&
        hasPrivilege(privilegeEnum.EvaluateReviewRequest)
      "
      align="center"
    >
      <button
        mat-flat-button
        color="warn"
        (click)="acceptAndReject(false)"
        *ngIf="reviewCycle.status === 0"
      >
        Reject Request <mat-icon>thumb_down_alt</mat-icon>
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="acceptAndReject(true)"
        *ngIf="reviewCycle.status === 0"
      >
        Accept Request <mat-icon>check_circle_outline</mat-icon>
      </button>

      <button
        mat-flat-button
        color="accent"
        (click)="edit()"
        *ngIf="reviewCycle.status === 0"
      >
        Edit <mat-icon>edit</mat-icon>
      </button>

      <button
        mat-flat-button
        color="accent"
        (click)="sendToPending()"
        *ngIf="reviewCycle.status !== 0 && !reviewCycle.reviews?.length"
      >
        Send back to Pending Requests <mat-icon>history</mat-icon>
      </button>
      <button
        mat-stroked-button
        color="primary"
        (click)="archive()"
        [disabled]="reviewCycle.status == reviewCycleStatus.Archived"
        *ngIf="
          reviewCycle.status !== 0 &&
          reviewCycle.status != reviewCycleStatus.Archived &&
          reviewCycle.reviews?.length
        "
      >
        Archive Request <mat-icon>archive</mat-icon>
      </button>
      <button
        mat-stroked-button
        color="primary"
        (click)="reActivate()"
        *ngIf="reviewCycle.status == reviewCycleStatus.Archived"
      >
        Re-Activate Request <mat-icon>archive</mat-icon>
      </button>
    </div>
  </div>
</div>
