<div class="pt-2 mt-3">
  <div class="row no-gutters">
    <div class="col-6">
      <h5 class="section-title">Hold Points</h5>
      <span *ngIf="!holdPoints?.length">No Hold Points found!</span>
    </div>
    <div class="col-6 d-print-none">
      <div class="button-container">
        <div class="d-flex align-items-center">
          <div
            class="button-wrapper mr-2"
            *ngIf="
              (holdPoints?.length ?? 0) > 1 &&
              (privileges?.canAddHoldPoints ||
                privileges?.canAddHoldPointsDraft)
            "
          >
            <button
              *ngIf="!enableReorder"
              mat-icon-button
              [disabled]="
                scfMaster &&
                scfMaster.status == formStatusEnum.Draft &&
                scfMaster.editingBy?.id != currentUser?.id &&
                scfMaster.editingBy?.id
              "
              color="accent"
              matTooltip="Enable Reorder Holdpoints"
              [ngClass]="{
              gray:
                scfMaster &&
                scfMaster.status == formStatusEnum.Draft &&
                scfMaster.editingBy?.id != currentUser?.id &&
                scfMaster.editingBy?.id,
            }"
              (click)="enableReorder = !enableReorder"
            >
              <mat-icon>swipe_vertical</mat-icon>
            </button>
            <button
              mat-mini-fab
              *ngIf="enableReorder"
              [disabled]="
                scfMaster &&
                scfMaster.status == formStatusEnum.Draft &&
                scfMaster.editingBy?.id != currentUser?.id &&
                scfMaster.editingBy?.id
              "
              color="accent"
              matTooltip="Disable Reorder Holdpoints"
              [ngClass]="{
              gray:
                scfMaster &&
                scfMaster.status == formStatusEnum.Draft &&
                scfMaster.editingBy?.id != currentUser?.id &&
                scfMaster.editingBy?.id,
            }"
              (click)="enableReorder = !enableReorder"
            >
              <mat-icon class="white">swipe_vertical</mat-icon>
            </button>
          </div>

          <button
            mat-flat-button
            class="float-right"
            color="accent"
            (click)="createHoldPoint()"
            *ngIf="
              privileges?.canAddHoldPoints || privileges?.canAddHoldPointsDraft
            "
            [disabled]="
              scfMaster &&
              scfMaster.status == formStatusEnum.Draft &&
              scfMaster.editingBy?.id != currentUser?.id &&
              scfMaster.editingBy?.id
            "
          >
            Add Hold Point
          </button>
        </div>

        <div
          *ngIf="
            scfMaster &&
            scfMaster.status == formStatusEnum.Draft &&
            scfMaster.editingBy?.id != currentUser?.id &&
            scfMaster.editingBy?.id
          "
          class="disabled-message"
        >
          You cannot add or modify hold points while the form is being edited
        </div>
      </div>
    </div>
  </div>

  <div
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    class="holdpoints-container"
    cdkDropListLockAxis="y"
  >
    <div
      *ngFor="let hp of holdPoints; let i = index"
      cdkDrag
      class="d-flex align-items-center"
    >
      <button mat-icon-button *ngIf="enableReorder" cdkDragHandle>
        <mat-icon color="accent">reorder</mat-icon>
      </button>
      <div class="row full-width border-hp relative mx-1">
        <span class="holdpoint-title" *ngIf="hp.number"
          >Hold Point {{ hp.number }}</span
        >
        <span class="holdpoint-buttons d-print-none">
          <button
            type="button"
            mat-icon-button
            color="accent"
            (click)="editHoldPoint(hp)"
            *ngIf="
              (privileges?.canAddHoldPoints ||
                privileges?.canAddHoldPointsDraft) &&
              holdPointUnsigned(hp)
            "
            [disabled]="
              scfMaster &&
              scfMaster.status == formStatusEnum.Draft &&
              scfMaster.editingBy?.id != currentUser?.id &&
              scfMaster.editingBy?.id
            "
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            type="button"
            mat-icon-button
            color="warn"
            (click)="deleteHoldPoint(hp)"
            *ngIf="
              (privileges?.canAddHoldPoints ||
                privileges?.canAddHoldPointsDraft) &&
              holdPointUnsigned(hp)
            "
            [disabled]="
              scfMaster &&
              scfMaster.status == formStatusEnum.Draft &&
              scfMaster.editingBy?.id != currentUser?.id &&
              scfMaster.editingBy?.id
            "
          >
            <mat-icon>delete</mat-icon>
          </button>
        </span>
        <div class="col-12 mt-3">
          <div class="holdpoint-comment">
            <span [innerHTML]="hp.description"> </span>
          </div>
          <div class="row px-2 justify-content-center">
            <div
              class="col px-1 pb-2"
              id="{{ s.disapprove }}"
              *ngFor="let s of getSignatures(hp)"
            >
              <button-signature
                #btnSign
                [roleIds]="s.roles"
                [roleCodes]="s.roleCodes"
                [user]="s.approvedBy"
                [title]="s.name"
                [disabled]="s.disabled"
                [value]="s.approved"
                [showApprove]="s.approve"
                [showDisapprove]="s.disapprove"
                [showUnapprove]="s.unapprove"
                [date]="s.approvedOn"
                (approve)="approve(s)"
                (disapprove)="disapprove(s, $event)"
                (unapprove)="unapprove(s)"
                [enabled]="s.enabled"
                [userNames]="s.userNames"
              ></button-signature>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
