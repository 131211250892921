import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { ErrorStateMatcher, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsModule } from '@ngxs/store';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ChecklistComponentsModule } from 'src/app/controls/checklist-components/checklist-components.module';
import { SharedModule } from 'src/app/modules/shared.module';
import { reducers } from 'src/app/store/reducers';
import { config } from 'src/assets/config';
import { AppComponent } from 'src/app/app.component';
import { httpInterceptorProviders } from 'src/app/interceptors/interceptorProviders';
import { ChecklistsModule } from '../checklists/checklists.module';
import { EbSideComponent } from './eb-side/eb-side.component';
import { EbComponent } from './eb.component';
import { EbMainComponent } from './eb-main/eb-main.component';
import { EbMainS1Component } from './eb-main/eb-main-s1/eb-main-s1.component';
import { EbMainS2Component } from './eb-main/eb-main-s2/eb-main-s2.component';
import { EbMainS3Component } from './eb-main/eb-main-s3/eb-main-s3.component';
import { EbMainS4Component } from './eb-main/eb-main-s4/eb-main-s4.component';
import { EbMainS5Component } from './eb-main/eb-main-s5/eb-main-s5.component';
import { EbMainS6Component } from './eb-main/eb-main-s6/eb-main-s6.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

class TouchedErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

@NgModule({
  declarations: [
    EbComponent,
    EbSideComponent,
    EbMainComponent,
    EbMainS1Component,
    EbMainS2Component,
    EbMainS3Component,
    EbMainS4Component,
    EbMainS5Component,
    EbMainS6Component
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    HttpClientModule,
    ScrollingModule,
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatRadioModule,
    // MatFileUploadModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    DragDropModule,
    MatTreeModule,
    MatExpansionModule,
    MatOptionModule,
    MatSidenavModule,
    MatTooltipModule,
    QuillModule.forRoot(),
    SharedModule,
    ChecklistComponentsModule,
    ChecklistsModule
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    httpInterceptorProviders,
    { provide: ErrorStateMatcher, useClass: TouchedErrorStateMatcher }
  ],
  bootstrap: [AppComponent, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EbModule { }
