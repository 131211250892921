import { Restriction } from "src/app/controls/restrictions/services/restriction";

export class FormRestriction {
  public id?: number;
  public formID?: number | null;
  public restrictionID?: number;
  public text?: string | null;
  public restriction?: Restriction;
  public hilite?: boolean | null;
}

export class FormRestrictionSave {
  public id?: number;
  public formID?: number | null;
  public restrictionID?: number;
  public text?: string;
}

export class FormRestrictionHandler {
  static toSave(formRestriction: FormRestriction): FormRestrictionSave {
    var save = new FormRestrictionSave();

    save.id = formRestriction.id;
    save.formID = formRestriction.formID;
    save.restrictionID = formRestriction.restrictionID;
    save.text = formRestriction.text ?? '';

    return save;
  }
}
