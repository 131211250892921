<div class="d-print-flex">
  <mat-form-field
    [ngClass]="{
      hilite: hilite
    }"
  >
    <mat-label>{{ placeholder }}</mat-label>
    <mat-chip-grid #chipList [formControl]="control">
      <mat-chip-row
        *ngFor="let otherSystem of otherSystems"
        [selectable]="true"
        [removable]="true"
        (removed)="remove(otherSystem)"
      >
        {{ otherSystem.text }}
        <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
      </mat-chip-row>
      <input
        #otherSystemInput
        [matChipInputFor]="chipList"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-grid>
    <mat-error *ngIf="control?.invalid">{{ getErrorMsg(control) }}</mat-error>
  </mat-form-field>
</div>
