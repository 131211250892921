<div class="card">
  <div class="card-header">
    <span class="card-title">
      {{
        templateType.id ? "Edit Template Type" : "Create Template Type"
      }}</span
    >
  </div>
  <div class="card-body">
    <form [formGroup]="formGroup" class="h-90">
      <div class="row mt-2">
        <div class="col-5">
          <mat-form-field>
            <mat-label>Template Name</mat-label>
            <input
              matInput
              [(ngModel)]="templateType.name"
              formControlName="templateTypeNameCtrl"
            />
            <mat-hint>How is displayed on Dropdowns</mat-hint>
            <mat-error *ngIf="templateTypeNameCtrl?.invalid">{{
              getErrorMsg(templateTypeNameCtrl)
            }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Template Description</mat-label>
            <input
              matInput
              [(ngModel)]="templateType.description"
              formControlName="templateTypeDescriptionCtrl"
            />
            <mat-hint>Full description</mat-hint>
            <mat-error *ngIf="templateTypeDescriptionCtrl?.invalid">{{
              getErrorMsg(templateTypeDescriptionCtrl)
            }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Template Code</mat-label>
            <input
              matInput
              [(ngModel)]="templateType.code"
              formControlName="templateTypeCodeCtrl"
            />
            <mat-hint>To be used on Serial Numbers</mat-hint>
            <mat-error *ngIf="templateTypeCodeCtrl?.invalid">{{
              getErrorMsg(templateTypeCodeCtrl)
            }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-7 pr-4" *ngIf="documentType?.id != 11">
          <mat-slide-toggle
            color="warn"
            (change)="rtToggleChanged($event)"
            formControlName="rtToggleCtrl"
            >Link to Resource <br /><span class="mat-hint"
              >Set ON if the Templates created for this Template Type will be
              based on a Resource table independent from Schedule</span
            >
          </mat-slide-toggle>
          <mat-form-field *ngIf="rtToggle" class="no-subscript">
            <mat-label>Select Resource Type</mat-label>
            <mat-select
              [(value)]="templateType.resourceTypeID"
              formControlName="resourceTypeCtrl"
              (selectionChange)="onResourceTypeChange($event)"
            >
              <mat-option
                [value]="itemList.id"
                *ngFor="let itemList of resourceTypes"
                >{{ itemList?.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="resourceTypeCtrl?.invalid">{{
              getErrorMsg(resourceTypeCtrl)
            }}</mat-error>
          </mat-form-field>
          <mat-slide-toggle
            color="warn"
            [(ngModel)]="rxToggle"
            (change)="rxToggleChanged($event)"
            formControlName="rxToggleCtrl"
            >Checklist Execution has Review Process <br /><span class="mat-hint"
              >Set ON to enable Review and Approval workflow in Checklist
              Execution</span
            >
          </mat-slide-toggle>
          <mat-form-field
            class="no-subscript"
            *ngIf="this.templateType.reviewInExec"
          >
            <mat-label>Roles to Review the Checklist</mat-label>
            <mat-chip-grid #chipListRole aria-label="Role selection">
              <mat-chip-row
                *ngFor="let item of templateRolePermissions"
                [selectable]="false"
                [removable]="true"
                (removed)="removeRole(item)"
              >
                {{ item.role.code }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-row>
              <input
                formControlName="roleCtrl"
                [matAutocomplete]="autoRole"
                [matChipInputFor]="chipListRole"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (keyup)="onRoleChange($event)"
              />
            </mat-chip-grid>
            <mat-autocomplete
              #autoRole="matAutocomplete"
              (optionSelected)="selectedRole($event)"
              #roleAutocomplete
            >
              <mat-option *ngFor="let role of rolesFiltered" [value]="role">
                {{ role.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error>You must choose at least one Role</mat-error>
          </mat-form-field>
          <mat-slide-toggle
            color="warn"
            [(ngModel)]="aiToggle"
            (change)="aiToggleChanged($event)"
            formControlName="aiToggleCtrl"
            >Allow enter Action Items in Checklist <br /><span class="mat-hint"
              >Set ON to enable Action Items creation in Checklist Execution.
              (Mostly used for ARC/BRC)</span
            >
          </mat-slide-toggle>
          <mat-slide-toggle
            color="warn"
            [(ngModel)]="atToggle"
            (change)="atToggleChanged($event)"
            formControlName="atToggleCtrl"
            >All Task Should be Checked or signed to Enable Close Checklist<br /><span
              class="mat-hint"
              >Set ON to Require All Tasks Checked or Signed before Closing the
              Checklist</span
            >
          </mat-slide-toggle>
          <mat-slide-toggle
            color="warn"
            [(ngModel)]="scToggle"
            (change)="scToggleChanged($event)"
            formControlName="scToggleCtrl"
            >Link to Schedule <br /><span class="mat-hint"
              >Set ON to use Schedule based Location list in Template
              Builder</span
            >
          </mat-slide-toggle>
          <mat-form-field *ngIf="scToggle" class="pt-2 no-subscript">
            <mat-label>Select Schedule Type</mat-label>
            <mat-select
              [(value)]="templateType.scheduleTypeID"
              formControlName="scheduleTypeCtrl"
              (selectionChange)="onScheduleTypeChange($event)"
            >
              <mat-option
                [value]="itemList.id"
                *ngFor="let itemList of scheduleTypes"
                >{{ itemList?.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="scheduleTypeCtrl?.invalid">{{
              getErrorMsg(scheduleTypeCtrl)
            }}</mat-error>
          </mat-form-field>
          <mat-form-field
            *ngIf="scToggle && scheduleSubtypes?.length"
            class="pt-2 no-subscript"
          >
            <mat-label>Select Schedule Subtype</mat-label>
            <mat-select
              [(value)]="templateType.scheduleSubtypeID"
              formControlName="scheduleSubtypeCtrl"
              (selectionChange)="onScheduleSubtypeChange($event)"
            >
              <mat-option
                [value]="itemList.id"
                *ngFor="let itemList of scheduleSubtypes"
                >{{ itemList?.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="scheduleSubtypeCtrl?.invalid">{{
              getErrorMsg(scheduleSubtypeCtrl)
            }}</mat-error>
          </mat-form-field>

          <mat-slide-toggle
            *ngIf="scToggle"
            color="accent"
            [(ngModel)]="scToggleHide"
            (change)="scToggleHideChanged($event)"
            formControlName="scToggleHideCtrl"
            >Show Custom Schedules in Resource List <br /><span class="mat-hint"
              >Set ON to display Custom Locations from Schedule list inclusive,
              OFF to display only pre-defined Locations in Resources
              catalogs.</span
            >
          </mat-slide-toggle>
          <mat-slide-toggle
            color="warn"
            [(ngModel)]="mvToggle"
            (change)="mvToggleChanged($event)"
            formControlName="mvToggleCtrl"
            >Allow Mutiple Active Versions<br /><span class="mat-hint"
              >Set ON to Allow keep multiple Active Versions of a Template in
              checklist Builder</span
            >
          </mat-slide-toggle>
        </div>
      </div>
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        class="cancel-btn"
        color="warn"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        class="accept-btn"
        color="primary"
        (click)="add()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
