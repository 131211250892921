import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-text',
  templateUrl: './cl-text.component.html',
  styleUrls: ['./cl-text.component.scss']
})
/** cl-text component*/
export class ClTextComponent {

  @Input() text?: string | null;
  @Input() label?: string | null = '#';
  @Input() editor?: boolean;

  /** cl-text ctor */
  constructor() {

  }
}
