import { Component, ElementRef, HostListener, Injector, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormType, FormStatusEnum, LoadingOrigin } from 'src/app/common/enumerations/enumerations';
import { CanComponentDeactivate } from 'src/app/common/guards/unsaved-changes.guard';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { FormService } from 'src/app/controls/form-table/form.service';
import { FormsStoreAndListenersService } from 'src/app/controls/form-table/store/forms.store';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { utils } from 'src/app/modules/libs/utils';
import { Form, FormHandler } from 'src/app/services/forms/form';
import { FormVersion } from 'src/app/services/forms/form-version';
import { FormVersionService } from 'src/app/services/forms/form-version.service';
import { SequenceNumber } from 'src/app/services/forms/sequence-number';
import { PpstbSideComponent } from './ppstb-side/ppstb-side.component';
import { PpstbMainComponent } from './ppstb-main/ppstb-main.component';
import { PpstbService } from './ppstb.service';
import { PPSTB } from './ppstb';
import { FormsUpdate } from 'src/app/controls/form-table/store/forms.action';

@Component({
  selector: 'app-ppstb',
  templateUrl: './ppstb.component.html',
  styleUrls: ['./ppstb.component.scss']
})
export class PpstbComponent extends BaseComponent implements OnChanges, CanComponentDeactivate {
  @ViewChild("sidenav") sidenav!: MatSidenav;
  @ViewChild("headerTop") headerTop!: ElementRef;
  @ViewChild(PpstbSideComponent) side!: PpstbSideComponent;
  @ViewChild(PpstbMainComponent) main!: PpstbMainComponent;

  disabled: boolean = false;

  resourceRequired: boolean = false;

  filterCtrl = new FormControl();

  errorMessages: string[] = [];
  headerOnTop: boolean = false;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter: any[] = [];
  currentFilter: any;
  filter: any;

  form?: Form | null;
  forms: Form[] = [];
  forms$!: Observable<Form[]>;
  formsSubs: Subscription = new Subscription;

  loadingMessage!: string;

  showAmendmentToggle: boolean = false;
  showAmendment: boolean = false;
  //////

  constructor(
    public service: PpstbService,
    private formService: FormService,
    private formVersionService: FormVersionService,
    private formStore: FormsStoreAndListenersService,
    protected override injector: Injector
  ) {
    super(injector);
    this.formStore.initStore(FormType.PPSTB);
  }

  ngAfterViewInit(): void {
    this.setHeights();
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit() {
    this.moduleTitle = 'Personnel Protection System Temporary Bypass';
    this.loadForms();
  }

  loadForms() {
    this.forms$ = this.store.select(state => state.Forms.data);
    this.formsSubs = this.forms$.subscribe(data => {
      if (data?.length) {
        this.forms = data;
        if (this.form) {
          const form = this.forms.find(f => f.id == this.form?.id);
          if (form) {
            this.form = form;
            this.rowSelected(this.form);
          }
        }
      }
    });
  }

  showStatusChangeMessage(form: Form) {
    this.alert.message('formStateChanged',
      [new MessagePlaceholder("{serialNo}", form.sequenceNumber.number), new MessagePlaceholder("{status}", form.formVersion.status.name ?? 'New'),]);
  }

  override ngOnDestroy(): void {
    this.formsSubs?.unsubscribe();
  }

  clear() {
    this.errorMessages = [];
  }

  async create() {
    if (await this.canDeactivate()) {
      const user = this.getCurrentUser();
      this.setLoading(true, this.loadingOrigin.Self);
      const serialNo = await this.formService.getSerialNo(FormType.PPSTB).toPromise();
      if (serialNo && user) {
        this.form = {
          id: 0,
          type: FormType.PPSTB,
          sequenceNumber: new SequenceNumber,
          formVersion: new FormVersion,
          editingById: user.id,
          editingBy: user,
          createdByUser: user,
          createdOn: new Date(),
          date: new Date()
        } as Form;
        this.form.sequenceNumber.number = serialNo?.serialNo;
        this.form.formVersion.statusID == FormStatusEnum.New;
        this.form.formVersion.operatorInitiatingForm = user;
        this.form.formVersion.operatorInitiatingFormID = user.id ?? 0;
        this.form.formVersion.ppstb = new PPSTB;

        this.side.formTable.form = undefined;
        this.setLoading(false, this.loadingOrigin.Self);
        this.setFormDirty();
        this.disabled = false;
      }
    }
  }

  formCancelled() {
    this.form = null;
    this.side.form = null;
    this.side.formTable.form = undefined;
  }

  async edit(value: boolean) {
    if (!value && await this.canDeactivate() || value)
      if (this.form?.id) {
        this.setLoading(true, this.loadingOrigin.Self);
        await this.formService.setEditing(this.form.id, value).toPromise();
        this.setLoading(false, this.loadingOrigin.Self);
      }
  }

  rowSelected(e: Form | any) {
    if (this.form?.id != e.id)
      this.scrollToTop('sidenav-content');
    this.form = e as Form;
    this.showAmendment = false;
    this.createdOn = moment(this.form?.createdOn).format("MMM-DD-YYYY");
    this.setHeights();
    if (this.form.editingBy && this.form.formVersion.statusID == FormStatusEnum.Amendment) this.showAmendment = true;
  }

  amendmentEnabled() {
    return (this.form &&
      this.main?.section1?.amendmentSignature?.amendmentEnabled &&
      this.hasPrivilege(this.privilegeEnum.PPSTBRequestAmendment) &&
      this.form.formVersion.statusID != FormStatusEnum.Amendment &&
      this.form.formVersion.statusID != FormStatusEnum.RestrictionsAmendment &&
      !this.form.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 4)?.wfSignatures?.some(s => s.signed)) ?? false;
  }

  async duplicate() {
    if (await this.canDeactivate()) {
      let form = new Form(FormType.PPSTB);
      const user = this.getCurrentUser();
      this.setLoading(true, this.loadingOrigin.Self);
      const serialNo = await this.formService.getSerialNo(FormType.PPSTB).toPromise();
      if (serialNo && user && this.form) {
        // this.sidePanel.selectedRowIndex = -1;
        form = {
          id: 0,
          type: FormType.PPSTB,
          sequenceNumber: new SequenceNumber,
          formVersion: new FormVersion,
          editingById: user.id,
          createdBy: user.id,
          createdOn: new Date(),
          date: new Date()
        } as Form;
        form.sequenceNumber.number = serialNo?.serialNo;
        form.formVersion.statusID == FormStatusEnum.Draft;
        form.formVersion.operatorInitiatingFormID = user.id ?? 0;
        form.formVersion.requesterID = this.form.formVersion.requesterID;

        const ppstb = utils.cloneDeep(this.form.formVersion.ppstb);
        ppstb.ppstbAcceleratorLocations.map(x => { x.id == 0; x.ppstbID = 0; });
        ppstb.ppstbOtherSystems.map(x => { x.id = 0; x.ppstbID = 0; });
        ppstb.ppstbRelatedLinks.map(x => { x.formID = 0; x.id = 0; });
        ppstb.ppstbRestrictions.map(x => { x.id = 0; x.formID = 0; });
        ppstb.ppstbShutters.map(x => { x.ppstbID = 0; x.id = 0; });
        ppstb.ppstbOtherFollowUps = [];
        ppstb.other = false;
        ppstb.modifyInterlock = false;
        ppstb.repairReplaceComponent = false;
        ppstb.id = 0;

        form.formVersion.id = 0;
        form.formVersion.ppstb = ppstb;
        this.setFormDirty();

        this.formService.create(FormHandler.toSave(form))?.subscribe(
          (data: Form) => {
            this.setOpenDocument(data.type, data.id);
            this.form = data;
            this.alert.message('DraftSaved', [
              new MessagePlaceholder("{serialNo}", data.sequenceNumber.number),
            ]);
            this.side.formCreated(this.form);
            this.setLoading(false, this.loadingOrigin.Self);
          },
          (error) => {
            console.error(error);
            this.alert.defaultError();
            this.setLoading(false, this.loadingOrigin.Self);
          }
        );

        this.disabled = false;
      }
    }
  }

  requestAmendment() {
    if (this.form?.wfTableID) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: "500px",
        data: {
          message: this.getMessage('request_amendment').description,
          icon: "warn",
        },
      });

      confirm.afterClosed().subscribe((data) => {
        if (data && this.form) {
          this.setLoading(true, this.loadingOrigin.Self);
          this.formVersionService.newVersion(this.form.formVersion.id).subscribe(async (formVersion: FormVersion) => {
            if (this.form && formVersion && this.main?.section1?.amendmentSignature) {
              this.form.formVersion = utils.cloneDeep(formVersion);
              await this.service.sign(this.form, this.main.section1.amendmentSignature.taskID, this.main.section1.amendmentSignature.amendmentID);
            }
            this.setLoading(false, this.loadingOrigin.Self);
          },
            (error) => {
              console.error(error);
              this.alert.defaultError();
              this.setLoading(false, this.loadingOrigin.Self);
            }
          );
        }
      });
    }
  }

  editAmendment() {
    if (this.form?.id && this.currentUser) {
      this.form.editingBy = this.currentUser;
      this.form.editingById = this.currentUser.id;
      this.store.dispatch(new FormsUpdate(this.form.id, this.form));
      this.showAmendment = true;
      this.formService.setEditing(this.form.id, true).toPromise();
    }
  }

  changeAmendmentToggle(e: any) {
    this.showAmendment = e.checked;
  }


  @HostListener("window:resize")
  onResize() {
    this.innerWidth = window.innerWidth;
    // if (this.isExpanded != false) {

    // }
    if (this.innerWidth < 768) {
      this.isExpanded = false;
    }
  }

  @HostListener("window:scroll")
  isHeaderOnTop() {
    let elemRec;
    if (this.headerTop?.nativeElement !== undefined) {
      elemRec = this.headerTop.nativeElement.getBoundingClientRect();
      const docViewTop = window.screenTop;
      const elemTop = elemRec.top;
      if (elemTop <= docViewTop) {
        this.headerOnTop = true;
      } else {
        this.headerOnTop = false;
      }
    } else {
      this.headerOnTop = false;
    }
  }

  //////
  changedFilter() { }
  clearFilter() { }
  selectedFilter() { }
  createDisabled() { }
  editDisabled() { }
  action() { }

  // settings() {
  //   const settings = this.dialog.open(ScfV2SettingsComponent, {
  //     width: "70vw", minWidth: "70vw", minHeight: "60vh", disableClose: true,
  //   });
  //   settings.afterClosed().subscribe(() => { });
  // }

  applyFilter(e: any) {
    if (!e)
      this.valueFilter = '';
    this.filter = e;
  }

  buttonsVisible: boolean | null = false;

  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 2000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Buttons:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Main:
          this.loadingMain = e as boolean;
          break;
      }
      console.log('main: ' + this.loadingMain + '   buttons: ' + this.loadingButtons + '   side: ' + this.loadingSide + '   Self: ' + this.loadingSelf);

      this.service.loading = this.loadingMain || this.loadingButtons || this.loadingSide || this.loadingSelf;
    }, 100);

  }

  loadingSide = false;
  loadingSelf = false;
  loadingButtons = false;
  loadingMain = false;
  loadingOrigin = LoadingOrigin;

}
