import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { BaseComponent } from 'src/app/common/base/base.component';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';

@Component({
  selector: 'user-chip',
  templateUrl: './user-chip.component.html',
  styleUrls: ['./user-chip.component.scss']
})
export class UserChipComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() control!: FormControl;
  @Input() relatedId!: number;
  @Input() selectedUsers!: any[];
  @Input() aplGroup: any;

  @Output() error = new EventEmitter<string>();
  @Output() edit = new EventEmitter();

  @ViewChild('userAutocomplete') userAutocomplete!: MatAutocomplete;
  @ViewChild('userInput') userInput!: ElementRef<HTMLInputElement>;

  allUsers!: User[];
  availableUsers!: User[];
  filteredUsers!: User[];

  ngOnInit(): void {
    if (this.control == null) this.control = new FormControl();
    this.availableUsers = [];
    this.filteredUsers = [];
    this.allUsers = this.getUsers().filter(u => u.status == 1);
    this.availableUsers = this.allUsers;
    this.filteredUsers = this.allUsers;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('selectedUsers' in changes) {
      this.setAvailableUsers();
      this.clear();
    }
  }

  selected(user: User) {
    let userRelated = {
      userID: user.id,
      user: user,
      aplGroupID: this.relatedId
    }
    this.selectedUsers.push(userRelated);
    this.setAvailableUsers();
    this.clear();
    let algo = this.aplGroup;
    this.edit.emit();
  }

  remove(user: User) {
    const index = this.selectedUsers.indexOf(user);

    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
      this.setAvailableUsers();
      this.clear();
      this.edit.emit();
    }
  }

  clear() {
    if (this.userInput != null) this.userInput.nativeElement.value = '';
    if (this.control != null) this.control.setValue(null);
  }

  setAvailableUsers() {
    this.availableUsers = [];
    if (this.allUsers == null) this.allUsers = [];
    if (this.selectedUsers == null) this.selectedUsers = [];
    this.availableUsers = this.utils.cloneDeep(this.allUsers);
    this.selectedUsers.map(x => {
      const index = this.availableUsers.map(y => y.id).indexOf(x.userID);

      if (index >= 0) {
        this.availableUsers.splice(index, 1);
      }
    });
    this.filteredUsers = [];
    this.filteredUsers = this.utils.cloneDeep(this.availableUsers);
  }

  onUserInputKeyup(e: any) {
    const text = e.target.value;
    this.filteredUsers = this.availableUsers.filter(x => x.name?.trim().toLowerCase().includes(text.trim().toLowerCase()));
  }
}
