
import { HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { throwError, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

export abstract class GenericHttpService<T, K> {

  protected componentMethodCallSource = new Subject<any>();
  componentMethodCalled$ = this.componentMethodCallSource.asObservable();

  protected rootURL: string;
  protected genericMsgError: string;
  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    observe: 'body' as const,
  };

  constructor(
    private httpClient: HttpClient,
    private endpoint: string
  ) {
    this.rootURL = environment.urls.baseApiUrl + this.endpoint;
    this.genericMsgError = environment.messages.genericError;
  }

  create(data: T): Observable<T> {
    return this.httpClient.post<T>(this.rootURL, data, this.httpOptions).pipe(catchError(e => this.handleError(e)));
  }

  readAll(): Observable<T[]> {
    return this.httpClient.get<T[]>(this.rootURL, this.httpOptions).pipe(catchError(e => this.handleError(e)));
  }

  read(id: K): Observable<T> {
    const url = this.rootURL + '/' + id;
    return this.httpClient.get<T>(url, this.httpOptions).pipe(catchError(e => this.handleError(e)));
  }

  update(data: T): Observable<T> {
    return this.httpClient.put<T>(this.rootURL, data, this.httpOptions).pipe(catchError(e => this.handleError(e)));
  }

  delete(id: K): Observable<any> {
    const url = this.rootURL + '/' + id;
    return this.httpClient.delete<T>(url, this.httpOptions).pipe(catchError(e => 'Delete: ' + id));
  }

  public handleError(error: HttpErrorResponse) {
    let result: string = '';
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      result = '';
      if (error.message) {
        result += ' msg: ' + error.message + '\n';
      }
      if (error.error) {
        result += 'full error: ' + JSON.stringify(error.error);
        if (error.error.result) {
          result += ' result: ' + error.message + '\n';
        }
        if (error.error.message) {
          result += ' err msg: ' + error.error.message + '\n';
        }
      }
    }
    if (typeof error?.error === 'string') {
      this.callComponentMethod(error.error);
    } else {
      this.callComponentMethod(this.genericMsgError);
    }
    console.log('Error in handler ');
    console.error(result);
    return throwError(this.genericMsgError);
  }

  // Service message commands
  callComponentMethod(error: string) {
    this.componentMethodCallSource.next(error);
  }

}
