<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-md-1 pt-2">
        <h6>{{ title }}</h6>
      </div>
      <div class="col-12 col-md-11 pt-2">
        <div class="d-flex justify-content-between">
          <mat-label
            [innerHTML]="question ?? '' | placeholderHighlight : editor"
          ></mat-label>
          <div class="d-flex flex-row justify-content-end align-items-baseline">
            <role-chips [roleCodes]="roleCodes" class="mr-3"></role-chips>
            <div class="d-flex align-items-end justify-content-between">
              <div class="text-right" style="width: 100%">
                <mat-icon
                  *ngIf="warning && checkboxVisible"
                  color="warn"
                  class="warn"
                  [matTooltip]="moreInfo ?? ''"
                  matTooltipPosition="left"
                  >warning</mat-icon
                >
                <div
                  class="d-flex align-content-end flex-wrap justify-content-end"
                >
                  <div *ngFor="let o of options" class="pl-2">
                    <mat-form-field class="no-subscript">
                      <mat-label
                        [innerHTML]="o.label | placeholderHighlight : editor"
                      >
                      </mat-label>
                      <input
                        matInput
                        type="text"
                        [disabled]="disabled ?? false"
                        [(ngModel)]="o.text"
                        (change)="textChanged(o)"
                      />
                      <mat-hint>{{ o?.user?.initials }}</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!-- <mat-label class="text-black-50 small float-right">{{
                user?.initials
              }}</mat-label> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
