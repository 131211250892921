<form-restriction-table
  [formType]="formTypeEnum.SCF"
  [formID]="formID"
  [formRestrictions]="formRestrictions"
  customTitle="Operational Restrictions"
  [disabled]="disabled"
  placeholder="Add Restriction"
  [locationIDs]="locationIDs"
  (changed)="restrictionsChanged($event)"
  [hilite]="diffRestrictions()"
></form-restriction-table>
