import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DeletedSectionsRefresh } from './deleted-section.action';
import { WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';
import { ChecklistBuilderService } from '../../checklist-builder/checklist-builder.service';

export class DeletedSectionsStateModel {
  data: WFSectionLocalResource[] = [];
}

@State<DeletedSectionsStateModel>({
  name: 'DeletedWFSectionLocals',
  defaults: {
    data: [],
  },
})
@Injectable()
export class DeletedSectionsState {
  constructor(private service: ChecklistBuilderService) { }

  @Selector()
  static read(state: DeletedSectionsStateModel) {
    return state.data;
  }

  @Action(DeletedSectionsRefresh)
  refresh({ patchState }: StateContext<DeletedSectionsStateModel>, { checklistTemplateID }: DeletedSectionsRefresh) {
    this.service.getDeletedSections(checklistTemplateID).toPromise().then((data) => {
      patchState({ data });
    },
      (error) => console.error(error)
    );
  }

}
