import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Schedule } from '../../models/schedule';

@Component({
  selector: 'app-complete-schedule',
  templateUrl: './complete-schedule.component.html',
  styleUrls: ['./complete-schedule.component.scss']
})
export class CompleteScheduleComponent implements OnInit {
  formComplete!: FormGroup;

  constructor(public formBuilder: FormBuilder, public dialogRef: MatDialogRef<CompleteScheduleComponent>, @Inject(MAT_DIALOG_DATA) public data: Schedule) {
    if (data?.name) {
      data.name = data.name.replace(/<br>/g, ', ');
    }
  }

  ngOnInit(): void {
    this.formComplete = this.formBuilder.group({
      date: [null, Validators.required]
    });
    this.formComplete.controls['date'].setValue(Date.now());
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    const value2 = this.formComplete.controls['date'].value as string;
    const value = Date.parse(value2);
    if (Number.isNaN(value)) {
      this.formComplete.controls['date'].setErrors({ required: true });
      return;
    }
    this.dialogRef.close(this.formComplete.controls['date'].value as Date);
  }

}
