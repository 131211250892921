import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ProcedureTemplateMaster } from "../models/procedure-template-master.model";
import { ProcedureTemplate } from "../models/procedure-template.model";


@Injectable({
  providedIn: 'root'
})
export class ProcedureTemplateService {
  private url: string = environment.urls.baseApiUrl + '/ProcedureTemplate';

  constructor(
    private http: HttpClient,
  ) { }

  createProcedureTemplateMaster(procedureTemplateMaster: ProcedureTemplateMaster): Observable<ProcedureTemplateMaster> {
    return this.http.post<ProcedureTemplateMaster>(this.url + '/Master', procedureTemplateMaster);
  }

  readProcedureTemplateMaster(): Observable<ProcedureTemplateMaster[]> {
    return this.http.get<ProcedureTemplateMaster[]>(this.url + '/Master');
  }

  updateProcedureTemplateMaster(id: number, procedureTemplateMaster: ProcedureTemplateMaster): Observable<ProcedureTemplateMaster> {
    return this.http.put<ProcedureTemplateMaster>(this.url + '/Master/' + id.toString(), procedureTemplateMaster);
  }

  deleteProcedureTemplateMaster(id: number): Observable<any> {
    return this.http.delete<any>(this.url + '/Master/' + id.toString());
  }

  createProcedureTemplate(procedureTemplate: ProcedureTemplate): Observable<ProcedureTemplate> {
    return this.http.post<ProcedureTemplate>(this.url, procedureTemplate);
  }

  updateProcedureTemplate(id: number, procedureTemplate: ProcedureTemplate): Observable<ProcedureTemplate> {
    return this.http.put<ProcedureTemplate>(this.url + '/' + id.toString(), procedureTemplate);
  }

  deleteProcedureTemplate(id: number): Observable<ProcedureTemplate> {
    return this.http.delete<any>(this.url + '/' + id.toString());
  }
}
