import { Component } from '@angular/core';

@Component({
  selector: 'waiting-dots',
  templateUrl: './waiting-dots.component.html',
  styleUrls: ['./waiting-dots.component.scss']
})
export class WaitingDotsComponent {

}
