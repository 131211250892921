import { ModelBase } from "src/app/common/models/model-base";
import { User } from "src/app/components/catalogs/user-catalog/services/user";


export interface RadMeterType extends ModelBase {
  radMeters?: RadMeter[];
}

export interface RadMeterUnit extends ModelBase {
  radMeters?: RadMeter[];
}

export interface RadMeterModel extends ModelBase {
  radMeters?: RadMeter[];
}

export interface RadMeter extends ModelBase {
  serialNumber: string;
  calibrationDueDate?: Date | null;
  calibrationDueDateChangedByID?: number | null;
  calibrationDueDateChangedBy?: User | null;
  calibrationDueDateChangedOn?: Date | null;
  sourceReading: string | null;
  sourceReadingChangedByID?: number | null;
  sourceReadingChangedBy?: User | null;
  sourceReadingChangedOn?: Date | null;
  backgroundReading: string | null;
  backgroundReadingChangedByID?: number | null;
  backgroundReadingChangedBy?: User | null;
  backgroundReadingChangedOn?: Date | null;
  radMeterTypeID?: number;
  radMeterType?: RadMeterType;
  radMeterUnitID?: number;
  radMeterUnit?: RadMeterUnit;
  radMeterModelID?: number;
  radMeterModel?: RadMeterModel;
}

export class RadMeterData {
  radMeter!: RadMeter;
}
