<div class="card-header">
  <mat-progress-bar mode="indeterminate" *ngIf="saving"></mat-progress-bar>
  <h5>
    Radiation Areas <span class="small">{{ subtitle }}</span>
  </h5>
</div>
<div class="card-body">
  <mat-form-field hintLabel="Max 100 characters" appearance="outline">
    <mat-label>Title</mat-label>
    <textarea
      [disabled]="saving"
      required
      rows="1"
      maxlength="100"
      [(ngModel)]="Name"
      matInput
    ></textarea>
    <mat-hint align="end">{{ Name.length || 0 }}/100</mat-hint>
    <mat-error *ngIf="Name?.length == 0 || !Name"
      >You must enter a value</mat-error
    >
  </mat-form-field>

  <mat-form-field hintLabel="Max 1000 characters" appearance="outline">
    <mat-label>Description</mat-label>
    <textarea
      [disabled]="saving"
      required
      rows="3"
      maxlength="1000"
      [(ngModel)]="Description"
      matInput
    ></textarea>
    <mat-hint align="end">{{ Description.length || 0 }}/1000</mat-hint>
    <mat-error *ngIf="Description?.length == 0 || !Description"
      >You must enter a value</mat-error
    >
  </mat-form-field>
  <!-- Image uploads -->
  <div>
    <div [ngClass]="{ invisible: !radArea?.files?.length }">
      <mat-file-list
        [radArea]="radArea"
        [uploadId]="radAreaUploadID"
        [disabled]="false"
        [save_files_database]="save_files_database"
        [typeDocument]="typeDocument"
        [upload_when_selected]="false"
        (changed)="changed()"
        (selectedFilesChanged)="getSelectedFiles($event)"
      ></mat-file-list>
    </div>
    <div [ngClass]="{ invisible: radArea?.files?.length }">
      <mat-file-v2-upload
        (selectedFilesChanged)="getSelectedFiles($event)"
        [uploadId]="radAreaUploadID ?? ''"
        [upload_when_selected]="false"
        [save_files_database]="save_files_database"
        [typeDocument]="typeDocument"
      ></mat-file-v2-upload>
    </div>
  </div>
</div>
<div class="card-footer">
  <div mat-dialog-actions align="center">
    <button
      mat-flat-button
      [disabled]="saving"
      color="warn"
      class="col-3"
      (click)="Cancel()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      [disabled]="saving"
      color="primary"
      class="col-3"
      (click)="Save()"
    >
      Save
    </button>
  </div>
</div>
