import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { DocumentsCreate, DocumentsDelete, DocumentsRefresh, DocumentsUpdate, DocumentsRequestRefresh, DocumentsUpdateAll } from './documents.action';
import { Document } from '../documents';
import { DocumentsService } from '../documents.service';


export class DocumentsStateModel {
  data: Document[] = [];
}

@State<DocumentsStateModel>({
  name: 'Documents',
  defaults: {
    data: []
  }
})
@Injectable()
export class DocumentsState {
  constructor(
    private _service: DocumentsService,
    private store: Store
  ) { }

  @Selector()
  static read(state: DocumentsStateModel) {
    return state.data;
  }

  @Action(DocumentsRefresh)
  refresh({ patchState }: StateContext<DocumentsStateModel>) {
    this._service.getDocuments().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(DocumentsRequestRefresh)
  refreshById({ getState, patchState }: StateContext<DocumentsStateModel>, { item }: DocumentsRequestRefresh) {
    this._service.getDocument(item).subscribe(
      data => {
        const state = getState();
        if (data.id) {
          const index = state.data.map(x => x.id).indexOf(data.id);

          if (index >= 0)
            state.data.splice(index, 1);

          patchState({
            data: [...state.data, data]
          });
        }
        else {
          this.store.dispatch(new DocumentsDelete(item))
        }
      },
      error => console.error(error)
    );
  }

  @Action(DocumentsCreate)
  create({ getState, patchState }: StateContext<DocumentsStateModel>, { item }: DocumentsCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(DocumentsUpdate)
  update({ getState, patchState }: StateContext<DocumentsStateModel>, { id, item }: DocumentsUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(DocumentsUpdateAll)
  updateAll({ patchState }: StateContext<DocumentsStateModel>, { data }: DocumentsUpdateAll) {
    patchState({ data });
  }

  @Action(DocumentsDelete)
  delete({ getState, patchState }: StateContext<DocumentsStateModel>, { item }: DocumentsDelete) {
    const state = getState();
    const index = state.data.findIndex(d => d.formID == item.id && d.type == item.formType && d.category == item.catgegory);

    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({ data: state.data });
  }

}
