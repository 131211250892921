<div class="card">
  <div class="card-header">
    <span class="card-title" *ngIf="data"> {{ data.name }}</span>
  </div>
  <div class="card-body">
    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Note</mat-label>
            <textarea matInput [formControl]="descriptionCtrl"></textarea>
            <mat-error *ngIf="descriptionCtrl.invalid"
              >Description is required</mat-error
            >
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="!descriptionCtrl.valid"
      >
        Accept
      </button>
    </div>
  </div>
</div>
