<div id="section0" class="container-fluid py-2" *ngIf="ke">
  <form [formGroup]="formGroup">
    <div class="row align-items-center">
      <div class="col">
        <mat-form-field appearance="outline" class="no-subscript">
          <mat-label>Shutter</mat-label>
          <input
            matInput
            placeholder="Shutter"
            #locationInput
            formControlName="shutterCtrl"
            [matAutocomplete]="autoLoc"
            (keyup)="onLocChange($event)"
            #trigger="matAutocompleteTrigger"
          />
          <mat-autocomplete
            #autoLoc="matAutocomplete"
            (optionSelected)="selectedLoc($event)"
            #locationAutocomplete
            [displayWith]="displayObject"
          >
            <mat-option
              *ngFor="let shutter of filteredShutters"
              [value]="shutter"
            >
              {{ shutter.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col" *ngIf="showbranch">
        <div class="flex-display">
          <mat-form-field appearance="outline" class="no-subscript">
            <mat-label>Branchline</mat-label>
            <input
              matInput
              placeholder="Branchline"
              #branchlineInput
              formControlName="branCtrl"
              [matAutocomplete]="autoBran"
              #trigger="matAutocompleteTrigger"
            />
            <mat-autocomplete
              #autoBran="matAutocomplete"
              (optionSelected)="selectedBran($event)"
              #branchlineAutocomplete
              [displayWith]="displayObject"
            >
              <mat-option
                *ngFor="let branchline of branchlines"
                [value]="branchline"
              >
                {{ branchline.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div class="col">
        <h5>{{ beamlines }}</h5>
      </div>
      <div class="col">
        <mat-radio-group
          aria-label="Select an option"
          class="radio-group"
          #radioGroup
          [disabled]="disabled"
        >
          <mat-radio-button
            *ngFor="let r of brOptions"
            class="radio-button"
            [value]="r.value"
            [checked]="r.checked"
            (change)="radioChanged($event)"
          >
            {{ r.label }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </form>
</div>
