import { ToxicGas } from "../../toxic-gas";


export class ToxicGasRefresh {
  static readonly type = '[ToxicGas] Refresh';
}

export class ToxicGasCreate {
  static readonly type = '[ToxicGas] Create';

  constructor(public item: ToxicGas) { }
}

export class ToxicGasUpdate {
  static readonly type = '[ToxicGas] Update';

  constructor(public id: number, public item: ToxicGas) { }
}

export class ToxicGasDelete {
  static readonly type = '[ToxicGas] Delete';
  constructor(public id: number) { }
}
