import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'document-chips',
  templateUrl: './document-chips.component.html',
  styleUrls: ['./document-chips.component.scss']
})
export class DocumentChipsComponent implements OnInit {
  @Input() documentChips?: DocumentChip[];
  @Input() shadow?: boolean;

  constructor() { }

  ngOnInit() {
  }

  openDocument(e: string) {
    window.location.href = "#/redirect/" + e;
  }

}

export interface DocumentChip {
  id: number;
  serialNo: string;
  status: string;
  cssClass: string;
}
