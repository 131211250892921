<!-- <div class="card">
  <div class="card-header">
    <span class="card-title">Attach Radiation Area Images</span>
  </div> -->

<div class="card-body">
  <!-- <mat-form-field class="no-subscript">
      <mat-label>Radiation Area</mat-label>
      <mat-select [(value)]="typeSelected">
        <mat-option
          [value]="itemList.id"
          *ngFor="let itemList of menuListCategories"
          >{{ itemList.name }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
  <div class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
    <input
      type="file"
      #fileDropRef
      id="fileDropRef"
      multiple
      (change)="fileBrowseHandler($event)"
    />
    <img src="assets/images/ic-upload-file.svg" alt="" />
    <h3>Drag and drop file here</h3>
    <h3>or</h3>
    <label class="red-background" for="fileDropRef">Browse for file</label>
  </div>

  <div class="files-list">
    <div class="single-file" *ngFor="let file of files; let i = index">
      <img
        src="{{ 'assets/icons/files/' + getFileExtension(file.name) + '.png' }}"
        width="32px"
      />
      <div class="info">
        <h4 class="name">
          {{ file?.name }}
        </h4>
        <p class="size">
          {{ formatBytes(file?.size) }}
        </p>
        <!-- <review-progress [progress]="file?.progress"></review-progress> -->
      </div>
      <!-- <button mat-icon-button color="warn" (click)="deleteFile(i)">
          <mat-icon>delete</mat-icon>
        </button> -->
    </div>
  </div>
</div>
<!-- <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
      <button mat-flat-button color="primary" (click)="SaveFiles()">
        Accept
      </button>
    </div>
  </div> -->
<!-- </div> -->
