import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { utils } from 'src/app/modules/libs/utils';
import { FormRestriction } from 'src/app/services/forms/form-restriction';
import { RestrictionAvailableAutocompleteComponent } from '../restriction-available-autocomplete/restriction-available-autocomplete.component';
import { Restriction } from 'src/app/controls/restrictions/services/restriction';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'form-restriction-table',
  templateUrl: './form-restriction-table.component.html',
  styleUrls: ['./form-restriction-table.component.scss']
})
export class FormRestrictionTableComponent implements OnChanges {
  @Input() formType!: FormType;
  @Input() formID?: number;
  @Input() formRestrictions?: FormRestriction[];
  @Input() locationIDs?: (number | undefined)[];
  @Input() disabled?: boolean | null;
  @Input() customTitle?: string;
  @Input() placeholder?: string;
  @Input() hilite?: boolean | null;
  @Input() control!: FormControl;

  restrictions?: FormRestriction[];
  dataSource!: MatTableDataSource<FormRestriction>;

  @Output() changed = new EventEmitter<FormRestriction[]>();

  @ViewChild(RestrictionAvailableAutocompleteComponent) restrictionsAutocomplete!: RestrictionAvailableAutocompleteComponent;

  displayedColumns = ["text", "actions"];

  constructor(

  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.restrictions = utils.cloneDeep(this.formRestrictions);
    this.dataSource = new MatTableDataSource(this.restrictions);
  }

  refresh(restrictions?: FormRestriction[]) {
    this.formRestrictions = restrictions;
    this.restrictions = utils.cloneDeep(this.formRestrictions);
    this.dataSource = new MatTableDataSource(this.restrictions);
  }

  added(e: string) {
    const formRestriction: FormRestriction = {
      id: 0,
      formID: this.formID,
      text: e,
    }
    if (!this.restrictions) this.restrictions = [];
    this.restrictions?.push(formRestriction);
    this.dataSource = new MatTableDataSource(this.restrictions);
    this.changed.emit(this.restrictions);
  }

  selected(e: Restriction) {
    const formRestriction: FormRestriction = {
      id: 0,
      formID: this.formID,
      restrictionID: e.id,
      text: e.restrictionLanguage,
      restriction: e
    }
    if (!this.restrictions) this.restrictions = [];
    this.restrictions?.push(formRestriction);
    this.dataSource = new MatTableDataSource(this.restrictions);
    this.changed.emit(this.restrictions);
  }

  delete(formRestriction: FormRestriction) {
    const index = this.restrictions?.indexOf(formRestriction) ?? -1;

    if (index >= 0) {
      this.formRestrictions?.splice(index, 1);
      this.restrictions?.splice(index, 1);
      this.restrictionsAutocomplete.loadRestrictions();
      this.dataSource = new MatTableDataSource(this.restrictions);
      this.changed.emit(this.restrictions);
    }
  }

}
