import { ScheduleReminder } from "../../models/schedule-reminder";

export class ScheduleRemindersRefresh {
  static readonly type = '[SchedulesTypes] Refresh';
}

export class ScheduleRemindersCreate {
  static readonly type = '[SchedulesTypes] Create';
  constructor(public item: ScheduleReminder) { }
}

export class ScheduleRemindersUpdate {
  static readonly type = '[SchedulesTypes] Update';
  constructor(public id: number, public item: ScheduleReminder) { }
}

export class ScheduleRemindersDelete {
  static readonly type = '[SchedulesTypes] Delete';
  constructor(public id: number) { }
}
