<div class="container-fluid">
  <div class="row mt-3">
    <div class="col">
      <h5>Operation Verification</h5>
    </div>
  </div>
  <form-workflow
    [form]="form"
    [sectionNumber]="5"
    [disabled]="disabled"
    (loading)="loading.emit($event)"
  >
  </form-workflow>
</div>
