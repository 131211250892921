import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AbsiResource, AbsiTemplateTypeResource } from './absi';
import { BaseService } from 'src/app/common/base/base.service';
import { FormType, FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { SaveTaskResource, WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';

@Injectable()
export class AbsiService extends BaseService<AbsiResource, number> {

  SerialNumber = '/SerialNumbers/' + FormType.KE;
  Locations = '/Locations';

  Api = '/Absi';
  apiAbsiTemplateTypes = this.BASE_URL + '/AbsiTemplateType';

  constructor(
    protected override injector: Injector
  ) {
    super(injector, '/Absi');
  }

  // Gets the Serial Number of the Form
  getSerialNo(): Observable<SerialNumber> {
    const httpresult1 = this.http.get<SerialNumber>(this.BASE_URL + this.SerialNumber);
    return httpresult1;
  }

  getFirstSignatureDate(wfTableId: number): Observable<Date> {
    const httpresult = this.http.get<Date>(this.BASE_URL + this.Api + '/FirstSignatureDate/' + wfTableId);
    return httpresult;
  }

  sign(taskId: number, actionId: number, status?: FormStatusEnum) {
    let url: string = this.BASE_URL + this.Api + '/Sign?task=' + taskId + '&action=' + actionId;
    if (status) url += '&status=' + status;
    const httpresult = this.http.put<any>(url, null);
    return httpresult;
  }

  saveWFTask(s: SaveTaskResource) {
    const httpresult = this.http.put<any>(this.BASE_URL + this.Api + '/Save/' + s.id, s);
    return httpresult;
  }

  readAllSections(wfTableId: number): Observable<WFSectionLocalResource[]> {
    const httpresult = this.http.get<WFSectionLocalResource[]>(this.BASE_URL + this.Api + '/WFSectionLocals/' + wfTableId);
    return httpresult;
  }

  refreshRnC(wfTableID: number) {
    return this.http.patch(this.BASE_URL + this.Api + '/RefreshRnC/' + wfTableID, null);
  }


  //#region AbsiTemplateType

  getAllAbsiTemplateTypes(): Observable<AbsiTemplateTypeResource[]> {
    return this.http.get<AbsiTemplateTypeResource[]>(this.apiAbsiTemplateTypes);
  }

  getAbsiTemplateTypeById(absiTemplateTypeId: number): Observable<AbsiTemplateTypeResource> {
    return this.http.get<AbsiTemplateTypeResource>(this.apiAbsiTemplateTypes + '/' + absiTemplateTypeId);
  }

  createAbsiTemplateType(absiTemplateType: AbsiTemplateTypeResource): Observable<AbsiTemplateTypeResource> {
    return this.http.post<AbsiTemplateTypeResource>(this.apiAbsiTemplateTypes, absiTemplateType);
  }

  updateAbsiTemplateType(absiTemplateTypeId: number, absiTemplateType: AbsiTemplateTypeResource): Observable<AbsiTemplateTypeResource> {
    return this.http.put<AbsiTemplateTypeResource>(this.apiAbsiTemplateTypes + '/' + absiTemplateTypeId, absiTemplateType);
  }

  deleteAbsiTemplateType(absiTemplateTypeId: number, deleteReason: string): Observable<AbsiTemplateTypeResource> {
    return this.http.delete<AbsiTemplateTypeResource>(this.apiAbsiTemplateTypes + '/' + absiTemplateTypeId + '/' + deleteReason);
  }

  //#endregion
}

class SerialNumber {
  serialNo!: string;
}
