import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DocumentType, Document } from './documents';
import { DocumentID } from './store/documents.action';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  baseUrl = environment.urls.baseApiUrl;
  apiDocuments = this.baseUrl + '/Documents';
  apiDocumentTypes = this.baseUrl + '/DocumentTypes';

  constructor(private http: HttpClient) { }

  getDocument(documentID: DocumentID) {
    return this.http.patch<Document>(this.apiDocuments, documentID);
  }

  getDocuments(): Observable<Document[]> {
    return this.http.get<Document[]>(this.apiDocuments);
  }

  getRelatedDocuments(id?: number): Observable<Document[]> {
    if (id) {
      return this.http.get<Document[]>(this.apiDocuments + '?id=' + id);
    } else {
      return this.http.get<Document[]>(this.apiDocuments);
    }
  }

  getDocumentTypes(): Observable<DocumentType[]> {
    return this.http.get<DocumentType[]>(this.apiDocumentTypes);
  }

  createDocumentType(documentTypeToCreate: DocumentType): Observable<DocumentType> {
    return this.http.post<DocumentType>(this.apiDocumentTypes, documentTypeToCreate);
  }

  updateDocumentType(documentTypeId: number, documentTypeToUpdate: DocumentType): Observable<DocumentType> {
    return this.http.put<DocumentType>(this.apiDocumentTypes + '/' + documentTypeId, documentTypeToUpdate);
  }

  delete(id: number) {
    return this.http.delete(this.apiDocumentTypes + '/' + id);
  }
}
