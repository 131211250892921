<div class="card">
  <div class="card-header">
    <span class="card-title"
      >{{ ologEmail.id ? "Edit" : "Add" }} Olog Email Address</span
    >
  </div>
  <div class="card-body">
    <form [formGroup]="formGroup">
      <div class="row mt-2">
        <div class="col-12">
          <mat-form-field>
            <mat-label>User</mat-label>
            <input
              matInput
              [(ngModel)]="ologEmail.user"
              [matAutocomplete]="auto"
              (keyup)="filter($event)"
              formControlName="userNameCtrl"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayUserName"
            >
              <mat-option *ngFor="let user of usersFiltered" [value]="user">
                {{ user.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="userNameCtrl?.invalid">{{
              getErrorMsg(userNameCtrl)
            }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Email</mat-label>
            <input
              matInput
              [ngModel]="ologEmail.user?.emailAddress"
              formControlName="userEmailCtrl"
              (ngModelChange)="
                ologEmail.user ? (ologEmail.user.emailAddress = $event) : null
              "
            />
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        class="cancel-btn"
        color="warn"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        class="accept-btn"
        color="primary"
        (click)="add()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
