<span class="file-input-text">{{ labelText }}</span>
<button
  mat-button
  color="primary"
  class="file-input-button maxWidth d-print-none"
  (click)="fileInput.click()"
  [attr.aria-label]="selectButtonText"
  [disabled]="disabled"
>
  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
  <span>{{ selectButtonText }}</span>
  <input
    #fileInput
    type="file"
    style="display: none"
    [accept]="acceptedTypes"
    [multiple]="allowMultipleFiles"
    (change)="filesChanged($event)"
  />
</button>
<button
  mat-flat-button
  color="primary"
  class="file-input-button"
  [disabled]="!selectedFiles"
  (click)="uploadFiles(0)"
  *ngIf="showUploadButton"
  [attr.aria-label]="uploadButtonText"
>
  <span
    *ngIf="loading"
    class="spinner-border spinner-border-sm d-print-none mr-1"
  ></span>
  {{ uploadButtonText }}</button
><br />
<span class="file-input-text">{{ selectedFileText }}</span>
<button
  *ngIf="selectedFileText != ''"
  mat-icon-button
  (click)="filesChanged(null)"
  aria-label="Remove Selected File(s)"
>
  <mat-icon>delete</mat-icon>
  <mat-icon *ngIf="customSvgIcon" [svgIcon]="customSvgIcon"></mat-icon>
</button>
<p *ngIf="showProgress">
  progress <strong>{{ percentage }}%</strong>
</p>
<div
  *ngIf="showFiles"
  class="border-top p-2"
  style="display: flex; flex-direction: row"
>
  <mat-file
    *ngFor="let attachment of attachments"
    [fileIcon]="attachment.fileIcon"
    [fileName]="attachment.fileName"
    [fileSize]="attachment.SizeKb ?? ''"
    [id]="attachment.id"
    (deleted)="refresh()"
  ></mat-file>
</div>
