import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";


export class EB2Shutter {
  public id?: number;
  public eB2ID?: number;
  public resourceID!: number;
  public resource!: Resource
}

export class EB2ShutterSave {
  public id?: number;
  public eB2ID?: number;
  public resourceID?: number;
}

export class EB2ShutterHandler {
  static toSave(eb2Shutter: EB2Shutter): EB2ShutterSave {
    var save = new EB2ShutterSave();

    save.id = eb2Shutter.id;
    save.eB2ID = eb2Shutter.eB2ID;
    save.resourceID = eb2Shutter.resourceID;

    return save;
  }
}
