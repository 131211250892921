import { RadMonitor } from "src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor";

export class RadMonitorRefresh {
  static readonly type = '[RadMonitor] Refresh';
}

export class RadMonitorCreate {
  static readonly type = '[RadMonitor] Create';
  constructor(public item: RadMonitor) { }
}

export class RadMonitorUpdate {
  static readonly type = '[RadMonitor] Update';
  constructor(public id: number, public item: RadMonitor) { }
}

export class RadMonitorDelete {
  static readonly type = '[RadMonitor] Delete';
  constructor(public id: number) { }
}
