<div class="row pt-3">
  <div class="col-4">
    <p>Statuses for Execution of the Checklist.</p>
    <p>
      You should set at least one of these statuses for each Step in you
      Checklist Template.
    </p>
    <p>
      Use Change Status Component in the Checklist Builder to Change the Status
      of the Checklist during the Execution.
    </p>
  </div>
  <div class="col-7 offset-1">
    <div class="mb-3 pr-1 item-container">
      <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div *ngFor="let s of statuses; index as i">
          <div cdkDrag class="row no-gutters border mb-1">
            <div class="col-1 pl-2 d-flex align-items-center">
              <mat-icon cdkDragHandle color="accent" style="cursor: move"
                >reorder</mat-icon
              >
            </div>
            <div class="col-8 pl-2 d-flex align-items-center">
              {{ s.order }}. {{ s.description }}
              <span class="pill ml-2" [ngClass]="getClass(s.cssClass)">{{
                s.code
              }}</span>
            </div>
            <div class="col-3 d-flex align-items-center justify-content-end">
              <button
                mat-icon-button
                color="primary"
                (click)="edit(s)"
                class="gear"
              >
                <mat-icon color="accent">edit</mat-icon>
              </button>
              <button
                mat-icon-button
                color="primary"
                (click)="delete(s)"
                class="gear"
              >
                <mat-icon color="warn">delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button mat-flat-button color="primary" (click)="add()">
      <mat-icon>add</mat-icon> Add
    </button>
  </div>
</div>
