import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { KEResource } from '../../ke';
import { KEnableCreate, KEnableDelete, KEnableRefresh, KEnableRefreshByID, KEnableUpdate } from './kenable.action';
import { KEService } from '../../ke.service';

export class KEnableStateModel {
  data: KEResource[] = [];
}

@State<KEnableStateModel>({
  name: 'KEnables',
  defaults: {
    data: []
  }
})

@Injectable()
export class KEnableState {
  constructor(
    private service: KEService
  ) { }

  @Selector()
  static read(state: KEnableStateModel) {
    return state.data;
  }

  @Action(KEnableRefresh)
  refresh({ patchState }: StateContext<KEnableStateModel>) {
    this.service.getAll().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(KEnableRefreshByID)
  refreshByID({ getState, patchState }: StateContext<KEnableStateModel>, { id }: KEnableRefreshByID) {
    this.service.getById(id).subscribe(data => {
      const state = getState();
      const index = state.data.map(x => x.id).indexOf(id);

      if (index >= 0) {
        state.data.splice(index, 1);
      }
      patchState({
        data: [...state.data, data]
      });
    },
      error => console.error(error)
    );
  }

  @Action(KEnableCreate)
  create({ getState, patchState }: StateContext<KEnableStateModel>, { item }: KEnableCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(KEnableUpdate)
  update({ getState, patchState }: StateContext<KEnableStateModel>, { id, item }: KEnableUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(KEnableDelete)
  delete({ getState, patchState }: StateContext<KEnableStateModel>, { id }: KEnableDelete) {
    patchState({
      data: getState().data.filter(x => x.id != id)
    });
  }
}
