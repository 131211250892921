<div class="d-flex flex-column justify-content-between pt-2 settings-container">
  <div class="card p-0" *ngFor="let group of privGroups">
    <div class="card-header">
      {{ group.name }}
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-3" *ngFor="let privilege of group.privileges">
          <role-selection
            [roles]="privilege.roles"
            [label]="privilege.name"
            [noSubscript]="true"
            (deleted)="deleteRole($event, privilege)"
            (selected)="selectedRole($event, privilege)"
          ></role-selection>
        </div>
      </div>
    </div>
  </div>
</div>
