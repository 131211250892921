import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RadMeter } from 'src/app/components/catalogs/meters-catalog/rad-meters';
import { RadMetersCreate, RadMetersDelete, RadMetersRefresh, RadMetersUpdate } from './rad-meters.action';
import { RadMetersService } from '../../services/rad-meters.service';

export class RadMetersStateModel {
  data: RadMeter[] = [];
}

@State<RadMetersStateModel>({
  name: 'RadMeters',
  defaults: {
    data: []
  }
})

@Injectable()
export class RadMetersState {
  constructor(
    private service: RadMetersService
  ) { }

  @Selector()
  static read(state: RadMetersStateModel) {
    return state.data;
  }

  @Action(RadMetersRefresh)
  refresh({ patchState }: StateContext<RadMetersStateModel>) {
    this.service.getAll().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(RadMetersCreate)
  create({ getState, patchState }: StateContext<RadMetersStateModel>, { item }: RadMetersCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(RadMetersUpdate)
  update({ getState, patchState }: StateContext<RadMetersStateModel>, { id, item }: RadMetersUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(RadMetersDelete)
  delete({ getState, patchState }: StateContext<RadMetersStateModel>, { id }: RadMetersDelete) {
    patchState({
      data: getState().data.filter(x => x.id != id)
    });
  }

}
