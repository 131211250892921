<mat-container>
  <mat-table [dataSource]="trainings">
    <ng-container matColumnDef="procedureNumber">
      <mat-header-cell class="px-2" *matHeaderCellDef>
        <span [innerHTML]="label_procedure_number"></span>
      </mat-header-cell>
      <mat-cell class="px-2" *matCellDef="let e">
        <span
          *ngIf="!e.procedure?.fileID"
          [ngClass]="{
            'pending-approval': e.procedureCredited?.status == 0,
            archived: e.procedure?.status != 1
          }"
          ><span
            [innerHTML]="
              e.procedure?.procedureNumber | highlightSearch : filter
            "
          ></span
        ></span>
        <a
          *ngIf="e.procedure?.fileID"
          href="{{ e.procedure?.file?.url }}"
          target="_blank"
          [ngClass]="{ archived: e.procedure?.status != 1 }"
          ><span
            class="bold"
            [innerHTML]="
              e.procedure?.procedureNumber | highlightSearch : filter
            "
          ></span
        ></a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
      <mat-header-cell class="px2" *matHeaderCellDef>
        <span [innerHTML]="label_procedure_title"></span>
      </mat-header-cell>
      <mat-cell class="px-2" *matCellDef="let e">
        <div
          [ngClass]="{
            'pending-approval': e.procedureCredited?.status == 0,
            archived: e.procedure?.status != 1
          }"
        >
          <span
            [innerHTML]="e.procedure?.title | highlightSearch : filter"
          ></span>
          <procedure-trainings-appendices
            [procedure]="e.procedure"
            [showAppendices]="e.showAppendices"
          ></procedure-trainings-appendices>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="currentRevision">
      <mat-header-cell class="px-2" *matHeaderCellDef>
        <span>Current Revision</span>
      </mat-header-cell>
      <mat-cell class="px-2" *matCellDef="let e">
        <span
          [ngClass]="{
            'pending-approval': e.procedureCredited?.status == 0,
            archived: e.procedure?.status != 1
          }"
          >{{
            !e.procedure?.historyProcedure ? e.procedure?.revision : ""
          }}</span
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="revisionCredited">
      <mat-header-cell class="px-2" *matHeaderCellDef>
        <span>Revision Credited</span>
      </mat-header-cell>
      <mat-cell class="px-2" *matCellDef="let e">
        <span
          [ngClass]="{
            'pending-approval': e.procedureCredited?.status == 0,
            archived: e.procedure?.status != 1
          }"
          >{{ e.creditedRev }}</span
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="obligationType">
      <mat-header-cell class="px-2" *matHeaderCellDef>
        <span>Obligation Type</span>
      </mat-header-cell>
      <mat-cell class="px-2" *matCellDef="let e">
        <span
          [ngClass]="{
            'pending-approval': e.procedureCredited?.status == 0,
            archived: e.procedure?.status != 1
          }"
          >{{ e?.obligationType }}</span
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateCredited">
      <mat-header-cell class="px-2" *matHeaderCellDef>
        <span>Date Credited</span>
      </mat-header-cell>
      <mat-cell class="px-2" *matCellDef="let e">
        <span
          [ngClass]="{
            'pending-approval': e.procedureCredited?.status == 0,
            archived: e.procedure?.status != 1
          }"
          >{{ e.creditedDate | date : "shortDate" }}</span
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="creditedBy">
      <mat-header-cell class="px-2" *matHeaderCellDef>
        <span>Credited by</span>
      </mat-header-cell>
      <mat-cell class="px-2" *matCellDef="let e">
        <span
          [ngClass]="{
            'pending-approval': e.procedureCredited?.status == 0,
            archived: e.procedure?.status != 1
          }"
          [matTooltip]="e.creditedBy?.name"
        >
          {{ getInitials(e) }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="applicableRoles">
      <mat-header-cell class="px-2" *matHeaderCellDef>
        <span>Applicable Role(s)</span>
      </mat-header-cell>
      <mat-cell class="px-2" *matCellDef="let e">
        <span
          [ngClass]="{
            'pending-approval': e.procedureCredited?.status == 0,
            archived: e.procedure?.status != 1
          }"
          [matTooltip]="formatRoles(e.applicableRoles)"
        >
          {{ formatRoles(e.applicableRoles) }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell class="px-2" *matHeaderCellDef></mat-header-cell>
      <mat-cell class="px-2 justify-content-end" *matCellDef="let e">
        <div
          class="cell-container"
          *ngIf="e.procedure?.historyProcedure ? false : hasActions(e)"
        >
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="actions"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              *ngIf="canComplete(e)"
              (click)="complete(e.procedure)"
            >
              <span>Complete</span>
            </button>
            <button
              mat-menu-item
              *ngIf="canApprove(e)"
              (click)="approve(e.procedureCredited)"
            >
              <span>Approve</span>
            </button>
            <button
              mat-menu-item
              *ngIf="canCompleteAndApprove(e)"
              (click)="completeAndApprove(e.procedure)"
            >
              <span>Complete and Approve</span>
            </button>
            <button
              mat-menu-item
              *ngIf="canUndo(e) || canApprove(e)"
              (click)="removeProcedureCredited(e.procedureCredited?.id)"
            >
              <span>Undo Completion</span>
            </button>
            <button
              mat-menu-item
              *ngIf="canDelete(e)"
              (click)="deleteTraining(e.procedure?.id)"
            >
              <span>Delete</span>
            </button>
            <button
              mat-menu-item
              *ngIf="canRemoveTrainingCredit(e)"
              (click)="removeProcedureCredited(e.procedureCredited?.id)"
            >
              <span>Remove Training Credit</span>
            </button>
          </mat-menu>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns; sticky: true"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columns"
      [ngClass]="{
        expired:
          row.procedure?.revision !=
            row.procedureCredited?.procedure?.revision &&
          row.procedure?.status == 1
      }"
    >
    </mat-row>
  </mat-table>
</mat-container>
