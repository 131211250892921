<h5 mat-dialog-title>{{ title }} Channel</h5>
<div mat-dialog-content>
  <form [formGroup]="formGroup">
    <div class="row mt-2">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Channel Name</mat-label>
          <input
            aria-label="name"
            matInput
            stripSpaces
            formControlName="nameCtrl"
            (change)="getData()"
          />
          <span class="value small" *ngIf="nameCtrl?.valid"
            >Value: <b><span [innerHTML]="value"></span></b
          ></span>
          <mat-error *ngIf="nameCtrl?.invalid">{{
            getErrorMsg(nameCtrl)
          }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Label</mat-label>
          <input aria-label="label" matInput formControlName="labelCtrl" />
          <mat-error *ngIf="labelCtrl?.invalid">{{
            getErrorMsg(labelCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<mat-dialog-actions align="center">
  <button mat-flat-button mat-dialog-close color="warn">Cancel</button>
  <button
    mat-flat-button
    class="ml-2"
    color="primary"
    [disabled]="!formGroup.valid || !valid"
    (click)="save()"
  >
    Save
  </button>
</mat-dialog-actions>
