import { Annotation } from "../../checklists";

export class AnnotationsRefresh {
  static readonly type = '[Annotations] Refresh';
}

export class AnnotationsCreate {
  static readonly type = '[Annotations] Create';

  constructor(public item: Annotation) { }
}

export class AnnotationsUpdate {
  static readonly type = '[Annotations] Update';

  constructor(public id: number, public item: Annotation) { }
}

export class AnnotationsDelete {
  static readonly type = '[Annotations] Delete';
  constructor(public id: number) { }
}
