<div
  class="row"
  *ngFor="let actionItemType of actionItemTypesFiltered; let i = index"
>
  <div class="col-12">
    <h6>{{ actionItemType.name }}</h6>
  </div>
  <div class="col-12">
    <div class="pb-3 mat-table-container">
      <div class="mat-container scrollbar">
        <mat-table [dataSource]="dataSources[i]" matSort>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell
              class="pl-1"
              [ngClass]="getClass(element)"
              *matCellDef="let element"
            >
              <mat-icon *ngIf="!element.status"> archive </mat-icon>
              <mat-icon
                *ngIf="element.status && element.reviewActionItemType.isStopper"
              >
                front_hand</mat-icon
              >
              <mat-icon
                *ngIf="
                  element.status && !element.reviewActionItemType.isStopper
                "
                color="primary"
              >
                event</mat-icon
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="topic">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Topic
            </mat-header-cell>
            <mat-cell
              class="font-weight-bold"
              [ngClass]="getClass(element)"
              *matCellDef="let element"
              nowrap
              >{{ element.name }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="responsible">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Responsible
            </mat-header-cell>
            <mat-cell
              class="pl-1"
              [ngClass]="getClass(element)"
              *matCellDef="let element"
            >
              {{ element.responsible?.name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="startDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Start Date
            </mat-header-cell>
            <mat-cell
              class="pl-1"
              [ngClass]="getClass(element)"
              *matCellDef="let element"
            >
              {{ element.startDate | date }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="endDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              End Date
            </mat-header-cell>
            <mat-cell
              class="pl-1"
              [ngClass]="getClass(element)"
              *matCellDef="let element"
            >
              {{ getEndDate(element.endDate) | date }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="taskNo">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Task No.
            </mat-header-cell>
            <mat-cell
              class="pl-1"
              [ngClass]="getClass(element)"
              *matCellDef="let element"
            >
              <span
                class="task-link"
                (click)="linkToTask($event, element.wfTaskLocal)"
              >
                {{ getTaskName(element.wfTaskLocal) }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action" *ngIf="canDelete">
            <mat-header-cell *matHeaderCellDef class="w-80">
              Action
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="w-80">
              <button
                mat-icon-button
                color="warn"
                (click)="deleteActionItem(element); $event.stopPropagation()"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            #matrow
            (click)="rowClicked(row)"
            *matRowDef="let row; columns: displayedColumns"
            class="table-row"
            style="white-space: nowrap"
            [ngClass]="{
              'mat-row-highlight': selectedRowIndex == row.id
            }"
          ></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
