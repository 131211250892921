import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { APLCategoryPopupComponent, APLCategoryPopupParameters } from './dialogs/apl-category-popup/apl-category-popup.component';
import { APLGroupRefresh } from '../store/apl-group/apl-group.action';

@Component({
  selector: 'app-apl-category-v2',
  templateUrl: './apl-category-v2.component.html',
  styleUrls: ['./apl-category-v2.component.scss']
})
export class APLCategoryV2Component extends BaseComponent implements OnInit, OnDestroy {

  filter!: string;

  loading!: boolean;
  loadingMessage!: string;

  labelTitle!: string;
  labelCategories!: string;
  labelFilter!: string;

  enableMove = false;
  createCategoryPrivilege!: boolean;

  ngOnInit(): void {
    this.store.dispatch(new APLGroupRefresh());

    this.createCategoryPrivilege = this.hasPrivilege(PrivilegeEnum.APLCategoryCreate);
    this.setLabels();
  }

  applyFilter(filter: string) {
    this.filter = filter;
  }

  setLabels() {
    this.labelTitle = this.getMessage('APL_Category_AuthorizedPersonsAndTechnicalExperts')?.description;
    this.labelFilter = this.getMessage('APL_Category_Filter')?.description;
    this.labelCategories = this.getMessage('APL_CategorySidePanel_Categories')?.description;
    if (!this.labelTitle) {
      setTimeout(() => {
        this.setLabels();
      }, 500);
    }
  }

  create() {
    this.dialog.open(APLCategoryPopupComponent, {
      height: 'auto',
      width: '25vw',
      data: {
      } as APLCategoryPopupParameters,
    });
  }

  setLoading(e: boolean) {
    setTimeout(() => {
      this.loading = e;
    }, 100);
  }
}
