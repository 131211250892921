<div class="container-fluid mt-3">
  <div class="container">
    <div class="row">
      <div class="col-3">
        <related-documents
          label="Select Form or Checklist"
          (changed)="changed($event)"
          [disableTooltips]="true"
        ></related-documents>
      </div>

      <div class="col-5">
        <mat-radio-group
          class="d-flex justify-content-between"
          [(ngModel)]="type"
        >
          <mat-radio-button *ngFor="let t of types" [value]="t">
            {{ t.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex align-items-start">
        <button
          mat-stroked-button
          color="accent"
          (click)="openOlog()"
          [disabled]="!documents?.length || !type?.id"
        >
          Open Olog
        </button>
        <button
          class="ml-2"
          mat-stroked-button
          color="primary"
          (click)="post()"
          [disabled]="!documents?.length || !type?.id"
        >
          Backend Post (New)
        </button>
        <button
          class="ml-2"
          mat-stroked-button
          color="primary"
          (click)="postFE()"
          [disabled]="!documents?.length || !type?.id"
        >
          Frontend Post (old)
        </button>
      </div>
    </div>
    <div class="mt-5">
      <div class="row mb-2" *ngFor="let res of results">
        <div class="col-1">
          {{ res.code }}
        </div>
        <div class="col-11">
          <div
            *ngIf="res.message.includes('<!DOCTYPE html>')"
            [innerHTML]="res.message"
          ></div>
          <pre
            *ngIf="!res.message.includes('<!DOCTYPE html>')"
          ><code [highlight]="formatMessage(res.message)"></code></pre>
        </div>
      </div>
    </div>
  </div>
</div>
