<div class="container small">
  <div class="row full-height">
    <div class="bl-dev" [ngClass]="{ 'col-6': precheck }" *ngIf="precheck">
      <div class="row" *ngFor="let satisfactionCheck of satisfactionChecks">
        <div
          class="col-12 pl-1"
          [ngClass]="{
            'hidden-check':
              satisfactionCheck.preCheck == satisfaction.Indeterminate
          }"
        >
          <span class="">
            <button
              mat-icon-button
              (click)="preCheckMilestone(satisfactionCheck)"
              [disabled]="!checkPrivilege(privilege.ReviewChecklistBLDevCheck)"
            >
              <mat-icon
                *ngIf="satisfactionCheck.preCheck == satisfaction.Satisfied"
                class="green"
                >check_circle_outline</mat-icon
              >
              <mat-icon
                *ngIf="satisfactionCheck.preCheck == satisfaction.Unsatisfied"
                class="red"
                >remove_circle_outline</mat-icon
              >
              <mat-icon
                *ngIf="satisfactionCheck.preCheck == satisfaction.Indeterminate"
                class="blue"
                >radio_button_unchecked</mat-icon
              >
            </button>
            <div
              [ngClass]="{
                'hidden-check':
                  satisfactionCheck.satisfaction == satisfaction.Indeterminate
              }"
              *ngIf="
                satisfactionCheck.satisfaction == satisfaction.Indeterminate
              "
            >
              Satisfied?
            </div>
          </span>
          <!-- <span *ngIf="satisfactionCheck.preCheck != satisfaction.Indeterminate">{{ satisfaction[satisfactionCheck.preCheck] }}</span> -->
          <span *ngIf="satisfactionCheck.preCheck == satisfaction.Satisfied"
            >believe Satisfies</span
          >
        </div>
        <div
          class="col-12 pl-1"
          *ngIf="satisfactionCheck.preCheck != satisfaction.Indeterminate"
        >
          <div
            class="satisfied-container"
            [ngClass]="{
              'border-green':
                satisfactionCheck.preCheck == satisfaction.Satisfied,
              'border-red':
                satisfactionCheck.preCheck == satisfaction.Unsatisfied
            }"
          >
            <b
              [matTooltip]="
                satisfactionCheck.preCheckBy?.name +
                ' on: ' +
                formatDate(satisfactionCheck?.preCheckOn)
              "
              >{{ satisfactionCheck.preCheckBy?.initials }}</b
            >
          </div>
        </div>
      </div>
    </div>
    <div class="bar" [ngClass]="{ 'col-12': !precheck, 'col-6': precheck }">
      <div class="row" *ngFor="let satisfactionCheck of satisfactionChecks">
        <div
          class="col-12 pl-1"
          [ngClass]="{
            'hidden-check':
              satisfactionCheck.satisfaction == satisfaction.Indeterminate
          }"
        >
          <span class="">
            <button
              mat-icon-button
              (click)="satCheckMilestone(satisfactionCheck)"
              [disabled]="!checkPrivilege(privilege.ReviewChecklistBARCol)"
            >
              <mat-icon
                *ngIf="satisfactionCheck.satisfaction == satisfaction.Satisfied"
                class="green"
                >check_circle_outline</mat-icon
              >
              <mat-icon
                *ngIf="
                  satisfactionCheck.satisfaction == satisfaction.Unsatisfied
                "
                class="red"
                >remove_circle_outline</mat-icon
              >
              <mat-icon
                *ngIf="
                  satisfactionCheck.satisfaction == satisfaction.Indeterminate
                "
                class="blue"
                >radio_button_unchecked</mat-icon
              >
            </button>
            <div
              [ngClass]="{
                'hidden-check':
                  satisfactionCheck.satisfaction == satisfaction.Indeterminate
              }"
              *ngIf="
                satisfactionCheck.satisfaction == satisfaction.Indeterminate
              "
            >
              Satisfied?
            </div>
          </span>
          <span
            *ngIf="satisfactionCheck.satisfaction != satisfaction.Indeterminate"
            >{{ satisfaction[satisfactionCheck.satisfaction ?? 0] }}</span
          >
        </div>
        <div
          class="col-12 pl-1"
          *ngIf="satisfactionCheck.satisfaction != satisfaction.Indeterminate"
        >
          <div
            class="satisfied-container"
            [ngClass]="{
              'border-green':
                satisfactionCheck.satisfaction == satisfaction.Satisfied,
              'border-red':
                satisfactionCheck.satisfaction == satisfaction.Unsatisfied
            }"
          >
            <span *ngIf="!precheck">
              <b>{{ satisfactionCheck.satisfactionCheckBy?.name }}</b
              ><br />
              <span class="small">on</span>&nbsp;<span class="date">{{
                satisfactionCheck.satisfactionCheckOn | date
              }}</span>
            </span>
            <span *ngIf="precheck">
              <b
                [matTooltip]="
                  satisfactionCheck.satisfactionCheckBy?.name +
                  ' on: ' +
                  formatDate(satisfactionCheck?.satisfactionCheckOn)
                "
                >{{ satisfactionCheck.satisfactionCheckBy?.initials }}</b
              >
            </span>
            <comments-box
              class="text-left"
              [textValue]="satisfactionCheck.comments"
              [disabled]="
                satisfactionCheck.satisfactionCheckByID != currentUser?.id
              "
              [formType]="checklist?.documentType?.code"
              [serialNo]="checklist?.serialNo"
              [title]="'Comment'"
              *ngIf="satisfactionCheck.satisfaction == satisfaction.Unsatisfied"
              (textChanged)="textChanged(satisfactionCheck, $event)"
            ></comments-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
