import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RadLocation } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RadLocationService {

  constructor(
    private http: HttpClient
  ) { }

  public api: string = environment.urls.baseApiUrl + '/RadLocation';

  GetRadLocations(): Observable<RadLocation[]> {
    return this.http.get<RadLocation[]>(this.api);
  }

  GetRadLocationById(id: number): Observable<RadLocation> {
    return this.http.get<RadLocation>(this.api + '/' + id);
  }

  CreateRadLocation(radLocation: RadLocation): Observable<RadLocation> {
    return this.http.post<RadLocation>(this.api, radLocation);
  }

  UpdateRadLocation(id: number, radLocation: RadLocation): Observable<RadLocation> {
    return this.http.put<RadLocation>(this.api + '/' + id, radLocation);
  }

  DeleteRadLocation(id: number) {
    return this.http.delete(this.api + '/' + id);
  }
}
