<div class="row">
  <div class="col-12">
    <mat-form-field class="no-subscript">
      <mat-label>Date</mat-label>
      <input
        matInput
        [matDatepicker]="datePicker"
        [min]="today"
        placeholder="Estimate Start Date"
        name="startDate"
        (dateChange)="changedDate($event)"
        [(ngModel)]="date"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="datePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <!-- <div class="col-6 pl-0">
    <fieldset class="field-group">
      <legend>Time</legend>
      <ngx-timepicker-field
        [controlOnly]="true"
        [minutesGap]="15"
        [defaultTime]="time"
        (timeChanged)="changedTime($event)"
      ></ngx-timepicker-field>
    </fieldset>
  </div> -->
</div>
