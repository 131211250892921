import { ChecklistTemplate, Option } from 'src/app/components/checklists/checklists';
import { Component, Input, EventEmitter, Output, OnInit, ViewChild } from '@angular/core';
import { Checklist } from 'src/app/components/checklists/checklists';
import { MessageBanner } from 'src/app/components/messages/services/message-banner';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { WfSignature } from 'src/app/services/work-flow/work-flow';
import { ComponentType } from 'src/app/common/enumerations/enumerations';
import { Tooltip } from 'src/app/common/tooltip';
import { KEResource } from 'src/app/components/kenable/ke';
import { ClShieldingsComponent } from '../cl-shieldings/cl-shieldings.component';

@Component({
  selector: 'master-control',
  templateUrl: './master-control.component.html',
  styleUrls: ['./master-control.component.scss']
})
export class MasterControlComponent implements OnInit {

  approval_user: any;
  approval_date: any;
  @Input() id?: number;
  @Input() builder?: boolean;
  @Input() editor?: boolean;
  @Input() enableColumns?: boolean;
  @Input() type?: number;                      // Control type to be displayed
  @Input() code?: string;
  @Input() number?: string | null;                    // Numeric label
  @Input() configuration?: string;

  @Input() header?: string | null;                    // Main text field for almost every control.
  @Input() headerAfter?: string;               // Altern text field for almost every control. Displayed after button    clicked.
  @Input() question?: string | null;                  // Question text field for almost every control that requieres questions.
  @Input() questionDisabled?: boolean;

  @Input() approved?: boolean;                 // True if task is Approved for signarures controls
  @Input() approvedBy?: User | null;                  // Approval User Object for signatures controls
  @Input() approvedOn?: Date | null;                  // Approval Date for signatures controls
  @Input() checkbox_checked?: boolean;         //

  @Input() confirm?: boolean;                  // Confirmation flago for checkbox signature
  @Input() booleanValue?: boolean;                  // Confirmation flago for checkbox signature

  @Input() showApprove? = true;       //
  @Input() showUnapprove? = true;     //
  @Input() showDisapprove? = false;   //

  @Input() radioQuestion?: string | null;
  @Input() radioOptions?: Option[];               // Options ObjectArray [{value: number, label: string}]
  @Input() radioSelection?: number;
  @Input() options?: any;

  @Input() roleIds?: number[] | null;
  @Input() roleCodes?: string;

  @Input() color!: string;                     // Primary color for several controls
  @Input() disabled?: boolean;                 // Disables control elements
  @Input() enabled?: boolean;
  @Input() visible?: boolean = true;                    // Visibility of the control

  @Input() label?: string | null;                     // Label for Note control
  @Input() text?: string | null;                      // Text for Note control

  @Input() textBefore?: string | null;                // For checkbox with text input
  @Input() textAfter?: string | null;                 // For checkbox with text input
  @Input() textValue?: string | null;
  @Input() disableEdit?: boolean;
  @Input() textAfter2?: string | null;
  @Input() scheduleResourceId?: number;
  @Input() scheduleTypeId?: number;
  @Input() dateValue1?: Date;
  @Input() dateValue2?: Date;
  @Input() dateValue3?: Date | null;
  @Input() scheduleName?: string;
  @Input() locationName?: string | null;
  @Input() userNames?: string;

  @Input() formType?: number | string;                  // CATL Component
  @Input() formId?: number;
  @Input() sectionNumber?: number;
  @Input() sectionId?: number;
  @Input() taskId?: number;

  @Input() checkboxVisible?: boolean = true;          // Used in Cl-Checkbox
  @Input() warning?: boolean;
  @Input() moreInfo?: string;

  @Input() currentChecklist?: Checklist | KEResource | null;
  @Input() currentChecklistTemplate?: ChecklistTemplate;
  @Input() serialNo?: string;

  @Input() editMode?: boolean;
  @Input() message?: MessageBanner;                     // Message for dialog confirmations

  @Input() followUpChanged?: boolean;
  @Input() rolesToApproveChanges?: string[];
  @Input() privilegeToApproveAnswer?: PrivilegeEnum;
  @Input() answerChangedById?: number;
  @Input() answerChangedBy?: User;

  @Input() s?: WfSignature;
  @Input() tooltips?: Tooltip[];
  @Input() saving?: boolean;

  @Input() required?: boolean;
  @Input() status?: number;               //Form or Checklist Status

  @Output() result = new EventEmitter<any>(); // Returns result data from editMode

  @Output() clicked = new EventEmitter<any>();  // Click event for button controls
  @Output() checked = new EventEmitter<any>();  // Check event for Checkboxes
  @Output() changed = new EventEmitter<any>();
  @Output() approve = new EventEmitter<any>();  // Click on Approve button event for Signature controls
  @Output() unapprove = new EventEmitter<any>();  // Click on Unapprove button event for Signature controls
  @Output() disapprove = new EventEmitter<any>();  // Click on disapprove button event for Signature controls
  @Output() save = new EventEmitter<any>();
  @Output() sendOptions = new EventEmitter<any>();
  @Output() sendCondition = new EventEmitter<any>();
  @Output() sendHeaders = new EventEmitter<any>();
  @Output() duplicate = new EventEmitter<any>();
  @Output() approveAnswer = new EventEmitter<any>();
  @Output() disapproveAnswer = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Output() loading = new EventEmitter<boolean>();
  @Output() checkShared = new EventEmitter<any>();

  @ViewChild(ClShieldingsComponent) shieldingsComponent?: ClShieldingsComponent;

  componentType = ComponentType;
  constructor(
  ) {

  }

  ngOnInit() {
    if (this.color == '' || this.color == null) { this.color = 'primary'; }
    // if (this.builder) { this.disabled = false; }
  }

  click_event() {
    this.clicked.emit();
  }

  check_event(e: any) {
    this.checked.emit(e);
  }

  approve_event(e: any) {
    this.approve.emit(e);
  }

  unapprove_event(e: any) {
    this.unapprove.emit(e);
  }

  disapprove_event(e: any) {
    this.disapprove.emit(e);
  }

  radio_event(e: any) {
    this.changed.emit(e);
  }

  text_changed(e: any) {
    this.changed.emit(e);
  }

  save_event(e: any) {
    this.save.emit(e);
  }

  duplicate_event(e: any) {
    this.duplicate.emit(e);
  }

  sendOptions_event(e: any) {
    this.sendOptions.emit(e);
  }

  sendCondition_event(e: any) {
    this.sendCondition.emit(e);
  }

  setLoading(e: any) {
    this.loading.emit(e);
  }

  close_event(e: any) {
    this.close.emit(e);
  }

  approveAnswer_event(e: any) {
    this.approveAnswer.emit(e);
  }

  disapproveAnswer_event(e: any) {
    this.disapproveAnswer.emit(e);
  }

  check_shared_event(e: any) {
    this.checkShared.emit(e);
  }


}
