import { distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ScheduleSubtype } from 'src/app/components/schedules/models/schedule-subtype';
import { ScheduleType } from 'src/app/components/schedules/models/schedule-type';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { TemplateType, TemplateRolePermission } from '../../../checklists';
import { TemplateTypesUpdate } from '../../../store/template-types/template-types.action';
import { ChecklistBuilderService } from '../../checklist-builder.service';
import { DocumentType } from 'src/app/services/documents/documents';
import { ResourceType } from 'src/app/components/catalogs/beamline-catalog/resource/resources';

@Component({
  selector: 'checklist-builder-settings-general',
  templateUrl: './checklist-builder-settings-general.component.html',
  styleUrls: ['./checklist-builder-settings-general.component.scss']
})

export class ChecklistBuilderSettingsGeneralComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() documentType!: DocumentType;

  @Output() disableClose = new EventEmitter<any>();
  @Output() selectTemplateType = new EventEmitter<any>();
  @Output() loading = new EventEmitter<boolean>();

  @ViewChild('roleInput')
  roleInput!: ElementRef<HTMLInputElement>;

  rtToggle?: boolean;
  scToggle?: boolean;
  rxToggle?: boolean;
  aiToggle?: boolean;
  mvToggle?: boolean;

  scToggleHide!: boolean;
  scToggleDisabled!: boolean;
  rtToggleDisabled!: boolean;
  resfToggle!: boolean;

  saving!: boolean;
  rtError?: boolean;
  scError?: boolean;
  rxError?: boolean;

  templateTypes$!: Observable<TemplateType[]>;
  templateTypes: TemplateType[] = [];
  templateTypesSubs!: Subscription;

  filteredResourceTypes!: ResourceType[];
  tmpResourceType!: string;
  resourceTypes: ResourceType[] = [];
  // resourceTypeCtrl = new FormControl();
  resourceTypesFiltered?: ResourceType[];
  resourceTypes$!: Observable<ResourceType[]>;
  resourceTypesSubs!: Subscription;

  filteredScheduleTypes!: ScheduleType[];
  scheduleTypes: ScheduleType[] = [];
  scheduleTypes$!: Observable<ScheduleType[]>;
  scheduleTypesSubs!: Subscription;
  scheduleTypeCtrl = new FormControl();

  filteredScheduleSubtypes!: ScheduleSubtype[];
  scheduleSubtypes!: ScheduleSubtype[];
  scheduleSubtypes$!: Observable<ScheduleSubtype[]>;
  scheduleSubtypesSubs!: Subscription;

  roles!: TemplateRolePermission[];
  allRoles!: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  rolesFiltered!: Role[];
  tmpRole!: string | null;
  roleCtrl = new FormControl();

  templateTypesExpansions?: Expansion[];

  constructor(
    protected override injector: Injector,
    private _srv: ChecklistBuilderService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loading.emit(true);
    // this.templateType = changes['templateType'].currentValue;
    this.loadTemplateTypes();
  }

  ngOnInit(): void {
    this.loadScheduleSubtypes();
    this.loadScheduleTypes();
    this.loadResourceTypes();
    this.loadRoles();
    this.loadTemplateTypes();
  }

  override ngOnDestroy(): void {
    this.scheduleTypesSubs?.unsubscribe();
    this.scheduleTypesSubs?.unsubscribe();
    this.templateTypesSubs?.unsubscribe();
    this.resourceTypesSubs?.unsubscribe();
    this.rolesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadTemplateTypes() {
    this.templateTypes$ = this.store.select(x => x.TemplateTypes.data);
    this.templateTypesSubs = this.templateTypes$.pipe(distinctUntilChanged()).subscribe(data => {
      this.templateTypes = data.filter(x => x.documentTypeID == this.documentType.id).sort((a, b) => this.utils.sort(a.order, b.order, true));

      if (!this.templateTypesExpansions) this.templateTypesExpansions = [];
      this.templateTypes.map(t => {
        const e = this.templateTypesExpansions?.find(e => e.templateType.id == t.id);
        if (e) e.templateType = t;
        else this.templateTypesExpansions?.push({ templateType: t, expanded: false, rtToggle: t.resourceTypeID != null, scToggle: t.scheduleTypeID != null });
      });
      this.loading.emit(false);

    });
  }

  loadResourceTypes() {
    this.resourceTypes$ = this.store.select(state => state.ResourceTypes.data);
    this.resourceTypesSubs = this.resourceTypes$.pipe(distinctUntilChanged()).subscribe(data => {
      if (data?.length) {
        this.resourceTypes = data;
        this.filteredResourceTypes = data;
        // this.resourceTypeCtrl.setValue(this.resourceTypes.find(r => r.id == this.templateType?.resourceTypeID));
        // this.validate();
      }
    });
  }

  getResourceTypes(e: Expansion) {
    return this.resourceTypes.filter(r => r.id == e.templateType.resourceTypeID);
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.pipe(distinctUntilChanged()).subscribe(data => {
      if (data?.length) {
        this.allRoles = data;
        this.rolesFiltered = data;
      }
    });
  }

  selectedResourceType(t: Expansion, e: MatAutocompleteSelectedEvent) {
    const value = e.option.value as ResourceType;
    t.templateType.resourceTypeID = value.id;
    this.update(t.templateType);
  }

  changedResource() {
    this.filteredResourceTypes = [];
    this.filteredResourceTypes = this.resourceTypes.filter(x => x.name.toLowerCase().includes(this.tmpResourceType.toLowerCase()));
  }

  loadScheduleTypes() {
    this.scheduleTypes$ = this.store.select(state => state.ScheduleTypes.data);
    this.scheduleTypesSubs = this.scheduleTypes$.pipe(distinctUntilChanged()).subscribe(data => {
      this.scheduleTypes = data as ScheduleType[];
      this.filteredScheduleTypes = data.filter(x => x.isActive);
      // this.scheduleTypeCtrl.setValue(this.scheduleTypes.find(s => s.id == this.templateType?.scheduleTypeID));
      // this.validate();
    });
  }

  loadScheduleSubtypes() {
    this.scheduleSubtypes$ = this.store.select(state => state.ScheduleSubtypes.data);
    this.scheduleSubtypesSubs = this.scheduleSubtypes$.pipe(distinctUntilChanged()).subscribe(data => {
      this.scheduleSubtypes = data as ScheduleSubtype[];
      this.filteredScheduleSubtypes = data.filter(x => x.isActive);
      // this.scheduleTypeCtrl.setValue(this.scheduleTypes.find(s => s.id == this.templateType?.scheduleTypeID));
    });
  }

  selectedScheduleType(t: TemplateType, e: MatAutocompleteSelectedEvent) {
    const value = e.option.value as ScheduleType;
    t.scheduleTypeID = value.id;
    this.update(t);
  }

  scToggleChanged(t: TemplateType, e: any) {
    if (!e.checked) {
      t.scheduleTypeID = null;
      this.scheduleTypeCtrl.setValue(null);
      this.update(t);
    }
    else {
      t.resourceType = null;
      t.resourceTypeID = null;
      this.rtToggle = false;
    }
    this.validate(t);
  }

  rtToggleChanged(t: Expansion, e: any) {
    if (!e.checked) {
      t.templateType.resourceTypeID = null;
      t.templateType.resourceType = null;
      // this.resourceTypeCtrl.setValue(null);
      this.update(t.templateType);
    }
    else {
      t.templateType.scheduleTypeID = null;
      t.scToggle = false;
    }
    // this.validate();
  }

  // rxToggleChanged(e: any) {
  //   if (this.templateType)
  //     this.templateType.reviewInExec = e.checked;
  //   this.update();
  //   // this.validate();
  // }

  // aiToggleChanged(e: any) {
  //   if (this.templateType)
  //     this.templateType.allowActionItems = e.checked;
  //   this.update();
  //   this.validate();
  // }

  // mvToggleChanged(e: any) {
  //   if (this.templateType)
  //     this.templateType.allowMultipleVersions = e.checked;
  //   this.update();
  //   this.validate();
  // }

  update(templateType: TemplateType | null = null) {
    this.saving = true;
    // if (!templateType && this.templateType) {
    //   templateType = this.templateType;
    // }
    if (templateType) {
      templateType.checklistTemplates = [];
      this._srv.updateTemplateType(templateType).subscribe(data => {
        if (data?.id) {
          this.store.dispatch(new TemplateTypesUpdate(data.id, data));
        }
        this.saving = false;
        // this.validate();
      },
        error => {
          console.log(error);
        });
    }
  }

  validate(t: TemplateType) {
    if (t) {
      this.scError = this.scToggle && t?.scheduleTypeID == null;
      this.rtError = this.rtToggle && t.resourceTypeID == null;
      this.rxError = this.rxToggle && (t.templateRolePermissions == null || t.templateRolePermissions.length == 0);
      this.disableClose.emit(this.rtError || this.scError || this.rxError);
    }
  }

  drop(event: CdkDragDrop<TemplateType[]>) {
    this.saving = true;
    if (event?.previousIndex !== event?.currentIndex) {
      moveItemInArray(this.templateTypes, event.previousIndex, event.currentIndex);
      this.renumberArray().then(() => {
        this.saving = false;
      });
    }
  }

  renumberArray() {
    return new Promise((resolve) => {
      let order = 1;
      this.templateTypes.map(s => {
        s.order = order;
        order++;
        s.checklistTemplates = [];
        this._srv.updateTemplateType(s).subscribe(data => {
          resolve(data);
        },
          error => {
            console.log(error);
          });
      });
    });
  }

  settings(templateType: TemplateType) {
    // this.templateType = templateType;
    // this.rtToggle = this.templateType.resourceTypeID != null;
    // this.scToggle = this.templateType.scheduleTypeID != null;
    // this.rxToggle = this.templateType.reviewInExec;
    // this.aiToggle = this.templateType.allowActionItems;
    // this.mvToggle = this.templateType.allowMultipleVersions;
    this.loadResourceTypes();
    this.loadScheduleTypes();
    // this.selectTemplateType.emit(this.templateType);
  }

  resfToggleChanged() {
    this.update();
  }

  changeRequired(status: boolean, e: Expansion) {
    e.templateType.status = status ? 1 : 0;
    this._srv.updateTemplateType(e.templateType).toPromise().then(templateTypeUpdated => {
    });
  }

  scToggleHideChanged(e: any) {
    // if (this.templateType)
    //   this.templateType.hideCustomSchedules = !e.checked;
    // this.update();
  }

  filterRevRoles() {
    this.rolesFiltered = [];
    this.rolesFiltered = this.allRoles?.filter(x => !this.roles.map(a => a.role.id).includes(x.id));
  }

  onRoleChange(e: any) {
    const text = e.target.value;
    this.rolesFiltered = this.allRoles.filter(x => !this.roles?.map(a => a.id).includes(x.id)).filter(x => x.name?.toLowerCase().includes(text.toLowerCase()) || x.code?.toLowerCase().includes(text.toLowerCase()));
  }

  // selectedRole(e: MatAutocompleteSelectedEvent) {
  //   this.saving = true;
  //   const value = e.option.value;
  //   const templateRolePermission: TemplateRolePermission = {
  //     id: 0,
  //     role: value,
  //     roleID: value.id,
  //     name: ChecklistPriv[12],
  //     checklistPrivilege: ChecklistPriv.ReviewChecklist,
  //     templateTypeID: this.templateType ? this.templateType.id : 0,
  //     documentTypeID: this.documentType.id,
  //     status: 1,
  //     type: this.templateType ? this.templateType.type ?? 0 : 0
  //   };
  //   this.roleInput.nativeElement.value = '';
  //   this._srv.createTemplateRolePersmission(templateRolePermission).subscribe(data => {
  //     if (!this.roles) { this.roles = []; }
  //     this.roles.push(data);
  //     this.tmpRole = null;
  //     this.filterRevRoles();
  //     this.saving = false;
  //   });
  // }

  removeRole(templateRolePermission: TemplateRolePermission) {
    this.saving = true;
    this.roles.splice(this.roles.findIndex(x => x.id == templateRolePermission.id), 1);
    this._srv.deleteTemplateRolePermission(templateRolePermission).subscribe(data => {
      this.saving = false;
    });
  }
}


interface Expansion {
  templateType: TemplateType;
  rtToggle: boolean;
  scToggle: boolean;
  expanded: boolean;
}
