import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import { environment } from '../../../environments/environment';
import { WFComponent } from '../../components/workflow/workflow';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/common/base/base.service';

@Injectable()
export class ComponentService extends BaseService<WFComponent, number> {
  refreshTableData: EventEmitter<any> = new EventEmitter();
  url = environment.urls.baseApiUrl + '/Components';
  constructor(
    protected override injector: Injector
  ) {
    super(injector, '/components');
  }

  Read(): Observable<WFComponent[]> {
    return this.http.get<WFComponent[]>(this.url);
  }

  ReadById(id: number): Observable<WFComponent> {
    return this.http.get<WFComponent>(this.url + '/' + id);
  }

}
