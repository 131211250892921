import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, EventEmitter, Output, Injector } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { BaseComponent } from "src/app/common/base/base.component";
import { ChecklistBuilderService } from "src/app/components/checklists/checklist-builder/checklist-builder.service";
import { TemplateType } from "src/app/components/checklists/checklists";
import { ScheduleType } from "src/app/components/schedules/models/schedule-type";
import { UserAutocompletePopupComponent, UserAutocompletePopupParameters } from "src/app/controls/user-autocomplete-popup/user-autocomplete-popup.component";
import { YesNoDialogComponent } from "src/app/controls/yes-no-dialog/yes-no-dialog.component";
import { PrivilegeEnum } from "src/app/services/role-privilege/privilege-enum";
import { User } from "src/app/components/catalogs/user-catalog/services/user";
import { ProcedurePopupComponent } from "../../dialogs/procedure-popup/procedure-popup.component";
import { ProcedureSignatureType } from "../../enums/procedure-signature-type.enum";
import { ProcedureTypeEnum } from "../../enums/procedure-type.enum";
import { ProcedureCategory } from "../../models/procedure-category.model";
import { ProcedureConfiguration } from "../../models/procedure-configuration.model";
import { ProcedureNote } from "../../models/procedure-note.model";
import { ProcedureSignature } from "../../models/procedure-signature.model";
import { ProcedureType } from "../../models/procedure-type";
import { Procedure } from "../../models/procedure.model";
import { ProcedureService } from "../../services/procedure.service";
import * as moment from "moment";
import { ProcedureNotePopupComponent } from "../../dialogs/procedure-note-popup/procedure-note-popup.component";
import { RelatedLinkDocumentBase } from "src/app/common/models/related-link-document-base.model";
import { Note, NoteType } from "src/app/services/notes/note.model";
import { NotificationMessage } from "src/app/components/catalogs/notifications/services/notification.models";
import { NotificationService } from "src/app/components/catalogs/notifications/services/notification.service";
import { NotificationType } from "src/app/common/enumerations/enumerations";
import { NotificationTemplate } from "src/app/components/catalogs/notifications/services/notification-template";
import { NotificationComponent } from "src/app/controls/notifications/notification.component";
import { NotificationTemplateStoreAndListenersService } from '../../../catalogs/notifications/store/notification-template.store';

@Component({
  selector: "procedure-update-table",
  templateUrl: "./procedure-update-table.component.html",
  styleUrls: ["./procedure-update-table.component.scss"],
})
export class ProcedureUpdateTableComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() procedureCategoryID?: number;
  @Input() filter = "";
  @Input() showInactive!: boolean;
  @Input() showArchived!: boolean;
  @Input() tabIndex!: number;
  @Input() myProcedures!: boolean;

  @Output() loading = new EventEmitter<boolean>();

  procedureType?: ProcedureType | null;
  scheduleType?: ScheduleType;

  scheduleTypes!: ScheduleType[];
  scheduleTypes$!: Observable<ScheduleType[]>;
  scheduleTypesSubs!: Subscription;

  procedureCategorySubscription!: Subscription;
  procedureCategory$!: Observable<ProcedureCategory[]>;
  procedureCategories!: ProcedureCategory[];
  procedureCategory?: ProcedureCategory;

  procedureSubscription!: Subscription;
  procedure$!: Observable<Procedure[]>;
  procedures!: Procedure[];
  proceduresFiltered!: Procedure[];
  procedureDS = new MatTableDataSource<Procedure>();

  procedureConfigurationSubscription!: Subscription;
  procedureConfiguration$!: Observable<ProcedureConfiguration>;
  procedureConfiguration!: ProcedureConfiguration;

  templateType!: TemplateType;
  templateTypes!: TemplateType[];
  templateTypes$!: Observable<TemplateType[]>;
  templateTypesSubs!: Subscription;

  labelNumber!: string;
  labelTitle!: string;
  labelRevision!: string;
  labelStatus!: string;
  labelLastReviewed!: string;
  labelReviewPeriod!: string;
  labelNextDue!: string;
  labelGoogleDoc!: string;
  labelPreparers!: string;
  labelAddPreparer!: string;
  labelRemovePreparer!: string;
  labelApprovePreparer!: string;
  labelUnapprovePreparer!: string;
  labelReviewers!: string;
  labelAddReviewer!: string;
  labelRemoveReviewer!: string;
  labelApproveReviewer!: string;
  labelUnapproveReviewer!: string;
  labelApprovers!: string;
  labelAddApprover!: string;
  labelRemoveApprover!: string;
  labelApproveApprover!: string;
  labelUnapproveApprover!: string;
  labelNotes!: string;
  labelActions!: string;
  labelEdit!: string;
  labelDelete!: string;
  labelCreateNote!: string;
  labelPublish!: string;
  labellocation!: string;
  labelDraft!: string;
  labelGeneralError!: string;
  labelChecklistNotFound!: string;
  labelProcedureAndDocumentManagement!: string;
  labelTitleLocationGlobal!: string;
  labelGoogleDocsDraftGlobal!: string;

  notificationMessage?: NotificationMessage | null;
  notificationTemplate?: NotificationTemplate;
  notificationTemplates$!: Observable<NotificationTemplate[]>;
  notificationTemplatesSubs!: Subscription;

  columns: string[] = [
    "procedureNumber",
    "description",
    "revision",
    "lastReviewed",
    "reviewPeriod",
    "nextDue",
    "files",
    "preparers",
    "reviewers",
    "approvers",
    "notes",
    "actions",
  ];
  title: string[] = ["procedureCategory"];

  signatureAdmin = false;
  actionsPermission = false;
  preparer = false;
  reviewer = false;
  approver = false;
  today = new Date();
  sort!: Sort;

  constructor(
    protected override injector: Injector,
    private procedureService: ProcedureService,
    private checklistBuilderService: ChecklistBuilderService,
    private router: Router,
    private notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loading.emit(true);
    this.setLabels();
    this.loadScheduleTypes();
    this.loadTemplateTypes();
    this.loadProcedures();
    this.loadProcedureCategories2();
    this.loadProcedureConfiguration();
    this.loadMessages();
    this.loadNotificationTemplates();
    this.signatureAdmin = this.hasPrivilege(PrivilegeEnum.ALSProcedureSignatureAdmin);
    this.actionsPermission = this.hasPrivilege(PrivilegeEnum.ALSProcedureActions);
  }

  override ngOnDestroy(): void {
    this.procedureCategorySubscription.unsubscribe();
    this.procedureSubscription?.unsubscribe();
    this.procedureConfigurationSubscription?.unsubscribe();
    this.scheduleTypesSubs?.unsubscribe();
    this.templateTypesSubs?.unsubscribe();
    this.notificationTemplatesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ("procedureCategoryID" in changes) {
      if (
        this.procedureCategories !== undefined &&
        this.procedureCategories != null &&
        this.procedureCategories?.length > 0
      ) {
        this.procedureCategory = this.procedureCategories.filter(
          (x) => x.id === this.procedureCategoryID
        )[0];
        this.labelTitleLocationGlobal =
          this.procedureCategory?.procedureTypeID !==
            ProcedureTypeEnum.ProcedureCenter &&
            this.procedureCategory?.procedureTypeID !==
            ProcedureTypeEnum.EPSDrawings &&
            this.procedureCategory?.procedureTypeID !==
            ProcedureTypeEnum.RadSurvey
            ? this.labellocation
            : this.labelTitle;
        this.labelGoogleDocsDraftGlobal =
          this.procedureCategory?.procedureTypeID ===
            ProcedureTypeEnum.RSSTest ||
            this.procedureCategory?.procedureTypeID === ProcedureTypeEnum.EPSTest
            ? this.labelDraft
            : this.labelGoogleDoc;
      }
    } else if ("filter" in changes) {
      this.loadProcedures();
    }
    this.ngOnInit();
  }

  loadNotificationTemplates() {
    this.notificationTemplates$ = this.store.select(state => state.NotificationTemplates.data);
    this.notificationTemplatesSubs = this.notificationTemplates$.subscribe(data => {
      if (data?.length) {
        this.notificationTemplate = data.find(n => n.type == NotificationType.Procedure);
      }
    });
  }

  loadProcedureCategories() {
    this.procedureCategory$ = this.store.select(
      (state) => state.ProcedureCategory.ProcedureCategories
    );
    this.procedureCategorySubscription = this.procedureCategory$.subscribe(
      (data) => {
        this.procedureCategories = data;
        this.procedureCategory = this.procedureCategories.find((x) => x.id === this.procedureCategoryID);
        this.procedureType = this.procedureCategory?.procedureType;
        this.scheduleType = this.scheduleTypes.find((x) => x.procedureTypeID == this.procedureType?.id);
        this.loadProcedures();
        this.setHeaders();
      }
    );
  }

  setHeaders() {
    if (!this.procedureCategory) {
      return;
    }
    if (this.procedureCategory.procedureTypeID === ProcedureTypeEnum.RSSTest) {
      this.columns = [
        "procedureNumber",
        "scheduleType",
        "description",
        "revision",
        "lastReviewed",
        "reviewPeriod",
        "nextDue",
        "files",
        "preparers",
        "reviewers",
        "approvers",
        "notes",
        "actions",
      ];
    }
    if (
      this.procedureCategory?.procedureTypeID == ProcedureTypeEnum.RadSurvey
    ) {
      this.columns = [
        "description",
        "procedureNumber",
        "revision",
        "scheduleType",
        "lastReviewed",
        "reviewPeriod",
        "nextDue",
        "files",
        "preparers",
        "reviewers",
        "approvers",
        "notes",
        "actions",
      ];
    }
    if (
      this.procedureCategory.procedureTypeID === ProcedureTypeEnum.RSSTest ||
      this.procedureCategory.procedureTypeID === ProcedureTypeEnum.EPSTest ||
      this.procedureCategory.procedureTypeID === ProcedureTypeEnum.KEChecklist
    ) {
      this.sort = {
        active: "description",
        direction: "asc",
      };
      this.sortData();
    }
    this.labelTitleLocationGlobal =
      this.procedureCategory?.procedureTypeID !==
        ProcedureTypeEnum.ProcedureCenter &&
        this.procedureCategory?.procedureTypeID !==
        ProcedureTypeEnum.EPSDrawings &&
        this.procedureCategory?.procedureTypeID !== ProcedureTypeEnum.RadSurvey
        ? this.labellocation
        : this.labelTitle;
    this.labelGoogleDocsDraftGlobal =
      this.procedureCategory?.procedureTypeID === ProcedureTypeEnum.RSSTest ||
        this.procedureCategory?.procedureTypeID === ProcedureTypeEnum.EPSTest
        ? this.labelDraft
        : this.labelGoogleDoc;
  }

  loadProcedureCategories2() {
    this.procedureCategory$ = this.store.select(
      (state) => state.ProcedureCategory.ProcedureCategories
    );
    this.procedureCategorySubscription = this.procedureCategory$.subscribe(
      (data) => {
        this.procedureCategory = data.find(
          (x) => x.id === this.procedureCategoryID
        );
        this.setPrivileges();
      }
    );
  }

  loadProcedures() {
    this.procedure$ = this.store.select((state) => state.Procedures.data);
    this.procedureSubscription = this.procedure$.subscribe((data) => {
      if (data == null || data?.length == 0) {
        return;
      }
      this.setHeaders();
      this.procedures = data.filter(
        (x) =>
          x.procedureCategoryID === this.procedureCategoryID &&
          ((this.showInactive && x.status == 0) ||
            (!this.showInactive && x.status == 1)) &&
          ((!this.showArchived && x.active) || this.showArchived)
      );
      // this.procedures.map(p=> p.procedureNotes = this.getNotes(p.procedureNotes))
      if (this.myProcedures) {
        this.procedures = this.procedures.filter((x) => x.procedureSignatures?.map((s) => s.userID).includes(this.currentUser?.id ?? 0));
      }
      this.proceduresFiltered = this.filter
        ? this.procedures.filter(
          (x) =>
            x.procedureNumber?.trim()?.toUpperCase()?.includes(this.filter?.trim()?.toUpperCase()) || x.title?.trim()
              ?.toUpperCase()?.includes(this.filter?.trim()?.toUpperCase()) || x.procedureAppendices?.some((a) =>
                a.text?.toLowerCase().includes(this.filter?.trim()?.toLocaleLowerCase()))) : this.procedures;
      this.procedureDS.data = this.proceduresFiltered.sort((a, b) =>
        this.utils.compare(a.procedureNumber, b.procedureNumber, true)
      );
      this.sortData();
      this.setPrivileges();
      this.loading.emit(false);
    });
  }

  setPrivileges() {
    if (!this.currentUser) {
      return;
    }
    switch (this.procedureCategory?.procedureTypeID) {
      case ProcedureTypeEnum.EPSTest:
        this.preparer = this.signatureAdmin
          ? true
          : this.hasPrivilege(PrivilegeEnum.PCEPSPreparer);
        this.reviewer = this.signatureAdmin
          ? true
          : this.hasPrivilege(PrivilegeEnum.PCEPSReviewer);
        this.approver = this.signatureAdmin
          ? true
          : this.hasPrivilege(PrivilegeEnum.PCEPSApprover);
        break;

      case ProcedureTypeEnum.RSSTest:
        this.preparer = this.signatureAdmin
          ? true
          : this.hasPrivilege(PrivilegeEnum.PCRSSPreparer);
        this.reviewer = this.signatureAdmin
          ? true
          : this.hasPrivilege(PrivilegeEnum.PCRSSReviewer);
        this.approver = this.signatureAdmin
          ? true
          : this.hasPrivilege(PrivilegeEnum.PCRSSApprover);
        break;

      case ProcedureTypeEnum.RadSurvey:
        this.preparer = this.signatureAdmin
          ? true
          : this.hasPrivilege(PrivilegeEnum.PCRADPreparer);
        this.reviewer = this.signatureAdmin
          ? true
          : this.hasPrivilege(PrivilegeEnum.PCRADReviewer);
        this.approver = this.signatureAdmin
          ? true
          : this.hasPrivilege(PrivilegeEnum.PCRADApprover);
        break;

      default:
        this.preparer = true;
        this.reviewer = true;
        this.approver = true;
        break;
    }
  }

  loadProcedureConfiguration() {
    this.procedureConfiguration$ = this.store.select(
      (state) => state.ProcedureConfiguration.ProcedureConfiguration
    );
    this.procedureConfigurationSubscription =
      this.procedureConfiguration$.subscribe((data) => {
        this.procedureConfiguration = data;
      });
  }

  loadScheduleTypes() {
    this.scheduleTypes$ = this.store.select(
      (state) => state.ScheduleTypes.data
    );
    this.scheduleTypesSubs = this.scheduleTypes$.subscribe((data) => {
      this.scheduleTypes = data;
      this.loadProcedureCategories();
    });
  }

  loadTemplateTypes() {
    this.templateTypes$ = this.store.select(
      (state) => state.TemplateTypes.data
    );
    this.templateTypesSubs = this.templateTypes$.subscribe((data) => {
      this.templateTypes = data?.filter(
        (x) =>
          this.scheduleType &&
          x.scheduleTypeID == this.scheduleType?.id &&
          this.scheduleType?.procedureTypeID == this.procedureType?.id
      );
    });
  }

  createSignature(procedure: Procedure, type: ProcedureSignatureType, user: User | null = this.currentUser) {
    const privilege = this.getPrivilege(procedure?.procedureCategory?.procedureTypeID ?? 0, type);
    if (this.signatureAdmin) {
      this.dialog
        .open(UserAutocompletePopupComponent, {
          width: "30vw",
          data: {
            placeholder: "Select a user",
            selectedUsers: procedure.procedureSignatures,
            privileges: privilege ? [privilege] : null,
          } as UserAutocompletePopupParameters,
        })
        .afterClosed().toPromise().then(data => {
          if (data) {
            if (procedure?.procedureSignatures?.some((x) => x.userID === data?.user?.id)) {
              this.alert.message("ALSPC_UserAlreadyInProcedure");
            } else {
              this.procedureService.createSignature({
                id: 0,
                procedureID: procedure.id,
                signatureType: type,
                userID: data?.user?.id,
              }).subscribe(() => { },
                (error) => this.alert.error(error.error)
              );
            }
          }
        });
    } else {
      if (procedure?.procedureSignatures?.some((x) => x.userID === user?.id)) {
        this.alert.message("ALSPC_UserAlreadyInProcedure");
      } else {
        this.procedureService.createSignature({
          id: 0,
          procedureID: procedure.id,
          signatureType: type,
          userID: user?.id ?? 0
        }).subscribe(() => {
          this.alert.success("Signature added!");
        },
          (error) => this.alert.error(error.error)
        );
      }
    }
  }

  removeSignature(procedureSignature: ProcedureSignature) {
    this.procedureService.deleteSignature(procedureSignature.id).subscribe(
      () => {
        this.alert.warning("Signature removed!");
      },
      (error) => this.alert.error(error.error)
    );
  }

  approveSignature(procedureSignature: ProcedureSignature) {
    if (this.currentUser?.id != procedureSignature.userID) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: "400px",
        data: {
          message: this.getMessage("ALSPC_AreYouSureApproveOtherUser").description.replace("{userName}", procedureSignature.user?.name ?? ''),
          icon: "stop",
        },
      });

      confirm.afterClosed().subscribe((data) => {
        if (data) {
          this.approve(procedureSignature);
        }
      });
    } else {
      this.approve(procedureSignature);
    }
  }

  approve(procedureSignature: ProcedureSignature) {
    this.procedureService.approveSignature(procedureSignature.id).subscribe(
      () => { },
      (error) => this.alert.error(error.error)
    );
  }

  unapproveSignature(procedureSignature: ProcedureSignature) {
    const message =
      this.currentUser?.id == procedureSignature.userID
        ? this.getMessage("ALSPC_AreYouSureRemoveYourApproval").description
        : this.getMessage("ALSPC_AreYouSureRemoveOtherApproval").description.replace("{userName}", procedureSignature.user?.name ?? '');
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "400px",
      data: {
        message,
        icon: "stop",
      },
    });

    confirm.afterClosed().subscribe((data) => {
      if (data) {
        this.procedureService
          .unapproveSignature(procedureSignature.id)
          .subscribe(
            () => { },
            (error) => this.alert.error(error.error)
          );
      }
    });
  }

  deleteNote(note: ProcedureNote) {
    this.dialog
      .open(YesNoDialogComponent, {
        width: "500px",
        data: {
          message: this.getMessage("ALSPC_AreYouSureDeleteNote").description,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data && note.id) {
          this.procedureService.deleteNote(note.id).subscribe(
            () => { },
            (error) => this.alert.error(error.error)
          );
        }
      });
  }

  openProcedurePopup(procedure: Procedure) {
    let obj: Procedure | null = null;

    if (
      this.procedureConfiguration?.currentPath?.trim()?.length <= 0 ||
      this.procedureConfiguration?.inactivePath?.trim()?.length <= 0 ||
      this.procedureConfiguration?.archivedPath?.trim()?.length <= 0
    ) {
      this.alert.message("ALSPC_ConfigurePaths");
      return;
    }

    if (procedure != null) {
      obj = this.utils.cloneDeep(procedure);
    }

    this.dialog.open(ProcedurePopupComponent, {
      height: "auto",
      width: "75vw",
      maxHeight: "85vh",
      disableClose: true,
      data: {
        procedure: obj,
        procedureTab: this.tabIndex,
      },
    });
  }

  delete(id: number) {
    this.dialog
      .open(YesNoDialogComponent, {
        width: "500px",
        data: {
          message: this.getMessage("ALSPC_DeleteProcedureConfirmation")
            .description,
        },
      })
      .afterClosed().subscribe((data) => {
        if (data) {
          this.procedureService.delete(id).toPromise().catch((error) => this.alert.error(error.error));
        }
      });
  }

  addNote(procedure: Procedure) {
    this.dialog
      .open(ProcedureNotePopupComponent, {
        width: "30vw",
        data: procedure,
      })
      .afterClosed()
      .subscribe((data: ProcedureNote) => {
        if (data != null) {
          this.procedureService.createNote(data).subscribe(
            () => { },
            (error) => this.alert.error(error.error)
          );
        }
      });
  }

  complete(procedure: Procedure) {
    const newVersion: Procedure = this.utils.cloneDeep(procedure);
    newVersion.fileID = null;
    newVersion.file = null;
    newVersion.newProcedure = false;
    newVersion.procedureAppendices = [];
    procedure.procedureAppendices?.map((appendix) => {
      newVersion.procedureAppendices?.push({
        id: 0,
        formID: 0,
        fileID: appendix.fileID,
        text: appendix.text,
        url: appendix.url
      });
    });

    this.dialog.open(ProcedurePopupComponent, {
      height: "auto",
      width: "75vw",
      data: {
        procedure: newVersion,
        complete: true,
      },
    });
  }

  canComplete(procedure: Procedure): boolean {
    if (procedure.procedureCategory?.procedureTypeID === ProcedureTypeEnum.EPSTest || procedure.procedureCategory?.procedureTypeID === ProcedureTypeEnum.RSSTest) {
      return false;
    }
    return (
      !procedure.procedureSignatures?.some((x) => !x.signedOn) &&
      procedure.procedureSignatures?.some((x) => x.signatureType === ProcedureSignatureType.Preparer) &&
      procedure.procedureSignatures.some((x) => x.signatureType === ProcedureSignatureType.Approver) && this.signatureAdmin
    ) ?? false;
  }

  expired(procedure: Procedure): boolean {
    if (!procedure.nextDue)
      return false;

    return (
      moment(this.today).isAfter(procedure?.nextDue) &&
      procedure?.active &&
      procedure?.status === 1
    ) ?? false;
  }

  openChecklist(procedure: Procedure) {
    if (procedure.procedureMasterID)
      this.procedureService.getChecklistTemplate(procedure.procedureMasterID).subscribe(
        (data) => {
          this.checklistBuilderService.setChecklistTemplate(data);
          this.router.navigate([]).then(() => {
            window.open(
              "/#/checklist-builder/" + data.documentTypeID?.toString(),
              "_blank"
            );
          });
        },
        (error) =>
          this.alert.error(
            error.status === 404
              ? this.labelChecklistNotFound
              : this.labelGeneralError
          )
      );
  }

  getPrivilege(procedureType: ProcedureTypeEnum, procedureSignatureType: ProcedureSignatureType): PrivilegeEnum | null | undefined {
    switch (procedureType) {
      case ProcedureTypeEnum.EPSTest:
        switch (procedureSignatureType) {
          case ProcedureSignatureType.Preparer:
            return PrivilegeEnum.PCEPSPreparer;
          case ProcedureSignatureType.Reviewer:
            return PrivilegeEnum.PCEPSReviewer;
          case ProcedureSignatureType.Approver:
            return PrivilegeEnum.PCEPSApprover;
          default:
            return null;
        }

      case ProcedureTypeEnum.RSSTest:
        switch (procedureSignatureType) {
          case ProcedureSignatureType.Preparer:
            return PrivilegeEnum.PCRSSPreparer;
          case ProcedureSignatureType.Reviewer:
            return PrivilegeEnum.PCRSSReviewer;
          case ProcedureSignatureType.Approver:
            return PrivilegeEnum.PCRSSApprover;
          default:
            return null;
        }

      case ProcedureTypeEnum.RadSurvey:
        switch (procedureSignatureType) {
          case ProcedureSignatureType.Preparer:
            return PrivilegeEnum.PCRADPreparer;
          case ProcedureSignatureType.Reviewer:
            return PrivilegeEnum.PCRADReviewer;
          case ProcedureSignatureType.Approver:
            return PrivilegeEnum.PCRADApprover;
          default:
            return null;
        }
    }
    return null;
  }

  hasTemplates() {
    return this.templateTypes?.length > 0;
  }

  isInSignatures(procedure: Procedure): boolean {
    if (this.signatureAdmin)
      return false;

    return procedure?.procedureSignatures?.some((x) => x.userID === this.currentUser?.id) ?? false;
  }

  sortData(sort: Sort = this.sort) {
    this.sort = sort;
    if (!this.proceduresFiltered)
      return;

    const data = this.proceduresFiltered.slice();
    if (!sort?.active || sort?.direction === "") {
      this.procedureDS.data = data;
      return;
    }

    this.procedureDS.data = data.sort((a, b) => {
      const isAsc = sort?.direction === "asc";
      switch (sort?.active) {
        case "procedureNumber":
          return this.utils.compare(a.procedureNumber, b.procedureNumber, isAsc);
        case "description":
          return this.utils.compare(a.title, b.title, isAsc);
        case "lastReviewed":
          return this.utils.compare(a.lastReviewed, b.lastReviewed, isAsc);
        case "reviewPeriod":
          return this.utils.compare(a.reviewPeriod, b.reviewPeriod, isAsc);
        case "nextDue":
          return this.utils.compare(a.nextDue, b.nextDue, isAsc);
        default:
          return 0;
      }
    });
  }

  sortedAppendices(procedure: Procedure): RelatedLinkDocumentBase[] {
    return procedure?.procedureAppendices?.sort((a, b) =>
      this.utils.compare(a.text, b.text, true)
    ) ?? [];
  }

  setLabels() {
    this.labelNumber = this.getMessage("ALSPC_Update_Number")?.description;
    this.labelTitle = this.getMessage("ALSPC_Update_Title")?.description;
    this.labelRevision = (this.procedureCategory?.procedureTypeID == ProcedureTypeEnum.EPSTest || this.procedureCategory?.procedureTypeID == ProcedureTypeEnum.RSSTest || this.procedureCategory?.procedureTypeID == ProcedureTypeEnum.RadSurvey) ? 'Next Revision' :
      this.getMessage("ALSPC_Update_Revision")?.description;
    this.labelStatus = this.getMessage("ALSPC_Update_Status")?.description;
    this.labelLastReviewed = this.getMessage("ALSPC_Update_LastReviewed")?.description;
    this.labelReviewPeriod = this.getMessage("ALSPC_Update_ReviewPeriod")?.description;
    this.labelNextDue = this.getMessage("ALSPC_Update_NextDue")?.description;
    this.labelGoogleDoc = this.getMessage("ALSPC_Update_GoogleDoc")?.description;
    this.labelPreparers = this.getMessage("ALSPC_Update_Preparers")?.description;
    this.labelAddPreparer = this.getMessage("ALSPC_Update_AddPreparer")?.description;
    this.labelRemovePreparer = this.getMessage("ALSPC_Update_RemovePreparer")?.description;
    this.labelApprovePreparer = this.getMessage("ALSPC_Update_ApprovePreparer")?.description;
    this.labelUnapprovePreparer = this.getMessage("ALSPC_Update_UnapprovePreparer")?.description;
    this.labelReviewers = this.getMessage("ALSPC_Update_Reviewers")?.description;
    this.labelAddReviewer = this.getMessage("ALSPC_Update_AddReviewer")?.description;
    this.labelRemoveReviewer = this.getMessage("ALSPC_Update_RemoveReviewer")?.description;
    this.labelApproveReviewer = this.getMessage("ALSPC_Update_ApproveReviewer")?.description;
    this.labelUnapproveReviewer = this.getMessage("ALSPC_Update_UnapproveReviewer")?.description;
    this.labelApprovers = this.getMessage("ALSPC_Update_Approvers")?.description;
    this.labelAddApprover = this.getMessage("ALSPC_Update_AddApprover")?.description;
    this.labelRemoveApprover = this.getMessage("ALSPC_Update_RemoveApprover")?.description;
    this.labelApproveApprover = this.getMessage("ALSPC_Update_ApproveApprover")?.description;
    this.labelUnapproveApprover = this.getMessage("ALSPC_Update_UnapproveApprover")?.description;
    this.labelNotes = this.getMessage("ALSPC_Update_Notes")?.description;
    this.labelActions = this.getMessage("ALSPC_Update_Actions")?.description;
    this.labelEdit = this.getMessage("ALSPC_Update_Edit")?.description;
    this.labelDelete = this.getMessage("ALSPC_Update_Delete")?.description;
    this.labelCreateNote = this.getMessage("ALSPC_Update_CreateNote")?.description;
    this.labelPublish = this.getMessage("ALSPC_Update_Publish")?.description;
    this.labellocation = this.getMessage("ALSPC_Update_Location")?.description;
    this.labelDraft = this.getMessage("ALSPC_Update_Draft")?.description;
    this.labelGeneralError = this.getMessage("defaultErrorMessage")?.description;
    this.labelChecklistNotFound = this.getMessage("ALSPC_Update_ChecklistNotFound")?.description;

    this.labelProcedureAndDocumentManagement = this.getMessage("ALSPC_Update_ProcedureAndDocumentManagement"
    )?.description;
    this.labelTitleLocationGlobal = this.procedureCategory?.procedureTypeID !== ProcedureTypeEnum.ProcedureCenter &&
      this.procedureCategory?.procedureTypeID !== ProcedureTypeEnum.EPSDrawings &&
      this.procedureCategory?.procedureTypeID !== ProcedureTypeEnum.RadSurvey
      ? this.labellocation : this.labelTitle;
    this.labelGoogleDocsDraftGlobal = this.procedureCategory?.procedureTypeID ===
      ProcedureTypeEnum.RSSTest || this.procedureCategory?.procedureTypeID === ProcedureTypeEnum.EPSTest
      ? this.labelDraft : this.labelGoogleDoc;
    if (
      this.procedureCategory?.procedureTypeID == ProcedureTypeEnum.RadSurvey
    ) {
      this.labelTitleLocationGlobal = "Beamline";
    }

    if (!this.labelNumber) {
      setTimeout(() => {
        this.setLabels();
      }, 1000);
    }
  }

  getSignatures(e: Procedure, type: number) {
    return e.procedureSignatures?.filter((x) => x.signatureType == type);
  }

  getNotes(pnotes?: ProcedureNote[] | null) {
    const notes: Note[] = [];
    pnotes?.map(n => {
      notes.push({ id: n.id ?? 0, user: n.user, description: n.description, date: n.date, type: NoteType.Normal });
    });
    return notes;
  }


  notify(e: Procedure, toAll: boolean) {
    const toAddresses = e.procedureSignatures?.filter(s => toAll ? true : !s.signedOn).map(s => `(${s.user?.name}) ${s.user?.emailAddress}`);
    const messageBody = this.notificationTemplate?.messageBody
      .replaceAll('{procedureNo}', e.procedureNumber)
      .replaceAll('{category}', e.procedureCategory?.name ?? '')
      .replaceAll('{type}', e.procedureCategory?.procedureType?.name ?? '')
      .replaceAll('{title}', e.title ?? '');

    this.notificationMessage = {
      serialNo: e.procedureNumber,
      notificationId: 0,
      documentId: e.id,
      toAddresses: toAddresses?.join(', '),
      messageSubject: this.notificationTemplate?.messageSubject,
      messageBody,
      messageAdditionalText: '',
      signature: this.notificationTemplate?.signature,
    } as NotificationMessage;
    const dialogRef = this.dialog.open(NotificationComponent, {
      height: "fit-content",
      width: "80%",
      data: {
        message: this.notificationMessage,
        templateID: 24,
        emailAddresses: toAddresses,
        readOnly: false,
        send: true
      },
    });

    dialogRef
      .afterClosed()
      .toPromise()
      .then(data => {

      });
  }
}
