<fieldset class="field-group">
  <legend class="field-group-label">Options</legend>

  <div class="pb-0">
    <div class="row no-gutters small">
      <div class="col-6">Label</div>
      <div class="col-5">Value</div>
      <div class="col-1 small">
        <div *ngIf="!disabled" class="btnx" (click)="add()">
          <mat-icon color="primary">add</mat-icon>
        </div>
      </div>
    </div>
    <div
      class="row mb-1 no-gutters"
      *ngFor="let o of tmpOptions; let i = index"
    >
      <div class="col-6 pr-1">
        <mat-form-field class="no-subscript">
          <input
            [disabled]="disabled ?? false"
            matInput
            type="text"
            [(ngModel)]="o.label"
            (blur)="change()"
          />
        </mat-form-field>
      </div>
      <div class="col-5 pr-1">
        <mat-form-field class="no-subscript">
          <input
            [disabled]="disabled ?? false"
            matInput
            type="text"
            [(ngModel)]="o.value"
            (blur)="change()"
          />
        </mat-form-field>
      </div>
      <div class="col-1 small">
        <div *ngIf="!disabled" class="btnx" (click)="del(o)">
          <mat-icon color="warn">delete</mat-icon>
        </div>
      </div>
    </div>
    <div class="row small pt-2">
      <div class="col">
        <mat-checkbox
          [disabled]="disabled ?? false"
          [(ngModel)]="multiple"
          (change)="chkChange()"
        >
          Allow multiple selection
        </mat-checkbox>
      </div>
      <div class="col">
        <mat-checkbox
          *ngIf="multiple"
          [disabled]="disabled ?? false"
          [(ngModel)]="required"
          (change)="chkChange()"
        >
          All Required
        </mat-checkbox>
      </div>
    </div>
  </div>
</fieldset>
