import { Component } from '@angular/core';
import { SignalRService } from './services/signal-r/signal-r.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { AlertService } from './services/alert/alert.service';
import { Store } from '@ngxs/store';
import { SetCurrentUser } from './store/actions';
import { InitStoreAndListenersService } from './services/signal-r/init-store-listeners.service';
import { environment } from 'src/environments/environment';
import { RedirectService } from './components/redirect/redirect.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ASCC-FE4.0';
  expired = false;

  constructor(
    private signalrService: SignalRService,
    private cookieService: CookieService,
    private authService: AuthenticationService,
    private alert: AlertService,
    private initService: InitStoreAndListenersService,
    private store: Store,
    private redirect: RedirectService
  ) {
    window.addEventListener('mousedown', this.updateMousePosition.bind(this));
    // window.addEventListener('mousemove', this.updateMousePosition.bind(this));
  }

  ngOnInit() {
    this.signalrService.startConnection();
    this.initService.initListeners();
    this.login();

    this.authService.getCookieValue().subscribe(data => {
      this.expired = true;
      this.login();
    });
  }

  private updateMousePosition(event: MouseEvent) {
    this.redirect.mouseX = event.clientX + 'px';
    this.redirect.mouseY = event.clientY + 'px';
    // localStorage.setItem('mouseX', event.clientX + 'px');
    // localStorage.setItem('mouseY', event.clientY + 'px');
  }

  login() {
    if (document.location.hostname === environment.productionEnvironment) {
      const cookieValue = this.cookieService.get('_shibobj');
      if (cookieValue) {
        const userEmail = JSON.parse(cookieValue).email;
        this.authService.loginMFA(userEmail).toPromise().then(response => {
          if (response?.code == 202) {
            console.log("User has been Authenticated from MFA");
          }
          else {
            this.alert.error("User Not Found or Not Authorized, Please contact Control Room!", "Unauthorized!");
            console.log("User not found");
            this.authService.logout();
          }

        }).catch(error => {
          const errorMsg = error.status === 423 ? this.alert.message('Login_UserInactive') : error.status === 401 ? this.alert.message('Login_UserUndefined') : this.alert.defaultError();
          console.log(error);
          this.authService.logout();
        }).finally(() => this.initService.initStore());
      }
      else {
        if (this.expired)
          this.alert.warning(
            "Your session has expired. For security purposes, please log in again.", "Session Expired", -1);
        this.authService.logout();
        this.initService.initStore();
      }
    } else {
      this.store.dispatch(new SetCurrentUser(localStorage.getItem('Token')));
      this.initService.initStore();
    }
  }
}
