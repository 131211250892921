<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h4 mat-dialog-title>Add Role</h4>
      <div mat-dialog-content>
        <div class="col-12">
          <div class="row"></div>
        </div>
      </div>
      <div mat-dialog-actions>
        <div class="col-12">
          <div class="row">
            <div class="col-md-6 center-btn">
              <button mat-flat-button color="warn" (click)="cancel()">
                Cancel
              </button>
            </div>
            <div class="col-md-6 center-btn">
              <button mat-flat-button color="primary" (click)="add()">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <span class="card-title">Select Role</span>
  </div>
  <div class="card-body">
    <mat-form-field>
      <input
        matInput
        type="text"
        placeholder="Select Role"
        [matAutocomplete]="auto"
        (keyup)="filter($event)"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let role of rolesFiltered"
          [value]="role.name"
          (click)="getRoleSelected(role)"
        >
          <span [matTooltip]="role.name ?? ''">{{ role.name }}</span>
          <span>({{ role.code }})</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button mat-flat-button color="primary" (click)="add()">Accept</button>
    </div>
  </div>
</div>
