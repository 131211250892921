import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent extends BaseComponent implements OnDestroy, OnInit {

  public devMode = true;
  public toggleVisible: boolean = false;

  loading = false;
  submitted = false;

  dropdown = true;
  username = '';
  usernameCtrl = new FormControl();
  passwordCtrl = new FormControl();
  password = '';

  usersFiltered: User[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    public dialogRef: MatDialogRef<LoginComponent>,
    protected override injector: Injector
  ) {
    super(injector);
    this.loading = false;

    this.devMode = !environment.production;
    this.toggleVisible = environment.toggle;
    this.password = '';
  }

  ngOnInit() {
    this.usersFiltered = this.getUsers().filter(u => u.status == 1);
    this.usersFiltered.map(u => {
      u.roleCodes = u.userRole?.map(r => r.role?.code).join(', ');
    });
    if (this.getCurrentUser()) {
      this.cancel;
      this.loading = false;
    }
  }

  toggle() {
    this.dropdown = !this.dropdown;
    if (this.dropdown) {
      this.getUsers();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  selected(e: MatSelectChange) {
    const user = this.getUsers().find(x => x.id === e.value);
    if (user)
      this.authenticationService.authenticate(user).subscribe(response => {
        this.loading = false;
        this.dialogRef.close(response);
      }, error => {
        console.log(error);
      });
  }
}
