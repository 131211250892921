import { Document } from './../../../../../services/documents/documents';
import { Component, OnInit, HostListener, Output, EventEmitter, OnDestroy, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { Resource, ResourceOrganization, ResourceRelation, ResourceUserRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';
import { CatalogService } from 'src/app/components/catalogs/catalog-service';
import { Link } from 'src/app/components/catalogs/navigation-links/link/link';
import { ScheduleStatusTimeEnum } from 'src/app/components/schedules/models/enums';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { ScheduleBeamlineOverviewResource } from 'src/app/services/schedule/schedule-beamline-overview-resource';
import { BeamlineStatus } from '../../beamline-status';
import { BeamlineOverview } from './beamline-overview';
import { BeamlineOverviewService } from './beamline-overview.service';
import { BaseComponent } from '../../../../../common/base/base.component';
import { FormControl } from '@angular/forms';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { BeamlineRestrictionRefreshByBeamlineID } from 'src/app/components/catalogs/operational-restrictions/store/beamline-restrictions/beamline-restrictions.action';

@Component({
  selector: 'app-beamline-overview-details',
  templateUrl: './beamline-overview-details.component.html',
  styleUrls: ['./beamline-overview-details.component.scss']
})
export class BeamlineOverviewDetailsComponent extends BaseComponent implements OnInit, OnDestroy {

  constructor(
    protected override injector: Injector,
    private beamlineOverviewService: BeamlineOverviewService,
    private router: Router,
    private _catalogService: CatalogService,
    private resourceService: ResourcesService,
    private sanitizer: DomSanitizer
  ) {
    super(injector);
  }

  @Output() formId = new EventEmitter<number>();

  windowScrolled!: boolean;
  loading!: boolean;
  loading2!: boolean;
  id!: number;

  docs?: Document[];
  documentsFiltered?: Document[];
  documents$!: Observable<Document[]>;
  documentsSubs!: Subscription;

  resourceRelations!: ResourceRelation[];
  resourceRelations$!: Observable<ResourceRelation[]>;
  resourceRelationsSubs!: Subscription;

  scfs: Document[] = [];
  ebs: Document[] = [];
  ppstbs: Document[] = [];
  rsswas: Document[] = [];
  kes: Document[] = [];
  absis: Document[] = [];
  rads: Document[] = [];

  overviewCtrl = new FormControl();
  overviewCtrlText!: string | null;

  public beamlineOverview!: BeamlineOverview;
  public beamline!: Resource;

  public documents: Document[] = [];
  public scfDocuments: Document[] = [];
  public rsswaDocuments: Document[] = [];
  public ebDocuments: Document[] = [];

  public beamlineStatus!: string;

  public displayedColumnsBlStatus: string[] = ['beamline', 'shutter', 'pss', 'rss', 'keReady'];
  public displayedColumnsResource: string[] = ['name'];
  public displayedColumnsOrgUserRelation: string[] = ['name', 'endstation', 'branchline'];
  public displayedColumnsSchedule: string[] = ['schedule', 'location', 'dueDate', 'status'];
  public displayedColumnsForms: string[] = ['name', 'status'];
  public dataSourceBlStatus!: MatTableDataSource<BeamlineStatus>;
  public dataSourceEndStation!: MatTableDataSource<Resource>;
  public dataSourceBranchline!: MatTableDataSource<Resource>;
  public dataSourceOrganization!: MatTableDataSource<ResourceOrganization>;
  public dataSourceSupervisor!: MatTableDataSource<ResourceUserRelation>;
  public dataSourceScientist!: MatTableDataSource<ResourceUserRelation>;
  public dataSourceAlternateScientist!: MatTableDataSource<ResourceUserRelation>;
  public dataSourceLink!: MatTableDataSource<Link>;
  public dataSourceSchedule!: MatTableDataSource<ScheduleBeamlineOverviewResource>;
  public dataSourceSCF!: MatTableDataSource<Document>;
  public dataSourceRSSWA!: MatTableDataSource<Document>;
  public dataSourcePPSTB!: MatTableDataSource<Document>;
  public dataSourceEB!: MatTableDataSource<Document>;

  public privilege = PrivilegeEnum;

  ngOnInit() {
    this.clear();
    this.scrollToTop();
    this.loadResourceRelations();
    // this.loadDocuments();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  //Called from Beamline Status links
  loadBeamlineOverview(id: number) {
    this.clear();
    this.store.dispatch(new BeamlineRestrictionRefreshByBeamlineID(id));
    this.scrollToTop();
    if (id) {
      this.id = id;
      this.loading = true;
      this.beamlineOverviewService.GetBeamlineOverviewById(id).subscribe(data => {
        this.addBeamlineOverviewData(data);
        this.getDocs(id);
        this.loading = false;
      });
    }
  }

  loadResourceRelations() {
    this.loading2 = true;
    this.resourceRelations$ = this.store.select(
      (state) => state.ResourceRelations.data
    );
    this.resourceRelationsSubs = this.resourceRelations$.subscribe((data) => {
      if (data.length) {
        this.resourceRelations = data;
        this.loadDocuments();
        this.loading2 = false;
      }
    });
  }

  loadDocuments() {
    this.documents$ = this.store.select(state => state.Documents.data);
    this.documentsSubs = this.documents$.subscribe(data => {
      if (data?.length) {
        // Get and order documents
        this.docs = data.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(b.serialNo, a.serialNo));
        if (this.id) {
          this.getDocs(this.id);
        }
      }
    });
  }

  getDocs(id: number) {
    if (this.resourceRelations && this.docs) {
      // id is beamline, we need to find shutter, branchlines, endstations
      const shutterID = this.resourceRelations?.find(r => r.childResourceID == id)?.parentResourceID;

      // Check if any location matches id or shutterID
      const matchesLocationOrShutterID = (locations: any[] | undefined) => {
        return locations?.some(l => l.id == id || l.id == shutterID);
      };

      // Check if any document matches parent ID (used for endstations and branchlines)
      const matchesParentID = (locations: any[] | undefined) => {
        const parentID = this.resourceRelations?.find(r => locations?.some(l => r.childResourceID == l.id))?.parentResourceID;
        return parentID == id;
      };

      // Sort by document type and location
      const filterDocs = (docTypeID: number | undefined) => {
        return this.docs?.filter(d =>
          d.documentType?.id == docTypeID && (matchesLocationOrShutterID(d.locations) || matchesParentID(d.locations))
        ) ?? [];
      };

      // Applying filters based on document type
      this.scfs = filterDocs(1); // Beamlines, Branchlines
      this.rsswas = filterDocs(3); // Shutters, Branchlines
      this.ppstbs = filterDocs(4); // Shutters, Branchlines
      this.ebs = filterDocs(9); // Shutters, Branchlines
      this.kes = filterDocs(2); // Shutters
      this.rads = filterDocs(120); // Beamlines
      this.absis = filterDocs(11); // Endstations
    }
  }

  addBeamlineOverviewData(data: BeamlineOverview) {
    this.beamlineOverview = data as BeamlineOverview;

    this.beamline = data.resourceSummary.beamline as Resource;
    if (this.beamline.lastCommentByUserId) {
      this.beamline.lastCommentByUser = this.getUserInfo(this.beamline.lastCommentByUserId);
      // this.userService.ReadOne(this.beamline.lastCommentByUserId).toPromise().then(user => {
      //   this.beamline.lastCommentByUser = user;
      // });
    }
    this.overviewCtrlText = this.beamline.comment ?? null;

    this.beamlineStatus = data.resourceSummary.beamline?.statusModel?.statusName as string;

    this.documents = data.beamlineStatus.documents as Document[];
    this.orderDocumentsByType(this.documents);

    this.dataSourceBlStatus.data.push(data.beamlineStatus as BeamlineStatus);
    this.dataSourceBlStatus.filter = '';

    this.dataSourceEndStation.data = data.resourceSummary.endstations as Resource[];
    this.dataSourceBranchline.data = data.resourceSummary.branchlines as Resource[];
    this.dataSourceOrganization.data = data.resourceSummary.organizations as ResourceOrganization[];
    this.dataSourceSupervisor.data = data.resourceSummary.supervisors as ResourceUserRelation[];
    this.dataSourceScientist.data = data.resourceSummary.scientists as ResourceUserRelation[];
    this.dataSourceAlternateScientist.data = data.resourceSummary.alternateScientists as ResourceUserRelation[];
    this.dataSourceLink.data = data.resourceSummary.otherLinks as Link[];

    this.dataSourceSchedule.data = this.setScheduleStatusName(data.schedules) as ScheduleBeamlineOverviewResource[];

    // this.dataSourceSCF.data = data.scfDocuments as Document[];
    // this.dataSourceRSSWA.data = data.rsswaDocuments as Document[];
    // this.dataSourceEB.data = data.ebDocuments as Document[];
  }

  orderDocumentsByType(documents: Document[]) {
    if (documents.length > 0) {
      documents.map(document => {
        if (document.type === FormType.SCF) {
          this.scfDocuments.push(document);
        }
        if (document.type === FormType.RSSWA) {
          this.rsswaDocuments.push(document);
        }
        if (document.type === FormType.EBF) {
          this.ebDocuments.push(document);
        }
      });
    }
  }

  setScheduleStatusName(schedules: ScheduleBeamlineOverviewResource[]): ScheduleBeamlineOverviewResource[] {
    if (schedules) {
      schedules.map(schedule => {
        schedule.statusTimeName = ScheduleStatusTimeEnum[schedule.statusTime];
        if (schedule.statusTimeName == 'Expired') {
          schedule.cssColor = 'red';
        }
        else schedule.cssColor = 'black';
      });
    }
    return schedules;
  }

  getClass(text: string) {
    let style = '';
    if (text) {
      style = text.trim().toLowerCase();
    }
    switch (style) {
      case 'offline': return 'badge badge-offline badge-pill';
      case 'online': return 'badge badge-online badge-pill';
      case 'open': return 'badge badge-open badge-pill';
      case 'closed': return 'badge badge-closed badge-pill';
      case 'error': return 'badge badge-error badge-pill';
      default: return 'badge badge-unknown badge-pill';
    }
  }

  commentChanged(e: any) {
    this.overviewCtrlText = e;
    this.beamline.comment = this.overviewCtrlText ?? '';
    this.beamline.lastCommentByUser = this.currentUser;

    this.resourceService.saveResource(this.beamline).toPromise().then((beamlineUpdated) => {
      if (beamlineUpdated) {
        this.beamline = beamlineUpdated;
        this.beamline.lastCommentByUser = this.getUserInfo(this.beamline.lastCommentByUserId ?? 0);
      }
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    } else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }

  navigateToSchedule(scheduleElement: ScheduleBeamlineOverviewResource) {
    this._catalogService.currentDocSchedule = scheduleElement;
    switch (scheduleElement.scheduleType) {
      case 'Annual Beamline Safety Inspection':
        this.router.navigate(['schedulesV2/1']);
        break;
      case 'Equipment Protection System':
        this.router.navigate(['schedulesV2/2']);
        break;
      case 'Annual Top Off Critical Aperture':
        this.router.navigate(['schedulesV2/3']);
        break;
      case 'Radiation Surveys':
        this.router.navigate(['schedulesV2/4']);
        break;
      case 'Radiation Safety System':
        this.router.navigate(['schedulesV2/5']);
        break;
      case 'LOTO Procedure Inspection':
        this.router.navigate(['schedulesV2/7']);
        break;
      default:
        break;
    }
  }

  navigateToForm(formDocument: Document) {
    this.router.navigate(['redirect/' + formDocument.serialNo]);
  }

  deleteBlComment() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('Confirm_Note_Delete_Question')?.description,
        icon: 'stop'
      }
    });
    confirm.afterClosed().toPromise().then(async data => {
      if (data) {

        this.beamline.comment = '';
        this.overviewCtrlText = null;
        this.resourceService.saveResource(this.beamline).toPromise().then((beamlineUpdated) => {
          if (beamlineUpdated) {
            this.beamline = beamlineUpdated;
            this.beamline.lastCommentByUser = this.getUserInfo(this.beamline.lastCommentByUserId ?? 0);
          }
        });
      }
    });
  }

  getHint() {
    return this.beamline.lastCommentByUserId ? this.sanitizer.bypassSecurityTrustHtml(this.getUpdateText() + `<b title="${this.beamline.lastCommentByUser?.name}">${this.beamline.lastCommentByUser?.initials}</span> on <span class="date">${moment(this.beamline.lastCommentOn).format('M/D/YY, h:mm A')}</span>`) : null;
  }

  getUpdateText(): string {
    if (!this.beamline.comment) {
      return 'Last deleted by ';
    } else {
      return 'Last updated by ';
    }
  }

  isAbleToEditComment(): boolean {
    if (this.beamline.id && this.currentUser) {
      if (this.hasPrivilege(this.privilegeEnum.AddEditBlOverviewComment)) {
        return true;
      }
    }
    return false;
  }

  isAbleToDeleteComment(): boolean {
    if (this.beamline.id && this.currentUser && this.beamline.comment) {
      if (this.beamline.lastCommentByUserId === this.currentUser.id || this.hasPrivilege(this.privilegeEnum.DeleteBlOverviewComment)) {
        return true;
      }
    }
    return false;
  }

  clear() {
    this.beamlineOverview = {} as BeamlineOverview;
    this.beamline = new Resource();

    this.beamlineStatus = '';
    this.overviewCtrlText = null;

    this.documents = [];
    this.scfDocuments = [];
    this.rsswaDocuments = [];
    this.ebDocuments = [];


    this.scfs = [];
    this.rsswas = [];
    this.ppstbs = [];
    this.ebs = [];
    this.kes = [];
    this.absis = [];
    this.rads = [];

    this.dataSourceBlStatus = new MatTableDataSource();
    this.dataSourceEndStation = new MatTableDataSource();
    this.dataSourceBranchline = new MatTableDataSource();
    this.dataSourceOrganization = new MatTableDataSource();
    this.dataSourceSupervisor = new MatTableDataSource();
    this.dataSourceScientist = new MatTableDataSource();
    this.dataSourceAlternateScientist = new MatTableDataSource();
    this.dataSourceLink = new MatTableDataSource();
    this.dataSourceSchedule = new MatTableDataSource();
    this.dataSourceSCF = new MatTableDataSource();
    this.dataSourceRSSWA = new MatTableDataSource();
    this.dataSourcePPSTB = new MatTableDataSource();
    this.dataSourceEB = new MatTableDataSource();
  }

}
