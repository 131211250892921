<div class="card">
  <div class="card-header">
    <span class="card-title">Save Roster</span>
  </div>
  <div class="card-body">
    <mat-form-field appearance="outline" class="input">
      <mat-label>Roster Name</mat-label>
      <input
        type="text"
        placeholder="Roster Name"
        matInput
        name="rosterCtrl"
        [formControl]="rosterCtrl"
        [matAutocomplete]="autoRoster"
        (keyup)="changedRoster($event)"
      />
      <mat-autocomplete
        #autoRoster="matAutocomplete"
        #rosterAutocomplete
        [displayWith]="displayObject"
        (optionSelected)="selectedReviewAttendanceSave($event)"
      >
        <mat-option
          *ngFor="let roster of reviewAttendanceSavedFiltered"
          [value]="roster"
        >
          <span>{{ roster.name }}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-hint>Select a Roster from the list or Enter a new Name</mat-hint>
      <mat-error></mat-error>
    </mat-form-field>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-stroked-button class="mr-2" color="warn" (click)="cancel()">
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="save()"
        [disabled]="disabledSave()"
      >
        Save
      </button>
    </div>
  </div>
</div>
