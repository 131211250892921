import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, } from "@angular/core";
import { MatDialogConfig, } from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable, Subscription } from "rxjs";
import { BaseComponent } from "src/app/common/base/base.component";
import { RadNoteType } from "src/app/common/enumerations/enumerations";
import { YesNoDialogComponent } from "src/app/controls/yes-no-dialog/yes-no-dialog.component";
import { RadInterlock } from "src/app/components/catalogs/rad-monitor/rad-monitor/rad-interlock";
import { RadLocation } from "src/app/components/catalogs/rad-monitor/rad-monitor/rad-location";
import { RadLocationType } from "src/app/components/catalogs/rad-monitor/rad-monitor/rad-location-type";
import { RadNote } from "src/app/components/catalogs/rad-monitor/rad-monitor/rad-note";
import { PrivilegeEnum } from "src/app/services/role-privilege/privilege-enum";
import { RadLocationService } from "../services/rad-location.service";
import { RadNoteService } from "../services/rad-note.service";
import { RadLocationRefresh } from "../store/rad-location/rad-location.action";
import { RadMonitorAddEditLocationComponent } from "../dialogs/add-edit-location/add-edit-location.component";
import { RadNoteComponent } from "../dialogs/add-rad-note/add-rad-note.component";
import { MessagePlaceholder } from "src/app/common/models/placeholder";
import { Note } from "src/app/services/notes/note.model";
import { filter } from 'rxjs/operators';


@Component({
  selector: "rad-monitor-location-tab",
  templateUrl: "./location-tab.component.html",
  styleUrls: ["./location-tab.component.scss"],
})
export class RadMonitorLocationTabComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() filter?: string | null;
  @Output() loading = new EventEmitter<boolean>();

  public radLocations$!: Observable<RadLocation[]>;
  public radLocationTypes$!: Observable<RadLocationType[]>;
  public radInterlocks$!: Observable<RadInterlock[]>;

  public radLocSubscription!: Subscription;
  public radLocTypeSubscription!: Subscription;
  public radInterlockSubscription!: Subscription;
  public messagesSubscription!: Subscription;

  public radLocations: RadLocation[] = [];
  public radLocationsFiltered: RadLocation[] = [];
  public radLocationTypes: RadLocationType[] = [];
  public radInterlocks: RadInterlock[] = [];

  public valueFilter = "";
  public headerEMShop = "Not Installed";

  public privilege = PrivilegeEnum;
  public radNoteType = RadNoteType;

  public displayedColumnsRadMonitor: string[] = [
    "locationName",
    "type",
    "rpixName",
    "description",
    "controllerLocation",
    "controllerPower",
    "resetLocation",
    "interlockChain",
    "summation",
    "tripsetA1",
    "tripsetI1",
    "tripset",
    "notes",
    "emShop",
    "options",
  ];
  public dataSourceRadLocation: MatTableDataSource<RadLocation> =
    new MatTableDataSource();

  @ViewChild("table") table!: ElementRef;
  headerOnTop = false;
  tableWidth = "";

  constructor(
    protected override injector: Injector,
    private radLocationService: RadLocationService,
    private radNoteService: RadNoteService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.setLabels();
    this.loadLocationTypes();
    this.loadLocations();
    this.loadRadInterlocks();
  }

  loadLocationTypes() {
    this.loading.emit(true);
    this.radLocationTypes$ = this.store.select((state) => state.RadLocationType.data);
    this.radLocTypeSubscription = this.radLocationTypes$.subscribe(
      (radLocationTypes) => {
        this.radLocationTypes = radLocationTypes;
        this.loading.emit(false);
      }
    );
  }

  loadLocations() {
    this.loading.emit(true);
    this.radLocations$ = this.store.select(
      (state) => state.RadLocation.data
    );
    this.radLocSubscription = this.radLocations$.subscribe((radLocations) => {
      if (radLocations.length) {
        this.radLocations = radLocations;
        this.radLocationsFiltered = radLocations;
        this.dataSourceRadLocation = new MatTableDataSource(this.radLocationsFiltered);
        this.sortData(this.dataSourceRadLocation.sort);
        this.setRadNotes();
        this.joinLocInterlockRelation();
        this.loading.emit(false);
      }
    });
  }

  loadRadInterlocks() {
    this.loading.emit(true);
    this.radInterlocks$ = this.store.select(
      (state) => state.RadInterlock.data
    );
    this.radInterlockSubscription = this.radInterlocks$.subscribe(
      (radInterlocks) => {
        this.radInterlocks = radInterlocks;
        this.loading.emit(false);
      }
    );
  }

  override ngOnDestroy() {
    this.radLocSubscription?.unsubscribe();
    this.radLocSubscription?.unsubscribe();
    this.radInterlockSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["filter"])
      this.applyFilter();
  }

  setLabels() {
    this.headerEMShop = this.getMessage("RadLocation_EMShop")?.description;
  }

  locationDialog(radLocation?: RadLocation) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      radLocation: radLocation ? this.utils.cloneDeep(radLocation)
        : new RadLocation(),
      radLocationTypes: this.radLocationTypes,
      radInterlocks: this.radInterlocks,
      dialogTitle: radLocation ? "Edit Location" : "Add Location",
    };
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '500px';
    const dialogRefLoc = this.dialog.open(
      RadMonitorAddEditLocationComponent,
      dialogConfig
    );
    dialogRefLoc
      .afterClosed()
      .toPromise()
      .then((radLocationToSave: RadLocation) => {
        if (radLocationToSave) {
          if (radLocationToSave.id) {
            this.radLocationService.UpdateRadLocation(radLocationToSave.id, radLocationToSave).toPromise().then(() => {
              this.alert.message("RadLocation_Updated");
            })
              .catch(() => {
                this.alert.defaultError();
              });
          } else {
            this.radLocationService.CreateRadLocation(radLocationToSave).toPromise().then(() => {
              this.alert.message("RadLocation_Created");
            })
              .catch(() => {
                this.alert.defaultError();
              });
          }
        }
      });
  }

  noteDialog(radLocation: RadLocation, radNoteType: RadNoteType) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      noteDescription: "",
      inputLabel: "Note",
      dialogTitle: "Add Note",
    };
    dialogConfig.width = "20em";
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(RadNoteComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .toPromise()
      .then((noteDescription: string) => {
        if (noteDescription) {
          const radNote: RadNote = {
            description: noteDescription,
            radLocationId: radLocation.id,
            type: radNoteType,
          };
          this.radNoteService.CreateRadNote(radNote).toPromise().then(() => {
            this.alert.message("RadNote_Created");
          })
            .catch(() => {
              this.alert.defaultError();
            });
        }
      });
  }

  async deleteLocation(radLocation: RadLocation) {
    if (await this.deleteConfirmationMsg("RadLocation_SureToDelete")) {
      if (radLocation.id)
        this.radLocationService.DeleteRadLocation(radLocation.id).toPromise().then(() => {
          this.alert.message("RadLocation_Deleted");
        })
          .catch((error: HttpErrorResponse) => {
            if (error.status === 409) {
              this.alert.message("RadCatalogs_Deleted", [new MessagePlaceholder("{what}", error.error)]);
            } else {
              this.alert.defaultError();
            }
          });
    }
  }

  async deleteNote(radNote: RadNote) {
    let canDelete = true;
    if (radNote.type === RadNoteType.Fault) {
      canDelete = await this.deleteConfirmationMsg("RadFaultNote_SureToDelete");
    }
    if (canDelete && radNote.id) {
      this.radNoteService.DeleteRadNote(radNote.id).toPromise().then(() => {
        this.alert.message("RadNote_Deleted");
      })
        .catch(() => {
          this.alert.defaultError();
        });
    }
  }

  async deleteConfirmationMsg(messageName: string) {
    const confirmation = this.dialog.open(YesNoDialogComponent, {
      width: "500px",
      data: {
        message: this.getMessage(messageName).description,
      },
    });
    return confirmation
      .afterClosed()
      .toPromise()
      .then((isSure: boolean) => {
        return Promise.resolve(isSure);
      });
  }

  setRadNotes() {
    this.dataSourceRadLocation.data.map((radLoc) => {
      radLoc.notes = radLoc.radNotes?.filter((note) => note.type === RadNoteType.Normal)?.map(n => {
        const note: Note = {
          id: n.id ?? 0,
          user: n.createdByUser,
          date: n.createdOn,
          description: n.description,
          type: n.type
        };
        return note;
      });

      radLoc.notesf = radLoc.radNotes?.filter((note) => note.type === RadNoteType.Fault)?.map(n => {
        n.radMonitor = radLoc.radMonitors?.find(r => r.id == n.radMonitorId);
        const note: Note = {
          id: n.id ?? 0,
          user: n.createdByUser,
          date: n.createdOn,
          description: n.description,
          noteName: 'EHS#: ' + n.radMonitor?.ehs + ' - Location: ' + radLoc?.name,
          type: n.type
        };
        return note;
      });
    });
  }

  setEMShop(isEMShop: boolean, radLocation: RadLocation) {
    radLocation.isEMShop = isEMShop;
    radLocation.radLocationInterlockRelations?.map((x) => {
      x.id = 0;
      x.radInterlock = null;
      x.radLocation = null;
    });
    if (radLocation.id)
      this.radLocationService.UpdateRadLocation(radLocation.id, radLocation).toPromise().then(() => {
        this.store.dispatch(new RadLocationRefresh());
      })
        .catch(() => {
          this.alert.defaultError();
        });
  }

  joinLocInterlockRelation() {
    this.dataSourceRadLocation.data.map((radLoc) => {
      const interlockNames: string[] = [];
      radLoc?.radLocationInterlockRelations?.map((locinterlockRelation) => {
        if (locinterlockRelation.radInterlock?.name)
          interlockNames.push(locinterlockRelation.radInterlock.name);
      });
      radLoc.interlockNames = interlockNames.join(", ");
    });
  }

  applyFilter() {
    if (this.filter) {
      const filter = this.filter.toLowerCase();
      this.radLocationsFiltered = this.radLocations.filter(data =>
        data.name?.toLowerCase().includes(filter) ||
        data.radLocationType?.name?.toLowerCase().includes(filter) ||
        data.interlockNames?.toString().includes(filter) ||
        data.rpixName?.toLowerCase().includes(filter) ||
        data.description?.toLowerCase().includes(filter) ||
        data.controllerLocation?.toLowerCase().includes(filter) ||
        data.controllerPower?.toLowerCase().includes(filter) ||
        data.resetLocation?.toLowerCase().includes(filter) ||
        data.summation?.toLowerCase().includes(filter) ||
        data.tripset?.toString().includes(filter) ||
        data.tripsetA1?.toString().includes(filter) ||
        data.tripsetI1?.toString().includes(filter));
    }
    else this.radLocationsFiltered = this.radLocations;
    this.dataSourceRadLocation = new MatTableDataSource(this.radLocationsFiltered);
  }

  sortData(sort?: Sort | null) {
    const dataToSort = this.utils.cloneDeep(this.dataSourceRadLocation.data);
    if (!sort?.active || sort.direction === "") {
      this.dataSourceRadLocation.data = dataToSort;
      return;
    }
    this.dataSourceRadLocation.data = dataToSort.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort?.active) {
        case "locationName":
          return this.utils.sort(a.name, b.name, isAsc);
        case "type":
          return this.utils.sort(a.radLocationType?.name, b.radLocationType?.name, isAsc);
        case "rpixName":
          return this.utils.sort(a.rpixName, b.rpixName, isAsc);
        case "description":
          return this.utils.sort(a.description, b.description, isAsc);
        case "controllerLocation":
          return this.utils.sort(a.controllerLocation, b.controllerLocation, isAsc);
        case "controllerPower":
          return this.utils.sort(a.controllerPower, b.controllerPower, isAsc);
        case "resetLocation":
          return this.utils.sort(a.resetLocation, b.resetLocation, isAsc);
        case "interlockChain":
          return this.utils.sort(a.interlockNames, b.interlockNames, isAsc);
        case "summation":
          return this.utils.sort(a.summation, b.summation, isAsc);
        case "tripsetA1":
          return this.utils.sort(a.tripsetA1, b.tripsetA1, isAsc);
        case "tripsetI1":
          return this.utils.sort(a.tripsetI1, b.tripsetI1, isAsc);
        case "tripset":
          return this.utils.sort(a.tripset, b.tripset, isAsc);
        default:
          return 0;
      }
    });
  }

  validateDisableEMShop(radLocation: RadLocation): boolean {
    if (!this.hasPrivilege(PrivilegeEnum.AddRadLocationEMShop)) {
      return true;
    }
    if (radLocation?.isEMShop) {
      return false;
    }
    if (
      this.dataSourceRadLocation.data.some((x) => x.isEMShop && x.radLocationType?.id == radLocation.radLocationType?.id
      )
    ) {
      return true;
    }
    return false;
  }

  validateShowMenu(): boolean {
    if (this.hasPrivilege(PrivilegeEnum.EditRadLocation)
    ) {
      return true;
    }
    if (this.hasPrivilege(PrivilegeEnum.CreateRadLocationNote)
    ) {
      return true;
    }
    if (
      this.hasPrivilege(PrivilegeEnum.DeleteRadLocation)
    ) {
      return true;
    }
    return false;
  }


}
