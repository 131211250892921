import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatFileUploadService } from '../mat-file-upload.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ProgressStatus, ProgressStatusEnum } from './mat-file-progress';
import { HttpEventType } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import FileV2 from 'src/app/services/file/file-v2.model';
import { FileV2Service } from 'src/app/services/file/file-v2.service';
import { MessageBannerService } from 'src/app/components/messages/services/message-banner.service';

@Component({
  selector: 'mat-file',
  templateUrl: './mat-file.component.html',
  styleUrls: ['./mat-file.component.scss']
})
/** mat-file component*/
export class MatFileComponent {

  @Input() fileIcon!: string;
  @Input() fileName!: string;
  @Input() fileSize!: string;
  @Input() fileUrl!: SafeResourceUrl;
  @Input() id!: string;
  @Input() disabled?: boolean;
  @Input() rootDirectory!: string;
  @Input() save_files_database = '';

  @Output() public downloadStatus: EventEmitter<ProgressStatus>;
  @Output() deletedAttachmentFile = new EventEmitter<string>();

  fileServer!: FileV2 | null;

  constructor(
    private uploadServ: MatFileUploadService,
    private file2Service: FileV2Service,
    public dialog: MatDialog,
    public _dMessage: MessageBannerService
  ) {
    this.downloadStatus = new EventEmitter<ProgressStatus>();
  }

  public async obtainFile(rootDirectoryAttachment: string) {
    if (rootDirectoryAttachment != '') {
      await this.obtainFileServer(rootDirectoryAttachment);
      if (this.fileServer != null) {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', this.fileServer.url);
        link.setAttribute('download', this.fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.log('Error: Could not get file information (obtainFileServer function in mat-file component)');
      }
    } else {
      this.download();
    }
  }

  obtainFileServer(rootDirectoryAttachment: string) {
    const promise = new Promise<FileV2 | null>(async (resolve) => {
      const fileServerId: number = Number(rootDirectoryAttachment ?? 0);
      let fileServedFound: FileV2 | null = null;

      await this.file2Service
        .obtainFile(fileServerId)
        .toPromise()
        .then(
          (file: FileV2) => {
            fileServedFound = file;
            this.fileServer = file;
          },
          (error) => {
            fileServedFound = null;
            this.fileServer = null;
            console.error(error);
          }
        );
      resolve(fileServedFound);
    });
    return promise;
  }

  public download() {
    this.downloadStatus.emit({ status: ProgressStatusEnum.START });
    this.uploadServ.downloadFile(this.id).subscribe(
      (data) => {
        if (data)
          switch (data.type) {
            case HttpEventType.DownloadProgress:
              this.downloadStatus.emit({ status: ProgressStatusEnum.IN_PROGRESS, percentage: Math.round((data.loaded / (data.total ?? 0)) * 100) });
              break;
            case HttpEventType.Response:
              this.downloadStatus.emit({ status: ProgressStatusEnum.COMPLETE });
              if (data.body) {
                const downloadedFile = new Blob([data.body], { type: data.body.type });
                const a = document.createElement('a');
                a.setAttribute('style', 'display:none;');
                document.body.appendChild(a);
                a.download = this.fileName;
                a.href = URL.createObjectURL(downloadedFile);
                a.target = '_blank';
                a.click();
                document.body.removeChild(a);
              }
              break;
          }
      },
      error => {
        this.downloadStatus.emit({ status: ProgressStatusEnum.ERROR });
      }
    );
  }

  deleteAttachment(id: string, rootDirectoryAttachment: string) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this._dMessage.get('attachments_Delete_File_Confirmation').description,
        icon: 'stop'
      }
    });

    confirm.afterClosed().subscribe(data => {
      if (data) {
        this.uploadServ.deleteFile(id).subscribe((res) => {
          const fileServerId: number = Number(rootDirectoryAttachment ?? 0);
          if (fileServerId != 0) {
            this.file2Service.delete(fileServerId).subscribe(
              () => this.deletedAttachmentFile.emit(id),
              (error) => {
                console.error(error);
                this.deletedAttachmentFile.emit(id);
              }
            );
          }
          else {
            this.deletedAttachmentFile.emit(id);
          }
        });
      }
    });
  }
}
