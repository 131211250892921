import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, ViewChild, Output, EventEmitter, Injector } from '@angular/core';
import { SCFPrivileges, SCFStatus } from '../scf-v2.service';
import { SCFMaster } from '../scf';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ScfV2MainS1Component } from './scf-v2-main-s1/scf-v2-main-s1.component';
import { ScfV2MainS2Component } from './scf-v2-main-s2/scf-v2-main-s2.component';
import { ScfV2MainS3Component } from './scf-v2-main-s3/scf-v2-main-s3.component';
import { ScfV2MainS4Component } from './scf-v2-main-s4/scf-v2-main-s4.component';
import { ScfV2MainS5Component } from './scf-v2-main-s5/scf-v2-main-s5.component';
@Component({
  selector: 'scf-v2-main',
  templateUrl: './scf-v2-main.component.html',
  styleUrls: ['./scf-v2-main.component.scss']
})
export class ScfV2MainComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() scfMaster?: SCFMaster | null;
  @Input() privileges?: SCFPrivileges;
  @Input() status?: SCFStatus;
  @Input() disabled?: boolean;
  @Input() showAmendment?: boolean;

  @Output() setStatus = new EventEmitter<SCFStatus>();
  @Output() saved = new EventEmitter<SCFMaster>();
  @Output() loading = new EventEmitter<boolean>();

  @ViewChild(ScfV2MainS1Component)
  section1!: ScfV2MainS1Component;
  @ViewChild(ScfV2MainS2Component) section2?: ScfV2MainS2Component;
  @ViewChild(ScfV2MainS3Component) section3?: ScfV2MainS3Component;
  @ViewChild(ScfV2MainS4Component) section4?: ScfV2MainS4Component;
  @ViewChild(ScfV2MainS5Component) section5?: ScfV2MainS5Component;

  loading1: boolean = false;
  loading3: boolean = false;
  loading4: boolean = false;
  loading5: boolean = false;

  constructor(
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
  }

  refresh() {
    this.section2?.refresh();
    this.section3?.refresh();
    this.section4?.refresh();
    this.section5?.refresh();
  }

  changed(e: SCFMaster | undefined | null = this.scfMaster) {
    this.scfMaster = e;
    this.refresh();
  }

  setLoading(val: boolean, s: number) {
    switch (s) {
      case 1:
        this.loading1 = val;
        // this.loading.emit(val);
        break;
      case 3:
        this.loading3 = val;
        break;
      case 4:
        this.loading4 = val;
        break;
      case 5:
        this.loading5 = val;
        break;
    }
    this.loading.emit(this.loading1 || this.loading3 || this.loading4 || this.loading5);
  }

  setStatusEvent(status: SCFStatus) {
    this.setStatus.emit(status);
  }

  savedEvent(e: SCFMaster) {
    this.saved.emit(e);
  }

  formValid() {
    return this.section1?.formValid();
  }

  setChecksFromWorkRequired(e: any) {
    this.section5?.setChecksFromWorkRequired(e);
  }
}
