<div class="row py-1 d-flex justify-content-between">
  <div class="d-flex justify-content-end align-items-center">
    <mat-checkbox
      [(ngModel)]="s.required"
      [labelPosition]="'before'"
      color="primary"
      (change)="changeRequired($event)"
      [disabled]="disabled"
      *ngIf="
        currentVersion?.status == checklistTemplateStatusEnum.Draft ||
        (currentVersion?.status ==
          checklistTemplateStatusEnum.CorrectionsInProgress &&
          annotations?.hasAnnotations)
      "
    ></mat-checkbox>
  </div>
  <div
    class="col-9 justify-content-end align-items-center py-0 px-0"
    [ngClass]="{
      'task-error': hasErrors(),
    }"
  >
    <master-control
      [id]="s.id"
      [type]="s.type"
      [builder]="true"
      [editor]="false"
      [visible]="true"
      [disabled]="true"
      [enabled]="true"
      [approve]="false"
      [disapprove]="false"
      [unapprove]="false"
      [color]="color"
      [number]="s.number"
      [header]="s.name"
      [label]="s.name"
      [text]="s.question"
      [question]="s.question"
      [radioQuestion]="s.question2"
      [options]="s.options"
      [radioOptions]="s.options"
      [controlID]="s.value2"
      (changed)="change($event)"
      [checkbox_checked]="s.approved"
      [approved]="s.approved"
      [approvedBy]="s.approvedBy"
      [approvedOn]="s.approvedOn"
      [roleIds]="s.roles"
      [roleCodes]="s.roleCodes"
      [scheduleResourceId]="s.scheduleResourceId"
      [scheduleTypeId]="s.scheduleTypeId"
      [textBefore]="
        componentsToSwapText.includes(s.type ?? 0) ? s.question2 : s.question
      "
      [textAfter]="
        componentsToSwapText.includes(s.type ?? 0) ? s.question : s.question2
      "
      [textAfter2]="s.question3"
      [textValue]="s.textValue"
      [checkboxVisible]="s.visible != undefined ? s.visible : true"
      [disableEdit]="true"
      [dateValue1]="s.dateValue1"
      [dateValue2]="s.dateValue2"
      [dateValue3]="s.dateValue3"
      [configuration]="s.configuration"
      [editMode]="s.editMode"
      [currentChecklistTemplate]="currentVersion"
      style="width: 100%"
    >
    </master-control>
    <div
      class="warning"
      *ngIf="hasErrors()"
      [matTooltip]="errorMessage"
      matTooltipClass="warn-tooltip"
      matTooltipPosition="right"
    >
      <mat-icon color="warn">warning</mat-icon>
    </div>
  </div>
  <div class="col-2 d-flex justify-content-between align-items-center pl-0">
    <div
      class="indicator d-flex flex-column justify-content-between"
      *ngIf="s.code || s.condition; else empty"
    >
      <div>
        <mat-icon
          class="small-icon"
          color="accent"
          *ngIf="s.code"
          [matTooltip]="s.code"
          >local_offer</mat-icon
        >
      </div>
      <div>
        <mat-icon
          class="pink small-icon"
          *ngIf="s.condition"
          [matTooltip]="s.condition"
          >device_hub</mat-icon
        >
      </div>
    </div>
    <ng-template #empty>&nbsp;</ng-template>
    <div class="d-flex justify-content-end align-items-center">
      <div
        class="d-flex justify-content-between flex-row"
        *ngIf="
          !disabled &&
            (currentVersion?.status == checklistTemplateStatusEnum.Draft ||
              currentVersion?.status ==
                checklistTemplateStatusEnum.CorrectionsInProgress ||
              currentVersion?.status == checklistTemplateStatusEnum.Temp);
          else viewButton
        "
      >
        <button
          mat-icon-button
          color="accent"
          matTooltip="Edit Task"
          (click)="edit(false)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          matTooltip="Duplicate Task"
          (click)="duplicate()"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          (click)="delete()"
          matTooltip="Delete Task"
          [disabled]="hasAnnotations"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      <ng-template #viewButton>
        <button mat-icon-button (click)="edit(true)" matTooltip="View">
          <mat-icon color="primary">visibility</mat-icon>
        </button>
      </ng-template>

      <button
        mat-icon-button
        class="purple"
        matTooltip="'Suggest change'"
        (click)="annotate()"
        *ngIf="
          (
            currentVersion?.status == checklistTemplateStatusEnum.InReview &&
            isReviewer()) ||
          (currentVersion?.status == checklistTemplateStatusEnum.Suggesting &&
            isApprover()) ||
          (isRCP &&
            (isPreparer() || isApprover()) &&
            !(
              currentVersion?.status == checklistTemplateStatusEnum.Draft ||
              currentVersion?.status == checklistTemplateStatusEnum.Active
            ))
        "
      >
        <mat-icon class="material-icons-outlined">rate_review</mat-icon>
      </button>
      <button
        mat-icon-button
        class="gray"
        matTooltip="More info"
        (click)="expanded = !expanded"
      >
        <mat-icon class="material-icons-outlined" *ngIf="!expanded"
          >expand_more</mat-icon
        >
        <mat-icon class="material-icons-outlined" *ngIf="expanded"
          >expand_less</mat-icon
        >
      </button>
    </div>
  </div>
</div>

<checklist-builder-task-more
  *ngIf="expanded"
  [task]="task"
></checklist-builder-task-more>

<checklist-builder-annotations
  [task]="task"
  [currentVersion]="currentVersion"
  [s]="s"
  *ngIf="currentVersion?.status != checklistTemplateStatusEnum.Active"
  (changed)="annotationChanged($event)"
></checklist-builder-annotations>
