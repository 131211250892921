import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SCFMastersCreate, SCFMastersDelete, SCFMastersRefresh, SCFMastersRefreshById, SCFMastersUpdate } from './scf-masters.action';
import { ScfV2Service } from '../../scf-v2.service';
import { SCFMaster } from '../../scf';


export class SCFMastersStateModel {
  data: SCFMaster[] = [];
}

@State<SCFMastersStateModel>({
  name: 'SCFMasters',
  defaults: {
    data: []
  }
})
@Injectable()
export class SCFMastersState {
  constructor(
    private _service: ScfV2Service
  ) { }

  @Selector()
  static read(state: SCFMastersStateModel) {
    return state.data;
  }

  @Action(SCFMastersRefresh)
  refresh({ patchState }: StateContext<SCFMastersStateModel>) {
    this._service.getAll().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(SCFMastersRefreshById)
  refreshById({ getState, patchState }: StateContext<SCFMastersStateModel>, { id }: SCFMastersRefreshById) {
    this._service.getById(id).subscribe(
      data => {
        const state = getState();
        const index = state.data.map(x => x.id).indexOf(data.id);
        if (index >= 0) {
          state.data.splice(index, 1);
        }
        patchState({
          data: [...getState().data, data]
        });
      },
      error => console.error(error)
    );
  }

  @Action(SCFMastersCreate)
  create({ getState, patchState }: StateContext<SCFMastersStateModel>, { item }: SCFMastersCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(SCFMastersUpdate)
  update({ getState, patchState }: StateContext<SCFMastersStateModel>, { id, item }: SCFMastersUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(SCFMastersDelete)
  delete({ getState, patchState }: StateContext<SCFMastersStateModel>, { id }: SCFMastersDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
