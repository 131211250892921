import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { AlertService } from '../services/alert/alert.service';
import { SetCurrentUser } from '../store/actions';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  constructor(private router: Router, private store: Store, private alertService: AlertService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url === environment.urls.ologUrl || req.url.includes('controls.als.lbl.gov')) {
      return next.handle(req);
    }
    const token = localStorage.getItem('Token');
    let authReq = req;
    if (token) {
      const authToken = 'Bearer ' + token;
      authReq = req.clone({
        headers: req.headers.set('Authorization', authToken),
        withCredentials: true
      });
    }
    else {
      authReq = req.clone({
        withCredentials: true
      });
    }

    // send cloned request with header to the next handler.
    return next.handle(authReq).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 || err.status === 403) {
          const token = localStorage.getItem('Token');
          if (token) {
            this.store.dispatch(new SetCurrentUser(null));
            this.alertService.warning(environment.messages.sessionFinished);
          }
          this.router.navigateByUrl('/home');
        }
        return throwError(err);
      })
    );
  }
}
