import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { RadNoteType } from 'src/app/common/enumerations/enumerations';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { RadLocation } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location';
import { RadMonitor } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor';
import { RadMonitorDescription } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor-description';
import { RadMonitorType } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor-type';
import { RadMonitorService } from 'src/app/components/catalogs/rad-monitor/services/rad-monitor.service';
import { RadNote } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-note';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { RadNoteService } from '../services/rad-note.service';
import { RadNoteComponent } from '../dialogs/add-rad-note/add-rad-note.component';
import { RadMonitorAddEditMonitorComponent } from './add-edit-monitor/add-edit-monitor.component';
import { Note } from '../../../../services/notes/note.model';

@Component({
  selector: 'rad-monitor-tab',
  templateUrl: './monitor-tab.component.html',
  styleUrls: ['./monitor-tab.component.scss']
})
export class RadMonitorTabComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() filter?: string | null;
  @Output() loading = new EventEmitter<boolean>();

  public radMonitors$!: Observable<RadMonitor[]>;
  public radLocations$!: Observable<RadLocation[]>;
  public radMonitorTypes$!: Observable<RadMonitorType[]>;
  public radMonitorDescriptions$!: Observable<RadMonitorDescription[]>;

  public radMonitorSubscription!: Subscription;
  public radLocSubscription!: Subscription;
  public radMonitorTypeSubscription!: Subscription;
  public radMonitorDescSubscription!: Subscription;

  public radLocations: RadLocation[] = [];
  public radMonitors: RadMonitor[] = [];
  public radMonitorsFiltered: RadMonitor[] = [];
  public radMonitorTypes: RadMonitorType[] = [];
  public radMonitorDescriptions: RadMonitorDescription[] = [];

  public privilege = PrivilegeEnum;
  public radNoteType = RadNoteType;

  public displayedColumnsRadMonitor: string[] = ['ehs', 'type', 'location', 'description', 'mfr', 'model', 'controllerLocation', 'resetLocation', 'calDueDate', 'installedDate', 'notes', 'options'];
  public dataSourceRadMonitor: MatTableDataSource<RadMonitor> = new MatTableDataSource();

  @ViewChild('table')
  table!: ElementRef;
  headerOnTop = false;
  tableWidth = '';

  @Input()
  tabIndex!: number;

  constructor(
    protected override injector: Injector,
    private radMonitorService: RadMonitorService,
    private radNoteService: RadNoteService,
  ) {
    super(injector);
  }

  override ngOnDestroy() {
    this.radMonitorSubscription?.unsubscribe();
    this.radLocSubscription?.unsubscribe();
    this.radMonitorTypeSubscription?.unsubscribe();
    this.radMonitorDescSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter']) {
      this.applyFilter();
    }
  }

  ngOnInit() {
    this.loadRadMonitors();
  }

  loadRadMonitors() {
    this.loading.emit(true);
    this.radMonitors$ = this.store.select(state => state.RadMonitor.data);
    this.radMonitorSubscription = this.radMonitors$.subscribe(data => {
      if (data.length) {
        this.radMonitors = data;
        this.radMonitorsFiltered = data;
        this.dataSourceRadMonitor = new MatTableDataSource(this.radMonitorsFiltered);
        const sortCalDueDate: Sort = {
          active: 'calDueDate',
          direction: 'asc'
        };
        if (this.filter)
          this.dataSourceRadMonitor.filter = this.filter;
        this.loadLocations();
        this.sortData(sortCalDueDate);
        this.setRadNotes();
        this.loading.emit(false);
      }
    });
  }

  loadLocations() {
    this.loading.emit(true);
    this.radLocations$ = this.store.select(
      (state) => state.RadLocation.data
    );
    this.radLocSubscription = this.radLocations$.subscribe((radLocations) => {
      this.radLocations = radLocations;
    });
  }

  setRadNotes() {
    this.dataSourceRadMonitor.data.map(radMonitor => {
      radMonitor.notes = radMonitor.radNotes?.filter(n => n.type == RadNoteType.Normal)?.map(n => {
        const note: Note = {
          id: n.id ?? 0,
          user: n.createdByUser,
          date: n.createdOn,
          description: n.description,
          type: n.type
        };
        return note;
      });
      radMonitor.notesf = radMonitor.radNotes?.filter(n => n.type == RadNoteType.Fault)?.map(n => {
        const note: Note = {
          id: n.id ?? 0,
          user: n.createdByUser,
          date: n.createdOn,
          description: n.description,
          noteName: 'EHS#: ' + radMonitor.ehs + ' - Location: ' + radMonitor.radLocation?.name,
          type: n.type
        };
        return note;
      });
    });
  }

  monitorDialog(radMonitor?: RadMonitor) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      radMonitor: radMonitor ? this.utils.cloneDeep(radMonitor) : new RadMonitor(),
      radLocations: this.radLocations,
      radMonitorTypes: this.radMonitorTypes,
      radMonitorDescriptions: this.radMonitorDescriptions,
      dialogTitle: radMonitor ? 'Edit Monitor' : 'Add Monitor'
    };
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    const dialog = this.dialog.open(RadMonitorAddEditMonitorComponent, dialogConfig);
    dialog.afterClosed().toPromise().then((radMonitorToSave: RadMonitor) => {
      if (radMonitorToSave) {
        if (radMonitor && radMonitorToSave.id) {
          this.radMonitorService.UpdateRadMonitor(radMonitorToSave.id, radMonitorToSave).toPromise()
            .then(() => {
              this.alert.message('RadMonitor_Updated');
            })
            .catch(() => {
              this.alert.defaultError();
            });
        } else {
          this.radMonitorService.CreateRadMonitor(radMonitorToSave).toPromise()
            .then(() => {
              this.alert.message('RadMonitor_Created');
            })
            .catch(() => {
              this.alert.defaultError();
            });
        }
      }
    });
  }

  noteDialog(radMonitor: RadMonitor, radNoteType: RadNoteType) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      noteDescription: '',
      inputLabel: 'Note',
      dialogTitle: radNoteType === RadNoteType.Normal ? 'Add Note' : 'Add Fault Note'
    };
    dialogConfig.width = '20em';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    const dialog = this.dialog.open(RadNoteComponent, dialogConfig);
    dialog.afterClosed().toPromise().then((noteDescription: string) => {
      if (noteDescription) {
        const radNote: RadNote = {
          description: noteDescription,
          radLocationId: radNoteType === RadNoteType.Fault ? radMonitor.radLocationID : undefined,
          radMonitorId: radMonitor.id,
          type: radNoteType
        };
        this.radNoteService.CreateRadNote(radNote).toPromise()
          .then(() => {
            const msg = radNoteType === RadNoteType.Normal ? 'RadNote_Created' : 'RadFaultNote_Created';
            this.alert.message(msg);
          })
          .catch(() => {
            this.alert.defaultError();
          });
      }
    });
  }

  async deleteMonitor(radMonitor: RadMonitor) {
    if (await this.deleteConfirmationMsg('RadMonitor_SureToDelete')) {
      if (radMonitor.id)
        this.radMonitorService.DeleteRadMonitor(radMonitor.id).toPromise()
          .then(() => {
            this.alert.message('RadMonitor_Deleted');
          })
          .catch((error: HttpErrorResponse) => {
            if (error.status === 409) {
              this.alert.message('RadCatalogs_Deleted'), [new MessagePlaceholder('{what}', error.error)];
            } else {
              this.alert.defaultError();
            }
          });
    }
  }

  async deleteNote(radNote: RadNote) {
    let canDelete = true;
    if (radNote.type === RadNoteType.Fault) {
      canDelete = await this.deleteConfirmationMsg('RadFaultNote_SureToDelete');
    }
    if (canDelete && radNote.id) {
      this.radNoteService.DeleteRadNote(radNote.id).toPromise()
        .then(() => {
          this.alert.message('RadNote_Deleted');
        })
        .catch(() => {
          this.alert.defaultError();
        });
    }
  }

  async deleteConfirmationMsg(messageName: string) {
    const confirmation = this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.getMessage(messageName).description
      }
    });
    return confirmation.afterClosed().toPromise().then((isSure: boolean) => {
      return Promise.resolve(isSure);
    });
  }

  getColor(radMonitor: RadMonitor): string {
    const currentDate = new Date();
    if (moment(radMonitor.calDueDate).isSameOrBefore(currentDate)) {
      if (radMonitor.radLocation?.isEMShop) {
        return '#4db5ff'; // Darker blue
      }
      else {
        return '#FF9494'; // Red
      }
    } else if (moment(radMonitor.calDueDate).diff(moment(currentDate), 'days') < 30) {
      if (radMonitor.radLocation?.isEMShop) {
        return '#7cc7fc'; // Lighter blue
      }
      else {
        return '#FCFBAC'; // Yellow
      }
    }
    return '';
  }

  applyFilter() {
    if (this.filter) {
      const filter = this.filter.toLowerCase();
      this.radMonitorsFiltered = this.radMonitors.filter(data =>
        data.radLocation?.name?.toLowerCase().includes(filter) ||
        data.radLocation?.radLocationType?.name?.toLowerCase().includes(filter) ||
        data.radMonitorType?.name?.toLowerCase().includes(filter) ||
        data.radMonitorDescription?.name?.toLowerCase().includes(filter) ||
        data.radLocation?.controllerLocation?.toLowerCase().includes(filter) ||
        data.radLocation?.resetLocation?.toLowerCase().includes(filter) ||
        data.ehs?.toLowerCase().includes(filter) ||
        data.mfr?.toLowerCase().includes(filter) ||
        data.model?.toLowerCase().includes(filter));
    }
    else this.radMonitorsFiltered = this.radMonitors;
    this.dataSourceRadMonitor = new MatTableDataSource(this.radMonitorsFiltered);
  }

  sortData(sort: Sort) {
    const dataToSort = this.utils.cloneDeep(this.dataSourceRadMonitor.data);
    if (!sort.active || sort.direction === '') {
      this.dataSourceRadMonitor.data = dataToSort;
      return;
    }
    this.dataSourceRadMonitor.data = dataToSort.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'ehs': return this.utils.sort(a.ehs, b.ehs, isAsc);
        case 'type': return this.utils.sort(a.radMonitorType?.name, b.radMonitorType?.name, isAsc);
        case 'description': return this.utils.sort(a.radMonitorDescription?.name, b.radMonitorDescription?.name, isAsc);
        case 'mfr': return this.utils.sort(a.mfr, b.mfr, isAsc);
        case 'model': return this.utils.sort(a.model, b.model, isAsc);
        case 'location': return this.utils.sort(a.radLocation?.name, b.radLocation?.name, isAsc);
        case 'calDueDate': return this.utils.sort(a.calDueDate, b.calDueDate, isAsc);
        case 'installedDate': return this.utils.sort(a.installedDate, b.installedDate, isAsc);
        default: return 0;
      }
    });
  }

  validateShowMenu(): boolean {
    return this.hasPrivilege(PrivilegeEnum.EditRadMonitor) || this.hasPrivilege(PrivilegeEnum.CreateRadMonitorNote) || this.hasPrivilege(PrivilegeEnum.CreateRadMonitorFaultNote) || this.hasPrivilege(PrivilegeEnum.DeleteRadMonitor);
  }
}
