import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Checklist } from 'src/app/components/checklists/checklists';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { ChecklistStatusComponent } from '../../checklist-status/checklist-status.component';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';
import { TextareaDialogComponent } from '../../textarea-dialog/textarea-dialog.component';
import { DocumentChip } from '../../checklist-chips/document-chips.component';

@Component({
  selector: 'op-approval-w-checklists',
  templateUrl: './op-approval-w-checklists.component.html',
  styleUrls: ['./op-approval-w-checklists.component.scss']
})
export class OpApprovalWChecklistsComponent extends ClBaseComponent implements OnInit {

  @Input() privilegeToApproveAnswer?: PrivilegeEnum;
  @Input() answerChangedById?: number;
  @Input() answerChangedBy?: User;
  @Input() answer?: boolean;
  @Input() approved?: boolean;
  @Input() rolesToApproveChanges?: string[];

  @Input() questionDisable?: boolean = true;
  @Input() followUpChanged?: boolean;

  @Output() approveAnswer = new EventEmitter<any>();
  @Output() disapproveAnswer = new EventEmitter<any>();
  @Output() loading = new EventEmitter<boolean>();

  @ViewChild(ChecklistStatusComponent) checklistStatus?: ChecklistStatusComponent;
  @ViewChild('container', { read: ElementRef }) container?: ElementRef;

  canApproveAnswer = false;

  name?: string | null;

  visible?: boolean = false;

  questionSanitized!: SafeHtml;
  lastApproval!: SafeHtml | null;
  lastChange!: SafeHtml | null;
  lastChangeBy!: SafeHtml | null;
  checklistChips?: DocumentChip[];

  checklistsIDs!: number[];
  prevChecklistsIDs!: number[];
  checklistTypeID!: number;
  isChanging!: boolean;
  checklistNotClosed!: boolean;

  checklists!: Checklist[];
  checklists$!: Observable<Checklist[]>;
  checklistsSubs!: Subscription;

  task!: WfTaskLocalResource;

  constructor(
    protected override injector: Injector,
    private sanitizer: DomSanitizer,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.checklistsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit() {
    this.setCanAnswer();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadChecklists();
    this.checklistTypeID = this.utils.JSONparse(this.configuration)?.ChecklistTypeID ?? 0;
    this.refresh();
  }

  loadChecklists() {
    this.checklists$ = this.store.select(state => state.Checklists.data);
    this.checklistsSubs = this.checklists$.subscribe(data => {
      if (data.length) {
        this.checklists = data;
      }
    });
  }

  refresh() {
    this.checked = this.answer;
    this.loading.emit(false);
    this.questionSanitized = this.sanitizer.bypassSecurityTrustHtml(this.question ?? '');
    if (!this.approved) {
      this.label = 'Approve';
      this.name = null;
      this.date = null;
      this.color = 'primary';
      this.visible = this.showApprove != undefined ? this.showApprove : true;
    } else {
      this.label = 'Unapprove';
      this.color = 'accent';
      this.visible = this.showUnapprove;
      this.name = this.user?.name;
    }
    this.setCanAnswer();
    const opApprovalValueFromBE = this.utils.JSONparse(this.utils.getTextValue(this.valueString));
    if (opApprovalValueFromBE) {
      const opApprovalValue = this.utils.convertObjectKeysToCamelCase<OpApprovalValue>(opApprovalValueFromBE) as OpApprovalValue;
      this.checklistsIDs = opApprovalValue?.checklistIds;
      const checklists = this.checklists?.filter(c => this.checklistsIDs.includes(c.id));
      this.checklistNotClosed = checklists?.some(c => ![FormStatusEnum.Closed].includes(c.checklistStatus?.formStatus.id ?? 0));
    }
    if (!this.checklistsIDs) this.checklistsIDs = [];
    this.lastApproval = this.getSafeContent(this.signature?.lastFollowUpChanged ?? '');
    const checklistChipsData = this.signature?.lastFollowUpCheckChanged?.split("|");
    if (checklistChipsData?.length) {
      this.lastChange = this.getSafeContent(checklistChipsData[0]);
      this.lastChangeBy = this.getSafeContent(checklistChipsData[1]);
      this.checklistChips = this.utils.JSONparse(checklistChipsData[2]);
    }
  }

  setCanAnswer() {
    if (this.getCurrentUser() && this.answerChangedById && this.answerChangedById !== this.getCurrentUser()?.id && this.signature?.pendingChange) {
      this.questionDisable = true;
      this.canApproveAnswer = this.hasPrivilege(this.privilegeToApproveAnswer as PrivilegeEnum);
    } else if (this.getCurrentUser()) {
      this.questionDisable = false;
      this.canApproveAnswer = false;
    }
    else {
      this.questionDisable = true;
      this.canApproveAnswer = false;
    }
  }

  checkboxChanged() {
    if (!this.isChanging || this.checked) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message: environment.messages.answer_message
        }
      });
      confirm.afterClosed().toPromise().then(data => {
        if (data) {
          // uncheck box and remove checklists
          if (!this.checked || this.checked && (this.checklistsIDs.length || !this.checklistTypeID)) {
            this.checklistsIDs = this.checked ? this.checklistsIDs : [];
            this.valuesChanged();
            this.answer = this.checked;
          }
          // check box
          else if (this.checklistTypeID && !this.checklistsIDs.length) {
            if (this.status == FormStatusEnum.Draft || this.status == FormStatusEnum.New)
              this.valuesChanged();
            else this.isChanging = true;
          }
        } else {
          this.checked = !this.checked;
        }
      });
    }
  }

  textChanged(value: OpApprovalValue) {
    this.checklistsIDs = value.checklistIds;
    this.approved = value.approved;
    if (this.checked)
      this.valuesChanged()
  }

  valuesChanged() {
    this.isChanging = false;
    this.loading.emit(true);
    const value = { checked: this.checked, checklistIds: this.checklistsIDs, approved: this.approved };
    this.valueString = this.utils.JSONstringify(value);
    this.changed.emit(this.valueString);
    this.loading.emit(false);
  }

  click() {
    if (!this.approved) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message: this.getMessage('approve_message')?.description
        }
      });

      confirm.afterClosed().subscribe(data => {
        if (data) {
          this.name = this.getCurrentUser()?.name;
          this.date = new Date();

          this.approve.emit({
            value: !this.approved,
            user: !this.approved ? this.getCurrentUser()?.id : 0,
            date: this.date,
          });
        }
      });
    } else {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message: this.getMessage('unapprove_message')?.description
        }
      });
      confirm.afterClosed().subscribe(data => {
        if (data) {
          this.name = null;
          this.date = null;
          this.unapprove.emit({
            value: !this.approved,
            user: !this.approved ? this.getCurrentUser()?.id : 0,
            date: this.date
          });
        }
      });
    }
  }

  apprAns() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: environment.messages.approve_message
      }
    });
    confirm.afterClosed().subscribe(data => {
      if (data) {
        this.loading.emit(true);
        this.approveAnswer.emit(!this.checked);
      }
    });
  }

  disapAns() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: environment.messages.disapprove_message_no_reason
      }
    });
    confirm.afterClosed().subscribe(data => {
      this.loading.emit(true);
      if (data) {
        this.disapproveAnswer.emit();
      }
    });
  }

  disap() {
    const confirm = this.dialog.open(TextareaDialogComponent, {
      width: '400px',
      data: {
        label: 'Dissaprove reason',
        text: ''
      }
    });
    confirm.afterClosed().subscribe(data => {
      if (data.acceptClicked) {
        this.name = null;
        this.date = null;

        this.disapprove.emit({
          value: !this.approved,
          user: !this.approved ? this.getCurrentUser()?.id : 0,
          date: this.date,
          text: data.text
        });
      }
    });
  }

  undo() {
    this.disapproveAnswer.emit({
      value: !this.approved,
      user: 0,
      date: this.date,
      text: ''
    });
  }

  getSafeContent(s: string) {
    if (!s) return null;
    return this.sanitizer.bypassSecurityTrustHtml(s);
  }
}

export interface OpApprovalValue {
  checked: boolean;
  checklistIds: number[];
  changedByID: number;
  changedOn: string;
  approved: boolean;
  approvedByID: number;
  approvedOn: string;
  rejectedByID: number;
  rejectedOn: string;
}
