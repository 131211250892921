import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, SimpleChanges, OnChanges, OnDestroy, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ReviewBoardType, ReviewStatus } from '../../reviews.models';
import { ReviewsService } from '../../reviews.service';
import { ReviewBoardTypesRefresh } from '../../store/review-board-types/review-board-types.action';
import { ReviewsSettingsStatusesDetailsComponent } from './reviews-settings-statuses-details/reviews-settings-statuses-details.component';
import { ReviewStatusDelete, ReviewStatusUpdate, ReviewStatusCreate } from '../../store/review-status/review-status.action';
import { ReviewBoardsRefresh } from '../../store/review-boards/review-boards.action';
import { ReviewsRefresh } from '../../store/reviews/reviews.action';
import { BaseComponent } from 'src/app/common/base/base.component';
import { StatusType } from 'src/app/common/enumerations/enumerations';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'reviews-settings-statuses',
  templateUrl: './reviews-settings-statuses.component.html',
  styleUrls: ['./reviews-settings-statuses.component.scss']
})
export class ReviewsSettingsStatusesComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() reviewBoardType!: ReviewBoardType;

  reviewStatuses!: ReviewStatus[];
  reviewStatuses$!: Observable<ReviewStatus[]>;
  reviewStatusesSubs!: Subscription;

  statusType = StatusType;

  constructor(
    protected override injector: Injector,
    private service: ReviewsService,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.reviewStatusesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reviewStatuses = this.reviewBoardType?.reviewStatuses?.sort((a, b) => a.order - b.order);
    this.reviewStatuses$ = this.store.select(state => state.ReviewStatus.data);
    this.reviewStatusesSubs = this.reviewStatuses$.subscribe(data => {
      this.reviewStatuses = data.filter(x => x.reviewBoardTypeID == this.reviewBoardType.id)?.sort((a, b) => a.order - b.order);
    });
    this.renumberArray();
  }

  ngOnInit(): void {

  }

  edit(reviewStatus: ReviewStatus) {
    const confirm = this.dialog.open(ReviewsSettingsStatusesDetailsComponent, {
      width: '800px',
      data: {
        title: 'Modify Status',
        reviewBoardType: this.reviewBoardType,
        reviewStatus,
        reviewStatuses: this.reviewStatuses,
        disableClose: true,
        autoFocus: false
      }
    });
    confirm.afterClosed().toPromise().then(data => {
      if (data) {
        this.service.updateReviewStatus(data.reviewStatus).toPromise().then(data => {
          if (data?.id)
            this.store.dispatch(new ReviewStatusUpdate(data.id, data));
          this.alert.message('ReviewStatusUpdated');
          this.refresh();
        });
      }
    });
  }

  delete(reviewStatus: ReviewStatus) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('ReviewStatusDeleteConfirmation').description,
        icon: 'warn'
      }
    });
    confirm.afterClosed().toPromise().then(data => {
      if (data && reviewStatus.id) {
        this.service.deleteReviewStatus(reviewStatus.id).toPromise().then(() => {
          if (reviewStatus.id)
            this.store.dispatch(new ReviewStatusDelete(reviewStatus.id));
          this.store.dispatch(new ReviewBoardsRefresh());
          this.alert.message('ReviewStatusDeleted');
          this.refresh();
        });
      }
    });
  }

  add() {
    const reviewStatus = {
      id: 0,
      reviewStatusActions: []
    };
    const confirm = this.dialog.open(ReviewsSettingsStatusesDetailsComponent, {
      width: '800px',
      data: {
        title: 'Add Status',
        reviewBoardType: this.reviewBoardType,
        reviewStatus,
        reviewStatuses: this.reviewStatuses,
        disableClose: true,
        autoFocus: false
      }
    });
    confirm.afterClosed().toPromise().then(data => {
      if (data) {
        this.service.createReviewStatus(data.reviewStatus).toPromise().then(data => {
          if (data)
            this.store.dispatch(new ReviewStatusCreate(data));
          this.alert.message('ReviewStatusCreated');
          this.refresh();
        });
      }
    });
  }

  drop(event: CdkDragDrop<ReviewStatus[]>) {
    if (event?.previousIndex !== event?.currentIndex) {
      moveItemInArray(this.reviewStatuses, event.previousIndex, event.currentIndex);
      this.renumberArray();
    }
  }

  renumberArray() {
    let order = 1;
    this.reviewStatuses?.map(async reviewStatus => {
      if (reviewStatus.order != order) {
        reviewStatus.order = order;
        await this.service.updateReviewStatusOrder(reviewStatus).toPromise();
      }
      order++;
    });
  }

  getClass(color: string) {
    return color + '-background';
  }

  refresh(refreshReviews: boolean = true) {
    if (refreshReviews) {
      this.store.dispatch(new ReviewsRefresh());
    }
    this.store.dispatch(new ReviewBoardsRefresh());
    this.store.dispatch(new ReviewBoardTypesRefresh());
  }
}
