<div class="container-fluid mt-2">
  <div *ngIf="false">
    <div class="row">
      <div class="col-12 col-md-5 col-xl-3 pt-2">
        <div class="d-flex justify-content-center">
          <button
            mat-flat-button
            color="primary"
            class="mr-3"
            (click)="addMeterCatalog()"
          >
            <mat-icon>add</mat-icon>
          </button>

          <button
            mat-flat-button
            color="accent"
            class="mr-3"
            (click)="manageMeterTypes()"
          >
            Types
          </button>

          <button
            mat-flat-button
            color="accent"
            class="mr-3"
            (click)="manageMeterUnits()"
          >
            Units
          </button>

          <button
            mat-flat-button
            color="accent"
            class="mr-3"
            (click)="manageMeterModels()"
          >
            Models
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="mat-container">
    <mat-table
      style="min-width: 55em"
      [dataSource]="dataSource.data"
      matSort
      (matSortChange)="sortData($event)"
    >
      <ng-container matColumnDef="serialNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Serial Number
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="element.serialNumber | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="calibrationDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Cal Due Date
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          <span
            [ngClass]="{ red: showWarning(element) }"
            [innerHTML]="
              format(element.calibrationDueDate) | highlightSearch : filter
            "
          ></span>
          <mat-icon
            class="warning-icon"
            *ngIf="showWarning(element)"
            color="warn"
            >warning</mat-icon
          >
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="model">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Model
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="element.radMeterModel?.name | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="element.radMeterType?.name | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="sourceReading">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Source Reading</mat-header-cell>
              <mat-cell *matCellDef="let element" nowrap>
                {{ element.sourceReading }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="backgroundReading">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Background Reading</mat-header-cell>
              <mat-cell *matCellDef="let element" nowrap>
                {{ element.backgroundReading }}
              </mat-cell>
            </ng-container> -->

      <ng-container matColumnDef="unit">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Unit
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="element.radMeterUnit?.name | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="options">
        <mat-header-cell *matHeaderCellDef> Options </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            class="float-right"
            color="primary"
            [matMenuTriggerFor]="menu"
            aria-label="Rad Location Menu"
          >
            <mat-icon class="small-dots">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="EditRowMeter(element)">
              <mat-icon class="yellow">edit</mat-icon>
              <span>Edit</span>
            </button>

            <button mat-menu-item (click)="deleteRowMeter(element)">
              <mat-icon class="red">delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row
        #header
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
