import { Component, OnInit, Inject, OnDestroy, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ResourceType } from 'src/app/common/enumerations/enumerations';
import { PendingResource } from 'src/app/services/pending-approvals/pending-approval';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { PendingResourcesRefresh } from '../../../store/pending-resources/pending-resources.action';


@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class AcceleratorLocationComponent extends BaseComponent implements OnInit, OnDestroy {

  resources!: Resource[];
  resourcesFiltered!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  pendingResources!: PendingResource[];
  pendingResourcesFiltered!: PendingResource[];
  pendingResources$!: Observable<PendingResource[]>;
  pendingResourcesSubs!: Subscription;


  public locationToSend!: Resource;

  public locationName?: string | null;
  public oldLocationName?: string | null;

  private errorMsg!: string[];
  private valid!: boolean;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<AcceleratorLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public resource: Resource,
  ) {
    super(injector);
    this.store.dispatch(new PendingResourcesRefresh());
  }

  override ngOnDestroy(): void {
    this.resourcesSubs?.unsubscribe();
    this.pendingResourcesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.locationToSend = new Resource();
    this.locationName = this.resource.name;
    this.oldLocationName = this.resource.name;
    this.loadResources();
    // this.loadPendingResources();
    this.errorMsg = [];
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data.length) {
        this.resources = data;
        this.resourcesFiltered = this.resources.filter(x => x.type == ResourceType.AcceleratorLocation);
      }
    });
  }

  // loadPendingResources() {
  //   this.pendingResources$ = this.store.select(state => state.PendingResources.data);
  //   this.pendingResourcesSubs = this.pendingResources$.subscribe(data => {
  //     if (data.length) {
  //       this.pendingResources = data;
  //       this.pendingResourcesFiltered = this.pendingResources.filter(x => x.resourceTypeId == ResourceType.AcceleratorLocation);
  //     }
  //   });
  // }

  async add() {
    if (await this.isValid()) {
      this.locationToSend.name = this.locationName;
      this.dialogRef.close(this.locationToSend);
    } else {
      this.alert.error(this.errorMsg.join(", "));
    }
    this.errorMsg = [];
  }

  cancel() {
    this.dialogRef.close();
  }

  async isValid() {
    this.valid = true;
    if (!this.locationName) {
      this.errorMsg.push("Field <b>Location Name</b> should not be blank!");
      this.valid = false;
    } else {
      if (this.oldLocationName != this.locationName) {
        const data = this.resourcesFiltered.find(x => x.name?.toLowerCase().trim() == this.locationName?.toLowerCase().trim());
        if (data) {
          this.errorMsg.push("Field <b>Location Name</b> already exists");
          this.valid = false;
        }
      }

    }
    return this.valid;
  }

}
