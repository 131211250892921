<div class="card">
  <div class="card-header">
    <span class="card-title">Site Configuration</span>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="card-body">
    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col-12 mb-2">
          <mat-slide-toggle
            formControlName="isEmailSCFActive"
            [disabled]="!isSA()"
            [checked]="isEmailSCFActive.value"
            >SCF Notifications
          </mat-slide-toggle>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline" class="filter">
            <mat-label>Default Emails</mat-label>
            <input
              matInput
              formControlName="defaultEmails"
              type="text"
              name="operator_form"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline" class="filter">
            <mat-label>OLOG Default User</mat-label>
            <input matInput type="text" formControlName="ologUser" />
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="filter">
            <mat-label>OLOG Default Password</mat-label>
            <input matInput type="password" formControlName="ologPassword" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-2">
          <mat-slide-toggle
            formControlName="isSchedulesReminderActive"
            [disabled]="!isSA()"
            [checked]="isSchedulesReminderActive.value"
            >Schedule Reminder Notifications</mat-slide-toggle
          >
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        color="warn"
        type="button"
        (click)="onNoClick(false)"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        (click)="onSubmit()"
        [disabled]="!formGroup.valid || !isSA()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
