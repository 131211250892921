import { AfterViewInit, Component, ElementRef, HostListener, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { DocumentType } from 'src/app/services/documents/documents';
import { DocumentTypeListComponent } from './document-type-list/document-type-list.component';

@Component({
  selector: 'app-document-type',
  templateUrl: './document-type.component.html',
  styleUrls: ['./document-type.component.scss']
})
export class DocumentTypeComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @ViewChild(DocumentTypeListComponent) side?: DocumentTypeListComponent;

  disabled: boolean = false;

  loading = false;

  resourceRequired: boolean = false;

  filterCtrl = new FormControl();

  errorMessages: string[] = [];
  headerOnTop: boolean = false;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter: any[] = [];
  currentFilter: any;
  filter: any;

  loadingMessage!: string;

  showAmendmentToggle: boolean = false;
  //////

  documentType?: DocumentType;
  documentTypes?: DocumentType[];
  documentTypes$!: Observable<DocumentType[]>;
  documentTypesSubs!: Subscription;

  constructor(
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.setHeights(64);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.moduleTitle = 'Document Type Catalog';
    this.loadDocumentTypes();
  }

  override ngOnDestroy(): void {
    this.documentTypesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadDocumentTypes() {
    this.documentTypes$ = this.store.select(state => state.DocumentType.data);
    this.documentTypesSubs = this.documentTypes$.subscribe(data => {
      if (data?.length) {
        this.documentTypes = data;
        if (this.documentType) {
          const documentType = this.documentTypes.find(x => x.id == this.documentType?.id);
          if (documentType) {
            this.documentType = documentType;
            this.documentType.documentTemplateId = this.documentTypes.find(x => x.type === this.documentType?.id)?.id;
          }
        }
      }
    });
  }

  clear() {
    this.errorMessages = [];
  }

  selectedRow(e: DocumentType) {
    this.documentType = e;
  }

  create() {
    this.side?.createDocumentType();
  }

  @HostListener("window:resize")
  onResize() {
    this.innerWidth = window.innerWidth;
    // if (this.isExpanded != false) {

    // }
    if (this.innerWidth < 768) {
      this.isExpanded = false;
    }
  }

  //////
  changedFilter() { }
  clearFilter() { }
  selectedFilter() { }
  createDisabled() { }
  editDisabled() { }
  action() { }

  applyFilter(e: any) {
    if (!e)
      this.valueFilter = '';
    this.filter = e;
  }



  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 2000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Buttons:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Main:
          this.loadingMain = e as boolean;
          break;
      }
      // console.log('m: ' + this.loadingMain + '   b: ' + this.loadingButtons + '   s: ' + this.loadingSide);
      this.loading = this.loadingMain || this.loadingButtons || this.loadingSide;
    }, 100);
  }

  loadingSide = false;
  loadingButtons = false;
  loadingMain = false;
  loadingOrigin = LoadingOrigin;


}

enum LoadingOrigin {
  Side = 1, Buttons = 2, Main = 3
}
