<div class="container">
  <h6 mat-dialog-title>Create Hold Point Inspection</h6>
  <div class="row">
    <div class="col-7">
      <comments-box
        formType="SCF"
        [serialNo]="scfMaster.serialNo"
        [minHeight]="'200px'"
        [title]="'Hold Point Inspection Comments'"
        [textValue]="scfHoldPoint.description"
        (textChanged)="textChanged($event)"
        [hasError]="!scfHoldPoint.description"
      >
      </comments-box>
    </div>
    <div class="col-5">
      <div class="row" *ngFor="let wfTaskLocal of wfTaskLocals">
        <div class="col-12 pl-5">
          <mat-checkbox color="accent" [(ngModel)]="wfTaskLocal.required"
            ><b>{{ wfTaskLocal.name }}</b
            ><br />
            {{ getRoles(wfTaskLocal) }}</mat-checkbox
          >
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-dialog-actions align="center">
        <button
          mat-flat-button
          class="cancel-btn"
          color="warn"
          (click)="cancel()"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          class="accept-btn"
          color="primary"
          [disabled]="!isValid()"
          (click)="save()"
        >
          Save
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</div>
