<div class="row">
  <div class="col-3 d-flex justify-content-end">
    <button
      mat-icon-button
      class="orange"
      [ngClass]="{ gray: index == 0 }"
      [disabled]="index == 0"
      (click)="moveOnHistory(move.FIRST)"
    >
      <mat-icon>first_page</mat-icon>
    </button>
    <button
      mat-icon-button
      class="orange"
      [ngClass]="{ gray: index == 0 }"
      [disabled]="index == 0"
      (click)="moveOnHistory(move.PREV)"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="col-3 d-flex flex-row align-items-center justify-content-center">
    <mat-slider
      *ngIf="catalogs?.length"
      min="0"
      [max]="(catalogs?.length ?? 0) - 1"
      step="1"
      [thumbLabel]="false"
      [discrete]="false"
      [showTickMarks]="true"
    >
      <input
        matSliderThumb
        [(ngModel)]="index"
        #slider
        (ngModelChange)="changed($event)"
    /></mat-slider>
    <span class="ml-2 nowrap" *ngIf="index >= 0"
      >Displaying Update <span class="blue bold">{{ index + 1 }}</span> of
      <span class="blue bold">{{ catalogs?.length }}</span></span
    >
  </div>
  <div
    class="col-3 d-flex flex-row align-items-center justify-content-start small"
  >
    <div *ngIf="catalog">
      <div>
        {{ index == 0 ? "Created by:" : "Updated by:" }}
        <span class="blue bold">{{ catalogInfo?.userCreatedBy?.name }}</span>
        on:
        <span class="date">{{ catalogInfo?.createdOn | date }}</span>
      </div>
      <div>
        Approved by:
        <span class="blue bold">{{ catalogInfo?.statusByUser?.name }}</span>
        on:
        <span class="date">{{ catalogInfo?.statusOn | date }}</span>
      </div>
    </div>
  </div>
  <div class="col-3 d-flex justify-content-start">
    <button
      mat-icon-button
      class="orange"
      [ngClass]="{
        gray: index == (catalogs?.length ?? 1) - 1
      }"
      [disabled]="index == (catalogs?.length ?? 1) - 1"
      (click)="moveOnHistory(move.NEXT)"
    >
      <mat-icon>arrow_forward</mat-icon>
    </button>
    <button
      mat-icon-button
      class="orange"
      [ngClass]="{
        gray: index == (catalogs?.length ?? 1) - 1
      }"
      [disabled]="index == (catalogs?.length ?? 1) - 1"
      (click)="moveOnHistory(move.LAST)"
    >
      <mat-icon>last_page</mat-icon>
    </button>
  </div>
</div>
