<div class="card">
  <div class="card-header">
    <span class="card-title">Scheduled For: {{ header(data.name ?? "") }}</span>
  </div>
  <div class="card-body">
    <form [formGroup]="formScheduledFor">
      <div class="row">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Scheduled For</mat-label>
            <input
              #input
              matInput
              [min]="minDate"
              [matDatepicker]="scheduledFor"
              placeholder="mm/dd/yyyy"
              name="scheduledFor"
              formControlName="scheduledFor"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="scheduledFor"
            ></mat-datepicker-toggle>
            <mat-datepicker #scheduledFor></mat-datepicker>
            <mat-error>Date must be today or greater</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onCancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="!formScheduledFor.valid"
      >
        Submit
      </button>
    </div>
  </div>
</div>
