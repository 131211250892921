import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { WFComponent } from '../../components/workflow/workflow';
import { Observable } from 'rxjs';

@Injectable()
export class ComponentService {
  refreshTableData: EventEmitter<any> = new EventEmitter();
  url = environment.urls.baseApiUrl + '/Components';
  constructor(private http: HttpClient) {

  }

  Read(): Observable<WFComponent[]> {
    return this.http.get<WFComponent[]>(this.url);
  }

  ReadById(id: number): Observable<WFComponent> {
    return this.http.get<WFComponent>(this.url + '/' + id);
  }


}
