<div class="body-content">
  <form [formGroup]="formNote">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Note</mat-label>
          <textarea matInput formControlName="description"></textarea>
          <mat-error *ngIf="noteDescription?.invalid"
            >Description is required</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="!formNote.valid"
      >
        Accept
      </button>
    </div>
  </form>
</div>
