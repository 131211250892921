import { Component, HostListener, OnInit, Injector, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription, filter } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import FileV2 from 'src/app/services/file/file-v2.model';
import { MenuItem } from '../head-menu/head-menu.models';
import { FileV2Service } from 'src/app/services/file/file-v2.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { SearchDialogComponent } from '../head-search/search-dialog/search-dialog.component';


@Component({
  selector: 'head-action-buttons',
  templateUrl: './head-action-buttons.component.html',
  styleUrls: ['./head-action-buttons.component.scss']
})
export class HeadActionButtonsComponent extends BaseComponent implements OnInit {

  helpFile!: FileV2 | null;

  menuItems!: MenuItem[];
  menuItems$!: Observable<MenuItem[]>;
  menuItemsSubs!: Subscription;

  collapsed = false;
  hasToolbar?: boolean;

  constructor(
    private router: Router,
    private file2service: FileV2Service,
    private clipboard: Clipboard,
    protected override injector: Injector
  ) {
    super(injector);
  }

  initialize() {
    this.initMenuItems();
    this.checkToolbar();
  }

  ngOnInit() {
    this.onResize(null);
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        // Do something when the route changes
        this.initialize();
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number; }; } | null) {
    if (event) {
      this.innerWidth = event.target.innerWidth;
    }
    else {
      this.innerWidth = window.screen.width;
    }
  }

  checkToolbar() {
    setTimeout(() => {
      this.hasToolbar = document.querySelector('.toolbar') != null;
    }, 300);
  }

  initMenuItems() {
    this.menuItems$ = this.store.select(state => state.MenuItem.data);
    this.menuItemsSubs = this.menuItems$.subscribe(async data => {
      if (data.length) {
        this.menuItems = data;
        this.initIconHelp();
      }
    });
  }

  initIconHelp() {
    if (this.menuItems?.length) {
      this.helpFile = null;
      const menuItem: MenuItem | undefined = this.menuItems.find(x => this.formatUrlPath(x.route) === this.formatUrlPath(this.router.url));
      if (menuItem?.fileV2Id && !this.helpFile) {
        this.file2service.obtainFile(menuItem.fileV2Id).toPromise().then((result: FileV2 | null) => {
          this.helpFile = result;
        }).catch(() => {
          // this.alert.defaultError();
        });
      }
    }
  }

  formatUrlPath(text?: string): string | undefined {
    if (text?.length) {
      if (text.charAt(0) === '/') {
        text = text.substring(1);
      }

      if (text.charAt(text.length - 1) === '/') {
        text = text.slice(0, -1);
      }
    }

    return text;
  }

  openSearchDialog() {
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      height: '90vh',
      width: '95vw',

      autoFocus: false,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log('Dialog closed');
    });
  }

  async openDocumentHelp() {
    const newTab = window.open(this.helpFile?.url, '_blank');
    if (newTab) {
      newTab.focus();
    }
  }

  copyLink() {
    let serialNo = document.getElementById('serialNumber')?.innerText;
    serialNo = serialNo != '' ? serialNo : document.getElementById('serialNumber')?.innerHTML;
    let link = null;
    if (serialNo && this.containsNUmbers(serialNo)) {
      link = window.location.origin + '/#/redirect/' + serialNo;
      this.alert.info('Link to <b><a href="' + link + '">' + serialNo + '</a></b> has been copied to the Clipboard!');
    }
    else {
      link = window.location.href;
      this.alert.info('Link to <b>' + link + '</b> has been copied to the Clipboard!');
    }
    this.clipboard.copy(link);

  }

  containsNUmbers(string: string) {
    return /\d/.test(string);
  }

  toggleToolbar() {
    const toolbar = document.querySelector('.toolbar');
    if (toolbar?.classList.contains('toolbar-collapsed')) {
      toolbar.classList.remove('toolbar-collapsed');
      this.collapsed = false;
      document.querySelector('.sidenav-container')?.classList.remove('sidenav-container-max');
      document.querySelector('.sidenav-content')?.classList.remove('sidenav-content-max');
    }
    else {
      toolbar?.classList.add('toolbar-collapsed');
      this.collapsed = true;
      document.querySelector('.sidenav-container')?.classList.add('sidenav-container-max');
      document.querySelector('.sidenav-content')?.classList.add('sidenav-content-max');
    }
  }

  printPage() {
    window.print();
  }
}
