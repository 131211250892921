import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ModalAddToRAComponent } from './modal-add-to-ra/modal-add-to-ra.component';
import { RadiationAreaItem } from './radiation-area-item';
import { RadiationAreaService } from './radiation-area-service.service';
import { Observable, Subscription } from 'rxjs';
import FileV2 from '../../../services/file/file-v2.model';
import { FileV2Service } from 'src/app/services/file/file-v2.service';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-radiation-area',
  templateUrl: './radiation-area.component.html',
  styleUrls: ['./radiation-area.component.scss']
})
export class RadiationAreaComponent extends BaseComponent implements OnInit, OnDestroy {

  public radAreas: RadiationAreaItem[] = [];
  public radiationAreaItems$!: Observable<RadiationAreaItem[]>;
  public radiationAreaItemSubscription: Subscription = new Subscription;

  public currentUserSubscription: Subscription = new Subscription;

  public nowdate: Date = new Date();
  loading = false;
  fileServer!: FileV2 | null;

  public maxHeight = '0px';

  constructor(
    public radiationAreaService: RadiationAreaService,
    private file2Service: FileV2Service,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getRadiationAreaItems();
    this.getCurrentUser();
  }

  ngAfterViewInit(): void {
    this.responsiveFunction();
  }

  override ngOnDestroy(): void {
    this.radiationAreaItemSubscription?.unsubscribe();
  }

  getRadiationAreaItems() {
    this.radiationAreaItems$ = this.store.select(state => state.RadiationArea.radiationAreaItems);
    this.radiationAreaItemSubscription = this.radiationAreaItems$.subscribe(radiationAreaItems => {
      if (radiationAreaItems?.length) {
        this.radAreas = radiationAreaItems?.filter(ra => ra.status === 1).sort((a, b) => (b.id ?? 0) - (a.id ?? 0));
      }
    });
  }

  addRA() {
    this.dialog.open(ModalAddToRAComponent, {
      maxWidth: '500px'
    });
  }

  editRA(item: RadiationAreaItem) {
    this.radiationAreaService.selectedItem = item;
    this.dialog.open(ModalAddToRAComponent, {
      maxWidth: '500px'
    });
  }

  deleteRA(item: RadiationAreaItem) {
    const self = this;
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('Rad_Area_Delete').description,
        icon: 'warn'
      }
    });
    confirm.afterClosed().toPromise().then(async data => {
      if (data && item.id) {
        this.radiationAreaService.deleteRAI(item.id).toPromise().then(() => {
          // Remove the item from this.radAreas
          this.radAreas = this.radAreas.filter(ra => ra.id !== item.id);
        });
      }
    });
  }

  responsiveFunction() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.maxHeight = (parseInt((this.innerHeight * 0.50).toFixed(0), 10) - 100) + 'px';
  }

}
