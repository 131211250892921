import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MessageBanner } from '../../components/messages/services/message-banner';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { MessageTypeEnum } from '../../components/messages/services/message-banner.enums';
import { MessageBannerService } from '../../components/messages/services/message-banner.service';
import { utils } from 'src/app/modules/libs/utils';

@Injectable({ providedIn: 'root' })
export class AlertService {

  private prevMessage?: string;

  toastrConfig = {
    timeOut: 3000,
    progressBar: false,
    closeButton: false,
    extendedTimeOut: 5000,
    easing: 'ease-in',
    enableHtml: true,
    newestOnTop: true,
    toastClass: 'ngx-toastr',
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    disableTimeOut: false
  };

  constructor(
    private toastr: ToastrService,
    private mbs: MessageBannerService
  ) {
  }

  getConfig(timeOut?: number | null, banner?: boolean) {
    const config = utils.cloneDeep(this.toastrConfig);
    if (timeOut == -1) {
      config.disableTimeOut = true;
      config.closeButton = true;
    }
    else if (timeOut ?? 0 > 0) {
      config.timeOut = timeOut ?? 3000;
    }
    if (banner) {
      config.toastClass = 'ngx-toastr-banner';
      config.disableTimeOut = true;
      config.closeButton = true;
      config.positionClass = 'toast-center';
    }
    return config;
  }

  success(message?: string, title: string = "Success!", timeOut: number | null = null) {
    if (message)
      this.toastr.success(message, title, this.getConfig(timeOut));
  }

  warning(message?: string, title: string = "Warning!", timeOut: number | null = null) {
    if (message)
      this.toastr.warning(message, title, this.getConfig(timeOut));
  }

  info(message?: string, title: string = "Info:", timeOut: number | null = null) {
    if (message)
      this.toastr.info(message, title, this.getConfig(timeOut));
  }

  banner(message?: string, title: string = "Info:", timeOut: number | null = null) {
    if (message)
      this.toastr.info(message, title, this.getConfig(timeOut, true));
  }

  error(message?: string, title: string = "Error!", timeOut: number | null = null) {
    if (message)
      this.toastr.error(message, title, this.getConfig(timeOut));
  }

  defaultError() {
    this.toastr.error(this.getMessage('defaultErrorMessage').description, "Error!", this.getConfig());
  }

  getMessage(val: any): MessageBanner {
    if (typeof (val) === 'string')
      return this.mbs.get(val);
    return new MessageBanner();
  }

  message(msg?: string | MessageBanner, placeholders: MessagePlaceholder[] = [], type?: MessageTypeEnum, title?: string, timeout?: number) {
    if (!msg) return;
    let data: MessageBanner | undefined = undefined;
    if (typeof msg == 'string') {
      data = this.getMessage(msg);
    }
    else data = msg;
    if (data) {
      let description = data?.description ?? msg;
      data.type = type ?? data?.type;
      data.timeout = timeout ?? data.timeout;

      placeholders.map(placeholder => description = description?.replace(placeholder.placeholder, placeholder.value ?? ''));

      if (description != this.prevMessage) {
        if (description)
          switch (data.type) {
            case MessageTypeEnum.Info:
              this.info(description, title ?? MessageTypeEnum[data?.type], data.timeout ? data.timeout * 1000 : null);
              break;
            case MessageTypeEnum.Warning:
              this.warning(description, title ?? MessageTypeEnum[data?.type], data.timeout ? data.timeout * 1000 : null);
              break;
            case MessageTypeEnum.Success:
              this.success(description, title ?? MessageTypeEnum[data?.type], data.timeout ? data.timeout * 1000 : null);
              break;
            case MessageTypeEnum.Error:
            default:
              this.error(description, title ?? 'Error', data.timeout ? data.timeout * 1000 : null);
              break;
          }
        this.prevMessage = description;
      }
      else {
        setTimeout(() => {
          this.prevMessage = undefined;
        }, 2000);
      }
    }
  }

  // This is the method used from AlertService in the Backend
  alert(data: MessageBanner) {
    const description = data.description;
    const title = data.name;

    if (title + description != this.prevMessage) {
      if (description)
        switch (data.type) {
          case MessageTypeEnum.Info:
            this.info(description, title, data.timeout);
            break;
          case MessageTypeEnum.Warning:
            this.warning(description, title, data.timeout);
            break;
          case MessageTypeEnum.Success:
            this.success(description, title, data.timeout);
            break;
          case MessageTypeEnum.Error:
          default:
            this.error(description, title, data.timeout);
            break;
        }
      this.prevMessage = title + description;
    }
    else {
      setTimeout(() => {
        this.prevMessage = undefined;
      }, 2000);
    }
  }

  clear() {
    this.toastr.clear();
  }

}
