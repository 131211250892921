import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-normal-input-dialog',
  templateUrl: './normal-input-dialog.component.html',
  styleUrls: ['./normal-input-dialog.component.scss']
})
export class NormalInputDialogComponent implements OnInit {
  public label!: string;
  public inputType = 'text';
  text = '';
  constructor(
    public dialog: MatDialogRef<NormalInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { }
  ngOnInit() {
    this.label = this.data.label;
    if ('inputType' in this.data) {
      this.inputType = this.data.inputType;
    }
  }
  cancel() {
    this.dialog.close(null);
  }
  accept() {
    this.dialog.close(this.text);
  }
}
