import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  BuilderTasksCreate, BuilderTasksDelete, BuilderTasksRefresh, BuilderTasksUpdate, BuilderTasksUpdateAll,
} from './builder-task.action';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { ChecklistsService } from '../../checklists.service';
import { utils } from 'src/app/modules/libs/utils';

export class BuilderTasksStateModel {
  data: WfTaskLocalResource[] = [];
}

@State<BuilderTasksStateModel>({
  name: 'BuilderWFTaskLocals',
  defaults: {
    data: [],
  },
})
@Injectable()
export class BuilderTasksState {
  utils = utils;
  constructor(private service: ChecklistsService) { }

  @Selector()
  static read(state: BuilderTasksStateModel) {
    return state.data;
  }

  @Action(BuilderTasksRefresh)
  refresh(
    { getState, patchState }: StateContext<BuilderTasksStateModel>,
    { wfStepLocalID }: BuilderTasksRefresh
  ) {
    this.service.getTasksByStepID(wfStepLocalID).toPromise().then((items) => {
      if (items) {
        patchState({ data: getState().data.filter((x) => !items?.map((i) => i.id).includes(x.id)) });
        const state = getState();
        const data = state.data.concat(items);
        patchState({ data });
      }
    },
      (error) => console.error(error)
    );
  }

  @Action(BuilderTasksCreate)
  create({ getState, patchState }: StateContext<BuilderTasksStateModel>, { item }: BuilderTasksCreate) {
    const state = getState();
    const index = state.data.map((x) => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item],
    });
  }

  @Action(BuilderTasksUpdate)
  update({ getState, patchState }: StateContext<BuilderTasksStateModel>, { id, item }: BuilderTasksUpdate) {
    const state = getState();
    let index = state.data.map((x) => x.id).indexOf(id);
    let newData = [...state.data];
    if (index >= 0) {
      newData.splice(index, 1);
    }
    newData.push(item);
    patchState({ data: newData });
  }

  @Action(BuilderTasksUpdateAll)
  updateAll({ getState, patchState }: StateContext<BuilderTasksStateModel>, { items }: BuilderTasksUpdateAll) {
    const state = getState().data;
    const updatedTasks: WfTaskLocalResource[] = [];
    items.map(task => {
      const currentTask = state.find(t => t.id == task.id);
      if (currentTask && currentTask.required != undefined)
        task.required = currentTask?.required;
      task.wfTasks?.map(t => {
        t.order = currentTask?.wfTasks?.find(x => x.id == t.id)?.order;
      });
      if (currentTask && !utils.isEquivalent(currentTask, task) || !currentTask) {
        updatedTasks.push(task);
      }
    });
    const IDs = [...new Set(updatedTasks.map(t => t.id))];
    let data = getState().data.filter(t => !IDs.includes(t.id));
    data = this.utils.cloneDeep(data.concat(updatedTasks));
    patchState({ data });
  }

  @Action(BuilderTasksDelete)
  delete(
    { getState, patchState }: StateContext<BuilderTasksStateModel>,
    { id }: BuilderTasksDelete
  ) {
    patchState({
      data: getState().data.filter((x) => x.id !== id),
    });
  }
}
