import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProcedureRefresh } from 'src/app/components/procedure/store/procedure/procedure.action';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
// import { ProcedureRefresh } from 'src/app/Store/procedure/procedure/procedure.action';
// import { Procedure } from 'src/app/_interface/procedure/procedure.model';
import { cloneDeep } from 'lodash';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'procedure-autocomplete',
  templateUrl: './procedure-autocomplete.component.html',
  styleUrls: ['./procedure-autocomplete.component.scss']
})
export class ProcedureAutocompleteComponent implements OnInit, OnChanges {
  @Input() placeholder!: string;
  @Input() disabled = false;
  @Input() control!: FormControl;
  @Input() selectedProcedures?: (Procedure | undefined)[];

  @Output() error = new EventEmitter<string>();
  @Output() select = new EventEmitter<Procedure>();

  @ViewChild('procedureAutocomplete') procedureAutocomplete!: MatAutocomplete;
  @ViewChild('procedureInput') procedureInput!: ElementRef<HTMLInputElement>;

  procedures$!: Observable<Procedure[]>;
  allProcedures: Procedure[] = [];
  availableProcedures: Procedure[] = [];
  filteredProcedures: Procedure[] = [];

  constructor(
    public store: Store
  ) {
    this.store.dispatch(ProcedureRefresh);
  }

  ngOnInit(): void {
    this.procedures$ = this.store.select(state => state.Procedures.data);
    this.procedures$.subscribe((data) => {
      this.allProcedures = data.filter(x => x.status == 1 && x.active).sort((a, b) => a.procedureNumber < b.procedureNumber ? -1 : 1);
      this.setAvailable();
    });
    this.control = this.control ?? new FormControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedProcedures' in changes) {
      this.setAvailable();
      this.clear();
    }
  }

  clear() {
    if (this.procedureInput != null) { this.procedureInput.nativeElement.value = ''; }
    if (this.control != null) { this.control.setValue(null); }
  }

  setAvailable() {
    this.availableProcedures = utils.cloneDeep(this.allProcedures);
    this.selectedProcedures = this.selectedProcedures ?? [];
    this.selectedProcedures.map(x => {
      if (x?.id) {
        const index = this.availableProcedures.map(y => y.id).indexOf(x.id);

        if (index >= 0) {
          this.availableProcedures.splice(index, 1);
        }
      }
    });
    this.onProcedureInputKeyup(this.procedureInput?.nativeElement?.value ?? '');
  }

  selected(procedure: Procedure) {
    this.select.emit(procedure);
    this.clear();
    this.setAvailable();
  }

  onProcedureInputKeyup(e: any) {
    const text = e.target.value;
    this.filteredProcedures = this.availableProcedures.filter(x => x.procedureNumber?.trim()?.toLowerCase()?.includes(text?.trim()?.toLowerCase()) || x?.title?.trim()?.toLowerCase()?.includes(text?.trim()?.toLowerCase()));
  }

}
