<div class="card">
  <div class="card-header">
    <span class="card-title">{{ title }}</span>
  </div>
  <div class="card-body max-60vh">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label [innerHTML]="labelName"></mat-label>
          <input
            matInput
            name="name"
            [disabled]="loading"
            [(ngModel)]="aplCategory.name"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label [innerHTML]="labelInitials"></mat-label>
          <input
            matInput
            name="initials"
            [disabled]="loading"
            [(ngModel)]="aplCategory.code"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <user-chip
          [placeholder]="labelManager"
          [disabled]="loading"
          [relatedID]="aplCategory.id"
          [relatedTag]="'aplCategoryID'"
          [selectedUsers]="aplCategory.aplCategoryManagers"
          (error)="alert.error($event)"
        ></user-chip>
      </div>
    </div>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        color="warn"
        type="button"
        [disabled]="loading"
        (click)="cancel()"
        [innerHTML]="labelCancel"
      ></button>
      <button
        mat-flat-button
        color="primary"
        type="button"
        [disabled]="loading"
        (click)="submit()"
        [innerHTML]="labelAccept"
      ></button>
    </div>
  </div>
</div>
