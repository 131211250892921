export enum MessageTypeEnum {
    Success = 1,
    Warning = 2,
    Info = 3,
    Error = 4,
    None = 5
}

export enum MessagePartEnum {
    Backend = 1,
    Frontend = 2
}