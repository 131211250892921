import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs';
import { ReviewBoardType } from 'src/app/components/reviews/reviews.models';
import { RedirectService } from './redirect.service';
import { Document } from 'src/app/services/documents/documents';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Store } from '@ngxs/store';
@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit, OnChanges, OnDestroy {

  documents!: Document[];
  documents$!: Observable<Document[]>;
  documentsSubs!: Subscription;

  loading!: boolean;
  reviewBoardTypes?: ReviewBoardType[];
  isReview?: boolean;

  serialNo?: string;
  serial: any;
  notFoundText: string = '';
  message!: string;
  documentType?: number;

  constructor(
    private _route: ActivatedRoute,
    private service: RedirectService,
    private store: Store,
    private router: Router
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnDestroy(): void {
    this.documentsSubs?.unsubscribe();
  }


  loadDocuments() {
    this.documents$ = this.store.select(state => state.Documents.data);
    this.documentsSubs = this.documents$.subscribe(data => {
      if (data.length) {
        this.documents = data;
        if (this.serialNo)
          setTimeout(() => {
            this.redirectTo();
          }, 1000);
      }
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.serialNo = this._route.snapshot.paramMap.get('serialNo')?.toUpperCase();
    if (this.serialNo)
      this.message = 'Redirecting to ' + this.serialNo;
    this.notFoundText = '';
    this.loadDocuments();
  }

  redirectTo() {
    if (this.documents?.length) {
      const document = this.documents.find(x => x.serialNo == this.serialNo || x.serialNo == this.serialNo?.split(' ')[0]);
      if (document) {
        this.service.openDocument(document);
      }
      else {
        this.notFoundText = `${this.serialNo} Not Found!`;
        this.loading = false;
      }
    }
    else {
      setTimeout(() => {
        this.loadDocuments();
      }, 1000);
    }
  }
}
