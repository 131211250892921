<div class="mt-3" *ngIf="visible">
  <form-workflow
    [form]="form"
    [sectionNumber]="5"
    [leftConfigs]="[111]"
    [rightConfigs]="[112]"
    [disabled]="disabled"
    (loading)="loading.emit($event)"
  >
  </form-workflow>
</div>
