import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PendingApprovalsRefresh } from './pending-approvals.action';
import { PendingApprovalsV2Service } from '../../pending-approvals-v2.service';
import { PendingApprovals } from 'src/app/services/pending-approvals/pending-approval';

export class PendingApprovalsStateModel {
  data!: PendingApprovals | null;
}

@State<PendingApprovalsStateModel>({
  name: 'PendingApprovals',
  defaults: {
    data: null,
  }
})
@Injectable()
export class PendingApprovalsState {
  constructor(
    private service: PendingApprovalsV2Service,
  ) { }

  @Selector()
  static read(state: PendingApprovalsStateModel) {
    return state.data;
  }

  @Action(PendingApprovalsRefresh)
  refresh({ patchState }: StateContext<PendingApprovalsStateModel>) {
    this.service.get().subscribe(
      data => {
        patchState({ data });
      },
      error => console.error(error)
    );
  }

}
