import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PrivilegeService } from '../../privilege.service';

import { PrivilegeCreate, PrivilegeDelete, PrivilegeRefresh, PrivilegeUpdate } from './privilege.action';
import { Privilege } from 'src/app/components/catalogs/roles/services/role-privilege';

export class PrivilegeStateModel {
  data: Privilege[] = [];
}

@State<PrivilegeStateModel>({
  name: 'Privileges',
  defaults: {
    data: []
  }
})
@Injectable()
export class PrivilegeState {
  constructor(
    private _service: PrivilegeService
  ) { }

  @Selector()
  static read(state: PrivilegeStateModel) {
    return state.data;
  }

  @Action(PrivilegeRefresh)
  refresh({ patchState }: StateContext<PrivilegeStateModel>) {
    this._service.getAll().toPromise().then(data => {
      patchState({ data });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(PrivilegeCreate)
  create({ getState, patchState }: StateContext<PrivilegeStateModel>, { item }: PrivilegeCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(PrivilegeUpdate)
  update({ getState, patchState }: StateContext<PrivilegeStateModel>, { id, item }: PrivilegeUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    const currentState = state.data;

    if (index >= 0) {
      currentState.splice(index, 1, item);
      patchState({ data: currentState });
    }
  }

  @Action(PrivilegeDelete)
  delete({ getState, patchState }: StateContext<PrivilegeStateModel>, { id }: PrivilegeDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
