import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, OnDestroy, Injector } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { FormStatusEnum, WorkRequired, OperationalApprovalCode, ComponentType, FormType, OlogNotificationType, Fields } from 'src/app/common/enumerations/enumerations';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { WfSignature, WfSignatureResource, SaveTaskResource, WFTaskSignatureResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { SCFMaster, SaveSCFStatusResource } from '../../scf';
import { SCFPrivileges, SCFStatus, ScfV2Service, SCFWorkRequired } from '../../scf-v2.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { OLogService } from 'src/app/components/olog/olog.service';

@Component({
  selector: 'scf-v2-main-s5',
  templateUrl: './scf-v2-main-s5.component.html',
  styleUrls: ['./scf-v2-main-s5.component.scss']
})
export class ScfV2MainS5Component extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() scfMaster?: SCFMaster | null;
  @Input() disabled? = true;
  @Input() privileges?: SCFPrivileges;
  @Input() status?: SCFStatus;

  @Output() changed = new EventEmitter<SCFMaster>();
  @Output() loading = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<SCFMaster>();

  disableControls = true;

  signatures?: WfSignature[];
  wfSignatures?: WfSignatureResource[];
  wfSignCount?: number;

  questionDisabled = false;

  scfMasters?: SCFMaster[];
  scfMasters$!: Observable<SCFMaster[]>;
  scfMastersSubs!: Subscription;

  message?: string;

  componentType = ComponentType;

  constructor(
    private scfService: ScfV2Service,
    private wf: WorkFlowService,
    protected override injector: Injector,
    private _olog: OLogService
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.scfMastersSubs?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  ngOnInit(): void {
    this.loadSCFmasters();
    this.initializeForm();
  }

  initializeForm() {

  }

  refresh() {
    this.setDisabled();
    this.getValues();
  }

  loadSCFmasters() {
    this.scfMasters$ = this.store.select(state => state.SCFMasters.data);
    this.scfMastersSubs = this.scfMasters$.subscribe(data => {
      this.scfMasters = data;
      if (this.scfMaster) {
        this.scfMaster = this.scfMasters.find(x => x.id == this.scfMaster?.id);
        this.getValues();
      }
    });
  }


  setDisabled() {
    this.disabled =
      this.scfMaster?.status != FormStatusEnum.WorkCompleted || this.scfMaster?.status != FormStatusEnum.WorkCompleted ||
      !this.getCurrentUser();
  }

  getValues() {
    this.wfSignatures = [];
    this.signatures = [];
    this.disabled = this.disabled && (this.scfMaster?.status == FormStatusEnum.Amendment && !this.status?.isViewingAmendment || this.scfMaster?.status != FormStatusEnum.Amendment);
    this.message = this.getMessage('OperationApprovalPendingAmendment')?.description;
    if (this.scfMaster) {
      if (this.status?.isViewingAmendment) {
        const scfAmendment = this.scfMaster?.scfAmendments?.find(x => x.active);
        this.wfSignatures = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == 4)?.wfSignatures?.filter(x => x.wfTaskLocal.amendmentID == scfAmendment?.id).sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
        const wfSignaturesOriginal = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == 4)?.wfSignatures?.filter(x => x.wfTaskLocal.amendmentID == null).sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
        this.wfSignatures?.map(s => {
          s.followUpChanged = wfSignaturesOriginal?.find(x => x.code == s.code)?.followUpChanged;
          s.lastFollowUpChanged = wfSignaturesOriginal?.find(x => x.code == s.code)?.lastFollowUpChanged;
          s.lastFollowUpCheckChanged = wfSignaturesOriginal?.find(x => x.code == s.code)?.lastFollowUpCheckChanged;
          s.answerChangedBy = wfSignaturesOriginal?.find(x => x.code == s.code)?.answerChangedBy;
          s.answerChangedById = wfSignaturesOriginal?.find(x => x.code == s.code)?.answerChangedById;
        });
      }
      else {
        this.wfSignatures = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == 4)?.wfSignatures?.filter(x => x.wfTaskLocal.amendmentID == null).sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
      }
      this.createSignatures();
    }
  }

  createSignatures() {
    this.wfSignCount = this.wfSignatures?.filter(x => x.signed).length;
    if (this.wfSignatures) {
      for (const t of this.wfSignatures) {
        const signature: WfSignature = {
          id: t.id,
          code: t.code,
          approveId: t.approveID,
          unapproveId: t.unapproveID,
          disapproveId: t.disapproveID,
          amendmentId: t.amendmentID,
          amendmentNumber: t.amendmentNumber,
          name: t.titleBefore,
          roles: t.roles.map(r => r?.id),
          roleCodes: t.roles.sort((r1, r2) => this.utils.sort(r1.code, r2.code)).map(r => r?.code).join('/'),
          disabled: !t.enabled || this.disabled || (t.answerChangedById ? true : false) || this.disableClose(t),
          approvedBy: t.signedBy,
          approved: t.signed,
          approvedOn: t.signedOn,
          approve: t.approveEnabled && t.value,
          unapprove: t.unapproveEnabled,
          disapprove: t.disapproveEnabled,
          amendment: t.amendmentEnabled,
          required: t.value,
          taskId: t.taskID,
          value: t.value,
          enabled: t.enabled,
          type: (t.type == 9 || t.titleBefore != 'SCF Complete') ? 3 : (t.type == 0 || t.titleBefore == 'SCF Complete') ? 23 : t.type, // Older forms have different type values
          textValue: t.text,
          configuration: t.configuration,
          question: t.question,
          privilegeToApproveAnswer: t.privilegeToApproveAnswer,
          answerChangedBy: t.answerChangedBy,
          answerChangedById: t.answerChangedById,
          followUpChanged: t.followUpChanged,
          lastFollowUpChanged: t.lastFollowUpChanged,
          lastFollowUpCheckChanged: t.lastFollowUpCheckChanged,
          pendingChange: t.pendingChange,
          pendingChangeObj: t.pendingChangeObj,
          wfTaskLocal: t.wfTaskLocal,
          status: this.scfMaster?.status
        };

        this.signatures?.push(signature);
      }
    }
  }

  disableClose(t: WfSignatureResource) {
    let val = false;
    if (t.type == ComponentType.CloseButton) {
      return this.signatures?.filter(s => s.type != ComponentType.CloseButton).some(s => s.value && !s.approved);
    }
    return val;
  }

  async setChecksFromWorkRequired(workRequired: SCFWorkRequired) {
    if (this.signatures && this.scfMaster) {
      const saveTasks: SaveTaskResource[] = [];
      switch (workRequired.workRequired) {
        case WorkRequired.PermanentAccRemoval:
          await this.saveSCFMaster();
          saveTasks.push({ id: this.signatures.find(x => x.code == OperationalApprovalCode.ACREVREQ)?.id ?? 0, value: workRequired.checked ?? false });
          if (!this.scfMaster?.permanentBLRemoval) {
            saveTasks.push({ id: this.signatures.find(x => x.code == OperationalApprovalCode.DOCUPREQ)?.id ?? 0, value: workRequired.checked ?? false });
            saveTasks.push({ id: this.signatures.find(x => x.code == OperationalApprovalCode.RADSVREQ)?.id ?? 0, value: workRequired.checked ?? false });
          }
          this.saveTasks(saveTasks);
          break;

        case WorkRequired.PermanentBLRemoval:
          await this.saveSCFMaster();
          saveTasks.push({ id: this.signatures.find(x => x.code == OperationalApprovalCode.BLREVREQ)?.id ?? 0, value: workRequired.checked ?? false });
          if (!this.scfMaster?.permanentAccRemoval) {
            saveTasks.push({ id: this.signatures.find(x => x.code == OperationalApprovalCode.DOCUPREQ)?.id ?? 0, value: workRequired.checked ?? false });
            saveTasks.push({ id: this.signatures.find(x => x.code == OperationalApprovalCode.RADSVREQ)?.id ?? 0, value: workRequired.checked ?? false });
          }
          this.saveTasks(saveTasks);
          break;

        case WorkRequired.TocaMovement:
          this.scfMaster.tocaMovement = workRequired.checked ?? false;
          await this.saveSCFMaster();
          this.loading.emit(false);
          break;

        case WorkRequired.AccRFShielding:
          this.loading.emit(false);
          break;

        case WorkRequired.BlShielding:
          this.loading.emit(false);
          break;
      }
    }
  }

  sign(taskId?: number, actionId?: number) {
    this.loading.emit(true);
    const wfTaskSignature: WFTaskSignatureResource = {
      taskId,
      actionId
    };
    this.scfService.sign(wfTaskSignature).toPromise().then(() => {
      this.loading.emit(false);
    }, error => {
      console.log(error);
      this.alert.defaultError();
      this.loading.emit(false);
    });
  }

  //Check any Operational Approval box
  async check(e: any, id?: number) {
    this.loading.emit(true);
    const signature = this.signatures?.find(x => x.id == id);
    if (signature && id) {
      signature.value = e;
      if (this.scfMaster?.status !== FormStatusEnum.Draft && this.scfMaster?.status !== FormStatusEnum.Amendment && this.scfMaster?.status != FormStatusEnum.New) {
        const saveTask: SaveTaskResource = {
          id,
          field: 'AnswerChangedById',
          answerChangedById: this.getCurrentUser()?.id
        };
        this.saveTask(saveTask);
        this.getValues();
      } else {
        const saveTask: SaveTaskResource = {
          id,
          value: e
        };
        signature.disabled = false;
        this.saveTask(saveTask);
      }
    }
  }

  async saveSCFMaster() {
    if (this.scfMaster) {
      this.loading.emit(true);
      this.setOpenDocument(FormType.SCF, this.scfMaster.id);
      this.scfMaster.scfHoldPoints = null;
      await this.scfService.update(this.scfMaster).toPromise().then(scfMaster => {
        this.scfMaster = scfMaster;
        this.getValues();
        this.setFormDirty(false);
        this.saved.emit(scfMaster);
      })
        .catch(error => {
          console.log(error);
          this.alert.defaultError();
          this.loading.emit(false);
        });
    }
  }

  async saveTask(saveTask: SaveTaskResource) {
    this.loading.emit(true);
    try {
      const data = await this.wf.save(saveTask).toPromise();
      const signature = this.signatures?.find(x => x.id == saveTask.id);
      if (signature)
        signature.value = data.value;
      if ((this.scfMaster?.status == FormStatusEnum.Draft || this.scfMaster?.status == FormStatusEnum.Amendment || this.scfMaster?.status == FormStatusEnum.New) && signature) {
        signature.disabled = false;
      }
      this.loading.emit(false);
    } catch (error) {
      console.log(error);
      this.alert.defaultError();
    }
  }

  async saveTasks(saveTasks: SaveTaskResource[]) {
    this.loading.emit(true);
    try {
      saveTasks.map(s => {
        const signature = this.signatures?.find(x => x.id == s.id);
        if (signature)
          signature.value = s.value;
      });
      const data = await this.scfService.saveTasks(saveTasks).toPromise().then(() => this.loading.emit(false));
    }
    catch (error) {
      console.log(error);
      this.alert.defaultError();
      this.loading.emit(false);
    }
  }

  //Approve or Disapprove pending Operational Approval change
  async approve(id?: number, approve: boolean = false) {
    if (id) {
      this.loading.emit(true);
      const signature = this.signatures?.find(x => x.id == id);
      if (signature?.type == this.componentType.OperationalApproval) {
        await this.wf.approveOpApproval(id, approve).toPromise();
      }
      else {
        if (approve) {
          await this.approveAnswer(id, true);
        }
        else {
          await this.disapproveAnswer(id);
        }
      }
      this.loading.emit(false);
      this.getValues();
    }
  }

  async approveAnswer(id?: number, e?: any) {
    if (id) {
      this.loading.emit(true);
      const saveTask: SaveTaskResource = {
        id,
        field: 'AnswerChangedById',
        answerChangedById: null,
        value: e
      };
      await this.saveTask(saveTask);
      this.loading.emit(false);
    }
  }
  async disapproveAnswer(id?: number) {
    if (id) {
      this.loading.emit(true);
      const saveTask: SaveTaskResource = {
        id,
        field: 'AnswerChangedById',
        answerChangedById: null
      };
      await this.saveTask(saveTask);
      this.loading.emit(false);
    }
  }

  async close(taskId?: number, actionId?: number) {
    const scfStatusResource = {
      id: this.scfMaster?.id,
      status: FormStatusEnum.Closed
    } as SaveSCFStatusResource;
    const response = await this._olog.postToOlog(FormType.SCF, this.scfMaster?.id ?? 0, OlogNotificationType.Closed);
    if (response?.ok) {
      this.loading.emit(true);
      this.scfService.putStatus(scfStatusResource).toPromise().then(async () => {
        if (this.scfMaster?.id) {
          const wfTaskSignature: WFTaskSignatureResource = {
            taskId,
            actionId
          };
          this.scfService.sign(wfTaskSignature).toPromise().then(() => {
            this.alert.message('formStateChanged', [new MessagePlaceholder('{serialNo}', this.scfMaster?.serialNo), new MessagePlaceholder('{status}', 'Closed')]);
            this.loading.emit(false);
          }, error => {
            console.log(error);
            this.alert.defaultError();
            this.loading.emit(false);
          });
        }
      });
    }
  }

  closeDisabled(s: WfSignature) {
    if (s.type == ComponentType.CloseButton) {
      const requiredSignatures = this.signatures?.filter(x => x.type == ComponentType.QuestionSignature && (x.value || x.answerChangedBy));
      return requiredSignatures?.filter(x => !x.approved).length != 0;
    }
    else { return false; }
  }

  diffValue(s: WfSignature) {
    return s.value != this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals.find(x => x.number == 4)?.wfSignatures?.find(x => x.code == s.code && x.wfTaskLocal.amendmentID == null)?.value;
  }

  async textChanged(text: string, wfTaskLocalID?: number) {
    if (this.isFormDirty()) {
      await this.saveSCFMaster();
      this.setFormDirty(false);
    }
    const saveTask: SaveTaskResource = {
      id: wfTaskLocalID ?? 0,
      text,
      formType: FormType.SCF,
      answerChangedById: this.currentUser?.id,
      field: Fields.TEXT,
      pendingChange: this.scfMaster?.status !== FormStatusEnum.Draft && this.scfMaster?.status !== FormStatusEnum.Amendment && this.scfMaster?.status != FormStatusEnum.New
    };
    await this.wf.saveOpApproval(saveTask).toPromise();
  }
}
