<div class="buttons d-print-none">
  <div class="row">
    <div class="col-6">
      <button
        type="button"
        mat-flat-button
        *ngIf="
          version &&
          version?.status == 0 &&
          hasPrivilege(privilegeEnum.ApproveABSITemplate)
        "
        color="warn"
        (click)="this.canceled.emit(true)"
      >
        Delete
      </button>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <button
        type="button"
        mat-flat-button
        *ngIf="
          version?.statusName == 'New' &&
          hasPrivilege(privilegeEnum.CreateABSITemplate)
        "
        color="primary"
        [disabled]="disabled"
        (click)="this.approved.emit(true)"
      >
        Approve
      </button>
    </div>
  </div>
</div>
