<div class="card">
  <div class="card-header">
    <span class="card-title">
      {{
        inputData.resourceOrganization.endstation?.id ? "Edit" : "Add"
      }}
      Organization</span
    >
  </div>
  <div class="card-body">
    <mat-form-field>
      <mat-label>Organization Name</mat-label>
      <input
        matInput
        [(ngModel)]="inputData.resourceOrganization.organization"
        type="text"
        placeholder="Organization Name"
        [matAutocomplete]="auto"
        (keyup)="filter($event)"
        [ngModelOptions]="{ standalone: true }"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayObject">
        <mat-option
          *ngFor="let organization of organizationsFiltered"
          [value]="organization"
        >
          {{ organization.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Endstation</mat-label>
      <mat-select
        [(ngModel)]="inputData.resourceOrganization.endstation"
        [compareWith]="compareResource"
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-option
          *ngFor="let endstation of inputData.endstations"
          [value]="endstation"
        >
          {{ endstation.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Branchline</mat-label>
      <mat-select
        [(ngModel)]="inputData.resourceOrganization.branchline"
        [compareWith]="compareResource"
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-option
          *ngFor="let branchline of inputData.branchlines"
          [value]="branchline"
        >
          {{ branchline.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="isSaving"
        (click)="add()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
