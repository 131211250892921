<div class="resource" *ngFor="let resource of resources" id="{{ resource.id }}">
  <div class="row">
    <div class="col-20">
      <h5 class="bold mb-2" [ngClass]="resource?.type == 1 ? 'blue' : 'green'">
        {{
          resource.displayName +
            (resource.displayName != resource.name
              ? " (" + resource.name + ")"
              : "")
        }}
      </h5>
    </div>
    <div class="col-80">
      {{ resource.description }}
    </div>
  </div>

  <div class="row ml-2 mb-1" *ngFor="let rssd of getRSSDs(resource)">
    <div class="col-20 bold" [ngClass]="rssd?.cssClass">
      {{ rssd?.name }}
    </div>
    <div class="col-80">{{ rssd?.keText }}</div>
  </div>
</div>
