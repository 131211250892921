import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { utils } from 'src/app/modules/libs/utils';
import { ToxicGas } from '../toxic-gas';
import { ToxicGasService } from '../toxic-gas.service';


@Component({
  selector: 'app-toxic-gas-editor',
  templateUrl: './toxic-gas-editor.component.html',
  styleUrls: ['./toxic-gas-editor.component.scss']
})

export class ToxicGasEditorComponent implements OnInit {
  @Input()
  dataItem!: ToxicGas;
  @Output() returnedItemEvent = new EventEmitter<ToxicGas>();

  //form values
  Id: number = 0;
  Username?: string;
  Date!: Date | null;
  Location!: string;
  Gas!: string;
  RoofAccess!: string;
  ResponsibleParty?: string;

  //flow
  subtitle!: string;
  saving!: boolean;

  //formGroup
  toxicGasForm!: FormGroup;
  get location(): AbstractControl | null { return this.toxicGasForm.get("location"); }
  get gas(): AbstractControl | null { return this.toxicGasForm.get("gas"); }

  constructor(
    public dialogRef: MatDialogRef<ToxicGasEditorComponent>,
    public service: ToxicGasService,
    private formBuilder: FormBuilder
  ) { }

  validate(): boolean {
    if (this.Date == null || this.Date == undefined) { return false; }
    if (utils.IsNullOrWhiteSpace(this.Location)) { return false; }
    if (utils.IsNullOrWhiteSpace(this.Gas)) { return false; }
    if (this.RoofAccess.length == 0 || this.RoofAccess == null || this.RoofAccess == undefined) { return false; }
    return true;
  }

  save() {
    if (this.validate()) {
      const self = this;
      this.saving = true;
      this.service.save({
        id: this.Id,
        name: this.Gas,
        description: this.Location,
        date: this.Date ?? new Date(),
        responsibleParty: this.ResponsibleParty,
        roofAccess: this.RoofAccess == "true"
      }).subscribe({
        next(returnedItem: ToxicGas) {
          self.returnedItemEvent.emit(returnedItem);
        },
        error(msg) {
          console.log(msg);
        },
        complete() {
          self.dialogRef.close();
          self.saving = false;
        }
      });
    }
  }

  ngOnInit() {
    this.initializeForm();
    if (this.dataItem != null && this.dataItem != undefined) {
      this.subtitle = this.dataItem.id == 0 ? this.dataItem.name == "" ? "NEW" : "COPY" : "EDIT";
      this.Id = this.dataItem.id;
      this.Username = this.dataItem.createdByUser;
      this.Location = this.dataItem.description;
      this.Gas = this.dataItem.name;
      this.Date = this.dataItem.date;
      this.RoofAccess = this.dataItem.roofAccess.toString();
      this.ResponsibleParty = this.dataItem.responsibleParty;
    }
  }

  initializeForm() {
    this.toxicGasForm = this.formBuilder.group({
      location: [{ value: '', disabled: this.saving }, [Validators.required, utils.IsWhiteSpaceReactiveForm]],
      gas: [{ value: '', disabled: this.saving }, [Validators.required, utils.IsWhiteSpaceReactiveForm]],
      responsibleParty: [{ value: '', disabled: this.saving }]
    });
  }

  getErrorMsg(control: AbstractControl | null): string | null {
    if (control) {
      if (control.hasError("required")) return "You must enter a value";
      if (control.hasError("hasWhiteSpace")) return "Enter a valid value";
    }
    return null;
  }
}
