<scf-v2-main-s4-nowork
  *ngIf="hasNoWorkTasks()"
  [scfMaster]="scfMaster"
  [disabled]="
    disabled &&
    scfMaster?.status != formStatusEnum.Active &&
    scfMaster?.status != formStatusEnum.HoldPoint
  "
  [privileges]="privileges"
  [status]="status"
  (loading)="setLoading($event)"
>
</scf-v2-main-s4-nowork>

<scf-v2-main-s4-holdpoints
  *ngIf="hasHoldPointTasks()"
  [scfMaster]="scfMaster"
  [disabled]="
    disabled &&
    scfMaster?.status != formStatusEnum.Active &&
    scfMaster?.status != formStatusEnum.HoldPoint
  "
  [privileges]="privileges"
  [status]="status"
  (loading)="setLoading($event)"
>
</scf-v2-main-s4-holdpoints>
<div class="pt-2 mt-3" *ngIf="signatures?.length">
  <h5 class="section-title">Work Complete</h5>

  <div class="row px-3 justify-content-center">
    <div
      class="col px-1 pb-2"
      id="{{ s.disapprove }}"
      *ngFor="let s of signatures"
    >
      <button-signature
        #btnSign
        [roleId]="s.roles"
        [roleCodes]="s.roleCodes"
        [user]="s.approvedBy"
        [title]="s.name"
        [disabled]="s.disabled"
        [value]="s.approved"
        [showApprove]="s.approve"
        [showDisapprove]="s.disapprove"
        [showUnapprove]="s.unapprove"
        [date]="s.approvedOn"
        (approve)="approve(s)"
        (disapprove)="disapprove(s, $event)"
        (unapprove)="unapprove(s)"
        [enabled]="s.enabled"
      ></button-signature>
    </div>
  </div>
</div>
