import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, OnDestroy, SimpleChanges, Injector } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { APLCategory } from 'src/app/components/apl/services/apl-category.model';
import { APLGroup } from 'src/app/components/apl/services/apl-group.model';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { APLGroupService } from '../../../apl-group.service';
import { APLGroupRefresh } from '../../../store/apl-group/apl-group.action';

@Component({
  selector: 'apl-category-v2-groups',
  templateUrl: './apl-category-v2-groups.component.html',
  styleUrls: ['./apl-category-v2-groups.component.scss']
})
export class AplCategoryV2GroupsComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() aplCategory!: APLCategory;
  @Input() enableMove!: boolean;
  @Input() filter!: string;

  @Output() loading = new EventEmitter<boolean>();

  aplGroups?: APLGroup[];
  aplGroupsFiltered!: APLGroup[];
  aplGroups$!: Observable<APLGroup[]>;
  aplGroupsSubscription!: Subscription;
  rolesSelected!: Role[];

  aplGroupsPending!: APLGroup[];
  aplGroupsPending$!: Observable<APLGroup[]>;
  aplGroupsPendingSubscription!: Subscription;

  aplGroupGlobal$!: Observable<any>;
  aplGroupGlobalSubscription!: Subscription;

  constructor(
    protected override injector: Injector,
    private aplGroupService: APLGroupService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.aplGroups = this.aplCategory.aplGroups;
    this.loadPendingGroups();
  }

  override ngOnDestroy(): void {
    this.aplGroupsSubscription?.unsubscribe();
    this.aplGroupsPendingSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.loadGlobalGroups();
  }

  loadGlobalGroups() {
    this.aplGroupGlobal$ = this.store.select(state => state.APLGroup.all);
    this.aplGroupGlobalSubscription = this.aplGroupGlobal$.subscribe((aplGroups: APLGroup[]) => {
      this.rolesSelected = [];
      aplGroups.map(aplGroup => {
        this.rolesSelected.push(...aplGroup.aplGroupRoles?.map(x => x.role) as Role[]);
        aplGroup.aplGroupRoles = aplGroup.aplGroupRoles?.sort((a, b) => this.utils.sort(a.order, b.order, true));
        aplGroup.aplProcedureTrainingCoordinators = aplGroup.aplProcedureTrainingCoordinators ? aplGroup.aplProcedureTrainingCoordinators : [];
      });
    });
  }

  loadPendingGroups() {
    this.aplGroupsPending$ = this.store.select(state => state.APLGroup.allPendingGlobal);
    this.aplGroupsPendingSubscription = this.aplGroupsPending$.subscribe(data => {
      this.aplGroupsPending = data.filter(x => x.aplGroupMaster?.aplCategoryMasterID == this.aplCategory.aplCategoryMasterID);
      this.aplGroupsPending?.map(aplGroup => {
        if (aplGroup.status !== 1) {
          const normalAplGroup = this.aplGroups?.find(group => group.id === aplGroup.previousVersionID);
          if (normalAplGroup) {
            normalAplGroup.status = aplGroup.status;
            normalAplGroup.newVersion = aplGroup;
          }
          else {
            this.aplGroups?.push(aplGroup);
          }
          this.aplCategory.pendingGroups = true;
        }
      });
      this.aplGroups?.sort((a, b) => this.utils.sort(a.aplGroupMaster?.order, b.aplGroupMaster?.order));
    });
  }

  isManager() {
    return this.aplCategory?.aplCategoryManagers?.map(x => x.user?.id).includes(this.currentUser?.id);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.aplGroups) {
      const from = this.aplGroups[event.previousIndex];
      const to = this.aplGroups[event.currentIndex];
      this.aplGroupService.reorder(from.aplGroupMasterID ?? 0, to.aplGroupMasterID ?? 0).subscribe(
        () => {
          // this.loading = false;
        },
        error => this.alert.error(error.error),
      );
      moveItemInArray(this.aplGroups, event.previousIndex, event.currentIndex);
    }
  }

}
