<div class="d-print-none">
  <div class="row">
    <div class="col-6">
      <button
        type="button"
        mat-flat-button
        *ngIf="scfMaster && privileges?.canCancel"
        color="warn"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button
        type="button"
        mat-flat-button
        *ngIf="
          scfMaster &&
          privileges?.canCancelAmendment &&
          this.status?.isViewingAmendment
        "
        color="warn"
        (click)="cancelAmendment()"
      >
        Cancel Amendment
      </button>
    </div>
    <div class="col-6 text-right">
      <button
        type="button"
        mat-flat-button
        *ngIf="
          (privileges?.canEdit || privileges?.canCreate) &&
          (this.status?.isCreating || this.status?.isEditing)
        "
        [disabled]="disableControls || !isFormDirty()"
        color="accent"
        (click)="save(formStatusEnum.Draft)"
      >
        {{
          scfMaster?.status !== formStatusEnum.New
            ? "Update Draft"
            : "Save Draft"
        }}
      </button>
      <button
        type="button"
        mat-flat-button
        *ngIf="
          (privileges?.canEditAmendment || privileges?.canCreateAmendment) &&
          (this.status?.isCreatingAmendment ||
            this.status?.isEditingAmendment) &&
          this.status?.isViewingAmendment
        "
        [disabled]="disableControls"
        color="accent"
        (click)="save(formStatusEnum.Amendment)"
      >
        Update Amendment
      </button>
      <button
        type="button"
        mat-flat-button
        *ngIf="privileges?.canUnsubmit"
        color="warn"
        (click)="save(formStatusEnum.Draft)"
      >
        Unsubmit
      </button>
      <button
        type="button"
        mat-flat-button
        *ngIf="privileges?.canUnsubmitAmendment"
        color="warn"
        (click)="unsubmitAmendment()"
      >
        Unsubmit Amendment
      </button>
      <button
        class="ml-2"
        type="button"
        mat-flat-button
        [disabled]="disableControls || !isValid()"
        *ngIf="
          privileges?.canSubmit &&
          (this.status?.isCreating || this.status?.isEditing)
        "
        color="primary"
        (click)="save(formStatusEnum.WorkApprovals)"
      >
        Submit
      </button>
      <button
        class="ml-2"
        type="button"
        mat-flat-button
        [disabled]="disableControls || !isValid()"
        *ngIf="
          privileges?.canSubmitAmendment &&
          this.status?.isViewingAmendment &&
          (this.status?.isEditingAmendment || this.status?.isCreatingAmendment)
        "
        color="primary"
        (click)="submitAmendment()"
      >
        Submit
      </button>
    </div>
  </div>
</div>
