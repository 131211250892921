import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APLGroup } from 'src/app/components/apl/services/apl-group.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class APLGroupService {
  private url = `${environment.urls.baseApiUrl}/APLGroup`;

  constructor(
    private http: HttpClient,
  ) { }

  public create(aplGroup: APLGroup): Observable<APLGroup> {
    return this.http.post<APLGroup>(this.url, aplGroup);
  }

  public readAllPending(): Observable<APLGroup[]> {
    return this.http.get<APLGroup[]>(`${this.url}/AllPending`);
  }

  public read(): Observable<APLGroup[]> {
    return this.http.get<APLGroup[]>(this.url);
  }

  public readPendingByAPLCategoryMasterID(aplCategoryMasterID: number): Observable<APLGroup[]> {
    return this.http.get<APLGroup[]>(`${this.url}/Pending/${aplCategoryMasterID}`);
  }

  public delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.url}/${id}`);
  }

  public approve(id: number): Observable<APLGroup> {
    return this.http.patch<APLGroup>(`${this.url}/Approve/${id}`, null);
  }

  public disapprove(id: number): Observable<APLGroup> {
    return this.http.patch<APLGroup>(`${this.url}/Disapprove/${id}`, null);
  }

  public reorder(fromID: number, toID: number): Observable<APLGroup> {
    return this.http.patch<APLGroup>(`${this.url}/Reorder/${fromID}/${toID}`, null);
  }

  public reorderRole(fromID: number, toID: number): Observable<APLGroup> {
    return this.http.patch<APLGroup>(`${this.url}/ReorderRole/${fromID}/${toID}`, null);
  }
}
