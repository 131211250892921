import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatFileUploadService } from '../mat-file-upload.service';
import { ProgressStatus, ProgressStatusEnum } from '../mat-file/mat-file-progress';
import { utils } from 'src/app/modules/libs/utils';
import { AlertService } from 'src/app/services/alert/alert.service';
import FileV2 from 'src/app/services/file/file-v2.model';
import { FileV2Service } from 'src/app/services/file/file-v2.service';

@Component({
  selector: 'mat-file-v2-upload',
  templateUrl: './mat-file-v2-upload.component.html',
  styles: [
    '.file-input-button { margin-right: 8px !important }',
    '.file-input-text { font-size: 14px !important; margin-right: 8px !important }',
    '.maxWidth{ max-width: fit-content; height: fit-content; }',
    '@media only screen and (max-width: 1600px){.maxWidth{ height: fit-content; white-space: normal; line-height: 1em; padding: 0.5em; }}'
  ]
})
export class MatFileV2UploadComponent implements OnInit {
  // Propiedades de entrada de la clase
  @Input() selectButtonText = 'Add Files';
  @Input() uploadButtonText = 'Upload';
  @Input() allowMultipleFiles = true;
  @Input() showUploadButton = false;
  @Input() showFiles = false;
  @Input() acceptedTypes = '*.pdf';
  @Input() customSvgIcon?: string | null = null;
  @Input() uploadId!: string;
  @Input() commentId? = 0;
  @Input() radiationAreaItemId? = 0;
  @Input() disabled?: boolean;
  @Input() upload_when_selected = false;
  @Input() save_files_database = 'Yes';
  @Input() typeDocument?: string;

  // Propiedades de salida de la clase
  @Output() selectedFilesChanged = new EventEmitter<FileList>();
  @Output() public uploadStatus: EventEmitter<ProgressStatus>;

  selectedFiles?: FileList;
  selectedFileText = '';
  loading = false;
  files?: FileList;
  attachments: Attachment[] = [];
  fileUrl: any;
  fileToUpload?: File;
  fileIdServer!: number;
  fileServer?: FileV2;
  archivo?: FileV2;
  path?: string;

  fileList = new DataTransfer();

  public percentage?: number;
  public showProgress?: boolean;
  public showDownloadError?: boolean;
  public showUploadError?: boolean;

  uploading?: boolean;



  // Decorador del elemento nativo fileInput (En el .html)
  @ViewChild('fileInput') fileInputRef!: ElementRef;

  constructor(
    private upload: MatFileUploadService,
    private _alert: AlertService,
    private file2Service: FileV2Service
  ) { this.uploadStatus = new EventEmitter<ProgressStatus>(); }

  ngOnInit(): void {
  }

  ngOnChange() {
    console.log(this.uploadId);
  }

  filesChanged(e: any | null): void {
    const files = e.target.files
    if (files) {
      if (files && files.length > 0) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < files?.length; i++) {
          this.fileList.items.add(files[i]);
          if (
            files[i].name.includes('.exe') ||
            files[i].name.includes('.msi') ||
            files[i].name.includes('.js') ||
            files[i].name.includes('.htm')
          ) {
            this._alert.error(environment.messages.upload_invalid_exe);
            return;
          }
        }
        this.selectedFiles = this.fileList.files;
      }

      if (this.upload_when_selected) {
        this.uploading = true;
        if (this.typeDocument != 'RadAreaImages' && this.commentId) {
          const id: number = +this.commentId;
          this.uploadFiles(id);
        }
        else if (this.typeDocument == 'RadAreaImages' && this.radiationAreaItemId) {
          const id: number = +this.radiationAreaItemId;
          this.uploadFiles(id);
        }
      } else {
        this.selectedFilesChanged.emit(this.selectedFiles);
        if (this.selectedFiles) {
          const numSelectedFiles = this.selectedFiles.length;
          this.selectedFileText = `${numSelectedFiles} files selected`;
        } else {
          this.selectedFileText = '';
          this.resetFileInput();
        }
      }
    }
    else {
      this.selectedFileText = '';
      this.resetFileInput();
    }
  }

  uploadFiles(id: number) {
    this.loading = true;
    const promise = new Promise((resolve, reject) => {
      if (this.selectedFiles) {
        this.uploadDocuments(this.selectedFiles, id).then(() => {
          this.resetFileInput();
          this.loading = false;
          this.uploading = false;
          resolve(true);
        });
      } else {
        this.loading = false;
        this.uploading = false;
        reject(false);
      }
    });
    return promise;
  }

  uploadRadFiles(id: number, selectedFiles: FileList) {
    this.loading = true;
    this.selectedFiles = selectedFiles;
    const promise = new Promise((resolve, reject) => {
      if (this.selectedFiles) {
        this.uploadDocuments(this.selectedFiles, id).then(() => {
          this.resetFileInput();
          this.loading = false;
          this.uploading = false;
          resolve(true);
        });
      } else {
        this.loading = false;
        this.uploading = false;
        reject(false);
      }
    });
    return promise;
  }

  uploadDocuments(files: FileList, id: number) {
    const promise = new Promise(async (resolve, reject) => {
      this.uploadStatus.emit({ status: ProgressStatusEnum.START });
      this.loading = true;
      const formData = new FormData();
      let size = 0;
      this.setPath(this.typeDocument, this.uploadId);

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < files.length; i++) {
        this.fileToUpload = files[i];

        // Save the file in the file server and obtain the id of the record of FileV2 table
        await this.getIdFileServer(this.fileToUpload).then((res) => {
          this.fileIdServer = res;
        });

        formData.append('file', this.fileToUpload, this.fileToUpload.name);
        formData.append('serialNo', this.uploadId);
        if (this.typeDocument == 'RadAreaImages') {
          formData.append('radiationAreaItemId', id.toString());
        }
        else if (this.typeDocument != 'RadAreaImages') {
          formData.append('commentId', id.toString());
        }
        formData.append('saveFileDatabase', this.save_files_database);
        formData.append('rootDirectory', this.fileIdServer.toString());
        formData.append('size', this.fileToUpload.size.toString());
        formData.append('eod', 'eod');

        size += this.fileToUpload.size;
      }

      this.upload.postFiles(formData).subscribe((data) => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              this.uploadStatusShow({
                status: ProgressStatusEnum.IN_PROGRESS,
                percentage: Math.round((data.loaded / data.total) * 100),
              });
              break;
            case HttpEventType.Response:
              this.fileInputRef.nativeElement.value = '';
              this.uploadStatusShow({ status: ProgressStatusEnum.COMPLETE });
              break;
          }
        }
        this.resetFileInput();
        this.loading = false;
        this.refresh();
        resolve(data);
      });
    });
    return promise;
  }

  // Save d the file in the file server and return the id of the record of file in FilesV2 table
  async getIdFileServer(file: File) {
    const promise = new Promise<number>(async (resolve) => {
      const form = new FormData();
      let idFileServer: number = 0;

      form.append('file', file);
      form.append('path', this.path + file.name);

      await this.file2Service.createFiles(form).toPromise().then(
        (file: FileV2) => {
          idFileServer = file.id;
          this.fileIdServer = file.id;
        },
        (error) => {
          idFileServer = 0;
          this.fileIdServer = 0;
          console.error(error);
        }
      );
      resolve(idFileServer);
    });
    return promise;
  }

  loadFiles() {
    this.attachments = [];
    if (this.uploadId) {
      this.upload.getFiles(this.uploadId).subscribe(res => {
        res.map((r: any) => {
          const attachment: Attachment = {
            id: r.id,
            fileName: r.fileName,
            fileSize: r.fileSize,
            createdon: r.createdOn,
            SizeKb: utils.numberWithCommas(Math.round(r.fileSize / 1024)) + ' Kb',
            fileExtension: r.fileExtension,
            fileIcon: ''
          };
          this.attachments.push(attachment);
        });
        this.attachments.map(a => {
          this.upload.iconCheck('../../../assets/icons/files/' + a.fileExtension.replace('.', '').toLocaleLowerCase() + '.png').subscribe(icon => {
            a.fileIcon = icon;
          });
        });
      });
    }
  }

  public uploadStatusShow(event: ProgressStatus) {
    switch (event.status) {
      case ProgressStatusEnum.START:
        this.showUploadError = false;
        break;
      case ProgressStatusEnum.IN_PROGRESS:
        this.showProgress = true;
        this.percentage = event.percentage;
        break;
      case ProgressStatusEnum.COMPLETE:
        this.showProgress = false;
        break;
      case ProgressStatusEnum.ERROR:
        this.showProgress = false;
        this.showUploadError = true;
        break;
    }
  }

  resetFileInput(): void {
    this.fileInputRef.nativeElement.value = '';
    this.fileList = new DataTransfer();
    this.selectedFiles = this.fileList.files;
  }

  setPath(folderName?: string, holderName?: string) {
    this.path = '/' + folderName + '/' + holderName + '/';
  }

  refresh() {
    this.selectedFileText = '';
    this.resetFileInput();

  }

  setId(id: string) {
    this.uploadId = id;
  }

}
interface Attachment {
  id: string;
  fileName: string;
  fileSize: number;
  createdon: Date;
  SizeKb?: string;
  fileExtension: string;
  fileIcon: string;
}
