import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { CanDeactivateResponse } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog-response';
import { PendingChangesDialogComponent } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog.component';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesGuard
  implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(
    component: CanComponentDeactivate
  ): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateService {

  formDirty!: boolean;

  constructor(
    private dialog: MatDialog
  ) { }

  async canDeactivate(): Promise<boolean> {
    if (this.formDirty) {
      const confirmation = this.dialog.open(PendingChangesDialogComponent, {
        height: 'fit-content',
        width: '400px',
        data: {}
      });

      const response: CanDeactivateResponse = await confirmation.afterClosed().toPromise();

      const isDiscard = response === CanDeactivateResponse.Discard;
      if (isDiscard) {
        this.formDirty = false; // Reset form dirty status
      }

      return isDiscard; // Return the user's decision
    }

    return true; // Allow navigation if the form is not dirty
  }

}
