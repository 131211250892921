import { Component, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Checklist } from 'src/app/components/checklists/checklists';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { FormControl, FormGroup } from '@angular/forms';
import { RadNoteType } from 'src/app/common/enumerations/enumerations';
import { RadLocation } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location';
import { RadMonitor } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor';
import { RadMonitorService } from 'src/app/components/catalogs/rad-monitor/services/rad-monitor.service';
import { RadNote, RadNoteSaveResource } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-note';
import { PlaceholdersService } from 'src/app/services/placeholders/placeholders.service';

@Component({
  selector: 'app-cl-rad-location',
  templateUrl: './cl-rad-location.component.html',
  styleUrls: ['./cl-rad-location.component.scss']
})
export class ClRadLocationComponent extends ClBaseComponent implements OnInit, OnDestroy, OnChanges {
  public valuesToSend: any[] = [];

  radLocation?: RadLocation;
  radLocations!: RadLocation[];
  radLocations$!: Observable<RadLocation[]>;
  radLocationsSubs!: Subscription;
  radLocationsFiltered!: RadLocation[];

  radMonitor?: RadMonitor | null;
  radMonitors?: RadMonitor[];
  radMonitorsFiltered?: RadMonitor[] = [];

  radLocationCtrl = new FormControl();
  radMonitorCtrl = new FormControl();
  reasonCtrl = new FormControl();

  failureMessage!: string;
  currentChecklist?: Checklist;

  public changeoutReasons = [
    { label: 'Routine for Calibration', value: 'calibration' },
    { label: 'Monitor Failure.', value: 'failure' }
  ];

  changeoutReason!: string;
  faultNote?: RadNote;
  faultNoteSave?: RadNoteSaveResource;

  constructor(
    protected override injector: Injector,
    private rmService: RadMonitorService,
    private placeholdersService: PlaceholdersService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.failureMessage = this.getMessage('RadMonitorComponent_Message').description;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadRadLocations();
    this.buildForm();
    this.currentChecklist = this.checklist as Checklist;
  }

  checkboxDisabled() {
    let enabled = !this.disabled;
    if (enabled) {
      enabled = this.radMonitor != null;
      if (enabled) {
        enabled = this.changeoutReason == 'calibration';
        if (!enabled) {
          enabled = this.faultNote?.description != null;
          if (!enabled) {
            enabled = this.checked ?? false;
          }
        }
      }
    }
    return !enabled;
  }

  buildForm() {
    this.formGroup = new FormGroup({
      radLocationCtrl: this.radLocationCtrl,
      radMonitorCtrl: this.radMonitorCtrl,
      reasonCtrl: this.reasonCtrl
    });
    if (this.disabled || this.checked) {
      this.formGroup.disable();
    }
    else {
      this.formGroup.enable();
    }
  }

  loadRadLocations() {
    this.radLocations$ = this.store.select(state => state.RadLocation.data);
    this.radLocationsSubs = this.radLocations$.subscribe(data => {
      this.radLocations = data.filter(x => !x.isEMShop && x.radMonitors?.length).sort((a, b) => this.utils.SortBeamlines(a.name, b.name));
      this.radLocations.map(x => x.type = x.radLocationType?.name);
      this.radLocationsFiltered = this.radLocations;
      this.getValues();
    });
  }

  checkChecked() {
    if (this.checked) {
      if (!this.radLocation || !this.radMonitor || !this.changeoutReason || (this.changeoutReason == 'failure' && !this.faultNote))
        if (this.getCurrentUser())
          this.answered.emit(false);
    }
  }

  getValues() {
    if (this.valueString) {
      this.valuesToSend = JSON.parse(this.valueString);
      const locationId = this.valuesToSend?.find(x => x.key === 'locationId')?.val;
      const location = this.valuesToSend?.find(x => x.key === 'location')?.val;
      const monitorId = this.valuesToSend?.find(x => x.key === 'monitorId')?.val;
      const monitor = this.valuesToSend?.find(x => x.key === 'monitor')?.val;
      const reason = this.valuesToSend?.find(x => x.key == 'changeoutReason')?.val;
      const faultNote = this.valuesToSend?.find(x => x.key == 'faultNote')?.val;
      if (locationId) {
        if (this.checked) {
          this.radLocation = location;
        }
        else {
          this.radLocation = this.radLocations.find(x => x.id == locationId);
        }
        this.radLocationCtrl.setValue(this.radLocation);
        this.radMonitors = this.radLocation?.radMonitors;
        this.radMonitors?.map(r => r.radLocation = null);
      }
      if (this.checked) {
        this.radMonitor = monitor;
      }
      else if (monitorId) {
        this.radMonitor = this.radMonitors?.find(x => x.id == monitorId);
      }
      if (this.radMonitor) {
        this.radMonitor.radLocation = this.radLocations.find(x => x.id == this.radMonitor?.radLocationID);
        this.rmService.setOutgoingMonitor(this.radMonitor);
      }
      this.radMonitorCtrl.setValue(this.radMonitor);
      if (reason) {
        this.changeoutReason = this.valuesToSend.find(x => x.key === 'changeoutReason')?.val;
        this.reasonCtrl.setValue(this.changeoutReason);
      }
      if (faultNote) {
        this.faultNote = faultNote;
      }
    }
    this.initials = this.user ? this.user.initials : '';
    this.checkChecked();
    this.setPlaceHolders();
  }

  override ngOnDestroy(): void {
    this.radLocationsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  displayObjectMon(obj: any): string {
    return obj ? obj.ehs : '';
  }

  radLocationSelected(radLocation: RadLocation) {
    this.radLocation = radLocation;
    this.radMonitors = radLocation.radMonitors;
    this.radMonitorsFiltered = this.radMonitors;
    if (this.radMonitors?.length == 1) {
      this.radMonitor = this.radMonitors[0];
      this.rmService.setOutgoingMonitor(this.radMonitor);
    }
    else {
      this.radMonitor = null;
    }
    this.radMonitorCtrl.setValue(this.radMonitor);
    this.saveData();
    this.setPlaceHolders();
  }

  radMonitorSelected(radMonitor: RadMonitor) {
    this.radMonitor = radMonitor;
    this.saveData();
    this.setPlaceHolders();
  }

  saveData() {
    this.valuesToSend = [];
    this.initials = this.checked ? this.getCurrentUser()?.initials : null;
    this.addUpdateValues('monitorId', this.radMonitor?.id);
    if (this.radMonitor) {
      this.radMonitor.radLocation = null;
      this.addUpdateValues('monitor', this.radMonitor);
      this.addUpdateValues('locationId', this.radLocation?.id);
      this.addUpdateValues('location', this.radLocation);
      this.addUpdateValues('changeoutReason', this.changeoutReason);
      this.addUpdateValues('faultNote', this.faultNoteSave);
      this.rmService.setOutgoingMonitor(this.radMonitor);
    }
    this.save.emit({ text: JSON.stringify(this.valuesToSend), hasRefresh: false });
  }

  checkboxChanged(e: any) {
    this.checked = e.checked;
    if (this.checked) {
      this.initials = this.getCurrentUser()?.initials;
    }
    else {
      this.initials = null;
    }
    this.answered.emit(this.checked);
  }

  addUpdateValues(key: string, val: any) {
    if (this.valuesToSend.some(x => x.key === key)) {
      const valueIndex = this.valuesToSend.map(x => x.key).indexOf(key);
      this.valuesToSend.splice(valueIndex, 1, { key, val });
    } else {
      this.valuesToSend.push({ key, val });
    }
  }

  filterRadLocation(e: any) {
    const text = e.target.value;
    this.radLocationsFiltered = this.radLocations.filter(x => x.name?.toLowerCase().includes(text.toLowerCase()));
  }

  filterRadMonitor(e: any) {
    const text = e.target.value;
    this.radMonitorsFiltered = this.radLocation?.radMonitors?.filter(x => x.ehs?.toLowerCase().includes(text.toLowerCase()));
  }

  changeoutReasonChange(optionSelected: string) {
    this.changeoutReason = optionSelected;
    this.saveData();
  }

  saveFaultNote(radNoteDescription: string) {
    this.faultNoteSave = {
      description: radNoteDescription,
      radLocationId: this.radLocation?.id,
      radMonitorId: this.radMonitor?.id,
      type: RadNoteType.Fault,
      updatedBy: this.getCurrentUser()?.id,
      updatedOn: new Date()
    };
    this.saveData();
  }

  setPlaceHolders() {
    if (this.radMonitor && this.radLocation) {
      this.radLocation.type = this.radLocation?.radLocationType?.name;
      this.radMonitor.radLocation = this.radLocation;
      this.placeholdersService.getKeyValuesRadMonitor(this.radMonitor, 'rmco');
    }
  }

}
