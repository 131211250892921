import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Form } from 'src/app/services/forms/form';

@Component({
  selector: 'ppstb-main-s6',
  templateUrl: './ppstb-main-s6.component.html',
  styleUrls: ['./ppstb-main-s6.component.scss']
})
export class PpstbMainS6Component {
  @Input() form?: Form | null;
  @Input() disabled!: boolean;

  @Output() loading = new EventEmitter<boolean>();
}
