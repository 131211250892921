<form [formGroup]="formGroup">
  <mat-form-field
    class="related-links no-subscript"
    [ngClass]="{ hilite: hilite }"
  >
    <mat-label>Related Documents and Links</mat-label>
    <mat-chip-grid #chipListRL formControlName="chipInputCtrl">
      <mat-chip-row
        *ngFor="let relatedLink of relatedLinks; let i = index"
        [selectable]="true"
        [removable]="true"
        (removed)="remove(relatedLink)"
        class="chip-row-height"
      >
        <a
          class="btn-link text-wrap"
          *ngIf="relatedLink?.url"
          href="{{ relatedLink.url }}"
          target="_blank"
          >{{ relatedLink.text }}</a
        >
        <span
          class="btn-link related-link-span manual-entry text-wrap"
          *ngIf="!relatedLink?.url"
          >{{ relatedLink.text }}</span
        >
        <mat-icon matChipRemove *ngIf="!disabled" class="d-print-none"
          >cancel</mat-icon
        >
      </mat-chip-row>
      <input
        class="input-hidden"
        #relatedLinksInput
        aria-label="relatedLinks"
        [matChipInputFor]="chipListRL"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      />
      <button
        class="add-button"
        mat-icon-button
        *ngIf="!disabled"
        (click)="open()"
        color="accent"
        matTooltip="Add Document or Link"
      >
        <mat-icon> add_box </mat-icon>
      </button>
    </mat-chip-grid>
  </mat-form-field>
</form>
