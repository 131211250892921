import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { OlogCategoryCreate, OlogCategoryDelete, OlogCategoryRefresh, OlogCategoryUpdate } from './olog-category.action';
import { OlogCategory } from '../../services/olog-category';
import { OlogCategoryService } from '../../services/olog-category.service';
import { utils } from 'src/app/modules/libs/utils';

export class OlogCategoryStateModel {
  data: OlogCategory[] = [];
}

@State<OlogCategoryStateModel>({
  name: 'OlogCategory',
  defaults: {
    data: []
  }
})
@Injectable()
export class OlogCategoryState {
  constructor(
    private ologCategoryService: OlogCategoryService
  ) { }

  @Selector()
  static read(state: OlogCategoryStateModel) {
    return state.data;
  }

  @Action(OlogCategoryRefresh)
  refresh({ patchState }: StateContext<OlogCategoryStateModel>) {
    this.ologCategoryService.GetOlogCategories().toPromise().then(data => {
      patchState({ data: utils.cloneDeep(data) });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(OlogCategoryCreate)
  create({ getState, patchState }: StateContext<OlogCategoryStateModel>, { item }: OlogCategoryCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(OlogCategoryUpdate)
  update({ getState, patchState }: StateContext<OlogCategoryStateModel>, { id, item }: OlogCategoryUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    const currentState = utils.cloneDeep(state.data);

    if (index >= 0) {
      currentState.splice(index, 1, utils.cloneDeep(item));
      patchState({ data: currentState });
    }
  }

  @Action(OlogCategoryDelete)
  delete({ getState, patchState }: StateContext<OlogCategoryStateModel>, { id }: OlogCategoryDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
