import { Procedure } from "src/app/components/procedure/models/procedure.model";

export class ProcedureRefresh {
  static readonly type = '[Procedure] Refresh';
}

export class ProcedureRefreshByType {
  static readonly type = '[Procedure] RefreshByType';
  constructor(public type: number) { }
}

export class ProcedureCreate {
  static readonly type = '[Procedure] Create';

  constructor(public item: Procedure) { }
}

export class ProcedureUpdate {
  static readonly type = '[Procedure] Update';

  constructor(public id: number, public item: Procedure) { }
}

export class ProcedureDelete {
  static readonly type = '[Procedure] Delete';

  constructor(public id: number) { }
}

export class ProcedureSet {
  static readonly type = '[Procedure] Set';

  constructor(public items: Procedure[]) { }
}
