import { User } from "src/app/components/catalogs/user-catalog/services/user";

export class OlogEmail {
  id?: number;
  userId?: number;
  status?: number;
  documentTypeId?: number;
  deleteReason?: string;
  isModified?: boolean;
  user?: User;
  createdOn?: Date;
  createdBy?: number;
  updatedOn?: Date;
  updatedBy?: number;
}
