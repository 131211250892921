<div class="card">
  <div class="card-header">
    <span class="card-title">{{ title }}</span>
    <mat-progress-bar
      class="progress-bar"
      mode="indeterminate"
      *ngIf="loading"
    ></mat-progress-bar>
  </div>
  <div class="card-body max-60vh">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>
            <span [innerHTML]="labelName"></span>
          </mat-label>
          <input
            matInput
            name="name"
            [disabled]="loading"
            [(ngModel)]="aplGroup.name"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <comments-box
          [textValue]="aplGroup.description"
          [disabled]="loading"
          [formType]="'APL'"
          [serialNo]="'Files'"
          title="Description"
          (textChanged)="aplGroup.description = $event"
        ></comments-box>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <user-chip
          [placeholder]="labelManagers"
          [disabled]="loading"
          [relatedId]="aplGroup.id"
          [selectedUsers]="aplGroup.aplGroupManagers ?? []"
          (error)="alert.error($event)"
        ></user-chip>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <role-chip
          [placeholder]="labelRoles"
          [disabled]="loading"
          [relatedId]="aplGroup.id"
          [roles]="aplGroup.aplGroupRoles ?? []"
          [selectedRoles]="rolesSelected"
          (error)="alert.error($event)"
        ></role-chip>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <user-chip
          [placeholder]="labelProcedureTrainingCoordinators"
          [disabled]="loading"
          [relatedId]="aplGroup.id"
          [selectedUsers]="aplGroup.aplProcedureTrainingCoordinators ?? []"
          [aplGroup]="aplGroup"
          (error)="alert.error($event)"
        ></user-chip>
      </div>
    </div>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        color="warn"
        type="button"
        [disabled]="loading"
        (click)="cancel()"
      >
        <span [innerHTML]="labelCancel"></span>
      </button>
      <button
        mat-flat-button
        color="primary"
        type="button"
        [disabled]="loading"
        (click)="submit()"
      >
        <span [innerHTML]="labelAccept"></span>
      </button>
    </div>
  </div>
</div>
