import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { MatFileUploadService } from './mat-file-upload.service';
import { ProgressStatus, ProgressStatusEnum } from './mat-file/mat-file-progress';
import { HttpEventType } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AlertService } from 'src/app/services/alert/alert.service';
import { utils } from '../../libs/utils';

@Component({
  selector: 'mat-file-upload',
  templateUrl: 'mat-file-upload.component.html',
  styles: [
    '.file-input-button { margin-right: 8px !important }',
    '.file-input-text { font-size: 14px !important; margin-right: 8px !important }',
    '.maxWidth{ max-width: fit-content; height: fit-content; }',
    '@media only screen and (max-width: 1600px){.maxWidth{ max-width: 6em; height: fit-content; white-space: normal; line-height: 1em; padding: 0.5em; }}'
  ],
})

export class MatFileUploadComponent implements OnInit {
  @Input() labelText = 'Attach files:';
  @Input() selectButtonText = 'Select File(s)';
  @Input() uploadButtonText = 'Upload File(s)';
  @Input() allowMultipleFiles = true;
  @Input() showUploadButton = false;
  @Input() showFiles = false;
  @Input() acceptedTypes = '*.pdf';
  @Input() customSvgIcon?: string | null = null;
  @Output() uploaded: EventEmitter<FileList> = new EventEmitter<FileList>();
  @Output() selectedFilesChanged: EventEmitter<FileList> = new EventEmitter<FileList>();

  @ViewChild('fileInput') fileInputRef!: ElementRef;
  selectedFiles?: FileList;
  selectedFileText = '';
  loading = false;
  files?: FileList;
  attachments: Attachment[] = [];
  fileToUpload?: File;

  public percentage?: number;
  public showProgress: boolean = false;
  public showDownloadError: boolean = false;
  public showUploadError: boolean = false;

  @Input()
  uploadId!: string;
  @Input()
  disabled: boolean = false;
  @Input() upload_when_selected = false;

  @Output() public uploadStatus: EventEmitter<ProgressStatus>;

  constructor(
    private upload: MatFileUploadService,
    private _alert: AlertService
  ) {
    this.uploadStatus = new EventEmitter<ProgressStatus>();
  }

  ngOnInit() {

  }

  ngOnChange() {
    console.log(this.uploadId);
  }

  filesChanged(e: any | null): void {
    const files = e.target.files;
    this.selectedFiles = files
    if (files && files.length > 0) {
      for (let i = 0; i < files?.length; i++) {
        if (files[i].name.includes('.exe') || files[i].name.includes('.msi') || files[i].name.includes('.js') || files[i].name.includes('.htm')) {
          this._alert.error(environment.messages.upload_invalid_exe);
          return;
        }
      }
    }

    if (this.upload_when_selected) {
      const id: number = +this.uploadId;
      this.uploadFiles(id);
    }
    else {
      this.selectedFilesChanged.emit(this.selectedFiles);
      if (this.selectedFiles) {
        const numSelectedFiles = this.selectedFiles.length;
        this.selectedFileText =
          numSelectedFiles === 1
            ? this.selectedFiles[0].name
            : `${numSelectedFiles} files selected`;
      } else {
        this.selectedFileText = '';
        this.resetFileInput();
      }
    }

  }

  setId(id: string) {
    this.uploadId = id;
  }

  refresh() {
    this.selectedFileText = '';
    this.resetFileInput();
    this.loadFiles();
  }

  uploadFiles(id: number) {
    this.loading = true;
    const promise = new Promise((resolve, reject) => {
      if (this.selectedFiles) {
        this.uploadDocuments(this.selectedFiles, id).then(() => {
          this.resetFileInput();
          this.uploaded.emit();
          this.loading = false;
          resolve(true);
        });
      }
      else {
        this.loading = false;
        reject(false);
      }
    });
    return promise;
  }

  loadFiles() {
    this.attachments = [];
    if (this.uploadId) {
      this.upload.getFiles(this.uploadId).subscribe(res => {
        res.map((r: any) => {
          const attachment: Attachment = {
            id: r.id,
            fileName: r.fileName,
            fileSize: r.fileSize,
            createdon: r.createdOn,
            SizeKb: utils.numberWithCommas(Math.round(r.fileSize / 1024)) + ' Kb',
            fileExtension: r.fileExtension,
            fileIcon: ''
          };
          this.attachments.push(attachment);
        });
        this.attachments.map(a => {
          this.upload.iconCheck('../../../assets/icons/files/' + a.fileExtension.replace('.', '').toLocaleLowerCase() + '.png').subscribe(icon => {
            a.fileIcon = icon;
          });
        });
      });
    }
  }

  resetFileInput(): void {
    this.fileInputRef.nativeElement.value = '';
  }

  uploadDocuments(files: FileList, id: number) {

    const promise = new Promise((resolve, reject) => {
      this.uploadStatus.emit({ status: ProgressStatusEnum.START });
      this.loading = true;
      const formData = new FormData();
      let size = 0;
      for (var i = 0; i < files.length; i++) {
        this.fileToUpload = files[i];

        formData.append('file', this.fileToUpload, this.fileToUpload.name);
        formData.append('serialNo', this.uploadId);
        formData.append('commentId', id.toString());
        formData.append('size', this.fileToUpload.size.toString());
        formData.append('eod', 'eod');

        size += this.fileToUpload.size;
      }

      this.upload.postFiles(formData).subscribe(data => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              this.uploadStatusShow({ status: ProgressStatusEnum.IN_PROGRESS, percentage: Math.round((data.loaded / data.total) * 100) });
              break;
            case HttpEventType.Response:
              this.fileInputRef.nativeElement.value = '';
              this.uploadStatusShow({ status: ProgressStatusEnum.COMPLETE });
              break;
          }
        }

        this.loading = false;
        this.refresh();
        resolve(data);
      });

    });
    return promise;
  }

  public uploadStatusShow(event: ProgressStatus) {
    switch (event.status) {
      case ProgressStatusEnum.START:
        this.showUploadError = false;
        break;
      case ProgressStatusEnum.IN_PROGRESS:
        this.showProgress = true;
        this.percentage = event.percentage;
        break;
      case ProgressStatusEnum.COMPLETE:
        this.showProgress = false;
        break;
      case ProgressStatusEnum.ERROR:
        this.showProgress = false;
        this.showUploadError = true;
        break;
    }
  }

}

class Attachment {
  id!: string;
  fileName!: string;
  fileSize!: number;
  createdon!: Date;
  SizeKb?: string;
  fileExtension!: string;
  fileIcon!: string;
}
