<div
  class="pt-2 mt-3 pl-2 sectionx"
  id="section"
  *ngIf="!loading && Signatures?.length"
>
  <div class="row no-gutters">
    <div
      *ngIf="sectionName"
      class="col-1 border-bottom d-flex flex-column justify-content-end pt-2"
    >
      <div class="small d-flex flex-row justify-content-between">
        <div><p *ngIf="!disabled">Reorder</p></div>
        <div><p>Include</p></div>
      </div>
    </div>
    <div class="col-10 pl-4 pb-2">
      <h5 id="Section1Title">{{ sectionName }}</h5>
    </div>
    <div
      *ngIf="sectionName"
      class="col-1 border-bottom d-flex flex-column justify-content-end pt-2"
    >
      <div
        *ngIf="!disabled"
        class="small pr-2 d-flex flex-row justify-content-between"
      >
        <div><p>Edit</p></div>
        <div><p>Delete</p></div>
      </div>
    </div>
  </div>

  <div cdkDropList (cdkDropListDropped)="reorderTable($event)">
    <div *ngFor="let s of Signatures">
      <div cdkDrag class="row no-gutters">
        <div
          class="col-1 p-1 border-bottom d-flex flex-column justify-content-center"
        >
          <div
            class="w-100 d-flex justify-content-between flex-row align-items-center"
          >
            <mat-icon
              [class.no-display]="disabled"
              cdkDragHandle
              color="accent"
              style="cursor: move"
              >reorder</mat-icon
            >
            <mat-checkbox
              [(ngModel)]="s.required"
              color="primary"
              (change)="changeRequired($event, s.taskId)"
              [disabled]="disabled ?? false"
            ></mat-checkbox>
          </div>
        </div>
        <div class="col-10 p-1" *ngIf="!editing || s.id != editingId">
          <master-control
            [type]="s.type"
            [visible]="true"
            [builder]="true"
            [disabled]="true"
            [color]="'primary'"
            [number]="s.number"
            [header]="
              s.required ? (s.number ? s.number.toString() : s.name) : null
            "
            [label]="s.name"
            [text]="s.question"
            [question]="s.question"
            [radioQuestion]="s.question2"
            [options]="s.options"
            [radioOptions]="s.options"
            [radioSelection]="s.value2"
            [checkbox_checked]="s.approved"
            [approved]="s.approved"
            [approvedBy]="s.approvedBy"
            [approvedOn]="s.approvedOn"
            [roleIds]=""
            [roleCodes]="s.roleCodes"
            [scheduleResourceId]="s.scheduleResourceId"
            [scheduleTypeId]="s.scheduleTypeId"
            [locationName]="s.locationName"
            [textBefore]="
              s.type == 6 || s.type == 8 || s.type == 9
                ? s.question2
                : s.question
            "
            [textAfter]="
              s.type == 6 || s.type == 8 || s.type == 9
                ? s.question
                : s.question2
            "
            [textAfter2]="s.question3"
            [textValue]="s.textValue"
            [checkboxVisible]="s.visible"
            [disableEdit]="true"
            [dateValue1]="s.dateValue1"
            [dateValue2]="s.dateValue2"
            [dateValue3]="s.dateValue3"
            [moreInfo]="s.moreInfo"
            [configuration]="s.configuration"
            style="width: 100%"
          >
          </master-control>
        </div>

        <div
          class="col-1 p-1 border-bottom d-flex justify-content-between align-items-center"
        >
          <div
            class="indicator d-flex flex-column justify-content-between"
            *ngIf="s.code || s.condition; else empty"
          >
            <div>
              <mat-icon
                class="small-icon"
                color="accent"
                *ngIf="s.code"
                [matTooltip]="s.code"
                >local_offer</mat-icon
              >
            </div>
            <div>
              <mat-icon
                class="pink small-icon"
                *ngIf="s.condition"
                [matTooltip]="s.condition"
                >device_hub</mat-icon
              >
            </div>
          </div>
          <ng-template #empty><span class="empty">&nbsp;</span></ng-template>
          <div class="options">
            <div *ngIf="!s.iterable">
              <mat-icon
                color="accent"
                class="edit"
                (click)="edit(s.id)"
                *ngIf="!disabled"
                >edit</mat-icon
              >
              <mat-icon
                color="primary"
                class="edit"
                (click)="edit(s.id)"
                *ngIf="disabled"
                >visibility</mat-icon
              >
            </div>
            <div class="pt-1 text-center" *ngIf="!s.iterable">
              <mat-icon
                color="warn"
                class="delete"
                (click)="delete(s.id)"
                *ngIf="!disabled"
                >delete</mat-icon
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row no-gutters" *ngIf="sectionName && !disabled">
    <div class="col-1"></div>
    <div class="col-11 pl-1">
      <button
        mat-flat-button
        class="btn"
        color="primary"
        *ngIf="!adding"
        (click)="edit()"
        matTooltip="Add Task"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
