import { Component, Output, EventEmitter, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { ResourceBeamlineStaffCatalog } from '../beamline-staff';

@Component({
  selector: 'beamline-staff-side-panel',
  templateUrl: './beamline-staff-side-panel.component.html',
  styleUrls: ['./beamline-staff-side-panel.component.scss']
})

export class BeamlineStaffSidePanelComponent implements OnInit {

  @Input() beamlines?: ResourceBeamlineStaffCatalog[] = [];
  @Input() filter?: string;

  @Output() beamlineSelected = new EventEmitter<ResourceBeamlineStaffCatalog>();

  loading: boolean = false;

  public beamLineListColumns: string[] = ['beamlineName', 'shutterName'];

  dataSource = new MatTableDataSource<ResourceBeamlineStaffCatalog>();

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = new MatTableDataSource(this.beamlines?.filter(b =>
      this.filter ? ((b.beamlineName as string)?.toLowerCase()?.includes(this.filter?.toLowerCase()) || (b.shutterName as string)?.toLowerCase()?.includes(this.filter?.toLowerCase())) : true));
  }

  selectBeamline(row: ResourceBeamlineStaffCatalog) {
    this.beamlineSelected.emit(row);
  }

}
