import { ProcedureType } from "../../models/procedure-type";

export class ProcedureTypeRefresh {
  static readonly type = '[ProcedureType] Refresh';
}
export class ProcedureTypeCreate {
  static readonly type = '[ProcedureType] Create';
  constructor(public item: ProcedureType) { }
}
export class ProcedureTypeUpdate {
  static readonly type = '[ProcedureType] Update';
  constructor(public id: number, public item: ProcedureType) { }
}
export class ProcedureTypeDelete {
  static readonly type = '[ProcedureType] Delete';
  constructor(public id: number) { }
}
