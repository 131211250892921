<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h4 mat-dialog-title>Add New Note</h4>
      <div mat-dialog-content>
        <div class="col-12">
          <div class="row">
            <mat-form-field>
              <mat-label>Note</mat-label>
              <textarea
                matInput
                [(ngModel)]="noteName"
                rows="5"
                placeholder="Note"
                [ngModelOptions]="{ standalone: true }"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <div class="col-12">
          <div class="row">
            <div class="col-md-6">
              <button mat-flat-button color="warn" (click)="cancel()">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <button
                mat-flat-button
                color="primary"
                [disabled]="isSaving"
                (click)="add()"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
