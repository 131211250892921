import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RadiationAreaItem } from './radiation-area-item';

@Injectable({
  providedIn: 'root'
})
export class RadiationAreaService {

  public apiRadiationArea: string = environment.urls.baseApiUrl + '/RadiationAreaItem/';

  selectedItem: RadiationAreaItem | null = null;

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<RadiationAreaItem[]> {
    return this.http.get<RadiationAreaItem[]>(this.apiRadiationArea + 'GetAll');
  }
  getAllActives(): Observable<RadiationAreaItem[]> {
    return this.http.get<RadiationAreaItem[]>(this.apiRadiationArea + 'GetActives');
  }
  getAllInactives(): Observable<RadiationAreaItem[]> {
    return this.http.get<RadiationAreaItem[]>(this.apiRadiationArea + 'GetInactives');
  }
  insertRAI(item: RadiationAreaItem): Observable<RadiationAreaItem> {
    return this.http.post<RadiationAreaItem>(this.apiRadiationArea, item);
  }
  updateRAI(item: RadiationAreaItem) {
    return this.http.put<RadiationAreaItem>(this.apiRadiationArea + item.id, item);
  }
  deleteRAI(id: number) {
    return this.http.delete<RadiationAreaItem>(this.apiRadiationArea + id.toString());
  }
}
