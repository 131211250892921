import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, Injector } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Checklist } from 'src/app/components/checklists/checklists';
import { Satisfaction, Review, ReviewSatisfactionCheck } from 'src/app/components/reviews/reviews.models';
import { ReviewsService } from 'src/app/components/reviews/reviews.service';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { ReviewSatisfactionChecksCreate, ReviewSatisfactionChecksUpdate } from 'src/app/components/reviews/store/review-satisfaction-checks/review-satisfaction-checks.action';

@Component({
  selector: 'checklist-task-review-satisfaction-check',
  templateUrl: './checklist-task-review-satisfaction-check.component.html',
  styleUrls: ['./checklist-task-review-satisfaction-check.component.scss']
})
export class ChecklistTaskReviewSatisfactionCheckComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() task?: WfTaskLocalResource;
  @Input() checklist?: Checklist | null;
  @Input() precheck?: boolean;

  satisfaction = Satisfaction;

  review?: Review;
  reviews!: Review[];
  reviews$!: Observable<Review[]>;
  reviewsSubs!: Subscription;

  satisfactionChecks!: ReviewSatisfactionCheck[];
  satisfactionChecks$!: Observable<ReviewSatisfactionCheck[]>;
  satisfactionChecksSubs!: Subscription;

  privilege = PrivilegeEnum;

  constructor(
    protected override injector: Injector,
    private service: ReviewsService,
    private reviewsService: ReviewsService
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.satisfactionChecksSubs?.unsubscribe();
    this.reviewsSubs?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    this.loadSatisfactionChecks();
    this.loadReviews();
  }

  loadSatisfactionChecks() {
    this.satisfactionChecks$ = this.store.select(state => state.ReviewSatisfactionChecks.data);
    this.satisfactionChecksSubs = this.satisfactionChecks$.subscribe(data => {
      this.satisfactionChecks = data?.filter(x => x.wfTaskLocalID == this.task?.id);
      if (!this.satisfactionChecks || this.satisfactionChecks.length == 0) {
        const reviewsatisfactionCheck = {
          id: 0,
          type: 1,
          satisfaction: Satisfaction.Indeterminate,
          preCheck: Satisfaction.Indeterminate,
          checklistID: this.checklist?.id,
          wfTaskLocalID: this.task?.id,
        } as ReviewSatisfactionCheck;
        this.satisfactionChecks = [];
        this.satisfactionChecks.push(reviewsatisfactionCheck);
      }
    });
  }

  loadReviews() {
    this.reviews$ = this.store.select(state => state.Reviews.data);
    this.reviewsSubs = this.reviews$.subscribe(data => {
      this.reviews = data;
      this.review = this.reviews.find(x => x.checklistID == this.checklist?.id);
    });
  }

  checkPrivilege(privilege: PrivilegeEnum) {
    return this.reviewsService.checkPrivilege(privilege, this.review?.reviewType?.reviewBoardType, this.review?.reviewStatus);
  }

  preCheckMilestone(satisfactionCheck: ReviewSatisfactionCheck) {
    if (satisfactionCheck.preCheck == Satisfaction.Indeterminate || satisfactionCheck.preCheckByID == this.currentUser?.id) {
      switch (satisfactionCheck.preCheck) {
        case Satisfaction.Indeterminate: {
          satisfactionCheck.preCheck = Satisfaction.Satisfied;
          satisfactionCheck.preCheckByID = this.currentUser?.id;
          satisfactionCheck.preCheckOn = new Date();
          break;
        }
        case Satisfaction.Satisfied: {
          satisfactionCheck.preCheck = Satisfaction.Indeterminate;
          satisfactionCheck.preCheckByID = null;
          satisfactionCheck.preCheckOn = null;
          break;
        }
      }
      this.save(satisfactionCheck);
    }
  }

  satCheckMilestone(satisfactionCheck: ReviewSatisfactionCheck) {
    if (satisfactionCheck.satisfaction == Satisfaction.Indeterminate || satisfactionCheck.satisfactionCheckByID == this.currentUser?.id) {
      switch (satisfactionCheck.satisfaction) {
        case Satisfaction.Unsatisfied: {
          satisfactionCheck.satisfaction = Satisfaction.Indeterminate;
          satisfactionCheck.satisfactionCheckBy = null;
          satisfactionCheck.satisfactionCheckOn = null;
          break;
        }
        case Satisfaction.Indeterminate: {
          satisfactionCheck.satisfaction = Satisfaction.Satisfied;
          satisfactionCheck.satisfactionCheckByID = this.currentUser?.id;
          satisfactionCheck.satisfactionCheckOn = new Date();
          break;
        }
        case Satisfaction.Satisfied: {
          satisfactionCheck.satisfaction = Satisfaction.Unsatisfied;
          satisfactionCheck.satisfactionCheckByID = this.currentUser?.id;
          satisfactionCheck.satisfactionCheckOn = new Date();
          break;
        }
      }
      this.save(satisfactionCheck);
    }
  }

  save(satisfactionCheck: ReviewSatisfactionCheck) {
    if (satisfactionCheck.id == 0) {
      this.service.createReviewSatisfactionCheck(satisfactionCheck).toPromise().then(data => {
        if (data)
          this.store.dispatch(new ReviewSatisfactionChecksCreate(data));
      });
    }
    else {
      this.service.updateReviewSatisfactionCheck(satisfactionCheck).toPromise().then(data => {
        if (data)
          this.store.dispatch(new ReviewSatisfactionChecksUpdate(data.id, data));
      });
    }
  }

  textChanged(satisfactionCheck: ReviewSatisfactionCheck, text: string) {
    satisfactionCheck.comments = text;
    this.save(satisfactionCheck);
  }

  // formatDate(date: Date) {
  //   return (moment(date)).format('MMM DD, YYYY');
  // }

}
