<div class="card p-2 d-flex flex-row justify-content-between">
  <mat-label class="roles-label mt-2" *ngIf="!roleOptions?.length"
    >Roles</mat-label
  >
  <div class="d-flex">
    <div class="w-250 mr-2" *ngFor="let o of roleOptions">
      <div>
        <cl-editor-checkbox-roles-selection
          [disabled]="disabled"
          [roleOption]="o"
          (roleOptionChanged)="roleOptionsChanged($event)"
        ></cl-editor-checkbox-roles-selection>
        <div *ngIf="!disabled" class="btnx" (click)="del(o)">
          <mat-icon color="warn">delete</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <button mat-stroked-button (click)="add()" *ngIf="!disabled">
    <mat-icon color="accent">add_box</mat-icon>&nbsp;Add Role Checkbox
  </button>
</div>
