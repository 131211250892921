import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Alert } from './alert.action';
import { AlertService } from '../alert.service';

export class AlertStateModel {
}

@State<AlertStateModel>({
  name: 'Alert'
})
@Injectable()
export class AlertState {
  constructor(
    private service: AlertService
  ) { }

  @Selector()
  static read() {
  }

  @Action(Alert)
  create({ getState, patchState }: StateContext<AlertStateModel>, { item }: Alert) {
    this.service.message(item);
  }

}
