import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ChecklistTemplate } from '../../checklists';
import { WFSectionLocalResource, WFStepLocalResource, WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { utils } from 'src/app/modules/libs/utils';
import { ChecklistBuilderService } from '../checklist-builder.service';
import { distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChecklistTemplatesUpdate } from '../../store/checklist-templates/checklist-templates.action';
import { SelectionModel } from '@angular/cdk/collections';
@Component({
  selector: 'checklist-builder-map',
  templateUrl: './checklist-builder-map.component.html',
  styleUrls: ['./checklist-builder-map.component.scss']
})
export class ChecklistBuilderMapComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() showDeleted?: boolean;
  @Output() loading = new EventEmitter<boolean>();

  checklistTemplate?: ChecklistTemplate | null;

  wfSectionLocals?: WFSectionLocalResource[];

  checklistTemplates?: ChecklistTemplate[];
  checklistTemplatesFiltered?: ChecklistTemplate[];
  checklistTemplates$!: Observable<ChecklistTemplate[]>;
  checklistTemplatesSubs!: Subscription;

  deletedTaskLocals?: WfTaskLocalResource[];
  deletedTaskLocals$!: Observable<WfTaskLocalResource[]>;
  deletedTaskLocalsSubs!: Subscription;

  deletedStepLocals?: WFStepLocalResource[];
  deletedStepLocals$!: Observable<WFStepLocalResource[]>;
  deletedStepLocalsSubs!: Subscription;

  deletedSectionLocals?: WFSectionLocalResource[];
  deletedSectionLocals$!: Observable<WFSectionLocalResource[]>;
  deletedSectionLocalsSubs!: Subscription;

  // To store expanded node IDs
  expandedNodeSet = new Set<number>();
  // expansionModel = new SelectionModel<string>(true);

  private _transformer = (node: WFNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      type: node.type,
      id: node.id,
      deleted: node.deleted,
      children: node.children,
      deletedChildren: node.deletedChildren
    } as WFFlatNode;
  };

  treeControl = new FlatTreeControl<WFFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: WFFlatNode) => node.expandable;

  constructor(
    protected override injector: Injector,
    public service: ChecklistBuilderService
  ) { super(injector); }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    this.loadChecklistTemplates();
    this.service.currentTemplate?.subscribe(data => {
      this.checklistTemplate = data;
      this.loadDeletedSections();
      this.loadDeletedSteps();
      this.loadDeletedTasks();
      this.buildTree();
    });
  }

  loadChecklistTemplates() {
    this.checklistTemplates$ = this.store.select(state => state.ChecklistTemplates.data);
    this.checklistTemplatesSubs = this.checklistTemplates$.pipe(distinctUntilChanged()).subscribe(data => {
      if (data?.length) {
        this.checklistTemplates = data;
        if (this.checklistTemplate) {
          const checklistTemplate = this.checklistTemplates.find(x => x.id == this.checklistTemplate?.id);
          if (checklistTemplate) {
            this.checklistTemplate = checklistTemplate;
            this.buildTree();
          }
        }
      }
    });
  }

  // Save expanded node state before refresh
  saveExpandedState() {
    this.expandedNodeSet.clear();
    this.treeControl.dataNodes?.forEach(node => {
      if (this.treeControl.isExpanded(node)) {
        this.expandedNodeSet.add(node.id); // Assuming each node has a unique 'id'
      }
    });
  }

  // Restore expanded node state after refresh
  restoreExpandedState() {
    this.treeControl.dataNodes?.forEach(node => {
      if (this.expandedNodeSet.has(node.id)) {
        this.treeControl.expand(node);
      }
    });
  }

  loadDeletedSections() {
    this.deletedSectionLocals$ = this.store.select((state) => state.DeletedWFSectionLocals.data);
    this.deletedSectionLocalsSubs = this.deletedSectionLocals$.pipe(distinctUntilChanged()).subscribe((data) => {
      this.deletedSectionLocals = data.filter(t => t.checklistTemplateID == this.checklistTemplate?.id);
      this.buildTree();
    });
  }

  loadDeletedSteps() {
    this.deletedStepLocals$ = this.store.select((state) => state.DeletedWFStepLocals.data);
    this.deletedStepLocalsSubs = this.deletedStepLocals$.pipe(distinctUntilChanged()).subscribe((data) => {
      this.deletedStepLocals = data.filter(t => t.checklistTemplateID == this.checklistTemplate?.id);
      this.buildTree();
    });
  }

  loadDeletedTasks() {
    this.deletedTaskLocals$ = this.store.select((state) => state.DeletedWFTaskLocals.data);
    this.deletedTaskLocalsSubs = this.deletedTaskLocals$.pipe(distinctUntilChanged()).subscribe((data) => {
      this.deletedTaskLocals = data.filter(t => t.checklistTemplateID == this.checklistTemplate?.id);
      this.buildTree();
    });
  }

  buildTree() {
    this.saveExpandedState(); // Save expanded nodes before refresh

    const sectionNodes: WFNode[] = [];
    this.wfSectionLocals = this.checklistTemplate?.wfTable?.wfTableLocal?.wfSectionLocals.sort((a, b) => utils.sort(a.order, b.order));
    if (this.showDeleted)
      this.wfSectionLocals = this.wfSectionLocals?.concat(this.deletedSectionLocals?.filter(d => !this.wfSectionLocals?.map(i => i.id).includes(d.id)) ?? []).sort((a, b) => utils.sort(a.order, b.order));
    this.wfSectionLocals?.map(s => {
      const stepNodes: WFNode[] = [];
      let steps = s.wfStepLocals?.sort((a, b) => utils.sort(a.order, b.order));
      if (this.showDeleted)
        steps = steps?.concat(this.deletedStepLocals?.filter(d => d.wfSectionLocalID == s.id && !steps?.map(i => i.id).includes(d.id)) ?? []).sort((a, b) => utils.sort(a.order, b.order));
      steps?.map(x => {
        const taskNodes: WFNode[] = [];
        let tasks = x.wfTaskLocals.sort((a, b) => utils.sort(a.order, b.order));
        if (this.showDeleted)
          tasks = tasks.concat(this.deletedTaskLocals?.filter(d => d.wfStepLocalID == x.id && !tasks.map(i => i.id).includes(d.id)) ?? []).sort((a, b) => utils.sort(a.order, b.order));
        tasks.map(t => {
          const taskNode: WFNode = { number: t.component?.numericHeader ? t.name : '', name: this.formatTaskName(t), type: ChecklistElement.TASK, id: t.id ?? 0, deleted: (t.logID ?? 0) > 0, deletedChildren: false };
          taskNodes.push(taskNode);
        });
        const stepNode: WFNode = { name: x.name, children: taskNodes, type: ChecklistElement.STEP, id: x.id, deleted: (x.logID ?? 0) > 0, deletedChildren: taskNodes.some(n => n.deleted) };
        stepNodes.push(stepNode);
      });
      const sectionNode: WFNode = { name: s.number + '. ' + s.name, children: stepNodes, type: ChecklistElement.SECTION, id: s.id, deleted: (s.logID ?? 0) > 0, deletedChildren: stepNodes.some(n => n.deleted) || stepNodes.some(n => n.deletedChildren) };
      sectionNodes.push(sectionNode);
    });
    this.dataSource.data = sectionNodes;
    this.restoreExpandedState(); // Restore expanded nodes after refresh
  }

  hasDeleted() {
    const sectionNodes: WFNode[] = [];
    const sections = this.wfSectionLocals?.concat(this.deletedSectionLocals?.filter(d => !this.wfSectionLocals?.map(i => i.id).includes(d.id)) ?? []);
    sections?.map(s => {
      const stepNodes: WFNode[] = [];
      let steps = s.wfStepLocals;
      steps = steps?.concat(this.deletedStepLocals?.filter(d => d.wfSectionLocalID == s.id && !steps?.map(i => i.id).includes(d.id)) ?? []);
      steps?.map(x => {
        const taskNodes: WFNode[] = [];
        let tasks = x.wfTaskLocals;
        tasks = tasks.concat(this.deletedTaskLocals?.filter(d => d.wfStepLocalID == x.id && !tasks.map(i => i.id).includes(d.id)) ?? []);
        tasks.map(t => {
          const taskNode: WFNode = { number: t.component?.numericHeader ? t.name : '', name: t.name, type: ChecklistElement.TASK, id: t.id ?? 0, deleted: (t.logID ?? 0) > 0, deletedChildren: false };
          taskNodes.push(taskNode);
        });
        const stepNode: WFNode = { name: x.name, children: taskNodes, type: ChecklistElement.STEP, id: x.id, deleted: (x.logID ?? 0) > 0, deletedChildren: taskNodes.some(n => n.deleted) };
        stepNodes.push(stepNode);
      });
      const sectionNode: WFNode = { name: s.number + '. ' + s.name, children: stepNodes, type: ChecklistElement.SECTION, id: s.id, deleted: (s.logID ?? 0) > 0, deletedChildren: stepNodes.some(n => n.deleted) || stepNodes.some(n => n.deletedChildren) };
      sectionNodes.push(sectionNode);
    });
    return sectionNodes.some(n => n.deleted || n.deletedChildren);
  }

  formatTaskName(t: WfTaskLocalResource) {
    if (t.component?.textFields)
      return (t.component?.numericHeader ? t.name + ' - ' : '') + utils.stripHTML(t.name3 ?? t.name2 ?? t.name);
    else return t.name;
  }

  scrollToNode(node: WFFlatNode) {
    const selectedElement: SelectedElement = {};

    const setSelectedNodes = (section: WFNode, step?: WFNode, task?: WFNode) => {
      selectedElement.sectionNode = this._transformer(section, 0);
      if (step) selectedElement.stepNode = this._transformer(step, 1);
      if (task) selectedElement.taskNode = this._transformer(task, 2);
    };

    switch (node.type) {
      case ChecklistElement.TASK:
        this.dataSource.data.some(section => {
          return section.children?.some(step => {
            return step.children?.some(task => {
              if (task.id === node.id) {
                setSelectedNodes(section, step, task);
                return true; // Found the task
              }
              return false; // Continue searching
            });
          });
        });
        break;

      case ChecklistElement.STEP:
        this.dataSource.data.some(section => {
          return section.children?.some(step => {
            if (step.id === node.id) {
              setSelectedNodes(section, step);
              return true; // Found the step
            }
            return false; // Continue searching
          });
        });
        break;

      case ChecklistElement.SECTION:
        const section = this.dataSource.data.find(section => section.id === node.id);
        if (section) setSelectedNodes(section);
        break;
    }

    this.service.selectedNode = selectedElement;
    const element = document.getElementById(node.type + '-' + node.id);
    if (element) {
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onNodeToggle(node: WFFlatNode) {
    const isExpanded = this.treeControl.isExpanded(node);
    this.saveExpandedState();
    console.log(`${node.name} is now ${isExpanded ? 'expanded' : 'collapsed'}`);
  }

  nodeParent(node: WFNode) {
    let result: WFNode | null | undefined = null;
    result = this.dataSource.data.find((x) => x.children?.some((c) => c.id === node.id));
    if (!result) {         // Parent is at level 1
      for (const section of this.dataSource.data) {
        result = section.children?.find((x) => x.children?.some((c) => c.id === node.id));
        if (result) break;
      }
    }
    return result;
  }

  nodeSiblings(node: WFNode, data: WFNode[], parent?: WFNode): WFNode[] | null | undefined {
    let result: WFNode[] | null | undefined = null;
    const section = data.find((x) => x.id === node.id);
    if (section) result = data;
    else {
      data.forEach((section) => {
        const step = section.children?.find((x) => x.id === node.id && section.id === parent?.id);
        if (step) result = section.children;
        else {
          section.children?.forEach((step) => {
            const task = step.children?.find((x) => x.id === node.id && step.id === parent?.id);
            if (task) result = step.children;
          });
        }
      });
    }
    return result;
  }

  saveDropChanges(nodeChanges: Change[]) {
    const type = nodeChanges[0].type;
    const changes = nodeChanges.map(x => this.utils.Serialize(x.element));
    switch (type) {
      case ChecklistElement.SECTION:
        this.service.updateSectionsOrder(changes as WFSectionLocalResource[]).toPromise();
        break;

      case ChecklistElement.STEP:
        this.service.updateStepsOrder(changes as WFStepLocalResource[]).toPromise();
        break;

      case ChecklistElement.TASK:
        this.service.updateTasksOrder(changes as WfTaskLocalResource[]).toPromise();
        break;
    }

  }

  getConnectedDropLists(node: WFNode): string[] {
    if (node.level === 0) return [];
    const dropLists = [] as string[];
    const parent = this.nodeParent(node)!;
    const parentParent = this.nodeParent(parent);
    const items = parentParent ? parentParent.children : this.dataSource.data;
    items?.forEach((child) => {
      dropLists.push(`${parent.type}-${child.id}`);
    });
    return dropLists;
  }

  /**
  * This constructs an array of nodes that matches the DOM
  */
  visibleNodes(): WFNode[] {
    const result: WFNode[] = [];

    this.dataSource.data.forEach((section) => {
      section.level = 0;
      result.push(section);
      if (this.expandedNodeSet.has(section.id)) {
        section.children?.forEach((step) => {
          step.level = 1;
          result.push(step);
          if (this.expandedNodeSet.has(step.id)) {
            step.children?.forEach((task) => {
              task.level = 2;
              result.push(task);
            });
          }
        });
      }
    });

    return result;
  }

  /**
  * Handle the drop - here we rearrange the data based on the drop event,
  * then rebuild the tree.
  * */
  drop(event: CdkDragDrop<string[]>) {
    // ignore drops outside of the tree
    if (!event.isPointerOverContainer) return;
    this.loading.emit(true);
    // construct a list of visible nodes, this will match the DOM.
    // the cdkDragDrop event.currentIndex jives with visible nodes.
    // it calls rememberExpandedTreeNodes to persist expand state
    const visibleNodes = this.visibleNodes();

    // deep clone the data source so we can mutate it
    const changedData = this.utils.cloneDeep(this.dataSource.data);

    const node = event.item.data;

    // determine where to insert the node
    let nodeAtDest = visibleNodes[event.currentIndex];

    // ensure validity of drop - must be same level
    const nodeLevel = this.nodeLevel(node.id);
    const destLevel = this.nodeLevel(nodeAtDest.id);

    let newParent = this.nodeParent(nodeAtDest);
    const oldParent = this.nodeParent(node);

    let error = false;
    if (nodeLevel > destLevel) {
      if (nodeLevel - destLevel == 1 && nodeAtDest.children) {
        if (nodeAtDest.children.length > 0)
          nodeAtDest = nodeAtDest.children![0];
        else if (nodeAtDest.level == 0 || nodeAtDest.level == 1) {
          nodeAtDest.children.push(utils.cloneDeep(node));
          newParent = nodeAtDest;
          nodeAtDest = nodeAtDest.children[0];
        }
        else error = true;
      }
      else error = true;
    }
    else if (nodeLevel < destLevel) {
      error = true;
    }

    if (error) {
      this.alert.warning('Items can only be moved within the same level.');
      this.loading.emit(false);
      return;
    }

    // check new siblings
    const newSiblings = this.nodeSiblings(nodeAtDest, changedData, newParent) ?? (node.level == 0 ? this.dataSource.data : []);
    if (!newSiblings) return;
    const insertIndex = newSiblings.findIndex(s => s.id === nodeAtDest.id);


    // remove the node from its old place
    const siblings = this.nodeSiblings(node, changedData, oldParent) ?? (node.level == 0 ? this.dataSource.data : []);
    const siblingIndex = siblings.findIndex(n => n.id === node.id);
    const nodeToInsert: WFNode = siblings.splice(siblingIndex, 1)[0];
    // if (nodeAtDest.id != nodeToInsert.id) {

    // insert node
    newSiblings.splice(insertIndex, 0, nodeToInsert);

    // Save Changes and Refresh Checklist Template
    if (this.checklistTemplate) {
      const siblingsChanges = this.getChangedData(node, newSiblings, newParent);
      const previousSiblingsChanges = this.getChangedData(node, siblings, oldParent);

      this.saveDropChanges(siblingsChanges.concat(previousSiblingsChanges));
      this.store.dispatch(new ChecklistTemplatesUpdate(this.checklistTemplate.id, this.checklistTemplate));
    }
    // }
    this.loading.emit(false);
  }

  nodeLevel(id: number) {
    return this.treeControl.dataNodes.find((n) => id === n.id)?.level ?? -1;
  }

  getChangedData(node: WFNode, data: WFNode[], parent?: WFNode | null): Change[] {
    const nodeChanges: Change[] = [];
    switch (node.type) {
      case ChecklistElement.SECTION:
        data.forEach((section, index) => {
          const wfSection = this.findWFSection(section.id);
          if (wfSection) {
            if (index != wfSection.order) {
              wfSection.order = index;
              wfSection.number = index + 1;
              nodeChanges.push({ type: ChecklistElement.SECTION, element: wfSection });
            }
          }
        });
        break;
      case ChecklistElement.STEP:
        const parentSection = parent!;
        const parentWFSection = this.findWFSection(parentSection.id)!;
        data.forEach((step, index) => {
          const wfStep = this.checklistTemplate?.wfTable?.wfTableLocal?.wfSectionLocals
            .flatMap(section => section.wfStepLocals || [])
            .find(s => s.id === step.id);
          if (wfStep) {
            if (index != wfStep.order || wfStep.wfSectionLocalID != parentSection.id) {
              wfStep.order = index;

              if (wfStep.wfSectionLocalID != parentSection.id) {
                wfStep.wfSectionLocalID = parentSection.id;
                wfStep.wfSectionLocal = parentWFSection;
                parentWFSection.wfStepLocals?.splice(index, 0, wfStep);
              }

              nodeChanges.push({ type: ChecklistElement.STEP, element: wfStep });
            }
          }
        });
        parentWFSection.wfStepLocals?.forEach((step) => {
          if (!data.some(s => s.id == step.id))
            parentWFSection.wfStepLocals?.splice(parentWFSection.wfStepLocals.indexOf(step), 1);
        });
        break;
      case ChecklistElement.TASK:
        const parentStep = parent!;
        const parentWFStep = this.findWFStep(parentStep.id);
        data.forEach((task, index) => {
          const wfTask = this.checklistTemplate?.wfTable?.wfTableLocal?.wfSectionLocals
            .flatMap(x => x.wfStepLocals || [])
            .flatMap(s => s.wfTaskLocals || [])
            .find(t => t.id === task.id);
          if (wfTask) {
            if (index != wfTask.order || wfTask.wfStepLocalID != parentStep.id) {
              wfTask.order = index;
              if (wfTask.wfStepLocalID != parentStep.id) {
                wfTask.wfStepLocalID = parentStep.id;
                wfTask.wfStepLocal = parentWFStep;
                parentWFStep?.wfTaskLocals?.splice(index, 0, wfTask);
              }
              nodeChanges.push({ type: ChecklistElement.TASK, element: wfTask });
            }
          }
        });
        parentWFStep?.wfTaskLocals?.forEach((task) => {
          if (!data.some(t => t.id == task.id))
            parentWFStep?.wfTaskLocals?.splice(parentWFStep?.wfTaskLocals?.indexOf(task), 1);
        });
        break;
    }

    return nodeChanges;
  }

  findWFSection(sectionID: number): WFSectionLocalResource | undefined {
    return this.checklistTemplate?.wfTable?.wfTableLocal?.wfSectionLocals.find(x => x.id == sectionID);
  }

  findWFStep(stepID: number): WFStepLocalResource | undefined {
    return this.checklistTemplate?.wfTable?.wfTableLocal?.wfSectionLocals.flatMap(x => x.wfStepLocals || []).find(x => x.id == stepID);
  }

  findWFTask(taskID: number): WfTaskLocalResource | undefined {
    return this.checklistTemplate?.wfTable?.wfTableLocal?.wfSectionLocals.flatMap(x => x.wfStepLocals || []).flatMap(s => s.wfTaskLocals || []).find(t => t.id == taskID);
  }

  dragging = false;
  expandTimeout: any;
  expandDelay = 1000;
  /**
   * Experimental - opening tree nodes as you drag over them
   */
  dragStart() {
    this.dragging = true;
  }
  dragEnd() {
    this.dragging = false;
  }
  dragHover(node: WFFlatNode) {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
      this.expandTimeout = setTimeout(() => {
        this.treeControl.expand(node);
      }, this.expandDelay);
    }
  }
  dragHoverEnd() {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
    }
  }
}




interface WFNode {
  name: string;
  type: ChecklistElement;
  id: number;
  number?: string;
  children?: WFNode[];
  deleted: boolean;
  level?: number;
  deletedChildren: boolean;
}

export interface WFFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  type: ChecklistElement;
  id: number;
  deleted: boolean;
  deletedChildren?: boolean;
  children?: WFNode[];
}

export enum ChecklistElement {
  SECTION = 'section',
  STEP = 'step',
  TASK = 'task'
}

export interface Change {
  type: ChecklistElement;
  element: WFSectionLocalResource | WFStepLocalResource | WfTaskLocalResource;
}

export class SelectedElement {
  sectionNode?: WFFlatNode;
  stepNode?: WFFlatNode;
  taskNode?: WFFlatNode;
}