import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
// import { SignalRAction } from 'src/app/enums/signalr-enums';
// import { SignalRService } from 'src/app/Services/SignalR/signal-r.service';
// import { GeneralSignalRResponse } from 'src/app/_interface/signal-r-models/GeneralSignalRResponse';
import * as TemplateTypesActions from 'src/app/components/checklists/store/template-types/template-types.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';
// import { Channels } from 'src/app/Store/channels';

@Injectable({
  providedIn: 'root'
})
export class TemplateTypesStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore() {
    this.store.dispatch(new TemplateTypesActions.TemplateTypesRefresh());
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.TEMPLATETYPES, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new TemplateTypesActions.TemplateTypesCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new TemplateTypesActions.TemplateTypesUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new TemplateTypesActions.TemplateTypesDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new TemplateTypesActions.TemplateTypesRefresh());
          break;
      }
    });
  }
}
