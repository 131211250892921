<div class="card">
  <div class="card-header">
    <span class="card-title">Request Extension Message</span>
  </div>
  <div class="card-body">
    <form [formGroup]="statusForm">
      <div class="row">
        <div class="col-12">
          <p>
            <b>Note: This is a global message that applies to all schedules.</b>
          </p>
          <p>The following placeholders are available to use:</p>
          <ul>
            <li><b>[Schedule]</b></li>
            <li><b>[Location]</b></li>
            <li><b>[LastCompleted]</b></li>
            <li><b>[NextInspectionDue]</b></li>
            <li><b>[ScheduledFor]</b></li>
            <!-- <li><b>[ExtendedDate]</b></li> -->
            <li><b>[Status]</b></li>
            <li><b>[SubmittedBy]</b></li>
            <li><b>[Reason]</b></li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Message</mat-label>
            <textarea
              aria-label="message"
              matInput
              formControlName="notificationMessage"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="!statusForm.valid"
      >
        Accept
      </button>
    </div>
  </div>
</div>
