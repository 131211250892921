import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import FileV2 from 'src/app/services/file/file-v2.model';
import { FileV2Service } from 'src/app/services/file/file-v2.service';

@Component({
  selector: 'file-upload-v2',
  templateUrl: './file-upload-v2.component.html',
  styleUrls: ['./file-upload-v2.component.scss']
})
export class FileUploadV2Component implements OnInit, OnChanges {
  @Input() get fileID() { return this.fileIDValue; }
  @Input() get file() { return this.fileValue; }
  @Input() get processingFile() { return this.processingFileValue }
  @Input() path: string = "/";
  @Input() disabled!: boolean;
  @Input() deleteFile: boolean = true;
  @Input() fileName?: string;
  @Input() fileHelp?: boolean;
  @Input() buttonLabel?: string;

  helpPath: string = '/help/';

  @Output() fileIDChange = new EventEmitter<number>();
  @Output() fileChange = new EventEmitter<FileV2>();
  @Output() processingFileChange = new EventEmitter<boolean>();

  fileIDValue?: number | null;
  fileValue?: FileV2 | null;
  processingFileValue!: boolean;

  set fileID(val) {
    this.fileIDValue = val;
    if (this.fileIDValue)
      this.fileIDChange.emit(this.fileIDValue);
  }

  set file(val) {
    this.fileValue = val;
    if (this.fileValue)
      this.fileChange.emit(this.fileValue);
  }

  set processingFile(val) {
    this.processingFileValue = val;
    this.processingFileChange.emit(this.processingFileValue);
  }

  //messages
  label_select_pdf?: string;
  //messages

  constructor(
    private file2Service: FileV2Service,
  ) { }

  ngOnInit(): void {
    this.label_select_pdf = 'Upload PDF';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('fileName' in changes) {
      if (this.fileName != undefined && this.fileName != null) {
        this.fileName = this.fileName;
      }
    }
  }

  delete() {
    if (!this.deleteFile) {
      this.file = null;
      this.fileID = null;
      return;
    }
    this.processingFile = true;
    if (this.fileID)
      this.file2Service.delete(this.fileID).subscribe(
        () => {
          this.file = null;
          this.fileID = null;
        },
        error => console.error(error),
        () => this.processingFile = false
      );
  }

  upload(e: any) {
    const file = e.target?.files?.[0];
    let form = new FormData();
    if (this.fileHelp) {
      this.formatHelpFilePath();
    }

    form.append("file", file);
    form.append("path", this.path + file.name);

    this.processingFile = true;
    this.file2Service.createFiles(form).subscribe(
      (file: FileV2) => {
        this.file = file;
        this.fileID = this.file.id;
      },
      error => {
        console.error(error);
      },
      () => {
        this.processingFile = false;
      }
    );
  }

  formatHelpFilePath() {
    if (this.path?.length > 0) {
      if (this.path[this.path?.length - 1] !== '/')
        this.path = this.path + '/'
    }
    if (!this.path.includes(this.helpPath)) {
      this.path = this.helpPath + this.path;
    }
  }

  getFileName() {
    return this.fileName ?? this.file?.name;
  }

}
