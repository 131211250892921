<div class="tab-container">
	<div #reminderTable class="mat-container scrollbarX style-15 mb-3">
		<mat-table
			[dataSource]="reminderSource"
			style="min-width: 45em;"
			[style.max-height.px]="innerWidth < 992 ? innerHeight * 0.7 : null"
		>
			<ng-container matColumnDef="name">
				<mat-header-cell *matHeaderCellDef> Reminder Name </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
			</ng-container>

			<ng-container matColumnDef="dueDateTime">
				<mat-header-cell *matHeaderCellDef> Day(s) </mat-header-cell>
				<mat-cell *matCellDef="let element">
					{{ element.timeInDays }}
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="period">
				<mat-header-cell *matHeaderCellDef> Period </mat-header-cell>
				<mat-cell *matCellDef="let element">
					{{ Period[element.period - 1] }}
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="message">
				<mat-header-cell *matHeaderCellDef> Message </mat-header-cell>
				<mat-cell *matCellDef="let element">
					<span [innerHTML]="element.message"></span>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="options">
				<mat-header-cell *matHeaderCellDef> Options </mat-header-cell>
				<mat-cell *matCellDef="let element">
					<ng-container *ngIf="validateShowMenu()">
						<button
							mat-icon-button
							[matMenuTriggerFor]="menuRem"
							aria-label="Reminders menu"
							color="primary"
						>
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menuRem="matMenu">
							<button
								mat-menu-item
								*ngIf="
                  sv.validateRole('updateReminder', tokenInfo, scheduleType)
                "
								(click)="editReminder(element)"
							>
								<mat-icon class="yellow">edit</mat-icon>
								<span>Edit</span>
							</button>
							<button
								mat-menu-item
								*ngIf="
                  sv.validateRole('deleteReminder', tokenInfo, scheduleType)
                "
								(click)="removeReminder(element.id)"
							>
								<mat-icon class="red">delete</mat-icon>
								<span>Delete</span>
							</button>
							<button
								mat-menu-item
								*ngIf="
                  sv.validateRole('subscriberReminder', tokenInfo, scheduleType)
                "
								(click)="openChooseUsers(element)"
							>
								<mat-icon class="blue">person</mat-icon>
								<span>Subscribe users</span>
							</button>
							<button
								mat-menu-item
								*ngIf="
                  sv.validateRole('subscriberReminder', tokenInfo, scheduleType)
                "
								(click)="openChooseRoles(element)"
							>
								<mat-icon class="blue">group</mat-icon>
								<span>Subscribe roles</span>
							</button>
						</mat-menu>
					</ng-container>
				</mat-cell>
			</ng-container>

			<mat-header-row
				#header
				[style.width]="innerWidth > 992 ? tableWidth : null"
				[ngClass]="{
          'p-0': true,
          'fixed-head-reminder': headerOnTop2 && innerWidth > 992,
          'sticky-headers': innerWidth < 992
        }"
				*matHeaderRowDef="displayedColumnsReminder"
			>
			</mat-header-row>
			<mat-row
				*matRowDef="let row; columns: displayedColumnsReminder"
			></mat-row>
		</mat-table>
	</div>
</div>
