<div class="pb-3" *ngFor="let reviewCategory of reviewCategories">
  <div class="row">
    <div class="col-12 col-sm-12">
      <mat-label>{{reviewCategory.name}}</mat-label>
    </div>
  </div>
  <div class="mat-container scrollbarX style-15">
    <mat-table [dataSource]="getReviewMembersByCategory(reviewCategory)">

      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          {{element.role?.name}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="member">
        <mat-header-cell *matHeaderCellDef> Member </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          {{element.user?.name}}
        </mat-cell>
      </ng-container>

      <mat-header-row #header class="p-0 headerRow" *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</div>
