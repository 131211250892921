import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { FormStatusEnum, NotificationType } from 'src/app/common/enumerations/enumerations';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { utils } from 'src/app/modules/libs/utils';
import { WFTaskSignatureResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { SCFMaster, SCFAmendment } from '../../scf';
import { SCFPrivileges, SCFStatus, ScfV2Service } from '../../scf-v2.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { NotificationService } from 'src/app/components/catalogs/notifications/services/notification.service';
import { CancelDialogComponent } from 'src/app/controls/cancel-dialog/cancel-dialog.component';
import { MessageTypeEnum } from 'src/app/components/messages/services/message-banner.enums';
import { NotificationMessage } from 'src/app/components/catalogs/notifications/services/notification.models';


@Component({
  selector: 'scf-v2-main-buttons',
  templateUrl: './scf-v2-main-buttons.component.html',
  styleUrls: ['./scf-v2-main-buttons.component.scss']
})
export class ScfV2MainButtonsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() scfMaster?: SCFMaster | null;
  @Input() disabled? = true;
  @Input() privileges?: SCFPrivileges;
  @Input() status?: SCFStatus;
  @Input() valid?: boolean;

  @Output() changed = new EventEmitter<SCFMaster>();
  @Output() loading = new EventEmitter<boolean>();
  @Output() setSetatus = new EventEmitter<SCFStatus>();
  @Output() saved = new EventEmitter<SCFMaster | null>();

  disableControls = true;

  // isMobileDevice = utils.isMobileDevice();

  scfAmendment?: SCFAmendment;
  scfAmendmentNumber?: number;

  constructor(
    private scfService: ScfV2Service,
    public wfService: WorkFlowService,
    private notificationService: NotificationService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getValues();
    this.setDisabled();
  }

  ngOnInit(): void {

  }

  getValues() {
    if (this.status?.isViewingAmendment) {
      this.scfAmendment = this.scfMaster?.scfAmendments?.find(x => x.active);
      this.scfAmendmentNumber = this.scfMaster?.scfAmendments?.filter(x => x.status != FormStatusEnum.Canceled).length;
    }
  }

  setValues() {

  }

  setDisabled() {
    if (this.disabled) {
      this.disableControls = true;
    }
    else if (this.status?.isCreating || this.status?.isEditing || this.status?.isCreatingAmendment || this.status?.isEditingAmendment) {
      this.disableControls = false;
    }
    else {
      this.disableControls = true;
    }
  }

  async update() {
    if (this.scfMaster)
      return await this.scfService.update(this.scfMaster).toPromise();
    else return null;
  }

  async save(status: FormStatusEnum) {
    this.loading.emit(true);
    const oldStatus = this.scfMaster?.status;
    if (this.scfMaster) {
      if (this.scfMaster.status === FormStatusEnum.Amendment) {
        status = this.scfMaster.status;
      } else {
        this.scfMaster.status = status;
      }
      if (status === FormStatusEnum.WorkApprovals) {
        this.scfMaster.editingById = null;
        this.scfMaster.editingUntil = null;
      }
    }
    const scfMaster = await this.update();
    this.alert.success('Update/Save');
    const wfSignature = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == 2)?.wfSignatures?.find(x => x.code == 'SUBMIT');
    if (wfSignature && status == FormStatusEnum.WorkApprovals) {
      const wfTaskSignature: WFTaskSignatureResource = {
        taskId: wfSignature.taskID,
        actionId: wfSignature.approveID
      };
      await this.sign(wfTaskSignature);
    }
    else if (status == FormStatusEnum.Draft && oldStatus == FormStatusEnum.WorkApprovals) {
      const wfUnsubmitSignature = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == 2)?.wfSignatures?.find(x => x.code == 'RWAPI');
      if (wfUnsubmitSignature?.disapproveID) {
        const wfTaskSignature: WFTaskSignatureResource = {
          taskId: wfUnsubmitSignature.taskID,
          actionId: wfUnsubmitSignature.disapproveID
        };
        await this.sign(wfTaskSignature);
      }
    }
    await this.createNotification();
    this.setFormDirty(false);
    this.loading.emit(false);
  }

  async createNotification() {
    if (this.scfMaster?.id)
      this.notificationService.getNotification(NotificationType.CreateSCF, this.scfMaster?.id, this.scfMaster.locations).toPromise().then(async data => {
        if (!data && this.scfMaster?.id) {
          const notification = {
            notificationId: NotificationType.CreateSCF,
            documentId: this.scfMaster?.id,
            toAddresses: this.utils.JSONstringify({ computed: await this.scfService.getEmails(this.scfMaster), added: [] }),
            type: 5,
            locations: this.scfMaster.locations ?? []
          } as NotificationMessage;
          this.notificationService.createNotification(notification).toPromise();
        }
      });
  }

  async sign(wfTaskSignature: WFTaskSignatureResource) {
    this.loading.emit(true);
    await this.scfService.sign(wfTaskSignature).toPromise().then(() => {
      this.loading.emit(false);
      if (this.scfMaster?.id)
        this.notificationService.getNotification(NotificationType.CreateSCF, this.scfMaster?.id, this.scfMaster.locations).toPromise().then(async notification => {
          this.setFormDirty(false);
          if (this.scfMaster)
            await this.scfService.getEmails(this.scfMaster).then(async scfMails => {
              if (notification != null) {
                const toAddressesAdded = utils.JSONparse(notification.toAddresses)?.added;
                notification.toAddresses = JSON.stringify({ computed: scfMails, added: toAddressesAdded });
                await this.notificationService.saveNotification(notification).toPromise().then(() => { }, () => this.alert.defaultError());
              }
            }).catch(error => {
              this.alert.defaultError();
              console.error(error);
              this.loading.emit(false);
            });
        }).catch(error => {
          this.alert.defaultError();
          console.error(error);
          this.loading.emit(false);
        });
    }).catch(error => {
      this.alert.defaultError();
      console.error(error);
      this.loading.emit(false);
    }).finally(() => {
      this.loading.emit(false);
    });
  }

  isValid() {
    if (!this.status?.isViewingAmendment) {
      return this.valid && this.scfMaster?.scfRestrictionsV2 && this.scfMaster?.scfRestrictionsV2.length !== 0;
    }
    else {
      const scfAmendment = this.scfMaster?.scfAmendments?.find(x => x.active);
      return this.valid && scfAmendment?.scfRestrictionsV2?.length !== 0;
    }
  }

  async submitAmendment() {
    this.loading.emit(true);
    if (this.scfMaster) {
      this.scfMaster.editingById = null;
      this.scfMaster.editingUntil = null;
      const scfMaster = await this.update();
      const wfSignature = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == 5)?.wfSignatures?.find(x => x.code == 'SUBMIT' && x.signedBy == null);
      if (wfSignature) {
        const wfTaskSignature: WFTaskSignatureResource = {
          taskId: wfSignature.taskID,
          actionId: wfSignature.approveID
        };
        this.scfService.sign(wfTaskSignature).toPromise().then(() => {
          this.setFormDirty(false);
          this.alert.message('amendment has been Submitted', [new MessagePlaceholder('{serialNo}', this.scfMaster?.serialNo ?? '')]);
          this.loading.emit(false);
        },
          error => {
            this.alert.defaultError();
            this.loading.emit(false);
          }
        );
      }
    }
  }

  unsubmitAmendment() {
    const wfSignature = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == 6)?.wfSignatures?.find(x => x.code == 'RWAPI' && !x.signed);
    if (wfSignature?.disapproveID) {
      this.loading.emit(true);
      const wfTaskSignature: WFTaskSignatureResource = {
        taskId: wfSignature.taskID,
        actionId: wfSignature.disapproveID,
        reason: 'Unsubmitted by ' + this.getCurrentUser()?.name
      };
      this.scfService.sign(wfTaskSignature).toPromise().then(() => {
        this.alert.message('amendment has been Unsubmitted', [new MessagePlaceholder('{serialNo}', this.scfMaster?.serialNo ?? '')], MessageTypeEnum.Warning);
        this.loading.emit(false);
      },
        error => {
          this.alert.defaultError();
          this.loading.emit(false);
        }
      );
    }
  }

  cancel() {
    const confirm = this.dialog.open(CancelDialogComponent, {
      width: '600px',
      data: {
      }
    });
    confirm.afterClosed().subscribe(data => {
      if (data?.accept && this.scfMaster) {
        this.loading.emit(true);
        this.scfService.cancel(this.scfMaster, data.text).toPromise().then(() => {
          this.setFormDirty(false);
          this.alert.message('CancelSuccess', [new MessagePlaceholder('{reason}', data.text)]);
          this.loading.emit(false);
          this.saved.emit(null);
        });
      }
    });
  }

  cancelAmendment() {
    let cancelTask = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == 5)?.wfSignatures?.find(x => x.cancelEnabled);
    if (!cancelTask) {
      cancelTask = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == 6)?.wfSignatures?.find(x => (x.code == 'RP' || x.code == 'RWAPI') && x.amendmentNumber == this.scfAmendmentNumber);
    }
    const confirm = this.dialog.open(CancelDialogComponent, {
      width: '600px',
      data: {
        amendment: true
      }
    });
    confirm.afterClosed().toPromise().then(async data => {
      if (data?.accept && cancelTask?.taskID && cancelTask?.cancelID) {
        this.loading.emit(true);
        const wfTaskSignature: WFTaskSignatureResource = {
          taskId: cancelTask?.taskID,
          actionId: cancelTask?.cancelID,
          reason: data.text
        };
        this.scfService.sign(wfTaskSignature).toPromise().then(() => {
          this.alert.message('CancelAmendmentSuccess', [new MessagePlaceholder('{reason}', wfTaskSignature.reason)]);
          if (this.status) {
            this.status.isCreatingAmendment = false;
            this.status.isViewingAmendment = false;
            this.status.isEditingAmendment = false;
            this.setSetatus.emit(this.status);
          }
          this.loading.emit(false);
          this.setFormDirty(false);
        },
          error => {
            this.alert.defaultError();
          }
        );
      }
    });
  }
}
