import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChecklistTasksAnnotationsEditComponent } from '../checklist-tasks-annotations/checklist-tasks-annotations-annotation/checklist-tasks-annotations-edit/checklist-tasks-annotations-edit.component';
import { Checklist } from '../../../checklists';
import { WfSignature, WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';

@Component({
  selector: 'checklist-task-review',
  templateUrl: './checklist-task-review.component.html',
  styleUrls: ['./checklist-task-review.component.scss']
})
export class ChecklistTaskReviewComponent implements OnInit {

  @Input() s!: WfSignature;
  @Input() task?: WfTaskLocalResource;
  @Input() currentChecklist?: Checklist | null;
  @Output() changed = new EventEmitter<WfTaskLocalResource>();

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  annotate() {
    const editor = this.dialog.open(ChecklistTasksAnnotationsEditComponent, {
      width: '80vw',
      maxHeight: '90vh',
      data: {
        s: this.s,
        task: this.task,
        annotation: null,
        currentChecklist: this.currentChecklist
      },
      autoFocus: false,
      disableClose: true,
      hasBackdrop: true
    });
    editor.afterClosed().toPromise().then((data) => {
      this.changed.emit(data);
    });
  }


}
