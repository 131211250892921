<div class="card">
  <div
    class="card-header d-flex align-items-center"
    *ngIf="dialogData?.isDialog"
  >
    <span class="card-title mr-3"> {{ title }}</span>
    <role-chips [roleCodes]="roleCodes"></role-chips>
    <mat-checkbox
      class="ml-3"
      [(ngModel)]="checked"
      [disabled]="disable || !dialogData?.isDialog"
      (ngModelChange)="checkboxChanged()"
    >
    </mat-checkbox>
  </div>
  <div class="card-body pb-1">
    <div class="row">
      <div class="col" *ngIf="!dialogData?.isDialog">
        <div class="row">
          <div
            class="col"
            [ngClass]="{
              'col-9': !dialogData?.isDialog,
              'col-10': dialogData?.isDialog
            }"
          >
            <h6>
              {{ title }}
            </h6>
            <role-chips [roleCodes]="roleCodes"></role-chips>
          </div>
          <div
            class="col"
            [ngClass]="{
              'col-3': !dialogData?.isDialog,
              'col-2': dialogData?.isDialog
            }"
          >
            <mat-checkbox
              class="float-right"
              [(ngModel)]="checked"
              [disabled]="disable || !dialogData?.isDialog"
            >
            </mat-checkbox>
          </div>
        </div>
      </div>
      <div
        [ngClass]="{
          'col-12 col-md-6': !dialogData?.isDialog,
          'col-12': dialogData?.isDialog
        }"
        class="col"
      >
        <form [formGroup]="formGroup">
          <comments-box
            [serialNo]="form?.sequenceNumber?.number"
            title="Details"
            [control]="textAreaControl"
            [textValue]="details"
            formType="PPSTB"
            [disabled]="disable || !dialogData?.isDialog || !checked"
            (textChanged)="textChanged($event)"
            minHeight="80px"
            [noSubscript]="!dialogData?.isDialog"
            [hasError]="!details"
          >
          </comments-box>
        </form>
      </div>
      <div class="col" *ngIf="!dialogData?.isDialog">
        <div class="row">
          <div class="col">
            <div class="row no-gutters">
              <div class="col-12 col-xl-3 fit-content pb-0">By:</div>
              <div class="col-12 col-xl-9 fit-content pb-0">
                <span class="name" *ngIf="checked">{{ userName }}</span>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-12 col-xl-3 fit-content pt-0">
                <span class="small">Date:</span>
              </div>
              <div class="col-12 col-xl-9 fit-content pt-0">
                <span class="small date" *ngIf="checked">{{
                  signedOn | date : "medium"
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-1 mb-4 d-flex align-items-start justify-content-end">
            <button
              mat-icon-button
              color="accent"
              class="d-print-none"
              (click)="followUpDialog()"
              *ngIf="!disable"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="card-footer"
    matDialogActions
    align="center"
    *ngIf="dialogData?.isDialog"
  >
    <button mat-flat-button class="mr-2" color="warn" (click)="cancel()">
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="accept()"
      [disabled]="checked && !details"
    >
      Save
    </button>
  </div>
</div>
