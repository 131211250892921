import { APLCategory } from "src/app/components/apl/services/apl-category.model";

export class APLCategoryRefresh {
  static readonly type = '[APL Category] Refresh';
}

export class APLCategoryCreate {
  static readonly type = '[APL Category] Create';
  constructor(public item: APLCategory) { }
}

export class APLCategoryUpdate {
  static readonly type = '[APL Category] Update';
  constructor(public id: number, public item: APLCategory) { }
}


export class APLCategoryRefreshByID {
  static readonly type = '[APL Category] RefreshByID';
  constructor(public id: number) { }
}
