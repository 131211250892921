<form [formGroup]="formGroup">
  <mat-form-field appearance="outline" class="no-subscript">
    <mat-label>Rad Locations</mat-label>
    <mat-chip-grid #chipList aria-label="Rad Locations">
      <mat-chip-row
        *ngFor="let radLocation of radLocationsList"
        [selectable]="!disabled"
        [removable]="!disabled"
        (removed)="remove(radLocation)"
      >
        <span>{{ radLocation.name }}</span>
        <mat-icon matChipRemove *ngIf="!disabled" class="d-print-none"
          >cancel</mat-icon
        >
      </mat-chip-row>

      <input
        matInput
        #radLocationInput
        [matAutocomplete]="autoRadL"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        formControlName="radLocationsCtrl"
        (keyup)="onChange($event)"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #autoRadL="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option
        *ngFor="let radLocation of radLocationsFiltered"
        [value]="radLocation"
        class="option"
      >
        <span class="blue" [innerHTML]="radLocation.name"></span>
        <span
          class="small description ml-1"
          [innerHTML]="radLocation.radLocationType?.name"
        >
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
