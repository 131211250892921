import { Component, OnInit, HostListener, AfterViewInit, Inject, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BakeoutEditorComponent } from './editor/editor.component';
import { BakeoutArchiveComponent } from './archive/archive.component';
import { CanDeactivateResponse } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog-response';
import { PendingChangesDialogComponent } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog.component';
import { BakeoutService } from './bakeout.service';
import { Bakeout } from './bakeouts';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-bakeouts-panel',
  templateUrl: './bakeouts-panel.component.html',
  styleUrls: ['./bakeouts-panel.component.scss']
})
export class BakeoutsPanelComponent extends BaseComponent implements OnInit, AfterViewInit {

  public loading: boolean = false;
  maxHeight: string | null = null;

  actives: Bakeout[] = [];

  bakeouts?: Bakeout[];
  bakeoutsFiltered?: Bakeout[];
  bakeouts$!: Observable<Bakeout[]>;
  bakeoutsSubs!: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public service: BakeoutService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  private removeItemById(id: number) {
    const index = this.actives.findIndex(item => item.id == id);
    if (index != -1) {
      this.actives.splice(index, 1);
    }
  }

  showArchive() {
    const self = this;
    const modal = this.dialog.open(BakeoutArchiveComponent, { maxHeight: "500px" });
    modal.componentInstance.copyItemEvent.subscribe((itemToCopy: Bakeout) => {
      itemToCopy.id = 0;
      self.showEdit(itemToCopy);
    });
  }

  showAdd() {
    this.showEdit({
      id: 0,
      name: "",       //phonenumber
      description: "",//location
      estimatedEnd: new Date(),
      estimatedStart: new Date(),
      temperature: "",
      createdOn: new Date(),
      createdBy: -1,
      temperatureUnit: "C",
      temperatureNotes: null,
      additionalNotes: null,
      createdByUser: this.getCurrentUser()?.name,
    });
  }

  showEdit(item: Bakeout) {
    const self = this;
    const modal = this.dialog.open(BakeoutEditorComponent, { disableClose: true });
    modal.componentInstance.dataItem = item;
    modal.backdropClick().subscribe(() => {
      const confirmation = this.dialog.open(PendingChangesDialogComponent, {
        height: 'fit-content',
        width: '40%',
        data: {}
      });
      confirmation.afterClosed().subscribe((response: CanDeactivateResponse) => {
        switch (response) {
          case CanDeactivateResponse.Discard:
            modal.close();
            break;
        }
      });
    });
    modal.componentInstance.returnedItemEvent.subscribe((returnedItem: Bakeout) => {
      self.loading = true;

      self.removeItemById(returnedItem.id);
      self.actives.push(returnedItem);
      self.actives = self.service.loadActives(self.actives);

      self.loading = false;
    });

    this.closeAllMenus();
  }

  archive(id: number) {
    this.closeAllMenus();

    const self = this;
    this.service.archive(id).subscribe({
      next(archiveItem: Bakeout) {
        self.removeItemById(archiveItem.id);
        self.actives = self.service.loadActives(self.actives);
      },
      error(msg) {
        console.log(msg);
      }
    });
  }

  toggleMenu(i: number) {
    var element = this.document.getElementById('bakeout-menu-' + i.toString());
    if (element) {
      element.classList.toggle('collapse');

      if (!element.classList.contains('collapse')) {
        var rect = element.parentElement?.getBoundingClientRect();
        element.style.top = (rect?.top ?? 0 + 30) + "px;";
        element.style.left = (rect?.right ?? 0 - 40) + "px;";
      }
    }
  }

  closeAllMenus() {
    this.document.querySelectorAll('div.bakeout-menu:not(.collapse)')
      .forEach(menu => { menu.classList.add('collapse'); });
  }

  ngOnInit() {
    this.loadBakeouts();
    // this.responsiveFunction();
    // this.loading = true;

    // //intitialize data
    // const self = this;
    // this.service.getActive().subscribe({
    //   next(data: Bakeout[]) {
    //     self.actives = self.service.loadActives(data);
    //   },
    //   error(msg) {
    //     console.log(msg);
    //   },
    //   complete() {
    //     self.loading = false;
    //   }
    // });
  }

  loadBakeouts() {
    this.bakeouts$ = this.store.select(state => state.Bakeouts.data);
    this.bakeoutsSubs = this.bakeouts$.subscribe(data => {
      if (data?.length) {
        this.bakeouts = data;
        this.actives = data.filter(b => b.status == 1);
      }
    });
  }

  refresh() {
    this.responsiveFunction();
    this.loading = true;

    //intitialize data
    const self = this;
    this.service.getActive().subscribe({
      next(data: Bakeout[]) {
        self.actives = self.service.loadActives(data);
      },
      error(msg) {
        console.log(msg);
      },
      complete() {
        self.loading = false;
      }
    });
  }

  ngAfterViewInit() {
    this.responsiveFunction();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
    // this.maxHeight = (parseInt(this.innerHeight) * (percentage)).toFixed(0) + "px";
    this.maxHeight = (parseInt((this.innerHeight * 0.45).toFixed(0))) + "px";
  }

  responsiveFunction() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    // this.maxHeight = (parseInt(this.innerHeight) * percentage).toFixed(0) + "px";
    this.maxHeight = (parseInt((this.innerHeight * 0.45).toFixed(0))) + "px";
  }
}
