<div class="card">
  <div class="card-header">
    <span class="card-title"> Component conditions help</span>
    <p>
      Conditions allow users to control the behavior (visibility or ability) of
      tasks based on various parameters. Below is a guide to setting up
      conditions effectively.
    </p>
    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="card-body">
    <h6>Condition Syntax</h6>
    <p>
      Conditions are defined using logical operators and
      <span class="tag">Tag</span> values. The supported operators include:
    </p>
    <table>
      <tr>
        <th>Operator</th>
        <th>Definition</th>
        <th>Example</th>
      </tr>
      <tr>
        <td><b>&&</b></td>
        <td>and</td>
        <td>
          <b class="mr-1">Tag1 && Tag2</b>Evaluates if both Tag1 and Tag2 are
          true
        </td>
      </tr>
      <tr>
        <td><b>||</b></td>
        <td>or</td>
        <td>
          <b class="mr-1">Tag1 || Tag2</b>Evaluates if either Tag1 or Tag2 is
          true
        </td>
      </tr>
      <tr>
        <td><b>==</b> or <b>===</b></td>
        <td>equals</td>
        <td>
          <b class="mr-1">Tag1 == Tag2</b>Evaluates if Tag1 is equal to Tag2<br />
          <b class="mr-1">Tag1 == 2</b>Evaluates if Tag1 is equal to a
          particular number (In this case to 2)
        </td>
      </tr>
      <tr>
        <td><b>!=</b> or <b>!==</b></td>
        <td>not equals</td>
        <td>
          <b class="mr-1">Tag1 != Tag2</b>Evaluates if Tag1 is not equal to
          Tag2<br />
          <b class="mr-1">Tag1 != 2</b>Evaluates if Tag1 is not equal to a
          particular number (In this case to 2)
        </td>
      </tr>
      <tr>
        <td><b>!</b></td>
        <td>not</td>
        <td><b class="mr-1">!Tag1</b>Evaluates if Tag1 is falsy</td>
      </tr>
      <tr>
        <td></td>
        <td>no operator</td>
        <td><b class="mr-1">Tag1</b>Evaluates if Tag1 is true</td>
      </tr>
    </table>

    <h6 class="mt-3">Nested Conditions</h6>
    <p>
      Parentheses can be used for grouping conditions:<br />
      (<b>Tag1</b> == 2 && <b>Tag2</b>) || <b>!Tag3</b>
    </p>

    <h6 class="mt-3">Handling Different Data Types</h6>
    <p>Conditions supports various data types:</p>
    <table>
      <tr>
        <th>Type</th>
        <th>Example</th>
        <th>Explanation</th>
      </tr>
      <tr>
        <td><b>Boolean</b></td>
        <td>
          <b>Tag1</b><br />
          <b>!Tag3</b>
        </td>
        <td>Tag1 is true<br />Tag 3 is falsy</td>
      </tr>
      <tr>
        <td><b>Numeric</b></td>
        <td><b>Tag2</b> == 10</td>
        <td>Checks if Tag2 equals 10</td>
      </tr>
      <tr>
        <td><b>Signatures</b></td>
        <td>
          <b>Tag1<br />!Tag3</b>
        </td>
        <td>
          Checks if Tag1 has been signed<br />Checks if Tag3 hasn't been signed
        </td>
      </tr>
    </table>

    <h6 class="mt-3">Special Conditions</h6>
    <p>Some predefined general conditions are available:</p>
    <ul>
      <li>
        <b class="mr-1">ALL-TASKS-COMPLETE</b>Returns true if all tasks are
        marked as completed
      </li>
    </ul>
  </div>
</div>
