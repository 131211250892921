<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-11">
        <h5>
          Settings for
          <b
            >{{ templateType != undefined ? templateType.description : null }}
            {{ documentType.description }}
          </b>
        </h5>
      </div>
      <!-- <div class="col-1">
        <div class="pr-0">
          <button
            class="float-right btnSettinggs"
            mat-stroked-button
            color="accent"
            (click)="back()"
            *ngIf="templateType && templateType.id"
          >
            Back General Settings
          </button>
        </div>
      </div> -->
    </div>
    <button
      mat-mini-fab
      class="closex"
      color="warn"
      (click)="close()"
      [disabled]="disabled"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="card-body pt-1">
    <mat-tab-group animationDuration="0ms" class="settings-tabs">
      <mat-tab label="General">
        <checklist-builder-settings-general
          [documentType]="documentType"
          (disableClose)="disableClose($event)"
          (selectTemplateType)="selectedTemplateType($event)"
          (loading)="setLoading($event, 1)"
        >
        </checklist-builder-settings-general>
      </mat-tab>
      <mat-tab label="Permissions" *ngIf="documentType">
        <checklist-builder-settings-permissions
          (refresh)="refresh()"
          [documentType]="documentType"
          (loading)="setLoading($event, 2)"
        ></checklist-builder-settings-permissions>
      </mat-tab>
      <mat-tab label="Statuses" *ngIf="documentType">
        <checklist-builder-settings-statuses
          (refresh)="refresh()"
          [documentType]="documentType"
          (loading)="setLoading($event, 3)"
        ></checklist-builder-settings-statuses>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
