import { Component, Injector, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ClBaseComponent } from '../cl-base/cl-base.component';


@Component({
  selector: 'cl-note',
  templateUrl: './cl-note.component.html',
  styleUrls: ['./cl-note.component.scss']
})
/** cl-note component*/
export class ClNoteComponent extends ClBaseComponent {

  textSanitized!: SafeHtml;

  constructor(
    protected override injector: Injector,
    private sanitizer: DomSanitizer
  ) {
    super(injector);
  }

}
