<div class="body">
  <mat-toolbar class="toolbar">
    <div class="full-width">
      <div class="row full-width">
        <div
          class="col-sm-12 col-md-2 d-print-none d-flex pl-5 align-items-center"
        >
          <span class="toolbar-title">Training Reports</span>
        </div>
        <div class="col-sm-12 col-md-10 d-flex justify-content-end">
          <div class="d-flex flex-column justify-content-center">
            <span class="d-flex justify-content-center pt-1">Export Table</span>
            <div class="pb-1">
              <button
                mat-flat-button
                (click)="
                  exporter.exportTable('xlsx', {
                    fileName: 'Training_Report',
                    sheet: 'Training_Report',
                    Props: { Author: 'ASCC' }
                  })
                "
                class="green-background mx-1"
              >
                Excel
              </button>
              <button
                mat-flat-button
                (click)="exporter.exportTable('csv')"
                class="yellow-background mx-1"
              >
                CSV
              </button>
              <button
                mat-flat-button
                (click)="exporter.exportTable('json')"
                class="blue-background mx-1"
              >
                Json
              </button>
              <button
                mat-flat-button
                (click)="exporter.exportTable('txt')"
                class="orange-background mx-1"
              >
                TXT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar>
  <div class="mat-container mat-table-container scrollbar">
    <form [formGroup]="form">
      <mat-table
        [dataSource]="dataSource"
        matTableExporter
        #exporter="matTableExporter"
      >
        <!-- Filter Columns -->
        <ng-container matColumnDef="0">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field class="no-subscript mb-0">
              <input
                matInput
                formControlName="nameCtrl"
                type="text"
                placeholder="User Name"
              />
            </mat-form-field>
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="1">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field class="no-subscript mb-0">
              <input
                matInput
                formControlName="numberCtrl"
                placeholder="Procedure #"
              />
            </mat-form-field>
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="2">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field class="no-subscript mb-0">
              <input
                matInput
                formControlName="title"
                placeholder="Procedure Title"
              />
            </mat-form-field>
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="3">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field class="no-subscript mb-0">
              <input
                matInput
                formControlName="revisionCtrl"
                placeholder="Rev."
              />
            </mat-form-field>
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="4">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field class="no-subscript mb-0">
              <input
                matInput
                formControlName="lastReviewedCtrl"
                placeholder="Last Reviewed"
              />
            </mat-form-field>
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="4a">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field class="no-subscript mb-0">
              <input
                matInput
                formControlName="activeCtrl"
                placeholder="Status"
              />
            </mat-form-field>
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="5">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field class="no-subscript mb-0">
              <input
                matInput
                formControlName="obligationCtrl"
                placeholder="Obligation"
              />
            </mat-form-field>
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="6">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field class="no-subscript mb-0">
              <input matInput formControlName="rolesCtrl" placeholder="Roles" />
            </mat-form-field>
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="6a">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field class="no-subscript mb-0">
              <input
                matInput
                formControlName="creditedRevisionCtrl"
                placeholder="Roles"
              />
            </mat-form-field>
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="7">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field class="no-subscript mb-0">
              <input
                matInput
                formControlName="creditedCtrl"
                placeholder="Date Credited"
              />
            </mat-form-field>
          </mat-header-cell>
        </ng-container>

        <ng-container matColumnDef="8">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field class="no-subscript mb-0">
              <input
                matInput
                formControlName="approverCtrl"
                placeholder="Approver"
                style="max-width: 6rem"
              />
            </mat-form-field>
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="9">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field class="no-subscript mb-0">
              <input
                matInput
                formControlName="groupManagerCtrl"
                placeholder="Group Manager"
              />
            </mat-form-field>
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="10">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field class="no-subscript mb-0">
              <input
                matInput
                formControlName="categoryManagerCtrl"
                placeholder="Category Manager"
              />
            </mat-form-field>
          </mat-header-cell>
        </ng-container>

        <!-- Clear Filters -->
        <ng-container matColumnDef="clear">
          <mat-header-cell *matHeaderCellDef>
            <button
              mat-icon-button
              title="Clear Filters"
              (click)="clearFilters()"
            >
              <mat-icon class="yellow">backspace</mat-icon>
            </button>
          </mat-header-cell>
        </ng-container>

        <!-- Header Columns -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> User Name </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span
              [ngClass]="{
                gray: !element.active,
                red: element.revision != element.creditedRevision
              }"
              [innerHTML]="element.userName | highlightSearch : nameCtrl.value"
            ></span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef> Procedure # </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span
              [ngClass]="{
                gray: !element.active,
                red: element.revision != element.creditedRevision
              }"
              [innerHTML]="
                element.procedureNumber | highlightSearch : numberCtrl.value
              "
            ></span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef> Procedure Title </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span
              [ngClass]="{
                gray: !element.active,
                red: element.revision != element.creditedRevision
              }"
              class="wrap"
              [innerHTML]="element.title | highlightSearch : titleCtrl.value"
            ></span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="revision">
          <mat-header-cell *matHeaderCellDef> Rev. </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span
              [ngClass]="{
                gray: !element.active,
                red: element.revision != element.creditedRevision
              }"
              [innerHTML]="
                element.revision | highlightSearch : revisionCtrl.value
              "
            ></span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="reviewed">
          <mat-header-cell *matHeaderCellDef> Last Reviewed </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span
              [ngClass]="{
                gray: !element.active,
                red: element.revision != element.creditedRevision
              }"
              [innerHTML]="
                formatDate(element.lastReviewed) ?? ''
                  | highlightSearch : creditedCtrl.value
              "
            ></span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span
              [ngClass]="{
                gray: !element.active,
                red: element.revision != element.creditedRevision
              }"
              [innerHTML]="
                element.active
                  ? 'Active'
                  : ('Inactive' | highlightSearch : activeCtrl.value)
              "
            ></span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="obligation">
          <mat-header-cell *matHeaderCellDef> Obligation </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span
              [ngClass]="{
                gray: !element.active,
                red: element.revision != element.creditedRevision
              }"
              [innerHTML]="
                element.obligationType | highlightSearch : obligationCtrl.value
              "
            ></span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="roles">
          <mat-header-cell *matHeaderCellDef> Role(s) </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span
              [ngClass]="{
                gray: !element.active,
                red: element.revision != element.creditedRevision
              }"
              class="wrap"
              [innerHTML]="element.roles | highlightSearch : rolesCtrl.value"
            ></span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="credrev">
          <mat-header-cell *matHeaderCellDef> Credited Rev </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span
              [ngClass]="{
                gray: !element.active,
                red: element.revision != element.creditedRevision
              }"
              [innerHTML]="
                element.creditedRevision
                  | highlightSearch : creditedRevisionCtrl.value
              "
            ></span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="credited">
          <mat-header-cell *matHeaderCellDef> Date Credited </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span
              [ngClass]="{
                gray: !element.active,
                red: element.revision != element.creditedRevision
              }"
              [innerHTML]="
                formatDate(element.dateCredited) ?? ''
                  | highlightSearch : creditedCtrl.value
              "
            ></span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="approver">
          <mat-header-cell *matHeaderCellDef> Approver </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span
              [ngClass]="{
                gray: !element.active,
                red: element.revision != element.creditedRevision
              }"
              [innerHTML]="
                element.approverName | highlightSearch : approverCtrl.value
              "
            ></span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="groupManager">
          <mat-header-cell *matHeaderCellDef>
            Group Manager(s)
          </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span
              [ngClass]="{
                gray: !element.active,
                red: element.revision != element.creditedRevision
              }"
              class="wrap"
              [innerHTML]="
                element.groupManagers | highlightSearch : groupManagerCtrl.value
              "
            ></span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="categoryManager">
          <mat-header-cell *matHeaderCellDef>
            Category Manager(s)
          </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span
              [ngClass]="{
                gray: !element.active,
                red: element.revision != element.creditedRevision
              }"
              class="wrap"
              [innerHTML]="
                element.categoryManagers
                  | highlightSearch : categoryManagerCtrl.value
              "
            ></span>
          </mat-cell>
        </ng-container>

        <!-- Show/Hide Filters -->
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>
            <button mat-icon-button (click)="filterToggle()">
              <mat-icon>filter_list</mat-icon>
            </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let element"></mat-cell>
        </ng-container>

        <!-- Header Row -->
        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>

        <!-- Filter Row -->
        <mat-header-row
          #matrow
          [style.display]="!filterOn ? 'none' : null"
          *matHeaderRowDef="
            [
              '0',
              '1',
              '2',
              '3',
              '4',
              '4a',
              '5',
              '6',
              '6a',
              '7',
              '8',
              '9',
              '10',
              'clear'
            ];
            sticky: true
          "
          class="filter-row"
        >
        </mat-header-row>

        <!-- Table Rows -->
        <mat-row
          #matrow
          *matRowDef="let row; columns: displayedColumns"
          style="white-space: nowrap"
        ></mat-row>
      </mat-table>
    </form>
  </div>
</div>
<overlay-loading *ngIf="loading"></overlay-loading>
