import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy, Injector } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { ProcedureSignatureType } from 'src/app/components/procedure/enums/procedure-signature-type.enum';
import { ProcedureSignature } from 'src/app/components/procedure/models/procedure-signature.model';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { WfSignature, WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { Annotation, ChecklistTemplate, ChecklistTemplateStatusEnum, AnnotationStatus } from '../../../checklists';
import { ChecklistBuilderService } from '../../checklist-builder.service';
import { ChecklistBuilderAnnotationEditComponent } from '../checklist-builder-annotation-edit/checklist-builder-annotation-edit.component';
import { ChecklistBuilderAnnotationsService } from '../checklist-builder-annotations.service';


@Component({
  selector: 'checklist-builder-annotation',
  templateUrl: './checklist-builder-annotation.component.html',
  styleUrls: ['./checklist-builder-annotation.component.scss']
})
export class ChecklistBuilderAnnotationComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() annotation!: Annotation;
  @Input() s!: WfSignature;
  @Input() task!: WfTaskLocalResource;
  @Input() currentVersion!: ChecklistTemplate;

  @Output() changed = new EventEmitter<WfTaskLocalResource>();

  annotationTextSanitized!: SafeHtml;
  responseTextSanitized!: SafeHtml;
  procedure!: Procedure | null;
  procedureSignature?: ProcedureSignature;

  checklistTemplateStatusEnum = ChecklistTemplateStatusEnum;
  annotationStatus = AnnotationStatus;

  hasRresponse!: boolean;
  isPreparer!: boolean;
  isReviewer!: boolean;
  isApprover!: boolean;
  isCreator!: boolean;
  isRCP!: boolean;

  constructor(
    protected override injector: Injector,
    private sanitizer: DomSanitizer,
    private _serv: ChecklistBuilderAnnotationsService,
    private _builder: ChecklistBuilderService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.annotationTextSanitized = this.sanitizer.bypassSecurityTrustHtml(this.annotation.annotationText ?? '');
    if (this.annotation.responseText)
      this.responseTextSanitized = this.sanitizer.bypassSecurityTrustHtml(this.annotation.responseText);
    this.annotation.createdByName = this.getUsers().find(x => x.id == this.annotation?.createdBy)?.name;
    this.annotation.responseByName = this.getUsers().find(x => x.id == this.annotation.responseBy)?.name;
    this.isCreator = this.annotation.createdBy == this.getCurrentUser()?.id;
    this.isRCP = this.currentVersion.documentTypeID == 200;
    this.loadProcedure();
    this.setPrivs();
  }

  ngOnInit() {
    this.setPrivs();
  }

  loadProcedure() {
    this._builder.getProcedure().subscribe(procedure => {
      this.procedure = procedure;
      this.setPrivs();
    });
  }

  setPrivs() {
    this.isPreparer = this.is(ProcedureSignatureType.Preparer);
    this.isReviewer = this.is(ProcedureSignatureType.Reviewer);
    this.isApprover = this.is(ProcedureSignatureType.Approver);
    this.procedureSignature = this.procedure?.procedureSignatures?.find(x => x.userID == this.getCurrentUser()?.id);
  }

  edit() {
    const editor = this.dialog.open(ChecklistBuilderAnnotationEditComponent, {
      width: '80vw',
      maxHeight: '90vh',
      data: {
        s: this.s,
        task: this.task,
        annotation: this.annotation,
        templateId: this.currentVersion.id,
        currentVersion: this.currentVersion,
        isResponse: false
      },
      autoFocus: false,
      disableClose: true,
      hasBackdrop: true
    });
    editor.afterClosed().toPromise().then(data => {
      if (data) {
        this.changed.emit(data);
      }
    });
  }

  delete() {
    const yesno = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('GenChklistBldr_DeleteAnnotationConfimation').description,
        icon: 'stop'
      }
    });
    yesno.afterClosed().toPromise().then(res => {
      if (res) {
        this._serv.delete(this.annotation).toPromise().then(data => {
          this.changed.emit(data);
        });
      }
    });
  }

  done() {
    this.annotation.status = this.annotationStatus.Done;
    this._serv.update(this.annotation).toPromise().then(data => {
      this.changed.emit(data);
    });
  }

  undone() {
    this.annotation.status = this.annotationStatus.Active;
    this._serv.update(this.annotation).toPromise().then(data => {
      this.changed.emit(data);
    });
  }

  respond() {
    const editor = this.dialog.open(ChecklistBuilderAnnotationEditComponent, {
      width: '80vw',
      maxHeight: '90vh',
      data: {
        s: this.s,
        task: this.task,
        annotation: this.annotation,
        templateId: this.currentVersion.id,
        currentVersion: this.currentVersion,
        isResponse: true
      },
      autoFocus: false,
      disableClose: true,
      hasBackdrop: true
    });
    editor.afterClosed().subscribe(async (data) => {
      if (data) {
        this.changed.emit(data);
      }
    });
  }


  deleteResponse() {
    this.annotation.responseBy = null;
    this.annotation.responseByName = null;
    this.annotation.responseDate = null;
    this.annotation.responseText = null;
    this._serv.update(this.annotation).toPromise().then(data => {
      this.changed.emit(data);
    });
  }

  is(signatureType: ProcedureSignatureType) {
    const signatures = this.procedure?.procedureSignatures?.filter(x => x.signatureType == signatureType);
    return signatures?.map(x => x.userID).includes(this.getCurrentUser()?.id ?? 0) ?? false;
  }
}
