import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'src/app/common/base/base.service';
import { ScheduleReminder } from '../models/schedule-reminder';

@Injectable({
  providedIn: 'root'
})
export class ScheduleReminderService extends BaseService<ScheduleReminder, number> {

  constructor(protected override injector: Injector) {
    super(injector, '/schedule_reminder');
  }

  // readAll(): Observable<ScheduleReminder[]> {
  //   return this.http.get<ScheduleReminder[]>(this.rootURL, this.httpOptions).pipe(catchError(e => this.handleError(e)));
  // }

  readByType(type: number) {
    return this.http.get<ScheduleReminder[]>(this.endpoint + '/type/' + type);
  }
}
