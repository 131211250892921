<div class="card">
  <div class="card-header">
    <span class="card-title"
      >{{ ologMessage.id ? "Edit" : "Add" }} Olog Message</span
    >
  </div>
  <div class="card-body">
    <form [formGroup]="formGroup">
      <div class="row mt-2">
        <div class="col-6">
          <div class="row">
            <div class="col-8">
              <mat-form-field class="no-subscript">
                <mat-label>Name</mat-label>
                <input matInput [formControl]="nameCtrl" />
                <mat-error *ngIf="nameCtrl?.invalid">{{
                  getErrorMsg(nameCtrl)
                }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-slide-toggle [formControl]="activeCtrl"
                >Active</mat-slide-toggle
              >
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field class="no-subscript">
                <mat-label>Notification Type</mat-label>
                <input
                  type="text"
                  placeholder="Pick one"
                  aria-label="Number"
                  matInput
                  [formControl]="notificationTypeCtrl"
                  [matAutocomplete]="autoType"
                />
                <mat-autocomplete
                  #autoType="matAutocomplete"
                  [displayWith]="displayObject"
                >
                  <mat-option *ngFor="let type of notifications" [value]="type">
                    {{ type.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-6">
          <fieldset class="field-group">
            <legend class="field">
              Olog Message will be sent when form changes Status:
            </legend>
            <div class="row mt-3">
              <div class="col-6 pr-1">
                <mat-form-field class="no-subscript">
                  <mat-label>From</mat-label>
                  <input
                    [ngClass]="fromCtrl.value?.class"
                    type="text"
                    placeholder="Pick one"
                    aria-label="Number"
                    matInput
                    [formControl]="fromCtrl"
                    [matAutocomplete]="autoFrom"
                    (keyup)="filterFromStatuses($event)"
                  />
                  <mat-autocomplete
                    #autoFrom="matAutocomplete"
                    [displayWith]="displayObject"
                  >
                    <mat-option
                      [ngClass]="status.class"
                      *ngFor="let status of fromStatusesFiltered"
                      [value]="status"
                    >
                      {{ status.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-6 pl-1">
                <mat-form-field class="no-subscript">
                  <mat-label>To</mat-label>
                  <input
                    [ngClass]="toCtrl.value?.class"
                    type="text"
                    placeholder="Pick one"
                    aria-label="Number"
                    matInput
                    [formControl]="toCtrl"
                    [matAutocomplete]="autoTo"
                    (keyup)="filterToStatuses($event)"
                  />
                  <mat-autocomplete
                    #autoTo="matAutocomplete"
                    [displayWith]="displayObject"
                  >
                    <mat-option
                      [ngClass]="status.class"
                      *ngFor="let status of toStatusesFiltered"
                      [value]="status"
                    >
                      {{ status.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-12">
          <comments-box
            title="Description"
            [control]="descriptionCtrl"
            [textValue]="ologMessage.description"
            [formType]="'Olog'"
            [serialNo]="serialNo"
            (textChanged)="
              descriptionCtrl.setValue($event); ologMessage.description = $event
            "
          ></comments-box>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h6>Message</h6>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline" class="no-subscript">
                <mat-label>Email To:</mat-label>
                <mat-chip-grid #chipListEmails>
                  <mat-chip-row
                    *ngFor="let email of ologEmails"
                    (removed)="removeEmail(email)"
                  >
                    {{
                      email.name
                        ? email.name + " <" + email.address + ">"
                        : email.address
                    }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip-row>
                </mat-chip-grid>
                <input
                  #emailInput
                  aria-label="email"
                  placeholder="Select or write an Email Address"
                  (keyup)="filterEmails($event)"
                  [matChipInputFor]="chipListEmails"
                  [matAutocomplete]="autoEmails"
                  [matChipInputAddOnBlur]="true"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addEmail($event)"
                  [formControl]="emailsCtrl"
                />
                <mat-autocomplete
                  #autoEmails="matAutocomplete"
                  (optionSelected)="selectedEmail($event)"
                >
                  <mat-option
                    *ngFor="let email of filteredEmails"
                    [value]="email"
                  >
                    {{
                      email.name
                        ? email.name + " <" + email.address + ">"
                        : email.address
                    }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field class="no-subscript">
                <mat-label>Subject</mat-label>
                <input
                  matInput
                  [(ngModel)]="ologMessage.subject"
                  [formControl]="subjectCtrl"
                />
                <mat-error *ngIf="subjectCtrl?.invalid">{{
                  getErrorMsg(subjectCtrl)
                }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <comments-box
                title="Message Body"
                [control]="messageCtrl"
                [textValue]="ologMessage.message"
                [formType]="'Olog'"
                [hasError]="!messageCtrl.valid"
                [serialNo]="serialNo"
                [editor]="true"
                (textChanged)="
                  messageCtrl.setValue($event); ologMessage.message = $event
                "
              ></comments-box>
            </div>
            <div class="col-12 pb-3">
              Placeholders for Subject or Message:
              <span
                class="placeholder"
                (click)="copyToClipboard('{serialNo}')"
                >{{ "{serialNo}" }}</span
              >,
              <span
                (click)="copyToClipboard('{location}')"
                class="placeholder"
                >{{ "{location}" }}</span
              >
            </div>
            <div class="col-12">
              <mat-form-field class="no-subscript">
                <mat-label>Categories</mat-label>
                <mat-chip-grid #chipGrid aria-label="Categories">
                  <mat-chip-row
                    *ngFor="let category of ologCategories"
                    (removed)="removeCategory(category)"
                  >
                    {{ category }}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                </mat-chip-grid>
                <input
                  placeholder="Select Category"
                  #categoryInput
                  [formControl]="categoriesCtrl"
                  [matChipInputFor]="chipGrid"
                  [matAutocomplete]="autoCategory"
                  [matChipInputAddOnBlur]="true"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addCategory($event)"
                  (keyup)="filterCategories($event)"
                />
                <mat-autocomplete
                  #autoCategory="matAutocomplete"
                  (optionSelected)="selectedCategory($event)"
                >
                  <mat-option
                    *ngFor="let category of filteredCategories"
                    [value]="category"
                  >
                    {{ category }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        class="cancel-btn"
        color="warn"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        class="accept-btn"
        color="primary"
        [disabled]="!isFormDirty() || !this.formGroup.valid"
        (click)="accept()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
