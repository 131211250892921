import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/common/base/base.service';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService extends BaseService<null, number> {

  constructor(
    protected override injector: Injector,
  ) {
    super(injector, '');
  }

  /**
   * Opens a confirmation dialog with a customizable message and icon.
   * The dialog displays an action (e.g., "delete") and an item to be acted upon.
   * The method returns an observable that emits the result once the dialog is closed.
   *
   * @param {string} action - The action being confirmed (e.g., "delete", "update").
   * @param {string} item - The item that the action will be performed on, displayed in the message.
   * @param {string} icon - The icon that will be displayed on the dialog: ['stop', 'question', 'warn'].
   * @returns {Observable<any>} - An observable that emits the result of the dialog (e.g., whether the user confirmed or canceled).
   */
  ConfirmationDialog(action: string, item: string, icon: string = 'stop'): Observable<any> {
    const message = this.getMessage("GenericActionConfirmation")
      .description?.replace('{action}', action)
      .replace('{item}', item);

    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: message,
        icon,
      },
    });

    return dialogRef.afterClosed(); // Return observable
  }
}
