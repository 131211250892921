import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { utils } from 'src/app/modules/libs/utils';
import { ReviewActionItem } from '../../../reviews.models';
import { ReviewsService } from '../../../reviews.service';

@Component({
  selector: 'reviews-action-items-complete',
  templateUrl: './reviews-action-items-complete.component.html',
  styleUrls: ['./reviews-action-items-complete.component.scss']
})
export class ReviewsActionItemsCompleteComponent implements OnInit {

  reviewActionItem: ReviewActionItem;
  completeComment?: string;
  hasError = true;
  isLoading!: boolean;

  constructor(
    public dialogRef: MatDialogRef<ReviewsActionItemsCompleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: ReviewsService,
  ) {
    this.reviewActionItem = data.reviewActionItem;
  }

  ngOnInit(): void {
  }

  async complete() {
    this.isLoading = true;
    this.reviewActionItem.closingComments = this.completeComment;
    this.reviewActionItem.status = 0;
    const reviewActionItem = utils.Serialize(this.reviewActionItem);
    await this.service.updateReviewActionItem(reviewActionItem).toPromise().then(data => {
      this.dialogRef.close(data);
    });
    this.isLoading = false;
  }

  commentChanged(e: any) {
    this.completeComment = e;
    this.hasError = e == null;
  }

  close() {
    this.dialogRef.close();
  }

}
