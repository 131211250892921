import { DefaultData } from "src/app/components/schedules/models/default-data";
import { MessageTypeEnum, MessagePartEnum } from "./message-banner.enums";

export class MessageBanner {
  id!: number;
  isActive!: boolean;
  createdOn!: Date;
  createdBy?: number;
  updatedOn?: Date;
  updatedBy?: number;
  deletedOn?: Date;
  deletedBy?: number;
  code!: string;
  name!: string;
  description!: string;
  type!: MessageTypeEnum;
  timeout!: number;
  part!: MessagePartEnum;
  keepAfterRouteChange!: boolean;
  notes!: string;
  categories?: string;
  messageCategoryRelations?: MessageCategoryRelation[];
}

export class MessageCategoryRelation {
  public id!: number;
  public messageBannerID!: number;
  public systemCategoryID!: number;
  public createdBy!: number;
  public updatedBy!: number;
  public createdOn!: Date;
  public updatedOn!: Date;
  public messageBanner!: MessageBanner;
  public systemCategory?: SystemCategory;
}

export class SystemCategory {
  public id?: number;
  public name?: string;
  public description?: string;
  public status?: boolean;
  public createdBy?: number;
  public updatedBy?: number;
  public createdOn?: Date;
  public updatedOn?: Date;
}
