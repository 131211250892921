import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { RSSWAOtherSystem } from 'src/app/controls/other-system-chips/other-system';

@Component({
  selector: 'rsswa-other-system-chips',
  templateUrl: './rsswa-other-system-chips.component.html',
  styleUrls: ['./rsswa-other-system-chips.component.scss']
})
export class RsswaOtherSystemChipsComponent implements OnInit {
  @Input() placeholder?: string;
  @Input() disabled?: boolean;
  @Input() control!: FormControl;
  @Input() rsswaId?: number;
  @Input() rsswaOtherSystems?: RSSWAOtherSystem[];

  @Output() onEdit = new EventEmitter<RSSWAOtherSystem[]>();

  constructor() { }

  ngOnInit(): void {
  }

  add(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if (input) {
      input.value = '';
    }

    if (!this.rsswaOtherSystems) this.rsswaOtherSystems = [];
    if (value.trim() != '') {
      this.rsswaOtherSystems?.push({
        rsswaID: this.rsswaId,
        text: value
      });
      this.onEdit.emit(this.rsswaOtherSystems);
    }
  }

  remove(rsswaOtherSystem: RSSWAOtherSystem) {
    const index = this.rsswaOtherSystems?.indexOf(rsswaOtherSystem) ?? -1;

    if (index >= 0) {
      this.rsswaOtherSystems?.splice(index, 1);
      this.onEdit.emit(this.rsswaOtherSystems);
    }
  }

}
