import { AfterViewInit, Component, ElementRef, HostListener, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Resource } from '../beamline-catalog/resource/resources';
import { RssdListMainComponent } from './rssd-list-main/rssd-list-main.component';

@Component({
  selector: 'rssd-list',
  templateUrl: './rssd-list.component.html',
  styleUrls: ['./rssd-list.component.scss']
})
export class RssdListComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @ViewChild("sidenav") sidenav!: MatSidenav;
  @ViewChild("headerTop") headerTop!: ElementRef;

  @ViewChild(RssdListMainComponent) main!: RssdListMainComponent;

  disabled: boolean = false;

  loading = false;

  resourceRequired: boolean = false;

  filterCtrl = new FormControl();

  errorMessages: string[] = [];
  headerOnTop: boolean = false;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter: any[] = [];
  currentFilter: any;
  filter: any;
  loadingMessage!: string;
  //////

  resource?: Resource | null;
  resources?: Resource[] | null;

  constructor(
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.setHeights();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.loadSCFs();
  }

  ngOnInit() {
    this.moduleTitle = 'RSSD List';
  }

  changeToggle(e: any) {

  }

  clear() {
    this.errorMessages = [];
  }


  @HostListener("window:resize")
  onResize() {
    this.innerWidth = window.innerWidth;
    // if (this.isExpanded != false) {

    // }
    if (this.innerWidth < 768) {
      this.isExpanded = false;
    }
  }

  @HostListener("window:scroll")
  isHeaderOnTop() {
    let elemRec;
    if (this.headerTop?.nativeElement !== undefined) {
      elemRec = this.headerTop.nativeElement.getBoundingClientRect();
      const docViewTop = window.screenTop;
      const elemTop = elemRec.top;
      if (elemTop <= docViewTop) {
        this.headerOnTop = true;
      } else {
        this.headerOnTop = false;
      }
      // this.tableWidth = this.scheduleTable.nativeElement.offsetWidth + 'px';
    } else {
      this.headerOnTop = false;
    }
  }

  //////
  changedFilter() { }
  clearFilter() { }
  selectedFilter() { }
  createDisabled() { }
  editDisabled() { }
  action() { }

  applyFilter(e: any) {
    if (!e)
      this.valueFilter = '';
    this.filter = e;
  }

  rowSelected(r: Resource) {
    this.main?.scrollTo(r);
  }

  rowsFiltered(rows: Resource[]) {
    this.resources = rows;
  }


  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 2000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Buttons:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Main:
          this.loadingMain = e as boolean;
          break;
      }
      // console.log('m: ' + this.loadingMain + '   b: ' + this.loadingButtons + '   s: ' + this.loadingSide);
      this.loading = this.loadingMain || this.loadingButtons || this.loadingSide;
    }, 100);
  }

  loadingSide = false;
  loadingButtons = false;
  loadingMain = false;
  loadingOrigin = LoadingOrigin;
}

enum LoadingOrigin {
  Side = 1, Buttons = 2, Main = 3
}
