import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, ViewChild, Injector } from '@angular/core';
import { MenuItem, MenuItemTypes } from '../head-menu.models';
import { HeadMenuService } from '../head-menu.service';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { ScheduleType } from 'src/app/components/schedules/models/schedule-type';
import { NavigationLink } from 'src/app/services/navigation-links/navigation-link';
import { MenuConfigActionsComponent, Orientation } from 'src/app/components/menu-config/menu-config-actions/menu-config-actions.component';
import { utils } from 'src/app/modules/libs/utils';
import { MatMenuTrigger } from '@angular/material/menu';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AppStateService } from 'src/app/store/app-state-service';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { ProcedureReportService } from 'src/app/components/procedure/services/procedure-report.service';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'head-menu-dropmenu',
  templateUrl: './head-menu-dropmenu.component.html',
  styleUrls: ['./head-menu-dropmenu.component.scss']
})
export class HeadMenuDropmenuComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() menuItem!: MenuItem;
  @Input() editing?: boolean;
  @Input() orientation?: Orientation;
  @Input() SA!: boolean;

  @ViewChild(MenuConfigActionsComponent) actions?: MenuConfigActionsComponent;
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;

  menuItems: MenuItem[] = [];
  menuItemTypes = MenuItemTypes;
  menuItems$!: Observable<MenuItem[]>;
  subscription: Subscription = new Subscription;

  scheduleTypes: ScheduleType[] = [];
  scheduleTypes$!: Observable<ScheduleType[]>;
  scheduleTypesSubs: Subscription = new Subscription;

  navigationLinks?: NavigationLink[];
  navigationLinks$!: Observable<NavigationLink[]>;
  navigationLinksSubs!: Subscription;

  orientationEnum = Orientation;

  missingTrainings?: number;
  missingTrainings$!: Observable<number>;
  missingTrainingsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private _service: HeadMenuService,
    private procedureReport: ProcedureReportService
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.scheduleTypesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadMenuItems();
  }

  ngOnInit() {
    this.loadMissingTrainings();
  }

  // override currentUserUpdated(): void {
  //   this.getMissingTrainings();
  // };

  loadMenuItems() {
    this.menuItems$ = this.store.select(state => state.MenuItem.data);
    this.subscription = this.menuItems$.subscribe(data => {
      if (data.length) {
        if (this.menuItem) {
          const menuItem = data.find(x => x.id == this.menuItem.id);
          if (menuItem) {
            menuItem.childMenuItems = data.filter(x => x.parentMenuItemID == menuItem?.id && (x.status || this.editing)).sort((a, b) => utils.sort(a.order, b.order));
            this.menuItem = menuItem;
            this.createMenu();
          }
        }
      }
    });
  }

  createMenu() {
    if (this.menuItem.table) {
      switch (this.menuItem.table.toLowerCase()) {
        case 'links':
          this.loadNavigationLinks();
          break;
        case 'scheduletypes':
          this.loadScheduleTypes();
          break;
      }
    }
    else {
      if (this.menuItem.childMenuItems)
        this.menuItems = this._service.calculateRouterLinks(this.menuItem.childMenuItems).sort((a, b) => a.order - b.order);
    }
  }

  loadNavigationLinks() {
    this.navigationLinks$ = this.store.select(state => state.NavigationLinks.data);
    this.navigationLinksSubs = this.navigationLinks$.subscribe(async data => {
      if (data?.length) {
        const menuItems: MenuItem[] = [];
        this.navigationLinks = data.filter(x => x.isActive);
        this.navigationLinks?.map(l => {
          const menuitem: MenuItem = {
            name: l.hyperlink,
            route: l.webAddress,
            visible: l.isActive,
            status: 1,
            isSeparator: false,
            order: l.order,
            type: MenuItemTypes.Link,
            disableEdit: true
          };
          menuItems.push(menuitem);
        });
        this.menuItems = this._service.calculateRouterLinks(menuItems);
      }
    });
  }

  loadScheduleTypes() {
    this.scheduleTypes$ = this.store.select(state => state.ScheduleTypes.data);
    this.scheduleTypesSubs = this.scheduleTypes$.subscribe(async data => {
      if (data?.length) {
        const menuItems: MenuItem[] = [];
        data.filter(x => x.isActive && x.name).map(l => {
          const menuitem: MenuItem = {
            name: l.name + ' (' + l.acronym + ')',
            route: 'schedulesV2/' + l.id,
            visible: l.isActive,
            isSeparator: false,
            order: 1,
            status: 1,
            type: MenuItemTypes.Normal,
            disableEdit: true
          };
          menuItems.push(menuitem);
        });
        this.menuItems = this._service.calculateRouterLinks(menuItems);
      }
    });
  }

  openComponent(name: string) {
    this._service.openComponent(name);
  }

  // getMissingTrainings() {
  //   if (this.currentUser?.id && this.menuItem.code == 'procedures') {
  //     this.procedureReport.readByUserID(this.currentUser.id).toPromise().then(data => {
  //       this.missingTrainings = data?.filter(d => d.revision != d.creditedRevision).length;
  //     });
  //   }
  // }

  loadMissingTrainings() {
    this.missingTrainings$ = this.store.select(state => state.MissingTraining.data);
    this.missingTrainingsSubs = this.missingTrainings$.subscribe(data => {
      this.missingTrainings = data;
    });
  }

}
