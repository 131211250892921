import { RadLocationInterlockRelation } from './rad-location-interlock-relation';
import { RadNote } from './rad-note';
import { RadMonitor } from './rad-monitor';
import { RadLocationType } from './rad-location-type';
import { Note } from '../../../../services/notes/note.model';

export class RadLocation {
  id?: number;
  name?: string;
  typeId?: number;
  rpixName?: string;
  description?: string;
  controllerLocation?: string;
  controllerPower?: string;
  resetLocation?: string;
  summation?: string;
  tripset?: number;
  tripsetA1?: number;
  tripsetI1?: number;
  radLocationType?: RadLocationType;
  radLocationInterlockRelations?: RadLocationInterlockRelation[];
  radNotes?: RadNote[];
  interlockNames?: string;
  normalNotes?: RadNote[];
  faultNotes?: RadNote[];
  isEMShop?: boolean;
  radMonitors?: RadMonitor[];
  type?: string;
  createdOn?: Date;
  createdBy?: number;
  updatedOn?: Date;
  updatedBy?: number;

  notes?: Note[];
  notesf?: Note[];
}
