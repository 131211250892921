<div class="card">
  <div class="card-header">
    <div class="card-title">
      Bakeout <span class="small">{{ subtitle }}</span>
    </div>
  </div>
  <div class="card-body">
    <form [formGroup]="bakeoutForm">
      <mat-form-field appearance="outline">
        <mat-label>Username</mat-label>
        <input
          disabled
          [(ngModel)]="Username"
          [ngModelOptions]="{ standalone: true }"
          matInput
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Phone number</mat-label>
        <input
          required
          [(ngModel)]="Phonenumber"
          matInput
          formControlName="phone"
          type="tel"
        />
        <mat-error *ngIf="phone?.invalid">{{ getErrorMsg(phone) }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label
          >Location (Include relevant details such as building, beamline,
          room)</mat-label
        >
        <input
          required
          [(ngModel)]="Location"
          matInput
          formControlName="location"
        />
        <mat-error *ngIf="location?.invalid">{{
          getErrorMsg(location)
        }}</mat-error>
      </mat-form-field>

      <div class="row no-gutters">
        <mat-form-field class="col">
          <mat-label>Estimated Start</mat-label>
          <input
            [disabled]="saving"
            required
            matInput
            [(ngModel)]="EstimatedStartOutput"
            [ngModelOptions]="{ standalone: true }"
            [min]="DateNow"
            (change)="changeDate()"
            (blur)="changeDate(true)"
            type="date"
          />
          <button
            [disabled]="saving"
            mat-button
            *ngIf="EstimatedStartOutput"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="EstimatedStartOutput = null"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="!EstimatedStartOutput"
            >You must enter a date</mat-error
          >
        </mat-form-field>

        <mat-form-field class="col ml-1">
          <mat-label>Estimated End</mat-label>
          <input
            [disabled]="saving"
            required
            matInput
            [(ngModel)]="EstimatedEndOutput"
            [ngModelOptions]="{ standalone: true }"
            [min]="
              EstimatedStartOutput == null ? DateNow : EstimatedStartOutput
            "
            (blur)="changeDate()"
            type="date"
          />
          <button
            [disabled]="saving"
            mat-button
            *ngIf="EstimatedEndOutput"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="EstimatedEndOutput = null"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="!EstimatedEndOutput"
            >You must enter a date</mat-error
          >
        </mat-form-field>
      </div>

      <div class="row no-gutters">
        <mat-form-field appearance="outline" class="col-10">
          <mat-label>Maximum Temperature</mat-label>
          <input
            required
            [(ngModel)]="Temperature"
            matInput
            type="number"
            (keypress)="numericOnly($event)"
            formControlName="temperature"
          />
          <mat-error *ngIf="Temperature?.length == 0 || !Temperature"
            >You must enter a value</mat-error
          >
        </mat-form-field>
        <div class="col ml-2">
          <mat-radio-group
            [disabled]="saving"
            required
            [(ngModel)]="TemperatureUnit"
            [ngModelOptions]="{ standalone: true }"
            aria-labelledby="roof-access-label"
            aria-label="Roof Access"
            [value]="TemperatureUnit"
          >
            <mat-radio-button value="C">C</mat-radio-button>
            <mat-radio-button value="F">F</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <mat-form-field appearance="outline">
        <mat-label
          >How is temperature controlled? (Describe whether using variable
          transformer (variac), process controller, or otherwise)</mat-label
        >
        <textarea
          [disabled]="saving"
          [(ngModel)]="TemperatureNotes"
          [ngModelOptions]="{ standalone: true }"
          matInput
        ></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Additional Notes</mat-label>
        <textarea
          [disabled]="saving"
          [(ngModel)]="AdditionalNotes"
          [ngModelOptions]="{ standalone: true }"
          matInput
        ></textarea>
      </mat-form-field>
    </form>
  </div>
  <div class="card-footer">
    <div class="row no-gutters">
      <div class="col-6">
        <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      </div>
      <div class="col-6">
        <button
          mat-flat-button
          [disabled]="saving"
          color="primary"
          class="right-btn"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
