import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, Injector } from '@angular/core';
import { ReviewBoardType } from '../../reviews.models';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MenuItem } from 'src/app/components/head-page/head-menu/head-menu.models';
import { HeadMenuService } from 'src/app/components/head-page/head-menu/head-menu.service';
import { MenuItemRefresh } from 'src/app/components/head-page/store/menu-item.action';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'reviews-settings-menu',
  templateUrl: './reviews-settings-menu.component.html',
  styleUrls: ['./reviews-settings-menu.component.scss']
})
export class ReviewsSettingsMenuComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  reviewBoardType!: ReviewBoardType | null;

  menuItems!: MenuItem[];
  menuItems$!: Observable<MenuItem[]>;
  menuItemSubs!: Subscription;
  menuItem?: MenuItem;

  menuMessage!: string;

  constructor(
    protected override injector: Injector,
    private menu: HeadMenuService,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.menuItemSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    this.loadMenuItems();
  }

  loadMenuItems() {
    this.menuItems$ = this.store.select(state => state.MenuItem.data);
    this.menuItemSubs = this.menuItems$.subscribe(data => {
      this.menuItems = data;
      this.showButton();
      this.getMenuMessage();
    });
  }

  getMenuMessage() {
    if (!this.menuItem) {
      this.menuMessage = this.getMessage('CreateReviewMenuItem').description.replace('{reviewBoardTypeName}', '<b>' + this.reviewBoardType?.name + '</b>');
    }
    else {
      this.menuMessage = this.getMessage('RemoveReviewMenuItem').description.replace('{reviewBoardTypeName}', '<b>' + this.reviewBoardType?.name + '</b>');
    }
    return this.menuMessage;
  }

  showButton() {
    if (this.menuItems.length) {
      this.menuItem = this.menuItems.find(x => x.route == 'reviews/' + this.reviewBoardType?.id);
    }
    return this.menuItem != null;
  }

  add() {
    const menuItem = {
      id: 0,
      route: 'reviews/' + this.reviewBoardType?.id,
      name: this.reviewBoardType?.name,
      description: this.reviewBoardType?.description,
      code: this.reviewBoardType?.code,
      type: 1,
      order: 99,
      status: 1,
      menuValidation: 0,
      parentMenuItemID: 9,
    } as MenuItem;
    this.menu.createMenu(menuItem).toPromise().then(data => {
      this.store.dispatch(new MenuItemRefresh());
      this.alert.message('ReviewMenuItemCreated', [{ placeholder: '{reviewBoardTypeName}', value: this.reviewBoardType?.name }]);
    });
  }

  del() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('ReviewMenuItemDeleteConfirmation').description.replace('{reviewBoardTypeName}', this.reviewBoardType?.name ?? ''),
        icon: 'warn'
      }
    });
    confirm.afterClosed().toPromise().then(() => {
      if (this.menuItem?.id)
        this.menu.deleteMenu(this.menuItem.id).toPromise().then(() => {
          this.store.dispatch(new MenuItemRefresh());
          this.alert.message('ReviewMenuItemRemoved', [{ placeholder: '{reviewBoardTypeName}', value: this.reviewBoardType?.name }]);
        });
    });
  }

}
