import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Checklist, Annotation, AnnotationStatus } from '../../checklists';
import { Subscription } from 'rxjs/internal/Subscription';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';

@Component({
  selector: 'checklist-exec-review-summary',
  templateUrl: './checklist-exec-review-summary.component.html',
  styleUrls: ['./checklist-exec-review-summary.component.scss']
})
export class ChecklistExecReviewSummaryComponent implements OnInit, OnChanges, OnDestroy {

  @Input() currentChecklist?: Checklist | null;

  totalAnnotations!: number;
  activeAnnotations!: number;

  annotations!: Annotation[];
  annotations$!: Observable<Annotation[]>;
  annotationsSubs!: Subscription;


  formStatus = FormStatusEnum;
  constructor(
    private store: Store
  ) { }

  ngOnDestroy(): void {
    this.annotationsSubs?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.activeAnnotations = this.currentChecklist?.annotations?.filter(x => x.status == AnnotationStatus.Active).length ?? 0;
    this.totalAnnotations = this.currentChecklist?.annotations?.length ?? 0;
  }

  ngOnInit(): void {
    this.loadAnnotations();
  }

  loadAnnotations() {
    this.annotations$ = this.store.select(state => state.Annotations.data);
    this.annotationsSubs = this.annotations$.subscribe(data => {
      if (this.currentChecklist) {
        this.annotations = data.filter(x => x.checklistID == this.currentChecklist?.id);
        this.activeAnnotations = this.annotations?.filter(x => x.status == AnnotationStatus.Active).length;
        this.totalAnnotations = this.annotations?.length;
      }
    });
  }

}
