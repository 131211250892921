import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { RSSWAResource } from '../../../rsswa';
import { RsswaService } from '../../../rsswa.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { ChecklistBuilderPopupParameters, ChecklistExecPopupComponent } from '../../checklist-exec-popup/checklist-exec-popup.component';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { RSSFunctionalTest } from '../../../rss-functional-test';

@Component({
  selector: 'rsswa-section1-functional-tests',
  templateUrl: './rsswa-section1-functional-tests.component.html',
  styleUrls: ['./rsswa-section1-functional-tests.component.scss']
})
export class RsswaSection1FunctionalTestsComponent extends BaseComponent implements OnInit {
  @Input() rsswa?: RSSWAResource | null;
  @Input() disabled: boolean = true;

  @Input() chkFuncTestCtrl!: FormControl<any>;
  @Output() chkFuncTestCtrlChange = new EventEmitter<FormControl>();

  @Input() functionalTestCtrl!: FormControl<any>;
  @Output() functionalTestCtrlChange = new EventEmitter<FormControl>();

  @Input() provisionalTestingRequiredCtrl!: FormControl<any>;
  @Output() provisionalTestingRequiredCtrlChange = new EventEmitter<FormControl>();

  @Output() testCreated = new EventEmitter();

  rssFunctionalTestCreatePrivilege?: boolean | null = false;

  tmpProvisionalTestingRequired?: string;

  constructor(
    protected override injector: Injector,
    public service: RsswaService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.rssFunctionalTestCreatePrivilege = this.hasPrivilege(PrivilegeEnum.RSSFunctionalTestCreate);
  }

  confirmRSSFunctionalTest(e: MatCheckboxChange) {
    if (e.checked) {
      this.dialog.open(YesNoDialogComponent, {
        width: '500px',
        data: {
          message: 'You will need to add at least one RSS functional test, do you want to continue?',
        },
      }).afterClosed().subscribe(data => {
        this.chkFuncTestCtrl.setValue(data);
      });
    }
    else this.chkFuncTestCtrl.setValue(false);
  }

  createRSSFunctionalTest() {
    this.dialog.open(ChecklistExecPopupComponent, {
      width: '80vw',
      data: {
        rsswa: this.rsswa,
        amendment: this.service.showAmendment,
      } as ChecklistBuilderPopupParameters,
    }).afterClosed().toPromise().then(result => {
      if (result && this.rsswa) {
        this.rsswa.rssFunctionalTests?.push(result);
        this.testCreated.emit();
      }
    });
  }

  edit(test: RSSFunctionalTest) {
    this.dialog.open(ChecklistExecPopupComponent, {
      width: '80vw',
      data: {
        rsswa: this.rsswa,
        amendment: this.service.showAmendment,
        rssFunctionalTest: test
      } as ChecklistBuilderPopupParameters,
    }).afterClosed().toPromise().then(result => {
      // if (result && this.rsswa) {
      //   this.rsswa.rssFunctionalTests?.push(result);
      //   this.testCreated.emit();
      // }
    });
  }

  showAlert(e: any) {
    this.alert.error(e);
  }

  remove(id: number) {
    const index = this.rsswa?.rssFunctionalTests?.findIndex(t => t.id == id) as number;
    if (index >= 0) {
      this.rsswa?.rssFunctionalTests?.splice(index, 1);
    }
  }

}
