import { ResourceRelation } from "src/app/components/catalogs/beamline-catalog/resource/resources";


export class ResourceRelationsRefresh {
  static readonly type = '[ResourceRelations] Refresh';
}

export class ResourceRelationsCreate {
  static readonly type = '[ResourceRelations] Create';

  constructor(public item: ResourceRelation) { }
}

export class ResourceRelationsUpdate {
  static readonly type = '[ResourceRelations] Update';

  constructor(public id: number, public item: ResourceRelation) { }
}

export class ResourceRelationsDelete {
  static readonly type = '[ResourceRelations] Delete';
  constructor(public id: number) { }
}
