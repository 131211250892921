import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { TOCA } from 'src/app/components/catalogs/beamline-catalog/resource/toca';

@Component({
  selector: 'app-toca',
  templateUrl: './toca.component.html',
  styleUrls: ['./toca.component.scss']
})
export class TocaComponent extends BaseComponent implements OnInit {

  toca!: TOCA | null;
  tocas!: TOCA[];
  tocas$!: Observable<TOCA[]>;
  tocasSubs!: Subscription;
  tocasFiltered!: TOCA[];
  tocasCtrl = new FormControl();

  tmpTocas: TOCA[];

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<TocaComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: {
      toca: TOCA,
      dialogTitle: string,
      inputTitle: string,
      tocas: TOCA[]
    }) {
    super(injector);

    this.toca = inputData.toca;
    this.tmpTocas = inputData.tocas;
  }

  ngOnInit() {
    this.tocas$ = this.store.select(state => state.TOCA.data);
    this.tocasSubs = this.tocas$.subscribe(data => {
      this.tocasCtrl.setValue(this.toca);
      this.tocas = data.filter(x => !this.tmpTocas?.map(t => t.name.toLowerCase()).includes(x.name.toLowerCase()) && x.isActive);
      this.tocas = this.tocas.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a?.name?.toLowerCase(), b?.name?.toLowerCase(), null, true));
      this.tocasFiltered = this.tocas.filter(x => !x.wasDelete);
    });
  }

  add() {
    if (this.toca?.id && this.tocasCtrl.value) {
      this.inputData.toca = this.toca;
      this.dialogRef.close(this.inputData.toca);
    }
    else {
      if (typeof this.tocasCtrl.value == 'string') {
        if (this.tmpTocas.find(x => x.name.toLowerCase() == (this.tocasCtrl.value as string).toLowerCase())) {
          this.alert.message('TOCA_AlreadyOnList');
        }
        else if (this.tocasFiltered?.length > 0 && this.tocasFiltered[0].name.toLowerCase() == this.tocasCtrl.value.toLowerCase()) {
          this.alert.message('TOCA_AlreadyExists');
        }
        else if (this.tocasCtrl.value.toLowerCase() != this.toca?.name?.toLowerCase()) {
          this.dialog.open(YesNoDialogComponent, {
            width: '500px',
            data: {
              message: this.getMessage('TOCA_CreateNew').description.replace('{toca}', this.tocasCtrl.value as string),
            },
          }).afterClosed().subscribe(data => {
            if (data) {
              const toca = {
                id: 0,
                name: (this.tocasCtrl.value as string)
              } as TOCA;
              this.inputData.toca = toca;
              this.dialogRef.close(this.inputData.toca);
            }
          });
        }
      }
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  filter(e: any) {
    const text = e.target.value;
    this.tocasFiltered = this.tocas?.filter(x => !x.wasDelete && x.name.toLowerCase().includes(text.toLowerCase()));
    if (this.tocasFiltered.length) {
      this.toca = this.tocasFiltered[0];
    }
    else this.toca = null;
  }

  selectedToca(e: MatAutocompleteSelectedEvent) {
    this.toca = e.option.value;
  }

}
