<div class="card">
  <div class="card-header">
    <span class="card-title"
      >{{ currentMeterType.id ? "Edit " : "Add " }}Meter Type</span
    >
  </div>
  <div class="card-body">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input
        matInput
        [(ngModel)]="currentMeterType.name"
        [disabled]="!(adding || modifying) || selected"
        name="name"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Description</mat-label>
      <input
        matInput
        [(ngModel)]="currentMeterType.description"
        [disabled]="!(adding || modifying)"
        name="description"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Status</mat-label>
      <mat-select
        [(ngModel)]="currentMeterType.status"
        [disabled]="!(adding || modifying) || disabledStatus"
        name="status"
      >
        <mat-option *ngFor="let RCstatus of statusList" [value]="RCstatus.id">
          {{ RCstatus.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        color="warn"
        (click)="onNoClick()"
        class="left-btn"
        style="width: 100px"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="disabledTimer"
        (click)="saveMeterType()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
