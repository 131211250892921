import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ReviewBoardType, ReviewPermission } from '../../reviews.models';
import { Subscription } from 'rxjs/internal/Subscription';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'reviews-settings-permissions',
  templateUrl: './reviews-settings-permissions.component.html',
  styleUrls: ['./reviews-settings-permissions.component.scss']
})
export class ReviewsSettingsPermissionsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() reviewBoardType!: ReviewBoardType;

  reviewPermissions!: ReviewPermission[];
  reviewPermissions$!: Observable<ReviewPermission[]>;
  reviewPermissionsSubs!: Subscription;

  constructor(
    private store: Store
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadReviewPermissions();
  }

  ngOnDestroy(): void {
    this.reviewPermissionsSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.loadReviewPermissions();
  }

  loadReviewPermissions() {
    this.reviewPermissions$ = this.store.select(state => state.ReviewPermissions.data);
    this.reviewPermissionsSubs = this.reviewPermissions$.subscribe(data => {
      this.reviewPermissions = data.filter(x => x.reviewBoardTypeID == this.reviewBoardType.id);
    });
  }

}
