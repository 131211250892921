<div class="mat-table-container mb--10">
  <div class="mat-container scrollbar list">
    <mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="sortTable($event)"
    >
      <ng-container matColumnDef="serialNo">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Version
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          nowrap
          [ngClass]="getClass(element.code)"
        >
          <span class="version-text" [matTooltip]="element.serialNo">{{
            element.serialNo
          }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="statusName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </mat-header-cell>
        <mat-cell
          class="pl-1"
          *matCellDef="let element"
          [ngClass]="getClass(element.code)"
        >
          {{ element.statusName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="resourceName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Resource
        </mat-header-cell>
        <mat-cell
          class="bold"
          *matCellDef="let element"
          [ngClass]="getClass(element.code)"
        >
          {{ element.resourceString }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="procedureNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Procedure No.
        </mat-header-cell>
        <mat-cell
          class="bold"
          *matCellDef="let element"
          [ngClass]="getClass(element.code)"
        >
          {{ element.procedure?.procedureNumber }}
          <span class="small" *ngIf="element.procedure"
            >&nbsp;{{ " Rev." + element.procedure?.revision }}</span
          >
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Type
        </mat-header-cell>
        <mat-cell
          class="pl-1"
          *matCellDef="let element"
          [ngClass]="getClass(element.code)"
        >
          {{ element.templateType?.name }}
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        #matrow
        *matRowDef="let row; columns: displayedColumns"
        (click)="rowClicked(row)"
        style="white-space: nowrap"
        [ngClass]="{ 'mat-row-highlight': selectedRowIndex == row.id }"
        [attr.position]="getPosition(row)"
      ></mat-row>
    </mat-table>
  </div>
</div>
