<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-2">
        <h6>{{ number ?? "#" }}</h6>
      </div>
      <div class="col-9 pt-2">
        <div [innerHTML]="questionSafe" class="mb-2"></div>
        <div class="mat-table-container">
          <div class="mat-container">
            <mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="ehs">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >EHS#</mat-header-cell
                >
                <mat-cell
                  class="font-weight-bold"
                  *matCellDef="let element"
                  nowrap
                >
                  {{ element.ehs }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="locationType">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Location Type
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.radLocation?.radLocationType?.name }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="location">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Location</mat-header-cell
                >
                <mat-cell class="wrap" *matCellDef="let element">
                  {{ element.radLocation?.name }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Description</mat-header-cell
                >
                <mat-cell class="wrap" *matCellDef="let element">
                  {{ element.description }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="dueDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Calibration Due Date</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let element"
                  nowrap
                  class="bold"
                  [ngClass]="element.cssClass"
                >
                  {{ element.calDueDate | date : "shortDate" }}
                </mat-cell>
              </ng-container>
              <!--
              <ng-container matColumnDef="actions" *ngIf="canModify()">
                <mat-header-cell *matHeaderCellDef>
                  <button
                    class="add"
                    mat-icon-button
                    (click)="add()"
                    [matTooltip]="'Add Monitor'"
                  >
                    <mat-icon color="accent">add</mat-icon>
                  </button>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <div *ngIf="canModify()">
                    <button
                      mat-icon-button
                      color="warn"
                      [matTooltip]="'Delete Monitor'"
                      (click)="delete(element)"
                    >
                      <mat-icon class="icon">delete</mat-icon>
                    </button>
                  </div>
                </mat-cell>
              </ng-container> -->

              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                #matrow
                *matRowDef="let row; columns: displayedColumns"
                class="table-row"
                style="white-space: nowrap"
              ></mat-row>
            </mat-table>
          </div>
        </div>
      </div>
      <div class="col-2 d-flex flex-row justify-content-end align-items-center">
        <mat-checkbox-with-roles
          *ngIf="checkboxVisible"
          [labelPosition]="'before'"
          [checked]="checked"
          [disabled]="disabled || globalStatus == 2"
          (change)="checkboxChanged($event)"
          [color]="color"
          [roleCodes]="roleCodes"
          [user]="user"
          [warning]="
            showWarning
              ? globalStatus == 2
                ? 'Expired'
                : 'Close to Expire'
              : ''
          "
          [date]="date"
        >
        </mat-checkbox-with-roles>
      </div>
    </div>
  </div>
</div>
