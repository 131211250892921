<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-6">
        <span class="card-title">Rosters</span>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline" class="no-subscript">
          <mat-label>Roster</mat-label>
          <input
            type="text"
            placeholder="Select Roster"
            matInput
            name="rosterCtrl"
            [disabled]="!addingCategory && !addingMember"
            [formControl]="rosterCtrl"
            [matAutocomplete]="autoRoster"
            (keyup)="changedRoster($event)"
          />
          <mat-autocomplete
            #autoRoster="matAutocomplete"
            #rosterAutocomplete
            [displayWith]="displayObject"
            (optionSelected)="selectedReviewAttendanceSave($event)"
          >
            <mat-option
              *ngFor="let roster of reviewAttendanceSavedFiltered"
              [value]="roster"
            >
              <span>{{ roster.name }}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="card-body">
    <form #form="ngForm">
      <div class="row title-row">
        <div
          class="col-3 d-flex flex-row justify-content-between align-items-center"
        >
          <h6>Category</h6>
          <button
            *ngIf="!addingCategory && !addingMember"
            mat-icon-button
            matTooltip="Add Category"
            (click)="addCategory()"
          >
            <mat-icon color="accent">add_box</mat-icon>
          </button>
        </div>
        <div class="col-9 d-flex align-items-center justify-content-center">
          <h6>Members</h6>
        </div>
      </div>
      <div class="cat-container scrollbar">
        <div
          class="row category mb-2"
          *ngFor="let category of reviewAttendanceCategoriesFiltered"
        >
          <div class="col-3 d-flex flex-row justify-content-between pr-0">
            <h6 *ngIf="category.id">{{ category.name }}</h6>
            <mat-form-field
              appearance="outline"
              class="no-subscript"
              *ngIf="!category.id"
            >
              <mat-label>Select Category</mat-label>
              <input
                type="text"
                placeholder="Select Category"
                matInput
                name="categoryCtrl"
                [formControl]="categoryCtrl"
                [matAutocomplete]="autoCategory"
              />
              <mat-autocomplete
                #autoCategory="matAutocomplete"
                #categoryAutocomplete
                [displayWith]="displayObject"
                (optionSelected)="selectedCategory($event)"
              >
                <mat-option
                  *ngFor="let category of reviewAttendanceCategoriesToAdd"
                  [value]="category"
                >
                  <span>{{ category.name }}</span>
                </mat-option>
              </mat-autocomplete>
              <button
                class="dd-button"
                mat-icon-button
                matSuffix
                color="warn"
                (click)="cancelAddCategory(); $event.stopPropagation()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <button
              mat-icon-button
              class="float-right"
              matTooltip="Add member"
              *ngIf="category.id && !addingMember && !addingCategory"
              (click)="setAdding(category)"
            >
              <mat-icon color="accent">add_box</mat-icon>
            </button>
            <button
              mat-icon-button
              class="float-right"
              matTooltip="Remove Category"
              *ngIf="
                category.id &&
                !category.reviewAttendanceRosters?.length &&
                !addingMember
              "
              (click)="deleteCategory(category)"
            >
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </div>
          <div class="col-9">
            <div
              class="row no-gutters"
              *ngFor="let roster of getRoster(category)"
            >
              <div class="col-5">
                <role-selection
                  [roles]="roster.role ? [roster.role] : []"
                  [disabled]="true"
                  [label]="'Role'"
                  [noSubscript]="true"
                  [showNames]="true"
                ></role-selection>
              </div>
              <div class="col-6 px-1">
                <mat-form-field
                  class="no-subscript"
                  appearance="outline"
                  [disabled]="true"
                >
                  <mat-label>Member</mat-label>
                  <input
                    matInput
                    type="text"
                    [ngModel]="roster.name"
                    [disabled]="true"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </mat-form-field>
              </div>
              <div class="col-1">
                <button
                  mat-icon-button
                  (click)="deleteMember(roster)"
                  *ngIf="!addingMember && !addingCategory"
                >
                  <mat-icon color="warn">delete</mat-icon>
                </button>
              </div>
            </div>
            <div class="row no-gutters" *ngIf="addingMember == category.id">
              <div class="col-5">
                <role-selection
                  [disabled]="!!role"
                  [label]="'Role'"
                  [noSubscript]="true"
                  [showNames]="true"
                  (selected)="selectedRole($event, category)"
                ></role-selection>
              </div>
              <div class="col-6 px-1">
                <mat-form-field
                  appearance="outline"
                  class="no-subscript"
                  *ngIf="this.role"
                >
                  <mat-label>New Member</mat-label>
                  <input
                    type="text"
                    placeholder="Select Member"
                    matInput
                    name="memberCtrl"
                    [formControl]="memberCtrl"
                    [matAutocomplete]="autoMember"
                    (keyup)="changedMember($event, category)"
                  />
                  <mat-autocomplete
                    #autoMember="matAutocomplete"
                    #memberAutocomplete
                    [displayWith]="displayObject"
                    (optionSelected)="selectedMember($event, category)"
                  >
                    <mat-option
                      *ngFor="let user of usersFiltered"
                      [value]="user"
                    >
                      <span>{{ user.name }}</span>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-1">
                <button mat-icon-button (click)="cancelAddMember()">
                  <mat-icon color="warn">cancel</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-stroked-button color="warn" (click)="cancel()">Cancel</button>
      <button
        class="float-right"
        mat-flat-button
        color="primary"
        (click)="save()"
        [disabled]="!changed()"
      >
        Save
      </button>
    </div>
  </div>
</div>
