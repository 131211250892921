import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Accelerator } from 'src/app/components/catalogs/beamline-catalog/resource/resources';

@Injectable({
  providedIn: 'root'
})
export class AcceleratorService {

  constructor(private http: HttpClient) { }
  getAcceleratorStatus(): Observable<Accelerator[]> {
    return this.http.get<Accelerator[]>(environment.urls.baseApiUrl + '/AcceleratorStatus');
  }
}
