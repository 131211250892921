import { ENTER, TAB } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DataButtonSignature } from 'src/app/common/models/data-button-signature';
import { Tooltip } from 'src/app/common/tooltip';
import { Checklist } from 'src/app/components/checklists/checklists';
import { utils } from 'src/app/modules/libs/utils';
import { MessageBanner } from 'src/app/components/messages/services/message-banner';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { WfSignature } from 'src/app/services/work-flow/work-flow';
import { Option } from 'src/app/components/checklists/checklists';
import { KEResource } from 'src/app/components/kenable/ke';
import { AbsiResource } from 'src/app/components/absi/absi';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'cl-base',
  templateUrl: './cl-base.component.html',
  styleUrls: ['./cl-base.component.scss']
})
export class ClBaseComponent extends BaseComponent implements OnDestroy {

  @Input() title?: string | null;
  @Input() textBefore?: string | null;
  @Input() textAfter?: string | null;
  @Input() textAfter2?: string | null;
  @Input() valueString?: string | null;
  @Input() roleIds?: number[] | null;
  @Input() question?: string | null;

  @Input() roleCodes?: string;
  @Input() disabled?: boolean;
  @Input() disableEdit?: boolean;

  @Input() checked?: boolean;
  @Input() color!: string;
  @Input() number?: string | null;
  @Input() checkboxVisible?: boolean = true;
  @Input() warning?: boolean;
  @Input() user?: User | null;
  @Input() moreInfo?: string | null;
  @Input() editor?: boolean;
  @Input() builder?: boolean;
  @Input() checklist?: Checklist | KEResource | AbsiResource | null;
  @Input() date?: Date | null;
  @Input() tooltips?: Tooltip[];
  @Input() userNames?: string;

  @Input() value?: boolean;
  @Input() textValue?: string | null;

  @Input() showApprove?: boolean;
  @Input() showUnapprove?: boolean;
  @Input() showDisapprove?: boolean;
  @Input() showCancel?: boolean;

  @Input() status!: number;
  @Input() cancelID?: number;
  @Input() taskID?: number;
  @Input() enabled?: boolean;
  @Input() message?: MessageBanner;

  @Input() radioQuestion?: string | null;
  @Input() radioOptions?: Option[];
  @Input() radioSelection?: number;

  @Input() configuration?: string;
  @Input() signature?: WfSignature;

  @Input() dateValue1?: Date;
  @Input() dateValue2?: Date;
  @Input() dateValue3?: Date | null;
  @Input() scheduleResourceId?: number;

  @Input() formId?: number;
  @Input() sectionNumber?: number;
  @Input() sectionId?: number;

  @Output() answered = new EventEmitter<any>();
  @Output() save = new EventEmitter<any>();
  @Output() approve = new EventEmitter<any>();
  @Output() disapprove = new EventEmitter<DataButtonSignature>();
  @Output() unapprove = new EventEmitter<any>();
  @Output() radioSelected = new EventEmitter<any>();
  @Output() changed = new EventEmitter<any>();

  @Input() text?: string | null;
  @Input() label?: string | null;

  @Input() formType?: string | number;
  @Input() serialNo?: string;

  @Input() options?: any[];

  public privilege = PrivilegeEnum;
  public initials?: string | null;

  valueObjects: any[] = [];


  saveValues(values: any) {
    this.save.emit(utils.JSONstringify(values));
  }

  getValueObjects(valueString?: string | null) {
    let valueObjects = this.utils.JSONparse(valueString);
    if (valueObjects?.[0]?.stringValue) {
      valueObjects = this.utils.JSONparse(valueObjects?.[0]?.stringValue);
      if (typeof valueObjects == 'string')
        valueObjects = this.getValueObjects(valueObjects);
    }
    return valueObjects;
  }

}
