import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TransactionLog } from './transaction-logs';

@Injectable({
  providedIn: 'root'
})
export class TransactionLogService {
  constructor(private _http: HttpClient) {
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  public Post(log: TransactionLog) {
    var result = this._http.post(environment.urls.baseApiUrl + '/TransactionLogs', log)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  public UpdateStatus(logID: number) {
    return this._http.put(environment.urls.baseApiUrl + '/TransactionLogs/' + logID, null);
  }

  public GetBySerialNo(serialNo: string) {
    return this._http.get<TransactionLog[]>(environment.urls.baseApiUrl + '/TransactionLogs/BySerialNo/' + serialNo);
  }
}
