import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CanDeactivateResponse } from './pending-changes-dialog-response';

@Component({
  selector: 'pending-changes-dialog',
  templateUrl: './pending-changes-dialog.component.html',
  styleUrls: ['./pending-changes-dialog.component.scss']
})
export class PendingChangesDialogComponent implements OnInit {

  constructor(
    public dialog: MatDialogRef<PendingChangesDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialog.close(CanDeactivateResponse.Cancel);
  }

  discard() {
    this.dialog.close(CanDeactivateResponse.Discard);
  }

}
