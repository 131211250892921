import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { WorkPerformer } from './work-performer';

@Injectable({
  providedIn: 'root'
})
export class WorkPerformerService {
  url = environment.urls.baseApiUrl + '/WorkPerformers';
  constructor(private http: HttpClient) { }

  Read(): Observable<WorkPerformer[]> {
    return this.http.get<WorkPerformer[]>(this.url);
  }

  ReadOne(id: number): Observable<WorkPerformer> {
    if (id == null) { id = 0 }
    return this.http.get<WorkPerformer>(this.url + '/' + id.toString());
  }

  ReadByIdAndType(id: number, type: number): Observable<WorkPerformer> {
    if (id == null) { id = 0 }
    return this.http.get<WorkPerformer>(this.url + '/' + type.toString() + '/' + id.toString());
  }
}
