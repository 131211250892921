export class PPSTBOtherSystem {
  public id?: number;
  public ppstbID?: number;
  public text?: string;
}

export class PPSTBOtherSystemSave {
  public id?: number;
  public ppstbID?: number;
  public text?: string;
}

export class PPSTBOtherSystemHandler {
  static toSave(ppstbOtherSystem: PPSTBOtherSystem): PPSTBOtherSystemSave {
    var save = new PPSTBOtherSystemSave();

    save.id = ppstbOtherSystem.id;
    save.ppstbID = ppstbOtherSystem.ppstbID;
    save.text = ppstbOtherSystem.text;

    return save;
  }
}
