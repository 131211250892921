import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ReviewAttendanceSave, ReviewAttendanceCategory } from '../../../../../../../reviews.models';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'load-saved-roster-left',
  templateUrl: './load-saved-roster-left.component.html',
  styleUrls: ['./load-saved-roster-left.component.scss']
})
export class LoadSavedRosterLeftComponent implements OnInit, OnChanges {

  @Input() reviewAttendanceSave?: ReviewAttendanceSave | null;

  reviewCategories?: ReviewAttendanceCategory[];
  public displayedColumns: string[] = ['role', 'member'];
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.reviewCategories = [];
    this.reviewAttendanceSave?.reviewAttendanceSavedList?.map(x => {
      if (!this.reviewCategories?.map(c => c.id).includes(x.categoryID)) {
        if (x.category)
          this.reviewCategories?.push(x.category);
      }
    });
    this.reviewCategories = this.reviewCategories.sort((a, b) => utils.sort(a.order, b.order));
  }

  ngOnInit(): void {

  }

  getReviewMembersByCategory(reviewCategory: ReviewAttendanceCategory) {
    return this.reviewAttendanceSave?.reviewAttendanceSavedList.filter(x => x.categoryID == reviewCategory?.id) ?? [];
  }

}
