<div
  *ngIf="getRoles() != null"
  [ngClass]="{
    'signatures-add': userCanBeAdded(),
    'signatures-del': userCanBeRemoved()
  }"
  class="signatures"
>
  <div class="d-flex align-items-center">
    <span>{{ title }}</span>
    <role-chips class="role-labels" [roleCodes]="getRoles()"></role-chips>
  </div>
  <div class="d-flex justify-content-center">
    <span
      class="user"
      [ngClass]="{
        'user-ok': signature.signedOn,
        self: signature.userID == getCurrentUser()?.id && !signature.signedOn,
        'self-ok':
          signature.userID == getCurrentUser()?.id && signature.signedOn,
        'user-rem':
          signature.userID != getCurrentUser()?.id &&
          !signature.signedOn &&
          userCanBeRemoved(signature) &&
          (isPreparer() || isApprover())
      }"
      *ngFor="let signature of signatures"
    >
      <span [matTooltip]="signature.user?.name ?? ''">{{
        signature.user?.initials
      }}</span>
      <span
        class="small"
        *ngIf="signature.signedOn"
        [matTooltip]="(signature.signedOn | date : 'medium') ?? ''"
      >
        <mat-icon class="green check">check</mat-icon>
      </span>
      <span
        class="small"
        *ngIf="
          userCanBeRemoved(signature) &&
          (getCurrentUser()?.id == signature.userID ||
            isPreparer() ||
            isApprover())
        "
        (click)="remove(signature)"
      >
        <mat-icon class="user-icon user-icon-del red">person_remove</mat-icon>
      </span>
    </span>
    <mat-icon
      class="user-icon green"
      *ngIf="userCanBeAdded()"
      (click)="add()"
      [matTooltip]="'Subscribe yourself'"
      >person_add</mat-icon
    >
    <mat-icon
      class="user-icon blue"
      *ngIf="otherUserCanBeAdded() && (isPreparer() || isApprover())"
      (click)="addOther()"
      [matTooltip]="'Subscribe others'"
      >person_add</mat-icon
    >
  </div>
</div>
