import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReviewsSettingsComponent } from '../../reviews-settings.component';
import { ReviewBoardType, ReviewType, ReviewTypesEnum } from '../../../reviews.models';
import { FormControl, FormGroup } from '@angular/forms';
import { TemplateType } from '../../../../checklists/checklists';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { Store } from '@ngxs/store';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'reviews-settings-review-types-details',
  templateUrl: './reviews-settings-review-types-details.component.html',
  styleUrls: ['./reviews-settings-review-types-details.component.scss']
})
export class ReviewsSettingsReviewTypesDetailsComponent implements OnInit, OnDestroy {

  reviewBoardType: ReviewBoardType;
  reviewType: ReviewType;

  reviewTypeNameCtrl = new FormControl();
  reviewTypeDescriptionCtrl = new FormControl();
  reviewTypeCodeCtrl = new FormControl();
  templateTypeCtrl = new FormControl();
  reviewTypeCtrl = new FormControl();
  reviewTypeLifeSpanCtrl = new FormControl();
  reviewTypeEnum: ReviewTypesEnum;

  templateTypes!: TemplateType[];
  templateTypesFiltered!: TemplateType[];
  templateTypes$!: Observable<TemplateType[]>;
  templateTypesSubs!: Subscription;

  reviewTypes!: ReviewType[];
  reviewTypesFiltered!: ReviewType[];
  reviewTypes$!: Observable<ReviewType[]>;
  reviewTypesSubs!: Subscription;

  form!: FormGroup;
  title: string;
  disabled!: boolean;

  hasNextReview!: boolean;
  isActive?: boolean;

  constructor(
    private store: Store,
    public dialog: MatDialogRef<ReviewsSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reviewBoardType = data.reviewBoardType;
    this.title = data.title;
    this.reviewType = data.reviewType;
    this.reviewTypeEnum = data.reviewTypeEnum;
  }

  ngOnDestroy(): void {
    this.reviewTypesSubs?.unsubscribe();
    this.templateTypesSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.buildForm();
    if (!this.reviewType) {
      this.reviewType = { id: 0, reviewBoardTypeID: this.reviewBoardType.id, status: 1, type: this.reviewTypeEnum } as ReviewType;
    }
    this.loadTemplateTypes();
    this.loadReviewTypes();
    this.reviewTypeNameCtrl.setValue(this.reviewType?.name);
    this.reviewTypeDescriptionCtrl.setValue(this.reviewType?.description);
    this.reviewTypeCodeCtrl.setValue(this.reviewType?.code);
    this.reviewTypeCtrl.setValue(this.reviewTypesFiltered.find(x => x.id === this.reviewType.nextReviewTypeID));
    this.hasNextReview = this.reviewTypesFiltered.find(x => x.id === this.reviewType.nextReviewTypeID) != null;
    this.reviewTypeLifeSpanCtrl.setValue(this.reviewType?.lifeSpan);
    this.isActive = this.reviewType.status == 1;
  }

  loadTemplateTypes() {
    this.templateTypes$ = this.store.select(state => state.TemplateTypes.data);
    this.templateTypesSubs = this.templateTypes$.subscribe(data => {
      this.templateTypes = data.filter(x => x.documentTypeID === 200);
      this.templateTypesFiltered = this.templateTypes;
      this.templateTypeCtrl.setValue(this.templateTypes.find(t => t.id === this.reviewType?.templateTypeID));
    });
  }

  loadReviewTypes() {
    this.reviewTypes$ = this.store.select(state => state.ReviewTypes.data);
    this.reviewTypesSubs = this.reviewTypes$.subscribe(data => {
      this.reviewTypes = data.filter(x => x.id !== this.reviewType.id);
      this.reviewTypesFiltered = this.reviewTypes;
    });
  }

  buildForm() {
    this.form = new FormGroup({
      reviewTypeNameCtrl: this.reviewTypeNameCtrl,
      reviewTypeDescriptionCtrl: this.reviewTypeDescriptionCtrl,
      reviewTypeCodeCtrl: this.reviewTypeCodeCtrl,
      templateTypeCtrl: this.templateTypeCtrl,
      reviewTypeCtrl: this.reviewTypeCtrl,
      reviewTypeLifeSpanCtrl: this.reviewTypeLifeSpanCtrl
    });
  }

  changedTemplateType(e: any) {
    this.templateTypesFiltered = this.templateTypes.filter(x => x.name?.toLowerCase().includes(e.target.value) || x.code?.toLowerCase().includes(e.target.value));
    if (this.templateTypesFiltered.length == 0) { e.target.value = null; }
  }

  displayObject(e: any) {
    return e ? e.name : null;
  }

  changeStatus(e: any) {
    this.reviewType.status = e.checked ? 1 : 0;
    this.form.markAsDirty();
  }

  save() {
    this.reviewType.name = this.reviewTypeNameCtrl.value;
    this.reviewType.description = this.reviewTypeDescriptionCtrl.value;
    this.reviewType.code = this.reviewTypeCodeCtrl.value;
    this.reviewType.type = this.reviewTypeEnum;
    this.reviewType.nextReviewTypeID = this.reviewTypeCtrl.value?.id;
    this.reviewType.lifeSpan = this.reviewTypeLifeSpanCtrl.value;
    this.reviewType.templateTypeID = this.templateTypeCtrl.value ? this.templateTypeCtrl.value.id : null;
    this.data.reviewType = this.reviewType;
    this.dialog.close(this.data);
  }

  cancel() {
    this.form.reset();
    this.dialog.close();
  }

  formValid() {
    return !utils.isNullValue(this.reviewTypeNameCtrl.value) && !utils.isNullValue(this.reviewTypeDescriptionCtrl.value) && !utils.isNullValue(this.reviewTypeCodeCtrl.value) && (this.reviewTypeLifeSpanCtrl.value == null || this.reviewTypeLifeSpanCtrl.value == '' || parseInt(this.reviewTypeLifeSpanCtrl.value, 10));
  }

  hasNextReviewChanged(e: any) {
    if (!e.checked) {
      this.reviewType.nextReviewTypeID = 0;
      this.reviewTypeCtrl.setValue(null);
    }
    this.form.markAsDirty();
  }


  changedReviewType(e: any) {
    const value = e.target.value;
    this.reviewTypesFiltered = this.reviewTypes.filter(x => x.name?.toLowerCase().includes(value.toLowerCase()) || x.code?.toLowerCase().includes(value.toLowerCase()));
  }
}
