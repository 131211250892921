import { Component, HostListener, AfterContentInit, OnDestroy, OnInit, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalAddToBBComponent } from './modal-add-to-bb/modal-add-to-bb.component';
import { ModalHistoryBBComponent } from './modal-history-bb/modal-history-bb.component';
import { Observable, Subscription } from 'rxjs';
import { BulletinBoardItem } from './bulletin-board-item';
import { BulletinBoardService } from './bulletin-board-service.service';
import { BaseComponent } from 'src/app/common/base/base.component';
@Component({
  selector: 'app-bulletin-board',
  templateUrl: './bulletin-board.component.html',
  styleUrls: ['./bulletin-board.component.scss']
})
export class BulletinBoardComponent extends BaseComponent implements OnInit, OnDestroy {

  public bulletinBoardItems$!: Observable<BulletinBoardItem[]>;

  public bulletinBoardItemSubscription: Subscription = new Subscription;
  public currentUserSubscription: Subscription = new Subscription;

  public activesHistory: BulletinBoardItem[] = [];

  public nowdate: Date = new Date();

  public maxHeight = '0px';

  constructor(
    public bulletinBoardService: BulletinBoardService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getBulletinBoardItems();
    this.getCurrentUser();
  }

  ngAfterViewInit(): void {
    this.responsiveFunction();
  }

  override ngOnDestroy(): void {
    this.bulletinBoardItemSubscription?.unsubscribe();
  }

  getBulletinBoardItems() {
    this.bulletinBoardItems$ = this.store.select(state => state.BulletinBoard.bulletinBoardItems);
    this.bulletinBoardItemSubscription = this.bulletinBoardItems$.subscribe(bulletinBoardItems => {
      if (bulletinBoardItems?.length)
        this.activesHistory = bulletinBoardItems?.filter(bb => bb.status === 1);
    });
  }

  seeMore(event: any, item: BulletinBoardItem) {
    if (item.description.length > 250) {
      if (event.target.nodeName === 'A') {
        event = event.target.parentNode;
      } else if (event.target.nodeName === 'SPAN') {
        event = event.target;
      } else if (event.target.nodeName === 'P') {
        event = event.target.firstElementChild;
      }
      if (event.lastChild.nodeName === 'A') {
        event.innerHTML = item.description.split('\n').join('<br>');
        event.className = event.className.trim() + ' OverflowVisible';
      } else {
        event.innerHTML = item.description.substring(0, 250) + '<a href="#">... See more</a>';
        event.classList.remove('OverflowVisible');
      }
    }
  }

  refresh() {
    this.responsiveFunction();
  }

  PinBB(item: BulletinBoardItem) {
    const ItemBackup = JSON.stringify(item);
    const itemToSave: BulletinBoardItem = JSON.parse(ItemBackup);
    itemToSave.isPined = true;
    this.bulletinBoardService.updateBBI(itemToSave).toPromise().then(() => {
      // this.store.dispatch(new BulletinBoardRefresh());
    });
  }

  UnPinBB(item: BulletinBoardItem) {
    const ItemBackup = JSON.stringify(item);
    const itemToSave: BulletinBoardItem = JSON.parse(ItemBackup);
    itemToSave.isPined = false;
    this.bulletinBoardService.updateBBI(itemToSave).toPromise().then(() => {
      // this.store.dispatch(new BulletinBoardRefresh());
    });
  }

  ArchiveBB(item: BulletinBoardItem) {
    const self = this;
    if (item.id)
      this.bulletinBoardService.archiveBBI(item.id).toPromise().then(() => {
        // this.store.dispatch(new BulletinBoardRefresh());
      });
  }

  AddToBB() {
    this.dialog.open(ModalAddToBBComponent, {
      maxWidth: '500px'
    });
  }

  EditBB(item: BulletinBoardItem) {
    this.bulletinBoardService.selectedItem = item;
    this.dialog.open(ModalAddToBBComponent, {
      maxWidth: '500px'
    });
  }

  BBHistory() {
    this.dialog.open(ModalHistoryBBComponent, {
      maxHeight: '500px'
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
    this.maxHeight = (parseInt((this.innerHeight * 0.50).toFixed(0), 10) - 100) + 'px';
  }

  responsiveFunction() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.maxHeight = (parseInt((this.innerHeight * 0.50).toFixed(0), 10) - 100) + 'px';
  }
}
