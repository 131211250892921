import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { User } from '../components/catalogs/user-catalog/services/user';
import { RefreshCurrentUser, SetCurrentUser } from './actions';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable()
export class AppStateModel {
  currentUser!: User | null;
}

@State<AppStateModel>({
  name: 'ASCC',
  defaults: {
    currentUser: null,
  },
})

@Injectable()
export class AppState {
  constructor(
    private service: AuthenticationService,
  ) { }

  @Action(SetCurrentUser)
  SetCurrentUser(ctx: StateContext<AppStateModel>, { token }: SetCurrentUser) {
    if (token) {
      localStorage.setItem('Token', token);
      this.service.getMe().subscribe((res: User) => {
        ctx.patchState({ currentUser: res });
      }, error => {
        console.error(error);
        ctx.patchState({ currentUser: null });
        localStorage.removeItem('Token');
      });
    } else {
      ctx.patchState({ currentUser: null });
      localStorage.removeItem('Token');
    }
  }

  @Action(RefreshCurrentUser)
  async RefreshCurrentUser({ getState, patchState }: StateContext<AppStateModel>) {
    const state = getState();
    if (state.currentUser) {
      var response = await this.service.authenticate(state.currentUser).toPromise();
      if (response?.code == 202 && response.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('Token', response.token);
        patchState({ currentUser: response.user });
      }
    }
  }

}
