<nav
  class="navbar navbar-expand-lg d-flex align-items-center navbar-dark mx-3 mt-3"
  [ngClass]="{
    'bg-prep': environment == 'prep',
    'bg-dark': environment == 'prod',
    'bg-test': environment == 'test',
    'bg-dev': environment == 'dev',
    'bg-local': environment == 'local',
    'bg-new-auth': environment == 'new-auth',
    'h-90': innerWidth > 990
  }"
>
  <div class="menu">
    <div *ngIf="!isLoading">
      <ul class="navbar-nav">
        <div
          class="d-flex flex-column flex-lg-row full-width justify-content-between px-3"
          id="menu"
        >
          <li *ngIf="showCarrousel">
            <button mat-icon-button class="navButtons" (click)="next(false)">
              <mat-icon>navigate_before</mat-icon>
            </button>
          </li>
          <li
            *ngFor="let menuItem of showingMenu; let i = index"
            class="nav-item d-flex align-items-center"
            [ngClass]="{ active: menuItem.name == 'Home' }"
          >
            <a
              class="nav-link p-0 relative"
              (click)="actions?.edit(menuItem)"
              *ngIf="
                menuItem.routerLink && menuItem.type != menuItemTypes.PopUp
              "
            >
              {{ menuItem.name }}
            </a>
            <a
              *ngIf="menuItem.type == menuItemTypes.PopUp"
              class="nav-link p-0"
              (click)="actions?.edit(menuItem)"
              >{{ menuItem.name }}</a
            >
            <head-menu-dropmenu
              [editing]="true"
              [SA]="SA"
              [menuItem]="menuItem"
              *ngIf="
                menuItem.type == menuItemTypes.Submenu ||
                menuItem.type == menuItemTypes.Table
              "
            ></head-menu-dropmenu>
            <menu-config-actions
              [SA]="SA"
              [menuItem]="menuItem"
              [menuItems]="showingMenu"
              [index]="i"
              [orientation]="orientation.Horizontal"
              buttonClass="white"
            ></menu-config-actions>
          </li>
          <li *ngIf="showCarrousel">
            <button mat-icon-button class="navButtons" (click)="next(true)">
              <mat-icon>navigate_next</mat-icon>
            </button>
          </li>
        </div>
      </ul>
    </div>
    <div class="loading text-loading" *ngIf="isLoading">Building menu ...</div>
  </div>
</nav>
