<div class="mat-container mt-2 scrollbar">
  <table mat-table #table [dataSource]="dataSource">
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td
        mat-cell
        *matCellDef="let element"
        [attr.rowspan]="getRowSpan(element)"
        [ngClass]="{ hidden: !isFirstElem(element) }"
        class="white-back"
      >
        {{ element.privilegeObj?.privilegeCategory?.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td
        mat-cell
        *matCellDef="let element"
        [ngClass]="{ 'yellow bold': element.pending }"
      >
        {{ element.privilegeObj?.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>
        <mat-icon
          mat-list-icon
          *ngIf="role?.status !== 0 && modifying && isSA()"
          class="add fakeA"
          color="primary"
          (click)="openAddRolePrivilege()"
          >add</mat-icon
        >
        <mat-icon
          mat-list-icon
          *ngIf="role?.status === 0 && isSA()"
          class="add fakeA colorDisabled"
          matTooltip="You can not add privileges to an inactive role"
          >add</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          *ngIf="modifying && isSA()"
          (click)="delete(element)"
          color="warn"
        >
          <mat-icon color="warn">delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" cdkDrag></tr>
  </table>
</div>
