import { Injectable, Injector } from '@angular/core';
import { FormType, OlogNotificationType } from 'src/app/common/enumerations/enumerations';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { FormService } from 'src/app/controls/form-table/form.service';
import { PartialOlogComponent } from 'src/app/controls/olog/partial-olog/partial-olog.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { Form } from 'src/app/services/forms/form';
import { OLogService } from 'src/app/components/olog/olog.service';
import { WFConfiguration, WFTaskSignatureResource, WfSignature } from 'src/app/services/work-flow/work-flow';
import { KEService } from '../kenable/ke.service';
import { Restriction } from 'src/app/controls/restrictions/services/restriction';
import { Observable, Subscription } from 'rxjs';
import { BaseService } from 'src/app/common/base/base.service';
import { EB2 } from './eb';

@Injectable({
  providedIn: 'root'
})
export class EbService extends BaseService<EB2, number> {
  public form!: Form;
  loading!: boolean;

  restrictions!: Restriction[];
  restrictionsFiltered!: Restriction[];
  restrictions$!: Observable<Restriction[]>;
  restrictionsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private formService: FormService,
    private ologService: OLogService,
    private keService: KEService
  ) {
    super(injector, '/EB');
  }

  loadRestrictions() {
    this.restrictions$ = this.store.select((state) => state.Restrictions.data);
    this.restrictionsSubs = this.restrictions$.subscribe((data) => {
      if (data?.length) {
        this.restrictions = data;
      }
    });
  }

  async confirmSign(form: Form | null | undefined, task: WfSignature, action?: number, reason?: string, options?: string, configuration?: WFConfiguration[]) {
    if (!form || !task || !action) return;

    this.form = form;
    this.loadRestrictions();

    const restrictedResourceIDs = this.restrictions
      .filter(x => this.form?.formVersion?.eB2?.eB2Restrictions?.some(r => r.restrictionID === x.id))
      .map(y => y.resourceID)
      .filter((resourceID): resourceID is number => resourceID !== undefined);

    const confirmMessage = this.getConfirmMessage(task, configuration);

    if (confirmMessage) {
      const confirmed = await this.showDialog(confirmMessage);
      if (!confirmed) return false;

      const ok = await this.handleOlog(form, task, configuration);
      if (ok) {
        await this.sign(form, task.taskId, action, reason, options);
        await this.handleKES(restrictedResourceIDs);
      }
    }
    else
      await this.sign(form, task.taskId, action, reason, options);
    return true;
  }

  private getConfirmMessage(task: WfSignature, configuration?: WFConfiguration[]): { message: string, icon: string } | null {
    if (!configuration) return null;

    const config = configuration.find(x => x.wfTaskLocalID === task.id);

    if (config?.identificator === 201) {
      return { message: this.getMessage('finish_message').description, icon: "stop" };
    } else if (config?.identificator === 202) {
      return { message: this.getMessage('toActiveWarning').description, icon: "warn" };
    } else if (config?.identificator === 205) {
      return { message: this.getMessage('EB_To_Approve_Warning').description, icon: "warn" };
    }

    return null;
  }

  private showDialog(confirmMessage: { message: string, icon: string }): Promise<boolean> {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "500px",
      data: confirmMessage,
    });

    return new Promise(resolve => {
      confirm.afterClosed().subscribe(data => resolve(!!data));
    });
  }

  private async handleOlog(form: Form, task: WfSignature, configuration?: WFConfiguration[]) {
    const isAmendment = form.formVersion.status.amendment;
    const bypassChanged = form.formVersion.eB2.bypassDetails !== form.formVersion?.previousVersion?.eB2.bypassDetails;
    let result = true;
    if (configuration?.some(x => x.wfTaskLocalID === task.id && x.identificator === 202 && (!isAmendment || bypassChanged))) {
      result = (await this.ologService.postToOlog(FormType.EBF, form.id, !isAmendment ? OlogNotificationType.Activate : OlogNotificationType.Amended))?.ok ?? false;
    } else if (configuration?.some(x => x.wfTaskLocalID === task.id && x.identificator === 205 && isAmendment && !bypassChanged)) {
      result = (await this.ologService.postToOlog(FormType.EBF, form.id, OlogNotificationType.Amended))?.ok ?? false;
    } else if (!configuration) {
      result = (await this.ologService.postToOlog(FormType.EBF, form.id, OlogNotificationType.Closed))?.ok ?? false;
    }
    return result;
  }

  private async handleKES(restrictedResourceIDs: number[]) {
    if (restrictedResourceIDs?.length) {
      this.keService.processKES(restrictedResourceIDs);
    }
  }

  async sign(form?: Form | null, taskId?: number, actionId?: number, reason?: string, options?: string, amendment?: boolean): Promise<Form | void> {
    this.loading = true;
    if (form?.id && taskId && actionId) {
      this.form = form;
      const formStatus = this.form.formVersion.status;
      const formID = form?.id
      const wfTaskSignature: WFTaskSignatureResource = { taskId, actionId, reason, formID, amendment, options };
      await this.formService.sign(wfTaskSignature).toPromise().then(data => {
        if (data && data?.formVersion.statusID != formStatus.id) {
          this.alert.message('formStateChanged', [new MessagePlaceholder("{serialNo}", this.form?.sequenceNumber.number), new MessagePlaceholder('{status}', data.formVersion.status.name)]);
        }
        this.loading = false;
        return data;
      })
        .catch((error) => {
          console.error(error);
          this.alert.defaultError();
          this.loading = false;
        });
    }
    else return undefined;
  }

  async editOlog(form?: Form | null) {
    if (form) {
      try {
        const data = await this.ologService.getOlog(FormType.EBF, form.id, OlogNotificationType.Activate).toPromise();
        if (data) {
          const dialogRef = this.dialog.open(PartialOlogComponent, {
            height: "fit-content",
            width: "80%",
            data,
          });
          dialogRef.afterClosed().subscribe((isSaved) => {
            console.log("The dialog was closed");
            if (isSaved) {
              console.log(isSaved);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

}
