import { FormStatusEnum } from "src/app/common/enumerations/enumerations";

export class FormStatus {
  public id!: number;
  public name: string;
  public description!: string;
  public code!: string;
  public cancelStatus: boolean;
  public recycleSerialOnCancel: boolean;
  public closedStatus: boolean;
  public editing: boolean;
  public amendment: boolean;
  public activeWork: boolean;
  public hidden: boolean;
  public color: string;

  constructor() {
    this.id = FormStatusEnum.New;
    this.name = 'New';
    this.cancelStatus = false;
    this.recycleSerialOnCancel = false;
    this.closedStatus = false;
    this.editing = true;
    this.amendment = false;
    this.activeWork = false;
    this.hidden = false;
    this.color = 'yellow';
  }
}
