import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RestrictionsCreate, RestrictionsDelete, RestrictionsRefresh, RestrictionsUpdate } from './restrictions.action';
import { RestrictionService } from 'src/app/controls/restrictions/services/restriction.service';
import { Restriction } from 'src/app/controls/restrictions/services/restriction';

export class RestrictionsStateModel {
  data: Restriction[] = [];
}

@State<RestrictionsStateModel>({
  name: 'Restrictions',
  defaults: {
    data: []
  }
})
@Injectable()
export class RestrictionsState {
  constructor(
    private _service: RestrictionService
  ) { }

  @Selector()
  static read(state: RestrictionsStateModel) {
    return state.data;
  }

  @Action(RestrictionsRefresh)
  refresh({ patchState }: StateContext<RestrictionsStateModel>) {
    this._service.Read().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(RestrictionsCreate)
  create({ getState, patchState }: StateContext<RestrictionsStateModel>, { item }: RestrictionsCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(RestrictionsUpdate)
  update({ getState, patchState }: StateContext<RestrictionsStateModel>, { id, item }: RestrictionsUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(RestrictionsDelete)
  delete({ getState, patchState }: StateContext<RestrictionsStateModel>, { id }: RestrictionsDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
