import { Component } from '@angular/core';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
  constructor() {

  }

  ngOnInit() {
    location.href = 'https://ascc.als.lbl.gov/Shibboleth.sso/Logout';
  }
}
