
import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { PendingAccelerator } from "../../../../services/pending-approvals/pending-approval";
import { Restriction } from "../../../../controls/restrictions/services/restriction";
import { ActionPendingCatalog } from "src/app/common/enumerations/enumerations";

export class AcceleratorCatalog {
  constructor() {
    this.details = new AcceleratorCatalogDetails();
  }
  id!: number;
  details: AcceleratorCatalogDetails;
  pendingDetails!: AcceleratorCatalogDetails;
  pendingAcceleratorCatalog!: PendingAccelerator | null;
  lastPendingAccelerator?: PendingAccelerator | null;
  noDataFound?: boolean;
}

export class AcceleratorCatalogDetails {
  constructor() {
    this.accelerator = new Resource();
    this.restrictions = [];
    this.locations = [];
    this.shieldings = [];
  }
  accelerator: Resource;
  restrictions: Restriction[];
  locations: Resource[];
  shieldings: Resource[];
  action?: ActionPendingCatalog;
}
