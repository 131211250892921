import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ReviewAttendanceCategoriesCreate, ReviewAttendanceCategoriesDelete, ReviewAttendanceCategoriesRefresh, ReviewAttendanceCategoriesUpdate } from './review-attendance-category.action';
import { ReviewsService } from '../../reviews.service';
import { ReviewAttendanceCategory } from '../../reviews.models';
import { utils } from 'src/app/modules/libs/utils';

export class ReviewAttendanceCategoriesStateModel {
  data: ReviewAttendanceCategory[] = [];
}

@State<ReviewAttendanceCategoriesStateModel>({
  name: 'ReviewAttendanceCategories',
  defaults: {
    data: []
  }
})
@Injectable()
export class ReviewAttendanceCategoriesState {
  constructor(
    private reviewsService: ReviewsService
  ) { }

  @Selector()
  static read(state: ReviewAttendanceCategoriesStateModel) {
    return state.data;
  }

  @Action(ReviewAttendanceCategoriesRefresh)
  refresh({ patchState }: StateContext<ReviewAttendanceCategoriesStateModel>) {
    this.reviewsService.getReviewAttendanceCategories().toPromise().then(data => {
      patchState({ data: utils.cloneDeep(data) });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(ReviewAttendanceCategoriesCreate)
  create({ getState, patchState }: StateContext<ReviewAttendanceCategoriesStateModel>, { item }: ReviewAttendanceCategoriesCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ReviewAttendanceCategoriesUpdate)
  update({ getState, patchState }: StateContext<ReviewAttendanceCategoriesStateModel>, { id, item }: ReviewAttendanceCategoriesUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    const currentState = utils.cloneDeep(state.data);

    if (index >= 0) {
      currentState.splice(index, 1, utils.cloneDeep(item));
      patchState({ data: currentState });
    }
  }

  @Action(ReviewAttendanceCategoriesDelete)
  delete({ getState, patchState }: StateContext<ReviewAttendanceCategoriesStateModel>, { id }: ReviewAttendanceCategoriesDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
