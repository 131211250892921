<div class="row mt-2">
  <div class="col-9 offset-1 px-3">
    <div class="row more-wrapper p-2 mb-1 mx-1">
      <div class="col-12 mb-2 border-bottom pb-2">
        Checklist Component Type: <b> {{ task.component?.name }}</b>
      </div>
      <div class="col flex-display">
        <div>
          Roles:
          <span *ngFor="let role of task.wfRoleLocals; let i = index"
            ><b [matTooltip]="role.role?.name ?? ''"
              >{{ role.role?.code
              }}<span *ngIf="i != (task.wfRoleLocals?.length ?? 0) - 1"
                >,&nbsp;</span
              ></b
            ></span
          >
          <span *ngIf="!task.wfRoleLocals?.length">
            <mat-icon
              class="warn"
              color="warn"
              [matTooltip]="'No Roles asigned!'"
              >warning</mat-icon
            >
          </span>
        </div>
        <div>
          Actions:
          <span *ngFor="let action of task.wfActionLocals; let i = index"
            ><b
              >{{ action.name
              }}<span *ngIf="i != (task.wfActionLocals?.length ?? 0) - 1"
                >,&nbsp;</span
              ></b
            ></span
          >
          <span *ngIf="!task.wfActionLocals?.length">
            <mat-icon
              class="warn"
              color="warn"
              [matTooltip]="'No Actions asigned!'"
              >warning</mat-icon
            >
          </span>
        </div>
        <div *ngIf="task.condition">
          Conditions: <b>{{ task.condition }}</b>
        </div>
        <div *ngIf="task.code">
          Tag: <b>{{ task.code }}</b>
        </div>
        <div class="small text-break" *ngIf="task.configuration">
          Configuration: {{ task.configuration }}
        </div>
      </div>
    </div>
  </div>
</div>
