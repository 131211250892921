import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FormStatusesCreate, FormStatusesDelete, FormStatusesRefresh, FormStatusesUpdate } from './form-status.action';
import { ChecklistBuilderService } from 'src/app/components/checklists/checklist-builder/checklist-builder.service';
import { FormStatus } from 'src/app/services/forms/form-status';
// import { FormStatus } from 'src/app/_interface/Form/FormStatus';
// import { ChecklistBuilderService } from '../../checklist-builder/checklist-builder.service';


export class FormStatusesStateModel {
  data: FormStatus[] = [];
}

@State<FormStatusesStateModel>({
  name: 'FormStatuses',
  defaults: {
    data: []
  }
})
@Injectable()
export class FormStatusesState {
  constructor(
    private _service: ChecklistBuilderService
  ) { }

  @Selector()
  static read(state: FormStatusesStateModel) {
    return state.data;
  }

  @Action(FormStatusesRefresh)
  refresh({ patchState }: StateContext<FormStatusesStateModel>) {
    this._service.getFormStatuses().toPromise().then(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(FormStatusesCreate)
  create({ getState, patchState }: StateContext<FormStatusesStateModel>, { item }: FormStatusesCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(FormStatusesUpdate)
  update({ getState, patchState }: StateContext<FormStatusesStateModel>, { id, item }: FormStatusesUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(FormStatusesDelete)
  delete({ getState, patchState }: StateContext<FormStatusesStateModel>, { id }: FormStatusesDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
