import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { Checklist } from 'src/app/components/checklists/checklists';
import { ReviewActionItem } from 'src/app/components/reviews/reviews.models';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';

@Component({
  selector: 'checklist-task-actionitems',
  templateUrl: './checklist-task-actionitems.component.html',
  styleUrls: ['./checklist-task-actionitems.component.scss']
})
export class ChecklistTaskActionitemsComponent implements OnInit, OnDestroy {

  @Input() task!: WfTaskLocalResource;
  @Input() currentChecklist?: Checklist | null;

  reviewActionItems?: ReviewActionItem[];
  reviewActionItems$!: Observable<ReviewActionItem[]>;
  reviewActionItemsSubs!: Subscription;

  constructor(
    private store: Store
  ) { }

  ngOnDestroy(): void {
    this.reviewActionItemsSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.loadActionItems();
  }

  loadActionItems() {
    this.reviewActionItems$ = this.store.select(state => state.ReviewActionItems.data);
    this.reviewActionItemsSubs = this.reviewActionItems$.subscribe(data => {
      this.reviewActionItems = data.filter(x => x.wfTaskLocalID == this.task.id);
    });
  }

  hasEndDate(actionItem: ReviewActionItem) {
    return actionItem.endDate != null && actionItem.endDate.toString() != '0001-01-01T00:00:00';
  }

}
