import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { MissingTrainingRefresh } from "./procedure-missing-training.action";
import { ProcedureReportService } from "../../services/procedure-report.service";

export class MissingTrainingStateModel {
  data: number = 0;
}

@State<MissingTrainingStateModel>({
  name: "MissingTraining",
  defaults: {
    data: 0
  }
})
@Injectable()
export class MissingTrainingState {

  constructor(private service: ProcedureReportService) { }

  @Action(MissingTrainingRefresh)
  refresh({ patchState }: StateContext<MissingTrainingStateModel>, { id }: MissingTrainingRefresh) {
    if (id) {
      this.service.readByUserID(id).subscribe(data => {
        const mt = data.filter(d => d.active && d.revision != d.creditedRevision).length;
        patchState({ data: mt });
      },
        error => console.error(error)
      );
    }
    else patchState({ data: 0 });
  }

}
