import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as ReviewBoardTypesActions from '../../store/review-board-types/review-board-types.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';

@Injectable({
  providedIn: 'root'
})
export class ReviewBoardTypesStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore() {
    this.store.dispatch(new ReviewBoardTypesActions.ReviewBoardTypesRefresh());
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.REVIEW_BOARD_TYPES, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new ReviewBoardTypesActions.ReviewBoardTypesCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new ReviewBoardTypesActions.ReviewBoardTypesUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new ReviewBoardTypesActions.ReviewBoardTypesDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new ReviewBoardTypesActions.ReviewBoardTypesRefresh());
          break;
      }
    });
  }
}
