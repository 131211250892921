import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ComponentsCreate, ComponentsDelete, ComponentsRefresh, ComponentsUpdate } from './components.action';
import { WFComponent } from 'src/app/components/workflow/workflow';
import { ComponentService } from 'src/app/services/work-flow/component.service';

export class ComponentsStateModel {
  data: WFComponent[] = [];
}

@State<ComponentsStateModel>({
  name: 'Components',
  defaults: {
    data: []
  }
})
@Injectable()
export class ComponentsState {
  constructor(
    private _service: ComponentService
  ) { }

  @Selector()
  static read(state: ComponentsStateModel) {
    return state.data;
  }

  @Action(ComponentsRefresh)
  refresh({ patchState, getState }: StateContext<ComponentsStateModel>) {
    this._service.Read().toPromise().then(
      data => {
        patchState({ data });
      },
      error => console.error(error)
    );
  }

  @Action(ComponentsCreate)
  create({ getState, patchState }: StateContext<ComponentsStateModel>, { item }: ComponentsCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ComponentsUpdate)
  update({ getState, patchState }: StateContext<ComponentsStateModel>, { id, item }: ComponentsUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ComponentsDelete)
  delete({ getState, patchState }: StateContext<ComponentsStateModel>, { id }: ComponentsDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
