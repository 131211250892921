import { Component, Inject, OnInit, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import imageCompressor from 'quill-image-compress';
import CustomImageResize from 'src/app/modules/libs/quill/quill-custom-image-resize/custom-image-resize';
import { utils } from 'src/app/modules/libs/utils';
import { ImageUploadService } from 'src/app/services/file/image-upload.service';
import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import ImageResize from 'quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module';
// import { ImageResize } from 'quill-image-resize-module';

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);
var Block = Quill.import('blots/block');
Block.tagName = 'DIV';  // Quill uses <p> by default
Quill.register(Block, true);
var bold = Quill.import('formats/bold');
bold.tagName = 'b';   // Quill uses <strong> by default
Quill.register(bold, true);

Quill.register('modules/customImageResize', CustomImageResize);
Quill.register('modules/imageCompressor', imageCompressor);
// Quill.register('modules/table', QuillTable);
@Component({
  selector: 'comments-box-edit',
  templateUrl: './comments-box-edit.component.html',
  styleUrls: ['./comments-box-edit.component.scss']
})
export class CommentsBoxEditComponent implements OnInit, AfterViewInit {

  textValue: string;
  serialNo: string;
  formType: string;
  title: string;
  minHeight: string;
  maxHeight: string;

  public form!: FormGroup;
  quillCtrl = new FormControl();
  showHTML!: boolean;
  loading!: boolean;
  dialogId!: string;

  imageMaxWidth = 300;
  imageSizes!: [{ name: 'Small'; val: 255; }, { name: 'Medium'; val: 400; }, { name: 'Original'; val: 0; }];
  fullScreen = true;
  quillEditorRef: any;
  quillColors = ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
    "#ffffff", "#ff5588", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
    "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
    "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
    "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"];

  // Is this even doing anything??
  quillConfig = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
        [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
        ['table'], // Add the table option to the toolbar
        ['link', 'image', 'video'],
        ['clean']
      ],
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range: any, context: any) => {
            return true;
          }
        }
      }
    },
    imageDrop: true,
    imageResize: true,
    customImageResize: true,
    imageCompressor: {
      quality: 0.3, // default
      maxWidth: this.imageMaxWidth, // default
      imageType: 'image/*', // default
      debug: true
    },
  };

  defaultSelect!: string;

  constructor(
    private service: ImageUploadService,
    public dialog: MatDialogRef<CommentsBoxEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private elem: ElementRef,
    private cdRef: ChangeDetectorRef
  ) {
    this.textValue = data.textValue;
    this.serialNo = data.serialNo;
    this.formType = data.formType;
    this.title = data.title;
    this.minHeight = data.minHeight;
    this.maxHeight = data.maxHeight;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngAfterViewInit() {
    this.maximize();
  }

  buildForm() {
    this.form = new FormGroup({
      quillCrtl: this.quillCtrl
    });
  }

  created(quill: any) {
    $('.ql-container').css('min-height', this.minHeight);
    $('.ql-container').css('max-height', this.maxHeight);
    $('.ql-editor').css('max-height', this.maxHeight);
    $('.ql-editor').addClass('scrollbar');
    const dialogs = $('.mat-mdc-dialog-container');
    this.dialogId = dialogs[dialogs.length - 1].id;
    this.changed();
    // const imageSizes = this.elem.nativeElement.querySelectorAll('.image-sizes .ql-picker-item');
    // imageSizes.map((s: any) => {
    //   s.textContent = s.dataset.label;
    //   s.addEventListener('click', () => this.imageSizeSelected({ label: s.dataset.label, value: s.dataset.value }));
    // });
    // this.defaultSelect = this.elem.nativeElement.querySelector('.image-sizes .ql-picker-label').innerHTML;
    // this.elem.nativeElement.querySelector('.image-sizes .ql-picker-label').innerHTML
    //   = 'Small' + this.defaultSelect;
    quill.focus();
    setTimeout(() => quill.setSelection(quill.getLength(), 0));

  }

  mouseOut() {
    // utils.removeExistingCustomContainers();
  }

  async save() {
    utils.removeExistingCustomContainers();
    this.loading = true;
    const textValue = await this.uploadImages(this.textValue);
    this.data.textValue = textValue;
    this.loading = false;
    this.dialog.close(this.data);
  }

  cancel() {
    this.dialog.close();
  }

  async uploadImages(textValue: string) {
    const text = await this.service.uploadImagesToServer(textValue, '/' + this.formType.toString()?.trim() + '/' + this.serialNo + '/Comments/');
    return text;
  }

  isShowHTML() {
    this.showHTML = !this.showHTML;
    this.cdRef.detectChanges();
    this.changed();
  }

  changed() {
    // const editorHeight = $('.edit-container').height() ?? 0;
    const windowHeight = $('#' + this.dialogId).height() ?? 0;
    $('.ql-container').height(windowHeight - 50);
    $('.html-textarea').height(windowHeight - 70);
    // if (editorHeight > windowHeight - 22) {
    //   $('#' + this.dialogId).height(editorHeight - 22);
    // }
  }

  maximize() {
    this.fullScreen = true;
    this.dialog.addPanelClass('maximized');
  }

  restore() {
    this.fullScreen = false;
    this.dialog.removePanelClass('maximized');
  }

  imageSizeSelected(e: { label: any; value: any; }) {
    this.elem.nativeElement.querySelector('.imageSizes .ql-picker-label').innerHTML
      = e.label + this.defaultSelect;
    this.imageMaxWidth = e.value;
    this.quillConfig.imageCompressor.maxWidth = this.imageMaxWidth;
    Quill.register('modules/imageCompressor', imageCompressor);
  }

  addTable(e: { clipboard: { dangerouslyPasteHTML: (arg0: string) => void; }; }) {
    var tableHtml = '<table><tr><td>Cell 1</td><td>Cell 2</td></tr><tr><td>Cell 3</td><td>Cell 4</td></tr></table>';
    e.clipboard.dangerouslyPasteHTML(tableHtml);
  }

}
