import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, Injector, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { WFSectionLocalResource, WFSectionLocalStatus } from 'src/app/services/work-flow/work-flow';
import { Configuration } from '../../../checklist-builder/checklist-builder-sections/checklist-builder-section/checklist-builder-section.component';
import { Checklist } from '../../../checklists';
import { ChecklistsService } from '../../../checklists.service';
import { ChecklistStepsComponent } from '../../checklist-steps/checklist-steps.component';

@Component({
  selector: "checklist-section",
  templateUrl: "./checklist-section.component.html",
  styleUrls: ["./checklist-section.component.scss"],
})
export class ChecklistSectionComponent extends BaseComponent implements OnDestroy, OnInit, OnChanges {
  @Input() currentChecklist?: Checklist | null;
  @Input() section?: WFSectionLocalResource;
  @Input() precheck!: boolean;
  @Input() disabled?: boolean;

  @Output() loading = new EventEmitter<boolean>();
  @Output() checkShared = new EventEmitter<any>();

  checklists!: Checklist[];
  checklistsFiltered!: Checklist[];
  checklists$!: Observable<Checklist[]>;
  checklistsSubs!: Subscription;

  roles!: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  config!: Configuration;
  userCanDuplicate!: boolean;
  hasCompletedTasks!: boolean;

  tooltip!: string;
  tooltipd!: string;

  wfSectionLocalStatus = WFSectionLocalStatus;
  checklistClosed?: boolean;

  @ViewChild(ChecklistStepsComponent) stepsComponent!: ChecklistStepsComponent;

  constructor(
    protected override injector: Injector,
    private service: ChecklistsService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentChecklist']) {
      this.section = this.currentChecklist?.wfTable?.wfTableLocal?.wfSectionLocals.find(x => x.id == this.section?.id);
    }
    if (this.section) {
      this.config = this.utils.JSONparse(this.section.configuration);
      if (!this.config) { this.config = new Configuration(); }
      this.loadRoles();
      this.userCanDuplicate = this.hasRoles(this.config?.roleIDs);
      this.checklistClosed = this.currentChecklist?.checklistStatus?.formStatus.closedStatus || this.currentChecklist?.checklistStatus?.formStatus.cancelStatus;
    }
  }

  ngOnInit(): void {

  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      if (data.length) {
        this.roles = data.filter(r => this.config.roleIDs.includes(r.id));
        this.tooltip = 'Duplicate Section (Allowed roles: ' + this.roles?.map(r => r.code).join(', ') + ')';
        this.tooltipd = 'Delete Duplicated Section (Allowed roles: ' + this.roles?.map(r => r.code).join(', ') + ')';
      }
    });
  }

  override ngOnDestroy(): void {
    this.checklistsSubs?.unsubscribe();
    this.rolesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  isSectionExpanded(): boolean {
    const expanded = this.section?.collapsible ? !this.section?.collapsed : true;
    return expanded;
  }

  saveState(collapsed: boolean) {
    if (collapsed != this.section?.collapsed && this.section?.collapsed != undefined && this.currentChecklist) {
      this.section.collapsed = collapsed;
      this.service.saveSectionExpanded(this.currentChecklist.id, this.section.id, collapsed);
    }
  }

  duplicate(e: any) {
    e.stopPropagation();
    const yesno = this.dialog.open(YesNoDialogComponent, {
      width: "400px",
      data: {
        message: this.getMessage("ChecklistDuplicateSectionConfirmation")
          .description,
        icon: "question",
      },
    });
    yesno.afterClosed().subscribe(async (data) => {
      if (data && this.section) {
        this.loading.emit(true);
        await this.service.duplicateSection(this.section.id).toPromise();
        this.loading.emit(false);
      }
    });
  }

  delete(e: any) {
    e.stopPropagation();
    const yesno = this.dialog.open(YesNoDialogComponent, {
      width: "400px",
      data: {
        message: this.getMessage("ChecklistDeleteSectionConfirmation")
          .description,
        icon: "question",
      },
    });
    yesno.afterClosed().subscribe(async (data) => {
      if (data && this.section) {
        this.loading.emit(true);
        await this.service.deleteSection(this.section.id).toPromise();
        this.loading.emit(false);
      }
    });
  }

}
