import { Component, ViewChildren, ViewContainerRef, QueryList, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, OnInit, Injector, Input, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatRow } from '@angular/material/table';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Observable, Subscription } from 'rxjs';
import { FormStatusEnum, FormType } from 'src/app/common/enumerations/enumerations';
import { AbsiService } from '../absi.service';
import { AbsiUpdate } from '../store/absi/absi.action';
import { AbsiResource } from '../absi';
import { MatSort } from '@angular/material/sort';
import { AbsiSectionRefresh } from '../store/absi-sections/absi-section.action';
@Component({
  selector: 'absi-side-panel',
  templateUrl: './absi-side-panel.component.html',
  styleUrls: ['./absi-side-panel.component.scss']
})
/** absi-side-panel component*/
export class AbsiSidePanelComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @Input() filter?: string | null;
  @Output() loading = new EventEmitter<boolean>();
  @Output() rowSelected = new EventEmitter<any>();

  @ViewChildren('matrow', { read: ViewContainerRef }) rows?: QueryList<ViewContainerRef>;
  @ViewChildren('matrow') datarows?: QueryList<MatRow>;

  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = [
    'serialNo',
    'resourceName',
    'statusName',
  ];

  public dataSource = new MatTableDataSource<AbsiResource>();

  absi?: AbsiResource;
  absis?: AbsiResource[];
  absisFiltered?: AbsiResource[];
  absis$!: Observable<AbsiResource[]>;
  absisSubs!: Subscription;

  serialNo!: string;
  selectedRowIndex? = -1;
  selectedIndex = -1;
  showClosed = false;

  allowNew = false;
  disabled = false;
  maxHeight!: string;

  constructor(
    protected override injector: Injector,
    private service: AbsiService
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.setSortingAccessor();
  }

  ngOnInit(): void {
    this.loading.emit(true);
    this.loadAbsis();
    this.setHeights();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadAbsis();
    this.setHeights();
  }

  loadAbsis() {
    this.absis$ = this.store.select(state => state.Absi.data);
    this.absisSubs = this.absis$.subscribe(async data => {
      if (data?.length) {
        const filter = this.filter?.toLowerCase() ?? '';
        this.absis = data.filter(a => a.status != FormStatusEnum.Canceled && a.status != this.formStatusEnum.Closed || this.showClosed).filter(a => a.serialNo.toLowerCase().includes(filter) || a.resourceName?.toLowerCase().includes(filter) || a.formStatus?.name.toLowerCase().includes(filter));
        this.absisFiltered = this.absis.sort((a, b) => this.utils.sort(a.serialNo, b.serialNo, false));
        this.dataSource = new MatTableDataSource(this.absisFiltered);
        this.setSortingAccessor();
        const openDocument = await this.checkOpenDocument<AbsiResource>(FormType.ABSI, data);
        if (openDocument) {
          this.loading.emit(true);
          this.showClosed = openDocument.closed ? true : this.showClosed;
          this.getValues(openDocument.document);
        }
        else this.loading.emit(false);
      }
    });
  }

  setSortingAccessor() {
    // Define the sorting accessor
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'statusName':
          return item.formStatus?.name ?? ''; // Access the nested property for status name
        default:
          return (item as any)[property]; // Fallback to the default property
      }
    };
    this.setSort<AbsiResource>(this.dataSource, this.sort);
  }

  changeClosed() {
    this.loadAbsis();
  }

  async getValues(row: AbsiResource) {
    if (await this.canDeactivate())
      if (!this.disabled && row) {
        this.disabled = true;
        if (!row.wfTable && row.wfTableID)
          this.store.dispatch(new AbsiSectionRefresh(row.wfTableID));
        this.rowSelected.emit(row);
        this.selectedRowIndex = row?.id;
        this.setOpenDocument(FormType.ABSI, row.id);
        if (!row.wfTable) {
          this.loading.emit(true);
          this.service.getById(row.id).toPromise().then(data => {
            if (data) {
              row = data;

              this.rowSelected.emit(row);
              this.store.dispatch(new AbsiUpdate(row.id, row));
            }
            this.disabled = false;
            this.loading.emit(false);
          });
        }
        else {
          this.disabled = false;
          this.loading.emit(false);
        }
      }
  }



  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex(r => {
      return r.id == row.id;
    });
    return i;
  }
  // //#endregion


  breakByComma(text: string) {
    if (text) {
      text = this.utils.replaceAll(text, ', ', '<br>');
    }
    return text;
  }
}
