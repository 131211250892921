import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { ChecklistExecStatus, ChecklistTemplate, } from "../../../../components/checklists/checklists";
import { FormControl, FormGroup } from "@angular/forms";
import { utils } from "src/app/modules/libs/utils";

@Component({
  selector: "cl-editor-status",
  templateUrl: "./cl-editor-status.component.html",
  styleUrls: ["./cl-editor-status.component.scss"],
})
export class ClEditorStatusComponent implements OnInit {
  @Input() disabled!: boolean;
  @Input() configuration?: string;
  @Input() checklistTemplate!: ChecklistTemplate;
  @Input() statusLabel!: string;
  @Input() action!: number;

  @Output() selected = new EventEmitter<any>();

  form!: FormGroup;
  statusCtrl = new FormControl();

  status?: ChecklistExecStatus | null;
  statuses?: ChecklistExecStatus[];
  statusesFiltered?: ChecklistExecStatus[];

  nochange = {
    id: 0,
    description: "[No Change]",
    cssClass: "gray",
    order: 0,
  } as ChecklistExecStatus;
  constructor() { }

  ngOnInit(): void {
    this.statuses = this.checklistTemplate?.documentType?.checklistExecStatuses;
    this.statusesFiltered = this.statuses?.sort((a, b) => a.order - b.order);
    this.sortStatuses();
    if (this.configuration) {
      switch (this.action) {
        case undefined:
          this.status = this.statuses?.find(
            (x) => x.id == utils.JSONparse(this.configuration).status
          );
          break;
        case 1:
          this.status = this.statuses?.find(
            (x) => x.id == utils.JSONparse(this.configuration).approveNextStatus
          );
          break;
        case 2:
          this.status = this.statuses?.find(
            (x) => x.id == utils.JSONparse(this.configuration).unapproveNextStatus
          );
          break;
        case 3:
          this.status = this.statuses?.find(
            (x) => x.id == utils.JSONparse(this.configuration).disapproveNextStatus
          );
          break;
      }
    }
    this.statusCtrl.setValue(this.status);
    if (this.disabled) {
      this.statusCtrl.disable();
    } else {
      this.statusCtrl.enable();
    }
  }

  changedStatus(e: any) {
    this.status = null;
    const val = e.target.value;
    this.statusesFiltered = this.statuses?.filter((x) =>
      x.name?.toLowerCase().includes(val.toLowerCase())
    );
    this.sortStatuses();
    this.selected.emit(null);
  }

  selectedStatus(e: any) {
    this.status = e.option.value;
    this.statusesFiltered = this.statuses?.filter(
      (x) => x.id != this.status?.id
    );
    this.sortStatuses();
    this.selected.emit(this.status?.id);
  }

  displayObject(e: any) {
    return e ? e.description : null;
  }

  sortStatuses() {
    if (this.action != undefined && !this.statusesFiltered?.map((x) => x.id).includes(0))
      this.statusesFiltered?.push(this.nochange);
    this.statusesFiltered = this.statusesFiltered?.sort(
      (a, b) => a.order - b.order
    );
  }
}
