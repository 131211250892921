import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PVRefresh, PVCreate, PVUpdate, PVDelete, PVRefreshByID } from './pv-info.action';
import { PV } from '../pv-info';
import { PVInfoService } from '../pv-info.service';

export class PVStateModel {
  data: PV[] = [];
}

@State<PVStateModel>({
  name: 'PV',
  defaults: {
    data: [],
  }
})
@Injectable()
export class PVState {
  constructor(
    private service: PVInfoService
  ) { }

  @Selector()
  static read(state: PVStateModel) {
    return state.data;
  }

  @Action(PVRefresh)
  refresh({ patchState }: StateContext<PVStateModel>) {
    this.service.getAll().subscribe(data => {
      patchState({ data });
    },
      error => console.error(error)
    );
  }

  // @Action(PVRefreshByID)
  // refreshByID({ getState, patchState }: StateContext<PVStateModel>, { id }: PVRefreshByID) {
  //   this.service.getById(id).subscribe(
  //     data => {
  //       const PV = data as PVResource;
  //       const state = getState();
  //       const index = state.data.map(x => x.id).indexOf(id);

  //       if (index >= 0) {
  //         state.data.splice(index, 1);
  //       }
  //       patchState({
  //         data: [...state.data, PV]
  //       });
  //     }
  //   );
  // }

  @Action(PVCreate)
  create({ getState, patchState }: StateContext<PVStateModel>, { item }: PVCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(PVUpdate)
  update({ getState, patchState }: StateContext<PVStateModel>, { id, item }: PVUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...state.data, item]
    });
  }

  @Action(PVDelete)
  delete({ getState, patchState }: StateContext<PVStateModel>, { id }: PVDelete) {
    patchState({
      data: getState().data.filter(x => x.id != id)
    });
  }


}
