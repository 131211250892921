<div class="card">
  <div class="card-header">
    <h6>Step Status Settings</h6>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-6">Status name</div>
          <div class="col-3">Enable</div>
          <div class="col-3">Hide</div>
        </div>
        <div class="row" *ngFor="let status of allStatuses">
          <div class="col-6 d-flex align-items-center">
            <span [ngClass]="status.cssClass">{{ status.description }}</span>
          </div>
          <div class="col-3">
            <mat-checkbox
              color="primary"
              [checked]="status.enabled"
              (click)="$event.stopPropagation()"
              (change)="enabledChange($event, status)"
              [disabled]="disabled"
            >
            </mat-checkbox>
          </div>
          <div class="col-3">
            <mat-checkbox
              [checked]="status.hidden"
              (click)="$event.stopPropagation()"
              (change)="hiddenChange($event, status)"
              [disabled]="disabled || status.enabled"
            >
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer pt-1">
    <div class="row">
      <div class="col-12 text-center pt-2">
        <button
          mat-stroked-button
          color="accent"
          class="mr-2"
          (click)="cancel()"
          *ngIf="disabled"
        >
          Close
        </button>
        <button
          mat-stroked-button
          color="warn"
          class="mr-2"
          (click)="cancel()"
          *ngIf="!disabled"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          color="primary"
          (click)="accept()"
          *ngIf="!disabled"
        >
          Accept
        </button>
      </div>
    </div>
  </div>
</div>
