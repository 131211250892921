<div class="row" cdkDropList (cdkDropListDropped)="drop($event)">
  <div
    class="col-12 review-box mb-2"
    *ngFor="let reviewStatus of reviewStatuses"
  >
    <div cdkDrag class="row">
      <div class="col-1 d-flex align-items-center">
        <mat-icon cdkDragHandle color="accent" class="drag"
          >drag_handle</mat-icon
        >
      </div>
      <div class="col-8 d-flex align-items-center">
        {{ reviewStatus.order }}. {{ reviewStatus.name }}
        <span class="pill ml-2" [ngClass]="getClass(reviewStatus.color)">{{
          reviewStatus.code
        }}</span>
        <span class="active" *ngIf="reviewStatus.type == statusType.Active"
          ><mat-icon color="primary">fiber_manual_record</mat-icon></span
        >
      </div>
      <div class="col-3 justify-content-end d-flex align-items-center">
        <button mat-icon-button (click)="edit(reviewStatus)">
          <mat-icon color="accent">edit</mat-icon>
        </button>
        <button mat-icon-button (click)="delete(reviewStatus)">
          <mat-icon color="warn">delete_outline</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<button mat-flat-button color="primary" (click)="add()">
  <mat-icon>add</mat-icon>Add
</button>
