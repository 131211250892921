<div class="container-fluid mt-2">
  <div id="linkModified">
    <div class="row">
      <div class="col-12 col-sm-6 col-lg-6">
        <mat-form-field>
          <mat-label>Hyperlink</mat-label>
          <input
            matInput
            [ngModel]="navigationLink?.hyperlink"
            (change)="hyperLinkChanged($event)"
            [disabled]="!(adding || modifying)"
            name="hyperlink"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <mat-form-field>
          <mat-label>Web Address</mat-label>
          <input
            type="url"
            matInput
            [ngModel]="navigationLink?.webAddress"
            (change)="webAddressChanged($event)"
            [disabled]="!(adding || modifying)"
            name="webAddress"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <mat-slide-toggle
          [disabled]="!(adding || modifying)"
          [ngModel]="navigationLink?.isActive"
          (change)="isActiveChanged($event)"
          name="isActive"
        >
          Is Active</mat-slide-toggle
        >
      </div>
    </div>
  </div>
</div>
<div class="buttons" id="sidenav-content-buttons">
  <div class="row">
    <div class="col-6" *ngIf="showCancelButton">
      <button
        mat-stroked-button
        color="warn"
        (click)="clear()"
        style="width: 100px"
        *ngIf="modifying || adding"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="warn"
        [disabled]="!hasPrivilege(privilegeEnum.LinksCatalogDelete)"
        class="ml-2"
        *ngIf="!modifying && !adding"
        (click)="delete()"
        style="width: 100px"
      >
        Delete
      </button>
    </div>
    <div class="col-6">
      <button
        mat-flat-button
        color="accent"
        [disabled]="!hasPrivilege(privilegeEnum.LinksCatalogModify)"
        class="right-btn"
        *ngIf="selected && !modifying && !adding"
        (click)="modify()"
        style="width: 100px"
      >
        Modify
      </button>
      <button
        mat-flat-button
        color="primary"
        class="right-btn"
        *ngIf="modifying || adding"
        [disabled]="!isFormDirty()"
        (click)="save()"
        style="width: 100px"
      >
        Save
      </button>
    </div>
  </div>
</div>
