import { Component, ViewChild, ElementRef, Injector } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { NotificationService } from 'src/app/components/catalogs/notifications/services/notification.service';
import { NotificationMessage } from 'src/app/components/catalogs/notifications/services/notification.models';
import { BaseComponent } from 'src/app/common/base/base.component';
import { EmailAddress } from 'src/app/components/olog/olog';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})

export class NotificationComponent extends BaseComponent {
  @ViewChild('emailsInput') emailsInputElem?: ElementRef<HTMLInputElement>;
  emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

  emailError = false;

  emails: EmailAddress[] = [];

  serialNo?: string;
  Subject?: string;
  Message?: string;
  Signature?: string;
  AdditionalText?: string;
  AddEmailRecipients = false;
  usersToNotify?: User[];

  readOnly = false;
  confirmation = false;
  disableSend = false;
  disabledTooltipMessage!: string;

  constructor(
    protected override injector: Injector,

    public dialogRef: MatDialogRef<NotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationServ: NotificationService,
  ) {
    super(injector);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
    this.readOnly = this.data.readOnly;
    this.confirmation = this.data.confirmation;
    this.serialNo = this.data.message.serialNo;
    this.disableSend = this.data.disableSend;

    if (this.isObject(this.data.message.toAddresses)) {
      const emlObj = JSON.parse(this.data.message.toAddresses);
      const curEmails = this.utils.parseEmailAddresses(this.data.emailAddresses);
      const newEmails = this.utils.parseEmailAddresses(emlObj.added)?.map((e) => { return { address: e.address, name: e.name, isCustom: true } as EmailAddress }) ?? [];
      this.emails = curEmails.concat(newEmails);
    }
    else {
      const curEmails = this.utils.parseEmailAddresses(this.data.emailAddresses);
      this.emails = curEmails;
    }
    // this.disableSend = this.curEmails.some((e: string) => e.includes('lbl.gov') && e != this.currentUser?.emailAddress);
    if (this.disableSend) this.disabledTooltipMessage = 'Disabled to prevent email notifications from being sent to real email addresses';
    this.Subject = this.data.message.messageSubject;
    this.Message = this.data.message.messageBody;
    this.Signature = this.data.message.signature;
    this.AdditionalText = this.data.message.messageAdditionalText;
  }

  disableAll() {

  }

  cancel() {
    this.dialogRef.close(false);
  }

  async sendNotification() {
    let notificationMessage = await this.saveNotification(this.readOnly);
    if (notificationMessage) {
      console.log(notificationMessage);
      this.notificationServ.sendNotification(notificationMessage).toPromise().then(res => {
        if (res) {
          notificationMessage = res;
          const addressObj = this.utils.JSONparse(notificationMessage.toAddresses);
          var addresses: string[] = [];
          addressObj.computed.map((e: any) => {
            addresses.push(e);
          });
          addressObj.added.map((e: any) => {
            addresses.push(e);
          });
          // this.alert.info('Notification Sent by Email to: ' + addresses.join(', '));
          this.dialogRef.close(true);
        }
      }).catch(error => {
        console.log(error);
        this.alert.defaultError();
        this.dialogRef.close(false);
      });

    }
  }

  continue() {
    this.dialogRef.close(true);
  }

  async saveNotification(close: boolean = true) {
    // if (!this.newEmails) {
    //   this.newEmails = [];
    // }
    // if (this.emails != '') {
    //   const emails = this.emails.split(',');
    //   emails.map(e => {
    //     if (e != '') {
    //       this.newEmails.push(e);
    //     }
    //   });
    // }

    let notificationMessage: NotificationMessage;
    notificationMessage = this.data.message;
    const computed = this.emails.filter(e => !e.isCustom).map(e => e.name + ' <' + e.address + '>');
    const added = this.emails.filter(e => e.isCustom).map(e => '<' + e.address + '>');
    notificationMessage.toAddresses = JSON.stringify({ computed, added });
    notificationMessage.messageAdditionalText = this.AdditionalText;
    notificationMessage.type = this.data.type;
    notificationMessage.code = this.data.code;

    if (this.readOnly) {
      return notificationMessage;
    }
    const result =
      (notificationMessage.id) ?
        await this.notificationServ.saveNotification(notificationMessage).toPromise().catch(error => {
          console.log(error);
          this.alert.defaultError();
        })
        :
        await this.notificationServ.createNotification(notificationMessage).toPromise().catch(error => {
          console.log(error);
          this.alert.defaultError();
        });

    if (close)
      this.dialogRef.close(result);
    return result;
  }

  showAddEmailField() {
    this.AddEmailRecipients = true;
    setTimeout(() => {
      this.emailsInputElem?.nativeElement.focus();
    }, 100);

  }

  validateEmailAddresses(e: any) {
    const value = e.target.value;
    let result = true;
    if (result) {
      result = this.emailPattern.test(value.trim());
    }
    this.emailError = !result;
  }

  checkIfEmpty() {
    // if (this.emails.trim() == '') {
    //   this.AddEmailRecipients = false;
    // }
  }

  isObject(obj: any) {
    let isValidJSON = true;
    try {
      JSON.parse(obj);
    } catch (e) {
      isValidJSON = false;
    }
    return isValidJSON;
  }

  add(e: any) {
    if (e.input.value.trim() != '') {
      this.emails.push({ address: e.input.value, isCustom: true } as EmailAddress);
      this.clearInputValue(e);
    }
  }

  remove(e: any) {
    this.emails.splice(this.emails.indexOf(e), 1);
  }
}
