import { Note } from "../../../../services/notes/note.model";
import { RadLocation } from "./rad-location";
import { RadMonitorDescription } from "./rad-monitor-description";
import { RadMonitorType } from "./rad-monitor-type";
import { RadNote, RadNoteSaveResource } from "./rad-note";

export class RadMonitor {
  id?: number;
  ehs?: string;
  typeId?: number;
  descriptionId?: number;
  mfr?: string;
  model?: string;
  radLocationID?: number;
  calDueDate?: Date | null;
  installedDate?: Date;
  radMonitorType?: RadMonitorType;
  radMonitorDescription?: RadMonitorDescription;
  radLocation?: RadLocation | null;
  radNotes?: RadNote[];
  normalNotes?: RadNote[];
  faultNotes?: RadNote[];
  createdOn?: Date;
  createdBy?: number;
  updatedOn?: Date;
  updatedBy?: number;

  notes?: Note[];
  notesf?: Note[];
  type?: number | null;
  status?: CalDueDateStatus;
  cssClass?: string;
}

export interface RadMonitorSaveResource {
  id: number;
  changeOutReason: string;
  faultNote: RadNoteSaveResource | null;
}

export enum CalDueDateStatus {
  Good = 0,
  CloseToExpire = 1,
  Expired = 2
}
