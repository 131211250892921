import { ChangeDetectorRef, Component, HostBinding, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TooltipComponent } from '@angular/material/tooltip';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert/alert.service';
import { TokenInfoService } from 'src/app/services/authentication/token-info.service';
import { MessageBanner } from 'src/app/components/messages/services/message-banner';
import { MessageBannerService } from 'src/app/components/messages/services/message-banner.service';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { AppStateService } from 'src/app/store/app-state-service';
import { FormStatusEnum, FormType, ResourceType, Roles } from '../enumerations/enumerations';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { utils } from 'src/app/modules/libs/utils';
import { CanDeactivateService } from '../guards/unsaved-changes.guard';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MessagePlaceholder } from '../models/placeholder';
import { RedirectService } from 'src/app/components/redirect/redirect.service';
import { DocumentCategory } from 'src/app/services/documents/documents';
import { FormVersion } from 'src/app/services/forms/form-version';
import { ChecklistExecStatus } from 'src/app/components/checklists/checklists';
import { Servers } from '../enumerations/servers-enum';
import { ImageUploadService } from 'src/app/services/file/image-upload.service';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { DialogService } from 'src/app/services/dialogs/dialog.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent {

  private subs: Subscription = new Subscription;
  public currentUser!: User | null;

  public users: User[] = [];
  private users$!: Observable<User[]>;
  private usersSubs: Subscription = new Subscription;

  private messageBanners$!: Observable<MessageBanner[]>;
  private messageBannersSubs!: Subscription;

  private _mbs!: MessageBannerService;
  private _ass!: AppStateService;
  private _tis!: TokenInfoService;
  private _cds: CanDeactivateService;
  private _san!: DomSanitizer;
  private _http!: HttpClient;

  public store!: Store;
  public alert!: AlertService;
  public dialog!: MatDialog;
  public formBuilder!: FormBuilder;
  public formGroup!: FormGroup;
  public cdRef!: ChangeDetectorRef;

  public redirect: RedirectService;
  public dialogService!: DialogService;
  public imageUpload: ImageUploadService;

  public mouseX: number = 0;
  public mouseY: number = 0;

  //Enumerations
  formStatusEnum = FormStatusEnum;
  formTypeEnum = FormType;
  privilegeEnum = PrivilegeEnum;
  resourceTypeEnum = ResourceType;
  roleEnum = Roles;

  servers = Servers;
  environment = document.location.hostname;

  moduleTitle = 'Template Module Title';

  isExpanded = true;
  showSubmenu = false;
  isShowing = false;
  showSubSubMenu = false;
  innerWidth = window.innerWidth;
  innerHeight = window.innerHeight;

  utils = utils;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    protected injector: Injector,
  ) {
    this._mbs = this.injector.get(MessageBannerService);
    this._ass = this.injector.get(AppStateService);
    this._tis = this.injector.get(TokenInfoService);
    this._cds = this.injector.get(CanDeactivateService);
    this._san = this.injector.get(DomSanitizer);
    this._http = this.injector.get(HttpClient);
    this.store = this.injector.get(Store);
    this.alert = this.injector.get(AlertService);
    this.dialog = this.injector.get(MatDialog);
    this.formBuilder = this.injector.get(FormBuilder);
    this.redirect = this.injector.get(RedirectService);
    this.imageUpload = this.injector.get(ImageUploadService);
    this.dialogService = this.injector.get(DialogService);
    this.cdRef = this.injector.get(ChangeDetectorRef);

    this.loadUsers();
    this.loadMessages();
    this.enableHTMLCodeOnTooltips();
    this.observeResize();
    this.loadCurrentUser();
  }

  loadMessages() {
    this.messageBanners$ = this.store.select(state => state.MessageBanners.items);
    this.messageBannersSubs = this.messageBanners$.subscribe(data => {
      if (data?.length) {
        this._mbs.messageBanners = data;
      }
    });
  }

  loadCurrentUser() {
    this.subs = this._ass.AppState.subscribe(appstate => {
      this.currentUser = appstate.currentUser;
      this.currentUserUpdated();
    });
  }

  currentUserUpdated(): void {
    // this.method show be overridden in derived class to trigger other methos when user updates!
  };

  loadUsers() {
    this.users$ = this.store.select(state => state.Users.data);
    this.usersSubs = this.users$.subscribe(data => {
      if (data?.length) {
        this.users = data;
      }
    });
  }

  getCurrentUser(min = false): User | undefined | null {
    if (!min)
      return this.currentUser;
    else return { id: this.currentUser?.id, name: this.currentUser?.name, initials: this.currentUser?.initials, emailAddress: this.currentUser?.emailAddress, username: this.currentUser?.username, userRole: this.currentUser?.userRole }
  }

  getCurrentUserInfo(): User | undefined | null {
    return { id: this.currentUser?.id, name: this.currentUser?.name, initials: this.currentUser?.initials, emailAddress: this.currentUser?.emailAddress, username: this.currentUser?.username }
  }

  getUsers() {
    this.loadUsers();
    return this.users ?? [];
  }

  getUser(id?: number) {
    this.getUsers();
    return this.users.find(u => u.id == id);
  }

  getUserInfo(id: number): User | undefined | null {
    const user = this.users.find(u => u.id == id);
    return { id: user?.id, name: user?.name, initials: user?.initials, emailAddress: user?.emailAddress, username: user?.username }
  }

  getMessage(val: number | string): MessageBanner {
    if (typeof (val) === 'string')
      return this._mbs.get(val);
    // if (typeof (val) === 'number')
    //   return this._mbs.getById(val) as MessageBanner;
    return {} as MessageBanner;
  }

  hasPrivilege(privilege: PrivilegeEnum): boolean {
    return this._tis.hasPrivilege(privilege) ?? false;
  }

  /**
     * Determines whether the Current User roles includes any of the roles in the roles array, returning true or false as appropriate.
     * @param roles The array of Roles to evaluate, can be a Role class array or Role Enumeration array or number array.
  */
  hasRoles(roles?: Role[] | Roles[] | number[] | null) {
    if (!roles) return false;
    let roleIds = [];

    if (roles.some((r: any) => this.isRole(r))) {
      roleIds = (roles as Role[]).map((r: Role) => r?.id);
    } else {
      roleIds = roles as number[];
    }
    const intersect = utils.intersect(this.currentUser?.userRole?.map((x) => x.roleID), roleIds);
    return intersect;
  }

  private isRole(r: any) {
    return typeof r == 'object';
  }


  isSA() {
    return this.hasRoles([Roles.SA]) ?? false;
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
    this.usersSubs?.unsubscribe();
    this.messageBannersSubs?.unsubscribe();
  }

  enableHTMLCodeOnTooltips() {
    try {
      Object.defineProperty(TooltipComponent.prototype, 'message', {
        set(v: any) {
          const el = document.querySelectorAll('.mdc-tooltip__surface');

          if (el) {
            el[el.length - 1].innerHTML = v;
          }
        },
      });
    } catch (error) {

    }
  }

  @HostBinding('attr.title')
  get disableTitleAttribute(): null {
    return null; // Return null to prevent the title attribute from being added
  }

  observeResize() {
    const element = $("#left-nav-buttons")[0] as Element;
    const buttons = $("#sidenav-content-buttons")[0];
    const resizeObserver = new ResizeObserver(() => {
      this.setHeights();
    });
    if (element)
      resizeObserver.observe(element);
    if (buttons)
      resizeObserver.observe(buttons);
    window.addEventListener(
      "resize",
      () => {
        this.setHeights();
      },
      true
    );
  }

  formatDate(date: Date | undefined | string | null): string | null {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'MM-dd-yyyy');
  }

  formatDatePipe(date: Date | string, format: string): string {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, format) || '';
  }

  stripDesc(html: any) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    if (doc.body.textContent == 'undefined') doc.body.textContent = '';
    return doc.body.textContent || "";
  }

  setHeights(offset: number = 0) {
    setTimeout(() => {
      const sideNav = document.querySelector('mat-sidenav');
      if (sideNav) {
        const sideNavHeight = sideNav.clientHeight;
        const buttonsElement = $('#left-nav-buttons')[0];
        const buttonsHeight = buttonsElement?.clientHeight ?? 0;
        const tableContainer = $('.left-table-container')[0];
        if (tableContainer)
          tableContainer.style.height = (sideNavHeight - buttonsHeight - 102 + offset) + 'px';
      }
    }, 300);
  }

  setFormDirty(val: boolean = true) {
    this._cds.formDirty = val;
  }

  isformDirty() {
    return this._cds.formDirty;
  }

  async canDeactivate(): Promise<boolean> {
    if (this.isformDirty())
      return await this._cds.canDeactivate();
    else return true;
  }

  displayObject(e: any) {
    return e ? e.name : null;
  }

  getErrorMsg(control?: AbstractControl | null): string | null {
    if (control) {
      if (control.hasError("required")) {
        return "You must enter a value";
      }
      if (control.hasError("hasWhiteSpace") || control.hasError("invalid")) {
        return "Enter a valid value";
      }
      if (control.hasError("invalidDate") && control.errors && control.errors["invalidDate"]) {
        return "Enter a valid date";
      }
      if (control.hasError("check")) {
        return "Check at least one checkbox";
      }
      if (control.invalid) {
        return "Enter a valid value";
      }
    }
    return null;
  }

  scrollToTop(e: any = window) {
    if (e == window)
      $(window).scrollTop(0);
    else {
      let element = $(e)[0];
      if (!element) element = $('.' + e);
      if (element) {
        element.animate({ scrollTop: 0 });
      }
    }
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  toggleSidenav() {
    this.isExpanded = !this.isExpanded;
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  deleteSerialNo(serialNo?: string) {
    const BASE_URL = environment.urls.baseApiUrl; // Api URL
    return this._http.delete(BASE_URL + '/SerialNumbers/' + serialNo);
  }

  sanitize(value?: string | null): SafeHtml {
    if (value)
      return this._san.bypassSecurityTrustHtml(value);
    return value ?? '';
  }

  clearInputValue(event: MatChipInputEvent) {
    const input = event.input;
    if (input) {
      input.value = '';
    }
  }

  validateRequiredControl(control: AbstractControl | null, label: string, value: boolean, prop?: string) {
    if ((!prop && !control?.value) || (prop && !control?.value[prop])) {
      control?.setErrors({ required: true });
      this.alert.message('generic_NotBlank', [new MessagePlaceholder('{what}', label)]);
      return false;
    }
    return value;
  }

  async checkOpenDocument<T extends IOpenDocument>(type: FormType | number, documents: T[]): Promise<{ document: T; closed: boolean; } | null> {
    const document = await this.redirect.getDocument(type);
    if (document) {
      const found = documents.find(a => a.id == document?.formID);
      let closed = false;
      switch (found?.type) {
        case FormType.PPSTB:
        case FormType.EBF:
          closed = found.formVersion?.statusID == FormStatusEnum.Closed || found.formVersion?.statusID == FormStatusEnum.Canceled;
          break;

        default:
          if (document.category == DocumentCategory.Checklist)
            closed = found?.checklistStatus?.formStatusID == FormStatusEnum.Closed || found?.checklistStatus?.formStatusID == FormStatusEnum.Canceled;
          else
            closed = found?.status == FormStatusEnum.Closed || found?.status == FormStatusEnum.Canceled;

          break;

      }
      return found ? { document: found as T, closed } : null;
    }
    return null;
  }

  setOpenDocument(type: FormType, id?: number) {
    this.redirect.document = null;
    if (id) {
      const open = this.redirect.openDocuments.find(o => o.type == type);
      if (!open)
        this.redirect.openDocuments.push({ id, type });
      else {
        open.id = id;
      }
    }
    else {
      const index = this.redirect.openDocuments.findIndex(o => o.type == type);
      this.redirect.openDocuments.splice(index, 1);
    }
  }

  async showAproveToActiveMessage(): Promise<boolean> {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.getMessage('toActiveWarning').description,
        icon: 'warn',
      },
    });
    return await confirm.afterClosed().toPromise();
  }

  isAnyDialogOpen(): boolean {
    return this.dialog.openDialogs.length > 0;
  }

  setSort<T>(dataSource: MatTableDataSource<T>, sort: MatSort) {
    setTimeout(() => {
      dataSource.sortData = (data, sort) => {
        const active = sort.active;
        const direction = sort.direction;

        if (!active || direction === '') {
          return data;
        }

        return data.sort((a, b) => {
          const valueA = dataSource.sortingDataAccessor(a, active);
          const valueB = dataSource.sortingDataAccessor(b, active);

          const comparatorResult = this.utils.sortArrayAlphabeticallyWithComplexNumbers(valueA?.toString(), valueB?.toString());

          return direction === 'asc' ? comparatorResult : -comparatorResult;
        });
      };
      if (sort)
        dataSource.sort = sort;
      else {
        setTimeout(() => {
          this.setSort<T>(dataSource, sort);
        }, 500);
      }
    }, 500);
  }

}



enum LoadingOrigin {
  Side = 1, Buttons = 2, Main = 3
}


interface IOpenDocument {
  id?: number;
  status?: FormStatusEnum;
  type?: FormType | number | null;
  formVersion?: FormVersion;
  checklistStatus?: ChecklistExecStatus;
}
