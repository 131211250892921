<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-2">
        <h6>{{ number ?? "#" }}</h6>
      </div>
      <div class="col-9 pt-2">
        <mat-label
          [innerHTML]="question ?? '' | placeholderHighlight : editor"
        ></mat-label>
        <div class="d-flex justify-content-start">
          <mat-label
            *ngIf="radioQuestion"
            [innerHTML]="radioQuestion | placeholderHighlight : editor"
          ></mat-label>
          <div class="ml-2">
            <mat-radio-group
              class="d-flex"
              [disabled]="disabled || checked || (radioSelection ?? 0) > 0"
            >
              <mat-radio-button
                class="mr-4"
                *ngFor="let r of radioOptions"
                [checked]="r.checked"
                [value]="r.id"
                (change)="radioChange($event)"
              >
                <span
                  [innerHTML]="r.label | placeholderHighlight : editor"
                ></span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="col-2 d-flex flex-row justify-content-end align-items-center">
        <mat-checkbox-with-roles
          [checked]="checked"
          [disabled]="disabled || unselected()"
          (change)="checkboxChanged($event)"
          color="primary"
          [roleCodes]="roleCodes"
          *ngIf="checkboxVisible"
          [warning]="warning && checkboxVisible ? moreInfo : null"
          [user]="user"
          [date]="date"
        ></mat-checkbox-with-roles>
      </div>
    </div>
  </div>
</div>
