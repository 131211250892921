<div class="row" [formGroup]="formGroup">
  <div class="col-12">
    <mat-form-field appearance="outline" class="no-subscript">
      <mat-label>Statuses</mat-label>
      <mat-chip-grid
        #chipListStatus
        aria-label="Status selection"
        [disabled]="false"
      >
        <mat-chip-row
          class="white small"
          [ngClass]="getClass(status)"
          *ngFor="let status of statusPermissions"
          [selectable]="true"
          [removable]="true"
          (removed)="removeStatus(status)"
          [matTooltip]="status.name ?? ''"
        >
          <span class="white"> {{ status.name }}</span>
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
        <input
          #statusInput
          formControlName="statusCtrl"
          [matAutocomplete]="autoStatus"
          [matChipInputFor]="chipListStatus"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [(ngModel)]="statusTemp"
          [matChipInputAddOnBlur]="true"
          (keyup)="statusChanged($event)"
          (matChipInputTokenEnd)="clearInputValue($event)"
          #trigger="matAutocompleteTrigger"
        />
      </mat-chip-grid>
      <mat-autocomplete
        #autoStatus="matAutocomplete"
        (optionSelected)="selectedStatus($event)"
      >
        <mat-option
          *ngFor="let status of reviewStatusesFiltered"
          [value]="status"
          matTooltip="{{ status.name }}"
        >
          {{ status.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error>You must choose at least one Status</mat-error>
    </mat-form-field>
  </div>
</div>
