import { Component, Input, OnChanges, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { WfTaskLocalResource, WfSignature } from 'src/app/services/work-flow/work-flow';
import { ChecklistTemplate, Annotation, AnnotationStatus } from '../../checklists';

@Component({
  selector: 'checklist-builder-annotations',
  templateUrl: './checklist-builder-annotations.component.html',
  styleUrls: ['./checklist-builder-annotations.component.scss']
})
export class ChecklistBuilderAnnotationsComponent implements OnChanges, OnDestroy {

  @Input() task!: WfTaskLocalResource;
  @Input() s!: WfSignature;
  @Input() currentVersion!: ChecklistTemplate;
  @Output() changed = new EventEmitter<WfTaskLocalResource>();

  annotations!: Annotation[];
  annotations$!: Observable<Annotation[]>;
  annotationsSubs!: Subscription;
  hasAnnotations!: boolean;

  constructor(
    private store: Store
  ) { }

  ngOnDestroy(): void {
    this.annotationsSubs?.unsubscribe();
  }

  ngOnChanges() {
    this.loadAnnotations();
  }

  loadAnnotations() {
    this.annotations$ = this.store.select(state => state.Annotations.data);
    this.annotationsSubs = this.annotations$.pipe(distinctUntilChanged()).subscribe(data => {
      if (data?.length) {
        this.annotations = data.filter(a => a.wfTaskLocalID == this.task.id);
        this.hasAnnotations = this.annotations?.filter(x => x.status == AnnotationStatus.Active).length > 0;
      }
    });
  }

}
