import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MenuItem, MenuItemTypes } from './head-menu.models';
import { HeadMenuService } from './head-menu.service';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { AppStateService } from 'src/app/store/app-state-service';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { PendingApprovalsV2Service } from '../../pending-approvals-v2/pending-approvals-v2.service';

@Component({
  selector: 'head-menu',
  templateUrl: './head-menu.component.html',
  styleUrls: ['./head-menu.component.scss']
})
export class HeadMenuComponent implements OnInit, OnDestroy, OnChanges {

  @Input() user?: User;

  currentUser!: User | null;

  menuItems: MenuItem[] = [];
  menuItems$!: Observable<MenuItem[]>;
  subscription: Subscription = new Subscription;
  showingMenu: MenuItem[] = [];

  isLoading = true;
  menuItemTypes = MenuItemTypes;
  unsubs: Subscription;
  showCarrousel = false;

  pendingApprovals?: number;
  myPendingChanges?: number;

  constructor(
    private _service: HeadMenuService,
    private store: Store,
    private ass: AppStateService,
    public pendingApprovalsService: PendingApprovalsV2Service
  ) {
    this.unsubs = this.ass.AppState.subscribe(appState => {
      this.currentUser = appState.currentUser;
      this.load();
    });
    this.pendingApprovalsService.pendingCatalogsSubject.subscribe(data => {
      this.load();
    });
  }

  load() {
    this.loadMenuItems();
    this.pendingApprovals = this.pendingApprovalsService.pendingApprovals?.total;
    this.myPendingChanges = this.pendingApprovalsService.myPendingChanges?.total;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    this.unsubs.unsubscribe();
  }

  ngOnInit() {
    this.loadMenuItems();
  }

  loadMenuItems() {
    this.isLoading = true;
    this.menuItems$ = this.store.select(state => state.MenuItem.data);
    this.subscription = this.menuItems$.subscribe(data => {
      if (data.length) {
        this.menuItems = data;
        const menuItems = this._service.calculateRouterLinks(this.menuItems.filter(x => !x.parentMenuItemID));
        this.menuItems = menuItems.sort((a, b) => a.order - b.order);
        if (this.menuItems.length > 9) {
          this.showCarrousel = true;
          const pageAmount = 9;
          if (this.menuItems.length > pageAmount) {
            this.showingMenu = this.menuItems.slice(0, pageAmount);
          } else {
            this.showingMenu = this.menuItems;
          }
        } else {
          this.showCarrousel = false;
          this.showingMenu = this.menuItems;
        }
        this.isLoading = false;
      }
    });
  }

  next(goNext: boolean) {
    const pageAmount = 9;
    if (goNext) {
      const lastItem = this.menuItems.indexOf(this.showingMenu[this.showingMenu.length - 1]);
      if (lastItem + pageAmount >= this.menuItems.length) {
        if (!this.showingMenu.includes(this.menuItems[this.menuItems.length - 1])) {
          this.showingMenu = this.menuItems.slice(lastItem, this.menuItems.indexOf(this.menuItems[this.menuItems.length - 1]) + 1);
        }
      } else {
        this.showingMenu = this.menuItems.slice(lastItem, lastItem + pageAmount);
      }
    } else {
      const firstItem = this.menuItems.indexOf(this.showingMenu[0]);
      if (firstItem - pageAmount <= 0) {
        this.showingMenu = this.menuItems.slice(0, pageAmount);
      } else {
        this.showingMenu = this.menuItems.slice(firstItem - pageAmount, firstItem);
      }
    }
  }

  openComponent(name: string) {
    this._service.openComponent(name);
  }
}
