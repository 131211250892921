import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, Injector, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Roles } from 'src/app/common/enumerations/enumerations';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { APLCategory } from 'src/app/components/apl/services/apl-category.model';
import { APLGroup } from 'src/app/components/apl/services/apl-group.model';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { ProcedureCredited } from '../../models/procedure-credited.model';
import { ProcedureTrainingCredited } from '../../models/procedure-training-credited.model';
import { ProcedureCreditedService } from '../../services/procedure-credited.service';
import { CreditedDatePopupComponent } from '../credited-date-popup/credited-date-popup.component';
import { Procedure } from '../../models/procedure.model';


@Component({
  selector: 'procedure-trainings-table-component',
  templateUrl: './procedure-trainings-table.component.html',
  styleUrls: ['./procedure-trainings-table.component.scss']
})
export class ProcedureTrainingsTableComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {


  columns: string[] = ['procedureNumber', 'title', 'currentRevision', 'revisionCredited', 'obligationType', 'dateCredited', 'creditedBy', 'applicableRoles', 'actions'];

  // Inputs
  @Input() user?: User | null;
  @Input() trainings: any[] = [];
  @Input() supervised: User[] = [];
  @Input() label_procedure_number = '';
  @Input() label_procedure_title = '';
  @Input() label_training_complete_option = '';
  @Input() label_training_remove_option = '';
  @Input() admin = false;
  @Input() completeTrainingInfo: any;
  @Input() extraTrainingsPermission = false;
  @Input() filter: any;
  @Input() showAppendices?: boolean;

  proceduresBeingCompleted: number[] = [];

  @Output() deleteProcedureTraining: EventEmitter<{ procedureId: number, userId: number }> = new EventEmitter<{ procedureId: number, userId: number }>();
  @Output() loading = new EventEmitter<boolean>();

  aplCategories$!: Observable<APLCategory[]>;
  aplCategories!: APLCategory[];
  aplCategoriesSubs!: Subscription;
  aplCategoryRoles: Role[] = [];

  aplGroups!: APLGroup[];
  aplGroups$!: Observable<APLGroup[]>;
  aplGroupsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private procedureCreditedService: ProcedureCreditedService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.trainings) {
      this.trainings.filter = this.filter;
    }
    this.loadAPLCategories();
  }

  ngOnInit(): void {
    this.setLabels();
  }

  override ngOnDestroy(): void {
    this.aplGroupsSubs?.unsubscribe();
    this.aplCategoriesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadAPLCategories() {
    this.aplCategories$ = this.store.select(state => state.APLCategory.APLCategories);
    this.aplCategoriesSubs = this.aplCategories$.subscribe(data => {
      if (data?.length && this.user?.id) {
        this.aplCategories = data.filter(x => x.aplCategoryManagers.map(m => m.userID).includes(this.user?.id ?? 0));
        this.loadAPLGroups();
      }
    });
  }

  loadAPLGroups() {
    this.aplGroups$ = this.store.select(state => state.APLGroup.all);
    this.aplGroupsSubs = this.aplGroups$.subscribe(data => {
      this.aplGroups = data.filter(g => this.aplCategories.map(c => c.aplCategoryMasterID).includes(g.aplGroupMaster?.aplCategoryMasterID ?? 0) || g.aplGroupManagers?.map(m => m.userID).includes(this.user?.id) || g.aplProcedureTrainingCoordinators?.map(c => c.userID).includes(this.user?.id));
      this.aplGroups.map(g => {
        const roles = g.aplGroupRoles?.filter(gr => gr.role).map(gr => gr.role as Role);
        if (roles)
          this.aplCategoryRoles.push(...roles);
      });
    });
  }

  canComplete(e: any): boolean {
    return this.completeTrainingInfo?.user?.id == this.user?.id && e.procedureCredited?.procedure?.version != e.procedure?.version && e.procedureCredited?.status != 0 && this.proceduresBeingCompleted.indexOf(e.procedure?.id) === -1;
  }

  canApprove(e: any): boolean {
    return this.trainingCanBeApproved(e) && (this.utils.intersect(this.aplCategoryRoles.map(c => c.id), e.applicableRoles.map((a: any) => a.id)) || (this.admin || this.hasRoles([Roles.SA, Roles.PC])));
  }

  canCompleteAndApprove(e: any) {
    return this.hasRoles([Roles.PC]) && e.procedureCredited?.procedure?.version != e.procedure?.version && e.procedureCredited?.status != 0 && this.proceduresBeingCompleted.indexOf(e.procedure?.id) === -1;
  }

  canUndo(e: any): boolean {
    return this.completeTrainingInfo?.user?.id == this.user?.id && e.procedureCredited?.status == 0;
  }

  canDelete(e: any): boolean {
    return (!e.procedureCredited && this.extraTrainingsPermission) || (this.completeTrainingInfo?.user?.id == this.user?.id && !e.procedureCredited && !e.applicableRoles);
  }

  canRemoveTrainingCredit(e: any): boolean {
    return e.procedure.version == e.procedureCredited?.procedure?.version && (e.procedureCredited?.status == 1 && (this.canRemoveOnNormalSection(e) || this.canRemoveOnExtraSection(e)));
  }

  canRemoveOnNormalSection(e: any): boolean {
    return e.applicableRoles != '' && (this.user?.userRole?.some(r => r.roleID === Roles.SA || r.role?.code === 'PC' || r.role?.code === 'PTC') || this.utils.intersect(this.aplCategoryRoles.map(c => c.id), e.applicableRoles.map((a: any) => a.id)));
  }

  canRemoveOnExtraSection(e: any): boolean {
    return this.hasRoles([Roles.SA, Roles.PC]) && !e.applicableRoles;
  }

  trainingCanBeApproved(e: any): boolean {
    return this.completeTrainingInfo?.user?.id != this.user?.id && e.procedureCredited?.status == 0;
  }

  hasActions(e: any): boolean {
    return this.canComplete(e) || this.canApprove(e) || this.canUndo(e) || this.canDelete(e) || this.canRemoveTrainingCredit(e) || this.canCompleteAndApprove(e);
  }

  formatRoles(roles: Role[]) {
    return roles.map(x => x.code).join(', ');
  }

  complete(procedure: Procedure) {
    return new Promise((resolve, reject) => {
      this.dialog.open(YesNoDialogComponent, {
        width: '45vw',
        data: {
          message: this.label_training_complete_option,
          changeButtonLabels: true,
          labelButtonCancel: 'Cancel',
          labelButtonAccept: 'Confirm'
        }
      }).afterClosed().subscribe(
        data => {
          if (data) {
            this.loading.emit(true);
            this.proceduresBeingCompleted.push(procedure.id);
            this.procedureCreditedService.complete(procedure.id).subscribe(
              procedureCredited => {
                this.loading.emit(false);
                resolve(procedureCredited);
              },
              error => {
                if (error.status === 404 || error.status === 409) {
                  this.alert.error(error.error);
                } else {
                  this.alert.defaultError();
                }
                console.error(error);
              }, () => {
                this.removeProcedureBeingCompleted(procedure.id);
              }
            );
          }
        }
      );
    });

  }

  removeProcedureBeingCompleted(procedureID: number) {
    const procedureCompletedIndex = this.proceduresBeingCompleted.indexOf(procedureID);
    if (procedureCompletedIndex > -1) {
      this.proceduresBeingCompleted.splice(procedureCompletedIndex, 1);
    }
  }

  approve(procedureCredited: ProcedureCredited) {
    this.dialog.open(CreditedDatePopupComponent, {
      width: '25vw',
      data: {
        procedure: procedureCredited?.procedure
      }
    }).afterClosed().subscribe(
      data => {
        if (data) {
          this.loading.emit(true);
          const procedureTrainingCredited = data as ProcedureTrainingCredited;
          if (procedureCredited.id)
            this.procedureCreditedService.approve(procedureCredited?.id, procedureTrainingCredited).subscribe(
              () => {
                this.loading.emit(false);
              },
              error => {
                if (error.status === 404 || error.status === 409) {
                  this.alert.error(error.error);
                } else {
                  this.alert.defaultError();
                }
                console.error(error);
              },
            );
        }
      }
    );
  }

  completeAndApprove(procedure: Procedure) {

    this.dialog.open(CreditedDatePopupComponent, {
      width: '25vw',
      data: {
        procedure: procedure
      }
    }).afterClosed().subscribe(
      data => {
        if (data) {
          this.loading.emit(true);
          this.proceduresBeingCompleted.push(procedure.id);
          this.procedureCreditedService.complete(procedure.id, this.completeTrainingInfo?.user?.id).subscribe(
            procedureCredited => {
              if (procedureCredited.id) {
                const procedureTrainingCredited = procedureCredited as ProcedureTrainingCredited;
                procedureTrainingCredited.creditDate = data.creditDate;
                this.removeProcedureBeingCompleted(procedure.id);
                this.procedureCreditedService.approve(procedureCredited?.id, procedureTrainingCredited).subscribe(
                  () => {
                    this.loading.emit(false);
                  },
                  error => {
                    if (error.status === 404 || error.status === 409) {
                      this.alert.error(error.error);
                    } else {
                      this.alert.defaultError();
                    }
                    console.error(error);
                  },
                );
              }
            },
            error => {
              if (error.status === 404 || error.status === 409) {
                this.alert.error(error.error);
              } else {
                this.alert.defaultError();
              }
              console.error(error);
            }, () => {

            }
          );
        }
      });
  }

  // async completeAndApprove2(procedureID: number) {
  //   const procedureCredited = await this.complete(procedureID);
  //   this.approve(procedureCredited as ProcedureCredited);
  // }

  getInitials(e: any) {
    if (e.creditedBy) {
      return e.creditedBy?.initials;
    }
    else if (e.creditedDate &&
      e.creditedRev &&
      (e.procedureCredited?.status != 0 || e.procedure?.status != 1) &&
      !(
        e.procedure?.revision != e.procedureCredited?.procedure?.revision &&
        e.procedure?.status == 1
      )) {
      return 'ASCC'
    }

  }

  removeProcedureCredited(procedureCreditedID: number) {
    this.dialog
      .open(YesNoDialogComponent, {
        width: '45vw',
        data: {
          message: this.label_training_remove_option,
          changeButtonLabels: true,
          labelButtonCancel: 'Cancel',
          labelButtonAccept: 'Confirm',
        },
      }).afterClosed().subscribe((data) => {
        if (data) {
          this.loading.emit(true);
          this.procedureCreditedService
            .removeProcedureCredited(procedureCreditedID)
            .subscribe(
              () => {
                this.loading.emit(false);
              },
              (error) => {
                if (error.status === 404 || error.status === 409) {
                  this.alert.error(error.error);
                } else {
                  this.alert.defaultError();
                }
                console.error(error);
              }
            );
        }
      });
  }

  deleteTraining(procedureId: number) {
    this.deleteProcedureTraining.emit({ procedureId, userId: this.completeTrainingInfo?.user?.id });
  }

  setLabels() {
    this.label_procedure_number = this.getMessage('ALSPC_ProcedureTraining_ProcedureNumber')?.description;
    this.label_procedure_title = this.getMessage('ALSPC_ProcedureTraining_Title')?.description;
    this.label_training_complete_option = this.getMessage('Training_Complete_Option')?.description;
    this.label_training_remove_option = this.getMessage('Training_Remove_Option')?.description;
    if (!this.label_procedure_number) {
      setTimeout(() => {
        this.setLabels();
      }, 1000);
    }
  }

}
