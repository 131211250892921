import { Component, Input, SimpleChanges } from '@angular/core';
import { Procedure } from '../../../models/procedure.model';
import { RelatedLinkDocumentBase } from 'src/app/common/models/related-link-document-base.model';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'procedure-trainings-appendices',
  templateUrl: './procedure-trainings-appendices.component.html',
  styleUrls: ['./procedure-trainings-appendices.component.scss']
})
export class ProcedureTrainingsAppendicesComponent {
  @Input() procedure?: Procedure;
  @Input() showAppendices?: boolean;

  appendices?: RelatedLinkDocumentBase[];

  ngOnChanges(changes: SimpleChanges): void {
    this.appendices = this.procedure?.procedureAppendices?.sort((a, b) => utils.sortArrayAlphabeticallyWithComplexNumbers(a.text, b.text));
  }
}
