<div class="card p-0">
  <div class="card-header p-3">
    <h5>
      {{ annotation ? "Edit " : "Create " }}
      {{ isResponse ? "Response" : isComment ? "Comment" : "Suggestion" }}
    </h5>
  </div>
  <div class="card-body p-3 scrollbar overflow-auto" *ngIf="!isResponse">
    <div>
      <master-control
        [id]="s.id"
        [type]="s.type"
        [builder]="true"
        [editor]="false"
        [visible]="true"
        [disabled]="true"
        [enabled]="false"
        [approve]="false"
        [disapprove]="false"
        [unapprove]="false"
        [number]="s.number"
        [header]="s.name"
        [label]="s.name"
        [text]="s.question"
        [question]="s.question"
        [radioQuestion]="s.question2"
        [options]="s.options"
        [controlID]="s.value2"
        [checkbox_checked]="s.approved"
        [approved]="s.approved"
        [approvedBy]="s.approvedBy"
        [approvedOn]="s.approvedOn"
        [roleIds]=""
        [roleCodes]="s.roleCodes"
        [scheduleResourceId]="s.scheduleResourceId"
        [scheduleTypeId]="s.scheduleTypeId"
        [textBefore]="s.type == 9 ? s.question2 : s.question"
        [textAfter]="s.type == 9 ? s.question : s.question2"
        [textAfter2]="s.question3"
        [textValue]="s.textValue"
        [checkboxVisible]="s.visible != undefined ? s.visible : true"
        [disableEdit]="true"
        [dateValue1]="s.dateValue1"
        [dateValue2]="s.dateValue2"
        [dateValue3]="s.dateValue3"
        [configuration]="s.configuration"
        [editMode]="s.editMode"
        style="width: 100%"
      >
      </master-control>
    </div>
  </div>
  <div class="card-footer p-3">
    <div class="row">
      <div class="col-11 h100">
        <comments-box
          title="Suggestion comments"
          [textValue]="annotationText"
          (textChanged)="annotationText = $event"
          [serialNo]="currentChecklist.serialNo"
          [formType]="currentChecklist.documentType?.code"
        ></comments-box>
        <!-- <quill-editor
          class="scrollbar"
          [(ngModel)]="annotationText"
          [modules]="quillConfig"
          [ngModelOptions]="{ standalone: true }"
        ></quill-editor> -->
      </div>
      <div class="col-1">
        <div class="flex-display flex-column">
          <button
            class="btn btn-green"
            (click)="accept()"
            [disabled]="annotationText == null"
          >
            <mat-icon>save</mat-icon>
          </button>
          <button class="btn btn-red mt-1" (click)="cancel()">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
