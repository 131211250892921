import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as OlogEmailActions from './olog-email.action';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';

@Injectable({
  providedIn: 'root'
})
export class OlogEmailStoreAndListenersService {

  constructor(
    private store: Store,
    private signalRService: SignalRService
  ) { }

  initStore() {
    this.store.dispatch(new OlogEmailActions.OlogEmailRefresh());
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.OLOGEMAIL, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new OlogEmailActions.OlogEmailCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new OlogEmailActions.OlogEmailUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new OlogEmailActions.OlogEmailDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new OlogEmailActions.OlogEmailRefresh());
      }
    });
  }
}
