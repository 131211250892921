import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { PrivilegeCategory } from '../../components/catalogs/roles/services/role-privilege';

@Injectable({
  providedIn: 'root'
})
export class PrivilegeCategoryService {

  public url: string = environment.urls.baseApiUrl;
  public api: string = this.url + '/PrivilegeCategories';

  constructor(
    public http: HttpClient
  ) { }

  getAll(): Observable<PrivilegeCategory[]> {
    return this.http.get<PrivilegeCategory[]>(this.api);
  }

  getByType(type: number): Observable<PrivilegeCategory[]> {
    return this.http.get<PrivilegeCategory[]>(this.api + '/Type/' + type.toString());

  }
}
