<div class="card">
  <div class="card-header">
    <span class="card-title"> {{ inputData.dialogTitle }}</span>
    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="card-body">
    <div class="row mb-2">
      <div class="col">
        <mat-checkbox (change)="addGuestChange($event)"
          >Add guest member</mat-checkbox
        >
      </div>
    </div>
    <form [formGroup]="reviewMemberForm" *ngIf="!addGuest">
      <div class="row" *ngIf="!addGuest">
        <div class="col">
          <mat-form-field>
            <mat-label>Role</mat-label>
            <mat-select
              [(ngModel)]="inputData.reviewMember.roleID"
              (selectionChange)="getUsersByRoleId($event.value)"
              formControlName="reviewRoleCtrl"
            >
              <mat-option *ngFor="let role of roles" [value]="role.id">
                {{ role.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="reviewRoleCtrl?.invalid">{{
              getErrorMsg(reviewRoleCtrl)
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label>Member</mat-label>
            <mat-select
              [(ngModel)]="inputData.reviewMember.userID"
              formControlName="reviewUserCtrl"
            >
              <mat-option *ngFor="let user of users" [value]="user.id">
                {{ user.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="reviewUserCtrl?.invalid">{{
              getErrorMsg(reviewUserCtrl)
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label>Category</mat-label>
            <mat-select
              [(ngModel)]="inputData.reviewMember.categoryID"
              formControlName="reviewCategoryCtrl"
            >
              <mat-option
                *ngFor="let category of inputData.reviewCategories"
                [value]="category.id"
              >
                {{ category.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="reviewCategoryCtrl?.invalid">{{
              getErrorMsg(reviewCategoryCtrl)
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>

    <form [formGroup]="guestMemberForm" *ngIf="addGuest">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Member name</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="guestName"
              formControlName="guestMemberNameCtrl"
            />
            <mat-error>{{ getErrorMsg(guestMemberNameCtrl) }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Category</mat-label>
            <input
              matInput
              type="text"
              formControlName="guestMemberCategoryCtrl"
              [(ngModel)]="guestCategory"
            />
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button class="cancel-btn" color="warn" (click)="close()">
        Cancel
      </button>
      <button
        [disabled]="reviewMemberForm.invalid && guestMemberForm.invalid"
        mat-flat-button
        class="accept-btn"
        color="primary"
        (click)="add()"
      >
        Add
      </button>
    </div>
  </div>
</div>
