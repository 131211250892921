import { Component, Inject, OnInit, AfterViewInit, ElementRef, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import imageCompressor from 'quill-image-compress';
import CustomImageResize from 'src/app/modules/libs/quill/quill-custom-image-resize/custom-image-resize';
import { utils } from 'src/app/modules/libs/utils';
import { ImageUploadService } from 'src/app/services/file/image-upload.service';
import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import ImageResize from 'quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module';
// import { ImageResize } from 'quill-image-resize-module';
import QuillMention from 'quill-mention';
import { ResourceType } from 'src/app/common/enumerations/enumerations';

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);
var Block = Quill.import('blots/block');
Block.tagName = 'DIV';  // Quill uses <p> by default
Quill.register(Block, true);
var bold = Quill.import('formats/bold');
bold.tagName = 'b';   // Quill uses <strong> by default
Quill.register(bold, true);

Quill.register('modules/customImageResize', CustomImageResize);
Quill.register('modules/imageCompressor', imageCompressor);
// Quill.register('modules/table', QuillTable);

Quill.register('modules/mention', QuillMention);

// const Size = Quill.import('attributors/style/size');
// Size.whitelist = ['14px']; // Add custom sizes
// Quill.register(Size, true);

@Component({
  selector: 'comments-box-edit',
  templateUrl: './comments-box-edit.component.html',
  styleUrls: ['./comments-box-edit.component.scss']
})
export class CommentsBoxEditComponent implements OnInit, AfterViewInit {

  textValue: string;
  serialNo: string;
  formType: string;
  title: string;
  minHeight: string;
  maxHeight: string;
  mentions?: any[];
  selectedMentions: number[] = [];

  public form!: FormGroup;
  quillCtrl = new FormControl();
  showHTML!: boolean;
  loading!: boolean;
  dialogId!: string;
  resourceTypeEnum = ResourceType;
  thisQuill: any;

  customShielding: boolean = false;

  imageMaxWidth = 300;
  imageSizes!: [{ name: 'Small'; val: 255; }, { name: 'Medium'; val: 400; }, { name: 'Original'; val: 0; }];
  fullScreen = true;
  quillEditorRef: any;
  quillColors = ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
    "#ffffff", "#ff5588", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
    "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
    "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
    "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"];

  quillConfig = {
    mention: {
      dataAttributes: ['id', 'value', 'isGroup', 'groupName', 'parentResourceType', 'childResourceType', 'shared', 'sharedWith', 'color', 'title'],
      mentionDenotationChars: ["@"],
      showDenotationChar: false, // Hide the "@" symbol in the mention dropdown
      blotName: 'styled-mention',
      source: (searchTerm: string, renderList: (matches: any[], searchTerm: any) => void) => {
        if (!this.mentions) return;

        let filteredMentions = this.mentions;

        if (searchTerm.length > 0) {
          filteredMentions = this.mentions.filter(item =>
            item.value.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }

        // Create group headers and organize items under them
        let matchesWithHeaders: any[] = [];
        let currentGroup: string | undefined;

        filteredMentions.forEach(item => {
          if (item.isGroup) {
            // Add group header
            if (currentGroup !== item.value) {
              matchesWithHeaders.push({
                id: `group-${item.value}`,
                value: item.value,
                isGroup: true,
                disabled: true
              });
              currentGroup = item.value;
            }
          } else {
            const isAlreadySelected = this.selectedMentions.includes(item.id);
            // Add shielding item
            matchesWithHeaders.push({
              id: item.id,
              value: item.value,
              groupName: item.groupName,
              parentResourceType: item.parentResourceType,
              childResourceType: item.childResourceType,
              shared: item.shared,
              sharedWith: item.sharedWith,
              title: item.title ?? '',
              isAlreadySelected // Store this property but do not use `disabled`
            });
          }
        });

        window.setTimeout(() => {
          renderList(matchesWithHeaders, searchTerm);
          setTimeout(() => this.observeDropdown(), 100); // Call with 'this'
        }, 500);
      },

      renderItem: (data: {
        isGroup?: boolean;
        value: string;
        parentResourceType?: number;
        childResourceType?: number;
        shared?: boolean;
        sharedWith?: any[];
        disabled?: boolean;
        color?: string;
        matTooltip?: string;
        title?: string;
        isAlreadySelected?: boolean;
      }) => {
        const div = document.createElement("div");
        div.innerText = data.value;

        // Apply different styles for group headers and regular items
        if (data.isGroup) {
          div.style.fontWeight = "bold"; // Highlight group names
          div.style.color = "#0366d6";
          div.style.padding = "4px";
          div.style.fontSize = "12px";
          div.style.borderBottom = "1px solid #ddd";
          div.style.pointerEvents = "none";
          div.style.opacity = "0.6";
          div.style.userSelect = "none"; // Prevent text selection
        } else {
          div.style.paddingLeft = "12px"; // Indent shielding items
          div.style.fontSize = "14px"; // Smaller font size for shielding items
          div.title = data.title ?? '';

          // Apply conditional styling based on shielding properties
          //TODO need to disable already selected, this isn't working
          if (data.isAlreadySelected) {
            div.style.color = "gray";
            // div.style.pointerEvents = "none"; // Prevent selection
            // div.style.opacity = "0.5";
            // data.color = "gray";
            div.setAttribute('data-disabled', 'true'); // Add custom attribute
          }
          else if (data.parentResourceType === this.resourceTypeEnum.Shutter ? data.shared : (data.sharedWith?.length ?? 0) > 0) {
            div.style.color = "red";
            data.color = "red";
          } else if (data.childResourceType && data.childResourceType > 99) {
            div.style.color = "darkorange";
            data.color = "darkorange";
          }
          else {
            div.style.color = "black";
            data.color = "black";
          }
        }

        return div;
      },
      onSelect: (item: any, insertItem: any) => {
        if (item.isGroup) return; // Prevent inserting group headers into the editor
        insertItem(item); // Insert mention into Quill editor
      }
    },
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
        [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
        ['table'], // Add the table option to the toolbar
        ['link', 'image', 'video'],
        ['clean'],
      ],
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range: any, context: any) => {
            return true;
          }
        }
      }
    },
    imageDrop: true,
    imageResize: true,
    customImageResize: true,
    imageCompressor: {
      quality: 0.3, // default
      maxWidth: this.imageMaxWidth, // default
      imageType: 'image/*', // default
      debug: true
    },
  };

  defaultSelect!: string;

  constructor(
    private service: ImageUploadService,
    public dialog: MatDialogRef<CommentsBoxEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private elem: ElementRef,
    private cdRef: ChangeDetectorRef) {
    this.textValue = data.textValue;
    this.serialNo = data.serialNo;
    this.formType = data.formType;
    this.title = data.title;
    this.minHeight = data.minHeight;
    this.maxHeight = data.maxHeight;
    this.mentions = data.mentions;
    this.selectedMentions = data.selectedMentions;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngAfterViewInit() {
    this.maximize();

    this.quillEditorRef = this.quillCtrl.valueChanges.subscribe(() => {
      const editor = document.querySelector('.ql-editor'); // Get the Quill editor content
      if (!editor) return;

      // Extract mention IDs from the editor
      const mentionsInEditor: number[] = [];
      editor.querySelectorAll('span[data-id]').forEach((mention) => {
        const id = Number(mention.getAttribute('data-id'));
        if (!isNaN(id)) {
          mentionsInEditor.push(id);
        }
      });
      this.selectedMentions = mentionsInEditor;
    });
  }

  buildForm() {
    this.form = new FormGroup({
      quillCrtl: this.quillCtrl
    });
  }

  // Observe the dropdown for changes and apply pointer-events to disabled items
  observeDropdown() {
    const dropdown = document.querySelector('.ql-mention-list-container');
    if (!dropdown) return;

    const applyStyles = () => {
      dropdown.querySelectorAll('div[data-disabled="true"]').forEach(div => {
        const parentLi = div.closest('li');
        if (parentLi) {
          parentLi.style.pointerEvents = 'none';
          parentLi.style.opacity = '0.5';
        }
      });
    };

    // Apply styles initially in case items are already present
    applyStyles();

    // Observe changes and re-apply styles when necessary
    const observer = new MutationObserver(() => {
      applyStyles();
    });

    observer.observe(dropdown, { childList: true, subtree: true });
  }

  created(quill: any) {
    $('.ql-container').css('min-height', this.minHeight);
    $('.ql-container').css('max-height', this.maxHeight);
    $('.ql-editor').css('max-height', this.maxHeight);
    $('.ql-editor').addClass('scrollbar');
    const dialogs = $('.mat-mdc-dialog-container');
    this.dialogId = dialogs[dialogs.length - 1].id;
    this.changed();
    quill.focus();
    setTimeout(() => quill.setSelection(quill.getLength(), 0));
    this.thisQuill = quill;
  }

  mouseOut() {
    // utils.removeExistingCustomContainers();
  }

  async save() {
    utils.removeExistingCustomContainers();
    this.loading = true;
    const textValue = await this.uploadImages(this.textValue);
    this.data.selectedMentions = this.selectedMentions;
    this.data.textValue = textValue;
    this.loading = false;
    this.dialog.close(this.data);
  }

  cancel() {
    this.dialog.close();
  }

  applyCustom() {
    const selected = this.thisQuill.getSelection().length;
    // If no selection toggle on or off
    if (!selected) {
      this.customShielding = !this.customShielding;
    }

    if (selected || this.customShielding) {
      this.thisQuill.format('color', 'blue');
      this.thisQuill.format('background', '#d3e1eb');
      this.thisQuill.format('bold', true);
    }
    else {
      this.thisQuill.format('color', false);
      this.thisQuill.format('background', false);
      this.thisQuill.format('bold', false);
    }
  }

  async uploadImages(textValue: string) {
    const text = await this.service.uploadImagesToServer(textValue, '/' + this.formType.toString()?.trim() + '/' + this.serialNo + '/Comments/');
    return text;
  }

  isShowHTML() {
    this.showHTML = !this.showHTML;
    this.cdRef.detectChanges();
    this.changed();
  }

  changed() {
    // const editorHeight = $('.edit-container').height() ?? 0;
    const windowHeight = $('#' + this.dialogId).height() ?? 0;
    $('.ql-container').height(windowHeight - 50);
    $('.html-textarea').height(windowHeight - 70);
    // if (editorHeight > windowHeight - 22) {
    //   $('#' + this.dialogId).height(editorHeight - 22);
    // }
  }

  maximize() {
    this.fullScreen = true;
    this.dialog.addPanelClass('maximized');
  }

  restore() {
    this.fullScreen = false;
    this.dialog.removePanelClass('maximized');
  }

  imageSizeSelected(e: { label: any; value: any; }) {
    this.elem.nativeElement.querySelector('.imageSizes .ql-picker-label').innerHTML
      = e.label + this.defaultSelect;
    this.imageMaxWidth = e.value;
    this.quillConfig.imageCompressor.maxWidth = this.imageMaxWidth;
    Quill.register('modules/imageCompressor', imageCompressor);
  }

  addTable(e: { clipboard: { dangerouslyPasteHTML: (arg0: string) => void; }; }) {
    var tableHtml = '<table><tr><td>Cell 1</td><td>Cell 2</td></tr><tr><td>Cell 3</td><td>Cell 4</td></tr></table>';
    e.clipboard.dangerouslyPasteHTML(tableHtml);
  }

}

// #region Mention style
const MentionBlot = Quill.import("blots/mention");
class StyledMentionBlot extends MentionBlot {
  static render(data: { value: string; color: string; title: string }) {
    const element = document.createElement("span");
    element.innerText = data.value;
    element.style.color = data.color;
    element.title = data.title;
    element.style.fontWeight = "bold";

    return element;
  }
}
StyledMentionBlot['blotName'] = "styled-mention";

Quill.register(StyledMentionBlot);
// #endregion
