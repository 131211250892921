import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PendingResourcesCreate, PendingResourcesDelete, PendingResourcesRefresh, PendingResourcesUpdate } from './pending-resources.action';
import { PendingResource } from 'src/app/services/pending-approvals/pending-approval';
import { CatalogService } from '../../catalog-service';

export class PendingResourcesStateModel {
  data: PendingResource[] = [];
}

@State<PendingResourcesStateModel>({
  name: 'PendingResources',
  defaults: {
    data: [],
  }
})
@Injectable()
export class PendingResourcesState {
  constructor(
    private _service: CatalogService,
  ) { }

  @Selector()
  static read(state: PendingResourcesStateModel) {
    return state.data;
  }

  @Action(PendingResourcesRefresh)
  refresh({ patchState }: StateContext<PendingResourcesStateModel>) {
    this._service.GetAllPendingResources().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(PendingResourcesCreate)
  create({ getState, patchState }: StateContext<PendingResourcesStateModel>, { item }: PendingResourcesCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(PendingResourcesUpdate)
  update({ getState, patchState }: StateContext<PendingResourcesStateModel>, { id, item }: PendingResourcesUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(PendingResourcesDelete)
  delete({ getState, patchState }: StateContext<PendingResourcesStateModel>, { id }: PendingResourcesDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }



}
