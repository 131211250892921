import { User } from "src/app/components/catalogs/user-catalog/services/user";


export class ProcedureNote {
  public id?: number;
  public procedureID?: number;
  public description?: string;
  public date?: Date;
  public userID?: number;
  public user?: User;
}
