<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-2 d-flex align-items-center">
        <span class="card-title">Search ASCC</span>
      </div>
      <div class="col-4 d-flex align-items-center">
        <filter-box
          (changed)="applyFilter($event)"
          class="full-width"
        ></filter-box>
      </div>
      <div class="col-3 d-flex align-items-center">
        <mat-form-field class="filter no-subscript mb-0">
          <mat-label>Document Type</mat-label>
          <mat-select (selectionChange)="filterType($event)">
            <mat-option [value]="0">All</mat-option>
            <mat-option
              *ngFor="let document of documentTypes"
              [value]="document.id"
            >
              {{ document.description ? document.description : document.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-1 d-flex align-items-center">
        <mat-form-field class="filter no-subscript mb-0">
          <mat-label>Year</mat-label>
          <mat-select (selectionChange)="filterYear($event)">
            <mat-option [value]="0">All</mat-option>
            <mat-option *ngFor="let year of years" [value]="year">
              {{ year }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <button
      mat-mini-fab
      class="closex"
      color="warn"
      (click)="dialogRef.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <div class="container-table">
          <div class="mat-container table-container scrollbar">
            <mat-table
              [dataSource]="dataSource"
              matSort
              (matSortChange)="sortData($event)"
            >
              <!-- Description Column -->
              <ng-container matColumnDef="name">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  class="custom-sort-arrow"
                >
                  Serial No
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <div [ngClass]="element.formStatus?.color">
                    <span
                      class="bold h6"
                      [innerHTML]="
                        element.serialNo | highlightSearch : filterValue
                      "
                    ></span>
                    <span *ngIf="element.formStatus"
                      ><br />{{ element.formStatus.name }}</span
                    >
                  </div>
                </mat-cell>
              </ng-container>

              <!-- Status Column -->
              <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                  Description
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <div
                    class="description-wrapper"
                    [innerHTML]="
                      element.description | highlightSearch : filterValue
                    "
                  ></div>
                </mat-cell>
              </ng-container>

              <!-- Location Columns -->
              <ng-container matColumnDef="locationNames">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Locations
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  [innerHTML]="
                    element.locationNames | highlightSearch : filterValue
                  "
                ></mat-cell>
              </ng-container>

              <!-- Requester Column -->
              <ng-container matColumnDef="userInfo">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  User Info
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  class="d-flex flex-column justify-content-center align-items-start"
                >
                  <div class="py-1">
                    Creator (Initiator):
                    <span
                      class="bold"
                      [innerHTML]="
                        element.createdByName | highlightSearch : filterValue
                      "
                    ></span>
                    on
                    <span class="date bold">
                      {{ element.createdOn | date : "M/d/yy" }}</span
                    >
                  </div>
                  <div class="py-1">
                    Requester:
                    <span
                      class="bold"
                      [innerHTML]="
                        element.requesterName | highlightSearch : filterValue
                      "
                    ></span>
                  </div>
                  <div class="py-1">
                    Last Update:
                    <span class="date bold">
                      {{ element.updatedOn | date : "M/d/yy" }}</span
                    >
                  </div>
                </mat-cell>
              </ng-container>

              <!-- Work Related Column -->
              <ng-container matColumnDef="workRelated">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Work Related
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  class="d-flex flex-column align-items-start justify-content-around"
                  ><span
                    [innerHTML]="element.gen1 | highlightSearch : filterValue"
                  ></span
                ></mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
                class="hoverRow"
                (click)="openDocument(row)"
              ></mat-row>
            </mat-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <small
      >Search results: <b>{{ documentsFiltered?.length }}</b></small
    >
  </div>
</div>

<div class="section">
  <div class="row pb-1">
    <div class="col-lg-4 col-sm-12 col-12"></div>

    <!-- <div class="col-lg-2 offset-4 col-sm-12 col-12">

    </div>

    <div class="col-lg-2 col-sm-12 col-12">

    </div>
  </div> -->
  </div>
  <!--</div>-->
</div>
