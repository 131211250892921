import { APLCategoryMasterRoleCatalog } from "./apl-category-master-role-catalog.model";


export class APLGroupMasterRoleCatalog {
  public id!: number;
  public aplCategoryMaster?: APLCategoryMasterRoleCatalog;

  constructor() {
    this.aplCategoryMaster = this.aplCategoryMaster ?? new APLCategoryMasterRoleCatalog();
  }
}
