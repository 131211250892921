import { ProcedureTraining } from "src/app/components/procedure/models/procedure-training.model";

export class ProcedureTrainingRefresh {
  static readonly type = "[ProcedureTraining] Refresh";
}

export class ProcedureTrainingCreate {
  static readonly type = "[ProcedureTraining] Create";

  constructor(public item: ProcedureTraining) { }
}

export class ProcedureTrainingUpdate {
  static readonly type = "[ProcedureTraining] Update";

  constructor(public id: number, public item: ProcedureTraining) { }
}

export class ProcedureTrainingDelete {
  static readonly type = "[ProcedureTraining] Delete";

  constructor(public id: number) { }
}
