<div class="mat-container mb-3" *ngIf="proceduresFiltered?.length">
  <table
    class="scrollbar"
    mat-table
    [dataSource]="procedureDS"
    matSort
    (matSortChange)="sortData($event)"
  >
    <ng-container matColumnDef="procedureNumber" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span [innerHTML]="labelNumber"></span>
      </th>
      <td mat-cell *matCellDef="let e" class="pr-2">
        <span
          *ngIf="e.fileID == null || e.fileID == undefined"
          [ngClass]="{ inactive: e.status == 0 }"
          [innerHTML]="e.procedureNumber | highlightSearch : filter"
          class="bold nowrap"
        ></span>
        <a
          *ngIf="!(e.fileID == null || e.fileID == undefined)"
          [ngClass]="{ inactive: e.status == 0 }"
          href="{{ e.file.url }}"
          target="_blank"
          [innerHTML]="e.procedureNumber | highlightSearch : filter"
          class="bold nowrap"
        ></a>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span [innerHTML]="labelTitleLocationGlobal"></span>
      </th>
      <td mat-cell *matCellDef="let e" class="pr-2">
        <span
          [ngClass]="{ inactive: e.status == 0 }"
          [innerHTML]="e.title | highlightSearch : filter"
          class="bold"
        ></span
        ><br />
        <ng-container *ngFor="let appendix of sortedAppendices(e)">
          <span
            [ngClass]="{ inactive: e.status == 0 }"
            *ngIf="
              (appendix.file == null || appendix.file == undefined) &&
              appendix.url == null
            "
            [innerHTML]="appendix.text | highlightSearch : filter"
          ></span>
          <a
            [ngClass]="{ inactive: e.status == 0 }"
            *ngIf="
              (appendix.file == null || appendix.file == undefined) &&
              appendix.url != null
            "
            href="{{ appendix.url }}"
            target="_blank"
            [innerHTML]="appendix.text | highlightSearch : filter"
          ></a>
          <a
            [ngClass]="{ inactive: e.status == 0 }"
            *ngIf="appendix.file != null && appendix.file != undefined"
            href="{{ appendix.file.url }}"
            target="_blank"
            [innerHTML]="appendix.text | highlightSearch : filter"
          ></a>
          <br />
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="scheduleType">
      <th mat-header-cell *matHeaderCellDef>
        <span>Schedule Type</span>
      </th>
      <td mat-cell *matCellDef="let e" class="pr-2">
        <span [ngClass]="{ inactive: e.status == 0 }">{{
          e.scheduleType
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>
        <span>Location</span>
      </th>
      <td mat-cell *matCellDef="let e" class="pr-2 nowrap">
        <span [ngClass]="{ inactive: e.status == 0 }"
          ><b>{{ e.locationFreeText }}</b></span
        ><br />
        <ng-container *ngFor="let appendix of e.procedureAppendices">
          <span
            [ngClass]="{ inactive: e.status == 0 }"
            *ngIf="
              (appendix.file == null || appendix.file == undefined) &&
              appendix.url == null
            "
            [innerHTML]="appendix.text | highlightSearch : filter"
          ></span>
          <a
            [ngClass]="{ inactive: e.status == 0 }"
            *ngIf="
              (appendix.file == null || appendix.file == undefined) &&
              appendix.url != null
            "
            href="{{ appendix.url }}"
            target="_blank"
            [innerHTML]="appendix.text | highlightSearch : filter"
          ></a>
          <a
            [ngClass]="{ inactive: e.status == 0 }"
            *ngIf="appendix.file != null && appendix.file != undefined"
            href="{{ appendix.file?.url }}"
            target="_blank"
            [innerHTML]="appendix.text | highlightSearch : filter"
          ></a>
          <br />
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="revision">
      <th mat-header-cell *matHeaderCellDef class="text-center">
        <span [innerHTML]="labelRevision"></span>
      </th>
      <td mat-cell *matCellDef="let e" class="text-center">
        <span [ngClass]="{ inactive: e.status == 0 }">{{ e.revision }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="text-center">
        <span [innerHTML]="labelStatus"></span>
      </th>
      <td mat-cell *matCellDef="let e" class="text-center">
        <span [ngClass]="{ inactive: e.status == 0 }">{{
          e.status == 1 ? "Active" : "Inactive"
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastReviewed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
        <span [innerHTML]="labelLastReviewed"></span>
      </th>
      <td mat-cell *matCellDef="let e" class="text-center">
        <span [ngClass]="{ inactive: e.status == 0 }">{{
          e.lastReviewed | date : "shortDate"
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="reviewPeriod">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
        <span [innerHTML]="labelReviewPeriod"></span>
      </th>
      <td mat-cell *matCellDef="let e" class="text-center">
        <span [ngClass]="{ inactive: e.status == 0 }">{{
          e.reviewPeriod == 1
            ? "3 years"
            : e.reviewPeriod == 2
            ? "5 years"
            : e.reviewPeriod == 3
            ? "Upon Use"
            : ""
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="nextDue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
        <span [innerHTML]="labelNextDue"></span>
      </th>
      <td mat-cell *matCellDef="let e" class="text-center">
        <span [ngClass]="{ inactive: e.status == 0, expired: expired(e) }">{{
          e.nextDue == null || e.nextDue == undefined
            ? "--"
            : (e.nextDue | date : "shortDate")
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="files">
      <th mat-header-cell *matHeaderCellDef>
        <span [innerHTML]="labelGoogleDocsDraftGlobal"></span>
      </th>
      <td mat-cell *matCellDef="let e">
        <ng-container *ngFor="let file of e.procedureFiles">
          <a
            [ngClass]="{ inactive: e.status == 0 }"
            href="{{ file.url }}"
            target="_blank"
            ><b>{{ file.text }}</b></a
          >
          <br />
        </ng-container>
        <ng-container
          *ngIf="
            procedureCategory?.procedureTypeID != 1 &&
            procedureCategory?.procedureTypeID != 4 &&
            procedureCategory?.procedureTypeID != 5 &&
            procedureCategory?.procedureTypeID != 6
          "
        >
          <button mat-icon-button color="primary" (click)="openChecklist(e)">
            <mat-icon>fact_check</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="preparers">
      <th mat-header-cell *matHeaderCellDef class="text-center">
        <span [innerHTML]="labelPreparers"></span>
      </th>
      <td mat-cell *matCellDef="let e" class="text-center">
        <div *ngIf="e.active">
          <button
            mat-icon-button
            class="add-btn noOutline"
            color="primary"
            (click)="createSignature(e, 1)"
            [matTooltip]="labelAddPreparer"
            *ngIf="preparer && !isInSignatures(e)"
          >
            <mat-icon>person_add</mat-icon>
          </button>
          <br />
          <mat-chip-listbox class="chip-grid">
            <mat-chip
              class="chip"
              *ngFor="let signature of getSignatures(e, 1)"
              [ngClass]="{ hilite: signature?.user?.id == currentUser?.id }"
            >
              <span
                class="mr-1 nowrap"
                [matTooltip]="signature?.user?.name ?? ''"
                >{{ signature?.user?.initials }}</span
              >
              <span class="nowrap date" *ngIf="signature?.signedOn">{{
                signature?.signedOn | date : "shortDate"
              }}</span>
              <button
                matChipRemove
                class="remove-button"
                *ngIf="
                  (signatureAdmin || signature?.userID == currentUser?.id) &&
                  !signature?.signedOn
                "
                [matTooltip]="labelRemovePreparer"
                (click)="removeSignature(signature)"
              >
                <mat-icon>cancel</mat-icon>
              </button>
              <button
                class="mat-chip-trailing-icon"
                *ngIf="
                  (signatureAdmin || signature?.userID == currentUser?.id) &&
                  !signature?.signedOn &&
                  e.procedureCategory.procedureType != 2 &&
                  e.procedureCategory.procedureType != 3 &&
                  !hasTemplates()
                "
                (click)="approveSignature(signature)"
                [matTooltip]="labelApprovePreparer"
              >
                <mat-icon class="blue">check_circle</mat-icon>
              </button>
              <button
                class="mat-chip-trailing-icon"
                color="accent"
                *ngIf="
                  (signature?.userID == currentUser?.id || signatureAdmin) &&
                  signature?.signedOn &&
                  !hasTemplates()
                "
                (click)="unapproveSignature(signature)"
                [matTooltip]="labelUnapprovePreparer"
              >
                <mat-icon class="blue">cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-listbox>
        </div>
        <div *ngIf="!e.active">
          <mat-chip-listbox class="chip-grid">
            <mat-chip
              class="chip"
              *ngFor="let signature of getSignatures(e, 1)"
              [ngClass]="{ hilite: signature?.user?.id == currentUser?.id }"
            >
              <span
                class="mr-1 nowrap"
                *ngIf="signature?.signedOn"
                [matTooltip]="signature?.user?.name ?? ''"
              >
                {{ signature?.user?.initials }}
              </span>
              <span class="nowrap date" *ngIf="signature?.signedOn">{{
                signature?.signedOn | date : "shortDate"
              }}</span>
            </mat-chip>
          </mat-chip-listbox>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="reviewers">
      <th mat-header-cell *matHeaderCellDef class="text-center">
        <span [innerHTML]="labelReviewers"></span>
      </th>
      <td mat-cell *matCellDef="let e" class="text-center">
        <div *ngIf="e.active">
          <button
            mat-icon-button
            class="add-btn noOutline"
            color="primary"
            (click)="createSignature(e, 2)"
            [matTooltip]="labelAddReviewer"
            *ngIf="preparer && !isInSignatures(e)"
          >
            <mat-icon>person_add</mat-icon>
          </button>
          <br />
          <mat-chip-listbox class="chip-grid">
            <mat-chip
              class="chip"
              *ngFor="let signature of getSignatures(e, 2)"
              [ngClass]="{ hilite: signature?.user?.id == currentUser?.id }"
            >
              <span
                class="mr-1 nowrap"
                [matTooltip]="signature?.user?.name ?? ''"
                >{{ signature?.user?.initials }}</span
              >
              <span class="nowrap date" *ngIf="signature?.signedOn">{{
                signature?.signedOn | date : "shortDate"
              }}</span>
              <button
                matChipRemove
                class="remove-button"
                *ngIf="
                  (signatureAdmin || signature?.userID == currentUser?.id) &&
                  !signature?.signedOn
                "
                [matTooltip]="labelRemoveReviewer"
                (click)="removeSignature(signature)"
              >
                <mat-icon>cancel</mat-icon>
              </button>
              <button
                class="mat-chip-trailing-icon"
                *ngIf="
                  (signatureAdmin || signature?.userID == currentUser?.id) &&
                  !signature?.signedOn &&
                  e.procedureCategory.procedureType != 2 &&
                  e.procedureCategory.procedureType != 3 &&
                  !hasTemplates()
                "
                (click)="approveSignature(signature)"
                [matTooltip]="labelApproveReviewer"
              >
                <mat-icon class="blue">check_circle</mat-icon>
              </button>
              <button
                class="mat-chip-trailing-icon"
                color="accent"
                *ngIf="
                  (signature?.userID == currentUser?.id || signatureAdmin) &&
                  signature?.signedOn &&
                  !hasTemplates()
                "
                (click)="unapproveSignature(signature)"
                [matTooltip]="labelUnapproveReviewer"
              >
                <mat-icon class="blue">cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-listbox>
        </div>
        <div *ngIf="!e.active">
          <mat-chip-listbox class="chip-grid">
            <mat-chip
              class="chip"
              *ngFor="let signature of getSignatures(e, 2)"
              [ngClass]="{ hilite: signature?.user?.id == currentUser?.id }"
            >
              <span
                class="mr-1 nowrap"
                *ngIf="signature?.signedOn"
                [matTooltip]="signature?.user?.name ?? ''"
              >
                {{ signature?.user?.initials }}
              </span>
              <span class="nowrap date" *ngIf="signature?.signedOn">{{
                signature?.signedOn | date : "shortDate"
              }}</span>
            </mat-chip>
          </mat-chip-listbox>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="approvers">
      <th mat-header-cell *matHeaderCellDef class="text-center">
        <span [innerHTML]="labelApprovers"></span>
      </th>
      <td mat-cell *matCellDef="let e" class="text-center">
        <div *ngIf="e.active">
          <button
            mat-icon-button
            class="add-btn noOutline"
            color="primary"
            (click)="createSignature(e, 3)"
            [matTooltip]="labelAddApprover"
            *ngIf="preparer && !isInSignatures(e)"
          >
            <mat-icon>person_add</mat-icon>
          </button>
          <br />
          <mat-chip-listbox class="chip-grid">
            <mat-chip
              class="chip"
              *ngFor="let signature of getSignatures(e, 3)"
              [ngClass]="{ hilite: signature?.user?.id == currentUser?.id }"
            >
              <span
                class="mr-1 nowrap"
                [matTooltip]="signature?.user?.name ?? ''"
                >{{ signature?.user?.initials }}</span
              >
              <span class="nowrap date" *ngIf="signature?.signedOn">{{
                signature?.signedOn | date : "shortDate"
              }}</span>
              <button
                matChipRemove
                class="remove-button"
                *ngIf="
                  (signatureAdmin || signature?.userID == currentUser?.id) &&
                  !signature?.signedOn
                "
                [matTooltip]="labelRemoveApprover"
                (click)="removeSignature(signature)"
              >
                <mat-icon>cancel</mat-icon>
              </button>
              <button
                class="mat-chip-trailing-icon"
                *ngIf="
                  (signatureAdmin || signature?.userID == currentUser?.id) &&
                  !signature?.signedOn &&
                  e.procedureCategory.procedureType != 2 &&
                  e.procedureCategory.procedureType != 3 &&
                  !hasTemplates()
                "
                (click)="approveSignature(signature)"
                [matTooltip]="labelApproveApprover"
              >
                <mat-icon class="blue">check_circle</mat-icon>
              </button>
              <button
                class="mat-chip-trailing-icon"
                color="accent"
                *ngIf="
                  (signature?.userID == currentUser?.id || signatureAdmin) &&
                  signature?.signedOn &&
                  !hasTemplates()
                "
                (click)="unapproveSignature(signature)"
                [matTooltip]="labelUnapproveApprover"
              >
                <mat-icon class="blue">cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-listbox>
        </div>

        <div *ngIf="!e.active">
          <mat-chip-listbox class="chip-grid">
            <mat-chip
              class="chip"
              *ngFor="let signature of getSignatures(e, 3)"
              [ngClass]="{ hilite: signature?.user?.id == currentUser?.id }"
            >
              <span
                class="mr-1 nowrap"
                *ngIf="signature?.signedOn"
                [matTooltip]="signature?.user?.name ?? ''"
              >
                {{ signature?.user?.initials }}
              </span>
              <span class="nowrap date" *ngIf="signature?.signedOn">{{
                signature?.signedOn | date : "shortDate"
              }}</span>
            </mat-chip>
          </mat-chip-listbox>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="notes">
      <th mat-header-cell *matHeaderCellDef class="pr-2">
        <span [innerHTML]="labelNotes"></span>
      </th>
      <td mat-cell *matCellDef="let e" class="pr-2">
        <notes
          [notes]="e.procedureNotes"
          [canDelete]="true"
          (delete)="deleteNote($event)"
        ></notes>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef>
        <span [innerHTML]="labelActions"></span>
      </th>
      <td mat-cell *matCellDef="let e">
        <div class="cell-container">
          <div class="cellx pt-1">
            <button
              mat-icon-button
              *ngIf="e.active"
              [matMenuTriggerFor]="menu"
              aria-label="actions"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                *ngIf="actionsPermission"
                (click)="openProcedurePopup(e)"
              >
                <mat-icon class="yellow">edit</mat-icon>
                <span [innerHTML]="labelEdit"></span>
              </button>
              <button
                mat-menu-item
                *ngIf="actionsPermission"
                (click)="delete(e.id)"
              >
                <mat-icon class="red">delete</mat-icon>
                <span [innerHTML]="labelDelete"></span>
              </button>
              <button mat-menu-item (click)="addNote(e)">
                <mat-icon class="yellow">note_add</mat-icon>
                <span [innerHTML]="labelCreateNote"></span>
              </button>
              <button
                mat-menu-item
                *ngIf="canComplete(e)"
                (click)="complete(e)"
              >
                <mat-icon class="green">done_outline</mat-icon>
                <span [innerHTML]="labelPublish"></span>
              </button>
              <hr *ngIf="hasPrivilege(privilegeEnum.PCNotifications)" />
              <button
                mat-menu-item
                [mat-menu-trigger-for]="notifyMenu"
                *ngIf="hasPrivilege(privilegeEnum.PCNotifications)"
              >
                <mat-icon class="blue">email</mat-icon>Notify
              </button>
            </mat-menu>
            <mat-menu #notifyMenu="matMenu">
              <button mat-menu-item (click)="notify(e, true)">
                <mat-icon class="blue">people</mat-icon>To All
              </button>
              <button mat-menu-item (click)="notify(e, false)">
                <mat-icon class="yellow">people_outline</mat-icon>Unsigned
              </button>
            </mat-menu>
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="procedureCategory">
      <th class="header-title" mat-header-cell *matHeaderCellDef colspan="20">
        <span [innerHTML]="procedureCategory?.name"></span>
      </th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="title; sticky: true"></tr>
    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columns"
      [ngClass]="{
        archived: !row.active
      }"
    ></tr>
  </table>
</div>
