import { Component, Inject, OnInit, OnDestroy, Injector, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Review, ReviewActionItem, ReviewActionItemType } from '../../reviews.models';
import { ReviewsService } from '../../reviews.service';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Observable, Subscription } from 'rxjs';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { ReviewsActionItemsCompleteComponent } from './reviews-action-items-complete/reviews-action-items-complete.component';
import { Checklist } from 'src/app/components/checklists/checklists';

@Component({
  selector: 'app-reviews-action-items-details',
  templateUrl: './reviews-action-items-details.component.html',
  styleUrls: ['./reviews-action-items-details.component.scss']
})
export class ReviewsActionItemsDetailsComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() review?: Review;
  @Input() checklist?: Checklist;

  reviewActionItem: ReviewActionItem;

  reviews!: Review[];
  reviewsFiltered!: Review[];
  reviews$!: Observable<Review[]>;
  reviewsSubs!: Subscription;

  reviewActionItemTypes!: ReviewActionItemType[];
  reviewActionItemTypesFiltered!: ReviewActionItemType[];
  reviewActionItemTypes$!: Observable<ReviewActionItemType[]>;
  reviewActionItemTypesSubs!: Subscription;

  canComplete!: boolean;
  usersFiltered!: User[];

  disabled: boolean;
  isLoading!: boolean;
  privilege = PrivilegeEnum;
  canAddRemove!: boolean;

  actionItemTypeCtrl = new FormControl();
  actionItemNameCtrl = new FormControl();
  actionItemStartDateCtrl = new FormControl();
  actionItemEndDateCtrl = new FormControl();
  actionItemResponsibleCtrl = new FormControl();
  actionItemClosedByCtrl = new FormControl();
  actionItemClosedDateCtrl = new FormControl();

  wfTaskLocal: WfTaskLocalResource;
  serialNo?: string;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<ReviewsActionItemsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: ReviewsService,

  ) {
    super(injector);
    this.reviewActionItem = data.reviewActionItem;
    this.wfTaskLocal = data.wfTaskLocal;
    this.disabled = this.reviewActionItem.status == 0;
    this.checklist = data.checklist;
  }

  override ngOnDestroy(): void {
    this.reviewActionItemTypesSubs?.unsubscribe();
    this.reviewsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  async ngOnInit(): Promise<void> {
    this.buildForm();
    this.loadReviews();
    this.loadReviewActionItemTypes();
    this.usersFiltered = this.getUsers();
    const review = this.reviews?.find(r => r.id == this.reviewActionItem.reviewID);
    if (review)
      this.reviewActionItem.review = review;
    setTimeout(() => {
      this.actionItemNameCtrl.setValue(this.reviewActionItem.name);
      this.actionItemTypeCtrl?.setValue(this.reviewActionItem.reviewActionItemType);
      this.actionItemResponsibleCtrl.setValue(this.reviewActionItem.responsible);
      this.actionItemStartDateCtrl.setValue(this.reviewActionItem.startDate);
      this.actionItemEndDateCtrl.setValue(this.getEndDate(this.reviewActionItem.endDate));
      this.actionItemClosedByCtrl.setValue(this.reviewActionItem.closedBy?.name);
      this.actionItemClosedDateCtrl.setValue(this.reviewActionItem.closedOn);
    }, 500);

    this.canComplete = await this.service.checkPrivilege(PrivilegeEnum.CompleteActionItems, this.reviewActionItem.review?.reviewCycle.reviewBoard.reviewBoardType, this.reviewActionItem.review?.reviewStatus) || this.reviewActionItem.responsible?.id == this.getCurrentUser()?.id;
    this.canAddRemove = this.service.checkPrivilege(PrivilegeEnum.AddRemoveActionItems, this.reviewActionItem.review?.reviewCycle.reviewBoard.reviewBoardType, this.reviewActionItem.review?.reviewStatus);
    if (!this.canAddRemove) {
      this.formGroup.disable();
    }

    this.serialNo = this.reviewActionItem?.review?.reviewCycle?.serialNo;
    if (!this.serialNo && this.checklist) this.serialNo = this.checklist.serialNo;
  }

  loadReviews() {
    this.reviews$ = this.store.select(state => state.Reviews.data);
    this.reviewsSubs = this.reviews$.subscribe(data => {
      if (data.length) {
        this.reviews = data;
      }
    });
  }

  sendEmail() {
    this.service.sendemailNotification(this.utils.Serialize(this.reviewActionItem)).toPromise().catch(error => {
      this.alert.error('There was an error sending Email Notification');
      console.log(error);
    });
  }

  changeCatl() {
    this.formGroup.markAsDirty();
    if (this.reviewActionItem.isCATL) {
      this.alert.warning('Email Notification will be sent after clicking on Save button');
    }
  }

  getEndDate(endDate?: Date) {
    return endDate && endDate.toString() != '0001-01-01T00:00:00' ? endDate : null;
  }

  loadReviewActionItemTypes() {
    this.reviewActionItemTypes$ = this.store.select(state => state.ReviewActionItemTypes.data);
    this.reviewActionItemTypesSubs = this.reviewActionItemTypes$.subscribe(data => {
      this.reviewActionItemTypes = data;
      this.reviewActionItemTypesFiltered = data;
    });
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      actionItemTypeCtrl: this.actionItemTypeCtrl,
      actionItemNameCtrl: this.actionItemNameCtrl,
      actionItemStartDateCtrl: this.actionItemStartDateCtrl,
      actionItemEndDateCtrl: this.actionItemEndDateCtrl,
      actionItemResponsibleCtrl: this.actionItemResponsibleCtrl,
      actionItemClosedByCtrl: this.actionItemClosedByCtrl,
      actionItemClosedDateCtrl: this.actionItemClosedDateCtrl
    });
    if (!this.reviewActionItem.status && this.reviewActionItem.id)
      this.formGroup.disable();
  }

  getActionTitle() {
    if (this.reviewActionItem.status == 0)
      return 'Completed';
    return !this.reviewActionItem?.id ? 'Create' : 'Modify';
  }

  close() {
    this.dialogRef.close();
  }

  cancel() {
    this.close();
  }

  formValid() {
    return !this.utils.isNullValue(this.actionItemTypeCtrl?.value) && !this.utils.isNullValue(this.actionItemNameCtrl.value) && !this.utils.isNullValue(this.reviewActionItem.description) && !this.utils.isNullValue(this.actionItemStartDateCtrl.value);
  }

  async save() {
    this.formGroup.disable();
    this.isLoading = true;
    this.reviewActionItem.name = this.actionItemNameCtrl.value;
    this.reviewActionItem.wfTaskLocalID = this.wfTaskLocal?.id;
    this.reviewActionItem.reviewActionItemTypeID = this.actionItemTypeCtrl?.value.id;
    if (!this.reviewActionItem.id) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message: 'Is a CATL Item?',
          icon: 'question'
        }
      });

      confirm.afterClosed().toPromise().then(async data => {
        if (data) {
          this.reviewActionItem.isCATL = data;
        }
        await this.service.createReviewActionItem(this.utils.Serialize(this.reviewActionItem)).toPromise().then(data => {
          if (data) {
            data.review = this.reviewActionItem.review;
            data.reviewActionItemType = this.actionItemTypeCtrl?.value;
            this.dialogRef.close();
          }
        });
        this.isLoading = false;
        this.formGroup.enable();
      });
    }
    else {
      this.service.updateReviewActionItem(this.utils.Serialize(this.reviewActionItem)).toPromise().then(data => {
        if (data) {
          data.review = this.reviewActionItem.review;
          this.dialogRef.close();
        }
      });
      this.isLoading = false;
      this.formGroup.enable();
    }
  }

  changedActionItemType(e: any) {
    const val = e.target.value as string;
    this.reviewActionItemTypesFiltered = this.reviewActionItemTypes.filter(x => x.name?.toLowerCase().includes(val.toLowerCase()));
    // if (this.reviewActionItemTypesFiltered.length == 0) {
    //   this.actionItemTypeCtrl?.setValue(null);
    // }
    // if (this.reviewActionItemTypesFiltered.length == 1 || e.type == 'blur') {
    //   this.actionItemTypeCtrl?.setValue(this.reviewActionItemTypesFiltered[0]);
    // }
  }

  changedActionItemResponsible(e: any) {
    const val = e.target.value as string;
    this.usersFiltered = this.getUsers().filter(x => x.name?.toLowerCase().includes(val.toLowerCase()));
  }

  selectedActionItemType(e: any) {
    const reviewActionItemType = e.option.value as ReviewActionItemType;
    this.reviewActionItem.reviewActionItemTypeID = reviewActionItemType.id;
  }

  selectedActionItemResponsible(e: any) {
    const user = e.option.value as User;
    this.reviewActionItem.responsibleID = user.id;
  }

  changedDescription(e: any) {
    this.reviewActionItem.description = e;
    this.formGroup.markAsDirty();
  }

  changedStartDate(e: any) {
    this.reviewActionItem.startDate = e.value;
  }

  changedEndDate(e: any) {
    this.reviewActionItem.endDate = e.value;
  }

  validateForm() {
    return !this.utils.isNullValue(this.actionItemNameCtrl.value) && this.utils.isNullValue(this.actionItemTypeCtrl?.value);
  }

  canBeCompleted() {
    return this.canComplete && this.reviewActionItem?.id != 0 && this.reviewActionItem.status;
  }

  openActionComplete() {
    const dialog = this.dialog.open(ReviewsActionItemsCompleteComponent, {
      width: '70%',
      minWidth: '500px',
      maxHeight: '80%',
      data: {
        reviewActionItem: this.reviewActionItem
      },
      disableClose: true,
      autoFocus: false
    });
    dialog.afterClosed().subscribe(data => {
      if (data && data.status == 0) {
        this.dialogRef.close();
      }
    });
  }
}
