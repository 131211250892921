import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BeamlineStatusCreate, BeamlineStatusDelete, BeamlineStatusRefresh, BeamlineStatusUpdate } from './bl-status.action';
import { BeamlineServService } from '../beamline-serv.service';
import { BeamlineStatus } from '../beamline-status';

export class BeamlineStatusStateModel {
  data!: BeamlineStatus[];
}

@State<BeamlineStatusStateModel>({
  name: 'BeamlineStatus',
  defaults: {
    data: []
  }
})

@Injectable()
export class BeamlineStatusState {
  constructor(
    private _service: BeamlineServService
  ) { }

  @Selector()
  static read(state: BeamlineStatusStateModel) {
    return state.data;
  }

  @Action(BeamlineStatusRefresh)
  refresh({ patchState }: StateContext<BeamlineStatusStateModel>) {
    this._service.getBeamlineStatus().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(BeamlineStatusCreate)
  create({ getState, patchState }: StateContext<BeamlineStatusStateModel>, { item }: BeamlineStatusCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(BeamlineStatusUpdate)
  update({ getState, patchState }: StateContext<BeamlineStatusStateModel>, { id, item }: BeamlineStatusUpdate) {
    const state = getState();
    const index = state.data.map(x => x.beamlineId).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(BeamlineStatusDelete)
  delete({ getState, patchState }: StateContext<BeamlineStatusStateModel>, { id }: BeamlineStatusDelete) {
    patchState({
      data: getState().data.filter(x => x.beamlineId != id)
    });
  }

}
