import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MatFileUploadService {
  constructor(private http: HttpClient) { }
  postFiles(data: FormData) {
    const result = this.http.post(environment.urls.baseApiUrl + '/Files', data)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }
  getFiles(serialNo: string) {
    const result = this.http.get(environment.urls.baseApiUrl + '/Files/BySerialNo/' + serialNo)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }
  getFilesByComment(commentId: number) {
    const result = this.http.get(environment.urls.baseApiUrl + '/Files/ByComment/' + commentId)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }
  getFilesByRadArea(radAreaId: number) {
    const result = this.http.get(environment.urls.baseApiUrl + '/Files/ByRadArea/' + radAreaId)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }
  deleteFile(id: string) {
    const result = this.http.delete(environment.urls.baseApiUrl + '/Files/' + id)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }
  public downloadFile(id: string): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'GET',
      `${environment.urls.baseApiUrl}/Files/${id}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  iconCheck(iconPath: string): Observable<string> {
    return this.http.get(iconPath, { observe: 'response', responseType: 'blob' })
      .pipe(
        map(response => {
          return iconPath;
        }),
        catchError(error => {
          return of('../../../assets/icons/files/unk.png');
        })
      );
  }

  returnUnknown() {
    return ('.');
  }
}
