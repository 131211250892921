<div class="container-fluid p-0">
  <div class="row no-gutters">
    <div class="col mb-2 small d-flex justify-content-end">
      <mat-slide-toggle
        color="warn"
        (change)="changeClosed()"
        [(ngModel)]="showClosed"
        >Show Closed/Canceled ABSIs</mat-slide-toggle
      >
    </div>
  </div>
</div>
<div class="w-100 table">
  <div class="left-table-container table-container mat-container scrollbar">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="serialNo">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          No.
        </mat-header-cell>
        <mat-cell
          class="font-weight-bold"
          [ngClass]="element.formStatus.color"
          *matCellDef="let element"
          nowrap
          ><span
            [innerHTML]="element.serialNo | highlightSearch : filter"
          ></span
        ></mat-cell>
      </ng-container>

      <ng-container matColumnDef="resourceName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Endstation(s)
        </mat-header-cell>
        <mat-cell
          class="pl-1"
          [ngClass]="element.formStatus.color"
          *matCellDef="let element"
        >
          <span
            [innerHTML]="
              breakByComma(element.resourceName) | highlightSearch : filter
            "
          ></span
        ></mat-cell>
      </ng-container>

      <ng-container matColumnDef="statusName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </mat-header-cell>
        <mat-cell
          class="pl-1"
          [ngClass]="element.formStatus.color"
          *matCellDef="let element"
        >
          <span
            class="wrap"
            [innerHTML]="element.formStatus.name | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        #matrow
        *matRowDef="let row; columns: displayedColumns"
        (click)="getValues(row)"
        class="table-row"
        style="white-space: nowrap"
        [ngClass]="{
          'mat-row-highlight': selectedRowIndex == row.id,
          'text-red': row.status == -1
        }"
        [attr.position]="getPosition(row)"
      ></mat-row>
    </mat-table>
  </div>
</div>
