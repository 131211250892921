<div class="container-fluid pt-3">
  Olog Messages
  <div class="mat-container">
    <mat-table #table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Message </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Active</mat-header-cell>
        <mat-cell *matCellDef="let element"><mat-slide-toggle [(ngModel)]="element.status"
            [disabled]="true"></mat-slide-toggle>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="from">
        <mat-header-cell *matHeaderCellDef> From Status </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="element.fromStatus?.class">{{ element.fromStatus?.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="to">
        <mat-header-cell *matHeaderCellDef> To Status </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="element.toStatus?.class">
          {{ element.toStatus?.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="add">
        <mat-header-cell *matHeaderCellDef>
          <button mat-icon-button color="accent" (click)="ologMessageDialog()">
            <mat-icon>add</mat-icon>
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let elementIndex = index" class="pr-0">
          <button mat-icon-button color="accent" (click)="ologMessageDialog(element)"
            *ngIf="hasPrivilege(privilegeEnum.UpdateOlogMessage)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="delete(element)"
            *ngIf="hasPrivilege(privilegeEnum.DeleteOlogMessage)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" [hidden]="row.deleteReason"></mat-row>
    </mat-table>
  </div>
</div>