import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ScheduleTypesCreate, ScheduleTypesDelete, ScheduleTypesRefresh, ScheduleTypesUpdate } from './schedule-types.action';
import { ScheduleType } from '../../models/schedule-type';
import { ScheduleTypeService } from '../../services/schedule-type.service';

export class ScheduleTypesStateModel {
  data: ScheduleType[] = [];
}

@State<ScheduleTypesStateModel>({
  name: 'ScheduleTypes',
  defaults: {
    data: [],
  }
})
@Injectable()
export class ScheduleTypesState {
  constructor(
    private _service: ScheduleTypeService
  ) { }

  @Selector()
  static read(state: ScheduleTypesStateModel) {
    return state.data;
  }

  @Action(ScheduleTypesRefresh)
  strefresh({ patchState }: StateContext<ScheduleTypesStateModel>) {
    this._service.getAll().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ScheduleTypesCreate)
  stcreate({ getState, patchState }: StateContext<ScheduleTypesStateModel>, { item }: ScheduleTypesCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ScheduleTypesUpdate)
  stupdate({ getState, patchState }: StateContext<ScheduleTypesStateModel>, { id, item }: ScheduleTypesUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ScheduleTypesDelete)
  stdelete({ getState, patchState }: StateContext<ScheduleTypesStateModel>, { id }: ScheduleTypesDelete) {
    patchState({
      data: getState().data.filter(x => x.id != id)
    });
  }

}
