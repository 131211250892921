import { Component, Input, EventEmitter, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormStatusEnum, FormType } from 'src/app/common/enumerations/enumerations';
import { CommentsAndAttachmentsComponent } from 'src/app/controls/comments-and-attachments/comments-and-attachments.component';
import { Form } from 'src/app/services/forms/form';
import { EbMainS1Component } from './eb-main-s1/eb-main-s1.component';

@Component({
  selector: 'eb-main',
  templateUrl: './eb-main.component.html',
  styleUrls: ['./eb-main.component.scss']
})
export class EbMainComponent extends BaseComponent implements OnChanges {
  @Input() form?: Form | null;
  @Input() showAmendment?: boolean;
  @Output() loading = new EventEmitter<boolean>();
  @Output() formCreated = new EventEmitter<Form>();

  @ViewChild(EbMainS1Component) section1!: EbMainS1Component;
  @ViewChild(CommentsAndAttachmentsComponent) comments!: CommentsAndAttachmentsComponent;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.comments && this.form) {
      this.comments.currentDocument = {
        status: this.form.formVersion?.statusID,
        serialNo: this.form.sequenceNumber?.number,
        type: FormType.EBF,
      };
      this.comments?.refresh();
    }
    this.setHeights();
  }

}
