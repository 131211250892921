import { AbsiTemplateTypeResource } from '../../absi';

export class AbsiTemplateTypeRefresh {
  static readonly type = '[AbsiTemplateType] Refresh';
}
export class AbsiTemplateTypeCreate {
  static readonly type = '[AbsiTemplateType] Create';
  constructor(public item: AbsiTemplateTypeResource) { }
}
export class AbsiTemplateTypeUpdate {
  static readonly type = '[AbsiTemplateType] Update';
  constructor(public id: number, public item: AbsiTemplateTypeResource) { }
}
export class AbsiTemplateTypeDelete {
  static readonly type = '[AbsiTemplateType] Delete';
  constructor(public id: number) { }
}

