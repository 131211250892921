import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss']
})
/// Info
export class YesNoDialogComponent implements OnInit {

  public message!: string;
  public buttonCancel = 'No';
  public buttonAccept = 'Yes';
  public icon = 'question';

  constructor(
    public dialog: MatDialogRef<YesNoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      message: string;
      icon: string;
      value: boolean;
      changeButtonLabels: boolean;
      labelButtonCancel: string;
      labelButtonAccept: string;
    }
  ) { }

  ngOnInit() {
    if (this.data?.changeButtonLabels) {
      this.buttonCancel = this.data.labelButtonCancel;
      this.buttonAccept = this.data.labelButtonAccept;
    }
    this.message = this.data.message;
    this.data.value = true;
    if (this.data.icon) {
      this.icon = this.data.icon;
    }
  }
}
