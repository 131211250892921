import { Component, Input } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { DocumentType } from 'src/app/services/documents/documents';
import { OlogCategory } from '../../services/olog-category';
import { OlogEmail } from '../../services/olog-email';

@Component({
  selector: 'olog-messages',
  templateUrl: './olog-messages.component.html',
  styleUrls: ['./olog-messages.component.scss']
})
export class OlogMessagesComponent extends BaseComponent {

  @Input() documentType!: DocumentType;
  @Input() disabled!: boolean;


  // ologCategoriesChanged(ologCategories?: OlogCategory[]) {
  //   if (this.documentType)
  //     this.documentType.ologCategories = ologCategories;
  //   this.setFormDirty();
  // }

  // ologEmailsChanged(ologEmails: OlogEmail[]) {
  //   if (this.documentType)
  //     this.documentType.ologEmails = ologEmails;
  //   this.setFormDirty();
  // }

}
