<mat-form-field class="pt-2 p-0 no-subscript" appearance="outline">
  <mat-label>ABSI Template Type</mat-label>
  <input
    matInput
    type="text"
    #typeInput
    [formControl]="typeCtrl"
    [matAutocomplete]="autoType"
  />
  <mat-autocomplete
    autoActiveFirstOption
    #autoType="matAutocomplete"
    (optionSelected)="selected($event)"
    #versionAutocomplete
    [displayWith]="displayType"
  >
    <mat-option *ngFor="let elem of absiTemplateTypes" [value]="elem">
      {{ elem.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<div class="left-table-container table-container mat-container scrollbar">
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Version
      </mat-header-cell>
      <mat-cell
        class="font-weight-bold"
        *matCellDef="let e"
        [ngClass]="e.statusClass"
        nowrap
        ><span [innerHTML]="e.name | highlightSearch : filter"></span>
        <mat-icon color="accent" *ngIf="e.editingById" class="editing"
          >edit</mat-icon
        >
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="statusName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Status
      </mat-header-cell>
      <mat-cell class="pl-1" *matCellDef="let e" [ngClass]="e.statusClass"
        ><span [innerHTML]="e.statusName | highlightSearch : filter"></span
      ></mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="onRowClick(row)"
      class="table-row"
      [ngClass]="{ 'mat-row-highlight': version?.id == row.id }"
    ></mat-row>
  </mat-table>
</div>
