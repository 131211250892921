import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Review, ReviewBoard, ReviewCycle, ReviewRequestStatus, ReviewTypesEnum } from '../../reviews.models';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Observable, Subscription } from 'rxjs';
import { MatDialogConfig } from '@angular/material/dialog';
import { ReviewCycleStatus } from 'src/app/common/enumerations/enumerations';
import { ReviewRequestComponent } from '../../reviews-requests/review-request/review-request.component';

@Component({
  selector: 'reviews-boards-cycles',
  templateUrl: './reviews-boards-cycles.component.html',
  styleUrls: ['./reviews-boards-cycles.component.scss']
})
export class ReviewsBoardsCyclesComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() reviewBoard?: ReviewBoard;
  @Input() reviewBoardFromIndex?: ReviewBoard;
  @Input() showOnlyActive?: boolean;
  @Output() selectedReview = new EventEmitter<Review>();

  reviewCycles!: ReviewCycle[];
  reviewCycles$!: Observable<ReviewCycle[]>;
  reviewCyclesSubs!: Subscription;

  statuses: ReviewRequestStatus[] = [
    { status: ReviewCycleStatus.Pending, name: 'Pending', icon: 'new_releases', color: 'yellow', selected: true },
    { status: ReviewCycleStatus.Approved, name: 'Approved', icon: 'check_circle', color: 'green', selected: true },
    { status: ReviewCycleStatus.Rejected, name: 'Rejected', icon: 'report', color: 'red' },
    { status: ReviewCycleStatus.Archived, name: 'Archived', icon: 'archive', color: 'gray' },
  ];


  ngOnChanges(changes: SimpleChanges): void {
    this.reviewCycles$ = this.store.select(state => state.ReviewCycles.data);
    this.reviewCyclesSubs = this.reviewCycles$.subscribe(data => {
      data.map(d => d.reviewRequestStatus = this.statuses.find(s => s.status == d.status));
      if (data && this.reviewBoard) {
        let reviewCyclesFull = data?.filter(x => x.reviewBoardID == this.reviewBoard?.id && x.type == ReviewTypesEnum.FULL).sort((a, b) => this.utils.sort(a.serialNo, b.serialNo, false));
        if (!reviewCyclesFull) reviewCyclesFull = [];
        const reviewCyclesModf = data?.filter(x => x.reviewBoardID == this.reviewBoard?.id && x.type == ReviewTypesEnum.MODF).sort((a, b) => this.utils.sort(a.serialNo, b.serialNo, false));
        this.reviewCycles = reviewCyclesFull.concat(reviewCyclesModf);
        if (this.showOnlyActive) this.reviewCycles = this.reviewCycles.filter(x => x.status != ReviewCycleStatus.Archived && x.status != ReviewCycleStatus.Pending && x.status != ReviewCycleStatus.Rejected);
      }
      else if (data && this.reviewBoardFromIndex) {
        let reviewCyclesFull = data?.filter(x => this.reviewBoardFromIndex?.reviewCycles?.map(c => c.id).includes(x.id) && x.type == ReviewTypesEnum.FULL).sort((a, b) => this.utils.sort(a.serialNo, b.serialNo, false));
        if (!reviewCyclesFull) reviewCyclesFull = [];
        const reviewCyclesModf = data?.filter(x => this.reviewBoardFromIndex?.reviewCycles?.map(c => c.id).includes(x.id) && x.type == ReviewTypesEnum.MODF).sort((a, b) => this.utils.sort(a.serialNo, b.serialNo, false));
        this.reviewCycles = reviewCyclesFull.concat(reviewCyclesModf);
        if (this.showOnlyActive) this.reviewCycles = this.reviewCycles.filter(x => x.status != ReviewCycleStatus.Archived && x.status != ReviewCycleStatus.Pending && x.status != ReviewCycleStatus.Rejected);
      }
    });
  }

  override ngOnDestroy(): void {
    this.reviewCyclesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {

  }

  label(reviewCycle: ReviewCycle) {
    if (this.reviewBoard)
      return reviewCycle.type == ReviewTypesEnum.MODF ? this.reviewBoard.resource?.name?.substr(0, 1) + 'MR' : null;
    else if (this.reviewBoardFromIndex)
      return reviewCycle.type == ReviewTypesEnum.MODF ? this.reviewBoardFromIndex.resource?.name?.substr(0, 1) + 'MR' : null;
    return '';
  }

  reviewSelected(review: Review) {
    this.selectedReview.emit(review);
  }

  requestColor(r: ReviewCycle) {
    return r.reviewRequestStatus?.color
  }

  requestIcon(r: ReviewCycle) {
    return r.reviewRequestStatus?.icon
  }

  requestTip(r: ReviewCycle) {
    return r.reviewRequestStatus?.name
  }

  openReviewRequest(r: ReviewCycle) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      reviewCycle: r,
      isEvaluate: true
    };
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ReviewRequestComponent, dialogConfig);
    dialogRef.afterClosed().toPromise();
  }
}
