export const Channels = {
  BRANCHLINESTATUS: 'BranchlineStatusHome',
  ACCELERATORSTATUS: 'AcceleratorStatus',
  BULLETINBOARD: 'BulletinBoard',
  RADIATIONAREA: 'RadiationArea',
  MESSAGEBANNER: 'MessageBanner',
  WFTASK: 'WFTask',
  PROCEDURECATEGORY: 'ProcedureCategory',
  PROCEDURE: 'Procedure',
  PROCEDURETRAINING: 'ProcedureTraining',
  PROCEDURECREDITED: 'ProcedureCredited',
  PROCEDURETEMPLATE: 'ProcedureTemplate',
  PROCEDURECONFIGURATION: 'ProcedureConfiguration',
  CLOCKTIMER: 'ClockTimerStatus',
  ALLABSIS: 'AllAbsis',
  ABSI_TEMPLATES: 'AbsiTemplates',
  ABSITEMPLATETYPES: 'AbsiTemplateTypes',
  ABSISECTION: 'AbsiSection',
  CATLACTIONITEMS: 'CatlActionItems',
  CHECKLISTS: 'Checklists',
  CHECKLIST_TEMPLATES: 'ChecklistTemplates',
  TEMPLATETYPES: 'TemplateTypes',
  RADINTERLOCK: 'RadInterlock',
  RADINTERLOCKRELATION: 'RadInterlockRelation',
  RADLOCATION: 'RadLocation',
  RADLOCATIONTYPE: 'RadLocationType',
  RADMONITOR: 'RadMonitor',
  RADMONITORDESCRIPTION: 'RadMonitorDescription',
  RADMONITORTYPE: 'RadMonitorType',
  RADNOTE: 'RadNote',
  CHECKLISTSECTION: 'ChecklistSection',
  SCHEDULES: 'Schedules',
  SCHEDULE_TYPES: 'ScheduleTypes',
  SCHEDULE_SUBTYPES: 'ScheduleSubtypes',
  SCHEDULE_REMINDERS: 'ScheduleReminders',
  DOCUMENTTYPES: 'DocumentTypes',
  ALLBRANCHLINES: 'Branchlines',
  KENABLE: 'KEnable',
  KEVERSION: 'KEVersion',
  CHECKLISTTEMPLATES: 'ChecklistTemplates',
  MENUITEMS: 'MenuItems',
  ANNOTATIONS: 'Annotations',
  APLCATEGORY: 'APLCategory',
  REVIEW_BOARD_TYPES: 'ReviewBoardTypes',
  REVIEW_BOARDS: 'ReviewBoards',
  REVIEWS: 'Reviews',
  REVIEW_TYPES: 'ReviewTypes',
  REVIEW_CYCLES: 'ReviewCycles',
  RESOURCE_TYPES: 'ResourceTypes',
  REVIEW_ACTION_ITEMS: 'ReviewActionItems',
  REVIEW_ACTION_ITEM_TYPES: 'ReviewActionItemTypes',
  REVIEW_SATISFACTION_CHECKS: 'ReviewSatisfactionChecks',
  REVIEW_PERMISSIONS: 'ReviewPermissions',
  RESOURCES: 'Resources',
  PENDING_RESOURCES: 'PendingResources',
  REVIEW_STATUS: 'ReviewStatus',
  FORMS: 'Forms',
  FORMSTATUSES: 'FormStatus',
  OLOGCATEGORY: 'OlogCategory',
  OLOGEMAIL: 'OlogEmail',
  APLGROUP: 'APLGroup',
  REVIEW_ATTENDANCE_CATEGORY: 'ReviewAttendanceCategory',
  REVIEW_ATTENDANCE_ROSTER: 'ReviewAttendanceRoster',
  REVIEW_ATTENDANCE_SAVE: 'ReviewAttendanceSave',
  ROLE: 'Role',
  ROLE_PRIVILEGES: 'RolePrivileges',
  USERS: 'Users',
  COMPONENTS: 'Components',
  RADMETERS: 'RadMeters',
  BEAMLINE_SHUTTER: 'BeamlineShutter',
  SCF_MASTERS: 'SCFMasters',
  BRATOI: 'Bratoi',
  RESTRICTIONS: 'Restrictions',
  DOCUMENTS: 'Documents',
  WORKERVERIFICATION: 'WorkerVerification',
  CHECKLIST_STATUSES: 'ChecklistStatus',
  PRIVILEGES: 'Privileges',
  PRIVILEGE_CATEGORIES: 'PrivilegeCategories',
  PENDING_APPROVALS: 'PendingApprovals',
  MY_PENDING_CATALOGS: 'MyPendingChanges',
  PENDING_CATALOGS: 'PendingCatalogs',
  COMMENTS: 'Comments',
  TASKS: 'Tasks',
  RESOURCE_RELATIONS: 'ResourceRelations',
  BEAMLINE_STATUS: 'BeamlineStatus',
  RSSWA: 'Rsswa',
  NAVIGATIONLINKS: 'NavigationLinks',
  NOTIFICATIONS: 'Notifications',
  TOCAS: 'Tocas',
  TOXIC_GAS: 'ToxicGas',
  BAKEOUT: 'Bakeout',
  PVINFO: 'PVInfo',
  AUTHENTICATION: 'Authentication',
  ALERT: 'Alert',
  DELETED_TASKS: 'DeletedTasks',
  DELETED_STEPS: 'DeletedSteps',
  DELETED_SECTIONS: 'DeletedSections',
  BEAMLINE_RESTRICTIONS: 'BeamlineRestrictions',
  PLACEHOLDERS: 'Placeholders'
};
