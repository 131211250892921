import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { Catalog, PendingResourcesType } from "src/app/common/enumerations/enumerations";
import { CanDeactivateResponse } from "src/app/controls/pending-changes-dialog/pending-changes-dialog-response";
import { PendingChangesDialogComponent } from "src/app/controls/pending-changes-dialog/pending-changes-dialog.component";
import { PendingResource, PendingApprovals, PendingResourceUsers, PendingCatalog, PendingCatalogType } from "src/app/services/pending-approvals/pending-approval";
import { BeamlineShutterRelation, Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { UserCatalog } from "src/app/components/catalogs/user-catalog/services/user";
import { environment } from "src/environments/environment";
import { CatalogModel, CatalogModelList, ModifyingResult, Tuple } from "./catalog-model";
import { ScheduleBeamlineOverviewResource } from "src/app/services/schedule/schedule-beamline-overview-resource";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  public api: string = environment.urls.baseApiUrl + '/WorkFlowCatalogs';
  public apiPendingResources: string = environment.urls.baseApiUrl + '/PendingResources';
  public apiPendingApproval: string = environment.urls.baseApiUrl + '/PendingApproval';
  public userCatalogs?: UserCatalog[];

  constructor(
    private http: HttpClient,
    private dialog: MatDialog
  ) { }

  public currentDocSchedule!: ScheduleBeamlineOverviewResource;

  pendingCatalog?: PendingCatalog;


  GetPendingCatalogById(id: number, catalog: Catalog): Observable<CatalogModel> {
    return this.http.get<CatalogModel>(this.apiPendingApproval + '/' + id + '/' + catalog);
  }

  GetJoinCreated(catalog: Catalog, join: boolean): Observable<CatalogModelList> {
    return this.http.get<CatalogModelList>(this.api + '/All/' + catalog + '/' + join);
  }

  GetHistory(catalog: Catalog, id: number) {
    return this.http.get<CatalogModelList>(this.api + '/History/' + catalog + '/' + id);
  }

  GetBeamlineShutter(beamlineId: number) {
    return this.http.get<BeamlineShutterRelation>(this.api + '/BeamlineShutter/' + beamlineId);
  }

  IsModifiyingById(id: number, catalog: Catalog): Observable<ModifyingResult> {
    return this.http.get<ModifyingResult>(this.api + '/IsModifiying/' + id + '/' + catalog);
  }

  GetAllPendingResources(): Observable<PendingResource[]> {
    return this.http.get<PendingResource[]>(this.apiPendingResources);
  }

  GetPendingResourcesUsersByResourceId(idRosurce: number, typePendingResource: PendingResourcesType): Observable<PendingResourceUsers> {
    return this.http.get<PendingResourceUsers>(this.apiPendingResources + '/version/users/' + idRosurce + '/' + typePendingResource);
  }

  IsFieldRepeated(catalogModel: CatalogModel, catalog: Catalog): Observable<Tuple> {
    return this.http.post<Tuple>(this.api + '/IsFieldRepeated/' + catalog, catalogModel);
  }

  IsFieldRepeatedResource(resourceName: string, type: number): Observable<boolean> {
    const resource: Resource = { id: 0, type, name: resourceName };
    return this.http.post<boolean>(this.api + '/IsFieldRepeatedResource/' + type, resource);
  }

  SaveAsDraft(catalogModel: CatalogModel, catalog: Catalog) {
    return this.http.post(this.api + '/' + catalog, catalogModel);
  }

  SaveAsDraftType(catalogModel: CatalogModel, catalog: Catalog, typePendingResource: PendingResourcesType) {
    return this.http.post(this.api + '/' + catalog + '/' + typePendingResource, catalogModel);
  }

  Approve(id: number, catalog: Catalog, type: PendingResourcesType = PendingResourcesType.Default): Observable<number> {
    return this.http.put<number>(this.api + '/Approve/' + id + '/' + catalog + '/' + type, null);
  }

  Unapprove(id: number, catalog: Catalog, disapprovalReason: string, type: PendingResourcesType = PendingResourcesType.Default) {
    return this.http.put(this.api + '/Disapprove/' + id + '/' + catalog + '/' + type, { reason: disapprovalReason });
  }

  RemoveRow(id: number, catalog: PendingCatalogType) {
    return this.http.delete(this.api + '/' + id + '/' + catalog);
  }

  CancelPending(id: number, catalog: Catalog, type: PendingResourcesType) {
    return this.http.delete(this.api + '/DeletePending/' + id + '/' + catalog + '/' + type);
  }



  async confirmationDialog(val: boolean): Promise<boolean> {
    if (val) {
      const confirmation = this.dialog.open(PendingChangesDialogComponent, {
        height: 'fit-content',
        width: '40%',
        data: {}
      });

      confirmation.afterClosed().subscribe((response: CanDeactivateResponse) => {
        switch (response) {
          case CanDeactivateResponse.Discard:
            return true;
          default:
            return false;
        }
      });
    } else {
      return true;
    }
    return false;
  }
}
