import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'filter-box',
  templateUrl: './filter-box.component.html',
  styleUrls: ['./filter-box.component.scss']
})
export class FilterBoxComponent {

  @Input() data?: FilterData[];
  @Output() changed = new EventEmitter<any>();
  @Output() selected = new EventEmitter<FilterData>();

  @ViewChild('filterBox') filterBox!: ElementRef;
  @ViewChild('results') results!: ElementRef;

  valueFilter?: string | null;
  filteredValues?: FilterData[];

  applyFilter(e: any) {
    if (!e) {
      this.valueFilter = '';
      this.filteredValues = undefined;
    }
    else this.valueFilter = e.target.value;
    if (!this.data && (this.valueFilter?.length ?? 0) >= 1 || !this.valueFilter)
      this.changed.emit(this.valueFilter);
    else {
      if (this.valueFilter) {
        this.filteredValues = this.data?.filter(x => this.valueFilter ? x.text.toLowerCase().includes(this.valueFilter.toLowerCase()) : false);
        const width = this.filterBox?.nativeElement.clientWidth;
        this.results.nativeElement.style.width = width + 'px';
      }
      else this.filteredValues = undefined;
    }
  }

  itemSelected(i: FilterData) {
    this.selected.emit(i);
  }
}

export interface FilterData {
  id: number;
  text: string;
}
