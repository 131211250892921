<div class="left-table-container table-container mat-container scrollbar">
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        System Category
      </mat-header-cell>
      <mat-cell class="blue font-weight-bold" *matCellDef="let element" nowrap
        ><span [innerHTML]="element.name | highlightSearch : filter"></span
      ></mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Description
      </mat-header-cell>
      <mat-cell class="blue pl-1" *matCellDef="let element">
        <span
          [innerHTML]="element.description | highlightSearch : filter"
        ></span
      ></mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      #matrow
      *matRowDef="let row; columns: displayedColumns"
      (click)="getValues(row)"
      [ngClass]="{
          'mat-row-highlight': selectedRowIndex == row.id,
        }"
    ></mat-row>
  </mat-table>
</div>
