import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { WorkerVerification } from './worker-verification';

@Injectable({
  providedIn: 'root'
})
export class WorkerVerificationService {
  baseUrl = environment.urls.baseApiUrl;
  endPoint = '/WorkerVerification';
  byForm = '/ByForm';
  byFormUser = '/ByFormUser';

  constructor(
    public http: HttpClient,
  ) { }

  public getAll(): Observable<WorkerVerification[]> {
    return this.http.get<WorkerVerification[]>(this.baseUrl + this.endPoint);
  }

  public getByForm(form: number, formID: number): Observable<WorkerVerification[]> {
    return this.http.get<WorkerVerification[]>(this.baseUrl + this.endPoint + this.byForm + '?form=' + form.toString() + '&formID=' + formID.toString());
  }

  public getByFormUser(form: number, formID: number): Observable<WorkerVerification> {
    return this.http.get<WorkerVerification>(this.baseUrl + this.endPoint + this.byFormUser + '?form=' + form.toString() + '&formID=' + formID.toString());
  }

  public create(workerVerification: WorkerVerification): Observable<WorkerVerification> {
    return this.http.post<WorkerVerification>(this.baseUrl + this.endPoint, workerVerification);
  }

  public delete(id: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl + this.endPoint + '/' + id.toString());
  }

  public deleteByFormUser(form: number, formID: number, userID: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl + this.endPoint + this.byFormUser + '?form=' + form.toString() + '&formID=' + formID.toString() + '&userID=' + userID.toString());
  }
}
