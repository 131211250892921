import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReviewAttachmentTypeDetailsComponent } from './review-attachment-type-details/review-attachment-type-details.component';
import { Review, ReviewDocumentCategory } from '../../reviews.models';
import { ReviewsService } from '../../reviews.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-review-attachment-types',
  templateUrl: './review-attachment-types.component.html',
  styleUrls: ['./review-attachment-types.component.scss']
})
export class ReviewAttachmentTypesComponent extends BaseComponent implements OnInit {

  public valueFilter: string = '';
  public dataSource: MatTableDataSource<ReviewDocumentCategory> = new MatTableDataSource<ReviewDocumentCategory>();
  public showInactive?: boolean;
  public loading = false;
  public reviewCategories!: ReviewDocumentCategory[];
  public displayedColumns: string[] = ['name', 'description', 'status', 'actions'];
  public createAvailable!: boolean;
  public selectedRowIndex = -1;
  public pending: boolean = false;

  constructor(protected override injector: Injector,
    private dialogRef: MatDialogRef<ReviewAttachmentTypesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Review,
    private _service: ReviewsService,
  ) {
    super(injector);

  }

  ngOnInit() {
    this.clear();
    this.loadReviewCategories();
    this.createAvailable = true;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clear() {
    this.showInactive = true;
    this.selectedRowIndex = 0;
    this.dataSource = new MatTableDataSource();
  }

  async loadReviewCategories(event?: any) {
    this.loading = true;
    this.valueFilter = '';
    await this._service.getReviewDocumentCategories().toPromise().then(result => {
      if (!(result?.length)) {
        result = [];
      }
      this.reviewCategories = result;
      if (!this.showInactive) {
        this.dataSource = new MatTableDataSource<ReviewDocumentCategory>(result.filter(x => x.status == 1));
      } else {
        this.dataSource = new MatTableDataSource<ReviewDocumentCategory>(result);
      }
    }).finally(() => this.loading = false);
  }

  refreshReviewCategories(event?: any) {
    this.loadReviewCategories();
    this.selectedRowIndex = -1;
  }

  showReviewCategoriesInactive() {
    this.refreshReviewCategories();
  }

  sortData(sort: Sort) {
    const data = this.reviewCategories;
    var sortedData: ReviewDocumentCategory[];
    if (!sort.active || sort.direction === '') {
      sortedData = data;
      return;
    }
    sortedData = data.sort((a, b) => {
      const isDesc = sort.direction === 'desc';
      switch (sort.active) {
        case 'name': return compare(a.name, b.name, isDesc);
        case 'description': return compare(a.description, b.description, isDesc);
        case 'status': return compare(a.status, b.status, isDesc);
        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource(sortedData);
  }

  getColor(reviewDocumentCategory: ReviewDocumentCategory): string {
    if (reviewDocumentCategory.status == 0)
      return "#D8D8D8";
    else
      return "#000000";
  }

  getPosition(row: any): number {
    var i = this.dataSource.data.findIndex(r => {
      return r.id == row.id;
    });
    return i;
  }

  highlight(row: any) {
    if (!this.pending) {
      this.selectedRowIndex = row.id;
    }
  }

  createReviewDocumentCategory() {
    this.selectedRowIndex = 0;
    let reviewDocumentCategoryNew: ReviewDocumentCategory = {} as ReviewDocumentCategory;
    reviewDocumentCategoryNew.id = 0;
    reviewDocumentCategoryNew.description = "";
    reviewDocumentCategoryNew.code = "";
    reviewDocumentCategoryNew.status = 1;
    reviewDocumentCategoryNew.name = "";
    reviewDocumentCategoryNew.type = 0;
    this.openDetailsReviewDocumentCategory(reviewDocumentCategoryNew);
  }

  openDetailsReviewDocumentCategory(reviewDocumentCategoryNew: ReviewDocumentCategory) {
    let dataDialog = reviewDocumentCategoryNew;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = dataDialog;
    dialogConfig.minWidth = '35vw';
    dialogConfig.maxHeight = '85%';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ReviewAttachmentTypeDetailsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data != undefined) {
        this.loading = true;
        this.refreshReviewCategories(data);
        if (data == "Create")
          this.alert.success(this.getMessage('BRC_Confirmation_Created_BRC_File_Category').description);
        else if (data == "Update")
          this.alert.success(this.getMessage('BRC_Confirmation_Updated_BRC_File_Category').description);
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  async deleteReviewDocumentCategory(element: ReviewDocumentCategory) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('BRC_Question_Delete_File_Category').description,
        icon: 'stop'
      }
    });
    confirm.afterClosed().toPromise().then(async data => {
      if (data != null) {
        this.loading = true;
        await this._service.getReviewDocumentsByCategory(element.id).toPromise().then(async result => {
          if (result?.length == 0) {
            await this._service.deleteReviewDocumentCategory(element.id).toPromise().then(result => {
              this.alert.success(this.getMessage('BRC_Confirmation_Deleted_File_Category').description);
            }).catch(() => {
              this.alert.error("Error: The beamline review attachments category was not deleted.");
            });
          }
          else {
            this.alert.error(this.getMessage('BRC_Message_File_Category_InUse').description);
          }
        });
      }
    }, error => {
    }).finally(() => {
      this.loading = false;
      this.refreshReviewCategories(true);
    });
  }

  EditReviewDocumentCategory(element: ReviewDocumentCategory) {
    this.openDetailsReviewDocumentCategory(element);
  }
}

function compare(a?: number | string | null, b?: number | string | null, isAsc?: boolean) {
  if (a && b)
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  return 0;
}
