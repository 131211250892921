import { Component, ElementRef, HostListener, Input, OnInit, Output, ViewChild, EventEmitter, Injector } from '@angular/core';
import { Action, ListImage } from '../cl-image-upload.component';
import { listImages } from '../../cl-editor/cl-editor-image/cl-editor-image.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';

const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2
}

@Component({
  selector: 'cl-image-upload-info',
  templateUrl: './cl-image-upload-info.component.html',
  styleUrls: ['./cl-image-upload-info.component.scss']
})
export class ClImageUploadInfoComponent extends BaseComponent implements OnInit {

  @Input('width') public width!: number;
  @Input('height') public height!: number;
  @Input('left') public left!: number;
  @Input('top') public top!: number;
  @Input() public rowId!: number;
  @Input() public actionType!: Action;
  @Input() imageId!: number;
  @Input() imageList!: ListImage[];

  @Output() cancel = new EventEmitter();
  @Output() imageChanged = new EventEmitter<ListImage[]>();

  @ViewChild('box') public box!: ElementRef;

  action = Action;
  canBeDeleted?: boolean;
  image?: string;

  private boxPosition!: { left: number; top: number; };
  private containerPos!: { left: number; top: number; right: number; bottom: number; };
  public mouse!: { x: number; y: number; };
  public status: Status = Status.OFF;
  private mouseClick!: { x: number; y: number; left: number; top: number; };

  constructor(
    protected override injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    if (this.imageId) {
      const image = this.imageList.find(i => i.id == this.imageId);
      setTimeout(() => {
        this.canBeDeleted = image?.canBeDeleted;
        this.image = image?.image;
      }, 100);
    }
  }

  ngAfterViewInit() {
    this.loadBox();
    this.loadContainer();
  }

  private loadBox() {
    const { left, top } = this.box.nativeElement.getBoundingClientRect();
    this.boxPosition = { left, top };
  }

  private loadContainer() {
    const left = this.boxPosition.left - this.left;
    const top = this.boxPosition.top - this.top;
    const right = left + 600;
    const bottom = top + 450;
    this.containerPos = { left, top, right, bottom };
  }

  setStatus(event: MouseEvent, status: number) {
    if (status === 1) { event.stopPropagation(); }
    else if (status === 2) { this.mouseClick = { x: event.clientX, y: event.clientY, left: this.left, top: this.top }; }
    else { this.loadBox(); }
    this.status = status;
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.mouse = { x: event.clientX, y: event.clientY };

    if (this.status === Status.RESIZE) { this.resize(); }
    else if (this.status === Status.MOVE) { this.move(); }
  }

  private resize() {
    if (this.resizeCondMeet()) {
      this.width = Number(this.mouse.x > this.boxPosition.left) ? this.mouse.x - this.boxPosition.left : 0;
      this.height = Number(this.mouse.y > this.boxPosition.top) ? this.mouse.y - this.boxPosition.top : 0;
    }
  }

  private resizeCondMeet() {
    return (this.mouse.x < this.containerPos.right && this.mouse.y < this.containerPos.bottom);
  }

  private move() {
    if (this.moveCondMeet()) {
      this.left = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
      this.top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
    }
  }

  private moveCondMeet() {
    return true;
    const offsetLeft = this.mouseClick.x - this.boxPosition.left;
    const offsetRight = this.width - offsetLeft;
    const offsetTop = this.mouseClick.y - this.boxPosition.top;
    const offsetBottom = this.height - offsetTop;
    return (
      this.mouse.x > this.containerPos.left + offsetLeft &&
      this.mouse.x < this.containerPos.right - offsetRight &&
      this.mouse.y > this.containerPos.top + offsetTop &&
      this.mouse.y < this.containerPos.bottom - offsetBottom
    );
  }

  cancelAdd() {
    this.cancel.emit(true);
  }

  upload(e: any): void {
    const imageList = this.imageList ?? [];
    const imageId = this.nextId(this.imageList);
    const changed = this.imageChanged;
    const render = new FileReader();
    render.onload = async function (event) {
      const img = new Image();
      img.onload = function () {
        imageList.push({
          id: imageId,
          name: e.target.files[0].name,
          image: img.src,
          imageWidth: img.width,
          imageHeight: img.height,
        });
        changed.emit(imageList);
      };
      img.src = event.target?.result?.toString() ?? '';
    };
    render.readAsDataURL(e.target.files[0]);
  }

  removeImage() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "400px",
      data: {
        message: this.getMessage("Rad_Survey_Image_Component_Delete_Image")
          .description,
        icon: "stop",
      },
    });

    confirm.afterClosed().toPromise().then(data => {
      if (data) {
        const imageList = this.imageList.filter(i => i.id != this.imageId);
        this.imageChanged.emit(imageList);
      }
    });
  }

  nextId(listImages: listImages[]): number {
    let _id = 0;
    listImages = listImages ?? [];
    if (listImages.length) {
      const uniqueIds = [...new Set(listImages.map((obj) => obj.id))];
      let arrayIds: number[] = [];
      uniqueIds.map((n) => {
        if (n)
          arrayIds.push(n);
      });
      arrayIds = arrayIds.sort((n1, n2) => n1 - n2);
      _id = Number(Math.max.apply(null, arrayIds)) + 1;
    } else {
      _id = 1;
    }
    return _id;
  }

}
