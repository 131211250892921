import { Component, Output, EventEmitter, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Option } from 'src/app/components/checklists/checklists';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';

@Component({
  selector: 'cl-checkbox-radio-location',
  templateUrl: './cl-checkbox-radio-location.component.html',
  styleUrls: ['./cl-checkbox-radio-location.component.scss']
})

export class ClCheckboxRadioLocationComponent extends ClBaseComponent implements OnInit, OnChanges, OnDestroy {

  @Output() sendOptions = new EventEmitter<any>();
  @Output() sendCondition = new EventEmitter<any>();

  public role!: string;
  public name!: string;

  public visible = false;

  public condition = '!SCFActive';

  public branchlines: Resource[] = [];
  public branchlines$!: Observable<Resource[]>;
  public location?: Resource;

  public subscription!: Subscription;

  override ngOnDestroy() {
    this.subscription.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges() {
    this.getBranchlines();
    if (this.radioSelection) { this.setRadio(this.radioSelection); }
  }

  ngOnInit() {
    if (!this.radioOptions?.length) { this.setRadioOptions(); }
    if (this.radioSelection) { this.setRadio(this.radioSelection); }
  }

  getBranchlines() {
    this.branchlines$ = this.store.select(state => state.Branchlines.data);
    this.subscription = this.branchlines$.subscribe(data => {
      this.branchlines = data;
      if (this.configuration) {
        const val = JSON.parse(this.configuration).val;
        this.location = data.find(x => x.id === val);
      }
    });
  }

  checkboxChanged(e: any) {
    this.checked = e.checked;
    this.user = this.checked ? this.getCurrentUser() : null;
    this.answered.emit(this.checked);
  }

  radioChange(e: any) {
    this.radioSelected.emit(e.value);
  }

  checkboxEnabled() {
    let enabled = !this.disabled;
    if (enabled) {
      enabled = this.radioOptions?.map(r => r.value).includes(this.radioSelection ?? -1) ?? false;
    }
    return enabled;
  }

  setRadio(value: number) {
    this.radioOptions?.map(r => {
      if (typeof (r.value) === 'string') {
        r.value = parseInt(r.value, 10);
      }
      r.checked = r.value === value;
    });
  }

  setRadioOptions() {
    const options: Option[] = [
      { label: 'Yes', value: 1 },
      { label: 'No', value: 2 }
    ];
    this.radioOptions = options;
    this.sendOptions.emit(options);
    this.sendCondition.emit(this.condition);
  }
}
