import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import * as BranchlineStatusActions from './branchline.actions';
import { GeneralSignalRResponse } from "src/app/services/signal-r/general-signal-r-response";
import { SignalRService } from "src/app/services/signal-r/signal-r.service";
import { SignalRAction } from "src/app/services/signal-r/signalr-enums";
import { Channels } from "src/app/store/channels";

@Injectable({
  providedIn: 'root'
})
export class BranchlineStatusStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore() {
    this.store.dispatch(new BranchlineStatusActions.Refresh());
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.BRANCHLINESTATUS, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new BranchlineStatusActions.Add(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new BranchlineStatusActions.Update(data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new BranchlineStatusActions.Delete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new BranchlineStatusActions.Refresh());
      }
    });

  }
}
