<div class="card w-100 p-0">
  <div class="card-header p-3">
    <div class="row pb-0 flex-row flex-nowrap justify-content-between">
      <div class="col d-flex align-items-center">
        <h6>{{ title }}</h6>
      </div>

      <div class="col d-flex justify-content-end align-items-center">
        <mat-chip-row class="ml-3 user-name small" *ngIf="userNames">{{
          userNames
        }}</mat-chip-row>
        <mat-checkbox-with-roles
          [checked]="value"
          class="checkbox"
          [disabled]="disabled ?? false"
          (change)="checkboxChanged($event)"
          color="primary"
          [roleCodes]="roleCodes"
          [user]="user"
          [date]="date"
          [warning]=""
        >
        </mat-checkbox-with-roles>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-3 nowrap">By:</div>
      <div class="col-9">
        <span class="name" *ngIf="value">{{ user?.name }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <small class="nowrap">Date:</small>
      </div>
      <div class="col-9">
        <span class="date small" *ngIf="value">{{
          date | date : "medium"
        }}</span>
      </div>
    </div>
  </div>
</div>
