
export class OlogCategory {
  id?: number;
  name?: string;
  status?: number;
  documentTypeID?: number;
  deleteReason?: string;
  isModified?: boolean;
  createdOn?: Date;
  createdBy?: number;
  updatedOn?: Date;
  updatedBy?: number;
}
