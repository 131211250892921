import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatFormFieldDefaultOptions, MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ErrorStateMatcher, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { FormControl, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { ScheduleRoutingModule } from './schedule-routing.module';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFileUploadModule } from 'src/app/modules/file-upload/file-upload-module';
import { AppComponent } from 'src/app/app.component';
import { httpInterceptorProviders } from 'src/app/interceptors/interceptorProviders';
import { SharedModule } from 'src/app/modules/shared.module';
import { QuillModule } from 'ngx-quill';
import { SchedulesV2Component } from './schedules-v2.component';
import { ScheduleTabComponent } from './schedule-tab/schedule-tab.component';
import { AddNoteComponent } from './schedule-tab/add-note/add-note.component';
import { AddScheduleComponent } from './schedule-tab/add-schedule/add-schedule.component';
import { RequestExtensionComponent } from './schedule-tab/request-extension/request-extension.component';
import { RejectExtensionComponent } from './schedule-tab/reject-extension/reject-extension.component';
import { EditScheduledForComponent } from './schedule-tab/edit-scheduled-for/edit-scheduled-for.component';
import { CompleteScheduleComponent } from './schedule-tab/complete-schedule/complete-schedule.component';
import { EditRequestMessageComponent } from './dialogs/edit-request-message/edit-request-message.component';
import { AddReminderComponent } from './reminder-tab/add-reminder/add-reminder.component';
import { ReminderTabComponent } from './reminder-tab/reminder-tab.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

class TouchedErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

@NgModule({
  declarations: [
    SchedulesV2Component,
    ScheduleTabComponent,
    AddScheduleComponent,
    EditScheduledForComponent,
    CompleteScheduleComponent,
    AddNoteComponent,

    RequestExtensionComponent,
    RejectExtensionComponent,
    EditRequestMessageComponent,

    ReminderTabComponent,
    AddReminderComponent
  ],

  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    HttpClientModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatSlideToggleModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    ScheduleRoutingModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatChipsModule,
    FormsModule,
    MatToolbarModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    ScrollingModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatRadioModule,
    MatFileUploadModule,
    MatStepperModule,
    MatGridListModule,
    DragDropModule,
    MatTreeModule,
    MatOptionModule,
    MatSidenavModule,
    QuillModule.forRoot(),
    SharedModule
  ],
  exports: [
    // ScheduleTabComponent,
    // SchedulesV2Component,
    RouterModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    httpInterceptorProviders,
    { provide: ErrorStateMatcher, useClass: TouchedErrorStateMatcher }
  ],
  bootstrap: [AppComponent, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ScheduleModule { }
