import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { Review, ReviewAttendanceCategory, ReviewAttendanceRoster, ReviewStatusEnum } from 'src/app/components/reviews/reviews.models';
import { ReviewsService } from 'src/app/components/reviews/reviews.service';
import { ReviewAttendanceCategoriesRefresh } from 'src/app/components/reviews/store/review-attendance-category/review-attendance-category.action';
import { ReviewAttendanceCategoriesComponent } from './dialogs/review-attendance-categories/review-attendance-categories.component';
import { ReviewMembersComponent } from './dialogs/review-members/review-members.component';
import { ReviewAttendanceSaveComponent } from './dialogs/review-attendance-save/review-attendance-save.component';
import { ReviewAttendanceSave, ReviewAttendanceSaveList } from '../../../../reviews.models';

import { ReviewsRefreshById } from 'src/app/components/reviews/store/reviews/reviews.action';
import { ReviewAttendanceSavedEditComponent } from './review-attendance-saved/saved-edit/saved-edit.component';
import { ReviewMembersLoadSavedRosterComponent } from './dialogs/load-saved/load-saved-roster.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { ReviewAttendanceRosterDelete } from 'src/app/components/reviews/store/review-attendance-roster/review-attendance-roster.action';

@Component({
  selector: 'app-reviews-boards-reviews-members',
  templateUrl: './reviews-members.component.html',
  styleUrls: ['./reviews-members.component.scss']
})
export class ReviewsBoardsReviewsMembersComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() review?: Review;
  @Input() canDelete!: boolean;
  @Input() canRegister?: boolean;

  public reviewCategories$!: Observable<ReviewAttendanceCategory[]>;

  public reviewCategorySubscription!: Subscription;
  public userSubscription!: Subscription;

  public reviewCategories!: ReviewAttendanceCategory[];
  public reviewCategoriesToShow!: ReviewAttendanceCategory[];

  public reviewAttendanceSaved!: ReviewAttendanceSave[];
  public similarReviewAttendanceSaved!: ReviewAttendanceSave[];
  public reviewAttendanceSaved$!: Observable<ReviewAttendanceSave[]>;
  public reviewAttendanceSavedSubs!: Subscription;

  public displayedColumnsMembers!: string[];

  reviews!: Review[];
  reviews$!: Observable<Review[]>;
  reviewsSubs!: Subscription;
  public similarRoster!: ReviewAttendanceSave;

  reviewStatusEnum = ReviewStatusEnum;


  constructor(
    protected override injector: Injector,
    private service: ReviewsService,

  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.reviewsSubs?.unsubscribe();
    this.userSubscription?.unsubscribe();
    this.reviewCategorySubscription?.unsubscribe();
    this.reviewAttendanceSavedSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit() {
    this.setColumns();
  }

  setColumns() {
    this.displayedColumnsMembers = this.canDelete && this.review?.reviewStatusID != this.reviewStatusEnum.Completed && this.review?.reviewStatusID != this.reviewStatusEnum.Canceled ? ['role', 'member', 'attendance', 'options'] : ['role', 'member', 'attendance'];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getReviews();
    this.getReviewCategories();
    this.getSavedLists();
    this.service.loading = false;
    this.setColumns();
  }

  getAttendance() {
    setTimeout(() => {
      this.reviewCategoriesToShow = [];
      this.review?.reviewAttendanceRosters?.map(r => {
        if (!this.reviewCategoriesToShow.map(c => c.id).includes(r.categoryID)) {
          const reviewCategory = this.reviewCategories.find(x => x.id == r.categoryID);
          if (reviewCategory)
            this.reviewCategoriesToShow.push(reviewCategory);
        }
      });
      this.reviewCategoriesToShow = this.reviewCategoriesToShow.sort((a, b) => this.utils.sort(a.order, b.order));
      this.compareSavedList();
    }, 100);
  }

  getReviews() {
    this.reviews$ = this.store.select(state => state.Reviews.data);
    this.reviewsSubs = this.reviews$.subscribe(data => {
      if (data.length) {
        this.reviews = data;
        if (this.review) {
          this.review = this.reviews.find(x => x.id == this.review?.id);
          this.getReviewCategories();
          this.getSavedLists();
          this.setColumns();
        }
      }
    });
  }

  getSavedLists() {
    this.reviewAttendanceSaved$ = this.store.select(state => state.ReviewAttendanceSaved.data);
    this.reviewAttendanceSavedSubs = this.reviewAttendanceSaved$.subscribe(data => {
      this.reviewAttendanceSaved = data;
      this.compareSavedList();
    });
  }

  getReviewCategories() {
    this.reviewCategories$ = this.store.select(state => state.ReviewAttendanceCategories.data);
    this.reviewCategorySubscription = this.reviewCategories$.subscribe(data => {
      this.reviewCategories = data.sort((a, b) => this.utils.sort(a.order, b.order));
      this.getAttendance();
    });
  }

  getReviewMembersByCategory(reviewCategory: ReviewAttendanceCategory): MatTableDataSource<ReviewAttendanceRoster> {
    const dataSourceMembers = new MatTableDataSource<ReviewAttendanceRoster>(this.review?.reviewAttendanceRosters.filter(x => x.categoryID == reviewCategory.id));
    return dataSourceMembers;
  }

  reviewMembersDialog() {
    this.service.loading = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      review: this.review,
      reviewMember: {} as ReviewAttendanceRoster,
      reviewCategories: this.reviewCategories,
      dialogTitle: 'Add Member',
    };
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ReviewMembersComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (!data) this.service.loading = false;
      if (this.review)
        this.store.dispatch(new ReviewsRefreshById(this.review.id));
    });
  }

  reviewAttendanceCategoryDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialogTitle: 'Review Categories',
    };
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    const cats = this.dialog.open(ReviewAttendanceCategoriesComponent, dialogConfig);
    cats.afterClosed().toPromise().then(() => {
      this.store.dispatch(new ReviewAttendanceCategoriesRefresh());
    });
  }

  addFromSavedDialog() {
    this.service.loading = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      review: this.review,
      reviewAttendanceSave: this.similarReviewAttendanceSaved,
      reviewMembersComponent: this
    };
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '800px';
    dialogConfig.minHeight = '400px';
    const dialogRef = this.dialog.open(ReviewMembersLoadSavedRosterComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data: ReviewAttendanceSave) => {
      if (data) {
        this.loadRoster(data);
      }
      else this.service.loading = false;
    });
  }

  async deleteRosters() {
    this.review?.reviewAttendanceRosters.map(async r => {
      await this.service.deleteReviewAttendanceRoster(r.id).toPromise().then(() => {
      });
    });
  }

  loadRoster(data: ReviewAttendanceSave) {
    const list = data.reviewAttendanceSavedList;
    const replace = data.replace;
    let countAdd = 0;
    let countDel = 0;
    list.map(l => {
      const found = this.review?.reviewAttendanceRosters.find(x => x.userID == l.userID && x.categoryID == l.categoryID && x.roleID == l.roleID);
      if (!found) {
        countAdd += 1;
        const reviewAttendanceRoster = {
          id: 0,
          reviewID: this.review?.id,
          userID: l.userID,
          categoryID: l.categoryID,
          roleID: l.roleID
        } as ReviewAttendanceRoster;
        this.service.createReviewAttendanceRoster(reviewAttendanceRoster).toPromise().then(res => {
          if (this.review)
            this.store.dispatch(new ReviewsRefreshById(this.review.id));
          this.store.dispatch(new ReviewAttendanceCategoriesRefresh());
          this.getReviewCategories();
        });
      }
    });
    this.review?.reviewAttendanceRosters.map((l, i) => {
      const found = list.find(x => x.userID == l.userID && x.categoryID == l.categoryID && x.roleID == l.roleID);
      if (!found && replace) {
        countDel += 1;
        this.service.deleteReviewAttendanceRoster(-l.id).toPromise().then(() => {
          if (this.review) {
            this.review.reviewAttendanceRosters.splice(i, 1);
            this.store.dispatch(new ReviewAttendanceRosterDelete(-l.id));
            this.store.dispatch(new ReviewsRefreshById(this.review.id));
            this.store.dispatch(new ReviewAttendanceCategoriesRefresh());
            this.getReviewCategories();
          }
        });
      }
    });
    this.alert.info(countAdd + ' members added and ' + countDel + ' removed from Roster');
  }

  setAttendance(isAttended: boolean, reviewAttendanceRoster: ReviewAttendanceRoster) {
    reviewAttendanceRoster.attended = isAttended;
    this.service.updateReviewAttendanceRoster(reviewAttendanceRoster).toPromise().then((reviewAttendanceRosterUpdated) => {
    }).catch(() => {
      this.alert.message('genericError');
    });
  }

  deleteReviewMember(reviewAttendanceRoster: ReviewAttendanceRoster) {
    this.service.loading = true;
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage("ALSPC_RolesCatalog_AreYouSureYouWantToDeleteTheUser").description,
        icon: 'warn'
      }
    });
    confirm.afterClosed().subscribe(res => {
      if (res) {
        try {
          this.service.deleteReviewAttendanceRoster(reviewAttendanceRoster.id).toPromise();
          this.alert.message('ReviewMember_Deleted');
          if (this.review)
            this.store.dispatch(new ReviewsRefreshById(this.review.id));
          this.store.dispatch(new ReviewAttendanceCategoriesRefresh());
          this.getReviewCategories();
        } catch (error) {
          this.alert.defaultError();
        }
      }
      else this.service.loading = false;
    });
  }


  sortData(sort: Sort, dataSourceMembers: MatTableDataSource<ReviewAttendanceRoster>) {
    const dataToSort = this.utils.cloneDeep(dataSourceMembers.data);
    if (!sort.active || sort.direction === '') {
      dataSourceMembers.data = dataToSort;
      return;
    }
    dataSourceMembers.data = dataToSort.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'role': return this.utils.sort(a.role?.name, b.role?.name, isAsc);
        case 'member': return this.utils.sort(a.user?.name, b.user?.name, isAsc);
        case 'attendance': return this.utils.sort(a.attended, b.attended, isAsc);
        default: return 0;
      }
    });
  }

  saveRoster() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: this.getMessage('ReviewAttendanceSaveMessage').description
    };
    dialogConfig.width = '500px';
    const dialogRef = this.dialog.open(ReviewAttendanceSaveComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (typeof (data) == 'string') {
          const reviewAttendanceSave = {
            id: 0,
            name: data,
            reviewAttendanceSavedList: this.getReviewAttendanceSaveList()
          } as ReviewAttendanceSave;
          this.service.createReviewAttendanceSaved(reviewAttendanceSave).toPromise().then(res => {
            this.alert.message('ReviewAttendanceSavedMsg', [{ placeholder: '{ListName}', value: res?.name }]);
          });
        }
        else {
          const reviewAttendanceSave = data as ReviewAttendanceSave;
          const dialogCfg = new MatDialogConfig();
          dialogCfg.data = {
            message: 'Are you sure you want to replace ' + reviewAttendanceSave.name + ' with the current List of memebers?',
            icon: 'question',
            value: true
          }
          const dialogYesNo = this.dialog.open(YesNoDialogComponent, dialogCfg);
          dialogYesNo.afterClosed().toPromise().then(yes => {
            if (yes) {
              reviewAttendanceSave.reviewAttendanceSavedList = this.getReviewAttendanceSaveList();
              this.service.updateReviewAttendanceSaved(reviewAttendanceSave).toPromise().then(res => {
                this.alert.message('ReviewAttendanceSavedMsg', [{ placeholder: '{ListName}', value: res?.name }]);
              });
            }
          });
        }
      }
    });
  }

  getReviewAttendanceSaveList() {
    const reviewAttendanceSaveList: any[] = [];
    this.review?.reviewAttendanceRosters?.map(r => {
      const reviewAttendanceMemberToSave = {
        name: r.user?.name,
        description: r.user?.roleCodes,
        userID: r.userID,
        categoryID: r.categoryID,
        roleID: r.roleID
      } as ReviewAttendanceSaveList;
      reviewAttendanceSaveList.push(reviewAttendanceMemberToSave);
    });
    return reviewAttendanceSaveList;
  }

  compareSavedList() {
    this.similarReviewAttendanceSaved = [];
    this.getReviewAttendanceSaveList();
    this.reviewAttendanceSaved?.map(s => {
      let match = true;
      this.review?.reviewAttendanceRosters?.map(c => {
        const found = s.reviewAttendanceSavedList.find(x => x.categoryID == c.categoryID && x.roleID == c.roleID && x.userID == c.userID);
        if (!found) {
          match = false;
        }
      });
      if (match) {
        s.reviewAttendanceSavedList?.map(d => {
          const found = this.review?.reviewAttendanceRosters?.find(x => x.categoryID == d.categoryID && x.roleID == d.roleID && x.userID == d.userID);
          if (!found) {
            match = false;
          }
        });
        if (match) {
          this.similarReviewAttendanceSaved.push(s);
        }
      }
    });
    this.similarRoster = this.similarReviewAttendanceSaved[0];
  }

  editSavedRoster(list?: ReviewAttendanceSave) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      reviewAttendanceSave: list
    };
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '800px';
    this.dialog.open(ReviewAttendanceSavedEditComponent, dialogConfig);
  }

  deleteSavedRoster(list: ReviewAttendanceSave) {
    const message = this.getMessage('ReviewAttendanceSavedDeleteMsg');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: message?.description.replace('{ListName}', '<b>' + list.name + '</b>'),
      icon: 'question',
      changeButtonLabels: true,
      labelButtonCancel: 'No',
      labelButtonAccept: 'Yes'
    };
    const dialogRef = this.dialog.open(YesNoDialogComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then(async res => {
      if (res) {
        this.service.deleteReviewAttendanceSaved(list.id).toPromise().then(() => {
        });
      }
    });
  }

}
