import { Component, OnInit, Inject, ViewChild, ElementRef, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatChipInputEvent } from '@angular/material/chips';
import { EmailAddress, OLogEntry, OLogFormData, OlogDataBaseObject } from 'src/app/components/olog/olog';
import { OLogService } from 'src/app/components/olog/olog.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { DocumentType } from 'src/app/services/documents/documents';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-partial-olog',
  templateUrl: './partial-olog.component.html',
  styleUrls: ['./partial-olog.component.scss']
})
export class PartialOlogComponent extends BaseComponent implements OnInit {
  @ViewChild('emailsInput') emailsInputElem?: ElementRef<HTMLInputElement>;
  emailPattern = /^[ ]*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}[ ]*$/;
  addOnBlur = true;
  serialNo?: string;
  ologResData?: OLogEntry;
  formOlog!: FormGroup;
  // ToAddresses: string;
  emailAddresses: EmailAddress[] = [];
  categories?: string = '';
  level = 'Info';
  highlight = 'no highlight';
  host = document.location.host;

  tmpDescription?: string;
  tmpDetails?: string;
  formType?: DocumentType;

  documentTypes?: DocumentType[];
  documentTypesFiltered?: DocumentType[];
  documentTypes$!: Observable<DocumentType[]>;
  documentTypesSubs!: Subscription;

  disabled: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OlogDataBaseObject,
    public dialogRef: MatDialogRef<PartialOlogComponent>,
    private fb: FormBuilder,
    private ologService: OLogService,
    protected override injector: Injector
  ) {
    super(injector);

  }

  loadDocumentTypes() {
    this.documentTypes$ = this.store.select(state => state.DocumentType.data);
    this.documentTypesSubs = this.documentTypes$.subscribe(data => {
      if (data?.length) {
        this.documentTypes = data;

      }
    });
  }

  ngOnInit(): void {
    this.loadDocumentTypes();
    this.disabled = this.data.disabled;
    const body: OLogFormData = JSON.parse(this.data.xmlBody.replace('\n', ''));
    this.categories = body.categories?.join(', ');
    let ologFormData: OLogFormData;
    if (body) {
      this.tmpDescription = body.description;
      this.emailAddresses = [];
      if (this.data?.cc && this.data?.cc?.length) {
        for (const mail of this.data.cc) {
          if (!this.emailAddresses.some(e => e?.address === mail?.address && e.name == mail?.name)) {
            this.emailAddresses.push({
              address: mail.address,
              isCustom: mail.isCustom,
              name: mail.name
            });
          }
        }
      }
      if (body.cc && body.cc.length) {
        for (const mail of body?.cc) {
          if (!this.emailAddresses.some(e => e?.address === mail?.address && e.name == mail?.name)) {
            this.emailAddresses.push({
              address: mail.address,
              isCustom: mail.isCustom,
              name: mail.name
            });
          }
        }
      }
      if (!body.extraSubject) {
        body.extraSubject = '';
      }
      ologFormData = {
        cc: [],
        subject: body.subject,
        details: body.details,
        description: body.description,
        extraSubject: body.extraSubject
      };
      this.serialNo = this.data.serialNo;
      this.tmpDetails = body.details ?? '';
    } else {
      ologFormData = {
        cc: [],
        subject: '',
        details: '',
        extraSubject: ''
      };
    }
    this.formOlog = this.fb.group(
      ologFormData
    );
    this.formType = this.documentTypes?.find(d => d.id == +this.data.formTypeID);
    this.formOlog.controls['cc'].setValidators([Validators.pattern(this.emailPattern)]);
  }

  async processData() {
    const body: OLogFormData = JSON.parse(this.data.xmlBody);

    // Extract unique custom email addresses
    const cc = [...new Set(this.emailAddresses.filter(mail => mail.isCustom))];

    // Construct OLogFormData object
    const ologFormData: OLogFormData = {
      cc,
      level: body.level,
      subject: this.formOlog.controls['subject'].value?.replace('\n', ' '),
      details: this.tmpDetails,
      categories: body.categories,
      highlight: body.highlight,
      extraSubject: this.formOlog.controls['extraSubject'].value?.replace('\n', ' ')
    };

    // Update data object
    this.data.xmlBody = JSON.stringify(ologFormData);
    this.data.details = ologFormData.details ?? '';

    let status = false;

    try {
      // Send data to the service
      const res = await this.ologService.Put(this.data, this.data.id ?? 0).toPromise();

      if (res) {
        this.alert.success(
          environment.messages.OLogEntryCreated.replace('{serialNo}', this.serialNo ?? '')
        );
        status = true;
      } else {
        this.alert.warning(environment.messages.OlogEntryError);
      }
    } catch (error) {
      this.alert.warning(environment.messages.OlogEntryError);
    } finally {
      // Close dialog with the status
      this.dialogRef.close(status);
    }
  }


  add(event: MatChipInputEvent): void {
    if (this.formOlog.controls['cc'].valid && this.formOlog.controls['cc'].value && this.formOlog.controls['cc'].value !== '') {
      const ccvalue = this.formOlog.controls['cc'].value.toLowerCase().trim();
      const test = this.emailAddresses.find(m => m.address === ccvalue);
      if (!this.emailAddresses.some(m => m.address === ccvalue)) {
        this.emailAddresses.push({
          name: "",
          address: ccvalue,
          isCustom: true
        });
        this.formOlog.controls['cc'].markAsPristine();
      }
      this.formOlog.controls['cc'].setValue('');
    } else {
      this.formOlog.controls['cc'].markAsDirty();
    }
  }

  keyUpCc() {
    this.formOlog.markAsDirty();
    this.formOlog.controls['cc'].markAsDirty();
    this.formOlog.controls['cc'].markAsTouched();
  }

  remove(mail: EmailAddress): void {
    const index = this.emailAddresses.indexOf(mail);
    if (index >= 0) {
      this.emailAddresses.splice(index, 1);
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  override getErrorMsg(control: AbstractControl): string | null {
    if (!control.valid) {
      return 'You must enter a valid value';
    } else if (this.data.hasError) {
      return this.data.error;
    }
    return null;
  }
}
