import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { UserService } from 'src/app/components/catalogs/user-catalog/services/user.service';


@Component({
  selector: 'user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss']
})
export class UserSelectComponent implements OnInit {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() control!: FormControl;
  @Input() get userID() {
    return this.userIDValue;
  }

  set userID(val) {
    this.userIDValue = val;
    this.userIDChange.emit(this.userIDValue);
  }

  @Output() userIDChange = new EventEmitter<number>();
  @Output() error = new EventEmitter<string>();

  userIDValue!: number;
  users!: User[];

  constructor(
    public userService: UserService
  ) { }

  ngOnInit(): void {
    if (this.control == null) {
      this.control = new FormControl();
    }
    this.userService.ReadActive().subscribe(
      (data: User[]) => {
        this.users = data;
      },
      error => {
        this.error.emit(error.error);
      }
    );
  }

}
