<div class="row mt-4">
  <div class="col-4">
    Roles to <b class="blue">Create</b>
    <b> {{ documentType.description }}</b>
  </div>
  <div class="col-8">
    <role-selection
      [roles]="addRoles"
      [noSubscript]="true"
      (selected)="selectedAddRole($event)"
      (deleted)="removeAddRole($event)"
    ></role-selection>
  </div>
</div>
<div class="row">
  <div class="col-4">
    Roles to <b class="blue">Approve</b> <b> {{ documentType.description }}</b
    ><br />
    <mat-slide-toggle
      color="warn"
      class="small"
      [(ngModel)]="sameToggle"
      (change)="sameToggleChanged($event)"
      >Allow Creator to Approve</mat-slide-toggle
    >
  </div>
  <div class="col-8">
    <role-selection
      [roles]="apprRoles"
      [noSubscript]="true"
      (selected)="selectedApprRole($event)"
      (deleted)="removeApprRole($event)"
    ></role-selection>
  </div>
</div>

<div class="row mt-4">
  <div class="col-4">
    Roles to <b class="blue">Create & Execute</b>
    <b>
      {{ templateType ? templateType.name : null }}
      {{ documentTypeExec?.name }}&nbsp; Checklist</b
    >
  </div>
  <div class="col-8">
    <role-selection
      [roles]="chklRoles"
      [noSubscript]="true"
      (selected)="selectedChklRole($event)"
      (deleted)="removeChklRole($event)"
    ></role-selection>
  </div>
</div>
<div class="saving" *ngIf="saving">
  <saving-circle></saving-circle>
</div>
