import { Component, Injector, OnInit, OnChanges } from '@angular/core';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { PlaceholdersService } from 'src/app/services/placeholders/placeholders.service';

@Component({
  selector: 'cl-radio-placeholder-cond',
  templateUrl: './cl-radio-placeholder-cond.component.html',
  styleUrls: ['./cl-radio-placeholder-cond.component.scss']
})
export class ClRadioPlaceholderCondComponent extends ClBaseComponent implements OnInit {

  constructor(
    protected override injector: Injector,
    private placeholderService: PlaceholdersService
  ) {
    super(injector);
  }

  ngOnInit() {
    if (!this.editor && !this.builder && this.checklist?.checklistStatus?.formStatusID != FormStatusEnum.Canceled) {
      this.placeholderService.keyValuePairsObservable.subscribe(data => {
        if (this.checked && this.disabled) this.setValues();
        else this.placeHolderSelection();
      });
    }
  }

  setValues() {
    const configuration = this.utils.JSONparse(this.configuration);
    this.radioOptions?.map(o => o.checked = o.id == this.radioSelection);
  }

  placeHolderSelection() {
    const configuration = this.utils.JSONparse(this.configuration);
    const placeholder = configuration?.placeholder;
    const value = this.placeholderService.getValue(placeholder);
    if (value != 'No value Set!') {
      if (!isNaN(value)) {
        this.radioOptions?.map(o => {
          if (o.operator) {
            const result = this.utils.compareNumbers(value, o.value, o.operator);
            if (result && !o.checked) {
              if (o.id != this.radioSelection) {
                this.radioSelected.emit(o.id);
              }
            }
            o.checked = result;
          }
        });
        if (!this.checked) {
          this.checked = !this.unselected();
          this.answered.emit(this.checked);
        }
      }
      else {
        if (this.checked) {
          this.checked = false;
          if (this.getCurrentUser())
            this.answered.emit(this.checked);
        }
      }
    }
  }

  checkboxChanged(e: any) {
    this.checked = e.checked;
    this.answered.emit(this.checked);
  }

  radioChange(e: any) {
    this.radioSelected.emit(e.id);
    this.radioSelection = e.id;
    this.checked = true;
    this.radioOptions?.map(x => x.checked = x.id == this.radioSelection);
  }

  unselected() {
    return !this.radioOptions?.map(r => r.checked).includes(true);
  }

  setRadio(value: any) {
    this.radioOptions?.map(r => {
      r.checked = r.value == value;
    });
  }

}
