import { Component, ElementRef, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ProcedureCategory } from '../../models/procedure-category.model';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { MessageBanner } from 'src/app/components/messages/services/message-banner';
import { messages } from 'src/environments/messages';
import { ProcedureTypeEnum } from '../../enums/procedure-type.enum';
import { ProcedureCategoryService } from '../../services/procedure-category.service';

class ProcedureCategoryForm extends ProcedureCategory {
  public editing?: boolean;
  public preName?: string;

  constructor() {
    super();
    this.preName = this.name;
  }
}

@Component({
  selector: 'procedure-category-popup',
  templateUrl: './procedure-category-popup.component.html',
  styleUrls: ['./procedure-category-popup.component.scss']
})
export class ProcedureCategoryPopupComponent extends BaseComponent implements OnInit {
  procedureCategories$!: Observable<ProcedureCategory[]>;
  procedureCategories: ProcedureCategoryForm[] = [];
  dataSource = new MatTableDataSource<ProcedureCategoryForm>();
  columns: string[] = ['name', 'type', 'clear', 'edit_save', 'delete'];

  // messages
  message_you_must_enter_a_category_name!: MessageBanner;
  message_generic_error!: MessageBanner;

  label_are_you_sure_you_want_to_create_the_category!: string;
  label_are_you_sure_you_want_to_modify_the_category!: string;
  label_are_you_sure_you_want_to_delete_the_category!: string;
  label_procedure_category!: string;
  label_name!: string;
  // messages

  @ViewChild('NameInput')
  nameInput!: ElementRef;
  @ViewChild('procedureTypeSelect')
  procedureTypeSelect!: ElementRef<HTMLSelectElement>;

  constructor(
    protected override injector: Injector,
    private procedureCategoryService: ProcedureCategoryService,
    private dialogRef: MatDialogRef<ProcedureCategoryPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    super(injector);

  }

  ngOnInit(): void {
    this.procedureCategories$ = this.store.select(state => state.ProcedureCategory.ProcedureCategories);
    this.procedureCategories$.subscribe((data) => {
      this.procedureCategories = data.sort((x1, x2) => this.utils.sort(x1.name, x2.name));
      this.procedureCategories.map(x => {
        x.editing = false;
        x.preName = x.name;
      });
      this.dataSource.data = this.procedureCategories;
    });
  }

  setLabels() {
    this.label_are_you_sure_you_want_to_create_the_category = this.getMessage('ALSPC_ProcedureCategory_AreYouSureYouWantToCreateTheCategory').description;
    this.label_are_you_sure_you_want_to_modify_the_category = this.getMessage('ALSPC_ProcedureCategory_AreYouSureYouWantToModifyTheCategory').description;
    this.label_are_you_sure_you_want_to_delete_the_category = this.getMessage('ALSPC_ProcedureCategory_AreYouSureYouWantToDeleteTheCategory').description;
    this.label_procedure_category = this.getMessage('ALSPC_ProcedureCategory_ProcedureCategory').description;
    this.label_name = this.getMessage('ALSPC_ProcedureCategory_Name').description;
  }

  validate(name?: string): boolean {
    if (!name) {
      this.alert.message('ALSPC_ProcedureCategory_YouMustEnterACategoryName');
      return false;
    }
    return true;
  }

  create(name: string, procedureType: ProcedureTypeEnum) {
    if (!this.validate(name)) {
      return;
    }

    this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.label_are_you_sure_you_want_to_create_the_category
      }
    }).afterClosed().subscribe(
      data => {
        if (data != null) {
          this.procedureCategoryService.create({ name, procedureTypeID: procedureType }).subscribe(
            () => {
              this.nameInput.nativeElement.value = '';
            },
            error => {
              if (error.status === 404 || error.status === 409) {
                this.alert.error(error.error);
              } else {
                this.alert.defaultError();
              }
            },
          );
        }
      }
    );
  }

  update(procedureCategory: ProcedureCategoryForm) {
    if (!this.validate(procedureCategory.name)) {
      return;
    }

    procedureCategory.name = procedureCategory.preName;
    this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.label_are_you_sure_you_want_to_modify_the_category
      }
    }).afterClosed().subscribe(
      data => {
        if (data && procedureCategory.id) {
          this.procedureCategoryService.update(procedureCategory.id, procedureCategory).subscribe(
            () => {
              procedureCategory.editing = false;
            },
            error => {
              if (error.status === 404 || error.status === 409) {
                this.alert.error(error.error);
              } else {
                this.alert.defaultError();
              }
            },
          );
        }
      }
    );
  }

  delete(id: number) {
    this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.label_are_you_sure_you_want_to_delete_the_category
      }
    }).afterClosed().subscribe(
      data => {
        if (data != null) {
          this.procedureCategoryService.delete(id).subscribe(
            () => { },
            error => {
              if (error.status === 404 || error.status === 409) {
                this.alert.error(error.error);
              } else {
                this.alert.defaultError();
              }
            },
          );
        }
      }
    );
  }

  startEditing(procedureCategory: ProcedureCategoryForm) {
    this.procedureCategories.map(x => {
      x.editing = false;
    });
    procedureCategory.editing = true;
  }

  discard(procedureCategory: ProcedureCategoryForm) {
    procedureCategory.preName = procedureCategory.name;
    procedureCategory.editing = false;
  }

  getProcedureTypeName(procedureCategory: ProcedureCategory) {
    switch (procedureCategory.procedureTypeID) {
      case ProcedureTypeEnum.ProcedureCenter: return 'Procedure';
      case ProcedureTypeEnum.RSSTest: return 'RSS Test';
      case ProcedureTypeEnum.EPSTest: return 'EPS Test';
      case ProcedureTypeEnum.KEChecklist: return 'KE Checklist';
      case ProcedureTypeEnum.EPSDrawings: return 'EPS Drawings';
      case ProcedureTypeEnum.RadSurvey: return 'Rad Survey';
      default: return '';
    }
  }
}
