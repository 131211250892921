import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { CommentsAndAttachmentsComponent } from 'src/app/controls/comments-and-attachments/comments-and-attachments.component';
import { ReviewActionItem, Review } from '../../reviews.models';

@Component({
  selector: 'reviews-action-items-comments',
  templateUrl: './reviews-action-items-comments.component.html',
  styleUrls: ['./reviews-action-items-comments.component.scss']
})
export class ReviewsActionItemsCommentsComponent implements OnInit, AfterViewInit {

  @Input() reviewActionItem!: ReviewActionItem;
  @Input() disabled!: boolean;

  @ViewChild(CommentsAndAttachmentsComponent) commentsAndAttachments!: CommentsAndAttachmentsComponent;
  review!: Review;
  serialNo!: string;
  constructor() { }

  ngAfterViewInit(): void {
    this.loadComments();
  }

  ngOnInit(): void {
    this.review = this.reviewActionItem.review as Review;
    this.serialNo = this.review.reviewCycle.serialNo + '-' + this.review.code + '-' + this.review.reviewType.code + '-' + this.review.id + '-AI-' + this.reviewActionItem.id;
  }

  loadComments() {
    this.commentsAndAttachments?.loadComments();
  }

}
