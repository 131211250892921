import { ReviewType } from '../../reviews.models';

export class ReviewTypesRefresh {
  static readonly type = '[ReviewTypes] Refresh';
}

export class ReviewTypesCreate {
  static readonly type = '[ReviewTypes] Create';

  constructor(public item: ReviewType) { }
}

export class ReviewTypesUpdate {
  static readonly type = '[ReviewTypes] Update';

  constructor(public id: number, public item: ReviewType) { }
}

export class ReviewTypesDelete {
  static readonly type = '[ReviewTypes] Delete';
  constructor(public id: number) { }
}
