<div class="card p-0">
  <div class="card-header">
    <div class="card-title">Accelerator Status</div>
  </div>
  <mat-progress-bar
    color="primary"
    mode="indeterminate"
    *ngIf="loading"
  ></mat-progress-bar>
  <div class="card-body px-2 py-0">
    <div class="row px-2 justify-content-center">
      <div class="item-col col p-1" *ngFor="let element of acceleratorStatus">
        <div class="item d-flex align-items-center">
          <span class="title">{{ element.name }}</span>
          <div
            [ngClass]="getClass(element.status)"
            style="cursor: default"
            [matTooltip]="element.moreInfo"
          >
            {{ element.status }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
