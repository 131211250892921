import { WfTaskLocalResource } from "src/app/services/work-flow/work-flow";


export class BuilderTasksRefresh {
  static readonly type = '[BuilderTasks] Refresh';

  constructor(public wfStepLocalID: number) { }
}

export class BuilderTasksCreate {
  static readonly type = '[BuilderTasks] Create';

  constructor(public item: WfTaskLocalResource) { }
}

export class BuilderTasksUpdate {
  static readonly type = '[BuilderTasks] Update';

  constructor(public id: number, public item: WfTaskLocalResource) { }
}

export class BuilderTasksUpdateAll {
  static readonly type = '[BuilderTasks] UpdateAll';

  constructor(public items: WfTaskLocalResource[]) { }
}

export class BuilderTasksDelete {
  static readonly type = '[BuilderTasks] Delete';
  constructor(public id: number) { }
}
