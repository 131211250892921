import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'disapproval-reason',
  templateUrl: './disapproval-reason.component.html',
  styleUrls: ['./disapproval-reason.component.scss']
})
export class DisapprovalReasonComponent extends BaseComponent implements OnInit {

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<DisapprovalReasonComponent>,
    @Inject(MAT_DIALOG_DATA) public disapprovalReason: string,
    private _alert: AlertService
  ) {
    super(injector);
  }

  private errorMsg!: string[];
  private valid!: boolean;

  ngOnInit(): void {
    this.errorMsg = [];
  }

  add() {
    if (this.isValid()) {
      this.dialogRef.close(this.disapprovalReason);
    } else {
      this._alert.error(this.errorMsg.join(", "));
      var that = this;
      setTimeout(function () { that._alert.clear(); }, 3000);
    }
    this.errorMsg = [];
  }

  cancel() {
    this.dialogRef.close();
  }

  isValid() {
    this.valid = true;
    if (isNullOrWhiteSpace(this.disapprovalReason) == true) {
      this.errorMsg.push("Field <b>Disapproval Reason</b> should not be blank!");
      this.valid = false;
    }
    return this.valid;
  }

}

function isNullOrWhiteSpace(input: string) {
  return !input || !input.trim();
}
