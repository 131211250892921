import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ScheduleRemindersCreate, ScheduleRemindersDelete, ScheduleRemindersRefresh, ScheduleRemindersUpdate } from './schedule-reminder.action';
import { ScheduleReminder } from '../../models/schedule-reminder';
import { ScheduleReminderService } from '../../services/schedule-reminder.service';

export class ScheduleRemindersStateModel {
  data: ScheduleReminder[] = [];
}

@State<ScheduleRemindersStateModel>({
  name: 'ScheduleReminders',
  defaults: {
    data: [],
  }
})
@Injectable()
export class ScheduleRemindersState {
  constructor(
    private _service: ScheduleReminderService
  ) { }

  @Selector()
  static read(state: ScheduleRemindersStateModel) {
    return state.data;
  }

  @Action(ScheduleRemindersRefresh)
  strefresh({ patchState }: StateContext<ScheduleRemindersStateModel>) {
    this._service.getAll().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ScheduleRemindersCreate)
  stcreate({ getState, patchState }: StateContext<ScheduleRemindersStateModel>, { item }: ScheduleRemindersCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ScheduleRemindersUpdate)
  stupdate({ getState, patchState }: StateContext<ScheduleRemindersStateModel>, { id, item }: ScheduleRemindersUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ScheduleRemindersDelete)
  stdelete({ getState, patchState }: StateContext<ScheduleRemindersStateModel>, { id }: ScheduleRemindersDelete) {
    patchState({
      data: getState().data.filter(x => x.id != id)
    });
  }

}
