import { ReviewStatusRefresh } from '../../../../store/review-status/review-status.action';
import { Component, Input, OnInit, Output, EventEmitter, Injector } from '@angular/core';
import { ReviewsService } from '../../../../reviews.service';
import { ReviewBoardsRefresh } from '../../../../store/review-boards/review-boards.action';
import { ReviewBoardTypesRefresh } from '../../../../store/review-board-types/review-board-types.action';
import { ReviewsRefresh } from '../../../../store/reviews/reviews.action';
import { MatDialog } from '@angular/material/dialog';
import { ReviewsSettingsStatusesActionsDetailsComponent } from './statuses-actions-details/statuses-actions-details.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ReviewStatus, ReviewStatusAction } from 'src/app/components/reviews/reviews.models';


@Component({
  selector: 'reviews-settings-statuses-actions',
  templateUrl: './statuses-actions.component.html',
  styleUrls: ['./statuses-actions.component.scss']
})
export class ReviewsSettingsStatusesActionsComponent extends BaseComponent implements OnInit {

  @Input() reviewStatus?: ReviewStatus;
  @Input() reviewStatuses!: ReviewStatus[];
  @Output() changed = new EventEmitter<any>();

  reviewStatusActions!: ReviewStatusAction[];

  constructor(
    protected override injector: Injector,
    private service: ReviewsService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.reviewStatusActions = this.reviewStatus?.reviewStatusActions ?? [];
  }

  getClass(color: string | null) {
    return color + '-background';
  }

  delete(reviewStatusAction: ReviewStatusAction) {
    this.service.deleteReviewStatusAction(reviewStatusAction.id).toPromise().then(() => {
      const index = this.reviewStatusActions.findIndex(x => x.id == reviewStatusAction.id);
      this.reviewStatusActions.splice(index, 1);
      this.changed.emit(this.reviewStatusActions);
      this.refreshStore();
    });
  }

  add() {
    const reviewStatusAction = {
      id: 0,
      name: null,
      description: null,
      color: null,
      reviewStatusID: this.reviewStatus?.id,
      nextReviewStatusID: 0,
      code: null,
      status: 1,
      type: 1
    } as ReviewStatusAction;
    const confirm =
      this.dialog.open(ReviewsSettingsStatusesActionsDetailsComponent, {
        width: '500px',
        data: {
          title: 'Add Status Action',
          reviewStatusAction,
          reviewStatuses: this.reviewStatuses,
          disableClose: true,
          autoFocus: false
        }
      });
    confirm.afterClosed().toPromise().then((res: ReviewStatusAction) => {
      if (res) {
        this.save(res);
      }
    });
  }

  edit(reviewStatusAction: ReviewStatusAction) {
    const confirm =
      this.dialog.open(ReviewsSettingsStatusesActionsDetailsComponent, {
        width: '500px',
        data: {
          title: 'Modify Status Action',
          reviewStatusAction,
          reviewStatuses: this.reviewStatuses,
          disableClose: true,
          autoFocus: false
        }
      });
    confirm.afterClosed().toPromise().then((res: ReviewStatusAction) => {
      if (res) {
        this.save(res);
      }
    });
  }

  refreshStore() {
    this.store.dispatch(new ReviewsRefresh());
    this.store.dispatch(new ReviewStatusRefresh());
    this.store.dispatch(new ReviewBoardsRefresh());
    this.store.dispatch(new ReviewBoardTypesRefresh());
  }

  save(reviewStatusAction: ReviewStatusAction) {
    if (!this.reviewStatusActions) {
      this.reviewStatusActions = [];
    }
    const index = this.reviewStatusActions.findIndex(x => x.id == reviewStatusAction.id || x.name == reviewStatusAction.name);
    if (reviewStatusAction.id || index >= 0) {
      this.reviewStatusActions[index] = reviewStatusAction;
    }
    else {
      if (!this.reviewStatusActions) { this.reviewStatusActions = []; }
      this.reviewStatusActions.push(reviewStatusAction);
    }
    this.changed.emit(this.reviewStatusActions);
  }

}
