import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Schedule } from '../../models/schedule';

@Component({
  selector: 'app-edit-scheduled-for',
  templateUrl: './edit-scheduled-for.component.html',
  styleUrls: []
})

export class EditScheduledForComponent implements OnInit {

  public formScheduledFor!: FormGroup;
  public minDate: Date;

  constructor(
    public dialogRef: MatDialogRef<EditScheduledForComponent>,
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Schedule
  ) {
    this.minDate = new Date();
  }

  ngOnInit(): void {
    this.formScheduledFor = this.formBuilder.group({
      scheduledFor: [Validators.required]
    });

    if (this.data.scheduleFor) {
      this.formScheduledFor.controls['scheduledFor'].setValue(this.data.scheduleFor);
    }
  }

  onSubmit() {
    this.data.scheduleFor = this.formScheduledFor.controls['scheduledFor'].value;
    this.dialogRef.close(this.data);
  }

  onCancel() {
    this.dialogRef.close();
  }

  header(data: string): string {
    return data.replace(/\<br>/gi, ', ');
  }
}
