import { APLCategoryRoleCatalog } from "./apl-category-role-catalog.model";


export class APLCategoryMasterRoleCatalog {
  public id!: number;
  public aplCategory?: APLCategoryRoleCatalog;

  constructor() {
    this.aplCategory = this.aplCategory ?? new APLCategoryRoleCatalog();
  }
}
