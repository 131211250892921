import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { ResourceType } from 'src/app/common/enumerations/enumerations';
import { utils } from 'src/app/modules/libs/utils';
import { Resource, ResourceRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';
import { RoleOption } from '../cl-editor/cl-editor-checkbox-roles/cl-editor-checkbox-roles.component';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';

@Component({
  selector: 'cl-shieldings',
  templateUrl: './cl-shieldings.component.html',
  styleUrls: ['./cl-shieldings.component.scss']
})
export class ClShieldingsComponent extends ClBaseComponent implements OnInit, OnChanges, OnDestroy {

  @Output() checkShared = new EventEmitter<any>();

  shieldings?: Shielding[];
  shieldingsToDisplay?: Shielding[];
  shielding0?: Shielding;

  roles: Role[] = [];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  resource?: Resource = new Resource;
  resources: Resource[] = [];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;
  resourceMap = new Map<number | undefined, Resource>();

  resourceRelations: ResourceRelation[] = [];
  resourceRelationsFiltered: ResourceRelation[] = [];
  resourceRelations$!: Observable<ResourceRelation[]>;
  resourceRelationsSubs: Subscription = new Subscription;
  resourceRelationsMap = new Map<number | undefined, ResourceRelation>();

  lastCheckedTime: number | null = null;
  saveDelay = 2000; // 2 seconds
  defaultSaveDelay = 2000;
  saveTimer: ReturnType<typeof setTimeout> | null = null;

  saving!: boolean;

  override ngOnDestroy(): void {
    this.rolesSubs?.unsubscribe();
    this.resourcesSubs?.unsubscribe();
    this.resourceRelationsSubs?.unsubscribe();
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadRoles();
    this.loadResources();
    if (this.builder || this.editor) {
      if ((changes['configuration'] || changes['options'])) {
        this.loadShieldingsFromConfiguration();
        this.createShielding0();
      }
    }
    else {
      if (changes['valueString']) {
        if (this.valueString != null)
          this.loadShieldingsFromValueString();
        else {
          this.loadShieldingsFromConfiguration();
          this.createShielding0();
        }
      }
    }
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      this.roles = data;
    });
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data.length) {
        this.resources = data;
        this.resources.map(resource => this.resourceMap.set(resource.id, resource));
        this.loadResourceRelations();
      }
    });
  }

  loadResourceRelations() {
    this.resourceRelations$ = this.store.select(state => state.ResourceRelations.data);
    this.resourceRelationsSubs = this.resourceRelations$.subscribe(data => {
      if (data.length) {
        this.resourceRelations = data;
        this.resourceRelations.map(resourceRelation => this.resourceRelationsMap.set(resourceRelation.id, resourceRelation));
      }
    });
  }

  loadValues() {
    if (this.resources?.length && this.resourceRelations?.length)
      if (this.valueString)
        this.loadShieldingsFromValueString();
      else
        this.loadShieldingsFromConfiguration();
  }

  loadShieldingsFromValueString() {
    if (!this.shieldings) {
      const allShieldings = utils.JSONparse(this.valueString) as Shielding[];
      this.shielding0 = allShieldings.find(s => s.id === 0);
      if (!this.shielding0) { this.createShielding0(); }
      const users = this.getUsers();
      if (allShieldings.length) {
        allShieldings.map(s => {
          const resource = this.resourceMap.get(this.resourceRelationsMap.get(s.id)?.childResourceID);
          if (resource) s.resource = resource;
          s.isShared = (s.resource.parentResources?.length ?? 0) > 1;
          s.roleOptions?.map(o => {
            o.user = o.user ? o.user : users?.find(u => u.id === o.userID);
            o.disabled = this.checkboxDisabled(o, s);
          });
          s.waiting = false;
        });
        const shieldings = allShieldings.filter(s => s.id != 0);
        this.shieldings = shieldings;
        this.shielding0?.roleOptions?.map(o => o.disabled = this.checkboxDisabled(o, this.shielding0));
        this.shieldingsToDisplay = this.utils.cloneDeep(this.shieldings);
      }
      else { this.loadShieldingsFromConfiguration(); }
    }
  }

  loadShieldingsFromConfiguration() {
    const configuration: ShieldingConfiguration = utils.JSONparse(this.configuration);
    const resourceID = configuration?.location;
    if (resourceID) {
      this.resource = this.resources?.find(r => r.id == resourceID);
      const parent = this.resource?.parentResources?.find(x => x.parentResourceType == ResourceType.Accelerator);
      const parentResource = this.resources?.find(x => x.id == parent?.parentResourceID);
      this.shieldings = parentResource?.childResources?.filter(s => s.childResourceType == ResourceType.Shielding && s.childResource?.checkboxVisible && utils.JSONparse(s.locationsIDs).includes(this.resource?.id))
        .filter(x => !configuration.longShutdown ? !x.childResource?.longShutdown : true)
        .filter(x => !configuration.inTunnel ? !x.childResource?.inTunnel : x.childResource?.inTunnel)
        .sort((a, b) => this.utils.sort(a.position, b.position))
        .map(r => {
          return { id: r.id, resourceID: r.childResource?.id, resource: r.childResource, roleOptions: this.getRoleOptions() } as Shielding;
        });
      this.shieldings?.map(s => {
        s.resource = this.resourceMap.get(this.resourceRelationsMap.get(s.id)?.childResourceID) as Resource;
        s.isShared = (this.resource?.parentResources?.length ?? 0) > 1;
        s.roleOptions?.map(o => {
          o.disabled = this.checkboxDisabled(o, s);
        });
      });
      this.shieldingsToDisplay = this.utils.cloneDeep(this.shieldings);
    }
  }

  createShielding0() {
    this.shielding0 = { id: 0, resourceID: 0, resource: { id: 0, type: 0 }, roleOptions: this.getRoleOptions() };
    this.shielding0?.roleOptions?.map(o => {
      o.disabled = this.checkboxDisabled(o, this.shielding0);
    });
  }

  getRoleOptions() {
    const options = utils.cloneDeep(this.options) as RoleOption[];
    options?.map(o => {
      if (!o.roleCodes) {
        o.roleCodes = this.roles.filter(x => o.roleIDs?.includes(x.id))?.map(r => r.code).join('/');
      }
    });
    return options;
  }

  checkboxChanged(e: any, id?: number, sid: number | null = null) {
    this.saveDelay = this.defaultSaveDelay;
    const currentUser = this.getCurrentUser(true) as User;
    currentUser.userRole = [];
    if (sid) {
      const shielding = this.shieldings?.find(s => s.resource?.id == sid);
      const shieldingToDisplay = this.shieldingsToDisplay?.find(s => s.id == shielding?.id);
      if (shieldingToDisplay)
        shieldingToDisplay.waiting = true;
      const option = shielding?.roleOptions?.find(x => x.id == id);
      if (option) {
        option.checked = e.checked;
        option.userID = e.checked ? currentUser?.id : null;
        option.user = e.checked ? this.getCurrentUserInfo() : null;
        option.date = e.checked ? new Date() : null;
        shielding?.roleOptions?.filter(o => o.id != option.id).map(o => o.disabled = true);

        const option0 = this.shielding0?.roleOptions?.find(r => r.id === id);

        if (e.checked) {
          // if (option0) {
          //   option0.userID = currentUser.id;
          // }
        } else {
          const isAnyOtherChecked = this.shieldings
            ?.filter(s => s.id !== 0)
            .some(s => s.roleOptions?.some(r => r.id === id && r.checked));

          if (!isAnyOtherChecked && option0) {
            option0.userID = null;
          }
        }
        this.saveData();
      }
    }
    else {
      this.checkAll(id, e.checked);
    }
  }

  checkAll(id?: number, checked?: boolean) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage(checked ? 'ChecklistShieldingCheckAllConfirmation' : 'ChecklistShieldingUncheckAllConfirmation').description,
        icon: 'stop'
      }
    });
    confirm.afterClosed().subscribe(data => {
      const option0 = this.shielding0?.roleOptions?.find(o => o.id == id);
      if (data && option0) {
        const currentUser = (checked ? this.getCurrentUserInfo() : null) as User;
        const date = checked ? new Date : null;
        Object.assign(option0, { checked, user: currentUser, userID: currentUser?.id, date });
        this.shieldings?.map(shielding => {
          const shieldingToDisplay = this.shieldingsToDisplay?.find(s => s.id == shielding?.id);
          if (shieldingToDisplay)
            shieldingToDisplay.waiting = true;
          const option = shielding.roleOptions?.find(o => o.id == id);
          if (option)
            Object.assign(option, { checked, user: currentUser, userID: currentUser?.id, date });
        });

        this.saveData();
      }
      else {
        this.loadShieldingsFromValueString();
      }
    });
  }

  // changeShared(shieldings: Shielding[]) {
  //   this.shieldings?.map(s => {
  //     const resource = this.resources.find(r => r.id == s.resourceID);
  //     if (resource)
  //       s.resource = resource;
  //   });
  //   shieldings?.map(s => {
  //     const resource = this.resources.find(r => r.id == s.resourceID);
  //     if (resource)
  //       s.resource = resource;
  //   });
  //   if (this.shieldings?.[0]?.resource?.parentResources?.[0]?.id != shieldings?.[0]?.resource?.parentResources?.[0]?.id) {
  //     const matchedShieldings = utils.intersect(shieldings.map(s => s.resourceID), this.shieldings?.map(s => s.resourceID));
  //     if (matchedShieldings) {
  //       this.shieldings?.map(localShielding => {
  //         const incomingShielding = shieldings.find(s => s.resourceID == localShielding.resourceID);
  //         if (incomingShielding) {
  //           incomingShielding.roleOptions?.map(o => {
  //             const option = localShielding.roleOptions?.find(x => x.id == o.id);
  //             if (option) {
  //               option.checked = o.checked;
  //               option.user = o.user;
  //               option.userID = o.userID;
  //               option.date = o.date;
  //             }
  //           });
  //         }
  //       });
  //       this.saveDelay = 10;
  //       this.saveData();
  //     }
  //   }
  // }

  checkboxDisabled(o: RoleOption, s?: Shielding) {
    const currentUser = this.getCurrentUser(true) as User;
    if (currentUser) {
      if ((s?.id ?? -1) >= 0) {
        const userSignedOption = s?.roleOptions?.find(o => o.userID == currentUser?.id);
        if (o.id == userSignedOption?.id) {
          return false;
        }
        if (userSignedOption) {
          return true;
        }
        if (!utils.intersect(o.roleIDs, currentUser.userRole?.map(r => r.roleID))) {
          return true;
        }
        if (this.shielding0?.roleOptions?.find(r => r.userID == currentUser.id)) {
          const id = this.shielding0?.roleOptions?.find(r => r.userID == currentUser.id)?.id;
          return o.id != id;
        }
        if (s?.id == 0) {
          const otherOptionsChecked = this.shieldings?.some(sh =>
            sh.roleOptions?.some(ro => ro.checked && ro.id != o.id && ro.userID == currentUser.id)
          );
          if (otherOptionsChecked) { return true; }
        }
        // if (this.shielding0?.roleOptions?.filter(r => r.userID && r.userID != currentUser.id)) {
        //   const ids = this.shielding0?.roleOptions?.filter(r => r.userID && r.userID != currentUser.id).map(r => r.id);
        //   return ids.includes(o.id);
        // }
        return (o.checked && o.userID) ? o.userID != currentUser.id : false;
      }
      else {
        if (o.userID == currentUser.id) {
          return false;
        }
        const otherOptionsChecked = this.shieldings?.some(sh =>
          sh.roleOptions?.some(ro => ro.checked && ro.id != o.id && ro.userID == currentUser.id)
        );
        if (otherOptionsChecked) { return true; }
        const roleAllowed = utils.intersect(o.roleIDs, currentUser.userRole?.map(r => r.roleID));
        if (roleAllowed && !o.checked) { return false; }
        return true;
      }
    }
    else { return true; }
  }

  saveData() {
    this.lastCheckedTime = Date.now();
    if (this.saveTimer) {
      clearTimeout(this.saveTimer);
    }
    if (this.lastCheckedTime) {
      this.saveTimer = setTimeout(() => {
        if (this.shieldings) {
          const shieldings = [this.shielding0, ...this.shieldings];
          shieldings.map(s => {
            if (s)
              s.resource = { id: 0, type: 0 };
          });
          this.save.emit({ text: JSON.stringify(shieldings), hasRefresh: false });
          this.saveTimer = null;
        }
      }, this.saveDelay);
    }
  }
}

export interface Shielding {
  id: number;
  resource: Resource;
  resourceID: number;
  roleOptions: RoleOption[];
  isShared?: boolean;
  waiting?: boolean;
}

export class ShieldingConfiguration {
  location?: number;
  longShutdown?: boolean;
  inTunnel?: boolean;
}
