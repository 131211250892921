import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Organization } from './organization';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  url = environment.urls.baseApiUrl + "/Organizations";
  constructor(private http: HttpClient) { }
  Read(): Observable<Organization[]> {
    return this.http.get<Organization[]>(this.url).pipe(retry(1));
  }
}
