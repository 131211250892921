import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { WfTaskResource, WfTaskLocalResource, SaveTaskResource, WFConfiguration, WfSignatureResource, WFSectionLocalResource, WFTaskSignatureResource } from 'src/app/Services/WorkFlow/work-flow';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WFTableResource, WFMasterResource, WFStepConfig, WFRoleConfig, WFActionConfig, WFTaskConfig, ReorderResource } from 'src/app/components/workflow/workflow';
// import { LogToShowResource } from 'src/app/_interface/HistoryLogs/log-to-show-resource';
// import { FormType } from 'src/app/_interface/Enumerations';
import { Store } from '@ngxs/store';
import { CommentResource } from '../../controls/comments-and-attachments/services/comment';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { WfTaskResource, WfTaskLocalResource, WFSectionLocalResource, WFConfiguration, WFTaskSignatureResource, SaveTaskResource, WfSignatureResource } from './work-flow';
import { LogToShowResource } from '../logs/log-to-show-resource';


@Injectable()
export class WorkFlowService {
  BASE_URL = environment.urls.baseApiUrl; // Api URL
  WorkFlowTask = '/WorkFlowTask';
  WFConfiguration = '/WFConfiguration';
  WorkFlow = '/WorkFlow';
  TransactionLogs = '/TransactionLogs';
  constructor(private http: HttpClient, private store: Store) {
  }
  public readPending(id: number) {
    const httpresult = this.http.get<WfTaskResource[]>(this.BASE_URL + this.WorkFlowTask + '/Pending/' + id);
    return httpresult;
  }
  public readByWorkFlow(id: number) {
    const httpresult = this.http.get<WfTaskLocalResource[]>(this.BASE_URL + this.WorkFlowTask + '/ByWorkFlow/' + id);
    return httpresult;
  }
  public readBySection(id: number, sectionNumber: number, showInactive: boolean = false, amendmentId: number = 0) {
    const httpresult = this.http.get<any[]>(this.BASE_URL + this.WorkFlowTask + '/BySection/' + id + '?SectionNumber=' + sectionNumber + '&showInactive=' + (showInactive ? 'true' : 'false') + '&amendmentId=' + amendmentId);
    return httpresult;
  }
  public readAllBySection(id: number, sectionNumber: number) {
    const httpResult = this.http.get<any[]>(this.BASE_URL + this.WorkFlowTask + '/AllBySection/' + id + '?SectionNumber=' + sectionNumber);
    return httpResult;
  }

  public readAllSections(wfTableId: number): Observable<WFSectionLocalResource[]> {
    const httpresult = this.http.get<WFSectionLocalResource[]>(this.BASE_URL + this.WorkFlow + '/WFSectionLocals/' + wfTableId);
    return httpresult;
  }

  public readByWorkFlowBySection(id: number, sectionId: number) {
    const httpresult = this.http.get<WfTaskLocalResource[]>(this.BASE_URL + this.WorkFlowTask + '/ByWorkFlow/' + id + '?wfSectionConfig=' + sectionId);
    return httpresult;
  }

  public readConfiguration(type: number, wfTableId: number) {
    const httpresult = this.http.get<WFConfiguration[]>(this.BASE_URL + this.WFConfiguration + '/' + type + '/' + wfTableId);
    return httpresult;
  }

  public sign(taskSignature: WFTaskSignatureResource) {
    const url: string = this.BASE_URL + this.WorkFlowTask + '/Sign';
    const httpresult = this.http.put<any>(url, taskSignature);
    return httpresult;
  }

  public save(s: SaveTaskResource) {
    const httpresult = this.http.put<any>(this.BASE_URL + this.WorkFlowTask + '/Save/' + s.id, s);
    return httpresult;
  }

  public saveOpApproval(s: SaveTaskResource) {
    const httpresult = this.http.put<any>(this.BASE_URL + this.WorkFlowTask + '/SaveOpApproval/' + s.id, s);
    return httpresult;
  }

  public approveOpApproval(id: number, approve: boolean) {
    const httpresult = this.http.put<WfTaskLocalResource>(this.BASE_URL + this.WorkFlowTask + '/ApproveOpApproval/' + id + '/' + approve, null);
    return httpresult;
  }

  public copyTask(wfTaskLocalId: number, duplicatedTag: string | null = null): Observable<WfTaskLocalResource> {
    const httpresult = this.http.post<WfTaskLocalResource>(this.BASE_URL + this.WorkFlowTask + '/CopyTask/' + wfTaskLocalId + '/' + duplicatedTag, null);
    return httpresult;
  }

  public removeTask(wfTaskLocalId: number) {
    const httpresult = this.http.post<any>(this.BASE_URL + this.WorkFlowTask + '/RemoveTask/' + wfTaskLocalId, null);
    return httpresult;
  }

  public readAmendmentTasks(wfTableID: number): Observable<any[]> {
    return this.http.get<any[]>(this.BASE_URL + this.WorkFlowTask + '/Amendments/' + wfTableID.toString());
  }

  public readCurrentAmendmentID(wfTableID: number): Observable<number> {
    return this.http.get<number>(this.BASE_URL + this.WorkFlow + '/CurrentAmendmentID/' + wfTableID.toString());
  }

  public getLogsBySn(SN: string): Observable<LogToShowResource> {
    return this.http.get<LogToShowResource>(this.BASE_URL + this.WorkFlow + '/GetLogsBySn/' + SN);
  }

  ////#region WF Configurator

  public getWFTable(): Observable<WFTableResource[]> {
    const httpresult = this.http.get<WFTableResource[]>(this.BASE_URL + this.WorkFlow);
    return httpresult;
  }

  public getWFTableByForm(formType: number): Observable<WFTableResource[]> {
    const httpresult = this.http.get<WFTableResource[]>(this.BASE_URL + this.WorkFlow + '/ByFormType/' + formType);
    return httpresult;
  }

  public getWFSectionsByForm(formType: number): Observable<WFMasterResource[]> {
    const httpresult = this.http.get<WFMasterResource[]>(this.BASE_URL + this.WorkFlow + '/SectionsByFormType/' + formType);
    return httpresult;
  }

  public getWFTableById(id: number): Observable<WFTableResource> {
    const httpresult = this.http.get<WFTableResource>(this.BASE_URL + this.WorkFlow + '/TableConfig/' + id);
    return httpresult;
  }

  public getWFMaster(id: number): Observable<WFMasterResource[]> {
    const httpresult = this.http.get<WFMasterResource[]>(this.BASE_URL + this.WorkFlow + '/MasterConfig/' + id);
    return httpresult;
  }

  public getWFSteps(id: number): Observable<WFStepConfig[]> {
    const httpresult = this.http.get<WFStepConfig[]>(this.BASE_URL + this.WorkFlow + '/StepConfig/' + id);
    return httpresult;
  }

  public getDocumentTypeByWFTableID(wfTableID: number): Observable<DocumentType> {
    const httpresult = this.http.get<DocumentType>(this.BASE_URL + this.WFConfiguration + '/' + wfTableID);
    return httpresult;
  }

  /// WFRoleConfig

  public postWFRole(wfRole: WFRoleConfig): Observable<WFRoleConfig> {
    const httpresult = this.http.post<WFRoleConfig>(this.BASE_URL + this.WorkFlow + '/RoleConfig', wfRole);
    return httpresult;
  }

  public putWFRole(wfRole: WFRoleConfig): Observable<WFRoleConfig> {
    const httpresult = this.http.put<WFRoleConfig>(this.BASE_URL + this.WorkFlow + '/RoleConfig/' + wfRole.id, wfRole);
    return httpresult;
  }

  public delWFRole(wfRole: WFRoleConfig) {
    const httpresult = this.http.delete(this.BASE_URL + this.WorkFlow + '/RoleConfig/' + wfRole.id);
    return httpresult;
  }

  /// WFActionConfig

  public postWFAction(wfAction: WFActionConfig): Observable<WFActionConfig> {
    const httpresult = this.http.post<WFActionConfig>(this.BASE_URL + this.WorkFlow + '/ActionConfig', wfAction);
    return httpresult;
  }

  public putWFAction(wfAction: WFActionConfig): Observable<WFActionConfig> {
    const httpresult = this.http.put<WFActionConfig>(this.BASE_URL + this.WorkFlow + '/ActionConfig/' + wfAction.id, wfAction);
    return httpresult;
  }

  public delWFAction(wfAction: WFActionConfig) {
    const httpresult = this.http.delete(this.BASE_URL + this.WorkFlow + '/ActionConfig/' + wfAction.id);
    return httpresult;
  }

  /// WFTaskConfig

  public postWFTask(wfTask: WFTaskConfig): Observable<WFTaskConfig> {
    const httpresult = this.http.post<WFTaskConfig>(this.BASE_URL + this.WorkFlow + '/TaskConfig', wfTask);
    return httpresult;
  }

  public putWFTask(wfTask: WFTaskConfig): Observable<WFTaskConfig> {
    const httpresult = this.http.put<WFTaskConfig>(this.BASE_URL + this.WorkFlow + '/TaskConfig/' + wfTask.id, wfTask);
    return httpresult;
  }

  public putWFTasks(wfTasks: WFTaskConfig[]): Observable<WFTaskConfig> {
    const httpresult = this.http.put<WFTaskConfig>(this.BASE_URL + this.WorkFlow + '/TaskConfigs/', wfTasks);
    return httpresult;
  }

  public delWFTask(wfTask: WFTaskConfig) {
    const httpresult = this.http.delete(this.BASE_URL + this.WorkFlow + '/TaskConfig/' + wfTask.id);
    return httpresult;
  }

  /// WFStepConfig

  public postWFStep(wfStep: WFStepConfig): Observable<WFStepConfig> {
    const httpresult = this.http.post<WFStepConfig>(this.BASE_URL + this.WorkFlow + '/StepConfig', wfStep);
    return httpresult;
  }

  public putWFStep(wfStep: WFStepConfig): Observable<WFStepConfig> {
    const httpresult = this.http.put<WFStepConfig>(this.BASE_URL + this.WorkFlow + '/StepConfig/' + wfStep.id, wfStep);
    return httpresult;
  }

  public delWFStep(wfStep: WFStepConfig) {
    const httpresult = this.http.delete(this.BASE_URL + this.WorkFlow + '/StepConfig/' + wfStep.id);
    return httpresult;
  }

  /// WFSectionConfig

  public postWFSection(wfSection: WFMasterResource): Observable<WFMasterResource> {
    const httpresult = this.http.post<WFMasterResource>(this.BASE_URL + this.WorkFlow + '/SectionConfig', wfSection);
    return httpresult;
  }

  public putWFSection(wfSection: WFMasterResource): Observable<WFMasterResource> {
    const httpresult = this.http.put<WFMasterResource>(this.BASE_URL + this.WorkFlow + '/SectionConfig/' + wfSection.id, wfSection);
    return httpresult;
  }

  public delWFSection(wfSection: WFMasterResource) {
    const httpresult = this.http.delete(this.BASE_URL + this.WorkFlow + '/SectionConfig/' + wfSection.id);
    return httpresult;
  }

  /// WFSectionConfig

  public postWFTable(wfTable: WFTableResource): Observable<WFTableResource> {
    const httpresult = this.http.post<WFTableResource>(this.BASE_URL + this.WorkFlow + '/TableConfig', wfTable);
    return httpresult;
  }

  public putWFTable(wfTable: WFTableResource): Observable<WFTableResource> {
    const httpresult = this.http.put<WFTableResource>(this.BASE_URL + this.WorkFlow + '/TableConfig/' + wfTable.id, wfTable);
    return httpresult;
  }

  public delWFTable(wfTable: WFTableResource) {
    const httpresult = this.http.delete(this.BASE_URL + this.WorkFlow + '/TableConfig/' + wfTable.id);
    return httpresult;
  }

  public copyWFTable(wfTable: WFTableResource) {
    const httpresult = this.http.put<WFTableResource>(this.BASE_URL + this.WorkFlow + '/DuplicateWF/' + wfTable.id, null);
    return httpresult;
  }

  //////#endregion
  public reorderItems(reorder: ReorderResource[], level: number) {
    const httpresult = this.http.patch(this.BASE_URL + this.WorkFlow + '/ReorderItems/' + level, reorder);
    return httpresult;
  }
  public cancelTask(wfTableID: number): Observable<WfSignatureResource> {
    return this.http.get<WfSignatureResource>(this.BASE_URL + this.WorkFlowTask + '/Cancel/' + wfTableID.toString());
  }
  public amendmendTask(wfTableID: number, type: FormType): Observable<WfSignatureResource> {
    return this.http.get<WfSignatureResource>(this.BASE_URL + this.WorkFlowTask + '/Amendment/' + type.toString() + '/' + wfTableID.toString());
  }
  public comments(wfTableID: number): Observable<CommentResource[]> {
    return this.http.get<CommentResource[]>(this.BASE_URL + this.WorkFlowTask + '/Comments/' + wfTableID.toString());
  }
}
