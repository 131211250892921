<div class="row no-gutters pb-3">
  <div class="col-12">
    <div class="float-right">
      <mat-slide-toggle
        color="warn"
        [(ngModel)]="showInactive"
        (change)="showRolesInactive()"
        >Show Inactive</mat-slide-toggle
      >
    </div>
  </div>
</div>
<!-- </div> -->
<!-- <div class="card-body"> -->
<div class="mat-container scrollbar">
  <mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="created"
    matSortDisableClear
  >
    <ng-container matColumnDef="role">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Role
      </mat-header-cell>
      <mat-cell
        class="bold"
        *matCellDef="let element"
        [ngClass]="{
          green: element.status == 1,
          gray: element.status == 0,
          yellow: element.color
        }"
      >
        <span [innerHTML]="element.code | highlightSearch : filter"></span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Description
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{
          green: element.status == 1,
          gray: element.status == 0,
          yellow: element.color
        }"
      >
        <span [innerHTML]="element.name | highlightSearch : filter"></span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="category">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Category
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{
          green: element.status == 1,
          gray: element.status == 0,
          yellow: element.color
        }"
      >
        <span
          [matTooltip]="
            element?.aplGroupRole?.aplGroup?.aplGroupMaster?.aplCategoryMaster
              ?.aplCategory?.name
          "
          [innerHTML]="
            element?.aplGroupRole?.aplGroup?.aplGroupMaster?.aplCategoryMaster
              ?.aplCategory?.code | highlightSearch : filter
          "
        >
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="group">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Group
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{
          green: element.status == 1,
          gray: element.status == 0,
          yellow: element.color
        }"
      >
        <span
          [innerHTML]="
            element?.aplGroupRole?.aplGroup?.name | highlightSearch : filter
          "
        ></span>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumnsRoles; sticky: true"
    ></mat-header-row>
    <mat-row
      #matrow
      *matRowDef="let row; columns: displayedColumnsRoles"
      class="table-row"
      [ngClass]="{ 'mat-row-highlight': rowHighlighted?.id == row.id }"
      [attr.position]="getPosition(row)"
      (click)="getRoleInfo(row)"
    >
    </mat-row>
  </mat-table>
</div>
<!-- </div> -->

<!-- <button
  *ngIf=""
  mat-flat-button
  color="primary"
  class="start-new-btn"
  [disabled]="!createAvailable"
  (click)="createRole()"
>
  Create role
</button> -->
<!-- </div> -->
