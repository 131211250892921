import { Component, Inject, OnInit, OnDestroy, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BratoiService } from '../bratoi.service';
import { BeamlineRequiringApproval } from '../models/beamline-requiring-approval';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'app-bratoi-history',
  templateUrl: './bratoi-history.component.html',
  styleUrls: ['./bratoi-history.component.scss']
})
export class BratoiHistoryComponent extends BaseComponent implements OnInit, OnDestroy {

  public loading = false;

  public displayedColumns: string[] = ['resource', 'documentation', 'criticalApertures', 'action'];
  public dataSource: MatTableDataSource<BeamlineRequiringApproval> = new MatTableDataSource();

  constructor(
    protected override injector: Injector,

    public dialogRef: MatDialogRef<BratoiHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public bratoi: BeamlineRequiringApproval,
    private service: BratoiService,
  ) {
    super(injector);

  }

  ngOnInit() {
    this.getInfo();
  }

  getInfo() {
    this.loading = true;
    this.service.GetHistoryByBlRequiring(this.bratoi.id).toPromise().then(data => {
      const history: BeamlineRequiringApproval[] = [];
      data?.sort((a, b) => this.utils.sort(b.id, a.id)).map(item => {
        const bratoi: any = this.utils.JSONparse(item.objectJson);
        bratoi.Action = item.name;
        bratoi.ActionOn = item.createdOn;
        bratoi.ActionBy = { Name: this.users.find(x => x.id == item.createdBy)?.name };
        history.push(bratoi);
      });
      this.dataSource = new MatTableDataSource(history);
      this.loading = false;
    });
  }
}
