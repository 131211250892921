Schedule:
<mat-form-field>
  <mat-select
    [disabled]="disabled"
    [(value)]="scheduleType"
    (selectionChange)="scheduleSelected()"
  >
    <mat-option
      *ngFor="let type of scheduleTypes"
      [value]="type"
      [displayWith]="displayType"
    >
      {{ type.acronym }}
    </mat-option>
  </mat-select>
</mat-form-field>
<div *ngIf="showLocation">
  Location:
  <mat-form-field>
    <mat-select
      [disabled]="disabled"
      [(value)]="schedule"
      (selectionChange)="locationSelected()"
    >
      <mat-option
        *ngFor="let schedule of tmpSchedules"
        [value]="schedule"
        [displayWith]="displayLocation"
      >
        {{ schedule.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
