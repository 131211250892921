<div class="sectionx" [ngClass]="{ collapse: !currentDocument }">
  <div class="section-title mb-2" ngClass="{{ headerClass }}">
    {{ Title }}
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="row mt-2 d-print-none" *ngIf="!ShowFiles">
    <div class="col-12">
      <comments-box
        [textValue]="comment"
        placeholder="Write a Comment"
        label="Comment"
        (textChanged)="comment = $event"
        [noSubscript]="true"
        [disabled]="disabled || loading"
        [formType]="formType"
        [serialNo]="serialNo"
      ></comments-box>

      <button
        mat-stroked-button
        color="primary"
        [disabled]="loading || !comment"
        *ngIf="!disabled && comment"
        #addBtn
        class="btn-comment"
        (click)="addComment()"
      >
        <mat-icon>save</mat-icon> Submit Comment
      </button>
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
    </div>
  </div>

  <div class="card mb-1" *ngFor="let c of comments">
    <div class="card-body">
      <div class="row">
        <div class="col-6 col-md-4 col-xl-2 small">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-between align-items-center">
                <mat-icon color="primary">person</mat-icon>
                <button
                  (click)="DeleteComment(c)"
                  [disabled]="loading"
                  class="d-print-none delete-button"
                  *ngIf="c.createdBy == getCurrentUser()?.id || isSA()"
                  mat-icon-button
                  color="warn"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <div>
                <span class="name">{{ c.createdByName }}</span
                ><br />
                <span class="date">{{ c.createdOn | date : "medium" }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-5 col-xl-8 border-left">
          <comments-box
            [textValue]="c.commentText"
            placeholder="Write a Comment"
            label="Comment"
            (textChanged)="comment = $event"
            [noSubscript]="true"
            [disabled]="true"
            [formType]="formType"
            [serialNo]="serialNo"
            [noBorder]="true"
          ></comments-box>
          <!-- <span [innerHTML]="c.commentText | linkify"></span> -->
        </div>
        <div
          class="col-6 col-lg-2 d-flex justify-content-end pr-0 align-items-end"
        >
          <mat-file-list
            [comment]="c"
            [uploadId]="c.serialNo"
            [disabled]="
              AttachmentsDisabled ||
              disabled ||
              loading ||
              (c.createdBy !== getCurrentUser()?.id && !isSA())
            "
            [save_files_database]="save_files_database"
            [typeDocument]="typeDocument"
          ></mat-file-list>
        </div>
      </div>
    </div>
  </div>

  <div class="row d-print-none mt-2" *ngIf="ShowFiles">
    <div class="col-12 col-md-10">
      <comments-box
        [textValue]="comment"
        [formType]="formType"
        [serialNo]="currentDocument?.serialNo"
        title="Write a Comment"
        (textChanged)="comment = $event"
        [disabled]="disabled"
        minHeight="80px"
        [noSubscript]="true"
      >
      </comments-box>
    </div>
    <div
      class="col-12 col-md-2 pl-0 d-flex flex-column pt-1 align-items-end justify-content-between"
    >
      <mat-file-v2-upload
        [uploadId]="serialNo ?? ''"
        [disabled]="disabled || loading"
        [save_files_database]="save_files_database"
        [typeDocument]="typeDocument"
      ></mat-file-v2-upload>
      <button
        mat-flat-button
        color="primary"
        [disabled]="loading || !comment || comment.trim() == ''"
        *ngIf="!disabled"
        class="btn-comment"
        #addBtn
        (click)="addComment()"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span
        >Submit Comment
      </button>
    </div>
  </div>
</div>
