<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-6">
        <span class="card-title">Documents Categories</span>
      </div>
      <div class="col-5 d-flex justify-content-end">
        <mat-slide-toggle
          color="warn"
          [disabled]="pending"
          [(ngModel)]="showInactive"
          (click)="showReviewCategoriesInactive()"
          >Show Inactive</mat-slide-toggle
        >
      </div>
    </div>

    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="card-body">
    <div class="mat-container">
      <mat-table
        [dataSource]="dataSource"
        matSortActive="created"
        matSortDisableClear
        matSort
        (matSortChange)="sortData($event)"
        matSortDirection="desc"
        [disabled]="pending"
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Category
          </mat-header-cell>
          <mat-cell
            class="font-weight-bold"
            *matCellDef="let element"
            [ngStyle]="{ color: getColor(element) }"
          >
            {{ element.name }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Description
          </mat-header-cell>
          <mat-cell
            align="center"
            *matCellDef="let element"
            [ngStyle]="{ color: getColor(element) }"
          >
            {{ element.description }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            [ngStyle]="{ color: getColor(element) }"
          >
            {{ element.status == 1 ? "Active" : "Inactive" }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef [ngClass]="'w-120'">
            <button
              mat-icon-button
              color="accent"
              [disabled]="pending"
              (click)="createReviewDocumentCategory()"
            >
              <mat-icon>add</mat-icon>
            </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="'w-120'">
            <button
              mat-icon-button
              color="accent"
              [matTooltip]="'Edit File'"
              (click)="EditReviewDocumentCategory(element)"
            >
              <mat-icon class="icon">edit</mat-icon>
            </button>
            <button
              mat-icon-button
              color="warn"
              [matTooltip]="'Delete File'"
              (click)="deleteReviewDocumentCategory(element)"
            >
              <mat-icon class="icon">delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row
          #matrow
          *matRowDef="let row; columns: displayedColumns"
          class="table-row"
          [ngClass]="{ 'mat-row-highlight': selectedRowIndex == row.id }"
          [attr.position]="getPosition(row)"
          (click)="highlight(row)"
        >
        </mat-row>
      </mat-table>
    </div>
  </div>
</div>
