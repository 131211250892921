import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { APLGroup } from 'src/app/components/apl/services/apl-group.model';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { UserService } from 'src/app/components/catalogs/user-catalog/services/user.service';
import { APLGroupService } from '../../../apl-group.service';

export class APLGroupApprovalPopupParameters {
  public aplGroup!: APLGroup;
  public user!: User;
}

@Component({
  selector: 'app-apl-group-approval-popup',
  templateUrl: './apl-group-approval-popup.component.html',
  styleUrls: ['./apl-group-approval-popup.component.scss']
})
export class APLGroupApprovalPopupComponent extends BaseComponent implements OnInit, OnDestroy {
  aplGroupApproval = false;
  loading = false;
  title!: string;
  showPrevious = false;
  submitted?: User;
  disableApproveDisapprove = false;

  labelDeleteGroup!: string;
  labelCreateGroup!: string;
  labelEditGroup!: string;
  labelAreYouSureYouWantToApprove!: string;
  labelAreYouSureYouWantToDisapprove!: string;
  labelAreYouSureYouWantToUnsubmit!: string;
  labelShowPrevious!: string;
  labelName!: string;
  labelManagers!: string;
  labelProcedureTrainingCoordinators!: string;
  labelRoles!: string;
  labelDisapprove!: string;
  labelApprove!: string;

  user!: User;

  constructor(
    protected override injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    public data: APLGroupApprovalPopupParameters,
    private dialogRef: MatDialogRef<APLGroupApprovalPopupComponent>,
    private aplGroupService: APLGroupService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.setPermissions();
    this.user = this.data?.user;
    this.submitted = this.getUser(this.data?.aplGroup?.createdBy);
    this.disableApproveDisapprove = this.data?.user?.id === this.submitted?.id;
    this.setLabels();
  }

  setLabels() {
    this.labelDeleteGroup = this.getMessage('APL_Group_Approval_DeleteGroup')?.description;
    this.labelCreateGroup = this.getMessage('APL_Group_Approval_CreateGroup')?.description;
    this.labelEditGroup = this.getMessage('APL_Group_Approval_EditGroup')?.description;
    this.labelAreYouSureYouWantToApprove = this.getMessage('APL_Group_Approval_AreYouSureYouWantToApprove')?.description;
    this.labelAreYouSureYouWantToDisapprove = this.getMessage('APL_Group_Approval_AreYouSureYouWantToDisapprove')?.description;
    this.labelAreYouSureYouWantToUnsubmit = this.getMessage('APL_CategoryApprovals_AreYouSureYouWantToUnsubmit')?.description;
    this.labelShowPrevious = this.getMessage('APL_Group_Approval_ShowPrevious')?.description;
    this.labelName = this.getMessage('APL_Group_Approval_Name')?.description;
    this.labelManagers = this.getMessage('APL_Group_Approval_Managers')?.description;
    this.labelRoles = this.getMessage('APL_Group_Approval_Roles')?.description;
    this.labelDisapprove = this.getMessage('APL_Group_Approval_Disapprove')?.description;
    this.labelApprove = this.getMessage('APL_Group_Approval_Approve')?.description;
    this.title = this.data?.aplGroup?.status == -2 ? this.labelDeleteGroup : (!this.data?.aplGroup?.previousVersion) ? this.labelCreateGroup : this.labelEditGroup;
    this.labelProcedureTrainingCoordinators = this.getMessage('APL_Group_Card_ProcedureTrainingCoordinators')?.description;
  }

  setPermissions() {
    this.aplGroupApproval = this.hasPrivilege(PrivilegeEnum.APLGroupApproval);
  }

  approve() {
    this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.labelAreYouSureYouWantToApprove,
      },
    }).afterClosed().subscribe(data => {
      if (data) {
        this.loading = true;
        this.aplGroupService.approve(this.data?.aplGroup?.id).subscribe(
          () => {
            this.dialogRef.close(true);
          },
          error => {
            this.alert.error(error.error);
            this.loading = false;
          }
        );
      }
    });
  }

  disapprove() {
    this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.labelAreYouSureYouWantToDisapprove,
      },
    }).afterClosed().subscribe(data => {
      if (data) {
        this.loading = true;
        this.aplGroupService.disapprove(this.data?.aplGroup?.id).subscribe(
          () => this.dialogRef.close(true),
          error => {
            this.alert.error(error.error);
            this.loading = false;
          }
        );
      }
    });
  }

  unSubmit() {
    this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.labelAreYouSureYouWantToUnsubmit,
      },
    }).afterClosed().subscribe(data => {
      if (data) {
        this.loading = true;
        this.aplGroupService.disapprove(this.data?.aplGroup?.id).subscribe(
          () => this.dialogRef.close(true),
          error => {
            this.alert.error(error.error);
            this.loading = false;
          }
        );
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

}
