import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-meters-catalog-settings',
  templateUrl: './meters-catalog-settings.component.html',
  styleUrls: ['./meters-catalog-settings.component.scss']
})
export class MetersCatalogSettingsComponent {

  constructor(
    private dialogRef: MatDialogRef<MetersCatalogSettingsComponent>) {

  }

  close() {
    this.dialogRef.close();
  }
}
