import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { Form, FormSave } from 'src/app/services/forms/form';
import { WFTaskSignatureResource } from 'src/app/services/work-flow/work-flow';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  FORMediting?: number | null = null;
  url = environment.urls.baseApiUrl;
  api = '/Form';
  currentDoc?: number | null = null;
  currentSN?: string | null = null;
  wasRouted?: boolean;

  SerialNumber = '/SerialNumbers/';

  constructor(
    public http: HttpClient,
  ) { }

  create(form: FormSave | null): Observable<Form> | null {
    if (form)
      return this.http.post<Form>(this.url + this.api, form);
    else return null;
  }

  getById(id: number): Observable<Form> {
    return this.http.get<Form>(this.url + this.api + '/' + id.toString());
  }

  readByType(type: FormType): Observable<Form[]> {
    return this.http.get<Form[]>(this.url + this.api + '/Type/' + type.toString());
  }

  setEditing(id: number, value?: boolean): Observable<number> {
    const URL = this.url + this.api + '/Setediting/' + id;
    const httpresult2 = this.http.put<number>(URL, { Value: value });
    return httpresult2;
  }

  readBySerialNumber(serial: string): Observable<Form> {
    return this.http.get<Form>(this.url + this.api + '/SerialNumber/' + serial);
  }

  sign(taskSignature: WFTaskSignatureResource): Observable<Form> {
    const httpresult = this.http.patch<Form>(this.url + this.api + '/Sign', taskSignature);
    return httpresult;
  }

  delete(serialNo: string) {
    const httpresult = this.http.delete(this.url + this.api + '/' + serialNo);
    return httpresult;
  }

  getSerialNo(formType: FormType): Observable<SerialNumber> {
    const httpresult1 = this.http.get<SerialNumber>(this.url + this.SerialNumber + formType);
    return httpresult1;
  }

  deleteSerialNumbers(formType: FormType, userID: number) {
    const httpresult1 = this.http.delete(this.url + this.SerialNumber + formType + '/' + userID);
    return httpresult1;
  }
}

interface SerialNumber {
  serialNo: string;
}
