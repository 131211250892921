import { Component, ElementRef, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { StepError } from '../checklist-builder.component';

@Component({
  selector: 'checklist-builder-reject',
  templateUrl: './checklist-builder-reject.component.html',
  styleUrls: ['./checklist-builder-reject.component.scss']
})
export class ChecklistBuilderRejectComponent implements OnChanges {

  @Input() errorMessages: StepError[] = [];
  displayedErrors?: StepError[];

  notDisplayedErrors?: StepError[];

  constructor(
    private elementRef: ElementRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.errorMessages.length) {
      this.displayedErrors = this.errorMessages.filter((e, i) => i <= 1);
      this.notDisplayedErrors = this.errorMessages.slice(this.displayedErrors.length);
    }
    else {
      this.displayedErrors = [];
      this.notDisplayedErrors = [];
    }
  }

  scrollTo(e: StepError) {
    const id = e.taskId ? 'task-' + e.taskId : 'step-' + e.stepId;
    const parent = document.querySelector('.sidenav-content');
    const child = document.getElementById(id);
    this.scrollParentToChild(parent, child);
  }

  scrollParentToChild(parent: any, child: any) {

    // Where is the parent on page
    const parentRect = parent.getBoundingClientRect();
    // What can you see?
    const parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth
    };

    // Where is the child
    const childRect = child.getBoundingClientRect();
    // Is the child viewable?
    const isViewable = (childRect.top >= parentRect.top) && (childRect.top <= parentRect.top + parentViewableArea.height);

    // if you can't see the child try to scroll parent
    if (!isViewable) {
      // Should we scroll using top or bottom? Find the smaller ABS adjustment
      const scrollTop = childRect.top - parentRect.top;
      parent.scrollTop += scrollTop;

      // const scrollBot = childRect.bottom - parentRect.bottom;
      // if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
      //   // we're near the top of the list
      //   parent.scrollTop += scrollTop;
      // } else {
      //   // we're near the bottom of the list
      //   parent.scrollTop += scrollBot;
      // }
    }

  }

  getMoreErrors() {
    return this.notDisplayedErrors?.map(e => {
      const id = e.taskId ? 'task-' + e.taskId : 'step-' + e.stepId;
      return '<a class="red" href="#' + id + '">' + e.text + '</a>';
    }).join('<br>');
  }

}
