<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-2">
        <h6>{{ number ?? "#" }}</h6>
      </div>
      <div class="col-11 pt-2">
        <div class="row">
          <div class="col">
            <mat-label
              [innerHTML]="question ?? '' | placeholderHighlight : editor"
            ></mat-label>
          </div>
          <div class="col d-flex flex-row align-items-end flex-column">
            <div class="d-flex mb-1 small">
              <mat-icon
                color="accent"
                matTooltip="All Checkboxes Required"
                *ngIf="allRequired"
                >warning</mat-icon
              ><role-chips [roleCodes]="roleCodes"></role-chips>
            </div>
            <div *ngFor="let o of radioOptions" class="mb-2">
              <mat-checkbox-with-label
                [label]="o.label"
                [labelPosition]="'before'"
                [checked]="o.checked"
                [disabled]="disabled ?? false"
                (change)="checkboxChanged($event, o.value)"
                [color]="color"
                [user]="o.user"
                [warning]="warning ? moreInfo : null"
                [date]="o.date"
              >
              </mat-checkbox-with-label>
            </div>
          </div>
          <div class="col-10 offset-2 pr-4 pt-1">
            <comments-box
              [disabled]="disabled"
              [textValue]="text"
              [formType]="formType"
              [serialNo]="serialNo"
              (textChanged)="textChanged($event)"
              [noSubscript]="true"
              [title]="text ? 'Description' : 'Describe'"
              minHeight="40px"
            ></comments-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
