import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectorRef, OnDestroy, AfterViewInit, OnChanges, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { Schedule } from 'src/app/components/schedules/models/schedule';
import { ScheduleType } from 'src/app/components/schedules/models/schedule-type';
import { ScheduleTypeService } from 'src/app/components/schedules/services/schedule-type.service';
import { ScheduleService } from 'src/app/components/schedules/services/schedule.service';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { RadMetersUnitsDetailsComponent } from '../../../components/catalogs/meters-catalog/rad-meters-units/rad-meters-units-details/rad-meters-units-details.component';
import { ScheduleStatusEnum } from 'src/app/components/schedules/models/enums';

@Component({
  selector: 'cl-schedule-absi',
  templateUrl: './cl-schedule-absi.component.html',
  styleUrls: ['./cl-schedule-absi.component.scss']
})
export class ClScheduleAbsiComponent extends ClBaseComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  adding!: boolean;
  type!: number;
  scheduleTypes$!: Observable<ScheduleType[]>;
  scheduleTypes!: ScheduleType[];
  location!: Schedule;
  locations: Schedule[] = [];
  filteredLocations!: Schedule[];
  locationCtrl = new FormControl();

  subscription!: Subscription;

  schedules?: Schedule[];
  schedulesFiltered?: Schedule[];
  schedules$!: Observable<Schedule[]>;
  schedulesSubs!: Subscription;

  comments!: string;
  /*--------------------------------*/

  editing!: boolean;
  public schedule!: Schedule;

  scheduleName?: string;

  checkboxChanged() {
    this.answered.emit(this.checked);
  }

  override ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.schedulesSubs?.unsubscribe();
  }

  ngAfterViewInit() {
    this.scheduleTypes$ = this.store.select(state => state.ScheduleTypes.data);
    if (this.configuration) {
      this.subscription = this.scheduleTypes$.subscribe(async data => {
        const st = this.utils.JSONparse(this.configuration);
        this.type = st.scheduleType;
        this.scheduleName = data.find(x => x.id == this.type)?.name;
        this.loadSchedules();
      });
    }
  }

  loadSchedules() {
    this.schedules$ = this.store.select(state => state.Schedules.data);
    this.schedulesSubs = this.schedules$.subscribe(data => {
      if (data?.length) {
        this.schedules = data.filter(s => s.typeId == this.type && s.isActive && s.statusId != ScheduleStatusEnum.completed && s.statusId != ScheduleStatusEnum.remove);
        this.filterLocations();
      }
    });
  }

  ngOnChanges() {
    this.ngAfterViewInit();
    this.initials = this.user ? this.user.initials : '';
    this.setValues();
  }

  ngOnInit() {
  }

  setValues() {
    this.valueObjects = this.getValueObjects(this.textValue);
    if (this.valueObjects) {
      this.comments = this.valueObjects?.find(x => x.key == 'comments').val;
      this.locations = this.valueObjects?.find(x => x.key == 'locations').val;
    }
  }

  textChanged(e: string) {
    this.comments = e;
    this.saveValues(this.getValues());
  }

  getValues() {
    this.valueObjects = [];
    this.valueObjects.push({ key: 'comments', val: this.comments });
    this.valueObjects.push({ key: 'locations', val: this.locations });
    return this.valueObjects;
  }

  addLocation() {
    this.adding = true;
  }

  filterLocations() {
    const locations: any[] = [];
    this.schedules?.map(s => {
      s.name = s.scheduleResources.map(x => x.resource ? x.resource.name : x.resourceString ? x.resourceString : null).join(', ');
      if (!this.locations.map(x => x.id).includes(s.id)) {
        locations.push(s);
      }
    });
    this.filteredLocations = locations.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
  }

  onLocationChange() {
    this.filterLocations();
    this.filteredLocations = this.filteredLocations.filter(loc => loc.name?.toLowerCase().includes(this.locationCtrl.value.toLowerCase()));
  }

  selectedLocation(event: MatAutocompleteSelectedEvent) {
    const locations = this.locations;
    this.location = event.option.value;
    locations.push(this.location);
    this.locations = locations.sort((a, b) => this.utils.SortBeamlines(a.name, b.name));
    this.locationCtrl.setValue(null);
    this.adding = false;
    this.saveValues(this.getValues());
  }

  displayLocation(loc: any) {
    return loc.name;
  }

  endAdding() {
    this.adding = false;
  }

  delete(location: Schedule) {
    this.locations.splice(this.locations.findIndex(l => l.id == location.id), 1);
    this.saveValues(this.getValues());
  }

  due(date?: Date) {
    let expired = false;
    if (date) {
      const today = new Date();
      const next = new Date(date);
      const due = new Date(next.getFullYear(), next.getMonth(), next.getDate());
      expired = due <= today;
      this.moreInfo = expired ? 'Date expired' : null;
    }
    return expired;
  }
}
