import { Component, OnInit, OnChanges, SimpleChanges, Injector, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MessageBanner } from 'src/app/components/messages/services/message-banner';
import { ProcedureCategory } from 'src/app/components/procedure/models/procedure-category.model';
import { ProcedureTypeEnum } from 'src/app/components/procedure/enums/procedure-type.enum';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { ProcedureTrainingRefresh } from '../store/procedure-training/procedure-training.action';
import { ProcedureConfiguration } from '../models/procedure-configuration.model';
import { ProcedureCategoryPopupComponent } from '../dialogs/procedure-category-popup/procedure-category-popup.component';
import { ProcedurePopupComponent } from '../dialogs/procedure-popup/procedure-popup.component';
import { ProcedureCategoryRefresh } from '../store/procedure-category/procedure-category.action';
import { ProcedureCreditedRefresh } from '../store/procedure-credited/procedure-credited.action';
import { ProcedureTemplateRefresh } from '../store/procedure-template/procedure-template.action';
import { ProcedureConfigurationRefresh } from '../store/procedure-configuration/procedure-configuration.action';

@Component({
  selector: 'app-procedure-all-v2',
  templateUrl: './procedure-all-v2.component.html',
  styleUrls: ['./procedure-all-v2.component.scss']
})
export class ProcedureAllV2Component extends BaseComponent implements OnInit, OnChanges, AfterViewInit {

  showSubMenu = false;

  disabled!: boolean;
  loading = false;
  loadingMessage!: string;

  filterCtrl = new FormControl();

  errorMessages!: string[];
  headerOnTop!: boolean;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter!: any[];
  currentFilter: any;
  filter: any;

  ///////////////////////

  categoriesSubscription!: Subscription;
  categories$!: Observable<ProcedureCategory[]>;
  categories: ProcedureCategory[] = [];
  procedureCategories!: ProcedureCategory[];
  rssTestCategories!: ProcedureCategory[];
  epsTestCategories!: ProcedureCategory[];
  epsDrawingsCategories!: ProcedureCategory[];
  keChecklistCategories!: ProcedureCategory[];
  radSurveyCategories!: ProcedureCategory[];
  categoriesDS = new MatTableDataSource<ProcedureCategory>();
  categoriesColumns: string[] = ['category'];

  procedureConfigurationSubscription!: Subscription;
  procedureConfiguration$!: Observable<ProcedureConfiguration>;
  procedureConfiguration!: ProcedureConfiguration;

  showInactive = false;
  showArchived = false;
  sidenavOpened = false;
  today = new Date();
  categoryPermission = false;
  addNewPermission = false;
  procedureCategorySort = '';
  tabIndex = 0;


  prevScrollpos = window.pageYOffset;
  headerWidth = '';
  links = this.hasPrivilege(PrivilegeEnum.PCViewRadTab) ? ['Procedure', 'RSS Test', 'EPS Test', 'EPS Drawings', 'Rad Survey'] : ['Procedure', 'RSS Test', 'EPS Test', 'EPS Drawings'];
  activeLink = this.links[0];

  messageSubscription!: Subscription;
  messages$!: Observable<MessageBanner[]>;
  tabLabel!: string;

  label_categories!: string;
  label_als_procedures!: string;
  label_filter!: string;
  label_manage_categories!: string;
  label_show_archived!: string;
  label_show_inactive!: string;

  unsubs!: Subscription;
  constructor(
    protected override injector: Injector,
    private activatedRoute: ActivatedRoute,
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.setLabels();
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    this.setLoading(true, LoadingOrigin.Self)
    // this.initStore();

    this.categoryPermission = this.hasPrivilege(PrivilegeEnum.ALSProcedureCategory);
    this.addNewPermission = this.hasPrivilege(PrivilegeEnum.ALSProcedureAddNew);

    this.categories$ = this.store.select(state => state.ProcedureCategory.ProcedureCategories);
    this.categoriesSubscription = this.categories$.subscribe((data) => {
      this.categories = data.sort((a, b) => this.utils.compare(a.name, b.name, true));
      this.procedureCategories = this.categories.filter(x => x.procedureTypeID === ProcedureTypeEnum.ProcedureCenter);
      this.rssTestCategories = this.categories.filter(x => x.procedureTypeID === ProcedureTypeEnum.RSSTest);
      this.epsTestCategories = this.categories.filter(x => x.procedureTypeID === ProcedureTypeEnum.EPSTest);
      this.epsDrawingsCategories = this.categories.filter(x => x.procedureTypeID === ProcedureTypeEnum.EPSDrawings);
      this.keChecklistCategories = this.categories.filter(x => x.procedureTypeID === ProcedureTypeEnum.KEChecklist);
      this.radSurveyCategories = this.categories.filter(x => x.procedureTypeID === ProcedureTypeEnum.RadSurvey);
      this.loadQueryParams();
    });

    this.procedureConfiguration$ = this.store.select(state => state.ProcedureConfiguration.ProcedureConfiguration);
    this.procedureConfigurationSubscription = this.procedureConfiguration$.subscribe(data => {
      this.procedureConfiguration = data;
    });

    this.setLoading(false, LoadingOrigin.Self)
  }

  loadQueryParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.setTab(params?.['tab']);
    });
  }


  applyFilter(e: any) {
    this.filter = e;
  }

  setLabels() {
    this.label_categories = this.getMessage('ALSPC_Update_Categories').description;
    this.label_als_procedures = this.getMessage('ALSPC_All_ALSProcedures').description;
    this.label_filter = this.getMessage('ALSPC_Update_Filter').description;
    this.label_manage_categories = this.getMessage('ALSPC_Update_ManageCategories').description;
    this.label_show_archived = this.getMessage('ALSPC_Update_ShowArchived').description;
    this.label_show_inactive = this.getMessage('ALSPC_Update_ShowInactive').description;
    if (!this.label_categories) {
      setTimeout(() => {
        this.setLabels();
      }, 1000);
    }
  }

  onTabChanged(e: string) {
    this.tabIndex = e ? this.links.indexOf(e) : 0;

    switch (this.tabIndex) {
      case 0:
        this.categoriesDS.data = this.procedureCategories;
        break;

      // case 1:
      //   this.categoriesDS.data = this.keChecklistCategories;
      //   break;

      case 1:
        this.categoriesDS.data = this.rssTestCategories;
        break;

      case 2:
        this.categoriesDS.data = this.epsTestCategories;
        break;

      case 3:
        this.categoriesDS.data = this.epsDrawingsCategories;
        break;

      case 4:
        this.categoriesDS.data = this.radSurveyCategories;
        break;
    }
  }

  setTab(tabParam?: string) {
    this.tabIndex = tabParam ? +tabParam : 0;
    this.setActiveLink();
  }

  setActiveLink() {
    this.activeLink = this.links[this.tabIndex];
    this.tabLabel = this.activeLink;
    this.onTabChanged(this.activeLink);
  }

  settings() {

  }


  navigate(id: number) {
    const e = document.getElementById('_' + id.toString());
    const c = document.getElementById('tables');
    document.getElementById('_' + id.toString())?.scrollIntoView({ behavior: 'smooth' });
  }

  openCategoriesPopup() {
    this.dialog.open(ProcedureCategoryPopupComponent, {
      height: 'auto',
      width: '75vw',
      data: {},
    });
  }

  openProcedurePopup(procedure: Procedure) {
    let obj: Procedure;

    if (this.procedureConfiguration?.currentPath?.trim()?.length <= 0 || this.procedureConfiguration?.inactivePath?.trim()?.length <= 0 || this.procedureConfiguration?.archivedPath?.trim()?.length <= 0) {
      this.alert.error('You need to configure tha paths to save the files');
      return;
    }

    if (procedure != null) {
      obj = this.utils.cloneDeep(procedure);
      this.dialog.open(ProcedurePopupComponent, {
        height: 'auto',
        width: '75vw',
        maxHeight: '85vh',
        disableClose: true,
        data: {
          procedure: obj,
          procedureTab: this.tabIndex,
        }
      });
    }
  }

  initStore() {
    this.store.dispatch(
      new ProcedureCategoryRefresh()
    );
    this.store.dispatch(
      new ProcedureTrainingRefresh()
    );
    this.store.dispatch(
      new ProcedureCreditedRefresh()
    );
    this.store.dispatch(
      new ProcedureTemplateRefresh()
    );
    this.store.dispatch(
      new ProcedureConfigurationRefresh()
    );
  }

  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 2000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Self:
          this.loadingSelf = e as boolean;
          break;
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Buttons:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Main:
          this.loadingMain = e as boolean;
          break;
      }
      // console.log('m: ' + this.loadingMain + '   b: ' + this.loadingButtons + '   s: ' + this.loadingSide);
      this.loading = this.loadingSelf || this.loadingMain || this.loadingButtons || this.loadingSide;
    }, 100);
  }

  // mainValid(): boolean {
  //   // return this.main.formValid();
  // }

  loadingSide = false;
  loadingButtons = false;
  loadingMain = false;
  loadingSelf = false;
  loadingOrigin = LoadingOrigin;



}

enum LoadingOrigin {
  Self = 0, Side = 1, Buttons = 2, Main = 3
}
