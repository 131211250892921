<div
  #box
  class="resizable-draggable"
  [class.active]="status === 1 || status === 2"
  (mousedown)="setStatus($event, 2)"
  (window:mouseup)="setStatus($event, 0)"
  [style.transform]="'translate3d(' + left + 'px,' + top + 'px,' + '0px)'"
>
  <div class="row">
    <div class="col">
      <h6>{{ actionType == action.AddSpot ? "Add Spot" : "Image Options" }}</h6>
    </div>
  </div>
  <div class="row" *ngIf="actionType == action.AddSpot">
    <div class="col d-flex flex-column align-items-center">
      <h5>
        Row selected&nbsp; <b> {{ rowId }} </b>
      </h5>
      <h6>Click on the Image to Add a new spot.</h6>
      <mat-icon class="big-icon" color="primary">add_location_alt</mat-icon>
    </div>
  </div>

  <div class="row" *ngIf="canBeDeleted && actionType == action.ImageOptions">
    <div class="col-12 d-flex flex-column align-items-center">
      <img class="image mb-1" alt="image" src="{{ image }}" />
      <button mat-stroked-button (click)="removeImage()" color="warn">
        Remove Image <mat-icon>cancel_presentation</mat-icon>
      </button>
    </div>
  </div>
  <div class="row" *ngIf="actionType == action.ImageOptions">
    <div class="col-12 d-flex flex-column align-items-center mt-1">
      <button mat-flat-button (click)="uploader.click()" color="primary">
        Add New Image&nbsp;<mat-icon>add_photo_alternate</mat-icon>
      </button>
      <form>
        <input
          hidden
          #uploader
          type="file"
          (change)="upload($event)"
          accept="image/*"
        />
      </form>
    </div>
  </div>
  <div
    class="row mt-4"
    *ngIf="!canBeDeleted && actionType == action.ImageOptions && image"
  >
    <div class="col red small">Images with red spots can't be deleted!</div>
  </div>
  <div class="row">
    <div class="col d-flex justify-content-center small mt-3">
      You can move this dialog to any position.
    </div>
  </div>

  <button
    mat-icon-button
    color="warn"
    (click)="cancelAdd()"
    class="close-btn"
    [matTooltip]="'Close Dialog'"
  >
    <mat-icon color="warn">cancel</mat-icon>
  </button>
</div>
