import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Review, ReviewAttendanceCategory, ReviewAttendanceSave, ReviewAttendanceRoster } from '../../../../../../../reviews.models';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'load-saved-roster-right',
  templateUrl: './load-saved-roster-right.component.html',
  styleUrls: ['./load-saved-roster-right.component.scss']
})
export class LoadSavedRosterRightComponent implements OnInit, OnChanges {

  @Input() review?: Review | null;
  @Input() reviewAttendanceSave?: ReviewAttendanceSave | null;
  @Input() replace?: boolean | null;
  @Output() membersToAddChanged = new EventEmitter();

  reviewCategories!: ReviewAttendanceCategory[];
  reviewAttendanceNew!: ReviewAttendanceRoster[];

  public displayedColumns: string[] = ['role', 'member'];
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.reviewCategories = [];
    this.reviewAttendanceNew = [];
    this.review?.reviewAttendanceRosters?.map(x => {
      this.reviewAttendanceNew.push(x);
    });
    this.reviewAttendanceSave?.reviewAttendanceSavedList.map(x => {
      const found = this.reviewAttendanceNew.find(n => n.userID == x.userID && n.categoryID == x.categoryID && n.roleID == x.roleID);
      //Add new users
      if (!found) {
        const reviewAttendanceRoster = {
          user: x.user,
          userID: x.userID,
          role: x.role,
          roleID: x.roleID,
          category: x.category,
          categoryID: x.categoryID,
          id: 0
        } as ReviewAttendanceRoster;
        this.reviewAttendanceNew.push(reviewAttendanceRoster);
      }
    });

    if (this.reviewAttendanceSave) {
      this.reviewAttendanceNew.map(x => {
        const found = this.reviewAttendanceSave?.reviewAttendanceSavedList.find(n => n.userID == x.userID && n.categoryID == x.categoryID && n.roleID == x.roleID);
        //Remove users not on saved list if Replace is toggled on
        if (!found && x.id > 0 && this.replace) {
          x.id = -x.id;
        }
        //If Combine is toggled, set ids postive again
        else if (!found && x.id < 0 && !this.replace) {
          x.id = Math.abs(x.id);
        }
      });
    }

    this.reviewAttendanceNew.map(x => {
      if (!this.reviewCategories.map(c => c.id).includes(x.categoryID)) {
        if (x.category)
          this.reviewCategories.push(x.category);
      }
    });
    this.reviewCategories = this.reviewCategories.sort((a, b) => utils.sort(a.order, b.order));
    this.membersToAddChanged.emit(this.reviewAttendanceNew.filter(x => x.id <= 0).length);
  }

  ngOnInit(): void {
  }

  getReviewMembersByCategory(reviewCategory: ReviewAttendanceCategory) {
    return this.reviewAttendanceNew.filter(x => x.categoryID == reviewCategory.id);
  }

}
