import { Component, ViewChild, HostListener, OnInit, OnDestroy, AfterViewInit, Injector } from '@angular/core';
import { KEResource, SaveKEResource } from './ke';
import { Observable, Subscription } from 'rxjs';
import { KEService } from './ke.service';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { FormStatus } from 'src/app/services/forms/form-status';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { BaseComponent } from 'src/app/common/base/base.component';
import { KESection0Component } from './ke-main/ke-section0/ke-section0.component';
import { KESidePanelComponent } from './ke-side/ke-side-panel.component';
import { KEMainPanelComponent } from './ke-main/ke-main-panel.component';

@Component({
  selector: 'ke',
  templateUrl: './kenable.component.html',
  styleUrls: ['./kenable.component.scss']
})

export class KEComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(KESection0Component) public section0!: KESection0Component;
  @ViewChild(KESidePanelComponent) public sidePanel!: KESidePanelComponent;
  @ViewChild(KEMainPanelComponent) public mainPanel!: KEMainPanelComponent;

  loading: boolean = true;

  ke?: KEResource | null;
  formStatus?: FormStatus;
  formStatuses!: FormStatus[];
  formStatuses$!: Observable<FormStatus[]>;
  formStatusesSubs!: Subscription;

  canCreate!: boolean | null;
  canCancel!: boolean | null;

  filter!: string;

  cancelAllowStatuses: FormStatusEnum[] = [FormStatusEnum.Draft, FormStatusEnum.Active, FormStatusEnum.Shielding];

  disapproveReason?: string;
  disapproved = false;

  dateNow!: any;

  constructor(
    protected override injector: Injector,
    private _ke: KEService
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    const element = $('#left-nav-buttons')[0];
    const resizeObserver = new ResizeObserver(() => {
      this.setHeights();
    });
    resizeObserver.observe(element);
    window.addEventListener('resize', () => {
      this.setHeights();
    }, true);
  }

  override ngOnDestroy(): void {
    this.formStatusesSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.loadFormStatuses();
    this.moduleTitle = 'Key-Enable Procedure Checklist';
    this.checkPrivileges();
  }

  checkPrivileges() {
    this.canCreate = this.hasPrivilege(PrivilegeEnum.CreateKEChecklist);
    this.canCancel = this.hasPrivilege(PrivilegeEnum.CancelKEChecklist) && this.cancelAllowStatuses.includes(this.ke?.status ?? 0);
  }

  loadFormStatuses() {
    this.formStatuses$ = this.store.select(state => state.FormStatuses.data);
    this.formStatusesSubs = this.formStatuses$.subscribe(data => {
      if (data) {
        this.formStatuses = data;
        setTimeout(() => {
          this.formStatus = this.formStatuses?.find(x => x.id == this.ke?.status);

        }, 100);
      }
    });
  }

  refresh(e: KEResource) {
    this.ke = e;
    this.checkPrivileges();
    setTimeout(() => {
      this.formStatus = this.formStatuses.find(x => x.id == this.ke?.status);
    }, 100);
    if (this.ke?.wfSignatures?.length) {
      this.disapproveReason = this.ke.wfSignatures?.find(x => x.sectionNumber == 0)?.disapproveReason;
    }
  }

  async create() {
    this.setFormDirty(true);
    this.ke = {
      id: 0,
      serialNo: (await this._ke.getSerialNo().toPromise())?.serialNo,
      status: 0,
      createdBy: this.getCurrentUser()?.id,
      createdByName: this.getCurrentUser()?.name,
      createdOn: new Date()
    } as KEResource;
    this.formStatus = this.formStatuses.find(x => x.id == this.ke?.status);
    this.mainPanel?.create(this.ke);
    this.sidePanel.selectedRowIndex = -1;
    this.sidePanel.ke = null;
  }

  showCreate() {

  }

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  save(status: number) {
    if (this.ke?.serialNo) {
      this.setLoading(true);

      const saveKEResource: SaveKEResource = {
        id: this.ke ? this.ke.id : 0,
        name: this.ke.serialNo,
        serialNo: this.ke.serialNo,
        shutterId: this.section0.shutter?.id ?? 0,
        branchlineId: this.section0.resource?.id ?? 0,
        status,
        requesterId: this.getCurrentUser()?.id,
        wfTableID: this.section0.wfTableID ?? 0,
        type: this.section0.radioSelection
      };
      if (saveKEResource.id == 0) {
        if (this.section0.isValid(saveKEResource)) {
          this.setLoading(true);
          this._ke.post(saveKEResource).toPromise().then(data => {
            if (data) {
              this.ke = data;
              this.sidePanel.getValues(this.ke);
              this.setFormDirty(false);
              const wfSignature = this.ke?.wfSignatures?.find(x => x.sectionNumber == 0);
              if (wfSignature)
                this.section0.sign(wfSignature.taskID, wfSignature.approveID);
              this.alert.info(this.getMessage('NewChecklistCreated').description.replace('{serialNo}', data.serialNo));
              this.setLoading(false);
            }
          },
            error => {
              console.log(error);
              this.alert.error(error.error);
              this.setLoading(false);
            }
          );
        }
        else {
          this.alert.error(this.section0.errorMsg.join(', '));
          this.setLoading(false);
        }
      }
    }
  }

  async cancel() {
    await this.section0.cancel();
  }

  unselect() {
    this.sidePanel.unselect();
  }

  async discard() {
    this.setLoading(true);
    await this.deleteSerialNo(this.ke?.serialNo)?.toPromise();
    this.ke = null;
    this.setFormDirty(false);
    this.setLoading(false);
  }

  override async canDeactivate(): Promise<boolean> {
    const value = await super.canDeactivate();
    if (value) {
      this.setLoading(true);
      if (this.ke?.serialNo && this.currentUser) {
        await this.deleteSerialNo(this.ke?.serialNo)?.toPromise();
        this.ke = null;
      }
      this.setLoading(false);
    }
    return value;
  }

  setLoading(val: boolean) {
    setTimeout(() => {
      this.loading = val;
    }, 100);
  }
}
