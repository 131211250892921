import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Checklist, ChecklistTemplateStatusEnum, ChecklistTemplate, TemplateType, RSSTest } from '../checklists';
import { ChecklistsService } from '../checklists.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ChecklistBuilderService } from '../checklist-builder/checklist-builder.service';
import { SchedulesRefresh } from '../../schedules/store/schedules/schedule.action';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { PlaceholdersService } from 'src/app/services/placeholders/placeholders.service';
import { ProcedureTypeEnum } from 'src/app/components/procedure/enums/procedure-type.enum';
import { WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';
import { Schedule } from '../../schedules/models/schedule';
import { BaseComponent } from 'src/app/common/base/base.component';
import { TemplateTypesRefresh } from '../store/template-types/template-types.action';

@Component({
  selector: 'app-checklist-temp',
  templateUrl: './checklist-temp.component.html',
  styleUrls: ['./checklist-temp.component.scss']
})
export class ChecklistTempComponent extends BaseComponent implements OnInit, OnDestroy {

  public schedule?: Schedule;
  public templateType?: TemplateType;
  public checklistTemplate?: ChecklistTemplate;
  public checklist?: Checklist;

  public schedules!: Schedule[];
  public templateTypes!: TemplateType[];
  public sections?: WFSectionLocalResource[];

  public schedules$!: Observable<Schedule[]>;
  public templateTypes$!: Observable<TemplateType[]>;

  public scheduleSubscribe!: Subscription;
  public templateTypeSubscribe!: Subscription;

  public loadingSchedules!: boolean;
  public loadingTemplateTypes!: boolean;
  public isLoading!: boolean;

  public dialogRef: MatDialogRef<any, any> | null;
  public dialogData: RSSTest;
  isPrintView = false;

  constructor(
    private checklistsService: ChecklistsService,
    private placeholdersService: PlaceholdersService,
    private router: Router,
    private route: ActivatedRoute,
    private checklistTempServ: ChecklistBuilderService,
    protected override injector: Injector
  ) {
    super(injector);
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
  }

  override ngOnDestroy(): void {
    this.scheduleSubscribe?.unsubscribe();
    this.templateTypeSubscribe?.unsubscribe();
  }

  async ngOnInit() {
    this.isLoading = true;
    if (this.router.url.includes('/rss-test-view-mode')) {
      const id = this.route.snapshot.queryParams['id'];
      this.checklistTempServ.getchecklistTemplateById(id).toPromise().then(result => {
        this.isPrintView = true;
        this.dialogData = {
          procedureMasterId: result?.procedureMasterID,
          procedureTypeEnum: ProcedureTypeEnum.RSSTest,
          checklistTemplate: result
        };
        if (this.dialogData?.checklistTemplate !== undefined && this.dialogData?.checklistTemplate) {
          this.createChecklistFromTemplate(this.dialogData?.checklistTemplate);
        } else {
          this.loadSchedules();
        }
      });
    } else {
      if (this.dialogData?.checklistTemplate !== undefined && this.dialogData?.checklistTemplate != null) {
        this.createChecklistFromTemplate(this.dialogData?.checklistTemplate);
      } else {
        await this.loadSchedules();
      }
    }
  }

  async loadChecklistTemplates() {
    this.templateTypes$ = this.store.select(state => state.TemplateTypes.data);
    this.templateTypeSubscribe = this.templateTypes$.subscribe(data => {
      if (data && data?.length) {
        this.templateTypes = data.filter(x => x.scheduleTypeID === (this.dialogData.procedureTypeEnum === ProcedureTypeEnum.RSSTest ? 5 : this.dialogData.procedureTypeEnum === ProcedureTypeEnum.EPSTest ? 2 : 0));
        this.templateType = this.templateTypes.find(templateType => templateType.checklistTemplates?.some(x => x.status === ChecklistTemplateStatusEnum.Active && x.resourceID === this.schedule?.scheduleResources.find(resource => resource.resourceId)?.resourceId));
        this.checklistTemplate = this.templateType?.checklistTemplates?.find(x => x.status === ChecklistTemplateStatusEnum.Active && x.resourceID === this.schedule?.scheduleResources.find(resource => resource.resourceId)?.resourceId);
        if (this.checklistTemplate) { this.createChecklist(); }
        else {
          'ChecklistNotFound'
            ;
          this.dialogRef?.close();
        }
      } else {
        if (!this.loadingTemplateTypes) {
          this.loadingTemplateTypes = true;
          this.store.dispatch(new TemplateTypesRefresh());
        }
      }
    });
  }

  async loadSchedules() {
    this.schedules$ = this.store.select(state => state.Schedules.data);
    this.scheduleSubscribe = this.schedules$.subscribe(data => {
      if (data) {
        this.schedules = data;
        this.schedule = this.schedules.find(x => x.procedureMasterID === this.dialogData.procedureMasterId);
        if (!this.schedule || !this.schedule.scheduleResources.filter(x => x.resourceId).length) {
          'ChecklistNotFound'
            ;
          this.dialogRef?.close();
        } else {
          this.loadChecklistTemplates();
        }
      } else {
        if (!this.loadingSchedules) {
          this.loadingSchedules = true;
          this.store.dispatch(new SchedulesRefresh());
        }
      }
    });
  }

  createChecklist() {
    const checklist: Checklist = {
      id: 0,
      resourceID: this.schedule?.scheduleResources.find(x => x.resourceId)?.resourceId,
      scheduleId: this.schedule?.id,
      checklistTemplateID: this.checklistTemplate?.id,
      status: FormStatusEnum.Temporal
    };
    this.checklistsService.post(checklist).toPromise().then(async data => {
      if (data) {
        this.checklist = data;
        if (this.checklist.resource)
          await this.placeholdersService.setKeyValuePairs(this.checklist.resource, false);
        this.sections = this.checklist.wfTable?.wfTableLocal?.wfSectionLocals;
      }
      this.isLoading = false;
    }).catch((error) => {
      console.log(error);
      this.alert.defaultError();
    });
  }

  createChecklistFromTemplate(checklistTemplate: ChecklistTemplate) {
    const checklist: Checklist = {
      id: 0,
      resourceID: checklistTemplate.resourceID,
      checklistTemplateID: checklistTemplate.id,
      status: FormStatusEnum.Temporal,
    };
    this.checklistsService.post(checklist).toPromise()
      .then(data => {
        if (data) {
          this.checklist = data;
          if (this.checklist.resource)
            this.placeholdersService.setKeyValuePairs(this.checklist.resource, false);
          this.sections = this.checklist.wfTable?.wfTableLocal?.wfSectionLocals;
        }
        this.isLoading = false;
      })
      .catch(error => {
        console.error(error);
        this.alert.defaultError();
      });
  }

  sendPrintView() {
    // Get the dialog content or the scrollable content within the dialog
    const dialogContent = document.getElementById('temp-rss-functional-test-checklist');
    if (!dialogContent) return;

    // Create a new window for printing, but hide it off-screen
    const printWindow = window.open('', '_blank', 'width=800,height=600,left=-10000,top=-10000');
    if (!printWindow) return;

    // Clone the content of the dialog
    const clone = dialogContent.cloneNode(true) as HTMLElement;

    // Create a new HTML document inside the print window
    printWindow.document.open();
    printWindow.document.write('<html><head><title>Print</title></head><body></body></html>');

    // Clone and append all styles (including scroll-related styles)
    const head = document.querySelector('head');
    if (head) {
      const clonedHead = head.cloneNode(true);
      printWindow.document.head.appendChild(clonedHead);
    }

    // Clone the content and append to the new window's body
    printWindow.document.body.appendChild(clone);

    // Optionally, add any additional styles to improve the print layout
    const style = printWindow.document.createElement('style');
    style.innerHTML = `
    body {
      margin: 0;
      font-family: Arial, sans-serif;
      overflow: visible;
    }
    /* You can adjust the page size and layout here */
    @media print {
      body {
        font-size: 12px;
        width: 100%;
        height: auto;
        margin: 0;
      }
      .card-body, .h-200 {
        overflow: visible !important;
        height: auto !important;
      }
    }
  `;
    printWindow.document.head.appendChild(style);

    // Close the document (completes the loading of content)
    printWindow.document.close();

    // Focus on the new print window (this may still be briefly visible)
    printWindow.focus();

    // Trigger the print dialog
    setTimeout(() => {
      printWindow.print();
      setTimeout(() => {
        printWindow.close();
      }, 1000); // Delay before closing the window to allow printing
    }, 500); // Ensure the window content is fully loaded before printing
  }



}
