import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OlogCategory } from './olog-category';

@Injectable({
  providedIn: 'root'
})
export class OlogCategoryService {

  public api: string = environment.urls.baseApiUrl + '/OlogCategory';

  constructor(
    private http: HttpClient
  ) { }

  GetOlogCategories(): Observable<OlogCategory[]> {
    return this.http.get<OlogCategory[]>(this.api);
  }

  GetOlogCategoryById(id: number): Observable<OlogCategory> {
    return this.http.get<OlogCategory>(this.api + '/' + id);
  }

  CreateOlogCategory(ologCategory: OlogCategory): Observable<OlogCategory> {
    return this.http.post<OlogCategory>(this.api, ologCategory);
  }

  UpdateOlogCategory(id: number, ologCategory: OlogCategory): Observable<OlogCategory> {
    return this.http.put<OlogCategory>(this.api + '/' + id, ologCategory);
  }

  DeleteOlogCategory(id: number, deleteReason: string) {
    return this.http.delete(this.api + '/' + id + '/' + deleteReason);
  }
}
