<div class="container-fluid pt-3">
  Olog Email Addresses
  <div class="mat-container">
    <mat-table #table [dataSource]="dataSource">
      <ng-container matColumnDef="user">
        <mat-header-cell *matHeaderCellDef> User Name </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.user.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
        <mat-cell *matCellDef="let element"
          >{{ element.user.emailAddress }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="add">
        <mat-header-cell *matHeaderCellDef>
          <button mat-icon-button color="accent" (click)="ologEmailDialog()">
            <mat-icon>add</mat-icon>
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            color="accent"
            (click)="ologEmailDialog(element)"
            *ngIf="hasPrivilege(privilegeEnum.UpdateOlogEmail)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            (click)="deleteOlogEmailReason(element)"
            *ngIf="hasPrivilege(privilegeEnum.DeleteOlogEmail)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumnsOlogEmail"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumnsOlogEmail"
        [hidden]="row.deleteReason"
      ></mat-row>
    </mat-table>
  </div>
</div>
