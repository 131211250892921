import { Component, ViewChild, OnChanges, Injector, Input } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { ImageUploadService } from 'src/app/services/file/image-upload.service';
import { CatlComponent } from '../../catl/catl.component';
import { ActionItem } from 'src/app/common/models/action-item';

@Component({
  selector: 'cl-checkbox-and-comments',
  templateUrl: './cl-checkbox-and-comments.component.html',
  styleUrls: ['./cl-checkbox-and-comments.component.scss']
})

export class ClCheckboxAndCommentsComponent extends ClBaseComponent implements OnChanges {

  @ViewChild(CatlComponent) public catl!: CatlComponent;

  loading!: boolean;

  applies!: boolean;
  textBeforeSanitized!: SafeHtml | null;
  textValue2!: string;
  actionItems: ActionItem[] = [];
  /*--------------------------------*/

  editing!: boolean;

  constructor(
    protected override injector: Injector,
    private _image: ImageUploadService,

    private sanitizer: DomSanitizer
  ) {
    super(injector);
  }

  setValues() {
    this.valueObjects = this.getValueObjects(this.valueString);
    if (this.valueObjects) {
      this.applies = this.valueObjects?.find(x => x.key == 'applies').val;
      this.radioSelection = this.valueObjects?.find(x => x.key == 'radio').val;
      this.textValue = this.valueObjects?.find(x => x.key == 'comments').val;
      this.actionItems = this.valueObjects?.find(x => x.key == 'catl').val;
      if (!this.actionItems) {
        this.actionItems = [];
      }
    }
  }

  ngOnChanges() {
    this.initials = this.user ? this.user.initials : '';
    this.setValues();
    this.textBeforeSanitized = this.textBefore ? this.sanitizer.bypassSecurityTrustHtml(this.textBefore) : null;
    this.loading = false;
  }

  checkboxChanged() {
    if (this.checked) {
      this.approve.emit(this.checked);
    }
    else {
      this.unapprove.emit(this.checked);
    }
    this.saveValues(this.getValues());
  }

  // saveValues() {
  //   this.loading = true;
  //   this.save.emit();
  // }

  getValues() {
    this.valueObjects = [];
    this.valueObjects.push({ key: 'applies', val: this.applies });
    this.valueObjects.push({ key: 'radio', val: this.radioSelection });
    this.valueObjects.push({ key: 'comments', val: this.textValue });
    this.valueObjects.push({ key: 'catl', val: this.actionItems });
    this.user = this.applies ? this.getCurrentUser() : null;
    return this.valueObjects;
  }

  /* --- Individual event controls --*/

  appliesChecked(e: any) {
    this.applies = e.checked;
    this.saveValues(this.getValues());
  }

  radioChanged(e: any) {
    this.radioSelection = e.value;
    this.saveValues(this.getValues());
  }

  textChanged(e: any) {
    this.textValue = e;
    this.saveValues(this.getValues());
  }

  addActionItem() {
    this.catl.add();
  }

  catlChanged(e: any) {
    this.saveValues(this.getValues());
  }

}
