<!-- <div class="row">
  <div class="col-3 center-text" *ngIf="icon">
    <img src="../../../../assets/icons/dialog/warn.png" style="width: 75%" />
  </div>
  <div class="col-9">
    <label style="font-weight: bold">{{ title }}</label>
  </div>
</div>
<div class="row">
  <div class="col-3"></div>
  <div class="col-9">
    <label>{{ warning }}</label>
  </div>
</div>
<div class="row">
  <div class="col-3"></div>
  <div class="col-9">
    <mat-form-field>
      <mat-label><span innerHTML="{{ placeholder }}"></span></mat-label>
      <textarea
        matInput
        id="rejectReason"
        rows="5"
        [(ngModel)]="data.text"
      ></textarea>
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-3"></div>
  <div class="col-9 center-text">
    <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
    <span style="margin-right: 10px"></span>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!data.text"
      (click)="accept()"
    >
      Accept
    </button>
  </div>
</div> -->

<div class="card">
  <div class="card-header">
    <span class="card-title">{{ title }}</span>
  </div>

  <div class="card-body">
    <mat-form-field>
      <mat-label><span innerHTML="{{ placeholder }}"></span></mat-label>
      <textarea
        matInput
        id="rejectReason"
        rows="5"
        [(ngModel)]="data.text"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!data.text"
        (click)="accept()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
