import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericHttpService } from 'src/app/services/generic-http.service';
import { ScheduleType, ScheduleTypeWithDocumentType as ScheduleTypeWithDocType } from '../models/schedule-type';

@Injectable({
  providedIn: 'root'
})
export class ScheduleTypeService extends GenericHttpService<ScheduleType, number> {

  constructor(private http: HttpClient) {
    super(http, '/ScheduleTypes');
  }
  post(schedTypeDocType: ScheduleTypeWithDocType) {
    return this.http.post<ScheduleType>(this.rootURL, schedTypeDocType);
  }
  getFromDocumentTypeId(documentTypeId: number) {
    return this.http.get<ScheduleType>(this.rootURL + '/ByDocumentType/' + documentTypeId);
  }
  getAll() {
    return this.http.get<ScheduleType[]>(this.rootURL + '/All');
  }

}
