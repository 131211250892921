<div
  [ngClass]="{
    'container-fluid': dataSourcePendingAccelerator.data.length,
    invisible: !dataSourcePendingAccelerator.data.length
  }"
>
  <pending-approvals-changes-table
    id="accCatalog"
    [dataSource]="dataSourcePendingAccelerator"
    [pendingCatalog]="pendingCatalogType.Accelerator"
  ></pending-approvals-changes-table>
</div>

<div
  [ngClass]="{
    'container-fluid': dataSourcePendingResource.data.length,
    invisible: !dataSourcePendingResource.data.length
  }"
>
  <pending-approvals-changes-table
    id="beamCatalog"
    [dataSource]="dataSourcePendingResource"
    [pendingCatalog]="pendingCatalogType.Beamline"
  ></pending-approvals-changes-table>
</div>

<div
  [ngClass]="{
    'container-fluid': dataSourcePendingUser.data.length,
    invisible: !dataSourcePendingUser.data.length
  }"
>
  <pending-approvals-changes-table
    id="users"
    [dataSource]="dataSourcePendingUser"
    [pendingCatalog]="pendingCatalogType.User"
  ></pending-approvals-changes-table>
</div>

<div
  [ngClass]="{
    'container-fluid': dataSourcePendingRole.data.length,
    invisible: !dataSourcePendingRole.data.length
  }"
>
  <pending-approvals-changes-table
    id="roles"
    [dataSource]="dataSourcePendingRole"
    [pendingCatalog]="pendingCatalogType.Role"
  ></pending-approvals-changes-table>
</div>

<div
  [ngClass]="{
    'container-fluid': dataSourcePendingNotificationTemplate.data.length,
    invisible: !dataSourcePendingNotificationTemplate.data.length
  }"
>
  <pending-approvals-changes-table
    id="notificationTemplates"
    [dataSource]="dataSourcePendingNotificationTemplate"
    [pendingCatalog]="pendingCatalogType.NotificationTemplate"
  ></pending-approvals-changes-table>
</div>

<div
  [ngClass]="{
    'container-fluid': dataSourcePendingRSSDatabase.data.length,
    invisible: !dataSourcePendingRSSDatabase.data.length
  }"
>
  <pending-approvals-changes-table
    id="rssdb"
    [dataSource]="dataSourcePendingRSSDatabase"
    [pendingCatalog]="pendingCatalogType.RSSDb"
  ></pending-approvals-changes-table>
</div>

<div
  [ngClass]="{
    'container-fluid': dataSourcePendingTemplates.data.length,
    invisible: !dataSourcePendingTemplates.data.length
  }"
>
  <pending-approvals-changes-table
    id="checklistTemplates"
    [dataSource]="dataSourcePendingTemplates"
  ></pending-approvals-changes-table>
</div>
