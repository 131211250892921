<div class="container-fluid pt-3">
  Olog Categories
  <div class="mat-container">
    <mat-table #table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Category </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="add">
        <mat-header-cell *matHeaderCellDef>
          <button mat-icon-button color="accent" (click)="ologCategoryDialog()">
            <mat-icon>add</mat-icon>
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let elementIndex = index">
          <button
            mat-icon-button
            color="accent"
            (click)="ologCategoryDialog(element)"
            *ngIf="hasPrivilege(privilegeEnum.UpdateOlogCategory)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            (click)="delete(element)"
            *ngIf="hasPrivilege(privilegeEnum.DeleteOlogCategory)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumnsOlogCategory"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumnsOlogCategory"
        [hidden]="row.deleteReason"
      ></mat-row>
    </mat-table>
  </div>
</div>
