<div class="card">
  <div class="card-body overflow-hidden">
    <div class="row">
      <div class="col d-flex flex-row justify-content-between">
        <div>
          <mat-icon color="warn">dangerous</mat-icon>
        </div>
        <div mat-dialog-content>
          <h4>Are you sure you want to leave?</h4>
          <label>You will lose all the data</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col center-text">
        <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
        <span style="margin-right: 10px"></span>
        <button mat-flat-button color="primary" (click)="discard()">
          Discard Changes
        </button>
      </div>
    </div>
  </div>
</div>
