import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss']
})
export class DatetimePickerComponent {

  @Input() dateTime?: Date | null;
  @Input() valid?: boolean;

  @Output() changed = new EventEmitter<Date>();

  today = new Date();

  date?: Date;
  time!: string;

  constructor(
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dateTime) {
      this.date = new Date(this.dateTime);
      let hours;
      let ampm;
      if (this.date.getHours() <= 12) {
        hours = this.date.getHours();
        ampm = 'AM';
      } else {
        hours = this.date.getHours() - 12;
        ampm = 'PM';
      }
      this.time = hours + ':' + this.date.getMinutes() + ' ' + ampm;
    }
  }

  changedDate(e: any) {
    this.date = e.value;
    this.emitDateTime();
  }

  // changedTime(e: string) {
  //   this.time = e;
  //   this.cdRef.detectChanges();
  //   this.emitDateTime();
  // }

  emitDateTime() {
    if (this.date) {
      const result = this.date.toDateString();
      const dateTime = new Date(result);
      this.changed.emit(dateTime);
    }
  }
}
