import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { PrivilegeCategoryCreate, PrivilegeCategoryDelete, PrivilegeCategoryRefresh, PrivilegeCategoryUpdate } from './privilege-categories.action';
import { PrivilegeCategory } from 'src/app/components/catalogs/roles/services/role-privilege';
import { PrivilegeCategoryService } from '../../privilege-category.service';

export class PrivilegeCategoryStateModel {
  data: PrivilegeCategory[] = [];
}

@State<PrivilegeCategoryStateModel>({
  name: 'PrivilegeCategories',
  defaults: {
    data: []
  }
})
@Injectable()
export class PrivilegeCategoryState {
  constructor(
    private _service: PrivilegeCategoryService
  ) { }

  @Selector()
  static read(state: PrivilegeCategoryStateModel) {
    return state.data;
  }

  @Action(PrivilegeCategoryRefresh)
  refresh({ patchState }: StateContext<PrivilegeCategoryStateModel>) {
    this._service.getAll().toPromise().then(data => {
      patchState({ data });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(PrivilegeCategoryCreate)
  create({ getState, patchState }: StateContext<PrivilegeCategoryStateModel>, { item }: PrivilegeCategoryCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(PrivilegeCategoryUpdate)
  update({ getState, patchState }: StateContext<PrivilegeCategoryStateModel>, { id, item }: PrivilegeCategoryUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    const currentState = state.data;

    if (index >= 0) {
      currentState.splice(index, 1, item);
      patchState({ data: currentState });
    }
  }

  @Action(PrivilegeCategoryDelete)
  delete({ getState, patchState }: StateContext<PrivilegeCategoryStateModel>, { id }: PrivilegeCategoryDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
