<div class="d-print-flex">
  <mat-form-field
    [ngClass]="{
      hilite: hilite
    }"
  >
    <mat-label>{{ placeholder }}</mat-label>
    <mat-chip-grid #acceleratorLocationsChips [disabled]="disabled">
      <mat-chip-row
        *ngFor="let location of locations"
        [selectable]="true"
        [removable]="true"
        (removed)="removed.emit(location)"
      >
        {{ location.resource == null ? "" : location.resource.name }}
        <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
      </mat-chip-row>
      <input
        #locationInput
        [disabled]="disabled"
        [formControl]="control"
        [matAutocomplete]="locationAutocomplete"
        [matChipInputFor]="acceleratorLocationsChips"
        [matChipInputAddOnBlur]="false"
        (keyup)="onKeyup($event)"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #locationAutocomplete="matAutocomplete"
      (optionSelected)="selected.emit($event)"
    >
      <mat-optgroup
        *ngFor="let group of locationGroups"
        [label]="group.name ?? ''"
      >
        <mat-option *ngFor="let location of group.locations" [value]="location">
          {{ location.name }}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
</div>
