import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MenuItem } from 'src/app/components/head-page/head-menu/head-menu.models';
import { HeadMenuService } from 'src/app/components/head-page/head-menu/head-menu.service';
import { DocumentCategory, DocumentType } from 'src/app/services/documents/documents';
import { MessageTypeEnum } from 'src/app/components/messages/services/message-banner.enums';
import { AbstractControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-document-type-tab',
  templateUrl: './document-type-tab.component.html',
  styleUrls: ['./document-type-tab.component.scss']
})
export class DocumentTypeTabComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() documentType?: DocumentType;
  @Input() isDisabled = true;

  @Output() loading = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<DocumentType>();

  createBuilderMenuEntryLabel!: string;
  createChecklistMenuEntryLabel!: string;
  removeMenuEntryBuilderMenuLabel!: string;
  removeMenuEntryChecklistMenuLabel!: string;

  menuItems$!: Observable<MenuItem[]>;
  menuItems!: MenuItem[];

  menuItemChk?: MenuItem | null;
  menuItemBld?: MenuItem | null;

  menuType = menuType;

  subsMenuItem!: Subscription;
  documentCategory = DocumentCategory;

  public get nameCtrl(): AbstractControl | null {
    return this.formGroup.get("nameCtrl");
  }
  public get descriptionCtrl(): AbstractControl | null {
    return this.formGroup.get("descriptionCtrl");
  }
  public get codeCtrl(): AbstractControl | null {
    return this.formGroup.get("codeCtrl");
  }

  @Output() sendDocumentTemplateId = new EventEmitter<number>();

  constructor(
    protected override injector: Injector,
    private menuService: HeadMenuService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setLabels();
    if (this.documentType) {
      this.menuItemBld = this.getMenuItem(menuType.Builder);
      this.menuItemChk = this.getMenuItem(menuType.Checklist);
    }
    this.enableDisabledForm();
  }

  ngOnInit() {
    this.loadMenuItems();
    this.buildForm();
  }

  loadMenuItems() {
    this.menuItems$ = this.store.select(state => state.MenuItem.data);
    this.subsMenuItem = this.menuItems$.subscribe(data => {
      this.menuItems = data;
      if (this.documentType) {
        this.menuItemBld = this.getMenuItem(menuType.Builder);
        this.menuItemChk = this.getMenuItem(menuType.Checklist);
        this.loading.emit(false);
      }
    });
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      nameCtrl: [{ value: '' }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      descriptionCtrl: [{ value: '' }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      codeCtrl: [{ value: '' }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]]
    });
    this.enableDisabledForm();
  }

  enableDisabledForm() {
    this.formGroup?.markAsPristine();
    this.formGroup?.markAsUntouched();
    if (this.isDisabled)
      this.formGroup?.disable();
    else this.formGroup?.enable();
  }

  isDocumentTypeValid() {
    let result = true;
    if (!this.documentType?.name) {
      this.nameCtrl?.setErrors({ required: true });
      this.alert.message('documentTypeTab_Name_Required');
      result = false;;
    }
    if (!this.documentType?.description) {
      this.descriptionCtrl?.setErrors({ required: true });
      this.alert.message('documentTypeTab_Description_Required');
      result = false;;
    }
    if (!this.documentType?.code) {
      this.codeCtrl?.setErrors({ required: true });
      this.alert.message('documentTypeTab_Code_Required');
      result = false;;
    }
    return result;
  }

  async setLabels() {
    this.createBuilderMenuEntryLabel = this.getMessage('CreateBuilderMenuEntryLabel')?.description;
    this.createChecklistMenuEntryLabel = this.getMessage('CreateChecklistMenuEntryLabel')?.description;
    this.removeMenuEntryBuilderMenuLabel = this.getMessage('RemoveMenuEntryBuilderMenuLabel')?.description;
    this.removeMenuEntryChecklistMenuLabel = this.getMessage('RemoveMenuEntryChecklistMenuLabel')?.description;
  }

  getMenuItem(type: menuType) {
    if (this.documentType?.documentTemplateId) {
      const route = (type === menuType.Builder ? 'checklist-builder/' : 'checklist-exec/') + this.documentType.documentTemplateId;
      return this.menuItems?.find(m => m.route?.toLowerCase() === route);
    }
    return null;
  }

  createMenu(type: menuType) {
    this.loading.emit(true);
    const parent = type === menuType.Builder ? 76 : 3;
    const route = (type === menuType.Builder ? 'checklist-builder/' : 'checklist-exec/') + this.documentType?.documentTemplateId;
    const menuItem: MenuItem = {
      id: 0,
      name: this.documentType?.description,
      description: this.documentType?.description,
      status: 1,
      type: 1,
      code: this.documentType?.code,
      parentMenuItemID: parent,
      order: 999,
      route,
      menuValidation: 0,
      isSeparator: false
    };
    this.menuService.createMenu(menuItem).toPromise().then(data => {
      this.alert.message('MenuItemCreatedSuccess', [], MessageTypeEnum.Success);
    });
  }

  removeMenu(type: menuType) {
    this.loading.emit(true);
    const id = type === menuType.Builder ? this.menuItemBld?.id : this.menuItemChk?.id;
    if (id)
      this.menuService.deleteMenu(id).toPromise().then(() => {
        this.alert.message('MenuItemRemoved', [], MessageTypeEnum.Warning);
      });
  }

  nameChanged(e: any) {
    if (this.documentType) {
      this.documentType.name = e.target.value;
      this.changed.emit(this.documentType);
      this.setFormDirty();
    }
  }

  descriptionChanged(e: any) {
    if (this.documentType) {
      this.documentType.description = e.target.value;
      this.changed.emit(this.documentType);
      this.setFormDirty();
    }
  }

  codeChanged(e: any) {
    const text = e.target.value;
    if (this.documentType) {
      this.documentType.code = this.utils.replaceAll(text?.toUpperCase() ?? '', ' ', '');
      this.changed.emit(this.documentType);
      this.setFormDirty();
    }
  }
}

enum menuType {
  Checklist = 1,
  Builder = 2
}
