<div class="d-print-flex">
  <mat-form-field>
    <input
      matInput
      #procedureInput
      placeholder="{{ placeholder }}"
      [attr.disabled]="disabled == true ? '' : null"
      [formControl]="control"
      [matAutocomplete]="procedureAutocomplete"
      (keyup)="onProcedureInputKeyup($event)"
    />
    <mat-autocomplete
      #procedureAutocomplete="matAutocomplete"
      (optionSelected)="selected($event.option.value)"
    >
      <mat-option
        *ngFor="let procedure of filteredProcedures"
        [value]="procedure"
      >
        {{ procedure?.procedureNumber }} - {{ procedure?.title }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
