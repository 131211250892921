<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col">
        <h5 class="card-title">
          {{ checklistTemplate ? "Edit" : "Create" }} RSS Functional Test
          Template
        </h5>

        <div class="d-flex pt-2 pl-4 full-width" *ngIf="!checklistTemplate">
          <span class="nowrap pt-1">from:</span>
          <mat-radio-group
            [(ngModel)]="createFrom"
            [align]="'start'"
            class="d-flex justify-content-around full-width"
          >
            <mat-radio-button value="1" selected>Scratch</mat-radio-button>
            <mat-radio-button value="2"
              >Existing RSS Functional Test Template</mat-radio-button
            >
            <mat-radio-button value="3"
              >Existing RSS Test Template</mat-radio-button
            >
          </mat-radio-group>
        </div>

        <button mat-mini-fab class="closex" color="warn" (click)="cancel()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="card-body p-0">
    <div class="px-3" *ngIf="!checklistTemplate">
      <div class="row">
        <div class="col">
          <div class="py-3" *ngIf="createFrom == '1'">
            <div class="row">
              <div class="col-5">
                <mat-form-field class="no-subscript">
                  <mat-label>Name</mat-label>
                  <input
                    aria-label="name"
                    matInput
                    type="text"
                    [disabled]="loading || !!checklistTemplate"
                    [(ngModel)]="name"
                    (ngModelChange)="setFormDirty()"
                    [formControl]="nameCtrl"
                  />
                  <mat-error *ngIf="!name">Name cannot be empty!</mat-error>
                </mat-form-field>
              </div>
              <div class="col-3">
                <mat-form-field class="no-subscript">
                  <mat-label>Type</mat-label>
                  <mat-select
                    [disabled]="loading || !!checklistTemplate"
                    [(ngModel)]="templateType"
                    (ngModelChange)="templateType = $event; setFormDirty()"
                  >
                    <mat-option
                      *ngFor="let templateType of templateTypes"
                      [value]="templateType"
                    >
                      {{ templateType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-3">
                <resource-autocomplete
                  *ngIf="templateType?.resourceTypeID"
                  [placeholder]="'Shutter'"
                  [disabled]="loading || !!checklistTemplate"
                  [customResources]="resources"
                  [(resource)]="resource"
                  (selected)="setFormDirty()"
                ></resource-autocomplete>
              </div>

              <div class="col-1">
                <button
                  *ngIf="!checklistTemplate"
                  mat-flat-button
                  color="primary"
                  [disabled]="loading || checkEnableCreate()"
                  (click)="createTemplate()"
                >
                  Create
                </button>
              </div>
            </div>
          </div>

          <div class="mat-container mt-1 mb-3" *ngIf="createFrom == '2'">
            <table mat-table [dataSource]="rssFunctionalTestTemplatesDS">
              <ng-container matColumnDef="version">
                <th mat-header-cell *matHeaderCellDef>
                  <span>Version</span>
                </th>
                <td mat-cell *matCellDef="let e" class="pr-2">
                  <span>{{ e.serialNo }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="resource">
                <th mat-header-cell *matHeaderCellDef>
                  <span>Resource</span>
                </th>
                <td mat-cell *matCellDef="let e" class="pr-2">
                  <span>{{ e.resource?.name }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>
                  <span>Type</span>
                </th>
                <td mat-cell *matCellDef="let e" class="pr-2">
                  <span>{{ e.templateType?.name }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="duplicate">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let e" class="pr-2">
                  <button
                    mat-icon-button
                    color="primary"
                    [matTooltip]="'Duplicate'"
                    (click)="duplicate(e)"
                  >
                    <mat-icon>content_copy</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="accent"
                    [matTooltip]="'View'"
                    (click)="openViewMode(e)"
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="checklistTemplateColumns; sticky: true"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: checklistTemplateColumns"
              ></tr>
            </table>
          </div>

          <div class="mat-container mt-1 mb-3" *ngIf="createFrom == '3'">
            <table mat-table [dataSource]="rssTestTemplatesDS">
              <ng-container matColumnDef="version">
                <th mat-header-cell *matHeaderCellDef>
                  <span>Version</span>
                </th>
                <td mat-cell *matCellDef="let e" class="pr-2">
                  <span>{{ e.serialNo }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="resource">
                <th mat-header-cell *matHeaderCellDef>
                  <span>Resource</span>
                </th>
                <td mat-cell *matCellDef="let e" class="pr-2">
                  <span>{{ e.resource?.name }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>
                  <span>Type</span>
                </th>
                <td mat-cell *matCellDef="let e" class="pr-2">
                  <span>{{ e.templateType?.name }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="duplicate">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let e" class="pr-2">
                  <button
                    mat-icon-button
                    color="primary"
                    [matTooltip]="'Duplicate'"
                    (click)="duplicate(e)"
                  >
                    <mat-icon>content_copy</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="accent"
                    [matTooltip]="'View'"
                    (click)="openViewMode(e)"
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="checklistTemplateColumns; sticky: true"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: checklistTemplateColumns"
              ></tr>
            </table>
          </div>
          <span
            *ngIf="createFrom != '1'"
            class="d-flex align-items-center full-width justify-content-end"
            >Click on <mat-icon class="blue mx-1">content_copy</mat-icon> button
            to Create!</span
          >
        </div>
      </div>
    </div>
    <div class="p-0" *ngIf="checklistTemplate">
      <checklist-builder-reject
        [errorMessages]="errorMessages ?? []"
        *ngIf="errorMessages?.length"
      >
      </checklist-builder-reject>
      <div
        id="scrollableContainer"
        class="sections-container p-2"
        #scrollableContainer
        scrollDetector
      >
        <checklist-builder-sections
          [sections]="sections ?? []"
          [currentVersion]="checklistTemplate"
          [wfTableID]="checklistTemplate.wfTableID"
          [disabled]="false"
        ></checklist-builder-sections>
      </div>
    </div>
  </div>

  <div class="card-footer" *ngIf="checklistTemplate">
    <div mat-dialog-actions align="center" *ngIf="!rssFunctionalTest">
      <button
        mat-flat-button
        color="warn"
        [disabled]="loading"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        class="ml-2"
        [disabled]="
          (loading || (errorMessages?.length ?? 0) > 0) && !isFormDirty()
        "
        (click)="createRSSFunctionalTest()"
      >
        Save
      </button>
    </div>
    <div *ngIf="rssFunctionalTest" class="d-flex justify-content-center">
      <span class="blue">Changes in Template are Saved Automatically</span>
    </div>
  </div>
</div>
