import { BeamlineRestriction } from "../../models";

export class BeamlineRestrictionRefresh {
  static readonly type = '[BeamlineRestriction] Refresh';
}

export class BeamlineRestrictionRefreshByBeamlineID {
  static readonly type = '[BeamlineRestriction] RefreshById';

  constructor(public id: number) { }
}

export class BeamlineRestrictionCreate {
  static readonly type = '[BeamlineRestriction] Create';

  constructor(public item: BeamlineRestriction) { }
}

export class BeamlineRestrictionUpdate {
  static readonly type = '[BeamlineRestriction] Update';

  constructor(public id: number, public item: BeamlineRestriction) { }
}

export class BeamlineRestrictionDelete {
  static readonly type = '[BeamlineRestriction] Delete';

  constructor(public id: number) { }
}
