<mat-expansion-panel
  [hideToggle]="hideToggle || !currentChecklist?.wfTable"
  class="checklist-expansion"
  (afterExpand)="expanded = true"
  (afterCollapse)="expanded = false"
>
  <mat-expansion-panel-header
    class="card-header pb-3 white {{
      currentChecklist?.checklistStatus?.cssClass + '-background'
    }}"
  >
    <div class="row full-width">
      <div class="col-6">
        <span
          *ngIf="currentChecklist?.serialNo != currentChecklist?.name"
          class="h5"
        >
          {{ currentChecklist?.name }}
          <span class="small" *ngIf="currentChecklist?.serialNo">{{
            " (" + currentChecklist?.serialNo + ")"
          }}</span>
        </span>
        <span
          *ngIf="currentChecklist?.serialNo == currentChecklist?.name"
          class="h5"
        >
          {{ currentChecklist?.serialNo }}
        </span>
      </div>
      <div class="col-4">
        <span class="h6">
          {{ currentChecklist?.checklistTemplate?.templateType?.name }}
        </span>
      </div>
    </div>
    <div class="col-2">
      <span class="h6">{{ currentChecklist?.checklistStatus?.name }}</span>
    </div>
  </mat-expansion-panel-header>
  <checklist-sections
    *ngIf="expanded"
    [currentChecklist]="currentChecklist"
    [fullHeight]="true"
    [disabled]="disabled"
  ></checklist-sections>
</mat-expansion-panel>
