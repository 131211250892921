import { Injectable } from '@angular/core';
import { Action, State, Selector, StateContext } from '@ngxs/store';
import { AcceleratorService } from 'src/app/components/home/accelerator-status/accelerator.service';
import { AcceleratorStatusRefresh } from './accelerator-status.action';
import { utils } from 'src/app/modules/libs/utils';
import { Accelerator } from 'src/app/components/catalogs/beamline-catalog/resource/resources';

export class AcceleratorStatusStateModel {
  acceleratorStatus: Accelerator[] | undefined;
}

@State<AcceleratorStatusStateModel>({
  name: 'AcceleratorStatus',
  defaults: {
    acceleratorStatus: []
  }
})
@Injectable()
export class AcceleratorStatusState {
  constructor(
    private acceleratorService: AcceleratorService
  ) { }

  @Selector()
  static read(state: AcceleratorStatusStateModel) {
    return state.acceleratorStatus;
  }

  @Action(AcceleratorStatusRefresh)
  refresh({ patchState }: StateContext<AcceleratorStatusStateModel>) {
    this.acceleratorService.getAcceleratorStatus().toPromise().then(data => {
      patchState({ acceleratorStatus: utils.cloneDeep(data) });
    }).catch(error => {
      console.error(error);
    });
  }

}
