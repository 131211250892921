<fieldset class="field-group">
  <legend>Images</legend>
  <div class="row">
    <div class="col-6">
      <button
        mat-stroked-button
        color="primary"
        (click)="uploader.click()"
        *ngIf="!disabled"
      >
        <mat-icon>add_photo_alternate</mat-icon> Add Image
      </button>
      <form>
        <input
          hidden
          #uploader
          type="file"
          (change)="upload($event)"
          accept="image/*"
        />
      </form>
    </div>
    <div class="col-6" [ngClass]="{ 'col-12': disabled }">
      <div *ngFor="let img of imagesList" class="image-container border p-1">
        <img alt="image" src="{{ img.image }}" height="60px" />
        <div *ngIf="!disabled" class="del-btn small btnx" (click)="delete(img)">
          <mat-icon color="warn">delete_outline</mat-icon>
        </div>
      </div>
    </div>
  </div>
</fieldset>
