import { ChecklistTemplateStatus } from '../../checklists';


export class ChecklistTemplateStatusesRefresh {
  static readonly type = '[ChecklistTemplateStatuses] Refresh';
}

export class ChecklistTemplateStatusesCreate {
  static readonly type = '[ChecklistTemplateStatuses] Create';

  constructor(public item: ChecklistTemplateStatus) { }
}

export class ChecklistTemplateStatusesUpdate {
  static readonly type = '[ChecklistTemplateStatuses] Update';

  constructor(public id: number, public item: ChecklistTemplateStatus) { }
}

export class ChecklistTemplateStatusesDelete {
  static readonly type = '[ChecklistTemplateStatuses] Delete';
  constructor(public id: number) { }
}
