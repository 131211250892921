<div #bodyHeader>
  <form #myForm="ngForm">
    <div class="main-panel px-3 pt-3">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('shutter', 'name')
                }"
                *ngIf="shutter"
              >
                <mat-label>Shutter Name</mat-label>
                <input
                  matInput
                  [(ngModel)]="shutter.name"
                  [disabled]="!(adding || modifying)"
                  name="shutterName"
                  (change)="setShutterSelected(); setFormDirty()"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('beamline', 'name')
                }"
                *ngIf="beamline"
              >
                <mat-label>Beamline (Location)</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamline.name"
                  [disabled]="!(adding || modifying)"
                  name="beamlineLocation"
                  (change)="setFormDirty()"
                />
              </mat-form-field>
            </div>
          </div>
          <form [formGroup]="formGroup" *ngIf="hasShutter">
            <div class="row">
              <div class="col-12 col-sm-6">
                <mat-form-field
                  appearance="outline"
                  [ngClass]="{
                    hilite: isModified('shutter', 'rssChannelName')
                  }"
                  *ngIf="shutter"
                >
                  <mat-label>RSS Channel Name</mat-label>
                  <input
                    matInput
                    stripSpaces
                    [(ngModel)]="shutter.rssChannelName"
                    [disabled]="!(adding || modifying)"
                    name="rssChannelName"
                    [formControl]="rssCtrl"
                    (change)="
                      validateChannel(shutter.rssChannelName, rssCtrl, 'rss')
                    "
                  />
                  <mat-error *ngIf="rssCtrl.errors">{{
                    rssCtrl.errors["message"]
                  }}</mat-error>
                </mat-form-field>
                <span class="value" [ngClass]="{ red: rssCtrl.errors }">{{
                  rssValue
                }}</span>
              </div>
              <div class="col-12 col-sm-6">
                <mat-form-field
                  appearance="outline"
                  [ngClass]="{
                    hilite: isModified('shutter', 'pssChannelName')
                  }"
                  *ngIf="shutter"
                >
                  <mat-label>PSS Channel Name</mat-label>
                  <input
                    matInput
                    stripSpaces
                    [(ngModel)]="shutter.pssChannelName"
                    [disabled]="!(adding || modifying)"
                    name="pssChannelName"
                    [formControl]="pssCtrl"
                    (change)="
                      validateChannel(shutter.pssChannelName, pssCtrl, 'pss')
                    "
                  />
                  <mat-error *ngIf="pssCtrl.errors">{{
                    pssCtrl.errors["message"]
                  }}</mat-error>
                </mat-form-field>
                <span class="value" [ngClass]="{ red: pssCtrl.errors }">{{
                  pssValue
                }}</span>
              </div>
            </div>
          </form>

          <div class="row">
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('beamline', 'description')
                }"
                *ngIf="beamline"
              >
                <mat-label>Beamline Name</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamline.description"
                  [disabled]="!(adding || modifying)"
                  name="beamlineDescription"
                  (change)="setFormDirty()"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('beamline', 'beamLineExtension')
                }"
                *ngIf="beamline"
              >
                <mat-label>Extension</mat-label>
                <input
                  matInput
                  [(ngModel)]="beamline.beamLineExtension"
                  [disabled]="!(adding || modifying)"
                  name="beamlineExtension"
                  (change)="setFormDirty()"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-6 d-flex" *ngIf="beamline">
              <fieldset
                class="field-group full-width"
                [ngClass]="{
                  hilite: isModified('beamline', 'statusResourceId')
                }"
              >
                <legend>Beamline Status</legend>
                <mat-radio-group
                  class="d-flex justify-content-between pr-2 full-width"
                  aria-label="Select an option"
                  [(ngModel)]="beamline.statusResourceId"
                  [disabled]="!(adding || modifying)"
                  name="status"
                  (change)="setFormDirty()"
                >
                  <mat-radio-button
                    *ngFor="let status of resourceStatuses"
                    [value]="status.id"
                  >
                    {{ status.name }}
                  </mat-radio-button>
                </mat-radio-group>
              </fieldset>
            </div>
          </div>
          <div class="mt-3 mb-3">
            <div>Beamline Operational Restrictions</div>
            <operational-restrictions
              [beamline]="beamline"
            ></operational-restrictions>
          </div>
          <div class="row mt-2">
            <div class="col-12 col-sm-12">
              <mat-label>Endstations</mat-label>
            </div>
          </div>
          <div class="row mt-1 mb-4">
            <div class="col-12 col-sm-12">
              <div
                class="mat-container"
                [ngClass]="{
                  hilite: isModified('summary', 'endstations')
                }"
              >
                <mat-table
                  cdkDropList
                  #table
                  [dataSource]="dataSourceEndStation"
                  [cdkDropListDisabled]="!modifying"
                  cdkDropListLockAxis="y"
                >
                  <ng-container matColumnDef="endstation">
                    <mat-header-cell *matHeaderCellDef>
                      Endstation
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{
                      element.name
                    }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="absiTemplateType">
                    <mat-header-cell *matHeaderCellDef>
                      ABSI Checklist Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{
                      element.absiTemplateName
                    }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="add">
                    <mat-header-cell *matHeaderCellDef>
                      <mat-icon
                        mat-list-icon
                        class="add"
                        (click)="openAddEndstationDialog()"
                        >add</mat-icon
                      >
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let element; let elementIndex = index"
                    >
                      <mat-icon
                        mat-list-icon
                        class="edit"
                        (click)="
                          openEditEndstationDialog(element, elementIndex)
                        "
                        >edit</mat-icon
                      >
                      <mat-icon
                        mat-list-icon
                        class="delete"
                        (click)="deleteEndstation(element)"
                        >delete</mat-icon
                      >
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="getColumns(resourceType.Endstation)"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="
                      let row;
                      columns: getColumns(resourceType.Endstation)
                    "
                    [ngClass]="{
                      'added-row': row.isAdded,
                      'deleted-row': row.isDeleted,
                      'modified-row':
                        row.isModified && !row.isAdded && !row.isDeleted
                    }"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>Branchlines</mat-label>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-sm-12">
              <div
                class="mat-container"
                [ngClass]="{
                  hilite: isModified('summary', 'branchlines')
                }"
              >
                <mat-table
                  cdkDropList
                  #table
                  [dataSource]="dataSourceBranchline"
                  [cdkDropListDisabled]="!modifying"
                  cdkDropListLockAxis="y"
                >
                  <ng-container matColumnDef="branchline">
                    <mat-header-cell *matHeaderCellDef>
                      Branchline
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{
                      element.name
                    }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="add">
                    <mat-header-cell *matHeaderCellDef>
                      <mat-icon
                        mat-list-icon
                        class="add"
                        (click)="openAddBranchlineDialog()"
                        >add</mat-icon
                      >
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let element; let elementIndex = index"
                    >
                      <mat-icon
                        mat-list-icon
                        class="edit"
                        (click)="
                          openEditBranchlineDialog(element, elementIndex)
                        "
                        >edit</mat-icon
                      >
                      <mat-icon
                        mat-list-icon
                        class="delete"
                        (click)="deleteBranchline(element)"
                        >delete</mat-icon
                      >
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="getColumns(resourceType.Branchline)"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="
                      let row;
                      columns: getColumns(resourceType.Branchline)
                    "
                    [ngClass]="{
                      'added-row': row.isAdded,
                      'deleted-row': row.isDeleted,
                      'modified-row':
                        row.isModified && !row.isAdded && !row.isDeleted
                    }"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
          <div class="mb-4" *ngIf="hasShutter">
            <div class="row">
              <div class="col-12 col-sm-12">
                <mat-label>Restrictions</mat-label>
              </div>
            </div>
            <app-restriction-table
              [isEditionChild]="adding || modifying"
              [restrictionsChild]="restrictionsParent"
              [branchlinesChild]="branchlineParent"
              [shutterChild]="shutter"
              [hilite]="isModified('summary', 'restrictions')"
              (emitData)="getDataFromRestriction($event); setFormDirty()"
            >
            </app-restriction-table>
          </div>
          <div class="mb-4">
            <div class="row">
              <div class="col-12 col-sm-12">
                <mat-label>Top-off Critical Apertures</mat-label>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-sm-12">
                <div
                  class="mat-container"
                  [ngClass]="{
                    hilite: isModified('summary', 'toca')
                  }"
                >
                  <mat-table #table [dataSource]="dataSourceTOCA">
                    <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef>
                        TOCAs
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element"
                        >{{ element.name }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="showChanges">
                      <mat-header-cell *matHeaderCellDef>
                        <mat-slide-toggle
                          [(ngModel)]="showChangesTOCA"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="onShowChangesTOCA()"
                        >
                          Show Changes
                        </mat-slide-toggle>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element"></mat-cell>
                    </ng-container>
                    <mat-header-row
                      *matHeaderRowDef="getColumns(resourceType.TOCA)"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="
                        let row;
                        columns: getColumns(resourceType.TOCA)
                      "
                      [ngClass]="{
                        'added-row': row.isAdded,
                        'deleted-row': row.isDeleted,
                        'modified-row':
                          row.isModified && !row.isAdded && !row.isDeleted
                      }"
                    ></mat-row>
                  </mat-table>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="hasShutter">
            <div class="row">
              <div class="col-12 col-sm-12">
                <mat-label>Shielding</mat-label>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-sm-12">
                <div
                  class="mat-container"
                  [ngClass]="{
                    hilite: isModified('summary', 'shieldings')
                  }"
                >
                  <mat-table
                    cdkDropList
                    #table
                    [dataSource]="dataSourceShielding"
                    [cdkDropListData]="dataSourceShielding"
                    (cdkDropListDropped)="
                      reorderTable($event, dataSourceShielding)
                    "
                    [cdkDropListDisabled]="!modifying"
                    cdkDropListLockAxis="y"
                  >
                    <ng-container matColumnDef="position">
                      <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.position }}
                        <!--<mat-icon mat-list-icon class="reorder">reorder</mat-icon>-->
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="shieldingName">
                      <mat-header-cell *matHeaderCellDef>
                        Shielding Name
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.name }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="keText">
                      <mat-header-cell *matHeaderCellDef>
                        KE Text
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.keText }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="visible">
                      <mat-header-cell *matHeaderCellDef>
                        Visible?
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        <mat-slide-toggle
                          [(ngModel)]="element.checkboxVisible"
                          [ngModelOptions]="{ standalone: true }"
                          [disabled]="!(adding || modifying)"
                          (toggleChange)="setFormDirty()"
                        >
                        </mat-slide-toggle>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="alsoRelated">
                      <mat-header-cell *matHeaderCellDef>
                        Shared With
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.sharedWith }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="isRSSD">
                      <mat-header-cell *matHeaderCellDef>
                        is RSSD?
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        <mat-slide-toggle
                          [(ngModel)]="element.isRSSD"
                          [ngModelOptions]="{ standalone: true }"
                          [disabled]="!(adding || modifying)"
                          (toggleChange)="setFormDirty()"
                        >
                        </mat-slide-toggle>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="add">
                      <mat-header-cell *matHeaderCellDef>
                        <mat-icon
                          mat-list-icon
                          class="add"
                          (click)="openAddShieldingDialog()"
                          >add</mat-icon
                        >
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        <mat-icon
                          mat-list-icon
                          class="edit"
                          (click)="openEditShieldingDialog(element)"
                          >edit</mat-icon
                        >
                        <mat-icon
                          mat-list-icon
                          class="delete"
                          (click)="deleteShielding(element)"
                          >delete</mat-icon
                        >
                      </mat-cell>
                    </ng-container>
                    <mat-header-row
                      *matHeaderRowDef="getColumns(resourceType.Shielding)"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="
                        let row;
                        columns: getColumns(resourceType.Shielding)
                      "
                      cdkDrag
                      [cdkDragData]="row"
                      [ngClass]="{
                        'added-row': row.isAdded,
                        'deleted-row': row.isDeleted,
                        'modified-row':
                          row.isModified && !row.isAdded && !row.isDeleted
                      }"
                    >
                    </mat-row>
                  </mat-table>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="hasShutter">
            <div class="row">
              <div class="col-12 col-sm-12">
                <mat-label>Vacuum</mat-label>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-sm-12">
                <div
                  class="mat-container"
                  [ngClass]="{
                    hilite: isModified('summary', 'vacuums')
                  }"
                >
                  <mat-table
                    cdkDropList
                    #table
                    [dataSource]="dataSourceVacuum"
                    [cdkDropListData]="dataSourceVacuum"
                    (cdkDropListDropped)="
                      reorderTable($event, dataSourceVacuum)
                    "
                    [cdkDropListDisabled]="!modifying"
                    cdkDropListLockAxis="y"
                  >
                    <ng-container matColumnDef="position">
                      <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.position }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="vacuumName">
                      <mat-header-cell *matHeaderCellDef>
                        Vacuum Name
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element"
                        >{{ element.name }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="minPressure">
                      <mat-header-cell *matHeaderCellDef>
                        Min Pressure
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.MinPressureString }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="maxPressure">
                      <mat-header-cell *matHeaderCellDef>
                        Max Pressure
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.MaxPressureString }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="channelName">
                      <mat-header-cell *matHeaderCellDef>
                        Channel Name (PV)
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.channelName }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="location">
                      <mat-header-cell *matHeaderCellDef>
                        Location
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.location }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="alsoRelated">
                      <mat-header-cell *matHeaderCellDef>
                        Shared With
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.sharedWith }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="add">
                      <mat-header-cell *matHeaderCellDef>
                        <mat-icon
                          mat-list-icon
                          class="add"
                          (click)="openAddVacuumDialog()"
                          >add</mat-icon
                        >
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        <mat-icon
                          mat-list-icon
                          class="edit"
                          (click)="openEditVacuumDialog(element)"
                          >edit</mat-icon
                        >
                        <mat-icon
                          mat-list-icon
                          class="delete"
                          (click)="deleteVacuum(element)"
                          >delete</mat-icon
                        >
                      </mat-cell>
                    </ng-container>
                    <mat-header-row
                      *matHeaderRowDef="getColumns(resourceType.Vacuum)"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="
                        let row;
                        columns: getColumns(resourceType.Vacuum)
                      "
                      [ngClass]="{
                        'added-row': row.isAdded,
                        'deleted-row': row.isDeleted,
                        'modified-row':
                          row.isModified && !row.isAdded && !row.isDeleted
                      }"
                      cdkDrag
                      [cdkDragData]="row"
                    ></mat-row>
                  </mat-table>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>Organizations</mat-label>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-sm-12">
              <div
                class="mat-container"
                [ngClass]="{
                  hilite: isModified('summary', 'organizations')
                }"
              >
                <mat-table #table [dataSource]="dataSourceOrganization">
                  <ng-container matColumnDef="organizationName">
                    <mat-header-cell *matHeaderCellDef>
                      Organizations
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.organization?.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="endstation">
                    <mat-header-cell *matHeaderCellDef>
                      Endstation
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.endstation?.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="branchline">
                    <mat-header-cell *matHeaderCellDef>
                      Branchline
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.branchline?.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="add">
                    <mat-header-cell *matHeaderCellDef>
                      <mat-icon
                        mat-list-icon
                        class="add"
                        (click)="openAddOrganizationDialog()"
                        >add</mat-icon
                      >
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let element; let elementIndex = index"
                    >
                      <mat-icon
                        mat-list-icon
                        class="edit"
                        (click)="
                          openEditOrganizationDialog(element, elementIndex)
                        "
                        >edit</mat-icon
                      >
                      <mat-icon
                        mat-list-icon
                        class="delete"
                        (click)="deleteOrganization(element)"
                        >delete</mat-icon
                      >
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="getColumns(resourceType.Organization)"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="
                      let row;
                      columns: getColumns(resourceType.Organization)
                    "
                    [ngClass]="{
                      'added-row': row.isAdded,
                      'deleted-row': row.isDeleted,
                      'modified-row':
                        row.isModified && !row.isAdded && !row.isDeleted
                    }"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>Supervisors</mat-label>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-sm-12">
              <app-resource-user-relation-table
                [isEdition]="adding || modifying"
                [userTitle]="'Supervisors'"
                [dialogTitle]="'Supervisor'"
                [roleTitle]="'Supervisor Name'"
                [roleSelected]="53"
                [resourceUserRelations]="dataSourceSupervisor.data"
                [branchlines]="dataSourceBranchline.data"
                [endstations]="dataSourceEndStation.data"
                [prev]="resourceSummary?.supervisors"
                [curr]="currentResourceSummary?.supervisors"
                (changed)="setFormDirty()"
                [hilite]="isModified('summary', 'supervisors')"
              >
              </app-resource-user-relation-table>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>BL Scientists</mat-label>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-sm-12">
              <app-resource-user-relation-table
                [isEdition]="adding || modifying"
                [userTitle]="'BL Scientists'"
                [dialogTitle]="'BL Scientist'"
                [roleTitle]="'Scientist Name'"
                [roleSelected]="8"
                [resourceUserRelations]="dataSourceScientist.data"
                [branchlines]="dataSourceBranchline.data"
                [endstations]="dataSourceEndStation.data"
                [prev]="resourceSummary?.scientists"
                [curr]="currentResourceSummary?.scientists"
                (changed)="setFormDirty()"
                [hilite]="isModified('summary', 'scientists')"
              >
              </app-resource-user-relation-table>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>Alternate BL Scientists</mat-label>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-sm-12">
              <app-resource-user-relation-table
                [isEdition]="adding || modifying"
                [userTitle]="'Alternate BL Scientists'"
                [dialogTitle]="'Alternate BL Scientist'"
                [roleTitle]="'Alternate Scientist Name'"
                [roleSelected]="47"
                [resourceUserRelations]="dataSourceAlternateScientist.data"
                [branchlines]="dataSourceBranchline.data"
                [endstations]="dataSourceEndStation.data"
                [prev]="resourceSummary?.alternateScientists"
                [curr]="currentResourceSummary?.alternateScientists"
                (changed)="setFormDirty()"
                [hilite]="isModified('summary', 'alternateScientists')"
              >
              </app-resource-user-relation-table>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <mat-label>Beamline Notes</mat-label>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-sm-12">
              <div
                class="mat-container"
                [ngClass]="{
                  hilite: isModified('summary', 'notes')
                }"
              >
                <mat-table #table [dataSource]="dataSourceNote">
                  <ng-container matColumnDef="note">
                    <mat-header-cell *matHeaderCellDef>
                      Beamline Notes
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"
                      >{{ element.noteName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="add">
                    <mat-header-cell *matHeaderCellDef>
                      <mat-icon
                        mat-list-icon
                        class="add"
                        (click)="openAddNoteDialog()"
                        >add</mat-icon
                      >
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-icon
                        mat-list-icon
                        class="edit"
                        (click)="openEditNoteDialog(element)"
                        >edit</mat-icon
                      >
                      <mat-icon
                        mat-list-icon
                        class="delete"
                        (click)="deleteNote(element)"
                        >delete</mat-icon
                      >
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="getColumns(resourceType.Note)"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: getColumns(resourceType.Note)"
                    [ngClass]="{
                      'added-row': row.isAdded,
                      'deleted-row': row.isDeleted,
                      'modified-row':
                        row.isModified && !row.isAdded && !row.isDeleted
                    }"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
          <div>
            <div
              [ngClass]="{
                hilite: isModified('summary', 'meDrawing')
              }"
            >
              <app-resource-link-relation
                [dialogTitle]="'ME Drawing Link'"
                [componentTitle]="'ME Drawing Links'"
                [typeLink]="2"
                [links]="dataSourceMEDrawing.data"
                [isEdition]="false"
                [resourceId]="beamlineShutter?.beamlineId"
                [changesAvailable]="true"
                (changed)="setFormDirty()"
              >
              </app-resource-link-relation>
            </div>
            <div
              [ngClass]="{
                hilite: isModified('summary', 'apPhaseSpace')
              }"
            >
              <app-resource-link-relation
                [dialogTitle]="'AP PhaseSpace Link'"
                [componentTitle]="'AP PhaseSpace Links'"
                [typeLink]="3"
                [links]="dataSourceAPPhaseSpace.data"
                [isEdition]="false"
                [resourceId]="beamlineShutter?.beamlineId"
                [changesAvailable]="true"
                (changed)="setFormDirty()"
              >
              </app-resource-link-relation>
            </div>
            <div
              [ngClass]="{
                hilite: isModified('summary', 'saSurvey')
              }"
            >
              <app-resource-link-relation
                [dialogTitle]="'SA Survey Link'"
                [componentTitle]="'SA Survey Links'"
                [typeLink]="4"
                [links]="dataSourceSASurvey.data"
                [isEdition]="false"
                [resourceId]="beamlineShutter?.beamlineId"
                [changesAvailable]="true"
                (changed)="setFormDirty()"
              >
              </app-resource-link-relation>
            </div>
          </div>
          <div
            *ngIf="hasShutter"
            [ngClass]="{
              hilite: isModified('summary', 'otherLinks')
            }"
          >
            <app-resource-link-relation
              [dialogTitle]="'Link'"
              [componentTitle]="'Other Links'"
              [typeLink]="1"
              [links]="dataSourceOtherLinks.data"
              [isEdition]="adding || modifying"
              (changed)="setFormDirty()"
            >
            </app-resource-link-relation>
          </div>
          <div class="row" *ngIf="hasShutter">
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('shutter', 'rssRackLocation')
                }"
                *ngIf="shutter"
              >
                <mat-label>RSS Rack Location</mat-label>
                <input
                  matInput
                  [(ngModel)]="shutter.rssRackLocation"
                  [disabled]="!(adding || modifying)"
                  name="rssRackLocation"
                  (change)="setFormDirty()"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('shutter', 'epsRackLocation')
                }"
                *ngIf="shutter"
              >
                <mat-label>EPS Rack Location</mat-label>
                <input
                  matInput
                  [(ngModel)]="shutter.epsRackLocation"
                  [disabled]="!(adding || modifying)"
                  name="epsRackLocation"
                  (change)="setFormDirty()"
                />
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="hasShutter">
            <div class="row">
              <div class="col-12 col-sm-12">
                <mat-label>Fast Valve Sensors</mat-label>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12">
                <div
                  class="mat-container"
                  [ngClass]="{
                    hilite: isModified('summary', 'fastValveSensors')
                  }"
                >
                  <mat-table
                    cdkDropList
                    #table
                    [dataSource]="dataSourceFastValveSensor"
                    [cdkDropListData]="dataSourceFastValveSensor"
                    (cdkDropListDropped)="
                      reorderTable($event, dataSourceFastValveSensor)
                    "
                    [cdkDropListDisabled]="!modifying"
                    cdkDropListLockAxis="y"
                  >
                    <ng-container matColumnDef="position">
                      <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.position }}
                        <!--<mat-icon mat-list-icon class="reorder">reorder</mat-icon>-->
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="fvsName">
                      <mat-header-cell *matHeaderCellDef>
                        FVS Name
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.name }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="keText">
                      <mat-header-cell *matHeaderCellDef>
                        KE Text
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.keText }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="add">
                      <mat-header-cell *matHeaderCellDef>
                        <mat-icon
                          mat-list-icon
                          class="add"
                          (click)="openAddFastValveSensorDialog()"
                          >add</mat-icon
                        >
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        <mat-icon
                          mat-list-icon
                          class="edit"
                          (click)="openEditFastValveSensorDialog(element)"
                          >edit</mat-icon
                        >
                        <mat-icon
                          mat-list-icon
                          class="delete"
                          (click)="deleteFastValveSensor(element)"
                          >delete</mat-icon
                        >
                      </mat-cell>
                    </ng-container>
                    <mat-header-row
                      *matHeaderRowDef="
                        getColumns(resourceType.FastValveSensor)
                      "
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="
                        let row;
                        columns: getColumns(resourceType.FastValveSensor)
                      "
                      [ngClass]="{
                        'added-row': row.isAdded,
                        'deleted-row': row.isDeleted,
                        'modified-row':
                          row.isModified && !row.isAdded && !row.isDeleted
                      }"
                      cdkDrag
                      [cdkDragData]="row"
                    ></mat-row>
                  </mat-table>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4" *ngIf="hasShutter">
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('shutter', 'fvsemLocation')
                }"
                *ngIf="shutter"
              >
                <mat-label>FVS Electronics Module Location</mat-label>
                <input
                  matInput
                  [(ngModel)]="shutter.fvsemLocation"
                  [disabled]="!(adding || modifying)"
                  name="fvsElectronicsModuleLocation"
                  (change)="setFormDirty()"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6">
              <mat-form-field
                appearance="outline"
                [ngClass]="{
                  hilite: isModified('shutter', 'drawingPostLocation')
                }"
                *ngIf="shutter"
              >
                <mat-label>EPS Drawing Location</mat-label>
                <input
                  matInput
                  [(ngModel)]="shutter.drawingPostLocation"
                  [disabled]="!(adding || modifying)"
                  name="postedDrawingLocation"
                  (change)="setFormDirty()"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="sidenav-content-buttons">
  <div class="row no-gutters" *ngIf="!showHistory">
    <div class="col-6">
      <button
        mat-flat-button
        color="warn"
        (click)="cancelChanges()"
        *ngIf="showCancelButton && !(modifying || adding || deleting)"
      >
        Cancel
      </button>
      <button
        mat-stroked-button
        color="warn"
        (click)="cancel()"
        [disabled]="!isFormDirty() && !adding"
        *ngIf="modifying || adding || deleting"
      >
        Cancel
      </button>
    </div>
    <div class="col-6">
      <button
        mat-flat-button
        color="accent"
        class="right-btn"
        *ngIf="showModifyButton && !modifying"
        (click)="modifyResource()"
        style="width: 100px"
      >
        Modify
      </button>
      <button
        mat-flat-button
        color="primary"
        class="right-btn"
        *ngIf="adding || deleting || modifying"
        [disabled]="!isFormDirty()"
        (click)="saveResource()"
        style="width: 100px"
      >
        Save
      </button>
      <button
        mat-flat-button
        color="primary"
        class="right-btn"
        *ngIf="showApproveDisapprove"
        (click)="approve()"
        style="width: 100px"
      >
        Approve
      </button>
      <button
        mat-flat-button
        color="warn"
        class="right-btn"
        *ngIf="showApproveDisapprove"
        (click)="openAddDisapprovalDialog()"
        style="width: 100px"
      >
        Disapprove
      </button>
    </div>
  </div>

  <catalog-history-navigation
    *ngIf="showHistory"
    [catalogs]="beamlineHistory"
    (selectedBeamline)="selectedHistory($event)"
  ></catalog-history-navigation>
</div>
