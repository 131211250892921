import { Component, OnInit, EventEmitter, Output, OnChanges, SimpleChanges, Input, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { YesNoDialogComponent } from "src/app/controls/yes-no-dialog/yes-no-dialog.component";
import { MessageBannerService } from "src/app/components/messages/services/message-banner.service";


@Component({
  selector: "cl-editor-image",
  templateUrl: "./cl-editor-image.component.html",
  styleUrls: ["./cl-editor-image.component.scss"],
})
export class ClEditorImageComponent implements OnInit, OnChanges {
  @Output() changed = new EventEmitter<listImages[]>();
  @Input() disabled?: boolean;
  @Input() configuration?: string;
  @ViewChild("uploader") uploader: { nativeElement: any; } | undefined;

  imagesList: listImages[] = [];

  constructor(
    public dialog: MatDialog,
    public _dMessage: MessageBannerService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.configuration) {
      const configuration = JSON.parse(this.configuration);
      if (configuration.images) {
        this.imagesList = configuration.images;
      }
    }
  }

  ngOnInit() { }

  upload(e: any): void {
    const imageList = this.imagesList;
    const imageId = this.nextId(this.imagesList);
    const changed = this.changed;
    const render = new FileReader();
    render.onload = async function (event) {
      const img = new Image();
      img.onload = function () {
        imageList.push({
          id: imageId,
          name: e.target.files[0].name,
          image: img.src,
          imageWidth: img.width,
          imageHeight: img.height,
        });
        changed.emit(imageList);
      };
      const src = event.target?.result?.toString();
      if (src)
        img.src = src;
    };
    render.readAsDataURL(e.target.files[0]);
  }

  delete(img: any) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "300px",
      data: {
        message: this._dMessage.get("Rad_Survey_Image_Component_Delete_Image")
          .description,
        icon: "stop",
      },
    });

    confirm.afterClosed().subscribe((data) => {
      if (data != null) {
        const imageList = this.imagesList.filter(
          (item) => item.image != img.image
        );
        const myuploader = this.uploader?.nativeElement;
        myuploader.value = "";
        this.changed.emit(imageList);
      }
    });
  }

  nextId(listImages: listImages[]): number {
    let _id = 0;
    if (listImages.length > 0) {
      const uniqueIds = [...new Set(listImages.map((obj) => obj.id))];
      let arrayIds: any[] = [];
      uniqueIds.map((n) => {
        arrayIds.push(n);
      });
      arrayIds = arrayIds.sort((n1, n2) => n1 - n2);
      _id = Number(Math.max.apply(null, arrayIds)) + 1;
    } else {
      _id = 1;
    }
    return _id;
  }
}

export class listImages {
  id?: number;
  name?: string;
  image?: string;
  imageWidth?: number;
  imageHeight?: number;
}
