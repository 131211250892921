import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Catalog } from 'src/app/common/enumerations/enumerations';
import { Servers } from 'src/app/common/enumerations/servers-enum';
import { DisapprovalReasonComponent } from 'src/app/controls/disapproval-reason/disapproval-reason.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { UserCatalog, User, UserCatalogDetails } from 'src/app/components/catalogs/user-catalog/services/user';
import { CatalogModel } from '../../catalog-model';
import { CatalogService } from '../../catalog-service';
import { Organization } from '../../beamline-catalog/dialogs/organization/organization';
import { OrganizationService } from '../../beamline-catalog/dialogs/organization/organization.service';

@Component({
  selector: 'user-catalog-details',
  templateUrl: './user-catalog-details.component.html',
  styleUrls: ['./user-catalog-details.component.scss']
})
export class UserCatalogDetailsComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() userCatalog?: UserCatalog;
  @Input() showModification?: boolean;

  @Output() loading = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<number>();
  @Output() changed = new EventEmitter<boolean>();

  catalogModel!: CatalogModel;

  errorMsg!: string[];
  disabled: boolean = true;

  oldUserName!: string;

  canModify!: boolean;
  canApprove!: boolean;
  isEditing!: boolean;
  isCreating!: boolean;
  canCancel!: boolean;

  valid!: boolean;

  formGroupSubs!: Subscription;

  supervisors!: User[];

  roles!: Role[];
  allRoles!: Role[];
  rolesFiltered!: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  public displayedColumns: string[] = ['supervisor'];
  public dataSource!: MatTableDataSource<User>;

  public statusList: any = [{ name: 'Active', id: 1 }, { name: 'Inactive', id: 0 }];
  public organizations!: Organization[];

  public disabledStatus!: boolean;
  public showSupervisor = false;
  public userSupervised!: User[];

  showRoleNames: boolean = false;

  public get nameCtrl(): AbstractControl | null { return this.formGroup.get('nameCtrl'); }
  public get userCtrl(): AbstractControl | null { return this.formGroup.get('userCtrl'); }
  public get emailCtrl(): AbstractControl | null { return this.formGroup.get('emailCtrl'); }
  public get phoneCtrl(): AbstractControl | null { return this.formGroup.get('phoneCtrl'); }
  public get mobileCtrl(): AbstractControl | null { return this.formGroup.get('mobileCtrl'); }
  public get initialsCtrl(): AbstractControl | null { return this.formGroup.get('initialsCtrl'); }
  public get statusCtrl(): AbstractControl | null { return this.formGroup.get('statusCtrl'); }
  public get issupCtrl(): AbstractControl | null { return this.formGroup.get('issupCtrl'); }
  public get organizationCtrl(): AbstractControl | null { return this.formGroup.get('organizationCtrl'); }
  public get supervisorCtrl(): AbstractControl | null { return this.formGroup.get('supervisorCtrl'); }
  public get rolesCtrl(): AbstractControl | null { return this.formGroup.get('rolesCtrl'); }

  constructor(
    protected override injector: Injector,
    private catalogService: CatalogService,
    private _organizationService: OrganizationService,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.rolesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  async ngOnInit() {
    this.initializeForm();
    this.clear(true);
    this.loadRoles();
    this.loadOrganizations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initializeForm();
    this.getDetails(this.userCatalog);
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      if (data.length) {
        this.allRoles = data;
        this.rolesFiltered = data;
      }
    });
  }

  ngAfterViewInit(): void {

  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      nameCtrl: ['', [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      userCtrl: ['', [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      emailCtrl: ['', [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      phoneCtrl: [''],
      initialsCtrl: ['', [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      statusCtrl: [0],
      mobileCtrl: [''],
      issupCtrl: [false],
      organizationCtrl: [0],
      supervisorCtrl: [0],
      rolesCtrl: [{ value: 0, disabled: true, validators: [Validators.required, this.utils.IsWhiteSpaceReactiveForm] }],
    });

    this.changed.emit(false);
    this.formGroupSubs = this.formGroup.valueChanges.subscribe(formValue => {
      if (formValue) {
        this.setFormDirty(this.formGroup.dirty);
      }
    });
    this.disableControls();
  }

  disableControls(disable: boolean = true) {
    this.disabled = disable;
    if (disable) {
      this.formGroup.disable();
      this.rolesCtrl?.disable();
    }
    else {
      this.formGroup.enable();
      this.rolesCtrl?.enable();
    }
  }

  async getDetails(userCatalog?: UserCatalog) {
    this.isCreating = false;
    this.isEditing = false;
    this.formGroup?.markAsPristine();

    if (userCatalog && !userCatalog?.id) {
      this.clear();
      this.disableControls(false);
      this.canModify = this.hasPrivilege(PrivilegeEnum.UserCatalogCreate);
      this.isCreating = true;
      this.statusCtrl?.setValue(1);
    }
    else if (userCatalog?.id) {
      this.loading.emit(true);
      this.userCatalog = userCatalog;
      this.canCancel = this.userCatalog.pendingUser && this.currentUser?.id == this.userCatalog.pendingUser?.createdBy;
      if (this.userCatalog.pendingUser && this.showModification) {
        this.setValues(this.userCatalog.pendingDetails);
      }
      else {
        this.setValues(this.userCatalog.details);
      }
      this.canModify = !this.userCatalog.pendingUser && this.hasPrivilege(PrivilegeEnum.UserCatalogCreate);
      this.canApprove = this.userCatalog.pendingUser && this.userCatalog.pendingUser.createdBy != this.currentUser?.id && this.hasPrivilege(PrivilegeEnum.UserCatalogApprove);
      this.loading.emit(false);
    } else if (this.userCatalog && (userCatalog?.id ?? 0) < 0) {
      this.loading.emit(true);
      this.setValues(this.userCatalog.pendingDetails);
      this.canModify = false;
      this.canApprove = this.userCatalog.pendingUser.createdBy != this.currentUser?.id && this.hasPrivilege(PrivilegeEnum.UserCatalogApprove);
      this.canCancel = this.userCatalog.pendingUser && this.currentUser?.id == this.userCatalog.pendingUser?.createdBy;
      this.loading.emit(false);
    }
    else {
      this.clear();
    }
  }

  compareName(e: any, type: number) {
    // if user name is already taken:
    const value = e.target.value.toString().toLowerCase();
    switch (type) {
      case 1: //name
        if (this.catalogService.userCatalogs?.find(x => x.details.user.name?.toLowerCase() == value || x.pendingDetails?.user?.name?.toLowerCase() == value)) {
          this.nameCtrl?.setErrors({ 'nameTaken': true });
        }
        else {
          this.nameCtrl?.setErrors(null);
        }
        break;
      case 2: //username
        if (this.catalogService.userCatalogs?.find(x => x.details.user.username?.toLowerCase() == value || x.pendingDetails?.user?.username?.toLowerCase() == value)) {
          this.userCtrl?.setErrors({ 'usernameTaken': true });
        }
        else {
          this.userCtrl?.setErrors(null);
        }
        break;
      case 3: //email
        if (document.location.hostname === Servers.PRODUCTION && (this.catalogService.userCatalogs?.find(x => x.details.user.emailAddress?.toLowerCase() == value || x.pendingDetails?.user?.emailAddress?.toLowerCase() == value))) {
          this.emailCtrl?.setErrors({ 'emailTaken': true });
        }
        else {
          this.emailCtrl?.setErrors(null);
        }
        break;
    }
  }

  async save() {
    this.catalogModel.userCatalog = this.getValues();
    this.loading.emit(true);
    if (this.isCreating) {
      this.catalogService.SaveAsDraft(this.catalogModel, Catalog.Users).toPromise().then(() => {
        this.alert.success('Congratulations !! The New User Has Been Sent To Approve Successfully!!');
        this.saved.emit(-1);
        this.clear();
      });
    } else {
      this.catalogService.SaveAsDraft(this.catalogModel, Catalog.Users).subscribe(x => {
        this.alert.success('Congratulations !! The User Modification Has Been Sent To Approve Successfully!!');
        if (this.userCatalog)
          this.saved.emit(this.userCatalog.id);
        this.clear();
      });
    }
    this.errorMsg = [];
  }

  modifyUser() {
    this.isEditing = true;
    this.disableControls(false);
  }

  formDirty() {
    this.formGroup.markAsDirty();
    this.changed.emit(this.formGroup.dirty);
  }

  approve() {
    if (this.userCatalog)
      this.catalogService.Approve(this.userCatalog.id, Catalog.Users).subscribe(() => {
        this.alert.success('Congratulations !! User Approved Successfully!!');
        if (this.userCatalog)
          this.saved.emit(this.userCatalog.id);
        this.clear();
      });
    this.errorMsg = [];
  }

  disapprove(disapprovalReason: string) {
    if (this.userCatalog)
      this.catalogService.Unapprove(this.userCatalog.id, Catalog.Users, disapprovalReason).subscribe(() => {
        this.alert.success('Congratulations !! User Disapproved Successfully!!');
        if (this.userCatalog)
          this.saved.emit(this.userCatalog.id);
        this.clear();
      });
  }

  openAddDisapprovalDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = '';
    const dialogRef = this.dialog.open(DisapprovalReasonComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.disapprove(data);
      }
    });
  }

  getValues(): UserCatalog | undefined {
    const pendingDetails = new UserCatalogDetails();
    pendingDetails.user = new User();
    pendingDetails.user.name = this.nameCtrl?.value;
    pendingDetails.user.username = this.userCtrl?.value;
    pendingDetails.user.emailAddress = this.emailCtrl?.value;
    pendingDetails.user.phoneExt = this.phoneCtrl?.value;
    pendingDetails.user.mobile = this.mobileCtrl?.value;
    pendingDetails.user.initials = this.initialsCtrl?.value;
    pendingDetails.user.organizationID = this.organizationCtrl?.value;
    pendingDetails.user.isManager = this.issupCtrl?.value;
    pendingDetails.user.status = this.statusCtrl?.value;
    pendingDetails.user.supervisorID = this.supervisorCtrl?.value;
    pendingDetails.roles = this.roles;
    if (this.userCatalog)
      this.userCatalog.pendingDetails = pendingDetails;
    return this.userCatalog;
  }

  setValues(userCatalogDetails: UserCatalogDetails) {
    const details = this.utils.cloneDeep(userCatalogDetails);
    this.nameCtrl?.setValue(details.user.name);
    this.userCtrl?.setValue(details.user.username);
    this.emailCtrl?.setValue(details.user.emailAddress);
    this.phoneCtrl?.setValue(details.user.phoneExt);
    this.mobileCtrl?.setValue(details.user.mobile);
    this.initialsCtrl?.setValue(details.user.initials);
    this.statusCtrl?.setValue(details.user.status);
    this.issupCtrl?.setValue(details.user.isManager);
    this.organizationCtrl?.setValue(details.user.organizationID);
    this.supervisorCtrl?.setValue(details.user.supervisorID);
    this.dataSource = new MatTableDataSource(this.users.filter(x => x.supervisorID == this.userCatalog?.details.user.id));
    this.roles = details.roles?.sort((a, b) => this.utils.sort(a.code, b.code, true));
    this.supervisors = this.users.filter(x => x.isManager && x.id != this.currentUser?.id);
    this.filterRoles();
  }

  filterRoles() {
    this.loadRoles();
    this.rolesFiltered = this.allRoles?.filter(x => !this.roles?.map(r => r.id).includes(x.id));
  }

  changeRole(e: any) {
    const value = (e.target.value as string).toLowerCase();
    this.filterRoles();
    this.rolesFiltered = this.rolesFiltered.filter(x => x.name?.toLowerCase().includes(value) || x.code?.toLowerCase().includes(value));
  }

  cancel() {
    this.formGroup.reset();
    if (this.isEditing || this.isCreating) {
      if (this.isEditing) {
        this.getDetails(this.userCatalog);
      }
      if (this.isCreating) {
        this.canModify = false;
      }
      this.isCreating = false;
      this.isEditing = false;
      this.disableControls();
    }
    else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        message: 'Are you sure you want to Cancel you changes?',
        icon: 'question'
      };
      const dialogRef = this.dialog.open(YesNoDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        if (data && this.userCatalog) {
          this.catalogService.Unapprove(this.userCatalog.id, Catalog.Users, 'User Cancelled own changes').subscribe(() => {
            this.alert.info('Your changes were Cancelled');
            this.saved.emit(undefined);
            this.clear();
          });
        }
      });
    }
  }

  isValid() {
    return this.formGroup.valid && this.formGroup.dirty && this.roles.length;
  }

  clear(isInit?: boolean) {
    this.userCatalog = new UserCatalog();
    this.catalogModel = new CatalogModel();
    this.errorMsg = [];
    this.isCreating = false;
    this.isEditing = false;
    this.canApprove = false;
    this.canModify = false;
    this.canCancel = false;
    this.roles = [];
    this.filterRoles();

    this.formGroup.reset();
    this.disableControls();
  }

  alertError() {
    this.alert.error(this.errorMsg.join(', '));
    const that = this;
    setTimeout(() => { that.alert.clear(); });
  }

  isModified(fieldName: string) {
    if (this.userCatalog?.pendingUser && this.showModification) {
      switch (fieldName) {
        case 'name':
          return this.userCatalog?.details.user.name != this.userCatalog.pendingDetails.user.name;
        case 'username':
          return this.userCatalog?.details.user.username != this.userCatalog.pendingDetails.user.username;
        case 'email':
          return this.userCatalog?.details.user.emailAddress != this.userCatalog.pendingDetails.user.emailAddress;
        case 'phone':
          return this.userCatalog?.details.user.phoneExt != this.userCatalog.pendingDetails.user.phoneExt;
        case 'mobile':
          return this.userCatalog?.details.user.mobile != this.userCatalog.pendingDetails.user.mobile;
        case 'initials':
          return this.userCatalog?.details.user.initials != this.userCatalog.pendingDetails.user.initials;
        case 'status':
          return this.userCatalog?.details.user.status != this.userCatalog.pendingDetails.user.status;
        case 'organization':
          return this.userCatalog?.details.user.organizationID != this.userCatalog.pendingDetails.user.organizationID;
        case 'supervisor':
          return this.userCatalog?.details.user.supervisorID != this.userCatalog.pendingDetails.user.supervisorID;
        case 'sup':
          return this.userCatalog?.details.user.isManager != this.userCatalog.pendingDetails.user.isManager;
        case 'roles':
          return this.utils.JSONstringify(this.userCatalog.details.roles?.sort((a, b) => a.id - b.id).map(r => {
            return { id: r.id };
          })) != this.utils.JSONstringify(this.userCatalog.pendingDetails.roles?.sort((a, b) => a.id - b.id).map(r => {
            return { id: r.id };
          }));
      }
    }
    return false;
  }

  override getErrorMsg(control: AbstractControl | null, name: string = ''): string | null {
    let result = null;
    if (name == 'rolesCtrl' && !this.roles.length) {
      result = 'You must select at least one Role';
    }

    if (control?.hasError('required')) { result = 'You must enter a value'; }
    if (control?.hasError('hasWhiteSpace')) { result = 'Enter a valid value'; }
    if (control?.hasError('nameTaken')) { result = 'This name is already taken'; }
    if (control?.hasError('usernameTaken')) { result = 'This username is already taken'; }
    if (control?.hasError('emailTaken')) { result = 'This email is already taken'; }
    return result;
  }

  numericOnly(e: any): boolean {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode == 46 || charCode == 32 || charCode == 9 || charCode == 8 || charCode >= 48 && charCode <= 57 || charCode == 43 || charCode == 45 || charCode == 40 || charCode == 41) {
      return true;
    }
    return false;
  }

  loadOrganizations() {
    this._organizationService.Read().subscribe(data => {
      this.organizations = data as Organization[];
    });
  }

  selectedRole(e: any) {
    const value = e.option.value;
    this.roles.push(value);
    this.filterRoles();
    this.rolesCtrl?.markAsDirty();
  }

  removeRole(role: Role) {
    const index = this.roles.findIndex(x => x.id == role.id);
    this.roles.splice(index, 1);
    this.filterRoles();
    this.formGroup.markAsDirty();
  }

}
