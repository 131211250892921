import { IResource } from "src/app/components/catalogs/beamline-catalog/resource/resources";

export class Refresh {
  static readonly type = '[Home Branch Status] - Refresh';
}

export class Update {
  static readonly type = '[Home Branch Status] - Update';
  constructor(public payload: IResource) { }
}

export class Add {
  static readonly type = '[Home Branch Status] - Add';
  constructor(public payload: IResource) { }
}

export class Delete {
  static readonly type = '[Home Branch Status] - Delete';
  constructor(public payload: number) { }
}
