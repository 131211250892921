import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert/alert.service';
import { RadMeterUnit } from '../../rad-meters';
import { RadMetersService } from '../../services/rad-meters.service';

@Component({
  selector: 'rad-meters-units-details',
  templateUrl: './rad-meters-units-details.component.html',
  styleUrls: ['./rad-meters-units-details.component.scss']
})
export class RadMetersUnitsDetailsComponent implements OnInit {
  @Input() currentMeterUnit!: RadMeterUnit;

  public modifying!: boolean;
  public selected!: boolean;
  public adding = false;
  public disabledStatus!: boolean;
  public statusList: any = [{ name: 'Active', id: 1 }, { name: 'Inactive', id: 0 }];
  public showCancelButton!: boolean;
  public isModifiying!: boolean;
  public modifyAvailable = false;
  public disabledTimer!: boolean;
  listMeterUnits: RadMeterUnit[] = [];
  private valid!: boolean;
  oldName?: string = '';
  oldDescription?: string = '';
  oldStaus?: number;
  private errorMsg: string[] = [];


  constructor(
    private alert: AlertService,
    private _service: RadMetersService,
    private dialogRef: MatDialogRef<RadMetersUnitsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RadMeterUnit) { }

  ngOnInit(): void {
    if (this.currentMeterUnit == undefined) {
      this.currentMeterUnit = { name: '', description: '', status: 0 } as RadMeterUnit;
    }
    this.obtainMeterUnits();
    if (this.data) {
      this.getDetails(this.data);
    }
  }

  async obtainMeterUnits() {
    await this._service.getRadMeterUnits().toPromise().then(result => {
      if (!(result?.length)) {
        result = [];
      }
      this.listMeterUnits = result;
    });
  }

  saveMeterUnit() {
    const meterUnit = this.createMeterUnit();
    if (this.isValid()) {
      if (!this.currentMeterUnit.id) {
        const meterUnitSearch = this.listMeterUnits.some(cat => cat.name?.trim() == this.currentMeterUnit.name?.trim());
        if (!meterUnitSearch) {
          this._service.createRadMeterUnit(meterUnit).toPromise().then(result => {
            this.currentMeterUnit.id = result?.id;
          }).finally(() => {
            this.dialogRef.close('Create');
          });
        }
        else {
          this.alert.warning('Warning !! There is already a category with the name entered!!');
        }
      } else {
        meterUnit.id = this.currentMeterUnit.id;
        this._service.updateRadMeterUnit(meterUnit).toPromise().then(result => {
        }).finally(() => {
          this.dialogRef.close('Update');
        });
      }
    }
  }

  isValid() {
    this.valid = true;
    if (!this.currentMeterUnit.name) {
      this.alert.error('Field <b>Name</b> should not be blank!');
      this.valid = false;
    }
    if (!this.currentMeterUnit.description) {
      this.alert.error('Field <b>Description</b> should not be blank!');
      this.valid = false;
    }
    return this.valid;
  }

  createMeterUnit(): RadMeterUnit {
    const radMeterUnit: RadMeterUnit = {
      name: this.currentMeterUnit.name,
      description: this.currentMeterUnit.description,
      status: this.currentMeterUnit.status,
      id: undefined,
      type: undefined,
      code: ''
    };
    return radMeterUnit;
  }

  getDetails(data: RadMeterUnit) {
    this.clear();
    if (!data.id) {
      this.currentMeterUnit.description = data.description;
      this.currentMeterUnit.name = data.name;
      this.currentMeterUnit.status = data.status;
      this.showCancelButton = true;
      this.adding = true;
      this.modifying = false;
      this.disabledStatus = false;
      this.selected = false;
    }
    else {
      this.selected = true;
      this.modifying = true;
      this.adding = false;
      this.disabledStatus = false;
      this.oldName = data.name;
      this.oldDescription = data.description;
      this.oldStaus = data.status;
      this.currentMeterUnit.id = data.id;
      this.currentMeterUnit.description = data.description;
      this.currentMeterUnit.name = data.name;
      this.currentMeterUnit.status = data.status;
      this.showCancelButton = true;
    }
  }

  clear() {
    this.currentMeterUnit = { name: '', description: '', status: 0 } as RadMeterUnit;
    this.adding = false;
    this.selected = false;
    this.modifying = false;
    this.isModifiying = false;
    this.disabledStatus = false;
    this.showCancelButton = false;
    this.disabledTimer = false;
  }

  close() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
