import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert/alert.service';
import { ILogger, LogLevel } from '@microsoft/signalr';
import { BehaviorSubject, Observable, Subscribable, Subscription } from 'rxjs';

export class MyLogger implements ILogger {

  public logLevel!: BehaviorSubject<number | null>;

  constructor() {
    this.logLevel = new BehaviorSubject<number | null>(null);
  }
  log(logLevel: LogLevel, message: string) {
    if (logLevel > 0) {
      // console.log(logLevel + ' - ' + message);
      this.setLogLevel(logLevel);
    }
  }

  private setLogLevel(value: number) {
    this.logLevel.next(value);
  }

  getLogLevel(): Observable<number | null> {
    return this.logLevel.asObservable();
  }

}

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  BASE_URL = environment.urls.baseApiUrl.replace('api', 'apphub');
  public hubConnection?: signalR.HubConnection;
  firstLoad = true;
  connection = false;
  reconnected = false;

  logger = new MyLogger();

  logLevelSubscription?: Subscription;

  constructor(
    private alert: AlertService,
  ) {
    this.checkLogger();
  }

  private checkLogger() {
    this.logger.logLevel?.subscribe(value => {
      const state = this.hubConnection?.state;
      if (state == 'Disconnected') {
        this.connection = false;
      }
      if (state == 'Connected') {
        this.connection = true;
        if (!this.firstLoad)
          this.reconnected = true;
      }
    });
  }

  public startConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.BASE_URL)
      .withAutomaticReconnect([0, 2000, 5000, 10000, 15000])
      .configureLogging(this.logger)
      .build();

    this.hubConnection.keepAliveIntervalInMilliseconds = 5000;
    this.hubConnection.serverTimeoutInMilliseconds = 60000;

    this.startHubConnection();

    this.hubConnection.onreconnecting(error => {
      this.connection = false;
      this.alert.error("Lost Connection to Server. Attempting to Reconnect...");
    });

    this.hubConnection.onreconnected(connectionId => {
      this.connection = true;
      this.alert.success('Reconnected successfully!');
      console.log('Reconnected successfully with connection ID:', connectionId);
    });

    this.hubConnection.onclose(error => {
      this.connection = false;
      console.log("Connection closed. Trying to reconnect...");
      this.alert.error('Connection closed. Trying to reconnect...');
      setTimeout(() => { this.startConnection(); }, 2000);  // Reconnect logic
    });
  }

  public listenOn(tag: string) {
    this.hubConnection?.on(tag, (data) => {
      console.log(data);
    });
  }

  startHubConnection() {
    this.hubConnection?.start()
      .then(() => {
        this.connection = true;
        if (!this.firstLoad) {
          this.firstLoad = false;
          console.log('Connection started');
          this.reconnected = true;
          this.alert.info('Connection Restablished!');
        }
      })
      .catch(err => {
        console.log('Error while starting connection: ' + err);
        setTimeout(() => this.startHubConnection(), 3000);  // Reconnect logic
      }
      );
  }

  public listenOn2 = (tag: string, funct: (...args: any[]) => any) => this.hubConnection?.on(tag, funct);
}
