import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RadLocationType } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location-type';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RadLocationTypeService {

  public api: string = environment.urls.baseApiUrl + '/RadLocationType';

  constructor(
    private http: HttpClient
  ) { }

  GetRadLocationTypes(): Observable<RadLocationType[]> {
    return this.http.get<RadLocationType[]>(this.api);
  }

  GetRadLocationTypeById(id: number): Observable<RadLocationType> {
    return this.http.get<RadLocationType>(this.api + '/' + id);
  }

  CreateRadLocationType(radLocationType: RadLocationType): Observable<RadLocationType> {
    return this.http.post<RadLocationType>(this.api, radLocationType);
  }

  UpdateRadLocationType(id: number, radLocationType: RadLocationType): Observable<RadLocationType> {
    return this.http.put<RadLocationType>(this.api + '/' + id, radLocationType);
  }

  UpdateRadLocationTypes(radLocationTypes: RadLocationType[]): Observable<RadLocationType[]> {
    return this.http.patch<RadLocationType[]>(this.api, radLocationTypes);
  }

  DeleteRadLocationType(id: number) {
    return this.http.delete(this.api + '/' + id);
  }

}
