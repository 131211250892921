<fieldset class="field-group">
  <legend>Select placeholder to use for comparisons</legend>
  <mat-radio-group [(ngModel)]="placeholder" class="d-flex">
    <mat-radio-button
      class="small mr-3"
      *ngFor="let o of options"
      [checked]="o.checked"
      [value]="o.value"
      (change)="change($event)"
    >
      <span [innerHTML]="o.value | placeholderHighlight : true"></span>
    </mat-radio-button>
  </mat-radio-group>
</fieldset>
