import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { FormStatusEnum, OlogNotificationType } from 'src/app/common/enumerations/enumerations';
import { Checklist } from 'src/app/components/checklists/checklists';
import { OLogService } from 'src/app/components/olog/olog.service';
import { PartialOlogComponent } from '../../olog/partial-olog/partial-olog.component';
import { ClBaseComponent } from '../cl-base/cl-base.component';

@Component({
  selector: 'app-cl-olog',
  templateUrl: './cl-olog.component.html',
  styleUrls: ['./cl-olog.component.scss']
})
export class ClOlogComponent extends ClBaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() btnColor!: string;
  @Input() btnName?: string | null;

  @Output() loading = new EventEmitter<boolean>();

  currentChecklist!: Checklist;
  checklists!: Checklist[];
  checklists$!: Observable<Checklist[]>;
  checklistsSubs!: Subscription;

  toggleLabel?: string;

  isChecked: boolean = true;

  constructor(
    protected override injector: Injector,
    private ologService: OLogService,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.checklistsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['checklist']) {
      const currentChecklist = changes['checklist'].currentValue as Checklist;
      if (currentChecklist?.checklistStatus?.formStatusID == FormStatusEnum.Closed) {
        this.currentChecklist = this.checklist as Checklist;
        this.checkOlogStatus();
      }
    }
  }

  ngOnInit(): void {
    this.toggleLabel = this.getMessage('OlogToggleLabel').description;
    if (!this.valueString) this.isChecked = true;
    else this.isChecked = this.utils.JSONparse(this.valueString).checked;
  }

  checkOlogStatus() {
    this.loading.emit(true);
    this.ologService.getOlog(this.currentChecklist.documentTypeID as number, this.currentChecklist.id, OlogNotificationType.Closed).toPromise().then(async olog => {
      if (olog?.id && !olog?.sent && this.ologService.ologIDPosting != olog.id && !this.currentChecklist.isOlogSent && this.isChecked) {
        this.ologService.ologIDPosting = olog.id;
        await this.ologService.postToOlog(this.currentChecklist.documentTypeID as number, this.currentChecklist.id, OlogNotificationType.Closed, 0, false, false);
      }
      this.loading.emit(false);
    });
  }

  openOlog() {
    this.currentChecklist = this.checklist as Checklist;
    if (this.currentChecklist.documentTypeID)
      this.ologService.getOlog(this.currentChecklist.documentTypeID, this.currentChecklist.id, OlogNotificationType.Closed).toPromise().then(data => {
        if (data) {
          const dialogConfig: MatDialogConfig = {
            data,
            width: '80%'
          };
          dialogConfig.data = data;
          const dialogRef = this.dialog.open(PartialOlogComponent, dialogConfig);
          dialogRef.afterClosed().toPromise();
        }
      });
  }

  toggleChanged() {
    if (this.isChecked) {
      this.alert.message('OlogToggleOn');
    }
    else {
      this.alert.message('OlogToggleOff');
    }
    this.valueString = this.utils.JSONstringify({ checked: this.isChecked });
    this.changed.emit(this.valueString);
  }

}
