import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { RadiationAreaItem } from '../radiation-area-item';
import { RadiationAreaService } from '../radiation-area-service.service';
import { RadiationAreaAttachmentComponent } from '../radiation-area-attachment/radiation-area-attachment.component';
import { Observable, Subscription } from 'rxjs';
import { MatFileV2UploadComponent } from 'src/app/modules/file-upload/lib/mat-file-v2-upload/mat-file-v2-upload.component';
import { RadiationAreaRefresh } from '../store/radiation-area.action';

@Component({
  selector: 'app-modal-add-to-ra',
  templateUrl: './modal-add-to-ra.component.html',
  styleUrls: ['./modal-add-to-ra.component.scss']
})
export class ModalAddToRAComponent implements OnInit {
  @ViewChild(RadiationAreaAttachmentComponent) radiationAreaAttachments!: RadiationAreaAttachmentComponent;
  @ViewChild(MatFileV2UploadComponent) private matFilev2Upload!: MatFileV2UploadComponent;

  radiationAreas?: RadiationAreaItem[];
  radiationAreasFiltered?: RadiationAreaItem[];
  radiationAreas$!: Observable<RadiationAreaItem[]>;
  radiationAreaSubs!: Subscription;

  public radAreas: RadiationAreaItem[] = [];
  public radiationAreaItems$!: Observable<RadiationAreaItem[]>;
  public radiationAreaItemSubscription: Subscription = new Subscription;

  loading = false;
  selectedFiles?: FileList;
  save_files_database = '';
  commentFiles?: any[];
  typeDocument = 'RadAreaImages';
  formType = 'RA';
  serialNo?: string;

  Id = 0;
  Name = '';
  // Files = [];
  // Files: any[] | undefined;
  Description = '';
  Placeholder = '';
  CreatedBy = 0;
  EmisionDate: Date = new Date();
  ItemBackup: string | null = null;
  saving = false;
  // USER LOAD
  public innerWidth: any;
  subtitle: string;
  options!: FormGroup;

  radArea?: RadiationAreaItem;
  radAreaUploadID?: string;

  constructor(public dialogRef: MatDialogRef<ModalAddToRAComponent>, public RAService: RadiationAreaService, private store: Store) {
    this.subtitle = 'NEW';
    if (RAService.selectedItem) {
      this.radArea = RAService.selectedItem;
      this.subtitle = 'EDIT';
      this.ItemBackup = JSON.stringify(RAService.selectedItem);
      this.EmisionDate = this.radArea.createdOn;
      this.Id = this.radArea.id ?? 0;
      this.Name = this.radArea.name;
      this.CreatedBy = this.radArea.createdBy;
      // this.Files = this.radArea.files;
      this.Description = this.radArea.description;
      this.CreatedBy = this.radArea.createdBy;
      // radAreaUploadID will be the serial number
      this.radAreaUploadID = (100000 + (this.radArea.id ?? 0)).toString();
      RAService.selectedItem = null;
    }
  }

  ngOnInit() {
    this.loadRadiationAreas();
  }

  ngOnDestroy(): void {
    this.radiationAreaItemSubscription?.unsubscribe();
  }

  loadRadiationAreas() {
    this.radiationAreaItems$ = this.store.select(state => state.RadiationArea.radiationAreaItems);
    this.radiationAreaItemSubscription = this.radiationAreaItems$.subscribe(radiationAreaItems => {
      if (radiationAreaItems?.length) {
        this.radAreas = radiationAreaItems?.filter(ra => ra.status === 1);
        const radArea = this.radAreas.find(r => r.id == this.radArea?.id);
        if (radArea) {
          this.radArea = radArea;
        }
      }
    });
  }

  changed() {
    this.store.dispatch(new RadiationAreaRefresh());
  }

  Validate(): boolean {
    if (!this.Name.length || !this.Description.length) {
      return false;
    }
    return true;
  }

  Save(): void {
    this.saving = true;
    if (this.Validate()) {
      this.radArea = {
        id: this.Id,
        name: this.Name,
        // files: this.Files,
        description: this.Description,
        status: 1,
        createdOn: this.EmisionDate,
        createdBy: this.CreatedBy
      };
      const self = this;
      if (this.ItemBackup == null || this.ItemBackup === undefined || this.ItemBackup === '') {
        this.radArea.createdOn = new Date();
        this.radAreaUploadID = (100000 + (this.radArea.id ?? 0)).toString();
        this.RAService.insertRAI(this.radArea).toPromise().then(async res => {
          if (this.selectedFiles?.length && res?.id) {
            this.radAreaUploadID = res.id.toString();
            await this.uploadAttachments(res.id, this.selectedFiles);
          }
          self.dialogRef.close();
        });
      }
      else {
        this.RAService.updateRAI(this.radArea).toPromise().then(async res => {
          if (this.selectedFiles?.length && res?.id) {
            this.radAreaUploadID = res.id.toString();
            await this.uploadAttachments(res.id, this.selectedFiles);
          }
          self.dialogRef.close();
        });
      }
    } else {
      this.saving = false;
    }
  }

  getSelectedFiles(e: FileList) {
    this.selectedFiles = e;
  }

  uploadAttachments(id: number, selectedFiles: FileList) {
    return new Promise((resolve, reject) => {
      this.matFilev2Upload
        .uploadRadFiles(id, selectedFiles)
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          console.log(error);
          reject(false);
        })
        .finally(() => {
          this.loading = false;
          // this.store.dispatch(new CommentRefreshByID(id));
        });
    });
  }

  Cancel() {
    this.dialogRef.close();
  }

}
