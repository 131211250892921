import { AfterViewInit, Component, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { PV } from 'src/app/services/pv-info/pv-info';
import { PVInfoService } from 'src/app/services/pv-info/pv-info.service';
import { PVRefresh } from 'src/app/services/pv-info/store/pv-info.action';

@Component({
  selector: 'pv-info-details',
  templateUrl: './pvinfo-details.component.html',
  styleUrls: ['./pvinfo-details.component.scss']
})
export class PvInfoDetailsComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() filter?: string | null;

  pvs?: PV[];
  pvs$!: Observable<PV[]>;
  pvsSubs!: Subscription;

  resources?: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  dataSource!: MatTableDataSource<PV>;
  displayedColumns: string[] = [
    'pvName',
    'value',
    'date',
    'resourceName',
    'resourceTypeName', 'actions'
  ];
  @ViewChild(MatSort) sort!: MatSort;

  waitingRestart: boolean = false;
  waitingRefresh: boolean = false;

  constructor(
    protected override injector: Injector,
    private pvService: PVInfoService
  ) {
    super(injector);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter'])
      this.loadPVs();
  }

  ngAfterViewInit(): void {
    if (this.dataSource)
      this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.refresh();
    this.loadResources();
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data?.length) {
        this.resources = data;
        this.loadPVs();
      }
    });
  }

  refresh() {
    this.waitingRefresh = true;
    this.store.dispatch(new PVRefresh());
  }

  loadPVs() {
    this.pvs$ = this.store.select(state => state.PV.data);
    this.pvsSubs = this.pvs$.subscribe(data => {
      if (data?.length) {
        this.pvs = data;
        this.pvs.map(p => {
          const resource = this.resources?.find(r => r.id == p.resourceID);
          p.resourceName = resource?.name;
          p.resourceTypeName = resource?.resourceType?.name;
        });
        this.dataSource = new MatTableDataSource(this.pvs);
        this.dataSource.filter = (this.filter ?? '').trim().toLowerCase();
        this.dataSource.sort = this.sort;
        this.waitingRefresh = false;
      }
    });
  }

  refreshPV(channelName: string) {
    this.pvService.getPVInfo(channelName).subscribe(data => {
      const pv = this.pvs?.find(p => p.pvName == channelName);
      if (pv) {
        if (pv.value == data.value && pv.date == data.date)
          this.alert.warning(`No new data found for <b> ${channelName} </b>`);
        pv.value = data.value;
        pv.date = data.date;
      }

    });
  }

  restart() {
    this.waitingRestart = true;
    this.pvService.restartPVInfoService().toPromise().then(() => {
      this.alert.success("PV Info service has been restarted!");
    }).catch(error => {
      this.alert.error(error);
    }).finally(() => this.waitingRestart = false);

  }

  delete(e: PV) {
    this.pvService.delete(e.id).toPromise().then(() => {
      this.alert.info(`PV ${e.pvName} Deleted`);
      this.alert.warning("Please push <b>Restart PV Service</b> button before refreshing Data to reflect changes!");
    }).catch(() => this.alert.defaultError());

  }

}
