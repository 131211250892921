import { Injectable } from '@angular/core';
import { AbsiTemplateResource, AbsiTemplateVersionResource, AbsiTemplateTypeResource } from '../absi';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { WfSignatureResource, WfTaskLocalResource, WfTaskResource } from 'src/app/services/work-flow/work-flow';

@Injectable()
export class AbsiBuilderService {

  BASE_URL = environment.urls.baseApiUrl; // Api URL
  Api = '/AbsiBuilder';

  constructor(private http: HttpClient) {

  }

  GetTemplateVersionsByType(typeId: number) {
    return this.http.get<AbsiTemplateVersionResource[]>(this.BASE_URL + this.Api + '/ByType/' + typeId);
  }

  GetTemplateVersionsByID(id: number) {
    return this.http.get<AbsiTemplateVersionResource>(this.BASE_URL + this.Api + '/ById/' + id);
  }

  CreateChecklistTemplate(absiTemplate: AbsiTemplateResource): Observable<AbsiTemplateResource> {
    let result = this.http.post<AbsiTemplateResource>(this.BASE_URL + this.Api, absiTemplate);
    return result;
  }

  ReadWFBySection(id: number, sectionNumber: number) {
    return this.http.get<WfSignatureResource[]>(this.BASE_URL + this.Api + '/WFBySection/' + id + '?SectionNumber=' + sectionNumber);
  }

  UpdateText(id: number, wfTaskLocalResource: WfTaskLocalResource) {
    return this.http.patch<WfTaskResource>(this.BASE_URL + this.Api + '/SaveTextType/' + id, wfTaskLocalResource);
  }

  SaveRequired(id: number, required: boolean) {
    return this.http.patch(this.BASE_URL + this.Api + '/SaveRequired/' + id + '/' + required, null);
  }

  SaveOrder(id: number, order: number) {
    return this.http.patch(this.BASE_URL + this.Api + '/SaveOrder/' + id + '/' + order, null);
  }

  GetTemplateTypes(): Observable<AbsiTemplateTypeResource[]> {
    return this.http.get<AbsiTemplateTypeResource[]>(this.BASE_URL + this.Api + '/Types');
  }

  ApproveVersion(absiVersion: AbsiTemplateVersionResource): Observable<AbsiTemplateVersionResource> {
    return this.http.put<AbsiTemplateVersionResource>(this.BASE_URL + this.Api + '/ApproveVersion', absiVersion);
  }

  DeleteVersion(id: number): Observable<AbsiTemplateVersionResource> {
    return this.http.delete<AbsiTemplateVersionResource>(this.BASE_URL + this.Api + '/DeleteVersion/' + id);
  }

  UpdateUpdater(id: number) {
    return this.http.patch<AbsiTemplateVersionResource>(this.BASE_URL + this.Api + '/UpdateUpdater/' + id, null);
  }
}
