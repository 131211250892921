<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-md-1 pt-2">
        <h6>{{ title }}</h6>
      </div>
      <div class="col-10 col-md-11 pt-2">
        <div class="row">
          <div class="col-7 pr-1">
            <div class="row">
              <div class="col-8 d-flex">
                <mat-label
                  [innerHTML]="
                    (textBefore ? textBefore : '')
                      | placeholderHighlight : editor
                  "
                ></mat-label>

                <b><mat-label [innerHTML]="scheduleName"></mat-label>&nbsp;</b>
                <mat-label
                  [innerHTML]="
                    (textAfter ? textAfter : '') | placeholderHighlight : editor
                  "
                ></mat-label>
                <div class="d-flex justify-content-end">
                  <role-chips
                    [roleCodes]="roleCodes"
                    class="mr-3 ml-3 top--4"
                  ></role-chips>
                  <button
                    *ngIf="!adding && !disabled"
                    class="btn small"
                    mat-flat-button
                    color="primary"
                    (click)="addLocation()"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
              <div class="col-4" style="display: inline-flex">
                <mat-form-field
                  class="p-0 no-subscript"
                  *ngIf="adding && !disabled"
                  appearance="outline"
                >
                  <mat-label>Location</mat-label>
                  <input
                    matInput
                    placeholder="Location"
                    #locationInput
                    [formControl]="locationCtrl"
                    [matAutocomplete]="autoLocation"
                    (keyup)="onLocationChange()"
                  />
                  <mat-autocomplete
                    #autoLocation="matAutocomplete"
                    (optionSelected)="selectedLocation($event)"
                    #locationAutocomplete
                    [displayWith]="displayLocation"
                  >
                    <mat-option
                      *ngFor="let location of filteredLocations"
                      [value]="location"
                    >
                      {{ location.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-icon
                  *ngIf="adding && !disabled"
                  color="warn"
                  class="sml small close"
                  (click)="endAdding()"
                  >close</mat-icon
                >
              </div>
            </div>

            <div>
              <div *ngFor="let location of locations">
                <div class="row border-top mt-1">
                  <div class="col-4">
                    <b><mat-label [innerHTML]="location?.name"></mat-label></b
                    >&nbsp;
                    <!--<mat-label [innerHTML]="textAfter2"></mat-label>-->
                    <mat-icon
                      *ngIf="!adding && !disabled"
                      color="warn"
                      class="sml small"
                      (click)="delete(location)"
                      >delete</mat-icon
                    >
                  </div>
                  <div class="col-8 nowrap">
                    <mat-label>Last Completed Date:</mat-label>
                    <b class="date"
                      >&nbsp;<mat-label
                        [innerHTML]="location.lastCompleted | date : 'M/d/yyyy'"
                      ></mat-label
                      >&nbsp;</b
                    ><br />
                    <mat-label>Next Inspection Due Date:</mat-label>
                    <b
                      [ngClass]="{
                        date: !due(location.nextDue),
                        dueDate: due(location.nextDue)
                      }"
                      >&nbsp;<mat-label
                        [innerHTML]="location.nextDue | date : 'M/d/yyyy'"
                      ></mat-label
                      >&nbsp;</b
                    >
                    <span *ngIf="location.extendedDate">
                      <br />
                      <mat-label>Extended Date:</mat-label>
                      <b
                        [ngClass]="{
                          date: !due(location.extendedDate),
                          dueDate: due(location.extendedDate)
                        }"
                        >&nbsp;<mat-label
                          [innerHTML]="
                            location.extendedDate | date : 'M/d/yyyy'
                          "
                        ></mat-label
                      ></b>
                    </span>
                    <mat-icon
                      *ngIf="
                        due(location.nextDue) || due(location.extendedDate)
                      "
                      color="warn"
                      class="warn float-right"
                      [matTooltip]="moreInfo ?? ''"
                      matTooltipPosition="left"
                      >warning</mat-icon
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5 note-col">
            <comments-box
              [disabled]="disabled"
              [textValue]="comments"
              title="Note"
              (textChanged)="textChanged($event)"
              [noSubscript]="true"
              [formType]="'ABSI'"
              [serialNo]="checklist?.serialNo"
            ></comments-box>
            <div>
              <mat-label class="text-black-50 small float-right text-right">{{
                initials
              }}</mat-label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
