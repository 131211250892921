import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
// import { RSSWAShutter } from 'src/app/_interface/RSSWA/RSSWAShutter';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';
import { RSSWAShutter } from '../../../rsswa-shutter';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { utils } from 'src/app/modules/libs/utils';
import { ResourceType } from 'src/app/common/enumerations/enumerations';
// import { ResourcesService } from 'src/app/Services/resources.service';
// import { Resource, ShutterResource } from 'src/app/_interface/Resources.models';

@Component({
  selector: 'rsswa-shutter-chips',
  templateUrl: './rsswa-shutter-chips.component.html',
  styleUrls: ['./rsswa-shutter-chips.component.scss']
})
export class RsswaShutterChipsComponent implements OnInit, OnChanges {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() control!: FormControl;
  @Input() rsswaId?: number;
  @Input() selectedRsswaShutters?: RSSWAShutter[];
  @Input() hilite!: boolean | null;

  @Output() onError = new EventEmitter<string>();
  @Output() onEdit = new EventEmitter<RSSWAShutter[]>();

  @ViewChild('shutterAutocomplete') shutterAutocomplete?: MatAutocomplete;
  @ViewChild('shutterInput') shutterInput?: ElementRef<HTMLInputElement>;

  resources?: Resource[];
  availableResources?: Resource[];
  filteredResources?: Resource[];

  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.resources = [];
    this.availableResources = [];
    this.filteredResources = [];
    this.loadResources();
    // this.resourceService.getResourcesByType(7).subscribe(
    //   (data : Resource[]) => {
    //     data.map(x => {
    //       this.allResources.push(x);
    //       this.availableResources.push(x);
    //       this.filteredResources.push(x);
    //     });
    //   },
    //   error => {
    //     this.onError.emit(error.error);
    //   }
    // );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedRsswaShutters' in changes) {
      this.setAvailableResources();
      this.clear();
    }
    if (this.disabled)
      this.control.disable();
    else
      this.control.enable();
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data?.length) {
        this.resources = data.filter(r => r.type == ResourceType.Shutter).sort((a, b) => utils.sort(a.name, b.name, true));
        this.availableResources = utils.cloneDeep(this.resources);
        this.filteredResources = utils.cloneDeep(this.resources);
      }
    });
  }

  selected(event: MatAutocompleteSelectedEvent) {
    var shutter: Resource = event.option.value;
    this.selectedRsswaShutters?.push({
      rsswaID: this.rsswaId,
      resourceID: shutter.id,
      resource: shutter
    });
    this.setAvailableResources();
    this.clear();
    this.onEdit.emit(this.selectedRsswaShutters);
  }

  remove(rsswaShutter: RSSWAShutter) {
    const index = this.selectedRsswaShutters?.indexOf(rsswaShutter) ?? -1;

    if (index >= 0) {
      this.selectedRsswaShutters?.splice(index, 1);
      this.setAvailableResources();
      this.clear();
      this.onEdit.emit(this.selectedRsswaShutters);
    }
  }

  clear() {
    if (this.shutterInput != null) this.shutterInput.nativeElement.value = '';
    if (this.control != null) this.control.setValue(null);
  }

  setAvailableResources() {
    this.availableResources = [];
    // if (!this.resources) this.resources = []
    if (this.selectedRsswaShutters == null) this.selectedRsswaShutters = [];
    this.availableResources = utils.cloneDeep(this.resources);
    // this.resources?.map(x => this.availableResources?.push(x));
    this.selectedRsswaShutters.map(x => {
      if (x.resource) {
        const index = this.availableResources?.map(a => a.id).indexOf(x.resource.id) ?? -1;
        if (index >= 0) {
          this.availableResources?.splice(index, 1);
        }
      }
    });
    this.filteredResources = [];
    this.availableResources?.map(x => this.filteredResources?.push(x));
  }

  onShutterInputKeyup(e: any) {
    const code = e.code;
    const text = e.target.value;
    if (!code?.includes("Arrow")) {
      this.filteredResources = this.availableResources?.filter(x => x.name?.trim().toLowerCase().includes(text.trim().toLowerCase()));
    }
  }

}
