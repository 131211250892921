import { Component, EventEmitter, Injector, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ReviewCycle } from '../../reviews.models';
import { MatTableDataSource } from '@angular/material/table';
import { TooltipComponent } from '@angular/material/tooltip';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'review-index-table',
  templateUrl: './review-index-table.component.html',
  styleUrls: ['./review-index-table.component.scss']
})
export class ReviewIndexTableComponent extends BaseComponent implements OnInit {

  @Input() reviewBoardTypeID!: number;
  @Input() visible!: boolean;
  @Output() reviewCycleSelected = new EventEmitter<ReviewCycle>();

  filter!: string;
  selectedRowIndex!: number;

  dataSource!: MatTableDataSource<ReviewCycle>;
  public displayedColumns: string[] = [
    'serialNo',
    'locationNames',
    'date',
  ];

  reviewCycle?: ReviewCycle;
  reviewCycles!: ReviewCycle[];
  reviewCyclesFiltered!: ReviewCycle[];
  reviewCycles$!: Observable<ReviewCycle[]>;
  reviewCyclesSubs!: Subscription;

  constructor(
    protected override injector: Injector,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.reviewCyclesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (this.service.currentDocument) { this.service.loading = true; }
    this.loadReviewCycles();
  }

  ngOnInit() {
    try {
      Object.defineProperty(TooltipComponent.prototype, 'message', {
        set(v: any) {
          const el = document.querySelectorAll('.mat-tooltip');
          if (el)
            el[el.length - 1].innerHTML = v;
        },
      });
    }
    catch { }
  }

  loadReviewCycles() {
    this.reviewCycles$ = this.store.select(state => state.ReviewCycles.data);
    this.reviewCyclesSubs = this.reviewCycles$.subscribe(data => {
      if (data.length) {
        this.reviewCycles = data.filter(c => c.reviewBoard?.reviewBoardTypeID == this.reviewBoardTypeID).sort((a, b) => this.utils.sort(a.serialNo, b.serialNo, false));
        this.dataSource = new MatTableDataSource(this.reviewCycles);
        // if (this.service.currentDocument) {
        //   this.reviewCycle = this.reviewCycles.find(r => r.serialNo == this.service.currentDocument?.serialNo);
        //   this.service.currentDocument = null;
        //   this.rowClicked(this.reviewCycle);
        // }
      }
    });
  }

  rowClicked(row?: ReviewCycle) {
    this.reviewCycle = row;
    this.reviewCycleSelected.emit(row);
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex((r) => {
      return r.id === row.id;
    });
    return i;
  }

}
