import { State, Selector, Action, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { BeamlineRestrictionCreate, BeamlineRestrictionDelete, BeamlineRestrictionRefresh, BeamlineRestrictionRefreshByBeamlineID, BeamlineRestrictionUpdate } from './beamline-restrictions.action';
import { BeamlineRestriction } from '../../models';
import { BeamlineRestrictionService } from '../../services/beamline-restriction.service';
import { utils } from 'src/app/modules/libs/utils';

export class BeamlineRestrictionStateModel {
  data: BeamlineRestriction[] = [];
}

@State<BeamlineRestrictionStateModel>({
  name: 'BeamlineRestrictions',
  defaults: {
    data: []
  }
})
@Injectable()
export class BeamlineRestrictionState {
  constructor(
    private service: BeamlineRestrictionService,
    private store: Store
  ) { }

  @Selector()
  static read(state: BeamlineRestrictionStateModel) {
    return state.data;
  }

  @Action(BeamlineRestrictionRefresh)
  refresh({ patchState }: StateContext<BeamlineRestrictionStateModel>) {
    this.service.getAll().toPromise().then(data => {
      patchState({ data });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(BeamlineRestrictionRefreshByBeamlineID)
  refreshById({ getState, patchState }: StateContext<BeamlineRestrictionStateModel>, { id }: BeamlineRestrictionRefreshByBeamlineID) {
    this.service.getByBeamlineID(id).subscribe(data => {
      const state = getState().data.filter(x => x.beamlineID != id);
      const newState = state.concat(data)
      patchState({
        data: newState
      });
    });
  }

  @Action(BeamlineRestrictionCreate)
  create({ getState, patchState }: StateContext<BeamlineRestrictionStateModel>, { item }: BeamlineRestrictionCreate) {
    const state = getState();
    patchState({
      data: [...state.data, item]
    });
  }

  @Action(BeamlineRestrictionUpdate)
  update({ getState, patchState }: StateContext<BeamlineRestrictionStateModel>, { id, item }: BeamlineRestrictionUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(BeamlineRestrictionDelete)
  delete({ getState, patchState }: StateContext<BeamlineRestrictionStateModel>, { id }: BeamlineRestrictionDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
