import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { NavigationLink } from './navigation-link';

@Injectable({
  providedIn: 'root'
})
export class NavigationLinkService {
  domain = environment.urls.baseApiUrl;
  controller = '/NavigationLink';
  constructor(
    public http: HttpClient,
    public auth: AuthenticationService
  ) { }

  create(link: NavigationLink): Observable<NavigationLink> {
    const url = this.domain + this.controller;
    return this.http.post<NavigationLink>(url, link);
  }

  readAll(): Observable<NavigationLink[]> {
    const url = this.domain + this.controller;
    return this.http.get<NavigationLink[]>(url);
  }

  readActive(): Observable<NavigationLink[]> {
    const url = this.domain + this.controller;
    return this.http.get<NavigationLink[]>(url + '/active');
  }

  read(id: number): Observable<NavigationLink> {
    const url = this.domain + this.controller + '/' + id.toString();
    return this.http.get<NavigationLink>(url);
  }

  update(id: number, link: NavigationLink): Observable<NavigationLink> {
    const url = this.domain + this.controller + '/' + id.toString();
    return this.http.put<NavigationLink>(url, link);
  }

  updateOrder(navigationLinks: NavigationLink[]) {
    return this.http.put(this.domain + this.controller, navigationLinks);
  }

  delete(id: number): Observable<any> {
    const url = this.domain + this.controller + '/' + id.toString();
    return this.http.delete<NavigationLink>(url);
  }
}
