
import { User } from "src/app/components/catalogs/user-catalog/services/user";
import { WfTaskLocalResource } from "src/app/services/work-flow/work-flow";
import { WFTableResource } from "../workflow/workflow";
import { RelatedDocument } from "src/app/services/documents/documents";
import { FormRestriction } from "src/app/services/forms/form-restriction";
import { FormStatus } from "src/app/services/forms/form-status";
import { Location } from "src/app/components/catalogs/beamline-catalog/resource/resources";


export interface SCFModelBase {
  id: number;
  name: string;
  description: string;
  status: number;
  type: number | null;
  code: string;
  createdOn: Date;
  createdBy: number;
  updatedOn: Date;
  updatedBy: number | null;
}
export class SCFMaster {
  id?: number;
  name?: string;
  serialNo!: string;
  relatedLinks?: string;
  locationIds?: string;
  locationNames?: string;
  status!: number;
  statusName?: string;
  accRFShielding?: boolean;
  blShielding?: boolean;
  permanentBLRemoval?: boolean;
  permanentAccRemoval?: boolean;
  tocaMovement?: boolean;
  shieldingAffected?: string;
  description?: string;
  requesterId?: number;
  requesterName?: string;
  estimatedStartOn?: Date;
  estimatedEndOn?: Date;
  notifyHP?: boolean;
  notifyBLSci?: boolean;
  notifyBRC?: boolean;
  notifyRF?: boolean;
  notifySnA?: boolean;
  notifyARC?: boolean;
  notifyRP?: boolean;
  notificationMessage?: string;
  createdOn?: Date;
  createdBy?: number;
  createdByName?: string;
  relatedDocuments?: RelatedDocument[];
  locations!: Location[];
  submittedBy?: number;
  submittedOn?: Date;
  wfTableID?: number;
  wfTable?: WFTableResource | null;
  type?: number = 1;
  disapprovalReason?: string;
  disapprovedBy?: number;
  disapprovedOn?: Date;
  activatedBy?: number;
  activatedOn?: Date;
  scfRestrictionsV2?: FormRestriction[];
  shieldingNames?: string;
  editingUntil?: Date | null;
  editingById?: number | null;
  editingBy?: User | null;
  wasRouted?: boolean;
  newWorkLabel?: boolean;
  scfAmendments?: SCFAmendment[];
  formStatus?: FormStatus;
  updatedOn?: Date | null;
  updatedBy?: number | null;
  retrievedBy?: number;
  scfHoldPoints?: SCFHoldPointResource[] | null;
}


export interface SCFAmendment extends SCFModelBase {
  id: number;
  scfID: number;
  locations?: Location[];
  locationIds: string;
  relatedLinks: string;
  locationNames: string;
  accRFShielding: boolean;
  blShielding: boolean;
  permanentBLRemoval: boolean;
  permanentAccRemoval: boolean;
  tocaMovement: boolean;
  shieldingAffected: string;
  requesterId: number;
  estimatedStartOn: Date;
  estimatedEndOn: Date;
  notifyHP: boolean;
  notifyBLSci: boolean;
  notifyBRC: boolean;
  notifyRF: boolean;
  notifySnA: boolean;
  notifyARC: boolean;
  notifyRP: boolean;
  notificationMessage: string;
  cancelReason: string;
  active: boolean;
  requester: User;
  number: number;
  scfRestrictionsV2?: FormRestriction[];
  relatedDocuments?: RelatedDocument[];
}
export class SaveSCFMasterResource {
  name!: string;
  relatedLinks!: string;
  serialNo!: string;
  locationIds!: string;
  locationNames!: string;
  status!: number;
  accRFShielding!: boolean;
  blShielding!: boolean;
  permanentBLRemoval!: boolean;
  permanentAccRemoval!: boolean;
  tocaMovement!: boolean;
  shieldingAffected!: string;
  description!: string;
  requesterId!: number;
  requesterName?: string;
  estimatedStartOn!: Date;
  estimatedEndOn!: Date;
  notifyHP!: boolean;
  notifyBLSci!: boolean;
  notifyBRC!: boolean;
  notifyRF!: boolean;
  notifySnA!: boolean;
  notifyARC?: boolean;
  notifyRP?: boolean;
  createdOn!: Date;
  createdBy!: number;
  relatedDocuments!: RelatedDocument[];
  submittedBy?: number;
  scfRestrictionsV2!: FormRestriction[];
}

export class SaveSCFSection1Resource {
  id!: number;
  relatedLinks!: string;
  name!: string;
  serialNo!: string;
  locationIds!: string;
  locationNames!: string;
  status!: number;
  accRFShielding!: boolean;
  blShielding!: boolean;
  permanentBLRemoval!: boolean;
  permanentAccRemoval!: boolean;
  tocaMovement!: boolean;
  shieldingAffected!: string;
  description!: string;
  requesterId!: number;
  requesterName?: string;
  estimatedStartOn!: Date;
  estimatedEndOn!: Date;
  notifyHP!: boolean;
  notifyBLSci!: boolean;
  notifyBRC!: boolean;
  notifyRF!: boolean;
  notifySnA!: boolean;
  notifyARC?: boolean;
  notifyRP?: boolean;
  createdOn!: Date;
  createdBy!: number;
  relatedDocuments!: RelatedDocument[];
  submittedBy?: number;
  submittedOn?: Date;
  scfRestrictionsV2!: FormRestriction[];
}

export class SCFSection2Resource {
  id?: number;
  status!: number;
  disapprovalReason!: string;
  disapprovedBy?: number;
  disapprovedOn?: Date;
  activatedBy?: number;
  activatedOn?: Date;
}

export class SaveSCFStatusResource {
  id?: number;
  status!: number;
  disapprovalReason?: string;
  disapprovedBy?: number;
  disapprovedOn?: Date;
  activatedBy?: number;
  activatedOn?: Date;
}

export class SCFSection3Resource {
  id!: number;
  workCompleteBy?: number;
  workCompleteOn?: Date;
  shieldingInspectedBy?: number;
  shieldingInspectedOn?: Date;
  shieldingVerifiedOICBy?: number;
  shieldingVerifiedOICOn?: Date;
  shieldingVerifiedFOBy?: number;
  shieldingVerifiedFOOn?: Date;
  shieldingVerifiedHPBy?: number;
  shieldingVerifiedHPOn?: Date;
  tocaPositionVerifiedBy?: number;
  tocaPositionVerifiedOn?: Date;
}

export class SaveSCFSection3Resource {
  id?: number;
  status?: number;
}

export class SCFSection4Resource {
  id?: number;
  scfAmended?: boolean;
  scfAmendmentsReviewedBy?: number;
  scfAmendmentsReviewedOn?: Date;
  blReviewRequired?: boolean;
  blReviewCompleteBy?: number;
  blReviewCompleteOn?: Date;
  bpPlugPermRemoved?: boolean;
  bL0804CompleteBy?: number;
  bL0804CompleteOn?: Date;
  docUpdateRequired?: boolean;
  docUpdateCompleteBy?: number;
  docUpdateCompleteOn?: Date;
  radiationSurveyRequired?: boolean;
  radiationSurveyCompleteBy?: number;
  radiationSurveyCompleteOn?: Date;
  rssTestComplete?: boolean;
  rssTestCompleteBy?: number;
  rssTestCompleteOn?: Date;
  scfCompleteBy?: number;
  scfCompleteOn?: Date;
}

export class SaveSCFSection4Resource {
  id!: number;
  scfAmended?: boolean;
  scfAmendmentsReviewedBy?: number;
  scfAmendmentsReviewedOn?: Date;
  blReviewRequired?: boolean;
  blReviewCompleteBy?: number;
  blReviewCompleteOn?: Date;
  bpPlugPermRemoved?: boolean;
  bl0804CompleteBy?: number;
  bl0804CompleteOn?: Date;
  docUpdateRequired?: boolean;
  docUpdateCompleteBy?: number;
  docUpdateCompleteOn?: Date;
  radiationSurveyRequired?: boolean;
  radiationSurveyCompleteBy?: number;
  radiationSurveyCompleteOn?: Date;
  rssTestComplete?: boolean;
  rssTestCompleteBy?: number;
  rssTestCompleteOn?: Date;
  scfCompleteBy?: number;
  scfCompleteOn?: Date;
}

export interface SCFHoldPointResource {
  id: number;
  name: string;
  description: string;
  status: number;
  type: number | null;
  code: string;
  number: number;
  createdOn: Date;
  createdBy: number;
  updatedOn: Date;
  updatedBy: number | null;
  scfMasterID: number;
  scfMaster?: SCFMaster;
  wfTaskLocals?: WfTaskLocalResource[];
}
