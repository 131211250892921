import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { RedirectDialogComponent } from 'src/app/components/redirect/redirect-dialog/redirect-dialog.component';
import { RedirectService } from 'src/app/components/redirect/redirect.service';

export interface IRedirectGuard {
  redirectTo: boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanDeactivate<IRedirectGuard> {

  constructor(
    public dialog: MatDialog,
    private redirect: RedirectService
  ) {

  }

  async canDeactivate(component: IRedirectGuard, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (component.redirectTo || !nextState.url.includes('/redirect/') || localStorage.getItem('currentUrl') == nextState.url) {
      localStorage.setItem('currentUrl', nextState.url);
      this.removeLocalStorageUrl();
      return true;
    } else {
      localStorage.setItem('currentUrl', nextState.url);
      // Show the dialog and return an observable based on user's response
      const dialogRef = this.dialog.open(RedirectDialogComponent, {
        disableClose: false,
        backdropClass: 'transparent-backdrop',
        data: { targetUrl: nextState.url },
        position: {
          left: this.redirect.mouseX ?? '',
          top: this.redirect.mouseY ?? '',
        },
      });

      const response = await dialogRef.afterClosed().toPromise().then(data => {
        return false;
      });
      this.removeLocalStorageUrl();
      return response;
    }
  }

  removeLocalStorageUrl() {
    setTimeout(() => {
      localStorage.removeItem('currentUrl');
    }, 3000);
  }
}
