<div class="row mb-3">
  <div class="col-4 margin-auto">Checklist Template:</div>
  <div class="col-6 margin-auto">
    <div *ngIf="!isTemplateEditing">
      <span
        class="hilite template-link"
        (click)="openChecklistTemplate($event, review?.checklistTemplate)"
        >{{ review?.checklistTemplate?.name }}</span
      >
      <span class="pill" [ngClass]="getStatusClass(templateStatus?.cssClass)">{{
        templateStatus?.name
      }}</span>
    </div>
    <div *ngIf="isTemplateEditing">
      <span class="hilite">
        <mat-form-field appearance="outline">
          <mat-label>Checklist Template</mat-label>
          <input
            type="text"
            placeholder="Select Checklist Template"
            matInput
            name="checklistTemplateCtrl"
            [formControl]="checklistTemplateCtrl"
            [matAutocomplete]="autoChecklistTemplate"
          />
          <mat-autocomplete
            #autoChecklistTemplate="matAutocomplete"
            #checklistTemplateAutocomplete
            [displayWith]="displayObject"
          >
            <mat-option
              *ngFor="let checklistTemplate of checklistTemplatesFiltered"
              [value]="checklistTemplate"
            >
              <span>{{ checklistTemplate.name }}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </span>
    </div>
  </div>
  <div class="col-2 margin-auto pr-4">
    <button
      *ngIf="
        !isTemplateEditing &&
        checkPrivilege(privilege.ChangeReviewTemplate) &&
        checkStatus()
      "
      class="float-right"
      mat-icon-button
      color="accent"
      (click)="edit()"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <span class="float-right" *ngIf="isTemplateEditing">
      <button
        mat-flat-button
        class="small-button green-background"
        (click)="save()"
      >
        <mat-icon class="button-icon">save</mat-icon>
      </button>
      <button
        mat-flat-button
        class="small-button"
        color="warn"
        (click)="cancel()"
      >
        <mat-icon class="button-icon">cancel</mat-icon>
      </button>
    </span>
  </div>
</div>
<div class="row" *ngIf="review?.checklist || isChecklistEditing">
  <div class="col-4 margin-auto">Checklist:</div>
  <div class="col-6 margin-auto">
    <div *ngIf="!isChecklistEditing">
      <span
        class="hilite template-link"
        (click)="openChecklist($event, review?.checklist)"
        >{{ review?.checklist?.name }}</span
      >
      <span
        class="pill"
        [ngClass]="getStatusClass(review?.checklist?.checklistStatus?.cssClass)"
        >{{ review?.checklist?.checklistStatus?.name }}</span
      >
    </div>

    <div *ngIf="isChecklistEditing">
      <span class="hilite">
        <mat-form-field appearance="outline" class="no-subscript">
          <mat-label>Checklists</mat-label>
          <input
            type="text"
            placeholder="Select Checklist"
            matInput
            name="checklistCtrl"
            [formControl]="checklistCtrl"
            [matAutocomplete]="autoChecklist"
          />
          <mat-autocomplete
            #autoChecklist="matAutocomplete"
            #checklistAutocomplete
            [displayWith]="displayObject"
          >
            <mat-option
              *ngFor="let checklist of checklistsFiltered"
              [value]="checklist"
            >
              <span>{{ checklist.name }}</span>
              <span
                style="margin-left: 10px"
                class="pill"
                [ngClass]="getStatusClass(checklist.checklistStatus?.cssClass)"
                >{{ checklist.checklistStatus?.name }}</span
              >
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </span>
    </div>
  </div>

  <div class="col-2 margin-auto pr-4">
    <span
      class="d-flex justify-content-end"
      *ngIf="
        !isChecklistEditing && checkPrivilege(privilege.ChangeReviewTemplate)
      "
    >
      <button mat-icon-button color="accent" (click)="editChecklist()">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button color="warn" (click)="deleteChecklist()">
        <mat-icon>delete</mat-icon>
      </button>
    </span>
    <span
      class="d-flex justify-content-end align-items-start"
      *ngIf="isChecklistEditing"
    >
      <button
        mat-flat-button
        class="small-button green-background"
        (click)="saveChecklist()"
      >
        <mat-icon class="button-icon">save</mat-icon>
      </button>
      <button
        mat-flat-button
        class="small-button"
        color="warn"
        (click)="cancelChecklist()"
      >
        <mat-icon class="button-icon">cancel</mat-icon>
      </button>
    </span>
  </div>
</div>
