import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResourceType } from 'src/app/common/enumerations/enumerations';
import { Resource, ResourceRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { CatalogService } from '../../../catalog-service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-vacuum',
  templateUrl: './vacuum.component.html',
  styleUrls: ['./vacuum.component.scss']
})
export class VacuumComponent extends BaseComponent implements OnInit {

  public vacuums!: Resource[];
  public vacuumsFiltered!: Resource[];

  public vacuumId!: number | null;

  public vacuumName?: string | null;
  public vacuumMinPressure?: string | null;
  public vacuumMaxPressure?: string | null;
  public vacuumChannelName?: string | null;
  public vacuumLocation?: string | null;
  public vacuumSharedWith?: string | null;
  public oldVacuum?: string | null;

  public checkIsRepeated = true;
  public isSaving!: boolean;

  resources?: Resource[];
  resourcesFiltered?: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  resourceRelations?: ResourceRelation[];
  resourceRelationsFiltered?: ResourceRelation[];
  resourceRelations$!: Observable<ResourceRelation[]>;
  resourceRelationsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<VacuumComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      resource: Resource,
      shutter: Resource
    },
    private catalogService: CatalogService,
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.loadResources();
    this.vacuumId = this.data.resource.id;
    this.vacuumName = this.data.resource.name;
    this.vacuumMinPressure = this.data.resource.MinPressureString;
    this.vacuumMaxPressure = this.data.resource.MaxPressureString;
    this.vacuumChannelName = this.data.resource.channelName;
    this.vacuumLocation = this.data.resource.location;
    this.vacuumSharedWith = this.data.resource.sharedWith;
    this.oldVacuum = this.data.resource.name;
    this.loadResources();
    this.loadResourceRelations();
    if (this.vacuumName)
      this.filter(this.vacuumName);
  }

  override ngOnDestroy(): void {
    this.resourcesSubs?.unsubscribe();
    this.resourceRelationsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data?.length) {
        this.resources = data;
        this.vacuums = this.resources.filter(r => r.type == ResourceType.Vacuum);
        this.vacuumsFiltered = this.vacuums;
      }
    });
  }

  loadResourceRelations() {
    this.resourceRelations$ = this.store.select(state => state.ResourceRelations.data);
    this.resourceRelationsSubs = this.resourceRelations$.subscribe(data => {
      if (data?.length) {
        this.resourceRelations = data;
      }
    });
  }

  getInfoVacuum(vacuum: Resource) {
    this.resourceRelationsFiltered = this.resourceRelations?.filter(r => r.childResourceID == vacuum.id);
    this.vacuumSharedWith = this.resourceRelationsFiltered?.find(x => x.parentResourceType === ResourceType.Shutter && x.parentResourceID !== this.data.shutter?.id)?.parentResourceName;
    this.vacuumId = vacuum.id;
    this.vacuumMinPressure = vacuum.keVacuumMinRequirement?.toString();
    this.vacuumMaxPressure = vacuum.keVacuumMaxRequirement?.toString();
    this.vacuumChannelName = vacuum.channelName;
    this.vacuumLocation = vacuum.location;
    this.checkIsRepeated = false;
  }

  async add() {
    this.isSaving = true;
    if (await this.isValid()) {
      this.data.resource.name = this.vacuumName;
      if (this.vacuumMinPressure) {
        this.data.resource.MinPressureString = this.vacuumMinPressure;
      }
      else { this.data.resource.MinPressureString = this.formatSciNotation('0'); }
      if (this.vacuumMaxPressure) {
        this.data.resource.MaxPressureString = this.vacuumMaxPressure;
      }
      else { this.data.resource.MaxPressureString = this.formatSciNotation('0'); }
      this.data.resource.channelName = this.vacuumChannelName;
      this.data.resource.location = this.vacuumLocation;
      this.data.resource.sharedWith = this.vacuumSharedWith;
      this.dialogRef.close(this.data.resource);
    } else {
      this.isSaving = false;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  async isValid() {
    if (!this.vacuumName) {
      this.alert.message('vacuumDialog_Required');
      return false;
    }
    if (this.oldVacuum !== this.vacuumName && this.checkIsRepeated) {
      const isRepeated = await this.catalogService.IsFieldRepeatedResource(this.vacuumName, ResourceType.Vacuum).toPromise();
      if (isRepeated) {
        this.alert.message('vacuumDialog_Repeated');
        return false;
      }
    }
    return true;
  }


  RemoveInfo(e: any) {
    const text = e.target.value;
    if (!this.vacuums?.filter(x => x.name === text).length) {
      this.vacuumId = null;
      this.vacuumSharedWith = null;
      this.checkIsRepeated = true;
    }
  }

  formatSciNotation(numberString: string): string {
    if (numberString === '0') { return ''; }
    else {
      if (numberString.includes('^')) {
        numberString = numberString.replace(/\s/g, '').replace('x10', 'e').replace('^-', '-');
      }
      return (+numberString).toPrecision(3).toString().replace('e', ' x 10').replace('-', '^-');
    }
  }

  changeFormatMin() {
    if (this.vacuumMinPressure) {
      this.vacuumMinPressure = this.formatSciNotation(this.vacuumMinPressure);
    }
    else { this.vacuumMinPressure = this.formatSciNotation('0'); }
  }

  changeFormatMax() {
    if (this.vacuumMaxPressure) {
      this.vacuumMaxPressure = this.formatSciNotation(this.vacuumMaxPressure);
    }
    else { this.vacuumMaxPressure = this.formatSciNotation('0'); }
  }

  filter(e: any) {
    const text = e.target.value;
    const code = e.code;
    if (!code?.includes('Arrow')) {
      this.vacuumsFiltered = this.vacuums?.filter(x => x.name?.toLocaleLowerCase().includes(text.trim().toLocaleLowerCase()));
    }
  }

}
