export class MessagePlaceholder {
  public placeholder: string;
  public value?: string | null;

  constructor(placeholder: string, value?: string | null) {
    this.placeholder = placeholder;
    this.value = value;
  }
}

export class KeyValuePair {
  keyValuePair!: [key: string, value: any];
}
