<mat-form-field>
  <mat-label>Location</mat-label>
  <input
    matInput
    aria-label="locations"
    #locationInput
    [matAutocomplete]="auto"
    (keyup)="change($event)"
    [formControl]="locationCtrl"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayObject"
    (optionSelected)="changed()"
    #locationAutocomplete
  >
    <mat-optgroup
      *ngFor="let group of resourceGroups"
      [label]="group.name ?? ''"
    >
      <mat-option *ngFor="let location of group.locations" [value]="location">
        {{ location.name }}
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>
</mat-form-field>
<mat-slide-toggle [(ngModel)]="longShutdown" (change)="changed()" class="small">
  Include Long Shutdown Shielding Checks?
</mat-slide-toggle>
<mat-slide-toggle [(ngModel)]="inTunnel" (change)="changed()" class="small">
  In Tunnel?
</mat-slide-toggle>
