<h5 class="section-title">History Log</h5>
<div class="section p-2 mt-3">
  <mat-tab-group>
    <mat-tab label="Approvals">
      <div class="tab-container">
        <mat-list>
          <mat-list-item *ngFor="let signature of signLogs">
            <div class="d-flex align-items-center">
              <span class="date">{{
                signature.signedOn | date : "medium"
              }}</span>
              <mat-icon color="primary" mat-list-icon>arrow_right_alt</mat-icon>

              <span>
                <b class="blue"> {{ signature.name }}</b> by
                <b>{{ signature.signedBy }} </b></span
              >
              <mat-icon
                color="accent"
                class="ml-3"
                *ngIf="signature.reason && signature.reason != ''"
                >comment</mat-icon
              >
              <span
                class="reason"
                *ngIf="signature.reason && signature.reason != ''"
              >
                <span class="comment"> {{ signature.reason }}</span></span
              >
            </div>
            <!-- <mat-icon mat-list-icon>arrow_right_alt</mat-icon>
            <div mat-line>
              {{ signature.name }} by {{ signature.signedBy }} on
              {{ signature.signedOn | date : "medium" }}
              <span
                mat-line
                *ngIf="
                  signature.reason != undefined &&
                  signature.reason != '' &&
                  signature.reason != null
                "
              >
                Disapproval reason: {{ signature.reason }}</span
              >
            </div> -->
          </mat-list-item>
        </mat-list>
      </div>
    </mat-tab>
    <mat-tab label="Amendments">
      <mat-accordion>
        <!--Original body-->
        <mat-expansion-panel *ngIf="this.generalLogs">
          <mat-expansion-panel-header>
            <mat-panel-title> Initial Values </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-12">
              <mat-list *ngIf="hasOriginal">
                <div mat-subheader>1. Description of Work</div>
                <mat-list-item>
                  <div mat-line>
                    <span class="bold">Creation Date: </span>
                    {{ originalBody.createdOn }}
                  </div>
                </mat-list-item>
                <mat-list-item>
                  <div mat-line>
                    <span class="bold">Operator initiating form: </span>
                    {{ originalBody.createdByName }}
                  </div>
                </mat-list-item>
                <mat-list-item>
                  <div mat-line>
                    <span class="bold">Requester: </span>
                    {{ originalBody.requesterName }}
                  </div>
                </mat-list-item>
                <mat-list-item>
                  <div mat-line>
                    <span class="bold">Related Documents: </span>
                    <span
                      *ngIf="
                        !originalBody?.relatedDocuments ||
                        maprelatedDocument(originalBody.relatedDocuments) ==
                          '' ||
                        maprelatedDocument(originalBody.relatedDocuments) ==
                          null
                      "
                    >
                      Empty
                    </span>
                    <span
                      *ngIf="
                        originalBody?.relatedDocuments &&
                        maprelatedDocument(originalBody.relatedDocuments) !=
                          '' &&
                        maprelatedDocument(originalBody.relatedDocuments) !=
                          null
                      "
                    >
                      {{ maprelatedDocument(originalBody.relatedDocuments) }}
                    </span>
                  </div>
                </mat-list-item>
                <mat-list-item>
                  <div mat-line>
                    <span class="bold">Estimated Start Date: </span>
                    {{ originalBody.startOn | date : "medium" }}
                  </div>
                </mat-list-item>
                <mat-list-item>
                  <div mat-line>
                    <span class="bold">Estimated End Date: </span>
                    {{ originalBody.completeOn | date : "medium" }}
                  </div>
                </mat-list-item>
                <mat-list-item>
                  <div mat-line>
                    <span class="bold"
                      >Group(s) / Person(s) Performing Work:
                    </span>
                    <span
                      *ngIf="
                        !originalBody?.workPerformers ||
                        mapWorkPerformers(originalBody.workPerformers) == '' ||
                        mapWorkPerformers(originalBody.workPerformers) == null
                      "
                    >
                      Empty
                    </span>
                    <span
                      *ngIf="
                        originalBody?.workPerformers &&
                        mapWorkPerformers(originalBody.workPerformers) != '' &&
                        mapWorkPerformers(originalBody.workPerformers) != null
                      "
                    >
                      {{ mapWorkPerformers(originalBody.workPerformers) }}
                    </span>
                  </div>
                </mat-list-item>
                <mat-list-item>
                  <div mat-line>
                    <span class="bold">Systems Affected: </span>
                    <span
                      *ngIf="
                        mapSystemsAffected(originalBody) == '' ||
                        mapSystemsAffected(originalBody) == null
                      "
                    >
                      Empty
                    </span>
                    <span
                      *ngIf="
                        mapSystemsAffected(originalBody) != '' &&
                        mapSystemsAffected(originalBody) != null
                      "
                    >
                      {{ mapSystemsAffected(originalBody) }}
                    </span>
                  </div>
                </mat-list-item>
                <mat-list-item>
                  <div mat-line>
                    <span class="bold">Locations: </span>
                    <span
                      *ngIf="
                        !originalBody?.rsswaAcceleratorLocations ||
                        mapRSSWAAcceleratorLocations(
                          originalBody.rsswaAcceleratorLocations
                        ) == '' ||
                        mapRSSWAAcceleratorLocations(
                          originalBody.rsswaAcceleratorLocations
                        ) == null
                      "
                    >
                      Empty
                    </span>
                    <span
                      *ngIf="
                        originalBody?.rsswaAcceleratorLocations &&
                        mapRSSWAAcceleratorLocations(
                          originalBody.rsswaAcceleratorLocations
                        ) != '' &&
                        mapRSSWAAcceleratorLocations(
                          originalBody.rsswaAcceleratorLocations
                        ) != null
                      "
                    >
                      {{
                        mapRSSWAAcceleratorLocations(
                          originalBody.rsswaAcceleratorLocations
                        )
                      }}
                    </span>
                  </div>
                </mat-list-item>
                <mat-list-item>
                  <div mat-line>
                    <span class="bold">RSSWA Shutters: </span>
                    <span
                      *ngIf="
                        !originalBody?.rsswaShutters ||
                        mapRSSWAShutters(originalBody.rsswaShutters) == '' ||
                        mapRSSWAShutters(originalBody.rsswaShutters) == null
                      "
                    >
                      Empty
                    </span>
                    <span
                      *ngIf="
                        originalBody?.rsswaShutters &&
                        mapRSSWAShutters(originalBody.rsswaShutters) != '' &&
                        mapRSSWAShutters(originalBody.rsswaShutters) != null
                      "
                    >
                      {{ mapRSSWAShutters(originalBody.rsswaShutters) }}
                    </span>
                  </div>
                </mat-list-item>
                <mat-list-item *ngIf="originalBody?.rssplcSet">
                  <div mat-line>
                    <span class="bold">RSS PLC Set Pt / Parameter Access</span>
                  </div>
                </mat-list-item>
                <mat-list-item *ngIf="originalBody?.rssSoftware">
                  <div mat-line>
                    <span class="bold">RSS Software / Firmware Download</span>
                  </div>
                </mat-list-item>
                <mat-list-item *ngIf="originalBody?.etiAccess">
                  <div mat-line>
                    <span class="bold"
                      >ETI Access / TOMBIS Modification / TOMBIS Removal of
                      Components</span
                    >
                  </div>
                </mat-list-item>
                <mat-list-item *ngIf="originalBody?.tombisplcSet">
                  <div mat-line>
                    <span class="bold"
                      >TOMBIS PLC Set Pt / Parameter Access</span
                    >
                  </div>
                </mat-list-item>
                <mat-list-item *ngIf="originalBody?.tombisSoftware">
                  <div mat-line>
                    <span class="bold"
                      >TOMBIS Software / Firmware Download</span
                    >
                  </div>
                </mat-list-item>
                <mat-list-item *ngIf="originalBody?.topOffInterlockSetPoint">
                  <div mat-line>
                    <span class="bold"
                      >TOP OFF Interlock set Point and/or Interlock threshold
                      modification</span
                    >
                  </div>
                </mat-list-item>
                <mat-list-item>
                  <div mat-line>
                    <span class="bold">Description of work: </span>
                    {{ originalBody.description }}
                  </div>
                </mat-list-item>
                <div mat-subheader>2. Work Controls</div>
                <mat-list-item
                  *ngFor="let item of originalBody.rsswaRestrictions"
                >
                  <div mat-line>
                    <span class="bold">Operational Restriction: </span>
                    <span *ngIf="!item.restriction">{{ item.text }}</span>
                    <span *ngIf="item.restriction">{{
                      item.restriction.restrictionLanguage
                    }}</span>
                  </div>
                </mat-list-item>
              </mat-list>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Updates -->
        <div *ngIf="generalLogs?.updates">
          <mat-expansion-panel *ngFor="let update of generalLogs?.updates">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Amendment
                {{
                  findIndex(update?.amendmentId) != 0
                    ? findIndex(update?.amendmentId)
                    : "Cancelled"
                }}
                created on {{ update?.amendmentDate | date : "medium" }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <br />
            <h6>
              <strong>{{ getRSSWALogHeadText(update.amendmentId) }}</strong>
            </h6>
            <div class="row">
              <div class="col-md-12 col-lg-7">
                <mat-list>
                  <div mat-subheader>1. Description of Work</div>
                  <mat-list-item>
                    <div mat-line>
                      <span class="bold">Creation Date: </span>
                      {{ update?.oldBodyRSSWA?.createdOn }}
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div mat-line>
                      <span class="bold">Operator initiating form: </span>
                      {{ update?.oldBodyRSSWA?.createdByName }}
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div mat-line>
                      <span class="bold">Requester: </span>
                      {{ update?.oldBodyRSSWA?.requesterName }}
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div mat-line>
                      <span class="bold">Related Documents: </span>
                      <span
                        *ngIf="
                          !update?.oldBodyRSSWA?.relatedDocuments ||
                          maprelatedDocument(
                            update?.oldBodyRSSWA?.relatedDocuments
                          ) == '' ||
                          maprelatedDocument(
                            update?.oldBodyRSSWA?.relatedDocuments
                          ) == null
                        "
                      >
                        Empty
                      </span>
                      <span
                        *ngIf="
                          update?.oldBodyRSSWA?.relatedDocuments &&
                          maprelatedDocument(
                            update?.oldBodyRSSWA?.relatedDocuments
                          ) != '' &&
                          maprelatedDocument(
                            update?.oldBodyRSSWA?.relatedDocuments
                          ) != null
                        "
                      >
                        {{
                          maprelatedDocument(
                            update?.oldBodyRSSWA?.relatedDocuments
                          )
                        }}
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div mat-line>
                      <span class="bold">Estimated Start Date: </span>
                      {{ update?.oldBodyRSSWA?.startOn | date : "medium" }}
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div mat-line>
                      <span class="bold">Estimated End Date: </span>
                      {{ update?.oldBodyRSSWA?.completeOn | date : "medium" }}
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div mat-line>
                      <span class="bold"
                        >Group(s) / Person(s) Performing Work:
                      </span>
                      <span
                        *ngIf="
                          !update?.oldBodyRSSWA?.workPerformers ||
                          mapWorkPerformers(
                            update?.oldBodyRSSWA?.workPerformers
                          ) == '' ||
                          mapWorkPerformers(
                            update?.oldBodyRSSWA?.workPerformers
                          ) == null
                        "
                      >
                        Empty
                      </span>
                      <span
                        *ngIf="
                          update?.oldBodyRSSWA?.workPerformers &&
                          mapWorkPerformers(
                            update?.oldBodyRSSWA?.workPerformers
                          ) != '' &&
                          mapWorkPerformers(
                            update?.oldBodyRSSWA?.workPerformers
                          ) != null
                        "
                      >
                        {{
                          mapWorkPerformers(
                            update?.oldBodyRSSWA?.workPerformers
                          )
                        }}
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div mat-line>
                      <span class="bold">Systems Affected: </span>
                      <span
                        *ngIf="
                          mapSystemsAffected(update?.oldBodyRSSWA) == '' ||
                          mapSystemsAffected(update?.oldBodyRSSWA) == null
                        "
                      >
                        Empty
                      </span>
                      <span
                        *ngIf="
                          mapSystemsAffected(update?.oldBodyRSSWA) != '' &&
                          mapSystemsAffected(update?.oldBodyRSSWA) != null
                        "
                      >
                        {{ mapSystemsAffected(update?.oldBodyRSSWA) }}
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div mat-line>
                      <span class="bold">Locations: </span>
                      <span
                        *ngIf="
                          !update?.oldBodyRSSWA?.rsswaAcceleratorLocations ||
                          mapRSSWAAcceleratorLocations(
                            update?.oldBodyRSSWA?.rsswaAcceleratorLocations
                          ) == '' ||
                          mapRSSWAAcceleratorLocations(
                            update?.oldBodyRSSWA?.rsswaAcceleratorLocations
                          ) == null
                        "
                      >
                        Empty
                      </span>
                      <span
                        *ngIf="
                          update?.oldBodyRSSWA?.rsswaAcceleratorLocations &&
                          mapRSSWAAcceleratorLocations(
                            update?.oldBodyRSSWA?.rsswaAcceleratorLocations
                          ) != '' &&
                          mapRSSWAAcceleratorLocations(
                            update?.oldBodyRSSWA?.rsswaAcceleratorLocations
                          ) != null
                        "
                      >
                        {{
                          mapRSSWAAcceleratorLocations(
                            update?.oldBodyRSSWA?.rsswaAcceleratorLocations
                          )
                        }}
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div mat-line>
                      <span class="bold">RSSWA Shutters: </span>
                      <span
                        *ngIf="
                          !update?.oldBodyRSSWA?.rsswaShutters ||
                          mapRSSWAShutters(
                            update?.oldBodyRSSWA?.rsswaShutters
                          ) == '' ||
                          mapRSSWAShutters(
                            update?.oldBodyRSSWA?.rsswaShutters
                          ) == null
                        "
                      >
                        Empty
                      </span>
                      <span
                        *ngIf="
                          update?.oldBodyRSSWA?.rsswaShutters &&
                          mapRSSWAShutters(
                            update?.oldBodyRSSWA?.rsswaShutters
                          ) != '' &&
                          mapRSSWAShutters(
                            update?.oldBodyRSSWA?.rsswaShutters
                          ) != null
                        "
                      >
                        {{
                          mapRSSWAShutters(update?.oldBodyRSSWA?.rsswaShutters)
                        }}
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item *ngIf="update?.oldBodyRSSWA?.rssplcSet">
                    <div mat-line>
                      <span class="bold"
                        >RSS PLC Set Pt / Parameter Access</span
                      >
                    </div>
                  </mat-list-item>
                  <mat-list-item *ngIf="update?.oldBodyRSSWA?.rssSoftware">
                    <div mat-line>
                      <span class="bold">RSS Software / Firmware Download</span>
                    </div>
                  </mat-list-item>
                  <mat-list-item *ngIf="update?.oldBodyRSSWA?.etiAccess">
                    <div mat-line>
                      <span class="bold"
                        >ETI Access / TOMBIS Modification / TOMBIS Removal of
                        Components</span
                      >
                    </div>
                  </mat-list-item>
                  <mat-list-item *ngIf="update?.oldBodyRSSWA?.tombisplcSet">
                    <div mat-line>
                      <span class="bold"
                        >TOMBIS PLC Set Pt / Parameter Access</span
                      >
                    </div>
                  </mat-list-item>
                  <mat-list-item *ngIf="update?.oldBodyRSSWA?.tombisSoftware">
                    <div mat-line>
                      <span class="bold"
                        >TOMBIS Software / Firmware Download</span
                      >
                    </div>
                  </mat-list-item>
                  <mat-list-item
                    *ngIf="update?.oldBodyRSSWA?.topOffInterlockSetPoint"
                  >
                    <div mat-line>
                      <span class="bold"
                        >TOP OFF Interlock set Point and/or Interlock threshold
                        modification</span
                      >
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div mat-line>
                      <span class="bold">Description of work: </span>
                      {{ update?.oldBodyRSSWA?.description }}
                    </div>
                  </mat-list-item>
                  <div mat-subheader>2. Work Controls</div>
                  <mat-list-item
                    *ngFor="let item of update?.oldBodyRSSWA?.rsswaRestrictions"
                  >
                    <div mat-line>
                      <span class="bold">Operational Restriction: </span>
                      <span *ngIf="!item.restriction">{{ item.text }}</span>
                      <span *ngIf="item.restriction">{{
                        item.restriction.restrictionLanguage
                      }}</span>
                    </div>
                  </mat-list-item>
                  <mat-list-item *ngIf="update?.oldBodyRSSWA?.functionalTest">
                    <div mat-line>
                      <span class="bold">RSSWA Functional Test</span>
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div mat-line>
                      <span class="bold"
                        >Testing Required Before Operation (Full Test,
                        Functional Test):
                      </span>
                      <span>{{
                        update?.oldBodyRSSWA?.provisionalTestingRequired
                      }}</span>
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div mat-line>
                      <span class="bold">Related Documents and Links: </span>
                      <div
                        *ngFor="
                          let item of update?.oldBodyRSSWA?.rsswaRelatedLinks;
                          let i = index
                        "
                      >
                        <a href="{{ item.url }}" *ngIf="item.url">{{
                          item.text
                        }}</a>
                        <span *ngIf="!item.url">{{ item.text }}</span>
                        <br />
                      </div>
                    </div>
                  </mat-list-item>
                </mat-list>
                <br />
                <h6>
                  <strong>{{
                    getRSSWALogFooterText(update.amendmentId)
                  }}</strong>
                </h6>
                <mat-expansion-panel *ngIf="update?.added?.length">
                  <!--Added-->
                  <mat-expansion-panel-header>
                    <mat-panel-title> Added </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-list>
                    <mat-list-item *ngFor="let addedField of update?.added">
                      <div mat-line>
                        <span class="bold">{{ addedField.name }}</span>
                        <span *ngIf="addedField?.change != 'True'"
                          >: {{ addedField.change }}</span
                        >
                      </div>
                    </mat-list-item>
                  </mat-list>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="update?.deleted?.length">
                  <!--Deleted-->
                  <mat-expansion-panel-header>
                    <mat-panel-title> Deleted </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-list>
                    <mat-list-item *ngFor="let deletedField of update?.deleted">
                      <div mat-line>
                        <span class="bold">{{ deletedField.name }}</span>
                        <span *ngIf="deletedField?.change != 'False'"
                          >: {{ deletedField.change }}</span
                        >
                      </div>
                    </mat-list-item>
                  </mat-list>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="update?.modified?.length">
                  <!--Modified-->
                  <mat-expansion-panel-header>
                    <mat-panel-title> Modified </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-list>
                    <mat-list-item
                      *ngFor="let modifiedField of update?.modified"
                    >
                      <div mat-line>
                        <span class="bold">{{ modifiedField.name }}: </span>
                        {{ modifiedField.change }}
                      </div>
                    </mat-list-item>
                  </mat-list>
                </mat-expansion-panel>
              </div>
              <div class="col-md-12 col-lg-5 mt-2">
                <div class="section">
                  <mat-list *ngIf="update?.signs?.length">
                    <div mat-subheader>Signatures</div>
                    <div *ngFor="let signature of update?.signs; let i = index">
                      <mat-list-item class="h7">
                        {{ signature.name }} by {{ signature.signedBy }} on
                        {{ signature.signedOn | date : "medium" }}
                      </mat-list-item>
                      <mat-divider
                        *ngIf="(update?.signs?.length ?? 0) > i + 1"
                      ></mat-divider>
                    </div>
                  </mat-list>
                  <div *ngIf="update?.signs?.length == 0">
                    <h5>Signatures</h5>
                    <h6>There are not signatures</h6>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
    </mat-tab>
  </mat-tab-group>
</div>
