import { BeamlineStatus } from "../beamline-status";


export class BeamlineStatusRefresh {
  static readonly type = '[BeamlineStatus] Refresh';
}
export class BeamlineStatusCreate {
  static readonly type = '[BeamlineStatus] Create';
  constructor(public item: BeamlineStatus) { }
}
export class BeamlineStatusUpdate {
  static readonly type = '[BeamlineStatus] Update';
  constructor(public id: number, public item: BeamlineStatus) { }
}
export class BeamlineStatusDelete {
  static readonly type = '[BeamlineStatus] Delete';
  constructor(public id: number) { }
}
