import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, Injector } from '@angular/core';
import { ScfV2MainS4HoldpointsAddComponent } from './scf-v2-main-s4-holdpoints-add/scf-v2-main-s4-holdpoints-add.component';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { DataButtonSignature } from 'src/app/common/models/data-button-signature';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { WfSignature, WfSignatureResource, WFTaskSignatureResource } from 'src/app/services/work-flow/work-flow';
import { SCFMaster, SCFHoldPointResource, SaveSCFStatusResource } from '../../../scf';
import { SCFPrivileges, SCFStatus, ScfV2Service } from '../../../scf-v2.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { utils } from 'src/app/modules/libs/utils';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'scf-v2-main-s4-holdpoints',
  templateUrl: './scf-v2-main-s4-holdpoints.component.html',
  styleUrls: ['./scf-v2-main-s4-holdpoints.component.scss']
})
export class ScfV2MainS4HoldpointsComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() scfMaster?: SCFMaster | null;
  @Input() disabled? = true;
  @Input() privileges?: SCFPrivileges;
  @Input() status?: SCFStatus;

  @Output() changed = new EventEmitter<SCFMaster>();
  @Output() loading = new EventEmitter<boolean>();

  disableControls = true;

  signatures?: WfSignature[];
  wfSignatures?: WfSignatureResource[];
  wfSignCount?: number;

  holdPoints?: SCFHoldPointResource[];
  enableReorder?: boolean;

  constructor(
    private scfService: ScfV2Service,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setDisabled();
    this.getValues();
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {

  }

  setDisabled() {
    this.disabled =
      (this.scfMaster?.status !== FormStatusEnum.Active && this.scfMaster?.status !== FormStatusEnum.AwaitingInspection && this.scfMaster?.status !== FormStatusEnum.HoldPoint) ||
      !this.getCurrentUser();
  }

  getValues() {
    this.wfSignatures = [];
    this.signatures = [];
    if (this.scfMaster) {
      this.wfSignCount = this.wfSignatures?.filter(x => x.signed).length;
      this.holdPoints = this.scfMaster.scfHoldPoints?.filter(x => x.status == 1).sort((a, b) => a.number - b.number);
      this.createSignatures();
    }
  }

  createSignatures() {
    this.wfSignatures = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals?.find(x => x.number == 3)?.wfSignatures?.filter(x => x.wfTaskLocal.wfStepLocal?.code == 'HOLDPOINT' && x.holdPointID && x.required);
    if (this.wfSignatures) {
      for (const t of this.wfSignatures) {
        if (t.required) {
          const signature: WfSignature = {
            id: t.id,
            approveId: t.approveID,
            unapproveId: t.unapproveID,
            disapproveId: t.disapproveID,
            amendmentId: t.amendmentID,
            name: t.titleBefore,
            roles: t.roles.map(r => r?.id),
            roleCodes: !t.requesterSignature ? t.roles.sort((a, b) => utils.sort(a.code, b.code)).map(r => r?.code).join('/') : undefined,
            disabled: !t.enabled || this.disabled || t.disabled,
            approvedBy: t.signedBy,
            approved: t.signed,
            approvedOn: t.signedOn,
            approve: t.approveEnabled && !this.userSigned(t),
            unapprove: t.unapproveEnabled,
            disapprove: t.disapproveEnabled,
            amendment: t.amendmentEnabled,
            required: t.required,
            taskId: t.taskID,
            value: t.value,
            warning: false,
            enabled: t.enabled,
            holdPointID: t.holdPointID,
            requesterSignature: t.requesterSignature,
            userNames: t.requesterSignature ? this.scfMaster?.requesterName : undefined
          };
          this.wfSignCount = this.signCount();
          this.signatures?.push(signature);
        }
      }
    }
  }

  userSigned(t: WfSignatureResource) {
    const alreadySigned = this.wfSignatures?.find(x => x.holdPointID == t.holdPointID && x.signedBy?.id == this.getCurrentUser()?.id && x.id != t.id);
    return alreadySigned != null;
  }

  getSignatures(holdPoint: SCFHoldPointResource) {
    let number = 1;
    const signatures = this.signatures?.filter(x => x.holdPointID == holdPoint.id);
    signatures?.map(s => {
      s.name = 'Hold Point ' + holdPoint.number + ' Inspection ' + number;
      number++;
    });
    return signatures;
  }

  signCount() {
    let count = 0;
    if (this.wfSignatures) {
      this.wfSignatures.map(t => {
        if (t?.signed) { count++; }
      });
    }
    return count;
  }

  approve(s: WfSignature) {
    const wfTaskSignature: WFTaskSignatureResource = {
      taskId: s.taskId,
      actionId: s.approveId
    };

    this.loading.emit(true);
    this.scfService.sign(wfTaskSignature).toPromise().then(res => {
      this.loading.emit(false);
    }).catch(error => {
      console.log(error);
      this.alert.defaultError();
      this.loading.emit(false);
    });
  }

  async disapprove(s: WfSignature, data: DataButtonSignature) {
    this.loading.emit(true);

    // Get an array of promises
    const signPromises = this.signatures
      ?.filter(x => x.holdPointID == s.holdPointID && x.approved)
      .map(s => {
        const wfTaskSignature: WFTaskSignatureResource = {
          taskId: s.taskId,
          actionId: s.unapproveId,
          reason: data.text
        };
        return this.scfService.sign(wfTaskSignature).toPromise().then(() => {
          const signature = this.signatures?.find(x => x.id == s.id);
          if (signature) signature.approved = false;
        });
      });

    // Wait for all promises to resolve
    if (signPromises) {
      await Promise.all(signPromises);
    }

    this.checkStatus(data);
    this.loading.emit(false);
  }


  unapprove(s: WfSignature) {
    this.loading.emit(true);
    const wfTaskSignature: WFTaskSignatureResource = {
      taskId: s.taskId,
      actionId: s.unapproveId
    };
    this.scfService.sign(wfTaskSignature).toPromise().then(() => {
      const signature = this.signatures?.find(x => x.id == s.id);
      if (signature) signature.approved = false;
      this.checkStatus();
      this.loading.emit(false);
    }, error => {
      console.log(error);
      this.alert.defaultError();
      this.loading.emit(false);
    });
  }

  async checkStatus(data?: any) {
    if (this.scfMaster && !this.signatures?.some(x => x.approved)) {
      this.loading.emit(true);
      const scf = {
        id: this.scfMaster.id,
        status: FormStatusEnum.Active,
        disapprovalReason: data?.text
      } as SaveSCFStatusResource;
      await this.scfService.putStatus(scf).toPromise();
      this.loading.emit(false);
    }
  }

  async update() {
    if (this.scfMaster)
      return await this.scfService.update(this.scfMaster).toPromise();
    else return null;
  }

  createHoldPoint() {
    const scfHoldPoint = {
      id: 0
    } as SCFHoldPointResource;
    const confirm = this.dialog.open(ScfV2MainS4HoldpointsAddComponent, {
      width: '65vw',
      disableClose: true,
      data: {
        scfMaster: this.scfMaster,
        scfHoldPoint
      }
    });
    confirm.afterClosed().toPromise().then(async data => {
      if (data && this.scfMaster) {
        this.loading.emit(true);
        const scfmaster = await this.update();
        this.scfService.createHoldPoint(data).toPromise().then(res => {
          this.loading.emit(false);
        }).catch(error => {
          console.log(error);
          this.alert.defaultError();
          this.loading.emit(false);
        });
      }
    });
  }

  editHoldPoint(scfHoldPoint: SCFHoldPointResource) {
    const confirm = this.dialog.open(ScfV2MainS4HoldpointsAddComponent, {
      width: '65vw',
      disableClose: true,
      data: {
        scfMaster: this.scfMaster,
        scfHoldPoint
      }
    });
    confirm.afterClosed().toPromise().then(async (data: SCFHoldPointResource) => {
      if (data && this.scfMaster) {
        this.loading.emit(true);
        const scfmaster = await this.update();
        this.scfService.updateHoldPoint(data).toPromise().then(res => {
          this.loading.emit(false);
        }).catch(error => {
          console.log(error);
          this.alert.defaultError();
          this.loading.emit(false);
        });
      }
    });
  }

  deleteHoldPoint(scfHoldPoint: SCFHoldPointResource) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      disableClose: true,
      data: {
        message: 'Are you sure you want to Delete this Hold Point?',
        icon: 'stop'
      }
    });
    confirm.afterClosed().toPromise().then(async data => {
      if (data && this.scfMaster) {
        this.loading.emit(true);
        const scfmaster = await this.update();
        this.scfService.deleteHoldPoint(scfHoldPoint).toPromise().then(res => {
          this.loading.emit(false);
        }).catch(error => {
          console.log(error);
          this.alert.defaultError();
          this.loading.emit(false);
        });
      }
    });
  }

  holdPointUnsigned(hp: SCFHoldPointResource) {
    return hp.wfTaskLocals?.filter(x => x.wfTasks?.some(t => t.signedBy)).length == 0;
  }

  nextHoldPointUnsigned(hp: SCFHoldPointResource) {
    const nextHoldPoint = this.scfMaster?.scfHoldPoints?.find(x => x.number > hp.number);
    return nextHoldPoint ? nextHoldPoint.wfTaskLocals?.filter(x => x.wfTasks?.some(t => t.signedBy)).length == 0 : true;
  }

  holdPointFinished(hp: SCFHoldPointResource) {
    return this.signatures?.filter(x => x.holdPointID == hp.id && !x.approved).length == 0;
  }

  // This method will be called when an item is dropped
  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.holdPoints ?? [], event.previousIndex, event.currentIndex);
    this.reorderArray();
  }

  reorderArray() {
    let number = 1;
    this.holdPoints?.map(h => {
      h.number = number;
      number++;
    });

    this.holdPoints = this.holdPoints?.sort((a, b) => a.number - b.number);
    this.scfService.updateHoldPointNumbers(this.holdPoints ?? []).toPromise();
  }
}
