<div class="card">
  <div class="card-header">
    <span class="card-title">Create a Note</span>
  </div>
  <div class="card-body">
    <mat-form-field appearance="outline">
      <mat-label [innerHTML]="label_note"></mat-label>
      <textarea matInput [(ngModel)]="note.description"></textarea>
      <mat-error
        *ngIf="note.description === ''"
        [innerHTML]="label_description_is_required"
      ></mat-error>
    </mat-form-field>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        color="warn"
        (click)="dialogRef.close()"
        [innerHTML]="label_cancel"
      ></button>
      <button
        mat-flat-button
        color="primary"
        (click)="accept()"
        [disabled]="!validateDescription()"
        [innerHTML]="label_accept"
      ></button>
    </div>
  </div>
</div>
