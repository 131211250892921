<div class="container-fluid mt-2">
  <div class="table-container mat-container">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="pvName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          PV Name
        </mat-header-cell>
        <mat-cell class="font-weight-bold" *matCellDef="let element" nowrap
          ><span [innerHTML]="element.pvName | highlightSearch : filter"></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Value
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [innerHTML]="element.value | highlightSearch : filter"></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Date
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span
            [innerHTML]="
              formatDatePipe(element.date, 'medium') | highlightSearch : filter
            "
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="resourceName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Resource
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span
            [innerHTML]="element.resourceName | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="resourceTypeName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span
            [innerHTML]="element.resourceTypeName | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            color="primary"
            (click)="refreshPV(element.pvName)"
          >
            <mat-icon>update</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            (click)="delete(element)"
            *ngIf="element.value == 'NaN' && isSA()"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        #matrow
        *matRowDef="let row; columns: displayedColumns"
        class="table-row"
        [matTooltip]="row.description"
        [matTooltipPosition]="'right'"
        matTooltipClass="tooltip-white tooltip-wide"
      ></mat-row>
    </mat-table>
  </div>
</div>
