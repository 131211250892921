import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PlaceholdersCreate, PlaceholdersDelete, PlaceholdersRefresh, PlaceholdersUpdate } from './placeholder.action';
import { Placeholder } from 'src/app/components/checklists/checklists';
import { PlaceholdersService } from '../placeholders.service';


export class PlaceholdersStateModel {
  data: Placeholder[] = [];
}

@State<PlaceholdersStateModel>({
  name: 'Placeholders',
  defaults: {
    data: []
  }
})
@Injectable()
export class PlaceholdersState {
  constructor(
    private _service: PlaceholdersService
  ) { }

  @Selector()
  static read(state: PlaceholdersStateModel) {
    return state.data;
  }

  @Action(PlaceholdersRefresh)
  refresh({ patchState }: StateContext<PlaceholdersStateModel>) {
    this._service.getAll().toPromise().then(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(PlaceholdersCreate)
  create({ getState, patchState }: StateContext<PlaceholdersStateModel>, { item }: PlaceholdersCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(PlaceholdersUpdate)
  update({ getState, patchState }: StateContext<PlaceholdersStateModel>, { id, item }: PlaceholdersUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(PlaceholdersDelete)
  delete({ getState, patchState }: StateContext<PlaceholdersStateModel>, { id }: PlaceholdersDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
