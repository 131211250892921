import { FormType } from "src/app/common/enumerations/enumerations";
import { EB2, EB2Save, EB2Handler } from "src/app/components/eb/eb";
import { User } from "../../components/catalogs/user-catalog/services/user";
import { FormStatus } from "./form-status";
import { PPSTB, PPSTBHandler, PPSTBSave } from "src/app/components/ppstb/ppstb";

export class FormVersion {
  public id!: number;
  public version!: number;
  public active!: boolean;
  public description!: string;
  public formID?: number;
  public statusID!: number;
  public previousVersionID?: number;
  public operatorInitiatingFormID!: number;
  public requesterID?: number;
  public cancelReason!: string;
  public disapprovalReason!: string;
  public createdBy?: number;
  public createdOn?: Date;
  public updatedBy?: number;
  public updatedOn?: Date;
  public status: FormStatus;
  public previousVersion?: FormVersion;
  public ppstb?: PPSTB;
  public eB2?: EB2;
  public operatorInitiatingForm: User;
  public requester: User;
  public createdByUser!: User;
  public updatedByUser!: User;
  public systemAffected? = '';

  constructor(type?: FormType) {
    if (type != null) {
      switch (type) {
        case FormType.PPSTB:
          this.ppstb = new PPSTB();
          break;

        case FormType.EBF:
          this.eB2 = new EB2();
          break;
      }
    }
    this.operatorInitiatingForm = new User();
    this.requester = new User();
    this.status = new FormStatus();
    this.statusID = 0;
  }
}

export class FormVersionSave {
  public id!: number;
  public version!: number;
  public active!: boolean;
  public description!: string;
  public formID?: number;
  public statusID!: number;
  public previousVersionID?: number;
  public operatorInitiatingFormID!: number;
  public requesterID?: number;
  public ppstb: PPSTBSave = new PPSTBSave;
  public eB2: EB2Save = new EB2Save;

  constructor(type: FormType | null) {
    switch (type) {
      case FormType.PPSTB:
        this.ppstb = new PPSTBSave();
        break;

      case FormType.EBF:
        this.eB2 = new EB2Save();
        break;
    }
  }
}

export class FormVersionHandler {
  static toSave(formVersion: FormVersion): FormVersionSave {
    const save = new FormVersionSave(null);

    save.id = formVersion.id;
    save.version = formVersion.version;
    save.active = formVersion.active;
    save.description = formVersion.description;
    save.formID = formVersion.formID;
    save.statusID = formVersion.statusID;
    save.previousVersionID = formVersion.previousVersionID;
    save.operatorInitiatingFormID = formVersion.operatorInitiatingFormID;
    save.requesterID = formVersion.requesterID;
    if (formVersion.ppstb != null) {
      save.ppstb = PPSTBHandler.toSave(formVersion.ppstb);
    }
    if (formVersion.eB2 != null) {
      save.eB2 = EB2Handler.toSave(formVersion.eB2);
    }

    return save;
  }
}
