import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Role } from 'src/app/components/catalogs/roles/services/role';

@Component({
  selector: 'role-chip',
  templateUrl: './role-chip.component.html',
  styleUrls: ['./role-chip.component.scss']
})
export class RoleChipComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() control!: FormControl;
  @Input() relatedId!: number;
  @Input() roles!: any[];
  @Input() selectedRoles!: Role[];

  @Output() error = new EventEmitter<string>();
  @Output() edit = new EventEmitter();

  @ViewChild('roleAutocomplete')
  roleAutocomplete!: MatAutocomplete;
  @ViewChild('roleInput')
  roleInput!: ElementRef<HTMLInputElement>;

  rolesFiltered?: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  allRoles: Role[] = [];
  availableRoles: Role[] = [];
  filteredRoles: Role[] = [];
  localSelectedRoles: Role[] = [];

  ngOnInit(): void {
    this.control = this.control ?? new FormControl();
    this.loadRoles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('roles' in changes) {
      this.setAvailableRoles();
      this.clear();
    }
    if ('selectedRoles' in changes) {
      if (!this.selectedRoles) {
        this.localSelectedRoles = this.utils.cloneDeep(this.selectedRoles);
        this.setAvailableRoles();
        this.clear();
      }
    }
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      if (data?.length) {
        this.allRoles = data;
        this.filteredRoles = data;
        this.availableRoles = data;
        this.setAvailableRoles();
        this.clear();
      }
    });
  }

  selected(role: Role) {
    const roleRelated = {
      roleID: role.id,
      role,
      aplGroupID: this.relatedId
    };
    this.roles.push(roleRelated);
    this.localSelectedRoles.push(role);
    this.setAvailableRoles();
    this.clear();
    this.edit.emit();
  }

  remove(role: Role) {
    const index = this.roles.indexOf(role);
    if (index >= 0) {
      this.roles.splice(index, 1);
    }

    const roleIndex = this.localSelectedRoles.indexOf(role);
    if (roleIndex >= 0) {
      this.localSelectedRoles.splice(roleIndex, 1);
    }

    this.setAvailableRoles();
    this.clear();
    this.edit.emit();
  }

  clear() {
    if (this.roleInput != null) {
      this.roleInput.nativeElement.value = '';
    }
    if (this.control != null) {
      this.control.setValue(null);
    }
  }

  setAvailableRoles() {
    this.availableRoles = this.utils.cloneDeep(this.allRoles);
    if (this.localSelectedRoles !== undefined && this.localSelectedRoles != null) {
      this.localSelectedRoles.map(selected => {
        const index = this.availableRoles.map(available => available.id).indexOf(selected.id);

        if (index >= 0) {
          this.availableRoles.splice(index, 1);
        }
      });
    } else {
      this.roles.map(selected => {
        const index = this.availableRoles.map(available => available.id).indexOf(selected.roleID);

        if (index >= 0) {
          this.availableRoles.splice(index, 1);
        }
      });
    }
    this.filteredRoles = this.utils.cloneDeep(this.availableRoles);
  }

  onRoleInputKeyup(e: any) {
    const text = e.target.value;
    this.filteredRoles = this.availableRoles.filter(x => x.name?.trim().toLowerCase().includes(text.trim().toLowerCase()));
  }

}
