import { Component, OnInit, Input, OnChanges, HostListener, AfterViewInit, ElementRef, ViewChild, OnDestroy, Output, EventEmitter, Injector, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription, async } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { Placeholder } from '../../checklists/checklists';
import { ChooseRolesComponent } from '../dialogs/choose-roles/choose-roles.component';
import { ChooseUsersComponent } from '../dialogs/choose-users/choose-users.component';
import { ReminderPeriodEnum } from '../models/enums';
import { RoleScheduleReminder } from '../models/role-schedule-reminder';
import { ScheduleReminder } from '../models/schedule-reminder';
import { ScheduleType } from '../models/schedule-type';
import { UserScheduleReminder } from '../models/user-schedule-reminder';
import { ScheduleValidations } from '../share/schedule-validations';
import { ScheduleRemindersRefresh } from '../store/schedule-reminder/schedule-reminder.action';
import { AddReminderComponent } from './add-reminder/add-reminder.component';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { RoleReminderService } from '../services/role-reminder.service';
import { ScheduleReminderService } from '../services/schedule-reminder.service';
import { UserReminderService } from '../services/user-reminder.service';
import { UserCheck } from 'src/app/common/models/user-check';
import { RoleCheck } from 'src/app/common/models/role-check';
import { TokenInfoService } from 'src/app/services/authentication/token-info.service';

@Component({
  selector: 'app-reminder-tab',
  templateUrl: './reminder-tab.component.html',
  styleUrls: ['./reminder-tab.component.scss']
})

export class ReminderTabComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() scheduleType?: ScheduleType;
  @Input() tabIndex!: number;
  @Input() tabName!: string;
  @Input() filter!: string;
  @Output() loading = new EventEmitter<boolean>();

  @ViewChild('reminderTable')
  reminderTable!: ElementRef;
  displayedColumnsReminder: string[] = ['name', 'dueDateTime', 'period', 'message', 'options'];
  reminderData: ScheduleReminder[] = [];
  reminderSource = new MatTableDataSource(this.reminderData);
  ReminderPeriodEnum = ReminderPeriodEnum;
  sv = ScheduleValidations;
  headerOnTop2 = false;
  tableWidth = '';

  maxHeight = null;
  Period = ['Before Inspected Due Date', 'After Inspected Due Date'];

  scheduleReminders!: ScheduleReminder[];
  scheduleReminders$!: Observable<ScheduleReminder[]>;
  scheduleRemindersSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private serviceReminder: ScheduleReminderService,
    public serviceUserReminder: UserReminderService,
    public serviceRoleReminder: RoleReminderService,
    public tokenInfo: TokenInfoService
  ) {
    super(injector);
    this.store.dispatch(new ScheduleRemindersRefresh());
  }

  override  ngOnDestroy(): void {
    this.scheduleRemindersSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadScheduleReminders();
    if (changes['tabIndex']) {
      if (this.tabIndex === 1) {
        this.headerOnTop2 = false;
        if (this.tableWidth === '0px') {
          this.tableWidth = '100%';
        }
      }
    }
  }

  ngOnInit(): void {
    this.loadScheduleReminders();
  }

  loadScheduleReminders() {
    this.scheduleReminders$ = this.store.select(state => state.ScheduleReminders.data);
    this.scheduleRemindersSubs = this.scheduleReminders$.subscribe(data => {
      this.scheduleReminders = data.filter(x => x.isActive && x.typeId == this.scheduleType?.id);
      this.reminderSource.data = this.scheduleReminders;
      this.reminderSource.filter = this.filter?.trim().toLowerCase();
      this.loading.emit(false);
    });
  }

  addReminder() {
    const schedule = { typeId: this.scheduleType?.id } as ScheduleReminder;
    const dialogRef = this.dialog.open(AddReminderComponent, {
      width: '500px',
      data: schedule
    });
    dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        this.loading.emit(true);
        result = result as ScheduleReminder;
        this.serviceReminder.create(result).toPromise().then(m => {
          this.alert.message('create', [new MessagePlaceholder('{what}', 'Reminder')]);
          this.loading.emit(false);
        });
      }
    });
  }

  removeReminder(id: number) {
    const removeRef = this.dialog.open(YesNoDialogComponent, {
      width: '20em',
      data: {
        message: 'Are you sure you want to erase this reminder?',
        icon: 'warn',
        val: false
      }
    });
    removeRef.afterClosed().toPromise().then(result => {
      if (result) {
        this.loading.emit(true);
        this.serviceReminder.delete(id).toPromise().then(d => {
          this.alert.message('remove', [new MessagePlaceholder('{what}', 'Reminder')]);
          this.loading.emit(false);
        });
      }
    });
  }

  removeItem(array: any, value: any): any {
    const index: number = array.indexOf(value);
    array.splice(index, 1);
    return index;
  }


  async getUserReminderByReminder(reminderId: number) {
    this.loading.emit(true);
    const value = await this.serviceUserReminder.readByReminder(reminderId).toPromise();
    this.loading.emit(false);
    return value?.map(val => val.secondId);
  }
  async getRolReminderByReminder(reminderId: any) {
    this.loading.emit(true);
    const value = await this.serviceRoleReminder.readByReminder(reminderId).toPromise();
    this.loading.emit(false);
    return value?.map(val => val.secondId);
  }

  editReminder(reminderedit: ScheduleReminder) {
    const dialogRef = this.dialog.open(AddReminderComponent, {
      width: '500px',
      data: reminderedit
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const last = this.removeItem(this.reminderData, reminderedit);
        result = result as ScheduleReminder;
        result.id = reminderedit.id;
        this.loading.emit(true);
        this.serviceReminder.update(result).subscribe(m => {
          this.alert.message('update', [new MessagePlaceholder('{what}', 'Reminder')]);
          this.loading.emit(false);
        });
      }
    });
  }

  async openChooseUsers(reminder: any) {
    const dialogRef = this.dialog.open(ChooseUsersComponent, {
      width: '50em',
      data: await this.getUserReminderByReminder(reminder.id)
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let userreminder = result.map((value: UserCheck) => {
          return {
            firstId: reminder.id,
            secondId: value.id
          } as UserScheduleReminder;
        });
        if (userreminder.length <= 0) {
          userreminder = [{ firstId: reminder.id, secondId: 0 } as UserScheduleReminder];
        }
        this.loading.emit(true);
        this.serviceUserReminder.createRange(userreminder).subscribe(response => {
          this.alert.message('updates', [new MessagePlaceholder('{what}', 'Users')]);
          this.loading.emit(false);
        });
        console.log(userreminder);
      }
    });
  }
  async openChooseRoles(reminder: any) {
    const dialogRef = this.dialog.open(ChooseRolesComponent, {
      width: '50em',
      data: await this.getRolReminderByReminder(reminder.id)
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        let rolreminder = result.map((value: RoleCheck) => {
          return {
            firstId: reminder.id,
            secondId: value.id
          } as RoleScheduleReminder;
        });
        if (rolreminder.length <= 0) {
          rolreminder = [{ firstId: reminder.id, secondId: 0 } as RoleScheduleReminder];
        }
        this.loading.emit(true);
        this.serviceRoleReminder.createRange(rolreminder).subscribe(response => {
          this.alert.message('updates', [new MessagePlaceholder('{what}', 'Roles')]);
          this.loading.emit(false);
        });
        console.log(rolreminder);
      }
    });
  }

  validateShowMenu() {
    if (this.sv.validateRole('updateReminder', this.tokenInfo, this.scheduleType)) { return true; }
    if (this.sv.validateRole('deleteReminder', this.tokenInfo, this.scheduleType)) { return true; }
    if (this.sv.validateRole('subscriberReminder', this.tokenInfo, this.scheduleType)) { return true; }
    return false;
  }

}
