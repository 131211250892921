import { ReviewBoardType } from '../../reviews.models';

export class ReviewBoardTypesRefresh {
  static readonly type = '[ReviewBoardTypes] Refresh';
}

export class ReviewBoardTypesCreate {
  static readonly type = '[ReviewBoardTypes] Create';

  constructor(public item: ReviewBoardType) { }
}

export class ReviewBoardTypesUpdate {
  static readonly type = '[ReviewBoardTypes] Update';

  constructor(public id: number, public item: ReviewBoardType) { }
}

export class ReviewBoardTypesDelete {
  static readonly type = '[ReviewBoardTypes] Delete';
  constructor(public id: number) { }
}
