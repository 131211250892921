import { EB2ShutterSave, EB2Shutter, EB2ShutterHandler } from './eb-shutter';
import { EB2AcceleratorLocation, EB2AcceleratorLocationSave, EB2AcceleratorLocationHandler } from './eb-accelerator-location';
import { EB2OtherSystem, EB2OtherSystemSave, EB2OtherSystemHandler } from './eb-other-systems';
import { RelatedDocument } from 'src/app/services/documents/documents';
import { FormRestriction, FormRestrictionHandler } from 'src/app/services/forms/form-restriction';
import { RelatedLinkBase, RelatedLinkBaseSave, RelatedLinkBaseHandler } from 'src/app/components/catalogs/navigation-links/link/related-link-base';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';

export class EB2 {
  public id!: number;
  public formVersionID!: number;
  public equipmentOrFunctionForBypassAndReason: string;
  public beamline: boolean = false;
  public accelerator: boolean = false;
  public bypassDetails: string;
  public conditionsAndLimitations: string;
  public systemAndPrintNumbers: string;
  public mitigationDetails: string;
  public permittedStartDate: Date;
  public estimatedCompletion: Date;
  public permanentBypassReason: string;
  public createdBy?: number;
  public createdOn?: Date;
  public updatedBy?: number;
  public updatedOn?: Date;
  public eB2Shutters: EB2Shutter[];
  public eB2AcceleratorLocations: EB2AcceleratorLocation[];
  public eB2OtherSystems: EB2OtherSystem[];
  public eB2Restrictions: FormRestriction[];
  public eB2RelatedLinks: RelatedLinkBase[];
  public relatedDocuments?: RelatedDocument[];
  public createdByUser: User = new User;
  public updatedByUser: User = new User;

  constructor() {
    const date = new Date();

    this.equipmentOrFunctionForBypassAndReason = "";
    this.bypassDetails = "";
    this.conditionsAndLimitations = "";
    this.systemAndPrintNumbers = "";
    this.mitigationDetails = "";
    this.permittedStartDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.estimatedCompletion = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.permanentBypassReason = "";
    this.eB2Shutters = [];
    this.eB2AcceleratorLocations = [];
    this.eB2OtherSystems = [];
    this.eB2Restrictions = [];
    this.eB2RelatedLinks = [];
    this.relatedDocuments = [];
  }
}

export class EB2Save {
  public id!: number;
  public formVersionID!: number;
  public equipmentOrFunctionForBypassAndReason: string;
  public beamline: boolean = false;
  public accelerator: boolean = false;
  public bypassDetails: string;
  public conditionsAndLimitations: string;
  public systemAndPrintNumbers: string;
  public mitigationDetails: string;
  public permittedStartDate: Date;
  public estimatedCompletion: Date;
  public eB2Shutters: EB2ShutterSave[];
  public eB2AcceleratorLocations: EB2AcceleratorLocationSave[];
  public eB2OtherSystems: EB2OtherSystemSave[];
  public eB2Restrictions: FormRestriction[];
  public eB2RelatedLinks: RelatedLinkBaseSave[];
  public relatedDocuments: RelatedDocument[];

  constructor() {
    this.equipmentOrFunctionForBypassAndReason = "";
    this.bypassDetails = "";
    this.conditionsAndLimitations = "";
    this.systemAndPrintNumbers = "";
    this.mitigationDetails = "";
    this.permittedStartDate = new Date();
    this.estimatedCompletion = new Date();
    this.eB2Shutters = [];
    this.eB2AcceleratorLocations = [];
    this.eB2OtherSystems = [];
    this.eB2Restrictions = [];
    this.eB2RelatedLinks = [];
    this.relatedDocuments = [];
  }
}

export class EB2Handler {
  static toSave(eb2: EB2): EB2Save {
    var save = new EB2Save();

    save.id = eb2.id;
    save.formVersionID = eb2.formVersionID;
    save.equipmentOrFunctionForBypassAndReason = eb2.equipmentOrFunctionForBypassAndReason;
    save.beamline = eb2.beamline;
    save.accelerator = eb2.accelerator;
    save.bypassDetails = eb2.bypassDetails;
    save.conditionsAndLimitations = eb2.conditionsAndLimitations;
    save.systemAndPrintNumbers = eb2.systemAndPrintNumbers;
    save.mitigationDetails = eb2.mitigationDetails;
    save.permittedStartDate = eb2.permittedStartDate;
    save.estimatedCompletion = eb2.estimatedCompletion;
    save.eB2Shutters = [];
    eb2.eB2Shutters.map(x => save.eB2Shutters.push(EB2ShutterHandler.toSave(x)));
    save.eB2RelatedLinks = [];
    eb2.eB2AcceleratorLocations.map(x => save.eB2AcceleratorLocations.push(EB2AcceleratorLocationHandler.toSave(x)));
    save.eB2OtherSystems = [];
    eb2.eB2OtherSystems.map(x => save.eB2OtherSystems.push(EB2OtherSystemHandler.toSave(x)));
    save.eB2Restrictions = [];
    eb2.eB2Restrictions.map(x => save.eB2Restrictions.push(FormRestrictionHandler.toSave(x)));
    save.eB2RelatedLinks = [];
    eb2.eB2RelatedLinks.map(x => save.eB2RelatedLinks.push(RelatedLinkBaseHandler.toSave(x)));
    save.relatedDocuments = [];
    eb2.relatedDocuments?.map(x => save.relatedDocuments.push(x));

    return save;
  }
}
