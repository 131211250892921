<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-2">
        <h6>{{ number ?? "#" }}</h6>
      </div>
      <div class="col-11 pt-2 d-flex flex-row justify-content-between">
        <mat-label
          [innerHTML]="question ?? '' | placeholderHighlight : editor"
        ></mat-label>
      </div>
      <div class="col-10 offset-2">
        <div class="checkboxes float-right">
          <div>
            <mat-icon
              *ngIf="warning && checkboxVisible"
              color="warn"
              class="warn"
              [matTooltip]="moreInfo ?? ''"
              matTooltipPosition="left"
              >warning</mat-icon
            >
            <div class="checkbox-container justify-content-end d-flex flex-row">
              <div *ngFor="let o of roleOptions">
                <mat-checkbox-with-roles
                  labelPosition="before"
                  [checked]="o.checked"
                  [disabled]="disabled || checkboxDisabled(o)"
                  (change)="checkboxChanged($event, o.id)"
                  [color]="color"
                  [roleCodes]="o.roleCodes"
                  [user]="o.user"
                  [date]="o.date"
                >
                </mat-checkbox-with-roles>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
