import { ReviewAttendanceRoster, ReviewAttendanceSaveListSave } from "./../../../../../../reviews.models";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ReviewAttendanceCategory, ReviewAttendanceSave, ReviewAttendanceSaveList, ReviewAttendanceSaveSave, } from "../../../../../../reviews.models";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ReviewsService } from "src/app/components/reviews/reviews.service";
import { Observable, Subscription } from "rxjs";
import { utils } from "src/app/modules/libs/utils";
import { Store } from "@ngxs/store";
import { Role } from "src/app/components/catalogs/roles/services/role";
import { User } from "src/app/components/catalogs/user-catalog/services/user";
import { TouchedErrorStateMatcher } from "src/app/components/pending-approvals-v2/pending-approvals-v2.module";

@Component({
  selector: "app-review-attendance-saved-edit",
  templateUrl: "./saved-edit.component.html",
  styleUrls: ["./saved-edit.component.scss"],
})
export class ReviewAttendanceSavedEditComponent implements OnInit, OnDestroy {
  reviewAttendanceSave!: ReviewAttendanceSave;
  reviewAttendanceSaved!: ReviewAttendanceSave[];
  reviewAttendanceSavedFiltered!: ReviewAttendanceSave[];
  reviewAttendanceSaved$!: Observable<ReviewAttendanceSave[]>;
  reviewAttendanceSavedSubs!: Subscription;

  reviewAttendanceCategory?: ReviewAttendanceCategory | null;
  reviewAttendanceCategories!: ReviewAttendanceCategory[];
  reviewAttendanceCategoriesFiltered!: ReviewAttendanceCategory[];
  reviewAttendanceCategoriesToAdd!: ReviewAttendanceCategory[];
  reviewAttendanceCategories$!: Observable<ReviewAttendanceCategory[]>;
  reviewAttendanceCategoriesSubs!: Subscription;

  form!: FormGroup;
  categoryCtrl = new FormControl();
  memberCtrl = new FormControl();
  rosterCtrl = new FormControl();
  roleCtrl = new FormControl();

  addingMember!: number;
  addingCategory!: boolean;

  user?: User | null;
  users!: User[];
  usersFiltered!: User[];
  users$!: Observable<User[]>;
  usersSubs!: Subscription;

  role?: Role | null;
  roles!: Role[];
  rolesFiltered!: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  memberDeleted!: boolean;

  constructor(
    private store: Store,
    public dialog: MatDialogRef<ReviewAttendanceSavedEditComponent>,
    private service: ReviewsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.reviewAttendanceSave != null) {
      this.reviewAttendanceSave = utils.cloneDeep(data.reviewAttendanceSave);
    }
  }

  ngOnDestroy(): void {
    this.usersSubs?.unsubscribe();
    this.reviewAttendanceCategoriesSubs?.unsubscribe();
    this.reviewAttendanceSavedSubs?.unsubscribe();
    this.rolesSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.loadRoles();
    this.loadUsers();
    this.buildForm();
    this.loadReviewAttendanceSaved();
    this.loadReviewAttendanceCategories();
    this.rosterCtrl.setValue(this.reviewAttendanceSave);
  }

  buildForm() {
    this.form = new FormGroup({
      categoryCtrl: this.categoryCtrl,
      rosterCtrl: this.rosterCtrl,
      memberCtrl: this.memberCtrl,
      roleCtrl: this.roleCtrl,
    });
  }

  loadReviewAttendanceSaved() {
    this.reviewAttendanceSaved$ = this.store.select(
      (state) => state.ReviewAttendanceSaved.data
    );
    this.reviewAttendanceSavedSubs = this.reviewAttendanceSaved$.subscribe(
      (data) => {
        if (data?.length) {
          this.reviewAttendanceSaved = data.sort((a, b) =>
            utils.sort(a.name, b.name, true)
          );
          this.reviewAttendanceSavedFiltered = data;
        }
      }
    );
  }

  changedRoster(e: any) {
    const value: string = e.target.value;
    this.reviewAttendanceSavedFiltered = this.reviewAttendanceSaved.filter(
      (x) => x.name?.toLowerCase().includes(value.toLowerCase())
    );
  }

  selectedReviewAttendanceSave(e: any) {
    this.reviewAttendanceSave = e.option.value;
    this.reviewAttendanceSave.reviewAttendanceSavedList.map(a => {
      if (!a.role) {
        a.role = this.roles.find(r => r.id == a.roleID);
        if (!a.role) a.role = { id: a.roleID, name: 'Guest' } as Role;
      };
    });
    this.loadReviewAttendanceCategories();
  }

  loadUsers() {
    this.users$ = this.store.select((state) => state.Users.data);
    this.usersSubs = this.users$.subscribe((data) => {
      this.users = data.filter((u) => u.status == 1);
    });
  }

  loadRoles() {
    this.roles$ = this.store.select((state) => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe((data) => {
      if (data.length) {
        this.roles = data;
        this.rolesFiltered = data;
      }
    });
  }

  save() {
    const reviewAttendanceSave: ReviewAttendanceSaveSave =
      this.reviewAttendanceSave;
    reviewAttendanceSave.name =
      typeof this.rosterCtrl.value == "string"
        ? this.rosterCtrl.value
        : this.reviewAttendanceSave.name;
    const reviewAttendanceSaveListNew: ReviewAttendanceSaveListSave[] = [];
    this.reviewAttendanceSave.reviewAttendanceSavedList.map((x) => {
      const reviewAttendanceSaveListItem: ReviewAttendanceSaveListSave = {
        id: x.id,
        name: x.user?.name,
        userID: x.userID,
        categoryID: x.categoryID,
        roleID: x.roleID,
        reviewAttendanceSaveID: x.reviewAttendanceSaveID,
      };
      reviewAttendanceSaveListNew.push(reviewAttendanceSaveListItem);
    });
    reviewAttendanceSave.reviewAttendanceSavedList =
      reviewAttendanceSaveListNew;
    this.service
      .updateReviewAttendanceSaved(reviewAttendanceSave)
      .toPromise()
      .then((data) => {
        this.dialog.close();
      });
    this.memberDeleted = false;
  }

  loadReviewAttendanceCategories() {
    this.reviewAttendanceCategories$ = this.store.select(
      (state) => state.ReviewAttendanceCategories.data
    );
    this.reviewAttendanceCategoriesSubs = this.reviewAttendanceCategories$.subscribe((data) => {
      if (data.length) {
        this.reviewAttendanceCategories = data;
        this.loadCategories();
      }
    });
  }

  loadCategories() {
    this.reviewAttendanceCategoriesFiltered = [];
    this.reviewAttendanceSave?.reviewAttendanceSavedList?.map(x => {
      x.user = this.users.find((u) => u.id == x.userID);
      x.category = this.reviewAttendanceCategories.find(
        (c) => c.id == x.categoryID
      );
      if (!this.reviewAttendanceCategoriesFiltered.map((c) => c.id).includes(x.categoryID)) {
        if (x.category)
          this.reviewAttendanceCategoriesFiltered.push(x.category);
      }
      this.reviewAttendanceCategoriesToAdd = this.reviewAttendanceCategories.filter((x) => !this.reviewAttendanceCategoriesFiltered.map((c) => c.id).includes(x.id));
    });
  }

  getRoster(c: ReviewAttendanceCategory) {
    return this.reviewAttendanceSave?.reviewAttendanceSavedList?.filter(
      (x) => x.categoryID == c.id
    );
  }

  cancel() {
    this.dialog.close();
  }

  addCategory() {
    this.addingCategory = true;
    const newCategory = {
      id: 0,
      reviewAttendanceRosters: [],
      preName: '',
      editing: false
    } as ReviewAttendanceCategory;
    this.reviewAttendanceCategoriesFiltered.push(newCategory);
    const element = $(".cat-container");
    element.animate(
      {
        scrollTop: element.prop("scrollHeight"),
      },
      500
    );
  }

  cancelAddCategory() {
    this.reviewAttendanceCategoriesFiltered =
      this.reviewAttendanceCategoriesFiltered.filter((x) => x.id != 0);
    this.addingCategory = false;
    this.reviewAttendanceCategory = null;
    this.categoryCtrl.setValue(null);
  }

  selectedCategory(e: any) {
    const value = e.option.value as ReviewAttendanceCategory;
    this.reviewAttendanceCategory = value;
    const index = this.reviewAttendanceCategoriesFiltered.findIndex(
      (x) => x.id == 0
    );
    this.reviewAttendanceCategoriesFiltered.splice(index, 1);
    this.reviewAttendanceCategoriesFiltered.push(this.reviewAttendanceCategory);
    this.setAdding(this.reviewAttendanceCategory);
  }

  deleteCategory(category: ReviewAttendanceCategory) {
    this.reviewAttendanceCategory = null;
  }

  setAdding(c: ReviewAttendanceCategory) {
    this.addingMember = c.id;
    this.usersFiltered = this.users.filter((u) => !this.reviewAttendanceSave.reviewAttendanceSavedList.map((a) => a.userID).includes(u.id ?? 0)
    );
    this.user = null;
    this.memberCtrl.setValue(null);
    this.role = null;
    this.roleCtrl.setValue(null);
    const element = $(".cat-container");
    element.animate(
      {
        scrollTop: element.prop("scrollHeight"),
      },
      500
    );
  }

  changedMember(e: any, c: ReviewAttendanceCategory) {
    const value: string = e.target.value;
    this.setAdding(c);
    this.usersFiltered = this.usersFiltered.filter((u) => u.name?.toLowerCase().includes(value.toLowerCase()));
  }

  changedRole(e: any) {
    const value: string = e.target.value;
    this.rolesFiltered = this.roles.filter((r) => r.name?.toLowerCase().includes(value.toLowerCase()) ||
      r.code?.toLowerCase().includes(value.toLowerCase()));
  }

  selectedRole(e: any, c: ReviewAttendanceCategory) {
    this.role = e;
    this.usersFiltered = this.users.filter((u) => !this.reviewAttendanceSave.reviewAttendanceSavedList.map((a) => a.userID).includes(u.id ?? 0) &&
      u.userRole?.map((r) => r.roleID).includes(this.role?.id)
    );
    this.user = null;
    this.memberCtrl.setValue(null);
  }

  selectedMember(e: any, c: ReviewAttendanceCategory) {
    this.user = e.option.value;
    const member: ReviewAttendanceSaveList = {
      id: 0,
      name: this.user?.name,
      userID: this.user?.id ?? 0,
      user: this.user,
      roleID: this.role?.id ?? 0,
      role: this.role,
      reviewAttendanceSaveID: this.reviewAttendanceSave.id,
      reviewAttendanceSave: this.reviewAttendanceSave,
      categoryID: c.id,
      category: c,
    };
    this.reviewAttendanceSave.reviewAttendanceSavedList.push(member);
    this.addingMember = 0;
    this.form.markAsDirty();
    if (this.addingCategory) {
      const roster = {
        id: 0,
        status: 1,
        userID: this.user?.id,
        user: this.user,
        roleID: this.role?.id,
        role: this.role,
        categoryID: this.reviewAttendanceCategory?.id,
      } as ReviewAttendanceRoster;
      const r = this.reviewAttendanceCategoriesFiltered?.find((x) => x.id == this.reviewAttendanceCategory?.id)?.reviewAttendanceRosters;
      if (r)
        r.push(roster);
    }
  }

  cancelAddMember() {
    this.addingMember = 0;
    this.addingCategory = false;
    this.reviewAttendanceCategory = null;
    this.categoryCtrl.setValue(null);
    if (this.reviewAttendanceCategory) {
      const index = this.reviewAttendanceCategoriesFiltered.findIndex(
        (x) => x.id == this.reviewAttendanceCategory?.id
      );
      if (index >= 0) {
        this.reviewAttendanceCategoriesFiltered.splice(index, 1);
      }
    }
  }

  displayObject(u: any) {
    return u ? u.name : null;
  }

  deleteMember(r: ReviewAttendanceSaveList) {
    const i1 = this.reviewAttendanceSave.reviewAttendanceSavedList.findIndex(
      (a) => a.id == r.id
    );
    this.reviewAttendanceSave.reviewAttendanceSavedList.splice(i1, 1);
    const rosters = this.reviewAttendanceCategoriesFiltered?.find((x) => x.id == r.categoryID)?.reviewAttendanceRosters;
    if (rosters) {
      const i2 = rosters.findIndex((x) => x.userID == r.userID && x.roleID == r.roleID && x.categoryID == r.categoryID);
      rosters.splice(i2, 1);
      if (!rosters.length) {
        const i3 = this.reviewAttendanceCategoriesFiltered.findIndex(
          (x) => x.id == r.categoryID
        );
        this.reviewAttendanceCategoriesFiltered.splice(i3, 1);
      }
      this.memberDeleted = true;
    }
  }

  changed() {
    return (
      (this.role && this.user) ||
      (this.reviewAttendanceSave &&
        typeof this.rosterCtrl.value == "string" &&
        this.rosterCtrl.value != this.reviewAttendanceSave?.name) ||
      this.memberDeleted
    );
  }
}
