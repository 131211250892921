import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OlogCategories, OlogCategory } from './olog-category';

@Injectable({
  providedIn: 'root'
})
export class OlogCategoriesService {

  public api: string = environment.urls.baseApiUrl + '/OlogCategories';

  constructor(
    private http: HttpClient
  ) { }

  GetOlogCategories(): Observable<OlogCategories[]> {
    return this.http.get<OlogCategories[]>(this.api);
  }

  GetOlogCategoryByDocumentTypeID(id: number): Observable<OlogCategories> {
    return this.http.get<OlogCategories>(this.api + '/' + id);
  }

}
