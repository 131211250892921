<div
  class="pt-2 mt-3 nowork"
  *ngIf="signatures?.length"
  [ngClass]="{
    amended: status?.isViewingAmendment && requesterAmended
  }"
>
  <div class="row no-gutters">
    <div class="col-6">
      <h5 class="section-title">{{ noWorkPerformedTitle }}</h5>
    </div>
    <div class="col red small">
      <span class="float-right">{{ noWorkPerformedLegend }}</span>
    </div>
  </div>

  <div class="row justify-content-center">
    <div
      class="col px-1 pb-2"
      id="{{ s.disapprove }}"
      *ngFor="let s of signatures"
    >
      <master-control
        [s]="s"
        [type]="s.type"
        [visible]="true"
        [disabled]="s.disabled"
        [header]="s.name"
        [question]="s.question"
        [roleIds]="s.roles"
        [roleCodes]="s.roleCodes"
        [userNames]="s.userNames"
        [user]="s.approvedBy"
        [title]="s.name"
        [label]="s.name"
        [text]="s.question"
        [radioQuestion]="s.question2"
        [radioOptions]="s.options"
        [radioSelection]="s.value2"
        [checkbox_checked]="s.value"
        [approved]="s.approved"
        [approvedBy]="s.approvedBy"
        [approvedOn]="s.approvedOn"
        [showApprove]="s.approve"
        [showDisapprove]="s.disapprove"
        [showUnapprove]="s.unapprove"
        [textBefore]="s.question"
        [textAfter]="s.question2"
        [textAfter2]="s.question3"
        [textValue]="s.textValue"
        (approve)="approve(s)"
        (disapprove)="disapprove(s, $event)"
        (unapprove)="unapprove(s)"
        (checked)="checked(s, $event)"
        [enabled]="s.enabled"
      >
      </master-control>
    </div>
  </div>
</div>
