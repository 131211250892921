<div class="d-flex flex-column">
  <mat-progress-bar
    class="progress-bar"
    mode="indeterminate"
    *ngIf="loading"
  ></mat-progress-bar>
  <mat-file
    *ngFor="let attachment of attachments"
    [fileIcon]="attachment.fileIcon"
    [fileName]="attachment.fileName"
    [fileSize]="attachment.SizeKb ?? ''"
    [id]="attachment.id"
    [save_files_database]="save_files_database"
    [rootDirectory]="attachment.rootDirectory ?? ''"
    (deletedAttachmentFile)="OnDeletedAttachmentFile($event)"
    [disabled]="disabled"
  ></mat-file>
</div>
<!-- <button
  class="ml-1"
  mat-stroked-button
  (click)="show_upload()"
  *ngIf="!disabled && !showupload"
>
  Add Files <mat-icon color="accent">add_box</mat-icon>
</button> -->
<mat-file-v2-upload
  id="upload"
  labelText=""
  *ngIf="!disabled && !loading"
  [uploadId]="uploadId ?? ''"
  [commentId]="comment?.id"
  [radiationAreaItemId]="radArea?.id"
  [upload_when_selected]="upload_when_selected"
  [save_files_database]="save_files_database"
  [typeDocument]="typeDocument"
  (selectedFilesChanged)="selectedFilesChanged.emit($event)"
></mat-file-v2-upload>
