import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'src/app/common/base/base.service';
import { UserScheduleStatus } from '../models/user-schedule-status';

@Injectable({
  providedIn: 'root'
})
export class UserScheduleStatusService extends BaseService<UserScheduleStatus, number> {

  constructor(protected override injector: Injector) {
    super(injector, '/user_schedule_status');
  }

  readByStatus(firstId: number) {
    const url = this.BASE_URL + this.endpoint + '/by_status/' + firstId;
    return this.http.get<UserScheduleStatus[]>(url);
  }
}
