<div class="card">
  <div class="card-header">
    <span class="card-title">{{
      addRelatedDocumentOrLink == null
        ? "Add Related Document or Link"
        : addRelatedDocumentOrLink
    }}</span>
  </div>
  <div class="card-body">
    <mat-form-field class="no-subscript">
      <mat-select [(ngModel)]="option" (selectionChange)="optionChange()">
        <mat-option [value]="1">Web Address</mat-option>
        <mat-option [value]="2">PDF</mat-option>
      </mat-select>
    </mat-form-field>
    <div
      class="d-flex justify-content-center small pb-1"
      *ngIf="clipboardFromAPL && option == 1"
    >
      <button mat-stroked-button color="primary" (click)="pasteFromAPL()">
        <mat-icon class="mr-1">content_paste</mat-icon>Paste from APL copied
        Category
      </button>
    </div>
    <mat-form-field>
      <mat-label>{{
        documentNameOrHyperlink == null
          ? "Document Name or Hyperlink"
          : documentNameOrHyperlink
      }}</mat-label>
      <textarea
        required
        matInput
        [(ngModel)]="data.relatedLinkDocument.text"
        [formControl]="textCtrl"
        rows="5"
        name="hyperlink"
      ></textarea>
      <mat-error>{{
        youMustEnterAValidaValue == null
          ? "You must enter a valid value"
          : youMustEnterAValidaValue
      }}</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="option == 1">
      <mat-label>{{
        webAddress == null ? "Web Address" : webAddress
      }}</mat-label>
      <textarea
        matInput
        [(ngModel)]="data.relatedLinkDocument.url"
        [formControl]="URL"
        rows="5"
        name="webAddress"
      ></textarea>
      <mat-error>{{
        youMustEnterAValidaValue == null
          ? "You must enter a valid value"
          : youMustEnterAValidaValue
      }}</mat-error>
    </mat-form-field>
    <div *ngIf="option == 2">
      <file-upload-v2
        [(fileID)]="data.relatedLinkDocument.fileID"
        [(file)]="data.relatedLinkDocument.file"
        [(processingFile)]="processingFile"
        [path]="data.path"
        [disabled]="data.disabledFileUpload"
        [fileName]="data.relatedLinkDocument.text"
        [deleteFile]="!(data.editing === true)"
      ></file-upload-v2>
    </div>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        *ngIf="option == 1"
        mat-flat-button
        color="primary"
        [disabled]="textCtrl.invalid || URL.invalid"
        (click)="accept()"
      >
        Accept
      </button>

      <button
        *ngIf="option == 2"
        mat-flat-button
        color="primary"
        [disabled]="
          textCtrl.invalid || !data.relatedLinkDocument.file || processingFile
        "
        (click)="accept()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
