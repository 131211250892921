import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { Restriction, SaveRestriction } from './restriction';
import { Document } from '../../../services/documents/documents';

@Injectable({
  providedIn: 'root'
})
export class RestrictionService {
  public endPoint = '/Restriction';

  constructor(private http: HttpClient) { }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  getAllRestrictions(): Observable<Restriction[]> {
    const result = this.http.get(environment.urls.baseApiUrl + '/Restriction')
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getByFormType(formType: FormType): Observable<Restriction[]> {
    return this.http.get<Restriction[]>(environment.urls.baseApiUrl + '/Restriction/FormType/' + formType.toString());
  }

  postRestriction(restriction: Restriction) {
    if (!restriction.id) {
      // Save New
      const saveRestriction: SaveRestriction = {
        resourceID: restriction.resourceID,
        locationID: restriction.locationID,
        restrictionLanguage: restriction.restrictionLanguage,
        alternateRestrictionLanguage: restriction.alternateRestrictionLanguage
      };
      const result = this.http.post(environment.urls.baseApiUrl + '/Restriction', saveRestriction)
        .pipe(
          catchError(this.handleError)
        );
      return result;
    } else {
      // Save Modified
      const result = this.http.put(environment.urls.baseApiUrl + '/Restriction/' + restriction.id, restriction)
        .pipe(
          catchError(this.handleError)
        );
      return result;
    }

  }

  deleteRestriction(restriction: Restriction) {
    const result = this.http.delete(environment.urls.baseApiUrl + '/Restriction/' + restriction.id)
      .pipe(
        catchError(this.handleError)
      );
    return result;
  }

  getByShieldingId(id: number): Observable<Restriction[]> {
    let url: string;
    url = environment.urls.baseApiUrl + this.endPoint + '/Shielding/' + id;
    return this.http.get<Restriction[]>(url);
  }

  Read(): Observable<Restriction[]> {
    return this.http.get<Restriction[]>(environment.urls.baseApiUrl + this.endPoint);
  }

  ReadAvailable(type: FormType, id: number): Observable<Restriction[]> {
    return this.http.get<Restriction[]>(environment.urls.baseApiUrl + '/Restriction/Available/' + type.valueOf().toString() + '/' + id.toString());
  }

  ReadAvailableByIds(type: FormType, ids: number[]) {
    let url = environment.urls.baseApiUrl + this.endPoint + '/Available/' + type.valueOf().toString();
    let queryString = '?';
    ids?.map(x => {
      if (queryString !== '?') {
        queryString += '&';
      }
      queryString += 'id=' + x.toString();
    });

    url += queryString;
    return this.http.get<Restriction[]>(url);
  }

  CheckByLocationID(locationID: number): Observable<Document[]> {
    return this.http.get<Document[]>(environment.urls.baseApiUrl + '/Restriction/CheckByLocation/' + locationID);
  }
}
