import FileV2 from "src/app/services/file/file-v2.model";

export class RelatedLinkDocumentBase {
  public id?: number;
  public formID?: number;
  public text?: string;
  public url?: string | null;
  public fileID?: number | null;
  public file?: FileV2 | null;
}

export class RelatedLinkDocumentBaseSave {
  public id?: number;
  public formID?: number;
  public text?: string;
  public url?: string | null;
  public fileID?: number | null;
}

export class RelatedLinkDocumentBaseHandler {
  static toSave(relatedLinkDocumentBase: RelatedLinkDocumentBase): RelatedLinkDocumentBaseSave {
    var save = new RelatedLinkDocumentBaseSave();

    save.id = relatedLinkDocumentBase.id;
    save.formID = relatedLinkDocumentBase.formID;
    save.text = relatedLinkDocumentBase.text;
    save.url = relatedLinkDocumentBase.url;
    save.fileID = relatedLinkDocumentBase.fileID;

    return save;
  }
}
