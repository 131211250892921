import { ScheduleType } from "../../models/schedule-type";


export class ScheduleTypesRefresh {
  static readonly type = '[SchedulesTypes] Refresh';
}

export class ScheduleTypesCreate {
  static readonly type = '[SchedulesTypes] Create';
  constructor(public item: ScheduleType) { }
}

export class ScheduleTypesUpdate {
  static readonly type = '[SchedulesTypes] Update';
  constructor(public id: number, public item: ScheduleType) { }
}

export class ScheduleTypesDelete {
  static readonly type = '[SchedulesTypes] Delete';
  constructor(public id: number) { }
}
