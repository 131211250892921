import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';

@Component({
  selector: 'button-signature-duplicate',
  templateUrl: './button-signature-duplicate.component.html',
  styleUrls: ['./button-signature-duplicate.component.scss']
})
export class ButtonSignatureDuplicateComponent extends ClBaseComponent {

  constructor(
    protected override injector: Injector,
    private wfService: WorkFlowService,
  ) {
    super(injector);
  }

  @Input() code?: string;
  @Output() duplicate = new EventEmitter<TaskDuplicateResource>();
  @Output() loading = new EventEmitter<boolean>();

  approveEvent(e: any) {
    this.approve.emit(e);
  }

  disapproveEvent(e: any) {
    this.disapprove.emit(e);
  }

  unapproveEvent(e: any) {
    this.unapprove.emit(e);
  }

  async duplicateEvent() {
    if (this.taskID) {
      this.loading.emit(true);
      await this.wfService.copyTask(this.taskID, 'DUP').toPromise().then(task => {
        const res = { taskID: task?.id, task, action: DuplicateAction.Duplicate } as TaskDuplicateResource;
        this.duplicate.emit(res);
        this.loading.emit(false);
      }).catch(error => {
        console.log(error);
      });
    }
  }

  async removeDuplicateEvent() {
    if (this.taskID) {
      this.loading.emit(true);
      await this.wfService.removeTask(this.taskID).toPromise().then(() => {
        const res = { taskID: this.taskID, action: DuplicateAction.Remove } as TaskDuplicateResource;
        this.duplicate.emit(res);
        this.loading.emit(false);
      }).catch(error => {
        console.log(error);
      });
    }
  }

}

export class TaskDuplicateResource {
  taskID!: number;
  task?: WfTaskLocalResource;
  action!: DuplicateAction;
}

export enum DuplicateAction {
  Duplicate = 1,
  Remove = 2
}
