import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-subtitle',
  templateUrl: './cl-subtitle.component.html',
  styleUrls: ['./cl-subtitle.component.scss']
})
export class ClSubtitleComponent {

  @Input() text?: string | null;
  @Input() label?: string | null;
  @Input() editor?: boolean | null;

  public visible: boolean = false;
}
