import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-review-actions-request-extension',
  templateUrl: './review-actions-request-extension.component.html',
  styleUrls: ['./review-actions-request-extension.component.scss']
})
export class ReviewActionsRequestExtensionComponent implements OnInit {

  dateTimeCtrl = new FormControl();
  defaultTime!: any[];
  minDate!: moment.Moment;

  constructor(
    private dialogRef: MatDialogRef<ReviewActionsRequestExtensionComponent>
  ) { }

  ngOnInit(): void {
    const now = moment();
    this.defaultTime = [now.add(1, 'hour').format('HH'), '00', '00'];
    this.minDate = now;
  }

  check_disabled() {
    return this.dateTimeCtrl.value?.format() == null || this.dateTimeCtrl.errors;
  }

  request() {
    this.dialogRef.close(this.dateTimeCtrl.value.format());
  }

}
