import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import * as MissingTrainingActions from './procedure-missing-training.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';
import { Subscription } from 'rxjs';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { AppStateService } from 'src/app/store/app-state-service';

@Injectable({
  providedIn: 'root'
})
export class MissingTrainingStoreAndListenersService {

  currentUser?: User | null;
  subscription!: Subscription;

  constructor(
    private store: Store,
    private signalRService: SignalRService,
    private ass: AppStateService
  ) {
    this.subscription = this.ass.AppState.subscribe(appState => {
      this.currentUser = appState.currentUser;
      this.store.dispatch(new MissingTrainingActions.MissingTrainingRefresh(this.currentUser?.id ?? 0));
    });
  }

  initStore() {
    this.store.dispatch(new MissingTrainingActions.MissingTrainingRefresh(this.currentUser?.id ?? 0));
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.MISSINGTRAINING, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Get:
          this.store.dispatch(new MissingTrainingActions.MissingTrainingRefresh(this.currentUser?.id ?? 0));
      }
    });

  }
}
