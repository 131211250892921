import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { PendingApprovalsCatalogsComponent, CatalogItem } from './pending-approvals-catalogs/pending-approvals-catalogs.component';
import { PendingApprovalsChangesComponent } from './pending-approvals-changes/pending-approvals-changes.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PendingApprovalsV2Service } from './pending-approvals-v2.service';

@Component({
  selector: 'app-pending-approvals-v2',
  templateUrl: './pending-approvals-v2.component.html',
  styleUrls: ['./pending-approvals-v2.component.scss']
})
export class PendingApprovalsV2Component extends BaseComponent implements OnInit {

  @ViewChild(PendingApprovalsCatalogsComponent) catalogs!: PendingApprovalsCatalogsComponent;
  @ViewChild(PendingApprovalsChangesComponent) myChanges!: PendingApprovalsChangesComponent;

  disabled!: boolean;
  loading = false;
  loadingMessage!: string;

  filterCtrl = new FormControl();

  errorMessages!: string[];
  headerOnTop!: boolean;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter!: any[];
  currentFilter: any;
  filter: any;

  ///////////////////////

  // dataSourceRoles = new MatTableDataSource<Role>();
  dataSourceCatalogs = new MatTableDataSource<CatalogItem>();
  dataSourceMyChanges = new MatTableDataSource<CatalogItem>();

  catalogsData!: CatalogItem[];
  myChangesData!: CatalogItem[];

  tableColumns = ['name'];

  activeLink!: number;
  tabIndex!: number;

  roles!: Role[];

  constructor(
    protected override injector: Injector,
    private activatedRoute: ActivatedRoute,
    public pendingApprovals: PendingApprovalsV2Service
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.moduleTitle = 'Pending Actions';
    this.activatedRoute.queryParams.subscribe(params => {
      this.setTab(params?.['tab']);
    });
  }

  setTab(tabParam?: string) {
    this.tabIndex = tabParam && tabParam != '0' ? Number(tabParam) : (this.dataSourceCatalogs.data.length ? 1 : (this.dataSourceMyChanges.data.length ? 2 : 0));
    this.activeLink = this.tabIndex;
  }

  onTabChanged(e: any) {
    this.tabIndex = e;
    this.setTab(e);
    this.scrollToTop('sidenav-content');
  }

  catalogsUpdate(e: any) {
    const activeTab = this.activeLink.toString();
    this.catalogsData = e;
    this.dataSourceCatalogs = new MatTableDataSource(this.catalogsData.filter(x => x.qty));
    this.setTab(activeTab);
  }

  myChangesUpdate(e: any) {
    const activeTab = this.activeLink.toString();
    this.myChangesData = e;
    this.dataSourceMyChanges = new MatTableDataSource(this.myChangesData.filter(x => x.qty));
    this.setTab(activeTab);
  }

  navigateToRole(id: number) {
    document.getElementById('role' + id.toString())?.scrollIntoView({ behavior: 'smooth' });
  }

  navigateToCatalog(id: string) {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  }
}
