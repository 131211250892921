<fieldset
  class="field-group mb-2"
  *ngFor="let reviewPermission of reviewPermissions"
>
  <legend [matTooltip]="reviewPermission.description ?? ''">
    {{ reviewPermission.name }}
  </legend>
  <div class="row">
    <div class="col-6">
      <reviews-settings-permissions-roles
        [reviewPermission]="reviewPermission"
      ></reviews-settings-permissions-roles>
    </div>
    <div class="col-6">
      <reviews-settings-permissions-status
        *ngIf="reviewPermission.hasStatusPermissions"
        [reviewPermission]="reviewPermission"
        [reviewBoardType]="reviewBoardType"
      ></reviews-settings-permissions-status>
    </div>
  </div>
</fieldset>
