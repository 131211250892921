import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RadInterlockRefresh, RadInterlockCreate, RadInterlockUpdate, RadInterlockDelete } from './rad-interlock.action';
import { RadInterlock } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-interlock';
import { RadInterlockService } from '../../services/rad-interlock.service';

export class RadInterlockStateModel {
  data: RadInterlock[] = [];
}

@State<RadInterlockStateModel>({
  name: 'RadInterlock',
  defaults: {
    data: []
  }
})
@Injectable()
export class RadInterlockState {
  constructor(
    private radInterlockService: RadInterlockService
  ) { }

  @Selector()
  static read(state: RadInterlockStateModel) {
    return state.data;
  }

  @Action(RadInterlockRefresh)
  refresh({ patchState }: StateContext<RadInterlockStateModel>) {
    this.radInterlockService.GetRadInterlocks().toPromise().then(data => {
      patchState({ data });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(RadInterlockCreate)
  create({ getState, patchState }: StateContext<RadInterlockStateModel>, { item }: RadInterlockCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(RadInterlockUpdate)
  update({ getState, patchState }: StateContext<RadInterlockStateModel>, { id, item }: RadInterlockUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    const currentState = state.data;

    if (index >= 0) {
      currentState.splice(index, 1, item);
      patchState({ data: currentState });
    }
  }

  @Action(RadInterlockDelete)
  delete({ getState, patchState }: StateContext<RadInterlockStateModel>, { id }: RadInterlockDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
