<h5 mat-dialog-title>How would you like to proceed?</h5>

<mat-dialog-content>
  <mat-nav-list>
    <mat-list-item (click)="openSameTab()">
      <span class="full-width">Open in Same Tab</span>
      <mat-icon matListItemIcon class="blue">system_update_alt</mat-icon>
    </mat-list-item>
    <mat-list-item (click)="openNewTab()">
      <span class="full-width">Open in New Tab</span>
      <mat-icon matListItemIcon class="yellow">open_in_new</mat-icon>
    </mat-list-item>
  </mat-nav-list>
</mat-dialog-content>
