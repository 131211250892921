import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Injector, OnDestroy, OnInit, ViewChild, } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Shielding } from "../accelerator-shielding.component";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { AbstractControl, FormBuilder, FormGroup, Validators, } from "@angular/forms";
import { BaseComponent } from "src/app/common/base/base.component";
import { Observable, Subscription } from "rxjs";
import { ResourceType } from "src/app/common/enumerations/enumerations";
import { Resource, ResourceRelation } from "src/app/components/catalogs/beamline-catalog/resource/resources";

@Component({
  selector: "app-accelerator-shielding-dialog",
  templateUrl: "./accelerator-shielding-dialog.component.html",
  styleUrls: ["./accelerator-shielding-dialog.component.scss"],
})
export class AcceleratorShieldingDialogComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {
  accelerator: Resource;
  shielding: Shielding;
  isAdding: boolean;

  public shieldings!: Resource[];
  public shieldingsFiltered!: Resource[];

  public shieldingKeText?: string | null;
  public shieldingColor?: string | null;
  public shieldingSharedWith!: string;
  public oldShielding!: string;

  public loading = false;

  resources!: Resource[];
  resourcesFiltered!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  resourceRelations!: ResourceRelation[];
  resourceRelationsFiltered!: ResourceRelation[];
  resourceRelations$!: Observable<ResourceRelation[]>;
  resourceRelationsSubs!: Subscription;

  subLocations?: ResourceRelation[];
  subLocationsFiltered!: ResourceRelation[];
  locations?: ResourceRelation[];

  @ViewChild("locationInput")
  locationInput!: ElementRef<HTMLInputElement>;
  @ViewChild("shieldingInput")
  shieldingInput!: ElementRef<HTMLInputElement>;

  public get shieldingCtrl(): AbstractControl | null {
    return this.formGroup.get("shieldingCtrl");
  }
  public get ketextCtrl(): AbstractControl | null {
    return this.formGroup.get("ketextCtrl");
  }
  public get locationCtrl(): AbstractControl | null {
    return this.formGroup.get("locationCtrl");
  }
  public get colorCtrl(): AbstractControl | null {
    return this.formGroup.get("colorCtrl");
  }

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<AcceleratorShieldingDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public inputData: {
      accelerator: Resource;
      shielding: Shielding;
      isAdding: boolean;
    },
  ) {
    super(injector);

    this.accelerator = inputData.accelerator;
    this.shielding = this.utils.cloneDeep(inputData.shielding);

    this.shieldingKeText = this.shielding.resource?.keText;
    this.shieldingColor = this.shielding.resource?.cssClass;
    this.locations = this.shielding.childResources;
    this.isAdding = inputData.isAdding;
    this.subLocations = this.accelerator.childResources?.filter(
      (x) => x.childResourceType == ResourceType.AcceleratorLocation
    );
    this.filterSubLocations();
  }

  override ngOnDestroy(): void {
    this.resourcesSubs?.unsubscribe();
    this.resourceRelationsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    if (!this.shielding.resource && this.shielding.name) {
      setTimeout(() => {
        this.shieldingInput.nativeElement.value = this.shielding.name ?? '';
        this.shieldingCtrl?.setValue(this.shielding.name);
      }, 500);
    } else {
      this.shieldingCtrl?.setValue(this.shielding);
    }
  }

  async ngOnInit() {
    this.initializeForm();
    this.loadResources();
    this.loadResourceRelations();
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      shieldingCtrl: [
        { value: null },
        [Validators.required, this.utils.IsWhiteSpaceReactiveForm],
      ],
      ketextCtrl: [
        { value: null },
        [Validators.required, this.utils.IsWhiteSpaceReactiveForm],
      ],
      locationCtrl: [
        { value: null },
        [Validators.required, this.utils.IsWhiteSpaceReactiveForm],
      ],
      colorCtrl: [
        { value: null },
        [Validators.required, this.utils.IsWhiteSpaceReactiveForm],
      ],
    });

    if (this.isAdding || !this.shielding.resource?.id) {
      // this.shieldingCtrl?.enable();
      this.colorCtrl?.setValue("black");
      this.shieldingColor = "black";
    } else {
      // this.shieldingCtrl?.disable();
    }
  }

  loadResources() {
    this.resources$ = this.store.select((state) => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe((data) => {
      if (data.length) {
        this.resources = data;
        this.shieldings = data
          .filter(
            (x) =>
              x.type == ResourceType.Shielding &&
              x.status == 1 &&
              !this.accelerator.childResources?.filter((r) => r.childResourceType == ResourceType.Shielding)
                .map((r) => r.childResourceID)
                .includes(x.id)
          )
          .sort((a, b) => this.utils.SortBeamlines(a.name, b.name));
        this.shieldingsFiltered = this.utils.cloneDeep(this.shieldings);
      }
    });
  }

  loadResourceRelations() {
    this.resourceRelations$ = this.store.select(
      (state) => state.ResourceRelations.data
    );
    this.resourceRelationsSubs = this.resourceRelations$.subscribe((data) => {
      if (data.length) {
        this.resourceRelations = data;
      }
    });
  }

  save() {
    if (this.isValid()) {
      if ((this.shielding.id ?? 0) < 0 || !this.shielding.id) {
        if (typeof this.shieldingCtrl?.value == "string") {
          this.shielding.name = this.shieldingCtrl.value;
          if (!this.shielding.resource) {
            this.shielding.resource = new Resource();
          }
          this.shielding.resource.type = ResourceType.Shielding;
          this.shielding.resource.name = this.shielding.name;
        } else {
          this.shielding.name = this.shielding.resource?.name;
        }
      }
      if (this.shielding.resource) {
        this.shielding.resource.cssClass = this.shieldingColor;
        this.shielding.resource.keText = this.shieldingKeText;
        this.shielding.childResources = this.locations;
        this.shielding.resource.childResources = this.locations;
        this.dialogRef.close(this.shielding);
      }
    }
  }

  selected(e: any) {
    const value = e.option.value;
    this.shielding.resource = this.utils.cloneDeep(value);
    this.shielding.resourceID = this.shielding.resource?.id ?? 0;
    this.shieldingKeText = this.shielding.resource?.keText;
    const subLocations = this.resourceRelations.filter(
      (x) => x.parentResourceID == this.shielding.resourceID
    );
    if (this.shielding.resource)
      this.shielding.resource.childResources = subLocations;
    this.shielding.childResources = subLocations;
    this.shielding.subLocationNames = subLocations
      .map((x) => x.childResourceName)
      .join(", ");
    this.shieldingColor = this.shielding.resource?.cssClass;
  }

  cancel() {
    this.dialogRef.close();
  }

  isValid() {
    if (!this.locations?.length) {
      this.locationCtrl?.setErrors({ required: true });
    }
    if (!this.shieldingColor) {
      this.colorCtrl?.setErrors({ required: true });
    }
    if (
      typeof this.shieldingCtrl?.value != "string" &&
      !this.shielding.resource?.name
    ) {
      this.shieldingCtrl?.setErrors({ required: true });
    }
    if (
      !this.isAdding &&
      (this.shielding.id ?? 0) < 0 &&
      this.shielding.name &&
      this.shieldingInput?.nativeElement.value &&
      this.shieldingCtrl?.pristine
    ) {
      this.shieldingCtrl.setErrors(null);
    }
    return this.formGroup.valid && !this.formGroup.pristine;
  }

  filter(e: any) {
    const text = e.target.value;
    const code = e.code;
    if (!code?.includes("Arrow")) {
      this.shieldingsFiltered = this.shieldings?.filter((x) =>
        x.name?.toLowerCase().includes(text.trim().toLowerCase())
      );
      if (!this.shieldingsFiltered) {
        this.shielding = this.inputData.shielding;
        this.filterSubLocations();
      }
    }
    this.shielding = this.utils.cloneDeep(this.inputData.shielding);
  }

  selectedLoc(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value as ResourceRelation;
    if (!this.locations) {
      this.locations = [];
    }
    if (value && !this.locations?.some((l) => l.id === value?.id)) {
      this.locations.push(value);
      this.filterSubLocations();
      this.locationInput.nativeElement.value = "";
    }
  }

  removeLoc(subLocation: ResourceRelation): void {
    const index = this.locations?.findIndex((x) => x.id == subLocation.id) ?? -1;
    if (index >= 0) {
      this.locations?.splice(index, 1);
      this.filterSubLocations();
    }
  }

  filterSubLocations() {
    this.subLocationsFiltered = this.subLocations?.filter(
      (x) => !this.locations?.map((s) => s.id).includes(x.childResourceID)
    ) ?? [];
  }

  onLocChange(e: any) {
    const value = e.target.value;
    this.filterSubLocations();
    this.subLocationsFiltered = this.subLocationsFiltered.filter((x) =>
      x.childResourceName?.toLowerCase().includes(value?.trim().toLowerCase())
    );
  }

  selectedColor(e: string) {
    this.shieldingColor = e;
    this.formGroup.markAsDirty();
    this.colorCtrl?.setErrors(null);
  }
}
