import { Component, OnInit, ViewChild } from '@angular/core';
import { BeamlineOverviewDetailsComponent } from '../beamline-overview-details/beamline-overview-details.component';
import { SendIdService } from '../../send-id.service';
import { BeamStatusComponent } from '../../beamline-status.component';

@Component({
  selector: 'app-beamline-overview',
  templateUrl: './beamline-overview.component.html',
  styleUrls: ['./beamline-overview.component.scss']
})
export class BeamlineOverviewComponent implements OnInit {

  public id!: number;

  @ViewChild(BeamlineOverviewDetailsComponent) beamlineOverviewDetails?: BeamlineOverviewDetailsComponent;
  @ViewChild(BeamStatusComponent) beamlineStatusComponent?: BeamStatusComponent;

  constructor(private _sendIdService: SendIdService) { }

  ngOnInit() {
    this._sendIdService.currentId.subscribe(data => {
      if (data) {
        this.id = data as number;
        setTimeout(() => {
          this.beamlineOverviewDetails?.loadBeamlineOverview(this.id);
        }, 500);
      }
    });
  }

  // refresh() {
  //   this._sendIdService.currentId.subscribe(data => {
  //     this.id = data as number;
  //   });
  //   this.beamlineOverviewDetails?.loadBeamlineOverview(this.id);
  // }

  // getId(id: number) {
  //   this.beamlineOverviewDetails?.loadBeamlineOverview(id);
  // }
}
