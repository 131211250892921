import { ScheduleType } from './schedule-type';
import { ScheduleSubtype } from './schedule-subtype';
import { Schedule } from './schedule';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';

export interface ScheduleResources {
  id?: number;
  scheduleId?: number;
  resourceId?: number | null;
  schedule?: Schedule;
  scheduleSubtypeId?: number;
  resource?: Resource;
  resourceString?: string;
  absiTemplateTypeID?: number;
}

export class ScheduleResource {
  id?: number;
  resourceId?: number;
  name?: string | null;
  dateValue1?: Date;
  dateValue2?: Date;
  dateValue3?: Date;
}

export class ScheduleResourcesByTab {
  tabId!: number;
  scheduleType!: ScheduleType;
  scheduleSubtype!: ScheduleSubtype;
  resources!: ScheduleResources[];
}
