import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AbsiService } from '../../absi.service';
import { AbsiSectionCreate, AbsiSectionDelete, AbsiSectionRefresh, AbsiSectionUpdate } from './absi-section.action';
import { WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';
import { ActionItemsUpdateBySection } from 'src/app/controls/catl/action-item/store/actionItems.action';
import { state } from '@angular/animations';
import { utils } from 'src/app/modules/libs/utils';

export class AbsiSectionStateModel {
  data: WFSectionLocalResource[] = [];
}

@State<AbsiSectionStateModel>({
  name: 'AbsiSections',
  defaults: {
    data: []
  }
})

@Injectable()
export class AbsiSectionState {
  constructor(
    private absiService: AbsiService,
    private store: Store
  ) { }

  @Selector()
  static read(state: AbsiSectionStateModel) {
    return state.data;
  }

  @Action(AbsiSectionRefresh)
  refresh({ patchState, getState }: StateContext<AbsiSectionStateModel>, { wfTableID }: AbsiSectionRefresh) {
    if (wfTableID)
      this.absiService.readAllSections(wfTableID).subscribe(data => {
        let newData = getState().data.filter(x => x.wfTableID && x.wfTableID != wfTableID);
        newData = newData.concat(data);
        patchState({
          data: utils.cloneDeep(newData)
        });
      });
  }

  @Action(AbsiSectionCreate)
  create({ getState, patchState }: StateContext<AbsiSectionStateModel>, { item }: AbsiSectionCreate) {
    patchState({
      data: [...getState().data, item]
    });
    this.store.dispatch(new ActionItemsUpdateBySection(item.id, item.actionItems ?? []));

  }

  @Action(AbsiSectionUpdate)
  update({ getState, patchState }: StateContext<AbsiSectionStateModel>, { id, item }: AbsiSectionUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0)
      state.data.splice(index, 1);
    patchState({
      data: [...state.data, item]
    });
    this.store.dispatch(new ActionItemsUpdateBySection(item.id, item.actionItems ?? []));
  }

  @Action(AbsiSectionDelete)
  delete({ getState, patchState }: StateContext<AbsiSectionStateModel>, { id }: AbsiSectionDelete) {
    patchState({
      data: getState().data.filter(x => x.id != id)
    });
  }

}
