<div class="row no-gutters">
  <div class="col-12 col-lg-4">
    <!-- <resources-list-new
      [selectedTab]="selectedTab"
      (rowSelected)="getDetails($event)"
      #list
    ></resources-list-new> -->
  </div>
  <div class="col-12 col-lg-8">
    <div class="toggle d-flex justify-content-end">
      <mat-slide-toggle
        *ngIf="showChangesToggle"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="showPrevResource"
      >
        Show Modification
      </mat-slide-toggle>
    </div>

    <div class="card card-wrapper mt-2">
      <mat-tab-group
        animationDuration="0ms"
        [(selectedIndex)]="selectedTab"
        (selectedIndexChange)="tabClicked($event)"
      >
        <mat-tab id="titulo" label="Details">
          <!-- <app-resource-details-new
            [showPrevResource]="showPrevResource"
            (pending)="list.pending = $event"
            #details
          ></app-resource-details-new> -->
        </mat-tab>
        <mat-tab label="KE Builder" *ngIf="isShutter">
          <!-- <ke-checklist-builder
            #keBuilder
            (requestRefresh)="list.refreshResources($event)"
          ></ke-checklist-builder> -->
        </mat-tab>
        <mat-tab label="RSS Database">
          <rss-test-database
            #rssTest
            (pending)="list.pending = $event"
          ></rss-test-database>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
