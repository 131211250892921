import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
// import { SignalRAction } from 'src/app/enums/signalr-enums';
// import { SignalRService } from 'src/app/Services/SignalR/signal-r.service';
// import { GeneralSignalRResponse } from 'src/app/_interface/signal-r-models/GeneralSignalRResponse';
import * as UsersActions from './users.action';
import { Channels } from 'src/app/store/channels';
import { GeneralSignalRResponse } from '../../../../services/signal-r/general-signal-r-response';
import { SignalRService } from '../../../../services/signal-r/signal-r.service';
import { SignalRAction } from '../../../../services/signal-r/signalr-enums';
// import { Channels } from 'src/app/Store/channels';

@Injectable({
  providedIn: 'root'
})
export class UsersStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore() {
    this.store.dispatch(new UsersActions.UsersRefresh());
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.USERS, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Add:
          this.store.dispatch(new UsersActions.UsersCreate(data.obj));
          break;

        case SignalRAction.Update:
          this.store.dispatch(new UsersActions.UsersUpdate(data.id, data.obj));
          break;

        case SignalRAction.Delete:
          this.store.dispatch(new UsersActions.UsersDelete(data.id));
          break;

        case SignalRAction.Get:
          this.store.dispatch(new UsersActions.UsersRefresh());
          break;
      }
    });
  }
}
