import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren, ViewContainerRef, OnDestroy, Input, OnChanges, SimpleChanges, Injector, ViewChild, AfterViewInit } from '@angular/core';
import { MatRow, MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { ScfV2Service } from '../scf-v2.service';
import { SCFMastersUpdate } from '../store/scf-master/scf-masters.action';
import { FormStatusEnum, FormType } from 'src/app/common/enumerations/enumerations';
import { utils } from 'src/app/modules/libs/utils';
import { SCFMaster } from '../scf';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'scf-v2-side',
  templateUrl: './scf-v2-side.component.html',
  styleUrls: ['./scf-v2-side.component.scss']
})
export class ScfV2SideComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @Input() filter?: string | null;
  @Output() loading = new EventEmitter<boolean>();
  @Output() rowSelected = new EventEmitter<any>();

  @ViewChildren('matrow', { read: ViewContainerRef }) rows?: QueryList<ViewContainerRef>;
  @ViewChildren('matrow') datarows?: QueryList<MatRow>;

  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = [
    'serialNo',
    'locationNames',
    'statusName',
  ];

  dataSource = new MatTableDataSource<SCFMaster>();

  scfMasters: SCFMaster[] = [];
  scfMasters$!: Observable<SCFMaster[]>;
  scfMastersSubs: Subscription = new Subscription;
  scfMaster?: SCFMaster | null;

  serialNo!: string;
  selectedRowIndex? = -1;
  selectedIndex = -1;
  showClosed = false;

  allowNew = false;
  disabled = false;
  maxHeight!: string;

  constructor(
    private service: ScfV2Service,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.setSort<SCFMaster>(this.dataSource, this.sort);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter']) {
      this.applyFilter();
    }
  }

  override ngOnDestroy(): void {
    this.scfMastersSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.loadSCFs();
  }

  loadSCFs() {
    this.loading.emit(true);
    this.scfMasters$ = this.store.select(state => state.SCFMasters.data);
    this.scfMastersSubs = this.scfMasters$.subscribe(async data => {
      if (data?.length > 0) {
        this.scfMasters = data.filter(x => x.serialNo !== '');
        this.createDataSource();

        const openDocument = await this.checkOpenDocument<SCFMaster>(FormType.SCF, data);
        if (openDocument) {
          this.loading.emit(true);
          this.showClosed = openDocument.closed;
          this.getValues(openDocument.document);
        }
        else this.loading.emit(false);
      }
    });
  }

  createDataSource() {
    this.dataSource = new MatTableDataSource(this.scfMasters?.filter(x => ((x.status !== FormStatusEnum.Canceled && x.status !== FormStatusEnum.Closed) && !this.showClosed) || this.showClosed).sort((a, b) => utils.sort(a.serialNo, b.serialNo, false)));
    this.setSort<SCFMaster>(this.dataSource, this.sort);
  }

  rowUnselected() {
    this.scfMaster = null;
    this.selectedRowIndex = -1;
    this.rowSelected.emit(null);
  }

  async rowClicked(row: SCFMaster) {
    if (await this.canDeactivate()) {
      if (this.scfMaster?.id == row.id) {
        this.rowUnselected();
        this.loading.emit(false);
      }
      else {
        this.getValues(row);
      }
    }
  }

  async getValues(row: SCFMaster) {
    this.loading.emit(true);
    this.selectedIndex = this.dataSource.data.findIndex(x => x.id === row.id);
    if (row?.id) {
      this.setOpenDocument(FormType.SCF, row.id);
      if (!row.wfTable || row.retrievedBy != this.currentUser?.id) {
        this.scfMaster = await this.getSCFMasterByID(row.id);
        if (this.scfMaster && this.scfMaster?.id)
          this.store.dispatch(new SCFMastersUpdate(this.scfMaster?.id, this.scfMaster));
      }
      else this.scfMaster = row;
      if (this.scfMaster && this.scfMaster?.id) {
        this.selectedRowIndex = this.scfMaster?.id;
        this.rowSelected.emit(this.scfMaster);
        this.loading.emit(false);
        this.showElement(this.selectedIndex, 7);
      }

    }
  }

  getSCFMasterByID(id: number): Promise<SCFMaster> {
    return new Promise((resolve, reject) => {
      this.service?.getById(id).toPromise().then(scfMaster => {
        if (scfMaster) {
          scfMaster.retrievedBy = this.getCurrentUser()?.id;
          resolve(scfMaster);
        }
      });
    });
  }

  showDocument(e: SCFMaster) {
    this.getValues(e);
  }

  //#endregion LOAD

  //#region ADD

  // createSCF() {
  //   this.selectedRowIndex = -1;
  //   this.serialNo = '{Serial No}';
  //   const scf = {
  //     id: 0,
  //     serialNo: this.serialNo,
  //     type: FormType.SCF,
  //     status: 1,
  //     locations: [],
  //     statusName: 'New',
  //     scfRestrictionsV2: [],
  //     wasRouted: false,
  //     editingBy: this.currentUser,
  //     editingById: this.currentUser?.id
  //   } as SCFMaster;
  //   this.rowSelected.emit(scf);
  // }

  // unselect() {
  //   this.createSCF();
  // }

  //#endregion

  //#region FILTERS

  applyFilter() {
    this.createDataSource();
    this.dataSource.filter = this.filter?.trim().toLowerCase() ?? '';
  }

  changeClosed() {
    this.applyFilter();
  }

  async refresh() {

  }

  //#endregion

  // #region TableScroll
  showElement(index: number, height: number) {
    const indexstr = index.toString();
    const row = this.rows?.find(
      (r) => r.element.nativeElement.getAttribute('position') === indexstr
    );
    if (row) {
      const rect = row.element.nativeElement.getBoundingClientRect();
      if (rect.y <= 0 || rect.y + rect.height > height) {
        row.element.nativeElement.scrollIntoView(false, {
          behavior: 'instant',
        });
      }
      return;
    }
    console.log('not found');
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex((r) => {
      return r.id === row.id;
    });
    return i;
  }

  formatLocationNames(locationNames: string) {
    if (locationNames) {
      return utils.replaceAll(locationNames, ',', ', ');
    }
    return '';
  }


}
