import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { utils } from 'src/app/modules/libs/utils';
import { APLCategory } from 'src/app/components/apl/services/apl-category.model';
// import { APLCategory } from 'src/app/_interface/apl/apl-category.model';

@Component({
  selector: 'apl-category-autocomplete',
  templateUrl: './apl-category-autocomplete.component.html',
  styleUrls: ['./apl-category-autocomplete.component.scss']
})
export class APLCategoryAutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() aplCategoryID?: number;

  //   return this.aplCategoryIDValue;
  // }

  @Output() aplCategoryIDChange = new EventEmitter<number>();

  // set aplCategoryID(val) {
  //   this.aplCategoryIDValue = val;
  //   this.aplCategoryIDChange.emit(this.aplCategoryIDValue);
  // }

  aplCategoryIDValue!: number;
  aplCategorySubscription!: Subscription;
  aplCategory$!: Observable<APLCategory[]>;
  allAplCategories!: APLCategory[];
  filteredAplCategories!: APLCategory[];
  text!: string;

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.aplCategory$ = this.store.select(state => state.APLCategory.APLCategories);
    this.aplCategorySubscription = this.aplCategory$.subscribe(aplCategories => {
      this.allAplCategories = aplCategories;
      this.filteredAplCategories = utils.cloneDeep(this.allAplCategories);
      this.set();
      this.filter();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('aplCategoryID' in changes) {
      this.set();
      this.filter();
    }
  }

  ngOnDestroy(): void {
    this.aplCategorySubscription?.unsubscribe();
  }

  set() {
    if (this.allAplCategories?.filter(x => x.id === this.aplCategoryID)?.length > 0) {
      this.text = this.allAplCategories.filter(x => x.id === this.aplCategoryID)[0]?.name;
    } else {
      this.text = '';
    }
  }

  filter(code?: string) {
    if (!code?.includes('Arrow')) {
      if (this.text != null) {
        this.filteredAplCategories = this.allAplCategories?.filter(x => x.name?.trim()?.toLowerCase()?.includes(this.text?.trim()?.toLowerCase()));
      } else {
        this.filteredAplCategories = utils.cloneDeep(this.allAplCategories);
      }
    }
  }

  select(event: MatAutocompleteSelectedEvent) {
    const aplCategory: APLCategory = event.option.value;
    this.aplCategoryID = aplCategory.id;
    this.set();
    this.filter();
  }

}
