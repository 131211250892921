<div class="card full-height">
  <div class="card-header d-flex justify-content-between">
    <h5 class="card-title">Duplicate Checklist Template</h5>
    <button
      mat-mini-fab
      class="closex"
      color="warn"
      [disabled]="loading"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="card-body full-height d-flex flex-column justify-content-center">
    <div class="d-flex">
      <div class="w-50">
        <div class="card p-0">
          <div class="card-header">From</div>
          <div class="card-body">
            <mat-form-field appearance="outline">
              <mat-label>Type</mat-label>
              <input
                aria-label="name"
                type="text"
                matInput
                [(ngModel)]="templateType.name"
                disabled
              />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              *ngIf="
                templateType.allowMultipleVersions || targetDocumentTypeID == 18
              "
            >
              <mat-label>Name</mat-label>
              <input
                aria-label="name"
                type="text"
                matInput
                [(ngModel)]="templateVersion.name"
                disabled
              />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              *ngIf="templateType.scheduleTypeID && schedule"
            >
              <mat-label>Resource</mat-label>
              <input
                aria-label="name"
                type="text"
                matInput
                [(ngModel)]="schedule.name"
                disabled
              />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              *ngIf="templateType.resourceTypeID"
            >
              <mat-label>Resource</mat-label>
              <input
                aria-label="name"
                type="text"
                matInput
                [(ngModel)]="resource.name"
                disabled
              />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              *ngIf="templateVersion.procedure"
            >
              <mat-label>Procedure</mat-label>
              <input
                aria-label="name"
                type="text"
                matInput
                [ngModel]="
                  templateVersion.procedure.procedureNumber +
                  ' - Rev: ' +
                  templateVersion.procedure.revision
                "
                disabled
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Version</mat-label>
              <input
                aria-label="serialNo"
                type="text"
                matInput
                [(ngModel)]="templateVersion.serialNo"
                disabled
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 5px;
        "
      >
        <button
          id="go"
          mat-mini-fab
          matTooltip="Proceed with duplication"
          color="primary"
          [disabled]="!enable() || loading"
          (click)="duplicate()"
        >
          <mat-icon>arrow_forward_ios</mat-icon>
        </button>
      </div>
      <div class="w-50">
        <div class="card p-0">
          <div class="card-header">To</div>
          <div class="card-body">
            <mat-form-field appearance="outline">
              <mat-label>Type</mat-label>
              <input
                type="text"
                placeholder="Select Type"
                matInput
                name="typeCtrl"
                [matAutocomplete]="autoType"
                [disabled]="disabled"
              />
              <mat-autocomplete
                #autoType="matAutocomplete"
                #typeAutocomplete
                [displayWith]="displayObject"
                (optionSelected)="selectedType($event)"
              >
                <mat-option
                  *ngFor="let type of filteredTemplateTypes"
                  [value]="type"
                >
                  {{ type.name }}
                </mat-option>
              </mat-autocomplete>
              <mat-hint class="error" *ngIf="!currentTemplateType"
                >You must choose a Type</mat-hint
              >
              <mat-hint *ngIf="currentTemplateType">
                {{ currentTemplateType.description }}
              </mat-hint>
            </mat-form-field>
            <mat-form-field
              *ngIf="
                currentTemplateType?.allowMultipleVersions ||
                targetDocumentTypeID == 18
              "
            >
              <mat-label>Name</mat-label>
              <input
                type="text"
                placeholder="Custom name"
                matInput
                name="nameCtrl"
                [(ngModel)]="name"
                [disabled]="disabled"
              />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              *ngIf="currentTemplateType?.scheduleTypeID"
            >
              <mat-label>Resource</mat-label>
              <input
                type="text"
                placeholder="Select Resource"
                matInput
                name="scheduleCtrl"
                [matAutocomplete]="autoSchedule"
                [(ngModel)]="tmpSchedule"
                (keyup)="changedSchedule()"
                [disabled]="disabled"
              />
              <mat-autocomplete
                #autoSchedule="matAutocomplete"
                #scheduleAutocomplete
                [displayWith]="displayObject"
                (optionSelected)="selectedSchedule($event)"
              >
                <mat-option
                  [disabled]="schedule.objectInfo"
                  *ngFor="let schedule of filteredSchedules"
                  [value]="schedule"
                >
                  {{ schedule.name }}
                </mat-option>
              </mat-autocomplete>
              <mat-hint class="error" *ngIf="!currentSchedule"
                >You must select a Resource</mat-hint
              >
              <mat-hint *ngIf="currentSchedule">
                {{ currentSchedule.description }}
              </mat-hint>
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              *ngIf="currentTemplateType?.resourceTypeID"
            >
              <mat-label>Resource</mat-label>
              <input
                type="text"
                placeholder="Select Resource"
                matInput
                name="resourceCtrl"
                [matAutocomplete]="autoResource"
                [(ngModel)]="tmpResource"
                (keyup)="changedResource()"
                [disabled]="disabled"
              />
              <mat-autocomplete
                #autoResource="matAutocomplete"
                #resourceAutocomplete
                [displayWith]="displayObject"
                (optionSelected)="selectedResource($event)"
              >
                <mat-option
                  *ngFor="let resource of filteredResources"
                  [value]="resource"
                >
                  {{ resource.name }}
                </mat-option>
              </mat-autocomplete>
              <mat-hint class="error" *ngIf="!currentResource"
                >You must select a Resource</mat-hint
              >
              <mat-hint *ngIf="currentResource">
                {{ currentResource.description }}
              </mat-hint>
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              *ngIf="currentSchedule?.procedure"
            >
              <mat-label>Procedure</mat-label>
              <input
                aria-label="name"
                type="text"
                matInput
                [ngModel]="
                  currentSchedule?.procedure?.procedureNumber +
                  ' - Rev: ' +
                  currentSchedule?.procedure?.revision
                "
                disabled
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Version</mat-label>
              <input
                aria-label="version"
                type="text"
                matInput
                [(ngModel)]="newVersion"
                disabled
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="card-footer"
    [ngClass]="{
      'red-background': message.type == messageType.Error,
      'yellow-background': message.type == messageType.Warning,
      'blue-background': message.type == messageType.Info,
      'green-background': message.type == messageType.Success
    }"
  >
    <span [innerHTML]="message.text" *ngIf="message"></span>
  </div>
</div>
