<div class="container-fluid">
  <div class="row mt-3">
    <div class="col">
      <h5>Follow Up</h5>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <ppstb-follow-up
        [form]="form"
        [title]="'Repair / replace component'"
        [roleCodes]="'EM/OIC/PPSTBTA'"
        [checked]="form?.formVersion?.ppstb?.repairReplaceComponent"
        [disable]="
          !(
            form?.formVersion?.status?.activeWork &&
            hasPrivilege(privilegeEnum.PPSTBRepairReplaceComponent)
          )
        "
        [ppstbOtherFollowUps]="form?.formVersion?.ppstb?.ppstbOtherFollowUps"
        [ppstbFollowUpType]="ppstbFollowUpType.RepairReplaceComponent"
        (approve)="
          approveFollowUp($event, ppstbFollowUpType.RepairReplaceComponent)
        "
      >
      </ppstb-follow-up>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <ppstb-follow-up
        class="mb-2"
        [form]="form"
        [title]="'Modify interlock'"
        [roleCodes]="'PPSTBTA'"
        [checked]="form?.formVersion?.ppstb?.modifyInterlock"
        [disable]="
          !(
            form?.formVersion?.status?.activeWork &&
            hasPrivilege(privilegeEnum.PPSTBModifyInterlock)
          )
        "
        [ppstbOtherFollowUps]="form?.formVersion?.ppstb?.ppstbOtherFollowUps"
        [ppstbFollowUpType]="ppstbFollowUpType.ModifyInterlock"
        (approve)="approveFollowUp($event, ppstbFollowUpType.ModifyInterlock)"
      >
      </ppstb-follow-up>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <ppstb-follow-up
        class="mb-2"
        [form]="form"
        [title]="'Other'"
        [roleCodes]="'EM/OIC/PPSTBTA'"
        [checked]="form?.formVersion?.ppstb?.other"
        [disable]="
          !(
            form?.formVersion?.status?.activeWork &&
            hasPrivilege(privilegeEnum.PPSTBOther)
          )
        "
        [ppstbOtherFollowUps]="form?.formVersion?.ppstb?.ppstbOtherFollowUps"
        [ppstbFollowUpType]="ppstbFollowUpType.Other"
        (approve)="approveFollowUp($event, ppstbFollowUpType.Other)"
      >
      </ppstb-follow-up>
    </div>
  </div>

  <form-workflow
    [form]="form"
    [sectionNumber]="8"
    [disabled]="disabled"
    (loading)="loading.emit($event)"
  >
  </form-workflow>
</div>
