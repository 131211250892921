import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ReviewPermissionsCreate, ReviewPermissionsDelete, ReviewPermissionsRefresh, ReviewPermissionsUpdate } from './review-permissions.action';
import { ReviewsService } from '../../reviews.service';
import { ReviewPermission } from '../../reviews.models';

export class ReviewPermissionsStateModel {
  data: ReviewPermission[] = [];
}

@State<ReviewPermissionsStateModel>({
  name: 'ReviewPermissions',
  defaults: {
    data: []
  }
})
@Injectable()
export class ReviewPermissionsState {
  constructor(
    private _service: ReviewsService
  ) { }

  @Selector()
  static read(state: ReviewPermissionsStateModel) {
    return state.data;
  }

  @Action(ReviewPermissionsRefresh)
  refresh({ patchState }: StateContext<ReviewPermissionsStateModel>) {
    this._service.getReviewPermissions().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ReviewPermissionsCreate)
  create({ getState, patchState }: StateContext<ReviewPermissionsStateModel>, { item }: ReviewPermissionsCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ReviewPermissionsUpdate)
  update({ getState, patchState }: StateContext<ReviewPermissionsStateModel>, { id, item }: ReviewPermissionsUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ReviewPermissionsDelete)
  delete({ getState, patchState }: StateContext<ReviewPermissionsStateModel>, { id }: ReviewPermissionsDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
