import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { Review, ReviewAttendanceCategory, ReviewAttendanceRoster } from 'src/app/components/reviews/reviews.models';
import { ReviewsService } from 'src/app/components/reviews/reviews.service';
import { ReviewAttendanceCategoriesRefresh } from 'src/app/components/reviews/store/review-attendance-category/review-attendance-category.action';
import { CanDeactivateResponse } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog-response';
import { PendingChangesDialogComponent } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog.component';
import { Role } from 'src/app/components/catalogs/roles/services/role';

@Component({
  selector: 'app-review-members',
  templateUrl: './review-members.component.html',
  styleUrls: ['./review-members.component.scss']
})
export class ReviewMembersComponent extends BaseComponent implements OnInit, OnDestroy {

  public roles$!: Observable<Role[]>;

  public roleSubscription!: Subscription;

  public roles!: Role[];

  public reviewMemberForm!: FormGroup;
  public get reviewRoleCtrl(): AbstractControl | null { return this.reviewMemberForm.get('reviewRoleCtrl'); }
  public get reviewUserCtrl(): AbstractControl | null { return this.reviewMemberForm.get('reviewUserCtrl'); }
  public get reviewCategoryCtrl(): AbstractControl | null { return this.reviewMemberForm.get('reviewCategoryCtrl'); }
  public guestMemberForm!: FormGroup;
  public get guestMemberNameCtrl(): AbstractControl | null { return this.guestMemberForm.get('guestMemberNameCtrl'); }
  public get guestMemberCategoryCtrl(): AbstractControl | null { return this.guestMemberForm.get('guestMemberCategoryCtrl'); }

  addGuest!: boolean;
  guestName!: string;
  guestCategory: string = 'Guest';
  isLoading!: boolean;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<ReviewMembersComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: {
      review: Review,
      reviewMember: ReviewAttendanceRoster,
      reviewCategories: ReviewAttendanceCategory[],
      dialogTitle: string
    },
    private reviewsService: ReviewsService,
  ) {
    super(injector);

  }

  ngOnInit() {
    this.inputData.reviewMember.reviewID = this.inputData.review.id;
    this.initializeForm();
    this.getRoles();
  }

  override ngOnDestroy(): void {
    this.roleSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  add() {
    if (this.reviewMemberForm.valid && !this.addGuest) {
      this.reviewsService.createReviewAttendanceRoster(this.inputData.reviewMember).toPromise().then(() => {
        this.alert.message('ReviewMember_Added');
        this.store.dispatch(new ReviewAttendanceCategoriesRefresh());
        this.dialogRef.close(this.inputData.reviewMember);
      }).catch((error: HttpErrorResponse) => {
        if (error.status === 409) {
          const member = this.users.find(x => x.id === this.inputData.reviewMember.userID);
          const role = this.roles.find(x => x.id === this.inputData.reviewMember.roleID);
          const category = this.inputData.reviewCategories.find(x => x.id === this.inputData.reviewMember.categoryID);
          this.alert.message(error.error, [new MessagePlaceholder('{reviewMember}', member?.name), new MessagePlaceholder('{reviewRole}', role?.name), new MessagePlaceholder('{reviewMemberCategory}', category?.name)]);
        } else {
          this.alert.message('genericError');
          this.dialogRef.close(this.inputData.reviewMember);
        }
      });
    }
    else if (this.guestMemberForm.valid && this.addGuest) {
      const guestRoster: ReviewAttendanceRoster = {
        id: 0,
        userID: 0,
        name: this.utils.nameToCamelCase(this.guestName),
        roleID: -1,
        reviewID: this.inputData.review.id,
        categoryID: -1
      }
      this.reviewsService.createGuestAttendanceRoster(guestRoster).toPromise().then((data) => {
        this.alert.message('ReviewMember_Added');
        this.store.dispatch(new ReviewAttendanceCategoriesRefresh());
        this.dialogRef.close(data);
      });
      // this.isLoading = false;
    }

  }

  addGuestChange(e: any) {
    this.addGuest = e.checked;
    this.reviewMemberForm.reset();
    this.guestMemberForm.reset();
  }

  close() {
    if (this.reviewMemberForm.dirty) {
      const confirmation = this.dialog.open(PendingChangesDialogComponent, {
        height: 'fit-content',
        width: '40%',
        data: {}
      });
      confirmation.afterClosed().toPromise().then((response: CanDeactivateResponse) => {
        if (response === CanDeactivateResponse.Discard) {
          this.dialogRef.close(null);
        }
      });
    } else {
      this.dialogRef.close(null);
    }
  }

  getRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.roleSubscription = this.roles$.subscribe(roles => {
      this.roles = this.utils.cloneDeep(roles);
    });
  }

  getUsersByRoleId(roleId: number) {
    this.users = [];
    this.roles.find(role => role.id === roleId)?.userRole?.map(userRole => {
      if (userRole.user)
        this.users.push(userRole.user);
    });
  }

  initializeForm() {
    this.reviewMemberForm = this.formBuilder.group({
      reviewRoleCtrl: [{ value: '' }, Validators.required],
      reviewUserCtrl: [{ value: '' }, Validators.required],
      reviewCategoryCtrl: [{ value: '' }, Validators.required]
    });

    this.guestMemberForm = this.formBuilder.group({
      guestMemberNameCtrl: [{ value: '' }, Validators.required],
      guestMemberCategoryCtrl: [{ value: 'Guest' }, Validators.required]
    });
    this.reviewMemberForm.reset();
    this.guestMemberForm.reset();
    this.guestMemberCategoryCtrl?.disable();
  }


}
