import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CanDeactivateResponse } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog-response';
import { PendingChangesDialogComponent } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog.component';
import { PPSTBOtherFollowUp } from '../../ppstb-other-follow-up';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PPSTBFollowUpType } from 'src/app/components/schedules/models/enums';
import { Form } from 'src/app/services/forms/form';

class DialogData {
  roleCodes!: string;
  title!: string;
  disable!: boolean;
  checked!: boolean;
  ppstbOtherFollowUps!: PPSTBOtherFollowUp[];
  ppstbFollowUpType!: number;
  details!: string;
  isDialog!: boolean;
};

@Component({
  selector: 'ppstb-follow-up',
  templateUrl: './ppstb-follow-up.component.html',
  styleUrls: ['./ppstb-follow-up.component.scss']
})
export class PpstbFollowUpComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() form?: Form | null;
  @Input() roleCodes!: string;
  @Input() title!: string;
  @Input() disable!: boolean;
  @Input() checked?: boolean;
  @Input() ppstbOtherFollowUps?: PPSTBOtherFollowUp[];
  @Input() ppstbFollowUpType!: PPSTBFollowUpType;

  @Output() approve = new EventEmitter<any>();
  private dialogRef: any = null;


  public signedOn?: Date | null;

  public userName?: string;
  public details?: string;
  public inputLabelTextCustom = 'Details';

  public ppstbOtherFollowUp?: PPSTBOtherFollowUp;

  public textAreaControl = new FormControl();

  dialogData?: DialogData;

  constructor(
    protected override injector: Injector,
  ) {
    super(injector);
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);

  }

  ngOnInit(): void {
    this.setDialog();
    this.innerWidth = window.innerWidth;
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      textAreaControl: this.textAreaControl
    });
  }

  accept() {
    const infoToSend = {
      value: this.checked,
      ppstbOtherFollowUp: this.ppstbOtherFollowUp,
      details: this.details
    };
    this.dialogRef.close(infoToSend);
  }

  checkboxChanged() {
    if (!this.checked)
      this.details = '';
  }

  textChanged(e?: string) {
    if (e) {
      this.details = e;
    }
    else {
      this.textAreaControl.setErrors({ required: true });
      this.formGroup.markAllAsTouched();
    }
  }

  cancel() {
    const confirmation = this.dialog.open(PendingChangesDialogComponent, {
      height: 'fit-content',
      width: '40%',
      data: {}
    });
    confirmation.afterClosed().toPromise().then((response: CanDeactivateResponse) => {
      if (response === CanDeactivateResponse.Discard) {
        this.dialogRef.close(null);
      }
    });
  }

  setDialog() {
    if (this.dialogData?.isDialog) {
      this.roleCodes = this.dialogData.roleCodes;
      this.title = this.dialogData.title;
      this.disable = this.dialogData.disable;
      this.checked = this.dialogData.checked;
      this.details = this.dialogData.details;
      this.ppstbOtherFollowUp = this.dialogData.ppstbOtherFollowUps?.find(x => x.type === this.dialogData?.ppstbFollowUpType);
    }
  }

  followUpDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      roleCodes: this.roleCodes,
      title: this.title,
      disable: this.disable,
      checked: this.checked,
      ppstbOtherFollowUps: this.ppstbOtherFollowUps,
      ppstbFollowUpType: this.ppstbFollowUpType,
      details: this.details,
      isDialog: true
    };
    dialogConfig.minWidth = '60vw';
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(PpstbFollowUpComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then((infoToSend) => {
      if (infoToSend) {
        this.approve.emit(infoToSend);
      }
    });
  }

  async refresh() {
    this.userName = '';
    this.details = '';
    this.signedOn = null;
    if (this.ppstbOtherFollowUps) {
      this.ppstbOtherFollowUp = this.ppstbOtherFollowUps.find(x => x.type === this.ppstbFollowUpType);
      if (this.ppstbOtherFollowUp) {
        const user = this.getUser(this.ppstbOtherFollowUp.signedBy);
        if (user) { this.userName = user.name; }
        this.signedOn = this.ppstbOtherFollowUp?.signedOn;
        this.details = this.ppstbOtherFollowUp?.text;
      }
    }
  }

}
