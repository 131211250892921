import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ResourceType } from 'src/app/common/enumerations/enumerations';
import { ResourceRelation, Resource, Location } from 'src/app/components/catalogs/beamline-catalog/resource/resources';

@Component({
  selector: 'accelerator-location-chips',
  templateUrl: './accelerator-location-chips.component.html',
  styleUrls: ['./accelerator-location-chips.component.scss']
})
export class AcceleratorLocationChipsComponent implements OnInit, OnChanges {

  @Input() placeholder!: string;
  @Input() locations?: any[];
  @Input() disabled!: boolean;
  @Input() hilite!: boolean | null;

  @Output() removed = new EventEmitter<any>();
  @Output() selected = new EventEmitter<any>();

  control = new FormControl();

  // filteredLocations: Resource[];

  resourceRelations?: ResourceRelation[];
  resourceRelationsFiltered?: ResourceRelation[];
  resourceRelations$!: Observable<ResourceRelation[]>;
  resourceRelationsSubs!: Subscription;
  locationGroups!: any[];

  resources?: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;


  constructor(
    private store: Store
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadResourceRelations();
    this.loadResources();
  }

  ngOnInit() {
  }

  createLocationGroups(text: string | null = null) {
    this.locationGroups = [];
    this.resources?.map(l => {
      if (l.type == 1) {
        if (!this.locationGroups.map(g => g.type).includes(l.id)) {
          const relations = this.resourceRelations?.filter(rr => rr.childResourceType == ResourceType.AcceleratorLocation && rr.parentResourceID == l.id && !this.locations?.map(x => x.id).includes(rr.childResourceID) && (rr.childResourceName?.toLowerCase().includes(text?.toLowerCase() ?? '') || !text));
          const locs = relations?.map(rr => {
            return { id: rr.childResourceID, name: rr.childResourceName, type: rr.childResourceType };
          });
          this.locationGroups.push({ name: l.name, type: l.type, id: l.id, locations: locs });
        }
      }
      else {
        if (!this.locationGroups.map(g => g.type).includes(l.type) && l.type != ResourceType.AcceleratorLocation) {
          const locs =
            this.resources?.filter(x => x.type == l.type && (x.name?.toLowerCase().includes(text?.toLowerCase() ?? '') || !text)).map(l => {
              return { id: l.id, name: l.name } as Location;
            });
          this.locationGroups.push({ name: l.resourceType?.name, type: l.type, locations: locs });
        }
      }
    });
    if (text) {
      this.locationGroups = this.locationGroups.filter(g => g.locations?.some((l: any) => l.name?.toLowerCase().includes(text.toLowerCase())));
    }
  }

  loadResourceRelations() {
    this.resourceRelations$ = this.store.select(state => state.ResourceRelations.data);
    this.resourceRelationsSubs = this.resourceRelations$.subscribe(data => {
      if (data.length) {
        this.resourceRelations = data;
      }
    });
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data.length) {
        this.resources = data.filter(r => r.type == ResourceType.Accelerator);
        this.createLocationGroups();
      }
    });
  }

  onKeyup(e: any) {
    const code = e.code;
    const text = e.taget.value;
    if (!code?.includes("Arrow")) {
      this.createLocationGroups(text);
    }
  }

}
