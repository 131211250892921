import { ReviewPermission } from "../../reviews.models";

export class ReviewPermissionsRefresh {
  static readonly type = '[ReviewPermissions] Refresh';
}

export class ReviewPermissionsCreate {
  static readonly type = '[ReviewPermissions] Create';

  constructor(public item: ReviewPermission) { }
}

export class ReviewPermissionsUpdate {
  static readonly type = '[ReviewPermissions] Update';

  constructor(public id: number, public item: ReviewPermission) { }
}

export class ReviewPermissionsDelete {
  static readonly type = '[ReviewPermissions] Delete';
  constructor(public id: number) { }
}
