import { Component, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy, Injector, Input, SimpleChanges } from '@angular/core';
import { RSSWAResource } from '../rsswa';
import { RsswaService } from '../rsswa.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TokenInfoService } from 'src/app/services/authentication/token-info.service';
import { WfSignatureResource, WFTaskSignatureResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'rsswa-main-panel',
  templateUrl: './rsswa-main-panel.component.html',
  styleUrls: ['./rsswa-main-panel.component.scss']
})
/* rsswa-main-panel component*/
export class RsswaMainPanelComponent extends BaseComponent implements OnInit {
  @Input() rsswa?: RSSWAResource | null;
  @Input() showAmendment?: boolean;
  @Input() testingCompleteSigned? = false;

  @Output() loading = new EventEmitter<boolean>();
  @Output() requestRefresh = new EventEmitter<number>();
  @Output() unselectTable = new EventEmitter<any>();
  @Output() displayDocument = new EventEmitter<string>();
  @Output() newDocument = new EventEmitter<any>();
  @Output() toggleSidePanel = new EventEmitter<any>();

  @Output() changed = new EventEmitter<RSSWAResource | null>();

  isSa = false;
  showEditButton: boolean = false;
  windowScrolled: boolean = false;
  tmpSerialNo!: string;
  serialNo!: string;
  tmpStatus = '';
  amendment = false;
  viewAmendment = false;
  editAmendment = false;
  startNewClicked = false;
  currentDocument: any = null;
  maxHeight: string | null = null;
  contentWidth: any;
  prevScrollpos = window.pageYOffset;

  tmpWfTableId: number | null = null;
  amendmentSignature: WfSignatureResource = new WfSignatureResource();
  wfTaskSignature: WFTaskSignatureResource = new WFTaskSignatureResource();

  IsCopyTaskVisible = false;
  // Clock timer real-time
  subEditing!: Subscription;

  constructor(
    public rsswaService: RsswaService,
    public rolePrivilegeService: TokenInfoService,
    public router: Router,
    private wfs: WorkFlowService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.responsiveFunction();
  }


  responsiveFunction() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.maxHeight = (this.innerHeight - 320) + 'px';
  }

  changedEvent(e: RSSWAResource | null) {
    this.rsswa = e;
    this.changed.emit(this.rsswa);
  }

  setLoading(val: boolean) {
    this.loading.emit(val);
  }

}
