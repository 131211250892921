<div class="row mt-4">
  <div class="col-12">
    <h5>Functional Tests</h5>
  </div>
</div>
<div class="row">
  <div class="col col-xl-3">
    <div class="d-flex justify-content-between">
      <mat-checkbox
        [ngClass]="{
          hilite:
            rsswa &&
            service.showAmendment &&
            rsswa.functionalTest != chkFuncTestCtrl.value
        }"
        matTooltip="You will need to add at least one rss functional test"
        [formControl]="chkFuncTestCtrl"
        (change)="confirmRSSFunctionalTest($event)"
        labelPosition="before"
      >
        Enable Functional Tests
      </mat-checkbox>

      <button
        mat-flat-button
        color="primary"
        *ngIf="
          !disabled &&
          chkFuncTestCtrl.value &&
          (rsswa?.id ?? 0) > 0 &&
          (rsswa?.status == 1 || rsswa?.status == 6)
        "
        [disabled]="!rssFunctionalTestCreatePrivilege || disabled"
        (click)="createRSSFunctionalTest()"
      >
        Create
      </button>
    </div>
    <span
      *ngIf="rsswa?.status == formStatusEnum.New && chkFuncTestCtrl.value"
      class="yellow small"
      >Form needs to be saved to allow <b>Functional Tests</b> to be created!
      Please click on <b>Save as Draft</b> button first.
    </span>
  </div>
  <div class="col col-xl-9" *ngIf="chkFuncTestCtrl.value">
    <rss-functional-test-chips
      [placeholder]="'RSS Functional Tests'"
      [disabled]="
        disabled ||
        !(rsswa?.status == 1 || rsswa?.status == 6) ||
        !chkFuncTestCtrl.value
      "
      [rsswa]="rsswa"
      [rssFunctionalTests]="rssFunctionalTests"
      [showAmendment]="service.showAmendment"
      (error)="showAlert($event)"
      [control]="functionalTestCtrl"
      (edit)="edit($event)"
      (remove)="remove($event)"
    >
    </rss-functional-test-chips>

    <div class="print-container">
      <comments-box
        title="Testing Required Before Operation (Full Test, Functional
                  Test)"
        [disabled]="disabled"
        formType="RSSWA"
        [serialNo]="rsswa?.serialNo"
        [control]="provisionalTestingRequiredCtrl"
        [textValue]="provisionalTestingRequiredCtrl.value"
        (textChanged)="provisionalTestingRequiredChanged($event)"
        [hilite]="
          rsswa &&
          service.showAmendment &&
          rsswa.provisionalTestingRequired !=
            rsswa.rsswaAmendment?.provisionalTestingRequired
        "
      ></comments-box>
    </div>
  </div>
</div>
