<div class="d-print-flex">
  <form [formGroup]="formGroup">
    <mat-form-field
      [ngClass]="{
        hilite: hilite
      }"
    >
      <mat-label>{{ placeholder }}</mat-label>
      <mat-chip-grid
        #rsswaPerformingWorkUserChips
        formControlName="chipInputCtrl"
      >
        <mat-chip-row
          *ngFor="
            let rsswaPerformingWorkUser of selectedRsswaPerformingWorkUsers
          "
          [selectable]="true"
          [removable]="true"
          (removed)="remove(rsswaPerformingWorkUser)"
        >
          {{
            rsswaPerformingWorkUser == null
              ? ""
              : rsswaPerformingWorkUser.user?.name
          }}
          <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
        </mat-chip-row>
        <input
          #userInput
          [placeholder]="placeholder"
          [matAutocomplete]="userAutocomplete"
          [matChipInputFor]="rsswaPerformingWorkUserChips"
          [matChipInputAddOnBlur]="false"
          (keyup)="onUserInputKeyup($event)"
        />
      </mat-chip-grid>
      <mat-autocomplete
        #userAutocomplete="matAutocomplete"
        (optionSelected)="select($event)"
      >
        <mat-option *ngFor="let user of filteredUsers" [value]="user">
          {{ user == null ? "" : user.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="chipInputCtrl?.invalid">{{ errorMessage }}</mat-error>
    </mat-form-field>
  </form>
</div>
