<div class="d-print-flex">
  <mat-form-field [ngClass]="{ 'border-yellow': isModified }">
    <mat-label>{{ placeholder }}</mat-label>
    <mat-chip-grid #rssTestShutterChips [disabled]="disabled">
      <mat-chip-row
        *ngFor="let rssTestShutter of selectedRssTestShutters"
        [selectable]="true"
        [removable]="true"
        (removed)="remove(rssTestShutter)"
      >
        {{ rssTestShutter.Shutter == null ? "" : rssTestShutter.Shutter.name }}
        <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
      </mat-chip-row>
      <input
        #shutterInput
        [formControl]="control"
        [matAutocomplete]="shutterAutocomplete"
        [matChipInputFor]="rssTestShutterChips"
        [matChipInputAddOnBlur]="false"
        (keyup)="onShutterInputKeyup($event)"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #shutterAutocomplete="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option *ngFor="let resource of filteredResources" [value]="resource">
        {{ resource == null ? "" : resource.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
