import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ProcedureTraining } from "../models/procedure-training.model";

@Injectable({
  providedIn: 'root'
})
export class ProcedureTrainingService {
  private url: string = environment.urls.baseApiUrl + "/ProcedureTraining";

  constructor(
    private http: HttpClient,
  ) { }

  create(procedureTraining: ProcedureTraining): Observable<ProcedureTraining> {
    return this.http.post<ProcedureTraining>(this.url, procedureTraining);
  }

  createFromTemplate(roleID: number, templateID: number): Observable<any> {
    return this.http.post<any>(this.url + '/FromTemplate?roleID=' + roleID.toString() + '&templateID=' + templateID.toString(), null);
  }

  read(): Observable<ProcedureTraining[]> {
    return this.http.get<ProcedureTraining[]>(this.url);
  }

  update(id: number, procedureTraining: ProcedureTraining): Observable<ProcedureTraining> {
    return this.http.put<ProcedureTraining>(this.url + "/" + id.toString(), procedureTraining);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(this.url + '/' + id.toString());
  }
}
