import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRoleNote } from 'src/app/services/user-roles/user-role-note';
// import { UserRoleNote } from 'src/app/_interface/UserRoleNote';

@Component({
  selector: 'app-add-user-role-note-component',
  templateUrl: './add-user-role-note.component.html',
  styleUrls: ['./add-user-role-note.component.scss']
})
export class AddUserRoleNoteComponent implements OnInit {

  formNote!: FormGroup;
  public get noteDescription(): AbstractControl | null { return this.formNote.get('description'); }
  public get noteId(): AbstractControl | null { return this.formNote.get('id'); }

  constructor(public formBuilder: FormBuilder, public dialogRef: MatDialogRef<AddUserRoleNoteComponent>, @Inject(MAT_DIALOG_DATA) public note: UserRoleNote) { }

  ngOnInit(): void {
    this.formNote = this.formBuilder.group({
      description: [null, Validators.required],
      userId: [],
      id: [0]
    });
    this.initForm();
  }

  initForm() {
    if (this.note && this.noteDescription && this.noteId) {
      this.noteDescription.setValue(this.note.description)
      this.noteId.setValue(this.note.id)
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    let desc = this.formNote.controls['description'].value as string;
    desc = desc.trim();
    if (desc === '' || desc == null) {
      this.formNote.controls['description'].setErrors({ required: true });
      return;
    }
    this.formNote.value.createdOn = new Date();
    let userRoleNote = this.formNote.value as UserRoleNote;
    this.dialogRef.close(userRoleNote);
  }

}
