<div class="card full-height">
  <div class="card-header">
    <span class="card-title">Settings</span>
    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="card-body pt-0 rad-mon-card">
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Privileges"
        ><rad-monitor-add-edit-privilege></rad-monitor-add-edit-privilege
      ></mat-tab>
      <mat-tab label="Location Types"
        ><rad-monitor-add-edit-rad-location-type></rad-monitor-add-edit-rad-location-type
      ></mat-tab>
      <mat-tab label="Interlock"
        ><rad-monitor-add-edit-rad-interlock></rad-monitor-add-edit-rad-interlock
      ></mat-tab>
    </mat-tab-group>
  </div>
</div>
