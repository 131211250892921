<div class="card">
  <div class="card-header">
    <span class="card-title">{{ title }}</span>
  </div>

  <div class="card-body">
    <mat-form-field appearance="outline">
      <mat-label>Status Type</mat-label>
      <input
        type="text"
        placeholder="Select Type"
        matInput
        name="statusNameCtrl"
        [formControl]="statusNameCtrl"
        [matAutocomplete]="autoStatus"
        (keyup)="changedStatus($event)"
        #triggerType="matAutocompleteTrigger"
      />
      <mat-autocomplete
        #autoStatus="matAutocomplete"
        #typeAutocomplete
        [displayWith]="displayObject"
        (optionSelected)="selectedStatus($event)"
      >
        <mat-option *ngFor="let type of statusesFiltered" [value]="type">
          {{ type.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-hint
        class="error"
        *ngIf="statusesFiltered.length == 0 || !formStatus"
        >Select a valid Status</mat-hint
      >
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input
        type="text"
        placeholder="Name"
        matInput
        name="statusDescriptionCtrl"
        [formControl]="statusDescriptionCtrl"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Acronym</mat-label>
      <input
        type="text"
        placeholder="Description"
        matInput
        name="statusCodeCtrl"
        [formControl]="statusCodeCtrl"
        class="input-uppercase"
      />
    </mat-form-field>
    <color-picker
      [color]="status.cssClass"
      (selected)="selectedColor($event)"
    ></color-picker>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        color="warn"
        (click)="cancel()"
        [disabled]="disabled"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        [ngClass]="{ 'btn-green': !disabled, 'btn-gray': disabled }"
        [disabled]="!(form.dirty && formValid()) || disabled"
        (click)="save()"
      >
        <mat-icon>save</mat-icon>&nbsp;Save
      </button>
    </div>
  </div>
</div>
