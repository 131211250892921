<div class="card">
  <div class="card-header">
    <span class="card-title">Subscribe Users</span>
  </div>
  <div class="card-body">
    <div class="mat-container">
      <mat-table [dataSource]="usersTable" matSort>
        <ng-container matColumnDef="choose">
          <mat-header-cell *matHeaderCellDef> Check </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox
              [checked]="element.isCheck"
              (change)="checking($event.checked, element)"
            ></mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="username">
          <mat-header-cell *matHeaderCellDef> User Name </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.username }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="emailAddress">
          <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.emailAddress }}
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
      <button mat-flat-button color="primary" (click)="onSubmit()">
        Accept
      </button>
    </div>
  </div>
</div>
