import { ResourceType } from '../../../catalogs/beamline-catalog/resource/resources';
import { Component, OnInit, OnDestroy, EventEmitter, Output, ViewChild, ElementRef, Input, Injector } from '@angular/core';
import { ReviewBoardType } from '../../reviews.models';
import { ReviewsService } from '../../reviews.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'reviews-settings-board-types',
  templateUrl: './reviews-settings-board-types.component.html',
  styleUrls: ['./reviews-settings-board-types.component.scss']
})
export class ReviewsSettingsBoardTypesComponent extends BaseComponent implements OnInit, OnDestroy {

  reviewBoardTypes!: ReviewBoardType[];
  reviewBoardTypes$!: Observable<ReviewBoardType[]>;
  reviewBoardTypesSubs!: Subscription;
  reviewBoardTypesFiltered!: ReviewBoardType[];

  resourceTypes!: ResourceType[];
  resourceTypes$!: Observable<ResourceType[]>;
  resourceTypesSubs!: Subscription;
  resourceTypesFiltered!: ResourceType[];
  resouceType!: ResourceType;

  boardTypeCtrl = new FormControl();
  boardTypeNameCtrl = new FormControl();
  boardTypeDescriptionCtrl = new FormControl();
  boardTypeCodeCtrl = new FormControl();
  resourceTypeCtrl = new FormControl();
  crToggleCtrl = new FormControl();

  crToggle?: boolean;
  disabled!: boolean;
  isModifying = false;

  @Input() reviewBoardType?: ReviewBoardType | null;
  @Output() reviewBoardTypeSelected = new EventEmitter<ReviewBoardType | null>();

  @ViewChild('boardTypeNameCtrlElement')
  boardTypeNameCtrlElement!: ElementRef;

  constructor(
    protected override injector: Injector,
    private service: ReviewsService,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.reviewBoardTypesSubs?.unsubscribe();
    this.resourceTypesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.buildForm();
    this.reviewBoardTypes$ = this.store.select(state => state.ReviewBoardTypes.data);
    this.reviewBoardTypesSubs = this.reviewBoardTypes$.subscribe(data => {
      this.reviewBoardTypes = this.utils.cloneDeep(data);
      if (!this.reviewBoardTypes.map(x => x.id).includes(0)) {
        this.reviewBoardTypes.push({ name: '[Add New]', id: 0 } as ReviewBoardType);
      }
      this.reviewBoardTypesFiltered = this.reviewBoardTypes;
      if (this.reviewBoardType) {
        this.reviewBoardType = this.reviewBoardTypes.find(x => x.id == this.reviewBoardType?.id);
        this.boardTypeCtrl.setValue(this.reviewBoardType);
        this.crToggle = this.reviewBoardType?.allowCustomResources;
        this.crToggleCtrl.disable();
        this.showDetails(this.reviewBoardType);
        this.reviewBoardTypeSelected.emit(this.reviewBoardType);
      }
    });
    this.resourceTypes$ = this.store.select(state => state.ResourceTypes.data);
    this.resourceTypesSubs = this.resourceTypes$.subscribe(data => {
      this.resourceTypes = data;
    });
  }

  buildForm() {
    this.formGroup = new FormGroup({
      boardTypeNameCtrl: this.boardTypeNameCtrl,
      boardTypeDescriptionCtrl: this.boardTypeDescriptionCtrl,
      boardTypeCodeCtrl: this.boardTypeCodeCtrl,
      resourceTypeCtrl: this.resourceTypeCtrl,
      crToggleCtrl: this.crToggleCtrl
    });
    this.formGroup.reset();
    this.formGroup.disable();
  }

  changedBoardType(e: any) {
    this.reviewBoardTypesFiltered = this.reviewBoardTypes?.filter(x => x.name?.toLowerCase().includes(e.target.value.toLowerCase()));
  }

  getFormattedResourceTypes(): string {
    const text = this.resourceTypes.filter(x => x.id == this.resourceTypeEnum.Beamline || x.id == this.resourceTypeEnum.Branchline).map(resource => resource.name).slice(0, 2).join(', ');
    return text;
  }

  selectedBoardType(e: any) {
    this.reviewBoardType = e.option.value;
    this.boardTypeNameCtrl.setValue(this.reviewBoardType?.id ? this.reviewBoardType?.name : null);

    if (this.reviewBoardType?.id) {
      this.showDetails(this.reviewBoardType);
      this.reviewBoardTypeSelected.emit(this.reviewBoardType);
    }
    else {
      this.reviewBoardTypeSelected.emit(this.reviewBoardType);
      this.formGroup.enable();
      this.boardTypeCtrl.disable();
      this.boardTypeCtrl.setValue(null);
      this.boardTypeNameCtrlElement.nativeElement.focus();
      this.showDetails(null);
    }
  }

  showDetails(reviewBoardType?: ReviewBoardType | null) {
    this.boardTypeNameCtrl.setValue(reviewBoardType?.name);
    this.boardTypeDescriptionCtrl.setValue(reviewBoardType?.description);
    this.boardTypeCodeCtrl.setValue(reviewBoardType?.code);
    this.resourceTypeCtrl.setValue(reviewBoardType?.resourceType);
    this.reviewBoardType = reviewBoardType;
  }

  changedResourceType(e: any) {
    this.resourceTypesFiltered = this.resourceTypes?.filter(x => x.name?.toLowerCase().includes(e.target.value.toLowerCase()));
  }

  modify() {
    this.isModifying = true;
    this.formGroup.enable();
    this.boardTypeCtrl.disable();
  }

  cancel() {
    this.isModifying = false;
    this.reviewBoardType = null;
    this.buildForm();
    this.boardTypeCtrl.enable();
    this.boardTypeCtrl.setValue(null);
    this.reviewBoardTypeSelected.emit(null);
  }

  formValid() {
    return !this.utils.isNullValue(this.boardTypeNameCtrl.value) && !this.utils.isNullValue(this.boardTypeDescriptionCtrl.value) && !this.utils.isNullValue(this.boardTypeCodeCtrl.value) && !this.utils.isNullValue(this.resourceTypeCtrl.value?.id);
  }

  save() {
    if (this.reviewBoardType) {
      this.disabled = true;
      this.reviewBoardType.name = this.boardTypeNameCtrl.value;
      this.reviewBoardType.description = this.boardTypeDescriptionCtrl.value;
      this.reviewBoardType.code = this.boardTypeCodeCtrl.value;
      this.reviewBoardType.resourceTypeID = this.resourceTypeCtrl.value.id;
      this.reviewBoardType.allowCustomResources = this.crToggle ?? false;
      if (!this.reviewBoardType.id) {
        this.service.createReviewBoardType(this.reviewBoardType).toPromise().then(data => {
          this.reviewBoardType = data;
          this.alert.message('ReviewBoardTypeCreated');
          this.disabled = false;
          this.formGroup.disable();
          this.boardTypeCtrl.enable();
        });
      }
      else {
        this.service.updateReviewBoardType(this.reviewBoardType).toPromise().then(data => {
          this.reviewBoardType = data;
          this.alert.message('ReviewBoardTypeUpdated');
          this.disabled = false;
          this.isModifying = false;
          this.formGroup.disable();
          this.boardTypeCtrl.enable();
        });
      }
    }
  }

  delete() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('ReviewBoardTypeDeleteConfirmation').description,
        icon: 'warn'
      }
    });
    confirm.afterClosed().toPromise().then(data => {
      if (data && this.reviewBoardType?.id) {
        this.service.deleteReviewBoardType(this.reviewBoardType.id).toPromise().then(() => {
          this.reviewBoardType = null;
          this.alert.message('ReviewBoardTypeDeleted');
          this.disabled = false;
          this.formGroup.disable();
          this.boardTypeCtrl.enable();
          this.boardTypeCtrl.setValue(null);
          this.buildForm();
        });
      }
    });
  }

  crToggleChanged(e: any) {
    if (this.reviewBoardType)
      this.reviewBoardType.allowCustomResources = e.value;
    this.formGroup.markAsDirty();
  }
}
