<div class="close-container">
  <mat-icon class="close" color="warn" (click)="close()">cancel</mat-icon>
</div>
<div class="small">
  <p>
    This component returns <b>{{ returnType }}</b> value.
  </p>
  <p>You can use <b>Tag</b> as follows in a <b>Conditions</b> field:</p>

  <span>
    <table>
      <tr>
        <th>Syntax</th>
        <th>Operator</th>
        <th>Explanation</th>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Boolean">
        <td>{{ data.tag }}</td>
        <td>(None)</td>
        <td>Check if component value is Yes or True</td>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Boolean">
        <td><b>!</b>{{ data.tag }}</td>
        <td>! (Inverse)</td>
        <td>Check if component component value is No or False</td>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Sign">
        <td>{{ data.tag }}</td>
        <td>(None)</td>
        <td>Check if component has been Signed or Checked</td>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Sign">
        <td><b>!</b>{{ data.tag }}</td>
        <td>! (Inverse)</td>
        <td>Check if component has not been Signed or Checked</td>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Sign">
        <td>{{ data.tag }} <b>&&</b> [Other Tag]</td>
        <td>&& (And)</td>
        <td>Check if two components have been Signed or Checked</td>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Sign">
        <td>{{ data.tag }} <b>||</b> [Other Tag]</td>
        <td>|| (Or)</td>
        <td>Check if any of two components have been Signed or Checked</td>
      </tr>
      <tr
        *ngIf="data.component.returnType == returnTypes.Numeric && data.multi"
      >
        <td>{{ data.tag }} <b>==</b> [Number]</td>
        <td>== (Equal)</td>
        <td>Check if selected values includes a specific number</td>
      </tr>
      <tr
        *ngIf="data.component.returnType == returnTypes.Numeric && data.multi"
      >
        <td>{{ data.tag }} <b>!=</b> [Number]</td>
        <td>!= (Not Equal)</td>
        <td>Check if selected values not include a specfic number</td>
      </tr>
      <tr
        *ngIf="data.component.returnType == returnTypes.Numeric && !data.multi"
      >
        <td>{{ data.tag }} <b>==</b> [Number]</td>
        <td>== (Equal)</td>
        <td>Check if selected value is a specific number</td>
      </tr>
      <tr
        *ngIf="data.component.returnType == returnTypes.Numeric && !data.multi"
      >
        <td>{{ data.tag }} <b>!=</b> [Number]</td>
        <td>!= (Not Equal)</td>
        <td>Check if selected value is not a specfic number</td>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Text">
        <td>{{ data.tag }} <b>==</b> '[String]'</td>
        <td>== (Equal)</td>
        <td>Check if any of entered text values matches a String</td>
      </tr>
      <tr *ngIf="data.component.returnType == returnTypes.Text">
        <td>{{ data.tag }} <b>!=</b> '[String]'</td>
        <td>!= (Not Equal)</td>
        <td>Check if none of entered text values matches a String</td>
      </tr>
    </table>
  </span>
</div>
