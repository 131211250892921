<div class="container-fluid mt-4">
  <div class="row">
    <div class="col">
      <h5 id="Section5Title">Close-out & Return to Service</h5>
    </div>
  </div>

  <div class="flex-display justify-content-center flex-wrap">
    <div
      class="px-2 col-6"
      [ngClass]="{
        'col-20': signatures.length == 5,
        'col-3': signatures.length == 4,
        'col-4': signatures.length == 3
      }"
      *ngFor="let s of signatures"
    >
      <button-signature
        #btnSign
        [roleId]="s.roles"
        [roleCodes]="s.roleCodes"
        [user]="s.approvedBy"
        [title]="s.name"
        [disabled]="s.disabled"
        [value]="s.approved"
        [showApprove]="s.approve"
        [showUnapprove]="s.unapprove"
        [showDisapprove]="s.disapprove"
        [date]="s.approvedOn"
        [showCancel]="s.cancel"
        [cancelID]="s.cancelID"
        [taskID]="s.taskId"
        [enabled]="s.enabled"
        (approve)="approve(s.taskId, s.approveId)"
        (unapprove)="unapprove(s.taskId, s.unapproveId)"
        (disapprove)="disapprove(s.taskId, s.disapproveId, $event)"
        (onError)="alert.error($event)"
      ></button-signature>
    </div>
  </div>
</div>
