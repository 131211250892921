import { Injectable } from '@angular/core';
import { Action, State, Selector, StateContext } from '@ngxs/store';
// import { utils } from 'src/app/modules/Libs/utils';
// import { RadiationAreaService } from 'src/app/Services/RadiationArea/radiation-area-service.service';
// import { RadiationAreaItem } from 'src/app/_interface/RadiationAreaItem';
import { RadiationAreaRefresh, RadiationAreaCreate, RadiationAreaUpdate, RadiationAreaDelete } from './radiation-area.action';
import { utils } from 'src/app/modules/libs/utils';
import { RadiationAreaItem } from '../radiation-area-item';
import { RadiationAreaService } from '../radiation-area-service.service';

export class RadiationAreaStateModel {
  radiationAreaItems!: RadiationAreaItem[];
}

@State<RadiationAreaStateModel>({
  name: 'RadiationArea',
  defaults: {
    radiationAreaItems: []
  }
})
@Injectable()
export class RadiationAreaState {
  constructor(
    private radiationAreaService: RadiationAreaService
  ) { }

  @Selector()
  static read(state: RadiationAreaStateModel) {
    return state.radiationAreaItems;
  }

  @Action(RadiationAreaRefresh)
  refresh({ patchState }: StateContext<RadiationAreaStateModel>) {
    this.radiationAreaService.getAll().toPromise().then(data => {
      patchState({ radiationAreaItems: utils.cloneDeep(data) });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(RadiationAreaCreate)
  create({ getState, patchState }: StateContext<RadiationAreaStateModel>, { item }: RadiationAreaCreate) {
    patchState({
      radiationAreaItems: [...getState().radiationAreaItems, item]
    });
  }

  @Action(RadiationAreaUpdate)
  update({ getState, patchState }: StateContext<RadiationAreaStateModel>, { id, item }: RadiationAreaUpdate) {
    const state = getState();
    const index = state.radiationAreaItems.findIndex(x => x.id === id);
    const currentState = utils.cloneDeep(state.radiationAreaItems);

    if (index >= 0) {
      currentState.splice(index, 1, utils.cloneDeep(item));
      patchState({ radiationAreaItems: currentState });
    }
  }

  @Action(RadiationAreaDelete)
  delete({ getState, patchState }: StateContext<RadiationAreaStateModel>, { id }: RadiationAreaDelete) {
    patchState({
      radiationAreaItems: getState().radiationAreaItems.filter(x => x.id !== id)
    });
  }
}
