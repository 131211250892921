import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'textarea-dialog',
  templateUrl: './textarea-dialog.component.html',
  styleUrls: ['./textarea-dialog.component.scss']
})
/* textarea-dialog component*/
export class TextareaDialogComponent implements OnInit {
  public label?: string;
  public acceptDisabled = true;
  public placeholder?: string;

  constructor(
    public dialog: MatDialogRef<TextareaDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
  }
  ngOnInit() {
    this.label = this.data.label;
    if ('placeholder' in this.data) {
      this.placeholder = this.data.placeholder;
    }
  }
  cancel() {
    this.data.acceptClicked = false;
    this.data.cancelClicked = true;
    this.dialog.close(this.data);
  }
  accept() {
    this.data.acceptClicked = true;
    this.data.cancelClicked = false;
    this.dialog.close(this.data);
  }
  textChange(text: string) {
    this.acceptDisabled = text.trim() === '';
  }
}
