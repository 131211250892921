import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Catalog } from 'src/app/common/enumerations/enumerations';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { Restriction } from 'src/app/controls/restrictions/services/restriction';
import { CatalogService } from '../../../catalog-service';
import { RestrictionComponent } from '../../dialogs/restriction/restriction.component';
import { utils } from 'src/app/modules/libs/utils';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { BaseComponent } from 'src/app/common/base/base.component';


@Component({
  selector: 'app-restriction-table',
  templateUrl: './restriction-table.component.html',
  styleUrls: ['./restriction-table.component.scss']
})
export class RestrictionTableComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() isEditionChild!: boolean;
  @Input() restrictionsChild: any;
  @Input() branchlinesChild: any;
  @Input() shutterChild?: Resource;
  @Input() hilite?: boolean;

  @Output() emitData = new EventEmitter<Restriction[]>();

  public displayedColumnsRestrictions: string[] = ['restriction', 'scf', 'rsswa', 'ebf', 'ppstb', 'appliesTo'];

  public dataSourceBranchline!: MatTableDataSource<Resource>;
  public dataSourceRestriction!: MatTableDataSource<Restriction>;
  public dataSourceAcc!: MatTableDataSource<Resource>;

  public shutter!: Resource;

  public isEdition!: boolean;
  public adding!: boolean;

  public restrictions: any;
  public branchlines: any;

  constructor(
    protected override injector: Injector,
    private _catalogService: CatalogService,
    private router: Router
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.clear();

    if (changes['isEditionChild'] != null) this.isEdition = changes['isEditionChild'].currentValue;
    if (changes['shutterChild'] != null) this.shutter = changes['shutterChild']?.currentValue;
    if (changes['branchlinesChild'] != null) this.branchlines = changes['branchlinesChild']?.currentValue;
    if (changes['restrictionsChild'] != null) this.restrictions = changes['restrictionsChild']?.currentValue;

    if (this.branchlines != null)
      this.dataSourceBranchline.data = this.branchlines?.list;
    if (this.restrictions != null)
      this.dataSourceRestriction.data = this.restrictions?.list;

    if (this.isEdition == true)
      this.create();
    else
      this.remove();
  }

  create() {
    this.displayedColumnsRestrictions = ['restriction', 'scf', 'rsswa', 'ebf', 'ppstb', 'appliesTo', 'add'];
    this.adding = true;
  }

  remove() {
    this.displayedColumnsRestrictions = ['restriction', 'scf', 'rsswa', 'ebf', 'ppstb', 'appliesTo'];
    this.adding = false;
  }

  async openAddRestrictionDialog() {
    if (this.router.url == "/resources-new") {
      await this.loadAccelerators();
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { restriction: new Restriction(), branchlines: this.dataSourceBranchline.data, shutter: this.shutter, accelerators: this.dataSourceAcc.data };
    const dialogRef = this.dialog.open(RestrictionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data: Restriction) => {
      if (data) {
        this.addRestriction(data);
        this.emitData.emit(this.dataSourceRestriction.data);
      }
    });
  }

  async openEditRestrictionDialog(restriction: Restriction) {
    if (this.router.url == "/resources-new") {
      await this.loadAccelerators();
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { restriction: restriction, branchlines: this.dataSourceBranchline.data, shutter: this.shutter, accelerators: this.dataSourceAcc.data };
    const dialogRef = this.dialog.open(RestrictionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data)
        this.emitData.emit(this.dataSourceRestriction.data);
    });
  }


  addRestriction(data: Restriction) {
    data.scf = true;
    data.ebf = true;
    data.rsswa = true;
    data.ppstb = true;
    this.dataSourceRestriction.data.push(data);
    this.dataSourceRestriction.filter = "";
  }

  deleteRestriction(data: Restriction) {
    this.dialogService.ConfirmationDialog('Remove', 'this <b>Restriction</b>').subscribe((result) => {
      if (result) {
        var index = this.dataSourceRestriction.data.indexOf(data);
        this.dataSourceRestriction.data.splice(index, 1);
        this.dataSourceRestriction.filter = "";
        this.emitData.emit(this.dataSourceRestriction.data);
      }
    });
  }

  async loadAccelerators() {
    await this._catalogService.GetJoinCreated(Catalog.AcceleratorCatalog, false).toPromise().then(data => {
      this.dataSourceAcc.data = data?.acceleratorCatalogs?.map(x => x.details.accelerator) as Resource[];
      this.dataSourceAcc.data.sort((a, b) => utils.sort(a.name, b.name));
    });
  }

  clear() {
    this.dataSourceRestriction = new MatTableDataSource();
    this.dataSourceBranchline = new MatTableDataSource();
    this.dataSourceAcc = new MatTableDataSource();

    this.remove();
  }

}
