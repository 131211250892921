import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WFSectionLocalResource, WfTaskLocalResource } from '../../../../services/work-flow/work-flow';
import { RadMonitor, RadMonitorSaveResource } from '../rad-monitor/rad-monitor';
import { ComponentType } from 'src/app/common/enumerations/enumerations';
import { PlaceholdersService } from 'src/app/services/placeholders/placeholders.service';
import { utils } from '../../../../modules/libs/utils';

@Injectable({
  providedIn: 'root'
})
export class RadMonitorService {

  public api: string = environment.urls.baseApiUrl + '/RadMonitor';

  public incomingMonitor: BehaviorSubject<RadMonitor | null>;
  public outgoingMonitor: BehaviorSubject<RadMonitor | null>;

  public incomingMonitorValue?: RadMonitor | null;
  public outgoingMonitorValue?: RadMonitor | null;

  public incomingSigned?: boolean;
  public outgoingSigned?: boolean;

  constructor(
    private http: HttpClient,
    private placeholdersService: PlaceholdersService
  ) {
    this.incomingMonitor = new BehaviorSubject<RadMonitor | null>(null);
    this.outgoingMonitor = new BehaviorSubject<RadMonitor | null>(null);
  }

  GetRadMonitors(): Observable<RadMonitor[]> {
    return this.http.get<RadMonitor[]>(this.api);
  }

  GetRadMonitorById(id: number): Observable<RadMonitor> {
    return this.http.get<RadMonitor>(this.api + '/' + id);
  }

  CreateRadMonitor(radMonitor: RadMonitor): Observable<RadMonitor> {
    return this.http.post<RadMonitor>(this.api, radMonitor);
  }

  UpdateRadMonitor(id: number, radMonitor: RadMonitor): Observable<RadMonitor> {
    return this.http.put<RadMonitor>(this.api + '/' + id, radMonitor);
  }

  SwapRadMonitors(inId: number, outgoingMonitor: RadMonitorSaveResource): Observable<RadMonitor> {
    return this.http.put<RadMonitor>(this.api + '/Swap/' + inId, outgoingMonitor);
  }

  DeleteRadMonitor(id: number) {
    return this.http.delete(this.api + '/' + id);
  }

  findRadMonitor(sections: WFSectionLocalResource[], type: string) {
    let task: WfTaskLocalResource = new WfTaskLocalResource();
    sections.map(x => {
      x.wfStepLocals?.map(s => {
        s.wfTaskLocals.map(t => {
          if (type == 'IN' && t.componentID == ComponentType.RadMonitorIncoming) {
            task = t;
          }
          if (type == 'OUT' && t.componentID == ComponentType.RadMonitorOutgoing) {
            task = t;
          }
        });
      });
    });
    if (task) {
      const text = task.text as string;
      const storedValues = utils.JSONparse(text);
      let values: any[] = [];
      if (storedValues)
        values = utils.JSONparse(storedValues[0].stringValue);
      return values;
    }
    return null;
  }

  getIncomingMonitor(): Observable<RadMonitor | null> {
    return this.incomingMonitor.asObservable();
  }

  setIncomingMonitor(value: RadMonitor) {
    this.incomingMonitorValue = value;
    this.incomingMonitor.next(value);
  }

  getOutgoingMonitor(): Observable<RadMonitor | null> {
    return this.outgoingMonitor.asObservable();
  }

  setOutgoingMonitor(value: RadMonitor) {
    this.outgoingMonitorValue = value;
    this.outgoingMonitor.next(value);
  }

  setKeyValuePairs(sections: WFSectionLocalResource[]) {
    const values = this.findRadMonitor(sections, 'OUT');
    const monitor = values?.find((r: any) => r.key == 'monitor')?.val as RadMonitor;
    this.placeholdersService.setKeyValuePairs(monitor).then(() => {
      return;
    });
  }
}
