<div class="card">
  <div class="card-header">
    <span class="card-title">{{ data.id ? "Edit" : "Create" }} Reminder</span>
  </div>
  <div class="card-body">
    <form [formGroup]="reminderForm">
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" placeholder="Name" />
            <mat-error *ngIf="reminderForm">Name is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Days</mat-label>
            <input
              matInput
              type="number"
              formControlName="timeInDays"
              placeholder="Days before due"
            />
            <mat-error *ngIf="reminderForm">Due date is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Period</mat-label>
            <mat-select formControlName="period">
              <mat-option [value]="ReminderPeriodEnum.after">
                {{ Period[ReminderPeriodEnum.after - 1] }}
              </mat-option>
              <mat-option [value]="ReminderPeriodEnum.before">
                {{ Period[ReminderPeriodEnum.before - 1] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p>
            The following placeholders are available to use in your message:
          </p>
          <ul>
            <li><b>[Location]</b></li>
            <li><b>[LastCompleted]</b></li>
            <li><b>[NextInspectionDue]</b></li>
            <li><b>[ScheduledFor]</b></li>
            <li><b>[ExtendedDate]</b></li>
            <li><b>[Status]</b></li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <comments-box
            [textValue]="data.message"
            (textChanged)="messageChanged($event)"
            title="Message"
            formType="ScheduleReminder"
            [serialNo]="'Schedule'"
          ></comments-box>
          <!-- <mat-form-field appearance="outline">
						<mat-label>Message</mat-label>
						<textarea matInput formControlName="message"></textarea>
					</mat-form-field> -->
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-slide-toggle formControlName="beamlineStaffNotification"
            >Beamline staff notification</mat-slide-toggle
          >
        </div>
      </div>
      <!-- <div class="row">
            <div class="col-12">
                <button mat-flat-button type="submit" [disabled]="!reminderForm.valid">Submit</button>
            </div>
        </div> -->
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="!reminderForm.valid"
      >
        Accept
      </button>
    </div>
  </div>
</div>
