import { AfterViewInit, Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ChecklistTemplate } from '../../../checklists';
import { ChecklistBuilderService } from '../../checklist-builder.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { WFSectionLocalResource, WFStepLocalResource } from 'src/app/services/work-flow/work-flow';
import { utils } from 'src/app/modules/libs/utils';
import { ChecklistBuilderStepsComponent } from '../../checklist-builder-steps/checklist-builder-steps.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { distinctUntilChanged, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'checklist-builder-section',
  templateUrl: './checklist-builder-section.component.html',
  styleUrls: ['./checklist-builder-section.component.scss']
})
export class ChecklistBuilderSectionComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() section!: WFSectionLocalResource;
  @Input() currentVersion!: ChecklistTemplate;
  @Input() disabled!: boolean;
  @Input() sectionDropLists!: string[];
  @Input() stepDropLists!: string[];
  @Input() showDeleted?: boolean;

  @Output() deleted = new EventEmitter();
  @Output() loading = new EventEmitter();
  sectionLoading = true;

  @Output() refreshPreviousStep = new EventEmitter<number>();

  @ViewChild(ChecklistBuilderStepsComponent) stepsComponent!: ChecklistBuilderStepsComponent;
  editing!: boolean;

  configuration: Configuration = new Configuration();
  wfStepLocals?: WFStepLocalResource[];

  showDeletedSteps?: boolean;
  deletedStepLocals?: WFStepLocalResource[];
  deletedStepLocals$!: Observable<WFStepLocalResource[]>;
  deletedStepLocalsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private _builder: ChecklistBuilderService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.sectionLoading = true;
    this.disabled = this.disabled || (this.section.logID ?? 0) > 0;
    this.configuration = utils.JSONparse(this.section.configuration);
    if (!this.configuration) { this.configuration = new Configuration(); }
    this.wfStepLocals = this.section.wfStepLocals;
    this.loadDeletedSteps();
  }

  ngAfterViewInit(): void {
    this.sectionLoading = false;
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  loadDeletedSteps() {
    this.deletedStepLocals$ = this.store.select((state) => state.DeletedWFStepLocals.data);
    this.deletedStepLocalsSubs = this.deletedStepLocals$.pipe(distinctUntilChanged()).subscribe((data) => {
      this.deletedStepLocals = data.filter((t) => t.wfSectionLocalID == this.section.id);
      if (this.showDeleted && !this.section.logID)
        this.wfStepLocals = this.wfStepLocals?.concat(this.deletedStepLocals).sort((a, b) => this.utils.sort(a.order, b.order));
    });
  }

  edit() {
    if (!this.disabled) {
      this.editing = true;
    }
  }

  save() {
    this.editing = false;
    this._builder.updateSection(this.section).subscribe(data => {
      this.section = data;
    },
      error => {
        console.log(error);
      })
  }

  delete() {
    if (this.section.wfStepLocals?.length != 0) {
      const yesno = this.dialog.open(YesNoDialogComponent, {
        width: "400px",
        data: {
          message: this.getMessage("CB_Delete_Section_Confirmation")?.description,
          icon: "stop",
        },
      });
      yesno.afterClosed().subscribe((data: any) => {
        if (data) {
          this.loading.emit(true);
          this._builder.deleteSection(this.section).subscribe(data => {
            this.deleted.emit(this.section);
            this.loading.emit(false);
          });
        }
      });
    }
    else {
      this.loading.emit(true);
      this._builder.deleteSection(this.section).subscribe(data => {
        this.deleted.emit(this.section);
        this.loading.emit(true);
      });
    }
  }

  cancel() {
    this.editing = false;
  }

  setLoading(e: boolean) {
    this.loading.emit(e);
  }

  configurationChanged() {
    this.section.configuration = utils.JSONstringify(this.configuration);
    this.save();
  }

  scrollDeletedSteps() {
    setTimeout(() => {
      const element = document.getElementById('deleted-steps-' + this.section.id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }, 500);
  }

  duplicate() {
    const message =
      this.getMessage('ChecklistDuplicateSectionConfirmation').description;
    const yesno = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message,
        icon: 'question',
      },
    });
    yesno.afterClosed().subscribe(async (data) => {
      this.loading.emit(true);
      if (data) {
        await this._builder.duplicateSection(this.section.id).toPromise().then(data => {
          this.alert.info(
            'Section: <b>' +
            this.section.name +
            '</b> has been Duplicated!.'
          );
          this.loading.emit(false);
        });
      }
    });
  }

}

export class Configuration {
  constructor() {
    this.duplicate = false;
    this.roleIDs = [];
  }
  duplicate: boolean;
  roleIDs: number[];
}
