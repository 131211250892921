import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { LogToShowResource } from 'src/app/services/logs/log-to-show-resource';
import { SignResource } from 'src/app/services/logs/signs';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { WorkPerformer } from 'src/app/services/work-performer/work-performer';
import { WorkerVerification } from 'src/app/services/worker-verification/worker-verification';
import { RSSWAResource } from '../../rsswa';
import { RSSWAAcceleratorLocation } from '../../rsswa-accelerator-location';
import { RSSWAShutter } from '../../rsswa-shutter';
import { utils } from 'src/app/modules/libs/utils';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Observable, Subscription } from 'rxjs';
import { RelatedDocument } from 'src/app/services/documents/documents';

@Component({
  selector: 'rsswa-log',
  templateUrl: './rsswa-log.component.html',
  styleUrls: ['./rsswa-log.component.scss']
})
export class RsswaLogComponent extends BaseComponent implements OnChanges {
  @Input() rsswa?: RSSWAResource | null;

  workerVerifications?: WorkerVerification[];
  workerVerifications$!: Observable<WorkerVerification[]>;
  workerVerificationsSubs!: Subscription;

  generalLogs: LogToShowResource = {};
  show = false;
  signLogs?: SignResource[] = [];
  amendmentsLogs: SignResource[] = [];
  loading = false;
  hasOriginal = false;
  originalBody!: RSSWAResource;
  amendmentsList: number[] = [];
  // MatPaginator Inputs
  length = 100;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 25];

  constructor(
    private wfService: WorkFlowService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadWorkerVerifications();
  }

  loadWorkerVerifications() {
    this.workerVerifications$ = this.store.select(state => state.WorkerVerification.data);
    this.workerVerificationsSubs = this.workerVerifications$.subscribe(data => {
      this.workerVerifications = data.filter(x => x.form == this.rsswa?.type && x.formID == this.rsswa?.id);
      this.refresh();
    });
  }

  refresh() {
    if (this.rsswa && this.rsswa.status !== FormStatusEnum.Canceled) {
      this.loading = true;
      this.wfService.getLogsBySn(this.rsswa.serialNo).subscribe(async response => {
        this.generalLogs = response;
        this.show = false;
        if (this.generalLogs !== null && this.generalLogs !== undefined && this.generalLogs.type === 2) {
          this.show = true;
        }
        this.fillLogs();
        for (const wv of this.workerVerifications ?? []) {
          const WorkerVerificationSignature: SignResource = {
            name: 'Worker Verification',
            signedBy: wv.user?.name,
            signedOn: wv.date,
            amendmentId: 0,
          };
          this.generalLogs.signs?.push(WorkerVerificationSignature);
        }
        this.generalLogs.signs = this.generalLogs.signs?.sort((a, b) => utils.sort(a.signedOn, b.signedOn));
        this.signLogs = this.generalLogs.signs?.filter(s => s.amendmentId === 0);
      });
    } else {
      this.show = false;
    }
  }

  fillLogs() {
    if (this.generalLogs.originalBody) {
      this.generalLogs.originalBodyRSSWA = JSON.parse(this.generalLogs.originalBody) as RSSWAResource;
      this.generalLogs.originalBodyRSSWA.createdByName = this.getUser(this.generalLogs.originalBodyRSSWA.createdBy)?.name;
      this.generalLogs.originalBodyRSSWA.requesterName = this.getUser(this.generalLogs.originalBodyRSSWA.requesterId)?.name;
      this.originalBody = this.generalLogs.originalBodyRSSWA as RSSWAResource;
      this.hasOriginal = true;
    }
    this.fillUpdates();
  }

  fillUpdates() {
    if (this.generalLogs?.originalBodyRSSWA?.wfTableID) {
      // #REGION SET AMENDMENTSLIST
      let wfSignatures = this.rsswa?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 9)?.wfSignatures;
      wfSignatures = wfSignatures?.filter(s => s.taskID !== 0);
      this.amendmentsList = this.getAmendmentList(wfSignatures);
      // #END REGION SET AMENDMENTSLIST
      if (this.generalLogs.updates && this.generalLogs.updates.length > 0) {
        this.generalLogs.updates.map(update => {
          if (update.oldBody) {
            update.oldBodyRSSWA = JSON.parse(update.oldBody);
            if (update.oldBodyRSSWA) {
              update.oldBodyRSSWA.createdByName = this.getUser(update.oldBodyRSSWA.createdBy)?.name;
              update.oldBodyRSSWA.requesterName = this.getUser(update.oldBodyRSSWA.requesterId)?.name;
            }
          }
        });
      }
    }
    this.loading = false;
  }

  findIndex(amendmentId?: number): number {
    if (amendmentId !== null && amendmentId !== 0) {
      let fakeAmendmentId = this.amendmentsList.findIndex(amid => amid === amendmentId);
      if (fakeAmendmentId !== -1) {
        fakeAmendmentId += 1;
        return fakeAmendmentId;
      } else {
        return 0;
      }
    }
    return amendmentId;
  }

  getAmendmentList(wfSignatures?: any[]): number[] {
    let olderAmendmentId = -1;
    let res: number[] = [];
    for (const t of wfSignatures ?? []) {
      if (t.amendmentID !== olderAmendmentId) {
        olderAmendmentId = t.amendmentID;
        res.push(olderAmendmentId);
      }
    }
    res = [...new Set(res)].sort((a, b) => a - b);
    return res;
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  mapLocations(Locations: any[]) {
    return Locations.map(({ name }) => name).join(', ');
  }

  mapWorkPerformers(workPerformers?: WorkPerformer[]) {
    return workPerformers?.map(({ name }) => name).join(', ');
  }

  maprelatedDocument(RelatedDocuments?: RelatedDocument[]) {
    return RelatedDocuments?.map(({ relatedDocumentSerialNo }) => relatedDocumentSerialNo).join(', ');
  }

  mapSystemsAffected(originalBody?: RSSWAResource) {
    const TopOff = originalBody?.topOff;
    const RadiationMonitoringSystem = originalBody?.radiationMonitoringSystem;
    const Beamline = originalBody?.beamLine;
    const Accelerator = originalBody?.accelerator;
    let res = TopOff ? 'Top-Off, ' : '';
    if (RadiationMonitoringSystem) {
      res += 'Radiation Monitoring System, ';
    }
    if (Beamline) {
      res += 'Beamline, ';
    }
    if (Accelerator) {
      res += 'Accelerator, ';
    }
    const rsswaOtherSystems = originalBody?.rsswaOtherSystems?.map(({ text }) => text).join(', ') + ', ';
    res += rsswaOtherSystems;
    if (res.length > 3) {
      res = res.substr(0, res.length - 2);
    }
    return res;
  }

  mapRSSWAShutters(rsswaShutters?: RSSWAShutter[]): string {
    let res = '';
    if (rsswaShutters)
      for (const rsswaShutter of rsswaShutters) {
        res += (rsswaShutter.resource === null ? '' : rsswaShutter.resource?.name) + ', ';
      }
    if (res.length > 3) {
      res = res.substr(0, res.length - 2);
    }
    return res;
  }

  mapRSSWAAcceleratorLocations(Locations?: RSSWAAcceleratorLocation[]) {
    let res = '';
    if (Locations)
      for (const location of Locations) {
        res += (location.resource === null ? '' : location.resource?.name) + ', ';
      }
    if (res.length > 3) {
      res = res.substr(0, res.length - 2);
    }
    return res;
  }

  getRSSWALogHeadText(amendmentId?: number): string {
    const fakeAmendmentId = this.findIndex(amendmentId);
    const res = fakeAmendmentId === 1 ? 'Initial Values' : 'Amendment ' + (fakeAmendmentId !== 0 ? (fakeAmendmentId - 1).toString() : 'Cancelled');
    return res;
  }

  getRSSWALogFooterText(amendmentId?: number): string {
    const res = 'Amendment ' + this.findIndex(amendmentId);
    return res;
  }
}
