<div class="container-fluid px-2 pt-2">
  <div class="pb-3" *ngIf="!review">
    <div
      class="row"
      [ngClass]="{
        'board-restrict': reviewBoard.resource?.keRestricted,
        'restrict-ke': reviewBoard.resource?.keRestricted,
        board: !reviewBoard.resource?.keRestricted
      }"
      *ngFor="let reviewBoard of reviewBoards"
    >
      <div
        class="col-2 location pr-0"
        id="locDiv"
        (longPress)="create(reviewBoard)"
      >
        <div class="full-width d-flex">
          <div
            class="location-name"
            [ngClass]="{
              'custom-location-name': reviewBoard.resource?.type == 20
            }"
          >
            {{ reviewBoard.resource?.name }}<br /><span
              class="very-small description"
            >
              {{ reviewBoard.resource?.description }}</span
            >
          </div>
          <div class="options p-1 text-right">
            <button
              mat-icon-button
              (click)="edit(reviewBoard.resource)"
              *ngIf="
                checkPrivilege(privilege.CreateCustomResources) &&
                (reviewBoard.resource?.code == 'RCP' ||
                  reviewBoard.resource?.type == 20)
              "
            >
              <mat-icon color="accent">edit</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="delete(reviewBoard.resource)"
              *ngIf="
                checkPrivilege(privilege.CreateCustomResources) &&
                !reviewBoard.reviewCycles?.length &&
                (reviewBoard.resource?.code == 'RCP' ||
                  reviewBoard.resource?.type == 20)
              "
            >
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </div>
        </div>
        <div
          class="options p-1 mb-1 d-flex flex-wrap"
          *ngIf="
            service.checkPrivilege(privilegeEnum.CreateReview, reviewBoardType)
          "
        >
          <button
            mat-stroked-button
            *ngIf="showLabel()"
            color="primary"
            (click)="create(reviewBoard)"
            class="white-background float-right"
            [disabled]="
              pendingRequests(reviewBoard, true) == 0 &&
              pendingRequests(reviewBoard, false) == 0 &&
              pendingCustom() == 0
            "
          >
            <mat-icon>add_box</mat-icon>
            <span class="button-text">Create Review</span>
          </button>
          <button
            mat-icon-button
            *ngIf="!showLabel()"
            color="primary"
            (click)="create(reviewBoard)"
            class="white-background float-right"
            [disabled]="
              pendingRequests(reviewBoard, true) == 0 &&
              pendingRequests(reviewBoard, false) == 0 &&
              pendingCustom() == 0
            "
          >
            <mat-icon>add_box</mat-icon>
          </button>
          <div class="d-flex">
            <span
              class="col-2"
              *ngIf="pendingRequests(reviewBoard, true) != 0"
              matTooltip="Active Review Cycles"
              class="create-reviews-wrapper created pl-2"
            >
              {{ pendingRequests(reviewBoard, true) }}
            </span>
            <span
              class="col-2"
              *ngIf="pendingRequests(reviewBoard, false) != 0"
              matTooltip="Pending Review Cycles"
              class="create-reviews-wrapper pending pl-2"
            >
              {{ pendingRequests(reviewBoard, false) }}
            </span>
            <span
              class="col-2"
              *ngIf="pendingCustom() != 0"
              matTooltip="Pending Custom Review Cycles"
              class="create-reviews-wrapper custom pl-2"
            >
              {{ pendingCustom() }}
            </span>
          </div>
        </div>

        <!-- Restrict KE -->
        <div
          class="ke p-1 mb-1 d-flex flex-wrap"
          *ngIf="
            service.checkPrivilege(
              privilegeEnum.CreateReview,
              reviewBoardType
            ) && shutterExists(reviewBoard)
          "
        >
          <mat-slide-toggle
            [checked]="reviewBoard.resource?.keRestricted"
            class="small"
            (change)="sliderChanged($event, reviewBoard)"
            color="primary"
            >Restrict Key Enable
          </mat-slide-toggle>
        </div>
        <div
          class="ke p-1 d-flex flex-wrap"
          *ngIf="reviewBoard.resource?.keRestricted"
        >
          <div class="col">
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
              <input
                type="text"
                matInput
                placeholder="Resource"
                name="shutterCtrl"
                [ngModel]="reviewBoard.resource?.reviewRestrictedResource?.name"
                [disabled]="true"
              />
            </mat-form-field>
          </div>
          <div class="col" *ngIf="showbranch">
            <div class="flex-display">
              <mat-form-field appearance="outline" class="no-subscript">
                <input
                  type="text"
                  placeholder="Branchline"
                  matInput
                  name="branchCtrl"
                  [formControl]="branchCtrl"
                  [matAutocomplete]="autoBranch"
                  #trigger="matAutocompleteTrigger"
                />
                <mat-autocomplete
                  #autoBranch="matAutocomplete"
                  #branchlineAutocomplete
                  [displayWith]="displayObject"
                  (optionSelected)="selectedBranch($event)"
                >
                  <mat-option
                    *ngFor="let branchline of filteredBranchlines"
                    [value]="branchline"
                  >
                    {{ branchline.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-icon
                  class="pointer input-icon"
                  *ngIf="!branchline"
                  color="primary"
                  >search</mat-icon
                >
                <mat-icon
                  class="pointer input-icon"
                  *ngIf="branchline"
                  color="warn"
                  (click)="clearBranch()"
                  >close</mat-icon
                >
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="col-10 pt-3 p-2 pl-4">
        <reviews-boards-cycles
          class="full-width"
          [reviewBoard]="reviewBoard"
          (selectedReview)="reviewSelected($event)"
          [showOnlyActive]="showOnlyActive"
        ></reviews-boards-cycles>
      </div>
    </div>
  </div>

  <reviews-boards-reviews-details
    *ngIf="review"
    [review]="review"
    (reviewUpdated)="reviewUpdated($event)"
  ></reviews-boards-reviews-details>
</div>
