<div class="card">
  <div class="card-header">
    <span class="card-title">{{
      !message ? "Add Message" : "Edit " + message.name
    }}</span>
  </div>
  <div class="card-body">
    <form [formGroup]="editMessageForm">
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description"></textarea>
        <!-- <mat-error *ngIf="editMessageForm.controls.['description'].hasError('required')">description is required</mat-error> -->
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Type</mat-label>
        <mat-select formControlName="type">
          <mat-option [value]="MessageType.None">
            {{ MessageType[MessageType.None] }}
          </mat-option>
          <mat-option [value]="MessageType.Error">
            {{ MessageType[MessageType.Error] }}
          </mat-option>
          <mat-option [value]="MessageType.Info">
            {{ MessageType[MessageType.Info] }}
          </mat-option>
          <mat-option [value]="MessageType.Success">
            {{ MessageType[MessageType.Success] }}
          </mat-option>
          <mat-option [value]="MessageType.Warning">
            {{ MessageType[MessageType.Warning] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>TimeOut</mat-label>
        <input
          matInput
          type="number"
          formControlName="timeout"
          placeholder="timeout"
        />
      </mat-form-field>

      <div class="row">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Notes</mat-label>
            <textarea matInput formControlName="notes"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onNoClick(false)">
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="!editMessageForm.valid"
      >
        Accept
      </button>
    </div>
  </div>
</div>
