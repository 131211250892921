<div class="card">
  <div class="card-header">
    <span class="card-title">Settings</span>
    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="main-card-body card-body">
    <div class="row full-height">
      <div class="col-4 pr-0 pb-2">
        <div class="p-1 full-height d-flex flex-column justify-content-between">
          <reviews-settings-board-types
            [reviewBoardType]="reviewBoardType"
            (reviewBoardTypeSelected)="reviewBoardTypeSelected($event)"
          >
          </reviews-settings-board-types>
          <reviews-settings-menu
            [reviewBoardType]="reviewBoardType"
          ></reviews-settings-menu>
        </div>
      </div>
      <div class="col-8">
        <mat-tab-group
          animationDuration="0ms"
          *ngIf="reviewBoardType"
          class="settings-tabs"
        >
          <mat-tab label="Review Types" bodyClass="settings-tab-body">
            <reviews-settings-review-types
              [reviewBoardType]="reviewBoardType"
            ></reviews-settings-review-types>
          </mat-tab>
          <mat-tab label="Statuses" bodyClass="settings-tab-body">
            <reviews-settings-statuses
              [reviewBoardType]="reviewBoardType"
            ></reviews-settings-statuses>
          </mat-tab>
          <mat-tab label="Permissions" bodyClass="settings-tab-body">
            <reviews-settings-permissions
              [reviewBoardType]="reviewBoardType"
            ></reviews-settings-permissions>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <div class="row">
      <div class="col"></div>
    </div>
  </div>
</div>
