<div class="card">
  <div class="card-header">
    <span class="card-title"
      >{{ dialogData.checklistTemplate?.name }} - Checklist<span class="ml-2 small"
        >from: {{ checklist?.checklistTemplate?.serialNo }}</span
      ></span
    >
    <button
      mat-mini-fab
      class="closex"
      color="warn"
      (click)="dialogRef?.close(checklist)"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

  <div class="card-body">
    <div [ngClass]="{ content: !isPrintView, 'container-fluid': isPrintView }">
      <div class="row">
        <div class="col-md-12">
          <checklist-sections
            [sections]="sections"
            [disabled]="true"
            [currentChecklist]="checklist"
            [isPrintView]="isPrintView"
          ></checklist-sections>
          <button
            mat-mini-fab
            aria-label="Print view"
            *ngIf="!isPrintView"
            class="print-btn"
            (click)="sendPrintView()"
          >
            <mat-icon>print</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="card-footer">
    <div mat-dialog-actions align="center"></div>
  </div> -->
</div>
