import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { RadMonitor } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor';
import { ClBaseComponent } from '../../cl-base/cl-base.component';
import { RadMonitorLocationTabComponent } from '../../../../components/catalogs/rad-monitor/location-tab/location-tab.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'cl-editor-rad-monitors',
  templateUrl: './cl-editor-rad-monitors.component.html',
  styleUrls: ['./cl-editor-rad-monitors.component.scss']
})
export class ClEditorRadMonitorsComponent extends ClBaseComponent implements OnInit {

  radMonitors?: RadMonitor[];
  radMonitorsList?: RadMonitor[];
  radMonitorsFiltered?: RadMonitor[];
  radMonitors$!: Observable<RadMonitor[]>;
  radMonitorsSubs!: Subscription;

  radMonitorsCtrl = new FormControl();

  ngOnInit(): void {
    this.buildForm();
    this.loadData();
    this.loadRadMonitors();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      radMonitorsCtrl: this.radMonitorsCtrl
    });
    if (this.disabled)
      this.formGroup.disable();
  }

  loadRadMonitors() {
    this.radMonitors$ = this.store.select(state => state.RadMonitor.data);
    this.radMonitorsSubs = this.radMonitors$.subscribe(data => {
      if (data?.length) {
        this.radMonitors = data;
        this.filterRadMonitors();
      }
    });
  }

  filterRadMonitors() {
    this.radMonitorsFiltered = this.radMonitors?.filter(r => !this.radMonitorsList?.map(x => x.id).includes(r.id));
  }

  loadData() {
    if (this.configuration) {
      this.radMonitorsList = [];
      const radMonitorsList = this.utils.JSONparse(this.configuration).data as RadMonitor[];
      radMonitorsList?.map(r => {
        const radMonitor = this.radMonitors?.find(x => x.id == r.id);
        if (radMonitor)
          this.radMonitorsList?.push(radMonitor);
        this.radMonitorsList?.sort((a, b) => this.utils.sort(a.ehs, b.ehs));
      });
    }
    else {
      this.radMonitorsList = [];
    }
  }

  saveData() {
    const radMonitorsList: any[] = [];
    this.radMonitorsList?.map(r => {
      radMonitorsList.push(this.utils.Serialize(r));
    });
    this.configuration = this.utils.JSONstringify({ data: radMonitorsList });
    this.changed.emit(this.configuration);
    this.loadData();
    this.filterRadMonitors();
  }

  add(e: any) {

  }

  remove(radMonitor: RadMonitor) {
    const index = this.radMonitorsList?.findIndex(x => x.id == radMonitor.id) as number;
    if (index >= 0) {
      this.radMonitorsList?.splice(index, 1);
      this.saveData();
    }
  }

  onChange(e: any) {
    const value = (e.target.value as string).toLowerCase();
    this.filterRadMonitors();
    this.radMonitorsFiltered = this.radMonitors?.filter(r => r.ehs?.includes(value));
  }

  selected(e: any) {
    const value = e.option.value;
    this.radMonitorsList?.push(value);
    this.saveData();
  }


}
