<mat-chip
  *ngFor="let c of documentChips"
  class="mat-chip {{ c.cssClass }}-background mr-1"
  [matTooltip]="c.status"
  matTooltipClass="{{ c.cssClass }}-background small"
  (click)="openDocument(c.serialNo)"
  [ngClass]="{ shadow: shadow }"
>
  <span class="btn-link white nowrap">{{ c.serialNo }}</span>
</mat-chip>
