<div id="section1" class="container-fluid mt-2">
  <form [formGroup]="formGroup" (keydown.enter)="$event.preventDefault()">
    <div class="row">
      <div class="col-8">
        <h5 id="section1Title">Initiation</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <mat-form-field
          [ngClass]="{
            hilite:
              formVersion &&
              showAmendment &&
              formVersion.requesterID !=
                formVersion.previousVersion?.requesterID
          }"
        >
          <mat-label>Requester</mat-label>
          <input
            matInput
            placeholder="Requester"
            aria-label="Requester"
            [matAutocomplete]="reqAuto"
            [formControl]="requesterCtrl"
            name="Requester"
            (keyup)="onReqChange($event)"
            #trigger="matAutocompleteTrigger"
          />
          <mat-autocomplete
            #reqAuto="matAutocomplete"
            [displayWith]="displayObject"
            (optionSelected)="selectedReq($event)"
          >
            <mat-option [value]="user" *ngFor="let user of activeUsers">{{
              user.name
            }}</mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="requesterCtrl?.invalid">{{
            getErrorMsg(requesterCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-4">
        <related-documents
          [relatedDocuments]="formVersion?.ppstb?.relatedDocuments"
          [disabled]="disabled"
          [formID]="formVersion?.ppstb?.id"
          [formType]="formTypeEnum.PPSTB"
          [disabled]="disabled"
          (changed)="relatedDocumentsChanged($event)"
          [hilite]="diffRelatedDocuments()"
        ></related-documents>
      </div>
      <div class="col-12 col-md-4">
        <related-link-chips
          placeholder="Related Links"
          [disabled]="disabled"
          [formId]="formVersion?.ppstb?.id"
          (onEdited)="setFormDirty()"
          [relatedLinks]="formVersion?.ppstb?.ppstbRelatedLinks"
          [hilite]="diffRelatedLinks()"
        >
        </related-link-chips>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <comments-box
          [disabled]="disabled"
          title="Justification for Bypass"
          formType="PPSTB"
          [serialNo]="form?.sequenceNumber?.number ?? ''"
          [textValue]="formVersion?.ppstb?.justificationForBypass"
          (textChanged)="descriptionChanged($event)"
          [control]="descriptionCtrl"
          [hilite]="
            formVersion &&
            showAmendment &&
            formVersion.ppstb?.justificationForBypass !=
              formVersion.previousVersion?.ppstb?.justificationForBypass
          "
        ></comments-box>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <mat-checkbox
          class="d-block"
          name="beamlineCheckbox"
          [formControl]="chkBeamlineCtrl"
          [disabled]="disabled"
          [(ngModel)]="tmpChkBeamline"
          (change)="blCheckboxChanged($event)"
          [ngClass]="{
            hilite:
              formVersion &&
              showAmendment &&
              formVersion.ppstb?.beamline !=
                formVersion.previousVersion?.ppstb?.beamline
          }"
        >
          Beamline
        </mat-checkbox>

        <resource-chips
          [selectedResources]="getResources(formVersion?.ppstb?.ppstbShutters)"
          [resourceTypes]="[
            resourceTypeEnum.Shutter,
            resourceTypeEnum.Branchline
          ]"
          [placeholder]="'Beamline Locations'"
          [control]="shuttersCtrl"
          [disabled]="disabled || (!disabled && !tmpChkBeamline)"
          (onEdit)="onShutterEdited($event)"
          (onError)="alert.error($event)"
          [hilite]="diffShutters()"
        >
        </resource-chips>
      </div>
      <div class="col-3">
        <mat-checkbox
          class="d-block"
          name="acceleratorCheckbox"
          [formControl]="chkAcceleratorCtrl"
          [disabled]="disabled"
          [(ngModel)]="tmpChkAccelerator"
          (change)="accCheckboxChanged($event)"
          [ngClass]="{
            hilite:
              formVersion &&
              showAmendment &&
              formVersion.ppstb?.accelerator !=
                formVersion.previousVersion?.ppstb?.accelerator
          }"
        >
          Accelerator
        </mat-checkbox>
        <resource-chips
          [selectedResources]="
            getResources(formVersion?.ppstb?.ppstbAcceleratorLocations)
          "
          [resourceTypes]="[
            resourceTypeEnum.Accelerator,
            resourceTypeEnum.AcceleratorLocation
          ]"
          [placeholder]="'Accelerator Locations'"
          [control]="acceleratorCtrl"
          [disabled]="disabled || (!disabled && !tmpChkAccelerator)"
          (onEdit)="onAcceleratorEdited($event)"
          (onError)="alert.error($event)"
          [hilite]="diffAccelerators()"
        >
        </resource-chips>
      </div>
      <div class="col-3">
        <mat-checkbox
          class="d-block"
          [formControl]="chkTopOffCtrl"
          [disabled]="disabled"
          [(ngModel)]="tmpChkTopOff"
          (change)="topCheckboxChanged($event)"
          [ngClass]="{
            hilite:
              formVersion &&
              showAmendment &&
              formVersion.ppstb?.topOff !=
                formVersion.previousVersion?.ppstb?.topOff
          }"
        >
          Top-Off
        </mat-checkbox>

        <other-system-chips
          [placeholder]="'Other systems'"
          [disabled]="disabled"
          [control]="otherSystemCtrl"
          [formId]="formVersion?.ppstb?.id"
          [formType]="formTypeEnum.PPSTB"
          [otherSystems]="tmpOtherSystems"
          (onEdit)="onOtherSystemsEdited($event)"
          [hilite]="diffOtherSystems()"
        >
        </other-system-chips>
      </div>
      <div class="col-3">
        <mat-checkbox
          class="d-block"
          [formControl]="chkRadMonCtrl"
          [disabled]="disabled"
          [(ngModel)]="tmpRadMonOff"
          (change)="radCheckboxChanged($event)"
          [ngClass]="{
            hilite:
              formVersion &&
              showAmendment &&
              formVersion.ppstb?.radiationMonitoringSystem !=
                formVersion.previousVersion?.ppstb?.radiationMonitoringSystem
          }"
        >
          Radiation Monitoring Systems
        </mat-checkbox>
      </div>
    </div>
    <div class="row" [ngClass]="{ 'mb-4': !form?.editingBy }">
      <div class="col">
        <form-restriction-table
          [formRestrictions]="formVersion?.ppstb?.ppstbRestrictions"
          [formID]="formVersion?.ppstb?.id"
          [formType]="formTypeEnum.PPSTB"
          [disabled]="disabled"
          customTitle="Mitigations"
          [control]="restrictionsCtrl"
          [locationIDs]="locationIDs"
          [hilite]="diffRestrictions()"
          (changed)="restrictionsChanged($event)"
          placeholder="Add Mitigation"
        ></form-restriction-table>
      </div>
    </div>

    <div id="section2">
      <div class="row mb-1 mt-2">
        <div class="col-8">
          <h5 id="Section2Title">
            <span> Controls & Approvals </span>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <comments-box
            [title]="getMessage('PPSTB_Conditions_And_Limitations').description"
            [disabled]="disabled"
            formType="PPSTB"
            [serialNo]="form?.sequenceNumber?.number ?? ''"
            [textValue]="formVersion?.ppstb?.conditionsAndLimitations"
            [control]="conditionsCtrl"
            (textChanged)="conditionsAndlimitationsChanged($event)"
            [hilite]="
              formVersion &&
              showAmendment &&
              formVersion.ppstb?.conditionsAndLimitations !=
                formVersion.previousVersion?.ppstb?.conditionsAndLimitations
            "
          ></comments-box>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <comments-box
            [title]="getMessage('PPSTB_Systems_And_Print_Number').description"
            [disabled]="disabled"
            formType="PPSTB"
            [serialNo]="form?.sequenceNumber?.number ?? ''"
            [textValue]="formVersion?.ppstb?.systemAndPrintNumbers"
            [control]="systemCtrl"
            (textChanged)="systemAndPrintChanged($event)"
            [hilite]="
              formVersion &&
              showAmendment &&
              formVersion.ppstb?.systemAndPrintNumbers !=
                formVersion.previousVersion?.ppstb?.systemAndPrintNumbers
            "
          ></comments-box>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <comments-box
            [title]="getMessage('PPSTB_Bypass_Details').description"
            [disabled]="disabled"
            formType="PPSTB"
            [serialNo]="form?.sequenceNumber?.number ?? ''"
            [textValue]="formVersion?.ppstb?.bypassDetails"
            [control]="bypassCtrl"
            (textChanged)="bypassDetailsChanged($event)"
            [hilite]="
              formVersion &&
              showAmendment &&
              formVersion.ppstb?.bypassDetails !=
                formVersion.previousVersion?.ppstb?.bypassDetails
            "
          ></comments-box>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <comments-box
            [title]="getMessage('PPSTB_label_testing_required').description"
            [disabled]="disabled"
            formType="PPSTB"
            [serialNo]="form?.sequenceNumber?.number ?? ''"
            [textValue]="formVersion?.ppstb?.testingRequiredBeforeOperation"
            [control]="testingCtrl"
            (textChanged)="testingRequiredChanged($event)"
            [hilite]="
              formVersion &&
              showAmendment &&
              formVersion.ppstb?.testingRequiredBeforeOperation !=
                formVersion.previousVersion?.ppstb
                  ?.testingRequiredBeforeOperation
            "
          ></comments-box>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">
          <mat-form-field
            [ngClass]="{
              hilite:
                formVersion &&
                showAmendment &&
                formVersion.ppstb?.permittedStartDate !=
                  formVersion.previousVersion?.ppstb?.permittedStartDate
            }"
          >
            <mat-label
              >{{ getMessage("EB_Permitted_Start_Date").description }}
            </mat-label>
            <input
              matInput
              [matDatepicker]="permittedStartDatePicker"
              [disabled]="disabled"
              formControlName="startDateCtrl"
              (dateChange)="startDateChanged($event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="permittedStartDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #permittedStartDatePicker></mat-datepicker>
            <mat-error *ngIf="startDateCtrl?.invalid">{{
              getErrorMsg(startDateCtrl)
            }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-4">
          <mat-form-field
            [ngClass]="{
              hilite:
                formVersion &&
                showAmendment &&
                formVersion.ppstb?.estimatedCompletion !=
                  formVersion.previousVersion?.ppstb?.estimatedCompletion
            }"
          >
            <mat-label
              >{{ getMessage("EB_Date_Of_Estimated_Completion").description }}
            </mat-label>
            <input
              matInput
              [matDatepicker]="estimatedCompletionPicker"
              name="completionDate"
              [disabled]="disabled"
              (dateChange)="completionDateChanged($event)"
              formControlName="completionDateCtrl"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="estimatedCompletionPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #estimatedCompletionPicker></mat-datepicker>
            <mat-error *ngIf="completionDateCtrl?.invalid">{{
              getErrorMsg(completionDateCtrl)
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
<div
  id="sidenav-content-buttons"
  class="buttons d-print-none d-flex justify-content-between"
>
  <div class="d-flex justify-content-start">
    <button
      mat-flat-button
      *ngIf="
        form &&
        (form?.formVersion?.statusID == formStatusEnum.WorkApprovals ||
          form?.formVersion?.statusID == formStatusEnum.Pending) &&
        hasPrivilege(privilegeEnum.EBCancel)
      "
      color="warn"
      class="d-print-none mr-2"
      (click)="cancel()"
    >
      Cancel
    </button>

    <button
      mat-flat-button
      *ngIf="
        form &&
        showAmendment &&
        form?.formVersion?.statusID == formStatusEnum.Amendment &&
        hasPrivilege(privilegeEnum.EBCancel)
      "
      color="warn"
      class="d-print-none mr-2"
      (click)="cancel()"
    >
      Cancel Amendment
    </button>

    <button
      mat-flat-button
      *ngIf="
        form &&
        form?.editingById == currentUser?.id &&
        ((form?.formVersion?.statusID ?? 0) == formStatusEnum.New ||
          form?.formVersion?.statusID == formStatusEnum.Draft)
      "
      color="warn"
      class="d-print-none"
      (click)="cancelDraft()"
    >
      Cancel
    </button>
  </div>

  <div class="d-flex justify-content-end">
    <button
      mat-stroked-button
      color="primary"
      class="d-print-none ml-2"
      *ngIf="
        form?.formVersion?.statusID == formStatusEnum.Draft &&
        hasRoles([roleEnum.OIC]) &&
        form?.editingById == currentUser?.id
      "
      (click)="service.editOlog(form)"
    >
      Edit Olog
    </button>
    <button
      [disabled]="formGroup.invalid"
      mat-flat-button
      class="d-print-none ml-2"
      color="primary"
      [disabled]="!form"
      *ngIf="
        form?.formVersion?.statusID == formStatusEnum.Draft &&
        hasRoles([roleEnum.OIC]) &&
        form?.editingById == currentUser?.id
      "
      (click)="submit()"
    >
      Submit
    </button>
    <button
      mat-flat-button
      *ngIf="
        showAmendment &&
        hasPrivilege(privilegeEnum.EBEditAmendment) &&
        form?.formVersion?.statusID == formStatusEnum.Amendment &&
        form?.editingById == currentUser?.id
      "
      color="primary"
      type="submit"
      (click)="submitAmendment()"
      class="d-print-none ml-2"
    >
      Submit
    </button>
    <button
      mat-flat-button
      [disabled]="formGroup.disabled"
      *ngIf="
        hasRoles([roleEnum.OIC, roleEnum.PPSTBTA, roleEnum.EMSup]) &&
        ((form && (form?.formVersion?.statusID ?? 0) == formStatusEnum.New) ||
          form?.formVersion?.statusID == formStatusEnum.Draft ||
          form?.formVersion?.statusID == formStatusEnum.Amendment) &&
        form?.editingById == currentUser?.id
      "
      color="accent"
      (click)="save()"
      class="d-print-none ml-2"
    >
      {{
        form?.formVersion?.statusID == formStatusEnum.Amendment
          ? "Update Amendment"
          : !form?.id
          ? "Save Draft"
          : "Update Draft"
      }}
    </button>

    <button
      *ngIf="
        canUnsubmit &&
        (form?.formVersion?.createdBy == getCurrentUser()?.id ||
          signature?.signedBy?.id == this.getCurrentUser()?.id ||
          isSA()) &&
        form?.formVersion?.statusID == formStatusEnum.WorkApprovals
      "
      class="d-print-none ml-2"
      mat-flat-button
      color="warn"
      (click)="unsubmit()"
    >
      Unsubmit
    </button>

    <button
      *ngIf="
        formVersion?.createdBy == getCurrentUser()?.id &&
        form?.formVersion?.statusID == formStatusEnum.RestrictionsAmendment &&
        hasPrivilege(privilegeEnum.PPSTBRequestAmendment) &&
        !technicalApprovalSignatureAmendment?.signed
      "
      class="d-print-none ml-2"
      mat-flat-button
      color="warn"
      (click)="unsubmitAmendment()"
    >
      Unsubmit Amendment
    </button>
  </div>
</div>
