import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { Review, ReviewAttendanceSave } from '../../../../../../reviews.models';
import { Subscription } from 'rxjs/internal/Subscription';
import { Store } from '@ngxs/store';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReviewsService } from 'src/app/components/reviews/reviews.service';
import { ReviewsBoardsReviewsMembersComponent } from '../../reviews-members.component';
import { LoadSavedRosterRightComponent } from '../load-saved/right/load-saved-roster-right.component';
import { Observable } from 'rxjs/internal/Observable';


@Component({
  selector: 'app-review-members-load-saved-roster',
  templateUrl: './load-saved-roster.component.html',
  styleUrls: ['./load-saved-roster.component.scss']
})
export class ReviewMembersLoadSavedRosterComponent implements OnInit, OnDestroy {

  review: Review;
  reviewAttendanceSave: ReviewAttendanceSave[];
  reviewMembersComponent: ReviewsBoardsReviewsMembersComponent;

  reviewAttendanceSaved!: ReviewAttendanceSave[];
  reviewAttendanceSavedFiltered!: ReviewAttendanceSave[];
  reviewAttendanceSaved$!: Observable<ReviewAttendanceSave[]>;
  reviewAttendanceSavedSubs!: Subscription;
  reviewAttendanceSavedSelected?: ReviewAttendanceSave | null;

  disabled: boolean = true;
  membersToAddCount = 0;

  replace: boolean = false;

  @ViewChild(LoadSavedRosterRightComponent)
  right!: LoadSavedRosterRightComponent;

  public form!: FormGroup;
  public listCtrl = new FormControl();

  constructor(
    private store: Store,
    public dialog: MatDialogRef<ReviewMembersLoadSavedRosterComponent>,
    private service: ReviewsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.review = data.review;
    this.reviewAttendanceSave = data.reviewAttendanceSave;
    this.reviewMembersComponent = data.reviewMembersComponent;
  }

  ngOnDestroy(): void {
    this.reviewAttendanceSavedSubs.unsubscribe();
  }

  ngOnInit(): void {
    this.reviewAttendanceSaved$ = this.store.select(state => state.ReviewAttendanceSaved.data);
    this.reviewAttendanceSavedSubs = this.reviewAttendanceSaved$.subscribe(data => {
      this.reviewAttendanceSaved = data.filter(x => !this.reviewAttendanceSave?.map(r => r.id).includes(x.id));
      this.reviewAttendanceSavedFiltered = this.reviewAttendanceSaved;
      if (this.reviewAttendanceSavedSelected) {
        this.reviewAttendanceSavedSelected = this.reviewAttendanceSavedFiltered.find(x => x.id == this.reviewAttendanceSavedSelected?.id);
        this.listCtrl.setValue(this.reviewAttendanceSavedSelected);
      }
    });
  }

  changedList(e: any) {
    const val = e.target.value;
    this.reviewAttendanceSavedFiltered = this.reviewAttendanceSaved.filter(x => x.name?.toLowerCase().includes(val.toLowerCase()));
    this.reviewAttendanceSavedSelected = this.reviewAttendanceSavedFiltered.length > 0 ? this.reviewAttendanceSavedFiltered[0] : null;
    if (!val) {
      this.reviewAttendanceSavedSelected = null;
    }
  }

  selectList() {
    this.listCtrl.setValue(this.reviewAttendanceSavedSelected);
  }

  selectedList(e: any) {
    this.reviewAttendanceSavedSelected = e.option.value;
  }

  displayObject(e: any) {
    return e ? e.name : null;
  }

  edit() {
    if (this.reviewAttendanceSavedSelected)
      this.reviewMembersComponent.editSavedRoster(this.reviewAttendanceSavedSelected);
  }

  delete() {
    if (this.reviewAttendanceSavedSelected)
      this.reviewMembersComponent.deleteSavedRoster(this.reviewAttendanceSavedSelected);
  }

  cancel() {
    this.dialog.close();
  }

  load() {
    if (this.reviewAttendanceSavedSelected)
      this.reviewAttendanceSavedSelected.replace = this.replace;
    this.dialog.close(this.reviewAttendanceSavedSelected);
  }

  membersToAdd(e: any) {
    this.membersToAddCount = e;
    this.disabled = this.membersToAddCount == 0;
  }

}
