import { Injectable } from '@angular/core';
import { Action, State, Selector, StateContext } from '@ngxs/store';
// import { utils } from 'src/app/modules/Libs/utils';
// import { BulletinBoardService } from 'src/app/Services/BulletinBoard/bulletin-board-service.service';
// import { BulletinBoardItem } from 'src/app/_interface/BulletinBoardItem';
import { BulletinBoardRefresh, BulletinBoardCreate, BulletinBoardUpdate, BulletinBoardDelete } from './bulletin-board.action';
import { utils } from 'src/app/modules/libs/utils';
import { BulletinBoardItem } from '../bulletin-board-item';
import { BulletinBoardService } from '../bulletin-board-service.service';

export class BulletinBoardStateModel {
  bulletinBoardItems!: BulletinBoardItem[];
}

@State<BulletinBoardStateModel>({
  name: 'BulletinBoard',
  defaults: {
    bulletinBoardItems: []
  }
})
@Injectable()
export class BulletinBoardState {
  constructor(
    private bulletinBoardService: BulletinBoardService
  ) { }

  @Selector()
  static read(state: BulletinBoardStateModel) {
    return state.bulletinBoardItems;
  }

  @Action(BulletinBoardRefresh)
  refresh({ patchState }: StateContext<BulletinBoardStateModel>) {
    this.bulletinBoardService.getAll().toPromise().then(data => {
      patchState({ bulletinBoardItems: utils.cloneDeep(data) });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(BulletinBoardCreate)
  create({ getState, patchState }: StateContext<BulletinBoardStateModel>, { item }: BulletinBoardCreate) {
    patchState({
      bulletinBoardItems: [...getState().bulletinBoardItems, item]
    });
  }

  @Action(BulletinBoardUpdate)
  update({ getState, patchState }: StateContext<BulletinBoardStateModel>, { id, item }: BulletinBoardUpdate) {
    const state = getState();
    const index = state.bulletinBoardItems.findIndex(x => x.id === id);
    const currentState = utils.cloneDeep(state.bulletinBoardItems);

    if (index >= 0) {
      currentState.splice(index, 1, utils.cloneDeep(item));
      patchState({ bulletinBoardItems: currentState });
    }
  }

  @Action(BulletinBoardDelete)
  delete({ getState, patchState }: StateContext<BulletinBoardStateModel>, { id }: BulletinBoardDelete) {
    patchState({
      bulletinBoardItems: getState().bulletinBoardItems.filter(x => x.id !== id)
    });
  }
}
