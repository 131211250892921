<div class="container-fluid">
  <h5 id="Section3Title">Review & Authorization</h5>
  <div
    class="no-gutters w-100 relative"
    *ngFor="let a of amendments"
    [ngClass]="{
      'border-amd': a > 0 && a == getLastAmendment(),
      'border-amd-old': a > 0 && a != getLastAmendment()
    }"
  >
    <span
      [ngClass]="{
        'amendment-title': a == getLastAmendment(),
        'amendment-title-old': a != getLastAmendment()
      }"
      *ngIf="a > 0"
      >Amendment {{ a }}</span
    >
    <div class="d-flex justify-content-center">
      <div
        *ngFor="let s of getSignaturesByAmendment(a)"
        class="btn-sign-container mx-2"
      >
        <button-signature
          #btnSign
          [roleId]="s.roles"
          [roleCodes]="s.roleCodes"
          [userNames]="s.userNames"
          [user]="s.approvedBy"
          [title]="s.name"
          [disabled]="s.disabled"
          [value]="s.approved"
          [showApprove]="s.approve"
          [showUnapprove]="s.unapprove"
          [showDisapprove]="s.disapprove"
          [showCancel]="s.cancel"
          [cancelID]="s.cancelID"
          [date]="s.approvedOn"
          [taskID]="s.taskId"
          [enabled]="s.enabled"
          (approve)="approve(s.taskId, s.approveId)"
          (unapprove)="unapprove(s.taskId, s.unapproveId)"
          (disapprove)="disapprove(s.taskId, s.disapproveId, $event)"
          (onError)="alert.error($event)"
        ></button-signature>
      </div>
    </div>
  </div>
</div>
