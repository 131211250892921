import { PendingUser } from '../../../../services/pending-approvals/pending-approval';
import { Role } from '../../roles/services/role';
import { UserRole } from '../../../../services/user-roles/user-role';

export class User {
  constructor() {
    this.pendingUsers = [];
    this.userRole = [];
  }
  public id?: number;
  public name?: string;
  public username?: string;
  public password?: string;
  public emailAddress?: string;
  public phoneExt?: string;
  public initials?: string;
  public status?: number;
  public isManager?: boolean;
  public supervisorID?: number;
  public organizationID?: number;
  public userRole?: UserRole[] | null;
  public roleCodes?: string;
  public token?: string | null;
  public mobile?: number;
  public color?: number;
  public orderFromRole?: number;
  public pendingUsers?: PendingUser[] | null;
  public modifyingById?: number;
}

export class UserCatalog {
  constructor() {
    this.details = new UserCatalogDetails();
  }
  id!: number;
  details: UserCatalogDetails;
  pendingDetails: UserCatalogDetails = new UserCatalogDetails;
  pendingUser: PendingUser = new PendingUser;
}

export class UserCatalogDetails {

  user: User = new User;
  roles: Role[] = [];
}
