import { Component, EventEmitter, Injector, Input, Output, SimpleChanges } from '@angular/core';
import { EbService } from 'src/app/components/eb/eb.service';
import { utils } from 'src/app/modules/libs/utils';
import { Form } from 'src/app/services/forms/form';
import { WfSignatureResource, WfSignature, WFConfiguration, ExtraAction } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { FormType } from '../enumerations/enumerations';
import { BaseComponent } from '../base/base.component';
import { PpstbService } from 'src/app/components/ppstb/ppstb.service';
import { Option } from 'src/app/components/checklists/checklists';

@Component({
  selector: 'form-workflow',
  templateUrl: './form-workflow.component.html',
  styleUrls: ['./form-workflow.component.scss']
})
export class FormWorkflowComponent extends BaseComponent {

  @Input() form?: Form | null;
  @Input() sectionNumber!: number;
  @Input() amendmentSectionNumbers?: number[] = [];
  @Input() leftConfigs!: number[];
  @Input() rightConfigs!: number[];
  @Input() disabled?: boolean;

  @Output() loading = new EventEmitter<boolean>()

  wfSignatures?: WfSignatureResource[];
  signatures!: WfSignature[];

  amendments!: any[];
  configuration?: WFConfiguration[];

  type!: FormType;
  service!: EbService | PpstbService;

  extraActions: ExtraAction[] = [];

  constructor(
    protected override injector: Injector,
    public ebService: EbService,
    public ppstbService: PpstbService,
    private workflowService: WorkFlowService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form)
      this.type = this.form.type;
    this.service = this.type == FormType.EBF ? this.ebService : this.ppstbService;
    this.refresh();
  }

  refresh() {
    if (!this.form?.wfTable) return;
    this.loading.emit(true);
    this.workflowService.readConfiguration(this.type, this.form.wfTableID ?? 0).toPromise().then(data => {
      this.configuration = data;

      const leftPersons = this.leftConfigs?.map(identificator => {
        return this.configuration?.filter(x => x.identificator === identificator) ?? [];
      });
      const rightPersons = this.rightConfigs?.map(identificator => {
        return this.configuration?.filter(x => x.identificator === identificator) ?? [];
      });

      this.wfSignatures = this.form?.wfTable?.wfTableLocal?.wfSectionLocals.find(x => x.number == this.sectionNumber)?.wfSignatures;
      this.amendmentSectionNumbers?.map(s => {
        const wfSignatures = this.form?.wfTable?.wfTableLocal?.wfSectionLocals.find(x => x.number == s)?.wfSignatures?.filter(x => x.amendmentNumber);
        if (wfSignatures)
          this.wfSignatures = this.wfSignatures?.concat(wfSignatures);
      });

      this.signatures = [];
      const wfSignaturesToValidate = this.wfSignatures?.concat(this.form?.wfTable?.wfTableLocal?.wfSectionLocals.find(x => x.number == (this.sectionNumber - 1))?.wfSignatures ?? []).concat(this.form?.wfTable?.wfTableLocal?.wfSectionLocals.find(x => x.number == (this.sectionNumber - 1 + 10))?.wfSignatures ?? []);
      if (this.leftConfigs?.length && this.rightConfigs.length) {
        this.wfSignatures?.map(signature => {
          this.signatures.push(
            WfSignatureResource.toWFSignatureResourceWithPerson(signature, wfSignaturesToValidate ?? [], leftPersons.filter(x => x.length), rightPersons.filter(x => x.length), this.currentUser?.id ?? -1));
        });
      }
      else
        this.wfSignatures?.map(signature => {
          this.signatures.push(WfSignatureResource.toWFSignatureResource(signature, true));
        });

      this.amendments = [];
      this.signatures.map(s => {
        if (!this.amendments.includes(s.amendmentNumber ?? 0)) {
          this.amendments.push(s.amendmentNumber ?? 0);
        }
      });
      this.loading.emit(false);
    });
  }

  getSignaturesByAmendment(a: any) {
    return this.signatures.filter(s => (s.amendmentNumber ?? 0) == a);
  }

  getLastAmendment() {
    const last = this.signatures.filter(x => x.amendmentNumber).map(x => x.amendmentNumber).sort((a, b) => utils.sort(a, b, false));
    return last ? last[0] : 0;
  }

  async approve(s: WfSignature, approve: boolean = true, data: any = null) {
    let options = undefined;
    if (data?.options) {
      options = this.utils.JSONstringify(data.options);
    }
    this.service.loading = true;
    const signed = await this.service.confirmSign(this.form, s, approve ? s.approveId : s.unapproveId, '', options, this.configuration);
    if (!signed) this.refresh();
  }

  async unnaprove(taskID?: number, unnaproveID?: number, data?: any) {
    let options = undefined;
    if (data?.options) {
      data.options.map((o: Option) => o.checked = false);
      options = this.utils.JSONstringify(data.options);
    }
    this.service.loading = true;
    await this.service.sign(this.form, taskID, unnaproveID, '', options);
  }

  check(e: boolean, s: WfSignature) {
    this.approve(s, e);
  }
}
