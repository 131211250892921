import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OlogEmail } from './olog-email';

@Injectable({
  providedIn: 'root'
})
export class OlogEmailService {

  public api: string = environment.urls.baseApiUrl + '/OlogEmail';

  constructor(
    private http: HttpClient
  ) { }

  GetOlogEmails(): Observable<OlogEmail[]> {
    return this.http.get<OlogEmail[]>(this.api);
  }

  GetOlogEmailById(id: number): Observable<OlogEmail> {
    return this.http.get<OlogEmail>(this.api + '/' + id);
  }

  CreateOlogEmail(ologEmail: OlogEmail): Observable<OlogEmail> {
    return this.http.post<OlogEmail>(this.api, ologEmail);
  }

  UpdateOlogEmail(id: number, ologEmail: OlogEmail): Observable<OlogEmail> {
    return this.http.put<OlogEmail>(this.api + '/' + id, ologEmail);
  }

  DeleteOlogEmail(id: number, deleteReason: string) {
    return this.http.delete(this.api + '/' + id + '/' + deleteReason);
  }
}
