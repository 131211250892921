<div class="body">
  <mat-toolbar class="toolbar">
    <div class="menu">
      <button mat-icon-button class="noOutline" (click)="toggleSidenav()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div class="full-width">
      <div class="row full-width">
        <div class="col-3 col-md-3 pt-1em">
          <span class="toolbar-title">Review Requests</span>
        </div>
      </div>
    </div>
  </mat-toolbar>
  <mat-sidenav-container
    class="sidenav-container mat-sidenav-collapse-transition"
    autosize
  >
    <mat-sidenav
      [ngClass]="{ 'mat-sidenav-collapsed': !isExpanded && !isShowing }"
      #sidenav
      opened="true"
      class="d-print-none"
      mode="side"
    >
      <mat-nav-list>
        <mat-list-item
          class="parent"
          (click)="reviewRequestDialog()"
          [disabled]="!createRequestPrivilege"
        >
          <span class="full-width" *ngIf="isExpanded">New Request</span>
          <mat-icon matListItemIcon class="yellow">add_box</mat-icon>
        </mat-list-item>
      </mat-nav-list>
      <mat-nav-list>
        <hr />
        <mat-list-item class="parent">
          <span *ngIf="isExpanded">Filter</span>
          <mat-icon matListItemIcon class="blue">filter_alt</mat-icon>
        </mat-list-item>
        <mat-selection-list #statusx class="pl-3" *ngIf="isExpanded">
          <mat-list-option
            *ngFor="let status of statuses"
            [selected]="status.selected"
            [value]="status.status"
            (click)="filterChanged(status)"
          >
            <mat-icon
              matListItemIcon
              [class]="status.color"
              [ngClass]="'alert--'"
              >{{ status.icon }}</mat-icon
            >
            {{ status.name }}
          </mat-list-option>
        </mat-selection-list>
      </mat-nav-list>
      <mat-nav-list> </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content p-3">
      <reviews-requests-table [statuses]="statuses"> </reviews-requests-table>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <overlay-loading *ngIf="loading"></overlay-loading>
</div>
