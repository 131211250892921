import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import * as moment from "moment";
import { User } from "src/app/components/catalogs/user-catalog/services/user";

@Component({
  selector: "mat-checkbox-with-roles",
  templateUrl: "./mat-checkbox-with-roles.component.html",
  styleUrls: ["./mat-checkbox-with-roles.component.scss"],
})
export class MatCheckboxWithRolesComponent implements OnInit, OnChanges {

  @Input() checked?: boolean = false;
  @Input() disabled?: boolean = false;
  @Input() labelPosition: "before" | "after" = 'before';
  @Input() color: string = "primary";
  @Input() roleCodes?: string;
  @Input() user?: User | null;
  @Input() warning?: string | null;
  @Input() date?: Date | null;

  @Output() change = new EventEmitter<any>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit() { }

  getTooltip() {
    if (this.user)
      return `<b>${this.user.name}</b>
  ${this.date ? `<br><span class='tooltip-date'>${moment(this.date).format("M/D/YY HH:mm:ss")}</span>` : ''}
`;
    return '';
  }
}
