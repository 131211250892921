import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { CanDeactivateResponse } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog-response';
import { PendingChangesDialogComponent } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog.component';
import { utils } from 'src/app/modules/libs/utils';
import { RadLocation } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-location';
import { RadMonitor } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor';
import { RadMonitorDescription } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor-description';
import { RadMonitorType } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor-type';

@Component({
  selector: 'app-add-edit-monitor',
  templateUrl: './add-edit-monitor.component.html',
  styleUrls: ['./add-edit-monitor.component.scss']
})
export class RadMonitorAddEditMonitorComponent extends BaseComponent implements OnInit {

  public get ehs(): AbstractControl | null { return this.formGroup.get('ehs'); }
  public get location(): AbstractControl | null { return this.formGroup.get('location'); }
  public get calDueDate(): AbstractControl | null { return this.formGroup.get('calDueDate'); }
  public get installedDate(): AbstractControl | null { return this.formGroup.get('installedDate'); }

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<RadMonitorAddEditMonitorComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: {
      radMonitor: RadMonitor,
      radLocations: RadLocation[],
      radMonitorTypes: RadMonitorType[],
      radMonitorDescriptions: RadMonitorDescription[],
      dialogTitle: string
    },
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initializeForm();
    if (!this.inputData.radMonitor.id) { this.inputData.radMonitor.mfr = 'HPI'; }
  }

  add() {
    if (this.validate()) {
      this.dialogRef.close(this.inputData.radMonitor);
    }
  }

  cancel() {
    if (this.formGroup.dirty) {
      const confirmation = this.dialog.open(PendingChangesDialogComponent, {
        height: 'fit-content',
        width: '40%',
        data: {}
      });
      confirmation.afterClosed().toPromise().then((response: CanDeactivateResponse) => {
        if (response === CanDeactivateResponse.Discard) {
          this.dialogRef.close(null);
        }
      });
    } else {
      this.dialogRef.close(null);
    }
  }

  getRadLocationWithTypeByRadLocation(radLocation: RadLocation): string {
    return radLocation.name + ', ' + radLocation.radLocationType?.name;
  }

  getDefaultModel(radLocationId: number) {
    const radLocation = this.inputData.radLocations.find(x => x.id === radLocationId);
    this.inputData.radMonitor.model = radLocation?.radLocationType?.defaultValue;
  }

  validate(): boolean {
    let result = true;
    if (!this.inputData.radMonitor.ehs) {
      this.alert.error('<b> EHS# </b> is required!');
      this.ehs?.setErrors({ required: true });
      result = false;
    }
    if (!this.inputData.radMonitor.radLocationID) {
      this.alert.error('<b> Location </b> is required!');
      this.location?.setErrors({ required: true });
      result = false;
    }
    if (!this.inputData.radMonitor.calDueDate) {
      this.alert.error('<b> Cal Due Date </b> is required!');
      this.calDueDate?.setErrors({ required: true });
      result = false;
    }
    this.formGroup.markAllAsTouched();
    return result;
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      ehs: [{ value: '' }, [Validators.required, utils.IsWhiteSpaceReactiveForm]],
      location: [{ value: '' }, Validators.required],
      calDueDate: [{ value: '' }, Validators.required],
      installedDate: [{ value: '' }],
      manufacturer: [{ value: '' }],
      model: [{ value: '' }]
    });
  }

}
