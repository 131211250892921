import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Procedure } from '../../models/procedure.model';

@Component({
  selector: 'app-extra-procedure-training-popup',
  templateUrl: './extra-procedure-training-popup.component.html',
  styleUrls: ['./extra-procedure-training-popup.component.scss']
})
export class ExtraProcedureTrainingPopupComponent extends BaseComponent implements OnInit {

  procedureCtrl = new FormControl('');
  proceduresToCreate: string[] = [];
  procedureFiltered: Procedure[] = [];
  allProcedures: Procedure[] = [];
  availableProcedures: Procedure[] = [];
  @Input() selectedProcedures: Procedure[];

  @ViewChild('procedureInput') procedureInput!: ElementRef<HTMLInputElement>;

  constructor(
    protected override injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ExtraProcedureTrainingPopupComponent>) {
    super(injector);

    this.selectedProcedures = data.selectedProcedures;
    this.allProcedures = data.allProcedures.filter((x: Procedure) => x.status == 1 && x.active) as Procedure[];
    this.allProcedures = this.allProcedures.sort((a, b) => a.procedureNumber < b.procedureNumber ? -1 : 1);
    this.setAvailable();
  }

  ngOnInit(): void {
  }

  setAvailable() {
    this.selectedProcedures = this.selectedProcedures ?? [];
    this.availableProcedures = this.utils.cloneDeep(this.allProcedures).filter(p => !this.selectedProcedures.map(x => x.id).includes(p.id));
    this.procedureFiltered = this.availableProcedures;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.proceduresToCreate.push(value);
    }

    // Clear the input value
    event.value = '';

    this.procedureCtrl.setValue(null);
  }

  remove(procedure: string): void {
    const index = this.proceduresToCreate.indexOf(procedure);

    if (index >= 0) {
      this.proceduresToCreate.splice(index, 1);
    }
    this.setAvailable();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.proceduresToCreate.push(event.option.viewValue);
    this.setAvailable();
  }

  createExtraTraining() {
    let procedureNameSplitted = this.proceduresToCreate[0].split(" - ");
    let procedureNumber = procedureNameSplitted[0];
    let procedureTitle = procedureNameSplitted[1];
    let selectedProcedure = this.allProcedures.find(procedure => procedure?.procedureNumber.includes(procedureNumber) && procedure?.title?.includes(procedureTitle));

    this.dialogRef.close(selectedProcedure);
  }

  closeThisModal() {
    this.dialogRef.close();
  }

  applyFilter(event: any) {
    let filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.setAvailable();
    this.procedureFiltered = this.procedureFiltered.filter(procedure => procedure?.procedureNumber.toLowerCase().includes(filterValue) ||
      procedure?.title?.includes(filterValue));
  }
}
