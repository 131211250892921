import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CompareOperator } from "src/app/common/enumerations/enumerations";
import { Option } from "src/app/components/checklists/checklists";
import { utils } from "src/app/modules/libs/utils";
import { AlertService } from "src/app/services/alert/alert.service";

@Component({
  selector: "cl-editor-placeholder-options",
  templateUrl: "./cl-editor-placeholder-options.component.html",
  styleUrls: ["./cl-editor-placeholder-options.component.scss"],
})
export class ClEditorPlaceholderOptionsComponent implements OnInit {
  @Input() disabled?: boolean;
  @Input() options?: string;
  @Input() configuration?: string;

  tmpOptions?: Option[] = [];
  multiple?: boolean;

  operators?: any[];
  placeholder?: string;

  @Output() changed = new EventEmitter<string>();
  @Output() checkbox = new EventEmitter<boolean>();

  constructor(private alert: AlertService) { }

  ngOnInit() {
    if (this.options) {
      this.tmpOptions = JSON.parse(this.options) as Option[];
    }
    this.getOperators();
  }

  change() {
    try {
      const error = this.tmpOptions?.find(
        (o) => !o.value || o.value == 0 || !o.label
      );
      if (!error) {
        this.options = JSON.stringify(this.tmpOptions);
        this.changed.emit(this.options);
      } else {
        this.alert.warning("Values and Labels cannot be empty");
      }
    } catch (e) { }
  }

  add() {
    const opt = new Option();
    opt.id = this.tmpOptions?.length
      ? (this.tmpOptions?.sort((a, b) => (b.id ?? 0) - (a.id ?? 0))?.[0]?.id ?? 0) + 1
      : 1;
    this.tmpOptions?.push(opt);
    this.change();
  }

  del(e: any) {
    this.tmpOptions?.splice(this.tmpOptions?.indexOf(e) ?? -1, 1);
    this.change();
  }

  getOperators() {
    this.operators = utils.getENUM(CompareOperator);
  }
}
