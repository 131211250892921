import { APLGroupManager } from './apl-group-manager.model';
import { APLGroupMaster } from './apl-group-master.model';
import { APLGroupCard_PTCs } from './apl-group-ptc';
import { APLGroupRole } from './apl-group-role.model';

export class APLGroup {
  public id!: number;
  public name?: string;
  public description?: string;
  public status?: number;
  public aplGroupMasterID?: number;
  public previousVersionID?: number;
  public version?: number;
  public createdBy?: number;
  public aplGroupMaster?: APLGroupMaster;
  public previousVersion?: APLGroup;
  public newVersion?: APLGroup;
  public aplGroupManagers?: APLGroupManager[];
  public aplGroupRoles?: APLGroupRole[];
  public aplProcedureTrainingCoordinators?: APLGroupCard_PTCs[];

  constructor() {
    this.aplGroupManagers = [];
    this.aplGroupRoles = [];
    this.aplProcedureTrainingCoordinators = [];
  }
}
