import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFileUploadService } from 'src/app/modules/file-upload/file-upload-module';
import { FileV2Service } from 'src/app/services/file/file-v2.service';
import { AddRelatedLinkDocumentComponent } from '../add-related-link-document/add-related-link-document.component';
import { RelatedLinkDocumentBase } from 'src/app/common/models/related-link-document-base.model';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'related-link-document-chips',
  templateUrl: './related-link-document-chips.component.html',
  styleUrls: ['./related-link-document-chips.component.scss']
})
export class RelatedLinkDocumentChipsComponent implements OnInit {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() formId?: number;
  @Input() relatedLinksDocuments!: RelatedLinkDocumentBase[];
  @Input() path!: string;
  @Input() disabledFileUpload!: boolean;
  @Input() deleteFile: boolean = true;

  @Output() edited = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    public fileUploadService: MatFileUploadService,
    public fileV2Service: FileV2Service,
  ) { }

  ngOnInit(): void {
  }

  open() {
    if (!this.disabled) {
      const dialog = this.dialog.open(AddRelatedLinkDocumentComponent, {
        maxWidth: '800px',
        disableClose: true,
        data: {
          path: this.path,
          disabledFileUpload: this.disabledFileUpload,
          editing: false,
        }
      });
      dialog.afterClosed().subscribe((result: RelatedLinkDocumentBase) => {
        if (result) {
          this.relatedLinksDocuments.push(result);
          this.edited.emit();
        }
      });
    }
  }

  remove(relatedLinkDocument: RelatedLinkDocumentBase) {
    const index = this.relatedLinksDocuments.indexOf(relatedLinkDocument);

    if (index >= 0) {
      this.relatedLinksDocuments.splice(index, 1);
      this.edited.emit();
      if ((relatedLinkDocument.fileID && this.deleteFile) || !relatedLinkDocument.id) {
        this.fileV2Service.delete(relatedLinkDocument.fileID ?? 0).subscribe(() => { },
          error => console.error(error),
        );
      }
    }
  }

  edit(relatedLinkDocument: RelatedLinkDocumentBase) {
    if (!this.disabled) {
      this.dialog.open(AddRelatedLinkDocumentComponent, {
        maxWidth: '800px',
        disableClose: true,
        data: {
          path: this.path,
          disabledFileUpload: this.disabledFileUpload,
          relatedLinkDocument: utils.cloneDeep(relatedLinkDocument),
          editing: true,
        }
      }).afterClosed().subscribe(
        (data: RelatedLinkDocumentBase) => {
          if (data) {
            relatedLinkDocument.text = data.text;
            relatedLinkDocument.url = data.url;
            relatedLinkDocument.fileID = data.fileID;
            relatedLinkDocument.file = data.file;
          }
        }
      );
    }
  }

}
