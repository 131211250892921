import { Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Checklist } from 'src/app/components/checklists/checklists';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { utils } from 'src/app/modules/libs/utils';
import { DocumentType } from 'src/app/services/documents/documents';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { BaseComponent } from 'src/app/common/base/base.component';
import { OpApprovalValue } from '../checklist-components/op-approval-w-checklists/op-approval-w-checklists.component';

@Component({
  selector: 'checklist-status',
  templateUrl: './checklist-status.component.html',
  styleUrls: ['./checklist-status.component.scss']
})
export class ChecklistStatusComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() disabled?: boolean;
  @Input() checklistsIDs: number[] = [];
  @Input() checklistTypeID!: number;
  @Input() isChanging!: boolean;
  @Input() formStatus?: number;
  @Input() checked?: boolean;

  @Output() changed = new EventEmitter<OpApprovalValue>();
  @Output() changing = new EventEmitter<boolean>();

  checklists!: Checklist[];
  checklistsFiltered!: Checklist[];
  checklistsSaved: Checklist[] = [];
  checklists$!: Observable<Checklist[]>;
  checklistsSubs!: Subscription;
  documentTypes!: (DocumentType | undefined)[];

  changes?: boolean = false;

  prevChecklistsIDs!: number[];

  filteredStatuses: FormStatusEnum[] = [FormStatusEnum.Canceled];

  checklistsCtrl = new FormControl();
  @ViewChild("checklistsInput") checklistsInput!: ElementRef<HTMLInputElement>;

  constructor(
    protected override injector: Injector,
    private router: Router,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['checklistsIDs'])
      this.prevChecklistsIDs = utils.cloneDeep(this.checklistsIDs);
    this.loadChecklists();
  }

  ngOnInit() {
    this.loadChecklists();
  }

  onChange(e: any) {
    const val = e.target.value as string;
    this.filterChecklists();
    this.checklistsFiltered = this.checklistsFiltered.filter(c => c.serialNo?.toLowerCase().includes(val.toLowerCase()));
    this.getDocumentTypes();
  }

  loadChecklists() {
    this.checklists$ = this.store.select(state => state.Checklists.data);
    this.checklistsSubs = this.checklists$.subscribe(data => {
      if (data.length) {
        this.checklists = data.filter(c => c.documentTypeID == this.checklistTypeID).sort((a, b) => utils.sort(a.serialNo, b.serialNo, false));
        this.filterChecklists();
        this.checklists.map(c => {
          c.resourceNameSanitized = c.checklistStatus?.name + (c.resourceName ? '<br>' + c.resourceName : '');
          c.tooltipCssClasses = ['white-background', c.checklistStatus?.cssClass + '-border', 'shadow'];
        });
        this.checklistsSaved = this.checklists.filter(c => this.checklistsIDs?.includes(c.id)).sort((a, b) => utils.sort(a.serialNo, b.serialNo, false));;
      }
    });
  }

  filterChecklists() {
    this.checklistsFiltered = this.checklists.filter(c => !this.checklistsIDs?.includes(c.id));
    this.getDocumentTypes();
  }

  getDocumentTypes() {
    this.documentTypes = this.checklistsFiltered.map(c => c.documentType).filter(
      (obj, index, self) => self.findIndex(o => o?.id === obj?.id) === index
    ).sort((a, b) => utils.sort(a?.description, b?.description, true));
  }

  getFilteredChecklists(document?: DocumentType) {
    return this.checklistsFiltered.filter(c => c.documentTypeID == document?.id && !this.filteredStatuses.includes(c.checklistStatus?.formStatusID ?? 0));
  }

  selected(e: any) {
    const checklist = e.option.value as Checklist;
    if (this.checklistsSaved == null) this.checklistsSaved = [];
    this.checklistsSaved.push(checklist);
    this.checklistsSaved = this.checklistsSaved.sort((a, b) => utils.sort(a.serialNo, b.serialNo, false));
    if (this.checklistsIDs == null) this.checklistsIDs = [];
    this.checklistsIDs.push(checklist.id);
    this.filterChecklists();
    this.checklistsInput.nativeElement.value = "";
    this.checklistsCtrl.setValue(null);
    this.checkChanged();
  }


  remove(checklist: Checklist) {
    this.checklistsSaved = this.checklistsSaved.filter(c => c.id != checklist.id).sort((a, b) => utils.sort(a.serialNo, b.serialNo, false));
    this.checklistsIDs = this.checklistsIDs.filter(c => c != checklist.id);
    this.checkChanged();
  }

  checkChanged() {
    this.changes = !this.utils.compareArrays(this.checklistsIDs, this.prevChecklistsIDs);
    this.changing.emit(this.changes);
    if (this.formStatus == FormStatusEnum.Draft) this.save();
    else this.setFormDirty(this.changes);
  }

  save() {
    const value = { checklistIds: this.checklistsIDs, approved: this.formStatus == FormStatusEnum.Draft } as OpApprovalValue;
    this.changed.emit(value);
    this.setFormDirty(false);
  }

  undo() {
    this.checklistsIDs = utils.cloneDeep(this.prevChecklistsIDs);
    this.checkChanged();
    this.loadChecklists();
  }

  openDocument(checklist: Checklist) {
    localStorage.setItem('checklistID', checklist.id.toString());
    const formStatusID = checklist.documentType?.checklistExecStatuses?.find(x => x.id == checklist.status)?.formStatusID ?? ''
    localStorage.setItem('status', formStatusID.toString());
    const route = 'checklist-exec/' + checklist.checklistTemplate?.documentType?.id;
    this.router.navigate([route]);
  }

  closedChecklist() {
    if (this.checklistsSaved?.some(c => c.checklistStatus?.formStatus.id == FormStatusEnum.Closed)) {
      return this.checklistsSaved.some(s => s.checklistStatus?.formStatus.id != FormStatusEnum.Closed && s.checklistStatus?.formStatus.id != FormStatusEnum.Canceled)
    }
    else
      return true;
  }

}
