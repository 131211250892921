import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnInit } from '@angular/core';
import { MatFileUploadService } from '../mat-file-upload.service';
import { Comment } from 'src/app/controls/comments-and-attachments/services/comment';
import { utils } from 'src/app/modules/libs/utils';
import { RadiationAreaItem } from 'src/app/components/home/radiation-area/radiation-area-item';

@Component({
  selector: 'mat-file-list',
  templateUrl: './mat-file-list.component.html',
  styleUrls: ['./mat-file-list.component.scss']
})

/** mat-file-list component*/
export class MatFileListComponent implements OnChanges, OnInit {

  showupload = false;
  loading = false;

  attachments: Attachment[] = [];
  @Input() public comment?: Comment;
  @Input() public radArea?: RadiationAreaItem;
  @Input() disabled?: boolean;
  @Input() public uploadId?: string;
  @Input() save_files_database = '';
  @Input() typeDocument?: string;
  @Input() upload_when_selected: boolean = true;
  @Output() changed = new EventEmitter<boolean>();
  @Output() selectedFilesChanged = new EventEmitter<FileList>();

  constructor(private upload: MatFileUploadService) {

  }

  async ngOnInit() {
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.refresh();
  }

  async refresh() {
    this.loading = true;
    await this.loadFiles().then(() => this.loading = false);
  }

  loadFiles() {
    return new Promise((resolve, reject) => {
      if ((this.comment?.files?.length ?? 0) > 0) {
        this.attachments = [];
        this.comment?.files?.map(file => {
          const attachment: Attachment = {
            id: file.id,
            fileName: file.fileName,
            fileSize: file.fileSize,
            createdon: file.createdOn,
            SizeKb: utils.numberWithCommas(Math.round(file.fileSize / 1024)) + ' Kb',
            fileExtension: file.fileExtension,
            fileIcon: '../../../assets/icons/files/' + file.fileExtension?.replace('.', '')?.toLocaleLowerCase() + '.png',
            rootDirectory: file.rootDirectory ?? ''
          };
          this.attachments.push(attachment);
        });
        this.attachments.map(a => {
          this.upload.iconCheck('../../../assets/icons/files/' + a.fileExtension?.replace('.', '').toLocaleLowerCase() + '.png').toPromise().then(icon => {
            a.fileIcon = icon ?? '';
          });
        });
        resolve(true);
      }

      else if ((this.radArea?.files?.length ?? 0) > 0) {
        this.attachments = [];
        this.radArea?.files?.map(file => {
          const attachment: Attachment = {
            id: file.id,
            fileName: file.fileName,
            fileSize: file.fileSize,
            createdon: file.createdOn,
            SizeKb: utils.numberWithCommas(Math.round(file.fileSize / 1024)) + ' Kb',
            fileExtension: file.fileExtension,
            fileIcon: '../../../assets/icons/files/' + file.fileExtension?.replace('.', '')?.toLocaleLowerCase() + '.png',
            rootDirectory: file.rootDirectory ?? ''
          };
          this.attachments.push(attachment);
        });
        this.attachments.map(a => {
          this.upload.iconCheck('../../../assets/icons/files/' + a.fileExtension?.replace('.', '').toLocaleLowerCase() + '.png').toPromise().then(icon => {
            a.fileIcon = icon ?? '';
          });
        });
        resolve(true);
      }

      else { resolve(true); }
    });
  }

  refresh_list() {
    this.showupload = false;
  }

  show_upload() {
    this.showupload = true;
  }

  OnDeletedAttachmentFile(deletedId: string): void {
    if (deletedId != '') {
      const filteredCommentFiles = this.comment?.files?.filter(file => file.id != deletedId);
      const filteredRadAreaFiles = this.radArea?.files?.filter(file => file.id != deletedId);
      if (this.comment)
        this.comment.files = filteredCommentFiles;
      else if (this.radArea)
        this.radArea.files = filteredRadAreaFiles;
      this.loadFiles();
      this.changed?.emit(true);
    }
  }

}
interface Attachment {
  id: string;
  fileName: string;
  fileSize: number;
  createdon: Date;
  SizeKb?: string;
  fileExtension: string;
  fileIcon: string;
  rootDirectory?: string;
}
