import { Component, Inject, OnInit, OnDestroy, Injector } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { TemplateType, TemplateRolePermission, TemplateTypeDialogData, ChecklistPriv } from 'src/app/components/checklists/checklists';
import { ProcedureCategory } from 'src/app/components/procedure/models/procedure-category.model';
import { ProcedureType } from 'src/app/components/procedure/models/procedure-type';
import { ScheduleSubtype } from 'src/app/components/schedules/models/schedule-subtype';
import { ScheduleType } from 'src/app/components/schedules/models/schedule-type';
import { DocumentType } from 'src/app/services/documents/documents';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { ResourceType } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ENTER, TAB } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-add-edit-template-type',
  templateUrl: './add-edit-template-type.component.html',
  styleUrls: ['./add-edit-template-type.component.scss']
})
export class AddEditTemplateTypeComponent extends BaseComponent implements OnInit, OnDestroy {

  public get templateTypeNameCtrl(): AbstractControl | null { return this.formGroup.get('templateTypeNameCtrl'); }
  public get templateTypeDescriptionCtrl(): AbstractControl | null { return this.formGroup.get('templateTypeDescriptionCtrl'); }
  public get templateTypeCodeCtrl(): AbstractControl | null { return this.formGroup.get('templateTypeCodeCtrl'); }
  public get createScheduleTabCtrl(): AbstractControl | null { return this.formGroup.get('createScheduleTabCtrl'); }
  public get removeScheduleTabCtrl(): AbstractControl | null { return this.formGroup.get('removeScheduleTabCtrl'); }
  public get resourceTypeCtrl(): AbstractControl | null { return this.formGroup.get('resourceTypeCtrl'); }
  public get scheduleTypeCtrl(): AbstractControl | null { return this.formGroup.get('scheduleTypeCtrl'); }
  public get scheduleSubtypeCtrl(): AbstractControl | null { return this.formGroup.get('scheduleSubtypeCtrl'); }
  public get scheduleResourceTypeCtrl(): AbstractControl | null { return this.formGroup.get('scheduleResourceTypeCtrl'); }
  public get rtToggleCtrl(): AbstractControl | null { return this.formGroup.get('rtToggleCtrl'); }
  public get scToggleCtrl(): AbstractControl | null { return this.formGroup.get('scToggleCtrl'); }
  public get scToggleHideCtrl(): AbstractControl | null { return this.formGroup.get('scToggleHideCtrl'); }
  public get procedureTypeCtrl(): AbstractControl | null { return this.formGroup.get('procedureTypeCtrl'); }
  public get procedureCategoryCtrl(): AbstractControl | null { return this.formGroup.get('procedureCategoryCtrl'); }
  public get prToggleCtrl(): AbstractControl | null { return this.formGroup.get('prToggleCtrl'); }
  public get rxToggleCtrl(): AbstractControl | null { return this.formGroup.get('rxToggleCtrl'); }
  public get aiToggleCtrl(): AbstractControl | null { return this.formGroup.get('aiToggleCtrl'); }
  public get atToggleCtrl(): AbstractControl | null { return this.formGroup.get('atToggleCtrl'); }
  public get roleCtrl(): AbstractControl | null { return this.formGroup.get('roleCtrl'); }
  public get tbToggleCtrl(): AbstractControl | null { return this.formGroup.get('tbToggleCtrl'); }
  public get dtToggleCtrl(): AbstractControl | null { return this.formGroup.get('dtToggleCtrl'); }
  public get mvToggleCtrl(): AbstractControl | null { return this.formGroup.get('mvToggleCtrl'); }

  disableTabButton = false;
  templateType!: TemplateType;
  scheduleType?: ScheduleType;
  scheduleSubtype?: ScheduleSubtype;
  documentType?: DocumentType;
  resourceType?: ResourceType | null;

  resourceTypes!: ResourceType[];
  resourceTypes$!: Observable<ResourceType[]>;
  resourceTypeSubs!: Subscription;

  scheduleTypes!: ScheduleType[];
  scheduleTypes$!: Observable<ScheduleType[]>;
  scheduleTypesSubs!: Subscription;

  scheduleSubtypes?: ScheduleSubtype[];

  scheduleResourceTypes!: ResourceType[];
  resourceTypesFiltered!: ResourceType[];

  procedureTypes!: ProcedureType[];
  procedureTypes$!: Observable<ProcedureType[]>;
  proceureTypesSubs!: Subscription;

  roles!: Role[];
  rolesFiltered!: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  procedureCategories?: ProcedureCategory[];

  rtToggle?: boolean;
  scToggle?: boolean;
  scToggleHide?: boolean;
  prToggle?: boolean;
  rxToggle?: boolean;
  aiToggle?: boolean;
  tbToggle?: boolean;
  dtToggle?: boolean;
  atToggle?: boolean;
  mvToggle?: boolean;

  templateRolePermissions?: TemplateRolePermission[];

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<AddEditTemplateTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public templateTypeData: TemplateTypeDialogData,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.resourceTypeSubs?.unsubscribe();
    this.scheduleTypesSubs?.unsubscribe();
    this.rolesSubs?.unsubscribe();
    this.proceureTypesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit() {
    this.templateType = this.templateTypeData.templateType;
    this.documentType = this.templateTypeData.documentType;
    this.initializeForm();
    this.getProcedures();
    this.getResourceTypes();
    this.getScheduleTypes();
    this.getRoles();
    this.setValues();
  }

  setValues() {
    this.scToggle = this.templateType.scheduleTypeID != null;
    this.scToggleCtrl?.setValue(this.scToggle);
    this.templateType.resourceType = this.resourceTypes.find(r => r.id == this.templateType.resourceTypeID);
    this.rtToggle = this.templateType.resourceType != null;
    this.rtToggleCtrl?.setValue(this.templateType.resourceType);
    this.resourceTypeCtrl?.setValue(this.templateType.resourceTypeID);
    if (this.scheduleType?.tabColumns) {
      this.dtToggle = this.scheduleSubtype?.dateFormat == 'MM/yy' ? true : false;
    }
    else {
      this.dtToggle = this.scheduleType?.dateFormat == 'MM/yy' ? true : false;
    }
    this.dtToggleCtrl?.setValue(this.dtToggle);
    if (this.templateType.scheduleType) {
      this.templateType.resourceType = null;
      this.templateType.resourceTypeID = null;
      this.resourceTypeCtrl?.setValue(null);
      this.resourceTypeCtrl?.disable();
      this.rtToggle = false;
      this.rtToggleCtrl?.setValue(false);
      this.rtToggleCtrl?.disable();
      this.tbToggle = this.templateType.scheduleType?.tabColumns;
      this.tbToggleCtrl?.setValue(this.tbToggle);
    }
    else {
      this.resourceTypeCtrl?.enable();
      this.rtToggleCtrl?.enable();
      this.tbToggle = false;
    }
    this.aiToggle = this.templateType.allowActionItems;
    this.aiToggleCtrl?.setValue(this.aiToggle);
    this.atToggle = this.templateType.allSignaturesRequired;
    this.atToggleCtrl?.setValue(this.atToggle);
    this.mvToggle = this.templateType.allowMultipleVersions;
  }

  add() {
    if (this.formGroup.valid) {
      this.dialogRef.close(this.templateType);
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      templateTypeNameCtrl: [{ value: '' }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      templateTypeDescriptionCtrl: [{ value: '' }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      templateTypeCodeCtrl: [{ value: '' }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      createScheduleTabCtrl: [{ value: false }],
      removeScheduleTabCtrl: [{ value: false }],
      resourceTypeCtrl: [{ value: '' }],
      scheduleTypeCtrl: [{ value: '' }],
      scheduleSubtypeCtrl: [{ value: '' }],
      rtToggleCtrl: [{ value: false }],
      scToggleCtrl: [{ value: false }],
      scToggleHideCtrl: [{ value: false }],
      procedureTypeCtrl: [{ value: '' }],
      prToggleCtrl: [{ value: false }],
      procedureCategoryCtrl: [{ value: '' }],
      rxToggleCtrl: [{ value: false }],
      roleCtrl: [{}],
      aiToggleCtrl: [{ value: false }],
      tbToggleCtrl: [{ value: true }],
      scheduleResourceTypeCtrl: [{ value: '' }],
      dtToggleCtrl: [{ value: false }],
      atToggleCtrl: [{ valur: false }],
      mvToggleCtrl: [{ value: false }]
    });
  }

  override getErrorMsg(control: AbstractControl | null): string | null {
    if (control?.hasError('required')) { return 'You must enter a value'; }
    if (control?.hasError('hasWhiteSpace')) { return 'Enter a valid value'; }
    if (control?.hasError('resourceType')) { return 'You must select a Resource Type'; }
    if (control?.hasError('scheduleType')) { return 'You must select a Schedule Type'; }
    if (control?.hasError('scheduleSubtype')) { return 'You must select a Schedule Subtype'; }
    return null;
  }

  assignTabToSchedules() {
    this.formGroup.controls['createScheduleTab']?.setValue(true);
    this.disableTabButton = true;
  }

  removeTabFromSchedules() {
    if (this.scheduleType?.schedules.length) {
      this.alert.getMessage('Template_Type_Tab_Has_Schedules');
      return;
    }
    this.formGroup.controls['removeScheduleTab']?.setValue(true);
    this.disableTabButton = true;
  }

  getRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      this.roles = data;
      this.rolesFiltered = data;
      this.templateRolePermissions = this.templateType?.templateRolePermissions;
      this.rxToggle = this.templateType?.reviewInExec;
      this.rxToggleCtrl?.setValue(this.rxToggle);
    });
  }

  getProcedures() {
    this.procedureTypes$ = this.store.select(state => state.ProcedureType.data);
    this.proceureTypesSubs = this.procedureTypes$.subscribe(data => {
      this.procedureTypes = data;
    });
  }

  getResourceTypes() {
    this.resourceTypes$ = this.store.select(state => state.ResourceTypes.data);
    this.resourceTypeSubs = this.resourceTypes$.subscribe(data => {
      this.resourceTypes = data;
      this.resourceType = this.templateType.resourceType;
      if (this.resourceType?.id)
        this.resourceTypeCtrl?.setValue(this.resourceType?.id);
      this.rtToggleCtrl?.setValue(this.resourceType);
      this.filterResourceTypes();
    });
  }

  getScheduleTypes() {
    this.scheduleTypes$ = this.store.select(state => state.ScheduleTypes.data);
    this.scheduleTypesSubs = this.scheduleTypes$.subscribe(data => {
      this.scheduleTypes = data.filter(x => x.isActive);
      if (this.templateType.id) {
        this.scheduleType = this.scheduleTypes.find(x => x.id == this.templateType?.scheduleTypeID);
        this.scheduleTypeCtrl?.setValue(this.scheduleType?.id);
        this.scToggle = this.templateType?.scheduleType != null;
        this.scToggleCtrl?.setValue(this.scToggle);
        this.scheduleSubtypes = this.scheduleType?.scheduleSubtypes;
        this.scheduleSubtype = this.scheduleSubtypes?.find(x => x.id == this.templateType.scheduleSubtypeID);
        this.scheduleSubtypeCtrl?.setValue(this.scheduleSubtype?.id);
        this.scToggleHide = !this.templateType?.hideCustomSchedules;
        this.scToggleHideCtrl?.setValue(this.scToggleHide);
        this.prToggle = this.scheduleType?.procedureType != null;
        this.procedureTypeCtrl?.setValue(this.scheduleType?.procedureTypeID);
        this.procedureCategories = this.scheduleType?.procedureType?.procedureCategories;
        if (!this.procedureCategories?.map(x => x.id).includes(0)) {
          this.procedureCategories?.unshift({ id: 0, name: '[All Procedure Categories]', procedureTypeID: this.scheduleType?.procedureTypeID });
        }
        this.procedureCategoryCtrl?.setValue(this.scheduleSubtype ? this.scheduleSubtype.procedureCategoryID : this.scheduleType?.procedureCategoryID ? this.scheduleType?.procedureCategoryID : 0);
      }
      else {
        this.scheduleType = this.scheduleTypes?.find(x => x.documentTypeId == this.documentType?.documentTemplateId);
        this.scheduleTypeCtrl?.setValue(this.scheduleType?.id);
      }
      this.filterResourceTypes();
    });
  }

  filterResourceTypes() {
    let resourceTypeArray = [];
    if (this.scheduleType?.tabColumns) {
      resourceTypeArray = this.scheduleSubtype?.resourceTypeIds ? JSON.parse(this.scheduleSubtype?.resourceTypeIds).map(Number) : new Array();
    }
    else {
      resourceTypeArray = this.scheduleType?.resourceTypeIds ? JSON.parse(this.scheduleType?.resourceTypeIds).map(Number) : new Array();
    }
    this.scheduleResourceTypes = [];
    resourceTypeArray.map((id: number) => {
      const resourceType = this.resourceTypes.find(x => x.id == id);
      if (resourceType)
        this.scheduleResourceTypes.push(resourceType);
    });
    this.resourceTypesFiltered = this.resourceTypes.filter(x => !this.scheduleResourceTypes.map(t => t.id).includes(x.id));
  }

  rtToggleChanged(e: any) {
    if (!e.checked) {
      this.rtToggle = false;
      this.templateType.resourceTypeID = null;
      this.templateType.resourceType = null;
      this.resourceTypeCtrl?.setValue(null);
    }
    else {
      this.rtToggle = true;
      this.rtToggleCtrl?.setValue(this.rtToggle);
      this.templateType.scheduleType = null;
      this.templateType.scheduleTypeID = null;
      this.scheduleTypeCtrl?.setValue(null);
      this.templateType.scheduleSubtype = null;
      this.templateType.scheduleSubtypeID = null;
      this.scheduleSubtypeCtrl?.setValue(null);
    }
  }

  scToggleChanged(e: any) {
    if (!e.checked) {
      this.templateType.scheduleType = null;
      this.templateType.scheduleTypeID = null;
      this.scheduleTypeCtrl?.setValue(null);
      this.templateType.scheduleSubtype = null;
      this.templateType.scheduleSubtypeID = null;
      this.scheduleSubtypeCtrl?.setValue(null);
      this.rtToggleCtrl?.enable();
      this.resourceTypeCtrl?.enable();
    }
    else {
      this.scheduleType = this.scheduleTypes?.find(x => x.documentTypeId == this.documentType?.documentTemplateId);
      if (this.scheduleType) {
        this.scheduleSubtypes = this.scheduleType?.scheduleSubtypes;
        this.scheduleTypeCtrl?.setValue(this.scheduleType.id);
      }
      this.templateType.resourceType = null;
      this.templateType.resourceTypeID = null;
      this.templateType.scheduleType = this.scheduleType;
      this.resourceTypeCtrl?.setValue(null);
      this.rtToggleCtrl?.disable();
      this.resourceTypeCtrl?.disable();
    }
  }

  scToggleHideChanged(e: any) {
    this.templateType.hideCustomSchedules = !e.checked;
  }

  prToggleChanged(e: any) {
    if (!e.checked && this.scheduleType) {
      this.scheduleType.procedureTypeID = null;
      this.scheduleType.procedureCategoryID = null;
    }
    this.templateType.scheduleType = this.scheduleType;
  }

  rxToggleChanged(e: any) {
    this.templateType.reviewInExec = e.checked;
  }

  dtToggleChanged(e: any) {
    if (this.scheduleType?.tabColumns) {
      if (this.scheduleSubtype) {
        this.scheduleSubtype.dateFormat = e.checked ? 'MM/yy' : 'shortDate';
      }
    }
    else {
      if (this.scheduleType)
        this.scheduleType.dateFormat = e.checked ? 'MM/yy' : 'shortDate';
    }
  }

  removeRole(templateRolePermission: TemplateRolePermission) {
    this.templateRolePermissions?.splice(this.templateRolePermissions.findIndex(x => x.roleID == templateRolePermission.roleID), 1);
    this.templateType.templateRolePermissions = this.templateRolePermissions;
  }

  selectedRole(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;
    const templateRolePermission: TemplateRolePermission = {
      id: 0,
      role: value,
      roleID: value.id,
      name: ChecklistPriv[12],
      checklistPrivilege: ChecklistPriv.ReviewChecklist,
      templateTypeID: this.templateType != undefined ? this.templateType.id : 0,
      documentTypeID: this.documentType?.id,
      status: 1,
      type: this.templateType != undefined ? this.templateType.type : 0
    };
    this.templateRolePermissions?.push(templateRolePermission);
    this.templateType.templateRolePermissions = this.templateRolePermissions;
    this.roleCtrl?.setValue(null);
  }

  onRoleChange(e: any) {
    const value = e.target.value;
    this.rolesFiltered = this.roles.filter(x => !this.templateRolePermissions?.map(a => a.roleID).includes(x.id)).filter(x => x.name?.toLowerCase().includes(value.toLowerCase()) || x.code?.toLowerCase().includes(value.toLowerCase()));
  }

  aiToggleChanged(e: any) {
    this.templateType.allowActionItems = e.checked;
  }

  atToggleChanged(e: any) {
    this.templateType.allSignaturesRequired = e.checked;
  }

  mvToggleChanged(e: any) {
    this.templateType.allowMultipleVersions = e.checked;
  }

  tbToggleChanged(e: any) {
    if (this.templateType.scheduleType) {
      this.templateType.scheduleType.tabColumns = e.checked;
      this.scheduleType = this.templateType.scheduleType;
    }
    this.filterResourceTypes();
  }

  removeResourceType(resourceType: ResourceType) {
    this.scheduleResourceTypes.splice(this.scheduleResourceTypes.findIndex(x => x.id == resourceType.id), 1);
    if (this.scheduleType?.tabColumns && this.scheduleSubtype) {
      this.scheduleSubtype.resourceTypeIds = this.utils.JSONstringify(this.scheduleResourceTypes.map(x => x.id));
    }
    else {
      if (this.scheduleType)
        this.scheduleType.resourceTypeIds = this.utils.JSONstringify(this.scheduleResourceTypes.map(x => x.id));
    }
  }

  selectedScheduleResource(e: any) {
    if (!this.scheduleResourceTypes) {
      this.scheduleResourceTypes = [];
    }
    this.scheduleResourceTypes.push(e.option.value);
    if (this.scheduleType?.tabColumns) {
      if (this.scheduleSubtype) {
        this.scheduleSubtype.resourceTypeIds = this.utils.JSONstringify(this.scheduleResourceTypes.map(x => x.id));
        this.scheduleType.resourceTypeIds = null;
      }
    }
    else {
      if (this.scheduleType)
        this.scheduleType.resourceTypeIds = this.utils.JSONstringify(this.scheduleResourceTypes.map(x => x.id));
    }
  }

  onResourceTypeChange(e: any) {
    this.resourceType = this.resourceTypes.find(x => x.id == e.value);
    this.templateType.resourceType = this.resourceType;
    this.templateType.resourceTypeID = this.resourceType?.id;
    this.templateType.scheduleType = null;
    this.templateType.scheduleSubtype = null;
  }

  onScheduleTypeChange(e: any) {
    this.scheduleType = this.scheduleTypes.find(x => x.id == e.value);
    this.templateType.scheduleType = this.scheduleType;
    this.templateType.scheduleTypeID = this.scheduleType?.id;
    this.scheduleSubtypes = this.scheduleType?.scheduleSubtypes;
    this.templateType.scheduleSubtype = null;
    this.templateType.scheduleSubtypeID = null;
    this.filterResourceTypes();
  }

  onScheduleSubtypeChange(e: any) {
    this.scheduleSubtype = this.scheduleSubtypes?.find(x => x.id == e.value);
    this.templateType.scheduleSubtype = this.scheduleSubtype;
    this.templateType.scheduleSubtypeID = this.scheduleSubtype?.id;
    this.filterResourceTypes();
  }

  onProcedureTypeChange(e: any) {
    this.procedureCategories = this.procedureTypes.find(x => x.id == e.value)?.procedureCategories;
    if (!this.procedureCategories?.map(x => x.id).includes(0)) {
      this.procedureCategories?.unshift({ id: 0, name: '[All Procedure Categories]', procedureTypeID: e.value });
    }
    if (this.scheduleSubtype && this.scheduleType) {
      this.scheduleSubtype.procedureType = this.procedureTypes.find(x => x.id == e.value);
      this.scheduleSubtype.procedureTypeID = e.value;
      this.scheduleType.procedureType = null;
      this.scheduleType.procedureTypeID = null;
    }
    else {
      if (this.scheduleType) {
        this.scheduleType.procedureType = this.procedureTypes.find(x => x.id == e.value);
        this.scheduleType.procedureTypeID = e.value;
      }
    }
  }

  onProcedureCategoryChange(e: any) {
    const id = e.value;
    const category = e.value == 0 ? null : this.procedureCategories?.find(x => x.id == id);
    if (this.scheduleType)
      if (this.scheduleSubtype) {
        this.scheduleSubtype.procedureCategory = category;
        this.scheduleSubtype.procedureCategoryID = id;
        this.scheduleType.procedureCategory = null;
        this.scheduleType.procedureCategoryID = null;
      }
      else {
        this.scheduleType.procedureCategory = category;
        this.scheduleType.procedureCategoryID = id;
      }
    this.templateType.scheduleType = this.scheduleType;
    this.templateType.scheduleSubtypeID = this.scheduleType?.id;
    this.templateType.scheduleSubtype = this.scheduleSubtype;
    this.templateType.scheduleSubtypeID = this.scheduleSubtype?.id;
  }
}
