<div class="d-flex justify-content-between">
  <div class="d-flex">
    <button
      class="mr-2"
      mat-flat-button
      [disabled]="disabled"
      [color]="btnColor"
      (click)="openOlog()"
    >
      {{ btnName }}
    </button>
    <role-chips [roleCodes]="roleCodes"></role-chips>
  </div>
  <div class="d-flex align-items-center">
    <mat-slide-toggle
      [(ngModel)]="isChecked"
      [disabled]="disabled ?? false"
      color="primary"
      labelPosition="before"
      (ngModelChange)="toggleChanged()"
    >
      <span [innerHTML]="toggleLabel" class="mr-2"></span
      ><span [ngClass]="{ 'bold red': !isChecked, gray: isChecked }"
        >NO</span
      ></mat-slide-toggle
    ><span class="ml-1" [ngClass]="{ 'bold blue': isChecked, gray: !isChecked }"
      >YES</span
    >
  </div>
</div>
