import { Component } from '@angular/core';
import { ClBaseComponent } from '../cl-base/cl-base.component';

@Component({
  selector: 'cl-checkbox',
  templateUrl: './cl-checkbox.component.html',
  styleUrls: ['./cl-checkbox.component.scss']
})

export class ClCheckboxComponent extends ClBaseComponent {

  checkboxChanged(e: any) {
    this.checked = e.checked;
    this.user = this.checked ? this.getCurrentUser() : null;
    this.answered.emit(this.checked);
  }

}
