import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ResourceType } from 'src/app/common/enumerations/enumerations';
import { Resource, ResourceRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { CatalogService } from '../../../catalog-service';


@Component({
  selector: 'app-shielding',
  templateUrl: './shielding.component.html',
  styleUrls: ['./shielding.component.scss']
})
export class ShieldingComponent extends BaseComponent implements OnInit, OnDestroy {

  public shieldings!: Resource[];
  public shieldingsFiltered!: Resource[];

  public shieldingId!: number | null;

  public shieldingName?: string | null;
  public shieldingKeText?: string | null;
  public shieldingSharedWith?: string | null;
  public oldShielding?: string | null;

  public loading = true;
  public checkIsRepeated = true;
  public isSaving!: boolean;

  resources!: Resource[];
  resourcesFiltered!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  resourceRelations?: ResourceRelation[];
  resourceRelationsFiltered?: ResourceRelation[];
  resourceRelations$!: Observable<ResourceRelation[]>;
  resourceRelationsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<ShieldingComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: {
      resource: Resource,
      shutter: Resource
    },
    private catalogService: CatalogService,
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.loadResources();
    this.loadResourceRelations();
    this.shieldingId = this.inputData.resource.id;
    this.shieldingName = this.inputData.resource.name;
    this.shieldingKeText = this.inputData.resource.keText;
    this.shieldingSharedWith = this.inputData.resource.sharedWith;
    this.oldShielding = this.inputData.resource.name;
    if (this.shieldingName) {
      this.filter(this.shieldingName);
    }
    this.loading = false;
  }

  override ngOnDestroy(): void {
    this.resourcesSubs?.unsubscribe();
    this.resourceRelationsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data.length) {
        this.resources = data;
        this.shieldings = this.resources.filter(x => x.type == ResourceType.Shielding);
        this.shieldingsFiltered = this.shieldings;
      }
    });
  }

  loadResourceRelations() {
    this.resourceRelations$ = this.store.select(state => state.ResourceRelations.data);
    this.resourceRelationsSubs = this.resourceRelations$.subscribe(data => {
      if (data?.length) {
        this.resourceRelations = data;
      }
    });
  }

  async add() {
    this.isSaving = true;
    if (await this.isValid()) {
      this.inputData.resource.id = this.shieldingId ?? 0;
      this.inputData.resource.name = this.shieldingName;
      this.inputData.resource.keText = this.shieldingKeText;
      this.inputData.resource.sharedWith = this.shieldingSharedWith;
      this.dialogRef.close(this.inputData.resource);
    } else {
      this.isSaving = false;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  async isValid() {
    if (!this.shieldingName) {
      this.alert.message('shieldingDialog_Name_Required');
      return false;
    }
    if (!this.shieldingKeText) {
      this.alert.message('shieldingDialog_KEText_Required');
      return false;
    }
    if (this.oldShielding !== this.shieldingName && this.checkIsRepeated) {
      const isRepeated = await this.catalogService.IsFieldRepeatedResource(this.shieldingName, ResourceType.Shielding).toPromise();
      if (isRepeated) {
        this.alert.message('shieldingDialog_Repeated');
        return false;
      }
    }
    return true;
  }

  getInfoShielding(shielding: Resource) {
    this.resourceRelationsFiltered = this.resourceRelations?.filter(r => r.childResourceID == shielding.id);
    this.shieldingSharedWith = this.resourceRelationsFiltered?.find(x => x.parentResourceType === ResourceType.Shutter && x.parentResourceID !== this.inputData.shutter?.id)?.parentResourceName;
    this.shieldingKeText = shielding.keText;
    this.shieldingId = shielding.id;
    this.checkIsRepeated = false;
  }

  filter(e: any) {
    const text = e.target?.value;
    const code = e.code;
    if (!code?.includes('Arrow')) {
      this.shieldingsFiltered = this.shieldings?.filter(x => x.name?.toLowerCase().includes(text?.trim().toLowerCase()));
    }
  }

}
