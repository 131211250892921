import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileV2Service {
  private url: string = environment.urls.baseApiUrl + '/FileV2';

  constructor(
    private http: HttpClient,
  ) { }

  createFiles(data: FormData) {
    const result = this.http.post(environment.urls.baseApiUrl + '/FileV2', data)
      .pipe(
        catchError(this.handleError)
      );

    return result;
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(this.url + '/' + id.toString());
  }

  obtainFile(id: number): Observable<any> {
    return this.http.get<any>(this.url + '/' + id.toString());
  }

  private handleError(error: any): Promise<any> {

    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
