import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {
  // linkExp = /((s?ftp|https?):\/\/|(www\.)|(mailto:)?[A-Za-z0-9._%+-]+@)\S*[^\s.;,(){}<>"\u201d\u2019]/i;
  linkExp = /(?!(https:\/\/ascc-docs\.als\.lbl\.gov))((s?ftp|https?):\/\/|(www\.)|(mailto:)?[A-Za-z0-9._%+-]+@)\S*[^\s.;,(){}<>"\u201d\u2019]/i;

  mailExp = /^mailto:/i;
  transform(value: string): string {
    return this.getHTMLText(value);
  }

  parseTextHTML = (text: string, isHTML: boolean, options: any) => {
    if (typeof text !== 'string') { return null; }
    if (text === null || text === '') { return text; }
    let rawText = text;
    const html = [];
    const URLS = [];
    let match;
    let i;
    let url;
    // tslint:disable-next-line: no-conditional-assignment
    while ((match = rawText.match(this.linkExp))) {
      [url] = match;
      if (!match[2] && !match[4]) {
        url = (match[3] ? 'http://' : 'mailto:') + url;
      }
      i = match.index;
      if (rawText.substr(0, i)) {
        html.push(rawText.substr(0, i));
      }
      html.push('<a ');
      if (options) {
        Object.keys(options).map((key) => {
          html.push(`${key}="${options[key]}" `);
        });
      }
      html.push('href="');
      URLS.push(url.replace(/"/g, '&quot;'));
      html.push(url.replace(/"/g, '&quot;'));
      html.push('">');
      if (match[0].replace(this.mailExp, '')) {
        html.push(match[0].replace(this.mailExp, ''));
      }
      html.push('</a>');
      if (i)
        rawText = rawText.substring(i + match[0].length);
    }
    if (rawText) {
      html.push(rawText);
    }
    return isHTML ? html.join('') : URLS;
  }

  getHTMLText(text: string, options: any = null): string {
    return this.parseTextHTML(text, true, options) as string;
  }
}
