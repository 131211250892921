<div
  class="card full-width"
  [ngClass]="{ 'card-deleted': step.logID && !isDeletedSection }"
>
  <div class="card-header">
    <div class="row d-flex align-items-center">
      <div class="col d-flex">
        <div class="d-flex">
          <h5
            *ngIf="!editing"
            [ngClass]="{
              'step-name': !disabled,
              'step-name-nohover': disabled
            }"
            (click)="edit()"
          >
            {{ step.name }}
          </h5>
          <h5 *ngIf="editing" class="input-sizer stacked">
            <textarea
              aria-label="text"
              matInput
              onmouseover="this.parentNode.dataset.value = this.value"
              onfocus="this.parentNode.dataset.value = this.value"
              oninput="this.parentNode.dataset.value = this.value"
              (change)="save()"
              rows="1"
              [(ngModel)]="step.name"
              appTextareaAutoresize
              style="overflow: hidden; height: 100%"
            ></textarea>
          </h5>
        </div>
      </div>
      <div class="col d-flex small">
        <mat-checkbox
          color="primary"
          [disabled]="disabled"
          [(ngModel)]="configuration.duplicate"
          (change)="configurationChanged()"
          >Allow Duplicates</mat-checkbox
        >
        <checklist-roles-chips
          *ngIf="configuration.duplicate"
          [configuration]="configuration"
          [disabled]="disabled"
          (configurationChanged)="configurationChanged()"
        ></checklist-roles-chips>
      </div>
      <div class="col d-flex justify-content-between">
        <div class="d-flex flex-column">
          <mat-slide-toggle
            [(ngModel)]="step.collapsible"
            [disabled]="disabled"
            class="small"
            (change)="save()"
            >Collapsible Step</mat-slide-toggle
          >
          <mat-slide-toggle
            class="small"
            *ngIf="step.collapsible"
            [(ngModel)]="step.collapsed"
            [disabled]="disabled"
            (change)="save()"
            >Collapsed by default</mat-slide-toggle
          >
        </div>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <div *ngIf="!enabledOn || enabledOn?.length == 0">
          <span class="red-background pill"
            >Error:&nbsp;<b>No Status Set</b>&nbsp;
            <mat-icon>warning</mat-icon>
          </span>
        </div>
        <div *ngIf="statusError">
          <span
            class="red-background pill"
            matTooltip="Please Uncheck and Check every box to Re-Create Statuses!"
            >Error: <b>Wrong status settings!</b>
            <mat-icon>warning</mat-icon>
          </span>
        </div>
        <div class="d-flex flex-column align-items-end">
          <div
            class="no-wrap d-flex small"
            *ngIf="enabledOn && enabledOn?.length != 0 && !statusError"
          >
            <mat-icon class="icon-check" matTooltip="No Errors!"
              >lightbulb</mat-icon
            >
            <span
              class="pill"
              [ngClass]="getClass(item)"
              [matTooltip]="item?.description ?? ''"
              *ngFor="let item of enabledOn"
              >{{ item?.code }}</span
            >
          </div>
          <div
            class="no-wrap d-flex small"
            *ngIf="hiddenOn && hiddenOn?.length != 0 && !statusError"
          >
            <mat-icon class="icon-hide">visibility_off</mat-icon>
            <span
              class="pill"
              [ngClass]="getClass(item)"
              *ngFor="let item of hiddenOn"
              >{{ item?.code }}</span
            >
          </div>
        </div>
        <div class="ml-2 no-wrap" *ngIf="!editing && !step.logID">
          <button
            mat-icon-button
            (click)="settings(true)"
            matTooltip="Step Settings"
            color="accent"
          >
            <mat-icon>settings</mat-icon>
          </button>
          <button
            mat-icon-button
            *ngIf="!disabled"
            (click)="duplicate()"
            color="primary"
            matTooltip="Duplicate Step"
          >
            <mat-icon>content_copy</mat-icon>
          </button>
          <button
            mat-icon-button
            *ngIf="!disabled"
            color="warn"
            (click)="delete()"
            matTooltip="Delete Step"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <div class="relative">
            <div
              class="card shadow settings-details"
              *ngIf="displaySettings"
              id="stepConfig"
            >
              <checklist-builder-step-config
                [step]="step"
                (statusChanged)="saveStatus($event)"
                [currentVersion]="currentVersion"
                [disabled]="disabled"
              >
              </checklist-builder-step-config>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <checklist-builder-tasks
        [tasks]="wfTaskLocals ?? []"
        [wfStepLocalID]="step.id"
        [disabled]="disabled"
        [currentVersion]="currentVersion"
        [stepDropLists]="stepDropLists"
        [procedure]="procedure"
        [showDeleted]="showDeleted"
        [isDeletedStep]="(step.logID ?? 0) > 0"
        (loading)="setLoading($event)"
        (refreshPreviousStep)="refreshPreviousStep.emit($event)"
      >
      </checklist-builder-tasks>
    </div>
  </div>
</div>
