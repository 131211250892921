<div
  class="mat-container"
  [ngClass]="{
    hilite
  }"
>
  <mat-table #table [dataSource]="dataSourceResourceUserRelation">
    <ng-container matColumnDef="user">
      <mat-header-cell *matHeaderCellDef> {{ userTitle }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.user?.name }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="endstation">
      <mat-header-cell *matHeaderCellDef> Endstation </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.joinEndstations.join(", ") }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="branchline">
      <mat-header-cell *matHeaderCellDef> Branchline </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.joinBranchlines.join(", ") }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="add">
      <mat-header-cell *matHeaderCellDef>
        <mat-icon
          mat-list-icon
          class="add"
          (click)="openAddResourceUserRelation()"
          >add</mat-icon
        >
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let elementIndex = index">
        <mat-icon
          mat-list-icon
          color="accent"
          (click)="openEditResourceUserRelation(element, elementIndex)"
          >edit</mat-icon
        >
        <mat-icon
          mat-list-icon
          color="warn"
          (click)="deleteResourceUserRelatio(element)"
          >delete</mat-icon
        >
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="displayedColumnsResourceUserRelation"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumnsResourceUserRelation"
      [ngClass]="{
        'background-yellow': row.isModified
      }"
    ></mat-row>
  </mat-table>
</div>
