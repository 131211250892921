import { AfterViewInit, Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Catalog } from 'src/app/common/enumerations/enumerations';
import { CatalogService } from 'src/app/components/catalogs/catalog-service';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { PendingCatalog, PendingCatalogType } from 'src/app/services/pending-approvals/pending-approval';

@Component({
  selector: 'pending-approvals-changes-table',
  templateUrl: './pending-approvals-changes-table.component.html',
  styleUrls: ['./pending-approvals-changes-table.component.scss']
})
export class PendingApprovalsChangesTableComponent extends BaseComponent implements AfterViewInit, OnChanges {

  @Input() dataSource!: MatTableDataSource<PendingCatalog>;
  // @Input() pendingCatalog?: PendingCatalogType;

  public displayedColumns: string[] = ['name', 'statusName', 'actionName', 'createdOn', 'disapprovalReason', 'remove'];
  @ViewChild('sort', { static: true }) sort!: MatSort | null;

  showRemoveAll?: boolean;
  // catalog?: Catalog;
  route?: string;
  header?: string;

  constructor(
    protected override injector: Injector,
    private _catalogService: CatalogService,
    private router: Router,
  ) {
    super(injector);
    this
  }

  ngAfterViewInit(): void {
    if (this.dataSource && this.sort)
      this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngAfterViewInit();
    this.showRemoveAll = this.dataSource.data.some(x => x.status == 3 && x.pendingID && x.catalogType < 10);
    this.setParams();
  }

  removeAll() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { message: 'Are you sure you want to remove All elements from the list?' };
    const dialogRef = this.dialog.open(YesNoDialogComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then(async (data) => {
      if (data) {
        this.dataSource.data.filter(x => x.status == 3).map(async x => await this._catalogService.RemoveRow(x?.pendingID, x.catalogType).toPromise());
      }
    });
  }

  removeRow(e: MouseEvent, pendingElement: PendingCatalog) {
    e.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { message: 'Are you sure you want to remove this element from the list?' };
    const dialogRef = this.dialog.open(YesNoDialogComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then(async (data) => {
      if (data) {
        await this._catalogService.RemoveRow(pendingElement?.pendingID, pendingElement.catalogType).toPromise();
      }
    });
  }

  setItem(pendingCatalog: PendingCatalog) {
    this._catalogService.pendingCatalog = pendingCatalog;
    if (!this.route)
      this.route = '/redirect/' + pendingCatalog.name;
    this.router.navigate([this.route]);
  }

  setParams() {
    switch (this.dataSource.data?.[0]?.catalogType) {
      case PendingCatalogType.Accelerator:
        this.route = '/accelerator-catalog';
        this.header = 'Accelerator Catalog';
        break;
      case PendingCatalogType.Beamline:
        this.route = '/resources-new';
        this.header = 'Beamline Catalog';
        break;
      case PendingCatalogType.User:
        this.route = '/users';
        this.header = 'User Catalog';
        break;
      case PendingCatalogType.Role:
        this.route = '/roles';
        this.header = 'Role Catalog';
        break;
      case PendingCatalogType.NotificationTemplate:
        this.route = '/email-notification-catalog';
        this.header = 'Notification Templates Catalog';
        break;
      case PendingCatalogType.RSSDb:
        this.route = '/resources-new?tab=2';
        this.header = 'RSS Database';
        break;
      case PendingCatalogType.ChecklistTemplate:
      case PendingCatalogType.KET:
      case PendingCatalogType.ABSI:
        this.header = 'Checklist Templates';
        break;

    }
  }
}
