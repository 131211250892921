import { RSSWAResource } from "../rsswa";

export class RSSWARefresh {
  static readonly type = '[RSSWAResource] Refresh';
}
export class RSSWACreate {
  static readonly type = '[RSSWAResource] Create';
  constructor(public item: RSSWAResource) { }
}
export class RSSWAUpdate {
  static readonly type = '[RSSWAResource] Update';
  constructor(public id: number, public item: RSSWAResource) { }
}
export class RSSWADelete {
  static readonly type = '[RSSWAResource] Delete';
  constructor(public id: number) { }
}

export class RSSWARefreshByID {
  static readonly type = '[RSSWAResource] RefreshByID';
  constructor(public id: number) { }
}
