import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';

import { APLGroupRefreshByAPLCategoryMasterID, APLGroupCreate, APLGroupUpdate, APLGroupRefresh, APLGroupAllPendingRefresh, APLGroupDelete } from './apl-group.action';
import { APLGroup } from 'src/app/components/apl/services/apl-group.model';
import { APLGroupService } from '../../apl-group.service';

class APLGroupStateModel {
  all: APLGroup[] = [];
  allPendingGlobal: APLGroup[] = [];
  allPending: APLGroup[] = [];
  loading: boolean = false;
}

@State<APLGroupStateModel>({
  name: 'APLGroup',
  defaults: {
    allPendingGlobal: [],
    all: [],
    allPending: [],
    loading: false,
  },
})
@Injectable()
export class APLGroupState {
  constructor(
    private aplGroupService: APLGroupService,
  ) { }

  @Action(APLGroupAllPendingRefresh)
  refreshAllPending({ patchState }: StateContext<APLGroupStateModel>, { }: APLGroupAllPendingRefresh) {
    this.aplGroupService.readAllPending().subscribe(
      data => patchState({ allPendingGlobal: data }),
      error => console.error(error),
    );
  }

  @Action(APLGroupRefresh)
  refresh({ patchState }: StateContext<APLGroupStateModel>, { }: APLGroupRefresh) {
    this.aplGroupService.read().subscribe(
      data => patchState({ all: data }),
      error => console.error(error),
    );
  }

  @Action(APLGroupRefreshByAPLCategoryMasterID)
  refreshByAPLCategoryMasterID({ patchState }: StateContext<APLGroupStateModel>, { id }: APLGroupRefreshByAPLCategoryMasterID) {
    patchState({ loading: true });

    this.aplGroupService.readPendingByAPLCategoryMasterID(id).subscribe(
      data => patchState({ allPending: data }),
      error => console.error(error),
      () => patchState({ loading: false }),
    );
  }

  // @Action(APLGroupCreate)
  // create({ }: StateContext<APLGroupStateModel>, { item }: APLGroupCreate) {
  //     this.store.dispatch(new APLGroupAllPendingRefresh());
  //     this.store.dispatch(new APLGroupRefresh());
  //     this.store.dispatch(new APLGroupRefreshByAPLCategoryMasterID(item.aplGroupMaster.aplCategoryMasterID));
  // }

  // @Action(APLGroupUpdate)
  // update({ }: StateContext<APLGroupStateModel>, { id, item }: APLGroupUpdate) {
  //     this.store.dispatch(new APLGroupAllPendingRefresh());
  //     this.store.dispatch(new APLGroupRefresh());
  //     this.store.dispatch(new APLGroupRefreshByAPLCategoryMasterID(item.aplGroupMaster.aplCategoryMasterID));
  // }
  @Action(APLGroupCreate)
  create({ getState, patchState }: StateContext<APLGroupStateModel>, { item }: APLGroupCreate) {
    patchState({
      all: [...getState().all, item]
    });
  }

  @Action(APLGroupUpdate)
  update({ getState, patchState }: StateContext<APLGroupStateModel>, { id, item }: APLGroupUpdate) {
    const state = getState();
    const index = state.all.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.all.splice(index, 1);
      patchState({
        all: [...state.all, item]
      });
    }
  }

  @Action(APLGroupDelete)
  delete({ getState, patchState }: StateContext<APLGroupStateModel>, { id }: APLGroupDelete) {
    patchState({
      all: getState().all.filter(x => x.id != id)
    });
  }
}
