<div class="container-fluid mt-2">
  <form [formGroup]="formGroup" (keydown.enter)="$event.preventDefault()">
    <div class="row">
      <div class="col-md-12 col-lg-8">
        <h5 id="Section1Title">Description of Work</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-lg-3">
        <mat-form-field
          [ngClass]="{
            hilite:
              rsswa && service.showAmendment && tmpStartDate != rsswa.startOn
          }"
        >
          <mat-label>Estimate Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="startDate"
            placeholder="Estimate Start Date"
            name="startDate"
            [(ngModel)]="tmpStartDate"
            (dateChange)="addEvent()"
            formControlName="estInitCtrl"
          />
          <mat-datepicker-toggle
            class="d-print-none"
            matSuffix
            [for]="startDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
          <mat-error *ngIf="estInitCtrl?.invalid">{{
            getErrorMsg(estInitCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-6 col-lg-3">
        <mat-form-field
          [ngClass]="{
            hilite:
              rsswa && service.showAmendment && tmpEndDate != rsswa.completeOn
          }"
        >
          <mat-label>Estimate Completion Date</mat-label>
          <input
            matInput
            [matDatepicker]="endDate"
            [min]="tmpStartDate"
            placeholder="Estimate Completion Date"
            name="endDate"
            [(ngModel)]="tmpEndDate"
            formControlName="estEndCtrl"
          />
          <mat-datepicker-toggle
            class="d-print-none"
            matSuffix
            [for]="endDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
          <mat-error *ngIf="estEndCtrl?.invalid">{{
            getErrorMsg(estEndCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12 col-lg-6">
        <mat-form-field
          [ngClass]="{
            hilite:
              rsswa &&
              service.showAmendment &&
              tmpRequesterName != rsswa.requesterName
          }"
        >
          <mat-label>Requester</mat-label>
          <input
            matInput
            placeholder="Requester"
            aria-label="Requester"
            [matAutocomplete]="reqAuto"
            formControlName="requesterCtrl"
            [(ngModel)]="tmpRequesterName"
            name="Requester"
            (keyup)="onReqChange($event.code)"
            #trigger="matAutocompleteTrigger"
          />
          <mat-autocomplete
            #reqAuto="matAutocomplete"
            (optionSelected)="selectedReq($event)"
            (closed)="onClosedReq()"
          >
            <mat-option
              [value]="user.name"
              *ngFor="let user of filteredRequesters"
              >{{ user.name }}</mat-option
            >
          </mat-autocomplete>
          <mat-error *ngIf="requesterCtrl?.invalid">{{
            getErrorMsg(requesterCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <!-- Related Documents -->
        <related-documents
          [relatedDocuments]="documents"
          [formID]="rsswa?.id"
          [formType]="formTypeEnum.RSSWA"
          [disabled]="disabled"
          (changed)="documents = $event; setFormDirty()"
          [hilite]="diffRelatedDocuments()"
          [control]="documentCtrl"
        ></related-documents>
      </div>

      <div class="col-12 col-lg-4">
        <mat-form-field
          appearance="outline"
          [ngClass]="{
            hilite: rsswa && service.showAmendment && diffWorkPerformers()
          }"
        >
          <mat-label>Group(s) Performing Work</mat-label>
          <mat-chip-grid
            #chipListPer
            aria-label="Workers selection"
            [formControl]="performerCtrl"
          >
            <mat-chip-row
              *ngFor="let performer of workPerformers"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="removePer(performer)"
            >
              {{ performer.name + " (" + performer.code + ")" }}
              <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
            </mat-chip-row>
            <input
              placeholder="Group(s) / Person(s) Performing Work"
              #performerInput
              [matAutocomplete]="autoPer"
              [matChipInputFor]="chipListPer"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="addPer($event)"
              (keyup)="onPerChange($event.code)"
            />
          </mat-chip-grid>
          <mat-autocomplete
            #autoPer="matAutocomplete"
            (optionSelected)="selectedPer($event)"
          >
            <mat-optgroup
              *ngFor="let group of filteredPerformerGroups"
              [label]="group.name"
            >
              <mat-option
                *ngFor="let performer of group.workPerformers"
                [value]="performer"
              >
                {{ performer.name + " (" + performer.code + ")" }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
          <mat-error *ngIf="performerCtrl?.invalid">{{
            getErrorMsg(performerCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4"></div>
    </div>

    <fieldset
      class="field-group"
      [disabled]="disabled"
      [ngClass]="{
        disabled: disabled,
        hilite: rsswa && service.showAmendment && rsswa.beamLine != tmpBeamLine,
        invalid: systemsAffectedError
      }"
    >
      <legend>Systems Affected</legend>
      <div class="row d-flex justify-content-between">
        <div class="d-flex flex-column col-3">
          <mat-checkbox
            [ngClass]="{
              hilite:
                rsswa && service.showAmendment && rsswa.beamLine != tmpBeamLine
            }"
            [(ngModel)]="tmpBeamLine"
            formControlName="chkBeamlineCtrl"
            (change)="systemChanged()"
            class="d-block mb-1"
            >Beamline</mat-checkbox
          >
          <resource-chips
            [selectedResources]="getResources(tmpRsswaShutters)"
            [resourceTypes]="[
              resourceTypeEnum.Shutter,
              resourceTypeEnum.Branchline
            ]"
            [placeholder]="'Beamline Locations'"
            [disabled]="!(!disabled && tmpBeamLine)"
            [control]="rsswaShuttersControl"
            (onEdit)="onRsswaShuttersEdited($event)"
            (onError)="showAlert($event)"
            [hilite]="diffShutters()"
          >
          </resource-chips>
        </div>
        <div class="d-flex flex-column col-3">
          <mat-checkbox
            [ngClass]="{
              hilite:
                rsswa &&
                service.showAmendment &&
                rsswa.accelerator != tmpAccelerator
            }"
            [(ngModel)]="tmpAccelerator"
            formControlName="chkAcceleratorCtrl"
            (change)="systemChanged()"
            class="d-block mb-1"
            >Accelerator</mat-checkbox
          >
          <resource-chips
            [selectedResources]="getResources(tmpRsswaAcceleratorLocations)"
            [resourceTypes]="[
              resourceTypeEnum.Accelerator,
              resourceTypeEnum.AcceleratorLocation
            ]"
            [placeholder]="'Accelerator Locations'"
            [disabled]="!(!disabled && tmpAccelerator)"
            [control]="rsswaAcceleratorLocationsControl"
            (onEdit)="onRsswaAcceleratorLocationsEdited($event)"
            (onError)="showAlert($event)"
            [hilite]="diffAccelerators()"
          ></resource-chips>
        </div>
        <div class="d-flex flex-column col-3">
          <mat-checkbox
            [ngClass]="{
              hilite:
                rsswa && service.showAmendment && rsswa.topOff != tmpTopOff
            }"
            [(ngModel)]="tmpTopOff"
            formControlName="chkTopOffCtrl"
            (change)="systemChanged()"
            class="d-block mb-1"
            >Top-off</mat-checkbox
          >
          <rsswa-other-system-chips
            [placeholder]="'Other systems'"
            [disabled]="disabled"
            [control]="rsswaOtherSystemsControl"
            [rsswaId]="rsswa?.id"
            [rsswaOtherSystems]="tmpRsswaOtherSystems"
            (onEdit)="onRsswaOtherSystemsEdited($event)"
            [hilite]="diffOtherSystems()"
          >
          </rsswa-other-system-chips>
        </div>
        <div class="d-flex flex-column col-3">
          <mat-checkbox
            [ngClass]="{
              hilite:
                rsswa &&
                service.showAmendment &&
                rsswa.radiationMonitoringSystem != tmpRadiationMonitoringSystem
            }"
            [(ngModel)]="tmpRadiationMonitoringSystem"
            formControlName="chkRadMonitorCtrl"
            (change)="systemChanged()"
            class="d-block mb-1"
            >Radiation Monitoring System</mat-checkbox
          >
        </div>
      </div>
    </fieldset>

    <div class="row py-2 px-3">
      <div class="col-md-6 col-lg-4 col-xl-2 p-1">
        <div class="check-card check-disabled" id="tmpChkRPLCSet">
          <mat-checkbox
            [ngClass]="{
              hilite:
                rsswa &&
                service.showAmendment &&
                rsswa.rssplcSet != tmpChkRPLCSet
            }"
            [(ngModel)]="tmpChkRPLCSet"
            formControlName="chkRSSPLCSetCtrl"
            >RSS PLC Set Pt / Parameter Access
          </mat-checkbox>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-2 p-1">
        <div class="check-card check-disabled" id="tmpChkRSoftware">
          <mat-checkbox
            [ngClass]="{
              hilite:
                rsswa &&
                service.showAmendment &&
                rsswa.rssSoftware != tmpChkRSoftware
            }"
            [(ngModel)]="tmpChkRSoftware"
            formControlName="chkRSSSoftwareCtrl"
            >RSS Software / Firmware Download
          </mat-checkbox>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-2 p-1">
        <div class="check-card check-disabled" id="tmpChkETIAccess">
          <mat-checkbox
            [ngClass]="{
              hilite:
                rsswa &&
                service.showAmendment &&
                rsswa.etiAccess != tmpChkETIAccess
            }"
            [(ngModel)]="tmpChkETIAccess"
            formControlName="chkETIAccessCtrl"
            >ETI Access / TOMBIS Modification / TOMBIS Removal of Components
          </mat-checkbox>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-2 p-1">
        <div class="check-card check-disabled" id="tmpChkTPLCSet">
          <mat-checkbox
            [ngClass]="{
              hilite:
                rsswa &&
                service.showAmendment &&
                rsswa.tombisplcSet != tmpChkTPLCSet
            }"
            [(ngModel)]="tmpChkTPLCSet"
            formControlName="chkTPLSetCtrl"
            >TOMBIS PLC Set Pt / Parameter Access
          </mat-checkbox>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-2 p-1">
        <div class="check-card check-disabled" id="tmpChkTSoftware">
          <mat-checkbox
            [ngClass]="{
              hilite:
                rsswa &&
                service.showAmendment &&
                rsswa.tombisSoftware != tmpChkTSoftware
            }"
            [(ngModel)]="tmpChkTSoftware"
            formControlName="chkTSoftwareCtrl"
            >TOMBIS Software / Firmware Download
          </mat-checkbox>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-2 p-1">
        <div
          class="check-card check-disabled"
          id="tmpChktopOffInterlockSetPoint"
        >
          <mat-checkbox
            [ngClass]="{
              hilite:
                rsswa &&
                service.showAmendment &&
                rsswa.topOffInterlockSetPoint != tmpChktopOffInterlockSetPoint
            }"
            [(ngModel)]="tmpChktopOffInterlockSetPoint"
            formControlName="chkTopOffInterlockCtrl"
            >TOP OFF Interlock set Point and/or Interlock threshold modification
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="row px-2 mt-0">
      <div class="col px-1">
        <comments-box
          title="Work Description"
          [textValue]="tmpDescription"
          [disabled]="disabled"
          formType="RSSWA"
          [serialNo]="rsswa?.serialNo"
          (textChanged)="descriptionChanged($event)"
          [hasError]="descriptionCtrl.invalid"
          [control]="descriptionCtrl"
          [hilite]="
            rsswa &&
            service.showAmendment &&
            rsswa.description != tmpDescription
          "
        >
        </comments-box>
      </div>
    </div>
    <div class="row">
      <div class="col col-xl-12">
        <div class="d-print-block w-100">
          <related-link-chips
            [placeholder]="'Related Documents and Links'"
            [disabled]="disabled"
            [formId]="rsswa?.id"
            [relatedLinks]="tmpRsswaRelatedLinks"
            (onEdited)="onRelatedLinksEdited($event)"
            [hilite]="diffRelatedLinks()"
          ></related-link-chips>
        </div>
      </div>
    </div>
    <div class="w-100 d-print-block print-container">
      <h5 id="Section2Title">Work Controls</h5>
      <div class="row">
        <div class="col">
          <form-restriction-table
            [formType]="formTypeEnum.RSSWA"
            [formID]="rsswa?.id"
            [locationIDs]="tmpResourceIds"
            [formRestrictions]="tmpRsswaRestrictions"
            [disabled]="disabled"
            [customTitle]="'Required controls in place prior to work:'"
            [hilite]="diffRestrictions()"
            [control]="restrictionsControl"
            (changed)="restrictionsChanged($event)"
          ></form-restriction-table>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <rsswa-section1-functional-tests
          [rsswa]="rsswa"
          [disabled]="disabled"
          [(chkFuncTestCtrl)]="chkFuncTestCtrl"
          [(functionalTestCtrl)]="functionalTestCtrl"
          [(provisionalTestingRequiredCtrl)]="provisionalTestingRequiredCtrl"
        ></rsswa-section1-functional-tests>
      </div>
    </div>
  </form>
</div>

<div
  class="buttons d-print-none d-flex justify-content-between"
  *ngIf="
    rsswa?.editingById == getCurrentUser()?.id ||
    showCancelDraft ||
    showCancelForm
  "
>
  <div class="d-flex justify-content-start">
    <button
      mat-flat-button
      *ngIf="showCancelDraft"
      color="warn"
      (click)="cancelDraft()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      *ngIf="showCancelForm"
      color="warn"
      (click)="cancel()"
    >
      {{ service.showAmendment ? "Cancel Amendment" : "Cancel" }}
    </button>
  </div>
  <div class="d-flex justify-content-end">
    <button
      (click)="editOlog()"
      mat-stroked-button
      color="primary"
      *ngIf="rsswa?.status == formStatusEnum.Draft"
      [disabled]="loadingOlog"
    >
      Edit Olog
    </button>

    <button
      mat-flat-button
      [disabled]="formGroup.disabled"
      type="submit"
      class="ml-2"
      *ngIf="
        (rsswa?.editingById == getCurrentUser()?.id &&
          rsswa?.status == formStatusEnum.New) ||
        rsswa?.status == formStatusEnum.Draft ||
        (rsswa?.status == formStatusEnum.Amendment && service.showAmendment)
      "
      color="accent"
      (click)="saveForm(1)"
    >
      {{ saveButtonText }}
    </button>

    <button
      *ngIf="rsswa?.status == formStatusEnum.WorkApprovals && showUnSubmit"
      mat-flat-button
      color="warn"
      class="ml-2"
      (click)="disapprove()"
    >
      Unsubmit
    </button>

    <button
      *ngIf="
        rsswa?.rsswaAmendment?.createdBy == getCurrentUser()?.id &&
        rsswa?.status == formStatusEnum.AmendmentSign &&
        service.showAmendment &&
        hasPrivilege(privilegeEnum.RSSWACancelAmendment)
      "
      class="ml-2"
      mat-flat-button
      color="warn"
      (click)="disapprove()"
    >
      Unsubmit Amendment
    </button>
    <button
      mat-flat-button
      *ngIf="
        showSubmit &&
        rsswa?.status == formStatusEnum.Draft &&
        hasPrivilege(privilegeEnum.RSSWASubmit)
      "
      type="submit"
      class="ml-2"
      color="primary"
      (click)="saveForm(formStatusEnum.WorkApprovals)"
    >
      Submit
    </button>
    <button
      mat-flat-button
      class="ml-2"
      *ngIf="service.showAmendment && hasPrivilege(privilegeEnum.RSSWASubmit)"
      color="primary"
      type="submit"
      (click)="submit_amendment()"
    >
      Submit
    </button>
  </div>
</div>
