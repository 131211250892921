import { Form } from "src/app/services/forms/form";

export class FormsRefresh {
  static readonly type = '[Forms] Refresh';
  constructor(public type?: number) { }
}

export class FormsRefreshByID {
  static readonly type = '[Forms] RefreshByID';
  constructor(public id: number) { }
}

export class FormsCreate {
  static readonly type = '[Forms] Create';

  constructor(public item: Form) { }
}

export class FormsUpdate {
  static readonly type = '[Forms] Update';

  constructor(public id: number, public item: Form) { }
}

export class FormsDelete {
  static readonly type = '[Forms] Delete';
  constructor(public id: number) { }
}
