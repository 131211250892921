<div class="container-fluid mt-2 pb-3">
  <div #table class="mat-container scrollbar">
    <mat-table
      style="min-width: 55em"
      [dataSource]="dataSourceRadMonitor"
      matSort
      (matSortChange)="sortData($event)"
    >
      <ng-container matColumnDef="ehs">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          EHS#
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span [innerHTML]="element.ehs | highlightSearch : filter"></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Location Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="
              element.radLocation?.radLocationType?.name
                | highlightSearch : filter
            "
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Location
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="element.radLocation.name | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Description
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="
              element.radLocation.description | highlightSearch : filter
            "
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mfr">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Mfr
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span [innerHTML]="element.mfr | highlightSearch : filter"></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="model">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Model
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span [innerHTML]="element.model | highlightSearch : filter"></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="controllerLocation">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Controller Location
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="
              element.radLocation?.controllerLocation | highlightSearch : filter
            "
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="resetLocation">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Reset Location
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <span
            [innerHTML]="
              element.radLocation?.resetLocation | highlightSearch : filter
            "
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="calDueDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Cal Due Date
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          {{ element.calDueDate | date : "shortDate" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="installedDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Installed Date
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <div *ngIf="element.installedDate">
            {{ element.installedDate | date : "shortDate" }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="notes">
        <mat-header-cell *matHeaderCellDef> Notes </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <notes
            [notes]="element.notes"
            [canDelete]="true"
            (delete)="deleteNote($event)"
          ></notes>
          <notes
            [type]="2"
            [notes]="element.notesf"
            [canDelete]="true"
            (delete)="deleteNote($event)"
          ></notes>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="options">
        <mat-header-cell *matHeaderCellDef> Options </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div *ngIf="validateShowMenu()">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Rad Monitor Menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                *ngIf="hasPrivilege(privilege.EditRadMonitor)"
                (click)="monitorDialog(element)"
              >
                <mat-icon class="yellow">edit</mat-icon>
                <span>Edit</span>
              </button>

              <button
                mat-menu-item
                *ngIf="hasPrivilege(privilege.CreateRadMonitorNote)"
                (click)="noteDialog(element, radNoteType.Normal)"
              >
                <mat-icon class="orange">note_add</mat-icon>
                <span>Create Note</span>
              </button>

              <button
                mat-menu-item
                *ngIf="hasPrivilege(privilege.CreateRadMonitorFaultNote)"
                (click)="noteDialog(element, radNoteType.Fault)"
              >
                <mat-icon class="red">note_add</mat-icon>
                <span>Create Fault Note</span>
              </button>

              <button
                mat-menu-item
                *ngIf="hasPrivilege(privilege.DeleteRadMonitor)"
                (click)="deleteMonitor(element)"
              >
                <mat-icon class="red">delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row
        #header
        [style.width]="innerWidth > 992 ? tableWidth : null"
        [ngClass]="{
          'p-0': true,
          'fixed-head-monitor': headerOnTop && innerWidth > 992,
          'sticky-headers': innerWidth < 992
        }"
        *matHeaderRowDef="displayedColumnsRadMonitor; sticky: true"
      >
      </mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumnsRadMonitor"
        [ngStyle]="{ 'background-color': getColor(row) }"
      ></mat-row>
    </mat-table>
  </div>
</div>
