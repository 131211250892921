<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-4">
        <span class="card-title">Comments & Recommendations</span>
      </div>
      <div class="col-3">
        <h5>
          <span class="small">SerialNo: </span>{{ currentDocument.serialNo }}
        </h5>
      </div>
      <div class="col-3">
        <h5 class="float-right">
          <span class="small">Endstation(s): </span
          >{{ currentDocument.resourceName }}
        </h5>
      </div>
      <div class="col-2">
        <h5 class="float-right">
          <span class="small pr-2">Comments Count: </span>{{ comments?.length }}
        </h5>
      </div>
    </div>

    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

  <div class="card-body scrollbar">
    <div class="card mb-2" *ngFor="let section of sections">
      <div class="card-header">
        Section:
        <b>
          {{ section }}
        </b>
      </div>
      <div class="card-body pt-0 pb-0">
        <div *ngFor="let comment of commentsBySection(section)">
          <div class="row pb-2 pt-2 border-bottom">
            <div class="col-4">
              Task: <b>{{ comment.code }}</b
              ><br />
              Created On: <b>{{ comment.createdOn | date }}</b
              ><br />
              Created By: <b>{{ comment.createdByName }}</b>
            </div>
            <div class="col-8">
              <div class="comment">
                <span [innerHTML]="comment.commentText"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="card-footer">
    <div mat-dialog-actions align="center">
	</div>
  </div> -->
</div>

<!-- <div class="row">
  <div class="col-12" style="height: 73vh">

  </div>
</div> -->
