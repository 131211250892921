import { Component, OnInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'cl-checkbox-and-radio',
  templateUrl: './cl-checkbox-and-radio.component.html',
  styleUrls: ['./cl-checkbox-and-radio.component.scss']
})

export class ClCheckboxAndRadioComponent extends ClBaseComponent implements OnInit, OnChanges, OnDestroy {

  ngOnChanges(changes: SimpleChanges) {
    if (this.radioSelection || this.radioSelection == 0) { this.setRadio(); }
    this.checked = this.user != null;
  }

  ngOnInit() {
  }

  checkboxChanged(e: any) {
    this.checked = e.checked;
    this.user = this.checked ? this.getCurrentUser() : null;
    if (!this.checked) this.answered.emit();
    this.answered.emit(this.checked);
  }

  radioChange(e: MatRadioChange) {
    this.radioSelected.emit(e.value);
    this.radioSelection = e.value;
    this.checked = true;
    this.user = this.getCurrentUser();
    this.setRadio();
  }

  checkboxEnabled() {
    let enabled = !this.disabled;
    if (enabled) {
      enabled = this.radioOptions?.map(r => r.checked).includes(true) ?? false;
    }
    return enabled;
  }

  setRadio() {
    this.radioOptions?.map(r => {
      r.checked = r.value == this.radioSelection;
    });
  }
}
