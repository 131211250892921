<div class="container-fluid mt-2">
  <div class="mat-container">
    <mat-table [dataSource]="dataSource" matSort style="min-width: 55em">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [innerHTML]="element.name | highlightSearch : filter"></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Description
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span
            [innerHTML]="element.description | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span
            [innerHTML]="messageType[element.type] | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Categories
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="d-flex flex-column align-items-start justify-content-center"
        >
          <span
            [innerHTML]="getCategories(element) | highlightSearch : filter"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="timeout">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          TimeOut
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.timeout }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="notes">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Notes
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.notes }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Copy Name to Clipboard"
            (click)="copy(element)"
          >
            <mat-icon color="primary">content_copy</mat-icon>
          </button>

          <button
            mat-icon-button
            *ngIf="hasPrivilege(privilegeEnum.AdminDisplayMessages)"
            matTooltip="Edit"
            (click)="openEdition(element)"
          >
            <mat-icon color="accent">edit</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row
        #header
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
