<div class="card p-0">
  <div class="card-header">
    <button
      mat-mini-fab
      class="closex float-right"
      [disabled]="isLoading"
      color="warn"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
    <h5>{{ getActionTitle() }} Action Item</h5>
    <!-- <span class="card-title"></span> -->
    <mat-progress-bar
      *ngIf="isLoading"
      class="progress-bar"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>
  <div class="card-body action-detail-height">
    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col-4 pt-1">
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline">
                <mat-label>Topic</mat-label>
                <input
                  type="text"
                  placeholder="Topic"
                  matInput
                  name="reviewActionItemTypeNameCtrl"
                  [formControl]="actionItemNameCtrl"
                />
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="outline">
                <mat-label>Action Item Category</mat-label>
                <input
                  type="text"
                  placeholder="Select Category"
                  matInput
                  name="actionItemTypeCtrl"
                  [formControl]="actionItemTypeCtrl"
                  [matAutocomplete]="autoActionItemType"
                  (keyup)="changedActionItemType($event)"
                />
                <mat-autocomplete
                  #autoActionItemType="matAutocomplete"
                  #actionItemTypeAutocomplete
                  [displayWith]="displayObject"
                  (optionSelected)="selectedActionItemType($event)"
                >
                  <mat-option
                    *ngFor="let actionItemType of reviewActionItemTypesFiltered"
                    [value]="actionItemType"
                  >
                    <span>{{ actionItemType.name }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="outline">
                <mat-label>Responsible</mat-label>
                <input
                  type="text"
                  placeholder="Select Responsible"
                  matInput
                  name="actionItemResponsibleCtrl"
                  [formControl]="actionItemResponsibleCtrl"
                  [matAutocomplete]="autoActionItemResponsible"
                  (keyup)="changedActionItemResponsible($event)"
                />
                <mat-autocomplete
                  #autoActionItemResponsible="matAutocomplete"
                  #actionItemResponsibleAutocomplete
                  [displayWith]="displayObject"
                  (optionSelected)="selectedActionItemResponsible($event)"
                >
                  <mat-option *ngFor="let user of usersFiltered" [value]="user">
                    <span>{{ user.name }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="no-subscript">
                <mat-label>Start Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="startDate"
                  placeholder="Start Date"
                  name="startDate"
                  [formControl]="actionItemStartDateCtrl"
                  readonly
                  (dateChange)="changedStartDate($event)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="startDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="no-subscript">
                <mat-label>End Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="endDate"
                  placeholder="End Date"
                  name="endDate"
                  [formControl]="actionItemEndDateCtrl"
                  readonly
                  (dateChange)="changedEndDate($event)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="endDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field
                *ngIf="reviewActionItem.status == 0"
                class="no-subscript"
              >
                <mat-label>Completed By</mat-label>
                <input
                  type="text"
                  matInput
                  name="reviewActionItemClosedByCtrl"
                  [formControl]="actionItemClosedByCtrl"
                />
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field
                *ngIf="reviewActionItem.status == 0"
                class="no-subscript"
              >
                <mat-label>Completion Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="closedDate"
                  placeholder="Completed Date"
                  name="closedDate"
                  [formControl]="actionItemClosedDateCtrl"
                  readonly
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="closedDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #closedDate></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-8 pt-1">
          <div class="row">
            <div class="col-12">
              <comments-box
                [noSubscript]="true"
                [textValue]="reviewActionItem.description"
                [disabled]="disabled || isLoading || !hasPrivilege"
                [serialNo]="serialNo"
                [formType]="'Review'"
                [title]="'Action Item Description'"
                (textChanged)="changedDescription($event)"
                maxHeight="70vh"
              >
              </comments-box>
            </div>
            <div class="col mb-3">
              <mat-slide-toggle
                *ngIf="reviewActionItem.id"
                color="primary"
                [disabled]="disabled || isLoading || !hasPrivilege"
                (change)="changeCatl()"
                [(ngModel)]="reviewActionItem.isCATL"
                >Is this a CATL Item?</mat-slide-toggle
              >
              <button
                mat-icon-button
                class="email-button"
                matTooltip="Re-Send Email notification"
                [disabled]="isLoading || !hasPrivilege"
                (click)="sendEmail()"
                *ngIf="reviewActionItem.emailSent && reviewActionItem.isCATL"
              >
                <mat-icon color="accent">email</mat-icon>
              </button>
            </div>
            <div class="col-12">
              <reviews-action-items-comments
                [disabled]="disabled || isLoading"
                [reviewActionItem]="reviewActionItem"
                *ngIf="reviewActionItem.id != 0"
              ></reviews-action-items-comments>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions class="d-flex justify-content-between">
      <div>
        <button
          mat-flat-button
          color="primary"
          class="float-left"
          [disabled]="isLoading"
          (click)="openActionComplete()"
          *ngIf="canBeCompleted()"
        >
          Complete Action Item
        </button>
      </div>
      <div>
        <button
          mat-stroked-button
          color="warn"
          class="mr-2"
          [disabled]="isLoading || !hasPrivilege"
          (click)="cancel()"
        >
          Cancel
        </button>
        <button
          *ngIf="!disabled"
          mat-flat-button
          color="primary"
          [disabled]="
            !formValid() || !hasPrivilege(privilegeEnum.AddRemoveActionItems)
          "
          (click)="save()"
        >
          Accept
        </button>
      </div>
    </div>
  </div>
</div>
