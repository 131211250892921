import { Component, OnInit } from '@angular/core';
import { ProcedureReportService } from '../../services/procedure-report.service';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { ProcedureReport } from '../../models/procedure-report.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-procedure-reports',
  templateUrl: './procedure-reports.component.html',
  styleUrls: ['./procedure-reports.component.scss']
})
export class ProcedureReportsComponent implements OnInit {

  dataSource = new MatTableDataSource<ProcedureReport>();
  displayedColumns: string[] = ['name', 'number', 'title', 'revision', 'reviewed', 'status', 'obligation', 'roles', 'credrev', 'credited', 'approver', 'groupManager', 'categoryManager', 'action'];
  loading!: boolean;

  isExpanded = true;
  isShowing = false;
  links = ['Report 1', 'Report 2'];
  activeLink = this.links[0];
  tabLabel!: string;
  tabIndex = 0;

  nameCtrl = new FormControl();
  numberCtrl = new FormControl();
  titleCtrl = new FormControl();
  activeCtrl = new FormControl();
  revisionCtrl = new FormControl();
  creditedRevisionCtrl = new FormControl();
  obligationCtrl = new FormControl();
  rolesCtrl = new FormControl();
  creditedCtrl = new FormControl();
  lastReviewedCtrl = new FormControl();
  approverCtrl = new FormControl();
  groupManagerCtrl = new FormControl();
  categoryManagerCtrl = new FormControl();

  filterOn: boolean = false;
  form = new FormGroup({
    nameCtrl: this.nameCtrl,
    numberCtrl: this.numberCtrl,
    titleCtrl: this.titleCtrl,
    activeCtrl: this.activeCtrl,
    revisionCtrl: this.revisionCtrl,
    creditedRevisionCtrl: this.creditedRevisionCtrl,
    obligationCtrl: this.obligationCtrl,
    rolesCtrl: this.rolesCtrl,
    creditedCtrl: this.creditedCtrl,
    lastReviewedCtrl: this.lastReviewedCtrl,
    approverCtrl: this.approverCtrl,
    groupManagerCtrl: this.groupManagerCtrl,
    categoryManagerCtrl: this.categoryManagerCtrl,

    filterOn: new FormControl(false),
  });

  constructor(private service: ProcedureReportService) {

  }

  ngOnInit() {
    this.loading = true;
    this.service.read().subscribe(reports => {
      this.dataSource = new MatTableDataSource(reports);
      this.dataSource.filterPredicate = this.customFilter;
      this.form.valueChanges.subscribe((data: any) => {
        const res = {
          name: data.nameCtrl?.toUpperCase(),
          number: data.numberCtrl?.toUpperCase(),
          title: data.titleCtrl?.toUpperCase(),
          revision: data.revisionCtrl?.toUpperCase(),
          active: data.activeCtrl?.toUpperCase(),
          lastReviewed: data.lastReviewedCtrl?.toUpperCase(),
          credRev: data.creditedRevisionCtrl?.toUpperCase(),
          obligation: data.obligationCtrl,
          roles: data.rolesCtrl?.toUpperCase(),
          credited: data.creditedCtrl?.toUpperCase(),
          approver: data.approverCtrl?.toUpperCase(),
          groupManager: data.groupManagerCtrl?.toUpperCase(),
          categoryManager: data.categoryManagerCtrl?.toUpperCase(),
          filterOn: true
        }

        this.dataSource.filter = JSON.stringify(res);
      });
      this.loading = false;
    });
    this.setTab();
  }

  onTabChanged(e: any) {
    this.tabIndex = e ? this.links.indexOf(e) : 0;
    this.setActiveLink();
  }

  setTab(tabParam?: string) {
    this.tabIndex = tabParam ? +tabParam : 0;
    this.setActiveLink();
  }

  setActiveLink() {
    this.activeLink = this.links[this.tabIndex];
    this.tabLabel = this.activeLink;
  }

  filterToggle() {
    this.filterOn = !this.filterOn;
    this.form.controls.filterOn.setValue(this.filterOn);
  }

  clearFilters() {
    this.form.controls.nameCtrl.setValue('');
    this.form.controls.numberCtrl.setValue('');
    this.form.controls.titleCtrl.setValue('');
    this.form.controls.revisionCtrl.setValue('');
    this.form.controls.lastReviewedCtrl.setValue('');
    this.form.controls.creditedRevisionCtrl.setValue('');
    this.form.controls.activeCtrl.setValue('');
    this.form.controls.obligationCtrl.setValue('');
    this.form.controls.rolesCtrl.setValue('');
    this.form.controls.creditedCtrl.setValue('');
    this.form.controls.approverCtrl.setValue('');
    this.form.controls.groupManagerCtrl.setValue('');
    this.form.controls.categoryManagerCtrl.setValue('');
  }

  customFilter(data: ProcedureReport, filter: string) {
    const filterData = JSON.parse(filter);
    let ok: boolean = true;
    if (!filterData.filterOn) {
      return true;
    }
    if (filterData.name) {
      ok = ok && (data.userName).toUpperCase().includes(filterData.name);
    }
    if (filterData.number) {
      ok = ok && data.procedureNumber.toUpperCase().includes(filterData.number);
    }
    if (filterData.title) {
      ok = ok && (data.title).toUpperCase().includes(filterData.title);
    }
    if (filterData.revision) {
      ok = ok && (data.revision).toUpperCase().includes(filterData.revision);
    }
    if (filterData.reviewed) {
      ok = ok && ('' + data.lastReviewed).toUpperCase().includes(filterData.lastReviewed);
    }
    if (filterData.active) {
      ok = ok && ('' + data.active).toUpperCase().includes(filterData.active);
    }
    if (filterData.obligation) {
      ok = ok && (data.obligationType?.toString()).includes(filterData.obligation);
    }
    if (filterData.roles) {
      ok = ok && data.roles.toUpperCase().includes(filterData.roles);
    }
    if (filterData.credRev) {
      ok = ok && ('' + data.creditedRevision).toUpperCase().includes(filterData.credRev);
    }
    if (filterData.credited) {
      ok = ok && ('' + data.dateCredited).toUpperCase().includes(filterData.credited);
    }
    if (filterData.approver) {
      ok = ok && (data.approverName).toUpperCase().includes(filterData.approver);
    }
    if (filterData.groupManager) {
      ok = ok && (data.groupManagers).toUpperCase().includes(filterData.groupManager);
    }
    if (filterData.categoryManager) {
      ok = ok && (data.categoryManagers).toUpperCase().includes(filterData.categoryManager);
    }
    return ok;
  };

  formatDate(date: Date | undefined | string): string | null {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'M/d/yy');
  }
}
