import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Observable } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { APLCategory } from 'src/app/components/apl/services/apl-category.model';
import { MessageBanner } from 'src/app/components/messages/services/message-banner';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { messages } from 'src/environments/messages';
import { APLCategoryService } from '../../../apl-category.service';


export class APLCategoryApprovalPopupParameters {
  public aplCategory!: APLCategory;
  public user!: User;
}

@Component({
  selector: 'apl-category-approval-popup',
  templateUrl: './apl-category-approval-popup.component.html',
  styleUrls: ['./apl-category-approval-popup.component.scss']
})
export class APLCategoryApprovalPopupComponent extends BaseComponent implements OnInit, OnDestroy {
  aplCategoryApproval: boolean = false;
  loading: boolean = false;
  title!: string;
  showPrevious = false;
  submitted?: User;
  disableApproveDisapprove: boolean = false;

  labelCreateCategory!: string;
  labelEditCategory!: string;
  labelDeleteCategory!: string;
  labelName!: string;
  labelInitials!: string;
  labelManagers!: string;
  labelDisapprove!: string;
  labelApprove!: string;
  labelAreYouSureYouWantToApprove!: string;
  labelAreYouSureYouWantToDisapprove!: string;
  labelAreYouSureYouWantToUnsubmit!: string;
  labelShowPrevious!: string;
  labelGeneralError!: string;

  constructor(
    protected override injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    public data: APLCategoryApprovalPopupParameters,
    private dialogRef: MatDialogRef<APLCategoryApprovalPopupComponent>,
    private aplCategoryService: APLCategoryService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.setPermissions();

    this.submitted = this.getUser(this.data?.aplCategory?.createdBy);
    this.disableApproveDisapprove = this.submitted?.id === this.data?.user?.id;

    this.setLabels();
  }


  setLabels() {
    this.labelCreateCategory = this.getMessage('APL_CategoryApproval_CreateCategory')?.description;
    this.labelEditCategory = this.getMessage('APL_CategoryApprovals_EditCategory')?.description;
    this.labelDeleteCategory = this.getMessage('APL_CategoryApprovals_DeleteCategory')?.description;
    this.labelName = this.getMessage('APL_CategoryApproval_Name')?.description;
    this.labelInitials = this.getMessage('APL_CategoryApproval_Initials')?.description;
    this.labelManagers = this.getMessage('APL_CategoryApprovals_Managers')?.description;
    this.labelDisapprove = this.getMessage('APL_CategoryApprovals_Disapprove')?.description;
    this.labelApprove = this.getMessage('APL_CategoryApprovals_Approve')?.description;
    this.labelAreYouSureYouWantToApprove = this.getMessage('APL_CategoryApprovals_AreYouSureYouWantToApprove')?.description;
    this.labelAreYouSureYouWantToDisapprove = this.getMessage('APL_CategoryApprovals_AreYouSureYouWantToDisapprove')?.description;
    this.labelAreYouSureYouWantToUnsubmit = this.getMessage('APL_CategoryApprovals_AreYouSureYouWantToUnsubmit')?.description;
    this.labelShowPrevious = this.getMessage('APL_CategoryApprovals_ShowPrevious')?.description;
    this.title = (this.data?.aplCategory?.status == -2) ? this.labelDeleteCategory : ((!this.data?.aplCategory?.previousVersion) ? this.labelCreateCategory : this.labelEditCategory);
  }

  setPermissions() {
    this.aplCategoryApproval = this.hasPrivilege(PrivilegeEnum.APLCategoryApproval);
  }

  approve() {
    this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.labelAreYouSureYouWantToApprove,
      },
    }).afterClosed().subscribe(data => {
      if (data) {
        this.loading = true;
        this.aplCategoryService.approve(this.data?.aplCategory?.id).subscribe(
          () => this.dialogRef.close(),
          () => {
            this.alert.error(this.labelGeneralError);
            this.loading = false;
          }
        );
      }
    });
  }

  disapprove() {
    this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.labelAreYouSureYouWantToDisapprove,
      },
    }).afterClosed().subscribe(data => {
      if (data) {
        this.loading = true;
        this.aplCategoryService.disapprove(this.data?.aplCategory?.id).subscribe(
          () => this.dialogRef.close(),
          () => {
            this.alert.error(this.labelGeneralError);
            this.loading = false;
          }
        );
      }
    });
  }

  unSubmit() {
    this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.labelAreYouSureYouWantToUnsubmit,
      },
    }).afterClosed().subscribe(data => {
      if (data) {
        this.loading = true;
        this.aplCategoryService.disapprove(this.data?.aplCategory?.id).subscribe(
          () => this.dialogRef.close(),
          () => {
            this.alert.error(this.labelGeneralError);
            this.loading = false;
          }
        );
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

}
