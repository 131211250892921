<div class="mat-container scrollbarX style-15">
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortData($event)"
  >
    <ng-container matColumnDef="serialNo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Serial No.
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        class="text-break bold"
        [ngClass]="element.reviewRequestStatus.color"
      >
        {{ element.serialNo }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="location">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Location
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        class="text-break"
        [ngClass]="element.reviewRequestStatus.color"
      >
        {{ element.location }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="proposedTimeline">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Proposed Review Date
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        class="text-break"
        [ngClass]="element.reviewRequestStatus.color"
        ><span
          [innerHTML]="
            utils.isValidDate(element.proposedTimeline)
              ? (element.proposedTimeline | date : 'mediumDate')
              : element.proposedTimeline
          "
        ></span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="requestBy">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Requested By
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        class="text-break"
        [ngClass]="element.reviewRequestStatus.color"
      >
        {{ element.createdByUser?.name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="requestOn">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Requested On
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        class="text-break"
        [ngClass]="element.reviewRequestStatus.color"
      >
        {{ element.createdOn | date : "mediumDate" }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="reviews">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Reviews
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        class="text-break d-flex flex-column align-items-start"
        [ngClass]="element.reviewRequestStatus.color"
      >
        <div
          *ngFor="let review of element.reviews"
          [class]="review.reviewStatus.color"
        >
          {{ review.reviewDate | date }} - {{ review.reviewStatus.name }}
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      #header
      class="p-0"
      *matHeaderRowDef="displayedColumns"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="reviewRequestDialog(row)"
    >
    </mat-row>
  </mat-table>
</div>
