<div class="container-fluid">
  <form [formGroup]="formGroup">
    <div class="row mt-2">
      <div class="col-12 col-md-6 col-lg-6">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input
            aria-label="name"
            matInput
            [ngModel]="documentType?.name"
            (change)="nameChanged($event)"
            [disabled]="isDisabled"
            formControlName="nameCtrl"
          />
          <mat-error *ngIf="nameCtrl?.invalid">{{
            getErrorMsg(nameCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 col-lg-6">
        <mat-form-field>
          <mat-label>Description</mat-label>
          <input
            aria-label="description"
            matInput
            [ngModel]="documentType?.description"
            (change)="descriptionChanged($event)"
            [disabled]="isDisabled"
            formControlName="descriptionCtrl"
          />
          <mat-error *ngIf="descriptionCtrl?.invalid">{{
            getErrorMsg(descriptionCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 col-lg-6">
        <mat-form-field>
          <mat-label>Code</mat-label>
          <input
            aria-label="code"
            matInput
            [ngModel]="documentType?.code"
            maxlength="10"
            (change)="codeChanged($event)"
            [disabled]="isDisabled"
            formControlName="codeCtrl"
          />
          <mat-error *ngIf="codeCtrl?.invalid">{{
            getErrorMsg(codeCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="row" *ngIf="documentType?.category == documentCategory.Checklist">
    <div class="col-12 col-md-6">
      <fieldset class="field-group d-flex justify-content-around">
        <legend>Menu Options</legend>

        <mat-slide-toggle
          [disabled]="!hasPrivilege(privilegeEnum.ModifyDocumentType)"
          [(ngModel)]="menuItemChk"
          (toggleChange)="
            !menuItemChk
              ? createMenu(menuType.Checklist)
              : removeMenu(menuType.Checklist)
          "
        >
          Show in Checklists
        </mat-slide-toggle>
        <mat-slide-toggle
          [disabled]="!hasPrivilege(privilegeEnum.ModifyDocumentType)"
          [(ngModel)]="menuItemBld"
          (toggleChange)="
            !menuItemBld
              ? createMenu(menuType.Builder)
              : removeMenu(menuType.Builder)
          "
        >
          Show in Template Builders
        </mat-slide-toggle>
      </fieldset>
    </div>
  </div>
</div>
