<div class="card p-0 pl-2" [ngClass]="{ amendment: answerChangedById }">
  <div class="row pl-3 pr-3">
    <div class="p-33per col-12 col-lg-4 pt-3 pb-2">
      <div class="row no-gutters">
        <div class="col-10 p-0">
          <h6 [innerHTML]="questionSanitized"></h6>
        </div>

        <div class="col-2">
          <mat-checkbox
            class="checkbox float-right"
            [(ngModel)]="checked"
            [disabled]="questionDisabled ?? true"
            (change)="checkboxChanged()"
          >
          </mat-checkbox>
        </div>
        <div class="col-12 smallTextQS">
          <span [innerHTML]="signature?.lastFollowUpCheckChanged"></span>
          <span
            class="float-right smallTextQS"
            *ngIf="!(answerChangedById && answerChangedBy?.name)"
            [innerHTML]="signature?.lastFollowUpChanged"
          >
          </span>
          <button
            mat-icon-button
            class="undo-button"
            matTooltip="Undo change"
            [disabled]="loading"
            color="primary"
            *ngIf="
              answerChangedById && answerChangedById == getCurrentUser()?.id
            "
            (click)="undo()"
          >
            <mat-icon>undo</mat-icon>
          </button>

          <span
            class="float-right smallTextQS"
            *ngIf="answerChangedById && answerChangedBy?.name"
          >
            <b class="yellow"> Pending Changes</b>
            <!-- <span *ngIf="RolesToApproveChanges"
              >&nbsp;to be approved by
              {{ RolesToApproveChanges.join(", ") }}</span
            > -->
          </span>
        </div>
        <div class="col-12 text-right" *ngIf="CanApproveAnswer">
          <button
            mat-flat-button
            class="approval-button"
            [disabled]="loading"
            color="primary"
            (click)="apprAns()"
          >
            Approve change
          </button>
          <button
            mat-flat-button
            class="approval-button"
            [disabled]="loading"
            color="warn"
            (click)="disapAns()"
          >
            Disapprove change
          </button>
        </div>
      </div>
    </div>
    <div class="p-66per col-12 col-lg-8 background">
      <div class="row">
        <div class="p-58per col-12 col-md-7">
          <div class="row">
            <div class="col-12 justify-content-center fit-content pb-0 d-flex">
              <h6>
                {{ title }}
              </h6>
              <role-chips [roleCodes]="roleCodes" class="ml-3"></role-chips>
            </div>
            <div
              class="col-12 text-center fit-content pt-0 d-flex justify-content-center"
            >
              <button
                mat-flat-button
                class="margin-auto d-print-none"
                *ngIf="visible && (showApprove || showUnapprove) && !disabled"
                [disabled]="loading || disabled"
                [color]="color"
                (click)="click()"
              >
                {{ label }}
              </button>
            </div>
            <div
              class="col-12 justify-content-center pt-0"
              *ngIf="showDisapprove"
            >
              <button
                mat-flat-button
                class="margin-auto d-print-none"
                *ngIf="visible && showDisapprove && !disabled"
                [disabled]="loading || disabled"
                color="warn"
                (click)="disap()"
              >
                Disapprove
              </button>
            </div>
          </div>
        </div>
        <div class="p-41per col-12 col-md-5">
          <div class="row no-gutters">
            <div class="p-25per col-12 col-xl-3 fit-content pb-0">By:</div>
            <div class="p-75per col-12 col-xl-9 fit-content pb-0">
              <span class="name" *ngIf="approved">{{ user?.name }}</span>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="p-25per col-12 col-xl-3 fit-content pt-0">
              <span class="small">Date:</span>
            </div>
            <div class="p-75per col-12 col-xl-9 fit-content pt-0">
              <span class="small date" *ngIf="approved">{{
                date | date : "medium"
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
