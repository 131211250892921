<div class="container-fluid"></div>
<div class="card">
  <div class="card-header">
    <span class="card-title"> {{ inputData.dialogTitle }}</span>
  </div>
  <div class="card-body">
    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label>EHS#</mat-label>
            <input
              aria-label="location"
              matInput
              [(ngModel)]="inputData.radMonitor.ehs"
              formControlName="ehs"
            />
            <mat-error *ngIf="ehs?.invalid">{{ getErrorMsg(ehs) }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Location & Type</mat-label>
            <mat-select
              [(ngModel)]="inputData.radMonitor.radLocationID"
              formControlName="location"
            >
              <mat-option
                *ngFor="let radLocation of inputData.radLocations"
                [value]="radLocation.id"
              >
                {{ getRadLocationWithTypeByRadLocation(radLocation) }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="location?.invalid">{{
              getErrorMsg(location)
            }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Manufacturer</mat-label>
            <input
              aria-label="manufacturer"
              matInput
              [(ngModel)]="inputData.radMonitor.mfr"
              formControlName="manufacturer"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Model</mat-label>
            <input
              aria-label="model"
              matInput
              [(ngModel)]="inputData.radMonitor.model"
              formControlName="model"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Cal Due Date</mat-label>
            <input
              matInput
              [matDatepicker]="calDueDate_Picker"
              placeholder="mm/dd/yyyy"
              [(ngModel)]="inputData.radMonitor.calDueDate"
              formControlName="calDueDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="calDueDate_Picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #calDueDate_Picker></mat-datepicker>
            <mat-error *ngIf="calDueDate?.invalid">{{
              getErrorMsg(calDueDate)
            }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Installed Date</mat-label>
            <input
              matInput
              [matDatepicker]="installedDate_Picker"
              placeholder="mm/dd/yyyy"
              [(ngModel)]="inputData.radMonitor.installedDate"
              formControlName="installedDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="installedDate_Picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #installedDate_Picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button
        mat-flat-button
        class="cancel-btn"
        color="warn"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button
        [disabled]="formGroup.pristine"
        mat-flat-button
        class="accept-btn"
        color="primary"
        (click)="add()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
