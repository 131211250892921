import { RadiationAreaItem } from "../radiation-area-item";


export class RadiationAreaRefresh {
  static readonly type = '[RadiationArea] Refresh';
}

export class RadiationAreaCreate {
  static readonly type = '[RadiationArea] Create';
  constructor(public item: RadiationAreaItem) { }
}

export class RadiationAreaUpdate {
  static readonly type = '[RadiationArea] Update';
  constructor(public id: number, public item: RadiationAreaItem) { }
}

export class RadiationAreaDelete {
  static readonly type = '[RadiationArea] Delete';
  constructor(public id: number) { }
}
