import { FormType } from "src/app/common/enumerations/enumerations";
import { Document } from "../documents";

export class DocumentsRefresh {
  static readonly type = '[Documents] Refresh';
}

export class DocumentsRequestRefresh {
  static readonly type = '[Documents] RequestRefresh';
  constructor(public item: DocumentID) { }
}

export class DocumentsCreate {
  static readonly type = '[Documents] Create';

  constructor(public item: Document) { }
}

export class DocumentsUpdate {
  static readonly type = '[Documents] Update';
  constructor(public id: number, public item: Document) { }
}

export class DocumentsUpdateAll {
  static readonly type = '[Documents] UpdateAll';
  constructor(public data: Document[]) { }
}

export class DocumentsDelete {
  static readonly type = '[Documents] Delete';
  constructor(public item: DocumentID) { }
}


export interface DocumentID {
  catgegory: DocumentCategory;
  formType: FormType;
  id: number;
}

export enum DocumentCategory {
  Form = 1,
  Checklist = 2,
  Review = 3
}
