<!-- <div class="float-right">
        <mat-slide-toggle
          color="warn"
          [disabled]="pending"
          [(ngModel)]="showInactive"
          (click)="showMetersTypesInactive()"
          >Show Inactive</mat-slide-toggle
        >
      </div> -->

<div class="mat-container">
  <mat-table
    [dataSource]="dataSource"
    matSortActive="created"
    matSortDisableClear
    matSort
    (matSortChange)="sortData($event)"
    matSortDirection="desc"
  >
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Type
      </mat-header-cell>
      <mat-cell
        class="font-weight-bold"
        *matCellDef="let element"
        [ngStyle]="{ color: getColor(element) }"
      >
        {{ element.name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Description
      </mat-header-cell>
      <mat-cell
        align="center"
        *matCellDef="let element"
        [ngStyle]="{ color: getColor(element) }"
      >
        {{ element.description }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngStyle]="{ color: getColor(element) }"
      >
        {{ element.status == 1 ? "Active" : "Inactive" }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>
        <button
          mat-icon-button
          color="primary"
          [disabled]="pending"
          class="start-new-btn"
          (click)="createMeterType()"
        >
          <mat-icon class="yellow">add</mat-icon>
        </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          color="accent"
          [matTooltip]="'Edit File'"
          (click)="EditMeterType(element)"
        >
          <mat-icon class="icon">edit</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          [matTooltip]="'Delete File'"
          *ngIf="element.radMeters?.length == 0"
          (click)="deleteMeterType(element)"
        >
          <mat-icon class="icon">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      #matrow
      *matRowDef="let row; columns: displayedColumns"
      class="table-row"
      [ngClass]="{ 'mat-row-highlight': selectedRowIndex == row.id }"
      [attr.position]="getPosition(row)"
      (click)="highlight(row)"
    >
    </mat-row>
  </mat-table>
</div>
