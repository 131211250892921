<div class="container-fluid mt-4">
  <h5 id="Section6Title">Notification & Follow-up</h5>
  <div *ngFor="let s of signatures">
    <div class="row no-gutters">
      <div class="col-12 p-1">
        <question-signature
          [question]="s.question"
          [answer]="s.value"
          [questionDisabled]="
            questionDisabled || s.disabled || !hasPrivilege(privilegeEnum.RSSWA)
          "
          [roleId]="s.roles"
          [roleCodes]="s.roleCodes"
          [user]="s.approvedBy"
          [title]="s.name"
          [disabled]="s.disabled"
          [approved]="s.approved"
          [showApprove]="s.approve"
          [showDisapprove]="false"
          [showUnapprove]="s.unapprove"
          [date]="s.approvedOn"
          (approve)="approve(s.taskId, s.approveId)"
          (unapprove)="unapprove(s.taskId, s.unapproveId)"
          (answered)="answered(s.id, $event)"
        ></question-signature>
      </div>
    </div>
  </div>
  <div class="px-1 mt-1">
    <cl-close-action
      [title]="'Close RSSWA'"
      [user]="wfFinish.approvedBy"
      [roleIds]="wfFinish.roles"
      [roleCodes]="wfFinish.roleCodes"
      [value]="wfFinish.approved"
      [date]="wfFinish.approvedOn"
      [disabled]="wfFinish.disabled"
      (closed)="close(wfFinish.taskId, wfFinish.approveId)"
    ></cl-close-action>
  </div>
</div>
