import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MenuService } from '../menu.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MenuItem, MenuItemRole, MenuItemType } from '../../head-page/head-menu/head-menu.models';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MenuItemType as MenuItemTypeEnum } from 'src/app/common/enumerations/enumerations';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs';
import { Router, Route } from '@angular/router';
import FileV2 from 'src/app/services/file/file-v2.model';
import { FileV2Service } from 'src/app/services/file/file-v2.service';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { AlertService } from 'src/app/services/alert/alert.service';
import { TemplateType } from '../../checklists/checklists';
import { ScheduleType } from '../../schedules/models/schedule-type';
import { ReviewBoardType } from '../../reviews/reviews.models';

@Component({
  selector: 'menu-config-edit',
  templateUrl: './menu-config-edit.component.html',
  styleUrls: ['./menu-config-edit.component.scss']
})
export class MenuConfigEditComponent extends BaseComponent implements OnInit {

  @Output() loading = new EventEmitter<boolean>();
  @Input() disabled!: boolean;

  menuItem?: MenuItem;
  label?: string;

  roles?: Role[];
  allRoles?: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  menuItemTypes?: MenuItemType[];
  menuItemType = MenuItemTypeEnum;

  menuItems?: MenuItem[];
  menuItemsFiltered?: MenuItem[];
  menuItems$!: Observable<MenuItem[]>;
  menuItemsSubs!: Subscription;

  templateTypes?: TemplateType[];
  templateTypes$!: Observable<TemplateType[]>;
  templateTypesSubs!: Subscription;

  scheduleTypes?: ScheduleType[];
  scheduleTypes$!: Observable<ScheduleType[]>;
  scheduleTypesSubs!: Subscription;

  reviewBoardTypes?: ReviewBoardType[];
  reviewBoardTypes$!: Observable<ReviewBoardType[]>;
  reviewBoardTypesSubs!: Subscription;

  menuItemGroups?: MenuItem[];

  parentMenuItems?: MenuItem[];

  routes?: Route[];

  helpFile?: FileV2 | null;

  tables = ['ScheduleTypes', 'Links'];

  routeHelpers?: RouteHelper[];
  routeTables?: RouteTable[];

  public get labelCtrl(): AbstractControl | null { return this.formGroup.get('labelCtrl'); }
  public get menuItemTypeCtrl(): AbstractControl | null { return this.formGroup.get('menuItemTypeCtrl'); }
  public get rolesCtrl(): AbstractControl | null | undefined { return this.formGroup.get('rolesCtrl'); }
  public get acronymCtrl(): AbstractControl | null { return this.formGroup.get('acronymCtrl'); }
  public get routeCtrl(): AbstractControl | null { return this.formGroup.get('routeCtrl'); }
  public get visibleToggleCtrl(): AbstractControl | null { return this.formGroup.get('visibleToggleCtrl'); }
  public get parentCtrl(): AbstractControl | null { return this.formGroup.get('parentCtrl'); }
  public get tableCtrl(): AbstractControl | null { return this.formGroup.get('tableCtrl'); }

  constructor(
    protected override injector: Injector,
    private service: MenuService,
    private router: Router,
    private fileService: FileV2Service
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loading.emit(true);
    this.buildForm();
    this.loadRoles();
    this.routes = this.router.config.filter(r => r.component);
    this.routes.sort((a, b) => this.utils.sort(a.path, b.path));
    this.getRouteReplacements();
    this.loadTemplateTypes();
    this.getMenuItemTypes();
    this.getInfo();
  }

  override ngOnDestroy(): void {
    this.rolesSubs?.unsubscribe();
    this.menuItemsSubs?.unsubscribe();
    this.templateTypesSubs?.unsubscribe();
    this.scheduleTypesSubs?.unsubscribe();
    this.reviewBoardTypesSubs?.unsubscribe();
    this.reviewBoardTypesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  async getMenuItemTypes() {
    this.menuItemTypes = (await this.service.getAllTypes().toPromise())?.filter(t => (t?.id ?? 0) > 0);
  }

  getInfo() {
    this.service.currentItem.subscribe(async data => {
      this.menuItem = this.utils.cloneDeep(data);
      this.getMenuItemTypes();
      this.loadMenuItems();
      this.label = data?.name;
      this.labelCtrl?.setValue(data?.name);
      this.roles = this.menuItem?.menuItemRoles?.map(r => {
        if (!r.role)
          r.role = this.allRoles?.find(x => x.id == r.roleID);
        return r.role as Role
      });
      this.menuItemTypeCtrl?.setValue(this.menuItem?.menuItemType?.id);
      this.acronymCtrl?.setValue(this.menuItem?.code);
      this.tableCtrl?.setValue(this.menuItem?.table);
      this.routeCtrl?.setValue({ path: this.menuItem?.route, component: { name: '[Component]' } });
      this.visibleToggleCtrl?.setValue(this.menuItem?.status);
      this.parentCtrl?.setValue(this.menuItems?.find(m => m.id == this.menuItem?.parentMenuItemID));
      if (this.menuItem?.fileV2Id)
        this.helpFile = await this.fileService.obtainFile(this.menuItem?.fileV2Id).toPromise();
      else this.helpFile = null;
      this.setFormDirty(false);
    });
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      if (data?.length) {
        this.allRoles = data;
      }
    });
  }

  loadTemplateTypes() {
    this.templateTypes$ = this.store.select(state => state.TemplateTypes.data);
    this.templateTypesSubs = this.templateTypes$.subscribe(data => {
      if (data?.length) {
        this.templateTypes = data;
        this.loadScheduleTypes();
      }
    });
  }

  loadScheduleTypes() {
    this.scheduleTypes$ = this.store.select(state => state.ScheduleTypes.data);
    this.scheduleTypesSubs = this.scheduleTypes$.subscribe(data => {
      if (data?.length) {
        this.scheduleTypes = data.filter(s => s.isActive);
        this.loadReviewBoardTypes();
      }
    });
  }

  loadReviewBoardTypes() {
    this.reviewBoardTypes$ = this.store.select(state => state.ReviewBoardTypes.data);
    this.reviewBoardTypesSubs = this.reviewBoardTypes$.subscribe(data => {
      if (data?.length) {
        this.reviewBoardTypes = data;
        this.getRouteHelpers();
      }
    });
  }

  displayRoute(e: Route) {
    return e ? e.path as string : '';
  }

  loadMenuItems() {
    this.menuItems$ = this.store.select(state => state.MenuItem.data);
    this.menuItemsSubs = this.menuItems$.subscribe(data => {
      if (data?.length) {
        this.menuItems = data;
        this.createMenuGroups();
      }
    });
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      labelCtrl: [{ value: false }],
      menuItemTypeCtrl: [{ value: '' }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      rolesCtrl: [{ value: false }],
      acronymCtrl: [{ value: false }],
      routeCtrl: [{ value: '' }],
      tableCtrl: [{ value: '' }],
      visibleToggleCtrl: [{ value: false }],
      parentCtrl: [{ value: false }]
    });
    if (this.disabled) { this.formGroup.disable(); }
  }

  createMenuGroups() {
    this.parentMenuItems = this.menuItems?.filter(m => m.menuItemType?.id == MenuItemTypeEnum.Submenu);
    this.menuItemGroups = [{ id: 0, name: 'Main Menu' } as MenuItem];
    const other = this.parentMenuItems?.filter(m => m.parentMenuItemID);
    other?.map(o => {
      if (!this.menuItemGroups?.map(m => m.id).includes(o.parentMenuItemID ?? 0)) {
        const group = this.menuItems?.find(m => m.id == o.parentMenuItemID);
        if (group)
          this.menuItemGroups?.push(group);
      }
    });
  }

  getRouteReplacements() {
    this.routeTables = [];
    this.routes?.filter(r => r.path?.includes('/:')).flatMap(r => r.path as string).map(path => {
      const p = path.split('/:');
      const routeTable = { path, table: p[0], param: p[1], helpers: [] } as RouteTable;
      this.routeTables?.push(routeTable);
    });
  }

  getRouteHelpers() {
    this.routeTables?.map(r => {
      switch (r.table) {
        case 'checklist-exec':
          r.helpers = this.templateTypes?.map(t => {
            const helper = { id: t.documentTypeID, description: t.execDocumentType?.description, route: r.table + '/' + t.documentTypeID } as RouteHelper;
            return helper;
          }).filter((obj, index, self) => index === self.findIndex(o => o.id === obj.id)).sort((a, b) => a.id - b.id) ?? [];
          break;
        case 'checklist-builder':
          r.helpers = this.templateTypes?.map(t => {
            const helper = { id: t.documentTypeID, description: t.documentType?.description, route: r.table + '/' + t.documentTypeID } as RouteHelper;
            return helper;
          }).filter((obj, index, self) => index === self.findIndex(o => o.id === obj.id)).sort((a, b) => a.id - b.id) ?? [];
          break;
        case 'schedulesV2':
          r.helpers = this.scheduleTypes?.flatMap(s => {
            const helper = { id: s.id, description: s.name, route: r.table + '/' + s.id } as RouteHelper;
            return helper;
          }).sort((a, b) => a.id - b.id) ?? [];
          break;
        case 'reviews':
          r.helpers = this.reviewBoardTypes?.flatMap(t => {
            const helper = { id: t.id, description: t.name, route: r.table + '/' + t.id } as RouteHelper;
            return helper;
          }).sort((a, b) => a.id - b.id) ?? [];
          break;
        case 'resources-new':
          r.helpers = [];
          r.helpers.push({ id: 0, description: 'Beamline Details', route: r.table });
          r.helpers.push({ id: 1, description: 'KE Builder', route: r.table + '/1' });
          r.helpers.push({ id: 2, description: 'RSS Database', route: r.table + '/2' });
          break;
      }
    });
    this.loading.emit(false);
  }

  getRouteTable() {
    let r: string;
    if (this.routeCtrl?.value?.path)
      r = this.routeCtrl.value.path;
    else
      r = this.routeCtrl?.value;
    const p = r.split('/') as string[];
    return this.routeTables?.find(t => t.table == p[0]);
  }

  validateRoute() {
    const val = this.routeCtrlValue();

    if (val.includes('/')) {
      const p = val.split('/');
      const table = this.routeTables?.find(t => t.table == p[0]);
      const route = table?.helpers.find(h => h.id.toString() == p[1]);
      if (!route) {
        this.routeCtrl?.setErrors({ invalid: true });
        return;
      }
    }
    this.routeCtrl?.setErrors(null);
  }

  routeCtrlValue() {
    const e = this.routeCtrl?.value;
    let val: string = '';
    if (e.source)
      val = e.source.value.path;
    else if (e.target)
      val = e.target.value;
    else if (e.path)
      val = e.path;
    else val = e;
    return val;
  }

  getGroupItems(group: MenuItem) {
    return this.parentMenuItems?.filter(m => m.id != this.menuItem?.id && (m.parentMenuItemID ?? 0) == group.id).sort((a, b) => this.utils.sort(a.order, b.order));
  }

  changed(e: any, formControlName: string) {
    this.setFormDirty();
    if (this.menuItem) {
      switch (formControlName) {

        case 'labelCtrl':
          this.menuItem.name = e.target.value;
          break;

        case 'menuItemTypeCtrl':
          this.menuItem.type = e.value;
          break;

        case 'rolesCtrl':
          const menuItemRoles = [] as MenuItemRole[];
          const roles = e as Role[];
          roles.map(r => {
            const menuItemRole = { menuItemID: this.menuItem?.id, roleID: r.id } as MenuItemRole;
            menuItemRoles.push(menuItemRole);
          });
          this.menuItem.menuItemRoles = menuItemRoles;
          break;

        case 'acronymCtrl':
          this.menuItem.code = e.target.value;
          break;

        case 'routeCtrl':
          if (e.source)
            this.menuItem.route = e.source.value.path;
          else if (e.target)
            this.menuItem.route = e.target.value;
          else if (e.path)
            this.menuItem.route = e.path;
          break;

        case 'tableCtrl':
          this.menuItem.table = e.value;
          break;

        case 'visibleToggleCtrl':
          this.menuItem.status = e.checked ? 1 : 0;
          this.menuItem.visible = e.checked;
          break;

        case 'parentCtrl':
          this.menuItem.parentMenuItemID = e.source.value.id;
          break;
      }
    }
    console.log(this.menuItem);
  }

  getRouteErrorMsg() {
    const path = this.routeCtrlValue() as any;
    if (path?.path?.includes(':')) return this.getRouteColonMessage(path)
    else return this.getErrorMsg(this.routeCtrl);
  }

  getRouteColonMessage(path: string) {
    this.routeCtrl?.setErrors({ action: true });
    const val = path.split(':')[1];
    return 'You must replace <b>:' + val + '</b> with the Value of the Route option.'
  }

  setFileID(e: number) {
    if (this.menuItem)
      this.menuItem.fileV2Id = e;
    this.setFormDirty();
  }

  create() {
    const menuItem = { id: 0, name: 'New Menu Item', menuItemRoles: [] as MenuItemRole[] } as MenuItem;
    this.service.setMenuItem(menuItem);
  }

  cancel() {
    if (!this.isFormDirty())
      this.reset();
    else
      this.getInfo();
    this.setFormDirty(false);
  }

  save() {
    if (!this.isValid()) return;

    if (this.menuItem?.id) {
      this.service.updateMenuOption(this.menuItem).toPromise().then(() => {
        this.alert.message('update', [new MessagePlaceholder('{what}', 'Menu Item: <b>' + this.menuItem?.name + '</b> ')]);
        this.reset();
      }).catch(() => this.alert.defaultError());
    }
    else if (this.menuItem) {
      this.service.createNewMenuOption(this.menuItem).toPromise().then(() => {
        this.alert.message('create', [new MessagePlaceholder('{what}', 'Menu Item: <b>' + this.menuItem?.name + '</b> ')]);
        this.reset();
      }).catch(() => this.alert.defaultError());
    }
  }

  isValid() {
    let result = true;
    result = this.validateRequiredControl(this.labelCtrl, 'Label', result);
    result = this.validateRequiredControl(this.menuItemTypeCtrl, 'Type', result);
    if (this.menuItem?.type == MenuItemTypeEnum.Route) {
      this.validateRoute();
      if (this.routeCtrl?.value?.path)
        result = this.validateRequiredControl(this.routeCtrl, 'Route', result, 'path');
      else
        result = this.validateRequiredControl(this.routeCtrl, 'Route', result);
    }

    if (this.menuItem?.type == MenuItemTypeEnum.Table)
      result = this.validateRequiredControl(this.tableCtrl, 'Table Name', result);
    this.formGroup.markAllAsTouched();
    return result;
  }

  reset() {
    this.menuItem = undefined;
    this.service.setMenuItem(this.menuItem);
    this.setFormDirty(false);
  }

}

interface RouteHelper {
  id: number;
  description: string;
  route: string;
}

interface RouteTable {
  path: string;
  table: string;
  param: string;
  helpers: RouteHelper[];
}
