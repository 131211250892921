<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-2">
        <h6>{{ number ?? "#" }}</h6>
      </div>
      <div class="col-9 pt-2">
        <div class="row">
          <div class="col-6">
            <h6
              [innerHTML]="textBefore ?? '' | placeholderHighlight : editor"
            ></h6>
          </div>
          <div class="col-3">
            <mat-form-field class="p-50">
              <mat-label>Incoming Monitor</mat-label>
              <input
                matInput
                placeholder="Incoming Monitor"
                [matAutocomplete]="autoMon"
                (keyup)="filterRadMonitor($event)"
                [formControl]="radMonitorCtrl"
              />
              <mat-autocomplete
                #autoMon="matAutocomplete"
                [displayWith]="displayObjectMon"
                (optionSelected)="radMonitorSelected($event.option.value)"
              >
                <mat-option
                  *ngFor="let radMonitor of radMonitorsFiltered"
                  [value]="radMonitor"
                >
                  {{ radMonitor.ehs }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="matWrapperNoPading" *ngIf="radMonitor">
              <mat-label>Cal Due Date</mat-label>
              <input
                matInput
                [matDatepicker]="calDueDatePicker"
                placeholder="mm/dd/yyyy"
                [(ngModel)]="calDueDate"
                (dateChange)="dateChanged()"
                [disabled]="disabled || (checked ?? false)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="calDueDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #calDueDatePicker></mat-datepicker>
            </mat-form-field>
            <div class="small nowrap" *ngIf="calDueDateUpdatedBy && radMonitor">
              Last updated by
              <span
                class="font-weight-bold"
                matTooltip="{{ calDueDateUpdatedBy.name }}"
                >{{ calDueDateUpdatedBy.initials }}</span
              >
              on
              <span class="date">{{
                calDueDateUpdatedOn | date : "short"
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2 d-flex flex-row justify-content-end">
        <mat-checkbox-with-roles
          [checked]="checked"
          [disabled]="disabled || !radMonitor"
          (change)="checkboxChanged($event)"
          color="primary"
          [roleCodes]="roleCodes"
          *ngIf="checkboxVisible"
          [user]="user"
          [date]="date"
        ></mat-checkbox-with-roles>
      </div>
    </div>
  </div>
</div>
