import { State, Selector, Action, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { BratoiCreate, BratoiDelete, BratoiRefresh, BratoiRefreshById, BratoiUpdate, BratoiUpdateAction } from './bratoi.action';
import { TOCARefresh } from '../toca/toca.action';
import { BeamlineRequiringApproval } from '../../models/beamline-requiring-approval';
import { BratoiService } from '../../bratoi.service';

export class BratoiStateModel {
  data: BeamlineRequiringApproval[] = [];
}

@State<BratoiStateModel>({
  name: 'Bratoi',
  defaults: {
    data: []
  }
})
@Injectable()
export class BratoiState {
  constructor(
    private bratoiService: BratoiService,
    private store: Store
  ) { }

  @Selector()
  static read(state: BratoiStateModel) {
    return state.data;
  }

  @Action(BratoiRefresh)
  refresh({ patchState }: StateContext<BratoiStateModel>) {
    this.bratoiService.GetBeamlinelApprovals().toPromise().then(data => {
      patchState({ data });
    }).catch(error => {
      console.error(error);
    });
  }

  @Action(BratoiRefreshById)
  refreshById({ getState, patchState }: StateContext<BratoiStateModel>, { id }: BratoiRefreshById) {
    this.bratoiService.GetBeamlinelApproval(id).subscribe(bratoi => {
      const state = getState();
      const index = state.data.findIndex(x => x.id === id);
      if (index >= 0) {
        state.data.splice(index, 1);
      }
      patchState({
        data: [...state.data, bratoi]
      });
    });
  }

  @Action(BratoiCreate)
  create({ getState, patchState }: StateContext<BratoiStateModel>, { item }: BratoiCreate) {
    const state = getState();
    patchState({
      data: [...state.data, item]
    });
    this.store.dispatch(new TOCARefresh());
  }

  @Action(BratoiUpdate)
  update({ getState, patchState }: StateContext<BratoiStateModel>, { id, item }: BratoiUpdate) {
    const state = getState();
    const index = state.data.findIndex(x => x.id === id);
    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
      this.store.dispatch(new TOCARefresh());
    }
  }

  @Action(BratoiUpdateAction)
  updateAction({ getState, patchState }: StateContext<BratoiStateModel>, { id, item, action }: BratoiUpdateAction) {
    this.bratoiService.UpdateActionBlApproval(id, action, item).toPromise().then(bratoi => {
      const state = getState();
      const index = state.data.findIndex(x => x.id === id);
      if (index >= 0) {
        state.data.splice(index, 1);
        patchState({
          data: [...state.data, bratoi]
        });
      }
    });
    this.store.dispatch(new TOCARefresh());
  }

  @Action(BratoiDelete)
  delete({ getState, patchState }: StateContext<BratoiStateModel>, { id }: BratoiDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }
}
