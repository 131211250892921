import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { OlogCategory } from '../../../services/olog-category';

@Component({
  selector: 'app-add-edit-olog-category',
  templateUrl: './add-edit-olog-category.component.html',
  styleUrls: ['./add-edit-olog-category.component.scss']
})
export class AddEditOlogCategoryComponent extends BaseComponent implements OnInit {

  public get ologCategoryNameCtrl(): AbstractControl | null { return this.formGroup.get('ologCategoryNameCtrl'); }

  constructor(
    protected override injector: Injector,

    public dialogRef: MatDialogRef<AddEditOlogCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public ologCategory: OlogCategory,
  ) {
    super(injector);

  }

  ngOnInit() {
    this.initializeForm();
  }

  add() {
    if (this.formGroup.valid) {
      this.dialogRef.close(this.ologCategory);
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      ologCategoryNameCtrl: [{ value: '' }, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]]
    });
  }


}
