<olog-messages-tab
  [documentType]="documentType"
  [disabled]="disabled"
></olog-messages-tab>

<!-- <app-olog-category-tab
  [documentType]="documentType"
  [isDisabled]="disabled"
  (changed)="ologCategoriesChanged($event)"
>
</app-olog-category-tab>
<app-olog-email-tab
  [documentType]="documentType"
  [isDisabled]="disabled"
  (changed)="ologEmailsChanged($event)"
>
</app-olog-email-tab> -->
