import { FormType } from 'src/app/common/enumerations/enumerations';
import { Checklist } from '../../checklists';
import { WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';


export class ChecklistsRefresh {
  static readonly type = '[Checklists] Refresh';
}

export class ChecklistsRefreshById {
  static readonly type = '[Checklists] RefreshById';
  constructor(public id: number) { }
}
export class ChecklistsRefreshByDocument {
  static readonly type = '[Checklists] RefreshByDocument';
  constructor(public id: FormType) { }
}

export class ChecklistsRefreshByDocumentMin {
  static readonly type = '[Checklists] RefreshByDocumentMin';
  constructor(public id: FormType) { }
}

export class ChecklistsCreate {
  static readonly type = '[Checklists] Create';

  constructor(public item: Checklist) { }
}

export class ChecklistsUpdate {
  static readonly type = '[Checklists] Update';

  constructor(public id: number, public item: Checklist) { }
}

export class ChecklistsDelete {
  static readonly type = '[Checklists] Delete';
  constructor(public id: number) { }
}

export class ChecklistsUpdateSection {
  static readonly type = '[ChecklistsSections] UpdateSection';
  constructor(public id: number, public item: WFSectionLocalResource) { }
}

export class ChecklistsRefreshSection {
  static readonly type = '[ChecklistsSections] RefreshSection';
  constructor(public id: number) { }
}
