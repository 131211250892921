import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Option } from "src/app/components/checklists/checklists";
import { AlertService } from "src/app/services/alert/alert.service";
@Component({
  selector: "cl-editor-radio-options",
  templateUrl: "./cl-editor-radio-options.component.html",
  styleUrls: ["./cl-editor-radio-options.component.scss"],
})
export class ClEditorRadioOptionsComponent implements OnInit {
  @Input() disabled?: boolean;
  @Input() options?: string;
  @Input() configuration?: string;

  tmpOptions: Option[] = [];
  multiple!: boolean;

  @Output() changed = new EventEmitter<string>();
  @Output() error = new EventEmitter<boolean>();
  constructor(private alert: AlertService) { }

  ngOnInit() {
    if (this.options) {
      this.tmpOptions = JSON.parse(this.options) as Option[];
    }
    if (this.configuration) {
      const m = JSON.parse(this.configuration).multiple;
      this.multiple = m;
    }
  }

  change() {
    try {
      const arry = this.tmpOptions.map((o) => o.value);
      const duplicateElements = arry.some((e, i) => arry.indexOf(e) !== i);
      const error = this.tmpOptions.find(
        (o) => !o.value || o.value == 0 || !o.label
      );
      if (!error && !duplicateElements) {
        this.error.emit(false);
        this.options = JSON.stringify(this.tmpOptions);
        this.changed.emit(this.options);
      } else if (error) {
        this.error.emit(true);
        this.alert.warning("Values and Labels cannot be empty or 0");
      } else if (duplicateElements) {
        this.error.emit(true);
        this.alert.warning("Values cannot be repeated!");
      }
    } catch (e) { }
  }


  add() {
    const opt = new Option();
    this.tmpOptions.push(opt);
    this.change();
  }

  del(e: any) {
    this.tmpOptions.splice(this.tmpOptions.indexOf(e), 1);
    this.change();
  }
}
