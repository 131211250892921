import { Component, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { FormControl, FormGroup } from '@angular/forms';
import { RadMonitor } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor';
import { RadMonitorService } from 'src/app/components/catalogs/rad-monitor/services/rad-monitor.service';
import { RadNote } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-note';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-cl-rad-monitor',
  templateUrl: './cl-rad-monitor.component.html',
  styleUrls: ['./cl-rad-monitor.component.scss']
})
export class ClRadMonitorComponent extends ClBaseComponent implements OnInit, OnDestroy, OnChanges {

  public radMonitorSubscription!: Subscription;

  public radMonitor?: RadMonitor;
  public radMonitors!: RadMonitor[];
  public radMonitorsFiltered!: RadMonitor[];
  public radMonitors$!: Observable<RadMonitor[]>;

  public valuesToSend: any[] = [];

  public faultNote!: RadNote;
  public calDueDateUpdatedBy?: User;
  public noteUpdatedBy!: User;

  public calDueDate?: Date | null;
  public calDueDateUpdatedOn!: Date;

  public changeoutReasonSelected!: string;

  radMonitorCtrl = new FormControl();

  outgoingMonitor?: RadMonitor | null;

  public changeoutReasons = [
    { label: 'Routine for Calibration', value: 'calibration' },
    { label: 'Monitor Failure.', value: 'failure' }
  ];


  constructor(
    protected override injector: Injector,
    private radMonitorService: RadMonitorService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.formGroup = new FormGroup({
      radMonitorCtrl: this.radMonitorCtrl
    });
    if (this.disabled || this.checked) {
      this.formGroup.disable();
    }
    else {
      this.formGroup.enable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadOutgoingMonitor();
  }

  loadOutgoingMonitor() {
    this.radMonitorService.getOutgoingMonitor().subscribe(value => {
      this.outgoingMonitor = value;
      this.getValues();
      this.loadRadMonitors();
    });
  }

  displayObjectMon(obj: any): string {
    return obj ? obj.ehs : '';
  }

  override ngOnDestroy(): void {
    this.radMonitorSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  loadRadMonitors(): void {
    this.radMonitors$ = this.store.select(state => state.RadMonitor.data);
    this.radMonitorSubscription = this.radMonitors$.subscribe(radMonitors => {
      if (!this.checked) {
        this.outgoingMonitor = radMonitors.find(x => x.id == this.outgoingMonitor?.id);
        this.radMonitors = radMonitors.filter(x => x.radLocation?.isEMShop && x.radLocation?.radLocationType?.id == this.outgoingMonitor?.radLocation?.radLocationType?.id && moment(x.calDueDate).isAfter(new Date())).sort((a, b) => this.utils.sort(a.ehs, b.ehs, true));
        this.radMonitorsFiltered = this.radMonitors;
        if (this.radMonitor)
          this.radMonitor = this.radMonitors.find(x => x.id == this.radMonitor?.id);
      }


      if (!this.radMonitor && this.checked) {
        this.calDueDate = null;
        this.radMonitorCtrl.setValue(null);
        if (this.getCurrentUser())
          this.answered.emit(false);
      }
    });
  }

  getRadMonitorById(id: number): RadMonitor | undefined {
    return this.radMonitors?.find(x => x.id === id);
  }

  radMonitorSelected(radMonitorSelected: RadMonitor) {
    this.addUpdateValues('monitorId', radMonitorSelected.id);
    this.addUpdateValues('locationId', radMonitorSelected.radLocationID);
    this.addUpdateValues('prevLocationId', radMonitorSelected.radLocationID);
    this.addUpdateValues('monitor', radMonitorSelected);
    this.save.emit({ text: JSON.stringify(this.valuesToSend), hasRefresh: true });
  }

  dateChanged() {
    if (typeof this.radMonitor?.calDueDate === 'string') { this.radMonitor.calDueDate = new Date(this.radMonitor.calDueDate); }
    this.radMonitor?.calDueDate?.setHours(0, 0, 0, 0);
    this.calDueDate?.setHours(0, 0, 0, 0);
    if (this.radMonitor?.calDueDate?.getTime() !== this.calDueDate?.getTime()) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        message: this.getMessage('RadMonitorComponent_SureToOverwrite').description
      };
      const dialogRef = this.dialog.open(YesNoDialogComponent, dialogConfig);
      dialogRef.afterClosed().toPromise().then(data => {
        if (data && this.radMonitor) {
          this.radMonitor.calDueDate = this.calDueDate;
          this.updateCalDueDate(this.radMonitor);
        } else {
          this.calDueDate = this.radMonitor?.calDueDate;
        }
      });
    }
  }

  updateCalDueDate(radMonitorToSave: RadMonitor) {
    if (radMonitorToSave.id)
      this.radMonitorService.UpdateRadMonitor(radMonitorToSave.id, radMonitorToSave).toPromise()
        .then(() => {
          this.addUpdateValues('calDueDateUpdatedBy', this.getCurrentUser());
          this.addUpdateValues('calDueDateUpdatedOn', new Date());
          this.save.emit(JSON.stringify(this.valuesToSend));
          this.alert.message('RadMonitorComponent_CalDueDateUpdated');
        })
        .catch(() => {
          this.alert.defaultError();
        });
  }

  checkboxChanged(e: any) {
    this.checked = e.checked;
    this.answered.emit(this.checked);
  }

  getValues() {
    this.initials = this.user ? this.user.initials : '';
    this.valuesToSend = this.utils.JSONparse(this.valueString);
    this.calDueDateUpdatedBy = this.valuesToSend?.find(x => x.key === 'calDueDateUpdatedBy')?.val;
    this.calDueDateUpdatedOn = this.valuesToSend?.find(x => x.key === 'calDueDateUpdatedOn')?.val;
    this.radMonitor = this.valuesToSend?.find(x => x.key === 'monitor')?.val;
    this.radMonitorCtrl.setValue(this.radMonitor);
    if (this.radMonitor) {
      this.calDueDate = this.radMonitor.calDueDate;
      this.radMonitorService.setIncomingMonitor(this.radMonitor);
    }
  }

  addUpdateValues(key: string, val: any) {
    if (!this.valuesToSend) { this.valuesToSend = []; }
    if (this.valuesToSend.some(x => x.key === key)) {
      const valueIndex = this.valuesToSend.map(x => x.key).indexOf(key);
      this.valuesToSend.splice(valueIndex, 1, { key, val });
    } else {
      this.valuesToSend.push({ key, val });
    }
  }

  displayRadMonitorName(radMonitor: RadMonitor) {
    return radMonitor && radMonitor.ehs ? radMonitor.ehs : '';
  }

  filterRadMonitor(e: any) {
    const text = e.target.value;
    this.radMonitorsFiltered = this.radMonitors.filter(x => x.ehs?.toLowerCase().includes(text.toLowerCase()));
  }

  saveData() {

  }

}
