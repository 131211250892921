import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import * as MBActions from './message-banner.actions';
import { MessageBanner } from '../../../components/messages/services/message-banner';
import { MessageBannerService } from '../../../components/messages/services/message-banner.service';
import { utils } from 'src/app/modules/libs/utils';

export interface MessageBannerStateModel {
  data: MessageBanner[];
}

@State<MessageBannerStateModel>({
  name: 'MessageBanners',
  defaults: {
    data: []
  }
})
@Injectable()
export class MessageBannerState {
  constructor(public service: MessageBannerService) { }

  @Action(MBActions.GetAll)
  GetAll(ctx: StateContext<MessageBannerStateModel>) {
    this.service.readAll().subscribe(data => {
      ctx.patchState({ data });
      this.service.messageBanners = data;
    });
  }

  @Action(MBActions.Update)
  Update(ctx: StateContext<MessageBannerStateModel>, { payload }: MBActions.Update) {
    const state = ctx.getState();
    const data = utils.cloneDeep(state.data);
    const index = data.findIndex(val => val.id === payload.id);
    data.splice(index, 1, payload);
    ctx.patchState({ data });
    this.service.messageBanners = data;
  }

  @Action(MBActions.Add)
  Add(ctx: StateContext<MessageBannerStateModel>, { payload }: MBActions.Add) {
    const state = ctx.getState();
    const data = utils.cloneDeep(state.data);
    data.splice(0, 0, payload);
    ctx.patchState({ data });
    this.service.messageBanners = data;
  }

  @Action(MBActions.Delete)
  Delete(ctx: StateContext<MessageBannerStateModel>, { payload }: MBActions.Delete) {
    const state = ctx.getState();
    const data = utils.cloneDeep(state.data);
    const index = data.findIndex(val => val.id === payload);
    data.splice(index, 1);
    ctx.patchState({ data });
    this.service.messageBanners = data;
  }
}
