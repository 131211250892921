import { APLCategoryManager } from "./apl-category-manager.model";

export class APLCategoryRoleCatalog {
  public id!: number;
  public name!: string;
  public code!: string;
  public status!: number;
  public aplCategoryManagers: APLCategoryManager[];

  constructor() {
    this.aplCategoryManagers = [];
  }
}
