import { Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { AbsiTemplateVersionResource } from '../../absi';
import { BaseComponent } from 'src/app/common/base/base.component';
import { WFSectionConfig } from 'src/app/components/workflow/workflow';
import { AbsiBuilderSectionComponent } from './absi-builder-section/absi-builder-section.component';

@Component({
  selector: 'absi-builder-main',
  templateUrl: './absi-builder-main.component.html',
  styleUrls: ['./absi-builder-main.component.scss']
})
export class AbsiBuilderMainComponent extends BaseComponent implements OnChanges {

  @Input() version?: AbsiTemplateVersionResource | null;
  @Output() loading = new EventEmitter<boolean>();

  @ViewChildren(AbsiBuilderSectionComponent)
  sectionComponents!: QueryList<AbsiBuilderSectionComponent>;

  sections?: WFSectionConfig[];
  wfTableId!: number;
  loadings: load[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.version) this.loading.emit(true);
    this.wfTableId = this.version?.wfTableID ?? 0;
    this.sections = this.version?.wfTableConfig?.wfSectionConfigs.filter(s => s.number && s.number < 100).sort((a, b) => a.number - b.number);
    this.loadings = [];
    this.sections?.map(s => {
      const l = { s: s.number, val: false };
      this.loadings.push(l);
    });
  }

  setLoading(e: boolean, s: number) {
    const section = this.loadings.find(l => l.s == s);
    if (section) section.val = e;
    this.loading.emit(this.loadings.some(l => l.val));
  }
}

export interface load {
  s: number;
  val: boolean;
}
