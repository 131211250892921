import { Component, EventEmitter, Injector, Input } from '@angular/core';
import { MenuItem, MenuItemRole } from '../../head-page/head-menu/head-menu.models';
import { MenuService } from '../menu.service';
import { MenuItemType } from 'src/app/common/enumerations/enumerations';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';

@Component({
  selector: 'menu-config-actions',
  templateUrl: './menu-config-actions.component.html',
  styleUrls: ['./menu-config-actions.component.scss']
})
export class MenuConfigActionsComponent extends BaseComponent {

  @Input() menuItem!: MenuItem;
  @Input() menuItems?: MenuItem[];
  @Input() index!: number;
  @Input() orientation!: Orientation;
  @Input() buttonClass?: string = 'blue';

  orientationEnum = Orientation;
  menuItemType = MenuItemType;

  constructor(
    protected override injector: Injector,
    private service: MenuService
  ) {
    super(injector);
  }

  async changeVisible(m: MenuItem, val: boolean) {
    if (await this.canDeactivate()) {
      m.status = val ? 1 : 0;
      this.service.updateMenuOption(m).toPromise();
    }
  }

  async edit(m: MenuItem) {
    if (await this.canDeactivate())
      this.service.setMenuItem(m);
  }

  async delete(m: MenuItem) {
    if (await this.canDeactivate() && m.id) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: "500px", data: {
          message: this.getMessage(2603)?.description.replace('{template}', 'this Menu Item'),
          icon: "stop"
        }
      });
      confirm.afterClosed().subscribe(async (data) => {
        if (data && m.id) {
          await this.service.deleteMenuOption(m.id).toPromise().then(() => this.alert.message('remove', [new MessagePlaceholder('{what}', 'Menu Item')])).catch(() => this.alert.defaultError());
        }
      });
    }
  }

  create(m: MenuItem) {
    const menuItem = { id: 0, name: 'New Menu Item', order: m.order, menuItemRoles: [] as MenuItemRole[], parentMenuItemID: m.parentMenuItemID } as MenuItem;
    this.service.setMenuItem(menuItem);
  }

  async addDivider(m: MenuItem) {
    const menuItem = { id: 0, name: '-- divider --', status: 1, type: -1, visible: true, order: m.order, isSeparator: true, parentMenuItemID: m.parentMenuItemID } as MenuItem;
    await this.service.createNewMenuOption(menuItem).toPromise().catch(() => this.alert.defaultError());
    this.alert.message('create', [new MessagePlaceholder('{what}', 'Divider')]);
  }

  move(m: MenuItem, way: number) {
    const index = this.menuItems?.findIndex(i => i.id == m.id) as number;
    const n = this.menuItems?.[index + way] as MenuItem;
    const o = n.order;
    n.order = m.order;
    m.order = o;
    this.service.updateMenuOptions([m, n]).toPromise().then(() => {
      this.alert.info('Item Moved');
    }).catch(() => this.alert.defaultError());
  }

}

export enum Orientation {
  Horizontal = 1,
  Vertical = 2
}
