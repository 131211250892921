import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PendingCatalogsRefresh } from './pending-catalogs.action';
import { PendingCatalogs } from 'src/app/services/pending-approvals/pending-approval';
import { CatalogService } from 'src/app/components/catalogs/catalog-service';

export class PendingCatalogsStateModel {
  data!: PendingCatalogs | null;
}

@State<PendingCatalogsStateModel>({
  name: 'PendingCatalogs',
  defaults: {
    data: null,
  }
})
@Injectable()
export class PendingCatalogsState {
  constructor(
    private service: CatalogService,
  ) { }

  @Selector()
  static read(state: PendingCatalogsStateModel) {
    return state.data;
  }

  @Action(PendingCatalogsRefresh)
  refresh({ patchState }: StateContext<PendingCatalogsStateModel>) {
    this.service.GetAllPendingCatalogs().subscribe(
      data => {
        patchState({ data });
      },
      error => console.error(error)
    );
  }

}
