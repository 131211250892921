import { BeamlineRequiringApprovalRelation } from "src/app/components/catalogs/bratoi/models/beamline-requiring-approval-relation";
import { ResourceTOCARelation } from "src/app/components/catalogs/beamline-catalog/resource/resources";

export class TOCA {
  id!: number;
  name!: string;
  status!: number;
  isActive?: boolean;
  wasDelete?: boolean;
  resourceTOCARelations?: ResourceTOCARelation[] | null;
  beamlineRequiringApprovalRelations?: BeamlineRequiringApprovalRelation[] | null;
}
