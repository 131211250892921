import { Component } from '@angular/core';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'head-logo',
  templateUrl: './head-logo.component.html',
  styleUrls: ['./head-logo.component.scss']
})
export class HeadLogoComponent {
  environment = environment;
  toolTipText!: string;
  constructor(public signalR: SignalRService) {
    this.toolTipText = `<h6>${environment.name}</h6>Version: ${environment.version}`;
  }

}
