import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ReviewTypesCreate, ReviewTypesDelete, ReviewTypesRefresh, ReviewTypesUpdate } from './review-types.action';
import { ReviewsService } from '../../reviews.service';
import { ReviewType } from '../../reviews.models';

export class ReviewTypesStateModel {
  data: ReviewType[] = [];
}

@State<ReviewTypesStateModel>({
  name: 'ReviewTypes',
  defaults: {
    data: []
  }
})
@Injectable()
export class ReviewTypesState {
  constructor(
    private _service: ReviewsService
  ) { }

  @Selector()
  static read(state: ReviewTypesStateModel) {
    return state.data;
  }

  @Action(ReviewTypesRefresh)
  refresh({ patchState }: StateContext<ReviewTypesStateModel>) {
    this._service.getReviewTypes().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(ReviewTypesCreate)
  create({ getState, patchState }: StateContext<ReviewTypesStateModel>, { item }: ReviewTypesCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(ReviewTypesUpdate)
  update({ getState, patchState }: StateContext<ReviewTypesStateModel>, { id, item }: ReviewTypesUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(ReviewTypesDelete)
  delete({ getState, patchState }: StateContext<ReviewTypesStateModel>, { id }: ReviewTypesDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

}
