import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss']
})
export class CancelDialogComponent extends BaseComponent implements OnInit {
  title = '';
  warning: string | null = '';
  placeholder = '';

  constructor(
    private cancelDialog: MatDialogRef<CancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.data.accept = false;
    this.data.cancel = false;
    this.warning = this.getMessage('CancelDialogWarning').description;
    if (this.data.amendment != undefined && this.data.amendment) {
      this.title = this.getMessage('CancelAmendmentDialogTitle').description;
      this.placeholder = this.getMessage('cancelAmendment').description;
    }
    else if (this.data.label) {
      this.title = this.data.label;
      this.placeholder = this.getMessage('cancelForm').description;
      this.warning = null;
    } else {
      this.title = this.getMessage('CancelFormDialogTitle').description;
      this.placeholder = this.getMessage('cancelForm').description;
    }
  }

  cancel() {
    this.data.accept = false;
    this.data.cancel = true;
    this.cancelDialog.close(this.data);
  }

  accept() {
    this.data.accept = true;
    this.data.cancel = false;
    this.cancelDialog.close(this.data);
  }

}
