<div cdkDropList (cdkDropListDropped)="drop($event)">
  <div
    class="row mt-3 mb-3 example-box"
    *ngFor="let aplGroup of aplGroups"
    cdkDrag
    [cdkDragDisabled]="!(isSA() || isManager()) || !enableMove"
  >
    <div class="col">
      <apl-category-v2-group-card
        [aplGroup]="aplGroup"
        [reorderable]="enableMove"
        [rolesSelected]="rolesSelected"
        [aplGroupCategoryManager]="isManager()"
        [isSuperAdmin]="isSA()"
        (loading)="loading.emit($event)"
        [filter]="filter"
      ></apl-category-v2-group-card>
    </div>
  </div>
</div>
