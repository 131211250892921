import { Component, Input } from '@angular/core';
import { WFComponent } from 'src/app/components/workflow/workflow';
import { ComponentService } from 'src/app/services/work-flow/component.service';

@Component({
  selector: 'cl-editor-component-help',
  templateUrl: './cl-editor-component-help.component.html',
  styleUrls: ['./cl-editor-component-help.component.scss']
})
export class ClEditorComponentHelpComponent {

  @Input() component!: WFComponent;

  constructor(
    private service: ComponentService
  ) { }

  close() { }

  changed(text: string) {
    this.component.helpText = text;
    this.service.update(this.component).toPromise();
  }
}
