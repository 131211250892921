<div class="d-flex">
  <mat-icon
    *ngIf="warning"
    color="warn"
    class="warn"
    [matTooltip]="warning"
    matTooltipClass="warn-tooltip"
    matTooltipPosition="left"
    >warning</mat-icon
  >
  <div class="checkbox-container ml-3" style="white-space: pre-line">
    <mat-checkbox
      [labelPosition]="labelPosition"
      [(ngModel)]="checked"
      [disabled]="disabled ?? false"
      (change)="changed($event)"
      [color]="color"
      [date]="date"
      [attr.data-initials]="user?.initials"
      [matTooltip]="checked ? getTooltip() : ''"
      matTooltipPosition="left"
      matTooltipClass="user-tooltip"
      class="m-0 checkbox"
    >
      <span [innerHTML]="label | placeholderHighlight : editor"></span>
    </mat-checkbox>
    <div class="label-container" *ngIf="checked">
      <mat-label *ngIf="user?.id != -1; else checkIcon">{{
        checked ? user?.initials : null
      }}</mat-label>
      <ng-template #checkIcon>
        <mat-icon>check</mat-icon>
      </ng-template>
    </div>
  </div>
</div>
