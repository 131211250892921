import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { RadMeter, RadMeterType } from '../rad-meters';
import { RadMetersTypesDetailsComponent } from 'src/app/components/catalogs/meters-catalog/rad-meters-types/rad-meters-types-details/rad-meters-types-details.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { RadMetersService } from '../services/rad-meters.service';

@Component({
  selector: 'rad-meters-types',
  templateUrl: './rad-meters-types.component.html',
  styleUrls: ['./rad-meters-types.component.scss']
})
export class RadMetersTypesComponent extends BaseComponent implements OnInit {
  public valueFilter = '';
  public dataSource: MatTableDataSource<RadMeterType> = new MatTableDataSource<RadMeterType>();
  public showInactive!: boolean;
  public loading = false;
  public radMetersTypesList?: RadMeterType[];
  public displayedColumns: string[] = ['name', 'description', 'status', 'actions'];
  public createAvailable!: boolean;
  public selectedRowIndex = -1;
  public pending = false;

  constructor(
    protected override injector: Injector,
    private dialogRef: MatDialogRef<RadMetersTypesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RadMeter,
    private _service: RadMetersService,
  ) {
    super(injector);

  }

  ngOnInit(): void {
    this.clear();
    this.loadMeterTypes();
    this.createAvailable = true;
  }

  clear() {
    this.showInactive = true;
    this.selectedRowIndex = 0;
    this.dataSource = new MatTableDataSource();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async loadMeterTypes() {
    this.loading = true;
    this.valueFilter = '';
    await this._service.getRadMeterTypes().toPromise().then(result => {
      if (!result) {
        result = [];
      }
      this.radMetersTypesList = result;
      if (!this.showInactive) {
        this.dataSource = new MatTableDataSource<RadMeterType>(result.filter(x => x.status == 1));
      } else {
        this.dataSource = new MatTableDataSource<RadMeterType>(result);
      }
    }).finally(() => this.loading = false);
  }

  refreshMeterTypes() {
    this.loadMeterTypes();
    this.selectedRowIndex = -1;
  }

  showMetersTypesInactive() {
    this.loadMeterTypes();
  }

  sortData(sort: Sort) {
    const data = this.radMetersTypesList;
    let sortedData: RadMeterType[];
    if (!sort.active || sort.direction === '') {
      sortedData = data ?? [];
      return;
    }
    sortedData = data?.sort((a, b) => {
      const isDesc = sort.direction === 'desc';
      switch (sort.active) {
        case 'name': return compare(a.name, b.name, isDesc);
        case 'description': return compare(a.description, b.description, isDesc);
        case 'status': return compare(a.status, b.status, isDesc);
        default: return 0;
      }
    }) ?? [];
    this.dataSource = new MatTableDataSource(sortedData);
  }

  highlight(row: any) {
    if (!this.pending) {
      this.selectedRowIndex = row.id;
    }
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex(r => {
      return r.id == row.id;
    });
    return i;
  }

  getColor(radMeterType: RadMeterType): string {
    if (radMeterType.status == 0) {
      return '#D8D8D8';
    }
    else {
      return '#000000';
    }
  }

  createMeterType() {
    this.selectedRowIndex = 0;
    const MeterTypeNew: RadMeterType = {} as RadMeterType;
    MeterTypeNew.id = 0;
    MeterTypeNew.description = '';
    MeterTypeNew.code = '';
    MeterTypeNew.status = 1;
    MeterTypeNew.name = '';
    MeterTypeNew.type = 0;
    this.openDetailsMeterType(MeterTypeNew);
  }

  openDetailsMeterType(MeterTypeNew: RadMeterType) {
    const dataDialog = MeterTypeNew;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = dataDialog;
    dialogConfig.minWidth = '35vw';
    dialogConfig.maxHeight = '85%';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(RadMetersTypesDetailsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (data == 'Create') {
          this.alert.success(this.getMessage('RAD_Meters_Catalog_Create_Type_Succeed')?.description);
        }
        if (data == 'Update') {
          this.alert.success(this.getMessage('RAD_Meters_Catalog_Update_Type_Succeed').description);
        }
        this.refreshMeterTypes();
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  async deleteMeterType(element: RadMeterType) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('RAD_Meters_Catalog_Confirm_Delete_Type')?.description,
        icon: 'stop'
      }
    });
    confirm.afterClosed().toPromise().then(async data => {
      if (data && element.id) {
        this.loading = true;
        await this._service.deleteRadMeterType(element.id).toPromise().then(result => {
          this.alert.success(this.getMessage('RAD_Meters_Catalog_Delete_Type_Succeed')?.description);
        }).catch(() => {
          this.alert.message('genericError');
        });
      }
    }, error => {
    }).finally(() => {
      this.loading = false;
      this.refreshMeterTypes();
    });
  }

  EditMeterType(element: RadMeterType) {
    this.openDetailsMeterType(element);
  }
}

function compare(a?: number | string, b?: number | string, isAsc?: boolean) {
  if (a && b)
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  return 1;
}
