<div class="sectionx">
  <div *ngFor="let s of signatures">
    <div *ngIf="s.required && s.type" class="row no-gutters">
      <div class="col-12 p-1">
        <master-control
          [type]="s.type"
          [visible]="s.visible"
          [disabled]="s.disabled"
          [currentChecklist]="absi"
          [color]="'primary'"
          [enabled]="true"
          [number]="s.number"
          [header]="s.name"
          [question]="s.question"
          [label]="s.name"
          [text]="s.question"
          [radioQuestion]="s.question2"
          [radioOptions]="s.options"
          [options]="s.options"
          [radioSelection]="s.value2"
          [checkbox_checked]="s.approved"
          [approved]="s.approved"
          [approvedBy]="s.approvedBy"
          [approvedOn]="s.approvedOn"
          [roleCodes]="s.roleCodes"
          [textBefore]="s.question"
          [textAfter]="s.question2"
          [textAfter2]="s.question3"
          [textValue]="s.textValue"
          [checkboxVisible]="s.visible"
          [disableEdit]="s.disableEdit"
          [warning]="s.warning"
          [moreInfo]="s.moreInfo"
          [scheduleResourceId]="s.scheduleResourceId"
          [dateValue1]="s.dateValue1"
          [dateValue2]="s.dateValue2"
          [dateValue3]="s.dateValue3"
          [scheduleName]="s.scheduleName"
          [locationName]="s.locationName"
          [configuration]="s.configuration"
          [userNames]="s.moreInfo"
          [formType]="'ABSI'"
          [formId]="absi?.id"
          [sectionNumber]="section?.number"
          [sectionId]="section?.id"
          [taskId]="s.taskId"
          [serialNo]="absi?.serialNo"
          [message]="message"
          style="width: 100%"
          (approve)="approve(s.taskId, s.approveId)"
          (unapprove)="unapprove(s.taskId, s.unapproveId)"
          (checked)="check($event, s.taskId, s.approveId, s.unapproveId)"
          (save)="save($event, s.id, s.taskId, s.approveId, s.code)"
        >
        </master-control>
      </div>
    </div>
  </div>
</div>
