import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnInit, OnDestroy, Injector, ElementRef, ViewChild, HostListener } from '@angular/core';
import { RelatedDocument } from 'src/app/services/documents/documents';
import { LogToShowResource } from 'src/app/services/logs/log-to-show-resource';
import { SignResource } from 'src/app/services/logs/signs';
import { WfSignatureResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { SCFMaster, SCFAmendment } from '../../scf';
import { WorkerVerification } from 'src/app/services/worker-verification/worker-verification';
import { WorkerVerificationService } from 'src/app/services/worker-verification/worker-verification.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { SCFStatus, ScfV2Service } from '../../scf-v2.service';
import * as moment from 'moment';
import { CommentDelete } from '../../../../controls/comments-and-attachments/store/comments.action';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'scf-log',
  templateUrl: './scf-log.component.html',
  styleUrls: ['./scf-log.component.scss']
})

export class SCFLogComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() scfMaster?: SCFMaster | null;
  @Output() requestRefresh = new EventEmitter();
  generalLogs: LogToShowResource = {};
  show = false;
  signLogs?: SignResource[] = [];
  amendmentsLogs: SignResource[] = [];
  loading = false;
  showUpdates = false;
  showAddedUpdates = false;
  showDeletedUpdates = false;
  showModifiedUpdates = false;
  hasOriginal = false;
  originalBody?: SCFMaster;
  workerVerifications?: WorkerVerification[] = [];

  length = 100;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 25];

  status?: SCFStatus;
  showBoxes?: boolean;

  @ViewChild('original') contentToPrint?: ElementRef;

  constructor(
    private wfService: WorkFlowService,
    private scfService: ScfV2Service,
    private workerVerificationService: WorkerVerificationService,
    protected override injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.scfMaster) {
      this.refresh();
    }
  }

  refresh() {
    if (this.scfMaster?.id) {
      this.loading = true;
      this.wfService.getLogsBySn(this.scfMaster.serialNo).subscribe(response => {
        this.generalLogs = response;
        if (this.generalLogs) {
          this.show = true;
        }
        this.fillLogs();
        if (this.scfMaster?.type && this.scfMaster?.id) {
          this.workerVerificationService.getByForm(this.scfMaster.type, this.scfMaster.id).toPromise().then(data => {
            this.workerVerifications = data;
            for (const wv of this.workerVerifications ?? []) {
              const WorkerVerificationSignature: SignResource = {
                name: 'Worker Verification',
                signedBy: wv.user?.name,
                signedOn: wv.date,
                amendmentId: 0,
              };
              this.generalLogs.signs?.push(WorkerVerificationSignature);
            }
          });
        }
        this.generalLogs.signs = this.generalLogs.signs?.sort((a, b) => moment(a.signedOn).isAfter(b.signedOn) ? 1 : moment(a.signedOn).isBefore(b.signedOn) ? -1 : 0);
        this.signLogs = this.generalLogs.signs?.filter(s => s.amendmentId === 0);
        this.signLogs?.map(x => {
          if (x.name?.includes('Disapproved') && !x.reason) {
            x.name = x.name.replace('Disapproved', 'Unsubmitted');
          }
        });
      });
    } else {
      this.show = false;
    }
  }

  fillLogs() {
    if (this.generalLogs.originalBody) {
      this.generalLogs.originalBodySCF = JSON.parse(this.generalLogs.originalBody) as SCFMaster;
      if (this.generalLogs.originalBodySCF) {
        this.generalLogs.originalBodySCF.createdByName = this.getUsers()?.find(u => u.id == this.generalLogs.originalBodySCF?.createdBy)?.name;
        this.generalLogs.originalBodySCF.requesterName = this.getUsers()?.find(u => u.id == this.generalLogs.originalBodySCF?.requesterId)?.name;
        this.originalBody = this.generalLogs.originalBodySCF as SCFMaster;
        this.originalBody.shieldingNames = '';
        this.status = { isViewingAmendment: false } as SCFStatus;
      }
      if (this.originalBody?.shieldingAffected) {
        (JSON.parse(this.originalBody.shieldingAffected)).map((shielding: any) => {
          if (shielding && this.originalBody) {
            this.originalBody.shieldingNames += shielding.childResourceName + ', ';
          }
        });
        this.originalBody.shieldingNames = this.originalBody.shieldingNames?.slice(0, -2);
      }
      this.hasOriginal = true;
    }
    this.fillUpdates();
  }

  fillUpdates() {
    if (this.generalLogs?.originalBodySCF?.wfTableID) {

      // #REGION SET AMENDMENTSLIST
      let wfSignatures = this.scfMaster?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 6)?.wfSignatures;
      wfSignatures = wfSignatures?.filter(s => s.taskID !== 0);
      if (wfSignatures)
        this.scfService.amendmentsList = this.getAmendmentList(wfSignatures);
      // #END REGION SET AMENDMENTSLIST

      if (this.generalLogs?.updates?.length) {
        this.generalLogs.updates.map((update, i) => {
          if (update.oldBody) {
            update.oldBodySCF = JSON.parse(update.oldBody);
            if (update.oldBodySCF) {
              update.oldBodySCF.shieldingNames = '';
              if (update.oldBodySCF.shieldingAffected) {
                (JSON.parse(update.oldBodySCF.shieldingAffected)).map((shielding: any) => {
                  if (shielding && update.oldBodySCF) {
                    update.oldBodySCF.shieldingNames += shielding.childResourceName + ', ';
                  }
                });
                update.oldBodySCF.shieldingNames = update.oldBodySCF.shieldingNames.slice(0, -2);
              }
            }
          }
          if (update.oldBodySCF) {
            update.oldBodySCF.createdByName = this.getUsers()?.find(u => u.id == update.oldBodySCF?.createdBy)?.name;
            update.oldBodySCF.requesterName = this.getUsers()?.find(u => u.id == update.oldBodySCF?.requesterId)?.name;
          }
          if (update.added && update.added.length > 0) {
            this.showAddedUpdates = true;
          }
          if (update.deleted && update.deleted.length > 0) {
            this.showDeletedUpdates = true;
          }
          if (update.modified && update.modified.length > 0) {
            this.showModifiedUpdates = true;
          }
          this.generateAmendmentViews();
          update.header = this.getScfLogHeadText(update.amendmentId);
        });
        this.showUpdates = true;
      } else { this.showUpdates = false; }
    } else {
      this.showUpdates = false;
    }
    this.loading = false;
  }

  generateAmendmentViews() {
    this.generalLogs.updates?.map((update, i) => {
      let scfMasterAmended = {} as SCFMaster;

      if (update.oldBodySCF && this.scfMaster) {
        update.status = { isViewingAmendment: true } as SCFStatus;
        update.scfMaster = update.oldBodySCF as SCFMaster;

        if (this.generalLogs.updates && i < this.generalLogs.updates.length - 1)
          scfMasterAmended = this.generalLogs.updates[i + 1].oldBodySCF as SCFMaster;
        else if (this.generalLogs.updates && i == this.generalLogs.updates?.length - 1)
          scfMasterAmended = this.scfMaster;

        if (scfMasterAmended)
          update.scfMaster.scfAmendments = [this.createAmendment(scfMasterAmended)];
      }
    });
  }

  getScfLogHeadText(amendmentId?: number): string {
    const fakeAmendmentId = this.findIndex(amendmentId);
    const res = (fakeAmendmentId ? ('<span class="yellow">Amendment ' + fakeAmendmentId) : '<span class="red">Amendment Cancelled') + '</span>';
    return res;
  }

  createAmendment(scfMaster: SCFMaster): SCFAmendment {
    return {
      id: 0,
      active: true,
      scfID: this.scfMaster?.id,
      name: scfMaster.name,
      description: scfMaster.description,
      status: scfMaster.status,
      type: scfMaster.type,
      createdOn: scfMaster.createdOn,
      createdBy: scfMaster.createdBy,
      locations: scfMaster.locations,
      locationNames: scfMaster.locationNames,
      locationIds: scfMaster.locationIds,
      accRFShielding: scfMaster.accRFShielding,
      blShielding: scfMaster.blShielding,
      permanentAccRemoval: scfMaster.permanentAccRemoval,
      permanentBLRemoval: scfMaster.permanentBLRemoval,
      tocaMovement: scfMaster.tocaMovement,
      shieldingAffected: scfMaster.shieldingAffected,
      requesterId: scfMaster.requesterId,
      estimatedEndOn: scfMaster.estimatedEndOn,
      estimatedStartOn: scfMaster.estimatedStartOn,
      notificationMessage: scfMaster.notificationMessage,
      notifyARC: scfMaster.notifyARC,
      notifyBLSci: scfMaster.notifyBLSci,
      notifyBRC: scfMaster.notifyBRC,
      notifyHP: scfMaster.notifyHP,
      notifyRF: scfMaster.notifyRF,
      notifyRP: scfMaster.notifyRP,
      notifySnA: scfMaster.notifySnA,
      scfRestrictionsV2: scfMaster.scfRestrictionsV2,
      relatedDocuments: scfMaster.relatedDocuments,
      relatedLinks: scfMaster.relatedLinks
    } as SCFAmendment;
  }

  findIndex(amendmentId?: number): number {
    if (amendmentId) {
      let fakeAmendmentId = this.scfService.amendmentsList.findIndex(amid => amid === amendmentId);
      if (fakeAmendmentId >= 0)
        return fakeAmendmentId + 1;
    }
    return 0;
  }

  getAmendmentList(wfSignatures: WfSignatureResource[]): number[] {
    let olderAmendmentId = -1;
    let res: number[] = [];
    wfSignatures.map(t => {
      if (t.amendmentID !== olderAmendmentId) {
        olderAmendmentId = t.amendmentID;
        res.push(olderAmendmentId);
      }
    });

    res = [...new Set(res)].sort((a, b) => a - b);
    return res;
  }

  // setPageSizeOptions(setPageSizeOptionsInput: string) {
  //   this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  // }

  // maprelatedDocument(RelatedDocuments?: RelatedDocument[]) {
  //   return RelatedDocuments?.map(({ relatedDocumentSerialNo }) => relatedDocumentSerialNo).join(', ');
  // }



  getTopPosition(parent: HTMLDivElement) {
    const rect = parent.getBoundingClientRect();
    if (rect.top < 170 && rect.bottom > 250)
      return {
        position: 'fixed', top: '169px', right: '90px', 'padding-bottom': '3px', 'padding-top': '5px',
        'box-shadow': '0 2px 3px #aaa'
      };
    else return { position: 'relative' };
  }


}
