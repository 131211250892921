import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProcedureTrainingCredited } from '../../models/procedure-training-credited.model';
import { Procedure } from '../../models/procedure.model';

@Component({
  selector: 'app-credited-date-popup',
  templateUrl: './credited-date-popup.component.html',
  styleUrls: ['./credited-date-popup.component.scss']
})
export class CreditedDatePopupComponent implements OnInit {

  procedureTrainingCredited: ProcedureTrainingCredited = new ProcedureTrainingCredited();
  procedure!: Procedure;

  constructor(public dialog: MatDialogRef<CreditedDatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      procedure: Procedure
    }) { }

  ngOnInit(): void {
    this.procedure = this.data.procedure;
  }

  cancel() {
    this.dialog.close(false);
  }

  accept() {
    this.dialog.close(this.procedureTrainingCredited);
  }

}
