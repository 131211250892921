<div class="dialog-container" resizableDialog>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-12 col-md-6 margin-auto">
          <h5>Component properties</h5>
        </div>
        <div class="col-12 col-md-4">
          <mat-form-field class="no-subscript">
            <mat-label>Component Type</mat-label>
            <input
              [disabled]="disabled"
              aria-label="type"
              matInput
              [(ngModel)]="component"
              [matAutocomplete]="autoType"
              (keyup)="filterControls($event)"
            />
          </mat-form-field>
          <mat-autocomplete
            [disabled]="disabled"
            class="autocomplete"
            #autoType="matAutocomplete"
            (optionSelected)="selectedType($event)"
            [displayWith]="displayType"
          >
            <mat-option
              *ngFor="let component of filteredComponents"
              [value]="component"
              matTooltip="{{ component.name }}"
            >
              {{ component.name }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="col-2 pt-2 text-right">
          <button
            class="btn btn-red ml-2"
            (click)="cancel()"
            [matTooltip]="'Cancel changes'"
          >
            <mat-icon>cancel</mat-icon>
          </button>
          <button
            *ngIf="!disabled"
            class="btn btn-green ml-2"
            (click)="accept()"
            [disabled]="checkAcceptDisabled()"
          >
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-9 pr-1 editor-body">
          <div class="row">
            <div class="col-12">
              <div class="pb-2">
                <mat-form-field
                  class="no-subscript mt-2"
                  *ngIf="
                    !component?.numericHeader &&
                    type != 13 &&
                    type != 7 &&
                    type != 22
                  "
                >
                  <mat-label>Label</mat-label>
                  <input
                    [disabled]="disabled"
                    aria-label="name"
                    matInput
                    type="text"
                    [(ngModel)]="name"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </mat-form-field>
              </div>
              <div *ngIf="component?.textFields ?? 0 >= 1">
                <div class="row">
                  <div class="col-6">
                    <h6>Text field(s)</h6>
                  </div>
                  <div class="col-6 d-flex justify-content-end">
                    <span
                      id="placeholderMessage"
                      class="placeholderMessage small"
                      [innerHTML]="clipboardMessage"
                    ></span>
                    <button
                      mat-stroked-button
                      color="warn"
                      (click)="placeholders()"
                    >
                      {{ "{ }" }} <span class="text">Placeholders</span>
                    </button>
                  </div>
                </div>

                <div class="pb-1" *ngIf="(component?.textFields ?? 0) >= 1">
                  <comments-box
                    [disabled]="disabled"
                    (textChanged)="textChanged($event, 1)"
                    [textValue]="t1"
                    [formType]="formType"
                    [serialNo]="serialNo"
                    [title]="'Text 1'"
                    [editor]="true"
                    [noSubscript]="true"
                  ></comments-box>
                </div>
                <div class="pb-1" *ngIf="(component?.textFields ?? 0) >= 2">
                  <comments-box
                    [disabled]="disabled"
                    (textChanged)="textChanged($event, 2)"
                    [textValue]="t2"
                    [formType]="formType"
                    [serialNo]="serialNo"
                    [title]="'Text 2'"
                    [editor]="true"
                    [noSubscript]="true"
                  ></comments-box>
                </div>

                <div class="pb-1" *ngIf="(component?.textFields ?? 0) >= 3">
                  <comments-box
                    [disabled]="disabled"
                    (textChanged)="textChanged($event, 3)"
                    [textValue]="t3"
                    [formType]="formType"
                    [serialNo]="serialNo"
                    [title]="'Text 3'"
                    [editor]="true"
                    [noSubscript]="true"
                  ></comments-box>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6" *ngIf="component?.needActions">
              <div class="row mt-2">
                <div class="col-4 action">
                  <mat-checkbox
                    [disabled]="disabled"
                    color="primary"
                    [(ngModel)]="approve"
                    [labelPosition]="'before'"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="actionChanged()"
                  >
                    <mat-label>Approve</mat-label>
                  </mat-checkbox>
                </div>
                <div class="col-7">
                  <cl-editor-status
                    [disabled]="disabled"
                    *ngIf="approve"
                    [action]="1"
                    [configuration]="s.configuration"
                    [color]="color"
                    [statusLabel]="'Next Status'"
                    [checklistTemplate]="checklistTemplate"
                    (selected)="actionStatusSelected($event, 1)"
                  ></cl-editor-status>
                </div>
              </div>
              <div class="row">
                <div class="col-4 action">
                  <mat-checkbox
                    [disabled]="disabled"
                    color="accent"
                    [(ngModel)]="unapprove"
                    [ngModelOptions]="{ standalone: true }"
                    [labelPosition]="'before'"
                    (change)="actionChanged()"
                  >
                    <mat-label>Unapprove</mat-label>
                  </mat-checkbox>
                </div>
                <div class="col-7">
                  <cl-editor-status
                    [disabled]="disabled"
                    *ngIf="unapprove"
                    [action]="2"
                    [configuration]="s.configuration"
                    [color]="color"
                    [statusLabel]="'Next Status'"
                    [checklistTemplate]="checklistTemplate"
                    (selected)="actionStatusSelected($event, 2)"
                  ></cl-editor-status>
                </div>
              </div>
              <div class="row">
                <div class="col-4 action">
                  <mat-checkbox
                    [disabled]="disabled"
                    color="warn"
                    [(ngModel)]="disapprove"
                    [labelPosition]="'before'"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="actionChanged()"
                  >
                    <mat-label>Disapprove</mat-label>
                  </mat-checkbox>
                </div>
                <div class="col-7">
                  <cl-editor-status
                    [disabled]="disabled"
                    *ngIf="disapprove"
                    [action]="3"
                    [configuration]="s.configuration"
                    [color]="color"
                    [statusLabel]="'Next Status'"
                    [checklistTemplate]="checklistTemplate"
                    (selected)="actionStatusSelected($event, 3)"
                  ></cl-editor-status>
                </div>
              </div>
            </div>

            <div
              class="col-6 pt-2"
              *ngIf="component?.needRoles && !component?.hasMultipleRoles"
            >
              <role-selection
                [noSubscript]="true"
                [roles]="roles"
                [disabled]="disabled"
                (deleted)="removeRole($event)"
                (selected)="selectedRole($event)"
              ></role-selection>
            </div>

            <div
              class="col-6 pt-2"
              *ngIf="type == componentType.PlaceholderCondition"
            >
              <cl-editor-placeholder-select
                [configuration]="s.configuration"
                [text]="t1"
                (selected)="placeHolderSelected($event)"
              >
              </cl-editor-placeholder-select>
            </div>

            <div class="col-6 pt-2" *ngIf="type == componentType.StatusChange">
              <cl-editor-status-change
                [disabled]="disabled"
                [configuration]="s.configuration"
                [color]="color"
                [checklistTemplate]="checklistTemplate"
                (changed)="statusSelected($event)"
              ></cl-editor-status-change>
            </div>

            <div
              class="col-6 pt-2"
              *ngIf="type == componentType.RadMonitorDueDate"
            >
              <cl-editor-rad-locations
                [disabled]="disabled"
                [configuration]="s.configuration"
                (changed)="configurationChanged($event)"
              >
              </cl-editor-rad-locations>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3 pt-2 editor-body">
          <div class="row">
            <div class="col-12 pb-2" *ngIf="component?.hasConditions">
              <mat-form-field class="no-subscript mb-1">
                <mat-label>Conditions</mat-label>
                <input
                  [disabled]="disabled"
                  aria-label="condition"
                  matInput
                  type="text"
                  [(ngModel)]="condition"
                  [ngModelOptions]="{ standalone: true }"
                />
                <button
                  class="dd-button"
                  mat-icon-button
                  matSuffix
                  color="primary"
                >
                  <mat-icon>help</mat-icon>
                </button>
              </mat-form-field>
              <cl-editor-hide-disable-toggle
                [disabled]="disabled"
                *ngIf="condition"
                [configuration]="s.configuration"
                (changed)="configurationChanged($event)"
              ></cl-editor-hide-disable-toggle>
            </div>
            <div class="col-12" *ngIf="component?.hasTag">
              <mat-form-field id="tagBox" class="no-subscript">
                <mat-label>Tag</mat-label>
                <input
                  [disabled]="disabled"
                  aria-label="code"
                  matInput
                  type="text"
                  [(ngModel)]="code"
                  [ngModelOptions]="{ standalone: true }"
                />
                <button
                  class="dd-button"
                  mat-icon-button
                  matSuffix
                  color="primary"
                  (click)="showTagInfo()"
                >
                  <mat-icon>help</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="col-12 pt-2" *ngIf="component?.hasRadioButtons">
              <cl-editor-radio-options
                [disabled]="disabled"
                [options]="tmpOptions"
                [configuration]="s.configuration"
                (changed)="optionsChange($event)"
                (error)="error = $event"
                *ngIf="type != componentType.PlaceholderCondition"
              ></cl-editor-radio-options>
              <cl-editor-placeholder-options
                [disabled]="disabled"
                [options]="tmpOptions"
                [configuration]="s.configuration"
                (changed)="optionsChange($event)"
                *ngIf="type == componentType.PlaceholderCondition"
              >
              </cl-editor-placeholder-options>
            </div>
            <div
              class="col-12 pt-2"
              *ngIf="
                component?.hasMultipleCheckboxes && !component?.hasMultipleRoles
              "
            >
              <cl-editor-checkbox-options
                [disabled]="disabled"
                [options]="tmpOptions"
                [configuration]="s.configuration"
                (changed)="optionsChange($event)"
                (checkbox)="checkboxChanged($event)"
                (error)="error = $event"
              ></cl-editor-checkbox-options>
            </div>
            <div class="col-12 pt-2" *ngIf="component?.hasMultipleTextboxes">
              <cl-editor-textbox-options
                [disabled]="disabled"
                [options]="tmpOptions"
                [configuration]="s.configuration"
                (changed)="optionsChange($event)"
              ></cl-editor-textbox-options>
            </div>

            <div
              class="col-12 pt-2"
              *ngIf="
                type == componentType.ScheduleAndCheckbox ||
                type == componentType.ScheduleAbsi
              "
            >
              <cl-editor-schedule
                [disabled]="disabled"
                [configuration]="s.configuration"
                [scheduleTypeId]="s.scheduleTypeId"
                [scheduleResourceId]="s.scheduleResourceId"
                (scheduleChanged)="scheduleChanged($event)"
                [showLocation]="type == componentType.ScheduleAndCheckbox"
              >
              </cl-editor-schedule>
            </div>
            <div
              class="col-12 pt-2"
              *ngIf="
                type == componentType.CheckboxRadioLocation ||
                type == componentType.Restrictions
              "
            >
              <cl-editor-location
                [disabled]="disabled"
                [resourceTypes]="
                  type == componentType.CheckboxRadioLocation
                    ? [resourceTypes.Branchline]
                    : [resourceTypes.Accelerator, resourceTypes.Shutter]
                "
                [configuration]="s.configuration"
                (changed)="locationChanged($event)"
              >
              </cl-editor-location>
            </div>

            <div class="col-12 pt-2" *ngIf="component?.colorize">
              <cl-editor-colors
                [disabled]="disabled"
                [configuration]="s.configuration"
                (colorChanged)="colorChanged($event)"
              >
              </cl-editor-colors>
            </div>
            <div class="col-12 pt-2" *ngIf="type == componentType.BRATOI">
              <cl-editor-bratoi
                [disabled]="disabled"
                [configuration]="s.configuration"
                (changed)="BRATOIChanged($event)"
                (sendBRCRole)="setRoleByCode($event)"
              >
              </cl-editor-bratoi>
            </div>
            <div
              class="col-12 pt-2"
              *ngIf="type == componentType.ShieldingsComponent"
            >
              <cl-editor-sublocations
                [configuration]="s.configuration"
                (configurationChanged)="configurationChanged($event)"
              >
              </cl-editor-sublocations>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="type == componentType.RADSurveyImageTable">
        <div class="col-12 pt-2">
          <mat-accordion [MatAcc]>
            <mat-expansion-panel
              [expanded]="!noOptions"
              (opened)="noOptions = false"
              (closed)="noOptions = true"
            >
              <mat-expansion-panel-header>
                Table Options
              </mat-expansion-panel-header>
              <cl-editor-table-headers
                [disabled]="disabled"
                [configuration]="s.configuration"
                (changed)="headersChange($event)"
              >
              </cl-editor-table-headers>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div class="row" *ngIf="component?.hasMultipleRoles">
        <div class="col-12 pt-2">
          <cl-editor-checkbox-roles
            [disabled]="disabled"
            [options]="tmpOptions"
            [configuration]="s.configuration"
            (changed)="optionsChange($event)"
          ></cl-editor-checkbox-roles>
        </div>
      </div>
    </div>
    <div
      class="card-footer px-3 py-2 scrollbar editor-footer"
      id="scrollableContainer-editor"
      [ngClass]="{ 'max-h-80': noOptions }"
    >
      <div class="row">
        <div class="col-12 pl-2">
          <master-control
            [type]="type"
            [visible]="true"
            [builder]="true"
            [editor]="true"
            [enabled]="true"
            [disabled]="disabled"
            [color]="color"
            [header]="component?.numericHeader ? '#' : name"
            [label]="name"
            [text]="clean(t1)"
            [question]="clean(t1)"
            [radioQuestion]="clean(t2)"
            [options]="options"
            [radioOptions]="options"
            [controlID]="s.value2"
            [checkbox_checked]="s.approved"
            [approved]="s.approved"
            [approvedBy]="s.approvedBy"
            [approvedOn]="s.approvedOn"
            [roleIds]=""
            [roleCodes]="s.roleCodes"
            [scheduleResourceId]="scheduleResourceId"
            [scheduleTypeId]="scheduleTypeId"
            [textBefore]="clean(t1)"
            [textAfter]="clean(t2)"
            [textAfter2]="clean(t3)"
            [textValue]="s.textValue"
            [checkboxVisible]="true"
            [disableEdit]="true"
            [dateValue1]="s.dateValue1"
            [dateValue2]="s.dateValue2"
            [dateValue3]="s.dateValue3"
            [locationName]="locationName"
            [configuration]="configuration"
            [editMode]="s.editMode"
            [enableColumns]="true"
            [currentChecklistTemplate]="checklistTemplate"
            (changed)="change($event)"
            style="width: 100%"
          >
          </master-control>
        </div>
      </div>
    </div>
  </div>
</div>
